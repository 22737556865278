import excelIcon from '../img/FileIcones/excel.png';
import awpIcon from '../img/FileIcones/AWP.png';
import pdfIcon from '../img/FileIcones/pdf.png';
import wordIcon from '../img/FileIcones/Word.png';
import textIcon from '../img/FileIcones/txt.png';
import zipIcon from '../img/FileIcones/ZIP.png';
import imageIcon from '../img/FileIcones/image.png';
import allIcon from '../img/FileIcones/ALL.png';

const extensionMap = {
    xls: excelIcon,
    xlsx: excelIcon,
    awp: awpIcon,
    pdf: pdfIcon,
    doc: wordIcon,
    docx: wordIcon,
    txt: textIcon,
    zip: zipIcon,
    png: imageIcon,
    gif: imageIcon,
    jpg: imageIcon,
    jpeg: imageIcon,
    jpe: imageIcon,
    ico: imageIcon,
    tga: imageIcon,
    tif: imageIcon,
    tiff: imageIcon,
    bmp: imageIcon,
};

export default function getIcon(extension) {
    try {
        let fileExtension = extension.split('.').pop();
        fileExtension = fileExtension.toLowerCase();
        let icon = extensionMap[fileExtension];
        return icon || allIcon;
    } catch (err) {
        return allIcon;
    }
}
