import React from 'react';
import {ModalCustomVersion2} from '../commonModals';
import TextInput from '~components/TextInput';
import CustomTable from '~components/CustomTable';
import {injectIntl} from 'react-intl';
import Label from './Label';
import {Button} from '../componentsFormV2';

const CURRENT_FILTER_TITLE = {
    contains: 'Contains',
    starts: 'Starts with',
    equals: 'Equals',
};

class TextLabelInputVersion2Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timestamp: Date.now(),
            highlightIndex: 0,
            highlightSelectUpdate: 0,
        };
    }

    componentDidMount() {
        this.props.onNameChange({target: {value: this.props.value}});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            setTimeout(() => {
                this.setState({timestamp: Date.now()});
            }, 100);
        }
    }

    getResultsLabelText = resultRows => {
        if (!resultRows) {
            return null;
        }
        if (resultRows.length === 0) {
            return 'No matches';
        }
        if (resultRows.length === 1) {
            return '1 match';
        }
        return `${resultRows.length} matches`;
    };

    setHighlightSelectUpdate = highlightSelectUpdate => this.setState({highlightSelectUpdate});

    setHighlightIndex = highlightIndex => this.setState({highlightIndex});

    onTextInputSearchTermEnter = () => {
        let resultRows = this.props.value ? this.props.searchList : this.props.listByIdName;
        if (resultRows && resultRows.length && this.state.highlightIndex >= 0) {
            let speciesID = (resultRows[this.state.highlightIndex] || {}).ID;
            this.props.onRowClick(speciesID);
        }
    };

    onArrowUp = () => this.setState({highlightSelectUpdate: -1});

    onArrowDown = () => this.setState({highlightSelectUpdate: 1});

    render() {
        let resultRows = this.props.value ? this.props.searchList : this.props.listByIdName;
        const textInputSearchTermTitle = this.props.currentFilter
            ? CURRENT_FILTER_TITLE[this.props.currentFilter]
            : CURRENT_FILTER_TITLE['starts'];
        this.props.value.length === 1 && this.props.searching && (resultRows = this.props.listByIdName);
        const resultsLabel = this.getResultsLabelText(resultRows);

        return (
            <ModalCustomVersion2
                close={this.props.close}
                closeOnEscapeKeyPress={true}
                header={
                    <div>
                        <h2 style={{textAlign: 'left'}}>{`Find elements by search term`}</h2>
                        <div style={{display: 'flex', alignItems: 'flex-end'}}>
                            <TextInput
                                focus={true}
                                label={textInputSearchTermTitle}
                                onChange={this.props.onNameChange}
                                value={this.props.value}
                                double={true}
                                onEnter={this.onTextInputSearchTermEnter}
                                onArrowUp={this.onArrowUp}
                                onArrowDown={this.onArrowDown}
                            />
                            {resultsLabel ? <Label>{resultsLabel}</Label> : null}
                        </div>
                        <div style={{clear: 'both'}} />
                    </div>
                }
                body={
                    <div style={{textAlign: 'left'}}>
                        <CustomTable
                            count={resultRows.length}
                            hiddenHeader={true}
                            tableName={'textLabelInputModal'}
                            tableType={'OBJECT'}
                            tableSource={resultRows}
                            id={'ID'}
                            resultFieldsDefault={['NAME']}
                            intl={this.props.intl}
                            hideExcelButton={true}
                            timestamp={this.state.timestamp}
                            notSortable={true}
                            unlimited={true}
                            rowClick={this.props.onRowClick}
                            formatFunctions={{
                                NAME: name =>
                                    name && this.props.value !== '' ? (
                                        <div
                                            /* eslint-disable-next-line react/no-danger */
                                            dangerouslySetInnerHTML={{
                                                __html: name.replaceAll(
                                                    RegExp(this.props.value, 'ig'),
                                                    match => `<mark style={fontWeight:400}>${match}</mark>`
                                                ),
                                            }}
                                        />
                                    ) : (
                                        name
                                    ),
                            }}
                            infiniteScrollEnabled={true}
                            highlightSelectEnabled={true}
                            setHighlightIndex={this.setHighlightIndex}
                            highlightSelectUpdate={this.state.highlightSelectUpdate}
                            setHighlightSelectUpdate={this.setHighlightSelectUpdate}
                        />
                        <div style={{clear: 'both'}} />
                    </div>
                }
                footer={<Button icon={'close'} clickAction={this.props.close} variation={'danger'}>{`Close`}</Button>}
            />
        );
    }
}

export default injectIntl(TextLabelInputVersion2Modal);
