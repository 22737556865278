import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Help from '../common/Help';
import ReactDOMServer from 'react-dom/server';
import ResponseTextModalClientNumber from './ResponseTextModalClientNumber/ResponseTextModalClientNumber';
import ResponseWrapper, {IWizardResponseWrapperProps, IWizardResponseWrapperState} from './ResponseWrapper';
import TextInput from '~components/TextInput';
import {VALIDATION_TYPE_VALUE} from '../responsesLogicModals/ModalLogicTypeValidations';
import {
    checkIfHasDisabledLogic,
    checkIfHasHiddenLogic,
    checkIfInputHasClientCode,
    checkIfInputHasLength,
    checkIfIsEmailInput,
    checkIfIsRegExpInput,
    getErrorMessageResponseMandatoryField,
    getErrorMessageResponseMandatoryFieldWithoutArrow,
} from './utils';
import {faPen, faPlus, faSearch} from '@fortawesome/free-solid-svg-icons';
import {checkClientCode, checkEmail, htmlToText} from '../../../utils';
import cx from 'classnames';
import {injectIntl} from 'react-intl';
import {replaceLineBreaksWithHTML} from '../../../utils/format';
import styles from './ResponseText.module.scss';
import {IGroupQuestionResponseLogic} from '../../../types';
import {findGroupOrTemplateFromQuestionId} from '../questions/utils';
import {getGroupKeyWithIteration} from '../utils';

interface IState extends IWizardResponseWrapperState {
    modalClientNumber: any;
    notValid: any;
    errorMessage: string | null;
}

class ResponseText extends ResponseWrapper<IWizardResponseWrapperProps, IState> {
    isEmailInput: boolean = false;
    isRegExp: string | null | false = false;
    hasLength: {min: any; max: any} | false = false;
    hasClientCode: IGroupQuestionResponseLogic | undefined | false = false;
    isDisabled: IGroupQuestionResponseLogic | undefined | false = false;
    isHidden: IGroupQuestionResponseLogic | undefined | false = false;

    constructor(props: IWizardResponseWrapperProps) {
        super(props);
        this.isEmailInput = checkIfIsEmailInput(props.response);
        this.isRegExp = checkIfIsRegExpInput(props.response);
        this.hasLength = checkIfInputHasLength(props.response);
        this.hasClientCode = checkIfInputHasClientCode(props.response);
        this.isDisabled = checkIfHasDisabledLogic(props.response);
        this.isHidden = checkIfHasHiddenLogic(props.response);
        this.state = {...this.state, modalClientNumber: null, notValid: null, errorMessage: null};
    }

    componentDidMount() {
        this.checkAndUpdateIfCurrentResponseIsDisabledDueToLogicDisabledOfOtherResponse();
        if (this.getResponseAnswer()) {
            this.isValidText();
        }
    }

    componentDidUpdate(prevProps: IWizardResponseWrapperProps, prevState: IState) {
        if (this.props.screenData.hash !== prevProps.screenData.hash) {
            this.isHidden = checkIfHasHiddenLogic(this.props.response);
            this.isDisabled = checkIfHasDisabledLogic(this.props.response);
            this.checkAndUpdateIfCurrentResponseIsDisabledDueToLogicDisabledOfOtherResponse();
        }
    }

    isValidText = () => {
        const {questionMandatory, response} = this.props;
        let responseHasOptionalLogicType = (response?.logics || []).find(logic => logic.type === 'optional');
        let mustHaveAnswer = questionMandatory && !responseHasOptionalLogicType; // TODO FIX: check optional + hidden with wrapper fn
        let responseAnswer = this.getResponseAnswer();
        let checkIfMustHaveAnswer = mustHaveAnswer ? true : responseAnswer;
        if (this.hasClientCode) {
            let notValid = checkIfMustHaveAnswer && !checkClientCode(responseAnswer);
            let errorMessage = notValid ? 'This response should have valid client code.' : null;
            this.setState({notValid, errorMessage});
        }
        if (this.isEmailInput) {
            let notValid = checkIfMustHaveAnswer && !checkEmail(responseAnswer);
            let errorMessage =
                this.getResponseAnswer() && notValid ? 'This response does not respect email format.' : null;
            this.setState({notValid, errorMessage});
        }
        if (this.isRegExp) {
            let logicPattern = this.isRegExp.replace('regexp:', '');
            let pattern = logicPattern.slice(1, logicPattern.length - 1);
            //Why we replace first and last slash from regexp:
            //console.log(logicPattern);
            // /^[^\r\n.\-_,;:!\?\*$%\+=&#\~\(\[\{\)\]\}<>'"\/\\]{1,}$/
            //console.log(new RegExp(logicPattern));
            // /\/^[^\r\n.\-_,;:!\?\*$%\+=&#\~\(\[\{\)\]\}<>'"\/\\]{1,}$\//
            //console.log(new RegExp(pattern));
            // /^[^\r\n.\-_,;:!\?\*$%\+=&#\~\(\[\{\)\]\}<>'"\/\\]{1,}$/
            const regularExpression = new RegExp(pattern);
            let notValid = checkIfMustHaveAnswer && !regularExpression.test(this.getResponseAnswer());
            if (this.isRegExp.includes(VALIDATION_TYPE_VALUE.PHONE_FAX)) {
                this.setState({
                    notValid,
                    errorMessage: notValid && `This response should have valid phone / fax.`,
                });
            } else if (
                this.isRegExp.includes(VALIDATION_TYPE_VALUE.DENOMINATION) ||
                this.isRegExp.includes(VALIDATION_TYPE_VALUE.DENOMINATION_NAKTUINBOUW)
            ) {
                this.setState({
                    notValid,
                    errorMessage: notValid && `This response should have valid denomination.`,
                });
            } else {
                this.setState({notValid, errorMessage: notValid && `The value is not valid for this field.`});
            }
        }
        if (this.hasLength) {
            const {min, max} = this.hasLength || {};
            let valid = false;
            let errorMessage = null;
            min &&
                max &&
                (valid =
                    this.getResponseAnswer() &&
                    this.getResponseAnswer().length >= min &&
                    this.getResponseAnswer().length <= max);
            min && !max && (valid = this.getResponseAnswer() && this.getResponseAnswer().length >= min);
            max && !min && (valid = this.getResponseAnswer() && this.getResponseAnswer().length <= max);
            if (!valid) {
                let errorMessages = [];
                min && errorMessages.push(`minimum ${min} characters`);
                max && errorMessages.push(`maximum ${max} characters`);
                errorMessage = `This response should have ${errorMessages.join(' and ')}.`;
            }
            this.setState({
                notValid: checkIfMustHaveAnswer && !valid,
                errorMessage: checkIfMustHaveAnswer && errorMessage,
            });
        }
        return true;
    };

    onTextInputChange = ({target: {value: textInputValue}}: React.ChangeEvent<HTMLInputElement>) => {
        if (this.hasClientCode) {
            textInputValue = textInputValue.replace(/[^0-9]/g, '').substring(0, 5);
        }
        let extraResponseClient: any = {};
        const responsesClientKeysToDelete: string[] = [];
        const {response} = this.props;
        ((response && response.logics) || [])
            .filter(logic => logic.type === 'forceresponse')
            .forEach(logicForceResponse => {
                const {logicParam, responseIdDest, questionIdDest} = logicForceResponse || {};
                const logicParamValue = parseInt((logicParam || '').split(':').pop() || '0') || 0;
                let groupOrTemplate = findGroupOrTemplateFromQuestionId({
                    steps: this.props.steps,
                    questionId: questionIdDest,
                });
                let groupKeyWithIteration = getGroupKeyWithIteration({
                    group: groupOrTemplate,
                });
                let questionKeyWithIteration = `${groupKeyWithIteration}_Q${questionIdDest}_1`;
                const {type: groupOrTemplateQuestionType} =
                    ((groupOrTemplate && groupOrTemplate.questions) || []).find(
                        question => parseInt(String(question.id)) === parseInt(String(questionIdDest))
                    ) || {};
                if (logicParamValue) {
                    if (textInputValue) {
                        if (groupOrTemplateQuestionType === 'radio') {
                            extraResponseClient[
                                `${questionKeyWithIteration}`
                            ] = `${questionKeyWithIteration}_R${responseIdDest}_1`;
                        }
                    } else {
                        responsesClientKeysToDelete.push(questionKeyWithIteration);
                    }
                }
            });
        this.updateCurrentResponseAnswer(
            textInputValue,
            this.isValidText,
            extraResponseClient,
            responsesClientKeysToDelete
        );
    };

    openModalClientNumber = () => this.setState({modalClientNumber: true});

    closeModalClientNumber = () => this.setState({modalClientNumber: null});

    renderRemedyIcon = () => {
        const remedyType = this.getRemedyType();
        return (
            <div
                className={cx(
                    styles.remedyIconWrap,
                    styles[this.props.question.type],
                    remedyType ? styles[remedyType] : null
                )}
                onClick={this.onButtonAddResponseToRemedyClick}
                id={`REMEDY_${this.getResponseKey()}`}
            >
                <FontAwesomeIcon
                    icon={(this.checkIfRemedyResponseExists() ? faPen : faPlus) as any}
                    color={'white'}
                    width={16}
                    height={10}
                />
            </div>
        );
    };

    render() {
        const {response} = this.props;
        const {messageRemark} = response;
        const outsideLabelWithHTML = this.getLabelWithHTML();
        const responseKey = this.getResponseKey();
        const textInputValue = this.getResponseAnswer();
        const hasClickedOnSaveAndValidate = this.checkIfUserHasClickedOnSaveAndValidate();
        const hasMandatoryError = this.checkIfResponseHasMandatoryError(this.props.responseIteration);
        const mandatoryErrorMessage = hasMandatoryError
            ? this.props.twoInputsPerLine
                ? getErrorMessageResponseMandatoryFieldWithoutArrow({intl: this.props.intl})
                : getErrorMessageResponseMandatoryField({intl: this.props.intl})
            : null;
        const errorMessage = hasClickedOnSaveAndValidate && (this.state.errorMessage || mandatoryErrorMessage);
        const hasOldBackOfficeStylesError = this.state.notValid || (hasClickedOnSaveAndValidate && errorMessage);
        if ((window as any).elements) {
            let element: any = {
                type: 'RESPONSE_TEXT',
                label: htmlToText(ReactDOMServer.renderToStaticMarkup(outsideLabelWithHTML)),
                order: this.getResponseOrderForPDF(),
                questionType: this.props.question.type,
            };
            messageRemark && (element.remark = messageRemark);
            let elementExist = (window as any).elements.find((el: any) => el.order === element.order);
            if (!elementExist) {
                (window as any).elements.push(element);
            }
        }

        return (
            <>
                {super.render()}
                {this.props.isRemedyStarted && this.renderRemedyIcon()}
                {this.state.modalClientNumber && (
                    <ResponseTextModalClientNumber
                        close={this.closeModalClientNumber}
                        screenData={this.props.screenData}
                        updateScreenData={this.props.updateScreenData}
                        questionKeyWithIteration={this.props.questionKeyWithIteration}
                        question={this.props.question}
                    />
                )}
                <div id={responseKey}>
                    <Help
                        message={response.messageHelp}
                        language={this.props.questionnaireLanguage}
                        className="responseHelp"
                    />
                    {this.props.PDFVersion ? (
                        <div className={styles.pdfResponseWrap}>
                            {this.props.isPDFVersionAndQuestionIsTableType ? null : (
                                <div className={styles.pdfResponseWrapLeft}>{outsideLabelWithHTML}</div>
                            )}
                            <div className={cx(styles.pdfResponseWrapRight, !!textInputValue && styles.hasPDFValue)}>
                                {replaceLineBreaksWithHTML(textInputValue)}
                            </div>
                        </div>
                    ) : (
                        <TextInput
                            onChange={this.onTextInputChange}
                            value={textInputValue}
                            outsideLabelWithHTML={outsideLabelWithHTML}
                            remark={messageRemark}
                            isRemarkTranslationMissing={this.checkIfResponseRemarkTranslationIsMissing()}
                            oldBackOfficeStyles={true}
                            twoInputsPerLine={this.props.twoInputsPerLine}
                            oldBackOfficeStylesError={hasOldBackOfficeStylesError}
                            type={this.props.type}
                            PDFVersion={this.props.PDFVersion}
                            error={errorMessage}
                            disabled={
                                this.isDisabled || this.state.isCurrentResponseDisabledDueToLogicDisabledOfOtherResponse
                            }
                            inputIsHidden={this.isHidden}
                            isTranslationMissing={this.checkIfTranslationIsMissing()}
                        />
                    )}

                    {this.hasClientCode && (
                        <button
                            type="button"
                            className={cx(styles.button, styles.clientSearchIconWrap)}
                            onClick={this.openModalClientNumber}
                        >
                            <FontAwesomeIcon icon={faSearch as any} />
                        </button>
                    )}
                </div>
            </>
        );
    }
}

export default injectIntl(ResponseText);
