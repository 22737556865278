import React from 'react';
import {injectIntl} from 'react-intl';
import TextInput from '~components/TextInput';
import SelectInput from '~components/SelectInput';
import Title from '~components/Title';
import DateInput from '~components/DateInput';
import TextLabelInput from '~components/TextLabelInput';
import InputLink from '~components/InputLink';
import Empty from '~components/Empty';
import {faChevronDown, faChevronRight, faInfo} from '@fortawesome/free-solid-svg-icons';
import {apiPublicationCharpters, apiSpecies} from '~commonApi/static';
import {apiExaminationOfficeCountries, apiPublicSearch} from './MyPVRLegalProceedingsService';
import styles from './MyPVRLegalProceedings.module.scss';
import {ModalAlertVersion2, ModalCPVOLogsVersion2, ModalStatusInfoVersion2} from '../../commonModals';
import ModalMyPVRPublicSearchObjection from './modals/ModalMyPVRPublicSearchObjection';
import ModalMyPVRPublicSearchNullity from './modals/ModalMyPVRPublicSearchNullity';
import ModalMyPVRPublicSearchCancellation from './modals/ModalMyPVRPublicSearchCancellation';
import ModalMyPVRPublicSearchRestitutioInIntegrum from './modals/ModalMyPVRPublicSearchRestitutioInIntegrum';
import ModalMyPVRPublicSearchAppeal from './modals/ModalMyPVRPublicSearchAppeal';
import ModalMyPVRPublicSearchOther from './modals/ModalMyPVRPublicSearchOther';
import CustomTable from '~components/CustomTable';
import {FORMAT_DATE, formatDateEasy, formatDateTimeEasy, reformatDateEasy} from '~components/FormatFunctions';
import {withRouter} from 'react-router-dom';
import {saveAs} from 'file-saver';
import MyPVRLegalProceedingsActionButtons from './MyPVRLegalProceedingsActionButtons';
import MyPVRPublicSearchTopCaption from './MyPVRLegalProceedingsTopCaption';
import ModalMyPVRPublicSearchELegalActions from './modals/ModalMyPVRPublicSearchELegalActions';
import {apiFileListLogs} from '../CPVOLogs/CPVOLogsService';
import {getDecodedJWT, trackPageView} from '../../utils';
import {getPreSignedURLFetchRequest} from '../../utils/requests';
import {getExcelSignedURLFetchRequest} from '../../commonApi/download';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import {Button} from '../../componentsFormV2';
import Navigation from '../../componentsLayout/Navigation';
import CommunicationCentreCounter from '../../shared/components/CommunicationCentreCounter';

const ALL = 'All';

const PUBLIC_RESULT_FIELDS_ALL = [
    'denomination',
    'speciesName',
    'grantNumber',
    'grantingDate',
    'applicationStatus',
    'applicants',
    'applicationDate',
    'applicationNumber',
    'specieUpovId',
    'firstInsideEU',
    'firstOutsideEU',
    'arrivalDate',
    'groupVarietal',
    'breedersReference',
    'nationalApplicationDate',
    'nationalReference',
    'expirationDate',
    'examOfficeCountry',
    'titleStatus',
];

const PUBLIC_RESULT_FIELDS_DEFAULT = [
    'denomination',
    'speciesName',
    'grantNumber',
    'applicationStatus',
    'applicants',
    'applicationDate',
    'applicationNumber',
    'titleStatus',
];

const advancedCriteria = [
    'grantNumber',
    'applicationStatus',
    'onlineAppNumber',
    'applicants',
    'applicationDateFrom',
    'applicationDateTo',
    'grantDateFrom',
    'grantDateTo',
    'withdrawalDateFrom',
    'withdrawalDateTo',
    'publicationYear',
    'gazetteIssue',
    'chapterId',
    'clientName',
    'examinationOfficeCountry',
];

const statusOptions = [
    {id: 'A', value: 'Active application'},
    {id: 'W', value: 'Withdrawn'},
    {id: 'R', value: 'Refused'},
    {id: 'G', value: 'Granted'},
    {id: 'T', value: 'Terminated Right'},
];

const DEFAULT_CRITERIA = {
    denomination: '',
    denominationFilter: 'equals',
    breedersReference: '',
    speciesName: '',
    speciesNameFilter: 'starts',
    speciesIds: '',
    applicationNumber: '',
    grantNumber: '',
    applicationStatus: ALL,
    onlineAppNumber: '',
    applicants: '',
    applicationDateFrom: '',
    applicationDateTo: '',
    grantDateFrom: '',
    grantDateTo: '',
    withdrawalDateFrom: '',
    withdrawalDateTo: '',
    publicationYear: ALL,
    gazetteIssue: ALL,
    chapterId: ALL,
    clientName: '',
    examinationOfficeCountry: ALL,
    //
    pageNumber: 1,
    pageSize: 10,
    order: 'applicationNumber',
    reverse: false,
    refresh: false,
    excel: false,
};

const DATEFORMAT_CRITERIA_KEYS = [
    'applicationDateFrom',
    'applicationDateTo',
    'grantDateFrom',
    'grantDateTo',
    'withdrawalDateFrom',
    'withdrawalDateTo',
];

const modalScreen = {
    STATUS_INFO: 'STATUS_INFO',
    LEGAL_ACTIONS: 'LEGAL_ACTIONS',
    LEGAL_PROCEEDING_OBJECTION: 'LEGAL_PROCEEDING_OBJECTION',
    LEGAL_PROCEEDING_NULLITY: 'LEGAL_PROCEEDING_NULLITY',
    LEGAL_PROCEEDING_CANCELLATION: 'LEGAL_PROCEEDING_CANCELLATION',
    LEGAL_PROCEEDING_RESTITUTIO_IN_INTEGRUM: 'LEGAL_PROCEEDING_RESTITUTIO_IN_INTEGRUM',
    LEGAL_PROCEEDING_APPEAL: 'LEGAL_PROCEEDING_APPEAL',
    LEGAL_PROCEEDING_OTHER: 'LEGAL_PROCEEDING_OTHER',
    MODAL_FILES: 'MODAL_FILES',
};

const eLegalActions = [
    {
        actionName: 'objection',
        modalScreen: modalScreen.LEGAL_PROCEEDING_OBJECTION,
        applicationStatus: 'Application active',
    },
    {
        actionName: 'nullity',
        modalScreen: modalScreen.LEGAL_PROCEEDING_NULLITY,
        applicationStatus: 'Application granted',
    },
    {
        actionName: 'cancellation',
        modalScreen: modalScreen.LEGAL_PROCEEDING_CANCELLATION,
        applicationStatus: 'Application granted',
    },
    {
        actionName: 'restitutioInIntegrum',
        modalScreen: modalScreen.LEGAL_PROCEEDING_RESTITUTIO_IN_INTEGRUM,
        applicationStatus: 'Any status',
    },
    {actionName: 'appeal', modalScreen: modalScreen.LEGAL_PROCEEDING_APPEAL, applicationStatus: 'Any status'},
    {actionName: 'other', modalScreen: modalScreen.LEGAL_PROCEEDING_OTHER, applicationStatus: 'Any status'},
];

const DEFAULT_COUNT = 0;

class MyPVRLegalProceedings extends React.Component {
    constructor(props) {
        super(props);
        this.thirdPartyId = (getDecodedJWT() || {}).thirdPartyId;
        this.state = {
            clients: null,
            advancedOpened: false,
            errorTitle: '',
            errorMessage: '',
            modalScreen: null,
            loading: 0,
            selectedSpecies: {},
            registers: null,
            timestamp: null,
            publicationChapterList: [],
            examinationOfficeCountries: [],
            count: DEFAULT_COUNT,
            criteria: DEFAULT_CRITERIA,
            criteriaCount: 0,
            advancedCriteriaCount: 0,
            lastSearch: (this.lastSearchCriteria && this.lastSearchCriteria.dateTime) || null,
            subjectToAnAppeal: false,
            clientRepresentant: true,
            speciesListByIdName: null,
            speciesNameHash: null,
            legalBasis: null,
            selectedApplicationNumbers: {},
            screenLoaded: false,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'legalProceedings'});
        this.loadJSONs();
        this.loadUrlParams();
    }

    loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const criteria = Object.assign({}, this.initialCriteria);
            const params = domainArray.pop();
            params.split('&').forEach(param => {
                const paramElements = param.split('=');
                if (paramElements.length === 2) {
                    const key = paramElements[0];
                    criteria[key] = decodeURIComponent(paramElements[1]);
                }
            });
            if (Object.keys(criteria).length > 0) {
                const selectedSpecies = {};
                if (criteria.speciesIds && criteria.speciesIds !== '') {
                    criteria.speciesIds.split(',').forEach(speciesId => (selectedSpecies[speciesId] = false));
                }
                DATEFORMAT_CRITERIA_KEYS.forEach(criteriaKey => {
                    if (/^[0-9]{4}(-([0-9]){2}){2}$/.test(criteria[criteriaKey])) {
                        criteria[criteriaKey] = formatDateEasy(criteria[criteriaKey]);
                    }
                });
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                this.setState(
                    Object.assign(
                        {},
                        {
                            criteria,
                            criteriaCount,
                            advancedCriteriaCount,
                            selectedSpecies,
                        },
                        criteria.order && {defaultOrder: criteria.order},
                        criteria.reverse && {reverseOrder: criteria.reverse}
                    ),
                    () => this.search(false, true)
                );
            }
        }
    };

    loadJSONs = () => {
        this.setState(
            prev => ({loading: ++prev.loading}),
            () =>
                apiPublicationCharpters()
                    .then(jsonResponse => jsonResponse && this.setState({publicationChapterList: jsonResponse}))
                    .then(() => this.setState(prev => ({loading: --prev.loading})))
        );

        this.setState(
            prev => ({loading: ++prev.loading}),
            () =>
                apiExaminationOfficeCountries()
                    .then(
                        jsonResult =>
                            jsonResult &&
                            jsonResult.data &&
                            this.setState({examinationOfficeCountries: jsonResult.data})
                    )
                    .then(() => this.setState(prev => ({loading: --prev.loading})))
        );

        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                apiPublicationCharpters()
                    .then(jsonResponse => {
                        if (jsonResponse) {
                            this.setState({
                                publicationChapterList: jsonResponse,
                            });
                        }
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                apiSpecies()
                    .then(jsonResponse => {
                        if (jsonResponse) {
                            const speciesNameHash = {};
                            (jsonResponse || []).forEach(speciesElement => {
                                if (!speciesNameHash[speciesElement.NAME]) {
                                    speciesNameHash[speciesElement.NAME] = [];
                                }
                                speciesNameHash[speciesElement.NAME].push(speciesElement.ID);
                            });
                            const speciesListByIdName = Object.keys(speciesNameHash).map(speciesName => ({
                                ID: speciesNameHash[speciesName].join(','),
                                NAME: speciesName,
                            }));
                            const selectedSpecies = {};
                            const selectedSpeciesKeyArray = Object.keys(this.state.selectedSpecies || {});
                            speciesListByIdName
                                .filter(i => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                                .forEach(item => (selectedSpecies[item.ID] = item.NAME));
                            this.setState({
                                speciesListByIdName,
                                speciesNameHash,
                                selectedSpecies,
                                timestamp: Date.now(),
                            });
                        }
                    })
                    .catch(error => {
                        ERROR`FormLabelInputSpecies: ${error.message}`;
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );
    };

    showError = (title, message) => {
        this.setState({
            errorTitle: title,
            errorMessage: message,
        });
    };

    closeError = () => {
        this.setState({
            errorTitle: '',
            errorMessage: '',
        });
    };

    toggleAdvancedSearch = () => {
        this.setState(
            prevState => ({
                advancedOpened: !prevState.advancedOpened,
            }),
            () => {
                if (this.state.advancedOpened && this.setSelectedFunction && this.state.criteria.applicants) {
                    this.setSelectedFunction(this.state.criteria.applicants.split(','));
                }
            }
        );
    };

    showSearchModalInfo = () => this.setState({modalScreen: modalScreen.STATUS_INFO});
    showModalSelectLegalAction = applicationNumber => {
        if (!this.isLogged()) {
            this.props.history.push(`/login?home=${encodeURIComponent(window.location.href.split('/').pop())}`);
        }
        if (applicationNumber) {
            this.setState({modalScreen: modalScreen.LEGAL_ACTIONS, legalActionTarget: [applicationNumber]});
        } else {
            this.setState(prev => ({
                modalScreen: modalScreen.LEGAL_ACTIONS,
                legalActionTarget: Object.keys(prev.selectedApplicationNumbers),
            }));
        }
    };
    isLogged = () => {
        const remember = localStorage.getItem('remember') === '1';
        return (remember ? localStorage : sessionStorage).getItem('token');
    };

    closeModal = () =>
        this.setState({modalScreen: null, legalActionTarget: [], actionName: null}, () =>
            this.buildParams(this.parseCriteria(this.state.criteria))
        );

    closeModalInfo = () => this.setState({modalInfo: null});

    openModal = ({folder, subject, comments}) => {
        this.setState(
            prev => ({
                loading: ++prev.loading,
            }),
            () =>
                apiFileListLogs('uploadDocuments', folder)
                    .then(jsonResult =>
                        this.setState({
                            files: jsonResult.files,
                            folder,
                            subject,
                            comments,
                            modalScreen: modalScreen.MODAL_FILES,
                            timestamp: Date.now,
                        })
                    )
                    .then(() =>
                        this.setState(prev => ({
                            loading: --prev.loading,
                        }))
                    )
        );
    };

    parseCriteria = criteria => {
        const parsedCriteria = {};
        Object.keys(criteria).map(key => {
            if (DATEFORMAT_CRITERIA_KEYS.indexOf(key) !== -1 && criteria[key] !== '') {
                const date = criteria[key];
                parsedCriteria[key] = reformatDateEasy(date);
            } else if (key === 'denominationFilter' && !criteria['denomination']) {
                criteria[key] = DEFAULT_CRITERIA[key];
            } else if (key === 'speciesNameFilter' && !criteria['speciesName']) {
                criteria[key] = DEFAULT_CRITERIA[key];
            } else {
                parsedCriteria[key] = criteria[key];
            }
        });
        return parsedCriteria;
    };

    search = (refresh, urlLoad) => {
        this.setState(
            prev => ({
                loading: ++prev.loading,
                subjectToAnAppeal: false,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams(parsedCriteria);
                apiPublicSearch(parsedCriteria, DEFAULT_CRITERIA)
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.registers) {
                            let subjectToAnAppeal = (jsonResponse.data.registers || []).some(
                                register => !!register.appealStatus
                            );
                            this.applicationHash = {};
                            (jsonResponse.data.registers || []).forEach(
                                register => (this.applicationHash[register.applicationNumber] = register)
                            );
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        registers: jsonResponse.data.registers,
                                        timestamp: Date.now(),
                                        subjectToAnAppeal,
                                        screenLoaded: true,
                                        selectedApplicationNumbers: {},
                                    },
                                    jsonResponse.data.count && {count: jsonResponse.data.count}
                                );
                            });
                        }
                    })
                    .catch(error => {
                        ERROR`Register search list error: ${error.message}`;
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );
    };

    printExcel = excelTranslations => {
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                const parsedCriteria = Object.assign({}, this.parseCriteria(this.state.criteria), {excel: true});
                apiPublicSearch(parsedCriteria, DEFAULT_CRITERIA, excelTranslations)
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                            getExcelSignedURLFetchRequest(jsonResponse.data.token).then(response => {
                                if (response && response.signedUrl) {
                                    const {signedUrl} = response;
                                    getPreSignedURLFetchRequest(signedUrl)
                                        .then(response => response.blob())
                                        .then(responseBlob => {
                                            saveAs(responseBlob, `PublicSearch.xlsx`);
                                        });
                                }
                            });
                        }
                    })
                    .catch(error => LOG`error downloading: ${error}`)
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );
    };

    goToRegisters = applicationNumber => {
        const paramsArray = document.location.href.split('?');
        const validParams = paramsArray.length > 1;
        paramsArray.shift();
        const params = validParams && paramsArray.join('?');
        this.props.history.push(
            `/publicConsultationDetails?registerId=${applicationNumber}${(params && `&${params}`) || ''}`
        );
    };

    buildParams = parsedCriteria => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(i => parsedCriteria[i] !== DEFAULT_CRITERIA[i] && i !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key]}`);
        this.props.history.replace(`/legalProceedings${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`);
    };

    countCriteria = (criteria, advanced) => {
        let count = 0;
        (advanced ? advancedCriteria : Object.keys(criteria)).map(key => {
            if (criteria[key] && criteria[key] !== DEFAULT_CRITERIA[key] && criteria[key] !== '') count++;
        });
        return count;
    };

    resetCriteria = () => {
        this.setState(prev => ({
            registers: null,
            selectedSpecies: {},
            criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
            criteriaCount: 0,
            advancedCriteriaCount: 0,
        }));
    };

    updateCriteriaValue = (criteriaValue, callback, refresh) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                // DEBUG`new criteria: ${{...criteria}}`;
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                pageNumberChanged = criteriaValue.pageNumber && prev.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse && prev.reverse !== criteriaValue.reverse;
                return {criteria, criteriaCount, advancedCriteriaCount};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    legalProceeding = applicationNumber => {
        const parsedCriteria = this.parseCriteria(this.state.criteria);
        this.buildParams(Object.assign({}, parsedCriteria, {legalProceeding: applicationNumber}));
        this.setState({legalProceeding: applicationNumber, modalScreen: modalScreen.LEGAL_PROCEEDING_OBJECTION});
    };

    getAppealStatusRowClass = rowObject => rowObject.appealStatus && styles.warningBold;

    refreshScreen = () => this.setState({timestamp: Date.now()});

    onApplicationStatusChange = event => {
        const applicationStatus = event.target.value;
        this.updateCriteriaValue({applicationStatus});
    };

    onPublicationYearChange = event => {
        const publicationYear = event.target.value;
        this.updateCriteriaValue(Object.assign({}, {publicationYear}, publicationYear === ALL && {gazetteIssue: ALL}));
    };

    onPublicationGazetteIssueChange = event => {
        const gazetteIssue = event.target.value;
        this.updateCriteriaValue({gazetteIssue});
    };

    onPublicationChapterChange = event => {
        const chapterId = event.target.value;
        this.updateCriteriaValue({chapterId});
    };

    onExaminationOfficeCountryChange = event => {
        const examinationOfficeCountry = event.target.value;
        this.updateCriteriaValue({examinationOfficeCountry});
    };

    onApplicationFromChange = applicationDateFrom => {
        this.updateCriteriaValue({applicationDateFrom});
    };

    onApplicationToChange = applicationDateTo => {
        this.updateCriteriaValue({applicationDateTo});
    };

    onGrantDateFromChange = grantDateFrom => {
        this.updateCriteriaValue({grantDateFrom});
    };

    onGrantDateToChange = grantDateTo => {
        this.updateCriteriaValue({grantDateTo});
    };

    onWidthdrawalDateFromChange = withdrawalDateFrom => {
        this.updateCriteriaValue({withdrawalDateFrom});
    };

    onWidthdrawalDateToChange = withdrawalDateTo => {
        this.updateCriteriaValue({withdrawalDateTo});
    };

    onDenominationChange = event => {
        const denomination = event.target.value;
        this.updateCriteriaValue({denomination});
    };

    onDenominationFilterChange = event => {
        const denominationFilter = event.target.value;
        this.updateCriteriaValue({denominationFilter});
    };

    onBreederReferenceChange = event => {
        const breedersReference = event.target.value;
        this.updateCriteriaValue({breedersReference});
    };

    onSpeciesSelectionChange = selectedSpecies =>
        this.updateCriteriaValue({speciesIds: Object.keys(selectedSpecies).join(','), speciesName: ''}, () =>
            this.setState({selectedSpecies})
        );

    onSpeciesNameChange = speciesName => {
        this.updateCriteriaValue({speciesName});
    };

    onSpeciesNameFilterChange = event => {
        const speciesNameFilter = event.target.value;
        this.updateCriteriaValue({speciesNameFilter});
    };

    onApplicationNumberChange = event => {
        let applicationNumber = event.target.value;
        if (/[0-9]{0,8}/.test(applicationNumber)) {
            applicationNumber = applicationNumber.substring(0, 8);
            this.updateCriteriaValue({applicationNumber});
        }
    };

    onGrantNumberChange = event => {
        const grantNumber = event.target.value;
        if (!isNaN(grantNumber)) {
            this.updateCriteriaValue({grantNumber});
        }
    };

    onClientChange = event => {
        const clientName = event.target.value;
        this.updateCriteriaValue({clientName});
    };

    onSelectedChange = applicationNumbers => {
        const selectedApplicationNumbers = {};
        applicationNumbers.forEach(
            applicationNumber =>
                (selectedApplicationNumbers[applicationNumber] =
                    this.applicationHash[applicationNumber] || this.state.selectedApplicationNumbers[applicationNumber])
        );
        this.setState({
            selectedApplicationNumbers,
            legalActionEnabled: Object.keys(Object.keys(selectedApplicationNumbers || {})).length > 0,
        });
    };

    onSelectModal = (screen, actionName) => {
        const hashOfDifferentStates = {};
        this.state.legalActionTarget.forEach(
            applicationNumber =>
                (hashOfDifferentStates[this.applicationHash[applicationNumber].applicationStatus] = true)
        );
        if (screen === modalScreen.LEGAL_PROCEEDING_OBJECTION && Object.keys(hashOfDifferentStates).length > 1) {
            this.setState({
                modalScreen: null,
                modalInfo: 'You cannot file an Objection of applications with different statuses at the same time.',
            });
        } else {
            this.setState({modalScreen: screen, actionName});
        }
    };

    render() {
        const actions = MyPVRLegalProceedingsActionButtons(this.goToRegisters);
        const yearOptions = [];
        for (let year = new Date().getFullYear(); year > 2004; year--) {
            yearOptions.push({id: year, value: year});
        }
        const gazetteIssuesOptions = [];
        for (let i = 1; i < 7; i++) {
            const issueNumber = `0${i}`;
            gazetteIssuesOptions.push({id: i, value: issueNumber});
        }
        const chapters = (this.state.publicationChapterList || []).map(chapter => ({
            id: chapter.CHAPTERID,
            value: `${chapter.CHAPTERID} - ${chapter.CHAPTERNAME}`,
        }));

        const gazetteIssueDisabled = this.state.criteria.publicationYear === ALL;
        let selectedElementsHash = {};
        Object.keys(this.state.selectedApplicationNumbers || {}).forEach(el => (selectedElementsHash[el] = true));

        return (
            <>
                {this.state.modalInfo && (
                    <ModalAlertVersion2
                        title={'General information'}
                        message={this.state.modalInfo}
                        close={this.closeModalInfo}
                    />
                )}
                {this.state.modalScreen === modalScreen.STATUS_INFO && (
                    <ModalStatusInfoVersion2 filter={['A', 'W', 'R', 'G', 'T']} close={this.closeModal} />
                )}
                {this.state.modalScreen === modalScreen.LEGAL_PROCEEDING_OBJECTION && (
                    <ModalMyPVRPublicSearchObjection
                        intl={this.props.intl}
                        legalActionTarget={this.state.legalActionTarget}
                        openModal={this.openModal}
                        actionName={this.state.actionName}
                        close={this.closeModal}
                    />
                )}
                {this.state.modalScreen === modalScreen.LEGAL_PROCEEDING_NULLITY && (
                    <ModalMyPVRPublicSearchNullity
                        intl={this.props.intl}
                        legalActionTarget={this.state.legalActionTarget}
                        openModal={this.openModal}
                        actionName={this.state.actionName}
                        close={this.closeModal}
                    />
                )}
                {this.state.modalScreen === modalScreen.LEGAL_PROCEEDING_CANCELLATION && (
                    <ModalMyPVRPublicSearchCancellation
                        intl={this.props.intl}
                        legalActionTarget={this.state.legalActionTarget}
                        openModal={this.openModal}
                        actionName={this.state.actionName}
                        close={this.closeModal}
                    />
                )}
                {this.state.modalScreen === modalScreen.LEGAL_PROCEEDING_RESTITUTIO_IN_INTEGRUM && (
                    <ModalMyPVRPublicSearchRestitutioInIntegrum
                        intl={this.props.intl}
                        legalActionTarget={this.state.legalActionTarget}
                        openModal={this.openModal}
                        actionName={this.state.actionName}
                        close={this.closeModal}
                    />
                )}
                {this.state.modalScreen === modalScreen.LEGAL_PROCEEDING_APPEAL && (
                    <ModalMyPVRPublicSearchAppeal
                        intl={this.props.intl}
                        legalActionTarget={this.state.legalActionTarget}
                        openModal={this.openModal}
                        actionName={this.state.actionName}
                        close={this.closeModal}
                    />
                )}
                {this.state.modalScreen === modalScreen.LEGAL_PROCEEDING_OTHER && (
                    <ModalMyPVRPublicSearchOther
                        intl={this.props.intl}
                        legalActionTarget={this.state.legalActionTarget}
                        openModal={this.openModal}
                        actionName={this.state.actionName}
                        close={this.closeModal}
                    />
                )}
                {this.state.modalScreen === modalScreen.LEGAL_ACTIONS && (
                    <ModalMyPVRPublicSearchELegalActions
                        intl={this.props.intl}
                        onSelectModal={this.onSelectModal}
                        eLegalActions={eLegalActions}
                        selectedApplicationNumbers={this.state.selectedApplicationNumbers}
                        close={this.closeModal}
                    />
                )}
                {this.state.modalScreen === modalScreen.MODAL_FILES && (
                    <ModalCPVOLogsVersion2
                        intl={this.props.intl}
                        zone={'uploadDocuments'}
                        folder={this.state.folder}
                        subject={this.state.subject}
                        comments={this.state.comments}
                        notice={false}
                        timestamp={this.state.timestamp}
                        files={this.state.files}
                        userMode={true}
                        close={this.closeModal}
                    />
                )}
                {/*{this.state.fieldChooserOpened ? (*/}
                {/*    <ModalMyPVRPublicSearchELegalActions*/}
                {/*        currentFields={currentFields}*/}
                {/*        resultFieldsAll={this.props.resultFieldsAll}*/}
                {/*        resultFieldsDefault={this.props.resultFieldsDefault}*/}
                {/*        close={this.fieldChooserClose}*/}
                {/*        intl={this.props.intl}*/}
                {/*        tableName={this.props.tableName}*/}
                {/*        updateFields={this.updateFields}*/}
                {/*    />*/}
                {/*) : null}*/}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={'Legal Proceedings'} />
                <Navigation linksRight={[{Component: CommunicationCentreCounter}]} />
                <MainWrapper>
                    <FormWrapper className={styles.formWrapper} classNameContent={styles.formContent}>
                        <div className={styles.mainFieldContainer}>
                            <TextInput
                                filter={['equals', 'contains']}
                                currentFilter={this.state.criteria.denominationFilter}
                                onFilterChange={this.onDenominationFilterChange}
                                double={true}
                                infoIcon={faInfo}
                                onChange={this.onDenominationChange}
                                onEnter={this.search}
                                value={this.state.criteria.denomination}
                                popOverText={`The search will find all applications containing what is typed`}
                                outsideLabel={'Denomination'}
                                outsideLabelWidth={160}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <TextInput
                                filter={'Contains'}
                                double={true}
                                infoIcon={faInfo}
                                onChange={this.onBreederReferenceChange}
                                onEnter={this.search}
                                value={this.state.criteria.breedersReference}
                                popOverText={`The search will find all applications containing what is typed`}
                                outsideLabel={'Breeder’s reference'}
                                outsideLabelWidth={160}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <TextLabelInput
                                filter={['starts', 'contains']}
                                currentFilter={this.state.criteria.speciesNameFilter}
                                onFilterChange={this.onSpeciesNameFilterChange}
                                double={true}
                                onSelectionChange={this.onSpeciesSelectionChange}
                                onChange={this.onSpeciesNameChange}
                                onEnter={this.search}
                                value={this.state.criteria.speciesName}
                                selectedElements={this.state.selectedSpecies}
                                delay={300}
                                multiple={true}
                                outsideLabel={'Species'}
                                outsideLabelWidth={160}
                                listByIdName={this.state.speciesListByIdName}
                                nameHash={this.state.speciesNameHash}
                                popover={`The search will find all applications concerned by the specified species`}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <TextInput
                                filter={'Starts with'}
                                placeholder={`e.g. 2015 or 20150001`}
                                infoIcon={faInfo}
                                onChange={this.onApplicationNumberChange}
                                onEnter={this.search}
                                value={this.state.criteria.applicationNumber}
                                popOverText={`The search will find all applications whose number starts with the entered terms.`}
                                outsideLabel={'Application number(s)'}
                                outsideLabelWidth={160}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <div className={styles.AdvancedSearchLinkSpacer}>
                                <Empty width={160} oneLine={true} />
                            </div>
                            <InputLink
                                label={`Advanced search${
                                    this.state.advancedCriteriaCount !== 0
                                        ? ` (${this.state.advancedCriteriaCount})`
                                        : ''
                                } `}
                                icon={this.state.advancedOpened ? faChevronDown : faChevronRight}
                                clickAction={this.toggleAdvancedSearch}
                            />
                            <Empty oneLine={true} />
                        </div>
                        {this.state.advancedOpened && (
                            <>
                                <div className={styles.doubleColumn}>
                                    <Title double={true} full={true}>{`Application details`}</Title>
                                    <TextInput
                                        filter={'Starts with'}
                                        label={`Grant number`}
                                        placeholder={`e.g. 3600`}
                                        infoIcon={faInfo}
                                        onChange={this.onGrantNumberChange}
                                        onEnter={this.search}
                                        value={this.state.criteria.grantNumber}
                                    />
                                    <DateInput
                                        label={`Application date`}
                                        changeDateFrom={this.onApplicationFromChange}
                                        changeDateTo={this.onApplicationToChange}
                                        inputValueFrom={this.state.criteria.applicationDateFrom}
                                        inputValueTo={this.state.criteria.applicationDateTo}
                                    />
                                    <SelectInput
                                        label={`Application status`}
                                        value={this.state.criteria.applicationStatus}
                                        onChange={this.onApplicationStatusChange}
                                        list={statusOptions}
                                        buttonAction={this.showSearchModalInfo}
                                        buttonIcon={faInfo}
                                    />
                                    <DateInput
                                        label={`Grant date`}
                                        changeDateFrom={this.onGrantDateFromChange}
                                        changeDateTo={this.onGrantDateToChange}
                                        inputValueFrom={this.state.criteria.grantDateFrom}
                                        inputValueTo={this.state.criteria.grantDateTo}
                                    />
                                    <TextInput
                                        label={`Applicants`}
                                        placeholder={`Please type part of the client name`}
                                        value={this.state.criteria.clientName}
                                        onChange={this.onClientChange}
                                    />
                                    <DateInput
                                        label={`Withdrawal date`}
                                        changeDateFrom={this.onWidthdrawalDateFromChange}
                                        changeDateTo={this.onWidthdrawalDateToChange}
                                        inputValueFrom={this.state.criteria.withdrawalDateFrom}
                                        inputValueTo={this.state.criteria.withdrawalDateTo}
                                    />
                                </div>
                                <div className={styles.simpleColumn}>
                                    <Title full={true}>{`Publication details`}</Title>
                                    <SelectInput
                                        label={`Publication year`}
                                        value={this.state.criteria.publicationYear}
                                        onChange={this.onPublicationYearChange}
                                        list={yearOptions}
                                    />
                                    <SelectInput
                                        label={`Gazette issue`}
                                        value={this.state.criteria.gazetteIssue}
                                        onChange={this.onPublicationGazetteIssueChange}
                                        disabled={gazetteIssueDisabled}
                                        list={gazetteIssuesOptions}
                                    />
                                    <SelectInput
                                        label={`Chapter`}
                                        value={this.state.criteria.chapterId}
                                        onChange={this.onPublicationChapterChange}
                                        list={chapters}
                                    />
                                </div>
                                <Title triple={true} full={true}>{`Examination Office details`}</Title>
                                <SelectInput
                                    label={`Examination office country`}
                                    value={this.state.criteria.examinationOfficeCountry}
                                    onChange={this.onExaminationOfficeCountryChange}
                                    list={this.state.examinationOfficeCountries}
                                />
                            </>
                        )}
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            <Button variation={'secondary'} clickAction={this.resetCriteria}>{`Clear fields`}</Button>
                            <Button variation={'primary'} clickAction={() => this.search(false)}>{`Search`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    {this.state.screenLoaded ? (
                        <div style={{padding: '0 0 30px 0', marginTop: -5}}>
                            <CustomTable
                                version={2}
                                loading={this.state.loading !== 0}
                                pageNumber={this.state.criteria.pageNumber}
                                {...this.props}
                                tableName={'publicSearch'}
                                tableType={'OBJECT'}
                                tableSource={this.state.registers}
                                timestamp={this.state.timestamp}
                                dataFilter={null}
                                id={'applicationNumber'}
                                pagination={true}
                                printExcel={this.printExcel}
                                rowClick={this.goToRegisters}
                                resultFieldsAll={PUBLIC_RESULT_FIELDS_ALL}
                                resultFieldsDefault={PUBLIC_RESULT_FIELDS_DEFAULT}
                                intl={this.props.intl}
                                defaultOrder={this.state.criteria.order}
                                reverseOrder={this.state.criteria.reverse}
                                pageSize={this.state.criteria.pageSize}
                                filterFunctions={null}
                                count={this.state.count}
                                rowClass={this.getAppealStatusRowClass}
                                formatFunctions={{
                                    applicants: applicants => {
                                        const applicantList = [];
                                        applicants.forEach(applicant => {
                                            applicantList.push(
                                                <p key={applicant} style={{margin: 0}} className="ng-scope">
                                                    {applicant}
                                                </p>
                                            );
                                        });
                                        return applicantList;
                                    },
                                    grantingDate: FORMAT_DATE,
                                    applicationStatus: fieldData => {
                                        switch (fieldData) {
                                            case 'A':
                                                return 'Active application';
                                            case 'T':
                                                return 'Terminated Right';
                                            case 'W':
                                                return 'Withdrawn';
                                            case 'R':
                                                return 'Refused';
                                            case 'G':
                                                return 'Granted';
                                            case 'P':
                                            case 'M':
                                            case 'U':
                                                return 'Reception ongoing';
                                            case 'N':
                                                return 'Abandoned application';
                                            default:
                                                return fieldData;
                                        }
                                    },
                                    applicationDate: FORMAT_DATE,
                                    arrivalDate: FORMAT_DATE,
                                    expirationDate: FORMAT_DATE,
                                    surrenderDate: FORMAT_DATE,
                                    nationalApplicationDate: FORMAT_DATE,
                                    firstInsideEU: (firstInsideEU, objectRow) =>
                                        firstInsideEU && objectRow['firstInsideEUCountry']
                                            ? `${formatDateTimeEasy(firstInsideEU)} - ${
                                                  objectRow['firstInsideEUCountry']
                                              }`
                                            : '',
                                    firstOutsideEU: (firstOutsideEU, objectRow) =>
                                        firstOutsideEU && objectRow['firstOutsideEUCountry']
                                            ? `${formatDateTimeEasy(firstOutsideEU)} - ${
                                                  objectRow['firstOutsideEUCountry']
                                              }`
                                            : '',
                                    titleStatus: titleStatus =>
                                        this.props.intl.formatMessage({id: `table.general.${titleStatus}`}),
                                }}
                                headerPopup={{
                                    applicationStatus: {
                                        description: 'Status explanations',
                                        handler: this.showSearchModalInfo,
                                    },
                                }}
                                excelFormatFunctions={{
                                    applicants: applicants => applicants.join('; '),
                                }}
                                updateCriteriaValue={this.updateCriteriaValue}
                                actions={actions}
                                selectable={this.isLogged()}
                                onSelectedChange={this.onSelectedChange}
                                selectDefault={selectedElementsHash}
                                timestampSelected={this.state.timestamp}
                                topCaption={
                                    this.isLogged() ? (
                                        <MyPVRPublicSearchTopCaption
                                            legalActionEnabled={this.state.legalActionEnabled}
                                            subjectToAnAppeal={this.state.subjectToAnAppeal}
                                            showModalSelectLegalAction={() => this.showModalSelectLegalAction()}
                                        />
                                    ) : null
                                }
                            />
                        </div>
                    ) : null}
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(MyPVRLegalProceedings));
