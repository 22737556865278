import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypePastDateHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>
                        The user can select only pictures to upload with a limit of 5 max.
                    </p>
                    <p className={styles.modalHelpParagraph}>
                        The document type is by default <strong>Photo</strong> and disabled.
                    </p>
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypePastDateHelp;
