import React, {useEffect, useState} from 'react';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import {ModalErrorVersion2} from '../../../../commonModals';
import NavigationTLO from '../../../../shared/NavigationTLO';
import {Prompt, RouteComponentProps, withRouter} from 'react-router-dom';
import SubMenuTabs from '../../SubMenu/SubMenuTabs';
import {Tree} from '../../CommonComponents/TreeView/Interfaces/TreeViewInterfaces';
import TreeView from '../../CommonComponents/TreeView/TreeView';
import {apiCreateTree, apiDownloadTreeDocumentFile, apiFetchTree} from '../../CommonApi/CommonApi';
import {getUserRoles} from '../../CommonFunctions/CommonFunctions';
import {getPreSignedURLFetchRequest} from '../../../../utils/requests';
import {injectIntl, IntlShape} from 'react-intl';
import {saveAs} from 'file-saver';
import {History} from 'history';
import {trackPageView} from '../../../../utils';

const modalScreenTypes = {
    ERROR: 'ERROR',
};

interface TemplatesPageProps {
    intl: IntlShape;
    history: History;
}

const TemplatesPagePage = (props: TemplatesPageProps & RouteComponentProps) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [tree, setTree] = useState<Tree>(JSON.parse(JSON.stringify({})));
    const [treeIsEdited, setTreeIsEdited] = useState(false);
    const [tag, setTag] = useState('templates');
    const [userRoles] = useState(getUserRoles());

    const menuLinks = [
        {
            id: 'VADEMECUM',
            value: userRoles.indexOf('EOADM') !== -1 ? 'Admin Vademecum' : 'Vademecum',
            path: 'tloDocumentsVademecum',
        },
        {id: 'TEMPLATES', value: 'Templates', path: 'tloDocumentsTemplates'},
        {id: 'GENERAL_INFORMATION', value: 'General Information', path: 'tloDocumentsGeneralInformation'},
    ];

    useEffect(() => {
        if (treeIsEdited) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }
    });

    const fetchTree = () => {
        setLoading(true);
        apiFetchTree(tag)
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.general && jsonResponse.general.length > 0) {
                    setTree(jsonResponse.general[0].document);
                    setTag(jsonResponse.general[0].tag);
                }
            })
            .catch(error => {
                setErrorMessage(`Fetch Tree error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        trackPageView({documentTitle: 'tloDocumentsTemplates'});
        if (userRoles.indexOf('EOADM') === -1) {
            fetchTree();
        } else {
            const treeFromLS = localStorage.getItem(tag + 'GeneralTree');
            if (treeFromLS === null) {
                fetchTree();
            } else {
                setTreeIsEdited(true);
                setTree(JSON.parse(treeFromLS));
            }
        }
    }, []);

    const closeModal = () => setModalScreen(null);

    const downloadDocumentFile = (fileUUID: string, fileName: string) => {
        setLoading(true);
        apiDownloadTreeDocumentFile(fileUUID)
            .then(response => {
                if (response && response.signedUrl) {
                    const {signedUrl} = response;
                    getPreSignedURLFetchRequest(signedUrl)
                        .then(response => response.blob())
                        .then(responseBlob => {
                            const saveFileName = fileName ? fileName : fileUUID;
                            saveAs(responseBlob, saveFileName);
                        });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading file: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const discardTreeChanges = (tag: string, typeOfTree: string) => {
        localStorage.removeItem(tag + typeOfTree + 'Tree');
        setTreeIsEdited(false);
        fetchTree();
    };

    const saveTree = (tree: Tree, typeOfTree: string) => {
        setLoading(true);
        apiCreateTree(Object.assign({}, {tree, tag}))
            .then(() => {
                discardTreeChanges(tag, typeOfTree);
            })
            .catch(error => {
                setErrorMessage(`error saving tree: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <Prompt when={treeIsEdited} message="You have unsaved changes, are you sure you want to leave?" />
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeModal} />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`Technical Liaison Officer`} />
            <NavigationTLO activeTitle={'Documents'} />
            <MainWrapper>
                <div style={{marginBottom: 20, minHeight: 600, textAlign: 'center'}}>
                    <FormWrapper paddingFormContent={'sm'}>
                        <SubMenuTabs tabId={'TEMPLATES'} loading={loading} menuLinks={menuLinks} />
                    </FormWrapper>
                    <TreeView
                        tree={tree}
                        treeIsEdited={treeIsEdited}
                        tag={tag}
                        isAdmin={userRoles.indexOf('EOADM') !== -1}
                        isGeneral={true}
                        downloadFile={downloadDocumentFile}
                        saveTree={saveTree}
                        discardTreeChanges={discardTreeChanges}
                        isACArea={false}
                    />
                </div>
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(TemplatesPagePage));
