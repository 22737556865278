import React from 'react';
import styles from './HeaderLoading.module.scss';

export default function ({message}) {
    return (
        <div className={styles.container}>
            <div className={styles.CPVOIconContainer2}>
                <a href="" id="OCVVLogo">
                    <div className={styles.CPVOIcon} />
                </a>
            </div>
            <div className={styles.label}>{message || 'Loading. Please wait ...'}</div>
            <div className={styles.bar}>{}</div>
            <div className={styles.background}>{` `}</div>
        </div>
    );
}
