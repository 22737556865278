import React from 'react';
import {FORMAT_DATE_EASY} from '~components/FormatFunctions';
import CustomTable from '~components/CustomTable';
import getIcon from '~utils/icons';
import {
    apiAdminProceedingsRightsByUserInsert,
    apiProceedingDocuments,
    apiProceedingRightsByUser,
} from './ModalMyPVRAdminProceedingsDocumentAccessByUserService';
import {saveAs} from 'file-saver';
import Checkbox from '~components/Checkbox';
import {apiDownload} from '~commonApi/download';
import {getPreSignedURLFetchRequest} from '../../../utils/requests';
import pako from 'pako';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';

export default class ModalMyPVRPublicSearchELegalActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {documents: null, loading: 0, authorization: false};
    }

    componentDidMount() {
        this.setState(
            prev => ({
                loading: ++prev.loading,
            }),
            () =>
                apiProceedingDocuments(this.props.applicationNumber)
                    .then(response => {
                        if (response && response.data && response.data.documents) {
                            this.setState({documents: response.data.documents, timestamp: Date.now});
                        }
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading})))
        );
        this.setState(
            prev => ({
                loading: ++prev.loading,
            }),
            () =>
                apiProceedingRightsByUser(this.props.contactId, this.props.applicationNumber).then(response => {
                    if (response && response.exceptionList) {
                        const tableSelectedDocuments = {};
                        for (const csDocsKey of response.exceptionList) {
                            tableSelectedDocuments[csDocsKey] = true;
                        }
                        LOG`${JSON.stringify({
                            authorization: response.authorization,
                            selectedDocuments: response.exceptionList.slice(0),
                            exceptionList: response.exceptionList,
                            tableSelectedDocuments,
                            timestampSelected: Date.now,
                        })}`;
                        this.setState(prev => ({
                            authorization: response.authorization,
                            selectedDocuments: response.exceptionList.slice(0),
                            exceptionList: response.exceptionList,
                            tableSelectedDocuments,
                            timestampSelected: Date.now,
                            loading: --prev.loading,
                        }));
                    }
                })
        );
    }

    close = () => this.props.close();

    downloadDocument = document => {
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                apiDownload(document.csDocsKey)
                    .then(JSONResponse => {
                        if (JSONResponse && JSONResponse.documents) {
                            let preSignedURL;
                            let fileName;
                            let isZip;
                            if (Array.isArray(JSONResponse.documents)) {
                                preSignedURL = JSONResponse.documents[0]?.signedUrl;
                                fileName = JSONResponse.documents[0]?.docName;
                                isZip = JSONResponse.documents[0]?.isZip;
                            } else if (JSONResponse.documents.signecUrl && JSONResponse.documents.fileName) {
                                preSignedURL = JSONResponse.documents.signecUrl;
                                fileName = JSONResponse.documents?.fileName;
                                isZip = JSONResponse.documents?.isZip;
                            }
                            if (preSignedURL && fileName) {
                                getPreSignedURLFetchRequest(preSignedURL)
                                    .then(response => response.blob())
                                    .then(responseBlob => {
                                        if (isZip) {
                                            responseBlob.arrayBuffer().then(fileReader => {
                                                saveAs(new Blob([pako.ungzip(new Uint8Array(fileReader))]), fileName);
                                            });
                                        } else {
                                            saveAs(responseBlob, fileName);
                                        }
                                    });
                            }
                        }
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );
    };

    onSelectedDocumentsChange = selectedDocuments => {
        LOG`onSelectedDocumentsChange: ${selectedDocuments}`;
        this.setState({selectedDocuments: selectedDocuments.map(i => parseInt(i))});
    };

    onAuthorizeCheckboxChange = () => {
        this.setState(prev => ({authorization: !prev.authorization}));
    };

    onSave = () => {
        LOG`this.state.selectedDocuments: ${JSON.stringify(this.state.selectedDocuments)}`;
        LOG`this.state.exceptionList: ${JSON.stringify(this.state.exceptionList)}`;
        LOG`${JSON.stringify({
            authorization: this.state.authorization,
            contactId: this.props.contactId,
            applicationNumber: this.props.applicationNumber,
            csDocsKeyToAdd: this.state.selectedDocuments.filter(i => this.state.exceptionList.indexOf(i) === -1),
            csDocsKeyToDelete: this.state.exceptionList.filter(i => this.state.selectedDocuments.indexOf(i) === -1),
        })}`;
        this.setState(
            prev => ({
                loading: ++prev.loading,
            }),
            () =>
                apiAdminProceedingsRightsByUserInsert({
                    authorization: this.state.authorization,
                    contactId: this.props.contactId,
                    applicationNumber: this.props.applicationNumber,
                    csDocsKeyToAdd: this.state.selectedDocuments.filter(
                        i => this.state.exceptionList.indexOf(i) === -1
                    ),
                    csDocsKeyToDelete: this.state.exceptionList.filter(
                        i => this.state.selectedDocuments.indexOf(i) === -1
                    ),
                })
                    .then(response => {
                        if (response && response.data && response.data.documents) {
                            this.setState({documents: response.data.documents, timestamp: Date.now});
                        }
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading}), this.close))
        );
    };

    render() {
        return (
            <ModalCustomVersion2
                close={this.props.close}
                loadingBar={this.state.loading !== 0}
                header={'Document Rights by User'}
                body={
                    this.state.documents && (
                        <>
                            <div style={{height: 56, paddingTop: 10}}>
                                <Checkbox
                                    clickAction={this.onAuthorizeCheckboxChange}
                                    label={`Authorise access to all the documents except...`}
                                    value={this.state.authorization}
                                    triple={true}
                                    height={46}
                                />
                            </div>
                            {this.state.documents.length === 0 ? (
                                <div style={{color: 'red', textAlign: 'center'}}>
                                    <b style={{textAlign: 'center'}}>
                                        <span>{`No documents available`}</span>
                                    </b>
                                </div>
                            ) : (
                                <CustomTable
                                    pageSize={100}
                                    tableName={'documentRights'}
                                    headerSelectColumnTitle={`Select / Deselect all documents`}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.documents}
                                    id={'csDocsKey'}
                                    resultFieldsDefault={['documentType', 'arrivalDate', 'nbDocs']}
                                    intl={this.props.intl}
                                    defaultOrder={'arrivalDate'}
                                    reverseOrder={true}
                                    formatFunctions={{arrivalDate: FORMAT_DATE_EASY}}
                                    selectable={true}
                                    onSelectedChange={this.onSelectedDocumentsChange}
                                    selectDefault={this.state.tableSelectedDocuments}
                                    timestampSelected={this.state.timestampSelected}
                                    forehandColumn={document => {
                                        return document.csDocsKey ? (
                                            <img
                                                style={{width: 20, height: 20, cursor: 'pointer'}}
                                                alt={'Download'}
                                                src={getIcon(document.extension || 'pdf')}
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    this.downloadDocument(document);
                                                }}
                                            />
                                        ) : (
                                            <span>{}</span>
                                        );
                                    }}
                                    timestamp={this.state.timestamp()}
                                />
                            )}
                            <br />
                        </>
                    )
                }
                footer={
                    <>
                        <Button variation={'danger'} clickAction={this.close} icon={'close'}>{`Close`}</Button>
                        <Button clickAction={this.onSave} icon={'arrowRight'}>{`Save`}</Button>
                    </>
                }
            />
        );
    }
}
