import React from 'react';
import COUNTRIES_LIST from './data/COUNTRIES_LIST.json';
import VFMyAlertsActionButtons from './VFMyAlertsActionButtons';
import {Button} from '../../componentsFormV2';
import CustomTable from '~components/CustomTable';
import DateInput from '~components/DateInput';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import {ModalConfirmVersion2} from '../../commonModals';
import NavigationVarietyFinder from '../../shared/NavigationVarietyFinder';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import graphQLClientInstance from '../../utils/axiosGraphQLClient';
import axiosGraphQLClient from '../../utils/axiosGraphQLClient';
import {createAlertFromValues} from './VFMyAlertsService';
import {convertDate} from '../../utils/utilsVarietyFinder';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {injectIntl} from 'react-intl';
import styles from './VFMyAlerts.module.scss';
import {withRouter} from 'react-router-dom';
import {trackPageView} from '../../utils';

const RESULT_FIELDS_ALL = ['denomination', 'country', 'speciesCode', 'startDate', 'endDate'];

const countriesListParsed = COUNTRIES_LIST.map(({label, value}) => ({
    label,
    value: JSON.parse(value),
}));

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    dateInputValidDateFromValue: string;
    dateInputValidDateFromError: string;
    dateInputValidDateToValue: string;
    forceHideSearchList: boolean;
    loading: number;
    isModalConfirmDeleteAlertOpen: number | false;
    screenLoaded: boolean;
    tableResults: any;
    textInputDenominationError: string;
    textInputDenominationValue: string;
    textLabelInputCountriesSelectedElements: any;
    textLabelInputSpeciesLatinNameSearchLoading: boolean;
    textLabelInputSpeciesLatinNameHash: any;
    textLabelInputSpeciesLatinNameList: any[];
    textLabelInputSpeciesLatinNameSelectedElements: any;
    textLabelInputSpeciesLatinNameTimeoutId: any;
    textLabelInputSpeciesLatinNameValue: string;
    timestamp: number;
    textTimestamp: number;
}

class VFMyAlerts extends React.Component<IProps, IState> {
    skipSearching = false;
    previousCountriesSelectedLabels: any = [];

    constructor(props: IProps) {
        super(props);
        this.state = {
            dateInputValidDateFromValue: '',
            dateInputValidDateFromError: '',
            dateInputValidDateToValue: '',
            forceHideSearchList: false,
            loading: 0,
            isModalConfirmDeleteAlertOpen: false,
            screenLoaded: false,
            tableResults: [],
            textInputDenominationError: '',
            textInputDenominationValue: '',
            textLabelInputCountriesSelectedElements: {},
            textLabelInputSpeciesLatinNameSearchLoading: false,
            textLabelInputSpeciesLatinNameHash: {},
            textLabelInputSpeciesLatinNameList: [],
            textLabelInputSpeciesLatinNameSelectedElements: {},
            textLabelInputSpeciesLatinNameTimeoutId: null,
            textLabelInputSpeciesLatinNameValue: '',
            timestamp: Date.now(),
            textTimestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'My Alerts'});
        this.loadJSONs();
    }

    loadJSONs = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                graphQLClientInstance.get('/api/v1/subscriptions').then(JSONResponse => {
                    if (JSONResponse && JSONResponse.data && JSONResponse.data.subscriptions_list) {
                        const tableResults = JSONResponse.data.subscriptions_list.map(
                            ({
                                conditions,
                                data_end: endDate,
                                data_start: startDate,
                                object_name: denomination,
                                id,
                            }: any) => ({
                                country: conditions && conditions.length ? conditions[0].sub_countries : [],
                                endDate,
                                denomination,
                                id,
                                speciesCode: conditions && conditions.length ? conditions[0].sub_species : [],
                                startDate,
                            })
                        );
                        this.setState(prev => ({
                            loading: prev.loading - 1,
                            screenLoaded: true,
                            tableResults,
                            timestamp: Date.now(),
                        }));
                    }
                });
            }
        );

    closeModalConfirmDeleteAlert = () => this.setState({isModalConfirmDeleteAlertOpen: false});

    getCountriesListByIdName = () => {
        let listByIdName: any = [];
        countriesListParsed.forEach(({label}: any) => {
            listByIdName.push({
                ID: label,
                NAME: label,
            });
        });
        return listByIdName;
    };

    getCountriesNameHash = () => {
        let nameHash: any = {};
        countriesListParsed.forEach(({label}: any) => {
            nameHash[label] = [label];
        });
        return nameHash;
    };

    autoCompletePostData = (autoCompleteName: string, term: string, type: string, includeSynonyms = false) => {
        const synonyms = includeSynonyms ? `includeSynonyms:"1"` : '';

        return {
            query: `
    {
      ${autoCompleteName}(
        term: "${term.toLowerCase()}"
        type: "${type}"
        ${synonyms}
      ){ name principal specieid}
    }`,
        };
    };

    searchSpeciesLatinNameSuggestions = () => {
        if (!this.skipSearching) {
            this.setState({textLabelInputSpeciesLatinNameSearchLoading: true}, () => {
                axiosGraphQLClient
                    .post(
                        '/graphql',
                        this.autoCompletePostData(
                            'autoCompleteSpeciesLatinName',
                            this.state.textLabelInputSpeciesLatinNameValue,
                            'contains',
                            false
                        )
                    )
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.autoCompleteSpeciesLatinName
                        ) {
                            const nameHash: {[key: string]: string[]} = {};
                            JSONResponse.data.data.autoCompleteSpeciesLatinName.forEach(({name, specieid}: any) => {
                                if (!nameHash[name]) {
                                    nameHash[name] = [];
                                }
                                nameHash[name].push(specieid);
                            });
                            const listByIdName: any = Object.keys(nameHash).map(key => ({
                                ID: nameHash[key].join(','),
                                NAME: key,
                            }));
                            this.setState({
                                forceHideSearchList: false,
                                textTimestamp: Date.now(),
                                textLabelInputSpeciesLatinNameSearchLoading: false,
                                textLabelInputSpeciesLatinNameHash: nameHash,
                                textLabelInputSpeciesLatinNameList: listByIdName,
                            });
                        }
                    })
                    .catch(err => ERROR([err.message]));
            });
        }
    };

    onTextInputDenominationChange = ({
        target: {value: textInputDenominationValue},
    }: React.ChangeEvent<HTMLInputElement>) => this.setState({textInputDenominationValue});

    onTextLabelInputSpeciesLatinNameSelectionChange = (textLabelInputSpeciesLatinNameSelectedElements: any) => {
        this.skipSearching = true;
        this.setState({
            textTimestamp: Date.now(),
            textLabelInputSpeciesLatinNameSelectedElements,
            textLabelInputSpeciesLatinNameValue: '',
        });
        setTimeout(() => {
            this.skipSearching = false;
        }, 1000);
    };

    onDateInputValidDateFromChange = (dateInputValidDateFromValue: string) =>
        this.setState({dateInputValidDateFromValue});

    onDateInputValidDateToChange = (dateInputValidDateToValue: string) => this.setState({dateInputValidDateToValue});

    onTextLabelInputSpeciesLatinNameChange = (textLabelInputSpeciesLatinNameValue: string) => {
        clearTimeout(this.state.textLabelInputSpeciesLatinNameTimeoutId);
        let textLabelInputSpeciesLatinNameHash: any = {};
        let textLabelInputSpeciesLatinNameList: any = [];
        Object.entries(this.state.textLabelInputSpeciesLatinNameSelectedElements).forEach(
            ([speciesId, speciesName]: any) => {
                textLabelInputSpeciesLatinNameHash[speciesName] = [speciesId];
                textLabelInputSpeciesLatinNameList.push({ID: speciesId, NAME: speciesName});
            }
        );
        this.setState({
            textLabelInputSpeciesLatinNameValue,
            textLabelInputSpeciesLatinNameHash,
            textLabelInputSpeciesLatinNameList,
        });
        if ((textLabelInputSpeciesLatinNameValue || '').length > 2) {
            let newTimeoutId = setTimeout(() => {
                this.state.textLabelInputSpeciesLatinNameValue && this.searchSpeciesLatinNameSuggestions();
            }, 300);
            this.setState({
                textLabelInputSpeciesLatinNameTimeoutId: newTimeoutId,
            });
        } else {
            this.setState({forceHideSearchList: true});
        }
    };

    onTextLabelInputCountriesSelectionChange = (_: any) => {
        const selectedElements = {...this.state.textLabelInputCountriesSelectedElements};
        if (this.previousCountriesSelectedLabels.includes('All') && !Object.keys(selectedElements).includes('All')) {
            this.previousCountriesSelectedLabels = [];
            this.setState({textLabelInputCountriesSelectedElements: {}});
            return;
        }
        if (Object.keys(selectedElements).includes('All') && !this.previousCountriesSelectedLabels.includes('All')) {
            let labelsAll = countriesListParsed.map(({label}) => label);
            let stateSelected: any = {};
            labelsAll.forEach(label => (stateSelected[label] = label));
            this.previousCountriesSelectedLabels = Object.keys(stateSelected);
            this.setState({textLabelInputCountriesSelectedElements: stateSelected});
            return;
        }
        if (
            Object.keys(selectedElements).includes('Non-EU') &&
            !this.previousCountriesSelectedLabels.includes('Non-EU')
        ) {
            let labelsArrayNonEU = countriesListParsed
                .filter(({value: {countryid, iseumember}}: any) => iseumember === 0 && countryid !== 'EU')
                .map(({label}) => label);
            let stateSelected: any = {};
            labelsArrayNonEU.forEach(label => (stateSelected[label] = label));
            this.setState(prev => ({
                textLabelInputCountriesSelectedElements: {
                    ...prev.textLabelInputCountriesSelectedElements,
                    ...stateSelected,
                },
            }));
            this.previousCountriesSelectedLabels = Object.keys(stateSelected);
        }
        if (
            this.previousCountriesSelectedLabels.includes('Non-EU') &&
            !Object.keys(selectedElements).includes('Non-EU')
        ) {
            let labelsArrayNonEU = countriesListParsed
                .filter(({value: {iseumember}}: any) => iseumember === 0)
                .map(({label}) => label);
            this.setState(prev => {
                const updatedObject = {...prev.textLabelInputCountriesSelectedElements};
                delete updatedObject['All'];
                Object.keys(updatedObject).forEach(
                    selectedLabel => labelsArrayNonEU.includes(selectedLabel) && delete updatedObject[selectedLabel]
                );
                this.previousCountriesSelectedLabels = Object.keys(updatedObject);
                return {
                    textLabelInputCountriesSelectedElements: updatedObject,
                };
            });
        }
        if (
            Object.keys(selectedElements).includes('European Union - EU') &&
            !this.previousCountriesSelectedLabels.includes('European Union - EU')
        ) {
            let labelsArrayEU = countriesListParsed
                .filter(({value: {iseumember}}: any) => iseumember === 1)
                .map(({label}) => label);
            let stateSelected: any = {};
            labelsArrayEU.forEach(label => (stateSelected[label] = label));
            this.setState(prev => {
                const updatedObject = {
                    ...prev.textLabelInputCountriesSelectedElements,
                    ...stateSelected,
                };
                this.previousCountriesSelectedLabels = Object.keys(updatedObject);
                return {
                    textLabelInputCountriesSelectedElements: updatedObject,
                };
            });
        }
        if (
            this.previousCountriesSelectedLabels.includes('European Union - EU') &&
            !Object.keys(selectedElements).includes('European Union - EU')
        ) {
            let labelsArrayEU = countriesListParsed
                .filter(({value: {iseumember}}: any) => iseumember === 1)
                .map(({label}) => label);

            this.setState(prev => {
                const updatedObject = {...prev.textLabelInputCountriesSelectedElements};
                Object.keys(updatedObject).forEach(
                    selectedLabel => labelsArrayEU.includes(selectedLabel) && delete updatedObject[selectedLabel]
                );
                delete updatedObject['All'];
                this.previousCountriesSelectedLabels = Object.keys(updatedObject);
                return {
                    textLabelInputCountriesSelectedElements: updatedObject,
                };
            });
        }
    };

    onTableIconDeleteClick = (id: number) => this.setState({isModalConfirmDeleteAlertOpen: id});

    onModalConfirmDeleteAlertActionButtonClick = () => {
        const alertIdToDelete = this.state.isModalConfirmDeleteAlertOpen;
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                isModalConfirmDeleteAlertOpen: false,
            }),
            () => {
                graphQLClientInstance.delete(`/api/v1/subscriptions/${alertIdToDelete}`).then(JSONResponse => {
                    if (JSONResponse) {
                        this.setState(
                            prev => ({
                                loading: prev.loading - 1,
                            }),
                            this.loadJSONs
                        );
                    }
                });
            }
        );
    };

    onLinkButtonClearFieldsClick = () =>
        this.setState({
            dateInputValidDateFromValue: '',
            dateInputValidDateFromError: '',
            dateInputValidDateToValue: '',
            textInputDenominationError: '',
            textInputDenominationValue: '',
            textLabelInputCountriesSelectedElements: {},
            textLabelInputSpeciesLatinNameHash: {},
            textLabelInputSpeciesLatinNameList: [],
            textLabelInputSpeciesLatinNameSelectedElements: {},
            textLabelInputSpeciesLatinNameTimeoutId: null,
            textLabelInputSpeciesLatinNameValue: '',
        });

    onLinkButtonHelpClick = () => window.open('/help/en/My_alerts.html', '_blank');

    onButtonCreateClick = () =>
        this.setState(
            {
                dateInputValidDateFromError: '',
                textInputDenominationError: '',
            },
            () => {
                if (!this.state.textInputDenominationValue || !this.state.dateInputValidDateFromValue) {
                    return this.setState(prev => ({
                        dateInputValidDateFromError: !prev.dateInputValidDateFromValue ? 'Field is required!' : '',
                        textInputDenominationError: !prev.textInputDenominationValue ? 'Field is required!' : '',
                    }));
                }
                const values: any = {
                    denomination: this.state.textInputDenominationValue,
                    startDate: this.state.dateInputValidDateFromValue.split('/').reverse().join(''),
                };
                if (this.state.dateInputValidDateToValue) {
                    values.endDate = this.state.dateInputValidDateToValue.split('/').reverse().join('');
                }
                if (this.state.textLabelInputCountriesSelectedElements) {
                    values.countries = Object.keys(this.state.textLabelInputCountriesSelectedElements)
                        .map((country: string) => (((country || '').split('-') || []).pop() || '').trim())
                        .filter(countryId => !['EU', 'All'].includes(countryId));
                }
                if (Object.keys(this.state.textLabelInputSpeciesLatinNameSelectedElements).length) {
                    values.speciesLatinName = Object.keys(this.state.textLabelInputSpeciesLatinNameSelectedElements);
                }
                this.setState(
                    prev => ({
                        loading: prev.loading + 1,
                    }),
                    () => {
                        graphQLClientInstance
                            .post('/api/v1/subscriptions', createAlertFromValues(values))
                            .then(JSONResponse => {
                                if (JSONResponse) {
                                    this.setState(
                                        prev => ({
                                            loading: prev.loading - 1,
                                        }),
                                        () => {
                                            this.onLinkButtonClearFieldsClick();
                                            this.loadJSONs();
                                        }
                                    );
                                }
                            });
                    }
                );
            }
        );

    onHeaderSearchLinkClick = (searchListItem: any) =>
        this.props.history.push({
            pathname: '/varieties',
            state: {searchListItem},
        });

    render() {
        const actions = VFMyAlertsActionButtons(this.onTableIconDeleteClick);
        const tomorrowDateObject = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                {this.state.isModalConfirmDeleteAlertOpen ? (
                    <ModalConfirmVersion2
                        title={`Alert`}
                        message={`Are you sure you want to delete this alert?`}
                        buttonName={'Confirm'}
                        action={this.onModalConfirmDeleteAlertActionButtonClick}
                        close={this.closeModalConfirmDeleteAlert}
                    />
                ) : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Variety Finder`} hostApplication={'Variety Finder'} />
                <NavigationVarietyFinder onSearchLinkClick={this.onHeaderSearchLinkClick} />
                <MainWrapper bgGray={true}>
                    <FormWrapper formInnerWidth={'lg'} paddingFormContent={'sm'}>
                        <div>
                            <TextInput
                                errorVarietyFinder={this.state.textInputDenominationError}
                                label={'Denomination'}
                                mandatory={true}
                                onChange={this.onTextInputDenominationChange}
                                popOverTextFirstRow={`Create an alert for this denomination. Differences in upper/lower case and in accented or stressed characters are ignored.`}
                                value={this.state.textInputDenominationValue}
                                size={'lg'}
                            />
                            <div className={styles.textLabelInputSpeciesLatinNameWrap}>
                                <TextLabelInput
                                    buttonIcon={this.state.textLabelInputSpeciesLatinNameSearchLoading && faSpinner}
                                    double={true}
                                    filterContainsKeepJSONResponseSortOrder={true}
                                    forceHideSearchList={this.state.forceHideSearchList}
                                    hideCounter={true}
                                    label={`Species Latin Name`}
                                    listByIdName={this.state.textLabelInputSpeciesLatinNameList}
                                    multiple={true}
                                    nameHash={this.state.textLabelInputSpeciesLatinNameHash}
                                    onChange={this.onTextLabelInputSpeciesLatinNameChange}
                                    onSelectionChange={this.onTextLabelInputSpeciesLatinNameSelectionChange}
                                    popOverTextFirstRow={`You may optionally select one or more species. To select a species, enter some characters and select a species in the list.`}
                                    noInfo={true}
                                    selectedElements={this.state.textLabelInputSpeciesLatinNameSelectedElements}
                                    timestamp={this.state.textTimestamp}
                                    removeStyleClear={true}
                                    value={this.state.textLabelInputSpeciesLatinNameValue}
                                    size={'lg'}
                                />
                            </div>
                            <div style={{clear: 'both'}} />
                        </div>
                        <TextLabelInput
                            filterContainsKeepJSONResponseSortOrder={true}
                            hideRemoveElementButton={true}
                            label={`Countries`}
                            listByIdName={this.getCountriesListByIdName()}
                            listMaxHeight={200}
                            maxElementsInList={50}
                            nameHash={this.getCountriesNameHash()}
                            onSelectionChange={this.onTextLabelInputCountriesSelectionChange}
                            popOverTextFirstRow={
                                <div
                                    /* eslint-disable-next-line react/no-danger */
                                    dangerouslySetInnerHTML={{
                                        __html: `<span>Optional – Select one or several countries/area<br>-\tEuropean Union - EU: includes EU institutions (CPVO, European Commission, EUIPO) and all EU Member States<br>-\tEuropean Union - QZ: includes EU institutions only</span>`,
                                    }}
                                />
                            }
                            noInfo={true}
                            timestamp={this.state.textTimestamp}
                            removeStyleClear={true}
                            selectedElements={this.state.textLabelInputCountriesSelectedElements}
                            useAsMultipleSelection={true}
                            value={``}
                            selectedLabelsAsInputText={true}
                            placeHolder={`Select`}
                            size={'lg'}
                        />
                        <DateInput
                            label={`Valid Date`}
                            changeDateFrom={this.onDateInputValidDateFromChange}
                            changeDateTo={this.onDateInputValidDateToChange}
                            errorVarietyFinder={this.state.dateInputValidDateFromError}
                            inputValueFrom={this.state.dateInputValidDateFromValue}
                            inputValueTo={this.state.dateInputValidDateToValue}
                            mandatory={true}
                            minDateFrom={tomorrowDateObject}
                            minDateTo={tomorrowDateObject}
                            size={'lg'}
                        />
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            <Button clickAction={this.onLinkButtonHelpClick} variation={'secondary'}>{`Help`}</Button>
                            <Button
                                clickAction={this.onLinkButtonClearFieldsClick}
                                variation={'secondary'}
                            >{`Clear fields`}</Button>
                            <Button clickAction={this.onButtonCreateClick}>{`Create`}</Button>
                        </FormFooter>
                        <div style={{clear: 'both'}} />
                    </FormWrapper>
                    <div>
                        {this.state.screenLoaded ? (
                            <CustomTable
                                actions={actions}
                                formatFunctions={{
                                    country: (country: any) => (
                                        <div className={styles.tableDataCountryWrap}>
                                            {(country || []).map((countryName: string) =>
                                                countryName ? (
                                                    <React.Fragment key={countryName}>
                                                        <span>{countryName}</span>
                                                    </React.Fragment>
                                                ) : null
                                            )}
                                        </div>
                                    ),
                                    endDate: (endDate: number) => convertDate(endDate * 1000),
                                    speciesCode: (speciesCode: any) => {
                                        if (!speciesCode) {
                                            return '';
                                        }
                                        if (speciesCode.length === 1) {
                                            return speciesCode;
                                        }
                                        if (speciesCode.length > 1) {
                                            return (
                                                <>
                                                    {(speciesCode || []).map((species: string) => (
                                                        <React.Fragment key={species}>
                                                            - {species}
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </>
                                            );
                                        }
                                    },
                                    startDate: (startDate: number) => convertDate(startDate * 1000),
                                }}
                                id={'id'}
                                intl={this.props.intl}
                                isNavigationButtonCompactOrDefaultViewEnabled={true}
                                hideExcelButton={true}
                                noChangePageSize={true}
                                noShowingPageNumber={true}
                                notSortable={['country', 'speciesCode']}
                                resultFieldsDefault={RESULT_FIELDS_ALL}
                                tableName={'VFMyAlerts'}
                                tableSource={this.state.tableResults}
                                tableType={'OBJECT'}
                                timestamp={this.state.timestamp}
                                unlimited={true}
                                version={2}
                                hoverGrey={true}
                                navigationBarWider={true}
                                fontSize={14}
                            />
                        ) : null}
                        <div style={{clear: 'both'}} />
                    </div>
                </MainWrapper>
                <Footer hostApplication={'Variety Finder'} />
            </>
        );
    }
}

export default withRouter(injectIntl(VFMyAlerts));
