import React from 'react';
import cx from 'classnames';
// import QRCode from 'qrcode-svg';
import styles from './WizardStepsAll.module.scss';
import WizardStepsGroup from './WizardStepsGroup';
import {injectIntl} from 'react-intl';
import NAKIcon from '../../shared/img/naktuinbouw.png';
import CPVOIcon from '../../shared/img/cpvo.svg';
import {formatDateEasy} from '~components/FormatFunctions';
import ImageSignSeal from '../OnlinePdf/imgs/sign_seal.png';
import en from '../../translations/en.json';
import es from '../../translations/es.json';
import fr from '../../translations/fr.json';
import nl from '../../translations/nl.json';
import de from '../../translations/de.json';
import {IGroup, IScreenData} from '../../types';
// import {getEnvironment} from '../../utils';

const LANGUAGES: any = {
    en,
    es,
    fr,
    nl,
    de,
};

const FORM_TYPES = {
    AF: 1,
    TQ: 2,
};

interface IProps {
    PDFVersion: any;
    applicationCode?: any;
    applicationBreederReference?: any;
    applicationSpeciesInput?: any;
    applicationSignBy?: any;
    applicationSignDateString?: any;
    assistedEntries: any;
    currentStepNumber: number;
    editable: any;
    formLanguage: any;
    formIsConfidential?: any;
    formValidityBegin?: string | null;
    formCommonName: any;
    formType: any;
    formOrTemplateStatus?: any;
    formOrTemplateOfficeId?: any;
    formTitle: any;
    formVersionCode: any;
    formId: any;
    goToStep?: any;
    intl: any;
    iteration?: any;
    isRemedyStarted?: any;
    loadFormQuestionsJSONs: any;
    mainScreen?: any;
    mock?: any;
    modalQueuePush?: any;
    modalQueuePop?: any;
    questionnaireLanguage?: any;
    removeQuestion?: any;
    removeResponse?: any;
    responsesClientHash?: any;
    setStepGroupCallables?: any;
    screenData: IScreenData;
    steps: IGroup[];
    updateScreenData?: any;
    vc: any;
}

interface IState {
    imageLoaded: boolean;
}

class WizardSteps extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {imageLoaded: false};
    }

    setImgLoaded = () => this.setState({imageLoaded: true});

    render() {
        // const svg = new QRCode({
        //     content: `https://${getEnvironment() === 'ONLINE' ? 'online' : 'dev'}.plantvarieties.eu/onlinePdf?vc=${
        //         this.props.vc
        //     }`,
        //     padding: 0,
        //     width: 85,
        //     height: 85,
        //     color: '#000000',
        //     background: '#ffffff',
        //     ecl: 'M',
        // }).svg();

        const datasAndFormDraftLoaded =
            this.props.applicationCode &&
            this.props.assistedEntries &&
            Object.keys(this.props.assistedEntries || {}).length > 0;

        return (
            <>
                {!this.props.applicationCode || (datasAndFormDraftLoaded && this.state.imageLoaded) ? (
                    <div className={'loaded'} />
                ) : null}
                <div className={styles.wizardAllStepsWrapper}>
                    <div className={styles.allStepsHeader}>
                        {datasAndFormDraftLoaded && (
                            <div className={styles.allStepsHeaderLeftRightWrap}>
                                <div className={styles.allStepsHeaderLeft}>
                                    <>
                                        {this.props.applicationCode.slice(0, 1) === 'B' ? (
                                            <img
                                                onLoad={this.setImgLoaded}
                                                src={NAKIcon}
                                                className={styles.NAKIcon}
                                                alt="MainPage link"
                                            />
                                        ) : (
                                            <img
                                                onLoad={this.setImgLoaded}
                                                src={CPVOIcon}
                                                className={styles.CPVOIcon}
                                                alt="MainPage link"
                                            />
                                        )}
                                    </>
                                </div>
                                <div className={styles.allStepsHeaderRight}>
                                    <div className={styles.applicationCode}>
                                        {`Application n° ${this.props.applicationCode}`}
                                    </div>
                                    {/*<div*/}
                                    {/*    ref={el => {*/}
                                    {/*        if (el) el.innerHTML = svg;*/}
                                    {/*    }}*/}
                                    {/*    className={styles.qrCode}*/}
                                    {/*/>*/}
                                </div>
                            </div>
                        )}
                        {this.props.formLanguage && this.props.formType === FORM_TYPES.TQ && (
                            <div className={styles.formTitle} style={{textAlign: 'center'}}>
                                {
                                    LANGUAGES[(this.props.formLanguage || 'EN').toLowerCase()][
                                        `general.technicalQuestionnaire`
                                    ]
                                }
                            </div>
                        )}
                        {this.props.formType === FORM_TYPES.AF && (
                            <div className={styles.nationalAgencyWrap}>
                                <div
                                    className={cx(styles.nationalAgencyLeft)}
                                    style={{marginBottom: 0, fontStyle: 'italic'}}
                                >
                                    {`For use of National Agency only, if necessary`}
                                </div>
                                <div className={cx(styles.nationalAgencyRight)}>
                                    <div className={cx(styles.nationalAgencyField)} />
                                    <div className={cx(styles.nationalAgencyField)} />
                                </div>
                            </div>
                        )}
                        {this.props.formIsConfidential && (
                            <div className={styles.formTitle}>{`** Confidential information **`}</div>
                        )}
                        <div
                            className={styles.formTitle}
                            // eslint-disable-next-line
                            dangerouslySetInnerHTML={{__html: this.props.formTitle}}
                        />
                        {this.props.formType === FORM_TYPES.TQ && this.props.formCommonName && (
                            <div
                                className={styles.formTitle}
                                // eslint-disable-next-line
                                dangerouslySetInnerHTML={{__html: this.props.formCommonName}}
                            />
                        )}
                        <div className={styles.formTitle}>
                            <span
                                // eslint-disable-next-line
                                dangerouslySetInnerHTML={{__html: this.props.formVersionCode}}
                            />
                            {this.props.formType === FORM_TYPES.TQ && this.props.formValidityBegin
                                ? ` - Publication date: ${this.props.formValidityBegin}`
                                : ''}
                        </div>
                        <div
                            className={styles.formSubtitle}
                        >{`Mandatory fields or sections are marked with an asterisk (*)`}</div>
                        {this.props.formType === FORM_TYPES.AF && (
                            <>
                                <div
                                    className={cx(styles.formTitle, styles.formHeaderFields)}
                                >{`I . Breeder's reference: ${this.props.applicationBreederReference || ''}`}</div>
                                <div className={cx(styles.formTitle, styles.formHeaderFields)}>{`II . Species name: ${
                                    this.props.applicationSpeciesInput || ''
                                }`}</div>
                            </>
                        )}
                        {this.props.formType === FORM_TYPES.TQ && !this.props.formIsConfidential && (
                            <>
                                {this.props.applicationBreederReference &&
                                    this.props.steps.slice(0, 3).map((step, i) => {
                                        return (
                                            <div
                                                key={step.message}
                                                ref={el => {
                                                    if (el) el.innerHTML = `0${i + 1}. ${step.message}`;
                                                }}
                                                className={cx(styles.formTitle, styles.formHeaderFields)}
                                            />
                                        );
                                    })}
                            </>
                        )}
                    </div>
                    <div className={styles.wizardStepContainer}>
                        {(this.props.steps || [])
                            .filter(i => i.isGroup)
                            .map(step => {
                                let groupOrTemplateKey = step.isTemplate ? `T${step.templateId}` : `GR${step.id}`;
                                if (this.props.formIsConfidential) {
                                    let groupOrTemplateHasConfidentialQuestionAnsweredYes = Object.keys(
                                        this.props.screenData.responsesClient || {}
                                    ).find(
                                        key =>
                                            key.startsWith(`conf${groupOrTemplateKey}_`) &&
                                            this.props.screenData.responsesClient[key] === 1
                                    );
                                    if (!groupOrTemplateHasConfidentialQuestionAnsweredYes) {
                                        return null;
                                    }
                                }
                                return (
                                    <div key={step.id} className={styles.allStepsStepWrap}>
                                        <WizardStepsGroup
                                            PDFVersion={this.props.PDFVersion}
                                            applicationCode={this.props.applicationCode}
                                            assistedEntries={this.props.assistedEntries}
                                            currentStepNumber={this.props.currentStepNumber}
                                            editable={this.props.editable}
                                            formOrTemplateStatus={this.props.formOrTemplateStatus}
                                            formOrTemplateOfficeId={this.props.formOrTemplateOfficeId}
                                            formId={this.props.formId}
                                            formIsConfidential={this.props.formIsConfidential}
                                            formLanguage={this.props.formLanguage}
                                            group={step}
                                            goToStep={this.props.goToStep}
                                            isRemedyStarted={this.props.isRemedyStarted}
                                            loadFormQuestionsJSONs={this.props.loadFormQuestionsJSONs}
                                            mainScreen={this.props.mainScreen}
                                            modalQueuePush={this.props.modalQueuePush}
                                            modalQueuePop={this.props.modalQueuePop}
                                            mock={this.props.mock}
                                            responsesClientHash={this.props.responsesClientHash}
                                            removeQuestion={this.props.removeQuestion}
                                            removeResponse={this.props.removeResponse}
                                            screenData={this.props.screenData}
                                            setStepGroupCallables={this.props.setStepGroupCallables}
                                            steps={this.props.steps}
                                            updateScreenData={this.props.updateScreenData}
                                        />
                                    </div>
                                );
                            })}
                        {this.props.applicationSignBy &&
                            this.props.applicationSignDateString &&
                            !this.props.formIsConfidential && (
                                <div className={styles.signSealWrap}>
                                    <img src={ImageSignSeal} alt="sign seal" />
                                    <div className={styles.signByLabel}>
                                        {`Application signed by ${this.props.applicationSignBy} on ${formatDateEasy(
                                            this.props.applicationSignDateString
                                        )}`}
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </>
        );
    }
}

export default injectIntl(WizardSteps);
