import React, {useEffect, useState} from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import SelectInput from '~components/SelectInput';
import {injectIntl} from 'react-intl';
import {htmlToText} from '~utils/index';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeApplySameConfidentialValueToOtherQuestions = ({
    close,
    onCreateOrUpdateLogicType,
    responseLogicValues,
    responseLogicType,
    iteration,
    steps,
}) => {
    const {logicParam, questionIdDest} = responseLogicValues || {};
    const {name} = responseLogicType || {};
    const [selectList, setSeletectList] = useState([]);
    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        const getAllQuestions = async () => {
            const propsQuestionsList = [].concat(...steps.map(step => step.questions)).map(question => ({
                id: question.id,
                value: `${question.order} - ${htmlToText(question.message)}`,
            }));
            setSeletectList(propsQuestionsList.map(el => ({id: el.id, value: el.value})));
            let logicSelectedId = questionIdDest ? questionIdDest : null;
            setSelectedId(logicSelectedId || (propsQuestionsList.length > 0 ? propsQuestionsList[0].id : null));
        };
        getAllQuestions();
    }, []);

    return (
        <>
            <ModalCustomVersion2
                close={close}
                header={name}
                body={
                    <>
                        <SelectInput
                            label={`Apply same value to:`}
                            value={selectedId}
                            notAll={true}
                            double={true}
                            onChange={e => setSelectedId(e.target.value)}
                            list={selectList}
                        />
                        <div style={{clear: 'both'}} />
                    </>
                }
                footer={
                    <>
                        <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                        <Button
                            icon={'arrowRight'}
                            clickAction={() =>
                                onCreateOrUpdateLogicType({
                                    param: logicParam !== undefined ? logicParam : null,
                                    applyToRemark: null,
                                    responseIdDest: null,
                                    questionIdDest: selectedId,
                                    messageId: null,
                                    iteration,
                                })
                            }
                        >{`Save`}</Button>
                    </>
                }
            />
        </>
    );
};

export default injectIntl(ModalLogicTypeApplySameConfidentialValueToOtherQuestions);
