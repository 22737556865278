import React from 'react';
import ModalCustomVersion2 from '../../commonModals/ModalCustomVersion2';
import Button from '../../componentsFormV2/Button';
import TextInput from '../../componentsFormV2/TextInput';
import {checkEmail} from '~utils';
import {Error} from '../../componentsLayout';

interface IProps {
    close: any;
    setLoading: any;
    apiLostLogin: any;
    showModalRetriveLoginOK: any;
}

interface IState {
    email: string;
    emailError: boolean;
    error: string;
}

export default class ModalAuthenticationLoginRetriveLoginVersion2 extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {email: '', emailError: false, error: ''};
    }

    componentDidMount() {
        (self as any).setModalStateFunction = this.setStateFunction;
        (self as any).defaultModalState = this.state;
    }

    setStateFunction = (state: any) => this.setState(state);

    close = () => {
        this.props.close && this.props.close();
    };

    isContinueEnabled = () => this.state.email !== '' && checkEmail(this.state.email);

    onEmailChange = ({target: {value: emailValue}}: React.ChangeEvent<HTMLSelectElement>) => {
        const emailValid = emailValue === '' || checkEmail(emailValue);
        this.setState({email: emailValue, emailError: !emailValid});
    };

    onContinue = () => {
        this.setState({error: ''}, () => {
            this.props
                .setLoading(true)
                .then(() => this.props.apiLostLogin({email: this.state.email}))
                .then((response: any) => {
                    if (response && response.data === 'OK') {
                        this.props.showModalRetriveLoginOK();
                    } else {
                        this.setState({error: 'Email not found'});
                    }
                })
                .then(() => this.props.setLoading(false));
        });
    };

    render() {
        return (
            <ModalCustomVersion2
                size={'sm'}
                close={this.close}
                header={`Retrieve your login`}
                body={
                    <>
                        <div style={{marginBottom: 20}}>
                            {
                                'Enter your email here. After clicking continue, you will receive an email to recover your password.'
                            }
                        </div>
                        {this.state.error && <Error>{this.state.error}</Error>}
                        <TextInput
                            onChange={this.onEmailChange}
                            value={this.state.email}
                            focus={true}
                            placeholder={'Type here your email'}
                        />
                    </>
                }
                footer={
                    <>
                        <Button clickAction={this.close} icon={'close'} variation={'danger'}>{`Cancel`}</Button>
                        <Button
                            clickAction={this.onContinue}
                            disabled={!this.isContinueEnabled()}
                            icon={'arrowRight'}
                        >{`Continue`}</Button>
                    </>
                }
            />
        );
    }
}
