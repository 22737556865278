import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';

import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function renderButton(popupContent: any, handler: any, icon: any, length?: any) {
    return {
        title: popupContent,
        icon: () => (
            <a data-content={popupContent}>
                <FontAwesomeIcon icon={icon} />
            </a>
        ),
        handler,
    };
}

export default function VFModalTestDenominationsSubmitBatchTableActionButtons(onTableIconDeleteClick: any) {
    const buttons = [];
    buttons.push(renderButton('Delete', (alertId: any) => onTableIconDeleteClick(alertId), faTrashAlt, length));
    return buttons;
}
