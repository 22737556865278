import React from 'react';
import LogoCPVO from '../img/logo_cpvo.png';
import styles from './HeaderLogo.module.scss';

export default function () {
    return (
        <header className={styles.headerLogo}>
            <a
                href={'https://cpvo.europa.eu/en'}
                target={'_blank'}
                rel={'noopener noreferrer'}
                style={{display: 'flex'}}
            >
                <img src={LogoCPVO} alt="logo" className={styles.logo} />
            </a>
        </header>
    );
}
