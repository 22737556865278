import React from 'react';
import ModalCustomVersion2 from './ModalCustomVersion2';
import defaultSendToList from './ModalContactUsVersion2.json';
import defaultSendToListNL from './ModalContactUsNLVersion2.json';
import {apiContactUs} from './ModalContactUsVersion2Service';
import {checkEmail} from '~utils';
import jwtDecode from 'jwt-decode';
import styles from './ModalContactUsVersion2.module.scss';
import {Error, HeaderLoading} from '../componentsLayout';
import {Button, SelectInput, TextAreaInput, TextInput} from '../componentsFormV2';

export const EMAIL_OFFICE = {
    NL: 'NL',
};

const computeSendToList = ({additionalSendToList, customSendToList, emailOffice}: any) => {
    if (customSendToList) {
        return customSendToList;
    }
    return [...(additionalSendToList || []), ...(emailOffice === 'NL' ? defaultSendToListNL : defaultSendToList)];
};

interface IProps {
    additionalSendToList?: {label: string; email: number}[];
    customSendToList?: {label: string; email: number}[];
    emailOffice?: string;
    sendTo?: number;
    close: any;
    headerTitle?: string;
    subjectTag?: string;
}

interface IState {
    from: string;
    sendTo: number;
    subject: string;
    comment: string;
    error: any;
    emailError: boolean;
    emailDisabled: boolean;
    loading: boolean;
    email: string;
}

export default class ModalContactUsVersion2 extends React.Component<IProps, IState> {
    sendToList: any;

    constructor(props: IProps) {
        super(props);
        this.sendToList = computeSendToList({
            additionalSendToList: props.additionalSendToList,
            customSendToList: props.customSendToList,
            emailOffice: props.emailOffice,
        });
        this.state = {
            from: '',
            sendTo: this.props.sendTo || this.sendToList[0].email,
            subject: '',
            comment: '',
            error: null,
            emailError: false,
            emailDisabled: false,
            loading: false,
            email: '',
        };
    }

    componentDidMount() {
        const remember = localStorage.getItem('remember') === '1';
        const authToken = (remember ? localStorage : sessionStorage).getItem('token');
        if (authToken) {
            const decoded = jwtDecode(authToken);
            if (decoded.email && (decoded.name || decoded.forename)) {
                this.setState({
                    emailDisabled: true,
                    email: decoded.email,
                    from: `${decoded.forename || ''}${decoded.name ? ` ${decoded.name}` : ''}`,
                });
            }
        }
    }

    close = (reload?: any) => this.props.close && this.props.close(reload);

    getMandatoryAsterisk = (label: string) => (
        <React.Fragment>
            <div style={{display: 'inline-block', marginRight: 5}}>{label}</div>
            <div style={{display: 'inline-block', color: 'red'}}>{'*'}</div>
        </React.Fragment>
    );

    formatSendToList = (sendToList: {label: string; email: number}[]) => {
        if (!sendToList) {
            return [];
        } else {
            return sendToList.map(element => ({
                id: element.email,
                value: element.label,
            }));
        }
    };

    onFromChange = ({target: {value: from}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({
            from,
            emailError: from !== '' && !checkEmail(from),
        });

    onSendToChange = ({target: {value: sendTo}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.setState({sendTo: parseInt(sendTo)});

    onSubjectChange = ({target: {value: subject}}: React.ChangeEvent<HTMLInputElement>) => this.setState({subject});

    onCommentChange = ({target: {value: comment}}: React.ChangeEvent<HTMLInputElement>) => this.setState({comment});

    onSend = () => {
        this.setState({error: null}, () => {
            const {from, sendTo, subject, comment} = this.state;
            if (from === '' || !sendTo || subject === '' || comment === '' || this.state.emailError) {
                this.setState({error: 'Error - Please fill all the fields.'});
            } else {
                this.setState({loading: true}, () => {
                    const messageSubject = this.props.subjectTag ? `${this.props.subjectTag} ${subject}` : subject;
                    apiContactUs({
                        from: this.state.emailDisabled ? this.state.email : from,
                        sendTo: this.state.sendTo,
                        subject: messageSubject,
                        comment,
                    })
                        .then((response: any) => {
                            if (response && response.data === 'OK') {
                                this.close();
                            } else {
                                this.setState({error: 'Error sending form'});
                            }
                        })
                        .then(() => this.setState({loading: false}));
                });
            }
        });
    };

    render() {
        const sendToListFormatted = this.sendToList.map((element: any) => ({id: element.email, value: element.label}));

        return (
            <>
                {this.state.loading && <HeaderLoading />}
                <ModalCustomVersion2
                    close={this.close}
                    header={this.props.headerTitle || 'Contact Us'}
                    body={
                        <div className={styles.body}>
                            <TextInput
                                onChange={this.onFromChange}
                                value={this.state.from}
                                label={'From'}
                                focus={true}
                                labelPosition={'leftOutside'}
                                // error={this.state.emailError && 'Invalid email'}
                                disabled={this.state.emailDisabled}
                                mandatory={true}
                            />
                            <SelectInput
                                value={this.state.sendTo}
                                onChange={this.onSendToChange}
                                list={sendToListFormatted}
                                label={'Send to'}
                                mandatory={true}
                                notAll={true}
                                labelPosition={'leftOutside'}
                                disabled={sendToListFormatted.length === 1}
                            />
                            <TextInput
                                onChange={this.onSubjectChange}
                                value={this.state.subject}
                                label={`Subject`}
                                labelPosition={'leftOutside'}
                                mandatory={true}
                            />
                            <TextAreaInput
                                label={'Comment'}
                                value={this.state.comment}
                                onChange={this.onCommentChange}
                                labelPosition={'leftOutside'}
                                mandatory={true}
                                rows={5}
                            />
                        </div>
                    }
                    footer={
                        <>
                            {this.state.error && <Error>{this.state.error}</Error>}
                            <Button clickAction={this.close} variation={'danger'} icon={'close'}>{`Close`}</Button>
                            <Button clickAction={this.onSend} icon={'arrowRight'}>{`Send`}</Button>
                        </>
                    }
                />
            </>
        );
    }
}
