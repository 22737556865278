import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {SectionElement} from '../../types';

function renderButton(popupContent: any, handler: any, icon: any) {
    return {
        title: popupContent,
        icon: () => (
            <a data-content={popupContent}>
                <FontAwesomeIcon icon={icon} />
            </a>
        ),
        handler,
    };
}

export default function ACAreaAdministratorSectionsActionButtons(
    onTableIconModifyClick: any,
    onTableIconDeleteClick: any
) {
    const buttons = [];
    buttons.push(
        renderButton(
            'Modify',
            (rowId: number, rowObject: SectionElement) => onTableIconModifyClick(rowId, rowObject),
            faEdit
        )
    );
    buttons.push(
        renderButton(
            'Delete',
            (rowId: number, rowObject: SectionElement) => onTableIconDeleteClick(rowId, rowObject),
            faTrashAlt
        )
    );
    return buttons;
}
