import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import AutocompleteCountries1 from './screenshots/AutocompleteCountries_1.PNG';
import AutocompleteCountries2 from './screenshots/AutocompleteCountries_2.PNG';
import AutocompleteCountries3 from './screenshots/AutocompleteCountries_3.PNG';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeControlListCountriesHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>Displays an input like the following: </p>
                    <img src={AutocompleteCountries1} alt="Auto complete Countries" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>shows suggestions based on the what the user is typing:</p>
                    <img src={AutocompleteCountries2} alt="Auto complete Countries" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>
                        and lets the user select a country from the auto completed list (but cannot edit the selection)
                    </p>
                    <img src={AutocompleteCountries3} alt="Auto complete Countries" className={styles.modalHelpImage} />
                    <p className={styles.helpNote}>
                        If modification of selection is needed, then Auto complete Countries logic type should be used
                        instead of Control list Countries
                    </p>
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeControlListCountriesHelp;
