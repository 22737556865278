import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Help from '../common/Help';
import ReactDOMServer from 'react-dom/server';
import ResponseWrapper, {IWizardResponseWrapperProps, IWizardResponseWrapperState} from './ResponseWrapper';
import SelectInput from '~components/SelectInput';
import cx from 'classnames';
import {faPen, faPlus} from '@fortawesome/free-solid-svg-icons';
import {getErrorMessageResponseMandatoryField} from './utils';
import {htmlToText} from '../../../utils';
import {injectIntl} from 'react-intl';
import styles from './ResponseCombobox.module.scss';

const STAGE_RESPONSE_VALUE_TO_STAGE_LETTER: {[key: number]: string} = {
    4: 'A',
    5: 'B',
    6: 'C',
    7: 'D',
};

interface IState extends IWizardResponseWrapperState {
    valuesToList: any;
}

class ResponseCombobox extends ResponseWrapper<IWizardResponseWrapperProps, IState> {
    pleaseChooseMessage: '';

    constructor(props: IWizardResponseWrapperProps) {
        super(props);
        this.pleaseChooseMessage = props.intl && props.intl.formatMessage({id: 'pleaseChoose'});
        this.state = {
            ...this.state,
            valuesToList: null,
        };
    }

    componentDidMount() {
        let valuesToList: any = [];
        const {assistedEntries, response} = this.props;
        const {
            countries,
            eo_countries: eoCountries,
            ue_countries: ueCountries,
            species,
            stages,
            stages_dus: stagesDus,
            procedures,
            component_ownership: componentOwnership,
            languages,
        } = assistedEntries || {};
        const {logics} = response || {};
        const getValuesToList = (arr = []) => [
            {id: this.pleaseChooseMessage, value: this.pleaseChooseMessage},
            ...arr.map((el: any) => ({id: el.ID, value: el.LABEL})),
        ];
        if (assistedEntries && Object.keys(assistedEntries).length && logics && logics.length) {
            let logicParam = logics[0].logicParam || logics[0].defaultParam || '';
            let options = [];
            logicParam.includes('countries') && (options = countries);
            logicParam.includes('eo_countries') && (options = eoCountries);
            logicParam.includes('ue_countries') && (options = ueCountries);
            logicParam.includes('species') && (options = species);
            logicParam.includes('stages') && (options = stages);
            logicParam.includes('stages_dus') && (options = stagesDus);
            logicParam.includes('procedures') && (options = procedures);
            logicParam.includes('component_ownership') && (options = componentOwnership);
            logicParam.includes('languages') && (options = languages);
            valuesToList = getValuesToList(options);
        }
        this.setState({
            valuesToList,
        });
        this.checkAndUpdateIfCurrentResponseIsDisabledDueToLogicDisabledOfOtherResponse();
    }

    componentDidUpdate(prevProps: IWizardResponseWrapperProps, prevState: IState) {
        if (this.props.screenData.hash !== prevProps.screenData.hash) {
            this.checkAndUpdateIfCurrentResponseIsDisabledDueToLogicDisabledOfOtherResponse();
        }
    }

    onListChange = ({target: {value: optionValue}}: React.ChangeEvent<HTMLInputElement>) => {
        const updatedResponsesClient = this.getCopyOfResponsesClient();
        const responseKey = this.getResponseKey();
        if (optionValue === this.pleaseChooseMessage) {
            delete updatedResponsesClient[responseKey];
        } else {
            updatedResponsesClient[responseKey] = optionValue;
        }
        this.updateResponsesClient(updatedResponsesClient);
    };

    renderRemedyIcon = () => {
        const remedyType = this.getRemedyType();
        return (
            <div
                className={cx(
                    styles.remedyIconWrap,
                    styles[this.props.question.type],
                    remedyType ? styles[remedyType] : null
                )}
                onClick={this.onButtonAddResponseToRemedyClick}
                id={`REMEDY_${this.getResponseKey()}`}
            >
                <FontAwesomeIcon
                    icon={(this.checkIfRemedyResponseExists() ? faPen : faPlus) as any}
                    color={'white'}
                    width={16}
                    height={10}
                />
            </div>
        );
    };

    renderPDFVersion = () => {
        const {messageRemark} = this.props.response || {};
        let answerValue = (
            (this.state.valuesToList || []).find((el: any) => {
                // el.id can be: 1, "1", "NL"
                if (Number.isInteger(parseInt(el.id))) {
                    return parseInt(el.id) === parseInt(this.getResponseAnswer());
                } else {
                    return el.id === this.getResponseAnswer();
                }
            }) || {}
        ).value;
        // el.id in older version can be "Netherlands" instead of "NL"
        if (!answerValue && this.getResponseAnswer()) {
            answerValue = this.getResponseAnswer();
        }
        if ((window as any).elements) {
            let element: any = {
                type: 'RESPONSE_COMBOBOX',
                label: htmlToText(ReactDOMServer.renderToStaticMarkup(this.getLabelWithHTML())),
                order: this.getResponseOrderForPDF(),
                questionType: this.props.question.type,
            };
            messageRemark && (element.remark = messageRemark);
            let elementExist = (window as any).elements.find((el: any) => el.order === element.order);
            if (!elementExist) {
                (window as any).elements.push(element);
            }
        }

        if (this.props.response.id === 35446 && this.props.question.type === 'table') {
            let responseClientListKey = this.getCopyOfResponsesClient()[this.getResponseKey()];
            if (responseClientListKey) {
                answerValue = STAGE_RESPONSE_VALUE_TO_STAGE_LETTER[responseClientListKey];
            }
        }

        if (
            this.props.question.type === 'table' &&
            this.props.response.logics.length &&
            this.props.response.logics.find(
                el => el?.logicParam?.includes('on:countries') && el.logicParam.includes('combobox:yes')
            )
        ) {
            let responseClientListKey = this.getCopyOfResponsesClient()[this.getResponseKey()];
            if (responseClientListKey) {
                answerValue = responseClientListKey;
            }
        }

        return (
            <>
                {super.render()}
                {this.props.isRemedyStarted && this.renderRemedyIcon()}
                <div className={styles.pdfResponseWrap}>
                    {this.props.isPDFVersionAndQuestionIsTableType ? null : (
                        <div className={styles.pdfResponseWrapLeft}>{this.getLabelWithHTML()}</div>
                    )}
                    <div className={cx(styles.pdfResponseWrapRight, !!answerValue && styles.hasPDFValue)}>
                        {answerValue}
                    </div>
                </div>
            </>
        );
    };

    getFormattedSelectInputList = () => {
        const formattedSelectInputList = [...(this.state.valuesToList || [])];
        if (this.props.response.id === 35446) {
            formattedSelectInputList.forEach((listItem: {id: any; value: string}, index: number) => {
                let letterToPrepend = STAGE_RESPONSE_VALUE_TO_STAGE_LETTER[listItem.id];
                if (letterToPrepend && !formattedSelectInputList[index].value.startsWith(letterToPrepend)) {
                    formattedSelectInputList[
                        index
                    ].value = `${letterToPrepend} - ${formattedSelectInputList[index].value}`;
                }
            });
        }
        return formattedSelectInputList;
    };

    render() {
        if (this.props.PDFVersion) {
            return this.renderPDFVersion();
        }
        const {response} = this.props;
        const {messageRemark} = response || {};
        const outsideLabelWithHTML = this.getLabelWithHTML();
        const hasClickedOnSaveAndValidate = this.checkIfUserHasClickedOnSaveAndValidate();
        const hasMandatoryError =
            hasClickedOnSaveAndValidate && this.checkIfResponseHasMandatoryError(this.props.responseIteration);
        const errorMessage = hasMandatoryError && getErrorMessageResponseMandatoryField({intl: this.props.intl});
        const formattedSelectInputList = this.getFormattedSelectInputList();

        return (
            <div>
                <Help
                    message={response && response.messageHelp}
                    language={this.props.questionnaireLanguage}
                    className="responseHelp"
                />
                <SelectInput
                    outsideLabelWithHTML={outsideLabelWithHTML}
                    value={this.getResponseAnswer() || this.pleaseChooseMessage}
                    onChange={this.onListChange}
                    list={formattedSelectInputList}
                    notAll={true}
                    remark={messageRemark}
                    isRemarkTranslationMissing={this.checkIfResponseRemarkTranslationIsMissing()}
                    isTranslationMissing={this.checkIfTranslationIsMissing()}
                    oldBackOfficeStyles={true}
                    oldBackOfficeStylesError={hasMandatoryError}
                    error={errorMessage}
                    disabled={this.state.isCurrentResponseDisabledDueToLogicDisabledOfOtherResponse}
                />
            </div>
        );
    }
}

export default injectIntl(ResponseCombobox);
