import React from 'react';
import styles from './ModalStatusInfoVersion2.module.scss';
import ModalCustomVersion2 from './ModalCustomVersion2';
import {Button} from '../componentsFormV2';

interface IProps {
    close: any;
    filter?: any;
}

interface IState {}

export default class ModalStatusInfoVersion2 extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    close = () => {
        this.props.close && this.props.close();
    };

    render() {
        return (
            <ModalCustomVersion2
                close={this.close}
                header={`Explanations on registers status`}
                body={
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>
                                    <a>{'Status'}</a>
                                </th>
                                <th>
                                    <a>{'Definition'}</a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!this.props.filter ||
                            this.props.filter.indexOf('P') !== -1 ||
                            this.props.filter.indexOf('M') !== -1 ||
                            this.props.filter.indexOf('U') !== -1 ? (
                                <tr>
                                    <td>Reception ongoing</td>
                                    <td>
                                        <ul style={{marginTop: 0, marginBottom: 0}}>
                                            <li>
                                                <strong>Undefined:</strong>&nbsp;Awaiting reception
                                            </li>
                                            <li>
                                                <strong>Pending major:</strong>&nbsp;Reception process on-going but no
                                                application date given since additional information and/or payment is
                                                required
                                            </li>
                                            <li>
                                                <strong>Pending minor:</strong>&nbsp;Reception process on-going and
                                                provisional application date allocated but additional information and/or
                                                payment is required
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            ) : null}
                            {!this.props.filter || this.props.filter.indexOf('N') !== -1 ? (
                                <tr>
                                    <td>Abandoned application</td>
                                    <td>
                                        The applicant decided to abandon the application (eg. : duplicate application,
                                        application filed too early, or by mistake)
                                    </td>
                                </tr>
                            ) : null}
                            {!this.props.filter || this.props.filter.indexOf('A') !== -1 ? (
                                <tr>
                                    <td>Active application</td>
                                    <td>
                                        Application under proceedings triggering a publication in the CPVO gazette and
                                        the provisional protection
                                    </td>
                                </tr>
                            ) : null}
                            {!this.props.filter || this.props.filter.indexOf('W') !== -1 ? (
                                <tr>
                                    <td>Withdrawn</td>
                                    <td>The applicant decided to withdraw the application</td>
                                </tr>
                            ) : null}
                            {!this.props.filter || this.props.filter.indexOf('R') !== -1 ? (
                                <tr>
                                    <td>Refused</td>
                                    <td>CPVO decided to refuse the application (article 61 on refusal grounds)</td>
                                </tr>
                            ) : null}
                            {!this.props.filter || this.props.filter.indexOf('G') !== -1 ? (
                                <tr>
                                    <td>Granted</td>
                                    <td>Granted Community Plant Variety Right</td>
                                </tr>
                            ) : null}
                            {!this.props.filter || this.props.filter.indexOf('T') !== -1 ? (
                                <tr>
                                    <td>Terminated Right</td>
                                    <td>
                                        Either the holder decided to surrender the right, or CPVO decided to cancel or
                                        nullify the right, or the right expired
                                    </td>
                                </tr>
                            ) : null}
                        </tbody>
                    </table>
                }
                footer={
                    <>
                        <Button variation={'danger'} icon={'close'} clickAction={this.close}>{`Close`}</Button>
                    </>
                }
            />
        );
    }
}
