import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';

const ACTIVE = 2;
function renderButton(popupContent, handler, icon) {
    return {
        title: popupContent,
        icon: rowObject =>
            icon !== faPlusCircle || (icon === faPlusCircle && rowObject.status === ACTIVE) ? (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon} />
                </a>
            ) : (
                <div />
            ),
        handler,
    };
}

export default function BackOfficeTemplatesActionButtons(props, openModalConfirmNewVersion, openModalConfirmDeletion) {
    const buttons = [];
    buttons.push(
        renderButton(
            'New version',
            (templateId, rowObject) => openModalConfirmNewVersion(rowObject.templateId),
            faPlusCircle
        )
    );
    buttons.push(
        renderButton('Delete', (labelId, rowObject) => openModalConfirmDeletion(rowObject.templateId), faTrashAlt)
    );
    return buttons;
}
