import React from 'react';
import cx from 'classnames';
import styles from './ModalCustomVersion2.module.scss';
import HeaderLoading from '../componentsLayout/HeaderLoading';
import ModalAlertVersion2 from './ModalAlertVersion2';
import CloseFill from '../iconComponents/CloseFill';
import {LoadingBar} from '../componentsLayout';

const MODAL_SIZE: any = {
    xl: 'Xl',
    sm: 'Sm',
};

const VARIATIONS = ['alert', 'danger'];

interface IProps {
    close: any;
    closeOnEscapeKeyPress?: boolean;
    plus?: number;
    plus2?: boolean;
    plus3?: boolean;
    loading?: boolean;
    loadingBar?: boolean;
    helpMessage?: any;
    closeHelpMessage?: any;
    children?: any;
    footer?: any;
    body?: any;
    header: any;
    headerSubtitle?: string;
    headerInnerTitlesStyle?: any;
    size?: 'xl' | 'sm';
    style?: any;
    variation?: 'alert' | 'danger';
    zIndexNumber?: number;
}

interface IState {
    apiData: any;
}

export default class ModalCustomVersion2 extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            apiData: {
                version: '',
                changes: [],
            },
        };
    }

    componentDidMount() {
        document.body.style.overflowY = 'hidden';
    }

    componentWillUnmount() {
        document.body.style.overflowY = 'auto';
    }

    close = () => {
        this.props.close();
    };

    handleKeyDown = (event: any) => {
        event = event || window.event;
        let isEscape = false;
        if ('key' in event) {
            isEscape = event.key === 'Escape' || event.key === 'Esc';
        } else {
            isEscape = event.keyCode === 27;
        }
        if (isEscape) {
            this.props.close();
        }
    };

    render() {
        let zIndexNumber = 1040;
        if (this.props.plus) {
            if (this.props.plus === 1) {
                zIndexNumber = 1054;
            } else {
                zIndexNumber = 1060;
            }
        }
        if (this.props.plus2) {
            zIndexNumber = 1160;
        }
        if (this.props.plus3) {
            zIndexNumber = 1260;
        }
        if (this.props.zIndexNumber) {
            zIndexNumber = this.props.zIndexNumber;
        }
        const style = {zIndex: zIndexNumber, ...(this.props.style || {})};

        return (
            <section
                className={styles.modalWrap}
                style={style}
                aria-hidden="true"
                tabIndex={-1}
                onKeyDown={this.handleKeyDown}
            >
                <div
                    className={cx(
                        styles.modal,
                        this.props.plus && styles.plus,
                        this.props.plus2 && styles.plus2,
                        this.props.plus3 && styles.plus3,
                        this.props.size && styles[`modalSize${MODAL_SIZE[this.props.size]}`]
                    )}
                    role="dialog"
                    onKeyDown={this.handleKeyDown}
                >
                    <div className={cx(styles.content, this.props.variation && styles[this.props.variation])}>
                        {this.props.header ? (
                            <header className={styles.header}>
                                <div className={styles.headerInner}>
                                    <div style={this.props.headerInnerTitlesStyle}>
                                        <h2>{this.props.header}</h2>
                                        {this.props.headerSubtitle ? (
                                            <p style={{margin: 0, textAlign: 'left'}}>{this.props.headerSubtitle}</p>
                                        ) : null}
                                    </div>
                                    <button
                                        type="button"
                                        title={'Close'}
                                        className={styles.buttonClose}
                                        onClick={this.close}
                                    >
                                        <CloseFill
                                            color={
                                                VARIATIONS.includes(this.props.variation || '') ? 'white' : undefined
                                            }
                                        />
                                    </button>
                                </div>
                            </header>
                        ) : null}
                        <main className={styles.main}>
                            <>
                                {this.props.loadingBar ? <LoadingBar /> : null}
                                {this.props.body}
                            </>
                        </main>
                        {this.props.children}
                        {this.props.footer ? <footer className={styles.footer}>{this.props.footer}</footer> : null}
                    </div>
                </div>
                {this.props.loading && <HeaderLoading />}
                {this.props.helpMessage && (
                    <ModalAlertVersion2
                        message={this.props.helpMessage}
                        title={`Help`}
                        close={this.props.closeHelpMessage}
                    />
                )}
            </section>
        );
    }
}
