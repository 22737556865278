export const DEFAULT_COLUMNS = [
    {
        name: 'denomination',
        enabled: true,
    },
    {
        name: 'denominationnature',
        enabled: true,
    },
    {
        name: 'specielatinname',
        enabled: true,
    },
    {
        name: 'specieclasscodes',
        enabled: true,
    },
    {
        name: 'specieclasses',
        enabled: false,
    },
    {
        name: 'countryid',
        enabled: true,
    },
    {
        name: 'publicationtype',
        enabled: true,
    },
    {
        name: 'applicationstatus',
        enabled: true,
    },
    {
        name: 'denominationstatus',
        enabled: true,
    },
    {
        name: 'applicationnumber',
        enabled: true,
    },
    {
        name: 'applicationdate',
        enabled: true,
    },
    {
        name: 'grantdate',
        enabled: true,
    },
    {
        name: 'breederreference',
        enabled: true,
    },
    {
        name: 'breedername',
        enabled: true,
    },
    {
        name: 'applicantname',
        enabled: false,
    },
    {
        name: 'pubapplicationdate',
        enabled: false,
    },
    {
        name: 'holdername',
        enabled: false,
    },
    {
        name: 'grantnumber',
        enabled: false,
    },
    {
        name: 'pubgrantdate',
        enabled: false,
    },
    {
        name: 'maintainername',
        enabled: false,
    },
    {
        name: 'varietyinsertdate',
        enabled: false,
    },
    {
        name: 'denominationinsertdate',
        enabled: false,
    },
    {
        name: 'speciesid',
        enabled: false,
    },
    {
        name: 'enddate',
        enabled: false,
    },
    {
        name: 'futureexpirationdate',
        enabled: false,
    },
    {
        name: 'denominationtype',
        enabled: false,
    },
    {
        name: 'proposaldate',
        enabled: false,
    },
    {
        name: 'pubproposaldate',
        enabled: false,
    },
    {
        name: 'approvaldate',
        enabled: false,
    },
    {
        name: 'deno_end_date',
        enabled: false,
    },
    {
        name: 'species_name_en',
        enabled: false,
    },
    {
        name: 'renewalregistration',
        enabled: false,
    },
    {
        name: 'otherparty',
        enabled: false,
    },
    {
        name: 'remark',
        enabled: false,
    },
    {
        name: 'extra_data_url',
        enabled: false,
    },
    {
        name: 'register_name',
        enabled: false,
    },
    {
        name: 'cropsectors',
        enabled: false,
    },
];
