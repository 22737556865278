import React from 'react';
import {Button} from '../../componentsFormV2';
import CustomTable from '~components/CustomTable';
import Error from '~components/Error';
import FileInput from '~components/FileInput';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import {ModalAlertVersion2} from '../../commonModals';
import NavigationVarietyFinder from '../../shared/NavigationVarietyFinder';
import SelectInput from '~components/SelectInput';
import TextAreaInput from '~components/TextAreaInput';
import {convertDate, downloadUrl, getUserId, GLOBAL_DATETIME_FORMAT} from '../../utils/utilsVarietyFinder';
import {createSubscriptionFromValues} from './VFContributorsPortalService';
import graphQLClientInstance from '../../utils/axiosGraphQLClient';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {trackPageView} from '../../utils';
import styles from '../VFTestDenominations/VFTestDenominations.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRedo} from '@fortawesome/free-solid-svg-icons';

const RESULT_FIELDS_ALL = ['country', 'uploadDate', 'uploader', 'fileName', 'comment'];

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    count: number;
    isModalAlertFileUploadSuccess: boolean;
    isModalAlertFileUploadError: boolean;
    isModalAlertFileNotReadyOpen: boolean;
    loading: number;
    selectedFile: any;
    selectedFileOverMaxAllowedError: boolean;
    selectedFileError: string;
    screenLoaded: boolean;
    selectInputCountriesError: string;
    selectInputCountriesList: any;
    selectInputCountriesValue: any;
    textAreaInputCommentValue: string;
    tableResults: any[];
    timestamp: number;
}

class VFContributorsPortal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            count: 0,
            isModalAlertFileUploadSuccess: false,
            isModalAlertFileUploadError: false,
            isModalAlertFileNotReadyOpen: false,
            loading: 0,
            selectedFile: null,
            selectedFileError: '',
            selectedFileOverMaxAllowedError: false,
            screenLoaded: false,
            selectInputCountriesError: '',
            selectInputCountriesValue: 'select',
            selectInputCountriesList: [{id: 'select', value: 'Select'}],
            textAreaInputCommentValue: '',
            tableResults: [],
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'Contributions'});
        window.scrollTo(0, 0);
        this.loadJSONs();
    }

    responseDataMapTableResults = ({
        country_id: country,
        file_comment: comment,
        file_name: fileName,
        uploader_name: uploader,
        upload_date: uploadDate,
        download_ready: downloadReady,
        file_path: filePath,
        ...rest
    }: any) => ({
        country,
        comment,
        uploader,
        uploadDate: convertDate(uploadDate * 1000, GLOBAL_DATETIME_FORMAT),
        fileName,
        downloadReady,
        filePath,
        ...rest,
    });

    loadJSONs() {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                Promise.all([
                    graphQLClientInstance.get('/api/v1/contributions'),
                    graphQLClientInstance.get('/api/v1/contributions/countries'),
                ]).then(([JSONResponseContributions, JSONResponseContributionsCountries]: any) => {
                    const stateObject: any = {
                        screenLoaded: true,
                    };
                    if (
                        JSONResponseContributions &&
                        JSONResponseContributions.data &&
                        JSONResponseContributions.data.data
                    ) {
                        stateObject.tableResults = JSONResponseContributions.data.data.map(
                            this.responseDataMapTableResults
                        );
                        stateObject.count = JSONResponseContributions.data.total;
                    }
                    if (
                        JSONResponseContributionsCountries &&
                        JSONResponseContributionsCountries.data &&
                        Array.isArray(JSONResponseContributionsCountries.data)
                    ) {
                        stateObject['selectInputCountriesList'] = [
                            ...this.state.selectInputCountriesList,
                            ...JSONResponseContributionsCountries.data.map(
                                ({country_id: countryId, country_name: countryName}: any) => ({
                                    id: countryId,
                                    value: countryName,
                                })
                            ),
                        ];
                    }
                    this.setState(prev => ({
                        ...stateObject,
                        loading: prev.loading - 1,
                    }));
                });
            }
        );
    }

    resetCriteria = () =>
        this.setState({
            selectedFile: null,
            selectedFileError: '',
            selectedFileOverMaxAllowedError: false,
            selectInputCountriesError: '',
            selectInputCountriesValue: 'select',
            textAreaInputCommentValue: '',
        });

    closeModalAlertFileNotReady = () => this.setState({isModalAlertFileNotReadyOpen: false});

    closeModalAlertFileUploadedSuccess = () => this.setState({isModalAlertFileUploadSuccess: false});

    closeModalAlertFileUploadedError = () => this.setState({isModalAlertFileUploadError: false});

    tableNavigationButtons = () => (
        <div className={styles.navigationBarV2LeftItem} onClick={this.onTableNavigationButtonRefreshClick}>
            <FontAwesomeIcon style={{float: 'left'}} icon={faRedo as any} />
            <div
                style={{
                    float: 'left',
                    paddingLeft: 5,
                }}
            >
                {`Refresh`}
            </div>
            <div style={{clear: 'both'}} />
        </div>
    );

    onHeaderSearchLinkClick = (searchListItem: any) => {
        this.props.history.push({
            pathname: '/varieties',
            state: {searchListItem},
        });
    };

    onLinkButtonHelpClick = () => window.open('help/en/Contributor_portal.html', '_blank');

    onTextAreaCommentChange = ({
        target: {value: textAreaInputCommentValue},
    }: React.ChangeEvent<HTMLTextAreaElement>): void =>
        this.setState(prev => ({
            textAreaInputCommentValue:
                textAreaInputCommentValue.length <= 1000 ? textAreaInputCommentValue : prev.textAreaInputCommentValue,
        }));

    onSelectInputCountryChange = ({target: {value: selectInputCountriesValue}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.setState({selectInputCountriesValue});

    onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({selectedFileOverMaxAllowedError: false, selectedFile: null}, () => {
            if (event.target.files !== null && event.target.files.length > 0) {
                const files = event.target.files;
                const selectedFile = files[0];
                if (selectedFile.size > 52428800) {
                    //50MB
                    this.setState({selectedFileOverMaxAllowedError: true});
                } else {
                    this.setState({selectedFile});
                }
            }
        });
    };

    onTableNavigationButtonRefreshClick = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                graphQLClientInstance.get('/api/v1/contributions').then(JSONResponseContributions => {
                    if (
                        JSONResponseContributions &&
                        JSONResponseContributions.data &&
                        JSONResponseContributions.data.data
                    ) {
                        this.setState(prev => ({
                            tableResults: JSONResponseContributions.data.data.map(this.responseDataMapTableResults),
                            loading: prev.loading - 1,
                            timestamp: Date.now(),
                        }));
                    }
                });
            }
        );

    onTableColumnDataFileNameClick = ({downloadReady, filePath, fileName, ...rest}: any) => {
        if (downloadReady !== 1) {
            return this.setState({isModalAlertFileNotReadyOpen: true});
        }
        graphQLClientInstance
            .post('/api/v1/contributions/file', {
                key: `${filePath?.substring(filePath?.indexOf('/') + 1)}/${fileName}`,
            })
            .then(JSONResponse => {
                if (JSONResponse && JSONResponse.data && JSONResponse.data.url) {
                    downloadUrl(JSONResponse.data.url);
                }
            });
    };

    onButtonCreateClick = () => {
        this.setState({selectInputCountriesError: '', selectedFileError: ''}, () => {
            if (
                !this.state.selectInputCountriesValue ||
                this.state.selectInputCountriesValue === 'select' ||
                !this.state.selectedFile
            ) {
                return this.setState(prev => ({
                    selectedFileError: !prev.selectedFile ? 'Field is required!' : '',
                    selectInputCountriesError:
                        !prev.selectInputCountriesValue || prev.selectInputCountriesValue === 'select'
                            ? 'Field is required!'
                            : '',
                }));
            }
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    graphQLClientInstance
                        .post(
                            '/api/v1/contributions',
                            createSubscriptionFromValues({
                                countryid: this.state.selectInputCountriesValue,
                                filename: this.state.selectedFile.name,
                                comment: this.state.textAreaInputCommentValue,
                            })
                        )
                        .then(JSONResponse => {
                            if (JSONResponse && JSONResponse.data && JSONResponse.data.url) {
                                const uploadData = new FormData();
                                for (const [fieldKey, fieldValue] of Object.entries(JSONResponse.data?.fields)) {
                                    uploadData.append(fieldKey, fieldValue as string);
                                }
                                uploadData.append('X-Amz-Meta-country_id', this.state.selectInputCountriesValue);
                                uploadData.append('X-Amz-Meta-user_id', getUserId());
                                uploadData.append('X-Amz-Meta-comment', JSONResponse.data.comment);
                                uploadData.append('file', this.state.selectedFile);

                                graphQLClientInstance
                                    .post(JSONResponse.data.url, uploadData, {
                                        headers: {
                                            'Content-Type': 'multipart/form-data',
                                        },
                                    })
                                    .then(() => {
                                        this.setState(
                                            prev => ({
                                                loading: prev.loading - 1,
                                                isModalAlertFileUploadSuccess: true,
                                            }),
                                            () => this.resetCriteria()
                                        );
                                    })
                                    .catch(() => {
                                        this.setState(prev => ({
                                            loading: prev.loading - 1,
                                            isModalAlertFileUploadError: true,
                                        }));
                                    });
                            }
                        });
                }
            );
        });
    };

    render() {
        return (
            <>
                {this.state.loading ? <HeaderLoading /> : null}
                {this.state.isModalAlertFileUploadSuccess ? (
                    <ModalAlertVersion2
                        title={`Info`}
                        message={'Your file has been successfully uploaded.'}
                        close={this.closeModalAlertFileUploadedSuccess}
                    />
                ) : null}
                {this.state.isModalAlertFileUploadError ? (
                    <ModalAlertVersion2
                        title={`Info`}
                        message={`Something went wrong. Please try again later`}
                        close={this.closeModalAlertFileUploadedError}
                    />
                ) : null}
                {this.state.isModalAlertFileNotReadyOpen ? (
                    <ModalAlertVersion2
                        title={`Info`}
                        message={'File is not available for download. If you want this file please contact CPVO.'}
                        close={this.closeModalAlertFileNotReady}
                    />
                ) : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Variety Finder`} hostApplication={'Variety Finder'} />
                <NavigationVarietyFinder onSearchLinkClick={this.onHeaderSearchLinkClick} />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'} formInnerWidth={'lg'}>
                        <SelectInput
                            label={`Country`}
                            value={this.state.selectInputCountriesValue}
                            notAll={true}
                            onChange={this.onSelectInputCountryChange}
                            list={this.state.selectInputCountriesList}
                            popOverTextFirstRow={`The country for which you are uploading a contribution.`}
                            errorVarietyFinder={this.state.selectInputCountriesError}
                            size={'lg'}
                        />
                        <FileInput
                            label={`Contribution File`}
                            popOverTextFirstRow={`Select a file to upload`}
                            onFileUpload={this.onFileUpload}
                            errorVarietyFinder={this.state.selectedFileError}
                            fileName={
                                this.state.selectedFile && this.state.selectedFile.name
                                    ? this.state.selectedFile.name
                                    : ''
                            }
                            size={'lg'}
                        />
                        <div>
                            <TextAreaInput
                                label="Comment"
                                onChange={this.onTextAreaCommentChange}
                                value={this.state.textAreaInputCommentValue}
                                rows={3}
                                triple={true}
                                maxCounter={1000}
                                counter={this.state.textAreaInputCommentValue.length}
                                popOverTextFirstRow={`A comment for CPVO in relation with this contribution.`}
                                size={'lg'}
                            />
                        </div>
                        <div style={{clear: 'both'}} />
                        {this.state.selectedFileOverMaxAllowedError ? (
                            <div>
                                <Error>{`Max size allowed: 50MB`}</Error>
                                <div style={{clear: 'both'}} />
                            </div>
                        ) : null}
                        <FormFooter>
                            <Button clickAction={this.onLinkButtonHelpClick} variation={'secondary'}>
                                {'Help'}
                            </Button>
                            <Button clickAction={this.resetCriteria} variation={'secondary'}>{`Clear fields`}</Button>
                            <Button clickAction={() => this.onButtonCreateClick()}>{`Create`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    <div style={{clear: 'both'}} />
                    {this.state.screenLoaded ? (
                        <CustomTable
                            additionalNavigationButtons={this.tableNavigationButtons}
                            count={this.state.count}
                            formatFunctions={{
                                fileName: (fileName: string, rowObject: any) => {
                                    return (
                                        <div
                                            onClick={() => this.onTableColumnDataFileNameClick(rowObject)}
                                            style={{
                                                color: '#255899',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {fileName}
                                        </div>
                                    );
                                },
                            }}
                            id={'uploadDate'}
                            intl={this.props.intl}
                            isNavigationButtonCompactOrDefaultViewEnabled={true}
                            notSortable={RESULT_FIELDS_ALL}
                            resultFieldsDefault={RESULT_FIELDS_ALL}
                            tableName={`varietyFinderContributions`}
                            tableType={'OBJECT'}
                            hideExcelButton={true}
                            tableSource={this.state.tableResults}
                            timestamp={this.state.timestamp}
                            noShowingPageNumber={true}
                            noChangePageSize={true}
                            version={2}
                            hoverGrey={true}
                            navigationBarWider={true}
                            fontSize={14}
                        />
                    ) : null}
                    <div style={{clear: 'both'}} />
                </MainWrapper>
                <Footer hostApplication={'Variety Finder'} />
            </>
        );
    }
}

export default withRouter(injectIntl(VFContributorsPortal));
