import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArchive, faReply, faList} from '@fortawesome/free-solid-svg-icons';
import {ICommunicationMessageClient} from '../../types';
import {faCheckCircle} from '@fortawesome/free-regular-svg-icons';
import stylesCustomTable from '../OnlineApplications/customTable/OnlineApplicationsCustomTableActionButtons.module.scss';

function renderButton(
    popupContent: string,
    handler: (id: any, rowObject: ICommunicationMessageClient) => void,
    icon: any
) {
    return {
        title: popupContent,
        icon: () => {
            return (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon} />
                </a>
            );
        },
        handler,
    };
}

function renderIconCheck(onTableIconCheckClick: (rowObject: ICommunicationMessageClient) => void) {
    return {
        title: null,
        icon: (rowObject: ICommunicationMessageClient) => {
            return (
                <div
                    className={stylesCustomTable.iconWrap}
                    title={rowObject.isTicked ? 'Un-tick message' : 'Tick message'}
                >
                    <FontAwesomeIcon icon={faCheckCircle as any} color={rowObject.isTicked ? 'gray' : 'green'} />
                </div>
            );
        },
        handler: (_: any, rowObject: ICommunicationMessageClient) => {
            return onTableIconCheckClick(rowObject);
        },
    };
}

export default function CCClientInboxActionButtons(
    onTableIconReplyClick: (rowObject: ICommunicationMessageClient) => void,
    onTableIconArchiveClick: (communicationId: number) => void,
    onTableIconHistoryClick: (rowObject: ICommunicationMessageClient) => void,
    onTableIconCheckClick: (rowObject: ICommunicationMessageClient) => void
) {
    const buttons: any = [];
    buttons.push(renderButton('Reply', (_, rowObject) => onTableIconReplyClick(rowObject), faReply));
    buttons.push(renderIconCheck(onTableIconCheckClick));
    buttons.push(renderButton('Archive', communicationId => onTableIconArchiveClick(communicationId), faArchive));
    buttons.push(renderButton('History', (_, rowObject) => onTableIconHistoryClick(rowObject), faList));
    return buttons;
}
