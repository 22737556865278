import React from 'react';

// ICONS
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faEdit,
    faAngleDoubleUp,
    faChevronUp,
    faChevronDown,
    faAngleDoubleDown,
    faTrashAlt,
    IconDefinition,
    faChevronRight,
    faChevronLeft,
    faPlusSquare,
} from '@fortawesome/free-solid-svg-icons';

// INTERFACE
import {TreeElement} from '../Interfaces/TreeViewInterfaces';

// ENV
import {TLO_LIST_OF_TLOS_TREE_VALUE} from '../../../../../commonApi';

function renderButton(
    popupContent: string,
    handler: (id: number, rowObject: TreeElement) => void,
    icon: IconDefinition,
    treeViewList: Array<TreeElement>,
    isACArea?: boolean
) {
    const arrowJsx = (popupContent: string) => (
        <a data-content={popupContent}>
            <FontAwesomeIcon icon={icon as any} />
        </a>
    );
    return {
        title: popupContent,
        icon: (rowObject: TreeElement) => {
            const uniqueElement = treeViewList.length === 1;
            const isRootParent = rowObject.level === 1;
            const rootParents = treeViewList.filter(el => el.level === 1);
            const currentRootParentIdx = rootParents.findIndex(el => el.id === rowObject.id);
            const isLastRootParent = currentRootParentIdx === rootParents.length - 1;
            const isFirstRootParent = currentRootParentIdx === 0;
            const isFirstElement = treeViewList.findIndex(el => el.id === rowObject.id) === 0;
            const isLastElement = treeViewList.findIndex(el => el.id === rowObject.id) === treeViewList.length - 1;
            const parentOfChild = treeViewList.find(el => el.id === rowObject.parentId);
            const parentHasOnlyOneChild = parentOfChild
                ? parentOfChild.children.length === 1
                : rootParents.length === 1;
            const isFirstButNotOnlyChild = parentOfChild
                ? parentOfChild.children.length > 1 &&
                  parentOfChild.children.findIndex(el => el.id === rowObject.id) === 0
                : rootParents.length > 1 && rootParents.findIndex(el => el.id === rowObject.id) === 0;
            const isLastButNotOnlyChild = parentOfChild
                ? parentOfChild.children.length > 1 &&
                  parentOfChild.children.findIndex(el => el.id === rowObject.id) === parentOfChild.children.length - 1
                : rootParents.length > 1 &&
                  rootParents.findIndex(el => el.id === rowObject.id) === rootParents.length - 1;
            const isCategory = rowObject.isCategory;
            const isListOfTLOs = rowObject.title === TLO_LIST_OF_TLOS_TREE_VALUE;

            switch (icon) {
                case faChevronLeft:
                    return isACArea ? null : isFirstElement || uniqueElement || isRootParent || isCategory ? (
                        <div></div>
                    ) : (
                        arrowJsx(popupContent)
                    );
                case faChevronRight:
                    return isACArea ? null : isFirstRootParent ||
                      uniqueElement ||
                      parentHasOnlyOneChild ||
                      isFirstButNotOnlyChild ||
                      isCategory ? (
                        <div></div>
                    ) : (
                        arrowJsx(popupContent)
                    );
                case faAngleDoubleUp:
                    return isACArea ? null : isFirstElement || uniqueElement || !isRootParent ? (
                        <div></div>
                    ) : (
                        arrowJsx(popupContent)
                    );
                case faChevronUp:
                    return isACArea ? null : isFirstElement ||
                      uniqueElement ||
                      parentHasOnlyOneChild ||
                      isFirstButNotOnlyChild ? (
                        <div></div>
                    ) : (
                        arrowJsx(popupContent)
                    );
                case faChevronDown:
                    return isACArea ? null : isLastElement ||
                      uniqueElement ||
                      parentHasOnlyOneChild ||
                      isLastButNotOnlyChild ? (
                        <div></div>
                    ) : (
                        arrowJsx(popupContent)
                    );
                case faAngleDoubleDown:
                    return isACArea ? null : isLastElement || uniqueElement || !isRootParent || isLastRootParent ? (
                        <div></div>
                    ) : (
                        arrowJsx(popupContent)
                    );
                case faPlusSquare:
                    return (isACArea && !rowObject.isContent && !rowObject.isContentItem) || isListOfTLOs ? (
                        <div></div>
                    ) : (
                        arrowJsx(popupContent)
                    );
                case faEdit:
                case faTrashAlt:
                    return (isACArea && !rowObject.isContent && !rowObject.isContentItem && rowObject.level > 3) ||
                        isListOfTLOs ? (
                        <div></div>
                    ) : (
                        arrowJsx(popupContent)
                    );
                default:
                    return arrowJsx(popupContent);
            }
        },
        handler,
    };
}

export default function TreeViewActionButtons(
    moveToPreviousLevel: (rowObject: TreeElement) => void,
    moveToNextLevel: (rowObject: TreeElement) => void,
    moveToFirstPosition: (rowObject: TreeElement) => void,
    moveToPreviousPosition: (rowObject: TreeElement) => void,
    moveToNextPosition: (rowObject: TreeElement) => void,
    moveToLastPosition: (rowObject: TreeElement) => void,
    openModalDocumentForAddition: (rowObject: TreeElement) => void,
    openModalCategoryForModify: (rowObject: TreeElement) => void,
    openModalDocumentForModify: (rowObject: TreeElement) => void,
    openModalConfirmDeletion: (rowObject: TreeElement) => void,
    openModalContentItemForAddition: (rowObject: TreeElement) => void,
    openModalContentDocumentForAddition: (rowObject: TreeElement) => void,
    openModalContentForModify: (rowObject: TreeElement) => void,
    openModalContentItemForModify: (rowObject: TreeElement) => void,
    openModalContentDocumentForModify: (rowObject: TreeElement) => void,
    treeViewList: Array<TreeElement> | null,
    isACArea?: boolean
) {
    const buttons = [];
    if (treeViewList !== null) {
        buttons.push(
            renderButton(
                'Move to previous level',
                (_id: number, rowObject: TreeElement) => moveToPreviousLevel(rowObject),
                faChevronLeft,
                treeViewList,
                isACArea
            )
        );
        buttons.push(
            renderButton(
                'Move to next level',
                (_id: number, rowObject: TreeElement) => moveToNextLevel(rowObject),
                faChevronRight,
                treeViewList,
                isACArea
            )
        );
        buttons.push(
            renderButton(
                'Move to first position',
                (_id: number, rowObject: TreeElement) => moveToFirstPosition(rowObject),
                faAngleDoubleUp,
                treeViewList,
                isACArea
            )
        );
        buttons.push(
            renderButton(
                'Move to previous position',
                (_id: number, rowObject: TreeElement) => moveToPreviousPosition(rowObject),
                faChevronUp,
                treeViewList,
                isACArea
            )
        );
        buttons.push(
            renderButton(
                'Move to next position',
                (_id: number, rowObject: TreeElement) => moveToNextPosition(rowObject),
                faChevronDown,
                treeViewList,
                isACArea
            )
        );
        buttons.push(
            renderButton(
                'Move to last position',
                (_id: number, rowObject: TreeElement) => moveToLastPosition(rowObject),
                faAngleDoubleDown,
                treeViewList,
                isACArea
            )
        );
        buttons.push(
            renderButton(
                isACArea ? `New Child` : `New Child (Document)`,
                (_id: number, rowObject: TreeElement) => {
                    isACArea
                        ? rowObject.isContent
                            ? openModalContentItemForAddition(rowObject)
                            : openModalContentDocumentForAddition(rowObject)
                        : openModalDocumentForAddition(rowObject);
                },
                faPlusSquare,
                treeViewList,
                isACArea
            )
        );
        buttons.push(
            renderButton(
                `Modify`,
                (_id: number, rowObject: TreeElement) => {
                    isACArea
                        ? rowObject.isContent
                            ? openModalContentForModify(rowObject)
                            : rowObject.isContentItem
                            ? openModalContentItemForModify(rowObject)
                            : openModalContentDocumentForModify(rowObject)
                        : rowObject.isCategory
                        ? openModalCategoryForModify(rowObject)
                        : openModalDocumentForModify(rowObject);
                },
                faEdit,
                treeViewList,
                isACArea
            )
        );
        buttons.push(
            renderButton(
                `Delete`,
                (_id: number, rowObject: TreeElement) => {
                    openModalConfirmDeletion(rowObject);
                },
                faTrashAlt,
                treeViewList,
                isACArea
            )
        );
    }

    return buttons;
}
