import React, {useState} from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import RadioButton from '~components/RadioButton';
import {Button} from '../../../componentsFormV2';

const OPTION_VALUES = {
    YES: 'value:1',
    NO: 'value:0',
};

const ModalLogicTypeDefaultValueForConfidentialOption = ({
    close,
    onCreateOrUpdateLogicType,
    responseLogicValues,
    responseLogicType,
    iteration,
}) => {
    const {logicParam, applyToRemark} = responseLogicValues || {};
    const {defaultParam, name} = responseLogicType || {};
    const [paramValue, setParamValue] = useState(logicParam !== undefined ? logicParam : defaultParam);

    const onRadioClick = value => setParamValue(value);

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <div className={styles.modalBody}>
                    <RadioButton
                        radioButtonList={[
                            {
                                description: 'Yes',
                                value: OPTION_VALUES.YES,
                            },
                            {description: 'No', value: OPTION_VALUES.NO},
                        ]}
                        groupName={'Default value for confidential option'}
                        value={paramValue}
                        clickEvent={onRadioClick}
                        triple={true}
                    />
                </div>
            }
            footer={
                <>
                    <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                    <Button
                        icon={'arrowRight'}
                        clickAction={() =>
                            onCreateOrUpdateLogicType({
                                param: paramValue,
                                applyToRemark: applyToRemark !== undefined ? applyToRemark : false,
                                responseIdDest: null,
                                questionIdDest: null,
                                messageId: null,
                                iteration,
                            })
                        }
                    >{`Save`}</Button>
                </>
            }
        />
    );
};

export default ModalLogicTypeDefaultValueForConfidentialOption;
