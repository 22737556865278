import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export function apiCommunicationCentreInternalSentSearch(
    criteria: any,
    DEFAULT_CRITERIA: any = {},
    excelTranslations?: boolean
) {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/communicationCentreInternal/v4/CCISS/sent/search?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}

export function apiCommunicationCentreInternalSentClientsList() {
    return getFetch(`${API_SERVERLESS}/communicationCentreInternal/v4/CCISCL/sent/clientsList`, true);
}

export function apiCommunicationCentreInternalSentMessageArchive(communicationIds: number[]) {
    return postFetch(`${API_SERVERLESS}/communicationCentreInternal/v4/CCISMA/sent/messageArchive`, true, {
        communicationIds,
    });
}
