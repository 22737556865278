import React from 'react';
import styles from './ModalCustomVersion3.module.scss';
import cx from 'classnames';
import ModalBackgroundVersion2 from './ModalBackgroundVersion2';

interface IProps {
    close: () => any;
    header: any;
    body: any;
    bodyStyle?: {[key: string]: any};
    footer: any;
    plus?: number;
    isPreviewMode: boolean;
    HTMLColumnRight?: any;
}

interface IState {}

export default class ModalCustomVersion3 extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        document.body.style.overflowY = 'hidden';
    }

    componentWillUnmount() {
        document.body.style.overflowY = 'auto';
    }

    render() {
        return (
            <>
                <ModalBackgroundVersion2 plus={this.props.plus || 1} />
                <section
                    className={cx(
                        styles.modalFullScreenTwoColumnWrap,
                        this.props.isPreviewMode ? styles.modalWrapModePreview : styles.modalWrapModeDefault,
                        this.props.HTMLColumnRight ? styles.hasHTMLColumnRight : null
                    )}
                >
                    <div
                        className={cx(
                            styles.modalFullScreenTwoColumnLeft,
                            this.props.isPreviewMode ? styles.modalModePreview : styles.modalModeDefault
                        )}
                    >
                        <div className={styles.modalCustomContainedWrap}>
                            <header>
                                <h2>{this.props.header}</h2>
                            </header>
                            <div className={styles.modalCustomContainedBody} style={{...(this.props.bodyStyle || {})}}>
                                {this.props.body}
                            </div>
                            <div className={styles.modalCustomContainedFooter}>{this.props.footer}</div>
                        </div>
                    </div>
                    <div className={styles.modalFullScreenTwoColumnRight}>
                        {this.props.HTMLColumnRight ? this.props.HTMLColumnRight : null}
                    </div>
                    {this.props.isPreviewMode ? null : (
                        <button className={styles.closeIcon} onClick={this.props.close} title={`Close`} type="button" />
                    )}
                </section>
                {this.props.isPreviewMode ? (
                    <button
                        className={styles.closeIconFixed}
                        onClick={this.props.close}
                        title={`Close`}
                        type="button"
                    />
                ) : null}
            </>
        );
    }
}
