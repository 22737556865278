import React from 'react';
import {HeaderCookies, HeaderLogo} from '../../componentsLayout';
import {trackPageView} from '../../utils';

export default class Cookies extends React.Component {
    login = '';
    password = '';

    constructor(props) {
        super(props);
        this.state = {
            enableButton: false,
            rememberIt: false,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'cookies'});
    }

    render() {
        return (
            <React.Fragment>
                <HeaderCookies />
                <HeaderLogo />
                <div style={{padding: 20}}>
                    <p>
                        <span>
                            To make this site work properly, we sometimes place small data files called cookies on your
                            device. Most big websites do this too.
                        </span>
                    </p>
                    <h3>
                        <span>What are cookies?</span>
                    </h3>
                    <p>
                        <span>{`A cookie is a small text file that a website saves on your computer or mobile device when you visit the site. It enables the website to remember your actions and preferences (such as login, language, font size and other display preferences) over a period of time, so you don't have to keep re-entering them whenever you come back to the site or browse from one page to another.`}</span>
                    </p>
                    <h3>
                        <span>How do we use cookies?</span>
                    </h3>
                    <p>
                        <span>A number of our pages use cookies to remember:</span>
                    </p>
                    <ul>
                        <li>
                            <span>your session identification</span>
                        </li>
                        <li>
                            <span>
                                your display preferences, such as font size or number of results per page when you use
                                our search engines
                            </span>
                        </li>
                        <li>
                            <span>{`if you have already replied to a survey pop-up that asks you if the content was helpful or not (so you won't be asked again)`}</span>
                        </li>
                        <li>
                            <span>if you have agreed (or not) to our use of cookies on this site</span>
                        </li>
                    </ul>
                    <p>
                        <span>
                            Also, we use cookies to anonymously gather statistics on the usage of the website (i.e. the
                            number of page views, how many files are downloaded...). However, all the gathered
                            information is only processed for statistics on the usage of the website, and will not be
                            shared with any thirdparties for any other purposes.
                        </span>
                    </p>
                    <p>
                        <span>
                            Enabling these cookies is not strictly necessary for the website to work but it will provide
                            you with a better browsing experience. You can delete or block these cookies, but if you do
                            that some features of this site may not work as intended.
                        </span>
                    </p>
                    <p>
                        <span>The cookie-related information </span>
                        <strong>is not used to identify you</strong>
                        <span>
                            {' '}
                            personally and the pattern data is fully under our control. These cookies are not used for
                            any purpose other than those described here.
                        </span>
                    </p>
                    <h3>
                        <span>How to control cookies</span>
                    </h3>
                    <p>
                        <span>You can </span>
                        <strong>control and/or delete</strong>
                        <span> cookies as you wish - for details, see </span>
                        <a href="http://www.aboutcookies.org/" title="Learn more about cookies.">
                            aboutcookies.org
                        </a>
                        <span>
                            . You can delete all cookies that are already on your computer and you can set most browsers
                            to prevent them from being placed. If you do this, however, you may have to manually adjust
                            some preferences every time you visit a site and some services and functionalities may not
                            work.
                        </span>
                        <br />
                    </p>
                </div>
            </React.Fragment>
        );
    }
}
