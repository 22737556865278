import React from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
// import {apiChangePassword} from './TermsAndConditionsService';
import {apiChangeSite} from '~commonApi/change';
import {apiHome} from '~commonApi/welcome';
import jwtDecode from 'jwt-decode';
import {
    Footer,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
} from '../../componentsLayout';
import {Button} from '../../componentsFormV2';
import {ModalErrorVersion2} from '../../commonModals';
import {trackPageView} from '../../utils';

const SITE_ARRAY = ['WEBOA'];
const ERROR_TITLE_ACCEPT_AND_CONDITIONS = 'Error accepting T&C';
const ERROR_MESSAGE_ACCEPT_AND_CONDITIONS = 'There is an error accepting the Terms and Conditions';

class TermsAndConditions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {errorMessageTermsAndConditions: false, loading: 0};
    }

    componentDidMount() {
        trackPageView({documentTitle: 'TermsAndConditions'});
        self.setStateFunction = this.setStateFunction;
        self.defaultState = this.state;
    }

    setStateFunction = state => this.setState(state);

    goToWelcome = () => this.props.history.push('/welcome');

    acceptTermsAndConditions = () => {
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                apiHome(false)
                    .then(response => {
                        if (response && response.sites) {
                            this.setState({sites: response.sites}, () => {
                                const remember = localStorage.getItem('remember') === '1';
                                const authToken = (remember ? localStorage : sessionStorage).getItem('token');
                                if (authToken && jwtDecode(authToken).impersonated) {
                                    const remember = localStorage.getItem('remember') === '1';
                                    (remember ? localStorage : sessionStorage).setItem('IMPERSONATED', 'true');
                                    this.goToWelcome();
                                } else {
                                    (response.sites || []).map(site => {
                                        const pendingToApproveSites = [];
                                        if (SITE_ARRAY.indexOf(site.code) !== -1 && !site.TC) {
                                            site.insertTC = true;
                                            pendingToApproveSites.push(
                                                new Promise((resolve, reject) => {
                                                    apiChangeSite(site).then(jsonResponse => {
                                                        if (jsonResponse && jsonResponse.data === 'OK') {
                                                            resolve();
                                                        } else {
                                                            this.setState({errorMessageTermsAndConditions: true}, () =>
                                                                reject('Error accepting T&C')
                                                            );
                                                        }
                                                    });
                                                })
                                            );
                                        }
                                        if (pendingToApproveSites.length > 0) {
                                            Promise.all(pendingToApproveSites).then(() => {
                                                this.goToWelcome();
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );
    };

    closeError = () => {
        this.setState({errorMessageTermsAndConditions: false});
    };

    render() {
        return (
            <>
                {this.state.errorMessage ? (
                    <ModalErrorVersion2
                        title={ERROR_TITLE_ACCEPT_AND_CONDITIONS}
                        message={ERROR_MESSAGE_ACCEPT_AND_CONDITIONS}
                        close={this.closeError}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Terms and Conditions`} />
                <FormWrapper paddingFormContent={'sm'}>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            clickAction={this.acceptTermsAndConditions}
                        >{`I accept the Terms and Conditions`}</Button>
                    </div>

                    <iframe
                        src={'assets/TC/TC_V4.0.pdf'}
                        style={{
                            width: '100%',
                            padding: 0,
                            margin: 0,
                            border: 0,
                            height: 1200,
                            marginTop: 50,
                        }}
                    />
                </FormWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(withRouter(TermsAndConditions));
