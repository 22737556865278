import React from 'react';
import {injectIntl} from 'react-intl';
import {loadUrlParams as loadUrlParamsFn} from '~utils';
import {apiSpecies} from '~commonApi/static';
import CustomTable from '~components/CustomTable';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import TextInput from '~components/TextInput';
import {faInfo} from '@fortawesome/free-solid-svg-icons';
import {apiFormSearch, apiPublicSearchFormsDownloadPDF} from './PublicTQSearchService';
import TextLabelInput from '~components/TextLabelInput';
import {FORMAT_DATE_EASY} from '~components/FormatFunctions';
import {saveAs} from 'file-saver';
import styles from './PublicTQSearch.module.scss';
import {Button} from '../../componentsFormV2';
import Navigation from '../../componentsLayout/Navigation';
import {addDaysToDate, trackPageView} from '../../utils';
import {formatDateEasy} from '../../components/FormatFunctions';

const ADVANCED_CRITERIA = [
    'formName',
    'formId',
    'expert',
    'versionCode',
    'proposalDate',
    'versionDate',
    'origin',
    'source',
    'speciesType',
    'readyXML',
];

const DEFAULT_RESULT_FIELDS = ['versionCode', 'commonName', 'formName', 'validFrom', 'languages'];

export type TDefaultCriteria = typeof DEFAULT_CRITERIA;

type TKeyOfDefaultCriteria = keyof TDefaultCriteria;

const DEFAULT_CRITERIA = {
    commonName: '',
    speciesName: '',
    speciesIds: '',
    type: '',
    status: '',
    formName: '',
    formId: '',
    expert: 'All',
    versionCode: '',
    proposalDate: '',
    versionDate: '',
    origin: '0',
    source: '0',
    speciesType: '0',
    readyXML: '0',
    pageNumber: 1,
    pageSize: 10,
    order: null,
    reverse: false,
    refresh: false,
};

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    count: number;
    criteria: any;
    criteriaCount: number;
    form: any;
    forms: any;
    generalForms: any;
    forceOpenGeneralTechnicalQuestionnaires: boolean | undefined;
    loading: number;
    speciesListByIdName: any;
    speciesNameHash: any;
    selectedSpecies: any;
    speciesNameFilter: any;
    timestamp: any;
}

class PublicTQSearch extends React.Component<IProps, IState> {
    initialCriteria = Object.assign({}, DEFAULT_CRITERIA);

    constructor(props: IProps) {
        super(props);
        this.state = {
            count: 0,
            criteria: {...this.initialCriteria, order: 'formName'},
            criteriaCount: 0,
            form: null,
            forms: null,
            generalForms: null,
            forceOpenGeneralTechnicalQuestionnaires: undefined,
            loading: 0,
            speciesListByIdName: null,
            speciesNameHash: null,
            selectedSpecies: {},
            speciesNameFilter: 'starts',
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'TQSearch'});
        this.loadJSONs();
    }

    loadJSONs = () => {
        Promise.all([this.loadSpecies(), this.loadGeneralTQs()]);
    };

    loadSpecies = () =>
        new Promise(resolve => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiSpecies()
                        .then((jsonResponse: any) => {
                            if (jsonResponse) {
                                const speciesNameHash: any = {};
                                (jsonResponse || []).forEach((speciesElement: any) => {
                                    if (!speciesNameHash[speciesElement.NAME]) {
                                        speciesNameHash[speciesElement.NAME] = [];
                                    }
                                    speciesNameHash[speciesElement.NAME].push(speciesElement.ID);
                                });
                                const speciesListByIdName = Object.keys(speciesNameHash).map(speciesName => ({
                                    ID: speciesNameHash[speciesName].join(','),
                                    NAME: speciesName,
                                }));
                                this.setState(
                                    prev => ({
                                        loading: prev.loading - 1,
                                        speciesListByIdName,
                                        speciesNameHash,
                                        timestamp: Date.now(),
                                    }),
                                    () => {
                                        this.loadUrlParams();
                                        resolve([]);
                                    }
                                );
                            }
                        })
                        .catch((error: any) => {
                            ERROR([`FormsLabelInputSpecies: ${error.message}`]);
                        });
                }
            );
        });

    loadGeneralTQs = () =>
        new Promise(resolve => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    const parsedCriteria = this.parseCriteria({
                        ...this.state.criteria,
                        speciesType: '1',
                        status: '2',
                        type: '2',
                    });
                    apiFormSearch(parsedCriteria, DEFAULT_CRITERIA).then((JSONResponse: any) => {
                        if (JSONResponse && JSONResponse.data && JSONResponse.data.forms) {
                            this.setState({generalForms: JSONResponse.data.forms});
                        }
                    });
                    this.setState(
                        prev => ({
                            loading: prev.loading - 1,
                        }),
                        () => resolve([])
                    );
                }
            );
        });

    loadUrlParams = () => {
        const urlParams = loadUrlParamsFn();
        const criteria = Object.assign({}, this.initialCriteria, urlParams);
        if (Object.keys(urlParams).length > 0) {
            const selectedSpecies: any = {};
            if (criteria.speciesIds && criteria.speciesIds !== '') {
                criteria.speciesIds
                    .split(',')
                    .forEach(
                        (speciesId: any) =>
                            (selectedSpecies[speciesId] = (
                                (this.state.speciesListByIdName || []).find((el: any) => el.ID === speciesId) || {}
                            ).NAME)
                    );
            }
            const criteriaCount = this.countCriteria(criteria);
            this.setState(
                {
                    criteria,
                    criteriaCount,
                    selectedSpecies,
                    timestamp: Date.now(),
                },
                this.search
            );
        }
    };

    countCriteria = (criteria: any, advanced?: any) => {
        let count = 0;
        (advanced ? ADVANCED_CRITERIA : Object.keys(criteria)).map(key => {
            if (
                criteria[key] &&
                criteria[key] !== DEFAULT_CRITERIA[key as TKeyOfDefaultCriteria] &&
                criteria[key] !== ''
            )
                count++;
        });
        return count;
    };

    buildParams = (criteria: TDefaultCriteria): void => {
        const paramArray = Object.keys(criteria)
            .filter(i => criteria[i as TKeyOfDefaultCriteria] !== DEFAULT_CRITERIA[i as TKeyOfDefaultCriteria])
            .map(key => `${key}=${criteria[key as TKeyOfDefaultCriteria]}`);
        this.props.history.replace(`/TQSearch${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`);
    };

    parseCriteria = (criteria: TDefaultCriteria): TDefaultCriteria => {
        const parsedCriteria: any = {};
        Object.keys(criteria).map(key => {
            let criteriaValue = criteria[key as TKeyOfDefaultCriteria];
            parsedCriteria[key] = criteriaValue;
        });
        return parsedCriteria;
    };

    search = (refresh?: any) => {
        this.setState(
            prev =>
                Object.assign(
                    {...prev},
                    {
                        loading: prev.loading + 1,
                        criteria: Object.assign(
                            {},
                            prev.criteria,
                            {refresh: !!refresh},
                            !refresh && !prev.criteria.pageNumber && {pageNumber: 1}
                        ),
                    },
                    !refresh && {selectedLabels: {}}
                ),
            () => {
                const {criteria} = this.state;
                const parsedCriteria = this.parseCriteria(criteria);
                this.buildParams(parsedCriteria);
                apiFormSearch({...parsedCriteria, status: 2, type: 2}, DEFAULT_CRITERIA)
                    .then((jsonResponse: any) => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.forms) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {
                                    pageNumber: prev.criteria.pageNumber || 1,
                                });
                                return Object.assign(
                                    {},
                                    !refresh && {criteria},
                                    {
                                        forms: jsonResponse.data.forms,
                                        forceOpenGeneralTechnicalQuestionnaires: false,
                                        timestamp: Date.now(),
                                    },
                                    jsonResponse.data.COUNT && {count: jsonResponse.data.COUNT}
                                );
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`Register search list error: ${error.message}`]);
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    updateCriteriaValue = (criteriaValue: any, callback?: any, refresh?: any) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                pageNumberChanged = criteriaValue.pageNumber && prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.criteria.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse && prev.criteria.reverse !== criteriaValue.reverse;
                return {...prev, criteria, criteriaCount, advancedCriteriaCount};
            },
            () => {
                // LOG`${JSON.stringify(this.state)}`;
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    downloadFileAndRename = ({formId, language, versionCode}: any) => {
        const downloadFileName = versionCode.replace('/', '_') + `_${language.toUpperCase()}.pdf`;
        try {
            apiPublicSearchFormsDownloadPDF({formId, formLanguage: language}).then(blob => {
                saveAs(blob, downloadFileName);
            });
        } catch (error: any) {
            ERROR([`PublicTQSearch error: ${error.message}`]);
        }
    };

    rowClick = (_: any, rowObject: any) => {
        const {formId, languages, versionCode} = rowObject || {};
        if (languages && languages.length === 1) {
            this.downloadFileAndRename({formId, language: languages[0], versionCode});
        }
    };

    rowClass = ({languages}: any) => (languages && languages.length === 1 ? styles.rowHover : styles.rowDefault);

    resetCriteria = () => {
        this.setState(prev => ({
            ...prev,
            criteria: Object.assign({}, this.initialCriteria, prev.criteria.pageSize),
            criteriaCount: 0,
            advancedCriteriaCount: 0,
            forms: null,
            selectedSpecies: {},
            timestamp: Date.now(),
            selectedCropSectors: {},
        }));
    };

    onCommonNameInputChange = ({target: {value: commonName}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({commonName});

    onSpeciesSelectionChange = (selectedSpecies: any = {}) => {
        if (selectedSpecies) {
            this.updateCriteriaValue({speciesIds: Object.keys(selectedSpecies).join(','), speciesName: ''}, () =>
                this.setState({selectedSpecies})
            );
        }
    };

    onSpeciesNameChange = (speciesName: any) => this.updateCriteriaValue({speciesName});

    onSpeciesNameFilterChange = ({target: {value: speciesNameFilter}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({speciesNameFilter});

    onFormLanguageLinkClick = ({formId, language, versionCode, languages}: any) => {
        if (languages && languages.length > 1) {
            this.downloadFileAndRename({formId, language, versionCode});
        }
    };

    setForceOpenGeneralTechnicalQuestionnaires = (forceOpenGeneralTechnicalQuestionnaires: boolean | undefined) =>
        this.setState({forceOpenGeneralTechnicalQuestionnaires});

    render() {
        return (
            <>
                {this.state.loading ? <HeaderLoading /> : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={'TQ Search'} />
                <Navigation hideLinkUserArea={true} showLinkHomeCPVO={true} links={[]} />
                <MainWrapper withoutNavigation={true}>
                    <FormWrapper paddingFormContent={'sm'}>
                        <DataSheetFormSection
                            title={'Specific Technical Questionnaires'}
                            style={{paddingLeft: 0, paddingRight: 0}}
                            styleTable={{paddingLeft: 0, paddingRight: 0, maxWidth: '100%'}}
                            disableToggle={true}
                        >
                            <TextInput
                                label={`Common name`}
                                filter={'Contains'}
                                infoIcon={faInfo}
                                onChange={this.onCommonNameInputChange}
                                value={this.state.criteria.commonName}
                                popOverText={`The search will find all forms that contains this text in their common name`}
                                double={true}
                                onEnter={this.search}
                            />
                            <TextLabelInput
                                label={`Species`}
                                filter={['starts']}
                                currentFilter={this.state.criteria.speciesNameFilter}
                                onFilterChange={this.onSpeciesNameFilterChange}
                                double={true}
                                onSelectionChange={this.onSpeciesSelectionChange}
                                onChange={this.onSpeciesNameChange}
                                value={this.state.criteria.speciesName}
                                selectedElements={this.state.selectedSpecies}
                                listByIdName={this.state.speciesListByIdName}
                                nameHash={this.state.speciesNameHash}
                                delay={300}
                                noInfo={true}
                                multiple={true}
                            />
                            <div style={{clear: 'both'}} />
                            <FormFooter>
                                <Button
                                    clickAction={this.resetCriteria}
                                    variation={'secondary'}
                                >{`Clear fields`}</Button>
                                <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                            </FormFooter>
                        </DataSheetFormSection>
                        <div style={{clear: 'both', marginBottom: 30}} />

                        <DataSheetFormSection
                            title={'General Technical Questionnaires'}
                            style={{paddingLeft: 0, paddingRight: 0}}
                            styleTable={{paddingLeft: 0, paddingRight: 0, maxWidth: '100%', marginLeft: '-15px'}}
                            forceOpen={this.state.forceOpenGeneralTechnicalQuestionnaires}
                            setForceOpen={this.setForceOpenGeneralTechnicalQuestionnaires}
                        >
                            <CustomTable
                                formatFunctions={{
                                    validFrom: FORMAT_DATE_EASY,
                                    languages: (languages: string[], {formId, versionCode}: any) => {
                                        return (
                                            <div style={{textAlign: 'right'}}>
                                                {(languages || []).map(language => (
                                                    <span
                                                        onClick={() =>
                                                            this.onFormLanguageLinkClick({
                                                                formId,
                                                                language,
                                                                versionCode,
                                                                languages,
                                                            })
                                                        }
                                                        key={language}
                                                        style={{
                                                            textDecoration: 'underline',
                                                            color: 'green',
                                                            marginRight: 7,
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {language}
                                                    </span>
                                                ))}
                                            </div>
                                        );
                                    },
                                }}
                                rowClick={this.rowClick}
                                rowClass={this.rowClass}
                                version={1}
                                loading={this.state.loading}
                                tableName={'TQSearch'}
                                tableType={'OBJECT'}
                                tableSource={this.state.generalForms}
                                timestamp={this.state.timestamp}
                                dataFilter={null}
                                id={'formId'}
                                resultFieldsDefault={DEFAULT_RESULT_FIELDS}
                                intl={this.props.intl}
                                defaultOrder={'versionCode'}
                                reverseOrder={this.state.criteria.reverse}
                                filterFunctions={null}
                                count={this.state.count}
                                hideExcelButton={true}
                            />
                        </DataSheetFormSection>
                    </FormWrapper>
                    <div style={{marginBottom: 20}}>
                        <CustomTable
                            formatFunctions={{
                                validFrom: FORMAT_DATE_EASY,
                                languages: (languages: string[], {formId, versionCode}: any) => {
                                    return (
                                        <div style={{textAlign: 'right'}}>
                                            {(languages || []).map(language => (
                                                <span
                                                    onClick={() =>
                                                        this.onFormLanguageLinkClick({
                                                            formId,
                                                            language,
                                                            versionCode,
                                                            languages,
                                                        })
                                                    }
                                                    key={language}
                                                    style={{
                                                        textDecoration: 'underline',
                                                        color: 'green',
                                                        marginRight: 7,
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {language}
                                                </span>
                                            ))}
                                        </div>
                                    );
                                },
                            }}
                            rowClick={this.rowClick}
                            rowClass={this.rowClass}
                            version={2}
                            loading={this.state.loading}
                            tableName={'TQSearch'}
                            tableType={'OBJECT'}
                            tableSource={this.state.forms}
                            timestamp={this.state.timestamp}
                            dataFilter={null}
                            id={'formId'}
                            resultFieldsDefault={DEFAULT_RESULT_FIELDS}
                            intl={this.props.intl}
                            defaultOrder={this.state.criteria.order}
                            reverseOrder={this.state.criteria.reverse}
                            filterFunctions={null}
                            count={this.state.count}
                            hideExcelButton={true}
                            forehandColumn={(rowObject: any) => {
                                const {currentTQVersionBeginDate} = rowObject;
                                if (currentTQVersionBeginDate) {
                                    let today = new Date();
                                    let currentTQVersionBeginDatePlus2Weeks = addDaysToDate({
                                        date: currentTQVersionBeginDate,
                                        numberOfDays: 14,
                                    });
                                    if (today < currentTQVersionBeginDatePlus2Weeks) {
                                        return (
                                            <div
                                                style={{
                                                    background: '#5bc0de',
                                                    color: 'white',
                                                    borderRadius: 4,
                                                    padding: '2px 4px',
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                    textAlign: 'center',
                                                }}
                                                title={`A new version of the TQ has been published on ${formatDateEasy(
                                                    currentTQVersionBeginDate
                                                )}`}
                                            >{`NEW`}</div>
                                        );
                                    }
                                }
                            }}
                        />
                    </div>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(PublicTQSearch);
