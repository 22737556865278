import React from 'react';
import VFModalDenominationTest from '../VFModalDenominationTest/VFModalDenominationTest';
import VFModalTestDenominationsSubmitBatchForm from './modals/VFModalTestDenominationsSubmitBatchForm';
import VFModalTestDenominationsSubmitBatchTable from './modals/VFModalTestDenominationsSubmitBatchTable';
import {Button} from '../../componentsFormV2';
import Checkbox from '~components/Checkbox';
import CustomTable from '~components/CustomTable';
import FormFooterLinkButton from '~componentsForm/FormFooterLinkButton';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import NavigationVarietyFinder from '../../shared/NavigationVarietyFinder';
import TextAreaInput from '~components/TextAreaInput';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import graphQLClientInstance from '../../utils/axiosGraphQLClient';
import axiosGraphQLClient from '../../utils/axiosGraphQLClient';
import {faCheckCircle, faInfoCircle, faRedo, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {injectIntl} from 'react-intl';
import {isAdvanced} from '../../utils/utilsVarietyFinder';
import styles from './VFTestDenominations.module.scss';
import {withRouter} from 'react-router-dom';
import {trackPageView} from '../../utils';

const RESULT_FIELDS_ALL = ['denomination', 'speciesLatinName', 'processing'];

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    checkboxRequestExpertAdviceValue: boolean;
    forceHideSearchList: boolean;
    isModalDenominationTestOpen: any;
    isModalSubmitBatchFormOpen: boolean;
    isModalSubmitBatchTableOpen: boolean;
    loading: number;
    screenLoaded: boolean;
    tableResults: any;
    textInputDenominationError: string;
    textInputDenominationValue: string;
    textInputBreedersReferenceValue: string;
    textInputBreedersNameValue: string;
    textInputBatchNameValue: string;
    textAreaInputRemarkValue: string;
    textLabelInputSpeciesLatinNameError: string;
    textLabelInputSpeciesLatinNameHash: any;
    textLabelInputSpeciesLatinNameList: any[];
    textLabelInputSpeciesLatinNameSearchLoading: boolean;
    textLabelInputSpeciesLatinNameTimeoutId: any;
    textLabelInputSpeciesLatinNameValue: string;
    textTimestamp: number;
    timestamp: number;
    testing: boolean;
}

class VFTestDenominations extends React.Component<IProps, IState> {
    skipSearching = false;
    speciesId = '';
    speciesName = '';
    searchAndUpdateTableResultsIntervalId: any = null;
    modalTestDenominationsSubmitFormTextAreaInputDenominationsValue = '';
    modalTestDenominationsSubmitFormTextInputBatchNameValue = '';
    modalTestDenominationsSubmitFormCheckboxRequestExpertAdviceValue = false;

    constructor(props: IProps) {
        super(props);
        this.state = {
            checkboxRequestExpertAdviceValue: false,
            forceHideSearchList: false,
            isModalDenominationTestOpen: false,
            isModalSubmitBatchFormOpen: false,
            isModalSubmitBatchTableOpen: false,
            loading: 0,
            screenLoaded: false,
            tableResults: [],
            textInputDenominationError: '',
            textInputDenominationValue: '',
            textInputBreedersReferenceValue: '',
            textInputBreedersNameValue: '',
            textInputBatchNameValue: '',
            textAreaInputRemarkValue: '',
            textLabelInputSpeciesLatinNameError: '',
            textLabelInputSpeciesLatinNameSearchLoading: false,
            textLabelInputSpeciesLatinNameHash: {},
            textLabelInputSpeciesLatinNameList: [],
            textLabelInputSpeciesLatinNameTimeoutId: null,
            textLabelInputSpeciesLatinNameValue: '',
            textTimestamp: Date.now(),
            timestamp: Date.now(),
            testing: true,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'Test Denominations'});
    }

    getModalDenominationTestIndex = () => {
        const {testid: rowObjectTestId} = this.state.isModalDenominationTestOpen || {};
        return this.state.tableResults
            .filter(({processing}: any) => !processing)
            .findIndex(({testId}: any) => testId === rowObjectTestId);
    };

    closeModalSubmitBatchForm = () => {
        this.modalTestDenominationsSubmitFormTextInputBatchNameValue = '';
        this.modalTestDenominationsSubmitFormTextAreaInputDenominationsValue = '';
        this.modalTestDenominationsSubmitFormCheckboxRequestExpertAdviceValue = false;
        this.setState({isModalSubmitBatchFormOpen: false});
    };

    closeModalSubmitBatchTable = () => {
        this.modalTestDenominationsSubmitFormTextInputBatchNameValue = '';
        this.modalTestDenominationsSubmitFormTextAreaInputDenominationsValue = '';
        this.modalTestDenominationsSubmitFormCheckboxRequestExpertAdviceValue = false;
        this.setState({isModalSubmitBatchTableOpen: false});
    };

    closeModalDenominationTest = () => this.setState({isModalDenominationTestOpen: false});

    searchAndUpdateTableResults = ({skipIntervalCheck, callback}: {skipIntervalCheck?: boolean; callback?: any}) => {
        if (!skipIntervalCheck) {
            if (this.state.tableResults.every(({processing}: any) => !processing)) {
                return clearInterval(this.searchAndUpdateTableResultsIntervalId);
            }
        }
        const tableTestIds: number[] = this.state.tableResults.map(({testId}: any) => testId) || [];
        graphQLClientInstance.post('/api/v1/tests/fetch-by-ids', {test_ids: tableTestIds}).then(JSONResponse => {
            if (
                JSONResponse &&
                JSONResponse.data &&
                JSONResponse.data.tests_list &&
                JSONResponse.data.tests_list.length
            ) {
                this.setState(
                    prev => {
                        const responseResults = [...JSONResponse.data.tests_list];
                        const updatedTableResults: any = [...prev.tableResults];
                        tableTestIds.forEach(testId => {
                            let foundResponseResult = responseResults.find(result => result.testid === testId) || {};
                            let indexToUpdate = updatedTableResults.findIndex(
                                ({testId: tableTestId}: any) => tableTestId === testId
                            );
                            if (foundResponseResult['test_status'] !== undefined) {
                                updatedTableResults[indexToUpdate]['processing'] =
                                    foundResponseResult['test_status'] !== 0;
                                if (!updatedTableResults[indexToUpdate]['JSONResponse']) {
                                    updatedTableResults[indexToUpdate]['JSONResponse'] = foundResponseResult;
                                }
                            }
                        });
                        return {
                            tableResults: updatedTableResults,
                            timestamp: Date.now(),
                        };
                    },
                    () => callback && callback()
                );
            }
        });
    };

    getCurrentDateFormatted = () => {
        let today = new Date();
        let date = today.getDate();
        let month = `00${today.getMonth() + 1}`.slice(-2);
        let year = today.getFullYear();
        return `${date}/${month}/${year}`;
    };

    tableNavigationButtons = () => (
        <div className={styles.navigationBarV2LeftItem} onClick={this.onTableNavigationButtonRefreshClick}>
            <FontAwesomeIcon style={{float: 'left'}} icon={faRedo as any} />
            <div
                style={{
                    float: 'left',
                    paddingLeft: 5,
                }}
            >
                {`Refresh`}
            </div>
            <div style={{clear: 'both'}} />
        </div>
    );

    autoCompletePostData = (autoCompleteName: string, term: string, type: string, includeSynonyms = false) => {
        const synonyms = includeSynonyms ? `includeSynonyms:"1"` : '';

        return {
            query: `
    {
      ${autoCompleteName}(
        term: "${term.toLowerCase()}"
        type: "${type}"
        ${synonyms}
      ){ name principal specieid}
    }`,
        };
    };

    searchSpeciesLatinNameSuggestions = () => {
        if (!this.skipSearching) {
            this.setState({textLabelInputSpeciesLatinNameSearchLoading: true}, () => {
                axiosGraphQLClient
                    .post(
                        '/graphql',
                        this.autoCompletePostData(
                            'autoCompleteSpeciesLatinName',
                            this.state.textLabelInputSpeciesLatinNameValue,
                            'contains',
                            true
                        )
                    )
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.autoCompleteSpeciesLatinName
                        ) {
                            const nameHash: {[key: string]: string[]} = {};
                            JSONResponse.data.data.autoCompleteSpeciesLatinName.forEach(({name, specieid}: any) => {
                                if (!nameHash[name]) {
                                    nameHash[name] = [];
                                }
                                nameHash[name].push(specieid);
                            });
                            const listByIdName: any = Object.keys(nameHash).map(key => {
                                let style: any = {};
                                let itemFound = JSONResponse.data.data.autoCompleteSpeciesLatinName.find(
                                    ({name}: any) => name === key
                                );
                                itemFound && itemFound.principal === 0 && (style.color = 'blue');
                                return {
                                    ID: nameHash[key].join(','),
                                    NAME: key,
                                    style,
                                };
                            });
                            this.setState({
                                forceHideSearchList: false,
                                textTimestamp: Date.now(),
                                textLabelInputSpeciesLatinNameSearchLoading: false,
                                textLabelInputSpeciesLatinNameHash: nameHash,
                                textLabelInputSpeciesLatinNameList: listByIdName,
                            });
                        }
                    })
                    .catch(err => ERROR([err]));
            });
        }
    };

    modalTestDenominationsSubmitBatchTableButtonBackClick = () =>
        this.setState({isModalSubmitBatchFormOpen: true, isModalSubmitBatchTableOpen: false});

    onModalTestDenominationsSubmitBatchTableButtonAddClick = (tableResults: any) =>
        this.setState(
            prev => ({
                isModalSubmitBatchTableOpen: false,
                loading: prev.loading + 1,
            }),
            () => {
                graphQLClientInstance
                    .post('/api/v1/tests', {
                        tests: tableResults.map(
                            ({breedersName, breedersReference, denomination, remark, speciesCode}: any) => ({
                                analysis_req: this.modalTestDenominationsSubmitFormCheckboxRequestExpertAdviceValue
                                    ? '1'
                                    : '0',
                                batch_name: this.modalTestDenominationsSubmitFormTextInputBatchNameValue,
                                breeder_name: breedersName,
                                breeder_ref: breedersReference,
                                denomination,
                                species_id: speciesCode,
                                user_remark: remark,
                            })
                        ),
                    })
                    .then(JSONResponse => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.test_ids &&
                            JSONResponse.data.test_ids.length
                        ) {
                            this.setState(
                                prev => ({
                                    loading: prev.loading - 1,
                                    tableResults: [
                                        ...prev.tableResults,
                                        ...tableResults.map(
                                            (
                                                {
                                                    breedersName,
                                                    breedersReference,
                                                    denomination,
                                                    speciesLatinName,
                                                    speciesCode,
                                                    remark,
                                                }: any,
                                                index: number
                                            ) => ({
                                                breedersName,
                                                breedersReference,
                                                testDate: this.getCurrentDateFormatted(),
                                                testId: JSONResponse.data.test_ids[index].test_id,
                                                denomination,
                                                speciesCode,
                                                speciesLatinName,
                                                processing: true,
                                                remark,
                                            })
                                        ),
                                    ],
                                    screenLoaded: true,
                                    timestamp: Date.now(),
                                }),
                                () => {
                                    this.modalTestDenominationsSubmitFormTextInputBatchNameValue = '';
                                    this.modalTestDenominationsSubmitFormTextAreaInputDenominationsValue = '';
                                    this.modalTestDenominationsSubmitFormCheckboxRequestExpertAdviceValue = false;
                                    this.searchAndUpdateTableResultsIntervalId = setInterval(() => {
                                        this.searchAndUpdateTableResults({});
                                    }, 3000);
                                }
                            );
                        }
                    });
            }
        );

    onModalDenominationTestNextClick = () => {
        let currentIndex = this.getModalDenominationTestIndex();
        this.setState({isModalDenominationTestOpen: false}, () => {
            let nextTableResult = this.state.tableResults.filter(({processing}: any) => !processing)[currentIndex + 1];
            this.onTableDataDenominationClick(nextTableResult);
        });
    };

    onModalDenominationTestPreviousClick = () => {
        let currentIndex = this.getModalDenominationTestIndex();
        this.setState({isModalDenominationTestOpen: false}, () => {
            let nextTableResult = this.state.tableResults.filter(({processing}: any) => !processing)[currentIndex - 1];
            this.onTableDataDenominationClick(nextTableResult);
        });
    };

    onTextInputDenominationChange = ({
        target: {value: textInputDenominationValue},
    }: React.ChangeEvent<HTMLInputElement>) => this.setState({textInputDenominationValue});

    onTextInputBreedersReferenceChange = ({
        target: {value: textInputBreedersReferenceValue},
    }: React.ChangeEvent<HTMLInputElement>) => this.setState({textInputBreedersReferenceValue});

    onTextInputBreedersNameChange = ({
        target: {value: textInputBreedersNameValue},
    }: React.ChangeEvent<HTMLInputElement>) => this.setState({textInputBreedersNameValue});

    onTextInputBatchNameChange = ({target: {value: textInputBatchNameValue}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({textInputBatchNameValue});

    onTextAreaInputRemarkChange = ({target: {value: textAreaInputRemarkValue}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState(prev => ({
            textAreaInputRemarkValue:
                textAreaInputRemarkValue.length <= 150 ? textAreaInputRemarkValue : prev.textAreaInputRemarkValue,
        }));

    onTextLabelInputSpeciesLatinNameSelectionChange = () => {
        this.skipSearching = true;
        this.setState({
            textLabelInputSpeciesLatinNameList: [],
            textLabelInputSpeciesLatinNameHash: {},
        });
        setTimeout(() => {
            this.skipSearching = false;
        }, 1000);
    };

    onTextLabelInputSpeciesLatinNameChange = (textLabelInputSpeciesLatinNameValue: string) => {
        if (this.state.textLabelInputSpeciesLatinNameHash[textLabelInputSpeciesLatinNameValue]) {
            this.speciesId = `${this.state.textLabelInputSpeciesLatinNameHash[textLabelInputSpeciesLatinNameValue]}`;
            this.speciesName = `${textLabelInputSpeciesLatinNameValue}`;
        } else {
            this.speciesId = '';
            this.speciesName = '';
        }
        clearTimeout(this.state.textLabelInputSpeciesLatinNameTimeoutId);
        this.setState({
            textLabelInputSpeciesLatinNameValue,
            textLabelInputSpeciesLatinNameHash: {},
            textLabelInputSpeciesLatinNameList: [],
        });
        if ((textLabelInputSpeciesLatinNameValue || '').length > 2) {
            let newTimeoutId = setTimeout(() => {
                this.state.textLabelInputSpeciesLatinNameValue && this.searchSpeciesLatinNameSuggestions();
            }, 300);
            this.setState({
                textLabelInputSpeciesLatinNameTimeoutId: newTimeoutId,
            });
        }
    };

    onTextLabelInputSpeciesLatinNameBlur = () => {
        if (this.state.textLabelInputSpeciesLatinNameValue !== this.speciesName) {
            this.setState({textLabelInputSpeciesLatinNameValue: ''});
        }
    };

    onLinkButtonHelpClick = () => window.open('/help/en/Test_denominations.html', '_blank');

    onLinkButtonDenominationGuidelinesClick = () =>
        window.open(
            'https://cpvo.europa.eu/sites/default/files/documents/cpvo_guidelines_on_art_63_with_explanatory_notes.pdf'
        );

    onButtonSubmitBatchClick = () => this.setState({isModalSubmitBatchFormOpen: true});

    onModalTestDenominationsSubmitBatchFormButtonAddClick = ({
        textAreaInputDenominationsValue,
        textInputBatchNameValue,
        checkboxRequestExpertAdviceValue,
    }: any) => {
        this.modalTestDenominationsSubmitFormTextAreaInputDenominationsValue = textAreaInputDenominationsValue;
        this.modalTestDenominationsSubmitFormTextInputBatchNameValue = textInputBatchNameValue;
        this.modalTestDenominationsSubmitFormCheckboxRequestExpertAdviceValue = checkboxRequestExpertAdviceValue;
        this.setState({isModalSubmitBatchFormOpen: false, isModalSubmitBatchTableOpen: true});
    };

    onButtonRunTestClick = () => {
        if (!this.state.textInputDenominationValue || !this.state.textLabelInputSpeciesLatinNameValue) {
            return this.setState(prev => ({
                textInputDenominationError: prev.textInputDenominationValue ? '' : 'Field is required!',
                textLabelInputSpeciesLatinNameError: prev.textLabelInputSpeciesLatinNameValue
                    ? ''
                    : 'Field is required!',
            }));
        }
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                textInputDenominationError: '',
                textLabelInputSpeciesLatinNameError: '',
            }),
            () => {
                graphQLClientInstance
                    .post('/api/v1/tests', {
                        tests: [
                            Object.assign(
                                {},
                                {
                                    denomination: this.state.textInputDenominationValue,
                                    species_id: this.speciesId,
                                    species_latin_name: this.speciesName,
                                    breeder_ref: this.state.textInputBreedersReferenceValue,
                                    breeder_name: this.state.textInputBreedersNameValue,
                                    batch_name: this.state.textInputBatchNameValue,
                                    user_remark: this.state.textAreaInputRemarkValue,
                                },
                                this.state.checkboxRequestExpertAdviceValue ? {analysis_req: 1} : {}
                            ),
                        ],
                    })
                    .then(JSONResponse => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.test_ids &&
                            JSONResponse.data.test_ids.length
                        ) {
                            this.setState(
                                prev => ({
                                    loading: prev.loading - 1,
                                    tableResults: [
                                        ...prev.tableResults,
                                        {
                                            breedersName: '',
                                            breedersReference: '',
                                            testId: JSONResponse.data.test_ids[0].test_id,
                                            denomination: prev.textInputDenominationValue,
                                            speciesLatinName: prev.textLabelInputSpeciesLatinNameValue,
                                            testDate: this.getCurrentDateFormatted(),
                                            processing: true,
                                            remark: '',
                                        },
                                    ],
                                    screenLoaded: true,
                                    timestamp: Date.now(),
                                }),
                                () => {
                                    this.searchAndUpdateTableResultsIntervalId = setInterval(() => {
                                        this.searchAndUpdateTableResults({});
                                    }, 3000);
                                }
                            );
                        }
                    });
            }
        );
    };

    onTableDataDenominationClick = (rowObject: any) => {
        const {JSONResponse, breedersReference, testId, denomination, speciesLatinName, testDate} = rowObject || {};
        const modalDenominationTestProps = {
            JSONResponse,
            breedersReference,
            denomination,
            speciesName: speciesLatinName,
            testDate,
            testStatus: 0,
            testid: testId,
        };
        this.setState({isModalDenominationTestOpen: modalDenominationTestProps});
    };

    onTableNavigationButtonRefreshClick = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                this.searchAndUpdateTableResults({
                    skipIntervalCheck: true,
                    callback: () => this.setState(prev => ({loading: prev.loading - 1})),
                });
            }
        );

    onHeaderSearchLinkClick = (searchListItem: any) => {
        this.props.history.push({
            pathname: '/varieties',
            state: {searchListItem},
        });
    };

    onCheckboxRequestExpertAdviceClick = () =>
        this.setState(prev => ({checkboxRequestExpertAdviceValue: !prev.checkboxRequestExpertAdviceValue}));

    render() {
        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                {this.state.isModalDenominationTestOpen ? (
                    <VFModalDenominationTest
                        close={this.closeModalDenominationTest}
                        currentIndex={this.getModalDenominationTestIndex()}
                        onButtonNextClick={this.onModalDenominationTestNextClick}
                        onButtonPreviousClick={this.onModalDenominationTestPreviousClick}
                        rowObject={this.state.isModalDenominationTestOpen}
                        totalCount={this.state.tableResults.filter(({processing}: any) => !processing).length}
                    />
                ) : null}
                {this.state.isModalSubmitBatchFormOpen ? (
                    <VFModalTestDenominationsSubmitBatchForm
                        close={this.closeModalSubmitBatchForm}
                        initialRequestExpertAdviceValue={
                            this.modalTestDenominationsSubmitFormCheckboxRequestExpertAdviceValue
                        }
                        initialBatchNameValue={this.modalTestDenominationsSubmitFormTextInputBatchNameValue}
                        initialDenominationsValue={this.modalTestDenominationsSubmitFormTextAreaInputDenominationsValue}
                        onButtonAddClick={this.onModalTestDenominationsSubmitBatchFormButtonAddClick}
                    />
                ) : null}
                {this.state.isModalSubmitBatchTableOpen ? (
                    <VFModalTestDenominationsSubmitBatchTable
                        close={this.closeModalSubmitBatchTable}
                        denominationsStringValue={this.modalTestDenominationsSubmitFormTextAreaInputDenominationsValue}
                        onButtonBackClick={this.modalTestDenominationsSubmitBatchTableButtonBackClick}
                        onButtonRunTestsClick={this.onModalTestDenominationsSubmitBatchTableButtonAddClick}
                    />
                ) : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Variety Finder`} hostApplication={'Variety Finder'} />
                <NavigationVarietyFinder onSearchLinkClick={this.onHeaderSearchLinkClick} />
                <MainWrapper bgGray={true}>
                    <FormWrapper formInnerWidth={'lg'} paddingFormContent={'sm'}>
                        <TextInput
                            errorVarietyFinder={this.state.textInputDenominationError}
                            label={'Denomination'}
                            mandatory={true}
                            onChange={this.onTextInputDenominationChange}
                            popOverTextFirstRow={`Enter the denomination to be tested.`}
                            value={this.state.textInputDenominationValue}
                            size={'lg'}
                        />
                        <div className={styles.textLabelInputSpeciesLatinNameWrap}>
                            <TextLabelInput
                                buttonIcon={this.state.textLabelInputSpeciesLatinNameSearchLoading && faSpinner}
                                double={true}
                                errorVarietyFinder={this.state.textLabelInputSpeciesLatinNameError}
                                filterContains={true}
                                filterContainsKeepJSONResponseSortOrder={true}
                                forceHideSearchList={this.state.forceHideSearchList}
                                hideCounter={true}
                                label={`Species Latin Name`}
                                listByIdName={this.state.textLabelInputSpeciesLatinNameList}
                                mandatory={true}
                                nameHash={this.state.textLabelInputSpeciesLatinNameHash}
                                onBlur={this.onTextLabelInputSpeciesLatinNameBlur}
                                onChange={this.onTextLabelInputSpeciesLatinNameChange}
                                onSelectionChange={this.onTextLabelInputSpeciesLatinNameSelectionChange}
                                popOverTextFirstRow={`Select the species in the list. This list shows all botanical names, synonyms in Latin.`}
                                noInfo={true}
                                selectedElements={{}}
                                timestamp={this.state.textTimestamp}
                                removeStyleClear={true}
                                value={this.state.textLabelInputSpeciesLatinNameValue}
                                size={'lg'}
                            />
                        </div>
                        {isAdvanced() ? (
                            <>
                                <TextInput
                                    label={"Breeder's Reference"}
                                    onChange={this.onTextInputBreedersReferenceChange}
                                    popOverTextFirstRow={`Internal designation of the variety before the latter is given an official denomination, which can identical to or different from the breeder’s reference. When applying in official procedures, the applicant should submit the  the same breeder’s reference and the same denomination.`}
                                    value={this.state.textInputBreedersReferenceValue}
                                    size={'lg'}
                                />
                                <TextInput
                                    label={"Breeder's Name"}
                                    onChange={this.onTextInputBreedersNameChange}
                                    popOverTextFirstRow={`This field is not compulsory for the  purpose of testing. However, in the framework of the cooperation service with Member States, it is highly recommended to provide this information in order to allow the Office to provide a more accurate advice.`}
                                    value={this.state.textInputBreedersNameValue}
                                    double={true}
                                    size={'lg'}
                                />
                                <TextAreaInput
                                    label="Remark"
                                    value={this.state.textAreaInputRemarkValue}
                                    rows={3}
                                    triple={true}
                                    maxCounter={150}
                                    counter={this.state.textAreaInputRemarkValue.length}
                                    onChange={this.onTextAreaInputRemarkChange}
                                    popOverTextFirstRow={`This field is not compulsory for the  purpose of testing. However, in the framework of the cooperation service, Member States are encouraged to provide any additional information that will ease the analysis and allow to the Office to provide a more accurate advice.`}
                                    size={'lg'}
                                />
                                <TextInput
                                    label={'Batch Name'}
                                    onChange={this.onTextInputBatchNameChange}
                                    popOverTextFirstRow={`This field is not compulsory for the  purpose of testing but will help the user to identify the batches in "My Tests" later.`}
                                    value={this.state.textInputBatchNameValue}
                                    size={'lg'}
                                />
                                <div style={{clear: 'both'}} />
                                <Checkbox
                                    clickAction={this.onCheckboxRequestExpertAdviceClick}
                                    triple={true}
                                    label={`Request for CPVO expert advice (only formal requests)`}
                                    value={this.state.checkboxRequestExpertAdviceValue}
                                />
                            </>
                        ) : null}
                        <div style={{float: 'right', marginBottom: 10, marginRight: -14}}>
                            <FormFooterLinkButton
                                clickAction={this.onLinkButtonDenominationGuidelinesClick}
                                hoverUnderline={true}
                            >{`Denomination guidelines`}</FormFooterLinkButton>
                        </div>
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            {isAdvanced() || this.state.testing === true ? (
                                <div style={{marginBottom: 20, textAlign: 'left'}}>
                                    <FontAwesomeIcon
                                        icon={faInfoCircle as any}
                                        color="#00447a"
                                        style={{marginRight: 15}}
                                    />
                                    <span
                                        style={{
                                            color: 'gray',
                                        }}
                                    >{`Filling out all the fields will provide an additional level of information, which will allow offering a more accurate expert advice.`}</span>
                                </div>
                            ) : null}
                            <Button clickAction={this.onLinkButtonHelpClick} variation={'secondary'}>{`Help`}</Button>
                            <Button clickAction={this.onButtonRunTestClick}>{`Run Test`}</Button>
                            <Button clickAction={this.onButtonSubmitBatchClick}>{`Submit Batch`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    <div>
                        {this.state.screenLoaded ? (
                            <div>
                                <CustomTable
                                    additionalNavigationButtons={this.tableNavigationButtons}
                                    formatFunctions={{
                                        denomination: (denomination: string, rowObject: any) => {
                                            const {processing} = rowObject || {};
                                            return (
                                                <div
                                                    onClick={() =>
                                                        processing
                                                            ? undefined
                                                            : this.onTableDataDenominationClick(rowObject)
                                                    }
                                                    style={
                                                        processing
                                                            ? undefined
                                                            : {
                                                                  color: '#255899',
                                                                  cursor: 'pointer',
                                                              }
                                                    }
                                                >
                                                    {denomination}
                                                </div>
                                            );
                                        },
                                        processing: (processing: boolean) => {
                                            if (processing) {
                                                return (
                                                    <div className={styles.tableSpinnerWrap}>
                                                        <FontAwesomeIcon icon={faSpinner as any} color={'green'} />
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div>
                                                        <FontAwesomeIcon icon={faCheckCircle as any} color={'green'} />
                                                    </div>
                                                );
                                            }
                                        },
                                    }}
                                    id={'id'}
                                    intl={this.props.intl}
                                    isNavigationButtonCompactOrDefaultViewEnabled={true}
                                    hideExcelButton={true}
                                    noChangePageSize={true}
                                    noShowingPageNumber={true}
                                    notSortable={RESULT_FIELDS_ALL}
                                    resultFieldsDefault={RESULT_FIELDS_ALL}
                                    tableName={'VFTestDenominations'}
                                    tableSource={this.state.tableResults}
                                    tableType={'OBJECT'}
                                    timestamp={this.state.timestamp}
                                    unlimited={true}
                                    version={2}
                                    hoverGrey={true}
                                    navigationBarWider={true}
                                    fontSize={14}
                                />
                            </div>
                        ) : null}
                    </div>
                </MainWrapper>
                <Footer hostApplication={'Variety Finder'} />
            </>
        );
    }
}

export default withRouter(injectIntl(VFTestDenominations));
