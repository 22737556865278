import {API_SERVERLESS, getFetch, postFetch} from '../../../../commonApi';

export interface TqSearchCandidateVarietiesUrlParamsData {
    speciesName: string;
    denomination: string;
    reference: string;
    applicantName: string;
    registerId: string;
    speciesId: string;
    countryId: string;
    confidential: string;

    [key: string]: string | number;
}

export interface TqSearchCandidateVarietiesTableRowData {
    groupOrder: string;
    questionOrder: string;
    questionName: string;
    score: number;
    textValue: string;
    remarkValue: string;
    confidential: number;
}

export interface TqSearchCandidateVarietiesParamsData {
    data: {
        candidateVarieties: Array<TqSearchCandidateVarietiesTableRowData>;
    };
}

export function apiTqSearchCandidateVarietiesFetch(
    urlParams: TqSearchCandidateVarietiesUrlParamsData
): Promise<TqSearchCandidateVarietiesParamsData> {
    const params = {...urlParams};
    return postFetch(`${API_SERVERLESS}/tlo/applications/tqsearch/candidatevarieties`, true, params);
}

export function apiTqSearchCandidateVarietiesDownloadPhotos(
    registerId: string,
    countryId: string
): Promise<{signedUrl: string} | []> {
    return getFetch(
        `${API_SERVERLESS}/tlo/applications/tqsearch/images?registerId=${registerId}&countryId=${countryId}`,
        true,
        {}
    );
}
