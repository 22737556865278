import React, {useState} from 'react';
import cx from 'classnames';
import styles from './FormWithBackground.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfo} from '@fortawesome/free-solid-svg-icons';
import {ModalWhatsNewVersion2} from '../commonModals';
import Link from '~components/Link';
import {loadUrlParams} from '~utils';

export const backgrounds = {
    background1: styles.background1,
    background2: styles.background2,
    background3: styles.background3,
    background4: styles.background4,
    background5: styles.background5,
    background6: styles.background6,
    background7: styles.background7,
    background8: styles.background8,
    background9: styles.background9,
    background10: styles.background10,
    background11: styles.background11,
    backgroundOABO: styles.backgroundOABO,
    backgroundOA: styles.backgroundOA,
    backgroundOADark: styles.backgroundOADark,
    backgroundVarietyFinder1: styles.backgroundVarietyFinder1,
    backgroundVarietyFinder2: styles.backgroundVarietyFinder2,
    backgroundVarietyFinder3: styles.backgroundVarietyFinder3,
    backgroundVarietyFinder4: styles.backgroundVarietyFinder4,
};

const modalScreens = {
    WHATS_NEW: 'WHATS_NEW',
};

const sizeMap = {
    lg: 'Lg',
};

export default function ({
    title,
    title2,
    subTitle,
    SubTitleComponent,
    subTitleStyles,
    centerTitle,
    centerTitle2,
    centerTitle3,
    children,
    background,
    double,
    opacity,
    versionUp,
    hideVersion,
    languages,
    onLanguageChange,
    versionColumnLeftHTML,
    size,
}) {
    const [modalScreen, setModalScreen] = useState(null);
    const openModalWhatsNew = () => setModalScreen(modalScreens.WHATS_NEW);
    const closeModal = () => setModalScreen(null);

    const renderVersionAndLanguages = () => {
        const params = loadUrlParams();
        let activeLanguage = 'en';
        let viewFormLang = params['viewFormLang'];
        if (languages && viewFormLang && languages.map(el => el.toLowerCase()).includes(viewFormLang.toLowerCase())) {
            activeLanguage = viewFormLang.toLowerCase();
        }

        return (
            <div className={styles.versionAndLanguagesRow}>
                {versionColumnLeftHTML ? (
                    <div className={styles.versionColumnLeftHTML}>{versionColumnLeftHTML}</div>
                ) : null}
                <div className={styles.version} onClick={openModalWhatsNew}>
                    {`version ${process.env.VERSION}`}
                    <button type="button" className={cx(styles.button)} onClick={() => {}}>
                        <FontAwesomeIcon icon={faInfo} color={'green'} />
                    </button>
                </div>
                {languages && (
                    <section className={styles.languages}>
                        {(languages || []).map((language, index) => (
                            <Link
                                disabled={language.toLowerCase() === activeLanguage}
                                key={language}
                                label={language.toLowerCase()}
                                clickAction={() => onLanguageChange(language)}
                            />
                        ))}
                    </section>
                )}
            </div>
        );
    };

    return (
        <>
            {modalScreen === modalScreens.WHATS_NEW && <ModalWhatsNewVersion2 close={closeModal} />}
            <div className={cx(styles.body, background, size && styles[`background${sizeMap[size]}`])}>
                {title && <div className={cx(styles.title, size && styles[`title${sizeMap[size]}`])}>{title}</div>}
                {title2 && <div className={styles.title}>{title2}</div>}
                {centerTitle && <div className={cx(styles.title, styles.centerTitle)}>{centerTitle}</div>}
                {centerTitle2 && <div className={cx(styles.title, styles.centerTitle)}>{centerTitle2}</div>}
                {centerTitle3 && <div className={cx(styles.title, styles.centerTitle)}>{centerTitle3}</div>}
                {!hideVersion && versionUp && renderVersionAndLanguages()}
                {(title || centerTitle) && (
                    <div
                        className={cx(styles.subTitle, size && styles[`subTitle${sizeMap[size]}`])}
                        style={{...(subTitleStyles || {})}}
                    >
                        {subTitle}
                    </div>
                )}
                {!hideVersion && !versionUp && renderVersionAndLanguages()}
                <div
                    className={cx(styles.form, size && styles[`form${sizeMap[size]}`], double ? styles.double : null)}
                    style={opacity && {background: `rgba(255,255,255,${opacity})`}}
                >
                    {children}
                </div>
            </div>
        </>
    );
}
