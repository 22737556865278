import React, {useEffect, useRef, useState} from 'react';
import styles from './ModalSubmitRequest.module.scss';
import {faChevronDown, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import DragAndDrop from '~components/DragAndDrop';
import CustomTable from '~components/CustomTable';
import getIcon from '~utils/icons';
import {saveAs} from 'file-saver';
import ModalEndOfFilesPreviousToSignStatusVersion2Actions from './ModalSubmitRequestActions';
import BrowserInfo from '~shared/BrowserInfo';
import InputLink from '~components/InputLink';
import SelectInput from '~components/SelectInput';
import {
    apiCertifiedCopiesCreateFolderAndUploadFilesAndSendRequest,
    apiCommonCheckApplicationNumbers,
} from './ModalSubmitRequestService';
import {TYPE_OF_REQUEST_SELECT_OPTIONS} from '../RequestCertifiedCopies';
import {ModalAlertVersion2, ModalConfirmVersion2, ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';
import {Error, LoadingBar} from '../../../componentsLayout';

const NONE = 'NONE';

const APPLICATION_NUMBER_STATUS = {
    NOT_COMPATIBLE:
        'Application status not compatible with your request for certified copies or an official extract from the register. Request cannot be processed. ',
    OK: 'OK',
    UNAUTHORIZED:
        'You are not registered as either the applicant/ titleholder or representative for the file concerned. Please attach an authorization (for a single or for multiple files) below or check the file number.',
    UNKNOWN: 'Title number unknown in our registers. Request cannot be processed.',
};

export default function ({
    selectedApplications,
    documentList,
    checkFile,
    deleteFile,
    error: validationError,
    intl,
    timestamp,
    onSelectType,
    close,
    radioButtonValueTypeOfRequest,
    textInputValueOtherTypeOfRequest,
    selectInputValueCountryOfRequestPurpose,
    selectInputValueLanguageOfRequest,
    radioButtonValueRequestPurpose,
    textInputValueApplicableFeesEmail,
    isUrgent,
    textInputValueDispatchOfTheDocumentsEmail,
    textAreaValueAdditionalComments,
    onSubmitRequestSuccess,
    onSubmitRequestError,
}: any) {
    const [loading, setLoading] = useState<boolean>(false);
    const [informationOpened, setInformationOpened] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [applicationNumbersErrors, setApplicationNumbersErrors] = useState<any>({});
    const [loadingApplicationNumbersErrorsCheck, setLoadingApplicationNumbersErrorsCheck] = useState(false);
    const [isModalProofOfPaymentMissingVisible, setIsModalProofOfPaymentMissingVisible] = useState(false);
    const [isModalNotCompatibleApplicationNumbersExistVisible, setIsModalNotCompatibleApplicationNumbersExistVisible] =
        useState('');

    const toggleInformation = () => setInformationOpened(!informationOpened);

    const pleaseChooseMessage = intl && intl.formatMessage({id: 'pleaseChoose'});

    const inputUploadRef: any = useRef(null);

    const DOCUMENT_TYPES = [
        {id: NONE, value: pleaseChooseMessage},
        {id: 'authorization', value: 'Authorization'},
        {id: 'proofOfPayment', value: 'Proof of payment'},
    ];

    useEffect(() => {
        setLoading(true);
        setLoadingApplicationNumbersErrorsCheck(true);
        apiCommonCheckApplicationNumbers({
            applicationNumbers: Object.keys(selectedApplications),
        }).then((JSONResponse: any) => {
            const applicationNumbersErrorsObj: any = {};
            if (JSONResponse && JSONResponse.data && Array.isArray(JSONResponse.data)) {
                JSONResponse.data.forEach(({applicationNumber, errors}: any = {}) => {
                    applicationNumbersErrorsObj[applicationNumber] = errors || [];
                });
                setApplicationNumbersErrors({...applicationNumbersErrorsObj});
            }
            setLoadingApplicationNumbersErrorsCheck(false);
            setLoading(false);
        });
    }, []);

    const onFileUploadHandler = (files: any, event: any) => {
        setInformationOpened(false);
        checkFile(files[0], event);
    };

    const handleDrop = (files: any, event: any) => onFileUploadHandler(files, event);

    const onFileUpload = (event: any) => onFileUploadHandler(event.target.files, event);

    const actions = ModalEndOfFilesPreviousToSignStatusVersion2Actions(deleteFile);

    if (error && documentList.filter((i: any) => i.documentType === null).length === 0) {
        setError('');
    }

    const getApplicationNumberStatus = (applicationNumber: any) => {
        if (!applicationNumbersErrors[applicationNumber]) {
            return APPLICATION_NUMBER_STATUS.UNKNOWN;
        }
        if (Array.isArray(applicationNumbersErrors[applicationNumber])) {
            if (applicationNumbersErrors[applicationNumber].length === 0) {
                return APPLICATION_NUMBER_STATUS.OK;
            }
            if (applicationNumbersErrors[applicationNumber][0] === 'nonCompatible') {
                return APPLICATION_NUMBER_STATUS.NOT_COMPATIBLE;
            }
            if (applicationNumbersErrors[applicationNumber][0] === 'unauthorised') {
                return APPLICATION_NUMBER_STATUS.UNAUTHORIZED;
            }
        }
        return APPLICATION_NUMBER_STATUS.UNKNOWN;
    };

    const sendRequest = () => {
        setLoading(true);
        const JSONRequest = Object.assign(
            {},
            {
                applicationNumbers: Object.keys(selectedApplications || {}).filter(
                    applicationNumber =>
                        getApplicationNumberStatus(applicationNumber) === APPLICATION_NUMBER_STATUS.OK ||
                        getApplicationNumberStatus(applicationNumber) === APPLICATION_NUMBER_STATUS.UNAUTHORIZED
                ),
                requestType:
                    radioButtonValueTypeOfRequest === TYPE_OF_REQUEST_SELECT_OPTIONS.OTHER
                        ? textInputValueOtherTypeOfRequest
                        : radioButtonValueTypeOfRequest,
                alternativeEmail: textInputValueDispatchOfTheDocumentsEmail,
                accountancyEmail: textInputValueApplicableFeesEmail,
                languageId: selectInputValueLanguageOfRequest,
                remarks: textAreaValueAdditionalComments,
                urgent: isUrgent,
            },
            radioButtonValueRequestPurpose && {
                filingPurpose: radioButtonValueRequestPurpose,
            },
            selectInputValueCountryOfRequestPurpose && {
                countryId: selectInputValueCountryOfRequestPurpose,
            }
        );
        apiCertifiedCopiesCreateFolderAndUploadFilesAndSendRequest(JSONRequest, documentList)
            .then((JSONResponse: any) => {
                if (JSONResponse) {
                    setLoading(false);
                    if (JSONResponse.data === 'OK' && JSONResponse.folder) {
                        onSubmitRequestSuccess && onSubmitRequestSuccess({folder: JSONResponse.folder});
                        setLoading(false);
                    }
                    if (JSONResponse.errorMessage) {
                        onSubmitRequestError && onSubmitRequestError(JSONResponse.errorMessage);
                    }
                } else {
                    onSubmitRequestError && onSubmitRequestError();
                }
            })
            .catch(() => onSubmitRequestError && onSubmitRequestError());
    };

    const closeModalProofOfPaymentMissing = () => setIsModalProofOfPaymentMissingVisible(false);

    const onSubmitUrgentRequestWithoutProofOfPaymentClick = () => {
        closeModalProofOfPaymentMissing();
        sendRequest();
    };

    const closeModalNotCompatibleApplicationNumbersExist = () =>
        setIsModalNotCompatibleApplicationNumbersExistVisible('');

    const onButtonSendRequestClick = () => {
        const applicationNumbersNotCompatible: any = [];
        if (documentList.filter((i: any) => i.documentType === null).length > 0) {
            return setError('You have to select a type for each document');
        }
        Object.keys(selectedApplications).forEach(applicationNumber => {
            if (getApplicationNumberStatus(applicationNumber) === APPLICATION_NUMBER_STATUS.NOT_COMPATIBLE) {
                applicationNumbersNotCompatible.push(applicationNumber);
            }
        });
        if (applicationNumbersNotCompatible.length) {
            const plural = applicationNumbersNotCompatible.length > 1;
            let messageStart = plural ? 'numbers ' : 'number';
            let messageEnd = plural ? 'are' : 'is';
            let message = `You cannot proceed sending the request because the application ${messageStart} ${applicationNumbersNotCompatible
                .map((el: any) => `"${el}"`)
                .join(', ')} ${messageEnd} not compatible`;
            return setIsModalNotCompatibleApplicationNumbersExistVisible(message);
        }
        if (isUrgent && documentList.filter((i: any) => i.documentType === 'proofOfPayment').length === 0) {
            return setIsModalProofOfPaymentMissingVisible(true);
        }
        sendRequest();
    };

    return (
        <>
            {isModalProofOfPaymentMissingVisible ? (
                <ModalConfirmVersion2
                    title={`Proof of payment missing`}
                    message={`You have marked this request as urgent but no document with type "Proof of payment" is attached. Are you sure you want to submit the request ?`}
                    buttonName={'Yes'}
                    action={onSubmitUrgentRequestWithoutProofOfPaymentClick}
                    close={closeModalProofOfPaymentMissing}
                />
            ) : null}
            {isModalNotCompatibleApplicationNumbersExistVisible ? (
                <ModalAlertVersion2
                    title={`Not compatible applications`}
                    message={isModalNotCompatibleApplicationNumbersExistVisible}
                    close={closeModalNotCompatibleApplicationNumbersExist}
                />
            ) : null}
            <DragAndDrop handleDrop={handleDrop}>
                <ModalCustomVersion2
                    close={close}
                    header={'Request status before signing'}
                    body={
                        <div>
                            {(documentList || []).length > 0 && (
                                <div style={{marginBottom: 10}}>
                                    <CustomTable
                                        actions={actions}
                                        count={(documentList || []).length}
                                        dataFilter={null}
                                        forehandColumn={(row: any) => (
                                            <img
                                                style={{width: 20, height: 20, cursor: 'pointer'}}
                                                alt={'Download'}
                                                src={getIcon(row.extension || 'pdf')}
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    saveAs(row.file, row.filename);
                                                }}
                                            />
                                        )}
                                        formatFunctions={{
                                            documentType: (documentType: any, object: any) => (
                                                <div style={{marginTop: -5, marginLeft: -10, width: 205}}>
                                                    <SelectInput
                                                        value={documentType ? documentType : pleaseChooseMessage}
                                                        onChange={(event: any) => onSelectType(event, object)}
                                                        list={DOCUMENT_TYPES}
                                                        notAll={true}
                                                        height={25}
                                                        width={180}
                                                    />
                                                    <div style={{clear: 'both'}} />
                                                </div>
                                            ),
                                            filename: (filename: any) => (
                                                <div
                                                    style={{
                                                        width: 430,
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                    }}
                                                    title={(filename || '').length > 50 ? filename : ''}
                                                >
                                                    {filename}
                                                </div>
                                            ),
                                            extension: (extension: any) => (
                                                <img src={getIcon(extension || 'pdf')} alt={extension} />
                                            ),
                                            size: (size: any) => (
                                                <div style={{width: 90}}>{`${Math.floor(size * 100) / 100} KB`}</div>
                                            ),
                                        }}
                                        hideExcelButton={true}
                                        id={'index'}
                                        intl={intl}
                                        notSortable={['filename', 'size', 'documentType']}
                                        noChangePageSize={true}
                                        resultFieldsDefault={['filename', 'size', 'documentType']}
                                        scrollbarHorizontalHide={true}
                                        setLastCursor={null}
                                        tableName={'documentUpload'}
                                        tableType={'OBJECT'}
                                        tableSource={documentList || []}
                                        timestamp={timestamp}
                                        version={2}
                                    />
                                    <div style={{clear: 'both'}} />
                                </div>
                            )}
                            {!loadingApplicationNumbersErrorsCheck ? (
                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <th>
                                                <a>{'Application Number'}</a>
                                            </th>
                                            <th style={{width: 697}}>
                                                <a>{'Remarks'}</a>
                                            </th>
                                        </tr>
                                    </thead>
                                    {Object.keys(applicationNumbersErrors || {}).length ? (
                                        <tbody>
                                            {((selectedApplications && Object.values(selectedApplications)) || []).map(
                                                (applicationNumber: any) => {
                                                    return (
                                                        <tr key={applicationNumber}>
                                                            <td>{applicationNumber}</td>
                                                            <td>
                                                                <ul
                                                                    style={{
                                                                        margin: 0,
                                                                        listStyleType: 'none',
                                                                        paddingLeft: 25,
                                                                    }}
                                                                >
                                                                    <li>
                                                                        {getApplicationNumberStatus(applicationNumber)}
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    ) : null}
                                </table>
                            ) : null}
                            <div style={{clear: 'both'}} />
                            {isUrgent && (
                                <div style={{paddingTop: 20, paddingBottom: 10}}>
                                    {`You ticked the `}
                                    <strong>{`urgent need of the documents, `}</strong>
                                    {`please add proof of payment (transfer order to the bank) as an attached document.`}
                                </div>
                            )}
                            <div style={{clear: 'both'}} />
                            <div style={{minHeight: 40}}>
                                <InputLink
                                    label={`Notes`}
                                    icon={informationOpened ? faChevronDown : faChevronRight}
                                    clickAction={toggleInformation}
                                />
                                <div style={{clear: 'both'}}>{}</div>
                                {informationOpened && <BrowserInfo intl={intl} />}
                                <div style={{clear: 'both'}} />
                            </div>
                            <div style={{clear: 'both'}} />
                        </div>
                    }
                    footer={
                        <>
                            {loading ? <LoadingBar /> : null}
                            {!loading ? (
                                <>
                                    {error || validationError ? <Error>{error || validationError}</Error> : null}
                                    <div className={styles.modalFooterActions}>
                                        <div className={styles.buttonAddDocument}>
                                            <label htmlFor={'upload'}>
                                                <input
                                                    type="file"
                                                    name="files[]"
                                                    id="upload"
                                                    style={{opacity: 0, width: 0}}
                                                    onChange={onFileUpload}
                                                    accept="image/jpg, image/jpeg, image/png, application/pdf"
                                                    ref={inputUploadRef}
                                                />
                                                <Button
                                                    variation={'secondary'}
                                                    clickAction={() =>
                                                        inputUploadRef &&
                                                        inputUploadRef.current &&
                                                        inputUploadRef.current.click()
                                                    }
                                                    disabled={loading}
                                                >{`Upload document(s)`}</Button>
                                            </label>
                                        </div>
                                        <div>
                                            <Button
                                                variation={'danger'}
                                                clickAction={close}
                                                icon={'close'}
                                                className={styles.buttonClose}
                                            >{`Cancel`}</Button>
                                            <Button
                                                clickAction={onButtonSendRequestClick}
                                                icon={'arrowRight'}
                                                disabled={loading}
                                            >{`Send request`}</Button>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </>
                    }
                />
            </DragAndDrop>
        </>
    );
}
