import React from 'react';
import {injectIntl} from 'react-intl';
import {
    Footer,
    FormWrapper,
    HeaderBreadcrumb,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitle,
} from '../../componentsLayout';
import styles from './AuthenticationMyProfile.module.scss';
import {apiMyPVRMyProfile, IUserDetails} from './AuthenticationMyProfileService';
import {checkIfUserHasAccessToMyPVR, getDecodedJWT, trackPageView} from '../../utils';
import {IDecodedJWT} from '../../types';
import ModalErrorVersion2 from '../../commonModals/ModalErrorVersion2';
import {Link} from 'react-router-dom';

interface IProps {
    intl: any;
}

interface IState extends IUserDetails {
    loading: number;
    isModalAskPermissionFromYourAdministratorOpen: boolean;
}

class AuthenticationMyProfile extends React.Component<IProps, IState> {
    jwtToken: IDecodedJWT | null = null;

    constructor(props: IProps) {
        super(props);
        this.jwtToken = getDecodedJWT();
        this.state = {
            login: '',
            fullName: '',
            nature: '',
            userEmail: '',
            companyName: '',
            companyWebsite: '',
            companyPhoneNumber: '',
            userAddress: '',
            userPostCode: '',
            userCity: '',
            userCountry: '',
            loading: 0,
            isModalAskPermissionFromYourAdministratorOpen: false,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'profile'});
        this.loadJSONs();
    }

    loadJSONs = () => {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiMyPVRMyProfile().then(JSONResponse => {
                    if (JSONResponse && JSONResponse.data) {
                        this.setState({...JSONResponse.data}, () => {
                            this.setState(prev => ({loading: prev.loading - 1}));
                        });
                    }
                });
            }
        );
    };

    goToTheCommunicationCentre = () => {
        const userHasAccessToMyPVR = checkIfUserHasAccessToMyPVR(this.jwtToken);
        if (userHasAccessToMyPVR) {
            if (this.jwtToken && this.jwtToken.thirdPartyId === '1') {
                window.open('/communicationCentreInternalInbox', '_blank');
            } else {
                window.open('/communicationCentreClientInbox', '_blank');
            }
        } else {
            this.setState({isModalAskPermissionFromYourAdministratorOpen: true});
        }
    };

    closeModalAskPermissionFromYourAdministrator = () =>
        this.setState({isModalAskPermissionFromYourAdministratorOpen: false});

    renderRow = (title: any, data: any) => (
        <div className={styles.row}>
            <h3>{title}</h3>
            <p>{data}</p>
        </div>
    );

    render() {
        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                {this.state.isModalAskPermissionFromYourAdministratorOpen ? (
                    <ModalErrorVersion2
                        title={`Alert`}
                        message={`Please ask the administrator of your company to give you access rights to MyPVR application.`}
                        close={this.closeModalAskPermissionFromYourAdministrator}
                    />
                ) : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderBreadcrumb
                    links={[
                        <Link key={'/'} to={'/'}>
                            {'User Area'}
                        </Link>,
                        'My Profile',
                    ]}
                />
                <HeaderTitle title={`My Profile`} />
                <section className={styles.warningBanner}>
                    {`For security reasons in your applications, if you would like any changes, please request them through the `}
                    <span
                        onClick={this.goToTheCommunicationCentre}
                        style={{color: '#00457C', cursor: 'pointer'}}
                    >{`Communication Centre.`}</span>
                </section>
                <FormWrapper className={styles.formWrapper}>
                    <div className={styles.formSection}>
                        <h2 className={styles.h2}>{`User details`}</h2>
                        {this.renderRow('Username:', this.state.login)}
                        {this.renderRow('Name:', this.state.fullName)}
                        {this.renderRow('Nature:', this.state.nature)}
                        {this.renderRow("Company's name:", this.state.companyName)}
                        {this.renderRow("Company's website:", this.state.companyWebsite)}
                        {this.renderRow("Company's phone number:", this.state.companyPhoneNumber)}
                    </div>
                    <div>
                        <h2 className={styles.h2}>{`Address details`}</h2>
                        {this.renderRow('Address:', this.state.userAddress)}
                        {this.renderRow('Postcode:', this.state.userPostCode)}
                        {this.renderRow('City:', this.state.userCity)}
                        {this.renderRow('Country:', this.state.userCountry)}
                    </div>
                </FormWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(AuthenticationMyProfile);
