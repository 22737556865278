import React from 'react';
import ModalCustomVersion2 from '../../commonModals/ModalCustomVersion2';
import CustomTable from '~components/CustomTable';
import {injectIntl} from 'react-intl';
import ModalWelcomeStoredImpersonatedUserActionButtons from './ModalWelcomeStoredImpersonatedUsersActionButtons';
import {deleteLocalStorageImpersonate, getLocalStorageImpersonate, ILocalStorageImpersonateItem} from './Welcome.utils';
import styles from './ModalWelcomeStoredImpersonatedUsers.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import Button from '../../componentsFormV2/Button';

interface IProps {
    intl: any;
    close: any;
    impersonatedUsers: ILocalStorageImpersonateItem[];
    onRowClick: any;
}

interface IState {
    timestamp: number;
}

class ModalWelcomeStoredImpersonatedUsers extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            timestamp: Date.now(),
        };
    }

    getSortedImpersonate = () => {
        const impersonatedUsers = getLocalStorageImpersonate();
        return impersonatedUsers.sort(
            (a: ILocalStorageImpersonateItem, b: ILocalStorageImpersonateItem) => b.lastLogin - a.lastLogin
        );
    };

    onTableIconDeleteClick = ({contactId}: ILocalStorageImpersonateItem) => {
        deleteLocalStorageImpersonate(contactId);
        this.setState({timestamp: Date.now()});
    };

    render() {
        const actions = ModalWelcomeStoredImpersonatedUserActionButtons(this.onTableIconDeleteClick);
        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={`Please select a user to impersonate`}
                body={
                    <div>
                        <div style={{visibility: 'hidden'}}>{'spacer'}</div>
                        <div style={{marginTop: -20}}>
                            <CustomTable
                                actions={actions}
                                count={this.props.impersonatedUsers.length}
                                formatFunctions={{
                                    name: (name: string) => (
                                        <div className={styles.tableRow}>
                                            <FontAwesomeIcon icon={faUser as any} style={{marginRight: 10}} />
                                            {name}
                                        </div>
                                    ),
                                }}
                                hideExcelButton={true}
                                hiddenHeader={true}
                                id={'contactId'}
                                intl={this.props.intl}
                                resultFieldsDefault={['name']}
                                rowClick={this.props.onRowClick}
                                tableName={'lastImpersonated'}
                                tableType={'OBJECT'}
                                tableSource={this.getSortedImpersonate()}
                                timestamp={this.state.timestamp}
                                unlimited={true}
                            />
                        </div>
                    </div>
                }
                footer={<Button variation={'danger'} icon={'close'} clickAction={this.props.close}>{`Close`}</Button>}
            />
        );
    }
}

export default injectIntl(ModalWelcomeStoredImpersonatedUsers);
