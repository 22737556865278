import {postFetch, API_SERVERLESS} from '~commonApi';

export function apiDenominationInsert(jsonData) {
    return postFetch(`${API_SERVERLESS}/mypvr/v3/denominationInsert`, true, jsonData).then(jsonResponse => {
        if (jsonResponse) {
            return jsonResponse;
        }
    });
}

export function apiDenominationUpdate(jsonData) {
    return postFetch(`${API_SERVERLESS}/mypvr/v3/denominationUpdate`, true, jsonData).then(jsonResponse => {
        if (jsonResponse) {
            return jsonResponse;
        }
    });
}
