import React, {useEffect, useState} from 'react';
import CustomTable from '../../../../components/CustomTable';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {Footer, HeaderLoading, HeaderLogoMenu, HeaderTitleAndVersion, MainWrapper} from '../../../../componentsLayout';
import {History} from 'history';
import {ModalErrorVersion2} from '../../../../commonModals';
import NavigationACArea from '../../../../shared/NavigationACArea';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {apiMembersListFetch, MembersList, MembersListFetchData} from './MembersListACAREAService';
import {injectIntl, IntlShape} from 'react-intl';
import sendToList from './data/ContactList.json';
import styles from './MembersListACAREA.module.scss';
import {trackPageView} from '../../../../utils';

const modalScreenTypes = {
    ACAREA_MEMBERS_LIST: 'ACAREA_MEMBERS_LIST',
    ERROR: 'ERROR',
};

const DEFAULT_PAGE_SIZE = 10;

interface MembersListACAREAProps {
    intl: IntlShape;
    history: History;
}

const MembersListACAREA = (props: MembersListACAREAProps & RouteComponentProps) => {
    const RESULT_FIELDS_DEFAULT = ['countryId', 'forename', 'member_status', 'name'];
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [acareaMembersList, setAcareaMembersList] = useState<Array<MembersList>>([]);
    const [membersListRowObj, setMembersListRowObj] = useState<MembersList | null>(null);
    const [showExpandedRow, setShowExpandedRow] = useState<{[key: number]: boolean}>({});
    const [showRowNumber, setShowRowNumber] = useState(DEFAULT_PAGE_SIZE);

    const closeErrorModal = () => {
        setErrorMessage(null);
        setModalScreen(null);
    };

    const getAcareaMembersList = () => {
        setLoading(true);
        apiMembersListFetch()
            .then((jsonResponse: MembersListFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.membersList) {
                    setAcareaMembersList(jsonResponse.data.membersList);
                }
            })
            .catch(error => {
                setErrorMessage(`ACAREA Members list fetch error: ${error.message}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        trackPageView({documentTitle: 'acareaMembersList'});
        getAcareaMembersList();
    }, []);

    const getFullName = (forename: string | undefined, rowObject: MembersList | null) => {
        if (rowObject) {
            const fullName = rowObject?.civility + ' ' + forename + ' ' + rowObject?.surname;
            return fullName.replace(/\s+/g, ' ');
        } else {
            return '';
        }
    };

    const getDataSheetFormSectionArea = (rowElement: MembersList) => {
        return (
            <>
                <DataSheetFormFields
                    label={`Full Name`}
                    data={getFullName(rowElement?.forename, rowElement)}
                    loading={false}
                    disabled={true}
                    double={true}
                />
                <div style={{clear: 'both'}} />
                <DataSheetFormFields
                    label={`Function`}
                    data={rowElement?.function}
                    loading={false}
                    disabled={true}
                    double={true}
                />
                <div style={{clear: 'both'}} />
                <DataSheetFormFields
                    label={`Name`}
                    data={rowElement?.name}
                    loading={false}
                    disabled={true}
                    double={true}
                />
                <div style={{clear: 'both'}} />
                <DataSheetFormFields
                    label={`Email`}
                    data={rowElement?.mail}
                    loading={false}
                    disabled={true}
                    double={true}
                />
                <div style={{clear: 'both'}} />
                <DataSheetFormFields
                    label={`Phone`}
                    data={rowElement?.phone}
                    loading={false}
                    disabled={true}
                    double={true}
                />
                <div style={{clear: 'both'}} />
                <DataSheetFormFields
                    label={`Fax`}
                    data={rowElement?.fax}
                    loading={false}
                    disabled={true}
                    double={true}
                />
                <div style={{clear: 'both'}} />
                <DataSheetFormFields
                    label={`Address`}
                    loading={false}
                    disabled={true}
                    data={rowElement?.address}
                    double={true}
                />
            </>
        );
    };

    const getRowClass = (rowObject: MembersList) => {
        const clickedRowIndex =
            membersListRowObj !== null &&
            acareaMembersList.findIndex(
                el => el.memberId === membersListRowObj.memberId && el.memberId === rowObject.memberId
            );
        const clickedRowIndexClass =
            membersListRowObj !== null && Number(clickedRowIndex) >= 0 ? Number(clickedRowIndex) + 1 : clickedRowIndex;
        const className =
            clickedRowIndex !== -1 && showExpandedRow[acareaMembersList.indexOf(rowObject)] === true
                ? clickedRowIndexClass + '-clickedRow'
                : '0-clickedRow';
        return styles[className];
    };

    const getTableArea = () => {
        return acareaMembersList.length === 0 ? (
            <div style={{paddingTop: 20, textAlign: 'left'}}>
                <b style={{color: 'red', textAlign: 'left'}}>
                    <span className="ng-scope">{`No ACAREA Member available`}</span>
                </b>
            </div>
        ) : (
            <div className={styles.membersList}>
                <CustomTable
                    version={2}
                    {...props}
                    useExpandedRow={true}
                    forehandColumn={true}
                    expandedRow={(rowObject: MembersList) =>
                        showExpandedRow[acareaMembersList.indexOf(rowObject) % showRowNumber] === true &&
                        getDataSheetFormSectionArea(rowObject)
                    }
                    showExpandedRow={showExpandedRow}
                    tableName={'acareaMembersList'}
                    tableType={'OBJECT'}
                    tableSource={acareaMembersList}
                    id={'memberId'}
                    hideExcelButton={true}
                    resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                    intl={props.intl}
                    defaultOrder={'countryId'}
                    reverseOrder={false}
                    count={acareaMembersList.length}
                    rowClick={(_memberId: number, rowObject: MembersList) => {
                        const tableRowNumber = document.querySelectorAll(
                            "div[class^='MembersListACAREA_membersList'] table tr[class^='CustomTable_rowData']"
                        ).length;
                        setShowRowNumber(tableRowNumber > DEFAULT_PAGE_SIZE ? tableRowNumber : DEFAULT_PAGE_SIZE);
                        setMembersListRowObj(rowObject);
                        const dataFormSection: {[key: number]: boolean} = {};
                        dataFormSection[acareaMembersList.indexOf(rowObject) % showRowNumber] =
                            !showExpandedRow[acareaMembersList.indexOf(rowObject) % showRowNumber];
                        setShowExpandedRow(dataFormSection);
                    }}
                    rowClass={getRowClass}
                    formatFunctions={{
                        forename: (forename: string, rowObject: MembersList) => getFullName(forename, rowObject),
                    }}
                />
            </div>
        );
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={'AC Area'} />
            <NavigationACArea />
            <MainWrapper>{!loading && <div className={styles.membersList}>{getTableArea()}</div>}</MainWrapper>
            <Footer customSendToList={sendToList.slice(1)} headerTitle={'Contact Webmaster'} subjectTag={'[ACAREA]'} />
        </>
    );
};

export default injectIntl(withRouter(MembersListACAREA));
