import React from 'react';
import {IGroup, IScreenData} from '../../types';

const stepStates = {
    DISABLED: 0,
    READY: 1,
    COMPLETE: 2,
};

interface IProps {
    intl: any;
    history: any;
}

export interface IWizardState {
    name: string;
    form: any;
    currentStepNumber: number;
    groupsQuestion: IGroup[];
    validatedSteps: boolean[];
    screenData: IScreenData;
    modalQueue: any[];
}

class WizardWrapper<_1, S extends IWizardState> extends React.Component<IProps, S> {
    constructor(props: IProps) {
        super(props);
        this.setState({
            name: '',
            form: null,
            currentStepNumber: 0,
            groupsQuestion: [],
            validatedSteps: [],
            screenData: {
                hash: Date.now(),
                responsesClient: {},
                tableQuestionsKeysIterationsMap: {},
                groupsIdsIterationsMap: {},
                hasClickedOnSaveAndValidate: false,
                showHidden: false,
            },
            modalQueue: [],
        });
    }

    isValidStepNumber = (stepNumber: any) => {
        return stepNumber < this.state.groupsQuestion.length && stepNumber >= 0;
    };

    getStepState = (step: any) => {
        if (step.isGroup === false) {
            return stepStates.DISABLED;
        }
        if (step.requiredStepNumber && this.isValidStepNumber(step.requiredStepNumber)) {
            return stepStates.DISABLED;
        } else if (step.stepForm && step.stepForm.valid) {
            return stepStates.COMPLETE;
        } else {
            return stepStates.READY;
        }
    };

    getCurrentStep = () => {
        return (
            this.state.groupsQuestion &&
            this.state.groupsQuestion.length > 0 &&
            this.state.groupsQuestion[this.state.currentStepNumber]
        );
    };

    goToStepByReference = (step: any) => {
        const stepNumber = this.state.groupsQuestion.indexOf(step);
        this.goToStep(stepNumber);
    };

    canGoToStep = (stepNumber: any) => {
        if (!this.isValidStepNumber(stepNumber)) {
            return false;
        }
        const newStep = this.state.groupsQuestion[stepNumber];
        return this.getStepState(newStep) !== stepStates.DISABLED;
    };

    goToStep = (stepNumber: any) => {
        if (this.canGoToStep(stepNumber)) {
            this.setState({currentStepNumber: stepNumber});
            window.scroll({top: 0, behavior: 'smooth'});
        }
    };

    updateScreenData = (screenData: Partial<IScreenData>, callback?: any) =>
        this.setState(
            prev => ({screenData: Object.assign({}, prev.screenData, screenData)}),
            () => {
                callback && callback();
            }
        );

    modalQueuePush = (modal: any) =>
        this.setState((prev: any) => {
            const modalQueue = (prev.modalQueue || []).slice(0);
            modalQueue.push(modal);
            return {modalQueue};
        });

    modalQueuePop = () =>
        this.setState((prev: any) => {
            const modalQueue = (prev.modalQueue || []).slice(0);
            modalQueue.pop();
            return {modalQueue};
        });

    render() {
        return (
            <>
                {(this.state.modalQueue || []).map(modal => {
                    return modal;
                })}
            </>
        );
    }
}

export default WizardWrapper;
