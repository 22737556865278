import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import AssistedEntries11 from './screenshots/Assisted_Entries_1_1.png';
import AssistedEntries12 from './screenshots/Assisted_Entries_1_2.png';
import AssistedEntries21 from './screenshots/Assisted_Entries_2_1.png';
import AssistedEntries31 from './screenshots/Assisted_Entries_3_1.png';
import AssistedEntries41 from './screenshots/Assisted_Entries_4_1.png';
import AssistedEntries51 from './screenshots/Assisted_Entries_5_1.png';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeShowQuestion = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>
                        Depending on the custom param, it displays various inputs / selects
                    </p>
                    <p>Examples for custom params:</p>
                    <p className={styles.helpBold}>autocomplete:yes;on:countries;controllist:yes;</p>
                    <img src={AssistedEntries11} alt="Assisted Entries" className={styles.modalHelpImage} />
                    <img src={AssistedEntries12} alt="Assisted Entries" className={styles.modalHelpImage} />
                    <p className={styles.helpBold}>on:stages;combobox:yes;</p>
                    <img src={AssistedEntries21} alt="Assisted Entries" className={styles.modalHelpImage} />
                    <p className={styles.helpBold}>on:procedures;combobox:yes;</p>
                    <img src={AssistedEntries31} alt="Assisted Entries" className={styles.modalHelpImage} />
                    <p className={styles.helpBold}>on:component_ownership;combobox:yes;</p>
                    <img src={AssistedEntries41} alt="Assisted Entries" className={styles.modalHelpImage} />
                    <p className={styles.helpBold}>on:stages_dus;combobox:yes;</p>
                    <img src={AssistedEntries51} alt="Assisted Entries" className={styles.modalHelpImage} />
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeShowQuestion;
