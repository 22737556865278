import React from 'react';
import cx from 'classnames';
import {LABEL_POSITION, labelIcons, TLabelIcon, TLabelPosition} from './TextInput';
import styles from './RadioButton.module.scss';

interface IRadioButtonProps {
    className?: any;
    clickEvent?: any;
    disabled?: boolean;
    groupName: string;
    label?: string;
    labelIcon?: TLabelIcon;
    labelPosition?: TLabelPosition;
    radioButtonList: {description: string; value: any}[];
    layout?: 'horizontal';
    mandatory?: boolean;
    style?: any;
    value: any;
}

function RadioButton({
    radioButtonList,
    groupName,
    value,
    clickEvent,
    className,
    disabled,
    label,
    labelIcon,
    labelPosition,
    layout,
    mandatory,
    style: userStyle,
}: IRadioButtonProps) {
    return (
        <section
            className={cx(
                styles.section,
                ![LABEL_POSITION.LEFT_INSIDE, LABEL_POSITION.LEFT_OUTSIDE].includes(labelPosition) &&
                    styles[`sectionLabelPositionTop`],
                className ? className : null
            )}
            style={userStyle}
        >
            {label ? (
                <label
                    className={cx(
                        styles.label,
                        labelPosition === LABEL_POSITION.LEFT_INSIDE && styles.labelPositionLeftInside,
                        labelPosition === LABEL_POSITION.LEFT_OUTSIDE && styles.labelPositionLeftOutside,
                        !labelPosition && styles.labelPositionTop
                    )}
                >
                    {labelIcon ? labelIcons[labelIcon] : null}
                    {label || null}
                    {mandatory ? <span className={styles.mandatoryStar}>*</span> : null}
                </label>
            ) : null}
            <div
                className={cx(
                    styles.radioButtonsWrap,
                    layout === 'horizontal' ? styles.radioButtonsWrapHorizontal : null
                )}
            >
                {radioButtonList.map(radioElement => {
                    return (
                        <React.Fragment key={radioElement.value}>
                            <label
                                onClick={() => clickEvent(radioElement.value)}
                                style={{
                                    pointerEvents: disabled ? 'none' : undefined,
                                }}
                            >
                                <input
                                    id={`${groupName}-${radioElement.value}`}
                                    checked={radioElement.value === value}
                                    onChange={event => clickEvent(event.target.value)}
                                    type="radio"
                                    value={radioElement.value}
                                    name={groupName}
                                    disabled={disabled}
                                    style={{cursor: 'pointer'}}
                                />
                                <div
                                    style={{
                                        display: 'inline-block',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {radioElement.description}
                                </div>
                            </label>
                            {/*{radioElement.value === value && radioElement.innerHTML}*/}
                        </React.Fragment>
                    );
                })}
            </div>
        </section>
    );
}

export default RadioButton;
