import React, {useState} from 'react';
import {ModalContactUsVersion2} from '../commonModals';
import Navigation from '../componentsLayout/Navigation';
import {getUserRoles} from '../screens/TLO/CommonFunctions/CommonFunctions';

const webmaster = [
    {
        label: 'Webmaster',
        email: 2,
    },
];

interface INavigationTLO {
    activeTitle?: string;
}

function NavigationTLO({activeTitle}: INavigationTLO) {
    const [isModalContactUsOpen, setIsModalContactUsOpen] = useState(false);
    const [userRoles] = useState(getUserRoles());

    const links = [{to: '/tlo', title: 'Dashboard'}];

    if (userRoles.includes('EOADM')) {
        links.push({to: '/tloAdministrator', title: 'Administrator'});
    }

    if (userRoles.some(role => ['WETLO', 'TLOOF', 'EOADM'].includes(role))) {
        links.push({to: '/tloMyApplicationsMyReports', title: 'My Applications'});
    }

    if (userRoles.some(role => ['WETLO', 'TLOOF', 'EOADM'].includes(role))) {
        links.push({to: '/tloMyContractDesignationAgreement', title: 'My Contract'});
    }

    if (!userRoles.includes('EOVIS')) {
        links.push({to: '/tloMyRnDProjects', title: 'My R&D Projects'});
    }

    if (!userRoles.includes('EOVIS')) {
        links.push({to: '/tloQasRequirements', title: 'My Quality Audit Area'});
    }

    if (userRoles.some(role => ['WETLO', 'TLOOF', 'EOADM'].includes(role))) {
        links.push({to: '/tloTqSearch', title: 'TQ Search'});
    }

    if (userRoles.some(role => ['WETLO', 'TLOOF', 'EOADM'].includes(role))) {
        links.push({to: '/tloDocumentsVademecum', title: 'Documents'});
    }

    if (userRoles.some(role => ['WETLO', 'TLOOF', 'EOADM'].includes(role))) {
        links.push({to: '/tloCpvoBox', title: 'CPVO Box'});
    }

    return (
        <>
            {isModalContactUsOpen ? (
                <ModalContactUsVersion2
                    headerTitle={'Contact Webmaster'}
                    sendTo={webmaster[0].email}
                    customSendToList={webmaster}
                    subjectTag={'[TLO]'}
                    close={() => setIsModalContactUsOpen(false)}
                />
            ) : null}
            <Navigation
                activeTitle={activeTitle}
                links={links}
                linkModals={[{onClick: () => setIsModalContactUsOpen(true), title: 'Contact Webmaster'}]}
            />
        </>
    );
}

export default NavigationTLO;
