import React from 'react';
import {injectIntl} from 'react-intl';
import styles from './BackOfficeTemplates.module.scss';
import BackOfficeTemplatesActionButtons from './BackOfficeTemplatesActionButtons';
import CustomTable from '~components/CustomTable';
import TextInput from '~components/TextInput';
import Checkbox from '~components/Checkbox';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {faInfo, faPlus} from '@fortawesome/free-solid-svg-icons';
import {
    apiBackOfficeDeleteTemplate,
    apiBackOfficeNewVersionTemplate,
    apiTemplateSearch,
} from './BackOfficeTemplatesService';
import DEFAULT_CRITERIA from './data/DEFAULT_CRITERIA.json';
import DEFAULT_RESULT_FIELDS from './data/DEFAULT_RESULT_FIELDS.json';
import StatusField from '~shared/StatusField';
import {htmlToText, joinParams, loadUrlParams as loadUrlParamsFn, trackPageView} from '../../utils';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationBackOffice from '../../shared/NavigationBackOffice';
import {Button} from '../../componentsFormV2';
import {ModalAlertVersion2, ModalConfirmVersion2} from '../../commonModals';

const STATUS = {
    IN_PREPARATION: '1',
    ACTIVE: '2',
    TERMINATED: '3',
};
const CHECKBOX_WIDTH = 188;

class BackOfficeTemplates extends React.Component {
    initialCriteria = null;

    constructor(props) {
        super(props);
        this.initialCriteria = Object.assign({}, DEFAULT_CRITERIA);
        this.initialCriteria.status = [STATUS.IN_PREPARATION, STATUS.ACTIVE].join(',');
        this.state = {
            loading: false,
            templates: null,
            criteria: this.initialCriteria,
            criteriaCount: 0,
            modalConfirmNewVersion: null,
            modalConfirmDeletion: null,
            modalAlertTitle: null,
            modalAlertMessage: null,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'templates'});
        self.setStateFunction = this.setStateFunction;
        self.defaultState = this.state;
        this.loadUrlParams();
    }

    setStateFunction = state => this.setState(state);

    loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const criteria = Object.assign({}, this.initialCriteria);
            const params = domainArray.pop();
            params.split('&').forEach(param => {
                const paramElements = param.split('=');
                if (paramElements.length === 2) {
                    const key = paramElements[0];
                    criteria[key] = decodeURIComponent(paramElements[1]);
                }
            });
            if (Object.keys(criteria).length > 0) {
                const criteriaCount = this.countCriteria(criteria);
                this.setState({criteria, criteriaCount}, this.search);
            }
        }
    };

    search = () => {
        this.setState({loading: true}, () => {
            const {criteria} = this.state;
            const parsedCriteria = Object.assign({}, criteria);
            this.buildParams(parsedCriteria);
            apiTemplateSearch(parsedCriteria, DEFAULT_CRITERIA)
                .then(jsonResponse => {
                    if (jsonResponse && jsonResponse.data && jsonResponse.data.templates) {
                        this.setState({
                            templates: jsonResponse.data.templates,
                            timestamp: Date.now(),
                            loading: false,
                        });
                    }
                })
                .catch(error => {
                    ERROR`Template search list error: ${error.message}`;
                })
                .then(() => this.setState({loading: false}));
        });
    };

    buildParams = criteria => {
        const paramArray = Object.keys(criteria)
            .filter(i => criteria[i] !== DEFAULT_CRITERIA[i])
            .map(key => `${key}=${criteria[key]}`);
        this.props.history.replace(`/templates${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`);
    };

    countCriteria = () => {
        return 0;
    };

    resetCriteria = () =>
        this.setState({criteria: this.initialCriteria, criteriaCount: 0, templates: null, timestamp: Date.now()});

    updateCriteriaValue = (criteriaValue, callback) => {
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                const criteriaCount = this.countCriteria(criteria);
                return {criteria, criteriaCount};
            },
            () => callback && callback()
        );
    };

    goToTemplateDetails = templateId => {
        const params = loadUrlParamsFn();
        this.props.history.push(
            `/templateDetails?templateId=${templateId}${(params && `&${joinParams(params)}`) || ''}`
        );
        window.scrollTo(0, 0);
    };

    openModalConfirmNewVersion = templateId => this.setState({modalConfirmNewVersion: templateId});

    closeModalConfirmNewVersion = () => this.setState({modalConfirmNewVersion: null});

    closeModalAlert = () => this.setState({modalAlertTitle: null, modalAlertMessage: null});

    createNewTemplateVersionAction = () => {
        const templateId = this.state.modalConfirmNewVersion;
        this.setState({loading: true, modalConfirmNewVersion: null}, () => {
            apiBackOfficeNewVersionTemplate({templateId}).then(jsonResponse =>
                this.setState({loading: false}, () => {
                    jsonResponse.templateId && this.goToTemplateDetails(jsonResponse.templateId);
                    jsonResponse.errorMessage &&
                        this.setState({
                            modalAlertTitle: `New version failed`,
                            modalAlertMessage: jsonResponse.errorMessage,
                        });
                })
            );
        });
    };

    openModalConfirmDeletion = templateId => this.setState({modalConfirmDeletion: templateId});
    closeModalConfirmDeletion = () => this.setState({modalConfirmDeletion: null});

    deleteTemplateAction = () => {
        const templateId = this.state.modalConfirmDeletion;
        this.setState({loading: true, modalConfirmDeletion: null}, () => {
            apiBackOfficeDeleteTemplate({templateId}).then(jsonResponse => {
                this.setState({loading: false}, () => {
                    jsonResponse.data === 'OK' && this.search(true);
                    jsonResponse.errorMessage &&
                        this.setState({
                            modalAlertTitle: `Template not deleted`,
                            modalAlertMessage: jsonResponse.errorMessage,
                        });
                });
            });
        });
    };

    toggleStatus = status => {
        let statusArray = (this.state.criteria.status || '') === '' ? [] : this.state.criteria.status.split(',');
        if (statusArray.indexOf(status) !== -1) {
            statusArray = statusArray.filter(i => i !== status);
        } else {
            statusArray.push(status);
        }
        return statusArray.join(',');
    };

    onTemplateNameInputChange = ({target: {value: templateName}}) => this.updateCriteriaValue({templateName});

    onInPreparationCheckboxChange = () => this.updateCriteriaValue({status: this.toggleStatus(STATUS.IN_PREPARATION)});

    onActiveCheckboxChange = () => this.updateCriteriaValue({status: this.toggleStatus(STATUS.ACTIVE)});

    onTerminatedCheckboxChange = () => this.updateCriteriaValue({status: this.toggleStatus(STATUS.TERMINATED)});

    render() {
        const actions = BackOfficeTemplatesActionButtons(
            this.props,
            this.openModalConfirmNewVersion,
            this.openModalConfirmDeletion
        );
        return (
            <>
                {this.state.modalConfirmNewVersion !== null ? (
                    <ModalConfirmVersion2
                        title={`New version`}
                        message={`Are you sure you want to create a new version of this template?`}
                        buttonName={'Yes'}
                        action={this.createNewTemplateVersionAction}
                        close={this.closeModalConfirmNewVersion}
                    />
                ) : null}
                {this.state.modalConfirmDeletion !== null ? (
                    <ModalConfirmVersion2
                        title={`Delete template`}
                        message={`Are you sure you want to delete this template?`}
                        buttonName={'Delete'}
                        action={this.deleteTemplateAction}
                        close={this.closeModalConfirmDeletion}
                    />
                ) : null}
                {this.state.modalAlertMessage !== null ? (
                    <ModalAlertVersion2
                        message={this.state.modalAlertMessage}
                        title={this.state.modalAlertTitle}
                        close={this.closeModalAlert}
                    />
                ) : null}
                {this.state.loading ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Web Back Office`} />
                <NavigationBackOffice />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <TextInput
                            label={`Template name`}
                            filter={'Contains'}
                            infoIcon={faInfo}
                            onChange={this.onTemplateNameInputChange}
                            value={this.state.criteria.templateName}
                            double={true}
                        />
                        <div className={styles.buttonCreateContainer}>
                            <FormFooterButton
                                color={buttonColor.green}
                                clickAction={() => this.goToTemplateDetails(0)}
                                icon={faPlus}
                            >{`Create a new template`}</FormFooterButton>
                        </div>
                        <div style={{clear: 'both'}} />
                        <Checkbox
                            clickAction={this.onInPreparationCheckboxChange}
                            simple={true}
                            label={`Under preparation`}
                            value={this.state.criteria.status.indexOf(STATUS.IN_PREPARATION) !== -1}
                            width={CHECKBOX_WIDTH}
                        />
                        <Checkbox
                            clickAction={this.onActiveCheckboxChange}
                            simple={true}
                            label={`Active`}
                            value={this.state.criteria.status.indexOf(STATUS.ACTIVE) !== -1}
                            width={CHECKBOX_WIDTH}
                        />
                        <Checkbox
                            clickAction={this.onTerminatedCheckboxChange}
                            simple={true}
                            label={`Terminated`}
                            value={this.state.criteria.status.indexOf(STATUS.TERMINATED) !== -1}
                            width={CHECKBOX_WIDTH}
                        />
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            <Button clickAction={this.resetCriteria} variation={'secondary'}>{`Clear fields`}</Button>
                            <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    <CustomTable
                        {...this.props}
                        version={2}
                        notSortable={DEFAULT_RESULT_FIELDS}
                        tableName={'templates'}
                        tableType={'OBJECT'}
                        tableSource={this.state.templates}
                        timestamp={this.state.timestamp}
                        dataFilter={null}
                        id={'templateId'}
                        rowClick={this.goToTemplateDetails}
                        setLastCursor={null}
                        resultFieldsDefault={DEFAULT_RESULT_FIELDS}
                        intl={this.props.intl}
                        defaultOrder={'displayOrder'}
                        reverseOrder={false}
                        filterFunctions={null}
                        count={(this.state.templates || []).length}
                        formatFunctions={{
                            nameLabelValueHTML: nameLabelValueHTML => htmlToText(nameLabelValueHTML),
                            templateComment: templateComment => htmlToText(templateComment),
                            status: statusId => <StatusField intl={this.props.intl} statusId={statusId} />,
                        }}
                        actions={actions}
                    />
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(BackOfficeTemplates);
