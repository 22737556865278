import React from 'react';
import cx from 'classnames';
import stylesNew from './TextAreaInput.module.scss';
import stylesOld from './TextAreaInputOld.module.scss';
import InputContainer from '~componentsForm/InputContainer';
import FirstRow from '~componentsForm/FirstRow';
import SecondRow from '~componentsForm/SecondRow';
import FirstRowLabel from '~componentsForm/FirstRowLabel';
import Label from './Label';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Error from './Error';
import Remark from './Remark';
import FormFooterButton, {buttonColor} from '../componentsForm/FormFooterButton';
import CustomPopoverInput from './CustomPopoverInput';

export default function ({
    children,
    double,
    triple,
    label,
    mandatory,
    value,
    onChange,
    maxCounter,
    counter,
    disabled,
    rows,
    onClick,
    outsideLabel,
    outsideLabelWithHTML,
    outsideLabelWidth,
    buttonIcon,
    buttonAction,
    popOverText,
    popOverTextFirstRow,
    error,
    width,
    remark,
    isRemarkTranslationMissing,
    isTranslationMissing,
    oldBackOfficeStyles,
    oldBackOfficeStylesError,
    isRemarkTextarea,
    noIcon,
    placeholder,
    PDFVersion,
    dynamicHeight,
    buttonTitle,
    buttonTitleIcon,
    buttonTitleClickAction,
    styleInputContainer,
    size,
}) {
    let styles = oldBackOfficeStyles ? stylesOld : stylesNew;
    const MIN_TEXTAREA_HEIGHT = 32;
    const textareaRef = React.useRef(null);
    React.useLayoutEffect(() => {
        if (dynamicHeight) {
            textareaRef.current.style.height = 'inherit';
            textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, MIN_TEXTAREA_HEIGHT)}px`;
        }
    }, [value]);

    const normalStyle = rows && !isNaN(rows) && rows > 0 && {height: `${rows * 1.5}em`};

    return (
        <React.Fragment>
            {outsideLabel && (
                <div className={styles.label}>
                    <Label width={outsideLabelWidth}>{outsideLabel}</Label>
                </div>
            )}
            {outsideLabelWithHTML && (
                <div className={cx(styles.label, isTranslationMissing && styles.translationMissing)}>
                    <Label width={outsideLabelWidth}>{outsideLabelWithHTML}</Label>
                </div>
            )}
            <InputContainer {...{double, triple, width, noIcon, size}} style={styleInputContainer}>
                <FirstRow>
                    <FirstRowLabel {...{mandatory, popOverTextFirstRow}}>{label}</FirstRowLabel>
                    <React.Fragment>{children}</React.Fragment>
                </FirstRow>
                <SecondRow buttonIcon={popOverText || buttonIcon} {...{disabled, rows, oldBackOfficeStyles}}>
                    <div style={{display: 'table', width: '100%'}}>
                        <div style={{display: 'table-row'}}>
                            <div style={{display: 'table-cell'}}>
                                <textarea
                                    ref={textareaRef}
                                    className={cx(
                                        styles.area,
                                        disabled && styles.areaDisabled,
                                        oldBackOfficeStylesError && styles.hasError
                                    )}
                                    value={value || ''}
                                    onClick={onClick}
                                    onChange={onChange}
                                    disabled={disabled && 'disabled'}
                                    placeholder={placeholder}
                                    style={{
                                        ...(dynamicHeight
                                            ? {
                                                  minHeight: MIN_TEXTAREA_HEIGHT,
                                                  resize: 'none',
                                              }
                                            : normalStyle),
                                        ...(onClick ? {caretColor: 'transparent', cursor: 'pointer'} : {}),
                                    }}
                                />
                            </div>
                            {counter && counter > 0 ? (
                                <div style={{display: 'table-cell', width: 40}}>
                                    <div
                                        className={cx(styles.counter, counter === maxCounter && styles.maxCounterLimit)}
                                    >{`${counter}/${maxCounter || 150}`}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div style={{clear: 'both'}}>{}</div>
                </SecondRow>
                {popOverText && <CustomPopoverInput content={popOverText} />}
                {buttonIcon && buttonIcon !== 'none' && (
                    <button type="button" className={styles.button} onClick={buttonAction}>
                        <FontAwesomeIcon icon={buttonIcon} color="green" />
                    </button>
                )}
                {buttonTitle && buttonTitleClickAction ? (
                    <div style={{marginTop: 7, float: 'right'}}>
                        <FormFooterButton
                            color={buttonColor.whiteGreen}
                            clickAction={buttonTitleClickAction}
                            icon={buttonTitleIcon}
                            disabled={disabled || false}
                            width={undefined}
                            extraStyles={undefined}
                            height={undefined}
                            float={undefined}
                        >
                            {buttonTitle}
                        </FormFooterButton>
                    </div>
                ) : null}
                {remark && (
                    <div>
                        <Remark remark={remark} isRemarkTranslationMissing={isRemarkTranslationMissing} />
                    </div>
                )}
                {error && (
                    <div className={cx(styles.errorWrap, isRemarkTextarea && styles.remarkTextarea)}>
                        <Error wizardResponse={true}>{error}</Error>
                    </div>
                )}
                <div style={{clear: 'both'}} />
            </InputContainer>
        </React.Fragment>
    );
}
