import React from 'react';
import {Button} from '../../../../../../componentsFormV2';
import Checkbox from '~components/Checkbox';
import DateInput from '~components/DateInput';
import {FormFooter} from '../../../../../../componentsLayout';
import {MyRegistersFilterCriteriaAreaProps} from './props/MyRegistersFilterCriteriaAreaProps';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import TextLabelInput from '../../../../../../components/TextLabelInput';
import styles from './MyRegistersFilterCriteriaArea.module.scss';

export default function MyRegistersFilterCriteriaArea(props: MyRegistersFilterCriteriaAreaProps) {
    const groupVarietalOptions = [
        {id: '1', value: 'Agricultural'},
        {id: '2', value: 'Vegetable'},
        {id: '3', value: 'Fruit'},
        {id: '4', value: 'Ornamental'},
        {id: '5', value: 'Forest'},
    ];

    const typeOptions = [
        {id: '1', value: 'Examination'},
        {id: '2', value: 'Take over'},
    ];

    const finalInterimOptions = [
        {id: '0', value: 'Preliminary'},
        {id: '1', value: 'Interim'},
        {id: '2', value: 'Final'},
    ];

    const statusOptions = [
        {id: '1', value: 'Positive'},
        {id: '2', value: 'Negative'},
        {id: '3', value: 'Undefined'},
    ];

    const onOrderIdChange = ({target: {value: orderId}}: React.ChangeEvent<HTMLInputElement>) =>
        props.updateCriteriaValue({orderId});

    const onRegisterIdChange = ({target: {value: registerId}}: React.ChangeEvent<HTMLInputElement>) =>
        props.updateCriteriaValue({registerId});

    const onReferenceChange = ({target: {value: reference}}: React.ChangeEvent<HTMLInputElement>) =>
        props.updateCriteriaValue({reference});

    const onDenominationChange = ({target: {value: denomination}}: React.ChangeEvent<HTMLInputElement>) =>
        props.updateCriteriaValue({denomination});

    const onSpeciesSelectionChange = (selectedSpecies: {[key: string]: string}) =>
        props.updateCriteriaValue(
            {
                speciesId: Object.keys(selectedSpecies).join(),
                specieName: '',
            },
            () => props.updateSelectedSpecies(selectedSpecies)
        );

    const onSpecieNameChange = (specieName: string) => props.updateCriteriaValue({specieName});

    const onSpecieNameFilterChange = ({target: {value: speciesNameFilter}}: React.ChangeEvent<HTMLInputElement>) =>
        props.updateCriteriaValue({speciesNameFilter});

    const onGroupVarietalChange = ({target: {value: groupVarietal}}: React.ChangeEvent<HTMLSelectElement>) =>
        props.updateCriteriaValue({groupVarietal});

    const onTypeChange = ({target: {value: type}}: React.ChangeEvent<HTMLSelectElement>) =>
        props.updateCriteriaValue({type});

    const onAnsweringDateFromChange = (answeringDateFrom: string) => props.updateCriteriaValue({answeringDateFrom});

    const onAnsweringDateToChange = (answeringDateTo: string) => props.updateCriteriaValue({answeringDateTo});

    const onForeseenDateFromChange = (forseenDateFrom: string) => props.updateCriteriaValue({forseenDateFrom});

    const onForeseenDateToChange = (forseenDateTo: string) => props.updateCriteriaValue({forseenDateTo});

    const onReportTypeChange = ({target: {value: finalInterim}}: React.ChangeEvent<HTMLSelectElement>) =>
        props.updateCriteriaValue({finalInterim});

    const onStatusChange = ({target: {value: status}}: React.ChangeEvent<HTMLSelectElement>) =>
        props.updateCriteriaValue({status});

    const onOnlyActiveFilesCheckboxChange = () => props.updateCriteriaValue({expected: !props.criteria.expected});

    const onShowTechnicalExaminationsCheckboxChange = () =>
        props.updateCriteriaValue({showTechnical: !props.criteria.showTechnical});

    return (
        <>
            <div className={styles.mainFieldContainer}>
                <TextInput
                    placeholder={`Please type order number`}
                    value={props.criteria.orderId}
                    onChange={onOrderIdChange}
                    double={true}
                    onEnter={() => props.search(true)}
                    outsideLabel={'Order number'}
                    outsideLabelWidth={160}
                />
                <div style={{clear: 'both'}} />
                <TextInput
                    placeholder={`Please type file number`}
                    value={props.criteria.registerId}
                    onChange={onRegisterIdChange}
                    double={true}
                    onEnter={() => props.search(true)}
                    outsideLabel={'File number'}
                    outsideLabelWidth={160}
                />
                <div style={{clear: 'both'}} />
                <TextInput
                    placeholder={"Please type Breeder's Reference"}
                    value={props.criteria.reference}
                    onChange={onReferenceChange}
                    double={true}
                    onEnter={() => props.search(true)}
                    outsideLabel={"Breeder's Reference"}
                    outsideLabelWidth={160}
                />
                <div style={{clear: 'both'}} />
                <TextInput
                    placeholder={`Please type denomination`}
                    value={props.criteria.denomination}
                    onChange={onDenominationChange}
                    double={true}
                    onEnter={() => props.search(true)}
                    outsideLabel={'Denomination'}
                    outsideLabelWidth={160}
                />
                <div style={{clear: 'both'}} />
                <TextLabelInput
                    filter={['starts', 'ends', 'contains', 'equals']}
                    placeholder={`please use autocompletion`}
                    currentFilter={props.criteria.speciesNameFilter}
                    onFilterChange={onSpecieNameFilterChange}
                    double={true}
                    onSelectionChange={onSpeciesSelectionChange}
                    onChange={onSpecieNameChange}
                    onEnter={props.search}
                    value={props.criteria.speciesName}
                    selectedElements={props.selectedSpecies}
                    delay={300}
                    outsideLabel={'Species'}
                    outsideLabelWidth={160}
                    listByIdName={props.speciesListByIdName}
                    nameHash={props.speciesNameHash}
                    noInfo={true}
                />
                <SelectInput
                    value={props.criteria.groupVarietal}
                    onChange={onGroupVarietalChange}
                    list={groupVarietalOptions}
                    outsideLabel={'Varietal group'}
                    outsideLabelWidth={160}
                    double={true}
                />
                <SelectInput
                    value={props.criteria.type}
                    onChange={onTypeChange}
                    double={true}
                    list={typeOptions}
                    outsideLabel={'Type'}
                    outsideLabelWidth={160}
                />
                <DateInput
                    label={'Request date'}
                    changeDateFrom={onAnsweringDateFromChange}
                    inputValueFrom={props.criteria.answeringDateFrom}
                    changeDateTo={onAnsweringDateToChange}
                    inputValueTo={props.criteria.answeringDateTo}
                    outsideLabel={' '}
                    outsideLabelWidth={160}
                />
                <DateInput
                    label={'Dead line'}
                    changeDateFrom={onForeseenDateFromChange}
                    inputValueFrom={props.criteria.forseenDateFrom}
                    changeDateTo={onForeseenDateToChange}
                    inputValueTo={props.criteria.forseenDateTo}
                />
                <SelectInput
                    value={props.criteria.finalInterim}
                    onChange={onReportTypeChange}
                    double={true}
                    list={finalInterimOptions}
                    outsideLabel={'Report Type'}
                    outsideLabelWidth={160}
                />
                <SelectInput
                    value={props.criteria.status}
                    onChange={onStatusChange}
                    double={true}
                    list={statusOptions}
                    outsideLabel={'Status'}
                    outsideLabelWidth={160}
                />
                <Checkbox
                    clickAction={onOnlyActiveFilesCheckboxChange}
                    label={`Only active files for which a report is expected`}
                    value={props.criteria.expected}
                    double={true}
                />
                <Checkbox
                    clickAction={onShowTechnicalExaminationsCheckboxChange}
                    label={`Show Technical Verification (TV)`}
                    value={props.criteria.showTechnical}
                    double={true}
                />
                <div style={{clear: 'both'}}>{}</div>
            </div>
            <FormFooter>
                <Button clickAction={() => props.resetCriteria()} variation={'secondary'}>{`Clear fields`}</Button>
                <Button clickAction={() => props.search(false)}>{`Search`}</Button>
            </FormFooter>
            <div style={{clear: 'both'}}>{}</div>
        </>
    );
}
