import React from 'react';
import {COMMUNICATION_TYPES} from '../CommunicationCentreClient/CommunicationCentreClient.utils';
import CryptoJS from 'crypto-js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronRight, faFlag, faPlus} from '@fortawesome/free-solid-svg-icons';
import {saveAs} from 'file-saver';
import jwtDecode from 'jwt-decode';
import BrowserInfo from '~shared/BrowserInfo';
import CustomTable from '~components/CustomTable';
import DragAndDrop from '~components/DragAndDrop';
import Info from '~components/Info';
import InputLink from '~components/InputLink';
import Link from '~components/Link';
import SelectInput from '~components/SelectInput';
import TextAreaInput from '~components/TextAreaInput';
import TextLabelInput from '~components/TextLabelInput';
import {getDecodedJWT, isRegisterIdValid, sanitize} from '~utils';
import {getMandatoryAsterisk} from '~utils/format';
import getIcon from '~utils/icons';
import CCModalClientComposeActions from './CCModalClientComposeActions';
import {
    apiCommunicationCentreModalClientComposeGetFileInfo,
    apiGetCommunicationIdUploadFilesAndSendMessage,
} from './CCModalClientComposeService';
import ModalApplicationList from '../RequestCertifiedCopies/modals/ModalApplicationList';
import {MAX_UPLOAD_FILE_SIZE} from '~utils/constants';
import {ICommunicationMessageClientSubmitJSONRequest, IDecodedJWT, IDocument} from '../../types';
import {ModalCustomVersion2} from '../../commonModals';
import styles from './CCModalClientCompose.module.scss';
import {Button} from '../../componentsFormV2';
import {Error, LoadingBar} from '../../componentsLayout';
import {checkIfUserHasAccessToMyPVR} from '../../utils';

const errorMessages = {
    UNKNOWN: `This is not a valid application number. The value must be in range 19950001 to ${new Date().getFullYear()}9999!`,
    FORBIDDEN: 'This application is not linked to your account',
    DUPLICATE: `Application number already in the list`,
    NOT_RECEIPT: 'The reception of your application has not been finalised yet.',
    ADD: 'Please include the application number using the button + or "Enter" keyboard button.',
    NOT_ALL_ADDED_FROM_LIST: 'Some applications could not be imported because they are not linked to your account',
};

const COMMUNICATION_TYPES_WARNINGS: any = {
    Denominations: (
        <>
            {`If you wish to submit a denomination proposal, please submit it online from your`}{' '}
            <Link label={`Dashboard`} clickAction={() => window.open('/dashboard', '_blank')} isInline={true} />
            {'. '}
            {`For denomination related matters, please use the communication center only in case of comments or questions relating to a denomination proposal you have already submitted or intend to submit.`}
        </>
    ),
    WithdrawalSurrender: (
        <>
            {`If you wish to file an end of file request, please file it online from your`}{' '}
            <Link label={`Dashboard`} clickAction={() => window.open('/dashboard', '_blank')} isInline={true} />
            {`.`}
        </>
    ),
};

const COMMUNICATION_TYPES_WARNINGS_FOR_USER_WITHOUT_ACCESS_TO_MYPVR: any = {
    Denominations: `If you wish to submit a denomination proposal, please request access control of MyPVR Dashboard. For denomination related matters, please use the communication center only in case of comments or questions relating to a denomination proposal you have already submitted or intend to submit.`,
    WithdrawalSurrender: `If you wish to file an end of file request, please request access control of MyPVR Dashboard.`,
};

interface IProps {
    close: (refresh?: boolean) => void;
    intl: any;
    openModalCPVOLogs: (obj: any) => void;
}

type TApplicationNumberError = typeof errorMessages;

type TKeyApplicationNumberError = keyof TApplicationNumberError;

interface IState {
    applicationNumber: string;
    applicationNumberError: TKeyApplicationNumberError | null;
    body: string;
    communicationType: string;
    documentList: IDocument[];
    error: string | null;
    informationOpened: boolean;
    loading: number;
    modalImportApplicationNumberList: boolean;
    selectedApplicationNumbers: {[key: string]: string};
    subject: string;
    timestamp: number;
}

export default class CCModalClientCompose extends React.Component<IProps, IState> {
    decodedJWT: IDecodedJWT = sessionStorage.getItem('token-cc')
        ? jwtDecode(sessionStorage.getItem('token-cc'))
        : getDecodedJWT();
    inputUploadRef: any = null;

    constructor(props: IProps) {
        super(props);
        this.inputUploadRef = React.createRef();
        this.state = {
            applicationNumber: '',
            applicationNumberError: null,
            body: '',
            communicationType: 'none',
            documentList: [],
            error: null,
            informationOpened: true,
            loading: 0,
            modalImportApplicationNumberList: false,
            selectedApplicationNumbers: {},
            subject: '',
            timestamp: Date.now(),
        };
    }

    confirmSending = (messageStatus: 'draft' | 'sent') => {
        this.setState({error: null}, () => {
            if (this.state.communicationType === 'none') {
                return this.setState({error: 'Please select communication type'});
            }
            if (Object.keys(this.state.selectedApplicationNumbers).length === 0) {
                return this.setState({error: 'No application number linked'});
            }
            if (!this.state.subject || this.state.subject === '') {
                return this.setState({error: 'Subject cannot be empty'});
            }
            if (!(this.state.documentList || []).length && (!this.state.body || this.state.body === '')) {
                return this.setState({error: 'Body cannot be empty'});
            }
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    const {selectedApplicationNumbers, subject, body, communicationType, documentList} = this.state;
                    const JSONRequest: ICommunicationMessageClientSubmitJSONRequest = {
                        applicationNumbers: Object.keys(selectedApplicationNumbers),
                        communicationType,
                        draft: messageStatus === 'draft',
                        messageBody: body,
                        messageSubject: subject,
                        numberOfAttachments: (documentList || []).length,
                    };
                    apiGetCommunicationIdUploadFilesAndSendMessage(JSONRequest, documentList)
                        .then(({communicationId}: {communicationId: number}) => {
                            if (communicationId) {
                                this.setState(
                                    prev => ({loading: prev.loading - 1}),
                                    () => {
                                        if (messageStatus === 'draft') {
                                            this.close(true);
                                        } else {
                                            this.props.close();
                                            this.props.openModalCPVOLogs &&
                                                this.props.openModalCPVOLogs({
                                                    comments: this.state.body,
                                                    communicationId,
                                                    subject: this.state.subject,
                                                });
                                        }
                                    }
                                );
                            } else {
                                this.setState(prev => ({loading: prev.loading - 1, error: '404 - Document not found'}));
                            }
                        })
                        .catch(this.close);
                }
            );
        });
    };

    close = (refresh: boolean) => this.props.close && this.props.close(refresh);

    closeError = () => this.setState({error: null});

    handleDrop = (files: FileList, event: any) => this.setState({error: null}, () => this.checkFile(files[0], event));

    checkFile = (file: File, event: React.ChangeEvent<HTMLInputElement>) => {
        LOG([`file.type:${file.type}`]);
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType?.toLowerCase();
        const filename = sanitize(file.name);
        this.setState({error: null}, () => {
            if (
                fileTypeLowercase === 'jpeg' ||
                fileTypeLowercase === 'png' ||
                fileTypeLowercase === 'jpg' ||
                fileTypeLowercase === 'pdf'
            ) {
                if (file.size > MAX_UPLOAD_FILE_SIZE) {
                    this.setState({
                        error: 'The maximum document size for uploads is 10 MB. Please select a proper file.',
                    });
                } else {
                    const a = new FileReader();
                    a.readAsBinaryString(file);
                    a.onloadend = () => {
                        const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(a.result));
                        this.setState(
                            prev => {
                                const documentList = prev.documentList.slice(0);
                                documentList.push({
                                    index,
                                    file,
                                    filename,
                                    size: file.size / 1024,
                                    extension: fileTypeLowercase,
                                    documentType: null,
                                });
                                return {documentList, timestamp: Date.now(), informationOpened: false, error: null};
                            },
                            () => (event.target.value = '')
                        );
                    };
                }
            } else {
                this.setState({error: 'type not valid'}, () => (event.target.value = ''));
            }
        });
    };

    deleteFile = (index: any) =>
        this.setState(prev => {
            return {
                documentList: prev.documentList.filter(i => i.index !== index),
                timestamp: Date.now(),
                informationOpened: prev.documentList.length === 1,
            };
        });

    toggleInformation = () => this.setState(prev => ({informationOpened: !prev.informationOpened}));

    checkApplicationNumber = (applicationNumber: string) => {
        let applicationNumberValue = applicationNumber;
        if (!isNaN(Number(applicationNumberValue))) {
            applicationNumberValue = applicationNumberValue.substring(0, 8);
            this.setState({
                applicationNumber: applicationNumberValue,
                applicationNumberError: isRegisterIdValid(applicationNumberValue)
                    ? applicationNumberValue.length === 8
                        ? 'ADD'
                        : null
                    : applicationNumber !== ''
                    ? 'UNKNOWN'
                    : null,
            });
        }
    };

    closeModalImportApplicationNumbersList = () => this.setState({modalImportApplicationNumberList: false});

    getCommunicationTypeWarningMessage = () =>
        (checkIfUserHasAccessToMyPVR(this.decodedJWT)
            ? COMMUNICATION_TYPES_WARNINGS
            : COMMUNICATION_TYPES_WARNINGS_FOR_USER_WITHOUT_ACCESS_TO_MYPVR)[this.state.communicationType];

    onInputLinkImportApplicationNumberListClick = () => this.setState({modalImportApplicationNumberList: true});

    onImportApplicationNumbersList = (matches: string[]) => {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                const validApplicationNumbers: string[] = [];
                Promise.all(
                    matches.map(applicationNumber =>
                        apiCommunicationCentreModalClientComposeGetFileInfo(applicationNumber)
                    )
                ).then(promisesResults => {
                    promisesResults.forEach(
                        (result, i) => result && result.message === 'OK' && validApplicationNumbers.push(matches[i])
                    );
                    this.setState(prev => {
                        const currentSelectedApplicationNumbers = {...prev.selectedApplicationNumbers};
                        validApplicationNumbers.forEach(el => (currentSelectedApplicationNumbers[el] = el));
                        const conditionalState: Partial<IState> = {};
                        if (validApplicationNumbers.length !== matches.length) {
                            conditionalState[`applicationNumberError`] = 'NOT_ALL_ADDED_FROM_LIST';
                        }
                        return {
                            ...prev,
                            loading: prev.loading - 1,
                            selectedApplicationNumbers: currentSelectedApplicationNumbers,
                            ...conditionalState,
                        };
                    });
                });
            }
        );
    };

    onApplicationNumberChange = (applicationNumber: string): void => this.checkApplicationNumber(applicationNumber);

    onApplicationNumbersSelectionChange = (selectedApplicationNumbers: {[key: string]: string}): void =>
        this.setState(Object.assign({}, {selectedApplicationNumbers}));

    onAddingApplicationNumber = () => {
        const {applicationNumber} = this.state;
        if (applicationNumber.length === 8 && isRegisterIdValid(applicationNumber)) {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiCommunicationCentreModalClientComposeGetFileInfo(applicationNumber)
                        .then((result: any) => {
                            if (result.message !== 'OK') {
                                this.setState({applicationNumberError: 'FORBIDDEN'});
                            } else {
                                this.setState(
                                    prev => {
                                        const selectedApplicationNumbers = prev.selectedApplicationNumbers;
                                        selectedApplicationNumbers[prev.applicationNumber] = prev.applicationNumber;
                                        return {
                                            applicationNumber: '',
                                            applicationNumberError: null,
                                            selectedApplicationNumbers,
                                        };
                                    }
                                    // () => this.updateClientList(this.state.selectedApplicationNumbers)
                                );
                            }
                        })
                        .then(() => this.setState(prev => ({loading: prev.loading - 1})));
                }
            );
        }
    };

    onCommunicationTypeChange = ({target: {value: communicationType}}: React.ChangeEvent<HTMLSelectElement>): void =>
        this.setState({communicationType});

    onSubjectChange = ({target: {value: subject}}: React.ChangeEvent<HTMLTextAreaElement>): void =>
        this.setState({subject: subject.slice(0, 200)});

    onBodyChange = ({target: {value: body}}: React.ChangeEvent<HTMLTextAreaElement>): void => this.setState({body});

    onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];
            this.checkFile(file, event);
        }
    };

    render() {
        const actions = CCModalClientComposeActions(this.deleteFile);

        return (
            <>
                {this.state.modalImportApplicationNumberList ? (
                    <ModalApplicationList
                        importList={this.onImportApplicationNumbersList}
                        close={this.closeModalImportApplicationNumbersList}
                    />
                ) : null}
                <DragAndDrop handleDrop={this.handleDrop}>
                    <div style={{clear: 'both'}} />
                    <ModalCustomVersion2
                        close={this.close}
                        header={`Compose a new message`}
                        body={
                            <>
                                <div style={{clear: 'both'}} />
                                {this.state.documentList.length > 0 && (
                                    <CustomTable
                                        version={2}
                                        {...this.props}
                                        notSortable={['filename', 'size']}
                                        tableName={'documentUpload'}
                                        tableType={'OBJECT'}
                                        tableSource={this.state.documentList || []}
                                        dataFilter={null}
                                        id={'index'}
                                        setLastCursor={null}
                                        resultFieldsDefault={['filename', 'type', 'size']}
                                        intl={this.props.intl}
                                        formatFunctions={{
                                            size: (size: number) => `${Math.floor(size * 100) / 100} KB`,
                                            extension: (extension: string) => (
                                                <img src={getIcon(extension || 'pdf')} alt={extension} />
                                            ),
                                            type: () =>
                                                this.state.communicationType && this.state.communicationType !== 'none'
                                                    ? (
                                                          COMMUNICATION_TYPES.find(
                                                              (el: any) => el.id === this.state.communicationType
                                                          ) || {}
                                                      ).value
                                                    : '',
                                        }}
                                        count={(this.state.documentList || []).length}
                                        hideExcelButton={true}
                                        noChangePageSize={true}
                                        forehandColumn={(row: IDocument) => (
                                            <img
                                                style={{width: 20, height: 20, cursor: 'pointer'}}
                                                alt={'Download'}
                                                src={getIcon(row.extension || 'pdf')}
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    saveAs(row.file, row.filename);
                                                }}
                                            />
                                        )}
                                        timestamp={this.state.timestamp}
                                        actions={actions}
                                    />
                                )}
                                <SelectInput
                                    label={getMandatoryAsterisk(`Type of communication`)}
                                    value={this.state.communicationType}
                                    onChange={this.onCommunicationTypeChange}
                                    list={COMMUNICATION_TYPES}
                                    notAll={true}
                                    triple={true}
                                    noIcon={true}
                                    hideOptionNone={true}
                                />
                                {['Denominations', 'WithdrawalSurrender'].includes(this.state.communicationType) ? (
                                    <div style={{margin: '0 50px 7px 7px'}}>
                                        <div style={{clear: 'both'}} />
                                        <Info>
                                            <div style={{display: 'inline', marginRight: 5}}>
                                                <FontAwesomeIcon icon={faFlag as any} color={'#8a6d3b'} />
                                            </div>
                                            <div style={{display: 'inline'}}>
                                                {this.getCommunicationTypeWarningMessage()}
                                            </div>
                                        </Info>
                                    </div>
                                ) : null}
                                <TextLabelInput
                                    onSelectionChange={this.onApplicationNumbersSelectionChange}
                                    onChange={this.onApplicationNumberChange}
                                    onEnter={this.onAddingApplicationNumber}
                                    value={this.state.applicationNumber}
                                    selectedElements={this.state.selectedApplicationNumbers}
                                    delay={300}
                                    multiple={true}
                                    label={getMandatoryAsterisk(
                                        'To which application number(s) the communication is linked'
                                    )}
                                    buttonAction={this.onAddingApplicationNumber}
                                    buttonIcon={faPlus}
                                    buttonDisabled={
                                        (this.state.applicationNumberError &&
                                            this.state.applicationNumberError !== 'ADD') ||
                                        this.state.applicationNumber?.length !== 8
                                    }
                                    triple={true}
                                    // disabled={this.state.communicationType === 'none'}
                                />
                                {this.state.applicationNumberError && (
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            width: 700,
                                            marginLeft: 40,
                                            marginBottom: 20,
                                        }}
                                    >
                                        <Info>
                                            <div style={{display: 'inline-block', marginRight: 5}}>
                                                <FontAwesomeIcon icon={faFlag as any} color={'#8a6d3b'} />
                                            </div>
                                            <div style={{display: 'inline-block'}}>
                                                {errorMessages[this.state.applicationNumberError]}
                                            </div>
                                        </Info>
                                    </div>
                                )}
                                <div style={{float: 'left', marginTop: -11, paddingBottom: 7}}>
                                    <InputLink
                                        label={`Import application number list`}
                                        clickAction={this.onInputLinkImportApplicationNumberListClick}
                                        width={280}
                                    />
                                </div>
                                <div style={{clear: 'both'}} />
                                <TextAreaInput
                                    label={getMandatoryAsterisk('Subject')}
                                    value={this.state.subject}
                                    onChange={this.onSubjectChange}
                                    rows={3}
                                    triple={true}
                                    noIcon={true}
                                />
                                <TextAreaInput
                                    label={
                                        (this.state.documentList || []).length > 0
                                            ? 'Body'
                                            : getMandatoryAsterisk('Body')
                                    }
                                    value={this.state.body}
                                    onChange={this.onBodyChange}
                                    rows={5}
                                    triple={true}
                                    noIcon={true}
                                />
                                <div style={{clear: 'both'}} />
                                <div>
                                    <InputLink
                                        label={`Notes`}
                                        icon={this.state.informationOpened ? faChevronDown : faChevronRight}
                                        clickAction={this.toggleInformation}
                                    />
                                    <div style={{clear: 'both'}}>{}</div>
                                    {this.state.informationOpened && <BrowserInfo intl={this.props.intl} />}
                                </div>
                                <div style={{clear: 'both'}}>{}</div>
                            </>
                        }
                        footer={
                            this.state.loading !== 0 ? (
                                <LoadingBar />
                            ) : (
                                <>
                                    {this.state.error ? <Error>{this.state.error}</Error> : null}
                                    <div className={styles.modalFooterActions}>
                                        <div className={styles.buttonAddDocument}>
                                            <label htmlFor={'upload'}>
                                                <input
                                                    type="file"
                                                    name="files[]"
                                                    data-ng-class="isAddDocumentDisabled() ? 'disabled': ''"
                                                    data-ng-disabled="isAddDocumentDisabled()"
                                                    id="upload"
                                                    style={{opacity: 0, width: 0}}
                                                    onChange={this.onFileUpload}
                                                    accept="image/jpg, image/jpeg, image/png, application/pdf"
                                                    ref={this.inputUploadRef}
                                                />
                                                <Button
                                                    variation={'secondary'}
                                                    clickAction={() => this.inputUploadRef.current.click()}
                                                >{`Add document`}</Button>
                                            </label>
                                        </div>
                                        <div className={styles.buttonsRightWrap}>
                                            <Button
                                                variation={'danger'}
                                                clickAction={this.close}
                                                icon={'close'}
                                                className={styles.buttonClose}
                                            >{`Close`}</Button>
                                            <Button
                                                variation={'secondary'}
                                                clickAction={() => this.confirmSending('draft')}
                                                className={styles.buttonDraft}
                                            >{`Draft`}</Button>
                                            <Button
                                                clickAction={() => this.confirmSending('sent')}
                                                icon={'arrowRight'}
                                            >{`Send`}</Button>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    />
                </DragAndDrop>
            </>
        );
    }
}
