import React, {useState} from 'react';
import {Popover} from 'react-tiny-popover';
import styles from './WizardQuestionLogicsPopover.module.scss';
import {htmlToText} from '~utils/index';
import cx from 'classnames';
import {IGroup, IGroupQuestion, IGroupQuestionLogic} from '../../../types';

const QUESTION_LOGICS_SHORT_DESCRIPTIONS: any = {
    confidentialvalue: 'Pre-defines the answer of a confidential question to Yes / No depending on the logic parameter',
    applysameconfidentialvalue:
        'Applies same confidential value to a target question as the confidential value of this source question',
    mandatoryfirstoccurrence:
        'In case of a group with multi option enabled in which multiple instances of the group can exist, if the question is mandatory and has this logic type then it is only mandatory in the first instance of the groups',
    ShowMeLinkToAFResponse: 'Deprecated',
    forbiddenresponse: 'Deprecated',
    forceresponse: 'Deprecated',
    showonfirstsXoccurrence:
        "In case of multi group, it shows the question and it's responses only on the first iteration (or more based on logic param) of the group",
};

const renderQuestionLogicShortDescription = (logicType: any) => {
    if (!logicType || !QUESTION_LOGICS_SHORT_DESCRIPTIONS[logicType]) {
        return null;
    }
    return (
        <div className={styles.questionPopoverShortDescription}>{QUESTION_LOGICS_SHORT_DESCRIPTIONS[logicType]}</div>
    );
};

const findNameOfDestinationQuestion = ({logic, steps}: {logic: IGroupQuestionLogic; steps: IGroup[]}) => {
    let destinationQuestion =
        (logic &&
            logic.questionIdDest &&
            (steps || [])
                .map(el => el.questions)
                .flat(1)
                .find(question => question && question.id === logic.questionIdDest)) ||
        null;
    return (
        <>
            {destinationQuestion ? (
                <>
                    <div>
                        {logic.name}:&nbsp;<strong>{destinationQuestion.order}</strong>&nbsp;
                        {/*{htmlToText(destinationQuestion.message)}*/}
                    </div>
                    {renderQuestionLogicShortDescription(logic.type)}
                </>
            ) : (
                ''
            )}
        </>
    );
};

const renderLogicTitle = ({logic, steps}: {logic: IGroupQuestionLogic; steps: IGroup[]}) => {
    if (logic.questionIdDest) {
        return findNameOfDestinationQuestion({logic, steps});
    }
    return (
        <>
            <div>{logic && `${logic.name}`}</div>
            {renderQuestionLogicShortDescription(logic.type)}
        </>
    );
};

export default function WizardQuestionLogicsPopover({
    logics,
    question,
    steps,
}: {
    logics: IGroupQuestionLogic[];
    question: IGroupQuestion;
    steps: IGroup[];
}) {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    return (
        <Popover
            isOpen={isPopoverOpen}
            positions={'right'}
            onClickOutside={() => null}
            content={() => (
                <div
                    className={styles.customPopover}
                    style={{
                        background: '#e8e8e8',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '4px',
                        maxWidth: '100%',
                        opacity: 0.9,
                        borderColor: 'black',
                        borderWidth: 1,
                        borderStyle: 'solid',
                    }}
                >
                    <div className={styles.popoverTitle}>Question Logics: {question && htmlToText(question.order)}</div>
                    <div className={styles.popoverLogicsWrap}>
                        <ul>
                            {(logics || []).map(logic => (
                                <li key={`popover-question-logic-${question.id}`} className={styles.popoverLogic}>
                                    {renderLogicTitle({logic, steps})}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        >
            {logics && logics.length > 0 ? (
                <div
                    onMouseEnter={() => setIsPopoverOpen(true)}
                    onMouseLeave={() => setIsPopoverOpen(false)}
                    style={{display: 'inline-block'}}
                    className={cx(styles.questionLogicsLabel)}
                    title={``}
                >
                    {logics.length}
                </div>
            ) : (
                <div />
            )}
        </Popover>
    );
}
