import {getFetch, postFetch, API_SERVERLESS} from '~commonApi';

export function apiGetBackOfficeContacts() {
    return getFetch(`${API_SERVERLESS}/online/v3/contacts`, true);
}

export function apiSearchApplications(criteria, DEFAULT_CRITERIA = {}, excelTranslations) {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/backOffice/v3/applicationRequests?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}

export function apiOnlineApplicationRequestRepairDocuments(applicationRequestId) {
    return getFetch(`${API_SERVERLESS}/online/v3/applicationRequestRepairDocuments/${applicationRequestId}`, true);
}
