import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export function apiLegalProceedings(criteria, DEFAULT_CRITERIA = {}, excelTranslations) {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/backOffice/v3/adminProceedings?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}

export function apiUpdateRequest(jsonRequest) {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/adminProceedingsEditRequest`, true, jsonRequest);
}
