import React from 'react';
import {ArrowContainer, Popover} from 'react-tiny-popover';

export default class CustomPopoverIcon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isPopoverOpen: false};
    }

    render() {
        const {popupContent, icon, handler} = this.props;
        const {isPopoverOpen} = this.state;
        return (
            <Popover
                isOpen={this.state.isPopoverOpen}
                positions={'top'}
                onClickOutside={() => this.setState({isPopoverOpen: !isPopoverOpen})}
                content={({position, childRect, popoverRect}) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={'#3a924d'}
                        arrowSize={5}
                        arrowStyle={{opacity: 0.7}}
                    >
                        <div onClick={() => this.setState({isPopoverOpen: !isPopoverOpen})}>
                            <div
                                className="custom-popover"
                                style={{
                                    background: 'white',
                                    border: '1px solid #3a924d',
                                    color: '#3a924d',
                                    padding: '5px',
                                    borderRadius: '4px',
                                    opacity: 0.9,
                                }}
                            >
                                {popupContent}
                            </div>
                        </div>
                    </ArrowContainer>
                )}
            >
                <a
                    onMouseEnter={() => this.setState({isPopoverOpen: true})}
                    onMouseLeave={() => this.setState({isPopoverOpen: false})}
                    onClick={handler}
                >
                    {icon}
                </a>
            </Popover>
        );
    }
}
