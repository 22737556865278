import {TPVRTemplate} from './CommunicationCentreInternalCPVOTemplates';

export function apiBackOfficePVRTemplatesSearch(
    criteria: any,
    DEFAULT_CRITERIA: any = {},
    excelTranslations?: boolean
): Promise<{
    count: number;
    data: TPVRTemplate[];
}> {
    // const params = {...criteria};
    // const requestParams = Object.keys(params)
    //     .filter(i => params[i] !== DEFAULT_CRITERIA[i])
    //     .map(k => `${k}=${params[k]}`);
    // return (criteria.excel ? postFetch : getFetch)(
    //     `${API_SERVERLESS}/backOffice/v3/searchPVRTemplates?${requestParams.join('&')}`,
    //     true,
    //     excelTranslations
    // );
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve({
                count: 4,
                data: [
                    {name: 'CPVO template 1', language: ['en', 'fr']},
                    {name: 'CPVO template 2', language: ['en', 'fr']},
                    {name: 'CPVO template 3', language: ['en', 'fr']},
                    {name: 'CPVO template 4', language: ['en', 'fr']},
                ],
            });
        }, 1000);
    });
}
