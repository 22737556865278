import React from 'react';
import styles from './MyRegistersLabelsBottomCaption.module.scss';

const MyRegistersLabelsBottomCaption = () => {
    return (
        <div>
            <fieldset className={styles.schedulerBorder}>
                <legend className={styles.legendSchedulerBorder}>
                    <i className="small" data-translate="">
                        <span className="ng-scope">{`Legend`}</span>
                    </i>
                </legend>
                <div className={styles.container}>
                    <div className={styles.warning}>{`Report not arrived and deadline in the next 28 days`}</div>
                    <div className={styles.danger}>{`Report not arrived and deadline passed`}</div>
                </div>
            </fieldset>
        </div>
    );
};

export default MyRegistersLabelsBottomCaption;
