import {computeGetUploadPreSignedURLPromises, postFileToPreSignedURLRequest} from '../../../utils/requests';
import {API_SERVERLESS, postFetch} from '~commonApi';
import {Tree} from '../../TLO/CommonComponents/TreeView/Interfaces/TreeViewInterfaces';

export function apiCreateTree(jsonData: {
    tree: Tree;
    categoryId: string;
    year: string;
    filename?: string;
    file?: Blob;
}): Promise<{fileUUID: string}> {
    if (jsonData.filename && jsonData.file) {
        return computeGetUploadPreSignedURLPromises({}).then(({presignedUrl, identifier}: any = {}) => {
            return postFileToPreSignedURLRequest({preSignedURL: presignedUrl, file: jsonData.file}).then(() => {
                return postFetch(`${API_SERVERLESS}/acarea/createdocument`, true, {
                    year: jsonData.year,
                    categoryId: jsonData.categoryId,
                    tree: jsonData.tree,
                    filename: jsonData.filename,
                    fileIdentifier: identifier,
                });
            });
        });
    } else {
        return postFetch(`${API_SERVERLESS}/acarea/createdocument`, true, {
            year: jsonData.year,
            categoryId: jsonData.categoryId,
            tree: jsonData.tree,
        });
    }
}

export function apiUpdatefile(jsonData: {uuid: string; file: Blob; categoryId: string}) {
    return computeGetUploadPreSignedURLPromises({}).then(({presignedUrl, identifier}: any = {}) => {
        return postFileToPreSignedURLRequest({preSignedURL: presignedUrl, file: jsonData.file}).then(() => {
            return postFetch(`${API_SERVERLESS}/acarea/updatefile`, true, {
                uuid: jsonData.uuid,
                fileIdentifier: identifier,
                categoryId: jsonData.categoryId,
            });
        });
    });
}
