// import {API_SERVERLESS, postFetch} from './index';

export function apiLogout() {
    // return postFetch(`${API_SERVERLESS}/common/v3/authentication/logout`, true, jsonData).then(jsonResponse => {
    //     if (jsonResponse) {
    //         LOG`Logout JSON response: ${JSON.stringify(jsonResponse, null, 4)}`;
    //         if (jsonResponse.result === 1) {
    const ie = !!navigator.userAgent.match(/Trident\/7\./);
    if (ie) {
        document.cookie = `ie-token=deleted`;
    } else {
        const remember = localStorage.getItem('remember') === '1';
        remember && localStorage.removeItem('remember');
        (remember ? localStorage : sessionStorage).removeItem('token');
        (remember ? localStorage : sessionStorage).removeItem('MYPVR');
        (remember ? localStorage : sessionStorage).removeItem('refresh-token');
    }
    //     }
    // }
    return Promise.resolve({result: 1});
    // });
}
