import React from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';
import {
    apiChangeUser,
    apiCommunicationCentreClientSearchInbox,
    apiCommunicationCentreInternalSearchInbox,
    apiMyPVRDashboardNextFeeDueDate,
    apiVarietyFinderCheckIfTermsAccepted,
} from './WelcomeService';
import {apiHome} from '~commonApi/welcome';
import {getEnvironment} from '~utils/index';
import {getFetch} from '~commonApi';
import {Button, SelectInput, TextInput} from '../../componentsFormV2';
import jwtDecode from 'jwt-decode';
import ModalContactUsVersion2 from '../../commonModals/ModalContactUsVersion2';
import {getLocalStorageImpersonate, updateLocalStorageImpersonate} from './Welcome.utils';
import ModalWelcomeStoredImpersonatedUsers from './ModalWelcomeStoredImpersonatedUsers';
import {ICompany, IDecodedJWT, ISite} from '../../types';
import styles from './Welcome.module.scss';
import ModalErrorVersion2 from '../../commonModals/ModalErrorVersion2';
import {injectIntl} from 'react-intl';
import {
    checkIfUserHasAccessToCommunicationCentre,
    checkIfUserHasAccessToMyPVR,
    loadUrlParams,
    trackPageView,
} from '../../utils';
import imageLegalProceedings from '../../img/icons/legalProceedings.svg';
import imageCertifiedCopies from '../../img/icons/certifiedCopiesRequest.svg';
import imageDevelopersProgram from '../../img/icons/devprogram.svg';
import imageVarietyFinder from '../../img/icons/varietyFinder.svg';
import imageCommunicationCentre from '../../img/icons/communicationCentre.svg';
import imageBackOffice from '../../img/icons/onlineApplicationsBackOffice.svg';
import imageOnlineApplications from '../../img/icons/onlineApplications.svg';
import imageTLO from '../../img/icons/tlo.svg';
import imageAdministrativeCouncil from '../../img/icons/administrativeCouncil.svg';
import imageCaseLaw from '../../img/icons/caseLaw.svg';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderBreadcrumb,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitle,
} from '../../componentsLayout';
import cx from 'classnames';
import dateFormat from 'date-format';
import {ModalAlertVersion2} from '../../commonModals';
import {
    findDefaultValueForCaseHolder,
    findDefaultValueForDirectory,
    OPTION_ALL,
} from '../CommunicationCentreInternal/CommunicationCentreInternal.utils';

const UNIQUE_ONLINE_SITE_ARRAY = ['OATEST', 'MYCPVO', 'WEBOA'];
const ONLINE_SITE_ARRAY = ['OATEST', 'WEBOA'];

const modalScreen = {
    CONTACT_US: 'CONTACT_US',
};

function getDecodedJWT() {
    const remember = localStorage.getItem('remember') === '1';
    const authToken = (remember ? localStorage : sessionStorage).getItem('token');
    let decoded = null;
    if (authToken) {
        decoded = jwtDecode(authToken);
    }
    return decoded;
}

interface IProps extends RouteComponentProps {
    intl: any;
}

interface IState {
    loading: number;
    sites: any[];
    companies: any[];
    administrator: boolean;
    contactId: number | null;
    isModalDebitNoteHasExpiredOpen: boolean;
    isModalAskPermissionFromYourAdministratorOpen: boolean;
    isModalAlertVarietyFinderTermsAlreadyAcceptedOpen: boolean;
    jwtToken: IDecodedJWT;
    modalScreen: any;
    modalImpersonatedUsers: any[];
    numberOfUnreadMessages: number | undefined;
    feeDueDateInDays: number | null;
    impersonated: boolean;
    timestamp: number;
}

class Welcome extends React.Component<IProps, IState> {
    selectedCompany = null;

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: 0,
            sites: [],
            companies: [],
            administrator: false,
            contactId: null,
            isModalDebitNoteHasExpiredOpen: false,
            isModalAskPermissionFromYourAdministratorOpen: false,
            isModalAlertVarietyFinderTermsAlreadyAcceptedOpen: false,
            jwtToken: getDecodedJWT(),
            modalScreen: null,
            modalImpersonatedUsers: [],
            numberOfUnreadMessages: undefined,
            feeDueDateInDays: null,
            impersonated: false,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'welcome'});
        this.loadJSONs().then(this.checkTermsAndConditions);
    }

    loadJSONs = () => {
        return new Promise((resolve, reject) => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () =>
                    Promise.all([
                        apiHome(false),
                        apiMyPVRDashboardNextFeeDueDate(),
                        this.loadCommunicationCenterNumberOfUnreadMessages(),
                    ])
                        .then(JSONResponse => {
                            const [
                                response,
                                responseNextFeeDueDate,
                                responseCommunicationCenterNumberOfUnreadMessages,
                            ]: [
                                response: {administrator: boolean; companies: ICompany[]; sites: ISite[]},
                                responseNextFeeDueDate: {feeDueDateInDays: number | null},
                                responseCommunicationCenterNumberOfUnreadMessages: any
                            ] = JSONResponse || {};
                            const stateObject: any = {};
                            if (
                                responseCommunicationCenterNumberOfUnreadMessages &&
                                !isNaN(responseCommunicationCenterNumberOfUnreadMessages.numberOfUnreadMessages)
                            ) {
                                stateObject.numberOfUnreadMessages =
                                    responseCommunicationCenterNumberOfUnreadMessages.numberOfUnreadMessages;
                            }
                            if (responseNextFeeDueDate) {
                                const {feeDueDateInDays} = responseNextFeeDueDate;
                                if (feeDueDateInDays !== null) {
                                    if (feeDueDateInDays < 0) {
                                        stateObject.isModalDebitNoteHasExpiredOpen = true;
                                    }
                                    stateObject.feeDueDateInDays = feeDueDateInDays;
                                }
                            }
                            if (response && response.sites) {
                                stateObject.sites = response.sites;
                            }
                            if (response && response.companies) {
                                stateObject.companies = response.companies;
                                stateObject.contactId =
                                    (response.companies &&
                                        response.companies.length > 0 &&
                                        response.companies[0].thirdPartyId) ||
                                    null;
                            }
                            if (response && response.administrator) {
                                stateObject.administrator = response.administrator;
                            }
                            this.setState(
                                prev => ({
                                    loading: prev.loading - 1,
                                    ...stateObject,
                                }),
                                () => resolve([])
                            );
                        })
                        .catch(reject)
            );
        });
    };

    loadCommunicationCenterNumberOfUnreadMessages = () =>
        new Promise(resolve => {
            const decoded = getDecodedJWT();
            const hasUserAccessToCommunicationCentre = checkIfUserHasAccessToCommunicationCentre(decoded);
            if (!decoded || (!hasUserAccessToCommunicationCentre && decoded.thirdPartyId !== '1')) {
                return resolve([]);
            } else {
                if (decoded.thirdPartyId === '1') {
                    const caseHolder = findDefaultValueForCaseHolder(decoded);
                    const directory = findDefaultValueForDirectory(decoded);
                    let additionSearchParams: any = {};
                    directory !== OPTION_ALL && (additionSearchParams.directory = directory);
                    caseHolder !== OPTION_ALL && (additionSearchParams.caseHolder = caseHolder);
                    let query = Object.entries(additionSearchParams)
                        .map(([key, value]) => `${key}=${value}`)
                        .join('&');
                    apiCommunicationCentreInternalSearchInbox(query).then((result: any) => {
                        if (result && result.data && !isNaN(result.data.count)) {
                            resolve({numberOfUnreadMessages: result.data.count});
                        }
                    });
                } else {
                    apiCommunicationCentreClientSearchInbox().then((result: any) => {
                        if (result && result.data && !isNaN(result.data.count)) {
                            resolve({numberOfUnreadMessages: result.data.count});
                        }
                    });
                }
            }
        });

    closeModalStoredImpersonatedUsers = () => this.setState({modalImpersonatedUsers: []});

    checkTermsAndConditions = () => {
        const remember = localStorage.getItem('remember') === '1';
        const impersonatedAccepted = (remember ? localStorage : sessionStorage).getItem('IMPERSONATED');
        if (impersonatedAccepted !== 'true') {
            const nonAcceptedTermsAndConditionsSiteArray = (this.state.sites || []).filter(
                siteItem => ONLINE_SITE_ARRAY.indexOf(siteItem.code) !== -1 && !siteItem.TC
            );
            if (nonAcceptedTermsAndConditionsSiteArray.length > 0) {
                this.props.history.push('/termsAndConditions');
            }
        }
    };

    setLoading = () =>
        new Promise(resolve =>
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => resolve([])
            )
        );

    goToApp = ({code: appCode, URL: url}: {code: string; URL: string}) => {
        const userRights = (this.state.sites || []).map(site => site.code);
        if (appCode === 'OABO3') {
            this.props.history.push('/forms');
        }
        if (appCode === 'WEBEO2') {
            this.goToTLO();
        } else if (appCode === 'ACAREA2') {
            this.goToAC();
        } else if (appCode === 'LAW2') {
            this.goToPVRCASELAW();
        } else if (appCode === 'DENO2') {
            this.goToVarietyFinder(url);
        } else if (getEnvironment() !== 'ONLINE' && ONLINE_SITE_ARRAY.indexOf(appCode) !== -1) {
            if (userRights.indexOf('MYCPVO') === -1 && userRights.indexOf('OATEST') === -1) {
                this.goToOnlineApplications();
            } else {
                const remember = localStorage.getItem('remember') === '1';
                (remember ? localStorage : sessionStorage).setItem('MYPVR', 'true');
                this.props.history.push('/dashboard');
            }
        } else if (ONLINE_SITE_ARRAY.indexOf(appCode) !== -1) {
            this.goToDashboard();
        } else {
            if (appCode === 'BACKOFFICE') {
                window.location.href = url;
            } else if (this.state.jwtToken && this.state.jwtToken.csCookie) {
                LOG([
                    `${JSON.stringify(
                        {
                            csCookie: this.state.jwtToken.csCookie,
                            csCookie2: this.state.jwtToken.csCookie2,
                            url: `https://cpvoextranet.cpvo.europa.eu/api/setcookie.php?cookie=${this.state.jwtToken.csCookie}&target=${url}`,
                        },
                        null,
                        4
                    )}`,
                ]);
                window.location.href = `https://cpvoextranet.cpvo.europa.eu/api/setcookie.php?cookie=${this.state.jwtToken.csCookie}&target=${url}`;
            } else {
                window.location.href = url;
            }
        }
    };

    goToMyPVR = () => this.props.history.push('/dashboard');

    goToTLO = () => this.props.history.push('/tlo');

    goToAC = () => this.props.history.push('/acarea');

    goToPVRCASELAW = () => this.props.history.push('/pvrcaselaw');

    goToVarietyFinder = (url: string) => {
        const tokenURL =
            getEnvironment().toLowerCase() === 'localhost'
                ? 'http://localhost:4000/local/api/common/v3/varietyFinder/token5'
                : `https://${getEnvironment().toLowerCase()}.plantvarieties.eu/api/common/v3/varietyFinder/token5`;
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                getFetch(tokenURL, true)
                    .then((json: any) => {
                        if (json.token) {
                            const remember = localStorage.getItem('remember') === '1';
                            (remember ? localStorage : sessionStorage).setItem('tokenVarietyFinder', json.token);
                            document.cookie = `token=${json.token};domain=.plantvarieties.eu;path=/;Secure;SameSite=Strict`;
                            this.props.history.push('/varieties');
                            // window.location.href = url;
                        }
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    goToDashboard = () => {
        const userRights = (this.state.sites || []).map(site => site.code);
        if (userRights.indexOf('MYCPVO') === -1) {
            this.goToOnlineApplications();
        } else {
            const remember = localStorage.getItem('remember') === '1';
            (remember ? localStorage : sessionStorage).setItem('MYPVR', 'true');
            this.props.history.push('/dashboard');
        }
    };

    changeCompany = () => {
        if (!this.state.contactId) {
            const impersonatedUsers = getLocalStorageImpersonate();
            if (impersonatedUsers.length) {
                return this.setState({modalImpersonatedUsers: impersonatedUsers});
            }
        }
        const remember = localStorage.getItem('remember') === '1';
        //TODO add an global error message window to reflect error, for example this.selectedCompany === null
        const contactId = this.state.contactId;
        this.setState(
            prev => ({loading: prev.loading + 1, sites: [], companies: [], administrator: false, contactId: null}),
            () => {
                apiChangeUser({contactId, remember})
                    .then((JSONResponse: any) => {
                        const decoded = jwtDecode(JSONResponse.token);
                        const {forename, name, id} = decoded || {};
                        let impersonatedName = `${forename ? forename + ' ' : ''}${name}`;
                        updateLocalStorageImpersonate(impersonatedName, id);
                        return [];
                    })
                    .then(this.loadJSONs)
                    .then(this.checkTermsAndConditions)
                    .then(() =>
                        this.setState(prev => {
                            return {
                                ...prev,
                                loading: prev.loading - 1,
                                timestamp: Date.now(),
                                jwtToken: getDecodedJWT(),
                            };
                        })
                    );
            }
        );
    };

    goToOnlineApplications = () => this.props.history.push('/applications');

    goToTheCommunicationCentre = () => {
        const remember = localStorage.getItem('remember') === '1';
        sessionStorage.setItem('token-cc', (remember ? localStorage : sessionStorage).getItem('token') as string);
        if (this.state.jwtToken && this.state.jwtToken.thirdPartyId === '1') {
            window.open('/communicationCentreInternalInbox', '_blank');
        } else {
            window.open('/communicationCentreClientInbox', '_blank');
        }
    };

    openModalContactUs = () => this.setState({modalScreen: modalScreen.CONTACT_US});

    closeModal = () => this.setState({modalScreen: null});

    closeModalDebitNoteHasExpired = () => this.setState({isModalDebitNoteHasExpiredOpen: false});

    closeModalAskPermissionFromYourAdministrator = () =>
        this.setState({isModalAskPermissionFromYourAdministratorOpen: false});

    closeModalAlertVarietyFinderTermsAlreadyAccepted = () =>
        this.setState({isModalAlertVarietyFinderTermsAlreadyAcceptedOpen: false});

    goToLegalProceedings = () => this.props.history.push('/legalProceedings');

    showDevelopersProgramLink = () => this.state.jwtToken && this.state.jwtToken.roles.indexOf('DPPRA') !== -1;

    showWebBackOfficeLink = () => {
        return (
            this.state.sites.map(i => i.code).indexOf('DENO2') === -1 &&
            ((this.state.jwtToken && this.state.jwtToken.thirdPartyId === '1') ||
                (this.state.jwtToken &&
                    (this.state.jwtToken.roles.indexOf('OABAC') !== -1 ||
                        this.state.jwtToken.roles.indexOf('OABAD') !== -1)))
        );
    };

    onContactIdChange = ({target: {value: contactId}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.setState({contactId: contactId ? parseInt(contactId) : null});

    onModalStoredImpersonatedUsersRowClick = (contactId: string) =>
        this.setState({contactId: parseInt(contactId), modalImpersonatedUsers: []}, this.changeCompany);

    onLinkOverdueFeesClick = () => {
        const userHasAccessToMyPVR = checkIfUserHasAccessToMyPVR(this.state.jwtToken);
        if (userHasAccessToMyPVR) {
            const dateObj = new Date();
            dateObj.setDate(dateObj.getDate() - 1);
            this.props.history.push(`/myFees?feeType=5&noteStatus=20&dueDateTo=${dateFormat('yyyy-MM-dd', dateObj)}`);
        } else {
            this.setState({isModalAskPermissionFromYourAdministratorOpen: true, isModalDebitNoteHasExpiredOpen: false});
        }
    };

    onLinkPendingFeesClick = () => {
        const userHasAccessToMyPVR = checkIfUserHasAccessToMyPVR(this.state.jwtToken);
        if (userHasAccessToMyPVR) {
            this.props.history.push(`/myFees?noteStatus=20&dueDateFrom=${dateFormat('yyyy-MM-dd', new Date())}`);
        } else {
            this.setState({isModalAskPermissionFromYourAdministratorOpen: true});
        }
    };

    onLinkDevelopersProgramClick = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiVarietyFinderCheckIfTermsAccepted().then(JSONResponse => {
                    if (JSONResponse) {
                        if (JSONResponse.accepted) {
                            this.setState(prev => ({
                                loading: prev.loading - 1,
                                isModalAlertVarietyFinderTermsAlreadyAcceptedOpen: true,
                            }));
                        } else {
                            this.setState(
                                prev => ({loading: prev.loading - 1}),
                                () => this.props.history.push('/varietyFinderTermsOfAPIUse')
                            );
                        }
                    }
                });
            }
        );

    renderSiteLinkHTML = (siteName: any, srcImage: any, counter?: number) => (
        <>
            <div className={styles.imgWrap}>
                <img src={srcImage} alt={siteName} />
            </div>
            <div className={styles.appLinkTitleWrap}>
                {counter && counter > 0 ? <span className={styles.appLinkTitleCounter}>{counter}</span> : null}
                <h2 className={styles.appLinkTitle}>
                    {siteName.replace('- version 3', '').replace('(New version)', '')}
                </h2>
            </div>
        </>
    );

    renderSiteLink = (site: ISite, siteName: any) => {
        const siteCodeIconImage: any = {
            WEBOA: imageOnlineApplications,
            WEBEO2: imageTLO,
            OABO3: imageBackOffice,
            ACAREA2: imageAdministrativeCouncil,
            LAW2: imageCaseLaw,
        };
        return (
            <a
                key={site.code}
                onClick={() => this.goToApp(site)}
                className={cx(styles.appLink, styles[`link${site.code}`])}
            >
                {this.renderSiteLinkHTML(siteName, siteCodeIconImage[site.code])}
            </a>
        );
    };

    render() {
        let uniqueOnlineSiteRendered = false;
        let companyList = (this.state.companies || []).map(company => ({
            id: company.thirdPartyId,
            value: `${company.ID} (${company.clientId})`,
        }));
        const hasUserAccessToCommunicationCentre = checkIfUserHasAccessToCommunicationCentre(this.state.jwtToken);
        const isCPVOUser =
            this.state.jwtToken && this.state.jwtToken.thirdPartyId && parseInt(this.state.jwtToken.thirdPartyId) === 1;
        const {mockWarning} = loadUrlParams();

        return (
            <>
                {this.state.modalScreen === modalScreen.CONTACT_US && (
                    <ModalContactUsVersion2 sendTo={7} close={this.closeModal} />
                )}
                {this.state.modalImpersonatedUsers && this.state.modalImpersonatedUsers.length ? (
                    <ModalWelcomeStoredImpersonatedUsers
                        close={this.closeModalStoredImpersonatedUsers}
                        impersonatedUsers={this.state.modalImpersonatedUsers}
                        onRowClick={this.onModalStoredImpersonatedUsersRowClick}
                    />
                ) : null}
                {this.state.isModalDebitNoteHasExpiredOpen ? (
                    <ModalErrorVersion2
                        title={`Alert`}
                        message={
                            <div>
                                {`Dear user, your debit note has expired.`}
                                <br />
                                <br />
                                {`We remind that the lack of payment will lead to the cancellation of the CPVR and the cancellation is irreversible.`}
                                <br />
                                <br />
                                {`Click `}
                                <a onClick={this.onLinkOverdueFeesClick}>{`here`}</a>
                                {` to access it.`}
                            </div>
                        }
                        close={this.closeModalDebitNoteHasExpired}
                    />
                ) : null}
                {this.state.isModalAskPermissionFromYourAdministratorOpen ? (
                    <ModalErrorVersion2
                        title={`Alert`}
                        message={`Please ask the administrator of your company to give you access rights to MyPVR application.`}
                        close={this.closeModalAskPermissionFromYourAdministrator}
                    />
                ) : null}
                {this.state.isModalAlertVarietyFinderTermsAlreadyAcceptedOpen ? (
                    <ModalAlertVersion2
                        title={'Terms accepted'}
                        message={'You have already accepted the terms for the developer program of Variety Finder'}
                        close={this.closeModalAlertVarietyFinderTermsAlreadyAccepted}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderBreadcrumb
                    links={[
                        <Link key={'/'} to={'/'}>
                            {'User Area'}
                        </Link>,
                        'Menu',
                    ]}
                />
                <HeaderTitle title={`Welcome to User area / MyPVR`} />
                {mockWarning ||
                (this.state.feeDueDateInDays !== null &&
                    this.state.feeDueDateInDays >= 0 &&
                    this.state.feeDueDateInDays < 10) ? (
                    <div className={styles.bannerDanger}>
                        {`Dear user, your debit note will expire in ${
                            parseInt(mockWarning) || this.state.feeDueDateInDays
                        } day(s). Failure to pay it will trigger the cancellation of Community plant variety rights. Click `}
                        <a onClick={this.onLinkPendingFeesClick}>{`here`}</a>
                        {` to see the debit note. We remind that the lack of payment will lead to the cancellation of the CPVR and the cancellation is irreversible.`}
                    </div>
                ) : null}
                <FormWrapper className={styles.formWrapper} classNameContent={styles.formContent} paddingY={'lg'}>
                    <div className={styles.appLinksWrap}>
                        {isCPVOUser || hasUserAccessToCommunicationCentre ? (
                            <a onClick={this.goToTheCommunicationCentre} className={styles.appLink}>
                                {this.renderSiteLinkHTML(
                                    'Communication Centre',
                                    imageCommunicationCentre,
                                    this.state.numberOfUnreadMessages
                                )}
                            </a>
                        ) : null}
                        <a onClick={this.goToLegalProceedings} className={styles.appLink}>
                            {this.renderSiteLinkHTML('Legal Proceedings', imageLegalProceedings)}
                        </a>
                        {(this.state.sites || []).map(site => {
                            let renderSite = true;
                            let siteName = site.name;
                            let decoded = getDecodedJWT();
                            if (UNIQUE_ONLINE_SITE_ARRAY.indexOf(site.code) !== -1) {
                                if (ONLINE_SITE_ARRAY.indexOf(site.code) !== -1 && !uniqueOnlineSiteRendered) {
                                    renderSite = true;
                                    uniqueOnlineSiteRendered = true;
                                    siteName = `My PVR / Online Applications`;
                                } else {
                                    renderSite = false;
                                }
                            }
                            if (
                                siteName === 'Examination Offices Website (Breeders associations)' &&
                                decoded &&
                                (decoded.roles || []).includes('WETLO')
                            ) {
                                renderSite = false;
                            }
                            return renderSite && this.renderSiteLink(site, siteName);
                        })}
                        <a
                            onClick={() =>
                                this.goToApp({
                                    code: 'DENO2',
                                    URL: 'https://vf.plantvarieties.eu/varieties',
                                })
                            }
                            className={styles.appLink}
                        >
                            {this.renderSiteLinkHTML('Variety Finder', imageVarietyFinder)}
                        </a>
                        <Link to={`/requestCertifiedCopies`} className={styles.appLink}>
                            {this.renderSiteLinkHTML('Certified copies request', imageCertifiedCopies)}
                        </Link>
                        <Link to={`/publicAccess`} className={styles.appLink}>
                            {this.renderSiteLinkHTML('Request of documents', imageCertifiedCopies)}
                        </Link>
                        {this.showWebBackOfficeLink() ? (
                            <a
                                onClick={() =>
                                    this.goToApp({
                                        code: 'BACKOFFICE',
                                        URL: '/forms',
                                    })
                                }
                                className={styles.appLink}
                            >
                                {this.renderSiteLinkHTML('Web Back office - version 3', imageBackOffice)}
                            </a>
                        ) : null}
                        {this.showDevelopersProgramLink() ? (
                            <div className={styles.appLink} onClick={this.onLinkDevelopersProgramClick}>
                                {this.renderSiteLinkHTML("Developers' Program", imageDevelopersProgram)}
                            </div>
                        ) : null}
                    </div>
                    {this.state.administrator || this.state.companies.length ? (
                        <FormFooter className={styles.formFooter}>
                            {this.state.administrator ? (
                                <TextInput
                                    className={styles.textInputContactId}
                                    placeholder={`Contact ID`}
                                    onChange={this.onContactIdChange}
                                    onEnter={this.changeCompany}
                                    value={this.state.contactId}
                                />
                            ) : null}
                            {this.state.companies.length > 0 && (
                                <SelectInput
                                    className={styles.selectInputCompany}
                                    value={this.state.contactId}
                                    onChange={this.onContactIdChange}
                                    list={companyList}
                                    notAll={true}
                                />
                            )}
                            {this.state.administrator || this.state.companies.length > 0 ? (
                                <Button
                                    clickAction={this.changeCompany}
                                    icon={'change'}
                                    variation={'primary'}
                                >{`Change`}</Button>
                            ) : null}
                        </FormFooter>
                    ) : null}
                </FormWrapper>
                <Footer showContactUs={this.openModalContactUs} sticky={true} />
            </>
        );
    }
}

export default injectIntl(Welcome);
