import React from 'react';

// ICONS
import {faPrint} from '@fortawesome/free-solid-svg-icons';

// REUSABLE COMPONENTS
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';

const PrintComponent = () => {
    return (
        <FormFooterButton
            color={buttonColor.whiteGray}
            clickAction={() => window.print()}
            icon={faPrint}
        >{`Print`}</FormFooterButton>
    );
};

export default PrintComponent;
