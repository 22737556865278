import React from 'react';
import cx from 'classnames';
import styles from './BackOfficeFormsBottomCaption.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt, faPlusCircle, faTasks, faPrint} from '@fortawesome/free-solid-svg-icons';
import {faEye, faTrashAlt, faFile} from '@fortawesome/free-regular-svg-icons';

const listItems = [
    {
        icon: faEye,
        message: `Preview form`,
    },
    {
        icon: faPencilAlt,
        message: 'Edit form',
    },
    {
        icon: faTasks,
        message: 'Compare with previous version',
    },
    {
        icon: faFile,
        message: 'Copy form',
    },
    {
        icon: faPrint,
        message: 'Print form',
    },
    {
        icon: faPlusCircle,
        message: 'Create a new version',
    },
    {
        icon: faTrashAlt,
        message: 'Delete form',
    },
];

const renderListItem = ({icon, message, index, listLength}: any) => {
    return (
        <div key={message} style={{display: 'inline-block'}}>
            <div className={cx(styles.legendItem, styles.icon)}>
                <FontAwesomeIcon icon={icon} />
            </div>
            <div className={styles.legendItem}>{` ${message} ${index < listLength - 1 ? ' - ' : ''}`}</div>
        </div>
    );
};

export default function BackOfficeFormsBottomCaption() {
    return (
        <div>
            <fieldset className={styles.schedulerBorder}>
                <legend className={styles.legendSchedulerBorder}>
                    <i>
                        <span>{`Legend`}</span>
                    </i>
                </legend>
                <div className={styles.container}>
                    {listItems.map((el, i) =>
                        renderListItem({
                            icon: el.icon,
                            message: el.message,
                            index: i,
                            listLength: listItems.length,
                        })
                    )}
                </div>
            </fieldset>
        </div>
    );
}
