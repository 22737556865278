import React from 'react';
import CryptoJS from 'crypto-js';
import {faFlag, faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {saveAs} from 'file-saver';
import jwtDecode from 'jwt-decode';
import TextLabelInput from '~components/TextLabelInput';
import Info from '~components/Info';
import TextAreaInput from '~components/TextAreaInput';
import CustomTable from '~components/CustomTable';
import SelectInput from '~components/SelectInput';
import DragAndDrop from '~components/DragAndDrop';
import {getDecodedJWT, isRegisterIdValid, sanitize, customBTOA} from '~utils';
import {getMandatoryAsterisk} from '~utils/format';
import getIcon from '~utils/icons';
import CCModalInternalComposeActions from './CCModalInternalComposeActions';
import {
    apiCommunicationCentreModalInternalComposeCCList,
    apiCommunicationCentreModalInternalComposeClients,
    apiCommunicationCentreModalInternalComposeGetFileInfo,
    apiCommunicationCentreModalInternalComposeMessageSend,
    apiCommunicationCentreModalInternalComposeSignature,
} from './CCModalInternalComposeService';
import {
    findDefaultValueForDirectory,
    getUserRoleName,
    OPTION_ALL,
    USER_SHORTKEY_TO_CONTACTID,
} from '../CommunicationCentreInternal/CommunicationCentreInternal.utils';
import InputLink from '~components/InputLink';
import ModalApplicationList from '../RequestCertifiedCopies/modals/ModalApplicationList';
import {MAX_UPLOAD_FILE_SIZE} from '~utils/constants';
import {
    ICCListItem,
    IClientItem,
    ICommunicationMessageInternalSubmitJSONRequest,
    IDecodedJWT,
    IDocument,
    TProfilesDirectoriesSubtype,
} from '../../types';
import _PROFILES_DIRECTORIES_SUBTYPES from '../CommunicationCentreInternal/modals/DATA/PROFILES_DIRECTORIES_SUBTYPES.json';
import {Editor} from '@tinymce/tinymce-react';
import {tinyConfig} from '../CommunicationCentreInternal/CommunicationCentreInternal.config';
import {ModalConfirmVersion2, ModalCustomVersion2} from '../../commonModals';
import {Error, LoadingBar} from '../../componentsLayout';
import styles from './CCModalInternalCompose.module.scss';
import {Button} from '../../componentsFormV2';
import {CC_MAX_MESSAGE_BODY_LENGTH} from '../../utils/constants';
import Checkbox from '~components/Checkbox';

const SELECT_LIST_ITEM_ID_NOT_SELECTED = 'none';

const SELECT_LIST_VALUE = {
    DOCMAN_PROFILE_NOT_SELECTED: 'Please select a profile',
    DOCMAN_DIRECTORY_NOT_SELECTED: 'Please select a directory',
    DOCMAN_SUBTYPE_NOT_SELECTED: 'Please select a subtype',
};

const SELECT_LIST_ALL_DOCMAN_PROFILES = [
    {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_PROFILE_NOT_SELECTED},
    {id: 'ACCOUN', value: 'Accountancy'},
    {id: 'BOADOC', value: 'Board of appeal'},
    {id: 'GREEND', value: 'Green Files'},
    {id: 'HR', value: 'Human Resources'},
    {id: 'LEGALD', value: 'Legal'},
    {id: 'PRESIF', value: 'Presidency'},
    {id: 'PURCHA', value: 'Purchase'},
    {id: 'QAUDIT', value: 'Quality Audit'},
    {id: 'TECHMA', value: 'Technical matters'},
    {id: 'THPDOC', value: 'Third parties'},
];

const errorMessages = {
    UNKNOWN: `This is not a valid application number. The value must be in range 19950001 to ${new Date().getFullYear()}9999!`,
    FORBIDDEN: 'This application is not linked to your account',
    DUPLICATE: `Application number already in the list`,
    NOT_RECEIPT: 'The reception of your application has not been finalised yet.',
    ADD: 'Please include the application number using the button + or "Enter" keyboard button.',
};

const CPVO_THIRD_PARTY_ID = '1';

const arrayUniqueByKey = (array: any[], uniqueKey = 'id') => [
    ...new Map(array.map(item => [item[uniqueKey], item])).values(),
];

const getSelectedThirdPartyIdValue = (client: IClientItem) => {
    let postFix = 'Requestor';
    if (client.roles === '1') {
        postFix = 'Applicant';
    }
    if (client.roles === '2') {
        postFix = 'Representative';
    }
    return `${client.companyName} - ${client.thirdPartyId} - (${postFix})`;
};

export const getSelectListOfDocmanProfiles = (decodedJWT: IDecodedJWT) => {
    let {id: contactId} = decodedJWT || {};
    let selectList = [
        {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_PROFILE_NOT_SELECTED},
        ...SELECT_LIST_ALL_DOCMAN_PROFILES.filter(el => Object.keys(PROFILES_DIRECTORIES_SUBTYPES).includes(el.id)),
    ];
    if (parseInt(contactId) === USER_SHORTKEY_TO_CONTACTID.FB) {
        selectList = selectList.filter(listOption => listOption.id === 'BOADOC');
    } else {
        selectList = selectList.filter(listOption => listOption.id === 'GREEND');
    }
    return selectList;
};

interface IProps {
    close: (refresh?: boolean) => void;
    intl: any;
}

type TApplicationNumberError = typeof errorMessages;

type TKeyApplicationNumberError = keyof TApplicationNumberError;

interface IState {
    CPVOColleaguesListByIdName: {ID: string; NAME: string}[];
    CPVOColleaguesNameHash: {[key: string]: number[]};
    applicationNumber: string;
    applicationNumberError: TKeyApplicationNumberError | null;
    body: string;
    checkboxUrgentValue: boolean;
    documentList: IDocument[];
    error: string | null;
    isModalConfirmSaveChangesAsADraftOpen: boolean;
    modalImportApplicationNumberList: boolean;
    selectValueProfile: string;
    selectValueDirectory: string;
    selectValueSubtypeOfDocument: string;
    selectedApplicationNumbers: {[key: string]: string};
    selectedCPVOColleagues: {[key: string]: string};
    subject: string;
    loading: number;
    selectedThirdPartyId: {[key: string]: string};
    thirdPartyId: any;
    selectListDocmanProfiles: {id: string; value: string}[];
    selectListDocmanDirectories: {id: string | number; value: string}[];
    selectListDocmanDocumentSubtypes: {id: string | number; value: string}[];
    signatureFullName: string;
    signatureRole: string;
    signatureUnit: string;
    textLabelInputValueCPVOColleagues: string;
    timestamp: number;
}

const PROFILES_DIRECTORIES_SUBTYPES = _PROFILES_DIRECTORIES_SUBTYPES as TProfilesDirectoriesSubtype;

export default class CCModalInternalCompose extends React.Component<IProps, IState> {
    clientList: {ID: string; NAME: string}[] = [];
    clientHash: {[key: string]: IClientItem} = {};
    clientNameHash: {[key: string]: number[]} = {};
    decodedJWT: IDecodedJWT = sessionStorage.getItem('token-cc')
        ? jwtDecode(sessionStorage.getItem('token-cc'))
        : getDecodedJWT();
    ccList: {id: number; name: string; role: number}[] = [];
    clientResults: IClientItem[] = [];
    inputUploadRef: any = null;

    constructor(props: IProps) {
        super(props);
        this.inputUploadRef = React.createRef();
        const defaultValueForDirectory = findDefaultValueForDirectory(this.decodedJWT);
        this.state = {
            CPVOColleaguesListByIdName: [],
            CPVOColleaguesNameHash: {},
            body: '',
            checkboxUrgentValue: false,
            selectValueProfile: 'GREEND',
            selectValueDirectory:
                defaultValueForDirectory === OPTION_ALL ? SELECT_LIST_ITEM_ID_NOT_SELECTED : defaultValueForDirectory,
            selectValueSubtypeOfDocument: SELECT_LIST_ITEM_ID_NOT_SELECTED,
            applicationNumber: '',
            applicationNumberError: null,
            selectedApplicationNumbers: {},
            selectedCPVOColleagues: {},
            subject: '',
            error: null,
            isModalConfirmSaveChangesAsADraftOpen: false,
            documentList: [],
            loading: 0,
            modalImportApplicationNumberList: false,
            selectedThirdPartyId: {},
            thirdPartyId: null,
            selectListDocmanProfiles: getSelectListOfDocmanProfiles(this.decodedJWT),
            selectListDocmanDirectories: [
                {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_DIRECTORY_NOT_SELECTED},
                ...arrayUniqueByKey(
                    PROFILES_DIRECTORIES_SUBTYPES['GREEND'].map(el => ({
                        id: el.dirId,
                        value: el.dirName,
                    }))
                ),
            ],
            selectListDocmanDocumentSubtypes: [
                {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_SUBTYPE_NOT_SELECTED},
            ],
            signatureFullName: '',
            signatureRole: '',
            signatureUnit: '',
            textLabelInputValueCPVOColleagues: '',
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        this.loadJSONs();
        if (this.state.selectListDocmanProfiles?.length === 1) {
            this.onSelectInputProfileChange({target: {value: this.state.selectListDocmanProfiles[0].id}});
        }
        if (this.state.selectValueDirectory !== SELECT_LIST_ITEM_ID_NOT_SELECTED) {
            this.onSelectInputDirectoryChange({target: {value: this.state.selectValueDirectory}});
        }
    }

    loadJSONs = () => {
        if (this.decodedJWT.thirdPartyId === CPVO_THIRD_PARTY_ID) {
            Promise.all([this.loadCPVOColleagues(), this.loadSignature()]);
        }
    };

    loadCPVOColleagues = () =>
        new Promise((resolve, reject) => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiCommunicationCentreModalInternalComposeCCList()
                        .then((jsonResponse: {data: ICCListItem[]}) => {
                            if (jsonResponse) {
                                this.ccList = jsonResponse.data;
                                const CPVOColleaguesNameHash: {[key: string]: number[]} = {};
                                (jsonResponse.data || []).forEach(colleagueElement => {
                                    const {id, name, role} = colleagueElement || {};
                                    let nameWithRole = `${name}${getUserRoleName(role, this.decodedJWT)}`;
                                    if (!CPVOColleaguesNameHash[nameWithRole]) {
                                        CPVOColleaguesNameHash[nameWithRole] = [];
                                    }
                                    CPVOColleaguesNameHash[nameWithRole].push(id);
                                });
                                const CPVOColleaguesListByIdName = Object.keys(CPVOColleaguesNameHash).map(
                                    colleagueName => ({
                                        ID: CPVOColleaguesNameHash[colleagueName].join(','),
                                        NAME: colleagueName,
                                    })
                                );
                                const selectedCPVOColleagues = {};
                                this.setState({
                                    CPVOColleaguesListByIdName,
                                    CPVOColleaguesNameHash,
                                    selectedCPVOColleagues,
                                });
                            }
                        })
                        .catch((error: any) => {
                            ERROR([`ModalCommunicationCenterCompose: ${error.message}`]);
                            reject(error && error.message);
                        })
                        .then(() =>
                            this.setState(
                                prev => ({loading: prev.loading - 1}),
                                () => resolve(true)
                            )
                        );
                }
            );
        });

    loadSignature = () =>
        new Promise((resolve, reject) => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiCommunicationCentreModalInternalComposeSignature()
                        .then(JSONRespose => {
                            if (JSONRespose && JSONRespose.data) {
                                const {signatureFullName, signatureRole, signatureUnit} = JSONRespose.data;
                                this.setState({
                                    signatureFullName,
                                    signatureRole,
                                    signatureUnit,
                                });
                            }
                        })
                        .catch((error: any) => {
                            ERROR([`ModalCommunicationCenterCompose: ${error.message}`]);
                            reject(error && error.message);
                        })
                        .finally(() =>
                            this.setState(
                                prev => ({loading: prev.loading - 1}),
                                () => resolve(true)
                            )
                        );
                }
            );
        });

    confirmSending = (messageStatus: 'draft' | 'sent') => {
        this.setState({isModalConfirmSaveChangesAsADraftOpen: false});
        if (this.state.selectValueProfile === SELECT_LIST_ITEM_ID_NOT_SELECTED) {
            this.setState({error: 'Please select a profile to continue'});
            return;
        }
        if (this.state.selectValueDirectory === SELECT_LIST_ITEM_ID_NOT_SELECTED) {
            this.setState({error: 'Please select a directory to continue'});
            return;
        }
        if (this.state.selectValueSubtypeOfDocument === SELECT_LIST_ITEM_ID_NOT_SELECTED) {
            this.setState({error: 'Please select a subtype of document to continue'});
            return;
        }
        if (this.checkIfAddressesIsMandatory()) {
            if (!Object.keys(this.state.selectedThirdPartyId || {}).length) {
                this.setState({error: 'Addressees cannot be empty'});
                return;
            }
        }
        if (Object.keys(this.state.selectedApplicationNumbers).length === 0) {
            this.setState({error: 'No application number linked'});
            return;
        }
        if (!this.state.subject) {
            this.setState({error: 'Subject cannot be empty'});
            return;
        }
        if (this.state.body.length > 4000) {
            this.setState({error: CC_MAX_MESSAGE_BODY_LENGTH});
            return;
        }
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                const {body, documentList, subject, selectedApplicationNumbers} = this.state;
                const JSONRequest: ICommunicationMessageInternalSubmitJSONRequest = {
                    CPVOColleagues: [],
                    addressees: [],
                    applicationNumbers: Object.keys(selectedApplicationNumbers),
                    messageBody: customBTOA(body),
                    docmanProfile: this.state.selectValueProfile,
                    docmanDirectory: this.state.selectValueDirectory,
                    docmanSubtypeOfDocument: this.state.selectValueSubtypeOfDocument,
                    draft: messageStatus === 'draft',
                    messageSubject: customBTOA(subject),
                    numberOfAttachments: (documentList || []).length,
                    urgent: this.state.checkboxUrgentValue,
                };
                Object.keys(this.state.selectedThirdPartyId || {})
                    .map(el => parseInt(el))
                    .forEach(thirdPartyId => {
                        JSONRequest.addressees.push({
                            thirdPartyId,
                            role:
                                (
                                    this.clientResults.find(
                                        clientResult => clientResult['thirdPartyId'] === thirdPartyId
                                    ) || {}
                                ).roles || null,
                        });
                    });
                Object.keys(this.state.selectedCPVOColleagues || {})
                    .map(el => parseInt(el))
                    .forEach(thirdPartyId => {
                        JSONRequest.CPVOColleagues.push({
                            contactId: thirdPartyId,
                            role:
                                (this.ccList.find(ccListResult => ccListResult['id'] === thirdPartyId) || {}).role ||
                                null,
                        });
                    });
                apiCommunicationCentreModalInternalComposeMessageSend(JSONRequest, documentList)
                    .then(({communicationId}: {communicationId: number}) => {
                        if (communicationId) {
                            this.setState(
                                prev => ({loading: prev.loading - 1}),
                                () => {
                                    if (messageStatus === 'draft') {
                                        this.close(true);
                                    } else {
                                        this.props.close();
                                    }
                                }
                            );
                        } else {
                            this.setState(prev => ({loading: prev.loading - 1, error: '404 - Document not found'}));
                        }
                    })
                    .catch(this.close);
            }
        );
    };

    close = (refresh: boolean) => this.props.close && this.props.close(refresh);

    closeError = () => this.setState({error: null});

    handleDrop = (files: FileList, event: any) => this.setState({error: null}, () => this.checkFile(files[0], event));

    checkFile = (file: File, event: React.ChangeEvent<HTMLInputElement>) => {
        LOG([`file.type:${file.type}`]);
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType?.toLowerCase();
        const filename = sanitize(file.name);
        this.setState({error: null}, () => {
            if (
                fileTypeLowercase === 'jpeg' ||
                fileTypeLowercase === 'png' ||
                fileTypeLowercase === 'jpg' ||
                fileTypeLowercase === 'pdf'
            ) {
                if (file.size > MAX_UPLOAD_FILE_SIZE) {
                    this.setState({
                        error: 'The maximum document size for uploads is 10 MB. Please select a proper file.',
                    });
                } else {
                    const a = new FileReader();
                    a.readAsBinaryString(file);
                    a.onloadend = () => {
                        const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(a.result));
                        this.setState(
                            prev => {
                                const documentList = prev.documentList.slice(0);
                                documentList.push({
                                    index,
                                    file,
                                    filename,
                                    size: file.size / 1024,
                                    extension: fileTypeLowercase,
                                    documentType: null,
                                });
                                return {documentList, timestamp: Date.now()};
                            },
                            () => (event.target.value = '')
                        );
                    };
                }
            } else {
                this.setState({error: 'type not valid'}, () => (event.target.value = ''));
            }
        });
    };

    deleteFile = (index: any) =>
        this.setState(prev => {
            return {
                documentList: prev.documentList.filter(i => i.index !== index),
                timestamp: Date.now(),
            };
        });

    checkApplicationNumber = (applicationNumber: string) => {
        let applicationNumberValue = applicationNumber;
        if (!isNaN(Number(applicationNumberValue))) {
            applicationNumberValue = applicationNumberValue.substring(0, 8);
            this.setState({
                applicationNumber: applicationNumberValue,
                applicationNumberError: isRegisterIdValid(applicationNumberValue)
                    ? applicationNumberValue.length === 8
                        ? 'ADD'
                        : null
                    : applicationNumber !== ''
                    ? 'UNKNOWN'
                    : null,
            });
        }
    };

    updateClientList = (selectedApplicationNumbers: {[key: string]: string}) =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiCommunicationCentreModalInternalComposeClients({
                    applicationNumbers: Object.keys(selectedApplicationNumbers),
                }).then((resultJSON: {data: IClientItem[]}) => {
                    this.clientList = [];
                    this.clientHash = {};
                    this.clientNameHash = {};
                    this.clientResults = resultJSON.data;
                    resultJSON.data.forEach(client => {
                        const companyNameWithThirdPartyIdv = getSelectedThirdPartyIdValue(client);
                        this.clientList.push({
                            ID: String(client.thirdPartyId),
                            NAME: companyNameWithThirdPartyIdv,
                        });
                        this.clientHash[`${client.thirdPartyId}`] = client;
                        this.clientNameHash[companyNameWithThirdPartyIdv] = [client.thirdPartyId];
                    });
                    let clientsWithRoles2 = resultJSON.data.filter(el => parseInt((el && el.roles) || '') === 2);
                    let clientsWithRoles1 = resultJSON.data.filter(el => parseInt((el && el.roles) || '') === 1);
                    return new Promise(resolve =>
                        this.setState(
                            prev => {
                                // THIS IS FOR keeping selected ids on application number change, if still exist
                                let selectedThirdPartyIdWithRoles2: {[key: string]: string} = {};
                                let selectedThirdPartyIdWithRoles1: {[key: string]: string} = {};
                                clientsWithRoles1.forEach(({thirdPartyId}) => {
                                    if (prev.selectedThirdPartyId[thirdPartyId]) {
                                        selectedThirdPartyIdWithRoles1[thirdPartyId] =
                                            prev.selectedThirdPartyId[thirdPartyId];
                                    }
                                });
                                clientsWithRoles2.forEach(client => {
                                    selectedThirdPartyIdWithRoles2[client.thirdPartyId] =
                                        getSelectedThirdPartyIdValue(client);
                                });
                                let theSelectedThirdParty: {[key: string]: string} = {};
                                if (clientsWithRoles2.length) {
                                    if (
                                        clientsWithRoles2 &&
                                        clientsWithRoles2[0] &&
                                        clientsWithRoles2[0].thirdPartyId
                                    ) {
                                        theSelectedThirdParty[clientsWithRoles2[0].thirdPartyId] =
                                            getSelectedThirdPartyIdValue(clientsWithRoles2[0]);
                                    }
                                }
                                if (!Object.keys(theSelectedThirdParty).length) {
                                    if (
                                        clientsWithRoles1 &&
                                        clientsWithRoles1[0] &&
                                        clientsWithRoles1[0].thirdPartyId
                                    ) {
                                        theSelectedThirdParty[clientsWithRoles1[0].thirdPartyId] =
                                            getSelectedThirdPartyIdValue(clientsWithRoles1[0]);
                                    }
                                }
                                return {
                                    selectedThirdPartyId: {
                                        ...theSelectedThirdParty,
                                        ...selectedThirdPartyIdWithRoles2,
                                        ...selectedThirdPartyIdWithRoles1,
                                    },
                                    loading: prev.loading - 1,
                                };
                            },
                            () => resolve(true)
                        )
                    );
                });
            }
        );

    checkIfAddressesIsMandatory = () => {
        let {id: contactId} = this.decodedJWT || {};
        if (parseInt(contactId) === USER_SHORTKEY_TO_CONTACTID.FB) {
            if (Object.keys(this.state.selectedCPVOColleagues).length) {
                return false;
            }
        }
        return true;
    };

    getLabelOfCC = () => {
        let {id: contactId} = this.decodedJWT || {};
        if (parseInt(contactId) === USER_SHORTKEY_TO_CONTACTID.FB) {
            return 'CPVO Colleagues / BOA members / EU agencies BOA members';
        }
        return 'CPVO Colleagues';
    };

    openModalConfirmSaveChangesAsADraft = () => this.setState({isModalConfirmSaveChangesAsADraftOpen: true});

    closeModalImportApplicationNumbersList = () => this.setState({modalImportApplicationNumberList: false});

    onSelectInputProfileChange = ({
        target: {value: selectValueProfile},
    }: React.ChangeEvent<HTMLSelectElement> | {target: {value: string}}) => {
        if (selectValueProfile === SELECT_LIST_ITEM_ID_NOT_SELECTED) {
            this.setState({
                selectValueProfile: SELECT_LIST_ITEM_ID_NOT_SELECTED,
                selectValueDirectory: SELECT_LIST_ITEM_ID_NOT_SELECTED,
                selectValueSubtypeOfDocument: SELECT_LIST_ITEM_ID_NOT_SELECTED,
            });
        } else {
            this.setState(() => {
                let selectListDocmanDirectories = [
                    {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_DIRECTORY_NOT_SELECTED},
                    ...arrayUniqueByKey(
                        PROFILES_DIRECTORIES_SUBTYPES[selectValueProfile].map(el => ({
                            id: el.dirId,
                            value: el.dirName,
                        }))
                    ),
                ];
                return {selectValueProfile, selectListDocmanDirectories};
            });
        }
    };

    onSelectInputDirectoryChange = ({
        target: {value: selectValueDirectory},
    }: React.ChangeEvent<HTMLSelectElement> | {target: {value: string}}) => {
        this.setState(prev => {
            let selectListDocmanDocumentSubtypes = [
                {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_SUBTYPE_NOT_SELECTED},
                ...PROFILES_DIRECTORIES_SUBTYPES[prev.selectValueProfile]
                    .filter(directoryItem => directoryItem.dirId === parseInt(selectValueDirectory))
                    .map(el => ({
                        id: el.docTypeId,
                        value: el.docTypeName,
                    })),
            ];
            return {selectValueDirectory, selectListDocmanDocumentSubtypes};
        });
    };

    onSelectInputSubtypeOfDocumentChange = ({
        target: {value: selectValueSubtypeOfDocument},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({selectValueSubtypeOfDocument});

    onInputLinkImportApplicationNumberListClick = () => this.setState({modalImportApplicationNumberList: true});

    onImportApplicationNumbersList = (matches: string[]) =>
        this.setState(
            prev => {
                const currentSelectedApplicationNumbers = {...prev.selectedApplicationNumbers};
                matches.forEach(el => (currentSelectedApplicationNumbers[el] = el));
                return {selectedApplicationNumbers: currentSelectedApplicationNumbers};
            },
            () => this.updateClientList(this.state.selectedApplicationNumbers)
        );

    onApplicationNumberChange = (applicationNumber: string): void => this.checkApplicationNumber(applicationNumber);

    onApplicationNumbersSelectionChange = (selectedApplicationNumbers: {[key: string]: string}): void =>
        this.setState(
            () => {
                let optionalState: any = {};
                if (Object.keys(selectedApplicationNumbers).length === 0) {
                    optionalState.selectedThirdPartyId = {};
                }
                return {
                    selectedApplicationNumbers,
                    ...optionalState,
                };
            },
            () => {
                Object.keys(selectedApplicationNumbers).length !== 0 &&
                    this.updateClientList(selectedApplicationNumbers);
            }
        );

    onIdChange = (thirdPartyId: string, callback: () => any) => {
        LOG([`thirdPartyId: ${thirdPartyId}`]);
        if (thirdPartyId) {
            const selectedThirdPartyId: {[key: string]: string} = {};
            this.clientList
                .filter(i => i.ID === thirdPartyId)
                .forEach(contact => (selectedThirdPartyId[contact.ID] = contact.NAME));
            this.setState(
                {
                    thirdPartyId,
                    selectedThirdPartyId,
                },
                callback
            );
        }
    };
    onThirdPartyUserSelectionChange = (selectedThirdPartyId: {[key: string]: string}) => {
        this.setState(() => {
            let optionalState: any = {};
            if (Object.keys(selectedThirdPartyId).length === 0) {
                optionalState.thirdPartyId = null;
            }
            return {
                selectedThirdPartyId,
                ...optionalState,
            };
        });
    };

    onAddingApplicationNumber = () => {
        const {applicationNumber} = this.state;
        if (applicationNumber.length === 8 && isRegisterIdValid(applicationNumber)) {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    (this.decodedJWT.thirdPartyId === CPVO_THIRD_PARTY_ID
                        ? Promise.resolve({message: 'OK'})
                        : apiCommunicationCentreModalInternalComposeGetFileInfo(applicationNumber)
                    ) // needed ?
                        .then((result: any) => {
                            if (result.message !== 'OK') {
                                this.setState({applicationNumberError: 'FORBIDDEN'});
                            } else {
                                this.setState(
                                    prev => {
                                        const selectedApplicationNumbers = prev.selectedApplicationNumbers;
                                        selectedApplicationNumbers[prev.applicationNumber] = prev.applicationNumber;
                                        return {
                                            applicationNumber: '',
                                            applicationNumberError: null,
                                            selectedApplicationNumbers,
                                        };
                                    },
                                    () => this.updateClientList(this.state.selectedApplicationNumbers)
                                );
                            }
                        })
                        .then(() => this.setState(prev => ({loading: prev.loading - 1})));
                }
            );
        }
    };

    onCPVOColleaguesSectionChange = (selectedCPVOColleagues: {[key: string]: string}): void =>
        this.setState({selectedCPVOColleagues, textLabelInputValueCPVOColleagues: ''});

    onTextLabelInputCPVOColleaguesChange = (textLabelInputValueCPVOColleagues: string): void =>
        this.setState({textLabelInputValueCPVOColleagues});

    onSubjectChange = ({target: {value: subject}}: React.ChangeEvent<HTMLTextAreaElement>) =>
        this.setState({subject: subject.slice(0, 200)});

    // onBodyChange = ({target: {value: body}}: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({body});
    onBodyChange = (body: string) => this.setState({body});

    onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];
            this.checkFile(file, event);
        }
    };

    onCheckboxUrgentClick = () => this.setState(prev => ({checkboxUrgentValue: !prev.checkboxUrgentValue}));

    onModalConfirmSaveChangesAsADraftActionClick = () => this.confirmSending('draft');

    render() {
        const actions = CCModalInternalComposeActions(this.deleteFile);

        return (
            <>
                {this.state.modalImportApplicationNumberList ? (
                    <ModalApplicationList
                        importList={this.onImportApplicationNumbersList}
                        close={this.closeModalImportApplicationNumbersList}
                    />
                ) : null}
                {this.state.isModalConfirmSaveChangesAsADraftOpen ? (
                    <ModalConfirmVersion2
                        title={`Save draft`}
                        message={`Do you want to save the message as a draft?`}
                        buttonName={'Yes'}
                        action={this.onModalConfirmSaveChangesAsADraftActionClick}
                        close={() => this.close(true)}
                    />
                ) : null}
                <DragAndDrop handleDrop={this.handleDrop}>
                    <ModalCustomVersion2
                        close={this.openModalConfirmSaveChangesAsADraft}
                        header={`Compose a new message`}
                        body={
                            <div>
                                <SelectInput
                                    label={`Profile`}
                                    value={this.state.selectValueProfile}
                                    onChange={this.onSelectInputProfileChange}
                                    list={this.state.selectListDocmanProfiles || []}
                                    notAll={true}
                                    triple={true}
                                    noIcon={true}
                                    mandatory={true}
                                    disabled={this.state.selectListDocmanProfiles?.length === 1}
                                />
                                <SelectInput
                                    label={`Directory`}
                                    value={this.state.selectValueDirectory}
                                    onChange={this.onSelectInputDirectoryChange}
                                    list={this.state.selectListDocmanDirectories || []}
                                    notAll={true}
                                    triple={true}
                                    noIcon={true}
                                    mandatory={true}
                                    disabled={this.state.selectValueProfile === SELECT_LIST_ITEM_ID_NOT_SELECTED}
                                    hideOptionNone={true}
                                />
                                <SelectInput
                                    label={`Subtype of document`}
                                    value={this.state.selectValueSubtypeOfDocument}
                                    onChange={this.onSelectInputSubtypeOfDocumentChange}
                                    list={this.state.selectListDocmanDocumentSubtypes || []}
                                    notAll={true}
                                    triple={true}
                                    noIcon={true}
                                    mandatory={true}
                                    disabled={
                                        this.state.selectValueProfile === SELECT_LIST_ITEM_ID_NOT_SELECTED ||
                                        this.state.selectValueDirectory === SELECT_LIST_ITEM_ID_NOT_SELECTED
                                    }
                                />
                                {this.state.documentList.length > 0 && (
                                    <CustomTable
                                        version={2}
                                        {...this.props}
                                        notSortable={['filename', 'size']}
                                        tableName={'documentUpload'}
                                        tableType={'OBJECT'}
                                        tableSource={this.state.documentList || []}
                                        dataFilter={null}
                                        id={'index'}
                                        setLastCursor={null}
                                        resultFieldsDefault={['filename', 'size']}
                                        intl={this.props.intl}
                                        formatFunctions={{
                                            size: (size: number) => `${Math.floor(size * 100) / 100} KB`,
                                            extension: (extension: string) => (
                                                <img src={getIcon(extension || 'pdf')} alt={extension} />
                                            ),
                                        }}
                                        count={(this.state.documentList || []).length}
                                        hideExcelButton={true}
                                        noChangePageSize={true}
                                        forehandColumn={(row: IDocument) => (
                                            <img
                                                style={{width: 20, height: 20, cursor: 'pointer'}}
                                                alt={'Download'}
                                                src={getIcon(row.extension || 'pdf')}
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    saveAs(row.file, row.filename);
                                                }}
                                            />
                                        )}
                                        timestamp={this.state.timestamp}
                                        actions={actions}
                                    />
                                )}
                                <TextLabelInput
                                    onSelectionChange={this.onApplicationNumbersSelectionChange}
                                    onChange={this.onApplicationNumberChange}
                                    onEnter={this.onAddingApplicationNumber}
                                    value={this.state.applicationNumber}
                                    selectedElements={this.state.selectedApplicationNumbers}
                                    delay={300}
                                    multiple={true}
                                    label={getMandatoryAsterisk(
                                        'To which application number(s) the communication is linked'
                                    )}
                                    buttonAction={this.onAddingApplicationNumber}
                                    buttonIcon={faPlus}
                                    buttonDisabled={
                                        (this.state.applicationNumberError &&
                                            this.state.applicationNumberError !== 'ADD') ||
                                        this.state.applicationNumber.length !== 8
                                    }
                                    triple={true}
                                />
                                {this.state.applicationNumberError && (
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            width: 700,
                                            marginLeft: 40,
                                            marginBottom: 20,
                                        }}
                                    >
                                        <Info>
                                            <div style={{display: 'inline-block', marginRight: 5}}>
                                                <FontAwesomeIcon icon={faFlag as any} color={'#8a6d3b'} />
                                            </div>
                                            <div style={{display: 'inline-block'}}>
                                                {errorMessages[this.state.applicationNumberError]}
                                            </div>
                                        </Info>
                                    </div>
                                )}
                                <div style={{float: 'left', marginTop: -11, paddingBottom: 7}}>
                                    <InputLink
                                        label={`Import application number list`}
                                        clickAction={this.onInputLinkImportApplicationNumberListClick}
                                        width={280}
                                    />
                                </div>
                                <div style={{clear: 'both'}} />
                                <TextLabelInput
                                    onIdChange={this.onIdChange}
                                    onSelectionChange={this.onThirdPartyUserSelectionChange}
                                    selectedElements={this.state.selectedThirdPartyId}
                                    delay={300}
                                    multiple={true}
                                    label={
                                        this.checkIfAddressesIsMandatory()
                                            ? getMandatoryAsterisk('Addressees')
                                            : 'Addressees'
                                    }
                                    placeholder={
                                        Object.keys(this.state.selectedThirdPartyId || {}).length
                                            ? ''
                                            : `Please click on input and select an addressee`
                                    }
                                    triple={true}
                                    disabled={Object.keys(this.state.selectedApplicationNumbers).length === 0}
                                    listByIdName={this.clientList}
                                    nameHash={this.clientNameHash}
                                    uniqueValue={false}
                                    autoSelection={true}
                                    filterContains={true}
                                    clickable={true}
                                />
                                <TextLabelInput
                                    clickableAndEditable={true}
                                    double={true}
                                    onChange={this.onTextLabelInputCPVOColleaguesChange}
                                    onSelectionChange={this.onCPVOColleaguesSectionChange}
                                    value={this.state.textLabelInputValueCPVOColleagues}
                                    selectedElements={this.state.selectedCPVOColleagues}
                                    delay={300}
                                    multiple={true}
                                    label={this.getLabelOfCC()}
                                    placeholder={`Please type part of the colleague name`}
                                    triple={true}
                                    listByIdName={this.state.CPVOColleaguesListByIdName}
                                    nameHash={this.state.CPVOColleaguesNameHash}
                                    filterContains
                                />
                                <TextAreaInput
                                    label={getMandatoryAsterisk('Subject')}
                                    value={this.state.subject}
                                    onChange={this.onSubjectChange}
                                    rows={3}
                                    triple={true}
                                    noIcon={true}
                                />
                                <div style={{clear: 'both'}} />
                                <div style={{margin: '3px 30px 15px 10px'}}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div>{getMandatoryAsterisk('Body')}</div>
                                        <div>
                                            {`Characters count: `}
                                            <span
                                                style={{color: this.state.body.length > 4000 ? 'red' : 'green'}}
                                            >{`${this.state.body.length} / 4000`}</span>
                                        </div>
                                    </div>
                                    <div className={styles.bodyEditorWrap}>
                                        <Editor
                                            id="textareaMainLanguage"
                                            value={this.state.body}
                                            init={tinyConfig}
                                            onEditorChange={this.onBodyChange}
                                        />
                                        {this.state.signatureFullName ||
                                        this.state.signatureRole ||
                                        this.state.signatureUnit ? (
                                            <div
                                                style={{
                                                    marginTop: 7,
                                                    marginBottom: 20,
                                                    marginLeft: 14,
                                                    color: 'navy',
                                                    fontSize: 14,
                                                }}
                                            >
                                                {this.state.signatureFullName ? (
                                                    <div
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        {this.state.signatureFullName}
                                                    </div>
                                                ) : null}
                                                {this.state.signatureRole && this.state.signatureUnit ? (
                                                    <div>
                                                        <span>{this.state.signatureUnit}</span>
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div>
                                    <Checkbox
                                        clickAction={this.onCheckboxUrgentClick}
                                        simple={true}
                                        label={`Urgent`}
                                        value={this.state.checkboxUrgentValue}
                                        width={500}
                                    />
                                </div>
                                <div style={{clear: 'both'}} />
                            </div>
                        }
                        footer={
                            this.state.loading !== 0 ? (
                                <LoadingBar />
                            ) : (
                                <>
                                    {this.state.error ? <Error>{this.state.error}</Error> : null}
                                    <div className={styles.modalFooterActions}>
                                        <div className={styles.buttonAddDocument}>
                                            <label htmlFor={'upload'}>
                                                <input
                                                    type="file"
                                                    name="files[]"
                                                    id="upload"
                                                    style={{opacity: 0, width: 0}}
                                                    onChange={this.onFileUpload}
                                                    accept="image/jpg, image/jpeg, image/png, application/pdf"
                                                    ref={this.inputUploadRef}
                                                />
                                                <Button
                                                    variation={'secondary'}
                                                    clickAction={() => this.inputUploadRef.current.click()}
                                                >{`Add document`}</Button>
                                            </label>
                                        </div>
                                        <div className={styles.buttonsRightWrap}>
                                            <Button
                                                variation={'danger'}
                                                clickAction={this.openModalConfirmSaveChangesAsADraft}
                                                icon={'close'}
                                                className={styles.buttonClose}
                                            >{`Close`}</Button>
                                            <Button
                                                variation={'secondary'}
                                                clickAction={() => this.confirmSending('draft')}
                                                className={styles.buttonDraft}
                                            >{`Draft`}</Button>
                                            <Button
                                                clickAction={() => this.confirmSending('sent')}
                                                icon={'arrowRight'}
                                            >{`Send`}</Button>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    />
                </DragAndDrop>
            </>
        );
    }
}
