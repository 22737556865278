import React, {useState} from 'react';
import Navigation, {ILink} from '../componentsLayout/Navigation';
import {isAdminUser, isUserOfficeCPVO} from '../utils';
import cx from 'classnames';
import styles from './NavigationCommunicationCentre.module.scss';
import {ArrowContainer, Popover} from 'react-tiny-popover';
import LoadingBar from '../components/LoadingBar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBell, faBellSlash, faBookmark, faChevronDown, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {faBookmark as faBookmarkO} from '@fortawesome/free-regular-svg-icons';
import {
    apiCommunicationCentreClientDefaultSearch,
    apiCommunicationCentreClientDeleteSearch,
    apiCommunicationCentreClientHeaderSavedSearches,
    apiCommunicationCentreInternalDefaultSearch,
    apiCommunicationCentreInternalDeleteSearch,
    apiCommunicationCentreInternalHeaderSavedSearches,
    apiCommunicationCentreInternalToggleAlertSearch,
} from '../screens/CCHeader/CCHeaderService';
import {ISearchItem} from '../types';
import {DEFAULT_CRITERIA as INTERNAL_DEFAULT_CRITERIA} from '../screens/CCInternalInbox/CCInternalInbox';
import {DEFAULT_CRITERIA as CLIENT_DEFAULT_CRITERIA} from '../screens/CCClientInbox/CCClientInbox';

let clickMySearchesDiv = false;

interface INavigationCommunicationCentreProps {
    clearFields?: any;
    openModalMessageCompose?: any;
    search?: any;
    updateCriteriaValue?: any;
}

function NavigationCommunicationCentre({
    clearFields,
    openModalMessageCompose,
    search,
    updateCriteriaValue,
}: INavigationCommunicationCentreProps) {
    const isCPVOUser = isUserOfficeCPVO();
    const isAdministratorUser = isAdminUser();

    const showMenuItemMySearches = window.location.href.includes('communicationCentreInternalInbox');

    const links: ILink[] = [];
    const linksRight: ILink[] = [];

    if (isCPVOUser) {
        links.push({to: '/communicationCentreInternalInbox', title: 'Inbox'});
        links.push({to: '/communicationCentreInternalDraft', title: 'Drafts'});
        links.push({to: '/communicationCentreInternalSent', title: 'Sent'});
        links.push({to: '/communicationCentreInternalArchive', title: 'Archive'});
        links.push({to: '/communicationCentreInternalAlerts', title: 'Alerts log (tmp)'});
        links.push({to: '/communicationCentreInternalCPVOTemplates', title: 'CPVO Templates'});
    } else {
        links.push({to: '/communicationCentreClientInbox', title: 'Inbox'});
        links.push({to: '/communicationCentreClientDraft', title: 'Drafts'});
        links.push({to: '/communicationCentreClientSent', title: 'Sent'});
        links.push({to: '/communicationCentreClientArchive', title: 'Archive'});
    }

    if (isAdministratorUser) {
        links.push({to: '/communicationCentreInternalBackOffice', title: 'Back Office'});
    }

    links.push({title: 'New message', onClick: openModalMessageCompose, style: {fontWeight: 'bold', color: '#004679'}});

    const MySearches = () => {
        const [loadingSearchList, setLoadingSearchList] = useState(false);
        const [isMySearchesPopoverOpen, setIsMySearchesPopoverOpen] = useState(false);
        const [searchList, setSearchList] = useState<ISearchItem[]>([]);

        const SEARCH_NAME_DEFAULT_PARAMETERS = 'Default parameters';

        const sortSearches = (searchesList: ISearchItem[]) => {
            searchesList = searchesList.sort((a, b) => {
                if (a.searchName < b.searchName) {
                    return -1;
                }
                if (a.searchName > b.searchName) {
                    return 1;
                }
                return 0;
            });
            let defaultIndex = searchesList.findIndex(el => el.searchName === SEARCH_NAME_DEFAULT_PARAMETERS);

            if (defaultIndex !== -1) {
                let updatedSearchList = [...searchesList];
                updatedSearchList.splice(defaultIndex, 1);
                return [searchesList[defaultIndex], ...updatedSearchList];
            }
            return searchesList;
        };

        const onClickMySearchesDiv = () => {
            clickMySearchesDiv = true;
            setIsMySearchesPopoverOpen(!isMySearchesPopoverOpen);
            setLoadingSearchList(true);
            (isCPVOUser
                ? apiCommunicationCentreInternalHeaderSavedSearches
                : apiCommunicationCentreClientHeaderSavedSearches)().then((JSONResponse: any) => {
                if (JSONResponse && JSONResponse.data) {
                    const resultSearchList = sortSearches(JSONResponse.data.searches);
                    console.log('resultSearchList', resultSearchList);
                    setSearchList(resultSearchList);
                }
                setLoadingSearchList(false);
            });
        };

        const onClickOutsideMySearchesFunction = () => {
            if (clickMySearchesDiv) {
                clickMySearchesDiv = false;
            } else {
                // @ts-ignore
                window.thisIsMySearchesPopoverOpen = !window.thisIsMySearchesPopoverOpen;
                setIsMySearchesPopoverOpen(!isMySearchesPopoverOpen);
            }
        };

        const onClickMySearchesMenu = () => setIsMySearchesPopoverOpen(!isMySearchesPopoverOpen);

        const onButtonIconDefaultSearchClick = (searchName: string) => {
            setLoadingSearchList(true);
            (isCPVOUser ? apiCommunicationCentreInternalDefaultSearch : apiCommunicationCentreClientDefaultSearch)(
                searchName
            ).then(JSONResult => {
                if (JSONResult && JSONResult.data === 'OK') {
                    (isCPVOUser
                        ? apiCommunicationCentreInternalHeaderSavedSearches
                        : apiCommunicationCentreClientHeaderSavedSearches)().then(JSONResponse => {
                        if (JSONResponse && JSONResponse.data) {
                            setSearchList(sortSearches(JSONResponse.data.searches));
                        }
                        setLoadingSearchList(false);
                    });
                }
            });
        };

        const onSearchNameClick = (searchListItem: ISearchItem) => {
            const {fields} = searchListItem || {};
            const newCriteria: any = {
                ...(isCPVOUser ? INTERNAL_DEFAULT_CRITERIA : CLIENT_DEFAULT_CRITERIA),
            };
            fields.forEach(({fieldName, fieldValue}: any) => {
                newCriteria[fieldName] = fieldValue;
            });
            clearFields && clearFields(true);
            updateCriteriaValue && updateCriteriaValue(newCriteria);
            search && search(true);
        };

        const onButtonIconToggleAlertSearch = (searchName: string) => {
            setLoadingSearchList(true);
            apiCommunicationCentreInternalToggleAlertSearch(searchName).then(JSONResult => {
                if (JSONResult && JSONResult.data === 'OK') {
                    apiCommunicationCentreInternalHeaderSavedSearches().then(JSONResponse => {
                        if (JSONResponse && JSONResponse.data) {
                            setSearchList(sortSearches(JSONResponse.data.searches));
                        }
                        setLoadingSearchList(false);
                    });
                }
            });
        };

        const onButtonIconDeleteSearchClick = (searchName: string) => {
            setLoadingSearchList(true);
            (isCPVOUser ? apiCommunicationCentreInternalDeleteSearch : apiCommunicationCentreClientDeleteSearch)(
                searchName
            ).then(JSONResult => {
                if (JSONResult && JSONResult.data === 'OK') {
                    (isCPVOUser
                        ? apiCommunicationCentreInternalHeaderSavedSearches
                        : apiCommunicationCentreClientHeaderSavedSearches)().then(JSONResponse => {
                        if (JSONResponse && JSONResponse.data) {
                            setSearchList(sortSearches(JSONResponse.data.searches));
                        }
                        setLoadingSearchList(false);
                    });
                }
            });
        };
        console.log(JSON.stringify(searchList));
        return (
            <div className={cx(styles.menuItem)} onClick={onClickMySearchesDiv}>
                <Popover
                    isOpen={isMySearchesPopoverOpen}
                    positions={'bottom'}
                    onClickOutside={onClickOutsideMySearchesFunction}
                    content={({position, childRect, popoverRect}) => (
                        <ArrowContainer
                            position={position}
                            childRect={childRect}
                            popoverRect={popoverRect}
                            arrowColor={'white'}
                            arrowSize={5}
                            arrowStyle={{
                                opacity: 0.7,
                            }}
                        >
                            <div onClick={onClickMySearchesMenu}>
                                <div
                                    className={styles.menuPopoverWrap}
                                    style={{
                                        background: 'white',
                                        color: 'black',
                                        borderRadius: '4px',
                                        zIndex: 99,
                                        position: 'relative',
                                        borderStyle: 'solid',
                                        borderWidth: '3px',
                                        borderColor: '#ccc',
                                        marginTop: 7,
                                    }}
                                >
                                    {loadingSearchList ? (
                                        <div style={{margin: '10px 20px'}}>
                                            <LoadingBar />
                                        </div>
                                    ) : (
                                        <ul className={styles.menuContainer}>
                                            {searchList.map((searchListItem: any, i) => (
                                                <li className={styles.bookmarkItem} key={searchListItem.searchName}>
                                                    <div className={styles.bookmarkItemLeft}>
                                                        <div
                                                            className={cx(
                                                                styles.iconBookmarkWrap,
                                                                styles.buttonTransparent
                                                            )}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                onButtonIconDefaultSearchClick(
                                                                    searchListItem.searchName
                                                                );
                                                            }}
                                                        >
                                                            <span className={styles.iconBookmarkDefaultState}>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        searchListItem.isDefault
                                                                            ? (faBookmark as any)
                                                                            : (faBookmarkO as any)
                                                                    }
                                                                />
                                                            </span>
                                                            <span className={styles.iconBookmarkHoverState}>
                                                                <FontAwesomeIcon icon={faBookmark as any} />
                                                            </span>
                                                        </div>
                                                        <span
                                                            className={styles.bookmarkItemSearchLink}
                                                            onClick={() => onSearchNameClick(searchListItem)}
                                                        >{`${searchListItem.searchName}`}</span>
                                                    </div>
                                                    <div className={styles.bookmarkItemRight}>
                                                        {!searchListItem.isDefault &&
                                                        searchListItem.searchName !== SEARCH_NAME_DEFAULT_PARAMETERS ? (
                                                            <button
                                                                className={styles.buttonTransparent}
                                                                title={`Delete search`}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    onButtonIconDeleteSearchClick(
                                                                        searchListItem.searchName
                                                                    );
                                                                }}
                                                                type={'button'}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashAlt as any} />
                                                            </button>
                                                        ) : null}
                                                        {isCPVOUser ? (
                                                            <div className={styles.bookmarkBellWrap}>
                                                                <button
                                                                    className={cx(
                                                                        styles.buttonTransparent,
                                                                        styles.buttonAlertWrap
                                                                    )}
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        onButtonIconToggleAlertSearch(
                                                                            searchListItem.searchName
                                                                        );
                                                                    }}
                                                                    type="button"
                                                                >
                                                                    <span className={styles.alertNormal}>
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                searchListItem.isAlertEnabled
                                                                                    ? (faBell as any)
                                                                                    : (faBellSlash as any)
                                                                            }
                                                                        />
                                                                    </span>
                                                                    <span
                                                                        className={cx(
                                                                            styles.alertHover,
                                                                            searchListItem.isAlertEnabled
                                                                                ? styles.alertHoverEnabled
                                                                                : styles.alertHoverDisabled
                                                                        )}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                searchListItem.isAlertEnabled
                                                                                    ? (faBellSlash as any)
                                                                                    : (faBell as any)
                                                                            }
                                                                        />
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </ArrowContainer>
                    )}
                >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {`My searches`}
                        <FontAwesomeIcon icon={faChevronDown as any} style={{marginLeft: 10}} />
                    </div>
                </Popover>
            </div>
        );
    };

    if (showMenuItemMySearches) {
        linksRight.push({Component: MySearches});
    }

    return <Navigation links={links} linksRight={linksRight} />;
}

export default NavigationCommunicationCentre;
