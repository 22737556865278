import {API_SERVERLESS, getFetch, postFetch} from '../../../../commonApi';

export interface MyFruitSubmissionsPageTableRowData {
    registerId: string;
    reference: string;
    specieName: string;
    sampleAccepted: any;
    examinationReference: string;
    materialSubmissionFrom: string;
    materialSubmissionTo: string;
    deliveryDate: string;
    comments: string;
    isEdited: boolean;
}

export interface MyFruitSubmissionsPageParamsData {
    data: {
        myFruitSubmissions: Array<MyFruitSubmissionsPageTableRowData>;
    };
    count: number;
}

export interface MyFruitSubmissionsPageTableCriteriaData {
    pageNumber: number;
    pageSize: number;
    refresh: boolean;
    order: string;
    reverse: boolean;
}

export interface MyFruitSubmissionsPageFilterCriteriaData extends MyFruitSubmissionsPageTableCriteriaData {
    registerId: string;
    reference: string;
    denomination: string;
    speciesId: string;
    specieName: string;
    speciesNameFilter: string;
    groupVarietal: string;
    type: string;
    status: string;

    [key: string]: string | number | boolean | undefined;
}

export function apiMyFruitSubmissionsPageSearch(
    // eslint-disable-next-line no-undef
    criteria: Partial<MyFruitSubmissionsPageFilterCriteriaData>,
    excelTranslations: {[key: string]: string} = {}
): Promise<any> {
    const requestParams = Object.keys(criteria).map(k => `${k}=${criteria[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/tlotree/getFruitSubmissions?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}

export function apiMyFruitSubmissionsPageSave(jsonData: {
    data: {myFruitSubmissions: MyFruitSubmissionsPageParamsData};
}) {
    return postFetch(`${API_SERVERLESS}/tlotree/updateSubmission`, true, jsonData).then(jsonResponse => {
        if (jsonResponse) {
            return jsonResponse;
        }
    });
}
