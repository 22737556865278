import React from 'react';
import styles from './Remark.module.scss';
import cx from 'classnames';

export default function Remark({remark, isRemarkTranslationMissing, extraStyles = {}}) {
    if (!remark) {
        return null;
    }

    return (
        <div
            className={cx(styles.responseMessageRemark, isRemarkTranslationMissing && styles.translationMissing)}
            style={{...extraStyles}}
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{__html: remark}}
        />
    );
}
