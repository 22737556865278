import React, {ReactElement} from 'react';
import Button from '../componentsFormV2/Button';
import ModalCustomVersion2 from './ModalCustomVersion2';
import {injectIntl} from 'react-intl';

interface IProps {
    action?: any;
    buttonName?: string;
    buttonVariation?: 'primary';
    close: any;
    intl: any;
    message: ReactElement | string;
    title?: string;
}

class ModalAlertVersion2 extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
    }

    close = () => {
        this.props.close && this.props.close();
    };

    action = () => {
        if (this.props.action) {
            return this.props.action();
        }
        if (this.props.close) {
            return this.props.close();
        }
    };

    render() {
        return (
            <ModalCustomVersion2
                close={this.close}
                plus={2}
                header={this.props.title || 'Alert'}
                body={this.props.message}
                footer={
                    <Button
                        variation={this.props.buttonVariation || 'danger'}
                        clickAction={() => this.action()}
                        icon={this.props.buttonVariation === 'primary' ? 'arrowRight' : 'close'}
                    >
                        {this.props.buttonName || this.props.intl.formatMessage({id: 'button.close'})}
                    </Button>
                }
                size={'sm'}
                variation={'alert'}
            />
        );
    }
}

export default injectIntl(ModalAlertVersion2);
