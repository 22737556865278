import React, {useEffect, useState} from 'react';
import {injectIntl, IntlShape} from 'react-intl';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import CustomTable from '../../../../components/CustomTable';
import AdminIcon from '../../../TLO/CommonComponents/AdminIcon/AdminIcon';
import {getUserRoles} from '../../../TLO/CommonFunctions/CommonFunctions';
import styles from './FaqPagePVRCASELAW.module.scss';
import {FaqElement, FaqsListFetchData} from './Interfaces/FaqPagePVRCASELAWInterfaces';
import {apiFaqDelete, apiFaqEdit, apiFaqListFetch, apiFaqSave} from './Services/FaqPagePVRCASELAWServices';
import ModalFaq from './Components/ModalFaq/ModalFaq';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {ModalConfirmVersion2, ModalErrorVersion2} from '../../../../commonModals';
import NavigationPVRCaseLaw from '../../../../shared/NavigationPVRCaseLaw';
import {Footer, HeaderLoading, HeaderLogoMenu, HeaderTitleAndVersion, MainWrapper} from '../../../../componentsLayout';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {History} from 'history';
import {faEdit, faPlus, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {trackPageView} from '../../../../utils';

const modalScreenTypes = {
    NEW_FAQ: 'NEW_FAQ',
    SAVE_FAQ: 'SAVE_FAQ',
    ERROR: 'ERROR',
    MODIFY_FAQ: 'MODIFY_FAQ',
    CONFIRM_DELETE: 'CONFIRM_DELETE',
};

const DEFAULT_PAGE_SIZE = 10;

interface FaqPagePVRCASELAWProps {
    intl: IntlShape;
    history: History;
}

const FaqPagePVRCASELAW = (props: FaqPagePVRCASELAWProps & RouteComponentProps) => {
    const userRoles = getUserRoles();

    const RESULT_FIELDS_DEFAULT = userRoles.indexOf('WLAW') !== -1 ? ['id', 'question'] : ['question'];

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [pvrcaselawFaqsList, setPvrcaselawFaqsList] = useState<FaqElement[]>([]);
    const [rowObjectForModal, setRowObjectForModal] = useState<FaqElement | null>(null);
    const [, setRowObjectForExtend] = useState<FaqElement | null>(null);
    const [showExpandedRow, setShowExpandedRow] = useState<{[key: number]: boolean}>({});
    const [showRowNumber, setShowRowNumber] = useState(DEFAULT_PAGE_SIZE);

    const getFaqsList = () => {
        setLoading(true);
        apiFaqListFetch()
            .then((jsonResponse: FaqsListFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.faqsList) {
                    setPvrcaselawFaqsList(jsonResponse.data.faqsList);
                }
            })
            .catch(error => {
                setErrorMessage(`FAQ Persons list fetch error: ${error.message}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        trackPageView({documentTitle: 'pvrcaselawFaq'});
        getFaqsList();
    }, []);

    const openModalFaqForAddition = (rowObject: FaqElement) => {
        setModalScreen(modalScreenTypes.NEW_FAQ);
    };

    const handlerModify = (rowId: number, rowObject: FaqElement) => {
        if (rowId > 0) {
            setRowObjectForModal(rowObject);
            setModalScreen(modalScreenTypes.MODIFY_FAQ);
        }
    };

    const handlerDelete = (rowId: number, rowObject: FaqElement) => {
        if (rowId > 0) {
            setRowObjectForModal(rowObject);
            setModalScreen(modalScreenTypes.CONFIRM_DELETE);
        }
    };

    const actions: any[] = [];
    actions.push({
        title: `Modify`,
        icon: () => {
            return (
                <a data-content={`Modify`}>
                    <FontAwesomeIcon icon={faEdit as IconDefinition} />
                </a>
            );
        },
        handler: handlerModify,
    });
    actions.push({
        title: `Delete`,
        icon: () => {
            return (
                <a data-content={`Delete`}>
                    <FontAwesomeIcon icon={faTrashAlt as IconDefinition} />
                </a>
            );
        },
        handler: handlerDelete,
    });

    const getTopCaptionElement = () =>
        userRoles.indexOf('WLAW') !== -1 ? (
            <FormFooterButton color={buttonColor.blue} clickAction={openModalFaqForAddition} icon={faPlus}>
                {`New Faq`}
                <AdminIcon />
            </FormFooterButton>
        ) : null;

    const getDataSheetFormSectionArea = (rowElement: FaqElement) => (
        <tr>
            <td colSpan={2} className={styles.textArea}>
                {rowElement?.answer}
            </td>
        </tr>
    );

    const getTableArea = () => {
        return pvrcaselawFaqsList.length === 0 ? (
            <div style={{paddingTop: 20, textAlign: 'left'}}>
                <b style={{color: 'red', textAlign: 'left'}}>
                    <span className="ng-scope">{`No Faq available`}</span>
                </b>
            </div>
        ) : (
            <div className={styles.faqList}>
                <CustomTable
                    version={2}
                    {...props}
                    useExpandedRow={true}
                    forehandColumn={true}
                    expandedRow={(rowObject: FaqElement) =>
                        showExpandedRow[pvrcaselawFaqsList.indexOf(rowObject) % showRowNumber] === true &&
                        getDataSheetFormSectionArea(rowObject)
                    }
                    showExpandedRow={showExpandedRow}
                    tableName={'pvrcaselawFaqsList'}
                    tableType={'OBJECT'}
                    tableSource={pvrcaselawFaqsList}
                    id={'id'}
                    hideExcelButton={true}
                    resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                    intl={props.intl}
                    defaultOrder={'id'}
                    reverseOrder={false}
                    count={pvrcaselawFaqsList.length}
                    actions={userRoles.indexOf('WLAW') !== -1 ? actions : undefined}
                    actionName={userRoles.indexOf('WLAW') !== -1 ? `Action` : undefined}
                    showAdminIcon={userRoles.indexOf('WLAW') !== -1}
                    rowClick={(_faqId: number, rowObject: FaqElement) => {
                        const tableRowNumber = document.querySelectorAll(
                            "div[class^='FaqPagePVRCASELAW_faqPage'] table tr[class^='CustomTable_rowData']"
                        ).length;
                        setShowRowNumber(tableRowNumber > DEFAULT_PAGE_SIZE ? tableRowNumber : DEFAULT_PAGE_SIZE);
                        setRowObjectForExtend(rowObject);
                        const dataFormSection: {[key: number]: boolean} = {};
                        dataFormSection[pvrcaselawFaqsList.indexOf(rowObject) % showRowNumber] =
                            !showExpandedRow[pvrcaselawFaqsList.indexOf(rowObject) % showRowNumber];
                        setShowExpandedRow(dataFormSection);
                    }}
                />
            </div>
        );
    };

    const closeModal = () => setModalScreen(null);

    const saveFaq = (faqId: number, faqQuestion: string, faqAnswer: string) => {
        setLoading(true);
        if (faqId > 0) {
            setModalScreen(null);
            apiFaqEdit(Object.assign({}, {id: faqId, question: faqQuestion, answer: faqAnswer}))
                .then(() => {
                    setModalScreen(null);
                    getFaqsList();
                })
                .catch(error => {
                    setLoading(false);
                    setErrorMessage(`error saving faq: ${error.message}`);
                    setModalScreen(modalScreenTypes.ERROR);
                });
        } else {
            apiFaqSave(Object.assign({}, {question: faqQuestion, answer: faqAnswer}))
                .then(() => {
                    setModalScreen(null);
                    getFaqsList();
                })
                .catch(error => {
                    setLoading(false);
                    setErrorMessage(`error saving faq: ${error.message}`);
                    setModalScreen(modalScreenTypes.ERROR);
                });
        }
    };

    const deleteSection = () => {
        setModalScreen(null);
        setLoading(true);
        if (rowObjectForModal !== null) {
            apiFaqDelete(rowObjectForModal.id)
                .then(() => {
                    setModalScreen(null);
                    getFaqsList();
                })
                .catch(error => {
                    setLoading(false);
                    setErrorMessage(`error deleting faq: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                });
        }
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeModal} />
            ) : null}
            {modalScreen === modalScreenTypes.NEW_FAQ ? (
                <ModalFaq faqElement={null} saveFaq={saveFaq} close={closeModal} />
            ) : null}
            {modalScreen === modalScreenTypes.MODIFY_FAQ && rowObjectForModal !== null ? (
                <ModalFaq faqElement={rowObjectForModal} saveFaq={saveFaq} close={closeModal} />
            ) : null}
            {modalScreen === modalScreenTypes.CONFIRM_DELETE ? (
                <ModalConfirmVersion2
                    title={`Delete FAQ`}
                    message={`Are you sure you want to delete this Faq?`}
                    buttonName={'Delete'}
                    action={deleteSection}
                    close={closeModal}
                />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`PVR Case Law`} />
            <NavigationPVRCaseLaw />
            <MainWrapper>
                <div
                    style={{
                        width: 'calc(100vw)',
                        marginBottom: 20,
                        minHeight: 600,
                        textAlign: 'center',
                        maxWidth: '100%',
                    }}
                >
                    {!loading ? (
                        <>
                            {getTopCaptionElement()}
                            <div style={{clear: 'both'}} />
                            <div className={styles.faqPage}>{getTableArea()}</div>
                        </>
                    ) : null}
                </div>
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(FaqPagePVRCASELAW));
