import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export function apiUsersMarkAsRead(flagIds) {
    const post = flagIds.length > 1;
    return (post ? postFetch : getFetch)(
        `${API_SERVERLESS}/mypvr/v3/myDocuments/markAsRead${post ? '' : `/${flagIds}`}`,
        true,
        post ? {flagIds} : null
    );
}

export function apiUsersMarkAsNotRead(flagIds) {
    const post = flagIds.length > 1;
    return (post ? postFetch : getFetch)(
        `${API_SERVERLESS}/mypvr/v3/myDocuments/markAsNotRead${post ? '' : `/${flagIds}`}`,
        true,
        post ? {flagIds} : null
    );
}

export function apiUsersMarkAsReadAll() {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/myDocuments/markAsRead`, true).then(jsonResponse => {
        if (jsonResponse) {
            return jsonResponse;
        }
    });
}

export function apiDocumentType() {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/documentType`, false);
}
