import React from 'react';
import cx from 'classnames';
import InputContainer from '~componentsForm/InputContainer';
import FirstRow from '~componentsForm/FirstRow';
import SecondRow from '~componentsForm/SecondRow';
import FirstRowLabel from '~componentsForm/FirstRowLabel';
import FirstRowInfo from '~componentsForm/FirstRowInfo';
import stylesNew from './SelectInput.module.scss';
import stylesOld from './SelectInputOld.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Label from './Label';
import Error from './Error';
import Remark from './Remark';
import CustomPopoverInput from './CustomPopoverInput';

const ALL = 'All';
export default function ({
    label,
    mandatory,
    buttonIcon,
    buttonAction,
    infoAction,
    value,
    onChange,
    list,
    disabled,
    popOverText,
    popOverWidth,
    popOverTextFirstRow,
    notAll,
    double,
    triple,
    menu,
    outsideLabel,
    outsideLabelWithHTML,
    outsideLabelWidth,
    background,
    error,
    errorVarietyFinder,
    width,
    minWidth,
    height,
    buttonPaddingLeft,
    remark,
    isRemarkTranslationMissing,
    isTranslationMissing,
    oldBackOfficeStyles,
    oldBackOfficeStylesError,
    noIcon,
    size,
    half,
    hideOptionNone,
}) {
    const options = (list || []).map(element => (
        <option
            key={element.id !== undefined ? element.id : element}
            label={element.value !== undefined ? element.value : element}
            value={element.id !== undefined ? element.id : element}
            disabled={hideOptionNone && element.id === 'none'}
            hidden={hideOptionNone && element.id === 'none'}
        >
            {element.value !== undefined ? element.value : element}
        </option>
    ));

    let styles = oldBackOfficeStyles ? stylesOld : stylesNew;

    return (
        <React.Fragment>
            {outsideLabel && (
                <div className={styles.label}>
                    <Label width={outsideLabelWidth}>{outsideLabel}</Label>
                </div>
            )}
            {outsideLabelWithHTML && (
                <div className={cx(styles.label, isTranslationMissing && styles.translationMissing)}>
                    <Label width={outsideLabelWidth}>{outsideLabelWithHTML}</Label>
                </div>
            )}
            <InputContainer {...{double, triple, width, minWidth, height, noIcon, size, half}}>
                <FirstRow>
                    <FirstRowLabel {...{mandatory, popOverTextFirstRow}}>{label}</FirstRowLabel>
                    {infoAction && <FirstRowInfo onClick={infoAction} />}
                </FirstRow>
                <SecondRow
                    buttonIcon={popOverText || buttonIcon}
                    {...{disabled, menu, background}}
                    errorBorderColor={errorVarietyFinder}
                    oldBackOfficeStyles={oldBackOfficeStyles}
                >
                    <select
                        className={cx(
                            styles.select,
                            disabled && styles.selectDisabled,
                            oldBackOfficeStylesError && styles.hasError
                        )}
                        {...{value, onChange}}
                        disabled={disabled && 'disabled'}
                        // ref={el => (elSelect = el)}
                    >
                        {(!notAll && (
                            <option label={ALL} value={ALL} key={ALL}>
                                {ALL}
                            </option>
                        )) ||
                            null}
                        {options}
                    </select>
                    <div style={{clear: 'both'}}>{}</div>
                </SecondRow>
                {popOverText && <CustomPopoverInput content={popOverText} popOverWidth={popOverWidth} />}
                {remark && (
                    <div>
                        <Remark remark={remark} isRemarkTranslationMissing={isRemarkTranslationMissing} />
                    </div>
                )}
                {buttonIcon && buttonIcon !== 'none' && (
                    <button
                        type="button"
                        className={styles.button}
                        onClick={buttonAction}
                        style={buttonPaddingLeft && {paddingLeft: buttonPaddingLeft}}
                    >
                        <FontAwesomeIcon icon={buttonIcon} color="green" />
                    </button>
                )}
                {error && (
                    <div className={styles.errorWrap}>
                        <Error wizardResponse={true}>{error}</Error>
                    </div>
                )}
                {errorVarietyFinder ? (
                    <>
                        <div style={{clear: 'both'}} />
                        <div style={{marginLeft: 3, marginTop: 3, color: 'red'}}>{errorVarietyFinder}</div>
                    </>
                ) : null}
            </InputContainer>
        </React.Fragment>
    );
}
