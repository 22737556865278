import React from 'react';
import Logo from './Logo';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import BrowserInfo from './BrowserInfo';
import {trackPageView} from '../utils';

class ErrorScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: '', status: ''};
    }

    componentDidMount() {
        trackPageView({documentTitle: 'error'});
        const parameters = loadUrlParams();
        const {error, status} = parameters;
        this.setState({error, status});
    }

    goBack = () => {
        const parameters = loadUrlParams();
        const {origin} = parameters;
        if (origin) {
            window.location.replace(origin);
        } else {
            this.props.history.goBack();
        }
    };

    goToLogin = () => {
        const parameters = loadUrlParams();
        const {origin} = parameters;
        if (origin) {
            this.props.history.replace(`/logout?home=${origin}`);
        } else {
            this.props.history.replace('/logout');
        }
    };

    isForbidden = status => status === '403';
    isUnauthorized = status => status === '401';

    render() {
        return (
            <React.Fragment>
                <div style={{textAlign: 'center', marginTop: '50px'}}>
                    {!this.isForbidden(this.state.status) && this.isUnauthorized(this.state.status) && (
                        <div style={{padding: 20}}>
                            <BrowserInfo intl={this.props.intl} notUpload={true} plus={true} />
                        </div>
                    )}
                    <Logo />
                    <div>
                        <div>
                            <div>
                                <h3>
                                    <span>{`Application Error: Sorry, an error has occurred`}</span>
                                </h3>
                                <p key={this.state.error} style={{color: '#C4343A'}}>
                                    <span className="ng-scope">
                                        <b>{this.state.error}</b>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div
                            style={{
                                width: 150,
                                float: 'none',
                                position: 'absolute',
                                left: 'calc(50% - 110px)',
                            }}
                        >
                            <FormFooterButton
                                color={buttonColor.green}
                                float={'none'}
                                width={150}
                                clickAction={
                                    this.isForbidden(this.state.status) || this.isUnauthorized(this.state.status)
                                        ? this.goToLogin
                                        : this.goBack
                                }
                            >
                                {this.isForbidden(this.state.status) || this.isUnauthorized(this.state.status)
                                    ? `Go to login`
                                    : `Go back`}
                            </FormFooterButton>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(withRouter(ErrorScreen));

function loadUrlParams() {
    const domainArray = document.location.href.split('?');
    const parameters = {};
    if (domainArray.length > 1) {
        const params = domainArray.pop();
        params.split('&').forEach(param => {
            const paramElements = param.split('=');
            if (paramElements.length === 2) {
                const key = paramElements[0];
                parameters[key] = decodeURIComponent(paramElements[1]);
            }
        });
    }
    return parameters;
}
