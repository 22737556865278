import React from 'react';

export default function ResponseModalDetailsLogicsBottomCaption() {
    let commonStyles = {
        display: 'inline',
        borderRadius: 12,
        padding: '5px 8px',
        fontSize: 12,
    };
    return (
        <div style={{padding: 15}}>
            <div style={{...commonStyles, backgroundColor: '#e8e8e8'}}>Logic belongs to Form</div>
            <div style={{...commonStyles, backgroundColor: '#faf2cc', marginLeft: 10}}>Logic belongs to Template</div>
        </div>
    );
}
