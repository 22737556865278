import {API_SERVERLESS, getFetch, postFetch} from '../../../commonApi';
import {
    CaseFileMetadata,
    CaseMetaDataFetchData,
    CaseNamesFetchData,
    CasesListFetchData,
    CountriesFetchData,
    DecidingBodiesFetchData,
    DecisionNumbersFetchData,
    KeywordsFetchData,
    LanguagesFetchData,
    PartiesFetchData,
    SearchFiles,
} from '../CommonInterfaces/CommonInterfaces';
import {SearchCasesAdvancedFiltersCriteriaData} from '../Screens/SearchCasesPageAdvancedPVRCASELAW/SearchCasesPageAdvancedPVRCASELAW';
import {getDecodedJWT} from '~utils';

export function apiCountriesFetch(): Promise<CountriesFetchData> {
    return getFetch(`${API_SERVERLESS}/caselaw/countries`, true);
}

export function apiLanguagesFetch(): Promise<LanguagesFetchData> {
    return getFetch(`${API_SERVERLESS}/caselaw/languages`, true);
}

export function apiKeywordsFetch(): Promise<KeywordsFetchData> {
    return getFetch(`${API_SERVERLESS}/caselaw/keywords`, true);
}

export function apiCaseNamesFetch(searchTerm: string, searchType: string): Promise<CaseNamesFetchData> {
    return getFetch(`${API_SERVERLESS}/caselaw/casenames?term=${searchTerm}&searchType=${searchType}`, true);
}

export function apiDecidingBodiesFetch(searchTerm: string, searchType: string): Promise<DecidingBodiesFetchData> {
    return getFetch(`${API_SERVERLESS}/caselaw/decidingbodies?term=${searchTerm}&searchType=${searchType}`, true);
}

export function apiDecisionNumbersFetch(searchTerm: string, searchType: string): Promise<DecisionNumbersFetchData> {
    return getFetch(`${API_SERVERLESS}/caselaw/decisionnumbers?term=${searchTerm}&searchType=${searchType}`, true);
}

export function apiPartiesFetch(searchTerm: string, searchType: string): Promise<PartiesFetchData> {
    return getFetch(`${API_SERVERLESS}/caselaw/parties?term=${searchTerm}&searchType=${searchType}`, true);
}

export function apiCaseDelete(caseId?: number): Promise<{message: string}> {
    return postFetch(`${API_SERVERLESS}/caselaw/deletecase`, true, {caseId: caseId});
}

export function apiDownloadFile(uuid: string): Promise<{signedUrl: string}> {
    return getFetch(`${API_SERVERLESS}/caselaw/downloadfile?uuid=${uuid}`, true);
}

function urlParamsDateValuesReplacements(params: Partial<SearchCasesAdvancedFiltersCriteriaData>) {
    let splittedList: string[] = [];
    if (params.decisionDateFrom) {
        splittedList = params.decisionDateFrom.split('/');
        params.decisionDateFrom = splittedList[2] + splittedList[1] + splittedList[0];
    }
    if (params.decisionDateTo) {
        splittedList = params.decisionDateTo.split('/');
        params.decisionDateTo = splittedList[2] + splittedList[1] + splittedList[0];
    }

    return params;
}

export function apiCasesListFetch(
    // eslint-disable-next-line no-undef
    criteria: Partial<SearchCasesAdvancedFiltersCriteriaData>,
    excelTranslations: {[key: string]: string} = {}
): Promise<CasesListFetchData> {
    const isUserLoggedIn = getDecodedJWT();
    const params = urlParamsDateValuesReplacements({...criteria});
    const paramsValueString = {...params};
    Object.keys(paramsValueString).forEach(key => (paramsValueString[key] = `${paramsValueString[key]}`));
    if (isUserLoggedIn) {
        if (criteria.excel) {
            return postFetch(`${API_SERVERLESS}/caselaw/casesExcel`, true, paramsValueString);
        } else {
            return postFetch(`${API_SERVERLESS}/caselaw/casesSearch`, true, paramsValueString);
        }
    } else {
        if (criteria.excel) {
            return postFetch(`${API_SERVERLESS}/caselaw/casesExcelPublic`, true, paramsValueString);
        } else {
            return postFetch(`${API_SERVERLESS}/caselaw/casesSearchPublic`, true, paramsValueString);
        }
    }
}

export function apiViewCaseFetch(caseId: number): Promise<CaseMetaDataFetchData> {
    return getFetch(`${API_SERVERLESS}/caselaw/viewcase?caseId=${caseId}`, true);
}

export function apiGetPresignedFileUrlsAndSizes(
    summaryFileName: string | null,
    decisionFileName: string | null,
    decisionTranslationFileName: string | null
): Promise<CaseFileMetadata> {
    return getFetch(
        `${API_SERVERLESS}/caselaw/getfileurlsandsizes?summaryFileName=${summaryFileName}&decisionFileName=${decisionFileName}&decisionTranslationFileName=${decisionTranslationFileName}`,
        true
    );
}

export function apiFilesSearch(
    searchTerm: string,
    searchFilter: string,
    from: number,
    size: number
): Promise<SearchFiles> {
    const requestData = {
        searchTerm,
        searchFilter,
        from,
        size,
    };
    return postFetch(`${API_SERVERLESS}/caselaw/filessearch`, true, requestData);
}
