import React, {useEffect, useState} from 'react';
import {injectIntl, IntlShape} from 'react-intl';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {sanitize} from '../../../../../../utils';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import {apiUploadImageFile} from './ModalDocumentsRelatedToProposalService';
import {MAX_UPLOAD_FILE_SIZE} from '../../../../../../utils/constants';
import {UploadedFileData} from '../../../../CommonComponents/ModalDocument/Interfaces/ModalDocumentInterfaces';
import styles from './ModalDocumentsRelatedToProposal.module.scss';
import {faChevronDown, faChevronRight, faPlus} from '@fortawesome/free-solid-svg-icons';
import getIcon from '../../../../../../utils/icons';
import {ModalCustomVersion2, ModalErrorVersion2} from '../../../../../../commonModals';
import CustomTable from '../../../../../../components/CustomTable';
import Label from '../../../../../../components/Label';
import InputLink from '../../../../../../components/InputLink';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import DragAndDrop from '../../../../../../components/DragAndDrop';
import AdminIcon from '../../../../CommonComponents/AdminIcon/AdminIcon';
import BrowserInfo from '../../../../../../shared/BrowserInfo';
import {History} from 'history';
import {ProposalDocument} from '../../NewSpeciesProcedurePageService';
import {Button} from '../../../../../../componentsFormV2';

const modalScreenTypes = {ERROR: 'ERROR'};

interface ModalDocumentsRelatedToProposalProps {
    intl: IntlShape;
    history: History;
    proposalDocumentsList: Array<ProposalDocument>;
    proposalNumber: number;
    userRoles: Array<string>;
    downloadDocuments: (selectedDocuments: Array<string>) => void;
    close: () => void;
}

const ModalDocumentsRelatedToProposal = (props: ModalDocumentsRelatedToProposalProps & RouteComponentProps) => {
    const RESULT_FIELDS_DOCUMENTS_TABLE_DEFAULT = ['documentTypeName', 'docmanTitle'];
    const RESULT_FIELDS_FILE_TABLE_DEFAULT = ['fileName', 'size', 'fileType'];
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState<Array<string>>([]);
    const [uploadedImageList, setUploadedImageList] = useState<Array<UploadedFileData>>([]);
    const [notesOpened, setNotesOpened] = useState(false);
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const [imageUrl, setImageUrl] = useState<Blob | null>(null);
    const [thumbnailUrl, setThumbnailUrl] = useState<Blob | null>(null);

    const onSelectedDocumentsChange = (selectedDocuments: Array<string>) => {
        setSelectedDocuments(selectedDocuments);
    };

    const downloadDocuments = () => {
        props.downloadDocuments && props.downloadDocuments(selectedDocuments);
    };

    const arrayBufferToString = (buffer: ArrayBuffer) => {
        return new TextDecoder().decode(buffer);
    };

    const createImage = (url: string) => {
        const image = new Image();
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url;

        return image;
    };

    const fromCanvasToBlob = (canvas: HTMLCanvasElement) => {
        canvas.toBlob(blob => {
            blob instanceof Blob && setThumbnailUrl(blob);
        });
    };

    const resizeImage = (image: HTMLImageElement) => {
        image.onload = () => {
            const canvas = document.createElement('canvas'),
                ctx = canvas.getContext('2d'),
                oc = document.createElement('canvas'),
                octx = oc.getContext('2d');
            canvas.width = 64;
            canvas.height = (canvas.width * image.height) / image.width;
            var cur = {
                width: Math.floor(image.width * 0.5),
                height: Math.floor(image.height * 0.5),
            };
            oc.width = cur.width;
            oc.height = cur.height;
            octx !== null && octx.drawImage(image, 0, 0, cur.width, cur.height);
            while (cur.width * 0.5 > 64) {
                cur = {
                    width: Math.floor(cur.width * 0.5),
                    height: Math.floor(cur.height * 0.5),
                };
                octx !== null && octx.drawImage(oc, 0, 0, cur.width * 2, cur.height * 2, 0, 0, cur.width, cur.height);
            }
            ctx !== null && ctx.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);
            fromCanvasToBlob(canvas);
        };
    };

    const checkFile = (file: File, _event: React.ChangeEvent<HTMLInputElement>) => {
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType && fileType.toLowerCase();
        const fileName = sanitize(file.name);
        if (fileTypeLowercase === 'jpg' || fileTypeLowercase === 'jpeg') {
            if (file.size > MAX_UPLOAD_FILE_SIZE) {
                setErrorMessage('The maximum document size for uploads is 10 MB. Please select a proper file.');
                setModalScreen(modalScreenTypes.ERROR);
            } else {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);
                let fileReaderResult = '';
                if (fileReader.result === null) {
                    fileReaderResult = '';
                } else if (fileReader.result instanceof ArrayBuffer) {
                    fileReaderResult = arrayBufferToString(fileReader.result);
                } else {
                    fileReaderResult = fileReader.result;
                }
                fileReader.onloadend = event => {
                    const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(fileReaderResult));
                    const documentList: Array<UploadedFileData> = [];
                    documentList.push({
                        index,
                        file,
                        fileName,
                        size: file.size / 1024,
                        extension: fileTypeLowercase,
                        fileType: 'IMAGE',
                        fileDate: moment().format('DD/MM/YYYY'),
                    });
                    setUploadedImageList(documentList);
                    setNotesOpened(false);
                    if (
                        event.target !== null &&
                        event.target.result !== null &&
                        typeof event.target.result === 'string'
                    ) {
                        setImage(createImage(event.target.result));
                    }
                };
            }
        } else {
            setErrorMessage('File Type is not valid. Please try again.');
            setModalScreen(modalScreenTypes.ERROR);
        }
    };

    const uploadImageFilesToFolders = (imageUrl: Blob, thumbnailUrl: Blob, eoProposalNumber: number) => {
        setLoading(true);
        apiUploadImageFile({image: imageUrl, thumbnail: thumbnailUrl, eoId: eoProposalNumber})
            .then(() => {
            })
            .catch(error => {
                setErrorMessage(`error uploading files: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (image !== null) {
            const thumbnailImg = image;
            resizeImage(thumbnailImg);
        }
    }, [image]);

    useEffect(() => {
        thumbnailUrl !== null &&
        imageUrl !== null &&
        uploadImageFilesToFolders(imageUrl, thumbnailUrl, props.proposalNumber);
    }, [thumbnailUrl]);

    const handleDrop = (files: Array<File>, event: React.ChangeEvent<HTMLInputElement>) => {
        setUploadedImageList([]);
        checkFile(files[0], event);
    };

    const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUploadedImageList([]);
        if (event.target.files !== null && event.target.files.length > 0) {
            const file = event.target.files[0];
            setImageUrl(file);
            checkFile(file, event);
        }
    };

    const toggleInformation = () => setNotesOpened(!notesOpened);

    const getUploadImageArea = () => {
        return (
            <DragAndDrop handleDrop={handleDrop}>
                <div className={styles.uploadImageContainer}>
                    <hr />
                    {uploadedImageList.length > 0 && (
                        <div className={styles.fileTableArea}>
                            <CustomTable
                                {...props}
                                notSortable={RESULT_FIELDS_FILE_TABLE_DEFAULT}
                                tableName={'uploadDocument'}
                                tableType={'OBJECT'}
                                tableSource={uploadedImageList || []}
                                dataFilter={null}
                                id={'index'}
                                setLastCursor={null}
                                resultFieldsDefault={RESULT_FIELDS_FILE_TABLE_DEFAULT}
                                intl={props.intl}
                                formatFunctions={{
                                    fileName: (fileName: string) => <div style={{maxWidth: 380}}>{fileName}</div>,
                                    size: (size: number) => (
                                        <div className={styles.sizeColumnArea}>{`${
                                            Math.floor(size * 100) / 100
                                        } KB`}</div>
                                    ),
                                }}
                                count={(uploadedImageList || []).length}
                                hideExcelButton={true}
                                noChangePageSize={true}
                                timestamp={Date.now()}
                                forehandColumn={(rowObject: UploadedFileData) => (
                                    <img src={getIcon(rowObject.extension || 'pdf')} alt={rowObject.extension} />
                                )}
                            />
                        </div>
                    )}
                    <div className={styles.uploadButtonArea}>
                        <input
                            type="file"
                            name="files[]"
                            data-ng-class="isAddDocumentDisabled() ? 'disabled': ''"
                            data-ng-disabled="isAddDocumentDisabled()"
                            id="upload"
                            style={{opacity: 0, width: 0}}
                            onChange={onFileUpload}
                            accept="image/jpg, image/jpeg"
                            disabled={undefined}
                        />
                        <label htmlFor={'upload'}>
                            <FormFooterButton color={buttonColor.blue} icon={faPlus}>
                                {`Upload Image`}
                                <AdminIcon />
                            </FormFooterButton>
                        </label>
                    </div>
                    <div style={{clear: 'both'}}>{}</div>
                    <div>
                        <InputLink
                            label={`Notes`}
                            icon={notesOpened ? faChevronDown : faChevronRight}
                            clickAction={toggleInformation}
                            width={undefined}
                            offset={undefined}
                            disabled={undefined}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        {notesOpened && (
                            <BrowserInfo
                                uploadOnlyOneFile={true}
                                documentTypes={['jpg', 'jpeg']}
                                notUpload={uploadedImageList.length >= 1}
                                intl={props.intl}
                                plus={undefined}
                            />
                        )}
                    </div>
                    <hr />
                    <div style={{clear: 'both'}}>{}</div>
                </div>
            </DragAndDrop>
        );
    };

    const getTableArea = () => {
        return props.proposalDocumentsList.length === 0 ? (
            <div style={{color: 'red', textAlign: 'center'}}>
                <b style={{textAlign: 'center'}}>
                    <span>{`No documents available`}</span>
                </b>
            </div>
        ) : (
            <React.Fragment>
                <div className={'documentsRelatedToProposalTableArea'}>
                    <CustomTable
                        pageSize={100}
                        tableName={'proposalDocumentsList'}
                        headerSelectColumnTitle={`Select / Deselect all documents`}
                        tableType={'OBJECT'}
                        tableSource={props.proposalDocumentsList}
                        id={'csDocsKey'}
                        resultFieldsDefault={RESULT_FIELDS_DOCUMENTS_TABLE_DEFAULT}
                        intl={props.intl}
                        defaultOrder={'docmanTitle'}
                        reverseOrder={false}
                        selectable={true}
                        onSelectedChange={onSelectedDocumentsChange}
                        // formatFunctions={{
                        //     docmanTitle: (docmanTitle: string, rowObject: ProposalDocument) => {
                        //         return (
                        //             <p style={{margin: 0}} className="ng-scope">
                        //                 <a
                        //                     href={rowObject.filePath + rowObject.fileName}
                        //                     rel={'noreferrer'}
                        //                     target={'_blank'}
                        //                     className="ng-binding"
                        //                 >
                        //                     {docmanTitle}
                        //                 </a>
                        //             </p>
                        //         );
                        //     },
                        // }}
                        topCaption={
                            <React.Fragment>
                                <Label width={300} double={undefined}>
                                    <div style={{color: '#5bc0de', float: 'left'}}>
                                        {`File Number: ${props.proposalDocumentsList[0].registerId}`}
                                    </div>
                                </Label>
                                <Label width={300} double={undefined}>
                                    <div style={{color: '#5bc0de', float: 'left'}}>
                                        {`/ Country of origin of main applicant: ${props.proposalDocumentsList[0].countryApplication}`}
                                    </div>
                                </Label>
                                <div style={{clear: 'both'}}>{}</div>
                            </React.Fragment>
                        }
                    />
                </div>
                {props.userRoles.indexOf('EOADM') !== -1 && getUploadImageArea()}
            </React.Fragment>
        );
    };

    const getModalContentArea = () => {
        if (
            props.userRoles.indexOf('WETLO') !== -1 ||
            props.userRoles.indexOf('TLOOF') !== -1 ||
            props.userRoles.indexOf('EOADM') !== -1
        ) {
            return getTableArea();
        } else {
            return (
                <div style={{color: 'red', textAlign: 'center'}}>
                    <b style={{textAlign: 'center'}}>
                        <span>{`You are not entitled to consult these documents.`}</span>
                    </b>
                </div>
            );
        }
    };

    const closeErrorModal = () => {
        setModalScreen(null);
        setErrorMessage(null);
    };

    return (
        <div>
            {modalScreen === modalScreenTypes.ERROR && (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            )}
            <ModalCustomVersion2
                loading={loading}
                close={() => props.close && props.close()}
                header={
                    <div className={styles.modalHeader}>
                        <h2
                            className={styles.modalTitle}
                        >{`Documents related to proposal nº ${props.proposalNumber}`}</h2>
                    </div>
                }
                body={getModalContentArea()}
                footer={
                    <>
                        <Button icon={'close'} clickAction={props.close} variation={'danger'}>
                            {`Close`}
                        </Button>
                        <Button icon={'arrowRight'} clickAction={downloadDocuments}
                                disabled={!selectedDocuments || selectedDocuments.length === 0}>
                            {`Download selected documents`}
                        </Button>
                    </>
                }
            />
        </div>
    );
};

export default injectIntl(withRouter(ModalDocumentsRelatedToProposal));
