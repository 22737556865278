import {API_SERVERLESS, getFetch, postFetch, postForm} from '~commonApi';
import {computeGetUploadPreSignedURLPromises, computePostFilesToPreSignedURLPromises} from '../../../utils/requests';

export function apiCommonCheckApplicationNumbers(JSONData: any) {
    return postFetch(`${API_SERVERLESS}/common/v3/checkApplicationNumbers`, true, JSONData);
}

export function apiCertifiedCopiesCreateFolderAndUploadFilesAndSendRequest(JSONRequest: any, documentList: any[]) {
    let fileFolder = '';

    const signRequest = () => {
        return postFetch(`${API_SERVERLESS}/common/v3/signRequests`, true, {
            ...JSONRequest,
            folder: fileFolder,
        }).then((JSONResponse: any) => ({...JSONResponse, folder: fileFolder}));
    };

    if (!documentList.length) {
        return getFetch(`${API_SERVERLESS}/common/v3/documentUploadCreateFolder`, true).then(({folder}: any) => {
            fileFolder = folder;
            return signRequest();
        });
    } else {
        return getFetch(`${API_SERVERLESS}/common/v3/documentUploadCreateFolder`, true)
            .then(({folder}: any) => {
                fileFolder = folder;
                const uploadArray: any = [];
                return Promise.all(computeGetUploadPreSignedURLPromises({documentList})).then(
                    getUploadPreSignedURLResponses => {
                        return Promise.all(
                            computePostFilesToPreSignedURLPromises({
                                getUploadPreSignedURLResponses,
                                documentList,
                            })
                        ).then(() => {
                            documentList.forEach((document, index) => {
                                const formData = new FormData();
                                formData.append(`folder`, folder);
                                formData.append('files[]', getUploadPreSignedURLResponses[index].identifier);
                                formData.append('filename', encodeURIComponent(document.filename));
                                formData.append(`documentType`, document.documentType);
                                uploadArray.push(
                                    postForm(`${API_SERVERLESS}/common/v3/documentUploadFile`, true, formData)
                                );
                            });
                            return Promise.all(uploadArray);
                        });
                    }
                );
            })
            .then(signRequest);
    }
}
