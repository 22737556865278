import React from 'react';
import {injectIntl} from 'react-intl';
import ClientMessageFieldsAndAttachments from '../CommunicationCentreClient/modals/ClientMessageFieldsAndAttachments';
import {
    apiCommunicationCenterModalClientReadDownloadDocument,
    apiCommunicationCentreModalClientReadMessageRead,
} from './CCModalClientReadService';
import {getDecodedJWT} from '../../utils';
import {ICommunicationAttachmentResponse, ICommunicationMessageClientRead} from '../../types';
import {SERVER_INDEX_CONFIRMATION_OF_RECEIPT} from '../../utils/constants';
import {getPreSignedURLFetchRequest} from '../../utils/requests';
import {saveAs} from 'file-saver';
import {ModalApplicationNumbers, ModalConfirmVersion2, ModalCustomVersion3} from '../../commonModals';
import {Button} from '../../componentsFormV2';
import styles from './CCModalClientRead.module.scss';

interface IProps {
    close: () => any;
    communicationId: number;
    folder?: string | null;
    intl: any;
    onButtonReplyClick?: () => any;
    isPreviewMode?: boolean;
    modalPreviewURL?: string;
    setModalPreviewURL?: any;
    setModalPreviewMessage?: (message: ICommunicationMessageClientRead) => any;
}

interface IState {
    isModalConfirmNotReplyingToColleagueOpen: boolean;
    loading: boolean;
    files: ICommunicationAttachmentResponse[];
    message: ICommunicationMessageClientRead | null;
    modalApplicationNumbers: string[];
    timestamp: number;
}

class CCModalClientRead extends React.Component<IProps, IState> {
    decodedJWT = getDecodedJWT();
    originalFiles: ICommunicationAttachmentResponse[] = [];

    constructor(props: IProps) {
        super(props);
        this.state = {
            isModalConfirmNotReplyingToColleagueOpen: false,
            loading: false,
            files: [],
            message: null,
            modalApplicationNumbers: [],
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        this.setState(
            {
                loading: true,
            },
            () => {
                apiCommunicationCentreModalClientReadMessageRead(this.props.communicationId).then(
                    (JSONResponse: {
                        files: ICommunicationAttachmentResponse[];
                        message: ICommunicationMessageClientRead;
                    }) => {
                        const {files, message} = JSONResponse || {};
                        let filesFiltered = [...(files || [])];
                        if (files.length) {
                            const fileIndexConfirmationOfReceipt = files.findIndex(
                                el => el.filename === 'ConfirmationOfReceipt.pdf'
                            );
                            if (
                                fileIndexConfirmationOfReceipt !== -1 &&
                                message.thirdPartyId !== parseInt(this.decodedJWT ? this.decodedJWT.thirdPartyId : '')
                            ) {
                                filesFiltered.splice(fileIndexConfirmationOfReceipt, 1);
                            }
                            if (files[files.length - 1].serverIndex === SERVER_INDEX_CONFIRMATION_OF_RECEIPT) {
                                this.originalFiles = [files[files.length - 1], ...files.slice(0, files.length - 1)];
                            } else {
                                this.originalFiles = [...files];
                            }
                        }
                        this.setState({
                            loading: false,
                            files: filesFiltered,
                            message: message,
                        });
                        this.props.setModalPreviewMessage && this.props.setModalPreviewMessage(message);
                    }
                );
            }
        );
    }

    openModalApplicationNumbers = (applicationNumbers: string[]) =>
        this.setState({modalApplicationNumbers: applicationNumbers});

    closeModalApplicationNumbers = () => this.setState({modalApplicationNumbers: []});

    onCloseModalConfirmNotReplyingColleague = () => this.setState({isModalConfirmNotReplyingToColleagueOpen: false});

    onButtonActionOfModalConfirmNotReplyingColleagueClick = () =>
        this.props.onButtonReplyClick && this.props.onButtonReplyClick();

    onButtonReplyClick = () => {
        const {thirdPartyId: messageThirdPartyId} = this.state.message || {};
        const {thirdPartyId: loggedInUserThirdPartyId} = this.decodedJWT || {};
        if (this.props.folder !== 'sent' && parseInt(loggedInUserThirdPartyId || '') === messageThirdPartyId) {
            this.setState({isModalConfirmNotReplyingToColleagueOpen: true});
        } else {
            this.props.onButtonReplyClick && this.props.onButtonReplyClick();
        }
    };

    onButtonSummaryClick = () => {
        const messageDoesNotHaveConfirmationOfReceipt =
            this.originalFiles.filter(el => el.serverIndex === SERVER_INDEX_CONFIRMATION_OF_RECEIPT).length === 0;
        if (messageDoesNotHaveConfirmationOfReceipt) {
            return alert('Message does not have confirmation of receipt in this environment');
        }
        const JSONRequest = {
            communicationId: this.props.communicationId,
            serverIndex: SERVER_INDEX_CONFIRMATION_OF_RECEIPT,
        };
        apiCommunicationCenterModalClientReadDownloadDocument(JSONRequest).then(
            (response: {signedUrl: string; filename: string}) => {
                if (response && response.signedUrl && response.filename) {
                    const {signedUrl, filename} = response;
                    getPreSignedURLFetchRequest(signedUrl)
                        .then(response => response.blob())
                        .then(responseBlob => {
                            saveAs(responseBlob, filename);
                        });
                }
            }
        );
    };

    render() {
        const attachments = this.props.isPreviewMode ? this.originalFiles : this.state.files;

        return (
            <>
                {this.state.isModalConfirmNotReplyingToColleagueOpen ? (
                    <ModalConfirmVersion2
                        title={`Replying CPVO`}
                        message={`You are not replying to your colleague, you are replying to CPVO. Are you sure you want to continue ?`}
                        buttonName={'Continue'}
                        action={this.onButtonActionOfModalConfirmNotReplyingColleagueClick}
                        close={this.onCloseModalConfirmNotReplyingColleague}
                    />
                ) : null}
                {this.state.modalApplicationNumbers.length ? (
                    <ModalApplicationNumbers
                        applicationNumbers={this.state.modalApplicationNumbers}
                        close={this.closeModalApplicationNumbers}
                    />
                ) : null}
                <ModalCustomVersion3
                    isPreviewMode={this.props.isPreviewMode || false}
                    close={this.props.close}
                    header={`Reading message...`}
                    body={
                        <div style={{minHeight: 300}}>
                            <ClientMessageFieldsAndAttachments
                                attachments={attachments}
                                communicationId={this.props.communicationId}
                                intl={this.props.intl}
                                message={this.state.message}
                                openModalApplicationNumbers={this.openModalApplicationNumbers}
                                isPreviewMode={this.props.isPreviewMode}
                                setModalPreviewURL={this.props.setModalPreviewURL}
                                modalPreviewURL={this.props.modalPreviewURL}
                            />
                        </div>
                    }
                    footer={
                        <div className={styles.modalFooterActions}>
                            <div className={styles.buttonSummary}>
                                <Button
                                    variation={'secondary'}
                                    clickAction={this.onButtonSummaryClick}
                                    disabled={this.state.loading}
                                >{`Summary`}</Button>
                            </div>
                            <div>
                                <Button
                                    variation={'danger'}
                                    clickAction={this.props.close}
                                    icon={'close'}
                                    className={styles.buttonClose}
                                >{`Close`}</Button>
                                {this.props.onButtonReplyClick ? (
                                    <Button
                                        clickAction={this.onButtonReplyClick}
                                        disabled={this.state.loading}
                                        icon={'arrowRight'}
                                    >{`Reply`}</Button>
                                ) : null}
                            </div>
                        </div>
                    }
                />
            </>
        );
    }
}

export default injectIntl(CCModalClientRead);
