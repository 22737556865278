import {API_SERVERLESS, getFetch, postFetch} from '../../../../commonApi';
import {
    ConsultModifySpeciesDataFilterCriteriaData,
    ConsultModifySpeciesDataParamsData,
    EntrustmentFetchData,
    EntrustmentTypesNamesFetchData,
    GenusesNamesFetchData,
} from './Interfaces/ConsultModifySpeciesDataInterfaces';
import {EntrustmentForSave} from './Components/ModalEntrustment/Interfaces/ModalEntrustmentInterfaces';

function concatIdsWithName(idsText: string, name: string) {
    if (idsText.length > 0 && name.length > 0) {
        return idsText + ',' + name;
    } else if (idsText.length > 0 && name.length === 0) {
        return idsText;
    } else if (idsText.length === 0 && name.length > 0) {
        return name;
    }
}

export function apiConsultModifySpeciesSearch(
    // eslint-disable-next-line no-undef
    criteria: Partial<typeof ConsultModifySpeciesDataFilterCriteriaData>,
    excelTranslations: {[key: string]: string} = {}
): Promise<typeof ConsultModifySpeciesDataParamsData> {
    const params = {...criteria};
    if (params.speciesIds !== undefined && params.speciesName !== undefined) {
        params.speciesName = concatIdsWithName(params.speciesIds, params.speciesName);
    }
    if (params.genusIds !== undefined && params.genus !== undefined) {
        params.genus = concatIdsWithName(params.genusIds, params.genus);
    }
    if (params.entrustmentTypeIds !== undefined && params.entrustmentType !== undefined) {
        params.entrustmentType = concatIdsWithName(params.entrustmentTypeIds, params.entrustmentType);
    }
    if (params.speciesNameFilter === 'equals') params.speciesNameFilter = 'exact';
    if (params.genusFilter === 'equals') params.genusFilter = 'exact';
    if (params.entrustmentTypeFilter === 'equals') params.entrustmentTypeFilter = 'exact';
    const requestParams = Object.keys(params).map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/tlo/applications/species?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}

export function apiGenusesAutocompletionFilterFetch(
    searchTerm: string,
    searchType: string
): Promise<typeof GenusesNamesFetchData> {
    return getFetch(
        `${API_SERVERLESS}/tlo/applications/commons/autocomplete/genus?term=${searchTerm}&searchType=${searchType}`,
        true
    );
}

export function apiEntrustmentTypesAutocompletionFilterFetch(
    searchTerm: string,
    searchType: string
): Promise<typeof EntrustmentTypesNamesFetchData> {
    return getFetch(
        `${API_SERVERLESS}/tlo/applications/commons/autocomplete/entrustmenttype?term=${searchTerm}&searchType=${searchType}`,
        true
    );
}

export function apiEntrustmentFetch(examId: number, modifId: number): Promise<typeof EntrustmentFetchData> {
    const params: {[key: string]: number} = Object.assign({}, {examId, modifId});
    const requestParams = Object.keys(params).map(k => `${k}=${params[k]}`);
    return getFetch(`${API_SERVERLESS}/tlo/applications/entrustment?${requestParams.join('&')}`, true);
}

export function apiDraftEntrustmentFetch(modifId: number) {
    return getFetch(`${API_SERVERLESS}/tlo/applications/draftentrustment?modifId=${modifId}`, true);
}

export function apiEntrustmentSubmitToCPVO(jsonData: EntrustmentForSave) {
    return postFetch(`${API_SERVERLESS}/tlo/applications/entrustment`, true, jsonData).then(jsonResponse => {
        if (jsonResponse) {
            return jsonResponse;
        }
    });
}

export function apiEntrustmentMassSubmitToCPVO(jsonData: Array<EntrustmentForSave>) {
    return postFetch(`${API_SERVERLESS}/tlo/applications/entrustment`, true, jsonData).then(jsonResponse => {
        if (jsonResponse) {
            return jsonResponse;
        }
    });
}
