import React from 'react';
import cx from 'classnames';
import styles from './DashboardCircles.module.scss';
import dateFormat from 'date-format';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function DashboardCircles(props) {
    const spinner = <FontAwesomeIcon icon={faSpinner} spin />;
    const documentsNotOpenedMinorThan7days =
        props.documentsNotOpenedMinorThan7days !== null ? props.documentsNotOpenedMinorThan7days : spinner;
    const documentsNotOpenedMoreThan7days =
        props.documentsNotOpenedMoreThan7days !== null ? props.documentsNotOpenedMoreThan7days : spinner;
    const overdueFees = props.overdueFees !== null ? props.overdueFees : spinner;
    const pendingFees = props.pendingFees !== null ? props.pendingFees : spinner;
    return (
        <div className={styles.productsView}>
            <div className={styles.todayProducts}>
                <div className={styles.products}>
                    {overdueFees > 0 ? (
                        <>
                            <div className={cx(styles.bigCircle)} onClick={() => goToOverdueFees(props)}>
                                <div className={styles.bigCircleAmount}>{overdueFees}</div>
                                <div className={styles.bigCircleTitle}>{`Overdue fees`}</div>
                            </div>
                            <div className={styles.smallCirclesWrap}>
                                <div
                                    className={cx(styles.smallCircle, styles.productYellow)}
                                    onClick={() => goToPendingFees(props)}
                                >
                                    <div className={styles.smallCircleAmount}>{pendingFees}</div>
                                    <div className={styles.smallCircleTitle}>{`Pending fees`}</div>
                                </div>
                                <div
                                    className={cx(styles.smallCircle, styles.productGreen)}
                                    onClick={() => goToDocumentsNotReadInTheLastSevenDays(props)}
                                >
                                    <div className={styles.smallCircleAmount}>{documentsNotOpenedMinorThan7days}</div>
                                    <div
                                        className={styles.smallCircleTitle}
                                    >{`New and notified documents not open in the last 7 days`}</div>
                                </div>
                                <div
                                    className={cx(styles.smallCircle, styles.productGreen)}
                                    onClick={() => goToDocumentsNotReadMoreThanSevenDays(props)}
                                >
                                    <div className={styles.smallCircleAmount}>{documentsNotOpenedMoreThan7days}</div>
                                    <div
                                        className={styles.smallCircleTitle}
                                    >{`Documents deemed to be open and not read.`}</div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className={cx(styles.product, styles.product1, styles.productGreen)}
                                onClick={() => goToDocumentsNotReadInTheLastSevenDays(props)}
                            >
                                <div className="position-tooltip has-category-icon-small">
                                    <div className={styles.amount}>{documentsNotOpenedMinorThan7days}</div>
                                    <div
                                        className={styles.productNameDocuments}
                                        style={{fontWeight: 800}}
                                    >{`New and notified`}</div>
                                    <div
                                        className={styles.productNameDocuments}
                                        style={{fontWeight: 800}}
                                    >{`documents not open`}</div>
                                    <div
                                        className={styles.productNameDocuments}
                                        style={{fontWeight: 800}}
                                    >{`in the last 7 days `}</div>
                                </div>
                            </div>
                            <div
                                className={cx(styles.product, styles.product2, styles.productGreen)}
                                onClick={() => goToDocumentsNotReadMoreThanSevenDays(props)}
                            >
                                <div className="position-tooltip has-category-icon-small">
                                    <div className={styles.amount}>{documentsNotOpenedMoreThan7days}</div>
                                    <div className={styles.productNameDocuments}>{`Documents deemed`}</div>
                                    <div className={styles.productNameDocuments}>{`to be open`}</div>
                                    <div className={styles.productNameDocuments}>{`and not read`}</div>
                                </div>
                            </div>
                            <div
                                className={cx(styles.product, styles.product3, styles.productYellow)}
                                onClick={() => goToPendingFees(props)}
                            >
                                <div className="position-tooltip has-category-icon-small">
                                    <div className={styles.amount}>{pendingFees}</div>
                                    <div className={styles.productNameFees}>{`Pending fees`}</div>
                                </div>
                            </div>
                            <div
                                className={cx(styles.product, styles.product4, styles.productRed)}
                                onClick={() => goToOverdueFees(props)}
                            >
                                <div className="position-tooltip has-category-icon-small">
                                    <div className={styles.amount}>{overdueFees}</div>
                                    <div className={styles.productNameFees}>{`Overdue fees`}</div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

function goToDocumentsNotReadInTheLastSevenDays(props) {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    props.history.push(
        `/myDocuments?documentRead=2&notificationDateFrom=${dateFormat('yyyy-MM-dd', date)}&addressedTo=1&pageSize=100`
    );
}

function goToDocumentsNotReadMoreThanSevenDays(props) {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    props.history.push(
        `/myDocuments?documentRead=2&notificationDateTo=${dateFormat('yyyy-MM-dd', date)}&addressedTo=1&pageSize=100`
    );
}

export function goToPendingFees(props) {
    LOG`goToPendingFees`;
    props.history.push(`/myFees?noteStatus=20&dueDateFrom=${dateFormat('yyyy-MM-dd', new Date())}`);
}

export function goToOverdueFees(props) {
    LOG`goToOverdueFees`;
    const dateObj = new Date();
    dateObj.setDate(dateObj.getDate() - 1);
    props.history.push(`/myFees?noteStatus=20&dueDateTo=${dateFormat('yyyy-MM-dd', dateObj)}`);
}
