import {API_SERVERLESS, postFetch} from '~commonApi';

export function apiBackOfficeQuestionLogicDelete(JSONRequest: {
    questionId: number;
    logicId: number;
    iteration: number;
    formId: number;
}): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/questionLogicDelete`, true, JSONRequest);
}

export function apiBackOfficeQuestionLogicTemplateDelete(JSONRequest: {
    questionId: number;
    logicId: number;
    iteration: number;
    templateId: number;
}): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/questionLogicTemplateDelete`, true, JSONRequest);
}

export function apiBackOfficeFormQuestionLogicCreateOrUpdate(jsonBody: {
    questionId: number;
    logicId: number;
    logicParam: string | null;
    questionIdDest: number | null;
    responseIdDest: number | null;
    applyToRemark: boolean;
    messageId: number | null;
    root: any;
    iteration: number;
    formId: number;
}): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formQuestionLogicCreateOrUpdate`, true, jsonBody);
}

export function apiBackOfficeFormQuestionLogicTemplateCreateOrUpdate(jsonBody: {
    questionId: number;
    logicId: number;
    logicParam: number | null;
    questionIdDest: number | null;
    responseIdDest: number | null;
    applyToRemark: boolean;
    messageId: number | null;
    root: any;
    iteration: number;
    templateId: number;
}): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formQuestionLogicTemplateCreateOrUpdate`, true, jsonBody);
}
