import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faList, faUndo} from '@fortawesome/free-solid-svg-icons';
import {ICommunicationMessageClient} from '../../types';

function renderButton(
    popupContent: string,
    handler: (id: any, rowObject: ICommunicationMessageClient) => void,
    icon: any
) {
    return {
        title: popupContent,
        icon: () => {
            return (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon} />
                </a>
            );
        },
        handler,
    };
}

export default function CCClientArchiveActionButtons(
    onTableIconUnArchiveClick: (communicationId: number) => void,
    onTableIconHistoryClick: (rowObject: ICommunicationMessageClient) => void
) {
    const buttons = [];
    buttons.push(renderButton('Un-archive', communicationId => onTableIconUnArchiveClick(communicationId), faUndo));
    buttons.push(renderButton('History', (_, rowObject) => onTableIconHistoryClick(rowObject), faList));
    return buttons;
}
