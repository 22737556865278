import React from 'react';
import {saveAs} from 'file-saver';
import styles from './BackOfficeLabels.module.scss';
import {injectIntl} from 'react-intl';
import CustomTable from '~components/CustomTable';
import BackOfficeLabelsBottomCaption from './BackOfficeLabelsBottomCaption';
import BackOfficeLabelsTopCaption from './BackOfficeLabelsTopCaption';
import BackOfficeLabelsActionButtons from './BackOfficeLabelsActionButtons';
import TextInput from '~components/TextInput';
import Checkbox from '~components/Checkbox';
import SelectInput from '~components/SelectInput';
import {faInfo, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {apiLabelsSearch} from '~commonApi/labels';
import {apiLabelUsedIn} from './BackOfficeLabelsService';
import ModalMergeLabelsVersion2 from './modals/ModalMergeLabelsVersion2';
import DEFAULT_CRITERIA from './data/DEFAULT_CRITERIA.json';
import DEFAULT_RESULT_FIELDS from './data/DEFAULT_RESULT_FIELDS.json';
import OPTIONS_OFFICE from './data/OPTIONS_OFFICE.json';
import OPTIONS_LANGUAGE from './data/OPTIONS_LANGUAGE.json';
import OPTIONS_TYPE from './data/OPTIONS_TYPE.json';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import StatusField from '~shared/StatusField';
import {getPreSignedURLFetchRequest} from '../../utils/requests';
import {getExcelSignedURLFetchRequest} from '../../commonApi/download';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationBackOffice from '../../shared/NavigationBackOffice';
import {Button} from '../../componentsFormV2';
import ModalUsedInVersion2, {rowType} from '../../commonModals/ModalUsedInVersion2';
import {ModalConfirmVersion2, ModalLabelPreviewVersion2, ModalLabelVersion2} from '../../commonModals';
import {trackPageView} from '../../utils';

const DEFAULT_COUNT = 0;

class BackOfficeLabels extends React.Component {
    initialCriteria = null;
    labelHash = {};
    clearSelectionFunction = null;

    constructor(props) {
        super(props);
        this.state = {
            labels: null,
            selectedLabels: {},
            criteria: DEFAULT_CRITERIA,
            criteriaCount: 0,
            count: DEFAULT_COUNT,
            modalLabelUsedIn: null,
            modalConfirm: null,
            modalLabel: null,
            modalMergeLabels: false,
            modalPreview: null,
            mergeEnabled: false,
            refreshModalMergeLabels: null,
            computingUsage: false,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'labels'});
        self.setStateFunction = this.setStateFunction;
        self.defaultState = this.state;
        //
        this.loadUrlParams();
    }

    setStateFunction = state => this.setState(state);

    loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const criteria = Object.assign({}, this.initialCriteria);
            const params = domainArray.pop();
            params.split('&').forEach(param => {
                const paramElements = param.split('=');
                if (paramElements.length === 2) {
                    const key = paramElements[0];
                    criteria[key] = decodeURIComponent(paramElements[1]);
                }
            });
            if (Object.keys(criteria).length > 0) {
                const criteriaCount = this.countCriteria(criteria);
                this.setState({criteria, criteriaCount}, this.search);
            }
        }
    };

    showModalPreview = valueHTML => this.setState({modalPreview: valueHTML});

    closeModalPreview = () => {
        this.setState({modalPreview: null});
    };

    showModalLabelUsedIn = labelId => {
        this.setState({modalLabelUsedIn: labelId});
    };

    closeModal = () => {
        this.setState({modalLabelUsedIn: null});
    };

    showModalLabel = (labelId, typeId) => {
        this.setState({modalLabel: {labelId, typeId}});
    };

    closeModalLabel = reload => {
        this.setState(Object.assign({}, {modalLabel: null}, reload && {refreshModalMergeLabels: Date.now()}), () => {
            if (reload === true && !this.state.modalMergeLabels) {
                this.clearSelectionFunction && this.clearSelectionFunction();
                this.search(true);
            }
        });
    };

    printExcel = labelId => {
        this.setState({loading: true}, () => {
            apiLabelUsedIn(labelId, true)
                .then(jsonResponse => {
                    if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                        getExcelSignedURLFetchRequest(jsonResponse.data.token).then(response => {
                            if (response && response.signedUrl) {
                                const {signedUrl} = response;
                                getPreSignedURLFetchRequest(signedUrl)
                                    .then(response => response.blob())
                                    .then(responseBlob => {
                                        saveAs(responseBlob, `LabelUsedIn.xlsx`);
                                    });
                            }
                        });
                    }
                })
                .catch(error => LOG`error downloading: ${error}`)
                .then(() => this.setState({loading: false}));
        });
    };

    search = refresh => {
        this.setState(
            prev =>
                Object.assign(
                    {},
                    {
                        loading: true,
                        criteria: Object.assign(
                            {},
                            prev.criteria,
                            {refresh: !!refresh},
                            !refresh && !prev.criteria.pageNumber && {pageNumber: 1}
                        ),
                    },
                    !refresh && {selectedLabels: {}}
                ),
            () => {
                const {criteria} = this.state;
                const parsedCriteria = Object.assign({}, criteria);
                this.buildParams(parsedCriteria);
                apiLabelsSearch(parsedCriteria, DEFAULT_CRITERIA)
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.labels) {
                            this.labelHash = {};
                            (jsonResponse.data.labels || []).forEach(
                                label => (this.labelHash[`${label.labelId}-${label.languageId}`] = label)
                            );
                            this.setState(
                                prev => {
                                    const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                    return Object.assign(
                                        {usedInChannel: Date.now()},
                                        !refresh && {criteria},
                                        !refresh && {selectedLabels: {}},
                                        {
                                            labels: jsonResponse.data.labels,
                                            timestamp: Date.now(),
                                        },
                                        jsonResponse.data.COUNT && {count: jsonResponse.data.COUNT}
                                    );
                                },
                                () => {
                                    !refresh && this.clearSelectionFunction && this.clearSelectionFunction();
                                }
                            );
                        }
                    })
                    .catch(error => {
                        ERROR`Register search list error: ${error.message}`;
                    })
                    .then(() => this.setState({loading: false}));
            }
        );
    };

    startComputeUsedInSearch = () => this.state.usedInChannel && this.computeUsedInSearch(this.state.usedInChannel);

    computeUsedInSearch = usedInChannel => {
        if (this.state.usedInChannel === usedInChannel) {
            const remainingLabelsToCalculate = (this.state.labels || []).filter(
                i => i.usedIn === undefined && i.typeId !== 10 && i.typeId !== 11
            );
            if (remainingLabelsToCalculate.length > 0) {
                this.setState({computingUsage: true}, () => {
                    const label = remainingLabelsToCalculate[0];
                    apiLabelUsedIn(label.labelId).then(response => {
                        (this.state.labels || []).forEach(labelItem => {
                            if (labelItem.labelId === label.labelId && this.state.usedInChannel === usedInChannel) {
                                let inProcess = 0;
                                let active = 0;
                                let terminated = 0;
                                if (response && response.data && Array.isArray(response.data)) {
                                    response.data.forEach(form => {
                                        switch (form.status) {
                                            case 1:
                                                inProcess++;
                                                break;
                                            case 2:
                                                active++;
                                                break;
                                            case 3:
                                                terminated++;
                                                break;
                                        }
                                    });
                                }
                                labelItem.usedIn = {1: inProcess, 2: active, 3: terminated};
                            }
                        });
                        this.setState({timestamp: Date.now()}, () => this.computeUsedInSearch(usedInChannel));
                    });
                });
            } else {
                this.setState({computingUsage: false});
            }
        }
    };

    buildParams = criteria => {
        const paramArray = Object.keys(criteria)
            .filter(i => criteria[i] !== DEFAULT_CRITERIA[i])
            .map(key => `${key}=${criteria[key]}`);
        this.props.history.replace(`/labels${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`);
    };

    countCriteria = () => {
        return 0;
    };

    getLanguageById = languageId => {
        const selectedLanguage = OPTIONS_LANGUAGE.filter(l => l.id === languageId);
        if (selectedLanguage.length > 0) {
            return selectedLanguage[0].value;
        } else {
            return languageId;
        }
    };

    getLabelTypeById = typeId => {
        const selectedType = OPTIONS_TYPE.filter(c => parseInt(c.id) === typeId);
        if (selectedType.length > 0) {
            return selectedType[0].value;
        } else {
            return typeId;
        }
    };

    getOfficeNameById = officeId => {
        const selectedOffice = OPTIONS_OFFICE.filter(o => parseInt(o.id) === officeId);
        if (selectedOffice.length > 0) {
            return selectedOffice[0].value;
        } else {
            return officeId;
        }
    };

    getRowClass = message => {
        return message.isUndefined === 1 && styles.danger;
    };

    resetCriteria = () => {
        // const {pageSize, order, reverse} = this.state.criteria;
        // const defaultCriteria = Object.assign({}, DEFAULT_CRITERIA, {pageSize, order, reverse});
        this.setState(prev => ({
            selectedLabels: {},
            criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
            criteriaCount: 0,
            labels: null,
            timestamp: Date.now(),
        }));
    };

    updateCriteriaValue = (criteriaValue, callback, refresh) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                const criteriaCount = this.countCriteria(criteria);
                pageNumberChanged = criteriaValue.pageNumber && prev.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse && prev.reverse !== criteriaValue.reverse;
                return {criteria, criteriaCount};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    setClearSelectionFunction = clearSelectionFunction => (this.clearSelectionFunction = clearSelectionFunction);

    closeModalConfirm = () => this.setState({modalConfirm: null});

    // openModalConfirm = labelId => this.setState({modalConfirm: labelId});

    showModalMergeLabels = () => this.state.mergeEnabled && this.setState({modalMergeLabels: true});

    closeModalMergeLabels = ({reload} = {}) =>
        this.setState({modalMergeLabels: false}, () => {
            if (reload === true) {
                this.clearSelectionFunction && this.clearSelectionFunction();
                this.search(true);
            }
        });

    onSelectedOfficeChange = event => {
        const officeId = event.target.value;
        this.updateCriteriaValue({officeId});
    };

    onSelectedLanguageChange = event => {
        const languageId = event.target.value;
        this.updateCriteriaValue({languageId});
    };

    onSelectedTypeIdChange = event => {
        const typeId = event.target.value;
        this.updateCriteriaValue({typeId});
    };

    onContainsInputChange = event => {
        const contains = event.target.value;
        this.updateCriteriaValue({contains});
    };

    onContainsFilterChange = event => {
        const containsFilter = event.target.value;
        this.updateCriteriaValue({containsFilter});
    };

    onLabelIdInputChange = event => {
        const labelIds = event.target.value;
        if (/^[0-9]*$/.test(labelIds)) {
            this.updateCriteriaValue({labelIds});
        }
    };

    onDuplicatedCheckboxChange = () => {
        this.updateCriteriaValue({duplicated: !this.state.criteria.duplicated});
    };

    onEmptyCheckboxChange = () => {
        this.updateCriteriaValue({empty: !this.state.criteria.empty});
    };

    onNotUsedCheckboxChange = () => {
        this.updateCriteriaValue({notUsed: !this.state.criteria.notUsed});
    };

    onSelectedChange = labelKeys => {
        const selectedLabels = {};
        let languageHash = {};
        let typeHash = {};
        labelKeys.forEach(labelKey => {
            selectedLabels[labelKey] = this.labelHash[labelKey] || this.state.selectedLabels[labelKey];
            const label = selectedLabels[labelKey];
            languageHash[label.languageId] = true;
            typeHash[label.typeId] = true;
        });
        const mergeEnabled =
            Object.keys(languageHash).length === 1 &&
            Object.keys(typeHash).length === 1 &&
            Object.keys(selectedLabels).length > 1;
        this.setState({selectedLabels, mergeEnabled});
    };

    render() {
        const actions = BackOfficeLabelsActionButtons(
            this.props,
            this.showModalLabel,
            this.showModalLabelUsedIn,
            this.showModalPreview,
            this.printExcel
        );
        const selectedLabelIds = Object.values(this.state.selectedLabels).map(label => label.labelId);
        return (
            <>
                {this.state.modalConfirm ? (
                    <ModalConfirmVersion2
                        title={`Duplicate message`}
                        message={`Are you sure you want to duplicate this message?`}
                        buttonName={'Duplicate'}
                        action={this.joinDocuments}
                        close={this.closeModalConfirm}
                    />
                ) : null}
                {this.state.modalMergeLabels ? (
                    <ModalMergeLabelsVersion2
                        intl={this.props.intl}
                        close={this.closeModalMergeLabels}
                        selectedLabelIds={selectedLabelIds}
                        showModalLabel={this.showModalLabel}
                        showModalLabelUsedIn={this.showModalLabelUsedIn}
                        showModalPreview={this.showModalPreview}
                        refreshModalMergeLabels={this.state.refreshModalMergeLabels}
                    />
                ) : null}
                {this.state.modalLabel ? (
                    <ModalLabelVersion2
                        intl={this.props.intl}
                        close={this.closeModalLabel}
                        label={this.state.modalLabel}
                        labelType={this.state.modalLabel}
                        showModalLabelUsedIn={this.showModalLabelUsedIn}
                    />
                ) : null}
                {this.state.modalLabelUsedIn ? (
                    <ModalUsedInVersion2
                        elementId={this.state.modalLabelUsedIn}
                        intl={this.props.intl}
                        type={rowType.LABEL}
                        close={this.closeModal}
                    />
                ) : null}
                {this.state.modalPreview !== null ? (
                    <ModalLabelPreviewVersion2
                        intl={this.props.intl}
                        close={this.closeModalPreview}
                        valueHTML={this.state.modalPreview}
                    />
                ) : null}
                {this.state.loading ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Web Back Office`} />
                <NavigationBackOffice />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <SelectInput
                            label={`Office`}
                            value={this.state.criteria.officeId}
                            onChange={this.onSelectedOfficeChange}
                            list={OPTIONS_OFFICE}
                        />
                        <SelectInput
                            label={`Language`}
                            value={this.state.criteria.languageId}
                            onChange={this.onSelectedLanguageChange}
                            list={OPTIONS_LANGUAGE}
                        />
                        <SelectInput
                            label={`Type`}
                            value={this.state.criteria.typeId}
                            onChange={this.onSelectedTypeIdChange}
                            list={OPTIONS_TYPE}
                        />
                        <TextInput
                            label={`Label value`}
                            filter={['start', 'contains']}
                            currentFilter={this.state.criteria.containsFilter}
                            onFilterChange={this.onContainsFilterChange}
                            infoIcon={faInfo}
                            onChange={this.onContainsInputChange}
                            value={this.state.criteria.contains}
                            popOverText={`The search will find all labels whose contains the entered text`}
                            double={true}
                        />
                        <TextInput
                            label={`Label ID`}
                            filter={'Equals'}
                            infoIcon={faInfo}
                            onChange={this.onLabelIdInputChange}
                            value={this.state.criteria.labelIds}
                            popOverText={`The search will find all labels whose ID correspond to the entered number`}
                        />
                        <Checkbox
                            clickAction={this.onDuplicatedCheckboxChange}
                            simple={true}
                            label={`Label with duplicates`}
                            value={this.state.criteria.duplicated}
                        />
                        <Checkbox
                            clickAction={this.onEmptyCheckboxChange}
                            simple={true}
                            label={`Empty text only`}
                            value={this.state.criteria.empty}
                        />
                        <Checkbox
                            clickAction={this.onNotUsedCheckboxChange}
                            simple={true}
                            label={`Not used only`}
                            value={this.state.criteria.notUsed}
                        />
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            <Button clickAction={this.resetCriteria} variation={'secondary'}>{`Clear fields`}</Button>
                            <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    <CustomTable
                        version={2}
                        loading={this.state.loading}
                        pageNumber={this.state.criteria.pageNumber}
                        {...this.props}
                        tableName={'labels'}
                        actionName={`Action`}
                        tableType={'OBJECT'}
                        tableSource={this.state.labels}
                        timestamp={this.state.timestamp}
                        dataFilter={null}
                        id={['labelId', 'languageId']}
                        selectable={true}
                        onSelectedChange={this.onSelectedChange}
                        setClearSelectionFunction={this.setClearSelectionFunction}
                        noSelectAll={true}
                        pagination={true}
                        resultFieldsDefault={DEFAULT_RESULT_FIELDS}
                        intl={this.props.intl}
                        defaultOrder={this.state.criteria.order}
                        reverseOrder={this.state.criteria.reverse}
                        pageSize={this.state.criteria.pageSize}
                        filterFunctions={null}
                        count={this.state.count}
                        hideExcelButton={true}
                        formatFunctions={{
                            languageId: this.getLanguageById,
                            typeId: this.getLabelTypeById,
                            officeId: this.getOfficeNameById,
                            usedIn: (labelId, label) => {
                                if (label.usedIn !== undefined) {
                                    return Object.keys(label.usedIn).map(statusId => {
                                        const counter = label.usedIn[statusId];
                                        return (
                                            counter !== 0 && (
                                                <div>
                                                    <StatusField
                                                        intl={this.props.intl}
                                                        statusId={statusId}
                                                        counter={counter}
                                                    />
                                                </div>
                                            )
                                        );
                                    });
                                } else {
                                    return (
                                        (label.typeId !== 10 && label.typeId !== 11 && this.state.computingUsage && (
                                            <FontAwesomeIcon icon={faSpinner} color={'green'} spin />
                                        )) ||
                                        null
                                    );
                                }
                            },
                        }}
                        actions={actions}
                        rowClass={this.getRowClass}
                        topCaption={
                            <BackOfficeLabelsTopCaption
                                mergeEnabled={this.state.mergeEnabled}
                                mergeInfo={
                                    !this.state.mergeEnabled && Object.keys(this.state.selectedLabels).length > 1
                                }
                                showModalMergeLabels={this.showModalMergeLabels}
                                computeEnabled={(this.state.labels || []).length > 0 && !this.state.loading}
                                computeUsedInSearch={this.startComputeUsedInSearch}
                            />
                        }
                        bottomCaption={<BackOfficeLabelsBottomCaption />}
                        updateCriteriaValue={this.updateCriteriaValue}
                    />
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(BackOfficeLabels);
