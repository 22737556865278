import React from 'react';
import cx from 'classnames';
import styles from './ModalComparisonVersion2.module.scss';
import ModalCustomVersion2 from './ModalCustomVersion2';
import {Button} from '../componentsFormV2';
import {apiBackOfficeFormCompare, IModalComparisonForm} from './ModalComparisonVersion2Service';
import CustomTable from '~components/CustomTable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGripLines, faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {faFolder} from '@fortawesome/free-regular-svg-icons';
import {LoadingBar} from '../componentsLayout';

export const rowType: any = {
    GROUP: 0,
    QUESTION: 1,
    RESPONSE: 2,
};
const nextRowType: any = {};
nextRowType[rowType.GROUP] = rowType.QUESTION;
nextRowType[rowType.QUESTION] = rowType.RESPONSE;

const rowTypeName: any = {};
rowTypeName[rowType.GROUP] = `Group`;
rowTypeName[rowType.QUESTION] = `Question`;
rowTypeName[rowType.RESPONSE] = `Response`;

const styleState: any = {
    1: styles.itemModified,
    2: styles.itemDeleted,
    3: styles.itemInserted,
};

interface IProps {
    close: any;
    formId: any;
    intl: any;
}

interface IState {
    countries: any;
    form: IModalComparisonForm | null;
    resultRows: any;
    inputValue: '';
    filterString: '';
    timestamp: number;
}

export default class ModalComparisonVersion2 extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            countries: null,
            form: null,
            resultRows: null,
            inputValue: '',
            filterString: '',
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        if (this.props.formId) {
            apiBackOfficeFormCompare(this.props.formId).then(jsonData => {
                jsonData &&
                    jsonData.data &&
                    jsonData.data.form &&
                    this.setState({form: jsonData.data.form}, () => {
                        this.processForms(this.state.form);
                    });
            });
        }
    }

    toggleFieldVisibility = (uniqueId: any) => this.processForms(this.state.form, uniqueId);

    processForms = (form: IModalComparisonForm | null, nodeToggleId?: any) => {
        const resultRows: any = [];
        ((form && form.groups) || []).forEach(group =>
            this.processNode(resultRows, group, rowType.GROUP, true, nodeToggleId)
        );
        this.setState({resultRows, timestamp: Date.now()});
    };

    processNode = (rows: any, node: any, type: any, parentOpen: any, nodeToggleId: any, prefix?: any) => {
        const uniqueId = `${prefix ? `${prefix}-` : ''}${node.isTemplate ? 'T' : ''}${node.id || node.templateId}`;
        node.open = nodeToggleId === uniqueId ? !node.open : node.open || false;
        if (parentOpen) {
            rows.push({
                id: node.id || node.templateId,
                isTemplate: node.isTemplate,
                uniqueId,
                order: node.order,
                name: node.name,
                type: node.type,
                jsonMapping: node.jsonMapping,
                rowType: type,
                iconOpen: node.open,
                children: (node.questions || node.responses || []).length,
                comparison: node.comparison,
            });
        }
        if (node.old) {
            const oldUniqueId = `${prefix ? `${prefix}-` : ''}${node.old.id}`;
            this.processNode(rows, node.old, type, parentOpen === false ? false : node.open, nodeToggleId, oldUniqueId);
        }
        const nextType = nextRowType[type];
        (node.questions || node.responses || []).forEach((child: any) => {
            this.processNode(rows, child, nextType, parentOpen === false ? false : node.open, nodeToggleId, uniqueId);
        });
    };

    close = () => this.props.close && this.props.close();

    renderFieldName = (name: any, rowObject: any) => (
        <div
            onClick={() => this.toggleFieldVisibility(rowObject.uniqueId)}
            style={(rowObject.children && rowObject.children > 0 && {cursor: 'pointer'}) || null}
        >
            <a style={{paddingLeft: rowObject.rowType * 18}}>
                {(((rowObject.children && rowObject.children > 0) || rowObject.rowType === rowType.RESPONSE) && (
                    <FontAwesomeIcon
                        icon={
                            rowObject.rowType === rowType.RESPONSE
                                ? faFolder
                                : rowObject.iconOpen
                                ? faMinus
                                : (faPlus as any)
                        }
                    />
                )) || <FontAwesomeIcon icon={faGripLines as any} color={'white'} />}
            </a>
            <span className="indented tree-label ng-scope">
                <span className="ng-scope">
                    <span className={styleState[rowObject.comparison]}>
                        {` `}
                        <b style={{paddingRight: 3}}>{rowTypeName[rowObject.rowType]}</b>
                        {`${rowObject.order} . ${name} `}
                    </span>
                    <span className={cx(styles.badge, rowObject.isTemplate && styles.template)}>{rowObject.id}</span>
                    <span className={styles.mappingCode}>{rowObject.jsonMapping}</span>
                </span>
            </span>
        </div>
    );

    render() {
        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={
                    <>
                        <h2>{`Form comparison`}</h2>
                        <div>{this.state.form && this.state.form.name}</div>
                    </>
                }
                body={
                    this.state.resultRows ? (
                        <CustomTable
                            {...this.props}
                            hiddenHeader={true}
                            tableName={'formComparison'}
                            tableType={'OBJECT'}
                            tableSource={this.state.resultRows}
                            id={'name'}
                            resultFieldsDefault={['name']}
                            intl={this.props.intl}
                            hideExcelButton={true}
                            formatFunctions={{
                                name: this.renderFieldName,
                            }}
                            timestamp={this.state.timestamp}
                            sortFunctions={{}}
                            notSortable={true}
                            unlimited={true}
                            topCaption={
                                <div className={styles.captionContainer}>
                                    <div className={cx(styles.label, styles.labelGreen)}>{`Template`}</div>
                                    <div className={cx(styles.label, styles.labelBlue)}>{`Group Question`}</div>
                                </div>
                            }
                        />
                    ) : (
                        <LoadingBar />
                    )
                }
                footer={<Button variation={'danger'} clickAction={this.close} icon={'close'}>{`Close`}</Button>}
            />
        );
    }
}
