import React from 'react';
import cx from 'classnames';
import stylesNew from './SecondRow.module.scss';
import stylesOld from './SecondRowOld.module.scss';

export default function ({
    children,
    errorBorderColor,
    buttonIcon,
    disabled,
    table,
    menu,
    background,
    position,
    rows,
    oldBackOfficeStyles,
    fit,
    style: userStyle,
}) {
    let styles = oldBackOfficeStyles ? stylesOld : stylesNew;

    return (
        <React.Fragment>
            <div
                className={cx(
                    styles.secondRow,
                    rows && !isNaN(rows) && rows > 0 && styles.withRows,
                    buttonIcon && styles.buttonIcon,
                    disabled && styles.disabled,
                    menu && styles.menu,
                    fit && styles.secondRowFit,
                    errorBorderColor && styles.errorBorderColor
                )}
                style={Object.assign(
                    {},
                    table && {display: 'table'},
                    background && {background},
                    position && {position},
                    {...userStyle}
                )}
            >
                {children}
            </div>
        </React.Fragment>
    );
}
