import React from 'react';
import {injectIntl} from 'react-intl';
import ApplicationConsultationTabs, {ACCESS, TAB} from '~shared/ApplicationConsultationTabs';
import {
    apiMyApplicationsApplicationDetails,
    apiMyApplicationsDenomination,
    IApplicationDenominationItem,
    IApplicationDetails,
} from './MyPVRPrivateConsultationDetailsService';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {FORMAT_DATE, formatDateEasy} from '~components/FormatFunctions';
import CustomTable from '~components/CustomTable';
import {faInfo} from '@fortawesome/free-solid-svg-icons';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogo,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationMyPVR from '../../shared/NavigationMyPVR';
import {ModalCountryListVersion2, ModalDenominationInfoVersion2, ModalStatusInfoVersion2} from '../../commonModals';
import {apiPublicSearchParties} from '../../commonApi/publicSearch';
import {loadUrlParams, trackPageView} from '../../utils';
import NavigationTLO from '../../shared/NavigationTLO';

interface IProps {
    close?: any;
    intl: any;
}

interface IState {
    denominations: IApplicationDenominationItem[] | null;
    fileDetails: IApplicationDetails | null;
    loading: boolean;
    modalDenominationInfo: boolean;
    modalScreenCountries: boolean;
    modalStatusInfo: boolean;
    parties: any;
    timestamp: number;
}

class MyPVRPrivateConsultationDetails extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            fileDetails: null,
            parties: null,
            denominations: null,
            loading: false,
            modalDenominationInfo: false,
            modalScreenCountries: false,
            modalStatusInfo: false,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'privateConsultationDetails'});
    }

    loadJSONs = (applicationNumber: string) =>
        new Promise((resolve, reject) => {
            this.setState({loading: true}, () => {
                Promise.all([
                    this.loadApplicationDetails(applicationNumber),
                    this.loadParties(applicationNumber),
                    this.loadDenominations(applicationNumber),
                ])
                    .then(() => this.setState({loading: false}, () => resolve))
                    .catch(reject);
            });
        });

    loadApplicationDetails = (applicationNumber: string) =>
        apiMyApplicationsApplicationDetails(applicationNumber)
            .then(
                JSONResponse =>
                    JSONResponse.data &&
                    this.setState({
                        fileDetails: Object.assign(
                            {},
                            JSONResponse.data,
                            JSONResponse.data.applicationNumber === '19951123' && {
                                applicationStatus: 'T',
                                expirationDate: '2023-12-31',
                            }
                        ),
                    })
            )
            .catch((error: any) => LOG([`ERROR ${error}`]));

    loadParties = (applicationNumber: string) =>
        apiPublicSearchParties(applicationNumber)
            .then(JSONResponse => JSONResponse.data && this.setState({parties: JSONResponse.data}))
            .catch(error => LOG([`ERROR ${error}`]));

    loadDenominations = (applicationNumber: string) =>
        apiMyApplicationsDenomination(applicationNumber)
            .then(JSONResponse => JSONResponse.data && this.setState({denominations: JSONResponse.data}))
            .catch((error: any) => LOG([`ERROR ${error}`]));

    closeModal = () =>
        this.setState({
            modalScreenCountries: false,
            modalDenominationInfo: false,
            modalStatusInfo: false,
        });

    showModalDenominationInfo = () => this.setState({modalDenominationInfo: true});

    showModalStatusInfo = () => this.setState({modalStatusInfo: true});

    showCountriesModal = () => this.setState({modalScreenCountries: true});

    updateTimestamp = (dateNow: any) => this.setState({timestamp: dateNow});

    render() {
        const fileDetails: any = this.state.fileDetails || {};
        const urlParams = loadUrlParams();

        return (
            <>
                {this.state.modalDenominationInfo ? <ModalDenominationInfoVersion2 close={this.closeModal} /> : null}
                {this.state.modalStatusInfo ? <ModalStatusInfoVersion2 close={this.closeModal} /> : null}
                {this.state.modalScreenCountries ? <ModalCountryListVersion2 close={this.closeModal} /> : null}
                {this.state.loading ? <HeaderLoading /> : null}
                <HeaderLogo />
                <HeaderTitleAndVersion title={`My PVR`} />
                {urlParams.tlo ? <NavigationTLO activeTitle={`My Applications`} /> : <NavigationMyPVR />}
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <ApplicationConsultationTabs
                            tabId={TAB.DETAILS}
                            access={ACCESS.PRIVATE}
                            close={this.props.close}
                            search={this.loadJSONs}
                            loading={this.state.loading}
                            updateTimestamp={this.updateTimestamp}
                        />
                        <DataSheetFormSection title={'General'}>
                            <DataSheetFormFields
                                label={'Application number'}
                                data={fileDetails.applicationNumber}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'Status'}
                                data={
                                    fileDetails.applicationStatus &&
                                    this.props.intl.formatMessage({
                                        id: `status.fieldName.${fileDetails.applicationStatus}`,
                                    })
                                }
                                loading={this.state.loading}
                                buttonIcon={faInfo}
                                buttonAction={this.showModalStatusInfo}
                            />
                            <DataSheetFormFields
                                label={'Application date'}
                                data={formatDateEasy(fileDetails.applicationDate)}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'UPOV Code'}
                                data={fileDetails.specieUPOVId}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'Species'}
                                data={fileDetails.specieName}
                                loading={this.state.loading}
                                double={true}
                            />
                            <DataSheetFormFields
                                label={'First sale inside EU'}
                                data={formatDateEasy(fileDetails.firstCommercializationInsideEUDate)}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'Country'}
                                data={fileDetails.countryIdInsideEU}
                                loading={this.state.loading}
                                buttonIcon={faInfo}
                                buttonAction={this.showCountriesModal}
                            />
                            <DataSheetFormFields
                                label={'First sale outside EU'}
                                data={formatDateEasy(fileDetails.firstCommercializationOutsideEUDate)}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'Country'}
                                data={fileDetails.countryIdOutsideEU}
                                loading={this.state.loading}
                                buttonIcon={faInfo}
                                buttonAction={this.showCountriesModal}
                            />
                        </DataSheetFormSection>
                        <DataSheetFormSection title={'Application'}>
                            <DataSheetFormFields
                                label={'Arrival date'}
                                data={formatDateEasy(fileDetails.arrivalDate)}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'National application date'}
                                data={formatDateEasy(fileDetails.nationalOfficeDate)}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'Crop sector'}
                                data={fileDetails.groupVarietal}
                                loading={this.state.loading}
                                double={true}
                            />
                            <DataSheetFormFields
                                label={'Denomination'}
                                data={fileDetails.denomination}
                                loading={this.state.loading}
                                double={true}
                            />
                            <DataSheetFormFields
                                label={"Breeder's reference"}
                                data={fileDetails.reference}
                                loading={this.state.loading}
                                double={true}
                            />
                        </DataSheetFormSection>
                        <DataSheetFormSection title={'Title'}>
                            <DataSheetFormFields
                                label={'Title number'}
                                data={fileDetails.title}
                                loading={this.state.loading}
                                double={true}
                            />
                            <DataSheetFormFields
                                label={'Title date'}
                                data={formatDateEasy(fileDetails.titleStartDate)}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'Expiration date of protection'}
                                data={formatDateEasy(fileDetails.expirationDate)}
                                loading={this.state.loading}
                            />
                        </DataSheetFormSection>
                        <DataSheetFormSection title={'Parties'}>
                            {this.state.parties && this.state.parties.length && this.state.parties.length > 0 ? (
                                <CustomTable
                                    tableName={'parties'}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.parties}
                                    id={'clientId'}
                                    loading={this.state.loading}
                                    resultFieldsDefault={['role', 'clientId', 'name', 'addressOfficial', 'addressMail']}
                                    intl={this.props.intl}
                                    formatFunctions={{
                                        addressOfficial: (fieldData: any) => (
                                            <div style={{cursor: 'default'}}>
                                                {fieldData.split('\n').map((line: any) => (
                                                    <div key={line}>{line}</div>
                                                ))}
                                            </div>
                                        ),
                                        addressMail: (fieldData: any) => (
                                            <div style={{cursor: 'default'}}>
                                                {fieldData.split('\n').map((line: any) => (
                                                    <div key={line}>{line}</div>
                                                ))}
                                            </div>
                                        ),
                                    }}
                                    bold={() => true}
                                    timestamp={this.state.timestamp}
                                />
                            ) : null}
                        </DataSheetFormSection>
                        {this.state.denominations &&
                        this.state.denominations.length &&
                        this.state.denominations.length > 0 ? (
                            <DataSheetFormSection title={'Denomination'}>
                                <CustomTable
                                    tableName={'denomination'}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.denominations}
                                    id={'denominationId'}
                                    resultFieldsDefault={[
                                        'denomination',
                                        'status',
                                        'applicationDate',
                                        'publicationDate',
                                    ]}
                                    intl={this.props.intl}
                                    defaultOrder={'applicationDate'}
                                    reverseOrder={true}
                                    formatFunctions={{
                                        status: (status: any) =>
                                            this.props.intl.formatMessage({
                                                id: `denomination.${status}`,
                                            }),
                                        applicationDate: FORMAT_DATE,
                                        publicationDate: FORMAT_DATE,
                                    }}
                                    headerPopup={{
                                        status: {
                                            description: 'Status explanations',
                                            handler: this.showModalDenominationInfo,
                                        },
                                    }}
                                    bold={() => true}
                                    timestamp={this.state.timestamp}
                                />
                            </DataSheetFormSection>
                        ) : null}
                    </FormWrapper>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(MyPVRPrivateConsultationDetails);
