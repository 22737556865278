import React from 'react';
import cx from 'classnames';
import styles from './WizardSteps.module.scss';
import WizardStepsGroup from './WizardStepsGroup';
import {htmlToText} from '~utils/index';
import {faArrowCircleLeft, faArrowCircleRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {injectIntl} from 'react-intl';
import {IGroup} from '../../types';

const stepStates = {
    DISABLED: 0,
    READY: 1,
    COMPLETE: 2,
};

interface IProps {
    applicationCode: any;
    assistedEntries: any;
    currentStepNumber: number;
    editable: any;
    formId: number;
    formLanguage?: any;
    formOrTemplateOfficeId?: any;
    formOrTemplateStatus?: any;
    getStepState: any;
    goToStepByReference: any;
    getCurrentStep: any;
    goToStep: any;
    intl: any;
    iteration?: any;
    loadFormQuestionsJSONs: any;
    mainScreen?: string;
    modalQueuePush?: any;
    modalQueuePop?: any;
    mock?: any;
    questionnaireLanguage?: any;
    responsesClientHash: any;
    removeQuestion: any;
    removeResponse: any;
    screenData: any;
    showHidden?: any;
    steps: IGroup[];
    setStepGroupCallables: any;
    updateScreenData: any;
    validatedSteps: boolean[];
}

interface IState {}

class WizardSteps extends React.Component<IProps, IState> {
    steps: any;
    buttons = {
        previous: 'previous',
        next: 'next',
        submit: 'submit',
    };

    firstGroup = -1;

    constructor(props: IProps) {
        super(props);
        if (props.intl) {
            this.buttons.previous = props.intl.formatMessage({id: 'previous'});
            this.buttons.next = props.intl.formatMessage({id: 'next'});
            this.buttons.submit = props.intl.formatMessage({id: 'submit'});
        }
    }

    goToNext = () => {
        this.props.goToStep(this.props.currentStepNumber + 1);
    };

    hasNext = () => {
        return (
            this.props.steps &&
            this.props.steps.length > this.props.currentStepNumber + 1 &&
            this.props.getStepState(this.props.steps[this.props.currentStepNumber + 1]) !== stepStates.DISABLED
        );
    };

    goToPrevious = () => {
        this.props.goToStep(this.props.currentStepNumber - 1);
    };

    hasPrevious = () => {
        return this.props.currentStepNumber > 0;
    };

    registerStep = (stepScope: any) => {
        this.steps.push(stepScope);
        if (!stepScope.isStep) stepScope.isIncomplete = false;

        if (stepScope.isStep === 'true' && this.firstGroup === -1) {
            this.setState({currentStepNumber: this.steps.length - 1});
        }
    };

    unregisterStep = (stepScope: any) => {
        var index = this.steps.indexOf(stepScope);
        if (index >= 0) {
            this.steps.splice(index, 1);
        }
    };

    render() {
        const group = this.props.getCurrentStep();
        return (
            <>
                <div className="col-lg-10 col-md-9 col-xs-12 wizard-main">
                    <ul className={styles.pager}>
                        <li key={'previous'} className={styles.previous}>
                            <a
                                className={cx(styles.buttonLinkPrevious, !this.hasPrevious() && styles.linkDisabled)}
                                onClick={this.goToPrevious}
                            >
                                <FontAwesomeIcon icon={faArrowCircleLeft as any} />
                                {` ${this.buttons.previous}`}
                            </a>
                        </li>
                        {(this.props.steps || [])
                            .filter(i => i.isGroup)
                            .map((step, groupIndex) => {
                                const title = `${step.order} . ${htmlToText(step.message)}`;
                                let groupIsNotValid =
                                    this.props.screenData &&
                                    this.props.screenData.hasClickedOnSaveAndValidate &&
                                    this.props.validatedSteps &&
                                    !this.props.validatedSteps[groupIndex];
                                let groupIsValid =
                                    this.props.screenData &&
                                    this.props.screenData.hasClickedOnSaveAndValidate &&
                                    this.props.validatedSteps &&
                                    this.props.validatedSteps[groupIndex];
                                return (
                                    <li
                                        className={cx(
                                            styles.buttonCircle,
                                            groupIsNotValid && styles.notValid,
                                            groupIsValid && styles.isValid
                                        )}
                                        key={`step_${step.order}`}
                                    >
                                        <div
                                            className={cx(
                                                styles.circle,
                                                this.props.getCurrentStep() === step && styles.circleActive
                                            )}
                                            onClick={() => this.props.goToStepByReference(step)}
                                        >
                                            <div className={styles.stepTitleHover}>{title}</div>
                                            <div>{title.split('.')[0].trim()}</div>
                                        </div>
                                    </li>
                                );
                            })}
                        <li key={'next'} className={styles.next}>
                            <a
                                className={cx(styles.buttonLinkNext, !this.hasNext() && styles.linkDisabled)}
                                onClick={this.goToNext}
                            >
                                {`${this.buttons.next} `}
                                <FontAwesomeIcon icon={faArrowCircleRight as any} />
                            </a>
                        </li>
                    </ul>
                    <div className={styles.wizardStepContainer}>
                        {group ? (
                            <WizardStepsGroup
                                applicationCode={this.props.applicationCode}
                                assistedEntries={this.props.assistedEntries}
                                currentStepNumber={this.props.currentStepNumber}
                                editable={this.props.editable}
                                formId={this.props.formId}
                                formOrTemplateOfficeId={this.props.formOrTemplateOfficeId}
                                formOrTemplateStatus={this.props.formOrTemplateStatus}
                                group={group}
                                goToStep={this.props.goToStep}
                                loadFormQuestionsJSONs={this.props.loadFormQuestionsJSONs}
                                mainScreen={this.props.mainScreen}
                                modalQueuePush={this.props.modalQueuePush}
                                modalQueuePop={this.props.modalQueuePop}
                                mock={this.props.mock}
                                responsesClientHash={this.props.responsesClientHash}
                                removeQuestion={this.props.removeQuestion}
                                removeResponse={this.props.removeResponse}
                                setStepGroupCallables={this.props.setStepGroupCallables}
                                screenData={this.props.screenData}
                                steps={this.props.steps}
                                updateScreenData={this.props.updateScreenData}
                            />
                        ) : null}
                    </div>
                    <ul className={styles.pager}>
                        <li className={styles.previous}>
                            <a
                                className={cx(styles.buttonLinkPrevious, !this.hasPrevious() && styles.linkDisabled)}
                                onClick={this.goToPrevious}
                            >
                                <FontAwesomeIcon icon={faArrowCircleLeft as any} />
                                {` ${this.buttons.previous}`}
                            </a>
                        </li>
                        <li className={styles.next}>
                            <a
                                className={cx(styles.buttonLinkNext, !this.hasNext() && styles.linkDisabled)}
                                onClick={this.goToNext}
                            >
                                {`${this.buttons.next} `}
                                <FontAwesomeIcon icon={faArrowCircleRight as any} />
                            </a>
                        </li>
                    </ul>
                </div>
            </>
        );
    }
}

export default injectIntl(WizardSteps);
