import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeForceResponseHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p>
                        By selecting one response in one step, then user must respond with a certain answer in some
                        other step of the application.
                    </p>
                    <p>
                        An example of this type of logic exists in the main form (APPLICATION FOR COMMUNITY PLANT
                        VARIETY RIGHT TO THE COMMUNITY PLANT VARIETY OFFICE - CPVO) at step 9.01
                    </p>
                    <p>
                        By selecting &quot;A technical examination for official purposes has already been carried
                        out&quot; or &quot;A technical examination for official purposes is in the process of being
                        carried out&quot; in question 09.01, you have to respond &quot;yes&quot; to the question 06.01
                        and fill the table on question 06.02.
                    </p>
                </>
            }
            footer={
                <>
                    <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                </>
            }
        />
    );
};

export default ModalLogicTypeForceResponseHelp;
