import React from 'react';
import {formatDate} from '~components/FormatFunctions';
import {isRegisterIdValid, REGISTER_VALID_SIZE} from '~utils';
import {apiDenominationInsert, apiDenominationUpdate} from './ModalDenominationVersion2Service';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {faFlag} from '@fortawesome/free-solid-svg-icons';
import TextInput from '~components/TextInput';
import Info from '~components/Info';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TextAreaInput from '~components/TextAreaInput';
import {ModalCustomVersion2} from '../../commonModals';
import {Button} from '../../componentsFormV2';
import {Error} from '../../componentsLayout';

export const DENOMINATION_EDIT_MODE = {NEW: 'NEW', VIEW: 'VIEW', EDIT: 'EDIT'};
export const DENOMINATION_TYPE = {NONE: 1, CODE: 2, FANCY_NAME: 3};
const TODAY_PROPOSAL = 'todayProposal';

class ModalDenominationVersion2 extends React.Component {
    previousEditMode = null;

    constructor(props) {
        super(props);
        this.state = {
            applicationNumber: props.applicationNumber || '',
            speciesName: props.speciesName || '',
            breedersReference: props.breedersReference || '',
            denomination: props.denomination || '',
            denominationStatus: props.denominationId === 0 ? TODAY_PROPOSAL : props.denominationStatus || '',
            proposalDate: props.proposalDate || '',
            clientRemark: props.clientRemark || '',
            analysisContent: props.analysisContent || '',
            validApplicationNumber: true,
            forbiddenApplicationNumber: null,
            editMode: props.editMode,
            loading: 0,
        };
        LOG`${this.state.denominationStatus}`;
    }

    componentDidMount() {
        if (
            this.state.editMode === DENOMINATION_EDIT_MODE.EDIT &&
            this.state.applicationNumber &&
            this.state.applicationNumber !== ''
        ) {
            this.checkApplicationNumber(this.state.applicationNumber);
        }
    }

    getMandatoryAsterisk = label => (
        <React.Fragment>
            <div style={{display: 'inline-block', marginRight: 5}}>{label}</div>
            <div style={{display: 'inline-block', color: 'red'}}>{'*'}</div>
        </React.Fragment>
    );

    createNewDenominationProposal = () => {
        LOG`createNewDenominationProposal`;
        this.previousEditMode = this.state.editMode;
        this.setState(
            {
                editMode: this.props.denominationId === 0 ? DENOMINATION_EDIT_MODE.EDIT : DENOMINATION_EDIT_MODE.NEW,
                // denominationType: null,
                denomination: '',
                clientRemark: '',
                applicationStatus: null,
                denominationStatus: TODAY_PROPOSAL,
            },
            () => this.checkApplicationNumber(this.props.applicationNumber)
        );
    };

    ignore = () => {
        this.signAndSend(this.state.denomination);
    };

    isFormNoValid = () => {
        LOG`isFormNoValid1`;
        let errors = {
            highlightErrors: false,
            validApplicationNumber: true,
            // denominationTypeIncomplete: false,
            varietyDenominationIncomplete: false,
            // fancyName: '',
        };
        LOG`isFormNoValid2`;
        if (this.state.editMode === DENOMINATION_EDIT_MODE.NEW) {
            if (!isRegisterIdValid(this.state.applicationNumber)) {
                errors.validApplicationNumber = false;
                errors.highlightErrors = true;
            }
        }
        LOG`isFormNoValid4`;
        if (this.state.denomination === '') {
            errors.highlightErrors = true;
            errors.varietyDenominationIncomplete = true;
        }
        LOG`isFormNoValid7`;
        if (this.state.applicationNumberErrorGranted && this.state.clientRemark === '') {
            errors.highlightErrors = true;
        }

        return errors;
    };

    // signAndSendFancyName = () => {
    //     this.signAndSend(this.state.fancyName);
    // };

    signAndSend = denominationValue => {
        const errors = this.isFormNoValid();
        const {highlightErrors} = errors;
        this.setState(errors, () => {
            if (!highlightErrors || denominationValue) {
                LOG`Create Denomination Proposal`;
                const {applicationNumber, denomination, clientRemark, analysisContent} = this.state;
                const partialState = {
                    applicationNumber,
                    denomination: denominationValue || denomination,
                    denominationType: DENOMINATION_TYPE.NONE,
                    clientRemark,
                    analysisContent,
                };
                const {
                    applicationStatus,
                    breedersReference,
                    speciesName,
                    deadlineForReplay,
                    applicationDenomination,
                    foreseenDate,
                } = this.props;
                const additionalFields = {
                    applicationStatus,
                    breedersReference,
                    speciesName,
                    deadlineForReplay,
                    applicationDenomination,
                    foreseenDate,
                };
                if (this.state.editMode === DENOMINATION_EDIT_MODE.NEW && !this.state.applicationNumberAlreadyDraft) {
                    this.setState(
                        prev => ({loading: ++prev.loading}),
                        () => {
                            LOG`apiDenominationInsert --> ${JSON.stringify(
                                Object.assign({}, partialState, additionalFields),
                                null,
                                4
                            )}`;
                            apiDenominationInsert(Object.assign({}, partialState, additionalFields))
                                .then(() => this.props.close && this.props.close(applicationNumber))
                                .then(() => this.setState(prev => ({loading: --prev.loading})));
                        }
                    );
                }
                if (this.state.editMode === DENOMINATION_EDIT_MODE.EDIT || this.state.applicationNumberAlreadyDraft) {
                    this.setState(
                        prev => ({loading: ++prev.loading}),
                        () => {
                            LOG`apiDenominationUpdate --> ${JSON.stringify(
                                Object.assign({}, partialState, additionalFields),
                                null,
                                4
                            )}`;
                            apiDenominationUpdate(Object.assign({}, partialState, additionalFields))
                                .then(() => this.props.close && this.props.close(applicationNumber))
                                .then(() => this.setState(prev => ({loading: --prev.loading})));
                        }
                    );
                }
            }
        });
    };

    comebackToSearch = () => {
        LOG`this.state.editMode: ${this.state.editMode}`;
        LOG`this.previousEditMode: ${this.previousEditMode}`;
        if (
            (this.state.editMode === DENOMINATION_EDIT_MODE.EDIT ||
                this.state.editMode === DENOMINATION_EDIT_MODE.NEW) &&
            this.previousEditMode === DENOMINATION_EDIT_MODE.VIEW
        ) {
            this.setState({
                editMode: DENOMINATION_EDIT_MODE.VIEW,
                // denominationType: this.props.denominationType,
                denomination: this.props.denomination,
                clientRemark: this.props.clientRemark,
                analysisContent: this.props.analysisContent,
                denominationStatus: this.props.denominationStatus,
                highlightErrors: false,
                validApplicationNumber: true,
                varietyDenominationIncomplete: false,
                forbiddenApplicationNumber: false,
                applicationNumberErrorGranted: false,
                applicationNumberAlreadyDraft: false,
            });
        } else {
            this.props.close && this.props.close();
        }
    };

    cancel = () => {
        LOG`CANCEL`;
        this.setState(
            {
                highlightErrors: false,
            },
            () => this.checkApplicationNumber(this.props.applicationNumber)
        );
    };

    checkApplicationNumber = applicationNumber => {
        const validApplicationNumber = isRegisterIdValid(applicationNumber);
        this.setState(
            {
                applicationNumber,
                validApplicationNumber,
                forbiddenApplicationNumber: false,
                applicationNumberErrorGranted: false,
                applicationNumberAlreadyDraft: false,
            },
            () => {
                if (validApplicationNumber) {
                    this.setState(
                        prev => ({loading: ++prev.loading}),
                        () =>
                            this.props
                                .apiDenominationSearch({applicationNumber})
                                .then(jsonResponse => {
                                    if (jsonResponse && jsonResponse.data && jsonResponse.data.denominations) {
                                        let speciesName = '';
                                        let breedersReference = '';
                                        let applicationNumberAlreadyDraft = false;
                                        let forbiddenApplicationNumber = true;
                                        let applicationNumberErrorGranted = false;
                                        jsonResponse.data.denominations.map(denomination => {
                                            forbiddenApplicationNumber = false;
                                            if (denomination.denominationId === 0) {
                                                applicationNumberAlreadyDraft = true;
                                            } else {
                                                applicationNumberErrorGranted = denomination.applicationStatus === 'G';
                                                speciesName = denomination.speciesName || '';
                                                breedersReference = denomination.breedersReference || '';
                                            }
                                        });
                                        this.setState({
                                            applicationNumberAlreadyDraft,
                                            forbiddenApplicationNumber,
                                            applicationNumberErrorGranted,
                                            speciesName,
                                            breedersReference,
                                        });
                                    }
                                })
                                .then(() => this.setState(prev => ({loading: --prev.loading})))
                    );
                }
            }
        );
    };

    onApplicationNumberChange = event => {
        event.preventDefault();
        const applicationNumber = event.target.value;
        this.checkApplicationNumber(applicationNumber);
    };
    onSpeciesNameChange = ({target: {value: speciesName}}) => this.setState({speciesName});
    onBreedersReferenceChange = ({target: {value: breedersReference}}) => this.setState({breedersReference});
    onVarietyDenominationChange = ({target: {value: denomination}}) => this.setState({denomination});
    onRemarkChange = ({target: {value: clientRemark}}) => this.setState({clientRemark});

    render() {
        const ableToCreateNew = 'AUPMG'.indexOf(this.props.applicationStatus) !== -1;
        const proposalDate = formatDate(this.state.proposalDate);
        return (
            <ModalCustomVersion2
                loading={this.state.loading !== 0}
                close={this.comebackToSearch}
                header={`Denomination - ${this.props.intl.formatMessage({
                    id: `denomination.${this.state.denominationStatus}`,
                })}`}
                body={
                    <>
                        <TextInput
                            placeholder={`e.g. 2015 or 20150001`}
                            onChange={this.onApplicationNumberChange}
                            value={this.state.applicationNumber}
                            outsideLabel={this.getMandatoryAsterisk('Application number')}
                            outsideLabelWidth={170}
                            disabled={true}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        {this.state.applicationNumberIncomplete ||
                            !this.state.validApplicationNumber ||
                            (this.state.applicationNumber &&
                                this.state.applicationNumber.length !== REGISTER_VALID_SIZE && (
                                    <Info>
                                        <div style={{display: 'inline-block', marginRight: 5}}>
                                            <FontAwesomeIcon icon={faFlag} color={'#8a6d3b'} />
                                        </div>
                                        <div style={{display: 'inline-block'}}>
                                            {` This is not a valid application number. The value must be in range 19950001 to 9999 !`}
                                        </div>
                                    </Info>
                                ))}
                        {this.state.forbiddenApplicationNumber && (
                            <Info>{`The application number doesn't exist in your registers`}</Info>
                        )}
                        {this.state.applicationNumberErrorGranted && this.state.clientRemark === '' && (
                            <Error>{`This is a granted file (article 66). Further information should be entered in the "Client's comment" field`}</Error>
                        )}
                        {this.state.applicationNumberAlreadyDraft && (
                            <Info>{`The previous draft proposal denomination will be considered as cancelled`}</Info>
                        )}
                        <div style={{clear: 'both'}}>{}</div>
                        <TextInput
                            value={this.state.speciesName}
                            outsideLabel={'Species'}
                            outsideLabelWidth={170}
                            disabled={true}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        <TextInput
                            value={this.state.breedersReference}
                            outsideLabel={"Breeder's reference"}
                            outsideLabelWidth={170}
                            disabled={true}
                            onChange={this.onBreedersReferenceChange}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        <TextInput
                            value={this.state.denomination}
                            onChange={this.onVarietyDenominationChange}
                            outsideLabel={this.getMandatoryAsterisk('Variety denomination')}
                            outsideLabelWidth={170}
                            disabled={this.state.editMode === DENOMINATION_EDIT_MODE.VIEW}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        {(this.state.editMode === DENOMINATION_EDIT_MODE.NEW ||
                            this.state.editMode === DENOMINATION_EDIT_MODE.EDIT) && (
                            <small>
                                <div style={{display: 'inline-block', marginRight: 5}}>
                                    {`Each word of the denomination must start with an uppercase letter (unless linguistic custom demands otherwise). Words consisting of a succession of letters and/or numbers that have to be individually pronounced should be drafted in capital letters.`}
                                </div>
                                <div style={{display: 'inline-block', marginRight: 5}}>
                                    {`Please be informed that the suitability of the denomination proposal for similarity can be checked in the `}
                                </div>
                                <div style={{display: 'inline-block', color: '#2f923f'}}>
                                    <a href={self.varietyfinder}>{`Variety Finder Database`}</a>
                                </div>
                                <div style={{display: 'inline-block', marginRight: 5}}>
                                    {`For more information about the suitability of variety denominations, please consult the denominations guidelines on variety denominations with explanatory notes on the`}
                                    <div style={{display: 'inline-block', marginLeft: 5, color: '#2f923f'}}>
                                        <a
                                            href={`https://cpvo.europa.eu/sites/default/files/documents/cpvo_guidelines_on_art_63_with_explanatory_notes.pdf`}
                                            target={`_blank`}
                                        >{`CPVO website.`}</a>
                                    </div>
                                </div>
                            </small>
                        )}
                        <div style={{clear: 'both'}}>{}</div>
                        <TextInput
                            value={this.props.intl.formatMessage({
                                id: `denomination.${this.state.denominationStatus}`,
                            })}
                            outsideLabel={'Denomination status'}
                            outsideLabelWidth={170}
                            disabled={true}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        {this.state.editMode === DENOMINATION_EDIT_MODE.VIEW &&
                            this.state.denominationStatus !== TODAY_PROPOSAL && (
                                <TextInput
                                    value={proposalDate}
                                    outsideLabel={'Proposal date'}
                                    outsideLabelWidth={170}
                                    disabled={true}
                                />
                            )}
                        <div style={{clear: 'both'}}>{}</div>
                        <TextAreaInput
                            double={true}
                            value={this.state.clientRemark || ''}
                            onChange={this.onRemarkChange}
                            outsideLabel={`Client's comment`}
                            outsideLabelWidth={170}
                            width={625}
                            rows={5}
                            disabled={this.state.editMode === DENOMINATION_EDIT_MODE.VIEW}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        <TextAreaInput
                            double={true}
                            value={
                                (!DENOMINATION_EDIT_MODE.NEW &&
                                    this.state.denominationStatus !== TODAY_PROPOSAL &&
                                    this.state.analysisContent) ||
                                ''
                            }
                            outsideLabel={`Analysis content`}
                            outsideLabelWidth={170}
                            width={625}
                            rows={7}
                            disabled={true}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                    </>
                }
                footer={
                    <>
                        {this.props.contactClient &&
                            this.props.contactClient !== 'CPVO' &&
                            this.state.denominationStatus === TODAY_PROPOSAL && (
                                <div style={{color: '#f0ad4e', textAlign: 'right', width: '100%'}}>
                                    {`Denomination proposal by ${this.props.contactClient}`}
                                </div>
                            )}
                        <div style={{width: '100%'}}>
                            <em className="ng-binding" style={{color: '#2f923f'}}>
                                {this.props.intl.formatMessage({
                                    id: 'registration.completeAddressPreview.comment',
                                })}
                                {`(`}
                                <span className="text-danger">*</span>
                                {`)`}
                            </em>
                            {this.state.highlightErrors ? (
                                <Error>{`Missing fields! Please complete the mandatory fields`}</Error>
                            ) : null}
                        </div>
                        <div>
                            <Button variation={'danger'} clickAction={this.comebackToSearch} icon={'close'}>
                                {this.state.editMode === DENOMINATION_EDIT_MODE.EDIT ? ` Cancel` : ` Close`}
                            </Button>
                            {this.state.editMode === DENOMINATION_EDIT_MODE.VIEW &&
                            (!this.props.applicationStatus || ableToCreateNew) ? (
                                <Button
                                    clickAction={this.createNewDenominationProposal}
                                    icon={'arrowRight'}
                                    style={{marginLeft: 15}}
                                >{`New denomination proposal`}</Button>
                            ) : null}
                            {this.state.editMode === DENOMINATION_EDIT_MODE.NEW ||
                            this.state.editMode === DENOMINATION_EDIT_MODE.EDIT ? (
                                <Button
                                    clickAction={() => this.signAndSend()}
                                    icon={'arrowRight'}
                                    style={{marginLeft: 15}}
                                >{`Sign & Send`}</Button>
                            ) : null}
                        </div>
                    </>
                }
            />
        );
    }
}

export default injectIntl(withRouter(ModalDenominationVersion2));
