import React from 'react';
import styles from './FormWrapper.module.scss';
import cx from 'classnames';

interface IFormWrapperProps {
    children: any;
    className?: any;
    classNameInner?: any;
    classNameContent?: any;
    formInnerWidth?: 'lg';
    padding?: 'sm' | 'md' | 'lg';
    paddingY?: 'sm' | 'lg';
    paddingFormContent?: 'sm';
    style?: any;
}

export default function ({
    children,
    className,
    classNameInner,
    classNameContent,
    formInnerWidth,
    paddingFormContent,
    paddingY,
    style: userStyle,
}: IFormWrapperProps) {
    let stylesAppliedFormContentPadding;
    let stylesAppliedFormInnerPadding;
    let stylesAppliedFormInnerWidth;
    let stylesAppliedPaddingY;

    const formInnerPaddingStyle: any = {
        sm: styles.formInnerPaddingSm,
    };

    const formContentPaddingStyle: any = {
        sm: styles.formContentPaddingSm,
    };

    const formInnerWidthStyle: any = {
        lg: styles.formInnerWidthLg,
    };

    const formWrapPaddingYStyle: any = {
        sm: styles.formWrapPaddingYSm,
        lg: styles.formWrapPaddingYLg,
    };

    if (paddingFormContent) {
        stylesAppliedFormContentPadding = formContentPaddingStyle[paddingFormContent];
        stylesAppliedFormInnerPadding = formInnerPaddingStyle[paddingFormContent];
    }

    if (formInnerWidth) {
        stylesAppliedFormInnerWidth = formInnerWidthStyle[formInnerWidth];
    }

    if (paddingY) {
        stylesAppliedPaddingY = formWrapPaddingYStyle[paddingY];
    }

    return (
        <section className={cx(styles.formWrap, className ? className : null, stylesAppliedPaddingY)} style={userStyle}>
            <div
                className={cx(
                    styles.formInner,
                    classNameInner,
                    stylesAppliedFormInnerPadding,
                    stylesAppliedFormInnerWidth
                )}
            >
                <div className={cx(styles.formContentWrap, classNameContent, stylesAppliedFormContentPadding)}>
                    {children}
                </div>
            </div>
        </section>
    );
}
