import {getFetch, postFetch, API_SERVERLESS} from '~commonApi';

export function apiProceedingDocuments(applicationNumber) {
    return getFetch(`${API_SERVERLESS}/backOffice/v3/adminProceedingsDocuments/${applicationNumber}`, true);
}

export function apiProceedingRightsByUser(contactId, applicationNumber) {
    return getFetch(
        `${API_SERVERLESS}/backOffice/v3/adminProceedingsRightsByUser/${contactId}/${applicationNumber}`,
        true
    );
}

export function apiAdminProceedingsRightsByUserInsert(jsonData) {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/adminProceedingsRightsByUserInsert`, true, jsonData);
}
