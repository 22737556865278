import React from 'react';
import {IntlProvider} from 'react-intl';
import deTranslations from '../translations/de.json';
import enTranslations from '../translations/en.json';
import esTranslations from '../translations/es.json';
import frTranslations from '../translations/fr.json';
import nlTranslations from '../translations/nl.json';
import {loadUrlParams} from '../utils';

const Context = React.createContext(null);

const LANGUAGES = {
    de: deTranslations,
    en: enTranslations,
    es: esTranslations,
    fr: frTranslations,
    nl: nlTranslations,
};

class IntlProviderWrapper extends React.Component {
    constructor(...args) {
        super(...args);

        const urlParams = loadUrlParams();
        this.initialLanguage = urlParams.viewFormLang
            ? Object.keys(LANGUAGES).includes(urlParams.viewFormLang.toLowerCase())
                ? urlParams.viewFormLang.toLowerCase()
                : 'en'
            : 'en';

        this.switchLanguage = (language = '') => {
            let languageToUpdate = language.toLowerCase();
            if (this.state.locale === languageToUpdate) return;
            if (Object.keys(LANGUAGES).includes(languageToUpdate)) {
                this.setState({
                    locale: languageToUpdate,
                    messages: {
                        ...LANGUAGES['en'],
                        ...LANGUAGES[languageToUpdate],
                    },
                });
            }
        };

        this.state = {
            locale: this.initialLanguage,
            messages:
                this.initialLanguage === 'en'
                    ? LANGUAGES[this.initialLanguage]
                    : {
                          ...LANGUAGES['en'],
                          ...LANGUAGES[this.initialLanguage],
                      },
            switchLanguage: this.switchLanguage,
        };
    }

    render() {
        const {children} = this.props;
        const {locale, messages} = this.state;
        return (
            <Context.Provider value={this.state}>
                <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale="en">
                    {children}
                </IntlProvider>
            </Context.Provider>
        );
    }
}

export {IntlProviderWrapper, Context as IntlContext};
