import React from 'react';

interface IIconCloseProps {
    style?: any;
    className?: any;
    color?: string;
}

function Close({style: userStyle, color, className}: IIconCloseProps) {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{...(userStyle || {})}}
            className={className}
        >
            <path
                id="Ellipse 5"
                d="M8 14.5L11.25 11.25M11.25 11.25L14.5 8M11.25 11.25L8 8M11.25 11.25L14.5 14.5M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 8.96818 1.60596 7.0779 2.64702 5.5C4.43576 2.78889 7.50897 1 11 1C16.5228 1 21 5.47715 21 11Z"
                stroke="#810202"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Close;
