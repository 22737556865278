import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export function apiCommunicationCentreInternalArchiveSearch(
    criteria: any,
    DEFAULT_CRITERIA: any = {},
    excelTranslations?: boolean
) {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/communicationCentreInternal/v4/CCIAS/archive/search?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}

export function apiCommunicationCentreInternalArchiveMessageUnArchive(communicationId: number) {
    return postFetch(`${API_SERVERLESS}/communicationCentreInternal/v4/CCIAMU/archive/messageUnArchive`, true, {
        communicationIds: [communicationId],
    });
}

export function apiCommunicationCentreInternalArchiveClientsList() {
    return getFetch(`${API_SERVERLESS}/communicationCentreInternal/v4/CCIACL/archive/clientsList`, true);
}
