import React from 'react';

interface IIconUserProps {
    style?: any;
    color?: string;
    className?: any;
}

export default function ({style: userStyle, color, className}: IIconUserProps) {
    return (
        <svg
            width="20"
            height="20"
            style={{...(userStyle || {})}}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M10 9.99997C11.3807 9.99997 12.5 8.88068 12.5 7.49997C12.5 6.11926 11.3807 4.99997 10 4.99997C8.61929 4.99997 7.5 6.11926 7.5 7.49997C7.5 8.88068 8.61929 9.99997 10 9.99997Z"
                stroke={color || '#656565'}
                strokeWidth="1.5"
            />
            <path
                d="M14.975 16.6666C14.8417 14.2566 14.1042 12.5 9.99999 12.5C5.89583 12.5 5.15833 14.2566 5.02499 16.6666"
                stroke={color || '#656565'}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M5.83332 2.78164C7.09959 2.04905 8.53708 1.66438 9.99999 1.66664C14.6025 1.66664 18.3333 5.39747 18.3333 9.99997C18.3333 14.6025 14.6025 18.3333 9.99999 18.3333C5.39749 18.3333 1.66666 14.6025 1.66666 9.99997C1.66666 8.48247 2.07249 7.0583 2.78166 5.8333"
                stroke={color || '#656565'}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
}
