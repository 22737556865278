import React from 'react';
import styles from './ApplicationConsultationTabs.module.scss';
import cx from 'classnames';
import {injectIntl} from 'react-intl';
import Label from '~components/Label';
import TextInput from '~components/TextInput';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {faSearch, faArrowLeft, faFile, faServer, faBook, faInbox, faEuroSign} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {withRouter} from 'react-router-dom';
import SelectInput from '~components/SelectInput';
import PrintComponent from '../screens/TLO/CommonComponents/PrintComponent/PrintComponent';

// import {apiPublicSearchApplicationStatus} from './ApplicationConsultationTabsService';

export const TAB = {
    DETAILS: 'DETAILS',
    EXAMINATION: 'EXAMINATION',
    PUBLICATIONS: 'PUBLICATIONS',
    DOCUMENTS: 'DOCUMENTS',
    FEES: 'FEES',
    SCHEMA: 'SCHEMA',
};

const menuLinks = [
    {id: TAB.DETAILS, value: 'Application details'},
    {id: TAB.EXAMINATION, value: 'Examinations'},
    {id: TAB.PUBLICATIONS, value: 'Publications'},
    {id: TAB.DOCUMENTS, value: 'Documents'},
];

export const ACCESS = {PUBLIC: 'PUBLIC', PRIVATE: 'PRIVATE'};

class ApplicationConsultationTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            applicationNumber: this.props.registerId,
            lastSearchApplicationNumber: this.props.registerId,
            params: null,
            status: null,
            print: null,
            tlo: null,
            showOnlyDocumentsTab: null,
        };
    }

    componentDidMount() {
        this.loadUrlParams(() => this.state.applicationNumber && this.props.search(this.state.applicationNumber));
        // apiPublicSearchApplicationStatus(applicationNumber).then(
        //     jsonResult => jsonResult && jsonResult.data && this.setState({status: jsonResult.data})
        // );
    }

    loadUrlParams = callback => {
        const params = loadUrlParams();
        if (params.registerId) {
            const registerId = params['registerId'];
            const print = params['print'];
            const tlo = params['tlo'];
            const showOnlyDocumentsTab = params['showOnlyDocumentsTab'];
            const validRegisterId = !isNaN(registerId);
            this.setState(
                Object.assign(
                    {params},
                    validRegisterId && {
                        registerId,
                        applicationNumber: registerId,
                        lastSearchApplicationNumber: registerId,
                        back: params.back,
                        print,
                        tlo,
                        showOnlyDocumentsTab,
                    }
                ),
                callback
            );
        }
    };

    refreshUrl = noScroll => {
        if (this.state.lastSearchApplicationNumber) {
            const domain = window.location.href.split('?')[0].split('/').pop();
            this.props.history.replace(`/${domain}${(this.state.params && `?${joinParams(this.state.params)}`) || ''}`);
            !noScroll && window.scrollTo(0, 0);
        }
    };

    search = () => {
        const validRegisterId = !isNaN(this.state.applicationNumber) && this.state.applicationNumber.length === 8;
        validRegisterId &&
            this.props.search(this.state.applicationNumber).then(() =>
                this.setState(
                    prev => {
                        const params = prev.params;
                        params.registerId = prev.applicationNumber;
                        return {lastSearchApplicationNumber: prev.applicationNumber, params};
                    },
                    () => {
                        this.refreshUrl(true);
                        this.props.updateTimestamp && this.props.updateTimestamp(Date.now());
                    }
                )
            );
    };

    goToApplicationDetails = () => {
        if (!this.props.loading && this.state.lastSearchApplicationNumber) {
            const params = Object.assign({}, this.state.params);
            params.registerId = this.state.lastSearchApplicationNumber;
            this.props.history.push(
                `/${
                    this.props.access === ACCESS.PRIVATE ? 'privateConsultationDetails' : 'publicConsultationDetails'
                }/?${joinParams(this.state.params)}`
            );
        }
    };

    goToExaminations = () => {
        if (!this.props.loading && this.state.lastSearchApplicationNumber) {
            const params = Object.assign({}, this.state.params);
            params.registerId = this.state.lastSearchApplicationNumber;
            this.props.history.push(
                `/${
                    this.props.access === ACCESS.PRIVATE
                        ? 'privateConsultationExamination'
                        : 'publicConsultationExamination'
                }/?${joinParams(this.state.params)}`
            );
        }
    };

    goToPublications = () => {
        if (!this.props.loading && this.state.lastSearchApplicationNumber) {
            const params = Object.assign({}, this.state.params);
            params.registerId = this.state.lastSearchApplicationNumber;
            this.props.history.push(
                `/${
                    this.props.access === ACCESS.PRIVATE
                        ? 'privateConsultationPublications'
                        : 'publicConsultationPublications'
                }/?${joinParams(this.state.params)}`
            );
        }
    };

    goToDocuments = () => {
        if (!this.props.loading && this.state.lastSearchApplicationNumber) {
            const params = Object.assign({}, this.state.params);
            params.registerId = this.state.lastSearchApplicationNumber;
            this.props.history.push(
                `/${
                    this.props.access === ACCESS.PRIVATE
                        ? 'privateConsultationDocuments'
                        : 'publicConsultationDocuments'
                }?${joinParams(this.state.params)}`
            );
        }
    };

    goToFees = () => {
        if (!this.props.loading && this.state.lastSearchApplicationNumber) {
            const params = Object.assign({}, this.state.params);
            params.registerId = this.state.lastSearchApplicationNumber;
            this.props.history.push(`/privateConsultationFees?${joinParams(this.state.params)}`);
        }
    };

    goBackToSearch = () => {
        const params = Object.assign({}, this.state.params);
        delete params.registerId;
        if (this.state.tlo) {
            delete params.print;
            delete params.tlo;
            delete params.showOnlyDocumentsTab;
        }
        const backToSearchPath = this.state.tlo ? 'tloMyApplicationsMyReports' : 'myApplications';
        this.props.history.push(
            `/${this.props.access === ACCESS.PRIVATE ? backToSearchPath : 'publicSearch'}${
                (this.state.params && `?${joinParams(params)}`) || ''
            }`
        );
    };

    onApplicationNumberChange = event => {
        let applicationNumber = event.target.value;
        if (/[0-9]{0,8}/.test(applicationNumber)) {
            applicationNumber = applicationNumber.substring(0, 8);
            this.setState({applicationNumber});
        }
    };

    onTabChange = event => {
        const tabId = event.target.value;
        switch (tabId) {
            case TAB.DETAILS:
                this.goToApplicationDetails();
                break;
            case TAB.EXAMINATION:
                this.goToExaminations();
                break;
            case TAB.PUBLICATIONS:
                this.goToPublications();
                break;
            case TAB.DOCUMENTS:
                this.goToDocuments();
                break;
            case TAB.FEES:
                this.goToFees();
                break;
        }
    };

    render() {
        return (
            <React.Fragment>
                {!this.state.back && (
                    <div style={{float: 'right', marginRight: 10, marginBottom: 20}}>
                        <FormFooterButton
                            color={buttonColor.whiteGray}
                            clickAction={this.goBackToSearch}
                            icon={faArrowLeft}
                        >{`Go back to search`}</FormFooterButton>
                        {this.state.print && <PrintComponent />}
                    </div>
                )}
                <Label width={160}>{`Application number`}</Label>
                <TextInput
                    value={this.state.applicationNumber}
                    onChange={this.onApplicationNumberChange}
                    buttonIcon={faSearch}
                    buttonAction={this.search}
                    buttonColor={this.props.loading ? 'gray' : 'green'}
                />
                <div style={{clear: 'both'}}>{}</div>
                <div className={styles.header}>
                    <div className={styles.leftPanel}>
                        {this.state.tlo && this.state.showOnlyDocumentsTab ? null : (
                            <div
                                className={cx(
                                    styles.menuItem,
                                    this.props.tabId === TAB.DETAILS && styles.menuItemActive
                                )}
                                onClick={this.goToApplicationDetails}
                            >
                                <div className={styles.menuIcon}>
                                    <FontAwesomeIcon icon={faFile} color={this.props.loading ? 'gray' : 'green'} />
                                </div>
                                <div className={styles.menuLabel}>{`Application details`}</div>
                            </div>
                        )}
                        {this.state.tlo && this.state.showOnlyDocumentsTab ? null : (
                            <div
                                className={cx(
                                    styles.menuItem,
                                    this.props.tabId === TAB.EXAMINATION && styles.menuItemActive
                                )}
                                onClick={this.goToExaminations}
                            >
                                <div className={styles.menuIcon}>
                                    <FontAwesomeIcon icon={faServer} color={this.props.loading ? 'gray' : 'green'} />
                                </div>
                                <div className={styles.menuLabel}>{`Examinations`}</div>
                            </div>
                        )}
                        {this.state.tlo && this.state.showOnlyDocumentsTab ? null : (
                            <div
                                className={cx(
                                    styles.menuItem,
                                    this.props.tabId === TAB.PUBLICATIONS && styles.menuItemActive
                                )}
                                onClick={this.goToPublications}
                            >
                                <div className={styles.menuIcon}>
                                    <FontAwesomeIcon icon={faBook} color={this.props.loading ? 'gray' : 'green'} />
                                </div>
                                <div className={styles.menuLabel}>{`Publications`}</div>
                            </div>
                        )}
                        {this.props.access === ACCESS.PRIVATE && !this.state.tlo && (
                            <div
                                className={cx(styles.menuItem, this.props.tabId === TAB.FEES && styles.menuItemActive)}
                                onClick={this.goToFees}
                                style={{minWidth: 60}}
                            >
                                <div className={styles.menuIcon}>
                                    <FontAwesomeIcon icon={faEuroSign} color={this.props.loading ? 'gray' : 'green'} />
                                </div>
                                <div className={styles.menuLabel}>{`Fees`}</div>
                            </div>
                        )}
                        <div
                            className={cx(styles.menuItem, this.props.tabId === TAB.DOCUMENTS && styles.menuItemActive)}
                            onClick={this.goToDocuments}
                        >
                            <div className={styles.menuIcon}>
                                <FontAwesomeIcon icon={faInbox} color={this.props.loading ? 'gray' : 'green'} />
                            </div>
                            <div className={styles.menuLabel}>{`Documents`}</div>
                        </div>
                        <div style={{clear: 'both'}}>{}</div>
                    </div>
                    <div className={styles.leftPanelMobile}>
                        <SelectInput
                            value={this.props.tabId}
                            onChange={this.onTabChange}
                            list={menuLinks}
                            notAll={true}
                            menu={true}
                        />
                    </div>
                    <div style={{clear: 'both'}}>{}</div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(withRouter(ApplicationConsultationTabs));

function loadUrlParams() {
    const domainArray = document.location.href.split('?');
    const parameters = {};
    if (domainArray.length > 1) {
        const params = domainArray.pop();
        params.split('&').forEach(param => {
            const paramElements = param.split('=');
            if (paramElements.length === 2) {
                const key = paramElements[0];
                parameters[key] = decodeURIComponent(paramElements[1]);
            }
        });
    }
    return parameters;
}

function joinParams(params) {
    return Object.keys(params)
        .map(paramKey => `${paramKey}=${params[paramKey]}`)
        .join('&');
}
