import {API_SERVERLESS, getFetch} from '../../commonApi';

export interface IUserDetails {
    login: string;
    fullName: string;
    nature: string;
    userEmail: string;
    companyName: string;
    companyWebsite: string;
    companyPhoneNumber: string;
    userAddress: string;
    userPostCode: string;
    userCity: string;
    userCountry: string;
}

export function apiMyPVRMyProfile(): Promise<{data: IUserDetails}> {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/myProfile`, true);
}
