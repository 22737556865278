import {ICommunicationMessageInternal} from '../../types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {faRedo} from '@fortawesome/free-solid-svg-icons';

function renderButton(
    popupContent: string,
    handler: (id: any, rowObject: ICommunicationMessageInternal) => void,
    icon: any
) {
    return {
        title: popupContent,
        icon: () => {
            return (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon} />
                </a>
            );
        },
        handler,
    };
}

export default function CCInternalBackOfficeActionButtons(
    onTableIconFixSummaryClick: (rowObject: ICommunicationMessageInternal) => void
) {
    const buttons = [];
    buttons.push(renderButton('Fix Summary', (_, rowObject) => onTableIconFixSummaryClick(rowObject), faRedo));
    return buttons;
}
