import React, {useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {sanitize} from '../../../../utils';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import {ModalDocumentProps} from './Props/ModalDocumentProps';
import {UploadedFileData} from './Interfaces/ModalDocumentInterfaces';
import {ErrorMessages} from '../../CommonInterfaces/CommonInterfaces';
import {TreeElement} from '../TreeView/Interfaces/TreeViewInterfaces';
import styles from './ModalDocument.module.scss';
import {faChevronDown, faChevronRight, faExclamationCircle, faPlus} from '@fortawesome/free-solid-svg-icons';
import getIcon from '../../../../utils/icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import BrowserInfo from '~shared/BrowserInfo';
import {MAX_UPLOAD_FILE_SIZE} from '../../../../utils/constants';
import {ModalCustomVersion2, ModalErrorVersion2} from '../../../../commonModals';
import TextInput from '~components/TextInput';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import DateInput from '~components/DateInput';
import InputLink from '~components/InputLink';
import CustomTable from '../../../../components/CustomTable';
import Checkbox from '~components/Checkbox';
import {Error} from '../../../../componentsLayout';
import {getConcatErrorMessage} from '../../CommonFunctions/CommonFunctions';
import {apiSendTreeDocumentFile} from '../../CommonApi/CommonApi';
import UploadDocumentActionButton from './UploadDocumentActionButton/UploadDocumentActionButton';
import DragAndDrop from '../../../../components/DragAndDrop';
import {Button} from '../../../../componentsFormV2';

const ModalDocument = (props: ModalDocumentProps & RouteComponentProps) => {
    const RESULT_FIELDS_DEFAULT = ['fileName', 'size', 'fileType'];
    const [loading, setLoading] = useState(false);
    const [documentId] = useState(props.documentElement.id || 0);
    const [categoryType] = useState(props.documentElement.categoryType || 0);
    const [documentName, setDocumentName] = useState(props.documentElement.title || '');
    const [documentCode, setDocumentCode] = useState(
        (props.documentElement.file.length > 0
            ? props.documentElement.file[0].documentCode
            : props.documentElement.documentCode) || ''
    );
    const [parentId] = useState(props.documentElement.parentId || 0);
    const [parentTitle] = useState(props.documentElement.parentTitle || '');
    const [uploadedDocumentList, setUploadedDocumentList] = useState<Array<UploadedFileData>>(
        props.documentElement.file || []
    );
    const [notesOpened, setNotesOpened] = useState(false);
    const [lastUpdateDate, setLastUpdateDate] = useState(props.documentElement.lastUpdateDate || '');
    const [notShowLastUpdateDate, setNotShowLastUpdateDate] = useState(
        !props.documentElement.showLastUpdateDate || false
    );
    const [beginDate, setBeginDate] = useState(props.documentElement.beginDate || '');
    const [endDate, setEndDate] = useState(props.documentElement.endDate || '');
    const [fileName, setFileName] = useState(
        (props.documentElement.file.length > 0 && props.documentElement.file[0].fileName) || ''
    );
    const [fileUUID, setFileUUID] = useState(
        (props.documentElement.file.length > 0 && props.documentElement.file[0].uuid) || ''
    );
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [highlightErrors, setHighlightErrors] = useState(false);
    const [isModalErrorFileEncryptedOpen, setIsModalErrorFileEncryptedOpen] = useState(false);

    const deleteFile = (fileName: string) => {
        setUploadedDocumentList(uploadedDocumentList.filter(el => el.fileName !== fileName));
    };

    const actions = UploadDocumentActionButton(deleteFile);

    const closeModal = () => {
        if (uploadedDocumentList.length > 0 && uploadedDocumentList[0].uuid) {
            deleteFile(uploadedDocumentList[0].fileName);
        }
        props.close && props.close();
    };

    const toggleInformation = () => setNotesOpened(!notesOpened);

    const onDocumentNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        setDocumentName(name);
    };

    const onDocumentCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const code = event.target.value;
        setDocumentCode(code);
    };

    const onLastUpdateDateChange = (lastUpdateDate: string) => {
        setLastUpdateDate(lastUpdateDate);
    };

    const onCheckNotShowUpdateDate = () => {
        setNotShowLastUpdateDate(!notShowLastUpdateDate);
    };

    const onBeginDateChange = (beginDate: string) => {
        setBeginDate(beginDate);
    };

    const onEndDateChange = (endDate: string) => {
        setEndDate(endDate);
    };

    const arrayBufferToString = (buffer: ArrayBuffer) => {
        return new TextDecoder().decode(buffer);
    };

    const checkFile = (file: File) => {
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType && fileType.toLowerCase();
        const fileName = sanitize(file.name);
        if (
            fileTypeLowercase === 'pdf' ||
            fileTypeLowercase === 'vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            fileTypeLowercase === 'msword'
        ) {
            if (file.size > MAX_UPLOAD_FILE_SIZE) {
                setErrorMessage('The maximum document size for uploads is 10 MB. Please select a proper file.');
                setHighlightErrors(true);
            } else {
                const fileReader = new FileReader();
                fileReader.readAsBinaryString(file);
                let fileReaderResult = '';
                if (fileReader.result === null) {
                    fileReaderResult = '';
                } else if (fileReader.result instanceof ArrayBuffer) {
                    fileReaderResult = arrayBufferToString(fileReader.result);
                } else {
                    fileReaderResult = fileReader.result;
                }
                fileReader.onloadend = () => {
                    const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(fileReaderResult));
                    const documentList = uploadedDocumentList.slice(0);
                    let extension = '';
                    if (fileTypeLowercase === 'msword') {
                        extension = 'doc';
                    } else if (fileTypeLowercase === 'pdf') {
                        extension = 'pdf';
                    } else {
                        extension = 'docx';
                    }
                    documentList.push({
                        index,
                        file,
                        fileName,
                        size: file.size / 1024,
                        extension,
                        fileType: fileTypeLowercase === 'pdf' ? 'PDF' : 'WORD',
                        fileDate: moment().format('DD/MM/YYYY'),
                    });
                    setUploadedDocumentList(documentList);
                    setFileName(documentList[0].fileName);
                    setNotesOpened(false);
                };
            }
        } else {
            setErrorMessage('File Type is not valid. Please try again.');
            setHighlightErrors(true);
        }
    };

    const sendInBase64Format = (filename: string, file: Blob) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onloadend = function () {
            const base64data = fileReader.result;
            if (base64data !== null) {
                setLoading(true);
                apiSendTreeDocumentFile({filename, file})
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.error && jsonResponse.error === 'encrypted') {
                            setIsModalErrorFileEncryptedOpen(true);
                            setUploadedDocumentList([]);
                        } else {
                            setFileUUID(jsonResponse.fileUUID);
                            if (uploadedDocumentList.length > 0) uploadedDocumentList[0].uuid = jsonResponse.fileUUID;
                            setUploadedDocumentList(uploadedDocumentList);
                            highlightErrors && setHighlightErrors(false);
                        }
                    })
                    .catch(error => {
                        setErrorMessage(`error sending file: ${error}`);
                        setHighlightErrors(true);
                        uploadedDocumentList.length > 0 && deleteFile(uploadedDocumentList[0].fileName);
                    })
                    .finally(() => setLoading(false));
            }
        };
    };

    const handleDrop = (files: Array<File>, _event: React.ChangeEvent<HTMLInputElement>) => {
        checkFile(files[0]);
    };

    const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length > 0) {
            const file = event.target.files[0];
            event.target.value = '';
            checkFile(file);
        }
    };

    useEffect(() => {
        fileName.length > 0 &&
            uploadedDocumentList.length > 0 &&
            uploadedDocumentList[0].file &&
            sendInBase64Format(fileName, uploadedDocumentList[0].file);
    }, [fileName]);

    const prepareDocumentDataForSave = (): TreeElement => {
        return {
            id: documentId,
            order: props.documentElement.order || '0',
            documentCode,
            title: documentName,
            lastUpdateDate,
            beginDate,
            endDate,
            categoryType,
            rights: props.documentElement.rights || [],
            file:
                uploadedDocumentList.length === 1
                    ? [
                          {
                              uuid: fileUUID,
                              fileName,
                              extension: uploadedDocumentList[0].extension,
                              size: uploadedDocumentList[0].size,
                              fileType: uploadedDocumentList[0].fileType,
                              fileDate: uploadedDocumentList[0].fileDate,
                              documentCode,
                          },
                      ]
                    : [],
            level: props.documentElement.level || 1,
            parentId,
            parentTitle,
            isCategory: false,
            showLastUpdateDate: !notShowLastUpdateDate,
            children: props.documentElement.children || [],
        };
    };

    const getErrorMessagesForSave = () => {
        setHighlightErrors(false);
        const diffValidStartEndDate = moment(beginDate, 'DD/MM/YYYY').diff(moment(endDate, 'DD/MM/YYYY'), 'days');
        const errorMessages: ErrorMessages = {
            errorInput: [],
            errorLogic: [],
        };

        if (documentName.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a name for the document');
        }

        if (lastUpdateDate.length === 0 && notShowLastUpdateDate === false) {
            errorMessages.errorInput && errorMessages.errorInput.push('the last update of the document');
        }

        if (beginDate.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a begin date for the document');
        }

        if (endDate.length > 0 && diffValidStartEndDate > 0) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push('You have to type a begin date before the end date.');
        }

        return errorMessages;
    };

    const saveDocument = () => {
        const errorMessagesObj = getErrorMessagesForSave();
        let errorMessage = getConcatErrorMessage(errorMessagesObj);

        if (errorMessage.length > 0) {
            setErrorMessage(errorMessage);
            setHighlightErrors(true);
        }

        if (errorMessage.length === 0) {
            const documentData = prepareDocumentDataForSave();
            props.saveDocument && props.saveDocument(documentData);
        }
    };

    const getUploadDocumentArea = () => {
        return (
            <div
                className={uploadedDocumentList.length > 0 ? styles.uploadedFileContainer : styles.uploadFileContainer}
            >
                <hr />
                {uploadedDocumentList.length > 0 && (
                    <div className={styles.fileTableArea}>
                        <CustomTable
                            {...props}
                            notSortable={RESULT_FIELDS_DEFAULT}
                            tableName={'uploadDocument'}
                            tableType={'OBJECT'}
                            tableSource={uploadedDocumentList || []}
                            dataFilter={null}
                            id={'fileName'}
                            setLastCursor={null}
                            resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                            intl={props.intl}
                            formatFunctions={{
                                fileName: (fileName: string) => <div style={{maxWidth: 380}}>{fileName}</div>,
                                size: (size: number) => (
                                    <div className={styles.sizeColumnArea}>{`${Math.floor(size * 100) / 100} KB`}</div>
                                ),
                            }}
                            count={(uploadedDocumentList || []).length}
                            hideExcelButton={true}
                            noChangePageSize={true}
                            timestamp={Date.now()}
                            actions={actions}
                            forehandColumn={(rowObject: UploadedFileData) => (
                                <img src={getIcon(rowObject.extension || 'pdf')} alt={rowObject.extension} />
                            )}
                        />
                    </div>
                )}
                <div className={uploadedDocumentList.length > 0 ? styles.uploadedButtonArea : styles.uploadButtonArea}>
                    <input
                        type="file"
                        name="files[]"
                        data-ng-class="isAddDocumentDisabled() ? 'disabled': ''"
                        data-ng-disabled="isAddDocumentDisabled()"
                        id="upload"
                        style={{opacity: 0, width: 0}}
                        onChange={onFileUpload}
                        accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
                        disabled={uploadedDocumentList.length >= 1}
                    />
                    <label htmlFor={'upload'}>
                        <FormFooterButton
                            color={buttonColor.blue}
                            icon={faPlus}
                            disabled={uploadedDocumentList.length >= 1}
                        >{`Upload File`}</FormFooterButton>
                    </label>
                </div>
                <div style={{clear: 'both'}}>{}</div>
                <div>
                    <InputLink
                        label={`Notes`}
                        icon={notesOpened ? faChevronDown : faChevronRight}
                        clickAction={toggleInformation}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    {notesOpened && (
                        <BrowserInfo
                            uploadOnlyOneFile={true}
                            documentTypes={['pdf', 'docx', 'doc']}
                            notUpload={uploadedDocumentList.length >= 1}
                            intl={props.intl}
                            plus={undefined}
                        />
                    )}
                </div>
                <hr />
                <div style={{clear: 'both'}}>{}</div>
            </div>
        );
    };

    const getCategoryName = () => {
        let categoryName = 'Parent';
        switch (props.tag) {
            case 'templates':
            case 'qas':
            case 'rnd':
            case 'generalInformation':
                if (props.isParentAcategory) {
                    categoryName = 'Category Name';
                }
                break;
            case 'vademecum':
                categoryName = 'Category Name';
                break;
        }

        return categoryName;
    };

    const getFieldsArea = () => {
        return (
            <>
                <TextInput
                    value={props.tag === 'vademecum' ? 'Vademecum' : parentTitle}
                    outsideLabel={getCategoryName()}
                    outsideLabelWidth={160}
                    disabled={true}
                    double={true}
                />
                <div style={{clear: 'both'}}>{}</div>
                {props.tag === 'vademecum' && (
                    <React.Fragment>
                        <TextInput
                            value={parentTitle}
                            outsideLabel={`Parent`}
                            outsideLabelWidth={160}
                            disabled={true}
                            double={true}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                    </React.Fragment>
                )}
                <TextInput
                    value={documentCode}
                    outsideLabel={'Document Code'}
                    outsideLabelWidth={160}
                    onChange={onDocumentCodeChange}
                    double={true}
                />
                <div style={{clear: 'both'}}>{}</div>
                <TextInput
                    value={documentName}
                    outsideLabel={'Document Name'}
                    outsideLabelWidth={160}
                    onChange={onDocumentNameChange}
                    double={true}
                />
                <div style={{clear: 'both'}}>{}</div>
                <DateInput
                    changeDateFrom={onLastUpdateDateChange}
                    inputValueFrom={lastUpdateDate}
                    outsideLabel={'Last Update'}
                    outsideLabelWidth={160}
                    simple={true}
                />
                <Checkbox
                    clickAction={onCheckNotShowUpdateDate}
                    label={`do not show update date`}
                    value={notShowLastUpdateDate}
                    simple={true}
                />
                <div style={{clear: 'both'}}>{}</div>
                <DateInput
                    changeDateFrom={onBeginDateChange}
                    inputValueFrom={beginDate}
                    label={'Valid From'}
                    outsideLabel={'Validity Period'}
                    outsideLabelWidth={160}
                    simple={true}
                />
                <DateInput changeDateFrom={onEndDateChange} inputValueFrom={endDate} label={'To'} simple={true} />
                <div style={{clear: 'both'}}>{}</div>
                {getUploadDocumentArea()}
            </>
        );
    };

    const getModalFooterArea = () => (
        <>
            {highlightErrors ? (
                <Error>
                    <FontAwesomeIcon icon={faExclamationCircle as any} color={'white'} />
                    {` ${errorMessage}`}
                </Error>
            ) : null}
            <Button clickAction={props.close} icon={'close'} variation={'danger'}>
                {`Cancel`}
            </Button>
            <Button clickAction={saveDocument} icon={'arrowRight'}>
                {`Save Document`}
            </Button>
        </>
    );

    return (
        <>
            {isModalErrorFileEncryptedOpen ? (
                <ModalErrorVersion2
                    title={'Error'}
                    message={
                        'The selected file was encrypted and cannot be uploaded. Please choose a file without encryption.'
                    }
                    close={() => setIsModalErrorFileEncryptedOpen(false)}
                />
            ) : null}
            <DragAndDrop handleDrop={handleDrop}>
                <div className="modalDocument">
                    <ModalCustomVersion2
                        loading={loading}
                        close={closeModal}
                        header={`Document`}
                        body={getFieldsArea()}
                        footer={getModalFooterArea()}
                    />
                </div>
            </DragAndDrop>
        </>
    );
};

export default injectIntl(withRouter(ModalDocument));
