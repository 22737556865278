import React from 'react';
import styles from './ACAreaAdministratorContentsBottomCaption.module.scss';

const ACAreaAdministratorContentsBottomCaption = () => {
    return (
        <div>
            <fieldset className={styles.schedulerBorder}>
                <legend className={styles.legendSchedulerBorder}>
                    <i className="small" data-translate="">
                        <span className="ng-scope">{`Legend`}</span>
                    </i>
                </legend>
                <div className={styles.container}>
                    <div className={styles.recent}>{`Most Recent`}</div>
                </div>
            </fieldset>
        </div>
    );
};

export default ACAreaAdministratorContentsBottomCaption;
