import {getFetch, postFetch, API_SERVERLESS} from '../../../../../commonApi';
import {FaqsListFetchData} from '../Interfaces/FaqPagePVRCASELAWInterfaces';

export function apiFaqListFetch(): Promise<FaqsListFetchData> {
    return getFetch(`${API_SERVERLESS}/caselaw/faqslist`, true);
}

export function apiFaqSave(
    jsonData: {
        question: string;
        answer: string;
    }
): Promise<any> {
    return postFetch(`${API_SERVERLESS}/caselaw/createfaq`, true, jsonData).then(jsonResponse => {
        if (jsonResponse) {
            return jsonResponse;
        }
    });
}

export function apiFaqEdit(
    jsonData: {
        id: number;
        question: string;
        answer: string;
    }
) {
    return postFetch(`${API_SERVERLESS}/caselaw/editfaq`, true, jsonData).then(jsonResponse => {
        if (jsonResponse) {
            return jsonResponse;
        }
    });
}

export function apiFaqDelete(faqId: number) {
    return postFetch(`${API_SERVERLESS}/caselaw/deletefaq`, true, {id: faqId});
}
