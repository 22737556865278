import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';

function renderButton(popupContent: any, handler: any, icon: any, length?: any) {
    const arrowJsx = (popupContent: any) => (
        <a data-content={popupContent}>
            <FontAwesomeIcon icon={icon} />
        </a>
    );
    return {
        title: popupContent,
        icon: (object: any) => {
            switch (icon) {
                default:
                    return arrowJsx(popupContent);
            }
        },
        handler,
    };
}

export default function OnlineDelegationsActionButtons(onTableOpenModalConfirmDelegationDeleteClick: any) {
    const buttons = [];

    buttons.push(
        renderButton(
            'Delete',
            (specieId: any, rowObject: any) => onTableOpenModalConfirmDelegationDeleteClick(rowObject.delegationId),
            faTrashAlt
        )
    );

    return buttons;
}
