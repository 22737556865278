import React from 'react';
import InputContainer from '~componentsForm/InputContainer';

export default function ({double, oneLine, width, height}) {
    return (
        <InputContainer {...{double, width, height}} empty={true} oneLine={oneLine}>
            {}
        </InputContainer>
    );
}
