import React from 'react';
import CustomTable from '~components/CustomTable';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import {
    ICommunicationAttachmentResponse,
    ICommunicationMessageComment,
    ICommunicationMessageInternalRead,
    IDecodedJWT,
} from '../../types';
import InternalMessageFieldsAndAttachments from '../CommunicationCentreInternal/modals/InternalMessageFieldsAndAttachments';
import ModalMessageClassify from '../CommunicationCentreInternal/modals/ModalMessageClassify';
import {
    apiCommunicationCenterModalInternalReadDownloadDocument,
    apiCommunicationCentreModalInternalCommentCreate,
    apiCommunicationCentreModalInternalComments,
    apiCommunicationCentreModalInternalReadMessageRead,
    apiCommunicationCentreModalRebuildSummaryPDF,
} from './CCModalInternalReadService';
import cx from 'classnames';
import {getDecodedJWT} from '../../utils';
import {injectIntl} from 'react-intl';
import styles from './CCModalInternalRead.module.scss';
import {FORMAT_DATE_TIME_EASY, formatDateTimeEasy} from '~components/FormatFunctions';
import TextAreaInput from '~components/TextAreaInput';
import {ModalApplicationNumbers, ModalConfirmVersion2, ModalCustomVersion3} from '../../commonModals';
import {getPreSignedURLFetchRequest} from '../../utils/requests';
import {SERVER_INDEX_CONFIRMATION_OF_RECEIPT, WORKFLOW} from '../../utils/constants';
import {saveAs} from 'file-saver';
import {HeaderLoading, LoadingBar} from '../../componentsLayout';
import {Button} from '../../componentsFormV2';

export const TABS = {
    MESSAGE: 'MESSAGE',
    COMMENTS: 'COMMENTS',
    ALERTS: 'ALERTS',
};

export type TTabs = keyof typeof TABS;

interface IProps {
    close: () => any;
    communicationId: number;
    folder?: string | null;
    intl: any;
    onButtonReplyClick?: () => any;
    onButtonPreviewClick?: (forceUpdate?: boolean) => any;
    isPreviewMode?: boolean;
    initialActiveTab?: string;
    modalPreviewURL?: string;
    setModalPreviewURL?: any;
    setModalPreviewMessage?: (message: ICommunicationMessageInternalRead) => any;
}

// const getDirectoryValueByDirectoryId = (decodedJWT: IDecodedJWT | null, docmanDirectoryId: number | null) => {
//     if (!docmanDirectoryId || !decodedJWT) {
//         return '';
//     }
//     return (getSelectListOfDirectory(decodedJWT).find(el => parseInt(el.id) === docmanDirectoryId) || {}).value || '';
// };

// const getDocumentTypeValueByDocumentTypeId = (documentTypeId: number | null) => {
//     if (!documentTypeId) {
//         return '';
//     }
//     let foundDocumentTypeIdIndex = Object.values(PROFILES_DIRECTORIES_SUBTYPES_INTERNAL)
//         .flat(1)
//         .find(el => el.docTypeId === documentTypeId);
//     if (foundDocumentTypeIdIndex) {
//         return foundDocumentTypeIdIndex.docTypeName;
//     }
//     return '';
// };

interface IState {
    activeTab: string;
    isModalClassifyOpen: boolean;
    isModalConfirmNotReplyingToColleagueOpen: boolean;
    isModalConfirmRebuildSummary: boolean;
    loading: boolean;
    files: ICommunicationAttachmentResponse[];
    message: ICommunicationMessageInternalRead | null;
    modalApplicationNumbers: string[];
    timestamp: number;
    textAreaInputCommentValue: string;
    commentIsBeingAdded: boolean;
    comments: ICommunicationMessageComment[];
    commentsAreLoaded: boolean;
    commentsLoading: boolean;
}

class CCModalInternalRead extends React.Component<IProps, IState> {
    decodedJWT: IDecodedJWT | null = getDecodedJWT();
    originalFiles: ICommunicationAttachmentResponse[] = [];

    constructor(props: IProps) {
        super(props);
        this.state = {
            activeTab: TABS.MESSAGE,
            isModalClassifyOpen: false,
            isModalConfirmNotReplyingToColleagueOpen: false,
            isModalConfirmRebuildSummary: false,
            loading: false,
            files: [],
            message: null,
            modalApplicationNumbers: [],
            timestamp: Date.now(),
            textAreaInputCommentValue: '',
            commentIsBeingAdded: false,
            comments: [],
            commentsAreLoaded: false,
            commentsLoading: false,
        };
    }

    componentDidMount() {
        this.loadJSONs();
        if (this.props.initialActiveTab === TABS.COMMENTS) {
            this.onTabCommentsClick();
        }
    }

    loadJSONs = () =>
        this.setState(
            {
                loading: true,
            },
            () => {
                apiCommunicationCentreModalInternalReadMessageRead(this.props.communicationId).then(
                    (JSONResponse: {
                        files: ICommunicationAttachmentResponse[];
                        message: ICommunicationMessageInternalRead;
                    }) => {
                        const {files, message} = JSONResponse || {};
                        let filesWithoutConfirmationOfReceipt = [...(files || [])];
                        if (files.length) {
                            const fileIndexConfirmationOfReceipt = files.findIndex(
                                el => el.serverIndex === SERVER_INDEX_CONFIRMATION_OF_RECEIPT
                            );
                            fileIndexConfirmationOfReceipt !== -1 &&
                                filesWithoutConfirmationOfReceipt.splice(fileIndexConfirmationOfReceipt, 1);
                            if (files[files.length - 1].serverIndex === SERVER_INDEX_CONFIRMATION_OF_RECEIPT) {
                                this.originalFiles = [files[files.length - 1], ...files.slice(0, files.length - 1)];
                            } else {
                                this.originalFiles = [...files];
                            }
                        }
                        this.setState({
                            loading: false,
                            files: [...(filesWithoutConfirmationOfReceipt || [])],
                            message: message,
                        });
                        this.props.setModalPreviewMessage && this.props.setModalPreviewMessage(message);
                    }
                );
            }
        );

    openModalApplicationNumbers = (applicationNumbers: string[]) =>
        this.setState({modalApplicationNumbers: applicationNumbers});

    closeModalApplicationNumbers = () => this.setState({modalApplicationNumbers: []});

    onButtonActionOfModalConfirmNotReplyingColleagueClick = () =>
        this.props.onButtonReplyClick && this.props.onButtonReplyClick();

    onButtonReplyClick = () => {
        const {thirdPartyId: messageThirdPartyId} = this.state.message || {};
        const {thirdPartyId: loggedInUserThirdPartyId} = this.decodedJWT || {};
        if (
            loggedInUserThirdPartyId &&
            this.props.folder !== 'sent' &&
            parseInt(loggedInUserThirdPartyId) === messageThirdPartyId
        ) {
            this.setState({isModalConfirmNotReplyingToColleagueOpen: true});
        } else {
            this.props.onButtonReplyClick && this.props.onButtonReplyClick();
        }
    };

    onCloseModalConfirmNotReplyingColleague = () => this.setState({isModalConfirmNotReplyingToColleagueOpen: false});
    onOpenModalConfirmRebuildSummary = () => this.setState({isModalConfirmRebuildSummary: true});
    onRebuildSummaryPDF = () =>
        this.setState(
            {
                loading: true,
            },
            () => {
                apiCommunicationCentreModalRebuildSummaryPDF(this.props.communicationId).then(JSONResult => {
                    if (JSONResult && JSONResult.data === 'OK') {
                        this.setState({
                            loading: false,
                            isModalConfirmRebuildSummary: false,
                        });
                    }
                });
            }
        );

    onCloseModalConfirmRebuildSummary = () => {
        this.setState({isModalConfirmRebuildSummary: false});
    };

    onButtonClassifyClick = () => this.setState({isModalClassifyOpen: true});

    onCloseModalClassify = (refresh: boolean) => {
        let partialState: any = {};
        refresh && (partialState.message = null);
        this.setState({isModalClassifyOpen: false, ...partialState}, () => refresh && this.loadJSONs());
    };

    onTabMessageClick = () => this.setState({activeTab: TABS.MESSAGE});

    onTabAlertsClick = () => this.setState({activeTab: TABS.ALERTS});

    onTabCommentsClick = () => {
        this.setState({activeTab: TABS.COMMENTS});
        if (!this.state.commentsAreLoaded) {
            this.setState({commentsLoading: true}, () => {
                apiCommunicationCentreModalInternalComments(this.props.communicationId)
                    .then(JSONResponse => {
                        if (JSONResponse && JSONResponse.comments) {
                            this.setState({
                                comments: JSONResponse.comments,
                                commentsAreLoaded: true,
                            });
                        }
                    })
                    .then(() => this.setState({commentsLoading: false}));
            });
        }
    };

    onTextAreaInputCommentChange = ({
        target: {value: textAreaInputCommentValue},
    }: React.ChangeEvent<HTMLInputElement>): void => this.setState({textAreaInputCommentValue});

    onButtonPostCommentClick = () => {
        let comment = this.state.textAreaInputCommentValue;
        this.setState({commentIsBeingAdded: true, textAreaInputCommentValue: ''}, () => {
            apiCommunicationCentreModalInternalCommentCreate({
                body: comment,
                communicationId: this.props.communicationId,
            }).then(JSONResponse => {
                if (JSONResponse && JSONResponse.data === 'OK') {
                    return Promise.all([
                        apiCommunicationCentreModalInternalComments(this.props.communicationId),
                        apiCommunicationCentreModalInternalReadMessageRead(this.props.communicationId),
                    ])
                        .then(([JSONResponseComments, JSONResponseMessage]) => {
                            const stateUpdate: any = {};
                            if (JSONResponseComments && JSONResponseComments.comments) {
                                stateUpdate.comments = JSONResponseComments.comments;
                            }
                            if (JSONResponseMessage && JSONResponseMessage.message) {
                                stateUpdate.message = JSONResponseMessage.message;
                            }
                            this.setState({
                                ...stateUpdate,
                            });
                        })
                        .then(() => {
                            this.setState({
                                commentIsBeingAdded: false,
                                timestamp: Date.now(),
                            });
                        });
                }
            });
        });
    };

    onButtonSummaryClick = () => {
        const messageDoesNotHaveConfirmationOfReceipt =
            this.originalFiles.filter(el => el.serverIndex === SERVER_INDEX_CONFIRMATION_OF_RECEIPT).length === 0;
        if (messageDoesNotHaveConfirmationOfReceipt) {
            return alert('Message does not have confirmation of receipt in this environment');
        }
        const JSONRequest = {
            communicationId: this.props.communicationId,
            serverIndex: SERVER_INDEX_CONFIRMATION_OF_RECEIPT,
        };
        apiCommunicationCenterModalInternalReadDownloadDocument(JSONRequest).then(
            (response: {signedUrl: string; filename: string}) => {
                if (response && response.signedUrl && response.filename) {
                    const {signedUrl, filename} = response;
                    getPreSignedURLFetchRequest(signedUrl)
                        .then(response => response.blob())
                        .then(responseBlob => {
                            saveAs(responseBlob, filename);
                        });
                }
            }
        );
    };

    onButtonPreviewClick = () => {
        this.props.onButtonPreviewClick && this.props.onButtonPreviewClick();
    };

    render() {
        const messageDoesNotHaveAnyFiles = this.originalFiles.length === 0;
        const messageIsLoading = this.state.loading;
        // const commentsCount = this.state.message?.commentsCount;
        const tableData = this.state.comments.map(({body, contactShortKey, sentDateTime, contactId}) => ({
            message: body,
            shortKeyLeft: contactShortKey,
            shortKeyRight: contactShortKey,
            sentDateTime,
            contactId,
        }));
        const attachments = this.props.isPreviewMode ? this.originalFiles : this.state.files;

        return (
            <>
                {this.state.loading && <HeaderLoading />}
                {this.state.isModalConfirmNotReplyingToColleagueOpen ? (
                    <ModalConfirmVersion2
                        title={`Replying external`}
                        message={`You are not replying to your colleague, you are replying to an external third party. Are you sure you want to continue ?`}
                        buttonName={'Continue'}
                        action={this.onButtonActionOfModalConfirmNotReplyingColleagueClick}
                        close={this.onCloseModalConfirmNotReplyingColleague}
                    />
                ) : null}
                {this.state.isModalConfirmRebuildSummary ? (
                    <ModalConfirmVersion2
                        title={`Rebuild summary`}
                        message={`Do you want to rebuild the summary PDF?`}
                        buttonName={'Yes'}
                        action={this.onRebuildSummaryPDF}
                        close={this.onCloseModalConfirmRebuildSummary}
                    />
                ) : null}
                {this.state.modalApplicationNumbers.length ? (
                    <ModalApplicationNumbers
                        applicationNumbers={this.state.modalApplicationNumbers}
                        close={this.closeModalApplicationNumbers}
                    />
                ) : null}
                {this.state.isModalClassifyOpen ? (
                    <ModalMessageClassify close={this.onCloseModalClassify} message={this.state.message} />
                ) : null}
                <ModalCustomVersion3
                    isPreviewMode={this.props.isPreviewMode || false}
                    close={this.props.close}
                    header={`Reading message...`}
                    body={
                        <div>
                            {/*<div className={styles.header}>*/}
                            {/*    <div className={styles.leftPanel}>*/}
                            {/*<div*/}
                            {/*    className={cx(*/}
                            {/*        styles.menuItem,*/}
                            {/*        this.state.activeTab === TABS.MESSAGE && styles.menuItemActive*/}
                            {/*    )}*/}
                            {/*    onClick={this.onTabMessageClick}*/}
                            {/*>*/}
                            {/*    <div className={styles.menuIcon}>*/}
                            {/*        <FontAwesomeIcon icon={faFile as any} color={`green`} />*/}
                            {/*    </div>*/}
                            {/*    <div className={styles.menuLabel}>{`Message`}</div>*/}
                            {/*</div>*/}
                            {/*{this.props.hideTabComments ? null : (*/}
                            {/*    <div*/}
                            {/*        className={cx(*/}
                            {/*            styles.menuItem,*/}
                            {/*            this.state.activeTab === TABS.COMMENTS && styles.menuItemActive*/}
                            {/*        )}*/}
                            {/*        onClick={this.onTabCommentsClick}*/}
                            {/*    >*/}
                            {/*        <div className={styles.menuIcon}>*/}
                            {/*            <FontAwesomeIcon icon={faServer as any} color={`green`} />*/}
                            {/*        </div>*/}
                            {/*        <div*/}
                            {/*            className={styles.menuLabel}*/}
                            {/*            style={{minWidth: 117, textAlign: 'left'}}*/}
                            {/*        >{`Comments ${commentsCount ? `(${commentsCount})` : ''}`}</div>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            {/*<div*/}
                            {/*    className={cx(*/}
                            {/*        styles.menuItem,*/}
                            {/*        this.state.activeTab === TABS.ALERTS && styles.menuItemActive*/}
                            {/*    )}*/}
                            {/*    onClick={this.onTabAlertsClick}*/}
                            {/*>*/}
                            {/*    <div className={styles.menuIcon}>*/}
                            {/*        <FontAwesomeIcon icon={faBell as any} color={`green`} />*/}
                            {/*    </div>*/}
                            {/*    <div className={styles.menuLabel}>{`Alerts`}</div>*/}
                            {/*</div>*/}
                            {/*        <div style={{clear: 'both'}} />*/}
                            {/*    </div>*/}
                            {/*    <div style={{clear: 'both'}} />*/}
                            {/*</div>*/}
                            {/*<div style={{clear: 'both'}} />*/}
                            <div
                                style={{minHeight: 440, overflowX: 'hidden'}}
                                className={cx(this.state.activeTab !== TABS.MESSAGE && styles.tabIsHidden)}
                            >
                                <DataSheetFormSection title={'Original message'} disableToggle={true}>
                                    <InternalMessageFieldsAndAttachments
                                        attachments={attachments}
                                        communicationId={this.props.communicationId}
                                        intl={this.props.intl}
                                        message={this.state.message}
                                        openModalApplicationNumbers={this.openModalApplicationNumbers}
                                        isPreviewMode={this.props.isPreviewMode}
                                        setModalPreviewURL={this.props.setModalPreviewURL}
                                        modalPreviewURL={this.props.modalPreviewURL}
                                    />
                                </DataSheetFormSection>
                                {/*<DataSheetFormSection title={'Classify'}>*/}
                                {/*    <DataSheetFormFields*/}
                                {/*        label={'To do by'}*/}
                                {/*        data={this.state.message?.todoBy}*/}
                                {/*        ready={true}*/}
                                {/*        double={true}*/}
                                {/*        loading={!this.state.message}*/}
                                {/*    />*/}
                                {/*    {(this.state.message?.communicationTypes?.length*/}
                                {/*        ? this.state.message.communicationTypes*/}
                                {/*        : [*/}
                                {/*              {*/}
                                {/*                  docmanDirectory: this.state.message?.docmanDirectory,*/}
                                {/*                  docmanSubtypeOfDocument: this.state.message?.docmanSubtypeOfDocument,*/}
                                {/*              },*/}
                                {/*          ]*/}
                                {/*    ).map((communicationType, i) => (*/}
                                {/*        <React.Fragment key={`communicationType-field${Date.now()}`}>*/}
                                {/*            <DataSheetFormFields*/}
                                {/*                label={`Directory`}*/}
                                {/*                data={getDirectoryValueByDirectoryId(*/}
                                {/*                    this.decodedJWT,*/}
                                {/*                    communicationType.docmanDirectory || null*/}
                                {/*                )}*/}
                                {/*                ready={true}*/}
                                {/*                double={true}*/}
                                {/*                loading={!this.state.message}*/}
                                {/*            />*/}
                                {/*            <DataSheetFormFields*/}
                                {/*                label={'Communication type'}*/}
                                {/*                data={getDocumentTypeValueByDocumentTypeId(*/}
                                {/*                    communicationType.docmanSubtypeOfDocument || null*/}
                                {/*                )}*/}
                                {/*                ready={true}*/}
                                {/*                double={true}*/}
                                {/*                loading={!this.state.message}*/}
                                {/*            />*/}
                                {/*        </React.Fragment>*/}
                                {/*    ))}*/}
                                {/*</DataSheetFormSection>*/}
                            </div>
                            <div
                                style={{minHeight: '50vh', marginTop: -20}}
                                className={cx(this.state.activeTab !== TABS.COMMENTS && styles.tabIsHidden)}
                            >
                                {this.state.commentsLoading ? (
                                    <div style={{margin: 20}}>
                                        <LoadingBar />
                                    </div>
                                ) : null}
                                {!this.state.commentsLoading ? (
                                    <div className={styles.tableCommentsWrap}>
                                        <DataSheetFormSection title={'Original message'} disableToggle={true}>
                                            <DataSheetFormFields
                                                label={'Subject'}
                                                data={this.state.message?.messageSubject}
                                                ready={true}
                                                double={true}
                                                loading={!this.state.message}
                                            />
                                        </DataSheetFormSection>
                                        <div style={{clear: 'both'}} />
                                        <div
                                            style={{
                                                marginLeft: tableData.length ? 0 : 15,
                                                marginBottom: tableData.length ? 0 : 10,
                                            }}
                                        >
                                            <CustomTable
                                                {...this.props}
                                                tableName={'messageComments'}
                                                formatFunctions={{
                                                    shortKeyLeft: (shortKey: string, rowObject: any) =>
                                                        parseInt(this.decodedJWT ? this.decodedJWT.id : '') ===
                                                        rowObject.contactId ? (
                                                            <div className={styles.shortKeyWrap}>
                                                                <span className={styles.shortKey}>{shortKey}</span>
                                                            </div>
                                                        ) : null,
                                                    shortKeyRight: (shortKey: string, rowObject: any) =>
                                                        parseInt(this.decodedJWT ? this.decodedJWT.id : '') !==
                                                        rowObject.contactId ? (
                                                            <div className={styles.shortKeyWrap}>
                                                                <span className={styles.shortKey}>{shortKey}</span>
                                                            </div>
                                                        ) : null,
                                                    message: (message: string, rowObject: any) => (
                                                        <>
                                                            <div
                                                                className={cx(
                                                                    styles.messageWrap,
                                                                    parseInt(
                                                                        this.decodedJWT ? this.decodedJWT.id : ''
                                                                    ) === rowObject.contactId && styles.messageLeft,
                                                                    parseInt(
                                                                        this.decodedJWT ? this.decodedJWT.id : ''
                                                                    ) !== rowObject.contactId && styles.messageRight
                                                                )}
                                                            >
                                                                {message}
                                                                <div
                                                                    style={{
                                                                        fontSize: 14,
                                                                        fontWeight: 'bold',
                                                                        borderTop: '1px solid #cfcfcf',
                                                                    }}
                                                                >
                                                                    {formatDateTimeEasy(rowObject.sentDateTime)}
                                                                </div>
                                                            </div>
                                                        </>
                                                    ),
                                                    sentDate: FORMAT_DATE_TIME_EASY,
                                                }}
                                                tableType={'OBJECT'}
                                                tableSource={tableData}
                                                id={'uniqueId'}
                                                resultFieldsDefault={['shortKeyLeft', 'message', 'shortKeyRight']}
                                                intl={this.props.intl}
                                                hideExcelButton={true}
                                                timestamp={this.state.timestamp}
                                                notSortable={true}
                                                unlimited={true}
                                                hiddenHeader={true}
                                            />
                                        </div>
                                        <div className={styles.textAreaCommentWrap}>
                                            <TextAreaInput
                                                label={'Enter comment'}
                                                disabled={this.state.commentIsBeingAdded}
                                                dynamicHeight={true}
                                                onChange={this.onTextAreaInputCommentChange}
                                                value={this.state.textAreaInputCommentValue}
                                                width={550}
                                                rows={2}
                                            />
                                            <Button
                                                clickAction={this.onButtonPostCommentClick}
                                                icon={'arrowRight'}
                                                disabled={this.state.commentIsBeingAdded}
                                            >
                                                {`Post comment`}
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {/*<div*/}
                            {/*    style={{minHeight: '50vh', marginTop: -20}}*/}
                            {/*    className={cx(this.state.activeTab !== TABS.ALERTS && styles.tabIsHidden)}*/}
                            {/*>*/}
                            {/*    <CustomTable*/}
                            {/*        {...this.props}*/}
                            {/*        tableName={'messageAlerts'}*/}
                            {/*        tableType={'OBJECT'}*/}
                            {/*        tableSource={[*/}
                            {/*            {*/}
                            {/*                contactFullName: 'Juan Manuel Sanchez-Navas',*/}
                            {/*                searchName: 'Default parameters',*/}
                            {/*            },*/}
                            {/*            {*/}
                            {/*                contactFullName: 'Laetitia Denecheau',*/}
                            {/*                searchName: 'To do by me',*/}
                            {/*            },*/}
                            {/*            {*/}
                            {/*                contactFullName: 'Jens Wegner',*/}
                            {/*                searchName: 'Default parameters',*/}
                            {/*            },*/}
                            {/*        ]}*/}
                            {/*        id={'contactFullName'}*/}
                            {/*        resultFieldsDefault={['contactFullName', 'searchName']}*/}
                            {/*        intl={this.props.intl}*/}
                            {/*        hideExcelButton={true}*/}
                            {/*        // timestamp={this.state.timestamp}*/}
                            {/*        notSortable={true}*/}
                            {/*        unlimited={true}*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>
                    }
                    footer={
                        <div className={styles.footerWrap}>
                            <div className={styles.footerLeft}>
                                <Button
                                    variation={'secondary'}
                                    clickAction={this.onButtonPreviewClick}
                                    disabled={messageDoesNotHaveAnyFiles || messageIsLoading}
                                    isCheckboxChecked={this.props.isPreviewMode}
                                >{`Preview`}</Button>
                                {WORKFLOW ? (
                                    <Button
                                        variation={'tertiary'}
                                        clickAction={this.onButtonClassifyClick}
                                        disabled={messageIsLoading}
                                    >{`Classify`}</Button>
                                ) : null}
                                <Button
                                    variation={'secondary'}
                                    clickAction={this.onButtonSummaryClick}
                                    onHoldAction={this.onOpenModalConfirmRebuildSummary}
                                    disabled={messageIsLoading}
                                >{`Summary`}</Button>
                            </div>
                            <div className={styles.footerRight}>
                                <Button
                                    variation={'danger'}
                                    clickAction={this.props.close}
                                    icon={'close'}
                                >{`Close`}</Button>
                                <Button
                                    clickAction={this.onButtonReplyClick}
                                    disabled={messageIsLoading}
                                    icon={'arrowRight'}
                                >{`Reply`}</Button>
                            </div>
                        </div>
                    }
                />
            </>
        );
    }
}

export default injectIntl(CCModalInternalRead);
