import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export function apiCommunicationCenterDownloadDocument({
    communicationId,
    serverIndex,
}: {
    communicationId: number;
    serverIndex: string;
}) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentre/v3/downloadDocument/${communicationId}/${serverIndex}`,
        true
    );
}

export function apiCommunicationCenterModalClientReadMessageOpenedPreview(communicationId: number) {
    return postFetch(`${API_SERVERLESS}/communicationCentreModalClient/v4/CCMCRMOP/read/messageOpenedPreview`, true, {
        communicationId,
    });
}
