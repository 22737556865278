import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';

function renderButton(popupContent: any, handler: any, icon: any) {
    return {
        title: popupContent,
        icon: () => (
            <a data-content={popupContent}>
                <FontAwesomeIcon icon={icon} />
            </a>
        ),
        handler,
    };
}

export default function CCModalClientReplyActionButtons(deleteFile: any) {
    const buttons = [];
    buttons.push(renderButton('Delete', (labelId: any, rowObject: any) => deleteFile(rowObject.index), faTrashAlt));
    return buttons;
}
