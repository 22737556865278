import React from 'react';
import styles from './ModalDenominationStatusInfoVersion2.module.scss';
import ModalCustomVersion2 from './ModalCustomVersion2';
import {Button} from '../componentsFormV2';

interface IProps {
    close?: any;
}

interface IState {}

export default class ModalDenominationStatusInfoVersion2 extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    close = () => {
        this.props.close && this.props.close();
    };

    render() {
        return (
            <ModalCustomVersion2
                close={this.close}
                header={`Explanations on denominations status`}
                body={
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>{`Status`}</th>
                                <th>{`Definition`}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{`Today's proposal`}</td>
                                <td>
                                    {`A denomination proposal which can be modified/replaced/deleted by the client on the same day of its submission only (before the night processing and transfer of the proposal to the CPVO)`}
                                </td>
                            </tr>
                            <tr>
                                <td>{`Under preparation`}</td>
                                <td>
                                    <ul>
                                        <li>
                                            {`A proposal has been received, but is awaiting the daily testing on similarity in Variety Finder during the night following the reception date`}
                                        </li>
                                        <li>
                                            {`The nightly testing program has been executed for this proposal, which is awaiting analysis by the expert`}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>{`Ready for publication / Published`}</td>
                                <td>
                                    {`Awaiting publication in our next Gazette / Recently published, awaiting legal 3 month-period for potential objections`}
                                </td>
                            </tr>
                            <tr>
                                <td>{`Observation`}</td>
                                <td>
                                    {`A possible impediment to the suitability of the variety denomination has been detected and an observation is being/has been sent to the applicant`}
                                </td>
                            </tr>
                            <tr>
                                <td>{`Objection`}</td>
                                <td>
                                    {`An objection has been received on the variety denomination by a third party and an objection is being/has been sent to the applicant`}
                                </td>
                            </tr>
                            <tr>
                                <td>{`Published more than 3 months ago`}</td>
                                <td>
                                    {`The variety denomination has been published in the Gazette and the three months’ waiting period for remarks after the publication of the proposal has passed without the Office having received any observations or objections`}
                                </td>
                            </tr>
                            <tr>
                                <td>{`Rejected/Withdrawn`}</td>
                                <td>
                                    {`The applicant has changed the variety denomination proposal for this application (either because of an impediment, or because of his free will); this denomination is no longer valid`}
                                </td>
                            </tr>
                            <tr>
                                <td>{`To be proposed`}</td>
                                <td>
                                    {`A denomination still needs to be proposed for this valid application; the time necessary to come to a valid proposal will take up minimum 5 months, so that the applicant should make sure to submit a proposal at least 5 months before the foreseen end date of the technical examination`}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
                footer={
                    <>
                        <Button variation={'danger'} clickAction={this.close} icon={'close'}>{`Close`}</Button>
                    </>
                }
            />
        );
    }
}
