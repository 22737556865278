import crossFetch from 'cross-fetch';
import {API_SERVERLESS, getFetch} from '../commonApi';

export const getPreSignedURLFetchRequest = preSignedURL => {
    return (window.fetch || crossFetch)(
        preSignedURL,
        // 'https://cpvo-documents.s3.eu-west-3.amazonaws.com/dms/GREEND/Device17/OSS0003/A0002760/0002759029?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIAVKHRYI7EIP7WN4HJ%2F20221202%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20221202T161947Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJH%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCWV1LXdlc3QtMyJHMEUCIDmELvLPtTQf2UGgCAijc6fyuEnpYaBoHtHUcKjd01qAAiEAmWNSotfTwt0B9qTF%2F1G5uOW4T3S8I6tplbof0tTW2ugqtAMIqf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARACGgwzNjU1NzkyMjUwMzIiDJLjsa3WtS0ralsOuyqIAyoYXcvccgQrgh9xoiuD%2FeNf%2Bp2JnQR9Utq4INOXmSgBHSHAvV2hyMH7e1VdQj59VIycyjHAYgqxgfUaduARK8ZRDqkXjjAAcX1JAyQAKqahYyTEPEgOY70mOFxL7jP%2BoBvWN%2FonLyVVS5X615aM5UAx8%2Farvn08ZfNmRzaW4fmrEo2Sj4L65nCZUtpoiOXUp6l7tMP278lAvucjZiMbB%2Fs%2BuusFZs5Ekvl8kKWXrQy72vwTFqms9a3IUuq5zVzyCLpOJCt9%2Bw6QMb5OuBiVTqKDirA4tL1qyHPpgtjWEfDrfkF0v5SIKZAppcKyMgBQkuNjHAcRv1eDEWqj2fXwYSYhQR0EhSDoEM3TnK81Ts7ePRMR0tENgLRRm0Xmc9YPe19ZKfOis2PK7VOOu1HQbNZj9p%2Fj9K6RBiYqG75ktnXj07ivjM3hRWp99XEaNpDn7Ks7diytr7HISjRH9tMUh6X5lrZKP2Hy29MfThkHXS2oyritvyHvatc%2B3Wm5Lz0cbRuu8MgWQ%2FCjML7KqJwGOp0BR5AXY97XS3OOMWYrP0cKgTGjLbYnGRx8UrlTuN2ofOmHtzbxq8pVmqw85nmZKo9H%2BrhPOzmdg8dgprFwwsBtZiVphRjC3y0m%2F1ylq%2BHsNt6ZYk7vCoBLVUfvyzKH2Q%2FfAxxbwXWX6gZCKeXowNUpnw8%2BX9Rw41Z80h3IH8ylomwhelplxqvie3QmwsBX6bELl0TGVAzWnvEqUgOmkQ%3D%3D&X-Amz-Signature=ceafbfd4f5dd985cb1d9dd9a18e7cb52c137cc5ba9821b5d5b4c33f37b911794&X-Amz-SignedHeaders=host&x-id=GetObject',
        Object.assign({
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrer: 'no-referrer',
            body: null,
        })
    );
};

export const computeGetUploadPreSignedURLPromises = ({documentList = {}}) => {
    const getPreSignedURLEndpoint = `${API_SERVERLESS}/common/v3/uploadPresignedUrl`;
    if (!Object.keys(documentList).length) {
        return getFetch(getPreSignedURLEndpoint, true);
    } else {
        return documentList.map(() => getFetch(getPreSignedURLEndpoint, true));
    }
};

export const postFileToPreSignedURLRequest = ({preSignedURL, file} = {}) => {
    return fetch(preSignedURL, {
        method: 'PUT',
        body: file?.file || file,
    });
};

export const computePostFilesToPreSignedURLPromises = ({getUploadPreSignedURLResponses, documentList} = {}) => {
    return getUploadPreSignedURLResponses.map(({presignedUrl} = {}, j) =>
        postFileToPreSignedURLRequest({
            preSignedURL: presignedUrl,
            file: documentList[j],
        })
    );
};
