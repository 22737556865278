import React from 'react';
import {ArrowContainer, Popover} from 'react-tiny-popover';
import cx from 'classnames';
import styles from './CustomPopoverCheckbox.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfo} from '@fortawesome/free-solid-svg-icons';

export default class CustomPopoverInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isPopoverOpen: false};
    }

    onButtonClick = () => this.setState({isPopoverOpen: true});

    render() {
        const {content} = this.props;
        const {isPopoverOpen} = this.state;
        return (
            <Popover
                isOpen={this.state.isPopoverOpen}
                positions={'right'}
                onClickOutside={() => this.setState({isPopoverOpen: !isPopoverOpen})}
                content={({position, childRect, popoverRect}) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={'white'}
                        arrowSize={5}
                        arrowStyle={{
                            opacity: 0.7,
                        }}
                    >
                        <div onClick={() => this.setState({isPopoverOpen: !isPopoverOpen})}>
                            <div
                                className="custom-popover"
                                style={{
                                    background: 'white',
                                    color: 'black',
                                    padding: '10px',
                                    borderRadius: '4px',
                                    maxWidth: '250px',
                                    opacity: 0.9,
                                    zIndex: 99,
                                    position: 'relative',
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    borderColor: '#ccc',
                                }}
                            >
                                {content}
                            </div>
                        </div>
                    </ArrowContainer>
                )}
            >
                <button type="button" className={cx(styles.button, styles.buttonInfo)} onClick={this.onButtonClick}>
                    <FontAwesomeIcon icon={faInfo} color="green" />
                </button>
            </Popover>
        );
    }
}
