import React from 'react';
import {getMandatoryAsterisk} from '~utils/format';
import {checkIfQuestionIsMandatoryInCurrentGroupIteration} from '../questions/utils';
import {ModalConfirmVersion2} from '../../../commonModals';
import ResponseWrapperModalRemedyResponse, {REMEDY_TYPES} from './ResponseWrapper/ResponseWrapperModalRemedyResponse';
import {
    IGroup,
    IGroupQuestion,
    IGroupQuestionResponse,
    IGroupQuestionResponseLogic,
    IScreenData,
    IStateResponsesClient,
} from '../../../types';

export interface IWizardResponseWrapperProps {
    PDFVersion: number;
    assistedEntries: any;
    applicationCode: string;
    editable: boolean;
    formId: number;
    intl: any;
    isRemedyStarted: boolean;
    isShortDisplay?: boolean;
    isPDFVersionAndQuestionIsTableType?: boolean;
    key: number;
    mock: any;
    groupIteration: number;
    group: IGroup;
    steps: IGroup[];
    response: IGroupQuestionResponse;
    responseIteration?: any;
    question: IGroupQuestion;
    questionCode?: string;
    questionId: number;
    questionKeyWithIteration: string;
    questionMandatory: boolean;
    questionStatus: number;
    questionType: string;
    questionnaireLanguage: string;
    responsesClientHash: any;
    screenData: IScreenData;
    type?: any;
    twoInputsPerLine?: boolean;
    updateScreenData: (arg0: Partial<IScreenData>, arg1?: any) => any;
}

export interface IWizardResponseWrapperState {
    isCurrentResponseDisabledDueToLogicDisabledOfOtherResponse: boolean;
    isModalRemedyResponseOpen: boolean;
    modalConfirmDeleteAttachments: any;
    modalConfirmDeleteAttachmentsUpdatedResponsesClient: any;
}

class ResponseWrapper<
    P extends IWizardResponseWrapperProps,
    S extends IWizardResponseWrapperState
> extends React.Component<P, S> {
    responseKeyWithIteration: any;

    constructor(props: P) {
        super(props);
        this.responseKeyWithIteration = `${props.questionKeyWithIteration}_R${props.response && props.response.id}_${
            props.responseIteration || 1
        }`;
        this.setState({
            isCurrentResponseDisabledDueToLogicDisabledOfOtherResponse: false,
            isModalRemedyResponseOpen: false,
            modalConfirmDeleteAttachments: null,
            modalConfirmDeleteAttachmentsUpdatedResponsesClient: null,
        });
    }

    showModalConfirmRemoveAttachments = (updatedResponsesClient: IStateResponsesClient) =>
        this.setState({
            modalConfirmDeleteAttachments: true,
            modalConfirmDeleteAttachmentsUpdatedResponsesClient: updatedResponsesClient,
        });

    closeModalConfirmDeleteAttachments = () =>
        this.setState({
            modalConfirmDeleteAttachments: false,
            modalConfirmDeleteAttachmentsUpdatedResponsesClient: null,
        });

    checkAndUpdateIfCurrentResponseIsDisabledDueToLogicDisabledOfOtherResponse = () => {
        const {responsesClient} = this.props.screenData || {};
        const {steps} = this.props;
        this.setState(
            {
                isCurrentResponseDisabledDueToLogicDisabledOfOtherResponse: false,
            },
            () => {
                Object.entries(responsesClient).forEach(([key, value]) => {
                    let isTheResponseClientInTheSameGroupOrTemplateIterationAsCurrentResponse =
                        parseInt(key.split('_')[1]) === parseInt(this.getResponseKey().split('_')[1]);
                    if (
                        key.indexOf('conf') === -1 &&
                        key.indexOf('ra') === -1 &&
                        key.indexOf('upl') === -1 &&
                        isTheResponseClientInTheSameGroupOrTemplateIterationAsCurrentResponse
                    ) {
                        let isResponseClientRadioType = key.split('_').length === 4;
                        let isResponseClientCheckboxType = value === 1;
                        let groupOrTemplateId = parseInt((key || '').split('_')[0].replace('GR', '').replace('T', ''));
                        let questionId = parseInt(key.split('_')[2].replace('Q', ''));
                        let groupOrTemplateResponseBelongs = steps.find(el =>
                            key.indexOf('GR') > -1 ? el.id === groupOrTemplateId : el.templateId === groupOrTemplateId
                        );
                        let questionResponseBelongs: any =
                            (groupOrTemplateResponseBelongs &&
                                (groupOrTemplateResponseBelongs.questions || []).find(el => el.id === questionId)) ||
                            {};
                        let responseId: any, response;
                        if (isResponseClientRadioType) {
                            responseId = parseInt((value as string).split('_')[4].replace('R', ''));
                        } else if (isResponseClientCheckboxType || value) {
                            responseId = parseInt(key.split('_')[4].replace('R', ''));
                        }
                        response = (questionResponseBelongs.responses || []).find(
                            (el: IGroupQuestionResponse) => el.id === responseId
                        );
                        const logicsDisabled: IGroupQuestionResponseLogic[] =
                            (response &&
                                response.logics &&
                                (response.logics || []).filter(
                                    (el: IGroupQuestionResponseLogic) => el.type === 'disabled'
                                )) ||
                            [];
                        logicsDisabled.forEach(logicDisabled => {
                            if (logicDisabled && logicDisabled.responseIdDest === this.props.response.id) {
                                if (!this.state.isCurrentResponseDisabledDueToLogicDisabledOfOtherResponse) {
                                    this.setState({
                                        isCurrentResponseDisabledDueToLogicDisabledOfOtherResponse: true,
                                    });
                                    let updatedResponseClient = this.getCopyOfResponsesClient();
                                    if (this.props.question && this.props.question.type === 'text') {
                                        if (updatedResponseClient[this.getResponseKey()]) {
                                            delete updatedResponseClient[this.getResponseKey()];
                                            delete updatedResponseClient[`ra${this.getResponseKey()}`];
                                            this.updateResponsesClient(updatedResponseClient);
                                        }
                                    }
                                    if (this.props.question && this.props.question.type === 'radio') {
                                        let questionKeyWithIteration = this.props.questionKeyWithIteration;
                                        if (updatedResponseClient[questionKeyWithIteration] === this.getResponseKey()) {
                                            delete updatedResponseClient[questionKeyWithIteration];
                                            delete updatedResponseClient[`ra${questionKeyWithIteration}`];
                                            this.updateResponsesClient(updatedResponseClient);
                                        }
                                    }
                                    if (this.props.question && this.props.question.type === 'checkbox') {
                                        let checkboxPayloadKey = this.getResponseKey(); //e.g. GR25_1_Q11492_1_R451_1: 1
                                        if (updatedResponseClient[checkboxPayloadKey] === 1) {
                                            delete updatedResponseClient[checkboxPayloadKey];
                                            delete updatedResponseClient[`ra${checkboxPayloadKey}`];
                                            this.updateResponsesClient(updatedResponseClient);
                                        }
                                    }
                                    if (this.props.question && this.props.question.type === 'table') {
                                        if (updatedResponseClient[this.getResponseKey()]) {
                                            Object.keys(updatedResponseClient).forEach(k => {
                                                if (k.indexOf(this.props.questionKeyWithIteration) !== -1) {
                                                    delete updatedResponseClient[k];
                                                    delete updatedResponseClient[`ra${k}`];
                                                }
                                            });
                                            this.updateResponsesClient(updatedResponseClient);
                                        }
                                    }
                                }
                            }
                        });
                    }
                });
            }
        );
    };

    getCopyOfResponsesClient = () => {
        let responsesClient = (this.props.screenData && this.props.screenData.responsesClient) || {};
        return {...responsesClient};
    };

    updateResponsesClient = (updatedResponsesClient: IStateResponsesClient, callback?: any) => {
        this.props.updateScreenData &&
            this.props.updateScreenData({responsesClient: updatedResponsesClient, hash: Date.now()}, callback);
    };

    getResponseKey = () =>
        `${this.props.questionKeyWithIteration}_R${this.props.response && this.props.response.id}_${
            this.props.responseIteration || 1
        }`;

    getResponseRemarkKey = () => {
        return `ra${this.getResponseKey()}`;
    };

    getResponseRemarkAnswer = () => {
        const responseRemarkCode = this.getResponseRemarkKey();
        return (
            this.props.screenData &&
            this.props.screenData.responsesClient &&
            this.props.screenData.responsesClient[responseRemarkCode]
        );
    };

    getResponseAnswer = () =>
        this.props.screenData &&
        this.props.screenData.responsesClient &&
        this.props.screenData.responsesClient[this.responseKeyWithIteration];

    getResponseLabel = () => {
        const {response} = this.props;
        let label = response.message;
        if (response.score && response.score > 0) {
            label = `${response.score} - ${label}`;
        }
        return label;
    };

    isResponseMandatory() {
        const {response} = this.props;
        const {logics} = response || {};
        let responseHasOptionalLogicType = (logics || []).find(logic => logic.type === 'optional');
        let responseHasHiddenLogicType = (logics || []).find(logic => logic.type === 'hidden');
        if (responseHasOptionalLogicType || responseHasHiddenLogicType) {
            return false;
        }
        return checkIfQuestionIsMandatoryInCurrentGroupIteration({
            question: this.props.question,
            groupIteration: this.props.groupIteration,
            responsesClient: this.props.screenData.responsesClient,
            steps: this.props.steps,
        });
    }

    getLabelWithHTML() {
        const {response} = this.props;
        const {message} = response || {};
        const messageHTML = (() => (
            /* eslint-disable-next-line react/no-danger */
            <div dangerouslySetInnerHTML={{__html: message}} style={{display: 'inline-block'}} />
        ))();
        return this.isResponseMandatory() ? getMandatoryAsterisk(messageHTML) : messageHTML;
    }

    updateCurrentResponseAnswer = (
        answer: string,
        callback?: any,
        extraResponsesClient = {},
        responsesClientKeysToDelete: any[] = []
    ) => {
        let updatedResponsesClient = this.getCopyOfResponsesClient();
        let responseKey = this.getResponseKey();
        if (answer === '') {
            delete updatedResponsesClient[responseKey];
        } else {
            updatedResponsesClient[responseKey] = answer;
        }
        responsesClientKeysToDelete.forEach(key => delete updatedResponsesClient[key]);
        this.updateResponsesClient({...updatedResponsesClient, ...extraResponsesClient}, callback);
    };

    checkIfResponseHasMandatoryError = (responseIteration = 1) => {
        const {question, questionKeyWithIteration} = this.props;
        let tableIterationCheck = true;
        if (responseIteration > 1) {
            tableIterationCheck = !!question.responses
                .map(response => `${questionKeyWithIteration}_R${response.id}_${responseIteration}`)
                .find(responseKey => this.getCopyOfResponsesClient()[responseKey]);
        }
        return tableIterationCheck && this.isResponseMandatory() && !this.getResponseAnswer();
    };

    checkIfUserHasClickedOnSaveAndValidate = () => {
        return this.props.screenData && this.props.screenData.hasClickedOnSaveAndValidate;
    };

    isRemarkAllowed = () => {
        const {remarkAllowed} = this.props.response || {};
        return !!remarkAllowed;
    };
    isRemarkMandatory = () => {
        const {remarkMandatory} = this.props.response || {};
        return !!remarkMandatory;
    };

    checkIfQuestionHasRemarkMandatoryError = () => {
        return (
            this.checkIfUserHasClickedOnSaveAndValidate() &&
            this.isRemarkAllowed() &&
            this.isRemarkMandatory() &&
            !this.getResponseRemarkAnswer()
        );
    };

    checkIfResponseLabelShouldTakeTheWholeLineAndNotWrap = () => {
        const {question} = this.props;
        const {type: questionType, responses: questionResponses} = question || {};
        if (questionType === 'checkbox' || questionType === 'radio') {
            const atLeastOneResponseHasRemarkMessageOrRemarkTextarea = (questionResponses || []).find(
                response => response.remarkAllowed === true || !!response.messageRemark
            );
            return !atLeastOneResponseHasRemarkMessageOrRemarkTextarea;
        }
        return false;
    };

    checkIfTranslationIsMissing = () =>
        this.props.editable && this.props.response && parseInt(this.props.response.messageIsDef) === 1;

    checkIfResponseRemarkTranslationIsMissing = () =>
        this.props.editable && this.props.response && parseInt(this.props.response.messageRemarkIsDef) === 1;

    getRemarkTextAreaNumberOfRows = () => {
        let remarkTextAreaNumberOfRows = 4;
        const {logicParam} =
            ((this.props.response && this.props.response.logics) || []).find(response =>
                response.type === 'area' &&
                ['radio', 'checkbox'].includes(this.props.question && this.props.question.type)
                    ? 1
                    : response.applyToRemark === 1
            ) || {};
        if (logicParam && logicParam.indexOf('rows:') > -1 && logicParam.split('rows:').length > 1) {
            remarkTextAreaNumberOfRows = parseInt(logicParam.split('rows:')[1]);
        }
        return remarkTextAreaNumberOfRows;
    };

    getRemedyType = () => {
        let LOCAL_STORAGE_KEY = `REMEDY_${this.props.applicationCode}`;
        let storedRemedy = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
        if (!storedRemedy || !storedRemedy[this.getResponseKey()]) {
            return false;
        } else {
            return storedRemedy[this.getResponseKey()].type;
        }
    };

    checkIfResponseHasRemedyError = () => {
        let LOCAL_STORAGE_KEY = `REMEDY_${this.props.applicationCode}`;
        let storedRemedy = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
        if (!storedRemedy || !storedRemedy[this.getResponseKey()]) {
            return false;
        } else {
            return REMEDY_TYPES[storedRemedy[this.getResponseKey()].type];
        }
    };

    checkIfRemedyResponseExists = () => {
        let LOCAL_STORAGE_KEY = `REMEDY_${this.props.applicationCode}`;
        let storedRemedy = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
        if (!storedRemedy) {
            return false;
        }
        return !!storedRemedy[this.getResponseKey()];
    };

    getResponseOrderForPDF = () => {
        return `${this.props.question.order}_${this.props.response.order}`;
    };

    onRemarkChange = ({target: {value: remarkText}}: React.ChangeEvent<HTMLInputElement>) => {
        let updatedResponses = this.getCopyOfResponsesClient();
        updatedResponses[this.getResponseRemarkKey()] = remarkText;
        this.updateResponsesClient(updatedResponses);
    };

    onButtonAddResponseToRemedyClick = () => {
        let LOCAL_STORAGE_KEY = `REMEDY_${this.props.applicationCode}`;
        let storedRemedy = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
        if (!storedRemedy) {
            storedRemedy = {};
        }
        if (!storedRemedy[this.getResponseKey()]) {
            storedRemedy[this.getResponseKey()] = {
                type: '',
                details: '',
                uniqueId: this.getResponseKey(),
            };
        }
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storedRemedy));
        this.setState({
            isModalRemedyResponseOpen: true,
        });
    };

    onCloseModalRemedyResponse = () => this.setState({isModalRemedyResponseOpen: false});

    onConfirmRemoveAttachmentsAction = () => {
        this.updateResponsesClient(this.state.modalConfirmDeleteAttachmentsUpdatedResponsesClient, () => {
            this.setState({
                modalConfirmDeleteAttachments: false,
                modalConfirmDeleteAttachmentsUpdatedResponsesClient: null,
            });
        });
    };

    render() {
        return (
            <>
                {this.state.isModalRemedyResponseOpen ? (
                    <ResponseWrapperModalRemedyResponse
                        close={this.onCloseModalRemedyResponse}
                        applicationCode={this.props.applicationCode}
                        responseKey={this.getResponseKey()}
                    />
                ) : null}
                {this.state.modalConfirmDeleteAttachments && (
                    <ModalConfirmVersion2
                        title={`Removing Attachments`}
                        message={`Some question(s) that include(s) attachments won't be visible due to the selected response answer. Do you confirm to remove attachments ?`}
                        buttonName={'Yes'}
                        action={this.onConfirmRemoveAttachmentsAction}
                        close={this.closeModalConfirmDeleteAttachments}
                    />
                )}
            </>
        );
    }
}

export default ResponseWrapper;
