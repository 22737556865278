import React, {useState} from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import TextAreaInput from '~components/TextAreaInput';
import styles from './ModalLogicType.module.scss';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeShowQuestion = ({close, onCreateOrUpdateLogicType, responseLogicValues, responseLogicType}) => {
    const {logicParam, applyToRemark} = responseLogicValues || {};
    const {defaultParam, name} = responseLogicType || {};
    const [paramValue, setParamValue] = useState(logicParam !== undefined ? logicParam : null);
    const onParamValueChange = ({target: {value: param}}) => setParamValue(param);

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <div className={styles.modalBody}>
                    {defaultParam && (
                        <div>
                            <TextAreaInput
                                label="Default Parameters"
                                value={defaultParam}
                                width={550}
                                rows={7}
                                disabled={true}
                            />
                        </div>
                    )}
                    <div>
                        <TextAreaInput
                            label="Parameters"
                            value={paramValue}
                            onChange={onParamValueChange}
                            width={550}
                            rows={7}
                        />
                    </div>
                    {/*<div>*/}
                    {/*    <Checkbox*/}
                    {/*        clickAction={onApplyToRemarkChange}*/}
                    {/*        label={`Apply to remark if exist`}*/}
                    {/*        value={applyToRemarkValue}*/}
                    {/*        simple={true}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
            }
            footer={
                <>
                    <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                    <Button
                        icon={'arrowRight'}
                        clickAction={() =>
                            onCreateOrUpdateLogicType(paramValue, applyToRemark !== undefined ? applyToRemark : false)
                        }
                    >{`Save`}</Button>
                </>
            }
        >
            <div style={{clear: 'both'}} />
        </ModalCustomVersion2>
    );
};

export default ModalLogicTypeShowQuestion;
