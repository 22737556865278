import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt, faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {IDocumentSignApplication} from '../../types';

function renderButton(popupContent: any, handler: any, icon: any) {
    return {
        title: popupContent,
        icon: (rowObject: any) => {
            const {type} = rowObject || {};
            if (type !== 'AF' && type !== 'TQ') {
                return <span />;
            }
            return (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon} />
                </a>
            );
        },
        handler,
    };
}

export default function OnlineSignApplicationActions(goToFormDraft: (rowObject: IDocumentSignApplication) => void) {
    const buttons = [];
    buttons.push(
        renderButton(
            'Open in a new tab',
            (labelId: any, rowObject: IDocumentSignApplication) => {
                let onlinePdfURL = `/onlinePdf?vc=${rowObject.verificationCode}`;
                window.open(onlinePdfURL, '_blank');
            },
            faExternalLinkAlt
        )
    );
    buttons.push(
        renderButton(
            'Edit form',
            (labelId: any, rowObject: IDocumentSignApplication) => goToFormDraft(rowObject),
            faPencilAlt
        )
    );
    return buttons;
}
