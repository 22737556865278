import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';

function renderButton(popupContent: string, handler: (id: any, rowObject: any) => void, icon: any) {
    return {
        title: popupContent,
        icon: () => (
            <a data-content={popupContent}>
                <FontAwesomeIcon icon={icon} />
            </a>
        ),
        handler,
    };
}

export default function ModalMyPVRPublicSearchLegalProceedingVersion2Actions(deleteFile: (index: any) => void) {
    const buttons = [];
    buttons.push(renderButton('Delete', (labelId, rowObject) => deleteFile(rowObject.index), faTrashAlt));
    return buttons;
}
