import {API_SERVERLESS, getFetch, postFetch, postForm} from '../../../commonApi';
import {EOlistData, Tree, TreeData} from '../CommonComponents/TreeView/Interfaces/TreeViewInterfaces';
import {
    DocumentsTableParamsData,
    FileSearchFilterCriteria,
} from '../CommonComponents/FileSearch/Interfaces/FileSearchInterfaces';
import {Right} from '../CommonComponents/ModalCategory/Interfaces/ModalCategoryInterfaces';
import {TabObjectsFetchData} from '../CommonComponents/ModalDefaultRequirements/Interfaces/ModalDefaultRequirmentsInterfaces';
import {SpeciesNamesFetchData} from '../CommonInterfaces/CommonInterfaces';
import {UploadedFileData} from '../CommonComponents/ModalDocument/Interfaces/ModalDocumentInterfaces';
import {Parameter} from '../Screens/AdministratorPage/AdministratorPageService';
import {
    computeGetUploadPreSignedURLPromises,
    computePostFilesToPreSignedURLPromises,
    postFileToPreSignedURLRequest,
} from '../../../utils/requests';
import {TloDocument} from '../Screens/NewSpeciesProcedurePage/NewSpeciesProcedurePageService';

export function apiDownloadPDF(token: string): Promise<{signedUrl: string}> {
    return getFetch(`${API_SERVERLESS}/tlo/download/pdf/${token}`, true);
}

export function apiTloDownloadDocuments(
    csDocsKey: number | null,
    jsonData: {csDocsKeys: Array<number>} | undefined
): Promise<{documents: TloDocument | Array<TloDocument>}> {
    return (jsonData ? postFetch : getFetch)(
        `${API_SERVERLESS}/tlo/applications/commons/filedownload${csDocsKey ? `/${csDocsKey}` : ''}`,
        true,
        jsonData
    );
}

export function apiTloDocumentsSearch(applicationNumber: string, pageSize: number) {
    return getFetch(
        `${API_SERVERLESS}/tlo/applications/document?applicationNumber=${applicationNumber}&pageSize=${pageSize}`,
        true,
        {}
    );
}

export function apiCreateTree(jsonData: {tree: Tree; tag: string} | {tree: Tree; tag: string; eoId: number}) {
    return postFetch(`${API_SERVERLESS}/tlotree/applications/commons/tree/create`, true, jsonData).then(
        jsonResponse => {
            if (jsonResponse) {
                return jsonResponse;
            }
        }
    );
}

export function apiFetchTree(tag: string, eoId?: string): Promise<TreeData> {
    const path =
        eoId && Number(eoId) > 0
            ? `/tlotree/applications/commons/tree?tag=${tag}&eoId=${eoId}`
            : `/tlotree/applications/commons/tree?tag=${tag}`;

    return getFetch(`${API_SERVERLESS}${path}`, true, {});
}

export function apiFetchEOlistForTree(): Promise<EOlistData> {
    return getFetch(`${API_SERVERLESS}/tlotree/applications/commons/tree/eolist`, true, {});
}

export function apiFetchDocumentCategoryRights(): Promise<Array<Right>> {
    return getFetch(`${API_SERVERLESS}/tlo/applications/commons/rights`, true, {});
}

export function apiSendTreeDocumentFile(jsonData: {
    filename: string;
    file: Blob;
}): Promise<{fileUUID: string; error?: string}> {
    return computeGetUploadPreSignedURLPromises({}).then(({presignedUrl, identifier}: any = {}) => {
        return postFileToPreSignedURLRequest({preSignedURL: presignedUrl, file: jsonData.file}).then(() => {
            return postFetch(`${API_SERVERLESS}/tlotree/applications/commons/tree/file`, true, {
                filename: jsonData.filename,
                identifier,
            });
        });
    });
}

export function apiDownloadTreeDocumentFile(fileUUID: string): Promise<{signedUrl: string}> {
    return getFetch(`${API_SERVERLESS}/tlotree/applications/commons/tree/file?id=${fileUUID}`, true);
}

export function apiDeleteTreeDocumentFile(fileUUID: string): Promise<{}> {
    return getFetch(`${API_SERVERLESS}/tlotree/applications/commons/tree/file/delete?id=${fileUUID}`, true, {});
}

export function apiSearchFile(
    // eslint-disable-next-line no-undef
    criteria: Partial<FileSearchFilterCriteria>,
    tag: string
): Promise<Array<DocumentsTableParamsData>> {
    const params = {...criteria};
    const term = params.searchTerm;
    return getFetch(
        `${API_SERVERLESS}/tlotree/applications/commons/tree/file/search?term=${term}&tag=${tag}`,
        true,
        {}
    );
}

export function apiTabObjectsFetch(): Promise<TabObjectsFetchData> {
    return getFetch(`${API_SERVERLESS}/tlo/applications/commons/tabobjects`, true);
}

export function apiSpeciesAutocompletionFilterFetch(
    searchTerm: string,
    searchType: string
): Promise<SpeciesNamesFetchData> {
    return getFetch(
        `${API_SERVERLESS}/tlo/applications/commons/autocomplete/speciesname?term=${searchTerm}&searchType=${searchType}`,
        true
    );
}

export function apiTloDocumentUpload(
    jsonRequest: {
        subject: string;
        comments: string;
        applicationNumberPerDocumentList: Array<{
            applicationNumber: string;
            fileName: string;
            invoiceDate: string;
            invoiceNumber: string;
            totalAmount: number;
            vatAmount: number;
        }>;
    },
    documentList: Array<UploadedFileData>
) {
    let fileFolder = '';

    const signRequest = (JSONResponses?: any) => {
        if (Array.isArray(JSONResponses)) {
            let encryptedFilesIndexes: number[] = [];
            JSONResponses.forEach(
                (JSONResponse, index) => JSONResponse?.error === 'encrypted' && encryptedFilesIndexes.push(index)
            );
            if (encryptedFilesIndexes.length) {
                return Promise.reject({encryptedFilesIndexes});
            }
        }
        return postFetch(
            `${API_SERVERLESS}/tlo/applications/cpvobox/upload`,
            true,
            Object.assign({}, jsonRequest, {fileFolder})
        );
    };

    if (!documentList.length) {
        return getFetch(`${API_SERVERLESS}/tlo/applications/commons/createuploadfolder`, true).then(({folder}) => {
            fileFolder = folder;
            return signRequest();
        });
    } else {
        return getFetch(`${API_SERVERLESS}/tlo/applications/commons/createuploadfolder`, true)
            .then(({folder}) => {
                fileFolder = folder;
                const uploadArray: Array<
                    Promise<{files: Array<Blob>; documentType: string; folder: string; index: string}>
                > = [];
                return Promise.all(computeGetUploadPreSignedURLPromises({documentList})).then(
                    getUploadPreSignedURLResponses => {
                        return Promise.all(
                            computePostFilesToPreSignedURLPromises({
                                getUploadPreSignedURLResponses,
                                documentList,
                            })
                        ).then(() => {
                            documentList.forEach((document, index) => {
                                const formData = new FormData();
                                formData.append('files[]', getUploadPreSignedURLResponses[index].identifier);
                                formData.append('filename', document.fileName);
                                formData.append(`documentType`, document.fileType !== null ? document.fileType : '');
                                formData.append(`folder`, folder);
                                formData.append(`index`, index.toString());
                                uploadArray.push(
                                    postForm(
                                        `${API_SERVERLESS}/tlo/applications/commons/uploadfilestofolder`,
                                        true,
                                        formData
                                    )
                                );
                            });

                            return Promise.all(uploadArray);
                        });
                    }
                );
            })
            .then(signRequest);
    }
}

export function apiGetEditorContent(): Promise<Array<Parameter>> {
    return getFetch(`${API_SERVERLESS}/tlo/homehtml`, true, {});
}
