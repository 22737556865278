import {API_SERVERLESS, postFetch} from '~commonApi';
import {ICommunicationType} from '../../../types';

export function apiCommunicationCentreClassifyCommunication(JSONRequest: {
    communicationId: number;
    todoByShortKey?: string;
    todoByContactId?: number;
    communicationTypes: ICommunicationType[];
}): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/communicationCentre/v3/internal/classifyCommunication`, true, JSONRequest);
    // return new Promise(function (resolve) {
    //     setTimeout(function () {
    //         resolve({
    //             data: 'OK',
    //         });
    //     }, 1000);
    // });
}
