import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faLightbulb} from '@fortawesome/free-regular-svg-icons';
import {IQuestionRowObject} from './QuestionModalSearchAndAdd';

function renderButton(popupContent: any, handler: any, icon: any) {
    return {
        title: popupContent,
        icon: (_: IQuestionRowObject) =>
            icon === faEye ? (
                <div />
            ) : (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon} color={'green'} />
                </a>
            ),
        handler,
    };
}

export default function QuestionModalSearchAndAddActionButtons(showModalLabelUsedIn: (labelId: number) => any) {
    const buttons = [];

    buttons.push(
        renderButton(
            'Used list',
            (questionId: number, _: IQuestionRowObject) => showModalLabelUsedIn(questionId),
            faLightbulb
        )
    );

    return buttons;
}
