import {API_SERVERLESS, getFetch, postFetch} from '../../../../commonApi';

export interface PersonElement {
    id: number;
    title: string;
    name: string;
    surname: string;
    country: string;
    countryId: string;
    countryArray?: string[];
    countryIdArray?: string[];
    acknowledgementsIdsArray?: number[];
}

export interface PersonsListFetchData {
    data: {
        acknowledgementsList: PersonElement[];
        token: string;
    };
}

export function apiPersonsListFetch(): Promise<PersonsListFetchData> {
    return getFetch(`${API_SERVERLESS}/caselaw/acknowledgementslist`, true);
}

export function apiPersonSave(jsonData: {
    title: string;
    name: string;
    surname: string;
    countryId: string;
}): Promise<{message: string}> {
    return postFetch(`${API_SERVERLESS}/caselaw/createacknowledgement`, true, jsonData).then(jsonResponse => {
        if (jsonResponse) {
            return jsonResponse;
        }
    });
}

export function apiPersonEdit(jsonData: {
    id?: number;
    title: string;
    name: string;
    surname: string;
    countryId?: string;
}): Promise<{message: string}> {
    return postFetch(`${API_SERVERLESS}/caselaw/editacknowledgement`, true, jsonData).then(jsonResponse => {
        if (jsonResponse) {
            return jsonResponse;
        }
    });
}

export function apiPersonDelete(personId?: number): Promise<{message: string}> {
    return postFetch(`${API_SERVERLESS}/caselaw/deleteacknowledgement`, true, {id: personId});
}
