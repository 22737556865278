import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';
import {IExaminationOfficeCountry, IRegister} from '../../types';

export function apiPublicSearch(
    criteria: any,
    DEFAULT_CRITERIA: any = {},
    excelTranslations: any
): Promise<{
    data: {
        count: number;
        registers: IRegister[];
    };
}> {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/publicSearch/v3/publicSearch?${requestParams.join('&')}`,
        false,
        excelTranslations
    );
}

export function apiExaminationOfficeCountries(): Promise<{
    data: IExaminationOfficeCountry[];
}> {
    return getFetch(`${API_SERVERLESS}/publicSearch/v3/publicSearch/examinationOfficeCountries`);
}
