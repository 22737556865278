import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import stylesNew from './TextInput.module.scss';
import {
    Eye as IconEye,
    EyeSlash as IconEyeSlash,
    Info as IconInfo,
    Password as IconPassword,
    User as IconUser,
} from '../iconComponents';
import {Error} from '../componentsLayout';
import {ArrowContainer, Popover} from 'react-tiny-popover';

export const labelIcons: any = {
    user: <IconUser style={{marginRight: 8}} />,
    password: <IconPassword style={{marginRight: 8}} />,
    eye: <IconEye style={{marginRight: 8}} />,
    eyeSlash: <IconEyeSlash style={{marginRight: 8}} />,
};

export type TLabelIcon = 'user' | 'password' | 'eye' | 'eyeSlash';

export type TLabelPosition = 'leftInside' | 'leftOutside';

export const LABEL_POSITION: any = {
    LEFT_INSIDE: 'leftInside',
    LEFT_OUTSIDE: 'leftOutside',
};

export type TButtonIcon = 'info' | 'none' | 'eye' | 'eyeSlash';

const buttonIcons: any = {
    info: <IconInfo />,
    eye: <IconEye />,
    eyeSlash: <IconEyeSlash />,
};

const filterLabelHash: any = {
    contains: 'Contains',
    start: 'Starts with',
    equals: 'Equals',
};

type TFilter = 'contains' | 'start' | 'equals';

interface ITextInputProps {
    autoCompleteDisabled?: boolean;
    buttonAction?: any;
    buttonIcon?: TButtonIcon;
    textInputIcon?: TButtonIcon;
    textInputIconAction?: any;
    buttonText?: any;
    className?: any;
    currentFilter?: TFilter;
    disabled?: boolean;
    filter?: true | TFilter[];
    focus?: boolean;
    error?: string;
    inputIsHidden?: any;
    label?: any;
    labelIcon?: TLabelIcon;
    labelPosition?: TLabelPosition;
    mandatory?: boolean;
    onBlur?: any;
    onChange: any;
    onEnter?: any;
    onFilterChange?: any;
    placeholder?: string;
    popOverText?: string;
    style?: any;
    type?: any;
    value: any;
}

export default function ({
    autoCompleteDisabled,
    buttonAction,
    buttonIcon,
    textInputIcon,
    textInputIconAction,
    buttonText,
    className,
    currentFilter,
    disabled,
    filter,
    focus,
    error,
    inputIsHidden,
    label,
    labelIcon,
    labelPosition,
    mandatory,
    onBlur,
    onChange,
    onEnter,
    onFilterChange,
    placeholder,
    style: userStyle,
    type,
    value,
    popOverText,
}: ITextInputProps) {
    let elInput: any = null;
    let styles = stylesNew;

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    useEffect(() => focus && elInput && elInput.focus(), []);

    const onBlurFn = () => onBlur && onBlur();

    const inputField = (
        <>
            <input
                type={type || 'text'}
                ref={el => (elInput = el)}
                {...{placeholder, onChange}}
                value={value || ''}
                className={cx(
                    styles.input,
                    disabled && styles.inputDisabled,
                    !buttonIcon && !buttonText && styles.inputNoButton,
                    filter && (!labelPosition || labelPosition === LABEL_POSITION.LEFT_OUTSIDE)
                        ? styles.inputHasFilter
                        : null,
                    !filter && labelPosition === LABEL_POSITION.LEFT_INSIDE
                        ? styles.inputHasLabelLeftInsideAndNoFilter
                        : null,
                    labelPosition !== LABEL_POSITION.LEFT_INSIDE && styles.inputLabelIsOutside
                )}
                onKeyUp={event => event.key === 'Enter' && onEnter && onEnter()}
                disabled={disabled}
                onBlur={onBlurFn}
                autoComplete={autoCompleteDisabled ? 'new-password' : 'off'}
            />
            {textInputIcon ? (
                <span
                    style={{
                        background: '#e6e6e6',
                        display: 'flex',
                        alignItems: 'center',
                        paddingRight: 6,
                        paddingLeft: 6,
                        cursor: 'pointer',
                    }}
                    onClick={textInputIconAction}
                >
                    {buttonIcons[textInputIcon]}
                </span>
            ) : null}
        </>
    );

    return (
        <section
            className={cx(
                styles.section,
                ![LABEL_POSITION.LEFT_INSIDE, LABEL_POSITION.LEFT_OUTSIDE].includes(labelPosition) &&
                    styles[`sectionLabelPositionTop`],
                className ? className : null
            )}
            style={userStyle}
        >
            {!inputIsHidden && (
                <>
                    {label ? (
                        <label
                            className={cx(
                                styles.label,
                                labelPosition === LABEL_POSITION.LEFT_INSIDE && styles.labelPositionLeftInside,
                                labelPosition === LABEL_POSITION.LEFT_OUTSIDE && styles.labelPositionLeftOutside,
                                !labelPosition && styles.labelPositionTop
                            )}
                        >
                            {labelIcon ? labelIcons[labelIcon] : null}
                            {label || null}
                            {mandatory ? <span className={styles.mandatoryStar}>*</span> : null}
                        </label>
                    ) : null}
                    <div className={styles.filterInputButtonWrap}>
                        <div className={styles.filterInputWrap}>
                            {filter === true || Array.isArray(filter) ? (
                                <select
                                    className={cx(
                                        styles.filter,
                                        disabled && styles.filterDisabled,
                                        labelPosition === LABEL_POSITION.LEFT_INSIDE &&
                                            styles.filterAndLabelPositionLeftInside
                                    )}
                                    onChange={onFilterChange}
                                    value={currentFilter}
                                >
                                    {(Array.isArray(filter) ? filter : ['contains', 'start', 'equals']).map(
                                        key =>
                                            filterLabelHash[key] && (
                                                <option key={key} value={key}>
                                                    {filterLabelHash[key]}
                                                </option>
                                            )
                                    )}
                                    disabled={disabled && 'disabled'}
                                </select>
                            ) : null}
                            {filter && filter !== true && !Array.isArray(filter) ? (
                                <div onClick={() => elInput && elInput.focus()} className={styles.tag}>
                                    {filter}
                                </div>
                            ) : null}
                            {type === 'password' ? (
                                <form
                                    className={styles.passwordForm}
                                    onSubmit={event => event.preventDefault()}
                                    autoComplete={'off'}
                                >
                                    {inputField}
                                </form>
                            ) : (
                                inputField
                            )}
                            {popOverText ? (
                                <Popover
                                    isOpen={isPopoverOpen}
                                    positions={'left'}
                                    onClickOutside={() => setIsPopoverOpen(!isPopoverOpen)}
                                    content={({position, childRect, popoverRect}) => (
                                        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                            position={position}
                                            childRect={childRect}
                                            popoverRect={popoverRect}
                                            arrowColor={'white'}
                                            arrowSize={5}
                                            arrowStyle={{
                                                opacity: 0.7,
                                            }}
                                        >
                                            <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                                <div className={styles.popover}>{popOverText}</div>
                                            </div>
                                        </ArrowContainer>
                                    )}
                                >
                                    <button
                                        type="button"
                                        title={'Popover text'}
                                        className={cx(styles.buttonPopoverText)}
                                        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                                    >
                                        {buttonIcons['info']}
                                    </button>
                                </Popover>
                            ) : null}
                        </div>
                        {(buttonIcon && buttonIcon !== 'none') || buttonText ? (
                            <button type="button" className={cx(styles.button)} onClick={buttonAction}>
                                {buttonText ? (
                                    <span
                                        className={cx(styles.buttonText, !buttonIcon && styles.buttonTextNoButtonIcon)}
                                    >
                                        {buttonText}
                                    </span>
                                ) : null}
                                {buttonIcon ? buttonIcons[buttonIcon] : null}
                            </button>
                        ) : null}
                        {error ? (
                            <Error mb={'none'} mt={'sm'}>
                                {error}
                            </Error>
                        ) : null}
                    </div>
                </>
            )}
        </section>
    );
}
