import {API_SERVERLESS, postFetch} from '~commonApi';
import {getEnvironment} from '~utils';

interface IJSONData {
    login: string;
    password: string;
    resetCookie: boolean;
    accessToken: any;
    remember?: boolean;
}

export function apiLogin(jsonData: IJSONData, username: string, criteria: any, history: any) {
    return postFetch(
        `${API_SERVERLESS}/common/v3/authenticationLogin/login${jsonData.accessToken ? 'Internal' : ''}${
            process.env.SOURCEAPI === 'mock' || getEnvironment() === 'MOCK' ? `?mock=${username}` : ''
        }`,
        false,
        jsonData
    ).then((jsonResponse: any) => {
        LOG([`Login JSON response: ${JSON.stringify(jsonResponse, null, 4)}`]);
        //todo check json.details
        const validAuthentication = jsonResponse && jsonResponse.user && jsonResponse.result === 1;
        if (
            validAuthentication &&
            jsonResponse.cookies &&
            jsonResponse.cookies['CS'] &&
            ((self as any).target || criteria.target)
        ) {
            const target = (self as any).target || criteria.target;
            (self as any).target = null;
            window.location.href = `https://cpvoextranet.cpvo.europa.eu/api/setcookie.php?cookie=${jsonResponse.cookies['CS']}&target=${target}`;
        } else if (validAuthentication) {
            if (jsonResponse.token) {
                if (jsonData.remember) {
                    localStorage.setItem('remember', '1');
                }
                const ie = !!navigator.userAgent.match(/Trident\/7\./);
                if (ie) {
                    document.cookie = `ie-token=${jsonResponse.token}`;
                } else {
                    const remember = localStorage.getItem('remember') === '1';
                    (remember ? localStorage : sessionStorage).setItem('token', jsonResponse.token);
                    (remember ? localStorage : sessionStorage).setItem('refresh-token', jsonResponse.refreshToken);
                }
            }
            if (jsonResponse.cookies) {
                document.cookie = 'OCVVSESSION=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'CS=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                Object.keys(jsonResponse.cookies).forEach(cookieKey => {
                    document.cookie = `${cookieKey}=${jsonResponse.cookies[cookieKey]}`;
                });
            }
            if (process.env.SOURCEAPI === 'mock' || getEnvironment() === 'MOCK') {
                (self as any).username = username;
            }

            if (criteria.home) {
                history.push(decodeURIComponent(criteria.home));
                return {target: true};
            }

            if (criteria.target) {
                window.location.href = criteria.target;
                return {target: true};
            }
            if (jsonResponse.challengeParams) {
                return {challengeParams: jsonResponse.challengeParams};
            }
        } else {
            return jsonResponse;
        }
    });
}

export function apiLostLogin(jsonData: any) {
    return postFetch(`${API_SERVERLESS}/common/v3/authenticationLogin/lostLogin`, false, jsonData);
}

export function apiLostPassword(jsonData: any) {
    return postFetch(`${API_SERVERLESS}/common/v3/authenticationLogin/lostPassword`, false, jsonData);
}
