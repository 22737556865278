import React, {useEffect, useRef, useState} from 'react';
import {FORMAT_DATE, formatDateEasy, reformatDateEasy} from '../../../../components/FormatFunctions';
import {Button} from '../../../../componentsFormV2';
import CustomTable from '../../../../components/CustomTable';
import DateInput from '~components/DateInput';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import InputLink from '~components/InputLink';
import {ModalAlertVersion2, ModalErrorVersion2} from '../../../../commonModals';
import NavigationTLO from '../../../../shared/NavigationTLO';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import TextLabelInput from '~components/TextLabelInput';
import TextInput from '~components/TextInput';
import {
    apiTqSearchExcel,
    apiTqSearchQuestionnaireIdsFetch,
    apiTqSearchQuestionnairesFetch,
    apiTqSearchQuestionsFetch,
    apiTqSearchSpeciesSearch,
    apiTqSpeciesAutocompletionFetch,
    TqInfoData,
    TqQuestionInfoData,
    TqSearchFilterCriteriaData,
    TqSearchTableRowData,
} from './TqSearchPageService';
import TqSearchTqsCriteriaArea from './Components/TqSearchTqsCriteriaArea/TqSearchTqsCriteriaArea';
import en from '../../../../translations/en.json';
import {faChevronDown, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {getUserRoles} from '../../CommonFunctions/CommonFunctions';
import {getExcelSignedURLFetchRequest} from '../../../../commonApi/download';
import {getPreSignedURLFetchRequest} from '../../../../utils/requests';
import {injectIntl} from 'react-intl';
import moment from 'moment';
import {saveAs} from 'file-saver';
import styles from './TqSearchPage.module.scss';
import {trackPageView} from '../../../../utils';

const RESULT_FIELDS_ALL = [
    'speciesName',
    'registerId',
    'denomination',
    'applicationDate',
    'reference',
    'applicantName',
    'email',
    'type',
    'officialName',
    'onlineId',
];
const RESULT_FIELDS_DEFAULT = [
    'speciesName',
    'registerId',
    'denomination',
    'applicationDate',
    'reference',
    'applicantName',
    'email',
    'type',
    'officialName',
    'onlineId',
];

const DEFAULT_CRITERIA: TqSearchFilterCriteriaData = {
    speciesName: '',
    speciesId: '',
    denomination: '',
    denominationFilter: 'equals',
    reference: '',
    applicantName: '',
    applicationDateFrom: '',
    applicationDateTo: '',
    responseIds: undefined,
    email: '',
    pageNumber: 1,
    pageSize: Number(localStorage.getItem('pageSizetqSearchSpecies')) || 50,
    refresh: false,
    excel: false,
    order: 'speciesName',
    reverse: false,
};

const DATEFORMAT_CRITERIA_KEYS = ['applicationDateFrom', 'applicationDateTo'];

const DEFAULT_COUNT = 0;

const modalScreenTypes = {ERROR: 'ERROR', INFO: 'INFO'};

interface TqSearchPageProps {
    intl: any;
    history: any;
}

const TqSearchPage = (props: TqSearchPageProps & RouteComponentProps) => {
    const [criteria, setCriteria] = useState<TqSearchFilterCriteriaData>(Object.assign({}, DEFAULT_CRITERIA));
    const [speciesListByIdName, setSpeciesListByIdName] = useState<Array<{ID: string; NAME: string}> | null>(null);
    const [speciesNameHash, setSpeciesNameHash] = useState<{[key: string]: Array<string>} | null>(null);
    const [selectedSpecies, setSelectedSpecies] = useState<{[key: string]: any}>({});
    const [tqSearchSpecies, setTqSearchSpecies] = useState<Array<TqSearchTableRowData> | null>(null);
    const [timestamp, setTimestamp] = useState(0);
    const [count, setCount] = useState(DEFAULT_COUNT);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [infoMessage, setInfoMessage] = useState<string>('');
    const [refresh, setRefresh] = useState(true);
    const [defaultOrder, setDefaultOrder] = useState('speciesName');
    const [reverseOrder, setReverseOrder] = useState(false);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [questionnaireIds, setQuestionnaireIds] = useState<Array<number>>([]);
    const [questionnaires, setQuestionnaires] = useState<Array<TqInfoData>>([]);
    const [questions, setQuestions] = useState<Array<TqQuestionInfoData>>([]);
    const [userRoles] = useState(getUserRoles());
    const firstLoad = useFirstRender();
    const [speciesLoaded, setSpeciesLoaded] = useState(false);
    const [tqsCriteriaOpened, setTqsCriteriaOpened] = useState(false);
    const [isImportantExplanationOpen, setIsImportantExplanationOpen] = useState(false);

    useEffect(() => {
        trackPageView({documentTitle: 'tloTqSearch'});
        loadUrlParams();
        (async () => {
            await fetchSpeciesListAndPopulateState();
        })();
    }, []);

    useEffect(() => {
        if (speciesLoaded && criteria.speciesId) {
            let speciesId = (criteria.speciesId.split(',') || [])[0];
            let speciesName =
                ((speciesListByIdName || []).find(species => species.ID === criteria.speciesId) || {}).NAME || '';
            setSelectedSpecies({
                [speciesId]: speciesName,
            });
        }
    }, [speciesLoaded]);

    useEffect(() => {
        if (Object.keys(selectedSpecies).length > 0) {
            let speciesName = Object.values(selectedSpecies)[0];
            setLoading(true);
            apiTqSearchQuestionnaireIdsFetch(speciesName)
                .then(JSONResponse => {
                    if (JSONResponse && JSONResponse.data && JSONResponse.data.questionnaireIds) {
                        setQuestionnaireIds(JSONResponse.data.questionnaireIds.map(el => el.questionnaireId));
                    }
                })
                .catch(error => {
                    setErrorMessage(`TQ search questionnaire ID fetch error: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        }
    }, [JSON.stringify(selectedSpecies)]);

    useEffect(() => {
        if (speciesLoaded) {
            if (questionnaireIds.length === 0) {
                setInfoMessage(`No active TQ available for ${Object.values(selectedSpecies)[0]}`);
                setModalScreen(modalScreenTypes.INFO);
            }
            if (questionnaireIds.length > 0) {
                setLoading(true);
                apiTqSearchQuestionnairesFetch(questionnaireIds)
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.questionnaires) {
                            setQuestionnaires(jsonResponse.data.questionnaires);
                        }
                    })
                    .catch(error => {
                        setErrorMessage(`TQ search questionnaires list fetch error: ${error}`);
                        setModalScreen(modalScreenTypes.ERROR);
                    })
                    .finally(() => setLoading(false));
            }
        }
    }, [questionnaireIds]);

    const fetchSpeciesListAndPopulateState = async () => {
        try {
            setLoading(true);
            const jsonResponse: any = await apiTqSpeciesAutocompletionFetch();
            const speciesNameHash: {[key: string]: Array<string>} = {};
            ((jsonResponse && jsonResponse.data && jsonResponse.data.speciesNames) || []).forEach((sn: any) => {
                if (!speciesNameHash[sn.lib]) {
                    speciesNameHash[sn.lib] = [];
                }
                speciesNameHash[sn.lib].push(sn.id);
            });
            const speciesNameListByIdName = Object.keys(speciesNameHash).map(snn => ({
                ID: speciesNameHash[snn].join(','),
                NAME: snn,
            }));
            const selectedSpeciesObj: {[key: string]: string} = {};
            const selectedSpeciesKeyArray = Object.keys(selectedSpecies || {});
            speciesNameListByIdName
                .filter(i => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                .forEach(item => (selectedSpeciesObj[item.ID] = item.NAME));
            setSpeciesNameHash(speciesNameHash);
            setSpeciesListByIdName(speciesNameListByIdName);
            if (Object.keys(selectedSpeciesObj).length > 0) {
                setSelectedSpecies(selectedSpeciesObj);
            } else {
                const selectedSpeciesValue = speciesNameListByIdName.filter(el => {
                    return el.ID.includes(Object.keys(selectedSpecies)[0]);
                });
                if (selectedSpeciesValue.length > 0)
                    selectedSpeciesObj[Object.keys(selectedSpecies)[0]] = selectedSpeciesValue[0].NAME;
                setSelectedSpecies(selectedSpeciesObj);
            }
            setSpeciesLoaded(true);
            setLoading(false);
        } catch (error) {
            setErrorMessage(`TQ Species Names Autocompletion list error: ${error}`);
            setModalScreen(modalScreenTypes.ERROR);
        }
    };

    useEffect(() => {
        !firstLoad && refresh && search(true);
    }, [criteria.order, criteria.reverse, criteria.pageSize, criteria.pageNumber]);

    useEffect(() => {
        (userRoles.indexOf('WETLO') !== -1 || userRoles.indexOf('TLOOF') !== -1 || userRoles.indexOf('EOADM') !== -1) &&
            firstLoad &&
            defaultOrder !== DEFAULT_CRITERIA.order &&
            reverseOrder !== DEFAULT_CRITERIA.reverse &&
            search(false, true);
    }, [criteria.order, criteria.reverse]);

    function useFirstRender() {
        const firstRender = useRef(true);
        useEffect(() => {
            firstRender.current = false;
        }, []);
        return firstRender.current;
    }

    const parseCriteria = (unParsedCriteria: TqSearchFilterCriteriaData): TqSearchFilterCriteriaData => {
        const parsedCriteria: TqSearchFilterCriteriaData = JSON.parse(JSON.stringify({}));
        Object.keys(unParsedCriteria).forEach(key => {
            if (DATEFORMAT_CRITERIA_KEYS.includes(key) && !unParsedCriteria[key]) {
                const date = unParsedCriteria[key];
                parsedCriteria[key] = reformatDateEasy(date);
            } else {
                if (key === 'responseIds') {
                    parsedCriteria[key] =
                        unParsedCriteria.responseIds &&
                        Object.entries(JSON.parse(unParsedCriteria.responseIds)).length === 0
                            ? undefined
                            : unParsedCriteria.responseIds;
                } else {
                    parsedCriteria[key] = unParsedCriteria[key];
                }
            }
        });
        return parsedCriteria;
    };

    const buildParams = (parsedCriteria: TqSearchFilterCriteriaData) => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(key => parsedCriteria[key] !== DEFAULT_CRITERIA[key] && key !== 'refresh')
            .map(
                key =>
                    `${key}=${
                        key === 'speciesId' || key === 'speciesName'
                            ? encodeURIComponent(parsedCriteria[key])
                            : parsedCriteria[key]
                    }`
            );
        const joinedParamsText = `?${paramArray.join('&')}`;
        props.history.replace(`/tloTqSearch${(paramArray.length > 0 && joinedParamsText) || ''}`);
    };

    const search = (shouldRefresh: boolean, urlLoad = false) => {
        setLoading(true);
        setCriteria(
            Object.assign({}, criteria, {refresh: !!shouldRefresh}, !shouldRefresh && !urlLoad && {pageNumber: 1})
        );
        const parsedCriteria = parseCriteria(criteria);
        buildParams(parsedCriteria);
        apiTqSearchSpeciesSearch(parsedCriteria)
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.tqSearchSpecies) {
                    const newCriteria = Object.assign({}, criteria, {pageNumber: 1});
                    if (!shouldRefresh && !urlLoad) {
                        setCriteria(Object.assign({}, newCriteria));
                    }
                    setTqSearchSpecies(jsonResponse.data.tqSearchSpecies);
                    setTimestamp(Date.now());
                    if (jsonResponse.data.count) {
                        setCount(jsonResponse.data.count);
                    }
                }
            })
            .catch(error => {
                setErrorMessage(`TQ search list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const params = domainArray.pop();
            if (params) {
                params.split('&').forEach(param => {
                    const paramElements = param.split('=');
                    if (paramElements.length === 2) {
                        const key = paramElements[0];
                        criteria[key] = decodeURIComponent(paramElements[1]);
                    }
                });
                if (Object.keys(criteria).length > 0) {
                    DATEFORMAT_CRITERIA_KEYS.forEach(criteriaKey => {
                        const criteriaValue = criteria && criteria?.criteriaKey?.toString();
                        if (criteriaValue && /^\d{4}(-(\d){2}){2}$/.test(criteriaValue)) {
                            criteria[criteriaKey] = formatDateEasy(criteria[criteriaKey]);
                        }
                    });
                    setCriteria(Object.assign({}, criteria));
                    criteria.order && setDefaultOrder(criteria.order);
                    criteria.reverse && setReverseOrder(criteria.reverse);
                }
            }
        }
    };

    const closeErrorModal = () => {
        setErrorMessage(null);
        setModalScreen(null);
    };

    const closeInfoModal = () => {
        setInfoMessage('');
        setModalScreen(null);
    };

    const updateCriteriaValue = (
        // eslint-disable-next-line no-undef
        criteriaValue: Partial<TqSearchFilterCriteriaData>,
        callback = () => {},
        shouldRefresh = true
    ) => {
        setRefresh(shouldRefresh);
        if (criteriaValue !== undefined) {
            setCriteria({...criteria, ...criteriaValue});
        }
        callback && callback();
    };

    const printExcel = (excelTranslations: {[key: string]: string}) => {
        const parsedCriteria = Object.assign({}, parseCriteria(criteria), {excel: true});
        if (
            parsedCriteria.applicantName.length > 0 ||
            parsedCriteria.applicationDateFrom.length > 0 ||
            parsedCriteria.applicationDateTo.length > 0 ||
            parsedCriteria.denomination.length > 0 ||
            parsedCriteria.reference.length > 0 ||
            parsedCriteria.responseIds ||
            parsedCriteria.speciesId.length > 0 ||
            parsedCriteria.speciesName.length > 0
        ) {
            setLoading(true);
            const fileName =
                parsedCriteria.speciesId.length > 0 && selectedSpecies[parsedCriteria.speciesId]
                    ? `TQs_${selectedSpecies[parsedCriteria.speciesId]}_${moment().format('DD/MM/YYYY')}.xlsx`
                    : `TQs_vacc_${moment().format('DD/MM/YYYY')}.xlsx`;
            apiTqSearchExcel(parsedCriteria, excelTranslations)
                .then(jsonResponse => {
                    if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                        getExcelSignedURLFetchRequest(jsonResponse.data.token).then(response => {
                            if (response && response.signedUrl) {
                                const {signedUrl} = response;
                                getPreSignedURLFetchRequest(signedUrl)
                                    .then(response => response.blob())
                                    .then(responseBlob => {
                                        saveAs(responseBlob, fileName);
                                    });
                            }
                        });
                    }
                })
                .catch(error => {
                    setErrorMessage(`error downloading: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        } else {
            setInfoMessage(
                `The data set is too large to export the excel file. Please use at least one filter and try again.`
            );
            setModalScreen(modalScreenTypes.INFO);
        }
    };

    const goToCandidateVarieties = (rowObject: TqSearchTableRowData) => {
        if (rowObject.onlineId === 'Yes') {
            props.history.push(
                `/candidateVarieties?speciesName=${rowObject.speciesName.replace(
                    new RegExp('&', 'g'),
                    '%26'
                )}&denomination=${rowObject.denomination}&reference=${rowObject.reference}&registerId=${
                    rowObject.registerId
                }&applicantName=${rowObject.applicantName.replace('\r\n', ', ')}&countryId=${
                    rowObject.countryId
                }&speciesId=${rowObject.speciesId}`
            );
        } else {
            setInfoMessage(
                `No TQ detail for the application ${rowObject.registerId}, country ${rowObject.countryName}.`
            );
            setModalScreen(modalScreenTypes.INFO);
        }
    };

    const resetTqsCriteria = () => {
        setQuestionnaires([]);
        setQuestions([]);
        criteria.responseIds && setCriteria(Object.assign({}, criteria, {responseIds: undefined}));
    };

    const getQuestionsOfSelectedQuestionnaire = (questionnaireId: number) => {
        setLoading(true);
        apiTqSearchQuestionsFetch(questionnaireId)
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.questions) {
                    setQuestions(jsonResponse.data.questions);
                }
            })
            .catch(error => {
                setErrorMessage(`TQ search questions list fetch error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const onSpeciesSelectionChange = (selectedSpecies: {[key: string]: string}) =>
        updateCriteriaValue({speciesId: Object.keys(selectedSpecies).join(), speciesName: ''}, () => {
            setSelectedSpecies(selectedSpecies);
            resetTqsCriteria();
        });

    /*-- criteria --- */
    const onSpeciesNameChange = (speciesName: string) => updateCriteriaValue({speciesName});

    const onDenominationFilterChange = ({target: {value: denominationFilter}}: React.ChangeEvent<HTMLInputElement>) =>
        updateCriteriaValue({denominationFilter});

    const onDenominationChange = ({target: {value: denomination}}: React.ChangeEvent<HTMLInputElement>) =>
        updateCriteriaValue({denomination});

    const onReferenceChange = ({target: {value: reference}}: React.ChangeEvent<HTMLInputElement>) =>
        updateCriteriaValue({reference});

    const onApplicantNameChange = ({target: {value: applicantName}}: React.ChangeEvent<HTMLInputElement>) =>
        updateCriteriaValue({applicantName});

    const onApplicationDateFromChange = (applicationDateFrom: string) => updateCriteriaValue({applicationDateFrom});

    const onApplicationDateToChange = (applicationDateTo: string) => updateCriteriaValue({applicationDateTo});

    const onClearAllFieldsClick = () => {
        setTqsCriteriaOpened(!tqsCriteriaOpened);
        setTqSearchSpecies(null);
        setSelectedSpecies({});
        resetTqsCriteria();
        setCriteria(Object.assign({}, DEFAULT_CRITERIA, criteria.pageSize));
        const parsedCriteria = parseCriteria(DEFAULT_CRITERIA);
        buildParams(parsedCriteria);
    };

    /* TQ Search Table Area */
    const tablePrintExcel = () => {
        const excelTranslations: {[key: string]: string} = {};
        const tqSearchSpeciesExcelKeys = Object.keys(en).filter(key => ~key.indexOf('excel.tqSearchSpecies.'));
        const tqSearchSpeciesHeaderKeys = tqSearchSpeciesExcelKeys.map(key =>
            key.replace('excel.tqSearchSpecies.', '')
        );
        tqSearchSpeciesHeaderKeys.forEach(headerKey => {
            excelTranslations[headerKey] = props.intl.formatMessage({
                id: `excel.tqSearchSpecies.${headerKey}`,
            });
        });
        printExcel(excelTranslations);
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {modalScreen === modalScreenTypes.INFO ? (
                <ModalAlertVersion2 title={'Info'} message={infoMessage} close={closeInfoModal} />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`Technical Liaison Officer`} />
            <NavigationTLO />
            <MainWrapper>
                <FormWrapper paddingFormContent={'sm'}>
                    {userRoles.indexOf('WETLO') === -1 &&
                    userRoles.indexOf('TLOOF') === -1 &&
                    userRoles.indexOf('EOADM') === -1 ? (
                        <div style={{paddingTop: 20}}>
                            <b style={{color: 'red'}}>
                                <span className="ng-scope">{`You have no permissions to access this page.`}</span>
                            </b>
                        </div>
                    ) : null}
                    <div className={styles.mainFieldContainer}>
                        <TextLabelInput
                            filter={'Equals'}
                            placeholder={`please use autocompletion`}
                            double={true}
                            onSelectionChange={onSpeciesSelectionChange}
                            onChange={onSpeciesNameChange}
                            onEnter={search}
                            value={criteria.speciesName}
                            selectedElements={selectedSpecies}
                            delay={300}
                            outsideLabel={'Species Name'}
                            outsideLabelWidth={160}
                            listByIdName={speciesListByIdName}
                            nameHash={speciesNameHash}
                            noInfo={true}
                            uniqueValue={true}
                        />
                        <TextInput
                            filter={['start', 'equals', 'contains']}
                            currentFilter={criteria.denominationFilter}
                            onFilterChange={onDenominationFilterChange}
                            placeholder={`Please type denomination`}
                            value={criteria.denomination}
                            onChange={onDenominationChange}
                            double={true}
                            onEnter={() => search(true)}
                            outsideLabel={'Denomination'}
                            outsideLabelWidth={160}
                        />
                        <div style={{clear: 'both'}} />
                        <TextInput
                            filter={'Contains'}
                            placeholder={`Please type breeder's reference`}
                            value={criteria.reference}
                            onChange={onReferenceChange}
                            double={true}
                            onEnter={() => search(true)}
                            outsideLabel={`Breeder's Reference`}
                            outsideLabelWidth={160}
                        />
                        <div style={{clear: 'both'}} />
                        <TextInput
                            filter={'Contains'}
                            placeholder={`Please type applicant(s)`}
                            value={criteria.applicantName}
                            onChange={onApplicantNameChange}
                            double={true}
                            onEnter={() => search(true)}
                            outsideLabel={`Applicant(s)`}
                            outsideLabelWidth={160}
                        />
                        <div style={{clear: 'both'}} />
                        <DateInput
                            changeDateFrom={onApplicationDateFromChange}
                            inputValueFrom={criteria.applicationDateFrom}
                            changeDateTo={onApplicationDateToChange}
                            inputValueTo={criteria.applicationDateTo}
                            outsideLabel={'Application Date'}
                            outsideLabelWidth={160}
                        />
                        <div style={{clear: 'both'}} />
                        {Object.keys(selectedSpecies).length > 0 ? (
                            <TqSearchTqsCriteriaArea
                                questionnaires={questionnaires}
                                questions={questions}
                                fetchQuestions={getQuestionsOfSelectedQuestionnaire}
                                updateCriteriaValue={updateCriteriaValue}
                            />
                        ) : null}
                        <div className={styles.container}>
                            <div style={{display: 'inline-block', marginRight: 20}}>
                                <InputLink
                                    label={`Important explanation on the content`}
                                    width={810}
                                    icon={isImportantExplanationOpen ? faChevronDown : faChevronRight}
                                    clickAction={() => setIsImportantExplanationOpen(!isImportantExplanationOpen)}
                                />
                                <div style={{clear: 'both'}} />
                                {isImportantExplanationOpen ? (
                                    <div className={styles.explanationNoteWrap}>
                                        <div>{`This search facility contains all information that was provided by CPVO applicants in e-TQ's for files currently under procedure. The data is made available once the application is considered to be complete and is kept available until the decision on granting / refusal or withdrawal by the applicant.`}</div>
                                        <br />
                                        <div>{`Paper TQ information is not retrievable with this tool. In some cases, the information provided initially by applicants is complemented or amended during the procedure. Such modifications are not reflected in the tool.`}</div>
                                        <br />
                                        <div>{`In accordance with Regulation 2018/1725, the TLO should not download the bulk of the email addresses included in the TLO website and must only make use, when required, of the specific email address/es relating to the files that he/she manages`}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div style={{clear: 'both'}} />
                    </div>
                    <div style={{clear: 'both'}} />
                    <FormFooter>
                        <Button
                            clickAction={onClearAllFieldsClick}
                            variation={'secondary'}
                        >{`Clear all fields`}</Button>
                        <Button clickAction={() => search(false)}>{`Search`}</Button>
                    </FormFooter>
                    <div style={{clear: 'both'}} />
                </FormWrapper>
                {userRoles.indexOf('WETLO') !== -1 ||
                userRoles.indexOf('TLOOF') !== -1 ||
                userRoles.indexOf('EOADM') !== -1 ? (
                    <CustomTable
                        version={2}
                        pageNumber={criteria.pageNumber}
                        tableName={'tqSearchSpecies'}
                        tableType={'OBJECT'}
                        tableSource={tqSearchSpecies}
                        timestamp={timestamp}
                        id={'registerId'}
                        pagination={true}
                        printExcel={tablePrintExcel}
                        formatFunctions={{
                            applicationDate: FORMAT_DATE,
                            applicantName: (applicantName: string, _rowObject: TqSearchTableRowData) =>
                                applicantName.replace('\r\n', ', '),
                        }}
                        rowClick={(_registerId: string, rowObject: TqSearchTableRowData) =>
                            goToCandidateVarieties(rowObject)
                        }
                        resultFieldsAll={RESULT_FIELDS_ALL}
                        resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                        intl={props.intl}
                        defaultOrder={criteria.order}
                        reverseOrder={criteria.reverse}
                        pageSize={criteria.pageSize}
                        count={count}
                        updateCriteriaValue={(criteriaValue: TqSearchFilterCriteriaData) =>
                            updateCriteriaValue(criteriaValue)
                        }
                    />
                ) : null}
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(TqSearchPage));
