import gqlPrettier from 'graphql-prettier';
import graphQLClientInstance from '../../utils/axiosGraphQLClient';

const getSingleValueFromTypeAhead = (
    value: any,
    valueSelect: string,
    elasticField: string,
    elasticFieldSearchType: string
) => {
    return value && value.length > 0 && Array.isArray(value)
        ? `${elasticField}:"${value[0].value.toLowerCase()}" ${elasticFieldSearchType}:"${valueSelect}"`
        : '';
};

const getValueFromFormControl = (
    value: any,
    valueSelect: string,
    elasticField: string,
    elasticFieldSearchType: string,
    regexp: RegExp | null = null
) => {
    if (value && value.length > 0) {
        const val = regexp ? value.replace(regexp, '') : value;
        const searchType = elasticFieldSearchType ? `${elasticFieldSearchType}:"${valueSelect}"` : '';
        return `${elasticField}:"${val.toLowerCase()}" ${searchType}`;
    } else {
        return '';
    }
};

const createSearchClassesParams = (values: any) => {
    const classCode = getSingleValueFromTypeAhead(
        values.classCode,
        values.classCodeSelect,
        'classid',
        'classidSearchType'
    );
    const className = getValueFromFormControl(
        values.className,
        values.classNameSelect,
        'classname',
        'classnameSearchType'
    );

    return `
        ${classCode}
        ${className}
  `;
};

export const getSearchClassesQuery = (values: any, pageSize: number, pageIndex: number) => {
    const searchParams = createSearchClassesParams(values);

    const searchQuery = `
  {
    classesSearch(
      size:${pageSize}
      from:${pageSize * pageIndex}
      ${searchParams}
    ){
      total
      data {
        classid,
        upovclass,
        classname,
        species_object,
        info
      }
    }
  }`;

    return {
        query: gqlPrettier(searchQuery),
    };
};

export function apiVarietyFinderGetClassDetails(classId: string): Promise<any> {
    return graphQLClientInstance.post(
        'graphql',
        getSearchClassesQuery(
            {
                classCode: [{value: classId}],
                classCodeSelect: 'exact',
                className: [],
                classNameSelect: 'starts',
            },
            1,
            0
        )
    );
}
