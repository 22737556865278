import React, {useEffect, useState} from 'react';
import styles from './ModalLogicType.module.scss';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {faLightbulb, faList, faPencilAlt, faPlus, faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import {injectIntl} from 'react-intl';
import {apiBackOfficeGetAllResponses} from './ModalLogicTypeForbiddenResponseService';
import {ModalCustomVersion2, ModalLabelPreviewVersion2, ModalLabelSearchVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';
import {LoadingBar} from '../../../componentsLayout';
import ModalLabelVersion2, {getLabelTypeId} from '../../../commonModals/ModalLabelVersion2';
import ModalUsedInVersion2, {rowType} from '../../../commonModals/ModalUsedInVersion2';

const ModalLogicTypeForbiddenResponse = ({
    close,
    onCreateOrUpdateLogicType,
    responseLogicValues,
    responseLogicType,
    intl,
    formId,
    questionId,
    responseId,
    iteration,
}) => {
    const {logicParam, messageLink, messageLinkId, responseIdDest} = responseLogicValues || {};
    const {name, id: logicId} = responseLogicType || {};
    const [selectList, setSeletectList] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [modalLabel, setModalLabel] = useState(null);
    const [modalLabelSearch, setModalLabelSearch] = useState(null);
    const [modalPreview, setModalPreview] = useState(null);
    const [modalUsedIn, setModalUsedIn] = useState(null);
    const [labelIdValue, setLabelIdValue] = useState(messageLinkId ? messageLinkId : null);
    const [labelHtml, setLabelHtml] = useState(messageLink ? messageLink : null);
    const [loading, setLoading] = useState(true);
    const [paramValue, setParamValue] = useState(logicParam !== undefined ? logicParam : null);

    useEffect(() => {
        const fetchAllResponses = async () => {
            const apiResponse = await apiBackOfficeGetAllResponses({
                logicId,
                formId,
                questionId,
                responseId,
                iteration,
            });
            const {list} = apiResponse || [];
            setSeletectList(list.map(el => ({id: el.id, value: el.libelle})));
            let logicSelectedId = responseIdDest ? responseIdDest : null;
            setSelectedId(logicSelectedId || (list.length > 0 ? list[0].id : null));
            setLoading(false);
        };
        fetchAllResponses();
    }, []);

    const onParamValueChange = ({target: {value: param}}) => setParamValue(param);

    const setLabelId = label => {
        const {labelId, valueHTML, labelValueHTML} = label;
        setLabelIdValue(labelId);
        setLabelHtml(valueHTML || labelValueHTML);
    };

    const clearLabelUse = () => {
        setLabelIdValue(null);
        setLabelHtml(null);
    };

    const renderPopupElements = (labelId, typeId, fieldIdKey, fieldHTMLKey) => {
        const elementsArr = [];
        elementsArr.push({
            label: 'Search label',
            icon: faSearch,
            handler: () => setModalLabelSearch({labelId, typeId, fieldIdKey, fieldHTMLKey}),
        });
        !labelId &&
            elementsArr.push({
                label: 'Add label',
                icon: faPlus,
                handler: () => setModalLabel({labelId: 0, typeId, fieldIdKey, fieldHTMLKey}),
            });
        labelId &&
            elementsArr.push({
                label: 'Show label',
                icon: faPencilAlt,
                handler: () => setModalPreview(labelHtml),
            });
        labelId &&
            elementsArr.push({
                label: 'Clear label',
                icon: faTimes,
                handler: () => clearLabelUse(),
            });
        labelId &&
            elementsArr.push({
                label: 'Used in',
                icon: faLightbulb,
                handler: () => setModalUsedIn(labelId),
            });
        return elementsArr;
    };

    return (
        <>
            <ModalCustomVersion2
                close={close}
                header={name}
                body={
                    loading ? (
                        <LoadingBar />
                    ) : (
                        <div className={styles.modalBody}>
                            <div>
                                <SelectInput
                                    label={`Response`}
                                    value={selectedId}
                                    notAll={true}
                                    double={true}
                                    onChange={e => setSelectedId(e.target.value)}
                                    list={selectList}
                                />
                                <TextInput
                                    double={true}
                                    label={`Value`}
                                    onChange={onParamValueChange}
                                    value={paramValue}
                                />
                            </div>
                            <div style={{clear: 'both'}} />
                            <div style={{paddingLeft: 10, marginTop: 10}}>
                                <DataSheetFormFields
                                    label={'Message'}
                                    html={labelHtml}
                                    double={true}
                                    containerClassName="popover-nested"
                                    labelIcon={faList}
                                    labelPopup={renderPopupElements(
                                        labelIdValue,
                                        getLabelTypeId('Question'),
                                        'nameMessage',
                                        'remarklValueHTML'
                                    )}
                                />
                            </div>
                        </div>
                    )
                }
                footer={
                    <>
                        <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                        <Button
                            icon={'arrowRight'}
                            clickAction={() =>
                                onCreateOrUpdateLogicType(paramValue, null, selectedId, null, labelIdValue)
                            }
                        >{`Save`}</Button>
                    </>
                }
            />
            {modalLabel ? (
                <ModalLabelVersion2
                    intl={intl}
                    label={modalLabel}
                    setLabelId={setLabelId}
                    showModalUsedIn={labelId => setModalUsedIn(labelId)}
                    close={() => setModalLabel(null)}
                />
            ) : null}
            {modalLabelSearch !== null ? (
                <ModalLabelSearchVersion2
                    intl={intl}
                    target={modalLabelSearch}
                    setLabelId={setLabelId}
                    // setRefreshModalSearchFunction={this.setRefreshModalSearchFunction}
                    showModalPreview={valueHTML => setModalPreview(valueHTML)}
                    closeModalPreview={() => setModalPreview(null)}
                    showModalUsedIn={labelId => setModalUsedIn(labelId)}
                    closeModal={() => setModalLabelSearch(null)}
                    showModalLabel={(labelId, typeId, fieldIdKey, fieldHTMLKey) =>
                        setModalLabel({labelId, typeId, fieldIdKey, fieldHTMLKey})
                    }
                    closeModalLabel={() => setModalLabel(null)}
                    close={() => setModalLabelSearch(null)}
                />
            ) : null}
            {modalPreview !== null ? (
                <ModalLabelPreviewVersion2 intl={intl} close={() => setModalPreview(null)} valueHTML={modalPreview} />
            ) : null}
            {modalUsedIn ? (
                <ModalUsedInVersion2
                    elementId={modalUsedIn}
                    intl={intl}
                    type={rowType.RESPONSE}
                    close={() => setModalUsedIn(null)}
                />
            ) : null}
        </>
    );
};

export default injectIntl(ModalLogicTypeForbiddenResponse);
