import React from 'react';
import styles from './ModalApplicationNumbers.module.scss';
import ModalCustomVersion2 from './ModalCustomVersion2';
import {Button} from '../componentsFormV2';
import {checkIfUserHasAccessToMyPVR, getDecodedJWT} from '../utils';

interface IModalApplicationNumbersProps {
    applicationNumbers: string[];
    close: any;
}

const ModalApplicationNumbers = ({applicationNumbers, close}: IModalApplicationNumbersProps) => {
    let decoded = getDecodedJWT();
    const userHasAccessToMyPVR = checkIfUserHasAccessToMyPVR(decoded);

    const onInputLinkApplicationNumberClick = (applicationNumber: string) =>
        window.open(`/privateConsultationDetails?registerId=${applicationNumber}`, '_blank');

    return (
        <ModalCustomVersion2
            close={close}
            header={`Application numbers list`}
            body={
                <div>
                    {(applicationNumbers || []).map(applicationNumber => (
                        <div key={applicationNumber} className={styles.applicationNumber}>
                            {userHasAccessToMyPVR ? (
                                <div
                                    className={styles.applicationNumberLink}
                                    onClick={() => onInputLinkApplicationNumberClick(applicationNumber)}
                                >
                                    {applicationNumber}
                                </div>
                            ) : (
                                applicationNumber
                            )}
                        </div>
                    ))}
                </div>
            }
            footer={<Button variation={'danger'} icon={'close'} clickAction={close}>{`Close`}</Button>}
            plus={2}
            size={'sm'}
        />
    );
};

export default ModalApplicationNumbers;
