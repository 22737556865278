export const DEFAULT_ORDERED_FILTERS = [
    {name: 'Denomination', enabled: true},
    {name: 'Species Latin Name', enabled: true},
    {name: 'Countries', enabled: true},
    {name: 'Denomination Nature', enabled: true},
    {name: 'Variety Status', enabled: true},
    {name: 'Register type', enabled: true},
    {name: "Breeder's Reference", enabled: true},
    {name: "Breeder's Name", enabled: true},
    {name: 'Species Crop Sector', enabled: false},
    {name: 'Parties', enabled: false},
    {name: 'Application Number', enabled: false},
    {name: 'Application Date', enabled: false},
    {name: 'Application Publication Date', enabled: false},
    {name: 'Grant/Registration Number', enabled: false},
    {name: 'Grant/Registration Date', enabled: false},
    {name: 'Grant/Registration Pub Date', enabled: false},
    {name: 'Renewal Date', enabled: false},
    {name: 'Variety Insertion Date', enabled: false},
    {name: 'Denomination Insertion Date', enabled: false},
    {name: 'Species Class Code', enabled: false},
    {name: 'Species Class Name', enabled: false},
    {name: 'Denomination status', enabled: false},
    {name: 'Register name', enabled: false},
    {name: 'Valid Denomination', enabled: false},
];
