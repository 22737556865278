import React, {useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import moment from 'moment';
import {ModalCategoryProps} from './Props/ModalCategoryProps';
import {Right} from './Interfaces/ModalCategoryInterfaces';
import {ErrorMessages} from '../../CommonInterfaces/CommonInterfaces';
import {TreeElement} from '../TreeView/Interfaces/TreeViewInterfaces';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ModalCustomVersion2} from '../../../../commonModals';
import TextInput from '~components/TextInput';
import CustomTable from '../../../../components/CustomTable';
import DateInput from '~components/DateInput';
import {getConcatErrorMessage} from '../../CommonFunctions/CommonFunctions';
import {Button} from '../../../../componentsFormV2';
import {Error} from '../../../../componentsLayout';

export const categoryTypes = {
    GENERAL_INFORMATION: 3,
    VADEMECUM: 4,
    RND: 5,
    TEMPLATES: 7,
    QAS: 8,
    DESIGNATION_AGREEMENT: 9,
};

const ModalCategory = (props: ModalCategoryProps & RouteComponentProps) => {
    const [categoryId] = useState(props.categoryElement.id || 0);
    const [categoryType] = useState(props.categoryElement.categoryType || 0);
    const [categoryName, setCategoryName] = useState(props.categoryElement.parentTitle || '');
    const [beginDate, setBeginDate] = useState(props.categoryElement.beginDate || '');
    const [endDate, setEndDate] = useState(props.categoryElement.endDate || '');
    const [selectedRightIds, setSelectedRightIds] = useState<Array<string>>(props.categoryElement.rights || []);
    const [selectedRightsHash, setSelectedRightsHash] = useState<{[key: string]: boolean}>({});
    const [rights] = useState<Array<Right>>(props.rights || []);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [highlightErrors, setHighlightErrors] = useState(false);

    const updateSelectedRightsHash = (selectedRightIds: Array<string>) => {
        const rightsHash: {[key: string]: boolean} = {};
        selectedRightIds.forEach(id => {
            if (id) {
                rightsHash[id] = rights.filter(el => el.rightId === id).length > 0 ? true : false;
            }
        });
        setSelectedRightsHash(rightsHash);
    };

    useEffect(() => {
        updateSelectedRightsHash(selectedRightIds);
    }, []);

    const getCategoryType = (categoryTypeId: number) => {
        let categoryTypeName = '';
        switch (categoryTypeId) {
            case categoryTypes.GENERAL_INFORMATION:
                categoryTypeName = 'General Info';
                break;
            case categoryTypes.VADEMECUM:
                categoryTypeName = 'Vademecum';
                break;
            case categoryTypes.RND:
                categoryTypeName = 'R&D';
                break;
            case categoryTypes.TEMPLATES:
                categoryTypeName = 'Templates';
                break;
            case categoryTypes.QAS:
                categoryTypeName = 'My Quality Audit Area';
                break;
            case categoryTypes.DESIGNATION_AGREEMENT:
                categoryTypeName = 'Designation Agreement';
                break;
        }

        return categoryTypeName;
    };

    const onSelectedRightsChange = (selectedRightIds: Array<string>) => {
        setSelectedRightIds(selectedRightIds);
        updateSelectedRightsHash(selectedRightIds);
    };

    const onCategoryNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const label = event.target.value;
        setCategoryName(label);
    };

    const onBeginDateChange = (beginDate: string) => {
        setBeginDate(beginDate);
    };

    const onEndDateChange = (endDate: string) => {
        setEndDate(endDate);
    };

    const prepareCategoryDataForSave = (): TreeElement => {
        return {
            id: categoryId,
            order: props.categoryElement.order || '0',
            documentCode: '',
            title: categoryName,
            lastUpdateDate: props.categoryElement.lastUpdateDate || '',
            beginDate,
            endDate,
            categoryType,
            rights: selectedRightIds,
            file: props.categoryElement.file || [],
            level: props.categoryElement.level || 1,
            parentId: props.categoryElement.parentId || 0,
            parentTitle: categoryName,
            isCategory: true,
            showLastUpdateDate: props.categoryElement.showLastUpdateDate || false,
            children: props.categoryElement.children || [],
        };
    };

    const getErrorMessagesForSave = () => {
        setHighlightErrors(false);
        const diffValidStartEndDate = moment(beginDate, 'DD/MM/YYYY').diff(moment(endDate, 'DD/MM/YYYY'), 'days');
        const errorMessages: ErrorMessages = {
            errorInput: [],
            errorLogic: [],
        };

        if (categoryName.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a name for the category');
        }

        if (beginDate.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a begin date for the category');
        }

        if (endDate.length > 0 && diffValidStartEndDate > 0) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push('You have to type a begin date before the end date.');
        }

        return errorMessages;
    };

    const saveCategory = () => {
        const errorMessagesObj = getErrorMessagesForSave();
        let errorMessage = getConcatErrorMessage(errorMessagesObj);

        if (errorMessage.length > 0) {
            setErrorMessage(errorMessage);
            setHighlightErrors(true);
        }

        if (errorMessage.length === 0) {
            const categoryData = prepareCategoryDataForSave();
            props.saveCategory && props.saveCategory(categoryData);
        }
    };

    const getFieldsArea = () => {
        return (
            <React.Fragment>
                <TextInput
                    value={getCategoryType(categoryType)}
                    outsideLabel={'Category Type'}
                    outsideLabelWidth={140}
                    disabled={true}
                    double={true}
                />
                <div style={{clear: 'both'}}>{}</div>
                <TextInput
                    value={categoryName}
                    outsideLabel={'Category Name'}
                    outsideLabelWidth={140}
                    onChange={onCategoryNameChange}
                    double={true}
                />
                <div style={{clear: 'both'}}>{}</div>
                <DateInput
                    changeDateFrom={onBeginDateChange}
                    inputValueFrom={beginDate}
                    label={'Valid From'}
                    outsideLabel={'Validity Period'}
                    outsideLabelWidth={140}
                    simple={true}
                />
                <DateInput changeDateFrom={onEndDateChange} inputValueFrom={endDate} label={'To'} simple={true} />
                <div style={{clear: 'both'}}>{}</div>
            </React.Fragment>
        );
    };

    const getTableArea = () => {
        return (
            <CustomTable
                {...props}
                version={1}
                tableName={'rights'}
                tableType={'OBJECT'}
                tableSource={rights}
                id={'rightId'}
                timestamp={Date.now()}
                selectable={true}
                onSelectedChange={onSelectedRightsChange}
                selectDefault={selectedRightsHash}
                hideExcelButton={true}
                noChangePageSize={true}
                resultFieldsDefault={['description']}
                intl={props.intl}
                defaultOrder={'description'}
                reverseOrder={false}
                count={rights.length}
            />
        );
    };

    const getModalFooterArea = () => (
        <>
            {highlightErrors ? (
                <Error wizardQuestion={undefined} wizardResponse={undefined}>
                    <FontAwesomeIcon icon={faExclamationCircle as any} color={'white'} />
                    {` ${errorMessage}`}
                </Error>
            ) : null}
            <Button clickAction={props.close} variation={'danger'} icon={'close'}>{`Cancel`}</Button>
            <Button clickAction={saveCategory} icon={'arrowRight'}>{`Save Category`}</Button>
        </>
    );

    return (
        <ModalCustomVersion2
            loading={false}
            close={() => props.close && props.close()}
            header={`Category`}
            body={
                <div>
                    {getFieldsArea()}
                    {getTableArea()}
                </div>
            }
            footer={getModalFooterArea()}
        />
    );
};

export default injectIntl(withRouter(ModalCategory));
