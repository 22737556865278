import React from 'react';
import XLSX from 'xlsx';
import CustomCountUp from './CustomCountUp';
import CustomTable from '~components/CustomTable';
import DateInput from '~components/DateInput';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormFooterLinkButton from '~componentsForm/FormFooterLinkButton';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {Popover} from 'react-tiny-popover';
import SelectInput from '~components/SelectInput';
import SortableConfigureSearchFields from './SortableConfigureSearchFields';
import SortableConfigureTableColumns from './SortableConfigureTableColumns';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import TextLabelInput from '~components/TextLabelInput';
import VFModalSpecies from '../VFModalSpecies/VFModalSpecies';
import VFModalClass from '../VFModalClass/VFModalClass';
import VFModalVarietyDataSheet from '../VFModalVarietyDataSheet/VFModalVarietyDataSheet';
import cx from 'classnames';
import {
    checkIfFirstDateIsAfterSecondDate,
    compareResults,
    convertDate,
    dataHeaderConv,
    formatSearchDate,
    isFullSearchExp,
} from '../../utils/utilsVarietyFinder';
import {faCog, faColumns, faSpinner} from '@fortawesome/free-solid-svg-icons';
import graphQLClientInstance from '../../utils/axiosGraphQLClient';
import axiosGraphQLClient from '../../utils/axiosGraphQLClient';
import {getExportNoteQuery, getSearchQuery} from './VFSearchVarietiesService';
import {injectIntl} from 'react-intl';
import styles from './VFSearchVarieties.module.scss';
import {withRouter} from 'react-router-dom';
import ModalSelectClasses from './ModalSelectClasses';
import {DEFAULT_COLUMNS} from './data/DEFAULT_COLUMNS';
import {DEFAULT_ORDERED_FILTERS} from './data/DEFAULT_ORDERED_FILTERS';
import {listByIdNameVarietyStatus} from './data/listByIdNameVarietyStatus';
import {listByIdNameDenominationNature} from './data/listByIdNameDenominationNature';
import {listByIdNameRegisterType} from './data/listByIdNameRegisterType';
import {listByIdNameSpeciesCropSector} from './data/listByIdNameSpeciesCropSector';
import {listByIdNameDenominationStatus} from './data/listByIdNameDenominationStatus';
import {
    countriesListParsed,
    getCountriesListByIdName,
    getCountriesNameHash,
    getNameHashFromListByIdName,
    getSearchListFromSelectedElements,
} from './VFSearchVarieties.utils';
import Checkbox from '~components/Checkbox';
import {ModalAlertVersion2} from '../../commonModals';
import ModalSelectSpecies from './ModalSelectSpecies';
import ModalSaveSearch, {getSearchTermsPayloadFromState} from './ModalSaveSearch';
import ModalExportData from './ModalExportData';
import NavigationVarietyFinder from '../../shared/NavigationVarietyFinder';
import {Button} from '../../componentsFormV2';
import {trackPageView} from '../../utils';
import ComponentPopover from './table/ComponentPopover';
import axios from 'axios';

const TABLE_NAME = 'varietyFinderSearchVarieties';

const LOCAL_STORAGE_TABLE_COLUMNS_KEY = 'VFSearchVarietiesTableColumns';

const SEARCH_SELECT_MAP_FILTER: any = {
    contains: 'contains',
    equals: 'exact',
    starts: 'starts',
    start: 'starts',
    ends: 'ends',
};

const SEARCH_SELECT_MAP_FILTER_REVERSE: any = {
    contains: 'contains',
    exact: 'equals',
    starts: 'starts',
    ends: 'ends',
};

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

const DEFAULT_CRITERIA = {
    applicationDateFrom: '',
    applicationDateTo: '',
    applicationPublicationDateFrom: '',
    applicationPublicationDateTo: '',
    denominationNature: [],
    denominationInsertDateFrom: '',
    denominationInsertDateTo: '',
    grandRegistrationDateFrom: '',
    grandRegistrationDateTo: '',
    publicationgrandRegistrationDateFrom: '',
    publicationgrandRegistrationDateTo: '',
    renewalregistrationDateFrom: '',
    renewalregistrationDateTo: '',
    varietyInsertDateFrom: '',
    varietyInsertDateTo: '',
    order: null,
    reverse: false,
    pageNumber: 1,
    pageSize: parseInt(localStorage.getItem(`pageSize${TABLE_NAME}`) || '50'),
    breedersReferenceFilter: 'contains',
    breedersReferenceValue: '',
    denominationFilter: 'contains',
    denominationValue: '',
    validDenomination: '',
};

const getDefaultTableColumns = () => {
    let localStorageTableColumns = localStorage.getItem(LOCAL_STORAGE_TABLE_COLUMNS_KEY);
    if (localStorageTableColumns === null) {
        localStorage.setItem(LOCAL_STORAGE_TABLE_COLUMNS_KEY, JSON.stringify(DEFAULT_COLUMNS));
        return JSON.parse(JSON.stringify(DEFAULT_COLUMNS));
    } else {
        return JSON.parse(localStorageTableColumns);
    }
};

const TABLE_BUTTON_FILTER = {
    STATUTORY_REGISTERS: 'Statutory Registers',
    COMMERCIAL_REGISTERS: 'Commercial Registers',
};

interface IProps {
    history: any;
    location: any;
    intl: any;
}

export interface ISearchState {
    applicationDateFromError: boolean;
    applicationPublicationDateFromError: boolean;
    grandRegistrationDateFromError: boolean;
    publicationgrandRegistrationDateFromError: boolean;
    renewalregistrationDateFromError: boolean;
    varietyInsertDateFromError: boolean;
    denominationInsertDateFromError: boolean;
    count: number;
    countSecondary: number;
    criteria: TDefaultCriteria;
    forceHideSearchList: boolean;
    currentIndex: number;
    isModalAlertPrintExcelReachedLimitOpen: boolean;
    isModalAlertSaveSearchMaximumLimitOpen: boolean;
    isModalClassOpen: string | false;
    isModalExportDataOpen: any;
    isModalSaveSearchOpen: any;
    isModalSpeciesOpen: string | false;
    isModalVarietyDataSheetOpen: {currentIndex: number; varietyId: number} | false;
    isPopoverConfigureSearchFieldsOpen: boolean;
    isPopoverConfigureTableColumnsOpen: boolean;
    loading: number;
    orderedColumns: any[];
    orderedFilters: any;
    orderedFiltersTemporary: any;
    stats: {
        countries: number;
        denominations: number;
        registers: number;
        varieties: number;
    };
    screenLoaded: boolean;
    tableActiveButtonFilter: string;
    tableResults: any[];
    timestamp: number;
    textLabelInputDenominationNatureSelectedElements: any;
    textLabelInputVarietyStatusSelectedElements: any;
    textLabelInputRegisterTypeSelectedElements: any;
    textLabelInputSpeciesCropSectorSelectedElements: any;
    textLabelInputSpeciesDenominationStatusSelectedElements: any;
    textLabelInputPartiesSearchLoading: boolean;
    textLabelInputPartiesCurrentFilter: string;
    textLabelInputPartiesHash: any;
    textLabelInputPartiesList: any[];
    textLabelInputPartiesTimeoutId: any;
    textLabelInputPartiesValue: string;
    textLabelInputApplicationNumberSearchLoading: boolean;
    textLabelInputApplicationNumberCurrentFilter: string;
    textLabelInputApplicationNumberHash: any;
    textLabelInputApplicationNumberList: any[];
    textLabelInputApplicationNumberTimeoutId: any;
    textLabelInputApplicationNumberValue: string;
    textLabelInputGrantRegistrationNumberSearchLoading: boolean;
    textLabelInputGrantRegistrationNumberCurrentFilter: string;
    textLabelInputGrantRegistrationNumberHash: any;
    textLabelInputGrantRegistrationNumberList: any[];
    textLabelInputGrantRegistrationNumberTimeoutId: any;
    textLabelInputGrantRegistrationNumberValue: string;
    textLabelInputRegisterNameSearchLoading: boolean;
    textLabelInputRegisterNameCurrentFilter: string;
    textLabelInputRegisterNameHash: any;
    textLabelInputRegisterNameList: any[];
    textLabelInputRegisterNameTimeoutId: any;
    textLabelInputRegisterNameValue: string;
    textLabelInputBreedersNameSearchLoading: boolean;
    textLabelInputBreedersNameCurrentFilter: string;
    textLabelInputBreedersNameHash: any;
    textLabelInputBreedersNameList: any[];
    textLabelInputBreedersNameTimeoutId: any;
    textLabelInputBreedersNameValue: string;
    textLabelInputSpeciesClassCodeSearchLoading: boolean;
    textLabelInputSpeciesClassCodeCurrentFilter: string;
    textLabelInputSpeciesClassCodeHash: any;
    textLabelInputSpeciesClassCodeList: any[];
    textLabelInputSpeciesClassCodeTimeoutId: any;
    textLabelInputSpeciesClassCodeValue: string;
    textLabelInputSpeciesClassCodeSelectedElements: any;
    isModalSpeciesClassCodeAddFromListOpen: boolean;
    textLabelInputSpeciesClassNameSearchLoading: boolean;
    textLabelInputSpeciesClassNameCurrentFilter: string;
    textLabelInputSpeciesClassNameHash: any;
    textLabelInputSpeciesClassNameList: any[];
    textLabelInputSpeciesClassNameTimeoutId: any;
    textLabelInputSpeciesClassNameValue: string;
    textLabelInputSpeciesClassNameSelectedElements: any;
    isModalSpeciesClassNameAddFromListOpen: boolean;
    textLabelInputSpeciesLatinNameSearchLoading: boolean;
    textLabelInputSpeciesLatinNameCurrentFilter: string;
    textLabelInputSpeciesLatinNameHash: any;
    textLabelInputSpeciesLatinNameList: any[];
    textLabelInputSpeciesLatinNameTimeoutId: any;
    textLabelInputSpeciesLatinNameValue: string;
    textLabelInputSpeciesLatinNameSelectedElements: any;
    isModalSpeciesLatinNameAddFromListOpen: boolean;
    textLabelInputSpeciesLatinNameIncludeSynonymsValue: boolean;
    textLabelInputCountriesSelectedElements: any;
    textTimestamp: number;
}

class VFSearchVarieties extends React.Component<IProps, ISearchState> {
    previousSelectedDenominationNature: any = [];
    previousSelectedVarietyStatus: any = [];
    previousSelectedRegisterType: any = [];
    previousSelectedSpeciesCropSector: any = [];
    previousSelectedDenominationStatus: any = [];
    previousCountriesSelectedLabels: any = [];
    skipSearching = false;

    constructor(props: IProps) {
        super(props);
        this.state = {
            applicationDateFromError: false,
            applicationPublicationDateFromError: false,
            grandRegistrationDateFromError: false,
            publicationgrandRegistrationDateFromError: false,
            renewalregistrationDateFromError: false,
            varietyInsertDateFromError: false,
            denominationInsertDateFromError: false,
            count: 0,
            countSecondary: 0,
            criteria: DEFAULT_CRITERIA,
            currentIndex: 0,
            forceHideSearchList: false,
            isModalAlertPrintExcelReachedLimitOpen: false,
            isModalAlertSaveSearchMaximumLimitOpen: false,
            isModalClassOpen: false,
            isModalExportDataOpen: false,
            isModalSaveSearchOpen: false,
            isModalSpeciesOpen: false,
            isModalVarietyDataSheetOpen: false,
            isPopoverConfigureSearchFieldsOpen: false,
            isPopoverConfigureTableColumnsOpen: false,
            loading: 0,
            orderedColumns: getDefaultTableColumns(),
            orderedFilters: JSON.parse(JSON.stringify(DEFAULT_ORDERED_FILTERS)),
            orderedFiltersTemporary: JSON.parse(JSON.stringify(DEFAULT_ORDERED_FILTERS)),
            stats: {
                countries: 0,
                denominations: 0,
                registers: 0,
                varieties: 0,
            },
            screenLoaded: false,
            tableActiveButtonFilter: TABLE_BUTTON_FILTER.STATUTORY_REGISTERS,
            tableResults: [],
            timestamp: Date.now(),
            textLabelInputDenominationNatureSelectedElements: {},
            textLabelInputVarietyStatusSelectedElements: {},
            textLabelInputRegisterTypeSelectedElements: {},
            textLabelInputSpeciesCropSectorSelectedElements: {},
            textLabelInputSpeciesDenominationStatusSelectedElements: {},
            textLabelInputPartiesSearchLoading: false,
            textLabelInputPartiesCurrentFilter: 'contains',
            textLabelInputPartiesHash: {},
            textLabelInputPartiesList: [],
            textLabelInputPartiesTimeoutId: null,
            textLabelInputPartiesValue: '',
            textLabelInputApplicationNumberSearchLoading: false,
            textLabelInputApplicationNumberCurrentFilter: 'contains',
            textLabelInputApplicationNumberHash: {},
            textLabelInputApplicationNumberList: [],
            textLabelInputApplicationNumberTimeoutId: null,
            textLabelInputApplicationNumberValue: '',
            textLabelInputGrantRegistrationNumberSearchLoading: false,
            textLabelInputGrantRegistrationNumberCurrentFilter: 'contains',
            textLabelInputGrantRegistrationNumberHash: {},
            textLabelInputGrantRegistrationNumberList: [],
            textLabelInputGrantRegistrationNumberTimeoutId: null,
            textLabelInputGrantRegistrationNumberValue: '',
            textLabelInputRegisterNameSearchLoading: false,
            textLabelInputRegisterNameCurrentFilter: 'contains',
            textLabelInputRegisterNameHash: {},
            textLabelInputRegisterNameList: [],
            textLabelInputRegisterNameTimeoutId: null,
            textLabelInputRegisterNameValue: '',
            textLabelInputBreedersNameSearchLoading: false,
            textLabelInputBreedersNameCurrentFilter: 'contains',
            textLabelInputBreedersNameHash: {},
            textLabelInputBreedersNameList: [],
            textLabelInputBreedersNameTimeoutId: null,
            textLabelInputBreedersNameValue: '',
            textLabelInputSpeciesClassCodeSearchLoading: false,
            textLabelInputSpeciesClassCodeCurrentFilter: 'contains',
            textLabelInputSpeciesClassCodeHash: {},
            textLabelInputSpeciesClassCodeList: [],
            textLabelInputSpeciesClassCodeTimeoutId: null,
            textLabelInputSpeciesClassCodeValue: '',
            textLabelInputSpeciesClassCodeSelectedElements: {},
            isModalSpeciesClassCodeAddFromListOpen: false,
            textLabelInputSpeciesClassNameSearchLoading: false,
            textLabelInputSpeciesClassNameCurrentFilter: 'contains',
            textLabelInputSpeciesClassNameHash: {},
            textLabelInputSpeciesClassNameList: [],
            textLabelInputSpeciesClassNameTimeoutId: null,
            textLabelInputSpeciesClassNameValue: '',
            textLabelInputSpeciesClassNameSelectedElements: {},
            isModalSpeciesClassNameAddFromListOpen: false,
            textLabelInputSpeciesLatinNameSearchLoading: false,
            textLabelInputSpeciesLatinNameCurrentFilter: 'contains',
            textLabelInputSpeciesLatinNameHash: {},
            textLabelInputSpeciesLatinNameList: [],
            textLabelInputSpeciesLatinNameTimeoutId: null,
            textLabelInputSpeciesLatinNameValue: '',
            textLabelInputSpeciesLatinNameSelectedElements: {},
            isModalSpeciesLatinNameAddFromListOpen: false,
            textLabelInputSpeciesLatinNameIncludeSynonymsValue: true,
            textLabelInputCountriesSelectedElements: {},
            textTimestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'Varieties'});
        const {searchTerms} = (this.props.location.state && this.props.location.state.searchListItem) || {};
        if (searchTerms) {
            this.populateSearchFields(searchTerms);
        }
        this.loadJSONs(!!searchTerms);
    }

    loadJSONs = (hasSearchTerms: boolean) =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                Promise.all([
                    graphQLClientInstance.get('/api/v1/stats'),
                    hasSearchTerms ? null : graphQLClientInstance.get('/api/v1/users/searches'),
                ]).then(([JSONResponseStats, JSONResponseSearches]) => {
                    if (
                        !hasSearchTerms &&
                        JSONResponseSearches &&
                        JSONResponseSearches.data &&
                        JSONResponseSearches.data.Items &&
                        JSONResponseSearches.data.Items.length
                    ) {
                        let searchesList = JSONResponseSearches.data.Items;
                        let defaultSearch = searchesList.find(({isDefault}: any) => isDefault);
                        if (defaultSearch) {
                            this.populateSearchFields(defaultSearch.searchTerms);
                        } else {
                            let lastSearch = sessionStorage.getItem('lastSearch');
                            if (lastSearch) {
                                this.populateSearchFields(JSON.parse(lastSearch));
                            }
                        }
                    }
                    if (JSONResponseStats && JSONResponseStats.data) {
                        const {countries, denominations, registers, varieties} = JSONResponseStats.data;
                        this.setState(prev => ({
                            stats: {
                                countries,
                                denominations,
                                registers,
                                varieties,
                            },
                            loading: prev.loading - 1,
                        }));
                    }
                });
            }
        );

    populateSearchFields(searchTerms: any) {
        this.setState(prev => {
            const updatedOrderedFilters = JSON.parse(JSON.stringify(prev.orderedFilters));
            const updatedCriteria = JSON.parse(JSON.stringify(prev.criteria));
            const changeFilterVisibility = (filterName: string, visible: boolean) => {
                let indexToDisable = updatedOrderedFilters.findIndex(
                    (orderedFilter: any) => orderedFilter.name === filterName
                );
                if (indexToDisable >= 0) updatedOrderedFilters[indexToDisable].enabled = visible;
            };
            const enableFilter = (filterName: string) => changeFilterVisibility(filterName, true);
            const disableFilter = (filterName: string) => changeFilterVisibility(filterName, false);
            const populateDate = (dateArray: any, from: any, to: any) => {
                if (dateArray.name && dateArray.name.length) {
                    dateArray.name.forEach(({type, value}: any) => {
                        if (type === 'from') {
                            updatedCriteria[from] = convertDate(value);
                        }
                        if (type === 'to') {
                            updatedCriteria[to] = convertDate(value);
                        }
                    });
                }
            };
            const enableAndPopulateDateIfNeeded = (
                dateObject: any,
                filterTitle: string,
                fieldFrom: string,
                fieldTo: string
            ) => {
                if (dateObject) {
                    enableFilter(filterTitle);
                    populateDate(dateObject, fieldFrom, fieldTo);
                } else {
                    disableFilter(filterTitle);
                }
            };
            const {
                applicationDate,
                applicationPubDate,
                denominationInsertionDate,
                grantRegistrationDate,
                grantRegistrationPubDate,
                renewalDate,
                varietyInsertionDate,
            } = searchTerms;
            const enabledAndPopulateSelectIfNeeded = (
                selectValues: any,
                fieldTitle: string,
                listyByIdName: any[],
                fieldSelectedElements: string,
                fieldPreviousSelected:
                    | 'previousSelectedDenominationNature'
                    | 'previousSelectedVarietyStatus'
                    | 'previousSelectedRegisterType'
                    | 'previousSelectedSpeciesCropSector'
                    | 'previousSelectedDenominationStatus'
                    | 'previousCountriesSelectedLabels'
            ) => {
                if (selectValues) {
                    enableFilter(fieldTitle);
                    if (selectValues.name && selectValues.name.length) {
                        updatedState[fieldSelectedElements] = {};
                        if (selectValues.name.length === listyByIdName.length - 1) {
                            updatedState[fieldSelectedElements]['All'] = 'All';
                        }
                        selectValues.name.forEach(({value, label}: any) => {
                            updatedState[fieldSelectedElements][value] = label;
                        });
                        this[fieldPreviousSelected] = Object.keys(updatedState[fieldSelectedElements]);
                    }
                } else {
                    disableFilter(fieldTitle);
                }
            };
            const enableAndPopulateTextInputIfNeeded = (
                textInput: any,
                fieldTitle: string,
                fieldValue: string,
                fieldFilter: string,
                criteria: boolean = false
            ) => {
                if (textInput) {
                    enableFilter(fieldTitle);
                    if (textInput.name) {
                        (criteria ? updatedCriteria : updatedState)[fieldValue] = Array.isArray(textInput.name)
                            ? textInput.name.map(({value}: any) => value).join('')
                            : textInput.name;
                    }
                    SEARCH_SELECT_MAP_FILTER_REVERSE[textInput.nameSelect] &&
                        ((criteria ? updatedCriteria : updatedState)[fieldFilter] =
                            SEARCH_SELECT_MAP_FILTER_REVERSE[textInput.nameSelect]);
                } else {
                    disableFilter(fieldTitle);
                }
            };
            enableAndPopulateDateIfNeeded(
                applicationDate,
                'Application Date',
                'applicationDateFrom',
                'applicationDateTo'
            );
            enableAndPopulateDateIfNeeded(
                applicationPubDate,
                'Application Publication Date',
                'applicationPublicationDateFrom',
                'applicationPublicationDateTo'
            );
            enableAndPopulateDateIfNeeded(
                denominationInsertionDate,
                'Denomination Insertion Date',
                'denominationInsertDateFrom',
                'denominationInsertDateTo'
            );
            enableAndPopulateDateIfNeeded(
                grantRegistrationDate,
                'Grant/Registration Date',
                'grandRegistrationDateFrom',
                'grandRegistrationDateTo'
            );
            enableAndPopulateDateIfNeeded(
                grantRegistrationPubDate,
                'Grant/Registration Pub Date',
                'publicationgrandRegistrationDateFrom',
                'publicationgrandRegistrationDateTo'
            );
            enableAndPopulateDateIfNeeded(
                renewalDate,
                'Renewal Date',
                'renewalregistrationDateFrom',
                'renewalregistrationDateTo'
            );
            enableAndPopulateDateIfNeeded(
                varietyInsertionDate,
                'Variety Insertion Date',
                'varietyInsertDateFrom',
                'varietyInsertDateTo'
            );
            const updatedState: any = {};
            const {denominationNature, varietyStatus, registerType, speciesCropSector, denominationStatus, countries} =
                searchTerms;
            enabledAndPopulateSelectIfNeeded(
                denominationNature,
                'Denomination Nature',
                listByIdNameDenominationNature,
                'textLabelInputDenominationNatureSelectedElements',
                'previousSelectedDenominationNature'
            );
            enabledAndPopulateSelectIfNeeded(
                varietyStatus,
                'Variety Status',
                listByIdNameVarietyStatus,
                'textLabelInputVarietyStatusSelectedElements',
                'previousSelectedVarietyStatus'
            );
            enabledAndPopulateSelectIfNeeded(
                registerType,
                'Register type',
                listByIdNameRegisterType,
                'textLabelInputRegisterTypeSelectedElements',
                'previousSelectedRegisterType'
            );
            enabledAndPopulateSelectIfNeeded(
                speciesCropSector,
                'Species Crop Sector',
                listByIdNameSpeciesCropSector,
                'textLabelInputSpeciesCropSectorSelectedElements',
                'previousSelectedSpeciesCropSector'
            );
            enabledAndPopulateSelectIfNeeded(
                denominationStatus,
                'Denomination status',
                listByIdNameDenominationStatus,
                'textLabelInputSpeciesDenominationStatusSelectedElements',
                'previousSelectedDenominationStatus'
            );
            enabledAndPopulateSelectIfNeeded(
                countries,
                'Countries',
                countriesListParsed,
                'textLabelInputCountriesSelectedElements',
                'previousCountriesSelectedLabels'
            );
            const {
                applicationNumber,
                denomination,
                breedersReference,
                breedersName,
                parties,
                registerName,
                grantRegistrationNumber,
            } = searchTerms;
            enableAndPopulateTextInputIfNeeded(
                denomination,
                'Denomination',
                'denominationValue',
                'denominationFilter',
                true
            );
            enableAndPopulateTextInputIfNeeded(
                breedersReference,
                "Breeder's Reference",
                'breedersReferenceValue',
                'breedersReferenceFilter',
                true
            );
            enableAndPopulateTextInputIfNeeded(
                breedersName,
                "Breeder's Name",
                'textLabelInputBreedersNameValue',
                'textLabelInputBreedersNameCurrentFilter'
            );
            enableAndPopulateTextInputIfNeeded(
                parties,
                'Parties',
                'textLabelInputPartiesValue',
                'textLabelInputPartiesCurrentFilter'
            );
            enableAndPopulateTextInputIfNeeded(
                applicationNumber,
                'Application Number',
                'textLabelInputApplicationNumberValue',
                'textLabelInputApplicationNumberCurrentFilter'
            );
            enableAndPopulateTextInputIfNeeded(
                grantRegistrationNumber,
                'Grant/Registration Number',
                'textLabelInputGrantRegistrationNumberValue',
                'textLabelInputGrantRegistrationNumberCurrentFilter'
            );
            enableAndPopulateTextInputIfNeeded(
                registerName,
                'Register name',
                'textLabelInputRegisterNameValue',
                'textLabelInputRegisterNameCurrentFilter'
            );
            const {registeredWithOther, speciesLatinName, speciesClassCode, speciesClassName} = searchTerms;
            if (registeredWithOther) {
                enableFilter('Valid Denomination');
                if (registeredWithOther.name) {
                    updatedCriteria.validDenomination = registeredWithOther.name;
                }
            } else {
                disableFilter('Valid Denomination');
            }
            if (speciesLatinName) {
                enableFilter('Species Latin Name');
                if (Array.isArray(speciesLatinName.name) && speciesLatinName.name.length) {
                    speciesLatinName.name.forEach(({value}: any) => {
                        if (!updatedState.textLabelInputSpeciesLatinNameSelectedElements)
                            updatedState.textLabelInputSpeciesLatinNameSelectedElements = {};
                        updatedState.textLabelInputSpeciesLatinNameSelectedElements[value] = value;
                    });
                    const nameHash: {[key: string]: string[]} = {};
                    Object.keys(updatedState.textLabelInputSpeciesLatinNameSelectedElements).forEach(
                        (suggestion: any) => {
                            if (!nameHash[suggestion]) {
                                nameHash[suggestion] = [];
                            }
                            nameHash[suggestion].push(suggestion);
                        }
                    );
                    const listByIdName: any = Object.keys(nameHash).map(key => ({
                        ID: nameHash[key].join(','),
                        NAME: key,
                    }));
                    updatedState.textLabelInputSpeciesLatinNameHash = nameHash;
                    updatedState.textLabelInputSpeciesLatinNameList = listByIdName;
                }
                SEARCH_SELECT_MAP_FILTER_REVERSE[speciesLatinName.nameSelect] &&
                    (updatedState.textLabelInputSpeciesLatinNameCurrentFilter =
                        SEARCH_SELECT_MAP_FILTER_REVERSE[speciesLatinName.nameSelect]);
                updatedState.textLabelInputSpeciesLatinNameIncludeSynonymsValue = speciesLatinName.nameCheck;
            } else {
                disableFilter('Species Latin Name');
            }
            if (speciesClassCode) {
                enableFilter('Species Class Code');
                if (speciesClassCode.nameSelect === 'exact') {
                    if (speciesClassCode.name && speciesClassCode.name.length) {
                        speciesClassCode.name.forEach(({value}: any) => {
                            if (!updatedState.textLabelInputSpeciesClassCodeSelectedElements)
                                updatedState.textLabelInputSpeciesClassCodeSelectedElements = {};
                            updatedState.textLabelInputSpeciesClassCodeSelectedElements[value] = value;
                        });
                        const nameHash: {[key: string]: string[]} = {};
                        Object.keys(updatedState.textLabelInputSpeciesClassCodeSelectedElements).forEach(
                            (suggestion: any) => {
                                if (!nameHash[suggestion]) {
                                    nameHash[suggestion] = [];
                                }
                                nameHash[suggestion].push(suggestion);
                            }
                        );
                        const listByIdName: any = Object.keys(nameHash).map(key => ({
                            ID: nameHash[key].join(','),
                            NAME: key,
                        }));
                        updatedState.textLabelInputSpeciesClassCodeHash = nameHash;
                        updatedState.textLabelInputSpeciesClassCodeList = listByIdName;
                    }
                } else {
                    updatedState.textLabelInputSpeciesClassCodeValue = speciesClassCode.name;
                }
                updatedState.textLabelInputSpeciesClassCodeCurrentFilter =
                    SEARCH_SELECT_MAP_FILTER_REVERSE[speciesClassCode.nameSelect];
            } else {
                disableFilter('Species Class Code');
            }
            if (speciesClassName) {
                enableFilter('Species Class Name');
                if (speciesClassName.nameSelect === 'exact') {
                    if (speciesClassName.name && speciesClassName.name.length) {
                        speciesClassName.name.forEach(({value}: any) => {
                            if (!updatedState.textLabelInputSpeciesClassNameSelectedElements)
                                updatedState.textLabelInputSpeciesClassNameSelectedElements = {};
                            updatedState.textLabelInputSpeciesClassNameSelectedElements[value] = value;
                        });
                        const nameHash: {[key: string]: string[]} = {};
                        Object.keys(updatedState.textLabelInputSpeciesClassNameSelectedElements).forEach(
                            (suggestion: any) => {
                                if (!nameHash[suggestion]) {
                                    nameHash[suggestion] = [];
                                }
                                nameHash[suggestion].push(suggestion);
                            }
                        );
                        const listByIdName: any = Object.keys(nameHash).map(key => ({
                            ID: nameHash[key].join(','),
                            NAME: key,
                        }));
                        updatedState.textLabelInputSpeciesClassNameHash = nameHash;
                        updatedState.textLabelInputSpeciesClassNameList = listByIdName;
                    }
                } else {
                    updatedState.textLabelInputSpeciesClassNameValue = speciesClassName.name;
                }
                updatedState.textLabelInputSpeciesClassNameCurrentFilter =
                    SEARCH_SELECT_MAP_FILTER_REVERSE[speciesClassName.nameSelect];
            } else {
                disableFilter('Species Class Name');
            }
            return {
                criteria: updatedCriteria,
                orderedFilters: updatedOrderedFilters,
                orderedFiltersTemporary: updatedOrderedFilters,
                ...updatedState,
            };
        });
    }

    updateCriteriaValue = (criteriaValue: any, callbackAfterSearch?: any) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = criteriaValue.pageNumber && prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.criteria.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse !== undefined && prev.criteria.reverse !== criteriaValue.reverse;
                return {...prev, criteria};
            },
            () => {
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    this.search(callbackAfterSearch);
            }
        );
    };

    searchValues = () => {
        const values: any = {
            applicationDate: [],
            applicationPubDate: [],
            grantRegistrationDate: [],
            grantRegistrationPubDate: [],
            renewalDate: [],
            varietyInsertionDate: [],
            denominationInsertionDate: [],
            denominationNature: [],
            varietyStatus: [],
            registerType: [],
            speciesCropSector: [],
            denominationStatus: [],
            breedersReference: this.state.criteria.breedersReferenceValue,
            breedersReferenceSelect: SEARCH_SELECT_MAP_FILTER[this.state.criteria.breedersReferenceFilter],
            denomination: this.state.criteria.denominationValue,
            denominationSelect: SEARCH_SELECT_MAP_FILTER[this.state.criteria.denominationFilter],
            registeredWithOther:
                this.state.criteria.validDenomination !== 'All' ? this.state.criteria.validDenomination : '',
        };
        if (this.state.criteria.applicationDateFrom) {
            values.applicationDate.push({
                type: 'from',
                value: formatSearchDate(this.state.criteria.applicationDateFrom),
            });
        }
        if (this.state.criteria.applicationDateTo) {
            values.applicationDate.push({
                type: 'to',
                value: formatSearchDate(this.state.criteria.applicationDateTo),
            });
        }
        if (this.state.criteria.applicationPublicationDateFrom) {
            values.applicationPubDate.push({
                type: 'from',
                value: formatSearchDate(this.state.criteria.applicationPublicationDateFrom),
            });
        }
        if (this.state.criteria.applicationPublicationDateTo) {
            values.applicationPubDate.push({
                type: 'to',
                value: formatSearchDate(this.state.criteria.applicationPublicationDateTo),
            });
        }
        if (this.state.criteria.grandRegistrationDateFrom) {
            values.grantRegistrationDate.push({
                type: 'from',
                value: formatSearchDate(this.state.criteria.grandRegistrationDateFrom),
            });
        }
        if (this.state.criteria.grandRegistrationDateTo) {
            values.grantRegistrationDate.push({
                type: 'to',
                value: formatSearchDate(this.state.criteria.grandRegistrationDateTo),
            });
        }
        if (this.state.criteria.publicationgrandRegistrationDateFrom) {
            values.grantRegistrationPubDate.push({
                type: 'from',
                value: formatSearchDate(this.state.criteria.publicationgrandRegistrationDateFrom),
            });
        }
        if (this.state.criteria.publicationgrandRegistrationDateTo) {
            values.grantRegistrationPubDate.push({
                type: 'to',
                value: formatSearchDate(this.state.criteria.publicationgrandRegistrationDateTo),
            });
        }
        if (this.state.criteria.renewalregistrationDateFrom) {
            values.renewalDate.push({
                type: 'from',
                value: formatSearchDate(this.state.criteria.renewalregistrationDateFrom),
            });
        }
        if (this.state.criteria.renewalregistrationDateTo) {
            values.renewalDate.push({
                type: 'to',
                value: formatSearchDate(this.state.criteria.renewalregistrationDateTo),
            });
        }
        if (this.state.criteria.varietyInsertDateFrom) {
            values.varietyInsertionDate.push({
                type: 'from',
                value: formatSearchDate(this.state.criteria.varietyInsertDateFrom),
            });
        }
        if (this.state.criteria.varietyInsertDateTo) {
            values.varietyInsertionDate.push({
                type: 'to',
                value: formatSearchDate(this.state.criteria.varietyInsertDateTo),
            });
        }
        if (this.state.criteria.denominationInsertDateFrom) {
            values.denominationInsertionDate.push({
                type: 'from',
                value: formatSearchDate(this.state.criteria.denominationInsertDateFrom),
            });
        }
        if (this.state.criteria.denominationInsertDateTo) {
            values.denominationInsertionDate.push({
                type: 'to',
                value: formatSearchDate(this.state.criteria.denominationInsertDateTo),
            });
        }
        if (Object.keys(this.state.textLabelInputDenominationNatureSelectedElements).length) {
            values.denominationNature = getSearchListFromSelectedElements(
                this.state.textLabelInputDenominationNatureSelectedElements
            );
        }
        if (Object.keys(this.state.textLabelInputVarietyStatusSelectedElements).length) {
            values.varietyStatus = getSearchListFromSelectedElements(
                this.state.textLabelInputVarietyStatusSelectedElements,
                true
            );
        }
        if (Object.keys(this.state.textLabelInputRegisterTypeSelectedElements).length) {
            values.registerType = getSearchListFromSelectedElements(
                this.state.textLabelInputRegisterTypeSelectedElements
            );
        }
        if (Object.keys(this.state.textLabelInputSpeciesCropSectorSelectedElements).length) {
            values.speciesCropSector = getSearchListFromSelectedElements(
                this.state.textLabelInputSpeciesCropSectorSelectedElements
            );
        }
        if (Object.keys(this.state.textLabelInputSpeciesDenominationStatusSelectedElements).length) {
            values.denominationStatus = getSearchListFromSelectedElements(
                this.state.textLabelInputSpeciesDenominationStatusSelectedElements
            );
        }
        if (this.state.textLabelInputPartiesValue) {
            values.parties = this.state.textLabelInputPartiesValue;
            values.partiesSelect = SEARCH_SELECT_MAP_FILTER[this.state.textLabelInputPartiesCurrentFilter];
        }
        if (this.state.textLabelInputApplicationNumberValue) {
            values.applicationNumber = this.state.textLabelInputApplicationNumberValue;
            values.applicationNumberSelect =
                SEARCH_SELECT_MAP_FILTER[this.state.textLabelInputApplicationNumberCurrentFilter];
        }
        if (this.state.textLabelInputGrantRegistrationNumberValue) {
            values.grantRegistrationNumber = this.state.textLabelInputGrantRegistrationNumberValue;
            values.grantRegistrationNumberSelect =
                SEARCH_SELECT_MAP_FILTER[this.state.textLabelInputGrantRegistrationNumberCurrentFilter];
        }
        if (this.state.textLabelInputRegisterNameValue) {
            values.registerName = this.state.textLabelInputRegisterNameValue;
            values.registerNameSelect = SEARCH_SELECT_MAP_FILTER[this.state.textLabelInputRegisterNameCurrentFilter];
        }
        if (this.state.textLabelInputBreedersNameValue) {
            values.breedersName = this.state.textLabelInputBreedersNameValue;
            values.breedersNameSelect = SEARCH_SELECT_MAP_FILTER[this.state.textLabelInputBreedersNameCurrentFilter];
        }
        if (Object.keys(this.state.textLabelInputSpeciesClassCodeSelectedElements).length) {
            values.speciesClassCode = getSearchListFromSelectedElements(
                this.state.textLabelInputSpeciesClassCodeSelectedElements
            );
            values.speciesClassCodeSelect =
                SEARCH_SELECT_MAP_FILTER[this.state.textLabelInputSpeciesClassCodeCurrentFilter];
        }
        if (this.state.textLabelInputSpeciesClassCodeValue) {
            values.speciesClassCode = this.state.textLabelInputSpeciesClassCodeValue;
            values.speciesClassCodeSelect =
                SEARCH_SELECT_MAP_FILTER[this.state.textLabelInputSpeciesClassCodeCurrentFilter];
        }
        if (Object.keys(this.state.textLabelInputSpeciesClassNameSelectedElements).length) {
            values.speciesClassName = getSearchListFromSelectedElements(
                this.state.textLabelInputSpeciesClassNameSelectedElements
            );
            values.speciesClassNameSelect =
                SEARCH_SELECT_MAP_FILTER[this.state.textLabelInputSpeciesClassNameCurrentFilter];
        }
        if (this.state.textLabelInputSpeciesClassNameValue) {
            values.speciesClassName = this.state.textLabelInputSpeciesClassNameValue;
            values.speciesClassNameSelect =
                SEARCH_SELECT_MAP_FILTER[this.state.textLabelInputSpeciesClassNameCurrentFilter];
        }
        if (Object.keys(this.state.textLabelInputSpeciesLatinNameSelectedElements).length) {
            values.speciesLatinName = getSearchListFromSelectedElements(
                this.state.textLabelInputSpeciesLatinNameSelectedElements
            );
            values.speciesLatinNameSelect =
                SEARCH_SELECT_MAP_FILTER[this.state.textLabelInputSpeciesLatinNameCurrentFilter];
            values.includeSynonyms = this.state.textLabelInputSpeciesLatinNameIncludeSynonymsValue;
        }
        if (Object.keys(this.state.textLabelInputCountriesSelectedElements).length) {
            let countriesIds = Object.keys(this.state.textLabelInputCountriesSelectedElements).map((country: string) =>
                (((country || '').split('-') || []).pop() || '').trim()
            );
            let countriesObject: any = {};
            countriesIds.forEach(countryId => (countriesObject[countryId] = countryId));
            values.countries = getSearchListFromSelectedElements(countriesObject);
        }
        return values;
    };

    searchSortBy = () => {
        const sortBy: any = [];
        if (this.state.criteria.order) {
            sortBy.push({id: this.state.criteria.order, desc: this.state.criteria.reverse});
        }
        return sortBy;
    };

    search = (callback?: any) => {
        this.setState(
            {
                applicationDateFromError: false,
            },
            () => {
                if (
                    this.state.criteria.applicationDateFrom &&
                    this.state.criteria.applicationDateTo &&
                    checkIfFirstDateIsAfterSecondDate(
                        this.state.criteria.applicationDateFrom,
                        this.state.criteria.applicationDateTo
                    )
                ) {
                    return this.setState({
                        applicationDateFromError: true,
                    });
                }
                if (
                    this.state.criteria.applicationPublicationDateFrom &&
                    this.state.criteria.applicationPublicationDateTo &&
                    checkIfFirstDateIsAfterSecondDate(
                        this.state.criteria.applicationPublicationDateFrom,
                        this.state.criteria.applicationPublicationDateTo
                    )
                ) {
                    return this.setState({
                        applicationPublicationDateFromError: true,
                    });
                }
                if (
                    this.state.criteria.grandRegistrationDateFrom &&
                    this.state.criteria.grandRegistrationDateTo &&
                    checkIfFirstDateIsAfterSecondDate(
                        this.state.criteria.grandRegistrationDateFrom,
                        this.state.criteria.grandRegistrationDateTo
                    )
                ) {
                    return this.setState({
                        grandRegistrationDateFromError: true,
                    });
                }
                if (
                    this.state.criteria.publicationgrandRegistrationDateFrom &&
                    this.state.criteria.publicationgrandRegistrationDateTo &&
                    checkIfFirstDateIsAfterSecondDate(
                        this.state.criteria.publicationgrandRegistrationDateFrom,
                        this.state.criteria.publicationgrandRegistrationDateTo
                    )
                ) {
                    return this.setState({
                        publicationgrandRegistrationDateFromError: true,
                    });
                }
                if (
                    this.state.criteria.renewalregistrationDateFrom &&
                    this.state.criteria.renewalregistrationDateTo &&
                    checkIfFirstDateIsAfterSecondDate(
                        this.state.criteria.renewalregistrationDateFrom,
                        this.state.criteria.renewalregistrationDateTo
                    )
                ) {
                    return this.setState({
                        renewalregistrationDateFromError: true,
                    });
                }
                if (
                    this.state.criteria.varietyInsertDateFrom &&
                    this.state.criteria.varietyInsertDateTo &&
                    checkIfFirstDateIsAfterSecondDate(
                        this.state.criteria.varietyInsertDateFrom,
                        this.state.criteria.varietyInsertDateTo
                    )
                ) {
                    return this.setState({
                        varietyInsertDateFromError: true,
                    });
                }
                if (
                    this.state.criteria.denominationInsertDateFrom &&
                    this.state.criteria.denominationInsertDateTo &&
                    checkIfFirstDateIsAfterSecondDate(
                        this.state.criteria.denominationInsertDateFrom,
                        this.state.criteria.denominationInsertDateTo
                    )
                ) {
                    return this.setState({
                        denominationInsertDateFromError: true,
                    });
                }

                const tablePageSize = this.state.criteria.pageSize;
                const tablePageIndex = this.state.criteria.pageNumber - 1;
                const commercial = this.state.tableActiveButtonFilter === TABLE_BUTTON_FILTER.COMMERCIAL_REGISTERS;
                const sortBy = this.searchSortBy();
                const values = this.searchValues();
                sessionStorage.setItem('lastSearch', JSON.stringify(getSearchTermsPayloadFromState(this.state)));
                if (isFullSearchExp()) {
                    this.setState(
                        prev => ({loading: prev.loading + 1}),
                        () => {
                            graphQLClientInstance
                                .post(
                                    '/graphql',
                                    getSearchQuery(
                                        values,
                                        tablePageSize,
                                        tablePageIndex,
                                        sortBy,
                                        this.state.orderedColumns,
                                        commercial
                                    )
                                )
                                .then(JSONResponse => {
                                    if (
                                        JSONResponse &&
                                        JSONResponse.data &&
                                        JSONResponse.data.data &&
                                        (JSONResponse.data.data.searchVariety ||
                                            JSONResponse.data.data.searchVarietyCom)
                                    ) {
                                        let data: any = [];
                                        let total = 0;
                                        if (JSONResponse.data.data.searchVariety) {
                                            data = JSONResponse.data.data.searchVariety.data;
                                            total = JSONResponse.data.data.searchVariety.total;
                                        }
                                        if (JSONResponse.data.data.searchVarietyCom) {
                                            data = JSONResponse.data.data.searchVarietyCom.data;
                                            total = JSONResponse.data.data.searchVarietyCom.total;
                                        }
                                        this.setState(
                                            prev => ({
                                                count: total,
                                                loading: prev.loading - 1,
                                                screenLoaded: true,
                                                tableResults: data,
                                                timestamp: Date.now(),
                                            }),
                                            () => callback && callback()
                                        );
                                    }
                                });
                        }
                    );
                } else {
                    this.setState(
                        prev => ({loading: prev.loading + 1}),
                        () => {
                            Promise.all([
                                graphQLClientInstance.post(
                                    '/graphql',
                                    getSearchQuery(
                                        values,
                                        tablePageSize,
                                        tablePageIndex,
                                        sortBy,
                                        this.state.orderedColumns,
                                        commercial
                                    )
                                ),
                                graphQLClientInstance.post(
                                    '/graphql',
                                    getSearchQuery(
                                        values,
                                        tablePageSize,
                                        tablePageIndex,
                                        sortBy,
                                        this.state.orderedColumns,
                                        !commercial
                                    )
                                ),
                            ]).then(([JSONResponse, JSONResponseSecondaryFilterCounter]: any) => {
                                let countSecondary = 0;
                                if (
                                    JSONResponseSecondaryFilterCounter &&
                                    JSONResponseSecondaryFilterCounter.data &&
                                    JSONResponseSecondaryFilterCounter.data.data &&
                                    (JSONResponseSecondaryFilterCounter.data.data.searchVariety ||
                                        JSONResponseSecondaryFilterCounter.data.data.searchVarietyCom)
                                ) {
                                    if (commercial) {
                                        countSecondary =
                                            JSONResponseSecondaryFilterCounter.data.data.searchVariety.total || 0;
                                    } else {
                                        countSecondary =
                                            JSONResponseSecondaryFilterCounter.data.data.searchVarietyCom.total || 0;
                                    }
                                }
                                if (
                                    JSONResponse &&
                                    JSONResponse.data &&
                                    JSONResponse.data.data &&
                                    (JSONResponse.data.data.searchVariety || JSONResponse.data.data.searchVarietyCom)
                                ) {
                                    let data: any = [];
                                    let total = 0;
                                    if (JSONResponse.data.data.searchVariety) {
                                        data = JSONResponse.data.data.searchVariety.data;
                                        total = JSONResponse.data.data.searchVariety.total;
                                    }
                                    if (JSONResponse.data.data.searchVarietyCom) {
                                        data = JSONResponse.data.data.searchVarietyCom.data;
                                        total = JSONResponse.data.data.searchVarietyCom.total;
                                    }
                                    this.setState(
                                        prev => ({
                                            count: total,
                                            countSecondary,
                                            loading: prev.loading - 1,
                                            screenLoaded: true,
                                            tableResults: data,
                                            timestamp: Date.now(),
                                        }),
                                        () => callback && callback()
                                    );
                                }
                            });
                        }
                    );
                }
            }
        );
    };

    printExcel = () => {
        if (this.state.count + this.state.countSecondary > 50000) {
            return this.setState({isModalAlertPrintExcelReachedLimitOpen: true});
        }
        const values = this.searchValues();
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                graphQLClientInstance.post('/api/v1/exportnote', getExportNoteQuery(values)).then(JSONResponse => {
                    const resData = JSONResponse.data.map((limit: any) => {
                        const limitExtended: any = {
                            ...limit,
                            exported_records: 0,
                        };
                        limitExtended.exported_records =
                            limitExtended.recordsinSearch < limit.max_records || limitExtended.max_records < 0
                                ? limitExtended.recordsinSearch
                                : limitExtended.max_records;
                        return limitExtended;
                    });
                    const limitedExported = resData.reduce(
                        (accumulator: number, currentValue: any) =>
                            Number(accumulator) + Number(currentValue.exported_records),
                        []
                    );
                    const limitedRegistersTotal = resData.reduce(
                        (accumulator: number, currentValue: any) =>
                            Number(accumulator) + Number(currentValue.recordsinSearch),
                        []
                    );
                    const registersWithoutLimits =
                        this.state.count + this.state.countSecondary - Number(limitedRegistersTotal);
                    const exported =
                        limitedExported + registersWithoutLimits > 50000
                            ? 50000
                            : limitedExported + registersWithoutLimits;
                    this.setState(prev => ({
                        isModalExportDataOpen: {exported, data: resData},
                        loading: prev.loading - 1,
                    }));
                });
            }
        );
    };

    openModalSpeciesClassCodeAddFromList = () => this.setState({isModalSpeciesClassCodeAddFromListOpen: true});

    closeModalSpeciesClassCodeAddFromList = () => this.setState({isModalSpeciesClassCodeAddFromListOpen: false});

    openModalSpeciesClassNameAddFromList = () => this.setState({isModalSpeciesClassNameAddFromListOpen: true});

    closeModalSpeciesClassNameAddFromList = () => this.setState({isModalSpeciesClassNameAddFromListOpen: false});

    openModalSpeciesLatinNameAddFromList = () => this.setState({isModalSpeciesLatinNameAddFromListOpen: true});

    closeModalSpeciesLatinNameAddFromList = () => this.setState({isModalSpeciesLatinNameAddFromListOpen: false});

    closeModalClass = () => this.setState({isModalClassOpen: false});

    closeModalSpecies = () => this.setState({isModalSpeciesOpen: false});

    closeModalVarietyDataSheet = () => this.setState({isModalVarietyDataSheetOpen: false});

    closeModalAlertPrintExcelReachedLimit = () => this.setState({isModalAlertPrintExcelReachedLimitOpen: false});

    closeModalAlertSaveSearchMaximumLimit = () => this.setState({isModalAlertSaveSearchMaximumLimitOpen: false});

    closeModalSaveSearch = () => this.setState({isModalSaveSearchOpen: false});

    closeModalExportData = () => this.setState({isModalExportDataOpen: false});

    sectionBetweenHeaderAndTable = () => {
        if (isFullSearchExp()) return null;
        return (
            <div className={styles.sectionBetweenHeaderAndTable}>
                <button
                    className={cx(
                        styles.tableFilterButton,
                        this.state.tableActiveButtonFilter === TABLE_BUTTON_FILTER.STATUTORY_REGISTERS &&
                            styles.tableFilterButtonActive
                    )}
                    type={`button`}
                    onClick={() => this.onTableFilterButtonClick(TABLE_BUTTON_FILTER.STATUTORY_REGISTERS)}
                >{`Statutory Registers`}</button>
                <button
                    className={cx(
                        styles.tableFilterButton,
                        this.state.tableActiveButtonFilter === TABLE_BUTTON_FILTER.COMMERCIAL_REGISTERS &&
                            styles.tableFilterButtonActive
                    )}
                    type={`button`}
                    onClick={() => this.onTableFilterButtonClick(TABLE_BUTTON_FILTER.COMMERCIAL_REGISTERS)}
                >{`Commercial Registers`}</button>
            </div>
        );
    };

    tableNavigationButtons = () => {
        return (
            <Popover
                isOpen={this.state.isPopoverConfigureTableColumnsOpen}
                positions={'right'}
                onClickOutside={this.onPopoverConfigureTableColumnsClickOutside}
                containerClassName={'container'}
                content={() => (
                    <div
                        className="custom-popover"
                        style={{
                            background: 'white',
                            color: 'black',
                            borderRadius: '4px',
                            width: '300px',
                            zIndex: 99,
                            position: 'relative',
                            padding: 5,
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: '#ccc',
                        }}
                    >
                        <div style={{maxHeight: 510, overflowY: 'auto'}}>
                            <SortableConfigureTableColumns
                                intl={this.props.intl}
                                orderedColumns={this.state.orderedColumns}
                                onCheckboxClick={this.onSortableConfigureTableColumnsCheckboxClick}
                                onSortEnd={this.onSortableConfigureTableColumnsSortEnd}
                            />
                        </div>
                        <div style={{paddingTop: 10, paddingBottom: 10}}>
                            <FormFooterButton
                                color={buttonColor.whiteGreen}
                                clickAction={this.onSortableConfigureTableColumnsButtonSelectDefaultClick}
                            >{`Select default`}</FormFooterButton>
                            <FormFooterButton
                                color={buttonColor.whiteGreen}
                                clickAction={this.onSortableConfigureTableColumnsButtonSelectAllClick}
                            >{`Select all`}</FormFooterButton>
                            <div style={{clear: 'both'}} />
                        </div>
                    </div>
                )}
            >
                <div className={styles.navigationBarV2LeftItem} onClick={this.onTableNavigationBarButtonColumnsClick}>
                    <FontAwesomeIcon style={{float: 'left'}} icon={faColumns as any} />
                    <div
                        style={{
                            float: 'left',
                            paddingLeft: 5,
                        }}
                    >
                        {`Columns`}
                    </div>
                    <div style={{clear: 'both'}} />
                </div>
            </Popover>
        );
    };

    filters = (): any => {
        return {
            Denomination: (
                <TextLabelInput
                    currentFilter={this.state.criteria.denominationFilter}
                    filter={true}
                    label={'Denomination'}
                    onFilterChange={this.onDenominationFilterChange}
                    onChange={this.onDenominationChange}
                    popOverTextFirstRow={`Internal designation of the variety before the latter is given an official denomination, which can identical to or different from the breeder’s reference. When applying in official procedures, the applicant should submit the  the same breeder’s reference and the same denomination.`}
                    value={this.state.criteria.denominationValue}
                    removeStyleClear={true}
                    triple={true}
                    noInfo={true}
                    placeholder={`Enter denomination (e.g. pink floyd)`}
                    size={'lg'}
                />
            ),
            'Species Latin Name': (
                <div className={styles.textLabelInputSpeciesLatinNameWrap}>
                    <TextLabelInput
                        onButtonAddFromListClick={this.openModalSpeciesLatinNameAddFromList}
                        buttonIcon={this.state.textLabelInputSpeciesLatinNameSearchLoading && faSpinner}
                        double={true}
                        currentFilter={this.state.textLabelInputSpeciesLatinNameCurrentFilter}
                        filterContainsKeepJSONResponseSortOrder={true}
                        filter={['starts', 'equals', 'contains', 'ends']}
                        forceHideSearchList={this.state.forceHideSearchList}
                        hideCounter={true}
                        label={`Species Latin Name`}
                        listByIdName={this.state.textLabelInputSpeciesLatinNameList}
                        nameHash={this.state.textLabelInputSpeciesLatinNameHash}
                        onBlur={this.onTextLabelInputSpeciesLatinNameBlur}
                        onEnterOverride={() => this.onTextLabelInputSpeciesLatinNameBlur('enter')}
                        onChange={this.onTextLabelInputSpeciesLatinNameChange}
                        onFilterChange={this.onTextLabelInputSpeciesLatinNameFilterChange}
                        onSelectionChange={this.onTextLabelInputSpeciesLatinNameSelectionChange}
                        popOverTextFirstRow={
                            <span>
                                The species&apos; Latin name. The list includes UPOV botanical names from the UPOV Genie
                                database, synonyms and other species. the following options are available:
                                <br />- Contains : returns records containing terms/characters entered – you can enter
                                several genus name or species terms like “hibiscus”, “zea may”, “rosa”…
                                <br />- Starts with : enter at least the first 3 letters of the species
                                <br />- Exact: enter at least 3 letters, select it from the list. You can include
                                synonyms (displayed in blue in the list) or exclude them
                            </span>
                        }
                        noInfo={true}
                        selectedElements={this.state.textLabelInputSpeciesLatinNameSelectedElements}
                        timestamp={this.state.textTimestamp}
                        removeStyleClear={true}
                        value={this.state.textLabelInputSpeciesLatinNameValue}
                        delay={300}
                        multiple={true}
                        size={'lg'}
                    />
                    <div className={styles.checkboxSpeciesLatinNameIncludeSynonymsWrap}>
                        <Checkbox
                            clickAction={this.onCheckboxSpeciesLatinNameIncludeSynonymsClick}
                            simple={true}
                            label={`Include Synonyms`}
                            value={this.state.textLabelInputSpeciesLatinNameIncludeSynonymsValue}
                            size={'lg'}
                        />
                    </div>
                </div>
            ),
            Countries: (
                <TextLabelInput
                    inputSearchList={true}
                    filterContainsKeepJSONResponseSortOrder={true}
                    hasSelectAllOption={true}
                    hideRemoveElementButton={true}
                    label={`Countries`}
                    listByIdName={getCountriesListByIdName()}
                    listMaxHeight={200}
                    maxElementsInList={50}
                    nameHash={getCountriesNameHash()}
                    onSelectionChange={this.onTextLabelInputCountriesSelectionChange}
                    popOverTextFirstRow={
                        <div
                            /* eslint-disable-next-line react/no-danger */
                            dangerouslySetInnerHTML={{
                                __html: `<span>Optional – Select one or several countries/area<br>-\tEuropean Union - EU: includes EU institutions (CPVO, European Commission, EUIPO) and all EU Member States<br>-\tEuropean Union - QZ: includes EU institutions only</span>`,
                            }}
                        />
                    }
                    noInfo={true}
                    timestamp={this.state.textTimestamp}
                    removeStyleClear={true}
                    selectedElements={this.state.textLabelInputCountriesSelectedElements}
                    useAsMultipleSelection={true}
                    value={``}
                    selectedLabelsAsInputText={true}
                    placeHolder={`Select`}
                    size={'lg'}
                />
            ),
            'Denomination Nature': (
                <TextLabelInput
                    useAsMultipleSelection={true}
                    autoSelection={true}
                    hideRemoveElementButton={true}
                    label={'Denomination Nature'}
                    onSelectionChange={this.onDenominationNatureChange}
                    delay={300}
                    selectedElements={this.state.textLabelInputDenominationNatureSelectedElements}
                    listByIdName={listByIdNameDenominationNature}
                    nameHash={getNameHashFromListByIdName(listByIdNameDenominationNature)}
                    popOverTextFirstRow={
                        <span>
                            - Denomination : varietal name
                            <br />- Synonym : synonym of varietal name
                            <br />- Trade name : commercial name
                            <br />- Trade mark : trade mark name
                        </span>
                    }
                    noInfo={true}
                    value={``}
                    removeStyleClear={true}
                    selectedLabelsAsInputText={true}
                    placeHolder={`Select`}
                    size={'lg'}
                />
            ),
            'Variety Status': (
                <TextLabelInput
                    useAsMultipleSelection={true}
                    autoSelection={true}
                    hideRemoveElementButton={true}
                    label={'Variety Status'}
                    onSelectionChange={this.onVarietyStatusChange}
                    delay={300}
                    selectedElements={this.state.textLabelInputVarietyStatusSelectedElements}
                    listByIdName={listByIdNameVarietyStatus}
                    nameHash={getNameHashFromListByIdName(listByIdNameVarietyStatus)}
                    popOverTextFirstRow={
                        <span>
                            - APPLICATION: under procedure of registration/grant
                            <br />- WITHDRAWN: withdrawn application
                            <br />- REJECTED: rejected application
                            <br />- REGISTERED: registered/granted
                            <br />- SURRENDERED: abandoned registration/right
                            <br />- TERMINATED: cancelled registration/right
                            <br />- EXPIRED: elapsed registration/right
                        </span>
                    }
                    noInfo={true}
                    value={``}
                    removeStyleClear={true}
                    selectedLabelsAsInputText={true}
                    placeHolder={`Select`}
                    size={'lg'}
                />
            ),
            'Register type': (
                <TextLabelInput
                    useAsMultipleSelection={true}
                    autoSelection={true}
                    hideRemoveElementButton={true}
                    label={'Register type'}
                    onSelectionChange={this.onRegisterTypeChange}
                    delay={300}
                    selectedElements={this.state.textLabelInputRegisterTypeSelectedElements}
                    listByIdName={listByIdNameRegisterType}
                    nameHash={getNameHashFromListByIdName(listByIdNameRegisterType)}
                    popOverTextFirstRow={
                        <span>
                            Register type:
                            <br />- PBR: Right granted to the breeder of a new plant variety giving the holder exclusive
                            control over the propagation of the variety for a number of years over a certain territory
                            <br />- PLP: Alternative system of protection of varieties
                            <br />- EUTM: Registered trademarks (living plants, class 31) at EUIPO
                            <br />- NLI: Varieties of agricultural and vegetable species eligible for marketing
                            <br />- FRU: Frumatis (EU Commission&apos;s Fruit Reproductive Material Information)
                            <br />- COM: Varieties present in a commercial register
                            <br />- ZZZ: Others types of registers
                            <br />- REF : Temporary list of varieties for which an official denomination’s proposal has
                            been received.
                        </span>
                    }
                    noInfo={true}
                    value={``}
                    removeStyleClear={true}
                    selectedLabelsAsInputText={true}
                    placeHolder={`Select`}
                    size={'lg'}
                />
            ),
            "Breeder's Reference": (
                <TextLabelInput
                    currentFilter={this.state.criteria.breedersReferenceFilter}
                    filter={['equals', 'contains']}
                    label={"Breeder's Reference"}
                    onFilterChange={this.onBreedersReferenceFilterChange}
                    onChange={this.onBreedersReferenceChange}
                    popOverTextFirstRow={`Internal designation of the variety before the latter is given an official denomination, which can identical to or different from the breeder’s reference. When applying in official procedures, the applicant should submit the  the same breeder’s reference and the same denomination.`}
                    noInfo={true}
                    value={this.state.criteria.breedersReferenceValue}
                    removeStyleClear={true}
                    size={'lg'}
                />
            ),
            "Breeder's Name": (
                <div className={styles.textLabelInputBreedersNameWrap}>
                    <TextLabelInput
                        buttonIcon={this.state.textLabelInputBreedersNameSearchLoading && faSpinner}
                        currentFilter={this.state.textLabelInputBreedersNameCurrentFilter}
                        double={true}
                        filterContainsKeepJSONResponseSortOrder={true}
                        filter={['equals', 'contains']}
                        forceHideSearchList={this.state.forceHideSearchList}
                        hideCounter={true}
                        label={`Breeder's Name`}
                        listByIdName={this.state.textLabelInputBreedersNameList}
                        nameHash={this.state.textLabelInputBreedersNameHash}
                        onChange={this.onTextLabelInputBreedersNameChange}
                        onFilterChange={this.onTextLabelInputBreedersNameFilterChange}
                        onSelectionChange={this.onTextLabelInputBreedersNameSelectionChange}
                        selectedElements={{}}
                        timestamp={this.state.textTimestamp}
                        removeStyleClear={true}
                        value={this.state.textLabelInputBreedersNameValue}
                        noInfo={true}
                        size={'lg'}
                    />
                </div>
            ),
            'Species Crop Sector': (
                <TextLabelInput
                    useAsMultipleSelection={true}
                    autoSelection={true}
                    hideRemoveElementButton={true}
                    label={'Species Crop Sector'}
                    onSelectionChange={this.onSpeciesCropSectorChange}
                    delay={300}
                    selectedElements={this.state.textLabelInputSpeciesCropSectorSelectedElements}
                    listByIdName={listByIdNameSpeciesCropSector}
                    nameHash={getNameHashFromListByIdName(listByIdNameSpeciesCropSector)}
                    popOverTextFirstRow={`Selecting several crop sectors will return species belonging to at least one of these crop`}
                    noInfo={true}
                    value={``}
                    removeStyleClear={true}
                    selectedLabelsAsInputText={true}
                    placeHolder={`Select`}
                    size={'lg'}
                />
            ),
            'Application Number': (
                <div className={styles.textLabelInputApplicationNumberWrap}>
                    <TextLabelInput
                        buttonIcon={this.state.textLabelInputApplicationNumberSearchLoading && faSpinner}
                        currentFilter={this.state.textLabelInputApplicationNumberCurrentFilter}
                        filterContainsKeepJSONResponseSortOrder={true}
                        filter={['starts', 'equals', 'contains']}
                        forceHideSearchList={this.state.forceHideSearchList}
                        hideCounter={true}
                        label={`Application Number`}
                        listByIdName={this.state.textLabelInputApplicationNumberList}
                        nameHash={this.state.textLabelInputApplicationNumberHash}
                        onChange={this.onTextLabelInputApplicationNumberChange}
                        onFilterChange={this.onTextLabelInputApplicationNumberFilterChange}
                        onSelectionChange={this.onTextLabelInputApplicationNumberSelectionChange}
                        popOverTextFirstRow={`File number/reference identifying the record in the corresponding register`}
                        noInfo={true}
                        selectedElements={{}}
                        timestamp={this.state.textTimestamp}
                        removeStyleClear={true}
                        value={this.state.textLabelInputApplicationNumberValue}
                        size={'lg'}
                    />
                </div>
            ),
            Parties: (
                <div className={styles.textLabelInputPartiesWrap}>
                    <TextLabelInput
                        buttonIcon={this.state.textLabelInputPartiesSearchLoading && faSpinner}
                        currentFilter={this.state.textLabelInputPartiesCurrentFilter}
                        filterContainsKeepJSONResponseSortOrder={true}
                        double={true}
                        filter={['equals', 'contains']}
                        forceHideSearchList={this.state.forceHideSearchList}
                        hideCounter={true}
                        label={`Parties`}
                        listByIdName={this.state.textLabelInputPartiesList}
                        nameHash={this.state.textLabelInputPartiesHash}
                        onChange={this.onTextLabelInputPartiesChange}
                        onFilterChange={this.onTextLabelInputPartiesFilterChange}
                        onSelectionChange={this.onTextLabelInputPartiesSelectionChange}
                        popOverTextFirstRow={`Search operates in fields breeder, applicant, maintainer, holder or other parties`}
                        noInfo={true}
                        selectedElements={{}}
                        timestamp={this.state.textTimestamp}
                        removeStyleClear={true}
                        value={this.state.textLabelInputPartiesValue}
                        triple={true}
                        size={'lg'}
                    />
                </div>
            ),
            'Grant/Registration Number': (
                <div className={styles.textLabelInputGrantRegistrationNumberWrap}>
                    <TextLabelInput
                        buttonIcon={this.state.textLabelInputGrantRegistrationNumberSearchLoading && faSpinner}
                        currentFilter={this.state.textLabelInputGrantRegistrationNumberCurrentFilter}
                        filterContainsKeepJSONResponseSortOrder={true}
                        filter={['starts', 'equals', 'contains']}
                        forceHideSearchList={this.state.forceHideSearchList}
                        hideCounter={true}
                        label={`Grant/Registration Number`}
                        listByIdName={this.state.textLabelInputGrantRegistrationNumberList}
                        nameHash={this.state.textLabelInputGrantRegistrationNumberHash}
                        onChange={this.onTextLabelInputGrantRegistrationNumberChange}
                        onFilterChange={this.onTextLabelInputGrantRegistrationNumberFilterChange}
                        onSelectionChange={this.onTextLabelInputGrantRegistrationNumberSelectionChange}
                        popOverTextFirstRow={`Reference identifying the variety once the title has been granted or registered.`}
                        noInfo={true}
                        selectedElements={{}}
                        timestamp={this.state.textTimestamp}
                        removeStyleClear={true}
                        value={this.state.textLabelInputGrantRegistrationNumberValue}
                        size={'lg'}
                    />
                </div>
            ),
            'Application Date': (
                <DateInput
                    changeDateFrom={this.onDateInputApplicationDateFromChange}
                    changeDateTo={this.onDateInputApplicationDateToChange}
                    inputValueFrom={this.state.criteria.applicationDateFrom}
                    inputValueTo={this.state.criteria.applicationDateTo}
                    label={'Application Date'}
                    maxDateFrom={new Date()}
                    maxDateTo={new Date()}
                    popOverTextFirstRow={`Date when the application for registration has been received (Format dd/mm/yyyy)`}
                    errorVarietyFinder={this.state.applicationDateFromError ? 'Date from must be before date to' : ''}
                    size={'lg'}
                />
            ),
            'Application Publication Date': (
                <DateInput
                    changeDateFrom={this.onDateInputApplicationPublicationDateFromChange}
                    changeDateTo={this.onDateInputApplicationPublicationDateToChange}
                    inputValueFrom={this.state.criteria.applicationPublicationDateFrom}
                    inputValueTo={this.state.criteria.applicationPublicationDateTo}
                    label={'Application Publication Date'}
                    maxDateFrom={new Date()}
                    maxDateTo={new Date()}
                    popOverTextFirstRow={`Publication date of the application in the corresponding register or official publication (Format dd/mm/yyyy)`}
                    errorVarietyFinder={
                        this.state.applicationPublicationDateFromError ? 'Date from must be before date to' : ''
                    }
                    size={'lg'}
                />
            ),
            'Grant/Registration Date': (
                <DateInput
                    changeDateFrom={this.onDateInputGrantRegistrationDateFromChange}
                    changeDateTo={this.onDateInputGrantRegistrationDateToChange}
                    inputValueFrom={this.state.criteria.grandRegistrationDateFrom}
                    inputValueTo={this.state.criteria.grandRegistrationDateTo}
                    label={'Grant/Registration Date'}
                    maxDateFrom={new Date()}
                    maxDateTo={new Date()}
                    popOverTextFirstRow={`Start date of registration or grant (Format dd/mm/yyyy)`}
                    errorVarietyFinder={
                        this.state.grandRegistrationDateFromError ? 'Date from must be before date to' : ''
                    }
                    size={'lg'}
                />
            ),
            'Grant/Registration Pub Date': (
                <DateInput
                    changeDateFrom={this.onDateInputGrantRegistrationPubDateFromChange}
                    changeDateTo={this.onDateInputGrantRegistrationPubDateToChange}
                    inputValueFrom={this.state.criteria.publicationgrandRegistrationDateFrom}
                    inputValueTo={this.state.criteria.publicationgrandRegistrationDateTo}
                    label={'Grant/Registration Pub Date'}
                    maxDateFrom={new Date()}
                    maxDateTo={new Date()}
                    popOverTextFirstRow={`Date of publication of the registration or grant in the corresponding register or official publication (Format dd/mm/yyyy)`}
                    errorVarietyFinder={
                        this.state.publicationgrandRegistrationDateFromError ? 'Date from must be before date to' : ''
                    }
                    size={'lg'}
                />
            ),
            'Renewal Date': (
                <DateInput
                    changeDateFrom={this.onDateInputRenewalDateFromChange}
                    changeDateTo={this.onDateInputRenewalDateToChange}
                    inputValueFrom={this.state.criteria.renewalregistrationDateFrom}
                    inputValueTo={this.state.criteria.renewalregistrationDateTo}
                    label={'Renewal Date'}
                    maxDateFrom={new Date()}
                    maxDateTo={new Date()}
                    popOverTextFirstRow={`Renewal date of registration (Format dd/mm/yyyy)`}
                    errorVarietyFinder={
                        this.state.renewalregistrationDateFromError ? 'Date from must be before date to' : ''
                    }
                    size={'lg'}
                />
            ),
            'Variety Insertion Date': (
                <DateInput
                    changeDateFrom={this.onDateInputVarietyInsertionDateFromChange}
                    changeDateTo={this.onDateInputVarietyInsertionDateToChange}
                    inputValueFrom={this.state.criteria.varietyInsertDateFrom}
                    inputValueTo={this.state.criteria.varietyInsertDateTo}
                    label={'Variety Insertion Date'}
                    maxDateFrom={new Date()}
                    maxDateTo={new Date()}
                    popOverTextFirstRow={`Insert date of the variety in the Variety Finder database (Format dd/mm/yyyy)`}
                    errorVarietyFinder={this.state.varietyInsertDateFromError ? 'Date from must be before date to' : ''}
                    size={'lg'}
                />
            ),
            'Denomination Insertion Date': (
                <DateInput
                    changeDateFrom={this.onDateInputDenominationInsertionDateFromChange}
                    changeDateTo={this.onDateInputDenominationInsertionDateToChange}
                    inputValueFrom={this.state.criteria.denominationInsertDateFrom}
                    inputValueTo={this.state.criteria.denominationInsertDateTo}
                    label={'Denomination Insertion Date'}
                    maxDateFrom={new Date()}
                    maxDateTo={new Date()}
                    popOverTextFirstRow={`Insert date of the denomination in the Variety Finder database (Format dd/mm/yyyy)`}
                    errorVarietyFinder={
                        this.state.denominationInsertDateFromError ? 'Date from must be before date to' : ''
                    }
                    size={'lg'}
                />
            ),
            'Species Class Code': (
                <div className={styles.textLabelInputSpeciesClassCodeWrap}>
                    <TextLabelInput
                        onButtonAddFromListClick={
                            this.state.textLabelInputSpeciesClassCodeCurrentFilter === 'equals'
                                ? this.openModalSpeciesClassCodeAddFromList
                                : undefined
                        }
                        buttonIcon={this.state.textLabelInputSpeciesClassCodeSearchLoading && faSpinner}
                        double={true}
                        currentFilter={this.state.textLabelInputSpeciesClassCodeCurrentFilter}
                        filterContainsKeepJSONResponseSortOrder={true}
                        filter={['starts', 'equals', 'contains']}
                        forceHideSearchList={this.state.forceHideSearchList}
                        hideCounter={true}
                        label={`Species Class Code`}
                        listByIdName={this.state.textLabelInputSpeciesClassCodeList}
                        nameHash={this.state.textLabelInputSpeciesClassCodeHash}
                        onChange={this.onTextLabelInputSpeciesClassCodeChange}
                        onFilterChange={this.onTextLabelInputSpeciesClassCodeFilterChange}
                        onSelectionChange={this.onTextLabelInputSpeciesClassCodeSelectionChange}
                        popOverTextFirstRow={`Identifier of the class`}
                        noInfo={true}
                        selectedElements={this.state.textLabelInputSpeciesClassCodeSelectedElements}
                        timestamp={this.state.textTimestamp}
                        removeStyleClear={true}
                        value={this.state.textLabelInputSpeciesClassCodeValue}
                        delay={300}
                        multiple={true}
                        size={'lg'}
                    />
                </div>
            ),
            'Species Class Name': (
                <div className={styles.textLabelInputSpeciesClassNameWrap}>
                    <TextLabelInput
                        onButtonAddFromListClick={
                            this.state.textLabelInputSpeciesClassNameCurrentFilter === 'equals'
                                ? this.openModalSpeciesClassNameAddFromList
                                : undefined
                        }
                        buttonIcon={this.state.textLabelInputSpeciesClassNameSearchLoading && faSpinner}
                        double={true}
                        currentFilter={this.state.textLabelInputSpeciesClassNameCurrentFilter}
                        filterContainsKeepJSONResponseSortOrder={true}
                        filter={['starts', 'equals', 'contains']}
                        forceHideSearchList={this.state.forceHideSearchList}
                        hideCounter={true}
                        label={`Species Class Name`}
                        listByIdName={this.state.textLabelInputSpeciesClassNameList}
                        nameHash={this.state.textLabelInputSpeciesClassNameHash}
                        onChange={this.onTextLabelInputSpeciesClassNameChange}
                        onFilterChange={this.onTextLabelInputSpeciesClassNameFilterChange}
                        onSelectionChange={this.onTextLabelInputSpeciesClassNameSelectionChange}
                        popOverTextFirstRow={`Name of the class`}
                        noInfo={true}
                        selectedElements={this.state.textLabelInputSpeciesClassNameSelectedElements}
                        timestamp={this.state.textTimestamp}
                        removeStyleClear={true}
                        value={this.state.textLabelInputSpeciesClassNameValue}
                        delay={300}
                        multiple={true}
                        size={'lg'}
                    />
                </div>
            ),
            'Denomination status': (
                <TextLabelInput
                    useAsMultipleSelection={true}
                    autoSelection={true}
                    hideRemoveElementButton={true}
                    label={'Denomination status'}
                    onSelectionChange={this.onDenominationStatusChange}
                    delay={300}
                    selectedElements={this.state.textLabelInputSpeciesDenominationStatusSelectedElements}
                    listByIdName={listByIdNameDenominationStatus}
                    nameHash={getNameHashFromListByIdName(listByIdNameDenominationStatus)}
                    popOverTextFirstRow={
                        <span>
                            Status of the denomination. the possible values are:
                            <br />- Proposed
                            <br />- Published
                            <br />- Approved
                            <br />- Rejected/Withdrawn/Cancelled can correspond today to :<br />* withdrawn or rejected
                            denomination’s proposals
                            <br />* denomination’s proposal replaced by a new denomination’s proposal.
                            <br />
                            In some registers, the status of the denomination is linked to the status of the variety:
                            the status of denomination is updated to ‘rejected/withdrawn/cancelled’ once the variety
                            status has changed to withdrawn, rejected, surrendered, expired, terminated.
                        </span>
                    }
                    noInfo={true}
                    value={``}
                    removeStyleClear={true}
                    selectedLabelsAsInputText={true}
                    placeHolder={`Select`}
                    size={'lg'}
                />
            ),
            'Register name': (
                <div className={styles.textLabelInputRegisterNameWrap}>
                    <TextLabelInput
                        buttonIcon={this.state.textLabelInputRegisterNameSearchLoading && faSpinner}
                        currentFilter={this.state.textLabelInputRegisterNameCurrentFilter}
                        filterContainsKeepJSONResponseSortOrder={true}
                        filter={['starts', 'equals', 'contains']}
                        forceHideSearchList={this.state.forceHideSearchList}
                        hideCounter={true}
                        label={`Register name`}
                        listByIdName={this.state.textLabelInputRegisterNameList}
                        nameHash={this.state.textLabelInputRegisterNameHash}
                        onChange={this.onTextLabelInputRegisterNameChange}
                        onFilterChange={this.onTextLabelInputRegisterNameFilterChange}
                        onSelectionChange={this.onTextLabelInputRegisterNameSelectionChange}
                        popOverTextFirstRow={`Name identifying the register, it usually corresponds to the name of official publication but can also be the name given to the register in the Variety Finder database.`}
                        noInfo={true}
                        selectedElements={{}}
                        timestamp={this.state.textTimestamp}
                        removeStyleClear={true}
                        value={this.state.textLabelInputRegisterNameValue}
                        size={'lg'}
                    />
                </div>
            ),
            'Valid Denomination': (
                <SelectInput
                    label={`Valid Denomination`}
                    value={this.state.criteria.validDenomination}
                    onChange={this.onValidDenominationChange}
                    list={[
                        {id: '0', value: 'Valid'},
                        {id: '1', value: 'Invalid'},
                    ]}
                    size={'lg'}
                />
            ),
        };
    };

    searchSpeciesLatinNameSuggestions = () => {
        if (!this.skipSearching) {
            this.setState({textLabelInputSpeciesLatinNameSearchLoading: true}, () => {
                axiosGraphQLClient
                    .post(
                        '/graphql',
                        this.autoCompletePostData(
                            'autoCompleteSpeciesLatinName',
                            this.state.textLabelInputSpeciesLatinNameValue,
                            'contains',
                            this.state.textLabelInputSpeciesLatinNameIncludeSynonymsValue,
                            `{ name principal }`
                        )
                    )
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.autoCompleteSpeciesLatinName
                        ) {
                            const nameHash: {[key: string]: string[]} = {};
                            [
                                ...Object.keys(this.state.textLabelInputSpeciesLatinNameSelectedElements),
                                ...JSONResponse.data.data.autoCompleteSpeciesLatinName.map(({name}: any) => name),
                            ].forEach((suggestion: any) => {
                                if (!nameHash[suggestion]) {
                                    nameHash[suggestion] = [];
                                }
                                nameHash[suggestion].push(suggestion);
                            });
                            const listByIdName: any = Object.keys(nameHash).map(key => {
                                let style: any = {};
                                let itemFound = JSONResponse.data.data.autoCompleteSpeciesLatinName.find(
                                    ({name}: any) => name === key
                                );
                                itemFound && itemFound.principal === 0 && (style.color = 'blue');
                                return {
                                    ID: nameHash[key].join(','),
                                    NAME: key,
                                    style,
                                };
                            });
                            this.setState({
                                forceHideSearchList: false,
                                textTimestamp: Date.now(),
                                textLabelInputSpeciesLatinNameSearchLoading: false,
                                textLabelInputSpeciesLatinNameHash: nameHash,
                                textLabelInputSpeciesLatinNameList: listByIdName,
                            });
                        }
                    })
                    .catch(err => ERROR([err]));
            });
        }
    };

    searchSpeciesClassCodeSuggestions = () => {
        if (!this.skipSearching) {
            this.setState({textLabelInputSpeciesClassCodeSearchLoading: true}, () => {
                axiosGraphQLClient
                    .post(
                        '/graphql',
                        this.autoCompletePostData(
                            'autoCompleteSpeciesClassCode',
                            this.state.textLabelInputSpeciesClassCodeValue,
                            'contains',
                            false
                        )
                    )
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.autoCompleteSpeciesClassCode
                        ) {
                            const nameHash: {[key: string]: string[]} = {};
                            [
                                ...Object.keys(this.state.textLabelInputSpeciesClassCodeSelectedElements),
                                ...JSONResponse.data.data.autoCompleteSpeciesClassCode,
                            ].forEach((suggestion: any) => {
                                if (!nameHash[suggestion]) {
                                    nameHash[suggestion] = [];
                                }
                                nameHash[suggestion].push(suggestion);
                            });
                            const listByIdName: any = Object.keys(nameHash).map(key => ({
                                ID: nameHash[key].join(','),
                                NAME: key,
                            }));
                            this.setState({
                                forceHideSearchList: false,
                                textTimestamp: Date.now(),
                                textLabelInputSpeciesClassCodeSearchLoading: false,
                                textLabelInputSpeciesClassCodeHash: nameHash,
                                textLabelInputSpeciesClassCodeList: listByIdName,
                            });
                        }
                    })
                    .catch(err => ERROR([err]));
            });
        }
    };

    searchSpeciesClassNameSuggestions = () => {
        if (!this.skipSearching) {
            this.setState({textLabelInputSpeciesClassNameSearchLoading: true}, () => {
                axiosGraphQLClient
                    .post(
                        '/graphql',
                        this.autoCompletePostData(
                            'autoCompleteSpeciesClass',
                            this.state.textLabelInputSpeciesClassNameValue,
                            'contains',
                            false
                        )
                    )
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.autoCompleteSpeciesClass
                        ) {
                            const nameHash: {[key: string]: string[]} = {};
                            [
                                ...Object.keys(this.state.textLabelInputSpeciesClassNameSelectedElements),
                                ...JSONResponse.data.data.autoCompleteSpeciesClass,
                            ].forEach((suggestion: any) => {
                                if (!nameHash[suggestion]) {
                                    nameHash[suggestion] = [];
                                }
                                nameHash[suggestion].push(suggestion);
                            });
                            const listByIdName: any = Object.keys(nameHash).map(key => ({
                                ID: nameHash[key].join(','),
                                NAME: key,
                            }));
                            this.setState({
                                forceHideSearchList: false,
                                textTimestamp: Date.now(),
                                textLabelInputSpeciesClassNameSearchLoading: false,
                                textLabelInputSpeciesClassNameHash: nameHash,
                                textLabelInputSpeciesClassNameList: listByIdName,
                            });
                        }
                    })
                    .catch(err => ERROR([err]));
            });
        }
    };

    searchBreedersNameSuggestions = () => {
        if (!this.skipSearching) {
            this.setState({textLabelInputBreedersNameSearchLoading: true}, () => {
                axiosGraphQLClient
                    .post(
                        '/graphql',
                        this.autoCompletePostData(
                            'autoCompleteBreederName',
                            this.state.textLabelInputBreedersNameValue,
                            'contains',
                            false
                        )
                    )
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.autoCompleteBreederName
                        ) {
                            const nameHash: {[key: string]: string[]} = {};
                            JSONResponse.data.data.autoCompleteBreederName.forEach((suggestion: any) => {
                                if (!nameHash[suggestion]) {
                                    nameHash[suggestion] = [];
                                }
                                nameHash[suggestion].push(suggestion);
                            });
                            const listByIdName: any = Object.keys(nameHash).map(key => ({
                                ID: nameHash[key].join(','),
                                NAME: key,
                            }));
                            this.setState({
                                forceHideSearchList: false,
                                textTimestamp: Date.now(),
                                textLabelInputBreedersNameSearchLoading: false,
                                textLabelInputBreedersNameHash: nameHash,
                                textLabelInputBreedersNameList: listByIdName,
                            });
                        }
                    })
                    .catch(err => ERROR([err]));
            });
        }
    };

    searchRegisterNameSuggestions = () => {
        if (!this.skipSearching) {
            this.setState({textLabelInputRegisterNameSearchLoading: true}, () => {
                axiosGraphQLClient
                    .post(
                        '/graphql',
                        this.autoCompletePostData(
                            'autoCompleteRegisterName',
                            this.state.textLabelInputRegisterNameValue,
                            'contains',
                            false
                        )
                    )
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.autoCompleteRegisterName
                        ) {
                            const nameHash: {[key: string]: string[]} = {};
                            JSONResponse.data.data.autoCompleteRegisterName.forEach((suggestion: any) => {
                                if (!nameHash[suggestion]) {
                                    nameHash[suggestion] = [];
                                }
                                nameHash[suggestion].push(suggestion);
                            });
                            const listByIdName: any = Object.keys(nameHash).map(key => ({
                                ID: nameHash[key].join(','),
                                NAME: key,
                            }));
                            this.setState({
                                forceHideSearchList: false,
                                textTimestamp: Date.now(),
                                textLabelInputRegisterNameSearchLoading: false,
                                textLabelInputRegisterNameHash: nameHash,
                                textLabelInputRegisterNameList: listByIdName,
                            });
                        }
                    })
                    .catch(err => ERROR([err]));
            });
        }
    };

    searchGrantRegistrationNumberSuggestions = () => {
        if (!this.skipSearching) {
            this.setState({textLabelInputGrantRegistrationNumberSearchLoading: true}, () => {
                axiosGraphQLClient
                    .post(
                        '/graphql',
                        this.autoCompletePostData(
                            'autoCompleteGrantNumber',
                            this.state.textLabelInputGrantRegistrationNumberValue,
                            'contains',
                            false
                        )
                    )
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.autoCompleteGrantNumber
                        ) {
                            const nameHash: {[key: string]: string[]} = {};
                            JSONResponse.data.data.autoCompleteGrantNumber.forEach((suggestion: any) => {
                                if (!nameHash[suggestion]) {
                                    nameHash[suggestion] = [];
                                }
                                nameHash[suggestion].push(suggestion);
                            });
                            const listByIdName: any = Object.keys(nameHash).map(key => ({
                                ID: nameHash[key].join(','),
                                NAME: key,
                            }));
                            this.setState({
                                forceHideSearchList: false,
                                textTimestamp: Date.now(),
                                textLabelInputGrantRegistrationNumberSearchLoading: false,
                                textLabelInputGrantRegistrationNumberHash: nameHash,
                                textLabelInputGrantRegistrationNumberList: listByIdName,
                            });
                        }
                    })
                    .catch(err => ERROR([err]));
            });
        }
    };

    searchApplicationNumberSuggestions = () => {
        if (!this.skipSearching) {
            this.setState({textLabelInputApplicationNumberSearchLoading: true}, () => {
                axiosGraphQLClient
                    .post(
                        '/graphql',
                        this.autoCompletePostData(
                            'autoCompleteApplicationNumber',
                            this.state.textLabelInputApplicationNumberValue,
                            'contains',
                            false
                        )
                    )
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.autoCompleteApplicationNumber
                        ) {
                            const nameHash: {[key: string]: string[]} = {};
                            JSONResponse.data.data.autoCompleteApplicationNumber.forEach((suggestion: any) => {
                                if (!nameHash[suggestion]) {
                                    nameHash[suggestion] = [];
                                }
                                nameHash[suggestion].push(suggestion);
                            });
                            const listByIdName: any = Object.keys(nameHash).map(key => ({
                                ID: nameHash[key].join(','),
                                NAME: key,
                            }));
                            this.setState({
                                forceHideSearchList: false,
                                textTimestamp: Date.now(),
                                textLabelInputApplicationNumberSearchLoading: false,
                                textLabelInputApplicationNumberHash: nameHash,
                                textLabelInputApplicationNumberList: listByIdName,
                            });
                        }
                    })
                    .catch(err => ERROR([err]));
            });
        }
    };

    searchPartiesSuggestions = () => {
        if (!this.skipSearching) {
            this.setState({textLabelInputPartiesSearchLoading: true}, () => {
                axiosGraphQLClient
                    .post(
                        '/graphql',
                        this.autoCompletePostData(
                            'autoCompleteParties',
                            this.state.textLabelInputPartiesValue,
                            'contains',
                            false
                        )
                    )
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.autoCompleteParties
                        ) {
                            const nameHash: {[key: string]: string[]} = {};
                            JSONResponse.data.data.autoCompleteParties.forEach((suggestion: any) => {
                                // let key = suggestion && suggestion.name;
                                if (!nameHash[suggestion]) {
                                    nameHash[suggestion] = [];
                                }
                                nameHash[suggestion].push(suggestion);
                            });
                            const listByIdName: any = Object.keys(nameHash).map(key => ({
                                ID: nameHash[key].join(','),
                                NAME: key,
                            }));
                            this.setState({
                                forceHideSearchList: false,
                                textTimestamp: Date.now(),
                                textLabelInputPartiesSearchLoading: false,
                                textLabelInputPartiesHash: nameHash,
                                textLabelInputPartiesList: listByIdName,
                            });
                        }
                    })
                    .catch(err => ERROR([err]));
            });
        }
    };

    autoCompletePostData = (
        autoCompleteName: string,
        term: string,
        type: string,
        includeSynonyms = false,
        fields = ''
    ) => {
        const synonyms = includeSynonyms ? `includeSynonyms:"1"` : '';

        return {
            query: `
    {
      ${autoCompleteName}(
        term: "${term.toLowerCase()}"
        type: "${type}"
        ${synonyms}
      ) ${fields}
    }`,
        };
    };

    resetCriteria = () =>
        this.setState({
            applicationDateFromError: false,
            applicationPublicationDateFromError: false,
            grandRegistrationDateFromError: false,
            publicationgrandRegistrationDateFromError: false,
            renewalregistrationDateFromError: false,
            varietyInsertDateFromError: false,
            denominationInsertDateFromError: false,
            criteria: DEFAULT_CRITERIA,
            timestamp: Date.now(),
            textLabelInputDenominationNatureSelectedElements: {},
            textLabelInputVarietyStatusSelectedElements: {},
            textLabelInputRegisterTypeSelectedElements: {},
            textLabelInputSpeciesCropSectorSelectedElements: {},
            textLabelInputSpeciesDenominationStatusSelectedElements: {},
            textLabelInputPartiesSearchLoading: false,
            textLabelInputPartiesCurrentFilter: 'contains',
            textLabelInputPartiesHash: {},
            textLabelInputPartiesList: [],
            textLabelInputPartiesTimeoutId: null,
            textLabelInputPartiesValue: '',
            textLabelInputApplicationNumberSearchLoading: false,
            textLabelInputApplicationNumberCurrentFilter: 'contains',
            textLabelInputApplicationNumberHash: {},
            textLabelInputApplicationNumberList: [],
            textLabelInputApplicationNumberTimeoutId: null,
            textLabelInputApplicationNumberValue: '',
            textLabelInputGrantRegistrationNumberSearchLoading: false,
            textLabelInputGrantRegistrationNumberCurrentFilter: 'contains',
            textLabelInputGrantRegistrationNumberHash: {},
            textLabelInputGrantRegistrationNumberList: [],
            textLabelInputGrantRegistrationNumberTimeoutId: null,
            textLabelInputGrantRegistrationNumberValue: '',
            textLabelInputRegisterNameSearchLoading: false,
            textLabelInputRegisterNameCurrentFilter: 'contains',
            textLabelInputRegisterNameHash: {},
            textLabelInputRegisterNameList: [],
            textLabelInputRegisterNameTimeoutId: null,
            textLabelInputRegisterNameValue: '',
            textLabelInputBreedersNameSearchLoading: false,
            textLabelInputBreedersNameCurrentFilter: 'contains',
            textLabelInputBreedersNameHash: {},
            textLabelInputBreedersNameList: [],
            textLabelInputBreedersNameTimeoutId: null,
            textLabelInputBreedersNameValue: '',
            textLabelInputSpeciesClassCodeSearchLoading: false,
            textLabelInputSpeciesClassCodeCurrentFilter: 'contains',
            textLabelInputSpeciesClassCodeHash: {},
            textLabelInputSpeciesClassCodeList: [],
            textLabelInputSpeciesClassCodeTimeoutId: null,
            textLabelInputSpeciesClassCodeValue: '',
            textLabelInputSpeciesClassCodeSelectedElements: {},
            isModalSpeciesClassCodeAddFromListOpen: false,
            textLabelInputSpeciesClassNameSearchLoading: false,
            textLabelInputSpeciesClassNameCurrentFilter: 'contains',
            textLabelInputSpeciesClassNameHash: {},
            textLabelInputSpeciesClassNameList: [],
            textLabelInputSpeciesClassNameTimeoutId: null,
            textLabelInputSpeciesClassNameValue: '',
            textLabelInputSpeciesClassNameSelectedElements: {},
            isModalSpeciesClassNameAddFromListOpen: false,
            textLabelInputSpeciesLatinNameSearchLoading: false,
            textLabelInputSpeciesLatinNameCurrentFilter: 'contains',
            textLabelInputSpeciesLatinNameHash: {},
            textLabelInputSpeciesLatinNameList: [],
            textLabelInputSpeciesLatinNameTimeoutId: null,
            textLabelInputSpeciesLatinNameValue: '',
            textLabelInputSpeciesLatinNameSelectedElements: {},
            isModalSpeciesLatinNameAddFromListOpen: false,
            textLabelInputSpeciesLatinNameIncludeSynonymsValue: true,
            textLabelInputCountriesSelectedElements: {},
            textTimestamp: Date.now(),
        });

    generatePromises = async (sortBy: any, values: any, totalItems: number, totalItems2: number, columns: any) => {
        let i = 0;
        let pageIdx = 0;
        const promiseArray: Array<Promise<any>> = [];
        const commercial = this.state.tableActiveButtonFilter === TABLE_BUTTON_FILTER.COMMERCIAL_REGISTERS;
        while (i < totalItems) {
            const body = getSearchQuery(values, 1000, pageIdx, sortBy, columns, commercial);
            // const promise = limiter.schedule(() => graphQLClientInstance.post('/api/v1/graphql', body));
            const promise = graphQLClientInstance.post('/graphql', body);
            promiseArray.push(promise);
            i += 1000;
            pageIdx++;
        }
        i = 0;
        pageIdx = 0;
        while (i < totalItems2) {
            const body = getSearchQuery(values, 1000, pageIdx, sortBy, columns, !commercial);
            // const promise = limiter.schedule(() => graphQLClientInstance.post('/api/v1/graphql', body));
            const promise = graphQLClientInstance.post('/graphql', body);
            promiseArray.push(promise);
            i += 1000;
            pageIdx++;
        }

        let dataArr: Array<any> = [];
        await axios.all(promiseArray).then(
            axios.spread((...res) => {
                dataArr = res
                    .map(r => {
                        if (r.data.data.searchVariety?.data) {
                            return r.data.data.searchVariety?.data;
                        } else if (r.data.data.searchVarietyCom?.data) {
                            return r.data.data.searchVarietyCom?.data;
                        }
                    })
                    .filter(Boolean);
            })
        );
        return dataArr;
    };

    onModalExportButtonCompleteExportClick = async () => {
        const sortBy = this.searchSortBy();
        const values = this.searchValues();
        let myData: any[] = [];
        const isModalExportDataOpen = JSON.parse(JSON.stringify(this.state.isModalExportDataOpen));
        this.setState(
            prev => ({isModalExportDataOpen: false, loading: prev.loading + 1}),
            async () => {
                const JSONResponse = await this.generatePromises(
                    sortBy,
                    values,
                    this.state.count,
                    this.state.countSecondary,
                    this.state.orderedColumns
                );
                if (JSONResponse) {
                    myData = JSONResponse;
                }
                const flattenData: any[] = myData.flat(2);
                if (this.state.countSecondary > 0 && sortBy.length) {
                    flattenData.sort(compareResults(sortBy[0]));
                }
                const limitedData: any[] = [];
                const limitsCounters: any = {};
                isModalExportDataOpen?.data.forEach((element: any) => {
                    limitsCounters[element.register] = element.max_records;
                });
                flattenData.forEach(element => {
                    if (element?.register in limitsCounters) {
                        if (limitsCounters[element.register] > 0) {
                            limitedData.push(element);
                            limitsCounters[element.register]--;
                        }
                    } else {
                        limitedData.push(element);
                    }
                });
                const columnsHeaderArray = this.state.orderedColumns
                    .filter(({enabled}: any) => enabled)
                    .map(({name}: any) => name);
                const resultGrid: any = [columnsHeaderArray.map(field => dataHeaderConv(field))];
                limitedData.forEach(data => {
                    const arrayValues: any = [];
                    columnsHeaderArray.map(columnTitle =>
                        arrayValues.push(
                            [
                                'applicationdate',
                                'grantdate',
                                'pubapplicationdate',
                                'pubgrantdate',
                                'varietyinsertdate',
                                'denominationinsertdate',
                                'enddate',
                                'futureexpirationdate',
                                'proposaldate',
                                'pubproposaldate',
                                'approvaldate',
                                'deno_end_date',
                            ].includes(columnTitle)
                                ? convertDate(data[columnTitle])
                                : Array.isArray(data[columnTitle])
                                ? data[columnTitle].join(', ')
                                : data[columnTitle]
                        )
                    );
                    resultGrid.push(arrayValues);
                });
                const workSheet = XLSX.utils.aoa_to_sheet(resultGrid);
                const workBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workBook, workSheet, 'Varieties');
                XLSX.writeFile(workBook, `Varieties.xlsx`);
                this.setState(prev => ({loading: prev.loading - 1}));
            }
        );
    };

    onModalSaveSearchSaveButtonClick = (payload: any) => {
        this.setState(
            prev => ({loading: prev.loading + 1, isModalSaveSearchOpen: false}),
            () => {
                graphQLClientInstance
                    .post('/api/v1/users/searches', payload)
                    .catch((error: any) => {
                        if (
                            error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message === 'limitreached'
                        ) {
                            this.setState({isModalAlertSaveSearchMaximumLimitOpen: true});
                        }
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    onDateInputApplicationDateFromChange = (applicationDateFrom: string) =>
        this.updateCriteriaValue({applicationDateFrom});

    onDateInputApplicationDateToChange = (applicationDateTo: string) => this.updateCriteriaValue({applicationDateTo});

    onDateInputApplicationPublicationDateFromChange = (applicationPublicationDateFrom: string) =>
        this.updateCriteriaValue({applicationPublicationDateFrom});

    onDateInputApplicationPublicationDateToChange = (applicationPublicationDateTo: string) =>
        this.updateCriteriaValue({applicationPublicationDateTo});

    onDateInputGrantRegistrationDateFromChange = (grandRegistrationDateFrom: string) =>
        this.updateCriteriaValue({grandRegistrationDateFrom});

    onDateInputGrantRegistrationDateToChange = (grandRegistrationDateTo: string) =>
        this.updateCriteriaValue({grandRegistrationDateTo});

    onDateInputRenewalDateFromChange = (renewalregistrationDateFrom: string) =>
        this.updateCriteriaValue({renewalregistrationDateFrom});

    onDateInputRenewalDateToChange = (renewalregistrationDateTo: string) =>
        this.updateCriteriaValue({renewalregistrationDateTo});

    onDateInputGrantRegistrationPubDateFromChange = (publicationgrandRegistrationDateFrom: string) =>
        this.updateCriteriaValue({publicationgrandRegistrationDateFrom});

    onDateInputGrantRegistrationPubDateToChange = (publicationgrandRegistrationDateTo: string) =>
        this.updateCriteriaValue({publicationgrandRegistrationDateTo});

    onDateInputVarietyInsertionDateFromChange = (varietyInsertDateFrom: string) =>
        this.updateCriteriaValue({varietyInsertDateFrom});

    onDateInputVarietyInsertionDateToChange = (varietyInsertDateTo: string) =>
        this.updateCriteriaValue({varietyInsertDateTo});

    onDateInputDenominationInsertionDateFromChange = (denominationInsertDateFrom: string) =>
        this.updateCriteriaValue({denominationInsertDateFrom});

    onDateInputDenominationInsertionDateToChange = (denominationInsertDateTo: string) =>
        this.updateCriteriaValue({denominationInsertDateTo});

    onLinkButtonHelpClick = () => window.open('/help/en/Search_varieties.html', '_blank');

    onLinkButtonConfigureSearchFieldsClick = () => this.setState({isPopoverConfigureSearchFieldsOpen: true});

    onPopoverConfigureSearchFieldsClickOutside = () =>
        this.setState(prev => ({
            isPopoverConfigureSearchFieldsOpen: false,
            orderedFilters: prev.orderedFiltersTemporary,
        }));

    onSortableConfigureSearchFieldsCheckboxClick = (name: string) =>
        this.setState(prev => {
            let updatedOrderedFilters = [...prev.orderedFiltersTemporary];
            let filterIndexChecked = updatedOrderedFilters.findIndex(({name: filterName}) => filterName === name);
            updatedOrderedFilters[filterIndexChecked] = {
                ...updatedOrderedFilters[filterIndexChecked],
                enabled: !updatedOrderedFilters[filterIndexChecked].enabled,
            };
            return {
                orderedFiltersTemporary: updatedOrderedFilters,
            };
        });

    onSortableConfigureSearchFieldsSortEnd = ({oldIndex, newIndex}: any) =>
        this.setState(prev => {
            const temp = [...prev.orderedFiltersTemporary];
            temp.splice(newIndex, 0, temp.splice(oldIndex, 1)[0]);
            return {
                orderedFiltersTemporary: temp,
            };
        });

    onSortableComponentButtonSelectAllClick = () =>
        this.setState(prev => {
            const updatedFilters = [...prev.orderedFiltersTemporary];
            updatedFilters.forEach((_, i) => (updatedFilters[i].enabled = true));
            return {
                orderedFiltersTemporary: updatedFilters,
            };
        });

    onSortableComponentButtonSelectDefaultClick = () =>
        this.setState(prev => {
            const updatedFilters = [...prev.orderedFiltersTemporary];
            updatedFilters.forEach(({name}, i) => {
                let defaultEnabled = (DEFAULT_ORDERED_FILTERS.find(({name: filterName}) => filterName === name) || {})
                    .enabled;
                updatedFilters[i].enabled = defaultEnabled;
            });
            return {
                orderedFiltersTemporary: updatedFilters,
            };
        });

    onSortableConfigureTableColumnsCheckboxClick = (name: string) => {
        this.setState(prev => {
            let updatedOrderedColumns = [...prev.orderedColumns];
            let columnIndexChecked = updatedOrderedColumns.findIndex(({name: columnName}) => columnName === name);
            updatedOrderedColumns[columnIndexChecked] = {
                ...updatedOrderedColumns[columnIndexChecked],
                enabled: !updatedOrderedColumns[columnIndexChecked].enabled,
            };
            localStorage.setItem(LOCAL_STORAGE_TABLE_COLUMNS_KEY, JSON.stringify(updatedOrderedColumns));
            return {
                orderedColumns: updatedOrderedColumns,
            };
        });
    };

    onSortableConfigureTableColumnsSortEnd = ({oldIndex, newIndex}: any) => {
        if (newIndex === 0) {
            return;
        }
        this.setState(prev => {
            const updatedOrderedColumns = [...prev.orderedColumns];
            updatedOrderedColumns.splice(newIndex, 0, updatedOrderedColumns.splice(oldIndex, 1)[0]);
            localStorage.setItem(LOCAL_STORAGE_TABLE_COLUMNS_KEY, JSON.stringify(updatedOrderedColumns));
            return {
                orderedColumns: updatedOrderedColumns,
            };
        });
    };

    onSortableConfigureTableColumnsButtonSelectDefaultClick = () => {
        const orderedColumns = JSON.parse(JSON.stringify(DEFAULT_COLUMNS));
        localStorage.setItem(LOCAL_STORAGE_TABLE_COLUMNS_KEY, JSON.stringify(orderedColumns));
        this.setState({
            orderedColumns,
        });
    };

    onSortableConfigureTableColumnsButtonSelectAllClick = () => {
        this.setState(prev => {
            const updatedOrderedColumns = [...prev.orderedColumns];
            updatedOrderedColumns.forEach((_, i) => (updatedOrderedColumns[i].enabled = true));
            localStorage.setItem(LOCAL_STORAGE_TABLE_COLUMNS_KEY, JSON.stringify(updatedOrderedColumns));
            return {
                orderedColumns: updatedOrderedColumns,
            };
        });
    };

    onPopoverConfigureTableColumnsClickOutside = () =>
        this.setState(
            {
                isPopoverConfigureTableColumnsOpen: false,
            },
            () => this.search()
        );

    onTableNavigationBarButtonColumnsClick = () => this.setState({isPopoverConfigureTableColumnsOpen: true});

    onTableFilterButtonClick = (tableActiveButtonFilter: string) =>
        this.setState({tableActiveButtonFilter}, () => this.search());

    onDenominationNatureChange = (_: any) => {
        const selectedElements = {...this.state.textLabelInputDenominationNatureSelectedElements};
        if (this.previousSelectedDenominationNature.includes('All') && !Object.keys(selectedElements).includes('All')) {
            this.previousSelectedDenominationNature = [];
            this.setState({textLabelInputDenominationNatureSelectedElements: {}});
            return;
        }
        if (Object.keys(selectedElements).includes('All') && !this.previousSelectedDenominationNature.includes('All')) {
            const nameHash = getNameHashFromListByIdName(listByIdNameDenominationNature);
            this.previousSelectedDenominationNature = Object.keys(nameHash);
            this.setState({textLabelInputDenominationNatureSelectedElements: nameHash});
            return;
        }
        if (
            Object.keys(selectedElements).includes('All') &&
            Object.keys(selectedElements).length < listByIdNameDenominationNature.length
        ) {
            delete selectedElements['All'];
            this.setState({textLabelInputDenominationNatureSelectedElements: selectedElements});
        }
        this.previousSelectedDenominationNature = Object.keys(selectedElements);
    };

    onVarietyStatusChange = (_: any) => {
        const selectedElements = {...this.state.textLabelInputVarietyStatusSelectedElements};
        if (this.previousSelectedVarietyStatus.includes('All') && !Object.keys(selectedElements).includes('All')) {
            this.previousSelectedVarietyStatus = [];
            this.setState({textLabelInputVarietyStatusSelectedElements: {}});
            return;
        }
        if (Object.keys(selectedElements).includes('All') && !this.previousSelectedVarietyStatus.includes('All')) {
            const nameHash = getNameHashFromListByIdName(listByIdNameVarietyStatus);
            this.previousSelectedVarietyStatus = Object.keys(nameHash);
            this.setState({textLabelInputVarietyStatusSelectedElements: nameHash});
            return;
        }
        if (
            Object.keys(selectedElements).includes('All') &&
            Object.keys(selectedElements).length < listByIdNameVarietyStatus.length
        ) {
            delete selectedElements['All'];
            this.setState({textLabelInputVarietyStatusSelectedElements: selectedElements});
        }
        this.previousSelectedVarietyStatus = Object.keys(selectedElements);
    };

    onRegisterTypeChange = (_: any) => {
        const selectedElements = {...this.state.textLabelInputRegisterTypeSelectedElements};
        if (this.previousSelectedRegisterType.includes('All') && !Object.keys(selectedElements).includes('All')) {
            this.previousSelectedRegisterType = [];
            this.setState({textLabelInputRegisterTypeSelectedElements: {}});
            return;
        }
        if (Object.keys(selectedElements).includes('All') && !this.previousSelectedRegisterType.includes('All')) {
            const nameHash = getNameHashFromListByIdName(listByIdNameRegisterType);
            this.previousSelectedRegisterType = Object.keys(nameHash);
            this.setState({textLabelInputRegisterTypeSelectedElements: nameHash});
            return;
        }
        if (
            Object.keys(selectedElements).includes('All') &&
            Object.keys(selectedElements).length < listByIdNameRegisterType.length
        ) {
            delete selectedElements['All'];
            this.setState({textLabelInputRegisterTypeSelectedElements: selectedElements});
        }
        this.previousSelectedRegisterType = Object.keys(selectedElements);
    };

    onSpeciesCropSectorChange = (_: any) => {
        const selectedElements = {...this.state.textLabelInputSpeciesCropSectorSelectedElements};
        if (this.previousSelectedSpeciesCropSector.includes('All') && !Object.keys(selectedElements).includes('All')) {
            this.previousSelectedSpeciesCropSector = [];
            this.setState({textLabelInputSpeciesCropSectorSelectedElements: {}});
            return;
        }
        if (Object.keys(selectedElements).includes('All') && !this.previousSelectedSpeciesCropSector.includes('All')) {
            const nameHash = getNameHashFromListByIdName(listByIdNameSpeciesCropSector);
            this.previousSelectedSpeciesCropSector = Object.keys(nameHash);
            this.setState({textLabelInputSpeciesCropSectorSelectedElements: nameHash});
            return;
        }
        if (
            Object.keys(selectedElements).includes('All') &&
            Object.keys(selectedElements).length < listByIdNameSpeciesCropSector.length
        ) {
            delete selectedElements['All'];
            this.setState({textLabelInputSpeciesCropSectorSelectedElements: selectedElements});
        }
        this.previousSelectedSpeciesCropSector = Object.keys(selectedElements);
    };

    onDenominationStatusChange = (_: any) => {
        const selectedElements = {...this.state.textLabelInputSpeciesDenominationStatusSelectedElements};
        if (this.previousSelectedDenominationStatus.includes('All') && !Object.keys(selectedElements).includes('All')) {
            this.previousSelectedDenominationStatus = [];
            this.setState({textLabelInputSpeciesDenominationStatusSelectedElements: {}});
            return;
        }
        if (Object.keys(selectedElements).includes('All') && !this.previousSelectedDenominationStatus.includes('All')) {
            const nameHash = getNameHashFromListByIdName(listByIdNameDenominationStatus);
            this.previousSelectedDenominationStatus = Object.keys(nameHash);
            this.setState({textLabelInputSpeciesDenominationStatusSelectedElements: nameHash});
            return;
        }
        if (
            Object.keys(selectedElements).includes('All') &&
            Object.keys(selectedElements).length < listByIdNameDenominationStatus.length
        ) {
            delete selectedElements['All'];
            this.setState({textLabelInputSpeciesDenominationStatusSelectedElements: selectedElements});
        }
        this.previousSelectedDenominationStatus = Object.keys(selectedElements);
    };

    onBreedersReferenceChange = (breedersReferenceValue: string) => this.updateCriteriaValue({breedersReferenceValue});

    onBreedersReferenceFilterChange = ({
        target: {value: breedersReferenceFilter},
    }: React.ChangeEvent<HTMLInputElement>) => this.updateCriteriaValue({breedersReferenceFilter});

    onDenominationChange = (denominationValue: string) => this.updateCriteriaValue({denominationValue});

    onDenominationFilterChange = ({target: {value: denominationFilter}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({denominationFilter});

    onValidDenominationChange = ({target: {value: validDenomination}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({validDenomination});

    onTextLabelInputPartiesFilterChange = ({
        target: {value: textLabelInputPartiesCurrentFilter},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({textLabelInputPartiesCurrentFilter});

    onTextLabelInputApplicationNumberFilterChange = ({
        target: {value: textLabelInputApplicationNumberCurrentFilter},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({textLabelInputApplicationNumberCurrentFilter});

    onTextLabelInputGrantRegistrationNumberFilterChange = ({
        target: {value: textLabelInputGrantRegistrationNumberCurrentFilter},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({textLabelInputGrantRegistrationNumberCurrentFilter});

    onTextLabelInputRegisterNameFilterChange = ({
        target: {value: textLabelInputRegisterNameCurrentFilter},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({textLabelInputRegisterNameCurrentFilter});

    onTextLabelInputBreedersNameFilterChange = ({
        target: {value: textLabelInputBreedersNameCurrentFilter},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({textLabelInputBreedersNameCurrentFilter});

    onTextLabelInputSpeciesClassCodeFilterChange = ({
        target: {value: textLabelInputSpeciesClassCodeCurrentFilter},
    }: React.ChangeEvent<HTMLSelectElement>) =>
        this.setState(prev => ({
            textLabelInputSpeciesClassCodeCurrentFilter,
            textLabelInputSpeciesClassCodeValue:
                textLabelInputSpeciesClassCodeCurrentFilter === 'equals'
                    ? ''
                    : prev.textLabelInputSpeciesClassCodeValue,
            textLabelInputSpeciesClassCodeSelectedElements:
                textLabelInputSpeciesClassCodeCurrentFilter !== 'equals'
                    ? {}
                    : prev.textLabelInputSpeciesClassCodeSelectedElements,
        }));

    onTextLabelInputSpeciesClassNameFilterChange = ({
        target: {value: textLabelInputSpeciesClassNameCurrentFilter},
    }: React.ChangeEvent<HTMLSelectElement>) =>
        this.setState(prev => ({
            textLabelInputSpeciesClassNameCurrentFilter,
            textLabelInputSpeciesClassNameValue:
                textLabelInputSpeciesClassNameCurrentFilter === 'equals'
                    ? ''
                    : prev.textLabelInputSpeciesClassNameValue,
            textLabelInputSpeciesClassNameSelectedElements:
                textLabelInputSpeciesClassNameCurrentFilter !== 'equals'
                    ? {}
                    : prev.textLabelInputSpeciesClassNameSelectedElements,
        }));

    onTextLabelInputSpeciesLatinNameFilterChange = ({
        target: {value: textLabelInputSpeciesLatinNameCurrentFilter},
    }: React.ChangeEvent<HTMLSelectElement>) =>
        this.setState(prev => ({
            textLabelInputSpeciesLatinNameCurrentFilter,
            textLabelInputSpeciesLatinNameValue:
                textLabelInputSpeciesLatinNameCurrentFilter === 'equals'
                    ? ''
                    : prev.textLabelInputSpeciesLatinNameValue,
            textLabelInputSpeciesLatinNameSelectedElements:
                textLabelInputSpeciesLatinNameCurrentFilter !== 'equals'
                    ? {}
                    : prev.textLabelInputSpeciesLatinNameSelectedElements,
        }));

    onTextLabelInputPartiesSelectionChange = () => {
        this.skipSearching = true;
        this.setState({
            textLabelInputPartiesList: [],
            textLabelInputPartiesHash: {},
        });
        setTimeout(() => {
            this.skipSearching = false;
        }, 1000);
    };

    onTextLabelInputApplicationNumberSelectionChange = () => {
        this.skipSearching = true;
        this.setState({
            textLabelInputApplicationNumberList: [],
            textLabelInputApplicationNumberHash: {},
        });
        setTimeout(() => {
            this.skipSearching = false;
        }, 1000);
    };

    onTextLabelInputGrantRegistrationNumberSelectionChange = () => {
        this.skipSearching = true;
        this.setState({
            textLabelInputGrantRegistrationNumberList: [],
            textLabelInputGrantRegistrationNumberHash: {},
        });
        setTimeout(() => {
            this.skipSearching = false;
        }, 1000);
    };

    onTextLabelInputRegisterNameSelectionChange = () => {
        this.skipSearching = true;
        this.setState({
            textLabelInputRegisterNameList: [],
            textLabelInputRegisterNameHash: {},
        });
        setTimeout(() => {
            this.skipSearching = false;
        }, 1000);
    };

    onTextLabelInputBreedersNameSelectionChange = () => {
        this.skipSearching = true;
        this.setState({
            textLabelInputBreedersNameList: [],
            textLabelInputBreedersNameHash: {},
        });
        setTimeout(() => {
            this.skipSearching = false;
        }, 1000);
    };

    onTextLabelInputSpeciesClassCodeSelectionChange = (selected: any) => {
        this.skipSearching = true;
        this.setState({
            textLabelInputSpeciesClassCodeSelectedElements: selected,
            textLabelInputSpeciesClassCodeValue: '',
        });
        setTimeout(() => {
            this.skipSearching = false;
        }, 1000);
    };

    onTextLabelInputSpeciesClassNameSelectionChange = (selected: any) => {
        this.skipSearching = true;
        this.setState({
            textLabelInputSpeciesClassNameSelectedElements: selected,
            textLabelInputSpeciesClassNameValue: '',
        });
        setTimeout(() => {
            this.skipSearching = false;
        }, 1000);
    };

    onTextLabelInputSpeciesLatinNameSelectionChange = (selected: any) => {
        this.skipSearching = true;
        this.setState({
            textLabelInputSpeciesLatinNameSelectedElements: selected,
            textLabelInputSpeciesLatinNameValue: '',
        });
        setTimeout(() => {
            this.skipSearching = false;
        }, 1000);
    };

    onTextLabelInputCountriesSelectionChange = (_: any) => {
        const selectedElements = {...this.state.textLabelInputCountriesSelectedElements};
        if (this.previousCountriesSelectedLabels.includes('All') && !Object.keys(selectedElements).includes('All')) {
            this.previousCountriesSelectedLabels = [];
            this.setState({textLabelInputCountriesSelectedElements: {}});
            return;
        }
        if (Object.keys(selectedElements).includes('All') && !this.previousCountriesSelectedLabels.includes('All')) {
            let labelsAll = countriesListParsed.map(({label}) => label);
            let stateSelected: any = {};
            labelsAll.forEach(label => (stateSelected[label] = label));
            this.previousCountriesSelectedLabels = Object.keys(stateSelected);
            this.setState({textLabelInputCountriesSelectedElements: stateSelected});
            return;
        }
        if (
            Object.keys(selectedElements).includes('Non-EU') &&
            !this.previousCountriesSelectedLabels.includes('Non-EU')
        ) {
            let labelsArrayNonEU = countriesListParsed
                .filter(({value: {countryid, iseumember}}: any) => iseumember === 0 && countryid !== 'EU')
                .map(({label}) => label);
            let stateSelected: any = {};
            labelsArrayNonEU.forEach(label => (stateSelected[label] = label));
            this.setState(prev => ({
                textLabelInputCountriesSelectedElements: {
                    ...prev.textLabelInputCountriesSelectedElements,
                    ...stateSelected,
                },
            }));
            this.previousCountriesSelectedLabels = Object.keys(stateSelected);
        }
        if (
            this.previousCountriesSelectedLabels.includes('Non-EU') &&
            !Object.keys(selectedElements).includes('Non-EU')
        ) {
            let labelsArrayNonEU = countriesListParsed
                .filter(({value: {iseumember}}: any) => iseumember === 0)
                .map(({label}) => label);
            this.setState(prev => {
                const updatedObject = {...prev.textLabelInputCountriesSelectedElements};
                delete updatedObject['All'];
                Object.keys(updatedObject).forEach(
                    selectedLabel => labelsArrayNonEU.includes(selectedLabel) && delete updatedObject[selectedLabel]
                );
                this.previousCountriesSelectedLabels = Object.keys(updatedObject);
                return {
                    textLabelInputCountriesSelectedElements: updatedObject,
                };
            });
        }
        if (
            Object.keys(selectedElements).includes('European Union - EU') &&
            !this.previousCountriesSelectedLabels.includes('European Union - EU')
        ) {
            let labelsArrayEU = countriesListParsed
                .filter(({value: {iseumember}}: any) => iseumember === 1)
                .map(({label}) => label);
            let stateSelected: any = {};
            labelsArrayEU.forEach(label => (stateSelected[label] = label));
            this.setState(prev => {
                const updatedObject = {
                    ...prev.textLabelInputCountriesSelectedElements,
                    ...stateSelected,
                };
                this.previousCountriesSelectedLabels = Object.keys(updatedObject);
                return {
                    textLabelInputCountriesSelectedElements: updatedObject,
                };
            });
        }
        if (
            this.previousCountriesSelectedLabels.includes('European Union - EU') &&
            !Object.keys(selectedElements).includes('European Union - EU')
        ) {
            let labelsArrayEU = countriesListParsed
                .filter(({value: {iseumember}}: any) => iseumember === 1)
                .map(({label}) => label);

            this.setState(prev => {
                const updatedObject = {...prev.textLabelInputCountriesSelectedElements};
                Object.keys(updatedObject).forEach(
                    selectedLabel => labelsArrayEU.includes(selectedLabel) && delete updatedObject[selectedLabel]
                );
                delete updatedObject['All'];
                this.previousCountriesSelectedLabels = Object.keys(updatedObject);
                return {
                    textLabelInputCountriesSelectedElements: updatedObject,
                };
            });
        }
    };

    onTextLabelInputPartiesChange = (textLabelInputPartiesValue: string) => {
        clearTimeout(this.state.textLabelInputPartiesTimeoutId);
        this.setState({
            textLabelInputPartiesValue,
            textLabelInputPartiesHash: {},
            textLabelInputPartiesList: [],
        });
        if (
            this.state.textLabelInputPartiesCurrentFilter === 'equals' &&
            (textLabelInputPartiesValue || '').length > 2
        ) {
            let newTimeoutId = setTimeout(() => {
                this.state.textLabelInputPartiesValue && this.searchPartiesSuggestions();
            }, 300);
            this.setState({
                textLabelInputPartiesTimeoutId: newTimeoutId,
            });
        }
    };

    onTextLabelInputApplicationNumberChange = (textLabelInputApplicationNumberValue: string) => {
        clearTimeout(this.state.textLabelInputApplicationNumberTimeoutId);
        this.setState({
            textLabelInputApplicationNumberValue,
            textLabelInputApplicationNumberHash: {},
            textLabelInputApplicationNumberList: [],
        });
        if (
            this.state.textLabelInputApplicationNumberCurrentFilter === 'equals' &&
            (textLabelInputApplicationNumberValue || '').length > 2
        ) {
            let newTimeoutId = setTimeout(() => {
                this.state.textLabelInputApplicationNumberValue && this.searchApplicationNumberSuggestions();
            }, 300);
            this.setState({
                textLabelInputApplicationNumberTimeoutId: newTimeoutId,
            });
        }
    };

    onTextLabelInputGrantRegistrationNumberChange = (textLabelInputGrantRegistrationNumberValue: string) => {
        clearTimeout(this.state.textLabelInputGrantRegistrationNumberTimeoutId);
        this.setState({
            textLabelInputGrantRegistrationNumberValue,
            textLabelInputGrantRegistrationNumberHash: {},
            textLabelInputGrantRegistrationNumberList: [],
        });
        if (
            this.state.textLabelInputGrantRegistrationNumberCurrentFilter === 'equals' &&
            (textLabelInputGrantRegistrationNumberValue || '').length > 2
        ) {
            let newTimeoutId = setTimeout(() => {
                this.state.textLabelInputGrantRegistrationNumberValue &&
                    this.searchGrantRegistrationNumberSuggestions();
            }, 300);
            this.setState({
                textLabelInputGrantRegistrationNumberTimeoutId: newTimeoutId,
            });
        }
    };

    onTextLabelInputRegisterNameChange = (textLabelInputRegisterNameValue: string) => {
        clearTimeout(this.state.textLabelInputRegisterNameTimeoutId);
        this.setState({
            textLabelInputRegisterNameValue,
            textLabelInputRegisterNameHash: {},
            textLabelInputRegisterNameList: [],
        });
        if (
            this.state.textLabelInputRegisterNameCurrentFilter === 'equals' &&
            (textLabelInputRegisterNameValue || '').length > 2
        ) {
            let newTimeoutId = setTimeout(() => {
                this.state.textLabelInputRegisterNameValue && this.searchRegisterNameSuggestions();
            }, 300);
            this.setState({
                textLabelInputRegisterNameTimeoutId: newTimeoutId,
            });
        }
    };

    onTextLabelInputBreedersNameChange = (textLabelInputBreedersNameValue: string) => {
        clearTimeout(this.state.textLabelInputBreedersNameTimeoutId);
        this.setState({
            textLabelInputBreedersNameValue,
            textLabelInputBreedersNameHash: {},
            textLabelInputBreedersNameList: [],
        });
        if (
            this.state.textLabelInputBreedersNameCurrentFilter === 'equals' &&
            (textLabelInputBreedersNameValue || '').length > 2
        ) {
            let newTimeoutId = setTimeout(() => {
                this.state.textLabelInputBreedersNameValue && this.searchBreedersNameSuggestions();
            }, 300);
            this.setState({
                textLabelInputBreedersNameTimeoutId: newTimeoutId,
            });
        }
    };

    onTextLabelInputSpeciesClassCodeChange = (textLabelInputSpeciesClassCodeValue: string) => {
        clearTimeout(this.state.textLabelInputBreedersNameTimeoutId);
        this.setState({
            textLabelInputSpeciesClassCodeValue,
        });
        if (textLabelInputSpeciesClassCodeValue.length <= 2) {
            this.setState({forceHideSearchList: true});
        }
        if (
            this.state.textLabelInputSpeciesClassCodeCurrentFilter === 'equals' &&
            (textLabelInputSpeciesClassCodeValue || '').length > 2
        ) {
            let newTimeoutId = setTimeout(() => {
                this.state.textLabelInputSpeciesClassCodeValue && this.searchSpeciesClassCodeSuggestions();
            }, 300);
            this.setState({
                textLabelInputSpeciesClassCodeTimeoutId: newTimeoutId,
            });
        }
    };

    onTextLabelInputSpeciesClassNameChange = (textLabelInputSpeciesClassNameValue: string) => {
        clearTimeout(this.state.textLabelInputSpeciesClassNameTimeoutId);
        this.setState({
            textLabelInputSpeciesClassNameValue,
        });
        if (textLabelInputSpeciesClassNameValue.length <= 2) {
            this.setState({forceHideSearchList: true});
        }
        if (
            this.state.textLabelInputSpeciesClassNameCurrentFilter === 'equals' &&
            (textLabelInputSpeciesClassNameValue || '').length > 2
        ) {
            let newTimeoutId = setTimeout(() => {
                this.state.textLabelInputSpeciesClassNameValue && this.searchSpeciesClassNameSuggestions();
            }, 300);
            this.setState({
                textLabelInputSpeciesClassNameTimeoutId: newTimeoutId,
            });
        }
    };

    onTextLabelInputSpeciesLatinNameChange = (textLabelInputSpeciesLatinNameValue: string) => {
        clearTimeout(this.state.textLabelInputSpeciesLatinNameTimeoutId);
        this.setState({
            textLabelInputSpeciesLatinNameValue,
        });
        if (textLabelInputSpeciesLatinNameValue.length <= 2) {
            this.setState({forceHideSearchList: true});
        }
        if (
            this.state.textLabelInputSpeciesLatinNameCurrentFilter === 'equals' &&
            (textLabelInputSpeciesLatinNameValue || '').length > 2
        ) {
            let newTimeoutId = setTimeout(() => {
                this.state.textLabelInputSpeciesLatinNameValue && this.searchSpeciesLatinNameSuggestions();
            }, 300);
            this.setState({
                textLabelInputSpeciesLatinNameTimeoutId: newTimeoutId,
            });
        }
    };

    onTextLabelInputSpeciesLatinNameBlur = (keyDown?: 'enter') => {
        if (!this.state.textLabelInputSpeciesLatinNameValue) {
            if (keyDown === 'enter') {
                return this.search();
            } else {
                return;
            }
        }
        const nameHash: {[key: string]: string[]} = {};
        [
            ...Object.keys(this.state.textLabelInputSpeciesLatinNameSelectedElements),
            this.state.textLabelInputSpeciesLatinNameValue,
        ].forEach((suggestion: any) => {
            if (!nameHash[suggestion]) {
                nameHash[suggestion] = [];
            }
            nameHash[suggestion].push(suggestion);
        });
        const listByIdName: any = Object.keys(nameHash).map(key => ({
            ID: nameHash[key].join(','),
            NAME: key,
        }));
        this.setState(prev => ({
            forceHideSearchList: false,
            textTimestamp: Date.now(),
            textLabelInputSpeciesLatinNameValue: '',
            textLabelInputSpeciesLatinNameHash: nameHash,
            textLabelInputSpeciesLatinNameList: listByIdName,
            textLabelInputSpeciesLatinNameSelectedElements: {
                ...prev.textLabelInputSpeciesLatinNameSelectedElements,
                [prev.textLabelInputSpeciesLatinNameValue]: prev.textLabelInputSpeciesLatinNameValue,
            },
        }));
    };

    onCheckboxSpeciesLatinNameIncludeSynonymsClick = () =>
        this.setState(prev => ({
            textLabelInputSpeciesLatinNameIncludeSynonymsValue:
                !prev.textLabelInputSpeciesLatinNameIncludeSynonymsValue,
        }));

    onTableDataSpeciesLatinNameClick = ({speciesid}: any) => this.setState({isModalSpeciesOpen: speciesid});

    onTableDataSpeciesClassCodeClick = (classCode: any) => this.setState({isModalClassOpen: classCode});

    onTableDataDenominationClick = ({currentIndex, varietyId}: {currentIndex: number; varietyId: number}) => {
        this.setState({isModalVarietyDataSheetOpen: {currentIndex, varietyId}});
    };

    onModalVarietyDataSheetButtonNextClick = () => {
        let {currentIndex} = this.state.isModalVarietyDataSheetOpen || {};
        if (currentIndex === undefined) currentIndex = 0;
        if (currentIndex < this.state.tableResults.length - 1) {
            this.setState({isModalVarietyDataSheetOpen: false}, () => {
                this.setState(prev => {
                    if (currentIndex === undefined) currentIndex = 0;
                    let nextTableResult = prev.tableResults[currentIndex + 1];
                    return {
                        isModalVarietyDataSheetOpen: {
                            currentIndex: currentIndex + 1,
                            varietyId: nextTableResult.varietyid,
                        },
                    };
                });
            });
        } else {
            this.setState({isModalVarietyDataSheetOpen: false}, () => {
                this.updateCriteriaValue({pageNumber: this.state.criteria.pageNumber + 1}, () => {
                    this.setState(prev => ({
                        isModalVarietyDataSheetOpen: {
                            currentIndex: 0,
                            varietyId: (prev.tableResults[0] || {}).varietyid,
                        },
                    }));
                });
            });
        }
    };

    onModalVarietyDataSheetButtonPreviousClick = () => {
        let {currentIndex} = this.state.isModalVarietyDataSheetOpen || {};
        if (currentIndex === undefined) currentIndex = 0;
        if (currentIndex > 0) {
            this.setState({isModalVarietyDataSheetOpen: false}, () => {
                this.setState(prev => {
                    if (currentIndex === undefined) currentIndex = 0;
                    let nextTableResult = prev.tableResults[currentIndex - 1];
                    return {
                        isModalVarietyDataSheetOpen: {
                            currentIndex: currentIndex - 1,
                            varietyId: nextTableResult.varietyid,
                        },
                    };
                });
            });
        } else {
            this.setState({isModalVarietyDataSheetOpen: false}, () => {
                this.updateCriteriaValue({pageNumber: this.state.criteria.pageNumber - 1}, () => {
                    this.setState(prev => ({
                        isModalVarietyDataSheetOpen: {
                            currentIndex: prev.tableResults.length - 1,
                            varietyId: (prev.tableResults[prev.tableResults.length - 1] || {}).varietyid,
                        },
                    }));
                });
            });
        }
    };

    onModalSpeciesClassCodeSelectClick = (selectedClassCodes: string[]) => {
        this.setState(prev => {
            const selectElementsObject: any = {};
            selectedClassCodes.forEach(classCode => (selectElementsObject[classCode] = classCode));

            const selectedUpdateObject = {
                ...prev.textLabelInputSpeciesClassCodeSelectedElements,
                ...selectElementsObject,
            };
            const nameHash: {[key: string]: string[]} = {};
            [...Object.keys(selectedUpdateObject)].forEach((suggestion: any) => {
                if (!nameHash[suggestion]) {
                    nameHash[suggestion] = [];
                }
                nameHash[suggestion].push(suggestion);
            });
            const listByIdName: any = Object.keys(nameHash).map(key => ({
                ID: nameHash[key].join(','),
                NAME: key,
            }));
            return {
                textLabelInputSpeciesClassCodeSelectedElements: selectedUpdateObject,
                textTimestamp: Date.now(),
                textLabelInputSpeciesClassCodeHash: nameHash,
                textLabelInputSpeciesClassCodeList: listByIdName,
            };
        });
    };

    onModalSpeciesClassNameSelectClick = (selectedClassNames: string[]) => {
        this.setState(prev => {
            const selectElementsObject: any = {};
            selectedClassNames.forEach(className => (selectElementsObject[className] = className));

            const selectedUpdateObject = {
                ...prev.textLabelInputSpeciesClassNameSelectedElements,
                ...selectElementsObject,
            };
            const nameHash: {[key: string]: string[]} = {};
            [...Object.keys(selectedUpdateObject)].forEach((suggestion: any) => {
                if (!nameHash[suggestion]) {
                    nameHash[suggestion] = [];
                }
                nameHash[suggestion].push(suggestion);
            });
            const listByIdName: any = Object.keys(nameHash).map(key => ({
                ID: nameHash[key].join(','),
                NAME: key,
            }));
            return {
                textLabelInputSpeciesClassNameSelectedElements: selectedUpdateObject,
                textTimestamp: Date.now(),
                textLabelInputSpeciesClassNameHash: nameHash,
                textLabelInputSpeciesClassNameList: listByIdName,
            };
        });
    };

    onModalSpeciesLatinNameSelectClick = (selectedLatinNames: string[]) => {
        this.setState(prev => {
            const selectElementsObject: any = {};
            selectedLatinNames.forEach(latinName => (selectElementsObject[latinName] = latinName));

            const selectedUpdateObject = {
                ...prev.textLabelInputSpeciesLatinNameSelectedElements,
                ...selectElementsObject,
            };
            const nameHash: {[key: string]: string[]} = {};
            [...Object.keys(selectedUpdateObject)].forEach((suggestion: any) => {
                if (!nameHash[suggestion]) {
                    nameHash[suggestion] = [];
                }
                nameHash[suggestion].push(suggestion);
            });
            const listByIdName: any = Object.keys(nameHash).map(key => ({
                ID: nameHash[key].join(','),
                NAME: key,
            }));
            return {
                textLabelInputSpeciesLatinNameSelectedElements: selectedUpdateObject,
                textTimestamp: Date.now(),
                textLabelInputSpeciesLatinNameHash: nameHash,
                textLabelInputSpeciesLatinNameList: listByIdName,
            };
        });
    };

    onButtonSaveSearchClick = () => this.setState({isModalSaveSearchOpen: true});

    onHeaderSearchLinkClick = ({searchTerms}: any) => {
        this.resetCriteria();
        this.populateSearchFields(searchTerms);
    };

    render() {
        const filters = this.state.orderedFilters.filter(({enabled}: any) => enabled);
        const resultFieldsDefault = this.state.orderedColumns
            .filter(({enabled}: any) => enabled)
            .map(({name}: any) => name);
        let {currentIndex, varietyId} = this.state.isModalVarietyDataSheetOpen || {};
        if (varietyId === undefined) varietyId = 0;
        if (currentIndex === undefined) currentIndex = 0;

        const hasRegisteredWithOther = (this.state.tableResults || []).some(
            rowObject => rowObject.registeredwithother === 1
        );

        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                {this.state.isModalExportDataOpen ? (
                    <ModalExportData
                        close={this.closeModalExportData}
                        exportNoteData={this.state.isModalExportDataOpen}
                        onButtonCompleteExportClick={this.onModalExportButtonCompleteExportClick}
                        total={this.state.count + this.state.countSecondary}
                    />
                ) : null}
                {this.state.isModalSaveSearchOpen ? (
                    <ModalSaveSearch
                        close={this.closeModalSaveSearch}
                        orderedFilters={this.state.orderedFilters}
                        onSaveButtonClick={this.onModalSaveSearchSaveButtonClick}
                        searchState={this.state}
                    />
                ) : null}
                {this.state.isModalAlertPrintExcelReachedLimitOpen ? (
                    <ModalAlertVersion2
                        title={'Info'}
                        message={`You have reached the limit of 50000 records for Excel download. Please refine your search.`}
                        close={this.closeModalAlertPrintExcelReachedLimit}
                    />
                ) : null}
                {this.state.isModalAlertSaveSearchMaximumLimitOpen ? (
                    <ModalAlertVersion2
                        title={'Info'}
                        message={`You have reached the maximum limit.`}
                        close={this.closeModalAlertSaveSearchMaximumLimit}
                    />
                ) : null}
                {this.state.isModalClassOpen ? (
                    <VFModalClass close={this.closeModalClass} classId={this.state.isModalClassOpen} />
                ) : null}
                {this.state.isModalSpeciesOpen ? (
                    <VFModalSpecies close={this.closeModalSpecies} speciesCode={this.state.isModalSpeciesOpen} />
                ) : null}
                {this.state.isModalVarietyDataSheetOpen ? (
                    <VFModalVarietyDataSheet
                        close={this.closeModalVarietyDataSheet}
                        currentIndex={
                            (this.state.criteria.pageNumber - 1) * this.state.criteria.pageSize + currentIndex
                        }
                        onButtonPreviousClick={this.onModalVarietyDataSheetButtonPreviousClick}
                        onButtonNextClick={this.onModalVarietyDataSheetButtonNextClick}
                        totalCount={this.state.count}
                        varietyId={varietyId}
                    />
                ) : null}
                {this.state.isModalSpeciesClassCodeAddFromListOpen ? (
                    <ModalSelectClasses
                        close={this.closeModalSpeciesClassCodeAddFromList}
                        onButtonSelectClick={this.onModalSpeciesClassCodeSelectClick}
                        tableId={`classCode`}
                    />
                ) : null}
                {this.state.isModalSpeciesClassNameAddFromListOpen ? (
                    <ModalSelectClasses
                        close={this.closeModalSpeciesClassNameAddFromList}
                        onButtonSelectClick={this.onModalSpeciesClassNameSelectClick}
                        tableId={`className`}
                    />
                ) : null}
                {this.state.isModalSpeciesLatinNameAddFromListOpen ? (
                    <ModalSelectSpecies
                        close={this.closeModalSpeciesLatinNameAddFromList}
                        onButtonSelectClick={this.onModalSpeciesLatinNameSelectClick}
                    />
                ) : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Variety Finder`} hostApplication={'Variety Finder'} />
                <NavigationVarietyFinder onSearchLinkClick={this.onHeaderSearchLinkClick} />
                <MainWrapper bgGray={true}>
                    <div style={{position: 'relative'}}>
                        <FormWrapper paddingFormContent={'sm'} formInnerWidth={'lg'}>
                            <div className={styles.countUpWrap}>
                                <span>{`Search among`}</span>
                                <div className={styles.headerUnderlinedCounterText} style={{width: 278}}>
                                    <strong className={styles.counterNumber}>
                                        <CustomCountUp end={this.state.stats.denominations} />
                                    </strong>
                                    {`Denominations, `}
                                </div>
                                <div className={styles.headerUnderlinedCounterText} style={{width: 210}}>
                                    <strong className={styles.counterNumber}>
                                        <CustomCountUp end={this.state.stats.varieties} />
                                    </strong>
                                    {`Varieties, `}
                                </div>
                                <div className={styles.headerUnderlinedCounterText} style={{width: 153}}>
                                    <strong className={styles.counterNumber}>
                                        <CustomCountUp end={this.state.stats.registers} />
                                    </strong>
                                    {`Registers, `}
                                </div>
                                {` in `}
                                <div className={styles.headerUnderlinedCounterText} style={{width: 134}}>
                                    <strong className={styles.counterNumber}>
                                        <CustomCountUp end={this.state.stats.countries} />
                                    </strong>
                                    {`Countries `}
                                </div>
                            </div>
                            {filters.map(({name}: any) => (
                                <React.Fragment key={name}>{this.filters()[name]}</React.Fragment>
                            ))}
                            <div style={{clear: 'both'}} />
                            <FormFooter
                                left={
                                    <Popover
                                        isOpen={this.state.isPopoverConfigureSearchFieldsOpen}
                                        positions={'right'}
                                        onClickOutside={this.onPopoverConfigureSearchFieldsClickOutside}
                                        containerClassName={'container'}
                                        content={() => (
                                            <div
                                                className="custom-popover"
                                                style={{
                                                    background: 'white',
                                                    color: 'black',
                                                    borderRadius: '4px',
                                                    width: '300px',
                                                    zIndex: 99,
                                                    position: 'relative',
                                                    padding: 5,
                                                    borderStyle: 'solid',
                                                    borderWidth: '1px',
                                                    borderColor: '#ccc',
                                                }}
                                            >
                                                <div style={{maxHeight: 510, overflowY: 'auto'}}>
                                                    <SortableConfigureSearchFields
                                                        orderedFilters={this.state.orderedFiltersTemporary}
                                                        onCheckboxClick={
                                                            this.onSortableConfigureSearchFieldsCheckboxClick
                                                        }
                                                        onSortEnd={this.onSortableConfigureSearchFieldsSortEnd}
                                                    />
                                                </div>
                                                <div style={{paddingTop: 10, paddingBottom: 10}}>
                                                    <FormFooterButton
                                                        color={buttonColor.whiteGreen}
                                                        clickAction={this.onSortableComponentButtonSelectDefaultClick}
                                                    >{`Select default`}</FormFooterButton>
                                                    <FormFooterButton
                                                        color={buttonColor.whiteGreen}
                                                        clickAction={this.onSortableComponentButtonSelectAllClick}
                                                    >{`Select all`}</FormFooterButton>
                                                    <div style={{clear: 'both'}} />
                                                </div>
                                            </div>
                                        )}
                                    >
                                        <div>
                                            <FormFooterLinkButton
                                                clickAction={this.onLinkButtonConfigureSearchFieldsClick}
                                                hoverUnderline={true}
                                            >
                                                <FontAwesomeIcon icon={faCog as any} style={{marginRight: 8}} />
                                                {`Configure search fields`}
                                            </FormFooterLinkButton>
                                        </div>
                                    </Popover>
                                }
                                right={
                                    <>
                                        <Button
                                            clickAction={this.onLinkButtonHelpClick}
                                            variation={'secondary'}
                                        >{`Help`}</Button>
                                        <Button
                                            clickAction={this.resetCriteria}
                                            variation={'secondary'}
                                        >{`Clear fields`}</Button>
                                        <Button
                                            clickAction={() => this.onButtonSaveSearchClick()}
                                            variation={'secondary'}
                                        >{`Save search`}</Button>
                                        <Button clickAction={() => this.search()}>{`Search`}</Button>
                                    </>
                                }
                            />
                        </FormWrapper>
                    </div>
                    {this.state.screenLoaded ? (
                        <>
                            <div style={{marginBottom: -12}}>
                                <div className={styles.tableHeaderCounterTitle}>{`${
                                    this.state.count + this.state.countSecondary
                                } results matching your criteria`}</div>
                                {!isFullSearchExp() ? (
                                    <div className={styles.tableHeaderSubtitle}>
                                        {'Commercial registers results, if any, are limited to 100 per register'}
                                    </div>
                                ) : null}
                            </div>
                            <CustomTable
                                additionalNavigationButtons={this.tableNavigationButtons}
                                additionalNavigationButtonsRenderAtLeftSide={true}
                                count={this.state.count}
                                forceUpdateResultFieldsDefault={true}
                                formatFunctions={{
                                    applicationnumber: (
                                        applicationnumber: any,
                                        {varietyid}: any,
                                        currentIndex: any
                                    ) => (
                                        <div
                                            style={{
                                                color: '#255899',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                                this.onTableDataDenominationClick({
                                                    currentIndex,
                                                    varietyId: varietyid,
                                                })
                                            }
                                        >
                                            {applicationnumber}
                                        </div>
                                    ),
                                    countryid: (countryid: string, {countryname}: any) => (
                                        <div title={countryname} style={{textDecoration: 'underline'}}>
                                            {countryid}
                                        </div>
                                    ),
                                    denomination: (denomination: any, {varietyid}: any, currentIndex: any) => (
                                        <div
                                            style={{
                                                color: '#255899',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                                this.onTableDataDenominationClick({
                                                    currentIndex,
                                                    varietyId: varietyid,
                                                })
                                            }
                                        >
                                            {denomination}
                                        </div>
                                    ),
                                    publicationtype: (publicationtype: string, {registertypename}: any) => (
                                        <div title={registertypename} style={{textDecoration: 'underline'}}>
                                            {publicationtype}
                                        </div>
                                    ),
                                    specielatinname: (speciesLatinName: string, rowObject: any) => (
                                        <div
                                            onClick={() => this.onTableDataSpeciesLatinNameClick(rowObject)}
                                            className={styles.tableDataLink}
                                        >
                                            {speciesLatinName}
                                        </div>
                                    ),
                                    specieclasscodes: (speciesClassCodes: string[]) =>
                                        speciesClassCodes.map(classCode => (
                                            <div
                                                key={classCode}
                                                onClick={() => this.onTableDataSpeciesClassCodeClick(classCode)}
                                                className={styles.tableDataLink}
                                                style={{
                                                    marginRight: 5,
                                                }}
                                            >
                                                {classCode}
                                            </div>
                                        )),
                                }}
                                intl={this.props.intl}
                                isNavigationButtonCompactOrDefaultViewEnabled={true}
                                pageNumber={this.state.criteria.pageNumber}
                                pageSize={this.state.criteria.pageSize}
                                pagination={true}
                                printExcel={this.printExcel}
                                resultFieldsDefault={resultFieldsDefault}
                                sectionBetweenHeaderAndTable={this.sectionBetweenHeaderAndTable}
                                tableName={TABLE_NAME}
                                tableType={'OBJECT'}
                                tableSource={this.state.tableResults}
                                timestamp={this.state.timestamp}
                                updateCriteriaValue={this.updateCriteriaValue}
                                version={2}
                                hoverGrey={true}
                                navigationBarWider={true}
                                fontSize={14}
                                forehandColumn={
                                    hasRegisteredWithOther
                                        ? (rowObject: any) =>
                                              rowObject.registeredwithother ? <ComponentPopover /> : null
                                        : false
                                }
                            />
                        </>
                    ) : null}
                </MainWrapper>
                <Footer hostApplication={'Variety Finder'} />
            </>
        );
    }
}

export default withRouter(injectIntl(VFSearchVarieties));
