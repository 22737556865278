import React, {useRef, useState} from 'react';
import styles from './ModalEndOfFilesPreviousToSignStatusVersion2.module.scss';
import {ModalCustomVersion2} from '../../commonModals';
import {faChevronDown, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import CustomTable from '~components/CustomTable';
import BrowserInfo from '~shared/BrowserInfo';
import {saveAs} from 'file-saver';
import getIcon from '~utils/icons';
import ModalEndOfFilesSignatorVersion2Actions from './ModalEndOfFilesSignatorVersion2Actions';
import InputLink from '~components/InputLink';
import DragAndDrop from '~components/DragAndDrop';
import {SIGN_CONSENT_DOCUMENT_TYPE} from './ModalEndOfFilesPreviousToSignStatusVersion2';
import {Button} from '../../componentsFormV2';

const RESULT_FIELDS_ALL = ['clientName', 'contactName', 'email'];
export default function ({
    onBehalfResults,
    documentList,
    checkFile,
    deleteFile,
    intl,
    timestamp,
    onContinueSignatory,
    close,
}) {
    const [informationOpened, setInformationOpened] = useState(false);
    const [selectedContactIds, setSelectedContactIds] = useState([]);
    const toggleInformation = () => setInformationOpened(!informationOpened);
    const onSelectedChange = selectedContactIds => setSelectedContactIds(selectedContactIds);
    const handleDrop = (files, event) => setInformationOpened(false) || checkFile(files[0], event);
    const onFileUpload = event =>
        setInformationOpened(false) || checkFile(event.target.files[0], event, SIGN_CONSENT_DOCUMENT_TYPE);
    const continueEnabled = selectedContactIds.length > 0;
    const actions = ModalEndOfFilesSignatorVersion2Actions(deleteFile);
    const inputUploadRef = useRef(null);

    return (
        <DragAndDrop handleDrop={handleDrop}>
            <ModalCustomVersion2
                close={close}
                header={`Selection of an authorised contact`}
                body={
                    <div
                        style={{
                            height: 400,
                            overflowY: 'auto',
                            padding: 20,
                        }}
                    >
                        <div style={{paddingBottom: 20}}>
                            <InputLink
                                label={`Notes`}
                                icon={informationOpened ? faChevronDown : faChevronRight}
                                clickAction={toggleInformation}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            {informationOpened && <BrowserInfo intl={intl} />}
                        </div>
                        {documentList.length > 0 && (
                            <div style={{marginBottom: 20}}>
                                <CustomTable
                                    version={2}
                                    notSortable={['filename', 'size']}
                                    tableName={'documentUpload'}
                                    tableType={'OBJECT'}
                                    tableSource={documentList || []}
                                    dataFilter={null}
                                    id={'index'}
                                    setLastCursor={null}
                                    resultFieldsDefault={['filename', 'size']}
                                    intl={intl}
                                    formatFunctions={{
                                        size: size => `${Math.floor(size * 100) / 100} KB`,
                                        extension: extension => (
                                            <img src={getIcon(extension || 'pdf')} alt={extension} />
                                        ),
                                    }}
                                    count={(documentList || []).length}
                                    hideExcelButton={true}
                                    noChangePageSize={true}
                                    forehandColumn={row => (
                                        <img
                                            style={{width: 20, height: 20, cursor: 'pointer'}}
                                            alt={'Download'}
                                            src={getIcon(row.extension || 'pdf')}
                                            onClick={event => {
                                                event.stopPropagation();
                                                saveAs(row.file, row.filename);
                                            }}
                                        />
                                    )}
                                    timestamp={timestamp}
                                    actions={actions}
                                />
                            </div>
                        )}
                        <div>
                            {`You are not registered as "authorised to sign" under this "MyPVR" account. Please select the contact "authorised to sign" on behalf of whom you file the end of file request `}
                            <b>{`and upload a document that is signed by this person by clicking on the "Upload document(s)" button.`}</b>
                            {` If you do not attach any document to this request, you will be required to provide it within one month.`}
                        </div>
                        <CustomTable
                            version={2}
                            selectable={true}
                            onSelectedChange={onSelectedChange}
                            onlyOneSelected={true}
                            noSelectAll={true}
                            noUnselectAll={true}
                            notSortable={RESULT_FIELDS_ALL}
                            tableName={'EndOfFilesSignatory'}
                            tableType={'OBJECT'}
                            tableSource={onBehalfResults || []}
                            dataFilter={null}
                            id={'contactId'}
                            setLastCursor={null}
                            resultFieldsDefault={RESULT_FIELDS_ALL}
                            intl={intl}
                            filterFunctions={null}
                            count={(onBehalfResults || []).length}
                            hideExcelButton={true}
                            noChangePageSize={true}
                        />
                    </div>
                }
                footer={
                    <div className={styles.footerWrap}>
                        <div className={styles.footerLeft}>
                            <label htmlFor={'upload'}>
                                <input
                                    type="file"
                                    name="files[]"
                                    id="upload"
                                    style={{opacity: 0, width: 0}}
                                    onChange={onFileUpload}
                                    accept="image/jpg, image/jpeg, image/png, application/pdf"
                                    ref={inputUploadRef}
                                />
                                <Button
                                    clickAction={() => inputUploadRef.current.click()}
                                    variation={'secondary'}
                                >{`Upload document(s)`}</Button>
                            </label>
                        </div>
                        <div className={styles.footerRight}>
                            <Button variation={'danger'} clickAction={close} icon={'close'}>{`Cancel`}</Button>
                            <Button
                                clickAction={() => onContinueSignatory(parseInt(selectedContactIds[0]))}
                                disabled={!continueEnabled}
                                icon={'arrowRight'}
                            >{`Continue`}</Button>
                        </div>
                    </div>
                }
            />
        </DragAndDrop>
    );
}
