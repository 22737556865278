import {API_SERVERLESS, postFetch, getFetch} from '~commonApi';

export interface IPayloadFormGroupCreateOrUpdate {
    groupId: number;
    formId: any;
    messageId: any;
    messageRemarkId: any;
    messageHelpId: any;
    messageButtonAddId: any;
    messageButtonDelId: any;
    orderDisplay: any;
    mappingCode: any;
    jsonMapping: any;
    mandatory: number;
    multi: number;
}

//TODO: add user language
export function apiBackOfficeFormGroupsGet(): Promise<{
    groups: {
        id: number;
        message: string;
        order: string;
        remark: string | null;
    }[];
}> {
    return getFetch(`${API_SERVERLESS}/backOffice/v3/formGroupsGet?language=EN`, true);
}

export function apiBackOfficeFormGroupCreateOrUpdate(JSONRequest: IPayloadFormGroupCreateOrUpdate): Promise<{
    newGroupId: number;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formGroupCreateOrUpdate`, true, JSONRequest);
}

// TODO FIX: check officeId & languageId
export function apiBackOfficeLabels({groupSearchTerm}: {groupSearchTerm: string}): Promise<{
    data: {
        COUNT: number;
        labels: {
            isUndefined: number;
            labelId: number;
            languageId: string;
            officeId: number;
            typeId: number;
            value: string;
            valueHTML: string;
        }[];
    };
}> {
    return getFetch(
        `${API_SERVERLESS}/backOffice/v2/labels?withHTML=true&officeId=1&languageId=EN&typeId=2&contains=${groupSearchTerm}&containsFilter=start&order=value`,
        true
    );
}

// TODO FIX: check languageId
export function apiBackOfficeUpdateLabels({labelValue}: {labelValue: string}) {
    return postFetch(`${API_SERVERLESS}/backOffice/v2/updateLabels/2`, true, {
        EN: {
            labelId: 0,
            labelValue: encodeURIComponent(labelValue),
            labelValueHTML: encodeURIComponent(labelValue),
        },
    });
}
