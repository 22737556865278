import React from 'react';
import styles from './ApplicationConsultationTabs.module.scss';
import cx from 'classnames';
import {injectIntl} from 'react-intl';
import {faFile} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {withRouter} from 'react-router-dom';

export const TAB = {
    GENERAL: 'GENERAL',
};

export const ACCESS = {PUBLIC: 'PUBLIC', PRIVATE: 'PRIVATE'};

class SettingsTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles.header}>
                    <div className={styles.leftPanel}>
                        <div className={cx(styles.menuItem, this.props.tabId === TAB.GENERAL && styles.menuItemActive)}>
                            <div className={styles.menuIcon}>
                                <FontAwesomeIcon icon={faFile} color={this.props.loading ? 'gray' : 'green'} />
                            </div>
                            <div className={styles.menuLabel}>{`General`}</div>
                        </div>
                        <div style={{clear: 'both'}}>{}</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(withRouter(SettingsTab));
