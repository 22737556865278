import gqlPrettier from 'graphql-prettier';

const getValueFromFormControl = (
    value: any,
    valueSelect: string,
    elasticField: string,
    elasticFieldSearchType: string,
    regexp: RegExp | null = null
) => {
    if (value && value.length > 0) {
        const val = regexp ? value.replace(regexp, '') : value;
        const searchType = elasticFieldSearchType ? `${elasticFieldSearchType}:"${valueSelect}"` : '';
        return `${elasticField}:"${val.toLowerCase()}" ${searchType}`;
    } else {
        return '';
    }
};

const getSingleValueFromTypeAhead = (
    value: any,
    valueSelect: string,
    elasticField: string,
    elasticFieldSearchType: string
) => {
    return value && value.length > 0
        ? `${elasticField}:"${value.toLowerCase()}" ${elasticFieldSearchType}:"${valueSelect}"`
        : '';
};

const createSearchClassesParams = (values: any) => {
    const selectMap: any = {
        contains: 'contains',
        equals: 'exact',
        start: 'starts',
        starts: 'starts',
    };
    const classCode = getSingleValueFromTypeAhead(
        values.classCode,
        selectMap[values.classCodeSelect],
        'classid',
        'classidSearchType'
    );
    const className = getValueFromFormControl(
        values.className,
        selectMap[values.classNameSelect],
        'classname',
        'classnameSearchType'
    );
    return `
        ${classCode}
        ${className}
  `;
};

export const getSearchClassesQuery = (values: any, pageSize: number, pageIndex: number) => {
    const searchParams = createSearchClassesParams(values);

    const searchQuery = `
  {
    classesSearch(
      size:${pageSize}
      from:${pageSize * pageIndex}
      ${searchParams}
    ){
      total
      data {
        classid,
        upovclass,
        classname,
        species_object,
        info
      }
    }
  }`;

    return {
        query: gqlPrettier(searchQuery),
    };
};
