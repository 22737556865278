import {API_SERVERLESS, getFetch} from '../../../../commonApi';

export interface CpvoBoxTableRowData {
    ackuid: string;
    contactid: number;
    subject: string;
    comments: string;
    folder: string;
    created: string;
    fileName: string;
    documentType: string;
    registers: string;
    invoiceDate: string;
    invoiceNumber: string;
    totalAmount: number;
    vatAmount: number;
}

export interface CpvoBoxFileListParamsData {
    data: {
        uploadsList: Array<CpvoBoxTableRowData>;
    };
    count: number;
}

export interface CpvoBoxTableCriteriaData {
    pageNumber: number;
    pageSize: number;
    refresh: boolean;
    excel: boolean;
    order: string;
    reverse: boolean;

    [key: string]: string | number | boolean | undefined;
}

export function apiCpvoBoxFileListSearch(
    // eslint-disable-next-line no-undef
    criteria: Partial<CpvoBoxTableCriteriaData>
): Promise<CpvoBoxFileListParamsData> {
    const params = {...criteria};
    const requestParams = Object.keys(params).map(k => `${k}=${params[k]}`);
    return getFetch(`${API_SERVERLESS}/tlo/applications/cpvobox/upload?${requestParams.join('&')}`, true);
}

export function apiUploadGetFileInfo(applicationNumber: string): Promise<{message?: string}> {
    return getFetch(`${API_SERVERLESS}/tlo/applications/cpvobox/check/${applicationNumber}`, true);
}
