import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import {faFile} from '@fortawesome/free-solid-svg-icons';
import styles from '~shared/ApplicationConsultationTabs.module.scss';

export const GROUP_OR_TEMPLATE_MODAL_DETAILS_TABS = {
    GENERAL_INFORMATION: 'GENERAL_INFORMATION',
};

interface IProps {
    onChange: any;
    currentTab: string;
}

interface IState {}

export default class GroupOrTemplateDetailsTabs extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    render() {
        const {onChange, currentTab} = this.props;
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.leftPanel}>
                        <div
                            className={cx(
                                styles.menuItem,
                                currentTab === GROUP_OR_TEMPLATE_MODAL_DETAILS_TABS.GENERAL_INFORMATION &&
                                    styles.menuItemActive
                            )}
                            onClick={onChange(GROUP_OR_TEMPLATE_MODAL_DETAILS_TABS.GENERAL_INFORMATION)}
                        >
                            <div className={styles.menuIcon}>
                                <FontAwesomeIcon icon={faFile as any} color={'green'} />
                            </div>
                            <div className={styles.menuLabel}>{`General information`}</div>
                        </div>
                        <div style={{clear: 'both'}} />
                    </div>
                    <div style={{clear: 'both'}} />
                </div>
            </>
        );
    }
}
