import {API_SERVERLESS, getFetch} from './index';
import {IExaminationItem, IExaminationReportItem, IPartyItem, IPublicationConsultationItem} from '../types';

export function apiPublicSearchExaminationReports(
    applicationNumber: string
): Promise<{data: IExaminationReportItem[]}> {
    return getFetch(`${API_SERVERLESS}/common/v3/publicSearchExaminationReports/${applicationNumber}`);
}

export function apiPublicSearchExaminations(applicationNumber: string): Promise<{data: IExaminationItem}> {
    return getFetch(`${API_SERVERLESS}/common/v3/publicSearchExaminations/${applicationNumber}`);
}

export function apiPublicSearchPublications(
    applicationNumber: string
): Promise<{data: IPublicationConsultationItem[]}> {
    return getFetch(`${API_SERVERLESS}/common/v3/publicSearchPublications/${applicationNumber}`);
}

export function apiPublicSearchParties(applicationNumber: string): Promise<{data: IPartyItem}> {
    return getFetch(`${API_SERVERLESS}/common/v3/publicSearchParties/${applicationNumber}`, false);
}
