import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';
import {apiCommunicationCentreGetCommunicationIdUploadFilesAndSendMessage} from '../CommunicationCentreInternal/CommunicationCentreInternalService';
import {ICommunicationMessageInternalSubmitJSONRequest, IDocument} from '../../types';

export function apiCommunicationCentreModalInternalComposeClients(jsonData: {applicationNumbers: string[]}) {
    return postFetch(`${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMICC/compose/clients`, true, jsonData);
}

export function apiCommunicationCentreModalInternalComposeCCList() {
    return getFetch(`${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMICCC/compose/ccList`, true);
}

export function apiCommunicationCentreModalInternalComposeSignature(): Promise<{
    data: {
        signatureFullName: string;
        signatureRole: string;
        signatureUnit: string;
    };
}> {
    return getFetch(`${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMISG/getSignature`, true);
}

export function apiCommunicationCentreModalInternalComposeMessageSend(
    JSONRequest: ICommunicationMessageInternalSubmitJSONRequest,
    documentList: IDocument[]
) {
    return apiCommunicationCentreGetCommunicationIdUploadFilesAndSendMessage(JSONRequest, documentList, {
        getCommunicationId: `communicationCentreModalInternal/v4/CCMICGID/compose/getCommunicationId`,
        documentUploadFile: 'communicationCentreModalInternal/v4/CCMICUF/compose/documentUploadFile',
        submitRequest: 'communicationCentreModalInternal/v4/CCMICS/compose/messageSend',
    });
}

export function apiCommunicationCentreModalInternalComposeGetFileInfo(applicationNumber: string) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMICCAN/compose/getFileInfoIfExists/${applicationNumber}`,
        true
    );
}
