import React, {useEffect, useState} from 'react';
import {TLO_STATIC_DOCS_DATA_PATH} from '../../../../commonApi';
import AdminIcon from '../../../TLO/CommonComponents/AdminIcon/AdminIcon';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
    Title,
} from '../../../../componentsLayout';
import {History} from 'history';
import {IntlShape} from 'react-intl';
import {ModalContactUsVersion2, ModalErrorVersion2} from '../../../../commonModals';
import NavigationACArea from '../../../../shared/NavigationACArea';
import cx from 'classnames';
import dashboardDataJSON from './data/dashboardData.json';
import {getUserRoles} from '../../../TLO/CommonFunctions/CommonFunctions';
import styles from './HomePageACAREA.module.scss';
import sendToList from './data/ContactList.json';
import {trackPageView} from '../../../../utils';

const modalScreenTypes = {ERROR: 'ERROR', CONTACT_US: 'CONTACT_US'};

export interface Page {
    id: number;
    title: string;
    link: string;
}

export interface Section {
    id: number;
    title: string;
    pages: Array<Page>;
}

export interface DashboardData {
    sections: Array<Section>;
}

interface IHomePageACAREAProps {
    intl: IntlShape;
    history: History;
}

const HomePageACAREA = (props: IHomePageACAREAProps) => {
    const [loading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [userRoles] = useState(getUserRoles());

    useEffect(() => {
        trackPageView({documentTitle: 'acarea'});
    }, []);

    const closeErrorModal = () => {
        setErrorMessage(null);
        setModalScreen(null);
    };

    const goToPage = (linkPath: string) => {
        if (linkPath.includes('tlodocs/manual')) {
            const url = TLO_STATIC_DOCS_DATA_PATH + 'manual\\TLO_user_manual_new_revised_by_LDE.pdf';
            props.history.push(location.pathname);
            window.open(url, '_blank');
        } else {
            props.history.push(linkPath);
        }
    };

    const closeModal = () => setModalScreen(null);

    const showModalContactUs = () => setModalScreen(modalScreenTypes.CONTACT_US);

    const filterPagesPerUserRoles = (pages: Array<Page>) => {
        let finalPagesList: Array<Page> = pages;
        if (userRoles.indexOf('ACADM') === -1) {
            finalPagesList = finalPagesList.filter(page => page.title !== 'Administrator');
        }
        return finalPagesList;
    };

    const openContactUsModalRoutine = (page: Page) => (
        <a
            onClick={() => {
                showModalContactUs();
            }}
            key={page.id}
        >
            <div
                className={cx(styles.pageTitleContainer, page.title === 'Contact Us' && styles.pageTitleTinyContainer)}
                key={page.id}
            >
                {page.title}
            </div>
        </a>
    );

    const addAdminIcon = (page: Page) => (page.title === 'Administrator' ? <AdminIcon /> : null);

    const goToPageRoutine = (page: Page) => (
        <a
            onClick={() => {
                goToPage(page.link);
            }}
            key={page.id}
            target={page.title === 'Contract’s User Manual' ? '_blank' : '_none'}
        >
            <div
                className={cx(styles.pageTitleContainer, page.title === 'CPVO Box' && styles.pageTitleTinyContainer)}
                key={page.id}
            >
                {page.title}
                {addAdminIcon(page)}
            </div>
        </a>
    );

    const DashboardArea = () => {
        const sections = dashboardDataJSON.dashboardData.sections;
        return sections.map((sectionEl: Section) => {
            const sectionPages = filterPagesPerUserRoles(sectionEl.pages);
            return (
                <div className={styles.sectionElement} key={sectionEl.id}>
                    <Title>{sectionEl.title}</Title>
                    {sectionPages.map((pageEl: Page) => {
                        if (pageEl.title === 'Contact Us') {
                            return openContactUsModalRoutine(pageEl);
                        } else {
                            return goToPageRoutine(pageEl);
                        }
                    })}
                </div>
            );
        });
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {modalScreen === modalScreenTypes.CONTACT_US ? (
                <ModalContactUsVersion2 additionalSendToList={sendToList} subjectTag={'[ACAREA]'} close={closeModal} />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={'AC Area'} />
            <NavigationACArea />
            <MainWrapper>
                <FormWrapper paddingFormContent={'sm'}>
                    {DashboardArea()}
                    <div style={{clear: 'both'}} />
                </FormWrapper>
            </MainWrapper>
            <Footer customSendToList={sendToList.slice(1)} headerTitle={'Contact Webmaster'} subjectTag={'[ACAREA]'} />
        </>
    );
};

export default HomePageACAREA;
