import React from 'react';

class ApplicationNumbersMultiple extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {applicationNumbers} = this.props;

        if (!applicationNumbers || !applicationNumbers.length) {
            return null;
        }

        return (
            <div
                onClick={e => {
                    if ((applicationNumbers || []).length > 3) {
                        e.preventDefault();
                        e.stopPropagation();
                        this.props.openModalApplicationNumbers &&
                            this.props.openModalApplicationNumbers(applicationNumbers);
                    }
                }}
            >
                {applicationNumbers.slice(0, 3).map(applicationNumber => (
                    <span
                        key={applicationNumber}
                        style={{
                            background: 'rgb(0, 120, 212)',
                            color: 'white',
                            marginRight: 5,
                            paddingLeft: 3,
                            paddingRight: 3,
                            borderRadius: 3,
                            fontWeight: 'normal',
                        }}
                    >
                        {applicationNumber}
                    </span>
                ))}
                {applicationNumbers.length > 3 && (
                    <span
                        style={{
                            background: 'rgb(0, 120, 212)',
                            color: 'white',
                            marginRight: 5,
                            paddingLeft: 3,
                            paddingRight: 3,
                            borderRadius: 3,
                            fontWeight: 'normal',
                        }}
                    >
                        {'...'}
                    </span>
                )}
            </div>
        );
    }
}

export default ApplicationNumbersMultiple;
