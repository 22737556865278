import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import SelectInput from '~components/SelectInput';
import {injectIntl} from 'react-intl';
import {
    CASEHOLDERS_SHORTKEYS,
    getSelectListOfDirectory,
    USER_SHORTKEY_TO_CONTACTID,
} from '../CommunicationCentreInternal.utils';
import {ICommunicationMessageInternalRead, ICommunicationType, IDecodedJWT} from '../../../types';
import {getDecodedJWT} from '~utils';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './ModalMessageClassify.module.scss';
import {LoadingBar} from '../../../componentsLayout';
import {apiCommunicationCentreClassifyCommunication} from './ModalMessageClassifyService';
import PROFILES_DIRECTORIES_SUBTYPES_INTERNAL from '../../CommunicationCentreInternal/modals/DATA/PROFILES_DIRECTORIES_SUBTYPES_INTERNAL.json';
import {Button} from '../../../componentsFormV2';

const SELECT_LIST_ID_ALL = 'All';

interface IProps {
    close: (refresh: boolean) => any;
    intl: any;
    message: ICommunicationMessageInternalRead | null;
}

interface IState {
    todoBy: string;
    numberOfIterationsOfCommunicationTypes: number;
    communicationTypes: ICommunicationType[];
    loading: boolean;
}

class ModalMessageClassify extends React.Component<IProps, IState> {
    decodedJWT: IDecodedJWT = getDecodedJWT();

    constructor(props: IProps) {
        super(props);
        this.state = {
            todoBy: this.props.message && this.props.message.todoBy ? this.props.message.todoBy : '',
            numberOfIterationsOfCommunicationTypes: 1,
            communicationTypes:
                this.props.message?.communicationTypes && this.props.message?.communicationTypes.length > 0
                    ? this.props.message?.communicationTypes
                    : [
                          {
                              docmanDirectory: this.props.message?.docmanDirectory || null,
                              docmanSubtypeOfDocument: this.props.message?.docmanSubtypeOfDocument || null,
                          },
                      ],
            loading: false,
        };
    }

    findDocumentTypesListForIteration = (iterationIndex: number) =>
        Object.values(PROFILES_DIRECTORIES_SUBTYPES_INTERNAL)
            .flat(1)
            .filter(el => el.dirId === this.state.communicationTypes[iterationIndex].docmanDirectory)
            .map(el => ({
                id: el.docTypeId,
                value: el.docTypeName,
            }));

    onSelectInputValueDirectoryChange = (selectInputValueDirectory: string, iterationIndex: number): void =>
        this.setState(prev => {
            const updatedCommunicationTypes = [...prev.communicationTypes];
            updatedCommunicationTypes[iterationIndex].docmanDirectory =
                selectInputValueDirectory && selectInputValueDirectory !== SELECT_LIST_ID_ALL
                    ? parseInt(selectInputValueDirectory)
                    : null;
            updatedCommunicationTypes[iterationIndex].docmanSubtypeOfDocument = null;
            return {
                communicationTypes: updatedCommunicationTypes,
            };
        });

    onSelectInputValueDocumentTypeChange = (selectInputValueDocumentType: string, iterationIndex: number): void =>
        this.setState(prev => {
            const updatedCommunicationTypes = [...prev.communicationTypes];
            updatedCommunicationTypes[iterationIndex].docmanSubtypeOfDocument =
                selectInputValueDocumentType && selectInputValueDocumentType !== SELECT_LIST_ID_ALL
                    ? parseInt(selectInputValueDocumentType)
                    : null;
            return {
                communicationTypes: updatedCommunicationTypes,
            };
        });

    onSelectInputValueTodoByChange = ({
        target: {value: selectInputValueTodoBy},
    }: React.ChangeEvent<HTMLSelectElement>): void =>
        this.setState({
            todoBy:
                selectInputValueTodoBy && selectInputValueTodoBy !== SELECT_LIST_ID_ALL ? selectInputValueTodoBy : '',
        });

    onPlusButton = () =>
        this.setState(prev => ({
            numberOfIterationsOfCommunicationTypes: prev.numberOfIterationsOfCommunicationTypes + 1,
            communicationTypes: [...prev.communicationTypes, {docmanDirectory: null, docmanSubtypeOfDocument: null}],
        }));

    onMinusButton = (iterationIndex: number) => {
        this.setState(prev => {
            const updatedCommunicationTypes = [...prev.communicationTypes];
            updatedCommunicationTypes.splice(iterationIndex, 1);
            return {
                numberOfIterationsOfCommunicationTypes: prev.numberOfIterationsOfCommunicationTypes - 1,
                communicationTypes: updatedCommunicationTypes,
            };
        });
    };

    onButtonClassifyClick = () => {
        this.setState({loading: true}, () => {
            apiCommunicationCentreClassifyCommunication({
                communicationId: this.props.message?.communicationId || -1,
                communicationTypes: this.state.communicationTypes,
                todoByShortKey: this.state.todoBy,
            })
                .then(JSONResponse => {
                    if (JSONResponse && JSONResponse.data === 'OK') {
                        this.props.close(true);
                    }
                })
                .then(() => {
                    this.setState({loading: false});
                });
        });
    };

    render() {
        const formattedTodoByList = Object.keys(USER_SHORTKEY_TO_CONTACTID).map(shortKey => ({
            id: shortKey,
            value: `${shortKey}${CASEHOLDERS_SHORTKEYS.includes(shortKey) ? ' - Caseholder' : ''}`,
        }));

        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={<h2>{`Classify message`}</h2>}
                body={
                    <div>
                        <SelectInput
                            outsideLabel={`To do by`}
                            outsideLabelWidth={160}
                            value={this.state.todoBy}
                            onChange={this.onSelectInputValueTodoByChange}
                            list={formattedTodoByList}
                            double={true}
                        />
                        <div style={{clear: 'both'}} />
                        {Array(this.state.numberOfIterationsOfCommunicationTypes)
                            .fill(true)
                            .map((_, iterationIndex) => (
                                <div
                                    className={styles.communicationTypeRowWrap}
                                    key={`communication-type-iteration-${Date.now()}`}
                                >
                                    <SelectInput
                                        outsideLabel={`Directory`}
                                        outsideLabelWidth={160}
                                        value={this.state.communicationTypes[iterationIndex].docmanDirectory}
                                        onChange={({
                                            target: {value: selectInputValueDocumentType},
                                        }: React.ChangeEvent<HTMLSelectElement>) =>
                                            this.onSelectInputValueDirectoryChange(
                                                selectInputValueDocumentType,
                                                iterationIndex
                                            )
                                        }
                                        list={getSelectListOfDirectory(this.decodedJWT)}
                                        double={true}
                                    />
                                    <SelectInput
                                        outsideLabel={`Communication type`}
                                        outsideLabelWidth={160}
                                        value={this.state.communicationTypes[iterationIndex].docmanSubtypeOfDocument}
                                        onChange={({
                                            target: {value: selectInputValueDocumentType},
                                        }: React.ChangeEvent<HTMLSelectElement>) =>
                                            this.onSelectInputValueDocumentTypeChange(
                                                selectInputValueDocumentType,
                                                iterationIndex
                                            )
                                        }
                                        list={this.findDocumentTypesListForIteration(iterationIndex)}
                                        double={true}
                                    />
                                    <div style={{clear: 'both'}} />
                                    {this.state.numberOfIterationsOfCommunicationTypes > 1 && (
                                        <div className={styles.minusWrap}>
                                            <button
                                                type="button"
                                                className={styles.buttonMinus}
                                                onClick={() => this.onMinusButton(iterationIndex)}
                                            >
                                                <FontAwesomeIcon icon={faMinus as any} />
                                            </button>
                                        </div>
                                    )}
                                    <div style={{clear: 'both'}} />
                                </div>
                            ))}
                        <div style={{clear: 'both'}} />
                        {/* @ts-ignore */}
                        {window[`enablePlusWrap`] ? (
                            <div className={styles.plusWrap}>
                                <button type="button" className={styles.buttonPlus} onClick={this.onPlusButton}>
                                    <FontAwesomeIcon icon={faPlus as any} />
                                </button>
                            </div>
                        ) : null}
                    </div>
                }
                footer={
                    <div style={{padding: '15px 0'}}>
                        {this.state.loading ? (
                            <LoadingBar />
                        ) : (
                            <>
                                <Button
                                    icon={'close'}
                                    clickAction={this.props.close}
                                    variation={'danger'}
                                >{`Close`}</Button>
                                <Button
                                    icon={'arrowRight'}
                                    clickAction={this.onButtonClassifyClick}
                                >{`Classify`}</Button>
                            </>
                        )}
                    </div>
                }
                plus={2}
            />
        );
    }
}

export default injectIntl(ModalMessageClassify);
