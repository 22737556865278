import React from 'react';

interface IIconDocumentProps {
    style?: any;
    className?: any;
    color?: string;
}

function IconDocument({style: userStyle, color, className}: IIconDocumentProps) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{...(userStyle || {})}}
            className={className}
        >
            <path
                d="M2.5 8.33335C2.5 5.19085 2.5 3.61919 3.47667 2.64335C4.4525 1.66669 6.02417 1.66669 9.16667 1.66669H10.8333C13.9758 1.66669 15.5475 1.66669 16.5233 2.64335C17.5 3.61919 17.5 5.19085 17.5 8.33335V11.6667C17.5 14.8092 17.5 16.3809 16.5233 17.3567C15.5475 18.3334 13.9758 18.3334 10.8333 18.3334H9.16667C6.02417 18.3334 4.4525 18.3334 3.47667 17.3567C2.5 16.3809 2.5 14.8092 2.5 11.6667V8.33335Z"
                stroke={color || '#999999'}
                strokeWidth="1.5"
            />
            <path
                d="M6.66667 10H13.3333H6.66667ZM6.66667 6.66669H13.3333H6.66667ZM6.66667 13.3334H10.8333H6.66667Z"
                fill={color || '#999999'}
            />
            <path
                d="M6.66667 10H13.3333M6.66667 6.66669H13.3333M6.66667 13.3334H10.8333"
                stroke={color || '#999999'}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
}

export default IconDocument;
