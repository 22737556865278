import {API_SERVERLESS, getFetch} from '~commonApi';

export interface IModalComparisonForm {
    groups?: {
        id: string;
        order: string;
        name: string;
        isTemplate: boolean;
        templateId: number | null;
        jsonMapping: any;
        questions: {
            id: number;
            order: string;
            name: string;
            jsonMapping: string;
            responses: {
                id: number;
                order: string;
                name: string;
                jsonMapping: string;
                comparison: number;
            }[];
            comparison: number;
        }[];
        comparison: number;
    }[];
    id: number;
    jsonMapping: any;
    name: string;
}

export function apiBackOfficeFormCompare(
    formId: any
): Promise<{data: {currentVersionId: number; form: IModalComparisonForm; previousVersionId: number | null}}> {
    return getFetch(`${API_SERVERLESS}/backOffice/v2/compareForm/${formId}`, true);
}
