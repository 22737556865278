import React from 'react';
import styles from './LoadingBar.module.scss';

export default function () {
    return (
        <section className={styles.bar}>
            <div className={styles.barWrap}>
                <div className={styles.bar} />
            </div>
        </section>
    );
}
