import {IPayloadQuestionCreateOrUpdate} from './QuestionModalDetailsGeneralInformationService';
import {IStateQuestion} from './QuestionModalDetailsGeneralInformation';
import {IGroup} from '../../../types';
import QUESTION_TYPES from './QuestionModalDetailsGeneralInformationElements.json';

export function prepareJSONRequestBody(
    JSONRequest: IStateQuestion & {formId?: any; templateId?: any}
): IPayloadQuestionCreateOrUpdate {
    return {
        ...JSONRequest,
        nameMessage: {id: JSONRequest.nameMessage.id},
        helpMessage: {
            id: JSONRequest.helpMessage.id,
        },
        remarkMessage: {
            id: JSONRequest.remarkMessage.id,
        },
        btnAddMsg: {
            id: JSONRequest.btnAddMsg.id,
        },
        btnDelMsg: {
            id: JSONRequest.btnDelMsg.id,
        },
    };
}

export function getQuestionOrderForNewTemplateQuestion(steps: IGroup[]) {
    if (steps && steps.length) {
        let template = steps[0];
        let groupOrder = template.order;
        let questionOrder =
            (template.questions || []).length > 0 ? `00${(template.questions || []).length + 1}`.slice(-2) : '01';
        return `${groupOrder}.${questionOrder}`;
    } else {
        return '';
    }
}

export const getQuestionTypeIdFromTypeName = (questionTypeName: any) => {
    let questionType = QUESTION_TYPES.types.find(type => type.name === questionTypeName);
    return questionType ? questionType.id : null;
};
