import React from 'react';
import CustomTable from '../../../../../../components/CustomTable';
import Checkbox from '~components/Checkbox';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {IntlShape} from 'react-intl';
import {TqSearchCandidateVarietiesTableRowData} from '../../TqSearchCandidateVarietiesPageService';
import {faImage} from '@fortawesome/free-solid-svg-icons';
import styles from './CandidateVarietiesTable.module.scss';

interface CandidateVarietiesTableProps {
    intl: IntlShape;
    candidateVarieties: Array<TqSearchCandidateVarietiesTableRowData> | null;
    timestamp: number;
    downloadPhotos: () => void;
}

export default function CandidateVarietiesTable(props: CandidateVarietiesTableProps) {
    const RESULT_FIELDS_DEFAULT = [
        'groupOrder',
        'questionOrder',
        'questionName',
        'score',
        'textValue',
        'remarkValue',
        'confidential',
    ];

    const getTopCaptionElement = () => {
        return (
            <div className={styles.topCaptionContainer}>
                <div className={styles.topCaptionContent}>
                    <FormFooterButton color={buttonColor.blue} clickAction={props.downloadPhotos} icon={faImage}>
                        {`Download Photos`}
                    </FormFooterButton>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <React.Fragment>
                <CustomTable
                    version={2}
                    tableName={'candidateVarieties'}
                    tableType={'OBJECT'}
                    tableSource={props.candidateVarieties}
                    timestamp={props.timestamp}
                    id={'groupOrder'}
                    resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                    resultFieldsAll={RESULT_FIELDS_DEFAULT}
                    intl={props.intl}
                    defaultOrder={'groupOrder'}
                    reverseOrder={false}
                    hideExcelButton={true}
                    pageSize={50}
                    formatFunctions={{
                        confidential: (confidential: number, _rowObject: TqSearchCandidateVarietiesTableRowData) => (
                            <Checkbox value={confidential !== 0} disabled={true} />
                        ),
                    }}
                    topCaption={getTopCaptionElement()}
                    count={props.candidateVarieties !== null && props.candidateVarieties.length}
                />
            </React.Fragment>
        </React.Fragment>
    );
}
