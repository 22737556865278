import React from 'react';
import Navigation, {ILink} from '../componentsLayout/Navigation';
import {checkIfUserHasAccessToMyPVR, getDecodedJWT} from '../utils';

function NavigationOnlineApplications() {
    let decoded = getDecodedJWT();
    const userHasAccessToMyPVR = checkIfUserHasAccessToMyPVR(decoded);

    const links: ILink[] = [
        {to: '/applications', title: 'Applications'},
        {
            to: '/delegations',
            title: 'Delegations',
        },
    ];
    userHasAccessToMyPVR &&
        links.push({
            to: '/dashboard',
            title: 'My PVR',
            style: {color: 'rgb(0, 70, 121)', fontWeight: 'bold'},
        });

    return <Navigation links={links} />;
}

export default NavigationOnlineApplications;
