import React, {useState} from 'react';
import {Button} from '../../../../../componentsFormV2';
import {Error} from '../../../../../componentsLayout';
import {ErrorMessages} from '../../../../TLO/CommonInterfaces/CommonInterfaces';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {KeywordElement} from '../CaseFormPagePVRCASELAWService';
import {ModalCustomVersion2} from '../../../../../commonModals';
import TextInput from '~components/TextInput';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {getConcatErrorMessage} from '../../../../TLO/CommonFunctions/CommonFunctions';

interface ModalKeywordProps {
    keywordElement: KeywordElement | null;
    saveKeyword: (keywordId: number, keyword: string) => void;
    close: () => void;
}

const ModalKeyword = (props: ModalKeywordProps) => {
    const keywordId = props.keywordElement?.keywordId || 0;
    const [keyword, setKeyword] = useState(props.keywordElement?.keyword || '');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [highlightErrors, setHighlightErrors] = useState(false);

    const onKeywordChange = ({target: {value: keyword}}: React.ChangeEvent<HTMLInputElement>) => setKeyword(keyword);

    const getErrorMessagesForSave = () => {
        setHighlightErrors(false);
        const errorMessages: ErrorMessages = {
            errorInput: [],
            errorLogic: [],
        };
        if (keyword.length === 0)
            errorMessages.errorInput && errorMessages.errorInput.push('a keyword for the new keyword');
        return errorMessages;
    };

    const onSaveKeyword = () => {
        const errorMessagesObj = getErrorMessagesForSave();
        let errorMessage = getConcatErrorMessage(errorMessagesObj);
        if (errorMessage.length > 0) {
            setErrorMessage(errorMessage);
            setHighlightErrors(true);
        }
        if (errorMessage.length === 0) props.saveKeyword(keywordId, keyword);
    };

    return (
        <ModalCustomVersion2
            close={() => props.close && props.close()}
            plus2={true}
            header={keywordId > 0 ? 'Edit Keyword' : 'Add New Keyword'}
            body={
                <>
                    <TextInput
                        value={keyword}
                        outsideLabel={'Keyword'}
                        outsideLabelWidth={140}
                        disabled={false}
                        onChange={onKeywordChange}
                        double={true}
                        placeholder={`Please type a Keyword`}
                    />
                    <div style={{clear: 'both'}} />
                </>
            }
            footer={
                <>
                    {highlightErrors ? (
                        <Error>
                            <FontAwesomeIcon icon={faExclamationCircle as IconDefinition} color={'white'} />
                            {` ${errorMessage}`}
                        </Error>
                    ) : null}
                    <Button clickAction={props.close} icon={'close'} variation={'danger'}>{`Cancel`}</Button>
                    <Button clickAction={onSaveKeyword}>{`Save`}</Button>
                </>
            }
        />
    );
};

export default ModalKeyword;
