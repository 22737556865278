import React, {useEffect, useRef, useState} from 'react';
import {ACCESS_MESSAGES} from '../../ConsultModifySpeciesDataPage';
import {Button} from '../../../../../../componentsFormV2';
import {
    CultivationTypesFetchData,
    EntrustmentEditMode,
    EntrustmentForSave,
    EntrustmentMode,
    FeeGroupsFetchData,
    ModalScreenType,
    PlantMaterialRequirements,
    PlantMaterialSubmissionType,
    QuantityQualityFetchData,
    SpeciesNamesFetchData,
    SubmissionPlacesFetchData,
    TestingSitesFetchData,
} from './Interfaces/ModalEntrustmentInterfaces';
import Checkbox from '~components/Checkbox';
import CustomDatePicker from '../../../../../../componentsForm/CustomDatePicker';
import DateInput from '~components/DateInput';
import {DraftEntrustment, Entrustment} from '../../../../../../types/TLO';
import Empty from '~components/Empty';
import {Error} from '../../../../../../componentsLayout';
import {ErrorMessages} from '../../../../CommonInterfaces/CommonInterfaces';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {History} from 'history';
import Label from '../../../../../../components/Label';
import {ModalConfirmVersion2, ModalCustomVersion2, ModalErrorVersion2} from '../../../../../../commonModals';
import ModalDefaultRequirements from '../../../../CommonComponents/ModalDefaultRequirements/ModalDefaultRequirements';
import RadioButton from '~components/RadioButton';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import SelectInput from '~components/SelectInput';
import TextLabelInput from '../../../../../../components/TextLabelInput';
import TextInput from '~components/TextInput';
import TextAreaInput from '~components/TextAreaInput';
import {
    apiCultivationTypesAutocompletionFetch,
    apiFeeGroupsFetch,
    apiQuantityQualityFetch,
    apiSpeciesAutocompletionFetch,
    apiSubmissionPlacesFetch,
    apiTestingSitesFetch,
} from './Services/ModalEntrustmentServices';
import {faEdit, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {getMandatoryAsterisk} from '../../../../../../utils/format';
import {getConcatErrorMessage, getDecodedInfoFromToken} from '../../../../CommonFunctions/CommonFunctions';
import {injectIntl, IntlShape} from 'react-intl';
import moment from 'moment';
import styles from './ModalEntrustment.module.scss';

export const entrustmentMode: EntrustmentMode = {CONSULT_MODIFY: 'CONSULT_MODIFY', NEW: 'NEW', WITHDRAW: 'WITHDRAW'};

export const entrustmentEditMode: EntrustmentEditMode = {NEW: 'NEW', VIEW: 'VIEW', EDIT: 'EDIT'};

const plantMaterialSubmissionType: PlantMaterialSubmissionType = {WEEK_NUMBER: 1, CALENDAR_DATE: 2};

const modalScreenType: ModalScreenType = {
    ERROR: 'ERROR',
    // SAVE_PROPOSAL_DRAFT_INFO: 'SAVE_PROPOSAL_DRAFT_INFO',
    SUBMIT_PROPOSAL_CONFIRM: 'SUBMIT_PROPOSAL_CONFIRM',
    DEFAULT_REQUIREMENTS_CUSTOM: 'DEFAULT_REQUIREMENTS_CUSTOM',
};

interface ModalEntrustmentProps {
    intl: IntlShape;
    history: History;
    speciesExamId: number;
    modifType: number | string | null;
    entrustmentObject: Entrustment | DraftEntrustment;
    entrustmentMode: string;
    entrustmentEditMode: string;
    accessMessage: string;
    modifySeveralPlantRequirements?: boolean;
    submitToTheCPVO: (entrustmentDataForSave: EntrustmentForSave) => void;
    close: (refresh?: boolean) => void;
}

const ModalEntrustment = (props: ModalEntrustmentProps & RouteComponentProps) => {
    const initQuantityQualityIds = (obj: Entrustment | DraftEntrustment) => {
        if ('quantityQualityIds' in obj) {
            if (obj.quantityQualityIds !== null) {
                return obj.quantityQualityIds.toString();
            } else {
                return '';
            }
        } else {
            return '';
        }
    };
    const initPlantMaterialSubmissionTypeState = () => {
        if (props.entrustmentObject.startDate || props.entrustmentObject.endDate) {
            return plantMaterialSubmissionType.CALENDAR_DATE;
        } else if (props.entrustmentObject.startWeek || props.entrustmentObject.endWeek) {
            return plantMaterialSubmissionType.WEEK_NUMBER;
        } else {
            return plantMaterialSubmissionType.CALENDAR_DATE;
        }
    };
    const [loading, setLoading] = useState(false);
    const [errorMessageModal, setErrorMessageModal] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [editMode, setEditMode] = useState(props.entrustmentEditMode);
    const [examinationOfficeName, setExaminationOfficeName] = useState(props.entrustmentObject.examOffice || '');
    const [speciesName, setSpeciesName] = useState(props.entrustmentObject.speciesName || '');
    const [selectedSpeciesNames, setSelectedSpeciesNames] = useState<{[key: string]: string}>({});
    const [speciesNamesListByIdName, setSpeciesNamesListByIdName] = useState<Array<{ID: string; NAME: string}> | null>(
        null
    );
    const [speciesNamesHash, setSpeciesNamesHash] = useState<{[key: string]: Array<string>}>({});
    const [entrustmentType, setEntrustmentType] = useState(props.entrustmentObject.entrustmentType || '');
    const [examinationFulfillsDate, setExaminationFulfillsDate] = useState(props.entrustmentObject.decisionDate || '');
    const [cultivationType, setCultivationType] = useState(props.entrustmentObject.cultivationType || '');
    const [selectedCultivationTypes, setSelectedCultivationTypes] = useState<{[key: string]: string}>({});
    const [cultivationTypesListByIdName, setCultivationTypesListByIdName] = useState<Array<{
        ID: string;
        NAME: string;
    }> | null>(null);
    const [cultivationTypesNameHash, setCultivationTypesNameHash] = useState<{[key: string]: Array<string>}>({});
    const [feeGroupId, setFeeGroupId] = useState(
        props.entrustmentObject.examFeeGroupId ? Number(props.entrustmentObject.examFeeGroupId) : 0
    );
    const [feeGroups, setFeeGroups] = useState<Array<{id: number; value: string}>>([
        {
            id: 0,
            value: 'Please choose',
        },
    ]);
    const [growingCycles, setGrowingCycles] = useState(
        (props.entrustmentObject.enamnBy &&
            props.entrustmentObject.enamnBy !== 0 &&
            props.entrustmentObject.enamnBy.toString()) ||
            '0'
    );
    const [submissionPlaceId, setSubmissionPlaceId] = useState(0);
    const [submissionPlaces, setSubmissionPlaces] = useState<Array<{id: number; value: string}>>([
        {
            id: 0,
            value: 'Please choose',
        },
    ]);
    const [testingSiteId, setTestingSiteId] = useState(0);
    const [testingSites, setTestingSites] = useState<Array<{id: number; value: string}>>([
        {
            id: 0,
            value: 'Please choose',
        },
    ]);
    const [closingDate, setClosingDate] = useState(props.entrustmentObject.closingDate || '');
    const [plantMaterialSubmissionTypeState, setPlantMaterialSubmissionTypeState] = useState(
        initPlantMaterialSubmissionTypeState()
    );
    const [submissionStartWeek, setSubmissionStartWeek] = useState(
        props.entrustmentObject.startWeek.toString() || null
    );
    const [submissionEndWeek, setSubmissionEndWeek] = useState(props.entrustmentObject.endWeek.toString() || null);
    const [submissionStartDate, setSubmissionStartDate] = useState(props.entrustmentObject.startDate || '');
    const [submissionEndDate, setSubmissionEndDate] = useState(props.entrustmentObject.endDate || '');
    const [firstInterimDate, setFirstInterimDate] = useState(props.entrustmentObject.interimReportDate || '');
    const [foreseenDate, setForeseenDate] = useState(props.entrustmentObject.foreseenFinalReport || '');
    const [seedPlantQuantityQuality, setSeedPlantQuantityQuality] = useState(
        props.entrustmentObject.quantityQuality || ''
    );
    const [plantRequirements, setPlantRequirements] = useState<PlantMaterialRequirements | null>(null);
    const [seedPlantQuantityQualityIds, setSeedPlantQuantityQualityIds] = useState(
        initQuantityQualityIds(props.entrustmentObject)
    );
    const [comment, setComment] = useState(props.entrustmentObject.newFeeGroupRemark || '');
    const [deleteCultivationType, setDeleteCultivationType] = useState(false);
    const [addCultivationType, setAddCultivationType] = useState(false);
    const [specifyCultivationType, setSpecifyCultivationType] = useState(props.entrustmentObject.remark || '');
    const [highlightErrors, setHighlightErrors] = useState(false);
    const [modalDefaultRequirementsTriggerCloseAfterInit, setModalDefaultRequirementsTriggerCloseAfterInit] =
        useState(false);

    const firstLoad = useFirstRender();

    function useFirstRender() {
        const firstRender = useRef(true);

        useEffect(() => {
            firstRender.current = false;
        }, []);

        return firstRender.current;
    }

    useEffect(() => {
        if (firstLoad) {
            props.accessMessage === ACCESS_MESSAGES.CONSULTATION_ACCESS && setEditMode(entrustmentEditMode.VIEW);
            setExaminationOfficeNameFromToken();
            loadJSONs();
            if (editMode !== entrustmentEditMode.NEW) {
                const {examFeeGroupId, examFeeGroupName} = props.entrustmentObject || {};
                //TODO review:
                // entrustmentObject in mode 'VIEW': {examFeeGroupName: "13-Vegetable greenhouse"}
                // entrustmentObject in mode 'EDIT': {examFeeGroupName: "Vegetable greenhouse"}
                if (examFeeGroupId && examFeeGroupName) {
                    setFeeGroups([
                        {
                            id: parseInt(examFeeGroupId),
                            value: `${
                                editMode === entrustmentEditMode.EDIT ? `${examFeeGroupId}-` : ''
                            }${examFeeGroupName}`,
                        },
                    ]);
                    setFeeGroupId(parseInt(examFeeGroupId));
                }
            }
        }
    }, []);

    const loadJSONs = () => {
        if (editMode === entrustmentEditMode.EDIT) {
            setModalDefaultRequirementsTriggerCloseAfterInit(true);
            getSpeciesAutocompletionList();
            getPlantRequirementsData();
        }
        (editMode === entrustmentEditMode.NEW || speciesName.length === 0) && getSpeciesAutocompletionList();
        editMode !== entrustmentEditMode.VIEW && getCultivationTypesAutocompletionList();
        editMode !== entrustmentEditMode.EDIT && editMode !== entrustmentEditMode.VIEW && getFeeGroups();
        getSubmissionPlaces();
        getTestingSites();
    };

    const getSpeciesAutocompletionList = () => {
        setLoading(true);
        apiSpeciesAutocompletionFetch(speciesName)
            .then((jsonResponse: SpeciesNamesFetchData) => {
                const speciesNameHash: {[key: string]: Array<string>} = {};
                ((jsonResponse && jsonResponse.data && jsonResponse.data.specieNames) || []).forEach(sn => {
                    if (!speciesNameHash[sn.lib]) {
                        speciesNameHash[sn.lib] = [];
                    }
                    speciesNameHash[sn.lib].push(sn.id);
                });
                const speciesNameListByIdName = Object.keys(speciesNameHash).map(snn => ({
                    ID: speciesNameHash[snn].join(','),
                    NAME: snn,
                }));
                const selectedSpeciesNamesObj: {[key: string]: string} = {};
                const selectedSpeciesNamesKeyArray = Object.keys(selectedSpeciesNames || {});
                speciesNameListByIdName
                    .filter(i => selectedSpeciesNamesKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectedSpeciesNamesObj[item.ID] = item.NAME));
                setSpeciesNamesHash(speciesNameHash);
                setSpeciesNamesListByIdName(speciesNameListByIdName);
                setSelectedSpeciesNames(selectedSpeciesNamesObj);
            })
            .catch(error => {
                setErrorMessageModal(`Species Names Autocompletion list error: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getCultivationTypesAutocompletionList = () => {
        setLoading(true);
        const cultivationTypeTerm = cultivationType;
        apiCultivationTypesAutocompletionFetch(cultivationTypeTerm)
            .then((jsonResponse: CultivationTypesFetchData) => {
                const cultivationTypesNameHash: {[key: string]: Array<string>} = {};
                ((jsonResponse && jsonResponse.data && jsonResponse.data.cultivationTypes) || []).forEach(ct => {
                    if (!cultivationTypesNameHash[ct.cultivationType]) {
                        cultivationTypesNameHash[ct.cultivationType] = [];
                    }
                    cultivationTypesNameHash[ct.cultivationType].push(ct.id);
                });
                const cultivationTypesListByIdName = Object.keys(cultivationTypesNameHash).map(ctn => ({
                    ID: cultivationTypesNameHash[ctn].join(','),
                    NAME: ctn,
                }));
                const selectedCultivationTypesObj: {[key: string]: string} = {};
                const selectedCultivationTypesKeyArray = Object.keys(selectedCultivationTypes || {});
                cultivationTypesListByIdName
                    .filter(i => selectedCultivationTypesKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectedCultivationTypesObj[item.ID] = item.NAME));
                setCultivationTypesNameHash(cultivationTypesNameHash);
                setCultivationTypesListByIdName(cultivationTypesListByIdName);
                setSelectedCultivationTypes(selectedCultivationTypesObj);
            })
            .catch(error => {
                setErrorMessageModal(`Cultivation Types Autocompletion list error: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getFeeGroups = () => {
        setLoading(true);
        apiFeeGroupsFetch()
            .then((jsonResponse: FeeGroupsFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.feeGroups) {
                    const finalFeeGroups: Array<{id: number; value: string}> = feeGroups;
                    jsonResponse.data.feeGroups.forEach(feeGroup =>
                        finalFeeGroups.push({
                            id: Number(feeGroup.examFeeGroupId),
                            value: feeGroup.examFeeGroupId + ' - ' + feeGroup.feeGroupName,
                        })
                    );
                    setFeeGroups(finalFeeGroups);
                }
            })
            .catch(error => {
                setErrorMessageModal(`Fee Groups list error: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getSubmissionPlaces = () => {
        setLoading(true);
        apiSubmissionPlacesFetch()
            .then((jsonResponse: SubmissionPlacesFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.submissionPlaces) {
                    const finalSubmissionPlaces: Array<{id: number; value: string}> = [];
                    jsonResponse.data.submissionPlaces.forEach(submissionPlace =>
                        finalSubmissionPlaces.push({
                            id: submissionPlace.id,
                            value: submissionPlace.name,
                        })
                    );
                    setSubmissionPlaces(finalSubmissionPlaces);
                    if (props.entrustmentObject.submissionAddressId) {
                        const submissionPlaceArray = finalSubmissionPlaces.filter(
                            sp => sp.id === props.entrustmentObject.submissionAddressId
                        );
                        const submissionPlace = submissionPlaceArray.length > 0 ? submissionPlaceArray[0].id : 0;
                        setSubmissionPlaceId(submissionPlace);
                    }
                }
            })
            .catch(error => {
                setErrorMessageModal(`Submission Places list error: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getTestingSites = () => {
        setLoading(true);
        apiTestingSitesFetch()
            .then((jsonResponse: TestingSitesFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.testingSites) {
                    const finalTestingSites: Array<{id: number; value: string}> = [];
                    jsonResponse.data.testingSites.forEach(testingSite =>
                        finalTestingSites.push({
                            id: testingSite.id,
                            value: testingSite.name,
                        })
                    );
                    setTestingSites(finalTestingSites);
                    if (props.entrustmentObject.addressTestingId) {
                        const testingSiteArray = finalTestingSites.filter(
                            sp => sp.id === props.entrustmentObject.addressTestingId
                        );
                        const testingSite = testingSiteArray.length > 0 ? testingSiteArray[0].id : 0;
                        setTestingSiteId(testingSite);
                    }
                }
            })
            .catch(error => {
                setErrorMessageModal(`Testing Sites list error: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const setExaminationOfficeNameFromToken = () => {
        const decoded = getDecodedInfoFromToken();
        decoded && decoded.company && setExaminationOfficeName(decoded.company);
    };

    const comebackToSearch = (refresh = false) => {
        if (refresh) {
            props.close && props.close(refresh);
        } else {
            props.close && props.close();
        }
    };

    const closeErrorModal = () => {
        setModalScreen(null);
        setErrorMessageModal(null);
    };

    // const closeSaveProposalInfoModal = () => {
    //     setModalScreen(null);
    //     comebackToSearch(true);
    // };

    const closeSubmitProposalToCPVO = () => setModalScreen(null);

    const closeDefaultRequirementsModal = (submit: boolean, quantityQuality?: string, quantityQualityIds?: string) => {
        setModalScreen(null);
        if (submit && quantityQuality) setSeedPlantQuantityQuality(quantityQuality);
        if (submit && quantityQualityIds) setSeedPlantQuantityQualityIds(quantityQualityIds);
    };

    const getModalTitleText = () => {
        if (editMode === entrustmentEditMode.NEW) {
            return `Request to become entrusted for an additional species`;
        } else if (editMode === entrustmentEditMode.VIEW) {
            if (props.accessMessage === ACCESS_MESSAGES.CONSULTATION_ACCESS) {
                return `Request to become entrusted for an additional species`;
            } else {
                return `Consult a pending request for change on species data`;
            }
        } else {
            return `Modify a plant material requirement`;
        }
    };

    const getContentTitle = () => {
        if (editMode === entrustmentEditMode.NEW) {
            return (
                <span
                    className={styles.newOrWithdraw}
                >{`You are currently creating a request to become entrusted for an additional species.`}</span>
            );
        } else if (editMode === entrustmentEditMode.VIEW) {
            if (props.speciesExamId !== 0 && props.modifType === 'DEL') {
                return (
                    <span
                        className={styles.newOrWithdraw}
                    >{`You have requested to remove this entrustment from your contract. The request has not been yet processed by the Office. No change possible.`}</span>
                );
            } else if (props.accessMessage === ACCESS_MESSAGES.CONSULTATION_ACCESS) {
                return <span className={styles.editOrView}>{`You are entitled to Consultation only.`}</span>;
            } else {
                return (
                    <span
                        className={styles.editOrView}
                    >{`You are currently consulting a pending request for change of species data which has not been yet processed by the Office. No change possible.`}</span>
                );
            }
        } else {
            return (
                <span
                    className={styles.editOrView}
                >{`You are currently editing an existing plant material requirement.`}</span>
            );
        }
    };

    const onSpeciesSelectionChange = (selectedSpecies: {[key: string]: string}) => {
        if (Object.keys(selectedSpecies).length > 0) {
            setLoading(true);
            apiSpeciesAutocompletionFetch(Object.values(selectedSpecies)[0], true)
                .then((jsonResponse: SpeciesNamesFetchData) => {
                    if (jsonResponse && jsonResponse.data && jsonResponse.data.specieNames) {
                        setSelectedSpeciesNames(selectedSpecies);
                    }
                })
                .catch(error => {
                    setErrorMessageModal(`Species Names selection from autocompletion list error: ${error}`);
                })
                .finally(() => setLoading(false));
        } else {
            setSelectedSpeciesNames({});
            setSpeciesName('');
        }
    };

    const onSpeciesNameChange = (speciesName: string) => setSpeciesName(speciesName);

    const onEntrustmentTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const entrustmentType = event.target.value;
        setEntrustmentType(entrustmentType);
    };

    const onExaminationFulfillsDateChange = (examinationFulfillsDate: string) =>
        setExaminationFulfillsDate(examinationFulfillsDate);

    const onCultivationTypeSelectionChange = (selectedCultivationTypes: {[key: string]: string}) =>
        setSelectedCultivationTypes(selectedCultivationTypes);

    const onCultivationTypeChange = (cultivationType: string) => setCultivationType(cultivationType);

    const onFeeGroupChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const feeGroupId = event.target.value;
        setFeeGroupId(Number(feeGroupId));
    };

    const onGrowingCyclesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const growingCycles = event.target.value
            .replace(/[^0-9]/g, '')
            .replace('', '0')
            .replace(/^0+(\d)/, '$1');
        setGrowingCycles(growingCycles);
    };

    const onSubmissionPlaceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const submissionPlaceId = event.target.value;
        setSubmissionPlaceId(Number(submissionPlaceId));
    };

    const onTestingSiteChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const testingSiteId = event.target.value;
        setTestingSiteId(Number(testingSiteId));
    };

    const onClosingDateChange = (closingDate: string) => setClosingDate(closingDate);

    const onPlantMaterialSubmissionChange = (value: string) => {
        const plantMaterialSubmissionType = value;
        setPlantMaterialSubmissionTypeState(Number(plantMaterialSubmissionType));
    };

    const onSubmissionStartWeekChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(event.target.value) >= 0 && Number(event.target.value) <= 52) {
            const submissionStartWeek = event.target.value
                .replace(/[^0-9]/g, '')
                .replace('', '0')
                .replace(/^0+(\d)/, '$1');
            setSubmissionStartWeek(submissionStartWeek);
        }
    };

    const onSubmissionEndWeekChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(event.target.value) >= 0 && Number(event.target.value) <= 52) {
            const submissionEndWeek = event.target.value
                .replace(/[^0-9]/g, '')
                .replace('', '0')
                .replace(/^0+(\d)/, '$1');
            setSubmissionEndWeek(submissionEndWeek);
        }
    };

    const onSubmissionStartDateChange = (submissionStartDate: string) => setSubmissionStartDate(submissionStartDate);

    const onSubmissionEndDateChange = (submissionEndDate: string) => setSubmissionEndDate(submissionEndDate);

    const onFirstInterimDateChange = (firstInterimDate: string) => setFirstInterimDate(firstInterimDate);

    const onForeseenDateChange = (foreseenDate: string) => setForeseenDate(foreseenDate);

    const onCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const comment = event.target.value;
        setComment(comment);
    };

    const onCheckDeleteCultivationType = () => setDeleteCultivationType(!deleteCultivationType);

    const onCheckAddCultivationType = () => setAddCultivationType(!addCultivationType);

    useEffect(() => {
        if (deleteCultivationType === true && addCultivationType === true) {
            setAddCultivationType(!addCultivationType);
        }
    }, [deleteCultivationType]);

    const onSpecifyCultivationTypeChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const specifyCultivationType = event.target.value;
        setSpecifyCultivationType(specifyCultivationType);
    };

    const getPlantRequirementsData = () => {
        if (props.speciesExamId !== 0) {
            setLoading(true);
            apiQuantityQualityFetch(props.speciesExamId)
                .then((jsonResponse: QuantityQualityFetchData) => {
                    if (
                        jsonResponse &&
                        jsonResponse.data &&
                        jsonResponse.data.main &&
                        jsonResponse.data.otherQualities &&
                        jsonResponse.data.testedPests &&
                        jsonResponse.data.others &&
                        jsonResponse.data.additionalQuantQualityInfo &&
                        jsonResponse.data.additionalOtherQualities &&
                        jsonResponse.data.additionalTestedPests &&
                        jsonResponse.data.additionalOthers
                    ) {
                        const plantRequirements = {
                            main: jsonResponse.data.main,
                            otherQualities: jsonResponse.data.otherQualities,
                            testedPests: jsonResponse.data.testedPests,
                            others: jsonResponse.data.others,
                            additionalQuantQualityInfo: jsonResponse.data.additionalQuantQualityInfo,
                            additionalOtherQualities: jsonResponse.data.additionalOtherQualities,
                            additionalTestedPests: jsonResponse.data.additionalTestedPests,
                            additionalOthers: jsonResponse.data.additionalOthers,
                        };
                        setPlantRequirements(plantRequirements);
                        setModalScreen(modalScreenType.DEFAULT_REQUIREMENTS_CUSTOM);
                    }
                })
                .catch(error => {
                    setErrorMessage(`Plant Requirements data error: ${error}`);
                })
                .finally(() => setLoading(false));
        } else {
            const plantRequirements: PlantMaterialRequirements = {
                main: [
                    {
                        quantityQuality: seedPlantQuantityQuality,
                        quantity1: 0,
                        unit1: '',
                        type1: '',
                        alternativeQuantity1: '',
                        age1: '',
                        size1: '',
                        rootsAndGrafts1: '',
                        seedQuality1: '',
                        phytoSanitaryCertificate1: '',
                        andor: '',
                    },
                ],
                otherQualities: [],
                testedPests: [],
                others: [],
                additionalQuantQualityInfo: [],
                additionalOtherQualities: [],
                additionalTestedPests: [],
                additionalOthers: [],
            };
            setPlantRequirements(plantRequirements);
            setModalScreen(modalScreenType.DEFAULT_REQUIREMENTS_CUSTOM);
        }
    };

    const getDateValueReplacements = () => {
        let startDate = '';
        let endDate = '';
        let closeDate = '';
        let decisionDate = '';
        let foreseenFinalDate = '';
        let finalInterimDate = '';
        let splittedDateList: Array<string> = [];
        if (submissionStartDate.length > 0) {
            splittedDateList = submissionStartDate.split('/');
            startDate = splittedDateList[2] + splittedDateList[1] + splittedDateList[0];
        }
        if (submissionEndDate.length > 0) {
            splittedDateList = submissionEndDate.split('/');
            endDate = splittedDateList[2] + splittedDateList[1] + splittedDateList[0];
        }
        if (closingDate.length > 0) {
            splittedDateList = closingDate.split('/');
            closeDate = splittedDateList[2] + splittedDateList[1] + splittedDateList[0];
        }
        if (examinationFulfillsDate.length > 0) {
            splittedDateList = examinationFulfillsDate.split('/');
            decisionDate = splittedDateList[2] + splittedDateList[1] + splittedDateList[0];
        }
        if (foreseenDate.length > 0) {
            splittedDateList = foreseenDate.split('/');
            foreseenFinalDate = splittedDateList[2] + splittedDateList[1] + splittedDateList[0];
        }
        if (firstInterimDate.length > 0) {
            splittedDateList = firstInterimDate.split('/');
            finalInterimDate = splittedDateList[2] + splittedDateList[1] + splittedDateList[0];
        }

        return {startDate, endDate, closeDate, decisionDate, foreseenFinalDate, finalInterimDate};
    };

    const getModifType = () => {
        let modifType = 'UPD';
        if (props.speciesExamId === 0) {
            modifType = 'INS';
        } else {
            if (deleteCultivationType === true) {
                modifType = 'CTDEL';
            } else if (addCultivationType === true) {
                modifType = 'CTADD';
            }
        }

        return modifType;
    };

    const prepareEntrustmentForSave = (): EntrustmentForSave => {
        const speciesId =
            speciesNamesListByIdName !== null && speciesName.length > 0
                ? speciesNamesListByIdName.filter(species => species.NAME === speciesName)[0].ID
                : 'XXX';
        const dateValueReplacements = getDateValueReplacements();
        const startingPeriod =
            plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE
                ? dateValueReplacements.startDate
                : '';
        const endingPeriod =
            plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE
                ? dateValueReplacements.endDate
                : '';
        const weekStartNumber =
            plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER && submissionStartWeek
                ? submissionStartWeek
                : 0;
        const weekEndNumber =
            plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER && submissionEndWeek
                ? submissionEndWeek
                : 0;
        const feeGroupName = (feeGroups.filter(fg => fg.id === feeGroupId)[0] || {}).value || '';
        const testingSiteAddress = testingSiteId !== 0 ? testingSiteId : 0;
        const submissionPlaceAddress = submissionPlaceId !== 0 ? submissionPlaceId : 0;
        return {
            modifId: props.entrustmentObject.modifId,
            speciesExamId: props.speciesExamId,
            speciesId,
            speciesName: speciesName,
            cultivationType: cultivationType,
            testingOffice: props.entrustmentObject.testingOffice,
            closingDate: dateValueReplacements.closeDate,
            startingPeriod,
            endingPeriod,
            weekStartNumber: Number(weekStartNumber),
            weekEndNumber: Number(weekEndNumber),
            quantityQuality: seedPlantQuantityQuality,
            remark: specifyCultivationType,
            newFeeGroupRemark: comment,
            firstInterimReportDate: dateValueReplacements.finalInterimDate,
            foreseenFinalReportDate: dateValueReplacements.foreseenFinalDate,
            forseenGrowingCycles: Number(growingCycles),
            feeGroupId: feeGroupId.toString(),
            feeGroupName,
            modificationType: getModifType(),
            testingSiteAddress,
            submissionPlaceAddress,
            entrustmentType: entrustmentType,
            quantityQualityIds: seedPlantQuantityQualityIds,
        };
    };

    // const getErrorMessagesForDraft = () => {
    //     setHighlightErrors(false);
    //     const diffSubmissionStartEndDate = moment(submissionStartDate, 'DD/MM/YYYY').diff(
    //         moment(submissionEndDate, 'DD/MM/YYYY'),
    //         'days'
    //     );
    //     const diffSubmissionStartFirstInterimDate = moment(submissionStartDate, 'DD/MM/YYYY').diff(
    //         moment(firstInterimDate, 'DD/MM/YYYY'),
    //         'days'
    //     );
    //     const diffSubmissionStartForeseenDate = moment(submissionStartDate, 'DD/MM/YYYY').diff(
    //         moment(foreseenDate, 'DD/MM/YYYY'),
    //         'days'
    //     );
    //     const diffSubmissionEndFirstInterimDate = moment(submissionEndDate, 'DD/MM/YYYY').diff(
    //         moment(firstInterimDate, 'DD/MM/YYYY'),
    //         'days'
    //     );
    //     const diffSubmissionEndForeseenDate = moment(submissionEndDate, 'DD/MM/YYYY').diff(
    //         moment(foreseenDate, 'DD/MM/YYYY'),
    //         'days'
    //     );
    //     const diffClosingDateSubmissionStartDate = moment(closingDate, 'DD/MM/YYYY').diff(
    //         moment(submissionStartDate, 'DD/MM/YYYY'),
    //         'days'
    //     );
    //     const diffClosingDateSubmissionEndDate = moment(closingDate, 'DD/MM/YYYY').diff(
    //         moment(submissionEndDate, 'DD/MM/YYYY'),
    //         'days'
    //     );
    //     const diffClosingDateFirstInterimDate = moment(closingDate, 'DD/MM/YYYY').diff(
    //         moment(firstInterimDate, 'DD/MM/YYYY'),
    //         'days'
    //     );
    //     const diffClosingDateForeseenDate = moment(closingDate, 'DD/MM/YYYY').diff(
    //         moment(foreseenDate, 'DD/MM/YYYY'),
    //         'days'
    //     );
    //     const diffForeseenDateFirstInterimDate = moment(firstInterimDate, 'DD/MM/YYYY').diff(
    //         moment(foreseenDate, 'DD/MM/YYYY'),
    //         'days'
    //     );
    //     const errorMessages: ErrorMessages = {
    //         errorLogic: [],
    //     };

    //     if (
    //         plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
    //         submissionStartDate.length > 0 &&
    //         diffSubmissionStartEndDate > 0
    //     ) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push(
    //                 'Please check that the submission start date is before the submission end.'
    //             );
    //     }

    //     if (
    //         plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
    //         submissionStartDate.length > 0 &&
    //         firstInterimDate.length > 0 &&
    //         diffSubmissionStartFirstInterimDate > 0
    //     ) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push(
    //                 'Please check that submission start date is before the foreseen first interim date.'
    //             );
    //     }

    //     if (
    //         plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
    //         submissionStartDate.length > 0 &&
    //         foreseenDate.length > 0 &&
    //         diffSubmissionStartForeseenDate > 0
    //     ) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push(
    //                 'Please check that submission start date is before the foreseen final report date.'
    //             );
    //     }

    //     if (
    //         plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
    //         submissionEndDate.length > 0 &&
    //         firstInterimDate.length > 0 &&
    //         diffSubmissionEndFirstInterimDate > 0
    //     ) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push(
    //                 'Please check that submission end date is before the foreseen first interim date.'
    //             );
    //     }

    //     if (
    //         plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
    //         submissionEndDate.length > 0 &&
    //         foreseenDate.length > 0 &&
    //         diffSubmissionEndForeseenDate > 0
    //     ) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push(
    //                 'Please check that submission end date is before the foreseen final report date.'
    //             );
    //     }

    //     if (
    //         plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
    //         closingDate.length > 0 &&
    //         submissionStartDate.length > 0 &&
    //         diffClosingDateSubmissionStartDate > 0
    //     ) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push('Please check that closing date is before the submission start date.');
    //     }

    //     if (
    //         plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
    //         closingDate.length > 0 &&
    //         submissionEndDate.length > 0 &&
    //         diffClosingDateSubmissionEndDate > 0
    //     ) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push('Please check that closing date is before the submission end date.');
    //     }

    //     if (
    //         plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
    //         closingDate.length > 0 &&
    //         firstInterimDate.length > 0 &&
    //         diffClosingDateFirstInterimDate > 0
    //     ) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push(
    //                 'Please check that closing date is before the foreseen first interim date.'
    //             );
    //     }

    //     if (
    //         plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
    //         closingDate.length > 0 &&
    //         foreseenDate.length > 0 &&
    //         diffClosingDateForeseenDate > 0
    //     ) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push(
    //                 'Please check that closing date is before the foreseen final report date.'
    //             );
    //     }

    //     if (
    //         plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER &&
    //         submissionStartWeek &&
    //         submissionEndWeek &&
    //         submissionEndWeek < submissionStartWeek
    //     ) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push(
    //                 'Please check that the submission start date is before the submission end.'
    //             );
    //     }

    //     if (
    //         plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER &&
    //         closingDate.length > 0 &&
    //         submissionStartWeek &&
    //         submissionStartWeek < moment(closingDate).week()
    //     ) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push('Please check that closing date is before the submission start date.');
    //     }

    //     if (
    //         plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER &&
    //         firstInterimDate.length > 0 &&
    //         submissionStartWeek &&
    //         submissionStartWeek > moment(firstInterimDate).week()
    //     ) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push(
    //                 'Please check that submission start date is before the foreseen first interim date.'
    //             );
    //     }

    //     if (
    //         plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER &&
    //         foreseenDate.length > 0 &&
    //         submissionStartWeek &&
    //         submissionStartWeek > moment(foreseenDate).week()
    //     ) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push(
    //                 'Please check that submission start date is before the foreseen final report date.'
    //             );
    //     }

    //     if (
    //         plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER &&
    //         closingDate.length > 0 &&
    //         submissionEndWeek &&
    //         submissionEndWeek < moment(closingDate).week()
    //     ) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push('Please check that closing date is before the submission end date.');
    //     }

    //     if (
    //         plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER &&
    //         firstInterimDate.length > 0 &&
    //         submissionEndWeek &&
    //         submissionEndWeek > moment(firstInterimDate).week()
    //     ) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push(
    //                 'Please check that submission end date is before the foreseen first interim date.'
    //             );
    //     }

    //     if (
    //         plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER &&
    //         foreseenDate.length > 0 &&
    //         submissionEndWeek &&
    //         submissionEndWeek > moment(foreseenDate).week()
    //     ) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push(
    //                 'Please check that submission end date is before the foreseen final report date.'
    //             );
    //     }

    //     if (growingCycles > 1 && firstInterimDate.length === 0) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push(
    //                 'When the examination is foreseen more than 1 year, please enter a foreseen date for the interim report.'
    //             );
    //     }

    //     if (growingCycles > 1 && diffForeseenDateFirstInterimDate > 0) {
    //         errorMessages.errorLogic &&
    //             errorMessages.errorLogic.push(
    //                 'The foreseen interim report date should be before the foreseen final report date.'
    //             );
    //     }

    //     return errorMessages;
    // };

    // const saveProposalAsDraft = () => {
    //     setLoading(true);
    //     const entrustmentDataForSave = prepareEntrustmentForSave();
    //     apiEntrustmentSaveAsDraft(Object.assign({}, entrustmentDataForSave))
    //         .then(() => setModalScreen(modalScreenType.SAVE_PROPOSAL_DRAFT_INFO))
    //         .catch(error => {
    //             setErrorMessageModal(`error saving proposal as draft: ${error}`);
    //             setModalScreen(modalScreenType.ERROR);
    //         })
    //         .finally(() => setLoading(false));
    // };

    // const saveProposalAsDraftRoutine = () => {
    //     const errorMessagesObj = getErrorMessagesForDraft();
    //     let errorMessage = getConcatErrorMessage(errorMessagesObj);

    //     if (errorMessage.length > 0) {
    //         setErrorMessage(errorMessage);
    //         setHighlightErrors(true);
    //     }

    //     if (errorMessage.length === 0) {
    //         saveProposalAsDraft();
    //     }
    // };

    const getErrorMessagesforSubmit = () => {
        setHighlightErrors(false);
        const diffSubmissionStartEndDate = moment(submissionStartDate, 'DD/MM/YYYY').diff(
            moment(submissionEndDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffSubmissionStartFirstInterimDate = moment(submissionStartDate, 'DD/MM/YYYY').diff(
            moment(firstInterimDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffSubmissionStartForeseenDate = moment(submissionStartDate, 'DD/MM/YYYY').diff(
            moment(foreseenDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffSubmissionEndFirstInterimDate = moment(submissionEndDate, 'DD/MM/YYYY').diff(
            moment(firstInterimDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffSubmissionEndForeseenDate = moment(submissionEndDate, 'DD/MM/YYYY').diff(
            moment(foreseenDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffClosingDateSubmissionStartDate = moment(closingDate, 'DD/MM/YYYY').diff(
            moment(submissionStartDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffClosingDateSubmissionEndDate = moment(closingDate, 'DD/MM/YYYY').diff(
            moment(submissionEndDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffClosingDateFirstInterimDate = moment(closingDate, 'DD/MM/YYYY').diff(
            moment(firstInterimDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffClosingDateForeseenDate = moment(closingDate, 'DD/MM/YYYY').diff(
            moment(foreseenDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffForeseenDateFirstInterimDate = moment(firstInterimDate, 'DD/MM/YYYY').diff(
            moment(foreseenDate, 'DD/MM/YYYY'),
            'days'
        );
        const errorMessages: ErrorMessages = {
            errorInput: [],
            errorSelect: [],
            errorLogic: [],
        };
        if (speciesName.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a species name');
        }
        if (Number(growingCycles) === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a number of growing cycles');
        }
        if (feeGroupId === 0) {
            errorMessages.errorSelect && errorMessages.errorSelect.push('a fee group');
        }
        if (testingSiteId === 0) {
            errorMessages.errorSelect && errorMessages.errorSelect.push('a testing site');
        }
        if (submissionPlaceId === 0) {
            errorMessages.errorSelect && errorMessages.errorSelect.push('a plant material submission site');
        }
        if (closingDate.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a closing date');
        }
        if (
            plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER &&
            Number(submissionEndWeek) === 0
        ) {
            errorMessages.errorInput && errorMessages.errorInput.push('a submission end week');
        }
        if (plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE) {
            if (submissionEndDate.length === 0) {
                errorMessages.errorInput && errorMessages.errorInput.push('a submission end date');
            } else if (submissionStartDate.length > 0 && diffSubmissionStartEndDate > 0) {
                errorMessages.errorLogic &&
                    errorMessages.errorLogic.push(
                        'Please check that the submission start date is before the submission end.'
                    );
            }
        }
        if (
            plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
            submissionStartDate.length > 0 &&
            firstInterimDate.length > 0 &&
            diffSubmissionStartFirstInterimDate > 0
        ) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'Please check that submission start date is before the foreseen first interim date.'
                );
        }
        if (
            plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
            submissionStartDate.length > 0 &&
            foreseenDate.length > 0 &&
            diffSubmissionStartForeseenDate > 0
        ) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'Please check that submission start date is before the foreseen final report date.'
                );
        }
        if (
            plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
            submissionEndDate.length > 0 &&
            firstInterimDate.length > 0 &&
            diffSubmissionEndFirstInterimDate > 0
        ) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'Please check that submission end date is before the foreseen first interim date.'
                );
        }
        if (
            plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
            submissionEndDate.length > 0 &&
            foreseenDate.length > 0 &&
            diffSubmissionEndForeseenDate > 0
        ) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'Please check that submission end date is before the foreseen final report date.'
                );
        }
        if (
            plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
            closingDate.length > 0 &&
            submissionStartDate.length > 0 &&
            diffClosingDateSubmissionStartDate > 0
        ) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push('Please check that closing date is before the submission start date.');
        }
        if (
            plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
            closingDate.length > 0 &&
            submissionEndDate.length > 0 &&
            diffClosingDateSubmissionEndDate > 0
        ) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push('Please check that closing date is before the submission end date.');
        }
        if (
            plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
            closingDate.length > 0 &&
            firstInterimDate.length > 0 &&
            diffClosingDateFirstInterimDate > 0
        ) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'Please check that closing date is before the foreseen first interim date.'
                );
        }
        if (
            plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE &&
            closingDate.length > 0 &&
            foreseenDate.length > 0 &&
            diffClosingDateForeseenDate > 0
        ) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'Please check that closing date is before the foreseen final report date.'
                );
        }
        if (
            plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER &&
            submissionStartWeek &&
            submissionEndWeek &&
            Number(submissionEndWeek) < Number(submissionStartWeek)
        ) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'Please check that the submission start date is before the submission end.'
                );
        }
        // if (
        //     plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER &&
        //     closingDate.length > 0 &&
        //     submissionStartWeek &&
        //     Number(submissionStartWeek) < moment(closingDate).week()
        // ) {
        //     errorMessages.errorLogic &&
        //         errorMessages.errorLogic.push('Please check that closing date is before the submission start date.');
        // }

        // if (
        //     plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER &&
        //     firstInterimDate.length > 0 &&
        //     submissionStartWeek &&
        //     Number(submissionStartWeek) > moment(firstInterimDate).week()
        // ) {
        //     errorMessages.errorLogic &&
        //         errorMessages.errorLogic.push(
        //             'Please check that submission start date is before the foreseen first interim date.'
        //         );
        // }

        // if (
        //     plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER &&
        //     foreseenDate.length > 0 &&
        //     submissionStartWeek &&
        //     Number(submissionStartWeek) > moment(foreseenDate).week()
        // ) {
        //     errorMessages.errorLogic &&
        //         errorMessages.errorLogic.push(
        //             'Please check that submission start date is before the foreseen final report date.'
        //         );
        // }

        // if (
        //     plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER &&
        //     closingDate.length > 0 &&
        //     submissionEndWeek &&
        //     Number(submissionEndWeek) < moment(closingDate).week()
        // ) {
        //     errorMessages.errorLogic &&
        //         errorMessages.errorLogic.push('Please check that closing date is before the submission start date.');
        // }

        // if (
        //     plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER &&
        //     firstInterimDate.length > 0 &&
        //     submissionEndWeek &&
        //     Number(submissionEndWeek) > moment(firstInterimDate).week()
        // ) {
        //     errorMessages.errorLogic &&
        //         errorMessages.errorLogic.push(
        //             'Please check that submission end date is before the foreseen first interim date.'
        //         );
        // }

        // if (
        //     plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER &&
        //     foreseenDate.length > 0 &&
        //     submissionEndWeek &&
        //     Number(submissionEndWeek) > moment(foreseenDate).week()
        // ) {
        //     errorMessages.errorLogic &&
        //         errorMessages.errorLogic.push(
        //             'Please check that submission end date is before the foreseen final report date.'
        //         );
        // }
        if (foreseenDate.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a foreseen date for the final report');
        }
        if (Number(growingCycles) > 1 && firstInterimDate.length === 0) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'When the examination is foreseen more than 1 year, please enter a foreseen date for the interim report.'
                );
        }
        if (Number(growingCycles) > 1 && diffForeseenDateFirstInterimDate > 0) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'The foreseen interim report date should be before the foreseen final report date.'
                );
        }
        if (seedPlantQuantityQuality.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a seed/plant quantity and quality');
        }
        return errorMessages;
    };

    const submitProposalToCPVORoutine = () => {
        const errorMessagesObj = getErrorMessagesforSubmit();
        let errorMessage = getConcatErrorMessage(errorMessagesObj);
        if (errorMessage.length > 0) {
            setErrorMessage(errorMessage);
            setHighlightErrors(true);
        }
        if (errorMessage.length === 0) {
            setModalScreen(modalScreenType.SUBMIT_PROPOSAL_CONFIRM);
        }
    };

    const submitToTheCPVO = () => {
        const entrustmentDataForSave = prepareEntrustmentForSave();
        props.submitToTheCPVO && props.submitToTheCPVO(entrustmentDataForSave);
    };

    return (
        <>
            {modalScreen === modalScreenType.DEFAULT_REQUIREMENTS_CUSTOM && (
                <ModalDefaultRequirements
                    plantRequirements={plantRequirements}
                    seedPlantQuantityQualityIds={seedPlantQuantityQualityIds}
                    speciesExamId={props.speciesExamId}
                    close={closeDefaultRequirementsModal}
                    triggerCloseAfterInit={modalDefaultRequirementsTriggerCloseAfterInit}
                    setTriggerCloseAfterInit={setModalDefaultRequirementsTriggerCloseAfterInit}
                />
            )}
            <ModalCustomVersion2
                loading={loading || modalDefaultRequirementsTriggerCloseAfterInit}
                close={comebackToSearch}
                header={getModalTitleText()}
                body={
                    <div>
                        {getContentTitle()}
                        <div style={{clear: 'both'}}>{}</div>
                        <Empty height={25} />
                        <Empty height={25} />
                        <div style={{clear: 'both'}} />
                        <TextInput
                            value={examinationOfficeName}
                            double={true}
                            outsideLabel={'Contract (EO name)'}
                            outsideLabelWidth={210}
                            disabled={true}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        {editMode !== entrustmentEditMode.NEW && speciesName.length > 0 ? (
                            <TextInput
                                value={speciesName}
                                double={true}
                                outsideLabel={getMandatoryAsterisk('Species Name')}
                                outsideLabelWidth={210}
                                disabled={true}
                            />
                        ) : (
                            <TextLabelInput
                                placeholder={`limited to species for which CPVO has already received applications`}
                                focus={true}
                                double={true}
                                onSelectionChange={onSpeciesSelectionChange}
                                onChange={onSpeciesNameChange}
                                value={speciesName}
                                selectedElements={selectedSpeciesNames}
                                delay={300}
                                outsideLabel={getMandatoryAsterisk('Species Name')}
                                outsideLabelWidth={210}
                                listByIdName={speciesNamesListByIdName}
                                nameHash={speciesNamesHash}
                                noInfo={true}
                                disabled={undefined}
                                uniqueValue={true}
                                isOnModal={true}
                            />
                        )}
                        <div style={{clear: 'both'}} />
                        <TextInput
                            placeholder={
                                editMode !== entrustmentEditMode.NEW ||
                                `limitation of the entrustment scope, i.e. ornamental sunflower, winter type...`
                            }
                            value={entrustmentType}
                            onChange={onEntrustmentTypeChange}
                            double={true}
                            outsideLabel={'Entrustment type, if any'}
                            outsideLabelWidth={210}
                            disabled={editMode !== entrustmentEditMode.NEW}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        <DateInput
                            changeDateFrom={onExaminationFulfillsDateChange}
                            inputValueFrom={examinationFulfillsDate}
                            outsideLabel={'Date as from which my examination fulfills the CPVO quality requirements'}
                            outsideLabelWidth={210}
                            simple={true}
                            disabled={editMode === entrustmentEditMode.EDIT || editMode === entrustmentEditMode.VIEW}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        <Empty height={25} />
                        <Empty height={25} />
                        <div style={{clear: 'both'}}>{}</div>
                        {editMode === entrustmentEditMode.VIEW ? (
                            <TextInput
                                value={cultivationType}
                                double={true}
                                outsideLabel={`Cultivation Type`}
                                outsideLabelWidth={210}
                                disabled={true}
                            />
                        ) : (
                            <TextLabelInput
                                double={true}
                                onSelectionChange={onCultivationTypeSelectionChange}
                                onChange={onCultivationTypeChange}
                                value={cultivationType}
                                selectedElements={selectedCultivationTypes}
                                delay={300}
                                outsideLabel={`Cultivation Type`}
                                outsideLabelWidth={210}
                                listByIdName={cultivationTypesListByIdName}
                                nameHash={cultivationTypesNameHash}
                                placeholder={`Precision on the propagation method, growing conditions... if any`}
                                noInfo={true}
                                isOnModal={true}
                            />
                        )}
                        <div style={{clear: 'both'}}>{}</div>
                        {editMode !== entrustmentEditMode.NEW && (
                            <small>
                                <div
                                    style={{display: 'inline-block'}}
                                >{`(If you would like to create an additional cultivation type for this species, please tick the relevant box below)`}</div>
                            </small>
                        )}
                        <div style={{clear: 'both'}}>{}</div>
                        <SelectInput
                            value={feeGroupId}
                            onChange={onFeeGroupChange}
                            double={true}
                            list={feeGroups}
                            outsideLabel={getMandatoryAsterisk('Fee Group')}
                            outsideLabelWidth={210}
                            notAll={true}
                            disabled={editMode === entrustmentEditMode.EDIT || editMode === entrustmentEditMode.VIEW}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        <TextInput
                            value={growingCycles}
                            onChange={onGrowingCyclesChange}
                            double={true}
                            outsideLabel={getMandatoryAsterisk('Growing periods')}
                            outsideLabelWidth={210}
                            type={'number'}
                            disabled={editMode === entrustmentEditMode.VIEW}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        <SelectInput
                            value={submissionPlaceId}
                            onChange={onSubmissionPlaceChange}
                            double={true}
                            list={submissionPlaces}
                            outsideLabel={getMandatoryAsterisk('Submission Place')}
                            outsideLabelWidth={210}
                            notAll={true}
                            disabled={editMode === entrustmentEditMode.VIEW}
                        />
                        <div style={{clear: 'both'}} />
                        <SelectInput
                            value={testingSiteId}
                            onChange={onTestingSiteChange}
                            double={true}
                            list={testingSites}
                            outsideLabel={getMandatoryAsterisk('Testing Site')}
                            outsideLabelWidth={210}
                            notAll={true}
                            disabled={editMode === entrustmentEditMode.VIEW}
                        />
                        <div style={{clear: 'both'}} />
                        <DateInput
                            changeDateFrom={onClosingDateChange}
                            inputValueFrom={closingDate}
                            outsideLabel={getMandatoryAsterisk('Closing Date')}
                            outsideLabelWidth={210}
                            simple={true}
                            disabled={editMode === entrustmentEditMode.VIEW}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        <div>
                            <Label width={210} double={undefined}>{`Plant Material Submission`}</Label>
                            <RadioButton
                                radioButtonList={[
                                    {
                                        description: `Week Number`,
                                        value: plantMaterialSubmissionType.WEEK_NUMBER,
                                    },
                                    {
                                        description: `Calendar Date`,
                                        value: plantMaterialSubmissionType.CALENDAR_DATE,
                                    },
                                ]}
                                groupName={'plantMaterialSubmissionType'}
                                clickEvent={onPlantMaterialSubmissionChange}
                                value={plantMaterialSubmissionTypeState}
                                disabled={editMode === entrustmentEditMode.VIEW}
                            />
                            {plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER && (
                                <TextInput
                                    label={'from'}
                                    value={submissionStartWeek}
                                    onChange={onSubmissionStartWeekChange}
                                    type={'number'}
                                    width={90}
                                    disabled={editMode === entrustmentEditMode.VIEW}
                                />
                            )}
                            {plantMaterialSubmissionTypeState === plantMaterialSubmissionType.WEEK_NUMBER && (
                                <TextInput
                                    label={getMandatoryAsterisk('to')}
                                    value={submissionEndWeek}
                                    onChange={onSubmissionEndWeekChange}
                                    type={'number'}
                                    width={90}
                                    disabled={editMode === entrustmentEditMode.VIEW}
                                />
                            )}
                            {plantMaterialSubmissionTypeState === plantMaterialSubmissionType.CALENDAR_DATE && (
                                <div style={{display: 'table', borderSpacing: 10}}>
                                    <div
                                        style={{
                                            display: 'table-cell',
                                            width: 90,
                                            borderBottomStyle: 'solid',
                                            borderBottomWidth: 1,
                                            borderColor: '#7db13a',
                                            color: '#555',
                                            backgroundColor: editMode === entrustmentEditMode.VIEW ? '#aaa' : '#e8e8e8',
                                        }}
                                    >
                                        <CustomDatePicker
                                            disabled={editMode === entrustmentEditMode.VIEW}
                                            placeholder={'from'}
                                            change={onSubmissionStartDateChange}
                                            inputValue={submissionStartDate}
                                            width={120}
                                            oldBackOfficeStyles={undefined}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'table-cell',
                                            width: 90,
                                            borderBottomStyle: 'solid',
                                            borderBottomWidth: 1,
                                            borderColor: '#7db13a',
                                            color: '#555',
                                            backgroundColor: editMode === entrustmentEditMode.VIEW ? '#aaa' : '#e8e8e8',
                                        }}
                                    >
                                        <CustomDatePicker
                                            style={{backgroundColor: '#e8e8e8'}}
                                            disabled={editMode === entrustmentEditMode.VIEW}
                                            placeholder={'to*'}
                                            change={onSubmissionEndDateChange}
                                            inputValue={submissionEndDate}
                                            width={120}
                                            oldBackOfficeStyles={undefined}
                                        />
                                    </div>
                                    {submissionEndDate && <div style={{color: 'red'}}>*</div>}
                                </div>
                            )}
                        </div>
                        <div style={{clear: 'both'}}>{}</div>
                        {Number(growingCycles) > 1 && (
                            <DateInput
                                changeDateFrom={onFirstInterimDateChange}
                                inputValueFrom={firstInterimDate}
                                outsideLabel={getMandatoryAsterisk('First Interim Report Date')}
                                outsideLabelWidth={210}
                                simple={true}
                                disabled={editMode === entrustmentEditMode.VIEW}
                            />
                        )}
                        <div style={{clear: 'both'}}>{}</div>
                        <DateInput
                            changeDateFrom={onForeseenDateChange}
                            inputValueFrom={foreseenDate}
                            outsideLabel={getMandatoryAsterisk('Foreseen Final Report Date')}
                            outsideLabelWidth={210}
                            simple={true}
                            disabled={editMode === entrustmentEditMode.VIEW}
                        />
                        <div style={{clear: 'both'}} />
                        <TextAreaInput
                            outsideLabel={getMandatoryAsterisk(`Seed / Plant Quantity and Quality`)}
                            outsideLabelWidth={210}
                            value={seedPlantQuantityQuality}
                            double={true}
                            rows={6}
                            onClick={editMode === entrustmentEditMode.VIEW ? undefined : getPlantRequirementsData}
                            disabled={editMode === entrustmentEditMode.VIEW}
                        />
                        {editMode !== entrustmentEditMode.VIEW && (
                            <span
                                title={'Edit answer'}
                                className={styles.editButton}
                                onClick={getPlantRequirementsData}
                            >
                                <FontAwesomeIcon icon={faEdit as any} color={'green'} />
                            </span>
                        )}
                        <div style={{clear: 'both'}}>{}</div>
                        {editMode !== entrustmentEditMode.NEW && (
                            <TextAreaInput
                                outsideLabel={`Comment to be transmitted to CPVO (entrustment type, fee group...)`}
                                outsideLabelWidth={210}
                                value={comment}
                                onChange={onCommentChange}
                                double={true}
                                rows={6}
                                disabled={editMode === entrustmentEditMode.VIEW}
                            />
                        )}
                        <div style={{clear: 'both'}}>{}</div>
                        <Empty height={5} double={undefined} oneLine={undefined} width={undefined} />
                        <div style={{clear: 'both'}}>{}</div>
                        {editMode !== entrustmentEditMode.NEW && (
                            <Checkbox
                                clickAction={onCheckDeleteCultivationType}
                                label={`I would like to delete this cultivation type`}
                                value={deleteCultivationType}
                                double={true}
                                disabled={editMode === entrustmentEditMode.VIEW}
                            />
                        )}
                        <div style={{clear: 'both'}}>{}</div>
                        {editMode !== entrustmentEditMode.NEW && (
                            <Checkbox
                                clickAction={onCheckAddCultivationType}
                                label={`I would like to add another cultivation type`}
                                value={addCultivationType}
                                double={true}
                                disabled={editMode === entrustmentEditMode.VIEW ? true : deleteCultivationType === true}
                            />
                        )}
                        <div style={{clear: 'both'}}>{}</div>
                        {editMode !== entrustmentEditMode.NEW && addCultivationType === true ? (
                            <TextAreaInput
                                outsideLabel={`Please specify`}
                                outsideLabelWidth={210}
                                value={specifyCultivationType}
                                onChange={onSpecifyCultivationTypeChange}
                                double={true}
                                rows={6}
                                disabled={editMode === entrustmentEditMode.VIEW}
                            />
                        ) : null}
                        <div style={{clear: 'both'}} />
                        <div style={{fontStyle: 'italic', marginTop: 20}}>
                            {`Note: Mandatory fields are marked with an asterisk (*)`}
                        </div>
                        <div style={{clear: 'both'}} />
                    </div>
                }
                footer={
                    <>
                        {highlightErrors ? (
                            <Error>
                                <FontAwesomeIcon icon={faExclamationCircle as any} color={'white'} />
                                {` ${errorMessage}`}
                            </Error>
                        ) : null}
                        <div style={{clear: 'both'}}>{}</div>
                        {/* {editMode !== entrustmentEditMode.VIEW && (
                                <FormFooterButton
                                    color={buttonColor.orange}
                                    clickAction={saveProposalAsDraftRoutine}
                                    icon={faSave}
                                    width={undefined}
                                    height={undefined}
                                    disabled={undefined}
                                    float={'left'}
                                    extraStyles={undefined}
                                >
                                    {`Save your proposal (draft)`}
                                </FormFooterButton>
                            )} */}
                        {editMode !== entrustmentEditMode.VIEW && (
                            <Button clickAction={submitProposalToCPVORoutine}>
                                {`Submit to the CPVO ${
                                    props.modifySeveralPlantRequirements
                                        ? '(To be applied to all selected species)'
                                        : ''
                                }`}
                            </Button>
                        )}
                    </>
                }
            >
                {modalScreen === modalScreenType.ERROR ? (
                    <ModalErrorVersion2 title={'Error'} message={errorMessageModal} close={closeErrorModal} />
                ) : null}
                {/* {modalScreen === modalScreenType.SAVE_PROPOSAL_DRAFT_INFO && (
                    <ModalAlertVersion2
                        title={`Save your proposal (draft)`}
                        message={`Your proposal has been saved as draft. It has not yet been submitted to CPVO.`}
                        close={closeSaveProposalInfoModal}
                    />
                )} */}
                {modalScreen === modalScreenType.SUBMIT_PROPOSAL_CONFIRM ? (
                    <ModalConfirmVersion2
                        title={`Submit to the CPVO`}
                        message={'Are you sure you want to submit your proposal to the CPVO?'}
                        buttonName={'Yes'}
                        close={closeSubmitProposalToCPVO}
                        action={submitToTheCPVO}
                    />
                ) : null}
            </ModalCustomVersion2>
        </>
    );
};

export default injectIntl(withRouter(ModalEntrustment));
