import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import Date1 from './screenshots/Date_1.PNG';
import Date2 from './screenshots/Date_2.PNG';
import Date3 from './screenshots/Date_3.PNG';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeDateHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>Displays an input like the following: </p>
                    <img src={Date1} alt="Date 1" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>
                        opens up a date picker when then user clicks on the input
                    </p>
                    <img src={Date2} alt="Date 2" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>the input value is updated when the user selects a date</p>
                    <img src={Date3} alt="Date 3" className={styles.modalHelpImage} />
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeDateHelp;
