import React from 'react';
import {Editor} from '@tinymce/tinymce-react';
import styles from './ModalLabelVersion2.module.scss';
import ModalCustomVersion2 from './ModalCustomVersion2';
import {Button, SelectInput} from '../componentsFormV2';
import {faLightbulb} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {apiBackOfficeLabels, apiUpdateLabel, ILabel, ILabelUpdate} from './ModalLabelVersion2Service';
import {getContentWithCorrectImagePath} from '~utils/index';
import ModalConfirmVersion2 from './ModalConfirmVersion2';
import {htmlToText} from '../utils';
import {Col, Grid, LoadingBar} from '../componentsLayout';

const labelType = [
    {id: '1', value: 'Questionnaire name'},
    {id: '2', value: 'Group question'},
    {id: '3', value: 'Question'},
    {id: '4', value: 'Response'},
    {id: '5', value: 'Remark'},
    {id: '6', value: 'Help'},
    {id: '7', value: 'Header'},
    {id: '8', value: 'Footer'},
    {id: '9', value: 'Upload Type'},
    {id: '10', value: 'Front Office'},
    {id: '11', value: 'Front Office Button'},
    {id: '12', value: 'Generic Table Value'},
];
const languageOptions = [
    {id: 'EN', value: 'English'},
    {id: 'FR', value: 'French'},
    {id: 'DE', value: 'German'},
    {id: 'NL', value: 'Dutch'},
    {id: 'ES', value: 'Spanish'},
];

interface IProps {
    close: any;
    intl: any;
    label?: any;
    showModalUsedIn?: any;
    setLabelId?: any;
}

interface IState {
    criteria: {languageId: string; typeId: any};
    translations: {[key: string]: ILabel};
    previousTranslations: {[key: string]: ILabel};
    loading: boolean;
    currentValue: string;
    updating: boolean;
    modalConfirm: boolean;
}

export default class ModalLabelVersion2 extends React.Component<IProps, IState> {
    tinyConfig = {
        height: 500,
        inline: false,
        plugins:
            'table hr anchor code paste searchreplace image preview fullscreen textcolor link table responsivefilemanager',
        toolbar1:
            'bold italic underline strikethrough | bullist numlist | alignleft aligncenter alignright alignjustify | outdent indent | link unlink | code preview fullscreen | image table',
        toolbar2: 'cut copy paste pastetext searchreplace | fontsizeselect fontselect forecolor backcolor | undo redo ',
        statusbar: false,
        menubar: false,
        image_advtab: true,
        image_dimensions: false,
        subfolder: 'images/html',
        language: 'en',
        table_default_attributes: {
            border: '0',
        },
        file_picker_types: 'image',
        file_browser_callback_types: 'image',
        image_prepend_url: 'IMAGES/HTML/', //'images/html/22',
        filemanager_crossdomain: true,
        external_filemanager_path: '/filemanager/',
        filemanager_title: 'Select a file',
        external_plugins: {
            filemanager: '/filemanager/plugin.min.js',
        },
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            criteria: {languageId: 'EN', typeId: null},
            translations: {},
            previousTranslations: {},
            loading: true,
            currentValue: '',
            updating: false,
            modalConfirm: false,
        };
    }

    componentDidMount() {
        const {labelId, typeId} = this.props.label || {};
        const translations: any = {};
        const previousTranslations: any = {};
        const emptyLanguageLabel = {labelId: 0, value: null, valueHTML: null};
        languageOptions.forEach(languageOption => {
            translations[languageOption.id] = Object.assign({}, emptyLanguageLabel);
            previousTranslations[languageOption.id] = Object.assign({}, emptyLanguageLabel);
        });
        if (labelId) {
            apiBackOfficeLabels(labelId)
                .then((response: any) => {
                    if (response.data && response.data.labels) {
                        response.data.labels.forEach((label: any) => {
                            translations[label.languageId] = Object.assign({}, label);
                            previousTranslations[label.languageId] = Object.assign({}, label);
                        });
                    }
                    this.setState({translations, previousTranslations});
                })
                .catch((error: any) => LOG([`${error}`]))
                .then(() =>
                    this.setState(prev => {
                        const criteria = prev.criteria;
                        criteria.typeId = typeId;
                        return {loading: false, criteria};
                    })
                );
        } else if (labelId === 0) {
            this.setState(prev => ({
                criteria: {...prev.criteria, typeId},
                translations,
                previousTranslations,
                loading: false,
            }));
        }
    }

    showModalLabelUsedIn = () =>
        this.props.showModalUsedIn &&
        this.props.label &&
        this.props.label.labelId &&
        !isNaN(this.props.label.labelId) &&
        this.props.showModalUsedIn(this.props.label.labelId);

    save = () => {
        const request = this.buildUpdateRequest();
        this.setState({updating: true}, () => {
            (Object.keys(request).length > 0
                ? apiUpdateLabel(encodeJSONRequest(request), this.state.criteria.typeId)
                : Promise.resolve()
            )
                .then((response: any) => {
                    if (response && response.data && this.props.setLabelId && request['EN']) {
                        const label = request['EN'];
                        if (response.data !== 'OK') {
                            label.labelId = response.data;
                        }
                        this.props.setLabelId(
                            label,
                            this.props.label && this.props.label.fieldIdKey,
                            this.props.label && this.props.label.fieldHTMLKey
                        );
                    }
                })
                .catch((error: any) => LOG([`Error: ${error}`]))
                .then(() => this.setState({updating: false}, () => this.close(true)));
        });
    };

    checkModificationsBeforeClosing = () => {
        const request = this.buildUpdateRequest();
        if (Object.keys(request).length > 0) {
            this.setState({modalConfirm: true});
        } else {
            this.close();
        }
    };

    buildUpdateRequest = () => {
        const request: ILabelUpdate = {};
        const {previousTranslations, translations} = this.state;
        Object.keys(previousTranslations || {}).forEach(languageKey => {
            const previousTranslation = previousTranslations[languageKey];
            const translation = translations[languageKey];
            if (
                previousTranslation.value !== translation.value ||
                previousTranslation.valueHTML !== translation.valueHTML
            ) {
                request[languageKey] = {
                    labelId: translation.labelId,
                    labelValue: translation.value,
                    labelValueHTML: translation.valueHTML,
                };
            }
        });
        return request;
    };

    close = (reload?: boolean) => this.props.close && this.props.close(reload);

    closeModalConfirm = () => this.setState({modalConfirm: false});

    updateCriteriaValue = (criteriaValue: any, callback?: any) => {
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                return {criteria};
            },
            () => {
                callback && callback();
            }
        );
    };

    onSelectedLanguageChange = ({target: {value: languageId}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({languageId});

    onEditorChangeMainLanguage = (contentHTML: string) =>
        this.setState(prev => {
            const translations = {...prev.translations};
            translations[prev.criteria.languageId] = {
                ...translations[prev.criteria.languageId],
                value: htmlToText(contentHTML),
                valueHTML: contentHTML,
            };
            return {translations};
        });

    render() {
        const {labelId} = this.props.label || {};
        const currentValue = getContentWithCorrectImagePath(
            (this.state.translations && (this.state.translations[this.state.criteria.languageId] || {}).valueHTML) || ''
        );
        return (
            <>
                <ModalCustomVersion2
                    close={this.checkModificationsBeforeClosing}
                    header={
                        <>
                            <h2>{`Label`}</h2>
                            {labelId ? (
                                <div className={styles.modalTitleId}>
                                    <div className={styles.modalTitleLabel}>{`(Label id: ${labelId})`}</div>
                                    <div className={styles.modalTitleIcon} onClick={this.showModalLabelUsedIn}>
                                        <FontAwesomeIcon icon={faLightbulb as any} color="green" />
                                    </div>
                                </div>
                            ) : null}
                        </>
                    }
                    body={
                        this.state.loading || !this.state.translations ? (
                            <LoadingBar />
                        ) : (
                            <>
                                <Grid mb={'lg'}>
                                    <Col md={6} lg={4}>
                                        <SelectInput
                                            label={`Label Type`}
                                            value={this.state.criteria.typeId}
                                            onChange={() => null}
                                            list={labelType}
                                            notAll={true}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={6} lg={4}>
                                        <SelectInput
                                            label={`Translation language`}
                                            value={this.state.criteria.languageId}
                                            onChange={this.onSelectedLanguageChange}
                                            list={languageOptions}
                                            notAll={true}
                                        />
                                    </Col>
                                </Grid>
                                <Editor
                                    id="textareaMainLanguage"
                                    value={currentValue}
                                    init={this.tinyConfig}
                                    onEditorChange={this.onEditorChangeMainLanguage}
                                />
                            </>
                        )
                    }
                    footer={
                        this.state.updating ? (
                            <LoadingBar />
                        ) : (
                            <>
                                <Button
                                    variation={'danger'}
                                    icon={'close'}
                                    clickAction={this.checkModificationsBeforeClosing}
                                >{`Close`}</Button>
                                <Button clickAction={this.save} icon={'tick'}>{`Save`}</Button>
                            </>
                        )
                    }
                    plus2={true}
                />
                {this.state.modalConfirm ? (
                    <ModalConfirmVersion2
                        title={`Label modified`}
                        message={`Are you sure you want to cancel your modifications?`}
                        buttonName={'Yes'}
                        action={this.close}
                        close={this.closeModalConfirm}
                    />
                ) : null}
            </>
        );
    }
}

export function getLabelTypeId(typeString: any) {
    return labelType.filter(i => i.value === typeString)[0].id;
}

function encodeJSONRequest(JSONRequest: any = {}) {
    const encodedJSONRequest: any = {...JSONRequest};
    Object.keys(JSONRequest).forEach(key => {
        encodedJSONRequest[key] = {
            ...JSONRequest[key],
            labelValue: encodeURIComponent(JSONRequest[key].labelValue),
            labelValueHTML: encodeURIComponent(JSONRequest[key].labelValueHTML),
        };
    });
    return encodedJSONRequest;
}
