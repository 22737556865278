import React, {useState} from 'react';
import {Button} from '../../../../../../componentsFormV2';
import Checkbox from '~components/Checkbox';
import DateInput from '~components/DateInput';
import Empty from '~components/Empty';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormFooter} from '../../../../../../componentsLayout';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import Label from '~components/Label';
import {ModalAlertVersion2} from '../../../../../../commonModals';
import ModalBasicSearchDetails from '../../../../CommonComponents/ModalFileSearchDetails/ModalFileSearchDetails';
import {SearchCasesAdvancedFiltersCriteriaData} from '../../SearchCasesPageAdvancedPVRCASELAW';
import TextLabelInput from '../../../../../../components/TextLabelInput';
import {faQuestionCircle, faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import {getUserRoles} from '../../../../../TLO/CommonFunctions/CommonFunctions';
import styles from './SearchCasesAdvancedFiltersCriteria.module.scss';

const modalScreenTypes = {
    INFO: 'INFO',
};

interface SearchCasesAdvancedFiltersCriteriaProps {
    criteria: SearchCasesAdvancedFiltersCriteriaData;
    //
    originsListByIdName: {ID: string; NAME: string}[] | null;
    originsNameHash: {[key: string]: string[]} | null;
    selectedOrigins: {[key: string]: string};
    languagesListByIdName: {ID: string; NAME: string}[] | null;
    languagesNameHash: {[key: string]: string[]} | null;
    selectedLanguages: {[key: string]: string};
    keywordsListByIdName: {ID: string; NAME: string}[] | null;
    keywordsNameHash: {[key: string]: string[]} | null;
    selectedKeywords: {[key: string]: string};
    casesListByIdName: {ID: string; NAME: string}[] | null;
    casesNameHash: {[key: string]: string[]} | null;
    selectedCases: {[key: string]: string};
    decidingsBodyListByIdName: {ID: string; NAME: string}[] | null;
    decidingsBodyNameHash: {[key: string]: string[]} | null;
    selectedDecidingsBody: {[key: string]: string};
    decisionsNumberListByIdName: {ID: string; NAME: string}[] | null;
    decisionsNumberNameHash: {[key: string]: string[]} | null;
    selectedDecisionNumbers: {[key: string]: string};
    partiesListByIdNames: {ID: string; NAME: string}[] | null;
    partiesHash: {[key: string]: string[]} | null;
    selectedParties: {[key: string]: string};
    filesListByIdNames: {ID: string; NAME: string}[] | null;
    filesNamesHash: {[key: string]: string[]} | null;
    //
    updateSelectedOrigins: (selectedOrigins: {[key: string]: string}) => void;
    updateSelectedLanguages: (selectedLanguages: {[key: string]: string}) => void;
    updateSelectedKeywords: (selectedKeywords: {[key: string]: string}) => void;
    updateSelectedCases: (selectedCases: {[key: string]: string}) => void;
    updateSelectedDecidingsBody: (selectedDecidingsBody: {[key: string]: string}) => void;
    updateSelectedDecisionNumbers: (selectedDecisionNumbers: {[key: string]: string}) => void;
    updateSelectedParties: (selectedParties: {[key: string]: string}) => void;
    //
    updateCriteriaValue: (
        // eslint-disable-next-line no-undef
        criteriaValue: Partial<SearchCasesAdvancedFiltersCriteriaData>,
        callback?: () => void,
        refresh?: boolean
    ) => void;
    resetCriteria: () => void;
    search: (shouldRefresh: boolean, urlLoad?: boolean) => void;
    resetFileSearch: () => void;
    searchInFiles: () => void;
}

export default function SearchCasesAdvancedFiltersCriteria(props: SearchCasesAdvancedFiltersCriteriaProps) {
    const [showBasicSearchDetailsModal, setShowBasicSearchDetailsModal] = useState(false);
    const [infoMessage, setInfoMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);

    const userRoles = getUserRoles();

    const onOriginSelectionChange = (selectedOrigin: {[key: string]: string}) => {
        props.updateCriteriaValue({originsId: Object.keys(selectedOrigin).join(), originName: ''}, () =>
            props.updateSelectedOrigins(selectedOrigin)
        );
    };

    const onOriginChange = (originName: string) => {
        props.updateCriteriaValue({originName});
    };

    const onLanguageSelectionChange = (selectedLanguage: {[key: string]: string}) => {
        props.updateCriteriaValue({languagesId: Object.keys(selectedLanguage).join(), languageName: ''}, () =>
            props.updateSelectedLanguages(selectedLanguage)
        );
    };

    const onLanguageChange = (languageName: string) => {
        props.updateCriteriaValue({languageName});
    };

    const onKeywordSelectionChange = (selectedKeyword: {[key: string]: string}) => {
        props.updateCriteriaValue({keywordsId: Object.keys(selectedKeyword).join(), keywordName: ''}, () =>
            props.updateSelectedKeywords(selectedKeyword)
        );
    };

    const onKeywordChange = (keywordName: string) => {
        props.updateCriteriaValue({keywordName});
    };

    const onDecisionDateFromChange = (decisionDateFrom: string) => {
        props.updateCriteriaValue({decisionDateFrom});
    };

    const onDecisionDateToChange = (decisionDateTo: string) => {
        props.updateCriteriaValue({decisionDateTo});
    };

    const onCasesSelectionChange = (selectedCases: {[key: string]: string}) => {
        props.updateCriteriaValue({caseId: Object.keys(selectedCases).join(','), caseName: ''}, () =>
            props.updateSelectedCases(selectedCases)
        );
    };

    const onCaseNameChange = (caseName: string) => {
        props.updateCriteriaValue({caseName});
    };

    const onCaseNameFilterChange = ({target: {value: caseNameFilter}}: React.ChangeEvent<HTMLSelectElement>) => {
        props.updateCriteriaValue({caseNameFilter});
    };

    const onDecidingBodySelectionChange = (selectedDecidings: {[key: string]: string}) => {
        props.updateCriteriaValue({decidingBodyId: Object.keys(selectedDecidings).join(','), decidingBody: ''}, () =>
            props.updateSelectedDecidingsBody(selectedDecidings)
        );
    };

    const onDecidingBodyChange = (decidingBody: string) => {
        props.updateCriteriaValue({decidingBody});
    };

    const onDecidingBodyFilterChange = ({
        target: {value: decidingBodyFilter},
    }: React.ChangeEvent<HTMLSelectElement>) => {
        props.updateCriteriaValue({decidingBodyFilter});
    };

    const onDecisionNumberSelectionChange = (selectedDecisions: {[key: string]: string}) => {
        props.updateCriteriaValue(
            {decisionNumberId: Object.keys(selectedDecisions).join(','), decisionNumber: ''},
            () => props.updateSelectedDecisionNumbers(selectedDecisions)
        );
    };

    const onDecisionNumberChange = (decisionNumber: string) => {
        props.updateCriteriaValue({decisionNumber});
    };

    const onDecisionNumberFilterChange = ({
        target: {value: decisionNumberFilter},
    }: React.ChangeEvent<HTMLSelectElement>) => {
        props.updateCriteriaValue({decisionNumberFilter});
    };

    const onpartiessSelectionChange = (selectedParties: {[key: string]: string}) => {
        props.updateCriteriaValue({partiesId: Object.keys(selectedParties).join(','), parties: ''}, () =>
            props.updateSelectedParties(selectedParties)
        );
    };

    const onpartiessChange = (parties: string) => {
        props.updateCriteriaValue({parties});
    };

    const onpartiessFilterChange = ({target: {value: partiesFilter}}: React.ChangeEvent<HTMLSelectElement>) => {
        props.updateCriteriaValue({partiesFilter});
    };

    const onFileNamesSelectionChange = () => {
        props.updateCriteriaValue({fileName: ''});
    };

    const onFileNamesChange = (fileName: string) => {
        props.updateCriteriaValue({fileName});
    };

    const onFileNameFilterChange = ({target: {value: fileNameFilter}}: React.ChangeEvent<HTMLSelectElement>) => {
        props.updateCriteriaValue({fileNameFilter});
    };

    const onDisplayOnlyDraftCasesCheckboxChange = () => {
        props.updateCriteriaValue({draft: !props.criteria.draft});
    };

    const onSearchInFilesClick = () => {
        if (!props.criteria.fileName || props.criteria.fileName.length === 0) {
            setInfoMessage(`Please type a search word and try again.`);
            setModalScreen(modalScreenTypes.INFO);
        } else {
            props.searchInFiles();
        }
    };

    const closeInfoModal = () => {
        setInfoMessage(null);
        setModalScreen(null);
    };

    return (
        <>
            <>
                {modalScreen === modalScreenTypes.INFO ? (
                    <ModalAlertVersion2 title={'Info'} message={infoMessage || ''} close={closeInfoModal} />
                ) : null}
                {showBasicSearchDetailsModal ? (
                    <ModalBasicSearchDetails close={() => setShowBasicSearchDetailsModal(false)} />
                ) : null}
                <div className={styles.filesSearchFieldContainer}>
                    <TextLabelInput
                        extraFilterLabelHash={{all: 'All', summary: 'Summary', decision: 'Decision'}}
                        filter={['all', 'summary', 'decision']}
                        placeholder={`please type a search word`}
                        onSelectionChange={onFileNamesSelectionChange}
                        onChange={onFileNamesChange}
                        value={props.criteria.fileName}
                        // selectedElements={props.selectedFiles}
                        currentFilter={props.criteria.fileNameFilter}
                        onFilterChange={onFileNameFilterChange}
                        double={true}
                        // onEnter={props.search}
                        delay={300}
                        outsideLabel={'Search in Files'}
                        outsideLabelWidth={184}
                        listByIdName={props.filesListByIdNames}
                        nameHash={props.filesNamesHash}
                        noInfo={true}
                        useAsMultipleSelection={false}
                        isOnModal={true}
                        listMaxHeight={370}
                        maxElementsInList={100}
                    />
                    <div className={styles.filesSearchButton}>
                        <span onClick={() => props.resetFileSearch()} style={{}}>
                            <a data-content={`Clear File Search`} title={`Clear File Search`}>
                                <FontAwesomeIcon icon={faTimes as IconDefinition} />
                            </a>
                        </span>
                    </div>
                    <div className={styles.filesSearchButton}>
                        <span onClick={onSearchInFilesClick} style={{}}>
                            <a data-content={`Search In Files`} title={`Search In Files`}>
                                <FontAwesomeIcon icon={faSearch as IconDefinition} />
                            </a>
                        </span>
                    </div>
                </div>
                <div style={{clear: 'both'}} />
                <div className={styles.labelAdvancedSearchDetails}>
                    <Label double={true}>
                        <a
                            onClick={() => setShowBasicSearchDetailsModal(true)}
                            title={`Click here to find out how the full text research is carried out`}
                        >
                            <FontAwesomeIcon icon={faQuestionCircle as IconDefinition} color="green" />
                        </a>
                        <span> {`Click here to find out how the full text research is carried out`}</span>
                    </Label>
                    <Empty height={5} width={85} />
                </div>
                <div style={{clear: 'both'}} />
                <hr />
                <div className={styles.spaceBetween10}></div>
                <div className={styles.mainFieldContainer}>
                    <TextLabelInput
                        double={true}
                        placeholder={'please use autocompletion'}
                        onSelectionChange={onOriginSelectionChange}
                        onChange={onOriginChange}
                        value={props.criteria.originName}
                        selectedElements={props.selectedOrigins}
                        delay={300}
                        outsideLabel={`Origin`}
                        outsideLabelWidth={184}
                        listByIdName={props.originsListByIdName}
                        nameHash={props.originsNameHash}
                        noInfo={true}
                        useAsMultipleSelection={true}
                        listMaxHeight={370}
                    />
                    <div style={{clear: 'both'}} />
                    <TextLabelInput
                        double={true}
                        placeholder={'please use autocompletion'}
                        onSelectionChange={onLanguageSelectionChange}
                        onChange={onLanguageChange}
                        value={props.criteria.languageName}
                        selectedElements={props.selectedLanguages}
                        delay={300}
                        outsideLabel={`Language of the Decision`}
                        outsideLabelWidth={184}
                        listByIdName={props.languagesListByIdName}
                        nameHash={props.languagesNameHash}
                        noInfo={true}
                        useAsMultipleSelection={true}
                        listMaxHeight={370}
                    />
                    <div style={{clear: 'both'}} />
                    <TextLabelInput
                        double={true}
                        placeholder={'please use autocompletion'}
                        onSelectionChange={onKeywordSelectionChange}
                        onChange={onKeywordChange}
                        value={props.criteria.keywordName}
                        selectedElements={props.selectedKeywords}
                        delay={300}
                        outsideLabel={`Keywords`}
                        outsideLabelWidth={184}
                        listByIdName={props.keywordsListByIdName}
                        nameHash={props.keywordsNameHash}
                        noInfo={false}
                        useAsMultipleSelection={true}
                        listMaxHeight={370}
                        popover={`We are doing our best to index all cases with keywords, but beware that including them in the search may over-restrict your search.`}
                    />
                    <div style={{clear: 'both'}} />
                    <DateInput
                        changeDateFrom={onDecisionDateFromChange}
                        inputValueFrom={props.criteria.decisionDateFrom}
                        changeDateTo={onDecisionDateToChange}
                        inputValueTo={props.criteria.decisionDateTo}
                        outsideLabel={'Decision Date'}
                        outsideLabelWidth={184}
                        double={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextLabelInput
                        filter={['starts', 'ends', 'contains', 'equals']}
                        placeholder={`please use autocompletion`}
                        currentFilter={props.criteria.caseNameFilter}
                        onFilterChange={onCaseNameFilterChange}
                        double={true}
                        onSelectionChange={onCasesSelectionChange}
                        onChange={onCaseNameChange}
                        onEnter={props.search}
                        value={props.criteria.caseName}
                        selectedElements={props.selectedCases}
                        delay={300}
                        outsideLabel={'Case Name'}
                        outsideLabelWidth={184}
                        listByIdName={props.casesListByIdName}
                        nameHash={props.casesNameHash}
                        noInfo={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextLabelInput
                        filter={['starts', 'ends', 'contains', 'equals']}
                        placeholder={`please use autocompletion`}
                        currentFilter={props.criteria.decidingBodyFilter}
                        onFilterChange={onDecidingBodyFilterChange}
                        double={true}
                        onSelectionChange={onDecidingBodySelectionChange}
                        onChange={onDecidingBodyChange}
                        onEnter={props.search}
                        value={props.criteria.decidingBody}
                        selectedElements={props.selectedDecidingsBody}
                        delay={300}
                        outsideLabel={'Deciding Body'}
                        outsideLabelWidth={184}
                        listByIdName={props.decidingsBodyListByIdName}
                        nameHash={props.decidingsBodyNameHash}
                        noInfo={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextLabelInput
                        filter={['starts', 'ends', 'contains', 'equals']}
                        placeholder={`please use autocompletion`}
                        currentFilter={props.criteria.decisionNumberFilter}
                        onFilterChange={onDecisionNumberFilterChange}
                        double={true}
                        onSelectionChange={onDecisionNumberSelectionChange}
                        onChange={onDecisionNumberChange}
                        onEnter={props.search}
                        value={props.criteria.decisionNumber}
                        selectedElements={props.selectedDecisionNumbers}
                        delay={300}
                        outsideLabel={'Decision Number'}
                        outsideLabelWidth={184}
                        listByIdName={props.decisionsNumberListByIdName}
                        nameHash={props.decisionsNumberNameHash}
                        noInfo={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextLabelInput
                        filter={['starts', 'ends', 'contains', 'equals']}
                        placeholder={`please use autocompletion`}
                        currentFilter={props.criteria.partiesFilter}
                        onFilterChange={onpartiessFilterChange}
                        double={true}
                        onSelectionChange={onpartiessSelectionChange}
                        onChange={onpartiessChange}
                        onEnter={props.search}
                        value={props.criteria.parties}
                        selectedElements={props.selectedParties}
                        delay={300}
                        outsideLabel={'Parties Names'}
                        outsideLabelWidth={184}
                        listByIdName={props.partiesListByIdNames}
                        nameHash={props.partiesHash}
                        noInfo={true}
                    />
                    <div style={{clear: 'both'}} />
                    {userRoles.indexOf('WLAW') !== -1 ? (
                        <Checkbox
                            clickAction={onDisplayOnlyDraftCasesCheckboxChange}
                            label={`display only draft cases`}
                            value={props.criteria.draft}
                            double={true}
                        />
                    ) : null}
                    <div style={{clear: 'both'}} />
                </div>
                <FormFooter>
                    <Button clickAction={() => props.resetCriteria()} variation={'secondary'}>{`Clear fields`}</Button>
                    <Button clickAction={() => props.search(false)}>{`Search`}</Button>
                </FormFooter>
                <div style={{clear: 'both'}} />
            </>
        </>
    );
}
