import React from 'react';
import CryptoJS from 'crypto-js';
import Checkbox from '~components/Checkbox';
import CustomPopoverRadio from '~components/CustomPopoverRadio';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Info from '~components/Info';
import InputLink from '~components/InputLink';
import LANGUAGE_OPTIONS from './data/LANGUAGE_OPTIONS.json';
import RadioButton from '~components/RadioButton';
import SelectInput from '~components/SelectInput';
import TextAreaInput from '~components/TextAreaInput';
import TextLabelInput from '~components/TextLabelInput';
import TextInput from '~components/TextInput';
import Title from '~components/Title';
import {apiCommonCheckApplicationNumbers, apiFileListLogs, apiLoadCountriesEU} from './RequestCertifiedCopiesService';
import {checkEmail, isRegisterIdValid, sanitize} from '~utils';
import {faFlag, faPlus} from '@fortawesome/free-solid-svg-icons';
import {injectIntl} from 'react-intl';
import styles from './RequestCertifiedCopies.module.scss';
import {withRouter} from 'react-router-dom';
import {MAX_UPLOAD_FILE_SIZE} from '../../utils/constants';
import {
    Error,
    Footer,
    FormFooter,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
} from '../../componentsLayout';
import {Button} from '../../componentsFormV2';
import {ModalAlertVersion2, ModalCPVOLogsVersion2} from '../../commonModals';
import {ModalApplicationList, ModalRequestorDetails, ModalSubmitRequest, ModalTitlesList} from './modals';
import Navigation from '../../componentsLayout/Navigation';
import {trackPageView} from '../../utils';

export const TYPE_OF_REQUEST_SELECT_OPTIONS = {
    CC: 'CC',
    EXTRACT: 'EXTRACT',
    MAINTENANCE: 'ML',
    TECL: 'TECL',
    OTHER: 'OTHER',
};

const REQUEST_PURPOSE_VALUE: any = {
    PBR: 1,
    LEGAL: 2,
};

const errorMessages: any = {
    UNKNOWN: `This is not a valid application number. The value must be in range 19950001 to ${new Date().getFullYear()}9999!`,
    FORBIDDEN: 'This application is not linked to your account',
    DUPLICATE: `Application number already in the list`,
    NOT_RECEIPT: 'The reception of your application has not been finalised yet.',
    ADD: 'Please include the application number using the button + or "Enter" keyboard button.',
};

const modalScreen = {
    APPLICATION_LIST: 'APPLICATION_LIST',
    TITLES_LIST: 'TITLES_LIST',
    REQUESTOR_DETAILS: 'REQUESTOR_DETAILS',
    SUBMIT_REQUEST: 'SUBMIT_REQUEST',
    ALERT: 'ALERT',
    MODAL_FILES: 'MODAL_FILES',
};

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    applicationNumber: string;
    applicationNumberError: any;
    applicationNumbersNotFound: any;
    checkboxValueApplicableFeesUrgent: boolean;
    documentList: any[];
    error: any;
    isModalAlertRequestPurposeCannotBeSelectedOpen: boolean;
    formError: any;
    loading: number;
    modalAlertMessage: string;
    modalAlertTitle: string;
    modalLogsFiles: any[];
    modalLogsFolder: string;
    modalScreen: any;
    optionsListCountriesEU: {id: string; value: string}[];
    radioButtonValueRequestPurpose: any;
    radioButtonValueTypeOfRequest: string;
    selectedApplicationNumbers: any;
    selectInputValueCountryOfRequestPurpose: string;
    selectInputValueLanguageOfRequest: string;
    textAreaValueAdditionalComments: string;
    textInputErrorApplicableFeesEmail: boolean;
    textInputErrorDispatchOfTheDocumentsEmail: boolean;
    textInputValueApplicableFeesEmail: string;
    textInputValueDispatchOfTheDocumentsEmail: string;
    textInputValueOtherTypeOfRequest: string;
    timestamp: number;
}

class RequestCertifiedCopies extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            applicationNumber: '',
            applicationNumberError: null,
            applicationNumbersNotFound: null,
            checkboxValueApplicableFeesUrgent: false,
            documentList: [],
            error: null,
            isModalAlertRequestPurposeCannotBeSelectedOpen: false,
            formError: null,
            loading: 0,
            modalAlertMessage: '',
            modalAlertTitle: '',
            modalLogsFiles: [],
            modalLogsFolder: '',
            modalScreen: null,
            optionsListCountriesEU: [{id: '', value: 'Please select a country'}],
            radioButtonValueRequestPurpose: '',
            radioButtonValueTypeOfRequest: '',
            selectedApplicationNumbers: {},
            selectInputValueCountryOfRequestPurpose: '',
            selectInputValueLanguageOfRequest: '',
            textAreaValueAdditionalComments: '',
            textInputErrorApplicableFeesEmail: false,
            textInputErrorDispatchOfTheDocumentsEmail: false,
            textInputValueApplicableFeesEmail: '',
            textInputValueDispatchOfTheDocumentsEmail: '',
            textInputValueOtherTypeOfRequest: '',
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'requestCertifiedCopies'});
        this.loadJSONs();
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (prevState.radioButtonValueTypeOfRequest !== this.state.radioButtonValueTypeOfRequest) {
            if (prevState.radioButtonValueTypeOfRequest === TYPE_OF_REQUEST_SELECT_OPTIONS.OTHER) {
                this.setState({textInputValueOtherTypeOfRequest: ''});
            }
            if (
                ![TYPE_OF_REQUEST_SELECT_OPTIONS.CC, TYPE_OF_REQUEST_SELECT_OPTIONS.MAINTENANCE].includes(
                    this.state.radioButtonValueTypeOfRequest
                )
            ) {
                this.setState({radioButtonValueRequestPurpose: '', selectInputValueCountryOfRequestPurpose: ''});
            }
        }
        if (prevState.radioButtonValueRequestPurpose !== this.state.radioButtonValueRequestPurpose) {
            if (this.state.radioButtonValueRequestPurpose === REQUEST_PURPOSE_VALUE.PBR) {
                this.setState({selectInputValueCountryOfRequestPurpose: ''});
            }
        }
    }

    loadJSONs = () => {
        apiLoadCountriesEU().then(JSONResponse => {
            const {data: JSONResponseDataCountries} = JSONResponse || {};
            this.setState(prevState => ({
                optionsListCountriesEU: [
                    ...prevState.optionsListCountriesEU,
                    ...JSONResponseDataCountries.map((country: any) => ({
                        id: country.COUNTRYID,
                        value: country.COUNTRYNAME,
                    })),
                ],
            }));
        });
    };

    checkFile = (file: any, event: any) => {
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType.toLowerCase();
        const filename = sanitize(file.name);
        this.setState({error: null}, () => {
            if (
                fileTypeLowercase === 'jpeg' ||
                fileTypeLowercase === 'png' ||
                fileTypeLowercase === 'jpg' ||
                fileTypeLowercase === 'pdf'
            ) {
                if (file.size > MAX_UPLOAD_FILE_SIZE) {
                    this.setState({
                        error: 'The maximum document size for uploads is 10 MB. Please select a proper file.',
                    });
                } else {
                    const a = new FileReader();
                    a.readAsBinaryString(file);
                    a.onloadend = () => {
                        const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(a.result));
                        this.setState(
                            prev => {
                                const documentList = prev.documentList.slice(0);
                                documentList.push({
                                    index,
                                    file,
                                    filename,
                                    size: file.size / 1024,
                                    extension: fileTypeLowercase,
                                    documentType: null,
                                });
                                return {documentList, timestamp: Date.now()};
                            },
                            () => (event.target.value = null)
                        );
                    };
                }
            } else {
                this.setState({error: 'type not valid'}, () => (event.target.value = null));
            }
        });
    };

    deleteFile = (index: any) =>
        this.setState(prev => ({
            documentList: prev.documentList.filter(i => i.index !== index),
            timestamp: Date.now(),
        }));

    getMandatoryAsterisk = (label: any) => (
        <>
            <div style={{display: 'inline-block', marginRight: 5}}>{label}</div>
            <div style={{display: 'inline-block', color: 'red'}}>{'*'}</div>
        </>
    );

    closeModal = () => this.setState({modalScreen: null});

    closeModalAlertRequestPurposeCannotBeSelected = () =>
        this.setState({isModalAlertRequestPurposeCannotBeSelectedOpen: false});

    showModalScreenApplicationList = () => this.setState({modalScreen: modalScreen.APPLICATION_LIST});

    showModalScreenTitlesList = () => this.setState({modalScreen: modalScreen.TITLES_LIST});

    showModalScreenRequestorDetails = () =>
        this.setState({
            modalScreen: modalScreen.REQUESTOR_DETAILS,
        });

    checkApplicationNumber = (applicationNumber: any) => {
        let applicationNumberValue = applicationNumber;
        if (!isNaN(applicationNumberValue)) {
            applicationNumberValue = applicationNumberValue.substring(0, 8);
            this.setState({
                applicationNumber: applicationNumberValue,
                applicationNumberError: isRegisterIdValid(applicationNumberValue)
                    ? applicationNumberValue.length === 8
                        ? 'ADD'
                        : null
                    : 'UNKNOWN',
            });
        }
    };

    closeModalApplicationNumbersNotFound = () =>
        this.setState({
            applicationNumbersNotFound: null,
        });

    onRadioButtonValueTypeOfRequestClick = (radioButtonValueTypeOfRequest: any) =>
        this.setState({radioButtonValueTypeOfRequest});

    onTextInputValueOtherTypeOfRequestChange = ({
        target: {value: textInputValueOtherTypeOfRequest},
    }: React.ChangeEvent<HTMLInputElement>) => this.setState({textInputValueOtherTypeOfRequest});

    onSelectInputValueLanguageOfRequestChange = ({
        target: {value: selectInputValueLanguageOfRequest},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({selectInputValueLanguageOfRequest});

    onSelectInputValueCountryOfRequestPurposeChange = ({
        target: {value: selectInputValueCountryOfRequestPurpose},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({selectInputValueCountryOfRequestPurpose});

    onRadioButtonValueRequestPurposeClick = (radioButtonValueRequestPurpose: number) =>
        this.setState({radioButtonValueRequestPurpose: radioButtonValueRequestPurpose});

    onApplicationNumbersSelectionChange = (selectedApplicationNumbers: any) =>
        this.setState(Object.assign({}, {selectedApplicationNumbers}));

    onApplicationNumberChange = (applicationNumber: string) => this.checkApplicationNumber(applicationNumber);

    onAddingApplicationNumber = () => {
        const {applicationNumber} = this.state;
        if (applicationNumber.length === 8 && isRegisterIdValid(applicationNumber)) {
            if (this.state.selectedApplicationNumbers[applicationNumber]) {
                this.setState({applicationNumberError: 'DUPLICATE'});
            } else {
                this.setState(
                    prevState => ({
                        loading: prevState.loading + 1,
                    }),
                    () => {
                        apiCommonCheckApplicationNumbers({applicationNumbers: [applicationNumber]})
                            .then((JSONResponse: any) => {
                                if (JSONResponse && JSONResponse.data && Array.isArray(JSONResponse.data)) {
                                    if (
                                        JSONResponse.data[0] &&
                                        JSONResponse.data[0].errors &&
                                        Array.isArray(JSONResponse.data[0].errors) &&
                                        JSONResponse.data[0].errors.includes('notFound')
                                    ) {
                                        this.setState(prevState => ({
                                            applicationNumber: '',
                                            applicationNumberError: `No corresponding application number: ${prevState.applicationNumber}. Please verify.`,
                                        }));
                                    } else {
                                        this.setState(prev => {
                                            const selectedApplicationNumbers = prev.selectedApplicationNumbers;
                                            selectedApplicationNumbers[prev.applicationNumber] = prev.applicationNumber;
                                            return {
                                                applicationNumber: '',
                                                applicationNumberError: null,
                                                selectedApplicationNumbers,
                                            };
                                        });
                                    }
                                }
                            })
                            .then(() => {
                                this.setState(prevState => ({
                                    loading: prevState.loading - 1,
                                }));
                            });
                    }
                );
            }
        }
    };

    onImportApplicationNumbersList = (list: string[]) => {
        this.setState(
            prevState => ({
                loading: prevState.loading + 1,
            }),
            () => {
                apiCommonCheckApplicationNumbers({applicationNumbers: [...list]})
                    .then(JSONResponse => {
                        if (JSONResponse && JSONResponse.data) {
                            let applicationNumbersNotFound = JSONResponse.data
                                .filter(el => el.errors.includes('notFound'))
                                .map(el => el.applicationNumber);
                            if (applicationNumbersNotFound.length) {
                                this.setState(prev => {
                                    const selectedApplicationNumbers = prev.selectedApplicationNumbers;
                                    list.forEach(
                                        applicationNumber =>
                                            !applicationNumbersNotFound.includes(applicationNumber) &&
                                            (selectedApplicationNumbers[applicationNumber] = applicationNumber)
                                    );
                                    return {
                                        selectedApplicationNumbers,
                                        applicationNumbersNotFound,
                                    };
                                });
                            } else {
                                this.setState(prev => {
                                    const selectedApplicationNumbers = prev.selectedApplicationNumbers;
                                    list.forEach(
                                        applicationNumber =>
                                            (selectedApplicationNumbers[applicationNumber] = applicationNumber)
                                    );
                                    return {
                                        selectedApplicationNumbers,
                                    };
                                });
                            }
                        }
                    })
                    .then(() => {
                        this.setState(prevState => ({
                            loading: prevState.loading - 1,
                        }));
                    });
            }
        );
    };

    onImportTitlesList = (list: any, titlesWithNoCorrespondingApplicationNumbers: string[]) => {
        this.setState(prev => {
            const selectedApplicationNumbers = prev.selectedApplicationNumbers;
            list.forEach(
                (applicationNumber: any) => (selectedApplicationNumbers[applicationNumber] = applicationNumber)
            );
            const stateUpdate: any = {};
            if (
                Array.isArray(titlesWithNoCorrespondingApplicationNumbers) &&
                titlesWithNoCorrespondingApplicationNumbers.length
            ) {
                let message =
                    titlesWithNoCorrespondingApplicationNumbers.length > 1
                        ? 'There are no corresponding application numbers found for the titles'
                        : 'There is no corresponding application number found for the title';
                stateUpdate.modalScreen = modalScreen.ALERT;
                stateUpdate.modalAlertTitle = 'No corresponding numbers';
                stateUpdate.modalAlertMessage = `${message}: ${titlesWithNoCorrespondingApplicationNumbers
                    .map(el => `"${el}"`)
                    .join(', ')}.`;
            }
            return {
                selectedApplicationNumbers,
                ...stateUpdate,
            };
        });
    };

    onTextInputValueApplicableFeesEmailChange = ({
        target: {value: textInputValueApplicableFeesEmail},
    }: React.ChangeEvent<HTMLInputElement>) =>
        this.setState(
            Object.assign(
                {},
                {textInputValueApplicableFeesEmail},
                {
                    textInputErrorApplicableFeesEmail: textInputValueApplicableFeesEmail
                        ? !checkEmail(textInputValueApplicableFeesEmail)
                        : false,
                }
            )
        );

    onCheckboxValueApplicableFeesUrgentClick = () =>
        this.setState(prevState => ({
            checkboxValueApplicableFeesUrgent: !prevState.checkboxValueApplicableFeesUrgent,
        }));

    onTextInputValueDispatchOfTheDocumentsEmailChange = ({
        target: {value: textInputValueDispatchOfTheDocumentsEmail},
    }: React.ChangeEvent<HTMLInputElement>) =>
        this.setState(
            Object.assign(
                {},
                {textInputValueDispatchOfTheDocumentsEmail},
                {
                    textInputErrorDispatchOfTheDocumentsEmail: textInputValueDispatchOfTheDocumentsEmail
                        ? !checkEmail(textInputValueDispatchOfTheDocumentsEmail)
                        : false,
                }
            )
        );

    onTextAreaValueAdditionalCommentsChange = ({
        target: {value: textAreaValueAdditionalComments},
    }: React.ChangeEvent<HTMLInputElement>) => this.setState({textAreaValueAdditionalComments});

    onButtonResetFormClick = () =>
        this.setState({
            applicationNumber: '',
            applicationNumberError: null,
            checkboxValueApplicableFeesUrgent: false,
            documentList: [],
            formError: '',
            radioButtonValueRequestPurpose: '',
            radioButtonValueTypeOfRequest: '',
            selectedApplicationNumbers: {},
            selectInputValueCountryOfRequestPurpose: '',
            selectInputValueLanguageOfRequest: '',
            textAreaValueAdditionalComments: '',
            textInputErrorApplicableFeesEmail: false,
            textInputErrorDispatchOfTheDocumentsEmail: false,
            textInputValueApplicableFeesEmail: '',
            textInputValueDispatchOfTheDocumentsEmail: '',
            textInputValueOtherTypeOfRequest: '',
        });

    onButtonContinueClick = () => {
        this.setState(
            {
                formError: '',
            },
            () => {
                if (
                    !this.state.radioButtonValueTypeOfRequest ||
                    (this.state.radioButtonValueTypeOfRequest === TYPE_OF_REQUEST_SELECT_OPTIONS.OTHER &&
                        !this.state.textInputValueOtherTypeOfRequest)
                ) {
                    return this.setState({
                        formError: 'Please select a type of request',
                    });
                }
                if (!this.state.selectInputValueLanguageOfRequest) {
                    return this.setState({
                        formError: 'Please select the language of the request',
                    });
                }
                if (
                    this.state.radioButtonValueRequestPurpose === REQUEST_PURPOSE_VALUE.LEGAL &&
                    !this.state.selectInputValueCountryOfRequestPurpose
                ) {
                    return this.setState({
                        formError: 'Please select the court country concerned',
                    });
                }
                if (!Object.keys(this.state.selectedApplicationNumbers || {}).length) {
                    return this.setState({
                        formError: 'Please select application numbers',
                    });
                }
                if (this.state.textInputErrorApplicableFeesEmail) {
                    return this.setState({
                        formError: 'Please provide a valid accountancy e-mail address',
                    });
                }
                if (this.state.textInputErrorDispatchOfTheDocumentsEmail) {
                    return this.setState({
                        formError: 'Please provide a valid additional e-mail address',
                    });
                }
                this.setState({modalScreen: modalScreen.SUBMIT_REQUEST});
            }
        );
    };

    onSelectType = (event: any, object: any) => {
        const documentType = event.target.value === 'NONE' ? null : event.target.value;
        this.setState(prev => ({
            documentList: prev.documentList
                .slice(0)
                .map(document =>
                    document.index === object.index ? Object.assign({}, document, {documentType}) : document
                ),
            timestamp: Date.now(),
        }));
    };

    onSubmitRequestSuccess = ({folder}: any) => {
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                modalScreen: null,
            }),
            () =>
                apiFileListLogs(folder)
                    .then((JSONResponse: any) => {
                        if (JSONResponse && JSONResponse.files) {
                            this.setState({
                                modalLogsFiles: JSONResponse.files,
                                modalLogsFolder: folder,
                                timestamp: Date.now(),
                                modalScreen: modalScreen.MODAL_FILES,
                            });
                            window.scroll({top: 0, behavior: 'smooth'});
                        }
                    })
                    .then(() =>
                        this.setState(
                            prev => ({
                                loading: prev.loading - 1,
                            }),
                            () => {
                                this.onButtonResetFormClick();
                            }
                        )
                    )
        );
    };

    onSubmitRequestError = () => {
        this.setState({
            modalScreen: modalScreen.ALERT,
            modalAlertTitle: 'Something went wrong',
            modalAlertMessage:
                'An error has occurred, and your request has not been processed. Sorry for the inconvenient, please try again later.',
        });
        window.scroll({top: 0, behavior: 'smooth'});
    };

    onRadioButtonRequestPurposeClick = (event: any) => {
        if (
            this.state.radioButtonValueTypeOfRequest !== TYPE_OF_REQUEST_SELECT_OPTIONS.CC &&
            this.state.radioButtonValueTypeOfRequest !== TYPE_OF_REQUEST_SELECT_OPTIONS.MAINTENANCE
        ) {
            event.preventDefault();
            this.setState({isModalAlertRequestPurposeCannotBeSelectedOpen: true});
        }
    };

    render() {
        return (
            <>
                {this.state.modalScreen === modalScreen.APPLICATION_LIST ? (
                    <ModalApplicationList importList={this.onImportApplicationNumbersList} close={this.closeModal} />
                ) : null}
                {this.state.modalScreen === modalScreen.TITLES_LIST ? (
                    <ModalTitlesList importList={this.onImportTitlesList} close={this.closeModal} />
                ) : null}
                {this.state.modalScreen === modalScreen.REQUESTOR_DETAILS ? (
                    <ModalRequestorDetails close={this.closeModal} />
                ) : null}
                {this.state.modalScreen === modalScreen.SUBMIT_REQUEST ? (
                    <ModalSubmitRequest
                        checkFile={this.checkFile}
                        deleteFile={this.deleteFile}
                        error={this.state.error}
                        documentList={this.state.documentList}
                        timestamp={this.state.timestamp}
                        selectedApplications={this.state.selectedApplicationNumbers}
                        onSelectType={this.onSelectType}
                        intl={this.props.intl}
                        close={this.closeModal}
                        radioButtonValueTypeOfRequest={this.state.radioButtonValueTypeOfRequest}
                        textInputValueOtherTypeOfRequest={this.state.textInputValueOtherTypeOfRequest}
                        selectInputValueLanguageOfRequest={this.state.selectInputValueLanguageOfRequest}
                        selectInputValueCountryOfRequestPurpose={this.state.selectInputValueCountryOfRequestPurpose}
                        radioButtonValueRequestPurpose={this.state.radioButtonValueRequestPurpose}
                        textInputValueApplicableFeesEmail={this.state.textInputValueApplicableFeesEmail}
                        isUrgent={this.state.checkboxValueApplicableFeesUrgent}
                        textInputValueDispatchOfTheDocumentsEmail={this.state.textInputValueDispatchOfTheDocumentsEmail}
                        textAreaValueAdditionalComments={this.state.textAreaValueAdditionalComments}
                        onSubmitRequestSuccess={this.onSubmitRequestSuccess}
                        onSubmitRequestError={this.onSubmitRequestError}
                    />
                ) : null}
                {this.state.modalScreen === modalScreen.MODAL_FILES ? (
                    <ModalCPVOLogsVersion2
                        intl={this.props.intl}
                        zone={'e-demandes'}
                        notice={true}
                        noticeMessage={`Your request is submitted with success.`}
                        timestamp={this.state.timestamp}
                        folder={this.state.modalLogsFolder}
                        files={this.state.modalLogsFiles}
                        close={this.closeModal}
                    />
                ) : null}
                {this.state.modalScreen === modalScreen.ALERT ? (
                    <ModalAlertVersion2
                        message={this.state.modalAlertMessage}
                        title={this.state.modalAlertTitle}
                        close={this.closeModal}
                    />
                ) : null}
                {this.state.isModalAlertRequestPurposeCannotBeSelectedOpen ? (
                    <ModalAlertVersion2
                        message={`Please note that you can only tick "for claiming priority" or "for legal purposes" if you have ticked under A. Type of request: "Certified Copies" or "Maintenance letter".`}
                        title={`Info`}
                        close={this.closeModalAlertRequestPurposeCannotBeSelected}
                    />
                ) : null}
                {this.state.applicationNumbersNotFound ? (
                    <ModalAlertVersion2
                        message={`No corresponding application number(s): ${this.state.applicationNumbersNotFound.join(
                            ', '
                        )}. Please verify.`}
                        title={`Applications not found`}
                        close={this.closeModalApplicationNumbersNotFound}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderTitleAndVersion
                    title={`Request for certified copies or an official extract from the register`}
                />
                <Navigation />
                <FormWrapper paddingFormContent={'sm'}>
                    <section className={styles.section}>
                        <Title triple={true} full={true}>
                            {this.getMandatoryAsterisk('A. Type of request')}
                        </Title>
                        <div>
                            <RadioButton
                                triple={true}
                                radioButtonList={[
                                    {
                                        description: 'Certified copies of documents (CC)',
                                        value: TYPE_OF_REQUEST_SELECT_OPTIONS.CC,
                                        popoverMaxWidth: '500px',
                                        popoverPosition: 'right',
                                        popOverText: (
                                            <div>
                                                <div>{`Each set of CC will contain a declaration indicating the list of documents that are certified and an extract from the register in the chosen E.U. language.`}</div>
                                                <div
                                                    style={{marginTop: 5}}
                                                >{`- For applications, the Office will deliver: `}</div>
                                                <div>
                                                    <ol>
                                                        <li>{`the application form`}</li>
                                                        <li>{`the technical questionnaire`}</li>
                                                        <li>
                                                            {`the confidential part of the technical questionnaire`}
                                                        </li>
                                                        <li>{`the denomination form and`}</li>
                                                        <li>{`photo(s).`}</li>
                                                    </ol>
                                                </div>
                                                <div style={{marginTop: 5}}>
                                                    {`- For titles, the Office will deliver: a copy of the certificate on the grant.`}
                                                </div>
                                                <div
                                                    style={{marginTop: 5}}
                                                >{`Documents are provided in the language originally selected by the applicant/titleholder at the time of filing the application in question.`}</div>
                                                <div style={{marginTop: 5}}>
                                                    {`Should you wish to deviate from the above sets of documents, please specify under ‘other documents’ your requirements.`}
                                                </div>
                                                <div style={{marginTop: 5}}>
                                                    {`As the requestor you should be either the applicant/titleholder or procedural representative for the file in question. If not the case, please provide the Office with a signed authorization from either the applicant/titleholder or procedural representative to effect so.`}
                                                </div>
                                            </div>
                                        ),
                                    },
                                    {
                                        description: 'Official extract from the register',
                                        value: TYPE_OF_REQUEST_SELECT_OPTIONS.EXTRACT,
                                        popoverPosition: 'right',
                                        popOverText:
                                            'An extract from the register of applications or of the register of rights can be provided in the chosen E.U. language.',
                                    },
                                    {
                                        description: 'Maintenance letter',
                                        value: TYPE_OF_REQUEST_SELECT_OPTIONS.MAINTENANCE,
                                        popoverMaxWidth: '500px',
                                        popoverPosition: 'right',
                                        popOverText: (
                                            <div>
                                                <div
                                                    style={{marginBottom: 15}}
                                                >{`A maintenance letter can be provided in the chosen E.U. language and is an extract from the register of rights stating when the latest annual fee was paid and that therefore the granted CPVR is still valid.`}</div>
                                                <div
                                                    style={{marginBottom: 15}}
                                                >{`As the requestor you should be either titleholder or procedural representative for the title in question. If not the case, please provide the Office with an authorization from either the titleholder or procedural representative.`}</div>
                                            </div>
                                        ),
                                    },
                                    {
                                        description: 'Technical examination confirmation letter (TECL)',
                                        value: TYPE_OF_REQUEST_SELECT_OPTIONS.TECL,
                                        popoverMaxWidth: '500px',
                                        popoverPosition: 'right',
                                        popOverText: (
                                            <div>
                                                <div
                                                    style={{marginBottom: 15}}
                                                >{`A TECL can be provided in the chosen E.U. language and is an extract, where the technical examination is organized by the CPVO, from the register of applications stating that the live material will be or has been delivered at the identified testing station, in which country the technical examination (will) take(s) place, and on which foreseen date the examination is to end.`}</div>
                                                <div
                                                    style={{marginBottom: 15}}
                                                >{`As the requestor you should be either the applicant or procedural representative for the application in question. If not the case, please provide the Office with an authorization from either the applicant or procedural representative.`}</div>
                                            </div>
                                        ),
                                    },
                                    {
                                        description: 'Other, please specify:',
                                        value: TYPE_OF_REQUEST_SELECT_OPTIONS.OTHER,
                                        popoverPosition: 'right',
                                        popOverText:
                                            'Please indicate the list of documents you need in your specific case bearing in mind that a declaration and extract will be added standard to each requested set of documents to certify the documents.',
                                    },
                                ]}
                                groupName={'typeOfRequest'}
                                value={this.state.radioButtonValueTypeOfRequest}
                                clickEvent={this.onRadioButtonValueTypeOfRequestClick}
                            />
                            <div style={{clear: 'both'}} />
                        </div>
                        {this.state.radioButtonValueTypeOfRequest === TYPE_OF_REQUEST_SELECT_OPTIONS.OTHER && (
                            <div>
                                <TextInput
                                    onChange={this.onTextInputValueOtherTypeOfRequestChange}
                                    value={this.state.textInputValueOtherTypeOfRequest}
                                    double={true}
                                />
                                <div style={{clear: 'both'}} />
                            </div>
                        )}
                    </section>
                    <section className={styles.section}>
                        <Title triple={true} full={true}>
                            {this.getMandatoryAsterisk('B. Language of the request')}
                            <span className={styles.customPopoverRadioTitle}>
                                <CustomPopoverRadio
                                    content={
                                        'Please select the appropriate E.U. language in the folding list in which you wish to have the declaration and extract to be drawn up.'
                                    }
                                    popoverPosition={`right`}
                                />
                            </span>
                        </Title>
                        <div>
                            <SelectInput
                                double={true}
                                value={this.state.selectInputValueLanguageOfRequest}
                                onChange={this.onSelectInputValueLanguageOfRequestChange}
                                list={LANGUAGE_OPTIONS}
                                outsideLabelWidth={250}
                                notAll={true}
                            />
                            <div style={{clear: 'both'}} />
                        </div>
                    </section>
                    <section className={styles.section}>
                        <Title
                            triple={true}
                            full={true}
                            titleDescription={`(only for "Certified copies of documents" or "Maintenance letter" requests)`}
                        >
                            {'C. Request purpose'}
                        </Title>
                        <div onClick={this.onRadioButtonRequestPurposeClick}>
                            <RadioButton
                                triple={true}
                                radioButtonList={[
                                    {
                                        description:
                                            'For submitting a PBR application with a different authority and claiming priority',
                                        value: REQUEST_PURPOSE_VALUE.PBR,
                                    },
                                    {
                                        description: 'For legal purposes, please select the court country concerned:',
                                        value: REQUEST_PURPOSE_VALUE.LEGAL,
                                        popoverPosition: 'right',
                                        popOverText:
                                            'Please state in the framework of which legal actions and before which court you will need the requested documents.',
                                    },
                                ]}
                                groupName={'requestPurpose'}
                                value={this.state.radioButtonValueRequestPurpose}
                                // className={styles.radioHorizontal}
                                clickEvent={this.onRadioButtonValueRequestPurposeClick}
                                disabled={
                                    this.state.radioButtonValueTypeOfRequest !== TYPE_OF_REQUEST_SELECT_OPTIONS.CC &&
                                    this.state.radioButtonValueTypeOfRequest !==
                                        TYPE_OF_REQUEST_SELECT_OPTIONS.MAINTENANCE
                                }
                            />
                            <div style={{clear: 'both'}} />
                        </div>
                        <div style={{position: 'relative', marginLeft: 20, top: -10, marginBottom: -15}}>
                            <SelectInput
                                disabled={this.state.radioButtonValueRequestPurpose !== REQUEST_PURPOSE_VALUE.LEGAL}
                                double={true}
                                list={this.state.optionsListCountriesEU}
                                notAll={true}
                                onChange={this.onSelectInputValueCountryOfRequestPurposeChange}
                                outsideLabelWidth={250}
                                value={this.state.selectInputValueCountryOfRequestPurpose}
                            />
                            <div style={{clear: 'both'}} />
                        </div>
                    </section>
                    <section className={styles.section}>
                        <Title triple={true} full={true}>
                            {this.getMandatoryAsterisk('D. Application number')}
                            <div className={styles.customPopoverRadioTitle}>
                                <CustomPopoverRadio
                                    content={
                                        'Consists of a year, followed by a number (4 digits). You can enter numbers one by one or in the form of a list (see below options).'
                                    }
                                    popoverPosition={`right`}
                                />
                            </div>
                        </Title>
                        <div style={{marginBottom: 10}}>
                            <TextLabelInput
                                double={true}
                                onSelectionChange={this.onApplicationNumbersSelectionChange}
                                onChange={this.onApplicationNumberChange}
                                onEnter={this.onAddingApplicationNumber}
                                value={this.state.applicationNumber}
                                selectedElements={this.state.selectedApplicationNumbers}
                                delay={300}
                                multiple={true}
                                buttonAction={this.onAddingApplicationNumber}
                                buttonIcon={faPlus}
                                placeholder={`e.g. 20101234`}
                                buttonDisabled={
                                    (this.state.applicationNumberError &&
                                        this.state.applicationNumberError !== 'ADD') ||
                                    this.state.applicationNumber.length !== 8
                                }
                            />
                            {this.state.applicationNumberError && (
                                <div style={{textAlign: 'center', width: 700, marginBottom: 20, marginLeft: 7}}>
                                    <Info>
                                        <div style={{display: 'inline-block', marginRight: 5}}>
                                            <FontAwesomeIcon icon={faFlag as any} color={'#8a6d3b'} />
                                        </div>
                                        <div style={{display: 'inline-block'}}>
                                            {errorMessages[this.state.applicationNumberError]
                                                ? errorMessages[this.state.applicationNumberError]
                                                : this.state.applicationNumberError}
                                        </div>
                                    </Info>
                                </div>
                            )}
                            <div style={{clear: 'both'}} />
                        </div>
                        <div>
                            <div className={styles.inputLinkContainer}>
                                <InputLink
                                    label={`Application number list`}
                                    clickAction={this.showModalScreenApplicationList}
                                    width={180}
                                />
                                <span className={styles.customPopoverRadioContainer}>
                                    <CustomPopoverRadio
                                        content={
                                            'Please enter a list of application numbers each separated by a carriage return.'
                                        }
                                        popoverPosition={`right`}
                                    />
                                </span>
                            </div>
                            <div className={styles.inputLinkContainer}>
                                <InputLink
                                    label={`Titles list`}
                                    clickAction={this.showModalScreenTitlesList}
                                    width={80}
                                />
                                <span className={styles.customPopoverRadioContainer}>
                                    <CustomPopoverRadio
                                        content={
                                            'Please enter a list of title numbers each separated by a carriage return.'
                                        }
                                        popoverPosition={`right`}
                                    />
                                </span>
                            </div>
                            <div style={{clear: 'both'}} />
                        </div>
                    </section>
                    <section className={styles.section}>
                        <Title triple={true} full={true}>
                            {'E. Applicable fees'}
                        </Title>
                        <div className={styles.paragraph}>
                            {`The fee applicable is `}
                            <strong>
                                {`€20 for the first ten pages and €1 per additional page for each set of documents`}
                            </strong>
                            {' requested for a specific application or title number.'}
                        </div>
                        <div className={styles.paragraph}>
                            {`By default, a `}
                            <strong>
                                {`debit note will be issued and addressed to the below mentioned CPVO user number`}
                            </strong>
                            {' as registered in the CPVO databases'}
                        </div>
                        <div className={styles.paragraph}>
                            <strong>{`You may wish to inform your accountancy e-mail address here`}</strong>
                        </div>
                        <div>
                            <TextInput
                                double={true}
                                error={this.state.textInputErrorApplicableFeesEmail && 'Invalid email'}
                                onChange={this.onTextInputValueApplicableFeesEmailChange}
                                value={this.state.textInputValueApplicableFeesEmail}
                            />
                            <div style={{clear: 'both'}} />
                        </div>
                        <div className={styles.paragraph}>
                            {`Kindly note that the Office is `}
                            <strong>{`not able to bill outside the European Union`}</strong>
                            {
                                ' (EU). For any request from a country outside of the EU the debit note will be sent to the procedural representative designated within the EU.'
                            }
                        </div>
                        <div className={styles.paragraph}>
                            <strong>{`Upon receipt of your payment documents in electronic format will be sent through our secured platform.`}</strong>
                        </div>
                        <div className={styles.paragraph}>
                            {`If you are in `}
                            <strong>{`urgent need of the documents`}</strong>
                            {`, please tick the box below and add proof of payment (transfer order to the bank) as an attached document.`}
                        </div>
                        <div>
                            <Checkbox
                                label={`Urgent`}
                                value={this.state.checkboxValueApplicableFeesUrgent}
                                clickAction={this.onCheckboxValueApplicableFeesUrgentClick}
                                simple={true}
                                height={28}
                                width={400}
                            />
                            <div style={{clear: 'both'}} />
                        </div>
                    </section>
                    <section className={styles.section}>
                        <Title triple={true} full={true}>
                            {'F. Dispatch of the documents'}
                        </Title>
                        <div className={styles.paragraph}>
                            {`Upon receipt of your payment documents in electronic format will be sent automatically through our secured platform by default to the correspondence address of the indicated CPVO user number.`}
                        </div>
                        <div className={styles.paragraph}>
                            {`If you wish to send the documents to `}
                            <strong>{`an additional addressee inside or outside the European Union`}</strong>
                            {' please fill in the correct e-mail address in the box below.'}
                        </div>
                        <div>
                            <TextInput
                                autoCompleteDisabled={true}
                                double={true}
                                error={this.state.textInputErrorDispatchOfTheDocumentsEmail && 'Invalid email'}
                                label={`Additional e-mail address`}
                                onChange={this.onTextInputValueDispatchOfTheDocumentsEmailChange}
                                value={this.state.textInputValueDispatchOfTheDocumentsEmail}
                            />
                            <div style={{clear: 'both'}} />
                        </div>
                    </section>
                    <section className={styles.section}>
                        <div>
                            <Title triple={true} full={true}>
                                {this.getMandatoryAsterisk('G. Requestor')}
                            </Title>
                        </div>
                        <div>
                            <InputLink
                                label={`Requestor details`}
                                clickAction={this.showModalScreenRequestorDetails}
                                width={180}
                            />
                        </div>
                        <div style={{clear: 'both'}} />
                    </section>
                    <section className={styles.section}>
                        <Title triple={true} full={true}>
                            {'H. Additional comments, if any for this request'}
                        </Title>
                        <div>
                            <TextAreaInput
                                double={true}
                                value={this.state.textAreaValueAdditionalComments}
                                onChange={this.onTextAreaValueAdditionalCommentsChange}
                                outsideLabelWidth={180}
                                rows={3}
                            />
                        </div>
                    </section>
                    <div style={{clear: 'both'}} />
                    <FormFooter>
                        {this.state.formError ? <Error>{this.state.formError}</Error> : null}
                        <Button
                            variation={'secondary'}
                            clickAction={this.onButtonResetFormClick}
                        >{`Reset form`}</Button>
                        <Button variation={'primary'} clickAction={this.onButtonContinueClick}>{`Continue`}</Button>
                    </FormFooter>
                </FormWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(RequestCertifiedCopies));
