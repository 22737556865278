import React, {useEffect, useRef, useState} from 'react';
import {injectIntl, IntlShape} from 'react-intl';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './ModalAnswerToCPVOProposalNumber.module.scss';
import {faEdit, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {ErrorMessages} from '../../../../CommonInterfaces/CommonInterfaces';
import {
    apiCultivationTypesAutocompletionFetch,
    apiSubmissionPlacesFetch,
    apiTestingSitesFetch,
    CultivationTypesFetchData,
    DraftProposalAnswerForSave,
    ModalScreenType,
    PlantMaterialRequirements,
    SubmissionPlacesFetchData,
    TestingSitesFetchData,
} from './ModalAnswerToCPVOProposalNumberService';
import {ModalConfirmVersion2, ModalCustomVersion2, ModalErrorVersion2} from '../../../../../../commonModals';
import TextLabelInput from '~components/TextLabelInput';
import TextInput from '~components/TextInput';
import DateInput from '~components/DateInput';
import Empty from '~components/Empty';
import SelectInput from '~components/SelectInput';
import Label from '~components/Label';
import TextAreaInput from '~components/TextAreaInput';
import Checkbox from '~components/Checkbox';
import Error from '~components/Error';
import {buttonColor} from '~componentsForm/FormFooterButton';
import ModalDefaultRequirements from '../../../../CommonComponents/ModalDefaultRequirements/ModalDefaultRequirements';
import {renderHTML} from '../../../../../../common/format';
import {getConcatErrorMessage} from '../../../../CommonFunctions/CommonFunctions';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {getMandatoryAsterisk} from '~utils/format';
import {History} from 'history';
import {ProposalAnswer} from '../../NewSpeciesProcedurePageService';
import {Button} from '../../../../../../componentsFormV2';

const modalScreenType: ModalScreenType = {
    ERROR: 'ERROR',
    SAVE_DRAFT_ANSWER_CONFIRM: 'SAVE_DRAFT_ANSWER_CONFIRM',
    APPLICATION_WITHDRAWAL_CONFIRM: 'APPLICATION_WITHDRAWAL_CONFIRM',
    DEFAULT_REQUIREMENTS_CUSTOM: 'DEFAULT_REQUIREMENTS_CUSTOM',
};

interface ModalAnswerToCPVOProposalNumberProps {
    intl: IntlShape;
    history: History;
    proposalAnswer: ProposalAnswer;
    proposalNumber: number;
    inventoryNumber: string;
    proposedCostGroup: string;
    quantityQuality: string | null;
    quantityQualityIds: string | null;
    speciesExamId: number;
    userRoles: Array<string>;
    saveProposalAnswerAsDraft: (proposalAnswerDataForSave: DraftProposalAnswerForSave) => void;
    close: (refresh?: boolean) => void;
}

const ModalAnswerToCPVOProposalNumber = (props: ModalAnswerToCPVOProposalNumberProps & RouteComponentProps) => {
    const initNationalProtocol = (nationalProtocol: string) => {
        if (Number(nationalProtocol) === 0) {
            return false;
        }

        return true;
    };
    const [loading, setLoading] = useState(false);
    const [errorMessageModal, setErrorMessageModal] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [requestsToBeEntrusted, setRequestToBeEntrusted] = useState(
        props.proposalAnswer.eoInterested !== undefined && props.proposalAnswer.eoInterested === 1
    );
    const [lumpSum, setLumpSum] = useState(
        props.proposalAnswer.lumpSum !== null &&
            props.proposalAnswer.lumpSum !== undefined &&
            props.proposalAnswer.lumpSum === 1
    );
    const [selfAssessment, setSelfAssessment] = useState(false);
    const [examinationFulfillsDate, setExaminationFulfillsDate] = useState(
        props.proposalAnswer.startEntrustmentDate || ''
    );
    const [entrustmentType, setEntrustmentType] = useState(props.proposalAnswer.entrustmentType || '');
    const [cultivationType, setCultivationType] = useState(props.proposalAnswer.cultivationType || '');
    const [selectedCultivationTypes, setSelectedCultivationTypes] = useState<{[key: string]: string}>({});
    const [cultivationTypesListByIdName, setCultivationTypesListByIdName] = useState<Array<{
        ID: string;
        NAME: string;
    }> | null>(null);
    const [cultivationTypesNameHash, setCultivationTypesNameHash] = useState<{[key: string]: Array<string>}>({});
    const [growingCycles, setGrowingCycles] = useState(
        (props.proposalAnswer.examinationByYear &&
            props.proposalAnswer.examinationByYear !== null &&
            props.proposalAnswer.examinationByYear.toString()) ||
            '0'
    );
    const [submissionPlaceId, setSubmissionPlaceId] = useState(0);
    const [submissionPlaces, setSubmissionPlaces] = useState<Array<{id: number; value: string}>>([
        {
            id: 0,
            value: 'Please choose',
        },
    ]);
    const [testingSiteId, setTestingSiteId] = useState(0);
    const [testingSites, setTestingSites] = useState<Array<{id: number; value: string}>>([
        {
            id: 0,
            value: 'Please choose',
        },
    ]);
    const [closingDate, setClosingDate] = useState(props.proposalAnswer.closingDate || '');
    const [submissionStartDate, setSubmissionStartDate] = useState(props.proposalAnswer.submissionStart || '');
    const [submissionEndDate, setSubmissionEndDate] = useState(props.proposalAnswer.submissionEnd || '');
    const [firstInterimDate, setFirstInterimDate] = useState(props.proposalAnswer.interimReportDate || '');
    const [foreseenDate, setForeseenDate] = useState(props.proposalAnswer.foreseenFinalReportDate || '');
    const [plantRequirements, setPlantRequirements] = useState<PlantMaterialRequirements | null>(null);
    const [seedPlantQuantityQuality, setSeedPlantQuantityQuality] = useState(props.quantityQuality || '');
    const [seedPlantQuantityQualityIds, setSeedPlantQuantityQualityIds] = useState(props.quantityQualityIds || '');
    const [comment, setComment] = useState(props.proposalAnswer.remarkExamination || '');
    const [existingNationalProtocol, setExistingNationalProtocol] = useState(
        (props.proposalAnswer.nationalPropotol && initNationalProtocol(props.proposalAnswer.nationalPropotol)) || false
    );
    const [specifyCultivationTypeOrComment, setSpecifyCultivationTypeOrComment] = useState(
        props.proposalAnswer.remark || ''
    );
    const [highlightErrors, setHighlightErrors] = useState(false);

    const firstLoad = useFirstRender();

    function useFirstRender() {
        const firstRender = useRef(true);

        useEffect(() => {
            firstRender.current = false;
        }, []);

        return firstRender.current;
    }

    const getCultivationTypesAutocompletionList = () => {
        setLoading(true);
        const cultivationTypeTerm = cultivationType;
        apiCultivationTypesAutocompletionFetch(cultivationTypeTerm)
            .then((jsonResponse: CultivationTypesFetchData) => {
                const cultivationTypesNameHash: {[key: string]: Array<string>} = {};
                ((jsonResponse && jsonResponse.data && jsonResponse.data.cultivationTypes) || []).forEach(ct => {
                    if (!cultivationTypesNameHash[ct.cultivationType]) {
                        cultivationTypesNameHash[ct.cultivationType] = [];
                    }
                    cultivationTypesNameHash[ct.cultivationType].push(ct.id);
                });
                const cultivationTypesListByIdName = Object.keys(cultivationTypesNameHash).map(ctn => ({
                    ID: cultivationTypesNameHash[ctn].join(','),
                    NAME: ctn,
                }));
                const selectedCultivationTypesObj: {[key: string]: string} = {};
                const selectedCultivationTypesKeyArray = Object.keys(selectedCultivationTypes || {});
                cultivationTypesListByIdName
                    .filter(i => selectedCultivationTypesKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectedCultivationTypesObj[item.ID] = item.NAME));
                setCultivationTypesNameHash(cultivationTypesNameHash);
                setCultivationTypesListByIdName(cultivationTypesListByIdName);
                setSelectedCultivationTypes(selectedCultivationTypesObj);
            })
            .catch(error => {
                setErrorMessageModal(`Cultivation Types Autocompletion list error: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getSubmissionPlaces = () => {
        setLoading(true);
        apiSubmissionPlacesFetch()
            .then((jsonResponse: SubmissionPlacesFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.submissionPlaces) {
                    const finalSubmissionPlaces: Array<{id: number; value: string}> = submissionPlaces;
                    jsonResponse.data.submissionPlaces.forEach(submissionPlace =>
                        finalSubmissionPlaces.push({
                            id: submissionPlace.id,
                            value: submissionPlace.name,
                        })
                    );
                    setSubmissionPlaces(finalSubmissionPlaces);
                    if (props.proposalAnswer.submissionAddressId) {
                        const submissionPlace = finalSubmissionPlaces.filter(
                            sp => sp.id === props.proposalAnswer.submissionAddressId
                        )[0].id;
                        setSubmissionPlaceId(submissionPlace);
                    }
                }
            })
            .catch(error => {
                setErrorMessageModal(`Submission Places list error: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getTestingSites = () => {
        setLoading(true);
        apiTestingSitesFetch()
            .then((jsonResponse: TestingSitesFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.testingSites) {
                    const finalTestingSites: Array<{id: number; value: string}> = testingSites;
                    jsonResponse.data.testingSites.forEach(testingSite =>
                        finalTestingSites.push({
                            id: testingSite.id,
                            value: testingSite.name,
                        })
                    );
                    setTestingSites(finalTestingSites);
                    if (props.proposalAnswer.addressTestingId) {
                        const testingSite = finalTestingSites.filter(
                            sp => sp.id === props.proposalAnswer.addressTestingId
                        )[0].id;
                        setTestingSiteId(testingSite);
                    }
                }
            })
            .catch(error => {
                setErrorMessageModal(`Testing Sites list error: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const loadJSONs = () => {
        getCultivationTypesAutocompletionList();
        getSubmissionPlaces();
        getTestingSites();
    };

    useEffect(() => {
        firstLoad && loadJSONs();
    }, []);

    const comebackToSearch = (refresh = false) => {
        if (refresh) {
            props.close && props.close(refresh);
        } else {
            props.close && props.close();
        }
    };

    const closeErrorModal = () => {
        setModalScreen(null);
        setErrorMessageModal(null);
    };

    const closeSubmitProposalToCPVO = () => {
        setModalScreen(null);
    };

    const closeDefaultRequirementsModal = (submit: boolean, quantityQuality?: string, quantityQualityIds?: string) => {
        setModalScreen(null);
        if (submit && quantityQuality) setSeedPlantQuantityQuality(quantityQuality);
        if (submit && quantityQualityIds) setSeedPlantQuantityQualityIds(quantityQualityIds);
    };

    const onCheckRequestToBeEntrusted = () => {
        setRequestToBeEntrusted(!requestsToBeEntrusted);
    };

    const onCheckLumpSum = () => {
        let checklumpSumTrue = lumpSum === true;
        let checklumpSumNull = lumpSum !== null;
        let checklumpSumUndefined = lumpSum !== undefined;
        setLumpSum(checklumpSumNull && checklumpSumUndefined && checklumpSumTrue ? false : true);
    };

    const onCheckSelfAssessment = () => {
        setSelfAssessment(!selfAssessment);
    };

    const onExaminationFulfillsDateChange = (examinationFulfillsDate: string) => {
        setExaminationFulfillsDate(examinationFulfillsDate);
    };

    const onEntrustmentTypeChange = ({target: {value: entrustmentType}}: React.ChangeEvent<HTMLInputElement>) =>
        setEntrustmentType(entrustmentType);

    const onCultivationTypeSelectionChange = (selectedCultivationTypes: {[key: string]: string}) => {
        const selectedCultivationTypesKeys = Object.keys(selectedCultivationTypes);
        if (selectedCultivationTypesKeys.length > 1) {
            delete selectedCultivationTypes[selectedCultivationTypesKeys[0]];
        }
        setSelectedCultivationTypes(selectedCultivationTypes);
    };

    const onCultivationTypeChange = (cultivationType: string) => setCultivationType(cultivationType);

    const onGrowingCyclesChange = ({target: {value: growingCycles}}: React.ChangeEvent<HTMLInputElement>) =>
        setGrowingCycles(
            growingCycles
                .replace(/[^0-9]/g, '')
                .replace('', '0')
                .replace(/^0+(\d)/, '$1')
        );

    const onSubmissionPlaceChange = ({target: {value: submissionPlaceId}}: React.ChangeEvent<HTMLSelectElement>) =>
        setSubmissionPlaceId(Number(submissionPlaceId));

    const onTestingSiteChange = ({target: {value: testingSiteId}}: React.ChangeEvent<HTMLSelectElement>) =>
        setTestingSiteId(Number(testingSiteId));

    const onClosingDateChange = (closingDate: string) => setClosingDate(closingDate);

    const onSubmissionStartDateChange = (submissionStartDate: string) => setSubmissionStartDate(submissionStartDate);

    const onSubmissionEndDateChange = (submissionEndDate: string) => setSubmissionEndDate(submissionEndDate);

    const onFirstInterimDateChange = (firstInterimDate: string) => setFirstInterimDate(firstInterimDate);

    const onForeseenDateChange = (foreseenDate: string) => setForeseenDate(foreseenDate);

    const onCommentChange = ({target: {value: comment}}: React.ChangeEvent<HTMLTextAreaElement>) => setComment(comment);

    const onCheckExistingNationalProtocol = () => setExistingNationalProtocol(!existingNationalProtocol);

    useEffect(() => {
        if (existingNationalProtocol && requestsToBeEntrusted) {
            setRequestToBeEntrusted(!requestsToBeEntrusted);
        }
    }, [existingNationalProtocol]);

    const onSpecifyCultivationTypeOrCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const specifyCultivationType = event.target.value;
        setSpecifyCultivationTypeOrComment(specifyCultivationType);
    };

    const getPlantRequirementsData = () => {
        const quantityQualityIdsList = props.quantityQualityIds === null ? [] : props.quantityQualityIds.split('|');
        let plantRequirements: PlantMaterialRequirements = {
            main: [
                {
                    quantityQuality: seedPlantQuantityQuality,
                    quantity1: 0,
                    unit1: '',
                    type1: '',
                    alternativeQuantity1: '',
                    age1: '',
                    size1: '',
                    rootsAndGrafts1: '',
                    seedQuality1: '',
                    phytoSanitaryCertificate1: '',
                    andor: '',
                },
            ],
            otherQualities: [],
            testedPests: [],
            others: [],
            additionalQuantQualityInfo: [],
            additionalOtherQualities: [],
            additionalTestedPests: [],
            additionalOthers: [],
        };
        if (quantityQualityIdsList.length > 0) {
            const quantity1 = quantityQualityIdsList[0].length > 0 ? Number(quantityQualityIdsList[0]) : 0;
            const unit1 = quantityQualityIdsList[1];
            const type1 = quantityQualityIdsList[2];
            const alternativeQuantity1 = quantityQualityIdsList[3];
            const age1 = quantityQualityIdsList[4];
            const size1 = quantityQualityIdsList[5];
            const seedQuality1 = quantityQualityIdsList[6];
            const rootsAndGrafts1 = quantityQualityIdsList[7];
            const otherQualities1 = quantityQualityIdsList[8];
            const phytoSanitaryCertificate1 = quantityQualityIdsList[9];
            const testedPests1 = quantityQualityIdsList[10];
            const others1 = quantityQualityIdsList[11];
            const andor = quantityQualityIdsList[12];
            const quantity2 = quantityQualityIdsList[13].length > 0 ? Number(quantityQualityIdsList[13]) : 0;
            const unit2 = quantityQualityIdsList[14];
            const type2 = quantityQualityIdsList[15];
            const alternativeQuantity2 = quantityQualityIdsList[16];
            const age2 = quantityQualityIdsList[17];
            const size2 = quantityQualityIdsList[18];
            const seedQuality2 = quantityQualityIdsList[19];
            const rootsAndGrafts2 = quantityQualityIdsList[20];
            const otherQualities2 = quantityQualityIdsList[21];
            const phytoSanitaryCertificate2 = quantityQualityIdsList[22];
            const testedPests2 = quantityQualityIdsList[23];
            const others2 = quantityQualityIdsList[24];
            plantRequirements = {
                main: [
                    {
                        quantityQuality: seedPlantQuantityQuality,
                        quantity1,
                        unit1,
                        type1,
                        alternativeQuantity1,
                        age1,
                        size1,
                        rootsAndGrafts1,
                        seedQuality1,
                        phytoSanitaryCertificate1,
                        andor,
                    },
                ],
                otherQualities: [{otherQualities1}],
                testedPests: [{testedPests1}],
                others: [{others1}],
                additionalQuantQualityInfo: [
                    {
                        quantity2,
                        unit2,
                        type2,
                        alternativeQuantity2,
                        age2,
                        size2,
                        rootsAndGrafts2,
                        seedQuality2,
                        phytoSanitaryCertificate2,
                    },
                ],
                additionalOtherQualities: [{otherQualities2}],
                additionalTestedPests: [{testedPests2}],
                additionalOthers: [{others2}],
            };
        }
        setPlantRequirements(plantRequirements);
        setModalScreen(modalScreenType.DEFAULT_REQUIREMENTS_CUSTOM);
    };

    const getDateValueReplacements = () => {
        let startDate = '';
        let endDate = '';
        let closeDate = '';
        let startEntrustmentDate = '';
        let foreseenFinalDate = '';
        let finalInterimDate = '';
        let splittedDateList: Array<string> = [];
        if (submissionStartDate.length > 0) {
            splittedDateList = submissionStartDate.split('/');
            startDate = splittedDateList[2] + splittedDateList[1] + splittedDateList[0];
        }
        if (submissionEndDate.length > 0) {
            splittedDateList = submissionEndDate.split('/');
            endDate = splittedDateList[2] + splittedDateList[1] + splittedDateList[0];
        }
        if (closingDate.length > 0) {
            splittedDateList = closingDate.split('/');
            closeDate = splittedDateList[2] + splittedDateList[1] + splittedDateList[0];
        }
        if (examinationFulfillsDate.length > 0) {
            splittedDateList = examinationFulfillsDate.split('/');
            startEntrustmentDate = splittedDateList[2] + splittedDateList[1] + splittedDateList[0];
        }
        if (foreseenDate.length > 0) {
            splittedDateList = foreseenDate.split('/');
            foreseenFinalDate = splittedDateList[2] + splittedDateList[1] + splittedDateList[0];
        }
        if (firstInterimDate.length > 0) {
            splittedDateList = firstInterimDate.split('/');
            finalInterimDate = splittedDateList[2] + splittedDateList[1] + splittedDateList[0];
        }

        return {startDate, endDate, closeDate, startEntrustmentDate, foreseenFinalDate, finalInterimDate};
    };

    const prepareProposalAnswerForSave = (): DraftProposalAnswerForSave => {
        const dateValueReplacements = getDateValueReplacements();
        const selfAssessmentFlag = requestsToBeEntrusted ? 1 : 0;
        const nationalProtocolFlag = existingNationalProtocol ? 1 : 0;
        const testingSite = testingSiteId !== 0 ? testingSiteId : 0;
        const submissionPlace = submissionPlaceId !== 0 ? submissionPlaceId : 0;
        return {
            eoProposal: props.proposalNumber,
            inventoryNumber: props.proposalAnswer.inventoryNumber,
            selfAssessmentFlag,
            remark: specifyCultivationTypeOrComment,
            closingDate: dateValueReplacements.closeDate,
            quantityQuality: seedPlantQuantityQuality,
            quantityQualityIds: seedPlantQuantityQualityIds,
            submissionStartDate: dateValueReplacements.startDate,
            submissionEndDate: dateValueReplacements.endDate,
            growingCycle: Number(growingCycles),
            interimReport: dateValueReplacements.finalInterimDate,
            finalReport: dateValueReplacements.foreseenFinalDate,
            nationalProtocolFlag: nationalProtocolFlag,
            remarkExamination: comment,
            speciesId: props.proposalAnswer.speciesId,
            testingSite,
            entrustmentDate: dateValueReplacements.startEntrustmentDate,
            cultivationType,
            submissionPlace,
            lumpSum: lumpSum ? 1 : 0,
        };
    };

    const getErrorMessagesforSave = () => {
        setHighlightErrors(false);
        const diffSubmissionStartEndDate = moment(submissionStartDate, 'DD/MM/YYYY').diff(
            moment(submissionEndDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffSubmissionStartFirstInterimDate = moment(submissionStartDate, 'DD/MM/YYYY').diff(
            moment(firstInterimDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffSubmissionStartForeseenDate = moment(submissionStartDate, 'DD/MM/YYYY').diff(
            moment(foreseenDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffSubmissionEndFirstInterimDate = moment(submissionEndDate, 'DD/MM/YYYY').diff(
            moment(firstInterimDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffSubmissionEndForeseenDate = moment(submissionEndDate, 'DD/MM/YYYY').diff(
            moment(foreseenDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffClosingDateSubmissionStartDate = moment(closingDate, 'DD/MM/YYYY').diff(
            moment(submissionStartDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffClosingDateSubmissionEndDate = moment(closingDate, 'DD/MM/YYYY').diff(
            moment(submissionEndDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffClosingDateFirstInterimDate = moment(closingDate, 'DD/MM/YYYY').diff(
            moment(firstInterimDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffClosingDateForeseenDate = moment(closingDate, 'DD/MM/YYYY').diff(
            moment(foreseenDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffForeseenDateFirstInterimDate = moment(firstInterimDate, 'DD/MM/YYYY').diff(
            moment(foreseenDate, 'DD/MM/YYYY'),
            'days'
        );
        const errorMessages: ErrorMessages = {
            errorInput: [],
            errorSelect: [],
            errorLogic: [],
        };

        if (closingDate.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a closing date');
        }

        if (seedPlantQuantityQuality.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a seed/plant quantity and quality');
        }

        if (submissionStartDate.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a submission start date');
        }

        if (submissionEndDate.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a submission end date');
        } else if (submissionStartDate.length > 0 && diffSubmissionStartEndDate > 0) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'Please check that the submission start date is before the submission end.'
                );
        }

        if (foreseenDate.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a foreseen date for the final report');
        }

        if (Number(growingCycles) > 1 && firstInterimDate.length === 0) {
            errorMessages.errorLogic && errorMessages.errorLogic.push('a foreseen date for the interim report');
        }

        if (Number(growingCycles) === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a number of growing periods');
        }

        if (testingSiteId === 0) {
            errorMessages.errorSelect && errorMessages.errorSelect.push('a testing site');
        }

        if (submissionPlaceId === 0) {
            errorMessages.errorSelect && errorMessages.errorSelect.push('a plant material submission site');
        }

        if (submissionStartDate.length > 0 && firstInterimDate.length > 0 && diffSubmissionStartFirstInterimDate > 0) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'Please check that submission start date is before the foreseen first interim date.'
                );
        }

        if (submissionStartDate.length > 0 && foreseenDate.length > 0 && diffSubmissionStartForeseenDate > 0) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'Please check that submission start date is before the foreseen final report date.'
                );
        }

        if (submissionEndDate.length > 0 && firstInterimDate.length > 0 && diffSubmissionEndFirstInterimDate > 0) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'Please check that submission end date is before the foreseen first interim date.'
                );
        }

        if (submissionEndDate.length > 0 && foreseenDate.length > 0 && diffSubmissionEndForeseenDate > 0) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'Please check that submission end date is before the foreseen final report date.'
                );
        }

        if (closingDate.length > 0 && submissionStartDate.length > 0 && diffClosingDateSubmissionStartDate > 0) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push('Please check that closing date is before the submission start date.');
        }

        if (closingDate.length > 0 && submissionEndDate.length > 0 && diffClosingDateSubmissionEndDate > 0) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push('Please check that closing date is before the submission end date.');
        }

        if (closingDate.length > 0 && firstInterimDate.length > 0 && diffClosingDateFirstInterimDate > 0) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'Please check that closing date is before the foreseen first interim date.'
                );
        }

        if (closingDate.length > 0 && foreseenDate.length > 0 && diffClosingDateForeseenDate > 0) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'Please check that closing date is before the foreseen final report date.'
                );
        }

        if (diffForeseenDateFirstInterimDate > 0) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'The foreseen interim report date should be before the foreseen final report date.'
                );
        }

        return errorMessages;
    };

    const submitProposalToCPVORoutine = () => {
        const errorMessagesObj = getErrorMessagesforSave();
        let errorMessage = getConcatErrorMessage(errorMessagesObj);

        if (errorMessage.length > 0) {
            setErrorMessage(errorMessage);
            setHighlightErrors(true);
        }

        if (errorMessage.length === 0 && requestsToBeEntrusted) {
            setModalScreen(modalScreenType.SAVE_DRAFT_ANSWER_CONFIRM);
        }

        if (!requestsToBeEntrusted) {
            setModalScreen(modalScreenType.APPLICATION_WITHDRAWAL_CONFIRM);
        }
    };

    const saveProposalAnswerAsDraft = () => {
        const proposalAnswerDataForSave = prepareProposalAnswerForSave();
        props.saveProposalAnswerAsDraft && props.saveProposalAnswerAsDraft(proposalAnswerDataForSave);
    };

    return (
        <div>
            {modalScreen === modalScreenType.DEFAULT_REQUIREMENTS_CUSTOM ? (
                <ModalDefaultRequirements
                    plantRequirements={plantRequirements}
                    speciesExamId={props.speciesExamId}
                    seedPlantQuantityQualityIds={seedPlantQuantityQualityIds}
                    close={closeDefaultRequirementsModal}
                />
            ) : null}
            {modalScreen === modalScreenType.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessageModal} close={closeErrorModal} />
            ) : null}
            {modalScreen === modalScreenType.SAVE_DRAFT_ANSWER_CONFIRM ? (
                <ModalConfirmVersion2
                    title={'Submit draft answer'}
                    message={`Are you sure you want to save your answer? In case you do not agree with the cost group proposed : ${props.proposedCostGroup}, please state so in the remark box.`}
                    buttonName={'Yes'}
                    close={closeSubmitProposalToCPVO}
                    action={saveProposalAnswerAsDraft}
                />
            ) : null}
            {modalScreen === modalScreenType.APPLICATION_WITHDRAWAL_CONFIRM ? (
                <ModalConfirmVersion2
                    title={'Application Withdrawal'}
                    message={`Are you sure you want to withraw your application?`}
                    buttonName={'Yes'}
                    close={closeSubmitProposalToCPVO}
                    action={saveProposalAnswerAsDraft}
                />
            ) : null}
            <ModalCustomVersion2
                loading={loading}
                close={comebackToSearch}
                header={`Answer to CPVO Proposal nº ${props.proposalNumber} (Inventory Number: ${props.inventoryNumber})`}
                body={
                    <div>
                        <div className={'answerToCPVOProposalNumber_species'}>
                            <DataSheetFormFields
                                label={undefined}
                                mandatory={undefined}
                                html={renderHTML(
                                    props.proposalAnswer && `Species: ${props.proposalAnswer.speciesName}`
                                )}
                                loading={false}
                                disabled={true}
                                double={true}
                            />
                        </div>
                        <div style={{clear: 'both'}}>{}</div>
                        <Checkbox
                            clickAction={onCheckRequestToBeEntrusted}
                            label={`My Office requests to be entrusted for this species.`}
                            value={requestsToBeEntrusted}
                            double={true}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        <Checkbox
                            clickAction={onCheckLumpSum}
                            label={`My Office qualifies for the attribution of the lump sum.`}
                            value={lumpSum}
                            double={true}
                        />
                        <div style={{clear: 'both'}} />
                        {requestsToBeEntrusted ? (
                            <React.Fragment>
                                <React.Fragment>
                                    <Checkbox
                                        clickAction={onCheckSelfAssessment}
                                        label={`Date as from which my examination fulfills the CPVO quality requirements (self-assessment)`}
                                        value={selfAssessment}
                                        width={400}
                                    />
                                    {selfAssessment || examinationFulfillsDate.length !== 0 ? (
                                        <DateInput
                                            changeDateFrom={onExaminationFulfillsDateChange}
                                            inputValueFrom={examinationFulfillsDate}
                                            simple={true}
                                        />
                                    ) : null}
                                </React.Fragment>
                                <div style={{clear: 'both'}} />
                                <Empty height={5} />
                                <div style={{clear: 'both'}} />
                                <TextInput
                                    value={entrustmentType}
                                    onChange={onEntrustmentTypeChange}
                                    double={true}
                                    outsideLabel={'Entrustment Type'}
                                    outsideLabelWidth={170}
                                    disabled={true}
                                />
                                <div style={{clear: 'both'}}>{}</div>
                                <TextInput
                                    value={props.proposedCostGroup}
                                    outsideLabel={'Proposed Cost Group'}
                                    outsideLabelWidth={170}
                                    type={'text'}
                                    double={true}
                                    disabled={true}
                                />
                                <div style={{clear: 'both'}} />
                                <TextLabelInput
                                    double={true}
                                    onSelectionChange={onCultivationTypeSelectionChange}
                                    onChange={onCultivationTypeChange}
                                    value={cultivationType}
                                    selectedElements={selectedCultivationTypes}
                                    delay={300}
                                    outsideLabel={`Cultivation Type`}
                                    outsideLabelWidth={170}
                                    listByIdName={cultivationTypesListByIdName}
                                    nameHash={cultivationTypesNameHash}
                                    noInfo={true}
                                    isOnModal={true}
                                />
                                <div style={{clear: 'both'}} />
                                <hr />
                                <div style={{clear: 'both'}} />
                                {requestsToBeEntrusted ? (
                                    <TextAreaInput
                                        outsideLabel={`Comment regarding the proposal (this comment will be visible for the other TLOs)`}
                                        outsideLabelWidth={170}
                                        value={comment}
                                        onChange={onCommentChange}
                                        double={true}
                                        rows={6}
                                    />
                                ) : null}
                                <div style={{clear: 'both'}} />
                                <Empty height={5} />
                                <div style={{clear: 'both'}} />
                                <small>
                                    <div style={{display: 'inline-block', fontStyle: 'italic'}}>
                                        {`The following information will not be visible for the other TLOs`}
                                    </div>
                                </small>
                                <div style={{clear: 'both'}} />
                                <Empty height={5} />
                                <div style={{clear: 'both'}}>{}</div>
                                <DateInput
                                    changeDateFrom={onClosingDateChange}
                                    inputValueFrom={closingDate}
                                    outsideLabel={getMandatoryAsterisk('Closing Date')}
                                    outsideLabelWidth={170}
                                    simple={true}
                                />
                                <div style={{clear: 'both'}}>{}</div>
                                <SelectInput
                                    value={submissionPlaceId}
                                    onChange={onSubmissionPlaceChange}
                                    double={true}
                                    list={submissionPlaces}
                                    outsideLabel={getMandatoryAsterisk('Submission Place')}
                                    outsideLabelWidth={170}
                                    notAll={true}
                                />
                                <div style={{clear: 'both'}} />
                                <SelectInput
                                    value={testingSiteId}
                                    onChange={onTestingSiteChange}
                                    double={true}
                                    list={testingSites}
                                    outsideLabel={getMandatoryAsterisk('Testing Site')}
                                    outsideLabelWidth={170}
                                    notAll={true}
                                />
                                <div style={{clear: 'both'}} />
                                <div className={`answerToCPVOProposalNumber_seedPlantQuantityQuality`}>
                                    <TextAreaInput
                                        outsideLabel={getMandatoryAsterisk(`Seed / Plant Quantity and Quality`)}
                                        outsideLabelWidth={170}
                                        value={seedPlantQuantityQuality}
                                        double={true}
                                        rows={6}
                                        disabled={true}
                                    />
                                </div>
                                <span
                                    title={'Edit answer'}
                                    className={styles.editButton}
                                    onClick={getPlantRequirementsData}
                                >
                                    <FontAwesomeIcon icon={faEdit as any} color={'green'} />
                                </span>
                                <div style={{clear: 'both'}}>{}</div>
                                <DateInput
                                    changeDateFrom={onSubmissionStartDateChange}
                                    inputValueFrom={submissionStartDate}
                                    outsideLabel={' '}
                                    outsideLabelWidth={170}
                                    simple={true}
                                    label={getMandatoryAsterisk('Submission Start')}
                                />
                                <DateInput
                                    changeDateFrom={onSubmissionEndDateChange}
                                    inputValueFrom={submissionEndDate}
                                    label={getMandatoryAsterisk('Submission End')}
                                    simple={true}
                                />
                                <div style={{clear: 'both'}} />
                                <DateInput
                                    changeDateFrom={onFirstInterimDateChange}
                                    inputValueFrom={firstInterimDate}
                                    outsideLabel={' '}
                                    outsideLabelWidth={170}
                                    simple={true}
                                    label={'First Interim Report Date'}
                                />
                                <DateInput
                                    changeDateFrom={onForeseenDateChange}
                                    inputValueFrom={foreseenDate}
                                    label={getMandatoryAsterisk('Foreseen Final Report Date')}
                                    simple={true}
                                />
                                <div style={{clear: 'both'}}>{}</div>
                                <TextInput
                                    value={growingCycles}
                                    onChange={onGrowingCyclesChange}
                                    double={true}
                                    outsideLabel={getMandatoryAsterisk('Foreseen growing periods')}
                                    outsideLabelWidth={170}
                                    type={'number'}
                                />
                                <div style={{clear: 'both'}} />
                                {existingNationalProtocol && (
                                    <React.Fragment>
                                        <Label width={170}>{`Existing National Protocol?`}</Label>
                                        <Checkbox
                                            clickAction={onCheckExistingNationalProtocol}
                                            label={`Yes`}
                                            value={existingNationalProtocol}
                                            simple={true}
                                            disabled={true}
                                            width={100}
                                        />
                                        <Label width={450}>
                                            {`(If yes, please send it by email to denecheau@cpvo.europa.eu)`}
                                        </Label>
                                    </React.Fragment>
                                )}
                                <div style={{clear: 'both'}}>{}</div>
                                <TextAreaInput
                                    outsideLabel={`If you would like to add a different cultivation type or if you have any comment regarding the examination, please specify:`}
                                    outsideLabelWidth={170}
                                    value={specifyCultivationTypeOrComment}
                                    onChange={onSpecifyCultivationTypeOrCommentChange}
                                    double={true}
                                    rows={6}
                                />
                                <div style={{clear: 'both'}}>{}</div>
                            </React.Fragment>
                        ) : null}
                    </div>
                }
                footer={
                    requestsToBeEntrusted ? (
                        <>
                            {highlightErrors ? (
                                <Error>
                                    <FontAwesomeIcon icon={faExclamationCircle as any} color={'white'} />
                                    {` ${errorMessage}`}
                                </Error>
                            ) : null}
                            {(props.userRoles.indexOf('TLOOF') !== -1 || props.userRoles.indexOf('EOADM') !== -1) && (
                                <Button clickAction={submitProposalToCPVORoutine} icon={'arrowRight'}>
                                    {`Save Draft Answer`}
                                </Button>
                            )}
                        </>
                    ) : (
                        <>
                            {props.userRoles.indexOf('TLOOF') !== -1 || props.userRoles.indexOf('EOADM') !== -1 ? (
                                <Button
                                    color={buttonColor.green}
                                    clickAction={submitProposalToCPVORoutine}
                                    icon={'arrowRight'}
                                >
                                    {`Confirm Application Withdrawal`}
                                </Button>
                            ) : null}
                        </>
                    )
                }
            />
        </div>
    );
};

export default injectIntl(withRouter(ModalAnswerToCPVOProposalNumber));
