import React from 'react';
import cx from 'classnames';
import CustomTableModalFieldChooser from './CustomTableModalFieldChooser';
import styles from './CustomTable.module.scss';
import XLSX from 'xlsx';
import {
    FORMAT_DATE,
    FORMAT_DATE_EASY,
    FORMAT_DATE_TIME_EASY,
    formatDate,
    formatDateEasy,
    formatDateTimeEasy,
} from './FormatFunctions';
import {
    NORMALIZE_DATE,
    NORMALIZE_DATE_EU,
    NORMALIZE_NUMBER,
    normalizeDate,
    normalizeDateEU,
    normalizeNumber,
} from './NormalizeFunctions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faArrowCircleDown,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faColumns,
    faQuestion,
    faSpinner,
    faThList,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import {renderHTML} from '~common/format';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import AdminIcon from '../screens/TLO/CommonComponents/AdminIcon/AdminIcon';
import {utilCalculateTableMaxPossiblePage} from '../utils';
import {Waypoint} from 'react-waypoint';

const DEFAULT_PAGE_SIZE = 10;
const PAGE_BEGIN = 0;

const INFINITE_SCROLL_ELEMENTS = 200;

export default class CustomTable extends React.Component {
    timestamp = null;
    hashCurrentData = {};

    constructor(props) {
        super(props);
        let currentFields = props.resultFieldsDefault;
        let reverseOrder = props.reverseOrder || false;
        let currentOrder = props.defaultOrder || null;
        const storedCurrentFields = localStorage.getItem(`${props.tableName}SearchColumns`);
        if (storedCurrentFields && storedCurrentFields !== 'undefined') {
            currentFields = JSON.parse(storedCurrentFields);
        }
        const localStoragePageSize =
            props.tableName && parseInt(localStorage.getItem(`pageSize${props.tableName}`) || null);
        this.state = {
            currentData: props.tableSource || [],
            fieldChooserOpened: false,
            currentOrder,
            reverseOrder,
            currentFields,
            isNavigationButtonCompactViewActive: localStorage.getItem(`compactView${props.tableName}`) !== 'false',
            selectedElementsHash: this.props.selectDefault || {},
            selectedAll: false,
            currentPage: PAGE_BEGIN,
            pageSize: this.props.pageSize || localStoragePageSize || DEFAULT_PAGE_SIZE,
            infiniteScrollPages: 0,
            highlightIndex: 0,
        };
    }

    componentDidMount() {
        this.calculateCurrentDataHash();
        if (this.props.updateCriteriaValue) {
            this.props.updateCriteriaValue(
                Object.assign({pageSize: this.state.pageSize, pageNumber: this.state.currentPage + 1})
            );
        } else if (this.state.currentOrder && !this.props.pagination) {
            this.processSort(this.state.currentOrder, this.state.reverseOrder);
        }
        this.props.setClearSelectionFunction && this.props.setClearSelectionFunction(() => this.selectAll(false));
    }

    componentDidUpdate(prevProps) {
        if (this.props.timestamp !== prevProps.timestamp) {
            const currentPage = this.props.pageNumber ? this.props.pageNumber - 1 : PAGE_BEGIN;
            let storedCurrentFields;
            if (this.props.forceUpdateCurrentFields) {
                storedCurrentFields = localStorage.getItem(`${this.props.tableName}SearchColumns`);
                if (storedCurrentFields && storedCurrentFields !== 'undefined') {
                    storedCurrentFields = JSON.parse(storedCurrentFields);
                }
            }
            this.setState(
                Object.assign(
                    {},
                    {currentPage, currentData: this.props.tableSource},
                    this.props.defaultOrder && {currentOrder: this.props.defaultOrder},
                    this.props.reverseOrder && {reverseOrder: this.props.reverseOrder},
                    this.props.pageSize && {pageSize: this.props.pageSize},
                    !this.props.resultFieldsAll &&
                        this.props.forceUpdateResultFieldsDefault && {currentFields: this.props.resultFieldsDefault},
                    this.props.forceUpdateCurrentFields && {
                        currentFields: storedCurrentFields || this.props.resultFieldsDefault,
                    }
                ),
                () => {
                    this.calculateCurrentDataHash();
                    if (this.state.currentOrder && !this.props.pagination) {
                        this.state.currentData && this.processSort(this.state.currentOrder, this.state.reverseOrder);
                    }
                }
            );
        }
        if (
            this.props.infiniteScrollEnabled &&
            (prevProps.tableSource || []).length !== (this.props.tableSource || []).length
        ) {
            this.setState({
                infiniteScrollPages: 0,
            });
        }
        if (this.props.highlightSelectEnabled) {
            if (this.props.highlightSelectUpdate && [1, -1].includes(this.props.highlightSelectUpdate)) {
                if (
                    this.props.highlightSelectUpdate === 1 &&
                    this.state.highlightIndex < (this.state.currentData || []).length - 1
                ) {
                    this.setState(
                        prev => ({
                            highlightIndex: prev.highlightIndex + 1,
                        }),
                        () => this.props.setHighlightIndex && this.props.setHighlightIndex(this.state.highlightIndex)
                    );
                }
                if (this.props.highlightSelectUpdate === -1 && this.state.highlightIndex > 0) {
                    this.setState(
                        prev => ({
                            highlightIndex: prev.highlightIndex - 1,
                        }),
                        () => this.props.setHighlightIndex && this.props.setHighlightIndex(this.state.highlightIndex)
                    );
                }
                this.props.setHighlightSelectUpdate(0);
            }
            if ((prevProps.tableSource || []).length !== (this.props.tableSource || []).length) {
                this.setState({
                    highlightIndex: 0,
                });
                this.props.setHighlightIndex && this.props.setHighlightIndex(0);
            }
        }
        if (
            this.props.selectedElements !== undefined &&
            this.props.selectedElements !== null &&
            this.props.selectedElements !== prevProps.selectedElements &&
            this.props.selectedElements === 0
        ) {
            this.setState({selectedElementsHash: {}});
        }
        if (this.props.timestampSelected !== prevProps.timestampSelected) {
            this.setState({selectedElementsHash: this.props.selectDefault || {}});
        }
    }

    calculateCurrentDataHash = () => {
        if (this.props.id && this.props.selectable) {
            (this.state.currentData || []).forEach(row => {
                const calculatedIndex = [];
                (Array.isArray(this.props.id) ? this.props.id : [this.props.id]).forEach(index =>
                    calculatedIndex.push(row[index])
                );
                this.hashCurrentData[calculatedIndex.join('-')] = row;
            });
        }
    };

    processSort = (orderBy, reverse) => {
        LOG`processSort(${orderBy},${reverse})`;
        this.setState(prev => {
            const currentData = prev.currentData.sort((rowA, rowB) => {
                let a = rowA[orderBy];
                let b = rowB[orderBy];
                if (
                    this.props.sortFunctions &&
                    this.props.sortFunctions[orderBy] &&
                    this.props.sortFunctions[orderBy].normalize
                ) {
                    let normalizeFunction = this.props.sortFunctions[orderBy].normalize;
                    switch (normalizeFunction) {
                        case NORMALIZE_DATE:
                            normalizeFunction = normalizeDate;
                            break;
                        case NORMALIZE_DATE_EU:
                            normalizeFunction = normalizeDateEU;
                            break;
                        case NORMALIZE_NUMBER:
                            normalizeFunction = normalizeNumber;
                            break;
                    }
                    a = normalizeFunction(rowA, orderBy);
                    b = normalizeFunction(rowB, orderBy);
                }
                if (
                    this.props.sortFunctions &&
                    this.props.sortFunctions[orderBy] &&
                    this.props.sortFunctions[orderBy].sortFunction
                ) {
                    return (reverse ? -1 : 1) * this.props.sortFunctions[orderBy].sortFunction(rowA, rowB);
                } else {
                    a = a ? (isNaN(a) ? a.toUpperCase() : a) : 'NULL';
                    b = b ? (isNaN(b) ? b.toUpperCase() : b) : 'NULL';
                    return (reverse ? -1 : 1) * (a > b ? 1 : a < b ? -1 : 0);
                }
            });
            return {currentData};
        });
    };

    sortTable = sortBy => {
        if (
            this.props.notSortable &&
            Array.isArray(this.props.notSortable) &&
            this.props.notSortable.indexOf(sortBy) !== -1
        ) {
            return null;
        }
        LOG`sortTable: ${sortBy}`;
        const previousOrder = this.state.currentOrder;
        const previousReverseOrder = this.state.reverseOrder;
        if (sortBy === previousOrder) {
            const reverseOrder = !previousReverseOrder;
            this.setState({reverseOrder}, () => {
                if (this.props.pagination) {
                    this.props.updateCriteriaValue({order: sortBy, reverse: reverseOrder}, null, true);
                } else {
                    this.processSort(sortBy, reverseOrder);
                }
            });
        } else {
            this.setState({currentOrder: sortBy}, () => {
                if (this.props.pagination) {
                    this.props.updateCriteriaValue({order: sortBy, reverse: previousReverseOrder}, null, true);
                } else {
                    this.processSort(sortBy, previousReverseOrder);
                }
            });
        }
    };

    setPageSize = newPageSize => {
        this.setState(
            prev => {
                let currentPage = prev.currentPage;
                let currentDataLength = this.props.pagination ? this.props.count : prev.currentData.length;
                if (currentPage * newPageSize > currentDataLength) {
                    currentPage = Math.floor(currentDataLength / newPageSize);
                }
                return {currentPage, pageSize: newPageSize};
            },
            () => {
                localStorage.setItem(`pageSize${this.props.tableName}`, newPageSize);
                this.props.updateCriteriaValue &&
                    this.props.updateCriteriaValue(
                        {pageSize: newPageSize, pageNumber: this.state.currentPage + 1},
                        null,
                        true
                    );
            }
        );
    };

    giveMeSelectedElements = () => {
        return (Object.keys(this.state.selectedElementsHash) || [])
            .map(key => {
                return {key, value: this.state.selectedElementsHash[key]};
            })
            .filter(i => i.value === true)
            .map(i => i.key);
    };

    setPage = newPage => {
        this.setState(
            {currentPage: newPage},
            () =>
                this.props.updateCriteriaValue && this.props.updateCriteriaValue({pageNumber: newPage + 1}, null, true)
        );
    };

    fieldChooserOpen = () => {
        this.setState({fieldChooserOpened: true});
    };

    fieldChooserClose = () => {
        this.setState({fieldChooserOpened: false});
    };

    updateFields = currentFields => {
        this.setState(
            {
                fieldChooserOpened: false,
            },
            () => {
                localStorage.setItem(`${this.props.tableName}SearchColumns`, JSON.stringify(currentFields));
                this.setState({currentFields});
            }
        );
    };

    getFormatFunction = formatFunction => {
        let formatFunctionResult = formatFunction;
        switch (formatFunction) {
            case FORMAT_DATE:
                formatFunctionResult = formatDate;
                break;
            case FORMAT_DATE_EASY:
                formatFunctionResult = formatDateEasy;
                break;
            case FORMAT_DATE_TIME_EASY:
                formatFunctionResult = formatDateTimeEasy;
                break;
        }
        return formatFunctionResult;
    };

    selectAll = checked => {
        const selectedElementsHash = {};
        if (checked && this.props.id) {
            Object.keys(this.hashCurrentData).forEach(key => (selectedElementsHash[key] = true));
        }
        this.setState({selectedElementsHash, selectedAll: checked}, () =>
            this.props.onSelectedChange(this.giveMeSelectedElements())
        );
    };

    printExcel = () => {
        if (this.props.printExcel) {
            const excelTranslations = {};
            this.state.currentFields.forEach(headerKey => {
                excelTranslations[headerKey] = this.props.intl.formatMessage({
                    id: `table.${this.props.tableName}.${headerKey}`,
                });
            });
            this.props.printExcel(excelTranslations);
        } else {
            // SheetJS
            // https://github.com/SheetJS/js-xlsx/tree/master/demos/array
            //1. Get headers
            const resultGrid = [];
            const headerList = [];
            const currentFields = this.state.currentFields.slice(0);
            this.props.group && this.props.group.label && currentFields.unshift(this.props.group.label);
            const headerFields = this.props.resultFieldsAll.slice(0) || this.props.resultFieldsDefault.slice(0);
            this.props.group && this.props.group.label && headerFields.unshift(this.props.group.label);
            headerFields.forEach(fieldId => {
                if (currentFields.indexOf(fieldId) !== -1) {
                    let labelId = `excel.${this.props.tableName}.${fieldId}`;
                    let headerLabel = this.props.intl.formatMessage({id: labelId});
                    if (headerLabel === labelId) {
                        labelId = `table.${this.props.tableName}.${fieldId}`;
                        headerLabel = this.props.intl.formatMessage({id: labelId});
                    }
                    headerList.push(headerLabel);
                }
            });
            resultGrid.push(headerList);
            const selectedElementsHashTrueValues = {...this.state.selectedElementsHash};
            Object.entries(this.state.selectedElementsHash).forEach(
                ([key, value]) => value !== true && delete selectedElementsHashTrueValues[key]
            );
            //2. Get data
            (this.props.selectable && this.props.id && Object.keys(selectedElementsHashTrueValues || {}).length > 0
                ? this.state.currentData.filter(
                      i =>
                          selectedElementsHashTrueValues[
                              i[Array.isArray(this.props.id) ? this.props.id.join('-') : this.props.id]
                          ]
                  )
                : this.state.currentData
            ).forEach(rowObject => {
                const dataRow = [];
                currentFields.forEach(fieldId => {
                    const fieldData = rowObject[fieldId];
                    if (
                        fieldData &&
                        ((this.props.excelFormatFunctions && this.props.excelFormatFunctions[fieldId]) ||
                            (this.props.formatFunctions && this.props.formatFunctions[fieldId]))
                    ) {
                        const formatFunction = this.getFormatFunction(
                            (this.props.excelFormatFunctions && this.props.excelFormatFunctions[fieldId]) ||
                                (this.props.formatFunctions && this.props.formatFunctions[fieldId])
                        );
                        dataRow.push(formatFunction(fieldData, rowObject));
                    } else {
                        dataRow.push(fieldData || '');
                    }
                });
                resultGrid.push(dataRow);
            });
            const workSheet = XLSX.utils.aoa_to_sheet(resultGrid);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, this.props.tableName);
            XLSX.writeFile(workBook, `${this.props.tableName}.xlsx`);
        }
    };

    onSelectCheckboxChange = event => {
        const rowId = event.target.value;
        const checked = event.target.checked;
        LOG`rowId: ${rowId}`;
        this.setState(
            prev => {
                const selectedElementsHash = prev.selectedElementsHash;
                selectedElementsHash[rowId] = checked;
                return {selectedElementsHash};
            },
            () => this.props.onSelectedChange(this.giveMeSelectedElements())
        );
    };

    onSelectRow = rowId => {
        if (!this.props.selectionBusinessRule || this.props.selectionBusinessRule(rowId)) {
            this.setState(
                prev => {
                    const selectedElementsHash = this.props.onlyOneSelected ? {} : prev.selectedElementsHash;
                    if (this.props.group && this.props.group.uniqueKey) {
                        const {uniqueKey} = this.props.group;
                        const indexPosition = this.props.id.indexOf(uniqueKey);
                        const rowIdUniqueKeyValue = rowId.split('-')[indexPosition];
                        Object.keys(selectedElementsHash).forEach(key => {
                            if (key !== rowId && key.split('-')[indexPosition] === rowIdUniqueKeyValue) {
                                delete selectedElementsHash[key];
                            }
                        });
                    }
                    selectedElementsHash[rowId] = this.props.onlyOneSelected ? true : !selectedElementsHash[rowId];
                    return {selectedElementsHash};
                },
                () => this.props.onSelectedChange(this.giveMeSelectedElements())
            );
        }
    };

    onSelectAllRows = () => {
        this.setState(
            prev => {
                const checked = !prev.selectedAll;
                const selectedElementsHash = {};
                if (checked) {
                    prev.currentData.forEach(rowObject => {
                        selectedElementsHash[
                            rowObject[Array.isArray(this.props.id) ? this.props.id.join('-') : this.props.id]
                        ] = true;
                    });
                }
                return {selectedElementsHash, selectedAll: checked};
            },
            () => this.props.onSelectedChange(this.giveMeSelectedElements())
        );
    };

    onChangePageSize = event => {
        const pageSize = event.target.value;
        this.setPageSize(parseInt(pageSize));
    };

    onNavigationButtonCompactOrDefaultViewClick = () =>
        this.setState(
            prev => ({isNavigationButtonCompactViewActive: !prev.isNavigationButtonCompactViewActive}),
            () =>
                localStorage.setItem(
                    `compactView${this.props.tableName}`,
                    `${this.state.isNavigationButtonCompactViewActive}`
                )
        );

    onVisibilitySensorChange = () => this.setState(prev => ({infiniteScrollPages: prev.infiniteScrollPages + 1}));

    onRowMouseEnter = highlightIndex => {
        this.props.setHighlightIndex && this.props.setHighlightIndex(highlightIndex);
        this.setState({highlightIndex});
    };

    render() {
        let lastGroupId;
        const resultPageNumber = this.state.currentPage;
        let currentPage = resultPageNumber;
        const resultPageSize = this.state.pageSize;
        const maxCurrentRecords = (resultPageNumber + 1) * resultPageSize;
        const rowBegin = resultPageNumber * resultPageSize;
        const rowEnd = rowBegin + resultPageSize;
        const rowsCount = this.props.count || 0;
        const {currentFields, currentOrder, reverseOrder} = this.state;
        const maxPossiblePage = utilCalculateTableMaxPossiblePage({totalRows: rowsCount, pageSize: resultPageSize});
        const beginDisabled = currentPage === PAGE_BEGIN;
        const endDisabled = currentPage === maxPossiblePage - 1;
        const groupRange =
            (this.props.group && this.props.group.hash && this.props.group.hash.slice(rowBegin, rowEnd)) || [];
        const selectedNumber = Object.values(this.state.selectedElementsHash).filter(i => i !== false).length;
        const compactOrDefaultViewStyles = {
            paddingTop: this.state.isNavigationButtonCompactViewActive ? 3 : 17,
            paddingBottom: this.state.isNavigationButtonCompactViewActive ? 3 : 17,
        };

        return (
            <React.Fragment>
                <div
                    className="form-group"
                    style={Object.assign(
                        {},
                        {
                            textAlign: 'left',
                            paddingLeft: 15,
                            paddingRight: 15,
                            overflowX: 'visible',
                            marginTop: 20,
                        },
                        (this.props.mode === 'mailbox' || this.props.rowBorderLeft) && {
                            paddingLeft: 0,
                            paddingRight: 0,
                            marginTop: 0,
                        }
                    )}
                >
                    {this.props.tableSource !== null ? (
                        <React.Fragment>
                            {this.props.tableSource.length > 0 ? (
                                <React.Fragment>
                                    {this.state.fieldChooserOpened ? (
                                        <CustomTableModalFieldChooser
                                            currentFields={currentFields}
                                            resultFieldsAll={this.props.resultFieldsAll}
                                            resultFieldsDefault={this.props.resultFieldsDefault}
                                            close={this.fieldChooserClose}
                                            intl={this.props.intl}
                                            tableName={this.props.tableName}
                                            updateFields={this.updateFields}
                                        />
                                    ) : null}
                                    <div>
                                        <div>
                                            {<div>{this.props.topCaption}</div>}
                                            {this.props.version === 2 && (
                                                <div
                                                    className={cx(
                                                        styles.navigationBarV2,
                                                        this.props.navigationBarWider && styles.navigationBarWider
                                                    )}
                                                    style={
                                                        this.props.mode === 'mailbox' || this.props.rowBorderLeft
                                                            ? {
                                                                  marginLeft: 0,
                                                                  width: 'auto',
                                                                  paddingRight: 20,
                                                              }
                                                            : null
                                                    }
                                                >
                                                    <div
                                                        className={styles.navigationBarV2Left}
                                                        style={{...(this.props.styleNavigationBarV2Left || {})}}
                                                    >
                                                        {this.props.additionalNavigationButtonsRenderAtLeftSide &&
                                                            this.props.additionalNavigationButtons &&
                                                            this.props.additionalNavigationButtons()}
                                                        {this.props.resultFieldsAll && (
                                                            <div
                                                                className={styles.navigationBarV2LeftItem}
                                                                onClick={this.fieldChooserOpen}
                                                            >
                                                                <FontAwesomeIcon
                                                                    style={{float: 'left'}}
                                                                    icon={faColumns}
                                                                />
                                                                <div
                                                                    style={{float: 'left', paddingLeft: 5}}
                                                                >{`Field chooser`}</div>
                                                                <div style={{clear: 'both'}}>{}</div>
                                                            </div>
                                                        )}
                                                        {this.props.isNavigationButtonCompactOrDefaultViewEnabled && (
                                                            <div
                                                                className={styles.navigationBarV2LeftItem}
                                                                onClick={
                                                                    this.onNavigationButtonCompactOrDefaultViewClick
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    style={{float: 'left'}}
                                                                    icon={faThList}
                                                                />
                                                                <div
                                                                    style={{
                                                                        float: 'left',
                                                                        paddingLeft: 5,
                                                                    }}
                                                                >
                                                                    {this.state.isNavigationButtonCompactViewActive
                                                                        ? `Default View`
                                                                        : `Compact View`}
                                                                </div>
                                                                <div style={{clear: 'both'}}>{}</div>
                                                            </div>
                                                        )}
                                                        {!this.props.hideExcelButton && (
                                                            <div
                                                                className={styles.navigationBarV2LeftItem}
                                                                onClick={this.printExcel}
                                                            >
                                                                <FontAwesomeIcon
                                                                    style={{float: 'left'}}
                                                                    icon={faArrowCircleDown}
                                                                />
                                                                <div
                                                                    style={{float: 'left', paddingLeft: 5}}
                                                                >{`Download Excel`}</div>
                                                                <div style={{clear: 'both'}}>{}</div>
                                                            </div>
                                                        )}
                                                        {!this.props.additionalNavigationButtonsRenderAtLeftSide &&
                                                            this.props.additionalNavigationButtons &&
                                                            this.props.additionalNavigationButtons()}
                                                    </div>
                                                    <div className={styles.navigationBarV2Right}>
                                                        {this.props.headerSectionRight
                                                            ? this.props.headerSectionRight()
                                                            : null}
                                                        {!this.props.noShowingPageNumber && (
                                                            <React.Fragment>
                                                                <FontAwesomeIcon
                                                                    className={styles.navigationBarV2RightNavigator}
                                                                    icon={faChevronRight}
                                                                    onClick={() =>
                                                                        !endDisabled && this.setPage(++currentPage)
                                                                    }
                                                                />
                                                                <FontAwesomeIcon
                                                                    className={styles.navigationBarV2RightNavigator}
                                                                    icon={faChevronLeft}
                                                                    onClick={() =>
                                                                        !beginDisabled && this.setPage(--currentPage)
                                                                    }
                                                                />
                                                                <div className={styles.navigationBarV2RightResults}>
                                                                    {`Showing ${
                                                                        resultPageNumber * resultPageSize + 1
                                                                    } to ${
                                                                        maxCurrentRecords > this.props.count
                                                                            ? this.props.count
                                                                            : maxCurrentRecords
                                                                    } of ${this.props.count}`}
                                                                    {this.props.count === 'many' && (
                                                                        <div
                                                                            style={{
                                                                                display: 'inline-block',
                                                                                marginLeft: 5,
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon icon={faSpinner} spin />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                        {!this.props.noChangePageSize && (
                                                            <div className={styles.navigationBarV2RightRecordsPerPage}>
                                                                <div
                                                                    style={{
                                                                        float: 'right',
                                                                        paddingTop: 3,
                                                                        paddingLeft: 3,
                                                                    }}
                                                                >{` Records per page`}</div>
                                                                <select
                                                                    className={styles.selectRowNumber}
                                                                    value={resultPageSize}
                                                                    onChange={this.onChangePageSize}
                                                                >
                                                                    <option label="10" value={10}>
                                                                        10
                                                                    </option>
                                                                    <option label="25" value={25}>
                                                                        25
                                                                    </option>
                                                                    <option label="50" value={50}>
                                                                        50
                                                                    </option>
                                                                    <option label="100" value={100}>
                                                                        100
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div style={{clear: 'both'}}>{}</div>
                                                </div>
                                            )}
                                            {this.props.sectionBetweenHeaderAndTable
                                                ? this.props.sectionBetweenHeaderAndTable()
                                                : null}
                                            <div
                                                className="col-sm-12"
                                                data-ng-show="registerFound"
                                                style={{padding: 0}}
                                            >
                                                <div
                                                    style={Object.assign(
                                                        {},
                                                        {
                                                            marginLeft: 15,
                                                            marginRight: 15,
                                                            overflowX: 'auto',
                                                            overflowY: 'hidden',
                                                        },
                                                        (this.props.mode === 'mailbox' || this.props.rowBorderLeft) && {
                                                            marginLeft: 0,
                                                        },
                                                        this.props.scrollbarHorizontalHide === true && {
                                                            overflowX: 'hidden',
                                                        }
                                                    )}
                                                >
                                                    {this.props.minimalTableTitle ||
                                                    this.props.minimalTableExcelButton ? (
                                                        <div
                                                            style={{
                                                                background: '#f2f2f2',
                                                                padding: '7px 14px 7px 5px',
                                                                marginBottom: 7,
                                                            }}
                                                        >
                                                            {this.props.minimalTableTitle ? (
                                                                <>{this.props.minimalTableTitle}</>
                                                            ) : null}
                                                            {this.props.minimalTableExcelButton ? (
                                                                <FontAwesomeIcon
                                                                    icon={faArrowCircleDown}
                                                                    onClick={this.printExcel}
                                                                    style={{cursor: 'pointer', float: 'right'}}
                                                                />
                                                            ) : null}
                                                        </div>
                                                    ) : null}
                                                    <table
                                                        style={{
                                                            width: '100%',
                                                            maxWidth: 'none',
                                                            borderSpacing: 0,
                                                            textAlign: 'left',
                                                            position: 'relative',
                                                            borderCollapse: 'collapse',
                                                            fontSize: this.props.fontSize || 16,
                                                        }}
                                                    >
                                                        {this.props.hiddenHeader ? null : (
                                                            <thead>
                                                                <tr>
                                                                    {/*<th*/}
                                                                    {/*    className="col-md-1"*/}
                                                                    {/*    style={{width: 30, borderColor: 'rgb(0,0,0,0)', cursor: 'default'}}*/}
                                                                    {/*>*/}
                                                                    {/*    {}*/}
                                                                    {/*</th>*/}
                                                                    {this.props.selectable ? (
                                                                        <th
                                                                            style={{
                                                                                position: 'sticky',
                                                                                top: 0,
                                                                            }}
                                                                        >
                                                                            {this.props.mode !== 'mailbox' && (
                                                                                <React.Fragment>
                                                                                    {this.props.noSelectAll ? (
                                                                                        <React.Fragment>
                                                                                            {!this.props
                                                                                                .noUnselectAll && (
                                                                                                <React.Fragment>
                                                                                                    <a
                                                                                                        style={{
                                                                                                            whiteSpace:
                                                                                                                'nowrap',
                                                                                                            color: 'black',
                                                                                                        }}
                                                                                                    >
                                                                                                        {` Selected: ${selectedNumber}`}
                                                                                                    </a>
                                                                                                    <span>{` / `}</span>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                        <React.Fragment>
                                                                                            <a
                                                                                                onClick={() =>
                                                                                                    this.selectAll(true)
                                                                                                }
                                                                                                style={{
                                                                                                    whiteSpace:
                                                                                                        'nowrap',
                                                                                                    color: 'black',
                                                                                                }}
                                                                                            >
                                                                                                {` Select all`}
                                                                                            </a>
                                                                                            <span>{` / `}</span>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                    {!this.props.noUnselectAll && (
                                                                                        <a
                                                                                            onClick={() =>
                                                                                                this.selectAll(false)
                                                                                            }
                                                                                            style={{
                                                                                                whiteSpace: 'nowrap',
                                                                                                color: 'black',
                                                                                            }}
                                                                                        >
                                                                                            {` Unselect all`}
                                                                                        </a>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            )}
                                                                        </th>
                                                                    ) : null}
                                                                    {this.props.forehandColumn ? (
                                                                        <th className="col-md-1" style={{width: 30}}>
                                                                            {}
                                                                        </th>
                                                                    ) : null}
                                                                    {(
                                                                        this.props.customHeader ||
                                                                        currentFields ||
                                                                        []
                                                                    ).map(fieldId => {
                                                                        return (
                                                                            <th
                                                                                key={fieldId}
                                                                                // style={
                                                                                //     this.props.version === 2 && {
                                                                                //         whiteSpace: 'nowrap',
                                                                                //     }
                                                                                // }
                                                                                style={Object.assign(
                                                                                    {},
                                                                                    {textAlign: 'left'},
                                                                                    this.props.headerMargin && {
                                                                                        borderLeftStyle: 'solid',
                                                                                        borderLeftWidth: 1,
                                                                                        borderLeftColor: 'Gray',
                                                                                    }
                                                                                )}
                                                                            >
                                                                                {this.props.headerSizes &&
                                                                                this.props.headerSizes[fieldId] ? (
                                                                                    <div
                                                                                        style={{
                                                                                            width: this.props
                                                                                                .headerSizes[fieldId],
                                                                                        }}
                                                                                    >
                                                                                        {this.props.intl.formatMessage({
                                                                                            id: `table.${this.props.tableName}.${fieldId}`,
                                                                                        })}
                                                                                    </div>
                                                                                ) : (
                                                                                    <a
                                                                                        onClick={() =>
                                                                                            !this.props.customHeader &&
                                                                                            this.sortTable(fieldId)
                                                                                        }
                                                                                        style={Object.assign(
                                                                                            {
                                                                                                color: '#444',
                                                                                                fontWeight: 700,
                                                                                                whiteSpace: this.props
                                                                                                    .wrapHeaders
                                                                                                    ? 'normal'
                                                                                                    : 'nowrap',
                                                                                                paddingRight: 5,
                                                                                            },
                                                                                            this.props.notSortable &&
                                                                                                Array.isArray(
                                                                                                    this.props
                                                                                                        .notSortable
                                                                                                ) &&
                                                                                                this.props.notSortable.indexOf(
                                                                                                    fieldId
                                                                                                ) !== -1 && {
                                                                                                    pointerEvents:
                                                                                                        'none',
                                                                                                }
                                                                                        )}
                                                                                    >
                                                                                        {this.props.customHeader
                                                                                            ? fieldId
                                                                                            : this.props.intl.formatMessage(
                                                                                                  {
                                                                                                      id: `table.${this.props.tableName}.${fieldId}`,
                                                                                                  }
                                                                                              )}
                                                                                        {(!this.props.customHeader &&
                                                                                            currentOrder === fieldId &&
                                                                                            (reverseOrder ? (
                                                                                                <FontAwesomeIcon
                                                                                                    style={{
                                                                                                        marginLeft: 5,
                                                                                                    }}
                                                                                                    icon={faChevronUp}
                                                                                                />
                                                                                            ) : (
                                                                                                <FontAwesomeIcon
                                                                                                    style={{
                                                                                                        marginLeft: 5,
                                                                                                    }}
                                                                                                    icon={faChevronDown}
                                                                                                />
                                                                                            ))) ||
                                                                                            null}
                                                                                    </a>
                                                                                )}
                                                                                {this.props.headerPopup &&
                                                                                this.props.headerPopup[fieldId] ? (
                                                                                    <a
                                                                                        title={
                                                                                            this.props.headerPopup[
                                                                                                fieldId
                                                                                            ].description
                                                                                        }
                                                                                        className="btn-link pull-right"
                                                                                        onClick={
                                                                                            this.props.headerPopup[
                                                                                                fieldId
                                                                                            ].handler
                                                                                        }
                                                                                        style={{
                                                                                            float:
                                                                                                this.props
                                                                                                    .floatHeaderIcon ||
                                                                                                'right',
                                                                                            background:
                                                                                                'rgba(255, 255, 255, 0)',
                                                                                            fontSize: 12,
                                                                                            borderWidth: 2,
                                                                                            borderStyle: 'solid',
                                                                                            borderColor: 'green',
                                                                                            borderRadius: 10,
                                                                                            width: 13,
                                                                                            height: 17,
                                                                                            paddingLeft: 3,
                                                                                        }}
                                                                                    >
                                                                                        <FontAwesomeIcon
                                                                                            icon={faQuestion}
                                                                                            color="green"
                                                                                        />
                                                                                    </a>
                                                                                ) : null}
                                                                            </th>
                                                                        );
                                                                    })}
                                                                    {this.props.actions ? (
                                                                        <th
                                                                            colSpan={this.props.actions.length}
                                                                            className="text-center"
                                                                            style={{width: 30}}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontWeight: 800,
                                                                                    color: 'black',
                                                                                }}
                                                                            >
                                                                                {this.props.actionName || ''}
                                                                                {this.props.showAdminIcon ? (
                                                                                    <AdminIcon />
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </span>
                                                                        </th>
                                                                    ) : null}
                                                                </tr>
                                                            </thead>
                                                        )}
                                                        <tbody
                                                            className={cx(
                                                                this.props.evenRowBackground &&
                                                                    styles.evenRowBackground,
                                                                this.props.whiteSpaceNoWrap && styles.whiteSpaceNoWrap
                                                            )}
                                                        >
                                                            {/*{this.props.selectable && this.props.rowAllLabel ? (*/}
                                                            {/*    <tr onClick={this.onSelectAllRows}>*/}
                                                            {/*        <td*/}
                                                            {/*            colSpan="8"*/}
                                                            {/*            className={cx(*/}
                                                            {/*                'table-group-row',*/}
                                                            {/*                styles.selectAllRow*/}
                                                            {/*            )}*/}
                                                            {/*        >*/}
                                                            {/*            <input*/}
                                                            {/*                type="checkbox"*/}
                                                            {/*                checked={this.state.selectedAll}*/}
                                                            {/*                onChange={() => {}}*/}
                                                            {/*            />*/}
                                                            {/*            <span*/}
                                                            {/*                className={cx(*/}
                                                            {/*                    'ng-binding',*/}
                                                            {/*                    styles.selectAllRow*/}
                                                            {/*                )}*/}
                                                            {/*            >*/}
                                                            {/*                {this.props.rowAllLabel}*/}
                                                            {/*            </span>*/}
                                                            {/*        </td>*/}
                                                            {/*    </tr>*/}
                                                            {/*) : null}*/}
                                                            {(this.props.pagination || this.props.unlimited
                                                                ? this.state.currentData || []
                                                                : this.props.group &&
                                                                  this.props.group.hash &&
                                                                  this.props.group.id
                                                                ? this.state.currentData.filter(dataRow => {
                                                                      return typeof dataRow[this.props.group.id] ===
                                                                          'number'
                                                                          ? groupRange.indexOf(
                                                                                dataRow[this.props.group.id].toString()
                                                                            ) !== -1
                                                                          : groupRange.indexOf(
                                                                                dataRow[this.props.group.id]
                                                                            ) !== -1;
                                                                  })
                                                                : (this.state.currentData || []).slice(
                                                                      rowBegin,
                                                                      rowEnd
                                                                  ) || []
                                                            )
                                                                .slice(
                                                                    0,
                                                                    this.props.infiniteScrollEnabled
                                                                        ? INFINITE_SCROLL_ELEMENTS *
                                                                              (this.state.infiniteScrollPages + 1)
                                                                        : (this.state.currentData || []).length
                                                                )
                                                                .map((rowElement, index) => {
                                                                    const dataRowList = [];
                                                                    const rowId = Array.isArray(this.props.id)
                                                                        ? this.props.id.reduce((prev, current) => {
                                                                              return `${rowElement[prev]}-${rowElement[current]}`;
                                                                          })
                                                                        : rowElement[this.props.id];
                                                                    // dataRowList.push(
                                                                    //     <td
                                                                    //         className={styles.transparentCell}
                                                                    //         style={{borderColor: 'rgb(0,0,0,0)', cursor: 'default'}}
                                                                    //         onClick={event => event.stopPropagation()}
                                                                    //     >
                                                                    //         {this.props.warning && this.props.warning(rowElement)}
                                                                    //     </td>
                                                                    // );
                                                                    if (this.props.selectable) {
                                                                        dataRowList.push(
                                                                            <td
                                                                                key={`td-${rowId}`}
                                                                                className={cx(
                                                                                    styles.transparentCell,
                                                                                    styles.cellData
                                                                                )}
                                                                                style={{
                                                                                    width:
                                                                                        this.props.noSelectAll &&
                                                                                        this.props.noUnselectAll
                                                                                            ? 25
                                                                                            : this.props.mode !==
                                                                                                  'mailbox' ||
                                                                                              this.props.rowBorderLeft
                                                                                            ? 100
                                                                                            : null,
                                                                                    cursor: 'pointer',
                                                                                    ...compactOrDefaultViewStyles,
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    {this.props.mode === 'mailbox' ? (
                                                                                        <div style={{fontSize: 25}}>
                                                                                            {this.state
                                                                                                .selectedElementsHash[
                                                                                                rowId
                                                                                            ] ? (
                                                                                                <div
                                                                                                    onClick={() =>
                                                                                                        this.onSelectRow(
                                                                                                            rowId
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <div
                                                                                                        style={{
                                                                                                            marginLeft: 5,
                                                                                                        }}
                                                                                                    >
                                                                                                        <FontAwesomeIcon
                                                                                                            icon={
                                                                                                                faCheckCircle
                                                                                                            }
                                                                                                            color={
                                                                                                                '#0078d4'
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div
                                                                                                    onClick={() =>
                                                                                                        this.onSelectRow(
                                                                                                            rowId
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {rowElement[
                                                                                                        'isSenderCPVO'
                                                                                                    ] ? (
                                                                                                        <div
                                                                                                            className={
                                                                                                                styles.CPVOIconContainer
                                                                                                            }
                                                                                                        >
                                                                                                            <div
                                                                                                                className={
                                                                                                                    styles.CPVOIcon
                                                                                                                }
                                                                                                            >
                                                                                                                &nbsp;
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <div
                                                                                                            style={{
                                                                                                                marginLeft: 5,
                                                                                                            }}
                                                                                                        >
                                                                                                            <FontAwesomeIcon
                                                                                                                icon={
                                                                                                                    faUser
                                                                                                                }
                                                                                                                color={
                                                                                                                    'gray'
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <td
                                                                                            key={`td-${rowId}`}
                                                                                            className={cx(
                                                                                                styles.transparentCell,
                                                                                                styles.cellData
                                                                                            )}
                                                                                            style={{
                                                                                                width:
                                                                                                    this.props
                                                                                                        .noSelectAll &&
                                                                                                    this.props
                                                                                                        .noUnselectAll
                                                                                                        ? 25
                                                                                                        : 100,
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                        >
                                                                                            {this.props
                                                                                                .onlyOneSelected ? (
                                                                                                <div
                                                                                                    style={{
                                                                                                        textAlign:
                                                                                                            'center',
                                                                                                        width: '100%',
                                                                                                    }}
                                                                                                >
                                                                                                    {this.state
                                                                                                        .selectedElementsHash[
                                                                                                        rowId
                                                                                                    ] && (
                                                                                                        <FontAwesomeIcon
                                                                                                            icon={
                                                                                                                faCheck
                                                                                                            }
                                                                                                            color={
                                                                                                                'green'
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                                </div>
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    {this.props
                                                                                                        .group ? null : (
                                                                                                        <input
                                                                                                            key={`input-${rowId}`}
                                                                                                            checked={
                                                                                                                !!this
                                                                                                                    .state
                                                                                                                    .selectedElementsHash[
                                                                                                                    rowId
                                                                                                                ]
                                                                                                            }
                                                                                                            type="checkbox"
                                                                                                            value={
                                                                                                                rowId
                                                                                                            }
                                                                                                            onChange={
                                                                                                                this
                                                                                                                    .onSelectCheckboxChange
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </td>
                                                                                    )}
                                                                                </div>
                                                                            </td>
                                                                        );
                                                                    }
                                                                    if (this.props.forehandColumn) {
                                                                        dataRowList.push(
                                                                            <td
                                                                                style={{
                                                                                    cursor: 'default',
                                                                                    width: 30,
                                                                                    ...compactOrDefaultViewStyles,
                                                                                }}
                                                                                className={cx(
                                                                                    styles.transparentCell,
                                                                                    styles.cellData
                                                                                )}
                                                                            >
                                                                                {this.props.useExpandedRow ? (
                                                                                    <FontAwesomeIcon
                                                                                        key={`expandedRow-${rowId}`}
                                                                                        icon={
                                                                                            this.props.showExpandedRow[
                                                                                                index
                                                                                            ]
                                                                                                ? faChevronDown
                                                                                                : faChevronRight
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    this.props.forehandColumn(
                                                                                        rowElement
                                                                                    )
                                                                                )}
                                                                            </td>
                                                                        );
                                                                    }
                                                                    currentFields.forEach(
                                                                        (fieldId, indexCurrentField) => {
                                                                            const fieldKey = `${rowId}-${indexCurrentField}`;
                                                                            let resultData = rowElement[fieldId];
                                                                            if (
                                                                                this.props.formatFunctions &&
                                                                                this.props.formatFunctions[fieldId]
                                                                            ) {
                                                                                const formatFunction =
                                                                                    this.getFormatFunction(
                                                                                        this.props.formatFunctions[
                                                                                            fieldId
                                                                                        ]
                                                                                    );
                                                                                resultData =
                                                                                    formatFunction(
                                                                                        resultData,
                                                                                        rowElement,
                                                                                        index
                                                                                    ) || '';
                                                                            }
                                                                            dataRowList.push(
                                                                                <td
                                                                                    key={fieldKey}
                                                                                    className={cx(
                                                                                        styles.transparentCell,
                                                                                        styles.cellData
                                                                                    )}
                                                                                    style={
                                                                                        this.props.rowClick ||
                                                                                        this.props.selectable
                                                                                            ? {
                                                                                                  cursor: 'pointer',
                                                                                                  ...compactOrDefaultViewStyles,
                                                                                              }
                                                                                            : {
                                                                                                  cursor: 'default',
                                                                                                  ...compactOrDefaultViewStyles,
                                                                                              }
                                                                                    }
                                                                                >
                                                                                    {resultData || ''}
                                                                                </td>
                                                                            );
                                                                        }
                                                                    );
                                                                    const actionList = [];
                                                                    (this.props.actions || []).forEach(
                                                                        (action, index) => {
                                                                            const key = `${rowId}-${index}`;
                                                                            const icon = action.icon(rowElement);
                                                                            icon &&
                                                                                actionList.push(
                                                                                    <td
                                                                                        key={key}
                                                                                        className={cx(
                                                                                            'text-center',
                                                                                            styles.transparentCell,
                                                                                            styles.cellData
                                                                                        )}
                                                                                        style={{
                                                                                            cursor: 'default',
                                                                                            width: 20,
                                                                                            textAlign: 'center',
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            onClick={event => {
                                                                                                if (action.handler) {
                                                                                                    event.stopPropagation();
                                                                                                    LOG`event.stopPropagation();`;
                                                                                                    action.handler(
                                                                                                        rowId,
                                                                                                        rowElement
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            title={action.title}
                                                                                            style={
                                                                                                icon
                                                                                                    ? {}
                                                                                                    : {
                                                                                                          cursor: 'default',
                                                                                                      }
                                                                                            }
                                                                                        >
                                                                                            {icon}
                                                                                        </span>
                                                                                    </td>
                                                                                );
                                                                        }
                                                                    );
                                                                    dataRowList.push(actionList);
                                                                    const key = `${rowId}-${index}`;
                                                                    let renderGroupTitle = false;
                                                                    if (
                                                                        this.props.group &&
                                                                        this.props.group.id &&
                                                                        lastGroupId !== rowElement[this.props.group.id]
                                                                    ) {
                                                                        renderGroupTitle = true;
                                                                        lastGroupId = rowElement[this.props.group.id];
                                                                    }
                                                                    let renderExpandedRow = false;
                                                                    if (
                                                                        this.props.useExpandedRow &&
                                                                        this.props.showExpandedRow &&
                                                                        this.props.showExpandedRow[index]
                                                                    ) {
                                                                        renderExpandedRow = true;
                                                                    }
                                                                    return (
                                                                        <React.Fragment>
                                                                            {renderGroupTitle ? (
                                                                                <tr
                                                                                    onClick={() =>
                                                                                        !this.props.group ||
                                                                                        (this.props.group &&
                                                                                            !this.props.group
                                                                                                .noSelectChildren &&
                                                                                            this.onSelectRow(
                                                                                                this.props.group &&
                                                                                                    this.props.group
                                                                                                        .id &&
                                                                                                    rowElement[
                                                                                                        this.props.group
                                                                                                            .id
                                                                                                    ]
                                                                                            ))
                                                                                    }
                                                                                    style={
                                                                                        this.props.selectable
                                                                                            ? {cursor: 'pointer'}
                                                                                            : {cursor: 'default'}
                                                                                    }
                                                                                >
                                                                                    <td
                                                                                        colSpan="20"
                                                                                        className={cx(
                                                                                            'table-group-row',
                                                                                            styles.selectAllRow
                                                                                        )}
                                                                                        style={{
                                                                                            paddingLeft: 5,
                                                                                            color: 'white',
                                                                                        }}
                                                                                    >
                                                                                        <input
                                                                                            key={`input-${rowId}`}
                                                                                            checked={
                                                                                                !!this.state
                                                                                                    .selectedElementsHash[
                                                                                                    rowId
                                                                                                ]
                                                                                            }
                                                                                            type="checkbox"
                                                                                            value={rowId}
                                                                                            onChange={
                                                                                                this
                                                                                                    .onSelectCheckboxChange
                                                                                            }
                                                                                            className="ng-pristine ng-untouched ng-valid ng-empty"
                                                                                            style={{
                                                                                                display: 'inline-block',
                                                                                                marginRight: 5,
                                                                                            }}
                                                                                        />
                                                                                        <div
                                                                                            className={cx(
                                                                                                'ng-binding',
                                                                                                styles.selectAllRow
                                                                                            )}
                                                                                            style={{
                                                                                                display: 'inline-block',
                                                                                            }}
                                                                                        >
                                                                                            {this.props.group &&
                                                                                                this.props.group
                                                                                                    .label &&
                                                                                                !this.props
                                                                                                    .useExpandedRow && (
                                                                                                    <span
                                                                                                        /* eslint-disable-next-line react/no-danger */
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: rowElement[
                                                                                                                this
                                                                                                                    .props
                                                                                                                    .group
                                                                                                                    .label
                                                                                                            ],
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                            {this.props.group &&
                                                                                                this.props.group
                                                                                                    .label &&
                                                                                                this.props
                                                                                                    .useExpandedRow && (
                                                                                                    <DataSheetFormFields
                                                                                                        label={
                                                                                                            undefined
                                                                                                        }
                                                                                                        mandatory={
                                                                                                            undefined
                                                                                                        }
                                                                                                        html={renderHTML(
                                                                                                            rowElement[
                                                                                                                this
                                                                                                                    .props
                                                                                                                    .group
                                                                                                                    .label
                                                                                                            ]
                                                                                                        )}
                                                                                                        loading={false}
                                                                                                        disabled={true}
                                                                                                        double={true}
                                                                                                        containerClassName={
                                                                                                            undefined
                                                                                                        }
                                                                                                        labelIcon={
                                                                                                            undefined
                                                                                                        }
                                                                                                        labelPopup={
                                                                                                            undefined
                                                                                                        }
                                                                                                        data={undefined}
                                                                                                        buttonIcon={
                                                                                                            undefined
                                                                                                        }
                                                                                                        buttonAction={
                                                                                                            undefined
                                                                                                        }
                                                                                                        labelIconEmpty={
                                                                                                            undefined
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ) : null}
                                                                            <tr
                                                                                key={key}
                                                                                onClick={e => {
                                                                                    if (
                                                                                        this.props.selectable &&
                                                                                        this.props.rowClick &&
                                                                                        this.props.rowBorderLeft
                                                                                    ) {
                                                                                        if (
                                                                                            e.target.nodeName ===
                                                                                                'INPUT' ||
                                                                                            e.target.innerHTML.includes(
                                                                                                'type="checkbox"'
                                                                                            )
                                                                                        ) {
                                                                                            return this.onSelectRow(
                                                                                                rowId
                                                                                            );
                                                                                        } else {
                                                                                            this.props.rowClick(
                                                                                                rowId,
                                                                                                rowElement
                                                                                            );
                                                                                        }
                                                                                    } else {
                                                                                        if (
                                                                                            this.props.selectable &&
                                                                                            this.props.mode !==
                                                                                                'mailbox'
                                                                                        ) {
                                                                                            this.onSelectRow(rowId);
                                                                                        } else if (
                                                                                            this.props.rowClick
                                                                                        ) {
                                                                                            this.props.rowClick(
                                                                                                rowId,
                                                                                                rowElement
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                className={cx(
                                                                                    styles.rowData,
                                                                                    this.props.rowClick
                                                                                        ? 'hand-cursor'
                                                                                        : null,
                                                                                    this.props.rowClass
                                                                                        ? this.props.rowClass(
                                                                                              rowElement
                                                                                          )
                                                                                        : styles.backgroundWhite,
                                                                                    this.props.hover
                                                                                        ? styles.hover
                                                                                        : null,
                                                                                    this.props.hoverGrey &&
                                                                                        styles.hoverGrey,
                                                                                    this.props.highlightSelectEnabled &&
                                                                                        this.state.highlightIndex ===
                                                                                            index
                                                                                        ? styles.highlighted
                                                                                        : null
                                                                                )}
                                                                                style={Object.assign(
                                                                                    {},
                                                                                    this.props.bold &&
                                                                                        this.props.bold(rowElement)
                                                                                        ? {fontWeight: 800}
                                                                                        : {},
                                                                                    this.props.bold &&
                                                                                        (this.props.mode ===
                                                                                            'mailbox' ||
                                                                                            this.props.rowBorderLeft) &&
                                                                                        (this.props.bold(rowElement)
                                                                                            ? {
                                                                                                  // borderTop: 1,
                                                                                                  // borderTopStyle: 'solid',
                                                                                                  // borderTopColor: 'gray',
                                                                                                  borderLeft: 5,
                                                                                                  borderLeftColor:
                                                                                                      '#0078d4',
                                                                                                  borderLeftStyle:
                                                                                                      'solid',
                                                                                              }
                                                                                            : {
                                                                                                  // borderTop: 1,
                                                                                                  // borderTopStyle: 'solid',
                                                                                                  // borderTopColor: 'gray',
                                                                                                  borderLeft: 5,
                                                                                                  borderLeftColor:
                                                                                                      'transparent',
                                                                                                  borderLeftStyle:
                                                                                                      'solid',
                                                                                              })
                                                                                )}
                                                                                onMouseEnter={
                                                                                    this.props.highlightSelectEnabled
                                                                                        ? () =>
                                                                                              this.onRowMouseEnter(
                                                                                                  index
                                                                                              )
                                                                                        : undefined
                                                                                }
                                                                            >
                                                                                {dataRowList}
                                                                            </tr>
                                                                            {renderExpandedRow ? (
                                                                                this.props.fullExpandedRow === false ? (
                                                                                    this.props.expandedRow(rowElement)
                                                                                ) : (
                                                                                    <tr
                                                                                        className={cx(
                                                                                            styles.extendedRowData,
                                                                                            this.props.rowClick
                                                                                                ? 'hand-cursor'
                                                                                                : null,
                                                                                            this.props.rowClass
                                                                                                ? this.props.rowClass(
                                                                                                      rowElement
                                                                                                  )
                                                                                                : styles.backgroundWhite
                                                                                        )}
                                                                                    >
                                                                                        <td
                                                                                            colSpan={dataRowList.length}
                                                                                            className={cx(
                                                                                                styles.transparentCell,
                                                                                                styles.cellData
                                                                                            )}
                                                                                        >
                                                                                            {this.props.expandedRow(
                                                                                                rowElement
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            ) : null}
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                        </tbody>
                                                    </table>
                                                    {this.props.infiniteScrollEnabled &&
                                                    this.state.currentData.length -
                                                        INFINITE_SCROLL_ELEMENTS *
                                                            (this.state.infiniteScrollPages + 1) >
                                                        0 ? (
                                                        <Waypoint
                                                            onEnter={this.onVisibilitySensorChange}
                                                            fireOnRapidScroll={true}
                                                            topOffset={`100px`}
                                                        >
                                                            <div
                                                                style={{
                                                                    height:
                                                                        (this.state.currentData.length -
                                                                            INFINITE_SCROLL_ELEMENTS *
                                                                                (this.state.infiniteScrollPages + 1)) *
                                                                        27,
                                                                }}
                                                            />
                                                        </Waypoint>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.version === 2 && (
                                            <div
                                                className={cx(
                                                    styles.navigationBarV2,
                                                    this.props.navigationBarWider && styles.navigationBarWider
                                                )}
                                                style={Object.assign(
                                                    {background: 'white'},
                                                    (this.props.mode === 'mailbox' || this.props.rowBorderLeft) && {
                                                        marginLeft: 0,
                                                        width: 'auto',
                                                        paddingRight: 20,
                                                    }
                                                )}
                                            >
                                                {!this.props.noShowingPageNumber && (
                                                    <React.Fragment>
                                                        <FontAwesomeIcon
                                                            style={{marginLeft: 15, float: 'right', cursor: 'pointer'}}
                                                            icon={faChevronRight}
                                                            onClick={() => !endDisabled && this.setPage(++currentPage)}
                                                        />
                                                        <FontAwesomeIcon
                                                            style={{marginLeft: 15, float: 'right', cursor: 'pointer'}}
                                                            icon={faChevronLeft}
                                                            onClick={() =>
                                                                !beginDisabled && this.setPage(--currentPage)
                                                            }
                                                        />
                                                        <div style={{marginLeft: 25, float: 'right'}}>{`Showing ${
                                                            resultPageNumber * resultPageSize + 1
                                                        } to ${
                                                            maxCurrentRecords > this.props.count
                                                                ? this.props.count
                                                                : maxCurrentRecords
                                                        } of ${this.props.count}`}</div>
                                                    </React.Fragment>
                                                )}
                                                {!this.props.noChangePageSize && (
                                                    <div style={{marginLeft: 25, float: 'right', marginTop: -4}}>
                                                        <div
                                                            style={{float: 'right', paddingTop: 3, paddingLeft: 3}}
                                                        >{` Records per page`}</div>
                                                        <select
                                                            className={styles.selectRowNumber}
                                                            value={resultPageSize}
                                                            onChange={this.onChangePageSize}
                                                        >
                                                            <option label="10" value={10}>
                                                                10
                                                            </option>
                                                            <option label="25" value={25}>
                                                                25
                                                            </option>
                                                            <option label="50" value={50}>
                                                                50
                                                            </option>
                                                            <option label="100" value={100}>
                                                                100
                                                            </option>
                                                        </select>
                                                    </div>
                                                )}
                                                <div style={{clear: 'both'}}>{}</div>
                                            </div>
                                        )}
                                        {this.props.bottomCaption}
                                    </div>
                                    <div style={{clear: 'both'}}>{}</div>
                                </React.Fragment>
                            ) : this.props.showNoResultFound !== undefined &&
                              this.props.showNoResultFound === false ? null : (
                                <>
                                    {this.props.sectionBetweenHeaderAndTable &&
                                        this.props.sectionBetweenHeaderAndTable()}
                                    <div
                                        style={{
                                            color: 'red',
                                            fontWeight: 800,
                                            fontSize: 20,
                                        }}
                                    >{`No result found`}</div>
                                </>
                            )}
                        </React.Fragment>
                    ) : null}
                </div>
            </React.Fragment>
        );
    }
}
