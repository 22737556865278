import React from 'react';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {faArchive, faExclamation} from '@fortawesome/free-solid-svg-icons';
import CustomTable from '~components/CustomTable';
import DateInput from '~components/DateInput';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {FORMAT_DATE_TIME_EASY, reformatDateEasy} from '~components/FormatFunctions';
import {getDecodedJWT, loadUrlParams} from '~utils';
import ModalCommunicationCenterDocumentList from '../../shared/ModalCommunicationCentreDocumentList';
import CCModalClientCompose from '../CCModalClientCompose/CCModalClientCompose';
import CCModalClientHistory from '../CCModalClientHistory/CCModalClientHistory';
import CCModalClientRead from '../CCModalClientRead/CCModalClientRead';
import CCModalClientReply from '../CCModalClientReply/CCModalClientReply';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import CCClientSentActionButtons from './CCClientSentActionButtons';
import {
    apiCommunicationCentreClientSearchSent,
    apiCommunicationCentreClientSentMessageArchive,
} from './CCClientSentService';
import CPVOIcon from '../../shared/img/cpvo.svg';
import _COMMUNICATION_KEYS_TO_TYPE from '../CommunicationCentreClient/DATA/COMMUNICATION_KEYS_TO_TYPES.json';
import {ModalApplicationNumbers} from '../../commonModals';
import ApplicationNumbersMultiple from '../../components/ApplicationNumbersMultiple';
import {
    ICommunicationMessageClient,
    ICommunicationMessageClientHistory,
    ICommunicationMessagesClientSearchResults,
    IDecodedJWT,
    TCommunicationType,
} from '../../types';
import {communicationTypeHash} from '../CommunicationCentreClient/CommunicationCentreClient.constants';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
} from '../../componentsLayout';
import NavigationCommunicationCentre from '../../shared/NavigationCommunicationCentre';
import {Button} from '../../componentsFormV2';
import MainWrapper from '../../componentsLayout/MainWrapper';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {trackPageView} from '../../utils';

export const RESULT_FIELDS_DEFAULT_SENT = [
    'to',
    'communicationType',
    'documentType',
    'messageSubject',
    'applicationNumbers',
    'sentDate',
];

const DATEFORMAT_CRITERIA_KEYS = ['arrivalDateFrom', 'arrivalDateTo', 'notificationDateFrom', 'notificationDateTo'];

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

type TKeyOfDefaultCriteria = keyof TDefaultCriteria;

const DEFAULT_CRITERIA = {
    pageNumber: 1,
    pageSize: 10,
    order: 'sentDate',
    reverse: true,
    refresh: false,
    excel: false,
    searchTerm: '',
    applicationNumber: '',
    arrivalDateFrom: '',
    arrivalDateTo: '',
    notificationDateFrom: '',
    notificationDateTo: '',
    readingStatus: 'All',
    documentType: 'All',
    sendingDateFrom: '',
    sendingDateTo: '',
};

const documentTypes = [
    {id: 'All', value: 'All'},
    {id: 'Acknowledgements of receipt', value: 'Acknowledgements of receipt'},
    {id: 'Application - Other matters', value: 'Application - Other matters'},
    {id: 'Application documents', value: 'Application documents'},
    {id: 'Deed of assignment', value: 'Deed of assignment'},
    {id: 'Denomination - Other documents', value: 'Denomination - Other documents'},
    {id: 'Denomination Proposal', value: 'Denomination Proposal'},
    {id: 'Denomination objection/observations', value: 'Denomination objection/observations'},
    {id: 'Designation of procedural representative', value: 'Designation of procedural representative'},
    {id: 'Examination procedure', value: 'Examination procedure'},
    {id: 'Financial information', value: 'Financial information'},
    {id: 'Legal proceedings', value: 'Legal proceedings'},
    {id: 'Legal proceedings to be identified', value: 'Legal proceedings to be identified'},
    {id: 'Miscellaneous - Other matters', value: 'Miscellaneous - Other matters'},
    {id: 'Novelty', value: 'Novelty'},
    {id: 'Office decisions', value: 'Office decisions'},
    {id: 'Photo submitted by the applicant', value: 'Photo submitted by the applicant'},
    {id: 'Priority', value: 'Priority'},
    {id: 'Recordals in the register', value: 'Recordals in the register'},
    {id: 'Reports', value: 'Reports'},
    {id: 'Request for plant material', value: 'Request for plant material'},
    {id: 'Title of protection', value: 'Title of protection'},
    {id: 'Withdrawal/Surrender documents', value: 'Withdrawal/Surrender documents'},
];

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    communicationIdsSelected: any;
    criteria: TDefaultCriteria;
    count: number;
    isModalHistoryOpen: number | false;
    loading: number;
    messages: ICommunicationMessageClient[];
    modalApplicationNumbers: string[];
    modalClientMessageCompose: boolean;
    modalClientMessageRead: number | false;
    modalClientMessageReply: number | false;
    modalCPVOLogs: {
        communicationId: number | null;
        subject: string;
    };
    screenLoaded: boolean;
    timestamp: number;
}

class CCClientSent extends React.Component<IProps, IState> {
    decodedJWT: IDecodedJWT = getDecodedJWT();

    constructor(props: IProps) {
        super(props);
        this.state = {
            communicationIdsSelected: [],
            criteria: Object.assign({}, DEFAULT_CRITERIA),
            count: 0,
            isModalHistoryOpen: false,
            loading: 0,
            messages: [],
            modalApplicationNumbers: [],
            modalClientMessageCompose: false,
            modalClientMessageRead: false,
            modalClientMessageReply: false,
            modalCPVOLogs: {
                communicationId: null,
                subject: '',
            },
            screenLoaded: false,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'CommunicationCentreClientSent'});
        const criteria = loadUrlParams(DATEFORMAT_CRITERIA_KEYS);
        this.setState(
            prev => ({criteria: {...prev.criteria, ...criteria}, timestamp: Date.now()}),
            () => this.search(false, false, () => this.setState({screenLoaded: true}))
        );
    }

    goToScreen = (screen: string): void =>
        this.setState({criteria: {...DEFAULT_CRITERIA}}, () => {
            this.props.history.push(screen);
        });

    parseCriteria = (criteria: TDefaultCriteria): TDefaultCriteria => {
        const parsedCriteria: any = {};
        Object.keys(criteria).map(key => {
            let criteriaValue = criteria[key as TKeyOfDefaultCriteria];
            if (DATEFORMAT_CRITERIA_KEYS.indexOf(key) !== -1 && criteriaValue !== '') {
                parsedCriteria[key] = reformatDateEasy(criteriaValue);
            } else if (key === 'documentType') {
                parsedCriteria[key] = encodeURIComponent(criteriaValue);
            } else {
                parsedCriteria[key] = criteriaValue;
            }
        });
        return parsedCriteria as TDefaultCriteria;
    };

    buildParams = (parsedCriteria: TDefaultCriteria): void => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(
                i =>
                    parsedCriteria[i as TKeyOfDefaultCriteria] !== DEFAULT_CRITERIA[i as TKeyOfDefaultCriteria] &&
                    i !== 'refresh'
            )
            .map(key => `${key}=${parsedCriteria[key as TKeyOfDefaultCriteria]}`);
        this.props.history.replace(
            `/communicationCentreClientSent${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`
        );
    };

    search = (refresh?: boolean, urlLoad?: boolean, callBack?: () => any): void => {
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams(parsedCriteria);
                apiCommunicationCentreClientSearchSent(parsedCriteria, DEFAULT_CRITERIA)
                    .then((jsonResponse: ICommunicationMessagesClientSearchResults) => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.messages) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        messages: jsonResponse.data.messages,
                                        timestamp: Date.now(),
                                    },
                                    jsonResponse.data.count && {count: jsonResponse.data.count},
                                    !refresh && {selectedMessages: {}}
                                );
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`Register search list error: ${error.message}`]);
                    })
                    .then(() => {
                        this.setState(prev => ({loading: prev.loading - 1}));
                        callBack && callBack();
                    });
            }
        );
    };

    updateCriteriaValue = (criteriaValue: Partial<TDefaultCriteria>, callback?: () => any, refresh?: boolean): void => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = prev.criteria.order !== criteriaValue.order;
                reverseChanged = prev.criteria.reverse !== criteriaValue.reverse;
                return {criteria};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    openModalClientMessageRead = (communicationId: number): void =>
        this.setState({modalClientMessageRead: communicationId});

    closeModalClientMessageRead = () => this.setState({modalClientMessageRead: false}, () => this.search(true));

    closeModalClientMessageReply = () => this.setState({modalClientMessageReply: false}, () => this.search(true));

    openModalClientMessageCompose = () => this.setState({modalClientMessageCompose: true});

    closeModalClientMessageCompose = () => this.setState({modalClientMessageCompose: false}, () => this.search(true));

    closeModalClientMessageHistory = () => this.setState({isModalHistoryOpen: false}, () => this.search(true));

    closeModalCPVOLogs = () => this.setState({modalCPVOLogs: {communicationId: null, subject: ''}});

    openModalCPVOLogs = ({communicationId, subject}: {communicationId: number; subject: string}) =>
        this.search(false, false, () => {
            this.setState({
                modalCPVOLogs: {
                    communicationId,
                    subject,
                },
            });
        });

    getTableFormatFunctions = () => ({
        applicationNumbers: (applicationNumbers: string[]) => (
            <ApplicationNumbersMultiple
                applicationNumbers={applicationNumbers}
                openModalApplicationNumbers={this.openModalApplicationNumbers}
            />
        ),
        communicationType: (communicationType: TCommunicationType) => communicationTypeHash[communicationType],
        deemedService: FORMAT_DATE_TIME_EASY,
        documentType: (type: string, rowObject: ICommunicationMessageClient) => {
            const {docmanProfile, docmanDirectory, docmanSubtypeOfDocument} = rowObject || {};
            let key = `${docmanProfile}-${docmanDirectory}-${docmanSubtypeOfDocument}`;
            const COMMUNICATION_KEYS_TO_TYPE: {[key: string]: string} = _COMMUNICATION_KEYS_TO_TYPE;
            return COMMUNICATION_KEYS_TO_TYPE[key];
        },
        firstOpened: FORMAT_DATE_TIME_EASY,
        lastUpdateDate: FORMAT_DATE_TIME_EASY,
        notificationDate: FORMAT_DATE_TIME_EASY,
        receivedDate: FORMAT_DATE_TIME_EASY,
        sentDate: FORMAT_DATE_TIME_EASY,
        to: () => <img src={CPVOIcon} style={{width: 32, height: 32}} alt="CPVO logo" />,
    });

    formatTableMessage = (messages: (ICommunicationMessageClient | ICommunicationMessageClientHistory)[]) => {
        return (messages || []).map(el => ({
            ...el,
            lastUpdateDate: el.sentDate,
            receivedDate: el.sentDate,
            to: 'Community Plant Variety Office',
        }));
    };

    closeModalApplicationNumbers = () => this.setState({modalApplicationNumbers: []});

    openModalApplicationNumbers = (applicationNumbers: string[]) =>
        this.setState({modalApplicationNumbers: applicationNumbers});

    onTextInputSearchTermChange = ({target: {value: searchTerm}}: React.ChangeEvent<HTMLInputElement>): void =>
        this.updateCriteriaValue({searchTerm});

    onTextInputValueApplicationNumberChange = ({
        target: {value: textInputValueApplicationNumber},
    }: React.ChangeEvent<HTMLInputElement>): void =>
        this.updateCriteriaValue({applicationNumber: textInputValueApplicationNumber});

    onDateInputValueArrivalDateFromChange = (dateInputValueArrivalDateFrom: string) =>
        this.updateCriteriaValue({arrivalDateFrom: dateInputValueArrivalDateFrom});

    onDateInputValueArrivalDateToChange = (dateInputValueArrivalDateTo: string) =>
        this.updateCriteriaValue({arrivalDateTo: dateInputValueArrivalDateTo});

    onSelectInputValueReadingStatusChange = ({
        target: {value: selectInputValueReadingStatus},
    }: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({readingStatus: selectInputValueReadingStatus});

    onSelectInputDocumentTypeChange = ({target: {value: documentType}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({documentType});

    onDateInputValueNotificationDateFromChange = (dateInputValueNotificationDateFrom: string) =>
        this.updateCriteriaValue({notificationDateFrom: dateInputValueNotificationDateFrom});

    onDateInputValueNotificationDateToChange = (dateInputValueNotificationDateTo: string) =>
        this.updateCriteriaValue({notificationDateTo: dateInputValueNotificationDateTo});

    onTableIconHistoryClick = ({correlationId}: ICommunicationMessageClient) =>
        this.setState({isModalHistoryOpen: correlationId});

    onModalClientMessageReadButtonReplyClick = () =>
        this.setState(prev => ({
            modalClientMessageRead: false,
            modalClientMessageReply: prev.modalClientMessageRead,
        }));

    onClearFieldsClick = () =>
        this.setState(
            prev => {
                return {
                    criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
                };
            },
            () => this.search()
        );

    onTableIconArchiveClick = (communicationId: number) => {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                apiCommunicationCentreClientSentMessageArchive([communicationId]).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1}));
                    this.search(true);
                })
        );
    };

    onTableRowClick = (communicationId: number): void => this.openModalClientMessageRead(communicationId);

    onSelectedChange = (communicationIdsSelected: string[]): void => this.setState({communicationIdsSelected});

    onButtonArchiveSelectedClick = () => {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                apiCommunicationCentreClientSentMessageArchive(
                    this.state.communicationIdsSelected.map((el: string) => parseInt(el))
                ).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1, communicationIdsSelected: []}));
                    this.search(true);
                })
        );
    };

    render() {
        const actions = CCClientSentActionButtons(this.onTableIconArchiveClick, this.onTableIconHistoryClick);

        return (
            <>
                {this.state.modalClientMessageCompose && (
                    <CCModalClientCompose
                        close={this.closeModalClientMessageCompose}
                        intl={this.props.intl}
                        openModalCPVOLogs={this.openModalCPVOLogs}
                    />
                )}
                {this.state.modalClientMessageRead && (
                    <CCModalClientRead
                        close={this.closeModalClientMessageRead}
                        communicationId={this.state.modalClientMessageRead}
                        folder={`sent`}
                        onButtonReplyClick={this.onModalClientMessageReadButtonReplyClick}
                    />
                )}
                {this.state.modalClientMessageReply && (
                    <CCModalClientReply
                        close={this.closeModalClientMessageReply}
                        communicationId={this.state.modalClientMessageReply}
                        openModalCPVOLogs={this.openModalCPVOLogs}
                    />
                )}
                {this.state.isModalHistoryOpen ? (
                    <CCModalClientHistory
                        close={this.closeModalClientMessageHistory}
                        formatTableMessages={this.formatTableMessage}
                        correlationId={this.state.isModalHistoryOpen}
                        getTableFormatFunctions={this.getTableFormatFunctions}
                    />
                ) : null}
                {this.state.modalCPVOLogs && this.state.modalCPVOLogs.communicationId ? (
                    <ModalCommunicationCenterDocumentList
                        close={this.closeModalCPVOLogs}
                        communicationId={this.state.modalCPVOLogs.communicationId}
                        intl={this.props.intl}
                        subject={this.state.modalCPVOLogs.subject}
                        userMode={false}
                    />
                ) : null}
                {this.state.modalApplicationNumbers.length ? (
                    <ModalApplicationNumbers
                        applicationNumbers={this.state.modalApplicationNumbers}
                        close={this.closeModalApplicationNumbers}
                    />
                ) : null}
                {(this.state.loading !== 0 && <HeaderLoading />) || null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Communication Centre`} />
                <NavigationCommunicationCentre openModalMessageCompose={this.openModalClientMessageCompose} />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div>
                            <TextInput
                                label={`Search term`}
                                onChange={this.onTextInputSearchTermChange}
                                value={this.state.criteria.searchTerm}
                                onEnter={this.search}
                            />
                            <SelectInput
                                label={'Document type'}
                                value={this.state.criteria.documentType}
                                onChange={this.onSelectInputDocumentTypeChange}
                                list={documentTypes}
                                notAll={true}
                                disabled={false}
                            />
                            <TextInput
                                filter={'Starts with'}
                                placeholder={`e.g. 2015 or 20150001`}
                                label={`Application number(s)`}
                                onChange={this.onTextInputValueApplicationNumberChange}
                                value={this.state.criteria.applicationNumber}
                                onEnter={this.search}
                            />
                            <DateInput
                                label={'Sending date'}
                                changeDateFrom={this.onDateInputValueArrivalDateFromChange}
                                changeDateTo={this.onDateInputValueArrivalDateToChange}
                                inputValueFrom={this.state.criteria.arrivalDateFrom}
                                inputValueTo={this.state.criteria.arrivalDateTo}
                            />
                            <DateInput
                                label={`Notification date`}
                                changeDateFrom={this.onDateInputValueNotificationDateFromChange}
                                changeDateTo={this.onDateInputValueNotificationDateToChange}
                                inputValueFrom={this.state.criteria.notificationDateFrom}
                                inputValueTo={this.state.criteria.notificationDateTo}
                                disabled={true}
                            />
                            <SelectInput
                                label={`Reading Status`}
                                value={this.state.criteria.readingStatus}
                                onChange={this.onSelectInputValueReadingStatusChange}
                                list={[
                                    {id: '1', value: 'Read'},
                                    {id: '2', value: 'not read'},
                                ]}
                                disabled={true}
                            />
                        </div>
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            <Button
                                variation={'secondary'}
                                clickAction={this.onClearFieldsClick}
                            >{`Clear fields`}</Button>
                            <Button color={buttonColor.green} clickAction={() => this.search(false)}>{`Search`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    <div style={{marginTop: 20, marginBottom: 20}}>
                        {this.state.screenLoaded ? (
                            <>
                                {this.state.messages.length ? (
                                    <div style={{paddingRight: 15, marginBottom: 15}}>
                                        <FormFooterButton
                                            color={buttonColor.blue}
                                            clickAction={this.onButtonArchiveSelectedClick}
                                            icon={faArchive}
                                            disabled={!(this.state.communicationIdsSelected || []).length}
                                        >{`Archive selected`}</FormFooterButton>
                                        <div style={{clear: 'both'}} />
                                    </div>
                                ) : null}
                                <CustomTable
                                    actions={actions}
                                    count={this.state.count}
                                    dataFilter={null}
                                    defaultOrder={this.state.criteria.order}
                                    filterFunctions={null}
                                    formatFunctions={this.getTableFormatFunctions()}
                                    forehandColumn={({urgent}: any) =>
                                        urgent ? (
                                            <FontAwesomeIcon
                                                icon={faExclamation as any}
                                                fontSize={25}
                                                title={'Urgent'}
                                                color={'#810202'}
                                            />
                                        ) : null
                                    }
                                    hideExcelButton={true}
                                    id={'communicationId'}
                                    intl={this.props.intl}
                                    // mode={'mailbox'}
                                    notSortable={RESULT_FIELDS_DEFAULT_SENT}
                                    onSelectedChange={this.onSelectedChange}
                                    pagination={true}
                                    pageNumber={this.state.criteria.pageNumber}
                                    pageSize={this.state.criteria.pageSize}
                                    resultFieldsDefault={RESULT_FIELDS_DEFAULT_SENT}
                                    reverseOrder={this.state.criteria.reverse}
                                    rowBorderLeft={true}
                                    rowClick={this.onTableRowClick}
                                    selectable={true}
                                    setLastCursor={null}
                                    timestamp={this.state.timestamp}
                                    tableName={'communicationCentreClientSent'}
                                    tableType={'OBJECT'}
                                    tableSource={this.formatTableMessage(this.state.messages)}
                                    updateCriteriaValue={this.updateCriteriaValue}
                                    version={2}
                                />
                            </>
                        ) : null}
                    </div>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(CCClientSent));
