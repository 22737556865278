import React, {useState} from 'react';
import styles from './MyPVRPrivateConsultationFeesTopCaption.module.scss';
import {faChevronDown, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import InputLink from '~components/InputLink';
import getIcon from '~utils/icons';
import cx from 'classnames';

export default function MyPVRPrivateConsultationFeesTopCaption() {
    const [informationOpened, setInformationOpened] = useState(false);
    const toggleInformationBox = () => setInformationOpened(!informationOpened);

    return (
        <div className={styles.container}>
            <div style={{display: 'inline-block', maxWidth: 'calc(100vw - 230px)', marginRight: 20}}>
                <InputLink
                    label={`Information about credit notes`}
                    icon={informationOpened ? faChevronDown : faChevronRight}
                    clickAction={toggleInformationBox}
                />
                <div style={{clear: 'both'}}>{}</div>
                {informationOpened ? (
                    <div
                        style={{
                            borderStyle: 'solid',
                            borderWidth: 1,
                            borderColor: 'black',
                            padding: 5,
                            marginBottom: 5,
                            marginLeft: 5,
                        }}
                    >
                        {`Credit notes are not refunded automatically. Please deduct them from your future payments or ask for the refund via MyPVR by sending your company's bank account details and the references of the credit notes to be refunded `}
                    </div>
                ) : null}
                <div style={{marginLeft: 5}}>
                    <div style={{display: 'inline-block', fontWeight: 800, marginRight: 5}}>{'Warning:'}</div>
                    <div style={{display: 'inline-block'}}>{'Please note that only the fees with the pdf icon'}</div>
                    <div style={{display: 'inline-block'}}>
                        <img alt={`pdf icon`} src={getIcon('pdf')} style={{width: 20, height: 20}} />
                    </div>
                    <div style={{display: 'inline-block', marginLeft: 5}}>{'can be downloaded.'}</div>
                </div>
                <div style={{clear: 'both'}}>{}</div>
                <div style={{marginTop: 5}}>
                    <div className={cx(styles.label, styles.labelYellow)}>{`Less than 15 days before due date`}</div>
                    <div className={cx(styles.label, styles.labelRed)}>{`Due date reached`}</div>
                    <div className={cx(styles.label, styles.labelGreen)}>{`Credit notes`}</div>
                </div>
            </div>
            <div style={{clear: 'both'}}>{}</div>
        </div>
    );
}
