import React from 'react';
import styles from './Grid.module.scss';
import cx from 'classnames';

interface IGridProps {
    children: any;
    className?: any;
    mb?: 'sm' | 'md' | 'lg';
}

function Grid({children, className, mb}: IGridProps) {
    let stylesAppliedMb;

    const mbStyle: any = {
        sm: 'marginBottomSm',
        md: 'marginBottomMd',
        lg: 'marginBottomLg',
    };

    if (mb) {
        stylesAppliedMb = styles[mbStyle[mb]];
    }

    return <div className={cx(styles.grid, stylesAppliedMb, className)}>{children}</div>;
}

export default Grid;
