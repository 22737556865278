import crossFetch from 'cross-fetch';
import {getEnvironment} from '~utils';
import {getLocalStorageLogins} from '../screens/AuthenticationLogin/AuthenticationLogin.utils';

// let myPVRUrl = 'https://0b01d820-5ca7-437f-9736-b2d5dcd25ce7.mock.pstmn.io/api';
// let myPVRUrl = 'https://cpvointranet2.ocvv.angers/api';
// let myPVRUrl = 'https://cpvoextranet.cpvo.europa.eu/api';
// let myStatic = 'https://json.plantvarieties.eu/';
let tloCpvoBoxUploadZone = 'uploadTloDocuments';
let tloStaticDocsDataPath = '\\tlodocs\\';
// let tloMinimumAmountInvoice = 240;
const tloListOfTlosTreeValue = 'List of TLOs';
let myPVRUrl = 'https://node.plantvarieties.eu/api';
let myPVRUrlNode1 = 'https://node.plantvarieties.eu/api';
let myPVRUrlNode1proxy = '';
let myPVRUrlNode2 = 'https://node.plantvarieties.eu/api';
let myOnlineUrl = 'https://node.plantvarieties.eu/api.ocvv';
let myPVRServerless = 'https://online.plantvarieties.eu/api';
const postmanApiKey = 'PMAK-6294c10dcef2103bd54013a5-c8a2c56c4af32aa1ee3de74ec7de7f8e41';

const environment = getEnvironment();

const POSTMAN_MOCK_SERVER_ID = 'ee0bce81-b6ba-4834-b2d6-85ccf180ac04';

let apiEnvironment = 'PRO';
switch (environment) {
    case 'LOCALHOST':
        apiEnvironment = 'PRE';
        myPVRServerless = 'http://localhost:4000/local/api';
        break;
    case 'DEV':
        myPVRServerless = 'https://dev.plantvarieties.eu/api';
        apiEnvironment = 'PRE';
        break;
    case 'TEST':
        myPVRServerless = 'https://test.plantvarieties.eu/api';
        apiEnvironment = 'PRE';
        break;
    case 'DEMO':
        myPVRServerless = 'https://demo.plantvarieties.eu/api';
        apiEnvironment = 'PRE';
        break;
    case 'PRE':
        myPVRServerless = 'https://pre.plantvarieties.eu/api';
        apiEnvironment = 'PRE';
        break;
    case 'MOCK':
        myPVRUrl = `https://${POSTMAN_MOCK_SERVER_ID}.mock.pstmn.io/api`;
        myPVRUrlNode1 = `https://${POSTMAN_MOCK_SERVER_ID}.mock.pstmn.io/api`;
        myOnlineUrl = `https://${POSTMAN_MOCK_SERVER_ID}.mock.pstmn.io/api.ocvv`;
        break;
    case 'AC':
        myPVRServerless = 'https://ac.plantvarieties.eu/api';
        apiEnvironment = 'PRO';
        break;
    case 'CASELAW':
        myPVRServerless = 'https://caselaw.plantvarieties.eu/api';
        apiEnvironment = 'PRO';
        break;
    case 'ONLINE':
        myPVRServerless = 'https://online.plantvarieties.eu/api';
        apiEnvironment = 'PRO';
        break;
}

switch (process.env.SOURCEAPI) {
    // case 'mock':
    //     myPVRUrl = `https://${POSTMAN_MOCK_SERVER_ID}.mock.pstmn.io/api`;
    //     myPVRUrlNode1 = `https://${POSTMAN_MOCK_SERVER_ID}.mock.pstmn.io/api`;
    //     myOnlineUrl = `https://${POSTMAN_MOCK_SERVER_ID}.mock.pstmn.io/api.ocvv`;
    //     break;
    case 'dev':
        myPVRServerless = 'https://dev.plantvarieties.eu/api';
        apiEnvironment = 'PRE';
        break;
    case 'pre':
        myPVRServerless = 'https://pre.plantvarieties.eu/api';
        apiEnvironment = 'PRE';
        break;
    case 'pro':
        myPVRServerless = 'https://online.plantvarieties.eu/api';
        apiEnvironment = 'PRO';
        break;
    case 'standard':
        myPVRServerless = 'http://localhost:4000/local/api';
        apiEnvironment = 'PRE';
        break;
    case 'demo':
        myPVRServerless = 'https://demo.plantvarieties.eu/api';
        apiEnvironment = 'PRE';
}
self.apiEnvironment = apiEnvironment;
export const TLO_STATIC_DOCS_DATA_PATH = tloStaticDocsDataPath;
export const TLO_CPVO_BOX_UPLOAD_ZONE = tloCpvoBoxUploadZone;
export const TLO_LIST_OF_TLOS_TREE_VALUE = tloListOfTlosTreeValue;
export const API_MYPVR_URL = myPVRUrl;
export const API_MYPVR_URL_NODE1 = myPVRUrlNode1;
export const API_MYPVR_URL_NODE1_PROXY = myPVRUrlNode1proxy;
export const API_MYPVR_URL_NODE2 = myPVRUrlNode2;
export const API_ONLINE_URL = myOnlineUrl;
export const API_SERVERLESS = myPVRServerless;

function checkStatus(response, statusException) {
    if (response.ok || response.status === statusException) {
        return response;
    } else {
        LOG`${response.status} ${response.statusText}`;
        const localStorageLogins = getLocalStorageLogins();
        const storedLogin = localStorageLogins.length ? localStorageLogins[0].username : '';
        if (response.status === 401 && storedLogin !== '') {
            // unauthorised
            window.location.href = `/login?target=${window.location.href}`;
        } else {
            window.location.href = `/error?origin=${window.location.href}&status=${response.status}&error=${response.statusText}`;
        }
    }
}

function apiError(error) {
    //self.store.dispatch(addError(JSON.stringify(error.message)));
    //self.refreshScreens && self.refreshScreens();
    return Promise.reject(error);
}

export function postFetch(url, withCredentials, jsonData, headers = {}) {
    try {
        fetch('/message.html')
            .then(response => response.text())
            .then(responseText => (document.getElementById('cpvoMessage').innerHTML = responseText));
    } catch (e) {
        LOG`${e && e.message}`;
    }
    //
    let customHeader = Object.assign({'Content-Type': 'application/json'}, headers);
    if (self.DEV_MOVE || getEnvironment() === 'MOCK')
        customHeader = Object.assign(
            {
                'X-Api-Key': postmanApiKey,
            },
            customHeader
        );
    const ie = !!navigator.userAgent.match(/Trident\/7\./);
    let authToken = null;
    if (ie) {
        let prefix = '';
        switch (getEnvironment()) {
            case 'DEV':
                prefix = 'dev';
                break;
            case 'DEMO':
                prefix = 'uat';
                break;
            default:
                prefix = '';
                break;
        }
        document.cookie.split(';').forEach(cookie => {
            const cookieElements = cookie.split('=');
            if (
                cookieElements[0].trim() === `${prefix !== '' ? `${prefix}-` : ''}token` &&
                cookieElements[1] &&
                cookieElements[1] !== 'deleted'
            ) {
                authToken = cookieElements[1];
            }
        });
    } else {
        const remember = localStorage.getItem('remember') === '1';
        authToken = (remember ? localStorage : sessionStorage).getItem('token');
    }
    customHeader = Object.assign(customHeader, {environment: apiEnvironment});
    const urlPrefix = url.split('?')[0];
    const value = localStorage.getItem(urlPrefix);
    if (
        process.env.CACHE === 'true' &&
        value !== null &&
        (urlPrefix.split('?').length !== 2 ||
            (urlPrefix.split('?').length === 2 && urlPrefix.split('?')[1] !== 'mock=badpassword'))
    ) {
        return Promise.resolve(JSON.parse(value));
    }
    const postRequest = requestAuthToken => {
        if (
            withCredentials &&
            requestAuthToken &&
            requestAuthToken !== 'undefined' &&
            requestAuthToken !== 'null' &&
            requestAuthToken
        ) {
            customHeader = Object.assign(customHeader, {
                Authorization: `Bearer ${requestAuthToken}`,
            });
        }
        return (window.fetch || crossFetch)(
            url,
            Object.assign(
                {headers: customHeader},
                {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    redirect: 'follow',
                    referrer: 'no-referrer',
                    body: JSON.stringify(jsonData),
                }
            )
        );
    };
    return postRequest(authToken)
        .then(response => refreshTokenWhenNeeded(response, postRequest))
        .then(checkStatus)
        .then(response => response.json())
        .then(response => {
            if (response && response.error === 'You have to log in first.') {
                //self.store.dispatch(addError(`403 ${JSON.stringify(response.error)}`));
            }
            if (process.env.CACHE === 'true') {
                localStorage.setItem(urlPrefix, JSON.stringify(response));
            }
            return response;
        })
        .catch(apiError);
}

export function getFetch(url, withCredentials, headers = {}, responseType) {
    try {
        fetch('/message.html')
            .then(response => response.text())
            .then(responseText => (document.getElementById('cpvoMessage').innerHTML = responseText));
    } catch (e) {
        LOG`${e && e.message}`;
    }
    //
    let customHeader = Object.assign({'Content-Type': responseType || 'application/octet-stream'}, headers);
    if (self.DEV_MOVE || getEnvironment() === 'MOCK')
        customHeader = Object.assign(
            {
                'X-Api-Key': postmanApiKey,
            },
            customHeader
        );
    const ie = !!navigator.userAgent.match(/Trident\/7\./);
    let authToken = null;
    if (ie) {
        let prefix = '';
        switch (getEnvironment()) {
            case 'DEV':
                prefix = 'dev';
                break;
            case 'DEMO':
                prefix = 'uat';
                break;
            default:
                prefix = '';
                break;
        }
        document.cookie.split(';').forEach(cookie => {
            const cookieElements = cookie.split('=');
            if (
                cookieElements[0].trim() === `${prefix !== '' ? `${prefix}-` : ''}token` &&
                cookieElements[1] &&
                cookieElements[1] !== 'deleted'
            ) {
                authToken = cookieElements[1];
            }
        });
    } else {
        const remember = localStorage.getItem('remember') === '1';
        authToken = (remember ? localStorage : sessionStorage).getItem('token');
    }
    customHeader = Object.assign(customHeader, {environment: apiEnvironment});
    const urlPrefix = url.split('?')[0];
    const value = localStorage.getItem(urlPrefix);
    if (
        process.env.CACHE === 'true' &&
        value !== null &&
        (urlPrefix.split('?').length !== 2 ||
            (urlPrefix.split('?').length === 2 && urlPrefix.split('?')[1] !== 'mock=badpassword'))
    ) {
        return Promise.resolve(JSON.parse(value));
    }
    const getRequest = requestAuthToken => {
        if (
            withCredentials &&
            requestAuthToken &&
            requestAuthToken !== 'undefined' &&
            requestAuthToken !== 'null' &&
            requestAuthToken
        ) {
            customHeader = Object.assign(customHeader, {
                Authorization: `Bearer ${requestAuthToken}`,
            });
        }
        return (window.fetch || crossFetch)(
            url,
            Object.assign(
                {headers: customHeader},
                {
                    method: 'GET',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    redirect: 'follow',
                    referrer: 'no-referrer',
                }
            )
        );
    };
    return getRequest(authToken)
        .then(response => refreshTokenWhenNeeded(response, getRequest))
        .then(checkStatus)
        .then(response => response.json())
        .then(response => {
            if (response && response.error === 'You have to log in first.') {
                //self.store.dispatch(addError(`403 ${JSON.stringify(response.error)}`));
            }
            if (process.env.CACHE === 'true') {
                localStorage.setItem(urlPrefix, JSON.stringify(response));
            }
            return response;
        })
        .catch(apiError);
}

export function getFile(url, withCredentials, jsonData) {
    try {
        fetch('/message.html')
            .then(response => response.text())
            .then(responseText => (document.getElementById('cpvoMessage').innerHTML = responseText));
    } catch (e) {
        LOG`${e && e.message}`;
    }
    //
    let customHeader = Object.assign({environment: apiEnvironment});
    const ie = !!navigator.userAgent.match(/Trident\/7\./);
    let authToken = null;
    if (ie) {
        let prefix = '';
        switch (getEnvironment()) {
            case 'DEV':
                prefix = 'dev';
                break;
            case 'DEMO':
                prefix = 'uat';
                break;
            default:
                prefix = '';
                break;
        }
        document.cookie.split(';').forEach(cookie => {
            const cookieElements = cookie.split('=');
            if (
                cookieElements[0].trim() === `${prefix !== '' ? `${prefix}-` : ''}token` &&
                cookieElements[1] &&
                cookieElements[1] !== 'deleted'
            ) {
                authToken = cookieElements[1];
            }
        });
    } else {
        const remember = localStorage.getItem('remember') === '1';
        authToken = (remember ? localStorage : sessionStorage).getItem('token');
    }
    const fileRequest = requestAuthToken => {
        if (
            withCredentials &&
            requestAuthToken &&
            requestAuthToken !== 'undefined' &&
            requestAuthToken !== 'null' &&
            requestAuthToken
        ) {
            customHeader = Object.assign(customHeader, {
                Authorization: `Bearer ${requestAuthToken}`,
            });
        }
        return (window.fetch || crossFetch)(
            url,
            Object.assign(
                {headers: customHeader},
                {
                    method: jsonData ? 'POST' : 'GET',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    redirect: 'follow',
                    referrer: 'no-referrer',
                    body: jsonData ? JSON.stringify(jsonData) : null,
                }
            )
        );
    };
    return fileRequest(authToken)
        .then(response => refreshTokenWhenNeeded(response, fileRequest))
        .then(response => checkStatus(response, [404]))
        .then(response => {
            if (response.status === 404) {
                return {error: 404};
            } else {
                return response.blob();
            }
        })
        .catch(apiError);
}

export function postForm(url, withCredentials, formData, headers = {}) {
    try {
        fetch('/message.html')
            .then(response => response.text())
            .then(responseText => (document.getElementById('cpvoMessage').innerHTML = responseText));
    } catch (e) {
        LOG`${e && e.message}`;
    }
    //
    let customHeader = Object.assign({}, headers);
    if (self.DEV_MOVE || getEnvironment() === 'MOCK')
        customHeader = Object.assign(
            {
                'X-Api-Key': postmanApiKey,
            },
            customHeader
        );
    const ie = !!navigator.userAgent.match(/Trident\/7\./);
    let authToken = null;
    if (ie) {
        let prefix = '';
        switch (getEnvironment()) {
            case 'DEV':
                prefix = 'dev';
                break;
            case 'DEMO':
                prefix = 'uat';
                break;
            default:
                prefix = '';
                break;
        }
        document.cookie.split(';').forEach(cookie => {
            const cookieElements = cookie.split('=');
            if (
                cookieElements[0].trim() === `${prefix !== '' ? `${prefix}-` : ''}token` &&
                cookieElements[1] &&
                cookieElements[1] !== 'deleted'
            ) {
                authToken = cookieElements[1];
            }
        });
    } else {
        const remember = localStorage.getItem('remember') === '1';
        authToken = (remember ? localStorage : sessionStorage).getItem('token');
    }
    customHeader = Object.assign(customHeader, {environment: apiEnvironment});
    const urlPrefix = url.split('?')[0];
    const value = localStorage.getItem(urlPrefix);
    if (
        process.env.CACHE === 'true' &&
        value !== null &&
        (urlPrefix.split('?').length !== 2 ||
            (urlPrefix.split('?').length === 2 && urlPrefix.split('?')[1] !== 'mock=badpassword'))
    ) {
        return Promise.resolve(JSON.parse(value));
    }
    const formRequest = requestAuthToken => {
        if (
            withCredentials &&
            requestAuthToken &&
            requestAuthToken !== 'undefined' &&
            requestAuthToken !== 'null' &&
            requestAuthToken
        ) {
            customHeader = Object.assign(customHeader, {
                Authorization: `Bearer ${requestAuthToken}`,
            });
        }
        return (window.fetch || crossFetch)(
            url,
            Object.assign(
                {headers: customHeader},
                {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    redirect: 'follow',
                    referrer: 'no-referrer',
                    body: formData,
                }
            )
        );
    };
    return formRequest(authToken)
        .then(response => refreshTokenWhenNeeded(response, formRequest))
        .then(checkStatus)
        .then(response => response.json())
        .then(response => {
            if (response && response.error === 'You have to log in first.') {
                //self.store.dispatch(addError(`403 ${JSON.stringify(response.error)}`));
            }
            if (process.env.CACHE === 'true') {
                localStorage.setItem(urlPrefix, JSON.stringify(response));
            }
            return response;
        })
        .catch(apiError);
}

function refreshTokenWhenNeeded(response, request) {
    // new cloud system is now 401
    if (response.status === 403) {
        const remember = localStorage.getItem('remember') === '1';
        const refreshToken = (remember ? localStorage : sessionStorage).getItem('refresh-token');
        return (window.fetch || crossFetch)(`${API_SERVERLESS}/common/v3/refresh`, {
            headers: {Authorization: `Bearer ${refreshToken}`},
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrer: 'no-referrer',
        })
            .then(checkStatus)
            .then(refreshResponse => refreshResponse.json())
            .then(jsonRefreshResponse => {
                if (jsonRefreshResponse && jsonRefreshResponse.token) {
                    const ie = !!navigator.userAgent.match(/Trident\/7\./);
                    if (ie) {
                        document.cookie = `ie-token=${jsonRefreshResponse.token}`;
                    } else {
                        const remember = localStorage.getItem('remember') === '1';
                        (remember ? localStorage : sessionStorage).setItem('token', jsonRefreshResponse.token);
                    }
                }
                return request(jsonRefreshResponse && jsonRefreshResponse.token);
            });
    } else {
        return response;
    }
}
