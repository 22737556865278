import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {MyFruitSubmissionsPageTableAreaProps} from './props/MyFruitSubmissionsPageTableAreaProps';
import styles from './MyFruitSubmissionsPageTableArea.module.scss';
import {
    apiMyFruitSubmissionsPageSave,
    MyFruitSubmissionsPageFilterCriteriaData,
    MyFruitSubmissionsPageTableRowData,
} from '../../MyFruitSubmissionsPageService';
import CustomTable from '../../../../../../components/CustomTable';
import TextAreaInput from '~components/TextAreaInput';
import {faCheck, faRemoveFormat} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import SelectInput from '~components/SelectInput';
import Error from '~components/Error';
import CustomDatePicker from '../../../../../../componentsForm/CustomDatePicker';

const modalScreenTypes = {ERROR: 'ERROR'};

export default function MyFruitSubmissionsPageTableArea(props: MyFruitSubmissionsPageTableAreaProps) {
    const RESULT_FIELDS_DEFAULT = [
        'specieName',
        'registerId',
        'reference',
        'examinationReference',
        'materialSubmissionFrom',
        'materialSubmissionTo',
        'sampleAccepted',
        'deliveryDate',
        'comments',
    ];
    const MY_FRUIT_SUBMISSION_INITIAL = {
        registerId: '',
        reference: '',
        specieName: '',
        sampleAccepted: false,
        examinationReference: '',
        materialSubmissionFrom: '',
        materialSubmissionTo: '',
        deliveryDate: '',
        comments: '',
        isEdited: false,
    };

    const [myFruitSubmissions, setMyFruitSubmissions] = useState<Array<MyFruitSubmissionsPageTableRowData> | null>(
        props.myFruitSubmissions
    );
    const [timestamp, setTimestamp] = useState(props.timestamp);
    const [editedRow, setEditedRow] = useState<MyFruitSubmissionsPageTableRowData>(MY_FRUIT_SUBMISSION_INITIAL);

    const [rowCommentChanged, setRowCommentChanged] = useState(false);
    const [rowIsEdited, setRowIsEdited] = useState(false);
    const [, setLoading] = useState(false);
    const [, setErrorMessage] = useState<string | null>(null);
    const [, setModalScreen] = useState<string | null>(null);
    const [mandatoryCommentNotFilledForRegisterId, setMandatoryCommentNotFilled] = useState<string | null>(null);
    const [comments, setComments] = useState<any[]>([]);

    useEffect(() => {
        setMyFruitSubmissions(props.myFruitSubmissions);
        setComments((props.myFruitSubmissions || []).map(({comments}: any) => comments));
        setTimestamp(Date.now());
    }, [props.myFruitSubmissions]);

    useEffect(() => {
        if (rowCommentChanged) {
            if (myFruitSubmissions) {
                let myFruitSubmissionsNew = [...myFruitSubmissions];
                myFruitSubmissionsNew = myFruitSubmissionsNew.map(el => {
                    if (el.registerId === editedRow.registerId) {
                        el = editedRow;
                    }
                    return el;
                });
                setMyFruitSubmissions(myFruitSubmissionsNew);
                setMandatoryCommentNotFilled(mandatoryCommentNotFilled(myFruitSubmissionsNew));
                setTimestamp(Date.now());
                setRowCommentChanged(false);
            }
        } else {
            if (myFruitSubmissions) {
                let myFruitSubmissionsNew = [...myFruitSubmissions];
                myFruitSubmissionsNew = myFruitSubmissionsNew.map(el => {
                    if (el.registerId === editedRow.registerId) {
                        el = editedRow;
                    }
                    return el;
                });
                setMyFruitSubmissions(myFruitSubmissionsNew);
                setMandatoryCommentNotFilled(mandatoryCommentNotFilled(myFruitSubmissionsNew));
                setTimestamp(Date.now());
            }
        }
    }, [editedRow]);

    const saveChanges = () => {
        // We do nothing if there is a mandatory field not filled
        if (mandatoryCommentNotFilledForRegisterId) {
            return;
        }
        setLoading(true);
        const parsedData = myFruitSubmissions
            ? myFruitSubmissions.map(submission => ({
                  ...submission,
                  sampleAccepted:
                      submission.sampleAccepted === 'comment'
                          ? 1
                          : submission.sampleAccepted
                          ? parseInt(submission.sampleAccepted)
                          : submission.sampleAccepted,
              }))
            : myFruitSubmissions;
        apiMyFruitSubmissionsPageSave(Object.assign({data: {myFruitSubmissions: parsedData}}))
            .then(() => {
                setRowIsEdited(false);
            })
            .catch(error => {
                setErrorMessage(`error saving tree: ${error.message}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const discardChanges = () => {
        setMyFruitSubmissions(props.myFruitSubmissions);
        setTimestamp(Date.now());
        setRowIsEdited(false);
        setMandatoryCommentNotFilled(null);
    };

    const checkCanRecorded = (rowObject: MyFruitSubmissionsPageTableRowData) => {
        const materialSubmissionFrom = moment(rowObject.materialSubmissionFrom).format('DD/MM/YYYY');
        const materialSubmissionTo = moment(rowObject.materialSubmissionTo).format('DD/MM/YYYY');
        const diffCurrentDatematerialSubmissionFromDate = moment(new Date(), 'DD/MM/YYYY').diff(
            moment(materialSubmissionFrom, 'DD/MM/YYYY'),
            'days'
        );
        const diffCurrentDatematerialSubmissionToDate = moment(new Date(), 'DD/MM/YYYY').diff(
            moment(materialSubmissionTo, 'DD/MM/YYYY'),
            'days'
        );

        if (diffCurrentDatematerialSubmissionFromDate >= 0 && diffCurrentDatematerialSubmissionToDate <= 0) {
            return false;
        } else {
            return true;
        }
    };

    const mandatoryCommentNotFilled = (fruitSubmissions: MyFruitSubmissionsPageTableRowData[]) => {
        if (!fruitSubmissions) {
            return null;
        }

        for (const submission of fruitSubmissions) {
            if (shouldCommentBeMandatory(submission) && !submission.comments) {
                return submission.registerId;
            }
        }

        return null;
    };

    const onSelectInputDeliveredAcceptedChange = (rowObject: MyFruitSubmissionsPageTableRowData, value: any) => {
        const newEditedRow: MyFruitSubmissionsPageTableRowData = Object.assign({}, rowObject);
        newEditedRow.sampleAccepted = value;
        newEditedRow.isEdited = true;
        newEditedRow.deliveryDate = rowObject.deliveryDate
            ? rowObject.deliveryDate.split('T')[0]
            : rowObject.deliveryDate;
        setEditedRow(newEditedRow);
        setRowIsEdited(true);
    };

    const onDeliveryDateChange = (date: Date, rowObject: MyFruitSubmissionsPageTableRowData) => {
        const newEditedRow: MyFruitSubmissionsPageTableRowData = Object.assign({}, rowObject);

        let deliveryDateString = (moment(date, 'DD/MM/YYYY') as any)._i;
        let deliveryDateArray = deliveryDateString.split('/');
        deliveryDateString = deliveryDateArray[2] + '-' + deliveryDateArray[1] + '-' + deliveryDateArray[0];

        newEditedRow.deliveryDate = deliveryDateString;
        newEditedRow.isEdited = true;
        setEditedRow(newEditedRow);
        setRowIsEdited(true);
    };

    const onCommentChange = (event: any, rowObject: MyFruitSubmissionsPageTableRowData, foundIndex: number) => {
        setRowCommentChanged(true);
        const newEditedRow: MyFruitSubmissionsPageTableRowData = Object.assign({}, rowObject);
        newEditedRow.comments = event?.target.value || '';
        newEditedRow.isEdited = true;
        newEditedRow.deliveryDate = rowObject.deliveryDate
            ? rowObject.deliveryDate.split('T')[0]
            : rowObject.deliveryDate;
        let updatedComments = [...comments];
        updatedComments[foundIndex] = newEditedRow.comments;
        setComments(updatedComments);
        setEditedRow(newEditedRow);
        setRowIsEdited(true);
    };

    const shouldCommentBeMandatory = (rowObject: MyFruitSubmissionsPageTableRowData) => {
        // when Delivered - Accepted field was selected option "No" then Comments field should be mandatory
        return rowObject.sampleAccepted === 0 || rowObject.sampleAccepted === '0';
    };

    const saveChangesDisabledStyles = {
        backgroundColor: mandatoryCommentNotFilledForRegisterId ? '#ccc' : undefined,
        cursor: mandatoryCommentNotFilledForRegisterId ? 'not-allowed' : undefined,
    };

    return (
        <React.Fragment>
            <div className={styles.myFruitSubmissionsPageTable}>
                {rowIsEdited && (
                    <React.Fragment>
                        <div
                            className={styles.customNavigationBar + ' ' + styles.saveButton}
                            style={saveChangesDisabledStyles}
                            onClick={saveChanges}
                        >
                            <FontAwesomeIcon style={{float: 'left'}} icon={faCheck as any} />
                            <div style={{float: 'left', paddingLeft: 5}}>{`Save Changes`}</div>
                        </div>
                        <div
                            className={styles.customNavigationBar + ' ' + styles.discardButton}
                            onClick={discardChanges}
                        >
                            <FontAwesomeIcon style={{float: 'left'}} icon={faRemoveFormat as any} />
                            <div style={{float: 'left', paddingLeft: 5}}>{`Discard Changes`}</div>
                        </div>
                        <div className={styles.clearBoth}></div>
                        {mandatoryCommentNotFilledForRegisterId && (
                            <div style={{marginTop: 15}}>
                                <Error>
                                    Please fill Comments for File Number: #{mandatoryCommentNotFilledForRegisterId}
                                </Error>
                            </div>
                        )}
                    </React.Fragment>
                )}
                <div className={styles.clearBoth}></div>
                <CustomTable
                    version={2}
                    {...props}
                    pageNumber={props.criteria.pageNumber}
                    tableName={'MyFruitSubmissionsPageTable'}
                    tableType={'OBJECT'}
                    tableSource={myFruitSubmissions}
                    timestamp={timestamp}
                    id={'registerId'}
                    pagination={true}
                    rowClass={undefined}
                    formatFunctions={{
                        materialSubmissionFrom: (materialSubmissionFrom: string) =>
                            materialSubmissionFrom && moment(materialSubmissionFrom).format('DD/MM/YYYY'),
                        materialSubmissionTo: (materialSubmissionTo: string) =>
                            materialSubmissionTo && moment(materialSubmissionTo).format('DD/MM/YYYY'),
                        sampleAccepted: (sampleAccepted: any, rowObject: MyFruitSubmissionsPageTableRowData) => (
                            <SelectInput
                                notAll={true}
                                value={
                                    sampleAccepted === null || sampleAccepted === undefined
                                        ? 'Please chooose'
                                        : sampleAccepted
                                }
                                onChange={({target: {value}}: any) =>
                                    onSelectInputDeliveredAcceptedChange(rowObject, value)
                                }
                                list={[
                                    {id: 'none', value: 'Please choose'},
                                    {id: 1, value: 'Yes'},
                                    {id: 0, value: 'No'},
                                    {id: 'comment', value: 'Yes, but...'},
                                ]}
                                width={120}
                            />
                        ),
                        deliveryDate: (deliveryDate: string, rowObject: MyFruitSubmissionsPageTableRowData) =>
                            checkCanRecorded(rowObject) ? (
                                deliveryDate && moment(deliveryDate).format('DD/MM/YYYY')
                            ) : (
                                <CustomDatePicker
                                    style={{backgroundColor: '#e8e8e8'}}
                                    disabled={checkCanRecorded(rowObject)}
                                    placeholder={' '}
                                    change={(date: Date) => onDeliveryDateChange(date, rowObject)}
                                    inputValue={deliveryDate && moment(deliveryDate).format('DD/MM/YYYY')}
                                    width={120}
                                    oldBackOfficeStyles={undefined}
                                    withPortal={true}
                                />
                            ),
                        comments: (_: string, rowObject: MyFruitSubmissionsPageTableRowData) => {
                            let commentsValue = '';
                            const foundIndex = (props.myFruitSubmissions || []).findIndex(
                                ({registerId}) => registerId === rowObject.registerId
                            );
                            if (comments.length > foundIndex && foundIndex > -1) {
                                commentsValue = comments[foundIndex];
                            }
                            return (
                                <TextAreaInput
                                    value={commentsValue}
                                    onChange={(event: any) => onCommentChange(event, rowObject, foundIndex)}
                                    double={true}
                                    rows={3}
                                    mandatory={shouldCommentBeMandatory(rowObject)}
                                    disabled={
                                        String(rowObject.sampleAccepted) !== 'comment' &&
                                        String(rowObject.sampleAccepted) !== '0'
                                    }
                                />
                            );
                        },
                    }}
                    resultFieldsAll={RESULT_FIELDS_DEFAULT}
                    resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                    intl={props.intl}
                    defaultOrder={props.criteria.order}
                    reverseOrder={props.criteria.reverse}
                    pageSize={props.criteria.pageSize}
                    count={props.count}
                    updateCriteriaValue={(criteriaValue: MyFruitSubmissionsPageFilterCriteriaData) =>
                        props.updateCriteriaValue(criteriaValue)
                    }
                    printExcel={props.printExcel}
                />
            </div>
        </React.Fragment>
    );
}
