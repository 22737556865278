import {API_SERVERLESS, getFetch} from '~commonApi';
import {apiCommunicationCentreGetCommunicationIdUploadFilesAndSendMessage} from '../CommunicationCentreClient/CommunicationCentreClientService';
import {ICommunicationMessageClientSubmitJSONRequest, IDocument} from '../../types';

export function apiGetCommunicationIdUploadFilesAndSendMessage(
    JSONRequest: ICommunicationMessageClientSubmitJSONRequest,
    documentList: IDocument[]
) {
    return apiCommunicationCentreGetCommunicationIdUploadFilesAndSendMessage(JSONRequest, documentList, {
        getCommunicationId: 'communicationCentreModalClient/v4/CCMCRGID/reply/getCommunicationId',
        documentUploadFile: 'communicationCentreModalClient/v4/CCMCRUF/reply/documentUploadFile',
        submitRequest: 'communicationCentreModalClient/v4/CCMCREMRE/reply/messageReply',
    });
}

export function apiCommunicationCentreModalClientReplyMessageRead(communicationId: number) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreModalClient/v4/CCMCREMR/reply/messageRead/${communicationId}`,
        true
    );
}
