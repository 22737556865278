import {getFetch, postFetch, API_SERVERLESS} from '~commonApi';
import {loadUrlParams} from '~utils';
import {IDelegationApplicationItem} from './ModalOnlineDelegationCreate';

export function apiOnlineDelegationApplicationsGet(): Promise<{
    applications: IDelegationApplicationItem[];
}> {
    return getFetch(`${API_SERVERLESS}/online/v3/delegationApplicationsGet`, true);
}

export function apiOnlineDelegationCheckLogin({delegatedLogin}: any) {
    let URLParams = loadUrlParams();
    let URLSearchQuery = '';
    URLParams && URLParams.mockErrorType && (URLSearchQuery = `?mockErrorType=${URLParams.mockErrorType}`);
    return postFetch(`${API_SERVERLESS}/online/v3/delegationCheckLogin${URLSearchQuery}`, true, {
        delegatedLogin,
    });
}
