import React from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import CustomTable from '~components/CustomTable';
import {FORMAT_DATE_EASY} from '~components/FormatFunctions';
import ModalOnlineDelegationCreate from './modals/ModalOnlineDelegationCreate/ModalOnlineDelegationCreate';
import {
    apiOnlineDelegationCreate,
    apiOnlineDelegationDelete,
    apiOnlineDelegationsGet,
} from './OnlineDelegationsService';
import OnlineDelegationsActionButtons from './OnlineDelegationsActionButtons';
import {getLoggedUsedId, trackPageView} from '../../utils';
import {IDelegationItem} from '../../types';
import {ModalAlertVersion2, ModalConfirmVersion2} from '../../commonModals';
import {Footer, HeaderLoading, HeaderLogoMenu, HeaderTitleAndVersion, MainWrapper} from '../../componentsLayout';
import NavigationOnlineApplications from '../../shared/NavigationOnlineApplications';

const RESULT_FIELDS_ALL = ['login', 'beginDateDelegation', 'endDateDelegation', 'applicationCode'];
const DEFAULT_RESULT_FIELDS = ['login', 'beginDateDelegation', 'endDateDelegation', 'applicationCode'];

export interface IPayloadCreateDelegation {
    login: string;
    applications: [
        {
            applicationId: string;
            applicationCode: string;
        }
    ];
    beginDate: string;
    endDate: string;
    information: string | null;
}

interface IProps {
    intl: any;
    history: any;
}

interface IState {
    loading: number;
    responseDelegations: IDelegationItem[];
    isModalConfirmDeleteDelegationOpen: number | null;
    isModalCreateDelegationOpen: boolean;
    modalAlertTitle: any;
    modalAlertMessage: any;
    timestamp: number;
}

class OnlineDelegations extends React.Component<IProps, IState> {
    loggedInUserContactId = getLoggedUsedId();

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: 0,
            responseDelegations: [],
            isModalConfirmDeleteDelegationOpen: null,
            isModalCreateDelegationOpen: false,
            modalAlertTitle: null,
            modalAlertMessage: null,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'delegations'});
        this.loadJSONs();
    }

    loadJSONs = () => {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiOnlineDelegationsGet({delegatingContactId: this.loggedInUserContactId})
                    .then(JSONResponse => {
                        JSONResponse.delegations &&
                            this.setState({
                                responseDelegations: [...JSONResponse.delegations],
                            });
                    })
                    .catch((error: any) => {
                        ERROR([`OnlineDelegations: ${error.message}`]);
                    })
                    .finally(() => this.setState(prev => ({loading: prev.loading - 1, timestamp: Date.now()})));
            }
        );
    };

    requestDeleteDelegation = () => {
        const delegationIdToDelete = this.state.isModalConfirmDeleteDelegationOpen;
        delegationIdToDelete &&
            this.setState(
                prev => ({loading: prev.loading + 1, isModalConfirmDeleteDelegationOpen: null}),
                () => {
                    apiOnlineDelegationDelete(delegationIdToDelete)
                        .catch((error: any) => {
                            ERROR([`OnlineDelegations: ${error.message}`]);
                        })
                        .finally(() =>
                            this.setState(
                                prev => ({loading: prev.loading - 1}),
                                () => this.loadJSONs()
                            )
                        );
                }
            );
    };

    requestCreateDelegation = (payload: IPayloadCreateDelegation) => {
        this.setState(
            prev => ({loading: prev.loading + 1, isModalCreateDelegationOpen: false}),
            () => {
                apiOnlineDelegationCreate(payload)
                    .then((jsonResponse: any) => {
                        jsonResponse.data === 'OK' && this.loadJSONs();
                        jsonResponse.errorMessage &&
                            this.setState({
                                modalAlertTitle: `Delegation create failed`,
                                modalAlertMessage: jsonResponse.errorMessage,
                            });
                    })
                    .catch((error: any) => {
                        ERROR([`OnlineDelegations: ${error.message}`]);
                        this.setState({
                            modalAlertTitle: `Delegation create failed`,
                            modalAlertMessage: `There was an error while creating the delegation`,
                        });
                    })
                    .finally(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    onTableOpenModalConfirmDeleteDelegationClick = (delegationId: number) =>
        this.setState({isModalConfirmDeleteDelegationOpen: delegationId});

    onCloseModalConfirmDeleteDelegationClick = () => this.setState({isModalConfirmDeleteDelegationOpen: null});

    onCloseModalAlertClick = () => this.setState({modalAlertTitle: null, modalAlertMessage: null});

    onButtonCreateDelegationClick = () => this.setState({isModalCreateDelegationOpen: true});

    onButtonCloseModalCreateClick = () => this.setState({isModalCreateDelegationOpen: false});

    render() {
        const tableSourceData = prepareTableSource(this.state.responseDelegations);
        const actions = OnlineDelegationsActionButtons(this.onTableOpenModalConfirmDeleteDelegationClick);

        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                {this.state.modalAlertMessage !== null && (
                    <ModalAlertVersion2
                        title={this.state.modalAlertTitle}
                        message={this.state.modalAlertMessage}
                        close={this.onCloseModalAlertClick}
                    />
                )}
                {this.state.isModalConfirmDeleteDelegationOpen ? (
                    <ModalConfirmVersion2
                        title={`Delete delegation`}
                        message={`Are you sure you want to delete this delegation ?`}
                        buttonName={'Yes'}
                        action={this.requestDeleteDelegation}
                        close={this.onCloseModalConfirmDeleteDelegationClick}
                    />
                ) : null}
                {this.state.isModalCreateDelegationOpen ? (
                    <ModalOnlineDelegationCreate
                        close={this.onButtonCloseModalCreateClick}
                        onDelegationCreate={this.requestCreateDelegation}
                    />
                ) : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Online Applications`} />
                <NavigationOnlineApplications />
                <MainWrapper>
                    <div style={{marginRight: 15}}>
                        <FormFooterButton
                            color={buttonColor.green}
                            clickAction={this.onButtonCreateDelegationClick}
                            icon={faPlus}
                            disabled={this.state.loading !== 0}
                        >{`Create a delegation`}</FormFooterButton>
                    </div>
                    <div style={{clear: 'both'}} />
                    <div
                        style={{
                            marginBottom: '60px',
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'left',
                                paddingLeft: 15,
                                paddingRight: 15,
                                overflowX: 'visible',
                            }}
                        >
                            <>
                                {(this.state.loading === 0 || tableSourceData.length > 0) && (
                                    <CustomTable
                                        version={2}
                                        {...this.props}
                                        notSortable={RESULT_FIELDS_ALL}
                                        tableName={'Delegations'}
                                        tableType={'OBJECT'}
                                        tableSource={tableSourceData}
                                        timestamp={this.state.timestamp}
                                        dataFilter={null}
                                        id={'applicationId'}
                                        setLastCursor={null}
                                        resultFieldsDefault={DEFAULT_RESULT_FIELDS}
                                        intl={this.props.intl}
                                        defaultOrder={'registerId'}
                                        reverseOrder={false}
                                        filterFunctions={null}
                                        count={this.state.responseDelegations && this.state.responseDelegations.length}
                                        formatFunctions={{
                                            beginDateDelegation: FORMAT_DATE_EASY,
                                            endDateDelegation: FORMAT_DATE_EASY,
                                        }}
                                        hideExcelButton={true}
                                        actions={actions}
                                    />
                                )}
                            </>
                        </div>
                    </div>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

function prepareTableSource(jsonDelegations: IDelegationItem[]) {
    return (jsonDelegations && jsonDelegations.reverse()).map(el => {
        const {delegatedLogin, beginDate, endDate, applications, delegationId} = el || {};
        let applicationCodes;
        if (applications && applications.length) {
            let applicationsCodesArray = applications.map(el => el.applicationCode);
            applicationCodes = applicationsCodesArray.join(', ');
        }
        return {
            login: delegatedLogin,
            beginDateDelegation: beginDate,
            endDateDelegation: endDate,
            applicationCode: applicationCodes,
            delegationId,
        };
    });
}

export default withRouter(injectIntl(OnlineDelegations));
