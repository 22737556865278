import React from 'react';
import {createRoot} from 'react-dom/client';

import {IntlProviderWrapper} from './context/IntlContext';
import './App.css';
import {
    getDomainAndAutorization,
    LOCALHOST,
    ONLINE2_DOMAIN,
    ONLINE_DOMAIN,
    PREVIEW_DOMAIN,
    PUBLIC2_DOMAIN,
    PUBLIC_DOMAIN,
} from '~common/init';
import App from '~appsource';

const {domain, authorization, onlinePdf, backOfficePdf} = getDomainAndAutorization();

const domNode = document.getElementById('root');
const root = createRoot(domNode!);

root.render(
    <React.Fragment>
        {domain === ONLINE_DOMAIN ||
        domain === ONLINE2_DOMAIN ||
        domain === LOCALHOST ||
        domain === PUBLIC_DOMAIN ||
        domain === PUBLIC2_DOMAIN ||
        domain === PREVIEW_DOMAIN ||
        authorization ||
        onlinePdf ||
        backOfficePdf ? (
            <IntlProviderWrapper>
                <App />
            </IntlProviderWrapper>
        ) : null}
    </React.Fragment>
);
