import React from 'react';
import {Button} from '../../../../../../componentsFormV2';
import {FormFooter} from '../../../../../../componentsLayout';
import {MyInvoicesFilterCriteriaAreaProps} from './props/MyInvoicesFilterCriteriaAreaProps';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import styles from './MyInvoicesFilterCriteriaArea.module.scss';

export default function MyInvoicesFilterCriteriaArea(props: MyInvoicesFilterCriteriaAreaProps) {
    const invoiceStatusOptions = [
        {id: 'Paid', value: 'Paid'},
        {id: 'Not Paid', value: 'Not Paid'},
        {id: 'Not Sent', value: 'Not yet sent to the CPVO'},
    ];

    const getYearOptions = () => {
        let yearOptions: Array<{id: number; value: string}> = [];
        for (let year = 1995; year <= new Date().getFullYear(); year++) {
            yearOptions.push({id: year, value: year.toString()});
        }

        return yearOptions.reverse();
    };

    const onInvoiceStatusChange = ({target: {value: invoiceStatus}}: React.ChangeEvent<HTMLSelectElement>) =>
        props.updateCriteriaValue({invoiceStatus});

    const onYearChange = ({target: {value: year}}: React.ChangeEvent<HTMLSelectElement>) =>
        props.updateCriteriaValue({year});

    const onFileNumberChange = ({target: {value: fileNumber}}: React.ChangeEvent<HTMLInputElement>) =>
        props.updateCriteriaValue({fileNumber});

    const onSupplierReferenceChange = ({target: {value: supplierReference}}: React.ChangeEvent<HTMLInputElement>) =>
        props.updateCriteriaValue({supplierReference});

    return (
        <>
            <div className={styles.mainFieldContainer}>
                <SelectInput
                    value={props.criteria.invoiceStatus}
                    onChange={onInvoiceStatusChange}
                    double={true}
                    list={invoiceStatusOptions}
                    outsideLabel={'Invoice status'}
                    outsideLabelWidth={160}
                />
                {(props.criteria.invoiceStatus === 'Paid' || props.criteria.invoiceStatus === 'All') && (
                    <SelectInput
                        value={props.criteria.year}
                        onChange={onYearChange}
                        double={true}
                        list={getYearOptions()}
                        outsideLabel={'Payment Year'}
                        outsideLabelWidth={160}
                    />
                )}
                <TextInput
                    placeholder={`Please type file number`}
                    value={props.criteria.fileNumber}
                    onChange={onFileNumberChange}
                    double={true}
                    onEnter={() => props.search(true)}
                    outsideLabel={'File Number'}
                    outsideLabelWidth={160}
                />
                <div style={{clear: 'both'}} />
                <TextInput
                    filter={'Contains'}
                    placeholder={`Please type supplier reference`}
                    value={props.criteria.supplierReference}
                    onChange={onSupplierReferenceChange}
                    double={true}
                    onEnter={() => props.search(true)}
                    outsideLabel={'Supplier Reference'}
                    outsideLabelWidth={160}
                />
                <div style={{clear: 'both'}} />
            </div>
            <FormFooter>
                <Button clickAction={() => props.resetCriteria()} variation={'secondary'}>{`Clear fields`}</Button>
                <Button clickAction={() => props.search(false)}>{`Search`}</Button>
            </FormFooter>
            <div style={{clear: 'both'}} />
        </>
    );
}
