import {API_SERVERLESS, postFetch} from '../../../../../../commonApi';

export function apiUploadClosedProcedureDocumentFile(jsonData: {file: Blob; inventoryNumber: string}) {
    const requestOption = {method: 'PUT', headers: {'Content-Type': 'application/pdf'}};

    return postFetch(`${API_SERVERLESS}/tlo/applications/documents/closedProcedures/file/upload`, true, {
        inventoryNumber: jsonData.inventoryNumber,
    }).then((jsonResponse: {data: {fileUrl: string}}) => {
        return fetch(jsonResponse.data.fileUrl, {...requestOption, body: jsonData.file});
    });
}
