import React from 'react';
import styles from './BrowserInfo.module.scss';

export default function ({
    intl,
    notUpload,
    plus,
    uploadOnlyOneFile = false,
    documentTypes = ['jpg', 'jpeg', 'png', 'pdf'],
}) {
    return (
        <React.Fragment>
            {!notUpload && (
                <div>
                    <ul style={{marginTop: 0, marginBottom: 15, textAlign: 'left'}}>
                        <li>{`For optimum compatibility and performance, we recommend using a modern browser (*).`}</li>
                        <li>
                            {`Document types allowed are: ${documentTypes.join(', ')} (`}
                            <strong className="ng-scope">{`not encrypted`}</strong>
                            {`)`}
                        </li>
                        <li>
                            {`The maximum document size for uploads is `}
                            <strong className="ng-scope">{`10 MB`}</strong>
                            {`. `}
                            {!uploadOnlyOneFile && `You may however upload as many documents as needed.`}
                        </li>
                        <li>
                            {`You can `}
                            <strong className="ng-scope">{`drag & drop`}</strong>
                            {` documents from your desktop on this webpage with Google Chrome, Mozilla Firefox 3.6+, Apple Safari 4+ and Internet Explorer 10+.`}
                        </li>
                    </ul>
                </div>
            )}
            <div style={{textAlign: 'left', paddingLeft: 7}}>
                <div style={{display: 'inline-block'}}>
                    <div className={styles.chromeIcon} />
                    <div className={styles.chromiumIcon} />
                    <div className={styles.edgeChromiumIcon} />
                </div>
                <div style={{display: 'inline-block', width: 'calc(100% - 90px)', verticalAlign: 'top', marginLeft: 5}}>
                    <a className="text-success" id="create-account" style={{fontWeight: 800}}>
                        {intl.formatMessage({id: 'login.recommend'})}
                    </a>
                    {plus && (
                        <React.Fragment>
                            <br />
                            <a className="text-success" id="create-account" style={{fontWeight: 800}}>
                                {intl.formatMessage({id: 'login.recommendPlus'})}
                            </a>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
