import React from 'react';
import cx from 'classnames';
import styles from './Button.module.scss';
import IconLogin from '../iconComponents/Login';
import IconUserPlus from '../iconComponents/UserPlus';
import IconChange from '../iconComponents/Change';
import IconArrowRight from '../iconComponents/ArrowRight';
import IconClose from '../iconComponents/Close';
import IconTick from '../iconComponents/Tick';
import IconBack from '../iconComponents/Back';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckSquare, faSquare} from '@fortawesome/free-regular-svg-icons';

const DEFAULT_VARIATION = 'primary';

interface IButtonProps {
    className?: any;
    children: any;
    color?: any;
    clickAction: any;
    onHoldAction?: any;
    icon?: 'arrowRight' | 'back' | 'change' | 'close' | 'login' | 'tick' | 'userPlus';
    disabled?: boolean;
    style?: any;
    variation?: 'primary' | 'secondary' | 'tertiary' | 'danger';
    mb?: 'sm' | 'md' | 'lg';
    isCheckboxChecked?: boolean;
}

let holdChannel: number | null = null;
export default function ({
    children,
    className,
    variation,
    clickAction,
    onHoldAction,
    icon,
    disabled,
    style: userStyle,
    mb,
    isCheckboxChecked,
}: IButtonProps) {
    let stylesAppliedMb;

    const iconColors: any = {
        secondary: '#009435',
        tertiary: '#999999',
    };

    const IconWrapper = ({Component}: any) => {
        return (
            <Component
                style={{verticalAlign: 'middle', marginRight: 8}}
                color={variation && iconColors[variation] ? iconColors[variation] : undefined}
                variation={variation || DEFAULT_VARIATION}
            />
        );
    };

    const icons: any = {
        arrowRight: <IconWrapper Component={IconArrowRight} />,
        back: <IconWrapper Component={IconBack} />,
        change: <IconWrapper Component={IconChange} />,
        close: <IconWrapper Component={IconClose} />,
        login: <IconWrapper Component={IconLogin} />,
        tick: <IconWrapper Component={IconTick} />,
        userPlus: <IconWrapper Component={IconUserPlus} />,
    };

    const mbStyle: any = {
        sm: 'marginBottomSm',
        md: 'marginBottomMd',
        lg: 'marginBottomLg',
    };

    if (mb) {
        stylesAppliedMb = styles[mbStyle[mb]];
    }

    const checkOnHold = () => {
        const channel = Date.now();
        holdChannel = channel;
        setTimeout(() => {
            if (holdChannel === channel && onHoldAction) {
                onHoldAction();
            }
        }, 5000);
    };

    const CleanOnHold = () => {
        holdChannel = null;
    };

    return (
        <button
            style={userStyle}
            onClick={!disabled ? clickAction : undefined}
            onMouseDown={checkOnHold}
            onMouseUp={CleanOnHold}
            className={cx(
                styles.button,
                styles[variation || DEFAULT_VARIATION],
                disabled && styles.disabled,
                stylesAppliedMb,
                className ? className : null
            )}
            type={'button'}
        >
            {icon ? icons[icon] : null}
            {isCheckboxChecked !== undefined ? (
                <FontAwesomeIcon
                    className={styles.checkbox}
                    icon={isCheckboxChecked ? faCheckSquare : (faSquare as any)}
                />
            ) : null}
            <span>{children}</span>
        </button>
    );
}
