import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';
import {IDelegationItem} from '../../types';
import {IPayloadCreateDelegation} from './OnlineDelegations';

export function apiOnlineDelegationsGet({delegatingContactId}: {delegatingContactId: string | null}): Promise<{
    data: string;
    delegations: IDelegationItem[];
}> {
    return getFetch(`${API_SERVERLESS}/online/v3/delegationsGet?delegatingContactId=${delegatingContactId}`, true);
}

export function apiOnlineDelegationDelete(delegationIdToDelete: number) {
    return postFetch(`${API_SERVERLESS}/online/v3/delegationDelete`, true, {
        delegationId: delegationIdToDelete,
    });
}

export function apiOnlineDelegationCreate(payload: IPayloadCreateDelegation) {
    return postFetch(`${API_SERVERLESS}/online/v3/delegationCreate`, true, payload);
}
