import React from 'react';
import SelectInput from '~components/SelectInput';
import DateInput from '~components/DateInput';
import Checkbox from '~components/Checkbox';
import {formatDateEasy, reformatDateEasy} from '~components/FormatFunctions';
import {apiUpdateRequest} from './ModalMyPVRAdminProceedingsEditService';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';

const requestStatusOptions = [
    {id: 'new', value: 'New'},
    {id: 'open', value: 'Open'},
    {id: 'closed', value: 'Closed'},
    {id: 'admissibilityCheck', value: 'Admissibility Check'},
];

const requestTypeOptions = [
    {id: 'objection', value: 'Objection'},
    {id: 'nullity', value: 'Nullity'},
    {id: 'cancellation', value: 'Cancellation'},
    {id: 'restitutioInIntegrum', value: 'Restitutio In Integrum'},
    {id: 'appeal', value: 'Appeal'},
    {id: 'other', value: 'Other'},
];

export default class ModalMyPVRAdminProceedingsEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requestStatus: this.props.criteria.requestStatus,
            requestType: this.props.criteria.requestType,
            deadline: formatDateEasy(this.props.criteria.deadline),
            loading: 0,
            sendToExaminationOfficeCheckboxValue: false,
        };
    }

    close = refresh => this.props.close && this.props.close(refresh);

    updateRequest = () => {
        this.setState(
            prev => ({
                loading: ++prev.loading,
            }),
            () =>
                apiUpdateRequest({
                    requestId: this.props.criteria.requestId,
                    requestStatus: this.state.requestStatus,
                    requestType: this.state.requestType,
                    deadline: reformatDateEasy(this.state.deadline),
                }).then(() =>
                    this.setState(
                        prev => ({loading: --prev.loading}),
                        () => this.close(true)
                    )
                )
        );
    };

    onRequestStatusChange = ({target: {value: requestStatus}}) => this.setState({requestStatus});

    onRequestTypeChange = ({target: {value: requestType}}) => this.setState({requestType});

    onDeadlineChange = deadline => this.setState({deadline});

    onSendToExaminationOfficeCheckboxChange = () =>
        this.setState(prev => ({sendToExaminationOfficeCheckboxValue: !prev.sendToExaminationOfficeCheckboxValue}));

    render() {
        return (
            <ModalCustomVersion2
                close={this.props.close}
                loading={this.state.loading !== 0}
                header={`Edit request`}
                body={
                    <div>
                        <SelectInput
                            outsideLabel={`Request status`}
                            outsideLabelWidth={160}
                            value={this.state.requestStatus}
                            onChange={this.onRequestStatusChange}
                            list={requestStatusOptions}
                            double={true}
                            notAll={true}
                        />
                        <SelectInput
                            outsideLabel={`Request type`}
                            outsideLabelWidth={160}
                            value={this.state.requestType}
                            onChange={this.onRequestTypeChange}
                            list={requestTypeOptions}
                            double={true}
                            notAll={true}
                        />
                        <div>
                            <DateInput
                                simple={true}
                                double={true}
                                changeDateFrom={this.onDeadlineChange}
                                inputValueFrom={this.state.deadline}
                                outsideLabel={`Deadline`}
                                outsideLabelWidth={160}
                            />
                        </div>
                        <div style={{clear: 'both'}}>{}</div>
                        <div style={{marginLeft: 180}}>
                            <Checkbox
                                clickAction={this.onSendToExaminationOfficeCheckboxChange}
                                simple={true}
                                label={`Send to Examination Office`}
                                value={this.state.sendToExaminationOfficeCheckboxValue}
                            />
                        </div>
                        <div style={{clear: 'both'}}>{}</div>
                    </div>
                }
                footer={
                    <>
                        <Button
                            variation={'danger'}
                            clickAction={() => this.close(false)}
                            icon={'close'}
                        >{`Close`}</Button>
                        <Button clickAction={this.updateRequest} icon={'arrowRight'}>{`Save`}</Button>
                    </>
                }
            />
        );
    }
}
