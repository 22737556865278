import React from 'react';
import styles from './ModalUsedInVersion2.module.scss';
import ModalCustomVersion2 from './ModalCustomVersion2';
import {Button} from '../componentsFormV2';
import {apiLabelUsedIn} from './ModalUsedInVersion2Service';
import CustomTable from '~components/CustomTable';
import StatusField from '~shared/StatusField';
import ModalUsedInVersion2ActionButtons from './ModalUsedInVersion2ActionButtons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {faFolder} from '@fortawesome/free-regular-svg-icons';
import FormFooterLinkButton from '~componentsForm/FormFooterLinkButton';
import TextInput from '~components/TextInput';
import {LoadingBar} from '../componentsLayout';

export const rowType = {
    FORM: 0,
    GROUP: 1,
    QUESTION: 2,
    LABEL: 3,
    TEMPLATE: 4,
    RESPONSE: 5,
};
const nextRowType: any = {};
nextRowType[rowType.FORM] = rowType.GROUP;
nextRowType[rowType.GROUP] = rowType.QUESTION;
nextRowType[rowType.QUESTION] = rowType.LABEL;

const rowTypeName: any = {};
rowTypeName[rowType.GROUP] = `Group`;
rowTypeName[rowType.QUESTION] = `Question`;
rowTypeName[rowType.LABEL] = `Label`;
rowTypeName[rowType.TEMPLATE] = `Template`;
rowTypeName[rowType.RESPONSE] = `Response`;

interface IProps {
    elementId?: any;
    close: any;
    intl: any;
    type?: any;
    plus?: any;
}

interface IState {
    countries: any;
    forms: any;
    resultRows: any;
    timestamp: any;
    inputValue: string;
    filterString: string;
}

export default class ModalUsedInVersion2 extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            countries: null,
            forms: null,
            resultRows: null,
            timestamp: null,
            inputValue: '',
            filterString: '',
        };
    }

    componentDidMount() {
        const type = rowTypeName[this.props.type];
        if (this.props.elementId && type) {
            apiLabelUsedIn(this.props.elementId, type.toLowerCase()).then((jsonData: any) => {
                jsonData &&
                    jsonData.data &&
                    this.setState({forms: jsonData.data}, () => {
                        this.processForms(this.state.forms);
                    });
            });
        }
    }

    toggleFieldVisibility = (uniqueId: any) => this.processForms(this.state.forms, uniqueId);

    filter = () =>
        this.setState(
            prev => ({filterString: prev.inputValue}),
            () => this.processForms(this.state.forms)
        );

    resetCriteria = () => this.setState({filterString: '', inputValue: ''}, () => this.processForms(this.state.forms));

    processForms = (forms: any, nodeToggledId?: any) => {
        const filterString = this.state.filterString;
        const resultRows: any = [];
        forms.forEach((questionnaire: any) => {
            if (filterString === '' || (questionnaire.name || '').toLowerCase().includes(filterString.toLowerCase())) {
                this.processNode(resultRows, questionnaire, rowType.FORM, true, nodeToggledId);
            }
        });
        this.setState({resultRows, timestamp: Date.now()});
    };

    processNode = (rows: any, node: any, type: any, parentOpen: any, nodeToggleId: any, prefix?: any) => {
        const uniqueId = `${prefix ? `${prefix}-` : ''}${node.id}`;
        node.open = nodeToggleId === uniqueId ? !node.open : node.open || false;
        if (parentOpen) {
            rows.push({
                id: node.id,
                uniqueId,
                name: node.name,
                office: node.office || '',
                status: node.status,
                type: node.type,
                rowType: type,
                iconOpen: node.open,
                children: node.children ? node.children.length : 0,
            });
        }
        const nextType = nextRowType[type];
        if (type !== rowType.LABEL && node.children) {
            node.children.forEach((child: any) => {
                this.processNode(
                    rows,
                    child,
                    nextType,
                    parentOpen === false ? false : node.open,
                    nodeToggleId,
                    uniqueId
                );
            });
        }
    };

    close = () => {
        this.props.close && this.props.close();
    };

    onInputFilterChange = (event: any) => this.setState({inputValue: event.target.value});

    renderFieldName = (name: any, rowObject: any) => (
        <div
            onClick={() => this.toggleFieldVisibility(rowObject.uniqueId)}
            style={(rowObject.children && rowObject.children > 0 && {cursor: 'pointer'}) || null}
        >
            <a style={{paddingLeft: rowObject.rowType * 18}}>
                {(((rowObject.children && rowObject.children > 0) || rowObject.rowType === rowType.LABEL) && (
                    <FontAwesomeIcon
                        icon={
                            rowObject.rowType === rowType.LABEL
                                ? faFolder
                                : rowObject.iconOpen
                                ? faMinus
                                : (faPlus as any)
                        }
                    />
                )) ||
                    null}
            </a>
            <span className="indented tree-label ng-scope">
                <span className="ng-scope">
                    <span className="ng-binding ng-scope">
                        {` `}
                        <b style={{paddingRight: 3}}>
                            {rowObject.rowType === rowType.FORM ? `${name} ` : rowTypeName[rowObject.rowType]}
                        </b>
                        {rowObject.rowType === rowType.FORM ? null : `${name} `}
                    </span>
                    <span className={styles.badge}>{rowObject.id}</span>
                </span>
            </span>
        </div>
    );

    render() {
        const actions = ModalUsedInVersion2ActionButtons();
        return (
            <ModalCustomVersion2
                plus={this.props.plus}
                close={this.props.close}
                header={
                    <>
                        {` ${rowTypeName[this.props.type] ? rowTypeName[this.props.type] : `Label`} Used In`}
                        <div className={styles.modalSubtitle}>{`List of all forms where the selected ${(rowTypeName[
                            this.props.type
                        ]
                            ? rowTypeName[this.props.type]
                            : `Label`
                        ).toLowerCase()} is used`}</div>
                    </>
                }
                body={
                    <form>
                        <div>
                            <TextInput
                                value={this.state.inputValue}
                                onChange={this.onInputFilterChange}
                                placeholder="Filter on questionnaire search"
                                buttonIcon={'info'}
                                buttonAction={this.filter}
                            />
                            {this.state.filterString !== '' && (
                                <div style={{width: 80, float: 'left'}}>
                                    <FormFooterLinkButton
                                        clickAction={this.resetCriteria}
                                    >{`Clear`}</FormFooterLinkButton>
                                </div>
                            )}
                            <div style={{clear: 'both'}}>{}</div>
                        </div>
                        <br />
                        <div>
                            {this.state.resultRows ? (
                                <CustomTable
                                    {...this.props}
                                    tableName={'messagesUsedIn'}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.resultRows}
                                    id={'uniqueId'}
                                    resultFieldsDefault={['name', 'office', 'status']}
                                    intl={this.props.intl}
                                    hideExcelButton={true}
                                    formatFunctions={{
                                        name: this.renderFieldName,
                                        status: (statusId: any) => (
                                            <StatusField intl={this.props.intl} statusId={statusId} />
                                        ),
                                    }}
                                    timestamp={this.state.timestamp}
                                    sortFunctions={{}}
                                    actions={actions}
                                    notSortable={true}
                                    unlimited={true}
                                />
                            ) : (
                                <LoadingBar />
                            )}
                        </div>
                    </form>
                }
                footer={<Button variation={'danger'} clickAction={this.close} icon={'close'}>{`Close`}</Button>}
            />
        );
    }
}
