import React, {useEffect, useState} from 'react';
import Navigation, {ILink} from '../componentsLayout/Navigation';
import {getDecodedJWT} from '../utils';

interface INavigationBackOfficeProps {
    activeTitle?: string;
}

function NavigationBackOffice({activeTitle}: INavigationBackOfficeProps) {
    const [thirdPartyId, setThirdPartyId] = useState('');
    const [backOfficeNotAuthorised, setBackOfficeNotAuthorised] = useState(true);

    useEffect(() => {
        let decoded = getDecodedJWT();
        if (decoded) {
            decoded.thirdPartyId && setThirdPartyId(decoded.thirdPartyId);
            setBackOfficeNotAuthorised(
                (decoded.roles || []).indexOf('OABAC') === -1 && (decoded.roles || []).indexOf('OABAD') === -1
            );
        }
    });

    const links: ILink[] = [
        {to: '/forms', title: 'Forms', disabled: backOfficeNotAuthorised},
        {to: '/labels', title: 'Labels', disabled: backOfficeNotAuthorised},
        {to: '/templates', title: 'Templates', disabled: backOfficeNotAuthorised},
    ];

    if (!backOfficeNotAuthorised) {
        links.push({to: '/requests', title: 'Requests', disabled: backOfficeNotAuthorised});
    }

    if (thirdPartyId && parseInt(thirdPartyId) === 1) {
        links.push({to: '/adminProceedings', title: 'Admin Proceedings'});
        // links.push({to: '/TQSearch', title: 'TQ Search'});
    }

    return <Navigation links={links} activeTitle={activeTitle} />;
}

export default NavigationBackOffice;
