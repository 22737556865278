import {API_SERVERLESS, getFetch} from '../../../commonApi';

export function apiCommunicationCenterDownloadDocument({
    communicationId,
    serverIndex,
}: {
    communicationId: number;
    serverIndex?: string;
}) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentre/v3/downloadDocument/${communicationId}/${serverIndex}`,
        true
    );
}
