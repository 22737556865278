export interface DObject {
    [key: string]: string | number;
}

const getValueFromFormControlObj = (
    value: any,
    valueSelect: string,
    elasticField: string,
    elasticFieldSearchType: string,
    regexp: RegExp | null = null,
    forceLowercase = true
) => {
    const output: DObject[] = [];
    let obj: DObject = {};
    if (value && value.length > 0) {
        const newValue = forceLowercase ? value.toLowerCase() : value;
        obj[elasticField] = regexp ? newValue.replace(regexp, '') : newValue;
        output.push(obj);
        obj = {};
        if (valueSelect) {
            obj[elasticFieldSearchType] = valueSelect;
            output.push(obj);
        }
    }
    return output;
};

export const alertFields = {
    DENOMINATION: 'objectName',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    CONDITIONS: 'conditions',
};

export const createAlertFromValues = (values: any, sendQuery = true) => {
    const objectName = getValueFromFormControlObj(values.denomination, '', alertFields.DENOMINATION, '', null, false);
    const startDate = getValueFromFormControlObj(values.startDate, '', alertFields.START_DATE, '', null, false);
    const endDate = getValueFromFormControlObj(values.endDate || '', '', alertFields.END_DATE, '', null, false);
    const conditions = [
        {
            sub_countries: values.countries?.length ? values.countries : [''],
            sub_species: values.speciesLatinName?.length ? values.speciesLatinName : [''],
        },
    ];

    return Object.assign({}, ...objectName, ...startDate, ...endDate, {conditions: conditions});
};
