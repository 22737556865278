import React from 'react';
import styles from './ModalFileSearchDetails.module.scss';
import {ModalCustomVersion2} from '../../../../commonModals';
import {Button} from '../../../../componentsFormV2';

interface ModalFileSearchDetailsProps {
    close: () => void;
}

const ModalFileSearchDetails = (props: ModalFileSearchDetailsProps) => {
    const closeModal = () => {
        props.close && props.close();
    };

    return (
        <ModalCustomVersion2
            loading={false}
            plus={1}
            close={closeModal}
            header={`Search Functionality Cases`}
            headerSubtitle={`The field “Search in Files” may be used to search the full text of documents. Use the guide below to maximise your text search.`}
            body={
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>
                                <a>{'Key'}</a>
                            </th>
                            <th>
                                <a>{'Use'}</a>
                            </th>
                            <th>
                                <a>{'Purpose'}</a>
                            </th>
                            <th>
                                <a>{'Example'}</a>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <b>{`(space)`}</b>
                            </td>
                            <td>{`Insert a space between search terms`}</td>
                            <td>{`A space is used between terms as an ‘and’ operator in order to search for a document with all the terms`}</td>
                            <td>
                                <b>{`Search: similarity use colour`}</b>
                                <br />
                                {`Results will contain all three words, in no particular order`}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>{`OR`}</b>
                            </td>
                            <td>{`Insert the word OR in between search terms`}</td>
                            <td>{`The word ‘OR’ between two terms tells the search engine to provide results containing either term`}</td>
                            <td>
                                <b>{`Search: fees OR costs`}</b>
                                <br />
                                {`Results will contain either the word fees or the word costs in the text`}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>{`?`}</b>
                            </td>
                            <td>{`Insert a question mark before/after a search term`}</td>
                            <td>{`A question mark is used as a joker character, and can only represent one character`}</td>
                            <td>
                                <b>{`Search: post?`}</b>
                                <br />
                                {`Results will contain Poste or Posta, but not posting`}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>{`*`}</b>
                            </td>
                            <td>{`Insert an asterisk before/within/after a search term`}</td>
                            <td>{`An asterisk is used as a joker character, and can represent any number of any characters`}</td>
                            <td>
                                <b>{`Search: admis*`}</b>
                                <br />
                                {`Results will contain any or all of the words admission, admissible and admissibility`}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>{`-`}</b>
                            </td>
                            <td>{`Following another search term, place a hyphen before the next search term`}</td>
                            <td>{`To narrow a text search, a hyphen placed before a successive word tells the search engine to exclude results containing that word`}</td>
                            <td>
                                <b>{`Search: cancellation -revoked`}</b>
                                <br />
                                {`Results will contain documents with the word cancellation but not with the word revoked`}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>{`“”`}</b>
                            </td>
                            <td>{`Begin and end a phrase with inverted commas`}</td>
                            <td>{`Phrases surrounded by inverted commas tells the search engine to look for results containing only that exact phrase`}</td>
                            <td>
                                <b>{`Search: “translation of evidence”`}</b>
                                <br />
                                {`Results will contain the words translation of evidence only if they appear in the text in that exact order. Documents which contain all three terms but not in that order will not be shown in the search results.`}
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
            footer={
                <Button clickAction={closeModal} icon={'close'} variation={'danger'}>
                    {`Close`}
                </Button>
            }
        />
    );
};

export default ModalFileSearchDetails;
