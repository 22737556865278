import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {MODAL_LOGIC_OPERATION_TO_PERFORM} from './ResponseModalDetailsLogics';
import {IGroupQuestionResponseLogic} from '../../../types';

function renderButton(popupContent: any, handler: any, icon: any, mainScreen: any) {
    return {
        title: popupContent,
        icon: (rowObject: IGroupQuestionResponseLogic) => {
            if (rowObject.type === 'removelabel') {
                return <div />;
            }
            return mainScreen === 'templateQuestions' ||
                (mainScreen === 'backOfficeFormQuestions' && !rowObject.fromTemplate) ? (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon} />
                </a>
            ) : (
                <div />
            );
        },
        handler,
    };
}

export default function ResponseModalDetailsActions(openModalLogic: any, onTableIconDeleteClick: any, mainScreen: any) {
    const buttons = [];
    buttons.push(
        renderButton(
            'Edit',
            (_: any, rowObject: IGroupQuestionResponseLogic) => {
                openModalLogic(rowObject.id, MODAL_LOGIC_OPERATION_TO_PERFORM.UPDATE, rowObject);
            },
            faPencilAlt,
            mainScreen
        )
    );
    buttons.push(
        renderButton(
            'Delete',
            (_: any, rowObject: IGroupQuestionResponseLogic) => {
                onTableIconDeleteClick(rowObject);
            },
            faTrashAlt,
            mainScreen
        )
    );
    return buttons;
}
