import React, {useState} from 'react';
import cx from 'classnames';
import {Popover} from 'react-tiny-popover';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './LabelQuestion.module.scss';
import {checkIfQuestionIsMandatoryInCurrentGroupIteration} from './utils';
import WizardQuestionLogicsPopover from './WizardQuestionLogicsPopover';
import {htmlToText} from '../../../utils';
import {IGroup, IGroupQuestion, IStateResponsesClient} from '../../../types';

const QUESTION_STATUS = {
    IN_PREPARATION: 1,
    ACTIVE: 2,
    TERMINATED: 3,
};

interface ILabelQuestionProps {
    editable?: boolean;
    groupIteration: number;
    hidden: boolean;
    hiddenDueToQuestionResponseLines: any;
    hasError: any;
    question: IGroupQuestion;
    responsesClient: IStateResponsesClient;
    steps: IGroup[];
}

export default function LabelQuestion({
    editable,
    groupIteration,
    hidden,
    hiddenDueToQuestionResponseLines,
    hasError,
    question,
    responsesClient,
    steps,
}: ILabelQuestionProps) {
    const [isHiddenPopoverOpen, setIsHiddenPopoverOpen] = useState(false);
    const isMandatory = checkIfQuestionIsMandatoryInCurrentGroupIteration({
        question,
        groupIteration,
        steps,
        responsesClient,
    });
    const getQuestionStatusTitle = (isInPreparation: boolean, isActive: boolean, isTerminated: boolean) => {
        let questionStatus;
        isInPreparation && (questionStatus = 'Under preparation');
        isActive && (questionStatus = 'Active');
        isTerminated && (questionStatus = 'Terminated');
        return `Question Status: ${questionStatus}`;
    };
    const isQuestionStatusInPreparation = question && question.status === QUESTION_STATUS.IN_PREPARATION;

    const isQuestionStatusActive = question && question.status === QUESTION_STATUS.ACTIVE;

    const isQuestionStatusTerminated = question && question.status === QUESTION_STATUS.TERMINATED;

    const questionStatusTitle = getQuestionStatusTitle(
        isQuestionStatusInPreparation,
        isQuestionStatusActive,
        isQuestionStatusTerminated
    );

    return (
        <span style={question.messageIsDef === 1 ? {color: 'red'} : {}}>
            {editable ? (
                <>
                    {question.CPVOCode ? (
                        <span style={{color: '#2f923f', marginRight: 5}}>{`(${question.CPVOCode})`}</span>
                    ) : null}
                    {question.UPOVCode ? (
                        <span style={{color: 'grey', marginRight: 5}}>{`(${question.UPOVCode})`}</span>
                    ) : null}
                </>
            ) : (
                <>
                    {question.CPVOCode ? (
                        <span style={{color: '#2f923f', marginRight: 5}}>{`(${question.CPVOCode})`}</span>
                    ) : null}
                    {!question.CPVOCode && question.UPOVCode ? (
                        <span style={{color: 'grey', marginRight: 5}}>{`(${question.UPOVCode})`}</span>
                    ) : null}
                </>
            )}
            {parseInt(String(question.groupingCharacteristic)) === 1 ? (
                <span className="groupingCharacteristic" style={{color: 'grey', marginRight: 5}}>{`(G)`}</span>
            ) : null}
            <div
                style={{display: 'inline'}}
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{__html: `${question.order}. ${question.message}`}}
            />
            {isMandatory ? <div className={styles.textDanger}>{`*`}</div> : null}
            {hasError ? <FontAwesomeIcon icon={faExclamationTriangle as any} color={`#d9534f`} /> : null}
            {editable ? (
                <>
                    <div
                        title={questionStatusTitle}
                        className={cx(
                            styles.questionTitleLabel,
                            isQuestionStatusInPreparation && styles.statusInPreparation,
                            isQuestionStatusActive && styles.statusActive,
                            isQuestionStatusTerminated && styles.statusTerminated
                        )}
                    >
                        {isQuestionStatusInPreparation ? 'Under preparation' : null}
                        {isQuestionStatusActive ? 'Active' : null}
                        {isQuestionStatusTerminated ? 'Terminated' : null}
                    </div>
                    <div
                        title={`Question type: ${question.type}`}
                        className={cx(styles.questionTitleLabel, styles.questionTypeLabel)}
                    >
                        {question.type}
                    </div>
                    <div className={cx(styles.questionTitleLabel, styles.questionTypeLabel)}>{question.id}</div>
                    {hidden ? (
                        <Popover
                            isOpen={isHiddenPopoverOpen}
                            positions={'top'}
                            onClickOutside={() => null}
                            content={() => (
                                <div
                                    className={styles.customPopover}
                                    style={{
                                        background: '#e8e8e8',
                                        color: 'black',
                                        padding: '5px',
                                        borderRadius: '4px',
                                        maxWidth: '100%',
                                        opacity: 0.9,
                                        borderColor: 'black',
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                    }}
                                >
                                    <>
                                        <div style={{marginBottom: 10}}>
                                            Hidden Question:&nbsp;<strong>{question.order}</strong>&nbsp;
                                            {/*{question.message}*/}
                                        </div>
                                        {hiddenDueToQuestionResponseLines
                                            ? Array.isArray(hiddenDueToQuestionResponseLines) &&
                                              hiddenDueToQuestionResponseLines.length && (
                                                  <>
                                                      <div>Due to:</div>
                                                      <ul className={styles.hiddenDueToLines}>
                                                          {hiddenDueToQuestionResponseLines.map(line => (
                                                              <li key={line}>
                                                                  <strong>{line.split('-')[0]}</strong> -{' '}
                                                                  <span className={styles.hiddenDueToLineResponse}>
                                                                      {htmlToText(line.slice(line.indexOf('-') + 2))}
                                                                  </span>
                                                              </li>
                                                          ))}
                                                      </ul>
                                                  </>
                                              )
                                            : null}
                                    </>
                                </div>
                            )}
                        >
                            <div
                                onMouseEnter={() => setIsHiddenPopoverOpen(true)}
                                onMouseLeave={() => setIsHiddenPopoverOpen(false)}
                                className={cx(styles.questionTitleLabel, styles.labelHidden)}
                            >
                                Hidden
                            </div>
                        </Popover>
                    ) : null}
                    {question &&
                    question.type &&
                    question.type === 'checkbox' &&
                    question.nbResponseAllowed &&
                    parseInt(String(question.nbResponseAllowed)) > 0 ? (
                        <div className={cx(styles.questionTitleLabel, styles.labelNbResponseAllowed)}>
                            Max: {question.nbResponseAllowed} checked
                        </div>
                    ) : null}
                    {question &&
                    question.type &&
                    question.type === 'checkbox' &&
                    question.successiveScoreOnly &&
                    question.successiveScoreOnly ? (
                        <div className={cx(styles.questionTitleLabel, styles.labelSuccessiveScoreOnly)}>
                            Successive only
                        </div>
                    ) : null}
                    {question && question.logics && question.logics.length > 0 ? (
                        <WizardQuestionLogicsPopover logics={question.logics} question={question} steps={steps} />
                    ) : null}
                </>
            ) : null}
        </span>
    );
}
