import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt, IconDefinition} from '@fortawesome/free-regular-svg-icons';
import {UploadedFileData} from '../Interfaces/ModalDocumentInterfaces';

function renderButton(
    popupContent: string,
    handler: (fileName: string, rowObject: UploadedFileData) => void,
    icon: IconDefinition
) {
    return {
        title: popupContent,
        icon: () => (
            <a data-content={popupContent}>
                <FontAwesomeIcon icon={icon as any} />
            </a>
        ),
        handler,
    };
}

export default function UploadDocumentActionButton(deleteFile: (fileName: string, uuid?: string) => void) {
    const buttons = [];
    buttons.push(
        renderButton(
            'Delete',
            (fileName: string, rowObject: UploadedFileData) => {
                deleteFile(fileName, rowObject.uuid);
            },
            faTrashAlt
        )
    );
    return buttons;
}
