import React, {useEffect, useRef, useState} from 'react';
import {
    ContentsFiltersCriteriaData,
    SectionElement,
    SectionsTableFetchData,
} from '../../CommonInterfaces/CommonInterfaces';
import ContentsFiltersCriteria from '../../CommonComponents/ContentsFiltersCriteria/ContentsFiltersCriteria';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import {History} from 'history';
import {IntlShape} from 'react-intl';
import {ModalAlertVersion2, ModalErrorVersion2} from '../../../../commonModals';
import NavigationACArea from '../../../../shared/NavigationACArea';
import {Tree} from '../../../TLO/CommonComponents/TreeView/Interfaces/TreeViewInterfaces';
import TreeView from '../../../TLO/CommonComponents/TreeView/TreeView';
import {
    apiDownloadTreeDocumentFile,
    apiFetchTree,
    apiSectionsTableFetch,
    apiYearsTableFetch,
} from '../../CommonFunctions/CommonFunctions';
import {getPreSignedURLFetchRequest} from '../../../../utils/requests';
import {saveAs} from 'file-saver';
import sendToList from './data/ContactList.json';
import styles from './SectionsACAREA.module.scss';
import {trackPageView} from '../../../../utils';

const modalScreenTypes = {
    ACAREA_SECTIONS: 'ACAREA_SECTIONS',
    ERROR: 'ERROR',
    OPEN_TRANSLATION_INFO: 'OPEN_TRANSLATION_INFO',
};

const DEFAULT_CRITERIA: ContentsFiltersCriteriaData = {
    section: '',
    year: '',
    language: '1',
    //
    refresh: false,
};

interface SectionsACAREAProps {
    intl: IntlShape;
    history: History;
}

const SectionsACAREA = (props: SectionsACAREAProps) => {
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [criteria, setCriteria] = useState<ContentsFiltersCriteriaData>(Object.assign({}, DEFAULT_CRITERIA));
    const [refresh, setRefresh] = useState(true);
    const [, setCriteriaCount] = useState(0);
    const [sectionsTable, setSectionsTable] = useState<Array<SectionElement>>([]);
    const [yearsTable, setYearsTable] = useState<Array<string>>([]);
    const [tree, setTree] = useState<Tree>(JSON.parse(JSON.stringify({})));
    const [tag, setTag] = useState<string>('');
    const [expanded, setExpanded] = useState<any[]>([]);
    const firstLoad = useFirstRender();

    useEffect(() => {
        trackPageView({documentTitle: 'acareaSections'});
    }, []);

    function useFirstRender() {
        const firstRender = useRef(true);

        useEffect(() => {
            firstRender.current = false;
        }, []);

        return firstRender.current;
    }

    const closeErrorModal = () => {
        setErrorMessage(null);
        setModalScreen(null);
    };

    const closeAlertModal = () => setModalScreen(null);

    const fetchTree = (year: string, categoryId: string, languageId: string) => {
        setLoading(true);
        apiFetchTree({year, categoryId, languageId})
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.tree && jsonResponse.tree.length > 0) {
                    if (languageId !== '1') {
                        jsonResponse.tree.forEach((meetingEl: any) => {
                            meetingEl.children.forEach((meetingItemEl: any) => {
                                const someResponse = meetingItemEl.children.some((docEl: any) => {
                                    return docEl.languageId === 1;
                                });
                                if (someResponse) {
                                    setModalScreen(modalScreenTypes.OPEN_TRANSLATION_INFO);
                                }
                            });
                        });
                    }
                    setTree(jsonResponse);
                } else {
                    setTree({tree: []});
                }
            })
            .catch(error => {
                setErrorMessage(`Fetch Tree error: ${error.message}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getSectionsTable = () => {
        setLoading(true);
        apiSectionsTableFetch()
            .then((jsonResponse: SectionsTableFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.categories) {
                    setSectionsTable(jsonResponse.data.categories);
                }
            })
            .catch(error => {
                setErrorMessage(`Sections Table fetch error: ${error.message}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getYearsTable = (sectionId: number) => {
        if (sectionId > 0 && !isNaN(sectionId)) {
            setLoading(true);
            apiYearsTableFetch(sectionId)
                .then(jsonResponse => {
                    if (jsonResponse && jsonResponse.data && jsonResponse.data.years) {
                        setYearsTable(jsonResponse.data.years);
                    }
                })
                .catch(error => {
                    setErrorMessage(`Years Table fetch error: ${error.message}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        }
    };

    const resetCriteria = () => {
        setTree(JSON.parse(JSON.stringify({})));
        setCriteria(Object.assign({}, DEFAULT_CRITERIA, criteria.pageSize));
        setCriteriaCount(0);
        setTag('');
        props.history.replace(`/acareaSections`);
    };

    const countCriteria = (contentsCriteria: ContentsFiltersCriteriaData) => {
        let countNum = 0;
        Object.keys(contentsCriteria).forEach(key => {
            if (
                contentsCriteria[key] &&
                contentsCriteria[key] !== DEFAULT_CRITERIA[key] &&
                contentsCriteria[key] !== ''
            )
                countNum++;
        });
        return countNum;
    };

    const parseCriteria = (unParsedCriteria: ContentsFiltersCriteriaData): ContentsFiltersCriteriaData => {
        const parsedCriteria: ContentsFiltersCriteriaData = JSON.parse(JSON.stringify({}));
        Object.keys(unParsedCriteria).forEach(key => {
            parsedCriteria[key] = unParsedCriteria[key];
        });

        return parsedCriteria;
    };

    const buildParams = (parsedCriteria: ContentsFiltersCriteriaData) => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(key => parsedCriteria[key] !== DEFAULT_CRITERIA[key] && key !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key]}`);
        const joinedParamsText = `?${paramArray.join('&')}`;
        props.history.replace(`/acareaSections${(paramArray.length > 0 && joinedParamsText) || ''}`);
    };

    const search = (shouldRefresh: boolean, urlLoad = false) => {
        setCriteria(Object.assign({}, criteria, {refresh: !!shouldRefresh}, !shouldRefresh && !urlLoad));
        const parsedCriteria = parseCriteria(criteria);
        buildParams(parsedCriteria);
        if (
            criteria.section &&
            criteria.section.length > 0 &&
            criteria.year &&
            criteria.year.length > 0 &&
            criteria.language &&
            criteria.language.length > 0
        ) {
            if (
                !criteria.section.includes('Please select a section') &&
                !criteria.year.includes('Please select a year')
            ) {
                const sectionNameList = sectionsTable.filter(section => section.ID === Number(criteria.section));
                if (sectionNameList.length === 1) {
                    setTag(sectionNameList[0].ABBREVIATION + criteria.year);
                    fetchTree(criteria.year, criteria.section, criteria.language);
                }
            } else {
                resetCriteria();
            }
        }
    };

    const loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const params = domainArray.pop();
            if (params) {
                params.split('&').forEach(param => {
                    const paramElements = param.split('=');
                    if (paramElements.length === 2) {
                        const key = paramElements[0];
                        criteria[key] = decodeURIComponent(paramElements[1]);
                    }
                });
                if (Object.keys(criteria).length > 0) {
                    setCriteria(Object.assign({}, criteria));
                    setCriteriaCount(countCriteria(criteria));
                    search(false, true);
                }
            }
        }
    };

    useEffect(() => {
        if (firstLoad) {
            getSectionsTable();
            loadUrlParams();
            setCriteria(Object.assign({}, criteria));
        }
    }, []);

    useEffect(() => {
        !firstLoad && sectionsTable.length > 0 && getYearsTable(Number(criteria.section));
    }, [sectionsTable]);

    useEffect(() => {
        !firstLoad && refresh && search(true);
    }, [sectionsTable, criteria.section, criteria.year, criteria.language]);

    useEffect(() => {
        firstLoad && search(false, true);
    }, [criteria.section, criteria.year, criteria.language]);

    const updateCriteriaValue = (
        // eslint-disable-next-line no-undef
        criteriaValue: Partial<ContentsFiltersCriteriaData>,
        callback = () => {},
        shouldRefresh = true
    ) => {
        setTree(JSON.parse(JSON.stringify({})));
        setRefresh(shouldRefresh);
        if (criteriaValue !== undefined) {
            setCriteria({...criteria, ...criteriaValue});
        }
        setCriteriaCount(countCriteria(criteria));
        callback && callback();
    };

    const downloadDocumentFile = (fileUUID: string, fileName: string) => {
        setLoading(true);
        apiDownloadTreeDocumentFile(fileUUID, criteria.section)
            .then(response => {
                if (response && response.signedUrl) {
                    const {signedUrl} = response;
                    getPreSignedURLFetchRequest(signedUrl)
                        .then(response => response.blob())
                        .then(responseBlob => {
                            const saveFileName = fileName ? fileName : fileUUID;
                            saveAs(responseBlob, saveFileName);
                        });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading file: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const rowClick = (rowElement: any) => {
        let order = rowElement && rowElement.order;
        const expandedCopy: any[] = [...expanded];
        let expandedUpdated: any[] = expandedCopy.includes(order)
            ? expandedCopy.filter(element => element !== rowElement.order)
            : [...expandedCopy, order];
        let additionalRemove: any[] = [];
        expandedUpdated.forEach(order => {
            let orderOfPreviousLevel;
            let subLevels = (order || '').split('.');
            let numberOfSubLevels = subLevels.length;
            if (numberOfSubLevels <= 2) {
                orderOfPreviousLevel = subLevels[0];
            }
            if (numberOfSubLevels === 3) {
                orderOfPreviousLevel = subLevels[0] + '.' + subLevels[1];
            }
            if (orderOfPreviousLevel && !expandedUpdated.includes(orderOfPreviousLevel)) {
                additionalRemove.push(order);
            }
        });
        expandedUpdated = expandedUpdated.filter(order => !additionalRemove.includes(order));
        setExpanded(expandedUpdated);
    };

    const rowClass = (rowObject: any) => {
        let orderOfPreviousLevel;
        let subLevels = (rowObject.order || '').split('.');
        let numberOfSubLevels = subLevels.length;
        if (numberOfSubLevels <= 2) {
            orderOfPreviousLevel = subLevels[0];
        }
        if (numberOfSubLevels === 3) {
            orderOfPreviousLevel = subLevels[0] + '.' + subLevels[1];
        }
        if (rowObject && rowObject.order && !expanded.includes(orderOfPreviousLevel)) {
            if (rowObject.order.split('.').length > 1) {
                return styles.hide;
            }
        }
        if (rowObject && rowObject.order && expanded.includes(rowObject.order)) {
            return styles.expanded;
        }
        return styles.initial;
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {modalScreen === modalScreenTypes.OPEN_TRANSLATION_INFO ? (
                <ModalAlertVersion2
                    title={`Information`}
                    message={`There is no translation for some of the document files.`}
                    close={closeAlertModal}
                />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={'AC Area'} />
            <NavigationACArea />
            <MainWrapper>
                <FormWrapper paddingFormContent={'sm'}>
                    <ContentsFiltersCriteria
                        criteria={criteria}
                        updateCriteriaValue={updateCriteriaValue}
                        getYearsTable={getYearsTable}
                        sectionsList={sectionsTable}
                        yearsList={yearsTable}
                        adminarea={false}
                        treeIsEdited={false}
                        resetCriteria={resetCriteria}
                    />
                </FormWrapper>
                {criteria.section &&
                    criteria.section.length > 0 &&
                    criteria.year &&
                    criteria.year.length > 0 &&
                    criteria.language &&
                    criteria.language.length > 0 &&
                    tag.length > 0 && (
                        <TreeView
                            tree={tree}
                            treeIsEdited={false}
                            tag={tag}
                            isAdmin={false}
                            isGeneral={true}
                            downloadFile={downloadDocumentFile}
                            saveTree={() => {}}
                            discardTreeChanges={() => {}}
                            isACArea={true}
                            rowClick={rowClick}
                            rowClass={rowClass}
                        />
                    )}
            </MainWrapper>
            <Footer customSendToList={sendToList.slice(1)} headerTitle={'Contact Webmaster'} subjectTag={'[ACAREA]'} />
        </>
    );
};

export default SectionsACAREA;
