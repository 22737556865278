import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import MaskEmail1 from './screenshots/MaskEmail_1.PNG';
import MaskEmail2 from './screenshots/MaskEmail_2.PNG';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeMaskEmailHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>Displays an email input like the following: </p>
                    <img src={MaskEmail1} alt="TextNumberOfLines_1" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>
                        If the value of the input is not a valid email address, then error styles are applied to the
                        input:
                    </p>
                    <img src={MaskEmail2} alt="TextNumberOfLines" className={styles.modalHelpImage} />
                </>
            }
            footer={<Button clickAction={close}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeMaskEmailHelp;
