import React from 'react';
import {apiLogout} from '~commonApi/authentication';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {trackPageView} from '../../utils';

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    error: string;
    status: string;
}

class Logout extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {error: '', status: ''};
    }

    componentDidMount() {
        trackPageView({documentTitle: 'logout'});
        const parameters: any = loadUrlParams();
        const {home} = parameters;
        let targetPath: string;
        if (home) {
            targetPath = '/login?home=${origin}';
        } else {
            targetPath = '/login';
        }
        //
        if (this.isLogged()) {
            apiLogout().then(() => this.props.history.replace(targetPath));
        } else {
            this.props.history.replace(targetPath);
        }
    }

    isLogged = () => {
        const remember = localStorage.getItem('remember') === '1';
        return (remember ? localStorage : sessionStorage).getItem('token');
    };

    render() {
        return `Logging out...`;
    }
}

export default withRouter(injectIntl(Logout));

function loadUrlParams() {
    const domainArray: any = document.location.href.split('?');
    const parameters: any = {};
    if (domainArray.length > 1) {
        const params = domainArray.pop();
        params.split('&').forEach((param: any) => {
            const paramElements: any = param.split('=');
            if (paramElements.length === 2) {
                const key = paramElements[0];
                parameters[key] = decodeURIComponent(paramElements[1]);
            }
        });
    }

    return parameters;
}
