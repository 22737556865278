import React from 'react';
import {injectIntl} from 'react-intl';
import SelectInput from '~components/SelectInput';
import Empty from '~components/Empty';
import {apiLegalProceedings} from './MyPVRAdminProceedingsService';
import styles from './MyPVRAdminProceedings.module.scss';
import CustomTable from '~components/CustomTable';
import {FORMAT_DATE, formatDateEasy, reformatDateEasy} from '~components/FormatFunctions';
import {withRouter} from 'react-router-dom';
import getIcon from '~utils/icons';
import MyPVRAdminProceedingsActions from './MyPVRAdminProceedingsActions';
import {apiFileListLogs} from '../CPVOLogs/CPVOLogsService';
import {getDecodedJWT, trackPageView} from '../../utils';
import {USER_SHORTKEY_TO_CONTACTID} from '../CommunicationCentreInternal/CommunicationCentreInternal.utils';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import {Button} from '../../componentsFormV2';
import NavigationBackOffice from '../../shared/NavigationBackOffice';
import {ModalAlertVersion2, ModalCPVOLogsVersion2, ModalStatusInfoVersion2} from '../../commonModals';
import {
    MODAL_COMPOSE_MESSAGE_ORIGIN_SCREENS,
    ModalCommunicationCenterCompose,
    ModalMyPVRAdminProceedingsDocumentAccessByUser,
    ModalMyPVRAdminProceedingsEdit,
} from './modals';

const ALL = 'All';

const RESULT_FIELDS_ALL = ['user', 'applicationNumbers', 'type', 'status', 'requestDate', 'deadline'];

const requestStatusOptions = [
    {id: 'new', value: 'New'},
    {id: 'open', value: 'Open'},
    {id: 'closed', value: 'Closed'},
    {id: 'admissibilityCheck', value: 'Admissibility Check'},
];

const DEFAULT_CRITERIA = {
    requestType: ALL,
    requestStatus: ALL,
    pageNumber: 1,
    pageSize: 10,
    order: 'requestDate',
    reverse: false,
    refresh: false,
    excel: false,
};

const DATEFORMAT_CRITERIA_KEYS = [
    'applicationDateFrom',
    'applicationDateTo',
    'grantDateFrom',
    'grantDateTo',
    'withdrawalDateFrom',
    'withdrawalDateTo',
];

const modalScreen = {
    STATUS_INFO: 'STATUS_INFO',
    LEGAL_ACTIONS: 'LEGAL_ACTIONS',
    LEGAL_PROCEEDING_OBJECTION: 'LEGAL_PROCEEDING_OBJECTION',
    MODAL_FILES: 'MODAL_FILES',
};

const getSelectListOfRequestType = decodedJWT => {
    let {id: contactId} = decodedJWT || {};
    if (parseInt(contactId) === USER_SHORTKEY_TO_CONTACTID.FB) {
        return [{id: 'appeal', value: 'Appeal'}];
    } else {
        return [
            {id: 'objection', value: 'Objection'},
            {id: 'nullity', value: 'Nullity'},
            {id: 'cancellation', value: 'Cancellation'},
            {id: 'restitutioInIntegrum', value: 'Restitutio In Integrum'},
            {id: 'other', value: 'Other'},
        ];
    }
};

const DEFAULT_COUNT = 0;

class MyPVRAdminProceedings extends React.Component {
    constructor(props) {
        super(props);
        this.decodedJWT = getDecodedJWT();
        this.requestTypeOptions = getSelectListOfRequestType(this.decodedJWT);
        if (this.requestTypeOptions?.length === 1) {
            DEFAULT_CRITERIA['requestType'] = this.requestTypeOptions[0].id;
        }
        this.state = {
            clients: null,
            advancedOpened: false,
            errorTitle: '',
            errorMessage: '',
            modalScreen: null,
            loading: 0,
            selectedSpecies: {},
            requests: null,
            timestamp: null,
            publicationChapterList: [],
            examinationOfficeCountries: [],
            count: DEFAULT_COUNT,
            criteria: DEFAULT_CRITERIA,
            criteriaCount: 0,
            lastSearch: (this.lastSearchCriteria && this.lastSearchCriteria.dateTime) || null,
            subjectToAnAppeal: false,
            clientRepresentant: true,
            gazetteIssueDisabled: true,
            speciesListByIdName: null,
            speciesNameHash: null,
            legalBasis: null,
            selectedApplicationNumber: {},
            modalDocumentRights: false,
            modalEdit: false,
            modalComposeMessage: false,
            modalComposeMessageApplicationNumbers: [],
            modalComposeMessageRequestorName: '',
            modalComposeMessageRequestorThirdPartyId: '',
            modalComposeMessageSubtypeOfDocument: '',
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'adminProceedings'});
        this.search();
        this.loadUrlParams();
    }

    loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const criteria = Object.assign({}, this.initialCriteria);
            const params = domainArray.pop();
            params.split('&').forEach(param => {
                const paramElements = param.split('=');
                if (paramElements.length === 2) {
                    const key = paramElements[0];
                    criteria[key] = decodeURIComponent(paramElements[1]);
                }
            });
            if (Object.keys(criteria).length > 0) {
                const selectedSpecies = {};
                if (criteria.speciesIds && criteria.speciesIds !== '') {
                    criteria.speciesIds.split(',').forEach(speciesId => (selectedSpecies[speciesId] = false));
                }
                DATEFORMAT_CRITERIA_KEYS.forEach(criteriaKey => {
                    if (/^[0-9]{4}(-([0-9]){2}){2}$/.test(criteria[criteriaKey])) {
                        criteria[criteriaKey] = formatDateEasy(criteria[criteriaKey]);
                    }
                });
                const criteriaCount = this.countCriteria(criteria);
                this.setState(
                    Object.assign(
                        {},
                        {
                            criteria,
                            criteriaCount,
                            selectedSpecies,
                        },
                        criteria.order && {defaultOrder: criteria.order},
                        criteria.reverse && {reverseOrder: criteria.reverse}
                    )
                );
            }
        }
    };

    showError = (title, message) =>
        this.setState({
            errorTitle: title,
            errorMessage: message,
        });

    closeError = () =>
        this.setState({
            errorTitle: '',
            errorMessage: '',
        });

    toggleAdvancedSearch = () =>
        this.setState(
            prevState => ({
                advancedOpened: !prevState.advancedOpened,
            }),
            () => {
                if (this.state.advancedOpened && this.setSelectedFunction && this.state.criteria.applicants) {
                    this.setSelectedFunction(this.state.criteria.applicants.split(','));
                }
            }
        );

    showModalSelectLegalAction = applicationNumber => {
        if (applicationNumber) {
            this.setState({modalScreen: modalScreen.LEGAL_ACTIONS, legalActionTarget: [applicationNumber]});
        } else {
            if (
                Object.values(this.state.selectedApplicationNumbers).filter(
                    i => 'GA'.indexOf(i.applicationStatus) !== -1
                ).length !== Object.keys(this.state.selectedApplicationNumbers).length
            ) {
                alert('Error, some selected applications are not in status Active or Granted');
            } else {
                this.setState(prev => ({
                    modalScreen: modalScreen.LEGAL_ACTIONS,
                    legalActionTarget: Object.keys(prev.selectedApplicationNumbers),
                }));
            }
        }
    };

    closeModal = () =>
        this.setState({modalScreen: null, legalActionTarget: []}, () =>
            this.buildParams(this.parseCriteria(this.state.criteria))
        );

    closeModalInfo = () => this.setState({modalInfo: null});

    parseCriteria = criteria => {
        const parsedCriteria = {};
        Object.keys(criteria).map(key => {
            if (DATEFORMAT_CRITERIA_KEYS.indexOf(key) !== -1 && criteria[key] !== '') {
                const date = criteria[key];
                parsedCriteria[key] = reformatDateEasy(date);
            } else if (key === 'denominationFilter' && !criteria['denomination']) {
                criteria[key] = DEFAULT_CRITERIA[key];
            } else if (key === 'speciesNameFilter' && !criteria['speciesName']) {
                criteria[key] = DEFAULT_CRITERIA[key];
            } else {
                parsedCriteria[key] = criteria[key];
            }
        });
        return parsedCriteria;
    };

    search = (refresh, urlLoad) =>
        this.setState(
            prev => ({
                loading: ++prev.loading,
                subjectToAnAppeal: false,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams(parsedCriteria);
                apiLegalProceedings(parsedCriteria, DEFAULT_CRITERIA)
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.requests) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        requests: jsonResponse.data.requests,
                                        timestamp: Date.now(),
                                    },
                                    jsonResponse.data.count && {count: jsonResponse.data.count}
                                );
                            });
                        }
                    })
                    .catch(error => {
                        ERROR`Register search list error: ${error.message}`;
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );

    buildParams = parsedCriteria => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(i => parsedCriteria[i] !== DEFAULT_CRITERIA[i] && i !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key]}`);
        this.props.history.replace(`/adminProceedings${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`);
    };

    countCriteria = criteria => {
        let count = 0;
        Object.keys(criteria).map(key => {
            if (criteria[key] && criteria[key] !== DEFAULT_CRITERIA[key] && criteria[key] !== '') count++;
        });
        return count;
    };

    resetCriteria = () =>
        this.setState(prev => ({
            registers: null,
            selectedSpecies: {},
            criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
            criteriaCount: 0,
            gazetteIssueDisabled: true,
        }));

    updateCriteriaValue = (criteriaValue, callback, refresh) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                // DEBUG`new criteria: ${{...criteria}}`;
                const criteriaCount = this.countCriteria(criteria);
                pageNumberChanged = criteriaValue.pageNumber && prev.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse && prev.reverse !== criteriaValue.reverse;
                return {criteria, criteriaCount};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    legalProceeding = applicationNumber => {
        const parsedCriteria = this.parseCriteria(this.state.criteria);
        this.buildParams(Object.assign({}, parsedCriteria, {legalProceeding: applicationNumber}));
        this.setState({legalProceeding: applicationNumber, modalScreen: modalScreen.LEGAL_PROCEEDING_OBJECTION});
    };

    refreshScreen = () => this.setState({timestamp: Date.now()});

    openModalDocumentRights = (applicationNumber, contactId) =>
        this.setState({modalDocumentRights: true, target: applicationNumber, targetContactId: contactId});

    closeModalDocumentRights = () => this.setState({modalDocumentRights: false, target: null, targetContactId: null});

    openModalEdit = object =>
        this.setState({
            modalEdit: true,
            modalCriteria: {
                requestId: object.requestId,
                requestStatus: object.status,
                requestType: object.type,
                deadline: object.deadline,
            },
        });

    closeModalEdit = refresh => this.setState({modalEdit: false}, () => refresh && this.search());

    openModalComposeMessage = rowObject => {
        const {applicationNumbers = [], forename, surname, thirdPartyId, type} = rowObject || {};
        this.setState({
            modalComposeMessage: true,
            modalComposeMessageApplicationNumbers: applicationNumbers,
            modalComposeMessageRequestorName: `${forename} ${surname}`,
            modalComposeMessageRequestorThirdPartyId: thirdPartyId,
            modalComposeMessageSubtypeOfDocument: type,
        });
    };

    closeModalComposeMessage = () =>
        this.setState({
            modalComposeMessage: false,
            modalComposeMessageApplicationNumbers: [],
            modalComposeMessageRequestorName: '',
            modalComposeMessageRequestorThirdPartyId: '',
            modalComposeMessageSubtypeOfDocument: '',
        });

    openModal = ({folder, subject, comments}) =>
        this.setState(
            prev => ({
                loading: ++prev.loading,
            }),
            () =>
                apiFileListLogs('uploadDocuments', folder)
                    .then(jsonResult =>
                        this.setState({
                            files: jsonResult.files,
                            folder,
                            subject,
                            comments,
                            modalScreen: modalScreen.MODAL_FILES,
                            timestamp: Date.now,
                        })
                    )
                    .then(() =>
                        this.setState(prev => ({
                            loading: --prev.loading,
                        }))
                    )
        );

    onRequestStatusChange = ({target: {value: requestStatus}}) => this.updateCriteriaValue({requestStatus});

    onRequestTypeChange = ({target: {value: requestType}}) => this.updateCriteriaValue({requestType});

    render() {
        const requestStatusHash = {};
        requestStatusOptions.forEach(i => (requestStatusHash[i.id] = i.value));
        const requestTypeHash = {};
        this.requestTypeOptions.forEach(i => (requestTypeHash[i.id] = i.value));
        const actions = MyPVRAdminProceedingsActions(this.openModalEdit, this.openModalComposeMessage);
        const initApplicationNumbersForModalComposeMessage =
            ((this.state.modalComposeMessageApplicationNumbers || []).length &&
                this.state.modalComposeMessageApplicationNumbers[0].split(',')) ||
            [];
        return (
            <>
                {this.state.modalInfo ? (
                    <ModalAlertVersion2
                        title={'General information'}
                        message={this.state.modalInfo}
                        close={this.closeModalInfo}
                    />
                ) : null}
                {this.state.modalScreen === modalScreen.STATUS_INFO ? (
                    <ModalStatusInfoVersion2 filter={['A', 'W', 'R', 'G', 'T']} close={this.closeModal} />
                ) : null}
                {this.state.modalDocumentRights === true ? (
                    <ModalMyPVRAdminProceedingsDocumentAccessByUser
                        intl={this.props.intl}
                        applicationNumber={this.state.target}
                        contactId={this.state.targetContactId}
                        close={this.closeModalDocumentRights}
                    />
                ) : null}
                {this.state.modalEdit === true ? (
                    <ModalMyPVRAdminProceedingsEdit
                        criteria={this.state.modalCriteria}
                        intl={this.props.intl}
                        close={this.closeModalEdit}
                    />
                ) : null}
                {this.state.modalScreen === modalScreen.MODAL_FILES ? (
                    <ModalCPVOLogsVersion2
                        intl={this.props.intl}
                        zone={'uploadDocuments'}
                        folder={this.state.folder}
                        subject={this.state.subject}
                        comments={this.state.comments}
                        notice={false}
                        timestamp={this.state.timestamp}
                        files={this.state.files}
                        userMode={true}
                        close={this.closeModal}
                    />
                ) : null}
                {this.state.modalComposeMessage ? (
                    <ModalCommunicationCenterCompose
                        intl={this.props.intl}
                        originScreen={MODAL_COMPOSE_MESSAGE_ORIGIN_SCREENS.ADMIN_PROCEEDINGS}
                        initApplicationNumbers={initApplicationNumbersForModalComposeMessage}
                        adminProccedingsComposeMessageRequestorName={this.state.modalComposeMessageRequestorName}
                        adminProccedingsComposeMessageRequestorThirdPartyId={
                            this.state.modalComposeMessageRequestorThirdPartyId
                        }
                        adminProccedingsComposeMessageSubtypeOfDocument={
                            this.state.modalComposeMessageSubtypeOfDocument
                        }
                        close={this.closeModalComposeMessage}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={'Admin Proceedings'} />
                <NavigationBackOffice />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <SelectInput
                            double={true}
                            outsideLabel={`Request status`}
                            outsideLabelWidth={160}
                            value={this.state.criteria.requestStatus}
                            onChange={this.onRequestStatusChange}
                            list={requestStatusOptions}
                        />
                        <SelectInput
                            disabled={this.requestTypeOptions?.length === 1}
                            double={true}
                            list={this.requestTypeOptions}
                            notAll={this.requestTypeOptions?.length === 1}
                            onChange={this.onRequestTypeChange}
                            outsideLabel={`Request type`}
                            outsideLabelWidth={160}
                            value={this.state.criteria.requestType}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        <div className={styles.AdvancedSearchLinkSpacer}>
                            <Empty width={160} oneLine={true} />
                        </div>
                        <Empty oneLine={true} />
                        <FormFooter>
                            <Button variation={'secondary'} clickAction={this.resetCriteria}>{`Clear fields`}</Button>
                            <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                        </FormFooter>
                        <div style={{clear: 'both'}}>{}</div>
                    </FormWrapper>
                    <CustomTable
                        version={2}
                        loading={this.state.loading !== 0}
                        pageNumber={this.state.criteria.pageNumber}
                        {...this.props}
                        tableName={'requestLegalProceedings'}
                        tableType={'OBJECT'}
                        tableSource={this.state.requests}
                        timestamp={this.state.timestamp}
                        dataFilter={null}
                        id={'requestId'}
                        pagination={true}
                        hideExcelButton={true}
                        resultFieldsAll={RESULT_FIELDS_ALL}
                        resultFieldsDefault={RESULT_FIELDS_ALL}
                        intl={this.props.intl}
                        defaultOrder={this.state.criteria.order}
                        reverseOrder={this.state.criteria.reverse}
                        pageSize={this.state.criteria.pageSize}
                        filterFunctions={null}
                        // count={this.state.count}
                        count={this.state.count}
                        formatFunctions={{
                            applicationNumbers: (applicationNumbers, object) => {
                                const registerList = [];
                                applicationNumbers.forEach(applicationNumber => {
                                    const applicationNumberLabel = applicationNumber;
                                    registerList.push(
                                        <p key={applicationNumberLabel} style={{margin: 0}} className="ng-scope">
                                            <a
                                                onClick={() =>
                                                    this.openModalDocumentRights(
                                                        applicationNumberLabel,
                                                        object.contactId
                                                    )
                                                }
                                                title="Open registers"
                                                className="ng-binding"
                                            >
                                                {applicationNumberLabel}
                                            </a>
                                        </p>
                                    );
                                });
                                return registerList;
                            },
                            requestDate: FORMAT_DATE,
                            deadline: FORMAT_DATE,
                            type: type => requestTypeHash[type] || type,
                            status: status => requestStatusHash[status] || status,
                            user: (from, object) =>
                                `${object.forename || ''} ${object.surname || ''}${
                                    object.company && ` (${object.company})`
                                }`,
                        }}
                        notSortable={['user']}
                        excelFormatFunctions={{
                            applicants: applicants => applicants.join('; '),
                        }}
                        updateCriteriaValue={this.updateCriteriaValue}
                        forehandColumn={record => (
                            <img
                                style={{width: 20, height: 20, cursor: 'pointer'}}
                                alt={'Download'}
                                src={getIcon('pdf')}
                                onClick={event => {
                                    event.stopPropagation();
                                    this.openModal({folder: record.folder});
                                }}
                            />
                        )}
                        actions={actions}
                    />
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(withRouter(MyPVRAdminProceedings));
