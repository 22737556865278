import React, {useEffect, useState} from 'react';
import CustomTable from '../../../../components/CustomTable';
import {Footer, HeaderLoading, HeaderLogoMenu, HeaderTitleAndVersion, MainWrapper} from '../../../../componentsLayout';
import {History} from 'history';
import {ModalErrorVersion2} from '../../../../commonModals';
import NavigationACArea from '../../../../shared/NavigationACArea';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {injectIntl, IntlShape} from 'react-intl';
import sendToList from './data/ContactList.json';
import styles from './RulesOfProcedures.module.scss';
import {trackPageView} from '../../../../utils';

const modalScreenTypes = {
    ACAREA_RULES_OF_PROCEDURES: 'ACAREA_RULES_OF_PROCEDURES',
    ERROR: 'ERROR',
};

interface RulesOfProceduresProps {
    intl: IntlShape;
    history: History;
}

interface RulesOfProcList {
    Id: number;
    name: string;
    docTitle: string;
    docLink: string;
    language: string;
}

const RulesOfProcedures = (props: RulesOfProceduresProps & RouteComponentProps) => {
    const RESULT_FIELDS_DEFAULT = ['Id', 'name', 'docTitle', 'language'];
    const RULES_OF_PROCEDURES: RulesOfProcList[] = [
        {
            Id: 1,
            name: 'Rules of procedure of the Administrative Council of the Community Plant Variety Office',
            docTitle: 'Consolidated version',
            docLink: 'https://cpvoextranet.cpvo.europa.eu/DATA/ACAREA/documents/Rules_of_procedure_AC_en_2020.pdf',
            language: 'EN',
        },
        {
            Id: 2,
            name: 'Mission expenses reimbursement form',
            docTitle: 'PDF form',
            docLink: 'https://cpvoextranet.cpvo.europa.eu/DATA/ACAREA/documents/Application_form_reimbursement_en.pdf',
            language: 'EN',
        },
        {
            Id: 3,
            name: 'Rules of reimbursement for outside experts',
            docTitle: 'Decision',
            docLink:
                'https://cpvoextranet.cpvo.europa.eu/DATA/ACAREA/documents/Rules_Reimbursement_outside_experts.pdf',
            language: 'EN',
        },
        {
            Id: 4,
            name: 'AC Decision on eligibility criteria for observership',
            docTitle: 'Decision',
            docLink: 'https://cpvoextranet.cpvo.europa.eu/DATA/ACAREA/documents/Decision_Observers_en.pdf',
            language: 'EN',
        },
    ];
    const [loading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [rulesOfProceduresList] = useState<Array<RulesOfProcList>>(RULES_OF_PROCEDURES);

    useEffect(() => {
        trackPageView({documentTitle: 'acareaRulesOfProcedures'});
    }, []);

    const closeErrorModal = () => {
        setErrorMessage(null);
        setModalScreen(null);
    };

    const getDocTitleWithLink = (docTitle: string | undefined, rowObject: RulesOfProcList | null) => {
        if (rowObject) {
            return (
                <a href={rowObject?.docLink} target={`_blank`} title={docTitle}>
                    {docTitle}
                </a>
            );
        } else {
            return '';
        }
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={'AC Area'} />
            <NavigationACArea />
            <MainWrapper>
                <div className={styles.rulesOfProceduresList}>
                    <CustomTable
                        version={2}
                        {...props}
                        tableName={'rulesOfProceduresList'}
                        tableType={'OBJECT'}
                        tableSource={rulesOfProceduresList}
                        id={'Id'}
                        hideExcelButton={true}
                        resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                        intl={props.intl}
                        defaultOrder={'Id'}
                        reverseOrder={false}
                        count={rulesOfProceduresList.length}
                        formatFunctions={{
                            docTitle: (docTitle: string, rowObject: RulesOfProcList) =>
                                getDocTitleWithLink(docTitle, rowObject),
                        }}
                    />
                </div>
            </MainWrapper>
            <Footer customSendToList={sendToList.slice(1)} headerTitle={'Contact Webmaster'} subjectTag={'[ACAREA]'} />
        </>
    );
};

export default injectIntl(withRouter(RulesOfProcedures));
