import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArchive, faFlag, faList, faReply} from '@fortawesome/free-solid-svg-icons';
import {ICommunicationMessageInternal} from '../../types';
import {faComment} from '@fortawesome/free-regular-svg-icons';
import stylesCustomTable from '../OnlineApplications/customTable/OnlineApplicationsCustomTableActionButtons.module.scss';
import {WORKFLOW} from '../../utils/constants';

function renderButton(
    popupContent: string,
    handler: (id: any, rowObject: ICommunicationMessageInternal) => void,
    icon: any
) {
    return {
        title: popupContent,
        icon: () => {
            return (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon} />
                </a>
            );
        },
        handler,
    };
}

function renderIconCheck(onTableIconFlagClick: (rowObject: ICommunicationMessageInternal) => void) {
    return {
        title: null,
        icon: (rowObject: ICommunicationMessageInternal) => {
            return (
                <div
                    className={stylesCustomTable.iconWrap}
                    title={rowObject.flagged ? 'Un-flag message' : 'Flag message'}
                >
                    <FontAwesomeIcon icon={faFlag as any} color={rowObject.flagged ? 'red' : 'green'} />
                </div>
            );
        },
        handler: (_: any, rowObject: ICommunicationMessageInternal) => {
            return onTableIconFlagClick(rowObject);
        },
    };
}

export default function CCInternalInboxActionButtons(
    onTableIconReplyClick: (rowObject: ICommunicationMessageInternal) => void,
    onTableIconArchiveClick: (communicationId: number) => void,
    onCustomTableIconHistoryClick: (rowObject: ICommunicationMessageInternal) => void,
    onCustomTableIconCommentsClick: (rowObject: ICommunicationMessageInternal) => void,
    onTableIconFlagClick: (rowObject: ICommunicationMessageInternal) => void
) {
    const buttons = [];
    buttons.push(renderButton('Reply', (_, rowObject) => onTableIconReplyClick(rowObject), faReply));
    buttons.push(renderIconCheck(onTableIconFlagClick));
    if (WORKFLOW) {
        buttons.push(renderButton('Comments', (_, rowObject) => onCustomTableIconCommentsClick(rowObject), faComment));
    }
    buttons.push(renderButton('Archive', communicationId => onTableIconArchiveClick(communicationId), faArchive));
    buttons.push(renderButton('History', (_, rowObject) => onCustomTableIconHistoryClick(rowObject), faList));
    return buttons;
}
