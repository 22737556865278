import React from 'react';
import COUNTRIES_LIST from '../VFMyAlerts/data/COUNTRIES_LIST.json';
import {Button} from '../../componentsFormV2';
import Checkbox from '~components/Checkbox';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import {ISearchState} from './VFSearchVarieties';
import {ModalCustomVersion2} from '../../commonModals';
import TextInput from '~components/TextInput';
import {formatSearchDate} from '../../utils/utilsVarietyFinder';
import {injectIntl} from 'react-intl';

interface IProps {
    close: any;
    intl: any;
    searchState: ISearchState;
    onSaveButtonClick: any;
    orderedFilters: {name: string; enabled: boolean}[];
}

interface IState {
    errorSearchName: string;
    defaultSearch: boolean;
    searchName: string;
}

export const getSearchTermsPayloadFromState = (searchState: any) => {
    const searchTermsPayload: any = {};
    const enabledFiltersNames = searchState.orderedFilters
        .filter(({enabled}: any) => enabled)
        .map(({name}: any) => name);
    const SELECT_MAP: any = {
        contains: 'contains',
        ends: 'ends',
        equals: 'exact',
        starts: 'starts',
    };
    if (enabledFiltersNames.includes('Denomination') || searchState.criteria.denominationValue) {
        searchTermsPayload.denomination = {
            name: searchState.criteria.denominationValue,
            nameSelect: SELECT_MAP[searchState.criteria.denominationFilter],
        };
    }
    if (
        enabledFiltersNames.includes('Species Latin Name') ||
        Object.keys(searchState.textLabelInputSpeciesLatinNameSelectedElements).length
    ) {
        searchTermsPayload.speciesLatinName = {
            name: Object.keys(searchState.textLabelInputSpeciesLatinNameSelectedElements).map(speciesLatinName => ({
                customOption: true,
                value: speciesLatinName,
            })),
            nameCheck: searchState.textLabelInputSpeciesLatinNameIncludeSynonymsValue,
            nameSelect: SELECT_MAP[searchState.textLabelInputSpeciesLatinNameCurrentFilter],
        };
    }
    if (
        enabledFiltersNames.includes('Countries') ||
        Object.keys(searchState.textLabelInputCountriesSelectedElements).length
    ) {
        let countriesArray: any = [];
        Object.keys(searchState.textLabelInputCountriesSelectedElements)
            .filter(countryLabel => countryLabel !== 'All')
            .forEach((countryLabel: string) => {
                let foundCountry = COUNTRIES_LIST.find((country: any) => country.label === countryLabel);
                if (foundCountry) {
                    countriesArray.push(foundCountry);
                }
            });
        searchTermsPayload.countries = {};
        searchTermsPayload.countries.name = countriesArray;
    }
    if (
        enabledFiltersNames.includes('Denomination Nature') ||
        Object.keys(searchState.textLabelInputDenominationNatureSelectedElements).length
    ) {
        const denominationNatureArray: any = [];
        Object.entries(searchState.textLabelInputDenominationNatureSelectedElements).forEach(([value, label]) => {
            denominationNatureArray.push({label, value});
        });
        searchTermsPayload.denominationNature = {};
        searchTermsPayload.denominationNature.name = denominationNatureArray.filter(({value}: any) => value !== 'All');
    }
    if (
        enabledFiltersNames.includes('Variety Status') ||
        Object.keys(searchState.textLabelInputVarietyStatusSelectedElements).length
    ) {
        const varietyStatusArray: any = [];
        Object.entries(searchState.textLabelInputVarietyStatusSelectedElements).forEach(([value, label]) => {
            varietyStatusArray.push({label, value});
        });
        searchTermsPayload.varietyStatus = {};
        searchTermsPayload.varietyStatus.name = varietyStatusArray
            .filter(({value}: any) => value !== 'All')
            .map(({label, value}: any) => ({label, value: parseInt(value)}));
    }
    if (
        enabledFiltersNames.includes('Register type') ||
        Object.keys(searchState.textLabelInputRegisterTypeSelectedElements).length
    ) {
        const registerTypeArray: any = [];
        Object.entries(searchState.textLabelInputRegisterTypeSelectedElements).forEach(([value, label]) => {
            registerTypeArray.push({label, value});
        });
        searchTermsPayload.registerType = {};
        searchTermsPayload.registerType.name = registerTypeArray.filter(({value}: any) => value !== 'All');
    }
    if (
        enabledFiltersNames.includes('Species Crop Sector') ||
        Object.keys(searchState.textLabelInputSpeciesCropSectorSelectedElements).length
    ) {
        const speciesCropSectorArray: any = [];
        Object.entries(searchState.textLabelInputSpeciesCropSectorSelectedElements).forEach(([value, label]) => {
            speciesCropSectorArray.push({label, value});
        });
        searchTermsPayload.speciesCropSector = {};
        searchTermsPayload.speciesCropSector.name = speciesCropSectorArray.filter(({value}: any) => value !== 'All');
    }
    if (
        enabledFiltersNames.includes('Denomination status') ||
        Object.keys(searchState.textLabelInputSpeciesDenominationStatusSelectedElements).length
    ) {
        const denominationStatusArray: any = [];
        Object.entries(searchState.textLabelInputSpeciesDenominationStatusSelectedElements).forEach(
            ([value, label]) => {
                denominationStatusArray.push({label, value});
            }
        );
        searchTermsPayload.denominationStatus = {};
        searchTermsPayload.denominationStatus.name = denominationStatusArray.filter(({value}: any) => value !== 'All');
    }
    if (enabledFiltersNames.includes("Breeder's Name") || searchState.textLabelInputBreedersNameValue) {
        searchTermsPayload.breedersName = {
            name: searchState.textLabelInputBreedersNameValue
                ? [{value: searchState.textLabelInputBreedersNameValue}]
                : [],
            nameSelect: SELECT_MAP[searchState.textLabelInputBreedersNameCurrentFilter],
        };
    }
    if (enabledFiltersNames.includes("Breeder's Reference") || searchState.criteria.breedersReferenceValue) {
        searchTermsPayload.breedersReference = {
            name: searchState.criteria.breedersReferenceValue,
            nameSelect: SELECT_MAP[searchState.criteria.breedersReferenceFilter],
        };
    }
    if (enabledFiltersNames.includes('Parties') || searchState.textLabelInputPartiesValue) {
        searchTermsPayload.parties = {
            name: searchState.textLabelInputPartiesValue ? [{value: searchState.textLabelInputPartiesValue}] : [],
            nameSelect: SELECT_MAP[searchState.textLabelInputPartiesCurrentFilter],
        };
    }
    if (enabledFiltersNames.includes('Application Number') || searchState.textLabelInputApplicationNumberValue) {
        searchTermsPayload.applicationNumber = {
            name: searchState.textLabelInputApplicationNumberValue
                ? [{value: searchState.textLabelInputApplicationNumberValue}]
                : [],
            nameSelect: SELECT_MAP[searchState.textLabelInputApplicationNumberCurrentFilter],
        };
    }
    if (
        enabledFiltersNames.includes('Grant/Registration Number') ||
        searchState.textLabelInputGrantRegistrationNumberValue
    ) {
        searchTermsPayload.grantRegistrationNumber = {
            name: searchState.textLabelInputGrantRegistrationNumberValue
                ? [{value: searchState.textLabelInputGrantRegistrationNumberValue}]
                : [],
            nameSelect: SELECT_MAP[searchState.textLabelInputGrantRegistrationNumberCurrentFilter],
        };
    }
    if (enabledFiltersNames.includes('Register name') || searchState.textLabelInputRegisterNameValue) {
        searchTermsPayload.registerName = {
            name: searchState.textLabelInputRegisterNameValue
                ? [
                      {
                          customOption: true,
                          value: searchState.textLabelInputRegisterNameValue,
                      },
                  ]
                : [],
            nameSelect: SELECT_MAP[searchState.textLabelInputRegisterNameCurrentFilter],
        };
    }
    if (
        enabledFiltersNames.includes('Valid Denomination') ||
        (searchState.criteria.validDenomination && searchState.criteria.validDenomination !== 'All')
    ) {
        searchTermsPayload.registeredWithOther = {
            name: searchState.criteria.validDenomination !== 'All' ? searchState.criteria.validDenomination : '',
        };
    }
    if (
        enabledFiltersNames.includes('Species Class Code') ||
        searchState.textLabelInputSpeciesClassCodeValue ||
        Object.keys(searchState.textLabelInputSpeciesClassCodeSelectedElements).length
    ) {
        if (searchState.textLabelInputSpeciesClassCodeCurrentFilter === 'equals') {
            searchTermsPayload.speciesClassCode = {
                name: Object.keys(searchState.textLabelInputSpeciesClassCodeSelectedElements).map(speciesClassCode => ({
                    customOption: true,
                    value: speciesClassCode,
                })),
                nameSelect: SELECT_MAP[searchState.textLabelInputSpeciesClassCodeCurrentFilter],
            };
        } else {
            searchTermsPayload.speciesClassCode = {
                name: searchState.textLabelInputSpeciesClassCodeValue,
                nameSelect: SELECT_MAP[searchState.textLabelInputSpeciesClassCodeCurrentFilter],
            };
        }
    }
    if (
        enabledFiltersNames.includes('Species Class Name') ||
        searchState.textLabelInputSpeciesClassNameValue ||
        Object.keys(searchState.textLabelInputSpeciesClassNameSelectedElements).length
    ) {
        if (searchState.textLabelInputSpeciesClassNameCurrentFilter === 'equals') {
            searchTermsPayload.speciesClassName = {
                name: Object.keys(searchState.textLabelInputSpeciesClassNameSelectedElements).map(speciesClassName => ({
                    value: speciesClassName,
                })),
                nameSelect: SELECT_MAP[searchState.textLabelInputSpeciesClassNameCurrentFilter],
            };
        } else {
            searchTermsPayload.speciesClassName = {
                name: searchState.textLabelInputSpeciesClassNameValue,
                nameSelect: SELECT_MAP[searchState.textLabelInputSpeciesClassNameCurrentFilter],
            };
        }
    }
    if (
        enabledFiltersNames.includes('Application Date') ||
        searchState.criteria.applicationDateFrom ||
        searchState.criteria.applicationDateTo
    ) {
        const dateArray: any = [];
        searchState.criteria.applicationDateFrom &&
            dateArray.push({
                type: 'from',
                value: formatSearchDate(searchState.criteria.applicationDateFrom),
            });
        searchState.criteria.applicationDateTo &&
            dateArray.push({
                type: 'to',
                value: formatSearchDate(searchState.criteria.applicationDateTo),
            });
        searchTermsPayload.applicationDate = {
            name: dateArray,
        };
    }
    if (
        enabledFiltersNames.includes('Application Publication Date') ||
        searchState.criteria.applicationPublicationDateFrom ||
        searchState.criteria.applicationPublicationDateTo
    ) {
        const dateArray: any = [];
        searchState.criteria.applicationPublicationDateFrom &&
            dateArray.push({
                type: 'from',
                value: formatSearchDate(searchState.criteria.applicationPublicationDateFrom),
            });
        searchState.criteria.applicationPublicationDateTo &&
            dateArray.push({
                type: 'to',
                value: formatSearchDate(searchState.criteria.applicationPublicationDateTo),
            });
        searchTermsPayload.applicationPubDate = {
            name: dateArray,
        };
    }
    if (
        enabledFiltersNames.includes('Denomination Insertion Date') ||
        searchState.criteria.denominationInsertDateFrom ||
        searchState.criteria.denominationInsertDateTo
    ) {
        const dateArray: any = [];
        searchState.criteria.denominationInsertDateFrom &&
            dateArray.push({
                type: 'from',
                value: formatSearchDate(searchState.criteria.denominationInsertDateFrom),
            });
        searchState.criteria.denominationInsertDateTo &&
            dateArray.push({
                type: 'to',
                value: formatSearchDate(searchState.criteria.denominationInsertDateTo),
            });
        searchTermsPayload.denominationInsertionDate = {
            name: dateArray,
        };
    }
    if (
        enabledFiltersNames.includes('Grant/Registration Date') ||
        searchState.criteria.grandRegistrationDateFrom ||
        searchState.criteria.grandRegistrationDateTo
    ) {
        const dateArray: any = [];
        searchState.criteria.grandRegistrationDateFrom &&
            dateArray.push({
                type: 'from',
                value: formatSearchDate(searchState.criteria.grandRegistrationDateFrom),
            });
        searchState.criteria.grandRegistrationDateTo &&
            dateArray.push({
                type: 'to',
                value: formatSearchDate(searchState.criteria.grandRegistrationDateTo),
            });
        searchTermsPayload.grantRegistrationDate = {
            name: dateArray,
        };
    }
    if (
        enabledFiltersNames.includes('Grant/Registration Pub Date') ||
        searchState.criteria.publicationgrandRegistrationDateFrom ||
        searchState.criteria.publicationgrandRegistrationDateTo
    ) {
        const dateArray: any = [];
        searchState.criteria.publicationgrandRegistrationDateFrom &&
            dateArray.push({
                type: 'from',
                value: formatSearchDate(searchState.criteria.publicationgrandRegistrationDateFrom),
            });
        searchState.criteria.publicationgrandRegistrationDateTo &&
            dateArray.push({
                type: 'to',
                value: formatSearchDate(searchState.criteria.publicationgrandRegistrationDateTo),
            });
        searchTermsPayload.grantRegistrationPubDate = {
            name: dateArray,
        };
    }
    if (
        enabledFiltersNames.includes('Renewal Date') ||
        searchState.criteria.renewalregistrationDateFrom ||
        searchState.criteria.renewalregistrationDateTo
    ) {
        const dateArray: any = [];
        searchState.criteria.renewalregistrationDateFrom &&
            dateArray.push({
                type: 'from',
                value: formatSearchDate(searchState.criteria.renewalregistrationDateFrom),
            });
        searchState.criteria.renewalregistrationDateTo &&
            dateArray.push({
                type: 'to',
                value: formatSearchDate(searchState.criteria.renewalregistrationDateTo),
            });
        searchTermsPayload.renewalDate = {
            name: dateArray,
        };
    }
    if (
        enabledFiltersNames.includes('Variety Insertion Date') ||
        searchState.criteria.varietyInsertDateFrom ||
        searchState.criteria.varietyInsertDateTo
    ) {
        const dateArray: any = [];
        searchState.criteria.varietyInsertDateFrom &&
            dateArray.push({
                type: 'from',
                value: formatSearchDate(searchState.criteria.varietyInsertDateFrom),
            });
        searchState.criteria.varietyInsertDateTo &&
            dateArray.push({
                type: 'to',
                value: formatSearchDate(searchState.criteria.varietyInsertDateTo),
            });
        searchTermsPayload.varietyInsertionDate = {
            name: dateArray,
        };
    }
    return searchTermsPayload;
};

class ModalSaveSearch extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            errorSearchName: '',
            defaultSearch: false,
            searchName: '',
        };
    }

    getFieldValueByField = (field: string) => {
        switch (field) {
            case 'Application Date':
                return this.formatSearchDate(
                    this.props.searchState.criteria.applicationDateFrom,
                    this.props.searchState.criteria.applicationDateTo
                );
            case 'Application Publication Date':
                return this.formatSearchDate(
                    this.props.searchState.criteria.applicationPublicationDateFrom,
                    this.props.searchState.criteria.applicationPublicationDateTo
                );
            case 'Grant/Registration Date':
                return this.formatSearchDate(
                    this.props.searchState.criteria.grandRegistrationDateFrom,
                    this.props.searchState.criteria.grandRegistrationDateTo
                );
            case 'Grant/Registration Pub Date':
                return this.formatSearchDate(
                    this.props.searchState.criteria.publicationgrandRegistrationDateFrom,
                    this.props.searchState.criteria.publicationgrandRegistrationDateTo
                );
            case 'Renewal Date':
                return this.formatSearchDate(
                    this.props.searchState.criteria.renewalregistrationDateFrom,
                    this.props.searchState.criteria.renewalregistrationDateTo
                );
            case 'Variety Insertion Date':
                return this.formatSearchDate(
                    this.props.searchState.criteria.varietyInsertDateFrom,
                    this.props.searchState.criteria.varietyInsertDateTo
                );
            case 'Denomination Insertion Date':
                return this.formatSearchDate(
                    this.props.searchState.criteria.denominationInsertDateFrom,
                    this.props.searchState.criteria.denominationInsertDateTo
                );
            case 'Application Number':
                return this.formatSearchTextLabelInputValue(
                    this.props.searchState.textLabelInputApplicationNumberValue,
                    this.props.searchState.textLabelInputApplicationNumberCurrentFilter
                );
            case "Breeder's Name":
                return this.formatSearchTextLabelInputValue(
                    this.props.searchState.textLabelInputBreedersNameValue,
                    this.props.searchState.textLabelInputBreedersNameCurrentFilter
                );
            case "Breeder's Reference":
                return this.formatSearchTextLabelInputValue(
                    this.props.searchState.criteria.breedersReferenceValue,
                    this.props.searchState.criteria.breedersReferenceFilter
                );
            case 'Denomination':
                return this.formatSearchTextLabelInputValue(
                    this.props.searchState.criteria.denominationValue,
                    this.props.searchState.criteria.denominationFilter
                );
            case 'Grant/Registration Number':
                return this.formatSearchTextLabelInputValue(
                    this.props.searchState.textLabelInputGrantRegistrationNumberValue,
                    this.props.searchState.textLabelInputGrantRegistrationNumberCurrentFilter
                );
            case 'Parties':
                return this.formatSearchTextLabelInputValue(
                    this.props.searchState.textLabelInputPartiesValue,
                    this.props.searchState.textLabelInputPartiesCurrentFilter
                );
            case 'Register name':
                return this.formatSearchTextLabelInputValue(
                    this.props.searchState.textLabelInputRegisterNameValue,
                    this.props.searchState.textLabelInputRegisterNameCurrentFilter
                );
            case 'Denomination Nature':
                return this.formatSearchSelectedElements(
                    this.props.searchState.textLabelInputDenominationNatureSelectedElements
                );
            case 'Variety Status':
                return this.formatSearchSelectedElements(
                    this.props.searchState.textLabelInputVarietyStatusSelectedElements
                );
            case 'Register type':
                return this.formatSearchSelectedElements(
                    this.props.searchState.textLabelInputRegisterTypeSelectedElements
                );
            case 'Species Crop Sector':
                return this.formatSearchSelectedElements(
                    this.props.searchState.textLabelInputSpeciesCropSectorSelectedElements
                );
            case 'Denomination status':
                return this.formatSearchSelectedElements(
                    this.props.searchState.textLabelInputSpeciesDenominationStatusSelectedElements
                );
            case 'Countries':
                return this.formatCountries();
            case 'Valid Denomination':
                return this.formatSearchCheckbox(this.props.searchState.criteria.validDenomination);
            case 'Species Latin Name':
                return this.formatSearchSelectedElementsWithFilter(
                    this.props.searchState.textLabelInputSpeciesLatinNameSelectedElements,
                    this.props.searchState.textLabelInputSpeciesLatinNameCurrentFilter
                );
            case 'Species Class Name':
                return this.formatSearchSelectedElementsOrValueWithFilter(
                    this.props.searchState.textLabelInputSpeciesClassNameSelectedElements,
                    this.props.searchState.textLabelInputSpeciesClassNameValue,
                    this.props.searchState.textLabelInputSpeciesClassNameCurrentFilter
                );
            default:
                return 'test';
        }
    };

    formatSearchCheckbox = (value: any) => {
        if (!value || value === 'All') return 'N/A';
        return value;
    };

    formatCountries = () => {
        let countriesIds = Object.keys(this.props.searchState.textLabelInputCountriesSelectedElements)
            .filter(key => !['Non-EU'].includes(key))
            .map((country: string) => (((country || '').split('-') || []).pop() || '').trim());
        let countriesObject: any = {};
        if (this.props.searchState.textLabelInputCountriesSelectedElements['Non-EU']) {
            countriesObject['Non-EU'] = 'Non-EU';
        }
        countriesIds.forEach(countryId => (countriesObject[countryId] = countryId));
        return this.formatSearchSelectedElements(countriesObject);
    };

    formatSearchSelectedElementsOrValueWithFilter = (selectedElements: any, value: string, filter: any) => {
        if (!Object.keys(selectedElements).length && !value) {
            return 'N/A';
        } else {
            let selectedAllObject = {...selectedElements};
            if (value) selectedAllObject[value] = value;
            return `${this.capitalizeFirstLetter(filter)}: ${Object.values(selectedAllObject)
                .filter((selected: any) => selected !== 'All')
                .join(', ')}`;
        }
    };

    formatSearchSelectedElementsWithFilter = (selectedElements: any, filter: any) => {
        if (!Object.keys(selectedElements).length) {
            return 'N/A';
        } else {
            return `${this.capitalizeFirstLetter(filter)}: ${Object.values(selectedElements)
                .filter((selected: any) => selected !== 'All')
                .join(', ')}`;
        }
    };

    formatSearchSelectedElements = (selectedElements: any) => {
        return (
            Object.values(selectedElements)
                .filter((selected: any) => selected !== 'All')
                .join(', ') || 'N/A'
        );
    };

    formatSearchTextLabelInputValue = (value: any, filter: any) => {
        if (value) {
            return `${this.capitalizeFirstLetter(filter)}: ${value}`;
        }
        return 'N/A';
    };

    formatSearchDate = (from: any, to: any) => {
        let dates = [];
        from && dates.push(`from: ${from}`);
        to && dates.push(`to: ${to}`);
        return dates.join(' ').trim() || 'N/A';
    };

    capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

    onTextInputSearchNameChange = ({target: {value: searchName}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({searchName});

    onCheckboxDefaultSearchClick = () => this.setState(prev => ({defaultSearch: !prev.defaultSearch}));

    onButtonSaveSearchClick = () =>
        this.setState({errorSearchName: ''}, () => {
            if (!this.state.searchName) {
                return this.setState({errorSearchName: 'Field is required!'});
            }
            this.props.onSaveButtonClick({
                searchName: this.state.searchName,
                isDefault: this.state.defaultSearch,
                searchTerms: getSearchTermsPayloadFromState(this.props.searchState),
            });
        });

    render() {
        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={`Save search`}
                body={
                    <>
                        <TextInput
                            onChange={this.onTextInputSearchNameChange}
                            label={`Search name`}
                            mandatory={true}
                            value={this.state.searchName}
                            double={true}
                            errorVarietyFinder={this.state.errorSearchName}
                        />
                        <div style={{float: 'left', marginTop: 12}}>
                            <Checkbox
                                clickAction={this.onCheckboxDefaultSearchClick}
                                label={`Default search`}
                                value={this.state.defaultSearch}
                            />
                        </div>
                        <div style={{clear: 'both'}} />
                        <DataSheetFormSection title={'Summary'}>
                            {this.props.orderedFilters
                                .filter(({enabled}) => enabled)
                                .map(({name}) => (
                                    <React.Fragment key={name}>
                                        <DataSheetFormFields
                                            label={name}
                                            data={this.getFieldValueByField(name)}
                                            double={true}
                                        />
                                    </React.Fragment>
                                ))}
                        </DataSheetFormSection>
                        <div style={{clear: 'both'}} />
                    </>
                }
                footer={
                    <>
                        <Button clickAction={this.props.close} variation={'secondary'}>{`Close`}</Button>
                        <Button clickAction={this.onButtonSaveSearchClick}>{`Save search`}</Button>
                    </>
                }
            />
        );
    }
}

export default injectIntl(ModalSaveSearch);
