import React from 'react';
import cx from 'classnames';
import InputContainer from '~componentsForm/InputContainer';
import FirstRow from '~componentsForm/FirstRow';
import SecondRow from '~componentsForm/SecondRow';
import FirstRowLabel from '~componentsForm/FirstRowLabel';
import FirstRowInfo from '~componentsForm/FirstRowInfo';
import styles from './FileInput.module.scss';
import Label from './Label';
import CustomPopoverInput from './CustomPopoverInput';

export default function ({
    label,
    fileName,
    mandatory,
    infoAction,
    disabled,
    outsideLabel,
    outsideLabelWithHTML,
    outsideLabelWidth,
    double,
    triple,
    error,
    errorVarietyFinder,
    popOverText,
    popOverTextFirstRow,
    onFileUpload,
    size,
}) {
    return (
        <React.Fragment>
            {(outsideLabel || outsideLabel === '') && (
                <div className={styles.label}>
                    <Label width={outsideLabelWidth}>{outsideLabel}</Label>
                </div>
            )}
            {(outsideLabelWithHTML || outsideLabelWithHTML === '') && (
                <div className={cx(styles.label)}>
                    <Label width={outsideLabelWidth}>{outsideLabelWithHTML}</Label>
                </div>
            )}
            <InputContainer {...{double, triple, size}}>
                <FirstRow>
                    <FirstRowLabel {...{mandatory, popOverTextFirstRow}}>{label}</FirstRowLabel>
                    {infoAction && <FirstRowInfo onClick={infoAction} />}
                </FirstRow>
                <SecondRow
                    buttonIcon={popOverText}
                    errorBorderColor={errorVarietyFinder}
                    {...{
                        disabled,
                    }}
                    style={{position: 'relative'}}
                >
                    <div className={styles.buttonBrowse}>Browse</div>
                    <div className={styles.labelFileName}>{fileName}</div>
                    <input type="file" className={styles.inputFile} onChange={onFileUpload} />
                </SecondRow>
                {popOverText && <CustomPopoverInput content={popOverText} />}
                {errorVarietyFinder ? (
                    <div style={{marginLeft: 3, marginTop: 5, color: 'red'}}>{errorVarietyFinder}</div>
                ) : null}
            </InputContainer>
        </React.Fragment>
    );
}
