import {API_SERVERLESS, postFetch} from '~commonApi';

export interface IPayloadGroup {
    formId: number;
    groupId: number;
    jsonMapping: string | null;
    mandatory: boolean;
    mappingCode: string | null;
    messageButtonAddId: number | null;
    messageButtonDelId: number | null;
    messageHelpId: number | null;
    messageId: number;
    messageRemarkId: number | null;
    multi: any;
    orderDisplay: string;
}

export function apiBackOfficeFormGroupCreateOrUpdate(JSONRequest: IPayloadGroup): Promise<{
    newGroupid: number;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formGroupCreateOrUpdate`, true, JSONRequest);
}
