import React, {useEffect, useState} from 'react';
import {TLO_LIST_OF_TLOS_TREE_VALUE} from '../../../../commonApi';
import CustomTable from '../../../../components/CustomTable';
import {HeaderLoading} from '../../../../componentsLayout';
import {ModalConfirmVersion2, ModalErrorVersion2} from '../../../../commonModals';
import ModalCategory, {categoryTypes} from '../ModalCategory/ModalCategory';
import ModalContent from '../../../ACAREA/Screens/AdministratorPageContents/modals/ModalContent';
import ModalContentItem from '../../../ACAREA/Screens/AdministratorPageContents/modals/ModalContentItem';
import ModalContentDocument from '../../../ACAREA/Screens/AdministratorPageContents/modals/ModalContentDocument';
import ModalDocument from '../ModalDocument/ModalDocument';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Right} from '../ModalCategory/Interfaces/ModalCategoryInterfaces';
import TreeViewActionButtons from './TreeViewActionButtons/TreeViewActionButtons';
import TreeViewLabelsBottomCaption from './Components/TreeViewLabelsBottomCaption/TreeViewLabelsBottomCaption';
import TreeViewTopCaption from './Components/TreeViewTopCaption/TreeViewTopCaption';
import {Tree, TreeElement} from './Interfaces/TreeViewInterfaces';
import {TreeViewProps} from './props/TreeViewProps';
import {UploadedFileData} from '../ModalDocument/Interfaces/ModalDocumentInterfaces';
import {apiFetchDocumentCategoryRights} from '../../CommonApi/CommonApi';
import {apiDeleteTreeDocumentFile} from '../../../ACAREA/CommonFunctions/CommonFunctions';
import {
    apiFetchACAREARights,
    getLanguageAbbrvFromId,
} from '../../../ACAREA/Screens/AdministratorPageContents/AdministrationPageContentsService';
import {formatDateEasy} from '../../../../components/FormatFunctions';
import {
    getLatestDateOfTreeElements,
    getUserRoles,
    mostRecentDateCondition,
} from '../../CommonFunctions/CommonFunctions';
import {injectIntl} from 'react-intl';
import moment from 'moment';
import styles from './TreeView.module.scss';
import cx from 'classnames';

const ORDER_OPERATION = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
    FIRST: 'FIRST',
    PREVIOUS: 'PREVIOUS',
    NEXT: 'NEXT',
    LAST: 'LAST',
};

const modalScreenTypes = {
    CONFIRM_DELETE: 'CONFIRM_DELETE',
    ERROR: 'ERROR',
    DOCUMENT: 'DOCUMENT',
    CATEGORY: 'CATEGORY',
    CONTENT: 'CONTENT',
    CONTENT_ITEM: 'CONTENT_ITEM',
    CONTENT_DOCUMENT: 'CONTENT_DOCUMENT',
    CONFIRM_SAVE_TREE: 'CONFIRM_SAVE_TREE',
    CONFIRM_DISCARD_TREE_CHANGES: 'CONFIRM_DISCARD_TREE_CHANGES',
};

const TreeView = (props: TreeViewProps & RouteComponentProps) => {
    const RESULT_FIELDS_DEFAULT = ['title', 'documentCode', 'lastUpdateDate'];
    const RESULT_FIELDS_DEFAULT_ACAREA = ['title', 'languageId', 'lastUpdateDate'];
    const [loading, setLoading] = useState(false);
    const [tree, setTree] = useState<Tree>(JSON.parse(JSON.stringify({})));
    const [treeViewList, setTreeViewList] = useState<Array<TreeElement> | null>(null);
    const [treeViewListACAreaExceptFrDocs, setTreeViewListACAreaExceptFrDocs] = useState<Array<TreeElement> | null>(
        null
    );
    const [treeViewListACAreaFrDocs, setTreeViewListACAreaFrDocs] = useState<Array<TreeElement> | null>(null);
    const [latestDate, setLatestDate] = useState(moment());
    const [timestamp, setTimestamp] = useState(0);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [rights, setRights] = useState<Array<Right>>([]);
    const [rowTypeText, setRowTypeText] = useState('');
    const [rowObjectForModal, setRowObjectForModal] = useState<TreeElement | null>(null);
    const [isParentAcategory, setIsParentAcategory] = useState(false);
    const [, setIsParentAcontentItem] = useState(false);
    const [, setIsParentAcontent] = useState(false);
    const [categories, setCategories] = useState<Array<TreeElement>>([]);
    const [treeIsEdited, setTreeIsEdited] = useState(false);
    const [showExpandedRow, setShowExpandedRow] = useState<{[key: number]: boolean}>({});
    const [newAddedFileUUIDs, setNewAddedFileUUIDs] = useState<Array<string>>([]);
    const [userRoles] = useState(getUserRoles());

    const getCategoryType = () => {
        let categoryTypeId = 0;
        switch (props.tag) {
            case 'generalInformation':
                categoryTypeId = categoryTypes.GENERAL_INFORMATION;
                break;
            case 'vademecum':
                categoryTypeId = categoryTypes.VADEMECUM;
                break;
            case 'rnd':
                categoryTypeId = categoryTypes.RND;
                break;
            case 'templates':
                categoryTypeId = categoryTypes.TEMPLATES;
                break;
            case 'qas':
                categoryTypeId = categoryTypes.QAS;
                break;
            case 'designationAgreement':
                categoryTypeId = categoryTypes.DESIGNATION_AGREEMENT;
                break;
        }

        return categoryTypeId;
    };

    const fromTreeToFlatArray = (tree: Tree) => {
        let flatArray: Array<TreeElement> = [];
        const getChildren = (children: Array<TreeElement>) => {
            for (const child of children) {
                flatArray.push(child);
                if (child.children.length > 0) {
                    getChildren(child.children);
                }
            }
        };
        for (const root of tree.tree) {
            flatArray.push(root);
            getChildren(root.children);
        }

        return flatArray;
    };

    useEffect(() => {
        setTreeIsEdited(props.treeIsEdited);
    }, [props.treeIsEdited]);

    useEffect(() => {
        setTree(props.tree);
    }, [props.tree]);

    useEffect(() => {
        if (Object.keys(tree).length > 0) {
            let newTreeViewList = fromTreeToFlatArray(tree);

            setCategories(newTreeViewList.filter(el => el.isCategory === true));
            if (props.isACArea) {
                setTreeViewListACAreaFrDocs(newTreeViewList.filter(el => el.level === 4));
                setTreeViewListACAreaExceptFrDocs(newTreeViewList.filter(el => el.level <= 3));
                setLatestDate(getLatestDateOfTreeElements(newTreeViewList));
            } else {
                setTreeViewList(newTreeViewList);
            }
            setTimestamp(Date.now());
        } else {
            setTreeViewList(null);
        }
    }, [tree]);

    const openModalConfirmDeletion = (rowObject: TreeElement) => {
        let text = '';
        if (props.isACArea) {
            if (rowObject.isContent) {
                text = 'content';
            } else if (rowObject.isContentItem) {
                text = 'content item';
            } else {
                text = 'document';
            }
        } else {
            text = rowObject.isCategory ? `category` : `document`;
        }
        setRowTypeText(text);
        setRowObjectForModal(rowObject);
        setModalScreen(modalScreenTypes.CONFIRM_DELETE);
    };

    const closeModal = () => setModalScreen(null);

    const closeErrorModal = () => {
        setModalScreen(null);
        setErrorMessage(null);
    };

    const moveRowToPosition = (arr: Array<TreeElement>, element: TreeElement, fromIdx: number, toIdx: number) => {
        arr.splice(fromIdx, 1);
        arr.splice(toIdx, 0, element);
    };

    function getParent(root: Array<TreeElement>, parentId?: number): TreeElement | undefined {
        let parent: TreeElement | undefined;

        root.some(el => {
            if (parentId && el.id === parentId) {
                return (parent = el);
            } else {
                return (parent = getParent(el.children, parentId));
            }
        });

        return parent;
    }

    const updateTree = (newTree: Tree) => {
        setTree(newTree);
        const typeOfTree = props.isGeneral ? 'General' : 'EoSpecific';
        if (typeOfTree === 'General') {
            localStorage.setItem(props.tag + typeOfTree + 'Tree', JSON.stringify(newTree));
        } else {
            props.eoSpecificId &&
                localStorage.setItem(props.tag + props.eoSpecificId + typeOfTree + 'Tree', JSON.stringify(newTree));
        }
        setTreeIsEdited(true);
    };

    const moveLevelChildren = (children: Array<TreeElement>, parentLevel: number) => {
        const getChildren = (children: Array<TreeElement>, parentLevel: number) => {
            for (const child of children) {
                child.level = parentLevel + 1;
                if (child.children.length > 0) {
                    getChildren(child.children, child.level);
                }
            }
        };
        for (const child of children) {
            child.level = parentLevel + 1;
            getChildren(child.children, child.level);
        }
    };

    const changeOrderOfSiblings = (arr: Array<TreeElement>, parentOrder?: string) => {
        for (let i = 0; i < arr.length; i += 1) {
            if (arr[i].level > 3 && props.isACArea) {
                arr[i].order = parentOrder + '.' + getLanguageAbbrvFromId(arr[i].languageId).toUpperCase();
            } else {
                arr[i].order = parentOrder ? parentOrder + '.' + (i + 1).toString() : (i + 1).toString();
            }
        }
    };

    const changeOrderOfChildren = (children: Array<TreeElement>, parentOrder: string) => {
        const getChildren = (children: Array<TreeElement>, parentOrder: string) => {
            for (const child of children) {
                changeOrderOfSiblings(children, parentOrder);
                if (child.children.length > 0) {
                    getChildren(child.children, child.order);
                }
            }
        };
        for (const child of children) {
            changeOrderOfSiblings(children, parentOrder);
            getChildren(child.children, child.order);
        }
    };

    const moveFirstCategory = (rowObject: TreeElement) => {
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        const rootIdx = newTree.tree.findIndex(el => el.id === rowObject.id);
        if (rootIdx >= 0) {
            moveRowToPosition(newTree.tree, rowObject, rootIdx, 0);
            changeOrderOfSiblings(newTree.tree);
            for (const treeEl of newTree.tree) {
                changeOrderOfChildren(treeEl.children, treeEl.order);
            }
        }
        updateTree(newTree);
    };

    const moveLastCategory = (rowObject: TreeElement) => {
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        const rootIdx = newTree.tree.findIndex(el => el.id === rowObject.id);
        if (rootIdx >= 0) {
            moveRowToPosition(newTree.tree, rowObject, rootIdx, newTree.tree.length - 1);
            changeOrderOfSiblings(newTree.tree);
            for (const treeEl of newTree.tree) {
                changeOrderOfChildren(treeEl.children, treeEl.order);
            }
        }
        updateTree(newTree);
    };

    const moveDownCategory = (rowObject: TreeElement) => {
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        const rootIdx = newTree.tree.findIndex(el => el.id === rowObject.id);
        if (rootIdx !== -1) {
            moveRowToPosition(newTree.tree, rowObject, rootIdx, rootIdx + 1);
            changeOrderOfSiblings(newTree.tree);
            for (const treeEl of newTree.tree) {
                changeOrderOfChildren(treeEl.children, treeEl.order);
            }
        }
        updateTree(newTree);
    };

    const moveUpCategory = (rowObject: TreeElement) => {
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        const rootIdx = newTree.tree.findIndex(el => el.id === rowObject.id);
        if (rootIdx !== -1) {
            moveRowToPosition(newTree.tree, rowObject, rootIdx, rootIdx - 1);
            changeOrderOfSiblings(newTree.tree);
            for (const treeEl of newTree.tree) {
                changeOrderOfChildren(treeEl.children, treeEl.order);
            }
        }
        updateTree(newTree);
    };

    const moveFirstDocument = (rowObject: TreeElement) => {
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        const rootIdx = newTree.tree.findIndex(el => el.id === rowObject.id);
        if (rootIdx !== -1) {
            moveRowToPosition(newTree.tree, rowObject, rootIdx, 0);
            changeOrderOfSiblings(newTree.tree);
            for (const treeEl of newTree.tree) {
                changeOrderOfChildren(treeEl.children, treeEl.order);
            }
        } else {
            const parent = getParent(newTree.tree, rowObject.parentId);
            if (parent) {
                const curIdx = parent.children.findIndex(el => el.id === rowObject.id);
                moveRowToPosition(parent.children, rowObject, curIdx, 0);
                changeOrderOfChildren(parent.children, parent.order);
                changeOrderOfChildren(rowObject.children, rowObject.order);
            }
        }
        updateTree(newTree);
    };

    const moveLastDocument = (rowObject: TreeElement) => {
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        const rootIdx = newTree.tree.findIndex(el => el.id === rowObject.id);
        if (rootIdx >= 0) {
            moveRowToPosition(newTree.tree, rowObject, rootIdx, newTree.tree.length - 1);
            changeOrderOfSiblings(newTree.tree);
            for (const treeEl of newTree.tree) {
                changeOrderOfChildren(treeEl.children, treeEl.order);
            }
        } else {
            const parent = getParent(newTree.tree, rowObject.parentId);
            if (parent) {
                const curIdx = parent.children.findIndex(el => el.id === rowObject.id);
                moveRowToPosition(parent.children, rowObject, curIdx, parent.children.length - 1);
                changeOrderOfChildren(parent.children, parent.order);
                changeOrderOfChildren(rowObject.children, rowObject.order);
            }
        }
        updateTree(newTree);
    };

    const moveDownDocument = (rowObject: TreeElement) => {
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        const rootIdx = newTree.tree.findIndex(el => el.id === rowObject.id);
        if (rootIdx !== -1) {
            moveRowToPosition(newTree.tree, rowObject, rootIdx, rootIdx + 1);
            changeOrderOfSiblings(newTree.tree);
            for (const treeEl of newTree.tree) {
                changeOrderOfChildren(treeEl.children, treeEl.order);
            }
        } else {
            const parent = getParent(newTree.tree, rowObject.parentId);
            if (parent) {
                const curIdx = parent.children.findIndex(el => el.id === rowObject.id);
                moveRowToPosition(parent.children, rowObject, curIdx, curIdx + 1);
                changeOrderOfChildren(parent.children, parent.order);
                changeOrderOfChildren(rowObject.children, rowObject.order);
            }
        }
        updateTree(newTree);
    };

    const moveUpDocument = (rowObject: TreeElement) => {
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        const rootIdx = newTree.tree.findIndex(el => el.id === rowObject.id);
        if (rootIdx !== -1) {
            moveRowToPosition(newTree.tree, rowObject, rootIdx, rootIdx - 1);
            changeOrderOfSiblings(newTree.tree);
            for (const treeEl of newTree.tree) {
                changeOrderOfChildren(treeEl.children, treeEl.order);
            }
        } else {
            const parent = getParent(newTree.tree, rowObject.parentId);
            if (parent) {
                const curIdx = parent.children.findIndex(el => el.id === rowObject.id);
                moveRowToPosition(parent.children, rowObject, curIdx, curIdx - 1);
                changeOrderOfChildren(parent.children, parent.order);
                changeOrderOfChildren(rowObject.children, rowObject.order);
            }
        }
        updateTree(newTree);
    };

    const moveLeftDocument = (rowObject: TreeElement) => {
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        const rootIdx = newTree.tree.findIndex(el => el.id === rowObject.id);
        if (rootIdx === -1) {
            const parent = getParent(newTree.tree, rowObject.parentId);
            if (parent) {
                const curIdx = parent.children.findIndex(el => el.id === rowObject.id);
                parent.children.splice(curIdx, 1);
                rowObject.level = parent.level;
                moveLevelChildren(rowObject.children, rowObject.level);
                const parentOfParent = getParent(newTree.tree, parent.parentId);
                if (parentOfParent) {
                    const parentIdx = parentOfParent.children.findIndex(el => el.id === parent.id);
                    parentOfParent.children.splice(parentIdx + 1, 0, rowObject);
                    rowObject.parentId = parentOfParent.id;
                    rowObject.parentTitle = parentOfParent.title;
                    changeOrderOfSiblings(parentOfParent.children, parentOfParent.order);
                    changeOrderOfChildren(parent.children, parent.order);
                    changeOrderOfChildren(rowObject.children, rowObject.order);
                } else {
                    const rootParent = newTree.tree.find(el => el.id === parent.id);
                    if (rootParent) {
                        const parentIdx = newTree.tree.findIndex(el => el.id === parent.id);
                        newTree.tree.splice(parentIdx + 1, 0, rowObject);
                        rowObject.parentId = rootParent.parentId;
                        rowObject.parentTitle = '';
                        changeOrderOfSiblings(newTree.tree);
                        for (const treeEl of newTree.tree) {
                            changeOrderOfChildren(treeEl.children, treeEl.order);
                        }
                    }
                }
            }
        }
        updateTree(newTree);
    };

    const moveRightDocument = (rowObject: TreeElement) => {
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        const rootIdx = newTree.tree.findIndex(el => el.id === rowObject.id);
        if (rootIdx !== -1) {
            const prevSibling = newTree.tree[rootIdx - 1];
            newTree.tree.splice(rootIdx, 1);
            prevSibling.children.push(rowObject);
            rowObject.level = prevSibling.level + 1;
            moveLevelChildren(rowObject.children, rowObject.level);
            rowObject.parentId = prevSibling.id;
            rowObject.parentTitle = prevSibling.title;
            changeOrderOfSiblings(newTree.tree);
            for (const treeEl of newTree.tree) {
                changeOrderOfChildren(treeEl.children, treeEl.order);
            }
        } else {
            const parent = getParent(newTree.tree, rowObject.parentId);
            if (parent) {
                const curIdx = parent.children.findIndex(el => el.id === rowObject.id);
                parent.children.splice(curIdx, 1);
                parent.children[curIdx - 1].children.push(rowObject);
                rowObject.level = parent.children[curIdx - 1].level + 1;
                moveLevelChildren(rowObject.children, rowObject.level);
                rowObject.parentId = parent.children[curIdx - 1].id;
                rowObject.parentTitle = parent.children[curIdx - 1].title;
                changeOrderOfSiblings(parent.children, parent.order);
                const newParent = getParent(parent.children, rowObject.parentId);
                if (newParent) changeOrderOfSiblings(newParent.children, newParent.order);
                changeOrderOfChildren(rowObject.children, rowObject.order);
            }
        }
        updateTree(newTree);
    };

    const moveRowToPositionOrLevelRoutine = (rowObject: TreeElement, mode: string) => {
        if (mode === ORDER_OPERATION.LEFT) {
            if (rowObject.isCategory === false) {
                moveLeftDocument(rowObject);
            }
        } else if (mode === ORDER_OPERATION.RIGHT) {
            if (rowObject.isCategory === false) {
                moveRightDocument(rowObject);
            }
        } else if (mode === ORDER_OPERATION.FIRST) {
            if (rowObject.isCategory === true) {
                moveFirstCategory(rowObject);
            } else {
                moveFirstDocument(rowObject);
            }
        } else if (mode === ORDER_OPERATION.PREVIOUS) {
            if (rowObject.isCategory === true) {
                moveUpCategory(rowObject);
            } else {
                moveUpDocument(rowObject);
            }
        } else if (mode === ORDER_OPERATION.NEXT) {
            if (rowObject.isCategory === true) {
                moveDownCategory(rowObject);
            } else {
                moveDownDocument(rowObject);
            }
        } else if (mode === ORDER_OPERATION.LAST) {
            if (rowObject.isCategory === true) {
                moveLastCategory(rowObject);
            } else {
                moveLastDocument(rowObject);
            }
        }
    };

    const moveRowToPreviousLevel = (rowObject: TreeElement) => {
        moveRowToPositionOrLevelRoutine(rowObject, ORDER_OPERATION.LEFT);
    };

    const moveRowToNextLevel = (rowObject: TreeElement) => {
        moveRowToPositionOrLevelRoutine(rowObject, ORDER_OPERATION.RIGHT);
    };

    const moveRowToFirstPosition = (rowObject: TreeElement) => {
        moveRowToPositionOrLevelRoutine(rowObject, ORDER_OPERATION.FIRST);
    };

    const moveRowToPreviousPosition = (rowObject: TreeElement) => {
        moveRowToPositionOrLevelRoutine(rowObject, ORDER_OPERATION.PREVIOUS);
    };

    const moveRowToNextPosition = (rowObject: TreeElement) => {
        moveRowToPositionOrLevelRoutine(rowObject, ORDER_OPERATION.NEXT);
    };

    const moveRowToLastPosition = (rowObject: TreeElement) => {
        moveRowToPositionOrLevelRoutine(rowObject, ORDER_OPERATION.LAST);
    };

    const getRights = (rowObject: TreeElement) => {
        setLoading(true);
        if (props.isACArea) {
            apiFetchACAREARights()
                .then(jsonResponse => {
                    setRights(jsonResponse);
                    if (rowObject.isContent === false && rowObject.isContentItem === true) {
                        setRowObjectForModal(rowObject);
                        setModalScreen(modalScreenTypes.CONTENT_ITEM);
                    }
                    if (rowObject.isContent === false && rowObject.isContentItem === false) {
                        setRowObjectForModal(rowObject);
                        setModalScreen(modalScreenTypes.CONTENT_DOCUMENT);
                    }
                })
                .catch(error => {
                    setErrorMessage(`Fetch Rights error: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        } else {
            apiFetchDocumentCategoryRights()
                .then((jsonResponse: any) => {
                    setRights(jsonResponse);
                    setRowObjectForModal(rowObject);
                    setModalScreen(modalScreenTypes.CATEGORY);
                })
                .catch((error: any) => {
                    setErrorMessage(`Fetch Rights error: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        }
    };

    const openModalCategoryForAddition = () => {
        const newTreeViewList: Array<TreeElement> = JSON.parse(JSON.stringify(treeViewList));
        const maxId =
            newTreeViewList !== null && newTreeViewList.length > 0
                ? newTreeViewList.sort((a, b) => b.id - a.id)[0].id
                : 0;
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        const order = Object.entries(newTree).length > 0 ? newTree.tree.length + 1 : 1;
        const rowObjectForAddition: TreeElement = {
            id: maxId + 1,
            order: order.toString(),
            documentCode: '',
            title: '',
            lastUpdateDate: '',
            beginDate: '',
            endDate: '',
            categoryType: getCategoryType(),
            rights: [],
            file: [],
            level: 1,
            parentId: 0,
            parentTitle: '',
            isCategory: true,
            showLastUpdateDate: false,
            children: [],
        };
        getRights(rowObjectForAddition);
    };

    const openModalCategoryForModify = (rowObject: TreeElement) => {
        if (rowObject.isCategory) {
            getRights(rowObject);
        }
    };

    const checkIfParentIsCategory = (parent: TreeElement | undefined) => {
        if (parent) {
            setIsParentAcategory(parent.isCategory);
        } else {
            setIsParentAcategory(false);
        }
    };

    const openModalDocumentForAdditionAsRootParent = () => {
        const newTreeViewList: Array<TreeElement> = JSON.parse(JSON.stringify(treeViewList));
        const maxId =
            newTreeViewList !== null && newTreeViewList.length > 0
                ? newTreeViewList.sort((a, b) => b.id - a.id)[0].id
                : 0;
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        const order = Object.entries(newTree).length > 0 ? newTree.tree.length + 1 : 1;
        const today = moment();
        const formattedDate = today.format('YYYY-MM-DD');
        const rowObjectForAddition: TreeElement = {
            id: maxId + 1,
            order: order.toString(),
            documentCode: '',
            title: '',
            lastUpdateDate: formatDateEasy(formattedDate),
            beginDate: formatDateEasy(formattedDate),
            endDate: '',
            categoryType: getCategoryType(),
            rights: [],
            file: [],
            level: 1,
            parentId: 0,
            parentTitle: '',
            isCategory: false,
            showLastUpdateDate: true,
            children: [],
        };
        setRowObjectForModal(rowObjectForAddition);
        setModalScreen(modalScreenTypes.DOCUMENT);
    };

    const openModalDocumentForAddition = (rowObject: TreeElement) => {
        const newTreeViewList: Array<TreeElement> = JSON.parse(JSON.stringify(treeViewList));
        const maxId = newTreeViewList !== null ? newTreeViewList.sort((a, b) => b.id - a.id)[0].id : 0;
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        const order = Object.entries(newTree).length > 0 ? rowObject.children.length + 1 : 1;
        const today = moment();
        const formattedDate = today.format('YYYY-MM-DD');
        const parent = getParent(newTree.tree, rowObject.id);
        checkIfParentIsCategory(parent);
        const rowObjectForAddition: TreeElement = {
            id: maxId + 1,
            order: rowObject.order + '.' + order.toString(),
            documentCode: '',
            title: '',
            lastUpdateDate: formatDateEasy(formattedDate),
            beginDate: formatDateEasy(formattedDate),
            endDate: '',
            categoryType: rowObject.categoryType,
            rights: [],
            file: [],
            level: rowObject.level + 1,
            parentId: rowObject.id,
            parentTitle: rowObject.title,
            isCategory: false,
            showLastUpdateDate: true,
            children: [],
        };
        setRowObjectForModal(rowObjectForAddition);
        setModalScreen(modalScreenTypes.DOCUMENT);
    };

    const openModalDocumentForModify = (rowObject: TreeElement) => {
        if (rowObject.isCategory === false) {
            const newTree: Tree = JSON.parse(JSON.stringify(tree));
            const parent = getParent(newTree.tree, rowObject.parentId);
            checkIfParentIsCategory(parent);
            setRowObjectForModal(rowObject);
            setModalScreen(modalScreenTypes.DOCUMENT);
        }
    };

    //ACAREA functions
    const checkIfParentIsContent = (parent: TreeElement | undefined) => {
        if (parent) {
            setIsParentAcontent(parent.isContent === undefined ? false : true);
        } else {
            setIsParentAcontent(false);
        }
    };

    const checkIfParentIsContentItem = (parent: TreeElement | undefined) => {
        if (parent) {
            setIsParentAcontentItem(parent.isContentItem === undefined ? false : true);
        } else {
            setIsParentAcontentItem(false);
        }
    };

    const openModalContentForAddition = () => {
        const newTreeViewList: Array<TreeElement> =
            treeViewListACAreaExceptFrDocs !== null && treeViewListACAreaFrDocs !== null
                ? JSON.parse(JSON.stringify(treeViewListACAreaExceptFrDocs.concat(treeViewListACAreaFrDocs)))
                : [];
        const maxId =
            newTreeViewList !== null && newTreeViewList.length > 0
                ? newTreeViewList.sort((a, b) => b.id - a.id)[0].id
                : 0;
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        const order = Object.entries(newTree).length > 0 ? newTree.tree.length + 1 : 1;
        const rowObjectForAddition: TreeElement = {
            id: maxId + 1,
            order: order.toString(),
            title: '',
            location: '',
            additionalInfo: '',
            beginDate: '',
            lastUpdateDate: '',
            isOnline: false,
            endDate: '',
            rights: [],
            file: [],
            level: 1,
            parentId: 0,
            parentTitle: '',
            showLastUpdateDate: true,
            categoryType: 0,
            isCategory: false,
            isContent: true,
            isContentItem: false,
            children: [],
        };
        setRowObjectForModal(rowObjectForAddition);
        setModalScreen(modalScreenTypes.CONTENT);
    };

    const openModalContentForModify = (rowObject: TreeElement) => {
        if (rowObject.isContent) {
            setRowObjectForModal(rowObject);
            setModalScreen(modalScreenTypes.CONTENT);
        }
    };
    const openModalContentItemForAddition = (rowObject: TreeElement) => {
        const newTreeViewList: Array<TreeElement> =
            treeViewListACAreaExceptFrDocs !== null && treeViewListACAreaFrDocs !== null
                ? JSON.parse(JSON.stringify(treeViewListACAreaExceptFrDocs.concat(treeViewListACAreaFrDocs)))
                : [];
        const maxId = newTreeViewList !== null ? newTreeViewList.sort((a, b) => b.id - a.id)[0].id : 0;
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        const order = Object.entries(newTree).length > 0 ? rowObject.children.length + 1 : 1;
        const today = moment();
        const formattedDate = today.format('YYYY-MM-DD');
        const parent = getParent(newTree.tree, rowObject.id);
        checkIfParentIsContent(parent);
        const rowObjectForAddition: TreeElement = {
            id: maxId + 1,
            order: rowObject.order + '.' + order.toString(),
            documentCode: '',
            title: '',
            additionalInfo: '',
            lastUpdateDate: formatDateEasy(formattedDate),
            beginDate: formatDateEasy(formattedDate),
            endDate: '',
            categoryType: 0,
            rights: [],
            file: [],
            level: rowObject.level + 1,
            parentId: rowObject.id,
            parentTitle: rowObject.title,
            isCategory: false,
            showLastUpdateDate: true,
            isContent: false,
            isContentItem: true,
            children: [],
        };
        getRights(rowObjectForAddition);
    };
    const openModalContentDocumentForAddition = (rowObject: TreeElement) => {
        const newTreeViewList: Array<TreeElement> =
            treeViewListACAreaExceptFrDocs !== null && treeViewListACAreaFrDocs !== null
                ? JSON.parse(JSON.stringify(treeViewListACAreaExceptFrDocs.concat(treeViewListACAreaFrDocs)))
                : [];
        const maxId = newTreeViewList !== null ? newTreeViewList.sort((a, b) => b.id - a.id)[0].id : 0;
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        const order = Object.entries(newTree).length > 0 ? rowObject.children.length + 1 : 1;
        const today = moment();
        const formattedDate = today.format('YYYY-MM-DD');
        const parent = getParent(newTree.tree, rowObject.id);
        checkIfParentIsContentItem(parent);
        const rowObjectForAddition: TreeElement = {
            id: maxId + 1,
            order: rowObject.order + '.' + order.toString(),
            documentCode: '',
            title: '',
            lastUpdateDate: formatDateEasy(formattedDate),
            beginDate: formatDateEasy(formattedDate),
            endDate: '',
            categoryType: 0,
            rights: [],
            file: [],
            level: rowObject.level + 1,
            parentId: rowObject.id,
            parentTitle: rowObject.title,
            isCategory: false,
            showLastUpdateDate: true,
            languageId: 1,
            isContent: false,
            isContentItem: false,
            children: [],
        };
        getRights(rowObjectForAddition);
    };
    const openModalContentItemForModify = (rowObject: TreeElement) => {
        if (rowObject.isContentItem) {
            getRights(rowObject);
        }
    };
    const openModalContentDocumentForModify = (rowObject: TreeElement) => {
        if (rowObject.isContent === false && rowObject.isContentItem === false) {
            const newTree: Tree = JSON.parse(JSON.stringify(tree));
            const parent = getParent(newTree.tree, rowObject.parentId);
            checkIfParentIsContentItem(parent);
            setRowObjectForModal(rowObject);
            getRights(rowObject);
        }
    };

    const getUploadedDocumentListWithLanguage = (rowObject: TreeElement) => {
        let result: Array<UploadedFileData> = [];
        const fileInEnglish = rowObject.file;
        const filesInOtherTranslations: Array<UploadedFileData> = [];
        for (const treeEl of rowObject.children) {
            if (treeEl.file.length > 0) {
                treeEl.file[0].languageId = treeEl.languageId;
                filesInOtherTranslations.push(treeEl.file[0]);
            }
        }

        if (fileInEnglish.length > 0) {
            fileInEnglish[0].languageId = rowObject.languageId;
            result = result.concat(fileInEnglish);
        }
        if (filesInOtherTranslations.length > 0) {
            result = result.concat(filesInOtherTranslations);
        }

        return result;
    };

    const deleteAllFiles = (rowObject: TreeElement) => {
        setLoading(true);
        let documentsList: Array<UploadedFileData> = getUploadedDocumentListWithLanguage(rowObject);
        for (const file of documentsList) {
            if (file.uuid && props.section) {
                apiDeleteTreeDocumentFile(file.uuid, props.section)
                    .then(() => {
                        documentsList = documentsList.filter(el => el.uuid !== file.uuid);
                    })
                    .catch(error => {
                        setLoading(false);
                        setErrorMessage(`error deleting file: ${error}`);
                        setModalScreen(modalScreenTypes.ERROR);
                    });
            }
        }
        setLoading(false);
    };

    const deleteRow = () => {
        closeModal();
        if (rowObjectForModal !== null) {
            if (rowObjectForModal.children.length > 0) {
                if (rowTypeText === 'category') {
                    setErrorMessage(
                        'Unable to delete this category. Some documents exist for this category. Delete them before delete the category.'
                    );
                    setModalScreen(modalScreenTypes.ERROR);
                } else if (rowTypeText === 'content') {
                    setErrorMessage(
                        'Unable to delete this content. Some content items and/or documents exist for this content. Delete them before delete the content.'
                    );
                    setModalScreen(modalScreenTypes.ERROR);
                } else if (rowTypeText === 'content item') {
                    setErrorMessage(
                        'Unable to delete this content item. Some documents exist for this content item. Delete them before delete the content item.'
                    );
                    setModalScreen(modalScreenTypes.ERROR);
                } else if (!props.isACArea && rowTypeText === 'document') {
                    setErrorMessage('You can not delete a document with children.');
                    setModalScreen(modalScreenTypes.ERROR);
                } else {
                    props.isACArea && rowObjectForModal.level === 3 && deleteAllFiles(rowObjectForModal);
                    const newTree: Tree = JSON.parse(JSON.stringify(tree));
                    const rootIdx = newTree.tree.findIndex(el => el.id === rowObjectForModal.id);
                    if (rootIdx !== -1) {
                        newTree.tree.splice(rootIdx, 1);
                        changeOrderOfSiblings(newTree.tree);
                        for (const treeEl of newTree.tree) {
                            changeOrderOfChildren(treeEl.children, treeEl.order);
                        }
                    } else {
                        const parent = getParent(newTree.tree, rowObjectForModal.parentId);
                        if (parent) {
                            const curIdx = parent.children.findIndex(el => el.id === rowObjectForModal.id);
                            parent.children.splice(curIdx, 1);
                            changeOrderOfChildren(parent.children, parent.order);
                        }
                    }
                    updateTree(newTree);
                }
            } else {
                props.isACArea && rowObjectForModal.level === 3 && deleteAllFiles(rowObjectForModal);
                const newTree: Tree = JSON.parse(JSON.stringify(tree));
                const rootIdx = newTree.tree.findIndex(el => el.id === rowObjectForModal.id);
                if (rootIdx !== -1) {
                    newTree.tree.splice(rootIdx, 1);
                    changeOrderOfSiblings(newTree.tree);
                    for (const treeEl of newTree.tree) {
                        changeOrderOfChildren(treeEl.children, treeEl.order);
                    }
                } else {
                    const parent = getParent(newTree.tree, rowObjectForModal.parentId);
                    if (parent) {
                        const curIdx = parent.children.findIndex(el => el.id === rowObjectForModal.id);
                        parent.children.splice(curIdx, 1);
                        changeOrderOfChildren(parent.children, parent.order);
                    }
                }
                updateTree(newTree);
            }
        }
    };

    const saveCategory = (categoryElement: TreeElement) => {
        closeModal();
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        let isNotEmptyTree = Object.entries(newTree).length > 0 && newTree.tree.length > 0;
        const categoryElementExist = isNotEmptyTree ? newTree.tree.find(el => el.id === categoryElement.id) : undefined;
        if (categoryElementExist && (categoryElement.isCategory || categoryElement.isContent)) {
            const curIdx = newTree.tree.findIndex(el => el.id === categoryElement.id);
            newTree.tree.splice(curIdx, 1);
            newTree.tree.splice(curIdx, 0, categoryElement);
        } else {
            if (isNotEmptyTree) {
                newTree.tree.push(categoryElement);
            } else {
                newTree.tree = [];
                newTree.tree.push(categoryElement);
            }
        }
        updateTree(newTree);
    };

    const saveDocument = (documentElement: TreeElement) => {
        closeModal();
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        let isNotEmptyTree = Object.entries(newTree).length > 0 && newTree.tree.length > 0;
        if (isNotEmptyTree) {
            const newTreeViewList: Array<TreeElement> = JSON.parse(JSON.stringify(treeViewList));
            const documentElements = newTreeViewList.filter(el => el.isCategory === false);
            const documentElementExist = documentElements.find(el => el.id === documentElement.id);
            if (documentElementExist && documentElement.isCategory === false) {
                const rootIdx = newTree.tree.findIndex(el => el.id === documentElement.id);
                if (rootIdx !== -1) {
                    newTree.tree.splice(rootIdx, 1);
                    newTree.tree.splice(rootIdx, 0, documentElement);
                } else {
                    const parent = getParent(newTree.tree, documentElement.parentId);
                    if (parent) {
                        const curIdx = parent.children.findIndex(el => el.id === documentElement.id);
                        parent.children.splice(curIdx, 1);
                        parent.children.splice(curIdx, 0, documentElement);
                    }
                }
            } else {
                const parent = getParent(newTree.tree, documentElement.parentId);
                if (parent) {
                    parent.children.push(documentElement);
                } else {
                    newTree.tree.push(documentElement);
                }
            }
        } else {
            newTree.tree = [];
            newTree.tree.push(documentElement);
        }
        updateTree(newTree);
    };

    const saveContent = (contentElement: TreeElement) => {
        saveCategory(contentElement);
        props.updateTreeIsEdited && props.updateTreeIsEdited(true);
    };

    const saveContentItem = (contentItemElement: TreeElement) => {
        closeModal();
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        let isNotEmptyTree = Object.entries(newTree).length > 0 && newTree.tree.length > 0;
        if (isNotEmptyTree) {
            const newTreeViewList: Array<TreeElement> =
                treeViewListACAreaExceptFrDocs !== null && treeViewListACAreaFrDocs !== null
                    ? JSON.parse(JSON.stringify(treeViewListACAreaExceptFrDocs.concat(treeViewListACAreaFrDocs)))
                    : [];
            const contentItemElements = newTreeViewList.filter(el => el.isContentItem);
            const contenteItemElementExists = contentItemElements.find(el => {
                return el.id === contentItemElement.id && contentItemElement.isContentItem;
            });
            if (contenteItemElementExists) {
                const parent = getParent(newTree.tree, contentItemElement.parentId);
                if (parent) {
                    const curIdx = parent.children.findIndex(el => el.id === contentItemElement.id);
                    parent.children.splice(curIdx, 1);
                    parent.children.splice(curIdx, 0, contentItemElement);
                }
            } else {
                const parent = getParent(newTree.tree, contentItemElement.parentId);
                if (parent) {
                    parent.children.push(contentItemElement);
                } else {
                    newTree.tree.push(contentItemElement);
                }
            }
            updateTree(newTree);
            props.updateTreeIsEdited && props.updateTreeIsEdited(true);
        }
    };

    const saveContentDocument = (contentDocumentElement: TreeElement, newAddedFileUUIDs: Array<string>) => {
        closeModal();
        setNewAddedFileUUIDs(newAddedFileUUIDs);
        const newTree: Tree = JSON.parse(JSON.stringify(tree));
        let isNotEmptyTree = Object.entries(newTree).length > 0 && newTree.tree.length > 0;
        if (isNotEmptyTree) {
            const newTreeViewList: Array<TreeElement> =
                treeViewListACAreaExceptFrDocs !== null && treeViewListACAreaFrDocs !== null
                    ? JSON.parse(JSON.stringify(treeViewListACAreaExceptFrDocs.concat(treeViewListACAreaFrDocs)))
                    : [];
            const contentDocumentElements = newTreeViewList.filter(
                el => el.isContent === false && el.isContentItem === false
            );
            const contentDocumentElementExist = contentDocumentElements.find(el => el.id === contentDocumentElement.id);
            if (
                contentDocumentElementExist &&
                contentDocumentElement.isContent === false &&
                contentDocumentElement.isContentItem === false
            ) {
                const rootIdx = newTree.tree.findIndex(el => el.id === contentDocumentElement.id);
                if (rootIdx !== -1) {
                    newTree.tree.splice(rootIdx, 1);
                    newTree.tree.splice(rootIdx, 0, contentDocumentElement);
                } else {
                    const parent = getParent(newTree.tree, contentDocumentElement.parentId);
                    if (parent) {
                        const curIdx = parent.children.findIndex(el => el.id === contentDocumentElement.id);
                        parent.children.splice(curIdx, 1);
                        parent.children.splice(curIdx, 0, contentDocumentElement);
                    }
                }
            } else {
                const parent = getParent(newTree.tree, contentDocumentElement.parentId);
                if (parent) {
                    parent.children.push(contentDocumentElement);
                } else {
                    newTree.tree.push(contentDocumentElement);
                }
            }
        } else {
            newTree.tree = [];
            newTree.tree.push(contentDocumentElement);
        }
        updateTree(newTree);
        props.updateTreeIsEdited && props.updateTreeIsEdited(true);
    };

    const getRowClass = (rowObject: TreeElement) => {
        const conditionLatestDate =
            mostRecentDateCondition(latestDate, rowObject, props.isACArea) && rowObject.showLastUpdateDate;
        const conditionHasΝοChild = rowObject.level === 3 && rowObject.children.length <= 0;
        const className =
            `${props.isACArea && props.isAdmin ? 'acarea-' : ''}nestLevel-` +
            rowObject.level +
            (conditionHasΝοChild ? '-no-child' : '') +
            (conditionLatestDate ? '-latestDate' : '');
        if (props.rowClass) {
            return cx([styles[className], props.rowClass(rowObject)]);
        }
        return styles[className];
    };

    const onClickTitle = (rowObject: TreeElement) => {
        const isListOfTlos = rowObject.title === TLO_LIST_OF_TLOS_TREE_VALUE && rowObject.isCategory === false;

        return isListOfTlos
            ? props.openListOfTLOModal && props.openListOfTLOModal()
            : rowObject.file.length > 0 &&
                  rowObject.file[0].uuid &&
                  props.downloadFile &&
                  props.downloadFile(rowObject.file[0].uuid, rowObject.file[0].fileName);
    };

    const getCategoryWithRights = (categories: Array<TreeElement>) => {
        let result: {[key: number]: Array<string>} = {};
        for (const category of categories) {
            result[category.id] = category.rights;
        }

        return result;
    };

    const getTitle = (title: string, rowObject: TreeElement) => {
        const hasFile = rowObject.file.length > 0 && rowObject.file[0].fileName.length > 0;
        const isListOfTlos = rowObject.title === TLO_LIST_OF_TLOS_TREE_VALUE && rowObject.isCategory === false;
        const finalTitle = rowObject.order + ' - ' + title;
        const validityPeriodΕxpired =
            rowObject.endDate.length > 0 &&
            moment(new Date(), 'DD/MM/YYYY').diff(moment(rowObject.endDate, 'DD/MM/YYYY'), 'days') > 0;
        const categoriesWithRights = getCategoryWithRights(categories);
        const hasRightToViewDocument =
            (rowObject.parentId > 0 &&
                categoriesWithRights[rowObject.parentId] &&
                userRoles.some(el => categoriesWithRights[rowObject.parentId].includes(el))) ||
            categories.length === 0;

        return (hasFile && !validityPeriodΕxpired && hasRightToViewDocument) || isListOfTlos ? (
            <div title="Download Document">
                <a onClick={() => onClickTitle(rowObject)}>{finalTitle}</a>
            </div>
        ) : (
            <div>{finalTitle}</div>
        );
    };

    const getLastUpdateDate = (lastUpdateDate: string, rowObject: TreeElement): string => {
        return rowObject.showLastUpdateDate && formatDateEasy(lastUpdateDate);
    };

    const getLanguageData = (languageId: number | undefined, _rowObject: TreeElement): string => {
        let languageText = '';
        if (languageId) {
            switch (languageId) {
                case 1: {
                    languageText = 'EN';
                    break;
                }
                case 2: {
                    languageText = 'FR';
                    break;
                }
            }
        }
        return languageText;
    };

    const actions = TreeViewActionButtons(
        moveRowToPreviousLevel,
        moveRowToNextLevel,
        moveRowToFirstPosition,
        moveRowToPreviousPosition,
        moveRowToNextPosition,
        moveRowToLastPosition,
        openModalDocumentForAddition,
        openModalCategoryForModify,
        openModalDocumentForModify,
        openModalConfirmDeletion,
        openModalContentItemForAddition,
        openModalContentDocumentForAddition,
        openModalContentForModify,
        openModalContentItemForModify,
        openModalContentDocumentForModify,
        props.isACArea ? treeViewListACAreaExceptFrDocs : treeViewList,
        props.isACArea
    );

    const getTopCaptionElement = () => {
        return (
            props.isAdmin && (
                <TreeViewTopCaption
                    tag={props.tag}
                    treeIsEdited={treeIsEdited}
                    openModalDocumentForAddition={openModalDocumentForAdditionAsRootParent}
                    openModalCategoryForAddition={openModalCategoryForAddition}
                    openModalContentForAddition={openModalContentForAddition}
                    openModalConfirmDiscard={() => setModalScreen(modalScreenTypes.CONFIRM_DISCARD_TREE_CHANGES)}
                    openModalConfirmSave={() => setModalScreen(modalScreenTypes.CONFIRM_SAVE_TREE)}
                    isACArea={props.isACArea}
                />
            )
        );
    };

    const expandedRow = (rowObject: TreeElement) => {
        if (treeViewListACAreaExceptFrDocs !== null && treeViewListACAreaFrDocs) {
            const fullTree = treeViewListACAreaExceptFrDocs.concat(treeViewListACAreaFrDocs);
            const findChild = fullTree.find(el => el.parentId === rowObject.id);
            const condition =
                findChild &&
                mostRecentDateCondition(latestDate, findChild, props.isACArea) &&
                findChild.showLastUpdateDate;
            if (
                showExpandedRow[treeViewListACAreaExceptFrDocs.indexOf(rowObject)] === true &&
                findChild &&
                findChild.level > 3
            ) {
                return (
                    <tr
                        style={{
                            backgroundColor: condition ? 'rgb(250, 242, 204)' : 'inherit',
                        }}
                    >
                        <td></td>
                        <td
                            style={{
                                paddingLeft: 99,
                            }}
                        >
                            {getTitle(findChild.title, findChild)}
                        </td>
                        <td>{getLanguageData(findChild.languageId, findChild)}</td>
                        <td>{getLastUpdateDate(findChild.lastUpdateDate, findChild)}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                );
            }
        }

        return null;
    };

    const rowClick = (_id: number, rowObject: TreeElement) => {
        if (treeViewListACAreaExceptFrDocs !== null) {
            const translationDocument: {[key: number]: boolean} = {};
            translationDocument[treeViewListACAreaExceptFrDocs.indexOf(rowObject)] =
                !showExpandedRow[treeViewListACAreaExceptFrDocs.indexOf(rowObject)];
            setShowExpandedRow(translationDocument);
        }
        props.rowClick && props.rowClick(rowObject);
    };

    const discardTreeChanges = () => {
        setModalScreen(null);
        setLoading(true);
        for (const uuid of newAddedFileUUIDs) {
            if (props.section) {
                apiDeleteTreeDocumentFile(uuid, props.section)
                    .then(() => {})
                    .catch(error => {
                        setLoading(false);
                        setErrorMessage(`error deleting file: ${error}`);
                        setModalScreen(modalScreenTypes.ERROR);
                    });
            }
        }
        setTreeIsEdited(false);
        const typeOfTree = props.isGeneral ? 'General' : 'EoSpecific';
        setLoading(false);
        props.discardTreeChanges && props.discardTreeChanges(props.tag, typeOfTree);
    };

    const saveTree = () => {
        setModalScreen(null);
        setTreeIsEdited(false);
        const typeOfTree = props.isGeneral ? 'General' : 'EoSpecific';
        props.saveTree && props.saveTree(tree, typeOfTree);
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {modalScreen === modalScreenTypes.CONFIRM_DELETE ? (
                <ModalConfirmVersion2
                    title={`Delete ${rowTypeText}`}
                    message={`${
                        props.isACArea && rowTypeText === 'document'
                            ? 'All files of all translations are going to be deleted. '
                            : ''
                    }Are you sure you want to delete this ${rowTypeText}?`}
                    buttonName={'Delete'}
                    action={deleteRow}
                    close={closeModal}
                />
            ) : null}
            {modalScreen === modalScreenTypes.CONFIRM_DISCARD_TREE_CHANGES ? (
                <ModalConfirmVersion2
                    title={'Discard Changes'}
                    message={'Are you sure you want to discard your changes?'}
                    buttonName={'Yes'}
                    close={closeModal}
                    action={discardTreeChanges}
                />
            ) : null}
            {modalScreen === modalScreenTypes.CONFIRM_SAVE_TREE ? (
                <ModalConfirmVersion2
                    title={'Save Tree'}
                    message={'Are you sure you want to save your changes?'}
                    buttonName={'Yes'}
                    close={closeModal}
                    action={saveTree}
                />
            ) : null}
            {modalScreen === modalScreenTypes.DOCUMENT && rowObjectForModal !== null ? (
                <ModalDocument
                    documentElement={rowObjectForModal}
                    isParentAcategory={isParentAcategory}
                    tag={props.tag}
                    saveDocument={saveDocument}
                    close={closeModal}
                />
            ) : null}
            {modalScreen === modalScreenTypes.CATEGORY && rowObjectForModal !== null ? (
                <ModalCategory
                    categoryElement={rowObjectForModal}
                    rights={rights}
                    saveCategory={saveCategory}
                    close={closeModal}
                />
            ) : null}
            {modalScreen === modalScreenTypes.CONTENT && rowObjectForModal !== null ? (
                <ModalContent contentElement={rowObjectForModal} saveContent={saveContent} close={closeModal} />
            ) : null}
            {modalScreen === modalScreenTypes.CONTENT_ITEM && rowObjectForModal !== null ? (
                <ModalContentItem
                    contentItemElement={rowObjectForModal}
                    rights={rights}
                    saveContentItem={saveContentItem}
                    close={closeModal}
                />
            ) : null}
            {modalScreen === modalScreenTypes.CONTENT_DOCUMENT && rowObjectForModal !== null ? (
                <ModalContentDocument
                    contentDocumentElement={rowObjectForModal}
                    content={getParent(tree.tree, getParent(tree.tree, rowObjectForModal.parentId)?.parentId)}
                    rights={rights}
                    tree={tree}
                    section={props.section || ''}
                    year={props.year || moment().format('YYYY')}
                    saveContentDocument={saveContentDocument}
                    close={closeModal}
                />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            {getTopCaptionElement()}
            <div style={{clear: 'both'}}>{}</div>
            <div className={'treeView'}>
                {(props.isACArea ? treeViewListACAreaExceptFrDocs : treeViewList) && (
                    <CustomTable
                        version={1}
                        pageSize={999}
                        id={'id'}
                        tableName={'treeViewList'}
                        tableType={'OBJECT'}
                        hideExcelButton={true}
                        tableSource={props.isACArea ? treeViewListACAreaExceptFrDocs : treeViewList}
                        timestamp={timestamp}
                        resultFieldsDefault={props.isACArea ? RESULT_FIELDS_DEFAULT_ACAREA : RESULT_FIELDS_DEFAULT}
                        intl={props.intl}
                        reverseOrder={false}
                        rowClass={getRowClass}
                        formatFunctions={{
                            title: (title: string, rowObject: TreeElement) => getTitle(title, rowObject),
                            documentCode: (_docCode: undefined, rowObject: TreeElement) => (
                                <div>{rowObject.documentCode}</div>
                            ),
                            lastUpdateDate: (lastUpdateDate: string, rowObject: TreeElement) =>
                                getLastUpdateDate(lastUpdateDate, rowObject),
                            languageId: (languageId: number | undefined, rowObject: TreeElement) =>
                                getLanguageData(languageId, rowObject),
                        }}
                        actions={props.isAdmin ? actions : undefined}
                        actionName={props.isAdmin ? `Action` : undefined}
                        showAdminIcon={props.isAdmin}
                        notSortable={['title', 'documentCode', 'lastUpdateDate', 'languageId']}
                        useExpandedRow={props.isACArea && props.isAdmin}
                        forehandColumn={props.isACArea && props.isAdmin}
                        fullExpandedRow={false}
                        expandedRow={expandedRow}
                        rowClick={rowClick}
                        showExpandedRow={showExpandedRow}
                        bottomCaption={props.hideLegend ? <div></div> : <TreeViewLabelsBottomCaption />}
                    />
                )}
            </div>
        </>
    );
};

export default injectIntl(withRouter(TreeView));
