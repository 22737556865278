import React from 'react';
import InputContainer from '~componentsForm/InputContainer';
import styles from './Title.module.scss';

export default function ({children, double, triple, full, titleDescription}) {
    return (
        <InputContainer {...{double, triple, full}}>
            <div className={styles.title}>
                {children}
                {titleDescription ? <span className={styles.titleDescription}>{titleDescription}</span> : null}
            </div>
        </InputContainer>
    );
}
