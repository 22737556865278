import React from 'react';
import {injectIntl} from 'react-intl';
import SettingsTabs, {ACCESS, TAB} from '~shared/SettingsTabs';
import {apiReadSettings, apiSetSettings} from './OnlineSettingsGeneralService';
import Checkbox from '~components/Checkbox';
import {Footer, FormFooter, FormWrapper, HeaderLoading, MainWrapper} from '../../componentsLayout';
import {Button} from '../../componentsFormV2';
import {trackPageView} from '../../utils';

class OnlineSettingsGeneral extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loading: false, timestamp: Date.now()};
    }

    componentDidMount() {
        trackPageView({documentTitle: 'onlineSettingsGeneral'});
        this.loadJSONs();
    }

    loadJSONs = applicationNumber => {
        this.setState({loading: true}, () => {
            apiReadSettings()
                .then(jsonResponse => this.setState(jsonResponse))
                .then(() => this.setState({loading: false}));
        });
    };

    saveSettings = () => {
        this.setState({loading: true}, () => {
            apiSetSettings({'2fa': this.state['2fa']}).then(() => this.setState({loading: false}));
        });
    };

    updateTimestamp = dateNow => this.setState({timestamp: dateNow});

    onTwoFactorAuthenticationChange = () => this.setState(prev => ({'2fa': !prev['2fa']}));

    render() {
        return (
            <>
                {this.state.loading ? <HeaderLoading /> : null}
                <MainWrapper>
                    <FormWrapper>
                        <SettingsTabs
                            tabId={TAB.DETAILS}
                            access={ACCESS.PRIVATE}
                            close={this.props.close}
                            search={this.loadJSONs}
                            loading={this.state.loading}
                            updateTimestamp={this.updateTimestamp}
                        />
                        <Checkbox
                            clickAction={this.onTwoFactorAuthenticationChange}
                            simple={true}
                            label={`Two factor authentication`}
                            value={this.state['2fa']}
                        />
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            <Button clickAction={this.saveSettings} icon={'arrowRight'}>{`Send`}</Button>
                        </FormFooter>
                    </FormWrapper>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(OnlineSettingsGeneral);
