import React from 'react';
import styles from './DataSheetFormSection.module.scss';
import {faChevronDown, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import cx from 'classnames';

const LAYOUT_STYLE = {
    green: 'Green',
};

export default class MyPVRPublicConsultationDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {open: true};
    }

    componentDidMount() {
        if (this.props.defaultOpen !== undefined) {
            this.setState({open: this.props.defaultOpen});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.forceOpen !== undefined) {
            this.setState({open: this.props.forceOpen});
            this.props.setForceOpen(undefined);
        }
    }

    toggle = () => this.setState(prev => ({open: !prev.open}));

    render() {
        return (
            <React.Fragment>
                <div className={styles.section} style={this.props.style}>
                    <div
                        className={cx(
                            styles.sectionHeader,
                            this.props.layoutStyle && styles[`sectionHeader${LAYOUT_STYLE[this.props.layoutStyle]}`]
                        )}
                    >
                        <div
                            className={cx(
                                styles.sectionTitle,
                                this.props.layoutStyle && styles[`sectionTitle${LAYOUT_STYLE[this.props.layoutStyle]}`],
                                this.props.subtitleClosed && styles.sectionHeaderHasSubtitleClosed
                            )}
                        >
                            {this.props.title}
                        </div>
                        {!this.state.open && this.props.subtitleClosed && (
                            <div className={styles.sectionSubtitle}>{this.props.subtitleClosed}</div>
                        )}
                        {!this.props.disableToggle && (
                            <div className={styles.sectionToggle} onClick={this.toggle}>
                                <FontAwesomeIcon icon={this.state.open ? faChevronDown : faChevronRight} color="gray" />
                            </div>
                        )}
                        <div style={{clear: 'both'}}>{}</div>
                    </div>
                </div>
                {this.state.open === true && (
                    <div className={styles.sectionTable} style={this.props.styleTable}>
                        {this.props.children}
                    </div>
                )}
            </React.Fragment>
        );
    }
}
