import React from 'react';

interface IIconUserPlusProps {
    style?: any;
    variation?: string;
}

const colors: any = {
    secondary: '#009933',
};

export default function ({style: userStyle, variation}: IIconUserPlusProps) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={userStyle ? userStyle : {}}
        >
            <path
                d="M8.33333 8.33335C10.1743 8.33335 11.6667 6.84097 11.6667 5.00002C11.6667 3.15907 10.1743 1.66669 8.33333 1.66669C6.49238 1.66669 5 3.15907 5 5.00002C5 6.84097 6.49238 8.33335 8.33333 8.33335Z"
                stroke="#009933"
                strokeWidth="1.5"
            />
            <path
                d="M17.5 8.33335H15.8334M15.8334 8.33335H14.1667M15.8334 8.33335V6.66669M15.8334 8.33335V10M14.9975 15C15 14.8634 15 14.7242 15 14.5834C15 12.5125 12.015 10.8334 8.33335 10.8334C4.65169 10.8334 1.66669 12.5125 1.66669 14.5834C1.66669 16.6542 1.66669 18.3334 8.33335 18.3334C10.1925 18.3334 11.5334 18.2025 12.5 17.9692"
                stroke="#009933"
                strokeWidth="1.5"
                strokeLinecap="round"
                style={variation ? {stroke: colors[variation]} : {}}
            />
        </svg>
    );
}
