import COUNTRIES_LIST from '../VFMyAlerts/data/COUNTRIES_LIST.json';

export const getNameHashFromListByIdName = (listByIdName: any) => {
    const nameHash: any = {};
    listByIdName.forEach(({ID, NAME}: any) => (nameHash[ID] = NAME));
    return nameHash;
};

export const getSearchListFromSelectedElements = (nameHash: {[key: string]: string}, idParseInt?: boolean) => {
    const searchList: any = [];
    Object.entries(nameHash).forEach(
        ([id, label]) =>
            id !== 'All' &&
            searchList.push({
                label,
                value: idParseInt ? parseInt(id) : id,
            })
    );
    return searchList;
};

export const countriesListParsed = COUNTRIES_LIST.map(({label, value}) => ({
    label,
    value: JSON.parse(value),
}));

export const getCountriesNameHash = () => {
    let nameHash: any = {};
    countriesListParsed.forEach(({label}: any) => {
        nameHash[label] = [label];
    });
    return nameHash;
};

export const getCountriesListByIdName = () => {
    let listByIdName: any = [];
    countriesListParsed.forEach(({label}: any) => {
        listByIdName.push({
            ID: label,
            NAME: label,
        });
    });
    return listByIdName;
};
