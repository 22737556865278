import React from 'react';
import cx from 'classnames';
import styles from './OnlineApplicationsCustomTableBottomCaption.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt, faPrint} from '@fortawesome/free-solid-svg-icons';
import {faTrashAlt, faCopy, faFileAlt, faPaperPlane} from '@fortawesome/free-regular-svg-icons';

const listItems = [
    {
        icon: faPencilAlt,
        message: `Modify application`,
    },
    {
        icon: faPrint,
        message: `Print application`,
    },
    {
        icon: faCopy,
        message: `Duplicate application form for use in the same procedure`,
    },
    {
        icon: faFileAlt,
        message: `Copy complete application for use in another procedure`,
    },
    {
        icon: faPaperPlane,
        message: `Send application to UPOV`,
    },
    {
        icon: faTrashAlt,
        message: `Delete application`,
    },
];

const renderListItem = ({
    icon,
    message,
    index,
    listLength,
}: {
    icon: any;
    message: string;
    index: number;
    listLength: number;
}) => {
    return (
        <div key={message} style={{display: 'inline-block'}}>
            <div className={cx(styles.legendItem, styles.icon)}>
                <FontAwesomeIcon icon={icon} />
            </div>
            <div className={styles.legendItem}>{` ${message} ${index < listLength - 1 ? ' - ' : ''}`}</div>
        </div>
    );
};

function OnlineApplicationsCustomTableBottomCaption({loggedInUserCompany = ''}) {
    return (
        <div>
            <fieldset className={styles.schedulerBorder}>
                <legend className={styles.legendSchedulerBorder}>
                    <i className="small" data-translate="">
                        <span className="ng-scope">{`Legend`}</span>
                    </i>
                </legend>
                <div className={styles.containerTop}>
                    <span className={styles.legendDelegation}>My delegations</span>
                    <span className={styles.legendSlash}>/</span>
                    <span className={styles.legendSignedBy}>
                        {`Created by other members of ${loggedInUserCompany}`}
                    </span>
                </div>
                <div className={styles.containerTopText}>
                    <strong>AF</strong> : Application form / <strong>TQ</strong> : Technical questionnaire /{' '}
                    <strong>SIGN</strong> : Sign application
                </div>
                <div className={styles.containerTop}>
                    <span className={styles.legendDelegation}>Form under preparation</span>
                    <span className={styles.legendSlash}>/</span>
                    <span className={styles.legendSignedBy}>Valid form</span>
                </div>
                <div className={styles.container}>
                    {listItems.map((el, i) =>
                        renderListItem({
                            icon: el.icon,
                            message: el.message,
                            index: i,
                            listLength: listItems.length,
                        })
                    )}
                </div>
            </fieldset>
        </div>
    );
}

export default OnlineApplicationsCustomTableBottomCaption;
