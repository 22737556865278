import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faAngleDoubleUp,
    faChevronUp,
    faChevronDown,
    faAngleDoubleDown,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

function renderButton(popupContent: any, handler: any, icon: any, length?: any) {
    const arrowJsx = (popupContent: any) => (
        <a data-content={popupContent}>
            <FontAwesomeIcon icon={icon} />
        </a>
    );
    return {
        title: popupContent,
        icon: (object: any) => {
            switch (icon) {
                case faAngleDoubleUp:
                case faChevronUp:
                    return object.order === 0 || length === 1 ? <div /> : arrowJsx(popupContent);
                case faChevronDown:
                case faAngleDoubleDown:
                    return object.order === length - 1 || length === 1 ? <div /> : arrowJsx(popupContent);
                default:
                    return arrowJsx(popupContent);
            }
        },
        handler,
    };
}

export default function BackOfficeFormGeneralSettingsActionButtons(
    moveToFirstPosition: any,
    moveToPreviousPosition: any,
    moveToNextPosition: any,
    moveToLastPosition: any,
    deleteSpecie: any,
    length: any
) {
    const buttons = [];
    buttons.push(
        renderButton(
            'Move to first position',
            (specieId: any, rowObject: any) => moveToFirstPosition(rowObject.order, rowObject.specieId),
            faAngleDoubleUp,
            length
        )
    );
    buttons.push(
        renderButton(
            'Move to previous position',
            (specieId: any, rowObject: any) => moveToPreviousPosition(rowObject.order, rowObject.specieId),
            faChevronUp,
            length
        )
    );
    buttons.push(
        renderButton(
            'Move to next position',
            (specieId: any, rowObject: any) => moveToNextPosition(rowObject.order, rowObject.specieId),
            faChevronDown,
            length
        )
    );
    buttons.push(
        renderButton(
            'Move to last position',
            (specieId: any, rowObject: any) => moveToLastPosition(rowObject.order, rowObject.specieId),
            faAngleDoubleDown,
            length
        )
    );
    buttons.push(
        renderButton(
            'Delete',
            (specieId: any, rowObject: any) => {
                deleteSpecie(rowObject.specieId);
            },
            faTrashAlt
        )
    );
    return buttons;
}
