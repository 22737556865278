import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export function apiCommunicationCentreInternalBackOfficeSearch(
    criteria: any,
    DEFAULT_CRITERIA: any = {},
    excelTranslations?: boolean
) {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/communicationCentreInternal/v4/CCIBS/backoffice/search?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}

export function apiCommunicationCentreFixSummary(communicationId: number) {
    return postFetch(`${API_SERVERLESS}/communicationCentreInternal/v4/CCIBRP/backoffice/repairPdf`, true, {
        communicationId,
    });
}
