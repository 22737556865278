import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export function apiCommunicationCentreClientSearchArchive(
    criteria: any,
    DEFAULT_CRITERIA: any = {},
    excelTranslations?: boolean
) {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/communicationCentreClient/v4/CCCAS/archive/search?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}

export function apiCommunicationCentreClientMessageUnArchive(communicationId: number) {
    return postFetch(`${API_SERVERLESS}/communicationCentreClient/v4/CCCAMU/archive/messageUnArchive`, true, {
        communicationIds: [communicationId],
    });
}
