import React, {useState} from 'react';
import {ModalContactUsVersion2} from '../commonModals';
import Navigation from '../componentsLayout/Navigation';
import {getUserRoles} from '../screens/TLO/CommonFunctions/CommonFunctions';

interface INavigationPublicSearchProps {
    activeTitle?: string;
}

function NavigationPublicSearch({activeTitle}: INavigationPublicSearchProps) {
    const [isModalContactUsOpen, setIsModalContactUsOpen] = useState(false);

    const userRoles = getUserRoles();

    const links = [{to: '/acarea', title: 'Dashboard'}];
    if (userRoles.indexOf('ACADM') !== -1) {
        links.push({
            to: '/acareaAdministratorSections',
            title: 'Administrator',
        });
    }
    links.push({
        to: '/acareaSections',
        title: 'Sections',
    });
    links.push({
        to: '/acareaRulesOfProcedures',
        title: 'Rules of Procedures',
    });
    links.push({
        to: '/acareaMembersList',
        title: 'Members List',
    });

    return (
        <>
            {isModalContactUsOpen ? (
                <ModalContactUsVersion2
                    additionalSendToList={[
                        {
                            label: 'AC Contact point',
                            email: 1,
                        },
                        {
                            label: 'Webmaster',
                            email: 2,
                        },
                    ]}
                    subjectTag={'[ACAREA]'}
                    close={() => setIsModalContactUsOpen(false)}
                />
            ) : null}
            <Navigation
                activeTitle={activeTitle}
                links={links}
                linkModals={[{onClick: () => setIsModalContactUsOpen(true), title: 'Contact us'}]}
            />
        </>
    );
}

export default NavigationPublicSearch;
