export const listByIdNameVarietyStatus = [
    {ID: 'All', NAME: 'All'},
    {ID: '1', NAME: 'Application'},
    {ID: '2', NAME: 'Registered'},
    {ID: '3', NAME: 'Withdrawn'},
    {ID: '4', NAME: 'Rejected'},
    {ID: '5', NAME: 'Surrendered'},
    {ID: '6', NAME: 'Terminated'},
    {ID: '7', NAME: 'Expired'},
];
