import React, {useEffect, useRef, useState} from 'react';
import {Button} from '../../../../componentsFormV2';
import {Editor} from '@tinymce/tinymce-react';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import {ModalErrorVersion2} from '../../../../commonModals';
import NavigationTLO from '../../../../shared/NavigationTLO';
import Title from '~components/Title';
import {apiGetEditorContent} from '../../CommonApi/CommonApi';
import {apiPostEditorContent, Parameter} from './AdministratorPageService';
import {getUserRoles} from '../../CommonFunctions/CommonFunctions';
import styles from './AdministratorPage.module.scss';
import {trackPageView} from '../../../../utils';

const modalScreenTypes = {ERROR: 'ERROR'};

const AdministratorPage = () => {
    const editorConfig = {
        width: '100%',
        height: 500,
        menubar: false,
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
        ],
        toolbar1: 'undo redo | bold italic underline strikethrough | ' + 'fontselect fontsizeselect formatselect ',
        toolbar2:
            'forecolor backcolor | alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist outdent indent | ' +
            'removeformat | link unlink | help',
        content_style: 'body { font-family:sans-serif; font-size:16px }',
    };
    const editorRef: React.MutableRefObject<any> = useRef(null);
    const [editorContent, setEditorContent] = useState<Parameter>({valueString: ''});
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [userRoles] = useState(getUserRoles());

    useEffect(() => {
        trackPageView({documentTitle: 'tloAdministrator'});
        userRoles.indexOf('EOADM') !== -1 && getEditorContent();
    }, []);

    const checkEditorContentFetched = (jsonData: Array<Parameter>) => {
        if (jsonData.length === 1 && jsonData[0].valueString) {
            setEditorContent({
                valueString: jsonData[0].valueString,
            });
        } else {
            setErrorMessage(`No Personalised home message found.`);
            setModalScreen(modalScreenTypes.ERROR);
        }
    };

    const getEditorContent = () => {
        setLoading(true);
        apiGetEditorContent()
            .then(jsonResponse => {
                checkEditorContentFetched(jsonResponse);
            })
            .catch(error => {
                setErrorMessage(`Fetch Editor Content error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getContentFromHTML = (content: string) => {
        const DIV = document.createElement('DIV');
        DIV.innerHTML = content;
        return DIV.textContent || DIV.innerText || '';
    };

    const onEditorChange = (contentHTML: string) => {
        const text = getContentFromHTML(contentHTML);
        setEditorContent({
            valueString: text,
        });
    };

    const saveEditorContent = () => {
        setLoading(true);
        if (editorRef.current) {
            apiPostEditorContent({homeHtml: editorContent.valueString})
                .then((jsonResponse: any) => {
                    if (jsonResponse) {
                        getEditorContent();
                    }
                })
                .catch((error: any) => {
                    setErrorMessage(`Save Editor Content error: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        }
    };

    const closeErrorModal = () => {
        setErrorMessage(null);
        setModalScreen(null);
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`Technical Liaison Officer`} />
            <NavigationTLO />
            <MainWrapper>
                <FormWrapper paddingFormContent={'sm'}>
                    {userRoles.indexOf('EOADM') === 0 ? (
                        <div style={{paddingTop: 20}}>
                            <b style={{color: 'red'}}>
                                <span className="ng-scope">{`You have no permissions to access this page.`}</span>
                            </b>
                        </div>
                    ) : (
                        <>
                            <div className={styles.mainFieldContainer}>
                                <Title triple={true}>{`Personalised home message`}</Title>
                            </div>
                            <Editor
                                tinymceScriptSrc="../../../../public/tinymce/tinymce.min.js"
                                onInit={(evt, editor) => (editorRef.current = editor)}
                                value={editorContent.valueString}
                                onEditorChange={onEditorChange}
                                init={editorConfig}
                            />
                            <FormFooter>
                                <Button clickAction={saveEditorContent}>{`Save`}</Button>
                            </FormFooter>
                            <div style={{clear: 'both'}} />
                        </>
                    )}
                </FormWrapper>
            </MainWrapper>
            <Footer />
        </>
    );
};

export default AdministratorPage;
