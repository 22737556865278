import {API_SERVERLESS, getFetch, postFetch} from '../../commonApi';
import {ICommunicationMessageComment} from '../../types';

export function apiCommunicationCentreModalInternalReadMessageRead(communicationId: number) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIRMR/read/messageRead/${communicationId}`,
        true
    );
}

export function apiCommunicationCentreModalRebuildSummaryPDF(communicationId: number) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIRRS/read/rebuildSummary/${communicationId}`,
        true
    );
}

export function apiCommunicationCentreModalInternalComments(communicationId: number): Promise<{
    comments: ICommunicationMessageComment[];
}> {
    return getFetch(`${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIRC/comments/${communicationId}`, true);
}

export function apiCommunicationCentreModalInternalCommentCreate(JSONRequest: {
    communicationId: number;
    body: string;
}): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIRCC/commentCreate`, true, JSONRequest);
}

export function apiCommunicationCenterModalInternalReadDownloadDocument({
    communicationId,
    serverIndex,
}: {
    communicationId: number;
    serverIndex?: string;
}) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIRMDD/read/downloadDocument/${communicationId}/${serverIndex}`,
        true
    );
}
