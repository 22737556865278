import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';
import {ILabelsSearchResponse} from '../../../types';

export interface IPayloadFormResponseCreateOrUpdate {
    nameMessage: {
        id: number;
    };
    remarkMessage: {
        id: number | null;
    };
    helpMessage: {
        id: number | null;
    };
    idElement: number | null;
    office: number;
    score: string | null;
    order: string;
    mappingCode: string | null;
    jsonMapping: string | null;
    remarkAllowed: number | null;
    remarkMandatory: number | null;
    status: number;
    questionId: number;
    responseId: number;
}

export function apiBackOfficeFormOrTemplateResponseCreateOrUpdate(
    JSONRequest: IPayloadFormResponseCreateOrUpdate
): Promise<{
    newResponseId?: number;
    data?: string;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formResponseCreateOrUpdate`, true, JSONRequest);
}

export function apiBackOfficeLabels({
    responseSearchTerm,
    officeId = 1,
    languageId = 'EN',
}: {
    responseSearchTerm: string;
    officeId?: number;
    languageId: string;
}): Promise<ILabelsSearchResponse> {
    return getFetch(
        `${API_SERVERLESS}/backOffice/v2/labels?withHTML=true&officeId=${officeId}&languageId=${languageId}&typeId=4&contains=${responseSearchTerm}&containsFilter=start&order=value`,
        true
    );
}

export function apiBackOfficeUpdateLabels({
    labelValue,
    languageId = 'EN',
}: {
    labelValue: string;
    languageId: string;
}): Promise<{data: number}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v2/updateLabels/4`, true, {
        [languageId]: {
            labelId: 0,
            labelValue: encodeURIComponent(labelValue),
            labelValueHTML: encodeURIComponent(labelValue),
        },
    });
}
