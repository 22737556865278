import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt, faPlusCircle, faPrint, faTasks, faUpload} from '@fortawesome/free-solid-svg-icons';
import {faCopy, faEye, faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {I_SEARCH_RESULT_FORM} from './BackOfficeFormsService';
import {Popover} from 'react-tiny-popover';
import PaperPrintIcon from '../../img/paper_print.png';

const STATUS_IN_PROCESS = 1;
const STATUS_ACTIVE = 2;

const languagesMap: any = {
    EN: 'English',
    FR: 'French',
    ES: 'Spanish',
    DE: 'Deutsch',
    NL: 'Dutch',
};

export const checkIfFormStatusIsScheduled = (rowObject: I_SEARCH_RESULT_FORM): boolean => {
    const {status, newVersionID} = rowObject || {};
    return status === STATUS_IN_PROCESS && !!newVersionID;
};

function renderButton(popupContent: any, handler: any, icon: any) {
    return {
        title: popupContent,
        icon: () => (
            <a data-content={popupContent}>
                <FontAwesomeIcon icon={icon} color={'green'} />
            </a>
        ),
        handler,
    };
}

function renderEditButton(handler: any, userOfficeId: any) {
    return {
        icon: (rowObject: I_SEARCH_RESULT_FORM) => {
            const {newVersionID} = rowObject || {};
            const isStatusScheduled = checkIfFormStatusIsScheduled(rowObject);
            const isEditionAvailable = rowObject.officeId === userOfficeId && !isStatusScheduled;
            let buttonTitle = 'Edit';
            isStatusScheduled &&
                (buttonTitle = `You cannot edit this application because there is a new version of this form with ID: ${newVersionID}.`);
            rowObject.officeId !== userOfficeId &&
                (buttonTitle = `Form belongs to another office and cannot be edited`);

            return (
                <a title={buttonTitle} style={{cursor: isEditionAvailable ? `pointer` : `not-allowed`}}>
                    <FontAwesomeIcon icon={faPencilAlt as any} color={isEditionAvailable ? 'green' : 'lightgray'} />
                </a>
            );
        },
        handler,
    };
}

function renderCompareButton(handler: any) {
    return {
        icon: (rowObject: I_SEARCH_RESULT_FORM) => {
            const isCompareAvailable = !!rowObject.origin;
            return (
                <a
                    title={isCompareAvailable ? `Compare` : `Form does not have a previous version to compare`}
                    style={{cursor: isCompareAvailable ? `pointer` : `default`}}
                >
                    <FontAwesomeIcon icon={faTasks as any} color={isCompareAvailable ? 'green' : 'lightgray'} />
                </a>
            );
        },
        handler,
    };
}

function renderCopyButton(handler: any, userOfficeId: any) {
    return {
        icon: (rowObject: I_SEARCH_RESULT_FORM) => {
            const {newVersionID} = rowObject || {};
            const isStatusScheduled = checkIfFormStatusIsScheduled(rowObject);
            const isCopyAvailable = rowObject.officeId === userOfficeId && !isStatusScheduled;
            let buttonTitle = 'Copy';
            isStatusScheduled &&
                (buttonTitle = `You cannot copy this application because there is a new version of this form with ID: ${newVersionID}.`);
            rowObject.officeId !== userOfficeId &&
                (buttonTitle = `Form belongs to another office and cannot be copied`);

            return (
                <a title={buttonTitle} style={{cursor: isCopyAvailable ? `pointer` : `not-allowed`}}>
                    <FontAwesomeIcon icon={faCopy as any} color={isCopyAvailable ? 'green' : 'lightgray'} />
                </a>
            );
        },
        handler,
    };
}

function RenderReadOnlyPrintButtonFC({rowObject, userOfficeId}: any) {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const onFormLanguageClick = (formLanguage: string) => {
        const {type, formId} = rowObject || {};
        const formType = type === 1 ? 'AF' : 'TQ';
        let formLanguageQuery = formLanguage !== 'EN' ? `&formLanguage=${formLanguage}` : '';
        window.open(`/backOfficePdf?formId=${formId}&formType=${formType}${formLanguageQuery}`);
    };

    const isPrintAvailable = rowObject.officeId === userOfficeId;

    if (isPrintAvailable) {
        if (rowObject && (rowObject.formLanguages || []).length === 1) {
            return (
                <a title={`Read-only Print`} style={{cursor: `pointer`}} onClick={() => onFormLanguageClick('EN')}>
                    <FontAwesomeIcon icon={faPrint as any} color={'green'} />
                </a>
            );
        } else {
            return (
                <Popover
                    isOpen={isPopoverOpen}
                    positions={'top'}
                    onClickOutside={() => setIsPopoverOpen(false)}
                    content={() => (
                        <div
                            style={{
                                padding: '5px 0',
                                width: 120,
                                background: 'white',
                                border: '1px solid #2f923f',
                                borderRadius: 5,
                            }}
                        >
                            {rowObject.formLanguages.map((formLanguage: any) => (
                                <div
                                    key={formLanguage}
                                    style={{
                                        padding: '5px 15px',
                                        color: 'green',
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                    }}
                                    onClick={() => onFormLanguageClick(formLanguage)}
                                >
                                    {languagesMap[formLanguage]}
                                </div>
                            ))}
                        </div>
                    )}
                >
                    <div
                        title={`Read-only Print`}
                        style={{cursor: isPrintAvailable ? 'pointer' : 'not-allowed'}}
                        onClick={() => setIsPopoverOpen(true)}
                    >
                        <FontAwesomeIcon icon={faPrint as any} color={isPrintAvailable ? 'green' : 'lightgray'} />
                    </div>
                </Popover>
            );
        }
    } else {
        return (
            <a title={`Form belongs to another office and cannot be printed`} style={{cursor: `not-allowed`}}>
                <FontAwesomeIcon icon={faPrint as any} color={'lightgray'} />
            </a>
        );
    }
}

function renderReadOnlyPrintButton(userOfficeId: any) {
    return {
        icon: (rowObject: any) => <RenderReadOnlyPrintButtonFC rowObject={rowObject} userOfficeId={userOfficeId} />,
        handler: () => null,
    };
}

function RenderPaperPrintButtonFC({rowObject, userOfficeId}: any) {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const isPrintAvailable = rowObject.officeId === userOfficeId && (rowObject.formLanguages || []).length;

    if (isPrintAvailable) {
        if (rowObject && (rowObject.formLanguages || []).length === 1) {
            return (
                <a
                    title={`Paper Print`}
                    style={{cursor: `pointer`, display: 'flex'}}
                    href={`https://applyfor.plantvarieties.eu/api.ocvv/oa/questionnaire/pdf?language=EN&confidential=0&blankForm=1&questionnaireId=${
                        rowObject && rowObject.formId
                    }`}
                    download
                >
                    <img
                        src={PaperPrintIcon}
                        alt="Paper Print icon"
                        style={{
                            height: 20,
                        }}
                    />
                </a>
            );
        } else {
            return (
                <Popover
                    isOpen={isPopoverOpen}
                    positions={'top'}
                    onClickOutside={() => setIsPopoverOpen(false)}
                    content={() => (
                        <div
                            style={{
                                padding: '5px 0',
                                width: 120,
                                background: 'white',
                                border: '1px solid #2f923f',
                                borderRadius: 5,
                            }}
                        >
                            {(rowObject.formLanguages || []).map((formLanguage: any) => (
                                <a
                                    key={formLanguage}
                                    style={{
                                        padding: '5px 15px',
                                        color: 'green',
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        display: 'block',
                                    }}
                                    href={`https://applyfor.plantvarieties.eu/api.ocvv/oa/questionnaire/pdf?language=${formLanguage.toUpperCase()}&confidential=0&blankForm=1&questionnaireId=${
                                        rowObject && rowObject.formId
                                    }`}
                                    download
                                >
                                    {languagesMap[formLanguage]}
                                </a>
                            ))}
                        </div>
                    )}
                >
                    <div
                        title={`Paper Print`}
                        style={{cursor: 'pointer', display: 'flex'}}
                        onClick={() => setIsPopoverOpen(true)}
                    >
                        <img
                            src={PaperPrintIcon}
                            alt="Paper Print icon"
                            style={{
                                height: 20,
                            }}
                        />
                    </div>
                </Popover>
            );
        }
    } else {
        return (
            <a
                title={`Form belongs to another office and cannot be printed, or form languages are not available`}
                style={{cursor: `default`, display: 'flex'}}
            >
                <img
                    src={PaperPrintIcon}
                    alt="Paper Print icon"
                    style={{
                        height: 20,
                        opacity: 0.6,
                        cursor: 'not-allowed',
                        filter: 'grayscale(1)',
                    }}
                />
            </a>
        );
    }
}

function renderPaperPrintButton(userOfficeId: any) {
    return {
        icon: (rowObject: any) => <RenderPaperPrintButtonFC rowObject={rowObject} userOfficeId={userOfficeId} />,
        handler: () => null,
    };
}

function renderNewVersionButton(handler: any, userOfficeId: any) {
    return {
        icon: (rowObject: I_SEARCH_RESULT_FORM) => {
            const isNewVersionAvailable = rowObject.status === STATUS_ACTIVE && rowObject.officeId === userOfficeId;
            return (
                <a
                    title={
                        isNewVersionAvailable
                            ? `New Version`
                            : rowObject.officeId === userOfficeId
                            ? `Form is not "Active" and a new version cannot be created`
                            : `Form belongs to another office and a new version cannot be created`
                    }
                    style={{cursor: isNewVersionAvailable ? `pointer` : `not-allowed`}}
                >
                    <FontAwesomeIcon icon={faPlusCircle as any} color={isNewVersionAvailable ? 'green' : 'lightgray'} />
                </a>
            );
        },
        handler,
    };
}

function renderImportButton(handler: any, userOfficeId: any) {
    return {
        icon: (rowObject: I_SEARCH_RESULT_FORM) => {
            const isImportAvailable = rowObject.officeId !== userOfficeId;
            return (
                <a
                    title={isImportAvailable ? `Import` : `Form belongs to the office and cannot be imported`}
                    style={{cursor: isImportAvailable ? `pointer` : `not-allowed`}}
                >
                    <FontAwesomeIcon icon={faUpload as any} color={isImportAvailable ? 'green' : 'lightgray'} />
                </a>
            );
        },
        handler,
    };
}

function renderDeleteButton(handler: any, userOfficeId: any) {
    return {
        icon: (rowObject: I_SEARCH_RESULT_FORM) => {
            const {newVersionID} = rowObject || {};
            const isStatusScheduled = checkIfFormStatusIsScheduled(rowObject);
            const isDeleteAvailable =
                rowObject.status === STATUS_IN_PROCESS && rowObject.officeId === userOfficeId && !isStatusScheduled;

            let buttonTitle = 'Delete';
            isStatusScheduled &&
                (buttonTitle = `You cannot delete this application because there is a new version of this form with ID: ${newVersionID}.`);
            rowObject.status !== STATUS_IN_PROCESS &&
                (buttonTitle = `Form is not "Under Preparation" and cannot be deleted`);
            rowObject.officeId !== userOfficeId &&
                (buttonTitle = `Form belongs to another office and cannot be deleted`);

            return (
                <a title={buttonTitle} style={{cursor: isDeleteAvailable ? `pointer` : `not-allowed`}}>
                    <FontAwesomeIcon icon={faTrashAlt as any} color={isDeleteAvailable ? 'green' : 'lightgray'} />
                </a>
            );
        },
        handler,
    };
}

export default function BackOfficeFormsActionButtons(
    userOfficeId: any,
    onTableIconPreviewClick: any,
    onTableIconEditClick: any,
    onTableIconCompareClick: any,
    onTableIconCopyClick: any,
    onTableIconNewVersionClick: any,
    onTableIconImportClick: any,
    onTableIconDeleteClick: any
) {
    const buttons = [];
    buttons.push(
        renderButton(
            'Preview',
            (formId: number, rowElement: I_SEARCH_RESULT_FORM) => onTableIconPreviewClick(formId, rowElement.type),
            faEye
        )
    );
    buttons.push(renderEditButton((formId: number) => onTableIconEditClick(formId), userOfficeId));
    buttons.push(renderCompareButton((formId: number) => onTableIconCompareClick(formId)));
    buttons.push(renderCopyButton((formId: number) => onTableIconCopyClick(formId), userOfficeId));
    buttons.push(renderReadOnlyPrintButton(userOfficeId));
    buttons.push(renderPaperPrintButton(userOfficeId));
    buttons.push(renderNewVersionButton((formId: number) => onTableIconNewVersionClick(formId), userOfficeId));
    buttons.push(renderImportButton((formId: number) => onTableIconImportClick(formId), userOfficeId));
    buttons.push(renderDeleteButton((formId: number) => onTableIconDeleteClick(formId), userOfficeId));
    return buttons;
}
