import React from 'react';
import {injectIntl} from 'react-intl';
import ApplicationConsultationTabs, {ACCESS, TAB} from '~shared/ApplicationConsultationTabs';
import {FORMAT_DATE_EASY} from '~components/FormatFunctions';
import CustomTable from '~components/CustomTable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faList} from '@fortawesome/free-solid-svg-icons';
import {Footer, FormWrapper, HeaderLoading, HeaderLogo, HeaderTitleAndVersion} from '../../componentsLayout';
import {loadUrlParams, trackPageView} from '../../utils';
import {apiPublicSearchPublications} from '../../commonApi/publicSearch';
import {IPublicationConsultationItem} from '../../types';
import MainWrapper from '../../componentsLayout/MainWrapper';
import NavigationTLO from '../../shared/NavigationTLO';
import NavigationMyPVR from '../../shared/NavigationMyPVR';

interface IProps {
    close: any;
    intl: any;
}

interface IState {
    loading: boolean;
    publications: IPublicationConsultationItem[] | null;
    timestamp: number;
}

class MyPVRPrivateConsultationPublications extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {publications: null, timestamp: Date.now(), loading: false};
    }

    componentDidMount() {
        trackPageView({documentTitle: 'privateConsultationPublications'});
    }

    loadJSONs = (applicationNumber: string) =>
        new Promise((resolve, reject) => {
            this.setState({loading: true}, () => {
                apiPublicSearchPublications(applicationNumber)
                    .then(JSONResponse => {
                        JSONResponse.data && this.setState({publications: JSONResponse.data, timestamp: Date.now()});
                    })
                    .then(() => this.setState({loading: false}, () => resolve))
                    .catch(reject);
            });
        });

    render() {
        const urlParams = loadUrlParams();

        return (
            <>
                {this.state.loading ? <HeaderLoading /> : null}
                <HeaderLogo />
                <HeaderTitleAndVersion title={`My PVR`} />
                {urlParams.tlo ? <NavigationTLO activeTitle={`My Applications`} /> : <NavigationMyPVR />}
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <ApplicationConsultationTabs
                            search={this.loadJSONs}
                            tabId={TAB.PUBLICATIONS}
                            access={ACCESS.PRIVATE}
                            loading={this.state.publications === null}
                            close={this.props.close}
                        />
                        {this.state.publications !== null ? (
                            <>
                                {this.state.publications.length > 0 ? (
                                    <CustomTable
                                        tableName={'publications'}
                                        tableType={'OBJECT'}
                                        tableSource={this.state.publications}
                                        timestamp={this.state.timestamp}
                                        id={'gazetteId'}
                                        resultFieldsDefault={['denomination', 'publicationDate', 'chapterId']}
                                        intl={this.props.intl}
                                        defaultOrder={'publicationDate'}
                                        formatFunctions={{
                                            publicationDate: FORMAT_DATE_EASY,
                                            chapterId: (fieldData: any, rowObject: any) => (
                                                <React.Fragment>
                                                    <div style={{float: 'left', width: 50}}>{fieldData}</div>
                                                    <div style={{float: 'left'}}>{`${rowObject['chapterName']}`}</div>
                                                </React.Fragment>
                                            ),
                                        }}
                                        actions={[
                                            {
                                                title: 'Download gazette',
                                                icon: (rowObject: any) =>
                                                    rowObject.publicationDate && rowObject.gazetteFile ? (
                                                        <a
                                                            href={rowObject.gazetteFile}
                                                            target={`_blank`}
                                                            rel="noopener noreferrer"
                                                        >
                                                            <FontAwesomeIcon icon={faList as any} color="green" />
                                                        </a>
                                                    ) : null,
                                                handler: () => null,
                                            },
                                        ]}
                                    />
                                ) : (
                                    <b style={{color: 'red'}}>{`No result found`}</b>
                                )}
                            </>
                        ) : null}
                    </FormWrapper>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(MyPVRPrivateConsultationPublications);
