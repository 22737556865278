import React from 'react';

// PROPS
import {MyInvoicesTableAreaProps} from './props/MyInvoicesTableAreaProps';

// INTERFACES
import {MyInvoicesFilterCriteriaData} from '../../MyInvoicesPageService';

// REUSABLE COMPONENTS
import CustomTable from '../../../../../../components/CustomTable';
import {FORMAT_DATE} from '../../../../../../components/FormatFunctions';

export default function MyInvoicesTableArea(props: MyInvoicesTableAreaProps) {
    const RESULT_FIELDS_DEFAULT = [
        'cpvoReference',
        'supplierReference',
        'invoiceStatusClear',
        'invoiceDate',
        'cpvoRemark',
        'cpvoFile',
        'receptionDate',
        'paymentDate',
        'amount',
        'vat',
        'totalPaid',
    ];

    return (
        <React.Fragment>
            <CustomTable
                version={2}
                pageNumber={props.criteria.pageNumber}
                {...props}
                tableName={'myInvoices'}
                tableType={'OBJECT'}
                tableSource={props.myInvoices}
                timestamp={props.timestamp}
                id={'cpvoReference'}
                pagination={true}
                printExcel={(excelTranslations: {[key: string]: string}) => props.printExcel(excelTranslations)}
                formatFunctions={{
                    invoiceDate: FORMAT_DATE,
                    reception_date: FORMAT_DATE,
                    payment_date: FORMAT_DATE,
                }}
                resultFieldsAll={RESULT_FIELDS_DEFAULT}
                resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                intl={props.intl}
                defaultOrder={props.criteria.order}
                reverseOrder={props.criteria.reverse}
                pageSize={props.criteria.pageSize}
                count={props.count}
                updateCriteriaValue={(criteriaValue: MyInvoicesFilterCriteriaData) =>
                    props.updateCriteriaValue(criteriaValue)
                }
            />
        </React.Fragment>
    );
}
