import React, {useState} from 'react';
import {Popover} from 'react-tiny-popover';
import styles from './WizardResponseLogicsPopover.module.scss';
import {htmlToText} from '~utils/index';
import {IGroup, IGroupQuestionResponse, IGroupQuestionResponseLogic} from '../../../types';

const RESPONSE_LOGICS_SHORT_DESCRIPTIONS: any = {
    showquestion:
        "If this response is selected / checked or filled in, then the destination hidden question gets enabled and it's responses appear",
    forceresponse:
        'If this response is selected / checked or filled in, then the destination response should have a specific answer which is defined by this logic',
    assistedentry:
        'Display a select / auto complete list with various options, depending on logic param. More info in Response logics tab - info button',
    area: 'Transforms the text input into a resizable text area',
    optional:
        'If question is mandatory and this response has explicitly the optional logic type, then this respond in not mandatory and no red asterisk appears',
    clientcode:
        'Appears a search icon, which when clicked opens a modal to search clients and fills mandatory fields with client information',
    emailaddress: 'Validates that the user has entered a proper format of email',
    autocomplete:
        'Shows suggestions on user input, depending on logic param - countries / languages / species. The user can select an option and modify it',
    controllist:
        'Shows suggestions on user input, depending on logic param - countries / languages / species. The user can select an option but cannot modify it',
    regularexpression: 'Validates user information that are in proper format for current response',
    disabled:
        'User cannot enter information in fields with disabled logic. Response with disabled logic can target another response',
    combobox: 'Shows a dropdown list with options depending on logic param (e.g. languages).',
    uploadfilter: 'Shows a button to add documents and a table of uploaded documents with edit and delete actions',
    length: 'Response answer must have a minimum and maximum characters length depending on logic param',
    date: 'Transforms the input into a date picker',
    number: 'Only numbers can be entered in this input',
};

const renderResponseLogicShortDescription = (logicType: string) => {
    if (!logicType || !RESPONSE_LOGICS_SHORT_DESCRIPTIONS[logicType]) {
        return null;
    }
    return (
        <div className={styles.responsePopoverShortDescription}>{RESPONSE_LOGICS_SHORT_DESCRIPTIONS[logicType]}</div>
    );
};

const findNameOfDestinationQuestionAndIfNeededResponse = ({
    logic,
    steps,
}: {
    logic: IGroupQuestionResponseLogic;
    steps: IGroup[];
}) => {
    let destinationQuestion =
        (logic &&
            logic.questionIdDest &&
            (steps || [])
                .map(el => el.questions)
                .flat(1)
                .find(question => question && question.id === logic.questionIdDest)) ||
        null;
    let destinationResponse =
        logic.responseIdDest &&
        ((destinationQuestion && destinationQuestion.responses) || []).find(
            response => response.id === logic.responseIdDest
        );
    return (
        <>
            {destinationQuestion ? (
                <div>
                    <div>
                        {' '}
                        {logic.name}:&nbsp;
                        <strong>
                            {destinationQuestion.order}
                            {destinationResponse && ` / ${htmlToText(destinationResponse.message)}`}
                        </strong>
                    </div>
                    {renderResponseLogicShortDescription(logic.type)}
                </div>
            ) : (
                ''
            )}
        </>
    );
};

const renderLogicTitle = ({logic, steps}: {logic: IGroupQuestionResponseLogic; steps: IGroup[]}) => {
    if (logic.questionIdDest) {
        return findNameOfDestinationQuestionAndIfNeededResponse({logic, steps});
    }
    return (
        <>
            <div>{logic && `${logic.name}`}</div>
            {renderResponseLogicShortDescription(logic.type)}
        </>
    );
};

export default function WizardResponseLogicsPopover({
    response,
    steps,
}: {
    response: IGroupQuestionResponse;
    steps: IGroup[];
}) {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    return (
        <Popover
            isOpen={isPopoverOpen}
            positions={'left'}
            onClickOutside={() => null}
            content={() => (
                <div
                    className={styles.customPopover}
                    style={{
                        background: '#e8e8e8',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '4px',
                        maxWidth: '100%',
                        opacity: 0.9,
                        borderColor: 'black',
                        borderWidth: 1,
                        borderStyle: 'solid',
                    }}
                >
                    <div className={styles.popoverTitle}>
                        Response logics: {response && htmlToText(response.message)}
                    </div>
                    <div className={styles.popoverLogicsWrap}>
                        <ul>
                            {((response && response.logics) || []).map(logic => (
                                <li key={`popover-response-logic-${response.id}`} className={styles.popoverLogic}>
                                    {renderLogicTitle({logic, steps})}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        >
            {response && response.logics && response.logics.length > 0 ? (
                <div
                    onMouseEnter={() => setIsPopoverOpen(true)}
                    onMouseLeave={() => setIsPopoverOpen(false)}
                    className={styles.responseLogicsLabel}
                    title={``}
                >
                    {response.logics.length}
                </div>
            ) : (
                <div />
            )}
        </Popover>
    );
}
