import React, {useEffect, useRef, useState} from 'react';
import AdminIcon from '../../../TLO/CommonComponents/AdminIcon/AdminIcon';
import {
    CaseElement,
    CaseMetaDataFetchData,
    CasesListFetchData,
    SearchFiles,
} from '../../CommonInterfaces/CommonInterfaces';
import CustomTable from '../../../../components/CustomTable';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import {History} from 'history';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {ModalErrorVersion2} from '../../../../commonModals';
import ModalCaseDetails from '../../CommonComponents/ModalCaseDetails/ModalCaseDetails';
import ModalCaseDelete from '../../CommonComponents/ModalCaseDelete/ModalCaseDelete';
import NavigationPVRCaseLaw from '../../../../shared/NavigationPVRCaseLaw';
import {RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import SubMenuTabs from '../../../TLO/SubMenu/SubMenuTabs';
import SearchCasesBasicFiltersCriteria from './Components/SearchCasesBasicFiltersCriteria/SearchCasesBasicFiltersCriteria';
import {
    apiCaseDelete,
    apiCasesListFetch,
    apiDownloadFile,
    apiFilesSearch,
    apiViewCaseFetch,
} from '../../CommonFunctions/CommonFunctions';
import en from '../../../../translations/en.json';
import {faEdit, faIdCard, faPlus, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {formatDate} from '../../../../components/FormatFunctions';
import {getUserRoles} from '../../../TLO/CommonFunctions/CommonFunctions';
import {getPreSignedURLFetchRequest} from '../../../../utils/requests';
import {getExcelSignedURLFetchRequest} from '../../../../commonApi/download';
import {injectIntl, IntlShape} from 'react-intl';
import moment from 'moment';
import {saveAs} from 'file-saver';
import styles from './SearchCasesPageBasicPVRCASELAW.module.scss';
import {trackPageView} from '../../../../utils';

const modalScreenTypes = {
    SAVE_CASE: 'SAVE_CASE',
    CONFIRM_DELETE: 'CONFIRM_DELETE',
    READ_DETAILS_CASE: 'READ_DETAILS_CASE',
    ERROR: 'ERROR',
};

const DEFAULT_CRITERIA: SearchFilesBasicFiltersCriteriaData = {
    fileName: '',
    fileNameFilter: 'all',
    pageNumber: 1,
    pageSize: Number(localStorage.getItem('pageSizePvrcaselawCasesBasicList')) || 50,
    reverse: false,
    order: 'caseId',
    excel: false,
    refresh: false,
};

const DEFAULT_COUNT = 0;

const DEFAULT_PAGE_SIZE = 50;

const DEFAULT_KEYWORDS_SIZE = 50;

export interface SearchFilesBasicFiltersCriteriaData {
    fileName: string;
    fileNameFilter: string;
    //
    pageNumber: number;
    pageSize: number;
    order: string;
    excel: boolean;
    reverse: boolean;
    refresh: boolean;

    [key: string]: string | boolean | number | undefined;
}

interface SearchCasesPageBasicPVRCASELAWProps {
    intl: IntlShape;
    history: History;
}

const SearchCasesPageBasicPVRCASELAW = (props: SearchCasesPageBasicPVRCASELAWProps & RouteComponentProps) => {
    const firstLoad = useFirstRender();
    const userRoles = getUserRoles();

    const RESULT_FIELDS_DEFAULT =
        userRoles.indexOf('WLAW') !== -1
            ? [
                'caseId',
                'countryName',
                'languageName',
                'parties',
                'decidingBody',
                'decisionDate',
                'decisionNumber',
                'caseName',
                'isOnline',
                'keywords',
            ]
            : [
                'countryName',
                'languageName',
                'parties',
                'decidingBody',
                'decisionDate',
                'decisionNumber',
                'caseName',
                'keywords',
            ];
    const [loading, setLoading] = useState(false);
    const [initLoading, setInitLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [pvrcaselawCasesList, setPvrcaselawCasesList] = useState<CaseElement[]>([]);
    const [rowObjectForModal, setRowObjectForModal] = useState<CaseElement | null>(null);
    const [rowObjectForExtend, setRowObjectForExtend] = useState<CaseElement | null>(null);
    const [showExpandedRow, setShowExpandedRow] = useState<{[key: number]: boolean}>({});
    const [showRowNumber, setShowRowNumber] = useState(DEFAULT_PAGE_SIZE);
    const [criteria, setCriteria] = useState<SearchFilesBasicFiltersCriteriaData>(Object.assign({}, DEFAULT_CRITERIA));
    const [refresh, setRefresh] = useState(true);
    const [count, setCount] = useState(DEFAULT_COUNT);
    const [timestamp, setTimestamp] = useState(0);
    const [, setDefaultOrder] = useState('caseId');
    const [, setReverseOrder] = useState(false);
    const [, setCriteriaCount] = useState(0);

    const filesListByIdNames: {ID: string; NAME: string}[] | null = null;
    const filesNamesHash: {[key: string]: string[]} | null = null;
    const [caseDetails, setCaseDetails] = useState<CaseElement | null>(null);
    const [, setPageNumberForOpenSearch] = useState(0);

    const menuLinks = [
        {id: 'BASIC_SEARCH', value: 'Full Text Search', path: 'pvrcaselawSearchCasesBasicSearch'},
        {id: 'ADVANCED_SEARCH', value: 'Search by Field', path: 'pvrcaselawSearchCasesAdvancedSearch'},
    ];

    let history = useHistory();

    useEffect(() => {
        trackPageView({documentTitle: 'pvrcaselawSearchCasesBasicSearch'});
    }, []);

    function useFirstRender() {
        const firstRender = useRef(true);

        useEffect(() => {
            firstRender.current = false;
        }, []);

        return firstRender.current;
    }

    const loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const params = domainArray.pop();
            if (params) {
                params.split('&').forEach(param => {
                    const paramElements = param.split('=');
                    if (paramElements.length === 2) {
                        const key = paramElements[0];
                        criteria[key] = decodeURIComponent(paramElements[1]);
                    }
                });
                if (Object.keys(criteria).length > 0) {
                    setCriteria(Object.assign({}, criteria));
                    criteria.order && setDefaultOrder(criteria.order);
                    criteria.reverse && setReverseOrder(criteria.reverse);
                    setCriteriaCount(countCriteria(criteria));
                    search();
                }
            }
        }
    };

    const closeErrorModal = () => {
        setErrorMessage(null);
        setModalScreen(null);
    };

    useEffect(() => {
        if (firstLoad) {
            window.scrollTo(0, 0);
            loadUrlParams();
        }
    }, []);

    useEffect(() => {
        !firstLoad && refresh && search();
    }, [criteria.order, criteria.reverse, criteria.pageSize, criteria.pageNumber]);

    useEffect(() => {
        firstLoad && search();
    }, [criteria.order, criteria.reverse]);

    const countCriteria = (registersCriteria: SearchFilesBasicFiltersCriteriaData) => {
        let countNum = 0;
        Object.keys(registersCriteria).forEach(key => {
            if (
                registersCriteria[key] &&
                registersCriteria[key] !== DEFAULT_CRITERIA[key] &&
                registersCriteria[key] !== ''
            )
                countNum++;
        });
        return countNum;
    };

    const parseCriteria = (
        unParsedCriteria: SearchFilesBasicFiltersCriteriaData
    ): SearchFilesBasicFiltersCriteriaData => {
        const parsedCriteria: SearchFilesBasicFiltersCriteriaData = JSON.parse(JSON.stringify({}));
        Object.keys(unParsedCriteria).forEach(key => {
            parsedCriteria[key] = unParsedCriteria[key];
        });

        return parsedCriteria;
    };

    const buildParams = (parsedCriteria: SearchFilesBasicFiltersCriteriaData) => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(key => parsedCriteria[key] !== DEFAULT_CRITERIA[key] && key !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key]}`);
        const joinedParamsText = `?${paramArray.join('&')}`;
        props.history.replace(`/pvrcaselawSearchCasesBasicSearch${(paramArray.length > 0 && joinedParamsText) || ''}`);
    };

    const getCasesList = (
        parsedCriteria: SearchFilesBasicFiltersCriteriaData,
        shouldRefresh: boolean,
        urlLoad = false,
        withoutPagination = false
    ) => {
        const newParsedCriteria = Object.assign({}, parsedCriteria, withoutPagination ? {pageNumber: 1} : {});
        apiCasesListFetch(newParsedCriteria, {})
            .then((jsonResponse: CasesListFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.cases) {
                    const newCriteria = Object.assign({}, criteria, {pageNumber: 1});
                    if (!shouldRefresh && !urlLoad) {
                        setCriteria(Object.assign({}, newCriteria));
                    }
                    setPvrcaselawCasesList(jsonResponse.data.cases);
                    setTimestamp(Date.now());
                    if (jsonResponse.count && criteria.fileName === '') setCount(jsonResponse.count);
                }
            })
            .catch(error => {
                setErrorMessage(`Search Cases list fetch error: ${error.message}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => {
                setLoading(false);
                setInitLoading(false);
            });
    };

    const resetCriteria = () => {
        setLoading(true);
        setPvrcaselawCasesList([]);
        setCriteria(Object.assign({}, DEFAULT_CRITERIA));
        const parsedCriteria = parseCriteria(DEFAULT_CRITERIA);
        buildParams(parsedCriteria);
        getCasesList(parsedCriteria, true, false);
    };

    const search = () => {
        setLoading(true);
        if (criteria.fileName === '') {
            const parsedCriteria = parseCriteria(criteria);
            getCasesList(parsedCriteria, true);
        } else {
            const newPageNumberForOpenSearch = (criteria.pageNumber - 1) * criteria.pageSize;
            setPageNumberForOpenSearch(newPageNumberForOpenSearch);
            apiFilesSearch(criteria.fileName, criteria.fileNameFilter, newPageNumberForOpenSearch, criteria.pageSize)
                .then((jsonResponse: SearchFiles) => {
                    // Search in returned files with rest of criterias like search button clicked
                    if (jsonResponse && jsonResponse.searchResults && jsonResponse.searchResults.length > 0) {
                        const caseIds = jsonResponse.searchResults.map(res => res.caseId);
                        let criteriaAfterFileSearch = {} as SearchFilesBasicFiltersCriteriaData;
                        if (caseIds.length > 0) {
                            criteriaAfterFileSearch = Object.assign({}, criteria, {
                                caseIds: caseIds.join(),
                            });
                        } else {
                            criteriaAfterFileSearch = Object.assign({}, criteria);
                        }
                        setCount(jsonResponse.searchResults[0].total);
                        setCriteria(criteriaAfterFileSearch);
                        const parsedCriteria = parseCriteria(criteriaAfterFileSearch);
                        buildParams(parsedCriteria);
                        if (caseIds.length > 0) {
                            getCasesList(parsedCriteria, true, false, true);
                        } else {
                            setCriteria(Object.assign({}, criteria, {pageNumber: 1}));
                            setPvrcaselawCasesList([]);
                            setLoading(false);
                        }
                    } else {
                        setPageNumberForOpenSearch(0);
                        setCriteria(Object.assign({}, criteria, {pageNumber: 1}));
                        setPvrcaselawCasesList([]);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    setErrorMessage(`error search in files: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                    setLoading(false);
                });
        }
    };

    const updateFilesCriteriaValue = (
        // eslint-disable-next-line no-undef
        criteriaValue: Partial<SearchFilesBasicFiltersCriteriaData>,
        callback = () => {
        },
        shouldRefresh = true
    ) => {
        setRefresh(shouldRefresh);
        if (criteriaValue !== undefined) {
            setCriteria({...criteria, ...criteriaValue});
        }
        callback && callback();
    };

    const closeModal = () => setModalScreen(null);

    const deleteCase = () => {
        setModalScreen(null);
        setLoading(true);
        if (rowObjectForModal !== null) {
            apiCaseDelete(rowObjectForModal.caseId)
                .then(() => {
                    setModalScreen(null);
                    resetCriteria();
                })
                .catch(error => {
                    setLoading(false);
                    setErrorMessage(`error deleting case: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                });
        }
    };

    const getTopCaptionElement = () =>
        userRoles.indexOf('WLAW') !== -1 ? (
            <div className={styles.topCaptionContainer}>
                <FormFooterButton color={buttonColor.blue} clickAction={openCasePageForAddition} icon={faPlus}>
                    {`New Case`}
                    <AdminIcon />
                </FormFooterButton>
            </div>
        ) : null;

    const goToEditOrCreateCasePage = (rowId?: number) => {
        const caseId = rowId || 0;
        if (caseId > 0) {
            history.push(`/pvrcaselawCase?caseId=${rowId}`);
        } else {
            history.push(`/pvrcaselawCase`);
        }
    };

    const openCasePageForAddition = () => goToEditOrCreateCasePage();

    const handlerModify = (rowId: number, rowObject: CaseElement) => {
        if (rowId > 0) goToEditOrCreateCasePage(rowId);
    };

    const handlerDelete = (rowId: number, rowObject: CaseElement) => {
        if (rowId > 0) {
            setRowObjectForModal(rowObject);
            setLoading(true);
            apiViewCaseFetch(rowObject.caseId)
                .then((jsonResponse: CaseMetaDataFetchData) => {
                    if (
                        jsonResponse &&
                        jsonResponse.data &&
                        jsonResponse.data.case &&
                        jsonResponse.data.case.length > 0
                    ) {
                        setCaseDetails(jsonResponse.data.case[0]);
                        setModalScreen(modalScreenTypes.CONFIRM_DELETE);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    setErrorMessage(`View Case Fetch error: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        }
    };

    const handlerDetails = (rowId: number, rowObject: CaseElement) => {
        if (rowId > 0) {
            setRowObjectForModal(rowObject);
            setLoading(true);
            apiViewCaseFetch(rowObject.caseId)
                .then((jsonResponse: CaseMetaDataFetchData) => {
                    if (
                        jsonResponse &&
                        jsonResponse.data &&
                        jsonResponse.data.case &&
                        jsonResponse.data.case.length > 0
                    ) {
                        setCaseDetails(jsonResponse.data.case[0]);
                        setModalScreen(modalScreenTypes.READ_DETAILS_CASE);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    setErrorMessage(`View Case Fetch error: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        }
    };

    const downloadFile = (uuid: string) => {
        setLoading(true);
        apiDownloadFile(uuid)
            .then(response => {
                if (response && response.signedUrl) {
                    const {signedUrl} = response;
                    getPreSignedURLFetchRequest(signedUrl)
                        .then(response => response.blob())
                        .then(responseBlob => {
                            if (uuid.includes('-')) {
                                const fileNameArray = uuid.split('_');
                                fileNameArray.splice(0, 1);
                                const fileName = fileNameArray.join('_');
                                saveAs(responseBlob, fileName);
                            } else {
                                saveAs(responseBlob, uuid);
                            }
                        });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading file: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const actions: any[] = [];
    if (userRoles.indexOf('WLAW') !== -1) {
        actions.push({
            title: `Modify`,
            icon: () => {
                return (
                    <a data-content={`Modify`}>
                        <FontAwesomeIcon icon={faEdit as IconDefinition} />
                    </a>
                );
            },
            handler: handlerModify,
        });
        actions.push({
            title: `Delete`,
            icon: () => {
                return (
                    <a data-content={`Delete`}>
                        <FontAwesomeIcon icon={faTrashAlt as IconDefinition} />
                    </a>
                );
            },
            handler: handlerDelete,
        });
    }
    actions.push({
        title: `Read Details`,
        icon: () => {
            return (
                <a data-content={`Read Details`}>
                    <FontAwesomeIcon icon={faIdCard as IconDefinition} />
                </a>
            );
        },
        handler: handlerDetails,
    });

    const onSetTextOpen = (value: boolean, caseId: number) => {
        pvrcaselawCasesList
            .filter(e => e.caseId === caseId)
            .map(item => (item.isKeywordsTextOpen = item.caseId === caseId ? value : false));
    };

    const getIsOnline = (isOnline: string | undefined, rowObject: CaseElement | null) => {
        if (rowObject && isOnline) {
            if (isOnline === '1') {
                return 'online';
            } else {
                return 'draft';
            }
        } else {
            return '';
        }
    };

    const getSubstringOfKeywords = (keywords: string | undefined, rowObject: CaseElement | null) => {
        if (rowObject && keywords) {
            if (keywords.length > DEFAULT_KEYWORDS_SIZE) {
                if (rowObject?.isKeywordsTextOpen) {
                    return <div>{keywords}</div>;
                } else {
                    return (
                        <div>
                            {keywords.substring(0, DEFAULT_KEYWORDS_SIZE)} {' ...'}
                        </div>
                    );
                }
            } else {
                return keywords;
            }
        } else {
            return '';
        }
    };

    const getDataSheetFormSectionArea = (rowElement: CaseElement) => (
        <tr>
            <td>
                <b>{`Abstract: `}</b>
            </td>
            <td colSpan={13}>{rowElement?.abstractInput}</td>
        </tr>
    );

    const getExcelTranslations = () => {
        const excelTranslations: {[key: string]: string} = {};
        const searchCasesExcelKeys = Object.keys(en).filter(key => ~key.indexOf('excel.pvrcaselawCasesList.'));
        const searchCasesHeaderKeys = searchCasesExcelKeys.map(key => key.replace('excel.pvrcaselawCasesList.', ''));
        searchCasesHeaderKeys.forEach(headerKey => {
            excelTranslations[headerKey] = props.intl.formatMessage({
                id: `excel.pvrcaselawCasesList.${headerKey}`,
            });
        });
        return excelTranslations;
    };

    const printExcel = () => {
        setLoading(true);
        const excelTranslations = getExcelTranslations();
        const parsedCriteria = Object.assign({}, {excel: true});
        apiCasesListFetch(parsedCriteria, excelTranslations)
            .then((jsonResponse: CasesListFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                    getExcelSignedURLFetchRequest(jsonResponse.data.token).then(response => {
                        if (response && response.signedUrl) {
                            const {signedUrl} = response;
                            getPreSignedURLFetchRequest(signedUrl)
                                .then(response => response.blob())
                                .then(responseBlob => {
                                    saveAs(responseBlob, `Export_Caselaw_${moment().format('DD/MM/YYYY')}.xlsx`);
                                });
                        }
                    });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getRowClass = (rowObject: CaseElement) => {
        const clickedRowIndex =
            rowObjectForExtend !== null &&
            pvrcaselawCasesList.findIndex(
                el => el.caseId === rowObjectForExtend.caseId && el.caseId === rowObject.caseId
            );
        const clickedRowIndexClass =
            rowObjectForExtend !== null && Number(clickedRowIndex) >= 0 ? Number(clickedRowIndex) + 1 : clickedRowIndex;
        const className =
            clickedRowIndex !== -1 && showExpandedRow[pvrcaselawCasesList.indexOf(rowObject)] === true
                ? clickedRowIndexClass + '-clickedRow'
                : '0-clickedRow';
        return styles[className];
    };

    const rowClick = (_caseId: number, rowObject: CaseElement) => {
        const tableRowNumber = document.querySelectorAll(
            "div[class^='SearchCasesPageBasicPVRCASELAW_searchCasesPage'] table tr[class^='CustomTable_rowData']"
        ).length;
        setShowRowNumber(tableRowNumber > DEFAULT_PAGE_SIZE ? tableRowNumber : DEFAULT_PAGE_SIZE);
        setRowObjectForExtend(rowObject);
        const dataFormSection: {[key: number]: boolean} = {};
        dataFormSection[pvrcaselawCasesList.indexOf(rowObject) % showRowNumber] =
            !showExpandedRow[pvrcaselawCasesList.indexOf(rowObject) % showRowNumber];
        setShowExpandedRow(dataFormSection);
        if (rowObject?.isKeywordsTextOpen) {
            onSetTextOpen(false, rowObject.caseId);
        } else {
            onSetTextOpen(true, rowObject.caseId);
        }
    };

    const updateCriteriaValue = (
        // eslint-disable-next-line no-undef
        criteriaValue: Partial<SearchFilesBasicFiltersCriteriaData>,
        callback = () => {
        },
        shouldRefresh = true
    ) => {
        setRefresh(shouldRefresh);
        if (criteriaValue !== undefined) setCriteria({...criteria, ...criteriaValue});

        setCriteriaCount(countCriteria(criteria));
        callback && callback();
    };

    const getTableArea = () =>
        pvrcaselawCasesList.length === 0 ? (
            <div style={{paddingTop: 20, textAlign: 'left'}}>
                <b style={{color: 'red', textAlign: 'left'}}>
                    <span className="ng-scope">{`No Search Cases available`}</span>
                </b>
            </div>
        ) : (
            <div className={styles.casesList}>
                <CustomTable
                    version={2}
                    {...props}
                    useExpandedRow={true}
                    forehandColumn={true}
                    expandedRow={(rowObject: CaseElement) =>
                        showExpandedRow[pvrcaselawCasesList.indexOf(rowObject) % showRowNumber] === true &&
                        getDataSheetFormSectionArea(rowObject)
                    }
                    showExpandedRow={showExpandedRow}
                    tableName={'pvrcaselawCasesList'}
                    tableType={'OBJECT'}
                    tableSource={pvrcaselawCasesList}
                    id={'caseId'}
                    resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                    intl={props.intl}
                    defaultOrder={criteria.order}
                    reverseOrder={criteria.reverse}
                    timestamp={timestamp}
                    count={count}
                    formatFunctions={{
                        decisionDate: (decisionDate: string, rowObject: CaseElement) =>
                            decisionDate && formatDate(decisionDate),
                        isOnline: (isOnline: string, rowObject: CaseElement) => getIsOnline(isOnline, rowObject),
                        keywords: (keywords: string, rowObject: CaseElement) =>
                            getSubstringOfKeywords(keywords, rowObject),
                    }}
                    actions={actions}
                    actionName={`Action`}
                    showAdminIcon={userRoles.indexOf('WLAW') !== -1}
                    rowClick={rowClick}
                    rowClass={getRowClass}
                    pageSize={criteria.pageSize}
                    pageNumber={criteria.pageNumber}
                    pagination={true}
                    updateCriteriaValue={(criteriaValue: SearchFilesBasicFiltersCriteriaData) =>
                        updateCriteriaValue(criteriaValue)
                    }
                    printExcel={(_excelTranslations: {[key: string]: string}) => printExcel()}
                />
            </div>
        );

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {modalScreen === modalScreenTypes.CONFIRM_DELETE && rowObjectForModal !== null && caseDetails !== null ? (
                <ModalCaseDelete
                    intl={props.intl}
                    caseElement={caseDetails}
                    delete={deleteCase}
                    downloadFile={downloadFile}
                    close={closeModal}
                />
            ) : null}
            {modalScreen === modalScreenTypes.READ_DETAILS_CASE &&
            rowObjectForModal !== null &&
            caseDetails !== null ? (
                <ModalCaseDetails
                    intl={props.intl}
                    caseElement={caseDetails}
                    downloadFile={downloadFile}
                    close={closeModal}
                />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`PVR Case Law`} />
            <NavigationPVRCaseLaw />
            <MainWrapper>
                <div
                    style={{
                        width: 'calc(100vw)',
                        marginBottom: 20,
                        minHeight: 600,
                        textAlign: 'center',
                        maxWidth: '100%',
                    }}
                >
                    <FormWrapper paddingFormContent={'sm'}>
                        <SubMenuTabs tabId={'BASIC_SEARCH'} loading={loading} menuLinks={menuLinks} />
                        <SearchCasesBasicFiltersCriteria
                            criteria={criteria}
                            filesListByIdNames={filesListByIdNames}
                            filesNamesHash={filesNamesHash}
                            updateFilesCriteriaValue={updateFilesCriteriaValue}
                            resetCriteria={resetCriteria}
                            search={search}
                        />
                    </FormWrapper>
                    {!initLoading ? (
                        <>
                            {getTopCaptionElement()}
                            <div style={{clear: 'both'}} />
                            <div className={styles.searchCasesPage}>{getTableArea()}</div>
                        </>
                    ) : null}
                </div>
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(SearchCasesPageBasicPVRCASELAW));
