import React from 'react';
import {injectIntl} from 'react-intl';
import CustomTable from '../../components/CustomTable';
import {apiCommunicationCentreModalInternalMessageHistory} from './CCModalInternalHistoryService';
import {LoadingBar} from '../../componentsLayout';
import {ModalCustomVersion2} from '../../commonModals';
import {ICommunicationMessageInternal} from '../../types';
import {Button} from '../../componentsFormV2';

interface IProps {
    close: () => any;
    correlationId: number;
    formatTableMessages: (messages: ICommunicationMessageInternal[]) => any;
    getTableFormatFunctions: () => {[key: string]: any};
    intl: any;
}

interface IState {
    loading: boolean;
    messages: ICommunicationMessageInternal[];
    timestamp: number;
}

class CCModalInternalHistory extends React.Component<IProps, IState> {
    RESULT_FIELDS_DEFAULT = ['from', 'messageSubject', 'applicationNumbers', 'receivedDate'];

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            messages: [],
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        this.setState(
            {
                loading: true,
            },
            () => {
                apiCommunicationCentreModalInternalMessageHistory(this.props.correlationId).then(
                    (JSONResponse: {messages: ICommunicationMessageInternal[]}) => {
                        const {messages} = JSONResponse || {};
                        this.setState({
                            loading: false,
                            messages,
                        });
                    }
                );
            }
        );
    }

    render() {
        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={`History`}
                body={
                    <div>
                        {this.state.loading ? (
                            <LoadingBar />
                        ) : (
                            <CustomTable
                                pagination={true}
                                notSortable={this.RESULT_FIELDS_DEFAULT}
                                mode={'mailbox'}
                                version={1}
                                timestamp={this.state.timestamp}
                                tableName={'communicationCentreHistory'}
                                tableType={'OBJECT'}
                                tableSource={this.props.formatTableMessages(this.state.messages)}
                                dataFilter={null}
                                id={'communicationId'}
                                setLastCursor={null}
                                resultFieldsDefault={this.RESULT_FIELDS_DEFAULT}
                                forceUpdateResultFieldsDefault={true}
                                intl={this.props.intl}
                                filterFunctions={null}
                                count={(this.state.messages || []).length}
                                hideExcelButton={true}
                                noChangePageSize={true}
                                formatFunctions={this.props.getTableFormatFunctions()}
                            />
                        )}
                    </div>
                }
                footer={<Button variation={'danger'} clickAction={this.props.close} icon={'close'}>{`Close`}</Button>}
            />
        );
    }
}

export default injectIntl(CCModalInternalHistory);
