import {API_SERVERLESS, getFetch, postFetch} from '../../../../commonApi';
import {DraftProposalAnswerForSave} from './Components/ModalAnswerToCPVOProposalNumber/ModalAnswerToCPVOProposalNumberService';

export interface ModalScreenType {
    ERROR: string;
    INFO: string;
    DOCUMENTS_RELATED_TO_PROPOSAL: string;
    LIST_OF_REQUESTED_ENTRUSTMENTS: string;
    SUMMARY_OF_ANSWERS_FOR_INVENTORY_NUMBER: string;
    ANSWERS_TO_CPVO_PROPOSAL: string;
    LUMPSUMOK_TO_CPVO_PROPOSAL: string;
}

interface InventoryNumber {
    inventoryNumber: string;
    startProposalDate: string;
    endProposalDate: string;
    deadlineNegotiationsDate: string;
}

export interface InventoryDetails {
    inventoryNumber: string;
    startProposalDate: string;
    deadlineNegotiationsDate: string;
    endProposalDate: string;
    eoProposalNumber: number;
    cropSector: string;
    speciesName: string;
    htmlSpeciesName: string;
    speciesId: string;
    proposalRemark: string;
    cost: string;
    registers_count: number;
    registers_list: string;
    originCountryId: string;
    quantityQuality: string | null;
    quantityQualityIds: string | null;
    cropSectorGroup?: string | null;
    status?: boolean | null;
}

export interface InventoryNumberListFetchData {
    data: {
        inventoryNumberList: Array<InventoryNumber>;
    };
}

export interface InventoryDetailsListFetchData {
    data: {
        inventoryDetails: Array<InventoryDetails>;
        token: string;
    };
    count: number;
}

export interface ProposalDocument {
    csDocsKey: number;
    registerId: string;
    filePath: string;
    fileName: string;
    directoryName: string;
    documentTypeName: string;
    docmanTitle: string;
    countryApplication: string;
}

export interface ProposalDocumentsListFetchData {
    data: {
        proposalWindow: Array<ProposalDocument>;
    };
}

export interface TloDocument {
    dkey: number;
    documentType: number;
    documentTypeLabel: string;
    title: string;
    arrivalDate: string;
    chronoNumber: number;
    csDocsKey: number;
    nbDocs: number;
    docPath: string;
    docName: string;
    signedUrl?: string;
    isZip?: boolean;
}

export interface ProposalAnswer {
    inventoryNumber: string;
    eoProposalNumber: number;
    speciesName: string;
    eoInterested: number;
    remark: string;
    closingDate: string;
    quantityQuality: string | null;
    quantityQualityIds: string | null;
    submissionStart: string;
    submissionEnd: string;
    examinationByYear: number;
    interimReportDate: string;
    foreseenFinalReportDate: string;
    nationalPropotol: string;
    remarkExamination: string;
    speciesId: string;
    addressTestingId: number;
    entrustmentType: string;
    startEntrustmentDate: string;
    cpvoProposalId: number;
    cultivationType: string;
    submissionAddressId: number;
    lumpSum: number;

    [key: string]: number | string | null;
}

export interface ProposalAnswersListFetchData {
    data: {
        proposalAnswers: Array<ProposalAnswer>;
    };
}

export interface RequestedEntrustment {
    eoProposalNumber: number;
    formattedSpeciesName: string;
    eoName: string;
    eoInterested: number;
    humanModifDate: string;
    oktoDisplayIfWithdrawn: string;
}

export interface RequestedEntrustmentsFetchData {
    data: {
        finalPreview: Array<RequestedEntrustment>;
    };
}

export interface SummaryOfAnswers {
    eoProposalNumber: number;
    speciesName: string;
    cost: string;
    name: string;
    remark: string | null;
    isOwnerOfAnswer: string;
    negotiation: string | null;
    startEntrustmentDate: string;
    lumpSum: number | string;
    lumpSumOk: boolean;
    examOfficeId: string;

    [key: string]: number | string | boolean | null;
}

export interface SummaryOfAnswersFetchData {
    data: {
        summaryAnswers: Array<SummaryOfAnswers>;
        token: string;
    };
}

export interface SummaryOfAnswersForSave {
    [key: string]: {negotiation: string; withdraw: boolean; lumpSumOk: boolean; examOfficeId?: string};
}

export interface SummaryOfAnswersUpdateNegotiationData {
    proposalNumber: string;
    negotiation: string;
}

export interface SummaryOfAnswersWithdrawProposalData {
    proposalNumber: Array<number>;
}

export interface SummaryOfAnswersUpdateLumpSumOkData {
    proposalNumber: string;
    lumpSumOk: boolean;
    examOfficeId?: string;
}

export function apiInventoryNumbersListFetch(): Promise<InventoryNumberListFetchData> {
    return getFetch(`${API_SERVERLESS}/tlo/applications/documents/inventorylist`, true);
}

export function apiInventoryDetailsListFetch(inventoryNumber: string): Promise<InventoryDetailsListFetchData> {
    return getFetch(
        `${API_SERVERLESS}/tlo/applications/documents/inventorydetails?inventoryNumber=${inventoryNumber}`,
        true
    );
}

export function apiRequestedEntrustmentsFetch(inventoryNumber: string): Promise<RequestedEntrustmentsFetchData> {
    return getFetch(
        `${API_SERVERLESS}/tlo/applications/documents/finalpreview?inventoryNumber=${inventoryNumber}`,
        true
    );
}

export function apiSummaryOfAnswersFetch(inventoryNumber: string): Promise<SummaryOfAnswersFetchData> {
    return getFetch(
        `${API_SERVERLESS}/tlo/applications/documents/summaryanswers?inventoryNumber=${inventoryNumber}`,
        true
    );
}

export function apiSummaryOfAnswersDownloadExcel(
    inventoryNumber: string,
    excelTranslations: {[key: string]: string} = {}
): Promise<SummaryOfAnswersFetchData> {
    return postFetch(
        `${API_SERVERLESS}/tlo/applications/documents/summaryanswersxls?inventoryNumber=${inventoryNumber}&excel=true`,
        true,
        excelTranslations
    );
}

export function apiUpdateNegotiationOfSummaryAnswers(jsonData: SummaryOfAnswersUpdateNegotiationData) {
    return postFetch(`${API_SERVERLESS}/tlo/applications/documents/summaryanswers`, true, jsonData).then(
        jsonResponse => {
            if (jsonResponse) {
                return jsonResponse;
            }
        }
    );
}

export function apiWithdrawProposalsOfSummaryAnswers(jsonData: SummaryOfAnswersWithdrawProposalData) {
    return postFetch(`${API_SERVERLESS}/tlo/applications/documents/summaryanswers/withdraw`, true, jsonData).then(
        jsonResponse => {
            if (jsonResponse) {
                return jsonResponse;
            }
        }
    );
}

export function apiUpdateLumpSumOkOfSummaryAnswers(jsonData: SummaryOfAnswersUpdateLumpSumOkData) {
    return postFetch(`${API_SERVERLESS}/tlotree/applications/documents/summaryanswers/lumpsumok`, true, jsonData).then(
        jsonResponse => {
            if (jsonResponse) {
                return jsonResponse;
            }
        }
    );
}

export function apiDocumentsProposalWindowFetch(
    inventoryNumber: string,
    proposalNumber: number
): Promise<ProposalDocumentsListFetchData> {
    return getFetch(
        `${API_SERVERLESS}/tlo/applications/documents/proposalwindow?inventoryNumber=${inventoryNumber}&proposalNumber=${proposalNumber}`,
        true
    );
}

export function apiProposalAnswersWindowFetch(
    inventoryNumber: string,
    proposalNumber: number
): Promise<ProposalAnswersListFetchData> {
    return getFetch(
        `${API_SERVERLESS}/tlo/applications/documents/proposalanswers?inventoryNumber=${inventoryNumber}&proposalNumber=${proposalNumber}`,
        true
    );
}

export function apiProposalAnswerSaveAsDraft(jsonData: DraftProposalAnswerForSave) {
    return postFetch(`${API_SERVERLESS}/tlo/applications/documents/proposalanswers`, true, jsonData).then(
        jsonResponse => {
            if (jsonResponse) {
                return jsonResponse;
            }
        }
    );
}

export function apiInventoryDetailsLDownloadExcel(
    inventoryNumber: string,
    excelTranslations: {[key: string]: string} = {}
): Promise<InventoryDetailsListFetchData> {
    return postFetch(
        `${API_SERVERLESS}/tlo/applications/documents/inventorydetails?inventoryNumber=${inventoryNumber}&excel=true`,
        true,
        excelTranslations
    );
}

export function apiInventoryDetailsLDownloadPDF(inventoryNumber: string): Promise<InventoryDetailsListFetchData> {
    return getFetch(
        `${API_SERVERLESS}/tlo/applications/documents/inventorypdf?inventoryNumber=${inventoryNumber}`,
        true
    );
}

export function apiGetImageFiles(eoIdList: Array<number>): Promise<{
    data: {
        urls: Array<{
            [key: string]: {
                fileUrl: string;
                thumbnailUrl: string;
            };
        }>;
    };
}> {
    return getFetch(`${API_SERVERLESS}/tlo/applications/documents/images?eoIds=${eoIdList}`, true);
}

export function apiGetClosedProcedureDocumentFile(inventoryNumber: string): Promise<{
    data: {
        url: string;
    };
}> {
    return getFetch(
        `${API_SERVERLESS}/tlo/applications/documents/closedProcedures/file?inventoryNumber=${inventoryNumber}`,
        true
    );
}
