import React from 'react';
import {injectIntl} from 'react-intl';
import {apiDocumentUpload, apiPictures} from './BackOfficePicturesService';
import CustomTable from '~components/CustomTable';
import {withRouter} from 'react-router-dom';
import DEFAULT_RESULT_FIELDS from './data/DEFAULT_RESULT_FIELDS.json';
import RESULT_FIELDS_ALL from './data/RESULT_FIELDS_ALL.json';
import {HeaderLoading} from '../../componentsLayout';
import TextInput from '~components/TextInput';
import FormFooterLinkButton from '~componentsForm/FormFooterLinkButton';
import {faFilter, faPlus} from '@fortawesome/free-solid-svg-icons';
import styles from './BackOfficePictures.module.scss';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {sanitize, trackPageView} from '../../utils';
import {MAX_UPLOAD_FILE_SIZE} from '../../utils/constants';
import Error from '~components/Error';
import {ModalErrorVersion2} from '../../commonModals';
import {getEnvironment} from '~utils/index';

const DEFAULT_CRITERIA = {
    search: '',
};

const DEFAULT_COUNT = 0;

interface IProps {
    intl: any;
    history: any;
}

interface IPicturesItem {
    fileName: string;
    filePath: string;
    size: number;
    type: string;
}

interface IState {
    inputValue: string;
    loading: number;
    pictures: IPicturesItem[];
    timestamp: number;
    count: number;
    criteria: any;
    criteriaCount: number;
    informationOpened: boolean;
    screenLoaded: boolean;
    error: string | null;
}

class BackOfficePictures extends React.Component<IProps, IState> {
    initialCriteria: any;
    lastSearchCriteria: any;

    constructor(props: IProps) {
        super(props);
        this.state = {
            inputValue: '',
            loading: 0,
            pictures: [],
            timestamp: Date.now(),
            count: DEFAULT_COUNT,
            criteria: DEFAULT_CRITERIA,
            criteriaCount: 0,
            informationOpened: true,
            screenLoaded: false,
            error: null,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'backOfficePictures'});
        this.loadJSONs();
    }

    loadJSONs = () => {
        this.search();
    };

    search = () => {
        this.setState(
            prev => ({
                loading: prev.loading + 1,
            }),
            () => {
                // const parsedCriteria = this.parseCriteria(this.state.criteria);
                apiPictures(this.state.criteria, DEFAULT_CRITERIA)
                    .then((jsonResponse: any) => {
                        if (jsonResponse && jsonResponse.files) {
                            this.setState({
                                pictures: jsonResponse.files.filter((i: any) =>
                                    RegExp('jpg|png|jpeg', 'ig').test(i.type)
                                ),
                                count: jsonResponse.files.length,
                                screenLoaded: true,
                                timestamp: Date.now(),
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`Register search list error: ${error.message}`]);
                    })
                    .then(() => {
                        this.setState(prev => ({loading: prev.loading - 1}));
                    });
            }
        );
    };

    toggleInformation = () => this.setState(prev => ({informationOpened: !prev.informationOpened}));

    resetCriteria = () =>
        this.setState(
            {
                pictures: [],
                criteria: DEFAULT_CRITERIA,
                criteriaCount: 0,
                inputValue: '',
            },
            () => this.search()
        );

    updateCriteriaValue = (criteriaValue: any, callback?: any) => {
        this.setState(prev => {
            const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
            // const criteriaCount = this.countCriteria(criteria);
            return {
                criteria,
                // criteriaCount
            };
        }, callback);
    };

    checkFile = (file: File, event: any) => {
        LOG([`file.type:${file.type}`]);
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = (fileType || '').toLowerCase();
        const filename = sanitize(file.name);

        this.setState({error: null}, () => {
            if (file.size > MAX_UPLOAD_FILE_SIZE) {
                return this.setState({
                    error: this.props.intl.formatMessage({
                        id: 'general.theMaximumDocumentSizeForUploadsIs10MBPleaseSelectAProperFile',
                    }),
                });
            }
            if (fileTypeLowercase === 'jpeg' || fileTypeLowercase === 'png' || fileTypeLowercase === 'jpg') {
                this.setState(
                    prev => ({loading: prev.loading + 1}),
                    () => {
                        const height = 91;
                        const wide = 122;
                        resizeImageBlob(file, wide, height).then(thumbFile => {
                            const uploadPicture = apiDocumentUpload({
                                document: {file, filename: `html/${filename}`},
                            });
                            const uploadThumb = apiDocumentUpload({
                                document: {file, filename: `thumbs/${filename}`},
                            });

                            Promise.all([uploadPicture, uploadThumb]).finally(() =>
                                this.setState(
                                    prev => ({loading: prev.loading - 1}),
                                    () => this.search()
                                )
                            );
                        });
                    }
                );
            } else {
                this.setState({error: 'type not valid'}, () => (event.target.value = null));
            }
        });
    };

    closeErrorModal = () => this.setState({error: null});

    onInputFilterChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({inputValue: event.target.value});

    onFilter = () =>
        this.setState(
            prev => ({criteria: {search: prev.inputValue}}),
            () => this.search()
        );

    onPictureRowClick = (filePath: string) => {
        const filePathWithoutBeginning = filePath.replace('static/images/html/', '');
        window.parent.postMessage(
            {sender: 'responsivefilemanager', url: filePathWithoutBeginning, field_id: null},
            window.location.origin
        );
    };

    onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        file && this.checkFile(file, event);
    };

    render() {
        let subdomain = getEnvironment() === 'LOCALHOST' ? 'dev' : getEnvironment().toLowerCase();

        return (
            <React.Fragment>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <div style={{clear: 'both'}} />
                {this.state.error && (
                    <ModalErrorVersion2 title={'Error'} message={this.state.error} close={this.closeErrorModal} />
                )}
                <div style={{width: '100%', marginBottom: 100, minHeight: 600, textAlign: 'center'}}>
                    <div className={styles.buttonCreateContainer}>
                        <input
                            type="file"
                            name="files[]"
                            id={`upload-filemanager`}
                            style={{opacity: 0, width: 0}}
                            onChange={this.onFileUpload}
                            accept="image/jpg, image/jpeg, image/png, application/pdf"
                        />
                        <label htmlFor={`upload-filemanager`}>
                            <FormFooterButton
                                color={buttonColor.green}
                                clickAction={this.onFileUpload}
                                icon={faPlus}
                            >{`Upload a picture`}</FormFooterButton>
                        </label>
                    </div>
                    <div className={styles.filterContainer}>
                        <TextInput
                            value={this.state.inputValue}
                            onChange={this.onInputFilterChange}
                            placeholder="Filter on filename search"
                            buttonIcon={faFilter}
                            buttonAction={this.onFilter}
                            onEnter={this.onFilter}
                        />
                        {this.state.criteria.search !== '' && (
                            <div style={{width: 80, float: 'left'}}>
                                <FormFooterLinkButton clickAction={this.resetCriteria}>{`Clear`}</FormFooterLinkButton>
                            </div>
                        )}
                        <div style={{clear: 'both'}}>{}</div>
                    </div>
                    <br />
                    {this.state.screenLoaded ? (
                        <div
                            className="form-group"
                            style={{
                                textAlign: 'left',
                                paddingLeft: 15,
                                paddingRight: 15,
                                overflowX: 'visible',
                                marginTop: 20,
                            }}
                        >
                            <CustomTable
                                {...this.props}
                                unlimited={true}
                                notSortable={RESULT_FIELDS_ALL}
                                tableName={'BackOfficePictures'}
                                tableType={'OBJECT'}
                                tableSource={this.state.pictures}
                                timestamp={this.state.timestamp}
                                dataFilter={null}
                                id={'filePath'}
                                setLastCursor={null}
                                resultFieldsAll={RESULT_FIELDS_ALL}
                                resultFieldsDefault={DEFAULT_RESULT_FIELDS}
                                intl={this.props.intl}
                                defaultOrder={'registerId'}
                                reverseOrder={false}
                                filterFunctions={null}
                                count={this.state.count}
                                formatFunctions={{
                                    preview: (preview: any, {filePath}: {filePath: string}): any => (
                                        <div style={{width: 122, height: 91, wordWrap: 'break-word'}}>
                                            <img
                                                key={filePath}
                                                src={
                                                    filePath &&
                                                    filePath.replace(
                                                        'static/images/html',
                                                        `https://${subdomain}.plantvarieties.eu/static/images/thumbs`
                                                    )
                                                }
                                                alt={filePath}
                                                loading="lazy"
                                                className="filemanager"
                                                style={{width: 122, height: 91}}
                                            />
                                        </div>
                                    ),
                                }}
                                rowClick={this.onPictureRowClick}
                            />
                        </div>
                    ) : null}
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(injectIntl(BackOfficePictures));

function resizeImageBlob(originalBlob: Blob, newWidth: number, newHeight: number) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function () {
            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;

            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
            }

            canvas.toBlob(
                function (newBlob) {
                    resolve(newBlob);
                },
                originalBlob.type,
                1
            );
        };

        img.onerror = function () {
            reject(new Error('Image loading failed.'));
        };

        img.src = URL.createObjectURL(originalBlob);
    });
}
