import React from 'react';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {
    Error,
    Footer,
    FormFooter,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationVarietyFinder from '../../shared/NavigationVarietyFinder';
import TextAreaInput from '~components/TextAreaInput';
import {Button} from '../../componentsFormV2';
import graphQLClientInstance from '../../utils/axiosGraphQLClient';
import {ModalAlertVersion2, ModalErrorVersion2} from '../../commonModals';
import {trackPageView} from '../../utils';

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    errorForm: string;
    isModalErrorFormSubmitOpen: boolean;
    isModalAlertFormSubmitSuccessOpen: boolean;
    loading: number;
    textAreaReasonsValue: string;
}

class VFRequestAPIAccess extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            errorForm: '',
            isModalErrorFormSubmitOpen: false,
            isModalAlertFormSubmitSuccessOpen: false,
            loading: 0,
            textAreaReasonsValue: '',
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'requestAPIAccess'});
    }

    onTextAreaReasonsChange = ({
        target: {value: textAreaReasonsValue},
    }: React.ChangeEvent<HTMLTextAreaElement>): void => {
        if (textAreaReasonsValue.length <= 2000) {
            this.setState({textAreaReasonsValue});
        }
    };

    onButtonClearClick = () => this.setState({textAreaReasonsValue: '', errorForm: ''});

    onButtonSubmitClick = () => {
        if (!this.state.textAreaReasonsValue) {
            return this.setState({errorForm: 'Please fill in the reasons for requesting access to developer program.'});
        }
        this.setState(
            prev => ({loading: prev.loading + 1, errorForm: ''}),
            () => {
                graphQLClientInstance
                    .post('/extended/api/v1/request-api-access', {reason: this.state.textAreaReasonsValue})
                    .then(JSONResponse => {
                        if (JSONResponse) {
                            this.setState({
                                textAreaReasonsValue: '',
                                isModalAlertFormSubmitSuccessOpen: true,
                            });
                            window.scroll({top: 0, behavior: 'smooth'});
                        }
                    })
                    .catch(() => this.setState({isModalErrorFormSubmitOpen: true}))
                    .finally(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    onModalAlertErrorClose = () => this.setState({isModalErrorFormSubmitOpen: false});

    onModalAlertFormSubmitSuccessClose = () => this.setState({isModalAlertFormSubmitSuccessOpen: false});

    onHeaderSearchLinkClick = (searchListItem: any) =>
        this.props.history.push({
            pathname: '/varieties',
            state: {searchListItem},
        });

    render() {
        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                {this.state.isModalErrorFormSubmitOpen ? (
                    <ModalErrorVersion2
                        message={'There was an error while sending the request. Please try again later.'}
                        close={this.onModalAlertErrorClose}
                    />
                ) : null}
                {this.state.isModalAlertFormSubmitSuccessOpen ? (
                    <ModalAlertVersion2
                        message={
                            'The form regarding the reasons for requesting access to developer program has been successfully submitted.'
                        }
                        close={this.onModalAlertFormSubmitSuccessClose}
                    />
                ) : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Variety Finder`} hostApplication={'Variety Finder'} />
                <NavigationVarietyFinder onSearchLinkClick={this.onHeaderSearchLinkClick} />
                <MainWrapper bgGray={true}>
                    <FormWrapper paddingFormContent={'sm'}>
                        <TextAreaInput
                            double={true}
                            onChange={this.onTextAreaReasonsChange}
                            label={"Reasons you want access to Developers' Program"}
                            mandatory={true}
                            rows={3}
                            value={this.state.textAreaReasonsValue}
                            counter={this.state.textAreaReasonsValue.length}
                            maxCounter={2000}
                            width={890}
                            popOverTextFirstRow={`The CPVO Application Programming Interface (API) allows a developer to access to all functionalities offered by the website to the Variety Finder database.`}
                        />
                        <div style={{clear: 'both'}} />
                        <FormFooter mt={0}>
                            {this.state.errorForm ? <Error>{this.state.errorForm}</Error> : null}
                            <Button variation={'secondary'} clickAction={this.onButtonClearClick}>{`Clear`}</Button>
                            <Button icon={'arrowRight'} clickAction={this.onButtonSubmitClick}>{`Submit`}</Button>
                        </FormFooter>
                    </FormWrapper>
                </MainWrapper>
                <Footer hostApplication={'Variety Finder'} />
            </>
        );
    }
}

export default withRouter(injectIntl(VFRequestAPIAccess));
