import React from 'react';
import {injectIntl} from 'react-intl';
import cx from 'classnames';
import AuthenticationLogin from './data/AuthenticationLogin.json';
import AuthenticationRegistration from './data/AuthenticationRegistration.json';
import styles from './Book.module.scss';
import {trackPageView} from '../../utils';

const screens = [AuthenticationLogin, AuthenticationRegistration];

class Book extends React.Component {
    iframe = null;

    constructor(props) {
        super(props);
        this.state = {target: '/login', currentKey: 'Login-Default'};
    }

    componentDidMount() {
        trackPageView({documentTitle: 'book'});
    }

    setTarget = (name, target) =>
        target !== this.state.target &&
        this.setState(
            {target, currentKey: `${name}-Default`},
            () =>
                this.iframe.contentWindow.setStateFunction &&
                this.iframe.contentWindow.setStateFunction(Object.assign({}, this.iframe.contentWindow.defaultState))
        );

    setScenarioState = (targetName, target, key, state, modalState) => {
        this.setTarget(targetName, target);
        this.setState({currentKey: key}, () => {
            this.iframe.contentWindow.setStateFunction &&
                this.iframe.contentWindow.setStateFunction(
                    Object.assign({}, this.iframe.contentWindow.defaultState, state)
                );
            if (this.iframe.contentWindow.setModalStateFunction) {
                this.iframe.contentWindow.setModalStateFunction(
                    Object.assign({}, this.iframe.contentWindow.defaultModalState, modalState)
                );
            }
        });
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.cellMenu}>
                    {screens.map(screen => (
                        <div>
                            <div
                                className={styles.screenName}
                                onClick={() => this.setTarget(screen.name, screen.target)}
                            >
                                {screen.name}
                            </div>
                            {screen.scenarios && (
                                <ul className={styles.scenarios}>
                                    {screen.scenarios.map(scenario => (
                                        <li
                                            key={`${screen.name}-${scenario.label}`}
                                            onClick={() =>
                                                this.setScenarioState(
                                                    screen.name,
                                                    screen.target,
                                                    `${screen.name}-${scenario.label}`,
                                                    scenario.state,
                                                    scenario.modalState
                                                )
                                            }
                                            className={cx(
                                                styles.scenario,
                                                this.state.currentKey === `${screen.name}-${scenario.label}` &&
                                                    styles.scenarioActive
                                            )}
                                        >
                                            {scenario.label}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
                </div>
                <div className={styles.cellIframe}>
                    <iframe
                        ref={iframe => (this.iframe = iframe)}
                        className={styles.iframe}
                        width="100%"
                        height="100%"
                        src={this.state.target}
                    />
                </div>
            </div>
        );
    }
}

export default injectIntl(Book);
