import React from 'react';
import styles from './Checkbox.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckSquare, faSquare} from '@fortawesome/free-regular-svg-icons';
import CustomPopoverCheckbox from '../components/CustomPopoverCheckbox';
import cx from 'classnames';

export default function ({label, value, clickAction, popOverText, disabled, style: userStyle, className}: any) {
    return (
        <>
            {popOverText && <CustomPopoverCheckbox content={popOverText} />}
            <div
                className={cx(styles.container, className ? className : null)}
                onClick={!disabled ? clickAction : undefined}
                style={userStyle}
            >
                <div className={cx(styles.checkbox)}>
                    {value ? (
                        <FontAwesomeIcon icon={faCheckSquare as any} color={disabled ? 'gray' : 'green'} />
                    ) : (
                        <FontAwesomeIcon icon={faSquare as any} color={disabled ? 'gray' : 'green'} />
                    )}
                    <span className={styles.label}>{label}</span>
                </div>
            </div>
        </>
    );
}
