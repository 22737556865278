import React from 'react';

interface IIconArrowRightProps {
    style?: any;
    className?: any;
    color?: string;
}

function ArrowRight({style: userStyle, color, className}: IIconArrowRightProps) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{...(userStyle || {})}}
            className={className}
        >
            <path
                d="M8.75 7.5L11.25 10L8.75 12.5"
                stroke={color || 'white'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.83334 2.78176C7.09961 2.04918 8.53709 1.6645 10 1.66676C14.6025 1.66676 18.3333 5.39759 18.3333 10.0001C18.3333 14.6026 14.6025 18.3334 10 18.3334C5.39751 18.3334 1.66667 14.6026 1.66667 10.0001C1.66667 8.48259 2.07251 7.05842 2.78167 5.83342"
                stroke={color || 'white'}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
}

export default ArrowRight;
