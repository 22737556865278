import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';
import {IApplicationSearchItem, IContactItem} from '../../types';

export function apiGetBackOfficeContacts(): Promise<{
    contacts: IContactItem[];
}> {
    return getFetch(`${API_SERVERLESS}/online/v3/contacts`, true);
}

export function apiSearchApplications(
    criteria: any,
    DEFAULT_CRITERIA: any = {},
    excelTranslations?: boolean
): Promise<{
    data: {
        COUNT: number;
        applications: IApplicationSearchItem[];
    };
}> {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/online/v3/applicationRequests?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}

export function apiOnlineApplicationRequestsDelete({
    applicationRequestId,
}: {
    applicationRequestId: number;
}): Promise<{data: 'OK'}> {
    return postFetch(`${API_SERVERLESS}/online/v3/applicationRequestsDelete/${applicationRequestId}`, true);
}

export function apiSendFormToUPOV({applicationRequestId}: {applicationRequestId: number}) {
    return getFetch(`${API_SERVERLESS}/online/v3/xml/export/${applicationRequestId}`, true);
}

export function apiOnlineApplicationRequestDuplicateAndCopy(JSONRequest: {
    applicationRequestId?: number;
    applicationFormId?: number;
    applicationFormLanguageId?: string;
    officeId: any;
    speciesId: string;
    technicalQuestionnaireId: number;
    technicalQuestionnaireLanguageId: string;
    selectedAFGroups: string[];
    selectedTQGroups: string[];
}): Promise<{
    applicationId: number;
    errorMessage?: string;
    formType: string;
    formId: number;
    formSequence: number;
}> {
    return postFetch(`${API_SERVERLESS}/online/v3/applicationRequestDuplicateAndCopy`, true, JSONRequest);
}

export function apiOnlineApplicationRequestDuplicateObsolete(JSONRequest: {applicationRequestId: number}): Promise<{
    data?: IApplicationSearchItem;
    errorMessage?: string;
}> {
    return postFetch(`${API_SERVERLESS}/online/v3/applicationRequestDuplicateObsolete`, true, JSONRequest);
}
