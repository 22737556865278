import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import AutocompleteSpecies1 from './screenshots/AutocompleteSpecies_1.PNG';
import AutocompleteSpecies2 from './screenshots/AutocompleteSpecies_2.PNG';
import AutocompleteSpecies3 from './screenshots/AutocompleteSpecies_3.PNG';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeAutocompleteSpeciesHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>Displays an input like the following: </p>
                    <img src={AutocompleteSpecies1} alt="Auto complete species" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>shows suggestions based on the what the user is typing:</p>
                    <img src={AutocompleteSpecies2} alt="Auto complete species" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>
                        and lets the user select a specie from the auto completed list (and also edit the selection)
                    </p>
                    <img src={AutocompleteSpecies3} alt="Auto complete species" className={styles.modalHelpImage} />
                    <p className={styles.helpNote}>
                        If modification of selection is needed, then Control list Species logic type should be used
                        instead of Auto complete Species
                    </p>
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeAutocompleteSpeciesHelp;
