import React from 'react';
import InputContainer from '~componentsForm/InputContainer';
import styles from './Option.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faDotCircle} from '@fortawesome/free-regular-svg-icons';

export default function ({label, value, simple, double, clickAction, width}) {
    return (
        <InputContainer checkbox={!simple} double={double} width={width}>
            <div className={styles.container} onClick={clickAction}>
                <div className={styles.option}>
                    {value ? (
                        <FontAwesomeIcon icon={faDotCircle} color={'green'} />
                    ) : (
                        <FontAwesomeIcon icon={faCircle} color={'green'} />
                    )}
                    <div className={styles.label}>{label}</div>
                </div>
            </div>
        </InputContainer>
    );
}
