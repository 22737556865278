import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {HeaderLoading} from '../componentsLayout';
import ModalUsedInVersion2, {rowType} from '../commonModals/ModalUsedInVersion2';
import {ModalConfirmVersion2} from '../commonModals';
import StatusField from './StatusField';
import {apiBackOfficeTemplateActive} from './BackOfficeTemplateTabsService';
import cx from 'classnames';
import {faArrowLeft, faFile, faLightbulb, faServer} from '@fortawesome/free-solid-svg-icons';
import {injectIntl} from 'react-intl';
import {joinParams, loadUrlParams} from '~utils';
import styles from './ApplicationConsultationTabs.module.scss';
import {withRouter} from 'react-router-dom';

export const TAB = {
    DETAILS: 'templateDetails',
    QUESTIONS: 'templateQuestions',
};
const STATUS = {
    IN_PREPARATION: 1,
    ACTIVE: 2,
    TERMINATED: 3,
};

const OFFICE: any = {
    1: 'CPVO',
    21: 'NAK',
};

let statusSingleton: any = null;

let templateIdSingleton: any = null;

interface IProps {
    history: any;
    intl: any;
    loading?: any;
    officeId?: any;
    search?: any;
    templateId?: any;
    tabId?: any;
    templateStatus?: any;
}

interface IState {
    applicationNumber: any;
    lastSearchApplicationNumber: any;
    loading: boolean;
    templateId: any;
    params: any;
    status: any;
    modalTemplateUsedIn: any;
    modalConfirmActivateTemplate: any;
}

class BackOfficeTemplateTabs extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            applicationNumber: null,
            lastSearchApplicationNumber: null,
            loading: false,
            templateId: null,
            params: null,
            status: null,
            modalTemplateUsedIn: null,
            modalConfirmActivateTemplate: null,
        };
    }

    componentDidMount() {
        this.loadUrlParams(
            () =>
                (this.state.templateId || this.state.templateId === 0) &&
                this.props.search &&
                this.props.search(this.state.templateId)
        );
    }

    loadUrlParams = (callback: any) => {
        const params: {templateId?: any} = loadUrlParams();
        if (params['templateId']) {
            const templateId = params['templateId'];
            const validTemplateId = !isNaN(templateId);
            this.setState(prev => Object.assign({...prev}, {params}, validTemplateId && {templateId}), callback);
        }
    };

    refreshUrl = (noScroll: any) => {
        if (this.state.lastSearchApplicationNumber) {
            this.props.history.replace(`/${this.props.tabId}${(this.state.params && `?${this.state.params}`) || ''}`);
            !noScroll && window.scrollTo(0, 0);
        }
    };

    search = () => {
        const validRegisterId = !isNaN(this.state.applicationNumber) && this.state.applicationNumber.length === 8;
        validRegisterId &&
            this.props.search(this.state.applicationNumber).then(() =>
                this.setState(
                    prev => ({lastSearchApplicationNumber: prev.applicationNumber}),
                    () => this.refreshUrl(true)
                )
            );
    };

    goToTemplateDetails = () =>
        !this.props.loading &&
        this.props.history.push(`/templateDetails${(this.state.params && `?${joinParams(this.state.params)}`) || ''}`);

    goToTemplateQuestions = () =>
        !this.props.loading &&
        this.props.history.push(
            `/templateQuestions${(this.state.params && `?${joinParams(this.state.params)}`) || ''}`
        );

    goBackToSearch = () => {
        const params = Object.assign({}, this.state.params);
        delete params.templateId;
        this.props.history.push(`/templates${(this.state.params && `?${joinParams(params)}`) || ''}`);
        window.scrollTo(0, 0);
    };

    showModalUsedIn = () => this.state.templateId && this.setState(prev => ({modalTemplateUsedIn: prev.templateId}));

    showModalConfirmActivateTemplate = () => this.setState(prev => ({modalConfirmActivateTemplate: prev.templateId}));

    closeModal = () => this.setState({modalTemplateUsedIn: null});

    closeModalConfirmActivateTemplate = () => this.setState({modalConfirmActivateTemplate: null});

    onActivateTemplateConfirmAction = () => {
        this.setState({loading: true, modalTemplateUsedIn: null}, () => {
            apiBackOfficeTemplateActive({templateId: this.state.templateId}).then((jsonResponse: any) => {
                jsonResponse.data === 'OK' && (window as any).location.reload() && this.setState({loading: false});
            });
        });
        this.closeModalConfirmActivateTemplate();
    };

    render() {
        statusSingleton = this.props.templateStatus || statusSingleton;
        templateIdSingleton = this.props.templateId || templateIdSingleton;
        return (
            <>
                {this.state.loading && <HeaderLoading />}
                {this.state.modalTemplateUsedIn !== null ? (
                    <ModalUsedInVersion2
                        elementId={this.state.modalTemplateUsedIn}
                        intl={this.props.intl}
                        type={rowType.TEMPLATE}
                        close={this.closeModal}
                    />
                ) : null}
                {this.state.modalConfirmActivateTemplate !== null ? (
                    <ModalConfirmVersion2
                        title={`Active`}
                        message={`Are you sure you want to active this template ?`}
                        buttonName={'Yes'}
                        action={this.onActivateTemplateConfirmAction}
                        close={this.closeModalConfirmActivateTemplate}
                    />
                ) : null}
                {statusSingleton && (
                    <div style={{float: 'left'}}>
                        <div style={{display: 'inline', paddingRight: 10, marginLeft: 15}}>{'Status:'}</div>
                        <StatusField intl={this.props.intl} statusId={statusSingleton} />
                    </div>
                )}
                {this.props.officeId ? (
                    <div style={{float: 'left'}}>
                        <div style={{display: 'inline', paddingRight: 5, marginLeft: 15}}>{'Office:'}</div>
                        <div style={{display: 'inline'}}>{OFFICE[this.props.officeId]}</div>
                    </div>
                ) : null}
                <div style={{float: 'right', marginRight: 10}}>
                    <FormFooterButton
                        color={buttonColor.whiteGray}
                        clickAction={this.goBackToSearch}
                        icon={faArrowLeft}
                    >{`Go back to search`}</FormFooterButton>
                    {templateIdSingleton ? (
                        <FormFooterButton
                            color={buttonColor.whiteGreen}
                            clickAction={this.showModalUsedIn}
                            icon={faLightbulb}
                        >{`Used in`}</FormFooterButton>
                    ) : null}
                    {this.props.templateStatus === STATUS.IN_PREPARATION && parseInt(this.state.templateId) !== 0 && (
                        <FormFooterButton
                            color={buttonColor.whiteGreen}
                            clickAction={this.showModalConfirmActivateTemplate}
                        >{`Active this template`}</FormFooterButton>
                    )}
                </div>
                <div style={{clear: 'both'}} />
                <div className={styles.header}>
                    <div className={styles.leftPanel}>
                        <div
                            className={cx(styles.menuItem, this.props.tabId === TAB.DETAILS && styles.menuItemActive)}
                            onClick={this.goToTemplateDetails}
                        >
                            <div className={styles.menuIcon}>
                                <FontAwesomeIcon icon={faFile as any} color={this.props.loading ? 'gray' : 'green'} />
                            </div>
                            <div className={styles.menuLabel}>{`Details`}</div>
                        </div>
                        {templateIdSingleton && parseInt(this.state.templateId) !== 0 ? (
                            <div
                                className={cx(
                                    styles.menuItem,
                                    this.props.tabId === TAB.QUESTIONS && styles.menuItemActive
                                )}
                                onClick={this.goToTemplateQuestions}
                            >
                                <div className={styles.menuIcon}>
                                    <FontAwesomeIcon
                                        icon={faServer as any}
                                        color={this.props.loading ? 'gray' : 'green'}
                                    />
                                </div>
                                <div className={styles.menuLabel}>{`Questions`}</div>
                            </div>
                        ) : null}
                        <div style={{clear: 'both'}} />
                    </div>
                    <div style={{clear: 'both'}} />
                </div>
            </>
        );
    }
}

export default withRouter(injectIntl(BackOfficeTemplateTabs));
