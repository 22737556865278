import React, {useEffect, useState} from 'react';
import {Button} from '../../../../../componentsFormV2';
import CustomTable from '../../../../../components/CustomTable';
import {IntlShape} from 'react-intl';
import {LinkedCasesElement} from '../CaseFormPagePVRCASELAWService';
import {ModalCustomVersion2} from '../../../../../commonModals';

interface ModalCasesLinkedWithKeywordsProps {
    intl: IntlShape;
    linkedCasesList: LinkedCasesElement[] | null;
    timestamp: number;
    close: () => void;
}

const ModalCasesLinkedWithKeywords = (props: ModalCasesLinkedWithKeywordsProps) => {
    const RESULT_FIELDS_DEFAULT = ['caseId', 'caseName'];
    const [timestamp, setTimestamp] = useState(props.timestamp);
    const [count, setCount] = useState<number>(0);
    const [linkedCasesList, setLinkedCasesList] = useState<LinkedCasesElement[] | null>(props.linkedCasesList);

    useEffect(() => {
        setLinkedCasesList(props.linkedCasesList);
        setTimestamp(Date.now());
        setCount(props.linkedCasesList ? props.linkedCasesList?.length : 0);
    }, [props.linkedCasesList]);

    const closeModal = () => props.close && props.close();

    const getLinkedCasesListArea = () => {
        return !linkedCasesList || linkedCasesList.length === 0 ? (
            <div style={{paddingTop: 20, textAlign: 'left'}}>
                <b style={{color: 'red', textAlign: 'left'}}>
                    <span className="ng-scope">{`No Linked Cases available`}</span>
                </b>
            </div>
        ) : (
            <CustomTable
                version={2}
                {...props}
                tableName={'linkedCasesList'}
                tableType={'OBJECT'}
                tableSource={linkedCasesList}
                id={'caseId'}
                hideExcelButton={true}
                resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                intl={props.intl}
                defaultOrder={'caseId'}
                reverseOrder={false}
                timestamp={timestamp}
                count={count}
            />
        );
    };

    return (
        <ModalCustomVersion2
            close={closeModal}
            header={`Linked Cases`}
            body={getLinkedCasesListArea()}
            footer={
                <Button clickAction={props.close} icon={'close'} variation={'danger'}>
                    {`Close`}
                </Button>
            }
        />
    );
};

export default ModalCasesLinkedWithKeywords;
