import React from 'react';
import {injectIntl} from 'react-intl';
import {Button, Checkbox, TextInput} from '../../componentsFormV2';
import styles from './AuthenticationLogin.module.scss';
import {apiLogin, apiLostLogin, apiLostPassword} from './AuthenticationLoginService';
import ModalAuthenticationLoginRetriveLoginVersion2 from './ModalAuthenticationLoginRetriveLoginVersion2';
import ModalAuthenticationLoginRedefinePasswordVersion2 from './ModalAuthenticationLoginRedefinePasswordVersion2';
import ModalAlertVersion2 from '../../commonModals/ModalAlertVersion2';
import ModalContactUsVersion2 from '../../commonModals/ModalContactUsVersion2';
import {getLocalStorageLogins, ILocalStorageLoginItem, updateLocalStorageLogins} from './AuthenticationLogin.utils';
import {getDecodedJWT, trackPageView} from '../../utils';
import {
    Error,
    Footer,
    FormFooter,
    FormWrapper,
    HeaderBreadcrumb,
    HeaderCookies,
    HeaderLoading,
    HeaderLogo,
    HeaderRecommendedBrowsers,
    HeaderTitle,
} from '../../componentsLayout';
import {ModalConfirmVersion2} from '../../commonModals';
import {InteractionRequiredAuthError, PublicClientApplication} from '@azure/msal-browser';

const modalScreen = {
    RETRIEVE_LOGIN: 'RETRIEVE_LOGIN',
    REDEFINE_PASSWORD: 'REDEFINE_PASSWORD',
    RETRIEVE_LOGIN_OK: 'RETRIEVE_LOGIN_OK',
    REDEFINE_PASSWORD_OK: 'REDEFINE_PASSWORD_OK',
    CONTACT_US: 'CONTACT_US',
};

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    enableButton: boolean;
    username: string;
    password: string;
    rememberMe: boolean;
    loginUiError: string;
    loading: boolean;
    login: string;
    modalScreen: string | null;
    modalLastLogins: ILocalStorageLoginItem[];
    errorTitle: string;
    errorMessage: string;
    infoTitle: string;
    infoMessage: string;
    hidePassword: boolean;
    criteria: any;
    isPopoverOpen: boolean;
    continueSession: boolean;
}

class AuthenticationLogin extends React.Component<IProps, IState> {
    localStorageLogins: ILocalStorageLoginItem[] = [];

    constructor(props: IProps) {
        super(props);
        this.localStorageLogins = getLocalStorageLogins();
        this.state = Object.assign(
            {},
            {
                enableButton: false,
                username: '',
                password: '',
                rememberMe: false,
                loginUiError: '',
                loading: false,
                login: '',
                modalScreen: null,
                modalLastLogins: [],
                errorTitle: '',
                errorMessage: '',
                infoTitle: '',
                infoMessage: '',
                hidePassword: true,
                criteria: {},
                isPopoverOpen: false,
                continueSession: false,
            }
        );
    }

    componentDidMount() {
        trackPageView({documentTitle: 'login'});
        this.loadUrlParams();
        (self as any).setStateFunction = this.setStateFunction;
        (self as any).defaultState = this.state;
    }

    setStateFunction = (state: any) => this.setState(state);

    loadUrlParams = () => {
        const domainArray = document.location.href.split('login?');
        const criteria: any = {};
        criteria['autologin'] = true;
        if (domainArray.length > 1) {
            const params: any = domainArray.pop();
            if (params.indexOf('2FA=') === 0) {
                const paramsArray = params.split('=');
                paramsArray.shift();
                criteria['2fa'] = paramsArray.join('=');
                this.signIn();
            }
            if (params.indexOf('target=') === 0) {
                const paramsArray = params.split('=');
                paramsArray.shift();
                criteria['target'] = paramsArray.join('=');
            }
            if (params.indexOf('autologinOff') === 0) {
                criteria['autologin'] = false;
            }
            if (params.indexOf('home=') === 0) {
                const paramsArray = params.split('=');
                paramsArray.shift();
                criteria['home'] = paramsArray.join('=');
            }
        }
        const storedLogin = this.localStorageLogins.length ? this.localStorageLogins[0].username : '';
        if (criteria.autologin !== false && storedLogin !== '') {
            this.setState({criteria, username: storedLogin, continueSession: true});
        } else if (Object.keys(criteria).length > 0) {
            this.setState({criteria});
        }
    };

    setLoading = (value: boolean) => {
        return new Promise((resolve: any) => {
            this.setState({loading: value}, resolve);
        });
    };

    openFAQ = () => window.open('https://cpvo.europa.eu/en/help-center/faq');

    showInfo = (title: string, message: string) => {
        this.setState(
            {
                modalScreen: '',
                infoTitle: title,
                infoMessage: message,
            },
            () => {
                LOG([`showInfo: ${JSON.stringify(this.state)}`]);
            }
        );
    };

    closeModal = () => {
        this.setState({modalScreen: null});
    };

    closeModalLastLogins = () => this.setState({modalLastLogins: []});

    showContactUs = () => this.setState({modalScreen: modalScreen.CONTACT_US});

    showModalRedefinePasswordOK = () => this.setState({modalScreen: modalScreen.REDEFINE_PASSWORD_OK});

    showModalRetriveLoginOK = () => this.setState({modalScreen: modalScreen.RETRIEVE_LOGIN_OK});

    showModalRedefinePassword = () => this.setState({modalScreen: modalScreen.REDEFINE_PASSWORD});

    showModalRetrieveLogin = () => this.setState({modalScreen: modalScreen.RETRIEVE_LOGIN});

    goToRegistration = () => this.props.history.replace('/register');

    signIn = async () => {
        let loginResponse: any = null;
        if (!this.state.criteria['2fa'] && this.state.username.indexOf('@cpvo.europa.eu') !== -1) {
            const msalConfig = {
                auth: {
                    clientId: '032b0fae-965b-48dc-942a-23257449b896',
                    authority: 'https://login.microsoftonline.com/32bb2b09-1568-45a9-afc8-d817ec57aaac',
                    redirectUri: window.location.origin,
                    postLogoutRedirectUri: window.location.origin,
                    // scope: 'https://graph.microsoft.com/mail.read',
                },
            };
            const msalInstance = new PublicClientApplication(msalConfig);
            const silentRequest = {
                scopes: ['user.read'],
                loginHint: this.state.username,
            };
            try {
                loginResponse = await msalInstance.ssoSilent(silentRequest);
                if (
                    loginResponse.account &&
                    loginResponse.account.username &&
                    loginResponse.account.username !== this.state.username
                ) {
                    LOG([`microsoft username: ${JSON.stringify(loginResponse.account.username)}`]);
                    loginResponse = await msalInstance.loginPopup(silentRequest).catch(error => {
                        this.setState(prev => ({
                            errorMessage: error.message,
                            username: prev.username.replace('@cpvo.europa.eu', ''),
                            criteria: Object.assign(prev.criteria, {autologin: false}),
                        }));
                    });
                }
                !this.state.username.includes('webmaster') && updateLocalStorageLogins(this.state.username);
            } catch (err: any) {
                if (err instanceof InteractionRequiredAuthError) {
                    loginResponse = await msalInstance.loginPopup(silentRequest).catch(error => {
                        this.setState(prev => ({
                            errorMessage: error.message,
                            username: prev.username.replace('@cpvo.europa.eu', ''),
                            criteria: Object.assign(prev.criteria, {autologin: false}),
                        }));
                    });
                } else {
                    this.setState({errorMessage: err.message});
                }
            }

            // const loginResponse = await msalInstance.loginPopup({});
        }
        if (
            this.state.criteria['2fa'] ||
            (this.state.username !== '' &&
                (this.state.password !== '' || this.state.username.indexOf('@cpvo.europa.eu') !== -1))
        ) {
            this.setState({loading: true}, () => {
                apiLogin(
                    Object.assign(
                        {
                            login: this.state.username.trim(),
                            password: this.state.password.trim(),
                            resetCookie: true,
                            accessToken: loginResponse && loginResponse.accessToken,
                        },
                        this.state.rememberMe === true ? {remember: true} : {},
                        this.state.criteria['2fa'] ? {'2fa': this.state.criteria['2fa']} : {}
                    ),
                    this.state.username,
                    this.state.criteria,
                    this.props.history
                )
                    .then(async (jsonResponse: any) => {
                        if (
                            jsonResponse &&
                            (jsonResponse.result === -1 || jsonResponse.result === -2) &&
                            jsonResponse.message !== ''
                        ) {
                            this.setState(prev => ({
                                loginUiError: jsonResponse.message,
                                username: prev.username.replace('@cpvo.europa.eu', ''),
                            }));
                        } else if (!jsonResponse) {
                            let loggedInUser = getDecodedJWT();
                            const {email, thirdPartyId} = loggedInUser || {};
                            if (parseInt(thirdPartyId || '') === 1 && !(email || '').includes('webmaster')) {
                                updateLocalStorageLogins(email || '');
                            }
                            this.props.history.push('/welcome');
                        } else if (jsonResponse.challengeParams) {
                            const {challengeParams} = jsonResponse;
                            const publicKey: any = {
                                challenge: new Uint8Array(
                                    challengeParams.challenge.match(/[\da-f]{2}/gi).map(function (h: any) {
                                        return parseInt(h, 16);
                                    })
                                ),
                                rp: {
                                    id: 'localhost', // 'plantvarieties.eu',
                                    name: 'Community Plant Variety Office',
                                },
                                user: {
                                    id: Uint8Array.from(challengeParams.user.id, (c: any) => c.charCodeAt(0)),
                                    name: challengeParams.user.name,
                                    displayName: challengeParams.user.displayName,
                                },
                                pubKeyCredParams: [
                                    {type: 'public-key', alg: -7},
                                    {type: 'public-key', alg: -257},
                                ],
                                // authenticatorSelection: {
                                //     authenticatorAttachment: ['cross-platform', 'cross-platform'], //,'platform',
                                // },
                                authenticatorSelection: {
                                    // Try to use UV if possible. This is also the default.
                                    userVerification: 'preferred',
                                },
                                timeout: 60000,
                                attestation: 'direct',
                            };
                            await navigator.credentials
                                .create({publicKey})
                                .then(newCredentialInfo => {
                                    LOG([`SUCCESS ${newCredentialInfo}`]);
                                })
                                .catch(error => {
                                    ERROR([`FAIL: ${error}`]);
                                });
                        }
                    })
                    .then(() => this.setState({loading: false}));
            });
        } else {
            this.setState({
                loginUiError: '',
            });
        }
    };

    onContinueSession = () => {
        setTimeout(this.onNotContinueSession, 10000);
        this.setState({continueSession: false}, this.signIn);
    };

    onNotContinueSession = () => {
        this.setState(prev => ({
            continueSession: false,
            username: prev.username.replace('@cpvo.europa.eu', ''),
            criteria: Object.assign(prev.criteria, {autologin: false}),
        }));
    };

    onUsernameChange = ({target: {value: username}}: React.ChangeEvent<HTMLSelectElement>) => this.setState({username});

    onPasswordChange = ({target: {value: password}}: React.ChangeEvent<HTMLSelectElement>) => this.setState({password});

    onRememberMeCheckboxChange = () => this.setState(prev => ({rememberMe: !prev.rememberMe}));

    onPasswordVisibilityChange = () => this.setState(prev => ({hidePassword: !prev.hidePassword}));

    onToggleHelp = () => this.setState(prev => ({isPopoverOpen: !prev.isPopoverOpen}));

    onModalLastLoginsRowClick = (username: string) =>
        this.setState({username, modalLastLogins: []}, () => {
            if (this.state.username.indexOf('@cpvo.europa.eu') !== -1) {
                this.signIn();
            }
        });

    onStoredLoginClick = (storedLogin: string) => {
        this.setState({username: storedLogin}, () => {
            if (this.state.username.indexOf('@cpvo.europa.eu') !== -1) {
                this.signIn();
            }
        });
    };

    render() {
        const storedLogin = this.localStorageLogins.length ? this.localStorageLogins[0].username : '';
        if (this.state.criteria.autologin !== false && storedLogin !== '' && !this.state.loginUiError) {
            return (
                <>
                    {this.state.continueSession && (
                        <ModalConfirmVersion2
                            title={`Microsoft session found`}
                            message={`Do you want to continue with the previous session? (${this.state.username})`}
                            buttonName={'Yes'}
                            action={this.onContinueSession}
                            close={this.onNotContinueSession}
                            zIndexNumber={999999999}
                        />
                    )}
                    <HeaderLoading />;
                </>
            );
        }
        return (
            <>
                {this.state.loading ? <HeaderLoading /> : null}
                {this.state.modalScreen === modalScreen.RETRIEVE_LOGIN && (
                    <ModalAuthenticationLoginRetriveLoginVersion2
                        apiLostLogin={apiLostLogin}
                        showModalRetriveLoginOK={this.showModalRetriveLoginOK}
                        setLoading={this.setLoading}
                        close={this.closeModal}
                    />
                )}
                {this.state.modalScreen === modalScreen.REDEFINE_PASSWORD && (
                    <ModalAuthenticationLoginRedefinePasswordVersion2
                        apiLostPassword={apiLostPassword}
                        showModalRedefinePasswordOK={this.showModalRedefinePasswordOK}
                        setLoading={this.setLoading}
                        showContactUs={this.showContactUs}
                        close={this.closeModal}
                    />
                )}
                {this.state.modalScreen === modalScreen.RETRIEVE_LOGIN_OK && (
                    <ModalAlertVersion2
                        title={`Retrieve your login`}
                        message={`If the email exists... Your login has been sent. You should receive it in a few minutes. If you don't see it in your INBOX, please check your SPAM folder.`}
                        close={this.closeModal}
                    />
                )}
                {this.state.modalScreen === modalScreen.REDEFINE_PASSWORD_OK && (
                    <ModalAlertVersion2
                        title={`Define your password`}
                        message={`If the username exists... An email has been sent to you with a link to change your password. You should receive it in a few minutes (in case you don't receive it, please check your SPAM mailbox). This link will remain active for 72 hours only.`}
                        close={this.closeModal}
                    />
                )}
                {this.state.modalScreen === modalScreen.CONTACT_US && (
                    <ModalContactUsVersion2 close={this.closeModal} />
                )}
                <HeaderCookies />
                <HeaderLogo />
                <HeaderBreadcrumb links={['User Area']} />
                <HeaderTitle title={`Sign in User Area / MyPVR`} />
                <HeaderRecommendedBrowsers />
                <FormWrapper className={styles.formWrapper} paddingY={'lg'}>
                    {storedLogin ? (
                        <div className={styles.storedlogin} onClick={() => this.onStoredLoginClick(storedLogin)}>
                            {`If you want to login with the user `}
                            <span
                                style={{
                                    color: 'green',
                                    textDecoration: 'underline',
                                }}
                            >
                                {storedLogin.replace('@cpvo.europa.eu', '')}
                            </span>
                            {` click here.`}
                        </div>
                    ) : null}
                    <TextInput
                        onChange={this.onUsernameChange}
                        value={this.state.username}
                        focus={true}
                        label={`Username`}
                        labelIcon={'user'}
                        labelPosition={'leftInside'}
                        buttonAction={this.showModalRetrieveLogin}
                        buttonText={`Forgot your username?`}
                        mandatory={true}
                    />
                    {this.state.username.indexOf('@cpvo.europa.eu') === -1 ? (
                        <TextInput
                            type={this.state.hidePassword ? 'password' : 'text'}
                            onChange={this.onPasswordChange}
                            onEnter={this.signIn}
                            value={this.state.password}
                            label={'Password'}
                            labelIcon={'password'}
                            labelPosition={'leftInside'}
                            buttonAction={this.showModalRedefinePassword}
                            buttonText={`Forgot your password?`}
                            mandatory={true}
                            textInputIcon={this.state.hidePassword ? 'eye' : 'eyeSlash'}
                            textInputIconAction={this.onPasswordVisibilityChange}
                        />
                    ) : null}
                    <FormFooter>
                        {this.state.loginUiError ? <Error>{this.state.loginUiError}</Error> : null}
                        <Checkbox
                            className={styles.checkboxRememberMe}
                            clickAction={this.onRememberMeCheckboxChange}
                            label={`Stay connected`}
                            value={this.state.rememberMe}
                        />
                        <Button
                            clickAction={this.goToRegistration}
                            icon={'userPlus'}
                            variation={'secondary'}
                        >{`Create an account`}</Button>
                        <Button clickAction={this.signIn} icon={'login'} variation={'primary'}>{`Sign in`}</Button>
                    </FormFooter>
                </FormWrapper>
                <Footer showContactUs={this.showContactUs} />
            </>
        );
    }
}

export default injectIntl(AuthenticationLogin);
