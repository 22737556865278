import React from 'react';
import cx from 'classnames';
import styles from './Help.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import {ModalAlertVersion2} from '../../../commonModals';
import {getContentWithCorrectImagePath} from '~utils';
import {injectIntl} from 'react-intl';

class Help extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        };
    }

    openModal = () => {
        this.setState({modalOpen: true});
    };

    closeModal = () => {
        this.setState({modalOpen: false});
    };

    render() {
        const helpClass = this.props.className ? styles[this.props.className] : null;

        return (
            <div className={cx(styles.helpWrap, helpClass)}>
                {this.state.modalOpen ? (
                    <ModalAlertVersion2
                        message={
                            <div
                                /* eslint-disable-next-line react/no-danger */
                                dangerouslySetInnerHTML={{__html: getContentWithCorrectImagePath(this.props.message)}}
                            />
                        }
                        title={this.props.intl.formatMessage({id: 'general.help'})}
                        close={this.closeModal}
                    />
                ) : null}
                {this.props.message && this.props.message !== '' ? (
                    <a
                        className={cx('btn-help', styles.help, helpClass)}
                        title={this.props.intl.formatMessage({id: 'general.help'})}
                        onClick={this.openModal}
                    >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </a>
                ) : null}
            </div>
        );
    }
}

export default injectIntl(Help);
