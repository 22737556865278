import React from 'react';
import {injectIntl} from 'react-intl';
import styles from './ModalOnlineDelegationCreate.module.scss';
import DateInput from '~components/DateInput';
import TextAreaInput from '~components/TextAreaInput';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import {apiOnlineDelegationApplicationsGet, apiOnlineDelegationCheckLogin} from './ModalOnlineDelegationCreateService';
import {getDecodedJWT} from '~utils';
import {reformatDateEasy} from '~components/FormatFunctions';
import {IDecodedJWT} from '../../../../types';
import {Error, LoadingBar} from '../../../../componentsLayout';
import {ModalConfirmVersion2, ModalCustomVersion2} from '../../../../commonModals';
import {Button} from '../../../../componentsFormV2';

export interface IDelegationApplicationItem {
    applicationCode: string;
    applicationId: number;
    specieInput: string;
}

interface IProps {
    intl: any;
    close: any;
    onDelegationCreate: any;
}

interface IState {
    applications: IDelegationApplicationItem[];
    loading: number;
    inputValueLogin: string;
    inputValueApplicationCode: string;
    inputValueBeginDate: string;
    inputValueEndDate: string;
    inputValueInformation: any;
    errorLoginDoesNotExist: boolean;
    errorLoginIsCurrent: boolean;
    errorLoginSameThirdParty: boolean;
    errorBeginDate: boolean;
    applicationsNameHash: {[key: string]: any};
    applicationsListByIdName: {ID: string; NAME: string}[];
    selectedApplications: {[key: string]: string};
    isModalCreateDelegationForAllFormsOpen: boolean;
}

class ModalOnlineDelegationCreate extends React.Component<IProps, IState> {
    loggedInUser: IDecodedJWT = getDecodedJWT();
    dateObjectToday: any;
    loginUrl = `${document.location.protocol}//${document.location.host}/login`;

    constructor(props: IProps) {
        super(props);
        this.dateObjectToday = new Date();
        this.dateObjectToday.setDate(this.dateObjectToday.getDate());
        this.state = {
            applications: [],
            loading: 0,
            inputValueLogin: '',
            inputValueApplicationCode: '',
            inputValueBeginDate: '',
            inputValueEndDate: '',
            inputValueInformation: null,
            errorLoginDoesNotExist: false,
            errorLoginIsCurrent: false,
            errorLoginSameThirdParty: false,
            errorBeginDate: false,
            applicationsNameHash: {},
            applicationsListByIdName: [],
            selectedApplications: {},
            isModalCreateDelegationForAllFormsOpen: false,
        };
    }

    componentDidMount() {
        this.loadJSONs();
    }

    loadJSONs = () => {
        const promises = [this.loadDelegationApplications()];
        Promise.all(promises).catch(error => LOG([`Error loading data: ${error}`]));
    };

    loadDelegationApplications = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiOnlineDelegationApplicationsGet()
                    .then(JSONResponse => {
                        if (JSONResponse && JSONResponse.applications) {
                            const applicationsNameHash: any = {
                                '* - All (All my forms)': ['*'],
                            };
                            (JSONResponse.applications || []).forEach((application: any) => {
                                let key = `${application.applicationCode} (${application.specieInput})`;
                                if (!applicationsNameHash[key]) {
                                    applicationsNameHash[key] = [];
                                }
                                applicationsNameHash[key].push(application.applicationId);
                            });
                            const applicationsListByIdName = Object.keys(applicationsNameHash).map(applicationName => ({
                                ID: applicationsNameHash[applicationName].join(','),
                                NAME: applicationName,
                            }));
                            this.setState({
                                applicationsListByIdName,
                                applicationsNameHash,
                                applications: [...JSONResponse.applications],
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`ModalOADelegationCreate: ${error.message}`]);
                    })
                    .finally(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );

    utilGetErrorMessage() {
        if (this.state.errorLoginDoesNotExist) {
            return `Login not found`;
        }
        if (this.state.errorLoginIsCurrent) {
            return `Delegate login must be different to login of current user.`;
        }
        if (this.state.errorLoginSameThirdParty) {
            return `The login can not be someone in your third parties`;
        }
        return null;
    }

    utilProcessDate = (date: any) => {
        let parts = (date || '').split('/');
        if (parts && parts.length && parts.length > 2) {
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
        return -1;
    };

    requestDelegationCreate = () => {
        const {inputValueLogin, inputValueBeginDate, inputValueEndDate, inputValueInformation, selectedApplications} =
            this.state;
        const applications = [];
        if (Object.keys(selectedApplications).indexOf('*') !== -1) {
            applications.push({
                applicationId: '*',
                applicationCode: '*',
            });
        } else {
            Object.keys(selectedApplications).forEach(applicationId => {
                let {applicationCode} =
                    this.state.applications.find(el => el.applicationId === parseInt(applicationId)) || {};
                applications.push({
                    applicationId,
                    applicationCode,
                });
            });
        }
        let payload = {
            login: inputValueLogin,
            applications,
            beginDate: reformatDateEasy(inputValueBeginDate),
            endDate: reformatDateEasy(inputValueEndDate),
            information: inputValueInformation,
        };
        this.props.onDelegationCreate && this.props.onDelegationCreate(payload);
    };

    onInputLoginChange = ({target: {value: inputValueLogin}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({inputValueLogin});

    onInputBeginDateDelegationChange = (inputValueBeginDate: string) => this.setState({inputValueBeginDate});

    onInputEndDateDelegationChange = (inputValueEndDate: string) => this.setState({inputValueEndDate});

    onInputInformationChange = ({target: {value: inputValueInformation}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({inputValueInformation});

    onInputApplicationCodeChange = (inputValueApplicationCode: string) => this.setState({inputValueApplicationCode});

    onInputLoginBlur = () => {
        if (!this.state.inputValueLogin) {
            return this.setState({
                errorLoginDoesNotExist: false,
                errorLoginIsCurrent: false,
                errorLoginSameThirdParty: false,
            });
        }
        this.setState(
            {errorLoginDoesNotExist: false, errorLoginIsCurrent: false, errorLoginSameThirdParty: false},
            () => {
                apiOnlineDelegationCheckLogin({delegatedLogin: this.state.inputValueLogin})
                    .then((jsonResponse: any) => {
                        jsonResponse &&
                            jsonResponse.loginExist === false &&
                            this.setState({
                                errorLoginDoesNotExist: true,
                            });
                        jsonResponse &&
                            jsonResponse.sameAsUser === true &&
                            this.setState({
                                errorLoginIsCurrent: true,
                            });
                        jsonResponse &&
                            jsonResponse.sameThirdparty === true &&
                            this.setState({
                                errorLoginSameThirdParty: true,
                            });
                    })
                    .catch((error: any) => {
                        ERROR([`ModalOADelegationCreate: ${error.message}`]);
                    });
            }
        );
    };

    onCloseModalCreateDelegationForAllForms = () => this.setState({isModalCreateDelegationForAllFormsOpen: false});

    onApplicationSelectionChange = (selectedApplications: any) =>
        this.setState({selectedApplications, inputValueApplicationCode: ''});

    onButtonDelegationCreateClick = () => {
        const {selectedApplications} = this.state;
        this.setState({errorBeginDate: false}, () => {
            if (Object.keys(selectedApplications).includes('*') && Object.keys(selectedApplications).length > 1) {
                return this.setState({isModalCreateDelegationForAllFormsOpen: true});
            }
            if (
                this.utilProcessDate(this.state.inputValueBeginDate) >=
                this.utilProcessDate(this.state.inputValueEndDate)
            ) {
                return this.setState({errorBeginDate: true});
            }
            this.requestDelegationCreate();
        });
    };

    render() {
        const {
            inputValueLogin,
            errorLoginDoesNotExist,
            errorLoginIsCurrent,
            errorLoginSameThirdParty,
            inputValueBeginDate,
            selectedApplications,
        } = this.state;
        const isCreateButtonEnabled =
            inputValueLogin &&
            !errorLoginDoesNotExist &&
            !errorLoginIsCurrent &&
            !errorLoginSameThirdParty &&
            inputValueBeginDate &&
            Object.keys(selectedApplications || {}).length;

        return (
            <>
                {this.state.isModalCreateDelegationForAllFormsOpen ? (
                    <ModalConfirmVersion2
                        title={`Create a delegation`}
                        message={`By selecting the 'All my forms' option the creation will not take into account other selected elements.
                        Continue ?`}
                        buttonName={'Yes'}
                        action={this.requestDelegationCreate}
                        close={this.onCloseModalCreateDelegationForAllForms}
                    />
                ) : null}
                <ModalCustomVersion2
                    close={this.props.close}
                    header={`Create Delegation`}
                    body={
                        <>
                            <TextInput
                                label={`Login`}
                                onChange={this.onInputLoginChange}
                                value={this.state.inputValueLogin}
                                error={this.utilGetErrorMessage()}
                                onBlur={this.onInputLoginBlur}
                                mandatory={true}
                                disabled={this.state.loading !== 0}
                            />
                            <div style={{clear: 'both'}} />
                            <TextLabelInput
                                label={`Application code (*=all)`}
                                onChange={this.onInputApplicationCodeChange}
                                onSelectionChange={this.onApplicationSelectionChange}
                                value={this.state.inputValueApplicationCode}
                                multiple={true}
                                selectedElements={this.state.selectedApplications || {}}
                                listByIdName={this.state.applicationsListByIdName || []}
                                nameHash={this.state.applicationsNameHash || {}}
                                double={true}
                                mandatory={true}
                                filterContains={true}
                                disabled={this.state.loading !== 0}
                            />
                            <p
                                className={styles.inputInfo}
                            >{`* : current and future applications. You may only delegate the right to edit your own applications (i.e. created by yourself).`}</p>
                            <div style={{clear: 'both'}} />
                            <DateInput
                                label={`Delegation begins`}
                                changeDateFrom={this.onInputBeginDateDelegationChange}
                                inputValueFrom={this.state.inputValueBeginDate}
                                simple={true}
                                mandatory={true}
                                minDateFrom={this.dateObjectToday}
                                maxDate={this.state.inputValueEndDate}
                                disabled={this.state.loading !== 0}
                            />
                            <DateInput
                                label={`Delegation ends`}
                                changeDateFrom={this.onInputEndDateDelegationChange}
                                inputValueFrom={this.state.inputValueEndDate}
                                simple={true}
                                minDateFrom={this.dateObjectToday}
                                disabled={this.state.loading !== 0}
                            />
                            {this.state.errorBeginDate ? (
                                <>
                                    <div style={{clear: 'both'}} />
                                    <div className={styles.errorWrap}>
                                        <Error>{`Begin date must be lower than end date`}</Error>
                                    </div>
                                    <div style={{clear: 'both'}} />
                                </>
                            ) : null}
                            <TextAreaInput
                                double={true}
                                label={`Additional information`}
                                value={this.state.inputValueInformation}
                                onChange={this.onInputInformationChange}
                                rows={5}
                                disabled={this.state.loading !== 0}
                            />
                            <div style={{clear: 'both'}} />
                            <div className={styles.messageContainer}>
                                <div className={styles.messageTitle}>Message</div>
                                <div>
                                    {this.loggedInUser &&
                                        `${this.loggedInUser.forename} ${this.loggedInUser.name} delegates the ability to fill in CPVO application #Form number (*=all).`}
                                </div>
                                <div style={{marginTop: 10, marginBottom: 10}}>
                                    Please use the following link to proceed:
                                </div>
                                <div>{this.loginUrl}</div>
                            </div>
                        </>
                    }
                    footer={
                        this.state.loading === 0 ? (
                            <>
                                <Button variation={'secondary'} clickAction={this.props.close}>{`Close`}</Button>
                                <Button
                                    clickAction={this.onButtonDelegationCreateClick}
                                    disabled={!isCreateButtonEnabled}
                                >{`Create`}</Button>
                            </>
                        ) : (
                            <LoadingBar />
                        )
                    }
                />
            </>
        );
    }
}

export default injectIntl(ModalOnlineDelegationCreate);
