import {getFetch, API_SERVERLESS} from '~commonApi';

export function apiPublicSearchDocuments(applicationNumber) {
    const remember = localStorage.getItem('remember') === '1';
    const isLogged = (remember ? localStorage : sessionStorage).getItem('token');
    return getFetch(
        `${API_SERVERLESS}/mypvr/v3/publicSearchDocuments${isLogged ? 'Auth' : ''}/${applicationNumber}`,
        isLogged
    );
}
