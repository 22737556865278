import React from 'react';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {injectIntl} from 'react-intl';
import {faChevronDown, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import CryptoJS from 'crypto-js';
import {saveAs} from 'file-saver';
import CustomTable from '~components/CustomTable';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import InputLink from '~components/InputLink';
import TextAreaInput from '~components/TextAreaInput';
import BrowserInfo from '~shared/BrowserInfo';
import getIcon from '~utils/icons';
import CCModalClientReplyActionButtons from './CCModalClientReplyActionButtons';
import ClientMessageFieldsAndAttachments from '../CommunicationCentreClient/modals/ClientMessageFieldsAndAttachments';
import {
    apiCommunicationCentreModalClientReplyMessageRead,
    apiGetCommunicationIdUploadFilesAndSendMessage,
} from './CCModalClientReplyService';
import {getMandatoryAsterisk} from '../../utils/format';
import {getDecodedJWT, sanitize} from '../../utils';
import {MAX_UPLOAD_FILE_SIZE} from '../../utils/constants';
import {
    ICommunicationAttachmentResponse,
    ICommunicationMessageClientRead,
    ICommunicationMessageClientSubmitJSONRequest,
    IDocument,
} from '../../types';
import {ModalApplicationNumbers, ModalCustomVersion3} from '../../commonModals';
import {Error} from '../../componentsLayout';
import styles from './CCModalClientReply.module.scss';
import {Button} from '../../componentsFormV2';

interface IProps {
    close: () => any;
    communicationId: number;
    intl: any;
    isPreviewMode?: boolean;
    openModalCPVOLogs: (obj: any) => void;
}

interface IState {
    documentList: IDocument[];
    error: string | null;
    files: ICommunicationAttachmentResponse[];
    informationOpened: boolean;
    loading: number;
    message: ICommunicationMessageClientRead | null;
    textAreaBodyValue: string;
    modalApplicationNumbers: string[];
    timestamp: number;
}

class CCModalClientReply extends React.Component<IProps, IState> {
    decodedJWT = getDecodedJWT();
    inputUploadRef: any = null;

    constructor(props: IProps) {
        super(props);
        this.inputUploadRef = React.createRef();
        this.state = {
            documentList: [],
            error: null,
            files: [],
            informationOpened: false,
            loading: 0,
            message: null,
            textAreaBodyValue: '',
            modalApplicationNumbers: [],
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        this.setState(
            prev => ({
                loading: prev.loading + 1,
            }),
            () => {
                apiCommunicationCentreModalClientReplyMessageRead(this.props.communicationId).then(
                    (JSONResponse: {
                        files: ICommunicationAttachmentResponse[];
                        message: ICommunicationMessageClientRead;
                    }) => {
                        const {files, message} = JSONResponse || {};
                        let filesFiltered = [...(files || [])];
                        if (files.length) {
                            const fileIndexConfirmationOfReceipt = files.findIndex(
                                el => el.filename === 'ConfirmationOfReceipt.pdf'
                            );
                            if (
                                fileIndexConfirmationOfReceipt !== -1 &&
                                message.thirdPartyId !== parseInt(this.decodedJWT ? this.decodedJWT.thirdPartyId : '')
                            ) {
                                filesFiltered.splice(fileIndexConfirmationOfReceipt, 1);
                            }
                        }
                        this.setState(prev => ({
                            loading: prev.loading - 1,
                            files: filesFiltered,
                            message: message,
                        }));
                    }
                );
            }
        );
    }

    openModalApplicationNumbers = (applicationNumbers: string[]) =>
        this.setState({modalApplicationNumbers: applicationNumbers});

    closeModalApplicationNumbers = () => this.setState({modalApplicationNumbers: []});

    confirmSendingReply = (messageStatus: 'draft' | 'sent') => {
        this.setState({error: null}, () => {
            if (!(this.state.documentList || []).length && !this.state.textAreaBodyValue) {
                return this.setState({error: 'Reply body cannot be empty'});
            }
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    const {textAreaBodyValue, documentList} = this.state;
                    if (this.state.message) {
                        const {
                            applicationNumbers,
                            communicationId: communicationPreviousId,
                            correlationId,
                        } = this.state.message;
                        const JSONRequest: ICommunicationMessageClientSubmitJSONRequest = {
                            applicationNumbers,
                            communicationPreviousId,
                            correlationId,
                            draft: messageStatus === 'draft',
                            messageBody: textAreaBodyValue,
                            numberOfAttachments: (documentList || []).length,
                        };
                        apiGetCommunicationIdUploadFilesAndSendMessage(JSONRequest, documentList)
                            .then(({communicationId}: {communicationId: number}) => {
                                if (communicationId) {
                                    this.setState(
                                        prev => ({loading: prev.loading - 1}),
                                        () => {
                                            this.props.close();
                                            if (messageStatus === 'sent') {
                                                this.props.openModalCPVOLogs &&
                                                    this.props.openModalCPVOLogs({
                                                        comments: textAreaBodyValue,
                                                        communicationId,
                                                        subject: this.state.message?.messageSubject,
                                                    });
                                            }
                                        }
                                    );
                                } else {
                                    this.setState(prev => ({
                                        loading: prev.loading - 1,
                                        error: '404 - Document not found',
                                    }));
                                }
                            })
                            .catch(this.props.close);
                    }
                }
            );
        });
    };

    toggleInformation = () => this.setState(prev => ({informationOpened: !prev.informationOpened}));

    checkFile = (file: File, event: React.ChangeEvent<HTMLInputElement>) => {
        LOG([`file.type:${file.type}`]);
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType?.toLowerCase();
        const filename = sanitize(file.name);
        this.setState({error: null}, () => {
            if (
                fileTypeLowercase === 'jpeg' ||
                fileTypeLowercase === 'png' ||
                fileTypeLowercase === 'jpg' ||
                fileTypeLowercase === 'pdf'
            ) {
                if (file.size > MAX_UPLOAD_FILE_SIZE) {
                    this.setState({
                        error: 'The maximum document size for uploads is 10 MB. Please select a proper file.',
                    });
                } else {
                    const a = new FileReader();
                    a.readAsBinaryString(file);
                    a.onloadend = () => {
                        const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(a.result));
                        this.setState(
                            prev => {
                                const documentList = prev.documentList.slice(0);
                                documentList.push({
                                    index,
                                    file,
                                    filename,
                                    size: file.size / 1024,
                                    extension: fileTypeLowercase,
                                    documentType: null,
                                });
                                return {documentList, timestamp: Date.now(), informationOpened: false, error: null};
                            },
                            () => (event.target.value = '')
                        );
                    };
                }
            } else {
                this.setState({error: 'type not valid'}, () => (event.target.value = ''));
            }
        });
    };

    deleteFile = (index: any) =>
        this.setState(prev => {
            return {
                documentList: prev.documentList.filter(i => i.index !== index),
                timestamp: Date.now(),
                informationOpened: prev.documentList.length === 1,
            };
        });

    getRecipientName = () => {
        const {addressees, from, thirdPartyId: messageThirdPartyId} = this.state.message || {};
        const {thirdPartyId: loggedInUserThirdPartyId} = this.decodedJWT || {};
        if (parseInt(loggedInUserThirdPartyId || '') === messageThirdPartyId) {
            return (addressees || []).map(el => el.NAME).join(', ');
        } else {
            return from;
        }
    };

    onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];
            this.checkFile(file, event);
        }
    };

    onTextAreaBodyChange = ({target: {value: textAreaBodyValue}}: React.ChangeEvent<HTMLTextAreaElement>): void =>
        this.setState({textAreaBodyValue});

    render() {
        const attachmentActions = CCModalClientReplyActionButtons(this.deleteFile);

        return (
            <>
                {this.state.modalApplicationNumbers.length ? (
                    <ModalApplicationNumbers
                        applicationNumbers={this.state.modalApplicationNumbers}
                        close={this.closeModalApplicationNumbers}
                    />
                ) : null}
                <ModalCustomVersion3
                    isPreviewMode={this.props.isPreviewMode || false}
                    close={this.props.close}
                    header={`Replying message`}
                    body={
                        <div>
                            <DataSheetFormSection title={'Original message'}>
                                {this.state.documentList && this.state.documentList.length > 0 && (
                                    <CustomTable
                                        version={1}
                                        {...this.props}
                                        notSortable={['filename', 'size']}
                                        tableName={'documentUpload'}
                                        tableType={'OBJECT'}
                                        tableSource={this.state.documentList || []}
                                        dataFilter={null}
                                        id={'index'}
                                        setLastCursor={null}
                                        resultFieldsDefault={['filename', 'size']}
                                        intl={this.props.intl}
                                        formatFunctions={{
                                            size: (size: number) => `${Math.floor(size * 100) / 100} KB`,
                                            extension: (extension: string) => (
                                                <img src={getIcon(extension || 'pdf')} alt={extension} />
                                            ),
                                        }}
                                        count={(this.state.documentList || []).length}
                                        hideExcelButton={true}
                                        forehandColumn={(row: IDocument) => (
                                            <img
                                                style={{width: 20, height: 20, cursor: 'pointer'}}
                                                alt={'Download'}
                                                src={getIcon(row.extension || 'pdf')}
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    saveAs(row.file, row.filename);
                                                }}
                                            />
                                        )}
                                        timestamp={this.state.timestamp}
                                        actions={attachmentActions}
                                    />
                                )}
                                <ClientMessageFieldsAndAttachments
                                    attachments={this.state.files}
                                    communicationId={this.props.communicationId}
                                    intl={this.props.intl}
                                    message={this.state.message}
                                    openModalApplicationNumbers={this.openModalApplicationNumbers}
                                />
                            </DataSheetFormSection>
                            <DataSheetFormSection disableToggle={true} title={'Replying message'}>
                                <DataSheetFormFields
                                    label={'To'}
                                    data={this.getRecipientName()}
                                    double={true}
                                    loading={!this.getRecipientName()}
                                />
                                <div>
                                    <TextAreaInput
                                        label={
                                            (this.state.documentList || []).length > 0
                                                ? 'Body'
                                                : getMandatoryAsterisk('Body')
                                        }
                                        value={this.state.textAreaBodyValue}
                                        onChange={this.onTextAreaBodyChange}
                                        rows={5}
                                        triple={true}
                                        width={750}
                                    />
                                </div>
                                <div>
                                    <InputLink
                                        label={`Notes`}
                                        icon={this.state.informationOpened ? faChevronDown : faChevronRight}
                                        clickAction={this.toggleInformation}
                                    />
                                    <div style={{clear: 'both'}} />
                                    {this.state.informationOpened && <BrowserInfo intl={this.props.intl} />}
                                </div>
                                <div style={{clear: 'both'}} />
                            </DataSheetFormSection>
                        </div>
                    }
                    footer={
                        <>
                            {this.state.error ? <Error>{this.state.error}</Error> : null}
                            <div className={styles.modalFooterActions}>
                                <div className={styles.buttonAddDocument}>
                                    <label htmlFor={'upload'}>
                                        <input
                                            type="file"
                                            name="files[]"
                                            id="upload"
                                            style={{opacity: 0, width: 0}}
                                            onChange={this.onFileUpload}
                                            accept="image/jpg, image/jpeg, image/png, application/pdf"
                                            ref={this.inputUploadRef}
                                        />
                                        <Button
                                            variation={'secondary'}
                                            disabled={this.state.loading !== 0}
                                            clickAction={() => this.inputUploadRef.current.click()}
                                        >{`Add document`}</Button>
                                    </label>
                                </div>
                                <div className={styles.buttonsRightWrap}>
                                    <Button
                                        variation={'danger'}
                                        clickAction={this.props.close}
                                        icon={'close'}
                                        className={styles.buttonClose}
                                    >{`Close`}</Button>
                                    <Button
                                        variation={'secondary'}
                                        clickAction={() => this.confirmSendingReply('draft')}
                                        disabled={this.state.loading !== 0}
                                        className={styles.buttonDraft}
                                    >{`Draft`}</Button>
                                    <Button
                                        clickAction={() => this.confirmSendingReply('sent')}
                                        disabled={this.state.loading !== 0}
                                        icon={'arrowRight'}
                                    >{`Send reply`}</Button>
                                </div>
                            </div>
                        </>
                    }
                />
            </>
        );
    }
}

export default injectIntl(CCModalClientReply);
