import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeValidationsHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p>Checks if the response the client replied with is in valid format.</p>
                    <p>The list of validations is the following:</p>
                    <ul>
                        <li>Phone / Fax</li>
                        <li>Email</li>
                        <li>Denomination</li>
                        <li>Denomination Naktuinbouw</li>
                        <li>Custom logic with regular expression</li>
                    </ul>
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeValidationsHelp;
