import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {injectIntl, IntlShape} from 'react-intl';
import styles from './ModalProposalImage.module.scss';
import {ModalCustomVersion2} from '../../../../../../commonModals';
import {History} from 'history';

interface ModalProposalImageProps {
    intl: IntlShape;
    history: History;
    photoUrl: string;
    close: () => void;
}

const ModalProposalImage = (props: ModalProposalImageProps & RouteComponentProps) => {
    return (
        <ModalCustomVersion2
            loading={false}
            header={'Proposal image'}
            close={() => props.close && props.close()}
            body={
                <div className={styles.modalContent}>
                    <img style={{maxWidth: 700, maxHeight: 700}} src={props.photoUrl} />
                    <div style={{clear: 'both'}}>{}</div>
                </div>
            }
            footer={<></>}
        />
    );
};
export default injectIntl(withRouter(ModalProposalImage));
