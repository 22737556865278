import React from 'react';
import Checkbox from '~components/Checkbox';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {IDecodedJWT, IGroup, IGroupQuestion} from '../../../types';
import {Error, LoadingBar} from '../../../componentsLayout';
import {ModalConfirmVersion2, ModalLabelPreviewVersion2, ModalLabelSearchVersion2} from '../../../commonModals';
import ModalUsedInVersion2, {rowType} from '../../../commonModals/ModalUsedInVersion2';
import ModalLabelVersion2, {getLabelTypeId} from '../../../commonModals/ModalLabelVersion2';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import {
    apiBackOfficeFormOrTemplateResponseCreateOrUpdate,
    apiBackOfficeLabels,
    apiBackOfficeUpdateLabels,
} from './ResponseModalDetailsGeneralInformationService';
import cx from 'classnames';
import {
    faLightbulb,
    faList,
    faPencilAlt,
    faPlus,
    faSearch,
    faSpinner,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {getContentWithCorrectImagePath, getDecodedJWT, isAdminUser} from '~utils';
import {injectIntl} from 'react-intl';
import {computeResponseOrder, prepareJSONRequestBody} from './ResponseModalDetailsGeneralInformation.utils';
import {renderHTML} from '~common/format';
import styles from './ResponseModalDetailsLogics.module.scss';
import {Button} from '../../../componentsFormV2';

const OFFICES: any = {
    1: 'CPVO',
    21: 'NAKT',
    3: 'Other',
};

const RESPONSE_STATUS = {
    IN_PREPARATION: 1,
    ACTIVE: 2,
    TERMINATED: 3,
};

export interface IProps {
    afterCreatingNewResponse?: any;
    close: (force?: any) => any;
    formId: number | null;
    formOrTemplateOfficeId?: number;
    group: IGroup;
    intl: any;
    loadFormQuestionsJSONs: any;
    mainScreen?: string;
    modalQueuePop: any;
    modalQueuePush: any;
    question: IGroupQuestion;
    response: any;
    responseId: number;
    steps: IGroup[];
    setCloseHandler: (handler: any) => any;
    setResponseModalDetailsStatus: (status: any) => any;
    templateId?: number | null;
    setRenderFooter?: any;
}

interface IState {
    errorMandatoryFields: boolean;
    forceHideSearchList: boolean;
    loading: number;
    modalConfirmClose: boolean;
    question: {idElement: number | null};
    response: any;
    textLabelResponseNameValue: string;
    textLabelResponseNameSelectedElements: any;
    textLabelResponseNameListByIdName: {ID: any; NAME: string}[];
    textLabelResponseNameNameHash: {[key: string]: number[]};
    textLabelResponseNameTimeoutId: any;
    textLabelResponseNameSearchLoading: boolean;
    textTimestamp: number;
}

class ResponseModalDetailsGeneralInformation extends React.Component<IProps, IState> {
    initialResponse: any = {};
    administrator: boolean = isAdminUser();
    decodedJWT: IDecodedJWT = getDecodedJWT();
    textLabelResponseNameResults: any = [];

    constructor(props: IProps) {
        super(props);
        props.setResponseModalDetailsStatus &&
            props.setResponseModalDetailsStatus((props.response && props.response.status) || null);
        this.state = {
            loading: 0,
            errorMandatoryFields: false,
            response: {
                nameMessage: {
                    id: (props.response && props.response.messageId) || null,
                    valueHTML: (props.response && props.response.message) || '',
                    value:
                        (props.response &&
                            props.response.message &&
                            getContentWithCorrectImagePath(props.response.message)) ||
                        '',
                },
                remarkMessage: {
                    id: (props.response && props.response.messageRemarkId) || null,
                    valueHTML: (props.response && props.response.messageRemark) || '',
                    value:
                        (props.response &&
                            props.response.messageRemark &&
                            getContentWithCorrectImagePath(props.response.messageRemark)) ||
                        '',
                },
                helpMessage: {
                    id: (props.response && props.response.messageHelpId) || null,
                    valueHTML: (props.response && props.response.messageHelp) || '',
                    value:
                        (props.response &&
                            props.response.messageHelp &&
                            getContentWithCorrectImagePath(props.response.messageHelp)) ||
                        '',
                },
                idElement: (props.response && props.response.id) || null,
                office: (props.question && props.question.office) || null,
                score: (props.response && props.response.score) || null,
                order: computeResponseOrder(props) || null,
                mappingCode: (props.response && props.response.mappingCode) || null,
                jsonMapping: (props.response && props.response.jsonMapping) || null,
                remarkAllowed: (props.response && props.response.remarkAllowed) || null,
                remarkMandatory: (props.response && props.response.remarkMandatory) || null,
                status: (props.response && props.response.status) || null,
            },
            question: {
                idElement: (props.question && props.question.id) || null,
            },
            modalConfirmClose: false,
            textLabelResponseNameValue: '',
            textLabelResponseNameSelectedElements:
                props.responseId === 0
                    ? {}
                    : {
                          [props.response.messageId]: [props.response.message],
                      },
            textLabelResponseNameListByIdName:
                props.responseId === 0
                    ? []
                    : [
                          {
                              ID: props.response.messageId,
                              NAME: props.response.message,
                          },
                      ],
            textLabelResponseNameNameHash:
                props.responseId === 0
                    ? {}
                    : {
                          [props.response.message]: [props.response.messageId],
                      },
            textLabelResponseNameTimeoutId: null,
            textLabelResponseNameSearchLoading: false,
            textTimestamp: Date.now(),
            forceHideSearchList: true,
        };
    }

    componentDidMount() {
        this.props.setCloseHandler(this.closeHandler);
        this.props.setRenderFooter && this.props.setRenderFooter(this.renderFooter());
    }

    closeHandler = () => {
        const formHasChanged =
            Object.keys(this.initialResponse || {}).filter(
                key => this.state.response && this.initialResponse[key] !== this.state.response[key]
            ).length > 0;
        if (formHasChanged) {
            this.openModalConfirmClose();
            return false;
        } else {
            return true;
        }
    };

    showModalPreview = (valueHTML: string) =>
        this.props.modalQueuePush(
            <ModalLabelPreviewVersion2 intl={this.props.intl} close={this.closeModalPreview} valueHTML={valueHTML} />
        );
    closeModalPreview = () => this.props.modalQueuePop();

    showModalLabel = (labelId: number | null, typeId: string, fieldIdKey: string, fieldHTMLKey: string) =>
        this.props.modalQueuePush(
            <ModalLabelVersion2
                intl={this.props.intl}
                label={{labelId, typeId, fieldIdKey, fieldHTMLKey}}
                setLabelId={this.setLabelId}
                showModalUsedIn={this.showModalUsedIn}
                close={this.closeModalLabel}
            />
        );
    closeModalLabel = () => this.props.modalQueuePop();

    showModalUsedIn = (labelId: number | null) =>
        this.props.modalQueuePush(
            <ModalUsedInVersion2
                elementId={labelId}
                intl={this.props.intl}
                type={rowType.LABEL}
                close={this.closeModalUsedIn}
            />
        );
    closeModalUsedIn = () => this.props.modalQueuePop();

    openModalConfirmClose = () => this.setState({modalConfirmClose: true});

    closeModalConfirmClose = () => this.setState({modalConfirmClose: false});

    updateForm = (stateData: any) =>
        this.setState(prev => {
            return {
                ...prev,
                response: {
                    ...prev.response,
                    ...stateData,
                },
            };
        });

    showModalLabelSearch = (typeId: string, fieldIdKey: string, fieldHTMLKey: string) =>
        this.props.modalQueuePush(
            <ModalLabelSearchVersion2
                intl={this.props.intl}
                target={{typeId, fieldIdKey, fieldHTMLKey}}
                setLabelId={this.setLabelId}
                showModalPreview={this.showModalPreview}
                showModalUsedIn={this.showModalUsedIn}
                showModalLabel={this.showModalLabel}
                close={this.closeModalLabelSearch}
            />
        );

    closeModalLabelSearch = () => this.props.modalQueuePop();

    setLabelId = (label: any, fieldIdKey: string) => {
        const {labelId, valueHTML, labelValueHTML, typeId} = label;
        if (fieldIdKey === 'nameMessage') {
            this.setState({
                textLabelResponseNameSelectedElements: {
                    [labelId]: valueHTML || labelValueHTML,
                },
                textLabelResponseNameListByIdName: [
                    {
                        ID: labelId,
                        NAME: valueHTML || labelValueHTML,
                    },
                ],
                textLabelResponseNameNameHash: {
                    [valueHTML || labelValueHTML]: [labelId],
                },
            });
        }
        return this.updateForm({
            [fieldIdKey]: {id: labelId, value: valueHTML || labelValueHTML, type: typeId},
        });
    };

    clearLabelUse = (fieldIdKey: string) => {
        this.updateForm({[fieldIdKey]: {id: null, value: null, type: null}});
        if (fieldIdKey === 'nameMessage') {
            this.onTextLabelResponseNameSelectionChange({});
            /* workaround to hide contextual menu popup after clear label use */
            setTimeout(() => {
                let DOMLabelIcon = document.querySelector('div[class^="DataSheetFormFields_labelIcon"]') as HTMLElement;
                DOMLabelIcon && DOMLabelIcon.click();
                let DOMLabelInput = document.querySelector('input[class^="TextLabelInput_input_"]') as HTMLElement;
                DOMLabelInput && DOMLabelInput.click();
            }, 100);
        }
    };

    requestFormOrTemplateResponseCreateOrUpdate = (JSONRequest: any) => {
        apiBackOfficeFormOrTemplateResponseCreateOrUpdate(prepareJSONRequestBody(JSONRequest)).then(JSONResponse => {
            this.setState(
                prev => ({loading: prev.loading - 1}),
                () => {
                    JSONResponse &&
                        JSONResponse.newResponseId &&
                        this.props.afterCreatingNewResponse &&
                        this.props.afterCreatingNewResponse(JSONResponse.newResponseId);
                    if (JSONResponse && JSONResponse.data === 'OK') {
                        this.props.loadFormQuestionsJSONs && this.props.loadFormQuestionsJSONs(null, null, null, true);
                        this.props.close && this.props.close();
                    }
                }
            );
        });
    };

    responseSearch = () => {
        this.setState({textLabelResponseNameSearchLoading: true}, () => {
            apiBackOfficeLabels({
                responseSearchTerm: this.state.textLabelResponseNameValue,
                languageId: ((this.decodedJWT && this.decodedJWT.language) || '').toUpperCase(),
                officeId: this.props.formOrTemplateOfficeId,
            }).then(JSONResponse => {
                const responseNameHash: {[key: string]: number[]} = {};
                ((JSONResponse.data && JSONResponse.data && JSONResponse.data.labels) || []).forEach((el: any) => {
                    let key = el.value;
                    if (!responseNameHash[key]) {
                        responseNameHash[key] = [];
                    }
                    responseNameHash[key].push(el.labelId);
                    this.textLabelResponseNameResults = JSONResponse.data.labels;
                });
                const responseListByIdName = Object.keys(responseNameHash).map(responseName => ({
                    ID: responseNameHash[responseName].join(','),
                    NAME: responseName,
                }));
                this.setState({
                    textLabelResponseNameSearchLoading: false,
                    textLabelResponseNameListByIdName: responseListByIdName,
                    textLabelResponseNameNameHash: responseNameHash,
                    textTimestamp: Date.now(),
                    forceHideSearchList: false,
                });
            });
        });
    };

    onTextLabelResponseNameValueChange = (textLabelResponseNameValue: string) => {
        clearTimeout(this.state.textLabelResponseNameTimeoutId);
        let newTimeoutId = setTimeout(() => {
            this.state.textLabelResponseNameValue && this.responseSearch();
        }, 300);
        this.setState({
            forceHideSearchList: true,
            textLabelResponseNameValue,
            textLabelResponseNameTimeoutId: newTimeoutId,
        });
    };

    onFormOrTemplateResponseCreateOrUpdate = () => {
        const {response} = this.state;
        const {order} = response || {};
        const rHasIdOrLabelValueToAdd = this.state.response.nameMessage.id || this.state.textLabelResponseNameValue;
        if (!rHasIdOrLabelValueToAdd || !order) {
            return this.setState({errorMandatoryFields: true});
        } else {
            this.setState({errorMandatoryFields: false});
        }
        let jsonData = {...this.state.response};
        jsonData.questionId = this.props.question.id;
        jsonData.responseId = this.props.response.id || 0;
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                if (!this.state.response.nameMessage.id) {
                    apiBackOfficeUpdateLabels({
                        labelValue: this.state.textLabelResponseNameValue,
                        languageId: ((this.decodedJWT && this.decodedJWT.language) || '').toUpperCase(),
                    }).then(labelCreateResponse => {
                        if (labelCreateResponse.data) {
                            jsonData.nameMessage = {
                                id: labelCreateResponse.data,
                                value: this.state.textLabelResponseNameValue,
                                valueHTML: this.state.textLabelResponseNameValue,
                            };
                            this.setState(prev => ({
                                response: {
                                    ...prev.response,
                                    nameMessage: {
                                        id: labelCreateResponse.data,
                                        value: prev.textLabelResponseNameValue,
                                        valueHTML: prev.textLabelResponseNameValue,
                                    },
                                },
                            }));
                            this.requestFormOrTemplateResponseCreateOrUpdate(jsonData);
                        }
                    });
                } else {
                    this.requestFormOrTemplateResponseCreateOrUpdate(jsonData);
                }
            }
        );
    };

    onScoreChange = ({target: {value: score}}: React.ChangeEvent<HTMLInputElement>) => this.onFormDataChange({score});

    onOrderChange = ({target: {value: order}}: React.ChangeEvent<HTMLInputElement>) => this.onFormDataChange({order});

    onMappingCodeChange = ({target: {value: mappingCode}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({mappingCode});

    onJsonMappingChange = ({target: {value: jsonMapping}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({jsonMapping});

    onRemarkAllowedChange = () => this.onFormDataChange({remarkAllowed: !this.state.response.remarkAllowed});

    onRemarkMandatoryChange = () =>
        this.onFormDataChange({remarkMandatory: !this.state.response.remarkMandatory, remarkAllowed: 1});

    onFormDataChange = (valueObject: any) =>
        this.setState(prev => ({response: Object.assign({}, prev.response, valueObject)}));

    onTextLabelResponseNameSelectionChange = (textLabelResponseNameSelectedElements: any) => {
        let rId: any = null,
            rValue: any = null,
            rValueHTML: any = null;
        if (Object.keys(textLabelResponseNameSelectedElements || {}).length) {
            rId = parseInt(Object.keys(textLabelResponseNameSelectedElements)[0]);
            rValue = Object.values(textLabelResponseNameSelectedElements)[0];
            rValueHTML = Object.values(textLabelResponseNameSelectedElements)[0];
        }
        let resultLabelHTML = (
            (this.textLabelResponseNameResults || []).find((el: any) => el.labelId === parseInt(rId)) || {}
        ).valueHTML;
        this.setState(prev => ({
            ...prev,
            textLabelResponseNameSelectedElements,
            textLabelResponseNameValue: '',
            response: {
                ...prev.response,
                nameMessage: {
                    id: rId,
                    value: rValue,
                    valueHTML: resultLabelHTML || rValueHTML,
                },
            },
        }));
    };

    renderPopupElements(labelId: number | null, typeId: string, fieldIdKey: string, fieldHTMLKey: string) {
        const {responseId = null} = this.props;
        const responseIsInPreparation =
            this.props.response && this.props.response.status === RESPONSE_STATUS.IN_PREPARATION;
        const isNameField = fieldIdKey === 'nameMessage';
        const showEditActionsForName = isNameField && responseIsInPreparation;
        const elementsArr = [];
        (showEditActionsForName || !isNameField || responseId === 0) &&
            elementsArr.push({
                label: 'Search label',
                icon: faSearch,
                handler: () => this.showModalLabelSearch(typeId, fieldIdKey, fieldHTMLKey),
            });
        !labelId &&
            (!isNameField || showEditActionsForName) &&
            elementsArr.push({
                label: 'Add label',
                icon: faPlus,
                handler: () => this.showModalLabel(0, typeId, fieldIdKey, fieldHTMLKey),
            });
        labelId &&
            elementsArr.push({
                label: 'Show label',
                icon: faPencilAlt,
                handler: () => this.showModalLabel(labelId, typeId, fieldIdKey, fieldHTMLKey),
            });
        labelId &&
            (!isNameField || showEditActionsForName) &&
            elementsArr.push({
                label: 'Clear label',
                icon: faTimes,
                handler: () => this.clearLabelUse(fieldIdKey),
            });
        labelId &&
            elementsArr.push({
                label: 'Used in',
                icon: faLightbulb,
                handler: () => this.showModalUsedIn(labelId),
            });
        return elementsArr;
    }

    renderFooter = () => {
        const {response} = this.state || {};

        return this.state.loading === 0 ? (
            <>
                {this.state.errorMandatoryFields && <Error>Please fill the mandatory fields</Error>}
                <Button
                    icon={'close'}
                    clickAction={() => this.props.close(false)}
                    variation={'danger'}
                >{`Close`}</Button>
                {!response || (response && response.status !== RESPONSE_STATUS.TERMINATED) ? (
                    <Button
                        icon={'arrowRight'}
                        clickAction={this.onFormOrTemplateResponseCreateOrUpdate}
                        disabled={
                            this.props.group &&
                            this.props.group.isTemplate &&
                            this.props.mainScreen === 'backOfficeFormQuestions'
                        }
                    >{`Save`}</Button>
                ) : null}
            </>
        ) : (
            <LoadingBar />
        );
    };

    render() {
        const {response} = this.state || {};
        const {question} = this.props || {};
        const responseContextualMenuPopupElements = this.renderPopupElements(
            response && response.nameMessage && response.nameMessage.id,
            getLabelTypeId('Response'),
            'nameMessage',
            'remarklValueHTML'
        );
        const responseContextualMenuHasClearLabel = !!responseContextualMenuPopupElements.find(
            el => el.label === 'Clear label'
        );

        return (
            <>
                {this.state.modalConfirmClose ? (
                    <ModalConfirmVersion2
                        title={'Element modified'}
                        message={'Are you sure you want to validate your modifications?'}
                        buttonName={'Yes'}
                        action={() => this.props.close(true)}
                        close={this.closeModalConfirmClose}
                    />
                ) : null}
                <section>
                    <main
                        style={{
                            maxHeight: `calc(100vh - 350px)`,
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            marginRight: -25,
                            marginBottom: 30,
                        }}
                    >
                        <div
                            style={{
                                marginBottom: 25,
                                zIndex: 1056,
                            }}
                        >
                            <DataSheetFormSection title={'Response fields'} disableToggle={true}>
                                <div
                                    className={cx(
                                        styles.textLabelResponseNameSearchWrap,
                                        responseContextualMenuHasClearLabel && styles.contextualMenuHasClearLabel
                                    )}
                                >
                                    <DataSheetFormFields
                                        label={responseContextualMenuHasClearLabel ? 'Name' : ''}
                                        mandatory={responseContextualMenuHasClearLabel}
                                        html={
                                            responseContextualMenuHasClearLabel
                                                ? renderHTML(
                                                      response &&
                                                          response.nameMessage &&
                                                          (response.nameMessage.valueHTML || response.nameMessage.value)
                                                  )
                                                : null
                                        }
                                        loading={this.state.loading}
                                        double={true}
                                        containerClassName="popover-nested"
                                        labelIcon={faList}
                                        labelPopup={this.renderPopupElements(
                                            response && response.nameMessage && response.nameMessage.id,
                                            getLabelTypeId('Response'),
                                            'nameMessage',
                                            'remarklValueHTML'
                                        )}
                                        disabled={
                                            (response && response.status === RESPONSE_STATUS.TERMINATED) ||
                                            this.state.loading !== 0
                                        }
                                    />
                                    {!responseContextualMenuHasClearLabel && (
                                        <TextLabelInput
                                            double={true}
                                            onSelectionChange={this.onTextLabelResponseNameSelectionChange}
                                            onChange={this.onTextLabelResponseNameValueChange}
                                            value={this.state.textLabelResponseNameValue}
                                            selectedElements={this.state.textLabelResponseNameSelectedElements}
                                            noInfo={true}
                                            multiple={false}
                                            filterContains={true}
                                            mandatory={true}
                                            label={'Name'}
                                            listByIdName={this.state.textLabelResponseNameListByIdName}
                                            nameHash={this.state.textLabelResponseNameNameHash}
                                            uniqueValue={true}
                                            buttonIcon={this.state.textLabelResponseNameSearchLoading && faSpinner}
                                            timestamp={this.state.textTimestamp}
                                            autoSelection={true}
                                            forceHideSearchList={this.state.forceHideSearchList}
                                            hideRemoveElementButton={
                                                this.props.responseId !== 0 &&
                                                response &&
                                                response.status !== RESPONSE_STATUS.IN_PREPARATION
                                            }
                                            disabled={
                                                this.props.responseId !== 0 &&
                                                response &&
                                                response.status !== RESPONSE_STATUS.IN_PREPARATION
                                            }
                                        />
                                    )}
                                </div>

                                <DataSheetFormFields
                                    label={'Remark'}
                                    html={renderHTML(
                                        response && response.remarkMessage && response.remarkMessage.value
                                    )}
                                    loading={this.state.loading}
                                    double={true}
                                    containerClassName="popover-nested"
                                    labelIcon={faList}
                                    labelPopup={this.renderPopupElements(
                                        response && response.remarkMessage && response.remarkMessage.id,
                                        getLabelTypeId('Remark'),
                                        'remarkMessage',
                                        'remarklValueHTML'
                                    )}
                                    disabled={
                                        (response && response.status === RESPONSE_STATUS.TERMINATED) ||
                                        this.state.loading !== 0
                                    }
                                />
                                <DataSheetFormFields
                                    label={'Help'}
                                    html={renderHTML(response && response.helpMessage && response.helpMessage.value)}
                                    loading={this.state.loading}
                                    double={true}
                                    containerClassName="popover-nested"
                                    labelIcon={faList}
                                    labelPopup={this.renderPopupElements(
                                        response && response.helpMessage && response.helpMessage.id,
                                        getLabelTypeId('Help'),
                                        'helpMessage',
                                        'helplValueHTML'
                                    )}
                                    disabled={
                                        (response && response.status === RESPONSE_STATUS.TERMINATED) ||
                                        this.state.loading !== 0
                                    }
                                />
                            </DataSheetFormSection>
                        </div>
                        <div>
                            <TextInput label={`Response Id`} value={response && response.idElement} disabled={true} />
                            <TextInput
                                label={`Sub-order`}
                                value={response && response.order}
                                onChange={this.onOrderChange}
                                mandatory={true}
                                disabled={
                                    (response && response.status !== RESPONSE_STATUS.IN_PREPARATION) ||
                                    this.state.loading !== 0
                                }
                            />
                            <TextInput label={`Office`} value={response && OFFICES[response.office]} disabled={true} />
                            <TextInput
                                label={`Question Id`}
                                required={true}
                                value={question && question.id}
                                mandatory={true}
                                disabled={true}
                            />
                            <TextInput
                                label={`Default score`}
                                value={response && response.score}
                                type={`number`}
                                onChange={this.onScoreChange}
                                disabled={
                                    (response && response.status !== RESPONSE_STATUS.IN_PREPARATION) ||
                                    this.state.loading !== 0 ||
                                    (question && question.type !== 'radio' && question.type !== 'checkbox')
                                }
                            />
                            <TextInput
                                label={`Question message`}
                                value={question && question.message}
                                disabled={true}
                            />
                            <TextInput
                                label={`Mapping code`}
                                value={response && response.mappingCode}
                                onChange={this.onMappingCodeChange}
                                disabled={
                                    (response && response.status === RESPONSE_STATUS.TERMINATED) ||
                                    this.state.loading !== 0
                                }
                            />
                            {this.administrator === true && (
                                <TextInput
                                    label={`JSON Mapping`}
                                    required={true}
                                    value={response && response.jsonMapping}
                                    onChange={this.onJsonMappingChange}
                                    double={true}
                                    disabled={
                                        (response && response.status === RESPONSE_STATUS.TERMINATED) ||
                                        this.state.loading !== 0
                                    }
                                />
                            )}
                        </div>
                        <div style={{float: 'left', width: '100%'}}>
                            <Checkbox
                                clickAction={this.onRemarkAllowedChange}
                                simple={true}
                                label={`Remark allowed`}
                                value={response && response.remarkAllowed}
                                disabled={
                                    !!(response && response.remarkMandatory) ||
                                    (response && response.status === RESPONSE_STATUS.TERMINATED) ||
                                    (question && question.type !== 'radio' && question.type !== 'checkbox') ||
                                    this.state.loading !== 0
                                }
                                // popOverText={`Available for question types Single / Multi choice`}
                            />
                            <Checkbox
                                clickAction={this.onRemarkMandatoryChange}
                                simple={true}
                                label={`Remark mandatory`}
                                value={response && response.remarkMandatory}
                                disabled={
                                    (response && response.status === RESPONSE_STATUS.TERMINATED) ||
                                    (question && question.type !== 'radio' && question.type !== 'checkbox') ||
                                    this.state.loading !== 0
                                }
                            />
                            <div style={{clear: 'both'}} />
                            {(question && question.type !== 'radio') || question.type !== 'checkbox' ? (
                                <div
                                    style={{
                                        color: 'rgb(138, 109, 59)',
                                        fontStyle: 'italic',
                                        marginBottom: 10,
                                        marginTop: 10,
                                    }}
                                >
                                    Default score and remark options available only for question types: Single-choice /
                                    Multi-choice
                                </div>
                            ) : null}
                        </div>
                        <div style={{clear: 'both'}} />
                        <div style={{float: 'left', fontStyle: 'italic'}}>
                            {this.props.group &&
                            this.props.group.isTemplate &&
                            this.props.mainScreen === 'backOfficeFormQuestions' ? (
                                <div style={{color: 'rgb(138, 109, 59)', fontStyle: 'italic', marginBottom: 0}}>
                                    {`Template Response modification is only available in templates screen`}
                                </div>
                            ) : (
                                <p style={{color: '#8a6d3b', marginBottom: 0}}>
                                    Note: required fields are marked with an asterisk (*)
                                </p>
                            )}
                            {response && response.user && response.modifDate && (
                                <p style={{color: '#31708f', marginTop: 5}}>
                                    Last update: {response.modifDate} by {response.user}
                                </p>
                            )}
                        </div>
                        <div style={{clear: 'both'}} />
                    </main>
                </section>
            </>
        );
    }
}

export default injectIntl(ResponseModalDetailsGeneralInformation);
