import React from 'react';
import CustomTable from '~components/CustomTable';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import ModalLogicTypeForceQuestion from '../questionsLogicModals/ModalLogicTypeForceQuestion';
import ModalLogicTypeForbiddenResponse from '../questionsLogicModals/ModalLogicTypeForbiddenResponse';
import ModalLogicTypeShowMeLinkToAFResponseQuestion from '../questionsLogicModals/ModalLogicTypeShowMeLinkToAFResponseQuestion';
import ModalLogicTypeResponseMandatoryOnFirstOccurrenceOfGroup from '../questionsLogicModals/ModalLogicTypeResponseMandatoryOnFirstOccurrenceOfGroup';
import ModalLogicTypeShowResponseOnTheFirstsXOccurrenceOfGroup from '../questionsLogicModals/ModalLogicTypeShowResponseOnTheFirstsXOccurrenceOfGroup';
import ModalLogicTypeDefaultValueForConfidentialOption from '../questionsLogicModals/ModalLogicTypeDefaultValueForConfidentialOption';
import ModalLogicTypeApplySameConfidentialValueToOtherQuestions from '../questionsLogicModals/ModalLogicTypeApplySameConfidentialValueToOtherQuestions';
import ModalLogicTypeForceQuestionHelp from '../questionsLogicModals/ModalLogicTypeForceQuestionHelp';
import ModalLogicTypeForbiddenResponseHelp from '../questionsLogicModals/ModalLogicTypeForbiddenResponseHelp';
import ModalLogicTypeShowMeLinkToAFResponseQuestionHelp from '../questionsLogicModals/ModalLogicTypeShowMeLinkToAFResponseQuestionHelp';
import ModalLogicTypeResponseMandatoryOnFirstOccurrenceOfGroupHelp from '../questionsLogicModals/ModalLogicTypeResponseMandatoryOnFirstOccurrenceOfGroupHelp';
import ModalLogicTypeShowResponseOnTheFirstsXOccurrenceOfGroupHelp from '../questionsLogicModals/ModalLogicTypeShowResponseOnTheFirstsXOccurrenceOfGroupHelp';
import ModalLogicTypeDefaultValueForConfidentialOptionHelp from '../questionsLogicModals/ModalLogicTypeDefaultValueForConfidentialOptionHelp';
import ModalLogicTypeApplySameConfidentialValueToOtherQuestionsHelp from '../questionsLogicModals/ModalLogicTypeApplySameConfidentialValueToOtherQuestionsHelp';
import QuestionModalDetailsLogicsActions from './QuestionModalDetailsLogicsActions';
import QuestionModalDetailsLogicsElements from './QuestionModalDetailsLogicsElements.json';
import SelectInput from '~components/SelectInput';
import {
    apiBackOfficeFormQuestionLogicCreateOrUpdate,
    apiBackOfficeFormQuestionLogicTemplateCreateOrUpdate,
    apiBackOfficeQuestionLogicDelete,
    apiBackOfficeQuestionLogicTemplateDelete,
} from './QuestionModalDetailsLogicsService';
import {faInfo, faPlus} from '@fortawesome/free-solid-svg-icons';
import {htmlToText} from '~utils/index';
import {injectIntl} from 'react-intl';
import {IGroup, IGroupQuestion, IGroupQuestionLogic} from '../../../types';
import {ModalConfirmVersion2} from '../../../commonModals';
import {LoadingBar} from '../../../componentsLayout';

export const LOGIC_TYPES = {
    FORCE_QUESTION: 137,
    FORBIDDEN_RESPONSE: 138,
    SHOW_ME_LINK_TO_AF_RESPONSE_QUESTION: 175,
    RESPONSE_MANDATORY_ON_FIRST_OCCURENCE_OF_GROUP: 215,
    SHOW_RESPONSE_ON_THE_FIRSTS_X_OCCURENCE_OF_GROUP: 216,
    DEFAULT_VALUE_FOR_CONFIDENTIAL_OPTION: 235,
    APPLY_SAME_CONFIDENTIAL_VALUE_TO_OTHER_QUESTIONS: 255,
};

const LOGIC_OPERATION = {
    ADD: 'ADD',
    EDIT: 'EDIT',
};

interface IListOption {
    id: string;
    libelle: string;
    name: string;
    type: string;
    defaultParam: string | null;
    message: string;
    messageHelp: string;
    applyOn: string | null;
    allowMultipleOccurrence: string;
}

interface IProps {
    formId?: number | null;
    intl: any;
    loadFormQuestionsJSONs: any;
    mainScreen: any;
    mock: any;
    modalQueuePop: any;
    modalQueuePush: any;
    responseId: any;
    steps: IGroup[];
    templateId: any;
    question?: IGroupQuestion;
    questionId: number;
    setRenderFooter?: any;
}

interface IState {
    loading: number;
    timestamp: number;
    modalLogic: any;
    modalConfirmDeletion: any;
    selectedLogicTypeId: any;
}

class QuestionModalDetailsLogics extends React.Component<IProps, IState> {
    logicTypesHash: {[key: string]: IListOption} = {};
    listOptions: IListOption[] = [];

    constructor(props: IProps) {
        super(props);
        this.listOptions = QuestionModalDetailsLogicsElements.types.filter(
            type =>
                ![
                    LOGIC_TYPES.FORCE_QUESTION,
                    LOGIC_TYPES.FORBIDDEN_RESPONSE,
                    LOGIC_TYPES.SHOW_ME_LINK_TO_AF_RESPONSE_QUESTION,
                ].includes(parseInt(type.id))
        );

        this.state = {
            loading: 0,
            timestamp: Date.now(),
            modalLogic: null,
            modalConfirmDeletion: null,
            selectedLogicTypeId: LOGIC_TYPES.FORCE_QUESTION,
        };
        QuestionModalDetailsLogicsElements.types.forEach(logic => (this.logicTypesHash[logic.id] = logic));
    }

    componentDidMount() {
        this.props.setRenderFooter && this.props.setRenderFooter(null);
        if (Object.values(LOGIC_TYPES).indexOf(this.props.mock) !== -1) {
            this.openModalLogic(this.props.mock);
        }
    }

    confirmLogicDeletion = (logicId: number) => this.setState({modalConfirmDeletion: logicId});

    closeModalConfirmDeletion = () => this.setState({modalConfirmDeletion: null});

    openModalLogic = (logicId: number, logicOperation?: string) => {
        logicId = parseInt(`${logicId}`);
        const responseLogicValues = ((this.props.question && this.props.question.logics) || []).find(
            (el: any) => el.id === logicId
        );
        const responseLogicType = this.logicTypesHash[logicId];
        const modalProps = {
            responseLogicValues,
            responseLogicType,
            onCreateOrUpdateLogicType: this.onFormOrTemplateQuestionLogicCreateOrUpdate,
            close: this.closeModalLogic,
            iteration: logicOperation === LOGIC_OPERATION.ADD ? 0 : (responseLogicValues || {}).iteration,
        };
        const modalExtraProps = {
            formId: this.props.formId,
            questionId: this.props.questionId,
            responseId: this.props.responseId,
            modalQueuePush: this.props.modalQueuePush,
            modalQueuePop: this.props.modalQueuePop,
            steps: this.props.steps,
        };
        let theModal;
        switch (logicId) {
            case LOGIC_TYPES.FORCE_QUESTION:
                theModal = <ModalLogicTypeForceQuestion {...modalProps} {...modalExtraProps} />;
                break;
            case LOGIC_TYPES.FORBIDDEN_RESPONSE:
                theModal = <ModalLogicTypeForbiddenResponse {...modalProps} {...modalExtraProps} />;
                break;
            case LOGIC_TYPES.SHOW_ME_LINK_TO_AF_RESPONSE_QUESTION:
                theModal = <ModalLogicTypeShowMeLinkToAFResponseQuestion {...modalProps} />;
                break;
            case LOGIC_TYPES.RESPONSE_MANDATORY_ON_FIRST_OCCURENCE_OF_GROUP:
                theModal = <ModalLogicTypeResponseMandatoryOnFirstOccurrenceOfGroup {...modalProps} />;
                break;
            case LOGIC_TYPES.SHOW_RESPONSE_ON_THE_FIRSTS_X_OCCURENCE_OF_GROUP:
                theModal = <ModalLogicTypeShowResponseOnTheFirstsXOccurrenceOfGroup {...modalProps} />;
                break;
            case LOGIC_TYPES.DEFAULT_VALUE_FOR_CONFIDENTIAL_OPTION:
                theModal = <ModalLogicTypeDefaultValueForConfidentialOption {...modalProps} />;
                break;
            case LOGIC_TYPES.APPLY_SAME_CONFIDENTIAL_VALUE_TO_OTHER_QUESTIONS:
                theModal = (
                    <ModalLogicTypeApplySameConfidentialValueToOtherQuestions {...modalProps} {...modalExtraProps} />
                );
                break;
            default:
                break;
        }
        this.props.modalQueuePush(theModal);
        this.setState({modalLogic: logicId});
    };

    closeModalLogic = () => {
        this.props.modalQueuePop();
        this.setState({modalLogic: null});
    };

    updateTimestamp = () => this.setState(prev => ({loading: prev.loading - 1, timestamp: Date.now()}));

    closeModalLogicHelp = () => this.props.modalQueuePop();

    addRemarkIfNeeded = (logic: IGroupQuestionLogic) => {
        if (logic.questionIdDest) {
            let tableSourceRowRemark = (
                []
                    .concat(...(this.props.steps || []).map((step: any) => step.questions))
                    .map((question: any) => ({
                        id: question.id,
                        libelle: `${question.order} - ${htmlToText(question.message)}`,
                        remark: question.order,
                    }))
                    .find((el: any) => el.id === logic.questionIdDest) || {}
            ).libelle;
            return {
                ...logic,
                remark: tableSourceRowRemark,
            };
        }
        return logic;
    };

    prepareTableSource = () =>
        ((this.props.question && this.props.question.logics) || []).map((logic: IGroupQuestionLogic) =>
            this.addRemarkIfNeeded(logic)
        );

    onSelectInputTypeChange = ({target: {value: selectedLogicTypeId}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.setState({selectedLogicTypeId});

    onFormOrTemplateQuestionLogicDeleteAction = () => {
        const logicId = this.state.modalConfirmDeletion;
        let logicToDelete = ((this.props.question && this.props.question.logics) || []).find(el => el.id === logicId);
        let {iteration: logicIteration} = logicToDelete || {};
        this.setState(
            prev => ({loading: prev.loading + 1, modalConfirmDeletion: null}),
            () => {
                (this.props.mainScreen && this.props.mainScreen === 'templateQuestions'
                    ? apiBackOfficeQuestionLogicTemplateDelete({
                          questionId: (this.props.question && this.props.question.id) || 0,
                          logicId,
                          iteration: logicIteration || 0,
                          templateId: this.props.templateId,
                      })
                    : apiBackOfficeQuestionLogicDelete({
                          formId: this.props.formId || 0,
                          questionId: (this.props.question && this.props.question.id) || 0,
                          logicId,
                          iteration: logicIteration || 0,
                      })
                )
                    .then(jsonResponse => {
                        jsonResponse &&
                            jsonResponse.data === 'OK' &&
                            this.props.loadFormQuestionsJSONs &&
                            this.props.loadFormQuestionsJSONs(null, this.updateTimestamp, null, true);
                    })
                    .catch((error: any) => {
                        ERROR([`QuestionModalDetailsLogics: ${error.message}`]);
                    });
            }
        );
    };

    onFormOrTemplateQuestionLogicCreateOrUpdate = ({
        param,
        applyToRemark,
        responseIdDest,
        questionIdDest,
        messageId,
        iteration, // this defines if its new (iteration = 0) or update
    }: any) => {
        const logicId = this.state.modalLogic;
        if (this.props.mainScreen && this.props.mainScreen === 'templateQuestions') {
            this.setState(
                prev => ({loading: prev.loading + 1, modalLogic: null}),
                () => {
                    this.props.modalQueuePop();
                    apiBackOfficeFormQuestionLogicTemplateCreateOrUpdate({
                        questionId: this.props.questionId,
                        logicId,
                        templateId: this.props.templateId,
                        logicParam: param ? param : null,
                        questionIdDest: questionIdDest ? parseInt(questionIdDest) : null,
                        responseIdDest: responseIdDest ? parseInt(responseIdDest) : null,
                        applyToRemark,
                        messageId: messageId ? messageId : null,
                        root: null,
                        iteration,
                    })
                        .then((jsonResponse: any) => {
                            jsonResponse &&
                                jsonResponse.data === 'OK' &&
                                this.props.loadFormQuestionsJSONs &&
                                this.props.loadFormQuestionsJSONs(null, this.updateTimestamp);
                        })
                        .catch((error: any) => {
                            ERROR([`Wizard QuestionModalDetailsLogics: ${error.message}`]);
                        });
                }
            );
        } else {
            this.setState(
                prev => ({loading: prev.loading + 1, modalLogic: null}),
                () => {
                    this.props.modalQueuePop();
                    apiBackOfficeFormQuestionLogicCreateOrUpdate({
                        formId: this.props.formId || 0,
                        questionId: this.props.questionId,
                        logicId,
                        logicParam: param ? param : null,
                        questionIdDest: questionIdDest ? parseInt(questionIdDest) : null,
                        responseIdDest: responseIdDest ? parseInt(responseIdDest) : null,
                        applyToRemark,
                        messageId: messageId ? messageId : null,
                        root: null,
                        iteration,
                    })
                        .then((jsonResponse: any) => {
                            jsonResponse &&
                                jsonResponse.data === 'OK' &&
                                this.props.loadFormQuestionsJSONs &&
                                this.props.loadFormQuestionsJSONs(null, this.updateTimestamp, null, true);
                        })
                        .catch((error: any) => {
                            ERROR([`Wizard QuestionModalDetailsLogics: ${error.message}`]);
                        });
                }
            );
        }
    };

    onInfoIconClick = () => {
        const {selectedLogicTypeId} = this.state;
        const responseLogicType = this.logicTypesHash[selectedLogicTypeId];
        const modalProps = {
            responseLogicType,
            close: this.closeModalLogicHelp,
        };
        let theModalHelp;
        switch (parseInt(selectedLogicTypeId)) {
            case LOGIC_TYPES.FORCE_QUESTION:
                theModalHelp = <ModalLogicTypeForceQuestionHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.FORBIDDEN_RESPONSE:
                theModalHelp = <ModalLogicTypeForbiddenResponseHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.SHOW_ME_LINK_TO_AF_RESPONSE_QUESTION:
                theModalHelp = <ModalLogicTypeShowMeLinkToAFResponseQuestionHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.RESPONSE_MANDATORY_ON_FIRST_OCCURENCE_OF_GROUP:
                theModalHelp = <ModalLogicTypeResponseMandatoryOnFirstOccurrenceOfGroupHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.SHOW_RESPONSE_ON_THE_FIRSTS_X_OCCURENCE_OF_GROUP:
                theModalHelp = <ModalLogicTypeShowResponseOnTheFirstsXOccurrenceOfGroupHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.DEFAULT_VALUE_FOR_CONFIDENTIAL_OPTION:
                theModalHelp = <ModalLogicTypeDefaultValueForConfidentialOptionHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.APPLY_SAME_CONFIDENTIAL_VALUE_TO_OTHER_QUESTIONS:
                theModalHelp = <ModalLogicTypeApplySameConfidentialValueToOtherQuestionsHelp {...modalProps} />;
                break;
            default:
                break;
        }
        this.props.modalQueuePush(theModalHelp);
    };

    render() {
        const actions = QuestionModalDetailsLogicsActions(
            this.openModalLogic,
            this.confirmLogicDeletion,
            this.props.mainScreen
        );
        const isAddLogicButtonEnabled =
            ((this.props.question && this.props.question.logics) || []).filter(
                (el: any) => el.id === this.state.selectedLogicTypeId
            ).length === 0;
        return (
            <>
                {this.state.loading !== 0 && <LoadingBar />}
                {this.state.modalConfirmDeletion && (
                    <ModalConfirmVersion2
                        title={'Confirm deletion'}
                        message={'Are you sure you want to delete this logic type?'}
                        buttonName={'Yes'}
                        action={this.onFormOrTemplateQuestionLogicDeleteAction}
                        close={this.closeModalConfirmDeletion}
                    />
                )}
                <section style={{visibility: this.state.loading === 0 ? 'visible' : 'hidden'}}>
                    <main
                        style={{
                            maxHeight: `calc(100vh - 350px)`,
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            marginRight: -25,
                            marginBottom: 30,
                        }}
                    >
                        <div>
                            <SelectInput
                                label={`Type`}
                                value={this.state.selectedLogicTypeId}
                                notAll={true}
                                double={true}
                                onChange={this.onSelectInputTypeChange}
                                list={this.listOptions.map((el: any) => ({
                                    id: el.id,
                                    value: el.libelle,
                                }))}
                                buttonIcon={faInfo}
                                buttonAction={this.onInfoIconClick}
                            />
                            <div style={{clear: 'both'}} />
                            <div style={{float: 'left'}}>
                                <FormFooterButton
                                    color={buttonColor.green}
                                    icon={faPlus}
                                    clickAction={() =>
                                        this.openModalLogic(this.state.selectedLogicTypeId, LOGIC_OPERATION.ADD)
                                    }
                                    disabled={!isAddLogicButtonEnabled}
                                >{`Add logic`}</FormFooterButton>
                            </div>
                            <div style={{clear: 'both'}} />
                            {this.props.question && this.props.question.logics && (
                                <CustomTable
                                    {...this.props}
                                    tableName={'questionLogics'}
                                    tableType={'OBJECT'}
                                    tableSource={this.prepareTableSource()}
                                    id={'type'}
                                    resultFieldsDefault={['type', 'name', 'logicParam', 'remark']}
                                    intl={this.props.intl}
                                    hideExcelButton={true}
                                    timestamp={this.state.timestamp}
                                    sortFunctions={{}}
                                    notSortable={true}
                                    unlimited={true}
                                    actions={actions}
                                />
                            )}
                            <div style={{clear: 'both'}} />
                        </div>
                    </main>
                </section>
            </>
        );
    }
}

export default injectIntl(QuestionModalDetailsLogics);
