import React from 'react';

// FONTS & ICONS
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const AdminIcon = () => {
    return <FontAwesomeIcon icon={faUser as any} color={'red'} className={'AdminIcon'} style={{marginLeft: 5}} />;
};

export default AdminIcon;
