import {API_SERVERLESS, getFetch} from '~commonApi';

export interface IApplicationDetails {
    applicationNumber: string;
    applicationDate: string;
    denomination: string;
    countryIdInsideEU: string | null;
    firstCommercializationInsideEUDate: string | null;
    countryIdOutsideEU: string | null;
    firstCommercializationOutsideEUDate: string | null;
    arrivalDate: string;
    nationalOfficeDate: string | null;
    groupVarietal: string;
    reference: string;
    nationalOfficeReference: string | null;
    onlineId: string | null;
    withdrawalDate: string | null;
    rejection: string | null;
    rejectionDate: string | null;
    rejectionReason: string | null;
    title: number;
    titleStartDate: string;
    titleMaxEndDate: string;
    titleEndDate: string;
    endProtectionStatus: string;
    specieId: string;
    specieName: string;
    specieUPOVId: string;
    appealStatus: string;
    pubWebDocRegister: number;
    applicationStatus: string;
    expirationDate: string;
    applicants: string;
}

export interface IApplicationDenominationItem {
    denomination: string;
    status: string;
    applicationDate: string | null;
    publicationDate: string;
}

export function apiMyApplicationsApplicationDetails(applicationNumber: string): Promise<{data: IApplicationDetails}> {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/myApplicationsApplicationDetails/${applicationNumber}`, true);
}

export function apiMyApplicationsDenomination(
    applicationNumber: string
): Promise<{data: IApplicationDenominationItem[]}> {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/myApplicationsDenominations/${applicationNumber}`, true);
}
