import {
    checkIfQuestionHasForceResponseError,
    checkIfQuestionHasMandatoryErrorFn,
    checkIfQuestionHasNumberOfResponsesAllowedError,
    checkIfQuestionHasSuccessiveScoreOnlyError,
    checkIfQuestionIsHiddenFn,
    checkIfQuestionIsMandatoryInCurrentGroupIteration,
    checkIfQuestionHasUploadTypeMustBeDefinedError,
    getQuestionKeyWithIteration,
} from './questions/utils';
import {getResponseKeyWithIteration, isValidDate, isValidText} from './responses/utils';
import {IAPIResponsesClient, IGroup, IStateResponsesClient} from '../../types';

export function getGroupKeyWithIteration({
    group,
    groupIteration,
}: {
    group?: {
        id?: number;
        templateId?: number | null;
        isTemplate?: boolean;
    };
    groupIteration?: number;
}) {
    if (!group) return `Undefined`;
    if (group.isTemplate) {
        return `T${group.templateId}_${groupIteration || 1}`;
    } else {
        return `GR${group.id}_${groupIteration || 1}`;
    }
}

export function getGroupsIdsIterationsMap(groupsQuestion: IGroup[], apiResponsesClient: IAPIResponsesClient[]) {
    let groupsIdsIterationsMap: any = {};
    (groupsQuestion || [])
        // .filter(group => group.multi)
        .forEach(group => {
            let currentGroups = (apiResponsesClient || []).filter(apiResponseClient => {
                return group.isTemplate
                    ? apiResponseClient.idTemplate === group.templateId
                    : apiResponseClient.idGroupQuestion === group.id;
            });
            let maxGroupIteration =
                currentGroups && currentGroups.length > 0
                    ? Math.max(...currentGroups.map(el => parseInt(el.iterationGroup)))
                    : 1;
            groupsIdsIterationsMap[getGroupOrTemplateKeyForIterationMap(group)] = maxGroupIteration;
        });
    return groupsIdsIterationsMap;
}

export function getTableQuestionsKeysIterationsMap(
    groupsQuestion: IGroup[],
    apiResponsesClient?: IAPIResponsesClient[]
) {
    let questionsTypeTable: string[] = [];
    (groupsQuestion || []).forEach(group =>
        questionsTypeTable.push(
            ...(group.questions || [])
                .filter(question => question.type === 'table')
                .map(question => `${getGroupKeyWithIteration({group})}_Q${question.id}`)
        )
    );
    let tableQuestionsKeysIterationsMap: any = {};
    questionsTypeTable.forEach(questionKeyWithIteration => {
        let questionKeyIteration = 1;
        if (apiResponsesClient && apiResponsesClient.length) {
            let apiResponseClientQuestion = apiResponsesClient.find(apiResponseClient => {
                let groupOrTemplateId: any = questionKeyWithIteration.split('_')[0];
                groupOrTemplateId = parseInt(groupOrTemplateId.replace('GR', '').replace('T', ''));
                let questionId: any = parseInt((questionKeyWithIteration.split('_').pop() || '').replace('Q', ''));
                return (
                    (parseInt(String(apiResponseClient.idGroupQuestion)) === groupOrTemplateId ||
                        parseInt(apiResponseClient.idTemplate) === groupOrTemplateId) &&
                    parseInt(String(apiResponseClient.idQuestion)) === questionId
                );
            });
            apiResponseClientQuestion &&
                (questionKeyIteration =
                    Math.max(...apiResponseClientQuestion.responses.map(el => parseInt(String(el.iteration)))) || 1);
        }
        tableQuestionsKeysIterationsMap[questionKeyWithIteration] = questionKeyIteration;
    });
    return tableQuestionsKeysIterationsMap;
}

export function getValidatedSteps({
    groupsQuestion,
    groupsIdsIterationsMap,
    tableQuestionsKeysIterationsMap,
    responsesClient,
}: {
    groupsQuestion: IGroup[];
    groupsIdsIterationsMap: any;
    tableQuestionsKeysIterationsMap: any;
    responsesClient: IStateResponsesClient;
}) {
    let validatedSteps: boolean[] = [];
    (groupsQuestion || [])
        .filter(i => i.isGroup)
        .forEach(group => {
            let isStepValidated = true;
            Array(groupsIdsIterationsMap[getGroupOrTemplateKeyForIterationMap(group)])
                .fill(true)
                .map((_, groupIndex) =>
                    (group.questions || []).forEach(question => {
                        if (isStepValidated) {
                            let groupKeyWithIteration = getGroupKeyWithIteration({
                                group,
                                groupIteration: groupIndex + 1,
                            });
                            let isQuestionHidden = checkIfQuestionIsHiddenFn({
                                steps: groupsQuestion,
                                question,
                                responsesClient,
                                groupIteration: groupIndex + 1,
                            }).hidden;
                            // QUESTION IS NOT HIDDEN CHECK (because of showquestion response logic type)
                            if (!isQuestionHidden) {
                                let questionKeyWithIteration = getQuestionKeyWithIteration({
                                    groupKeyWithIteration,
                                    question,
                                });
                                let questionHasMandatoryError = checkIfQuestionHasMandatoryErrorFn({
                                    question,
                                    responsesClient: responsesClient,
                                    groupIteration: groupIndex + 1,
                                    questionKeyWithIteration,
                                    questionsCounter:
                                        tableQuestionsKeysIterationsMap[`${groupKeyWithIteration}_Q${question.id}`] ||
                                        1,
                                    steps: groupsQuestion.filter(i => i.isGroup),
                                });
                                let questionHasForceResponseError = checkIfQuestionHasForceResponseError({
                                    steps: groupsQuestion.filter(i => i.isGroup),
                                    destinationQuestion: question,
                                    responsesClient: responsesClient,
                                    questionKeyWithIteration,
                                });
                                let questionHasSuccessiveScoreOnlyError = checkIfQuestionHasSuccessiveScoreOnlyError({
                                    question,
                                    responsesClient,
                                    questionKeyWithIteration,
                                });
                                let questionHasNumberOfResponsesAllowedError =
                                    checkIfQuestionHasNumberOfResponsesAllowedError({
                                        question,
                                        responsesClient,
                                        questionKeyWithIteration,
                                    });

                                let questionHasUploadTypeMustBeDefinedError =
                                    checkIfQuestionHasUploadTypeMustBeDefinedError({
                                        question,
                                        responsesClient,
                                        questionKeyWithIteration,
                                    });

                                // QUESTION MANDATORY CHECK OR FORCE RESPONSE CHECK OR SUCCESSIVE SCORE ONLY CHECK
                                // OR NUMBER OF RESPONSES ALLOWED CHECK
                                if (
                                    questionHasMandatoryError ||
                                    questionHasForceResponseError ||
                                    questionHasSuccessiveScoreOnlyError ||
                                    questionHasNumberOfResponsesAllowedError ||
                                    questionHasUploadTypeMustBeDefinedError
                                ) {
                                    isStepValidated = false;
                                }

                                // REMARK MANDATORY CHECK
                                isStepValidated &&
                                    (question.type === 'checkbox' || question.type === 'radio') &&
                                    question.responses
                                        .filter(response => response.remarkMandatory)
                                        .forEach(response => {
                                            let responseKeyWithIteration = getResponseKeyWithIteration({
                                                questionKeyWithIteration,
                                                response,
                                            });
                                            let responseRemarkKeyWithIteration = `ra${responseKeyWithIteration}`;
                                            if (question.type === 'radio') {
                                                if (
                                                    responsesClient[questionKeyWithIteration] ===
                                                        responseKeyWithIteration &&
                                                    !responsesClient[responseRemarkKeyWithIteration]
                                                ) {
                                                    isStepValidated = false;
                                                }
                                            }
                                            if (question.type === 'checkbox') {
                                                if (
                                                    responsesClient[responseKeyWithIteration] &&
                                                    !responsesClient[responseRemarkKeyWithIteration]
                                                ) {
                                                    isStepValidated = false;
                                                }
                                            }
                                        });

                                // RESPONSE VALID TEXT CHECK
                                isStepValidated &&
                                    question.responses.forEach(response => {
                                        let responseKeyWithIteration = getResponseKeyWithIteration({
                                            questionKeyWithIteration,
                                            response,
                                        });
                                        let responseAnswer = responsesClient[responseKeyWithIteration];
                                        let responseHasDateLogicType = response.logics.find(
                                            logic => logic.type === 'date'
                                        );
                                        if (question.type === 'text') {
                                            let responseHasOptionalLogicType = response.logics.find(
                                                logic => logic.type === 'optional'
                                            );
                                            let responseHasHiddenLogicType = response.logics.find(
                                                logic => logic.type === 'hidden'
                                            );
                                            let questionIsMandatory = checkIfQuestionIsMandatoryInCurrentGroupIteration(
                                                {
                                                    question,
                                                    groupIteration: groupIndex + 1,
                                                    steps: groupsQuestion.filter(i => i.isGroup),
                                                    responsesClient,
                                                }
                                            );
                                            let mustHaveAnswer =
                                                questionIsMandatory &&
                                                !responseHasOptionalLogicType &&
                                                !responseHasHiddenLogicType;
                                            if (
                                                (mustHaveAnswer ? true : responseAnswer) &&
                                                !isValidText({response, responseAnswer})
                                            ) {
                                                isStepValidated = false;
                                            }
                                        }
                                        if (
                                            responseHasDateLogicType &&
                                            responseAnswer &&
                                            !isValidDate(responseAnswer)
                                        ) {
                                            isStepValidated = false;
                                        }
                                    });
                            }
                        }
                    })
                );
            validatedSteps.push(isStepValidated);
        });
    return validatedSteps;
}

export function getGroupOrTemplateKeyForIterationMap(group: IGroup) {
    return `${group.order}_${group.isTemplate ? 'T' : 'G'}_${group.isTemplate ? group.templateId : group.id}`;
}
