import React, {useEffect, useState} from 'react';
import {
    apiCommunicationCenterDownloadDocument,
    apiCommunicationCenterGetDocumentList,
} from './ModalCommunicationCentreDocumentListService';
import CustomTable from '~components/CustomTable';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import getIcon from '~utils/icons';
import {ModalCustomVersion2} from '../commonModals';
import {saveAs} from 'file-saver';
import {getPreSignedURLFetchRequest} from '../utils/requests';
import {Button} from '../componentsFormV2';

export default function ({communicationId, subject, userMode, intl, close}) {
    const [files, setFiles] = useState([]);
    const [timestamp, setTimestamp] = useState(Date.now());

    const downloadFile = ({filename, serverIndex} = {}) => {
        apiCommunicationCenterDownloadDocument({communicationId, serverIndex}).then(response => {
            if (response && response.signedUrl && response.filename) {
                const {signedUrl} = response;
                getPreSignedURLFetchRequest(signedUrl)
                    .then(response => response.blob())
                    .then(responseBlob => {
                        saveAs(
                            responseBlob,
                            filename === '_INDEX2.pdf' || filename === '_INDEX.pdf'
                                ? 'Confirmation of receipt.pdf'
                                : filename
                        );
                    });
            }
        });
    };

    useEffect(() => {
        (async () => {
            if (communicationId) {
                const {files: filesResponse} = (await apiCommunicationCenterGetDocumentList(communicationId)) || {};
                setFiles(filesResponse || []);
                setTimestamp(Date.now());
            }
        })();
    }, [communicationId]);

    return (
        <ModalCustomVersion2
            close={close}
            header={'Files list'}
            body={
                <>
                    {subject && (
                        <DataSheetFormFields
                            label={'Subject'}
                            data={subject}
                            loading={false}
                            ready={true}
                            double={true}
                        />
                    )}
                    <div style={{clear: 'both'}}>{}</div>
                    {(files || []).length > 0 && (
                        <div style={{marginBottom: 20}}>
                            <CustomTable
                                version={2}
                                notSortable={['filename']}
                                tableName={'fileLogs'}
                                tableType={'OBJECT'}
                                tableSource={files || []}
                                dataFilter={null}
                                id={'filename'}
                                setLastCursor={null}
                                resultFieldsDefault={['filename']}
                                intl={intl}
                                count={(files || []).length}
                                hideExcelButton={true}
                                noChangePageSize={true}
                                rowClick={(_, rowObject) => downloadFile(rowObject)}
                                forehandColumn={rowObject => (
                                    <img
                                        style={{width: 20, height: 20, cursor: 'pointer'}}
                                        alt={'Download'}
                                        src={getIcon(rowObject.filename || 'pdf')}
                                        onClick={event => {
                                            event.stopPropagation();
                                            downloadFile(rowObject);
                                        }}
                                    />
                                )}
                                timestamp={timestamp}
                                formatFunctions={{
                                    filename: filename => {
                                        const filenameArray = filename.split('_');
                                        try {
                                            filenameArray.shift();
                                        } catch (error) {
                                            LOG`OK`;
                                        }
                                        const modifiedFilename = filenameArray.join('_');
                                        return userMode && (filename === '_INDEX2.pdf' || filename === '_INDEX.pdf')
                                            ? 'Confirmation of receipt.pdf'
                                            : userMode
                                            ? modifiedFilename
                                            : filename;
                                    },
                                }}
                            />
                        </div>
                    )}
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
}
