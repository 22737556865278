import React from 'react';
import styles from './ACAreaAdministratorTabs.module.scss';
import {NavLink} from 'react-router-dom';

class ACAreaAdministratorTabs extends React.Component {
    render() {
        return (
            <div className={styles.header}>
                <div className={styles.leftPanel}>
                    <NavLink
                        to={'/acareaAdministratorSections'}
                        className={styles.menuItem}
                        activeClassName={styles.menuItemActive}
                    >{`Sections`}</NavLink>
                    <NavLink
                        to={'/acareaAdministratorContents'}
                        className={styles.menuItem}
                        activeClassName={styles.menuItemActive}
                    >{`Contents`}</NavLink>
                </div>
                <div style={{clear: 'both'}} />
            </div>
        );
    }
}

export default ACAreaAdministratorTabs;
