import React, {useState} from 'react';
import {Button} from '../../../../componentsFormV2';
import {CaseElement} from '../../CommonInterfaces/CommonInterfaces';
import DateInput from '~components/DateInput';
import InputContainer from '~componentsForm/InputContainer';
import {IntlShape} from 'react-intl';
import Label from '~components/Label';
import {ModalCustomVersion2} from '../../../../commonModals';
import TextAreaInput from '~components/TextAreaInput';
import TextInput from '~components/TextInput';
import {formatDate} from '~components/FormatFunctions';
import styles from './ModalCaseDetails.module.scss';

interface ModalCaseDetailsProps {
    intl: IntlShape;
    caseElement: CaseElement | null;
    downloadFile: (fileName: string) => void;
    close: () => void;
}

const ModalCaseDetails = (props: ModalCaseDetailsProps) => {
    const [caseName] = useState(props.caseElement?.caseName || '');
    const [origin] = useState(props.caseElement?.countryName || '');
    const [decisionLanguage] = useState(props.caseElement?.languageName || '');
    const [decisionNumber] = useState(props.caseElement?.decisionNumber || '');
    const [decisionDate] = useState(props.caseElement?.decisionDate || '');
    const [publicationNumber] = useState(props.caseElement?.publicationNumber || '');
    const [publicationDate] = useState(props.caseElement?.publicationDate || '');
    const [decidingBody] = useState(props.caseElement?.decidingBody || '');
    const [keywords] = useState(props.caseElement?.keywords || '');
    const [parties] = useState(props.caseElement?.parties || '');
    const [abstractInput] = useState(props.caseElement?.abstractInput || '');
    const [summaryFileName] = useState(props.caseElement?.summaryFile || '');
    const [decisionFileName] = useState(props.caseElement?.decisionFile || '');
    const [decisionTranslationFile] = useState(props.caseElement?.decisionTranslationFile || '');

    const closeModal = () => props.close && props.close();

    const downloadFile = (fileName: string) => props.downloadFile && props.downloadFile(fileName);

    return (
        <ModalCustomVersion2
            loading={false}
            close={closeModal}
            header={`Case Read Details`}
            body={
                <>
                    <TextInput
                        value={caseName}
                        outsideLabel={'Case Name'}
                        outsideLabelWidth={160}
                        disabled={true}
                        double={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextInput
                        value={origin}
                        outsideLabel={'Origin'}
                        outsideLabelWidth={160}
                        disabled={true}
                        double={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextInput
                        value={decisionLanguage}
                        outsideLabel={'Decision Language'}
                        outsideLabelWidth={160}
                        disabled={true}
                        double={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextInput
                        value={decisionNumber}
                        outsideLabel={'Decision Number'}
                        outsideLabelWidth={160}
                        disabled={true}
                        double={true}
                    />
                    <div style={{clear: 'both'}} />
                    <DateInput
                        inputValueFrom={formatDate(decisionDate)}
                        outsideLabel={'Decision Date'}
                        outsideLabelWidth={160}
                        simple={true}
                        disabled={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextInput
                        value={publicationNumber}
                        outsideLabel={'Publication Number'}
                        outsideLabelWidth={160}
                        disabled={true}
                        double={true}
                    />
                    <div style={{clear: 'both'}} />
                    <DateInput
                        inputValueFrom={formatDate(publicationDate)}
                        outsideLabel={'Publication Date'}
                        outsideLabelWidth={160}
                        simple={true}
                        disabled={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextInput
                        value={decidingBody}
                        outsideLabel={'Deciding Body'}
                        outsideLabelWidth={160}
                        disabled={true}
                        double={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextAreaInput
                        outsideLabel={`Keywords`}
                        outsideLabelWidth={160}
                        value={keywords}
                        double={true}
                        disabled={true}
                        rows={5}
                    />
                    <div style={{clear: 'both'}} />
                    <TextInput
                        value={parties}
                        outsideLabel={'Parties'}
                        outsideLabelWidth={160}
                        disabled={true}
                        double={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextAreaInput
                        outsideLabel={`Abstract`}
                        outsideLabelWidth={160}
                        value={abstractInput}
                        double={true}
                        disabled={true}
                        rows={5}
                    />
                    <div style={{clear: 'both'}} />
                    <>
                        <Label width={160}>{`Files`}</Label>
                        <InputContainer double={true} noIcon={true}>
                            {summaryFileName.length > 0 ? (
                                <div title="Download Summary Document">
                                    <a onClick={() => downloadFile(summaryFileName)}>{`Download Summary`}</a>
                                </div>
                            ) : null}
                            {decisionTranslationFile.length > 0 ? (
                                <div title="Download Complete Decision Document">
                                    <a
                                        onClick={() => downloadFile(decisionTranslationFile)}
                                    >{`Download Complete Decision`}</a>
                                </div>
                            ) : null}
                            {decisionFileName.length > 0 ? (
                                <div title="Download Complete Decision English Document">
                                    <a
                                        onClick={() => downloadFile(decisionFileName)}
                                    >{`Download Complete Decision (English)`}</a>
                                </div>
                            ) : null}
                            {summaryFileName.length === 0 &&
                            decisionFileName.length === 0 &&
                            decisionTranslationFile.length === 0 ? (
                                <div className={styles.noFilesAvailable}>{`No files available`}</div>
                            ) : null}
                        </InputContainer>
                    </>
                    <div style={{clear: 'both'}} />
                </>
            }
            footer={<Button clickAction={props.close} icon={'close'} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalCaseDetails;
