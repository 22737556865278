import React from 'react';
import {injectIntl} from 'react-intl';
import CustomTable from '~components/CustomTable';
import {FORMAT_DATE, formatDate} from '~components/FormatFunctions';
import {withRouter} from 'react-router-dom';
import styles from './Dashboard.module.scss';
import endOfFilesActions from './data/endOfFilesActions.json';
import {
    apiDashboardDenominations,
    apiDashboardDocuments,
    apiDashboardFees,
    apiDashboardLastWeekNotifications,
    apiEndOfFilesSurrenderDrafts,
    apiEndOfFilesSurrenderTodayRequests,
    apiEndOfFilesWithdrawalDrafts,
    apiEndOfFilesWithdrawalTodayRequests,
} from './DashboardService';
import DashboardCircles from './DashboardCircles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleRight, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationMyPVR from '../../shared/NavigationMyPVR';
import {ModalStatusInfoVersion2} from '../../commonModals';
import {trackPageView} from '../../utils';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            documentsNotOpenedMinorThan7days: null,
            documentsNotOpenedMoreThan7days: null,
            overdueFees: null,
            pendingFees: null,
            lastWeekNotifications: null,
            denominationStatus: null,
            endOfFilesActionsGrantedFiles: endOfFilesActions,
            endOfFilesActionsActiveFiles: endOfFilesActions,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'dashboard'});
        apiEndOfFilesSurrenderTodayRequests().then(response =>
            this.setState(prev => ({
                timestamp: Date.now(),
                endOfFilesActionsGrantedFiles: prev.endOfFilesActionsGrantedFiles.map(i =>
                    i.status === "Today's requests" ? Object.assign({}, i, {count: response.data.count}) : i
                ),
            }))
        );
        apiEndOfFilesSurrenderDrafts().then(response =>
            this.setState(prev => ({
                timestamp: Date.now(),
                endOfFilesActionsGrantedFiles: prev.endOfFilesActionsGrantedFiles.map(i =>
                    i.status === 'Draft requests' ? Object.assign({}, i, {count: response.data.count}) : i
                ),
            }))
        );
        apiEndOfFilesWithdrawalTodayRequests().then(response =>
            this.setState(prev => ({
                timestamp: Date.now(),
                endOfFilesActionsActiveFiles: prev.endOfFilesActionsActiveFiles.map(i =>
                    i.status === "Today's requests" ? Object.assign({}, i, {count: response.data.count}) : i
                ),
            }))
        );
        apiEndOfFilesWithdrawalDrafts().then(response =>
            this.setState(prev => ({
                timestamp: Date.now(),
                endOfFilesActionsActiveFiles: prev.endOfFilesActionsActiveFiles.map(i =>
                    i.status === 'Draft requests' ? Object.assign({}, i, {count: response.data.count}) : i
                ),
            }))
        );

        apiDashboardLastWeekNotifications().then(jsonRespose => {
            if (jsonRespose && jsonRespose.data.lastWeekNotifications) {
                this.setState({...jsonRespose.data});
            }
        });
        apiDashboardDocuments().then(jsonResponse => {
            if (
                jsonResponse &&
                jsonResponse.data &&
                jsonResponse.data.documentsNotOpenedMinorThan7days !== null &&
                jsonResponse.data.documentsNotOpenedMoreThan7days !== null
            ) {
                this.setState({...jsonResponse.data});
            }
        });
        apiDashboardFees().then(jsonResponse => {
            if (
                jsonResponse &&
                jsonResponse.data &&
                jsonResponse.data.overdueFees !== null &&
                jsonResponse.data.pendingFees !== null
            ) {
                this.setState({...jsonResponse.data});
            }
        });
        apiDashboardDenominations().then(jsonResponse => {
            if (jsonResponse && jsonResponse.data !== null) {
                this.setState({denominationStatus: jsonResponse.data});
            }
        });
    }

    goToDocuments = weekDay =>
        this.props.history.push(
            `/myDocuments?notificationDateFrom=${formatDate(weekDay)}&notificationDateTo=${formatDate(
                weekDay
            )}&addressedTo=1`
        );

    goToDenominations = denominationStatus =>
        this.props.history.push(
            `/denomination/?denominationStatusIds=${denominationStatus}${
                denominationStatus !== 'todayProposal' ? ',todayProposal' : ''
            }`
        );

    goToSurrenderOfGrantedFiles = id => {
        switch (id) {
            case 1:
                this.props.history.push(`/endOfFiles?applicationStatus=G&pageSize=100`);
                break;
            case 2:
                this.props.history.push(`/endOfFiles?endOfFileStatus=todayRequest&applicationStatus=G&pageSize=100`);
                break;
            case 3:
                this.props.history.push(`/endOfFiles?endOfFileStatus=draft&applicationStatus=G&pageSize=100`);
                break;
        }
    };
    goToWithdrawalOfActiveFiles = id => {
        switch (id) {
            case 1:
                this.props.history.push(`/endOfFiles?applicationStatus=A&pageSize=100`);
                break;
            case 2:
                this.props.history.push(`/endOfFiles?endOfFileStatus=todayRequest&applicationStatus=A&pageSize=100`);
                break;
            case 3:
                this.props.history.push(`/endOfFiles?endOfFileStatus=draft&applicationStatus=A&pageSize=100`);
                break;
        }
    };

    render() {
        return (
            <>
                {this.state.modalScreen ? <ModalStatusInfoVersion2 close={this.closeModal} /> : null}
                {this.state.loading ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`My PVR`} />
                <NavigationMyPVR />
                <MainWrapper bgGray={true}>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div className="row">
                            <div style={{width: '419.5px', float: 'left', paddingLeft: 15}}>
                                <DashboardCircles
                                    {...this.props}
                                    documentsNotOpenedMinorThan7days={this.state.documentsNotOpenedMinorThan7days}
                                    documentsNotOpenedMoreThan7days={this.state.documentsNotOpenedMoreThan7days}
                                    pendingFees={this.state.pendingFees}
                                    overdueFees={this.state.overdueFees}
                                />
                                <div style={{fontSize: '20px'}}>
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            marginBottom: '-20px',
                                            marginTop: 15,
                                            color: 'green',
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        <span className={styles.titleOnRightSide}>
                                            {`Documents notified within the last seven days with one line per day`}
                                        </span>
                                    </div>
                                    {this.state.lastWeekNotifications ? (
                                        <>
                                            {this.state.lastWeekNotifications.length > 0 ? (
                                                <CustomTable
                                                    tableName={'newDocuments'}
                                                    tableType={'OBJECT'}
                                                    id={'weekDate'}
                                                    tableSource={this.state.lastWeekNotifications}
                                                    resultFieldsDefault={['weekDate', 'message']}
                                                    defaultOrder={'weekDate'}
                                                    reverseOrder={true}
                                                    hiddenHeader={true}
                                                    rowClick={this.goToDocuments}
                                                    formatFunctions={{weekDate: FORMAT_DATE}}
                                                />
                                            ) : (
                                                <div style={{paddingTop: 20}}>
                                                    <b style={{color: 'red'}}>
                                                        <span>{`No result found`}</span>
                                                    </b>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div style={{marginTop: 20}}>
                                            <b className="col-sm-offset-2 col-sm-10" style={{color: 'red'}}>
                                                <span>{`Loading...`}</span>
                                            </b>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div style={{width: '434.5px', float: 'left'}}>
                                <div style={{fontSize: '16px'}}>
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            marginBottom: '-20px',
                                            marginTop: 15,
                                            color: 'green',
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        <span className={styles.titleOnRightSide}>{`Denominations `}</span>
                                        <span style={{fontSize: '16px', color: 'grey'}}>
                                            {`(status list updated every night)`}
                                        </span>
                                    </div>
                                    {this.state.denominationStatus ? (
                                        <>
                                            {this.state.denominationStatus.length > 0 ? (
                                                <CustomTable
                                                    tableName={'denominationDashboard'}
                                                    tableType={'OBJECT'}
                                                    tableSource={this.state.denominationStatus}
                                                    hiddenHeader={true}
                                                    id={'status'}
                                                    rowClick={this.goToDenominations}
                                                    resultFieldsDefault={['count', 'status']}
                                                    intl={this.props.intl}
                                                    actions={[
                                                        {
                                                            title: 'Go to denominations',
                                                            icon: () => (
                                                                <FontAwesomeIcon
                                                                    icon={faChevronCircleRight}
                                                                    color={'green'}
                                                                />
                                                            ),
                                                            handler: this.goToDenominations,
                                                        },
                                                    ]}
                                                    formatFunctions={{
                                                        status: status => {
                                                            const style =
                                                                status === 'toBeProposedImminentArrivalOfTheFinalDUS'
                                                                    ? {color: '#c4383a'}
                                                                    : {};
                                                            return (
                                                                <span
                                                                    style={Object.assign(style, {
                                                                        fontSize: '16px',
                                                                    })}
                                                                >
                                                                    {this.props.intl.formatMessage({
                                                                        id: `denominationStatus.${status}`,
                                                                    })}
                                                                </span>
                                                            );
                                                        },
                                                        count: (fieldId, rowObject) => {
                                                            let background = 'red';
                                                            let color = 'white';
                                                            switch (rowObject.status) {
                                                                case 'toBeProposedImminentArrivalOfTheFinalDUS':
                                                                    background = '#c4383a';
                                                                    break;
                                                                case 'objection':
                                                                    background = '#4a4a4a';
                                                                    break;
                                                                case 'observation':
                                                                    background = '#e3673a';
                                                                    break;
                                                                case 'toBeProposed':
                                                                    background = '#004678';
                                                                    break;
                                                                case 'todayProposal':
                                                                    background = '#a1bc30';
                                                                    break;
                                                            }
                                                            return (
                                                                <span
                                                                    style={{
                                                                        background,
                                                                        color,
                                                                        fontSize: '16px',
                                                                        paddingLeft: 8,
                                                                        paddingRight: 8,
                                                                        borderRadius: 10,
                                                                    }}
                                                                >
                                                                    {fieldId}
                                                                </span>
                                                            );
                                                        },
                                                    }}
                                                />
                                            ) : (
                                                <div style={{paddingTop: 20}}>
                                                    <b style={{color: 'red'}}>
                                                        <span>{`No result found`}</span>
                                                    </b>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div style={{marginTop: 20}}>
                                            <b className="col-sm-offset-2 col-sm-10" style={{color: 'red'}}>
                                                <span>{`Loading...`}</span>
                                            </b>
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            marginBottom: '-20px',
                                            marginTop: 15,
                                            color: 'green',
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        <span className={styles.titleOnRightSide}>{`Surrender of Granted Files`}</span>
                                    </div>
                                    <CustomTable
                                        tableName={'surrenderOfGrantedFiles'}
                                        tableType={'OBJECT'}
                                        tableSource={this.state.endOfFilesActionsGrantedFiles}
                                        hiddenHeader={true}
                                        id={'actionId'}
                                        timestamp={this.state.timestamp}
                                        rowClick={this.goToSurrenderOfGrantedFiles}
                                        resultFieldsDefault={['count', 'status']}
                                        intl={this.props.intl}
                                        actions={[
                                            {
                                                title: '',
                                                icon: () => (
                                                    <FontAwesomeIcon icon={faChevronCircleRight} color={'green'} />
                                                ),
                                                handler: this.goToSurrenderOfGrantedFiles,
                                            },
                                        ]}
                                        formatFunctions={{
                                            count: (fieldId, rowObject) => {
                                                let background = 'white';
                                                let color = 'white';
                                                switch (rowObject.status) {
                                                    case "Today's requests":
                                                        background = '#a1bc30';
                                                        break;
                                                    case 'Draft requests':
                                                        background = '#004678';
                                                        break;
                                                }
                                                return rowObject.status !== 'New requests' ? (
                                                    fieldId !== null ? (
                                                        <span
                                                            style={{
                                                                background,
                                                                color,
                                                                fontSize: '16px',
                                                                paddingLeft: 8,
                                                                paddingRight: 8,
                                                                borderRadius: 10,
                                                            }}
                                                        >
                                                            {fieldId}
                                                        </span>
                                                    ) : (
                                                        <FontAwesomeIcon icon={faSpinner} spin />
                                                    )
                                                ) : null;
                                            },
                                        }}
                                    />
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            marginBottom: '-20px',
                                            marginTop: 15,
                                            color: 'green',
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        <span className={styles.titleOnRightSide}>{`Withdrawal of Active Files`}</span>
                                    </div>
                                    <CustomTable
                                        tableName={'withdrawalOfActiveFiles'}
                                        tableType={'OBJECT'}
                                        tableSource={this.state.endOfFilesActionsActiveFiles}
                                        hiddenHeader={true}
                                        id={'actionId'}
                                        timestamp={this.state.timestamp}
                                        rowClick={this.goToWithdrawalOfActiveFiles}
                                        resultFieldsDefault={['count', 'status']}
                                        intl={this.props.intl}
                                        actions={[
                                            {
                                                title: '',
                                                icon: () => (
                                                    <FontAwesomeIcon icon={faChevronCircleRight} color={'green'} />
                                                ),
                                                handler: this.goToWithdrawalOfActiveFiles,
                                            },
                                        ]}
                                        formatFunctions={{
                                            count: (fieldId, rowObject) => {
                                                let background = 'white';
                                                let color = 'white';
                                                switch (rowObject.status) {
                                                    case "Today's requests":
                                                        background = '#a1bc30';
                                                        break;
                                                    case 'Draft requests':
                                                        background = '#004678';
                                                        break;
                                                }
                                                return rowObject.status !== 'New requests' ? (
                                                    fieldId !== null ? (
                                                        <span
                                                            style={{
                                                                background,
                                                                color,
                                                                fontSize: '16px',
                                                                paddingLeft: 8,
                                                                paddingRight: 8,
                                                                borderRadius: 10,
                                                            }}
                                                        >
                                                            {fieldId}
                                                        </span>
                                                    ) : (
                                                        <FontAwesomeIcon icon={faSpinner} spin />
                                                    )
                                                ) : null;
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{clear: 'both'}}>{}</div>
                            <div style={{width: '434.5px'}} />
                        </div>
                    </FormWrapper>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(withRouter(Dashboard));
