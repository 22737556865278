import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import cx from 'classnames';
import styles from './ApplicationSteps.module.scss';
import {ArrowContainer, Popover} from 'react-tiny-popover';
import {faInfo, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {buildFormDraftUrl} from '../../utils';
import {API_SERVERLESS, getFetch} from '~commonApi';
import {IApplicationFormFullListItem, IApplicationSearchItem} from '../../../../types';
import {checkIfApplicationHasObsoleteForms} from '../../OnlineApplications';

function apiOnlineApplicationFormsFullList({applicationRequestId, formIdApplicationForm, formIdTQ}: any): Promise<{
    formsFullList: IApplicationFormFullListItem[];
}> {
    return getFetch(
        `${API_SERVERLESS}/online/v3/applicationRequestFormsFullList?applicationRequestId=${applicationRequestId}&formIdApplicationForm=${formIdApplicationForm}&formIdTQ=${formIdTQ}`,
        true
    );
}

//TODO FIX: add response payload field if application is valid, so it take into account multiple TQs
//          and we need this before the on click on Forms button click, to show proper color and SIGN button
const checkIfApplicationRequestIsValid = ({AFValid, TQValid}: IApplicationSearchItem) => {
    return AFValid === 1 && TQValid === 1;
};

interface IApplicationStepsProps {
    application: IApplicationSearchItem;
    onApplicationStepClick: any;
}

export default function ApplicationSteps({application, onApplicationStepClick}: IApplicationStepsProps) {
    const history = useHistory();
    const {applicationRequestId, userId: applicationUserId} = application || {};
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
    const [isInfoOpen, setIsInfoOpen] = useState<boolean>(false);
    const [formsFullList, setFormsFullList] = useState<IApplicationFormFullListItem[]>([]);
    const validApplicationRequest = checkIfApplicationRequestIsValid(application);

    const onFormClick = (questionnaire: IApplicationFormFullListItem) => {
        const {formType, formId, formSequence} = questionnaire || {};
        let uri = buildFormDraftUrl({
            applicationId: applicationRequestId,
            formType,
            formId,
            formSequence,
        });
        history.push(uri);
    };

    const fetchApplicationsFormsFullList = async (application: IApplicationSearchItem) => {
        const {applicationRequestId, formIdApplicationForm, formIdTQ} = application || {};
        const jsonResponse = await apiOnlineApplicationFormsFullList({
            applicationRequestId,
            formIdApplicationForm,
            formIdTQ,
        });
        setFormsFullList((jsonResponse && jsonResponse.formsFullList) || []);
    };

    const onFormsButtonClick = (rowObject: IApplicationSearchItem) => {
        if (checkIfApplicationHasObsoleteForms(rowObject)) {
            return onApplicationStepClick(rowObject);
        }
        setIsPopoverOpen(!isPopoverOpen);
        fetchApplicationsFormsFullList(rowObject);
    };

    const goToSignApplicationClick = (rowObject: IApplicationSearchItem) => {
        if (checkIfApplicationHasObsoleteForms(rowObject)) {
            return onApplicationStepClick(rowObject);
        }
        history.push(`/onlineSignApplication?applicationRequestId=${applicationRequestId}&originScreen=applications`);
    };

    return (
        <div className={styles.container}>
            <Popover
                isOpen={isPopoverOpen}
                positions={'top'}
                onClickOutside={() => setIsPopoverOpen(!isPopoverOpen)}
                content={() => (
                    <div className={styles.popupWrap}>
                        <h5>{`Application's Forms`}</h5>
                        <main>
                            {formsFullList && formsFullList.length
                                ? formsFullList.map(questionnaire => {
                                      const {formId, formType, formName, formIsValid} = questionnaire || {};
                                      return (
                                          <div
                                              key={formId}
                                              className={styles[formIsValid ? 'valid' : 'inPreparation']}
                                              onClick={() => onFormClick(questionnaire)}
                                          >
                                              {formType}: {formName}
                                          </div>
                                      );
                                  })
                                : null}
                            {formsFullList && !formsFullList.length ? (
                                <div className={styles.spinner}>
                                    <FontAwesomeIcon icon={faSpinner as any} color="green" />
                                </div>
                            ) : null}
                        </main>
                    </div>
                )}
            >
                <div className={styles.inlineBlock}>
                    <div
                        className={cx(
                            styles.field,
                            styles.inlineBlock,
                            styles[validApplicationRequest ? 'valid' : 'inPreparation']
                        )}
                        onClick={() => onFormsButtonClick(application)}
                    >
                        {`Forms`}
                    </div>
                    {validApplicationRequest ? (
                        <div
                            className={cx(styles.field, styles.inlineBlock, styles.valid)}
                            onClick={() => goToSignApplicationClick(application)}
                        >{`SIGN`}</div>
                    ) : null}
                </div>
            </Popover>
            {(applicationUserId || '').toUpperCase() === 'PROC' && (
                <Popover
                    isOpen={isInfoOpen}
                    positions={'top'}
                    onClickOutside={() => setIsInfoOpen(!isInfoOpen)}
                    content={({position, childRect, popoverRect}) => (
                        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                            position={position}
                            childRect={childRect}
                            popoverRect={popoverRect}
                            arrowColor={'#3a924d'}
                            arrowSize={5}
                            arrowStyle={{opacity: 0.7}}
                        >
                            <div className={styles.infoWrap}>
                                <h5>{`Model out of date`}</h5>
                                <main>
                                    <p>{`The form model has been updated by the Office since the last time you have edited or validated it. Therefore, it may have gone from the status "valid" to "under preparation". You will have to review your data to make sure that the form is complete.`}</p>
                                </main>
                            </div>
                        </ArrowContainer>
                    )}
                >
                    <div onClick={() => setIsInfoOpen(!isInfoOpen)} className={styles.inlineBlock}>
                        <FontAwesomeIcon icon={faInfo as any} color="green" />
                    </div>
                </Popover>
            )}
        </div>
    );
}
