import React from 'react';
import cx from 'classnames';
import styles from './Error.module.scss';

export default function ({children, wizardResponse, wizardQuestion}) {
    return (
        <div
            className={cx(
                styles.error,
                wizardResponse && styles.wizardResponse,
                wizardQuestion && styles.wizardQuestion
            )}
        >
            {children}
        </div>
    );
}
