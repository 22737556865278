import {API_SERVERLESS, getFetch, postFetch, postForm} from '~commonApi';
import {
    computeGetUploadPreSignedURLPromises,
    computePostFilesToPreSignedURLPromises,
    postFileToPreSignedURLRequest,
} from '../../../utils/requests';

export function apiCommunicationCentreClients(jsonData) {
    return postFetch(`${API_SERVERLESS}/communicationCentre/v3/internal/clients`, true, jsonData);
}

export function apiCPVOColleagues() {
    return getFetch(`${API_SERVERLESS}/communicationCentre/v3/ccList`, true);
}

const getFormData = ({communicationId, document = {}, index, identifier} = {}) => {
    const formData = new FormData();
    formData.append(`communicationId`, communicationId);
    if (document.file) {
        formData.append('files[]', identifier);
    } else {
        formData.append('files[]', null);
    }
    formData.append('filename', encodeURIComponent(document.filename));
    formData.append(`index`, index);
    if (document.serverIndex) {
        formData.append(`serverIndex`, document.serverIndex || null);
    }
    return formData;
};

export function apiDocumentUpload(jsonRequest, documentList) {
    let messageCommunicationId = '';
    return getFetch(`${API_SERVERLESS}/communicationCentre/v3/getCommunicationId`, true)
        .then(({communicationId} = {}) => {
            messageCommunicationId = communicationId;
            if (documentList.length) {
                return computeGetUploadPreSignedURLPromises({}).then(({presignedUrl, identifier} = {}) =>
                    postFileToPreSignedURLRequest({preSignedURL: presignedUrl, file: documentList[0]}).then(() =>
                        postForm(
                            `${API_SERVERLESS}/communicationCentre/v3/documentUploadFile`,
                            true,
                            getFormData({
                                communicationId: messageCommunicationId,
                                document: documentList[0],
                                index: 0,
                                identifier,
                            })
                        ).then(() => {
                            if (documentList.length > 1) {
                                const uploadArray = [];
                                let restDocumentList = [...documentList];
                                restDocumentList.splice(0, 1);
                                return Promise.all(
                                    computeGetUploadPreSignedURLPromises({documentList: restDocumentList})
                                ).then(getUploadPreSignedURLResponses => {
                                    return Promise.all(
                                        computePostFilesToPreSignedURLPromises({
                                            getUploadPreSignedURLResponses,
                                            documentList: restDocumentList,
                                        })
                                    ).then(() => {
                                        restDocumentList.forEach((document, index) => {
                                            const formData = getFormData({
                                                communicationId: messageCommunicationId,
                                                document,
                                                index: index + 1,
                                                identifier: getUploadPreSignedURLResponses[index].identifier,
                                            });
                                            uploadArray.push(
                                                postForm(
                                                    `${API_SERVERLESS}/communicationCentre/v3/documentUploadFile`,
                                                    true,
                                                    formData
                                                )
                                            );
                                        });
                                        return Promise.all(uploadArray);
                                    });
                                });
                            } else {
                                return Promise.resolve();
                            }
                        })
                    )
                );
            } else {
                return Promise.resolve();
            }
        })
        .then(() => {
            return postFetch(
                `${API_SERVERLESS}/communicationCentre/v3/internal/messageSend`,
                true,
                Object.assign({}, jsonRequest, {communicationId: messageCommunicationId})
            );
        })
        .then(() => ({
            communicationId: messageCommunicationId,
        }));
}

export function apiUploadGetFileInfo(applicationNumber) {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/upload/getFileInfoIfExists/${applicationNumber}`, true);
}
