import React, {useEffect, useState} from 'react';
import TextInput from '~components/TextInput';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeShowMeLinkToAFResponseQuestion = ({
    close,
    onCreateOrUpdateLogicType,
    responseLogicValues,
    responseLogicType,
}) => {
    const {logicParam, applyToRemark} = responseLogicValues || {};
    const {name} = responseLogicType || {};
    const [paramValue, setParamValue] = useState(logicParam !== undefined ? logicParam : null);
    const [groupValue, setGroupValue] = useState(null);
    const [questionValue, setQuestionValue] = useState(null);
    const [responseValue, setResponseValue] = useState(null);
    const [valueValue, setValueValue] = useState(null);
    const onGroupValueChange = ({target: {value: group}}) => setGroupValue(group);
    const onQuestionValueChange = ({target: {value: question}}) => setQuestionValue(question);
    const onResponseValueChange = ({target: {value: response}}) => setResponseValue(response);
    const onValueValueChange = ({target: {value}}) => setValueValue(value);

    useEffect(() => {
        if (logicParam) {
            // api param --> GROUP:thegroup ; QUESTION:thequestion ; RESPONSE:theresponse 2 ; VALUES:thevalue2
            const initValues = logicParam.split(';').map(el => el.trim());
            setGroupValue(initValues[0].replace('GROUP:', ''));
            setQuestionValue(initValues[1].replace('QUESTION:', ''));
            setResponseValue(initValues[2].replace('RESPONSE:', ''));
            setValueValue(initValues[3].replace('VALUES:', ''));
        }
    }, []);

    useEffect(() => {
        let updatedParamValue = `GROUP:${groupValue} ; QUESTION:${questionValue} ; RESPONSE${responseValue} ; VALUES:${valueValue}`;
        setParamValue(updatedParamValue);
    }, [groupValue, questionValue, responseValue, valueValue]);

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <TextInput label="Group" value={groupValue} onChange={onGroupValueChange} width={550} rows={7} />
                    <TextInput
                        label="Question"
                        value={questionValue}
                        onChange={onQuestionValueChange}
                        width={550}
                        rows={7}
                    />
                    <TextInput
                        label="Response"
                        value={responseValue}
                        onChange={onResponseValueChange}
                        width={550}
                        rows={7}
                    />
                    <TextInput label="Value" value={valueValue} onChange={onValueValueChange} width={550} rows={7} />
                </>
            }
            footer={
                <>
                    <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                    <Button
                        icon={'arrowRight'}
                        clickAction={() =>
                            onCreateOrUpdateLogicType(paramValue, applyToRemark !== undefined ? applyToRemark : false)
                        }
                    >{`Save`}</Button>
                </>
            }
        />
    );
};

export default ModalLogicTypeShowMeLinkToAFResponseQuestion;
