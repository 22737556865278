export const tinyConfig = {
    height: 350,
    inline: false,
    plugins: 'table hr anchor paste preview table',
    toolbar1:
        'bold italic underline strikethrough | bullist numlist | alignleft aligncenter alignright alignjustify | outdent indent | preview | table | undo redo ',
    statusbar: false,
    menubar: false,
    language: 'en',
    table_default_attributes: {
        border: '0',
    },
};

export const tinyConfigFullHeight = {
    ...tinyConfig,
    height: window.innerHeight - 290,
};
