import React from 'react';

interface IIconMenuProps {
    style?: any;
    className?: any;
    color?: string;
}

export default function ({style: userStyle, color, className}: IIconMenuProps) {
    return (
        <svg
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={userStyle}
            className={className}
        >
            <path
                d="M1 1H17M1 7H17M1 13H17"
                stroke={color || '#197437'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
