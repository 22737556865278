import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Help from '../common/Help';
import RadioButtonSingle from '~components/RadioButtonSingle';
import ResponseWrapper, {IWizardResponseWrapperProps, IWizardResponseWrapperState} from './ResponseWrapper';
import TextAreaInput from '~components/TextAreaInput';
import cx from 'classnames';
import {getErrorMessageResponseMandatoryRemark} from './utils';
import {replaceLineBreaksWithHTML} from '../../../utils/format';
import styles from './ResponseRadio.module.scss';
import {faPen, faPlus} from '@fortawesome/free-solid-svg-icons';

interface IState extends IWizardResponseWrapperState {}

class ResponseRadio extends ResponseWrapper<IWizardResponseWrapperProps, IState> {
    constructor(props: IWizardResponseWrapperProps) {
        super(props);
        this.state = {
            ...this.state,
        };
    }

    componentDidMount() {
        this.checkAndUpdateIfCurrentResponseIsDisabledDueToLogicDisabledOfOtherResponse();
    }

    componentDidUpdate(prevProps: IWizardResponseWrapperProps, prevState: IState) {
        if (this.props.screenData.hash !== prevProps.screenData.hash) {
            this.checkAndUpdateIfCurrentResponseIsDisabledDueToLogicDisabledOfOtherResponse();
        }
    }

    isRadioChecked = () => {
        const {screenData} = this.props;
        const {responsesClient} = screenData || {};
        return responsesClient && responsesClient[this.props.questionKeyWithIteration] === this.getResponseKey();
    };

    onRadioChange = ({target: {checked}}: React.ChangeEvent<HTMLInputElement>) => {
        const updatedResponsesClient = this.getCopyOfResponsesClient();
        if (checked) {
            updatedResponsesClient[this.props.questionKeyWithIteration] = this.getResponseKey();
            Object.keys(updatedResponsesClient).forEach(key => {
                if (key.startsWith(`ra${this.props.questionKeyWithIteration}`)) {
                    delete updatedResponsesClient[key];
                }
            });
            let targetQuestionHasAttachment = false;
            this.props.question.responses
                .filter(response => response.id !== this.props.response.id)
                .forEach(response =>
                    response.logics
                        .filter(logic => logic.type === 'showquestion')
                        .forEach(logic => {
                            Object.entries(this.props.screenData.responsesClient).forEach(([key, value]) => {
                                if (
                                    !targetQuestionHasAttachment &&
                                    key &&
                                    key.includes(`_Q${logic.questionIdDest}_`) &&
                                    parseInt(key.split('_')[1]) === this.props.groupIteration &&
                                    typeof value === 'string' &&
                                    value.startsWith(`${this.props.applicationCode}_`)
                                ) {
                                    targetQuestionHasAttachment = true;
                                }
                            });
                        })
                );
            if (targetQuestionHasAttachment) {
                this.showModalConfirmRemoveAttachments(updatedResponsesClient);
            } else {
                this.updateResponsesClient(updatedResponsesClient);
            }
        }
    };

    renderRemedyIcon = () => {
        const remedyType = this.getRemedyType();
        return (
            <div
                className={cx(
                    styles.remedyIconWrap,
                    styles[this.props.question.type],
                    remedyType ? styles[remedyType] : null
                )}
                onClick={this.onButtonAddResponseToRemedyClick}
                id={`REMEDY_${this.getResponseKey()}`}
            >
                <FontAwesomeIcon
                    icon={(this.checkIfRemedyResponseExists() ? faPen : faPlus) as any}
                    color={'white'}
                    width={16}
                    height={10}
                />
            </div>
        );
    };

    renderPDFVersion = () => {
        const remarkAnswer = this.getResponseRemarkAnswer();
        const {response} = this.props;
        const {messageRemark} = response || {};
        let questionHasSelectedRadio = !!this.getCopyOfResponsesClient()[this.props.questionKeyWithIteration];
        if ((window as any).elements) {
            let element: any = {
                type: 'RESPONSE_RADIO',
                label: this.getResponseLabel(),
                order: this.getResponseOrderForPDF(),
                questionType: this.props.question.type,
            };
            messageRemark && (element.remark = messageRemark);
            this.isRemarkAllowed() && (element.remarkTextArea = true);
            let elementExist = (window as any).elements.find((el: any) => el.order === element.order);
            if (!elementExist) {
                (window as any).elements.push(element);
            }
        }

        return (
            <>
                {super.render()}
                {this.props.isRemedyStarted && this.renderRemedyIcon()}
                <div className={styles.pdfResponseWrap}>
                    <div className={styles.pdfResponseWrapLeft}>
                        <span
                            className={cx(styles.pdfResponseRadio, this.isRadioChecked() && styles.pdfRadioChecked)}
                        />
                        <span
                            /* eslint-disable-next-line react/no-danger */
                            dangerouslySetInnerHTML={{__html: this.getResponseLabel()}}
                        />
                    </div>
                    <div className={styles.pdfRadioWrapRightOuter}>
                        {messageRemark && (
                            <div
                                ref={el => {
                                    if (el) el.innerHTML = messageRemark;
                                }}
                                className={styles.messageRemark}
                            />
                        )}
                        {(questionHasSelectedRadio && this.isRadioChecked() && this.isRemarkAllowed()) ||
                        (!questionHasSelectedRadio && this.isRemarkAllowed()) ? (
                            <div className={cx(styles.pdfResponseWrapRightInner, !!remarkAnswer && styles.hasPDFValue)}>
                                {replaceLineBreaksWithHTML(remarkAnswer)}
                            </div>
                        ) : null}
                    </div>
                </div>
            </>
        );
    };

    render() {
        const {questionCode, response} = this.props;
        const {messageRemark} = response || {};
        const responseRemarkKey = this.getResponseRemarkKey();
        const responseRemarkAnswer = this.getResponseRemarkAnswer();
        const stylesNormal = {marginRight: '35px', display: 'inline', float: 'right'};
        const stylesShortDisplay = {};
        const styles = this.props.isShortDisplay ? stylesShortDisplay : stylesNormal;
        const showRemarkTextArea = this.isRemarkAllowed() && this.isRadioChecked();
        const hasRemarkMandatoryError = this.checkIfQuestionHasRemarkMandatoryError();
        const remarkMandatoryErrorMessage =
            hasRemarkMandatoryError && getErrorMessageResponseMandatoryRemark({intl: this.props.intl});
        const responseLabelShouldTakeTheWholeLineAndNotWrap =
            this.checkIfResponseLabelShouldTakeTheWholeLineAndNotWrap();

        if (this.props.PDFVersion) {
            return this.renderPDFVersion();
        }

        return (
            <>
                {super.render()}
                <Help
                    message={response.messageHelp}
                    language={this.props.questionnaireLanguage}
                    className="responseHelp"
                />
                <RadioButtonSingle
                    label={this.getResponseLabel()}
                    name={questionCode}
                    checked={this.isRadioChecked()}
                    onChange={this.onRadioChange}
                    remark={messageRemark}
                    isRemarkTranslationMissing={this.checkIfResponseRemarkTranslationIsMissing()}
                    oldBackOfficeStyles={true}
                    isShortDisplay={this.props.isShortDisplay}
                    responseLabelShouldTakeTheWholeLineAndNotWrap={responseLabelShouldTakeTheWholeLineAndNotWrap}
                    isTranslationMissing={this.checkIfTranslationIsMissing()}
                    disabled={this.state.isCurrentResponseDisabledDueToLogicDisabledOfOtherResponse}
                >
                    {showRemarkTextArea && (
                        <div style={styles}>
                            <TextAreaInput
                                value={responseRemarkAnswer}
                                name={responseRemarkKey}
                                width={400}
                                rows={this.getRemarkTextAreaNumberOfRows()}
                                oldBackOfficeStyles={true}
                                oldBackOfficeStylesError={hasRemarkMandatoryError}
                                onChange={this.onRemarkChange}
                                error={remarkMandatoryErrorMessage}
                                isRemarkTextarea={true}
                            />
                        </div>
                    )}
                </RadioButtonSingle>
            </>
        );
    }
}

export default ResponseRadio;
