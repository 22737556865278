import React from 'react';
import cx from 'classnames';
// import CustomPopoverInput from '~components/CustomPopoverInput';
import stylesNew from './TextLabelInput.module.scss';
import stylesOld from './TextLabelInputOld.module.scss';
import {faChevronDown, faChevronUp, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import Label from '~components/Label';
// import Error from '~components/Error';
import {htmlToText, isTextHTML} from '../utils';
import ClickOutside from '~components/ClickOutside';
import {LABEL_POSITION, labelIcons, TLabelIcon, TLabelPosition} from './TextInput';
import {ArrowContainer, Popover} from 'react-tiny-popover';
import {Info as IconInfo} from '../iconComponents';

const filterLabelHash = {
    contains: 'Contains',
    starts: 'Starts with',
    equals: 'Equals',
    ends: 'Ends with',
};

const FIRST_LIST_ELEMENT = 0;
const MAX_ELEMENTS_IN_LIST = 20;

let styles: any;

const buttonIcons: any = {
    info: <IconInfo />,
};

interface IProps {
    className?: any;
    style?: any;
    labelPosition?: TLabelPosition;
    labelIcon?: TLabelIcon;
    oldBackOfficeStyles?: boolean;
    oldBackOfficeStylesError?: any;
    value: any;
    timestamp?: number;
    currentFilter?: any;
    focus?: boolean;
    selectedElements: any;
    uniqueValue?: any;
    extraFilterLabelHash?: any;
    autoSelection?: any;
    onIdChange?: any;
    onFilterChange?: any;
    maxElementsInList?: any;
    errorVarietyFinder?: any;
    useAsMultipleSelection?: any;
    listByIdName: {ID: any; NAME: any}[];
    filterContains?: any;
    filterContainsKeepJSONResponseSortOrder?: any;
    onSelectionChange: any;
    onBlur?: any;
    onChange?: any;
    onEnter?: any;
    delay?: any;
    nameHash: {[key: string]: any};
    outsideLabelWithHTML?: any;
    isTranslationMissing?: any;
    selectedLabelsAsInputText?: any;
    outsideLabel?: any;
    outsideLabelWidth?: any;
    double?: any;
    triple?: any;
    size?: any;
    half?: any;
    filter?: any;
    popOverTextFirstRow?: any;
    label?: any;
    mandatory?: any;
    noInfo?: any;
    onButtonAddFromListClick?: any;
    disabled?: any;
    clickable?: any;
    children?: any;
    clickableAndEditable?: any;
    hideRemoveElementButton?: any;
    hideRemoveElementButtonForId?: any;
    forceHideSearchList?: any;
    listMaxHeight?: any;
    inputSearchList?: any;
    isRemarkTranslationMissing?: any;
    remark?: any;
    removeStyleClear?: any;
    error?: any;
    buttonIcon?: any;
    buttonDisabled?: any;
    buttonColor?: any;
    popover?: any;
    buttonAction?: any;
    listSticky?: any;
    hideCounter?: any;
    showCounter?: any;
    placeholder?: any;
    selectable?: any;
    buttonText?: any;
    popOverText?: any;
}

interface IState {
    inputSearchListValue: string;
    resultLabel: string;
    searchList: any[];
    highlighted: number;
    timestamp: number;
    error: any;
    isPopoverOpen: boolean;
}

class TextLabelInput extends React.Component<IProps, IState> {
    channel: any = null;
    elInput: any = null;
    flagDeleted: boolean = false;
    filterLabelHash: any = filterLabelHash;

    constructor(props: IProps) {
        super(props);
        this.state = {
            inputSearchListValue: '',
            resultLabel: '',
            searchList: [],
            highlighted: FIRST_LIST_ELEMENT,
            timestamp: Date.now(),
            error: null,
            isPopoverOpen: false,
        };
        styles = props.oldBackOfficeStyles ? stylesOld : stylesNew;
    }

    componentDidUpdate(prevProps: IProps, prevSate: IState) {
        prevProps.focus !== this.props.focus && this.elInput && this.elInput.focus();
        if (prevProps.timestamp !== this.props.timestamp) {
            this.setState({timestamp: this.props.timestamp || Date.now()});
            this.searchByName(this.props.value, this.channel);
        }
        if (
            Object.keys(this.props.selectedElements || {}).length !== 1 &&
            this.props.uniqueValue &&
            this.elInput &&
            this.flagDeleted
        ) {
            this.flagDeleted = false;
            this.elInput.focus();
        }
        if (prevProps.extraFilterLabelHash !== this.props.extraFilterLabelHash) {
            this.filterLabelHash = Object.assign({}, filterLabelHash, this.props.extraFilterLabelHash || {});
        }
    }

    clearList = (callback?: any) => {
        this.channel = Date.now();
        this.setState({resultLabel: '', searchList: [], inputSearchListValue: ''}, callback);
    };

    checkAutoSelection = () => {
        const {searchList} = this.state;
        const {autoSelection, onIdChange} = this.props;
        autoSelection && searchList.length === 1 && onIdChange(searchList[0].ID);
    };

    searchByName = (nameCriteria: any, channel: any) => {
        const maxElementsInList = this.props?.maxElementsInList || MAX_ELEMENTS_IN_LIST;
        if (channel === this.channel && this.props.listByIdName && this.props.value !== '') {
            if (nameCriteria === '') {
                this.clearList && this.clearList();
            } else {
                this.setState(prev => {
                    let filteredData;
                    if (this.props.filterContainsKeepJSONResponseSortOrder) {
                        filteredData = [...this.props.listByIdName];
                    } else {
                        if (this.props.filterContains || this.props.currentFilter === 'contains') {
                            filteredData = this.props.listByIdName.filter(
                                (i: any) =>
                                    i.NAME.toUpperCase().indexOf(nameCriteria.toUpperCase()) !== -1 &&
                                    (!this.props.selectedElements || !this.props.selectedElements[i.ID])
                            );
                        } else {
                            filteredData = this.props.listByIdName.filter(
                                (i: any) =>
                                    i.NAME.toUpperCase().startsWith(nameCriteria.toUpperCase()) &&
                                    (!this.props.selectedElements || !this.props.selectedElements[i.ID])
                            );
                        }
                    }
                    let resultLabel = '';
                    if (nameCriteria !== '') {
                        if (filteredData.length === 1) {
                            resultLabel = 'Exactly one match';
                        } else if (filteredData.length < maxElementsInList) {
                            resultLabel = `${filteredData.length} matches`;
                        } else {
                            resultLabel = `${maxElementsInList} or more matches`;
                        }
                    }
                    return {
                        resultLabel,
                        searchList: this.props.filterContainsKeepJSONResponseSortOrder
                            ? filteredData
                            : filteredData
                                  .sort((a: any, b: any) => {
                                      if (a.NAME > b.NAME) return 1;
                                      else if (a.NAME < b.NAME) return -1;
                                      else return 0;
                                  })
                                  .slice(0, maxElementsInList),
                        highlighted: FIRST_LIST_ELEMENT,
                    };
                });
            }
        }
    };

    calculateScore = ({NAME}: any) => {
        const searchTerm = this.props.value;
        try {
            const regexStartsWithTerm = new RegExp(`^${searchTerm}`, 'i');
            const regexStartsWithTermAndSpace = new RegExp(`^${searchTerm} `, 'i');
            const regexNonLatinBeforeTerm = new RegExp(`(\\(|\\)|([^(a-zA-Z)]))${searchTerm}`, 'i');
            const regexSpaceBeforeAndAfter = new RegExp(` ${searchTerm} `, 'i');
            const regexSpaceBeforeEnd = new RegExp(` ${searchTerm}$`, 'i');
            const regexNonLatinBeforeAndNonLatinAfter = new RegExp(
                `(\(|\)|[^(a-zA-Z)])${searchTerm}(\(|\)|[^(a-zA-Z)])`, // eslint-disable-line
                'i'
            );
            const regexNonLatinBeforeTheEnd = new RegExp(`(\\(|\\)|[^(a-zA-Z)])${searchTerm}$`, 'i'); // eslint-disable-line
            if (regexStartsWithTerm.test(NAME)) {
                return 1;
            }
            if (regexStartsWithTermAndSpace.test(NAME)) {
                return 0.95;
            }
            if (regexNonLatinBeforeTerm.test(NAME)) {
                return 0.9;
            }
            if (regexSpaceBeforeAndAfter.test(NAME)) {
                return 0.8;
            }
            if (regexSpaceBeforeEnd.test(NAME)) {
                return 0.7;
            }
            if (regexNonLatinBeforeAndNonLatinAfter.test(NAME)) {
                return 0.6;
            }
            if (regexNonLatinBeforeTheEnd.test(NAME)) {
                return 0.5;
            }
        } catch (error) {
            return 0;
        }
        return 0;
    };

    toggleList = () => {
        const remainingElements = this.props.listByIdName.filter((i: any) => !this.props.selectedElements[i.ID]);
        if (Object.keys(remainingElements).length > 0 && this.state.searchList.length === 0) {
            this.setState({searchList: remainingElements});
        } else {
            this.setState({searchList: []});
        }
    };

    checkIfShouldHideInputText = () =>
        (this.props.uniqueValue && Object.keys(this.props.selectedElements || {}).length === 1) ||
        this.props.selectable ||
        this.props.disabled;

    clickOutside = (e: any) => {
        if (
            e &&
            e.target &&
            e.target.className &&
            e.target.className.includes &&
            e.target.className.includes('TextLabelInput_listElementLink_')
        ) {
            e.preventDefault();
        } else {
            this.clearList();
        }
    };

    onNameChange = (event: any) => {
        const nameCriteria = event.target.value;
        this.props.onChange(nameCriteria, null, null);
        this.props.onIdChange && this.props.onIdChange(null);
        this.channel = Date.now();
        setTimeout(
            () => {
                this.searchByName(nameCriteria, this.channel);
            },
            nameCriteria === '' ? 1 : this.props.delay || 1
        );
    };

    onElementListMouseOver = (index: any) => {
        this.setState({
            highlighted: index,
        });
    };

    onElementListClick = (index: any) => {
        const name = this.state.searchList[index].NAME;
        const id = this.state.searchList[index].ID;
        const object = this.state.searchList[this.state.highlighted];
        let selectedElements = this.props.uniqueValue ? {} : this.props.selectedElements;
        if (this.props.useAsMultipleSelection && selectedElements[id]) {
            this.flagDeleted = true;
            if (this.props.selectedElements && this.props.onSelectionChange) {
                delete selectedElements[id];
                this.props.onSelectionChange(selectedElements);
            }
        } else {
            if (selectedElements) {
                id.split(',').forEach((id: any) => {
                    selectedElements[id] = name;
                });
            }
        }
        if (!this.props.useAsMultipleSelection) {
            this.clearList &&
                this.clearList(() => {
                    this.props.onChange &&
                        this.props.onChange(this.props.useAsMultipleSelection ? '' : name, id, object);
                    this.props.onIdChange && this.props.onIdChange(id);
                    this.props.onSelectionChange && this.props.onSelectionChange(selectedElements);
                });
        } else {
            this.props.onChange && this.props.onChange(this.props.useAsMultipleSelection ? '' : name, id, object);
            this.props.onIdChange && this.props.onIdChange(id);
            this.props.onSelectionChange && this.props.onSelectionChange(selectedElements);
        }
        this.elInput && this.elInput.focus();
        this.setState({error: false});
    };

    onInputKeyDown = (event: any) => {
        LOG([`event.key: ${event.key}`]);
        if (event.key === 'Enter') {
            if (this.state.searchList[this.state.highlighted]) {
                const name = this.state.searchList[this.state.highlighted].NAME;
                const id = this.state.searchList[this.state.highlighted].ID;
                const object = this.state.searchList[this.state.highlighted];
                const selectedElements = this.props.uniqueValue ? {} : this.props.selectedElements;
                if (this.props.useAsMultipleSelection && selectedElements[id]) {
                    this.flagDeleted = true;
                    if (this.props.selectedElements && this.props.onSelectionChange) {
                        delete selectedElements[id];
                        this.props.onSelectionChange(selectedElements);
                    }
                } else {
                    if (selectedElements) {
                        id.split(',').forEach((id: any) => {
                            selectedElements[id] = name;
                        });
                    }
                }
                this.clearList &&
                    this.clearList(() => {
                        this.props.onChange(this.props.useAsMultipleSelection ? '' : name, id, object);
                        this.props.onIdChange && this.props.onIdChange(id);
                        this.props.onSelectionChange && this.props.onSelectionChange(selectedElements);
                    });
                this.elInput && this.elInput.focus();
            } else if (this.props.onEnter) {
                this.props.onEnter();
            }
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            if (this.state.searchList.length > 0 && this.state.highlighted > 0) {
                this.setState(prev => ({highlighted: prev.highlighted - 1}));
            }
        } else if (event.key === 'ArrowDown') {
            event.preventDefault();
            if (this.state.highlighted < this.state.searchList.length - 1) {
                this.setState(prev => ({highlighted: prev.highlighted + 1}));
            }
        } else if (event.key === 'Escape') {
            this.clearList && this.clearList();
        }
    };

    onFocus = () => {
        const maxElementsInList = this.props?.maxElementsInList || MAX_ELEMENTS_IN_LIST;
        this.setState({
            searchList: this.props.filterContainsKeepJSONResponseSortOrder
                ? [...this.props.listByIdName]
                : this.props.listByIdName
                      .sort((a: any, b: any) => {
                          if (a.NAME > b.NAME) return 1;
                          else if (a.NAME < b.NAME) return -1;
                          else return 0;
                      })
                      .slice(0, maxElementsInList),
            highlighted: FIRST_LIST_ELEMENT,
        });
    };

    onRemoveElement = (event: any, elementName: any) => {
        event.stopPropagation();
        this.flagDeleted = true;
        if (this.props.selectedElements && this.props.onSelectionChange) {
            const selectedElements = Object.assign({}, this.props.selectedElements);
            if (this.props.nameHash) {
                (this.props.nameHash[elementName] || []).forEach(
                    (elementId: any) => delete selectedElements[elementId]
                );
            } else if (selectedElements[elementName]) {
                delete selectedElements[elementName];
            }
            this.props.onSelectionChange(selectedElements);
            this.setState({searchList: []});
        }
    };

    onFilterChange = (event: any) => {
        this.elInput && this.elInput.focus();
        this.props.onFilterChange && this.props.onFilterChange(event);
    };

    onBlur = () => {
        const {searchList} = this.state;
        const {autoSelection, onIdChange} = this.props;
        if (autoSelection && searchList.length === 1 && onIdChange) {
            onIdChange(searchList[0].ID, this.props.onBlur);
        } else {
            this.props.onBlur && this.props.onBlur();
        }
        this.clearList();
    };

    onBlurContainer = () => this.toggleList();

    onInputSearchListChange = ({target: {value: inputSearchListValue}}: any) => this.setState({inputSearchListValue});

    render() {
        const renderedLabel: any = {};
        const {label, labelPosition, labelIcon, mandatory, className, style: userStyle} = this.props;
        const hideInputText = this.checkIfShouldHideInputText();

        return (
            <section
                className={cx(
                    styles.section,
                    ![LABEL_POSITION.LEFT_INSIDE, LABEL_POSITION.LEFT_OUTSIDE].includes(labelPosition) &&
                        styles[`sectionLabelPositionTop`],
                    className ? className : null
                )}
                style={userStyle}
            >
                {label ? (
                    <label
                        className={cx(
                            styles.label,
                            labelPosition === LABEL_POSITION.LEFT_INSIDE && styles.labelPositionLeftInside,
                            labelPosition === LABEL_POSITION.LEFT_OUTSIDE && styles.labelPositionLeftOutside,
                            !labelPosition && styles.labelPositionTop
                        )}
                    >
                        {labelIcon ? labelIcons[labelIcon] : null}
                        {label || null}
                        {mandatory ? <span className={styles.mandatoryStar}>*</span> : null}
                    </label>
                ) : null}
                <ClickOutside
                    className={cx(styles.selectedListAndFilterAndInputWrapAndSuggestionListWrap)}
                    onClickOutside={this.props.useAsMultipleSelection ? this.clickOutside : undefined}
                >
                    <div
                        className={cx(
                            styles.selectedListAndFilterAndInputWrap,
                            !this.props.buttonIcon && !this.props.buttonText && styles.inputNoButton,
                            labelPosition !== LABEL_POSITION.LEFT_INSIDE && styles.inputLabelIsOutside,
                            labelPosition === LABEL_POSITION.LEFT_INSIDE &&
                                styles.selectedListAndFilterAndInputWrapWithLabelPositionLeftInside
                        )}
                        onClick={() => (this.props.clickable || this.props.clickableAndEditable) && this.toggleList()}
                        style={this.props.clickable && {cursor: 'pointer'}}
                    >
                        {!this.props.selectedLabelsAsInputText &&
                        Object.keys(this.props.selectedElements || {}).length ? (
                            <ul
                                className={cx(
                                    styles.selectedList,
                                    hideInputText ? styles.selectedListWithoutInputText : null
                                )}
                            >
                                {Object.keys(this.props.selectedElements || {}).map(itemKey => {
                                    if (
                                        this.props.selectedElements[itemKey] &&
                                        this.props.selectedElements[itemKey] !== '' &&
                                        !renderedLabel[this.props.selectedElements[itemKey]]
                                    ) {
                                        renderedLabel[this.props.selectedElements[itemKey]] = true;
                                        LOG([`${this.props.selectedElements[itemKey]}`]);
                                        return (
                                            <li
                                                key={itemKey}
                                                className={cx(
                                                    styles.selectedListItem,
                                                    this.props.noInfo && styles.selectedListItemNoInfo,
                                                    this.props.triple && styles.selectedListItemTriple
                                                )}
                                                title={htmlToText(this.props.selectedElements[itemKey])}
                                            >
                                                <div
                                                    className={cx(
                                                        styles.selectedListItemLabel,
                                                        (this.props.hideRemoveElementButton ||
                                                            `${this.props.hideRemoveElementButtonForId}` ===
                                                                `${itemKey}`) &&
                                                            styles.hideRemoveElementButton
                                                    )}
                                                >
                                                    {htmlToText(this.props.selectedElements[itemKey])}
                                                </div>
                                                {!this.props.hideRemoveElementButton &&
                                                    (!this.props.hideRemoveElementButtonForId ||
                                                        `${this.props.hideRemoveElementButtonForId}` !==
                                                            `${itemKey}`) && (
                                                        <div
                                                            className={styles.selectedListItemIcon}
                                                            onClick={event =>
                                                                this.onRemoveElement(
                                                                    event,
                                                                    this.props.selectedElements[itemKey]
                                                                )
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faTimes as any} />
                                                        </div>
                                                    )}
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        ) : null}
                        <div className={styles.filterInputWrap}>
                            {this.props.filter ? (
                                this.props.filter === true || Array.isArray(this.props.filter) ? (
                                    <select
                                        className={styles.filter}
                                        onChange={this.onFilterChange}
                                        value={this.props.currentFilter}
                                    >
                                        {(Array.isArray(this.props.filter)
                                            ? this.props.filter
                                            : ['contains', 'starts', 'equals', 'ends']
                                        ).map(
                                            key =>
                                                this.filterLabelHash[key] && (
                                                    <option key={key} value={key}>
                                                        {this.filterLabelHash[key]}
                                                    </option>
                                                )
                                        )}
                                    </select>
                                ) : (
                                    <div onClick={() => this.elInput && this.elInput.focus()} className={styles.tag}>
                                        {this.props.filter}
                                    </div>
                                )
                            ) : null}
                            {hideInputText ? null : (
                                <>
                                    <input
                                        ref={el => (this.elInput = el)}
                                        type="text"
                                        title={this.props.label || 'Text input'}
                                        placeholder={this.props.placeholder}
                                        onChange={this.onNameChange}
                                        value={
                                            this.props.selectedLabelsAsInputText
                                                ? Object.values(this.props.selectedElements).join(', ')
                                                : this.props.value
                                        }
                                        onKeyDown={this.onInputKeyDown}
                                        onFocus={this.props.useAsMultipleSelection ? this.onFocus : undefined}
                                        onBlur={this.props.useAsMultipleSelection ? undefined : this.onBlur}
                                        autoComplete="nope"
                                        className={cx(
                                            styles.input,
                                            !this.props.filter && styles.inputWithoutFilter,
                                            this.props.selectedLabelsAsInputText && styles.inputWithLabelsAsInputText,
                                            this.props.oldBackOfficeStylesError && styles.hasError,
                                            this.props.disabled && styles.inputDisabled
                                        )}
                                        disabled={this.props.clickable ? true : undefined}
                                    />
                                    {this.props.selectedLabelsAsInputText ? (
                                        <FontAwesomeIcon
                                            onClick={
                                                this.props.useAsMultipleSelection
                                                    ? this.state.searchList.length > 0
                                                        ? this.clickOutside
                                                        : this.onFocus
                                                    : undefined
                                            }
                                            icon={
                                                this.state.searchList.length > 0 && !this.props.forceHideSearchList
                                                    ? faChevronUp
                                                    : (faChevronDown as any)
                                            }
                                            className={styles.selectListToggleIcon}
                                        />
                                    ) : null}
                                </>
                            )}
                            {this.props.popOverText ? (
                                <Popover
                                    isOpen={this.state.isPopoverOpen}
                                    positions={'left'}
                                    onClickOutside={() => this.setState(prev => ({isPopoverOpen: !prev.isPopoverOpen}))}
                                    content={({position, childRect, popoverRect}) => (
                                        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                            position={position}
                                            childRect={childRect}
                                            popoverRect={popoverRect}
                                            arrowColor={'white'}
                                            arrowSize={5}
                                            arrowStyle={{
                                                opacity: 0.7,
                                            }}
                                        >
                                            <div
                                                onClick={() =>
                                                    this.setState(prev => ({isPopoverOpen: !prev.isPopoverOpen}))
                                                }
                                            >
                                                <div className={styles.popover}>{this.props.popOverText}</div>
                                            </div>
                                        </ArrowContainer>
                                    )}
                                >
                                    <button
                                        type="button"
                                        title={'Popover text'}
                                        className={cx(styles.buttonPopoverText)}
                                        onClick={() => this.setState(prev => ({isPopoverOpen: !prev.isPopoverOpen}))}
                                    >
                                        {buttonIcons['info']}
                                    </button>
                                </Popover>
                            ) : null}
                        </div>
                        {!this.props.hideCounter ? (
                            <>
                                {(this.props.filter || this.props.showCounter) && this.state.resultLabel && (
                                    <div
                                        className={styles.counter}
                                        onClick={() => this.elInput && this.elInput.focus()}
                                    >
                                        {this.state.resultLabel}
                                    </div>
                                )}
                            </>
                        ) : null}
                    </div>
                    {this.state.searchList.length > 0 && !this.props.forceHideSearchList ? (
                        <ul
                            className={cx(
                                styles.suggestionListWrap,
                                this.props.listMaxHeight && styles.listHasMaxHeight,
                                this.props.listSticky && styles.listSticky
                            )}
                            aria-hidden="true"
                            style={{
                                top: 'unset',
                                left: 'unset',
                                display: 'block',
                                marginTop: 5,
                                maxHeight: this.props.listMaxHeight ? this.props.listMaxHeight : 'max-content',
                            }}
                        >
                            {this.props.inputSearchList ? (
                                <input
                                    type="text"
                                    placeholder={`Search`}
                                    id={`input-search-list`}
                                    value={this.state.inputSearchListValue}
                                    onChange={this.onInputSearchListChange}
                                    className={styles.inputSearchList}
                                />
                            ) : null}
                            {this.state.searchList.map((element, index) => {
                                // @ts-ignore
                                return (
                                    <li
                                        key={element.ID}
                                        className={cx(
                                            styles.suggestionListElement,
                                            this.props.noInfo && styles.listElementNoInfo,
                                            this.props.triple && styles.listElementTriple
                                        )}
                                        onMouseEnter={() => this.onElementListMouseOver(index)}
                                        onMouseDown={() => this.onElementListClick(index)}
                                        role="option"
                                        style={
                                            this.state.inputSearchListValue &&
                                            !element.NAME.toLowerCase().includes(
                                                this.state.inputSearchListValue.toLowerCase()
                                            )
                                                ? {display: 'none'}
                                                : {}
                                        }
                                    >
                                        {isTextHTML(element.NAME) ? (
                                            <a
                                                tabIndex={-1}
                                                title={htmlToText(element.NAME)}
                                                className={cx(
                                                    styles.suggestionListElementLink,
                                                    this.state.highlighted === index &&
                                                        styles.suggestionListElementLinkActive
                                                )}
                                            >
                                                <>
                                                    {this.props.useAsMultipleSelection && (
                                                        <input
                                                            type="checkbox"
                                                            className={cx(
                                                                styles.listElementLinkCheckbox,
                                                                this.state.highlighted === index &&
                                                                    styles.listElementLinkCheckboxActive
                                                            )}
                                                            readOnly
                                                            checked={
                                                                Object.values(this.props.selectedElements).indexOf(
                                                                    element.NAME
                                                                ) > -1
                                                            }
                                                        />
                                                    )}
                                                    {element.NAME}
                                                </>
                                            </a>
                                        ) : (
                                            <a
                                                tabIndex={-1}
                                                title={element.NAME}
                                                className={cx(
                                                    styles.suggestionListElementLink,
                                                    this.state.highlighted === index &&
                                                        styles.suggestionListElementLinkActive
                                                )}
                                            >
                                                {this.props.useAsMultipleSelection && (
                                                    <input
                                                        type="checkbox"
                                                        className={cx(
                                                            styles.listElementLinkCheckbox,
                                                            this.state.highlighted === index &&
                                                                styles.listElementLinkCheckboxActive
                                                        )}
                                                        readOnly
                                                        checked={
                                                            Object.values(this.props.selectedElements).indexOf(
                                                                element.NAME
                                                            ) > -1
                                                        }
                                                    />
                                                )}
                                                {element.NAME}
                                            </a>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    ) : null}
                </ClickOutside>
                {/*<InputContainer*/}
                {/*    double={this.props.double}*/}
                {/*    triple={this.props.triple}*/}
                {/*    size={this.props.size}*/}
                {/*    half={this.props.half}*/}
                {/*>*/}
                {/*<FirstRow>*/}
                {/*    {this.props.outsideLabel && (*/}
                {/*        <FirstRowLabel mandatory={this.props.mandatory} outsideLabel={this.props.outsideLabel}>*/}
                {/*            {this.props.outsideLabel}*/}
                {/*        </FirstRowLabel>*/}
                {/*    )}*/}
                {/*    {this.props.label && (*/}
                {/*        <FirstRowLabel*/}
                {/*            mandatory={this.props.mandatory}*/}
                {/*            popOverTextFirstRow={this.props.popOverTextFirstRow}*/}
                {/*            onButtonAddFromListClick={this.props.onButtonAddFromListClick}*/}
                {/*        >*/}
                {/*            {this.props.label}*/}
                {/*        </FirstRowLabel>*/}
                {/*    )}*/}
                {/*    <React.Fragment>{this.props.children}</React.Fragment>*/}
                {/*</FirstRow>*/}

                {/*{this.props.popover && <CustomPopoverInput content={this.props.popover} />}*/}
                {/*{this.props.buttonIcon && this.props.buttonIcon !== 'none' && (*/}
                {/*    <button*/}
                {/*        type="button"*/}
                {/*        className={cx(styles.button, this.props.buttonDisabled && styles.buttonDisabled)}*/}
                {/*        onClick={!this.props.buttonDisabled && this.props.buttonAction}*/}
                {/*    >*/}
                {/*        <FontAwesomeIcon*/}
                {/*            icon={this.props.buttonIcon}*/}
                {/*            color={this.props.buttonColor || this.props.buttonDisabled ? 'gray' : 'green'}*/}
                {/*        />*/}
                {/*    </button>*/}
                {/*)}*/}
                {/*{this.props.error && (*/}
                {/*    <div style={{marginRight: 32}} className={styles.errorWrap}>*/}
                {/*        {this.props.oldBackOfficeStylesError ? (*/}
                {/*            <Error wizardResponse={true}>{this.props.error}</Error>*/}
                {/*        ) : (*/}
                {/*            <Error>{this.props.error}</Error>*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*)}*/}
                {/*{this.props.errorVarietyFinder ? (*/}
                {/*    <div*/}
                {/*        style={{*/}
                {/*            marginLeft: 3,*/}
                {/*            marginTop: 3,*/}
                {/*            color: 'red',*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        {this.props.errorVarietyFinder}*/}
                {/*    </div>*/}
                {/*) : null}*/}
                {/*</InputContainer>*/}
                {/*{this.props.removeStyleClear ? null : <div style={{clear: 'both'}}>{}</div>}*/}
            </section>
        );
    }
}

export default TextLabelInput;
