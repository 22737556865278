import React from 'react';

interface IIconTickProps {
    style?: any;
    className?: any;
    color?: string;
}

function Tick({style: userStyle, color, className}: IIconTickProps) {
    return (
        <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={userStyle}
            className={className}
        >
            <path
                d="M6.58325 9.91675L8.24992 11.5834L12.4166 7.41675"
                stroke={color || 'white'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.33329 2.28176C6.59956 1.54918 8.03705 1.1645 9.49996 1.16676C14.1025 1.16676 17.8333 4.89759 17.8333 9.50009C17.8333 14.1026 14.1025 17.8334 9.49996 17.8334C4.89746 17.8334 1.16663 14.1026 1.16663 9.50009C1.16663 7.98259 1.57246 6.55842 2.28163 5.33342"
                stroke={color || 'white'}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
}

export default Tick;
