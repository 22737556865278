export const getDataSheetFromVidQuery = (varietyIds: string[]) => {
    return {
        query: `
    {
      datasheetFromVid(
        varietyids:${JSON.stringify(varietyIds)}
      ){
        applicationnumber
        applicant
        app_date
        app_pub_date
        breeder
        breeder_ref
        country
        countryid
        crop_sectors
        end_date
        extra_data_url
        future_exp_date
        grant_date
        grant_number
        grant_pub_date
        maintainer
        name
        other_names
        other_parties
        pub_name
        pub_type_name
        publicationtypeid
        publicationextraid
        remark
        reg_status
        renewal_date
        species_code
        species_classes
        species_princ_bot_name
        titleholder
        varietyid
        websiteurl
        denominationsList
      }
    }`,
    };
};
