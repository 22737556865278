import React, {useEffect, useState} from 'react';
import CandidateVarietiesFieldsArea from './Components/CandidateVarietiesFields/CandidateVarietiesFieldsArea';
import CandidateVarietiesTable from './Components/CandidateVarietiesTable/CandidateVarietiesTable';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import {History} from 'history';
import {ModalErrorVersion2} from '../../../../commonModals';
import NavigationTLO from '../../../../shared/NavigationTLO';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {
    apiTqSearchCandidateVarietiesDownloadPhotos,
    apiTqSearchCandidateVarietiesFetch,
    TqSearchCandidateVarietiesTableRowData,
    TqSearchCandidateVarietiesUrlParamsData,
} from './TqSearchCandidateVarietiesPageService';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {getPreSignedURLFetchRequest} from '../../../../utils/requests';
import {injectIntl, IntlShape} from 'react-intl';
import {saveAs} from 'file-saver';
import {trackPageView} from '../../../../utils';

const modalScreenTypes = {ERROR: 'ERROR'};

interface TqSearchCandidateVarietiesPageProps {
    intl: IntlShape;
    history: History;
    searchPath: string;
    screen: string;
}

const TqSearchCandidateVarietiesPage = (props: TqSearchCandidateVarietiesPageProps & RouteComponentProps) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [urlParams, setUrlParams] = useState<TqSearchCandidateVarietiesUrlParamsData | null>(null);
    const [candidateVarieties, setCandidateVarieties] = useState<Array<TqSearchCandidateVarietiesTableRowData> | null>(
        null
    );
    const [timestamp, setTimestamp] = useState(0);
    const [applicants, setApplicants] = useState<Array<string>>([]);

    const loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        const parameters: TqSearchCandidateVarietiesUrlParamsData = JSON.parse(JSON.stringify({}));
        if (domainArray.length > 1) {
            const params = domainArray.pop();
            if (params)
                params.split('&').forEach(param => {
                    const paramElements = param.split('=');
                    if (paramElements.length === 2) {
                        const key = paramElements[0];
                        parameters[key] = decodeURIComponent(paramElements[1]);
                    }
                });
        }
        if (
            parameters.registerId &&
            parameters.speciesId &&
            parameters.speciesName &&
            parameters.denomination &&
            parameters.reference &&
            parameters.applicantName &&
            parameters.countryId
        ) {
            setUrlParams(Object.assign({}, parameters, {confidential: '0'}));
            if (parameters.applicantName.includes(', ')) {
                setApplicants(parameters.applicantName.split(', '));
            } else {
                setApplicants([parameters.applicantName]);
            }
        }
    };

    const loadCandidateVarietiesList = (urlParams: TqSearchCandidateVarietiesUrlParamsData) => {
        setLoading(true);
        apiTqSearchCandidateVarietiesFetch(urlParams)
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.candidateVarieties) {
                    setCandidateVarieties(jsonResponse.data.candidateVarieties);
                    setTimestamp(Date.now());
                }
            })
            .catch(error => {
                setErrorMessage(`Candidate Varieties list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        trackPageView({documentTitle: 'candidateVarieties'});
        loadUrlParams();
    }, []);

    useEffect(() => {
        urlParams !== null && loadCandidateVarietiesList(urlParams);
    }, [urlParams]);

    const closeErrorModal = () => {
        setErrorMessage(null);
        setModalScreen(null);
    };

    const goBackToSearch = () => props.history.goBack();

    const downloadPhotos = () => {
        setLoading(true);
        if (urlParams !== null && urlParams.registerId && urlParams.countryId) {
            apiTqSearchCandidateVarietiesDownloadPhotos(urlParams.registerId, urlParams.countryId)
                .then(response => {
                    if (response && !Array.isArray(response) && response.signedUrl) {
                        const {signedUrl} = response;
                        if (urlParams !== null) {
                            const fileName = urlParams.registerId + '_' + urlParams.countryId + '_photos.zip';
                            getPreSignedURLFetchRequest(signedUrl)
                                .then(response => response.blob())
                                .then(responseBlob => {
                                    saveAs(responseBlob, fileName);
                                });
                        }
                    }
                })
                .catch(error => {
                    setErrorMessage(`error downloading photos: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`Technical Liaison Officer`} />
            <NavigationTLO />
            <MainWrapper>
                <div style={{marginBottom: 20, minHeight: 600, textAlign: 'center'}}>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div style={{marginRight: 10, marginBottom: 20}}>
                            <FormFooterButton
                                color={buttonColor.whiteGray}
                                clickAction={goBackToSearch}
                                icon={faArrowLeft}
                            >
                                {`Go back to search`}
                            </FormFooterButton>
                            <div style={{clear: 'both'}}>{}</div>
                        </div>
                        {urlParams !== null && (
                            <CandidateVarietiesFieldsArea urlParams={urlParams} applicants={applicants} />
                        )}
                    </FormWrapper>
                    <CandidateVarietiesTable
                        intl={props.intl}
                        candidateVarieties={candidateVarieties}
                        timestamp={timestamp}
                        downloadPhotos={downloadPhotos}
                    />
                </div>
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(TqSearchCandidateVarietiesPage));
