import {API_SERVERLESS, getFetch, postFetch, postForm} from '~commonApi';
import {
    computeGetUploadPreSignedURLPromises,
    computePostFilesToPreSignedURLPromises,
    postFileToPreSignedURLRequest,
} from '../../utils/requests';

export function apiCommunicationCentreModalInternalReplyBOAClients(jsonData: {applicationNumbers: string[]}) {
    return postFetch(`${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIRBC/replyBOA/clients`, true, jsonData);
}

export function apiCommunicationCentreModalInternalReplyBOACCList() {
    return getFetch(`${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIRBCC/replyBOA/ccList`, true);
}

const getFormData = ({
    communicationId,
    document = {},
    index,
    identifier,
}: {
    communicationId: any;
    document: any;
    index: any;
    identifier: any;
}) => {
    const formData = new FormData();
    formData.append(`communicationId`, communicationId);
    if (document.file) {
        formData.append('files[]', identifier);
    } else {
        formData.append('files[]', '');
    }
    formData.append('filename', encodeURIComponent(document.filename));
    formData.append(`index`, index);
    if (document.serverIndex) {
        formData.append(`serverIndex`, document.serverIndex || null);
    }
    return formData;
};

export function apiDocumentUpload(jsonRequest: any, documentList: any) {
    let messageCommunicationId = '';
    return getFetch(`${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIRBGID/replyBOA/getCommunicationId`, true)
        .then(({communicationId}: {communicationId: any}) => {
            messageCommunicationId = communicationId;
            if (documentList.length) {
                return computeGetUploadPreSignedURLPromises({}).then(
                    ({presignedUrl, identifier}: {presignedUrl: any; identifier: any}) =>
                        postFileToPreSignedURLRequest({preSignedURL: presignedUrl, file: documentList[0]}).then(() =>
                            postForm(
                                `${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIRBUF/replyBOA/documentUploadFile`,
                                true,
                                getFormData({
                                    communicationId: messageCommunicationId,
                                    document: documentList[0],
                                    index: 0,
                                    identifier,
                                })
                            ).then(() => {
                                if (documentList.length > 1) {
                                    const uploadArray: any[] = [];
                                    let restDocumentList = [...documentList];
                                    restDocumentList.splice(0, 1);
                                    return Promise.all(
                                        computeGetUploadPreSignedURLPromises({documentList: restDocumentList})
                                    ).then(getUploadPreSignedURLResponses => {
                                        return Promise.all(
                                            computePostFilesToPreSignedURLPromises({
                                                getUploadPreSignedURLResponses,
                                                documentList: restDocumentList,
                                            })
                                        ).then(() => {
                                            restDocumentList.forEach((document, index) => {
                                                const formData = getFormData({
                                                    communicationId: messageCommunicationId,
                                                    document,
                                                    index: index + 1,
                                                    identifier: getUploadPreSignedURLResponses[index].identifier,
                                                });
                                                uploadArray.push(
                                                    postForm(
                                                        `${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIRBUF/replyBOA/documentUploadFile`,
                                                        true,
                                                        formData
                                                    )
                                                );
                                            });
                                            return Promise.all(uploadArray);
                                        });
                                    });
                                } else {
                                    return Promise.resolve();
                                }
                            })
                        )
                );
            } else {
                return Promise.resolve();
            }
        })
        .then(() => {
            return postFetch(
                `${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIRBMRE/replyBOA/messageReply`,
                true,
                Object.assign({}, jsonRequest, {communicationId: messageCommunicationId})
            );
        })
        .then(() => ({
            communicationId: messageCommunicationId,
        }));
}

export function apiCommunicationCentreModalInternalReplyBOAGetFileInfo(applicationNumber: any) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIRBCAN/replyBOA/getFileInfoIfExists/${applicationNumber}`,
        true
    );
}
