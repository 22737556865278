import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export function apiCommonContactInfo(): Promise<{
    data: {
        title: string | null;
        lastname: string | null;
        firstname: string | null;
        company: string | null;
        cpvoUserNumber: string | null;
        occupation: string | null;
        email: string | null;
        address: string | null;
        postalCode: string | null;
        city: string | null;
        country: string | null;
        phone: string | null;
    };
}> {
    return getFetch(`${API_SERVERLESS}/common/v3/contactInfo`, true);
}

export function apiMyPVRPublicAccess(JSONRequest: any) {
    return postFetch(`${API_SERVERLESS}/mypvr/v3/publicAccess`, false, JSONRequest);
}
