import React from 'react';
import styles from './AdviceRequestBadge.module.scss';
import cx from 'classnames';

const ADVICE_ID_MAP_TITLE: any = {
    '1': 'Not requested',
    '2': 'Requested',
    '3': 'Pending analysis',
    '4': 'Pending committee',
    '5': 'No observation',
    '6': 'Observation',
    '7': 'Cancelled',
    '8': 'Condition',
    '9': 'Prior Right',
};

export const CPVO_EXPERT_ADVICE_STATUS = {
    NOT_REQUESTED: 1,
    REQUESTED: 2,
    PENDING_ANALYSIS: 3,
    PENDING_COMMITTEE: 4,
    NO_OBSERVATION: 5,
    OBSERVATION: 6,
    CANCELLED: 7,
    CONDITION: 8,
    PRIOR_RIGHT: 9,
};

export const CPVO_EXPERT_ADVICE_STATUS_TEXT = {
    NOT_REQUESTED: 'Not requested',
    REQUESTED: 'Requested',
    PENDING_ANALYSIS: 'Pending analysis',
    PENDING_COMMITTEE: 'Pending committee',
    NO_OBSERVATION: 'No observation',
    OBSERVATION: 'observation',
    CANCELLED: 'Cancelled',
    CONDITION: 'Condition',
    PRIOR_RIGHT: 'Prior right',
};

interface IProps {
    adviceId?: any;
    style?: any;
    text?: any;
}

interface IState {}

class AdviceRequestBadge extends React.Component<IProps, IState> {
    getClassName = (id: number) => {
        switch (id) {
            case 1:
                return styles.badgeNotRequested;
            case 2:
                return styles.badgeRequested;
            case 3:
                return styles.badgePendingAnalysis;
            case 4:
                return styles.badgePendingCommittee;
            case 5:
                return styles.badgeNoObservation;
            case 6:
                return styles.badgeObservation;
            case 7:
                return styles.badgeCancelled;
            case 8:
                return styles.badgeCondition;
            case 9:
                return styles.badgePriorRight;
            default:
                return styles.badgeNotRequested;
        }
    };

    render() {
        let id = this.props.adviceId;
        if (this.props.text) {
            switch (this.props.text) {
                case CPVO_EXPERT_ADVICE_STATUS_TEXT.NOT_REQUESTED:
                    id = CPVO_EXPERT_ADVICE_STATUS.NOT_REQUESTED;
                    break;
                case CPVO_EXPERT_ADVICE_STATUS_TEXT.REQUESTED:
                    id = CPVO_EXPERT_ADVICE_STATUS.REQUESTED;
                    break;
                case CPVO_EXPERT_ADVICE_STATUS_TEXT.PENDING_ANALYSIS:
                    id = CPVO_EXPERT_ADVICE_STATUS.PENDING_ANALYSIS;
                    break;
                case CPVO_EXPERT_ADVICE_STATUS_TEXT.PENDING_COMMITTEE:
                    id = CPVO_EXPERT_ADVICE_STATUS.PENDING_COMMITTEE;
                    break;
                case CPVO_EXPERT_ADVICE_STATUS_TEXT.NO_OBSERVATION:
                    id = CPVO_EXPERT_ADVICE_STATUS.NO_OBSERVATION;
                    break;
                case CPVO_EXPERT_ADVICE_STATUS_TEXT.OBSERVATION:
                    id = CPVO_EXPERT_ADVICE_STATUS.OBSERVATION;
                    break;
                case CPVO_EXPERT_ADVICE_STATUS_TEXT.CANCELLED:
                    id = CPVO_EXPERT_ADVICE_STATUS.CANCELLED;
                    break;
                case CPVO_EXPERT_ADVICE_STATUS_TEXT.CONDITION:
                    id = CPVO_EXPERT_ADVICE_STATUS.CONDITION;
                    break;
                case CPVO_EXPERT_ADVICE_STATUS_TEXT.PRIOR_RIGHT:
                    id = CPVO_EXPERT_ADVICE_STATUS.PRIOR_RIGHT;
                    break;
                default:
                    id = -1;
                    break;
            }
        }

        if (!ADVICE_ID_MAP_TITLE[id] || id === 1) {
            return null;
        }

        return (
            <div className={cx(styles.badge, this.getClassName(id))} style={this.props.style || {}}>
                {ADVICE_ID_MAP_TITLE[id]}
            </div>
        );
    }
}

export default AdviceRequestBadge;
