import {API_SERVERLESS, getFetch} from '~commonApi';

export interface IVDCCase {
    caseId: number;
    submissionDate: string;
    testId: number;
    title: string;
}

export function apiVDCCasesGet(): Promise<IVDCCase[]> {
    return getFetch(`${API_SERVERLESS}/vdc/v4/VDCCasesGet`, true);
}
