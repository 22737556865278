import React, {useEffect, useState} from 'react';
import {injectIntl, IntlShape} from 'react-intl';
import {saveAs} from 'file-saver';
import styles from './QasRequirementsPage.module.scss';
import {Tree, TreeData} from '../../CommonComponents/TreeView/Interfaces/TreeViewInterfaces';
import {ModalErrorVersion2} from '../../../../commonModals';
import SelectInput from '~components/SelectInput';
import Empty from '../../../../components/Empty';
import {Prompt, RouteComponentProps, withRouter} from 'react-router-dom';
import Title from '../../../../components/Title';
import TreeView from '../../CommonComponents/TreeView/TreeView';
import AdminIcon from '../../CommonComponents/AdminIcon/AdminIcon';
import {
    apiCreateTree,
    apiDownloadTreeDocumentFile,
    apiFetchEOlistForTree,
    apiFetchTree,
} from '../../CommonApi/CommonApi';
import {getUserRoles} from '../../CommonFunctions/CommonFunctions';
import {getPreSignedURLFetchRequest} from '../../../../utils/requests';
import {History} from 'history';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import NavigationTLO from '../../../../shared/NavigationTLO';
import {trackPageView} from '../../../../utils';

const modalScreenTypes = {
    ERROR: 'ERROR',
};

const treeTypes = [
    {id: 0, value: 'Please select Tree Type'},
    {id: 1, value: 'My Quality Audit Area General Tree'},
    {id: 2, value: 'My Quality Audit Area EO Specific Tree'},
];

interface QasRequirementsPageProps {
    intl: IntlShape;
    history: History;
}

const QasRequirementsPage = (props: QasRequirementsPageProps & RouteComponentProps) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [, setTreeList] = useState<TreeData>(JSON.parse(JSON.stringify({})));
    const [treeTypeId, setTreeTypeId] = useState(0);
    const [generalTree, setGeneralTree] = useState<Tree>(JSON.parse(JSON.stringify({})));
    const [eoSpecificTree, setEoSpecificTree] = useState<Tree>(JSON.parse(JSON.stringify({})));
    const [generalTreeIsEdited, setGeneralTreeIsEdited] = useState(false);
    const [eoSpecificTreeIsEdited, setEoSpecificTreeIsEdited] = useState(false);
    const [tag, setTag] = useState('qas');
    const [eoSpecificId, setEoSpecificId] = useState('0');
    const [eoList, setEoList] = useState<Array<{id: string; value: string}>>([]);
    const [userRoles] = useState(getUserRoles());

    useEffect(() => {
        trackPageView({documentTitle: 'tloQasRequirements'});
    }, []);

    useEffect(() => {
        if (generalTreeIsEdited || eoSpecificTreeIsEdited) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }
    });

    const fetchEOlistForTree = () => {
        setLoading(true);
        apiFetchEOlistForTree()
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.eos.length > 0) {
                    const eoList: Array<{id: string; value: string}> = [
                        {id: '0', value: 'Please select EO specific tree'},
                    ];
                    jsonResponse.data.eos.forEach(eo => eoList.push({id: eo.eoId, value: eo.eoName}));
                    setEoList(eoList);
                }
            })
            .catch(error => {
                setErrorMessage(`Fetch EO List for tree error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const fetchTreeForNonAdminUser = () => {
        setLoading(true);
        apiFetchTree(tag)
            .then(jsonResponse => {
                if (jsonResponse) {
                    if (jsonResponse.general && jsonResponse.general.length > 0) {
                        setGeneralTree(jsonResponse.general[0].document);
                        setTag(jsonResponse.general[0].tag);
                    }
                    if (jsonResponse.eoSpecific && jsonResponse.eoSpecific.length > 0) {
                        setEoSpecificTree(jsonResponse.eoSpecific[0].document);
                        setTag(jsonResponse.eoSpecific[0].tag);
                    }
                }
            })
            .catch(error => {
                setErrorMessage(`Fetch Tree error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const fetchTree = () => {
        setLoading(true);
        apiFetchTree(tag, eoSpecificId)
            .then(jsonResponse => {
                if (jsonResponse) {
                    setTreeList(jsonResponse);
                    const generalTreeFromLS = localStorage.getItem(tag + 'GeneralTree');
                    const eoSpecificTreeFromLS = localStorage.getItem(tag + eoSpecificId + 'EoSpecificTree');
                    if (jsonResponse.general && generalTreeFromLS === null) {
                        if (jsonResponse.general.length > 0) {
                            setGeneralTree(jsonResponse.general[0].document);
                            setTag(jsonResponse.general[0].tag);
                        } else {
                            setGeneralTree(JSON.parse(JSON.stringify({})));
                        }
                    }
                    if (jsonResponse.eoSpecific && eoSpecificTreeFromLS === null) {
                        if (jsonResponse.eoSpecific.length > 0) {
                            setEoSpecificTree(jsonResponse.eoSpecific[0].document);
                            setTag(jsonResponse.eoSpecific[0].tag);
                        } else {
                            setEoSpecificTree(JSON.parse(JSON.stringify({})));
                        }
                    }
                }
            })
            .catch(error => {
                setErrorMessage(`Fetch Tree error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (userRoles.length === 1 && userRoles.indexOf('EOVIS') === 0) {
            //Show nothing
        } else {
            if (userRoles.indexOf('EOADM') === -1) {
                fetchTreeForNonAdminUser();
            } else {
                const generalTreeFromLS = localStorage.getItem(tag + 'GeneralTree');
                const eoSpecificTreeFromLS = localStorage.getItem(tag + eoSpecificId + 'EoSpecificTree');
                if (generalTreeFromLS === null && eoSpecificTreeFromLS === null) {
                    fetchTree();
                } else {
                    if (generalTreeFromLS !== null) {
                        setGeneralTreeIsEdited(true);
                        setGeneralTree(JSON.parse(generalTreeFromLS));
                    } else {
                        fetchTree();
                    }
                    if (eoSpecificTreeFromLS !== null) {
                        setEoSpecificTreeIsEdited(true);
                        setEoSpecificTree(JSON.parse(eoSpecificTreeFromLS));
                    } else {
                        fetchTree();
                    }
                }
            }
        }
    }, []);

    const onTreeTypeIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const treeTypeId = event.target.value;
        setTreeTypeId(Number(treeTypeId));
    };

    useEffect(() => {
        treeTypeId === 2 && eoList.length === 0 && fetchEOlistForTree();
    }, [treeTypeId]);

    const onEoSpecificIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const eoSpecificId = event.target.value;
        setEoSpecificId(eoSpecificId);
    };

    useEffect(() => {
        if (Number(eoSpecificId) > 0) {
            const eoSpecificTreeFromLS = localStorage.getItem(tag + eoSpecificId + 'EoSpecificTree');
            if (eoSpecificTreeFromLS === null) {
                fetchTree();
                setEoSpecificTreeIsEdited(false);
            } else {
                setEoSpecificTreeIsEdited(true);
                setEoSpecificTree(JSON.parse(eoSpecificTreeFromLS));
            }
        }
    }, [eoSpecificId]);

    const closeModal = () => setModalScreen(null);

    const downloadDocumentFile = (fileUUID: string, fileName: string) => {
        setLoading(true);
        apiDownloadTreeDocumentFile(fileUUID)
            .then(response => {
                if (response && response.signedUrl) {
                    const {signedUrl} = response;
                    getPreSignedURLFetchRequest(signedUrl)
                        .then(response => response.blob())
                        .then(responseBlob => {
                            const saveFileName = fileName ? fileName : fileUUID;
                            saveAs(responseBlob, saveFileName);
                        });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading file: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const discardTreeChanges = (tag: string, typeOfTree: string) => {
        if (typeOfTree === 'General') {
            localStorage.removeItem(tag + typeOfTree + 'Tree');
            setGeneralTreeIsEdited(false);
        } else {
            localStorage.removeItem(tag + eoSpecificId + typeOfTree + 'Tree');
            setEoSpecificTreeIsEdited(false);
        }
        fetchTree();
    };

    const saveTree = (tree: Tree, typeOfTree: string) => {
        setLoading(true);
        const createTreeParams =
            typeOfTree === 'General'
                ? Object.assign({}, {tree, tag})
                : Object.assign({}, {tree, tag, eoId: eoSpecificId});
        apiCreateTree(createTreeParams)
            .then(() => {
                discardTreeChanges(tag, typeOfTree);
            })
            .catch(error => {
                setErrorMessage(`error saving tree: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getComboboxArea = () => {
        return (
            <React.Fragment>
                <div className={styles.mainFieldContainer}>
                    <SelectInput
                        value={treeTypeId}
                        onChange={onTreeTypeIdChange}
                        list={treeTypes}
                        outsideLabel={'Tree Type'}
                        outsideLabelWidth={160}
                        notAll={true}
                        double={true}
                    />
                    <div className={styles.adminIconArea}>
                        <AdminIcon />
                    </div>
                    {treeTypeId === 2 && (
                        <React.Fragment>
                            <div style={{clear: 'both'}}>{}</div>
                            <SelectInput
                                value={eoSpecificId}
                                onChange={onEoSpecificIdChange}
                                list={eoList}
                                outsideLabel={'EO Specific Tree'}
                                outsideLabelWidth={160}
                                notAll={true}
                                double={true}
                            />
                        </React.Fragment>
                    )}
                </div>
                <div style={{clear: 'both'}}>{}</div>
            </React.Fragment>
        );
    };

    const getTrees = () => {
        return userRoles.indexOf('EOADM') !== -1 ? (
            <React.Fragment>
                {treeTypeId === 1 && (
                    <TreeView
                        tree={generalTree}
                        treeIsEdited={generalTreeIsEdited}
                        isGeneral={true}
                        tag={tag}
                        isAdmin={userRoles.indexOf('EOADM') !== -1}
                        downloadFile={downloadDocumentFile}
                        saveTree={saveTree}
                        discardTreeChanges={discardTreeChanges}
                        isACArea={false}
                    />
                )}
                {treeTypeId === 2 && Number(eoSpecificId) > 0 && (
                    <React.Fragment>
                        <div style={{clear: 'both'}}>{}</div>
                        <TreeView
                            tree={eoSpecificTree}
                            treeIsEdited={eoSpecificTreeIsEdited}
                            tag={tag}
                            isGeneral={false}
                            eoSpecificId={eoSpecificId}
                            isAdmin={userRoles.indexOf('EOADM') !== -1}
                            downloadFile={downloadDocumentFile}
                            saveTree={saveTree}
                            discardTreeChanges={discardTreeChanges}
                            isACArea={false}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        ) : (
            <React.Fragment>
                {Object.keys(generalTree).length > 0 && (
                    <React.Fragment>
                        <Empty oneLine={true} double={undefined} width={undefined} height={5} />
                        <div style={{clear: 'both'}}>{}</div>
                        <Empty oneLine={true} double={undefined} width={13} height={0} />
                        <Title
                            titleDescription={undefined}
                            double={true}
                            triple={undefined}
                            full={undefined}
                        >{`My Quality Audit Area`}</Title>
                        <TreeView
                            tree={generalTree}
                            treeIsEdited={generalTreeIsEdited}
                            isGeneral={true}
                            tag={tag}
                            isAdmin={userRoles.indexOf('EOADM') !== -1}
                            hideLegend={Object.keys(eoSpecificTree).length > 0}
                            downloadFile={downloadDocumentFile}
                            saveTree={saveTree}
                            discardTreeChanges={discardTreeChanges}
                            isACArea={false}
                        />
                    </React.Fragment>
                )}
                {Object.keys(eoSpecificTree).length > 0 && (
                    <React.Fragment>
                        <Empty oneLine={true} double={undefined} width={undefined} height={25} />
                        <div style={{clear: 'both'}}>{}</div>
                        <Empty oneLine={true} double={undefined} width={13} height={0} />
                        <Title
                            titleDescription={undefined}
                            double={true}
                            triple={undefined}
                            full={undefined}
                        >{`My EO QAS Documents`}</Title>
                        <TreeView
                            tree={eoSpecificTree}
                            treeIsEdited={eoSpecificTreeIsEdited}
                            tag={tag}
                            isGeneral={false}
                            eoSpecificId={eoSpecificId}
                            isAdmin={userRoles.indexOf('EOADM') !== -1}
                            downloadFile={downloadDocumentFile}
                            saveTree={saveTree}
                            discardTreeChanges={discardTreeChanges}
                            isACArea={false}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    const getFormWrapperContent = () => {
        if (userRoles.length === 1 && userRoles.indexOf('EOVIS') === 0) {
            return (
                <div style={{paddingTop: 20}}>
                    <b style={{color: 'red'}}>
                        <span className="ng-scope">{`You have no permissions to access this page.`}</span>
                    </b>
                </div>
            );
        }
        if (userRoles.length === 1 && userRoles.indexOf('EOVIS') === 0) {
            return null;
        } else {
            if (userRoles.indexOf('EOADM') !== -1) {
                return getComboboxArea();
            }
        }
    };

    return (
        <>
            <Prompt
                when={generalTreeIsEdited || eoSpecificTreeIsEdited}
                message="You have unsaved changes, are you sure you want to leave?"
            />
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeModal} />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`Technical Liaison Officer | My Quality Audit Area`} />
            <NavigationTLO />
            <MainWrapper>
                {getFormWrapperContent() ? (
                    <FormWrapper paddingFormContent={'sm'}>{getFormWrapperContent()}</FormWrapper>
                ) : null}
                {userRoles.length === 1 && userRoles.indexOf('EOVIS') === 0 ? <div></div> : getTrees()}
                <div style={{height: 20}} />
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(QasRequirementsPage));
