import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import UploadFilter1 from './screenshots/UploadFilter_1.PNG';
import UploadFilter2 from './screenshots/UploadFilter_2.PNG';
import UploadFilter3 from './screenshots/UploadFilter_3.PNG';
import UploadFilter4 from './screenshots/UploadFilter_4.png';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeUploadFilterHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>
                        Displays a button that allows the user to Add documents.
                    </p>
                    <img src={UploadFilter1} alt="UploadFilter" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>
                        User can upload multiple files at the same response by clicking on Add document. The files
                        appear in a table-like layout{' '}
                    </p>
                    <img src={UploadFilter2} alt="UploadFilter" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>
                        It can take a <span className={styles.helpParameter}>parameter</span> like e.g.{' '}
                        <span className={styles.helpCode}>filter:26;</span> that pre-defines the type of the document
                    </p>
                    <img src={UploadFilter3} alt="UploadFilter" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>
                        <span className={styles.helpParameter}>parameter</span> can support multiple types e.g.{' '}
                        <span className={styles.helpCode}>filter:24,25,26;</span> that pre-defines multiple supported
                        document types
                    </p>
                    <img src={UploadFilter4} alt="UploadFilter" className={styles.modalHelpImage} />
                    <p>The list of possible filter numbers, that correspond to specific file types is the following</p>
                    <p>21: Upplication cover letter</p>
                    <p>23: Entitlement deed of assignment</p>
                    <p>24: Other confidential documents</p>
                    <p>25: Other documents</p>
                    <p>26: Photo</p>
                    <p>27: Procedural representative credentials</p>
                    <p>28: Priority</p>
                    <p>41: Financial information</p>

                    <p>
                        So for example, if we want the document type select list to have the options: Photo, Priority
                        and Financial information then the parameter should be{' '}
                        <span className={styles.helpCode}>filter:26,28,41;</span>{' '}
                    </p>
                    <p>If we want to include all the options, we do not add any parameter at all</p>
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeUploadFilterHelp;
