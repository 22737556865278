import {getFetch, postFetch, API_SERVERLESS} from '~commonApi';
import {IRegister} from '../../types';

export function apiApplicationSearch(
    criteria: any,
    DEFAULT_CRITERIA: any = {},
    excelTranslations?: boolean
): Promise<{
    data: {
        count: number;
        registers: IRegister[];
        token?: any;
    };
}> {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/mypvr/v3/myApplications?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}
