import React from 'react';
import ACAreaAdministratorSectionsActionButtons from './ACAreaAdministratorSectionsActionButtons';
import AdminIcon from '../TLO/CommonComponents/AdminIcon/AdminIcon';
import CustomTable from '~components/CustomTable';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {ModalConfirmVersion2, ModalErrorVersion2} from '../../commonModals';
import ModalSectionNew from './modals/ModalSectionNew';
import ModalSectionEdit from './modals/ModalSectionEdit';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {SectionElement, SectionsTableFetchData} from '../../types';
import {apiSectionDelete, apiSectionHasContents, apiSectionsTableFetch} from './ACAreaAdministratorSectionsService';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {getUserRoles} from '../TLO/CommonFunctions/CommonFunctions';
import {injectIntl, IntlShape} from 'react-intl';
import styles from './ACAreaAdministratorSections.module.scss';
import ACAreaAdministratorTabs from '../../shared/ACAreaAdministratorTabs';
import sendToList from '../ACAREA/Screens/MembersListACAREA/data/ContactList.json';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationACArea from '../../shared/NavigationACArea';
import {trackPageView} from '../../utils';

const RESULT_FIELDS_DEFAULT = ['ID', 'NAME', 'ABBREVIATION'];

interface IProps extends RouteComponentProps {
    intl: IntlShape;
}

interface IState {
    isModalConfirmDeleteOpen: number | false;
    isModalErrorOpen: string | false;
    isModalSectionNewOpen: boolean;
    isModalSectionEditOpen: SectionElement | false;
    loading: boolean;
    sectionsTable: SectionElement[];
}

class ACAreaAdministratorSections extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isModalConfirmDeleteOpen: false,
            isModalErrorOpen: false,
            isModalSectionNewOpen: false,
            isModalSectionEditOpen: false,
            loading: false,
            sectionsTable: [],
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'acareaAdministratorSections'});
        this.loadJSONs();
    }

    loadJSONs = () =>
        this.setState({loading: true}, () => {
            apiSectionsTableFetch()
                .then((jsonResponse: SectionsTableFetchData) => {
                    if (jsonResponse && jsonResponse.data && jsonResponse.data.categories) {
                        this.setState({sectionsTable: jsonResponse.data.categories});
                    }
                })
                .catch((error: any) => {
                    this.setState({isModalErrorOpen: `Sections Table fetch error: ${error.message}`});
                })
                .finally(() => this.setState({loading: false}));
        });

    closeModalError = () => this.setState({isModalErrorOpen: false});

    closeModalSectionNew = (refresh?: boolean) =>
        this.setState({isModalSectionNewOpen: false}, () => refresh && this.loadJSONs());

    closeModalSectionEdit = (refresh?: boolean) =>
        this.setState({isModalSectionEditOpen: false}, () => refresh && this.loadJSONs());

    closeModalConfirmDelete = () => this.setState({isModalConfirmDeleteOpen: false});

    onButtonNewSectionClick = () => this.setState({isModalSectionNewOpen: true});

    onTableIconModifyClick = (rowId: number, rowObject: SectionElement) =>
        this.setState({isModalSectionEditOpen: rowObject});

    onTableIconDeleteClick = (rowId: number) => this.setState({isModalConfirmDeleteOpen: rowId});

    onModalConfirmDeleteAction = () => {
        let hasChildren = false;
        const rowId = this.state.isModalConfirmDeleteOpen;
        this.setState({loading: true, isModalConfirmDeleteOpen: false}, () => {
            apiSectionHasContents(rowId || -1)
                .then((jsonResponse: any) => {
                    if (jsonResponse && jsonResponse.hasContents !== undefined) {
                        hasChildren = jsonResponse.hasContents;
                        if (hasChildren) {
                            this.setState({
                                isModalErrorOpen:
                                    'Unable to delete this section. Some contents exist for this section. Υou have to delete each content item before you delete the section.',
                            });
                        } else {
                            apiSectionDelete(rowId || -1)
                                .then(() => {
                                    this.loadJSONs();
                                })
                                .catch((error: any) => {
                                    this.setState({isModalErrorOpen: `error saving tree: ${error}`});
                                })
                                .finally(() => this.setState({loading: false}));
                        }
                    }
                })
                .catch((error: any) => {
                    this.setState({isModalErrorOpen: `error saving tree: ${error}`});
                })
                .finally(() => this.setState({loading: false}));
        });
    };

    render() {
        const actions = ACAreaAdministratorSectionsActionButtons(
            this.onTableIconModifyClick,
            this.onTableIconDeleteClick
        );
        const userRoles = getUserRoles();

        return (
            <>
                {this.state.isModalErrorOpen ? (
                    <ModalErrorVersion2
                        title={`Error`}
                        message={this.state.isModalErrorOpen}
                        close={this.closeModalError}
                    />
                ) : null}
                {this.state.isModalSectionNewOpen ? <ModalSectionNew close={this.closeModalSectionNew} /> : null}
                {this.state.isModalSectionEditOpen ? (
                    <ModalSectionEdit
                        close={this.closeModalSectionEdit}
                        sectionElement={this.state.isModalSectionEditOpen}
                    />
                ) : null}
                {this.state.isModalConfirmDeleteOpen ? (
                    <ModalConfirmVersion2
                        title={`Delete Section`}
                        message={`Are you sure you want to delete this Section?`}
                        buttonName={'Delete'}
                        action={this.onModalConfirmDeleteAction}
                        close={this.closeModalConfirmDelete}
                    />
                ) : null}
                {this.state.loading ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={'AC Area'} />
                <NavigationACArea />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        {userRoles.indexOf('ACADM') === -1 ? (
                            <div style={{paddingTop: 20}}>
                                <b style={{color: 'red'}}>
                                    <span className="ng-scope">{`You have no permissions to access this page.`}</span>
                                </b>
                            </div>
                        ) : (
                            <ACAreaAdministratorTabs />
                        )}
                    </FormWrapper>
                    {userRoles.indexOf('ACADM') !== -1 && (
                        <>
                            {!this.state.loading && (
                                <div style={{marginTop: 20}}>
                                    {userRoles.indexOf('ACADM') !== -1 ? (
                                        <div className="">
                                            <FormFooterButton
                                                color={buttonColor.blue}
                                                clickAction={this.onButtonNewSectionClick}
                                                icon={faPlus}
                                            >
                                                {`New Section`}
                                                <AdminIcon />
                                            </FormFooterButton>
                                        </div>
                                    ) : null}
                                    <div style={{clear: 'both'}}>{}</div>
                                    {this.state.sectionsTable.length === 0 ? (
                                        <div style={{paddingTop: 20, textAlign: 'left'}}>
                                            <b style={{color: 'red', textAlign: 'left'}}>
                                                <span className="ng-scope">{`No Results Found`}</span>
                                            </b>
                                        </div>
                                    ) : (
                                        <div className={styles.sectionsList}>
                                            <CustomTable
                                                version={2}
                                                tableName={'sectionsTable'}
                                                tableType={'OBJECT'}
                                                tableSource={this.state.sectionsTable}
                                                id={'ID'}
                                                hideExcelButton={true}
                                                resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                                                intl={this.props.intl}
                                                defaultOrder={'ID'}
                                                reverseOrder={false}
                                                count={this.state.sectionsTable.length}
                                                actions={userRoles.indexOf('ACADM') !== -1 ? actions : undefined}
                                                actionName={userRoles.indexOf('ACADM') !== -1 ? `Action` : undefined}
                                                showAdminIcon={userRoles.indexOf('ACADM') !== -1}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </MainWrapper>
                <Footer
                    customSendToList={sendToList.slice(1)}
                    headerTitle={'Contact Webmaster'}
                    subjectTag={'[ACAREA]'}
                />
            </>
        );
    }
}

export default withRouter(injectIntl(ACAreaAdministratorSections));
