import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
// import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';

function renderButton(popupContent, handler, icon) {
    return {
        title: popupContent,
        icon: object =>
            icon && (object.endOfFileStatus === 'draft' || object.endOfFileStatus === 'todayRequest') ? (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon} />
                </a>
            ) : (
                <div />
            ),
        handler,
    };
}

export default function MyPVREndOfFilesActionButtons(props, deleteRequest) {
    const buttons = [];
    // buttons.push(renderButton('To sign', (labelId, rowObject) => {}, faPencilAlt));
    buttons.push(
        renderButton(
            'To delete',
            (labelId, rowObject) => deleteRequest(rowObject.applicationNumber, rowObject.endOfFileStatus),
            faTrashAlt
        )
    );
    return buttons;
}
