import React, {useRef, useState} from 'react';
import LogoCPVO from '../img/logo_cpvo.png';
import LogoNAK from '../img/naktuinbouw.png';
import styles from './HeaderLogoMenu.module.scss';
import {checkIfUserHasAccessToMyPVR, CPVO_THIRDPARTY_ID, getDecodedJWT} from '../utils';
import useClickOutside from '../hooks/useOnClickOutside';
import {Link, useHistory} from 'react-router-dom';
import cx from 'classnames';
import {apiLogout} from '~commonApi/authentication';
import {
    ArrowDown as IconArrowDown,
    Dashboard as IconDashboard,
    IconSettings,
    Logout as IconLogout,
    Password as IconPassword,
    User as IconUser,
} from '../iconComponents';

interface IHeaderLogoMenuProps {
    showLogo?: 'NAK';
}

function HeaderLogoMenu({showLogo}: IHeaderLogoMenuProps) {
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    let decoded = getDecodedJWT();
    const wrapperRef = useRef(null);
    let history = useHistory();
    useClickOutside(wrapperRef, () => setIsDropDownOpen(false));
    const userHasAccessToMyPVR = checkIfUserHasAccessToMyPVR(decoded);

    const disconnect = (event: any) => {
        event && event.preventDefault();
        let loggedInUser = getDecodedJWT();
        const {thirdPartyId} = loggedInUser || {};
        apiLogout().then(
            (jsonResult: any) =>
                jsonResult &&
                jsonResult.result === 1 &&
                history.push(thirdPartyId === CPVO_THIRDPARTY_ID ? '/login?autologinOff' : `/login`)
        );
    };

    return (
        <header className={styles.headerLogoMenu}>
            {showLogo === 'NAK' ? (
                <a
                    href={'https://cpvo.europa.eu/en'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    style={{display: 'flex'}}
                >
                    <img src={LogoNAK} alt="logo" className={styles.logo} />
                </a>
            ) : (
                <a
                    href={'https://cpvo.europa.eu/en'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    style={{display: 'flex'}}
                >
                    <img src={LogoCPVO} alt="logo" className={styles.logo} />
                </a>
            )}
            {decoded ? (
                <section className={styles.nameAndDropdownMenuWrap} ref={wrapperRef}>
                    <div className={styles.nameArrowLogoutWrap}>
                        <h3 className={styles.userName}>
                            <IconUser color={'#197437'} className={styles.userIcon} />
                            <span className={styles.userNameForename}>
                                {decoded.forename} {decoded.name}
                            </span>
                        </h3>
                        <button
                            type={'button'}
                            title={'Toggle menu'}
                            className={styles.buttonArrow}
                            onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                        >
                            <IconArrowDown />
                        </button>
                    </div>
                    <ul className={cx(styles.dropdownWrap, isDropDownOpen ? styles.dropdownIsOpen : null)}>
                        <li>
                            <Link to={'/profile'}>
                                {' '}
                                <IconSettings color={'#4D4D4D'} /> {`My Profile`}
                            </Link>
                        </li>
                        {userHasAccessToMyPVR ? (
                            <li>
                                <Link to={'/dashboard'}>
                                    <IconDashboard color={'#4D4D4D'} /> {`My dashboard`}
                                </Link>
                            </li>
                        ) : null}
                        <li>
                            <Link to={'/changePassword'}>
                                {' '}
                                <IconPassword color={'#4D4D4D'} /> {`Change password`}
                            </Link>
                        </li>
                        <li>
                            <Link to={'/logout'} onClick={disconnect}>
                                {' '}
                                <IconLogout color={'#4D4D4D'} /> {`Logout`}
                            </Link>
                        </li>
                    </ul>
                </section>
            ) : null}
        </header>
    );
}

export default HeaderLogoMenu;
