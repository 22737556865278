export const examplePVRTemplate = `<p>&nbsp;</p>
<div style="padding-left: 440px;"><strong>&laquo;MAILADDRESS&raquo;</strong></div>
<div style="padding-left: 440px;">&nbsp;</div>
<div style="padding-left: 440px;">Angers, <strong>&laquo;DATE&raquo;</strong></div>
<p>Our ref: &laquo;ChronoOut&raquo;</p>
<p><strong><u>Subject</u></strong><strong>: Test for Distinctness, Uniformity and Stability</strong></p>
<p><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Submission of material</strong></p>
<p><strong>Species: </strong><strong>&laquo;SPENAME&raquo;</strong></p>
<table>
<tbody>
<tr>
<td width="206">
<p><strong>Breeder&rsquo;s reference</strong></p>
</td>
<td width="198">
<p><strong>CPVO File N&deg;</strong></p>
</td>
<td width="189">
<p><strong>Examination reference</strong></p>
</td>
</tr>
<tr>
<td width="206">
<p>&laquo;REFERENCELIST&raquo;</p>
</td>
<td width="198">
<p>&laquo;REGISTERIDLIST&raquo;</p>
</td>
<td width="189">
<p>&laquo;REFVARIETYLIST&raquo;</p>
</td>
</tr>
</tbody>
</table>
<p><strong><u>&nbsp;</u></strong></p>
<table>
<tbody>
<tr>
<td width="111">
<p><strong><u>Applicant(s)</u></strong><strong>:</strong></p>
</td>
<td width="508">
<p><strong>&laquo;LISTAPPLICANTS&raquo;</strong></p>
</td>
</tr>
</tbody>
</table>
<p>Dear &laquo;Techrepresentative&raquo;,</p>
<p>Could you please submit for the technical examination of each of your varieties the following plant material?</p>
<table>
<tbody>
<tr>
<td width="187">
<p>Quantity and Quality:</p>
</td>
<td width="407">
<p><strong>&laquo;QUANTITYQUALITY&raquo;</strong></p>
<p><strong>&nbsp;</strong></p>
</td>
</tr>
<tr>
<td width="187">
<p>Additional requirements:</p>
<p>&nbsp;</p>
</td>
<td width="407">
<p><strong>The plant material must be free of any pathogen.</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>&laquo;SPECIFICREQUESTLIST&raquo;</strong><strong> -</strong></p>
<p><strong>&nbsp;</strong></p>
</td>
</tr>
<tr>
<td width="187">
<p>Date of submission:</p>
</td>
<td width="407">
<p><strong>&laquo;SUBMISSIONSTARTINGDATE&raquo;</strong><strong> - </strong><strong>&laquo;SUBMISSIONDATE&raquo;</strong></p>
<p><strong>&nbsp;</strong></p>
</td>
</tr>
<tr>
<td width="187">
<p>Submission place:</p>
</td>
<td width="407">
<p><strong>&laquo;EXAMADDRESS&raquo;</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>Contact for the submission:</strong></p>
<p><strong>&laquo;EOTESTCONTACTNAME&raquo;</strong></p>
<p><strong>&laquo;EOTESTCONTACTTEL&raquo;</strong><strong>- </strong><strong>&laquo;EOTESTCONTACTMOBIL&raquo;</strong></p>
<p><strong>&laquo;EOTESTCONTACTMAIL&raquo;</strong></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p>The plant material has to be accompanied by the following information:</p>
<div style="padding-left: 40px;">-&nbsp;&nbsp; Species,</div>
<div style="padding-left: 40px;">-&nbsp;&nbsp; Name of the applicant,</div>
<div style="padding-left: 40px;">-&nbsp;&nbsp; Provisional designation/breeder&rsquo;s reference,</div>
<div style="padding-left: 40px;">-&nbsp;&nbsp; CPVO application number,</div>
<div style="padding-left: 40px;">-&nbsp;&nbsp; Reference of the examination office,</div>
<div style="padding-left: 40px;">-&nbsp;&nbsp; The statement &ldquo;On request of the CPVO&rdquo;.</div>
<p><strong>&nbsp;</strong></p>
<p style="text-align: justify;"><strong>Unless the above material request specifies otherwise, the &lsquo;General Instructions on the submission of samples&rsquo; enclosed are applicable. The &lsquo;General Instructions on the submission of samples&rsquo; may also be found on our website (</strong><strong><a href="https://cpvo.europa.eu/">cpvo.europa.eu</a></strong><strong>).</strong></p>
<p style="text-align: justify;">The material has to be submitted without any additional cost (free of charge, postage paid and free of duty) for the Community Plant Variety Office and the examination office mentioned above. <strong>The sender is responsible for all carriage incurred, and for proper delivery of the plant material, including all the necessary phytosanitary and customs requirements</strong>. <strong>Seeds must <u>not</u> have undergone any chemical treatment; plant material of vegetatively propagated varieties must <u>not</u> have undergone any chemical treatment that will affect subsequent development of the variety.</strong></p>
<p><strong>FAILURE TO COMPLY WITH ALL THE ABOVE INSTRUCTIONS WILL RESULT IN A REJECTION OF THE APPLICATION!</strong></p>
<p><strong>&nbsp;</strong></p>
<p style="text-align: justify;">At the end of the technical examination the applicant will receive a draft variety description of the candidate variety, provided that the examination office judges the candidate variety as distinct, uniform and stable.&nbsp;Said draft description may be expected for &laquo;Foreseendate&raquo;. The applicant will be informed on any problems which may arise during the technical examination and he will be given the opportunity to send comments. The applicant may visit the examination office <u>after making an appointment</u> regardless whether or not problems were reported.</p>
<p><strong>The examination fee to be paid for each growing period of </strong><strong><em>&laquo;SPENAME&raquo;</em></strong><strong> is <u>EUR&nbsp;</u></strong><strong><u>&laquo;FEEAMOUNT&raquo;</u></strong><strong><u> per variety.</u></strong></p>
<p style="text-align: justify;">Please note that the debit note for the first growing period will be sent by a separate mail.<strong>&laquo;FINADDRESS&raquo;</strong></p>
<p>Yours faithfully,</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p style="padding-left: 440px;"><strong>&laquo;EXPERT&raquo;</strong></p>`;
