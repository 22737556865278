import React, {useState} from 'react';
import CustomTable from '../../../../../../components/CustomTable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Info from '../../../../../../components/Info';
import {IntlShape} from 'react-intl';
import SelectInput from '~components/SelectInput';
import TextLabelInput from '../../../../../../components/TextLabelInput';
import {UploadedFileData} from '../../../../CommonComponents/ModalDocument/Interfaces/ModalDocumentInterfaces';
import UploadDocumentActionButton from '../../../../CommonComponents/ModalDocument/UploadDocumentActionButton/UploadDocumentActionButton';
import {faCheck, faEdit, faFlag, faInfo, faPlus} from '@fortawesome/free-solid-svg-icons';
import getIcon from '../../../../../../utils/icons';
import {saveAs} from 'file-saver';
import styles from './UploadDocumentsArea.module.scss';

export const DOCUMENT_TYPES = [
    {id: null, value: 'Please select a document type'},
    {id: 'CoverLetterReports', value: 'Cover Letter for Reports'},
    {id: 'ExaminationOtherDocuments', value: `Examination procedure - Other documents`},
    {id: 'FinalReport', value: `Final Report`},
    {id: 'InterimReport', value: 'Interim Report'},
    {id: 'Invoice', value: `Invoice`},
    {id: 'PhotoFinalReport', value: 'Photo Final Report'},
    {id: 'PreliminaryReport', value: `Preliminary Report`},
    {id: 'RequestPlantMaterial', value: `Request for Plant Material`},
    {id: 'TechnicalVerification', value: 'Technical Verification'},
    {id: 'VarietyDescription', value: `Variety Description`},
    {id: 'WithdrawalEOConfirmation', value: `Withdrawal Confirmation`},
];

const errorMessageTypes = {
    UNKNOWN: `This is not a valid application number. The value must be in range 19950001 to ${new Date().getFullYear()}9999!`,
    FORBIDDEN: 'This application is not linked to your account',
    DUPLICATE: `Application number already in the list`,
    NOT_RECEIPT: 'The reception of your application has not been finalised yet.',
    ADD: 'Please include the application number using the button + or "Enter" keyboard button.',
    NO_DOCUMENT_TYPE: 'You have to select a type for this document.',
    INVOICE_NOT_EDITED: 'You have to fill the details of the invoice document (click on button).',
};

interface UploadDocumentsAreaProps {
    intl: IntlShape;
    documentList: Array<UploadedFileData>;
    timestamp: number;
    applicationNumberPerDocument: {[key: string]: string};
    applicationNumberErrorPerDocument: {
        [key: string]: 'UNKNOWN' | 'FORBIDDEN' | 'DUPLICATE' | 'NOT_RECEIPT' | 'ADD';
    };
    documentTypeErrorPerDocument: {
        [key: string]: 'NO_DOCUMENT_TYPE' | 'INVOICE_NOT_EDITED';
    };
    checkApplicationNumber: (applicationNumber: string, fileName: string) => void;
    onAddingApplicationNumber: (applicationNumber: string, fileName: string) => void;
    onDocumentTypeSelect: (documentType: string, fileName: string) => void;
    deleteFile: (fileName: string) => void;
    editInvoice: (fileName: string) => void;
    showExplanationsModalInfo: () => void;
    criteria: {pageNumber: number};
    updateCriteriaValue: (
        // eslint-disable-next-line no-undef
        criteriaValue: {pageNumber: number},
        callback?: () => void
    ) => void;
    invoiceDocumentsEdited: {[key: string]: boolean};
}

const UploadDocumentsArea = (props: UploadDocumentsAreaProps) => {
    const [applicationNumberPerDocument, setApplicationNumberPerDocument] = useState<{[key: string]: string}>(
        props.applicationNumberPerDocument
    );

    const onApplicationNumberChange = (applicationNumber: string | undefined, fileName: string) => {
        if (applicationNumber !== undefined) {
            const applicationNumberValue = applicationNumber.substring(0, 8);
            setApplicationNumberPerDocument(
                Object.assign({}, applicationNumberPerDocument, {[fileName]: applicationNumberValue})
            );
            props.checkApplicationNumber && props.checkApplicationNumber(applicationNumberValue, fileName);
        }
    };

    const onAddingApplicationNumber = (applicationNumber: string | undefined, fileName: string) => {
        applicationNumber &&
            props.onAddingApplicationNumber &&
            props.onAddingApplicationNumber(applicationNumber, fileName);
    };

    const onSelectType = ({target: {value: documentType}}: React.ChangeEvent<HTMLSelectElement>, fileName: string) =>
        props.onDocumentTypeSelect && props.onDocumentTypeSelect(documentType, fileName);

    const deleteFile = (fileName: string) => props.deleteFile && props.deleteFile(fileName);

    const editInvoice = (fileName: string) => props.editInvoice && props.editInvoice(fileName);

    const actions = UploadDocumentActionButton(deleteFile);

    const getErrorHandlingDocumentTypeArea = (fileName: string) => {
        return (
            props.documentTypeErrorPerDocument[fileName] && (
                <div className={styles.documentTypeErrorArea}>
                    <Info>
                        <div className={styles.documentTypeErrorIconArea}>
                            <FontAwesomeIcon icon={faFlag as any} color={'#8a6d3b'} />
                        </div>
                        <div className={styles.documentTypeErrorTextArea}>
                            {errorMessageTypes[props.documentTypeErrorPerDocument[fileName]]}
                        </div>
                    </Info>
                </div>
            )
        );
    };

    const getDocumentTypeSelectionArea = (documentType: string | null, fileName: string) => (
        <div className={styles.documentTypeCellArea}>
            <SelectInput
                value={documentType === null ? '' : documentType}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onSelectType(event, fileName)}
                list={DOCUMENT_TYPES}
                notAll={true}
                height={25}
                width={330}
                noIcon={true}
            />
            {documentType === 'Invoice' && (
                <div
                    className={
                        Object.keys(props.invoiceDocumentsEdited).length > 0 &&
                        props.invoiceDocumentsEdited[fileName] === true
                            ? styles.editInvoiceButtonAreaEdited
                            : styles.editInvoiceButtonAreaNotEdited
                    }
                    onClick={() => editInvoice(fileName)}
                    title={'Edit'}
                >
                    <FontAwesomeIcon
                        icon={faEdit as any}
                        color={
                            Object.keys(props.invoiceDocumentsEdited).length > 0 &&
                            props.invoiceDocumentsEdited[fileName] === true
                                ? 'green'
                                : 'rgb(254,127,38)'
                        }
                    />
                </div>
            )}
            <div style={{clear: 'both'}}>{}</div>
            {getErrorHandlingDocumentTypeArea(fileName)}
        </div>
    );

    const getErrorHandlingApplicationNumberArea = (fileName: string) => {
        return (
            props.applicationNumberErrorPerDocument[fileName] && (
                <div className={styles.applicationNumberErrorArea}>
                    <Info>
                        <div className={styles.applicationNumberErrorIconArea}>
                            <FontAwesomeIcon icon={faFlag as any} color={'#8a6d3b'} />
                        </div>
                        <div className={styles.applicationNumberErrorTextArea}>
                            {errorMessageTypes[props.applicationNumberErrorPerDocument[fileName]]}
                        </div>
                    </Info>
                </div>
            )
        );
    };

    const getApplicationNumberInputArea = (fileName: string) => {
        const shouldButtonBeDisabled =
            !props.applicationNumberErrorPerDocument[fileName] ||
            !applicationNumberPerDocument[fileName] ||
            (props.applicationNumberErrorPerDocument[fileName] &&
                props.applicationNumberErrorPerDocument[fileName] !== 'ADD') ||
            (applicationNumberPerDocument[fileName] && applicationNumberPerDocument[fileName].length !== 8);
        const shouldShowCheckIcon =
            !props.applicationNumberErrorPerDocument[fileName] && applicationNumberPerDocument[fileName];

        return (
            <div className={styles.applicationNumberCellArea}>
                <TextLabelInput
                    onChange={(applicationNumber: string) => onApplicationNumberChange(applicationNumber, fileName)}
                    onEnter={() => onAddingApplicationNumber(applicationNumberPerDocument[fileName], fileName)}
                    value={applicationNumberPerDocument[fileName] || ''}
                    delay={300}
                    outsideLabel={undefined}
                    outsideLabelWidth={undefined}
                    buttonAction={(_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                        onAddingApplicationNumber(applicationNumberPerDocument[fileName], fileName)
                    }
                    buttonIcon={shouldShowCheckIcon ? faCheck : faPlus}
                    buttonDisabled={shouldButtonBeDisabled}
                    uniqueValue={true}
                    isOnModal={true}
                />
                {getErrorHandlingApplicationNumberArea(fileName)}
            </div>
        );
    };

    return (
        <div>
            {props.documentList.length > 0 && (
                <CustomTable
                    version={2}
                    {...props}
                    pageNumber={props.criteria.pageNumber}
                    updateCriteriaValue={(criteriaValue: {pageNumber: number}) =>
                        props.updateCriteriaValue(criteriaValue)
                    }
                    notSortable={['fileName', 'size', 'fileType', 'applicationNumber']}
                    tableName={'cpvoBoxDocumentUpload'}
                    tableType={'OBJECT'}
                    tableSource={props.documentList || []}
                    dataFilter={null}
                    id={'fileName'}
                    setLastCursor={null}
                    resultFieldsDefault={['fileName', 'size', 'fileType', 'applicationNumber']}
                    intl={props.intl}
                    formatFunctions={{
                        fileName: (filename: any) => (
                            <div
                                style={{
                                    width: 185,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                                title={filename || ''}
                            >
                                {filename}
                            </div>
                        ),
                        size: (size: number) => (
                            <div className={styles.sizeColumnArea}>{`${Math.floor(size * 100) / 100} KB`}</div>
                        ),
                        extension: (extension: string) => <img src={getIcon(extension || 'pdf')} alt={extension} />,
                        fileType: (fileType: string | null, rowObject: UploadedFileData) =>
                            getDocumentTypeSelectionArea(fileType, rowObject.fileName),
                        applicationNumber: (_applicationNumber: string, rowObject: UploadedFileData) =>
                            getApplicationNumberInputArea(rowObject.fileName),
                    }}
                    count={(props.documentList || []).length}
                    hideExcelButton={true}
                    noChangePageSize={true}
                    forehandColumn={(rowObject: UploadedFileData) => (
                        <img
                            className={styles.downloadDocumentIcon}
                            alt={'Download'}
                            src={getIcon(rowObject.extension || 'pdf')}
                            onClick={event => {
                                event.stopPropagation();
                                rowObject.file && saveAs(rowObject.file, rowObject.fileName);
                            }}
                        />
                    )}
                    headerPopup={{
                        fileType: {
                            description: 'Explanations on documents types',
                            handler: props.showExplanationsModalInfo,
                        },
                    }}
                    timestamp={props.timestamp}
                    actionName={`Action`}
                    actions={actions}
                    floatHeaderIcon={'none'}
                    topCaption={
                        <div
                            style={{
                                background: '#0a3d66',
                                color: 'white',
                                padding: 8,
                                borderRadius: 6,
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 12,
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faInfo as any}
                                color={'white'}
                                style={{
                                    marginRight: 9,
                                    borderRadius: '50%',
                                    border: '1px solid white',
                                    width: 16,
                                    height: 16,
                                    padding: 5,
                                }}
                            />
                            {`PDF documents should be uploaded without encryption`}
                        </div>
                    }
                />
            )}
            <div style={{clear: 'both'}}>{}</div>
        </div>
    );
};

export default UploadDocumentsArea;
