import React from 'react';
import {injectIntl} from 'react-intl';
import styles from './AuthenticationRegistration.module.scss';
import {Link, withRouter} from 'react-router-dom';
import {Button, Checkbox, RadioButton, TextAreaInput, TextInput, TextLabelInput} from '../../componentsFormV2';
import {checkEmail, checkPhoneNumber} from '~utils';
import {apiCountries} from '~commonApi/static';
import {ModalAlertVersion2, ModalErrorVersion2} from '../../commonModals';
import {
    Col,
    Error,
    Footer,
    FormFooter,
    FormWrapper,
    Grid,
    HeaderBreadcrumb,
    HeaderCookies,
    HeaderLoading,
    HeaderLogo,
    HeaderTitle,
} from '../../componentsLayout';
import FormHeader from './FormHeader';
import {apiRegister} from './AuthenticationRegistrationService';
import {CPVO_THIRDPARTY_ID, getDecodedJWT, trackPageView} from '../../utils';

const NATURE = {NATURAL_PERSON: '1', LEGAL_ENTITY: '2'};

interface IProps {
    intl: any;
    history: any;
}

interface IState {
    checkboxNewsletterValue: boolean;
    username: any;
    nature: any;
    firstName: any;
    lastName: any;
    email: any;
    errorMessage: string;
    emailError: any;
    emailConfirmation: any;
    emailConfirmationError: any;
    company: any;
    country: any;
    companyWebsite: any;
    phone: any;
    phoneError: any;
    error: any;
    street: any;
    ok: boolean;
    postcode: any;
    city: any;
    countryId: any;
    countryErrorMessage: string;
    selectedCountry: any;
    countryNameHash: any;
    countryListByIdName: any;
    loading: number;
    timestamp: number;
}

class AuthenticationRegistration extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = Object.assign(
            {},
            {
                checkboxNewsletterValue: false,
                username: '',
                nature: null,
                firstName: '',
                lastName: '',
                errorMessage: '',
                email: '',
                emailError: false,
                emailConfirmation: '',
                emailConfirmationError: false,
                company: '',
                country: null,
                companyWebsite: '',
                phone: '',
                phoneError: false,
                error: null,
                street: '',
                ok: false,
                postcode: '',
                city: '',
                countryId: '',
                countryErrorMessage: '',
                selectedCountry: null,
                countryNameHash: null,
                countryListByIdName: null,
                loading: 0,
                timestamp: Date.now(),
            }
        );
    }

    componentDidMount() {
        trackPageView({documentTitle: 'register'});
        this.loadJSONs();
    }

    loadJSONs = () => {
        apiCountries().then((jsonData: any) => {
            if (jsonData) {
                const countryNameHash: any = {};
                (jsonData || []).forEach((countryElement: any) => {
                    if (!countryNameHash[countryElement.COUNTRYNAME]) {
                        countryNameHash[countryElement.COUNTRYNAME] = [];
                    }
                    countryNameHash[countryElement.COUNTRYNAME].push(countryElement.COUNTRYID);
                });
                const countryListByIdName = Object.keys(countryNameHash).map(countryName => ({
                    ID: countryNameHash[countryName].join(','),
                    NAME: countryName,
                }));
                const selectedCountry: any = {};
                const selectedCountryKeyArray = Object.keys(this.state.selectedCountry || {});
                countryListByIdName
                    .filter(i => selectedCountryKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectedCountry[item.ID] = item.NAME));
                this.setState({countryListByIdName, countryNameHash, selectedCountry, timestamp: Date.now()});
            }
        });
    };

    sendRegistration = () => {
        if (!this.isValidForm()) {
            this.setState({error: 'Registration error - Please fill all mandatory fields.'});
        } else {
            this.setState(
                prev => ({loading: prev.loading + 1, error: null}),
                () => {
                    const {
                        username,
                        nature,
                        firstName,
                        lastName,
                        email,
                        company,
                        companyWebsite,
                        phone,
                        street,
                        postcode,
                        city,
                        selectedCountry,
                        checkboxNewsletterValue,
                    } = this.state || {};
                    apiRegister({
                        username,
                        nature,
                        firstName,
                        lastName,
                        email,
                        company,
                        companyWebsite,
                        phone,
                        street,
                        postcode,
                        city,
                        countryId: Object.keys(selectedCountry)[0],
                        newsletter: checkboxNewsletterValue,
                    })
                        .then((result: any) => {
                            if (result.errorMessage) {
                                this.setState({errorMessage: result.errorMessage});
                            } else {
                                this.setState({ok: true});
                            }
                        })
                        .then(() => this.setState(prev => ({loading: prev.loading - 1})));
                }
            );
        }
    };

    isValidForm = () => {
        if (this.state.username === '') return false;
        if (this.state.nature === null) return false;
        if (this.state.firstName === '') return false;
        if (this.state.lastName === '') return false;
        if (this.state.email === '') return false;
        if (this.state.emailConfirmation === '') return false;
        if (this.state.street === '') return false;
        if (this.state.postcode === '') return false;
        if (this.state.city === '') return false;
        if (!this.state.selectedCountry || !Object.keys(this.state.selectedCountry).length) return false;
        return true;
    };

    closeError = () => this.setState({errorMessage: ''});

    goToLogin = () => {
        let loggedInUser = getDecodedJWT();
        const {thirdPartyId} = loggedInUser || {};
        if (thirdPartyId === CPVO_THIRDPARTY_ID) {
            this.props.history.replace('/login?autologinOff');
        } else {
            this.props.history.replace('/login');
        }
    };

    getMandatoryAsterisk = (label: any) => (
        <React.Fragment>
            <div style={{display: 'inline-block', marginRight: 5}}>{label}</div>
            <div style={{display: 'inline-block', color: 'red'}}>{'*'}</div>
        </React.Fragment>
    );

    onUsernameChange = ({target: {value: username}}: React.ChangeEvent<HTMLInputElement>) => this.setState({username});

    onNatureChange = (nature: any) => this.setState({nature});

    onFirstNameChange = ({target: {value: firstName}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({firstName});

    onLastNameChange = ({target: {value: lastName}}: React.ChangeEvent<HTMLInputElement>) => this.setState({lastName});

    onEmailChange = ({target: {value: email}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState(prev => ({
            email,
            emailError: email !== '' && !checkEmail(email),
            emailConfirmationError: email !== prev.emailConfirmation,
        }));

    onEmailConfirmationChange = ({target: {value: emailConfirmation}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState(prev => ({
            emailConfirmation,
            emailConfirmationError: emailConfirmation !== prev.email,
        }));

    onCompanyChange = ({target: {value: company}}: React.ChangeEvent<HTMLInputElement>) => this.setState({company});

    onCompanyWebsiteChange = ({target: {value: companyWebsite}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({companyWebsite});

    onPhoneChange = ({target: {value: phone}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({
            phone,
            phoneError: phone !== '' && !checkPhoneNumber(phone),
        });

    onStreetChange = ({target: {value: street}}: React.ChangeEvent<HTMLInputElement>) => this.setState({street});

    onPostcodeChange = ({target: {value: postcode}}: React.ChangeEvent<HTMLInputElement>) => this.setState({postcode});

    onCityChange = ({target: {value: city}}: React.ChangeEvent<HTMLInputElement>) => this.setState({city});

    onCountrySelectionChange = (selectedCountry: any) => this.setState({selectedCountry, country: ''});

    onCountryChange = (country: string) => this.setState({country});

    onCountryInputBlur = () => {
        LOG([`${this.state.selectedCountry}`]);
        if (Object.keys(this.state.selectedCountry || {}).length === 0) {
            this.setState({countryErrorMessage: 'Please type and select one country from the list'});
            this.setState({country: ''});
        } else {
            this.setState({countryErrorMessage: ''});
        }
    };

    onCheckboxNewsletterChange = () =>
        this.setState(prev => ({checkboxNewsletterValue: !prev.checkboxNewsletterValue}));

    render() {
        return (
            <>
                {this.state.errorMessage ? (
                    <ModalErrorVersion2
                        title={'Registration error'}
                        message={this.state.errorMessage}
                        close={this.closeError}
                    />
                ) : null}
                {this.state.ok ? (
                    <ModalAlertVersion2
                        title={'Creating account'}
                        message={
                            'If the username and email are not in use... The user account has been created: To secure better your access, you should receive an email to change your password. You will receive an email with a link to confirm your email address.'
                        }
                        close={this.goToLogin}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderCookies />
                <HeaderLogo />
                <HeaderBreadcrumb
                    links={[
                        <Link key={'/'} to={'/'}>
                            {'User Area'}
                        </Link>,
                        'Create an Account',
                    ]}
                />
                <HeaderTitle title={'Create an Account'} />
                <FormWrapper classNameContent={styles.formContent}>
                    <div className={styles.formSection}>
                        <FormHeader number={1} title={'Create your account to access User area / MyPVR'} />
                        <Grid>
                            <Col lg={6} mb={'lg'}>
                                <TextInput
                                    label={`Define username`}
                                    labelPosition={'leftOutside'}
                                    onChange={this.onUsernameChange}
                                    value={this.state.username}
                                    mandatory={true}
                                    focus={true}
                                />
                            </Col>
                            <Col lg={6} mb={'lg'}>
                                <div>
                                    <RadioButton
                                        label={`Nature`}
                                        labelPosition={'leftOutside'}
                                        radioButtonList={[
                                            {
                                                description: 'Natural person',
                                                value: NATURE.NATURAL_PERSON,
                                            },
                                            {description: 'Legal entity', value: NATURE.LEGAL_ENTITY},
                                        ]}
                                        groupName={'nature'}
                                        value={this.state.nature}
                                        className={styles.radioButtonNatureOptionsWrap}
                                        clickEvent={this.onNatureChange}
                                        mandatory={true}
                                        layout={'horizontal'}
                                    />
                                </div>
                            </Col>
                        </Grid>
                    </div>
                    <div className={styles.formSection}>
                        <FormHeader number={2} title={'User details'} />
                        <Grid>
                            <Col lg={6} mb={'lg'}>
                                <TextInput
                                    label={'First name'}
                                    labelPosition={'leftOutside'}
                                    onChange={this.onFirstNameChange}
                                    value={this.state.firstName}
                                    mandatory={true}
                                />
                            </Col>
                            <Col lg={6} mb={'lg'}>
                                <TextInput
                                    label={'Last name'}
                                    labelPosition={'leftOutside'}
                                    onChange={this.onLastNameChange}
                                    value={this.state.lastName}
                                    mandatory={true}
                                />
                            </Col>
                            <Col lg={6} mb={'lg'}>
                                <TextInput
                                    label={'Email'}
                                    labelPosition={'leftOutside'}
                                    onChange={this.onEmailChange}
                                    value={this.state.email}
                                    mandatory={true}
                                    error={this.state.emailError && 'Invalid email'}
                                />
                            </Col>
                            <Col lg={6} mb={'lg'}>
                                <TextInput
                                    label={'Confirm email'}
                                    labelPosition={'leftOutside'}
                                    onChange={this.onEmailConfirmationChange}
                                    value={this.state.emailConfirmation}
                                    mandatory={true}
                                    error={this.state.emailConfirmationError && 'Email does not match'}
                                />
                            </Col>
                            <Col mb={'lg'}>
                                <TextInput
                                    label={'Company'}
                                    labelPosition={'leftOutside'}
                                    onChange={this.onCompanyChange}
                                    value={this.state.company}
                                />
                            </Col>
                            <Col lg={6} mb={'lg'}>
                                <TextInput
                                    label={'Company website'}
                                    labelPosition={'leftOutside'}
                                    onChange={this.onCompanyWebsiteChange}
                                    value={this.state.companyWebsite}
                                />
                            </Col>
                            <Col lg={6} mb={'lg'}>
                                <TextInput
                                    label={'Phone'}
                                    labelPosition={'leftOutside'}
                                    onChange={this.onPhoneChange}
                                    value={this.state.phone}
                                    error={this.state.phoneError && 'Invalid phone number'}
                                />
                            </Col>
                        </Grid>
                    </div>
                    <div className={styles.formSection}>
                        <FormHeader number={3} title={'Address details of the user (Natural person or Legal entity)'} />
                        <Grid>
                            <Col mb={'lg'}>
                                <TextAreaInput
                                    label={'Street / Place'}
                                    labelPosition={'leftOutside'}
                                    value={this.state.street}
                                    onChange={this.onStreetChange}
                                    rows={1}
                                    mandatory={true}
                                />
                            </Col>
                            <Col lg={6} mb={'md'}>
                                <TextInput
                                    label={'City'}
                                    labelPosition={'leftOutside'}
                                    onChange={this.onCityChange}
                                    value={this.state.city}
                                    mandatory={true}
                                />
                            </Col>
                            <Col lg={6} mb={'lg'}>
                                <TextInput
                                    label={'Postcode'}
                                    labelPosition={'leftOutside'}
                                    onChange={this.onPostcodeChange}
                                    value={this.state.postcode}
                                    mandatory={true}
                                />
                            </Col>
                            <Col lg={6}>
                                <TextLabelInput
                                    label={'Country'}
                                    labelPosition={'leftOutside'}
                                    mandatory={true}
                                    onSelectionChange={this.onCountrySelectionChange}
                                    onChange={this.onCountryChange}
                                    value={this.state.country}
                                    selectedElements={this.state.selectedCountry}
                                    delay={300}
                                    listByIdName={this.state.countryListByIdName}
                                    nameHash={this.state.countryNameHash}
                                    onBlur={this.onCountryInputBlur}
                                    error={this.state.countryErrorMessage}
                                    uniqueValue={true}
                                    autoSelection={true}
                                />
                            </Col>
                        </Grid>
                    </div>
                    <Checkbox
                        clickAction={this.onCheckboxNewsletterChange}
                        label={`I agree to receive news from the CPVO which may include information on Plant Variety Rights, invitations to seminars and workshops, surveys.`}
                        value={this.state.checkboxNewsletterValue}
                        double={true}
                        style={{marginTop: 20}}
                    />
                    <div style={{clear: 'both'}} />
                    <FormFooter>
                        {this.state.error ? <Error>{this.state.error}</Error> : null}
                        <Button
                            icon={'back'}
                            variation={'secondary'}
                            clickAction={this.goToLogin}
                        >{`I have an account`}</Button>
                        <Button icon={'tick'} clickAction={this.sendRegistration}>{`Send`}</Button>
                    </FormFooter>
                </FormWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(AuthenticationRegistration));
