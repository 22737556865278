import React from 'react';
import styles from './ModalRequestorDetails.module.scss';
import {apiCertifiedCopiesContactInfo} from './ModalRequestorDetailsService';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';

interface IProps {
    close: any;
}

interface IState {
    content: string;
    loading: boolean;
    userInfo: {
        title: string;
        lastname: string;
        firstname: string;
        company: string;
        cpvoUserNumber: string;
        occupation: string;
        email: string;
        address: string;
        postalCode: string;
        city: string;
        country: string;
        phone: string;
    };
}

export default class ModalRequestorDetails extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            content: '',
            loading: false,
            userInfo: {
                title: '',
                lastname: '',
                firstname: '',
                company: '',
                cpvoUserNumber: '',
                occupation: '',
                email: '',
                address: '',
                postalCode: '',
                city: '',
                country: '',
                phone: '',
            },
        };
    }

    componentDidMount() {
        this.setState({loading: true}, () => {
            apiCertifiedCopiesContactInfo().then((JSONResponse: any) => {
                if (JSONResponse && JSONResponse.data) {
                    this.setState(prevState => ({
                        userInfo: {
                            ...prevState.userInfo,
                            ...JSONResponse.data,
                        },
                        loading: false,
                    }));
                }
            });
        });
    }

    close = (reload: any) => this.props.close && this.props.close(reload);

    render() {
        return (
            <ModalCustomVersion2
                close={this.close}
                header={`Requestor details`}
                body={
                    <div className={styles.bodyContainer}>
                        <DataSheetFormFields
                            label={`Title`}
                            data={this.state.userInfo.title}
                            loading={this.state.loading}
                            double={true}
                        />
                        <DataSheetFormFields
                            label={`Lastname`}
                            data={this.state.userInfo.lastname}
                            loading={this.state.loading}
                            double={true}
                        />
                        <DataSheetFormFields
                            label={`Firstname`}
                            data={this.state.userInfo.firstname}
                            loading={this.state.loading}
                            double={true}
                        />
                        <DataSheetFormFields
                            label={`Company`}
                            data={this.state.userInfo.company}
                            loading={this.state.loading}
                            double={true}
                        />
                        <DataSheetFormFields
                            label={`CPVO user number`}
                            data={this.state.userInfo.cpvoUserNumber}
                            loading={this.state.loading}
                            double={true}
                        />
                        <DataSheetFormFields
                            label={`Occupation`}
                            data={this.state.userInfo.occupation}
                            loading={this.state.loading}
                            double={true}
                        />
                        <DataSheetFormFields
                            label={`E-mail where the documents will be sent to`}
                            data={this.state.userInfo.email}
                            loading={this.state.loading}
                            double={true}
                        />
                        <DataSheetFormFields
                            label={`Address`}
                            data={this.state.userInfo.address}
                            loading={this.state.loading}
                            double={true}
                        />
                        <DataSheetFormFields
                            label={`Postal code`}
                            data={this.state.userInfo.postalCode}
                            loading={this.state.loading}
                            double={true}
                        />
                        <DataSheetFormFields
                            label={`City`}
                            data={this.state.userInfo.city}
                            loading={this.state.loading}
                            double={true}
                        />
                        <DataSheetFormFields
                            label={`Country`}
                            data={this.state.userInfo.country}
                            loading={this.state.loading}
                            double={true}
                        />
                        <DataSheetFormFields
                            label={`Phone`}
                            data={this.state.userInfo.phone}
                            loading={this.state.loading}
                            double={true}
                        />
                        <div style={{clear: 'both'}} />
                    </div>
                }
                footer={<Button variation={'danger'} clickAction={this.close} icon={'close'}>{`Close`}</Button>}
            />
        );
    }
}
