export function prepareFormCreateBody(stateForm: any, selectedCropSectors: any) {
    let formCreatePayload = {
        cropSectors: Object.entries(selectedCropSectors || []).map(([key, value]) => ({ID: key, NAME: value})),
        messageId: null,
        messageCommonId: null,
        formStatus: null,
        validityBeginDate: null,
        validityEndDate: null,
        expert: null,
        remarkModif: null,
        versionCode: null,
        versionDate: null,
        messageHeaderId: null,
        messageFooterId: null,
        questionnaireType: null,
        questionnaireOrigin: null,
        origin: 0,
        proposalDate: null,
        mappingCode: null,
        idRoot: null,
        questionnaireIdFirst: null,
        modifRemark: null,
        tgCode: null,
        proposeOtherOptionOnPDF: null,
        paramXmlReady: 0,
        root: 0,
    };
    let {
        name,
        common,
        status,
        validityBegin,
        validityEnd,
        expert,
        remarkModif,
        versionCode,
        versionDate,
        header,
        footer,
        type,
        origin,
        proposalDate,
        mappingCode,
        idFormulaireRoot,
        questionnaireIdFirst,
        proposeOtherOptionOnPDF,
        paramXmlReady,
        root,
    } = stateForm;
    let overrideValues = {
        messageId: name.id,
        messageCommonId: common.id,
        formStatus: status,
        validityBeginDate: validityBegin,
        validityEndDate: validityEnd,
        expert,
        remarkModif,
        versionCode,
        versionDate,
        messageHeaderId: (header && header.id) || null,
        messageFooterId: (footer && footer.id) || null,
        questionnaireType: type,
        questionnaireOrigin: null,
        origin,
        proposalDate,
        mappingCode,
        idRoot: idFormulaireRoot,
        questionnaireIdFirst,
        proposeOtherOptionOnPDF,
        paramXmlReady,
        root: root ? 1 : 0,
    };
    return {...formCreatePayload, ...overrideValues};
}

export function prepareFormUpdateBody(JSONRequest: any = {}, selectedCropSectors: any, informApplicants?: boolean) {
    const FIELDS_TO_KEEP_ID_ONLY = ['name', 'common', 'header', 'footer'];
    const preparedFormUpdateBody: any = {
        ...JSONRequest,
        cropSectors: Object.entries(selectedCropSectors || []).map(([key, value]) => ({ID: key, NAME: value})),
        informApplicants,
    };
    FIELDS_TO_KEEP_ID_ONLY.forEach(key => {
        if (preparedFormUpdateBody[key]) {
            preparedFormUpdateBody[key] = {
                id: JSONRequest[key].id,
            };
        }
    });
    return preparedFormUpdateBody;
}
