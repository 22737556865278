import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Error, LoadingBar} from '../../../componentsLayout';
import {ModalCustomVersion2} from '../../../commonModals';
import {SectionElement} from '../../../types';
import TextInput from '~components/TextInput';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {apiSectionEdit} from './ModalSectionEditService';
import {Button} from '../../../componentsFormV2';

export interface IProps {
    close: (refresh?: boolean) => void;
    sectionElement: SectionElement;
}

interface IState {
    error: string;
    isModalErrorOpen: string | false;
    loading: boolean;
    sectionNameValue: string;
}

class ModalSectionEdit extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            error: '',
            isModalErrorOpen: false,
            loading: false,
            sectionNameValue: props.sectionElement.NAME,
        };
    }

    onSectionNameChange = ({target: {value: sectionNameValue}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({sectionNameValue});

    onButtonSaveClick = () =>
        this.setState({error: ''}, () => {
            if (!this.state.sectionNameValue) {
                return this.setState({error: 'Please insert a name for the new section.'});
            }
            this.setState({loading: true}, () => {
                apiSectionEdit({id: this.props.sectionElement.ID, name: this.state.sectionNameValue})
                    .then(() => {
                        this.props.close(true);
                    })
                    .catch((error: any) => {
                        this.setState({isModalErrorOpen: `error saving section: ${error.message}`});
                    })
                    .finally(() => this.setState({loading: false}));
            });
        });

    render() {
        return (
            <ModalCustomVersion2
                loading={false}
                close={() => this.props.close()}
                header={`Edit Section`}
                body={
                    <div>
                        <TextInput
                            value={this.state.sectionNameValue}
                            outsideLabel={'Section'}
                            outsideLabelWidth={140}
                            disabled={false}
                            onChange={this.onSectionNameChange}
                            double={true}
                            placeholder={`Please type a section name`}
                        />
                        <div style={{clear: 'both'}} />
                    </div>
                }
                footer={
                    <>
                        {this.state.loading ? (
                            <LoadingBar />
                        ) : (
                            <>
                                {this.state.error ? (
                                    <Error>
                                        <FontAwesomeIcon icon={faExclamationCircle as any} color={'white'} />
                                        {this.state.error}
                                    </Error>
                                ) : null}
                                <Button icon={'close'} clickAction={this.onButtonSaveClick} variation={'danger'}>
                                    {`Cancel`}
                                </Button>
                                <Button icon={'arrowRight'} clickAction={this.onButtonSaveClick}>
                                    {`Save`}
                                </Button>
                            </>
                        )}
                    </>
                }
            />
        );
    }
}

export default ModalSectionEdit;
