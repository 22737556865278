import {API_SERVERLESS, getFetch, postFetch} from '../../../../commonApi';

export interface MyInvoicesTableRowData {
    cpvoReference: number;
    supplierReference: string;
    invoiceStatusClear: string;
    invoiceDate: string;
    cpvoRemark: string | null;
    cpvoFile: string;
    receptionDate: string;
    paymentDate: string;
    amount: number;
    vat: number;
    totalpaid: number;
}

export interface MyInvoicesParamsData {
    data: {
        myInvoices: Array<MyInvoicesTableRowData>;
        token: string;
    };
    count: number;
}

export interface MyInvoicesTableCriteriaData {
    pageNumber: number;
    pageSize: number;
    refresh: boolean;
    excel: boolean;
    order: string;
    reverse: boolean;
}

export interface MyInvoicesFilterCriteriaData extends MyInvoicesTableCriteriaData {
    invoiceStatus: string;
    fileNumber: string;
    year: string;

    [key: string]: string | number | boolean | undefined;
}

export function apiMyInvoicesSearch(
    // eslint-disable-next-line no-undef
    criteria: Partial<MyInvoicesFilterCriteriaData>,
    excelTranslations: {[key: string]: string} = {}
): Promise<MyInvoicesParamsData> {
    const params = {...criteria};
    params.pageSize = params.excel ? 3000 : params.pageSize;
    const requestParams = Object.keys(params).map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/tlo/myinvoices?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}
