import React from 'react';
import {injectIntl} from 'react-intl';
import {apiFileListLogs, apiFileLogs} from './CPVOLogsService';
import styles from './CPVOLogs.module.scss';
import CustomTable from '~components/CustomTable';
import {withRouter} from 'react-router-dom';
import cx from 'classnames';
import {ModalCPVOLogsVersion2} from '../../commonModals';
import {
    Footer,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import Navigation from '../../componentsLayout/Navigation';
import {trackPageView} from '../../utils';

const PUBLIC_RESULT_FIELDS_ALL = ['folderName', 'server'];
const PUBLIC_RESULT_FIELDS_DEFAULT = ['folderName', 'server'];
const DEFAULT_CRITERIA = {
    folderName: '',
    server: '',
    //
    pageNumber: 1,
    pageSize: 10,
    order: 'folderName',
    reverse: false,
    refresh: false,
    excel: false,
};
const modalScreen = {MODAL_FILES: 'MODAL_FILES'};
const DEFAULT_COUNT = 0;

class CPVOLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: null,
            criteria: DEFAULT_CRITERIA,
            count: DEFAULT_COUNT,
            loading: 0,
            zone: null,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'cpvoLogs'});
        this.loadJSONs();
    }

    loadJSONs = () => {
        const domainArray = document.location.href.split('=');
        const zone = domainArray.pop();
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                apiFileLogs(zone)
                    .then(jsonResponse => {
                        if (jsonResponse) {
                            this.setState({
                                logs: jsonResponse.logs,
                                timestamp: Date.now(),
                                zone,
                            });
                        }
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );
    };

    openModal = folder =>
        apiFileListLogs(this.state.zone, folder).then(jsonResult =>
            this.setState({
                files: jsonResult.files,
                folder,
                modalScreen: modalScreen.MODAL_FILES,
                timestamp: Date.now,
            })
        );
    closeModal = () => this.setState({modalScreen: false});

    render() {
        return (
            <>
                {this.state.modalScreen === modalScreen.MODAL_FILES && (
                    <ModalCPVOLogsVersion2
                        intl={this.props.intl}
                        zone={this.state.zone}
                        folder={this.state.folder}
                        timestamp={this.state.timestamp}
                        files={this.state.files}
                        close={this.closeModal}
                    />
                )}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`CPVO logs`} />
                <Navigation />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div style={{textAlign: 'center'}}>
                            <h1>{`CPVO File Logs: ${this.state.zone}`}</h1>

                            <h3>
                                {
                                    'yyyy-MM-dd-hh-mm-ss-SSS-contactId-thirdPartyId-ApplicationNumber-00000000-0000-0000-000000000000'
                                }
                            </h3>
                            <CustomTable
                                version={2}
                                loading={this.state.loading !== 0}
                                {...this.props}
                                tableName={'cpvoLogs'}
                                tableType={'OBJECT'}
                                tableSource={this.state.logs}
                                timestamp={this.state.timestamp}
                                dataFilter={null}
                                id={'folderName'}
                                hideExcelButton={true}
                                notSortable={PUBLIC_RESULT_FIELDS_ALL}
                                rowClick={this.openModal}
                                resultFieldsAll={PUBLIC_RESULT_FIELDS_ALL}
                                resultFieldsDefault={PUBLIC_RESULT_FIELDS_DEFAULT}
                                intl={this.props.intl}
                                defaultOrder={'folderName'}
                                reverseOrder={true}
                                pageSize={this.state.criteria.pageSize}
                                filterFunctions={null}
                                count={this.state.logs ? this.state.logs.length : 0}
                                rowClass={this.getAppealStatusRowClass}
                                formatFunctions={{}}
                                headerPopup={{
                                    applicationStatus: {
                                        description: 'Status explanations',
                                        handler: this.showSearchModalInfo,
                                    },
                                }}
                                topCaption={
                                    this.state.subjectToAnAppeal && (
                                        <div className={styles.captionContainer}>
                                            <div className={cx(styles.label, styles.labelYellow)}>
                                                {`This file is subject to an appeal`}
                                            </div>
                                        </div>
                                    )
                                }
                                excelFormatFunctions={{
                                    applicants: applicants => applicants.join('; '),
                                }}
                                updateCriteriaValue={this.updateCriteriaValue}
                            />
                        </div>
                    </FormWrapper>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(withRouter(CPVOLogs));
