import React from 'react';
import {LOGIC_TYPES} from '../modals/ResponseModalDetailsLogics';
import {MOCK} from '../../BackOfficeFormQuestions/BackOfficeFormQuestions';
import RemarkMessage from './RemarkMessage';
import WizardContextualMenu from '../common/WizardContextualMenu';
import WizardQuestionWrapper, {IWizardQuestionWrapperProps, IWizardQuestionWrapperState} from './WizardQuestionWrapper';
import cx from 'classnames';
import {
    getResponseComponent,
    renderMandatoryError,
    renderNumberOfResponsesAllowedError,
    renderSuccessiveScoreOnlyError,
} from './utils';
import {injectIntl} from 'react-intl';
import styles from './WizardQuestionShortDisplay.module.scss';

interface IState extends IWizardQuestionWrapperState {
    hidden: boolean;
}

class WizardQuestionShortDisplay extends WizardQuestionWrapper<IWizardQuestionWrapperProps, IState> {
    constructor(props: IWizardQuestionWrapperProps) {
        super(props);
        this.state = {
            ...this.state,
            hidden: false,
        };
    }

    componentDidMount() {
        this.setState(this.checkIfQuestionIsHidden(), () =>
            this.removeResponsesFromHiddenQuestionIfExist(this.state.hidden)
        );
        this.props.setWizardQuestionCallables &&
            this.props.setWizardQuestionCallables({
                showModalResponseDetails: this.showModalResponseDetails,
            });
        if (
            (this.props.mock === MOCK.EDIT_RESPONSE || Object.values(LOGIC_TYPES).indexOf(this.props.mock) !== -1) &&
            !(self as any).singletonModal
        ) {
            (self as any).singletonModal = true;
            this.showModalResponseDetails(11719);
        }
    }

    componentDidUpdate(prevProps: IWizardQuestionWrapperProps, prevState: IWizardQuestionWrapperState) {
        if (this.props.screenData.hash !== prevProps.screenData.hash) {
            this.setState(this.checkIfQuestionIsHidden(), () =>
                this.removeResponsesFromHiddenQuestionIfExist(this.state.hidden)
            );
        }
    }

    render() {
        const questionKeyWithIteration = this.getQuestionKeyWithIteration();
        const showMandatoryError = this.checkIfShouldShowMandatoryError(this.state.hidden);
        const successiveScoreOnlyError = this.checkIfShouldShowSuccessiveScoreOnlyError(showMandatoryError);
        const numberOfResponsesAllowedError = this.checkIfShouldShowNumberOfResponsesAllowedError(showMandatoryError);
        const {hidden, hiddenDueToQuestionResponseLines} = this.state;

        if (this.pdfConfidentialHideQuestion()) {
            return <></>;
        }

        return (
            <>
                {super.render()}
                {showMandatoryError && renderMandatoryError({intl: this.props.intl})}
                {successiveScoreOnlyError && renderSuccessiveScoreOnlyError({intl: this.props.intl})}
                {numberOfResponsesAllowedError &&
                    renderNumberOfResponsesAllowedError({intl: this.props.intl, numberOfResponsesAllowedError})}

                <div id={questionKeyWithIteration} className={styles.section}>
                    <div className={cx(styles.titleSection, this.state.hidden && styles.sectionHidden)}>
                        {this.renderQuestionLabel({
                            hidden,
                            hiddenDueToQuestionResponseLines,
                        })}
                        {this.renderQuestionHelpIcon()}
                    </div>
                    {this.state.hidden === false ? (
                        <>
                            <div className={styles.shortResponsesWrap}>
                                <RemarkMessage
                                    remarkMessage={this.props.question && this.props.question.messageremark}
                                />
                                {this.pdfConfidentialHideResponsesOnly() ? (
                                    <div style={{marginLeft: 20}}>Confidential information</div>
                                ) : (
                                    ((this.props.question && this.props.question.responses) || []).map(response => {
                                        let params: any = this.getResponseComponentProps(response);
                                        params.isShortDisplay = true;
                                        let responseComponent = getResponseComponent({
                                            question: this.props.question,
                                            response,
                                            params,
                                        });
                                        return (
                                            <div key={`short_${response.id}`} className={styles.responseWrap}>
                                                <WizardContextualMenu
                                                    editable={this.props.editable}
                                                    menuType="response"
                                                    labelPopup={this.getResponseLabelPopup(response.id)}
                                                />
                                                <div title={this.props.editable ? String(response.id) : undefined}>
                                                    {responseComponent}
                                                    {this.renderResponseLogicsPopover(response)}
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                            <div style={{clear: 'both'}} />
                            {this.renderConfidentialQuestion()}
                            <div style={{clear: 'both'}} />
                        </>
                    ) : null}
                </div>
            </>
        );
    }
}

export default injectIntl(WizardQuestionShortDisplay);
