import React from 'react';
import ModalCustomVersion2 from './ModalCustomVersion2';
import {Button} from '../componentsFormV2';
import CustomTable from '~components/CustomTable';
import getIcon from '~utils/icons';
import {saveAs} from 'file-saver';
import {apiFileLogsDownload} from './ModalCPVOLogsVersion2Service';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import Success from '~components/Success';
import {getPreSignedURLFetchRequest} from '../utils/requests';

interface IModalCPVOLogsVersion2Props {
    files?: any;
    zone?: any;
    folder?: any;
    subject?: any;
    comments?: any;
    notice?: any;
    noticeMessage?: any;
    userMode?: any;
    intl?: any;
    timestamp?: any;
    successContent?: any;
    close?: any;
    plus?: any;
}

export default function ({
    files,
    zone,
    folder,
    subject,
    comments,
    notice,
    noticeMessage,
    userMode,
    intl,
    timestamp,
    successContent,
    close,
    plus,
}: IModalCPVOLogsVersion2Props) {
    const downloadFile = (filename: string) =>
        apiFileLogsDownload(zone, folder, filename).then((response: any) => {
            if (response && response.signedUrl) {
                const {signedUrl} = response;
                getPreSignedURLFetchRequest(signedUrl)
                    .then(response => response.blob())
                    .then(responseBlob => {
                        saveAs(
                            responseBlob,
                            filename === '_INDEX2.pdf' || filename === '_INDEX.pdf'
                                ? 'Confirmation of receipt.pdf'
                                : filename
                        );
                    });
            }
        });

    const successMessage =
        successContent ||
        noticeMessage ||
        'Success! You can consult the list of sent documents on the "Notifications" screen, "Sent messages" tab.';

    return (
        <ModalCustomVersion2
            close={close}
            plus={plus}
            header={`Files list`}
            body={
                <>
                    {notice && (
                        <Success>
                            <div style={{fontWeight: 800}}>{successMessage}</div>
                        </Success>
                    )}
                    <div style={{height: 400, overflowY: 'auto', padding: 20}}>
                        {subject && (
                            <DataSheetFormFields
                                label={'Subject'}
                                data={subject}
                                loading={false}
                                ready={true}
                                double={true}
                            />
                        )}
                        {comments && (
                            <DataSheetFormFields
                                label={'Comments'}
                                data={comments && comments.split('\n')}
                                loading={false}
                                ready={true}
                                double={true}
                            />
                        )}
                        <div style={{clear: 'both'}}>{}</div>
                        {(files || []).length > 0 && (
                            <div style={{marginBottom: 20}}>
                                <CustomTable
                                    version={2}
                                    notSortable={['filename']}
                                    tableName={'fileLogs'}
                                    tableType={'OBJECT'}
                                    tableSource={files || []}
                                    dataFilter={null}
                                    id={'filename'}
                                    setLastCursor={null}
                                    resultFieldsDefault={['filename']}
                                    intl={intl}
                                    count={(files || []).length}
                                    hideExcelButton={true}
                                    noChangePageSize={true}
                                    rowClick={(filename: string) => downloadFile(filename)}
                                    forehandColumn={(row: any) => (
                                        <img
                                            style={{width: 20, height: 20, cursor: 'pointer'}}
                                            alt={'Download'}
                                            src={getIcon(row.filename || 'pdf')}
                                            onClick={event => {
                                                event.stopPropagation();
                                                downloadFile(row.filename);
                                            }}
                                        />
                                    )}
                                    timestamp={timestamp}
                                    formatFunctions={{
                                        filename: (filename: string) => {
                                            const filenameArray = filename.split('_');
                                            try {
                                                filenameArray.shift();
                                            } catch (error) {
                                                LOG([`OK`]);
                                            }
                                            const modifiedFilename = filenameArray.join('_');
                                            return userMode && (filename === '_INDEX2.pdf' || filename === '_INDEX.pdf')
                                                ? 'Confirmation of receipt.pdf'
                                                : userMode
                                                ? modifiedFilename
                                                : filename;
                                        },
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </>
            }
            footer={<Button variation={'danger'} clickAction={close} icon={'close'}>{`Close`}</Button>}
        />
    );
}
