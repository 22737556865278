import {getFetch, postFetch, API_SERVERLESS} from '~commonApi';

export function apiReadSettings() {
    // return new Promise(function (resolve) {
    //     setTimeout(function () {
    //         resolve({'2fa': true});
    //     }, 1000);
    // });
    return getFetch(`${API_SERVERLESS}/common/v3/contactSettings`, true);
}

export function apiSetSettings(jsonData) {
    // return new Promise(function (resolve) {
    //     setTimeout(function () {
    //         resolve({
    //             data: 'OK',
    //         });
    //     }, 1000);
    // });
    return postFetch(`${API_SERVERLESS}/common/v3/contactSettings`, true, jsonData);
}
