import React from 'react';
import {injectIntl} from 'react-intl';
import TextInput from '~components/TextInput';
import {faInfo, faPlus} from '@fortawesome/free-solid-svg-icons';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {apiBackOfficePVRTemplatesSearch} from './CommunicationCentreCPVOTemplatesService';
import CustomTable from '~components/CustomTable';
import CommunicationCentreCPVOTemplatesActionButtons from './CommunicationCentreCPVOTemplatesActionButtons';
import styles from '../BackOfficeTemplates/BackOfficeTemplates.module.scss';
import ModalBackOfficeTemplatePVRPreview from './modals/ModalBackOfficeTemplatePVRPreview';
import ModalInternalMessageCompose from '../CCModalInternalCompose/CCModalInternalCompose';
import SelectInput from '~components/SelectInput';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationCommunicationCentre from '../../shared/NavigationCommunicationCentre';
import {Button} from '../../componentsFormV2';
import {trackPageView} from '../../utils';

const RESULT_FIELDS_DEFAULT_PVR_TEMPLATES = ['name', 'language'];

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

type TKeyOfDefaultCriteria = keyof TDefaultCriteria;

export type TPVRTemplate = {
    name: string;
    language: string[];
};

const DEFAULT_CRITERIA = {
    pageNumber: 1,
    pageSize: 10,
    order: 'name',
    reverse: true,
    refresh: false,
    templateName: '',
};

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    PVRTemplates: TPVRTemplate[];
    count: number;
    criteria: any;
    loading: number;
    modalPVRTemplatePreview: number | null;
    modalInternalMessageCompose: boolean;
    pageNumber: number;
    screenLoaded: boolean;
    timestamp: number;
}

class CommunicationCentreInternalCPVOTemplates extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            PVRTemplates: [],
            count: 0,
            criteria: Object.assign({}, DEFAULT_CRITERIA),
            loading: 0,
            modalPVRTemplatePreview: null,
            modalInternalMessageCompose: false,
            pageNumber: 1,
            screenLoaded: false,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'communicationCentreInternalCPVOTemplates'});
        this.search(false, false, () => this.setState({screenLoaded: true}));
    }

    updateCriteriaValue = (criteriaValue: Partial<TDefaultCriteria>, callback?: () => any, refresh?: boolean) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = prev.criteria.order !== criteriaValue.order;
                reverseChanged = prev.criteria.reverse !== criteriaValue.reverse;
                return {criteria};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    parseCriteria = (criteria: TDefaultCriteria): TDefaultCriteria => {
        const parsedCriteria: {[key: string]: any} = {};
        Object.keys(criteria).map(key => {
            parsedCriteria[key] = criteria[key as TKeyOfDefaultCriteria];
        });
        return parsedCriteria as TDefaultCriteria;
    };

    buildParams = (parsedCriteria: TDefaultCriteria): void => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(
                i =>
                    parsedCriteria[i as TKeyOfDefaultCriteria] !== DEFAULT_CRITERIA[i as TKeyOfDefaultCriteria] &&
                    i !== 'refresh'
            )
            .map(key => `${key}=${parsedCriteria[key as TKeyOfDefaultCriteria]}`);
        this.props.history.replace(
            `/communicationCentreInternalCPVOTemplates${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`
        );
    };

    search = (refresh?: boolean, urlLoad?: boolean, callBack?: () => any): void =>
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams(parsedCriteria);
                apiBackOfficePVRTemplatesSearch(parsedCriteria, DEFAULT_CRITERIA)
                    .then(JSONResponse => {
                        if (JSONResponse && JSONResponse.data) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {...prev},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        PVRTemplates: JSONResponse.data,
                                        timestamp: Date.now(),
                                    },
                                    JSONResponse.count && {count: JSONResponse.count}
                                );
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`CommunicationCentreInternalCPVOTemplates: ${error.message}`]);
                    })
                    .then(() => {
                        this.setState(prev => ({loading: prev.loading - 1}));
                        callBack && callBack();
                    });
            }
        );

    closeModalBackOfficeTemplatePVRPreview = () => this.setState({modalPVRTemplatePreview: null});

    openModalInternalMessageCompose = (): void => this.setState({modalInternalMessageCompose: true});

    closeModalInternalMessageCompose = (): void =>
        this.setState({modalInternalMessageCompose: false}, () => this.search(true));

    goToScreen = (screen: string): void => this.props.history.push(screen);

    onPVRTemplateNameInputChange = ({target: {value: templateName}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({templateName});

    onClearFieldsClick = () =>
        this.setState(
            prev => {
                return {
                    criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
                };
            },
            () => this.search()
        );

    onTableIconEditClick = () => this.setState({modalPVRTemplatePreview: 1});

    onTableIconDeleteClick = () => {
        alert('Delete CPVO Template');
    };

    onButtonCreateNewPVRTemplateClick = () => this.setState({modalPVRTemplatePreview: 0});

    render() {
        const actions = CommunicationCentreCPVOTemplatesActionButtons(
            this.onTableIconEditClick,
            this.onTableIconDeleteClick
        );

        return (
            <>
                {(this.state.loading !== 0 && <HeaderLoading />) || null}
                {this.state.modalPVRTemplatePreview !== null ? (
                    <ModalBackOfficeTemplatePVRPreview
                        close={this.closeModalBackOfficeTemplatePVRPreview}
                        PVRTemplateId={this.state.modalPVRTemplatePreview}
                    />
                ) : null}
                {this.state.modalInternalMessageCompose && (
                    <ModalInternalMessageCompose close={this.closeModalInternalMessageCompose} intl={this.props.intl} />
                )}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Communication Centre`} />
                <NavigationCommunicationCentre openModalMessageCompose={this.openModalInternalMessageCompose} />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div>
                            <TextInput
                                label={`CPVO template name`}
                                filter={'Contains'}
                                infoIcon={faInfo}
                                onChange={this.onPVRTemplateNameInputChange}
                                value={this.state.criteria.templateName}
                                double={true}
                            />
                            <SelectInput
                                label={`Language`}
                                value={this.state.criteria.languageId}
                                onChange={() => null}
                                list={[
                                    {id: 'en', value: 'English'},
                                    {id: 'fr', value: 'French'},
                                ]}
                            />
                        </div>
                        <div className={styles.buttonCreateContainer}>
                            <FormFooterButton
                                color={buttonColor.green}
                                clickAction={() => this.onButtonCreateNewPVRTemplateClick()}
                                icon={faPlus}
                            >{`Create CPVO template`}</FormFooterButton>
                        </div>
                        <div style={{clear: `both`}} />
                        <FormFooter>
                            <Button
                                clickAction={this.onClearFieldsClick}
                                variation={'secondary'}
                            >{`Clear fields`}</Button>
                            <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    <div style={{marginTop: 20, marginBottom: 20}}>
                        {this.state.screenLoaded ? (
                            <CustomTable
                                actions={actions}
                                formatFunctions={{
                                    language: (language: string[]) =>
                                        language.map(language => (
                                            <span
                                                key={`language`}
                                                style={{
                                                    marginRight: 8,
                                                    textDecoration: 'underline',
                                                    color: '#009435',
                                                    cursor: `pointer`,
                                                }}
                                                onClick={() => this.setState({modalPVRTemplatePreview: 1})}
                                            >
                                                {language}
                                            </span>
                                        )),
                                }}
                                count={this.state.count}
                                dataFilter={null}
                                defaultOrder={this.state.criteria.order}
                                filterFunctions={null}
                                hideExcelButton={true}
                                id={'id'}
                                intl={this.props.intl}
                                pageSize={this.state.criteria.pageSize}
                                pageNumber={this.state.criteria.pageNumber}
                                pagination={true}
                                resultFieldsDefault={RESULT_FIELDS_DEFAULT_PVR_TEMPLATES}
                                reverseOrder={this.state.criteria.reverse}
                                setLastCursor={null}
                                tableName={'communicationCentreInternalCPVOTemplates'}
                                tableType={'OBJECT'}
                                tableSource={this.state.PVRTemplates}
                                timestamp={this.state.timestamp}
                                updateCriteriaValue={this.updateCriteriaValue}
                                version={2}
                            />
                        ) : null}
                    </div>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(CommunicationCentreInternalCPVOTemplates);
