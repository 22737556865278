import React from 'react';
import {injectIntl, IntlShape} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {faFlag, faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Checkbox from '~components/Checkbox';
import Info from '~components/Info';
import Label from '~components/Label';
import RadioButton from '~components/RadioButton';
import TextAreaInput from '~components/TextAreaInput';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import {apiCountries} from '~commonApi/static';
import {getDecodedJWT, isRegisterIdValid} from '~utils';
import {apiCommonContactInfo, apiMyPVRPublicAccess} from './PublicAccessService';
import {ModalAlertVersion2, ModalErrorVersion2} from '../../commonModals';
import {IDecodedJWT} from '../../types';
import {
    Error,
    Footer,
    FormFooter,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import {History} from 'history';
import {Button} from '../../componentsFormV2';
import Navigation from '../../componentsLayout/Navigation';
import {checkEmail, trackPageView} from '../../utils';

const OUTSIDE_LABEL_WIDTH = 150;
const INPUT_WIDTH = 685;

const TITLE = {MR: '1', MS: '2'};

const errorMessages: any = {
    UNKNOWN: `This is not a valid application number. The value must be in range 19950001 to ${new Date().getFullYear()}9999!`,
    FORBIDDEN: 'This application is not linked to your account',
    DUPLICATE: `Application number already in the list`,
    NOT_RECEIPT: 'The reception of your application has not been finalised yet.',
    ADD: 'Please include the application number using the button + or "Enter" keyboard button.',
};

const DOCUMENT_REQUESTED = [
    {
        title: 'Application form',
        id: 'applicationForm',
    },
    {
        title: 'Technical questionnaire',
        id: 'technicalQuestionnaire',
    },
    {
        title: 'Proposal for a variety denomination',
        id: 'varietyDenominationProposal',
    },
    {
        title: 'Photographs',
        id: 'photographs',
    },
    {
        title: 'Variety description',
        id: 'varietyDescription',
    },
    {
        title: 'Assignment',
        id: 'assignment',
    },
    {
        title: 'Other',
        id: 'other',
    },
];

const documentsRequestState: any = {};
DOCUMENT_REQUESTED.forEach(({id}) => {
    documentsRequestState[id] = false;
});

interface IProps {
    intl: IntlShape;
    history: History;
}

interface IState {
    applicationNumberError: any;
    loading: number;
    documentsRequested: any;
    countryErrorMessage: any;
    countryId: any;
    comments: string;
    title: string;
    lastName: string;
    firstName: string;
    company: string;
    error: any;
    occupation: string;
    email: string;
    address: string;
    postcode: string;
    city: string;
    region: string;
    country: string;
    selectedCountry: any;
    countryNameHash: any;
    countryListByIdName: any;
    phone: any;
    applicationNumber: string;
    selectedApplicationNumbers: any;
    isModalSuccessOpen: boolean;
    isModalErrorCommentsLengthOpen: false | number;
    timestamp: number;
}

class PublicAccess extends React.Component<IProps, IState> {
    decodedJWT: IDecodedJWT = getDecodedJWT();

    constructor(props: IProps) {
        super(props);
        this.state = {
            applicationNumberError: null,
            loading: 0,
            documentsRequested: {
                ...documentsRequestState,
            },
            countryErrorMessage: null,
            comments: '',
            title: '',
            lastName: '',
            firstName: '',
            countryId: null,
            company: '',
            error: null,
            occupation: '',
            email: '',
            address: '',
            postcode: '',
            city: '',
            region: '',
            country: '',
            selectedCountry: '',
            countryNameHash: null,
            countryListByIdName: null,
            phone: '',
            applicationNumber: '',
            selectedApplicationNumbers: {},
            isModalSuccessOpen: false,
            isModalErrorCommentsLengthOpen: false,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'publicAccess'});
        this.loadJSONs();
    }

    loadJSONs = () => {
        this.loadApiCountries().then(() => {
            this.decodedJWT && this.decodedJWT.thirdPartyId && this.loadContactInfo();
        });
    };

    loadApiCountries = () => {
        return new Promise(resolve => {
            this.setState(
                prev => ({
                    loading: prev.loading + 1,
                }),
                () => {
                    apiCountries().then((jsonData: any) => {
                        if (jsonData) {
                            const countryNameHash: {[key: string]: string[]} = {};
                            (jsonData || []).forEach((countryElement: any) => {
                                if (!countryNameHash[countryElement.COUNTRYNAME]) {
                                    countryNameHash[countryElement.COUNTRYNAME] = [];
                                }
                                countryNameHash[countryElement.COUNTRYNAME].push(countryElement.COUNTRYID);
                            });
                            const countryListByIdName = Object.keys(countryNameHash).map(countryName => ({
                                ID: countryNameHash[countryName].join(','),
                                NAME: countryName,
                            }));
                            const selectedCountry: any = {};
                            const selectedCountryKeyArray: string[] = Object.keys(this.state.selectedCountry || {});
                            countryListByIdName
                                .filter(i => selectedCountryKeyArray.indexOf(i.ID) !== -1)
                                .forEach(item => (selectedCountry[item.ID] = item.NAME));
                            this.setState(prev => ({
                                loading: prev.loading - 1,
                                countryListByIdName,
                                countryNameHash,
                                selectedCountry,
                                timestamp: Date.now(),
                            }));
                            resolve([]);
                        }
                    });
                }
            );
        });
    };

    loadContactInfo = () => {
        return new Promise(resolve => {
            this.setState(
                prev => ({
                    loading: prev.loading + 1,
                }),
                () => {
                    apiCommonContactInfo().then((JSONResponse: any) => {
                        if (JSONResponse && JSONResponse.data) {
                            const {
                                address,
                                city,
                                company,
                                country,
                                email,
                                firstname: firstName,
                                lastname: lastName,
                                occupation,
                                phone,
                                postalCode: postcode,
                                //title
                            } = JSONResponse.data || {};
                            const selectedCountryIDName = this.state.countryListByIdName.find(
                                (el: any) => el.ID === country
                            );
                            this.setState(prev => ({
                                loading: prev.loading - 1,
                                address,
                                city,
                                company,
                                selectedCountry: {
                                    [selectedCountryIDName?.ID]: selectedCountryIDName?.NAME,
                                },
                                email,
                                firstName,
                                lastName,
                                occupation,
                                phone,
                                postcode,
                            }));
                            resolve([]);
                        }
                    });
                }
            );
        });
    };

    clearForm = () => {
        this.setState({
            documentsRequested: {
                ...documentsRequestState,
            },
            comments: '',
            title: '',
            lastName: '',
            firstName: '',
            company: '',
            occupation: '',
            email: '',
            address: '',
            postcode: '',
            city: '',
            region: '',
            country: '',
            selectedCountry: '',
            phone: '',
            applicationNumber: '',
            selectedApplicationNumbers: {},
        });
    };

    getMandatoryAsterisk = (label: string) => (
        <>
            <div style={{display: 'inline-block', marginRight: 5}}>{label}</div>
            <div style={{display: 'inline-block', color: 'red'}}>{'*'}</div>
        </>
    );

    checkApplicationNumber = (applicationNumber: any) => {
        let applicationNumberValue = applicationNumber;
        if (!isNaN(applicationNumberValue)) {
            applicationNumberValue = applicationNumberValue.substring(0, 8);
            this.setState({
                applicationNumber: applicationNumberValue,
                applicationNumberError: isRegisterIdValid(applicationNumberValue)
                    ? applicationNumberValue.length === 8
                        ? 'ADD'
                        : null
                    : 'UNKNOWN',
            });
        }
    };

    sendForm = () => {
        const {
            selectedApplicationNumbers,
            selectedCountry,
            documentsRequested,
            comments,
            title,
            company,
            lastName,
            firstName,
            email,
            address,
            occupation,
            postcode,
            city,
            region,
            phone,
        } = this.state;
        const documentsChecked: string[] = [];
        Object.entries(documentsRequested).forEach(([key, value]) => value && documentsChecked.push(key));
        const applicationNumbers = Object.keys(selectedApplicationNumbers || {});
        const country = Object.keys(selectedCountry || {})[0];
        this.setState(
            {
                error: '',
            },
            () => {
                if (!documentsChecked.length) {
                    return this.setState({error: 'Please check at least one document'});
                }
                if (documentsChecked.filter((el: any) => el !== 'other').length > 0 && !applicationNumbers.length) {
                    return this.setState({error: 'Please enter application number'});
                }
                if (!title) {
                    return this.setState({error: 'Please include your title'});
                }
                if (!lastName) {
                    return this.setState({error: 'Please include your last name'});
                }
                if (!firstName) {
                    return this.setState({error: 'Please include your first name'});
                }
                if (!email) {
                    return this.setState({error: 'Please include your email'});
                }
                if (!checkEmail(email)) {
                    return this.setState({error: 'Please enter a valid email address'});
                }
                if (!address) {
                    return this.setState({error: 'Please include your address'});
                }
                if (!postcode) {
                    return this.setState({error: 'Please include your post code'});
                }
                if (!city) {
                    return this.setState({error: 'Please include your city'});
                }
                if (!country) {
                    return this.setState({error: 'Please include your country'});
                }
                const JSONRequest = {
                    applicationNumbers,
                    country,
                    company,
                    documentsRequested: documentsChecked,
                    comments,
                    title,
                    lastName,
                    firstName,
                    occupation,
                    email,
                    address,
                    postcode,
                    city,
                    region,
                    phone,
                };
                this.setState(
                    prev => ({
                        loading: prev.loading + 1,
                    }),
                    () => {
                        apiMyPVRPublicAccess(JSONRequest)
                            .then((JSONResponse: any) => {
                                if (JSONResponse && JSONResponse.data === 'OK') {
                                    this.clearForm();
                                    this.setState({
                                        isModalSuccessOpen: true,
                                    });
                                }
                                if (JSONResponse && JSONResponse.error && JSONResponse.error === 'COMMENTS_LENGTH') {
                                    this.setState({
                                        isModalErrorCommentsLengthOpen: JSONResponse.commentsMaxLength,
                                    });
                                }
                            })
                            .then(() => {
                                this.setState(prev => ({loading: prev.loading - 1}));
                            });
                    }
                );
            }
        );
    };

    closeModalSuccess = () => this.setState({isModalSuccessOpen: false}, () => this.props.history.push('/welcome'));

    closeModalError = () => this.setState({isModalErrorCommentsLengthOpen: false});

    onApplicationNumbersSelectionChange = (selectedApplicationNumbers: any) =>
        this.setState(Object.assign({}, {selectedApplicationNumbers}));

    onApplicationNumberChange = (applicationNumber: any) => this.checkApplicationNumber(applicationNumber);

    onAddingApplicationNumber = () => {
        const {applicationNumber} = this.state;
        if (applicationNumber.length === 8 && isRegisterIdValid(applicationNumber)) {
            if (this.state.selectedApplicationNumbers[applicationNumber]) {
                this.setState({applicationNumberError: 'DUPLICATE'});
            } else {
                this.setState(prev => {
                    const selectedApplicationNumbers = prev.selectedApplicationNumbers;
                    selectedApplicationNumbers[prev.applicationNumber] = prev.applicationNumber;
                    return {
                        applicationNumber: '',
                        applicationNumberError: null,
                        selectedApplicationNumbers,
                    };
                });
            }
        }
    };

    onCountrySelectionChange = (selectedCountry: any) => {
        let stateUpdate: any = {
            countryId: Object.keys(selectedCountry).join(','),
            country: '',
        };
        if (Object.keys(selectedCountry).length === 0) {
            stateUpdate.selectedCountry = selectedCountry;
        } else {
            stateUpdate.countryErrorMessage = null;
        }
        this.setState(stateUpdate);
    };

    onIdChange = (countryId: string | null, callback: any) => {
        if (countryId) {
            const selectedCountry: any = {};
            this.state.countryListByIdName
                .filter((i: any) => i.ID === countryId)
                .forEach((countryElement: any) => (selectedCountry[countryElement.ID] = countryElement.NAME));
            this.setState(
                {
                    countryId,
                    selectedCountry,
                    country: '',
                },
                callback
            );
        }
    };

    onCountryChange = (country: any) => this.setState({country});

    onCountryInputBlur = () => {
        if (Object.keys(this.state.selectedCountry || {}).length === 0) {
            this.setState({country: ''});
        } else {
            this.setState({countryErrorMessage: null});
        }
    };

    onCheckboxDocumentRequested = (id: string) =>
        this.setState(prev => ({
            documentsRequested: {
                ...prev.documentsRequested,
                [id]: !prev.documentsRequested[id],
            },
        }));

    onCommentsChange = ({target: {value: comments}}: React.ChangeEvent<HTMLInputElement>) => this.setState({comments});

    onTitleChange = (title: any) => this.setState({title});

    onLastNameChange = ({target: {value: lastName}}: React.ChangeEvent<HTMLInputElement>) => this.setState({lastName});

    onFirstNameChange = ({target: {value: firstName}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({firstName});

    onCompanyChange = ({target: {value: company}}: React.ChangeEvent<HTMLInputElement>) => this.setState({company});

    onOccupationChange = ({target: {value: occupation}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({occupation});

    onEmailChange = ({target: {value: email}}: React.ChangeEvent<HTMLInputElement>) => this.setState({email});

    onAddressChange = ({target: {value: address}}: React.ChangeEvent<HTMLInputElement>) => this.setState({address});

    onPostcodeChange = ({target: {value: postcode}}: React.ChangeEvent<HTMLInputElement>) => this.setState({postcode});

    onCityChange = ({target: {value: city}}: React.ChangeEvent<HTMLInputElement>) => this.setState({city});

    onRegionChange = ({target: {value: region}}: React.ChangeEvent<HTMLInputElement>) => this.setState({region});

    onPhoneChange = ({target: {value: phone}}: React.ChangeEvent<HTMLInputElement>) => this.setState({phone});

    render() {
        const documentsChecked: string[] = [];
        Object.entries(this.state.documentsRequested).forEach(([key, value]) => value && documentsChecked.push(key));

        return (
            <>
                {(this.state.loading !== 0 && <HeaderLoading />) || null}
                {this.state.isModalErrorCommentsLengthOpen ? (
                    <ModalErrorVersion2
                        message={`There was an error while sending your request. Please reduce the comments to ${this.state.isModalErrorCommentsLengthOpen} characters and try again.`}
                        close={this.closeModalError}
                    />
                ) : null}
                {this.state.isModalSuccessOpen ? (
                    <ModalAlertVersion2
                        title={`Success`}
                        message={`Your request of document(s) to the CPVO has been successfully submitted.`}
                        close={this.closeModalSuccess}
                    />
                ) : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={'Request of document(s) to the CPVO'} />
                <Navigation />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div>
                            <div
                                style={{
                                    display: 'inline-block',
                                    width: OUTSIDE_LABEL_WIDTH,
                                    textAlign: 'right',
                                    paddingRight: 15,
                                    paddingTop: 15,
                                    verticalAlign: 'top',
                                }}
                            >
                                {`Document(s) requested`}
                                <span style={{color: 'red', marginLeft: 5}}>*</span>
                            </div>
                            <div style={{display: 'inline-block'}}>
                                <div>
                                    {DOCUMENT_REQUESTED.map(({title, id}) => (
                                        <div key={id}>
                                            <Checkbox
                                                clickAction={() => this.onCheckboxDocumentRequested(id)}
                                                label={title}
                                                value={this.state.documentsRequested[id]}
                                                width={300}
                                                simple={true}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div style={{clear: 'both'}} />
                        <div
                            style={{marginLeft: OUTSIDE_LABEL_WIDTH + 22, marginBottom: 30}}
                        >{`Note: if you cannot find the appropriate document in the list, please choose "other" and fill in a detailed description of your needs in the "Comments" box hereunder.`}</div>
                        <div>
                            <div style={{marginBottom: 30}}>
                                <TextLabelInput
                                    double={true}
                                    onSelectionChange={this.onApplicationNumbersSelectionChange}
                                    onChange={this.onApplicationNumberChange}
                                    onEnter={this.onAddingApplicationNumber}
                                    value={this.state.applicationNumber}
                                    selectedElements={this.state.selectedApplicationNumbers}
                                    outsideLabel={
                                        documentsChecked.filter((el: any) => el !== 'other').length > 0
                                            ? this.getMandatoryAsterisk('Application number')
                                            : 'Application number'
                                    }
                                    delay={300}
                                    outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                    width={INPUT_WIDTH}
                                    multiple={true}
                                    buttonAction={this.onAddingApplicationNumber}
                                    buttonIcon={faPlus}
                                    placeholder={`e.g. 20101234`}
                                    buttonDisabled={
                                        (this.state.applicationNumberError &&
                                            this.state.applicationNumberError !== 'ADD') ||
                                        this.state.applicationNumber.length !== 8
                                    }
                                />
                                {this.state.applicationNumberError && (
                                    <div style={{textAlign: 'center', width: 700, marginBottom: 20, marginLeft: 150}}>
                                        <Info>
                                            <div style={{display: 'inline-block', marginRight: 5}}>
                                                <FontAwesomeIcon icon={faFlag as any} color={'#8a6d3b'} />
                                            </div>
                                            <div style={{display: 'inline-block'}}>
                                                {errorMessages[this.state.applicationNumberError]}
                                            </div>
                                        </Info>
                                    </div>
                                )}
                                <div style={{clear: 'both'}} />
                            </div>
                        </div>
                        <TextAreaInput
                            double={true}
                            value={this.state.comments}
                            onChange={this.onCommentsChange}
                            outsideLabel={'Comments'}
                            outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                            width={INPUT_WIDTH}
                            rows={4}
                        />
                        <div>
                            <div>
                                <Label width={OUTSIDE_LABEL_WIDTH}>{this.getMandatoryAsterisk('Title:')}</Label>
                                <RadioButton
                                    radioButtonList={[
                                        {
                                            description: 'Mr',
                                            value: TITLE.MR,
                                        },
                                        {description: 'Ms', value: TITLE.MS},
                                    ]}
                                    groupName={'title'}
                                    value={this.state.title}
                                    clickEvent={this.onTitleChange}
                                />
                            </div>
                            <div style={{clear: 'both'}} />
                            <TextInput
                                double={true}
                                onChange={this.onLastNameChange}
                                value={this.state.lastName}
                                outsideLabel={this.getMandatoryAsterisk('Last name')}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                width={INPUT_WIDTH}
                            />
                            <div style={{clear: 'both'}} />
                            <TextInput
                                double={true}
                                onChange={this.onFirstNameChange}
                                value={this.state.firstName}
                                outsideLabel={this.getMandatoryAsterisk('First name')}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                width={INPUT_WIDTH}
                            />
                            <div style={{clear: 'both'}} />
                            <TextInput
                                double={true}
                                onChange={this.onCompanyChange}
                                value={this.state.company}
                                outsideLabel={'Company'}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                width={INPUT_WIDTH}
                            />
                            <div style={{clear: 'both'}} />
                            <TextInput
                                double={true}
                                onChange={this.onOccupationChange}
                                value={this.state.occupation}
                                outsideLabel={'Occupation'}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                width={INPUT_WIDTH}
                            />
                            <div style={{clear: 'both'}} />
                            <TextInput
                                double={true}
                                onChange={this.onEmailChange}
                                value={this.state.email}
                                outsideLabel={this.getMandatoryAsterisk('Email')}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                width={INPUT_WIDTH}
                            />
                        </div>
                        <div style={{clear: 'both'}} />
                        <div>
                            <TextAreaInput
                                double={true}
                                value={this.state.address}
                                onChange={this.onAddressChange}
                                outsideLabel={this.getMandatoryAsterisk('Address')}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                width={INPUT_WIDTH}
                                rows={3}
                            />
                            <div>
                                <TextInput
                                    double={true}
                                    onChange={this.onPostcodeChange}
                                    value={this.state.postcode}
                                    outsideLabel={this.getMandatoryAsterisk('Postcode')}
                                    outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                    width={INPUT_WIDTH}
                                />
                            </div>
                            <div style={{clear: 'both'}} />
                            <TextInput
                                double={true}
                                onChange={this.onCityChange}
                                value={this.state.city}
                                outsideLabel={this.getMandatoryAsterisk('City')}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                width={INPUT_WIDTH}
                            />
                            <div style={{clear: 'both'}} />
                            <TextInput
                                double={true}
                                onChange={this.onRegionChange}
                                value={this.state.region}
                                outsideLabel={'Region'}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                width={INPUT_WIDTH}
                            />
                            <div style={{clear: 'both'}} />
                            <input autoComplete="off" name="hidden" type="text" style={{display: 'none'}} />
                            <TextLabelInput
                                double={true}
                                onSelectionChange={this.onCountrySelectionChange}
                                onIdChange={this.onIdChange}
                                onChange={this.onCountryChange}
                                value={this.state.country}
                                selectedElements={this.state.selectedCountry}
                                delay={300}
                                multiple={true}
                                outsideLabel={this.getMandatoryAsterisk('Country')}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                listByIdName={this.state.countryListByIdName}
                                nameHash={this.state.countryNameHash}
                                width={INPUT_WIDTH}
                                autoSelection={true}
                                onBlur={this.onCountryInputBlur}
                                uniqueValue={true}
                            />
                            <div style={{clear: 'both'}} />
                        </div>
                        <TextInput
                            double={true}
                            onChange={this.onPhoneChange}
                            value={this.state.phone}
                            outsideLabel={'Phone'}
                            outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                            width={INPUT_WIDTH}
                        />
                        <div style={{clear: 'both'}} />
                        <TextInput
                            double={true}
                            disabled={true}
                            value={`Email only`}
                            outsideLabel={'Delivery'}
                            outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                            width={INPUT_WIDTH}
                        />
                        <div style={{clear: 'both'}} />
                        <div
                            style={{marginLeft: OUTSIDE_LABEL_WIDTH + 22}}
                        >{`Note: in case of numerous documents, a link to the downloadable files will be sent by email.`}</div>
                        <div style={{clear: 'both'}} />
                        <div
                            style={{
                                marginTop: 30,
                                marginBottom: 30,
                                marginLeft: OUTSIDE_LABEL_WIDTH + 22,
                                color: '#3c763d',
                            }}
                        >
                            {`Note: mandatory fields are marked with an asterisk (`}
                            <span style={{color: 'red'}}>*</span>
                            {`)`}
                        </div>
                        <div style={{marginLeft: OUTSIDE_LABEL_WIDTH + 22, marginBottom: 30}}>
                            Protection of personal data
                            <br />
                            Personal data you have entered in this request form is processed in accordance with the
                            Regulation (EU) No 2018/1725 of the European Parliament and of the Council of 23 October
                            2018 on the protection of natural persons with regard to the processing of personal data by
                            the Union institutions, bodies, offices and agencies and on the free movement of such data,
                            and repealing Regulation (EC) No 45/2001 and Decision No 1247/2002/EC. For more information,
                            please consult our CPVO Decision of 17 December 2018 on Implementing Regulation (EU)
                            2018/1725, the Data Protection Notice, the CPVO Data Protection Register, and the privacy
                            statements, available on our website{' '}
                            <a href="https://cpvo.europa.eu/en/data-protection" target={'_blank'} rel="noreferrer">
                                https://cpvo.europa.eu/en/data-protection
                            </a>
                            .
                        </div>
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            {this.state.error ? <Error>{this.state.error}</Error> : null}
                            <Button clickAction={this.clearForm} variation={'secondary'}>{`Reset form`}</Button>
                            <Button clickAction={this.sendForm}>{`Send form`}</Button>
                        </FormFooter>
                    </FormWrapper>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(PublicAccess));
