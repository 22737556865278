import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';
import {IGroup} from '../../types';

export function apiFormDatas(officeId: number) {
    return getFetch(`${API_SERVERLESS}/backOffice/v2/datas?officeId=${officeId}`, false).then((jsonResponse: any) => {
        if (jsonResponse) {
            if (jsonResponse.datas && jsonResponse.datas.countries) {
                jsonResponse.datas.ue_countries = jsonResponse.datas.countries.filter(
                    ({UEMEMBER}: any = {}) => parseInt(UEMEMBER) === 1
                );
            }
            return {
                ...jsonResponse,
                datas: {
                    ...jsonResponse.datas,
                    eo_countries: [
                        {
                            ID: 'ID',
                            LABEL: 'Countries depends on species selected by end user',
                        },
                    ],
                },
            };
        }
    });
}

export function apiBackOfficeGetTemplate(
    templateId: number,
    language = 'EN'
): Promise<{
    template: IGroup;
}> {
    return getFetch(`${API_SERVERLESS}/backOffice/v3/templateWithQuestions/${language}/${templateId}`, true);
}

export function apiBackOfficeRemoveQuestion(JSONRequest: {
    formId: null;
    questionId: number;
    templateId?: number | null;
}): Promise<{data: string}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/questionDelete`, true, JSONRequest);
}

export function apiBackOfficeRemoveResponse(JSONRequest: {
    formId: null;
    questionId: number;
    responseId: number;
    templateId?: number | null;
}): Promise<{data: string}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/responseDelete`, true, JSONRequest);
}
