import React, {useEffect, useRef, useState} from 'react';
import {MAX_UPLOAD_FILE_SIZE} from '../../../../utils/constants';
import {Button} from '../../../../componentsFormV2';
import CaseFormPagePVRCASELAWActions from './CaseFormPagePVRCASELAWActions';
import {
    CaseElement,
    CaseMetaDataFetchData,
    CountriesFetchData,
    KeywordsFetchData,
    LanguagesFetchData,
} from '../../CommonInterfaces/CommonInterfaces';
import Checkbox from '~components/Checkbox';
import CryptoJS from 'crypto-js';
import CustomTable from '~components/CustomTable';
import DateInput from '~components/DateInput';
import {ErrorMessages} from '../../../TLO/CommonInterfaces/CommonInterfaces';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {ModalConfirmVersion2, ModalErrorVersion2} from '../../../../commonModals';
import ModalKeywordsList from './modals/ModalKeywordsList';
import NavigationPVRCaseLaw from '../../../../shared/NavigationPVRCaseLaw';
import {RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import SelectInput from '~components/SelectInput';
import TextAreaInput from '~components/TextAreaInput';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import {
    apiCaseEdit,
    apiCaseSave,
    apiGetCaseFetch,
    apiKeywordsListFetch,
    apiUploadCaseFile,
    CaseFormPageUrlParamsData,
    KeywordElement,
    KeywordsListFetchData,
} from './CaseFormPagePVRCASELAWService';
import {
    apiCaseDelete,
    apiCountriesFetch,
    apiDownloadFile,
    apiKeywordsFetch,
    apiLanguagesFetch,
} from '../../CommonFunctions/CommonFunctions';
import {faArrowLeft, faFileUpload, faPencilAlt, faPlus} from '@fortawesome/free-solid-svg-icons';
import {formatDate, reformatDate} from '~components/FormatFunctions';
import {getPreSignedURLFetchRequest} from '../../../../utils/requests';
import {getConcatErrorMessage, getUserRoles} from '../../../TLO/CommonFunctions/CommonFunctions';
import getIcon from '~utils/icons';
import {injectIntl, IntlShape} from 'react-intl';
import moment from 'moment';
import {sanitize, trackPageView, customBTOA} from '../../../../utils';
import {saveAs} from 'file-saver';
import styles from './CaseFormPagePVRCASELAW.module.scss';
import {History} from 'history';

interface fileJsonData {
    fileName: string;
    caseId?: number;
    isSummary?: boolean;
    file?: File;
    translationLanguageId?: string;
    buttonId: string;
}

const modalScreenTypes = {
    ERROR: 'ERROR',
    CONFIRM_DELETE: 'CONFIRM_DELETE',
    CONFIRM_DELETE_FILE: 'CONFIRM_DELETE_FILE',
    MANAGE_KEYWORDS: 'MANAGE_KEYWORDS',
};

interface CaseFormPagePVRCASELAWProps {
    caseElement: CaseElement | null;
    intl: IntlShape;
    history: History;
}

const CaseFormPagePVRCASELAW = (props: CaseFormPagePVRCASELAWProps & RouteComponentProps) => {
    let currentCachedSelectedKeywords: any = null;
    const maxCounter = 2000;
    const [abstractCounter, setAbstractCounter] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [customError, setCustomError] = useState<boolean>(false);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [redirectOnErrorClose, setRedirectOnErrorClose] = useState<boolean>(false);
    const [caseElement, setCaseElement] = useState<CaseElement | null>(null);
    const [caseId, setCaseId] = useState<number | 0>(0);
    const [keywords, setKeywords] = useState<string | undefined>(undefined);
    const [keywordsSearchTerm, setKeywordsSearchTerm] = useState<string | undefined>(undefined);
    const [keywordIds, setKeywordIds] = useState<string | undefined>(undefined);
    const [origin, setOrigin] = useState<string | undefined>(undefined);
    const [decisionLanguage, setDecisionLanguage] = useState<string>('');
    const [caseName, setCaseName] = useState<string | undefined>(undefined);
    const [isOnline, setIsOnline] = useState<boolean | undefined>(undefined);
    const [decidingBody, setDecidingBody] = useState<string | undefined>(undefined);
    const [decisionNumber, setDecisionNumber] = useState<string | undefined>(undefined);
    const [decisionDate, setDecisionDate] = useState<string | undefined>(undefined);
    const [publicationNumber, setPublicationNumber] = useState<string | undefined>(undefined);
    const [publicationDate, setPublicationDate] = useState<string | undefined>(undefined);
    const [parties, setParties] = useState<string | undefined>(undefined);
    const [abstractInput, setAbstractInput] = useState<string | undefined>(undefined);

    const [judgmentLanguage, setJudgmentLanguage] = useState<string>();
    const [_1, setJudgmentLanguageOptions] = useState<{id: string; value: string}[]>();

    /* Upload  */
    const [uploadedSummaryDocName, setUploadedSummaryDocName] = useState<string>('');
    const [uploadedJudgmentEngDocName, setUploadedJudgmentEngDocName] = useState<string>('');
    const [uploadedJudgmentTranslationDocName, setUploadedJudgmentTranslationDocName] = useState<string>('');

    const userRoles = getUserRoles();

    const [originOptions, setOriginOptions] = useState<{id: string; value: string}[]>();
    const [decisionLanguageOptions, setDecisionLanguageOptions] = useState<{id: string; value: string}[]>();
    const [selectedKeywords, setSelectedKeywords] = useState<{[key: string]: string}>({});
    const [_2, setKeywordsListByIdName] = useState<{ID: string; NAME: string}[] | null>(null);
    const [_3, setKeywordsNameHash] = useState<{[key: string]: string[]}>({});
    const [keywordsListByIdNameSuggestions, setKeywordsListByIdNameSuggestions] = useState<
        {ID: string; NAME: string}[] | null
    >(null);
    const [keywordsNameHashSuggestions, setKeywordsNameHashSuggestions] = useState<{[key: string]: string[]}>({});
    const [keywordsTimestamp, setKeywordsTimestamp] = useState(Date.now());

    const [keywordsList, setKeywordsList] = useState<KeywordElement[]>([]);
    const [timestamp, setTimestamp] = useState(0);

    const [filesData, setFilesData] = useState<fileJsonData[]>([]);

    const [screenLoaded, setScreenLoaded] = useState(false);

    const firstLoad = useFirstRender();

    useEffect(() => {
        trackPageView({documentTitle: 'pvrcaselawCase'});
    }, []);

    function useFirstRender() {
        const firstRender = useRef(true);

        useEffect(() => {
            firstRender.current = false;
        }, []);

        return firstRender.current;
    }

    let history = useHistory();

    const loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        const parameters: CaseFormPageUrlParamsData = JSON.parse(JSON.stringify({}));
        if (domainArray.length > 1) {
            const params = domainArray.pop();
            if (params)
                params.split('&').forEach(param => {
                    const paramElements = param.split('=');
                    if (paramElements.length === 2) {
                        const key = paramElements[0];
                        parameters[key] = decodeURIComponent(paramElements[1]);
                    }
                });
        }
        if (parameters.caseId) {
            setCaseId(parseInt(parameters.caseId));
        } else {
            setCaseId(0);
            setScreenLoaded(true);
            const storedValue = localStorage.getItem('newDraft');
            if (storedValue === null) {
                console.log('No value found in localStorage for key "newDraft"');
                return false;
            } else {
                if (confirm('Do you want to load the cached data?')) {
                    const {
                        origin: cachedOrigin,
                        decisionLanguage: cachedDecisionLanguage,
                        parties: cachedParties,
                        decidingBody: cachedDecidingBody,
                        decisionDate: cachedDecisionDate,
                        decisionNumber: cachedDecisionNumber,
                        caseName: cachedCaseName,
                        isOnline: cachedIsOnline,
                        abstractInput: cachedAbstractInput,
                        publicationDate: cachedPublicationDate,
                        publicationNumber: cachedPublicationNumber,
                        selectedKeywords: cachedSelectedKeywords,
                    } = JSON.parse(storedValue);
                    setOrigin(cachedOrigin);
                    setDecisionLanguage(cachedDecisionLanguage);
                    setParties(cachedParties);
                    setDecidingBody(cachedDecidingBody);
                    setDecisionDate(cachedDecisionDate);
                    setDecisionNumber(cachedDecisionNumber);
                    setCaseName(cachedCaseName);
                    setIsOnline(cachedIsOnline);
                    setAbstractInput(cachedAbstractInput);
                    setPublicationDate(cachedPublicationDate);
                    setPublicationNumber(cachedPublicationNumber);
                    currentCachedSelectedKeywords = cachedSelectedKeywords;
                } else {
                    localStorage.removeItem('newDraft');
                }
            }
        }
    };

    const getOriginsList = () => {
        apiCountriesFetch()
            .then((jsonResponse: CountriesFetchData) => {
                const originsList: {id: string; value: string}[] = [{id: '', value: ''}];
                ((jsonResponse && jsonResponse.data && jsonResponse.data.countries) || []).forEach(el => {
                    originsList.push({
                        id: el.countryId,
                        value: el.countryName,
                    });
                });
                setOriginOptions(originsList);
            })
            .catch(error => {
                setLoading(false);
                setErrorMessage(`Origins list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            });
    };

    const getLanguagesList = () => {
        apiLanguagesFetch()
            .then((jsonResponse: LanguagesFetchData) => {
                const languagesList: {id: string; value: string}[] = [{id: '', value: ''}];
                ((jsonResponse && jsonResponse.data && jsonResponse.data.languages) || []).forEach(el => {
                    languagesList.push({
                        id: el.languageId,
                        value: el.languageName,
                    });
                });
                setDecisionLanguageOptions(languagesList);
            })
            .catch(error => {
                setLoading(false);
                setErrorMessage(`Languages list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            });
    };

    const getKeywordsAutocompletionList = () => {
        apiKeywordsFetch()
            .then((jsonResponse: KeywordsFetchData) => {
                const keywordsNameHash: {[key: string]: string[]} = {};
                ((jsonResponse && jsonResponse.data && jsonResponse.data.keywords) || []).forEach(el => {
                    keywordsNameHash[el.keyword] = [];
                    if (!keywordsNameHash[el.keyword]) {
                        keywordsNameHash[el.keyword] = [];
                    }
                    keywordsNameHash[el.keyword].push(el.keywordId);
                });
                const keywordsListByIdName = Object.keys(keywordsNameHash).map(el => ({
                    ID: keywordsNameHash[el].join(','),
                    NAME: el,
                }));
                const selectedKeywordObj: {[key: string]: string} = {};
                const selectedKeywordKeyArray = Object.keys(currentCachedSelectedKeywords || selectedKeywords || {});
                keywordsListByIdName
                    .filter(i => selectedKeywordKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectedKeywordObj[item.ID] = item.NAME));
                setKeywordsNameHash(keywordsNameHash);
                setKeywordsNameHashSuggestions(keywordsNameHash);
                setKeywordsListByIdName(keywordsListByIdName);
                setKeywordsListByIdNameSuggestions(keywordsListByIdName);
                setSelectedKeywords(selectedKeywordObj);
            })
            .catch(error => {
                setLoading(false);
                setErrorMessage(`Keywords Autocompletion list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            });
    };

    const getJudgmentLanguagesList = () => {
        apiLanguagesFetch()
            .then((jsonResponse: LanguagesFetchData) => {
                const languagesList: {id: string; value: string}[] = [{id: '', value: ''}];
                ((jsonResponse && jsonResponse.data && jsonResponse.data.languages) || []).forEach(el => {
                    languagesList.push({
                        id: el.languageId,
                        value: el.languageName,
                    });
                });
                setJudgmentLanguageOptions(languagesList);
            })
            .catch(error => {
                setLoading(false);
                setErrorMessage(`Judgment languages list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            });
    };

    useEffect(() => {
        if (firstLoad && userRoles.indexOf('WLAW') !== -1) {
            window.scrollTo(0, 0);
            setLoading(true);
            loadUrlParams();
            getOriginsList();
            getLanguagesList();
            getKeywordsAutocompletionList();
            getJudgmentLanguagesList();
        }
    }, []);

    const getCaseElement = () => {
        setLoading(true);
        apiGetCaseFetch(caseId)
            .then((jsonResponse: CaseMetaDataFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.case && jsonResponse.data.case.length > 0)
                    setCaseElement(jsonResponse.data.case[0]);
            })
            .catch(error => {
                setLoading(false);
                setErrorMessage(`View Case Fetch error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
                setRedirectOnErrorClose(true);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        !firstLoad && caseId > 0 && userRoles.indexOf('WLAW') !== -1 && getCaseElement();
        firstLoad && (!caseId || caseId <= 0) && userRoles.indexOf('WLAW') !== -1 && setLoading(false);
    }, [caseId]);

    useEffect(() => {
        if (caseElement) {
            setKeywords(caseElement?.keywords);
            setKeywordIds(caseElement?.keywordIds);
            setOrigin(caseElement?.countryId);
            setDecisionLanguage(caseElement?.languageId ? caseElement?.languageId : '');
            setCaseName(caseElement?.caseName);
            setIsOnline(caseElement?.isOnline && String(caseElement?.isOnline) === '1' ? true : false);
            setDecidingBody(caseElement?.decidingBody);
            setDecisionNumber(caseElement?.decisionNumber);
            setDecisionDate(caseElement?.decisionDate);
            setPublicationNumber(caseElement?.publicationNumber);
            setPublicationDate(caseElement?.publicationDate);
            setParties(caseElement?.parties);
            setAbstractInput(caseElement?.abstractInput);

            if (caseElement?.abstractInput) setAbstractCounter(caseElement?.abstractInput.length);

            caseElement.translationLanguageId === null && caseElement.languageId !== null
                ? setJudgmentLanguage(caseElement.languageId)
                : setJudgmentLanguage(caseElement.translationLanguageId);
            setUploadedSummaryDocName(caseElement.summaryFile ? caseElement.summaryFile : '');
            setUploadedJudgmentEngDocName(caseElement.decisionFile ? caseElement.decisionFile : '');
            setUploadedJudgmentTranslationDocName(
                caseElement.decisionTranslationFile ? caseElement.decisionTranslationFile : ''
            );

            let responseFilesData = [];
            if (caseElement.summaryFile) {
                responseFilesData.push({
                    fileName: caseElement.summaryFile,
                    buttonId: 'uploadSummary',
                });
            }
            if (caseElement.decisionFile) {
                responseFilesData.push({
                    fileName: caseElement.decisionFile,
                    buttonId: 'uploadJudgmentEng',
                });
            }
            if (caseElement.decisionTranslationFile) {
                responseFilesData.push({
                    fileName: caseElement.decisionTranslationFile,
                    buttonId: 'uploadJudgmentTranslation',
                });
            }
            setFilesData(responseFilesData);
            setTimestamp(Date.now());
            setScreenLoaded(true);
        }
    }, [caseElement]);

    useEffect(() => {
        const keywordIdsArray = keywordIds ? keywordIds.split(', ') : [];
        const keywordsArray = keywords ? keywords.split(', ') : [];
        for (let i = 0; i < keywordIdsArray.length; i++) selectedKeywords[keywordIdsArray[i]] = keywordsArray[i];
        setSelectedKeywords(selectedKeywords);
    }, [keywordIds, keywords]);

    const onCaseNameChange = ({target: {value: caseName}}: React.ChangeEvent<HTMLInputElement>) =>
        setCaseName(caseName.slice(0, 100));

    const onDecidingBodyChange = ({target: {value: decidingBody}}: React.ChangeEvent<HTMLInputElement>) =>
        setDecidingBody(decidingBody.slice(0, 500));

    const onDecisionNumberChange = ({target: {value: decisionNumber}}: React.ChangeEvent<HTMLInputElement>) =>
        setDecisionNumber(decisionNumber.slice(0, 20));

    const onPublicationNumberChange = ({target: {value: publicationNumber}}: React.ChangeEvent<HTMLInputElement>) =>
        setPublicationNumber(publicationNumber.slice(0, 20));

    const onPartiesChange = ({target: {value: parties}}: React.ChangeEvent<HTMLInputElement>) =>
        setParties(parties.slice(0, 1000));

    const onAbstractChange = ({target: {value: abstractInput}}: React.ChangeEvent<HTMLInputElement>) => {
        if (abstractInput.length <= maxCounter) {
            setAbstractCounter(abstractInput.length);
            setAbstractInput(abstractInput);
        }
    };

    const onDecisionDateChange = (decisionDate: string) => {
        setDecisionDate(decisionDate);
    };

    const onPublicationDateChange = (publicationDate: string) => {
        setPublicationDate(publicationDate);
    };

    const onCheckIsOnline = () => {
        setIsOnline(!isOnline);
    };

    const onOriginChange = ({target: {value: origin}}: React.ChangeEvent<HTMLSelectElement>) => {
        setOrigin(origin);
    };

    const onDecisionLanguageChange = ({target: {value: decisionLanguage}}: React.ChangeEvent<HTMLSelectElement>) => {
        setDecisionLanguage(decisionLanguage);
        setJudgmentLanguage(decisionLanguage);
    };

    const onKeywordIdsSelectionChange = (selectedKeywords: {[key: string]: string}) => {
        setSelectedKeywords(selectedKeywords);
        setKeywordsTimestamp(Date.now());
    };

    const onKeywordIdsChange = (_keywordIds: string) => {
        const keywordsArray: string[] = [];
        const keywordsIdsArray: string[] = [];
        Object.entries(selectedKeywords).forEach(el => {
            const [key, value] = el;
            if (key && value) {
                keywordsArray.push(value);
                keywordsIdsArray.push(key);
            }
        });
        setKeywords(keywordsArray.join(', '));
        setKeywordIds(keywordsIdsArray.join(', '));
        setKeywordsSearchTerm(_keywordIds);
    };

    const openManageKeywordsPage = () => {
        getKeywordsList();
    };

    const onCreateNewCase = () => {
        setLoading(true);
        props.history.push('/pvrcaselawCase');
        window.location.reload();
        window.scrollTo(0, 0);
    };

    const getErrorMessagesForSave = () => {
        const errorMessages: ErrorMessages = {
            errorInput: [],
            errorLogic: [],
        };

        if (!origin || origin === null || Number(origin) <= 0) {
            errorMessages.errorInput &&
                (errorMessages.errorInput.length > 0
                    ? errorMessages.errorInput.push('\nan origin for the new case')
                    : errorMessages.errorInput && errorMessages.errorInput.push('an origin for the new case'));
        }
        if (!decisionLanguage || decisionLanguage === null || Number(decisionLanguage) <= 0) {
            errorMessages.errorInput &&
                (errorMessages.errorInput.length > 0
                    ? errorMessages.errorInput.push('\na decision language for the new case')
                    : errorMessages.errorInput.push('a decision language for the new case'));
        }
        if (!decidingBody || decidingBody === null) {
            errorMessages.errorInput &&
                (errorMessages.errorInput.length > 0
                    ? errorMessages.errorInput.push('\na deciding body for the new case')
                    : errorMessages.errorInput.push('a deciding body for the new case'));
        }
        if (!parties || parties === null) {
            errorMessages.errorInput &&
                (errorMessages.errorInput.length > 0
                    ? errorMessages.errorInput.push('\na parties for the new case')
                    : errorMessages.errorInput.push('a parties for the new case'));
        }

        if (filesData.find(file => !file.buttonId || file.buttonId === 'NONE')) {
            errorMessages.errorInput &&
                (errorMessages.errorInput.length > 0
                    ? errorMessages.errorInput.push('\na document type for all files')
                    : errorMessages.errorInput && errorMessages.errorInput.push('a document type for all files'));
        }

        return errorMessages;
    };

    const onSaveFiles = async () => {
        let promises: any = [];
        let fileTypes: any = [];
        const createUUIDs: any = {};
        filesData.forEach(file => {
            if (file.file) {
                promises.push(apiUploadCaseFile(Object.assign({}, file)));
                fileTypes.push(file.buttonId);
            }
        });
        try {
            const responses = await Promise.all(promises);
            responses.forEach((response, index) => {
                createUUIDs[fileTypes[index]] = response.fileUUID;
            });
            return createUUIDs;
        } catch (error: any) {
            setErrorMessage(`error uploading file`);
            setModalScreen(modalScreenTypes.ERROR);
        }
    };

    const getPayloadKeywords = () => Object.keys(selectedKeywords || []).map(keywordId => parseInt(keywordId));

    const onSaveCase = async () => {
        setLoading(true);
        setCustomError(false);
        const errorMessagesObj = getErrorMessagesForSave();
        let errorMessage = getConcatErrorMessage(errorMessagesObj);

        if (errorMessage.length > 0) {
            setLoading(false);
            setErrorMessage(errorMessage);
            setModalScreen(modalScreenTypes.ERROR);
            setCustomError(true);
        }

        if (errorMessage.length === 0) {
            const createUUIDs = await onSaveFiles();
            if (caseId > 0) {
                const editDraft = {
                    caseId,
                    origin,
                    decisionLanguage,
                    parties,
                    decidingBody,
                    decisionDate,
                    decisionNumber,
                    caseName,
                    isOnline,
                    abstractInput,
                    publicationDate,
                    publicationNumber,
                    selectedKeywords,
                };
                apiCaseEdit(
                    Object.assign(
                        {},
                        {
                            caseId: caseId,
                            countryId: origin || '',
                            decisionLanguageId: decisionLanguage || '',
                            parties: parties ? customBTOA(parties) : '',
                            decidingBody: decidingBody ? customBTOA(decidingBody) : '',
                            decisionDate: decisionDate
                                ? moment(decisionDate, decisionDate.includes('/') ? 'DD/MM/YYYY' : undefined).format(
                                      'YYYY-MM-DD'
                                  )
                                : '',
                            decisionNumber: decisionNumber ? customBTOA(decisionNumber) : '',
                            caseName: caseName ? customBTOA(caseName) : '',
                            isOnline: isOnline || false,
                            abstractInput: abstractInput ? customBTOA(abstractInput) : '',
                            publicationDate: publicationDate
                                ? moment(
                                      publicationDate,
                                      publicationDate.includes('/') ? 'DD/MM/YYYY' : undefined
                                  ).format('YYYY-MM-DD')
                                : '',
                            publicationNumber: publicationNumber ? customBTOA(publicationNumber) : '',
                            keywords: getPayloadKeywords(),
                            summaryFile: createUUIDs['uploadSummary']
                                ? customBTOA(createUUIDs['uploadSummary'])
                                : uploadedSummaryDocName
                                ? customBTOA(uploadedSummaryDocName)
                                : uploadedSummaryDocName,
                            decisionFile: createUUIDs['uploadJudgmentEng']
                                ? customBTOA(createUUIDs['uploadJudgmentEng'])
                                : uploadedJudgmentEngDocName
                                ? customBTOA(uploadedJudgmentEngDocName)
                                : uploadedJudgmentEngDocName,
                            decisionTranslationLanguageId:
                                uploadedJudgmentTranslationDocName.length > 0 && judgmentLanguage
                                    ? judgmentLanguage
                                    : '',
                            decisionTranslationFile: createUUIDs['uploadJudgmentTranslation']
                                ? customBTOA(createUUIDs['uploadJudgmentTranslation'])
                                : uploadedJudgmentTranslationDocName
                                ? customBTOA(uploadedJudgmentTranslationDocName)
                                : uploadedJudgmentTranslationDocName,
                        }
                    )
                )
                    .then(() => {
                        // window.location.href = `/pvrcaselawCase?caseId=${caseId}`;
                        // onReturnToTheList();
                        props.history.push('/pvrcaselawSearchCasesBasicSearch');
                    })
                    .catch(error => {
                        setErrorMessage(
                            `error saving case: ${
                                error.message
                            }. Please copy this data and put in the Mantis ticket: ${JSON.stringify(editDraft)}`
                        );
                        setModalScreen(modalScreenTypes.ERROR);
                        setLoading(false);
                    });
            } else {
                const newDraft = {
                    origin,
                    decisionLanguage,
                    parties,
                    decidingBody,
                    decisionDate,
                    decisionNumber,
                    caseName,
                    isOnline,
                    abstractInput,
                    publicationDate,
                    publicationNumber,
                    selectedKeywords,
                };
                localStorage.setItem('newDraft', JSON.stringify(newDraft));
                apiCaseSave(
                    Object.assign(
                        {},
                        {
                            countryId: origin || '',
                            decisionLanguageId: decisionLanguage || '',
                            parties: parties ? customBTOA(parties) : '',
                            decidingBody: decidingBody ? customBTOA(decidingBody) : '',
                            decisionDate: decisionDate
                                ? moment(decisionDate, decisionDate.includes('/') ? 'DD/MM/YYYY' : undefined).format(
                                      'YYYY-MM-DD'
                                  )
                                : '',
                            decisionNumber: decisionNumber ? customBTOA(decisionNumber) : '',
                            caseName: caseName ? customBTOA(caseName) : '',
                            isOnline: isOnline || false,
                            abstractInput: abstractInput ? customBTOA(abstractInput) : '',
                            publicationDate: publicationDate
                                ? moment(
                                      publicationDate,
                                      publicationDate.includes('/') ? 'DD/MM/YYYY' : undefined
                                  ).format('YYYY-MM-DD')
                                : '',
                            publicationNumber: publicationNumber ? customBTOA(publicationNumber) : '',
                            keywords: getPayloadKeywords(),
                            summaryFile: createUUIDs['uploadSummary']
                                ? customBTOA(createUUIDs['uploadSummary'])
                                : uploadedSummaryDocName
                                ? customBTOA(uploadedSummaryDocName)
                                : uploadedSummaryDocName,
                            decisionFile: createUUIDs['uploadJudgmentEng']
                                ? customBTOA(createUUIDs['uploadJudgmentEng'])
                                : uploadedJudgmentEngDocName
                                ? customBTOA(uploadedJudgmentEngDocName)
                                : uploadedJudgmentEngDocName,
                            decisionTranslationLanguageId:
                                uploadedJudgmentTranslationDocName.length > 0 && judgmentLanguage
                                    ? judgmentLanguage
                                    : '',
                            decisionTranslationFile: createUUIDs['uploadJudgmentTranslation']
                                ? customBTOA(createUUIDs['uploadJudgmentTranslation'])
                                : uploadedJudgmentTranslationDocName
                                ? customBTOA(uploadedJudgmentTranslationDocName)
                                : uploadedJudgmentTranslationDocName,
                        }
                    )
                )
                    .then((JSONResponse: any) => {
                        if (JSONResponse && JSONResponse.caseId) {
                            localStorage.removeItem('newDraft');
                            props.history.push('/pvrcaselawSearchCasesBasicSearch');
                        }
                        // onReturnToTheList();
                    })
                    .catch(error => {
                        setErrorMessage(
                            `error saving case: ${
                                error.message
                            }. Please copy this data and put in the Mantis ticket: ${JSON.stringify(newDraft)}`
                        );
                        setModalScreen(modalScreenTypes.ERROR);
                    })
                    .finally(() => setLoading(false));
            }
        }
    };

    const handlerDelete = () => {
        if (caseId > 0) setModalScreen(modalScreenTypes.CONFIRM_DELETE);
    };

    const onDeleteCase = () => {
        setLoading(true);
        if (caseId > 0) {
            apiCaseDelete(caseId)
                .then(() => {
                    setModalScreen(null);
                    onReturnToTheList();
                })
                .catch(error => {
                    setLoading(false);
                    setErrorMessage(`error deleting case: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                });
        }
    };

    const onReturnToTheList = () => history.goBack();

    const closeErrorModal = () => {
        setErrorMessage(null);
        setModalScreen(null);
        if (redirectOnErrorClose) {
            setRedirectOnErrorClose(false);
            onReturnToTheList();
        }
    };

    const closeModal = () => setModalScreen(null);

    const getKeywordsList = () => {
        setLoading(true);
        apiKeywordsListFetch()
            .then((jsonResponse: KeywordsListFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.keywords) {
                    setKeywordsList(jsonResponse.data.keywords);
                    setTimestamp(Date.now());
                }
            })
            .catch(error => {
                setErrorMessage(`Keywords list fetch error: ${error.message}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => {
                setLoading(false);
                setModalScreen(modalScreenTypes.MANAGE_KEYWORDS);
            });
    };

    const arrayBufferToString = (buffer: ArrayBuffer) => new TextDecoder().decode(buffer);

    const uploadFile = (filename: string, _index: any, file: File, buttonId: string = '') => {
        setLoading(true);
        const isSummary = buttonId === 'uploadSummary';
        const translationLanguageId =
            buttonId === 'uploadJudgmentEng' || decisionLanguage === 'EN'
                ? {}
                : {translationLanguageId: judgmentLanguage};
        const jsonData =
            caseId === 0
                ? Object.assign(
                      {},
                      {fileName: filename, isSummary: isSummary, file: file, buttonId: ''},
                      translationLanguageId
                  )
                : Object.assign(
                      {},
                      {fileName: filename, caseId: caseId, isSummary: isSummary, file: file, buttonId: ''},
                      translationLanguageId
                  );
        const filesDataTmp = filesData.concat(jsonData);
        setFilesData(filesDataTmp);
        setErrorMessage(null);
        setLoading(false);
        setTimestamp(Date.now());
    };

    const checkFile = (file: File, buttonId: string = '') => {
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType && fileType.toLowerCase();
        const fileName = sanitize(file.name.normalize ? file.name.normalize('NFD') : file.name);
        if (fileTypeLowercase === 'pdf') {
            if (file.size > MAX_UPLOAD_FILE_SIZE) {
                setErrorMessage('The maximum document size for uploads is 10 MB. Please select a proper file.');
                setModalScreen(modalScreenTypes.ERROR);
            } else {
                const fileReader = new FileReader();
                fileReader.readAsBinaryString(file);
                let fileReaderResult = '';
                if (fileReader.result === null) {
                    fileReaderResult = '';
                } else if (fileReader.result instanceof ArrayBuffer) {
                    fileReaderResult = arrayBufferToString(fileReader.result);
                } else {
                    fileReaderResult = fileReader.result;
                }
                fileReader.onloadend = () => {
                    const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(fileReaderResult));
                    setErrorMessage(null);
                    fileName.length > 0 && uploadFile(fileName, index, file, buttonId);
                };
            }
        } else {
            setErrorMessage('File Type is not valid. Please try again.');
            setModalScreen(modalScreenTypes.ERROR);
        }
    };

    const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length > 0) {
            const files = event.target.files;
            for (let i = 0; i < files.length; i++) checkFile(files[i], event.target.id);
            event.target.value = '';
        }
    };

    const downloadFile = (uuid: string) => {
        setLoading(true);
        apiDownloadFile(uuid)
            .then(response => {
                if (response && response.signedUrl) {
                    const {signedUrl} = response;
                    getPreSignedURLFetchRequest(signedUrl)
                        .then(response => response.blob())
                        .then(responseBlob => {
                            if (uuid.includes('-')) {
                                const fileNameArray = uuid.split('_');
                                fileNameArray.splice(0, 1);
                                const fileName = fileNameArray.join('_');
                                saveAs(responseBlob, fileName);
                            } else {
                                saveAs(responseBlob, uuid);
                            }
                        });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading file: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const onSelectDocumentType = ({target: {value: buttonId}}: any, object: any) => {
        setFilesData(prevFilesData => {
            const updatedFilesData = [...prevFilesData];
            let indexToUpdate = prevFilesData.findIndex(file => file.fileName === object.fileName);
            if (indexToUpdate > -1) {
                updatedFilesData[indexToUpdate]['buttonId'] = buttonId;
            }
            return updatedFilesData;
        });
    };

    const deleteFile = (rowObject: any) => {
        setFilesData(prevFilesData => {
            const updatedFilesData = [...prevFilesData];
            const indexToDelete = updatedFilesData.findIndex(file => file.buttonId === rowObject.buttonId);
            if (indexToDelete > -1) {
                updatedFilesData.splice(indexToDelete, 1);
            }
            return updatedFilesData;
        });
        setTimestamp(Date.now());
        if (rowObject.buttonId === 'uploadSummary') {
            setUploadedSummaryDocName('');
        }
        if (rowObject.buttonId === 'uploadJudgmentEng') {
            setUploadedJudgmentEngDocName('');
        }
        if (rowObject.buttonId === 'uploadJudgmentTranslation') {
            setUploadedJudgmentTranslationDocName('');
        }
    };

    const getUploadSummaryDocArea = () => {
        const actions = CaseFormPagePVRCASELAWActions(deleteFile);

        const listDocumentTypes = [
            {id: 'NONE', value: 'Please choose'},
            {id: 'uploadSummary', value: 'Summary'},
            {id: 'uploadJudgmentEng', value: 'Judgment English'},
        ];

        if (decisionLanguage && decisionLanguage !== 'EN') {
            listDocumentTypes.push({id: 'uploadJudgmentTranslation', value: 'Judgment Translation'});
        }

        return (
            <div style={{marginLeft: 5, textAlign: 'left', paddingBottom: 20, float: 'left', width: '100%'}}>
                <div style={{float: 'left', width: '100%'}}>
                    <h4 style={{textAlign: 'left', margin: '10px 20px 10px 7px'}}>{`Documents list`}</h4>
                    <div style={{marginBottom: 15}}>
                        {filesData.length === 0 ? <div style={{marginLeft: 7}}>{`No document selected`}</div> : null}
                        {filesData.length > 0 ? (
                            <CustomTable
                                version={1}
                                notSortable={['fileName', 'buttonId']}
                                tableName={'documentUpload'}
                                tableType={'OBJECT'}
                                tableSource={filesData}
                                dataFilter={null}
                                id={'index'}
                                setLastCursor={null}
                                resultFieldsDefault={['fileName', 'buttonId']}
                                intl={props.intl}
                                formatFunctions={{
                                    buttonId: (buttonId: any, object: any) => (
                                        <div style={{marginTop: '-5px'}}>
                                            <SelectInput
                                                value={buttonId ? buttonId : 'Please choose type'}
                                                onChange={(event: any) => onSelectDocumentType(event, object)}
                                                list={listDocumentTypes}
                                                notAll={true}
                                                height={25}
                                                width={250}
                                                disabled={!object.file}
                                            />
                                            <div style={{clear: 'both'}} />
                                        </div>
                                    ),
                                }}
                                count={(filesData || []).length}
                                hideExcelButton={true}
                                noChangePageSize={true}
                                forehandColumn={(row: any) => (
                                    <img
                                        style={{width: 20, height: 20, cursor: 'pointer'}}
                                        alt={'Download'}
                                        src={getIcon(row.extension || 'pdf')}
                                        onClick={event => {
                                            event.stopPropagation();
                                            if (row.file) {
                                                saveAs(row.file, row.filename);
                                            } else if (row.fileName) {
                                                downloadFile(row.fileName);
                                            }
                                        }}
                                    />
                                )}
                                timestamp={timestamp}
                                actions={actions}
                                unlimited={true}
                            />
                        ) : null}
                    </div>
                    <div style={{float: 'left'}}>
                        <input
                            type="file"
                            name="files[]"
                            id="uploadJudgmentTranslation"
                            style={{opacity: 0, width: 0}}
                            onChange={onFileUpload}
                            accept="application/pdf"
                            disabled={false}
                        />
                        <label htmlFor={'uploadJudgmentTranslation'}>
                            <FormFooterButton color={buttonColor.blue} icon={faFileUpload}>
                                {`Upload File`}
                            </FormFooterButton>
                        </label>
                    </div>
                </div>
            </div>
        );
    };

    const getFormTitle = () => {
        if (caseId > 0) {
            return parties ? `: ${parties}` : '';
        } else {
            return ': Create new case';
        }
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {modalScreen === modalScreenTypes.ERROR && customError ? (
                <div className={styles.caseFormErrorModal}>
                    <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
                </div>
            ) : null}
            {modalScreen === modalScreenTypes.CONFIRM_DELETE ? (
                <ModalConfirmVersion2
                    title={`Delete Case`}
                    message={`Are you sure you want to delete this case?`}
                    buttonName={'Delete'}
                    action={onDeleteCase}
                    close={closeModal}
                />
            ) : null}
            {modalScreen === modalScreenTypes.MANAGE_KEYWORDS ? (
                <ModalKeywordsList
                    intl={props.intl}
                    close={closeModal}
                    timestamp={timestamp}
                    keywordsList={keywordsList}
                    getKeywordsList={getKeywordsList}
                />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`PVR Case Law ${getFormTitle()}`} />
            <NavigationPVRCaseLaw />
            <MainWrapper>
                <div
                    style={{
                        minHeight: 600,
                        textAlign: 'center',
                        maxWidth: '100%',
                    }}
                >
                    <FormWrapper paddingFormContent={'sm'}>
                        {userRoles.indexOf('WLAW') === -1 ? (
                            <div style={{paddingTop: 20}}>
                                <b style={{color: 'red'}}>
                                    <span className="ng-scope">{`You have no permissions to access this page.`}</span>
                                </b>
                            </div>
                        ) : (
                            <></>
                        )}
                        {userRoles.indexOf('WLAW') !== -1 && screenLoaded ? (
                            <>
                                <div className={styles.formHeader}>
                                    <div style={{float: 'left'}}>
                                        <FormFooterButton
                                            color={buttonColor.whiteGray}
                                            clickAction={() => props.history.push('/pvrcaselawSearchCasesBasicSearch')}
                                            icon={faArrowLeft}
                                        >
                                            {`Go back to search`}
                                        </FormFooterButton>
                                    </div>
                                    {caseId > 0 ? (
                                        <FormFooterButton
                                            color={buttonColor.green}
                                            clickAction={onCreateNewCase}
                                            icon={faPlus}
                                        >
                                            {`Create new case`}
                                        </FormFooterButton>
                                    ) : null}
                                    <FormFooterButton
                                        color={buttonColor.blue}
                                        clickAction={openManageKeywordsPage}
                                        icon={faPencilAlt}
                                    >
                                        {`Manage Keywords`}
                                    </FormFooterButton>
                                    <div style={{clear: 'both'}} />
                                </div>
                                <div className={styles.caseFormItemsOuter}>
                                    <TextLabelInput
                                        double={true}
                                        placeholder={'please use autocompletion'}
                                        onSelectionChange={onKeywordIdsSelectionChange}
                                        onChange={onKeywordIdsChange}
                                        value={keywordsSearchTerm}
                                        selectedElements={selectedKeywords}
                                        delay={300}
                                        label={`Subject matter (keywords)`}
                                        listByIdName={keywordsListByIdNameSuggestions}
                                        nameHash={keywordsNameHashSuggestions}
                                        noInfo={true}
                                        useAsMultipleSelection={true}
                                        isOnModal={true}
                                        listMaxHeight={370}
                                        removeStyleClear={true}
                                        maxElementsInList={10000}
                                        timestamp={keywordsTimestamp}
                                        showFullListAfterSelectingElement={true}
                                    />
                                    <TextInput
                                        value={decisionNumber}
                                        label={'Decision Number'}
                                        disabled={false}
                                        onChange={onDecisionNumberChange}
                                        placeholder={`Please type a decision number`}
                                    />
                                    <div style={{clear: 'both'}} />
                                    <SelectInput
                                        value={origin}
                                        onChange={onOriginChange}
                                        list={originOptions}
                                        label={'Origin'}
                                        notAll={true}
                                        disabled={false}
                                        mandatory={true}
                                    />
                                    <SelectInput
                                        value={decisionLanguage}
                                        onChange={onDecisionLanguageChange}
                                        list={decisionLanguageOptions}
                                        label={'Language of the Decision'}
                                        notAll={true}
                                        disabled={false}
                                        mandatory={true}
                                    />
                                    <TextInput
                                        value={publicationNumber}
                                        label={'Publication Number'}
                                        disabled={false}
                                        onChange={onPublicationNumberChange}
                                        placeholder={`Please type a publication number`}
                                    />
                                    <TextInput
                                        value={caseName}
                                        label={'Case name (nickname)'}
                                        disabled={false}
                                        onChange={onCaseNameChange}
                                        double={true}
                                        placeholder={`Please type a case name (nickname)`}
                                    />
                                    <DateInput
                                        changeDateFrom={onDecisionDateChange}
                                        inputValueFrom={formatDate(decisionDate)}
                                        label={'Decision Date'}
                                        simple={true}
                                    />
                                    <TextInput
                                        value={decidingBody}
                                        label={'Deciding Body'}
                                        disabled={false}
                                        onChange={onDecidingBodyChange}
                                        double={true}
                                        placeholder={`Please type a deciding body`}
                                        mandatory={true}
                                    />
                                    <DateInput
                                        changeDateFrom={onPublicationDateChange}
                                        inputValueFrom={formatDate(publicationDate)}
                                        label={'Publication Date'}
                                        simple={true}
                                    />
                                    <div style={{clear: 'both'}} />
                                    <TextInput
                                        value={parties}
                                        label={'Parties'}
                                        disabled={false}
                                        onChange={onPartiesChange}
                                        double={true}
                                        placeholder={`Please type some parties`}
                                        mandatory={true}
                                        triple={true}
                                    />
                                    <div style={{clear: 'both'}} />
                                    <TextAreaInput
                                        value={abstractInput}
                                        label={`Abstract`}
                                        double={true}
                                        rows={7}
                                        onChange={onAbstractChange}
                                        maxCounter={maxCounter}
                                        counter={abstractCounter}
                                        width={`calc(100% - 15px)`}
                                    />
                                    <Checkbox
                                        clickAction={onCheckIsOnline}
                                        label={`Online`}
                                        value={isOnline}
                                        double={true}
                                    />
                                </div>
                                <div style={{clear: 'both'}} />
                                {getUploadSummaryDocArea()}
                                <div style={{clear: 'both'}} />
                                <FormFooter
                                    right={<Button clickAction={onSaveCase}>{`Save`}</Button>}
                                    left={
                                        <Button
                                            clickAction={handlerDelete}
                                            variation={'danger'}
                                        >{`Delete case`}</Button>
                                    }
                                />
                                <div style={{clear: 'both'}} />
                            </>
                        ) : null}
                    </FormWrapper>
                </div>
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(CaseFormPagePVRCASELAW));
