import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {} from '@fortawesome/free-regular-svg-icons';
import {faPencilAlt, faEnvelope} from '@fortawesome/free-solid-svg-icons';

function renderButton(popupContent, handler, icon) {
    return {
        title: popupContent,
        icon: () => (
            <a data-content={popupContent}>
                <FontAwesomeIcon icon={icon} />
            </a>
        ),
        handler,
    };
}

export default function MyPVRAdminProceedingsActions(openModalEdit, openModalComposeMessage) {
    const buttons = [];
    buttons.push(
        renderButton('Compose message', (labelId, rowObject) => openModalComposeMessage(rowObject), faEnvelope)
    );
    buttons.push(renderButton('Edit', (labelId, rowObject) => openModalEdit(rowObject), faPencilAlt));
    return buttons;
}
