import React from 'react';
import cx from 'classnames';
import CustomPopoverInput from './CustomPopoverInput';
import stylesNew from './TextLabelInput.module.scss';
import stylesOld from './TextLabelInputOld.module.scss';
import InputContainer from '~componentsForm/InputContainer';
import FirstRow from '~componentsForm/FirstRow';
import SecondRow from '~componentsForm/SecondRow';
import FirstRowLabel from '~componentsForm/FirstRowLabel';
import {faChevronDown, faChevronUp, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Label from './Label';
import Error from './Error';
import Remark from './Remark';
import {htmlToText, isTextHTML} from '../utils';
import ClickOutside from './ClickOutside';

const filterLabelHash = {
    contains: 'Contains',
    starts: 'Starts with',
    equals: 'Equals',
    ends: 'Ends with',
};

const FIRST_LIST_ELEMENT = 0;
const MAX_ELEMENTS_IN_LIST = 20;

let styles;
export default class TextLabelInput extends React.Component {
    channel = null;
    elInput = null;
    flagDeleted = false;
    filterLabelHash = filterLabelHash;

    constructor(props) {
        super(props);
        this.state = {
            inputSearchListValue: '',
            resultLabel: '',
            searchList: [],
            highlighted: FIRST_LIST_ELEMENT,
            timestamp: null,
            error: null,
        };
        styles = props.oldBackOfficeStyles ? stylesOld : stylesNew;
    }

    componentDidUpdate(prevProps, prevSate) {
        prevProps.focus !== this.props.focus && this.elInput && this.elInput.focus();
        if (prevProps.timestamp !== this.props.timestamp) {
            this.setState({timestamp: this.props.timestamp});
            this.searchByName(this.props.value, this.channel);
        }
        if (
            Object.keys(this.props.selectedElements || {}).length !== 1 &&
            this.props.uniqueValue &&
            this.elInput &&
            this.flagDeleted
        ) {
            this.flagDeleted = false;
            this.elInput.focus();
        }
        if (prevProps.extraFilterLabelHash !== this.props.extraFilterLabelHash) {
            this.filterLabelHash = Object.assign({}, filterLabelHash, this.props.extraFilterLabelHash || {});
        }
    }

    clearList = callback => {
        this.channel = Date.now();
        this.setState({resultLabel: '', searchList: [], inputSearchListValue: ''}, callback);
    };

    checkAutoSelection = () => {
        const {searchList} = this.state;
        const {autoSelection, onIdChange} = this.props;
        autoSelection && searchList.length === 1 && onIdChange(searchList[0].ID);
    };

    searchByName = (nameCriteria, channel) => {
        const maxElementsInList = this.props?.maxElementsInList || MAX_ELEMENTS_IN_LIST;
        if (channel === this.channel && this.props.listByIdName && this.props.value !== '') {
            if (nameCriteria === '') {
                this.clearList && this.clearList();
            } else {
                this.setState(prev => {
                    let filteredData;
                    if (this.props.filterContainsKeepJSONResponseSortOrder) {
                        filteredData = [...this.props.listByIdName];
                    } else {
                        if (this.props.filterContains || this.props.currentFilter === 'contains') {
                            filteredData = this.props.listByIdName.filter(
                                i =>
                                    i.NAME.toUpperCase().indexOf(nameCriteria.toUpperCase()) !== -1 &&
                                    (!this.props.selectedElements || !this.props.selectedElements[i.ID])
                            );
                        } else {
                            filteredData = this.props.listByIdName.filter(
                                i =>
                                    i.NAME.toUpperCase().startsWith(nameCriteria.toUpperCase()) &&
                                    (!this.props.selectedElements || !this.props.selectedElements[i.ID])
                            );
                        }
                    }
                    let resultLabel = '';
                    if (nameCriteria !== '') {
                        if (filteredData.length === 1) {
                            resultLabel = 'Exactly one match';
                        } else if (filteredData.length < maxElementsInList) {
                            resultLabel = `${filteredData.length} matches`;
                        } else {
                            resultLabel = `${maxElementsInList} or more matches${
                                this.props.messageMoreMatchesPostFix || ''
                            }`;
                        }
                    }
                    return {
                        resultLabel,
                        searchList: this.props.filterContainsKeepJSONResponseSortOrder
                            ? filteredData
                            : filteredData
                                  .sort((a, b) => {
                                      if (a.NAME > b.NAME) return 1;
                                      else if (a.NAME < b.NAME) return -1;
                                      else return 0;
                                  })
                                  .slice(0, maxElementsInList),
                        highlighted: FIRST_LIST_ELEMENT,
                    };
                });
            }
        }
        if (this.props.showFullListAfterSelectingElement && this.props.value === '') {
            this.setState({searchList: this.props.listByIdName});
            setTimeout(() => {
                this.elInput && this.elInput.focus();
            }, 100);
        }
    };

    calculateScore = ({NAME}) => {
        const searchTerm = this.props.value;
        try {
            const regexStartsWithTerm = new RegExp(`^${searchTerm}`, 'i');
            const regexStartsWithTermAndSpace = new RegExp(`^${searchTerm} `, 'i');
            const regexNonLatinBeforeTerm = new RegExp(`(\\(|\\)|([^(a-zA-Z)]))${searchTerm}`, 'i');
            const regexSpaceBeforeAndAfter = new RegExp(` ${searchTerm} `, 'i');
            const regexSpaceBeforeEnd = new RegExp(` ${searchTerm}$`, 'i');
            const regexNonLatinBeforeAndNonLatinAfter = new RegExp(
                `(\(|\)|[^(a-zA-Z)])${searchTerm}(\(|\)|[^(a-zA-Z)])`, // eslint-disable-line
                'i'
            );
            const regexNonLatinBeforeTheEnd = new RegExp(`(\\(|\\)|[^(a-zA-Z)])${searchTerm}$`, 'i'); // eslint-disable-line
            if (regexStartsWithTerm.test(NAME)) {
                return 1;
            }
            if (regexStartsWithTermAndSpace.test(NAME)) {
                return 0.95;
            }
            if (regexNonLatinBeforeTerm.test(NAME)) {
                return 0.9;
            }
            if (regexSpaceBeforeAndAfter.test(NAME)) {
                return 0.8;
            }
            if (regexSpaceBeforeEnd.test(NAME)) {
                return 0.7;
            }
            if (regexNonLatinBeforeAndNonLatinAfter.test(NAME)) {
                return 0.6;
            }
            if (regexNonLatinBeforeTheEnd.test(NAME)) {
                return 0.5;
            }
        } catch (error) {
            return 0;
        }
        return 0;
    };

    toggleList = () => {
        const remainingElements = this.props.listByIdName.filter(i => !this.props.selectedElements[i.ID]);
        if (Object.keys(remainingElements).length > 0 && this.state.searchList.length === 0) {
            this.setState({searchList: remainingElements});
        } else {
            this.setState({searchList: []});
        }
    };

    clickOutside = e => {
        if (
            e &&
            e.target &&
            e.target.className &&
            e.target.className.includes &&
            e.target.className.includes('TextLabelInput_listElementLink_')
        ) {
            e.preventDefault();
        } else {
            this.clearList();
        }
    };

    onNameChange = event => {
        const nameCriteria = event.target.value;
        this.props.onChange(nameCriteria, null, null);
        this.props.onIdChange && this.props.onIdChange(null);
        this.channel = Date.now();
        setTimeout(
            () => {
                this.searchByName(nameCriteria, this.channel);
            },
            nameCriteria === '' ? 1 : this.props.delay || 1
        );
    };

    onElementListMouseOver = index => {
        this.setState({
            highlighted: index,
        });
    };

    onElementListClick = index => {
        const name = this.state.searchList[index].NAME;
        const id = this.state.searchList[index].ID;
        const object = this.state.searchList[this.state.highlighted];
        let selectedElements = this.props.uniqueValue ? {} : this.props.selectedElements;
        if (this.props.useAsMultipleSelection && selectedElements[id]) {
            this.flagDeleted = true;
            if (this.props.selectedElements && this.props.onSelectionChange) {
                delete selectedElements[id];
                !this.props.hasSelectAllOption && this.props.onSelectionChange(selectedElements);
            }
        } else {
            if (selectedElements) {
                id.split(',').forEach(id => {
                    selectedElements[id] = name;
                });
            }
        }
        if (!this.props.useAsMultipleSelection) {
            this.clearList &&
                this.clearList(() => {
                    this.props.onChange &&
                        this.props.onChange(this.props.useAsMultipleSelection ? '' : name, id, object);
                    this.props.onIdChange && this.props.onIdChange(id);
                    this.props.onSelectionChange && this.props.onSelectionChange(selectedElements);
                });
        } else {
            this.props.onChange && this.props.onChange(this.props.useAsMultipleSelection ? '' : name, id, object);
            this.props.onIdChange && this.props.onIdChange(id);
            this.props.onSelectionChange && this.props.onSelectionChange(selectedElements);
        }
        this.elInput && this.elInput.focus();
        this.setState({error: false});
    };

    onInputKeyDown = event => {
        LOG`event.key: ${event.key}`;
        if (event.key === 'Enter') {
            if (this.props.onEnterOverride) {
                this.props.onEnterOverride();
                this.clearList();
            } else {
                if (this.state.searchList[this.state.highlighted]) {
                    const name = this.state.searchList[this.state.highlighted].NAME;
                    const id = this.state.searchList[this.state.highlighted].ID;
                    const object = this.state.searchList[this.state.highlighted];
                    const selectedElements = this.props.uniqueValue ? {} : this.props.selectedElements;
                    if (this.props.useAsMultipleSelection && selectedElements[id]) {
                        this.flagDeleted = true;
                        if (this.props.selectedElements && this.props.onSelectionChange) {
                            delete selectedElements[id];
                            this.props.onSelectionChange(selectedElements);
                        }
                    } else {
                        if (selectedElements) {
                            id.split(',').forEach(id => {
                                selectedElements[id] = name;
                            });
                        }
                    }
                    this.clearList &&
                        this.clearList(() => {
                            this.props.onChange(this.props.useAsMultipleSelection ? '' : name, id, object);
                            this.props.onIdChange && this.props.onIdChange(id);
                            this.props.onSelectionChange && this.props.onSelectionChange(selectedElements);
                        });
                    this.elInput && this.elInput.focus();
                } else if (this.props.onEnter) {
                    this.props.onEnter();
                }
            }
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            if (this.state.searchList.length > 0 && this.state.highlighted > 0) {
                this.setState(prev => ({highlighted: --prev.highlighted}));
            }
        } else if (event.key === 'ArrowDown') {
            event.preventDefault();
            if (this.state.highlighted < this.state.searchList.length - 1) {
                this.setState(prev => ({highlighted: ++prev.highlighted}));
            }
        } else if (event.key === 'Escape') {
            this.clearList && this.clearList();
        }
    };

    onFocus = () => {
        const maxElementsInList = this.props?.maxElementsInList || MAX_ELEMENTS_IN_LIST;
        this.setState({
            searchList: this.props.filterContainsKeepJSONResponseSortOrder
                ? [...this.props.listByIdName]
                : this.props.listByIdName
                      .sort((a, b) => {
                          if (a.NAME > b.NAME) return 1;
                          else if (a.NAME < b.NAME) return -1;
                          else return 0;
                      })
                      .slice(0, maxElementsInList),
            highlighted: FIRST_LIST_ELEMENT,
        });
    };

    onRemoveElement = (event, elementName) => {
        event.stopPropagation();
        this.flagDeleted = true;
        if (this.props.selectedElements && this.props.onSelectionChange) {
            const selectedElements = Object.assign({}, this.props.selectedElements);
            if (this.props.nameHash) {
                (this.props.nameHash[elementName] || []).forEach(elementId => delete selectedElements[elementId]);
            } else if (selectedElements[elementName]) {
                delete selectedElements[elementName];
            }
            this.props.onSelectionChange(selectedElements);
            this.setState({searchList: []});
        }
    };

    onFilterChange = event => {
        this.elInput && this.elInput.focus();
        this.props.onFilterChange && this.props.onFilterChange(event);
    };

    onBlur = () => {
        const {searchList} = this.state;
        const {autoSelection, onIdChange} = this.props;
        if (autoSelection && searchList.length === 1 && onIdChange) {
            onIdChange(searchList[0].ID, this.props.onBlur);
        } else {
            this.props.onBlur && this.props.onBlur();
        }
        this.clearList();
    };

    onBlurContainer = () => alert() || this.toggleList();

    onInputSearchListChange = ({target: {value: inputSearchListValue}}) => this.setState({inputSearchListValue});

    render() {
        const renderedLabel = {};

        return (
            <React.Fragment>
                {(this.props.outsideLabelWithHTML || this.props.outsideLabelWithHTML === '') && (
                    <div className={cx(styles.label, this.props.isTranslationMissing && styles.translationMissing)}>
                        <Label width={this.props.outsideLabelWidth}>{this.props.outsideLabelWithHTML}</Label>
                    </div>
                )}
                {(this.props.outsideLabel || this.props.outsideLabel === '') && (
                    <div className={styles.label}>
                        <Label width={this.props.outsideLabelWidth}>{this.props.outsideLabel}</Label>
                    </div>
                )}
                <InputContainer
                    double={this.props.double}
                    triple={this.props.triple}
                    size={this.props.size}
                    half={this.props.half}
                >
                    <FirstRow>
                        {this.props.outsideLabel && (
                            <FirstRowLabel mandatory={this.props.mandatory} outsideLabel={this.props.outsideLabel}>
                                {this.props.outsideLabel}
                            </FirstRowLabel>
                        )}
                        {this.props.label && (
                            <FirstRowLabel
                                mandatory={this.props.mandatory}
                                popOverTextFirstRow={this.props.popOverTextFirstRow}
                                onButtonAddFromListClick={this.props.onButtonAddFromListClick}
                            >
                                {this.props.label}
                            </FirstRowLabel>
                        )}
                        <React.Fragment>{this.props.children}</React.Fragment>
                    </FirstRow>
                    <ClickOutside onClickOutside={this.props.useAsMultipleSelection ? this.clickOutside : undefined}>
                        <div
                            onClick={() =>
                                (this.props.clickable || this.props.clickableAndEditable) && this.toggleList()
                            }
                            style={this.props.clickable && {cursor: 'pointer'}}
                        >
                            <SecondRow
                                buttonIcon={!this.props.noInfo}
                                errorBorderColor={this.props.errorVarietyFinder}
                                table={true}
                                oldBackOfficeStyles={this.props.oldBackOfficeStyles}
                                disabled={this.props.disabled}
                                style={{position: 'relative'}}
                            >
                                {!this.props.selectedLabelsAsInputText ? (
                                    <div className={styles.selectedListContainer}>
                                        <ul className={styles.selectedList}>
                                            {Object.keys(this.props.selectedElements || {}).map(itemKey => {
                                                if (
                                                    this.props.selectedElements[itemKey] &&
                                                    this.props.selectedElements[itemKey] !== '' &&
                                                    !renderedLabel[this.props.selectedElements[itemKey]]
                                                ) {
                                                    renderedLabel[this.props.selectedElements[itemKey]] = true;
                                                    LOG`${this.props.selectedElements[itemKey]}`;
                                                    return (
                                                        <li
                                                            key={itemKey}
                                                            className={cx(
                                                                styles.selectedListItem,
                                                                this.props.noInfo && styles.selectedListItemNoInfo,
                                                                this.props.triple && styles.selectedListItemTriple
                                                            )}
                                                            title={htmlToText(this.props.selectedElements[itemKey])}
                                                        >
                                                            <div
                                                                className={cx(
                                                                    styles.selectedListItemLabel,
                                                                    (this.props.hideRemoveElementButton ||
                                                                        `${this.props.hideRemoveElementButtonForId}` ===
                                                                            `${itemKey}`) &&
                                                                        styles.hideRemoveElementButton
                                                                )}
                                                            >
                                                                {htmlToText(this.props.selectedElements[itemKey])}
                                                            </div>
                                                            {!this.props.hideRemoveElementButton &&
                                                                (!this.props.hideRemoveElementButtonForId ||
                                                                    `${this.props.hideRemoveElementButtonForId}` !==
                                                                        `${itemKey}`) && (
                                                                    <div
                                                                        className={styles.selectedListItemIcon}
                                                                        onClick={event =>
                                                                            this.onRemoveElement(
                                                                                event,
                                                                                this.props.selectedElements[itemKey]
                                                                            )
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon icon={faTimes} />
                                                                    </div>
                                                                )}
                                                            <div style={{clear: 'both'}}>{}</div>
                                                        </li>
                                                    );
                                                }
                                            })}
                                        </ul>
                                        <div style={{clear: 'both'}}>{}</div>
                                    </div>
                                ) : null}
                                <div style={{width: '100%', display: 'table', overflow: 'hidden'}}>
                                    <div style={{width: '100%', display: 'table-row'}}>
                                        {this.props.filter ? (
                                            <React.Fragment>
                                                {this.props.filter === true || Array.isArray(this.props.filter) ? (
                                                    <select
                                                        className={styles.select}
                                                        onChange={this.onFilterChange}
                                                        value={this.props.currentFilter}
                                                    >
                                                        {(Array.isArray(this.props.filter)
                                                            ? this.props.filter
                                                            : ['contains', 'starts', 'equals', 'ends']
                                                        ).map(
                                                            key =>
                                                                this.filterLabelHash[key] && (
                                                                    <option value={key}>
                                                                        {this.filterLabelHash[key]}
                                                                    </option>
                                                                )
                                                        )}
                                                    </select>
                                                ) : (
                                                    <div
                                                        onClick={() => this.elInput && this.elInput.focus()}
                                                        className={styles.tag}
                                                    >
                                                        {this.props.filter}
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ) : null}
                                        {(this.props.uniqueValue &&
                                            Object.keys(this.props.selectedElements || {}).length === 1) ||
                                        this.props.selectable ||
                                        this.props.disabled ? null : (
                                            <>
                                                <input
                                                    ref={el => (this.elInput = el)}
                                                    type="text"
                                                    placeholder={this.props.placeholder}
                                                    onChange={this.onNameChange}
                                                    value={
                                                        this.props.selectedLabelsAsInputText
                                                            ? Object.values(this.props.selectedElements).join(', ')
                                                            : this.props.value
                                                    }
                                                    onKeyDown={this.onInputKeyDown}
                                                    onFocus={
                                                        this.props.useAsMultipleSelection ? this.onFocus : undefined
                                                    }
                                                    onBlur={this.props.useAsMultipleSelection ? undefined : this.onBlur}
                                                    onClick={() =>
                                                        (this.props.clickable || this.props.clickableAndEditable) &&
                                                        this.toggleList()
                                                    }
                                                    autoComplete="nope"
                                                    className={cx(
                                                        styles.input,
                                                        !this.props.filter && styles.inputWithoutFilter,
                                                        this.props.selectedLabelsAsInputText &&
                                                            styles.inputWithLabelsAsInputText,
                                                        this.props.oldBackOfficeStylesError && styles.hasError
                                                    )}
                                                    placeHolder={this.props.placeHolder}
                                                    readonly={this.props.clickable ? 'readonly' : null}
                                                    style={this.props.clickable ? {cursor: 'pointer'} : {}}
                                                />
                                                {this.props.selectedLabelsAsInputText ? (
                                                    <FontAwesomeIcon
                                                        onClick={
                                                            this.props.useAsMultipleSelection
                                                                ? this.state.searchList.length > 0
                                                                    ? this.clickOutside
                                                                    : this.onFocus
                                                                : undefined
                                                        }
                                                        icon={
                                                            this.state.searchList.length > 0 &&
                                                            !this.props.forceHideSearchList
                                                                ? faChevronUp
                                                                : faChevronDown
                                                        }
                                                        className={styles.selectListToggleIcon}
                                                    />
                                                ) : null}
                                            </>
                                        )}
                                        {!this.props.hideCounter ? (
                                            <>
                                                {(this.props.filter || this.props.showCounter) &&
                                                    this.state.resultLabel && (
                                                        <div
                                                            className={styles.counter}
                                                            style={{border: 0, background: '#e8e8e8', cursor: 'text'}}
                                                            onClick={() => this.elInput && this.elInput.focus()}
                                                        >
                                                            {this.state.resultLabel}
                                                        </div>
                                                    )}
                                            </>
                                        ) : null}
                                        {this.state.searchList.length > 0 && !this.props.forceHideSearchList ? (
                                            <>
                                                <ul
                                                    className={cx(
                                                        styles.list,
                                                        this.props.listMaxHeight && styles.listHasMaxHeight,
                                                        this.props.listSticky && styles.listSticky
                                                    )}
                                                    aria-hidden="true"
                                                    style={{
                                                        top: 'unset',
                                                        left: 'unset',
                                                        display: 'block',
                                                        marginTop: 5,
                                                        maxHeight: this.props.listMaxHeight
                                                            ? this.props.listMaxHeight
                                                            : 'max-content',
                                                    }}
                                                >
                                                    {this.props.inputSearchList ? (
                                                        <input
                                                            type="text"
                                                            placeholder={`Search`}
                                                            id={`input-search-list`}
                                                            value={this.state.inputSearchListValue}
                                                            onChange={this.onInputSearchListChange}
                                                            className={styles.inputSearchList}
                                                        />
                                                    ) : null}
                                                    {this.state.searchList.map((element, index) => {
                                                        return (
                                                            <li
                                                                key={element.ID}
                                                                className={cx(
                                                                    styles.listElement,
                                                                    this.props.noInfo && styles.listElementNoInfo,
                                                                    this.props.triple && styles.listElementTriple
                                                                )}
                                                                onMouseEnter={() => this.onElementListMouseOver(index)}
                                                                onMouseDown={() => this.onElementListClick(index)}
                                                                role="option"
                                                                style={
                                                                    this.state.inputSearchListValue &&
                                                                    !element.NAME.toLowerCase().includes(
                                                                        this.state.inputSearchListValue.toLowerCase()
                                                                    )
                                                                        ? {display: 'none'}
                                                                        : element.style
                                                                }
                                                            >
                                                                {isTextHTML(element.NAME) ? (
                                                                    <a
                                                                        tabIndex="-1"
                                                                        title={htmlToText(element.NAME)}
                                                                        className={cx(
                                                                            styles.listElementLink,
                                                                            this.state.highlighted === index &&
                                                                                styles.listElementLinkActive
                                                                        )}
                                                                        style={element.style}
                                                                        /* eslint-disable-next-line react/no-danger */
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: (
                                                                                <>
                                                                                    {this.props
                                                                                        .useAsMultipleSelection && (
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className={cx(
                                                                                                styles.listElementLinkCheckbox,
                                                                                                this.state
                                                                                                    .highlighted ===
                                                                                                    index &&
                                                                                                    styles.listElementLinkCheckboxActive
                                                                                            )}
                                                                                            readOnly
                                                                                            checked={
                                                                                                Object.values(
                                                                                                    this.props
                                                                                                        .selectedElements
                                                                                                ).indexOf(
                                                                                                    element.NAME
                                                                                                ) > -1
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                    {element.NAME}
                                                                                </>
                                                                            ),
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <a
                                                                        tabIndex="-1"
                                                                        title={element.NAME}
                                                                        className={cx(
                                                                            styles.listElementLink,
                                                                            this.state.highlighted === index &&
                                                                                styles.listElementLinkActive
                                                                        )}
                                                                        style={element.style}
                                                                    >
                                                                        {this.props.useAsMultipleSelection && (
                                                                            <input
                                                                                type="checkbox"
                                                                                className={cx(
                                                                                    styles.listElementLinkCheckbox,
                                                                                    this.state.highlighted === index &&
                                                                                        styles.listElementLinkCheckboxActive
                                                                                )}
                                                                                readOnly
                                                                                checked={
                                                                                    Object.values(
                                                                                        this.props.selectedElements
                                                                                    ).indexOf(element.NAME) > -1
                                                                                }
                                                                            />
                                                                        )}
                                                                        {element.NAME}
                                                                    </a>
                                                                )}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </SecondRow>
                            {this.props.remark && (
                                <div>
                                    <Remark
                                        remark={this.props.remark}
                                        isRemarkTranslationMissing={this.props.isRemarkTranslationMissing}
                                    />
                                </div>
                            )}
                        </div>
                    </ClickOutside>
                    {this.props.popover && <CustomPopoverInput content={this.props.popover} />}
                    {this.props.buttonIcon && this.props.buttonIcon !== 'none' && (
                        <button
                            type="button"
                            className={cx(styles.button, this.props.buttonDisabled && styles.buttonDisabled)}
                            onClick={!this.props.buttonDisabled && this.props.buttonAction}
                        >
                            <FontAwesomeIcon
                                icon={this.props.buttonIcon}
                                color={this.props.buttonColor || this.props.buttonDisabled ? 'gray' : 'green'}
                            />
                        </button>
                    )}
                    {this.props.error && (
                        <div style={{marginRight: 32}} className={styles.errorWrap}>
                            {this.props.oldBackOfficeStylesError ? (
                                <Error wizardResponse={true}>{this.props.error}</Error>
                            ) : (
                                <Error>{this.props.error}</Error>
                            )}
                        </div>
                    )}
                    {this.props.errorVarietyFinder ? (
                        <div
                            style={{
                                marginLeft: 3,
                                marginTop: 3,
                                color: 'red',
                            }}
                        >
                            {this.props.errorVarietyFinder}
                        </div>
                    ) : null}
                </InputContainer>
                {this.props.removeStyleClear ? null : <div style={{clear: 'both'}}>{}</div>}
            </React.Fragment>
        );
    }
}
