import React from 'react';
import {DOCUMENT_TYPES} from '../UploadDocumentsArea/UploadDocumentsArea';
import {FORMAT_DATE} from '../../../../../../components/FormatFunctions';
import {CpvoBoxTableCriteriaData, CpvoBoxTableRowData} from '../../CpvoBoxPageService';
import CustomTable from '../../../../../../components/CustomTable';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {IntlShape} from 'react-intl';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import styles from './CpvoBoxPageTableArea.module.scss';

interface CpvoBoxPageTableAreaProps {
    intl: IntlShape;
    criteria: CpvoBoxTableCriteriaData;
    cpvoBoxFileList: Array<CpvoBoxTableRowData> | null;
    timestamp: number;
    count: number;
    showModalCpvoBox: () => void;
    updateCriteriaValue: (
        // eslint-disable-next-line no-undef
        criteriaValue: Partial<CpvoBoxTableCriteriaData>,
        callback?: () => void,
        refresh?: boolean
    ) => void;
}

export default function CpvoBoxPageTableArea(props: CpvoBoxPageTableAreaProps) {
    const RESULT_FIELDS_ALL = [
        'ackuid',
        'subject',
        'comments',
        'folder',
        'created',
        'fileName',
        'documentType',
        'registers',
        'invoiceDate',
        'invoiceNumber',
        'totalAmount',
        'vatAmount',
    ];
    const RESULT_FIELDS_DEFAULT = [
        'subject',
        'comments',
        'created',
        'fileName',
        'documentType',
        'registers',
        'invoiceDate',
        'invoiceNumber',
        'totalAmount',
        'vatAmount',
    ];

    const getTopCaptionElement = () => (
        <div className={styles.sendDocumentsButtonArea}>
            <div className={styles.sendDocumentsButtonArea}>
                <FormFooterButton color={buttonColor.blue} clickAction={props.showModalCpvoBox} icon={faPlus}>
                    {`Send Documents`}
                </FormFooterButton>
            </div>
        </div>
    );

    return (
        <>
            {getTopCaptionElement()}
            <CustomTable
                version={2}
                pageNumber={props.criteria.pageNumber}
                {...props}
                tableName={'cpvoBoxFileList'}
                tableType={'OBJECT'}
                tableSource={props.cpvoBoxFileList}
                timestamp={props.timestamp}
                id={'registers'}
                pagination={true}
                hideExcelButton={true}
                formatFunctions={{
                    created: FORMAT_DATE,
                    invoiceDate: FORMAT_DATE,
                    ackuid: (ackuid: string, _rowObject: CpvoBoxTableCriteriaData) => (
                        <div className={styles.ackuidCellArea}>{ackuid}</div>
                    ),
                    documentType: (documentType: string, _rowObject: CpvoBoxTableCriteriaData) => (
                        <div>
                            {DOCUMENT_TYPES.filter(el => el.id === documentType).length === 1 &&
                                DOCUMENT_TYPES.filter(el => el.id === documentType)[0].value}
                        </div>
                    ),
                }}
                resultFieldsAll={RESULT_FIELDS_ALL}
                resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                intl={props.intl}
                defaultOrder={props.criteria.order}
                reverseOrder={props.criteria.reverse}
                pageSize={props.criteria.pageSize}
                count={props.count}
                updateCriteriaValue={(criteriaValue: CpvoBoxTableCriteriaData) =>
                    props.updateCriteriaValue(criteriaValue)
                }
                showNoResultFound={false}
            />
        </>
    );
}
