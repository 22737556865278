import React from 'react';
import {COUNTRY_CODE_MAP_COUNTRY_NAME} from '../../utils/constants';
import {SELECT_INPUT_REGISTER_TYPE_OPTIONS} from '../VFRegisters/VFRegister.config';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {LoadingBar} from '../../componentsLayout';
import {ModalCustomVersion2} from '../../commonModals';
import {computeRegisterLastContribution, getRegistersQuery} from '../../utils/utilsVarietyFinder';
import graphQLClientInstance from '../../utils/axiosGraphQLClient';
import {injectIntl} from 'react-intl';

let countryCodeMapCountryName: any = COUNTRY_CODE_MAP_COUNTRY_NAME;

interface IProps {
    close: any;
    countryId?: any;
    intl: any;
    publicationTypeId?: any;
    publicationExtraId?: any;
    responseRegister?: IStateRegister;
}

interface IStateRegister {
    addressformatted: string | null;
    all_contributions: {
        pub_date: string | null;
        pub_info: string | null;
        pub_number: string | null;
    }[];
    countryid: string | null;
    email: string | null;
    name: string | null;
    publicationname: string | null;
    publicationtypeid: string | null;
    remarks: string | null;
    websiteurl: string | null;
}

interface IState {
    loading: number;
    register: IStateRegister;
}

class VFModalRegister extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: 0,
            register: {
                addressformatted: '',
                all_contributions: [],
                countryid: '',
                email: '',
                name: '',
                publicationname: '',
                publicationtypeid: '',
                remarks: '',
                websiteurl: '',
            },
        };
    }

    componentDidMount() {
        if (this.props.responseRegister) {
            this.setState({
                register: this.props.responseRegister,
            });
        } else {
            this.loadJSONs();
        }
    }

    loadJSONs() {
        const values = {
            countryid: this.props.countryId,
            registerType: this.props.publicationTypeId,
            extraid: this.props.publicationExtraId,
        };
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                graphQLClientInstance.post('/graphql', getRegistersQuery(values, 1, 0)).then((JSONResponse: any) => {
                    if (
                        JSONResponse &&
                        JSONResponse.data &&
                        JSONResponse.data.data &&
                        JSONResponse.data.data.registersSearch &&
                        JSONResponse.data.data.registersSearch.data &&
                        JSONResponse.data.data.registersSearch.data.length
                    ) {
                        this.setState(prev => ({
                            loading: prev.loading - 1,
                            register: JSONResponse.data.data.registersSearch.data[0],
                        }));
                    }
                });
            }
        );
    }

    getRegisterTypeTitle = () => {
        let registerType = `(${this.state.register.publicationtypeid})`;
        const registerTypeTitle = SELECT_INPUT_REGISTER_TYPE_OPTIONS.find(
            option => option.id === (this.state.register.publicationtypeid || '').toLowerCase()
        );
        if (registerTypeTitle) {
            registerType = registerTypeTitle.value;
        }
        return registerType;
    };

    getCountry = () => {
        let country = this.state.register.countryid;
        if (countryCodeMapCountryName[this.state.register.countryid || '']) {
            country = countryCodeMapCountryName[this.state.register.countryid || ''];
        }
        return country;
    };

    render() {
        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={`Register`}
                body={
                    <div>
                        {this.state.loading !== 0 ? <LoadingBar /> : null}
                        {this.state.loading === 0 ? (
                            <>
                                <DataSheetFormFields
                                    label={'Register name'}
                                    data={this.state.register.publicationname}
                                    double={true}
                                />
                                <DataSheetFormFields
                                    label={'Register type'}
                                    data={this.getRegisterTypeTitle()}
                                    double={true}
                                />
                                <DataSheetFormFields label={'Country'} data={this.getCountry()} double={true} />
                                <DataSheetFormFields
                                    label={'Web site'}
                                    data={
                                        <a
                                            href={'//' + this.state.register.websiteurl}
                                            rel="noopener noreferrer"
                                            target={`_blank`}
                                        >
                                            {this.state.register.websiteurl}
                                        </a>
                                    }
                                    double={true}
                                />
                                <DataSheetFormFields
                                    label={'Last contribution date'}
                                    data={computeRegisterLastContribution(this.state.register.all_contributions)}
                                    double={true}
                                />
                                <DataSheetFormFields
                                    label={'Remarks'}
                                    data={this.state.register.remarks}
                                    double={true}
                                />
                                <DataSheetFormFields
                                    label={'Organization'}
                                    data={this.state.register.name}
                                    double={true}
                                />
                                <DataSheetFormFields
                                    label={'Address'}
                                    data={this.state.register.addressformatted}
                                    double={true}
                                />
                                <DataSheetFormFields
                                    label={'Contact'}
                                    data={
                                        <a
                                            href={'mailto:' + this.state.register.email}
                                            rel="noopener noreferrer"
                                            target={`_blank`}
                                        >
                                            {this.state.register.email}
                                        </a>
                                    }
                                    double={true}
                                />
                            </>
                        ) : null}
                        <div style={{clear: 'both'}} />
                    </div>
                }
                footer={<></>}
                plus2={true}
            />
        );
    }
}

export default injectIntl(VFModalRegister);
