import React from 'react';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {HeaderLoading} from '../../componentsLayout';
import WizardStepsAll from '../Wizard/WizardStepsAll';
import WizardWrapper, {IWizardState} from '../Wizard/WizardWrapper';
import {apiBackOfficeFormWithQuestions, apiCommonGetPDF, apiFormDatas} from './BackOfficePdfService';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons';
import {getGroupsIdsIterationsMap, getTableQuestionsKeysIterationsMap} from '../Wizard/utils';
import {injectIntl} from 'react-intl';
import {loadUrlParams} from '~utils';
import {saveAs} from 'file-saver';
import styles from './BackOfficePdf.module.scss';
import {trackPageView} from '../../utils';

interface IProps {
    intl: any;
    history: any;
}

interface IState extends IWizardState {
    assistedEntries: any;
    formCommonName: string | null;
    formId: number | null;
    formType: number | null;
    formLanguage: string;
    formVersionCode: any;
    formValidityBegin: string | null;
    loading: number;
    screenLoaded: boolean;
}

class BackOfficePdf extends WizardWrapper<IProps, IState> {
    urlParams = loadUrlParams();

    constructor(props: IProps) {
        super(props);
        if (!(window as any).elements) {
            (window as any).elements = [];
        }
        this.state = {
            ...this.state,
            assistedEntries: null,
            formCommonName: '',
            formId: null,
            formLanguage: '',
            formType: null,
            formVersionCode: null,
            formValidityBegin: '',
            loading: 0,
            screenLoaded: false,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'backOfficePdf'});
        this.loadJSONs();
    }

    loadJSONs() {
        this.loadDatas().then(() => this.loadForm());
    }

    loadDatas = () => {
        return new Promise(resolve => {
            const {formId} = this.urlParams || {};
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiFormDatas(formId).then(
                        jsonResponseDatas =>
                            jsonResponseDatas &&
                            jsonResponseDatas.datas &&
                            this.setState(
                                prev => ({
                                    ...prev,
                                    assistedEntries: jsonResponseDatas.datas,
                                    loading: prev.loading - 1,
                                }),
                                () => resolve([])
                            )
                    );
                }
            );
        });
    };

    loadForm = () => {
        const {formId} = this.urlParams || {};
        let formType = this.urlParams.formType || 'TQ';
        let formLanguage = this.urlParams.formLanguage || 'EN';
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiBackOfficeFormWithQuestions(formId, formType, formLanguage).then(JSONResponse => {
                    let tableQuestionsKeysIterationsMap = getTableQuestionsKeysIterationsMap(
                        JSONResponse.form.groupsQuestion,
                        []
                    );
                    const groupsIdsIterationsMap = getGroupsIdsIterationsMap(JSONResponse.form.groupsQuestion, []);
                    this.setState(prev => ({
                        ...prev,
                        name: JSONResponse.form.name.value,
                        formId: JSONResponse.form.id,
                        officeId: JSONResponse.form.office,
                        formType: JSONResponse.form.type,
                        formVersionCode: JSONResponse.form.versionCode,
                        formValidityBegin: JSONResponse.form.validityBegin,
                        formCommonName: JSONResponse.form.common && JSONResponse.form.common.value,
                        groupsQuestion: JSONResponse.form.groupsQuestion,
                        formLanguage,
                        loading: prev.loading - 1,
                        screenData: {
                            ...prev.screenData,
                            hash: Date.now(),
                            tableQuestionsKeysIterationsMap,
                            groupsIdsIterationsMap,
                            responsesClient: {},
                            showHidden: true,
                        },
                        screenLoaded: true,
                    }));
                });
            }
        );
    };

    onPrintPDF = () => {
        let formType = this.urlParams.formType || 'TQ';
        let formLanguage = this.urlParams.formLanguage || 'EN';
        let currentPageURL = window.location.href;
        if (currentPageURL.includes('localhost')) {
            currentPageURL = currentPageURL.replace('http://localhost:3080', 'https://dev.plantvarieties.eu');
        }
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiCommonGetPDF({
                    URL: currentPageURL,
                }).then((blob: Blob) => {
                    saveAs(blob, `${formType}_${formLanguage}.pdf`);
                    this.setState(prev => ({
                        loading: prev.loading - 1,
                    }));
                });
            }
        );
    };

    render() {
        return (
            <div>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <div className={styles.headerWrap}>
                    <div className={styles.headerWrapInner}>
                        <div className={styles.printButton}>
                            <FormFooterButton
                                color={buttonColor.whiteBlue}
                                clickAction={this.onPrintPDF}
                                icon={faFilePdf}
                                disabled={false}
                            >{`Download PDF`}</FormFooterButton>
                        </div>
                    </div>
                </div>
                {this.state.screenLoaded ? (
                    <WizardStepsAll
                        PDFVersion={1}
                        assistedEntries={this.state.assistedEntries}
                        currentStepNumber={this.state.currentStepNumber}
                        editable={false}
                        formId={this.state.formId}
                        formTitle={this.state.name}
                        formType={this.state.formType}
                        formVersionCode={this.state.formVersionCode}
                        formValidityBegin={this.state.formValidityBegin}
                        formCommonName={this.state.formCommonName}
                        formLanguage={this.state.formLanguage}
                        loadFormQuestionsJSONs={this.loadJSONs}
                        steps={this.state.groupsQuestion}
                        screenData={this.state.screenData}
                        updateScreenData={this.updateScreenData}
                        vc={this.urlParams.vc}
                    />
                ) : null}
            </div>
        );
    }
}

export default injectIntl(BackOfficePdf);
