import React from 'react';
import {injectIntl} from 'react-intl';
import {ModalCustomVersion2} from '../../../commonModals';
import TextInput from '~components/TextInput';
import SelectInput from '~components/SelectInput';
import {Editor} from '@tinymce/tinymce-react';
import {examplePVRTemplate} from './DATA/templatePreview';
import {Button} from '../../../componentsFormV2';

interface IProps {
    PVRTemplateId: number;
    close: any;
    history?: any;
    intl: any;
}

interface IState {
    editorValue: string;
    templateName: string;
}

class ModalBackOfficeTemplatePVRPreview extends React.Component<IProps, IState> {
    tinyConfig = {
        height: window.innerHeight - 280,
        inline: false,
        plugins:
            'table hr anchor code paste searchreplace image preview fullscreen textcolor link table responsivefilemanager',
        toolbar1:
            'bold italic underline strikethrough | bullist numlist | alignleft aligncenter alignright alignjustify | outdent indent | link unlink | code preview fullscreen | image table',
        toolbar2: 'cut copy paste pastetext searchreplace | fontsizeselect fontselect forecolor backcolor | undo redo ',
        statusbar: false,
        menubar: false,
        image_advtab: true,
        image_dimensions: false,
        subfolder: 'images/html',
        language: 'en',
        table_default_attributes: {
            border: '0',
        },
        // setup: editor => {
        //     editor.on('activate', e => this.activateEditor(editor, e));
        //     editor.on('change', e => this.changeEditor(editor, e));
        // },
        file_picker_types: 'image',
        file_browser_callback_types: 'image',
        image_prepend_url: 'IMAGES/HTML/', //'images/html/22',
        filemanager_crossdomain: true,
        external_filemanager_path: '/filemanager/',
        filemanager_title: 'Select a file',
        external_plugins: {
            filemanager: '/filemanager/plugin.min.js',
        },
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            editorValue: this.props.PVRTemplateId !== 0 ? examplePVRTemplate : '',
            templateName: this.props.PVRTemplateId !== 0 ? 'Template name 1' : '',
        };
    }

    onPVRTemplateNameInputChange = ({target: {value: templateName}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({templateName});

    onEditorChange = (contentHTML: string) => this.setState({editorValue: contentHTML});

    render() {
        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={`CPVO template`}
                body={
                    <div style={{paddingBottom: 30, height: 'calc(100vh - 200px)'}}>
                        <div>
                            <TextInput
                                label={`CPVO template name`}
                                onChange={this.onPVRTemplateNameInputChange}
                                value={this.state.templateName}
                                double={true}
                            />
                            <SelectInput
                                label={`Language`}
                                value={'en'}
                                onChange={() => null}
                                list={[
                                    {id: 'en', value: 'English'},
                                    {id: 'fr', value: 'French'},
                                ]}
                                notAll={true}
                            />
                        </div>
                        <div style={{clear: 'both'}} />
                        <div style={{marginLeft: 9}}>
                            <div>{`CPVO template content`}</div>
                            <Editor
                                id="textareaTranslation"
                                value={this.state.editorValue}
                                init={this.tinyConfig}
                                onEditorChange={this.onEditorChange}
                            />
                        </div>
                        <div style={{clear: 'both'}} />
                    </div>
                }
                footer={
                    <>
                        <Button clickAction={this.props.close} variation={'secondary'}>{`Close`}</Button>
                        <Button clickAction={this.props.close}>{`Save`}</Button>
                    </>
                }
            />
        );
    }
}

export default injectIntl(ModalBackOfficeTemplatePVRPreview);
