import {getFetch, API_SERVERLESS} from '../../../../../../../commonApi';
import {
    SpeciesNamesFetchData,
    CultivationTypesFetchData,
    FeeGroupsFetchData,
    SubmissionPlacesFetchData,
    TestingSitesFetchData,
    // EntrustmentForSave,
    QuantityQualityFetchData,
} from '../Interfaces/ModalEntrustmentInterfaces';

export function apiSpeciesAutocompletionFetch(searchTerm: string, exactCheck = false): Promise<SpeciesNamesFetchData> {
    return getFetch(
        `${API_SERVERLESS}/tlo/applications/autocomplete/speciename?term=${searchTerm}&exactCheck=${exactCheck}`,
        true
    );
}

export function apiCultivationTypesAutocompletionFetch(searchTerm: string): Promise<CultivationTypesFetchData> {
    return getFetch(`${API_SERVERLESS}/tlo/applications/autocomplete/cultivationtype?term=${searchTerm}`, true);
}

export function apiFeeGroupsFetch(): Promise<FeeGroupsFetchData> {
    return getFetch(`${API_SERVERLESS}/tlo/applications/commons/feegroups`, true);
}

export function apiSubmissionPlacesFetch(): Promise<SubmissionPlacesFetchData> {
    return getFetch(`${API_SERVERLESS}/tlo/applications/commons/submissionplaces`, true);
}

export function apiTestingSitesFetch(): Promise<TestingSitesFetchData> {
    return getFetch(`${API_SERVERLESS}/tlo/applications/commons/testingsites`, true);
}

export function apiQuantityQualityFetch(speciesExamId: number): Promise<QuantityQualityFetchData> {
    return getFetch(`${API_SERVERLESS}/tlo/applications/commons/quantityquality?speciesExamId=${speciesExamId}`, true);
}

// export function apiEntrustmentSaveAsDraft(jsonData: EntrustmentForSave) {
//     return postFetch(`${API_SERVERLESS}/tlo/applications/draftentrustment`, true, jsonData).then(jsonResponse => {
//         if (jsonResponse) {
//             return jsonResponse;
//         }
//     });
// }
