import React from 'react';
import styles from './FormHeader.module.scss';

export default function ({number, title}: {number: number; title: string}) {
    return (
        <header className={styles.header}>
            <div className={styles.number}>{number}</div>
            <h2 className={styles.title}>{title}</h2>
        </header>
    );
}
