import {API_SERVERLESS, getFetch} from '../../../../commonApi';
import {TLOContactsListFetchData} from '../../../../types/TLO';

export function apiTloContactsListFetch(): Promise<TLOContactsListFetchData> {
    return getFetch(`${API_SERVERLESS}/tlo/applications/commons/contactlist`, true);
}

export function apiTloContactListLDownloadPDF(): Promise<TLOContactsListFetchData> {
    return getFetch(`${API_SERVERLESS}/tlo/applications/commons/contactlistpdf`, true);
}
