import React from 'react';

interface IIconBackProps {
    style?: any;
    className?: any;
    color?: string;
}

function Back({style: userStyle, color, className}: IIconBackProps) {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{...(userStyle || {})}}
            className={className}
        >
            <path
                d="M3.91667 1.33334L1 3.83334L3.91667 6.75001"
                stroke={color || '#009933'}
                strokeWidth="1.72549"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 3.83334H10.5808C13.4488 3.83334 15.8842 6.17501 15.9958 9.04168C16.1142 12.0708 13.6113 14.6667 10.5808 14.6667H3.49917"
                stroke={color || '#009933'}
                strokeWidth="1.72549"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Back;
