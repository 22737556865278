import {Link} from 'react-router-dom';
import linkedInIcon from '../img/icons/linkedin.svg';
import twitterIcon from '../img/icons/twitter.svg';
import youtubeIcon from '../img/icons/youtube.svg';
import styles from './Footer.module.scss';
import {Document as IconDocument, Question as IconQuestion, UserSpeak as IconUserSpeak} from '../iconComponents';
import ModalContactUsVersion2 from '../commonModals/ModalContactUsVersion2';
import React, {useRef, useState} from 'react';
import {getUserRoles} from '../screens/TLO/CommonFunctions/CommonFunctions';
import cx from 'classnames';
import {getExtraFooterLinksForVarietyFinder} from './Footer.utils';

interface IFooterProps {
    customSendToList?: {label: string; email: number}[];
    emailOffice?: string;
    extraLinks?: {title: string; pathname: string; anchor?: boolean}[];
    hostApplication?: 'Variety Finder' | 'Online Applications';
    headerTitle?: string;
    showContactUs?: any;
    subjectTag?: string;
    sendTo?: number;
    sticky?: boolean;
}

export default function ({
    emailOffice,
    extraLinks,
    hostApplication,
    headerTitle,
    showContactUs,
    sendTo,
    sticky,
}: IFooterProps) {
    const [isModalContactUsOpen, setIsModalContactUsOpen] = useState(false);
    const userRoles = useRef(getUserRoles());

    const onLinkContactUsClick = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (showContactUs) {
            showContactUs();
        } else {
            setIsModalContactUsOpen(true);
        }
    };

    const closeModalContactUs = () => setIsModalContactUsOpen(false);

    const getExtraLinksForHostApplication = () => {
        const extraLinksVarietyFinder = getExtraFooterLinksForVarietyFinder();
        if (hostApplication === 'Variety Finder') {
            if (!userRoles.current.includes('DPPRA') && !userRoles.current.includes('DPTUA')) {
                return [
                    ...extraLinksVarietyFinder,
                    {
                        title: 'Request Developer Program Access',
                        pathname: '/requestAPIAccess',
                    },
                ];
            }
            return extraLinksVarietyFinder;
        }
        return [];
    };

    const extraLinksToRender = hostApplication ? getExtraLinksForHostApplication() : extraLinks;

    return (
        <>
            {isModalContactUsOpen ? (
                <ModalContactUsVersion2
                    close={closeModalContactUs}
                    emailOffice={emailOffice}
                    headerTitle={headerTitle}
                    sendTo={sendTo}
                />
            ) : null}
            <footer className={cx(styles.footer, sticky && styles.sticky)}>
                {extraLinksToRender?.map(({anchor, pathname, title}) =>
                    anchor ? (
                        <a
                            key={pathname}
                            className={styles.link}
                            href={pathname}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <span>{title}</span>
                        </a>
                    ) : (
                        <Link to={pathname} className={styles.link} key={pathname}>
                            <span>{title}</span>
                        </Link>
                    )
                )}
                {hostApplication !== 'Variety Finder' ? (
                    <a
                        className={styles.link}
                        href="https://online.plantvarieties.eu/assets/TC/TC_V4.0.pdf"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <IconDocument color={'white'} className={styles.linkIcon} />
                        <span>{`Terms and Conditions`}</span>
                    </a>
                ) : null}
                <a className={styles.link} onClick={onLinkContactUsClick}>
                    <IconUserSpeak color={'white'} className={styles.linkIcon} />
                    <span>{`Contact`}</span>
                </a>
                {hostApplication !== 'Variety Finder' ? (
                    <a
                        href={`https://cpvo.europa.eu/en/help-center/faq`}
                        rel="noopener noreferrer"
                        target={`_blank`}
                        className={styles.link}
                    >
                        <IconQuestion color={'white'} className={styles.linkIcon} />
                        <span>{`FAQ`}</span>
                    </a>
                ) : null}
                <div className={styles.socialLinksWrap}>
                    <a
                        href="https://www.linkedin.com/company/community-plant-variety-office/"
                        target={'_blank'}
                        className={styles.linkSocial}
                        rel="noopener noreferrer"
                    >
                        <img src={linkedInIcon} alt={'linkedIn'} />
                    </a>
                    <a
                        href="https://twitter.com/cpvotweets"
                        target={'_blank'}
                        className={styles.linkSocial}
                        rel="noopener noreferrer"
                    >
                        <img src={twitterIcon} alt={'twitter'} />
                    </a>
                    <a
                        href="https://www.youtube.com/channel/UCUUEHok3qqYzqU_jfWb2Wew"
                        target={'_blank'}
                        className={styles.linkSocial}
                        rel="noopener noreferrer"
                    >
                        <img src={youtubeIcon} alt={'youtube'} />
                    </a>
                </div>
            </footer>
        </>
    );
}
