import React from 'react';
import cx from 'classnames';
import styles from './ModalJSONMapping.module.scss';
import {ModalCustomVersion2} from '../../../../commonModals';
import {LoadingBar} from '../../../../componentsLayout';
import {apiBackOfficeGetFormJSONMappings} from './ModalJSONMappingService';
import CustomTable from '~components/CustomTable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGripLines, faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {faFolder} from '@fortawesome/free-regular-svg-icons';
import {Button} from '../../../../componentsFormV2';

export const rowType = {
    GROUP: 0,
    QUESTION: 1,
    RESPONSE: 2,
};
const nextRowType = {};
nextRowType[rowType.GROUP] = rowType.QUESTION;
nextRowType[rowType.QUESTION] = rowType.RESPONSE;

const rowTypeName = {};
rowTypeName[rowType.GROUP] = `Group`;
rowTypeName[rowType.QUESTION] = `Question`;
rowTypeName[rowType.RESPONSE] = `Response`;

const styleState = {
    1: styles.itemModified,
    2: styles.itemDeleted,
    3: styles.itemInserted,
};

export default class ModalUsedInVersion2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: null,
            form: null,
            resultRows: null,
            timestamp: null,
            inputValue: '',
            filterString: '',
        };
    }

    componentDidMount() {
        if (this.props.formId) {
            apiBackOfficeGetFormJSONMappings(this.props.formId).then(jsonData => {
                jsonData &&
                    jsonData.form &&
                    this.setState({form: jsonData.form}, () => {
                        this.processForms(this.state.form);
                    });
            });
        }
    }

    toggleFieldVisibility = uniqueId => this.processForms(this.state.form, uniqueId);

    processForms = (form, nodeToggleId) => {
        const resultRows = [];
        (form.groups || []).forEach(group => this.processNode(resultRows, group, rowType.GROUP, true, nodeToggleId));
        this.setState({resultRows, timestamp: Date.now()});
    };

    processNode = (rows, node, type, parentOpen, nodeToggleId, prefix) => {
        const uniqueId = `${prefix ? `${prefix}-` : ''}${node.isTemplate ? 'T' : ''}${node.id || node.templateId}`;
        node.open = nodeToggleId === uniqueId ? !node.open : node.open || false;
        if (parentOpen) {
            rows.push({
                id: node.id || node.templateId,
                isTemplate: node.isTemplate,
                uniqueId,
                order: node.order,
                name: node.name,
                type: node.type,
                jsonMapping: node.jsonMapping,
                rowType: type,
                iconOpen: node.open,
                children: (node.questions || node.responses || []).length,
                comparison: node.comparison,
            });
        }
        if (node.old) {
            const oldUniqueId = `${prefix ? `${prefix}-` : ''}${node.old.id}`;
            this.processNode(rows, node.old, type, parentOpen === false ? false : node.open, nodeToggleId, oldUniqueId);
        }
        const nextType = nextRowType[type];
        (node.questions || node.responses || []).forEach(child => {
            this.processNode(rows, child, nextType, parentOpen === false ? false : node.open, nodeToggleId, uniqueId);
        });
    };

    close = () => {
        this.props.close && this.props.close();
    };

    renderFieldName = (name, rowObject) => (
        <div
            onClick={() => this.toggleFieldVisibility(rowObject.uniqueId)}
            style={(rowObject.children && rowObject.children > 0 && {cursor: 'pointer'}) || null}
        >
            <a style={{paddingLeft: rowObject.rowType * 18}}>
                {(((rowObject.children && rowObject.children > 0) || rowObject.rowType === rowType.RESPONSE) && (
                    <FontAwesomeIcon
                        icon={rowObject.rowType === rowType.RESPONSE ? faFolder : rowObject.iconOpen ? faMinus : faPlus}
                    />
                )) || <FontAwesomeIcon icon={faGripLines} color={'white'} />}
            </a>
            <span className="indented tree-label ng-scope">
                <span className="ng-scope">
                    <span className={styleState[rowObject.comparison]}>
                        {` `}
                        <b style={{paddingRight: 3}}>{rowTypeName[rowObject.rowType]}</b>
                        {`${rowObject.order} . ${name} `}
                    </span>
                    <span className={cx(styles.badge, rowObject.isTemplate && styles.template)}>{rowObject.id}</span>
                    <span className={styles.mappingCode}>{rowObject.jsonMapping}</span>
                </span>
            </span>
        </div>
    );

    render() {
        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={
                    <>
                        {`JSON Mappings`}
                        <div style={{fontWeight: 'normal', fontSize: 22, width: '100%'}}>
                            {this.state.form && this.state.form.name}
                        </div>
                    </>
                }
                body={
                    <div
                        style={{
                            height: 510,
                            overflowY: 'auto',
                        }}
                    >
                        {this.state.resultRows ? (
                            <CustomTable
                                {...this.props}
                                hiddenHeader={true}
                                tableName={'formComparison'}
                                tableType={'OBJECT'}
                                tableSource={this.state.resultRows}
                                id={'name'}
                                resultFieldsDefault={['name']}
                                intl={this.props.intl}
                                hideExcelButton={true}
                                formatFunctions={{
                                    name: this.renderFieldName,
                                }}
                                timestamp={this.state.timestamp}
                                sortFunctions={{}}
                                notSortable={true}
                                unlimited={true}
                                topCaption={
                                    <div className={styles.captionContainer}>
                                        <div className={cx(styles.label, styles.labelGreen)}>{`Template`}</div>
                                        <div className={cx(styles.label, styles.labelBlue)}>{`Group Question`}</div>
                                    </div>
                                }
                            />
                        ) : (
                            <LoadingBar />
                        )}
                    </div>
                }
                footer={<Button clickAction={this.close} icon={'close'} variation={'danger'}>{`Close`}</Button>}
            />
        );
    }
}
