import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeShowQuestion = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p>
                        If the option <strong>Current Response</strong> is selected, then this response is deactivated
                        by default.
                    </p>
                    <p>
                        If the option <strong>Other Response</strong> is selected, then the target response will be
                        deactivated if this response is clicked (checkbox / multi-choice) or selected (radio /
                        single-choice).
                    </p>
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeShowQuestion;
