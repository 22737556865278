import React from 'react';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import CustomTable from '~components/CustomTable';
import DateInput from '~components/DateInput';
import {FORMAT_DATE_TIME_EASY, reformatDateEasy} from '~components/FormatFunctions';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import {getDecodedJWT} from '~utils';
import CCModalInternalHistory from '../CCModalInternalHistory/CCModalInternalHistory';
import CCModalInternalDraft from '../CCModalInternalDraft/CCModalInternalDraft';
import CCModalInternalCompose from '../CCModalInternalCompose/CCModalInternalCompose';
import CCInternalDraftActionButtons from './CCInternalDraftActionButtons';
import {
    apiCommunicationCentreInternalDraftClientsList,
    apiCommunicationCentreInternalDraftDelete,
    apiCommunicationCentreInternalDraftSearch,
} from './CCInternalDraftService';
import {
    CASEHOLDERS_SHORTKEYS,
    findDefaultValueForCaseHolder,
    findDefaultValueForDirectory,
    getSelectListOfDirectory,
} from '../CommunicationCentreInternal/CommunicationCentreInternal.utils';
import ApplicationNumbersMultiple from '../../components/ApplicationNumbersMultiple';
import {ModalApplicationNumbers, ModalConfirmVersion2} from '../../commonModals';
import {
    IClientsList,
    ICommunicationMessageInternal,
    ICommunicationMessagesInternalSearchResults,
    IDecodedJWT,
} from '../../types';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
} from '../../componentsLayout';
import NavigationCommunicationCentre from '../../shared/NavigationCommunicationCentre';
import {Button} from '../../componentsFormV2';
import MainWrapper from '../../componentsLayout/MainWrapper';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamation} from '@fortawesome/free-solid-svg-icons';
import {loadUrlParams, trackPageView} from '../../utils';

export const RESULT_FIELDS_DEFAULT_DRAFTS_CPVO = [
    'from',
    'messageSubject',
    'applicationNumbers',
    'addressees',
    'lastUpdateDate',
];

const DATEFORMAT_CRITERIA_KEYS = ['arrivalDateFrom', 'arrivalDateTo'];

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

type TKeyOfDefaultCriteria = keyof TDefaultCriteria;

const DEFAULT_CRITERIA = {
    from: '',
    pageNumber: 1,
    pageSize: 10,
    order: 'sentDate',
    reverse: true,
    refresh: false,
    excel: false,
    applicationNumber: '',
    directory: 'All',
    caseHolder: 'All',
    replyBy: 'All',
    cc: 'All',
    arrivalDateFrom: '',
    arrivalDateTo: '',
    readingStatus: 'All',
    sendingDateFrom: '',
    sendingDateTo: '',
    searchTerm: '',
};

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    clientsListByIdName: {
        ID: string;
        NAME: string;
    }[];
    clientsName: string;
    clientsNameHash: any;
    count: number;
    criteria: TDefaultCriteria;
    isModalCPVOLogsOpen: boolean;
    isModalDraftDeleteOpen: number | false;
    isModalHistoryOpen: number | false;
    loading: number;
    messages: ICommunicationMessageInternal[];
    modalApplicationNumbers: string[];
    modalInternalMessageCompose: boolean;
    modalInternalMessageDraft: number | false;
    modalInternalMessageRead: number | boolean;
    modalInternalMessageReply: number | boolean;
    selectedClients: {[key: string]: string};
    screenLoaded: boolean;
    timestamp: number;
}

class CCInternalDraft extends React.Component<IProps, IState> {
    decodedJWT: IDecodedJWT = getDecodedJWT();

    constructor(props: IProps) {
        super(props);
        this.state = {
            clientsListByIdName: [],
            clientsName: '',
            clientsNameHash: {},
            count: 0,
            criteria: Object.assign({}, DEFAULT_CRITERIA),
            isModalCPVOLogsOpen: false,
            isModalDraftDeleteOpen: false,
            isModalHistoryOpen: false,
            loading: 0,
            messages: [],
            modalApplicationNumbers: [],
            modalInternalMessageCompose: false,
            modalInternalMessageDraft: false,
            modalInternalMessageRead: false,
            modalInternalMessageReply: false,
            selectedClients: {},
            screenLoaded: false,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'communicationCentreInternalDraft'});
        const params = loadUrlParams(DATEFORMAT_CRITERIA_KEYS);
        const URLCriteria: any = {};
        Object.entries(params).forEach(([fieldName, fieldValue]) => {
            if (Object.keys(DEFAULT_CRITERIA).includes(fieldName)) {
                URLCriteria[fieldName] = fieldValue;
            }
        });
        this.loadJSONs();
        this.updateCriteriaValue(
            {
                directory: findDefaultValueForDirectory(this.decodedJWT),
                caseHolder: findDefaultValueForCaseHolder(this.decodedJWT),
                ...URLCriteria,
            },
            () => this.search(false, false, () => this.setState({screenLoaded: true}))
        );
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (prevState.criteria.directory !== this.state.criteria.directory) {
            if (parseInt(this.state.criteria.directory) !== 12 && this.state.criteria.caseHolder !== 'All') {
                this.updateCriteriaValue({caseHolder: 'All'});
            }
        }
    }

    loadJSONs = () => {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiCommunicationCentreInternalDraftClientsList()
                    .then((jsonResponse: IClientsList) => {
                        if (jsonResponse && jsonResponse.data) {
                            const nameHash: {[key: string]: number[]} = {};
                            (jsonResponse.data || []).forEach(speciesElement => {
                                if (!nameHash[speciesElement.NAME]) {
                                    nameHash[speciesElement.NAME] = [];
                                }
                                nameHash[speciesElement.NAME].push(speciesElement.ID);
                            });
                            const listByIdName = Object.keys(nameHash).map(name => ({
                                ID: nameHash[name].join(','),
                                NAME: name,
                            }));
                            const selectedClients: {[key: string]: string} = {};
                            const selectedSpeciesKeyArray = Object.keys(this.state.selectedClients || {});
                            listByIdName
                                .filter(i => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                                .forEach(item => (selectedClients[item.ID] = item.NAME));
                            this.setState({
                                clientsListByIdName: listByIdName,
                                clientsNameHash: nameHash,
                                selectedClients,
                                timestamp: Date.now(),
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`FormLabelInputSpecies: ${error.message}`]);
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    goToScreen = (screen: string): void =>
        this.setState({criteria: {...DEFAULT_CRITERIA}, selectedClients: {}}, () => {
            this.props.history.push(screen);
        });

    parseCriteria = (criteria: TDefaultCriteria): TDefaultCriteria => {
        const parsedCriteria: any = {};
        Object.keys(criteria).map(key => {
            let criteriaValue = criteria[key as TKeyOfDefaultCriteria];
            if (DATEFORMAT_CRITERIA_KEYS.indexOf(key) !== -1 && criteriaValue !== '') {
                parsedCriteria[key] = reformatDateEasy(criteriaValue);
            } else {
                parsedCriteria[key] = criteriaValue;
            }
        });
        return parsedCriteria;
    };

    buildParams = (parsedCriteria: TDefaultCriteria): void => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(
                i =>
                    parsedCriteria[i as TKeyOfDefaultCriteria] !== DEFAULT_CRITERIA[i as TKeyOfDefaultCriteria] &&
                    i !== 'refresh'
            )
            .map(key => `${key}=${parsedCriteria[key as TKeyOfDefaultCriteria]}`);
        this.props.history.replace(
            `/communicationCentreInternalDraft${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`
        );
    };

    closeModalDraftDelete = () => this.setState({isModalDraftDeleteOpen: false});

    search = (refresh?: boolean, urlLoad?: boolean, callBack?: () => any): any =>
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams(parsedCriteria);
                apiCommunicationCentreInternalDraftSearch(parsedCriteria, DEFAULT_CRITERIA)
                    .then((jsonResponse: ICommunicationMessagesInternalSearchResults) => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.messages) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        messages: jsonResponse.data.messages,
                                        timestamp: Date.now(),
                                    },
                                    jsonResponse.data.count && {count: jsonResponse.data.count},
                                    !refresh && {selectedMessages: {}}
                                );
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`Register search list error: ${error.message}`]);
                    })
                    .then(() => {
                        this.setState(prev => ({loading: prev.loading - 1}));
                        callBack && callBack();
                    });
            }
        );

    closeModalInternalMessageDraft = (refresh: boolean) =>
        this.setState({modalInternalMessageDraft: false}, () => refresh && this.search(true));

    updateCriteriaValue = (criteriaValue: Partial<TDefaultCriteria>, callback?: () => any, refresh?: boolean): void => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = prev.criteria.order !== criteriaValue.order;
                reverseChanged = prev.criteria.reverse !== criteriaValue.reverse;
                return {criteria};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    openModalInternalMessageCompose = () => this.setState({modalInternalMessageCompose: true});

    closeModalInternalMessageCompose = () =>
        this.setState({modalInternalMessageCompose: false}, () => this.search(true));

    closeModalInternalMessageHistory = () => this.setState({isModalHistoryOpen: false}, () => this.search(true));

    formatTableMessages = (messages: ICommunicationMessageInternal[]) =>
        [...(messages || [])].map(el => ({
            ...el,
            lastUpdateDate: el.sentDate,
            receivedDate: el.sentDate,
            to: el.from,
        }));

    getTableFormatFunctions = () => ({
        addressees: (addressees: string) => (addressees || '').split('\\n').join(', '),
        applicationNumbers: (applicationNumbers: string[]) => (
            <ApplicationNumbersMultiple
                applicationNumbers={applicationNumbers}
                openModalApplicationNumbers={this.openModalApplicationNumbers}
            />
        ),
        deemedService: FORMAT_DATE_TIME_EASY,
        firstOpened: FORMAT_DATE_TIME_EASY,
        lastUpdateDate: FORMAT_DATE_TIME_EASY,
        notificationDate: FORMAT_DATE_TIME_EASY,
        receivedDate: FORMAT_DATE_TIME_EASY,
        sentDate: FORMAT_DATE_TIME_EASY,
    });

    closeModalApplicationNumbers = () => this.setState({modalApplicationNumbers: []});

    openModalApplicationNumbers = (applicationNumbers: string[]) =>
        this.setState({modalApplicationNumbers: applicationNumbers});

    onTableRowClick = (communicationId: number): void => this.setState({modalInternalMessageDraft: communicationId});

    onModalInternalMessageReadButtonReplyClick = () =>
        this.setState(prev => ({
            modalInternalMessageRead: false,
            modalInternalMessageReply: prev.modalInternalMessageRead,
        }));

    onTextInputSearchTermChange = ({target: {value: searchTerm}}: React.ChangeEvent<HTMLInputElement>): void =>
        this.updateCriteriaValue({searchTerm});

    onTextInputValueApplicationNumberChange = ({
        target: {value: textInputValueApplicationNumber},
    }: React.ChangeEvent<HTMLInputElement>): void =>
        this.updateCriteriaValue({applicationNumber: textInputValueApplicationNumber});

    onSelectInputValueDirectoryChange = ({
        target: {value: selectInputValueDirectory},
    }: React.ChangeEvent<HTMLSelectElement>): void => this.updateCriteriaValue({directory: selectInputValueDirectory});

    onSelectInputValueCaseHolderChange = ({
        target: {value: selectInputValueCaseHolder},
    }: React.ChangeEvent<HTMLSelectElement>): void =>
        this.updateCriteriaValue({caseHolder: selectInputValueCaseHolder});

    onSelectInputValueCCChange = ({target: {value: selectInputValueCC}}: React.ChangeEvent<HTMLSelectElement>): void =>
        this.updateCriteriaValue({cc: selectInputValueCC});

    onDateInputValueArrivalDateFromChange = (dateInputValueArrivalDateFrom: string): void =>
        this.updateCriteriaValue({arrivalDateFrom: dateInputValueArrivalDateFrom});

    onDateInputValueArrivalDateToChange = (dateInputValueArrivalDateTo: string): void =>
        this.updateCriteriaValue({arrivalDateTo: dateInputValueArrivalDateTo});

    onSelectInputValueReadingStatusChange = ({
        target: {value: selectInputValueReadingStatus},
    }: React.ChangeEvent<HTMLSelectElement>): void =>
        this.updateCriteriaValue({readingStatus: selectInputValueReadingStatus});

    onClearFieldsClick = () =>
        this.setState(
            prev => ({
                selectedClients: {},
                criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
            }),
            () => this.search()
        );

    onTableIconHistoryClick = ({correlationId}: ICommunicationMessageInternal) =>
        this.setState({isModalHistoryOpen: correlationId});

    onTableIconDeleteClick = ({communicationId}: ICommunicationMessageInternal) =>
        this.setState({isModalDraftDeleteOpen: communicationId});

    onClientsSelectionChange = (selectedClients: {[key: string]: string}) =>
        this.setState({selectedClients, clientsName: ''}, () =>
            this.updateCriteriaValue({from: Object.keys(selectedClients || {}).join(',')})
        );

    onClientsNameChange = (clientsName: string): void => this.setState({clientsName});

    onModalButtonDraftDeleteActionClick = () => {
        const communicationIdToDelete = this.state.isModalDraftDeleteOpen || 0;
        this.setState(
            prev => ({loading: prev.loading + 1, isModalDraftDeleteOpen: false}),
            () =>
                apiCommunicationCentreInternalDraftDelete(communicationIdToDelete).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1}));
                    this.search(true);
                })
        );
    };

    render() {
        const actions = CCInternalDraftActionButtons(this.onTableIconHistoryClick, this.onTableIconDeleteClick);

        return (
            <>
                {this.state.isModalHistoryOpen ? (
                    <CCModalInternalHistory
                        close={this.closeModalInternalMessageHistory}
                        correlationId={this.state.isModalHistoryOpen}
                        formatTableMessages={this.formatTableMessages}
                        getTableFormatFunctions={this.getTableFormatFunctions}
                    />
                ) : null}
                {this.state.modalInternalMessageCompose && (
                    <CCModalInternalCompose close={this.closeModalInternalMessageCompose} intl={this.props.intl} />
                )}
                {this.state.modalInternalMessageDraft ? (
                    <CCModalInternalDraft
                        close={this.closeModalInternalMessageDraft}
                        communicationId={this.state.modalInternalMessageDraft}
                        intl={this.props.intl}
                    />
                ) : null}
                {this.state.modalApplicationNumbers.length ? (
                    <ModalApplicationNumbers
                        applicationNumbers={this.state.modalApplicationNumbers}
                        close={this.closeModalApplicationNumbers}
                    />
                ) : null}
                {this.state.isModalDraftDeleteOpen ? (
                    <ModalConfirmVersion2
                        title={`Delete draft`}
                        message={`Are you sure you want to delete this draft message ?`}
                        buttonName={'DELETE'}
                        action={this.onModalButtonDraftDeleteActionClick}
                        close={this.closeModalDraftDelete}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Communication Centre`} />
                <NavigationCommunicationCentre openModalMessageCompose={this.openModalInternalMessageCompose} />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div>
                            <TextInput
                                label={`Search term`}
                                onChange={this.onTextInputSearchTermChange}
                                onEnter={this.search}
                                placeholder={`Type a search term`}
                                value={this.state.criteria.searchTerm}
                            />
                            <TextInput
                                filter={'Starts with'}
                                placeholder={`e.g. 2015 or 20150001`}
                                label={`Application number(s)`}
                                onChange={this.onTextInputValueApplicationNumberChange}
                                value={this.state.criteria.applicationNumber}
                                onEnter={this.search}
                            />
                            <DateInput
                                label={`Arrival date`}
                                changeDateFrom={this.onDateInputValueArrivalDateFromChange}
                                changeDateTo={this.onDateInputValueArrivalDateToChange}
                                inputValueFrom={this.state.criteria.arrivalDateFrom}
                                inputValueTo={this.state.criteria.arrivalDateTo}
                                disabled={true}
                            />
                            <SelectInput
                                label={`Directory`}
                                value={this.state.criteria.directory}
                                onChange={this.onSelectInputValueDirectoryChange}
                                list={getSelectListOfDirectory(this.decodedJWT)}
                            />
                            <SelectInput
                                label={`Caseholder`}
                                value={this.state.criteria.caseHolder || findDefaultValueForCaseHolder(this.decodedJWT)}
                                onChange={this.onSelectInputValueCaseHolderChange}
                                list={CASEHOLDERS_SHORTKEYS.map(shortKey => ({
                                    id: shortKey,
                                    value: shortKey,
                                }))}
                                disabled={parseInt(this.state.criteria.directory) !== 12}
                            />
                            <SelectInput
                                label={`Reading Status`}
                                value={this.state.criteria.readingStatus}
                                onChange={this.onSelectInputValueReadingStatusChange}
                                list={[
                                    {id: '1', value: 'Read'},
                                    {id: '2', value: 'not read'},
                                ]}
                                disabled={true}
                            />
                            <SelectInput
                                label={`CC`}
                                value={this.state.criteria.cc}
                                onChange={this.onSelectInputValueCCChange}
                                list={[{id: '1', value: 'Show only CC messages'}]}
                                disabled={true}
                            />
                            <TextLabelInput
                                delay={300}
                                double={true}
                                disabled={true}
                                label={'From'}
                                listByIdName={this.state.clientsListByIdName}
                                nameHash={this.state.clientsNameHash}
                                onSelectionChange={this.onClientsSelectionChange}
                                onChange={this.onClientsNameChange}
                                outsideLabelWidth={160}
                                placeholder={`Type part of the users name`}
                                selectedElements={this.state.selectedClients}
                                uniqueValue={true}
                                noInfo={true}
                                value={this.state.clientsName}
                            />
                            {/*<SelectInput*/}
                            {/*    label={`Shared`}*/}
                            {/*    value={this.state.criteria.readingStatus}*/}
                            {/*    onChange={this.onSelectInputValueReadingStatusChange}*/}
                            {/*    notAll={true}*/}
                            {/*    list={[*/}
                            {/*        {id: '1', value: 'My drafts'},*/}
                            {/*        {id: '2', value: 'All'},*/}
                            {/*    ]}*/}
                            {/*/>*/}
                        </div>
                        <FormFooter>
                            <Button
                                variation={'secondary'}
                                clickAction={this.onClearFieldsClick}
                            >{`Clear fields`}</Button>
                            <Button clickAction={() => this.search(false)} icon={'arrowRight'}>{`Search`}</Button>
                        </FormFooter>
                        <div style={{clear: 'both'}} />
                    </FormWrapper>
                    <div style={{marginTop: 20, marginBottom: 20}}>
                        {this.state.screenLoaded ? (
                            <CustomTable
                                pagination={true}
                                mode={'mailbox'}
                                version={2}
                                timestamp={this.state.timestamp}
                                tableName={'communicationCentreInternalDraft'}
                                tableType={'OBJECT'}
                                tableSource={this.formatTableMessages(this.state.messages)}
                                dataFilter={null}
                                id={'communicationId'}
                                setLastCursor={null}
                                resultFieldsDefault={RESULT_FIELDS_DEFAULT_DRAFTS_CPVO}
                                forceUpdateResultFieldsDefault={true}
                                defaultOrder={this.state.criteria.order}
                                reverseOrder={this.state.criteria.reverse}
                                pageSize={this.state.criteria.pageSize}
                                intl={this.props.intl}
                                filterFunctions={null}
                                count={this.state.count}
                                hideExcelButton={true}
                                rowClick={this.onTableRowClick}
                                formatFunctions={this.getTableFormatFunctions()}
                                forehandColumn={({urgent}: any) =>
                                    urgent ? (
                                        <FontAwesomeIcon
                                            icon={faExclamation as any}
                                            fontSize={25}
                                            title={'Urgent'}
                                            color={'#810202'}
                                        />
                                    ) : null
                                }
                                actions={actions}
                                updateCriteriaValue={this.updateCriteriaValue}
                                pageNumber={this.state.criteria.pageNumber}
                                selectable={true}
                            />
                        ) : null}
                    </div>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(CCInternalDraft));
