import {API_SERVERLESS, getFetch, postFetch} from '../../../../commonApi';

export interface ReportingDeadlinesTableRowData {
    addressName: string;
    examContractId: number;
}

export interface ReportingDeadlinesParamsData {
    data: {
        deadlines: Array<ReportingDeadlinesTableRowData>;
        token: string;
    };
    count: number;
}

export interface ReportingDeadlinesTableCriteriaData {
    pageNumber: number;
    refresh: boolean;
    excel: boolean;

    [key: string]: string | number | boolean | undefined;
}

export function apiReportingDeadlinesSearch(
    // eslint-disable-next-line no-undef
    criteria: Partial<ReportingDeadlinesTableCriteriaData>
): Promise<ReportingDeadlinesParamsData> {
    const params = {...criteria};
    const requestParams = Object.keys(params).map(k => `${k}=${params[k]}`);
    return getFetch(`${API_SERVERLESS}/tlo/deadlines?${requestParams.join('&')}`, true);
}

export function apiReportingDeadlinesDownload(
    examContractId: number,
    excelTranslations: {[key: string]: string} = {}
): Promise<ReportingDeadlinesParamsData> {
    return postFetch(`${API_SERVERLESS}/tlo/deadlines/${examContractId}?excel=true`, true, excelTranslations);
}
