import React from 'react';
import {MyFruitSubmissionsPageFilterCriteriaAreaProps} from './props/MyFruitSubmissionsPageFilterCriteriaAreaProps';
import styles from './MyFruitSubmissionsPageFilterCriteriaArea.module.scss';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import TextLabelInput from '../../../../../../components/TextLabelInput';
import {FormFooter} from '../../../../../../componentsLayout';
import {Button} from '../../../../../../componentsFormV2';

export default function MyFruitSubmissionsPageFilterCriteriaArea(props: MyFruitSubmissionsPageFilterCriteriaAreaProps) {
    const groupVarietalOptions = [
        {id: '1', value: 'Agricultural'},
        {id: '2', value: 'Vegetable'},
        {id: '3', value: 'Fruit'},
        {id: '4', value: 'Ornamental'},
        {id: '5', value: 'Forest'},
    ];

    const typeOptions = [
        {id: '1', value: 'Examination'},
        {id: '2', value: 'Take over'},
    ];

    const statusOptions = [
        {id: '1', value: 'Positive'},
        {id: '2', value: 'Negative'},
        {id: '3', value: 'Undefined'},
    ];

    const onRegisterIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const registerId = event.target.value;
        props.updateCriteriaValue({registerId});
    };

    const onReferenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const reference = event.target.value;
        props.updateCriteriaValue({reference});
    };

    const onDenominationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const denomination = event.target.value;
        props.updateCriteriaValue({denomination});
    };

    const onSpeciesSelectionChange = (selectedSpecies: {[key: string]: string}) => {
        props.updateCriteriaValue({speciesId: Object.keys(selectedSpecies).join(), specieName: ''}, () =>
            props.updateSelectedSpecies(selectedSpecies)
        );
    };

    const onSpecieNameChange = (specieName: string) => {
        props.updateCriteriaValue({specieName});
    };

    const onSpecieNameFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const speciesNameFilter = event.target.value;
        props.updateCriteriaValue({speciesNameFilter});
    };

    return (
        <>
            <div className={styles.mainFieldContainer}>
                <TextInput
                    placeholder={`Please type file number`}
                    value={props.criteria.registerId}
                    onChange={onRegisterIdChange}
                    double={true}
                    onEnter={() => props.search(true)}
                    outsideLabel={'File number'}
                    outsideLabelWidth={160}
                />
                <div style={{clear: 'both'}} />
                <TextInput
                    placeholder={"Please type Breeder's Reference"}
                    value={props.criteria.reference}
                    onChange={onReferenceChange}
                    double={true}
                    onEnter={() => props.search(true)}
                    outsideLabel={"Breeder's Reference"}
                    outsideLabelWidth={160}
                />
                <div style={{clear: 'both'}} />
                <TextInput
                    placeholder={`Please type denomination`}
                    value={props.criteria.denomination}
                    onChange={onDenominationChange}
                    double={true}
                    onEnter={() => props.search(true)}
                    outsideLabel={'Denomination'}
                    outsideLabelWidth={160}
                />
                <div style={{clear: 'both'}} />
                <TextLabelInput
                    filter={['starts', 'ends', 'contains', 'equals']}
                    placeholder={`please use autocompletion`}
                    currentFilter={props.criteria.speciesNameFilter}
                    onFilterChange={onSpecieNameFilterChange}
                    double={true}
                    onSelectionChange={onSpeciesSelectionChange}
                    onChange={onSpecieNameChange}
                    onEnter={props.search}
                    value={props.criteria.speciesName}
                    selectedElements={props.selectedSpecies}
                    delay={300}
                    outsideLabel={'Species'}
                    outsideLabelWidth={160}
                    listByIdName={props.speciesListByIdName}
                    nameHash={props.speciesNameHash}
                    noInfo={true}
                />
                <SelectInput
                    value={props.criteria.groupVarietal}
                    disabled={true}
                    list={groupVarietalOptions}
                    outsideLabel={'Varietal group'}
                    outsideLabelWidth={160}
                    double={true}
                />
                <SelectInput
                    value={props.criteria.type}
                    disabled={true}
                    double={true}
                    list={typeOptions}
                    outsideLabel={'Type'}
                    outsideLabelWidth={160}
                />
                <SelectInput
                    value={props.criteria.status}
                    onChange={undefined}
                    double={true}
                    list={statusOptions}
                    outsideLabel={'Status'}
                    outsideLabelWidth={160}
                    disabled={true}
                />
                <div style={{clear: 'both'}} />
            </div>
            <FormFooter>
                <Button clickAction={() => props.resetCriteria()} variation={'secondary'}>{`Clear fields`}</Button>
                <Button clickAction={() => props.search(false)}>{`Search`}</Button>
            </FormFooter>
            <div style={{clear: 'both'}} />
        </>
    );
}
