import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {faEye, faLightbulb, faFileExcel} from '@fortawesome/free-regular-svg-icons';

const FRONT_OFFICE = 10;
const FRONT_OFFICE_BUTTON = 11;
function renderButton(popupContent, handler, icon) {
    return {
        title: popupContent,
        icon: rowObject =>
            icon === faEye && !rowObject.valueHTML ? (
                <div />
            ) : (
                <a data-content={popupContent}>
                    <FontAwesomeIcon
                        icon={icon}
                        color={
                            (rowObject.typeId === FRONT_OFFICE || rowObject.typeId === FRONT_OFFICE_BUTTON) &&
                            icon === faLightbulb
                                ? 'gray'
                                : 'green'
                        }
                    />
                </a>
            ),
        handler,
    };
}

export default function BackOfficeLabelsActionButtons(
    props,
    showModalLabel,
    showModalLabelUsedIn,
    showModalPreview,
    printExcel
) {
    const buttons = [];
    buttons.push(renderButton('Preview', (labelId, rowObject) => showModalPreview(rowObject.valueHTML), faEye));
    buttons.push(
        renderButton(
            'Show details',
            (labelId, rowObject) => showModalLabel(rowObject.labelId, rowObject.typeId),
            faPencilAlt
        )
    );
    // buttons.push(renderButton('Duplicate', labelId => openModalConfirm(labelId), faCopy));
    buttons.push(
        renderButton(
            'Used list',
            (labelId, rowObject) =>
                rowObject.typeId !== FRONT_OFFICE &&
                rowObject.typeId !== FRONT_OFFICE_BUTTON &&
                showModalLabelUsedIn(rowObject.labelId),
            faLightbulb
        )
    );
    buttons.push(renderButton('Used In', labelId => printExcel(labelId), faFileExcel));
    return buttons;
}
