import React from 'react';
import ClearRadioResponse from './ClearRadioResponse';
import ConfidentialQuestion from './ConfidentialQuestion';
import Help from '../common/Help';
import LabelQuestion from './LabelQuestion';
import {ModalConfirmVersion2} from '../../../commonModals';
import ModalUsedInVersion2, {rowType} from '../../../commonModals/ModalUsedInVersion2';
import ReactDOMServer from 'react-dom/server';
import ResponseModalDetails from '../modals/ResponseModalDetails';
import WizardResponseLogicsPopover from './WizardResponseLogicsPopover';
import WizardResponseIsDisabledTargetLogicPopover from './WizardResponseIsDisabledTargetLogicPopover';
import {
    checkIfQuestionHasMandatoryErrorFn,
    checkIfQuestionHasNumberOfResponsesAllowedError,
    checkIfQuestionHasSuccessiveScoreOnlyError,
    checkIfQuestionHasUploadTypeMustBeDefinedError as checkIfQuestionHasUploadTypeMustBeDefinedErrorFn,
    checkIfQuestionIsHiddenFn,
    checkIfQuestionIsMandatoryInCurrentGroupIteration,
} from './utils';
import {faLightbulb, faPencilAlt, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {htmlToText} from '../../../utils';
import {getDecodedJWT} from '~utils';
import {
    IDecodedJWT,
    IGroup,
    IGroupQuestion,
    IGroupQuestionResponse,
    IScreenData,
    IStateResponsesClient,
} from '../../../types';

const MODAL_SCREEN = {
    DETAILS: 'DETAILS',
    USED_IN: 'USED_IN',
};

const STATUS = {
    IN_PREPARATION: 1,
    ACTIVE: 2,
    TERMINATED: 3,
};

export interface IWizardQuestionWrapperProps {
    PDFVersion?: number;
    assistedEntries: any;
    applicationCode?: string;
    editable?: boolean;
    formIsConfidential: boolean;
    formLanguage?: string;
    formOrTemplateStatus?: number | null;
    formOrTemplateOfficeId?: number;
    formId: number | null;
    groupIteration: number;
    groupKeyWithIteration: string;
    group: IGroup;
    intl: any;
    isRemedyStarted: any;
    loadFormQuestionsJSONs: any;
    mock: any;
    mainScreen: any;
    modalQueuePop: any;
    modalQueuePush: any;
    question: IGroupQuestion;
    questionnaireLanguage?: string;
    removeResponse?: (modalConfirmResponseDeletion: any, questionId: any) => any;
    responseClient?: IStateResponsesClient;
    responsesClientHash: any;
    screenData: IScreenData;
    steps: IGroup[];
    templateId?: number | null;
    updateScreenData: (arg0: Partial<IScreenData>, arg1?: () => any) => any;
    setWizardQuestionCallables?: any;
}

export interface IWizardQuestionWrapperState {
    activeResponseId: any;
    modalResponseDetails: any;
    modalConfirmResponseDeletion: any;
    modalResponseUsedIn: any;
    hidden: boolean;
    hiddenDueToQuestionResponseLines: string[];
}

class WizardQuestionWrapper<
    P extends IWizardQuestionWrapperProps,
    S extends IWizardQuestionWrapperState
> extends React.Component<P, S> {
    decodedJWT: IDecodedJWT = getDecodedJWT();

    constructor(props: P) {
        super(props);
        this.setState({
            activeResponseId: null,
            modalResponseDetails: null,
            modalConfirmResponseDeletion: null,
            modalResponseUsedIn: null,
            hidden: false,
            hiddenDueToQuestionResponseLines: [],
        });
    }

    checkIfQuestionIsHidden() {
        if (this.props.screenData && this.props.screenData.showHidden) {
            return {
                hidden: false,
                hiddenDueToQuestionResponseLines: [],
            };
        }
        return checkIfQuestionIsHiddenFn({
            steps: this.props.steps,
            question: this.props.question,
            responsesClient: this.props.screenData.responsesClient,
            groupIteration: this.props.groupIteration,
        });
    }

    getTableQuestionIterationKey() {
        return `${this.props.groupKeyWithIteration}_Q${this.props.question && this.props.question.id}`;
    }

    removeResponsesFromHiddenQuestionIfExist(hidden: boolean) {
        if (hidden) {
            let updatedResponsesClient = this.getCopyOfResponsesClient();
            let shouldUpdateState = false;
            const extraStateScreenDate: any = {};
            Object.keys(updatedResponsesClient).forEach(key => {
                let questionKeyWithIteration = this.getQuestionKeyWithIteration();
                if (
                    key.startsWith(this.getQuestionKeyWithIteration()) ||
                    key.startsWith(this.getQuestionConfKeyWithIteration()) ||
                    key.startsWith(`upl${questionKeyWithIteration}`) ||
                    key.startsWith(`ra${questionKeyWithIteration}`)
                ) {
                    shouldUpdateState = true;
                    delete updatedResponsesClient[key];
                }
            });
            if (this.props.question && this.props.question.type === 'table') {
                let iterationKey = this.getTableQuestionIterationKey();
                extraStateScreenDate.tableQuestionsKeysIterationsMap = {
                    ...this.props.screenData.tableQuestionsKeysIterationsMap,
                    [iterationKey]: 1,
                };
            }
            shouldUpdateState &&
                this.props.updateScreenData &&
                this.props.updateScreenData({
                    ...extraStateScreenDate,
                    responsesClient: updatedResponsesClient,
                    hash: Date.now(),
                });
        }
    }

    //e.g: GR123_1_Q20363_1
    getQuestionKeyWithIteration = () =>
        `${this.props.groupKeyWithIteration}_Q${this.props.question && this.props.question.id}_1`;

    //e.g: confGR123_1_Q20363_1
    getQuestionConfKeyWithIteration = () => `conf${this.getQuestionKeyWithIteration()}`;

    getCopyOfResponsesClient = () => {
        let responsesClient = (this.props.screenData && this.props.screenData.responsesClient) || {};
        return {...responsesClient};
    };

    updateResponsesClient = (updatedResponsesClient: IStateResponsesClient) =>
        this.props.updateScreenData &&
        this.props.updateScreenData({responsesClient: updatedResponsesClient, hash: Date.now()});

    showModalResponseDetails = (responseId: number) =>
        this.setState({
            modalResponseDetails: MODAL_SCREEN.DETAILS,
            activeResponseId: responseId,
        });

    showModalResponseUsedIn = (responseId: number) => this.setState({modalResponseUsedIn: responseId});

    closeModalResponseUsedIn = () =>
        this.setState({
            modalResponseUsedIn: null,
        });

    closeModalResponseDetails = () => this.setState({modalResponseDetails: null, activeResponseId: null});

    openModalConfirmResponseDeletion = (responseId: number) =>
        this.setState({modalConfirmResponseDeletion: responseId});

    closeModalConfirmResponseDeletion = () => this.setState({modalConfirmResponseDeletion: null});

    checkIfUserHasClickedOnSaveAndValidate = () => {
        return this.props.screenData && this.props.screenData.hasClickedOnSaveAndValidate;
    };

    checkIfQuestionHasMandatoryError = (questionsCounter?: number) =>
        checkIfQuestionHasMandatoryErrorFn({
            question: this.props.question,
            responsesClient: this.getCopyOfResponsesClient(),
            groupIteration: this.props.groupIteration,
            questionKeyWithIteration: this.getQuestionKeyWithIteration(),
            questionsCounter,
            steps: this.props.steps,
        });

    getResponseLabelPopup = (responseId: number, response?: IGroupQuestionResponse) => {
        const {formOrTemplateStatus} = this.props;
        const {thirdPartyId} = this.decodedJWT || {};
        let isFormOrTemplateInPreparation = formOrTemplateStatus === STATUS.IN_PREPARATION;
        let isResponseInPreparation = response && response.status === STATUS.IN_PREPARATION;
        // let isResponseActive = response && response.status === STATUS.ACTIVE;
        const userHasSameOfficeAsFormOrTemplateOffice = parseInt(thirdPartyId) === this.props.formOrTemplateOfficeId;
        let showUpdateOrRemoveActions =
            isFormOrTemplateInPreparation || (isResponseInPreparation && userHasSameOfficeAsFormOrTemplateOffice);

        let labelPopup = [];
        labelPopup.push({
            label: 'Show details',
            icon: faPencilAlt,
            handler: () => this.showModalResponseDetails(responseId),
        });
        showUpdateOrRemoveActions &&
            labelPopup.push({
                label: 'Remove Response',
                icon: faTrashAlt,
                handler: () => this.openModalConfirmResponseDeletion(responseId),
            });
        labelPopup.push({
            label: 'Used in',
            icon: faLightbulb,
            handler: () => this.showModalResponseUsedIn(responseId),
        });
        return labelPopup;
    };

    getResponseComponentProps = (response: IGroupQuestionResponse) => {
        const questionMandatory = checkIfQuestionIsMandatoryInCurrentGroupIteration({
            question: this.props.question,
            groupIteration: this.props.groupIteration,
            responsesClient: this.props.screenData.responsesClient,
            steps: this.props.steps,
        });
        const questionKeyWithIteration = this.getQuestionKeyWithIteration();
        return {
            PDFVersion: this.props.PDFVersion,
            assistedEntries: this.props.assistedEntries,
            applicationCode: this.props.applicationCode,
            editable: this.props.editable,
            formId: this.props.formId,
            group: this.props.group,
            groupIteration: this.props.groupIteration,
            intl: this.props.intl,
            isRemedyStarted: this.props.isRemedyStarted,
            key: response.id,
            mock: this.props.mock,
            question: this.props.question,
            questionId: this.props.question && this.props.question.id,
            questionKeyWithIteration,
            questionMandatory,
            questionStatus: this.props.question && this.props.question.status,
            questionType: this.props.question && this.props.question.type,
            questionnaireLanguage: this.props.questionnaireLanguage,
            response,
            responsesClientHash: this.props.responsesClientHash,
            steps: this.props.steps,
            screenData: this.props.screenData,
            updateScreenData: this.props.updateScreenData,
        };
    };

    clearRadioResponse = () => {
        const updatedResponsesClient = this.getCopyOfResponsesClient();
        const questionCode = this.getQuestionKeyWithIteration();
        delete updatedResponsesClient[questionCode];
        this.updateResponsesClient(updatedResponsesClient);
    };

    checkIfShouldShowMandatoryError = (hidden: boolean) =>
        !hidden && this.checkIfUserHasClickedOnSaveAndValidate() && this.checkIfQuestionHasMandatoryError();

    checkIfQuestionHasUploadTypeMustBeDefinedError = () =>
        checkIfQuestionHasUploadTypeMustBeDefinedErrorFn({
            question: this.props.question,
            responsesClient: this.props.screenData.responsesClient,
            questionKeyWithIteration: this.getQuestionKeyWithIteration(),
        });

    checkIfShouldShowSuccessiveScoreOnlyError = (showMandatoryError: boolean) => {
        if (!this.checkIfUserHasClickedOnSaveAndValidate() || showMandatoryError) {
            return false;
        }
        return checkIfQuestionHasSuccessiveScoreOnlyError({
            question: this.props.question,
            responsesClient: this.props.screenData.responsesClient,
            questionKeyWithIteration: this.getQuestionKeyWithIteration(),
        });
    };

    checkIfShouldShowNumberOfResponsesAllowedError = (showMandatoryError: boolean) => {
        if (!this.checkIfUserHasClickedOnSaveAndValidate() || showMandatoryError) {
            return false;
        }
        return checkIfQuestionHasNumberOfResponsesAllowedError({
            question: this.props.question,
            responsesClient: this.props.screenData.responsesClient,
            questionKeyWithIteration: this.getQuestionKeyWithIteration(),
        });
    };

    getActiveResponse = () => {
        return ((this.props.question && this.props.question.responses) || []).find(
            response => response.id === this.state.activeResponseId
        );
    };

    pdfConfidentialHideQuestion = () => {
        if (!this.props.PDFVersion) {
            return false;
        }
        if (this.props.formIsConfidential) {
            if (
                this.props.screenData.responsesClient[
                    `conf${this.props.groupKeyWithIteration}_Q${this.props.question.id}_1`
                ] !== 1
            ) {
                return true;
            }
        }
    };

    pdfConfidentialHideResponsesOnly = () => {
        if (!this.props.PDFVersion) {
            return false;
        }
        if (!this.props.formIsConfidential) {
            if (
                this.props.screenData.responsesClient[
                    `conf${this.props.groupKeyWithIteration}_Q${this.props.question.id}_1`
                ] === 1
            ) {
                return true;
            }
        }
    };

    checkIfQuestionRemarkTranslationIsMissing = () =>
        this.props.editable && this.props.question && parseInt(this.props.question.messageRemarkIsDef) === 1;

    onRemoveResponseAction = () => {
        const {question} = this.props;
        const {id: questionId} = question || {};
        this.props.removeResponse && this.props.removeResponse(this.state.modalConfirmResponseDeletion, questionId);
        this.closeModalConfirmResponseDeletion();
    };

    renderQuestionHelpIcon() {
        return (
            <Help
                message={this.props.question && this.props.question.messageHelp}
                language={this.props.questionnaireLanguage}
                className="questionHelp"
            />
        );
    }

    renderQuestionLabel({hidden, hasError = null, hiddenDueToQuestionResponseLines}: any) {
        let QuestionLabel = (
            <LabelQuestion
                question={this.props.question}
                groupIteration={this.props.groupIteration}
                editable={this.props.editable}
                hidden={hidden}
                hiddenDueToQuestionResponseLines={hiddenDueToQuestionResponseLines}
                hasError={hasError}
                responsesClient={this.props.screenData.responsesClient}
                steps={this.props.steps}
            />
        );
        if ((window as any).elements) {
            const htmlString = ReactDOMServer.renderToStaticMarkup(QuestionLabel);
            let elementExists = (window as any).elements.find(
                (element: any) => element.order === this.props.question.order
            );
            if (!elementExists) {
                let element: any = {
                    type: 'QUESTION_TITLE',
                    label: htmlToText(htmlString),
                    order: this.props.question.order,
                    questionType: this.props.question.type,
                };
                this.props.question.messageremark && (element.remark = this.props.question.messageremark);
                this.props.question.shortDisplay && (element.shortDisplay = true);
                (window as any).elements.push(element);
            }
        }

        return QuestionLabel;
    }

    renderConfidentialQuestion = () => {
        return (
            <ConfidentialQuestion
                question={this.props.question}
                getCopyOfResponsesClient={this.getCopyOfResponsesClient}
                getQuestionConfKeyWithIteration={this.getQuestionConfKeyWithIteration}
                updateResponsesClient={this.updateResponsesClient}
                group={this.props.group}
                groupKeyWithIteration={this.props.groupKeyWithIteration}
                intl={this.props.intl}
            />
        );
    };

    renderClearRadioResponse = () => {
        return this.props.question && this.props.question.type === 'radio' ? (
            <ClearRadioResponse
                applicationCode={this.props.applicationCode}
                clearRadioResponseAction={this.clearRadioResponse}
                groupIteration={this.props.groupIteration}
                question={this.props.question}
                screenData={this.props.screenData}
            />
        ) : null;
    };

    renderResponseLogicsPopover = (response: IGroupQuestionResponse) =>
        this.props.editable ? <WizardResponseLogicsPopover response={response} steps={this.props.steps} /> : null;

    renderResponseIsDisabledTargetPopover = (response: IGroupQuestionResponse) =>
        this.props.editable ? (
            <WizardResponseIsDisabledTargetLogicPopover response={response} steps={this.props.steps} />
        ) : null;

    render() {
        const {question} = this.props || {};
        return (
            <>
                {this.state.modalResponseUsedIn && (
                    <ModalUsedInVersion2
                        close={this.closeModalResponseUsedIn}
                        elementId={this.state.modalResponseUsedIn}
                        intl={this.props.intl}
                        type={rowType.RESPONSE}
                    />
                )}
                {this.state.modalResponseDetails && this.state.activeResponseId && (
                    <ResponseModalDetails
                        activeResponseId={this.state.activeResponseId}
                        close={this.closeModalResponseDetails}
                        formId={this.props.formId}
                        group={this.props.group}
                        loadFormQuestionsJSONs={this.props.loadFormQuestionsJSONs}
                        mainScreen={this.props.mainScreen}
                        mock={this.props.mock}
                        modalQueuePop={this.props.modalQueuePop}
                        modalQueuePush={this.props.modalQueuePush}
                        question={this.props.question}
                        questionId={question && question.id}
                        response={this.getActiveResponse()}
                        screenData={this.props.screenData}
                        steps={this.props.steps}
                        templateId={this.props.templateId}
                        updateScreenData={this.props.updateScreenData}
                    />
                )}
                {this.state.modalConfirmResponseDeletion ? (
                    <ModalConfirmVersion2
                        action={this.onRemoveResponseAction}
                        buttonName={'REMOVE'}
                        close={this.closeModalConfirmResponseDeletion}
                        message={`Are you sure you want to remove this response ?`}
                        title={`Remove Response`}
                    />
                ) : null}
            </>
        );
    }
}

export default WizardQuestionWrapper;
