import {API_SERVERLESS, getFetch, postFetch, postForm} from '../../commonApi';
import {
    computeGetUploadPreSignedURLPromises,
    computePostFilesToPreSignedURLPromises,
    postFileToPreSignedURLRequest,
} from '../../utils/requests';
import {ICommunicationMessageInternalSubmitJSONRequest, IDocument} from '../../types';

const getFormData = ({
    communicationId,
    document,
    index,
    identifier,
}: {
    communicationId: number;
    document: IDocument;
    index: number;
    identifier: string;
}) => {
    const formData = new FormData();
    formData.append(`communicationId`, `${communicationId}`);
    if (document.file) {
        formData.append('files[]', identifier);
    } else {
        formData.append('files[]', `${null}`);
    }
    formData.append('filename', encodeURIComponent(document.filename));
    formData.append(`index`, `${index}`);
    if (document.serverIndex) {
        formData.append(`serverIndex`, document.serverIndex || '');
    }
    return formData;
};

export function apiCommunicationCentreGetCommunicationIdUploadFilesAndSendMessage(
    jsonRequest: ICommunicationMessageInternalSubmitJSONRequest,
    documentList: IDocument[],
    endpoints: {
        getCommunicationId: string;
        documentUploadFile: string;
        submitRequest: string;
    }
) {
    let messageCommunicationId: number = -1;
    const {communicationId} = jsonRequest;
    return (
        communicationId
            ? Promise.resolve({communicationId})
            : getFetch(`${API_SERVERLESS}/${endpoints.getCommunicationId}`, true)
    )
        .then(({communicationId}: {communicationId: number}) => {
            messageCommunicationId = communicationId;
            if (documentList.length) {
                return computeGetUploadPreSignedURLPromises({}).then(
                    ({presignedUrl, identifier}: {presignedUrl: string; identifier: string}) =>
                        postFileToPreSignedURLRequest({preSignedURL: presignedUrl, file: documentList[0]}).then(() =>
                            postForm(
                                `${API_SERVERLESS}/${endpoints.documentUploadFile}`,
                                true,
                                getFormData({
                                    communicationId: messageCommunicationId,
                                    document: documentList[0],
                                    index: 0,
                                    identifier,
                                })
                            ).then(async () => {
                                if (documentList.length > 1) {
                                    const uploadArray: Promise<any>[] = [];
                                    let restDocumentList = [...documentList];
                                    restDocumentList.splice(0, 1);
                                    let getUploadPreSignedURLResponses = await Promise.all(
                                        computeGetUploadPreSignedURLPromises({documentList: restDocumentList})
                                    );
                                    await Promise.all(
                                        computePostFilesToPreSignedURLPromises({
                                            getUploadPreSignedURLResponses,
                                            documentList: restDocumentList,
                                        })
                                    );
                                    restDocumentList.forEach((document, index) => {
                                        const formData = getFormData({
                                            communicationId: messageCommunicationId,
                                            document,
                                            index: index + 1,
                                            identifier: getUploadPreSignedURLResponses[index].identifier,
                                        });
                                        uploadArray.push(
                                            postForm(
                                                `${API_SERVERLESS}/${endpoints.documentUploadFile}`,
                                                true,
                                                formData
                                            )
                                        );
                                    });
                                    return Promise.all(uploadArray);
                                } else {
                                    return Promise.resolve();
                                }
                            })
                        )
                );
            } else {
                return Promise.resolve();
            }
        })
        .then(() => {
            return postFetch(
                `${API_SERVERLESS}/${endpoints.submitRequest}`,
                true,
                Object.assign({}, jsonRequest, {communicationId: messageCommunicationId})
            );
        })
        .then(() => ({
            communicationId: messageCommunicationId,
        }));
}
