import React, {Fragment} from 'react';
import styles from './HeaderBreadcrumb.module.scss';

function HeaderBreadcrumb({links}: {links: any[]}) {
    return (
        <section className={styles.wrap}>
            {links.map((link, index) => (
                <Fragment key={link}>
                    <span
                        className={styles.linkTitle}
                        style={{fontWeight: index < links.length - 1 ? 'normal' : 'bold'}}
                    >
                        {link}
                    </span>
                    {index < links.length - 1 ? <span className={styles.separator}>{`>`}</span> : null}
                </Fragment>
            ))}
        </section>
    );
}

export default HeaderBreadcrumb;
