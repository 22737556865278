import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import ClientCode1 from './screenshots/ClientCode_1.PNG';
import ClientCode2 from './screenshots/ClientCode_2.PNG';
import ClientCode3 from './screenshots/ClientCode_3.PNG';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeClientCodeHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>
                        Displays a CPVO client number input with a search icon:{' '}
                    </p>
                    <img src={ClientCode1} alt="ClientCode" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>
                        The user can click on the search icon and search for a client in the modal that appears
                    </p>
                    <img src={ClientCode2} alt="ClientCode" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>
                        When the user clicks on a client, the search modal closes and then client fields: Name, Official
                        address, Postcode, City and Country are populated{' '}
                    </p>
                    <img src={ClientCode3} alt="ClientCode" className={styles.modalHelpImage} />
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeClientCodeHelp;
