import React from 'react';
import {
    faLightbulb,
    faList,
    faPencilAlt,
    faPlus,
    faSearch,
    faSpinner,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {
    ModalConfirmVersion2,
    ModalCustomVersion2,
    ModalLabelPreviewVersion2,
    ModalLabelSearchVersion2,
} from '../../../commonModals';
import ModalLabelVersion2, {getLabelTypeId} from '../../../commonModals/ModalLabelVersion2';
import ModalUsedInVersion2, {rowType} from '../../../commonModals/ModalUsedInVersion2';
import {Error, LoadingBar} from '../../../componentsLayout';
import StatusField from '~shared/StatusField';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import Checkbox from '~components/Checkbox';
import {injectIntl} from 'react-intl';
import {Button} from '../../../componentsFormV2';
import {
    apiBackOfficeFormGroupCreateOrUpdate,
    apiBackOfficeFormGroupsGet,
    apiBackOfficeLabels,
    apiBackOfficeUpdateLabels,
    IPayloadFormGroupCreateOrUpdate,
} from './GroupModalCreateService';
import {renderHTML} from '~common/format';
import styles from './GroupModalCreate.module.scss';

const EMPTY_GROUP: IStateGroup = {
    nameMessage: {
        id: null,
        value: null,
        type: null,
    },
    remarkMessage: {
        id: null,
        value: null,
        type: null,
    },
    helpMessage: {
        id: null,
        value: null,
        type: null,
    },
    btnAddMessage: {
        id: null,
        value: null,
        type: null,
    },
    btnDelMessage: {
        id: null,
        value: null,
        type: null,
    },
    order: null,
    mappingCode: null,
    jsonMapping: null,
    mandatory: false,
    multi: false,
};

interface IStateGroup {
    nameMessage: {
        id: any;
        value: any;
        type: any;
    };
    remarkMessage: {
        id: any;
        value: any;
        type: any;
    };
    helpMessage: {
        id: any;
        value: any;
        type: any;
    };
    btnAddMessage: {
        id: any;
        value: any;
        type: any;
    };
    btnDelMessage: {
        id: any;
        value: any;
        type: any;
    };
    order: any;
    mappingCode: any;
    jsonMapping: any;
    mandatory: boolean;
    multi: boolean;
}

type TPayloadGroup = typeof EMPTY_GROUP;

type TKeyOfPayloadGroup = keyof TPayloadGroup;

interface IProps {
    close: (force?: any) => any;
    loadJSONs: any;
    formId: any;
    intl: any;
    setCloseHandler: any;
    modalQueuePush: any;
    modalQueuePop: any;
    addGroupToGroupsQuestionDrafts: any;
    groupsQuestion: any;
    loadFormQuestionsJSONs: any;
}

interface IState {
    loading: number;
    errorMandatoryFields: boolean;
    group: IStateGroup;
    modalLabelSearch: any;
    modalPreview: any;
    modalUsedIn: any;
    modalLabel: any;
    modalConfirmClose: any;
    textLabelGroupNameValue: string;
    textLabelGroupNameSelectedElements: {[key: string]: any};
    textLabelGroupNameListByIdName: {ID: string; NAME: string}[];
    textLabelGroupNameNameHash: {[key: string]: number[]};
    textLabelGroupNameTimeoutId: any;
    textLabelGroupNameSearchLoading: boolean;
    textTimestamp: number;
    forceHideSearchList: boolean;
}

class GroupModalCreate extends React.Component<IProps, IState> {
    initialGroup: IStateGroup = EMPTY_GROUP;

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: 0,
            errorMandatoryFields: false,
            group: EMPTY_GROUP,
            modalLabelSearch: null,
            modalPreview: null,
            modalUsedIn: null,
            modalLabel: null,
            modalConfirmClose: null,
            textLabelGroupNameValue: '',
            textLabelGroupNameSelectedElements: {},
            textLabelGroupNameListByIdName: [],
            textLabelGroupNameNameHash: {},
            textLabelGroupNameTimeoutId: null,
            textLabelGroupNameSearchLoading: false,
            textTimestamp: Date.now(),
            forceHideSearchList: true,
        };
    }

    componentDidMount() {
        this.props.setCloseHandler(this.closeHandler);
        // let maximumGroupOrder = this.props.groupsQuestion
        //     .filter(group => group.isGroup === true)
        //     .map(group => parseInt(group.order))
        //     .pop();
        // this.setState({group: {order: `00${maximumGroupOrder ? maximumGroupOrder + 1 : 1}`.slice(-2)}});
        this.loadJSONs();
    }

    loadJSONs = () => {
        this.setState(
            prev => ({
                loading: prev.loading + 1,
            }),
            () => {
                apiBackOfficeFormGroupsGet().then(jsonResponse => {
                    jsonResponse.groups &&
                        jsonResponse.groups.length > 0 &&
                        this.setState(prev => ({
                            loading: prev.loading - 1,
                            group: {
                                ...prev.group,
                                order: `${
                                    (Math.max(...jsonResponse.groups.map((el: any) => parseInt(el.order))) || 0) + 1
                                }`,
                            },
                        }));
                });
            }
        );
    };

    closeHandler = () => {
        const formHasChanged =
            Object.keys(this.initialGroup).filter(
                key =>
                    this.state.group &&
                    this.initialGroup[key as TKeyOfPayloadGroup] !== this.state.group[key as TKeyOfPayloadGroup]
            ).length > 0;
        if (formHasChanged) {
            this.openModalConfirmClose();
            return false;
        } else {
            return true;
        }
    };

    prepareDraftGroup = ({
        JSONRequest,
        JSONResponse,
    }: {
        JSONRequest: IPayloadFormGroupCreateOrUpdate;
        JSONResponse: {newGroupId: number};
    }) => {
        return {
            ...JSONRequest,
            id: JSONResponse.newGroupId,
            message: this.state.group && this.state.group.nameMessage && this.state.group.nameMessage.value,
            messageRemark: this.state.group && this.state.group.remarkMessage && this.state.group.remarkMessage.value,
            messageHelp: this.state.group && this.state.group.helpMessage && this.state.group.helpMessage.value,
            messageButtonAdd:
                this.state.group && this.state.group.btnAddMessage && this.state.group.btnAddMessage.value,
            messageButtonDel:
                this.state.group && this.state.group.btnDelMessage && this.state.group.btnDelMessage.value,
            messageIdButtonAddId: this.state.group.btnAddMessage && this.state.group.btnAddMessage.id, //TODO FIX: messageIdButtonAddId --> messageButtonAddId
            messageIdButtonDelId: this.state.group.btnDelMessage && this.state.group.btnDelMessage.id, //TODO FIX: messageIdButtonAddId --> messageButtonAddId
            isGroup: true,
            isTemplate: false,
            order: this.state.group.order,
            status: 1,
            questions: [],
        };
    };

    prepareGroupCreateBody = (): IPayloadFormGroupCreateOrUpdate => ({
        groupId: 0,
        formId: this.props.formId,
        messageId: (this.state.group.nameMessage && this.state.group.nameMessage.id) || null,
        messageRemarkId: (this.state.group.remarkMessage && this.state.group.remarkMessage.id) || null,
        messageHelpId: (this.state.group.helpMessage && this.state.group.helpMessage.id) || null,
        messageButtonAddId: (this.state.group.btnAddMessage && this.state.group.btnAddMessage.id) || null,
        messageButtonDelId: (this.state.group.btnAddMessage && this.state.group.btnAddMessage.id) || null,
        orderDisplay: this.state.group.order,
        mappingCode: this.state.group.mappingCode || null,
        jsonMapping: this.state.group.jsonMapping || null,
        mandatory: this.state.group.mandatory ? 1 : 0,
        multi: this.state.group.multi ? 1 : 0,
    });

    showModalPreview = (valueHTML: string) =>
        this.props.modalQueuePush(
            <ModalLabelPreviewVersion2 intl={this.props.intl} close={this.closeModalPreview} valueHTML={valueHTML} />
        );

    closeModalPreview = () => this.props.modalQueuePop();

    showModalLabel = (labelId: number, typeId: string, fieldIdKey: string, fieldHTMLKey: string) =>
        this.props.modalQueuePush(
            <ModalLabelVersion2
                intl={this.props.intl}
                label={{labelId, typeId, fieldIdKey, fieldHTMLKey}}
                setLabelId={this.setLabelId}
                showModalUsedIn={this.showModalUsedIn}
                close={this.closeModalLabel}
            />
        );

    closeModalLabel = () => this.props.modalQueuePop();

    showModalUsedIn = (labelId: number) =>
        this.props.modalQueuePush(
            <ModalUsedInVersion2
                elementId={labelId}
                intl={this.props.intl}
                type={rowType.LABEL}
                close={this.closeModalUsedIn}
            />
        );

    closeModalUsedIn = () => this.props.modalQueuePop();

    openModalConfirmClose = () => this.setState({modalConfirmClose: true});

    closeModalConfirmClose = () => this.setState({modalConfirmClose: null});

    modalConfirmCloseAction = () => this.props.close(true);

    updateForm = (stateData: any) =>
        this.setState(prev => {
            return {
                ...prev,
                group: {
                    ...prev.group,
                    ...stateData,
                },
            };
        });

    showModalLabelSearch = (typeId: string, fieldIdKey: string, fieldHTMLKey: string) =>
        this.props.modalQueuePush(
            <ModalLabelSearchVersion2
                intl={this.props.intl}
                target={{typeId, fieldIdKey, fieldHTMLKey}}
                setLabelId={this.setLabelId}
                showModalPreview={this.showModalPreview}
                showModalUsedIn={this.showModalUsedIn}
                showModalLabel={this.showModalLabel}
                close={this.closeModalLabelSearch}
            />
        );

    closeModalLabelSearch = () => this.props.modalQueuePop();

    setLabelId = (
        label: {
            labelId: number;
            languageId: string;
            typeId: number;
            officeId: number;
            isUndefined: number;
            value: string;
            valueHTML: string;
            labelValueHTML?: any;
        },
        fieldIdKey: string
    ) => {
        const {labelId, valueHTML, labelValueHTML, typeId} = label;
        return this.updateForm({
            [fieldIdKey]: {id: labelId, value: valueHTML || labelValueHTML, type: typeId},
        });
    };

    clearLabelUse = (fieldIdKey: string) => this.updateForm({[fieldIdKey]: {id: null, value: null, type: null}});

    groupSearch = () => {
        this.setState({textLabelGroupNameSearchLoading: true}, () => {
            apiBackOfficeLabels({
                groupSearchTerm: this.state.textLabelGroupNameValue,
            }).then(jsonResponse => {
                const groupNameHash: {[key: string]: number[]} = {};
                (jsonResponse.data.labels || []).forEach((el: any) => {
                    let key = el.value;
                    if (!groupNameHash[key]) {
                        groupNameHash[key] = [];
                    }
                    groupNameHash[key].push(el.labelId);
                });
                const groupListByIdName = Object.keys(groupNameHash).map(groupName => ({
                    ID: groupNameHash[groupName].join(','),
                    NAME: groupName,
                }));
                this.setState({
                    textLabelGroupNameSearchLoading: false,
                    textLabelGroupNameListByIdName: groupListByIdName,
                    textLabelGroupNameNameHash: groupNameHash,
                    textTimestamp: Date.now(),
                    forceHideSearchList: false,
                });
            });
        });
    };

    requestGroupCreateOrUpdate = (JSONRequest: IPayloadFormGroupCreateOrUpdate) => {
        apiBackOfficeFormGroupCreateOrUpdate(JSONRequest).then(JSONResponse => {
            let draftGroup = this.prepareDraftGroup({JSONRequest, JSONResponse});
            this.props.addGroupToGroupsQuestionDrafts && this.props.addGroupToGroupsQuestionDrafts(draftGroup);
            this.props.loadFormQuestionsJSONs &&
                this.props.loadFormQuestionsJSONs(null, null, null, true, {groupId: draftGroup.id});
            this.props.close(true);
        });
    };

    onSave = () => {
        const {group} = this.state || {};
        const {nameMessage, order} = group || {};
        const {id: nameMessageId} = nameMessage || {};
        const gHasIdOrLabelValueToAdd = nameMessageId || this.state.textLabelGroupNameValue;
        const hasEmptyMandatoryFields = !gHasIdOrLabelValueToAdd || !order;

        if (hasEmptyMandatoryFields) {
            return this.setState({errorMandatoryFields: true});
        } else {
            this.setState({errorMandatoryFields: false});
        }
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                let JSONRequest: IPayloadFormGroupCreateOrUpdate = this.prepareGroupCreateBody();
                if (!this.state.group.nameMessage || !this.state.group.nameMessage.id) {
                    apiBackOfficeUpdateLabels({labelValue: this.state.textLabelGroupNameValue}).then(
                        (labelCreateResponse: any) => {
                            if (labelCreateResponse.data) {
                                JSONRequest.messageId = labelCreateResponse.data;
                                this.setState(
                                    prev => ({
                                        group: {
                                            ...prev.group,
                                            nameMessage: {
                                                ...prev.group.nameMessage,
                                                id: labelCreateResponse.data,
                                                value: prev.textLabelGroupNameValue,
                                                valueHTML: prev.textLabelGroupNameValue,
                                            },
                                        },
                                    }),
                                    () => {
                                        this.requestGroupCreateOrUpdate(JSONRequest);
                                    }
                                );
                            }
                        }
                    );
                } else {
                    this.requestGroupCreateOrUpdate(JSONRequest);
                }
            }
        );
    };

    onTextLabelGroupNameSelectionChange = (textLabelGroupNameSelectedElements: any) => {
        let gId: any = null,
            gValue: any = null,
            gValueHTML: any = null;
        if (Object.keys(textLabelGroupNameSelectedElements || {}).length) {
            gId = parseInt(Object.keys(textLabelGroupNameSelectedElements)[0]);
            gValue = Object.values(textLabelGroupNameSelectedElements)[0];
            gValueHTML = Object.values(textLabelGroupNameSelectedElements)[0];
        }
        this.setState(prev => ({
            textLabelGroupNameSelectedElements,
            textLabelGroupNameValue: '',
            group: {
                ...prev.group,
                nameMessage: {
                    ...prev.group.nameMessage,
                    id: gId,
                    value: gValue,
                    valueHTML: gValueHTML,
                },
            },
        }));
    };

    onOrderChange = ({target: {value: order}}: React.ChangeEvent<HTMLInputElement>) => this.onFormDataChange({order});

    onMappingCodeChange = ({target: {value: mappingCode}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({mappingCode});

    onJsonMappingChange = ({target: {value: jsonMapping}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({jsonMapping});

    onMultiChange = () => this.onFormDataChange({multi: !this.state.group.multi});

    onMandatoryChange = () => this.onFormDataChange({mandatory: !this.state.group.mandatory});

    onFormDataChange = (valueObject: any) =>
        this.setState(prev => ({group: Object.assign({}, prev.group, valueObject)}));

    // onSaveBAK = () => {
    //     const {group} = this.state || {};
    //     const {nameMessage, order} = group || {};
    //     const {id: nameMessageId} = nameMessage || {};
    //     const hasEmptyMandatoryFields = !nameMessageId || !order;
    //
    //     if (hasEmptyMandatoryFields) {
    //         return this.setState({errorMandatoryFields: true});
    //     } else {
    //         this.setState({errorMandatoryFields: false});
    //     }
    //     this.setState(
    //         prev => ({loading: ++prev.loading}),
    //         () => {
    //             let jsonBody = this.prepareGroupCreateBody();
    //             apiBackOfficeFormGroupCreateOrUpdate(jsonBody).then(jsonData => {
    //                 let draftGroup = this.prepareDraftGroup({jsonBody, jsonData});
    //                 this.props.addGroupToGroupsQuestionDrafts && this.props.addGroupToGroupsQuestionDrafts(draftGroup);
    //                 this.props.loadFormQuestionsJSONs && this.props.loadFormQuestionsJSONs(null, null, null, true);
    //                 this.props.close(true);
    //             });
    //         }
    //     );
    // };

    onTextLabelGroupNameValueChange = (textLabelGroupNameValue: string) => {
        clearTimeout(this.state.textLabelGroupNameTimeoutId);
        let newTimeoutId = setTimeout(() => {
            this.state.textLabelGroupNameValue && this.groupSearch();
        }, 300);
        this.setState({
            forceHideSearchList: true,
            textLabelGroupNameValue,
            textLabelGroupNameTimeoutId: newTimeoutId,
        });
    };

    renderPopupElements(labelId: any, typeId: any, fieldIdKey: any, fieldHTMLKey: any) {
        const elementsArr = [];
        elementsArr.push({
            label: 'Search label',
            icon: faSearch,
            handler: () => this.showModalLabelSearch(typeId, fieldIdKey, fieldHTMLKey),
        });
        !labelId &&
            elementsArr.push({
                label: 'Add label',
                icon: faPlus,
                handler: () => this.showModalLabel(0, typeId, fieldIdKey, fieldHTMLKey),
            });
        labelId &&
            elementsArr.push({
                label: 'Show label',
                icon: faPencilAlt,
                handler: () => this.showModalLabel(labelId, typeId, fieldIdKey, fieldHTMLKey),
            });
        labelId &&
            elementsArr.push({
                label: 'Clear label',
                icon: faTimes,
                handler: () => this.clearLabelUse(fieldIdKey),
            });
        labelId &&
            elementsArr.push({
                label: 'Used in',
                icon: faLightbulb,
                handler: () => this.showModalUsedIn(labelId),
            });
        return elementsArr;
    }

    render() {
        const {group} = this.state || {};

        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={
                    <>
                        {`New Group`}
                        <div style={{marginLeft: 15}}>
                            <StatusField intl={this.props.intl} statusId={1} />
                        </div>
                    </>
                }
                body={
                    <>
                        {this.state.modalLabelSearch !== null ? (
                            <ModalLabelSearchVersion2
                                intl={this.props.intl}
                                target={this.state.modalLabelSearch}
                                setLabelId={this.setLabelId}
                                showModalPreview={this.showModalPreview}
                                showModalUsedIn={this.showModalUsedIn}
                                showModalLabel={this.showModalLabel}
                                close={this.closeModalLabelSearch}
                            />
                        ) : null}
                        {this.state.modalPreview !== null ? (
                            <ModalLabelPreviewVersion2
                                intl={this.props.intl}
                                close={this.closeModalPreview}
                                valueHTML={this.state.modalPreview}
                            />
                        ) : null}
                        {this.state.modalLabel ? (
                            <ModalLabelVersion2
                                intl={this.props.intl}
                                label={this.state.modalLabel}
                                setLabelId={this.setLabelId}
                                showModalUsedIn={this.showModalUsedIn}
                                close={this.closeModalLabel}
                            />
                        ) : null}
                        {this.state.modalUsedIn ? (
                            <ModalUsedInVersion2
                                elementId={this.state.modalUsedIn}
                                intl={this.props.intl}
                                type={rowType.LABEL}
                                close={this.closeModalUsedIn}
                            />
                        ) : null}
                        {this.state.modalConfirmClose && (
                            <ModalConfirmVersion2
                                title={'Element modified'}
                                message={'Are you sure you want to validate your modifications?'}
                                buttonName={'Yes'}
                                action={() => this.props.close(true)}
                                close={this.closeModalConfirmClose}
                            />
                        )}
                        <section style={{overflowX: 'hidden'}}>
                            <div
                                style={{
                                    marginBottom: 25,
                                    zIndex: 1056,
                                }}
                            >
                                <DataSheetFormSection title={'Group fields'} disableToggle={true}>
                                    <div className={styles.textLabelGroupNameSearchWrap}>
                                        <TextLabelInput
                                            double={true}
                                            onSelectionChange={this.onTextLabelGroupNameSelectionChange}
                                            onChange={this.onTextLabelGroupNameValueChange}
                                            value={this.state.textLabelGroupNameValue}
                                            selectedElements={this.state.textLabelGroupNameSelectedElements}
                                            noInfo={true}
                                            multiple={false}
                                            filterContains={true}
                                            label={'Name'}
                                            mandatory={true}
                                            listByIdName={this.state.textLabelGroupNameListByIdName}
                                            nameHash={this.state.textLabelGroupNameNameHash}
                                            uniqueValue={true}
                                            buttonIcon={this.state.textLabelGroupNameSearchLoading && faSpinner}
                                            timestamp={this.state.textTimestamp}
                                            autoSelection={true}
                                            forceHideSearchList={this.state.forceHideSearchList}
                                        />
                                    </div>
                                    <DataSheetFormFields
                                        label={'Remark'}
                                        html={renderHTML(group && group.remarkMessage && group.remarkMessage.value)}
                                        loading={this.state.loading}
                                        double={true}
                                        containerClassName="popover-nested"
                                        labelIcon={faList}
                                        labelPopup={this.renderPopupElements(
                                            group && group.remarkMessage && group.remarkMessage.id,
                                            getLabelTypeId('Remark'),
                                            'remarkMessage',
                                            'remarklValueHTML'
                                        )}
                                    />
                                    <DataSheetFormFields
                                        label={'Help'}
                                        html={renderHTML(group && group.helpMessage && group.helpMessage.value)}
                                        loading={this.state.loading}
                                        double={true}
                                        containerClassName="popover-nested"
                                        labelIcon={faList}
                                        labelPopup={this.renderPopupElements(
                                            group && group.helpMessage && group.helpMessage.id,
                                            getLabelTypeId('Help'),
                                            'helpMessage',
                                            'helplValueHTML'
                                        )}
                                    />
                                    <DataSheetFormFields
                                        label={'Add'}
                                        html={renderHTML(group && group.btnAddMessage && group.btnAddMessage.value)}
                                        loading={this.state.loading}
                                        double={true}
                                        containerClassName="popover-nested"
                                        labelIcon={faList}
                                        labelPopup={this.renderPopupElements(
                                            group && group.btnAddMessage && group.btnAddMessage.id,
                                            getLabelTypeId('Front Office Button'),
                                            'btnAddMessage',
                                            'addlValueHTML'
                                        )}
                                    />
                                    <DataSheetFormFields
                                        label={'Del'}
                                        html={renderHTML(group && group.btnDelMessage && group.btnDelMessage.value)}
                                        loading={this.state.loading}
                                        double={true}
                                        containerClassName="popover-nested"
                                        labelIcon={faList}
                                        labelPopup={this.renderPopupElements(
                                            group && group.btnDelMessage && group.btnDelMessage.id,
                                            getLabelTypeId('Front Office Button'),
                                            'btnDelMessage',
                                            'delValueHTML'
                                        )}
                                    />
                                </DataSheetFormSection>
                            </div>
                            <div>
                                <TextInput
                                    label={`Order display`}
                                    mandatory={true}
                                    value={group && group.order}
                                    onChange={this.onOrderChange}
                                    disabled={this.state.loading !== 0}
                                />
                                <TextInput
                                    label={`Mapping code`}
                                    value={group && group.mappingCode}
                                    onChange={this.onMappingCodeChange}
                                    disabled={this.state.loading !== 0}
                                />
                                <TextInput
                                    label={`JSON Mapping`}
                                    required={true}
                                    value={group && group.jsonMapping}
                                    onChange={this.onJsonMappingChange}
                                    double={true}
                                    disabled={this.state.loading !== 0}
                                />
                            </div>
                            <div style={{float: 'left', width: '100%'}}>
                                <Checkbox
                                    clickAction={this.onMandatoryChange}
                                    simple={true}
                                    label={`Mandatory`}
                                    value={group && group.mandatory}
                                    disabled={this.state.loading !== 0}
                                />
                                <Checkbox
                                    clickAction={this.onMultiChange}
                                    simple={true}
                                    label={`Multi`}
                                    value={group && group.multi}
                                    disabled={this.state.loading !== 0}
                                />
                            </div>
                            <div style={{float: 'left', fontStyle: 'italic'}}>
                                <p style={{color: '#8a6d3b', marginBottom: 0}}>
                                    Note: required fields are marked with an asterisk (*)
                                </p>
                            </div>
                            <div style={{clear: 'both'}} />
                        </section>
                    </>
                }
                footer={
                    this.state.loading !== 0 ? (
                        <LoadingBar />
                    ) : (
                        <>
                            {this.state.errorMandatoryFields ? <Error>Please fill the mandatory fields</Error> : null}
                            <Button
                                clickAction={() => this.openModalConfirmClose()}
                                icon={'close'}
                                variation={'danger'}
                            >{`Close`}</Button>
                            <Button clickAction={this.onSave} icon={'arrowRight'}>{`Save`}</Button>
                        </>
                    )
                }
            />
        );
    }
}

export default injectIntl(GroupModalCreate);
