import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Help from '../common/Help';
import {ModalAlertVersion2} from '../../../commonModals';
import Remark from '../../../components/Remark';
import ResponseWrapper, {IWizardResponseWrapperProps, IWizardResponseWrapperState} from './ResponseWrapper';
import TextAreaInput from '~components/TextAreaInput';
import cx from 'classnames';
import {faPen, faPlus} from '@fortawesome/free-solid-svg-icons';
import {findGroupOrTemplateFromQuestionId} from '../questions/utils';
import {getGroupKeyWithIteration} from '../utils';
import {getErrorMessageResponseMandatoryRemark} from './utils';
import {injectIntl} from 'react-intl';
import styles from './ResponseCheckbox.module.scss';
import {replaceLineBreaksWithHTML} from '../../../utils/format';
import {IStateResponsesClient} from '../../../types';

interface IState extends IWizardResponseWrapperState {
    isForceResponseHelpModalOpen: boolean;
    messageLink: string | null;
}

class ResponseCheckbox extends ResponseWrapper<IWizardResponseWrapperProps, IState> {
    constructor(props: IWizardResponseWrapperProps) {
        super(props);
        this.state = {
            ...this.state,
            isForceResponseHelpModalOpen: false,
            messageLink: null,
        };
    }

    componentDidMount() {
        this.checkAndUpdateIfCurrentResponseIsDisabledDueToLogicDisabledOfOtherResponse();
    }

    componentDidUpdate(prevProps: IWizardResponseWrapperProps, prevState: IState) {
        if (this.props.screenData.hash !== prevProps.screenData.hash) {
            this.checkAndUpdateIfCurrentResponseIsDisabledDueToLogicDisabledOfOtherResponse();
        }
    }

    // TODO: Should run for text area also (or for all) ?
    checkForForceResponseLogicType = (
        responsesClient: IStateResponsesClient,
        checkboxIsChecked: boolean
    ): {messageLink: any; newResponseClient: {[p: string]: any}; hasForceResponseLogicType: boolean} => {
        let newResponseClient = {...responsesClient};
        const {response} = this.props;
        let helpMessage;
        let hasForceResponseLogicType = false;
        ((response && response.logics) || [])
            .filter(logic => logic.type === 'forceresponse')
            .forEach(logicForceResponse => {
                const {logicParam, responseIdDest, questionIdDest, messageLink} = logicForceResponse || {};
                messageLink && (helpMessage = messageLink);
                hasForceResponseLogicType = true;
                const logicParamValue = parseInt((logicParam || '').split(':').pop() || '0') || 0;
                // Payload logic, (does not have groupId)
                // questionIdDest: "12589"
                // responseIdDest: "26840"
                // When checked, force response answer
                // GR29_1_Q209_1_R491_1: 1
                // GR103_1_Q12589_1: "GR103_1_Q12589_1_R26840_1"
                // but this.props.questionKeyWithIteration: "GR29_1_Q209_1"
                // find this question's group (103)
                let groupOrTemplate = findGroupOrTemplateFromQuestionId({
                    steps: this.props.steps,
                    questionId: questionIdDest,
                });
                let groupKeyWithIteration = getGroupKeyWithIteration({
                    group: groupOrTemplate,
                });
                let questionKeyWithIteration = `${groupKeyWithIteration}_Q${questionIdDest}_1`;
                const {type: groupOrTemplateQuestionType} =
                    ((groupOrTemplate && groupOrTemplate.questions) || []).find(
                        question => parseInt(String(question.id)) === parseInt(String(questionIdDest))
                    ) || {};
                if (checkboxIsChecked) {
                    if (groupOrTemplateQuestionType === 'checkbox') {
                        if (logicParamValue) {
                            newResponseClient[`${questionKeyWithIteration}_R${responseIdDest}_1`] = 1;
                        } else {
                            delete newResponseClient[`${questionKeyWithIteration}_R${responseIdDest}_1`];
                        }
                    } else {
                        if (logicParamValue) {
                            newResponseClient[
                                questionKeyWithIteration
                            ] = `${questionKeyWithIteration}_R${responseIdDest}_1`;
                        } else {
                            delete newResponseClient[questionKeyWithIteration];
                        }
                    }
                }
            });
        return {
            newResponseClient,
            messageLink: helpMessage || null,
            hasForceResponseLogicType,
        };
    };

    onCheckboxChange = () => {
        let updatedResponseClient = this.getCopyOfResponsesClient();
        let checkboxPayloadKey = this.getResponseKey(); //e.g. GR25_1_Q11492_1_R451_1: 1
        let checkboxIsChecked = false;
        if (updatedResponseClient[checkboxPayloadKey] === 1) {
            delete updatedResponseClient[checkboxPayloadKey];
            delete updatedResponseClient[`ra${checkboxPayloadKey}`];
        } else {
            checkboxIsChecked = true;
            updatedResponseClient[checkboxPayloadKey] = 1;
        }
        let {newResponseClient, hasForceResponseLogicType, messageLink} = this.checkForForceResponseLogicType(
            updatedResponseClient,
            checkboxIsChecked
        );
        this.setState({
            isForceResponseHelpModalOpen:
                hasForceResponseLogicType && !!messageLink && updatedResponseClient[checkboxPayloadKey] === 1,
            messageLink:
                hasForceResponseLogicType && updatedResponseClient[checkboxPayloadKey] === 1 ? messageLink : null,
        });
        let targetQuestionHasAttachment = false;
        if (!updatedResponseClient[checkboxPayloadKey]) {
            this.props.response.logics
                .filter(logic => logic.type === 'showquestion')
                .forEach(logic => {
                    Object.entries(this.props.screenData.responsesClient).forEach(([key, value]) => {
                        if (
                            !targetQuestionHasAttachment &&
                            key &&
                            key.includes(`_Q${logic.questionIdDest}_`) &&
                            parseInt(key.split('_')[1]) === this.props.groupIteration &&
                            typeof value === 'string' &&
                            value.startsWith(`${this.props.applicationCode}_`)
                        ) {
                            targetQuestionHasAttachment = true;
                        }
                    });
                });
        }
        if (targetQuestionHasAttachment) {
            this.showModalConfirmRemoveAttachments(newResponseClient);
        } else {
            this.updateResponsesClient(newResponseClient);
        }
    };

    closeModal = () => this.setState({isForceResponseHelpModalOpen: false});

    renderRemedyIcon = () => {
        const remedyType = this.getRemedyType();
        return (
            <div
                className={cx(
                    styles.remedyIconWrap,
                    styles[this.props.question.type],
                    remedyType ? styles[remedyType] : null
                )}
                onClick={this.onButtonAddResponseToRemedyClick}
                id={`REMEDY_${this.getResponseKey()}`}
            >
                <FontAwesomeIcon
                    icon={(this.checkIfRemedyResponseExists() ? faPen : faPlus) as any}
                    color={'white'}
                    width={16}
                    height={10}
                />
            </div>
        );
    };

    renderPDFVersion = () => {
        const {response} = this.props;
        const {messageRemark} = response || {};
        const remarkAnswer = this.getResponseRemarkAnswer();
        const isCheckboxChecked = this.getResponseAnswer() === 1;

        return (
            <>
                {super.render()}
                {this.props.isRemedyStarted && this.renderRemedyIcon()}
                <div className={styles.pdfResponseWrap}>
                    <div className={styles.pdfResponseWrapLeft}>
                        <span className={cx(styles.pdfResponseRadio, isCheckboxChecked && styles.pdfRadioChecked)} />
                        <span
                            /* eslint-disable-next-line react/no-danger */
                            dangerouslySetInnerHTML={{__html: this.getResponseLabel()}}
                        />
                    </div>
                    {messageRemark || this.isRemarkAllowed() ? (
                        <div className={styles.pdfRadioWrapRightOuter}>
                            {messageRemark && (
                                <div
                                    ref={el => {
                                        if (el) el.innerHTML = messageRemark;
                                    }}
                                    className={styles.messageRemark}
                                />
                            )}
                            {this.isRemarkAllowed() && (
                                <div
                                    className={cx(
                                        styles.pdfResponseWrapRightInner,
                                        !!remarkAnswer && styles.hasPDFValue
                                    )}
                                >
                                    {replaceLineBreaksWithHTML(remarkAnswer)}
                                </div>
                            )}
                        </div>
                    ) : null}
                </div>
            </>
        );
    };

    render() {
        const {response} = this.props;
        const {messageRemark} = response || {};
        const isCheckboxChecked = this.getResponseAnswer() === 1;
        const responseKey = this.getResponseKey();
        const responseLabel = this.getResponseLabel();
        const responseRemarkKey = this.getResponseRemarkKey();
        const responseRemarkAnswer = this.getResponseRemarkAnswer();
        const showRemarkTextArea = this.isRemarkAllowed() && isCheckboxChecked;
        const hasRemarkMandatoryError = this.checkIfQuestionHasRemarkMandatoryError();
        const remarkMandatoryErrorMessage =
            hasRemarkMandatoryError && getErrorMessageResponseMandatoryRemark({intl: this.props.intl});
        const responseLabelShouldTakeTheWholeLineAndNotWrap =
            this.checkIfResponseLabelShouldTakeTheWholeLineAndNotWrap();

        if (this.props.PDFVersion) {
            if ((window as any).elements) {
                let element: any = {
                    type: 'RESPONSE_CHECKBOX',
                    label: this.getResponseLabel(),
                    order: this.getResponseOrderForPDF(),
                };
                messageRemark && (element.remark = messageRemark);
                this.isRemarkAllowed() && (element.remarkTextArea = true);
                let elementExist = (window as any).elements.find((el: any) => el.order === element.order);
                if (!elementExist) {
                    (window as any).elements.push(element);
                }
            }
            return this.renderPDFVersion();
        }

        return (
            <>
                {super.render()}
                {this.state.isForceResponseHelpModalOpen && (
                    <ModalAlertVersion2
                        message={
                            <div
                                /* eslint-disable-next-line react/no-danger */
                                dangerouslySetInnerHTML={{__html: this.state.messageLink || ''}}
                            />
                        }
                        title={`Help`}
                        close={this.closeModal}
                    />
                )}
                <Help
                    message={response.messageHelp}
                    language={this.props.questionnaireLanguage}
                    className="responseHelp"
                />
                <div
                    className={cx(
                        styles.checkboxWrap,
                        responseLabelShouldTakeTheWholeLineAndNotWrap ? styles.checkboxWrapWillFullWidthLabel : null
                    )}
                >
                    <label>
                        <input
                            type="checkbox"
                            id={responseKey}
                            checked={isCheckboxChecked}
                            onChange={this.onCheckboxChange}
                            disabled={this.state.isCurrentResponseDisabledDueToLogicDisabledOfOtherResponse}
                        />
                        <span
                            ref={el => {
                                if (el) el.innerHTML = responseLabel;
                            }}
                            style={
                                this.checkIfTranslationIsMissing()
                                    ? {color: 'red'}
                                    : this.state.isCurrentResponseDisabledDueToLogicDisabledOfOtherResponse
                                    ? {color: '#bfbfbf'}
                                    : {}
                            }
                        />
                    </label>
                    {messageRemark && (
                        <Remark
                            remark={messageRemark}
                            isRemarkTranslationMissing={this.checkIfResponseRemarkTranslationIsMissing()}
                            extraStyles={{maxWidth: 'calc(100% - 300px)'}}
                        />
                    )}
                    {showRemarkTextArea && (
                        <div className={cx(styles.remarkAllowedTextArea, messageRemark && styles.hasRemarkMessage)}>
                            <TextAreaInput
                                value={responseRemarkAnswer}
                                name={responseRemarkKey}
                                width={400}
                                rows={this.getRemarkTextAreaNumberOfRows()}
                                oldBackOfficeStyles={true}
                                oldBackOfficeStylesError={hasRemarkMandatoryError}
                                onChange={this.onRemarkChange}
                                error={remarkMandatoryErrorMessage}
                                isRemarkTextarea={true}
                            />
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default injectIntl(ResponseCheckbox);
