import React from 'react';
import styles from './ApplicationConsultationTabs.module.scss';
import cx from 'classnames';
import {injectIntl} from 'react-intl';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {faArrowLeft, faFile, faServer} from '@fortawesome/free-solid-svg-icons';
import {withRouter} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import StatusField from './StatusField';
import {joinParams, loadUrlParams as loadUrlParamsFn} from '~utils';

export const TAB = {
    GENERAL_SETTINGS: 'backOfficeFormGeneralSettings',
    QUESTIONS: 'backOfficeFormQuestions',
};

const OFFICE = {
    1: 'CPVO',
    21: 'NAK',
};
let statusSingleton = null;
let questionnaireIdSingleton = null;

class BackOfficeFormTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formId: null,
            params: null,
            status: null,
        };
    }

    componentDidMount() {
        this.loadUrlParams();
    }

    loadUrlParams = callback => {
        const params = loadUrlParamsFn();
        if (params['viewFormId']) {
            const formId = params['viewFormId'];
            this.setState(Object.assign({params}, !isNaN(formId) && {formId}), callback);
        }
    };

    goToFormDetails = () =>
        !this.props.loading &&
        this.props.history.push(
            `/backOfficeFormGeneralSettings${(this.state.params && `?${joinParams(this.state.params)}`) || ''}`
        );

    goToFormQuestions = () =>
        !this.props.loading &&
        this.props.history.push(
            `/backOfficeFormQuestions${(this.state.params && `?${joinParams(this.state.params)}`) || ''}`
        );

    render() {
        statusSingleton = this.props.formStatus || statusSingleton;
        questionnaireIdSingleton = this.props.formId || questionnaireIdSingleton;
        return (
            <>
                {statusSingleton && (
                    <div style={{float: 'left'}}>
                        <div style={{display: 'inline', paddingRight: 10, marginLeft: 15}}>{'Status:'}</div>
                        <StatusField
                            intl={this.props.intl}
                            statusId={statusSingleton}
                            validFrom={this.props.formValidFrom}
                        />
                    </div>
                )}
                {this.props.officeId ? (
                    <div style={{float: 'left'}}>
                        <div style={{display: 'inline', paddingRight: 5, marginLeft: 15}}>{'Office:'}</div>
                        <div style={{display: 'inline'}}>{OFFICE[this.props.officeId]}</div>
                    </div>
                ) : null}
                <div style={{float: 'right', marginRight: 10}}>
                    <FormFooterButton
                        color={buttonColor.whiteGray}
                        clickAction={this.props.onGoBackToSearch}
                        icon={faArrowLeft}
                    >{`Go back to search`}</FormFooterButton>
                </div>
                <div style={{clear: 'both'}} />
                <div className={styles.header}>
                    <div className={styles.leftPanel}>
                        <div
                            className={cx(
                                styles.menuItem,
                                this.props.tabId === TAB.GENERAL_SETTINGS && styles.menuItemActive
                            )}
                            onClick={this.goToFormDetails}
                        >
                            <div className={styles.menuIcon}>
                                <FontAwesomeIcon icon={faFile} color={this.props.loading ? 'gray' : 'green'} />
                            </div>
                            <div className={styles.menuLabel}>{`General settings`}</div>
                        </div>
                        {parseInt(this.props.formId) !== 0 ? (
                            <div
                                className={cx(
                                    styles.menuItem,
                                    this.props.tabId === TAB.QUESTIONS && styles.menuItemActive
                                )}
                                onClick={this.goToFormQuestions}
                            >
                                <div className={styles.menuIcon}>
                                    <FontAwesomeIcon icon={faServer} color={this.props.loading ? 'gray' : 'green'} />
                                </div>
                                <div className={styles.menuLabel}>{`Questions`}</div>
                            </div>
                        ) : null}
                        <div style={{clear: 'both'}} />
                    </div>
                    <div style={{clear: 'both'}} />
                </div>
            </>
        );
    }
}

export default withRouter(injectIntl(BackOfficeFormTabs));
