import React from 'react';
import styles from './Col.module.scss';
import cx from 'classnames';

interface IColProps {
    className?: any;
    children: any;
    sm?: 6;
    md?: 2 | 4 | 6 | 8;
    lg?: 2 | 3 | 4 | 6;
    mb?: 'sm' | 'md' | 'lg';
    key?: any;
}

function Col({className, children, sm, md, lg, mb, key}: IColProps) {
    let stylesAppliedSm, stylesAppliedMd, stylesAppliedLg, stylesAppliedMb;

    const smStyle: any = {
        6: 'colSm6',
    };

    const mdStyle: any = {
        2: 'colMd2',
        4: 'colMd4',
        6: 'colMd6',
        8: 'colMd8',
    };

    const lgStyle: any = {
        2: 'colLg2',
        3: 'colLg3',
        4: 'colLg4',
        6: 'colLg6',
    };

    const mbStyle: any = {
        sm: 'marginBottomSm',
        md: 'marginBottomMd',
        lg: 'marginBottomLg',
    };

    if (sm) {
        stylesAppliedSm = styles[smStyle[sm]];
    }
    if (md) {
        stylesAppliedMd = styles[mdStyle[md]];
    }
    if (lg) {
        stylesAppliedLg = styles[lgStyle[lg]];
    }
    if (mb) {
        stylesAppliedMb = styles[mbStyle[mb]];
    }

    return (
        <div
            className={cx(styles.col, className, stylesAppliedLg, stylesAppliedMd, stylesAppliedSm, stylesAppliedMb)}
            key={key}
        >
            {children}
        </div>
    );
}

export default Col;
