import React from 'react';
import {Button} from '../../../componentsFormV2';
import Checkbox from '~components/Checkbox';
import {ModalCustomVersion2} from '../../../commonModals';
import TextAreaInput from '~components/TextAreaInput';
import TextInput from '~components/TextInput';
import {injectIntl} from 'react-intl';

interface IProps {
    onButtonAddClick: any;
    close: any;
    intl: any;
    initialBatchNameValue: string;
    initialDenominationsValue: string;
    initialRequestExpertAdviceValue: boolean;
}

interface IState {
    checkboxRequestExpertAdviceValue: boolean;
    textAreaInputDenominationsValue: string;
    textInputBatchNameValue: string;
}

class VFModalTestDenominationsSubmitBatchForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            checkboxRequestExpertAdviceValue: props.initialRequestExpertAdviceValue,
            textAreaInputDenominationsValue: props.initialDenominationsValue,
            textInputBatchNameValue: props.initialBatchNameValue,
        };
    }

    onTextInputBatchNameChange = ({target: {value: textInputBatchNameValue}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({textInputBatchNameValue});

    onTextAreaInputDenominationsChange = ({
        target: {value: textAreaInputDenominationsValue},
    }: React.ChangeEvent<HTMLInputElement>) => this.setState({textAreaInputDenominationsValue});

    onCheckboxRequestExpertAdviceClick = () =>
        this.setState(prev => ({checkboxRequestExpertAdviceValue: !prev.checkboxRequestExpertAdviceValue}));

    onButtonAddClick = () =>
        this.props.onButtonAddClick({
            textAreaInputDenominationsValue: this.state.textAreaInputDenominationsValue,
            textInputBatchNameValue: this.state.textInputBatchNameValue,
            checkboxRequestExpertAdviceValue: this.state.checkboxRequestExpertAdviceValue,
        });

    render() {
        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={`Submit batch`}
                body={
                    <>
                        <TextInput
                            double={true}
                            label={`Batch Name`}
                            onChange={this.onTextInputBatchNameChange}
                            popOverText={`The search will find all applications whose code starts with the entered terms.`}
                            value={this.state.textInputBatchNameValue}
                        />
                        <div style={{clear: 'both'}} />
                        <TextAreaInput
                            label="Copy and paste your list of denominations"
                            onChange={this.onTextAreaInputDenominationsChange}
                            placeholder={`One record per line including the tested denomination, Species code, Breeder's reference, breeder name and Remark.
Example :
DENOMINATION1;SPECIES CODE1;BREEDER REF;BREEDER NAME
DENOMINATION2;SPECIES CODE2 BREEDER REF;BREEDER NAME;REMARK2
DENOMINATION3;SPECIES CODE3; BREEDER REF;BREEDER NAME;REMARK3`}
                            rows={10}
                            value={this.state.textAreaInputDenominationsValue}
                            width={800}
                            popOverText={`Enter your test data using the indicated format.`}
                        />
                        <Checkbox
                            clickAction={this.onCheckboxRequestExpertAdviceClick}
                            triple={true}
                            label={`Request for CPVO expert advice (only formal requests)`}
                            value={this.state.checkboxRequestExpertAdviceValue}
                        />
                        <div style={{clear: 'both'}} />
                    </>
                }
                footer={
                    <>
                        <Button clickAction={this.props.close} variation={'secondary'}>{`Close`}</Button>
                        <Button clickAction={this.onButtonAddClick}>{`Add`}</Button>
                    </>
                }
            />
        );
    }
}

export default injectIntl(VFModalTestDenominationsSubmitBatchForm);
