import React from 'react';
import CustomTable from '~components/CustomTable';
import {
    ICommunicationMessageClientRead,
    ICommunicationMessageInternal,
    ICommunicationMessageInternalRead,
} from '../../types';
import {RESULT_FIELDS_ALL_INBOX_CPVO, RESULT_FIELDS_DEFAULT_INBOX_CPVO} from '../CCInternalInbox/CCInternalInbox';
import CCInternalBackOfficeActionButtons from './CCInternalBackOfficeActions';
import ApplicationNumbersMultiple from '../../components/ApplicationNumbersMultiple';
import {FORMAT_DATE_TIME_EASY} from '../../components/FormatFunctions';
import CPVOIcon from '../../shared/img/cpvo.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile, faUser} from '@fortawesome/free-solid-svg-icons';
import {injectIntl} from 'react-intl';
import {
    apiCommunicationCentreFixSummary,
    apiCommunicationCentreInternalBackOfficeSearch,
} from './CCInternalBackOfficeService';
import {ModalAlertVersion2, ModalApplicationNumbers, ModalConfirmVersion2} from '../../commonModals';
import CCModalInternalRead, {TABS} from '../CCModalInternalRead/CCModalInternalRead';
import ModalPreview from '../CommunicationCentreInternal/modals/ModalPreview';
import CCModalClientRead from '../CCModalClientRead/CCModalClientRead';
import {Footer, HeaderLoading, HeaderLogoMenu, HeaderTitleAndVersion} from '../../componentsLayout';
import NavigationCommunicationCentre from '../../shared/NavigationCommunicationCentre';
import MainWrapper from '../../componentsLayout/MainWrapper';
import {trackPageView} from '../../utils';

const DEFAULT_CRITERIA = {
    pageNumber: 1,
    pageSize: 10,
    order: 'name',
    reverse: true,
    refresh: false,
};

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

type TKeyOfDefaultCriteria = keyof TDefaultCriteria;

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    count: number;
    criteria: any;
    isModalConfirmFixSummaryOpen: number | false;
    loading: number;
    modalInternalMessageCompose: boolean;
    timestamp: number;
    modalApplicationNumbers: string[];
    messages: ICommunicationMessageInternal[];
    modalAlertRepairPDFSuccess: boolean;
    modalClientMessageRead: number | false;
    modalInternalMessageRead: number | false;
    modalInternalMessageReadInitialActiveTab: string;
    modalPreviewMessage: ICommunicationMessageInternalRead | ICommunicationMessageClientRead | null;
    modalPreviewURL: string;
    isModalReplyPreviewOpen: boolean;
    isModalPreviewOpen: boolean;
}

class CCInternalBackOffice extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            count: 0,
            criteria: Object.assign({}, DEFAULT_CRITERIA),
            isModalPreviewOpen: false,
            isModalConfirmFixSummaryOpen: false,
            isModalReplyPreviewOpen: false,
            loading: 0,
            modalInternalMessageCompose: false,
            timestamp: Date.now(),
            modalClientMessageRead: false,
            modalInternalMessageRead: false,
            modalApplicationNumbers: [],
            modalAlertRepairPDFSuccess: false,
            modalInternalMessageReadInitialActiveTab: TABS.MESSAGE,
            modalPreviewURL: '',
            modalPreviewMessage: null,
            messages: [],
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'communicationCentreInternalBackOffice'});
        this.search(false, false);
    }

    parseCriteria = (criteria: TDefaultCriteria): TDefaultCriteria => {
        const parsedCriteria: {[key: string]: any} = {};
        Object.keys(criteria).map(key => {
            parsedCriteria[key] = criteria[key as TKeyOfDefaultCriteria];
        });
        return parsedCriteria as TDefaultCriteria;
    };

    buildParams = (parsedCriteria: TDefaultCriteria): void => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(
                i =>
                    parsedCriteria[i as TKeyOfDefaultCriteria] !== DEFAULT_CRITERIA[i as TKeyOfDefaultCriteria] &&
                    i !== 'refresh'
            )
            .map(key => `${key}=${parsedCriteria[key as TKeyOfDefaultCriteria]}`);
        this.props.history.replace(
            `/communicationCentreInternalBackOffice${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`
        );
    };

    search = (refresh?: boolean, urlLoad?: boolean, callBack?: () => any): void =>
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams(parsedCriteria);
                apiCommunicationCentreInternalBackOfficeSearch(parsedCriteria, DEFAULT_CRITERIA)
                    .then((JSONResponse: any) => {
                        if (JSONResponse && JSONResponse.data) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {...prev},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        messages: JSONResponse.data.messages,
                                        timestamp: Date.now(),
                                    },
                                    JSONResponse.data.count && {count: JSONResponse.data.count}
                                );
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`CommunicationCentreInternalCPVOTemplates: ${error.message}`]);
                    })
                    .then(() => {
                        this.setState(prev => ({loading: prev.loading - 1}));
                        callBack && callBack();
                    });
            }
        );

    updateCriteriaValue = (
        criteriaValue: Partial<TDefaultCriteria>,
        callback?: () => any,
        refresh?: boolean,
        searchCallback?: () => any
    ): void => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = prev.criteria.order !== criteriaValue.order;
                reverseChanged = prev.criteria.reverse !== criteriaValue.reverse;
                return {criteria};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true, false, searchCallback);
            }
        );
    };

    openModalInternalMessageRead = (communicationId: number): void =>
        this.setState({modalInternalMessageRead: communicationId});

    closeModalInternalMessageRead = () =>
        this.setState(
            {
                modalInternalMessageRead: false,
                isModalPreviewOpen: false,
                modalPreviewURL: '',
                isModalReplyPreviewOpen: false,
                modalInternalMessageReadInitialActiveTab: TABS.MESSAGE,
            },
            () => this.search(true)
        );

    openModalClientMessageRead = (communicationId: number) => this.setState({modalClientMessageRead: communicationId});

    closeModalClientMessageRead = () =>
        this.setState({modalClientMessageRead: false, isModalPreviewOpen: false, modalPreviewURL: ''}, () =>
            this.search(true)
        );

    goToScreen = (screen: string): void => this.props.history.push(screen);

    formatTableMessages = (messages: ICommunicationMessageInternal[]) =>
        [...(messages || [])].map(el => ({
            ...el,
            lastUpdateDate: el.sentDate,
            receivedDate: el.sentDate,
            to: el.from,
        }));

    openModalInternalMessageCompose = (): void => this.setState({modalInternalMessageCompose: true});

    setModalPreviewURL = (URL: any) => this.setState({modalPreviewURL: URL});

    closeModalApplicationNumbers = () => this.setState({modalApplicationNumbers: []});

    closeModalAlertRepairPDFSuccess = () => this.setState({modalAlertRepairPDFSuccess: false});

    openModalApplicationNumbers = (applicationNumbers: string[]) =>
        this.setState({modalApplicationNumbers: applicationNumbers});

    setModalPreviewMessage = (message: ICommunicationMessageInternalRead | ICommunicationMessageClientRead) =>
        this.setState({modalPreviewMessage: message});

    getTableFormatFunctions = () => ({
        addressees: (addressees: string[]) => (addressees || []).join(', '),
        applicationNumbers: (applicationNumbers: string[]) => (
            <ApplicationNumbersMultiple
                applicationNumbers={applicationNumbers}
                openModalApplicationNumbers={this.openModalApplicationNumbers}
            />
        ),
        deemedService: FORMAT_DATE_TIME_EASY,
        firstOpened: FORMAT_DATE_TIME_EASY,
        from: (from: string, {thirdPartyIdFrom}: ICommunicationMessageInternal) => (
            <>
                {thirdPartyIdFrom === 1 ? (
                    <img
                        src={CPVOIcon}
                        style={{
                            position: 'relative',
                            marginRight: -4,
                            left: -10,
                            width: 42,
                            height: 42,
                            verticalAlign: 'middle',
                        }}
                        alt="CPVO logo"
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faUser as any}
                        color={'gray'}
                        style={{width: 22, height: 25, verticalAlign: 'middle', marginRight: 10}}
                    />
                )}
                {from}
            </>
        ),
        lastUpdateDate: FORMAT_DATE_TIME_EASY,
        notificationDate: FORMAT_DATE_TIME_EASY,
        receivedDate: FORMAT_DATE_TIME_EASY,
        sentDate: FORMAT_DATE_TIME_EASY,
        numberOfAttachments: (numberOfAttachments: number) =>
            numberOfAttachments > 1 ? <FontAwesomeIcon icon={faFile as any} color={'green'} /> : <></>,
    });

    closeModalInternalMessageCompose = (): void =>
        this.setState({modalInternalMessageCompose: false}, () => this.search(true));

    onCloseModalConfirmFixSummaryClick = () => this.setState({isModalConfirmFixSummaryOpen: false});

    onTableIconFixSummaryClick = ({communicationId}: ICommunicationMessageInternal) =>
        this.setState({isModalConfirmFixSummaryOpen: communicationId});

    onTableRowClick = (communicationId: number, rowObject: any): void => {
        const {thirdPartyIdFrom} = rowObject || {};
        this.setState({isModalPreviewOpen: true});
        if (thirdPartyIdFrom === 1) {
            this.openModalClientMessageRead(communicationId);
        } else {
            this.openModalInternalMessageRead(communicationId);
        }
    };

    onConfirmActionFixSummaryClick = () => {
        const communicationIdToFix = this.state.isModalConfirmFixSummaryOpen;
        if (communicationIdToFix) {
            this.onCloseModalConfirmFixSummaryClick();
            this.setState(
                prev => ({
                    loading: prev.loading + 1,
                }),
                () => {
                    apiCommunicationCentreFixSummary(communicationIdToFix)
                        .then((JSONResponse: any) => {
                            if (JSONResponse && JSONResponse.data === 'OK') {
                                this.search(false, false, () => this.setState({modalAlertRepairPDFSuccess: true}));
                            }
                        })
                        .then(() => this.setState(prev => ({loading: prev.loading - 1})));
                }
            );
        }
    };

    onModalReadButtonPreviewClick = () =>
        this.setState(prev => ({
            isModalPreviewOpen: !prev.isModalPreviewOpen,
        }));

    render() {
        const actions = CCInternalBackOfficeActionButtons(this.onTableIconFixSummaryClick);

        return (
            <>
                {(this.state.loading !== 0 && <HeaderLoading />) || null}
                {this.state.modalAlertRepairPDFSuccess ? (
                    <ModalAlertVersion2
                        title={`Success`}
                        message={`Message confirmation PDF was successfully rebuilt.`}
                        close={this.closeModalAlertRepairPDFSuccess}
                    />
                ) : null}
                {this.state.isModalConfirmFixSummaryOpen && (
                    <ModalConfirmVersion2
                        title={`Fix Summary`}
                        message={`Are you sure you want to rebuild the summary PDF for this message ?`}
                        buttonName={'Yes'}
                        action={this.onConfirmActionFixSummaryClick}
                        close={this.onCloseModalConfirmFixSummaryClick}
                    />
                )}
                {this.state.modalApplicationNumbers.length ? (
                    <ModalApplicationNumbers
                        applicationNumbers={this.state.modalApplicationNumbers}
                        close={this.closeModalApplicationNumbers}
                    />
                ) : null}
                {this.state.modalInternalMessageRead ? (
                    <CCModalInternalRead
                        close={this.closeModalInternalMessageRead}
                        communicationId={this.state.modalInternalMessageRead}
                        folder={null}
                        onButtonPreviewClick={this.onModalReadButtonPreviewClick}
                        isPreviewMode={this.state.isModalPreviewOpen}
                        modalPreviewURL={this.state.modalPreviewURL}
                        setModalPreviewURL={this.setModalPreviewURL}
                        setModalPreviewMessage={this.setModalPreviewMessage}
                        initialActiveTab={this.state.modalInternalMessageReadInitialActiveTab}
                    />
                ) : null}
                {this.state.modalClientMessageRead && (
                    <CCModalClientRead
                        close={this.closeModalClientMessageRead}
                        communicationId={this.state.modalClientMessageRead}
                        folder={null}
                        isPreviewMode={this.state.isModalPreviewOpen}
                        modalPreviewURL={this.state.modalPreviewURL}
                        setModalPreviewURL={this.setModalPreviewURL}
                        setModalPreviewMessage={this.setModalPreviewMessage}
                    />
                )}
                {this.state.isModalPreviewOpen ? (
                    <ModalPreview
                        modalPreviewURL={this.state.modalPreviewURL}
                        modalPreviewMessage={this.state.modalPreviewMessage}
                        modalReadCommunicationId={this.state.modalInternalMessageRead}
                        tableMessages={this.state.messages}
                        count={this.state.count}
                        currentPageNumber={this.state.criteria.pageNumber}
                        currentPageSize={this.state.criteria.pageSize}
                        hideNavButtons={true}
                    />
                ) : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Communication Centre`} />
                <NavigationCommunicationCentre
                    openModalMessageCompose={this.openModalInternalMessageCompose}
                    search={this.search}
                    updateCriteriaValue={this.updateCriteriaValue}
                />
                <MainWrapper>
                    <CustomTable
                        actions={actions}
                        bold={(rowObject: ICommunicationMessageInternal) => !rowObject.isRead}
                        count={this.state.count}
                        dataFilter={null}
                        defaultOrder={this.state.criteria.order}
                        filterFunctions={null}
                        formatFunctions={this.getTableFormatFunctions()}
                        hideExcelButton={true}
                        id={'communicationId'}
                        intl={this.props.intl}
                        pagination={true}
                        pageNumber={this.state.criteria.pageNumber}
                        pageSize={this.state.criteria.pageSize}
                        resultFieldsDefault={RESULT_FIELDS_DEFAULT_INBOX_CPVO}
                        resultFieldsAll={RESULT_FIELDS_ALL_INBOX_CPVO}
                        reverseOrder={this.state.criteria.reverse}
                        notSortable={['numberOfAttachments']}
                        rowClick={this.onTableRowClick}
                        timestampSelected={this.state.timestamp}
                        setLastCursor={null}
                        timestamp={this.state.timestamp}
                        tableName={'communicationCentreInternalInbox'}
                        tableType={'OBJECT'}
                        tableSource={this.formatTableMessages(this.state.messages)}
                        updateCriteriaValue={this.updateCriteriaValue}
                        version={2}
                    />
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(CCInternalBackOffice);
