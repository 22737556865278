import React from 'react';
import cx from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile, faServer} from '@fortawesome/free-solid-svg-icons';
import styles from '~shared/ApplicationConsultationTabs.module.scss';

export const RESPONSE_MODAL_DETAILS_TABS = {
    GENERAL_INFORMATION: 'GENERAL_INFORMATION',
    LOGICS: 'LOGICS',
};

interface IProps {
    currentTab: any;
    onChange: any;
    responseId: number;
}

interface IState {}

export default class ResponseModalDetailsTabs extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    render() {
        const {onChange, currentTab} = this.props;
        return (
            <React.Fragment>
                <div className={styles.header}>
                    <div className={styles.leftPanel}>
                        <div
                            className={cx(
                                styles.menuItem,
                                currentTab === RESPONSE_MODAL_DETAILS_TABS.GENERAL_INFORMATION && styles.menuItemActive
                            )}
                            onClick={onChange(RESPONSE_MODAL_DETAILS_TABS.GENERAL_INFORMATION)}
                        >
                            <div className={styles.menuIcon}>
                                <FontAwesomeIcon icon={faFile as any} color={'green'} />
                            </div>
                            <div className={styles.menuLabel}>{`General information`}</div>
                        </div>
                        {this.props.responseId !== 0 && (
                            <div
                                className={cx(
                                    styles.menuItem,
                                    currentTab === RESPONSE_MODAL_DETAILS_TABS.LOGICS && styles.menuItemActive
                                )}
                                onClick={onChange(RESPONSE_MODAL_DETAILS_TABS.LOGICS)}
                            >
                                <div className={styles.menuIcon}>
                                    <FontAwesomeIcon icon={faServer as any} color={'green'} />
                                </div>
                                <div className={styles.menuLabel}>{`Logics`}</div>
                            </div>
                        )}
                        <div style={{clear: 'both'}} />
                    </div>
                    <div style={{clear: 'both'}} />
                </div>
            </React.Fragment>
        );
    }
}
