import React, {useState} from 'react';
import cx from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faListOl, faPrint, faRedo, faRocket, faSpinner} from '@fortawesome/free-solid-svg-icons';
import styles from '../BackOfficeRequests.module.scss';
import stylesCustomTable from './BackOfficeRequestsCustomTableActionButtons.module.scss';
import {API_SERVERLESS, getFetch} from '~commonApi';
import {Popover} from 'react-tiny-popover';
import {saveAs} from 'file-saver';
import {isUserOfficeCPVO} from '../../../utils';
import {ModalConfirmVersion2} from '../../../commonModals';
import {
    apiGetBackOfficeApplicationRequestVersions,
    apiGetBackOfficeContacts,
} from './BackOfficeRequestsCustomTableActionButtonsService';
import {HeaderLoading} from '../../../componentsLayout';
import {getPreSignedURLFetchRequest} from '../../../utils/requests';
import {formatDateEasy} from '../../../components/FormatFunctions';

function apiBackOfficeApplicationRequestFormsAndAttachments({demandeId, applicationRequestCode} = {}) {
    let params = '';
    applicationRequestCode && (params = `applicationRequestCode=${applicationRequestCode}`);
    demandeId && (params = `applicationRequestId=${demandeId}`);
    return getFetch(`${API_SERVERLESS}/backOffice/v3/applicationRequestFormsAndAttachments?${params}`, true);
}

function apiDocumentDownload({filename, applicationRequestCode} = {}) {
    return getFetch(
        `${API_SERVERLESS}/online/v3/attachmentDownload/${applicationRequestCode}/${encodeURIComponent(filename)}`,
        true
    );
}

function downloadFile({filename, applicationRequestCode} = {}) {
    apiDocumentDownload({filename, applicationRequestCode}).then(response => {
        if (response && response.signedUrl) {
            const {signedUrl} = response;
            getPreSignedURLFetchRequest(signedUrl)
                .then(response => response.blob())
                .then(responseBlob => {
                    saveAs(responseBlob, filename);
                });
        }
    });
}

const ICON_COLOR_ENABLED = '#2f923f';

const RenderIconFC = ({rowObject}) => {
    const {applicationRequestCode} = rowObject || {};
    const iconPrint = faPrint;
    const [attachments, setAttachments] = useState(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(null);
    const [loading, setLoading] = useState(null);
    const [isConfirmModalOpen, setConfirmModal] = useState(null);
    const [loadingBar, setLoadingBar] = useState(false);

    useState(() => {
        setLoading(true);
    }, []);

    const fetchApplicationAttachments = async () => {
        const jsonResponse = await apiBackOfficeApplicationRequestFormsAndAttachments({applicationRequestCode});
        setAttachments((jsonResponse && jsonResponse.documents) || null);
        setLoading(false);
    };

    const onPrintIconClick = () => {
        if (applicationRequestCode) {
            fetchApplicationAttachments();
            setIsPopoverOpen(true);
        }
    };

    const onDownloadFileClick = file => {
        const {type, filename, verificationCode} = file || {};
        const {applicationRequestCode} = rowObject || {};
        if (type === 'AF' || type === 'TQ') {
            let onlinePdfURL = `/onlinePdf?vc=${verificationCode}`;
            window.open(onlinePdfURL, '_blank');
        } else {
            downloadFile({filename, applicationRequestCode});
        }
    };

    const openModalConfirmRebuild = document => setConfirmModal(document);
    const closeModalConfirmRebuild = () => setConfirmModal(null);

    const rebuildPDF = () => {
        const {contactId, thirdPartyId, applicationRequestId, applicationRequestCode} = rowObject;
        // eslint-disable-next-line
        console.log(
            JSON.stringify({
                document: isConfirmModalOpen,
                contactId,
                thirdPartyId,
                applicationRequestId,
                applicationRequestCode,
            })
        );
        setLoadingBar(true);
        apiGetBackOfficeContacts({
            document: isConfirmModalOpen,
            contactId,
            thirdPartyId,
            applicationRequestId,
            applicationRequestCode,
        }).then(() => {
            setConfirmModal(null);
            setLoadingBar(false);
        });
    };

    const customContent = loading ? (
        <div className={stylesCustomTable.spinner}>
            <FontAwesomeIcon icon={faSpinner} color="green" />
        </div>
    ) : attachments && attachments.length ? (
        attachments.map(el => {
            const {filenameDisplay} = el || {};
            return (
                <>
                    <div
                        className={stylesCustomTable.customContentItem}
                        key={filenameDisplay}
                        onClick={() => onDownloadFileClick(el)}
                    >
                        {filenameDisplay}
                    </div>
                    {isUserOfficeCPVO && el.type !== 'DOCUMENT' && (
                        <div
                            className={stylesCustomTable.customContentItemPrint}
                            onClick={() => openModalConfirmRebuild(el)}
                        >
                            <FontAwesomeIcon icon={faRedo} color={'green'} />
                        </div>
                    )}
                </>
            );
        })
    ) : (
        <div className={styles.customContentItemNoAttachments}>No attachments found for this application request</div>
    );
    return (
        <>
            {isConfirmModalOpen !== null && (
                <ModalConfirmVersion2
                    title={`Rebuild PDF`}
                    message={`Are you sure you want to rebuild the PDF "${isConfirmModalOpen.filenameDisplay}"?`}
                    buttonName={'REBUILD'}
                    action={rebuildPDF}
                    close={closeModalConfirmRebuild}
                />
            )}
            <div className={cx(stylesCustomTable.iconWrap, isPopoverOpen ? stylesCustomTable.popoverIsOpen : null)}>
                <div className={stylesCustomTable.hoverMessage}>
                    {`PDF files and joint attachment(s) ready to download`}
                </div>
                <Popover
                    isOpen={isPopoverOpen}
                    positions={'top'}
                    onClickOutside={() => setIsPopoverOpen(false)}
                    content={() => (
                        <div
                            style={{
                                width: 600,
                                background: 'white',
                                border: '1px solid #2f923f',
                                borderRadius: 5,
                            }}
                        >
                            {customContent}
                        </div>
                    )}
                >
                    <div onClick={() => onPrintIconClick(rowObject)}>
                        <FontAwesomeIcon icon={iconPrint} color={ICON_COLOR_ENABLED} />
                    </div>
                </Popover>
            </div>
            {(loadingBar && <HeaderLoading />) || null}
        </>
    );
};

function renderIconPrint() {
    return {
        title: null,
        icon: rowObject => <RenderIconFC rowObject={rowObject} />,
        handler: () => null,
    };
}

const RenderIconApplicationRequestVersionsFC = ({rowObject}) => {
    const {applicationRequestCode} = rowObject || {};
    const [attachments, setAttachments] = useState(null);
    const [applicationRequestVersions, setApplicationRequestVersions] = useState(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(null);
    const [loading, setLoading] = useState(null);

    useState(() => {
        setLoading(true);
    }, []);

    const fetchApplicationRequestVersions = async () => {
        const jsonResponse = await apiGetBackOfficeApplicationRequestVersions(applicationRequestCode);
        setApplicationRequestVersions((jsonResponse && jsonResponse.data) || null);
        setLoading(false);
    };

    const onIconApplicationRequestVersionsClick = () => {
        if (applicationRequestCode) {
            fetchApplicationRequestVersions();
            setIsPopoverOpen(true);
        }
    };

    const fetchApplicationAttachments = async demandeId => {
        const jsonResponse = await apiBackOfficeApplicationRequestFormsAndAttachments({demandeId});
        setAttachments((jsonResponse && jsonResponse.documents) || null);
        setLoading(false);
    };

    const onApplicationRequestVersionClick = demandeId => {
        setLoading(true);
        if (applicationRequestCode) {
            fetchApplicationAttachments(demandeId);
            setIsPopoverOpen(true);
        }
    };

    const onDownloadFileClick = file => {
        const {type, filename, verificationCode} = file || {};
        const {applicationRequestCode} = rowObject || {};
        if (type === 'AF' || type === 'TQ') {
            let onlinePdfURL = `/onlinePdf?vc=${verificationCode}`;
            window.open(onlinePdfURL, '_blank');
        } else {
            downloadFile({filename, applicationRequestCode});
        }
    };

    const customContent = () => {
        if (loading) {
            return (
                <div className={stylesCustomTable.spinner}>
                    <FontAwesomeIcon icon={faSpinner} color="green" />
                </div>
            );
        }
        if (attachments) {
            if (attachments.length) {
                return attachments.map(el => {
                    const {filenameDisplay} = el || {};
                    return (
                        <>
                            <div
                                className={stylesCustomTable.customContentItem}
                                key={filenameDisplay}
                                onClick={() => onDownloadFileClick(el)}
                            >
                                {filenameDisplay}
                            </div>
                        </>
                    );
                });
            } else {
                return <div>{`No attachments found for this application request`}</div>;
            }
        }
        if (applicationRequestVersions) {
            if (applicationRequestVersions.length) {
                return applicationRequestVersions.map(({version, modifRemark, modifDate, demandeId}) => {
                    return (
                        <>
                            <div
                                className={stylesCustomTable.customContentItem}
                                key={version}
                                onClick={() => onApplicationRequestVersionClick(demandeId)}
                            >
                                Version: {version} - {formatDateEasy(modifDate)} - {modifRemark}
                            </div>
                        </>
                    );
                });
            } else {
                return <div>{`No versions found for this application request`}</div>;
            }
        }
    };

    return (
        <>
            <div className={cx(stylesCustomTable.iconWrap, isPopoverOpen ? stylesCustomTable.popoverIsOpen : null)}>
                <div className={stylesCustomTable.hoverMessage}>{`Application Requests Versions`}</div>
                <Popover
                    isOpen={isPopoverOpen}
                    positions={'top'}
                    onClickOutside={() => setIsPopoverOpen(false)}
                    content={() => (
                        <div
                            style={{
                                width: 600,
                                background: 'white',
                                border: '1px solid #2f923f',
                                borderRadius: 5,
                            }}
                        >
                            {customContent()}
                        </div>
                    )}
                >
                    <div onClick={() => onIconApplicationRequestVersionsClick(rowObject)}>
                        <FontAwesomeIcon icon={faListOl} color={ICON_COLOR_ENABLED} />
                    </div>
                </Popover>
            </div>
        </>
    );
};

function renderIconApplicationRequestVersions() {
    return {
        title: null,
        icon: rowObject => <RenderIconApplicationRequestVersionsFC rowObject={rowObject} />,
        handler: () => null,
    };
}

function renderButton(popupContent, handler, icon) {
    return {
        title: popupContent,
        icon: () => (
            <a data-content={popupContent}>
                <FontAwesomeIcon icon={icon} color={'green'} />
            </a>
        ),
        handler,
    };
}

export default function BackOfficeRequestsCustomTableActionButtons(onTableIconRepairClick) {
    const buttons = [];
    buttons.push(renderButton('Repair', (_, rowObject) => onTableIconRepairClick(rowObject), faRocket));
    buttons.push(renderIconPrint());
    buttons.push(renderIconApplicationRequestVersions());
    return buttons;
}
