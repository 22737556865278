import React from 'react';

interface IIconQuestionProps {
    style?: any;
    className?: any;
    color?: string;
}

export default function ({style: userStyle, color, className}: IIconQuestionProps) {
    return (
        <svg
            width="20"
            height="20"
            style={{...(userStyle || {})}}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_486_1741)">
                <path
                    d="M8.4375 7.39585C8.43754 7.12222 8.50943 6.85339 8.64599 6.61627C8.78255 6.37915 8.97899 6.18205 9.21565 6.04469C9.45231 5.90733 9.72089 5.83453 9.99452 5.83357C10.2682 5.83261 10.5372 5.90352 10.7749 6.03922C11.0125 6.17492 11.2103 6.37064 11.3485 6.60679C11.4867 6.84295 11.5605 7.11127 11.5625 7.38489C11.5644 7.65852 11.4945 7.92786 11.3596 8.16597C11.2248 8.40408 11.0298 8.6026 10.7942 8.74168C10.3983 8.97585 10 9.33168 10 9.79168V10.8333"
                    stroke={color || '#999999'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <path
                    d="M10.3333 13.3333C10.3333 13.5174 10.1841 13.6667 9.99999 13.6667C9.81589 13.6667 9.66666 13.5174 9.66666 13.3333C9.66666 13.1492 9.81589 13 9.99999 13C10.1841 13 10.3333 13.1492 10.3333 13.3333Z"
                    fill="#999999"
                    stroke={color || '#999999'}
                />
                <path
                    d="M5.83332 2.7817C7.09959 2.04911 8.53708 1.66444 9.99999 1.6667C14.6025 1.6667 18.3333 5.39753 18.3333 10C18.3333 14.6025 14.6025 18.3334 9.99999 18.3334C5.39749 18.3334 1.66666 14.6025 1.66666 10C1.66666 8.48253 2.07249 7.05836 2.78166 5.83336"
                    stroke={color || '#999999'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_486_1741">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
