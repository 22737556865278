import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import GroupOrTemplateModalDetailsTabs, {GROUP_OR_TEMPLATE_MODAL_DETAILS_TABS} from './GroupOrTemplateModalDetailsTabs';
import GroupOrTemplateModalDetailsGeneralInformation from './GroupOrTemplateModalDetailsGeneralInformation';
import {ModalCustomVersion2} from '../../../commonModals';
import ModalUsedInVersion2, {rowType} from '../../../commonModals/ModalUsedInVersion2';
import StatusField from '~shared/StatusField';
import {faLightbulb} from '@fortawesome/free-solid-svg-icons';
import {injectIntl} from 'react-intl';
import {IGroup} from '../../../types';

interface IProps {
    close: any;
    formId?: number | null;
    group: IGroup | null;
    intl: any;
    loadFormQuestionsJSONs: any;
    mainScreen?: string;
    modalQueuePush: any;
    modalQueuePop: any;
}

interface IState {
    currentTab: string;
    groupStatus: number | null;
    modalUsedIn: number | null | undefined;
    footer: any;
}

class GroupOrTemplateModalDetails extends React.Component<IProps, IState> {
    modalTitle = '';
    closeHandler: any;

    constructor(props: IProps) {
        super(props);
        this.modalTitle = props.group && props.group.isTemplate ? 'Template' : 'Group';
        this.state = {
            currentTab: GROUP_OR_TEMPLATE_MODAL_DETAILS_TABS.GENERAL_INFORMATION,
            groupStatus: (props.group && props.group.status) || null,
            modalUsedIn: null,
            footer: undefined,
        };
    }

    setCloseHandler = (handler: any) => (this.closeHandler = handler);

    close = (force?: boolean) => {
        if (force) {
            this.props.close();
        } else if (this.closeHandler && this.closeHandler()) {
            this.props.close();
        }
    };

    setGroupModalDetailsStatus = (status: number | null) => this.setState({groupStatus: status});

    showModalUsedIn = () =>
        this.setState({modalUsedIn: this.props.group?.isTemplate ? this.props.group.templateId : this.props.group?.id});

    closeModalUsedIn = () => this.setState({modalUsedIn: null});

    setRenderFooter = (footer: any) => this.setState({footer});

    onTabChange = (currentTab: string) => () => this.setState({currentTab});

    render() {
        return (
            <>
                <ModalCustomVersion2
                    close={this.close}
                    header={
                        <>
                            {this.modalTitle}
                            <div
                                style={{
                                    fontSize: 22,
                                    marginLeft: 10,
                                    border: '1px solid green',
                                    borderRadius: '50%',
                                    width: 26,
                                    height: 26,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: 2,
                                    cursor: 'pointer',
                                }}
                                onClick={this.showModalUsedIn}
                            >
                                <FontAwesomeIcon icon={faLightbulb as any} color="green" />
                            </div>
                            {this.state.groupStatus && (
                                <div style={{marginLeft: 20}}>
                                    <StatusField intl={this.props.intl} statusId={this.state.groupStatus} />
                                </div>
                            )}
                            <div style={{clear: 'both'}} />
                        </>
                    }
                    body={
                        <>
                            {' '}
                            <GroupOrTemplateModalDetailsTabs
                                onChange={this.onTabChange}
                                currentTab={this.state.currentTab}
                            />
                            <div>
                                {this.state.currentTab === GROUP_OR_TEMPLATE_MODAL_DETAILS_TABS.GENERAL_INFORMATION && (
                                    <GroupOrTemplateModalDetailsGeneralInformation
                                        close={this.close}
                                        formId={this.props.formId}
                                        group={this.props.group}
                                        setCloseHandler={this.setCloseHandler}
                                        setGroupModalDetailsStatus={this.setGroupModalDetailsStatus}
                                        loadFormQuestionsJSONs={this.props.loadFormQuestionsJSONs}
                                        mainScreen={this.props.mainScreen}
                                        modalQueuePush={this.props.modalQueuePush}
                                        modalQueuePop={this.props.modalQueuePop}
                                        setRenderFooter={this.setRenderFooter}
                                    />
                                )}
                            </div>
                        </>
                    }
                    footer={<>{this.state.footer}</>}
                />
                {this.state.modalUsedIn !== null && (
                    <ModalUsedInVersion2
                        elementId={this.state.modalUsedIn}
                        intl={this.props.intl}
                        type={this.props.group?.isTemplate ? rowType.TEMPLATE : rowType.GROUP}
                        close={this.closeModalUsedIn}
                    />
                )}
            </>
        );
    }
}

export default injectIntl(GroupOrTemplateModalDetails);
