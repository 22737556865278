import React from 'react';
import cx from 'classnames';
import stylesNew from './InputContainer.module.scss';
import stylesOld from './InputContainerOld.module.scss';

export default function ({
    children,
    double,
    triple,
    empty,
    checkbox,
    width,
    height,
    oneLine,
    full,
    oldBackOfficeStyles,
    twoInputsPerLine,
    isShortDisplay,
    noIcon,
    style: userStyle,
    size,
    half,
}) {
    let styles = oldBackOfficeStyles ? stylesOld : stylesNew;

    let sizeMap = {
        lg: 'Lg',
    };

    return (
        <div
            className={cx(
                styles.container,
                triple ? styles.triple : double ? styles.double : half ? styles.half : styles.simple,
                size && styles[`size${sizeMap[size]}`],
                noIcon && triple ? stylesNew.tripleNoIcon : noIcon && double ? stylesNew.doubleNoIcon : null,
                empty ? styles.empty : null,
                double ? null : checkbox ? styles.checkbox : null,
                oneLine && styles.oneLine,
                full && styles.full,
                isShortDisplay && styles.isShortDisplay,
                twoInputsPerLine && styles.twoInputsPerLine
            )}
            style={{height, maxWidth: width, ...(userStyle ? userStyle : {})}}
        >
            {children}
        </div>
    );
}
