import {getFetch, getFile, API_SERVERLESS} from '~commonApi';

export function apiFileLogs(zone) {
    return getFetch(`${API_SERVERLESS}/common/v3/cpvoLogs/${zone}`, true);
}

export function apiFileListLogs(zone, directory, server) {
    return getFetch(`${API_SERVERLESS}/common/v3/cpvoLogsCPVO/${zone}/${directory}`, true);
}

export function apiFileLogsDownload(zone, directory, filename, server) {
    return getFile(`${API_SERVERLESS}/common/v3/cpvoLogs/${zone}/${directory}/${encodeURIComponent(filename)}`, true);
}
