import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LoadingBar from '../components/LoadingBar';
import Navigation from '../componentsLayout/Navigation';
import {ArrowContainer, Popover} from 'react-tiny-popover';
import cx from 'classnames';
import {faBell, faBookmark, faChevronDown, faFolder, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {faBookmark as faBookmarkO} from '@fortawesome/free-regular-svg-icons';
import graphQLClientInstance from '../utils/axiosGraphQLClient';
import styles from './NavigationVarietyFinder.module.scss';
import {isAdvanced} from '../utils/utilsVarietyFinder';
import moment from 'moment';
import {millisecondsToDateFormatted} from '../utils';
import AdviceRequestBadge from '../screens/VFMyTests/AdviceRequestBadge';
import {HeaderLoading} from '../componentsLayout';
import VFModalDenominationTest from '../screens/VFModalDenominationTest/VFModalDenominationTest';

let clickMySearchesDiv = false;

let clickNotificationsDiv = false;

function NavigationVarietyFinder({onSearchLinkClick}: any) {
    const [searchList, setSearchList] = useState([]);
    const [loadingSearchList, setLoadingSearchList] = useState(false);
    const [isMySearchesPopoverOpen, setIsMySearchesPopoverOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [isNotificationsPopoverOpen, setIsNotificationsPopoverOpen] = useState(false);
    const [isModalDenominationTestOpen, setIsModalDenominationTestOpen] = useState<any>(false);
    const [loading, setLoading] = useState(false);

    const checkDateForNotifications = () => {
        const currentDate = moment.utc();
        const startDate = moment('08:00:00', 'HH:mm:ss', 'Europe/Brussels');
        const endDate = moment('18:00:00', 'HH:mm:ss', 'Europe/Brussels');
        return currentDate.isAfter(startDate) && currentDate.isBefore(endDate);
    };

    const fetchAndUpdateNotifications = () => {
        graphQLClientInstance.get('/api/v1/tests/notifications').then(JSONResponse => {
            if (JSONResponse && JSONResponse.data) {
                setNotifications(JSONResponse.data);
            }
        });
    };

    useEffect(() => {
        if (isAdvanced()) {
            fetchAndUpdateNotifications();
            const interval = setInterval(() => {
                if (checkDateForNotifications()) {
                    fetchAndUpdateNotifications();
                } else {
                    clearInterval(interval);
                }
            }, 600 * 1000);
            return () => clearInterval(interval);
        }
    }, []);

    const onClickNotificationsMenu = () => setIsNotificationsPopoverOpen(!isNotificationsPopoverOpen);

    const onClickOutsideNotificationsFunction = () => {
        if (clickNotificationsDiv) {
            clickNotificationsDiv = false;
        } else {
            // @ts-ignore
            window.thisIsNotificationsPopoverOpen = !window.thisIsNotificationsPopoverOpen;
            setIsNotificationsPopoverOpen(!isNotificationsPopoverOpen);
        }
    };

    const onClickNotificationsDiv = () => {
        clickNotificationsDiv = true;
        setIsNotificationsPopoverOpen(!isNotificationsPopoverOpen);
    };

    const onNotificationClick = (testid: any, markAsRead = true) => {
        setLoading(true);
        Promise.all([
            graphQLClientInstance.post('/api/v1/tests/fetch-by-ids', {test_ids: [testid]}),
            markAsRead
                ? graphQLClientInstance.patch('/api/v1/tests/notifications/read-unread', {
                      test_ids: [testid],
                      read_unread: 1,
                  })
                : null,
        ]).then(([JSONResponseTest]) => {
            const callback = () => {
                if (JSONResponseTest.data?.tests_list?.length) {
                    const JSONResponse = JSONResponseTest.data.tests_list[0];
                    const {
                        breederreference: breedersReference,
                        denomination,
                        species_name: speciesName,
                        test_date: testDate,
                        test_status: testStatus,
                        testid,
                    } = JSONResponse || {};
                    setLoading(false);
                    setIsModalDenominationTestOpen({
                        JSONResponse,
                        breedersReference,
                        denomination,
                        speciesName,
                        testDate: millisecondsToDateFormatted(testDate),
                        testStatus,
                        testid,
                    });
                }
            };
            if (markAsRead) {
                graphQLClientInstance.get('/api/v1/tests/notifications').then(JSONResponse => {
                    if (JSONResponse && JSONResponse.data) {
                        setNotifications(JSONResponse.data);
                        callback();
                    }
                });
            } else {
                callback();
            }
        });
    };

    const onCloseModalDenominationTest = (refresh: any) => {
        if (refresh) {
            const {testid} = isModalDenominationTestOpen;
            onNotificationClick(testid, false);
        } else {
            setIsModalDenominationTestOpen(false);
        }
    };

    const onButtonIconDeleteSearchClick = ({datatype}: any) => {
        setLoadingSearchList(true);
        graphQLClientInstance.delete(`/api/v1/users/searches/${datatype.replace(/#/g, '%')}`).then(JSONResponse => {
            if (JSONResponse && JSONResponse.data && JSONResponse.data.message === 'itemdeleted') {
                graphQLClientInstance('/api/v1/users/searches').then(JSONResponse => {
                    if (JSONResponse && JSONResponse.data) {
                        setSearchList(JSONResponse.data.Items);
                        setLoadingSearchList(false);
                    }
                });
            }
        });
    };

    const onSearchNameClick = (searchListItem: any) => onSearchLinkClick && onSearchLinkClick(searchListItem);

    const onClickMySearchesMenu = () => setIsMySearchesPopoverOpen(!isMySearchesPopoverOpen);

    const onClickMySearchesDiv = () => {
        clickMySearchesDiv = true;
        setIsMySearchesPopoverOpen(!isMySearchesPopoverOpen);
        setLoadingSearchList(true);
        graphQLClientInstance('/api/v1/users/searches').then(JSONResponse => {
            if (JSONResponse && JSONResponse.data) {
                setSearchList(JSONResponse.data.Items);
                setLoadingSearchList(false);
            }
        });
    };

    const onClickOutsideMySearchesFunction = () => {
        if (clickMySearchesDiv) {
            clickMySearchesDiv = false;
        } else {
            // @ts-ignore
            window.thisIsMySearchesPopoverOpen = !window.thisIsMySearchesPopoverOpen;
            setIsMySearchesPopoverOpen(!isMySearchesPopoverOpen);
        }
    };

    const onButtonIconDefaultSearchClick = (searchItem: any) => {
        setLoadingSearchList(true);
        graphQLClientInstance
            .patch(`/api/v1/users/searches/${searchItem.datatype.replace(/#/g, '%')}`, {
                ...searchItem,
                isDefault: true,
            })
            .then(JSONResponse => {
                if (JSONResponse && JSONResponse.data && JSONResponse.data.update) {
                    graphQLClientInstance('/api/v1/users/searches').then(JSONResponse => {
                        if (JSONResponse && JSONResponse.data) {
                            setSearchList(JSONResponse.data.Items);
                            setLoadingSearchList(false);
                        }
                    });
                }
            });
    };

    const ComponentMySearch = () => (
        <div style={{marginRight: 8, float: 'right', cursor: 'pointer'}} onClick={onClickMySearchesDiv}>
            <FontAwesomeIcon icon={faFolder as any} style={{marginRight: 10}} /> {`My searches`}
            <Popover
                isOpen={isMySearchesPopoverOpen}
                positions={'bottom'}
                onClickOutside={onClickOutsideMySearchesFunction}
                content={({position, childRect, popoverRect}) => (
                    <ArrowContainer
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={'white'}
                        arrowSize={5}
                        arrowStyle={{
                            opacity: 0.7,
                        }}
                    >
                        <div onClick={onClickMySearchesMenu}>
                            <div
                                className={styles.menuPopoverWrap}
                                style={{
                                    background: 'white',
                                    color: 'black',
                                    borderRadius: '4px',
                                    zIndex: 99,
                                    position: 'relative',
                                    borderStyle: 'solid',
                                    borderWidth: '3px',
                                    borderColor: '#ccc',
                                    marginTop: 7,
                                }}
                            >
                                {loadingSearchList ? (
                                    <div style={{margin: '10px 20px'}}>
                                        <LoadingBar />
                                    </div>
                                ) : searchList.length ? (
                                    <ul className={styles.menuContainer}>
                                        {searchList.map((searchListItem: any, i) => (
                                            <li className={styles.bookmarkItem} key={Date.now()}>
                                                <div className={styles.bookmarkItemLeft}>
                                                    <div
                                                        className={cx(
                                                            styles.iconBookmarkWrap,
                                                            styles.buttonTransparent
                                                        )}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            onButtonIconDefaultSearchClick(searchListItem);
                                                        }}
                                                    >
                                                        <span className={styles.iconBookmarkDefaultState}>
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    searchListItem.isDefault
                                                                        ? faBookmark
                                                                        : (faBookmarkO as any)
                                                                }
                                                            />
                                                        </span>
                                                        <span className={styles.iconBookmarkHoverState}>
                                                            <FontAwesomeIcon icon={faBookmark as any} />
                                                        </span>
                                                    </div>
                                                    <span
                                                        className={styles.bookmarkItemSearchLink}
                                                        onClick={() => onSearchNameClick(searchListItem)}
                                                    >{`${searchListItem.searchName}`}</span>
                                                </div>
                                                <div className={styles.bookmarkItemRight}>
                                                    <button
                                                        className={styles.buttonTransparent}
                                                        title={`Delete search`}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            onButtonIconDeleteSearchClick(searchListItem);
                                                        }}
                                                        type={'button'}
                                                    >
                                                        <FontAwesomeIcon icon={faTrashAlt as any} />
                                                    </button>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <div style={{padding: '8px 20px'}}>No saved searches</div>
                                )}
                            </div>
                        </div>
                    </ArrowContainer>
                )}
            >
                <div style={{display: 'inline-block', marginLeft: 5}}>
                    <div className={styles.menuCompanyIconRight}>
                        <FontAwesomeIcon icon={faChevronDown as any} />
                    </div>
                </div>
            </Popover>
        </div>
    );

    const ComponentNotifications = () => (
        <div className={cx(styles.menuItem)} onClick={onClickNotificationsDiv}>
            <Popover
                isOpen={isNotificationsPopoverOpen}
                positions={'bottom'}
                onClickOutside={onClickOutsideNotificationsFunction}
                content={({position, childRect, popoverRect}: any) => (
                    <ArrowContainer
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={'white'}
                        arrowSize={5}
                        arrowStyle={{
                            opacity: 0.7,
                        }}
                    >
                        <div onClick={onClickNotificationsMenu}>
                            <div
                                className={styles.menuPopoverWrap}
                                style={{
                                    background: 'white',
                                    color: 'black',
                                    borderRadius: '4px',
                                    zIndex: 99,
                                    position: 'relative',
                                    borderStyle: 'solid',
                                    borderWidth: '3px',
                                    borderColor: '#ccc',
                                    marginTop: 7,
                                }}
                            >
                                <ul className={styles.menuContainer}>
                                    {notifications.map(({denomination, test_status: testStatus, testid}) => (
                                        <li
                                            key={testid}
                                            onClick={() => onNotificationClick(testid)}
                                            className={styles.notificationListItem}
                                        >
                                            <span
                                                style={{
                                                    marginRight: 10,
                                                    fontSize: 18,
                                                }}
                                            >
                                                {denomination}
                                            </span>
                                            <AdviceRequestBadge text={testStatus} style={{display: 'inline-block'}} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </ArrowContainer>
                )}
            >
                <div style={{display: 'inline-block', marginLeft: 5, position: 'relative'}}>
                    <div className={styles.menuCompanyIconRight}>
                        <FontAwesomeIcon icon={faBell as any} />
                        <span className={styles.notificationNumber}>{notifications.length}</span>
                    </div>
                </div>
            </Popover>
        </div>
    );

    const linksRight = [
        {to: '/myalerts', title: 'My Alerts'},
        {to: '/mytests', title: 'My Tests'},
        {
            Component: ComponentMySearch,
        },
    ];

    if (notifications.length) {
        linksRight.push({
            Component: ComponentNotifications,
        });
    }

    return (
        <>
            {loading ? <HeaderLoading /> : null}
            {isModalDenominationTestOpen ? (
                <VFModalDenominationTest
                    close={onCloseModalDenominationTest}
                    hideNavigation={true}
                    rowObject={isModalDenominationTestOpen}
                    currentIndex={0}
                    onButtonNextClick={undefined}
                    onButtonPreviousClick={undefined}
                    totalCount={0}
                />
            ) : null}
            <Navigation
                links={[
                    {to: '/varieties', title: 'Search varieties'},
                    {to: '/denominations', title: 'Test Denominations'},
                    {to: '/species', title: 'Species'},
                    {to: '/classes', title: 'UPOV Classes'},
                    {to: '/registers', title: 'Registers'},
                ]}
                linksRight={linksRight}
            />
        </>
    );
}

export default NavigationVarietyFinder;
