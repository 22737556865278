import {API_SERVERLESS, getFetch} from '~commonApi';

export function apiPublications(criteria: any, DEFAULT_CRITERIA: any = {}) {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return getFetch(`${API_SERVERLESS}/mypvr/v3/publications?${requestParams.join('&')}`);
}

export function apiExaminationOffices(): Promise<{data: string[]}> {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/publication/examinationOffices`);
}

export function apiCountries(): Promise<{data: string[]}> {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/publication/countries`);
}

export function apiGenus(): Promise<any> {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/publication/genus`);
}
