import React from 'react';
import {Button} from '../../../componentsFormV2';
import Checkbox from '~components/Checkbox';
import {ModalCustomVersion2} from '../../../commonModals';
import SelectInput from '~components/SelectInput';

interface IProps {
    close: any;
    onDownloadPDF: any;
}

interface IState {
    checkboxIncludeEUIPOTrademarksValue: boolean;
    selectInputNumberOfResultsValue: string;
}

class ModalVFMyTestsDownloadSelected extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            checkboxIncludeEUIPOTrademarksValue: false,
            selectInputNumberOfResultsValue: '20',
        };
    }

    onSelectInputNumberOfResultsChange = ({
        target: {value: selectInputNumberOfResultsValue},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({selectInputNumberOfResultsValue});

    onCheckboxIncludeEUIPOTrademarksClick = () =>
        this.setState(prev => ({checkboxIncludeEUIPOTrademarksValue: !prev.checkboxIncludeEUIPOTrademarksValue}));

    onButtonDownloadPDFClick = () =>
        this.props.onDownloadPDF({
            checkboxIncludeEUIPOTrademarksValue: this.state.checkboxIncludeEUIPOTrademarksValue,
            numberOfResults: this.state.selectInputNumberOfResultsValue,
        });

    render() {
        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={`PDF Download of test results`}
                body={
                    <div>
                        <p>{`By default, only the first 20 varieties are exported and EUIPO Trademarks are excluded.`}</p>
                        <p>{`You can change these options below`}</p>
                        <SelectInput
                            label={``}
                            single={true}
                            value={this.state.selectInputNumberOfResultsValue}
                            onChange={this.onSelectInputNumberOfResultsChange}
                            list={[
                                {id: '20', value: 'Top 20'},
                                {id: '50', value: 'Top 50'},
                                {id: '-1', value: 'All'},
                            ]}
                            notAll={true}
                        />
                        <div style={{marginTop: -6, float: 'left'}}>
                            <Checkbox
                                clickAction={this.onCheckboxIncludeEUIPOTrademarksClick}
                                simple={true}
                                label={`Include EUIPO Trademarks`}
                                value={this.state.checkboxIncludeEUIPOTrademarksValue}
                                width={500}
                            />
                        </div>
                        <div style={{clear: 'both'}} />
                    </div>
                }
                footer={
                    <>
                        <Button clickAction={this.props.close} variation={'secondary'}>
                            {`Close`}
                        </Button>
                        <Button clickAction={this.onButtonDownloadPDFClick}>{`Download PDF`}</Button>
                    </>
                }
            />
        );
    }
}

export default ModalVFMyTestsDownloadSelected;
