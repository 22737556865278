import {API_SERVERLESS, getFetch} from '~commonApi';

export function apiHome(administrator) {
    return getFetch(`${API_SERVERLESS}/common/v3/home${self.username ? `?mock=${self.username}` : ''}`, true).then(
        jsonResponse => {
            LOG`administrator: ${administrator}`;
            if (
                (jsonResponse && jsonResponse.administrator) ||
                jsonResponse.sites ||
                jsonResponse.companies ||
                jsonResponse.notice
            ) {
                return Object.assign(
                    {},
                    {
                        administrator: jsonResponse.administrator,
                        sites: jsonResponse.sites,
                        companies: jsonResponse.companies,
                        notice: jsonResponse.notice,
                    },
                    !jsonResponse.administrator && administrator ? {impersonate: true} : {}
                );
            }
        }
    );
}
