import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {ILocalStorageImpersonateItem} from './Welcome.utils';

function renderButton(popupContent: any, handler: any, icon: any) {
    return {
        title: popupContent,
        icon: () => {
            return (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon} />
                </a>
            );
        },
        handler,
    };
}

export default function ModalWelcomeStoredImpersonatedUsersActionButtons(onTableIconDeleteClick: any) {
    const buttons = [];
    buttons.push(
        renderButton(
            'Delete',
            (_: any, rowObject: ILocalStorageImpersonateItem) => onTableIconDeleteClick(rowObject),
            faTrash
        )
    );
    return buttons;
}
