import React from 'react';

interface IIconCloseFillProps {
    style?: any;
    className?: any;
    color?: string;
}

function CloseFill({style: userStyle, color, className}: IIconCloseFillProps) {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_754_1182"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="22"
                height="22"
                style={{...(userStyle || {})}}
                className={className}
            >
                <path
                    d="M11 21C16.523 21 21 16.523 21 11C21 5.477 16.523 1 11 1C5.477 1 1 5.477 1 11C1 16.523 5.477 21 11 21Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.8285 8.17139L8.17151 13.8284M8.17151 8.17139L13.8285 13.8284"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </mask>
            <g mask="url(#mask0_754_1182)">
                <path d="M-1 -1H23V23H-1V-1Z" fill={color || '#656565'} />
            </g>
        </svg>
    );
}

export default CloseFill;
