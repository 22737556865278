const LOCAL_STORAGE_LOGIN_KEY = 'logins';

export interface ILocalStorageLoginItem {
    username: string;
    lastLogin: number;
}

const createLoginItem = (username: string) => ({username, lastLogin: Date.now()});

export const updateLocalStorageLogins = (username: string) => {
    let loginsArray: ILocalStorageLoginItem[] = [];
    let storedLogins = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LOGIN_KEY) || '{}');
    if (!storedLogins || !Array.isArray(storedLogins)) {
        loginsArray.push(createLoginItem(username));
    } else {
        loginsArray = [...storedLogins];
        let loginIndex = storedLogins.findIndex(login => login.username === username);
        if (loginIndex === -1) {
            loginsArray.push(createLoginItem(username));
        } else {
            loginsArray[loginIndex] = createLoginItem(username);
        }
    }
    localStorage.setItem(LOCAL_STORAGE_LOGIN_KEY, JSON.stringify(loginsArray));
};

export const getLocalStorageLogins = () => {
    const USERNAME_WEBMASTER = 'webmaster@cpvo.europa.eu';
    let logins = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LOGIN_KEY) || '{}');
    logins = Array.isArray(logins) ? logins : [];
    let webmasterIndex = logins.findIndex((login: ILocalStorageLoginItem) => login.username === USERNAME_WEBMASTER);
    if (webmasterIndex >= 0) {
        deleteLocalStorageLogin(USERNAME_WEBMASTER);
        logins = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LOGIN_KEY) || '{}');
        logins = Array.isArray(logins) ? logins : [];
    }
    return logins.sort((a: ILocalStorageLoginItem, b: ILocalStorageLoginItem) => b.lastLogin - a.lastLogin);
};

export const deleteLocalStorageLogin = (username: string) => {
    let loginsArray = JSON.parse(localStorage.getItem(LOCAL_STORAGE_LOGIN_KEY) || '{}');
    let loginIndex = loginsArray.findIndex((login: ILocalStorageLoginItem) => login.username === username);
    loginsArray.splice(loginIndex, 1);
    localStorage.setItem(LOCAL_STORAGE_LOGIN_KEY, JSON.stringify(loginsArray));
};
