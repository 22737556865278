import React from 'react';
import {FORMAT_DATE, formatDateEasy, formatDateTimeEasy, reformatDateEasy} from '~components/FormatFunctions';
import CustomTable from '~components/CustomTable';
import DateInput from '~components/DateInput';
import Empty from '~components/Empty';
import {IChapterItem, IRegister} from '../../types';
import InputLink from '~components/InputLink';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import Title from '~components/Title';
import {apiPublicationCharpters, apiSpecies} from '../../commonApi/static';
import {apiApplicationSearch} from './MyPVRMyApplicationsService';
import cx from 'classnames';
import {faChevronDown, faChevronRight, faInfo} from '@fortawesome/free-solid-svg-icons';
import {getPreSignedURLFetchRequest} from '../../utils/requests';
import {getExcelSignedURLFetchRequest} from '../../commonApi/download';
import {injectIntl} from 'react-intl';
import {saveAs} from 'file-saver';
import styles from './MyPVRMyApplications.module.scss';
import {withRouter} from 'react-router-dom';
import {ModalStatusInfoVersion2} from '../../commonModals';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
} from '../../componentsLayout';
import NavigationMyPVR from '../../shared/NavigationMyPVR';
import {Button} from '../../componentsFormV2';
import MainWrapper from '../../componentsLayout/MainWrapper';
import {trackPageView} from '../../utils';

const ALL = 'All';

const APPEAL_STATUS = {
    CLOSED: 'Appeal closed',
};

const PUBLIC_RESULT_FIELDS_ALL = [
    'denomination',
    'speciesName',
    'grantNumber',
    'grantingDate',
    'applicationStatus',
    'applicants',
    'applicationDate',
    'applicationNumber',
    'specieUpovId',
    'firstInsideEU',
    'firstOutsideEU',
    'arrivalDate',
    'groupVarietal',
    'breedersReference',
    'nationalApplicationDate',
    'nationalReference',
    'expirationDate',
    'titleStatus',
    'withdrawalDate',
];

const PUBLIC_RESULT_FIELDS_DEFAULT = [
    'denomination',
    'speciesName',
    'grantNumber',
    'applicationStatus',
    'applicants',
    'applicationDate',
    'applicationNumber',
    'titleStatus',
];

const advancedCriteria = [
    'grantNumber',
    'applicationStatus',
    'onlineAppNumber',
    'applicants',
    'applicationDateFrom',
    'applicationDateTo',
    'grantDateFrom',
    'grantDateTo',
    'withdrawalDateFrom',
    'withdrawalDateTo',
    'publicationYear',
    'gazetteIssue',
    'chapterId',
    'clientName',
];

const statusOptions = [
    {id: 'A', value: 'Active application'},
    {id: 'W', value: 'Withdrawn'},
    {id: 'R', value: 'Refused'},
    {id: 'G', value: 'Granted'},
    {id: 'T', value: 'Terminated Right'},
    {id: 'P,M,U', value: 'Reception ongoing'},
];

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

type TKeyOfDefaultCriteria = keyof TDefaultCriteria;

const DEFAULT_CRITERIA = {
    denomination: '',
    denominationFilter: 'equals',
    breedersReference: '',
    speciesName: '',
    speciesNameFilter: 'starts',
    speciesIds: '',
    applicationNumber: '',
    grantNumber: '',
    applicationStatus: ALL,
    onlineAppNumber: '',
    applicants: '',
    applicationDateFrom: '',
    applicationDateTo: '',
    grantDateFrom: '',
    grantDateTo: '',
    withdrawalDateFrom: '',
    withdrawalDateTo: '',
    publicationYear: ALL,
    gazetteIssue: ALL,
    chapterId: ALL,
    clientName: '',
    //
    pageNumber: 1,
    pageSize: 10,
    order: 'applicationNumber',
    reverse: false,
    refresh: false,
    excel: false,
};

const DATEFORMAT_CRITERIA_KEYS = [
    'applicationDateFrom',
    'applicationDateTo',
    'grantDateFrom',
    'grantDateTo',
    'withdrawalDateFrom',
    'withdrawalDateTo',
];

const DEFAULT_COUNT = 0;

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    advancedCriteriaCount: number;
    advancedOpened: boolean;
    count: number;
    criteria: TDefaultCriteria;
    criteriaCount: number;
    errorTitle: string;
    errorMessage: string;
    loading: number;
    modalScreen: boolean;
    publicationChapterList: IChapterItem[];
    registers: IRegister[] | null;
    selectedSpecies: {[key: string]: string};
    speciesListByIdName: {ID: any; NAME: string}[];
    speciesNameHash: any;
    subjectToAnAppeal: boolean;
    timestamp: number;
}

class MyPVRMyApplications extends React.Component<IProps, IState> {
    initialCriteria: any;
    setSelectedFunction: any;

    constructor(props: IProps) {
        super(props);
        this.state = {
            advancedCriteriaCount: 0,
            advancedOpened: false,
            count: DEFAULT_COUNT,
            criteria: DEFAULT_CRITERIA,
            criteriaCount: 0,
            errorTitle: '',
            errorMessage: '',
            loading: 0,
            modalScreen: false,
            publicationChapterList: [],
            registers: null,
            selectedSpecies: {},
            speciesListByIdName: [],
            speciesNameHash: null,
            subjectToAnAppeal: false,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'myApplications'});
        this.loadJSONs();
        this.loadUrlParams();
    }

    loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const criteria: any = Object.assign({}, this.initialCriteria);
            const params: any = domainArray.pop();
            params.split('&').forEach((param: any) => {
                const paramElements = param.split('=');
                if (paramElements.length === 2) {
                    const key = paramElements[0];
                    criteria[key] = decodeURIComponent(paramElements[1]);
                }
            });
            if (Object.keys(criteria).length > 0) {
                const selectedSpecies: any = {};
                if (criteria.speciesIds && criteria.speciesIds !== '') {
                    criteria.speciesIds.split(',').forEach((speciesId: any) => (selectedSpecies[speciesId] = false));
                }
                DATEFORMAT_CRITERIA_KEYS.forEach(criteriaKey => {
                    if (/^[0-9]{4}(-([0-9]){2}){2}$/.test(criteria[criteriaKey])) {
                        criteria[criteriaKey] = formatDateEasy(criteria[criteriaKey]);
                    }
                });
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                this.setState(
                    Object.assign(
                        {},
                        {
                            criteria,
                            criteriaCount,
                            advancedCriteriaCount,
                            selectedSpecies,
                        },
                        criteria.order && {defaultOrder: criteria.order},
                        criteria.reverse && {reverseOrder: criteria.reverse}
                    ),
                    () => this.search(false, true)
                );
            }
        }
    };

    loadJSONs = () => {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiPublicationCharpters()
                    .then(jsonResponse => {
                        if (jsonResponse) {
                            this.setState({
                                publicationChapterList: jsonResponse,
                            });
                        }
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiSpecies()
                    .then(jsonResponse => {
                        if (jsonResponse) {
                            const speciesNameHash: {[key: string]: string[]} = {};
                            (jsonResponse || []).forEach((speciesElement: any) => {
                                if (!speciesNameHash[speciesElement.NAME]) {
                                    speciesNameHash[speciesElement.NAME] = [];
                                }
                                speciesNameHash[speciesElement.NAME].push(speciesElement.ID);
                            });
                            const speciesListByIdName = Object.keys(speciesNameHash).map(speciesName => ({
                                ID: speciesNameHash[speciesName].join(','),
                                NAME: speciesName,
                            }));
                            const selectedSpecies: any = {};
                            const selectedSpeciesKeyArray = Object.keys(this.state.selectedSpecies || {});
                            speciesListByIdName
                                .filter((i: any) => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                                .forEach((item: any) => (selectedSpecies[item.ID] = item.NAME));
                            this.setState({
                                speciesListByIdName,
                                speciesNameHash,
                                selectedSpecies,
                                timestamp: Date.now(),
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`FormLabelInputSpecies: ${error.message}`]);
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    showError = (title: string, message: string) =>
        this.setState({
            errorTitle: title,
            errorMessage: message,
        });

    closeError = () => {
        this.setState({
            errorTitle: '',
            errorMessage: '',
        });
    };

    toggleAdvancedSearch = () =>
        this.setState(
            prevState => ({
                advancedOpened: !prevState.advancedOpened,
            }),
            () => {
                if (this.state.advancedOpened && this.setSelectedFunction && this.state.criteria.applicants) {
                    this.setSelectedFunction(this.state.criteria.applicants.split(','));
                }
            }
        );

    showSearchModalInfo = () => this.setState({modalScreen: true});

    closeModal = () => this.setState({modalScreen: false});

    parseCriteria = (criteria: TDefaultCriteria): TDefaultCriteria => {
        const parsedCriteria: any = {};
        Object.keys(criteria).map(key => {
            if (DATEFORMAT_CRITERIA_KEYS.indexOf(key) !== -1 && criteria[key as TKeyOfDefaultCriteria] !== '') {
                const date = criteria[key as TKeyOfDefaultCriteria];
                parsedCriteria[key] = reformatDateEasy(date);
            } else if (key === 'denominationFilter' && !criteria['denomination']) {
                criteria[key] = DEFAULT_CRITERIA[key];
            } else if (key === 'speciesNameFilter' && !criteria['speciesName']) {
                criteria[key] = DEFAULT_CRITERIA[key];
            } else {
                parsedCriteria[key] = criteria[key as TKeyOfDefaultCriteria];
            }
        });
        return parsedCriteria;
    };

    search = (refresh: boolean, urlLoad?: any) => {
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                subjectToAnAppeal: false,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams(parsedCriteria);
                apiApplicationSearch(parsedCriteria, DEFAULT_CRITERIA)
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.registers) {
                            let subjectToAnAppeal = (jsonResponse.data.registers || []).some(
                                register => !!register.appealStatus && register.appealStatus !== APPEAL_STATUS.CLOSED
                            );
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {...prev},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        registers: jsonResponse.data.registers.map(i =>
                                            i.applicationNumber === 19951123
                                                ? Object.assign({}, i, {
                                                      applicationStatus: 'T',
                                                      expirationDate: '2023-12-31',
                                                  })
                                                : i
                                        ),
                                        timestamp: Date.now(),
                                        subjectToAnAppeal,
                                    },
                                    jsonResponse.data.count && {count: jsonResponse.data.count}
                                );
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`Register search list error: ${error.message}`]);
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    printExcel = (excelTranslations: boolean) => {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                const parsedCriteria = Object.assign({}, this.parseCriteria(this.state.criteria), {excel: true});
                apiApplicationSearch(parsedCriteria, DEFAULT_CRITERIA, excelTranslations)
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                            getExcelSignedURLFetchRequest(jsonResponse.data.token).then(response => {
                                if (response && response.signedUrl) {
                                    const {signedUrl} = response;
                                    getPreSignedURLFetchRequest(signedUrl)
                                        .then(response => response.blob())
                                        .then(responseBlob => {
                                            saveAs(responseBlob, `MyApplications.xlsx`);
                                        });
                                }
                            });
                        }
                    })
                    .catch((error: any) => LOG([`error downloading: ${error}`]))
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    goToRegisters = (applicationNumber: number) => {
        const paramsArray = document.location.href.split('?');
        const validParams = paramsArray.length > 1;
        paramsArray.shift();
        const params = validParams && paramsArray.join('?');
        this.props.history.push(
            `/privateConsultationDetails?registerId=${applicationNumber}${(params && `&${params}`) || ''}`
        );
    };

    buildParams = (parsedCriteria: TDefaultCriteria): void => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(
                i =>
                    parsedCriteria[i as TKeyOfDefaultCriteria] !== DEFAULT_CRITERIA[i as TKeyOfDefaultCriteria] &&
                    i !== 'refresh'
            )
            .map(key => `${key}=${parsedCriteria[key as TKeyOfDefaultCriteria]}`);
        this.props.history.replace(`/myApplications${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`);
    };

    countCriteria = (criteria: TDefaultCriteria, advanced?: boolean): number => {
        let count = 0;
        (advanced ? advancedCriteria : Object.keys(criteria)).map(key => {
            let criteriaValue = criteria[key as TKeyOfDefaultCriteria];
            if (
                criteriaValue &&
                criteriaValue !== DEFAULT_CRITERIA[key as TKeyOfDefaultCriteria] &&
                criteriaValue !== ''
            )
                count++;
        });
        return count;
    };

    resetCriteria = () =>
        this.setState(prev => ({
            registers: null,
            selectedSpecies: {},
            criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
            criteriaCount: 0,
            advancedCriteriaCount: 0,
        }));

    updateCriteriaValue = (criteriaValue: Partial<TDefaultCriteria>, callback?: () => any, refresh?: boolean) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                pageNumberChanged = prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = prev.criteria.order !== criteriaValue.order;
                reverseChanged = prev.criteria.reverse !== criteriaValue.reverse;
                return {criteria, criteriaCount, advancedCriteriaCount};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    getAppealStatusRowClass = (rowObject: IRegister) =>
        rowObject.appealStatus && rowObject.appealStatus !== APPEAL_STATUS.CLOSED && styles.warningBold;

    onApplicationStatusChange = ({target: {value: applicationStatus}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({applicationStatus});

    onPublicationYearChange = ({target: {value: publicationYear}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue(Object.assign({}, {publicationYear}, publicationYear === ALL && {gazetteIssue: ALL}));

    onPublicationGazetteIssueChange = ({target: {value: gazetteIssue}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({gazetteIssue});

    onPublicationChapterChange = ({target: {value: chapterId}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({chapterId});

    onApplicationFromChange = (applicationDateFrom: string) => this.updateCriteriaValue({applicationDateFrom});

    onApplicationToChange = (applicationDateTo: string) => this.updateCriteriaValue({applicationDateTo});

    onGrantDateFromChange = (grantDateFrom: string) => this.updateCriteriaValue({grantDateFrom});

    onGrantDateToChange = (grantDateTo: string) => this.updateCriteriaValue({grantDateTo});

    onWidthdrawalDateFromChange = (withdrawalDateFrom: string) => this.updateCriteriaValue({withdrawalDateFrom});

    onWidthdrawalDateToChange = (withdrawalDateTo: string) => this.updateCriteriaValue({withdrawalDateTo});

    onDenominationChange = ({target: {value: denomination}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({denomination});

    onDenominationFilterChange = ({target: {value: denominationFilter}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({denominationFilter});

    onBreederReferenceChange = ({target: {value: breedersReference}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({breedersReference});

    onSpeciesSelectionChange = (selectedSpecies: {[key: string]: string}) =>
        this.updateCriteriaValue({speciesIds: Object.keys(selectedSpecies).join(','), speciesName: ''}, () =>
            this.setState({selectedSpecies})
        );

    onSpeciesNameChange = (speciesName: string) => this.updateCriteriaValue({speciesName});

    onSpeciesNameFilterChange = ({target: {value: speciesNameFilter}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({speciesNameFilter});

    onApplicationNumberChange = ({target: {value: applicationNumber}}: React.ChangeEvent<HTMLInputElement>) => {
        if (/[0-9]{0,8}/.test(applicationNumber)) {
            applicationNumber = applicationNumber.substring(0, 8);
            this.updateCriteriaValue({applicationNumber});
        }
    };

    onGrantNumberChange = ({target: {value: grantNumber}}: React.ChangeEvent<HTMLInputElement>) => {
        if (!isNaN(Number(grantNumber))) {
            this.updateCriteriaValue({grantNumber});
        }
    };

    onClientChange = ({target: {value: clientName}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({clientName});

    render() {
        const yearOptions = [];
        for (let year = new Date().getFullYear(); year > 2004; year--) {
            yearOptions.push({id: year, value: year});
        }
        const gazetteIssuesOptions = [];
        for (let i = 1; i < 7; i++) {
            const issueNumber = `0${i}`;
            gazetteIssuesOptions.push({id: i, value: issueNumber});
        }
        const chapters = (this.state.publicationChapterList || []).map(chapter => ({
            id: chapter.CHAPTERID,
            value: `${chapter.CHAPTERID} - ${chapter.CHAPTERNAME}`,
        }));

        const gazetteIssueDisabled = this.state.criteria.publicationYear === ALL;

        return (
            <>
                {this.state.modalScreen ? <ModalStatusInfoVersion2 close={this.closeModal} /> : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`My PVR`} />
                <NavigationMyPVR />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div className={styles.mainFieldContainer}>
                            <TextInput
                                filter={['equals', 'contains']}
                                currentFilter={this.state.criteria.denominationFilter}
                                onFilterChange={this.onDenominationFilterChange}
                                double={true}
                                infoIcon={faInfo}
                                onChange={this.onDenominationChange}
                                onEnter={this.search}
                                value={this.state.criteria.denomination}
                                popOverText={`The search will find all applications containing what is typed`}
                                outsideLabel={'Denomination'}
                                outsideLabelWidth={160}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <TextInput
                                filter={'Contains'}
                                double={true}
                                infoIcon={faInfo}
                                onChange={this.onBreederReferenceChange}
                                onEnter={this.search}
                                value={this.state.criteria.breedersReference}
                                popOverText={`The search will find all applications containing what is typed`}
                                outsideLabel={'Breeder’s reference'}
                                outsideLabelWidth={160}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <TextLabelInput
                                filter={['starts', 'contains']}
                                currentFilter={this.state.criteria.speciesNameFilter}
                                onFilterChange={this.onSpeciesNameFilterChange}
                                double={true}
                                onSelectionChange={this.onSpeciesSelectionChange}
                                onChange={this.onSpeciesNameChange}
                                onEnter={this.search}
                                value={this.state.criteria.speciesName}
                                selectedElements={this.state.selectedSpecies}
                                delay={300}
                                multiple={true}
                                outsideLabel={'Species'}
                                outsideLabelWidth={160}
                                listByIdName={this.state.speciesListByIdName}
                                nameHash={this.state.speciesNameHash}
                                popover={`The search will find all applications concerned by the specified species`}
                            />
                            <TextInput
                                filter={'Starts with'}
                                placeholder={`e.g. 2015 or 20150001`}
                                infoIcon={faInfo}
                                onChange={this.onApplicationNumberChange}
                                onEnter={this.search}
                                value={this.state.criteria.applicationNumber}
                                popOverText={`The search will find all applications whose number starts with the entered terms.`}
                                outsideLabel={'Application number(s)'}
                                outsideLabelWidth={160}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <div className={styles.AdvancedSearchLinkSpacer}>
                                <Empty width={160} oneLine={true} />
                            </div>
                            <InputLink
                                label={`Advanced search${
                                    this.state.advancedCriteriaCount !== 0
                                        ? ` (${this.state.advancedCriteriaCount})`
                                        : ''
                                } `}
                                icon={this.state.advancedOpened ? faChevronDown : faChevronRight}
                                clickAction={this.toggleAdvancedSearch}
                            />
                            <Empty oneLine={true} />
                        </div>
                        {this.state.advancedOpened ? (
                            <>
                                <div className={styles.doubleColumn}>
                                    <Title double={true} full={true}>{`Application details`}</Title>
                                    <TextInput
                                        filter={'Starts with'}
                                        label={`Grant number`}
                                        placeholder={`e.g. 3600`}
                                        infoIcon={faInfo}
                                        onChange={this.onGrantNumberChange}
                                        onEnter={this.search}
                                        value={this.state.criteria.grantNumber}
                                    />
                                    <DateInput
                                        label={`Application date from`}
                                        changeDateFrom={this.onApplicationFromChange}
                                        changeDateTo={this.onApplicationToChange}
                                        inputValueFrom={this.state.criteria.applicationDateFrom}
                                        inputValueTo={this.state.criteria.applicationDateTo}
                                    />
                                    <SelectInput
                                        label={`Application status`}
                                        value={this.state.criteria.applicationStatus}
                                        onChange={this.onApplicationStatusChange}
                                        list={statusOptions}
                                        buttonAction={this.showSearchModalInfo}
                                        buttonIcon={faInfo}
                                    />
                                    <DateInput
                                        label={`Grant date`}
                                        changeDateFrom={this.onGrantDateFromChange}
                                        changeDateTo={this.onGrantDateToChange}
                                        inputValueFrom={this.state.criteria.grantDateFrom}
                                        inputValueTo={this.state.criteria.grantDateTo}
                                    />
                                    <TextInput
                                        label={`Applicants`}
                                        placeholder={`Please type part of the client name`}
                                        value={this.state.criteria.clientName}
                                        onChange={this.onClientChange}
                                    />
                                    <DateInput
                                        label={`Withdrawal date`}
                                        changeDateFrom={this.onWidthdrawalDateFromChange}
                                        changeDateTo={this.onWidthdrawalDateToChange}
                                        inputValueFrom={this.state.criteria.withdrawalDateFrom}
                                        inputValueTo={this.state.criteria.withdrawalDateTo}
                                    />
                                </div>
                                <div className={styles.simpleColumn}>
                                    <Title full={true}>{`Publication details`}</Title>
                                    <SelectInput
                                        label={`Publication year`}
                                        value={this.state.criteria.publicationYear}
                                        onChange={this.onPublicationYearChange}
                                        list={yearOptions}
                                    />
                                    <SelectInput
                                        label={`Gazette issue`}
                                        value={this.state.criteria.gazetteIssue}
                                        onChange={this.onPublicationGazetteIssueChange}
                                        disabled={gazetteIssueDisabled}
                                        list={gazetteIssuesOptions}
                                    />
                                    <SelectInput
                                        label={`Chapter`}
                                        value={this.state.criteria.chapterId}
                                        onChange={this.onPublicationChapterChange}
                                        list={chapters}
                                    />
                                </div>
                            </>
                        ) : null}
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            <Button clickAction={this.resetCriteria} variation={'secondary'}>{`Clear fields`}</Button>
                            <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    <div style={{marginBottom: 20}}>
                        <CustomTable
                            version={2}
                            loading={this.state.loading !== 0}
                            pageNumber={this.state.criteria.pageNumber}
                            {...this.props}
                            tableName={'myApplications'}
                            tableType={'OBJECT'}
                            tableSource={this.state.registers}
                            timestamp={this.state.timestamp}
                            dataFilter={null}
                            id={'applicationNumber'}
                            pagination={true}
                            printExcel={this.printExcel}
                            rowClick={this.goToRegisters}
                            resultFieldsAll={PUBLIC_RESULT_FIELDS_ALL}
                            resultFieldsDefault={PUBLIC_RESULT_FIELDS_DEFAULT}
                            intl={this.props.intl}
                            defaultOrder={this.state.criteria.order}
                            reverseOrder={this.state.criteria.reverse}
                            pageSize={this.state.criteria.pageSize}
                            filterFunctions={null}
                            count={this.state.count}
                            rowClass={this.getAppealStatusRowClass}
                            formatFunctions={{
                                applicants: (applicants: string[]) => {
                                    const applicantList: any = [];
                                    applicants.forEach((applicant: any) => {
                                        applicantList.push(
                                            <p key={applicant} style={{margin: 0}} className="ng-scope">
                                                {applicant}
                                            </p>
                                        );
                                    });
                                    return applicantList;
                                },
                                grantingDate: FORMAT_DATE,
                                applicationStatus: (fieldData: string) => {
                                    switch (fieldData) {
                                        case 'A':
                                            return 'Active application';
                                        case 'T':
                                            return 'Terminated Right';
                                        case 'W':
                                            return 'Withdrawn';
                                        case 'R':
                                            return 'Refused';
                                        case 'G':
                                            return 'Granted';
                                        case 'P':
                                        case 'M':
                                        case 'U':
                                            return 'Reception ongoing';
                                        case 'N':
                                            return 'Abandoned application';
                                        default:
                                            return fieldData;
                                    }
                                },
                                applicationDate: FORMAT_DATE,
                                withdrawalDate: FORMAT_DATE,
                                arrivalDate: FORMAT_DATE,
                                expirationDate: FORMAT_DATE,
                                surrenderDate: FORMAT_DATE,
                                nationalApplicationDate: FORMAT_DATE,
                                firstInsideEU: (firstInsideEU: any, objectRow: IRegister) =>
                                    firstInsideEU && objectRow['firstInsideEUCountry']
                                        ? `${formatDateTimeEasy(firstInsideEU)} - ${objectRow['firstInsideEUCountry']}`
                                        : '',
                                firstOutsideEU: (firstOutsideEU: any, objectRow: IRegister) =>
                                    firstOutsideEU && objectRow['firstOutsideEUCountry']
                                        ? `${formatDateTimeEasy(firstOutsideEU)} - ${
                                              objectRow['firstOutsideEUCountry']
                                          }`
                                        : '',
                                titleStatus: (titleStatus: string) =>
                                    this.props.intl.formatMessage({id: `table.general.${titleStatus}`}),
                            }}
                            headerPopup={{
                                applicationStatus: {
                                    description: 'Status explanations',
                                    handler: this.showSearchModalInfo,
                                },
                            }}
                            topCaption={
                                this.state.subjectToAnAppeal && (
                                    <div className={styles.captionContainer}>
                                        <div className={cx(styles.label, styles.labelYellow)}>
                                            {`This file is subject to an appeal`}
                                        </div>
                                    </div>
                                )
                            }
                            excelFormatFunctions={{
                                applicants: (applicants: string[]) => applicants.join('; '),
                            }}
                            updateCriteriaValue={this.updateCriteriaValue}
                        />
                        <div style={{clear: 'both'}} />
                    </div>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(MyPVRMyApplications));
