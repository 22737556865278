import React from 'react';
import ModalInternalMessageCompose from '../CCModalInternalCompose/CCModalInternalCompose';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {apiCommunicationCentreInternalAlerts} from './CCInternalAlertsService';
import CustomTable from '~components/CustomTable';
import {IAlertItem} from '../../types';
import {FORMAT_DATE_TIME_EASY} from '~components/FormatFunctions';
import {Footer, HeaderLoading, HeaderLogoMenu, HeaderTitleAndVersion, MainWrapper} from '../../componentsLayout';
import NavigationCommunicationCentre from '../../shared/NavigationCommunicationCentre';
import {trackPageView} from '../../utils';

const RESULT_FIELDS_DEFAULT = ['logTime', 'contactShortKey', 'logText', 'contactName', 'contactSurname'];

export type TDefaultCriteria = typeof DEFAULT_CRITERIA;

type TKeyOfDefaultCriteria = keyof TDefaultCriteria;

const DEFAULT_CRITERIA: any = {
    pageNumber: 1,
    pageSize: 10,
    order: 'logTime',
    reverse: true,
    refresh: false,
};

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    alerts: IAlertItem[];
    count: number;
    criteria: TDefaultCriteria;
    loading: number;
    modalInternalMessageCompose: boolean;
    screenLoaded: boolean;
    timestamp: number;
}

class CCInternalAlerts extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            alerts: [],
            count: 0,
            criteria: Object.assign({}, DEFAULT_CRITERIA),
            loading: 0,
            modalInternalMessageCompose: false,
            screenLoaded: false,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'CommunicationCentreInternalAlerts'});
        this.loadJSONs();
    }

    loadJSONs = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiCommunicationCentreInternalAlerts({}, DEFAULT_CRITERIA).then(JSONResponse => {
                    if (JSONResponse && JSONResponse.data) {
                        this.setState({alerts: JSONResponse.data, count: JSONResponse.count, screenLoaded: true});
                    }
                    this.setState(prev => ({loading: prev.loading - 1}));
                });
            }
        );

    goToScreen = (screen: string) => this.props.history.push(screen);

    openModalInternalMessageCompose = () => this.setState({modalInternalMessageCompose: true});

    closeModalInternalMessageCompose = () => this.setState({modalInternalMessageCompose: false});

    updateCriteriaValue = (
        criteriaValue: Partial<TDefaultCriteria>,
        callback?: () => any,
        refresh?: boolean,
        searchCallback?: () => any
    ): void => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = prev.criteria.order !== criteriaValue.order;
                reverseChanged = prev.criteria.reverse !== criteriaValue.reverse;
                return {criteria};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true, false, searchCallback);
            }
        );
    };

    buildParams = (parsedCriteria: any): void => {
        const paramArray: any = Object.keys(parsedCriteria)
            .filter(i => parsedCriteria[i] !== DEFAULT_CRITERIA[i as TKeyOfDefaultCriteria] && i !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key as TKeyOfDefaultCriteria]}`);
        this.props.history.replace(
            `/communicationCentreInternalAlerts${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`
        );
    };

    parseCriteria = (criteria: TDefaultCriteria): TDefaultCriteria => {
        const parsedCriteria: any = {};
        Object.keys(criteria).map(key => {
            let criteriaValue = criteria[key as TKeyOfDefaultCriteria];
            parsedCriteria[key] = criteriaValue;
        });
        return parsedCriteria;
    };

    search = (refresh?: boolean, urlLoad?: boolean, callBack?: () => any): any =>
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams(parsedCriteria);
                apiCommunicationCentreInternalAlerts(parsedCriteria, DEFAULT_CRITERIA)
                    .then((jsonResponse: any) => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        alerts: jsonResponse.data,
                                        timestamp: Date.now(),
                                        loading: prev.loading - 1,
                                    },
                                    jsonResponse.data.count && {count: jsonResponse.data.count}
                                );
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`Register search list error: ${error.message}`]);
                    })
                    .then(() => {
                        callBack && callBack();
                    });
            }
        );

    render() {
        return (
            <>
                {this.state.modalInternalMessageCompose ? (
                    <ModalInternalMessageCompose close={this.closeModalInternalMessageCompose} intl={this.props.intl} />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading message={undefined} /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Communication Centre`} />
                <NavigationCommunicationCentre
                    openModalMessageCompose={this.openModalInternalMessageCompose}
                    search={this.search}
                    updateCriteriaValue={this.updateCriteriaValue}
                />
                <MainWrapper>
                    {this.state.screenLoaded ? (
                        <CustomTable
                            {...this.props}
                            count={this.state.count}
                            formatFunctions={{
                                logTime: FORMAT_DATE_TIME_EASY,
                            }}
                            tableName={'communicationCentreAlerts'}
                            tableType={'OBJECT'}
                            tableSource={this.state.alerts}
                            id={'logText'}
                            resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                            intl={this.props.intl}
                            hideExcelButton={true}
                            notSortable={RESULT_FIELDS_DEFAULT}
                            pagination={true}
                            pageNumber={this.state.criteria.pageNumber}
                            pageSize={this.state.criteria.pageSize}
                            timestamp={this.state.timestamp}
                            updateCriteriaValue={this.updateCriteriaValue}
                            version={2}
                        />
                    ) : null}
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(CCInternalAlerts));
