import React from 'react';

import {useCountUp} from 'react-countup';

const CustomCountUp = (props: any) => {
    const {countUp, update, start} = useCountUp({
        end: props.end,
        duration: 3,
        separator: '.',
        startOnMount: false,
    });

    React.useEffect(() => {
        if (props.end) {
            update(props.end);
            start();
        }
    }, [props.end]);

    return <span style={{fontWeight: 600, width: `${calculateWidth(props.end)}px`}}>{countUp}</span>;
};

const calculateWidth = (num: number) => {
    const numSize = num?.toString().length;
    return 13.55 * (numSize + calculateDots(numSize));
};

const calculateDots = (size: number) => {
    return size > 3 ? Math.floor(size / 3) : 0.5;
};

export default CustomCountUp;
