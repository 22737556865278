import React from 'react';
import styles from './HeaderRecommendedBrowsers.module.scss';
import chromeIcon from '../img/icons/chrome.svg';
import chromiumIcon from '../img/icons/chromium.svg';
import edgeIcon from '../img/icons/edge.svg';

export default function () {
    return (
        <section className={styles.headerRecommendedBrowsersWrap}>
            <div>
                <h3 className={styles.titleRecommendedBrowsers}>{`Recommended browsers:`}</h3>
                <img className={styles.browserIcon} src={chromeIcon} alt="Chrome icon" />
                <img className={styles.browserIcon} src={chromiumIcon} alt="Chromium icon" />
                <img className={styles.browserIcon} src={edgeIcon} alt="Edge icon" />
            </div>
            <p
                className={styles.paragraph}
            >{`To guarantee a good experience using MyPVR area, we recommend using Chrome, Chromium and Edge Chromium. Other web browsers may be not suitable for this service. If however you encounter any particular problems with these web browsers, please close all current sessions (log out), clear the Cache & Cookies and try again.`}</p>
        </section>
    );
}
