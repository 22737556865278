import React from 'react';

import {Document, Font, Image, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import {ITestDataSheet, ITestResultsResponse} from '../VFMyTests/VFMyTests';
import {chunkArray, CPVO_EXPERT_ADVICE_STATUS} from './VFPDFTestDataSheet.utils';
import {convertDate} from '../../utils/utilsVarietyFinder';

Font.register({
    family: 'Open Sans',
    fonts: [
        {src: '/OpenSans-Regular.ttf'},
        {src: '/OpenSans-Italic.ttf', fontStyle: 'italic'},
        {src: '/OpenSans-Bold.ttf', fontWeight: 'bold'},
    ],
});

const styles = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        margin: 10,
        padding: 10,
        fontFamily: 'Open Sans',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'center',
        flexGrow: 0,
        flexShrink: 0,
    },
    header: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 500,
    },
    col: {
        flexGrow: 0,
        flexShrink: 0,
    },
    textKey: {
        paddingTop: 4,
        paddingBottom: 4,
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        fontSize: 9,
        color: '#867B5E',
    },
    textValue: {
        border: 1,
        fontSize: 9,
        padding: 4,
    },
    textValueItalics: {
        border: 1,
        fontSize: 9,
        padding: 4,
        fontFamily: 'Open Sans',
        fontStyle: 'italic',
    },
    regionTitle: {
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        fontSize: 14,
        color: '#2F932F',
    },
    fullRow: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 500,
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 8,
        bottom: 30,
        left: 290,
        right: 0,
    },
    table: {
        // @ts-ignore
        display: 'table',
        width: 'auto',
        marginRight: 38,
        marginLeft: 38,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        borderColor: '#AFA794',
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row',
    },
    tableCol: {
        width: '25%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: '#AFA794',
    },
    tableColScore: {
        width: '7%',
        minWidth: '35px',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: '#AFA794',
    },
    tableColDenomination: {
        width: '20%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: '#AFA794',
    },
    tableColBreedersReference: {
        width: '13%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: '#AFA794',
    },
    tableColSpecies: {
        width: '18%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: '#AFA794',
    },
    tableColDenominationsApplicationNumber: {
        width: '18%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: '#AFA794',
    },
    tableColTradeMark: {
        width: '33%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: '#AFA794',
    },
    tableColApplicationDate: {
        width: '13%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: '#AFA794',
    },
    tableColApplicationNumber: {
        width: '15%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: '#AFA794',
    },
    tableColApplicant: {
        width: '35%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: '#AFA794',
    },
    tableColCountryPubli: {
        width: '11%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: '#AFA794',
    },
    tableHeaderCol: {
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        backgroundColor: '#EFE7DE',
        color: '#867A5E',
    },
    tableHeaderColScore: {
        width: '7%',
        minWidth: '35px',
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        backgroundColor: '#EFE7DE',
        color: '#867A5E',
    },
    tableHeaderColDenomination: {
        width: '20%',
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        backgroundColor: '#EFE7DE',
        color: '#867A5E',
    },
    tableHeaderColBreedersReference: {
        width: '13%',
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        backgroundColor: '#EFE7DE',
        color: '#867A5E',
    },
    tableHeaderColSpecies: {
        width: '18%',
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        backgroundColor: '#EFE7DE',
        color: '#867A5E',
    },
    tableHeaderColDenominationsApplicationNumber: {
        width: '18%',
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        backgroundColor: '#EFE7DE',
        color: '#867A5E',
    },
    tableHeaderColTradeMark: {
        width: '33%',
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        backgroundColor: '#EFE7DE',
        color: '#867A5E',
    },
    tableHeaderColApplicationDate: {
        width: '13%',
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        backgroundColor: '#EFE7DE',
        color: '#867A5E',
    },
    tableHeaderColApplicationNumber: {
        width: '15%',
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        backgroundColor: '#EFE7DE',
        color: '#867A5E',
    },
    tableHeaderColApplicant: {
        width: '35%',
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        backgroundColor: '#EFE7DE',
        color: '#867A5E',
    },
    tableHeaderColCountryPubli: {
        width: '11%',
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        backgroundColor: '#EFE7DE',
        color: '#867A5E',
    },
    tableCell: {
        fontSize: 8,
        paddingHorizontal: 4,
        paddingVertical: 2,
        justifyContent: 'center',
    },
    image: {width: 200, backgroundColor: '#ffffff'},
    badge: {
        borderRadius: 9,
        fontSize: 9,
        fontWeight: 'ultrabold',
        paddingVertical: 3,
        paddingHorizontal: 5,
        justifyContent: 'center',
        textAlign: 'center',
        color: '#FFFFFF',
        minWidth: 160,
    },
    badgeRequested: {
        backgroundColor: '#00447A',
    },
    badgeNoObservation: {
        backgroundColor: '#57AB27',
    },
    badgeObservation: {
        backgroundColor: '#E53138',
    },
    badgePendingAnalysis: {
        backgroundColor: '#FA6400',
    },
    badgePendingCommittee: {
        backgroundColor: '#FAD200',
    },
    badgeCancelled: {
        color: '#000000',
        backgroundColor: '#FFFFFF',
        border: 1,
    },
    badgeCondition: {
        backgroundColor: '#FA6400',
    },
    badgePriorRight: {
        backgroundColor: '#808080',
    },
    tableEUTMColScoreWidth: {
        width: '7%',
    },
    tableEUTMColTradeMarkWidth: {
        width: '29%',
    },
    tableEUTMColApplicationDateWidth: {
        width: '12%',
    },
    tableEUTMColApplicationNumberWidth: {
        width: '13%',
    },
    tableEUTMColApplicantWidth: {
        width: '29%',
    },
    tableEUTMColCountryPubliWidth: {
        width: '10%',
    },
});

const getStyle = (requestAdviceId: any) => {
    switch (requestAdviceId) {
        case CPVO_EXPERT_ADVICE_STATUS.REQUESTED:
            return styles.badgeRequested;
        case CPVO_EXPERT_ADVICE_STATUS.PENDING_ANALYSIS:
            return styles.badgePendingAnalysis;
        case CPVO_EXPERT_ADVICE_STATUS.PENDING_COMMITTEE:
            return styles.badgePendingCommittee;
        case CPVO_EXPERT_ADVICE_STATUS.NO_OBSERVATION:
            return styles.badgeNoObservation;
        case CPVO_EXPERT_ADVICE_STATUS.OBSERVATION:
            return styles.badgeObservation;
        case CPVO_EXPERT_ADVICE_STATUS.CANCELLED:
            return styles.badgeCancelled;
        case CPVO_EXPERT_ADVICE_STATUS.CONDITION:
            return styles.badgeCondition;
        case CPVO_EXPERT_ADVICE_STATUS.PRIOR_RIGHT:
            return styles.badgePriorRight;
        default:
            return {};
    }
};

export const VFPDFTestDataSheet = (props: {data: ITestDataSheet[]}) => {
    const getText = (requestAdviceId: any) => {
        switch (requestAdviceId) {
            case CPVO_EXPERT_ADVICE_STATUS.REQUESTED:
                return 'Requested';
            case CPVO_EXPERT_ADVICE_STATUS.PENDING_ANALYSIS:
                return 'Pending analysis';
            case CPVO_EXPERT_ADVICE_STATUS.PENDING_COMMITTEE:
                return 'Pending committee';
            case CPVO_EXPERT_ADVICE_STATUS.NO_OBSERVATION:
                return 'No observation';
            case CPVO_EXPERT_ADVICE_STATUS.OBSERVATION:
                return 'Observation';
            case CPVO_EXPERT_ADVICE_STATUS.CANCELLED:
                return 'Cancelled';
            case CPVO_EXPERT_ADVICE_STATUS.CONDITION:
                return 'Condition';
            case CPVO_EXPERT_ADVICE_STATUS.PRIOR_RIGHT:
                return 'Prior Right';
            default:
                return '';
        }
    };

    const printHtmlField = (value: any, type = 'text') => {
        if (type === 'date') {
            const dateFormatted = convertDate(value);
            return dateFormatted ? dateFormatted : ' ';
        }
        return value ? value : ' ';
    };
    const statusBadge = (requestAdviceId: any) => {
        return requestAdviceId ? (
            <Text style={[styles.badge, getStyle(requestAdviceId)]}>{printHtmlField(getText(requestAdviceId))}</Text>
        ) : (
            <></>
        );
    };

    const denominationsPage = (providedData: any, index: number) => {
        const columnsWidth = [4, 18, 11, 12, 11, 18, 14, 9];

        return (
            <>
                <Page size="A4" style={styles.page}>
                    {index === 0 ? (
                        <View style={[styles.row, {paddingTop: 20}]}>
                            <View style={styles.fullRow}>
                                <Text style={styles.regionTitle}>{`Denominations`}</Text>
                            </View>
                        </View>
                    ) : (
                        <></>
                    )}
                    <View style={[styles.table, {marginTop: 5}]}>
                        <View style={styles.tableRow}>
                            <View
                                style={[
                                    styles.tableHeaderColScore,
                                    styles.tableColScore,
                                    {width: `${columnsWidth[0]}%`},
                                ]}
                            >
                                <Text style={styles.tableCell}>{`Score`}</Text>
                            </View>
                            <View
                                style={[
                                    styles.tableHeaderColDenomination,
                                    styles.tableColDenomination,
                                    {width: `${columnsWidth[1]}%`},
                                ]}
                            >
                                <Text style={styles.tableCell}>{`Denomination`}</Text>
                            </View>
                            <View
                                style={[
                                    styles.tableHeaderColBreedersReference,
                                    styles.tableColBreedersReference,
                                    {width: `${columnsWidth[2]}%`},
                                ]}
                            >
                                <Text style={styles.tableCell}>{`Breeder's Reference`}</Text>
                            </View>
                            <View
                                style={[
                                    styles.tableHeaderColApplicationDate,
                                    styles.tableColApplicationDate,
                                    {width: `${columnsWidth[3]}%`},
                                ]}
                            >
                                <Text style={styles.tableCell}>{`Variety Status`}</Text>
                            </View>
                            <View
                                style={[
                                    styles.tableHeaderColApplicationDate,
                                    styles.tableColApplicationDate,
                                    {width: `${columnsWidth[4]}%`},
                                ]}
                            >
                                <Text style={styles.tableCell}>{`Variety Status Date`}</Text>
                            </View>
                            <View
                                style={[
                                    styles.tableHeaderColSpecies,
                                    styles.tableColSpecies,
                                    {width: `${columnsWidth[5]}%`},
                                ]}
                            >
                                <Text style={styles.tableCell}>{`Species`}</Text>
                            </View>
                            <View
                                style={[
                                    styles.tableHeaderColDenominationsApplicationNumber,
                                    styles.tableColDenominationsApplicationNumber,
                                    {width: `${columnsWidth[6]}%`},
                                ]}
                            >
                                <Text style={[styles.tableCell]}>{`Application Number`}</Text>
                            </View>
                            <View
                                style={[
                                    styles.tableHeaderColCountryPubli,
                                    styles.tableColCountryPubli,
                                    {width: `${columnsWidth[7]}%`},
                                ]}
                            >
                                <Text style={[styles.tableCell]}>{`Country Publi`}</Text>
                            </View>
                        </View>
                        {(providedData as ITestResultsResponse[]).map(
                            (data: any, denominationsIndex: number, accum) => (
                                <View style={styles.tableRow} key={data['apl_nb']}>
                                    <View style={[styles.tableColScore, {width: `${columnsWidth[0]}%`}]}>
                                        <Text style={styles.tableCell}>{data['score']}</Text>
                                    </View>
                                    <View style={[styles.tableColDenomination, {width: `${columnsWidth[1]}%`}]}>
                                        <Text style={styles.tableCell}>{data['denomination']}</Text>
                                    </View>
                                    <View style={[styles.tableColBreedersReference, {width: `${columnsWidth[2]}%`}]}>
                                        <Text style={styles.tableCell}>{data['brd_ref']}</Text>
                                    </View>
                                    <View style={[styles.tableColApplicationDate, {width: `${columnsWidth[3]}%`}]}>
                                        <Text style={styles.tableCell}>{data['var_status']}</Text>
                                    </View>
                                    <View style={[styles.tableColApplicationDate, {width: `${columnsWidth[4]}%`}]}>
                                        <Text style={styles.tableCell}>
                                            {printHtmlField(data['var_status_date'], 'date')}
                                        </Text>
                                    </View>
                                    <View style={[styles.tableColSpecies, {width: `${columnsWidth[5]}%`}]}>
                                        <Text style={styles.tableCell}>{data['speciename']}</Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.tableColDenominationsApplicationNumber,
                                            {width: `${columnsWidth[6]}%`},
                                        ]}
                                    >
                                        <Text style={styles.tableCell}>{data['apl_nb']}</Text>
                                    </View>
                                    <View style={[styles.tableColCountryPubli, {width: `${columnsWidth[7]}%`}]}>
                                        <Text style={styles.tableCell}>
                                            {data['register'].substr(0, 2)} {data['register'].substr(2)}
                                        </Text>
                                    </View>
                                </View>
                            )
                        )}
                    </View>
                    <Text
                        style={{
                            position: 'absolute',
                            bottom: 33,
                            left: 40,
                            right: 320,
                            borderBottom: 1,
                        }}
                        fixed
                    />
                    <Text
                        style={styles.pageNumber}
                        render={({pageNumber, totalPages}: any) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                    <Text
                        style={{
                            position: 'absolute',
                            bottom: 33,
                            left: 320,
                            right: 40,
                            borderBottom: 1,
                        }}
                        fixed
                    />
                </Page>
            </>
        );
    };

    const communityTrademarksPage = (providedData: any, index: number) => (
        <>
            <Page size="A4" style={styles.page}>
                {index === 0 ? (
                    <View style={[styles.row, {paddingTop: 20}]}>
                        <View style={styles.fullRow}>
                            <Text style={styles.regionTitle}>{`European Union Trade Marks`}</Text>
                        </View>
                    </View>
                ) : (
                    <></>
                )}
                <View style={[styles.table, {marginTop: 5}]}>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableHeaderColScore, styles.tableColScore, styles.tableEUTMColScoreWidth]}>
                            <Text style={styles.tableCell}>{`Score`}</Text>
                        </View>
                        <View
                            style={[
                                styles.tableHeaderColTradeMark,
                                styles.tableColTradeMark,
                                styles.tableEUTMColTradeMarkWidth,
                            ]}
                        >
                            <Text style={styles.tableCell}>{`European Union Trade Mark`}</Text>
                        </View>
                        <View
                            style={[
                                styles.tableHeaderColApplicationDate,
                                styles.tableColApplicationDate,
                                styles.tableEUTMColApplicationDateWidth,
                            ]}
                        >
                            <Text style={styles.tableCell}>{`Application Date`}</Text>
                        </View>
                        <View
                            style={[
                                styles.tableHeaderColApplicationNumber,
                                styles.tableColApplicationNumber,
                                styles.tableEUTMColApplicationNumberWidth,
                            ]}
                        >
                            <Text style={styles.tableCell}>{`Application Number`}</Text>
                        </View>
                        <View
                            style={[
                                styles.tableHeaderColApplicant,
                                styles.tableColApplicant,
                                styles.tableEUTMColApplicantWidth,
                            ]}
                        >
                            <Text style={styles.tableCell}>{`Applicant`}</Text>
                        </View>
                        <View
                            style={[
                                styles.tableHeaderColCountryPubli,
                                styles.tableColCountryPubli,
                                styles.tableEUTMColCountryPubliWidth,
                            ]}
                        >
                            <Text style={styles.tableCell}>{`Country Publi`}</Text>
                        </View>
                    </View>
                    {(providedData as ITestResultsResponse[]).map((data: any, eutmIndex: number) => (
                        <View style={styles.tableRow} key={data['apl_nb']}>
                            <View style={[styles.tableColScore, styles.tableEUTMColScoreWidth]}>
                                <Text style={styles.tableCell}>{data['score']}</Text>
                            </View>
                            <View style={[styles.tableColTradeMark, styles.tableEUTMColTradeMarkWidth]}>
                                <Text style={styles.tableCell}>{data['denomination']}</Text>
                            </View>
                            <View style={[styles.tableColApplicationDate, styles.tableEUTMColApplicationDateWidth]}>
                                <Text style={styles.tableCell}>{printHtmlField(data['var_status_date'], 'date')}</Text>
                            </View>
                            <View style={[styles.tableColApplicationNumber, styles.tableEUTMColApplicationNumberWidth]}>
                                <Text style={styles.tableCell}>{data['apl_nb']}</Text>
                            </View>
                            <View style={[styles.tableColApplicant, styles.tableEUTMColApplicantWidth]}>
                                <Text style={styles.tableCell}>{data['applicantname']}</Text>
                            </View>
                            <View style={[styles.tableColCountryPubli, styles.tableEUTMColCountryPubliWidth]}>
                                <Text style={styles.tableCell}>{data['register']}</Text>
                            </View>
                        </View>
                    ))}
                </View>
                <Text
                    style={{
                        position: 'absolute',
                        bottom: 33,
                        left: 40,
                        right: 320,
                        borderBottom: 1,
                    }}
                    fixed
                />
                <Text
                    style={styles.pageNumber}
                    render={({pageNumber, totalPages}: any) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
                <Text
                    style={{
                        position: 'absolute',
                        bottom: 33,
                        left: 320,
                        right: 40,
                        borderBottom: 1,
                    }}
                    fixed
                />
            </Page>
        </>
    );

    return (
        <>
            <Document>
                {props.data.map((datasheet: ITestDataSheet, i: number) => (
                    <>
                        <Page size="A4" style={styles.page} key={datasheet.test_id}>
                            <View style={[styles.row, {paddingTop: 10}]}>
                                <View style={styles.header}>
                                    <Image style={styles.image} src="/logo-en.png" />
                                </View>
                            </View>
                            <View style={[styles.row, {paddingTop: 20}]}>
                                <View style={styles.fullRow}>
                                    <Text style={styles.regionTitle}>
                                        {`Denomination test result No ${datasheet.test_id}`}
                                    </Text>
                                </View>
                            </View>
                            <View style={[styles.row, {paddingTop: 5}]}>
                                <View style={[styles.col, {flexBasis: 100}]}>
                                    <Text style={styles.textKey}>{`Denomination tested`}</Text>
                                </View>
                                <View style={[styles.col, {flexBasis: 270}]}>
                                    <Text style={styles.textValue}>{printHtmlField(datasheet.denomination)}</Text>
                                </View>

                                <View style={[styles.col, {flexBasis: 50, paddingLeft: 10}]}>
                                    <Text style={styles.textKey}>{`Date`}</Text>
                                </View>
                                <View style={[styles.col, {flexBasis: 80}]}>
                                    <Text style={styles.textValue}>{printHtmlField(datasheet.test_date, 'date')}</Text>
                                </View>
                            </View>
                            <View style={[styles.row, {paddingTop: 5}]}>
                                <View style={[styles.col, {flexBasis: 100}]}>
                                    <Text style={styles.textKey}>{`Species`}</Text>
                                </View>
                                <View style={[styles.col, {flexBasis: 400}]}>
                                    <Text style={styles.textValue}>{printHtmlField(datasheet.species_latin_name)}</Text>
                                </View>
                            </View>
                            <View style={[styles.row, {paddingTop: 5}]}>
                                <View style={[styles.col, {flexBasis: 100}]}>
                                    <Text style={styles.textKey}>{`Breeder ref. / name`}</Text>
                                </View>
                                <View style={[styles.col, {flexBasis: 130}]}>
                                    <Text style={styles.textValue}>{printHtmlField(datasheet.breeder_ref)}</Text>
                                </View>
                                <View style={[styles.col, {paddingLeft: 10, flexBasis: 270}]}>
                                    <Text style={styles.textValue}>{printHtmlField(datasheet.breeder_name)}</Text>
                                </View>
                            </View>
                            <View style={[styles.row, {paddingTop: 5}]}>
                                <View style={[styles.col, {flexBasis: 100}]}>
                                    <Text style={styles.textKey}>{`Remark`}</Text>
                                </View>
                                <View style={[styles.col, {flexBasis: 400}]}>
                                    <Text style={styles.textValue}>{printHtmlField(datasheet.user_remark)}</Text>
                                </View>
                            </View>
                            <View style={[styles.row, {paddingTop: 5}]}>
                                <View style={[styles.col, {flexBasis: 100}]}>
                                    <Text style={styles.textKey}>{`Class`}</Text>
                                </View>
                                <View style={[styles.col, {flexBasis: 80}]}>
                                    <Text style={styles.textValue}>{printHtmlField(datasheet.species_id)}</Text>
                                </View>
                                <View style={[styles.col, {flexBasis: 320}]} />
                            </View>
                            <View style={[styles.row, {paddingTop: 5}]}>
                                <View style={[styles.col, {flexBasis: 100}]}>
                                    <Text style={styles.textKey}>{`Test status`}</Text>
                                </View>
                                <View style={[styles.col, {flexBasis: 400}]}>
                                    <Text style={styles.textValue}>{printHtmlField(datasheet.test_response)}</Text>
                                </View>
                            </View>
                            {datasheet.request_advice_id && datasheet.request_advice_id > 1 ? (
                                <>
                                    <View style={[styles.row, {paddingTop: 20}]}>
                                        <View style={[styles.col, {flexBasis: 340}]}>
                                            <Text style={styles.regionTitle}>
                                                {`CPVO Expert advice`}
                                                {/*{t('cpvo_expert_advice', {id: datasheet.test_id})}*/}
                                                {/*//TODO check translation variable*/}
                                            </Text>
                                        </View>
                                        <View style={[styles.col, {flexBasis: 160}]}>
                                            {statusBadge(datasheet.request_advice_id)}
                                        </View>
                                    </View>
                                    {datasheet.analysis_date ||
                                    (datasheet.analysisby && datasheet.analysisby !== ' ') ? (
                                        <View style={[styles.row, {paddingTop: 5}]}>
                                            <View style={[styles.col, {flexBasis: 500}]}>
                                                <Text style={styles.textValueItalics}>
                                                    {`Analysis delivered on ${printHtmlField(
                                                        datasheet.analysis_date,
                                                        'date'
                                                    )} - ${printHtmlField(datasheet.analysisby)}`}
                                                </Text>
                                            </View>
                                        </View>
                                    ) : (
                                        <></>
                                    )}
                                    {datasheet.cpvo_advice ? (
                                        <View style={[styles.row, {paddingTop: 5}]}>
                                            <View style={[styles.col, {flexBasis: 500}]}>
                                                <Text style={styles.textValue}>
                                                    {printHtmlField(datasheet.cpvo_advice.map(advice => advice + '\n'))}
                                                </Text>
                                            </View>
                                        </View>
                                    ) : (
                                        <></>
                                    )}
                                    {datasheet.standard_advice ? (
                                        <View style={[styles.row, {paddingTop: 5}]}>
                                            <View style={[styles.col, {flexBasis: 500}]}>
                                                <Text style={styles.textValue}>
                                                    {printHtmlField(datasheet.standard_advice?.replace(/(\r)/gm, ''))}
                                                </Text>
                                            </View>
                                        </View>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : (
                                <></>
                            )}
                            <Text
                                style={{
                                    position: 'absolute',
                                    bottom: 33,
                                    left: 40,
                                    right: 320,
                                    borderBottom: 1,
                                }}
                                fixed
                            />
                            <Text
                                style={styles.pageNumber}
                                render={({pageNumber, totalPages}: any) => `${pageNumber} / ${totalPages}`}
                                fixed
                            />
                            <Text
                                style={{
                                    position: 'absolute',
                                    bottom: 33,
                                    left: 320,
                                    right: 40,
                                    borderBottom: 1,
                                }}
                                fixed
                            />
                        </Page>
                        {datasheet.test_results.denominations ? (
                            chunkArray(
                                datasheet.test_results.denominations,
                                [
                                    {
                                        name: 'denomination',
                                        size: 21,
                                    },
                                    {
                                        name: 'speciename',
                                        size: 21,
                                    },
                                ],
                                44
                            ).map((chunk: any, index: number) => denominationsPage(chunk, index))
                        ) : (
                            <></>
                        )}
                        {datasheet.test_results.eutm ? (
                            chunkArray(
                                datasheet.test_results.eutm,
                                [
                                    {
                                        name: 'denomination',
                                        size: 28,
                                    },
                                    {
                                        name: 'applicantname',
                                        size: 28,
                                    },
                                ],
                                52
                            ).map((chunk: any, index: number) => communityTrademarksPage(chunk, index))
                        ) : (
                            <></>
                        )}
                    </>
                ))}
            </Document>
        </>
    );
};
