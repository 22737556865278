export const listByIdNameRegisterType = [
    {ID: 'All', NAME: 'All'},
    {ID: 'pbr', NAME: "Plant Breeder's Rights (PBR)"},
    {ID: 'plp', NAME: 'Plant Patents (PLP)'},
    {ID: 'eutm', NAME: 'European Union trade Mark (EUTM)'},
    {ID: 'nli', NAME: 'National List (NLI)'},
    {ID: 'fru', NAME: 'Frumatis (FRU)'},
    {ID: 'com', NAME: 'Commercial Registers (COM)'},
    {ID: 'zzz', NAME: 'Other Registers (ZZZ)'},
];
