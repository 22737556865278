import gqlPrettier from 'graphql-prettier';

export function apiBackOfficeGetCropSectors(): Promise<{
    data: {ID: string; NAME: string}[];
}> {
    return new Promise((resolve, reject) => {
        resolve({
            data: [
                {ID: '1', NAME: 'Agricultural'},
                {ID: '2', NAME: 'Vegetable'},
                {ID: '3', NAME: 'Fruit'},
                {ID: '4', NAME: 'Ornamental'},
                {ID: '5', NAME: 'Forest'},
                {ID: '6', NAME: 'Miscellaneous'},
            ],
        });
    });
}

const getSingleValueFromTypeAhead = (
    value: any,
    valueSelect: string,
    elasticField: string,
    elasticFieldSearchType: string
) => {
    return value && value.length > 0
        ? `${elasticField}:"${value.toLowerCase()}" ${elasticFieldSearchType}:"${valueSelect}"`
        : '';
};

const getValueFromFormControl = (
    value: any,
    valueSelect: string,
    elasticField: string,
    elasticFieldSearchType: string,
    regexp: RegExp | null = null
) => {
    if (value && value.length > 0) {
        const val = regexp ? value.replace(regexp, '') : value;
        const searchType = elasticFieldSearchType ? `${elasticFieldSearchType}:"${valueSelect}"` : '';
        return `${elasticField}:"${val.toLowerCase()}" ${searchType}`;
    } else {
        return '';
    }
};

const getValueFromMultiselect = (value: any, elasticField: string, valueFromKey?: boolean) => {
    return value && Object.values(value).length > 0
        ? `${elasticField}:${JSON.stringify(
              (valueFromKey ? Object.keys(value) : Object.values(value)).map((value: any) => value.toLowerCase())
          )}`
        : '';
};

const createSearchSpeciesParams = (values: any) => {
    const selectMap: any = {
        contains: 'contains',
        equals: 'exact',
        start: 'starts',
        starts: 'starts',
    };
    const speciesLatinName = getSingleValueFromTypeAhead(
        values.speciesLatinName,
        selectMap[values.speciesLatinNameSelect],
        'speciesLatinName',
        'speciesLatinNameSearchType'
    );
    const speciesCode = getValueFromFormControl(
        values.speciesCode,
        selectMap[values.speciesCodeSelect],
        'speciesCode',
        'speciesCodeSearchType'
    );
    const commonName = getValueFromFormControl(
        values.commonName,
        selectMap[values.commonNameSelect],
        'commonName',
        'commonNameSearchType'
    );
    const commonNameLanguage = getValueFromMultiselect(values.commonNameLanguage, 'commonNameLanguage', true);
    const cropSector = getValueFromMultiselect(values.speciesCropSector, 'cropSector');

    return `
        ${speciesLatinName}
        ${speciesCode}
        ${commonName}
        ${commonNameLanguage}
        ${cropSector}
  `;
};

export const getSearchSpeciesQuery = (values: any, pageSize: number, pageIndex: number) => {
    const searchParams = createSearchSpeciesParams(values);

    const searchQuery = `
    {
      speciesSearch(
        size:${pageSize}
        from:${pageSize * pageIndex}
        ${searchParams}
      ){
        total
        data {
          class_data {
            class_id
            class_type
            class_name
            class_type_value
          }
          species_id
          crop_sectors
          princ_bot
          other_bot
          comments
          species_upov
          en
          fr
          de
          es
        }
      }
    }`;

    return {
        query: gqlPrettier(searchQuery),
    };
};
