import React, {useEffect, useState} from 'react';
import AdminIcon from '../../CommonComponents/AdminIcon/AdminIcon';
import Empty from '~components/Empty';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import {History} from 'history';
import {ModalErrorVersion2} from '../../../../commonModals';
import NavigationTLO from '../../../../shared/NavigationTLO';
import {Prompt, RouteComponentProps, withRouter} from 'react-router-dom';
import SubMenuTabs from '../../SubMenu/SubMenuTabs';
import SelectInput from '~components/SelectInput';
import Title from '~components/Title';
import {Tree, TreeData} from '../../CommonComponents/TreeView/Interfaces/TreeViewInterfaces';
import TreeView from '../../CommonComponents/TreeView/TreeView';
import {
    apiCreateTree,
    apiDownloadTreeDocumentFile,
    apiFetchEOlistForTree,
    apiFetchTree,
} from '../../CommonApi/CommonApi';
import {getUserRoles} from '../../CommonFunctions/CommonFunctions';
import {getPreSignedURLFetchRequest} from '../../../../utils/requests';
import {injectIntl, IntlShape} from 'react-intl';
import {saveAs} from 'file-saver';
import styles from './DesignationAgreementPage.module.scss';
import {trackPageView} from '../../../../utils';

const modalScreenTypes = {
    ERROR: 'ERROR',
};

interface DesignationAgreementPageProps {
    intl: IntlShape;
    history: History;
}

const DesignationAgreementPage = (props: DesignationAgreementPageProps & RouteComponentProps) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [, setTreeList] = useState<TreeData>(JSON.parse(JSON.stringify({})));
    const [treeTypeId, setTreeTypeId] = useState(0);
    const [eoSpecificTree, setEoSpecificTree] = useState<Tree>(JSON.parse(JSON.stringify({})));
    const [eoSpecificTreeIsEdited, setEoSpecificTreeIsEdited] = useState(false);
    const [tag, setTag] = useState('designationAgreement');
    const [eoSpecificId, setEoSpecificId] = useState('0');
    const [eoList, setEoList] = useState<Array<{id: string; value: string}>>([]);
    const [userRoles] = useState(getUserRoles());

    const getMenuLinks = () => {
        return userRoles.indexOf('WETLO') === -1 &&
            userRoles.indexOf('TLOOF') === -1 &&
            userRoles.indexOf('EOADM') === -1
            ? []
            : [
                  {
                      id: 'DESIGNATION_AGREEMENT',
                      value: 'Designation Agreement',
                      path: 'tloMyContractDesignationAgreement',
                  },
                  {
                      id: 'SCOPE_OF_ENTRUSTMENT',
                      value: 'Scope of Entrustment',
                      path: 'tloMyContractScopeOfEntrustment',
                  },
                  {
                      id: 'CONSULT_MODIFY_SPECIES_DATA',
                      value: 'Consult / Modify Species Data',
                      path: 'tloMyContractConsultModifySpecies',
                  },
                  {id: 'REPORTING_DEADLINES', value: 'Reporting Deadlines', path: 'tloMyContractReportingDeadlines'},
                  {
                      id: 'REQUEST_NEW_ENTRUSTMENT',
                      value: 'Request New Entrustment',
                      path: 'tloMyContractRequestNewEntrustment',
                  },
                  {
                      id: 'WITHDRAW_AN_ENTRUSTMENT',
                      value: 'Withdraw an Entrustment',
                      path: 'tloMyContractWithdrawAnEntrustment',
                  },
                  {
                      id: 'NEW_SPECIES_PROCEDURE',
                      value: 'New Species Procedure',
                      path: 'tloMyContractNewSpeciesProcedure',
                  },
                  {
                      id: 'MANUAL',
                      value: 'Contract’s User Manual',
                      path: 'tlodocs/manual/TLO_user_manual_new_revised_by_LDE.pdf',
                  },
              ];
    };

    const treeTypes = [
        {id: 0, value: 'Please select Tree Type'},
        {id: 1, value: 'Designation Agreement EO Specific Tree'},
    ];

    useEffect(() => {
        trackPageView({documentTitle: 'tloMyContractDesignationAgreement'});
    }, []);

    useEffect(() => {
        if (eoSpecificTreeIsEdited) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }
    });

    const fetchEOlistForTree = () => {
        setLoading(true);
        apiFetchEOlistForTree()
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.eos.length > 0) {
                    const eoList: Array<{id: string; value: string}> = [
                        {id: '0', value: 'Please select EO specific tree'},
                    ];
                    jsonResponse.data.eos.forEach(eo => eoList.push({id: eo.eoId, value: eo.eoName}));
                    setEoList(eoList);
                }
            })
            .catch(error => {
                setErrorMessage(`Fetch EO List for tree error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const fetchTreeForNonAdminUser = () => {
        setLoading(true);
        apiFetchTree(tag)
            .then(jsonResponse => {
                if (jsonResponse) {
                    if (jsonResponse.eoSpecific && jsonResponse.eoSpecific.length > 0) {
                        setEoSpecificTree(jsonResponse.eoSpecific[0].document);
                        setTag(jsonResponse.eoSpecific[0].tag);
                    }
                }
            })
            .catch(error => {
                setErrorMessage(`Fetch Tree error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const fetchTree = () => {
        setLoading(true);
        apiFetchTree(tag, eoSpecificId)
            .then(jsonResponse => {
                if (jsonResponse) {
                    setTreeList(jsonResponse);
                    const eoSpecificTreeFromLS = localStorage.getItem(tag + eoSpecificId + 'EoSpecificTree');
                    if (jsonResponse.eoSpecific && eoSpecificTreeFromLS === null) {
                        if (jsonResponse.eoSpecific.length > 0) {
                            setEoSpecificTree(jsonResponse.eoSpecific[0].document);
                            setTag(jsonResponse.eoSpecific[0].tag);
                        } else {
                            setEoSpecificTree(JSON.parse(JSON.stringify({})));
                        }
                    }
                }
            })
            .catch(error => {
                setErrorMessage(`Fetch Tree error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (
            userRoles.indexOf('EOADM') === -1 &&
            (userRoles.indexOf('WETLO') !== -1 || userRoles.indexOf('TLOOF') !== -1)
        ) {
            fetchTreeForNonAdminUser();
        } else {
            if (userRoles.indexOf('EOADM') !== -1) {
                const eoSpecificTreeFromLS = localStorage.getItem(tag + eoSpecificId + 'EoSpecificTree');
                if (eoSpecificTreeFromLS === null) {
                    fetchTree();
                } else {
                    setEoSpecificTreeIsEdited(true);
                    setEoSpecificTree(JSON.parse(eoSpecificTreeFromLS));
                }
            }
        }
    }, []);

    const onTreeTypeIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const treeTypeId = event.target.value;
        setTreeTypeId(Number(treeTypeId));
    };

    useEffect(() => {
        treeTypeId === 1 && eoList.length === 0 && fetchEOlistForTree();
    }, [treeTypeId]);

    const onEoSpecificIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const eoSpecificId = event.target.value;
        setEoSpecificId(eoSpecificId);
    };

    useEffect(() => {
        if (Number(eoSpecificId) > 0) {
            const eoSpecificTreeFromLS = localStorage.getItem(tag + eoSpecificId + 'EoSpecificTree');
            if (eoSpecificTreeFromLS === null) {
                fetchTree();
                setEoSpecificTreeIsEdited(false);
            } else {
                setEoSpecificTreeIsEdited(true);
                setEoSpecificTree(JSON.parse(eoSpecificTreeFromLS));
            }
        }
    }, [eoSpecificId]);

    const closeModal = () => setModalScreen(null);

    const downloadDocumentFile = (fileUUID: string, fileName: string) => {
        setLoading(true);
        apiDownloadTreeDocumentFile(fileUUID)
            .then(response => {
                if (response && response.signedUrl) {
                    const {signedUrl} = response;
                    getPreSignedURLFetchRequest(signedUrl)
                        .then(response => response.blob())
                        .then(responseBlob => {
                            const saveFileName = fileName ? fileName : fileUUID;
                            saveAs(responseBlob, saveFileName);
                        });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading file: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const discardTreeChanges = (tag: string, typeOfTree: string) => {
        localStorage.removeItem(tag + eoSpecificId + typeOfTree + 'Tree');
        setEoSpecificTreeIsEdited(false);
        fetchTree();
    };

    const saveTree = (tree: Tree, typeOfTree: string) => {
        setLoading(true);
        const createTreeParams = Object.assign({}, {tree, tag, eoId: eoSpecificId});
        apiCreateTree(createTreeParams)
            .then(() => {
                discardTreeChanges(tag, typeOfTree);
            })
            .catch(error => {
                setErrorMessage(`error saving tree: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getComboboxArea = () => (
        <>
            <div className={styles.mainFieldContainer}>
                <SelectInput
                    value={treeTypeId}
                    onChange={onTreeTypeIdChange}
                    list={treeTypes}
                    outsideLabel={'Tree Type'}
                    outsideLabelWidth={160}
                    notAll={true}
                    double={true}
                />
                <div className={styles.adminIconArea}>
                    <AdminIcon />
                </div>
                {treeTypeId === 1 && (
                    <>
                        <div style={{clear: 'both'}} />
                        <SelectInput
                            value={eoSpecificId}
                            onChange={onEoSpecificIdChange}
                            list={eoList}
                            outsideLabel={'EO Specific Tree'}
                            outsideLabelWidth={160}
                            notAll={true}
                            double={true}
                        />
                    </>
                )}
            </div>
            <div style={{clear: 'both'}}>{}</div>
        </>
    );

    const getTrees = () => {
        return userRoles.indexOf('EOADM') !== -1 ? (
            <>
                {treeTypeId === 1 && Number(eoSpecificId) > 0 ? (
                    <>
                        <div style={{clear: 'both'}}>{}</div>
                        <TreeView
                            tree={eoSpecificTree}
                            treeIsEdited={eoSpecificTreeIsEdited}
                            tag={tag}
                            isGeneral={false}
                            eoSpecificId={eoSpecificId}
                            isAdmin={userRoles.indexOf('EOADM') !== -1}
                            downloadFile={downloadDocumentFile}
                            saveTree={saveTree}
                            discardTreeChanges={discardTreeChanges}
                            isACArea={false}
                        />
                    </>
                ) : null}
            </>
        ) : (
            <>
                {Object.keys(eoSpecificTree).length > 0 && (
                    <>
                        <Empty oneLine={true} height={25} />
                        <div style={{clear: 'both'}}>{}</div>
                        <Empty oneLine={true} width={13} height={0} />
                        <Title double={true}>{`My Designation Agreement`}</Title>
                        <TreeView
                            tree={eoSpecificTree}
                            treeIsEdited={eoSpecificTreeIsEdited}
                            tag={tag}
                            isGeneral={false}
                            eoSpecificId={eoSpecificId}
                            isAdmin={userRoles.indexOf('EOADM') !== -1}
                            downloadFile={downloadDocumentFile}
                            saveTree={saveTree}
                            discardTreeChanges={discardTreeChanges}
                            isACArea={false}
                        />
                    </>
                )}
            </>
        );
    };

    return (
        <>
            <Prompt when={eoSpecificTreeIsEdited} message="You have unsaved changes, are you sure you want to leave?" />
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeModal} />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`Technical Liaison Officer`} />
            <NavigationTLO />
            <MainWrapper>
                <div style={{marginBottom: 20, textAlign: 'center'}}>
                    <FormWrapper paddingFormContent={'sm'}>
                        {userRoles.indexOf('WETLO') === -1 &&
                        userRoles.indexOf('TLOOF') === -1 &&
                        userRoles.indexOf('EOADM') === -1 ? (
                            <div style={{paddingTop: 20}}>
                                <b style={{color: 'red'}}>
                                    <span className="ng-scope">{`You have no permissions to access this page.`}</span>
                                </b>
                            </div>
                        ) : (
                            <>
                                <SubMenuTabs
                                    tabId={'DESIGNATION_AGREEMENT'}
                                    loading={loading}
                                    menuLinks={getMenuLinks()}
                                />
                                {userRoles.indexOf('EOADM') !== -1 && getComboboxArea()}
                            </>
                        )}
                    </FormWrapper>
                    {(userRoles.indexOf('WETLO') !== -1 ||
                        userRoles.indexOf('TLOOF') !== -1 ||
                        userRoles.indexOf('EOADM') !== -1) &&
                        getTrees()}
                </div>
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(DesignationAgreementPage));
