import React, {useEffect, useRef, useState} from 'react';
import {
    ConsultModifySpeciesDataFilterCriteriaData,
    ConsultModifySpeciesDataTableRowData,
    DraftEntrustment,
    DraftEntrustmentFetchData,
    EditRowsSpecies,
    Entrustment,
    EntrustmentFetchData,
    EntrustmentTypesNamesFetchData,
    GenusesNamesFetchData,
    ModifySpeciesModal,
} from '../../../../types/TLO';
import ConsultModifySpeciesDataTableArea from './Components/ConsultModifySpeciesDataTableArea/ConsultModifySpeciesDataTableArea';
import ConsultModifySpeciesDataFilterCriteriaArea from './Components/ConsultModifySpeciesDataFilterCriteriaArea/ConsultModifySpeciesDataFilterCriteriaArea';
import {EntrustmentForSave} from './Components/ModalEntrustment/Interfaces/ModalEntrustmentInterfaces';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import {ModalAlertVersion2, ModalErrorVersion2} from '../../../../commonModals';
import ModalEntrustment, {entrustmentEditMode, entrustmentMode} from './Components/ModalEntrustment/ModalEntrustment';
import ModalSelectSpecies from './Components/ModalSelectSpecies/ModalSelectSpecies';
import NavigationTLO from '../../../../shared/NavigationTLO';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import SubMenuTabs from '../../SubMenu/SubMenuTabs';
import {SpeciesNamesFetchData} from '../../CommonInterfaces/CommonInterfaces';
import Title from '~components/Title';
import {
    apiConsultModifySpeciesSearch,
    apiDraftEntrustmentFetch,
    apiEntrustmentFetch,
    apiEntrustmentMassSubmitToCPVO,
    apiEntrustmentSubmitToCPVO,
    apiEntrustmentTypesAutocompletionFilterFetch,
    apiGenusesAutocompletionFilterFetch,
} from './ConsultModifySpeciesDataPageService';
import {apiSpeciesAutocompletionFilterFetch} from '../../CommonApi/CommonApi';
import {getUserRoles} from '../../CommonFunctions/CommonFunctions';
import {getExcelSignedURLFetchRequest} from '../../../../commonApi/download';
import {getPreSignedURLFetchRequest} from '../../../../utils/requests';
import {injectIntl, IntlShape} from 'react-intl';
import moment from 'moment';
import {saveAs} from 'file-saver';
import {History} from 'history';
import {trackPageView} from '../../../../utils';

const modalScreenTypes = {
    ERROR: 'ERROR',
    INFO: 'INFO',
    MODAL_ENTRUSTMENT: 'MODAL_ENTRUSTMENT',
    MODAL_SELECT_SPECIES: 'MODAL_SELECT_SPECIES',
    MODAL_SEVERAL_PLANT_REQUIREMENTS_SUCCESS: 'MODAL_SEVERAL_PLANT_REQUIREMENTS_SUCCESS',
};

const DEFAULT_CRITERIA: ConsultModifySpeciesDataFilterCriteriaData = {
    speciesName: '',
    speciesNameFilter: 'starts',
    speciesIds: '',
    genus: '',
    genusFilter: 'starts',
    genusIds: '',
    entrustmentType: '',
    entrustmentTypeFilter: 'starts',
    entrustmentTypeIds: '',
    //
    pageNumber: 1,
    pageSize: Number(localStorage.getItem('pageSizeconsultSpecies')) || 50,
    refresh: false,
    excel: false,
    order: 'speciesName',
    reverse: false,
};

const DATEFORMAT_ENTRUSTMENT_KEYS = [
    'startDate',
    'endDate',
    'closingDate',
    'foreseenFinalReport',
    'interimReportDate',
    'decisionDate',
];

export const ACCESS_MESSAGES = {
    COMPLETE_ACCESS: 'You have complete access: consultation, modification, deletion and request of new entrustment.',
    CONSULTATION_ACCESS: 'You have access for consultation only.',
    NO_ACCESS: 'You do not have access.',
};

const DEFAULT_COUNT = 0;

interface ConsultModifySpeciesDataPageProps {
    intl: IntlShape;
    history: History;
}

const ConsultModifySpeciesDataPage = (props: ConsultModifySpeciesDataPageProps & RouteComponentProps) => {
    const [criteria, setCriteria] = useState<ConsultModifySpeciesDataFilterCriteriaData>(
        Object.assign({}, DEFAULT_CRITERIA)
    );
    const [, setCriteriaCount] = useState(0);
    const [species, setSpecies] = useState<Array<ConsultModifySpeciesDataTableRowData> | null>(null);
    const [speciesListByIdName, setSpeciesListByIdName] = useState<Array<{ID: string; NAME: string}> | null>(null);
    const [speciesListByIdNameExamId, setSpeciesListByIdNameExamId] = useState<Array<{
        ID: string;
        NAME: string;
        EXAMID: number;
    }> | null>(null);
    const [speciesNameHash, setSpeciesNameHash] = useState<{[key: string]: Array<string>} | null>(null);
    const [selectedSpecies, setSelectedSpecies] = useState<{[key: string]: string}>({});
    const [genusesListByIdName, setGenusesListByIdName] = useState<Array<{ID: string; NAME: string}> | null>(null);
    const [genusesNameHash, setGenusesNameHash] = useState<{[key: string]: Array<string>} | null>(null);
    const [selectedGenuses, setSelectedGenuses] = useState<{[key: string]: string}>({});
    const [entrustmentTypesListByIdName, setEntrustmentTypesListByIdName] = useState<Array<{
        ID: string;
        NAME: string;
    }> | null>(null);
    const [entrustmentTypesNameHash, setEntrustmentTypesNameHash] = useState<{[key: string]: Array<string>} | null>(
        null
    );
    const [selectedEntrustmentTypes, setSelectedEntrustmentTypes] = useState<{[key: string]: string}>({});
    const [timestamp, setTimestamp] = useState(0);
    const [count, setCount] = useState(DEFAULT_COUNT);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [infoMessage, setInfoMessage] = useState<string>('');
    const [modifySeveralPlantRequirementsSuccessMessage, setModifySeveralPlantRequirementsSuccessMessage] =
        useState<string>('');
    const [refresh, setRefresh] = useState(true);
    const [, setDefaultOrder] = useState('speciesName');
    const [, setReverseOrder] = useState(false);
    const [editMode, setEditMode] = useState<string>(entrustmentEditMode.EDIT);
    const [speciesExamId, setSpeciesExamId] = useState<number | null>(null);
    const [modifType, setModifType] = useState<number | string | null>(null);
    const [entrustmentObject, setEntrustmentObject] = useState<Entrustment | DraftEntrustment | null>(null);
    const [entrustmentObjectFromSpeciesModalLoad, setEntrustmentObjectFromSpeciesModalLoad] = useState<boolean>(false);
    const [accessMessage, setAccessMessage] = useState('');
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [userRoles] = useState(getUserRoles());
    const firstLoad = useFirstRender();

    const [modifySeveralPlantRequirements, setModifySeveralPlantRequirements] = useState<boolean>(false);
    const [editRowsSpeciesForSubmit, setEditRowsSpeciesForSubmit] = useState<Array<EditRowsSpecies>>([]);
    const [speciesForModalModifySeveralPlantRequirements, setSpeciesForModalModifySeveralPlantRequirements] =
        useState<Array<ConsultModifySpeciesDataTableRowData> | null>(null);
    const [countForModalModifySeveralPlantRequirements, setCountForModalModifySeveralPlantRequirements] =
        useState<number>(0);

    const [selectedModifySpecies, setSelectedModifySpecies] = useState<{[key: string]: string}>({});
    const [modifySpecies, setModifySpecies] = useState<ModifySpeciesModal>({
        modifySpeciesName: '',
        modifySpeciesNameFilter: 'starts',
        modifySpeciesIds: '',
    });

    useEffect(() => {
        trackPageView({documentTitle: 'tloMyContractConsultModifySpecies'});
    }, []);

    function useFirstRender() {
        const firstRender = useRef(true);

        useEffect(() => {
            firstRender.current = false;
        }, []);

        return firstRender.current;
    }

    const getMenuLinks = () => {
        return userRoles.indexOf('WETLO') === -1 &&
            userRoles.indexOf('TLOOF') === -1 &&
            userRoles.indexOf('EOADM') === -1
            ? []
            : [
                  {
                      id: 'DESIGNATION_AGREEMENT',
                      value: 'Designation Agreement',
                      path: 'tloMyContractDesignationAgreement',
                  },
                  {
                      id: 'SCOPE_OF_ENTRUSTMENT',
                      value: 'Scope of Entrustment',
                      path: 'tloMyContractScopeOfEntrustment',
                  },
                  {
                      id: 'CONSULT_MODIFY_SPECIES_DATA',
                      value: 'Consult / Modify Species Data',
                      path: 'tloMyContractConsultModifySpecies',
                  },
                  {id: 'REPORTING_DEADLINES', value: 'Reporting Deadlines', path: 'tloMyContractReportingDeadlines'},
                  {
                      id: 'REQUEST_NEW_ENTRUSTMENT',
                      value: 'Request New Entrustment',
                      path: 'tloMyContractRequestNewEntrustment',
                  },
                  {
                      id: 'WITHDRAW_AN_ENTRUSTMENT',
                      value: 'Withdraw an Entrustment',
                      path: 'tloMyContractWithdrawAnEntrustment',
                  },
                  {
                      id: 'NEW_SPECIES_PROCEDURE',
                      value: 'New Species Procedure',
                      path: 'tloMyContractNewSpeciesProcedure',
                  },
                  {
                      id: 'MANUAL',
                      value: 'Contract’s User Manual',
                      path: 'tlodocs/manual/TLO_user_manual_new_revised_by_LDE.pdf',
                  },
              ];
    };

    const getAccessMessagesPerUserRole = (userRoles: Array<string>) => {
        if (userRoles.length > 0) {
            if (userRoles.indexOf('TLOOF') !== -1 || userRoles.indexOf('EOADM') !== -1) {
                return ACCESS_MESSAGES.COMPLETE_ACCESS;
            } else if (userRoles.indexOf('WETLO') !== -1) {
                return ACCESS_MESSAGES.CONSULTATION_ACCESS;
            } else {
                return ACCESS_MESSAGES.NO_ACCESS;
            }
        } else {
            return '';
        }
    };

    const countCriteria = (consultModifySpeciesCriteria: ConsultModifySpeciesDataFilterCriteriaData) => {
        let countNum = 0;
        Object.keys(consultModifySpeciesCriteria).forEach(key => {
            if (
                consultModifySpeciesCriteria[key] &&
                consultModifySpeciesCriteria[key] !== DEFAULT_CRITERIA[key] &&
                consultModifySpeciesCriteria[key] !== ''
            )
                countNum++;
        });
        return countNum;
    };

    const parseCriteria = (
        unParsedCriteria: ConsultModifySpeciesDataFilterCriteriaData
    ): ConsultModifySpeciesDataFilterCriteriaData => {
        const parsedCriteria: ConsultModifySpeciesDataFilterCriteriaData = JSON.parse(JSON.stringify({}));
        Object.keys(unParsedCriteria).forEach(key => {
            if (key === 'genusFilter' && !criteria['genus'] && !criteria['genusIds']) {
                unParsedCriteria[key] = DEFAULT_CRITERIA[key];
            } else if (
                key === 'entrustmentTypeFilter' &&
                !criteria['entrustmentType'] &&
                !criteria['entrustmentTypeIds']
            ) {
                unParsedCriteria[key] = DEFAULT_CRITERIA[key];
            } else if (key === 'speciesNameFilter' && !criteria['speciesName'] && !criteria['speciesIds']) {
                unParsedCriteria[key] = DEFAULT_CRITERIA[key];
            } else {
                parsedCriteria[key] = unParsedCriteria[key];
            }
        });

        return parsedCriteria;
    };

    const buildParams = (parsedCriteria: ConsultModifySpeciesDataFilterCriteriaData) => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(key => parsedCriteria[key] !== DEFAULT_CRITERIA[key] && key !== 'refresh')
            .map(
                key =>
                    `${key}=${
                        key === 'speciesIds' || key === 'speciesName'
                            ? encodeURIComponent(parsedCriteria[key])
                            : parsedCriteria[key]
                    }`
            );
        const joinedParamsText = `?${paramArray.join('&')}`;
        props.history.replace(`/tloMyContractConsultModifySpecies${(paramArray.length > 0 && joinedParamsText) || ''}`);
    };

    const getConsultModifySpeciesList = (
        parsedCriteria: ConsultModifySpeciesDataFilterCriteriaData,
        shouldRefresh: boolean,
        urlLoad = false
    ) => {
        apiConsultModifySpeciesSearch(parsedCriteria, {})
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.species) {
                    const newCriteria = Object.assign({}, criteria, {pageNumber: 1});
                    if (!shouldRefresh && !urlLoad) {
                        setCriteria(Object.assign({}, newCriteria));
                    }
                    jsonResponse.data.species.forEach((el: any) => (el['modifyCheckbox'] = false));
                    setSpecies(jsonResponse.data.species);
                    setTimestamp(Date.now());
                    if (jsonResponse.count !== undefined) {
                        setCount(jsonResponse.count);
                    }
                }
            })
            .catch(error => {
                setErrorMessage(`Consult / Modify Species Data search list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const search = (shouldRefresh: boolean, urlLoad = false) => {
        if (userRoles.indexOf('EOADM') !== -1) {
            setInfoMessage(`Your account is not linked to an EO office.`);
            setModalScreen(modalScreenTypes.INFO);
        } else {
            setLoading(true);
            setCriteria(
                Object.assign({}, criteria, {refresh: !!shouldRefresh}, !shouldRefresh && !urlLoad && {pageNumber: 1})
            );
            const parsedCriteria = parseCriteria(criteria);
            buildParams(parsedCriteria);
            getConsultModifySpeciesList(parsedCriteria, shouldRefresh, urlLoad);
        }
    };

    const loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const params = domainArray.pop();
            if (params) {
                params.split('&').forEach(param => {
                    const paramElements = param.split('=');
                    if (paramElements.length === 2) {
                        const key = paramElements[0];
                        criteria[key] = decodeURIComponent(paramElements[1]);
                    }
                });
                if (Object.keys(criteria).length > 0) {
                    setCriteria(Object.assign({}, criteria));
                    criteria.order && setDefaultOrder(criteria.order);
                    criteria.reverse && setReverseOrder(criteria.reverse);
                    setCriteriaCount(countCriteria(criteria));
                    species && species.length === 0 && search(false, true);
                }
            }
        }
    };

    const closeErrorModal = () => {
        setModalScreen(null);
        if (accessMessage === ACCESS_MESSAGES.NO_ACCESS) {
            setErrorMessage(null);
            props.history.replace(`/TLO`);
        } else {
            setErrorMessage(null);
        }
    };

    const closeInfoModal = () => {
        setInfoMessage('');
        setModalScreen(null);
    };

    const getSpeciesAutocompletionList = (selectedSpecies: {[key: string]: string}) =>
        apiSpeciesAutocompletionFilterFetch(criteria.speciesName, criteria.speciesNameFilter)
            .then((jsonResponse: SpeciesNamesFetchData) => {
                const speciesNameHash: {[key: string]: Array<string>} = {};
                const speciesNameHashExamId: {
                    [key: string]: Array<{['ID']: string; ['NAME']: string; ['EXAMID']: number}>;
                } = {};
                ((jsonResponse && jsonResponse.data && jsonResponse.data.speciesNamesList) || []).forEach(
                    ({speciesName, speciesExamId}) => {
                        if (!speciesNameHash[speciesName]) {
                            speciesNameHash[speciesName] = [];
                        }
                        speciesNameHash[speciesName].push(speciesName);
                        if (speciesExamId > 0) {
                            speciesNameHashExamId[speciesName] = [];
                            speciesNameHashExamId[speciesName].push({
                                ID: speciesName,
                                NAME: speciesName,
                                EXAMID: speciesExamId,
                            });
                        }
                    }
                );
                const speciesNameListByIdName = Object.keys(speciesNameHash).map(snn => ({
                    ID: speciesNameHash[snn].join(','),
                    NAME: snn,
                }));
                const selectedSpeciesObj: {[key: string]: string} = {};
                const selectedSpeciesKeyArray = Object.keys(selectedSpecies || {});
                speciesNameListByIdName
                    .filter(i => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectedSpeciesObj[item.ID] = item.NAME));

                const speciesNameListByIdNameExamId = Object.keys(speciesNameHashExamId).map(snn => ({
                    ID: snn,
                    NAME: snn,
                    EXAMID: 0,
                }));
                speciesNameListByIdNameExamId.forEach(el => {
                    el.EXAMID =
                        speciesNameHashExamId[el.ID] !== undefined ? speciesNameHashExamId[el.ID][0]['EXAMID'] : 0;
                });
                setSpeciesNameHash(speciesNameHash);
                setSpeciesListByIdName(speciesNameListByIdName);
                setSpeciesListByIdNameExamId(speciesNameListByIdNameExamId);
                setSelectedSpecies(selectedSpeciesObj);
            })
            .catch(error => {
                setLoading(false);
                setErrorMessage(`Species Names Autocompletion list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            });

    const getGenusesAutocompletionList = (selectedGenuses: {[key: string]: string}) => {
        apiGenusesAutocompletionFilterFetch(criteria.genus, criteria.genusFilter)
            .then((jsonResponse: GenusesNamesFetchData) => {
                const genusesNameHash: {[key: string]: Array<string>} = {};
                ((jsonResponse && jsonResponse.data && jsonResponse.data.genusList) || []).forEach(gn => {
                    if (!genusesNameHash[gn.genus]) {
                        genusesNameHash[gn.genus] = [];
                    }
                    genusesNameHash[gn.genus].push(gn.genus);
                });
                const genusesNameListByIdName = Object.keys(genusesNameHash).map(gnn => ({
                    ID: genusesNameHash[gnn].join(','),
                    NAME: gnn,
                }));
                const selectedGenusesObj: {[key: string]: string} = {};
                const selectedGenusesKeyArray = Object.keys(selectedGenuses || {});
                genusesNameListByIdName
                    .filter(i => selectedGenusesKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectedGenusesObj[item.ID] = item.NAME));
                setGenusesNameHash(genusesNameHash);
                setGenusesListByIdName(genusesNameListByIdName);
                setSelectedGenuses(selectedGenusesObj);
            })
            .catch(error => {
                setLoading(false);
                setErrorMessage(`Genuses Names Autocompletion list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            });
    };

    const getEntrustmentTypesAutocompletionList = (selectedEntrustmentTypes: {[key: string]: string}) => {
        apiEntrustmentTypesAutocompletionFilterFetch(criteria.entrustmentType, criteria.entrustmentTypeFilter)
            .then((jsonResponse: EntrustmentTypesNamesFetchData) => {
                const entrustmentTypesNameHash: {[key: string]: Array<string>} = {};
                ((jsonResponse && jsonResponse.data && jsonResponse.data.entrustmentTypeList) || []).forEach(etn => {
                    if (!entrustmentTypesNameHash[etn.entrustmentType]) {
                        entrustmentTypesNameHash[etn.entrustmentType] = [];
                    }
                    entrustmentTypesNameHash[etn.entrustmentType].push(etn.entrustmentType);
                });
                const entrustmentTypesNameListByIdName = Object.keys(entrustmentTypesNameHash).map(etnn => ({
                    ID: entrustmentTypesNameHash[etnn].join(','),
                    NAME: etnn,
                }));
                const selectedEntrustmentTypesObj: {[key: string]: string} = {};
                const selectedEntrustmentTypesKeyArray = Object.keys(selectedEntrustmentTypes || {});
                entrustmentTypesNameListByIdName
                    .filter(i => selectedEntrustmentTypesKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectedEntrustmentTypesObj[item.ID] = item.NAME));
                setEntrustmentTypesNameHash(entrustmentTypesNameHash);
                setEntrustmentTypesListByIdName(entrustmentTypesNameListByIdName);
                setSelectedEntrustmentTypes(selectedEntrustmentTypesObj);
            })
            .catch(error => {
                setLoading(false);
                setErrorMessage(`Entrustment Types Names Autocompletion list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            });
    };

    useEffect(() => {
        if (
            (userRoles.indexOf('WETLO') !== -1 ||
                userRoles.indexOf('TLOOF') !== -1 ||
                userRoles.indexOf('EOADM') !== -1) &&
            firstLoad
        ) {
            setAccessMessage(getAccessMessagesPerUserRole(userRoles));
            if (accessMessage === ACCESS_MESSAGES.NO_ACCESS) {
                setErrorMessage(ACCESS_MESSAGES.NO_ACCESS);
                setModalScreen(modalScreenTypes.ERROR);
            } else {
                loadUrlParams();
                criteria.pageSize = Number(localStorage.getItem('pageSizeconsultSpecies')) || 50;
                setCriteria(Object.assign({}, criteria));
                const selectedSpecies: {[key: string]: string} = {};
                if (criteria.speciesIds && criteria.speciesIds !== '') {
                    criteria.speciesIds.split(',').forEach(speciesId => (selectedSpecies[speciesId] = ''));
                }
                getSpeciesAutocompletionList(selectedSpecies);
                const selectedGenuses: {[key: string]: string} = {};
                if (criteria.genusIds && criteria.genusIds !== '') {
                    criteria.genusIds.split(',').forEach(genusId => (selectedGenuses[genusId] = ''));
                }
                getGenusesAutocompletionList(selectedGenuses);
                const selectedEntrustmentTypes: {[key: string]: string} = {};
                if (criteria.entrustmentTypeIds && criteria.entrustmentTypeIds !== '') {
                    criteria.entrustmentTypeIds
                        .split(',')
                        .forEach(entrustmentTypeId => (selectedEntrustmentTypes[entrustmentTypeId] = ''));
                }
                getEntrustmentTypesAutocompletionList(selectedEntrustmentTypes);
            }
        }
    }, []);

    useEffect(() => {
        !firstLoad && refresh && species && search(true);
    }, [criteria.pageNumber, criteria.pageSize, criteria.order, criteria.reverse]);

    useEffect(() => {
        (userRoles.indexOf('WETLO') !== -1 || userRoles.indexOf('TLOOF') !== -1 || userRoles.indexOf('EOADM') !== -1) &&
            firstLoad &&
            accessMessage !== ACCESS_MESSAGES.NO_ACCESS &&
            search(false, true);
    }, [criteria.order, criteria.reverse]);

    const closeModalEntrustments = (refresh = false) => {
        setModalScreen(null);
        setEntrustmentObject(null);
        setSpeciesExamId(null);
        setModifType(null);
        refresh && search(refresh);
    };

    const closeModalSelectSpecies = (refresh = false) => {
        setModalScreen(null);
        setEntrustmentObject(null);
        setSpeciesExamId(null);
        setModifType(null);
        setEditRowsSpeciesForSubmit([]);
        setSelectedModifySpecies({});
        refresh && search(refresh);
    };

    const parseEntrustment = (rowObject: Entrustment | DraftEntrustment): Entrustment | DraftEntrustment => {
        const parsedEntrustment = Object.assign({}, rowObject);
        Object.keys(rowObject).forEach(key => {
            if (DATEFORMAT_ENTRUSTMENT_KEYS.includes(key)) {
                const date = rowObject[key];
                if (date !== null) {
                    parsedEntrustment[key] = moment(date).format('DD/MM/YYYY');
                } else {
                    parsedEntrustment[key] = date;
                }
            } else {
                parsedEntrustment[key] = rowObject[key];
            }
        });

        return parsedEntrustment;
    };

    const getEntrustmentData = (rowObject: ConsultModifySpeciesDataTableRowData, editMode: string) => {
        setLoading(true);
        if (rowObject.speciesExamId !== 0) {
            apiEntrustmentFetch(rowObject.speciesExamId, rowObject.modifId)
                .then((jsonResponse: EntrustmentFetchData) => {
                    if (
                        jsonResponse &&
                        jsonResponse.data &&
                        jsonResponse.data.entrustment &&
                        jsonResponse.data.entrustment.length > 0
                    ) {
                        const entrustmentData = parseEntrustment(jsonResponse.data.entrustment[0]);
                        setEntrustmentObject(entrustmentData);
                        setSpeciesExamId(rowObject.speciesExamId);
                        setModifType(rowObject.modifType);
                        setEditMode(editMode);
                        setModalScreen(modalScreenTypes.MODAL_ENTRUSTMENT);
                    }
                })
                .catch(error => {
                    setErrorMessage(`Entrustment data error: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        } else {
            apiDraftEntrustmentFetch(rowObject.modifId)
                .then((jsonResponse: DraftEntrustmentFetchData) => {
                    if (
                        jsonResponse &&
                        jsonResponse.data &&
                        jsonResponse.data.draftEntrustment &&
                        jsonResponse.data.draftEntrustment.length === 1
                    ) {
                        const draftEntrustmentData = parseEntrustment(jsonResponse.data.draftEntrustment[0]);
                        setEntrustmentObject(draftEntrustmentData);
                        setSpeciesExamId(rowObject.speciesExamId);
                        setModifType(rowObject.modifType);
                        setEditMode(editMode);
                        setModalScreen(modalScreenTypes.MODAL_ENTRUSTMENT);
                    }
                })
                .catch(error => {
                    setErrorMessage(`Draft / New Entrustment data error: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        }
    };

    const getEntrustmentDataFromSpeciesModal = (speciesExamId: number, modifId: number, editMode: string) => {
        setLoading(true);
        if (speciesExamId !== 0) {
            apiEntrustmentFetch(speciesExamId, modifId)
                .then((jsonResponse: EntrustmentFetchData) => {
                    if (
                        jsonResponse &&
                        jsonResponse.data &&
                        jsonResponse.data.entrustment &&
                        jsonResponse.data.entrustment.length > 0
                    ) {
                        const entrustmentData = parseEntrustment(jsonResponse.data.entrustment[0]);
                        setEntrustmentObject(entrustmentData);
                        setSpeciesExamId(speciesExamId);
                        setModifType(modifType);
                        setEditMode(editMode);
                        setModalScreen(modalScreenTypes.MODAL_ENTRUSTMENT);
                        setEntrustmentObjectFromSpeciesModalLoad(true);
                    }
                })
                .catch(error => {
                    setErrorMessage(`Entrustment data error: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        } else {
            apiDraftEntrustmentFetch(modifId)
                .then((jsonResponse: DraftEntrustmentFetchData) => {
                    if (
                        jsonResponse &&
                        jsonResponse.data &&
                        jsonResponse.data.draftEntrustment &&
                        jsonResponse.data.draftEntrustment.length === 1
                    ) {
                        const draftEntrustmentData = parseEntrustment(jsonResponse.data.draftEntrustment[0]);
                        setEntrustmentObject(draftEntrustmentData);
                        setSpeciesExamId(speciesExamId);
                        setModifType(modifType);
                        setEditMode(editMode);
                        setModalScreen(modalScreenTypes.MODAL_ENTRUSTMENT);
                        setEntrustmentObjectFromSpeciesModalLoad(true);
                    }
                })
                .catch(error => {
                    setErrorMessage(`Draft / New Entrustment data error: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        }
    };

    const showModalEntrustmentScreen = (
        rowObject: ConsultModifySpeciesDataTableRowData,
        editMode: string,
        modalModifySeveralPlantRequirements: boolean = false
    ) => {
        getEntrustmentData(rowObject, editMode);
        setModifySeveralPlantRequirements(modalModifySeveralPlantRequirements);
    };

    const showModalSelectSpecies = (editRowsSpecies: Array<EditRowsSpecies> = []) => {
        setEditRowsSpeciesForSubmit(editRowsSpecies);
        const speciesForModalModify: Array<ConsultModifySpeciesDataTableRowData> = [];
        const speciesLength = species ? species.length : 0;
        for (let i = 0; i < speciesLength; i++) {
            for (let j = 0; j < editRowsSpecies.length; j++) {
                if (species && species[i].speciesExamId === editRowsSpecies[j].speciesExamId) {
                    speciesForModalModify.push(Object.assign({}, species[i]));
                }
            }
        }
        setSpeciesForModalModifySeveralPlantRequirements(speciesForModalModify);
        setCountForModalModifySeveralPlantRequirements(speciesForModalModify ? speciesForModalModify.length : 0);
        setModalScreen(modalScreenTypes.MODAL_SELECT_SPECIES);
    };

    const showModalModifySeveralPlantRequirements = (editMode: string, speciesExamId: any = null) => {
        const modifId = 0;
        let examId = 0;
        speciesListByIdNameExamId?.forEach(el => {
            if (el.NAME === modifySpecies.modifySpeciesIds) {
                examId = el.EXAMID;
            }
        });
        getEntrustmentDataFromSpeciesModal(speciesExamId || examId, modifId, editMode);
        setModifySeveralPlantRequirements(true);
        setModalScreen(modalScreenTypes.MODAL_ENTRUSTMENT);
    };

    const showModalError = (errorMeassage: string) => {
        setErrorMessage(`${errorMeassage}`);
        setModalScreen(modalScreenTypes.ERROR);
    };

    const submitToTheCPVO = (entrustmentDataForSave: EntrustmentForSave) => {
        setLoading(true);
        if (editRowsSpeciesForSubmit.length === 0) {
            apiEntrustmentSubmitToCPVO(Object.assign({}, entrustmentDataForSave))
                .then(() => closeModalEntrustments(true))
                .catch(error => {
                    setErrorMessage(`error submiting to CPVO: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        } else {
            let entrustmentDataForSaveMassSave: Array<EntrustmentForSave> = [];
            let newEntrustmentDataForSave: EntrustmentForSave = entrustmentDataForSave;
            let successMessage: string = 'Your request for modify for the species ';
            let successMessageArray: Array<string> = [];
            editRowsSpeciesForSubmit.forEach(el => {
                newEntrustmentDataForSave.speciesExamId = el.speciesExamId;
                newEntrustmentDataForSave.speciesId = el.speciesId;
                newEntrustmentDataForSave.speciesName = el.speciesName;
                entrustmentDataForSaveMassSave.push(Object.assign({}, newEntrustmentDataForSave));
                successMessageArray.push(el.speciesName);
            });
            successMessage += successMessageArray.join(', ');
            successMessage += ' has been submitted to the CPVO.';

            apiEntrustmentMassSubmitToCPVO(entrustmentDataForSaveMassSave)
                .then(() => {
                    closeModalEntrustments(true);
                    setModalScreen(modalScreenTypes.MODAL_SEVERAL_PLANT_REQUIREMENTS_SUCCESS);
                    setModifySeveralPlantRequirementsSuccessMessage(successMessage);
                })
                .catch(error => {
                    setErrorMessage(`error submiting to CPVO: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => {
                    setSelectedModifySpecies({});
                    setModifySpecies({modifySpeciesName: '', modifySpeciesNameFilter: 'starts', modifySpeciesIds: ''});
                    setLoading(false);
                });
        }
    };

    const printExcel = (excelTranslations: {[key: string]: string}) => {
        setLoading(true);
        const parsedCriteria = Object.assign({}, parseCriteria(criteria), {excel: true});
        apiConsultModifySpeciesSearch(parsedCriteria, excelTranslations)
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                    getExcelSignedURLFetchRequest(jsonResponse.data.token).then(response => {
                        if (response && response.signedUrl) {
                            const {signedUrl} = response;
                            getPreSignedURLFetchRequest(signedUrl)
                                .then(response => response.blob())
                                .then(responseBlob => {
                                    saveAs(responseBlob, `Contract_overview_${moment().format('DD/MM/YYYY')}.xlsx`);
                                });
                        }
                    });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const updateCriteriaValue = (
        // eslint-disable-next-line no-undef
        criteriaValue: Partial<ConsultModifySpeciesDataFilterCriteriaData>,
        callback = () => {},
        shouldRefresh = true
    ) => {
        setRefresh(shouldRefresh);
        if (criteriaValue !== undefined) {
            setCriteria({...criteria, ...criteriaValue});
        }
        setCriteriaCount(countCriteria(criteria));
        callback && callback();
    };

    const updateSelectedSpecies = (selectedSpecies: {[key: string]: string}) => {
        setSelectedSpecies(selectedSpecies);
    };

    const updateModifySpeciesValue = (
        // eslint-disable-next-line no-undef
        modifySpeciesValue: Partial<ModifySpeciesModal>,
        callback = () => {},
        shouldRefresh = true
    ) => {
        setRefresh(shouldRefresh);
        if (modifySpeciesValue !== undefined) {
            setModifySpecies({...modifySpecies, ...modifySpeciesValue});
        }
        callback && callback();
    };

    const updateSelectedModifySpecies = (selectedModifySpeciesNew: {[key: string]: string}) =>
        setSelectedModifySpecies(selectedModifySpeciesNew);

    const updateSelectedGenuses = (selectedGenuses: {[key: string]: string}) => setSelectedGenuses(selectedGenuses);

    const updateSelectedEntrustmentTypes = (selectedEntrustmentTypes: {[key: string]: string}) =>
        setSelectedEntrustmentTypes(selectedEntrustmentTypes);

    const resetCriteria = () => {
        setLoading(true);
        setSpecies(null);
        setSelectedSpecies({});
        setSelectedGenuses({});
        setSelectedEntrustmentTypes({});
        setCriteria(Object.assign({}, DEFAULT_CRITERIA, criteria.pageSize));
        setCriteriaCount(0);
        const parsedCriteria = parseCriteria(DEFAULT_CRITERIA);
        buildParams(parsedCriteria);
        getConsultModifySpeciesList(parsedCriteria, true, false);
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {modalScreen === modalScreenTypes.INFO ? (
                <ModalAlertVersion2 title={'Info'} message={infoMessage} close={closeInfoModal} />
            ) : null}
            {modalScreen === modalScreenTypes.MODAL_ENTRUSTMENT &&
            entrustmentObject === null &&
            entrustmentObjectFromSpeciesModalLoad ? (
                <ModalAlertVersion2
                    title={'Info'}
                    message={`${'There is no entrustment for the selected species.'}`}
                    close={closeInfoModal}
                />
            ) : null}
            {modalScreen === modalScreenTypes.MODAL_SEVERAL_PLANT_REQUIREMENTS_SUCCESS ? (
                <ModalAlertVersion2
                    title={'Info'}
                    message={modifySeveralPlantRequirementsSuccessMessage}
                    close={closeInfoModal}
                />
            ) : null}
            {modalScreen === modalScreenTypes.MODAL_ENTRUSTMENT && entrustmentObject && speciesExamId !== null ? (
                <ModalEntrustment
                    speciesExamId={speciesExamId}
                    modifType={modifType}
                    entrustmentObject={entrustmentObject}
                    entrustmentMode={entrustmentMode.CONSULT_MODIFY}
                    entrustmentEditMode={editMode}
                    accessMessage={accessMessage}
                    modifySeveralPlantRequirements={modifySeveralPlantRequirements}
                    submitToTheCPVO={submitToTheCPVO}
                    close={closeModalEntrustments}
                />
            ) : null}
            {modalScreen === modalScreenTypes.MODAL_SELECT_SPECIES && editRowsSpeciesForSubmit ? (
                <ModalSelectSpecies
                    editRowsSpecies={editRowsSpeciesForSubmit}
                    speciesForModalModifySeveralPlantRequirements={speciesForModalModifySeveralPlantRequirements}
                    count={countForModalModifySeveralPlantRequirements}
                    timestamp={timestamp}
                    modifySpecies={modifySpecies}
                    speciesListByIdNameExamId={speciesListByIdNameExamId}
                    speciesNameHash={speciesNameHash}
                    selectedModifySpecies={selectedModifySpecies}
                    updateSelectedModifySpecies={updateSelectedModifySpecies}
                    updateModifySpeciesValue={updateModifySpeciesValue}
                    actionButtonClickCModifySeveralPlantRequirements={showModalModifySeveralPlantRequirements}
                    close={closeModalSelectSpecies}
                />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`Technical Liaison Officer`} />
            <NavigationTLO activeTitle={`My Contract`} />
            <MainWrapper>
                <FormWrapper paddingFormContent={'sm'} style={{marginBottom: 20}}>
                    {userRoles.indexOf('WETLO') === -1 &&
                    userRoles.indexOf('TLOOF') === -1 &&
                    userRoles.indexOf('EOADM') === -1 ? (
                        <div style={{paddingTop: 20}}>
                            <b style={{color: 'red'}}>
                                <span className="ng-scope">{`You have no permissions to access this page.`}</span>
                            </b>
                        </div>
                    ) : (
                        <>
                            <SubMenuTabs
                                tabId={'CONSULT_MODIFY_SPECIES_DATA'}
                                loading={loading}
                                menuLinks={getMenuLinks()}
                            />
                            <Title triple={true}>{`My Contract - Overview and Modifications of Species Data`}</Title>
                            <br />
                            <h4>{accessMessage}</h4>
                            <ConsultModifySpeciesDataFilterCriteriaArea
                                criteria={criteria}
                                speciesListByIdName={speciesListByIdName}
                                speciesNameHash={speciesNameHash}
                                selectedSpecies={selectedSpecies}
                                updateSelectedSpecies={updateSelectedSpecies}
                                genusesListByIdName={genusesListByIdName}
                                genusesNameHash={genusesNameHash}
                                selectedGenuses={selectedGenuses}
                                updateSelectedGenuses={updateSelectedGenuses}
                                entrustmentTypesListByIdName={entrustmentTypesListByIdName}
                                entrustmentTypesNameHash={entrustmentTypesNameHash}
                                selectedEntrustmentTypes={selectedEntrustmentTypes}
                                updateSelectedEntrustmentTypes={updateSelectedEntrustmentTypes}
                                updateCriteriaValue={updateCriteriaValue}
                                search={search}
                                resetCriteria={resetCriteria}
                            />
                        </>
                    )}
                </FormWrapper>
                {(userRoles.indexOf('WETLO') !== -1 ||
                    userRoles.indexOf('TLOOF') !== -1 ||
                    userRoles.indexOf('EOADM') !== -1) && (
                    <ConsultModifySpeciesDataTableArea
                        intl={props.intl}
                        count={count}
                        timestamp={timestamp}
                        criteria={criteria}
                        species={species}
                        accessMessage={accessMessage}
                        entrustmentMode={entrustmentMode.CONSULT_MODIFY}
                        printExcel={printExcel}
                        actionButtonClickCB={showModalEntrustmentScreen}
                        actionButtonClickCSelectSpecies={showModalSelectSpecies}
                        actionButtonClickCErrorMessage={showModalError}
                        updateCriteriaValue={updateCriteriaValue}
                    />
                )}
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(ConsultModifySpeciesDataPage));
