import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import TextNumberOfLines1 from './screenshots/TextNumberOfLines_1.PNG';
import TextNumberOfLines2 from './screenshots/TextNumberOfLines_2.PNG';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeTextNumberOfLineHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>Displays a textarea like the following: </p>
                    <img src={TextNumberOfLines1} alt="TextNumberOfLines_1" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>
                        It can take a <span className={styles.helpParameter}>parameter</span> like e.g.{' '}
                        <span className={styles.helpCode}>rows:2</span> that defines the number of rows of the textarea
                        (default is 4 rows)
                    </p>
                    <img src={TextNumberOfLines2} alt="TextNumberOfLines" className={styles.modalHelpImage} />
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeTextNumberOfLineHelp;
