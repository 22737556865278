import React from 'react';

interface IIconArrowDownProps {
    style?: any;
    className?: any;
    color?: string;
}

export default function ({style: userStyle, color, className}: IIconArrowDownProps) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={userStyle}
            className={className}
        >
            <path
                d="M8.24671 10.5566L12.5334 6.13659C12.8007 5.85992 12.6387 5.33325 12.2867 5.33325H3.71338C3.36138 5.33325 3.19938 5.85992 3.46671 6.13659L7.75338 10.5566C7.89538 10.7033 8.10471 10.7033 8.24671 10.5566Z"
                fill={color || '#005CA6'}
            />
        </svg>
    );
}
