import React, {useState} from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import TextAreaInput from '~components/TextAreaInput';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeMaskPhoneFax = ({close, onCreateOrUpdateLogicType, responseLogicValues, responseLogicType}) => {
    const {logicParam, applyToRemark} = responseLogicValues || {};
    const {defaultParam, message: helpMessage} = responseLogicType || {};
    const [paramValue, setParamValue] = useState(logicParam !== undefined ? logicParam : null);
    const [helpMessageValue, setHelpMessageValue] = useState(null);
    const onParamValueChange = ({target: {value: param}}) => setParamValue(param);
    const openHelpMessage = () => setHelpMessageValue(helpMessage);
    const closeHelpMessage = () => setHelpMessageValue(null);

    return (
        <ModalCustomVersion2
            close={close}
            helpMessage={helpMessageValue}
            closeHelpMessage={closeHelpMessage}
            header={
                <>
                    name
                    {helpMessage && (
                        <div style={{marginLeft: 10, display: 'inline-block'}} onClick={openHelpMessage}>
                            <FontAwesomeIcon icon={faQuestionCircle} color="black" size="lg" />
                        </div>
                    )}
                </>
            }
            body={
                <>
                    {defaultParam && (
                        <div>
                            <TextAreaInput
                                label="Default Parameters"
                                value={defaultParam}
                                width={550}
                                rows={7}
                                disabled={true}
                            />
                        </div>
                    )}
                    <div>
                        <TextAreaInput
                            label="Parameters"
                            value={paramValue}
                            onChange={onParamValueChange}
                            width={550}
                            rows={7}
                        />
                    </div>
                </>
            }
            footer={
                <>
                    <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                    <Button
                        icon={'arrowRight'}
                        clickAction={() =>
                            onCreateOrUpdateLogicType(paramValue, logicParam !== undefined ? applyToRemark : false)
                        }
                        variation={'danger'}
                    >{`Save`}</Button>
                </>
            }
        />
    );
};

export default ModalLogicTypeMaskPhoneFax;
