import React from 'react';

export const getMandatoryAsterisk = label => (
    <React.Fragment>
        <div style={{display: 'inline-block'}}>
            {label}
            <span style={{marginLeft: 5, color: 'red'}}>{'*'}</span>
        </div>
    </React.Fragment>
);

export const replaceLineBreaksWithHTML = (text = '') => {
    const textValue = text ? String(text) : '';
    return textValue.split('\n').map(function (item, idx) {
        const key = `${idx}`;
        return (
            <React.Fragment key={key}>
                {item}
                <br />
            </React.Fragment>
        );
    });
};
