import {getFetch, postFetch, API_SERVERLESS} from '~commonApi';

export function apiDenominationSearch(criteria, DEFAULT_CRITERIA = {}, excelTranslations) {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/mypvr/v3/denominations?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}

export function apiDenominationDelete(jsonData) {
    return postFetch(`${API_SERVERLESS}/mypvr/v3/denominationDelete`, true, jsonData);
}

export function apiDownloadProposalPDF(applicationNumber) {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/denominations/reader/${applicationNumber}`, true);
}

export function getDenominationPDFUrl(applicationNumber) {
    return `${API_SERVERLESS}/mypvr/v3/denominations/reader/${applicationNumber}`;
}
