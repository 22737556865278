import styles from './HeaderTitle.module.scss';
import IconInfo from '../iconComponents/Info';
import ModalWhatsNewVersion2 from '../commonModals/ModalWhatsNewVersion2';
import React, {useState} from 'react';

const modalScreens = {
    WHATS_NEW: 'WHATS_NEW',
};

interface IHeaderTitleProps {
    title: string;
}

export default function ({title}: IHeaderTitleProps) {
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const openModalWhatsNew = () => setModalScreen(modalScreens.WHATS_NEW);
    const closeModal = () => setModalScreen(null);

    return (
        <>
            {modalScreen === modalScreens.WHATS_NEW && <ModalWhatsNewVersion2 close={closeModal} />}
            <section className={styles.headerTitleWrap}>
                <h1 className={styles.title}>{title}</h1>
                <button type={`button`} className={styles.version} onClick={openModalWhatsNew}>
                    {`Version ${process.env.VERSION}`}
                    <IconInfo style={{marginLeft: 4}} />
                </button>
            </section>
        </>
    );
}
