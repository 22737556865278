import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RemarkMessage from './RemarkMessage';
import WizardContextualMenu from '../common/WizardContextualMenu';
import WizardQuestionWrapper, {IWizardQuestionWrapperProps, IWizardQuestionWrapperState} from './WizardQuestionWrapper';
import cx from 'classnames';
import {faPlus, faMinus} from '@fortawesome/free-solid-svg-icons';
import {getResponseComponent} from './utils';
import {renderError} from './utils';
import styles from './WizardQuestionTable.module.scss';

interface IState extends IWizardQuestionWrapperState {
    hidden: boolean;
}

class WizardQuestionTable extends WizardQuestionWrapper<IWizardQuestionWrapperProps, IState> {
    constructor(props: IWizardQuestionWrapperProps) {
        super(props);
        this.state = {
            ...this.state,
            hidden: false,
        };
    }

    componentDidMount() {
        this.setState(this.checkIfQuestionIsHidden(), () =>
            this.removeResponsesFromHiddenQuestionIfExist(this.state.hidden)
        );
    }

    componentDidUpdate(prevProps: IWizardQuestionWrapperProps, prevState: IWizardQuestionWrapperState) {
        if (this.props.screenData.hash !== prevProps.screenData.hash) {
            this.setState(this.checkIfQuestionIsHidden(), () =>
                this.removeResponsesFromHiddenQuestionIfExist(this.state.hidden)
            );
        }
    }

    updateScreenDataTableQuestionsKeysIterationsMap = (value: 1 | -1) => {
        let iterationKey = this.getTableQuestionIterationKey();
        this.props.updateScreenData({
            hash: Date.now(),
            tableQuestionsKeysIterationsMap: {
                ...this.props.screenData.tableQuestionsKeysIterationsMap,
                [iterationKey]: this.props.screenData.tableQuestionsKeysIterationsMap[iterationKey] + value,
            },
        });
    };

    onPlusButton = () => this.updateScreenDataTableQuestionsKeysIterationsMap(1);

    onMinusButton = (questionIndexToDelete: number) => {
        this.updateScreenDataTableQuestionsKeysIterationsMap(-1);
        this.deleteAndReindexResponsesClient(questionIndexToDelete);
    };

    deleteAndReindexResponsesClient = (questionIndexToDelete: number) => {
        let updatedResponses = this.getCopyOfResponsesClient();
        const questionIterationToDelete = questionIndexToDelete + 1;
        const questionKeyWithIteration = this.getQuestionKeyWithIteration();
        // Delete all responsesClient that key:
        // start with: questionKeyWithIteration --> "GR29_1_Q19875_1"
        // ends with: _2
        // GR29_1_Q19875_1_R42833_1: "test1"
        // GR29_1_Q19875_1_R42833_2: "test2"
        // GR29_1_Q19875_1_R42833_3: "test3"
        // GR29_1_Q19875_1_R42833_4: "test4"
        // GR29_1_Q19875_1_R42833_5: "test5"
        Object.keys(updatedResponses).forEach(key => {
            if (key.startsWith(questionKeyWithIteration) && key.endsWith(`_${questionIterationToDelete}`)) {
                delete updatedResponses[key];
            }
        });
        //Reindex
        Object.keys(updatedResponses).forEach(key => {
            let currentKeyIteration = parseInt(key.slice(-1));
            if (currentKeyIteration > questionIterationToDelete) {
                let tempValue = updatedResponses[key];
                let newKey = `${key.slice(0, -1)}${currentKeyIteration - 1}`;
                updatedResponses[newKey] = tempValue;
                delete updatedResponses[key];
            }
        });
        this.updateResponsesClient(updatedResponses);
    };

    renderPDFVersion = ({
        questionsCounter,
        questionKeyWithIteration,
    }: {
        questionsCounter: number;
        questionKeyWithIteration: string;
    }) => {
        const {question} = this.props;
        return (
            <table
                className={styles.questionTableHorizontalWrap}
                lang={this.props.formLanguage ? this.props.formLanguage.toLowerCase() : undefined}
            >
                <thead>
                    <tr>
                        {((this.props.question && this.props.question.responses) || []).map(response => {
                            return (
                                <th
                                    ref={el => {
                                        if (el) el.innerHTML = response.message;
                                    }}
                                    style={{width: `${100 / this.props.question.responses.length}%`}}
                                    key={response.message}
                                />
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {Array(questionsCounter)
                        .fill(true)
                        .map((el, index) => {
                            let questionIndex = index;
                            return (
                                <tr key={`${questionKeyWithIteration}_${questionIndex}`}>
                                    {((this.props.question && this.props.question.responses) || []).map(response => {
                                        let params: any = this.getResponseComponentProps(response);
                                        params.responseIteration = questionIndex + 1;
                                        params.isPDFVersionAndQuestionIsTableType = true;
                                        let responseComponent = getResponseComponent({
                                            question,
                                            response,
                                            params,
                                        });
                                        return (
                                            <React.Fragment key={response.id}>
                                                <td>{responseComponent}</td>
                                            </React.Fragment>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        );
    };

    render() {
        const {question} = this.props;
        const questionsCounter =
            this.props.screenData.tableQuestionsKeysIterationsMap[
                `${this.props.groupKeyWithIteration}_Q${this.props.question && this.props.question.id}`
            ];
        const questionKeyWithIteration = this.getQuestionKeyWithIteration();
        const hasClickedOnSaveAndValidate = this.checkIfUserHasClickedOnSaveAndValidate();
        const hasMandatoryError =
            !this.state.hidden &&
            hasClickedOnSaveAndValidate &&
            this.checkIfQuestionHasMandatoryError(questionsCounter);
        const {hidden, hiddenDueToQuestionResponseLines} = this.state;

        if (this.pdfConfidentialHideQuestion()) {
            return <></>;
        }

        return (
            <>
                {super.render()}
                <div id={questionKeyWithIteration}>
                    <div className={cx(styles.titleSection, this.state.hidden && styles.sectionHidden)}>
                        {this.renderQuestionLabel({
                            hidden,
                            hiddenDueToQuestionResponseLines,
                            hasError: hasMandatoryError,
                        })}
                        {this.renderQuestionHelpIcon()}
                    </div>
                    {hasMandatoryError &&
                        renderError(
                            this.props.intl?.formatMessage({
                                id: 'online.pleaseRememberToCompleteTheMandatoryResponsesOfTheQuestion',
                            })
                        )}
                    {this.pdfConfidentialHideResponsesOnly() ? (
                        <div style={{marginTop: 20, marginLeft: 20}}>Confidential information</div>
                    ) : (
                        !this.state.hidden && (
                            <>
                                {this.props.question && this.props.question.messageremark ? (
                                    <div style={{marginTop: 15}}>
                                        <RemarkMessage
                                            remarkMessage={this.props.question && this.props.question.messageremark}
                                            isRemarkTranslationMissing={this.checkIfQuestionRemarkTranslationIsMissing()}
                                        />
                                    </div>
                                ) : null}
                                {this.props.PDFVersion
                                    ? this.renderPDFVersion({questionsCounter, questionKeyWithIteration})
                                    : Array(questionsCounter)
                                          .fill(true)
                                          .map((el, index) => {
                                              let questionIndex = index;
                                              return (
                                                  <div
                                                      className={styles.questionResponses}
                                                      key={`${questionKeyWithIteration}_${questionIndex}`}
                                                  >
                                                      {question.responses.map(response => {
                                                          let params: any = this.getResponseComponentProps(response);
                                                          params.responseIteration = questionIndex + 1;
                                                          let responseComponent = getResponseComponent({
                                                              question,
                                                              response,
                                                              params,
                                                          });
                                                          return (
                                                              <React.Fragment key={response.id}>
                                                                  <WizardContextualMenu
                                                                      editable={this.props.editable}
                                                                      menuType="response"
                                                                      labelPopup={this.getResponseLabelPopup(
                                                                          response.id,
                                                                          response
                                                                      )}
                                                                  />
                                                                  <div className={styles.responseComponentWrap}>
                                                                      {responseComponent}
                                                                      {this.renderResponseLogicsPopover(response)}
                                                                  </div>
                                                                  <div style={{clear: 'both'}} />
                                                              </React.Fragment>
                                                          );
                                                      })}
                                                      {questionsCounter > 1 && (
                                                          <div className={styles.minusWrap}>
                                                              <button
                                                                  type="button"
                                                                  className={styles.buttonMinus}
                                                                  onClick={() => this.onMinusButton(questionIndex)}
                                                              >
                                                                  <FontAwesomeIcon icon={faMinus as any} />
                                                              </button>
                                                          </div>
                                                      )}
                                                  </div>
                                              );
                                          })}
                                {this.renderConfidentialQuestion()}
                                <div className={styles.plusWrap}>
                                    <button type="button" className={styles.buttonPlus} onClick={this.onPlusButton}>
                                        <FontAwesomeIcon icon={faPlus as any} />
                                    </button>
                                </div>
                            </>
                        )
                    )}
                </div>
            </>
        );
    }
}

export default WizardQuestionTable;
