import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export interface IResponseTemplate {
    templateId: number;
    nameLabelId: number;
    nameLabelValueHTML: string;
    remarkLabelId: number | null;
    remarkLabelValueHTML: string | null;
    helpLabelId: number | null;
    helpLabelValueHTML: string | null;
    displayOrder: string;
    status: number;
    mappingCode: string | null;
    multi: number;
    mandatory: number;
    buttonAddLabelId: number | null;
    buttonAddLabelValueHTML: string | null;
    buttonDelLabelId: number | null;
    buttonDelLabelValueHTML: string | null;
    officeId: number;
    jsonMapping: string | null;
    templateComment: string | null;
}

export function apiBackOfficeTemplatesStatusActive(): Promise<{
    data: {
        templates: IResponseTemplate[];
    };
}> {
    return getFetch(`${API_SERVERLESS}/backOffice/v2/templates?status=2`, true);
}

export function apiBackOfficeUseTemplateInsideForm(JSONRequest: {formId: number; templateId: number}) {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/useTemplateInsideForm`, true, JSONRequest);
}
