import React, {useState} from 'react';
import {injectIntl, IntlShape} from 'react-intl';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import moment from 'moment';
import en from '../../../../../../translations/en.json';
import {SummaryOfAnswers, SummaryOfAnswersForSave} from '../../NewSpeciesProcedurePageService';
import styles from './ModalSummaryOfAnswersForInventoryNumber.module.scss';
import {ModalConfirmVersion2, ModalCustomVersion2} from '../../../../../../commonModals';
import CustomTable from '../../../../../../components/CustomTable';
import TextAreaInput from '~components/TextAreaInput';
import Checkbox from '~components/Checkbox';
import {FORMAT_DATE_EASY} from '../../../../../../components/FormatFunctions';

import ModalSummaryOfAnswersForInventoryNumberFieldForShowHide from '../ModalSummaryOfAnswersForInventoryNumberFieldForShowHide/ModalSummaryOfAnswersForInventoryNumberFieldForShowHide';
import {History} from 'history';
import {Button} from '../../../../../../componentsFormV2';

interface ModalSummaryOfAnswersForInventoryNumberProps {
    intl: IntlShape;
    history: History;
    summaryOfAnswersList: Array<SummaryOfAnswers>;
    inventoryNumber: string;
    userRoles: Array<string>;
    endProposalDate: string | null;
    deadlineNegotiationsDate: string | null;
    groupHash: {id: string; label: string; hash: Array<string>};
    printExcel: (excelTranslations: {[key: string]: string}) => void;
    saveEditedRows: (editedRows: SummaryOfAnswersForSave) => void;
    close: () => void;
}

const ModalSummaryOfAnswersForInventoryNumber = (
    props: ModalSummaryOfAnswersForInventoryNumberProps & RouteComponentProps
) => {
    const RESULT_FIELDS_DEFAULT = [
        'eoProposalNumber',
        'name',
        'cost',
        'lumpSum',
        'lumpSumOk',
        'remark',
        'negotiation',
        'startEntrustmentDate',
    ];
    const RESULT_FIELDS_ALL = [
        'withdraw',
        'eoProposalNumber',
        'name',
        'cost',
        'lumpSum',
        'lumpSumOk',
        'remark',
        'negotiation',
        'startEntrustmentDate',
    ];
    const [editedRows, setEditedRows] = useState<SummaryOfAnswersForSave>({});
    const [showSubmitSavingAnswersModal, setShowSubmitSavingAnswersModal] = useState(false);

    const onNegotiationChange = (event: React.ChangeEvent<HTMLTextAreaElement>, rowObject: SummaryOfAnswers) => {
        const negotiation = event.target.value;
        const newEditedRows: SummaryOfAnswersForSave = Object.assign({}, editedRows);
        if (newEditedRows[rowObject.eoProposalNumber] !== undefined) {
            newEditedRows[rowObject.eoProposalNumber].negotiation = negotiation;
        } else {
            newEditedRows[rowObject.eoProposalNumber] = {
                examOfficeId: rowObject.examOfficeId,
                negotiation: negotiation,
                withdraw:
                    (editedRows[rowObject.eoProposalNumber] && editedRows[rowObject.eoProposalNumber].withdraw) ||
                    false,
                lumpSumOk:
                    (editedRows[rowObject.eoProposalNumber] && editedRows[rowObject.eoProposalNumber].lumpSumOk) ||
                    false,
            };
        }

        setEditedRows(newEditedRows);
    };

    const onCheckWithdraw = (rowObject: SummaryOfAnswers) => {
        const newEditedRows: SummaryOfAnswersForSave = Object.assign({}, editedRows);
        if (newEditedRows[rowObject.eoProposalNumber] !== undefined) {
            newEditedRows[rowObject.eoProposalNumber].withdraw = !editedRows[rowObject.eoProposalNumber].withdraw;
        } else {
            newEditedRows[rowObject.eoProposalNumber] = {
                examOfficeId: rowObject.examOfficeId,
                negotiation: (rowObject.negotiation !== null && rowObject.negotiation) || '',
                withdraw: true,
                lumpSumOk: (rowObject.lumpSumOk !== null && rowObject.lumpSumOk) || false,
            };
        }
        setEditedRows(newEditedRows);
    };

    const getNegotiationCells = (
        negotiation: string,
        rowObject: SummaryOfAnswers,
        diffCurrentDateDeadlineNegotiationsDate: number,
        diffCurrentDateEndProposalDate: number
    ) => {
        if (diffCurrentDateDeadlineNegotiationsDate > 0 || diffCurrentDateEndProposalDate <= 0) {
            return (
                <ModalSummaryOfAnswersForInventoryNumberFieldForShowHide {...{fieldData: negotiation, maxLines: 2}} />
            );
        } else {
            if (rowObject.isOwnerOfAnswer === '1') {
                return (
                    <p style={{margin: 0}} className="ng-scope">
                        <TextAreaInput
                            value={
                                editedRows[rowObject.eoProposalNumber]
                                    ? editedRows[rowObject.eoProposalNumber].negotiation
                                    : negotiation
                            }
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                                onNegotiationChange(event, rowObject)
                            }
                            rows={6}
                            width={160}
                        />
                    </p>
                );
            } else {
                return (
                    <ModalSummaryOfAnswersForInventoryNumberFieldForShowHide
                        {...{fieldData: negotiation, maxLines: 2}}
                    />
                );
            }
        }
    };

    const getWithdrawCells = (
        rowObject: SummaryOfAnswers,
        diffCurrentDateDeadlineNegotiationsDate: number,
        diffCurrentDateEndProposalDate: number
    ) => {
        if (
            diffCurrentDateDeadlineNegotiationsDate > 0 ||
            diffCurrentDateEndProposalDate <= 0 ||
            rowObject.name === 'No proposal from any Examination Office' ||
            rowObject.isOwnerOfAnswer !== '1'
        ) {
            return <p style={{margin: 0}} className="ng-scope"></p>;
        } else {
            return (
                <div style={{width: 130}}>
                    <Checkbox
                        clickAction={() => onCheckWithdraw(rowObject)}
                        label={`Withdraw`}
                        value={
                            editedRows[rowObject.eoProposalNumber] && editedRows[rowObject.eoProposalNumber].withdraw
                        }
                    />
                </div>
            );
        }
    };

    const onCheckLumpSumOk = (rowObject: SummaryOfAnswers) => {
        const newEditedRows: SummaryOfAnswersForSave = Object.assign({}, editedRows);
        if (newEditedRows[rowObject.eoProposalNumber] !== undefined) {
            newEditedRows[rowObject.eoProposalNumber].lumpSumOk = !editedRows[rowObject.eoProposalNumber].lumpSumOk;
        } else {
            newEditedRows[rowObject.eoProposalNumber] = {
                examOfficeId: rowObject.examOfficeId,
                negotiation: (rowObject.negotiation !== null && rowObject.negotiation) || '',
                withdraw: (rowObject.withdraw !== null && (rowObject.withdraw as boolean)) || false,
                lumpSumOk: true,
            };
        }
        setEditedRows(newEditedRows);
    };

    const getLumpSumOkCells = (lumpSumOk: boolean, rowObject: SummaryOfAnswers) => {
        const disabled = props.userRoles.indexOf('EOADM') === -1 || rowObject.lumpSum === 'NO';
        return (
            <div
                title={
                    disabled
                        ? 'You cannot check the Attribution of Lump Sum if the "Request of Lump Sum" has the value "NO"'
                        : undefined
                }
            >
                <Checkbox
                    value={
                        editedRows[rowObject.eoProposalNumber]
                            ? editedRows[rowObject.eoProposalNumber].lumpSumOk
                            : lumpSumOk
                    }
                    disabled={disabled}
                    clickAction={() => onCheckLumpSumOk(rowObject)}
                />
            </div>
        );
    };

    const closeSubmitSavingAnswersModal = () => {
        setShowSubmitSavingAnswersModal(false);
    };

    const saveEdited = () => {
        props.saveEditedRows && props.saveEditedRows(editedRows);
    };

    const setResultFieldsDefault = (
        diffCurrentDateDeadlineNegotiationsDate: number,
        diffCurrentDateEndProposalDate: number
    ) => {
        if (diffCurrentDateDeadlineNegotiationsDate > 0 || diffCurrentDateEndProposalDate <= 0) {
            localStorage.setItem('summaryOfAnswersListSearchColumns', JSON.stringify(RESULT_FIELDS_DEFAULT));
            return RESULT_FIELDS_DEFAULT;
        } else {
            localStorage.setItem('summaryOfAnswersListSearchColumns', JSON.stringify(RESULT_FIELDS_ALL));
            return RESULT_FIELDS_ALL;
        }
    };

    const printExcel = () => {
        const excelTranslations: {[key: string]: string} = {};
        const inventoryDetailsExcelKeys = Object.keys(en).filter(key => ~key.indexOf('excel.summaryOfAnswersList.'));
        const inventoryDetailsHeaderKeys = inventoryDetailsExcelKeys.map(key =>
            key.replace('excel.summaryOfAnswersList.', '')
        );
        inventoryDetailsHeaderKeys.forEach(headerKey => {
            excelTranslations[headerKey] = props.intl.formatMessage({
                id: `excel.summaryOfAnswersList.${headerKey}`,
            });
        });
        props.printExcel(excelTranslations);
    };

    const getTableArea = (diffCurrentDateDeadlineNegotiationsDate: number, diffCurrentDateEndProposalDate: number) => {
        return (
            <CustomTable
                version={2}
                {...props}
                group={props.groupHash}
                onSelectedChange={() => {}}
                notSortable={RESULT_FIELDS_ALL}
                tableName={'summaryOfAnswersList'}
                tableType={'OBJECT'}
                tableSource={props.summaryOfAnswersList}
                id={'eoProposalNumber'}
                resultFieldsDefault={setResultFieldsDefault(
                    diffCurrentDateDeadlineNegotiationsDate,
                    diffCurrentDateEndProposalDate
                )}
                resultFieldsAll={RESULT_FIELDS_ALL}
                intl={props.intl}
                defaultOrder={'eoProposalNumber'}
                reverseOrder={false}
                count={Object.keys(props.groupHash.hash).length}
                printExcel={printExcel}
                formatFunctions={{
                    remark: (remark: string, _rowObject: SummaryOfAnswers) => (
                        <ModalSummaryOfAnswersForInventoryNumberFieldForShowHide
                            {...{fieldData: remark, maxLines: 2}}
                        />
                    ),
                    withdraw: (_withdraw: undefined, rowObject: SummaryOfAnswers) =>
                        getWithdrawCells(
                            rowObject,
                            diffCurrentDateDeadlineNegotiationsDate,
                            diffCurrentDateEndProposalDate
                        ),
                    negotiation: (negotiation: string, rowObject: SummaryOfAnswers) =>
                        getNegotiationCells(
                            negotiation,
                            rowObject,
                            diffCurrentDateDeadlineNegotiationsDate,
                            diffCurrentDateEndProposalDate
                        ),
                    lumpSumOk: (lumpSumOk: boolean, rowObject: SummaryOfAnswers) =>
                        getLumpSumOkCells(lumpSumOk, rowObject),
                    startEntrustmentDate: FORMAT_DATE_EASY,
                }}
                wrapHeaders={true}
            />
        );
    };

    const getModalContentArea = () => {
        if (props.userRoles.indexOf('TLOOF') !== -1 || props.userRoles.indexOf('EOADM') !== -1) {
            let diffCurrentDateDeadlineNegotiationsDate = 0;
            let diffCurrentDateEndProposalDate = 0;
            if (props.deadlineNegotiationsDate && props.endProposalDate) {
                const deadlineNegotiationsDate = moment(props.deadlineNegotiationsDate).format('DD/MM/YYYY');
                const endProposalDate = moment(props.endProposalDate).format('DD/MM/YYYY');
                diffCurrentDateDeadlineNegotiationsDate = moment(new Date(), 'DD/MM/YYYY').diff(
                    moment(deadlineNegotiationsDate, 'DD/MM/YYYY'),
                    'days'
                );
                diffCurrentDateEndProposalDate = moment(new Date(), 'DD/MM/YYYY').diff(
                    moment(endProposalDate, 'DD/MM/YYYY'),
                    'days'
                );
            }
            return getTableArea(diffCurrentDateDeadlineNegotiationsDate, diffCurrentDateEndProposalDate);
        } else {
            return (
                <div style={{color: 'red', textAlign: 'center'}}>
                    <b style={{textAlign: 'center'}}>
                        <span>{`You are not entitled to consult those answers.`}</span>
                    </b>
                </div>
            );
        }
    };

    return (
        <div className={'summaryOfAnswers'}>
            <ModalCustomVersion2
                loading={false}
                close={() => props.close && props.close()}
                header={
                    <div className={styles.modalHeader}>
                        <h2
                            className={styles.modalTitle}
                        >{`Summary of answers for Inventory Number nº ${props.inventoryNumber}`}</h2>
                    </div>
                }
                body={getModalContentArea()}
                footer={
                    <>
                        <Button icon={'close'} clickAction={props.close} variation={'danger'}>
                            {`Close`}
                        </Button>
                        <Button
                            icon={'arrowRight'}
                            clickAction={() => setShowSubmitSavingAnswersModal(true)}
                            disabled={Object.keys(editedRows).length === 0}
                        >
                            {`Save`}
                        </Button>
                    </>
                }
            >
                {showSubmitSavingAnswersModal && (
                    <ModalConfirmVersion2
                        title={'Save Changes'}
                        message={'Are you sure you want to save your changes?'}
                        buttonName={'Yes'}
                        close={closeSubmitSavingAnswersModal}
                        action={saveEdited}
                    />
                )}
            </ModalCustomVersion2>
        </div>
    );
};

export default injectIntl(withRouter(ModalSummaryOfAnswersForInventoryNumber));
