import React from 'react';
import cx from 'classnames';
import stylesNew from './CustomDateInput.module.scss';
import stylesOld from './CustomDateInputOld.module.scss';
import dateIcon from './icons/date.png';

let styles;

export default class CustomDateInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
        };

        styles = props.oldBackOfficeStyles ? stylesOld : stylesNew;
    }

    onInputFocus = event => {
        if (!this.props.notEnabled) {
            this.props.onClick(event);
            this.props.focus();
        }
    };

    render() {
        let placeholder = this.props.customPlaceholder || 'dd/mm/yyyy';
        if (this.props.notEnabled && !this.props.inputValue) {
            placeholder = '';
        }

        return (
            <React.Fragment>
                <div style={this.props.width && {width: this.props.width}}>
                    <input
                        onBlur={this.props.blur}
                        onFocus={this.onInputFocus}
                        onChange={this.props.change}
                        placeholder={placeholder}
                        autoComplete="off"
                        type="text"
                        className={cx(
                            styles.input,
                            this.props.notEnabled && styles.inputDisabled,
                            this.props.oldBackOfficeStylesError && styles.hasError,
                            this.props.disabled && styles.inputDisabled
                        )}
                        name="beginDate"
                        required=""
                        style={{width: this.props.oldBackOfficeStyles ? null : 'calc(100% - 38px)'}}
                        value={this.props.inputValue}
                        disabled={this.props.notEnabled || this.props.disabled}
                        width={this.props.width}
                    />
                    <div
                        onClick={event => !this.props.notEnabled && this.props.onClick(event)}
                        style={{height: '34px', padding: 0, cursor: this.props.notEnabled ? 'default' : 'pointer'}}
                    >
                        <img src={dateIcon} alt={`date picker`} style={{height: 20, width: 20, marginTop: 5}} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
