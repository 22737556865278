import React, {useState} from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import TextAreaInput from '~components/TextAreaInput';
import styles from './ModalLogicType.module.scss';
import Checkbox from '~components/Checkbox';
import TextInput from '~components/TextInput';
import {Button} from '../../../componentsFormV2';

const getLabelMonths = logicParam => {
    if (logicParam && logicParam.includes('months:')) {
        return logicParam.split('months:').pop().split(';')[0];
    }
    return 12;
};

const ModalLogicTypeDate = ({close, onCreateOrUpdateLogicType, responseLogicValues, responseLogicType}) => {
    const {logicParam, applyToRemark} = responseLogicValues || {};
    const {defaultParam, name} = responseLogicType || {};

    const [showInformativeSticker, setShowInformativeSticker] = useState((logicParam || '').includes('showlabel:true'));
    const [labelMonths, setLabelMonths] = useState(getLabelMonths(logicParam));

    const onInputLabelMonthsChange = ({target: {value}}) => {
        setLabelMonths(value <= 0 ? 1 : value);
    };

    const onCreateOrUpdateLogicTypeClick = () => {
        let customParamValue = showInformativeSticker ? `showlabel:true;months:${labelMonths};` : null;
        onCreateOrUpdateLogicType(
            showInformativeSticker ? customParamValue : '',
            logicParam !== undefined ? applyToRemark : false
        );
    };

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <div className={styles.modalBody}>
                    {defaultParam && (
                        <div>
                            <TextAreaInput
                                label="Default Parameters"
                                value={defaultParam}
                                width={550}
                                rows={7}
                                disabled={true}
                            />
                        </div>
                    )}
                    <div style={{clear: 'both'}} />
                    <div>
                        <h4 style={{marginBottom: 0}}>{`Date should be no more than x months`}</h4>
                        <Checkbox
                            clickAction={() => setShowInformativeSticker(!showInformativeSticker)}
                            label={`Show informative sticker`}
                            value={showInformativeSticker}
                            double={true}
                        />
                        <div style={{clear: 'both'}} />
                        <div style={{marginTop: 10}}>
                            <TextInput
                                double={true}
                                label={'Label months'}
                                onChange={onInputLabelMonthsChange}
                                value={labelMonths}
                                width={100}
                                type={'number'}
                                disabled={!showInformativeSticker}
                            />
                        </div>
                    </div>
                </div>
            }
            footer={
                <>
                    <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                    <Button icon={'arrowRight'} clickAction={onCreateOrUpdateLogicTypeClick}>{`Save`}</Button>
                </>
            }
        />
    );
};

export default ModalLogicTypeDate;
