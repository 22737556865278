import React from 'react';
import {Button} from '../../componentsFormV2';
import {ModalCustomVersion2} from '../../commonModals';
import {isFullSearchExp} from '../../utils/utilsVarietyFinder';

interface IProps {
    close: any;
    exportNoteData: any;
    total: number;
    onButtonCompleteExportClick: any;
}

interface IState {
}

class ModalExportData extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render() {
        const exported = isFullSearchExp() ? this.props.exportNoteData?.exported : this.props.total;

        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={'Export to Excel'}
                body={
                    <div style={{minHeight: 80}}>
                        <div style={{marginBottom: 20}}>{`Export / total : ${exported}/${this.props.total}`}</div>
                        <div>{`For commercial registers, the export is limited to max 100 records/register`}</div>
                        <div style={{clear: 'both'}} />
                    </div>
                }
                footer={
                    <>
                        <Button clickAction={this.props.close} variation={'secondary'}>{`Close`}</Button>
                        <Button clickAction={this.props.onButtonCompleteExportClick}>{`Complete export`}</Button>
                    </>
                }
            />
        );
    }
}

export default ModalExportData;
