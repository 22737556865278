import React from 'react';
import styles from './BackOfficeLabelsBottomCaption.module.scss';
import cx from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {faEye, faLightbulb} from '@fortawesome/free-regular-svg-icons';

export default function BackOfficeLabelsBottomCaption(props) {
    return (
        <div>
            <fieldset className={styles.schedulerBorder}>
                <legend className={styles.legendSchedulerBorder}>
                    <i className="small" data-translate="">
                        <span className="ng-scope">{`Legend`}</span>
                    </i>
                </legend>
                <div className={styles.container}>
                    <div style={{color: '#333', background: '#f2dede'}}>
                        {`No translation of the label in this language. The displayed label corresponds to the English label`}
                    </div>
                </div>
                <div className={styles.container}>
                    <div className={cx(styles.legendItem, styles.icon)}>
                        <FontAwesomeIcon icon={faEye} />
                    </div>
                    <div className={styles.legendItem}>{` Preview the label in HTML - `}</div>
                    <div className={cx(styles.legendItem, styles.icon)}>
                        <FontAwesomeIcon icon={faPencilAlt} />
                    </div>
                    <div className={styles.legendItem}>{` Show details about the label - `}</div>
                    <div className={cx(styles.legendItem, styles.icon)}>
                        <FontAwesomeIcon icon={faLightbulb} />
                    </div>
                    <div className={styles.legendItem}>{` Used list of the label`}</div>
                </div>
            </fieldset>
        </div>
    );
}
