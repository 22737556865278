import {API_SERVERLESS, getFetch} from '../../../../../../commonApi';

interface CultivationType {
    id: string;
    cultivationType: string;
}

interface SubmissionPlace {
    id: number;
    name: string;
}

interface TestingSite extends SubmissionPlace {}

export interface CultivationTypesFetchData {
    data: {
        cultivationTypes: Array<CultivationType>;
    };
}

export interface SubmissionPlacesFetchData {
    data: {
        submissionPlaces: Array<SubmissionPlace>;
    };
}

export interface TestingSitesFetchData {
    data: {
        testingSites: Array<TestingSite>;
    };
}

export interface ModalScreenType {
    ERROR: string;
    SAVE_DRAFT_ANSWER_CONFIRM: string;
    APPLICATION_WITHDRAWAL_CONFIRM: string;
    DEFAULT_REQUIREMENTS_CUSTOM: string;
}

interface MainQuantityQualityInfo {
    quantityQuality: string;
    quantity1: number;
    unit1: string;
    type1: string;
    alternativeQuantity1: string;
    age1: string;
    size1: string;
    rootsAndGrafts1: string;
    seedQuality1: string;
    phytoSanitaryCertificate1: string;
    andor: string;
}

interface MainOtherQualities {
    otherQualities1: string;
}

interface MainTestedPests {
    testedPests1: string;
}

interface MainOthers {
    others1: string;
}

interface AdditionalQuantityQualityInfo {
    quantity2: number;
    unit2: string;
    type2: string;
    alternativeQuantity2: string;
    age2: string;
    size2: string;
    rootsAndGrafts2: string;
    seedQuality2: string;
    phytoSanitaryCertificate2: string;
}

interface AdditionalOtherQualities {
    otherQualities2: string;
}

interface AdditionalTestedPests {
    testedPests2: string;
}

interface AdditionalOthers {
    others2: string;
}

export interface PlantMaterialRequirements {
    main: Array<MainQuantityQualityInfo>;
    otherQualities: Array<MainOtherQualities>;
    testedPests: Array<MainTestedPests>;
    others: Array<MainOthers>;
    additionalQuantQualityInfo: Array<AdditionalQuantityQualityInfo>;
    additionalOtherQualities: Array<AdditionalOtherQualities>;
    additionalTestedPests: Array<AdditionalTestedPests>;
    additionalOthers: Array<AdditionalOthers>;
}

export interface QuantityQualityFetchData {
    data: {
        main: Array<MainQuantityQualityInfo>;
        otherQualities: Array<MainOtherQualities>;
        testedPests: Array<MainTestedPests>;
        others: Array<MainOthers>;
        additionalQuantQualityInfo: Array<AdditionalQuantityQualityInfo>;
        additionalOtherQualities: Array<AdditionalOtherQualities>;
        additionalTestedPests: Array<AdditionalTestedPests>;
        additionalOthers: Array<AdditionalOthers>;
    };
}

export interface DraftProposalAnswerForSave {
    eoProposal: number;
    inventoryNumber: string;
    selfAssessmentFlag: number;
    remark: string;
    closingDate: string;
    quantityQuality: string;
    quantityQualityIds: string;
    submissionStartDate: string;
    submissionEndDate: string;
    growingCycle: number;
    interimReport: string;
    finalReport: string;
    nationalProtocolFlag: number;
    remarkExamination: string;
    speciesId: string;
    testingSite: number;
    entrustmentDate: string;
    cultivationType: string;
    submissionPlace: number;
    lumpSum: number;
}

export function apiCultivationTypesAutocompletionFetch(searchTerm: string): Promise<CultivationTypesFetchData> {
    return getFetch(`${API_SERVERLESS}/tlo/applications/autocomplete/cultivationtype?term=${searchTerm}`, true);
}

export function apiSubmissionPlacesFetch(): Promise<SubmissionPlacesFetchData> {
    return getFetch(`${API_SERVERLESS}/tlo/applications/commons/submissionplaces`, true);
}

export function apiTestingSitesFetch(): Promise<TestingSitesFetchData> {
    return getFetch(`${API_SERVERLESS}/tlo/applications/commons/testingsites`, true);
}

export function apiQuantityQualityFetch(speciesExamId: number): Promise<QuantityQualityFetchData> {
    return getFetch(`${API_SERVERLESS}/tlo/applications/commons/quantityquality?speciesExamId=${speciesExamId}`, true);
}
