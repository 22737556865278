import {getFetch, postFetch, API_SERVERLESS} from '~commonApi';
import {postFileToPreSignedURLRequest} from '../../utils/requests';

const computeGetUploadPreSignedURLPromises = () => {
    const getPreSignedURLEndpoint = `${API_SERVERLESS}/tinymce/filemanager/action/uploadPresignedUrl`;
    return getFetch(getPreSignedURLEndpoint, true);
};

export function apiDocumentUpload({document}: {document: any}): Promise<{
    data: string;
}> {
    return computeGetUploadPreSignedURLPromises().then(
        ({presignedUrl, identifier}: {presignedUrl: string; identifier: string}) => {
            return postFileToPreSignedURLRequest({preSignedURL: presignedUrl, file: document.file}).then(() => {
                const formData = {
                    fileIdentifier: identifier,
                    filename: encodeURIComponent(document.filename),
                };
                return postFetch(`${API_SERVERLESS}/tinymce/filemanager/action/uploadFile`, true, formData);
            });
        }
    );
}

export function apiPictures(criteria: any, DEFAULT_CRITERIA: any = {}) {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);

    return getFetch(`${API_SERVERLESS}/tinymce/filemanager/action/listFiles?${requestParams.join('&')}`);
}

// export function apiExaminationOffices(): Promise<{data: string[]}> {
//     return getFetch(`${API_SERVERLESS}/mypvr/v3/publication/examinationOffices`);
// }
//
// export function apiCountries(): Promise<{data: string[]}> {
//     return getFetch(`${API_SERVERLESS}/mypvr/v3/publication/countries`);
// }
