import {getFetch, API_SERVERLESS} from '~commonApi';

export function apiGetFormDraft({applicationId, formId, formType, formSequence = '0'}) {
    return getFetch(
        `${API_SERVERLESS}/online/v3/formDraftGet?applicationId=${applicationId}&formId=${formId}&formType=${formType}&formSequence=${formSequence}&languageId=${`EN`}`,
        true
    );
}

export function apiGetOAPrepare({officeId, speciesId, speciesName} = {}) {
    let uri = Object.entries({officeId, speciesId, speciesName})
        .map(([key, value]) => value && `${key}=${value}`)
        .filter(el => el)
        .join('&');
    return getFetch(`${API_SERVERLESS}/online/v3/prepare?${uri}`, true);
}
