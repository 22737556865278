import {SectionsTableFetchData} from '../../types';
import {API_SERVERLESS, getFetch, postFetch} from '../../commonApi';

export function apiSectionsTableFetch(): Promise<SectionsTableFetchData> {
    return getFetch(`${API_SERVERLESS}/acarea/viewcategories`, true);
}

export function apiSectionDelete(sectionId: number) {
    return postFetch(`${API_SERVERLESS}/acarea/deletecategories`, true, {id: sectionId});
}

export function apiSectionHasContents(sectionId: number) {
    return getFetch(`${API_SERVERLESS}/acarea/categoryhascontents?categoryId=${sectionId}`, true);
}
