import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeShowResponseOnTheFirstsXOccurrenceOfGroupHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <div className={styles.helpParagraph}>
                        When a group is multi, meaning we can add multiple groups (e.g. Add a breader - Main form, step
                        5), and has a question with this logic, then the question will only show up in the firsts X
                        occurences of the group
                    </div>
                    <div className={styles.helpParagraph}>The default parameter is 1</div>
                    <div className={styles.helpParagraph}>
                        An example is the question: 05 . 05 . Geographical origin of the variety of Main form, step 5.
                        When we add a Group (Add a breeder), this question doesn&apos;t appear in the following groups
                    </div>
                    <div className={styles.helpParagraph}>
                        We can show this question in more group occurences e.g. 3, by providing the parameter:{' '}
                        <div className={styles.helpCode}>param:3</div>
                    </div>
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeShowResponseOnTheFirstsXOccurrenceOfGroupHelp;
