import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';

function renderButton(popupContent: string, handler: (id: any, rowObject: any) => void, icon: any) {
    return {
        title: popupContent,
        icon: () => {
            return (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon} />
                </a>
            );
        },
        handler,
    };
}

export default function VFModalDenominationTestMessageActions(onTableIconDeleteClick: (rowObject: any) => void) {
    const buttons = [];
    buttons.push(renderButton('Delete', (_, rowObject) => onTableIconDeleteClick(rowObject), faTrash));
    return buttons;
}
