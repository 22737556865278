import React from 'react';
import cx from 'classnames';
import styles from './MainWrapper.module.scss';

interface IMainWrapper {
    children: any;
    bgGray?: true;
    withoutNavigation?: true;
}

export default function ({bgGray, children, withoutNavigation}: IMainWrapper) {
    return (
        <main
            className={cx(styles.mainWrapper, bgGray && styles.bgGray, withoutNavigation && styles.withoutNavigation)}
        >
            {bgGray ? <div style={{background: 'white'}}>{children}</div> : null}
            {!bgGray ? children : null}
        </main>
    );
}
