//@ts-ignore
import gqlPrettier from 'graphql-prettier';

export const jurisprudenceReturnTypes = {
    ARTICLE: 'article',
    SUBHEADING: 'subheading',
    SPECIES_NAME: 'specieid',
    TITLE: 'title',
    DECISION_DATE: 'decisiondate',
    DENOMINATION: 'denomination',
    OUTCOME: 'outcomestatus',
    DESCRIPTION: 'description',
    ID: 'jurisprudenceid',
};

const getValueFromFormControlSelect = (value: any, elasticField: string) => {
    return value ? `${elasticField}:"${value.toLowerCase()}"` : '';
};

const getValueFromFormControl = (
    value: any,
    valueSelect: string,
    elasticField: string,
    elasticFieldSearchType: string,
    regexp: RegExp | null = null
) => {
    if (value && value.length > 0) {
        const val = regexp ? value.replace(regexp, '') : value;
        const searchType = elasticFieldSearchType ? `${elasticFieldSearchType}:"${valueSelect}"` : '';
        return `${elasticField}:"${val.toLowerCase()}" ${searchType}`;
    } else {
        return '';
    }
};

const createSearchJurisprudenceParams = (values: any) => {
    const article = getValueFromFormControlSelect(values.article, 'article');
    const subheading = getValueFromFormControlSelect(values.subheading, 'subHeading');
    const outcome = getValueFromFormControlSelect(values.outcome, 'outcome');
    const denomination = getValueFromFormControl(values.denomination, '', 'denomination', '');
    const keyword = getValueFromFormControl(values.decisionKwrd, '', 'decisionKwrd', '');
    const decisionDateMin = getValueFromFormControlSelect(values.decisionDateMin, 'decisionDateMin');
    const decisionDateMax = getValueFromFormControlSelect(values.decisionDateMax, 'decisionDateMax');

    return `
        ${article}
        ${subheading}
        ${outcome}
        ${denomination}
        ${keyword}
        ${decisionDateMin}
        ${decisionDateMax}
  `;
};

export const getSearchJurisprudenceQuery = (values: any, pageSize: number, pageIndex: number) => {
    const searchParams = createSearchJurisprudenceParams(values);

    const searchQuery = `
  {
    jurisprudenceSearch(
      size:${pageSize}
      from:${pageSize * pageIndex}
      ${searchParams}
    ){
      data {
        ${jurisprudenceReturnTypes.ID}
        ${jurisprudenceReturnTypes.ARTICLE}
        ${jurisprudenceReturnTypes.SUBHEADING}
        ${jurisprudenceReturnTypes.SPECIES_NAME}
        ${jurisprudenceReturnTypes.TITLE}
        ${jurisprudenceReturnTypes.DENOMINATION}
        ${jurisprudenceReturnTypes.DECISION_DATE}
        ${jurisprudenceReturnTypes.OUTCOME}
        ${jurisprudenceReturnTypes.DESCRIPTION}
       } total
    }
  }`;

    return {
        query: gqlPrettier(searchQuery),
    };
};
