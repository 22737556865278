import React from 'react';
import {injectIntl} from 'react-intl';
import {apiUserClients} from '~commonApi/clients';
import {apiDocumentType, apiUsersMarkAsNotRead, apiUsersMarkAsRead} from './MyPVRMyDocumentsService';
import {cleanSpecialCharOnFilename} from '~utils';
import {apiDownload} from '~commonApi/download';
import {saveAs} from 'file-saver';
import TextInput from '~components/TextInput';
import {faChevronDown, faChevronRight, faExclamationTriangle, faInfo} from '@fortawesome/free-solid-svg-icons';
import TextLabelInput from '~components/TextLabelInput';
import Empty from '~components/Empty';
import InputLink from '~components/InputLink';
import Title from '~components/Title';
import DateInput from '~components/DateInput';
import SelectInput from '~components/SelectInput';
import CustomTable from '~components/CustomTable';
import {FORMAT_DATE, formatDateEasy, reformatDateEasy} from '~components/FormatFunctions';
import RESULT_FIELDS_ALL from './data/RESULT_FIELDS_ALL.json';
import DEFAULT_RESULT_FIELDS from './data/DEFAULT_RESULT_FIELDS.json';
import jwtDecode from 'jwt-decode';
import getIcon from '~utils/icons';
import {apiDocumentsSearch, apiDocumentsSearchCount} from '~commonApi/documents';
import {apiSpecies} from '~commonApi/static';
import addressedTo from './data/DocumentsAddressedTo.json';
import statusRead from './data/DocumentsStatusRead.json';
import statusFlag from './data/DocumentsStatusFlag.json';
import dateFormat from 'date-format';
import DocumentsActionButtons from './DocumentsActionButtons';
import MyPVRMyDocumentsTopCaption from './MyPVRMyDocumentsTopCaption';
import ModalMergeList from './ModalMergeList';
import {apiFileListLogs} from '../CPVOLogs/CPVOLogsService';
import {withRouter} from 'react-router-dom';
import PDFMerger from 'pdf-merger-js/browser';
import {getPreSignedURLFetchRequest} from '../../utils/requests';
import JSZip from 'jszip';
import pako from 'pako';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getExcelSignedURLFetchRequest} from '../../commonApi/download';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
} from '../../componentsLayout';
import NavigationMyPVR from '../../shared/NavigationMyPVR';
import {Button} from '../../componentsFormV2';
import {
    ModalAlertVersion2,
    ModalCommunicationCentreDocumentList,
    ModalConfirmVersion2,
    ModalCPVOLogsVersion2,
    ModalStatusInfoVersion2,
} from '../../commonModals';
import MainWrapper from '../../componentsLayout/MainWrapper';
import {trackPageView} from '../../utils';

const ALL = '0';
const FLAG_ALL = '-1';
const DEFAULT_CRITERIA = {
    documentType: ALL,
    applicationNumber: '',
    documentRead: ALL,
    arrivalDateFrom: '',
    arrivalDateTo: '',
    notificationDateFrom: '',
    notificationDateTo: '',
    addressedTo: ALL,
    flag: '-1',
    applicants: '',
    clientName: '',
    speciesNameFilter: 'starts',
    speciesIds: '',
    speciesName: '',
    denomination: '',
    denominationFiler: 'equals',
    breedersReference: '',
    //
    pageNumber: 1,
    pageSize: 10,
    order: 'arrivalDate',
    reverse: true,
    refresh: false,
    excel: false,
};
const advancedCriteria = ['speciesIds', 'applicants', 'denomination', 'breedersReference'];

const DATEFORMAT_CRITERIA_KEYS = ['arrivalDateFrom', 'arrivalDateTo', 'notificationDateFrom', 'notificationDateTo'];

const modalScreen = {
    STATUS_INFO: 'STATUS_INFO',
    MODAL_FILES: 'MODAL_FILES',
};

const DEFAULT_LIMIT = 2000;

// const DATA_SETTINGS_KEY = '';
class MyPVRMyDocuments extends React.Component {
    elPrintButtonTop = null;
    elDownloadButtonTop = null;
    initialCriteria = null;
    folder = null;
    lastSearchCriteria = null;
    elSearchButton = null;
    documentByIndexHash = {};
    downloadedDocuments = [];
    lastSearchAddressedTo = null;
    onlyLegal = false;

    constructor(props) {
        super(props);
        this.initialCriteria = Object.assign({}, DEFAULT_CRITERIA);
        this.initialCriteria.addressedTo = ALL;
        this.initialCriteria.documentRead = ALL;
        this.state = {
            documents: null,
            loading: 0,
            advancedOpened: false,
            count: null,
            limit: DEFAULT_LIMIT,
            criteria: this.initialCriteria,
            criteriaCount: 0,
            advancedCriteriaCount: 0,
            notelines: null,
            modalAlert: false,
            modalAlertSelection: false,
            modalAlertPrint: false,
            modalAlertAddressedToMe: false,
            modalConfirm: false,
            legendVisible: false,
            selectedClients: {},
            clientListByIdName: null,
            clientNameHash: null,
            companyName: null,
            selectedDocuments: {},
            printStatus: null,
            loadedDocuments: [],
            speciesListByIdName: null,
            speciesNameHash: null,
            selectedSpecies: {},
            timestamp: null,
            clientName: '',
            modalCPVOLogs: {
                communicationId: '',
                subject: '',
            },
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'myDocuments'});
        this.loadJSONs();
        this.loadUrlParams();
        this.getCompanyName();
    }

    componentDidUpdate() {
        if (this.elDownloadButtonTop && this.state.selectedDocuments.length > 0) {
            this.elDownloadButtonTop && this.elDownloadButtonTop.removeAttribute('disabled');
        } else {
            this.elDownloadButtonTop && this.elDownloadButtonTop.setAttribute('disabled', 'disabled');
        }
    }

    getCompanyName = () => {
        const remember = localStorage.getItem('remember') === '1';
        const authToken = (remember ? localStorage : sessionStorage).getItem('token');
        if (authToken) {
            const decoded = jwtDecode(authToken);
            decoded && decoded.company && this.setState({companyName: decoded.company});
        }
    };

    getUniqueFlags = flagStatus => {
        const documentsWithFlag = this.state.documents.filter(doc => doc.flagId && doc.flagged === flagStatus);
        const flagIds = documentsWithFlag.map(doc => doc.flagId);
        return [...new Set(flagIds)];
    };

    unTickAll = () => this.markUsRead(this.getUniqueFlags(1), 1);

    tickAll = () => this.markUsRead(this.getUniqueFlags(0), 0);

    toggleAdvancedSearch = () => {
        this.setState(
            prevState => ({
                advancedOpened: !prevState.advancedOpened,
            }),
            () => this.state.advancedOpened
        );
    };

    searchLastCriteria = () => {
        const criteriaCount = this.countCriteria(this.lastSearchCriteria.criteria);
        const advancedCriteriaCount = this.countCriteria(this.lastSearchCriteria.criteria, true);
        this.setState({
            criteriaCount,
            advancedCriteriaCount,
            criteria: Object.assign({}, DEFAULT_CRITERIA, this.lastSearchCriteria.criteria),
        });
    };

    loadJSONs = () => {
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                apiUserClients()
                    .then(jsonResponse => {
                        if (jsonResponse) {
                            const clientNameHash = {};
                            ((jsonResponse && jsonResponse.clients) || []).forEach(client => {
                                if (!clientNameHash[client.name]) {
                                    clientNameHash[client.name] = [];
                                }
                                clientNameHash[client.name].push(client.clientId);
                            });
                            const clientListByIdName = Object.keys(clientNameHash).map(clientName => ({
                                ID: clientNameHash[clientName].join(','),
                                NAME: clientName,
                            }));
                            const selectedClients = {};
                            const selectedClientsKeyArray = Object.keys(this.state.selectedClients || {});
                            clientListByIdName
                                .filter(i => selectedClientsKeyArray.indexOf(i.ID) !== -1)
                                .forEach(item => (selectedClients[item.ID] = item.NAME));
                            this.setState({clientListByIdName, clientNameHash, selectedClients});
                        }
                    })
                    .catch(error => {
                        ERROR`Error loadJSONs() at MyPVRFinances: ${error.message}`;
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );

        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                apiDocumentType()
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.data) {
                            jsonResponse.data.unshift({id: ALL, label: 'All'});
                            this.setState({
                                documentTypes: jsonResponse.data.map(type => ({id: type.id, value: type.label})),
                            });
                        }
                    })
                    .catch(error => {
                        ERROR`Document types list error: ${error.message}`;
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );

        apiSpecies()
            .then(jsonResponse => {
                if (jsonResponse) {
                    const speciesNameHash = {};
                    (jsonResponse || []).forEach(speciesElement => {
                        if (!speciesNameHash[speciesElement.NAME]) {
                            speciesNameHash[speciesElement.NAME] = [];
                        }
                        speciesNameHash[speciesElement.NAME].push(speciesElement.ID);
                    });
                    const speciesListByIdName = Object.keys(speciesNameHash).map(speciesName => ({
                        ID: speciesNameHash[speciesName].join(','),
                        NAME: speciesName,
                    }));
                    const selectedSpecies = {};
                    const selectedSpeciesKeyArray = Object.keys(this.state.selectedSpecies || {});
                    speciesListByIdName
                        .filter(i => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                        .forEach(item => (selectedSpecies[item.ID] = item.NAME));
                    this.setState({speciesListByIdName, speciesNameHash, selectedSpecies, timestamp: Date.now()});
                }
            })
            .catch(error => {
                ERROR`FormLabelInputSpecies: ${error.message}`;
            });
    };

    loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const criteria = Object.assign({}, this.initialCriteria);
            const params = domainArray.pop();
            params.split('&').forEach(param => {
                const paramElements = param.split('=');
                if (paramElements.length === 2) {
                    const key = paramElements[0];
                    if (key === 'onlyLegal' && paramElements[1] === 'true') {
                        criteria['documentType'] = 41;
                        this.onlyLegal = true;
                    } else {
                        criteria[key] = decodeURIComponent(paramElements[1]);
                    }
                }
            });
            if (Object.keys(criteria).length > 0) {
                const selectedSpecies = {};
                if (criteria.speciesIds) {
                    criteria.speciesIds.split(',').forEach(speciesId => (selectedSpecies[speciesId] = false));
                }
                const selectedClients = {};
                if (criteria.applicants) {
                    criteria.applicants.split(',').forEach(clientId => (selectedClients[clientId] = false));
                }
                DATEFORMAT_CRITERIA_KEYS.forEach(criteriaKey => {
                    if (/^[0-9]{4}(-([0-9]){2}){2}$/.test(criteria[criteriaKey])) {
                        criteria[criteriaKey] = formatDateEasy(criteria[criteriaKey]);
                    }
                });
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                this.setState(
                    Object.assign(
                        {},
                        {
                            criteria,
                            criteriaCount,
                            advancedCriteriaCount,
                            selectedSpecies,
                            selectedClients,
                        },
                        criteria.order && {defaultOrder: criteria.order},
                        criteria.reverse && {reverseOrder: criteria.reverse}
                    ),
                    () => this.search(false, true)
                );
            }
        }
    };

    refreshScreen = () => this.setState({timestamp: Date.now()});

    checkUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const criteria = {};
            const params = domainArray.pop();
            params.split('&').forEach(param => {
                const paramElements = param.split('=');
                if (paramElements.length === 2) {
                    criteria[paramElements[0]] = paramElements[1];
                }
            });
            return Object.keys(criteria).length === 1 && criteria['noteId'];
        }
        return false;
    };

    resetCriteria = () => {
        LOG`resetCriteria`;
        this.setState({
            criteria: this.initialCriteria,
            criteriaCount: 0,
            advancedCriteriaCount: 0,
            timestamp: Date.now(),
            notes: null,
            selectedClients: {},
            selectedSpecies: {},
        });
    };

    parseCriteria = criteria => {
        const parsedCriteria = {};
        Object.keys(criteria).map(key => {
            if (DATEFORMAT_CRITERIA_KEYS.indexOf(key) !== -1 && criteria[key] !== '') {
                const date = criteria[key];
                parsedCriteria[key] = reformatDateEasy(date);
            } else {
                parsedCriteria[key] = criteria[key];
            }
        });
        return parsedCriteria;
    };

    search = (refresh, urlLoad) => {
        this.lastSearchAddressedTo = this.state.criteria.addressedTo;
        const criteriaCountWithoutAdvancedCriteria = this.state.criteriaCount - this.state.advancedCriteriaCount;
        if (
            this.state.criteria.documentType === ALL &&
            (criteriaCountWithoutAdvancedCriteria === 0 ||
                (criteriaCountWithoutAdvancedCriteria === 1 && this.state.criteria.flag !== FLAG_ALL))
        ) {
            this.setState({modalAlert: true});
        } else {
            this.setState(
                prev => ({
                    loading: ++prev.loading,
                    count: null,
                    criteria: Object.assign(
                        {},
                        prev.criteria,
                        {refresh: !!refresh},
                        !refresh && !urlLoad && {pageNumber: 1}
                    ),
                }),
                () => {
                    const parsedCriteria = this.parseCriteria(this.state.criteria);
                    !urlLoad && this.buildParams(parsedCriteria);
                    apiDocumentsSearch(Object.assign({}, parsedCriteria, {refresh: true}), DEFAULT_CRITERIA)
                        .then(jsonResponse => {
                            if (jsonResponse && jsonResponse.data && jsonResponse.data.documents) {
                                jsonResponse.data.documents.forEach(document => {
                                    this.documentByIndexHash[document.csDocsKey] = document;
                                });
                                this.setState(
                                    prev => {
                                        const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                        return Object.assign(
                                            {},
                                            !refresh && !urlLoad && {criteria},
                                            {
                                                documents: jsonResponse.data.documents,
                                                timestamp: Date.now(),
                                            },
                                            jsonResponse.data.count && {count: jsonResponse.data.count}
                                        );
                                    },
                                    () => {
                                        if (
                                            jsonResponse &&
                                            jsonResponse.data &&
                                            jsonResponse.data.documents &&
                                            jsonResponse.data.documents.length > 0
                                        ) {
                                            apiDocumentsSearchCount(parsedCriteria, DEFAULT_CRITERIA).then(response =>
                                                this.setState({count: response.data.count})
                                            );
                                        } else {
                                            this.setState({count: 0});
                                        }
                                    }
                                );
                            }
                        })
                        .catch(error => {
                            ERROR`Register search list error: ${error.message}`;
                        })
                        .then(() => this.setState(prev => ({loading: --prev.loading})));
                }
            );
        }
    };

    printExcel = excelTranslations => {
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                const parsedCriteria = Object.assign({}, this.parseCriteria(this.state.criteria), {excel: true});
                apiDocumentsSearch(parsedCriteria, DEFAULT_CRITERIA, excelTranslations)
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                            getExcelSignedURLFetchRequest(jsonResponse.data.token).then(response => {
                                if (response && response.signedUrl) {
                                    const {signedUrl} = response;
                                    getPreSignedURLFetchRequest(signedUrl)
                                        .then(response => response.blob())
                                        .then(responseBlob => {
                                            saveAs(responseBlob, `MyDocuments.xlsx`);
                                        });
                                }
                            });
                        }
                    })
                    .catch(error => LOG`error downloading: ${error}`)
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );
    };

    buildParams = parsedCriteria => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(i => parsedCriteria[i] !== DEFAULT_CRITERIA[i] && i !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key]}`);
        this.props.history.replace(`/myDocuments${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`);
    };

    countCriteria = (criteria, advanced) => {
        let count = 0;
        (advanced ? advancedCriteria : Object.keys(criteria)).map(key => {
            if (criteria[key] !== this.initialCriteria[key]) count++;
        });
        return count;
    };

    getFileName = document => {
        const fileDestinationArray = [];
        if (document.fileNumber) {
            fileDestinationArray.push(document.fileNumber);
            fileDestinationArray.push('_');
        }
        if (document.arrivalDate && document.arrivalDate !== '') {
            fileDestinationArray.push(dateFormat('yyyy-MM-dd', dateFormat.parse('yyyy-MM-dd', document.arrivalDate)));
            fileDestinationArray.push('_');
        }
        if (document.documentTitle) {
            fileDestinationArray.push(
                `${document.documentTitle}-${document.nbDocs ? document.nbDocs.replace('/', '.') : ''}-`
            );
        } else if (document.documentType) {
            fileDestinationArray.push(
                `${document.documentType.substring(0, 180)}-${
                    document.nbDocs ? document.nbDocs.replace('/', '.') : ''
                }-`
            );
        }
        const fileName = `${fileDestinationArray.join('')}${document.csDocsKey}.${document.extension || 'pdf'}`;
        let fileDestination = `${cleanSpecialCharOnFilename(fileName)}`;
        fileDestination = fileDestination.split(' ').join('');
        return fileDestination;
    };

    downloadDocument = document => {
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                apiDownload(document.csDocsKey)
                    .then(JSONResponse => {
                        if (JSONResponse && JSONResponse.documents) {
                            let preSignedURL;
                            let fileName;
                            let isZip;
                            if (Array.isArray(JSONResponse.documents)) {
                                preSignedURL = JSONResponse.documents[0]?.signedUrl;
                                fileName = JSONResponse.documents[0]?.docName;
                                isZip = JSONResponse.documents[0]?.isZip;
                            } else if (JSONResponse.documents.signecUrl && JSONResponse.documents.fileName) {
                                preSignedURL = JSONResponse.documents.signecUrl;
                                fileName = JSONResponse.documents?.fileName;
                                isZip = JSONResponse.documents?.isZip;
                            }
                            if (preSignedURL && fileName) {
                                getPreSignedURLFetchRequest(preSignedURL)
                                    .then(response => response.blob())
                                    .then(responseBlob => {
                                        if (isZip) {
                                            responseBlob.arrayBuffer().then(fileReader => {
                                                saveAs(new Blob([pako.ungzip(new Uint8Array(fileReader))]), fileName);
                                            });
                                        } else {
                                            saveAs(responseBlob, fileName);
                                        }
                                    });
                            }
                        }
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );
    };

    downloadDocuments = () => {
        if (this.isDownloadAndMergeEnabled()) {
            const csDocsKeyArray = Object.keys(this.state.selectedDocuments || {}).filter(cs => cs !== null);
            const csDocsKeys = [...new Set(csDocsKeyArray)];
            const documentCount = csDocsKeys.length;
            if (documentCount > 300) {
                this.setState({modalAlertSelection: true});
            } else {
                this.setState(
                    prev => ({loading: ++prev.loading}),
                    () => {
                        apiDownload(
                            documentCount === 1 ? csDocsKeys.slice(0, 1) : null,
                            documentCount > 1 ? {csDocsKeys} : null
                        )
                            .then(JSONResponse => {
                                if (JSONResponse && JSONResponse.documents) {
                                    let preSignedURLs = [];
                                    let fileNames = [];
                                    let isZips = [];
                                    if (Array.isArray(JSONResponse.documents)) {
                                        preSignedURLs = JSONResponse.documents.map(document => document?.signedUrl);
                                        fileNames = JSONResponse.documents.map(document => document?.docName);
                                        isZips = JSONResponse.documents.map(document => document?.isZip);
                                    } else {
                                        preSignedURLs.push(JSONResponse.documents?.signecUrl);
                                        fileNames.push(JSONResponse.documents?.fileName);
                                        isZips = [JSONResponse.documents?.isZip];
                                    }
                                    if (preSignedURLs.length === 1) {
                                        getPreSignedURLFetchRequest(preSignedURLs[0])
                                            .then(response => response.blob())
                                            .then(responseBlob => {
                                                if (isZips[0]) {
                                                    responseBlob.arrayBuffer().then(fileReader => {
                                                        saveAs(
                                                            new Blob([pako.ungzip(new Uint8Array(fileReader))]),
                                                            fileNames[0]
                                                        );
                                                    });
                                                } else {
                                                    saveAs(responseBlob, fileNames[0]);
                                                }
                                            });
                                    } else {
                                        (async function () {
                                            let i = 0;
                                            let processedBlobs = [];
                                            for await (let preSignedURL of preSignedURLs) {
                                                let response = await getPreSignedURLFetchRequest(preSignedURL);
                                                let responseBlob = await response.blob();
                                                if (isZips[i]) {
                                                    let fileReader = await responseBlob.arrayBuffer();
                                                    processedBlobs.push(
                                                        new Blob([pako.ungzip(new Uint8Array(fileReader))])
                                                    );
                                                } else {
                                                    processedBlobs.push(responseBlob);
                                                }
                                                i += 1;
                                            }
                                            const zip = new JSZip();
                                            processedBlobs.forEach((blob, i) => {
                                                zip.file(fileNames[i], blob, {
                                                    binary: true,
                                                });
                                            });
                                            let content = await zip.generateAsync({
                                                type: 'blob',
                                            });
                                            saveAs(content, 'archive.zip');
                                        })();
                                    }
                                }
                            })
                            .then(() => this.setState(prev => ({loading: --prev.loading})));
                    }
                );
            }
        }
    };

    printDocuments = () => {
        if (this.isDownloadAndMergeEnabled()) {
            if (this.lastSearchAddressedTo !== '1') {
                this.setState({modalAlertAddressedToMe: true});
            } else {
                const csDocsKeyArray = Object.keys(this.state.selectedDocuments || {}).filter(
                    cs => cs !== null && this.documentByIndexHash[cs].extension.toLowerCase() === 'pdf'
                );
                if (csDocsKeyArray.length > 300) {
                    this.setState({modalAlertSelection: true});
                } else if (csDocsKeyArray.length === 0) {
                    this.setState({modalAlertPrint: true});
                } else {
                    const csDocsKeys = [...new Set(csDocsKeyArray)];
                    this.setState({loadedDocuments: [], documentLoading: true}, () => {
                        this.downloadedDocuments = [];
                        this.getDocumentsQuick(csDocsKeys).then(dataState => this.setState(dataState));
                    });
                }
            }
        }
    };

    closeModalAlertAddressedToMe = () => {
        this.setState({modalAlertAddressedToMe: false});
    };

    openModal = ({folder, subject, comments}) => {
        this.setState(
            prev => ({
                loading: ++prev.loading,
            }),
            () =>
                apiFileListLogs('uploadDocuments', folder)
                    .then(jsonResult =>
                        this.setState({
                            files: jsonResult.files,
                            folder,
                            subject,
                            comments,
                            modalScreen: modalScreen.MODAL_FILES,
                            timestamp: Date.now,
                        })
                    )
                    .then(() =>
                        this.setState(prev => ({
                            loading: --prev.loading,
                        }))
                    )
        );
    };

    getDocumentsQuick = csDocsKeys => {
        return new Promise((resolve, reject) => {
            try {
                const loadedDocuments = csDocsKeys.map(csDocsKey => {
                    this.downloadedDocuments.push(csDocsKey);
                    return {name: this.getFileName(this.documentByIndexHash[csDocsKey])};
                });
                resolve({
                    loadedDocuments,
                    printStatus: `${csDocsKeys.length} selected documents.`,
                    documentLoading: false,
                });
            } catch (error) {
                reject(error);
            }
        });
    };
    closeModalPrint = () => this.setState({printStatus: null});

    closeModalAlertPrint = () => this.setState({modalAlertPrint: false});

    openMergeConfirm = () => this.setState({modalConfirm: true});

    joinDocuments = () => {
        this.setState(
            prev => ({modalConfirm: false, loading: ++prev.loading}),
            () => {
                const csDocsKeys = this.downloadedDocuments;
                const documentCount = csDocsKeys.length;
                apiDownload(
                    documentCount === 1 ? csDocsKeys.slice(0, 1) : null,
                    documentCount > 1
                        ? {
                              csDocsKeys,
                              format: 'pdf',
                          }
                        : null
                )
                    .then(JSONResponse => {
                        if (JSONResponse && JSONResponse.documents) {
                            let preSignedURLs = [];
                            let fileNames = [];
                            let isZips = [];
                            if (Array.isArray(JSONResponse.documents)) {
                                preSignedURLs = JSONResponse.documents.map(document => document?.signedUrl);
                                fileNames = JSONResponse.documents.map(document => document?.docName);
                                fileNames = JSONResponse.documents.map(document => document?.docName);
                                isZips = JSONResponse.documents.map(document => document?.isZip);
                            } else {
                                preSignedURLs.push(JSONResponse.documents?.signecUrl);
                                fileNames.push(JSONResponse.documents?.fileName);
                                isZips = [JSONResponse.documents?.isZip];
                            }
                            if (preSignedURLs.length === 1) {
                                getPreSignedURLFetchRequest(preSignedURLs[0])
                                    .then(response => response.blob())
                                    .then(responseBlob => {
                                        if (isZips[0]) {
                                            responseBlob.arrayBuffer().then(fileReader => {
                                                saveAs(
                                                    new Blob([pako.ungzip(new Uint8Array(fileReader))]),
                                                    fileNames[0]
                                                );
                                            });
                                        } else {
                                            saveAs(responseBlob, fileNames[0]);
                                        }
                                    });
                            } else {
                                (async function () {
                                    let i = 0;
                                    let processedBlobs = [];
                                    for await (let preSignedURL of preSignedURLs) {
                                        let response = await getPreSignedURLFetchRequest(preSignedURL);
                                        let responseBlob = await response.blob();
                                        if (isZips[i]) {
                                            let fileReader = await responseBlob.arrayBuffer();
                                            processedBlobs.push(new Blob([pako.ungzip(new Uint8Array(fileReader))]));
                                        } else {
                                            processedBlobs.push(responseBlob);
                                        }
                                        i += 1;
                                    }
                                    const merger = new PDFMerger();
                                    for (const blob of processedBlobs) {
                                        await merger.add(blob);
                                    }
                                    let mergedPDF = await merger.saveAsBlob();
                                    saveAs(mergedPDF, 'pdf-group.pdf');
                                })();
                            }
                        }
                    })
                    .then(() => this.setState(prev => ({printStatus: null, loading: --prev.loading})));
            }
        );
    };

    closeModalConfirm = () => this.setState({modalConfirm: false});

    closeModalAlertSelection = () => this.setState({modalAlertSelection: false});

    closeModalAlert = () => this.setState({modalAlert: 0});

    closeNoteLinesModal = () => {
        this.setState({notelines: null});
    };

    markUsRead = (flagIds, read) => {
        this.setState(
            prev => {
                const documents = prev.documents.map(document => {
                    if (flagIds.indexOf(document.flagId) !== -1) {
                        document.flagged = 3;
                    }
                    return document;
                });
                return {documents, timestamp: Date.now()};
            },
            () => {
                (read === 1 ? apiUsersMarkAsNotRead : apiUsersMarkAsRead)(flagIds).then(jsonResponse => {
                    if ((read === 0 && jsonResponse.markasread) || (read === 1 && jsonResponse.markasnotread)) {
                        this.setState(prev => {
                            const modifiedDocuments = prev.documents.map(document => {
                                if (flagIds.indexOf(document.flagId) !== -1) {
                                    document.flagged = read === 1 ? 0 : 1;
                                }
                                return document;
                            });
                            return {documents: modifiedDocuments, timestamp: Date.now()};
                        });
                    }
                });
            }
        );
    };

    updateCriteriaValue = (criteriaValue, callback, refresh) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                // DEBUG`new criteria: ${{...criteria}}`;
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                pageNumberChanged = criteriaValue.pageNumber && prev.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse && prev.reverse !== criteriaValue.reverse;
                return {criteria, criteriaCount, advancedCriteriaCount};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    goToRegisters = applicationNumber =>
        this.props.history.push(`privateConsultationDocuments?registerId=${applicationNumber}&back=documents`);

    isDownloadAndMergeEnabled = () =>
        Object.keys(this.state.selectedDocuments).length > 0 && !this.state.documentLoading;

    closeModal = () => this.setState({modalScreen: null, subject: null, comments: null});

    closeModalAlertSoonAvailable = () => this.setState({isModalAlertSoonAvailable: false});

    closeModalCPVOLogs = () => this.setState({modalCPVOLogs: {communicationId: '', subject: ''}});

    onSelectedChange = csDocsKeys => {
        const selectedDocuments = {};
        csDocsKeys.forEach(
            csDocsKey =>
                (selectedDocuments[csDocsKey] =
                    this.documentByIndexHash[csDocsKey] || this.state.selectedDocuments[csDocsKey])
        );
        this.setState({selectedDocuments});
    };

    onDocumentTypeChange = ({target: {value: documentType}}) => this.updateCriteriaValue({documentType});

    onApplicationNumberChange = event => {
        let applicationNumber = event.target.value;
        if (/[0-9]{0,8}/.test(applicationNumber)) {
            applicationNumber = applicationNumber.substring(0, 8);
            this.updateCriteriaValue({applicationNumber});
        }
    };

    onReadingStatusChange = ({target: {value: documentRead}}) => this.updateCriteriaValue({documentRead});

    onArrivalDateFromChange = ({target: {value: arrivalDateFrom}}) => this.updateCriteriaValue({arrivalDateFrom});

    onArrivalDateToChange = arrivalDateTo => this.updateCriteriaValue({arrivalDateTo});

    onNotificationDateFromChange = notificationDateFrom => this.updateCriteriaValue({notificationDateFrom});

    onNotificationDateToChange = notificationDateTo => this.updateCriteriaValue({notificationDateTo});

    onFlagStatusChange = ({target: {value: flag}}) => this.updateCriteriaValue({flag});

    onSelectedAddressToChange = ({target: {value: addressedTo}}) => this.updateCriteriaValue({addressedTo});

    onSelectedClientsIdChange = selectedClients =>
        this.updateCriteriaValue({applicants: Object.keys(selectedClients).join(',')}, () =>
            this.setState({selectedClients, clientName: ''})
        );

    onClientNameChange = clientName => this.setState({clientName});

    onSpeciesSelectionChange = selectedSpecies =>
        this.updateCriteriaValue({speciesIds: Object.keys(selectedSpecies).join(','), speciesName: ''}, () =>
            this.setState({selectedSpecies})
        );

    onSpeciesNameChange = speciesName => this.updateCriteriaValue({speciesName});

    onSpeciesNameFilterChange = ({target: {value: speciesNameFilter}}) => this.updateCriteriaValue({speciesNameFilter});

    onDenominationChange = ({target: {value: denomination}}) => this.updateCriteriaValue({denomination});

    onDenominationFilterChange = ({target: {value: denominationFilter}}) =>
        this.updateCriteriaValue({denominationFilter});

    onBreederReferenceChange = ({target: {value: breedersReference}}) => this.updateCriteriaValue({breedersReference});

    onButtonSendDocumentsClick = () => this.props.history.push('/communicationCentreClientInbox?new');

    renderTable = () => this.setState({timestamp: Date.now()});

    render() {
        const actions = DocumentsActionButtons(this.props, this.renderTable, this.markUsRead);
        const addressedToWithCompany = addressedTo.map(element => ({
            id: element.id,
            value: element.value === 'Me' && this.state.companyName ? this.state.companyName : element.value,
        }));

        return (
            <>
                {this.state.modalCPVOLogs && this.state.modalCPVOLogs.communicationId ? (
                    <ModalCommunicationCentreDocumentList
                        close={this.closeModalCPVOLogs}
                        communicationId={this.state.modalCPVOLogs.communicationId}
                        intl={this.props.intl}
                        subject={this.state.modalCPVOLogs.subject}
                    />
                ) : null}
                {this.state.isModalAlertSoonAvailable ? (
                    <ModalAlertVersion2
                        message={`This service will be soon available. Thank you for your understanding.`}
                        title={`Soon available`}
                        close={this.closeModalAlertSoonAvailable}
                    />
                ) : null}
                {this.state.modalConfirm ? (
                    <ModalConfirmVersion2
                        title={`Merge documents`}
                        message={`These documents will be marked as read. Do you want to continue?`}
                        buttonName={'Merge'}
                        action={this.joinDocuments}
                        close={this.closeModalConfirm}
                    />
                ) : null}
                {this.state.modalAlertSelection ? (
                    <ModalAlertVersion2
                        title={`Download documents`}
                        message={`You can download only 300 documents simultaneously. Please change your selection and try again.`}
                        close={this.closeModalAlertSelection}
                    />
                ) : null}
                {this.state.modalAlert ? (
                    <ModalAlertVersion2
                        title={`Invalid search`}
                        message={`It is mandatory to set another criterion when "Document type" is set to "All".`}
                        close={this.closeModalAlert}
                    />
                ) : null}
                {this.state.modalAlertAddressedToMe ? (
                    <ModalAlertVersion2
                        title={`Invalid merge`}
                        message={`You can only merge document "addressed to": "Me".`}
                        close={this.closeModalAlertAddressedToMe}
                    />
                ) : null}
                {this.state.modalAlertPrint ? (
                    <ModalAlertVersion2
                        title={`Print documents`}
                        message={`There are not PDF documents in the selection. `}
                        close={this.closeModalAlertPrint}
                    />
                ) : null}
                {this.state.printStatus !== null ? (
                    <ModalMergeList
                        title={`List of pdf documents`}
                        subtitle={`(only PDFs)`}
                        loadedDocuments={this.state.loadedDocuments}
                        intl={this.props.intl}
                        openMergeConfirm={this.openMergeConfirm}
                        close={this.closeModalPrint}
                    />
                ) : null}
                {this.state.modalScreen === modalScreen.STATUS_INFO ? (
                    <ModalStatusInfoVersion2 close={this.closeModal} />
                ) : null}
                {this.state.modalScreen === modalScreen.MODAL_FILES ? (
                    <ModalCPVOLogsVersion2
                        intl={this.props.intl}
                        zone={'uploadDocuments'}
                        folder={this.state.folder}
                        subject={this.state.subject}
                        notice={true}
                        timestamp={this.state.timestamp}
                        files={this.state.files}
                        userMode={true}
                        close={this.closeModal}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`My PVR`} />
                <NavigationMyPVR />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <SelectInput
                            label={'Document type'}
                            value={this.state.criteria.documentType}
                            onChange={this.onDocumentTypeChange}
                            list={this.state.documentTypes}
                            notAll={true}
                            disabled={this.onlyLegal}
                        />
                        <TextInput
                            label={'Application number(s)'}
                            filter={'Starts with'}
                            placeholder={`e.g. 2015 or 20150001`}
                            infoIcon={faInfo}
                            onChange={this.onApplicationNumberChange}
                            onEnter={this.search}
                            value={this.state.criteria.applicationNumber}
                            popOverText={`The search will find all documents whose number starts with the entered terms.`}
                        />
                        <SelectInput
                            label={'Reading status'}
                            value={this.state.criteria.documentRead}
                            onChange={this.onReadingStatusChange}
                            list={statusRead}
                            notAll={true}
                        />
                        <DateInput
                            changeDateFrom={this.onArrivalDateFromChange}
                            changeDateTo={this.onArrivalDateToChange}
                            inputValueFrom={this.state.criteria.arrivalDateFrom}
                            inputValueTo={this.state.criteria.arrivalDateTo}
                            label={`Arrival/sending date`}
                        />
                        <DateInput
                            changeDateFrom={this.onNotificationDateFromChange}
                            changeDateTo={this.onNotificationDateToChange}
                            inputValueFrom={this.state.criteria.notificationDateFrom}
                            inputValueTo={this.state.criteria.notificationDateTo}
                            label={`Notification date`}
                        />
                        <SelectInput
                            label={'Ticking status'}
                            value={this.state.criteria.flag}
                            onChange={this.onFlagStatusChange}
                            list={statusFlag}
                            notAll={true}
                        />
                        <SelectInput
                            double={true}
                            label={'Addressed to'}
                            value={this.state.criteria.addressedTo}
                            onChange={this.onSelectedAddressToChange}
                            list={addressedToWithCompany}
                            notAll={true}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        <InputLink
                            label={`Advanced search${
                                this.state.advancedCriteriaCount !== 0 ? ` (${this.state.advancedCriteriaCount})` : ''
                            } `}
                            icon={this.state.advancedOpened ? faChevronDown : faChevronRight}
                            clickAction={this.toggleAdvancedSearch}
                        />
                        <Empty oneLine={true} />
                        {this.state.advancedOpened && (
                            <>
                                <Title triple={true}>{`Other details`}</Title>
                                <TextLabelInput
                                    double={true}
                                    onSelectionChange={this.onSelectedClientsIdChange}
                                    onChange={this.onClientNameChange}
                                    value={this.state.clientName}
                                    selectedElements={this.state.selectedClients}
                                    delay={300}
                                    multiple={true}
                                    label={'Applicant(s)/Breeder(s)/Representative(s)'}
                                    outsideLabel={''}
                                    outsideLabelWidth={160}
                                    listByIdName={this.state.clientListByIdName}
                                    nameHash={this.state.clientNameHash}
                                />
                                <TextInput
                                    filter={['equals', 'contains']}
                                    currentFilter={this.state.criteria.denominationFilter}
                                    onFilterChange={this.onDenominationFilterChange}
                                    double={true}
                                    infoIcon={faInfo}
                                    onChange={this.onDenominationChange}
                                    onEnter={this.search}
                                    value={this.state.criteria.denomination}
                                    popOverText={`The search will find all applications containing what is typed`}
                                    outsideLabel={'Denomination'}
                                    outsideLabelWidth={160}
                                />
                                <div style={{clear: 'both'}}>{}</div>
                                <TextInput
                                    filter={'Contains'}
                                    double={true}
                                    infoIcon={faInfo}
                                    onChange={this.onBreederReferenceChange}
                                    onEnter={this.search}
                                    value={this.state.criteria.breedersReference}
                                    popOverText={`The search will find all applications containing what is typed`}
                                    outsideLabel={'Breeder’s reference'}
                                    outsideLabelWidth={160}
                                />
                                <div style={{clear: 'both'}}>{}</div>
                                <TextLabelInput
                                    currentFilter={this.state.criteria.speciesNameFilter}
                                    delay={300}
                                    double={true}
                                    filter={['starts', 'contains']}
                                    listByIdName={this.state.speciesListByIdName}
                                    multiple={true}
                                    nameHash={this.state.speciesNameHash}
                                    onChange={this.onSpeciesNameChange}
                                    onEnter={this.search}
                                    onFilterChange={this.onSpeciesNameFilterChange}
                                    onSelectionChange={this.onSpeciesSelectionChange}
                                    outsideLabel={'Species'}
                                    outsideLabelWidth={160}
                                    selectedElements={this.state.selectedSpecies}
                                    value={this.state.criteria.speciesName}
                                />
                            </>
                        )}
                        <div style={{clear: 'both'}}>{}</div>
                        <FormFooter
                            left={
                                <Button
                                    variation={'secondary'}
                                    clickAction={this.onButtonSendDocumentsClick}
                                >{`Send documents`}</Button>
                            }
                            right={
                                <>
                                    <Button
                                        variation={'secondary'}
                                        clickAction={this.resetCriteria}
                                    >{`Clear fields`}</Button>
                                    <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                                </>
                            }
                        />
                    </FormWrapper>
                    <div style={{marginBottom: 20, textAlign: 'center'}}>
                        <CustomTable
                            version={2}
                            loading={this.state.loading !== 0}
                            pageNumber={this.state.criteria.pageNumber}
                            selectable={true}
                            onSelectedChange={this.onSelectedChange}
                            {...this.props}
                            tableName={'documents'}
                            tableType={'OBJECT'}
                            tableSource={this.state.documents}
                            timestamp={this.state.timestamp}
                            dataFilter={null}
                            id={'csDocsKey'}
                            pagination={true}
                            printExcel={this.printExcel}
                            resultFieldsAll={RESULT_FIELDS_ALL}
                            resultFieldsDefault={DEFAULT_RESULT_FIELDS}
                            intl={this.props.intl}
                            defaultOrder={this.state.criteria.order}
                            reverseOrder={this.state.criteria.reverse}
                            pageSize={this.state.criteria.pageSize}
                            filterFunctions={null}
                            count={this.state.count || 'many'}
                            actionName={
                                <React.Fragment>
                                    <a onClick={this.tickAll} style={{whiteSpace: 'nowrap', color: 'black'}}>
                                        {` Tick all`}
                                    </a>
                                    <span>{` / `}</span>
                                    <a onClick={this.unTickAll} style={{whiteSpace: 'nowrap', color: 'black'}}>
                                        {` Untick all`}
                                    </a>
                                </React.Fragment>
                            }
                            actions={actions}
                            setLastCursor={null}
                            notSortable={['denominations', 'breedersReferences', 'speciesNames']}
                            formatFunctions={{
                                applicationNumbers: applicationNumbers => {
                                    const registerList = [];
                                    applicationNumbers.forEach(applicationNumber => {
                                        const applicationNumberLabel = applicationNumber;
                                        registerList.push(
                                            <p key={applicationNumberLabel} style={{margin: 0}} className="ng-scope">
                                                <a
                                                    onClick={() => this.goToRegisters(applicationNumberLabel)}
                                                    title="Open registers"
                                                    className="ng-binding"
                                                >
                                                    {applicationNumberLabel}
                                                </a>
                                            </p>
                                        );
                                    });
                                    return registerList;
                                },
                                denominations: denominations => {
                                    const denominationList = [];
                                    denominations.forEach((denomination, index) => {
                                        const denominationKey = `${denomination}-${index}`;
                                        denominationList.push(
                                            <p key={denominationKey} style={{margin: 0}} className="ng-scope">
                                                {denomination}
                                            </p>
                                        );
                                    });
                                    return denominationList;
                                },
                                breedersReferences: breedersReferences => {
                                    const breedersReferenceList = [];
                                    breedersReferences.forEach((breedersReference, index) => {
                                        const breedersReferenceKey = `${breedersReference}-${index}`;
                                        breedersReferenceList.push(
                                            <p key={breedersReferenceKey} style={{margin: 0}} className="ng-scope">
                                                {breedersReference}
                                            </p>
                                        );
                                    });
                                    return breedersReferenceList;
                                },
                                speciesNames: speciesNames => {
                                    const speciesNameList = [];
                                    speciesNames.forEach((speciesName, index) => {
                                        const speciesNameKey = `${speciesName}-${index}`;
                                        speciesNameList.push(
                                            <p key={speciesNameKey} style={{margin: 0}} className="ng-scope">
                                                {speciesName}
                                            </p>
                                        );
                                    });
                                    return speciesNameList;
                                },
                                arrivalDate: FORMAT_DATE,
                                firstOpen: FORMAT_DATE,
                                notificationDate: FORMAT_DATE,
                                deemedService: FORMAT_DATE,
                            }}
                            bold={rowObject => !rowObject.firstOpen && rowObject.flagId}
                            topCaption={
                                <MyPVRMyDocumentsTopCaption
                                    isDownloadAndMergeEnabled={this.isDownloadAndMergeEnabled}
                                    downloadDocuments={this.downloadDocuments}
                                    printDocuments={this.printDocuments}
                                />
                            }
                            forehandColumn={document =>
                                document.csDocsKey ? (
                                    <>
                                        {document.fileExists ? (
                                            <img
                                                style={{width: 20, height: 20, cursor: 'pointer'}}
                                                alt={'Download'}
                                                src={getIcon(document.extension || 'pdf')}
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    this.downloadDocument(document);
                                                }}
                                            />
                                        ) : (
                                            <div
                                                title={
                                                    'File not present, it is possible that it is pending of synchronization. Please try later'
                                                }
                                            >
                                                <FontAwesomeIcon icon={faExclamationTriangle} color={'orange'} />
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <span>{}</span>
                                )
                            }
                            updateCriteriaValue={this.updateCriteriaValue}
                        />
                    </div>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(withRouter(MyPVRMyDocuments));
