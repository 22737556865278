import React from 'react';
import cx from 'classnames';
import TextLabelInput from '../../../components/TextLabelInput';
import Help from '../common/Help';
import ResponseWrapper, {IWizardResponseWrapperProps, IWizardResponseWrapperState} from './ResponseWrapper';
import ResponseCombobox from './ResponseCombobox';
import {injectIntl} from 'react-intl';
import styles from './ResponseUpload.module.scss';
import {getErrorMessageResponseMandatoryField} from './utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';

const TEXT_INPUT_TYPES = {
    CONTROLLIST: 'CONTROLLIST',
    AUTOCOMPLETE: 'AUTOCOMPLETE',
    COMBOBOX: 'COMBOBOX',
};

const TEXT_INPUT_OPTIONS_TYPES = {
    countries: 'countries',
    eo_countries: 'eo_countries',
    ue_countries: 'ue_countries',
    species: 'species',
    languages: 'languages',
    procedures: 'procedures',
    component_ownership: 'component_ownership',
    stages: 'stages',
    stages_dus: 'stages_dus',
};

interface IState extends IWizardResponseWrapperState {
    inputErrorMessage: null;
    inputId: null;
    inputListByIdName: {ID: string; NAME: string}[];
    inputNameHash: any;
    inputValue: any;
    textInputType: string | any;
    textInputOptionsType: any;
    selectedInput: any;
    selectedType: any;
    timestamp: number;
}

class ResponseAssistedEntry extends ResponseWrapper<IWizardResponseWrapperProps, IState> {
    constructor(props: IWizardResponseWrapperProps) {
        super(props);
        this.state = {
            ...this.state,
            inputErrorMessage: null,
            inputId: null,
            inputListByIdName: [],
            inputNameHash: {},
            inputValue: null,
            textInputType: null,
            textInputOptionsType: null,
            selectedType: null,
            selectedInput: null,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        const {response} = this.props;
        const {logics = []} = response || {};
        let textInputType;
        let textInputOptionsType: any;
        let logicAssistedEntry = logics.find(logic => logic.type === 'assistedentry');
        let logicControlList = logics.find(logic => logic.type === 'controllist');
        let logicAutocomplete = logics.find(logic => logic.type === 'autocomplete');
        const getTextInputOptionsTypeWithPriority = (param: string) => {
            let type;
            Object.keys(TEXT_INPUT_OPTIONS_TYPES).forEach(key => {
                if (param.indexOf(key) > -1) {
                    type = key;
                }
            });
            return type;
        };
        if (logicAssistedEntry) {
            let param = (logicAssistedEntry.logicParam || logicAssistedEntry.defaultParam || '').toLowerCase();
            param.indexOf('autocomplete') > -1 && (textInputType = TEXT_INPUT_TYPES.AUTOCOMPLETE);
            param.indexOf('combobox') > -1 && (textInputType = TEXT_INPUT_TYPES.COMBOBOX);
            param.indexOf('controllist') > -1 && (textInputType = TEXT_INPUT_TYPES.CONTROLLIST);
            textInputOptionsType = getTextInputOptionsTypeWithPriority(param);
        }
        if (logicAutocomplete) {
            let param = (logicAutocomplete.logicParam || logicAutocomplete.defaultParam || '').toLowerCase();
            textInputType = TEXT_INPUT_TYPES.AUTOCOMPLETE;
            textInputOptionsType = getTextInputOptionsTypeWithPriority(param);
        }
        if (logicControlList) {
            let param = (logicControlList.logicParam || logicControlList.defaultParam || '').toLowerCase();
            textInputType = TEXT_INPUT_TYPES.CONTROLLIST;
            textInputOptionsType = getTextInputOptionsTypeWithPriority(param);
        }
        this.setState({textInputOptionsType: textInputOptionsType, textInputType}, () =>
            this.loadJSONs(textInputOptionsType)
        );
        if (!this.getResponseAnswer()) {
            this.setState({
                selectedInput: {},
            });
        }
    }

    componentDidUpdate(prevProps: IWizardResponseWrapperProps, prevState: IState) {
        if (this.props.screenData.hash !== prevProps.screenData.hash) {
            this.checkAndUpdateIfCurrentResponseIsDisabledDueToLogicDisabledOfOtherResponse();
            if (!this.getResponseAnswer()) {
                this.setState({
                    selectedInput: {},
                });
            }
        }
    }

    loadJSONs = (optionsType: string) => {
        const {assistedEntries} = this.props;
        if (assistedEntries) {
            const JSONOptions = assistedEntries[optionsType];
            if (JSONOptions) {
                const nameHash: {[key: string]: any[]} = {};
                (JSONOptions || []).forEach((el: any) => {
                    if (!nameHash[el.LABEL]) {
                        nameHash[el.LABEL] = [];
                    }
                    nameHash[el.LABEL].push(el.ID);
                });
                const listByIdName = Object.keys(nameHash).map(name => ({
                    ID: nameHash[name].join(','),
                    NAME: name,
                }));
                const selectedType: any = {};
                const selectedKeyArray = Object.keys(this.state.selectedType || {});
                listByIdName
                    .filter(i => selectedKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectedType[item.ID] = item.NAME));
                this.setState({
                    inputListByIdName: listByIdName,
                    inputNameHash: nameHash,
                    selectedInput: selectedType,
                    timestamp: Date.now(),
                });
            }
        }
    };

    onChange = (inputValue: string) => {
        this.setState({inputValue});
        this.updateCurrentResponseAnswer(inputValue);
    };

    onSelectionChange = (selectedInput: any) => {
        if (Object.keys(selectedInput).length === 0) {
            this.updateCurrentResponseAnswer('');
        }
        let stateUpdate: any = {
            inputId: Object.keys(selectedInput).join(','),
        };
        Object.keys(selectedInput).length === 0
            ? (stateUpdate.selectedInput = selectedInput)
            : (stateUpdate.inputErrorMessage = null);
        this.state.textInputType === TEXT_INPUT_TYPES.CONTROLLIST ? (stateUpdate.inputValue = '') : null;
        this.setState(stateUpdate);
    };

    onIdChange = (inputId: any, callback?: any) => {
        if (inputId) {
            const selectedInput: any = {};
            this.state.inputListByIdName
                .filter(i => i.ID === inputId)
                .forEach(inputElement => (selectedInput[inputElement.ID] = inputElement.NAME));
            this.setState(
                {
                    inputId,
                    selectedInput,
                    inputValue: '',
                },
                callback
            );
        }
    };

    renderRemedyIcon = () => {
        const remedyType = this.getRemedyType();
        return (
            <div
                className={cx(
                    styles.remedyIconWrap,
                    styles[this.props.question.type],
                    remedyType ? styles[remedyType] : null
                )}
                onClick={this.onButtonAddResponseToRemedyClick}
                id={`REMEDY_${this.getResponseKey()}`}
            >
                <FontAwesomeIcon icon={faPlus as any} color={'white'} width={16} height={10} />
            </div>
        );
    };

    renderPDFVersion = () => {
        return (
            <>
                {super.render()}
                {this.props.isRemedyStarted && this.renderRemedyIcon()}
                <div className={styles.pdfResponseWrap}>
                    <div className={styles.pdfResponseWrapLeft}>{this.getLabelWithHTML()}</div>
                    <div className={cx(styles.pdfResponseWrapRight, !!this.getResponseAnswer() && styles.hasPDFValue)}>
                        {this.getResponseAnswer()}
                    </div>
                </div>
            </>
        );
    };

    render() {
        const {response} = this.props;
        const {messageRemark} = response;
        const outsideLabelWithHTML = this.getLabelWithHTML();
        const istextInputOptionsType = this.state.textInputType === TEXT_INPUT_TYPES.AUTOCOMPLETE;
        const hasClickedOnSaveAndValidate = this.checkIfUserHasClickedOnSaveAndValidate();
        const hasMandatoryError =
            hasClickedOnSaveAndValidate && this.checkIfResponseHasMandatoryError(this.props.responseIteration);
        const errorMessage = hasMandatoryError && getErrorMessageResponseMandatoryField({intl: this.props.intl});

        if (this.state.textInputType === TEXT_INPUT_TYPES.COMBOBOX) {
            return <ResponseCombobox {...this.props} />;
        }

        if (this.props.PDFVersion) {
            return this.renderPDFVersion();
        }

        return (
            <div>
                <Help
                    message={response.messageHelp}
                    language={this.props.questionnaireLanguage}
                    className="responseHelp"
                />
                <TextLabelInput
                    onSelectionChange={this.onSelectionChange}
                    onIdChange={istextInputOptionsType ? null : this.onIdChange}
                    onChange={this.onChange}
                    value={this.getResponseAnswer()}
                    selectedElements={this.state.selectedInput}
                    delay={300}
                    multiple={true}
                    outsideLabelWithHTML={outsideLabelWithHTML}
                    listByIdName={this.state.inputListByIdName}
                    nameHash={this.state.inputNameHash}
                    uniqueValue={true}
                    noInfo={true}
                    remark={messageRemark}
                    isRemarkTranslationMissing={this.checkIfResponseRemarkTranslationIsMissing()}
                    isTranslationMissing={this.checkIfTranslationIsMissing()}
                    oldBackOfficeStyles={true}
                    oldBackOfficeStylesError={hasMandatoryError}
                    error={errorMessage}
                    disabled={this.state.isCurrentResponseDisabledDueToLogicDisabledOfOtherResponse}
                />
            </div>
        );
    }
}

export default injectIntl(ResponseAssistedEntry);
