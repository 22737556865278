import React from 'react';
import {
    ContentsFiltersCriteriaData,
    Right,
    SectionElement,
    SectionsTableFetchData,
    Tree,
    TreeElement,
    UploadedFileData,
} from '../../types';
import {ModalErrorVersion2} from '../../commonModals';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {
    apiCreateTree,
    apiDeleteTreeDocumentFile,
    apiDownloadTreeDocumentFile,
    apiFetchACAREARights,
    apiFetchTree,
    apiSectionsTableFetch,
    apiYearsTableFetch,
} from './ACAreaAdministratorContentsService';
import {injectIntl, IntlShape} from 'react-intl';
import {
    getLatestDateOfTreeElements,
    getUserRoles,
    mostRecentDateCondition,
} from '../TLO/CommonFunctions/CommonFunctions';
import SelectInput from '~components/SelectInput';
import styles from './ACAreaAdministratorContents.module.scss';
import {getPreSignedURLFetchRequest} from '../../utils/requests';
import {saveAs} from 'file-saver';
import CustomTable from '~components/CustomTable';
import ACAreaAdministratorContentsBottomCaption from './ACAreaAdministratorContentsBottomCaption';
import moment from 'moment';
import {formatDateEasy} from '../../components/FormatFunctions';
import ACAreaAdministratorContentsActionButtons from './ACAreaAdministratorContentsActionButtons';
import ModalContentItem from './modals/ModalContentItem';
import ModalContent from './modals/ModalContent';
import ModalContentDocument, {getLanguageAbbrvFromId} from './modals/ModalContentDocument';
import ModalConfirmVersion2 from '../../commonModals/ModalConfirmVersion2';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import AdminIcon from '../TLO/CommonComponents/AdminIcon/AdminIcon';
import ACAreaAdministratorTabs from '../../shared/ACAreaAdministratorTabs';
import NavigationACArea from '../../shared/NavigationACArea';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import {trackPageView} from '../../utils';
import cx from 'classnames';

const sendToList = [
    {
        label: 'AC Contact point',
        email: 1,
    },
    {
        label: 'Webmaster',
        email: 2,
    },
];

const modalScreenTypes = {
    CONFIRM_DELETE: 'CONFIRM_DELETE',
    ERROR: 'ERROR',
    DOCUMENT: 'DOCUMENT',
    CONTENT: 'CONTENT',
    CONTENT_ITEM: 'CONTENT_ITEM',
    CONTENT_DOCUMENT: 'CONTENT_DOCUMENT',
};

const RESULT_FIELDS_DEFAULT_ACAREA = ['title', 'languageId', 'lastUpdateDate'];

const DEFAULT_CRITERIA: ContentsFiltersCriteriaData = {
    section: '',
    year: '',
    language: '1',
    refresh: false,
};

interface IProps extends RouteComponentProps {
    intl: IntlShape;
}

interface IState {
    criteria: ContentsFiltersCriteriaData;
    expanded: any[];
    isModalErrorOpen: string | false;
    loading: boolean;
    modalScreen: string;
    newAddedFileUUIDs: string[];
    refresh: boolean;
    rights: Right[];
    rowObjectForModal: TreeElement | null;
    rowTypeText: string;
    sectionsTable: SectionElement[];
    showExpandedRow: {[key: number]: boolean};
    tag: string;
    timestamp: number;
    tree: Tree;
    yearsTable: string[];
}

class ACAreaAdministratorContents extends React.Component<IProps, IState> {
    userRoles = getUserRoles();

    constructor(props: IProps) {
        super(props);
        this.state = {
            criteria: DEFAULT_CRITERIA,
            expanded: [],
            isModalErrorOpen: false,
            loading: false,
            modalScreen: '',
            newAddedFileUUIDs: [],
            rights: [],
            rowObjectForModal: null,
            rowTypeText: '',
            refresh: false,
            sectionsTable: [],
            showExpandedRow: {},
            tag: '',
            timestamp: Date.now(),
            tree: JSON.parse(JSON.stringify({})),
            yearsTable: [],
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'acareaAdministratorContents'});
        this.loadJSONs();
    }

    loadJSONs = () =>
        this.setState({loading: true}, () => {
            apiSectionsTableFetch()
                .then((jsonResponse: SectionsTableFetchData) => {
                    if (jsonResponse && jsonResponse.data && jsonResponse.data.categories) {
                        this.setState({sectionsTable: jsonResponse.data.categories}, () => this.loadUrlParams());
                    }
                })
                .catch(error => {
                    this.setState({isModalErrorOpen: `Sections Table fetch error: ${error.message}`});
                });
        });

    fetchTree = () =>
        this.setState({loading: true}, () => {
            apiFetchTree({year: this.state.criteria.year, categoryId: this.state.criteria.section})
                .then(jsonResponse => {
                    if (jsonResponse && jsonResponse.tree && jsonResponse.tree.length > 0) {
                        this.setState({tree: jsonResponse, timestamp: Date.now()}, () => this.search(false));
                    } else {
                        this.setState({tree: JSON.parse(JSON.stringify({}))});
                    }
                })
                .finally(() => this.setState({loading: false}));
        });

    loadUrlParams = () => {
        this.setState({loading: true}, () => {
            const domainArray = document.location.href.split('?');
            const criteria = {...this.state.criteria};
            if (domainArray.length > 1) {
                const params = domainArray.pop();
                if (params) {
                    params.split('&').forEach(param => {
                        const paramElements = param.split('=');
                        if (paramElements.length === 2) {
                            const key = paramElements[0];
                            criteria[key] = decodeURIComponent(paramElements[1]);
                        }
                    });
                    if (criteria.section || criteria.year) {
                        if (criteria.section) {
                            apiYearsTableFetch(parseInt(criteria.section)).then(jsonResponse => {
                                if (jsonResponse && jsonResponse.data && jsonResponse.data.years) {
                                    const year = new Date().getFullYear().toString();
                                    if (jsonResponse.data.years.length === 0) {
                                        this.setState({yearsTable: [year]});
                                    } else {
                                        const years = jsonResponse.data.years;
                                        const yearIndex = years.indexOf(year);
                                        if (yearIndex === -1) {
                                            years.push(year);
                                        }
                                        this.setState({yearsTable: years});
                                    }
                                }
                                if (Object.keys(criteria).length > 0) {
                                    this.setState({criteria}, this.fetchTree);
                                }
                            });
                        } else {
                            if (Object.keys(criteria).length > 0) {
                                this.setState({criteria}, this.fetchTree);
                            }
                        }
                    }
                }
            } else {
                this.setState({loading: false});
            }
        });
    };

    closeModalError = () => this.setState({isModalErrorOpen: false});

    updateCriteriaValue = (
        // eslint-disable-next-line no-undef
        criteriaValue: Partial<ContentsFiltersCriteriaData>,
        callback = () => {},
        shouldRefresh = true
    ) => {
        this.setState(
            prev => ({
                criteria: {...prev.criteria, ...criteriaValue},
                tree: JSON.parse(JSON.stringify({})),
                refresh: shouldRefresh,
            }),
            () => callback && callback()
        );
    };

    resetCriteria = (callback?: any) =>
        this.setState(
            prev => ({
                criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
                tree: JSON.parse(JSON.stringify({})),
                tag: '',
            }),
            () => {
                this.props.history.replace(`/acareaAdministratorContents`);
                callback && callback();
            }
        );

    parseCriteria = (unParsedCriteria: ContentsFiltersCriteriaData): ContentsFiltersCriteriaData => {
        const parsedCriteria: ContentsFiltersCriteriaData = JSON.parse(JSON.stringify({}));
        Object.keys(unParsedCriteria).forEach(key => {
            parsedCriteria[key] = unParsedCriteria[key];
        });
        return parsedCriteria;
    };

    buildParams = (parsedCriteria: ContentsFiltersCriteriaData) => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(key => parsedCriteria[key] !== DEFAULT_CRITERIA[key] && key !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key]}`);
        const joinedParamsText = `?${paramArray.join('&')}`;
        this.props.history.replace(`/acareaAdministratorContents${(paramArray.length > 0 && joinedParamsText) || ''}`);
    };

    search = (shouldRefresh: boolean, urlLoad = false) => {
        // setCriteria(Object.assign({}, criteria, {refresh: !!shouldRefresh}, !shouldRefresh && !urlLoad));
        this.setState(prev => ({
            criteria: Object.assign({}, prev.criteria, {refresh: !!shouldRefresh}, !shouldRefresh && !urlLoad),
        }));
        const parsedCriteria = this.parseCriteria(this.state.criteria);
        this.buildParams(parsedCriteria);
        if (
            this.state.criteria.section &&
            this.state.criteria.section.length > 0 &&
            this.state.criteria.year &&
            this.state.criteria.year.length > 0
        ) {
            if (
                !this.state.criteria.section.includes('Please select a section') &&
                !this.state.criteria.year.includes('Please select a year')
            ) {
                const sectionNameList = this.state.sectionsTable.filter(
                    section => section.ID === Number(this.state.criteria.section)
                );
                if (sectionNameList.length === 1) {
                    this.setState(prev => ({tag: sectionNameList[0].ABBREVIATION + prev.criteria.year}));
                }
            } else {
                this.resetCriteria();
            }
        }
    };

    getYearOptions = () => {
        let yearOptions: Array<{id: number; value: string}> = [];
        this.state.yearsTable.forEach((yearEl: string) => yearOptions.push({id: Number(yearEl), value: yearEl}));
        yearOptions.sort((a, b) => b.id - a.id);
        if (this.state.criteria.year === '' && !this.state.criteria.year.includes('Please select a year')) {
            yearOptions.unshift({id: 0, value: '-- Please select a year --'});
        }
        return yearOptions;
    };

    getSectionsOptions = () => {
        let sectionOptions: Array<{id: number; value: string}> = [];
        this.state.sectionsTable.forEach((section: SectionElement) =>
            sectionOptions.push({id: section?.ID, value: section?.NAME})
        );
        sectionOptions.sort((a, b) => {
            if (a.value < b.value) {
                return -1;
            }
            if (a.value > b.value) {
                return 1;
            }
            return 0;
        });
        if (this.state.criteria.section === '' && !this.state.criteria.section.includes('Please select a section')) {
            sectionOptions.unshift({id: 0, value: '-- Please select a section --'});
        }
        return sectionOptions;
    };

    rowClickParent = (rowElement: any) => {
        let order = rowElement && rowElement.order;
        const expandedCopy: any[] = [...this.state.expanded];
        let expandedUpdated: any[] = expandedCopy.includes(order)
            ? expandedCopy.filter(element => element !== rowElement.order)
            : [...expandedCopy, order];
        let additionalRemove: any[] = [];
        expandedUpdated.forEach(order => {
            let orderOfPreviousLevel;
            let subLevels = (order || '').split('.');
            let numberOfSubLevels = subLevels.length;
            if (numberOfSubLevels <= 2) {
                orderOfPreviousLevel = subLevels[0];
            }
            if (numberOfSubLevels === 3) {
                orderOfPreviousLevel = subLevels[0] + '.' + subLevels[1];
            }
            if (orderOfPreviousLevel && !expandedUpdated.includes(orderOfPreviousLevel)) {
                additionalRemove.push(order);
            }
        });
        expandedUpdated = expandedUpdated.filter(order => !additionalRemove.includes(order));
        this.setState({expanded: expandedUpdated});
    };

    rowClass = (rowObject: any) => {
        let orderOfPreviousLevel;
        let subLevels = (rowObject.order || '').split('.');
        let numberOfSubLevels = subLevels.length;
        if (numberOfSubLevels <= 2) {
            orderOfPreviousLevel = subLevels[0];
        }
        if (numberOfSubLevels === 3) {
            orderOfPreviousLevel = subLevels[0] + '.' + subLevels[1];
        }
        if (rowObject && rowObject.order && !this.state.expanded.includes(orderOfPreviousLevel)) {
            if (rowObject.order.split('.').length > 1) {
                return styles.hide;
            }
        }
        if (rowObject && rowObject.order && this.state.expanded.includes(rowObject.order)) {
            return styles.expanded;
        }
        return styles.initial;
    };

    saveTree = (tree: Tree, callback?: any) =>
        this.setState({loading: true}, () => {
            apiCreateTree({
                tree,
                categoryId: this.state.criteria.section,
                year: this.state.criteria.year,
            })
                .then(() => this.setState({loading: false}, () => callback && callback()))
                .catch(error => {
                    this.setState({isModalErrorOpen: `error saving tree: ${error.message}`});
                });
        });

    downloadDocumentFile = (fileUUID: string, fileName: string) =>
        this.setState({loading: true}, () => {
            apiDownloadTreeDocumentFile(fileUUID, this.state.criteria.section)
                .then(response => {
                    if (response && response.signedUrl) {
                        const {signedUrl} = response;
                        getPreSignedURLFetchRequest(signedUrl)
                            .then(response => response.blob())
                            .then(responseBlob => {
                                const saveFileName = fileName ? fileName : fileUUID;
                                saveAs(responseBlob, saveFileName);
                            });
                    }
                })
                .catch(error => {
                    this.setState({isModalErrorOpen: `error downloading file: ${error}`});
                })
                .finally(() => this.setState({loading: false}));
        });

    openModalContentForAddition = () => {
        let treeViewListACAreaExceptFrDocs = this.fromTreeToFlatArray(this.state.tree).filter(el => el.level <= 3);
        let treeViewListACAreaFrDocs = this.fromTreeToFlatArray(this.state.tree).filter(el => el.level === 4);
        const newTreeViewList: Array<TreeElement> =
            treeViewListACAreaExceptFrDocs !== null && treeViewListACAreaFrDocs !== null
                ? JSON.parse(JSON.stringify(treeViewListACAreaExceptFrDocs.concat(treeViewListACAreaFrDocs)))
                : [];
        const maxId =
            newTreeViewList !== null && newTreeViewList.length > 0
                ? newTreeViewList.sort((a, b) => b.id - a.id)[0].id
                : 0;
        const newTree: Tree = JSON.parse(JSON.stringify(this.state.tree));
        const order = Object.entries(newTree).length > 0 ? newTree.tree.length + 1 : 1;
        const rowObjectForAddition: TreeElement = {
            id: maxId + 1,
            order: order.toString(),
            title: '',
            location: '',
            additionalInfo: '',
            beginDate: '',
            lastUpdateDate: '',
            isOnline: false,
            endDate: '',
            rights: [],
            file: [],
            level: 1,
            parentId: 0,
            parentTitle: '',
            showLastUpdateDate: true,
            categoryType: 0,
            isCategory: false,
            isContent: true,
            isContentItem: false,
            children: [],
        };
        this.setState({
            rowObjectForModal: rowObjectForAddition,
            modalScreen: modalScreenTypes.CONTENT,
        });
    };

    deleteRow = () => {
        this.closeModal();
        if (this.state.rowObjectForModal !== null) {
            if (this.state.rowObjectForModal.children.length > 0) {
                if (this.state.rowTypeText === 'category') {
                    this.setState({
                        isModalErrorOpen:
                            'Unable to delete this category. Some documents exist for this category. Delete them before delete the category.',
                    });
                } else if (this.state.rowTypeText === 'content') {
                    this.setState({
                        isModalErrorOpen:
                            'Unable to delete this content. Some content items and/or documents exist for this content. Delete them before delete the content.',
                    });
                } else if (this.state.rowTypeText === 'content item') {
                    this.setState({
                        isModalErrorOpen:
                            'Unable to delete this content item. Some documents exist for this content item. Delete them before delete the content item.',
                    });
                } else {
                    this.state.rowObjectForModal.level === 3 && this.deleteAllFiles(this.state.rowObjectForModal);
                    const newTree: Tree = JSON.parse(JSON.stringify(this.state.tree));
                    const rootIdx = newTree.tree.findIndex(el => el.id === this.state.rowObjectForModal?.id);
                    if (rootIdx !== -1) {
                        newTree.tree.splice(rootIdx, 1);
                        this.changeOrderOfSiblings(newTree.tree);
                        for (const treeEl of newTree.tree) {
                            this.changeOrderOfChildren(treeEl.children, treeEl.order);
                        }
                    } else {
                        const parent = this.getParent(newTree.tree, this.state.rowObjectForModal.parentId);
                        if (parent) {
                            const curIdx = parent.children.findIndex(el => el.id === this.state.rowObjectForModal?.id);
                            parent.children.splice(curIdx, 1);
                            this.changeOrderOfChildren(parent.children, parent.order);
                        }
                    }
                    this.saveTree(newTree, this.fetchTree);
                }
            } else {
                this.state.rowObjectForModal.level === 3 && this.deleteAllFiles(this.state.rowObjectForModal);
                const newTree: Tree = JSON.parse(JSON.stringify(this.state.tree));
                const rootIdx = newTree.tree.findIndex(el => el.id === this.state.rowObjectForModal?.id);
                if (rootIdx !== -1) {
                    newTree.tree.splice(rootIdx, 1);
                    this.changeOrderOfSiblings(newTree.tree);
                    for (const treeEl of newTree.tree) {
                        this.changeOrderOfChildren(treeEl.children, treeEl.order);
                    }
                } else {
                    const parent = this.getParent(newTree.tree, this.state.rowObjectForModal.parentId);
                    if (parent) {
                        const curIdx = parent.children.findIndex(el => el.id === this.state.rowObjectForModal?.id);
                        parent.children.splice(curIdx, 1);
                        this.changeOrderOfChildren(parent.children, parent.order);
                    }
                }
                this.saveTree(newTree, this.fetchTree);
            }
        }
    };

    changeOrderOfChildren = (children: Array<TreeElement>, parentOrder: string) => {
        const getChildren = (children: Array<TreeElement>, parentOrder: string) => {
            for (const child of children) {
                this.changeOrderOfSiblings(children, parentOrder);
                if (child.children.length > 0) {
                    getChildren(child.children, child.order);
                }
            }
        };
        for (const child of children) {
            this.changeOrderOfSiblings(children, parentOrder);
            getChildren(child.children, child.order);
        }
    };

    deleteAllFiles = (rowObject: TreeElement) =>
        this.setState({loading: true}, () => {
            let documentsList: Array<UploadedFileData> = this.getUploadedDocumentListWithLanguage(rowObject);
            for (const file of documentsList) {
                if (file.uuid && this.state.criteria.section) {
                    apiDeleteTreeDocumentFile(file.uuid, this.state.criteria.section)
                        .then(() => {
                            documentsList = documentsList.filter(el => el.uuid !== file.uuid);
                        })
                        .catch(error => {
                            this.setState({
                                loading: false,
                                isModalErrorOpen: `error deleting file: ${error}`,
                            });
                        });
                }
            }
            this.setState({loading: false});
        });

    changeOrderOfSiblings = (arr: Array<TreeElement>, parentOrder?: string) => {
        for (let i = 0; i < arr.length; i += 1) {
            if (arr[i].level > 3) {
                arr[i].order = parentOrder + '.' + getLanguageAbbrvFromId(arr[i].languageId).toUpperCase();
            } else {
                arr[i].order = parentOrder ? parentOrder + '.' + (i + 1).toString() : (i + 1).toString();
            }
        }
    };

    getUploadedDocumentListWithLanguage = (rowObject: TreeElement) => {
        let result: Array<UploadedFileData> = [];
        const fileInEnglish = rowObject.file;
        const filesInOtherTranslations: Array<UploadedFileData> = [];
        for (const treeEl of rowObject.children) {
            if (treeEl.file.length > 0) {
                treeEl.file[0].languageId = treeEl.languageId;
                filesInOtherTranslations.push(treeEl.file[0]);
            }
        }

        if (fileInEnglish.length > 0) {
            fileInEnglish[0].languageId = rowObject.languageId;
            result = result.concat(fileInEnglish);
        }
        if (filesInOtherTranslations.length > 0) {
            result = result.concat(filesInOtherTranslations);
        }

        return result;
    };

    openModalConfirmDeletion = (rowObject: TreeElement) => {
        let text = '';
        if (rowObject.isContent) {
            text = 'content';
        } else if (rowObject.isContentItem) {
            text = 'content item';
        } else {
            text = 'document';
        }
        this.setState({
            rowTypeText: text,
            rowObjectForModal: rowObject,
            modalScreen: modalScreenTypes.CONFIRM_DELETE,
        });
    };

    saveContentDocument = (contentDocumentElement: TreeElement, newAddedFileUUIDs: Array<string>) => {
        this.closeModal();
        this.setState({newAddedFileUUIDs}, () => {
            const newTree: Tree = JSON.parse(JSON.stringify(this.state.tree));
            let isNotEmptyTree = Object.entries(newTree).length > 0 && newTree.tree.length > 0;
            if (isNotEmptyTree) {
                let treeViewListACAreaExceptFrDocs = this.fromTreeToFlatArray(this.state.tree).filter(
                    el => el.level <= 3
                );
                let treeViewListACAreaFrDocs = this.fromTreeToFlatArray(this.state.tree).filter(el => el.level === 4);
                const newTreeViewList: Array<TreeElement> =
                    treeViewListACAreaExceptFrDocs !== null && treeViewListACAreaFrDocs !== null
                        ? JSON.parse(JSON.stringify(treeViewListACAreaExceptFrDocs.concat(treeViewListACAreaFrDocs)))
                        : [];
                const contentDocumentElements = newTreeViewList.filter(
                    el => el.isContent === false && el.isContentItem === false
                );
                const contentDocumentElementExist = contentDocumentElements.find(
                    el => el.id === contentDocumentElement.id
                );
                if (
                    contentDocumentElementExist &&
                    contentDocumentElement.isContent === false &&
                    contentDocumentElement.isContentItem === false
                ) {
                    const rootIdx = newTree.tree.findIndex(el => el.id === contentDocumentElement.id);
                    if (rootIdx !== -1) {
                        newTree.tree.splice(rootIdx, 1);
                        newTree.tree.splice(rootIdx, 0, contentDocumentElement);
                    } else {
                        const parent = this.getParent(newTree.tree, contentDocumentElement.parentId);
                        if (parent) {
                            const curIdx = parent.children.findIndex(el => el.id === contentDocumentElement.id);
                            parent.children.splice(curIdx, 1);
                            parent.children.splice(curIdx, 0, contentDocumentElement);
                        }
                    }
                } else {
                    const parent = this.getParent(newTree.tree, contentDocumentElement.parentId);
                    if (parent) {
                        parent.children.push(contentDocumentElement);
                    } else {
                        newTree.tree.push(contentDocumentElement);
                    }
                }
            } else {
                newTree.tree = [];
                newTree.tree.push(contentDocumentElement);
            }
            this.saveTree(newTree, this.fetchTree);
        });
    };

    saveContentItem = (contentItemElement: TreeElement) => {
        this.setState({modalScreen: ''});
        const newTree: Tree = JSON.parse(JSON.stringify(this.state.tree));
        let isNotEmptyTree = Object.entries(newTree).length > 0 && newTree.tree.length > 0;
        if (isNotEmptyTree) {
            let treeViewListACAreaExceptFrDocs = this.fromTreeToFlatArray(this.state.tree).filter(el => el.level <= 3);
            let treeViewListACAreaFrDocs = this.fromTreeToFlatArray(this.state.tree).filter(el => el.level === 4);
            const newTreeViewList: Array<TreeElement> =
                treeViewListACAreaExceptFrDocs !== null && treeViewListACAreaFrDocs !== null
                    ? JSON.parse(JSON.stringify(treeViewListACAreaExceptFrDocs.concat(treeViewListACAreaFrDocs)))
                    : [];
            const contentItemElements = newTreeViewList.filter(el => el.isContentItem);
            const contenteItemElementExists = contentItemElements.find(el => {
                return el.id === contentItemElement.id && contentItemElement.isContentItem;
            });
            if (contenteItemElementExists) {
                const parent = this.getParent(newTree.tree, contentItemElement.parentId);
                if (parent) {
                    const curIdx = parent.children.findIndex(el => el.id === contentItemElement.id);
                    parent.children.splice(curIdx, 1);
                    parent.children.splice(curIdx, 0, contentItemElement);
                }
            } else {
                const parent = this.getParent(newTree.tree, contentItemElement.parentId);
                if (parent) {
                    parent.children.push(contentItemElement);
                } else {
                    newTree.tree.push(contentItemElement);
                }
            }
            this.saveTree(newTree, this.fetchTree);
        }
    };

    saveContent = (contentElement: TreeElement) => {
        this.closeModal();
        const newTree: Tree = JSON.parse(JSON.stringify(this.state.tree));
        let isNotEmptyTree = Object.entries(newTree).length > 0 && newTree.tree.length > 0;
        const categoryElementExist = isNotEmptyTree ? newTree.tree.find(el => el.id === contentElement.id) : undefined;
        if (categoryElementExist && (contentElement.isCategory || contentElement.isContent)) {
            const curIdx = newTree.tree.findIndex(el => el.id === contentElement.id);
            newTree.tree.splice(curIdx, 1);
            newTree.tree.splice(curIdx, 0, contentElement);
        } else {
            if (isNotEmptyTree) {
                newTree.tree.push(contentElement);
            } else {
                newTree.tree = [];
                newTree.tree.push(contentElement);
            }
        }
        this.saveTree(newTree, this.fetchTree);
    };

    openModalContentDocumentForModify = (rowObject: TreeElement) => {
        if (rowObject.isContent === false && rowObject.isContentItem === false) {
            this.setState({rowObjectForModal: rowObject});
            this.getRights(rowObject);
        }
    };

    openModalContentItemForModify = (rowObject: TreeElement) => {
        if (rowObject.isContentItem) {
            this.getRights(rowObject);
        }
    };

    openModalContentForModify = (rowObject: TreeElement) => {
        if (rowObject.isContent) {
            this.setState({rowObjectForModal: rowObject, modalScreen: modalScreenTypes.CONTENT});
        }
    };

    closeModal = () => this.setState({modalScreen: ''});

    openModalContentDocumentForAddition = (rowObject: TreeElement) => {
        let treeViewListACAreaExceptFrDocs = this.fromTreeToFlatArray(this.state.tree).filter(el => el.level <= 3);
        let treeViewListACAreaFrDocs = this.fromTreeToFlatArray(this.state.tree).filter(el => el.level === 4);

        const newTreeViewList: Array<TreeElement> =
            treeViewListACAreaExceptFrDocs !== null && treeViewListACAreaFrDocs !== null
                ? JSON.parse(JSON.stringify(treeViewListACAreaExceptFrDocs.concat(treeViewListACAreaFrDocs)))
                : [];
        const maxId = newTreeViewList !== null ? newTreeViewList.sort((a, b) => b.id - a.id)[0].id : 0;
        const newTree: Tree = JSON.parse(JSON.stringify(this.state.tree));
        const order = Object.entries(newTree).length > 0 ? rowObject.children.length + 1 : 1;
        const today = moment();
        const formattedDate = today.format('YYYY-MM-DD');
        const rowObjectForAddition: TreeElement = {
            id: maxId + 1,
            order: rowObject.order + '.' + order.toString(),
            documentCode: '',
            title: '',
            lastUpdateDate: formatDateEasy(formattedDate),
            beginDate: formatDateEasy(formattedDate),
            endDate: '',
            categoryType: 0,
            rights: [],
            file: [],
            level: rowObject.level + 1,
            parentId: rowObject.id,
            parentTitle: rowObject.title,
            isCategory: false,
            showLastUpdateDate: true,
            languageId: 1,
            isContent: false,
            isContentItem: false,
            children: [],
        };
        this.getRights(rowObjectForAddition);
    };

    openModalContentItemForAddition = (rowObject: TreeElement) => {
        let treeViewListACAreaExceptFrDocs = this.fromTreeToFlatArray(this.state.tree).filter(el => el.level <= 3);
        let treeViewListACAreaFrDocs = this.fromTreeToFlatArray(this.state.tree).filter(el => el.level === 4);

        const newTreeViewList: Array<TreeElement> =
            treeViewListACAreaExceptFrDocs !== null && treeViewListACAreaFrDocs !== null
                ? JSON.parse(JSON.stringify(treeViewListACAreaExceptFrDocs.concat(treeViewListACAreaFrDocs)))
                : [];
        const maxId = newTreeViewList !== null ? newTreeViewList.sort((a, b) => b.id - a.id)[0].id : 0;
        const newTree: Tree = JSON.parse(JSON.stringify(this.state.tree));
        const order = Object.entries(newTree).length > 0 ? rowObject.children.length + 1 : 1;
        const today = moment();
        const formattedDate = today.format('YYYY-MM-DD');
        const rowObjectForAddition: TreeElement = {
            id: maxId + 1,
            order: rowObject.order + '.' + order.toString(),
            documentCode: '',
            title: '',
            additionalInfo: '',
            lastUpdateDate: formatDateEasy(formattedDate),
            beginDate: formatDateEasy(formattedDate),
            endDate: '',
            categoryType: 0,
            rights: [],
            file: [],
            level: rowObject.level + 1,
            parentId: rowObject.id,
            parentTitle: rowObject.title,
            isCategory: false,
            showLastUpdateDate: true,
            isContent: false,
            isContentItem: true,
            children: [],
        };
        this.getRights(rowObjectForAddition);
    };

    getRights = (rowObject: TreeElement) =>
        this.setState({loading: true}, () => {
            apiFetchACAREARights()
                .then(jsonResponse => {
                    this.setState({rights: jsonResponse});
                    if (rowObject.isContent === false && rowObject.isContentItem === true) {
                        this.setState({rowObjectForModal: rowObject, modalScreen: modalScreenTypes.CONTENT_ITEM});
                    }
                    if (rowObject.isContent === false && rowObject.isContentItem === false) {
                        this.setState({rowObjectForModal: rowObject, modalScreen: modalScreenTypes.CONTENT_DOCUMENT});
                    }
                })
                .catch(error => {
                    this.setState({isModalErrorOpen: `Fetch Rights error: ${error}`});
                })
                .finally(() => this.setState({loading: false}));
        });

    getParent(root: Array<TreeElement>, parentId?: number): TreeElement | undefined {
        let parent: TreeElement | undefined;

        root.some(el => {
            if (parentId && el.id === parentId) {
                return (parent = el);
            } else {
                return (parent = this.getParent(el.children, parentId));
            }
        });

        return parent;
    }

    expandedRow = (rowObject: TreeElement) => {
        let newTreeViewList = this.fromTreeToFlatArray(this.state.tree);
        let treeViewListACAreaExceptFrDocs = newTreeViewList.filter(el => el.level <= 3);
        let treeViewListACAreaFrDocs = newTreeViewList.filter(el => el.level === 4);
        let latestDate = getLatestDateOfTreeElements(newTreeViewList);

        if (treeViewListACAreaExceptFrDocs !== null && treeViewListACAreaFrDocs) {
            const fullTree = treeViewListACAreaExceptFrDocs.concat(treeViewListACAreaFrDocs);
            const findChild = fullTree.find(el => el.parentId === rowObject.id);
            const condition =
                findChild && mostRecentDateCondition(latestDate, findChild, true) && findChild.showLastUpdateDate;
            if (
                this.state.showExpandedRow[treeViewListACAreaExceptFrDocs.indexOf(rowObject)] === true &&
                findChild &&
                findChild.level > 3
            ) {
                return (
                    <tr
                        style={{
                            backgroundColor: condition ? 'rgb(250, 242, 204)' : 'inherit',
                        }}
                    >
                        <td></td>
                        <td
                            style={{
                                paddingLeft: 99,
                            }}
                        >
                            {this.getTitle(findChild.title, findChild)}
                        </td>
                        <td>{this.getLanguageData(findChild.languageId, findChild)}</td>
                        <td>{this.getLastUpdateDate(findChild.lastUpdateDate, findChild)}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                );
            }
        }

        return null;
    };

    rowClick = (_id: number, rowObject: TreeElement) => {
        let newTreeViewList = this.fromTreeToFlatArray(this.state.tree);
        let treeViewListACAreaExceptFrDocs = newTreeViewList.filter(el => el.level <= 3);
        if (treeViewListACAreaExceptFrDocs !== null) {
            const translationDocument: {[key: number]: boolean} = {};
            translationDocument[treeViewListACAreaExceptFrDocs.indexOf(rowObject)] =
                !this.state.showExpandedRow[treeViewListACAreaExceptFrDocs.indexOf(rowObject)];
            this.setState({showExpandedRow: translationDocument});
        }
        this.rowClickParent && this.rowClickParent(rowObject);
    };

    getLanguageData = (languageId: number | undefined, _rowObject: TreeElement): string => {
        let languageText = '';
        if (languageId) {
            switch (languageId) {
                case 1: {
                    languageText = 'EN';
                    break;
                }
                case 2: {
                    languageText = 'FR';
                    break;
                }
            }
        }
        return languageText;
    };

    getLastUpdateDate = (lastUpdateDate: string, rowObject: TreeElement): string =>
        rowObject.showLastUpdateDate && formatDateEasy(lastUpdateDate);

    getTitle = (title: string, rowObject: TreeElement) => {
        let newTreeViewList = this.fromTreeToFlatArray(this.state.tree);
        let categories = newTreeViewList.filter(el => el.isCategory === true);
        const hasFile = rowObject.file.length > 0 && rowObject.file[0].fileName.length > 0;
        const finalTitle = rowObject.order + ' - ' + title;
        const validityPeriodΕxpired =
            rowObject.endDate.length > 0 &&
            moment(new Date(), 'DD/MM/YYYY').diff(moment(rowObject.endDate, 'DD/MM/YYYY'), 'days') > 0;
        const categoriesWithRights = this.getCategoryWithRights(categories);
        const hasRightToViewDocument =
            (rowObject.parentId > 0 &&
                categoriesWithRights[rowObject.parentId] &&
                this.userRoles.some(el => categoriesWithRights[rowObject.parentId].includes(el))) ||
            categories.length === 0;

        return hasFile && !validityPeriodΕxpired && hasRightToViewDocument ? (
            <div title="Download Document">
                <a onClick={() => this.onClickTitle(rowObject)}>{finalTitle}</a>
            </div>
        ) : (
            <div>{finalTitle}</div>
        );
    };

    getCategoryWithRights = (categories: Array<TreeElement>) => {
        let result: {[key: number]: Array<string>} = {};
        for (const category of categories) {
            result[category.id] = category.rights;
        }

        return result;
    };

    fromTreeToFlatArray = (tree: Tree) => {
        let flatArray: Array<TreeElement> = [];
        if (tree && tree.tree) {
            const getChildren = (children: Array<TreeElement>) => {
                for (const child of children) {
                    flatArray.push(child);
                    if (child.children.length > 0) {
                        getChildren(child.children);
                    }
                }
            };
            for (const root of tree.tree) {
                flatArray.push(root);
                getChildren(root.children);
            }
        }
        return flatArray;
    };

    getRowClass = (rowObject: TreeElement) => {
        let newTreeViewList = this.fromTreeToFlatArray(this.state.tree);
        let latestDate = getLatestDateOfTreeElements(newTreeViewList);
        const conditionLatestDate =
            mostRecentDateCondition(latestDate, rowObject, true) && rowObject.showLastUpdateDate;
        const conditionHasΝοChild = rowObject.level === 3 && rowObject.children.length <= 0;
        const className =
            `acarea-nestLevel-` +
            rowObject.level +
            (conditionHasΝοChild ? '-no-child' : '') +
            (conditionLatestDate ? '-latestDate' : '');
        if (this.rowClass) {
            return cx([styles[className], this.rowClass(rowObject)]);
        }

        return styles[className];
    };

    onSectionChange = ({target: {value: section}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.resetCriteria(() => {
            this.updateCriteriaValue({section, year: '', language: '1'}, () => {
                if (parseInt(section) > 0 && !isNaN(parseInt(section))) {
                    this.setState({loading: true}, () => {
                        apiYearsTableFetch(parseInt(section))
                            .then(jsonResponse => {
                                if (jsonResponse && jsonResponse.data && jsonResponse.data.years) {
                                    const year = new Date().getFullYear().toString();
                                    if (jsonResponse.data.years.length === 0) {
                                        this.setState({yearsTable: [year]});
                                    } else {
                                        const years = jsonResponse.data.years;
                                        const yearIndex = years.indexOf(year);
                                        if (yearIndex === -1) {
                                            years.push(year);
                                        }
                                        this.setState({yearsTable: years});
                                    }
                                }
                            })
                            .catch(error => {
                                this.setState({isModalErrorOpen: `Years Table fetch error: ${error.message}`});
                            })
                            .finally(() => this.setState({loading: false}));
                    });
                }
            });
        });

    onYearChange = ({target: {value: year}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({year}, () => {
            this.setState({loading: true}, () => {
                apiFetchTree({year, categoryId: this.state.criteria.section})
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.tree && jsonResponse.tree.length > 0) {
                            this.setState({tree: jsonResponse});
                        } else {
                            this.setState({tree: JSON.parse(JSON.stringify({}))});
                        }
                    })
                    .catch(error => {
                        this.setState({isModalErrorOpen: `Fetch Tree error: ${error.message}`});
                    })
                    .finally(() => this.setState({loading: false}, () => this.search(false)));
            });
        });

    onClickTitle = (rowObject: TreeElement) =>
        this.downloadDocumentFile(rowObject.file[0].uuid || '', rowObject.file[0].fileName);

    render() {
        let newTreeViewList = this.fromTreeToFlatArray(this.state.tree);
        let treeViewListACAreaExceptFrDocs = newTreeViewList.filter(el => el.level <= 3);
        const actions = ACAreaAdministratorContentsActionButtons(
            this.openModalContentItemForAddition,
            this.openModalContentDocumentForAddition,
            this.openModalContentForModify,
            this.openModalContentItemForModify,
            this.openModalContentDocumentForModify,
            this.openModalConfirmDeletion
        );

        return (
            <>
                {this.state.isModalErrorOpen ? (
                    <ModalErrorVersion2
                        title={'Error'}
                        message={this.state.isModalErrorOpen}
                        close={this.closeModalError}
                    />
                ) : null}
                {this.state.modalScreen === modalScreenTypes.CONFIRM_DELETE && (
                    <ModalConfirmVersion2
                        title={`Delete ${this.state.rowTypeText}`}
                        message={`${
                            this.state.rowTypeText === 'document'
                                ? 'All files of all translations are going to be deleted. '
                                : ''
                        }Are you sure you want to delete this ${this.state.rowTypeText}?`}
                        buttonName={'Delete'}
                        action={this.deleteRow}
                        close={this.closeModal}
                    />
                )}
                {this.state.modalScreen === modalScreenTypes.CONTENT && this.state.rowObjectForModal !== null && (
                    <ModalContent
                        contentElement={this.state.rowObjectForModal}
                        saveContent={this.saveContent}
                        close={this.closeModal}
                    />
                )}
                {this.state.modalScreen === modalScreenTypes.CONTENT_DOCUMENT &&
                    this.state.rowObjectForModal !== null && (
                        <ModalContentDocument
                            contentDocumentElement={this.state.rowObjectForModal}
                            content={this.getParent(
                                this.state.tree.tree,
                                this.getParent(this.state.tree.tree, this.state.rowObjectForModal.parentId)?.parentId
                            )}
                            rights={this.state.rights}
                            tree={this.state.tree}
                            section={this.state.criteria.section || ''}
                            year={this.state.criteria.year || moment().format('YYYY')}
                            saveContentDocument={this.saveContentDocument}
                            close={this.closeModal}
                        />
                    )}
                {this.state.modalScreen === modalScreenTypes.CONTENT_ITEM && this.state.rowObjectForModal !== null && (
                    <ModalContentItem
                        contentItemElement={this.state.rowObjectForModal}
                        rights={this.state.rights}
                        saveContentItem={this.saveContentItem}
                        close={this.closeModal}
                    />
                )}
                {this.state.loading ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={'AC Area'} />
                <NavigationACArea activeTitle={'Administrator'} />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        {this.userRoles.indexOf('ACADM') === -1 ? (
                            <div style={{paddingTop: 20}}>
                                <b style={{color: 'red'}}>
                                    <span className="ng-scope">{`You have no permissions to access this page.`}</span>
                                </b>
                            </div>
                        ) : (
                            <>
                                <ACAreaAdministratorTabs />
                                <SelectInput
                                    value={this.state.criteria.section}
                                    onChange={this.onSectionChange}
                                    double={true}
                                    list={this.getSectionsOptions()}
                                    outsideLabel={'Section'}
                                    outsideLabelWidth={160}
                                    notAll={true}
                                />
                                {this.state.criteria.section !== '' &&
                                    !this.state.criteria.section.includes('Please select a section') && (
                                        <SelectInput
                                            value={this.state.criteria.year}
                                            onChange={this.onYearChange}
                                            double={true}
                                            list={this.getYearOptions()}
                                            outsideLabel={'Year'}
                                            outsideLabelWidth={160}
                                            notAll={true}
                                        />
                                    )}
                                <div style={{clear: 'both'}} />
                            </>
                        )}
                    </FormWrapper>
                    {this.userRoles.indexOf('ACADM') !== -1 &&
                        this.state.criteria.section &&
                        this.state.criteria.section.length > 0 &&
                        this.state.criteria.year &&
                        this.state.criteria.year.length > 0 &&
                        this.state.tag.length > 0 && (
                            <>
                                <div className={styles.topCaptionContainer} style={{marginTop: 20}}>
                                    <FormFooterButton
                                        color={buttonColor.blue}
                                        clickAction={this.openModalContentForAddition}
                                        icon={faPlus}
                                    >
                                        {`New Content`}
                                        <AdminIcon />
                                    </FormFooterButton>
                                </div>
                                <CustomTable
                                    version={1}
                                    pageSize={999}
                                    id={'id'}
                                    tableName={'treeViewList'}
                                    tableType={'OBJECT'}
                                    hideExcelButton={true}
                                    tableSource={treeViewListACAreaExceptFrDocs}
                                    timestamp={this.state.timestamp}
                                    resultFieldsDefault={RESULT_FIELDS_DEFAULT_ACAREA}
                                    intl={this.props.intl}
                                    reverseOrder={false}
                                    rowClass={this.getRowClass}
                                    formatFunctions={{
                                        title: (title: string, rowObject: TreeElement) =>
                                            this.getTitle(title, rowObject),
                                        documentCode: (_docCode: undefined, rowObject: TreeElement) => (
                                            <div>{rowObject.documentCode}</div>
                                        ),
                                        lastUpdateDate: (lastUpdateDate: string, rowObject: TreeElement) =>
                                            this.getLastUpdateDate(lastUpdateDate, rowObject),
                                        languageId: (languageId: number | undefined, rowObject: TreeElement) =>
                                            this.getLanguageData(languageId, rowObject),
                                    }}
                                    actions={actions}
                                    actionName={`Action`}
                                    showAdminIcon={true}
                                    notSortable={['title', 'documentCode', 'lastUpdateDate', 'languageId']}
                                    useExpandedRow={true}
                                    forehandColumn={true}
                                    fullExpandedRow={false}
                                    expandedRow={this.expandedRow}
                                    rowClick={this.rowClick}
                                    showExpandedRow={this.state.showExpandedRow}
                                    bottomCaption={<ACAreaAdministratorContentsBottomCaption />}
                                />
                            </>
                        )}
                </MainWrapper>
                <Footer
                    customSendToList={sendToList.slice(1)}
                    headerTitle={'Contact Webmaster'}
                    subjectTag={'[ACAREA]'}
                />
            </>
        );
    }
}

export default withRouter(injectIntl(ACAreaAdministratorContents));
