import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

let saving = false;
let APIongoing: any = null;

export function apiOnlineApplicationRequestUpdate2(jsonData: any) {
    return new Promise(resolve => {
        if (!saving) {
            saving = true;
            APIongoing = postFetch(`${API_SERVERLESS}/online/v3/applicationRequestUpdate`, true, jsonData);
            APIongoing.then((JSONResponse: any) => {
                saving = false;
                resolve(JSONResponse);
            });
        } else {
            APIongoing.then(() => {
                resolve({data: 'SAVING'});
            });
        }
    });
}

export function apiFormDatas({applicationRequestId, formType, verificationCode, formId}: any = {}) {
    let query = '';
    applicationRequestId &&
        formType &&
        formId &&
        (query = `applicationRequestId=${applicationRequestId}&formType=${formType}&formId=${formId}`);
    verificationCode && (query = `verificationCode=${verificationCode}`);
    return getFetch(`${API_SERVERLESS}/online/v2/datas${query ? `?${query}` : ''}`).then((jsonResponse: any) => {
        if (jsonResponse) {
            if (jsonResponse.datas && jsonResponse.datas.countries) {
                jsonResponse.datas.ue_countries = jsonResponse.datas.countries.filter(
                    ({UEMEMBER}: any = {}) => parseInt(UEMEMBER) === 1
                );
            }
            return jsonResponse;
        }
    });
}

export function apiOnlineApplicationRequestUpdate(jsonData: any) {
    return postFetch(`${API_SERVERLESS}/online/v3/applicationRequestUpdate`, true, jsonData);
}

export function apiGetFormDraft({applicationId, formId, formType, formSequence = '0'}: any) {
    return getFetch(
        `${API_SERVERLESS}/online/v3/formDraftGet?applicationId=${applicationId}&formId=${formId}&formType=${formType}&formSequence=${formSequence}&languageId=${`EN`}`,
        true
    );
}

export function apiFormDraftCheckIfUpdated({
    applicationRequestId,
    formId,
    formType,
    formSequence = '0',
    timestamp,
}: any) {
    return getFetch(
        `${API_SERVERLESS}/online/v3/formDraftCheckIfUpdated?applicationRequestId=${applicationRequestId}&formId=${formId}&formType=${formType}&formSequence=${formSequence}&timestamp=${timestamp}`,
        true
    );
}
