import React from 'react';

interface IIconHomeProps {
    style?: any;
    className?: any;
    color?: string;
}

export default function ({style: userStyle, color, className}: IIconHomeProps) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={userStyle}
            className={className}
        >
            <path
                d="M10.707 2.29279C10.5195 2.10532 10.2651 2 9.99998 2C9.73482 2 9.48051 2.10532 9.29298 2.29279L2.29298 9.29279C2.11082 9.48139 2.01003 9.73399 2.01231 9.99619C2.01458 10.2584 2.11975 10.5092 2.30516 10.6946C2.49057 10.88 2.74138 10.9852 3.00358 10.9875C3.26578 10.9897 3.51838 10.8889 3.70698 10.7068L3.99998 10.4138V16.9998C3.99998 17.265 4.10534 17.5194 4.29287 17.7069C4.48041 17.8944 4.73476 17.9998 4.99998 17.9998H6.99998C7.2652 17.9998 7.51955 17.8944 7.70709 17.7069C7.89462 17.5194 7.99998 17.265 7.99998 16.9998V14.9998C7.99998 14.7346 8.10534 14.4802 8.29287 14.2927C8.48041 14.1051 8.73476 13.9998 8.99998 13.9998H11C11.2652 13.9998 11.5196 14.1051 11.7071 14.2927C11.8946 14.4802 12 14.7346 12 14.9998V16.9998C12 17.265 12.1053 17.5194 12.2929 17.7069C12.4804 17.8944 12.7348 17.9998 13 17.9998H15C15.2652 17.9998 15.5196 17.8944 15.7071 17.7069C15.8946 17.5194 16 17.265 16 16.9998V10.4138L16.293 10.7068C16.4816 10.8889 16.7342 10.9897 16.9964 10.9875C17.2586 10.9852 17.5094 10.88 17.6948 10.6946C17.8802 10.5092 17.9854 10.2584 17.9877 9.99619C17.9899 9.73399 17.8891 9.48139 17.707 9.29279L10.707 2.29279Z"
                fill={color || '#323232'}
            />
        </svg>
    );
}
