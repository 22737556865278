import React, {useEffect} from 'react';
import styles from './ConfidentialQuestion.module.scss';
import {IGroup, IGroupQuestion, IStateResponsesClient} from '../../../types';

// needed because at rendering of multiple confidential questions in the same group
// they all get the same initial state
// and when updating, only the last - default confidential answer - is persisted
let defaultValues: any = {};

// doesn't take into account if the question belongs to other group
// e.g.: apply same confidential value to questions of this group
const checkIfCurrentQuestionHasSameConfidentialValue = ({
    group,
    question: currentQuestion,
}: {
    group: IGroup;
    question: IGroupQuestion;
}) => {
    let result = false;
    (group.questions || []).forEach(question =>
        question.logics.forEach(logic => {
            if (logic.type === 'applysameconfidentialvalue' && logic.questionIdDest === currentQuestion.id) {
                result = true;
            }
        })
    );
    return result;
};

const getSourceQuestionWithLogicApplySameConfidentialValueToCurrentQuestion = ({
    group,
    question: currentQuestion,
}: {
    group: IGroup;
    question: IGroupQuestion;
}): IGroupQuestion | false => {
    let result: IGroupQuestion | false = false;
    (group.questions || []).forEach(question =>
        question.logics.forEach(logic => {
            if (logic.type === 'applysameconfidentialvalue' && logic.questionIdDest === currentQuestion.id) {
                result = question;
            }
        })
    );
    return result;
};

const updateResponsesClientSameConfidentialValuesIfNeeded = ({
    question,
    updatedResponsesClient,
    confidentialValue,
    groupKeyWithIteration,
}: {
    question: IGroupQuestion;
    updatedResponsesClient: IStateResponsesClient;
    confidentialValue: any;
    groupKeyWithIteration: string;
}): IStateResponsesClient => {
    (question.logics || []).forEach(logic => {
        if (logic.type === 'applysameconfidentialvalue') {
            let questionIdDest = logic.questionIdDest;
            if (questionIdDest) {
                let key = `conf${groupKeyWithIteration}_Q${questionIdDest}_1`;
                updatedResponsesClient[key] = confidentialValue;
                defaultValues[key] = confidentialValue;
            }
        }
    });
    return updatedResponsesClient;
};

const getQuestionLogicDefaultConfidentialValueFromQuestion = (question: IGroupQuestion) => {
    if (!question || !question.logics || !question.logics.length) {
        return null;
    }
    let questionLogicDefaultConfidentialValue = (question.logics || []).find(
        logic => logic.type === 'confidentialvalue'
    );
    if (questionLogicDefaultConfidentialValue) {
        let {logicParam} = questionLogicDefaultConfidentialValue || {};
        if (logicParam && logicParam.indexOf('value:') !== -1 && (logicParam || '').split('value:').length > 1) {
            return parseInt(logicParam.split('value:')[1]);
        }
        return 1;
    }
    return null;
};

const checkAndUpdateIfQuestionHasDefaultConfidentialValue = ({
    groupKeyWithIteration,
    question,
    getCopyOfResponsesClient,
    updateResponsesClient,
    getQuestionConfKeyWithIteration,
}: {
    groupKeyWithIteration: string;
    question: IGroupQuestion;
    getCopyOfResponsesClient: () => any;
    updateResponsesClient: (responsesClient: IStateResponsesClient) => any;
    getQuestionConfKeyWithIteration: () => string;
}) => {
    let defaultConfidentialValue = getQuestionLogicDefaultConfidentialValueFromQuestion(question);
    if (defaultConfidentialValue !== null) {
        let updatedResponsesClient = getCopyOfResponsesClient();
        let confQuestionKeyWithIteration = getQuestionConfKeyWithIteration();
        if (updatedResponsesClient[confQuestionKeyWithIteration] === undefined) {
            updatedResponsesClient[confQuestionKeyWithIteration] = defaultConfidentialValue;
            defaultValues[confQuestionKeyWithIteration] = defaultConfidentialValue;
            updateResponsesClientSameConfidentialValuesIfNeeded({
                question,
                updatedResponsesClient,
                confidentialValue: defaultConfidentialValue,
                groupKeyWithIteration,
            });
            updateResponsesClient({...updatedResponsesClient, ...defaultValues});
        }
    }
};

interface IConfidentialQuestionProps {
    getCopyOfResponsesClient: () => IStateResponsesClient;
    getQuestionConfKeyWithIteration: () => string;
    group: IGroup;
    groupKeyWithIteration: string;
    intl: any;
    question: IGroupQuestion;
    updateResponsesClient: (arg0: IStateResponsesClient) => any;
}

export default function ConfidentialQuestion({
    getCopyOfResponsesClient,
    getQuestionConfKeyWithIteration,
    group,
    groupKeyWithIteration,
    intl,
    question,
    updateResponsesClient,
}: IConfidentialQuestionProps) {
    if (!question || !question.confidential) {
        return null;
    }

    useEffect(() => {
        checkAndUpdateIfQuestionHasDefaultConfidentialValue({
            groupKeyWithIteration,
            getQuestionConfKeyWithIteration,
            question,
            getCopyOfResponsesClient,
            updateResponsesClient,
        });
    }, [question]);

    //e.g: confGR123_1_Q20407_1: 1
    const onConfidentialValueChange = ({target: {value: confidentialValue}}: React.ChangeEvent<HTMLInputElement>) => {
        let updatedResponsesClient = getCopyOfResponsesClient();
        const questionConfKeyWithIteration = getQuestionConfKeyWithIteration();
        updatedResponsesClient[questionConfKeyWithIteration] = parseInt(confidentialValue);
        updatedResponsesClient = updateResponsesClientSameConfidentialValuesIfNeeded({
            question,
            updatedResponsesClient,
            confidentialValue: parseInt(confidentialValue),
            groupKeyWithIteration,
        });
        updateResponsesClient(updatedResponsesClient);
    };

    const responsesClient = getCopyOfResponsesClient();

    const questionConfKeyWithIteration = getQuestionConfKeyWithIteration();

    const questionHasConfidentialAnswerYes = parseInt(responsesClient[questionConfKeyWithIteration]) === 1;

    const questionHasConfidentialAnswerNo = parseInt(responsesClient[questionConfKeyWithIteration]) === 0;

    const hasSameConfidentialValue = checkIfCurrentQuestionHasSameConfidentialValue({
        group,
        question,
    });

    useEffect(() => {
        let qSource = getSourceQuestionWithLogicApplySameConfidentialValueToCurrentQuestion({
            group,
            question,
        });
        if (qSource && (questionConfKeyWithIteration || '').split('_').length === 4) {
            let keyParts = (questionConfKeyWithIteration || '').split('_'); // ['confT21', '1', 'Q11918', '1']
            keyParts[2] = `Q${qSource.id}`;
            let sourceQuestionConfKeyWithIteration = keyParts.join('_');
            if (
                sourceQuestionConfKeyWithIteration &&
                (responsesClient[sourceQuestionConfKeyWithIteration] === 0 ||
                    responsesClient[sourceQuestionConfKeyWithIteration] === 1)
            ) {
                let updatedResponsesClient = getCopyOfResponsesClient();
                updatedResponsesClient[questionConfKeyWithIteration] =
                    responsesClient[sourceQuestionConfKeyWithIteration];
                updateResponsesClient(updatedResponsesClient);
            }
        }
        setTimeout(() => {
            defaultValues = {};
        }, 2000);
    }, []);

    return (
        <>
            <div
                className={styles.questionConfidentialWrap}
                style={{marginTop: 30, color: hasSameConfidentialValue ? '#ccc' : 'black'}}
            >
                <div>
                    <span
                        className={styles.questionConfidentialMessage}
                        style={{color: hasSameConfidentialValue ? '#ccc' : '#2f923f', marginRight: 15}}
                    >
                        {`${intl && intl.formatMessage({id: 'treatAsConfidential.message'})}`}
                    </span>
                    <label
                        htmlFor={`question-confidential-yes-${questionConfKeyWithIteration}`}
                        className={styles.confidentialQuestionLabel}
                    >
                        <input
                            type="radio"
                            name={questionConfKeyWithIteration}
                            value="1"
                            id={`question-confidential-yes-${questionConfKeyWithIteration}`}
                            onChange={hasSameConfidentialValue ? undefined : onConfidentialValueChange}
                            checked={questionHasConfidentialAnswerYes}
                            disabled={hasSameConfidentialValue}
                            className={styles.confidentialQuestionInput}
                        />
                        <span>{`${intl && intl.formatMessage({id: 'treatAsConfidential.yes'})}`}</span> &nbsp;
                    </label>
                    <label
                        htmlFor={`question-confidential-no-${questionConfKeyWithIteration}`}
                        className={styles.confidentialQuestionLabel}
                    >
                        <input
                            type="radio"
                            name={questionConfKeyWithIteration}
                            value="0"
                            id={`question-confidential-no-${questionConfKeyWithIteration}`}
                            onChange={hasSameConfidentialValue ? undefined : onConfidentialValueChange}
                            checked={questionHasConfidentialAnswerNo}
                            disabled={hasSameConfidentialValue}
                            className={styles.confidentialQuestionInput}
                        />
                        <span>{`${intl && intl.formatMessage({id: 'treatAsConfidential.no'})}`}</span>
                    </label>
                </div>
            </div>
        </>
    );
}
