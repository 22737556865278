import React from 'react';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {apiVDCMessageGet, apiVDCMessageSend, IVDCCaseMessage} from './VDCCaseMessageService';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {Editor} from '@tinymce/tinymce-react';
import RadioButton from '~components/RadioButton';
import {loadUrlParams} from '~utils';
import {formatDateTimeEasy} from '~components/FormatFunctions';
import styles from './VDCCaseMessage.module.scss';
import Checkbox from '~components/Checkbox';
import {htmlToText, trackPageView} from '../../utils';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import {Button} from '../../componentsFormV2';
import Navigation from '../../componentsLayout/Navigation';

const tinyConfig = {
    height: 350,
    inline: false,
    plugins: 'table hr anchor paste preview table',
    toolbar1: 'bold italic underline strikethrough | bullist numlist | outdent indent',
    statusbar: false,
    menubar: false,
    language: 'en',
    table_default_attributes: {
        border: '0',
    },
};

interface IProps {
    intl: any;
    history: any;
}

interface IState {
    body: string;
    caseTitle: string;
    checkboxConclusionValue: boolean;
    loading: number;
    messages: IVDCCaseMessage[];
    president: boolean;
    radioButtonTypeValue: string;
    screenLoaded: boolean;
    usersByEmail: {[keys: string]: {email: string; firstName: string; lastName: string}};
}

export const checkIfURIHasPARAM = (): string | undefined => {
    if (window.location.search.split('PARAM=').length > 1) {
        return window.location.search.split('PARAM=')[1];
    }
};

export const checkIfURIHasPARAM2 = (): string | undefined => {
    if (window.location.search.split('PARAM2=').length > 1) {
        return window.location.search.split('PARAM2=')[1];
    }
};

class VDCCaseMessage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            body: ``,
            caseTitle: '',
            checkboxConclusionValue: false,
            loading: 0,
            messages: [],
            screenLoaded: false,
            president: false,
            radioButtonTypeValue: '3',
            usersByEmail: {},
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'VDCCaseMessage'});
        this.loadJSONs();
    }

    loadJSONs = () => {
        const {caseId} = loadUrlParams();
        const PARAM = checkIfURIHasPARAM();
        const PARAM2 = checkIfURIHasPARAM2();
        if (caseId || PARAM || PARAM2) {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiVDCMessageGet(caseId, PARAM, PARAM2).then(({messages, president, title, usersByEmail}) => {
                        if (Array.isArray(messages)) {
                            this.setState(prev => ({
                                caseTitle: title,
                                loading: prev.loading - 1,
                                messages: messages,
                                president,
                                usersByEmail,
                                screenLoaded: true,
                            }));
                        }
                    });
                }
            );
        }
    };

    formatEmailToFullName = (email: string, comma?: boolean) => {
        let user = this.state.usersByEmail[email];
        if (user) {
            return (
                <React.Fragment key={email}>
                    <a href={`mailto:${user.email}`} title={email}>{`${user.firstName} ${user.lastName}`}</a>
                    {comma ? <span>{`, `}</span> : null}
                </React.Fragment>
            );
        }
        return email;
    };

    formatStringEmailsToFullNames = (stringEmails: string) => (
        <>
            {stringEmails.split(';').map((email: string, index: number) => {
                return this.formatEmailToFullName(email, index + 1 !== stringEmails.split(';').length);
            })}
        </>
    );

    getConclusion = (conclusion: number) => {
        if (conclusion) {
            return <div className={styles.labelVDCConclusion}>{`VDC Conclusion`}</div>;
        }
    };

    getObservation = (suitableNotSuitable: number) => {
        if (suitableNotSuitable === 1) {
            return <div className={styles.labelNoObservation}>{`No observation`}</div>;
        } else if (suitableNotSuitable === 2) {
            return <div className={styles.labelObservation}>{`Observation`}</div>;
        }
    };

    getMessageTitle = () => {
        if (this.state.caseTitle) {
            return this.state.caseTitle;
        }
        if (this.state.messages.length) {
            return ([...this.state.messages].pop() || {}).messageSubject;
        }
        return '...';
    };

    onBodyChange = (body: string) => this.setState({body});

    onButtonJurisprudenceClick = () => {
        const {caseId} = loadUrlParams();
        const PARAM = checkIfURIHasPARAM();
        const PARAM2 = checkIfURIHasPARAM2();
        let testId;
        if (this.state.messages.length) {
            testId = this.state.messages[0].testId;
        }
        this.props.history.push(
            `/VDCJurisprudence?testId=${testId}&${caseId ? `caseId=${caseId}` : ''}${PARAM ? `PARAM=${PARAM}` : ''}${
                PARAM2 ? `PARAM2=${PARAM2}` : ''
            }`
        );
    };

    onButtonSendMessageClick = () => {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiVDCMessageSend({
                    messageText: htmlToText(this.state.body),
                    messageHtml: this.state.body,
                    type: this.state.radioButtonTypeValue,
                    caseId: this.state.messages.length ? this.state.messages[0].caseId : null,
                    conclusion: this.state.checkboxConclusionValue,
                    previousMessageId: this.state.messages.length ? this.state.messages[0].messageId : -1,
                }).then(JSONResponse => {
                    if (JSONResponse && JSONResponse.data === 'OK') {
                        this.setState(
                            prev => ({
                                loading: prev.loading - 1,
                                body: '',
                            }),
                            () => this.loadJSONs()
                        );
                    }
                });
            }
        );
    };

    onRadioButtonTypeChange = (radioButtonTypeValue: string) => this.setState({radioButtonTypeValue});

    onButtonVDCCasesClick = () => this.props.history.push('/VDCCases?goBack=true');

    onLinkButtonClearMessageClick = () => this.setState({body: ''});

    onCheckboxConclusionChange = () =>
        this.setState(prev => ({checkboxConclusionValue: !prev.checkboxConclusionValue}));

    render() {
        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={this.getMessageTitle() || ''} />
                <Navigation />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div style={{marginBottom: 20}}>
                            <Button
                                variation={'secondary'}
                                clickAction={this.onButtonVDCCasesClick}
                                style={{marginRight: 15}}
                            >{`VDC Cases`}</Button>
                            <Button
                                variation={'secondary'}
                                clickAction={this.onButtonJurisprudenceClick}
                            >{`Jurisprudence`}</Button>
                        </div>
                        <RadioButton
                            radioButtonList={[
                                {
                                    description: 'No observation',
                                    value: '1',
                                },
                                {description: 'Observation', value: '2'},
                                {description: 'No opinion', value: '3'},
                            ]}
                            groupName={'Type'}
                            value={this.state.radioButtonTypeValue}
                            clickEvent={this.onRadioButtonTypeChange}
                            double={true}
                        />
                        {this.state.president ? (
                            <div style={{marginBottom: 10, marginTop: -15, float: 'left'}}>
                                <Checkbox
                                    clickAction={this.onCheckboxConclusionChange}
                                    label={`VDC Conclusion`}
                                    value={this.state.checkboxConclusionValue}
                                    double={true}
                                />
                                <div style={{clear: 'both'}} />
                            </div>
                        ) : null}
                        <div style={{clear: 'both'}} />
                        <Editor
                            id="textareaMainLanguage"
                            value={this.state.body}
                            init={tinyConfig}
                            onEditorChange={this.onBodyChange}
                        />
                        <FormFooter>
                            <Button
                                variation={'secondary'}
                                clickAction={this.onLinkButtonClearMessageClick}
                            >{`Clear message`}</Button>
                            <Button clickAction={this.onButtonSendMessageClick}>{`Send Message`}</Button>
                        </FormFooter>
                        <div style={{marginTop: 50}}>
                            {this.state.messages.map(
                                ({
                                    messageId,
                                    messageBody,
                                    messageSubject,
                                    sender,
                                    suitableNotSuitable,
                                    sendingDate,
                                    emailTo,
                                    emailCC,
                                    conclusion,
                                }) => (
                                    <div key={messageId}>
                                        <DataSheetFormSection disableToggle={true}>
                                            {this.getConclusion(conclusion) ||
                                            this.getObservation(suitableNotSuitable) ? (
                                                <section className={styles.labels}>
                                                    {this.getConclusion(conclusion)}
                                                    {this.getObservation(suitableNotSuitable)}
                                                </section>
                                            ) : null}
                                            <div>
                                                <div>
                                                    <DataSheetFormFields
                                                        label={'From'}
                                                        data={
                                                            <div style={{wordBreak: 'break-word'}}>
                                                                {this.formatEmailToFullName(sender)}
                                                            </div>
                                                        }
                                                        double={true}
                                                    />
                                                    <DataSheetFormFields
                                                        label={'Sent on'}
                                                        data={formatDateTimeEasy(sendingDate)}
                                                        double={true}
                                                    />
                                                    <DataSheetFormFields
                                                        label={'To'}
                                                        data={
                                                            <div style={{wordBreak: 'break-word'}}>
                                                                {this.formatStringEmailsToFullNames(emailTo || '')}
                                                            </div>
                                                        }
                                                        double={true}
                                                    />
                                                    <DataSheetFormFields
                                                        label={'Copy to'}
                                                        data={
                                                            <div style={{wordBreak: 'break-word'}}>
                                                                {this.formatStringEmailsToFullNames(emailCC || '')}
                                                            </div>
                                                        }
                                                        double={true}
                                                    />
                                                    <DataSheetFormFields
                                                        label={'Subject'}
                                                        data={messageSubject}
                                                        double={true}
                                                    />
                                                </div>
                                                <div style={{clear: 'both'}} />
                                            </div>
                                            <div
                                                /* eslint-disable-next-line react/no-danger */
                                                dangerouslySetInnerHTML={{__html: messageBody}}
                                                style={{marginTop: 15}}
                                            />
                                        </DataSheetFormSection>
                                        <div style={{clear: 'both'}} />
                                    </div>
                                )
                            )}
                        </div>
                    </FormWrapper>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(VDCCaseMessage));
