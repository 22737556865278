import React, {useEffect} from 'react';
import cx from 'classnames';
import stylesNew from './TextInput.module.scss';
import stylesOld from './TextInputOld.module.scss';
import InputContainer from '~componentsForm/InputContainer';
import FirstRow from '~componentsForm/FirstRow';
import SecondRow from '~componentsForm/SecondRow';
import FirstRowLabel from '~componentsForm/FirstRowLabel';
import FirstRowInfo from '~componentsForm/FirstRowInfo';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CustomPopoverInput from './CustomPopoverInput';
import Label from './Label';
import Error from './Error';
import Remark from './Remark';

const filterLabelHash = {
    contains: 'Contains',
    start: 'Starts with',
    equals: 'Equals',
};

export default function ({
    autoCompleteDisabled,
    children,
    placeholder,
    filter,
    currentFilter,
    onFilterChange,
    double,
    triple,
    label,
    mandatory,
    infoAction,
    buttonIcon,
    buttonAction,
    buttonColor,
    onChange,
    onEnter,
    value,
    popOverText,
    popOverTextFirstRow,
    disabled,
    outsideLabel,
    outsideLabelWithHTML,
    outsideLabelWidth,
    type,
    focus,
    background,
    error,
    errorVarietyFinder,
    width,
    maxWidth,
    remark,
    isRemarkTranslationMissing,
    oldBackOfficeStyles,
    oldBackOfficeStylesError,
    twoInputsPerLine,
    isTranslationMissing,
    onBlur,
    noIcon,
    inputIsHidden,
    PDFVersion,
    size,
    half,
    onArrowUp,
    onArrowDown,
}) {
    let elInput = null;
    let styles = oldBackOfficeStyles ? stylesOld : stylesNew;

    useEffect(() => focus && elInput && elInput.focus(), []);

    const onBlurFn = () => onBlur && onBlur();

    const onKeyDown = event => {
        if (event && event.key === 'ArrowUp' && onArrowUp) {
            event.preventDefault();
            onArrowUp();
        }
        if (event && event.key === 'ArrowDown' && onArrowDown) {
            event.preventDefault();
            onArrowDown();
        }
    };

    const inputField = (
        <input
            type={type || 'text'}
            ref={el => (elInput = el)}
            {...{placeholder, onChange}}
            value={value || ''}
            className={cx(styles.input, disabled && styles.inputDisabled, oldBackOfficeStylesError && styles.hasError)}
            onKeyUp={event => event.key === 'Enter' && onEnter && onEnter()}
            onKeyDown={onKeyDown}
            disabled={disabled && 'disabled'}
            onBlur={onBlurFn}
            autoComplete={autoCompleteDisabled ? 'new-password' : 'off'}
        />
    );

    return (
        <React.Fragment>
            {outsideLabelWithHTML && (
                <div
                    className={cx(
                        styles.label,
                        twoInputsPerLine && styles.twoInputsPerLine,
                        isTranslationMissing && styles.translationMissing
                    )}
                >
                    <Label {...{mandatory}} width={outsideLabelWidth}>
                        {outsideLabelWithHTML}
                    </Label>
                </div>
            )}
            {outsideLabel && (
                <div className={styles.label}>
                    <Label {...{mandatory}} width={outsideLabelWidth}>
                        {outsideLabel}
                    </Label>
                </div>
            )}
            {!inputIsHidden && (
                <InputContainer {...{double, triple, width, maxWidth, noIcon, twoInputsPerLine, size, half}}>
                    <FirstRow>
                        {outsideLabel ? (
                            <FirstRowLabel {...{mandatory, outsideLabel}}>{outsideLabel}</FirstRowLabel>
                        ) : null}
                        {label ? <FirstRowLabel {...{mandatory, popOverTextFirstRow}}>{label}</FirstRowLabel> : null}
                        <React.Fragment>{children}</React.Fragment>
                        {infoAction && <FirstRowInfo onClick={infoAction} />}
                    </FirstRow>
                    <SecondRow
                        buttonIcon={popOverText || buttonIcon}
                        errorBorderColor={errorVarietyFinder}
                        {...{disabled}}
                        {...{background, oldBackOfficeStyles}}
                    >
                        {filter ? (
                            <React.Fragment>
                                {filter === true || Array.isArray(filter) ? (
                                    <select
                                        className={cx(styles.select, disabled && styles.selectDisabled)}
                                        onChange={onFilterChange}
                                        value={currentFilter}
                                    >
                                        {(Array.isArray(filter) ? filter : ['contains', 'start', 'equals']).map(
                                            key =>
                                                filterLabelHash[key] && (
                                                    <option value={key}>{filterLabelHash[key]}</option>
                                                )
                                        )}
                                        disabled={disabled && 'disabled'}
                                    </select>
                                ) : (
                                    <div onClick={() => elInput && elInput.focus()} className={styles.tag}>
                                        {filter}
                                    </div>
                                )}
                            </React.Fragment>
                        ) : null}
                        {type === 'password' ? (
                            <form onSubmit={event => event.preventDefault()} autoComplete={'off'}>
                                {inputField}
                            </form>
                        ) : (
                            inputField
                        )}
                        <div style={{clear: 'both'}}>{}</div>
                    </SecondRow>
                    {buttonIcon && buttonIcon !== 'none' && (
                        <button type="button" className={cx(styles.button)} onClick={buttonAction}>
                            <FontAwesomeIcon icon={buttonIcon} color={buttonColor || 'green'} />
                        </button>
                    )}
                    {error && (
                        <div
                            className={cx(styles.errorWrap, twoInputsPerLine && styles.twoInputsPerLine)}
                            style={buttonIcon && {marginRight: 32}}
                        >
                            {oldBackOfficeStylesError ? (
                                <Error wizardResponse={true}>{error}</Error>
                            ) : (
                                <Error> {error}</Error>
                            )}
                        </div>
                    )}
                    {remark && (
                        <div>
                            <Remark remark={remark} isRemarkTranslationMissing={isRemarkTranslationMissing} />
                        </div>
                    )}
                    {popOverText && <CustomPopoverInput content={popOverText} />}
                    {errorVarietyFinder ? (
                        <>
                            <div style={{clear: 'both'}} />
                            <div style={{marginLeft: 3, marginTop: 3, color: 'red'}}>{errorVarietyFinder}</div>
                        </>
                    ) : null}
                </InputContainer>
            )}
        </React.Fragment>
    );
}
