import axios from 'axios';
import {checkTokenError, getToken} from './utilsVarietyFinder';
import {getEnvironment} from '~utils';

const UNAUTHORIZED = 401;

const graphQLClientInstance = axios.create({
    baseURL: getEnvironment() === 'LOCALHOST' ? 'http://localhost:4000/' : 'https://vf-api.plantvarieties.eu/',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
});

graphQLClientInstance.interceptors.request.use(
    config => {
        const token = getToken();
        if (token && !checkTokenError(token)) {
            config.headers['x-auth'] = token;
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

graphQLClientInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response) {
            const {status, statusText} = error.response;
            if (status === UNAUTHORIZED) {
                window.location.href = `/error?origin=${window.location.href}&status=${status}&error=${statusText}`;
            }
        }
        return Promise.reject(error);
    }
);

export default graphQLClientInstance;
