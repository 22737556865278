import dateFormat from 'date-format';

export const FORMAT_DATE = 'FORMAT_DATE';
export function formatDate(fieldData) {
    if (fieldData) {
        if (fieldData.indexOf('/') !== -1) {
            const fieldDataArray = fieldData.split('/');
            if (fieldDataArray[0].length === 4) {
                return dateFormat('dd/MM/yyyy', dateFormat.parse('yyyy/MM/dd', fieldData));
            }
            return fieldData;
        }
        return dateFormat(
            'dd/MM/yyyy',
            dateFormat.parse(fieldData.indexOf('-') === -1 ? 'yyyyMMdd' : 'yyyy-MM-dd', fieldData)
        );
    }
    return '';
}

export const FORMAT_DATE_EASY = 'FORMAT_DATE_EASY';
export function formatDateEasy(fieldData) {
    if (fieldData) {
        return fieldData.split('-').reverse().join('/');
    }
    return '';
}

export const FORMAT_DATE_TIME_EASY = 'FORMAT_DATE_TIME_EASY';
export function formatDateTimeEasy(fieldData) {
    if (fieldData) {
        return `${fieldData.split(' ')[0].split('-').reverse().join('/')} ${fieldData.split(' ')[1] || ''}`;
    }
    return '';
}

export function reformatDateEasy(fieldData) {
    if (fieldData) {
        return fieldData.split('/').reverse().join('-');
    }
    return '';
}

export function reformatDate(fieldData) {
    if (fieldData) {
        return fieldData.split('/').reverse().join('');
    }
    return '';
}
