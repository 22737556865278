import {API_SERVERLESS, getFetch, getFile} from '~commonApi';
import {IBackOfficeForm, IResponseFormDatas} from '../../types';

export function apiFormDatas(formId: string): Promise<IResponseFormDatas> {
    return getFetch(`${API_SERVERLESS}/backOffice/v2/datas?formId=${formId}`, false).then((jsonResponse: any) => {
        if (jsonResponse) {
            if (jsonResponse.datas && jsonResponse.datas.countries) {
                jsonResponse.datas.ue_countries = jsonResponse.datas.countries.filter(
                    ({UEMEMBER}: any = {}) => parseInt(UEMEMBER) === 1
                );
            }
            return {
                ...jsonResponse,
                datas: {
                    ...jsonResponse.datas,
                    eo_countries: [
                        {
                            ID: 'ID',
                            LABEL: 'Countries depends on species selected by end user',
                        },
                    ],
                },
            };
        }
    });
}

export function apiBackOfficeFormWithQuestions(
    formId = '',
    type = 'TQ',
    language = 'EN'
): Promise<{form: IBackOfficeForm}> {
    return getFetch(`${API_SERVERLESS}/backOffice/v3/formWithQuestions/${language}/${type}/${formId}`, false);
}

export function apiCommonGetPDF({URL}: {URL: string}) {
    return getFile(`${API_SERVERLESS}/common/v3/getPDF?url=${encodeURIComponent(URL)}&pageNumbers=1`, true);
}
