import gqlPrettier from 'graphql-prettier';

export interface DObject {
    [key: string]: string | number;
}

export const searchVarietyReturnTypes = {
    DENOMINATION: 'denomination',
    SPECIES_LATIN_NAME: 'specielatinname',
    COUNTRIES: 'countryid',
    COUNTRY_NAME: 'countryname',
    DENOMINATION_NATURE: 'denominationnature',
    VARIETY_STATUS: 'varietystatus',
    VARIETY_STATUS_TEXT: 'applicationstatus',
    REGISTER_TYPE_NAME: 'registertypename',
    REGISTER_TYPE: 'publicationtype',
    BREEDERS_REFERENCE: 'breederreference',
    BREEDERS_NAME: 'breedername',
    SPECIES_CROP_SECTOR: 'cropsectors',
    OTHER_PARTIES: 'otherparty',
    APPLICATION_NUMBER: 'applicationnumber',
    APPLICATION_DATE: 'applicationdate',
    APPLICATION_PUB_DATE: 'pubapplicationdate',
    GRANT_REGISTRATION_NUMBER: 'grantnumber',
    GRANT_REGISTRATION_DATE: 'grantdate',
    GRANT_REGISTRATION_PUB_DATE: 'pubgrantdate',
    RENEWAL_DATE: 'renewalregistration',
    REGISTER: 'register',
    VARIETY_INSERTION_DATE: 'varietyinsertdate',
    DENOMINATION_INSERTION_DATE: 'denominationinsertdate',
    SPECIES_CLASS_CODE: 'specieclasscodes',
    SPECIES_CLASS_NAME: 'specieclasses',
    DENOMINATION_STATUS: 'denominationstatus',
    EU_MEMBER: 'iseumember',
    DENOMINATION_SYNONYM: 'registeredwithother',
    PUBLICATION_EXTRA_ID: 'publicationextraid',
    VARIETY_ID: 'varietyid',
    APPLICANT: 'applicantname',
    HOLDER: 'holdername',
    MAINTAINER: 'maintainername',
    EXPIRATION_ACTUAL: 'enddate',
    FUTURE_EXPIRATION_DATE: 'futureexpirationdate',
    DENOMINATION_TYPE: 'denominationtype',
    DENOMINATION_PROPOSAL_DATE: 'proposaldate',
    DENOMINATION_APPROVAL_DATE: 'approvaldate',
    DENOMINATION_PUBLISHED_DATE: 'pubproposaldate',
    REMARKS: 'remark',
    SPECIES_CODE: 'speciesid',
    DENOMINATION_END_DATE: 'deno_end_date',
    SPECIES_NAME_EN: 'species_name_en',
    EXTRA_DATA_URL: 'extra_data_url',
    REGISTER_NAME: 'register_name',
    /* UNUSED-UNKNOWN RETURN TYPES
    COMMON_IDENTIFIER: 'upovcommonidentifier',
    PUBLICATION_PROPOSAL_DATE: 'pubproposaldate',
    EU_MEMBER: 'eu_member',
    DENOMINATION_SYNONYM: 'deno_synonym',
    DENOMINATION_ID: 'denominationid',
    RENEWAL_REGISTRATION_DATE: 'renewalregistration',
    TRADEMARK: 'tradename',
    */
};

const escapeValue = (txt: string) => {
    return txt.replace('.', '\\.');
};

const getValueFromFormControl = (
    value: any,
    valueSelect: string,
    elasticField: string,
    elasticFieldSearchType: string,
    regexp: RegExp | null = null
) => {
    if (value && value.length > 0) {
        const val = regexp ? value.replace(regexp, '') : value;
        const searchType = elasticFieldSearchType ? `${elasticFieldSearchType}:"${valueSelect}"` : '';
        return `${elasticField}:"${val.toLowerCase()}" ${searchType}`;
    } else {
        return '';
    }
};

const getValueFromFormControlObj = (
    value: any,
    valueSelect: string,
    elasticField: string,
    elasticFieldSearchType: string,
    regexp: RegExp | null = null,
    forceLowercase = true
) => {
    const output: DObject[] = [];
    let obj: DObject = {};
    if (value && value.length > 0) {
        const newValue = forceLowercase ? value.toLowerCase() : value;
        obj[elasticField] = regexp ? newValue.replace(regexp, '') : newValue;
        output.push(obj);
        obj = {};
        if (valueSelect) {
            obj[elasticFieldSearchType] = valueSelect;
            output.push(obj);
        }
    }
    return output;
};

const getMultiValueFromTypeAhead = (
    value: any,
    valueSelect: string,
    elasticField: string,
    elasticFieldSearchType: string
) => {
    return value && value.length > 0
        ? `${elasticField}:${JSON.stringify(
              value.map((el: any) => el.value.toLowerCase())
          )} ${elasticFieldSearchType}:"${valueSelect}"`
        : '';
};

const getMultiValueFromTypeAheadObj = (
    value: any,
    valueSelect: string,
    elasticField: string,
    elasticFieldSearchType: string
) => {
    const output: DObject[] = [];
    let obj: DObject = {};
    if (value && value.length > 0) {
        obj[elasticField] = value.map((el: any) => el.value.toLowerCase());
        output.push(obj);
        obj = {};
        obj[elasticFieldSearchType] = valueSelect;
        output.push(obj);
    }
    return output;
};

const getValueFromMultiselect = (value: any, elasticField: string) => {
    return value && value.length > 0
        ? `${elasticField}:${JSON.stringify(
              value.map((el: any) => {
                  if (typeof el.value === 'number') {
                      return el.value;
                  }
                  try {
                      const parsedValue = JSON.parse(el.value);
                      return parsedValue.countryid.toLowerCase();
                  } catch {
                      return el.value.toLowerCase();
                  }
              })
          )}`
        : '';
};

const getValueFromMultiselectObj = (value: any, elasticField: string) => {
    const output: DObject[] = [];
    const obj: DObject = {};
    if (value && value.length > 0) {
        obj[elasticField] = value.map((el: any) => {
            if (typeof el.value === 'number') {
                return el.value;
            }
            try {
                const parsedValue = JSON.parse(el.value);
                return parsedValue.countryid.toLowerCase();
            } catch {
                return el.value.toLowerCase();
            }
        });
        output.push(obj);
    }
    return output;
};

const getSingleValueFromTypeAhead = (
    value: any,
    valueSelect: string,
    elasticField: string,
    elasticFieldSearchType: string
) => {
    return value && value.length > 0
        ? `${elasticField}:"${value.toLowerCase()}" ${elasticFieldSearchType}:"${valueSelect}"`
        : '';
};

const getSingleValueFromTypeAheadObj = (
    value: any,
    valueSelect: string,
    elasticField: string,
    elasticFieldSearchType: string,
    valueField = '',
    forceLowercase = true
) => {
    const output: DObject[] = [];
    let obj: DObject = {};
    if (value && value.length > 0) {
        obj[elasticField] = forceLowercase ? value.toLowerCase() : value;
        output.push(obj);
        obj = {};
        if (valueSelect) {
            obj[elasticFieldSearchType] = valueSelect;
            output.push(obj);
        }
    }
    return output;
};

export const selectOptions = {
    CONTAINS: 'contains',
    STARTS: 'starts',
    EXACT: 'exact',
    ENDS: 'ends',
};

const getValueFromDate = (date: any, elasticField: string) => {
    let output = '';
    if (date && date.length > 0) {
        date.forEach(function (el: any) {
            const elasticFieldType = el.type ? el.type.charAt(0).toUpperCase() + el.type.slice(1) : '';
            output += el.value ? ` ${elasticField}${elasticFieldType}:${el.value}` : '';
        });
    }

    return output;
};

const getValueFromDateObj = (date: any, elasticField: string) => {
    const output: DObject[] = [];
    let obj: DObject = {};
    if (date && date.length > 0) {
        date.forEach(function (el: any) {
            if (el.value) {
                const elasticFieldType = el.type ? el.type.charAt(0).toUpperCase() + el.type.slice(1) : '';
                obj[elasticField + elasticFieldType] = parseInt(el.value);
                output.push(obj);
                obj = {};
            }
        });
    }
    return output;
};

const createSearchParams = (values: any) => {
    const denomination = getValueFromFormControl(
        values.denomination,
        values.denominationSelect,
        'denomination',
        'denominationSearchType'
    );
    const registeredWithOther = ['0', '1'].includes(values.registeredWithOther)
        ? `registeredWithOther: ${values.registeredWithOther}`
        : '';
    const speciesLatinName = getMultiValueFromTypeAhead(
        values.speciesLatinName,
        values.speciesLatinNameSelect,
        'speciesLatinName',
        'speciesLatinNameSearchType'
    );
    const includeSynonyms = speciesLatinName.length > 0 && values.includeSynonyms ? `includeSynonyms:"1"` : '';
    const countries = getValueFromMultiselect(values.countries, 'countryid');
    const denominationNature = getValueFromMultiselect(values.denominationNature, 'denominationNature');
    const varietyStatusParams = getValueFromMultiselect(values.varietyStatus, 'varietyStatus');
    const registerType = getValueFromMultiselect(values.registerType, 'registerType');
    const breedersReference = getValueFromFormControl(
        values.breedersReference,
        values.breedersReferenceSelect,
        'breedersReference',
        'breedersReferenceSearchType',
        /[ -/]/g
    );
    const breedersName = getSingleValueFromTypeAhead(
        values.breedersName,
        values.breedersNameSelect,
        'breederName',
        'breederNameSearchType'
    );

    const parties = getSingleValueFromTypeAhead(
        values.parties,
        values.partiesSelect,
        'otherParty',
        'otherPartySearchType'
    );
    const applicationNumber = getSingleValueFromTypeAhead(
        values.applicationNumber,
        values.applicationNumberSelect,
        'applicationNumber',
        'applicationNumberSearchType'
    );
    const grantRegistrationNumber = getSingleValueFromTypeAhead(
        values.grantRegistrationNumber,
        values.grantRegistrationNumberSelect,
        'grantNumber',
        'grantNumberSearchType'
    );
    const speciesClassCode =
        values.speciesClassCodeSelect === selectOptions.EXACT
            ? getMultiValueFromTypeAhead(
                  values.speciesClassCode,
                  values.speciesClassCodeSelect,
                  'speciesClassCode',
                  'speciesClassCodeSearchType'
              )
            : getValueFromFormControl(
                  values.speciesClassCode,
                  values.speciesClassCodeSelect,
                  'speciesClassCode',
                  'speciesClassCodeSearchType'
              );
    const speciesClassName =
        values.speciesClassNameSelect === selectOptions.EXACT
            ? getMultiValueFromTypeAhead(
                  values.speciesClassName,
                  values.speciesClassNameSelect,
                  'speciesClass',
                  'speciesClassSearchType'
              )
            : getValueFromFormControl(
                  values.speciesClassName,
                  values.speciesClassNameSelect,
                  'speciesClass',
                  'speciesClassSearchType'
              );
    const applicationDate = getValueFromDate(values.applicationDate, 'applicationDate');
    const applicationPubDate = getValueFromDate(values.applicationPubDate, 'applicationPublicationDate');
    const grantRegistrationDate = getValueFromDate(values.grantRegistrationDate, 'grandRegistrationDate');
    const grantRegistrationPubDate = getValueFromDate(
        values.grantRegistrationPubDate,
        'publicationgrandRegistrationDate'
    );
    const renewalDate = getValueFromDate(values.renewalDate, 'renewalregistrationDate');
    const varietyInsertionDate = getValueFromDate(values.varietyInsertionDate, 'varietyInsertDate');
    const denominationInsertionDate = getValueFromDate(values.denominationInsertionDate, 'denominationInsertDate');
    const speciesCropSector = getValueFromMultiselect(values.speciesCropSector, 'cropsectors');
    const denominationStatus = getValueFromMultiselect(values.denominationStatus, 'denominationStatus');
    const registerName = getSingleValueFromTypeAhead(
        values.registerName,
        values.registerNameSelect,
        'registerName',
        'registerNameSearchType'
    );

    return `
        ${denomination}
        ${registeredWithOther}
        ${includeSynonyms}
        ${speciesLatinName}
        ${countries}
        ${denominationNature}
        ${varietyStatusParams}
        ${registerType}
        ${breedersReference}
        ${breedersName}
        ${parties}
        ${applicationNumber}
        ${applicationDate}
        ${applicationPubDate}
        ${grantRegistrationNumber}
        ${grantRegistrationDate}
        ${grantRegistrationPubDate}
        ${renewalDate}
        ${varietyInsertionDate}
        ${denominationInsertionDate}
        ${speciesClassCode}
        ${speciesClassName}
        ${denominationStatus}
        ${speciesCropSector}
        ${registerName}
  `;
};
const createSearchParamsObj = (values: any) => {
    const denomination = getValueFromFormControlObj(
        values.denomination,
        values.denominationSelect,
        'denomination',
        'denominationSearchType'
    );
    const speciesLatinName = getMultiValueFromTypeAheadObj(
        values.speciesLatinName,
        values.speciesLatinNameSelect,
        'speciesLatinName',
        'speciesLatinNameSearchType'
    );
    const includeSynonyms = speciesLatinName.length > 0 && values.includeSynonyms ? [{includeSynonyms: '1'}] : [];
    const registeredWithOther = ['0', '1'].includes(values.registeredWithOther)
        ? [{registeredWithOther: parseInt(values.registeredWithOther)}]
        : [];
    const countries = getValueFromMultiselectObj(values.countries, 'countryid');
    const denominationNature = getValueFromMultiselectObj(values.denominationNature, 'denominationNature');
    const varietyStatusObj = getValueFromMultiselectObj(values.varietyStatus, 'varietyStatus');
    const registerType = getValueFromMultiselectObj(values.registerType, 'registerType');
    const breedersReference = getValueFromFormControlObj(
        escapeValue(values.breedersReference),
        values.breedersReferenceSelect,
        'breedersReference',
        'breedersReferenceSearchType',
        /[ -/]/g
    );
    const breedersName = getSingleValueFromTypeAheadObj(
        values.breedersName,
        values.breedersNameSelect,
        'breederName',
        'breederNameSearchType'
    );
    const parties = getSingleValueFromTypeAheadObj(
        values.parties,
        values.partiesSelect,
        'otherParty',
        'otherPartySearchType'
    );
    const applicationNumber = getSingleValueFromTypeAheadObj(
        values.applicationNumber,
        values.applicationNumberSelect,
        'applicationNumber',
        'applicationNumberSearchType'
    );
    const grantRegistrationNumber = getSingleValueFromTypeAheadObj(
        values.grantRegistrationNumber,
        values.grantRegistrationNumberSelect,
        'grantNumber',
        'grantNumberSearchType'
    );
    const speciesClassCode =
        values.speciesClassCodeSelect === selectOptions.EXACT
            ? getMultiValueFromTypeAheadObj(
                  values.speciesClassCode,
                  values.speciesClassCodeSelect,
                  'speciesClassCode',
                  'speciesClassCodeSearchType'
              )
            : getValueFromFormControlObj(
                  values.speciesClassCode,
                  values.speciesClassCodeSelect,
                  'speciesClassCode',
                  'speciesClassCodeSearchType'
              );
    const speciesClassName =
        values.speciesClassNameSelect === selectOptions.EXACT
            ? getMultiValueFromTypeAheadObj(
                  values.speciesClassName,
                  values.speciesClassNameSelect,
                  'speciesClass',
                  'speciesClassSearchType'
              )
            : getValueFromFormControlObj(
                  values.speciesClassName,
                  values.speciesClassNameSelect,
                  'speciesClass',
                  'speciesClassSearchType'
              );
    const applicationDate = getValueFromDateObj(values.applicationDate, 'applicationDate');
    const applicationPubDate = getValueFromDateObj(values.applicationPubDate, 'applicationPublicationDate');
    const grantRegistrationDate = getValueFromDateObj(values.grantRegistrationDate, 'grandRegistrationDate');
    const grantRegistrationPubDate = getValueFromDateObj(
        values.grantRegistrationPubDate,
        'publicationgrandRegistrationDate'
    );
    const renewalDate = getValueFromDateObj(values.renewalDate, 'renewalregistrationDate');
    const varietyInsertionDate = getValueFromDateObj(values.varietyInsertionDate, 'varietyInsertDate');
    const denominationInsertionDate = getValueFromDateObj(values.denominationInsertionDate, 'denominationInsertDate');
    const speciesCropSector = getValueFromMultiselectObj(values.speciesCropSector, 'cropsectors');
    const denominationStatus = getValueFromMultiselectObj(values.denominationStatus, 'denominationStatus');
    const registerName = getSingleValueFromTypeAheadObj(
        values.registerName,
        values.registerNameSelect,
        'register_name',
        'registerNameSearchType'
    );

    return [
        ...denomination,
        ...registeredWithOther,
        ...speciesLatinName,
        ...includeSynonyms,
        ...countries,
        ...denominationNature,
        ...varietyStatusObj,
        ...registerType,
        ...breedersReference,
        ...breedersName,
        ...parties,
        ...applicationNumber,
        ...applicationDate,
        ...applicationPubDate,
        ...grantRegistrationNumber,
        ...grantRegistrationDate,
        ...grantRegistrationPubDate,
        ...renewalDate,
        ...varietyInsertionDate,
        ...denominationInsertionDate,
        ...speciesClassCode,
        ...speciesClassName,
        ...denominationStatus,
        ...speciesCropSector,
        ...registerName,
    ];
};

export const getSearchQuery = (
    values: any,
    pageSize: number,
    pageIndex: number,
    sortBy: {id: string; desc: boolean}[],
    columns: any[],
    commercial: boolean = false
) => {
    const searchParams = createSearchParams(values);
    const sorting =
        sortBy && sortBy.length
            ? `sortingField:"${sortBy[0].id}" sortingDirection:"${sortBy[0].desc ? 'desc' : 'asc'}"`
            : '';
    const columnsData = columns.filter((val: any) => val.enabled).map((val: any) => val.name);

    columnsData.includes('specielatinname') && columnsData.push('speciesid');
    columnsData.includes('specieclasscodes') && columnsData.push('specieclasses');
    columnsData.includes('countryid') && columnsData.push('countryname');
    columnsData.includes('publicationtype') && columnsData.push('registertypename');

    const mandatoryColumns = [
        searchVarietyReturnTypes.DENOMINATION,
        searchVarietyReturnTypes.COUNTRIES,
        searchVarietyReturnTypes.REGISTER_TYPE,
        searchVarietyReturnTypes.APPLICATION_NUMBER,
        searchVarietyReturnTypes.PUBLICATION_EXTRA_ID,
        searchVarietyReturnTypes.VARIETY_ID,
    ].filter((val: string) => !columnsData.includes(val));

    const searchQuery = `
  {
    ${commercial ? 'searchVarietyCom' : 'searchVariety'}(
      size:${pageSize}
      from:${pageSize * pageIndex}
      ${searchParams}
      ${sorting}
    ){
      data {
        ${searchVarietyReturnTypes.DENOMINATION_SYNONYM},
        ${searchVarietyReturnTypes.REGISTER},
        ${columnsData.concat(mandatoryColumns).join(',')}
      }
      total
    }
  }`;

    return {
        query: gqlPrettier(searchQuery),
    };
};

export const getExportNoteQuery = (values: any) => {
    const searchParams: any = createSearchParamsObj(values);

    const output = {
        searchQuery: {
            fields: searchParams,
            terms: ['key', 'doc_count'],
        },
    };

    return JSON.stringify(output);
};
