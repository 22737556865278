export interface DObject {
    [key: string]: string | number;
}

const getValueFromFormControlSelectObj = (value: any, elasticField: string) => {
    const obj: DObject = {};
    if (value) {
        obj[elasticField] = value.toLowerCase();
    }
    return obj;
};

const getValueFromFormControlObj = (
    value: any,
    valueSelect: string,
    elasticField: string,
    elasticFieldSearchType: string,
    regexp: RegExp | null = null,
    forceLowercase = true
) => {
    const output: DObject[] = [];
    let obj: DObject = {};
    if (value && value.length > 0) {
        const newValue = forceLowercase ? value.toLowerCase() : value;
        obj[elasticField] = regexp ? newValue.replace(regexp, '') : newValue;
        output.push(obj);
        obj = {};
        if (valueSelect) {
            obj[elasticFieldSearchType] = valueSelect;
            output.push(obj);
        }
    }
    return output;
};

export const createSubscriptionFromValues = (values: any, sendQuery = true) => {
    const country = getValueFromFormControlSelectObj(values.countryid, 'country_id');
    const filename = getValueFromFormControlObj(values.filename, '', 'file_name', '');

    return Object.assign({}, country, ...filename, {comment: values.comment});
};
