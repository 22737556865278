import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ModalCustomVersion2} from '../../../commonModals';
import ModalUsedInVersion2, {rowType} from '../../../commonModals/ModalUsedInVersion2';
import ResponseModalDetailsTabs, {RESPONSE_MODAL_DETAILS_TABS} from './ResponseModalDetailsTabs';
import ResponseModalDetailsGeneralInformation from './ResponseModalDetailsGeneralInformation';
import ResponseModalDetailsLogics, {LOGIC_TYPES} from './ResponseModalDetailsLogics';
import StatusField from '~shared/StatusField';
import {faLightbulb} from '@fortawesome/free-solid-svg-icons';
import {injectIntl} from 'react-intl';
import {IGroup, IGroupQuestion, IGroupQuestionResponse, IScreenData} from '../../../types';

interface IProps {
    afterCreatingNewResponse?: () => any;
    activeResponseId: number;
    close: () => any;
    formId: number | null;
    formOrTemplateOfficeId?: number;
    intl: any;
    group: IGroup;
    loadFormQuestionsJSONs?: any;
    mainScreen?: string;
    mock: any;
    modalQueuePush: any;
    modalQueuePop: any;
    question: IGroupQuestion;
    questionId: number;
    response?: IGroupQuestionResponse;
    screenData?: IScreenData;
    steps: IGroup[];
    templateId?: number | null;
    updateScreenData?: (screenData: Partial<IScreenData>, callback?: any) => any;
}

interface IState {
    currentTab: string;
    modalUsedIn: number | null;
    responseStatus: number | null;
    footer: any;
}

class ResponseModalDetails extends React.Component<IProps, IState> {
    closeHandler: any;

    constructor(props: IProps) {
        super(props);
        this.state = {
            currentTab:
                Object.values(LOGIC_TYPES).indexOf(props.mock) !== -1
                    ? RESPONSE_MODAL_DETAILS_TABS.LOGICS
                    : RESPONSE_MODAL_DETAILS_TABS.GENERAL_INFORMATION,
            modalUsedIn: null,
            responseStatus: null,
            footer: null,
        };
    }

    setCloseHandler = (handler: any) => (this.closeHandler = handler);

    close = (force: any) => {
        if (force) {
            this.props.close();
        } else if (this.closeHandler && this.closeHandler()) {
            this.props.close();
        }
    };

    showModalUsedIn = () => this.setState({modalUsedIn: this.props.activeResponseId});

    closeModalUsedIn = () => this.setState({modalUsedIn: null});

    setResponseModalDetailsStatus = (status: any) => this.setState({responseStatus: status});

    setRenderFooter = (footer: any) => this.setState({footer});

    onTabChange = (currentTab: string) => (_: any) => this.setState({currentTab});

    render() {
        const isNewResponseOperation = this.props.activeResponseId === 0;
        return (
            <>
                <ModalCustomVersion2
                    close={this.close}
                    header={
                        <>
                            {isNewResponseOperation ? 'New Response' : 'Response'}
                            {!isNewResponseOperation && (
                                <div
                                    style={{
                                        fontSize: 22,
                                        marginLeft: 10,
                                        border: '1px solid green',
                                        borderRadius: '50%',
                                        width: 26,
                                        height: 26,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: 2,
                                        cursor: 'pointer',
                                    }}
                                    onClick={this.showModalUsedIn}
                                >
                                    <FontAwesomeIcon icon={faLightbulb as any} color="green" />
                                </div>
                            )}
                            {this.state.responseStatus && (
                                <div style={{marginLeft: 20}}>
                                    <StatusField intl={this.props.intl} statusId={this.state.responseStatus} />
                                </div>
                            )}
                            <div style={{clear: 'both'}} />
                        </>
                    }
                    body={
                        <>
                            <ResponseModalDetailsTabs
                                currentTab={this.state.currentTab}
                                onChange={this.onTabChange}
                                responseId={this.props.activeResponseId}
                            />
                            <div>
                                {this.state.currentTab === RESPONSE_MODAL_DETAILS_TABS.GENERAL_INFORMATION && (
                                    <ResponseModalDetailsGeneralInformation
                                        afterCreatingNewResponse={this.props.afterCreatingNewResponse}
                                        close={this.close}
                                        formId={this.props.formId}
                                        formOrTemplateOfficeId={this.props.formOrTemplateOfficeId}
                                        group={this.props.group}
                                        question={this.props.question}
                                        response={this.props.response ? this.props.response : {status: 1}}
                                        responseId={this.props.activeResponseId}
                                        steps={this.props.steps}
                                        setCloseHandler={this.setCloseHandler}
                                        setResponseModalDetailsStatus={this.setResponseModalDetailsStatus}
                                        loadFormQuestionsJSONs={this.props.loadFormQuestionsJSONs}
                                        mainScreen={this.props.mainScreen}
                                        modalQueuePush={this.props.modalQueuePush}
                                        modalQueuePop={this.props.modalQueuePop}
                                        templateId={this.props.templateId}
                                        setRenderFooter={this.setRenderFooter}
                                    />
                                )}
                                {this.state.currentTab === RESPONSE_MODAL_DETAILS_TABS.LOGICS && (
                                    <ResponseModalDetailsLogics
                                        formId={this.props.formId}
                                        loadFormQuestionsJSONs={this.props.loadFormQuestionsJSONs}
                                        mainScreen={this.props.mainScreen}
                                        mock={this.props.mock}
                                        modalQueuePush={this.props.modalQueuePush}
                                        modalQueuePop={this.props.modalQueuePop}
                                        questionId={this.props.questionId}
                                        response={this.props.response}
                                        responseId={this.props.activeResponseId}
                                        screenData={this.props.screenData}
                                        steps={this.props.steps}
                                        templateId={this.props.templateId}
                                        updateScreenData={this.props.updateScreenData}
                                        setRenderFooter={this.setRenderFooter}
                                    />
                                )}
                            </div>
                        </>
                    }
                    footer={<>{this.state.footer}</>}
                />
                {this.state.modalUsedIn !== null ? (
                    <ModalUsedInVersion2
                        elementId={this.state.modalUsedIn}
                        intl={this.props.intl}
                        type={rowType.RESPONSE}
                        close={this.closeModalUsedIn}
                    />
                ) : null}
            </>
        );
    }
}

export default injectIntl(ResponseModalDetails);
