import React from 'react';
import InputContainer from '~componentsForm/InputContainer';
import {htmlToText} from '~utils';
import styles from './RadioButtonSingle.module.scss';
import Remark from './Remark';
import cx from 'classnames';

export default function RadioButtonSingle({
    name,
    value,
    checked,
    clickEvent,
    className,
    disabled,
    double,
    triple,
    popOverText,
    onChange,
    label,
    remark,
    isRemarkTranslationMissing,
    oldBackOfficeStyles,
    isTranslationMissing,

    isShortDisplay,
    children,
    responseLabelShouldTakeTheWholeLineAndNotWrap,
}) {
    return (
        <InputContainer {...{double, triple, oldBackOfficeStyles, isShortDisplay}}>
            <React.Fragment key={value}>
                <div
                    className={cx(
                        className,
                        responseLabelShouldTakeTheWholeLineAndNotWrap ? styles.radioWrapWithFullWidthLabel : null
                    )}
                >
                    <label className={styles.singleRadioLabel}>
                        <input
                            id={name}
                            onChange={onChange}
                            type="radio"
                            value={value}
                            name={name}
                            disabled={disabled}
                            style={{cursor: 'pointer'}}
                            checked={checked}
                        />
                        <div
                            style={{display: 'inline', cursor: 'pointer'}}
                            className={cx(
                                isTranslationMissing && styles.translationMissing,
                                disabled && styles.isDisabled
                            )}
                        >
                            {htmlToText(label)}
                        </div>
                    </label>
                    <Remark
                        remark={remark}
                        extraStyles={{maxWidth: 'calc(100% - 300px)'}}
                        isRemarkTranslationMissing={isRemarkTranslationMissing}
                    />
                    {children}
                </div>
                <div style={{clear: 'both'}} />
            </React.Fragment>
        </InputContainer>
    );
}
