import React from 'react';
import CustomTable from '~components/CustomTable';
import {ModalCustomVersion2} from '../../../commonModals';
import {LoadingBar} from '../../../componentsLayout';
import {Button} from '../../../componentsFormV2';
import {
    apiBackOfficeTemplatesStatusActive,
    apiBackOfficeUseTemplateInsideForm,
    IResponseTemplate,
} from './QuestionModalUseTemplateService';

interface IStateTemplate {
    templateId: number;
    message: string;
    comment: string | null;
    order: string;
}

interface IProps {
    close: any;
    formId: number;
    intl: any;
    loadFormQuestionsJSONs: any;
}

interface IState {
    loading: boolean;
    screenLoaded: boolean;
    templates: IStateTemplate[];
    timestamp: number;
}

export default class QuestionModalUseTemplate extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            screenLoaded: false,
            templates: [],
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        this.loadJSONs();
    }

    loadJSONs = () =>
        this.setState({loading: true}, () =>
            apiBackOfficeTemplatesStatusActive()
                .then(jsonResponse => {
                    if (jsonResponse) {
                        jsonResponse.data &&
                        jsonResponse.data.templates &&
                        this.setState({
                            screenLoaded: true,
                            templates: prepareTableData(jsonResponse.data.templates),
                            timestamp: Date.now(),
                        });
                    }
                })
                .catch((error: any) => {
                    ERROR([`QuestionModalUseTemplate: ${error.message}`]);
                })
                .then(() => this.setState({loading: false}))
        );

    close = () => this.props.close && this.props.close();

    onUseTemplateInsideFormRowClick = (templateId: number) => {
        const JSONRequest = {
            formId: this.props.formId,
            templateId,
        };
        this.setState({loading: true}, () => {
            apiBackOfficeUseTemplateInsideForm(JSONRequest)
                .then((jsonResponse: any) => {
                    this.setState({loading: false}, () => {
                        if (jsonResponse) {
                            this.props.loadFormQuestionsJSONs &&
                            this.props.loadFormQuestionsJSONs(null, null, null, true, {templateId});
                            this.close();
                        }
                    });
                })
                .catch((error: any) => LOG([`QuestionModalUseTemplate ${error}`]));
        });
    };

    render() {
        return (
            <>
                <ModalCustomVersion2
                    close={this.close}
                    header={`Select a template`}
                    body={
                        <div>
                            {this.state.screenLoaded ? (
                                <CustomTable
                                    count={(this.state.templates || []).length}
                                    hideExcelButton={true}
                                    id={'templateId'}
                                    intl={this.props.intl}
                                    resultFieldsDefault={['order', 'templateId', 'message', 'comment']}
                                    rowClick={this.onUseTemplateInsideFormRowClick}
                                    tableName={'useTemplate'}
                                    tableSource={this.state.templates || []}
                                    tableType={'OBJECT'}
                                    timestamp={this.state.timestamp}
                                    version={2}
                                />
                            ) : null}
                        </div>
                    }
                    footer={
                        <>
                            {this.state.loading ? (
                                <LoadingBar />
                            ) : (
                                <>
                                    <Button
                                        icon={'close'}
                                        clickAction={this.close}
                                        variation={'danger'}
                                    >{`Close`}</Button>
                                </>
                            )}
                        </>
                    }
                />
            </>
        );
    }
}

function prepareTableData(templates: IResponseTemplate[]) {
    return templates.map(el => {
        const {templateId, nameLabelValueHTML, templateComment, displayOrder} = el;
        return {
            templateId,
            message: nameLabelValueHTML,
            comment: templateComment,
            order: displayOrder,
        };
    });
}
