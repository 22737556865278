import React from 'react';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import {Button} from '../../componentsFormV2';
import getIcon from '~utils/icons';
import {saveAs} from 'file-saver';
import CustomTable from '~components/CustomTable';
import {injectIntl} from 'react-intl';
import CryptoJS from 'crypto-js';
import CCModalInternalReplyActionButtons from './CCModalInternalReplyActionButtons';
import {
    apiCommunicationCentreModalInternalReplyCCList,
    apiCommunicationCentreModalInternalReplyMessageRead,
    apiCommunicationCentreModalInternalReplyMessageReply,
} from './CCModalInternalReplyService';
import _PROFILES_DIRECTORIES_SUBTYPES from '../CommunicationCentreInternal/modals/DATA/PROFILES_DIRECTORIES_SUBTYPES.json';
import styles from './CCModalInternalReply.module.scss';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import TextLabelInput from '~components/TextLabelInput';
import {getDecodedJWT, isRegisterIdValid, sanitize, customBTOA} from '~utils';
import Info from '~components/Info';
import {
    getUserRoleName,
    USER_SHORTKEY_TO_CONTACTID,
} from '../CommunicationCentreInternal/CommunicationCentreInternal.utils';
import jwtDecode from 'jwt-decode';
import InputLink from '~components/InputLink';
import ModalApplicationList from '../RequestCertifiedCopies/modals/ModalApplicationList';
import InternalMessageFieldsAndAttachments from '../CommunicationCentreInternal/modals/InternalMessageFieldsAndAttachments';
import {CC_MAX_MESSAGE_BODY_LENGTH, MAX_UPLOAD_FILE_SIZE} from '../../utils/constants';
import {
    ICommunicationAttachmentResponse,
    ICommunicationMessageInternalRead,
    ICommunicationMessageInternalSubmitJSONRequest,
    IDecodedJWT,
    IDocument,
    TProfilesDirectoriesSubtype,
} from '../../types';
import {ModalApplicationNumbers, ModalCustomVersion3} from '../../commonModals';
import {Editor} from '@tinymce/tinymce-react';
import {tinyConfig, tinyConfigFullHeight} from '../CommunicationCentreInternal/CommunicationCentreInternal.config';
import {Error} from '../../componentsLayout';
import Checkbox from '~components/Checkbox';

const errorMessages = {
    UNKNOWN: `This is not a valid application number. The value must be in range 19950001 to ${new Date().getFullYear()}9999!`,
    FORBIDDEN: 'This application is not linked to your account',
    DUPLICATE: `Application number already in the list`,
    NOT_RECEIPT: 'The reception of your application has not been finalised yet.',
    ADD: 'Please include the application number using the button + or "Enter" keyboard button.',
};

const SELECT_LIST_ITEM_ID_NOT_SELECTED = 'none';

const SELECT_LIST_VALUE = {
    DOCMAN_PROFILE_NOT_SELECTED: 'Please select a profile',
    DOCMAN_DIRECTORY_NOT_SELECTED: 'Please select a directory',
    DOCMAN_SUBTYPE_NOT_SELECTED: 'Please select a subtype',
};

const SELECT_LIST_ALL_DOCMAN_PROFILES = [
    {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_PROFILE_NOT_SELECTED},
    {id: 'ACCOUN', value: 'Accountancy'},
    {id: 'BOADOC', value: 'Board of appeal'},
    {id: 'GREEND', value: 'Green Files'},
    {id: 'HR', value: 'Human Resources'},
    {id: 'LEGALD', value: 'Legal'},
    {id: 'PRESIF', value: 'Presidency'},
    {id: 'PURCHA', value: 'Purchase'},
    {id: 'QAUDIT', value: 'Quality Audit'},
    {id: 'TECHMA', value: 'Technical matters'},
    {id: 'THPDOC', value: 'Third parties'},
];

const arrayUniqueByKey = (array: any[], uniqueKey = 'id') => [
    ...new Map(array.map(item => [item[uniqueKey], item])).values(),
];

const getSelectListOfDocmanProfiles = (decodedJWT: IDecodedJWT): {id: string; value: string}[] => {
    let {id: contactId} = decodedJWT || {};
    let selectList = [
        {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_PROFILE_NOT_SELECTED},
        ...SELECT_LIST_ALL_DOCMAN_PROFILES.filter(el => Object.keys(PROFILES_DIRECTORIES_SUBTYPES).includes(el.id)),
    ];
    if (parseInt(contactId) === USER_SHORTKEY_TO_CONTACTID.FB) {
        selectList = selectList.filter(listOption => listOption.id === 'BOADOC');
    } else {
        selectList = selectList.filter(listOption => listOption.id === 'GREEND');
    }
    return selectList;
};

interface IProps {
    close: () => any;
    communicationId: number;
    intl: any;
    isPreviewMode?: boolean;
}

type TApplicationNumberError = typeof errorMessages;

type TKeyApplicationNumberError = keyof TApplicationNumberError;

interface IState {
    CPVOColleaguesListByIdName: {ID: string; NAME: string}[];
    CPVOColleaguesNameHash: {[key: string]: number[]};
    applicationNumber: string;
    applicationNumberError: TKeyApplicationNumberError | null;
    attachments: any[];
    checkboxUrgentValue: boolean;
    documentList: IDocument[];
    error: string | null;
    isApplicationNumbersEditVisible: boolean;
    isPreviewMode: boolean;
    loading: number;
    message: ICommunicationMessageInternalRead | null;
    modalApplicationNumbers: string[];
    modalImportApplicationNumberList: boolean;
    selectListDocmanDirectories: {id: string | number; value: string}[];
    selectListDocmanDocumentSubtypes: {id: string | number; value: string}[];
    selectListDocmanProfiles: {id: string; value: string}[];
    selectValueCPVOTemplate: string;
    selectValueCPVOTemplateLanguage: string;
    selectValueDirectory: string;
    selectValueProfile: string;
    selectValueSubtypeOfDocument: string;
    selectedCPVOColleagues: {[key: string]: string};
    selectedApplicationNumbers: {[key: string]: string};
    textAreaBodyValue: string;
    textInputValueSubject: string;
    textLabelInputValueCPVOColleagues: string;
    timestamp: number;
}

const PROFILES_DIRECTORIES_SUBTYPES = _PROFILES_DIRECTORIES_SUBTYPES as TProfilesDirectoriesSubtype;

class CCModalInternalReply extends React.Component<IProps, IState> {
    decodedJWT: IDecodedJWT = sessionStorage.getItem('token-cc')
        ? jwtDecode(sessionStorage.getItem('token-cc'))
        : getDecodedJWT();
    ccList: {id: number; name: string; role: number}[] = [];
    originalAttachments: ICommunicationAttachmentResponse[] = [];
    originalMessage: ICommunicationMessageInternalRead | null = null;
    inputUploadRef: any = null;

    constructor(props: IProps) {
        super(props);
        this.inputUploadRef = React.createRef();
        this.state = {
            CPVOColleaguesListByIdName: [],
            CPVOColleaguesNameHash: {},
            applicationNumber: '',
            applicationNumberError: null,
            attachments: [],
            checkboxUrgentValue: false,
            documentList: [],
            error: null,
            isApplicationNumbersEditVisible: false,
            isPreviewMode: false,
            loading: 0,
            message: null,
            modalApplicationNumbers: [],
            modalImportApplicationNumberList: false,
            selectListDocmanDirectories: [
                {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_DIRECTORY_NOT_SELECTED},
                ...arrayUniqueByKey(
                    PROFILES_DIRECTORIES_SUBTYPES['GREEND'].map(el => ({
                        id: el.dirId,
                        value: el.dirName,
                    }))
                ),
            ],
            selectListDocmanDocumentSubtypes: [
                {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_SUBTYPE_NOT_SELECTED},
            ],
            selectListDocmanProfiles: getSelectListOfDocmanProfiles(this.decodedJWT),
            selectValueCPVOTemplate: '',
            selectValueCPVOTemplateLanguage: '',
            selectValueDirectory: SELECT_LIST_ITEM_ID_NOT_SELECTED,
            selectValueProfile: 'GREEND',
            selectValueSubtypeOfDocument: SELECT_LIST_ITEM_ID_NOT_SELECTED,
            selectedCPVOColleagues: {},
            selectedApplicationNumbers: {},
            textAreaBodyValue: '',
            textInputValueSubject: '',
            textLabelInputValueCPVOColleagues: '',
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        Promise.all([this.loadCommunicationCentreReadMessage(), this.loadCPVOColleagues()]).then();
        if (this.state.selectListDocmanProfiles?.length === 1) {
            this.onSelectInputProfileChange({target: {value: this.state.selectListDocmanProfiles[0].id}});
        }
    }

    loadCommunicationCentreReadMessage = () =>
        new Promise(resolve => {
            this.setState(
                prev => ({
                    loading: prev.loading + 1,
                }),
                () => {
                    apiCommunicationCentreModalInternalReplyMessageRead(this.props.communicationId).then(
                        (JSONResponse: {
                            files: ICommunicationAttachmentResponse[];
                            message: ICommunicationMessageInternalRead;
                        }) => {
                            const {files, message} = JSONResponse || {};
                            const {docmanDirectory} = message || {};
                            const selectedApplicationNumbers: {[key: string]: string} = {};
                            ((message && message.applicationNumbers) || []).forEach((applicationNumber: string) => {
                                selectedApplicationNumbers[applicationNumber] = applicationNumber;
                            });
                            let filesFiltered = [...(files || [])];
                            const fileIndexConfirmationOfReceipt = files.findIndex(
                                el => el.filename === 'ConfirmationOfReceipt.pdf'
                            );
                            fileIndexConfirmationOfReceipt !== -1 &&
                                filesFiltered.splice(fileIndexConfirmationOfReceipt, 1);
                            this.originalAttachments = filesFiltered;
                            this.originalMessage = {...message};
                            this.setState(
                                prev => ({
                                    loading: prev.loading - 1,
                                    message,
                                    selectedApplicationNumbers,
                                }),
                                () => {
                                    if (
                                        (this.state.selectListDocmanDirectories || [])
                                            .map(el => el.id)
                                            .includes(docmanDirectory)
                                    ) {
                                        this.onSelectInputDirectoryChange({target: {value: `${docmanDirectory}`}});
                                    }
                                    resolve(true);
                                }
                            );
                        }
                    );
                }
            );
        });

    loadCPVOColleagues = () =>
        new Promise((resolve, reject) => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiCommunicationCentreModalInternalReplyCCList()
                        .then((jsonResponse: {data: {id: number; name: string; role: number}[]}) => {
                            if (jsonResponse) {
                                this.ccList = jsonResponse.data;
                                const CPVOColleaguesNameHash: {[key: string]: number[]} = {};
                                (jsonResponse.data || []).forEach(colleagueElement => {
                                    const {id, name, role} = colleagueElement || {};
                                    let nameWithRole = `${name}${getUserRoleName(role, this.decodedJWT)}`;
                                    if (!CPVOColleaguesNameHash[nameWithRole]) {
                                        CPVOColleaguesNameHash[nameWithRole] = [];
                                    }
                                    CPVOColleaguesNameHash[nameWithRole].push(id);
                                });
                                const CPVOColleaguesListByIdName: {ID: string; NAME: string}[] = Object.keys(
                                    CPVOColleaguesNameHash
                                ).map(colleagueName => ({
                                    ID: CPVOColleaguesNameHash[colleagueName].join(','),
                                    NAME: colleagueName,
                                }));
                                this.setState({
                                    CPVOColleaguesListByIdName,
                                    CPVOColleaguesNameHash,
                                });
                            }
                        })
                        .catch((error: any) => {
                            ERROR([`ModalCommunicationCenterCompose: ${error.message}`]);
                            reject(error && error.message);
                        })
                        .then(() =>
                            this.setState(
                                prev => ({loading: prev.loading - 1}),
                                () => resolve(true)
                            )
                        );
                }
            );
        });

    checkApplicationNumber = (applicationNumber: string) => {
        let applicationNumberValue = applicationNumber;
        if (!isNaN(Number(applicationNumberValue))) {
            applicationNumberValue = applicationNumberValue.substring(0, 8);
            this.setState({
                applicationNumber: applicationNumberValue,
                applicationNumberError: isRegisterIdValid(applicationNumberValue)
                    ? applicationNumberValue.length === 8
                        ? 'ADD'
                        : null
                    : applicationNumber !== ''
                    ? 'UNKNOWN'
                    : null,
            });
        }
    };

    deleteFile = (index: any) => {
        this.setState(prev => ({
            documentList: prev.documentList.filter(i => i.index !== index),
            timestamp: Date.now(),
        }));
    };

    checkFile = (file: File, event: React.ChangeEvent<HTMLInputElement>) => {
        LOG([`file.type:${file.type}`]);
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType?.toLowerCase();
        const filename = sanitize(file.name);
        this.setState({error: null}, () => {
            if (
                fileTypeLowercase === 'jpeg' ||
                fileTypeLowercase === 'png' ||
                fileTypeLowercase === 'jpg' ||
                fileTypeLowercase === 'pdf'
            ) {
                if (file.size > MAX_UPLOAD_FILE_SIZE) {
                    this.setState({
                        error: 'The maximum document size for uploads is 10 MB. Please select a proper file.',
                    });
                } else {
                    const a = new FileReader();
                    a.readAsBinaryString(file);
                    a.onloadend = () => {
                        const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(a.result));
                        this.setState(
                            prev => {
                                const documentList = prev.documentList.slice(0);
                                documentList.push({
                                    index,
                                    file,
                                    filename,
                                    size: file.size / 1024,
                                    extension: fileTypeLowercase,
                                    documentType: null,
                                });
                                return {documentList, timestamp: Date.now()};
                            },
                            () => (event.target.value = '')
                        );
                    };
                }
            } else {
                this.setState({error: 'type not valid'}, () => (event.target.value = ''));
            }
        });
    };

    confirmSendingReply = (messageStatus: 'draft' | 'sent') => {
        if (!this.state.textAreaBodyValue) {
            return this.setState({error: 'Reply body cannot be empty'});
        }
        if (!this.state.selectValueDirectory || this.state.selectValueDirectory === SELECT_LIST_ITEM_ID_NOT_SELECTED) {
            return this.setState({error: 'Directory cannot be empty'});
        }
        if (
            !this.state.selectValueSubtypeOfDocument ||
            this.state.selectValueSubtypeOfDocument === SELECT_LIST_ITEM_ID_NOT_SELECTED
        ) {
            return this.setState({error: 'Subtype of document cannot be empty'});
        }
        if (this.state.textAreaBodyValue.length > 4000) {
            return this.setState({error: CC_MAX_MESSAGE_BODY_LENGTH});
        }
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                const {textAreaBodyValue, documentList} = this.state;
                if (this.state.message) {
                    let {
                        thirdPartyId,
                        messageSubject,
                        communicationId: communicationPreviousId,
                        correlationId,
                    } = this.state.message;
                    const JSONRequest: ICommunicationMessageInternalSubmitJSONRequest = {
                        CPVOColleagues: [],
                        addressees: [],
                        applicationNumbers: Object.keys(this.state.selectedApplicationNumbers),
                        communicationPreviousId,
                        correlationId,
                        docmanProfile: this.state.selectValueProfile,
                        docmanDirectory: this.state.selectValueDirectory,
                        docmanSubtypeOfDocument: this.state.selectValueSubtypeOfDocument,
                        draft: messageStatus === 'draft',
                        messageBody: customBTOA(textAreaBodyValue),
                        messageSubject: customBTOA(messageSubject),
                        numberOfAttachments: (documentList || []).length,
                        urgent: this.state.checkboxUrgentValue,
                    };
                    const {thirdPartyId: messageThirdPartyId} = this.state.message || {};
                    const {thirdPartyId: loggedInUserThirdPartyId} = this.decodedJWT || {};
                    if (parseInt(loggedInUserThirdPartyId) === messageThirdPartyId) {
                        JSONRequest.addressees = ((this.state.message && this.state.message.addressees) || []).map(
                            el => ({
                                thirdPartyId: el.ID,
                                role: el.ROLE,
                            })
                        );
                    } else {
                        JSONRequest.addressees = [{thirdPartyId, role: null}];
                    }
                    Object.keys(this.state.selectedCPVOColleagues || {})
                        .map(el => parseInt(el))
                        .forEach(thirdPartyId => {
                            JSONRequest.CPVOColleagues.push({
                                contactId: thirdPartyId,
                                role:
                                    (this.ccList.find(ccListResult => ccListResult['id'] === thirdPartyId) || {})
                                        .role || null,
                            });
                        });
                    apiCommunicationCentreModalInternalReplyMessageReply(JSONRequest, documentList)
                        .then(({communicationId}: {communicationId: number}) => {
                            if (communicationId) {
                                this.setState(
                                    prev => ({loading: prev.loading - 1}),
                                    () => {
                                        this.props.close();
                                    }
                                );
                            } else {
                                this.setState(prev => ({loading: prev.loading - 1, error: '404 - Document not found'}));
                            }
                        })
                        .catch(this.props.close);
                }
            }
        );
    };

    closeModalImportApplicationNumbersList = () => this.setState({modalImportApplicationNumberList: false});

    getRecipientName = () => {
        const {addressees, from, thirdPartyId: messageThirdPartyId} = this.state.message || {};
        const {thirdPartyId: loggedInUserThirdPartyId} = this.decodedJWT || {};
        if (parseInt(loggedInUserThirdPartyId) === messageThirdPartyId) {
            return (addressees || []).map(el => el.NAME).join(', ');
        } else {
            return from;
        }
    };

    openModalApplicationNumbers = (applicationNumbers: string[]) =>
        this.setState({modalApplicationNumbers: applicationNumbers});

    closeModalApplicationNumbers = () => this.setState({modalApplicationNumbers: []});

    onSelectInputProfileChange = ({
        target: {value: selectValueProfile},
    }: React.ChangeEvent<HTMLSelectElement> | {target: {value: string}}): void => {
        if (selectValueProfile === SELECT_LIST_ITEM_ID_NOT_SELECTED) {
            this.setState({
                selectValueProfile: SELECT_LIST_ITEM_ID_NOT_SELECTED,
                selectValueDirectory: SELECT_LIST_ITEM_ID_NOT_SELECTED,
                selectValueSubtypeOfDocument: SELECT_LIST_ITEM_ID_NOT_SELECTED,
            });
        } else {
            this.setState(() => {
                let selectListDocmanDirectories = [
                    {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_DIRECTORY_NOT_SELECTED},
                    ...arrayUniqueByKey(
                        PROFILES_DIRECTORIES_SUBTYPES[selectValueProfile].map(el => ({
                            id: el.dirId,
                            value: el.dirName,
                        }))
                    ),
                ];
                return {selectValueProfile, selectListDocmanDirectories};
            });
        }
    };

    onSelectInputDirectoryChange = ({
        target: {value: selectValueDirectory},
    }: React.ChangeEvent<HTMLSelectElement> | {target: {value: string}}): void => {
        this.setState(prev => {
            let selectListDocmanDocumentSubtypes = [
                {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_SUBTYPE_NOT_SELECTED},
                ...PROFILES_DIRECTORIES_SUBTYPES[prev.selectValueProfile]
                    .filter(directoryItem => directoryItem.dirId === parseInt(selectValueDirectory))
                    .map(el => ({
                        id: el.docTypeId,
                        value: el.docTypeName,
                    })),
            ];
            return {selectValueDirectory, selectListDocmanDocumentSubtypes};
        });
    };

    onSelectInputSubtypeOfDocumentChange = ({
        target: {value: selectValueSubtypeOfDocument},
    }: React.ChangeEvent<HTMLSelectElement>): void => this.setState({selectValueSubtypeOfDocument});

    // onSelectInputCPVOTemplateChange = ({
    //     target: {value: selectValueCPVOTemplate},
    // }: React.ChangeEvent<HTMLSelectElement>): void => {
    //     this.setState({selectValueCPVOTemplate, selectValueCPVOTemplateLanguage: 'none', textAreaBodyValue: ''});
    // };
    //
    // onSelectInputCPVOTemplateLanguageChange = ({
    //     target: {value: selectValueCPVOTemplateLanguage},
    // }: React.ChangeEvent<HTMLSelectElement>): void => {
    //     let parsedExampleTemplate = examplePVRTemplate
    //         .replaceAll('&laquo;', '<div style="background: red;display: inline-block">')
    //         .replaceAll('&raquo;', '</div>');
    //     if (!selectValueCPVOTemplateLanguage || selectValueCPVOTemplateLanguage === 'none') {
    //         this.setState({textAreaBodyValue: ''});
    //     } else {
    //         this.setState({textAreaBodyValue: parsedExampleTemplate});
    //     }
    //     this.setState({selectValueCPVOTemplateLanguage});
    // };

    onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];
            this.checkFile(file, event);
        }
    };

    // onTextAreaBodyChange = ({target: {value: textAreaBodyValue}}: React.ChangeEvent<HTMLTextAreaElement>): void =>
    //     this.setState({textAreaBodyValue});
    onTextAreaBodyChange = (textAreaBodyValue: string): void => {
        let parsedValue = textAreaBodyValue.replaceAll('ff0000', 'ffffff');
        this.setState({textAreaBodyValue: parsedValue});
    };

    onTextInputValueSubjectChange = ({target: {value: messageSubject}}: React.ChangeEvent<HTMLInputElement>) => {
        if (this.state.message) {
            this.setState(prev => {
                let updatedMessage = {
                    ...prev.message,
                    messageSubject: messageSubject.slice(0, 200),
                } as ICommunicationMessageInternalRead;
                return {
                    message: updatedMessage,
                };
            });
        }
    };

    onInputLinkImportApplicationNumberListClick = () => this.setState({modalImportApplicationNumberList: true});

    onImportApplicationNumbersList = (matches: string[]) =>
        this.setState(prev => {
            const currentSelectedApplicationNumbers = {...prev.selectedApplicationNumbers};
            matches.forEach(el => (currentSelectedApplicationNumbers[el] = el));
            return {selectedApplicationNumbers: currentSelectedApplicationNumbers};
        });

    onApplicationNumberChange = (applicationNumber: string): void => this.checkApplicationNumber(applicationNumber);

    onApplicationNumbersSelectionChange = (selectedApplicationNumbers: {[key: string]: string}): void =>
        this.setState({selectedApplicationNumbers});

    onAddingApplicationNumber = () => {
        const {applicationNumber} = this.state;
        if (applicationNumber.length === 8 && isRegisterIdValid(applicationNumber)) {
            this.setState(prev => {
                const selectedApplicationNumbers = prev.selectedApplicationNumbers;
                selectedApplicationNumbers[prev.applicationNumber] = prev.applicationNumber;
                return {
                    applicationNumber: '',
                    applicationNumberError: null,
                    selectedApplicationNumbers,
                };
            });
        }
    };

    onCPVOColleaguesSectionChange = (selectedCPVOColleagues: {[key: string]: string}): void =>
        this.setState({selectedCPVOColleagues, textLabelInputValueCPVOColleagues: ''});

    onTextLabelInputCPVOColleaguesChange = (textLabelInputValueCPVOColleagues: string): void =>
        this.setState({textLabelInputValueCPVOColleagues});

    onButtonPreviewClick = () => this.setState(prev => ({isPreviewMode: !prev.isPreviewMode}));

    onCheckboxUrgentClick = () => this.setState(prev => ({checkboxUrgentValue: !prev.checkboxUrgentValue}));

    render() {
        const attachmentActions = CCModalInternalReplyActionButtons(this.deleteFile);

        return (
            <>
                {this.state.modalImportApplicationNumberList ? (
                    <ModalApplicationList
                        importList={this.onImportApplicationNumbersList}
                        close={this.closeModalImportApplicationNumbersList}
                    />
                ) : null}
                {this.state.modalApplicationNumbers.length ? (
                    <ModalApplicationNumbers
                        applicationNumbers={this.state.modalApplicationNumbers}
                        close={this.closeModalApplicationNumbers}
                    />
                ) : null}
                <ModalCustomVersion3
                    isPreviewMode={this.props.isPreviewMode || false}
                    close={this.props.close}
                    header={`Replying message`}
                    body={
                        this.state.isPreviewMode ? (
                            <div className={styles.previewWrap} style={{height: 'calc(100vh - 220px)'}}>
                                <div>
                                    <DataSheetFormFields
                                        label={'Subject'}
                                        data={(this.state.message && this.state.message.messageSubject) || ''}
                                        loading={false}
                                        ready={true}
                                        double={true}
                                    />
                                </div>
                                <div style={{clear: 'both'}} />
                                <div style={{marginTop: 15}}>
                                    <div>{`Body`}</div>
                                    <Editor
                                        id="textareaMainLanguage"
                                        value={this.state.textAreaBodyValue}
                                        init={tinyConfigFullHeight}
                                        onEditorChange={this.onTextAreaBodyChange}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div style={{overflowX: 'hidden', paddingBottom: 20}}>
                                <DataSheetFormSection title={'Original message'}>
                                    <InternalMessageFieldsAndAttachments
                                        attachments={this.originalAttachments}
                                        communicationId={this.props.communicationId}
                                        intl={this.props.intl}
                                        message={this.originalMessage}
                                        openModalApplicationNumbers={this.openModalApplicationNumbers}
                                    />
                                </DataSheetFormSection>
                                <DataSheetFormSection disableToggle={true} title={`Replying message`}>
                                    <DataSheetFormFields
                                        label={'To'}
                                        data={this.getRecipientName()}
                                        double={true}
                                        loading={this.state.loading !== 0}
                                    />
                                    <div className={styles.applicationNumbersEditWrap}>
                                        <DataSheetFormFields
                                            label={'Application numbers'}
                                            data={
                                                <div style={{marginLeft: -8}}>
                                                    <TextLabelInput
                                                        onSelectionChange={this.onApplicationNumbersSelectionChange}
                                                        onChange={this.onApplicationNumberChange}
                                                        onEnter={this.onAddingApplicationNumber}
                                                        value={this.state.applicationNumber}
                                                        selectedElements={this.state.selectedApplicationNumbers}
                                                        delay={300}
                                                        multiple={true}
                                                        buttonAction={this.onAddingApplicationNumber}
                                                        buttonIcon={faPlus}
                                                        buttonDisabled={
                                                            (this.state.applicationNumberError &&
                                                                this.state.applicationNumberError !== 'ADD') ||
                                                            this.state.applicationNumber.length !== 8
                                                        }
                                                        double={true}
                                                    />
                                                    {this.state.applicationNumberError && (
                                                        <div style={{marginBottom: 15, marginLeft: 7}}>
                                                            <Info>
                                                                <div style={{display: 'inline-block'}}>
                                                                    {errorMessages[this.state.applicationNumberError]}
                                                                </div>
                                                            </Info>
                                                        </div>
                                                    )}
                                                    <div
                                                        style={{
                                                            float: 'left',
                                                            marginTop: -11,
                                                            paddingBottom: 7,
                                                            fontWeight: 'normal',
                                                        }}
                                                    >
                                                        <InputLink
                                                            label={`Import application number list`}
                                                            clickAction={
                                                                this.onInputLinkImportApplicationNumberListClick
                                                            }
                                                            width={280}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            loading={false}
                                            ready={true}
                                            double={true}
                                        />
                                    </div>
                                    <div>
                                        <DataSheetFormFields
                                            label={'Subject'}
                                            data={
                                                <div style={{marginLeft: -10}}>
                                                    <TextInput
                                                        onChange={this.onTextInputValueSubjectChange}
                                                        value={
                                                            (this.state.message && this.state.message.messageSubject) ||
                                                            ''
                                                        }
                                                        triple={true}
                                                    />
                                                </div>
                                            }
                                            loading={false}
                                            ready={true}
                                            double={true}
                                        />
                                    </div>
                                    <div style={{clear: 'both'}} />
                                    {/*<SelectInput*/}
                                    {/*    label={`CPVO Template`}*/}
                                    {/*    value={this.state.selectValueCPVOTemplate}*/}
                                    {/*    onChange={this.onSelectInputCPVOTemplateChange}*/}
                                    {/*    list={[*/}
                                    {/*        {*/}
                                    {/*            id: 'none',*/}
                                    {/*            value: 'Select a CPVO Template to reply',*/}
                                    {/*        },*/}
                                    {/*        {*/}
                                    {/*            id: '1',*/}
                                    {/*            value: 'CPVO template 1',*/}
                                    {/*        },*/}
                                    {/*        {id: '2', value: 'CPVO template 2'},*/}
                                    {/*        {id: '3', value: 'CPVO template 3'},*/}
                                    {/*        {id: '4', value: 'CPVO template 4'},*/}
                                    {/*    ]}*/}
                                    {/*    notAll={true}*/}
                                    {/*    noIcon={true}*/}
                                    {/*    double={true}*/}
                                    {/*/>*/}
                                    {/*<SelectInput*/}
                                    {/*    label={`CPVO template language`}*/}
                                    {/*    value={this.state.selectValueCPVOTemplateLanguage}*/}
                                    {/*    onChange={this.onSelectInputCPVOTemplateLanguageChange}*/}
                                    {/*    list={[*/}
                                    {/*        {*/}
                                    {/*            id: 'none',*/}
                                    {/*            value:*/}
                                    {/*                !this.state.selectValueCPVOTemplate ||*/}
                                    {/*                this.state.selectValueCPVOTemplate === 'none'*/}
                                    {/*                    ? 'First select a CPVO template'*/}
                                    {/*                    : 'Select a template language',*/}
                                    {/*        },*/}
                                    {/*        {*/}
                                    {/*            id: 'en',*/}
                                    {/*            value: 'English',*/}
                                    {/*        },*/}
                                    {/*        {id: 'fr', value: 'French'},*/}
                                    {/*    ]}*/}
                                    {/*    notAll={true}*/}
                                    {/*    noIcon={true}*/}
                                    {/*    disabled={*/}
                                    {/*        !this.state.selectValueCPVOTemplate ||*/}
                                    {/*        this.state.selectValueCPVOTemplate === 'none'*/}
                                    {/*    }*/}
                                    {/*/>*/}
                                    <div>
                                        {/*<TextAreaInput*/}
                                        {/*    label="Body"*/}
                                        {/*    value={this.state.textAreaBodyValue}*/}
                                        {/*    onChange={this.onTextAreaBodyChange}*/}
                                        {/*    rows={5}*/}
                                        {/*    mandatory={true}*/}
                                        {/*    triple={true}*/}
                                        {/*    width={700}*/}
                                        {/*/>*/}
                                        <div style={{margin: '0px 57px 15px 7px'}}>
                                            <div>{'Body'}</div>
                                            <Editor
                                                id="textareaMainLanguage"
                                                value={this.state.textAreaBodyValue}
                                                init={tinyConfig}
                                                onEditorChange={this.onTextAreaBodyChange}
                                            />
                                        </div>
                                    </div>
                                    <div style={{marginLeft: 7, marginBottom: 10}}>
                                        {`Characters count: `}
                                        <span
                                            style={{
                                                color: this.state.textAreaBodyValue.length > 4000 ? 'red' : 'green',
                                            }}
                                        >{`${this.state.textAreaBodyValue.length} / 4000`}</span>
                                    </div>
                                    <div>
                                        <Checkbox
                                            clickAction={this.onCheckboxUrgentClick}
                                            simple={true}
                                            label={`Urgent`}
                                            value={this.state.checkboxUrgentValue}
                                            width={500}
                                        />
                                    </div>
                                    <TextLabelInput
                                        clickableAndEditable={true}
                                        double={true}
                                        onChange={this.onTextLabelInputCPVOColleaguesChange}
                                        onSelectionChange={this.onCPVOColleaguesSectionChange}
                                        value={this.state.textLabelInputValueCPVOColleagues}
                                        selectedElements={this.state.selectedCPVOColleagues}
                                        delay={300}
                                        multiple={true}
                                        label={'CC CPVO colleagues'}
                                        placeholder={`Please type part of the colleague name`}
                                        triple={true}
                                        listByIdName={this.state.CPVOColleaguesListByIdName}
                                        nameHash={this.state.CPVOColleaguesNameHash}
                                        filterContains
                                        listMaxHeight={`270px`}
                                    />
                                    <SelectInput
                                        label={`Profile`}
                                        value={this.state.selectValueProfile}
                                        onChange={this.onSelectInputProfileChange}
                                        list={this.state.selectListDocmanProfiles || []}
                                        notAll={true}
                                        triple={true}
                                        noIcon={true}
                                        mandatory={true}
                                        disabled={this.state.selectListDocmanProfiles?.length === 1}
                                    />
                                    <SelectInput
                                        label={`Directory`}
                                        value={this.state.selectValueDirectory}
                                        onChange={this.onSelectInputDirectoryChange}
                                        list={this.state.selectListDocmanDirectories || []}
                                        notAll={true}
                                        triple={true}
                                        noIcon={true}
                                        mandatory={true}
                                        disabled={this.state.selectValueProfile === SELECT_LIST_ITEM_ID_NOT_SELECTED}
                                        hideOptionNone={true}
                                    />
                                    <SelectInput
                                        label={`Subtype of document`}
                                        value={this.state.selectValueSubtypeOfDocument}
                                        onChange={this.onSelectInputSubtypeOfDocumentChange}
                                        list={this.state.selectListDocmanDocumentSubtypes || []}
                                        notAll={true}
                                        triple={true}
                                        noIcon={true}
                                        mandatory={true}
                                        disabled={
                                            this.state.selectValueProfile === SELECT_LIST_ITEM_ID_NOT_SELECTED ||
                                            this.state.selectValueDirectory === SELECT_LIST_ITEM_ID_NOT_SELECTED
                                        }
                                    />
                                    <div style={{clear: 'both'}} />
                                    {this.state.documentList && this.state.documentList.length > 0 && (
                                        <CustomTable
                                            version={1}
                                            {...this.props}
                                            notSortable={['filename', 'size']}
                                            tableName={'documentUpload'}
                                            tableType={'OBJECT'}
                                            tableSource={this.state.documentList || []}
                                            dataFilter={null}
                                            id={'index'}
                                            setLastCursor={null}
                                            resultFieldsDefault={['filename', 'size']}
                                            intl={this.props.intl}
                                            formatFunctions={{
                                                size: (size: number) => `${Math.floor(size * 100) / 100} KB`,
                                                extension: (extension: string) => (
                                                    <img src={getIcon(extension || 'pdf')} alt={extension} />
                                                ),
                                            }}
                                            count={(this.state.documentList || []).length}
                                            hideExcelButton={true}
                                            forehandColumn={(row: IDocument) => (
                                                <img
                                                    style={{width: 20, height: 20, cursor: 'pointer'}}
                                                    alt={'Download'}
                                                    src={getIcon(row.extension || 'pdf')}
                                                    onClick={event => {
                                                        event.stopPropagation();
                                                        saveAs(row.file, row.filename);
                                                    }}
                                                />
                                            )}
                                            timestamp={this.state.timestamp}
                                            actions={attachmentActions}
                                        />
                                    )}
                                </DataSheetFormSection>
                            </div>
                        )
                    }
                    footer={
                        <div className={styles.footerWrap}>
                            <>
                                {this.state.error ? <Error>{this.state.error}</Error> : null}
                                <div className={styles.footerLeft}>
                                    <Button
                                        variation={'secondary'}
                                        clickAction={this.onButtonPreviewClick}
                                        isCheckboxChecked={this.state.isPreviewMode}
                                    >{`Preview`}</Button>
                                    <label htmlFor={'upload'}>
                                        <input
                                            type="file"
                                            name="files[]"
                                            id="upload"
                                            style={{opacity: 0, width: 0}}
                                            onChange={this.onFileUpload}
                                            accept="image/jpg, image/jpeg, image/png, application/pdf"
                                            ref={this.inputUploadRef}
                                        />
                                        <Button
                                            variation={'secondary'}
                                            clickAction={() => this.inputUploadRef.current.click()}
                                        >{`Add document`}</Button>
                                    </label>
                                </div>
                                <div className={styles.footerRight}>
                                    <Button
                                        variation={'danger'}
                                        clickAction={this.props.close}
                                        icon={'close'}
                                    >{`Close`}</Button>
                                    <Button
                                        variation={'secondary'}
                                        clickAction={() => this.confirmSendingReply('draft')}
                                    >{`Draft`}</Button>
                                    <Button
                                        clickAction={() => this.confirmSendingReply('sent')}
                                        icon={'arrowRight'}
                                        disabled={this.state.loading !== 0}
                                    >{`Send reply`}</Button>
                                </div>
                            </>
                        </div>
                    }
                />
            </>
        );
    }
}

export default injectIntl(CCModalInternalReply);
