import React from 'react';
import {rowType} from './ModalUsedInVersion2';

function renderButton(popupContent: any, handler: any, icon: any) {
    return {
        title: popupContent,
        icon: (rowObject: any) =>
            rowObject.rowType !== rowType.FORM ? (
                <span>{}</span>
            ) : (
                <a data-content={popupContent}>
                    <i className={`fa fa-${icon}`}>{}</i>
                </a>
            ),
        handler,
    };
}

export default function ModalLabelUsedInActionButtons() {
    const buttons = [];
    buttons.push(renderButton('Open questionnaire', () => {}, 'eye'));
    return buttons;
}
