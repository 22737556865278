import React from 'react';
import styles from './MyPVRPublicConsultationDocumentsTopCaption.module.scss';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {faDownload} from '@fortawesome/free-solid-svg-icons';

export default function BackOfficeMessagesBottomCaption({downloadEnable, downloadSelected}) {
    const buttonEnabled = downloadEnable();
    return (
        <div className={styles.container}>
            <FormFooterButton
                color={buttonColor.blue}
                clickAction={downloadSelected}
                icon={faDownload}
                disabled={!buttonEnabled}
            >{`Download`}</FormFooterButton>

            <div style={{clear: 'both'}}>{}</div>
        </div>
    );
}
