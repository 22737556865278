import React, {useState} from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import TextAreaInput from '~components/TextAreaInput';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeControlListCountries = ({
    close,
    onCreateOrUpdateLogicType,
    responseLogicValues,
    responseLogicType,
}) => {
    const {logicParam, applyToRemark} = responseLogicValues || {};
    const {defaultParam, name} = responseLogicType || {};
    const [paramValue, setParamValue] = useState(logicParam !== undefined ? logicParam : null);
    const onParamValueChange = ({target: {value: param}}) => setParamValue(param);

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    {defaultParam && (
                        <div>
                            <TextAreaInput
                                label="Default Parameters"
                                value={defaultParam}
                                width={550}
                                rows={7}
                                disabled={true}
                            />
                        </div>
                    )}
                    <div>
                        <TextAreaInput
                            label="Parameters"
                            value={paramValue}
                            onChange={onParamValueChange}
                            width={550}
                            rows={7}
                        />
                    </div>
                </>
            }
            footer={
                <>
                    <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                    <Button
                        icon={'arrowRight'}
                        clickAction={() =>
                            onCreateOrUpdateLogicType(paramValue, logicParam !== undefined ? applyToRemark : false)
                        }
                    >{`Save`}</Button>
                </>
            }
        />
    );
};

export default ModalLogicTypeControlListCountries;
