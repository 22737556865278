import React from 'react';
import moment from 'moment';
import styles from './IntroductionSectionArea.module.scss';

interface IntroductionSectionAreaProps {
    startProposalDate: string;
    deadlineNegotiationsDate: string;
    endProposalDate: string;
}

const IntroductionSectionArea = (props: IntroductionSectionAreaProps) => {
    const endProposalDatePlus1Day = moment(props.endProposalDate).add(1, 'day').format('DD/MM/YYYY');
    return (
        <p className={styles.introductionContainer}>
            <span>
                <strong>
                    {`Step one: ` +
                        moment(props.startProposalDate).format('DD/MM/YYYY') +
                        ` - ` +
                        moment(props.endProposalDate).format('DD/MM/YYYY') +
                        ` : EXPRESSION OF INTEREST IN NEW ENTRUSTMENTS`}
                </strong>
                <br />
            </span>
            <span>
                {`For each new species, you can consult all the applications documents received by the CPVO and indicate if your Examination Office is willing to become entrusted for this species. The proposals from the Other Examination offices are already visible at this stage.`}
                <br />
                {`Note: Click in the first column to display associated documents.`}
                {` Click on the last column to edit your answer.`}
                {` The answer becomes definitive only when the deadline is met.`}
                {` Please note that it is not possible to extend this deadline`}
            </span>
            <br />
            <br />
            <span>
                <strong>
                    {`Step two ` +
                        endProposalDatePlus1Day +
                        ` - ` +
                        moment(props.deadlineNegotiationsDate).format('DD/MM/YYYY') +
                        ` :  NEGOTIATIONS ROUND BETWEEN EXAMINATION OFFICES`}
                </strong>
                <br />
                {`The proposals from the other Examination Offices are visible at this stage. During this second step, you cannot request a new entrustment which was not in your initial list, but you can withdraw some of your requests for entrustment.`}
                {` To Help negotiations, you can find the list of TLOs contacts under "General Information > List of TLOs"`}
            </span>
        </p>
    );
};

export default IntroductionSectionArea;
