import {downloadUrl} from '../../utils/utilsVarietyFinder';

export const chunkArray = (array: any[], limits: any[], size: number) => {
    const chunkedArr: any[] = [];
    let startIndex = 0;
    let currentIndex = 0;
    let currentLines = 0;
    array.forEach((row: any) => {
        currentIndex++;
        let lines = 1;
        limits.forEach((limit: any) => {
            if (row[limit.name]?.length > limit.size) {
                let rowLines = countColumnLines(row[limit.name], limit.size);
                if (rowLines > lines) {
                    lines = rowLines;
                }
            }
        });
        currentLines += lines;
        if (currentLines > size) {
            chunkedArr.push(array.slice(startIndex, currentIndex - 1));
            startIndex = currentIndex;
            currentLines = 0;
        }
    });
    chunkedArr.push(array.slice(startIndex === 0 ? startIndex : startIndex - 1));
    return chunkedArr;
};

const countColumnLines = (value: string, lineSize: number) => {
    return Math.ceil(value.length / lineSize);
};

export const CPVO_EXPERT_ADVICE_STATUS = {
    NOT_REQUESTED: 1,
    REQUESTED: 2,
    PENDING_ANALYSIS: 3,
    PENDING_COMMITTEE: 4,
    NO_OBSERVATION: 5,
    OBSERVATION: 6,
    CANCELLED: 7,
    CONDITION: 8,
    PRIOR_RIGHT: 9,
};

export const testResultsListReturnTypes = {
    DENOMINATION: 'denomination',
    DENOMINATION_SPECIES: 'denomination_species',
    DENOMINATION_STATUS: 'deno_status',
    BREEDERS_REFERENCE: 'brd_ref',
    BREEDERS_NAME: 'brd_name',
    VARIETY_STATUS: 'var_status',
    DENO_VALID: 'deno_valid',
    VARIETY_STATUS_DATE: 'var_status_date',
    APPLICATION_NUMBER: 'apl_nb',
    SPECIES_LATIN_NAME: 'speciename',
    SCORE: 'score',
    EUTM: 'eutm_tab',
    APPLICANT: 'applicantname',
    TITLE_HOLDER: 'title_holder',
    MAINTAINER: 'maintainer',
    REGISTER: 'register',
    TEST_ID: 'testid',
};

export const downloadBlob = (blob: any, fileName: string) => {
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
    } else {
        const url = window.URL.createObjectURL(blob);
        downloadUrl(url, fileName);
    }
};
