import {API_SERVERLESS, getFetch, postFetch} from './index';

export function apiDownload(csDocsKey: string, jsonData?: any) {
    return (jsonData ? postFetch : getFetch)(
        `${API_SERVERLESS}/common/v3/download${csDocsKey ? `/${csDocsKey}` : ''}`,
        true,
        jsonData
    );
}

export function apiDownloadPublic(csDocsKey: any, jsonData?: any) {
    return (jsonData ? postFetch : getFetch)(
        `${API_SERVERLESS}/common/v3/downloadPublic${csDocsKey ? `/${csDocsKey}` : ''}`,
        false,
        jsonData
    );
}

export function getDownloadURL(token: any) {
    return `${API_SERVERLESS}/common/v3/download/excel/${token}`;
}

export function getExcelSignedURLFetchRequest(token: any) {
    return getFetch(`${API_SERVERLESS}/common/v4/download/excel/${token}`);
}
