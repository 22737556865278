import React from 'react';
import InputContainer from '~componentsForm/InputContainer';
import styles from './ToggleButton.module.scss';
import cx from 'classnames';

interface IProps {
    simple?: boolean;
    double?: boolean;
    triple?: boolean;
    width?: number;
    height?: number;
    label: string;
    subtitleDisplay?: string;
    value: boolean;
    clickAction: any;
    size?: string;
}

interface IState {}

export default class ToggleButton extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    render() {
        return (
            <div>
                <InputContainer
                    checkbox={!this.props.simple}
                    double={this.props.double}
                    triple={this.props.triple}
                    width={this.props.width}
                    height={this.props.height}
                    size={this.props.size}
                >
                    <div className={styles.toggleButtonContainer}>
                        {this.props.label}
                        <div className={styles.toggleOuterWrap} onClick={this.props.clickAction}>
                            <div
                                className={cx(styles.toggleInner, this.props.value ? styles.checked : styles.unChecked)}
                            />
                        </div>
                    </div>
                    {this.props.subtitleDisplay ? (
                        <div>
                            Display <strong>{this.props.subtitleDisplay}</strong>
                        </div>
                    ) : null}
                </InputContainer>
            </div>
        );
    }
}
