import {API_SERVERLESS, getFetch, postFetch} from '../../../../commonApi';

export interface MyRegistersTableRowData {
    registerId: string;
    orderId: string;
    reference: string;
    denomination: string | null;
    specieName: string;
    type: string;
    applicationDate: string;
    amountReserved: number;
    foreseenDate: string;
    arrivalDate: string;
    incorrectReportDate: string;
    finalInterim: string;
    status: string;
}

export interface MyRegistersParamsData {
    data: {
        myRegisters: Array<MyRegistersTableRowData>;
        token: string;
    };
    count: number;
}

export interface MyRegistersTableCriteriaData {
    pageNumber: number;
    pageSize: number;
    refresh: boolean;
    excel: boolean;
    order: string;
    reverse: boolean;
}

export interface MyRegistersConsultationFlags {
    print: boolean;
    tlo: boolean;
    showOnlyDocumentsTab: boolean;
}

export interface MyRegistersFilterCriteriaData extends MyRegistersTableCriteriaData, MyRegistersConsultationFlags {
    orderId: string;
    registerId: string;
    reference: string;
    denomination: string;
    speciesId: string;
    specieName: string;
    speciesNameFilter: string;
    groupVarietal: string;
    type: string;
    forseenDateFrom: string;
    forseenDateTo: string;
    answeringDateFrom: string;
    answeringDateTo: string;
    finalInterim: string;
    status: string;
    expected: boolean;
    showTechnical: boolean;

    [key: string]: string | number | boolean | undefined;
}

// eslint-disable-next-line no-undef
function urlParamsDateValuesReplacements(params: Partial<MyRegistersFilterCriteriaData>) {
    let splittedList: Array<string> = [];
    if (params.answeringDateFrom) {
        splittedList = params.answeringDateFrom.split('/');
        params.answeringDateFrom = splittedList[2] + splittedList[1] + splittedList[0];
    }
    if (params.answeringDateTo) {
        splittedList = params.answeringDateTo.split('/');
        params.answeringDateTo = splittedList[2] + splittedList[1] + splittedList[0];
    }
    if (params.forseenDateFrom) {
        splittedList = params.forseenDateFrom.split('/');
        params.forseenDateFrom = splittedList[2] + splittedList[1] + splittedList[0];
    }
    if (params.forseenDateTo) {
        splittedList = params.forseenDateTo.split('/');
        params.forseenDateTo = splittedList[2] + splittedList[1] + splittedList[0];
    }

    return params;
}

export function apiMyRegistersSearch(
    // eslint-disable-next-line no-undef
    criteria: Partial<MyRegistersFilterCriteriaData>,
    excelTranslations: {[key: string]: string} = {}
): Promise<MyRegistersParamsData> {
    const params = urlParamsDateValuesReplacements({...criteria});
    const requestParams = Object.keys(params).map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/tlo/myregisters?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}
