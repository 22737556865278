import React from 'react';
import {injectIntl} from 'react-intl';
import ApplicationConsultationTabs, {ACCESS, TAB} from '~shared/ApplicationConsultationTabs';
import CustomTable from '~components/CustomTable';
import getIcon from '~utils/icons';
import {saveAs} from 'file-saver';
import {FORMAT_DATE_EASY} from '~components/FormatFunctions';
import MyPVRPublicConsultationDocumentsTopCaption from './MyPVRPrivateConsultationDocumentsTopCaption';
import dateFormat from 'date-format';
import {cleanSpecialCharOnFilename} from '~utils/index';
import {apiDownload} from '~commonApi/download';
import {apiTloDocumentsSearch, apiTloDownloadDocuments} from '../TLO/CommonApi/CommonApi';
import {getPreSignedURLFetchRequest} from '../../utils/requests';
import JSZip from 'jszip';
import pako from 'pako';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {apiMyPVRDocuments} from './MyPVRPrivateConsultationDocumentsService';
import {Footer, FormWrapper, HeaderLoading, HeaderLogo, HeaderTitleAndVersion} from '../../componentsLayout';
import NavigationMyPVR from '../../shared/NavigationMyPVR';
import MainWrapper from '../../componentsLayout/MainWrapper';
import NavigationTLO from '../../shared/NavigationTLO';
import {trackPageView} from '../../utils';

class MyPVRPrivateConsultationDocuments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {documents: null, selectedDocuments: [], downloading: false, loading: false, count: null};
    }

    componentDidMount() {
        trackPageView({documentTitle: 'privateConsultationDocuments'});
    }

    loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        const parameters = {};
        if (domainArray.length > 1) {
            const params = domainArray.pop();
            params.split('&').forEach(param => {
                const paramElements = param.split('=');
                if (paramElements.length === 2) {
                    const key = paramElements[0];
                    parameters[key] = decodeURIComponent(paramElements[1]);
                }
            });
        }
        return parameters;
    };

    loadJSONs = applicationNumber =>
        new Promise((resolve, reject) => {
            this.setState({loading: true}, () => {
                const urlParams = this.loadUrlParams();
                if (urlParams.tlo) {
                    apiTloDocumentsSearch(applicationNumber, 100)
                        .then(jsonResponse => {
                            if (jsonResponse.data && jsonResponse.data.documents) {
                                for (const [index, document] of jsonResponse.data.documents.entries()) {
                                    document.arrivalDate =
                                        document.arrivalDate.substring(0, 4) +
                                        '-' +
                                        document.arrivalDate.substring(4, 6) +
                                        '-' +
                                        document.arrivalDate.substring(6, 8);
                                    document.nbDocs = index + 1 + '/' + jsonResponse.data.documents.length;
                                }
                                this.setState({documents: jsonResponse.data.documents});
                            }
                        })
                        .then(() => this.setState({loading: false}, resolve))
                        .catch(reject);
                } else {
                    apiMyPVRDocuments(applicationNumber)
                        .then(jsonResponse => {
                            jsonResponse &&
                                jsonResponse.documents &&
                                this.setState({documents: jsonResponse.documents});
                        })
                        .then(() => this.setState({loading: false}, resolve))
                        .catch(reject);
                }
            });
        });

    downloadDocument = document =>
        this.setState({downloading: true}, () => {
            const urlParams = this.loadUrlParams();
            if (urlParams.tlo) {
                apiTloDownloadDocuments(document.csDocsKey)
                    .then(JSONResponse => {
                        if (JSONResponse && JSONResponse.documents) {
                            let preSignedURL;
                            let fileName;
                            let isZip;
                            if (Array.isArray(JSONResponse.documents)) {
                                preSignedURL = JSONResponse.documents[0]?.signedUrl;
                                fileName = JSONResponse.documents[0]?.docName;
                                isZip = JSONResponse.documents[0]?.isZip;
                            } else if (JSONResponse.documents.signedUrl && JSONResponse.documents.fileName) {
                                preSignedURL = JSONResponse.documents.signedUrl;
                                fileName = JSONResponse.documents?.fileName;
                                isZip = JSONResponse.documents?.isZip;
                            }
                            if (preSignedURL && fileName) {
                                getPreSignedURLFetchRequest(preSignedURL)
                                    .then(response => response.blob())
                                    .then(responseBlob => {
                                        if (isZip) {
                                            responseBlob.arrayBuffer().then(fileReader => {
                                                saveAs(new Blob([pako.ungzip(new Uint8Array(fileReader))]), fileName);
                                            });
                                        } else {
                                            saveAs(responseBlob, fileName);
                                        }
                                    });
                            }
                        }
                    })
                    .then(() => this.setState({downloading: false}));
            } else {
                apiDownload(document.csDocsKey)
                    .then(JSONResponse => {
                        if (JSONResponse && JSONResponse.documents) {
                            let preSignedURL;
                            let fileName;
                            let isZip;
                            if (Array.isArray(JSONResponse.documents)) {
                                preSignedURL = JSONResponse.documents[0]?.signedUrl;
                                fileName = JSONResponse.documents[0]?.docName;
                                isZip = JSONResponse.documents[0]?.isZip;
                            } else if (JSONResponse.documents.signecUrl && JSONResponse.documents.fileName) {
                                preSignedURL = JSONResponse.documents.signecUrl;
                                fileName = JSONResponse.documents?.fileName;
                                isZip = JSONResponse.documents?.isZip;
                            }
                            if (preSignedURL && fileName) {
                                getPreSignedURLFetchRequest(preSignedURL)
                                    .then(response => response.blob())
                                    .then(responseBlob => {
                                        if (isZip) {
                                            responseBlob.arrayBuffer().then(fileReader => {
                                                saveAs(new Blob([pako.ungzip(new Uint8Array(fileReader))]), fileName);
                                            });
                                        } else {
                                            saveAs(responseBlob, fileName);
                                        }
                                    });
                            }
                        }
                    })
                    .then(() => this.setState({downloading: false}));
            }
        });

    downloadDocuments = () => {
        if (this.isDownLoadEnable()) {
            const csDocsKeyArray = (this.state.selectedDocuments || []).filter(cs => cs !== null);
            const csDocsKeys = [...new Set(csDocsKeyArray)];
            const documentCount = csDocsKeys.length;
            this.setState({downloading: true}, () => {
                const urlParams = this.loadUrlParams();
                if (urlParams.tlo) {
                    apiTloDownloadDocuments(
                        documentCount === 1 ? csDocsKeys.slice(0, 1) : null,
                        documentCount > 1 ? {csDocsKeys} : null
                    )
                        .then(JSONResponse => {
                            if (JSONResponse && JSONResponse.documents) {
                                let preSignedURLs = [];
                                let fileNames = [];
                                let isZips = [];
                                if (Array.isArray(JSONResponse.documents)) {
                                    preSignedURLs = JSONResponse.documents.map(document => document?.signedUrl);
                                    fileNames = JSONResponse.documents.map(document => document?.docName);
                                    isZips = JSONResponse.documents.map(document => document?.isZip);
                                } else {
                                    preSignedURLs.push(JSONResponse.documents?.signedUrl);
                                    fileNames.push(JSONResponse.documents?.fileName);
                                    isZips = [JSONResponse.documents?.isZip];
                                }
                                if (preSignedURLs.length === 1) {
                                    getPreSignedURLFetchRequest(preSignedURLs[0])
                                        .then(response => response.blob())
                                        .then(responseBlob => {
                                            if (isZips[0]) {
                                                responseBlob.arrayBuffer().then(fileReader => {
                                                    saveAs(
                                                        new Blob([pako.ungzip(new Uint8Array(fileReader))]),
                                                        fileNames[0]
                                                    );
                                                });
                                            } else {
                                                saveAs(responseBlob, fileNames[0]);
                                            }
                                        });
                                } else {
                                    (async function () {
                                        let i = 0;
                                        let processedBlobs = [];
                                        for await (let preSignedURL of preSignedURLs) {
                                            let response = await getPreSignedURLFetchRequest(preSignedURL);
                                            let responseBlob = await response.blob();
                                            if (isZips[i]) {
                                                let fileReader = await responseBlob.arrayBuffer();
                                                processedBlobs.push(
                                                    new Blob([pako.ungzip(new Uint8Array(fileReader))])
                                                );
                                            } else {
                                                processedBlobs.push(responseBlob);
                                            }
                                            i += 1;
                                        }
                                        const zip = new JSZip();
                                        processedBlobs.forEach((blob, i) => {
                                            zip.file(fileNames[i], blob, {
                                                binary: true,
                                            });
                                        });
                                        let content = await zip.generateAsync({
                                            type: 'blob',
                                        });
                                        saveAs(content, 'archive.zip');
                                    })();
                                }
                            }
                        })
                        .then(() => this.setState({downloading: false}));
                } else {
                    apiDownload(
                        documentCount === 1 ? csDocsKeys.slice(0, 1) : null,
                        documentCount > 1 ? {csDocsKeys} : null
                    )
                        .then(JSONResponse => {
                            if (JSONResponse && JSONResponse.documents) {
                                let preSignedURLs = [];
                                let fileNames = [];
                                let isZips = [];
                                if (Array.isArray(JSONResponse.documents)) {
                                    preSignedURLs = JSONResponse.documents.map(document => document?.signedUrl);
                                    fileNames = JSONResponse.documents.map(document => document?.docName);
                                    isZips = JSONResponse.documents.map(document => document?.isZip);
                                } else {
                                    preSignedURLs.push(JSONResponse.documents?.signecUrl);
                                    fileNames.push(JSONResponse.documents?.fileName);
                                    isZips = [JSONResponse.documents?.isZip];
                                }
                                if (preSignedURLs.length === 1) {
                                    getPreSignedURLFetchRequest(preSignedURLs[0])
                                        .then(response => response.blob())
                                        .then(responseBlob => {
                                            if (isZips[0]) {
                                                responseBlob.arrayBuffer().then(fileReader => {
                                                    saveAs(
                                                        new Blob([pako.ungzip(new Uint8Array(fileReader))]),
                                                        fileNames[0]
                                                    );
                                                });
                                            } else {
                                                saveAs(responseBlob, fileNames[0]);
                                            }
                                        });
                                } else {
                                    (async function () {
                                        let i = 0;
                                        let processedBlobs = [];
                                        for await (let preSignedURL of preSignedURLs) {
                                            let response = await getPreSignedURLFetchRequest(preSignedURL);
                                            let responseBlob = await response.blob();
                                            if (isZips[i]) {
                                                let fileReader = await responseBlob.arrayBuffer();
                                                processedBlobs.push(
                                                    new Blob([pako.ungzip(new Uint8Array(fileReader))])
                                                );
                                            } else {
                                                processedBlobs.push(responseBlob);
                                            }
                                            i += 1;
                                        }
                                        const zip = new JSZip();
                                        processedBlobs.forEach((blob, i) => {
                                            zip.file(fileNames[i], blob, {
                                                binary: true,
                                            });
                                        });
                                        let content = await zip.generateAsync({
                                            type: 'blob',
                                        });
                                        saveAs(content, 'archive.zip');
                                    })();
                                }
                            }
                        })
                        .then(() => this.setState({downloading: false}));
                }
            });
        }
    };

    getFileName = document => {
        const fileDestinationArray = [];
        if (document.fileNumber) {
            fileDestinationArray.push(document.fileNumber);
            fileDestinationArray.push('_');
        }
        if (document.arrivalDate && document.arrivalDate !== '') {
            fileDestinationArray.push(dateFormat('yyyy-MM-dd', dateFormat.parse('yyyy-MM-dd', document.arrivalDate)));
            fileDestinationArray.push('_');
        }
        if (document.documentTitle) {
            fileDestinationArray.push(
                `${document.documentTitle}-${document.nbDocs ? document.nbDocs.replace('/', '.') : ''}-`
            );
        } else if (document.documentType) {
            fileDestinationArray.push(
                `${document.documentType.substring(0, 180)}-${
                    document.nbDocs ? document.nbDocs.replace('/', '.') : ''
                }-`
            );
        }
        const fileName = `${fileDestinationArray.join('')}${document.csDocsKey}.${document.extension || 'pdf'}`;
        let fileDestination = `${cleanSpecialCharOnFilename(fileName)}`;
        fileDestination = fileDestination.split(' ').join('');
        return fileDestination;
    };

    isDownLoadEnable = () => this.state.selectedDocuments && this.state.selectedDocuments.length > 0;

    onSelectedDocumentsChange = selectedDocuments => this.setState({selectedDocuments});

    render() {
        const urlParams = this.loadUrlParams();

        return (
            <>
                {this.state.loading ? <HeaderLoading /> : null}
                {this.state.downloading ? <HeaderLoading message={`Downloading ...`} /> : null}
                <HeaderLogo />
                <HeaderTitleAndVersion title={`My PVR`} />
                {urlParams.tlo ? <NavigationTLO activeTitle={`My Applications`} /> : <NavigationMyPVR />}
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <ApplicationConsultationTabs
                            search={this.loadJSONs}
                            tabId={TAB.DOCUMENTS}
                            access={ACCESS.PRIVATE}
                            loading={this.state.documents === null}
                            close={this.props.close}
                        />
                        {this.state.documents ? (
                            this.state.documents.length === 0 ? (
                                <div style={{color: 'red', textAlign: 'center'}}>
                                    <b style={{textAlign: 'center'}}>
                                        <span>{`No documents available`}</span>
                                    </b>
                                </div>
                            ) : (
                                <CustomTable
                                    pageSize={100}
                                    tableName={'myApplicationsDocuments'}
                                    // rowAllLabel={this.getGroupTitle()}
                                    headerSelectColumnTitle={`Select / Deselect all documents`}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.documents}
                                    id={'csDocsKey'}
                                    resultFieldsDefault={['documentType', 'arrivalDate', 'nbDocs']}
                                    intl={this.props.intl}
                                    defaultOrder={'arrivalDate'}
                                    reverseOrder={true}
                                    formatFunctions={{arrivalDate: FORMAT_DATE_EASY}}
                                    selectable={true}
                                    onSelectedChange={this.onSelectedDocumentsChange}
                                    forehandColumn={document => {
                                        return document.fileExists ? (
                                            <img
                                                style={{width: 20, height: 20, cursor: 'pointer'}}
                                                alt={'Download'}
                                                src={getIcon(
                                                    document.extension || document.docName.split('.')[1] || 'pdf'
                                                )}
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    this.downloadDocument(document);
                                                }}
                                            />
                                        ) : (
                                            <div
                                                title={
                                                    'File not present, it is possible that it is pending of synchronization. Please try later'
                                                }
                                            >
                                                <FontAwesomeIcon icon={faExclamationTriangle} color={'orange'} />
                                            </div>
                                        );
                                    }}
                                    topCaption={
                                        this.state.documents &&
                                        this.state.documents.length > 0 && (
                                            <MyPVRPublicConsultationDocumentsTopCaption
                                                downloadEnable={this.isDownLoadEnable}
                                                downloadSelected={this.downloadDocuments}
                                            />
                                        )
                                    }
                                />
                            )
                        ) : null}
                    </FormWrapper>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(MyPVRPrivateConsultationDocuments);
