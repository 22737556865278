import {API_SERVERLESS, postFetch} from '~commonApi';

export function apiBackOfficeFormResponseLogicCreateOrUpdate(jsonData: {
    responseId: number;
    formId: number | null;
    templateId?: number | null;
    logicId: number;
    logicParam: string | null;
    questionIdDest: number | null;
    responseIdDest: number | null;
    iteration: number;
    messageId: number | null;
    root: any;
}): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formResponseLogicCreateOrUpdate`, true, jsonData);
}

export function apiBackOfficeTemplateResponseLogicCreateOrUpdate(jsonData: {
    responseId: number;
    formId: number | null;
    templateId?: number | null;
    logicId: number;
    logicParam: string;
    questionIdDest: number | null;
    responseIdDest: number | null;
    iteration: number;
    messageId: number | null;
    root: any;
}): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formResponseLogicTemplateCreateOrUpdate`, true, jsonData);
}

export function apiBackOfficeFormResponseLogicDelete(jsonBody: {
    formId: number | null;
    responseId?: number;
    logicId?: number;
    iteration?: number;
}): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/responseLogicDelete`, true, jsonBody);
}

export function apiBackOfficeTemplateResponseLogicDelete(jsonBody: {
    templateId?: number | null;
    responseId?: number;
    logicId?: number;
    iteration?: number;
}): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/responseLogicTemplateDelete`, true, jsonBody);
}
