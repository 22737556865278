import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import {Button} from '../../../componentsFormV2';
import AutocompleteLanguages1 from './screenshots/AutocompleteLanguages_1.PNG';
import AutocompleteLanguages2 from './screenshots/AutocompleteLanguages_2.PNG';
import AutocompleteLanguages3 from './screenshots/AutocompleteLanguages_3.PNG';

const ModalLogicTypeAutocompleteLanguagesHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>Displays an input like the following: </p>
                    <img src={AutocompleteLanguages1} alt="Auto complete Languages" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>shows suggestions based on the what the user is typing:</p>
                    <img src={AutocompleteLanguages2} alt="Auto complete Languages" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>
                        and lets the user select a language from the auto completed list (and also edit the selection)
                    </p>
                    <img src={AutocompleteLanguages3} alt="Auto complete Languages" className={styles.modalHelpImage} />
                    <p className={styles.helpNote}>
                        If modification of selection is <span className={styles.helpBold}>not</span> needed, then
                        Control list Languages logic type should be used instead of Auto complete Languages
                    </p>
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeAutocompleteLanguagesHelp;
