import React from 'react';
import styles from './ModalCategoryInfoVersion2.module.scss';
import {ModalCustomVersion2} from '../../commonModals';
import {Button} from '../../componentsFormV2';

interface IProps {
    close: () => void;
}

interface IState {
}

export default class ModalCategoryInfoVersion2 extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    close = () => {
        this.props.close && this.props.close();
    };

    render() {
        return (
            <ModalCustomVersion2
                close={this.close}
                header={`Explanations on registers status`}
                body={

                    <table className={styles.table}>
                        <thead>
                        <tr>
                            <th>
                                <a>{'Category'}</a>
                            </th>
                            <th>
                                <a>{'Definition'}</a>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Category A</td>
                            <td>
                                Tree and vine species in the sense of Articles 10 (1)(b) and 19 (1) of Council
                                Regulation (EC) 2100/94:
                                <ul style={{marginTop: 0, marginBottom: 0}}>
                                    <li>
                                        Six years Period of Grace for Novelty for Commercialisations outside the
                                        EU.
                                    </li>
                                    <li>
                                        Duration of the Community plant variety rights: 30 years{' '}
                                        <strong>without</strong> any deduction of periods of protection in
                                        another country prior to the grant of the Community plant variety right.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Category B</td>
                            <td>
                                Genera or species for which the Council has provided for an extended period of
                                protection pursuant to Article 19 (2) of Council Regulation (EC) 2100/94:
                                <ul style={{marginTop: 0, marginBottom: 0}}>
                                    <li>
                                        <i>Asparagus officinalis L.</i>, potatoes, small woody fruit crops,
                                        ornamental flower bulbs, woody ornamentals.
                                    </li>
                                    <li>
                                        Four years Period of Grace for Novelty for Commercialisations outside
                                        the EU.
                                    </li>
                                    <li>
                                        Duration of the Community plant variety rights: 30 years{' '}
                                        <strong>with</strong> deduction of the longest period of national
                                        protections in an EU Member state prior to the grant of the Community
                                        plant variety right. The deduction shall be made in full years for a
                                        maximum period of five years.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Category C</td>
                            <td>
                                Genera or species not falling in category A or B:
                                <ul style={{marginTop: 0, marginBottom: 0}}>
                                    <li>
                                        Four years Period of Grace for Novelty for Commercialisations outside
                                        the EU.
                                    </li>
                                    <li>
                                        Duration of the Community plant variety rights: 25 years{' '}
                                        <strong>without</strong> any deduction of periods of protection in
                                        another country prior to the grant of the Community plant variety right.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Categories “A or B”, “A or B or C” and “B or C”</td>
                            <td>
                                <ul style={{marginTop: 0, marginBottom: 0}}>
                                    <li>
                                        Genera comprising species belonging to more than one of the categories
                                        referred above. For details please refer to the species in question.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Category “*”</td>
                            <td>
                                <ul style={{marginTop: 0, marginBottom: 0}}>
                                    <li>Classification into the categories referred above pending.</li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                }
                footer={<Button icon={'close'} variation={'danger'} clickAction={this.close}>{`Close`}</Button>}
            />
        );
    }
}
