import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export interface IResponseCreatePayload {
    nameMessage: {
        id: number;
    };
    remarkMessage: {
        id: number | null;
    };
    helpMessage: {
        id: number | null;
    };
    idElement: number | null;
    office: number;
    score: any;
    order: string;
    mappingCode: string | null;
    jsonMapping: string | null;
    remarkAllowed: number | null;
    remarkMandatory: any;
    status: number;
    questionId: number;
    responseId: number;
}

export function apiBackOfficeFormOrTemplateResponseCreateOrUpdate(jsonData: IResponseCreatePayload): Promise<{
    newResponseId: number;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formResponseCreateOrUpdate`, true, jsonData);
}

interface IResponseLabel {
    labelId: number;
    languageId: string;
    typeId: number;
    officeId: number;
    isUndefined: number;
    value: string;
    valueHTML: string;
}

export interface IResponseLabelsSearch {
    data: {
        labels: IResponseLabel[];
        COUNT: number;
    };
}

export function apiBackOfficeLabelsSearch({
    searchTerm,
    officeId = '1',
    languageId = 'EN',
}: {
    searchTerm: string;
    officeId?: string;
    languageId?: string;
}): Promise<IResponseLabelsSearch> {
    return getFetch(
        `${API_SERVERLESS}/backOffice/v2/labels?withHTML=true&officeId=${officeId}&languageId=${languageId}&typeId=4&contains=${searchTerm}&containsFilter=start&order=value`,
        true
    );
}

export function apiBackOfficeUpdateLabels({
    labelValue,
    languageId = 'EN',
}: {
    labelValue: string;
    languageId: string;
}): Promise<{
    data: number;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v2/updateLabels/4`, true, {
        [languageId]: {
            labelId: 0,
            labelValue: encodeURIComponent(labelValue),
            labelValueHTML: encodeURIComponent(labelValue),
        },
    });
}
