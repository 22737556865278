import React, {useState} from 'react';
import moment from 'moment';
import en from '../../../../../../translations/en.json';
import styles from './InventoryDetailsTableArea.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faFileExcel, faFilePdf, faFolder} from '@fortawesome/free-solid-svg-icons';
import CustomTable from '~components/CustomTable';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import ModalProposalImage from '../ModalProposalImage/ModalProposalImage';
import {IntlShape} from 'react-intl';
import {InventoryDetails} from '../../NewSpeciesProcedurePageService';

interface InventoryDetailsTableAreaProps {
    intl: IntlShape;
    inventoryDetails: Array<InventoryDetails> | null;
    photosUrls: Array<{
        [key: string]: {
            fileUrl: string;
            thumbnailUrl: string;
        };
    }>;
    count: number;
    userRoles: Array<string>;
    groupHash: {id: string; label: string; hash: Array<string>};
    printExcel: (excelTranslations: {[key: string]: string}) => void;
    printPDF: () => void;
    proposalNumberButtonCB: (proposalNumber: number) => void;
    editButtonCB: (
        proposalNumber: number,
        cost: string,
        quantityQuality: string | null,
        quantityQualityIds: string | null
    ) => void;
}

export default function InventoryDetailsTableArea(props: InventoryDetailsTableAreaProps) {
    const RESULT_FIELDS_ALL = [
        'eoProposalNumber',
        'registers_count',
        'originCountryId',
        'cropSector',
        'htmlSpeciesName',
        'cost',
        'proposalRemark',
        'picture',
    ];
    const RESULT_FIELDS_DEFAULT = [
        'eoProposalNumber',
        'registers_count',
        'originCountryId',
        'cropSector',
        'htmlSpeciesName',
        'cost',
        'proposalRemark',
        'picture',
    ];

    const [showCustomModalV2, setShowCustomModalV2] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    const formatFunctionProposalNumberColumn = (proposalNumber: number) => {
        return (
            <p style={{margin: 'auto'}} className="ng-scope">
                <FormFooterButton
                    color={buttonColor.lightGreen}
                    clickAction={() => props.proposalNumberButtonCB(proposalNumber)}
                    icon={faFolder}
                    width={'50%'}
                    float={'none'}
                >
                    {proposalNumber}
                </FormFooterButton>
            </p>
        );
    };

    const showModalCustomVersion2Screen = (imageUrl: string) => {
        setImageUrl(imageUrl);
        setShowCustomModalV2(true);
    };

    const formatFunctionPictureColumn = (rowObject: InventoryDetails) => {
        const startProposalDate = moment(rowObject.startProposalDate).format('DD/MM/YYYY');
        const diffCurrentDateStartProposalDate = moment(new Date(), 'DD/MM/YYYY').diff(
            moment(startProposalDate, 'DD/MM/YYYY'),
            'days'
        );
        let thumbnailUrl = '';
        let imageUrl = '';
        for (const element of props.photosUrls) {
            if (element[rowObject.eoProposalNumber]) {
                thumbnailUrl = element[rowObject.eoProposalNumber].thumbnailUrl;
                imageUrl = element[rowObject.eoProposalNumber].fileUrl;
            }
        }
        if (diffCurrentDateStartProposalDate >= 0) {
            return (
                <span title={`Click to see full picture`}>
                    <div onClick={() => showModalCustomVersion2Screen(imageUrl)} className="ng-binding">
                        <img src={thumbnailUrl} style={{cursor: 'pointer'}} />
                    </div>
                </span>
            );
        } else {
            return <span></span>;
        }
    };
    const formatLumpSumFields = (lumpSumField: number) => {
        return lumpSumField === 1 ? 'Yes' : 'No';
    };

    const printExcel = () => {
        const excelTranslations: {[key: string]: string} = {};
        const inventoryDetailsExcelKeys = Object.keys(en).filter(key => ~key.indexOf('excel.inventoryDetails.'));
        const inventoryDetailsHeaderKeys = inventoryDetailsExcelKeys.map(key =>
            key.replace('excel.inventoryDetails.', '')
        );
        inventoryDetailsHeaderKeys.forEach(headerKey => {
            excelTranslations[headerKey] = props.intl.formatMessage({
                id: `excel.inventoryDetails.${headerKey}`,
            });
        });
        props.printExcel(excelTranslations);
    };

    const getTopCaptionElement = () => {
        return (
            <div className={styles.topCaptionContainer}>
                <FormFooterButton
                    color={buttonColor.blue}
                    clickAction={printExcel}
                    icon={faFileExcel}
                >{`Export to Excel`}</FormFooterButton>
                <FormFooterButton
                    color={buttonColor.blue}
                    clickAction={props.printPDF}
                    icon={faFilePdf}
                >{`Export to PDF`}</FormFooterButton>
            </div>
        );
    };

    const getActionButton = (rowObject: InventoryDetails) => {
        const startProposalDate = moment(rowObject.startProposalDate).format('DD/MM/YYYY');
        const deadlineNegotiationsDate = moment(rowObject.deadlineNegotiationsDate).format('DD/MM/YYYY');
        const endProposalDate = moment(rowObject.endProposalDate).format('DD/MM/YYYY');
        const diffCurrentDateStartProposalDate = moment(new Date(), 'DD/MM/YYYY').diff(
            moment(startProposalDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffCurrentDateDeadlineNegotiationsDate = moment(new Date(), 'DD/MM/YYYY').diff(
            moment(deadlineNegotiationsDate, 'DD/MM/YYYY'),
            'days'
        );
        const diffCurrentDateEndProposalDate = moment(new Date(), 'DD/MM/YYYY').diff(
            moment(endProposalDate, 'DD/MM/YYYY'),
            'days'
        );
        if (
            diffCurrentDateStartProposalDate >= 0 &&
            diffCurrentDateDeadlineNegotiationsDate <= 0 &&
            diffCurrentDateEndProposalDate <= 0 &&
            (props.userRoles.indexOf('WETLO') !== -1 ||
                props.userRoles.indexOf('TLOOF') !== -1 ||
                props.userRoles.indexOf('EOADM') !== -1)
        ) {
            return (
                <span
                    style={{cursor: 'pointer'}}
                    onClick={() =>
                        props.editButtonCB(
                            rowObject.eoProposalNumber,
                            rowObject.cost,
                            rowObject.quantityQuality,
                            rowObject.quantityQualityIds
                        )
                    }
                    title={'Edit Answer'}
                >
                    <FontAwesomeIcon icon={faEdit as any} color={'green'} />
                </span>
            );
        }

        return <span></span>;
    };

    return (
        <React.Fragment>
            {showCustomModalV2 && imageUrl.length > 0 && (
                <ModalProposalImage
                    photoUrl={imageUrl}
                    close={() => {
                        setShowCustomModalV2(false);
                    }}
                />
            )}
            <div className={'inventoryDetailsTableArea'}>
                <CustomTable
                    version={2}
                    {...props}
                    group={props.groupHash}
                    onSelectedChange={() => {}}
                    notSortable={RESULT_FIELDS_ALL}
                    tableName={'inventoryDetails'}
                    tableType={'OBJECT'}
                    tableSource={props.inventoryDetails}
                    timestamp={Date.now()}
                    id={'eoProposalNumber'}
                    hideExcelButton={true}
                    resultFieldsAll={RESULT_FIELDS_ALL}
                    resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                    formatFunctions={{
                        cropSector: (cropSector: string, _rowObject: InventoryDetails) => {
                            return cropSector.substring(1);
                        },
                        eoProposalNumber: (eoProposalNumber: number, _rowObject: InventoryDetails) => {
                            return formatFunctionProposalNumberColumn(eoProposalNumber);
                        },
                        htmlSpeciesName: (htmlSpeciesName: string, _rowObject: InventoryDetails) => (
                            // eslint-disable-next-line react/no-danger
                            <div dangerouslySetInnerHTML={{__html: htmlSpeciesName}} />
                        ),
                        picture: (_picture: undefined, rowObject: InventoryDetails) => {
                            return formatFunctionPictureColumn(rowObject);
                        },
                        lumpSum: (lumpSum: number, _rowObject: InventoryDetails) => {
                            return formatLumpSumFields(lumpSum);
                        },
                        lumpSumOk: (lumpSumOk: number, _rowObject: InventoryDetails) => {
                            return formatLumpSumFields(lumpSumOk);
                        },
                    }}
                    intl={props.intl}
                    defaultOrder={'cropSector'}
                    reverseOrder={false}
                    count={Object.keys(props.groupHash.hash).length}
                    topCaption={getTopCaptionElement()}
                    noChangePageSize={true}
                    noShowingPageNumber={true}
                    wrapHeaders={true}
                    actions={[
                        {
                            icon: (rowObject: InventoryDetails) => getActionButton(rowObject),
                        },
                    ]}
                />
            </div>
        </React.Fragment>
    );
}
