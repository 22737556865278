import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import MaskDenominationNaktuinbouw1 from './screenshots/MaskDenominationNaktuinbouw_1.PNG';
import MaskDenominationNaktuinbouw2 from './screenshots/MaskDenominationNaktuinbouw_2.PNG';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeMaskDenominationNaktuinbouwHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>Displays an denomination input like the following: </p>
                    <img
                        src={MaskDenominationNaktuinbouw1}
                        alt="MaskDenominationNaktuinbouw"
                        className={styles.modalHelpImage}
                    />
                    <p className={styles.modalHelpParagraph}>
                        If the value of the input is not a valid denomination (e.g. contains special characters), then
                        error styles are applied to the input:
                    </p>
                    <img
                        src={MaskDenominationNaktuinbouw2}
                        alt="MaskDenominationNaktuinbouw"
                        className={styles.modalHelpImage}
                    />
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeMaskDenominationNaktuinbouwHelp;
