import React, {useState} from 'react';
import {injectIntl, IntlShape} from 'react-intl';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import CryptoJS from 'crypto-js';
import {sanitize} from '../../../../../../utils';
import moment from 'moment';
import {apiUploadClosedProcedureDocumentFile} from './UploadClosedProcedureDocumentAreaService';
import {MAX_UPLOAD_FILE_SIZE} from '../../../../../../utils/constants';
import {UploadedFileData} from '../../../../CommonComponents/ModalDocument/Interfaces/ModalDocumentInterfaces';
import styles from './UploadClosedProcedureDocumentArea.module.scss';
import {faChevronDown, faChevronRight, faPlus} from '@fortawesome/free-solid-svg-icons';
import getIcon from '../../../../../../utils/icons';
import {ModalErrorVersion2} from '../../../../../../commonModals';
import CustomTable from '../../../../../../components/CustomTable';
import InputLink from '../../../../../../components/InputLink';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import DragAndDrop from '../../../../../../components/DragAndDrop';
import AdminIcon from '../../../../CommonComponents/AdminIcon/AdminIcon';
import BrowserInfo from '../../../../../../shared/BrowserInfo';
import HeaderLoading from '../../../../../../shared/HeaderLoading';
import {History} from 'history';

const modalScreenTypes = {ERROR: 'ERROR'};

interface UploadClosedProcedureDocumentAreaProps {
    intl: IntlShape;
    history: History;
    inventoryNumber: string;
    showDownloadLinkArea: (shouldShow: boolean, fileUrl: string) => void;
}

const UploadClosedProcedureDocumentArea = (props: UploadClosedProcedureDocumentAreaProps & RouteComponentProps) => {
    const RESULT_FIELDS_FILE_TABLE_DEFAULT = ['fileName', 'size', 'fileType'];
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [uploadedDocumentList, setUploadedDocumentList] = useState<Array<UploadedFileData>>([]);
    const [notesOpened, setNotesOpened] = useState(false);

    const toggleInformation = () => setNotesOpened(!notesOpened);

    const arrayBufferToString = (buffer: ArrayBuffer) => {
        return new TextDecoder().decode(buffer);
    };

    const uploadFileToFolder = (file: Blob, inventoryNumber: string) => {
        setLoading(true);
        apiUploadClosedProcedureDocumentFile({file, inventoryNumber})
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.ok && jsonResponse.url && jsonResponse.url.length > 0)
                    props.showDownloadLinkArea(true, jsonResponse.url);
            })
            .catch(error => {
                setErrorMessage(`error uploading file: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const checkFile = (file: File) => {
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType && fileType.toLowerCase();
        const fileName = sanitize(file.name);
        if (fileTypeLowercase === 'pdf') {
            if (file.size > MAX_UPLOAD_FILE_SIZE) {
                setErrorMessage('The maximum document size for uploads is 10 MB. Please select a proper file.');
                setModalScreen(modalScreenTypes.ERROR);
            } else {
                const fileReader = new FileReader();
                fileReader.readAsBinaryString(file);
                let fileReaderResult = '';
                if (fileReader.result === null) {
                    fileReaderResult = '';
                } else if (fileReader.result instanceof ArrayBuffer) {
                    fileReaderResult = arrayBufferToString(fileReader.result);
                } else {
                    fileReaderResult = fileReader.result;
                }
                fileReader.onloadend = () => {
                    const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(fileReaderResult));
                    const documentList = uploadedDocumentList.slice(0);
                    documentList.push({
                        index,
                        file,
                        fileName,
                        size: file.size / 1024,
                        extension: fileTypeLowercase,
                        fileType: fileTypeLowercase === 'pdf' ? 'PDF' : 'WORD',
                        fileDate: moment().format('DD/MM/YYYY'),
                    });
                    setUploadedDocumentList(documentList);
                    setNotesOpened(false);
                    uploadFileToFolder(file, props.inventoryNumber);
                };
            }
        } else {
            setErrorMessage('File Type is not valid. Please try again.');
            setModalScreen(modalScreenTypes.ERROR);
        }
    };

    const handleDrop = (files: Array<File>, _event: React.ChangeEvent<HTMLInputElement>) => {
        setUploadedDocumentList([]);
        checkFile(files[0]);
    };

    const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUploadedDocumentList([]);
        if (event.target.files !== null && event.target.files.length > 0) {
            const file = event.target.files[0];
            event.target.value = '';
            checkFile(file);
        }
    };

    const getUploadFileArea = () => {
        return (
            <DragAndDrop handleDrop={handleDrop}>
                <div className={styles.uploadImageContainer}>
                    <hr />
                    {uploadedDocumentList.length > 0 && (
                        <div className={styles.fileTableArea}>
                            <CustomTable
                                {...props}
                                notSortable={RESULT_FIELDS_FILE_TABLE_DEFAULT}
                                tableName={'uploadDocument'}
                                tableType={'OBJECT'}
                                tableSource={uploadedDocumentList || []}
                                dataFilter={null}
                                id={'index'}
                                setLastCursor={null}
                                resultFieldsDefault={RESULT_FIELDS_FILE_TABLE_DEFAULT}
                                intl={props.intl}
                                formatFunctions={{
                                    fileName: (fileName: string) => <div style={{maxWidth: 380}}>{fileName}</div>,
                                    size: (size: number) => (
                                        <div className={styles.sizeColumnArea}>{`${
                                            Math.floor(size * 100) / 100
                                        } KB`}</div>
                                    ),
                                }}
                                count={(uploadedDocumentList || []).length}
                                hideExcelButton={true}
                                noChangePageSize={true}
                                timestamp={Date.now()}
                                forehandColumn={(rowObject: UploadedFileData) => (
                                    <img src={getIcon(rowObject.extension || 'pdf')} alt={rowObject.extension} />
                                )}
                            />
                        </div>
                    )}
                    <div className={styles.uploadButtonArea}>
                        <input
                            type="file"
                            name="files[]"
                            data-ng-class="isAddDocumentDisabled() ? 'disabled': ''"
                            data-ng-disabled="isAddDocumentDisabled()"
                            id="upload"
                            style={{opacity: 0, width: 0}}
                            onChange={onFileUpload}
                            accept="application/pdf"
                            disabled={uploadedDocumentList.length > 0}
                        />
                        <label htmlFor={'upload'}>
                            <FormFooterButton color={buttonColor.blue} icon={faPlus}>
                                {`Upload Document`}
                                <AdminIcon />
                            </FormFooterButton>
                        </label>
                    </div>
                    <div style={{clear: 'both'}}>{}</div>
                    <div>
                        <InputLink
                            label={`Notes`}
                            icon={notesOpened ? faChevronDown : faChevronRight}
                            clickAction={toggleInformation}
                            width={undefined}
                            offset={undefined}
                            disabled={undefined}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        {notesOpened && (
                            <BrowserInfo
                                uploadOnlyOneFile={true}
                                documentTypes={['pdf']}
                                notUpload={uploadedDocumentList.length >= 1}
                                intl={props.intl}
                                plus={undefined}
                            />
                        )}
                    </div>
                    <hr />
                    <div style={{clear: 'both'}}>{}</div>
                </div>
            </DragAndDrop>
        );
    };

    const closeErrorModal = () => {
        setModalScreen(null);
        setErrorMessage(null);
    };

    return (
        <React.Fragment>
            {modalScreen === modalScreenTypes.ERROR && (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            )}

            {getUploadFileArea()}
            {(loading && <HeaderLoading message={undefined} />) || null}
        </React.Fragment>
    );
};

export default injectIntl(withRouter(UploadClosedProcedureDocumentArea));
