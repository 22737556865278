import React, {useEffect, useState} from 'react';
import {injectIntl, IntlShape} from 'react-intl';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import {UploadedFileData} from '../../../../TLO/CommonComponents/ModalDocument/Interfaces/ModalDocumentInterfaces';
import {Tree, TreeElement} from '../../../../TLO/CommonComponents/TreeView/Interfaces/TreeViewInterfaces';
import {ErrorMessages} from '../../../../TLO/CommonInterfaces/CommonInterfaces';
import {Right} from '../../../../TLO/CommonComponents/ModalCategory/Interfaces/ModalCategoryInterfaces';
import styles from './ModalContentDocument.module.scss';
import {
    faChevronDown,
    faChevronRight,
    faExclamationCircle,
    faFileUpload,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import {sanitize} from '../../../../../utils';
import getIcon from '../../../../../utils/icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MB_50_TO_BYTES} from '../../../../../utils/constants';
import BrowserInfo from '~shared/BrowserInfo';
import {ModalAlertVersion2, ModalConfirmVersion2, ModalCustomVersion2} from '../../../../../commonModals';
import TextInput from '~components/TextInput';
import SelectInput from '~components/SelectInput';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import DateInput from '~components/DateInput';
import InputLink from '~components/InputLink';
import CustomTable from '../../../../../components/CustomTable';
import {Error} from '../../../../../componentsLayout';
import {getConcatErrorMessage} from '../../../../TLO/CommonFunctions/CommonFunctions';
import ModalContentDocumentActionButton from './ModalContentDocumentActionButtons';
import {apiCreateTree, apiUpdatefile} from '../../../CommonFunctions/CommonFunctions';
import DragAndDrop from '../../../../../components/DragAndDrop';
import {getLanguageAbbrvFromId, getLanguageIdFromAbbrv} from '../AdministrationPageContentsService';
import {History} from 'history';
import {Button} from '../../../../../componentsFormV2';

const modalScreenTypes = {
    CONFIRM_DELETE_FILE: 'CONFIRM_DELETE_FILE',
    CONFIRM_REPLACE_FILE: 'CONFIRM_REPLACE_FILE',
    OPEN_REPLACE_FILE_MODAL: 'OPEN_REPLACE_FILE_MODAL',
    WARNING_ON_DRAG_DROP: 'WARNING_ON_DRAG_DROP',
    WARNING_ON_DRAG_DROP_FILE_EXISTS: 'WARNING_ON_DRAG_DROP_FILE_EXISTS',
    WARNING_ON_DRAG_DROP_SELECT_LANGUAGE: 'WARNING_ON_DRAG_DROP_SELECT_LANGUAGE',
    UPLOADED_SUCCESSFULLY: 'UPLOADED_SUCCESSFULLY',
};

interface ModalContentDocumentProps {
    intl: IntlShape;
    history: History;
    content: TreeElement | undefined;
    contentDocumentElement: TreeElement;
    rights: Array<Right>;
    tree: Tree;
    year: string;
    section: string;
    saveContentDocument: (contentDocumentElement: TreeElement, newAddedFileUUIDs: Array<string>) => void;
    close: () => void;
}

const ModalContentDocument = (props: ModalContentDocumentProps & RouteComponentProps) => {
    const getUploadedDocumentListWithLanguage = (listType?: string) => {
        let result: Array<UploadedFileData> = [];
        const fileInEnglish = props.contentDocumentElement.file || [];
        const filesInOtherTranslations: Array<UploadedFileData> = [];
        for (const treeEl of props.contentDocumentElement.children) {
            if (treeEl.file.length > 0) {
                treeEl.file[0].languageId = treeEl.languageId;
                filesInOtherTranslations.push(treeEl.file[0]);
            }
        }

        if ((listType === 'default' || listType === 'all') && fileInEnglish.length > 0) {
            fileInEnglish[0].languageId = props.contentDocumentElement.languageId;
            result = result.concat(fileInEnglish);
        }
        if ((listType === 'translations' || listType === 'all') && filesInOtherTranslations.length > 0) {
            result = result.concat(filesInOtherTranslations);
        }

        return result;
    };
    const RESULT_FIELDS_DEFAULT = ['fileName', 'languageId', 'size', 'fileType'];
    const [loading, setLoading] = useState(false);
    const [documentId] = useState(props.contentDocumentElement.id || 0);
    const [categoryType] = useState(props.contentDocumentElement.categoryType || 0);
    const [documentName, setDocumentName] = useState(props.contentDocumentElement.title || '');
    const [content] = useState(props.content?.title || '');
    const [parentId] = useState(props.contentDocumentElement.parentId || 0);
    const [parentTitle] = useState(props.contentDocumentElement.parentTitle || '');
    const [uploadedDocumentList, setUploadedDocumentList] = useState<Array<UploadedFileData>>(
        getUploadedDocumentListWithLanguage('all')
    );
    const [uploadedDocumentListDefault, setUploadedDocumentListDefault] = useState<Array<UploadedFileData>>(
        getUploadedDocumentListWithLanguage('default')
    );
    const [uploadedDocumentListTranslations, setUploadedDocumentListTranslations] = useState<Array<UploadedFileData>>(
        getUploadedDocumentListWithLanguage('translations')
    );
    const [replaceDocumentFileList, setReplaceDocumentFileList] = useState<Array<UploadedFileData>>([]);
    const [notesOpened, setNotesOpened] = useState(false);
    const [notesTranslationsOpened, setNotesTranslationsOpened] = useState(false);
    const [notesReplaceFileOpened, setNotesReplaceFileOpened] = useState(false);
    const [lastUpdateDate] = useState(props.contentDocumentElement.lastUpdateDate || '');
    const [notShowLastUpdateDate] = useState(!props.contentDocumentElement.showLastUpdateDate || false);
    const [beginDate] = useState(props.contentDocumentElement.beginDate || '');
    const [endDate] = useState(props.contentDocumentElement.endDate || '');
    const [fileUUID, setFileUUID] = useState(
        (props.contentDocumentElement.file.length > 0 && props.contentDocumentElement.file[0].uuid) || ''
    );
    const [replaceUUID, setReplaceUUID] = useState('');
    const [selectedRightIds, setSelectedRightIds] = useState<Array<string>>(props.contentDocumentElement.rights || []);
    const [selectedRightsHash, setSelectedRightsHash] = useState<{[key: string]: boolean}>({});
    const [rights] = useState<Array<Right>>(props.rights || []);
    const [language, setLanguage] = useState<string>(getLanguageAbbrvFromId(props.contentDocumentElement.languageId));
    const [languageTranslation, setLanguageTranslation] = useState<string>(getLanguageAbbrvFromId(2));
    const [languageOptions, setLanguageOptions] = useState<Array<{id: string; value: string}>>([
        {id: '1', value: 'English'},
    ]);
    const [languageTranslationOptions, setLanguageTranslationOptions] = useState<Array<{id: string; value: string}>>([
        {id: '2', value: 'French'},
    ]);
    const [showRights, setShowRights] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [highlightErrors, setHighlightErrors] = useState(false);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [newAddedFileUUIDs, setNewAddedFileUUIDs] = useState<Array<string>>([]);
    const [translationsUploaded, setTranslationsUploaded] = useState(false);
    const [replaceFileMode, setReplaceFileMode] = useState(false);

    const updateSelectedRightsHash = (selectedRightIds: Array<string>) => {
        const rightsHash: {[key: string]: boolean} = {};
        selectedRightIds.forEach(id => {
            if (id) {
                rightsHash[id] = rights.filter(el => el.rightId === id).length > 0 ? true : false;
            }
        });
        setSelectedRightsHash(rightsHash);
    };

    const getLanguageOptions = () => {
        const langOptions: Array<{id: string; value: string}> = JSON.parse(JSON.stringify(languageOptions));
        setLanguageOptions(langOptions);
    };

    const getLanguageTranslationOptions = () => {
        const langOptions: Array<{id: string; value: string}> = JSON.parse(JSON.stringify(languageOptions));
        setLanguageOptions(langOptions);
    };

    useEffect(() => {
        updateSelectedRightsHash(selectedRightIds);
        getLanguageOptions();
        getLanguageTranslationOptions();
    }, []);

    useEffect(() => {
        if (uploadedDocumentListTranslations.length > 0) {
            setTranslationsUploaded(true);
        } else {
            setTranslationsUploaded(false);
        }
    }, [uploadedDocumentListTranslations]);

    useEffect(() => {
        replaceUUID.length > 0 && setReplaceFileMode(true);
        uploadedDocumentListDefault.forEach(el => {
            if (el.uuid === replaceUUID) {
                setReplaceDocumentFileList(uploadedDocumentListDefault);
                setLanguage(getLanguageAbbrvFromId(el.languageId).toUpperCase());
            }
        });
        uploadedDocumentListTranslations.forEach(el => {
            if (el.uuid === replaceUUID) {
                setReplaceDocumentFileList(uploadedDocumentListTranslations);
                setLanguageTranslation(getLanguageAbbrvFromId(el.languageId).toUpperCase());
            }
        });
    }, [replaceUUID]);

    useEffect(() => {
        if (uploadedDocumentListDefault.length > 0) {
            setDocumentName(uploadedDocumentListDefault[0].fileName.replace('.pdf', '').replace('.zip', ''));
        } else if (uploadedDocumentList.length > 0) {
            setDocumentName(uploadedDocumentList[0].fileName.replace('.pdf', '').replace('.zip', ''));
        } else {
            setDocumentName('');
        }
    }, [uploadedDocumentListDefault]);

    const proceedToDeleteFile = (uuid?: string) => {
        if (uuid) {
            setFileUUID(uuid);
            setModalScreen(modalScreenTypes.CONFIRM_DELETE_FILE);
        }
    };

    const proceedToReplaceFile = (uuid?: string) => {
        if (uuid) {
            setReplaceUUID(uuid);
            setModalScreen(modalScreenTypes.CONFIRM_REPLACE_FILE);
        }
    };

    const actions = ModalContentDocumentActionButton(
        proceedToDeleteFile,
        proceedToReplaceFile,
        translationsUploaded,
        replaceFileMode
    );

    const addEnglishLanguageAsOption = () => {
        const langOptions: Array<{id: string; value: string}> = JSON.parse(JSON.stringify(languageOptions));
        langOptions.push({id: '1', value: 'English'});
        setLanguageOptions(langOptions);
        setLanguage('1');
    };

    const deleteFile = () => {
        let defaultList = [];
        let translationsList = [];
        setModalScreen(null);
        setLoading(true);
        let documentsList: Array<UploadedFileData> = JSON.parse(
            JSON.stringify(uploadedDocumentListDefault.concat(uploadedDocumentListTranslations))
        );
        documentsList = documentsList.filter(el => el.uuid !== fileUUID);
        setUploadedDocumentList(documentsList);
        defaultList = documentsList.filter(el => el.languageId === 1);
        setUploadedDocumentListDefault(defaultList);
        translationsList = documentsList.filter(el => el.languageId !== 1);
        setUploadedDocumentListTranslations(translationsList);
        const isEnglishAsOption = languageOptions.some(el => el.id === 'en');
        !isEnglishAsOption && addEnglishLanguageAsOption();
        setLoading(false);
    };

    const openReplaceFileModal = () => {
        setModalScreen(modalScreenTypes.OPEN_REPLACE_FILE_MODAL);
    };

    const closeModal = () => {
        setUploadedDocumentList([]);
        setUploadedDocumentListDefault([]);
        setUploadedDocumentListTranslations([]);
        props.close && props.close();
    };

    const closeAlertModal = () => {
        setModalScreen(null);
    };

    const closeUploadedSuccessfullyModal = () => {
        setReplaceFileMode(false);
        setModalScreen(null);
    };

    const toggleInformation = () => setNotesOpened(!notesOpened);
    const toggleInformationTranslations = () => setNotesTranslationsOpened(!notesTranslationsOpened);
    const toggleInformationReplaceFile = () => setNotesReplaceFileOpened(!notesReplaceFileOpened);

    const onDocumentNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        setDocumentName(name);
    };

    const onSelectedRightsChange = (selectedRightIds: Array<string>) => {
        setSelectedRightIds(selectedRightIds);
        updateSelectedRightsHash(selectedRightIds);
    };

    const onLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const language = event.target.value;
        setLanguage(language);
    };

    const onLanguageTranslationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const language = event.target.value;
        setLanguageTranslation(language);
    };

    const onClickAddTranslation = () => {
        let langTranslationOptions: Array<{id: string; value: string}> = JSON.parse(
            JSON.stringify(languageTranslationOptions)
        );
        langTranslationOptions = langTranslationOptions.filter(el => el.id !== '1');
        setLanguageTranslationOptions(langTranslationOptions);
        setLanguageTranslation('2');
    };

    const onClickShowHideRights = () => {
        setShowRights(!showRights);
    };

    const onCancelReplaceModal = () => {
        setReplaceUUID('');
        setReplaceFileMode(false);
        setModalScreen(null);
    };

    const onCancelConfirmationReplaceModal = () => {
        onCancelReplaceModal();
    };

    const arrayBufferToString = (buffer: ArrayBuffer) => {
        return new TextDecoder().decode(buffer);
    };

    const uploadFile = (filename: string, index: any, file: Blob) => {
        setUploadedDocumentList(uploadedDocumentListDefault.concat(uploadedDocumentListTranslations));
        let defaultList = [];
        let translationsList = [];
        setLoading(true);
        apiCreateTree(
            Object.assign({}, {tree: props.tree, categoryId: props.section, year: props.year, filename, file})
        )
            .then(jsonResponse => {
                setFileUUID(jsonResponse.fileUUID);
                for (const fl of uploadedDocumentList) {
                    if (fl.index === index) {
                        fl.uuid = jsonResponse.fileUUID;
                    }
                }
                setUploadedDocumentList(uploadedDocumentList);
                newAddedFileUUIDs.push(jsonResponse.fileUUID);
                setNewAddedFileUUIDs(newAddedFileUUIDs);

                defaultList = uploadedDocumentList.filter(el => el.languageId === 1);
                setUploadedDocumentListDefault(defaultList);

                translationsList = uploadedDocumentList.filter(el => el.languageId !== 1);
                setUploadedDocumentListTranslations(translationsList);

                highlightErrors && setHighlightErrors(false);
            })
            .catch(error => {
                setErrorMessage(`error uploading file: ${error}`);
                setHighlightErrors(true);
                uploadedDocumentList.length > 0 &&
                    setUploadedDocumentList(uploadedDocumentList.filter(el => el.index !== index));
                setUploadedDocumentListDefault(uploadedDocumentList.filter(el => el.index !== index));
                setUploadedDocumentListTranslations(uploadedDocumentList.filter(el => el.index !== index));
            })
            .finally(() => setLoading(false));
    };

    const replaceFile = (index: any, file: Blob) => {
        let defaultList: any = [];
        let translationsList: any = [];
        setLoading(true);
        apiUpdatefile(Object.assign({}, {uuid: replaceUUID, file, categoryId: props.section}))
            .then(() => {
                setFileUUID(replaceUUID);
                for (const fl of uploadedDocumentList) {
                    if (fl.index === index) {
                        fl.uuid = replaceUUID;
                    }
                }
                setUploadedDocumentList(uploadedDocumentList);
                newAddedFileUUIDs.push(replaceUUID);
                setNewAddedFileUUIDs(newAddedFileUUIDs);

                defaultList = uploadedDocumentList.filter(el => el.languageId === 1);
                setUploadedDocumentListDefault(defaultList);

                translationsList = uploadedDocumentList.filter(el => el.languageId !== 1);
                setUploadedDocumentListTranslations(translationsList);

                defaultList.forEach((el: any) => {
                    if (el.uuid === replaceUUID) {
                        setReplaceDocumentFileList(defaultList);
                    }
                });
                translationsList.forEach((el: any) => {
                    if (el.uuid === replaceUUID) {
                        setReplaceDocumentFileList(translationsList);
                    }
                });

                highlightErrors && setHighlightErrors(false);

                setModalScreen(modalScreenTypes.UPLOADED_SUCCESSFULLY);
            })
            .catch((error: string) => {
                setErrorMessage(`error uploading file: ${error}`);
                setHighlightErrors(true);
                uploadedDocumentList.length > 0 &&
                    setUploadedDocumentList(uploadedDocumentList.filter(el => el.index !== index));
                setUploadedDocumentListDefault(uploadedDocumentList.filter(el => el.index !== index));
                setUploadedDocumentListTranslations(uploadedDocumentList.filter(el => el.index !== index));
            })
            .finally(() => setLoading(false));
    };

    const checkFile = (file: File, buttonId: string = '') => {
        setUploadedDocumentList(uploadedDocumentListDefault.concat(uploadedDocumentListTranslations));
        const replaceFileTypeArray = replaceUUID.split('.');
        const replaceFileType = replaceFileTypeArray.length > 0 ? replaceFileTypeArray[1] : '';

        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType && fileType.toLowerCase();
        const fileName = sanitize(file.name);
        if (replaceFileMode && fileTypeLowercase !== replaceFileType) {
            setErrorMessage('New File Type is not the same as existing File Type. Please try again.');
            setHighlightErrors(true);
        } else {
            if (fileTypeLowercase === 'pdf' || fileTypeLowercase?.includes('zip')) {
                if (file.size > MB_50_TO_BYTES) {
                    setErrorMessage('The maximum document size for uploads is 50 MB. Please select a proper file.');
                    setHighlightErrors(true);
                } else {
                    const fileReader = new FileReader();
                    fileReader.readAsBinaryString(file);
                    let fileReaderResult = '';
                    if (fileReader.result === null) {
                        fileReaderResult = '';
                    } else if (fileReader.result instanceof ArrayBuffer) {
                        fileReaderResult = arrayBufferToString(fileReader.result);
                    } else {
                        fileReaderResult = fileReader.result;
                    }
                    fileReader.onloadend = () => {
                        const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(fileReaderResult));
                        let languageReplaceDoc = language;
                        uploadedDocumentList.forEach((el, index) => {
                            if (el.uuid === replaceUUID) {
                                uploadedDocumentList.splice(index, 1).pop();
                                languageReplaceDoc = String(el.languageId);
                            }
                        });
                        let languageSelected = language;
                        switch (buttonId) {
                            case 'uploadTranslations': {
                                languageSelected = languageTranslation;
                                break;
                            }
                            case 'replaceFile': {
                                languageSelected = languageReplaceDoc;
                                break;
                            }
                            default: {
                                languageSelected = language;
                                break;
                            }
                        }
                        uploadedDocumentList.push({
                            index,
                            file,
                            fileName,
                            size: file.size / 1024,
                            extension: fileTypeLowercase,
                            fileType: fileTypeLowercase === 'pdf' ? 'PDF' : 'ZIP',
                            fileDate: moment().format('DD/MM/YYYY'),
                            languageId: getLanguageIdFromAbbrv(languageSelected),
                        });
                        setUploadedDocumentList(uploadedDocumentList);
                        setDocumentName(
                            uploadedDocumentListDefault.length > 0
                                ? uploadedDocumentListDefault[0].fileName.replace('.pdf', '').replace('.zip', '')
                                : uploadedDocumentList[0].fileName.replace('.pdf', '').replace('.zip', '')
                        );
                        setNotesOpened(false);
                        if (replaceFileMode) {
                            fileName.length > 0 && uploadedDocumentList.length > 0 && replaceFile(index, file);
                        } else {
                            fileName.length > 0 && uploadedDocumentList.length > 0 && uploadFile(fileName, index, file);
                        }
                    };
                }
            } else {
                setErrorMessage(`File Type (${fileType}) is not valid. Please try again.`);
                setHighlightErrors(true);
            }
        }
    };

    const handleDrop = (files: Array<File>, _event: React.ChangeEvent<HTMLInputElement>) => {
        if (files.length > 1) {
            setModalScreen(modalScreenTypes.WARNING_ON_DRAG_DROP);
        } else {
            for (const file of files) {
                if (replaceFileMode) {
                    checkFile(file, 'replaceFile');
                } else {
                    if (uploadedDocumentListDefault.length === 1 && uploadedDocumentListTranslations.length === 1) {
                        setModalScreen(modalScreenTypes.WARNING_ON_DRAG_DROP_FILE_EXISTS);
                    } else {
                        if (uploadedDocumentListDefault.length === 0) {
                            checkFile(file, 'upload');
                        } else if (
                            uploadedDocumentListDefault.length === 1 &&
                            uploadedDocumentListTranslations.length === 0 &&
                            languageTranslation === '2'
                        ) {
                            checkFile(file, 'uploadTranslations');
                        } else {
                            setModalScreen(modalScreenTypes.WARNING_ON_DRAG_DROP_SELECT_LANGUAGE);
                        }
                    }
                }
            }
        }
    };

    const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length > 0) {
            const files = event.target.files;
            for (let i = 0; i < files.length; i++) {
                checkFile(files[i], event.target.id);
            }
            event.target.value = '';
        }
    };

    const prepareDocumentDataForSave = (): TreeElement => {
        setUploadedDocumentList(uploadedDocumentListDefault.concat(uploadedDocumentListTranslations));
        return {
            id: documentId,
            order: props.contentDocumentElement.order || '0',
            documentCode: '',
            title: documentName,
            lastUpdateDate,
            beginDate,
            endDate,
            categoryType,
            rights: selectedRightIds,
            file:
                uploadedDocumentList.length > 0
                    ? [
                          {
                              uuid: uploadedDocumentListDefault[0].uuid,
                              fileName: uploadedDocumentListDefault[0].fileName,
                              extension: uploadedDocumentListDefault[0].extension,
                              size: uploadedDocumentListDefault[0].size,
                              fileType: uploadedDocumentListDefault[0].fileType,
                              fileDate: uploadedDocumentListDefault[0].fileDate,
                              documentCode: '',
                          },
                      ]
                    : [],
            level: props.contentDocumentElement.level || 1,
            parentId,
            parentTitle,
            isCategory: false,
            isContent: false,
            isContentItem: false,
            languageId: uploadedDocumentList.length > 0 ? uploadedDocumentListDefault[0].languageId : 0,
            showLastUpdateDate: !notShowLastUpdateDate,
            children:
                uploadedDocumentList.length > 1
                    ? [
                          {
                              id: documentId + 1,
                              order:
                                  props.contentDocumentElement.order +
                                      '.' +
                                      getLanguageAbbrvFromId(
                                          uploadedDocumentListTranslations[0].languageId
                                      ).toUpperCase() || '0',
                              documentCode: '',
                              title: documentName,
                              lastUpdateDate,
                              beginDate,
                              endDate,
                              categoryType,
                              rights: selectedRightIds,
                              file: [
                                  {
                                      uuid: uploadedDocumentListTranslations[0].uuid,
                                      fileName: uploadedDocumentListTranslations[0].fileName,
                                      extension: uploadedDocumentListTranslations[0].extension,
                                      size: uploadedDocumentListTranslations[0].size,
                                      fileType: uploadedDocumentListTranslations[0].fileType,
                                      fileDate: uploadedDocumentListTranslations[0].fileDate,
                                      documentCode: '',
                                  },
                              ],
                              level: props.contentDocumentElement.level + 1 || 1,
                              parentId: documentId,
                              parentTitle: documentName,
                              isCategory: false,
                              isContent: false,
                              isContentItem: false,
                              languageId: uploadedDocumentListTranslations[0].languageId,
                              showLastUpdateDate: !notShowLastUpdateDate,
                              children: [],
                          },
                      ]
                    : [],
        };
    };

    const getErrorMessagesForSave = () => {
        setHighlightErrors(false);
        const errorMessages: ErrorMessages = {
            errorInput: [],
        };

        if (documentName.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a name for the document');
        }

        return errorMessages;
    };

    const saveDocument = () => {
        const errorMessagesObj = getErrorMessagesForSave();
        let errorMessage = getConcatErrorMessage(errorMessagesObj);

        if (errorMessage.length > 0) {
            setErrorMessage(errorMessage);
            setHighlightErrors(true);
        }

        if (errorMessage.length === 0) {
            const documentData = prepareDocumentDataForSave();
            props.saveContentDocument && props.saveContentDocument(documentData, newAddedFileUUIDs);
        }
    };

    const getUploadDefaultDocumentArea = () => {
        return (
            <React.Fragment>
                <div className={styles.showHideRightsButtonArea + ' ' + styles.inputFieldsArea}>
                    <SelectInput
                        value={language}
                        onChange={onLanguageChange}
                        list={languageOptions}
                        label={'Language'}
                        notAll={true}
                        // disabled={languageOptions.length === 1 || language === '1'}
                        disabled={true}
                    />
                    <div
                        className={
                            uploadedDocumentListDefault.length > 0 ? styles.uploadedButtonArea : styles.uploadButtonArea
                        }
                    >
                        <input
                            type="file"
                            name="files[]"
                            data-ng-class="isAddDocumentDisabled() ? 'disabled': ''"
                            data-ng-disabled="isAddDocumentDisabled()"
                            id="upload"
                            style={{opacity: 0, width: 0}}
                            onChange={onFileUpload}
                            accept="application/pdf, application/zip, application/x-zip-compressed, multipart/x-zip"
                            disabled={
                                uploadedDocumentListDefault.length === 1 ||
                                (language === '1' && uploadedDocumentListDefault.length === 1)
                            }
                        />
                        <label htmlFor={'upload'}>
                            <FormFooterButton
                                color={buttonColor.blue}
                                icon={faFileUpload}
                                disabled={
                                    uploadedDocumentListDefault.length > 0 ||
                                    (language === '1' && uploadedDocumentListDefault.length === 1)
                                }
                            >
                                {`Upload File`}
                            </FormFooterButton>
                        </label>
                    </div>
                    <div style={{clear: 'both'}}>{}</div>
                    <hr />
                    {uploadedDocumentListDefault.length > 0 && (
                        <div className={styles.fileTableArea}>
                            <CustomTable
                                {...props}
                                notSortable={RESULT_FIELDS_DEFAULT}
                                tableName={'uploadContentDocument'}
                                tableType={'OBJECT'}
                                tableSource={uploadedDocumentListDefault || []}
                                dataFilter={null}
                                id={'fileName'}
                                setLastCursor={null}
                                resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                                intl={props.intl}
                                formatFunctions={{
                                    fileName: (fileName: string) => <div style={{maxWidth: 380}}>{fileName}</div>,
                                    languageId: (languageId: number) => (
                                        <div>{getLanguageAbbrvFromId(languageId).toUpperCase()}</div>
                                    ),
                                    size: (size: number) => (
                                        <div className={styles.sizeColumnArea}>{`${
                                            Math.floor(size * 100) / 100
                                        } KB`}</div>
                                    ),
                                }}
                                count={(uploadedDocumentListDefault || []).length}
                                hideExcelButton={true}
                                noChangePageSize={true}
                                timestamp={Date.now()}
                                actions={actions}
                                forehandColumn={(rowObject: UploadedFileData) => (
                                    <img src={getIcon(rowObject.extension || 'pdf')} alt={rowObject.extension} />
                                )}
                            />
                        </div>
                    )}
                    <div>
                        <InputLink
                            label={`Notes`}
                            icon={notesOpened ? faChevronDown : faChevronRight}
                            clickAction={toggleInformation}
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        {notesOpened && (
                            <BrowserInfo
                                documentTypes={['pdf', 'zip']}
                                notUpload={uploadedDocumentListDefault.length >= 1}
                                intl={props.intl}
                                plus={undefined}
                            />
                        )}
                    </div>
                    <hr />
                    <div style={{clear: 'both'}}>{}</div>
                </div>
            </React.Fragment>
        );
    };

    const getFieldsArea = () => {
        return (
            <React.Fragment>
                <div className={styles.showHideRightsButtonArea + ' ' + styles.inputFieldsArea}>
                    <TextInput value={content} label={'Content'} disabled={true} />
                    <TextInput value={parentTitle} label={'Content Item'} disabled={true} />
                    <div style={{clear: 'both'}}>{}</div>
                    <TextInput value={documentName} onChange={onDocumentNameChange} label={'Document Name'} />
                    <DateInput
                        changeDateFrom={undefined}
                        inputValueFrom={lastUpdateDate}
                        label={'Last update'}
                        simple={true}
                        disabled={true}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <hr />
                </div>
            </React.Fragment>
        );
    };

    const getUploadDocumentArea = () => {
        return (
            <React.Fragment>
                {uploadedDocumentListDefault.length >= 1 && (
                    <div
                        className={
                            uploadedDocumentListTranslations.length > 0
                                ? styles.uploadedFileContainer
                                : styles.uploadFileContainer
                        }
                    >
                        <hr />
                        <h2>{'Translations'}</h2>
                        {uploadedDocumentListDefault.length === 1 && languageTranslation === '2' && (
                            <SelectInput
                                value={languageTranslation}
                                onChange={onLanguageTranslationChange}
                                list={languageTranslationOptions}
                                label={'Language'}
                                notAll={true}
                                disabled={
                                    uploadedDocumentListTranslations.length > 0 ||
                                    languageTranslationOptions.length === 1 ||
                                    languageTranslation === '2'
                                }
                            />
                        )}
                        {uploadedDocumentListDefault.length === 1 &&
                            languageTranslationOptions.length > 0 &&
                            uploadedDocumentListTranslations.length < 1 &&
                            languageTranslation !== '2' && (
                                <div className={styles.addTranslationButtonArea + ' ' + buttonColor.whiteGreen}>
                                    <label htmlFor={'addTrannslation'}>
                                        <FormFooterButton icon={faPlus} clickAction={onClickAddTranslation}>
                                            {`Add Translation`}
                                        </FormFooterButton>
                                    </label>
                                </div>
                            )}
                        <div style={{clear: 'both'}}>{}</div>
                        <hr />
                        {uploadedDocumentListTranslations.length > 0 && (
                            <div className={styles.fileTableArea}>
                                <CustomTable
                                    {...props}
                                    notSortable={RESULT_FIELDS_DEFAULT}
                                    tableName={'uploadContentDocumentTranslations'}
                                    tableType={'OBJECT'}
                                    tableSource={uploadedDocumentListTranslations || []}
                                    dataFilter={null}
                                    id={'fileName'}
                                    setLastCursor={null}
                                    resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                                    intl={props.intl}
                                    formatFunctions={{
                                        fileName: (fileName: string) => <div style={{maxWidth: 380}}>{fileName}</div>,
                                        languageId: (languageId: number) => (
                                            <div>{getLanguageAbbrvFromId(languageId).toUpperCase()}</div>
                                        ),
                                        size: (size: number) => (
                                            <div className={styles.sizeColumnArea}>{`${
                                                Math.floor(size * 100) / 100
                                            } KB`}</div>
                                        ),
                                    }}
                                    count={(uploadedDocumentListTranslations || []).length}
                                    hideExcelButton={true}
                                    noChangePageSize={true}
                                    timestamp={Date.now()}
                                    actions={actions}
                                    forehandColumn={(rowObject: UploadedFileData) => (
                                        <img src={getIcon(rowObject.extension || 'pdf')} alt={rowObject.extension} />
                                    )}
                                />
                            </div>
                        )}
                        {uploadedDocumentListDefault.length === 1 && languageTranslation === '2' && (
                            <div>
                                <div
                                    className={
                                        uploadedDocumentListTranslations.length > 0
                                            ? styles.uploadedButtonArea
                                            : styles.uploadButtonArea
                                    }
                                >
                                    <input
                                        type="file"
                                        name="files[]"
                                        data-ng-class="isAddDocumentDisabled() ? 'disabled': ''"
                                        data-ng-disabled="isAddDocumentDisabled()"
                                        id="uploadTranslations"
                                        style={{opacity: 0, width: 0}}
                                        onChange={onFileUpload}
                                        accept="application/pdf, application/zip"
                                        disabled={
                                            uploadedDocumentListTranslations.length === 1 ||
                                            (languageTranslation === '2' &&
                                                uploadedDocumentListTranslations.length === 1)
                                        }
                                    />
                                    <label htmlFor={'uploadTranslations'}>
                                        <FormFooterButton
                                            color={buttonColor.blue}
                                            icon={faFileUpload}
                                            disabled={uploadedDocumentListTranslations.length > 0}
                                        >
                                            {`Upload File`}
                                        </FormFooterButton>
                                    </label>
                                </div>
                                <div style={{clear: 'both'}}>{}</div>
                                <div>
                                    <InputLink
                                        label={`Notes`}
                                        icon={notesTranslationsOpened ? faChevronDown : faChevronRight}
                                        clickAction={toggleInformationTranslations}
                                    />
                                    <div style={{clear: 'both'}}>{}</div>
                                    {notesTranslationsOpened && (
                                        <BrowserInfo
                                            documentTypes={['pdf', 'zip']}
                                            notUpload={uploadedDocumentListTranslations.length > 0}
                                            intl={props.intl}
                                            plus={undefined}
                                        />
                                    )}
                                </div>
                                <div style={{clear: 'both'}}>{}</div>
                                <hr />
                            </div>
                        )}
                    </div>
                )}
            </React.Fragment>
        );
    };

    const getRightsTableArea = () => {
        return (
            <React.Fragment>
                <div className={styles.showHideRightsButtonArea}>
                    <FormFooterButton color={buttonColor.whiteGreen} clickAction={onClickShowHideRights}>
                        {`${showRights ? 'Hide' : 'Show'} Rights`}
                    </FormFooterButton>
                </div>
                <div style={{clear: 'both'}}>{}</div>
                {showRights && (
                    <CustomTable
                        {...props}
                        version={1}
                        tableName={'rights'}
                        tableType={'OBJECT'}
                        tableSource={rights}
                        id={'rightId'}
                        timestamp={Date.now()}
                        selectable={true}
                        onSelectedChange={onSelectedRightsChange}
                        selectDefault={selectedRightsHash}
                        hideExcelButton={true}
                        noChangePageSize={true}
                        resultFieldsDefault={['description']}
                        intl={props.intl}
                        defaultOrder={'description'}
                        reverseOrder={false}
                        count={rights.length}
                    />
                )}
            </React.Fragment>
        );
    };

    return (
        <DragAndDrop handleDrop={handleDrop}>
            {modalScreen === modalScreenTypes.CONFIRM_DELETE_FILE && (
                <ModalConfirmVersion2
                    title={`Delete File`}
                    message={`Are you sure you want to delete this file?`}
                    buttonName={'Delete'}
                    action={deleteFile}
                    close={() => setModalScreen(null)}
                />
            )}
            {modalScreen === modalScreenTypes.CONFIRM_REPLACE_FILE && (
                <ModalConfirmVersion2
                    title={`Replace File`}
                    message={`Are you sure you want to replace this file?`}
                    buttonName={'Replace'}
                    action={openReplaceFileModal}
                    close={onCancelConfirmationReplaceModal}
                />
            )}
            {modalScreen === modalScreenTypes.OPEN_REPLACE_FILE_MODAL && (
                <div className="modalContentDocumentReplace">
                    <ModalCustomVersion2
                        loading={loading}
                        close={() => setModalScreen(null)}
                        header={`Replace File`}
                        body={
                            <div>
                                {
                                    <React.Fragment>
                                        <div>
                                            <input
                                                type="file"
                                                name="files[]"
                                                data-ng-class="isAddDocumentDisabled() ? 'disabled': ''"
                                                data-ng-disabled="isAddDocumentDisabled()"
                                                id="replaceFile"
                                                style={{opacity: 0, width: 0}}
                                                onChange={onFileUpload}
                                                accept="application/pdf, application/zip"
                                                disabled={undefined}
                                            />
                                            <label htmlFor={'replaceFile'}>
                                                <FormFooterButton
                                                    color={buttonColor.blue}
                                                    icon={faFileUpload}
                                                    float={'left'}
                                                >
                                                    {`Upload File`}
                                                </FormFooterButton>
                                            </label>
                                        </div>
                                        <div style={{clear: 'both'}}>{}</div>
                                        <hr />
                                        {replaceDocumentFileList.length > 0 && (
                                            <div className={styles.fileTableArea}>
                                                <CustomTable
                                                    {...props}
                                                    notSortable={RESULT_FIELDS_DEFAULT}
                                                    tableName={'replaceDocumentFileTable'}
                                                    tableType={'OBJECT'}
                                                    tableSource={replaceDocumentFileList || []}
                                                    dataFilter={null}
                                                    id={'fileName'}
                                                    setLastCursor={null}
                                                    resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                                                    intl={props.intl}
                                                    formatFunctions={{
                                                        fileName: (fileName: string) => (
                                                            <div style={{maxWidth: 380}}>{fileName}</div>
                                                        ),
                                                        languageId: (languageId: number) => (
                                                            <div>
                                                                {getLanguageAbbrvFromId(languageId).toUpperCase()}
                                                            </div>
                                                        ),
                                                        size: (size: number) => (
                                                            <div className={styles.sizeColumnArea}>{`${
                                                                Math.floor(size * 100) / 100
                                                            } KB`}</div>
                                                        ),
                                                    }}
                                                    count={(replaceDocumentFileList || []).length}
                                                    hideExcelButton={true}
                                                    noChangePageSize={true}
                                                    timestamp={Date.now()}
                                                    actions={actions}
                                                    forehandColumn={(rowObject: UploadedFileData) => (
                                                        <img
                                                            src={getIcon(rowObject.extension || 'pdf')}
                                                            alt={rowObject.extension}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )}
                                        <div style={{clear: 'both'}}>{}</div>
                                        <div>
                                            <InputLink
                                                label={`Notes`}
                                                icon={notesReplaceFileOpened ? faChevronDown : faChevronRight}
                                                clickAction={toggleInformationReplaceFile}
                                            />
                                            <div style={{clear: 'both'}}>{}</div>
                                            {notesReplaceFileOpened && (
                                                <BrowserInfo
                                                    documentTypes={['pdf', 'zip']}
                                                    notUpload={uploadedDocumentListTranslations.length > 0}
                                                    intl={props.intl}
                                                />
                                            )}
                                        </div>
                                        <div style={{clear: 'both'}}>{}</div>
                                        <hr />
                                    </React.Fragment>
                                }
                            </div>
                        }
                        footer={
                            <>
                                {highlightErrors ? (
                                    <Error>
                                        <FontAwesomeIcon icon={faExclamationCircle as any} color={'white'} />
                                        {` ${errorMessage}`}
                                    </Error>
                                ) : null}
                                <Button
                                    clickAction={onCancelReplaceModal}
                                    icon={'close'}
                                    variation={'danger'}
                                >{`Close`}</Button>
                            </>
                        }
                    />
                </div>
            )}
            {modalScreen === modalScreenTypes.WARNING_ON_DRAG_DROP && (
                <ModalAlertVersion2
                    title={`Information`}
                    message={`You can drag and drop only one document file.`}
                    close={closeAlertModal}
                />
            )}
            {modalScreen === modalScreenTypes.WARNING_ON_DRAG_DROP_FILE_EXISTS && (
                <ModalAlertVersion2
                    title={`Information`}
                    message={`Already exist files for all the languages.`}
                    close={closeAlertModal}
                />
            )}
            {modalScreen === modalScreenTypes.WARNING_ON_DRAG_DROP_SELECT_LANGUAGE && (
                <ModalAlertVersion2
                    title={`Information`}
                    message={`You have to select a translation language first.`}
                    close={closeAlertModal}
                />
            )}
            {modalScreen === modalScreenTypes.UPLOADED_SUCCESSFULLY && (
                <ModalAlertVersion2
                    title={`Information`}
                    message={`Uploaded successfully.`}
                    close={closeUploadedSuccessfullyModal}
                />
            )}
            <div className="modalContentDocument">
                <ModalCustomVersion2
                    loading={loading}
                    close={closeModal}
                    header={`Content Document`}
                    body={
                        <div>
                            {getUploadDefaultDocumentArea()}
                            {getFieldsArea()}
                            {getUploadDocumentArea()}
                            {getRightsTableArea()}
                        </div>
                    }
                    footer={
                        <>
                            {highlightErrors ? (
                                <Error>
                                    <FontAwesomeIcon icon={faExclamationCircle as any} color={'white'} />
                                    {` ${errorMessage}`}
                                </Error>
                            ) : null}
                            <Button clickAction={props.close} icon={'close'} variation={'danger'}>{`Cancel`}</Button>
                            <Button clickAction={saveDocument} icon={'arrowRight'}>{`Save Document`}</Button>
                        </>
                    }
                />
            </div>
        </DragAndDrop>
    );
};

export default injectIntl(withRouter(ModalContentDocument));
