import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEuroSign, faPencilAlt, faPrint, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {faFileAlt, faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import styles from '../OnlineApplications.module.scss';
import {API_SERVERLESS, getFetch} from '~commonApi';
import stylesCustomTable from './OnlineApplicationsCustomTableActionButtons.module.scss';
import {Popover} from 'react-tiny-popover';
import {saveAs} from 'file-saver';
import {getPreSignedURLFetchRequest} from '../../../utils/requests';
import {IApplicationSearchItem} from '../../../types';
import {checkIfApplicationHasObsoleteForms} from '../OnlineApplications';

function apiOnlineApplicationAttachments({applicationRequestId}: any = {}) {
    return getFetch(
        `${API_SERVERLESS}/online/v3/applicationRequestSignApplicationDetails?applicationRequestId=${applicationRequestId}`,
        true
    );
}

function apiDocumentDownload({filename, applicationRequestCode}: any = {}) {
    return getFetch(
        `${API_SERVERLESS}/online/v3/attachmentDownload/${applicationRequestCode}/${encodeURIComponent(filename)}`,
        true
    );
}

function downloadFile({filename, applicationRequestCode}: any = {}) {
    apiDocumentDownload({filename, applicationRequestCode}).then((response: any) => {
        if (response && response.signedUrl) {
            const {signedUrl} = response;
            getPreSignedURLFetchRequest(signedUrl)
                .then(response => response.blob())
                .then(responseBlob => {
                    saveAs(responseBlob, filename);
                });
        }
    });
}

const ICON_COLOR_DISABLED = '#c5c5c5';
const ICON_COLOR_ENABLED = '#2f923f';

function renderIconEdit(onTableEditClick: any) {
    const iconEdit = faPencilAlt;
    const checkIfApplicationCanBeEdited = ({signatureDate}: any) => {
        return !signatureDate;
    };
    return {
        title: null,
        icon: ({signatureDate}: any) => {
            let applicationCanBeEdited = checkIfApplicationCanBeEdited({signatureDate});
            return (
                <div className={stylesCustomTable.iconWrap}>
                    <div className={stylesCustomTable.hoverMessage}>
                        {applicationCanBeEdited
                            ? `Modify this application`
                            : `Application is already signed and cannot be edited`}
                    </div>
                    <FontAwesomeIcon
                        icon={iconEdit as any}
                        color={applicationCanBeEdited ? ICON_COLOR_ENABLED : ICON_COLOR_DISABLED}
                    />
                </div>
            );
        },
        handler: (_: any, tableRowObject: any) => {
            const {signatureDate} = tableRowObject || {};
            return checkIfApplicationCanBeEdited({signatureDate}) ? onTableEditClick(tableRowObject) : null;
        },
    };
}

const RenderIconFC = ({rowObject}: any) => {
    const {applicationRequestId} = rowObject || {};
    const iconPrint = faPrint;
    const [attachments, setAttachments] = useState<any>(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState<any>(null);
    const [loading, setLoading] = useState<any>(null);

    useEffect(() => {
        setLoading(true);
    }, []);

    const fetchApplicationAttachments = async () => {
        const jsonResponse = await apiOnlineApplicationAttachments({applicationRequestId});
        setAttachments((jsonResponse && jsonResponse.documents) || null);
        setLoading(false);
    };

    const onPrintIconClick = () => {
        if (checkIfApplicationHasObsoleteForms(rowObject)) {
            return undefined;
        }
        if (applicationRequestId) {
            fetchApplicationAttachments();
            setIsPopoverOpen(true);
        }
    };

    const onDownloadFileClick = (file: any) => {
        const {type, filename, verificationCode} = file || {};
        const {applicationRequestCode} = rowObject || {};
        if (type === 'AF' || type === 'TQ') {
            let onlinePdfURL = `/onlinePdf?vc=${verificationCode}`;
            window.open(onlinePdfURL, '_blank');
        } else {
            downloadFile({filename, applicationRequestCode});
        }
    };

    const customContent = loading ? (
        <div className={stylesCustomTable.spinner}>
            <FontAwesomeIcon icon={faSpinner as any} color="green" />
        </div>
    ) : attachments && attachments.length ? (
        attachments.map((el: any) => {
            const {filenameDisplay} = el || {};
            return (
                <div className={styles.customContentItem} key={filenameDisplay} onClick={() => onDownloadFileClick(el)}>
                    {filenameDisplay}
                </div>
            );
        })
    ) : (
        <div className={styles.customContentItemNoAttachments}>No attachments found for this application request</div>
    );
    return (
        <div className={cx(stylesCustomTable.iconWrap, isPopoverOpen ? stylesCustomTable.popoverIsOpen : null)}>
            <div className={stylesCustomTable.hoverMessage}>
                {`PDF files and joint attachment(s) ready to download`}
            </div>
            <Popover
                isOpen={isPopoverOpen}
                positions={'top'}
                onClickOutside={() => setIsPopoverOpen(false)}
                content={() => (
                    <div style={{width: 500, background: 'white', border: '1px solid #2f923f', borderRadius: 5}}>
                        {customContent}
                    </div>
                )}
            >
                <div onClick={() => onPrintIconClick()}>
                    <FontAwesomeIcon icon={iconPrint as any} color={ICON_COLOR_ENABLED} />
                </div>
            </Popover>
        </div>
    );
};

function renderIconPrint(onTableIconPrintClick: any) {
    return {
        title: null,
        icon: (rowObject: any) => <RenderIconFC rowObject={rowObject} />,
        handler: (_: any, rowObject: IApplicationSearchItem) => onTableIconPrintClick(rowObject),
    };
}

// function renderIconDuplicate(onTableDuplicateClick: any) {
//     const iconDuplicate = faCopy;
//     const checkIfApplicationCanBeDuplicate = ({signatureDate}: any) => {
//         if (signatureDate) {
//             let today = new Date();
//             let signDatePlusOneYear = new Date(signatureDate);
//             signDatePlusOneYear.setMonth(signDatePlusOneYear.getMonth() + 12);
//             return today <= signDatePlusOneYear;
//         }
//         return true;
//     };
//     return {
//         title: null,
//         icon: ({signatureDate}: any) => {
//             let applicationCanBeDuplicate = checkIfApplicationCanBeDuplicate({signatureDate});
//             return (
//                 <div className={stylesCustomTable.iconWrap}>
//                     <div className={stylesCustomTable.hoverMessage}>
//                         {applicationCanBeDuplicate
//                             ? `Duplicate`
//                             : `Applications can be duplicated or copied for reuse maximum one year after their initial signature date.`}
//                     </div>
//                     <FontAwesomeIcon
//                         icon={iconDuplicate as any}
//                         color={applicationCanBeDuplicate ? ICON_COLOR_ENABLED : ICON_COLOR_DISABLED}
//                     />
//                 </div>
//             );
//         },
//         handler: (formId: any, {applicationRequestId, signatureDate}: any) => {
//             return checkIfApplicationCanBeDuplicate({signatureDate})
//                 ? onTableDuplicateClick(applicationRequestId)
//                 : null;
//         },
//     };
// }

// function renderIconRemedy(onTableRemedyClick: any) {
//     const checkIfApplicationCanBeRemedy = ({signatureDate}: any) => {
//         return !!signatureDate;
//     };
//     return {
//         title: null,
//         icon: ({signatureDate}: any) => {
//             let applicationCanBeRemedy = checkIfApplicationCanBeRemedy({signatureDate});
//             return (
//                 <div className={stylesCustomTable.iconWrap}>
//                     <div className={stylesCustomTable.hoverMessage}>
//                         {applicationCanBeRemedy ? `Remedy` : `Only signed applications can be remedy.`}
//                     </div>
//                     <FontAwesomeIcon
//                         icon={faExclamation as any}
//                         color={applicationCanBeRemedy ? ICON_COLOR_ENABLED : ICON_COLOR_DISABLED}
//                     />
//                 </div>
//             );
//         },
//         handler: (_: any, rowObject: any) => {
//             const {signatureDate} = rowObject || {};
//             return checkIfApplicationCanBeRemedy({signatureDate}) ? onTableRemedyClick(rowObject) : null;
//         },
//     };
// }

// function renderIconDataRequest(onTableDataRequestClick: any) {
//     const checkIfUserCanRequestData = ({signatureDate}: any) => {
//         return !signatureDate;
//     };
//     return {
//         title: null,
//         icon: ({signatureDate}: any) => {
//             let canRequestData = checkIfUserCanRequestData({signatureDate});
//             return (
//                 <div className={stylesCustomTable.iconWrap}>
//                     <div className={stylesCustomTable.hoverMessage}>
//                         {canRequestData ? `Data request` : `Only for draft applications a data request can be made.`}
//                     </div>
//                     <FontAwesomeIcon
//                         icon={faInfoCircle as any}
//                         color={canRequestData ? ICON_COLOR_ENABLED : ICON_COLOR_DISABLED}
//                     />
//                 </div>
//             );
//         },
//         handler: (_: any, rowObject: any) => {
//             const {signatureDate} = rowObject || {};
//             return checkIfUserCanRequestData({signatureDate}) ? onTableDataRequestClick(rowObject) : null;
//         },
//     };
// }

function renderIconCopy(copyAction: any) {
    const iconCopy = faFileAlt;
    const checkIfApplicationCanBeCopied = ({signatureDate}: any) => {
        if (signatureDate) {
            let today = new Date();
            let signDatePlusTwoYears = new Date(signatureDate);
            signDatePlusTwoYears.setMonth(signDatePlusTwoYears.getMonth() + 24);
            return today <= signDatePlusTwoYears;
        }
        return true;
    };
    return {
        title: null,
        icon: ({signatureDate}: any) => {
            let applicationCanBeCopied = checkIfApplicationCanBeCopied({signatureDate});
            return (
                <div className={stylesCustomTable.iconWrap}>
                    <div className={stylesCustomTable.hoverMessage}>
                        {applicationCanBeCopied
                            ? `Duplicate`
                            : `Applications can be duplicated or copied for reuse maximum two years after their initial signature date.`}
                    </div>
                    <FontAwesomeIcon
                        icon={iconCopy as any}
                        color={applicationCanBeCopied ? ICON_COLOR_ENABLED : ICON_COLOR_DISABLED}
                    />
                </div>
            );
        },
        handler: (formId: any, rowObject: IApplicationSearchItem) => {
            return checkIfApplicationCanBeCopied({signatureDate: rowObject.signatureDate})
                ? copyAction(rowObject.applicationRequestId, rowObject)
                : null;
        },
    };
}

function renderIconDelete(onTableDeleteClick: any) {
    const iconDelete = faTrashAlt;
    const checkIfApplicationCanBeDeleted = ({signatureDate}: any) => {
        return !signatureDate;
    };
    return {
        title: null,
        icon: ({signatureDate}: any) => {
            let applicationCanBeDeleted = checkIfApplicationCanBeDeleted({signatureDate});
            return (
                <div className={stylesCustomTable.iconWrap}>
                    <div className={stylesCustomTable.hoverMessage}>
                        {applicationCanBeDeleted ? `Delete` : `Cannot be deleted`}
                    </div>
                    <FontAwesomeIcon
                        icon={iconDelete as any}
                        color={applicationCanBeDeleted ? ICON_COLOR_ENABLED : ICON_COLOR_DISABLED}
                    />
                </div>
            );
        },
        handler: (_: any, rowObject: any) => {
            const {applicationRequestId, signatureDate} = rowObject || {};
            return checkIfApplicationCanBeDeleted({signatureDate}) ? onTableDeleteClick(applicationRequestId) : null;
        },
    };
}

function renderIconPaymentInfo(onTablePaymentInfoClick: any) {
    const iconDelete = faEuroSign;
    const checkIfApplicationHasPaymentInfo = ({signatureDate}: any) => {
        return !!signatureDate;
    };
    return {
        title: null,
        icon: ({signatureDate}: any) => {
            let applicationCanBeDeleted = checkIfApplicationHasPaymentInfo({signatureDate});
            return (
                <div className={stylesCustomTable.iconWrap}>
                    <div className={stylesCustomTable.hoverMessage}>
                        {applicationCanBeDeleted ? `Payment info` : `Application is not signed yet`}
                    </div>
                    <FontAwesomeIcon
                        icon={iconDelete as any}
                        color={applicationCanBeDeleted ? ICON_COLOR_ENABLED : ICON_COLOR_DISABLED}
                    />
                </div>
            );
        },
        handler: (_: any, rowObject: any) => {
            const {signatureDate} = rowObject || {};
            return checkIfApplicationHasPaymentInfo({signatureDate}) ? onTablePaymentInfoClick(rowObject) : null;
        },
    };
}

// function renderIconSendToUPOV(onTableSendToUPOVClick: any) {
//     const checkIfApplicationHasPaymentInfo = ({signatureDate}: any) => {
//         return !!signatureDate;
//     };
//     return {
//         title: null,
//         icon: ({signatureDate}: any) => {
//             let applicationCanBeDeleted = checkIfApplicationHasPaymentInfo({signatureDate});
//             return (
//                 <div className={stylesCustomTable.iconWrap}>
//                     <div className={stylesCustomTable.hoverMessage}>
//                         {applicationCanBeDeleted ? `Send to UPOV` : `Application must be signed to send to UPOV`}
//                     </div>
//                     <FontAwesomeIcon
//                         icon={faPaperPlane as any}
//                         color={applicationCanBeDeleted ? ICON_COLOR_ENABLED : ICON_COLOR_DISABLED}
//                     />
//                 </div>
//             );
//         },
//         handler: (_: any, rowObject: any) => {
//             const {signatureDate} = rowObject || {};
//             return checkIfApplicationHasPaymentInfo({signatureDate}) ? onTableSendToUPOVClick(rowObject) : null;
//         },
//     };
// }

// const RenderIconRemedyFC = ({rowObject}: any) => {
//     const {applicationRequestId} = rowObject || {};
//     const iconPrint = faInfoCircle;
//     const [attachments, setAttachments] = useState<any>(null);
//     const [isPopoverOpen, setIsPopoverOpen] = useState<any>(null);
//     const [loading, setLoading] = useState<any>(null);
//
//     useEffect(() => {
//         setLoading(true);
//     }, []);
//
//     const fetchApplicationAttachments = async () => {
//         const jsonResponse = await apiOnlineApplicationAttachments({applicationRequestId});
//         setAttachments((jsonResponse && jsonResponse.documents) || null);
//         setLoading(false);
//     };
//
//     const onPrintIconClick = () => {
//         if (applicationRequestId) {
//             fetchApplicationAttachments();
//             setIsPopoverOpen(true);
//         }
//     };
//
//     const onDownloadFileClick = (file: any) => {
//         const {applicationRequestId, formIdTQ, formIdApplicationForm} = rowObject || {};
//         const {type, itQuest} = file || {};
//         if (type === 'AF' || type === 'TQ') {
//             const formId = type === 'AF' ? formIdApplicationForm : formIdTQ;
//             let uri = buildFormDraftUrl({
//                 applicationId: applicationRequestId,
//                 formType: type,
//                 formId,
//                 formSequence: itQuest,
//             });
//             window.open(uri, '_blank');
//         }
//         // const {applicationRequestCode} = rowObject || {};
//         // if (type === 'AF' || type === 'TQ') {
//         //     let onlinePdfURL = `/onlinePdf?vc=${verificationCode}`;
//         //     window.open(onlinePdfURL, '_blank');
//         // } else {
//         //     downloadFile({filename, applicationRequestCode});
//         // }
//     };
//
//     const customContent = loading ? (
//         <div className={stylesCustomTable.spinner}>
//             <FontAwesomeIcon icon={faSpinner as any} color="green" />
//         </div>
//     ) : attachments && attachments.length ? (
//         attachments.map((el: any) => {
//             const {filenameDisplay} = el || {};
//             return (
//                 <div className={styles.customContentItem} key={filenameDisplay} onClick={() => onDownloadFileClick(el)}>
//                     {filenameDisplay}
//                 </div>
//             );
//         })
//     ) : (
//         <div className={styles.customContentItemNoAttachments}>No attachments found for this application request</div>
//     );
//     return (
//         <div className={cx(stylesCustomTable.iconWrap, isPopoverOpen ? stylesCustomTable.popoverIsOpen : null)}>
//             <div className={stylesCustomTable.hoverMessage}>
//                 {`PDF files and joint attachment(s) ready to download`}
//             </div>
//             <Popover
//                 isOpen={isPopoverOpen}
//                 position={'top'}
//                 onClickOutside={() => setIsPopoverOpen(false)}
//                 transitionDuration={0}
//                 content={({position, targetRect, popoverRect}) => (
//                     <div style={{width: 500, background: 'white', border: '1px solid #2f923f', borderRadius: 5}}>
//                         {customContent}
//                     </div>
//                 )}
//             >
//                 <div onClick={() => onPrintIconClick()}>
//                     <FontAwesomeIcon icon={iconPrint as any} color={ICON_COLOR_ENABLED} />
//                 </div>
//             </Popover>
//         </div>
//     );
// };

// function renderIconRemedyForm(onTableRemedyFormClick: any) {
//     return {
//         title: null,
//         icon: (rowObject: any) => <RenderIconRemedyFC rowObject={rowObject} />,
//         handler: () => null,
//     };
// }

export default function OnlineApplicationsCustomTableActionButtons(
    onTableEditClick: any,
    onTableIconPrintClick: any,
    onTableRemedyClick: any,
    onTableDataRequestClick: any,
    onTableCopyClick: any,
    onTableSendToUPOVClick: any,
    onTableDeleteClick: any,
    onTablePaymentInfoClick: any,
    onTableRemedyFormClick: any
) {
    const buttons = [];
    buttons.push(renderIconEdit(onTableEditClick));
    buttons.push(renderIconPrint(onTableIconPrintClick));
    // buttons.push(renderIconDuplicate(onTableDuplicateClick));
    // buttons.push(renderIconRemedyForm(onTableRemedyFormClick));
    // buttons.push(renderIconRemedy(onTableRemedyClick));
    // buttons.push(renderIconDataRequest(onTableDataRequestClick));
    buttons.push(renderIconCopy(onTableCopyClick));
    // buttons.push(renderIconSendToUPOV(onTableSendToUPOVClick));
    buttons.push(renderIconPaymentInfo(onTablePaymentInfoClick));
    buttons.push(renderIconDelete(onTableDeleteClick));
    return buttons;
}
