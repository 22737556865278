import React from 'react';
import cx from 'classnames';
import styles from './ModalMyPVRPublicSearchELegalActions.module.scss';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';
import {Col, Grid} from '../../../componentsLayout';

interface IProps {
    selectedApplicationNumbers?: any;
    close?: any;
    eLegalActions?: any;
    intl?: any;
    onSelectModal: any;
}

interface IState {}

export default class ModalMyPVRPublicSearchELegalActions extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    close = () => {
        this.props.close();
    };

    render() {
        const selectedApplicationNumberValues = Object.values(this.props.selectedApplicationNumbers);
        const actionEnabled: any = {
            objection:
                selectedApplicationNumberValues.filter((i: any) => i.applicationStatus === 'A').length ===
                selectedApplicationNumberValues.length,
            nullity:
                selectedApplicationNumberValues.filter((i: any) => i.applicationStatus === 'G').length ===
                selectedApplicationNumberValues.length,
            cancellation:
                selectedApplicationNumberValues.filter((i: any) => i.applicationStatus === 'G').length ===
                selectedApplicationNumberValues.length,
            restitutioInIntegrum: true,
            appeal: true,
            other: true,
        };

        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={`Please select legal proceedings`}
                body={
                    <>
                        <Grid className={cx(styles.fieldsList)}>
                            {this.props.eLegalActions.map((eLegalAction: any) => {
                                const buttonName = this.props.intl.formatMessage({
                                    id: `table.eLegalActions.${eLegalAction.actionName}`,
                                });
                                return (
                                    <Col lg={4} md={6} mb={'lg'} key={eLegalAction.actionName}>
                                        <li
                                            key={eLegalAction.actionName}
                                            className={cx(
                                                styles.fieldsListLi,
                                                actionEnabled[eLegalAction.actionName] && styles.fieldActive
                                            )}
                                            onClick={() =>
                                                actionEnabled[eLegalAction.actionName] &&
                                                this.props.onSelectModal(
                                                    eLegalAction.modalScreen,
                                                    eLegalAction.actionName
                                                )
                                            }
                                        >
                                            <div
                                                title={buttonName}
                                                className={cx(
                                                    styles.fieldsListTitle,
                                                    actionEnabled[eLegalAction.actionName] &&
                                                        styles.fieldsListTitleActive
                                                )}
                                            >
                                                {` ${buttonName}`}
                                            </div>
                                            <div className={styles.fieldsListStatus}>
                                                {eLegalAction.applicationStatus}
                                            </div>
                                        </li>
                                    </Col>
                                );
                            })}
                        </Grid>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>
                                            <span>{'Proceeding action'}</span>
                                        </th>
                                        <th>
                                            <span>{'Application status allowed'}</span>
                                        </th>
                                        <th>
                                            <span>{'Documentation'}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Objection</td>
                                        <td>Application active</td>
                                        <td />
                                    </tr>
                                    <tr>
                                        <td>Nullity</td>
                                        <td>Application granted</td>
                                        <td />
                                    </tr>
                                    <tr>
                                        <td>Cancellation</td>
                                        <td>Application granted</td>
                                        <td />
                                    </tr>
                                    <tr>
                                        <td>Restitutio in integrum</td>
                                        <td>Any status</td>
                                        <td />
                                    </tr>
                                    <tr>
                                        <td>Appeal</td>
                                        <td>Any status</td>
                                        <td>
                                            <a
                                                href="https://cpvo.europa.eu/en/about-us/law-and-practice/board-appeal#1"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                CPVO website
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Other</td>
                                        <td>Any status</td>
                                        <td />
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <div style={{clear: 'both'}} />
                        </div>
                    </>
                }
                footer={<Button variation={'danger'} clickAction={this.close} icon={'close'}>{`Close`}</Button>}
            />
        );
    }
}
