import {getGroupKeyWithIteration} from '../Wizard/utils';
import {getQuestionKeyWithIteration} from '../Wizard/questions/utils';
import {getResponseKeyWithIteration} from '../Wizard/responses/utils';
import {IAPIResponsesClient, IGroup, IStateResponsesClient} from '../../types';

export function cyrb53(str: any, seed = 0) {
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

export function prepareResponsesClient({
    apiResponsesClient,
    groupsQuestion,
}: {
    apiResponsesClient: IAPIResponsesClient[];
    groupsQuestion: IGroup[];
}) {
    let stateResponsesClient: IStateResponsesClient = {};
    (apiResponsesClient || []).forEach(responseElement => {
        let currentQuestion = (([...groupsQuestion] || []).map(group => group.questions).flat(1) || []).find(
            question => question && question.id === responseElement.idQuestion
        );
        let groupObj = {
            id: responseElement.idGroupQuestion,
            templateId: responseElement.idTemplate,
            isTemplate: !!responseElement.idTemplate,
        };
        let groupKeyWithIteration = getGroupKeyWithIteration({
            group: groupObj,
            groupIteration: parseInt(responseElement.iterationGroup),
        });
        let questionKeyWithIteration = getQuestionKeyWithIteration({
            groupKeyWithIteration,
            question: {
                id: responseElement.idQuestion,
            },
        });
        if (currentQuestion && currentQuestion.type) {
            // Fill in client response answer
            if (currentQuestion.type === 'radio') {
                stateResponsesClient[questionKeyWithIteration] = getResponseKeyWithIteration({
                    questionKeyWithIteration,
                    response: {
                        id: responseElement.responses[0].responseId,
                    },
                    responseIteration: responseElement.responses[0].iteration,
                });
            } else {
                (responseElement.responses || []).forEach(response => {
                    let responseKeyWithIteration = getResponseKeyWithIteration({
                        questionKeyWithIteration,
                        response: {
                            id: response.responseId,
                        },
                        responseIteration: response.iteration,
                    });
                    response.textValue !== null &&
                        (stateResponsesClient[responseKeyWithIteration] =
                            response.textValue === '1' || response.textValue === '0'
                                ? parseInt(response.textValue)
                                : response.textValue);
                });
            }
            // Fill in remark
            if (['radio', 'checkbox'].includes(currentQuestion.type)) {
                (responseElement.responses || [])
                    .filter((response: any) => !!response.remarkValue)
                    .forEach((response: any) => {
                        let responseKeyWithIteration = getResponseKeyWithIteration({
                            questionKeyWithIteration,
                            response: {
                                id: parseInt(response.responseId),
                            },
                            responseIteration: parseInt(response.iteration),
                        });
                        stateResponsesClient[`ra${responseKeyWithIteration}`] = response.remarkValue;
                    });
            }
            // Fill in question type upload responses
            if (currentQuestion.type === 'upload') {
                (responseElement.responses || []).forEach((response: any) => {
                    let responseKeyWithIteration = getResponseKeyWithIteration({
                        questionKeyWithIteration,
                        response: {
                            id: parseInt(response.responseId),
                        },
                        responseIteration: parseInt(response.iteration),
                    });
                    stateResponsesClient[`${responseKeyWithIteration}`] = response.textValue;
                    stateResponsesClient[`ra${responseKeyWithIteration}`] = response.remarkValue;
                    stateResponsesClient[`upl${responseKeyWithIteration}`] = response.uploadTypeId;
                });
            }
        }
        //Fill in confidential question value
        if (responseElement.confidential === 1 || responseElement.confidential === 0) {
            stateResponsesClient[`conf${questionKeyWithIteration}`] = responseElement.confidential;
        }
    });
    return stateResponsesClient;
}
