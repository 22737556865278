import React from 'react';
import VFModalTestDenominationsSubmitBatchTableActionButtons from './VFModalTestDenominationsSubmitBatchTableActionButtons';
import {Button} from '../../../componentsFormV2';
import CustomTable from '~components/CustomTable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LoadingBar} from '../../../componentsLayout';
import {ModalCustomVersion2} from '../../../commonModals';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import graphQLClientInstance from '~utils/axiosGraphQLClient';
import {injectIntl} from 'react-intl';
import styles from './VFModalTestDenominationsSubmitBatchTable.module.scss';

const RESULT_FIELDS_DEFAULT = [
    'denomination',
    'speciesLatinName',
    'speciesCode',
    'breedersReference',
    'breedersName',
    'remark',
];

interface IProps {
    close: any;
    denominationsStringValue: string;
    intl: any;
    onButtonBackClick: any;
    onButtonRunTestsClick: any;
}

interface IState {
    invalidSpeciesCodesArray: string[];
    loading: number;
    tableResults: any[];
    timestamp: number;
}

class VFModalTestDenominationsSubmitBatchTable extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            invalidSpeciesCodesArray: [],
            loading: 0,
            tableResults: [],
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        const lines = (this.props.denominationsStringValue || '').split('\n').filter(Boolean);
        const customDelimiter = '|-|-|-|-|-|';
        const denominationsList = lines
            .map((denomination: any) => {
                let maxOccurences = 4;
                return denomination
                    .replace(/[;:,\t]+/g, (s: any) => (maxOccurences ? (maxOccurences--, customDelimiter) : s))
                    .split(customDelimiter);
            })
            .filter(Boolean);
        const tableResults = denominationsList.map((rowData, rowIndex) => {
            const length = denominationsList[rowIndex].length;
            return {
                denomination: length ? rowData[0] : '',
                rowIndex,
                speciesCode: length > 0 ? rowData[1] : '',
                breedersReference: length > 1 ? rowData[2] : '',
                breedersName: length > 2 ? rowData[3] : '',
                remark: length > 3 ? rowData[4] : '',
            };
        });
        this.setState(
            {
                tableResults,
                timestamp: Date.now(),
            },
            () => this.loadJSONs()
        );
    }

    loadJSONs = () => {
        const denominations = this.state.tableResults.map(({denomination}: any) => denomination);
        const speciesCodes = this.state.tableResults.map(({speciesCode}: any) => speciesCode);
        const promise1 = graphQLClientInstance.post('/api/v1/tests/checkinput', {
            payload: denominations,
        });
        const promise2 = graphQLClientInstance.post('/graphql', {
            query: `
                {
                  speciesSearchByID(
                    specieId: ${JSON.stringify(speciesCodes)}
                  ) {
                    princ_bot,
                    species_id
                  }
                }
            `,
        });
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                Promise.all([promise1, promise2]).then(JSONResponses => {
                    const [_1, JSONResponseSpeciesSearch] = JSONResponses;
                    if (
                        JSONResponseSpeciesSearch &&
                        JSONResponseSpeciesSearch.data &&
                        JSONResponseSpeciesSearch.data.data &&
                        JSONResponseSpeciesSearch.data.data.speciesSearchByID
                    ) {
                        let speciesFound = JSONResponseSpeciesSearch.data.data.speciesSearchByID;
                        this.setState(prev => {
                            const updatedTableResults = [...prev.tableResults];
                            speciesFound.forEach(({princ_bot: speciesName, species_id: responseSpeciesCode}: any) => {
                                updatedTableResults.forEach(({speciesCode}: any, index) => {
                                    if (speciesCode === responseSpeciesCode) {
                                        updatedTableResults[index]['speciesLatinName'] = speciesName;
                                    }
                                });
                            });
                            return {
                                loading: prev.loading - 1,
                                tableResults: updatedTableResults,
                                timestamp: Date.now(),
                            };
                        }, this.validateTableResults);
                    }
                });
            }
        );
    };

    validateTableResults = () => {
        const invalidSpeciesCodesArray = (
            this.state.tableResults.filter(({speciesLatinName}: any) => !speciesLatinName) || []
        ).map(({speciesCode}: any) => speciesCode);
        if (invalidSpeciesCodesArray) {
            this.setState({
                invalidSpeciesCodesArray,
                timestamp: Date.now(),
            });
        }
    };

    getTableRowClass = ({speciesLatinName}: any) => (!speciesLatinName ? styles.hasError : null);

    onButtonRunTestClick = () => this.props.onButtonRunTestsClick(this.state.tableResults);

    onButtonBackClick = () => this.props.onButtonBackClick();

    onTableIconDeleteClick = (tableIndex: number) =>
        this.setState(prev => {
            const updatedTableResults = [...prev.tableResults];
            updatedTableResults.splice(tableIndex, 1);
            return {
                tableResults: updatedTableResults.map((tableResult, rowIndex) => {
                    return {
                        ...tableResult,
                        rowIndex,
                    };
                }),
                timestamp: Date.now(),
            };
        }, this.validateTableResults);

    render() {
        const actions = VFModalTestDenominationsSubmitBatchTableActionButtons(this.onTableIconDeleteClick);

        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={`Submit batch`}
                body={
                    <div>
                        <div style={{marginBottom: 15}}>
                            <FontAwesomeIcon style={{marginRight: 10}} icon={faInfoCircle as any} />
                            {`You will be able to access the results within few minutes in "My Tests"`}
                        </div>
                        {this.state.loading !== 0 ? <LoadingBar /> : null}
                        {this.state.loading === 0 ? (
                            <CustomTable
                                actions={actions}
                                id={'rowIndex'}
                                intl={this.props.intl}
                                hideExcelButton={true}
                                notSortable={true}
                                resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                                rowClass={this.getTableRowClass}
                                tableName={'VFModalTestDenominationsSubmitBatchTable'}
                                tableType={'OBJECT'}
                                tableSource={this.state.tableResults}
                                timestamp={this.state.timestamp}
                                unlimited={true}
                            />
                        ) : null}
                        {this.state.invalidSpeciesCodesArray.length ? (
                            <div style={{color: 'red', marginTop: 20}}>
                                <div>
                                    <FontAwesomeIcon
                                        style={{marginRight: 10}}
                                        icon={faInfoCircle as any}
                                        color={`red`}
                                    />
                                    {`Some information entered is incorrect. Please remove the erroneous tests from the list or correct the data in the previous screen.`}
                                </div>
                                <div>
                                    <FontAwesomeIcon
                                        style={{marginRight: 10}}
                                        icon={faInfoCircle as any}
                                        color={`red`}
                                    />
                                    {`Invalid species codes: ${this.state.invalidSpeciesCodesArray.join(' ,')}`}
                                </div>
                            </div>
                        ) : null}
                    </div>
                }
                footer={
                    <>
                        <Button clickAction={this.props.onButtonBackClick} variation={'secondary'}>{`Back`}</Button>
                        <Button
                            clickAction={this.onButtonRunTestClick}
                            disabled={this.state.invalidSpeciesCodesArray.length !== 0 || this.state.loading !== 0}
                        >{`Run test`}</Button>
                    </>
                }
            />
        );
    }
}

export default injectIntl(VFModalTestDenominationsSubmitBatchTable);
