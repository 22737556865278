import {API_SERVERLESS, getFetch, postFetch} from '../../commonApi';
import {IAlertItem} from '../../types';

export function apiCommunicationCentreInternalAlerts(
    criteria: any,
    DEFAULT_CRITERIA: any = {},
    excelTranslations?: boolean
): Promise<{
    count: number;
    data: IAlertItem[];
}> {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/communicationCentreInternal/v4/CCISAL/alertLogs?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
    // return new Promise(resolve => {
    //     setTimeout(function () {
    //         resolve({
    //             data: [
    //                 {
    //                     contactId: 123,
    //                     logTime: '2023-04-04 12:35',
    //                     logText: 'lorem 50 lorem 50 lorem 50 lorem',
    //                     contactName: 'Contact name',
    //                     contactSurname: 'surname',
    //                     contactShortKey: 'SK',
    //                 },
    //             ],
    //         });
    //     }, 1000);
    // });
}
