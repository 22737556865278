import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export function apiCommunicationCentreClientSearchInbox(
    criteria: any,
    DEFAULT_CRITERIA: any = {},
    excelTranslations?: boolean
) {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/communicationCentreClient/v4/CCCIS/inbox/search?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}

export function apiCommunicationCentreClientInboxMessageArchive(communicationIds: any) {
    return postFetch(`${API_SERVERLESS}/communicationCentreClient/v4/CCCIMA/inbox/messageArchive`, true, {
        communicationIds,
    });
}

export function apiCommunicationCentreClientInboxClients() {
    return getFetch(`${API_SERVERLESS}/communicationCentreClient/v4/CCCIC/inbox/userClients`, true);
}

export function apiCommunicationCentreClientInboxSpecies() {
    return getFetch(`${API_SERVERLESS}/communicationCentreClient/v4/CCCISP/inbox/species`, false);
}

export function apiCommunicationCentreClientInboxMarkMessagesRead({
    communicationIds,
    all,
}: {
    communicationIds?: number[];
    all?: 'inbox' | 'archive';
}): Promise<{
    data: string;
}> {
    const JSONRequest: any = {};
    communicationIds && (JSONRequest.communicationIds = communicationIds);
    all && (JSONRequest.all = all);
    return postFetch(
        `${API_SERVERLESS}/communicationCentreClient/v4/CCCIMMR/inbox/markMessagesRead`,
        true,
        JSONRequest
    );
}

export function apiCommunicationCentreClientInboxMarkMessagesTicked(communicationId: number): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/communicationCentreClient/v4/CCCIMMT/inbox/markMessagesTicked`, true, {
        communicationIds: [communicationId],
    });
}

export function apiCommunicationCentreClientInboxMarkMessagesUnTick(communicationId: number): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/communicationCentreClient/v4/CCCIMMUT/inbox/markMessagesUnticked`, true, {
        communicationIds: [communicationId],
    });
}
