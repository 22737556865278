import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import Optional1 from './screenshots/Optional_1.PNG';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeOptionalHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>
                        Response is optional. It may be entered or can be left blank
                    </p>
                    <img src={Optional1} alt="Optional" className={styles.modalHelpImage} />
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeOptionalHelp;
