import React from 'react';
import styles from './MyPVRLegalProceedingsTopCaption.module.scss';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import cx from 'classnames';

interface IMyPVRLegalProceedingsTopCaption {
    legalActionEnabled?: any;
    showModalSelectLegalAction?: any;
    subjectToAnAppeal?: any;
}

export default function ({
    legalActionEnabled,
    showModalSelectLegalAction,
    subjectToAnAppeal,
}: IMyPVRLegalProceedingsTopCaption) {
    return (
        <div className={styles.container}>
            <div>
                <div className={styles.captionContainer}>
                    {subjectToAnAppeal && (
                        <div className={cx(styles.label, styles.labelYellow)}>
                            {`This file is subject to an appeal`}
                        </div>
                    )}
                    <div style={{clear: 'both'}}>{}</div>
                    <div
                        className={cx(styles.label)}
                        style={{paddingTop: 10}}
                    >{`Please select applications in order to proceed with an e-Legal action`}</div>
                </div>
                <FormFooterButton
                    color={buttonColor.blue}
                    clickAction={showModalSelectLegalAction}
                    disabled={!legalActionEnabled}
                >{`Legal actions`}</FormFooterButton>
            </div>
            <div style={{clear: 'both'}}>{}</div>
        </div>
    );
}
