export function buildFormDraftUrl({applicationId, formType, formId, formSequence}: any) {
    const FORM_DRAFT_PARAMS = ['applicationId', 'formType', 'formId', 'formSequence'];
    let formDraftParams = `/formDraft?applicationId=${applicationId}&formType=${formType}&formId=${formId}&formSequence=${formSequence}`;
    const paramsArray = document.location.href.split('?');
    paramsArray.shift();
    let currentPathParamsWithoutFormDraftParams =
        paramsArray.length &&
        paramsArray[0]
            .split('&')
            .filter(param => !FORM_DRAFT_PARAMS.includes((param || '').split('=')[0]))
            .join('&');
    return `${formDraftParams}${
        currentPathParamsWithoutFormDraftParams ? `&${currentPathParamsWithoutFormDraftParams}` : ''
    }`;
}
