import React from 'react';
import cx from 'classnames';
import styles from './WizardQuestion.module.scss';
import WizardQuestionWrapper, {IWizardQuestionWrapperProps, IWizardQuestionWrapperState} from './WizardQuestionWrapper';
import RemarkMessage from './RemarkMessage';

interface IState extends IWizardQuestionWrapperState {
    hidden: boolean;
}

export default class WizardQuestionNo extends WizardQuestionWrapper<IWizardQuestionWrapperProps, IState> {
    constructor(props: IWizardQuestionWrapperProps) {
        super(props);
        this.state = {
            ...this.state,
            hidden: false,
        };
    }

    componentDidMount() {
        this.setState(this.checkIfQuestionIsHidden());
    }

    componentDidUpdate(prevProps: IWizardQuestionWrapperProps, prevState: IWizardQuestionWrapperState) {
        if (this.props.screenData.hash !== prevProps.screenData.hash) {
            this.setState(this.checkIfQuestionIsHidden());
        }
    }

    render() {
        const {hidden, hiddenDueToQuestionResponseLines} = this.state;

        if (this.pdfConfidentialHideQuestion()) {
            return <></>;
        }
        return (
            <>
                <div className={cx(styles.titleSection, this.state.hidden && styles.sectionHidden)}>
                    {this.renderQuestionLabel({
                        hidden,
                        hiddenDueToQuestionResponseLines,
                    })}
                    {this.renderQuestionHelpIcon()}
                </div>
                {/* FYI: Wizard Question without response acts like a label (not real question), so it doesn't / shouldn't have any responses */}
                {!this.state.hidden && (
                    <div className={this.props.question.messageremark ? styles.section : undefined}>
                        <RemarkMessage
                            remarkMessage={this.props.question && this.props.question.messageremark}
                            isRemarkTranslationMissing={this.checkIfQuestionRemarkTranslationIsMissing()}
                        />
                    </div>
                )}
            </>
        );
    }
}
