import React from 'react';
import DatePicker from 'react-datepicker/es';
import dateFormat from 'date-format';
import CustomDateInput from './CustomDateInput';
import cx from 'classnames';
import stylesNew from './CustomDatePicker.module.scss';
import stylesOld from './CustomDatePickerOld.module.scss';
import {registerLocale, setDefaultLocale} from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
import Error from '../components/Error';

registerLocale('en-GB', enGB);

let styles;

export default class CustomDatePicker extends React.Component {
    previousPropsInputValue = null;

    constructor(props) {
        super(props);
        this.previousPropsInputValue = props.inputValue;
        this.state = {
            datePickerDate: null,
        };

        styles = props.oldBackOfficeStyles ? stylesOld : stylesNew;
    }

    componentDidMount() {
        setDefaultLocale('en-GB');
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.inputValue === '' && prevState.datePickerDate !== null) {
            this.setState({datePickerDate: null});
        }
    }

    onPickerChange = date => {
        LOG`onPickerChange: ${date}`;
        const inputValue = dateFormat('dd/MM/yyyy', date);
        this.setState({datePickerDate: date}, () => {
            this.props.change && this.props.change(inputValue, date);
        });
    };

    onInputValueChange = event => {
        const inputValue = event.target.value;
        LOG`inputValue: ${inputValue}`;
        let possibleDate = null;
        try {
            possibleDate = dateFormat.parse('yyyy/MM/dd', inputValue.split('/').reverse().join('/'));
        } catch {
            possibleDate = null;
        }
        this.setState(
            prev => ({datePickerDate: possibleDate || prev.datePickerDate}),
            () => {
                LOG`inputValue2: ${inputValue}`;
                this.props.change && this.props.change(inputValue, possibleDate);
            }
        );
    };

    onInputBlur = event => {
        LOG`onInputBlur`;
        let inputValue = event.target.value;
        let newDate = null;
        try {
            newDate = dateFormat.parse('yyyy/MM/dd', inputValue.split('/').reverse().join('/'));
        } catch {
            if (inputValue !== '') {
                newDate = new Date();
            }
        }
        if (inputValue !== '') {
            inputValue = dateFormat('dd/MM/yyyy', newDate);
        }
        this.setState(
            prev => ({datePickerDate: newDate || prev.datePickerDate}),
            () => {
                this.props.change && this.props.change(inputValue, newDate);
            }
        );
    };

    onInputFocus = () => {
        this.setState({datePickerDate: null});
    };

    render() {
        const error = this.props.errorMessage !== undefined && this.props.errorMessage !== '';
        return (
            <React.Fragment>
                <div className={cx('input-group date', styles.inputGroup)}>
                    <DatePicker
                        customInput={
                            <CustomDateInput
                                change={this.onInputValueChange}
                                focus={this.onInputFocus}
                                blur={this.onInputBlur}
                                inputValue={this.props.inputValue || ''}
                                customPlaceholder={this.props.placeholder}
                                notEnabled={this.props.disabled}
                                width={this.props.oldBackOfficeStyles ? 275 - 24 : this.props.width} // 24px is the padding of input 2*12px
                                oldBackOfficeStyles={this.props.oldBackOfficeStyles}
                                oldBackOfficeStylesError={this.props.oldBackOfficeStylesError}
                                disabled={this.props.disabled}
                            />
                        }
                        selected={this.state.datePickerDate}
                        focusSelectedMonth
                        onChange={this.onPickerChange}
                        showYearDropdown
                        minDate={this.props.minDate}
                        maxDate={this.props.maxDate}
                        locale="en-GB"
                        withPortal={this.props.withPortal}
                    />
                </div>
                {error && (
                    <div className={styles.errorWrap}>
                        {this.props.oldBackOfficeStylesError ? (
                            <Error wizardResponse={true}>{this.props.errorMessage}</Error>
                        ) : (
                            <Error> {this.props.errorMessage}</Error>
                        )}
                    </div>
                )}
            </React.Fragment>
        );
    }
}
