import React, {useState} from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import SelectInput from '~components/SelectInput';
import {injectIntl} from 'react-intl';
import {htmlToText} from '~utils/index';
import {Button} from '../../../componentsFormV2';

const ID_NONE = 'NONE';

const getSelectListForDestinationQuestion = ({steps}) => {
    const propsQuestionsList = [].concat(...steps.map(step => step.questions)).map(question => ({
        id: question.id,
        libelle: `${question.order} - ${htmlToText(question.message)}`,
        remark: question.order,
    }));
    return [{id: ID_NONE, value: 'Not applicable'}, ...propsQuestionsList.map(el => ({id: el.id, value: el.libelle}))];
};

const ModalLogicTypeShowQuestion = ({
    close,
    onCreateOrUpdateLogicType,
    responseLogicValues,
    responseLogicType,
    intl,
    steps,
}) => {
    const {questionIdDest, iteration} = responseLogicValues || {};
    const {name} = responseLogicType || {};
    const [selectedQuestionIdDest, setSelectedQuestionIdDest] = useState(questionIdDest ? questionIdDest : ID_NONE);

    const onButtonSaveClick = () => {
        let selectedQuestionIdDestValue =
            selectedQuestionIdDest && selectedQuestionIdDest !== ID_NONE ? selectedQuestionIdDest : null;
        onCreateOrUpdateLogicType(null, null, null, selectedQuestionIdDestValue, null, iteration);
    };

    const SELECT_LIST_FOR_DESTINATION_QUESTION = getSelectListForDestinationQuestion({steps});

    return (
        <>
            <ModalCustomVersion2
                close={close}
                header={name}
                body={
                    <div>
                        <SelectInput
                            label={`Question to be treated as optional if current response is selected`}
                            value={selectedQuestionIdDest}
                            notAll={true}
                            double={true}
                            onChange={({target: {value}}) => setSelectedQuestionIdDest(value)}
                            list={SELECT_LIST_FOR_DESTINATION_QUESTION}
                        />
                        <div style={{clear: 'both'}} />
                    </div>
                }
                footer={
                    <>
                        <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                        <Button icon={'arrowRight'} clickAction={onButtonSaveClick}>{`Save`}</Button>
                    </>
                }
            />
        </>
    );
};

export default injectIntl(ModalLogicTypeShowQuestion);
