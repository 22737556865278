import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Help from '../common/Help';
import {IGroupQuestionResponseLogic} from '../../../types';
import ReactDOMServer from 'react-dom/server';
import ResponseWrapper, {IWizardResponseWrapperProps, IWizardResponseWrapperState} from './ResponseWrapper';
import TextAreaInput from '~components/TextAreaInput';
import cx from 'classnames';
import {faPen, faPlus} from '@fortawesome/free-solid-svg-icons';
import {getErrorMessageResponseMandatoryField} from './utils';
import {htmlToText} from '../../../utils';
import {injectIntl} from 'react-intl';
import {replaceLineBreaksWithHTML} from '../../../utils/format';
import styles from './ResponseTextArea.module.scss';

interface IState extends IWizardResponseWrapperState {
    errorMessage: any;
    notValid: any;
}

class ResponseTextArea extends ResponseWrapper<IWizardResponseWrapperProps, IState> {
    constructor(props: IWizardResponseWrapperProps) {
        super(props);
        this.state = {
            ...this.state,
            errorMessage: null,
            notValid: null,
        };
    }

    componentDidMount() {
        this.checkAndUpdateIfCurrentResponseIsDisabledDueToLogicDisabledOfOtherResponse();
    }

    componentDidUpdate(prevProps: IWizardResponseWrapperProps, prevState: IState) {
        if (this.props.screenData.hash !== prevProps.screenData.hash) {
            this.checkAndUpdateIfCurrentResponseIsDisabledDueToLogicDisabledOfOtherResponse();
        }
    }

    onTextAreaInputChange = ({target: {value: textInputValue}}: React.ChangeEvent<HTMLInputElement>) => {
        this.updateCurrentResponseAnswer(textInputValue);
        // this.isValidText();
    };

    getNumberOfRows(logics: IGroupQuestionResponseLogic[]): number {
        let numberOfRows = 2;
        if (logics && logics.length) {
            let {logicParam} = logics[logics.length - 1] || {};
            //"rows:2;cols:2;" -> 2
            let arrayOfParams = (logicParam || '').split('rows:');
            if (arrayOfParams.length > 1) {
                numberOfRows = parseInt(arrayOfParams[1].replace(';', ''));
            }
        }
        return numberOfRows;
    }

    renderRemedyIcon = () => {
        const remedyType = this.getRemedyType();
        return (
            <div
                className={cx(
                    styles.remedyIconWrap,
                    styles[this.props.question.type],
                    remedyType ? styles[remedyType] : null
                )}
                onClick={this.onButtonAddResponseToRemedyClick}
                id={`REMEDY_${this.getResponseKey()}`}
            >
                <FontAwesomeIcon
                    icon={(this.checkIfRemedyResponseExists() ? faPen : faPlus) as any}
                    color={'white'}
                    width={16}
                    height={10}
                />
            </div>
        );
    };

    renderPDFVersion() {
        const {messageRemark} = this.props.response;
        const outsideLabelWithHTML = this.getLabelWithHTML();
        const responseAnswer = this.getResponseAnswer();
        if ((window as any).elements) {
            let element: any = {
                type: 'RESPONSE_TEXTAREA',
                label: htmlToText(ReactDOMServer.renderToStaticMarkup(outsideLabelWithHTML)),
                order: this.getResponseOrderForPDF(),
                questionType: this.props.question.type,
            };
            messageRemark && (element.remark = messageRemark);
            let elementExist = (window as any).elements.find((el: any) => el.order === element.order);
            if (!elementExist) {
                (window as any).elements.push(element);
            }
        }

        return (
            <>
                {super.render()}
                {this.props.isRemedyStarted && this.renderRemedyIcon()}
                <div className={styles.pdfResponseWrap}>
                    {this.props.isPDFVersionAndQuestionIsTableType ? null : (
                        <div className={styles.pdfResponseWrapLeft}>{outsideLabelWithHTML}</div>
                    )}
                    <div className={cx(styles.pdfResponseWrapRight, !!responseAnswer && styles.hasPDFValue)}>
                        {replaceLineBreaksWithHTML(responseAnswer)}
                    </div>
                </div>
            </>
        );
    }

    render() {
        const {response} = this.props;
        const {messageRemark, logics} = response;
        const numberOfRows = this.getNumberOfRows(logics);
        const responseKey = this.getResponseKey();
        const responseAnswer = this.getResponseAnswer();
        const outsideLabelWithHTML = this.getLabelWithHTML();
        const hasMandatoryError = this.checkIfResponseHasMandatoryError();
        const hasClickedOnSaveAndValidate = this.checkIfUserHasClickedOnSaveAndValidate();
        const mandatoryErrorMessage = hasMandatoryError
            ? getErrorMessageResponseMandatoryField({intl: this.props.intl})
            : null;
        const errorMessage = hasClickedOnSaveAndValidate && (this.state.errorMessage || mandatoryErrorMessage);
        const hasOldBackOfficeStylesError = this.state.notValid || (hasClickedOnSaveAndValidate && errorMessage);

        if (this.props.PDFVersion) {
            return this.renderPDFVersion();
        }

        return (
            <div id={responseKey}>
                <Help
                    message={response.messageHelp}
                    language={this.props.questionnaireLanguage}
                    className="responseHelp"
                />
                <TextAreaInput
                    onChange={this.onTextAreaInputChange}
                    value={responseAnswer}
                    outsideLabelWithHTML={outsideLabelWithHTML}
                    remark={messageRemark}
                    isRemarkTranslationMissing={this.checkIfResponseRemarkTranslationIsMissing()}
                    oldBackOfficeStyles={true}
                    oldBackOfficeStylesError={hasOldBackOfficeStylesError}
                    error={errorMessage}
                    rows={numberOfRows}
                    isTranslationMissing={this.checkIfTranslationIsMissing()}
                    PDFVersion={this.props.PDFVersion}
                    disabled={this.state.isCurrentResponseDisabledDueToLogicDisabledOfOtherResponse}
                    dynamicHeight={true}
                />
            </div>
        );
    }
}

export default injectIntl(ResponseTextArea);
