import React from 'react';
import {getGroupKeyWithIteration} from '../utils';
import Error from '~components/Error';
import ResponseAssistedEntry from '../responses/ResponseAssistedEntry';
import ResponseCheckbox from '../responses/ResponseCheckbox';
import ResponseCombobox from '../responses/ResponseCombobox';
import ResponseDate from '../responses/ResponseDate';
import ResponseHidden from '../responses/ResponseHidden';
import ResponseRadio from '../responses/ResponseRadio';
import ResponseText from '../responses/ResponseText';
import ResponseTextArea from '../responses/ResponseTextArea';
import ResponseUpload from '../responses/ResponseUpload';
import {FormattedMessage} from 'react-intl';
import {
    STRING_PARAM_PREFIX_ONLY_IF_CURRENT_COMBOBOX_ANSWER,
    STRING_PARAM_PREFIX_ONLY_IF_RESPONSE_IS_SELECTED,
} from '../responsesLogicModals/ModalLogicTypeShowQuestion';
import {
    IGroup,
    IGroupQuestion,
    IGroupQuestionResponse,
    IGroupQuestionResponseLogic,
    IStateResponsesClient,
} from '../../../types';

const checkIfQuestionTypeRadioIsHidden = ({
    logic,
    groupIteration,
    responsesClient,
    sourceQuestionKeyWithIteration,
    sourceResponseKeyWithIteration,
}: {
    logic: IGroupQuestionResponseLogic;
    groupIteration: number;
    responsesClient: IStateResponsesClient;
    sourceQuestionKeyWithIteration: string;
    sourceResponseKeyWithIteration: string;
}): boolean => {
    let condition1 = responsesClient[sourceQuestionKeyWithIteration] === sourceResponseKeyWithIteration;
    if (logic.logicParam?.includes(`${STRING_PARAM_PREFIX_ONLY_IF_RESPONSE_IS_SELECTED}:`)) {
        let condition2 = true;
        let paramValue =
            (logic as any)?.logicParam
                .split(`${STRING_PARAM_PREFIX_ONLY_IF_RESPONSE_IS_SELECTED}:`)
                .pop()
                .split(';')[0] || '';
        let conditionalQuestionKeyWithIteration = '';
        let conditionalResponseKeyWithIteration = '';
        if (paramValue.split('_').length === 3 && ['GR', '_Q', '_R'].every(term => paramValue.includes(term))) {
            let conditionalGroupKeyWithIteration = `${paramValue.split('_')[0]}_${groupIteration}`;
            let questionId = paramValue.split('_')[1].split('Q').pop();
            let responseId = paramValue.split('_')[2].split('R').pop();
            conditionalQuestionKeyWithIteration = `${conditionalGroupKeyWithIteration}_Q${questionId}_1`;
            conditionalResponseKeyWithIteration = `${conditionalQuestionKeyWithIteration}_R${responseId}_1`;
        }
        if (conditionalQuestionKeyWithIteration && conditionalResponseKeyWithIteration) {
            condition2 = responsesClient[conditionalQuestionKeyWithIteration] === conditionalResponseKeyWithIteration;
        }
        return condition1 && condition2;
    }
    return condition1;
};

function checkIfQuestionTypeTextIsHidden({logic, responsesClient, sourceResponseKeyWithIteration}: any) {
    if (logic.logicParam?.includes(`${STRING_PARAM_PREFIX_ONLY_IF_CURRENT_COMBOBOX_ANSWER}:`)) {
        let paramValue =
            (logic as any)?.logicParam
                .split(`${STRING_PARAM_PREFIX_ONLY_IF_CURRENT_COMBOBOX_ANSWER}:`)
                .pop()
                .split(';')[0] || '';
        if (paramValue) {
            return responsesClient[sourceResponseKeyWithIteration] === paramValue;
        }
    }
    return !!responsesClient[sourceResponseKeyWithIteration];
}

export function checkIfQuestionIsMandatoryInCurrentGroupIteration({
    question,
    groupIteration = 1,
    steps,
    responsesClient,
}: {
    question: IGroupQuestion;
    groupIteration: number;
    steps: IGroup[];
    responsesClient: IStateResponsesClient;
}) {
    let isQuestionOptionalDueToResponseLogic = false;
    const responseLogicsQuestionOptionalWithDestinationCurrentQuestion: any[] = [];
    (steps || []).forEach(step =>
        (step.questions || []).forEach(stepQuestion =>
            stepQuestion.responses.forEach(response =>
                response.logics
                    .filter(logic => logic.type === 'questionoptional')
                    .forEach(logic => {
                        if (`${logic.questionIdDest}` === `${question.id}`) {
                            responseLogicsQuestionOptionalWithDestinationCurrentQuestion.push({
                                logic,
                                stepQuestion,
                                response,
                            });
                        }
                    })
            )
        )
    );
    if (responseLogicsQuestionOptionalWithDestinationCurrentQuestion.length) {
        responseLogicsQuestionOptionalWithDestinationCurrentQuestion.forEach(({logic, stepQuestion}) => {
            // source meaning the question that this logic's response belongs
            let sourceQuestionType = stepQuestion.type;
            let sourceQuestionId = stepQuestion.id;
            let sourceGroupOrTemplate = findGroupOrTemplateFromQuestionId({steps, questionId: stepQuestion.id});
            if (sourceGroupOrTemplate) {
                let sourceGroupKeyWithIteration = getGroupKeyWithIteration({
                    group: sourceGroupOrTemplate,
                    groupIteration,
                });
                let sourceQuestionKeyWithIteration = `${sourceGroupKeyWithIteration}_Q${sourceQuestionId}_1`;
                let sourceResponseKeyWithIteration = `${sourceQuestionKeyWithIteration}_R${logic.responseId}_1`;
                switch (sourceQuestionType) {
                    case 'radio':
                        isQuestionOptionalDueToResponseLogic = checkIfQuestionTypeRadioIsHidden({
                            logic,
                            groupIteration,
                            responsesClient,
                            sourceQuestionKeyWithIteration,
                            sourceResponseKeyWithIteration,
                        });
                        break;
                    case 'checkbox':
                        isQuestionOptionalDueToResponseLogic = responsesClient[sourceResponseKeyWithIteration] === 1;
                        break;
                    case 'text':
                    case 'textarea':
                        isQuestionOptionalDueToResponseLogic = !!responsesClient[sourceResponseKeyWithIteration];
                        break;
                    default:
                        break;
                }
            }
        });
    }
    let isMandatory = question.mandatory && !isQuestionOptionalDueToResponseLogic;
    const questionLogics = question.logics;
    if (isMandatory && questionLogics && questionLogics.length > 0) {
        let mandatoryfirstoccurrenceLogic = questionLogics.find(
            questionLogic => questionLogic.type === 'mandatoryfirstoccurrence'
        );
        if (mandatoryfirstoccurrenceLogic) {
            isMandatory = groupIteration < 2;
        }
    }
    return isMandatory;
}

export function checkIfShouldHideQuestionInCurrentGroupIteration({
    question,
    groupIteration,
}: {
    question: IGroupQuestion;
    groupIteration: number;
}) {
    const questionLogics = question.logics;
    if (questionLogics && questionLogics.length > 0) {
        let showonfirstsXoccurrenceLogic = questionLogics.find(
            questionLogic => questionLogic.type === 'showonfirstsXoccurrence'
        );
        if (showonfirstsXoccurrenceLogic && showonfirstsXoccurrenceLogic.logicParam) {
            let numOfIterationsThatQuestionIsVisible = showonfirstsXoccurrenceLogic.logicParam.split('param:')[1];
            if (
                numOfIterationsThatQuestionIsVisible &&
                groupIteration > parseInt(numOfIterationsThatQuestionIsVisible)
            ) {
                return true;
            }
        }
    }
    return false;
}

//hidden due to some response logic showquestion
export function checkIfQuestionIsHiddenFn({
    steps,
    question,
    responsesClient,
    groupIteration,
}: {
    steps: IGroup[];
    question: IGroupQuestion;
    responsesClient: IStateResponsesClient;
    groupIteration: number;
}): {hidden: boolean; hiddenDueToQuestionResponseLines: string[]} {
    const logicsShowQuestionWithDestinationTheCurrentQuestion: {
        logic: IGroupQuestionResponseLogic;
        stepQuestion: IGroupQuestion;
        response: IGroupQuestionResponse;
    }[] = [];
    let numberOfEnteredAnswersInSourceQuestions = 0;
    let hiddenDueToQuestionResponseLines: string[] = [];
    (steps || []).forEach(step =>
        (step.questions || []).forEach(stepQuestion =>
            stepQuestion.responses.forEach(response =>
                response.logics
                    .filter(logic => logic.type === 'showquestion')
                    .forEach(logic => {
                        if (`${logic.questionIdDest}` === `${question.id}`) {
                            logicsShowQuestionWithDestinationTheCurrentQuestion.push({
                                logic,
                                stepQuestion,
                                response,
                            });
                            hiddenDueToQuestionResponseLines.push(`${stepQuestion.order} - ${response.message}`);
                        }
                    })
            )
        )
    );

    if (logicsShowQuestionWithDestinationTheCurrentQuestion.length) {
        logicsShowQuestionWithDestinationTheCurrentQuestion.forEach(({logic, stepQuestion}) => {
            let isShown = false;
            // source meaning the question that this logic's response belongs
            let sourceQuestionType = stepQuestion.type;
            let sourceQuestionId = stepQuestion.id;
            let sourceGroupOrTemplate = findGroupOrTemplateFromQuestionId({steps, questionId: stepQuestion.id});
            if (sourceGroupOrTemplate) {
                let sourceGroupKeyWithIteration = getGroupKeyWithIteration({
                    group: sourceGroupOrTemplate,
                    groupIteration,
                });
                let sourceQuestionKeyWithIteration = `${sourceGroupKeyWithIteration}_Q${sourceQuestionId}_1`;
                let sourceResponseKeyWithIteration = `${sourceQuestionKeyWithIteration}_R${logic.responseId}_1`;
                switch (sourceQuestionType) {
                    case 'radio':
                        isShown = checkIfQuestionTypeRadioIsHidden({
                            logic,
                            groupIteration,
                            responsesClient,
                            sourceQuestionKeyWithIteration,
                            sourceResponseKeyWithIteration,
                        });
                        break;
                    case 'checkbox':
                        isShown = responsesClient[sourceResponseKeyWithIteration] === 1;
                        break;
                    case 'text':
                    case 'textarea':
                        isShown = checkIfQuestionTypeTextIsHidden({
                            logic,
                            responsesClient,
                            sourceResponseKeyWithIteration,
                        });
                        break;
                    default:
                        break;
                }
                if (isShown) {
                    numberOfEnteredAnswersInSourceQuestions += 1;
                }
            }
        });
    }

    return {
        hidden: logicsShowQuestionWithDestinationTheCurrentQuestion.length
            ? numberOfEnteredAnswersInSourceQuestions === 0
            : false,
        hiddenDueToQuestionResponseLines,
    };
}

export function checkIfQuestionHasMandatoryErrorFn({
    question,
    responsesClient,
    groupIteration,
    questionKeyWithIteration,
    questionsCounter = 1,
    steps,
}: {
    question: IGroupQuestion;
    responsesClient: IStateResponsesClient;
    groupIteration: number;
    questionKeyWithIteration: string;
    questionsCounter?: number;
    steps: IGroup[];
}) {
    const {type, responses} = question || {};
    let questionIsMandatoryInCurrentGroupIteration = checkIfQuestionIsMandatoryInCurrentGroupIteration({
        question,
        groupIteration,
        responsesClient,
        steps,
    });
    let questionIsHiddenInCurrentIteration = checkIfShouldHideQuestionInCurrentGroupIteration({
        question,
        groupIteration,
    });
    if (questionIsMandatoryInCurrentGroupIteration && !questionIsHiddenInCurrentIteration) {
        if (type === 'radio') {
            if (!responsesClient[questionKeyWithIteration]) {
                // does not have GR521_1_Q14459_1: 'GR521_1_Q14459_1_R25277_1' response answer
                return true;
            }
        }
        if (type === 'checkbox') {
            let hasCheckboxAnswer = false;
            Object.keys(responsesClient).forEach(responseKey => {
                if (responseKey.indexOf(questionKeyWithIteration) !== -1 && responsesClient[responseKey] === 1) {
                    //it has one checkbox answer eg: GR521_1_Q11719_1_R25271_1: 1
                    hasCheckboxAnswer = true;
                }
            });
            return !hasCheckboxAnswer;
        }
        if (type === 'text' || type === 'hybrid') {
            let hasAllMandatoryTextAnswers = true;
            const mandatoryResponsesIds = responses
                .filter(response => !response.logics.find(logic => logic.type === 'optional'))
                .filter(response => !response.logics.find(logic => logic.type === 'hidden'))
                .map(response => response.id);
            mandatoryResponsesIds.forEach(responseId => {
                let responseKey = `${questionKeyWithIteration}_R${responseId}_1`;
                if (!responsesClient[responseKey]) {
                    hasAllMandatoryTextAnswers = false;
                }
            });
            return !hasAllMandatoryTextAnswers;
        }
        if (type === 'upload') {
            const {responses} = question || {};
            let responseId = responses.length > 0 && responses[0].id;
            let responseAnswerOfUploadedDocument = `${questionKeyWithIteration}_R${responseId}_1`;
            return !responsesClient[responseAnswerOfUploadedDocument];
        }
        if (type === 'table') {
            let hasMandatoryError = false;
            const mandatoryResponsesIds = responses
                .filter(response => !response.logics.find(logic => logic.type === 'optional'))
                .map(response => response.id);
            //if question mandatory, all responses(except logic optional) of each iteration should be answered
            let allResponsesKeysThatMustHaveAnswer: string[] = [];
            Array(questionsCounter || 1)
                .fill(true)
                .forEach((_, index) => {
                    mandatoryResponsesIds.forEach(responseId => {
                        let key = `${questionKeyWithIteration}_R${responseId}_${index + 1}`;
                        allResponsesKeysThatMustHaveAnswer.push(key);
                    });
                });
            let hasAtLeastOneAnswer;
            if (questionsCounter > 1) {
                hasAtLeastOneAnswer = responses
                    .map(response => `${questionKeyWithIteration}_R${response.id}_${questionsCounter}`)
                    .find(responseKey => responsesClient[responseKey]);
            }
            if (questionsCounter === 1 || (questionsCounter > 1 && hasAtLeastOneAnswer)) {
                allResponsesKeysThatMustHaveAnswer.forEach(responseKey => {
                    if (!responsesClient[responseKey]) {
                        hasMandatoryError = true;
                    }
                });
            }

            return hasMandatoryError;
        }
    }
    return false;
}

export function checkIfQuestionHasUploadTypeMustBeDefinedError({
    question,
    responsesClient,
    questionKeyWithIteration,
}: {
    question: IGroupQuestion;
    responsesClient: IStateResponsesClient;
    questionKeyWithIteration: string;
}) {
    return (
        question &&
        question.type === 'upload' &&
        Object.entries(responsesClient).find(
            ([key, value]) => key.startsWith(`upl${questionKeyWithIteration}`) && !value
        )
    );
}

export function getQuestionKeyWithIteration({
    groupKeyWithIteration,
    question,
}: {
    groupKeyWithIteration: string;
    question: {
        id: number;
    };
}) {
    return `${groupKeyWithIteration}_Q${question && question.id}_1`;
}

export function findGroupOrTemplateKeyFromQuestionId({steps, questionId}: {steps: IGroup[]; questionId: number}) {
    let questionIdGroupOrTemplateKeyMap: {[key: string]: string} = {};
    (steps || []).forEach(step =>
        (step.questions || []).forEach(
            question =>
                (questionIdGroupOrTemplateKeyMap[question.id] = step.isTemplate ? `T${step.templateId}` : `G${step.id}`)
        )
    );
    return questionIdGroupOrTemplateKeyMap[questionId];
}

export function findGroupOrTemplateFromGroupOrTemplateKey({
    steps,
    groupOrTemplateKey = '',
}: {
    steps: IGroup[];
    groupOrTemplateKey: string;
}) {
    return (steps || []).find(
        step =>
            step[groupOrTemplateKey.slice(0, 1) === 'T' ? 'templateId' : 'id'] === parseInt(groupOrTemplateKey.slice(1))
    );
}

export function findGroupOrTemplateFromQuestionId({steps, questionId}: {steps: IGroup[]; questionId: number}) {
    let groupOrTemplateKey = findGroupOrTemplateKeyFromQuestionId({steps, questionId});
    return findGroupOrTemplateFromGroupOrTemplateKey({steps, groupOrTemplateKey});
}

export function checkIfQuestionHasForceResponseError({
    steps,
    destinationQuestion,
    responsesClient,
    questionKeyWithIteration,
}: {
    steps: IGroup[];
    destinationQuestion: IGroupQuestion;
    responsesClient: IStateResponsesClient;
    questionKeyWithIteration: string;
}) {
    const DEFAULT_FORCE_RESPONSE_ERROR = 'Please review the validity of the answers.';
    let hasForceResponseError: string | boolean = false;
    let sourceLogicsForceResponseTargetingResponseOfTheCurrentQuestion: (IGroupQuestionResponseLogic & {
        questionId: number;
        questionType: string;
    })[] = [];
    steps.forEach(step => {
        (step.questions || []).forEach(question => {
            question.responses.forEach(response => {
                response.logics.forEach(logic => {
                    if (logic.type === 'forceresponse' && logic.questionIdDest === destinationQuestion.id) {
                        sourceLogicsForceResponseTargetingResponseOfTheCurrentQuestion.push({
                            ...logic,
                            questionId: question.id,
                            questionType: question.type,
                        });
                    }
                });
            });
        });
    });
    sourceLogicsForceResponseTargetingResponseOfTheCurrentQuestion.forEach(sourceLogic => {
        let sourceGroupOrTemplate = findGroupOrTemplateFromQuestionId({
            steps,
            questionId: sourceLogic.questionId,
        });
        if (sourceGroupOrTemplate) {
            let sourceGroupKeyWithIteration = getGroupKeyWithIteration({group: sourceGroupOrTemplate});
            let sourceIsActivated = false;
            let sourceQuestionKey = `${sourceGroupKeyWithIteration}_Q${sourceLogic.questionId}_1`;
            let sourceResponseKey = `${sourceQuestionKey}_R${sourceLogic.responseId}_1`;
            if (sourceLogic.questionType === 'checkbox' && responsesClient[sourceResponseKey] === 1) {
                sourceIsActivated = true;
            }
            if (sourceLogic.questionType === 'radio' && responsesClient[sourceQuestionKey]) {
                sourceIsActivated = true;
            }
            if (sourceIsActivated && !hasForceResponseError) {
                const destinationResponseMustHaveValue =
                    parseInt((sourceLogic.logicParam || '').split(':').pop() as string) || 0;
                const destinationResponseAnswer = `${questionKeyWithIteration}_R${sourceLogic.responseIdDest}_1`;
                if (destinationQuestion.type === 'radio') {
                    if (destinationResponseMustHaveValue) {
                        if (responsesClient[questionKeyWithIteration] !== destinationResponseAnswer) {
                            hasForceResponseError = sourceLogic.messageLink || DEFAULT_FORCE_RESPONSE_ERROR;
                        }
                    } else {
                        if (responsesClient[questionKeyWithIteration]) {
                            hasForceResponseError = sourceLogic.messageLink || DEFAULT_FORCE_RESPONSE_ERROR;
                        }
                    }
                }
                if (destinationQuestion.type === 'checkbox') {
                    if (destinationResponseMustHaveValue) {
                        if (!responsesClient[destinationResponseAnswer]) {
                            hasForceResponseError = sourceLogic.messageLink || DEFAULT_FORCE_RESPONSE_ERROR;
                        }
                    } else {
                        if (responsesClient[destinationResponseAnswer]) {
                            hasForceResponseError = sourceLogic.messageLink || DEFAULT_FORCE_RESPONSE_ERROR;
                        }
                    }
                }
            }
        }
    });
    return hasForceResponseError;
}

export function checkIfQuestionHasSuccessiveScoreOnlyError({
    responsesClient,
    questionKeyWithIteration,
    question,
}: {
    responsesClient: IStateResponsesClient;
    questionKeyWithIteration: string;
    question: IGroupQuestion;
}) {
    let hasSuccessiveScoreOnlyError = false;
    if (question && question.type !== 'checkbox') {
        return false;
    }
    if (question && !question.successiveScoreOnly) {
        return false;
    }
    let answersIds = Object.keys(responsesClient)
        .filter(responseKey => responseKey.startsWith(questionKeyWithIteration) && responsesClient[responseKey] === 1)
        .map(key => parseInt(key.replace(`${questionKeyWithIteration}_R`, '').split('_')[0])); // GR2_1_Q15435_1_R16306_1 --> 16306
    let responsesOrders = question.responses
        .filter(response => answersIds.indexOf(response.id) !== -1)
        .map(response => parseInt(response.order));
    responsesOrders.forEach((order, i) => {
        if (i < responsesOrders.length - 1) {
            if (responsesOrders[i + 1] !== responsesOrders[i] + 1) {
                hasSuccessiveScoreOnlyError = true;
            }
        }
    });
    return hasSuccessiveScoreOnlyError;
}

export function checkIfQuestionHasNumberOfResponsesAllowedError({
    responsesClient,
    questionKeyWithIteration,
    question,
}: {
    responsesClient: IStateResponsesClient;
    questionKeyWithIteration: string;
    question: IGroupQuestion;
}): false | {numberOfResponsesAllowed: number; numberOfResponsesGiven: number} {
    const {nbResponseAllowed} = question || {};
    if (question && question.type !== 'checkbox') {
        return false;
    }
    if (!nbResponseAllowed || parseInt(String(nbResponseAllowed), 0) < 1) {
        return false;
    }
    let numberOfResponsesGiven = Object.keys(responsesClient).filter(
        responseKey => responseKey.startsWith(questionKeyWithIteration) && responsesClient[responseKey] === 1
    ).length;
    if (numberOfResponsesGiven > nbResponseAllowed) {
        return {
            numberOfResponsesAllowed: nbResponseAllowed,
            numberOfResponsesGiven,
        };
    }
    return false;
}

export function checkIfQuestionIsShortDisplay(question: IGroupQuestion): boolean {
    let shortDisplay = false;
    if (question.responses.length > 0) {
        return question.shortDisplay;
    }
    return shortDisplay;
}

export function getResponseComponentTypeBasedOnResponseLogics(
    question: IGroupQuestion,
    response: IGroupQuestionResponse
): string {
    let responseType = question.type;
    response.logics.map(logic => {
        switch (logic.type) {
            case 'autocomplete':
            case 'controllist':
            case 'assistedentry':
                responseType = 'assistedentry';
                break;
            case 'combobox':
                responseType = 'combobox';
                break;
            case 'date':
                responseType = 'date';
                break;
            case 'area':
                responseType =
                    ['radio', 'checkbox'].includes(question.type) || logic.applyToRemark ? question.type : 'textarea';
                break;
            case 'number':
                responseType = 'number';
                break;
        }
        if (logic.type === 'combobox') {
            responseType = 'combobox';
        }
    });
    return responseType === 'table' ? 'text' : responseType;
}

export function getResponseComponent({
    question,
    response,
    params,
}: {
    question: IGroupQuestion;
    response: IGroupQuestionResponse;
    params: any;
}): any {
    let responseComponent;
    switch (getResponseComponentTypeBasedOnResponseLogics(question, response)) {
        case 'assistedentry':
            responseComponent = <ResponseAssistedEntry {...params} />;
            break;
        case 'checkbox':
            responseComponent = <ResponseCheckbox {...params} />;
            break;
        case 'combobox':
            responseComponent = <ResponseCombobox {...params} />;
            break;
        case 'date':
            responseComponent = <ResponseDate {...params} />;
            break;
        case 'hidden':
            responseComponent = <ResponseHidden {...params} />;
            break;
        case 'radio':
            responseComponent = <ResponseRadio {...params} />;
            break;
        case 'text':
            responseComponent = <ResponseText {...params} />;
            break;
        case 'number':
            responseComponent = <ResponseText {...params} {...{type: 'number'}} />;
            break;
        case 'textarea':
            responseComponent = <ResponseTextArea {...params} />;
            break;
        case 'upload':
            responseComponent = <ResponseUpload {...params} />;
            break;
        default:
            break;
    }
    return responseComponent;
}

export function renderError(errorMessage: any, dangerouslySetInnerHTML?: boolean) {
    return (
        <Error wizardQuestion={true}>
            {dangerouslySetInnerHTML ? <div dangerouslySetInnerHTML={{__html: errorMessage}} /> : errorMessage || ''}
        </Error>
    );
}

export function renderMandatoryError({intl}: {intl: any}) {
    return renderError(intl?.formatMessage({id: 'online.pleaseRememberToFillTheMandatoryFieldsOfTheQuestion'}));
}

export function renderSuccessiveScoreOnlyError({intl}: {intl: any}) {
    return renderError(intl?.formatMessage({id: 'online.thisQuestionMustHaveAnswersWithSuccessiveScores'}));
}

export function renderUploadTypeMustBeDefinedError({intl}: {intl: any}) {
    return renderError(intl?.formatMessage({id: 'online.pleaseSelectADocumentTypeForAllDocuments'}));
}

export function renderNumberOfResponsesAllowedError({
    intl,
    numberOfResponsesAllowedError,
}: {
    intl: any;
    numberOfResponsesAllowedError: any;
}) {
    const {numberOfResponsesAllowed, numberOfResponsesGiven} = numberOfResponsesAllowedError || {};
    return renderError(
        <FormattedMessage
            id="online.onlyXResponsesAllowedForThisQuestionYGiven"
            values={{numberOfResponsesAllowed, numberOfResponsesGiven}}
        />
    );
}

export function renderForceResponseError(errorMessage: any) {
    return renderError(errorMessage, true);
}
