import {API_SERVERLESS, getFetch} from '~commonApi';

export function apiDashboardDocuments() {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/dashboard/1`, true);
}

export function apiDashboardFees() {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/dashboard/2`, true);
}

export function apiDashboardLastWeekNotifications() {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/dashboard/3`, true);
}

export function apiDashboardDenominations() {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/dashboard/denominations`, true);
}

export function apiEndOfFilesSurrenderTodayRequests() {
    return getFetch(
        `${API_SERVERLESS}/mypvr/v3/endOfFiles?endOfFileStatus=todayRequest&applicationStatus=G&onlyCount=true`,
        true
    );
}
export function apiEndOfFilesSurrenderDrafts() {
    return getFetch(
        `${API_SERVERLESS}/mypvr/v3/endOfFiles?endOfFileStatus=draft&applicationStatus=G&onlyCount=true`,
        true
    );
}
export function apiEndOfFilesWithdrawalTodayRequests() {
    return getFetch(
        `${API_SERVERLESS}/mypvr/v3/endOfFiles?endOfFileStatus=todayRequest&applicationStatus=A&onlyCount=true`,
        true
    );
}
export function apiEndOfFilesWithdrawalDrafts() {
    return getFetch(
        `${API_SERVERLESS}/mypvr/v3/endOfFiles?endOfFileStatus=draft&applicationStatus=A&onlyCount=true`,
        true
    );
}
