import React, {useEffect, useRef, useState} from 'react';
import CpvoBoxPageTableArea from './Components/CpvoBoxPageTableArea/CpvoBoxPageTableArea';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import {History} from 'history';
import ModalCpvoBox from './Components/ModalCpvoBox/ModalCpvoBox';
import {ModalErrorVersion2} from '../../../../commonModals';
import NavigationTLO from '../../../../shared/NavigationTLO';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {getUserRoles} from '../../CommonFunctions/CommonFunctions';
import {injectIntl, IntlShape} from 'react-intl';
import {apiCpvoBoxFileListSearch, CpvoBoxTableCriteriaData, CpvoBoxTableRowData} from './CpvoBoxPageService';
import {trackPageView} from '../../../../utils';

const DEFAULT_CRITERIA: CpvoBoxTableCriteriaData = {
    pageNumber: 1,
    pageSize: Number(localStorage.getItem('pageSizecpvoBoxFileList')) || 50,
    refresh: false,
    excel: false,
    order: 'registers',
    reverse: false,
};

const DEFAULT_COUNT = 0;

const modalScreenTypes = {ERROR: 'ERROR', CPVO_BOX: 'CPVO_BOX'};

interface CpvoBoxPageProps {
    intl: IntlShape;
    history: History;
}

const CpvoBoxPage = (props: CpvoBoxPageProps & RouteComponentProps) => {
    const [criteria, setCriteria] = useState<CpvoBoxTableCriteriaData>(Object.assign({}, DEFAULT_CRITERIA));
    const [, setCriteriaCount] = useState(0);
    const [cpvoBoxFileList, setCpvoBoxFileList] = useState<Array<CpvoBoxTableRowData> | null>(null);
    const [timestamp, setTimestamp] = useState(0);
    const [count, setCount] = useState(DEFAULT_COUNT);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [refresh, setRefresh] = useState(true);
    const [, setDefaultOrder] = useState('registers');
    const [, setReverseOrder] = useState(false);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const firstLoad = useFirstRender();
    const [userRoles] = useState(getUserRoles());

    useEffect(() => {
        trackPageView({documentTitle: 'tloCpvoBox'});
    }, []);

    function useFirstRender() {
        const firstRender = useRef(true);
        useEffect(() => {
            firstRender.current = false;
        }, []);
        return firstRender.current;
    }

    const showModalCpvoBox = () => setModalScreen(modalScreenTypes.CPVO_BOX);

    const countCriteria = (registersCriteria: CpvoBoxTableCriteriaData) => {
        let countNum = 0;
        Object.keys(registersCriteria).forEach(key => {
            if (
                registersCriteria[key] &&
                registersCriteria[key] !== DEFAULT_CRITERIA[key] &&
                registersCriteria[key] !== ''
            )
                countNum++;
        });
        return countNum;
    };

    const parseCriteria = (unParsedCriteria: CpvoBoxTableCriteriaData): CpvoBoxTableCriteriaData => {
        const parsedCriteria: CpvoBoxTableCriteriaData = JSON.parse(JSON.stringify({}));
        Object.keys(unParsedCriteria).forEach(key => {
            parsedCriteria[key] = unParsedCriteria[key];
        });
        return parsedCriteria;
    };

    const buildParams = (parsedCriteria: CpvoBoxTableCriteriaData) => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(key => parsedCriteria[key] !== DEFAULT_CRITERIA[key] && key !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key]}`);
        const joinedParamsText = `?${paramArray.join('&')}`;
        props.history.replace(`/tloCpvoBox${(paramArray.length > 0 && joinedParamsText) || ''}`);
    };

    const search = (shouldRefresh: boolean, urlLoad = false) => {
        setLoading(true);
        setCriteria(
            Object.assign({}, criteria, {refresh: !!shouldRefresh}, !shouldRefresh && !urlLoad && {pageNumber: 1})
        );
        const parsedCriteria = parseCriteria(criteria);
        buildParams(parsedCriteria);
        apiCpvoBoxFileListSearch(parsedCriteria)
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.uploadsList) {
                    const newCriteria = Object.assign({}, criteria, {pageNumber: 1});
                    if (!shouldRefresh && !urlLoad) {
                        setCriteria(Object.assign({}, newCriteria));
                    }
                    setCpvoBoxFileList(jsonResponse.data.uploadsList);
                    setTimestamp(Date.now());
                    if (jsonResponse.count) {
                        setCount(jsonResponse.count);
                    }
                }
            })
            .catch(error => {
                setErrorMessage(`CPVO Box file search list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const params = domainArray.pop();
            if (params) {
                params.split('&').forEach(param => {
                    const paramElements = param.split('=');
                    if (paramElements.length === 2) {
                        const key = paramElements[0];
                        criteria[key] = decodeURIComponent(paramElements[1]);
                    }
                });
                if (Object.keys(criteria).length > 0) {
                    setCriteria(Object.assign({}, criteria));
                    criteria.order && setDefaultOrder(criteria.order);
                    criteria.reverse && setReverseOrder(criteria.reverse);
                    setCriteriaCount(countCriteria(criteria));
                    search(false, true);
                }
            }
        }
    };

    const closeModal = (doesCpvoBoxModalClose: boolean) => {
        setModalScreen(null);
        doesCpvoBoxModalClose && search(false, true);
    };

    useEffect(() => {
        if (firstLoad) {
            loadUrlParams();
            criteria.pageSize = Number(localStorage.getItem('pageSizecpvoBoxFileList')) || 50;
            setCriteria(Object.assign({}, criteria));
        }
    }, []);

    useEffect(() => {
        !firstLoad && refresh && search(true);
    }, [criteria.pageNumber, criteria.pageSize, criteria.order, criteria.reverse]);

    useEffect(() => {
        firstLoad && search(false, true);
    }, [criteria.order, criteria.reverse]);

    const updateCriteriaValue = (
        // eslint-disable-next-line no-undef
        criteriaValue: Partial<CpvoBoxTableCriteriaData>,
        callback = () => {},
        shouldRefresh = true
    ) => {
        setRefresh(shouldRefresh);
        if (criteriaValue !== undefined) {
            setCriteria({...criteria, ...criteriaValue});
        }
        setCriteriaCount(countCriteria(criteria));
        callback && callback();
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeModal} />
            ) : null}
            {modalScreen === modalScreenTypes.CPVO_BOX ? <ModalCpvoBox intl={props.intl} close={closeModal} /> : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`Technical Liaison Officer`} />
            <NavigationTLO />
            <MainWrapper>
                <div
                    style={{
                        width: cpvoBoxFileList ? 'calc(100vw - 15px)' : 'calc(100vw)',
                        marginBottom: 100,
                        textAlign: 'center',
                    }}
                >
                    <FormWrapper>
                        {userRoles.length === 1 && userRoles.indexOf('EOVIS') === 0 ? (
                            <b style={{color: 'red'}}>
                                <span className="ng-scope">{`You have no permissions to access this page.`}</span>
                            </b>
                        ) : (
                            <strong>
                                <span className="ng-scope">{`Welcome to CPVO Box`}</span>
                            </strong>
                        )}
                    </FormWrapper>
                    {userRoles.length === 1 && userRoles.indexOf('EOVIS') === 0 ? null : (
                        <CpvoBoxPageTableArea
                            intl={props.intl}
                            count={count}
                            timestamp={timestamp}
                            criteria={criteria}
                            cpvoBoxFileList={cpvoBoxFileList}
                            showModalCpvoBox={showModalCpvoBox}
                            updateCriteriaValue={updateCriteriaValue}
                        />
                    )}
                </div>
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(CpvoBoxPage));
