import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';

function renderButton(popupContent: string, handler: () => void, icon: any) {
    return {
        title: popupContent,
        icon: () => {
            return (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon} />
                </a>
            );
        },
        handler,
    };
}

export default function CommunicationCentreClientArchiveActionButtons(
    onTableIconEditClick: () => void,
    onTableIconDeleteClick: () => void
) {
    const buttons = [];
    buttons.push(renderButton('Edit', () => onTableIconEditClick(), faEdit));
    buttons.push(renderButton('Delete', () => onTableIconDeleteClick(), faTrashAlt));
    return buttons;
}
