import React from 'react';
import {injectIntl} from 'react-intl';
import {ModalCustomVersion2} from '../../../../commonModals';
import TextInput from '~components/TextInput';
import {apiBackOfficeGetClient, apiBackOfficeSearchClients} from './ResponseTextModalClientNumberService';
import {LoadingBar} from '../../../../componentsLayout';
import CustomTable from '~components/CustomTable';
import {Button} from '../../../../componentsFormV2';

const RESULT_FIELDS = ['clientId', 'name', 'address'];

const PAYLOAD_FIELD_MAP_MESSAGE_ID = {
    city: [
        21980, 21989, 22008, 21928, 21945, 602, 19215, 21961, 18475, 10688, 10116, 22055, 22070, 122, 18339, 37966,
        37976, 42846, 42865,
    ],
    clientName: [21986, 22004, 21925, 19205, 614, 21972, 18465, 10689, 22071, 18329, 124, 21969, 10112],
    clientNumber: [21979, 599, 19211, 22015, 21957, 14319, 18471, 18932, 10110, 18335],
    countryName: [
        21981, 21990, 21885, 21890, 22006, 19283, 21926, 21943, 600, 19213, 21959, 26203, 25682, 18473, 18543, 661,
        24301, 25034, 10686, 26098, 10117, 26661, 26388, 23680, 24205, 22053, 22060, 23549, 22068, 25348, 18407, 24840,
        25134, 24047, 24626, 18337, 25487, 31262, 32240, 34431, 37180, 36974, 37688, 37977, 37914, 37967, 42783, 42847,
        42866,
    ],
    officialAddress: [
        628, 19266, 22005, 21929, 21941, 21942, 603, 611, 19216, 19217, 19225, 18476, 18477, 18485, 18526, 5314, 10690,
        10113, 22056, 22072, 18390, 18340, 18341, 18349, 10114, 37964, 37974, 42844, 42863, 21978, 21987, 21968, 18931,
        13527,
    ],
    postCode: [
        21927, 10687, 21973, 21988, 22007, 21944, 19214, 21960, 18474, 601, 10115, 22054, 22069, 18338, 31390, 37965,
        37975, 42845, 42864,
    ],
};

const getResponseKeysIdsMapForModalClientSearch = question => {
    const responses = (question && question.responses) || [];
    const payloadFieldsMapResponsesIds = {};
    Object.entries(PAYLOAD_FIELD_MAP_MESSAGE_ID).forEach(([payloadField, messageIds]) => {
        let indx = messageIds.findIndex(messageId => responses.find(el => el.messageId === messageId));
        payloadFieldsMapResponsesIds[payloadField] = (responses.find(el => el.messageId === messageIds[indx]) || {}).id;
    });
    return payloadFieldsMapResponsesIds;
};

class ResponseTextModalClientNumber extends React.Component {
    constructor(props) {
        super(props);
        this.clientFieldsResponseIds = getResponseKeysIdsMapForModalClientSearch(props.question);
        this.state = {
            loading: false,
            clientNumber: null,
            clients: null,
            timestamp: new Date(),
        };
    }

    onClientNumberChange = ({target: {value: clientNumber}}) => this.setState({clientNumber});

    onSearch = () =>
        this.setState({loading: true, clients: []}, () => {
            apiBackOfficeSearchClients(this.state.clientNumber || '')
                .then(({data}) => {
                    this.setState({
                        clients: [...data],
                    });
                })
                .catch(error => {
                    ERROR`ResponseTextModalClientNumber: ${error.message}`;
                })
                .finally(() => this.setState({loading: false, timestamp: new Date()}));
        });

    onClientNumberRowClick = clientCode => {
        this.setState({loading: true}, () => {
            apiBackOfficeGetClient(clientCode)
                .then(({data: client}) => {
                    let updatedResponsesClient = {...this.props.screenData.responsesClient};
                    Object.keys(client).forEach(responseKey => {
                        updatedResponsesClient[
                            `${this.props.questionKeyWithIteration}_R${
                                this.clientFieldsResponseIds && this.clientFieldsResponseIds[responseKey]
                            }_1`
                        ] = client[responseKey];
                    });
                    this.props.updateScreenData({responsesClient: updatedResponsesClient, hash: Date.now()});
                    this.props.close();
                })
                .catch(error => {
                    ERROR`ResponseTextModalClientNumber: ${error.message}`;
                })
                .finally(() => this.setState({loading: false}));
        });
    };

    render() {
        const {loading, clients} = this.state;
        return (
            <>
                <ModalCustomVersion2
                    close={this.props.close}
                    plus={2}
                    header={`Search client`}
                    body={
                        <>
                            <TextInput
                                onChange={this.onClientNumberChange}
                                value={this.state.clientNumber}
                                outsideLabel={`Client number`}
                            />
                            <div style={{clear: 'both'}} />
                            {!loading && clients && (
                                <CustomTable
                                    version={2}
                                    {...this.props}
                                    notSortable={RESULT_FIELDS}
                                    tableName={'clientNumbers'}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.clients || []}
                                    timestamp={this.state.timestamp}
                                    id={'clientId'}
                                    resultFieldsDefault={RESULT_FIELDS}
                                    hideExcelButton={true}
                                    intl={this.props.intl}
                                    defaultOrder={'clientId'}
                                    count={(this.state.clients || []).length}
                                    rowClick={this.onClientNumberRowClick}
                                />
                            )}
                        </>
                    }
                    footer={
                        <>
                            {loading ? (
                                <LoadingBar />
                            ) : (
                                <>
                                    <Button icon={'close'} clickAction={this.props.close}>{`Close`}</Button>
                                    <Button icon={'arrowRight'} clickAction={this.onSearch}>{`Search`}</Button>
                                </>
                            )}
                        </>
                    }
                />
            </>
        );
    }
}

export default injectIntl(ResponseTextModalClientNumber);
