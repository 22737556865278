import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';
import styles from './ModalLogicType.module.scss';

const ModalLogicTypeForbiddenResponseHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <div>
                    <p className={styles.deprecated}>DEPRECATED</p>
                    <p>This logic can be applied to a question several times.</p>
                    <p>
                        If there is a response to a question for which the logic has been defined, invalidate (delete)
                        the response selected if a response has been entered by the user.
                    </p>
                </div>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeForbiddenResponseHelp;
