import React from 'react';
import cx from 'classnames';
import styles from './FirstRowLabel.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfo, faPlus} from '@fortawesome/free-solid-svg-icons';

export default function ({children, mandatory, outsideLabel, popOverTextFirstRow, onButtonAddFromListClick}) {
    return (
        <React.Fragment>
            <div className={cx(styles.label, outsideLabel && styles.outsideLabel)}>{children}</div>
            {mandatory ? <div className={styles.mandatory}>*</div> : null}
            {popOverTextFirstRow ? (
                <div className={styles.popoverIconInfoWrap} style={{float: 'right', marginLeft: 10}}>
                    <div className={styles.popoverWrap}>{popOverTextFirstRow}</div>
                    <span className={styles.iconInfoWrap}>
                        <FontAwesomeIcon
                            icon={faInfo}
                            color="green"
                            fontSize={7}
                            style={{width: 7, padding: 2, border: '1px solid', borderRadius: '50%'}}
                        />
                    </span>
                </div>
            ) : null}
            {onButtonAddFromListClick ? (
                <div className={styles.buttonAddFromList} onClick={onButtonAddFromListClick}>
                    <FontAwesomeIcon icon={faPlus} /> add from list
                </div>
            ) : null}
            <div style={{clear: 'both'}} />
        </React.Fragment>
    );
}
