import React from 'react';
import styles from './FormFooter.module.scss';
import cx from 'classnames';

interface IFormFooterProps {
    children?: any;
    className?: any;
    left?: any;
    right?: any;
    mt?: 0;
}

export default function ({children, className, left, right, mt}: IFormFooterProps) {
    return (
        <footer
            className={cx(
                styles.formFooterWrap,
                mt !== undefined ? styles.marginTopZero : null,
                className ? className : null
            )}
        >
            {children}
            {left || right ? (
                <div className={styles.footerGrid}>
                    {left ? <div className={styles.gridLeft}>{left}</div> : null}
                    {right ? <div className={styles.gridRight}>{right}</div> : null}
                </div>
            ) : null}
        </footer>
    );
}
