import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export function apiTemplateSearch(criteria, DEFAULT_CRITERIA = {}) {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return getFetch(`${API_SERVERLESS}/backOffice/v2/templates?${requestParams.join('&')}`, true);
}

export function apiBackOfficeNewVersionTemplate(jsonRequest) {
    return postFetch(`${API_SERVERLESS}/backOffice/v2/newVersionTemplate`, true, jsonRequest);
}

export function apiBackOfficeDeleteTemplate(jsonRequest) {
    return postFetch(`${API_SERVERLESS}/backOffice/v2/deleteTemplate`, true, jsonRequest);
}
