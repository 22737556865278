import React from 'react';
import CustomTable from '~components/CustomTable';
import VDCCasesActionButtons from './VDCCasesActionButtons';
import {apiVDCCasesGet, IVDCCase} from './VDCCasesService';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {loadUrlParams} from '~utils';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import {Button} from '../../componentsFormV2';
import Navigation from '../../componentsLayout/Navigation';
import {trackPageView} from '../../utils';

interface IProps {
    intl: any;
    history: any;
}

interface IState {
    loading: number;
    cases: IVDCCase[];
    screenLoaded: boolean;
    timestamp: number;
}

class VDCCases extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: 0,
            cases: [],
            screenLoaded: false,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'VDCCases'});
        this.loadJSONs();
    }

    loadJSONs = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiVDCCasesGet().then(JSONResponse => {
                    if (JSONResponse) {
                        this.setState(prev => ({
                            loading: prev.loading - 1,
                            cases: JSONResponse,
                            screenLoaded: true,
                        }));
                    }
                });
            }
        );

    onTableIconSelectCaseClick = (caseId: number) => this.props.history.push(`/VDCCaseMessage?caseId=${caseId}`);

    onButtonGoBackClick = () => this.props.history.goBack();

    render() {
        const actions = VDCCasesActionButtons(this.onTableIconSelectCaseClick);
        const {goBack} = loadUrlParams();

        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Pending VDC cases`} />
                <Navigation />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        {this.state.screenLoaded ? (
                            <>
                                {goBack ? (
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button
                                            variation={'tertiary'}
                                            clickAction={this.onButtonGoBackClick}
                                            icon={'back'}
                                        >{`Go back to case`}</Button>
                                    </div>
                                ) : null}
                                <CustomTable
                                    actions={actions}
                                    tableName={'VDCCases'}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.cases}
                                    timestamp={this.state.timestamp}
                                    id={'caseId'}
                                    resultFieldsDefault={['title', 'caseId', 'submissionDate']}
                                    intl={this.props.intl}
                                    notSortable={['title', 'caseId', 'submissionDate']}
                                    unlimited={true}
                                    rowClick={this.onTableIconSelectCaseClick}
                                    headerSizes={{submissionDate: 80}}
                                />
                            </>
                        ) : null}
                    </FormWrapper>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(VDCCases));
