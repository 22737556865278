import React, {useEffect, useState} from 'react';
import styles from './TqSearchTqsCriteriaArea.module.scss';
import {TqInfoData, TqQuestionInfoData, TqSearchFilterCriteriaData} from '../../TqSearchPageService';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import Title from '~components/Title';
import TextLabelInput from '~components/TextLabelInput';
import FormFooterLinkButton from '~componentsForm/FormFooterLinkButton';
import {loadUrlParams} from '~utils';

export interface TqSearchTqsCriteriaAreaProps {
    questionnaires: Array<TqInfoData>;
    questions: Array<TqQuestionInfoData>;
    fetchQuestions: (tqId: number) => void;
    updateCriteriaValue: (
        // eslint-disable-next-line no-undef
        criteriaValue: Partial<TqSearchFilterCriteriaData>,
        callback?: () => void,
        refresh?: boolean
    ) => void;
}

export default function TqSearchTqsCriteriaArea(props: TqSearchTqsCriteriaAreaProps) {
    const [questionnaireId, setQuestionnaireId] = useState(0);
    const [questionnaireNames, setQuestionnaireNames] = useState<Array<{id: number; value: string}>>([
        {
            id: 0,
            value: 'Please select a TQ',
        },
    ]);
    const [versionCode, setVersionCode] = useState('');
    const [commonName, setCommonName] = useState('');
    const [responsesListByIdNamePerQuestion, setResponsesListByIdNamePerQuestion] = useState<{
        [key: string]: Array<{ID: string; NAME: string}>;
    }>({});
    const [responsesNameHashPerQuestion, setResponsesNameHashPerQuestion] = useState<{
        [key: string]: Array<{[key: string]: Array<string>}>;
    }>({});
    const [selectedResponsesPerQuestion, setSelectedResponsesPerQuestion] = useState<{
        [key: string]: {[key: string]: string};
    }>({});
    const [responseNamePerQuestion, setResponseNamePerQuestion] = useState<{[key: string]: string}>({});
    const [initialQuestionsLoaded, setInitialQuestionsLoaded] = useState(false);

    useEffect(() => {
        props.questionnaires.length > 0 && updateQuestionnaireNames(props.questionnaires);
        props.questionnaires.length === 1 && updateUniqueQuestionnaireId(props.questionnaires);
    }, [props.questionnaires]);

    useEffect(() => {
        if (questionnaireId > 0) {
            updateVersionCode(props.questionnaires, questionnaireId);
            updateCommonName(props.questionnaires, questionnaireId);
            props.fetchQuestions(questionnaireId);
        }
    }, [questionnaireId]);

    useEffect(() => {
        if (props.questions.length > 0) {
            groupResponsesListByIdNameByQuestion(props.questions, 'questionName');
            groupResponsesNameHashByQuestion(props.questions, 'questionName');
            if (!initialQuestionsLoaded) {
                const URLParams = loadUrlParams();
                if (URLParams && URLParams.responseIds) {
                    let initialState: any = {};
                    let parsedResponseIds = JSON.parse(URLParams.responseIds);
                    Object.entries(parsedResponseIds).forEach(([questionName, responseIdsArray]: any) => {
                        if (!initialState[questionName]) {
                            initialState[questionName] = {};
                        }
                        responseIdsArray.forEach((responseId: any) => {
                            let responseLabel = '';
                            let responseElement = props.questions.find(
                                question => question.responseId === parseInt(responseId)
                            );
                            if (responseElement) {
                                responseLabel = `${responseElement.score} - ${responseElement.responseName}`;
                            }
                            initialState[questionName][responseId] = responseLabel;
                        });
                    });
                    setSelectedResponsesPerQuestion(initialState);
                }
            }
            setInitialQuestionsLoaded(true);
        }
    }, [props.questions]);

    const updateQuestionnaireNames = (questionnaires: Array<TqInfoData>) => {
        const finalQuestionnaireNames: Array<{id: number; value: string}> = questionnaireNames;
        questionnaires.forEach(tq =>
            finalQuestionnaireNames.push({
                id: tq.questionnaireId,
                value: tq.name,
            })
        );
        setQuestionnaireNames(finalQuestionnaireNames);
    };

    const updateUniqueQuestionnaireId = (questionnaires: Array<TqInfoData>) =>
        setQuestionnaireId(questionnaires[0].questionnaireId);

    const updateVersionCode = (questionnaires: Array<TqInfoData>, questionnaireId: number) => {
        const selectedQuestionnaires = questionnaires.filter(el => el.questionnaireId === questionnaireId);
        selectedQuestionnaires.length > 0 && setVersionCode(selectedQuestionnaires[0].versionCode);
    };

    const updateCommonName = (questionnaires: Array<TqInfoData>, questionnaireId: number) => {
        const selectedQuestionnaires = questionnaires.filter(el => el.questionnaireId === questionnaireId);
        selectedQuestionnaires.length > 0 && setCommonName(selectedQuestionnaires[0].nameCommon);
    };

    const onQuestionnaireNameChange = ({target: {value: questionnaireId}}: React.ChangeEvent<HTMLSelectElement>) =>
        setQuestionnaireId(Number(questionnaireId));

    const groupResponsesListByIdNameByQuestion = (questions: Array<TqQuestionInfoData>, key: string) => {
        const finalResponsesListByIdNamePerQuestion: {[key: string]: Array<{ID: string; NAME: string}>} =
            questions.reduce((result: {[key: string]: Array<{ID: string; NAME: string}>}, currentValue) => {
                (result[currentValue[key]] = result[currentValue[key]] || []).push({
                    ID: currentValue.responseId.toString(),
                    NAME: currentValue.score + ' - ' + currentValue.responseName,
                });

                return result;
            }, {});
        setResponsesListByIdNamePerQuestion(finalResponsesListByIdNamePerQuestion);
    };

    const groupResponsesNameHashByQuestion = (questions: Array<TqQuestionInfoData>, key: string) => {
        const finalResponsesNameHashPerQuestion: {[key: string]: Array<{[key: string]: Array<string>}>} =
            questions.reduce((result: {[key: string]: Array<{[key: string]: Array<string>}>}, currentValue) => {
                (result[currentValue[key]] = result[currentValue[key]] || []).push({
                    [currentValue.score + ' - ' + currentValue.responseName]: [currentValue.responseId.toString()],
                });

                return result;
            }, {});
        setResponsesNameHashPerQuestion(finalResponsesNameHashPerQuestion);
    };

    const updateResponsesIds = (selectedResponsesPerQuestion: {[key: string]: {[key: string]: string}}) => {
        const responseIds: {[key: string]: Array<string>} = {};
        for (const [key, value] of Object.entries(selectedResponsesPerQuestion)) {
            responseIds[key] = Object.keys(value);
        }
        props.updateCriteriaValue({responseIds: JSON.stringify(responseIds)});
    };

    const selectAllResponsesForEveryQuestion = (questions: Array<TqQuestionInfoData>, key: string) => {
        const finalSelectedResponsesPerQuestion: {[key: string]: {[key: string]: string}} = questions.reduce(
            (result: {[key: string]: {[key: string]: string}}, currentValue) => {
                result[currentValue[key]] = result[currentValue[key]] || {};
                result[currentValue[key]][currentValue.responseId] =
                    currentValue.score + ' - ' + currentValue.responseName;

                return result;
            },
            {}
        );
        setSelectedResponsesPerQuestion(finalSelectedResponsesPerQuestion);
        updateResponsesIds(finalSelectedResponsesPerQuestion);
    };

    const getResponsesListByIdNameForSpecificQuestion = (questionName: string) =>
        responsesListByIdNamePerQuestion[questionName];

    const getResponsesNameHashForSpecificQuestion = (questionName: string) => {
        let result: {[key: string]: Array<string>} = {};
        for (const responseNameHash of responsesNameHashPerQuestion[questionName]) {
            result = Object.assign({}, result, responseNameHash);
        }
        return result;
    };

    const onResponseChange = (responseName: string, questionName: string) =>
        setResponseNamePerQuestion(Object.assign({}, responseNamePerQuestion, {[questionName]: responseName}));

    const onResponsesSelectionChange = (selectedResponses: {[key: string]: string}, questionName: string) => {
        const newSelectedResponsesPerQuestion: {[key: string]: {[key: string]: string}} = Object.assign(
            {},
            selectedResponsesPerQuestion,
            {[questionName]: selectedResponses}
        );
        setSelectedResponsesPerQuestion(newSelectedResponsesPerQuestion);
        updateResponsesIds(newSelectedResponsesPerQuestion);
    };

    const unselectAllResponsesForSpecificQuestion = (questionName: string) => {
        const newSelectedResponsesPerQuestion: {[key: string]: {[key: string]: string}} = Object.assign(
            {},
            selectedResponsesPerQuestion
        );
        delete newSelectedResponsesPerQuestion[questionName];
        setSelectedResponsesPerQuestion(newSelectedResponsesPerQuestion);
        updateResponsesIds(newSelectedResponsesPerQuestion);
    };

    const selectAllResponsesForSpecificQuestion = (questionName: string) => {
        const responsesForSpecificQuestion = props.questions.filter(qn => qn.questionName === questionName);
        const newSelectedResponsesPerQuestion: {[key: string]: {[key: string]: string}} = Object.assign(
            {},
            selectedResponsesPerQuestion
        );
        responsesForSpecificQuestion.forEach(resp => {
            newSelectedResponsesPerQuestion[questionName] = newSelectedResponsesPerQuestion[questionName] || {};
            newSelectedResponsesPerQuestion[questionName][resp.responseId] = resp.score + ' - ' + resp.responseName;
        });
        setSelectedResponsesPerQuestion(newSelectedResponsesPerQuestion);
        updateResponsesIds(newSelectedResponsesPerQuestion);
    };

    const unselectAllResponsesForEveryQuestion = () => {
        setSelectedResponsesPerQuestion({});
        props.updateCriteriaValue({responseIds: undefined});
    };

    return (
        <>
            <SelectInput
                value={questionnaireId}
                onChange={onQuestionnaireNameChange}
                list={questionnaireNames}
                outsideLabel={'TQ Name'}
                outsideLabelWidth={160}
                notAll={true}
                double={true}
            />
            {questionnaireId > 0 ? (
                <>
                    <TextInput
                        value={versionCode}
                        double={true}
                        outsideLabel={`Version Code`}
                        outsideLabelWidth={160}
                        disabled={true}
                    />
                    <TextInput
                        value={commonName}
                        double={true}
                        outsideLabel={`Common Name`}
                        outsideLabelWidth={160}
                        disabled={true}
                    />
                </>
            ) : null}
            {props.questions.length > 0 ? (
                <div className="TqQuestionsArea">
                    <Title triple={true}>{`Questions`}</Title>
                    <div style={{marginBottom: 10, float: 'right'}}>
                        <FormFooterLinkButton clickAction={() => unselectAllResponsesForEveryQuestion()}>
                            {`Unselect all responses`}
                        </FormFooterLinkButton>
                        <FormFooterLinkButton
                            clickAction={() => selectAllResponsesForEveryQuestion(props.questions, 'questionName')}
                        >
                            {`Select all responses`}
                        </FormFooterLinkButton>
                    </div>
                    <div style={{clear: 'both'}} />
                    {Object.keys(responsesListByIdNamePerQuestion).map(questionName => (
                        <React.Fragment key={questionName}>
                            <div className={styles.questionWrap}>
                                <div className={styles.linkSelectResponsesContainer}>
                                    <FormFooterLinkButton
                                        clickAction={() => unselectAllResponsesForSpecificQuestion(questionName)}
                                    >
                                        {`None`}
                                    </FormFooterLinkButton>
                                    <FormFooterLinkButton
                                        clickAction={() => selectAllResponsesForSpecificQuestion(questionName)}
                                    >
                                        {`All`}
                                    </FormFooterLinkButton>
                                </div>
                                <TextLabelInput
                                    double={true}
                                    onSelectionChange={(selectedResponses: {[key: string]: string}) =>
                                        onResponsesSelectionChange(selectedResponses, questionName)
                                    }
                                    onChange={(responseName: string) => onResponseChange(responseName, questionName)}
                                    value={responseNamePerQuestion[questionName] || ''}
                                    selectedElements={selectedResponsesPerQuestion[questionName] || {}}
                                    delay={300}
                                    outsideLabel={' '}
                                    outsideLabelWidth={1}
                                    listByIdName={getResponsesListByIdNameForSpecificQuestion(questionName)}
                                    nameHash={getResponsesNameHashForSpecificQuestion(questionName)}
                                    noInfo={true}
                                    label={questionName}
                                    useAsMultipleSelection={true}
                                />
                                <div style={{clear: 'both'}} />
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            ) : null}
        </>
    );
}
