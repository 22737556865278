import React from 'react';

interface IIconChangeProps {
    style?: any;
    className?: any;
    color?: string;
}

export default function ({style: userStyle, color, className}: IIconChangeProps) {
    return (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{...(userStyle || {})}}
            className={className}
        >
            <path
                d="M4.08325 15.0541H14.0833M4.08325 15.0541L6.99992 12.1374M4.08325 15.0541L6.99992 17.9707M6.58325 6.72074H17.4166M17.4166 6.72074L14.4999 3.80408M17.4166 6.72074L14.4999 9.63741"
                stroke={color || 'white'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
