import {API_SERVERLESS, getFetch} from './index';
import {IChapterItem, ICountryItem, ISpeciesItem} from '../types';

export function apiPublicationCharpters(): Promise<IChapterItem[]> {
    return getFetch(`${API_SERVERLESS}/common/v3/chapters`, false).then(jsonResponse => {
        if (jsonResponse) {
            return jsonResponse;
        }
    });
}

export function apiSpecies(): Promise<ISpeciesItem[]> {
    return getFetch(`${API_SERVERLESS}/common/v3/species`, false);
}

export function apiCountries(): Promise<ICountryItem[]> {
    return getFetch(`${API_SERVERLESS}/common/v3/countries/en`, false).then(jsonResponse => {
        if (jsonResponse) {
            return jsonResponse;
        }
    });
}
