import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt, IconDefinition} from '@fortawesome/free-regular-svg-icons';
import {UploadedFileData} from '../../../../TLO/CommonComponents/ModalDocument/Interfaces/ModalDocumentInterfaces';
import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons';

function renderButton(
    popupContent: string,
    handler: (fileName: string, rowObject: UploadedFileData) => void,
    icon: IconDefinition,
    translationsUploaded: boolean,
    replaceFileMode: boolean
) {
    const arrowJsx = (popupContent: string) => (
        <a data-content={popupContent}>
            <FontAwesomeIcon icon={icon as any} />
        </a>
    );
    const iconFn = (rowObject: UploadedFileData) => {
        switch (icon) {
            case faTrashAlt:
                return (rowObject.languageId === 1 && translationsUploaded) || replaceFileMode ? (
                    <div></div>
                ) : (
                    arrowJsx(popupContent)
                );
            case faExchangeAlt:
                return replaceFileMode ? <div></div> : arrowJsx(popupContent);
            default:
                return arrowJsx(popupContent);
        }
    };

    return {
        title: popupContent,
        icon: iconFn,
        handler,
    };
}

export default function ModalContentDocumentActionButtons(
    proceedToDeleteFile: (uuid?: string) => void,
    proceedToReplaceFile: (uuid?: string) => void,
    translationsUploaded: boolean,
    replaceFileMode: boolean = false
) {
    const buttons = [];
    buttons.push(
        renderButton(
            'Replace',
            (_fileName: string, rowObject: UploadedFileData) => {
                proceedToReplaceFile(rowObject?.uuid);
            },
            faExchangeAlt,
            translationsUploaded,
            replaceFileMode
        )
    );
    buttons.push(
        renderButton(
            'Delete',
            (_fileName: string, rowObject: UploadedFileData) => {
                proceedToDeleteFile(rowObject?.uuid);
            },
            faTrashAlt,
            translationsUploaded,
            replaceFileMode
        )
    );
    return buttons;
}
