import {API_MYPVR_URL, API_SERVERLESS, getFetch, postFetch} from '~commonApi';
import {getEnvironment} from '~utils/index';

export function apiChangeUser(jsonData: any) {
    return postFetch(`${API_SERVERLESS}/common/v3/change/user`, true, jsonData).then((jsonResponse: any) => {
        if (jsonResponse && jsonResponse.token) {
            if (jsonResponse.token) {
                const ie = !!navigator.userAgent.match(/Trident\/7\./);
                const remember = localStorage.getItem('remember') === '1';
                if (ie) {
                    let prefix = '';
                    switch (getEnvironment()) {
                        case 'DEV':
                            prefix = 'dev';
                            break;
                        case 'DEMO':
                            prefix = 'uat';
                            break;
                        default:
                            prefix = '';
                            break;
                    }
                    const expirationDate = new Date();
                    expirationDate.setTime(expirationDate.getTime() + 365 * 24 * 60 * 60 * 1000);
                    const expires = ''; //';expires=' + expirationDate.toUTCString();
                    document.cookie = `${prefix !== '' ? `${prefix}-` : ''}token=${
                        jsonResponse.token
                    }${expires};domain=.plantvarieties.eu;path=/;Secure;`;
                } else {
                    (remember ? localStorage : sessionStorage).setItem('token', jsonResponse.token);
                    (remember ? localStorage : sessionStorage).setItem('refresh-token', jsonResponse.refreshToken);
                }
                return jsonResponse;
            }
        }
    });
}

export function apiVerifyDelay(contactId: any) {
    return getFetch(`${API_MYPVR_URL}/authentification/verifdelay?contactid=${contactId}`, false).then(
        (jsonResponse: any) => {
            if (jsonResponse) {
                LOG([`Define password JSON response: ${JSON.stringify(jsonResponse, null, 4)}`]);
                if (jsonResponse.error) {
                    throw new Error(jsonResponse.message || 'Error');
                }
                return jsonResponse;
            }
        }
    );
}

export function apiMyPVRDashboardNextFeeDueDate() {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/dashboard/nextFeeDueDate?feeType=5`, true);
}

export function apiDefinePassword(jsonData: any) {
    return postFetch(`${API_SERVERLESS}/v2/authentification/definepassword`, true, jsonData).then(
        (jsonResponse: any) => {
            if (jsonResponse) {
                LOG([`Define password JSON response: ${JSON.stringify(jsonResponse, null, 4)}`]);
                if (jsonResponse.error) {
                    throw new Error(jsonResponse.message || 'Error');
                }
                return jsonResponse;
            }
        }
    );
}

export function apiDecrypt(jsonData: {params: {data: string; getWebsite: boolean}}) {
    return postFetch(`${API_MYPVR_URL}/tools/decrypt`, false, jsonData).then((jsonResponse: any) => {
        if (jsonResponse) {
            LOG([`Decrypt JSON response: ${JSON.stringify(jsonResponse, null, 4)}`]);
            if (jsonResponse.error) {
                throw new Error(jsonResponse.message || 'Error');
            }
            return jsonResponse;
        }
    });
}

export function apiVarietyFinderCheckIfTermsAccepted(): Promise<{accepted: boolean}> {
    return getFetch(`${API_SERVERLESS}/publicSearch/v3/varietyFinder/checkIfTermsAccepted`, true);
}

export function apiCommunicationCentreClientSearchInbox() {
    return getFetch(`${API_SERVERLESS}/communicationCentreClient/v4/CCCIS/inbox/search?readingStatus=2`, true);
}

export function apiCommunicationCentreInternalSearchInbox(query: any) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreInternal/v4/CCIIS/inbox/search?readingStatus=2&counter=1${
            query ? `&${query}` : ''
        }`,
        true
    );
}
