import React from 'react';
import styles from './ModalApplicationList.module.scss';
import {ModalCustomVersion2} from '../../commonModals';
import {Button} from '../../componentsFormV2';
import TextAreaInput from '~components/TextAreaInput';

export default class ModalApplicationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: '',
            invalid: true,
        };
    }

    import = () => {
        if (!this.state.invalid) {
            const regexp = RegExp('(((199[5-9])|(20([0|1|2]\\d)))((?!0000)\\d{4}))', 'g');
            const str = this.state.content;
            const matches = [];
            let match;

            while ((match = regexp.exec(str)) !== null) {
                matches.push(match[0]);
            }
            this.props.importList(matches);
            this.props.close();
        }
    };

    close = reload => this.props.close && this.props.close(reload);

    onInputChange = ({target: {value}}) => {
        const parsedValue = value.replace(/\//g, '');
        this.setState({
            content: parsedValue,
            invalid:
                !/^(((199[5-9])|(20([0|1|2]\d)))((?!0000)\d{4}))(((?!\d)(.|(\n)*))+(((199[5-9])|(20([0|1|2]\d)))((?!0000)\d{4})))*(\n)*$/.test(
                    parsedValue
                ),
        });
    };

    render() {
        return (
            <ModalCustomVersion2
                close={this.close}
                header={`Import application numbers`}
                body={
                    <div
                        style={{
                            height: 400,
                            mintHeight: 400,
                            overflowY: 'auto',
                        }}
                    >
                        <TextAreaInput
                            label={`Please introduce the list of application numbers that you would like to include. The format is not correct. You need to include application numbers which are groups of 8 digits with a non-digit separator.`}
                            triple={true}
                            value={this.state.content}
                            onChange={this.onInputChange}
                            rows={25}
                        />
                        {this.state.content && this.state.invalid && (
                            <div
                                className={styles.errorFormat}
                            >{`The format is not correct. The format is not correct. You need to include application numbers which are groups of 8 digits with a non-digit separator.`}</div>
                        )}
                    </div>
                }
                footer={
                    <>
                        <Button variation={'danger'} clickAction={this.close} icon={'close'}>{`Close`}</Button>
                        <Button
                            clickAction={this.import}
                            icon={'arrowRight'}
                            disabled={this.state.invalid}
                        >{`Import`}</Button>
                    </>
                }
            />
        );
    }
}
