export const COMMUNICATION_TYPES = [
    {id: 'none', value: 'Please select a communication type'},
    {id: 'ApplicationProcedure', value: 'Application procedure'},
    {id: 'Denominations', value: `Denominations`},
    {id: 'Financial', value: 'Financial'},
    {id: 'LegalProceedings', value: `Legal proceedings`},
    // {id: 'ApplicationProcedure', value: 'MyPVR Correspondence'},
    {id: 'OtherMatters', value: `Other matters`},
    {id: 'TechnicalExamination', value: 'Technical examination'},
    {id: 'WithdrawalSurrender', value: `Withdrawal/Surrender documents`},
];

export const COMMUNICATION_TYPE = {
    ApplicationProcedure: 'Application procedure',
    Denominations: 'Denominations',
    Financial: 'Financial',
    LegalProceedings: 'Legal proceedings',
    // applicationProcedure: 'MyPVR Correspondence',
    OtherMatters: 'Other matters',
    TechnicalExamination: 'Technical examination',
    WithdrawalSurrender: 'Withdrawal/Surrender documents',
};
