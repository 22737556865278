import React from 'react';
import styles from './QuestionModalSearchAndAddTopCaption.module.scss';
import cx from 'classnames';

export default function () {
    return (
        <div className={styles.container}>
            <div className={styles.captionContainer}>
                <div
                    className={cx(styles.label, styles.labelYellow)}
                >{`Rows in yellow are from a different office`}</div>
            </div>
            <div style={{clear: 'both'}} />
        </div>
    );
}
