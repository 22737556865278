import {API_SERVERLESS, getFetch, postFetch} from '../../../../commonApi';

export interface ScopeOfEntrustmentTableRowData {
    addressName: string;
    examContractId: number;
}

export interface ScopeOfEntrustmentParamsData {
    data: {
        annexList: Array<ScopeOfEntrustmentTableRowData>;
        token: string;
    };
    count: number;
}

export interface ScopeOfEntrustmentTableCriteriaData {
    pageNumber: number;
    refresh: boolean;
    excel: boolean;

    [key: string]: string | number | boolean | undefined;
}

export function apiScopeOfEntrustmentSearch(
    // eslint-disable-next-line no-undef
    criteria: Partial<ScopeOfEntrustmentTableCriteriaData>
): Promise<ScopeOfEntrustmentParamsData> {
    const params = {...criteria};
    const requestParams = Object.keys(params).map(k => `${k}=${params[k]}`);
    return getFetch(`${API_SERVERLESS}/tlo/annex1?${requestParams.join('&')}`, true);
}

export function apiScopeOfEntrustmentDownload(
    examContractId: number,
    excelTranslations: {[key: string]: string} = {}
): Promise<ScopeOfEntrustmentParamsData> {
    return postFetch(`${API_SERVERLESS}/tlo/annex1/${examContractId}?excel=true`, true, excelTranslations);
}
