import {API_SERVERLESS, getFetch} from '~commonApi';
import {apiCommunicationCentreGetCommunicationIdUploadFilesAndSendMessage} from '../CommunicationCentreInternal/CommunicationCentreInternalService';
import {ICommunicationMessageInternalSubmitJSONRequest, IDocument} from '../../types';

export function apiCommunicationCentreModalInternalReplyMessageReply(
    JSONRequest: ICommunicationMessageInternalSubmitJSONRequest,
    documentList: IDocument[]
) {
    return apiCommunicationCentreGetCommunicationIdUploadFilesAndSendMessage(JSONRequest, documentList, {
        getCommunicationId: 'communicationCentreModalInternal/v4/CCMIREGID/reply/getCommunicationId',
        documentUploadFile: 'communicationCentreModalInternal/v4/CCMIREUF/reply/documentUploadFile',
        submitRequest: 'communicationCentreModalInternal/v4/CCMIREMRE/reply/messageReply',
    });
}

export function apiCommunicationCentreModalInternalReplyMessageRead(communicationId: number) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIREMR/reply/messageRead/${communicationId}`,
        true
    );
}

export function apiCommunicationCentreModalInternalReplyCCList() {
    return getFetch(`${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIRECC/reply/ccList`, true);
}
