import React from 'react';
import cx from 'classnames';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {joinParams, loadUrlParams} from '~utils';
import {
    apiFormDatas,
    apiFormDraftCheckIfUpdated,
    apiGetFormDraft,
    apiOnlineApplicationRequestUpdate,
} from './OnlineFormDraftService';
import FormWithBackground, {backgrounds} from '~shared/FormWithBackground';
import WizardSteps from '../Wizard/WizardSteps';
import WizardWrapper, {IWizardState} from '../Wizard/WizardWrapper';
import {getGroupsIdsIterationsMap, getTableQuestionsKeysIterationsMap, getValidatedSteps} from '../Wizard/utils';
import styles from './OnlineFormDraft.module.scss';
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import OtherFormsButton from './modals/OtherFormsButton/OtherFormsButton';
import {buildFormDraftUrl} from '../OnlineApplications/utils';
import {cyrb53, formatFormLastUpdateDate, prepareResponsesClient} from './OnlineFormDraft.utils';
import {IntlContext} from '../../context/IntlContext';
import {IDraftFormFullListItem} from '../../types';
import {EMAIL_OFFICE} from '../../commonModals/ModalContactUsVersion2';
import {Footer, HeaderLoading, HeaderLogoMenu, HeaderTitleAndVersion, MainWrapper} from '../../componentsLayout';
import NavigationOnlineApplications from '../../shared/NavigationOnlineApplications';
import {ModalConfirmVersion2, ModalCustomVersion2} from '../../commonModals';
import {Button} from '../../componentsFormV2';
import {trackPageView} from '../../utils';

const SCREENS = {
    SEARCH: 'search',
};

const ERROR_TYPE_FORM_CHECK_OR_UPDATE = {
    UPDATED_BY_ANOTHER_USER: 'UPDATED_BY_ANOTHER_USER',
    ALREADY_SIGNED: 'ALREADY_SIGNED',
    NOT_AUTHORIZED: 'NOT_AUTHORIZED',
    RELOAD_BECAUSE_OF_INACTIVITY: 'RELOAD_BECAUSE_OF_INACTIVITY',
    CONNECTION_WAS_LOST: 'CONNECTION_WAS_LOST',
};

interface IProps {
    intl: any;
    history: any;
}

interface IState extends IWizardState {
    assistedEntries: any;
    loading: number;
    applicationId: any;
    applicationCode: string;
    breederReference: string | null;
    currentStepNumber: number;
    formId: number;
    formSequence: string;
    formValidityBegin: string;
    formType: number;
    formsFullList: IDraftFormFullListItem[];
    formLanguage: string;
    isPopupOtherFormsOpen: boolean;
    modalAlertMessage: string;
    modalAlertSaveChangesBeforeLeavingPage: string | null;
    modalAlertSaveChangesBeforeLeavingPageRefresh: boolean;
    modalAlertContinueToTQ: boolean;
    errorTypeFormCheckOrUpdate:
        | (typeof ERROR_TYPE_FORM_CHECK_OR_UPDATE)[keyof typeof ERROR_TYPE_FORM_CHECK_OR_UPDATE]
        | null;
    errorContactNameFormCheckOrUpdate: string;
    errorFormLastUpdateDateCheckOrUpdate: string;
    flashLastSavedMessage: boolean;
}

class OnlineFormDraft extends WizardWrapper<IProps, IState> {
    static contextType = IntlContext;
    urlParams: any = loadUrlParams();
    formLastUpdateDate: any = null;
    screenLastUpdateDate: any = Date.now();
    responsesClientHash: any = null;
    intervalIdForUpdatingResponsesClient: any = null;

    constructor(props: IProps) {
        super(props);
        this.state = {
            ...this.state,
            assistedEntries: null,
            loading: 0,
            applicationId: null,
            applicationCode: '',
            breederReference: '',
            formLanguage: '',
            formValidityBegin: '',
            isPopupOtherFormsOpen: false,
            modalAlertMessage: '',
            modalAlertSaveChangesBeforeLeavingPage: null,
            modalAlertContinueToTQ: false,
            errorTypeFormCheckOrUpdate: null,
            errorContactNameFormCheckOrUpdate: '',
            errorFormLastUpdateDateCheckOrUpdate: '',
            flashLastSavedMessage: false,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'formDraft'});
        this.loadJSONs();
        this.setState(prev => ({...prev, screenData: {...prev.screenData, hasClickedOnSaveAndValidate: true}}));
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (
            this.state.screenData.hasClickedOnSaveAndValidate &&
            this.state.screenData?.hash !== prevState.screenData?.hash
        ) {
            this.setState(prevState => ({
                validatedSteps: getValidatedSteps({
                    groupsQuestion: prevState.groupsQuestion,
                    groupsIdsIterationsMap: prevState.screenData.groupsIdsIterationsMap,
                    tableQuestionsKeysIterationsMap: prevState.screenData.tableQuestionsKeysIterationsMap,
                    responsesClient: prevState.screenData.responsesClient,
                }),
            }));
        }
    }

    componentWillUnmount() {
        if (this.intervalIdForUpdatingResponsesClient) {
            clearInterval(this.intervalIdForUpdatingResponsesClient);
        }
        if (!window.location.href.includes('formDraft')) {
            (this.context as any).switchLanguage('en');
        }
    }

    loadJSONs() {
        const promises = [this.loadDatas(), this.loadFormDraft()];
        Promise.all(promises).catch(error => LOG([`Error loading data: ${error}`]));
    }

    loadDatas = () =>
        new Promise(resolve => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    const {applicationId, formType, formId} = this.urlParams || {};
                    apiFormDatas({applicationRequestId: applicationId, formType, formId})
                        .then(
                            (jsonResponseDatas: any) =>
                                jsonResponseDatas &&
                                jsonResponseDatas.datas &&
                                this.setState((prev: any) => ({
                                    ...prev,
                                    assistedEntries: jsonResponseDatas.datas,
                                    loading: --prev.loading,
                                }))
                        )
                        .then(() => resolve([]));
                }
            );
        });

    loadFormDraft({keepCurrentStepNumber = false} = {}) {
        return new Promise(resolve => {
            const {applicationId, formId, formType, formSequence} = this.urlParams || {};
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiGetFormDraft({applicationId, formId, formType, formSequence}).then((jsonResponse: any) => {
                        let tableQuestionsKeysIterationsMap = getTableQuestionsKeysIterationsMap(
                            jsonResponse.form.groupsQuestion,
                            jsonResponse.responsesClient
                        );
                        const groupsIdsIterationsMap = getGroupsIdsIterationsMap(
                            jsonResponse.form.groupsQuestion,
                            jsonResponse.responsesClient
                        );
                        let currentStepNumber = keepCurrentStepNumber
                            ? this.state.currentStepNumber
                            : jsonResponse.form.groupsQuestion && jsonResponse.form.groupsQuestion.length > 0
                            ? jsonResponse.form.groupsQuestion.findIndex((el: any) => el.isGroup === true)
                            : 0;
                        let stateResponsesClient = prepareResponsesClient({
                            apiResponsesClient: jsonResponse.responsesClient,
                            groupsQuestion: jsonResponse.form.groupsQuestion,
                        });
                        this.setState(
                            prev => ({
                                ...prev,
                                name: jsonResponse.form.name.value,
                                applicationId: jsonResponse.applicationId,
                                applicationCode: jsonResponse.applicationCode,
                                breederReference: jsonResponse.breederReference,
                                formId: jsonResponse.form.id,
                                formsFullList: jsonResponse.formsFullList,
                                formType: jsonResponse.form.type,
                                formSequence: jsonResponse.formSequence,
                                formValidityBegin: jsonResponse.form.validityBegin,
                                groupsQuestion: jsonResponse.form.groupsQuestion,
                                currentStepNumber,
                                formLanguage: jsonResponse.formLanguage,
                                screenData: {
                                    ...prev.screenData,
                                    hash: Date.now(),
                                    responsesClient: {...(stateResponsesClient || {})},
                                    tableQuestionsKeysIterationsMap,
                                    groupsIdsIterationsMap,
                                },
                                loading: prev.loading - 1,
                            }),
                            () => {
                                this.formLastUpdateDate = jsonResponse.timestamp;
                                this.screenLastUpdateDate = Date.now();
                                this.responsesClientHash = cyrb53(
                                    JSON.stringify(this.state.screenData.responsesClient)
                                );
                                (this.context as any).switchLanguage(jsonResponse.formLanguage || 'en');
                                this.setIntervalForUpdatingResponsesClient();
                                resolve([]);
                            }
                        );
                    });
                }
            );
        });
    }

    handleFormUpdateError = ({errorType, contactName, signedBy, timestamp}: any = {}) => {
        clearInterval(this.intervalIdForUpdatingResponsesClient);
        window.scroll({top: 0, behavior: 'smooth'});
        return new Promise((resolve, reject) => {
            try {
                this.setState(
                    {
                        errorTypeFormCheckOrUpdate: null,
                        errorContactNameFormCheckOrUpdate: '',
                        errorFormLastUpdateDateCheckOrUpdate: '',
                    },
                    () => {
                        errorType === ERROR_TYPE_FORM_CHECK_OR_UPDATE.CONNECTION_WAS_LOST &&
                            this.setState({
                                errorTypeFormCheckOrUpdate: ERROR_TYPE_FORM_CHECK_OR_UPDATE.CONNECTION_WAS_LOST,
                            });
                        errorType === ERROR_TYPE_FORM_CHECK_OR_UPDATE.RELOAD_BECAUSE_OF_INACTIVITY &&
                            this.setState({
                                errorTypeFormCheckOrUpdate:
                                    ERROR_TYPE_FORM_CHECK_OR_UPDATE.RELOAD_BECAUSE_OF_INACTIVITY,
                            });
                        errorType === ERROR_TYPE_FORM_CHECK_OR_UPDATE.UPDATED_BY_ANOTHER_USER &&
                            this.setState({
                                errorTypeFormCheckOrUpdate: ERROR_TYPE_FORM_CHECK_OR_UPDATE.UPDATED_BY_ANOTHER_USER,
                                errorContactNameFormCheckOrUpdate: contactName,
                                errorFormLastUpdateDateCheckOrUpdate: timestamp,
                            });
                        errorType === ERROR_TYPE_FORM_CHECK_OR_UPDATE.ALREADY_SIGNED &&
                            this.setState({
                                errorTypeFormCheckOrUpdate: ERROR_TYPE_FORM_CHECK_OR_UPDATE.ALREADY_SIGNED,
                                errorContactNameFormCheckOrUpdate: signedBy,
                            });

                        errorType === ERROR_TYPE_FORM_CHECK_OR_UPDATE.NOT_AUTHORIZED &&
                            this.setState({
                                errorTypeFormCheckOrUpdate: ERROR_TYPE_FORM_CHECK_OR_UPDATE.NOT_AUTHORIZED,
                            });
                    }
                );
            } catch (error) {
                reject(error);
            }
        });
    };

    setIntervalForUpdatingResponsesClient = () => {
        this.intervalIdForUpdatingResponsesClient = setInterval(() => {
            const minutesPassedFromLastUpdatedToClearInterval = 60;
            if (
                parseInt(String((new Date().getTime() - this.screenLastUpdateDate) / 1000 / 60)) >=
                minutesPassedFromLastUpdatedToClearInterval
            ) {
                this.handleFormUpdateError({errorType: ERROR_TYPE_FORM_CHECK_OR_UPDATE.RELOAD_BECAUSE_OF_INACTIVITY});
            } else {
                let currentResponsesClientHash = cyrb53(JSON.stringify(this.state.screenData.responsesClient));
                if (this.responsesClientHash !== currentResponsesClientHash) {
                    apiOnlineApplicationRequestUpdate(this.getPayloadOfFormSaveRequest())
                        .then((JSONResponse: any) => {
                            if (JSONResponse.data === 'OK') {
                                this.formLastUpdateDate = JSONResponse.timestamp;
                                this.responsesClientHash = currentResponsesClientHash;
                                this.screenLastUpdateDate = Date.now();
                                this.setState({
                                    flashLastSavedMessage: true,
                                });
                                setTimeout(() => {
                                    this.setState({
                                        flashLastSavedMessage: false,
                                    });
                                }, 1000);
                            }
                            JSONResponse && JSONResponse.errorType && this.handleFormUpdateError(JSONResponse);
                        })
                        .catch((err: any) => {
                            // err && err.message === 'Failed to fetch' &&
                            this.handleFormUpdateError({
                                errorType: ERROR_TYPE_FORM_CHECK_OR_UPDATE.CONNECTION_WAS_LOST,
                            });
                        });
                } else {
                    apiFormDraftCheckIfUpdated({
                        applicationRequestId: this.state.applicationId,
                        formId: parseInt(String(this.state.formId)),
                        formSequence: parseInt(this.state.formSequence),
                        formType: this.state.formType === 1 ? 'AF' : 'TQ',
                        timestamp: this.formLastUpdateDate,
                    })
                        .then((JSONResponse: any) => {
                            JSONResponse && JSONResponse.errorType && this.handleFormUpdateError(JSONResponse);
                        })
                        .catch((err: any) => {
                            // err && err.message === 'Failed to fetch' &&
                            this.handleFormUpdateError({
                                errorType: ERROR_TYPE_FORM_CHECK_OR_UPDATE.CONNECTION_WAS_LOST,
                            });
                        });
                }
            }
        }, 5000);
    };

    onButtonRetrieveChangesClick = () =>
        this.setState(
            {
                errorTypeFormCheckOrUpdate: null,
                errorContactNameFormCheckOrUpdate: '',
                errorFormLastUpdateDateCheckOrUpdate: '',
            },
            () => this.loadFormDraft({keepCurrentStepNumber: true})
        );

    onButtonGoToSignedApplicationsClick = () => this.props.history.push(`/applications?signed=true`);

    onButtonGoToDraftApplicationsClick = () => this.props.history.push(`/applications`);

    getPayloadOfFormSaveRequest = ({valid: isFormValid}: any = {}) => {
        return {
            applicationRequestId: this.state.applicationId,
            formId: parseInt(String(this.state.formId)),
            formSequence: parseInt(this.state.formSequence),
            responsesClient: this.state.screenData.responsesClient,
            valid: isFormValid || this.checkIfFormIsValid(),
            timestamp: this.formLastUpdateDate,
        };
    };

    getComputedValidatedSteps = () =>
        getValidatedSteps({
            groupsQuestion: this.state.groupsQuestion,
            groupsIdsIterationsMap: this.state.screenData.groupsIdsIterationsMap,
            tableQuestionsKeysIterationsMap: this.state.screenData.tableQuestionsKeysIterationsMap,
            responsesClient: this.state.screenData.responsesClient,
        });

    checkIfFormIsValid = ({computedValidatedSteps}: any = {}) => {
        const validatedSteps = computedValidatedSteps || this.getComputedValidatedSteps();
        return !(validatedSteps || []).some((el: any) => el === false);
    };

    navigateToTQ = () => {
        const nextTQ = this.state.formsFullList.find(form => {
            return form.type === 'TQ' && form.iteration === 0;
        });
        const {questionnaireId} = nextTQ || {};
        window.location.href = buildFormDraftUrl({
            applicationId: this.state.applicationId,
            formType: 'TQ',
            formId: questionnaireId,
            formSequence: 0,
        });
    };

    navigateToAF = () => {
        const nextTQ = this.state.formsFullList.find(form => {
            return form.type === 'AF' && form.iteration === 0;
        });
        const {questionnaireId} = nextTQ || {};
        window.location.href = buildFormDraftUrl({
            applicationId: this.state.applicationId,
            formType: 'AF',
            formId: questionnaireId,
            formSequence: 0,
        });
    };

    navigateToSignature = () =>
        this.props.history.push(`/onlineSignApplication?applicationRequestId=${this.state.applicationId}`);

    onButtonNavigateToNextClick = () => {
        const computedValidatedSteps = this.getComputedValidatedSteps();
        const isFormValid = this.checkIfFormIsValid({computedValidatedSteps});
        const currentResponsesClientHash = cyrb53(JSON.stringify(this.state.screenData.responsesClient));
        const navigateToNextURLWhenCurrentFormIsValid = () => {
            if (this.checkIfOtherFormIsValid()) {
                return this.navigateToSignature();
            }
            return this.state.formType === 1 ? this.navigateToTQ() : this.navigateToAF();
        };
        if (this.responsesClientHash === currentResponsesClientHash) {
            if (isFormValid) {
                navigateToNextURLWhenCurrentFormIsValid();
            } else {
                if (this.state.formType === 1) {
                    this.setState({modalAlertContinueToTQ: true});
                } else {
                    this.navigateToAF();
                }
            }
        } else {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    clearInterval(this.intervalIdForUpdatingResponsesClient);
                    apiOnlineApplicationRequestUpdate(this.getPayloadOfFormSaveRequest({valid: isFormValid}))
                        .then((JSONResponse: any) => {
                            this.setState(
                                prev => ({loading: prev.loading - 1}),
                                () => {
                                    if (JSONResponse && JSONResponse.data === 'OK' && isFormValid === true) {
                                        navigateToNextURLWhenCurrentFormIsValid();
                                    }
                                    if (JSONResponse && JSONResponse.data === 'OK' && isFormValid === false) {
                                        if (this.state.formType === 1) {
                                            this.formLastUpdateDate = JSONResponse.timestamp;
                                            this.responsesClientHash = currentResponsesClientHash;
                                            this.screenLastUpdateDate = Date.now();
                                            this.setIntervalForUpdatingResponsesClient();
                                            this.setState({modalAlertContinueToTQ: true});
                                        } else {
                                            this.navigateToAF();
                                        }
                                    }
                                    JSONResponse && JSONResponse.errorType && this.handleFormUpdateError(JSONResponse);
                                }
                            );
                        })
                        .catch(() => {
                            // err && err.message === 'Failed to fetch' &&
                            this.handleFormUpdateError({
                                errorType: ERROR_TYPE_FORM_CHECK_OR_UPDATE.CONNECTION_WAS_LOST,
                            });
                        });
                }
            );
        }
    };

    goToNextScreen = (screen: string, hardRefresh = false) => {
        let currentResponsesClientHash = cyrb53(JSON.stringify(this.state.screenData.responsesClient));
        if (this.responsesClientHash !== currentResponsesClientHash) {
            this.setState({
                modalAlertSaveChangesBeforeLeavingPage: screen,
                modalAlertSaveChangesBeforeLeavingPageRefresh: hardRefresh,
            });
        } else {
            this.proceedToLeavePageAction(screen, hardRefresh);
        }
    };

    saveResponsesClientAndProceedToLeavePageAction = () => {
        let nextScreen = this.state.modalAlertSaveChangesBeforeLeavingPage;
        let refresh = this.state.modalAlertSaveChangesBeforeLeavingPageRefresh;
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                modalAlertSaveChangesBeforeLeavingPage: null,
                modalAlertSaveChangesBeforeLeavingPageRefresh: false,
            }),
            () => {
                apiOnlineApplicationRequestUpdate(this.getPayloadOfFormSaveRequest())
                    .then((JSONResponse: any) => {
                        this.setState(
                            prev => ({loading: prev.loading - 1}),
                            () => {
                                JSONResponse &&
                                    JSONResponse.data === 'OK' &&
                                    this.proceedToLeavePageAction(nextScreen, refresh);
                                JSONResponse && JSONResponse.errorType && this.handleFormUpdateError(JSONResponse);
                            }
                        );
                    })
                    .catch(() => {
                        // err && err.message === 'Failed to fetch' &&
                        this.handleFormUpdateError({
                            errorType: ERROR_TYPE_FORM_CHECK_OR_UPDATE.CONNECTION_WAS_LOST,
                        });
                    });
            }
        );
    };

    proceedToLeavePageAction = (
        screen = this.state.modalAlertSaveChangesBeforeLeavingPage,
        hardRefresh = this.state.modalAlertSaveChangesBeforeLeavingPageRefresh
    ) => {
        switch (screen) {
            case SCREENS.SEARCH: {
                let urlParams = loadUrlParams();
                delete urlParams.applicationId;
                delete urlParams.formType;
                delete urlParams.formId;
                delete urlParams.formSequence;
                this.props.history.push(`/applications${(urlParams && `?${joinParams(urlParams)}`) || ''}`);
                window.scrollTo(0, 0);
                break;
            }
            default:
                //TODO: useParams hook and reload form on change, remove window.location.href keep only history
                hardRefresh ? ((window as any).location.href = screen) : this.props.history.push(screen);
                LOG([`Next Screen: ${screen}`]);
        }
    };

    closeModalAlertSaveChangesBeforeLeavingPage = () => this.setState({modalAlertSaveChangesBeforeLeavingPage: null});

    closeModalAlertContinueToTQ = () => this.setState({modalAlertContinueToTQ: false});

    checkIfOtherFormIsValid = () => {
        if (this.state.formType === 1) {
            let otherForm = (this.state.formsFullList || []).find(form => form.type === 'TQ');
            return otherForm && otherForm.ISVALID;
        } else {
            let otherForm = (this.state.formsFullList || []).find(form => form.type === 'AF');
            return otherForm && otherForm.ISVALID;
        }
    };

    getButtonTitleNavigateToNext = () => {
        if (!this.state.formType) {
            return null;
        }
        if (this.checkIfFormIsValid()) {
            if (this.state.formType === 1) {
                return this.props.intl.formatMessage({
                    id: this.checkIfOtherFormIsValid() ? 'button.continueToSignature' : 'button.continueToTQ',
                });
            } else {
                return this.props.intl.formatMessage({
                    id: this.checkIfOtherFormIsValid() ? 'button.continueToSignature' : 'button.continueToForm',
                });
            }
        } else {
            return this.props.intl.formatMessage({
                id: this.state.formType === 1 ? 'button.continueToTQ' : 'button.continueToForm',
            });
        }
    };

    render() {
        const buttonTitleNavigateToNext = this.getButtonTitleNavigateToNext();

        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                {this.state.modalAlertContinueToTQ ? (
                    <ModalCustomVersion2
                        size={'sm'}
                        close={this.closeModalAlertContinueToTQ}
                        header={this.props.intl.formatMessage({
                            id: 'general.dataSavedSuccessfully',
                        })}
                        body={
                            <div style={{minHeight: 100}}>
                                <p style={{fontSize: 18, margin: 0}}>
                                    {this.props.intl.formatMessage({
                                        id: 'online.formDraft.someFieldsMayBeIncorrectlyFilled',
                                    })}
                                </p>
                            </div>
                        }
                        footer={
                            <Button clickAction={this.navigateToTQ}>
                                {this.props.intl.formatMessage({
                                    id: 'button.continueToTQ',
                                })}
                            </Button>
                        }
                    />
                ) : null}
                {this.state.modalAlertSaveChangesBeforeLeavingPage ? (
                    <ModalConfirmVersion2
                        title={this.props.intl.formatMessage({
                            id: 'general.unsavedChanges',
                        })}
                        message={this.props.intl.formatMessage({
                            id: 'general.youHaveUnsavedChangesDoYouWantToSaveThem',
                        })}
                        buttonName={this.props.intl.formatMessage({
                            id: 'general.check.yes',
                        })}
                        close={this.closeModalAlertSaveChangesBeforeLeavingPage}
                        action={this.saveResponsesClientAndProceedToLeavePageAction}
                        actionOnNoButtonClick={() => this.proceedToLeavePageAction()}
                    />
                ) : null}
                <HeaderLogoMenu showLogo={(this.state.applicationCode || '').startsWith('B') ? 'NAK' : undefined} />
                <HeaderTitleAndVersion title={`Online Applications`} />
                <NavigationOnlineApplications />
                <MainWrapper>
                    <div className={styles.formWithBackgroundWrap}>
                        <FormWithBackground
                            title={
                                <div className={styles.titleWrap}>
                                    <span>
                                        {this.urlParams && this.urlParams['formType'] === 'AF'
                                            ? this.props.intl.formatMessage({
                                                  id: 'online.formDraft.draftApplicationForm',
                                              })
                                            : this.props.intl.formatMessage({
                                                  id: 'online.formDraft.draftTechnicalQuestionnaire',
                                              })}
                                    </span>
                                    <span className={styles.titleWrapRight}>{this.state.applicationCode}</span>
                                </div>
                            }
                            subTitle={
                                this.state.name ? (
                                    <div
                                        style={{paddingBottom: 15}}
                                        /* eslint-disable-next-line react/no-danger */
                                        dangerouslySetInnerHTML={{__html: this.state.name}}
                                    />
                                ) : null
                            }
                            background={backgrounds.backgroundOA}
                            versionColumnLeftHTML={
                                this.formLastUpdateDate ? (
                                    <div
                                        className={cx(
                                            styles.lastSaveMessageWrap,
                                            this.state.flashLastSavedMessage && styles.flashLastSaveMessageWrap
                                        )}
                                    >{`${this.props.intl.formatMessage({
                                        id: 'online.formDraft.formAutomaticallySavedOn',
                                    })} ${formatFormLastUpdateDate(this.formLastUpdateDate)}`}</div>
                                ) : null
                            }
                        >
                            {this.state.formType === 1 &&
                            this.state.screenData.responsesClient['GR24_1_Q193_1_R449_1'] ? (
                                <div className={styles.breedersReferenceWrap}>
                                    {`Breeder's Reference:`}{' '}
                                    <span style={{fontWeight: 'bold'}}>
                                        {this.state.screenData.responsesClient['GR24_1_Q193_1_R449_1']}
                                    </span>
                                </div>
                            ) : null}
                            {this.state.formType === 2 &&
                            (this.state.breederReference || this.state.formValidityBegin) ? (
                                <div className={styles.breedersReferenceWrap}>
                                    {this.state.breederReference ? (
                                        <div style={{display: 'inline-block'}}>
                                            {`Breeder's Reference:`}{' '}
                                            <span style={{fontWeight: 'bold'}}>{this.state.breederReference}</span>
                                        </div>
                                    ) : null}
                                    {this.state.formValidityBegin ? (
                                        <div
                                            style={Object.assign(
                                                {display: 'inline-block'},
                                                this.state.breederReference && {marginLeft: 15}
                                            )}
                                        >
                                            {`Publication date:`}{' '}
                                            <span style={{fontWeight: 'bold'}}>{this.state.formValidityBegin}</span>
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                            <div className={styles.formTopActions}>
                                <FormFooterButton
                                    color={buttonColor.whiteGray}
                                    clickAction={() => this.goToNextScreen(SCREENS.SEARCH)}
                                    icon={faArrowLeft}
                                    extraStyles={styles.goBackToSearchBtn}
                                >
                                    {this.props.intl.formatMessage({id: 'button.goBackToSearch'})}
                                </FormFooterButton>
                                {buttonTitleNavigateToNext ? (
                                    <FormFooterButton
                                        color={buttonColor.whiteBlack}
                                        clickAction={this.onButtonNavigateToNextClick}
                                        icon={faArrowRight}
                                        style={{marginTop: 10}}
                                        disabled={this.state.loading !== 0}
                                    >
                                        {buttonTitleNavigateToNext}
                                    </FormFooterButton>
                                ) : null}
                                <OtherFormsButton
                                    forms={(this.state.formsFullList || []).map((el: any) => ({
                                        ...el,
                                        applicationId: this.state.applicationId,
                                    }))}
                                    intl={this.props.intl}
                                    loading={this.state.loading}
                                    goToNextScreen={this.goToNextScreen}
                                    currentFormId={this.state.formId}
                                    groupsQuestion={this.state.groupsQuestion}
                                    groupsIdsIterationsMap={this.state.screenData?.groupsIdsIterationsMap}
                                    tableQuestionsKeysIterationsMap={
                                        this.state.screenData?.tableQuestionsKeysIterationsMap
                                    }
                                    responsesClient={this.state.screenData?.responsesClient}
                                    checkIfFormIsValid={this.checkIfFormIsValid}
                                />
                            </div>
                            <div style={{clear: 'both'}} />
                            {this.state.errorTypeFormCheckOrUpdate ? (
                                <div className={styles.formUpdateErrorWrap}>
                                    <div className={styles.formUpdateErrorInner}>
                                        <div className={styles.formUpdateErrorInnerLeft}>
                                            <>
                                                {this.state.errorTypeFormCheckOrUpdate ===
                                                ERROR_TYPE_FORM_CHECK_OR_UPDATE.UPDATED_BY_ANOTHER_USER
                                                    ? `${this.props.intl.formatMessage({
                                                          id: 'online.formDraft.youDontHaveTheLastVersionResponsesHaveBeenModifiedBy',
                                                      })} ${
                                                          this.state.errorContactNameFormCheckOrUpdate
                                                      } ${this.props.intl.formatMessage({
                                                          id: 'online.formDraft.onDate',
                                                      })} ${formatFormLastUpdateDate(
                                                          this.state.errorFormLastUpdateDateCheckOrUpdate
                                                      )}`
                                                    : null}
                                            </>
                                            <>
                                                {this.state.errorTypeFormCheckOrUpdate ===
                                                ERROR_TYPE_FORM_CHECK_OR_UPDATE.ALREADY_SIGNED
                                                    ? `${this.props.intl.formatMessage({
                                                          id: 'online.formDraft.youCantEditTheFormIsAlreadySignedBy',
                                                      })} ${this.state.errorContactNameFormCheckOrUpdate}`
                                                    : null}
                                            </>
                                            <>
                                                {this.state.errorTypeFormCheckOrUpdate ===
                                                ERROR_TYPE_FORM_CHECK_OR_UPDATE.NOT_AUTHORIZED
                                                    ? this.props.intl.formatMessage({
                                                          id: 'online.formDraft.youDontHaveAccessToEdit',
                                                      })
                                                    : null}
                                            </>
                                            <>
                                                {this.state.errorTypeFormCheckOrUpdate ===
                                                ERROR_TYPE_FORM_CHECK_OR_UPDATE.RELOAD_BECAUSE_OF_INACTIVITY
                                                    ? this.props.intl.formatMessage({
                                                          id: 'online.formDraft.youCannotModifyThisFormDueToInactivityPeriod',
                                                      })
                                                    : null}
                                            </>
                                            <>
                                                {this.state.errorTypeFormCheckOrUpdate ===
                                                ERROR_TYPE_FORM_CHECK_OR_UPDATE.CONNECTION_WAS_LOST
                                                    ? this.props.intl.formatMessage({
                                                          id: 'online.formDraft.theConnectionWithTheServerWasLost',
                                                      })
                                                    : null}
                                            </>
                                        </div>
                                        <div className={styles.formUpdateErrorInnerRight}>
                                            {this.state.errorTypeFormCheckOrUpdate ===
                                            ERROR_TYPE_FORM_CHECK_OR_UPDATE.UPDATED_BY_ANOTHER_USER ? (
                                                <FormFooterButton
                                                    color={buttonColor.whiteBlue}
                                                    clickAction={this.onButtonRetrieveChangesClick}
                                                >
                                                    {this.props.intl.formatMessage({
                                                        id: 'button.retrieveChanges',
                                                    })}
                                                </FormFooterButton>
                                            ) : null}
                                            {this.state.errorTypeFormCheckOrUpdate ===
                                            ERROR_TYPE_FORM_CHECK_OR_UPDATE.ALREADY_SIGNED ? (
                                                <FormFooterButton
                                                    color={buttonColor.whiteBlue}
                                                    clickAction={this.onButtonGoToSignedApplicationsClick}
                                                >
                                                    {this.props.intl.formatMessage({
                                                        id: 'button.signedApplications',
                                                    })}
                                                </FormFooterButton>
                                            ) : null}
                                            {this.state.errorTypeFormCheckOrUpdate ===
                                            ERROR_TYPE_FORM_CHECK_OR_UPDATE.NOT_AUTHORIZED ? (
                                                <FormFooterButton
                                                    color={buttonColor.whiteBlue}
                                                    clickAction={this.onButtonGoToDraftApplicationsClick}
                                                >
                                                    {this.props.intl.formatMessage({
                                                        id: 'button.draftApplications',
                                                    })}
                                                </FormFooterButton>
                                            ) : null}
                                            {this.state.errorTypeFormCheckOrUpdate ===
                                                ERROR_TYPE_FORM_CHECK_OR_UPDATE.RELOAD_BECAUSE_OF_INACTIVITY ||
                                            this.state.errorTypeFormCheckOrUpdate ===
                                                ERROR_TYPE_FORM_CHECK_OR_UPDATE.CONNECTION_WAS_LOST ? (
                                                <FormFooterButton
                                                    color={buttonColor.whiteBlue}
                                                    clickAction={this.onButtonRetrieveChangesClick}
                                                >
                                                    {this.props.intl.formatMessage({
                                                        id: 'button.reloadForm',
                                                    })}
                                                </FormFooterButton>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            <div className={styles.wizardStepsContainer}>
                                <div className={cx(this.state.errorTypeFormCheckOrUpdate && styles.wizardBlur)}>
                                    <WizardSteps
                                        applicationCode={this.state.applicationCode}
                                        assistedEntries={this.state.assistedEntries}
                                        currentStepNumber={this.state.currentStepNumber}
                                        editable={false}
                                        formId={this.state.formId}
                                        formLanguage={this.state.formLanguage}
                                        getStepState={this.getStepState}
                                        goToStepByReference={this.goToStepByReference}
                                        getCurrentStep={this.getCurrentStep}
                                        goToStep={this.goToStep}
                                        loadFormQuestionsJSONs={this.loadJSONs}
                                        removeQuestion={() => null}
                                        removeResponse={() => null}
                                        setStepGroupCallables={() => null}
                                        screenData={this.state.screenData}
                                        steps={this.state.groupsQuestion}
                                        updateScreenData={this.updateScreenData}
                                        validatedSteps={this.state.validatedSteps}
                                        responsesClientHash={this.responsesClientHash}
                                    />
                                </div>
                            </div>
                        </FormWithBackground>
                    </div>
                </MainWrapper>
                <Footer
                    emailOffice={(this.state.applicationCode || '').startsWith('B') ? EMAIL_OFFICE.NL : undefined}
                />
            </>
        );
    }
}

export default withRouter(injectIntl(OnlineFormDraft));
