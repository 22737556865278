import React from 'react';
import cx from 'classnames';
import styles from './RemarkMessage.module.scss';

export default function RemarkMessage({
    remarkMessage,
    isRemarkTranslationMissing,
}: {
    remarkMessage: string | null;
    isRemarkTranslationMissing?: boolean;
}) {
    if (!remarkMessage) return null;

    return (
        <p
            className={cx(styles.remark, isRemarkTranslationMissing && styles.translationMissing)}
            ref={el => {
                el && (el.innerHTML = remarkMessage);
            }}
        />
    );
}
