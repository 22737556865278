import React from 'react';
import {injectIntl} from 'react-intl';
import {saveAs} from 'file-saver';
import {withRouter} from 'react-router-dom';
import styles from './OnlineSignApplication.module.scss';
import {getDecodedJWT, loadUrlParams} from '~utils';
import {
    apiApplicationRequestAttachmentDownload,
    apiCommonGetPDF,
    apiOnlineSignApplicationDetails,
    apiOnlineSignApplicationRequest,
} from './OnlineSignApplicationService';
import TextInput from '~components/TextInput';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import CustomTable from '~components/CustomTable';
import getIcon from '~utils/icons';
import {getEnvironment, trackPageView} from '../../utils';
import OnlineSignApplicationActions from './OnlineSignApplicationActions';
import {buildFormDraftUrl} from '../OnlineApplications/utils';
import {getPreSignedURLFetchRequest} from '../../utils/requests';
import {IDecodedJWT, IDocumentSignApplication} from '../../types';
import {ModalAlertVersion2, ModalPaymentInfoVersion2} from '../../commonModals';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationOnlineApplications from '../../shared/NavigationOnlineApplications';

const ERROR_TYPE_FORM_CHECK_OR_UPDATE = {
    UPDATED_BY_ANOTHER_USER: 'UPDATED_BY_ANOTHER_USER',
    ALREADY_SIGNED: 'ALREADY_SIGNED',
    NOT_AUTHORIZED: 'NOT_AUTHORIZED',
    APPLICATION_FORMS_NOT_VALID: 'APPLICATION_FORMS_NOT_VALID',
};

const DECLARATION_MESSAGES_CPVO = [
    'I (We) hereby apply for the grant of a Community plant variety right.',
    'Authorisation is hereby given to the Community Plant Variety Office to exchange with the Examination Office and other competent authorities all necessary information and material related to the variety provided that the rights of the applicant are safeguarded.',
    'I (We) hereby declare that, to the best of my (our) knowledge, the information necessary for the examination of the application, given in this form and the annexes, is accurate and complete.',
    'I (We) hereby declare that no person other than the person or persons mentionned in this application has been involved in the breeding, or discovery and development, of the variety.',
];

const DECLARATION_MESSAGES_NAK = [
    "I (We) hereby apply for Dutch plant breeders' right and/or national listing in the Dutch variety register.",
    'Authorisation is hereby given to the Board for Plant Varieties to exchange with Naktuinbouw and other competent authorities all necessary information and material related to the variety provided that the rights of the applicant are safeguarded.',
    'I (We) hereby declare that, to the best of my (our) knowledge, the information necessary for the examination of the application, given in this form and the annexes, is complete and correct.',
];

const formatFormLastUpdateDate = (dateTime: string) => {
    let dateObj = new Date(dateTime);
    let calendarDate =
        `0${dateObj.getDate()}`.slice(-2) + '/' + `0${dateObj.getMonth() + 1}`.slice(-2) + '/' + dateObj.getFullYear();
    return `${calendarDate}, ${dateObj.toLocaleTimeString()}`;
};

interface IProps {
    location: any;
    history: any;
    intl: any;
}

interface IState {
    loading: number;
    applicationRequestId: string;
    admins: string[];
    documents: IDocumentSignApplication[];
    isModalAlertOpen: boolean;
    errorType: any;
    errorMessage: string;
    officeId: number | null;
    timestampAF: string;
    timestampTQ: string;
    authorizedToSign: boolean | null;
    applicationRequestCode: string;
    applicationRequestSpeciesInput: string;
    isModalApplicationSignedOpen: boolean;
    applicationSignedDate: string;
    timestamp: number;
    screenLoaded: boolean;
}

class OnlineSignApplication extends React.Component<IProps, IState> {
    decodedJWT: IDecodedJWT = getDecodedJWT();
    isThePreviousScreenTheSearchScreen: boolean;

    constructor(props: IProps) {
        super(props);
        this.isThePreviousScreenTheSearchScreen = (props.location.search || '').includes('originScreen=applications');
        this.state = {
            loading: 0,
            applicationRequestId: '',
            admins: [],
            documents: [],
            isModalAlertOpen: false,
            errorType: null,
            errorMessage: '',
            officeId: null,
            timestampAF: '',
            timestampTQ: '',
            authorizedToSign: null,
            applicationRequestCode: '',
            applicationRequestSpeciesInput: '',
            isModalApplicationSignedOpen: false,
            applicationSignedDate: '',
            timestamp: Date.now(),
            screenLoaded: false,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'onlineSignApplication'});
        this.loadJSONs();
    }

    loadJSONs = () => {
        const {applicationRequestId} = loadUrlParams();
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiOnlineSignApplicationDetails({applicationRequestId}).then(JSONResponse => {
                    const {
                        admins,
                        documents,
                        timestampAF,
                        timestampTQ,
                        authorizedToSign,
                        applicationRequestCode,
                        applicationRequestSpeciesInput,
                        officeId,
                    } = JSONResponse || {};
                    const populateStateObject: any = {};
                    admins && (populateStateObject.admins = admins);
                    documents && (populateStateObject.documents = documents);
                    timestampAF && (populateStateObject.timestampAF = timestampAF);
                    timestampTQ && (populateStateObject.timestampTQ = timestampTQ);
                    authorizedToSign && (populateStateObject.authorizedToSign = authorizedToSign);
                    officeId && (populateStateObject.officeId = officeId);
                    applicationRequestCode && (populateStateObject.applicationRequestCode = applicationRequestCode);
                    applicationRequestSpeciesInput &&
                        (populateStateObject.applicationRequestSpeciesInput = applicationRequestSpeciesInput);
                    this.setState(prev => ({
                        ...populateStateObject,
                        loading: prev.loading - 1,
                        applicationRequestId,
                        timestamp: Date.now(),
                        screenLoaded: true,
                    }));
                });
            }
        );
    };

    // downloadFile = attachment => {
    //     const {type, filename, verificationCode} = attachment || {};
    //     if (type === 'AF' || type === 'TQ') {
    //         let onlinePdfURL = `/onlinePdf?vc=${verificationCode}`;
    //         window.open(onlinePdfURL, '_blank');
    //         // let formFilename = `${filename}.pdf`;
    //         // apiApplicationRequestAttachmentDownload({
    //         //     filename: formFilename,
    //         //     applicationRequestCode: this.state.applicationRequestCode || `applicationCode`,
    //         // }).then(blob => saveAs(blob, filename));
    //     } else {
    //         apiApplicationRequestAttachmentDownload({
    //             filename,
    //             applicationRequestCode: this.state.applicationRequestCode || `applicationCode`,
    //         }).then(blob => saveAs(blob, filename));
    //     }
    // };

    downloadFormOrAttachment = (rowObject: IDocumentSignApplication) => {
        const {type, filename, verificationCode} = rowObject || {};
        if (type === 'AF' || type === 'TQ') {
            const {applicationRequestCode, applicationRequestSpeciesInput} = this.state;
            let subdomain = getEnvironment() === 'LOCALHOST' ? 'dev' : getEnvironment().toLowerCase();
            let ORIGIN_URL = `https://${subdomain}.plantvarieties.eu`;
            let URL = `${encodeURIComponent(`${ORIGIN_URL}/onlinePdf?vc=`)}${verificationCode}`;
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiCommonGetPDF({
                        URL,
                        applicationNumber: applicationRequestCode,
                        species: applicationRequestSpeciesInput,
                    }).then((blob: any) => {
                        saveAs(blob, filename);
                        this.setState(prev => ({
                            ...prev,
                            loading: prev.loading - 1,
                        }));
                    });
                }
            );
        } else {
            apiApplicationRequestAttachmentDownload({
                filename,
                applicationRequestCode: this.state.applicationRequestCode || `applicationCode`,
            }).then((response: any) => {
                if (response && response.signedUrl) {
                    const {signedUrl, filename} = response;
                    getPreSignedURLFetchRequest(signedUrl)
                        .then(response => response.blob())
                        .then(responseBlob => {
                            saveAs(responseBlob, filename);
                        });
                }
            });
        }
    };

    closeModalAlert = () => this.setState({isModalAlertOpen: false});

    goToOnlinePDF = (rowObject: IDocumentSignApplication) => {
        const {verificationCode} = rowObject || {};
        let onlinePDFURL = `/onlinePdf?vc=${verificationCode}`;
        this.props.history.push(onlinePDFURL);
    };

    goToFormDraft = (rowObject: IDocumentSignApplication) => {
        const {questionnaireId, type, itQuest} = rowObject || {};
        let formDraftURL = buildFormDraftUrl({
            applicationId: this.state.applicationRequestId,
            formType: type,
            formId: questionnaireId,
            formSequence: itQuest,
        });
        this.props.history.push(formDraftURL);
    };

    goToLatestSignedApplications = () =>
        this.props.history.push('/applications?signed=true&order=signatureDate&reverse=true');

    onButtonGoBackClick = () => this.props.history.goBack();

    onButtonGoToApplicationsScreen = () => this.props.history.push('/applications');

    onButtonSignApplicationClick = () => {
        this.setState(
            prev => ({...prev, loading: prev.loading + 1, isModalAlertOpen: false, errorType: null}),
            () => {
                apiOnlineSignApplicationRequest({
                    applicationRequestId: this.state.applicationRequestId,
                    timestampAF: this.state.timestampAF,
                    timestampTQ: this.state.timestampTQ,
                }).then((JSONResponse: any) => {
                    this.setState(
                        prev => ({loading: prev.loading - 1}),
                        () => {
                            if (JSONResponse && JSONResponse.data === 'OK' && JSONResponse.signedDate) {
                                this.setState({
                                    applicationSignedDate: JSONResponse.signedDate,
                                    isModalApplicationSignedOpen: true,
                                });
                                return;
                            }
                            if (JSONResponse && JSONResponse.errorType) {
                                JSONResponse.errorType ===
                                    ERROR_TYPE_FORM_CHECK_OR_UPDATE.APPLICATION_FORMS_NOT_VALID &&
                                    this.setState({
                                        isModalAlertOpen: true,
                                        errorMessage: `This application has obsolete forms (either AF or TQ). You may go back to search screen and modify your forms.`,
                                    });
                                JSONResponse.errorType === ERROR_TYPE_FORM_CHECK_OR_UPDATE.NOT_AUTHORIZED &&
                                    this.setState({
                                        isModalAlertOpen: true,
                                        errorMessage: `You don't have access to sign this application anymore`,
                                    });
                                JSONResponse.errorType === ERROR_TYPE_FORM_CHECK_OR_UPDATE.ALREADY_SIGNED &&
                                    this.setState({
                                        isModalAlertOpen: true,
                                        errorMessage: JSONResponse.signedBy
                                            ? `You can't sign this application because it is already signed by ${JSONResponse.signedBy}`
                                            : `You can't sign this application because it is already signed`,
                                    });
                                if (
                                    JSONResponse.errorType === ERROR_TYPE_FORM_CHECK_OR_UPDATE.UPDATED_BY_ANOTHER_USER
                                ) {
                                    let errorMessageAF =
                                        JSONResponse.contactNameAF && JSONResponse.timestampAF
                                            ? `the Application Form is modified by ${
                                                  JSONResponse.contactNameAF
                                              } on ${formatFormLastUpdateDate(JSONResponse.timestampAF)}`
                                            : '';
                                    let errorMessageTQ =
                                        JSONResponse.contactNameTQ && JSONResponse.timestampTQ
                                            ? `the Technical Questionnaire is modified by ${
                                                  JSONResponse.contactNameTQ
                                              } on ${formatFormLastUpdateDate(JSONResponse.timestampTQ)}`
                                            : '';

                                    let errorFrom = [errorMessageAF, errorMessageTQ].filter(i => !!i).join(' and ');
                                    this.setState({
                                        isModalAlertOpen: true,
                                        errorMessage: errorFrom
                                            ? `You can't sign this application because ${errorFrom}`
                                            : `You can't sign this application because it is updated by another user`,
                                    });
                                }
                                return;
                            }
                            return this.setState({
                                isModalAlertOpen: true,
                                errorMessage:
                                    'Something went wrong while signing the application. Please try again later',
                            });
                        }
                    );
                });
            }
        );
    };

    render() {
        const actions = OnlineSignApplicationActions(this.goToFormDraft);
        return (
            <>
                {this.state.isModalAlertOpen ? (
                    <ModalAlertVersion2
                        title={`Sign error`}
                        message={this.state.errorMessage}
                        close={this.closeModalAlert}
                    />
                ) : null}
                {this.state.isModalApplicationSignedOpen ? (
                    <ModalPaymentInfoVersion2
                        confirm={this.goToLatestSignedApplications}
                        applicationRequestCode={this.state.applicationRequestCode}
                        applicationSpeciesInput={this.state.applicationRequestSpeciesInput}
                        applicationSignDateString={this.state.applicationSignedDate}
                        officeId={this.state.officeId}
                    />
                ) : null}
                <div className={styles.screenWrap}>
                    {this.state.loading ? <HeaderLoading /> : null}
                    <HeaderLogoMenu />
                    <HeaderTitleAndVersion title={`Sign an application`} />
                    <NavigationOnlineApplications />
                    <MainWrapper>
                        <div style={{textAlign: 'center'}}>
                            <FormWrapper paddingFormContent={'sm'}>
                                <div className={styles.section}>
                                    <div
                                        className={styles.sectionTitle}
                                    >{`PDF files and joint attachments(s) ready to download`}</div>
                                    <div className={styles.sectionContent}>
                                        {this.state.screenLoaded && (
                                            <CustomTable
                                                version={2}
                                                {...this.props}
                                                tableName={'signApplicationFormsAndAttachments'}
                                                tableType={'OBJECT'}
                                                tableSource={this.state.documents}
                                                dataFilter={null}
                                                id={'index'}
                                                setLastCursor={null}
                                                resultFieldsDefault={['filenameDisplay']}
                                                intl={this.props.intl}
                                                formatFunctions={{
                                                    filenameDisplay: (filenameDisplay: string, rowObject: any) => (
                                                        <div
                                                            onClick={() =>
                                                                ['AF', 'TQ'].includes(rowObject.type)
                                                                    ? this.goToOnlinePDF(rowObject)
                                                                    : null
                                                            }
                                                            className={
                                                                ['AF', 'TQ'].includes(rowObject.type)
                                                                    ? styles.customTableLink
                                                                    : undefined
                                                            }
                                                        >
                                                            {filenameDisplay}
                                                        </div>
                                                    ),
                                                }}
                                                count={this.state.documents.length}
                                                hideExcelButton={true}
                                                noChangePageSize={true}
                                                forehandColumn={(row: any) => {
                                                    return (
                                                        <img
                                                            style={{width: 20, height: 20, cursor: 'pointer'}}
                                                            alt={'Download'}
                                                            src={getIcon(
                                                                (row.filename || '').split('.').pop() || 'pdf'
                                                            )}
                                                            onClick={event => {
                                                                event.stopPropagation();
                                                                this.downloadFormOrAttachment(row);
                                                            }}
                                                        />
                                                    );
                                                }}
                                                timestamp={this.state.timestamp}
                                                actions={actions}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className={styles.section}>
                                    <div className={styles.sectionTitle}>{`Signature`}</div>
                                    {this.state.applicationRequestId ? (
                                        <div className={styles.sectionContent}>
                                            {this.state.authorizedToSign === true ? (
                                                <>
                                                    <div className={styles.sectionSubtitle}>{`Declarations`}</div>
                                                    <ul>
                                                        {((this.state.applicationRequestCode || '').slice(0, 1) === 'B'
                                                            ? DECLARATION_MESSAGES_NAK
                                                            : DECLARATION_MESSAGES_CPVO
                                                        ).map(message => (
                                                            <li key={message} className={styles.listItem}>
                                                                {message}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </>
                                            ) : (
                                                <>
                                                    <div>
                                                        You are currently not authorised to sign applications. Please
                                                        contact one of the following persons:
                                                    </div>
                                                    <ul>
                                                        {(this.state.admins || []).map((admin: string) => (
                                                            <li key={admin}>{admin}</li>
                                                        ))}
                                                    </ul>
                                                </>
                                            )}
                                            {this.state.authorizedToSign === true && (
                                                <div className={styles.textInputFullWidthWrap}>
                                                    <TextInput
                                                        value={
                                                            this.decodedJWT &&
                                                            `${this.decodedJWT.name} ${this.decodedJWT.forename}`
                                                        }
                                                        onChange={() => null}
                                                        width={804}
                                                        disabled={true}
                                                    />
                                                </div>
                                            )}
                                            <div className={styles.buttonsWrap}>
                                                {this.state.authorizedToSign === true && (
                                                    <FormFooterButton
                                                        color={buttonColor.green}
                                                        clickAction={this.onButtonSignApplicationClick}
                                                    >{`Sign application and submit to the office`}</FormFooterButton>
                                                )}
                                                {!this.isThePreviousScreenTheSearchScreen && (
                                                    <FormFooterButton
                                                        color={buttonColor.orange}
                                                        clickAction={this.onButtonGoBackClick}
                                                    >{`Go back and modify your forms`}</FormFooterButton>
                                                )}
                                                <FormFooterButton
                                                    color={buttonColor.orange}
                                                    clickAction={this.onButtonGoToApplicationsScreen}
                                                >{`Go back to search`}</FormFooterButton>
                                            </div>
                                            <div style={{clear: 'both'}} />
                                        </div>
                                    ) : null}
                                </div>
                            </FormWrapper>
                        </div>
                    </MainWrapper>
                    <Footer />
                </div>
            </>
        );
    }
}

export default withRouter(injectIntl(OnlineSignApplication));
