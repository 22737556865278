import React from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {loadUrlParams} from '~utils';
import {apiCommonGetPDF, apiFormDatas, apiGetFormDraft, apiGetFormDraftWithVerificationCode} from './OnlinePdfService';
import WizardStepsAll from '../Wizard/WizardStepsAll';
import WizardWrapper, {IWizardState} from '../Wizard/WizardWrapper';
import {getGroupsIdsIterationsMap, getTableQuestionsKeysIterationsMap} from '../Wizard/utils';
import {cyrb53, prepareResponsesClient} from './OnlinePdf.utils';
import {HeaderLoading} from '../../componentsLayout';
import {faEuroSign, faFilePdf} from '@fortawesome/free-solid-svg-icons';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import styles from './OnlinePdf.module.scss';
import {getEnvironment, isUserOfficeCPVO, trackPageView} from '../../utils';
import {ModalPaymentInfoBodyVersion2, ModalPaymentInfoVersion2} from '../../commonModals';
// import {getPreSignedURLFetchRequest} from 'util/requests';
import {saveAs} from 'file-saver';
import OnlinePdfModalRemedyReview from './modals/OnlinePdfModalRemedyReview';

interface IProps {
    intl: any;
    history: any;
    applicationRequestId?: any;
}

interface IState extends IWizardState {
    assistedEntries: any;
    applicationSpeciesInput: any;
    applicationBreederReference: any;
    applicationSignBy: any;
    applicationSignDateString: any;
    loading: number;
    applicationId: any;
    applicationCode: any;
    formCommonName: any;
    formValidityBegin: string | null;
    formId: any;
    formIsConfidential: any;
    formLanguage: string;
    formType: any;
    formSequence: string;
    formVersionCode: any;
    isRemedyStarted: boolean;
    isModalRemedyReviewOpen: boolean;
    officeId: any;
    paymentModal: boolean;
    screenLoaded: boolean;
}

class OnlinePdf extends WizardWrapper<IProps, IState> {
    urlParams: any;
    isCPVOUser: boolean;
    formLastUpdateDate: any;
    screenLastUpdateDate: number;
    responsesClientHash: any;

    constructor(props: IProps) {
        super(props);
        if (props.applicationRequestId) {
            this.urlParams = props;
        } else {
            this.urlParams = loadUrlParams();
        }
        this.isCPVOUser = isUserOfficeCPVO();
        this.formLastUpdateDate = null;
        this.screenLastUpdateDate = Date.now();
        this.responsesClientHash = null;
        this.state = {
            ...this.state,
            assistedEntries: null,
            applicationSpeciesInput: null,
            applicationBreederReference: null,
            applicationSignBy: null,
            applicationSignDateString: null,
            loading: 0,
            applicationId: null,
            applicationCode: null,
            formCommonName: null,
            formValidityBegin: '',
            formId: null,
            formIsConfidential: null,
            formLanguage: '',
            formType: null,
            formSequence: '',
            formVersionCode: null,
            isRemedyStarted: false,
            isModalRemedyReviewOpen: false,
            officeId: null,
            paymentModal: false,
            screenLoaded: false,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'onlinePdf'});
        this.loadJSONs();
        this.setState({
            screenData: {
                ...this.state.screenData,
                hasClickedOnSaveAndValidate: true,
            },
        });
        // addEventListener('beforeprint', this.onPrintEvent);
    }

    // componentWillUnmount() {
    //     removeEventListener('beforeprint', this.onPrintEvent);
    // }

    loadJSONs() {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                this.loadDatas().then(() =>
                    this.loadFormDraft().then(() =>
                        this.setState(prev => ({
                            loading: prev.loading - 1,
                        }))
                    )
                )
        );
    }

    loadDatas = () =>
        new Promise(resolve => {
            const {vc} = this.urlParams || {};
            apiFormDatas({verificationCode: vc}).then(
                JSONResponse =>
                    JSONResponse &&
                    JSONResponse.datas &&
                    this.setState(
                        {
                            assistedEntries: JSONResponse.datas,
                        },
                        () => resolve([])
                    )
            );
        });

    loadFormDraft = ({keepCurrentStepNumber = false} = {}) =>
        new Promise(resolve => {
            const {applicationId, formId, formType, formSequence, vc} = this.urlParams || {};
            (vc
                ? apiGetFormDraftWithVerificationCode({verificationCode: vc})
                : apiGetFormDraft({
                      // REVIEW: when online PDF has this params?
                      applicationId,
                      formId,
                      formType,
                      formSequence,
                  })
            ).then((jsonResponse: any) => {
                let tableQuestionsKeysIterationsMap = getTableQuestionsKeysIterationsMap(
                    jsonResponse.form.groupsQuestion,
                    jsonResponse.responsesClient
                );
                const groupsIdsIterationsMap = getGroupsIdsIterationsMap(
                    jsonResponse.form.groupsQuestion,
                    jsonResponse.responsesClient
                );
                let stateResponsesClient = prepareResponsesClient({
                    apiResponsesClient: jsonResponse.responsesClient,
                    groupsQuestion: jsonResponse.form.groupsQuestion,
                });
                this.setState(
                    prev => ({
                        name: jsonResponse.form.name.value,
                        applicationId: jsonResponse.applicationId,
                        applicationCode: jsonResponse.applicationCode,
                        formId: jsonResponse.form.id,
                        officeId: jsonResponse.form.office,
                        formType: jsonResponse.form.type,
                        formIsConfidential: parseInt(jsonResponse.confidential) === 1,
                        formSequence: jsonResponse.formSequence,
                        formVersionCode: jsonResponse.form.versionCode,
                        formValidityBegin: jsonResponse.form.validityBegin,
                        formCommonName: jsonResponse.form.common && jsonResponse.form.common.value,
                        groupsQuestion: jsonResponse.form.groupsQuestion,
                        currentStepNumber: 0,
                        formLanguage: jsonResponse.formLanguage,
                        applicationSpeciesInput: jsonResponse.specieInput,
                        applicationBreederReference: jsonResponse.breederReference,
                        applicationSignBy: jsonResponse.signBy,
                        applicationSignDateString: jsonResponse.signDateString,
                        screenData: {
                            ...prev.screenData,
                            hash: Date.now(),
                            tableQuestionsKeysIterationsMap,
                            groupsIdsIterationsMap,
                            responsesClient: {...(stateResponsesClient || {})},
                        },
                        screenLoaded: true,
                    }),
                    () => {
                        this.formLastUpdateDate = jsonResponse.timestamp;
                        this.screenLastUpdateDate = Date.now();
                        this.responsesClientHash = cyrb53(JSON.stringify(this.state.screenData.responsesClient));
                        this.checkIfRemedyStarted();
                        resolve([]);
                    }
                );
            });
        });

    checkIfRemedyStarted = () => {
        const storedRemedy = JSON.parse(localStorage.getItem(`REMEDY_${this.state.applicationCode}`) || '{}') || {};
        if (Object.keys(storedRemedy).length > 0) {
            this.setState({isRemedyStarted: true});
        }
    };

    onPrintPDF = () => {
        let subdomain = getEnvironment() === 'LOCALHOST' ? 'dev' : getEnvironment().toLowerCase();
        let ORIGIN_URL = `https://${subdomain}.plantvarieties.eu`;
        let URL = `${encodeURIComponent(`${ORIGIN_URL}/onlinePdf?vc=`)}${this.urlParams.vc}`;
        const {applicationSpeciesInput, applicationCode, formVersionCode} = this.state;
        const filename = `${applicationCode}_${formVersionCode}.pdf`;
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiCommonGetPDF({URL, species: applicationSpeciesInput, applicationNumber: applicationCode}).then(
                    (blob: any) => {
                        saveAs(blob, filename);
                        this.setState(prev => ({
                            loading: prev.loading - 1,
                        }));
                    }
                );
            }
        );
        // if (getEnvironment() !== 'ONLINE' || this.state.applicationSignBy === null) {
        //     // let url = 'https://devcloud.plantvarieties.eu';
        // } else {
        //     let url2 = `https://${getEnvironment() === 'ONLINE' ? 'online' : 'devcloud'}.plantvarieties.eu`;
        //     let url3 = `https://cnfx6b0vhi.execute-api.eu-west-3.amazonaws.com/dev/getPDF?url=`;
        //     // window.open(`${url3}${encodeURIComponent(`${url2}/onlinePdf?vc=`)}${this.urlParams.vc}`);
        //     //TODO: review revert back to this
        //     // apiGetFormApplicationName({
        //     //     applicationRequestId: this.state.applicationId,
        //     //     formType: this.state.formType === 1 ? 'AF' : 'TQ',
        //     //     confidential: this.state.formIsConfidential,
        //     // }).then(jsonResponse => {
        //     //     if (jsonResponse && jsonResponse.filename) {
        //     //         apiDocumentDownload({
        //     //             filename: jsonResponse.filename,
        //     //             applicationRequestCode: jsonResponse.filename.split('_')[0],
        //     //         }).then(response => {
        //     //             if (response && response.signedUrl) {
        //     //                 const {signedUrl} = response;
        //     //                 getPreSignedURLFetchRequest(signedUrl)
        //     //                     .then(response => response.blob())
        //     //                     .then(responseBlob => {
        //     //                         saveAs(responseBlob, jsonResponse.filename);
        //     //                     });
        //     //             }
        //     //         });
        //     //
        //     //     }
        //     // });
        // }
    };

    onOpenPaymentModal = () => this.setState({paymentModal: true});

    onClosePaymentModal = () => this.setState({paymentModal: false});

    onModalPaymentInfoButtonPrintClick = () => {
        document.getElementById('react-content')!.style.display = 'none';
        const printInfo = document.getElementById('print-info');
        printInfo!.style.display = 'block';
        window.print();
        document.getElementById('react-content')!.style.display = 'block';
        printInfo!.style.display = 'none';
    };

    onButtonStartRemedyClick = () => this.setState({isRemedyStarted: true});

    onButtonReviewRemedyClick = () => this.setState({isModalRemedyReviewOpen: true});

    onCloseModalRemedyReview = (reset: any) => {
        const stateUpdate: any = {
            isModalRemedyReviewOpen: false,
        };
        reset && (stateUpdate.isRemedyStarted = false);
        this.setState(stateUpdate);
    };

    render() {
        return (
            <>
                {this.state.isModalRemedyReviewOpen ? (
                    <OnlinePdfModalRemedyReview
                        close={this.onCloseModalRemedyReview}
                        applicationCode={this.state.applicationCode}
                    />
                ) : null}
                <div id={'print-info'} style={{display: 'none'}}>
                    <ModalPaymentInfoBodyVersion2
                        applicationRequestCode={this.state.applicationCode}
                        applicationSpeciesInput={this.state.applicationSpeciesInput}
                        applicationSignDateString={this.state.applicationSignDateString}
                        officeId={this.state.officeId}
                    />
                </div>
                <div id={'react-content'}>
                    {this.state.loading !== 0 ? <HeaderLoading /> : null}
                    {this.state.screenLoaded ? (
                        <div>
                            {this.state.paymentModal && (
                                <ModalPaymentInfoVersion2
                                    confirm={this.onClosePaymentModal}
                                    applicationRequestCode={this.state.applicationCode}
                                    applicationSpeciesInput={this.state.applicationSpeciesInput}
                                    applicationSignDateString={this.state.applicationSignDateString}
                                    officeId={this.state.officeId}
                                    onPrint={this.onModalPaymentInfoButtonPrintClick}
                                />
                            )}
                            <div className={styles.headerWrap}>
                                <div className={styles.headerWrapInner}>
                                    <div className={styles.printButton}>
                                        <FormFooterButton
                                            color={buttonColor.whiteBlue}
                                            clickAction={this.onPrintPDF}
                                            icon={faFilePdf}
                                            disabled={false}
                                        >{`Download PDF`}</FormFooterButton>
                                    </div>
                                    {/*{this.isCPVOUser ? (*/}
                                    {/*    <>*/}
                                    {/*        {this.state.isRemedyStarted ? (*/}
                                    {/*            <div className={styles.printButton}>*/}
                                    {/*                <FormFooterButton*/}
                                    {/*                    color={buttonColor.whiteBlue}*/}
                                    {/*                    clickAction={this.onButtonReviewRemedyClick}*/}
                                    {/*                    disabled={false}*/}
                                    {/*                >{`Review remedy`}</FormFooterButton>*/}
                                    {/*            </div>*/}
                                    {/*        ) : (*/}
                                    {/*            <div className={styles.printButton}>*/}
                                    {/*                <FormFooterButton*/}
                                    {/*                    color={buttonColor.whiteBlue}*/}
                                    {/*                    clickAction={this.onButtonStartRemedyClick}*/}
                                    {/*                    disabled={false}*/}
                                    {/*                >{`Start remedy`}</FormFooterButton>*/}
                                    {/*            </div>*/}
                                    {/*        )}*/}
                                    {/*    </>*/}
                                    {/*) : null}*/}
                                    {this.state.applicationSignBy && this.state.officeId && (
                                        <div className={styles.printButton}>
                                            <FormFooterButton
                                                color={buttonColor.whiteBlue}
                                                clickAction={this.onOpenPaymentModal}
                                                icon={faEuroSign}
                                                disabled={false}
                                            >{`Payment info`}</FormFooterButton>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <WizardStepsAll
                                PDFVersion={1}
                                applicationCode={this.state.applicationCode}
                                assistedEntries={this.state.assistedEntries}
                                applicationBreederReference={this.state.applicationBreederReference}
                                applicationSpeciesInput={this.state.applicationSpeciesInput}
                                applicationSignBy={this.state.applicationSignBy}
                                applicationSignDateString={this.state.applicationSignDateString}
                                currentStepNumber={this.state.currentStepNumber}
                                editable={false}
                                formId={this.state.formId}
                                formTitle={this.state.name}
                                formType={this.state.formType}
                                formIsConfidential={this.state.formIsConfidential}
                                formVersionCode={this.state.formVersionCode}
                                formValidityBegin={this.state.formValidityBegin}
                                formCommonName={this.state.formCommonName}
                                formLanguage={this.state.formLanguage}
                                loadFormQuestionsJSONs={this.loadJSONs}
                                responsesClientHash={this.responsesClientHash}
                                removeQuestion={() => null}
                                removeResponse={() => null}
                                screenData={this.state.screenData}
                                steps={this.state.groupsQuestion}
                                setStepGroupCallables={() => null}
                                updateScreenData={this.updateScreenData}
                                vc={this.urlParams.vc}
                                // isRemedyStarted={this.state.isRemedyStarted}
                            />
                        </div>
                    ) : null}
                </div>
            </>
        );
    }
}

export default withRouter(injectIntl(OnlinePdf));
