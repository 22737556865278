import React from 'react';
import styles from './MyPVRMyDocumentsTopCaption.module.scss';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {faPrint, faTimes, faDownload} from '@fortawesome/free-solid-svg-icons';
import getIcon from '~utils/icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-regular-svg-icons';

export default function MyPVRMyDocumentsTopCaption({isDownloadAndMergeEnabled, downloadDocuments, printDocuments}) {
    return (
        <div className={styles.container}>
            <div style={{display: 'inline-block', width: 'calc(100vw - 380px)', marginRight: 20}}>
                <div style={{marginBottom: 10}}>
                    <div style={{display: 'inline-block', fontWeight: 800, marginRight: 5}}>{`Warning:`}</div>
                    <div style={{display: 'inline-block'}}>{`Please note that only the documents with the icon (`}</div>
                    <div style={{display: 'inline-block'}}>
                        <img alt={`pdf icon`} src={getIcon('pdf')} style={{width: 20, height: 20}} />
                    </div>
                    <div style={{display: 'inline-block', marginRight: 5}}>{`,`}</div>
                    <div style={{display: 'inline-block'}}>
                        <img alt={`jpg icon`} src={getIcon('jpg')} style={{width: 20, height: 20}} />
                    </div>
                    <div style={{display: 'inline-block', marginRight: 5}}>{`, ...) can be downloaded.`}</div>
                </div>
                <div style={{marginBottom: 10}}>
                    <div style={{display: 'inline-block', marginRight: 5}}>
                        {`All newly notified documents are by default unticked`}
                    </div>
                    <div style={{display: 'inline-block', fontSize: 20}}>
                        <FontAwesomeIcon icon={faCheckCircle} color={'#AAA'} />
                    </div>
                    <div style={{display: 'inline-block', marginRight: 5}}>
                        {`. All documents that were removed/closed`}
                    </div>
                    <div style={{display: 'inline-block', fontSize: 20}}>
                        <FontAwesomeIcon icon={faTimes} color={'red'} />
                    </div>
                    <div style={{display: 'inline-block', marginLeft: 5, marginRight: 5}}>
                        {`from the old dashboard are ticked`}
                    </div>
                    <div style={{display: 'inline-block', fontSize: 20}}>
                        <FontAwesomeIcon icon={faCheckCircle} color={'#2f923f'} />
                    </div>
                </div>
            </div>
            <div style={{display: 'inline-block', textAlign: 'right', verticalAlign: 'top'}}>
                <FormFooterButton
                    color={buttonColor.blue}
                    clickAction={printDocuments}
                    icon={faPrint}
                    disabled={!isDownloadAndMergeEnabled()}
                >{`Merge PDFs`}</FormFooterButton>
                <FormFooterButton
                    color={buttonColor.blue}
                    clickAction={downloadDocuments}
                    icon={faDownload}
                    disabled={!isDownloadAndMergeEnabled()}
                >{`Download`}</FormFooterButton>
            </div>
        </div>
    );
}
