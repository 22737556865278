import React from 'react';
import styles from './ModalApplicationList.module.scss';
import TextAreaInput from '~components/TextAreaInput';
import {apiCertifiedCopiesCheckTitles} from './ModalTitlesListService';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';
import {LoadingBar} from '../../../componentsLayout';

interface IProps {
    close: any;
    importList: any;
}

interface IState {
    content: string;
    invalid: boolean;
    loading: boolean;
}

export default class ModalTitlesList extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            content: '',
            invalid: true,
            loading: false,
        };
    }

    close = (reload: any) => this.props.close && this.props.close(reload);

    onButtonImportClick = () => {
        this.setState({loading: true}, () => {
            if (!this.state.invalid) {
                const regexp = RegExp('(\\d){1,8}', 'g');
                const str = this.state.content;
                const matches: string[] = [];
                let match;
                while ((match = regexp.exec(str)) !== null) {
                    matches.push(match[0]);
                }
                apiCertifiedCopiesCheckTitles(matches).then((JSONResponse: any) => {
                    if (JSONResponse && JSONResponse.data && Array.isArray(JSONResponse.data)) {
                        let applicationNumbers = JSONResponse.data.map((el: any) => el.applicationNumber);
                        let foundTitles = JSONResponse.data.map((el: any) => el.title);
                        let titlesWithNoCorrespondingApplicationNumbers: any = [];
                        matches.forEach((match: any) => {
                            if (!foundTitles.includes(parseInt(match))) {
                                titlesWithNoCorrespondingApplicationNumbers.push(match);
                            }
                        });
                        this.props.close();
                        this.props.importList(applicationNumbers, titlesWithNoCorrespondingApplicationNumbers);
                    }
                    this.setState({loading: false});
                });
            }
        });
    };

    onInputChange = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            content: value,
            invalid: !/^(\d){1,8}(((?!\d)(.|(\n)*))+((\d){1,8}))*(\n)*$/.test(value),
        });
    };

    render() {
        return (
            <ModalCustomVersion2
                close={this.close}
                header={`Import titles list`}
                body={
                    <>
                        <TextAreaInput
                            label={`Please introduce the list of titles that you would like to include.`}
                            triple={true}
                            value={this.state.content}
                            onChange={this.onInputChange}
                            rows={10}
                        />
                        {this.state.content && this.state.invalid && (
                            <div
                                className={styles.errorFormat}
                            >{`The format is not correct. You need to include application numbers which are groups of 8 digits with a non-digit separator.`}</div>
                        )}
                        <div style={{clear: 'both'}} />
                    </>
                }
                footer={
                    <>
                        {this.state.loading ? (
                            <LoadingBar />
                        ) : (
                            <>
                                <Button variation={'danger'} clickAction={this.close} icon={'close'}>{`Close`}</Button>
                                <Button
                                    clickAction={this.onButtonImportClick}
                                    icon={'arrowRight'}
                                    disabled={this.state.invalid}
                                >{`Import`}</Button>
                            </>
                        )}
                    </>
                }
            />
        );
    }
}
