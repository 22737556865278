import React, {useState} from 'react';
import {Button} from '../../../../../../componentsFormV2';
import CustomTable from '../../../../../../components/CustomTable';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import DataSheetFormSection from '../../../../../../shared/DataSheetFormSection';
import {History} from 'history';
import {ModalCustomVersion2} from '../../../../../../commonModals';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {TLOContact} from '../../../../../../types/TLO';
import {injectIntl, IntlShape} from 'react-intl';
import styles from './ModalTLOContactsList.module.scss';

interface ModalTLOContactsListProps {
    intl: IntlShape;
    history: History;
    tloContactList: Array<TLOContact>;
    printPDF: () => void;
    close: () => void;
}

const ModalTLOContactsList = (props: ModalTLOContactsListProps & RouteComponentProps) => {
    const RESULT_FIELDS_DEFAULT = ['countryId', 'name', 'addressName'];
    const [showDataSheetFormSection] = useState(props.tloContactList.length > 0);
    const [contactListRowObj, setContactListRowObj] = useState<TLOContact | null>(null);

    const getDataSheetFormSectionArea = () => {
        return (
            <DataSheetFormSection disableToggle={true}>
                <DataSheetFormFields
                    label={`Phone`}
                    data={contactListRowObj?.phoneNumber}
                    loading={false}
                    disabled={true}
                    double={true}
                />
                <div style={{clear: 'both'}}>{}</div>
                <DataSheetFormFields
                    label={`Address`}
                    loading={false}
                    disabled={true}
                    data={contactListRowObj?.addressFormated}
                    double={true}
                />
                <div style={{clear: 'both'}}>{}</div>
                <DataSheetFormFields
                    label={`Fax`}
                    data={contactListRowObj?.fax}
                    loading={false}
                    disabled={true}
                    double={true}
                />
                <div style={{clear: 'both'}}>{}</div>
                <DataSheetFormFields
                    label={`Email`}
                    data={contactListRowObj?.email}
                    loading={false}
                    disabled={true}
                    double={true}
                />
                <div style={{clear: 'both'}}>{}</div>
                <DataSheetFormFields
                    label={`Web`}
                    data={contactListRowObj?.websiteUrl}
                    loading={false}
                    disabled={true}
                    double={true}
                />
            </DataSheetFormSection>
        );
    };

    const getRowClass = (rowObject: TLOContact) => {
        const clickedRowIndex =
            contactListRowObj !== null &&
            props.tloContactList.findIndex(
                el => el.clientId === contactListRowObj.clientId && el.clientId === rowObject.clientId
            );
        const className = clickedRowIndex !== -1 ? clickedRowIndex + '-clickedRow' : '0-clickedRow';
        return styles[className];
    };

    const getTableArea = () =>
        props.tloContactList.length === 0 ? (
            <div style={{color: 'red', textAlign: 'center'}}>
                <b style={{textAlign: 'center'}}>
                    <span>{`No TLO Contact available`}</span>
                </b>
            </div>
        ) : (
            <CustomTable
                version={2}
                {...props}
                tableName={'tloContactList'}
                tableType={'OBJECT'}
                tableSource={props.tloContactList}
                id={'clientId'}
                hideExcelButton={true}
                resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                intl={props.intl}
                defaultOrder={'name'}
                reverseOrder={false}
                count={props.tloContactList.length}
                rowClick={(_clientId: string, rowObject: TLOContact) => setContactListRowObj(rowObject)}
                rowClass={getRowClass}
            />
        );

    return (
        <div className="tloContactList">
            <ModalCustomVersion2
                close={() => props.close && props.close()}
                loading={false}
                header={`TLO Contacts List`}
                body={
                    <>
                        {getTableArea()}
                        {showDataSheetFormSection && getDataSheetFormSectionArea()}
                    </>
                }
                footer={<Button clickAction={props.printPDF}>{`Export to PDF`}</Button>}
            />
        </div>
    );
};

export default injectIntl(withRouter(ModalTLOContactsList));
