import dateFormat from 'date-format';
import {formatDate} from './FormatFunctions';

export const NORMALIZE_DATE = 'NORMALIZE_DATE';
export function normalizeDate(rowObject, fieldId) {
    const fieldData = rowObject[fieldId];
    const date = formatDate(fieldData);
    if (date !== '') {
        return dateFormat('yyyyMMdd', dateFormat.parse('yyyy/MM/dd', date.split('/').reverse().join('/')));
    }
    return '';
}

export const NORMALIZE_NUMBER = 'NORMALIZE_NUMBER';
export function normalizeNumber(rowObject, fieldId) {
    const fieldData = rowObject[fieldId];
    if (fieldData && fieldData !== '') {
        return parseInt(fieldData);
    }
    return Number.MAX_SAFE_INTEGER;
}

export const NORMALIZE_DATE_EU = 'NORMALIZE_DATE_EU';
export function normalizeDateEU(rowObject, fieldId) {
    const fieldData = rowObject[fieldId];
    if (fieldData && fieldData !== '') {
        const date = fieldData.split(' - ')[0];
        return dateFormat('yyyyMMdd', dateFormat.parse('yyyy/MM/dd', date.split('/').reverse().join('/')));
    }
    return ' ';
}
