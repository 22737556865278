import React, {useState} from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';
import TextAreaInput from '~components/TextAreaInput';
import SelectInput from '~components/SelectInput';

const SELECT_INPUT_OPTIONS = [
    {id: 'countries', value: 'Countries'},
    {id: 'eo_countries', value: 'Examination offices countries'},
    {id: 'ue_countries', value: 'UE countries'},
    {id: 'species', value: 'Species'},
    {id: 'languages', value: 'Languages'},
    {id: 'procedures', value: 'Procedures'},
    {id: 'component_ownership', value: 'Component ownership'},
    {id: 'stages', value: 'Stages'},
    {id: 'stages_dus', value: 'Stages Dus'},
];

const SELECT_INPUT_TYPES = [
    {id: 'combobox', value: 'Combobox'},
    {id: 'controllist', value: 'Control List'},
    {id: 'autocomplete', value: 'Autocomplete'},
];

const getSelectOptionFromLogicParam = logicParam => {
    let selectedLogicParam = 'countries';
    if (logicParam?.includes('on:')) {
        selectedLogicParam = logicParam.split('on:').pop().split(';')[0] || 'countries';
    }
    return selectedLogicParam;
};

const getSelectInputTypeFromLogicParam = logicParam => {
    return SELECT_INPUT_TYPES.map(el => el.id).find(el => (logicParam || '').includes(el)) || 'combobox';
};

const ModalLogicTypeShowQuestion = ({close, onCreateOrUpdateLogicType, responseLogicValues, responseLogicType}) => {
    const {logicParam, applyToRemark} = responseLogicValues || {};
    const {defaultParam, name} = responseLogicType || {};
    const [selectOptionsValue, setSelectOptionsValue] = useState(getSelectOptionFromLogicParam(logicParam));
    const [selectInputTypeValue, setSelectInputTypeValue] = useState(getSelectInputTypeFromLogicParam(logicParam));

    const onSelectOptionsValueChange = ({target: {value}}) => setSelectOptionsValue(value);

    const onSelectInputTypeValueChange = ({target: {value}}) => setSelectInputTypeValue(value);

    const onCreateOrUpdateLogicTypeClick = () => {
        let paramValue = `on:${selectOptionsValue};`;
        selectInputTypeValue === 'combobox' && (paramValue += 'combobox:yes;');
        selectInputTypeValue === 'autocomplete' && (paramValue += 'autocomplete:yes');
        selectInputTypeValue === 'controllist' && (paramValue += 'autocomplete:yes;controllist:yes;');
        onCreateOrUpdateLogicType(paramValue, applyToRemark !== undefined ? applyToRemark : false);
    };

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <div>
                    <SelectInput
                        label={`Options`}
                        value={selectOptionsValue}
                        notAll={true}
                        width={250}
                        onChange={onSelectOptionsValueChange}
                        list={SELECT_INPUT_OPTIONS}
                    />
                    <SelectInput
                        label={`Input Type`}
                        value={selectInputTypeValue}
                        notAll={true}
                        width={250}
                        onChange={onSelectInputTypeValueChange}
                        list={SELECT_INPUT_TYPES}
                    />
                    {defaultParam && (
                        <div>
                            <TextAreaInput
                                label="Default Parameters"
                                value={defaultParam}
                                width={550}
                                rows={7}
                                disabled={true}
                            />
                        </div>
                    )}
                    <div style={{clear: 'both'}} />
                </div>
            }
            footer={
                <>
                    <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                    <Button icon={'arrowRight'} clickAction={onCreateOrUpdateLogicTypeClick}>{`Save`}</Button>
                </>
            }
        />
    );
};

export default ModalLogicTypeShowQuestion;
