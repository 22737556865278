import React, {useState} from 'react';
import {TLO_CPVO_BOX_UPLOAD_ZONE} from '../../../../../../commonApi';
import {MAX_UPLOAD_FILE_SIZE} from '../../../../../../utils/constants';
import BrowserInfo from '../../../../../../shared/BrowserInfo';
import CryptoJS from 'crypto-js';
import DragAndDrop from '../../../../../../components/DragAndDrop';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {IntlShape} from 'react-intl';
import Info from '../../../../../../components/Info';
import {InvoiceData} from '../ModalRecordInvoiceData/Interfaces/ModalRecordInvoiceDataInterfaces';
import InputLink from '~components/InputLink';
import {ModalCPVOLogsVersion2, ModalCustomVersion2, ModalErrorVersion2} from '../../../../../../commonModals';
import ModalDocumentTypesExplanationsInfo from '../ModalDocumentTypesExplanationsInfo/ModalDocumentTypesExplanationsInfo';
import ModalRecordInvoiceData from '../ModalRecordInvoiceData/ModalRecordInvoiceData';
import TextInput from '~components/TextInput';
import TextAreaInput from '~components/TextAreaInput';
import UploadDocumentsArea from '../UploadDocumentsArea/UploadDocumentsArea';
import {UploadedFileData} from '../../../../CommonComponents/ModalDocument/Interfaces/ModalDocumentInterfaces';
import {apiFileListLogs} from '../../../../../CPVOLogs/CPVOLogsService';
import {apiUploadGetFileInfo} from '../../CpvoBoxPageService';
import {apiTloDocumentUpload} from '../../../../CommonApi/CommonApi';
import {faChevronDown, faChevronRight, faFlag, faPlus} from '@fortawesome/free-solid-svg-icons';
import {getMandatoryAsterisk} from '../../../../../../utils/format';
import {isRegisterIdValid, sanitize} from '../../../../../../utils';
import styles from './ModalCpvoBox.module.scss';
import {Button} from '../../../../../../componentsFormV2';

const modalScreenTypes = {
    ERROR: 'ERROR',
    MODAL_FILES: 'MODAL_FILES',
    EXPLANATIONS_INFO: 'EXPLANATIONS_INFO',
    RECORD_INVOICE_DATA: 'RECORD_INVOICE_DATA',
};

const DEFAULT_CRITERIA = {
    pageNumber: 1,
};

interface ModalCpvoBoxProps {
    intl: IntlShape;
    close: (doesCpvoBoxModalClose: boolean) => void;
}

const ModalCpvoBox = (props: ModalCpvoBoxProps) => {
    const [loading, setLoading] = useState(false);
    const [criteria, setCriteria] = useState<{pageNumber: number}>(Object.assign({}, DEFAULT_CRITERIA));
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [subject, setSubject] = useState('');
    const [comments, setComments] = useState('');
    const [applicationNumberPerDocument, setApplicationNumberPerDocument] = useState<{[key: string]: string}>({});
    const [applicationNumberErrorPerDocument, setApplicationNumberErrorPerDocument] = useState<{
        [key: string]: 'UNKNOWN' | 'FORBIDDEN' | 'DUPLICATE' | 'NOT_RECEIPT' | 'ADD';
    }>({});
    const [documentTypeErrorPerDocument, setDocumentTypeErrorPerDocument] = useState<{
        [key: string]: 'NO_DOCUMENT_TYPE' | 'INVOICE_NOT_EDITED';
    }>({});
    const [invoiceDataPerDocument, setInvoiceDataPerDocument] = useState<{[key: string]: InvoiceData}>({});
    const [invoiceDocumentsEdited, setInvoiceDocumentsEdited] = useState<{[key: string]: boolean}>({});
    const [invoiceDocumentForEdit, setInvoiceDocumentForEdit] = useState<UploadedFileData | null>(null);
    const [informationOpened, setInformationOpened] = useState(true);
    const [documentList, setDocumentList] = useState<Array<UploadedFileData>>([]);
    const [files, setFiles] = useState<Array<File>>([]);
    const [folder, setFolder] = useState('');
    const [timestamp, setTimestamp] = useState(0);
    const [acknowledgementNumber, setAcknowledgementNumber] = useState('');
    const [showSubjectError, setShowSubjectError] = useState(false);
    const [isModalErrorFileEncryptedOpen, setIsModalErrorFileEncryptedOpen] = useState<string[]>([]);

    const onSubjectChange = ({target: {value: subject}}: React.ChangeEvent<HTMLInputElement>) => {
        setSubject(subject);
        subject.length > 0 && setShowSubjectError(false);
    };

    const onCommentsChange = ({target: {value: comments}}: React.ChangeEvent<HTMLTextAreaElement>) =>
        setComments(comments);

    const arrayBufferToString = (buffer: ArrayBuffer) => new TextDecoder().decode(buffer);

    const checkFile = (file: File) => {
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType && fileType.toLowerCase();
        const fileName = sanitize(file.name);
        if (
            fileTypeLowercase === 'jpeg' ||
            fileTypeLowercase === 'png' ||
            fileTypeLowercase === 'jpg' ||
            fileTypeLowercase === 'pdf'
        ) {
            if (file.size > MAX_UPLOAD_FILE_SIZE) {
                setErrorMessage('The maximum document size for uploads is 10 MB. Please select a proper file.');
                setModalScreen(modalScreenTypes.ERROR);
            } else {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);
                let fileReaderResult = '';
                if (fileReader.result === null) {
                    fileReaderResult = '';
                } else if (fileReader.result instanceof ArrayBuffer) {
                    fileReaderResult = arrayBufferToString(fileReader.result);
                } else {
                    fileReaderResult = fileReader.result;
                }
                fileReader.onloadend = (event: ProgressEvent<FileReader>) => {
                    const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(fileReaderResult));
                    if (documentList.filter(document => document.fileName === fileName).length === 0) {
                        documentList.push({
                            index,
                            file,
                            fileName,
                            size: file.size / 1024,
                            extension: fileTypeLowercase,
                            fileType: null,
                            applicationNumber: '',
                            fileUrl:
                                (event.target !== null &&
                                    event.target.result !== null &&
                                    typeof event.target.result === 'string' &&
                                    event.target.result) ||
                                '',
                        });
                        setDocumentList(documentList);
                        setTimestamp(Date.now());
                        setInformationOpened(false);
                    }
                };
            }
        } else {
            setErrorMessage('File type is not valid');
            setModalScreen(modalScreenTypes.ERROR);
        }
    };

    const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length > 0) {
            const files = event.target.files;
            for (let i = 0; i < files.length; i++) {
                checkFile(files[i]);
            }
            event.target.value = '';
        }
    };

    const handleDrop = (files: Array<File>, _event: React.ChangeEvent<HTMLInputElement>) => {
        for (const file of files) {
            checkFile(file);
        }
    };

    const checkApplicationNumber = (applicationNumber: string, fileName: string) => {
        const applicationNumberValue = applicationNumber.substring(0, 8);
        const newApplicationNumberError = isRegisterIdValid(applicationNumberValue)
            ? applicationNumberValue.length === 8
                ? 'ADD'
                : null
            : 'UNKNOWN';
        setApplicationNumberErrorPerDocument(
            Object.assign({}, applicationNumberErrorPerDocument, {[fileName]: newApplicationNumberError})
        );
        setApplicationNumberPerDocument(
            Object.assign({}, applicationNumberPerDocument, {[fileName]: applicationNumberValue})
        );
        if (newApplicationNumberError === null) {
            setDocumentList(
                documentList
                    .slice(0)
                    .map(document =>
                        document.fileName === fileName
                            ? Object.assign({}, document, {applicationNumberValue})
                            : document
                    )
            );
            setTimestamp(Date.now());
        }
    };

    const onAddingApplicationNumber = (applicationNumber: string, fileName: string) => {
        if (applicationNumber.length === 8 && isRegisterIdValid(applicationNumber)) {
            setLoading(true);
            apiUploadGetFileInfo(applicationNumber)
                .then(jsonResult => {
                    if (jsonResult.message && jsonResult.message !== 'OK') {
                        setApplicationNumberErrorPerDocument(
                            Object.assign({}, applicationNumberErrorPerDocument, {[fileName]: 'FORBIDDEN'})
                        );
                    } else {
                        const newApplicationNumberErrorPerDocument = Object.assign(
                            {},
                            applicationNumberErrorPerDocument
                        );
                        newApplicationNumberErrorPerDocument[fileName] &&
                            delete newApplicationNumberErrorPerDocument[fileName];
                        setApplicationNumberErrorPerDocument(newApplicationNumberErrorPerDocument);
                        setDocumentList(
                            documentList
                                .slice(0)
                                .map(document =>
                                    document.fileName === fileName
                                        ? Object.assign({}, document, {applicationNumber})
                                        : document
                                )
                        );
                        setTimestamp(Date.now());
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const removeDocumentTypeErrorForSpecificFileName = (fileName: string) => {
        const newDocumentTypeErrorPerDocument = Object.assign({}, documentTypeErrorPerDocument);
        newDocumentTypeErrorPerDocument[fileName] && delete newDocumentTypeErrorPerDocument[fileName];
        setDocumentTypeErrorPerDocument(newDocumentTypeErrorPerDocument);
        setTimestamp(Date.now());
    };

    const onDocumentTypeSelect = (documentType: string, fileName: string) => {
        setDocumentList(
            documentList
                .slice(0)
                .map(document =>
                    document.fileName === fileName ? Object.assign({}, document, {fileType: documentType}) : document
                )
        );
        if (documentType === 'Invoice' && invoiceDocumentsEdited[fileName] === true) {
            setTimestamp(Date.now());
        } else if (documentType === 'Invoice') {
            setInvoiceDocumentsEdited(Object.assign({}, invoiceDocumentsEdited, {[fileName]: false}));
            setTimestamp(Date.now());
        } else {
            removeDocumentTypeErrorForSpecificFileName(fileName);
        }
    };

    const saveInvoiceData = (invoiceData: InvoiceData, fileName: string) => {
        setModalScreen(null);
        removeDocumentTypeErrorForSpecificFileName(fileName);
        setInvoiceDataPerDocument(Object.assign({}, invoiceDataPerDocument, {[fileName]: invoiceData}));
        setInvoiceDocumentsEdited(Object.assign({}, invoiceDocumentsEdited, {[fileName]: true}));
        setInvoiceDocumentForEdit(null);
    };

    const deleteFile = (fileName: string) => {
        setDocumentList(documentList.filter(document => document.fileName !== fileName));
        setTimestamp(Date.now());
        setInformationOpened(documentList.length === 1);
    };

    const editInvoice = (fileName: string) => {
        const invoiceDocument = documentList.filter(
            document => document.fileName === fileName && document.fileType === 'Invoice'
        )[0];
        const hasApplicationNumber = invoiceDocument.applicationNumber !== undefined;
        if (hasApplicationNumber) {
            setInvoiceDocumentForEdit(invoiceDocument);
            setModalScreen(modalScreenTypes.RECORD_INVOICE_DATA);
        }
    };

    const showExplanationsModalInfo = () => setModalScreen(modalScreenTypes.EXPLANATIONS_INFO);

    const toggleInformation = () => setInformationOpened(!informationOpened);

    const closeModal = () => setModalScreen(null);

    const resetStates = () => {
        setModalScreen(null);
        setSubject('');
        setComments('');
        setDocumentList([]);
        setInformationOpened(true);
        setFiles([]);
        setFolder('');
        setAcknowledgementNumber('');
        setApplicationNumberErrorPerDocument({});
        setApplicationNumberPerDocument({});
        setInvoiceDataPerDocument({});
        setInvoiceDocumentForEdit(null);
        setInvoiceDocumentsEdited({});
        setDocumentTypeErrorPerDocument({});
    };

    const openModalFiles = (folder: string) => {
        setLoading(true);
        apiFileListLogs(TLO_CPVO_BOX_UPLOAD_ZONE, folder)
            .then((jsonResult: {files: Array<File>}) => {
                setFiles(jsonResult.files);
                setFolder(folder);
                setTimestamp(Date.now());
                setModalScreen(modalScreenTypes.MODAL_FILES);
            })
            .finally(() => setLoading(false));
    };

    const getApplicationNumberPerDocumentList = () => {
        let applicationNumberPerDocumentList: Array<{
            applicationNumber: string;
            fileName: string;
            invoiceDate: string;
            invoiceNumber: string;
            totalAmount: number;
            vatAmount: number;
        }> = [];
        for (const document of documentList) {
            if (document.applicationNumber) {
                const invoiceDataOfDocument: InvoiceData | undefined = invoiceDataPerDocument[document.fileName];
                let splittedDateList = [];
                let finalInvoiceDate = '';
                if (invoiceDataOfDocument) {
                    splittedDateList = invoiceDataOfDocument.invoiceDate.split('/');
                    finalInvoiceDate = splittedDateList[2] + splittedDateList[1] + splittedDateList[0];
                }
                applicationNumberPerDocumentList.push({
                    applicationNumber: document.applicationNumber,
                    fileName: document.fileName,
                    invoiceDate: invoiceDataOfDocument && finalInvoiceDate,
                    invoiceNumber: invoiceDataOfDocument && invoiceDataOfDocument.invoiceNumber,
                    totalAmount: invoiceDataOfDocument && invoiceDataOfDocument.totalAmountVatIncl,
                    vatAmount: invoiceDataOfDocument && invoiceDataOfDocument.vatAmount,
                });
            }
        }

        return applicationNumberPerDocumentList;
    };

    const uploadDocuments = () => {
        const applicationNumberPerDocumentList = getApplicationNumberPerDocumentList();
        setLoading(true);
        apiTloDocumentUpload(
            {
                subject,
                comments,
                applicationNumberPerDocumentList,
            },
            documentList
        )
            .then((response: {folder: string; ackuid: string; error?: number}) => {
                if (response.error === 404 || !response || !response.folder) {
                    setErrorMessage('Something went wrong. Please try again later.');
                    setModalScreen(modalScreenTypes.ERROR);
                } else {
                    setAcknowledgementNumber(response.ackuid);
                    openModalFiles(response.folder);
                }
            })
            .catch(error => {
                if (error && error.encryptedFilesIndexes && error.encryptedFilesIndexes.length) {
                    const encryptedFileNames: string[] = [];
                    documentList.forEach(
                        (documentItem, index) =>
                            error.encryptedFilesIndexes.includes(index) &&
                            encryptedFileNames.push(documentItem.fileName)
                    );
                    setDocumentList(documentList.filter((_, index) => !error.encryptedFilesIndexes.includes(index)));
                    setTimestamp(Date.now());
                    setIsModalErrorFileEncryptedOpen(encryptedFileNames);
                } else {
                    closeModal();
                }
            })
            .finally(() => setLoading(false));
    };

    const checkForExistingDocumentsWithoutApplicationNumber = (exist: boolean) => {
        if (exist) {
            const documentsWithoutApplicationNumber = documentList.filter(
                document => document.applicationNumber === undefined || document.applicationNumber.length === 0
            );
            let newApplicationNumberErrorPerDocument: {[key: string]: 'UNKNOWN'} = {};
            for (const document of documentsWithoutApplicationNumber) {
                if (applicationNumberErrorPerDocument[document.fileName]) {
                    return;
                } else {
                    newApplicationNumberErrorPerDocument[document.fileName] = 'UNKNOWN';
                }
            }
            setApplicationNumberErrorPerDocument(Object.assign({}, newApplicationNumberErrorPerDocument));
            setTimestamp(Date.now());
        } else {
            const documentsWithApplicationNumber = documentList.filter(
                document => document.applicationNumber !== undefined && document.applicationNumber.length === 8
            );
            for (const document of documentsWithApplicationNumber) {
                if (applicationNumberErrorPerDocument[document.fileName]) {
                    const newApplicationNumberErrorPerDocument = Object.assign({}, applicationNumberErrorPerDocument);
                    delete newApplicationNumberErrorPerDocument[document.fileName];
                    setApplicationNumberErrorPerDocument(newApplicationNumberErrorPerDocument);
                    setTimestamp(Date.now());
                }
            }
        }
    };

    const checkForExistingDocumentsWithoutDocumentType = (exist: boolean) => {
        if (exist) {
            const documentsWithoutDocumentType = documentList.filter(
                document => document.fileType === null || document.fileType === 'Please select a document type'
            );
            let newDocumentTypeErrorPerDocument: {[key: string]: 'NO_DOCUMENT_TYPE'} = {};
            for (const document of documentsWithoutDocumentType) {
                if (documentTypeErrorPerDocument[document.fileName]) {
                    return;
                } else {
                    newDocumentTypeErrorPerDocument[document.fileName] = 'NO_DOCUMENT_TYPE';
                }
            }
            setDocumentTypeErrorPerDocument(Object.assign({}, newDocumentTypeErrorPerDocument));
            setTimestamp(Date.now());
        } else {
            const documentsWithDocumentType = documentList.filter(
                document => document.fileType !== null && document.fileType !== 'Please select a document type'
            );
            for (const document of documentsWithDocumentType) {
                if (documentTypeErrorPerDocument[document.fileName]) {
                    const newDocumentTypeErrorPerDocument = Object.assign({}, documentTypeErrorPerDocument);
                    delete newDocumentTypeErrorPerDocument[document.fileName];
                    setDocumentTypeErrorPerDocument(newDocumentTypeErrorPerDocument);
                    setTimestamp(Date.now());
                }
            }
        }
    };

    const checkForDocumentsWithDocumentTypeInvoiceAndNotEdited = (exist: boolean) => {
        if (exist) {
            const documentsWithDocumentTypeInvoiceAndNotEdited = documentList.filter(
                document => document.fileType === 'Invoice' && invoiceDocumentsEdited[document.fileName] === false
            );
            let newDocumentTypeErrorPerDocument: {[key: string]: 'INVOICE_NOT_EDITED'} = {};
            for (const document of documentsWithDocumentTypeInvoiceAndNotEdited) {
                newDocumentTypeErrorPerDocument[document.fileName] = 'INVOICE_NOT_EDITED';
            }
            setDocumentTypeErrorPerDocument(Object.assign({}, newDocumentTypeErrorPerDocument));
            setTimestamp(Date.now());
        } else {
            const documentsWithDocumentTypeInvoiceAndEdited = documentList.filter(
                document => document.fileType === 'Invoice' && invoiceDocumentsEdited[document.fileName] === true
            );
            for (const document of documentsWithDocumentTypeInvoiceAndEdited) {
                if (documentTypeErrorPerDocument[document.fileName]) {
                    const newDocumentTypeErrorPerDocument = Object.assign({}, documentTypeErrorPerDocument);
                    delete newDocumentTypeErrorPerDocument[document.fileName];
                    setDocumentTypeErrorPerDocument(newDocumentTypeErrorPerDocument);
                    setTimestamp(Date.now());
                }
            }
        }
    };

    const confirmSending = () => {
        if (subject.length === 0) {
            setShowSubjectError(true);
        }
        const existDocumentsWithoutApplicationNumber = documentList.some(
            document => document.applicationNumber === undefined || document.applicationNumber.length === 0
        );
        checkForExistingDocumentsWithoutApplicationNumber(existDocumentsWithoutApplicationNumber);

        const existDocumentsWithoutDocumentType = documentList.some(
            document => document.fileType === null || document.fileType === 'Please select a document type'
        );
        checkForExistingDocumentsWithoutDocumentType(existDocumentsWithoutDocumentType);

        const existDocumentsWithDocumentTypeInvoiceAndNotEdited = documentList.some(
            document => document.fileType === 'Invoice' && invoiceDocumentsEdited[document.fileName] === false
        );
        checkForDocumentsWithDocumentTypeInvoiceAndNotEdited(existDocumentsWithDocumentTypeInvoiceAndNotEdited);

        if (
            subject.length > 0 &&
            !existDocumentsWithoutApplicationNumber &&
            !existDocumentsWithoutDocumentType &&
            !existDocumentsWithDocumentTypeInvoiceAndNotEdited
        )
            uploadDocuments();
    };

    const getSuccessContentArea = () => (
        <p>
            <p>
                <span>{`Success! Your files have been recorded and will be processed by the Office.`}</span>
            </p>
            <p>
                <span className={styles.acknowledgementNumberSuccessArea}>
                    {`The acknowledgment number is `}
                    <strong>{`${acknowledgementNumber}`}</strong>
                    {`.`}
                </span>
            </p>
        </p>
    );

    const getErrorHandlingSubjectArea = () => {
        return (
            showSubjectError && (
                <div className={styles.subjectErrorArea}>
                    <Info>
                        <div className={styles.subjectErrorIconArea}>
                            <FontAwesomeIcon icon={faFlag as any} color={'#8a6d3b'} />
                        </div>
                        <div className={styles.subjectErrorTextArea}>{`Subject cannot be empty.`}</div>
                    </Info>
                </div>
            )
        );
    };

    const updateCriteriaValue = (
        // eslint-disable-next-line no-undef
        criteriaValue: {pageNumber: number},
        callback = () => {}
    ) => {
        if (criteriaValue !== undefined) {
            setCriteria({...criteria, ...criteriaValue});
        }
        callback && callback();
    };

    const getFieldsArea = () => (
        <div className={'FieldsArea'}>
            <TextInput
                value={subject}
                outsideLabel={getMandatoryAsterisk('Subject')}
                outsideLabelWidth={160}
                onChange={onSubjectChange}
                double={true}
            />
            <div style={{clear: 'both'}}>{}</div>
            {getErrorHandlingSubjectArea()}
            <div style={{clear: 'both'}} />
            <TextAreaInput
                outsideLabel={`Comments`}
                outsideLabelWidth={160}
                value={comments}
                onChange={onCommentsChange}
                double={true}
                rows={3}
            />
            <div style={{clear: 'both'}} />
        </div>
    );

    const getAddFilesButtonArea = () => (
        <div className={styles.addFilesButtonArea}>
            <input
                type="file"
                name="files[]"
                data-ng-class="isAddDocumentDisabled() ? 'disabled': ''"
                data-ng-disabled="isAddDocumentDisabled()"
                id="upload"
                style={{opacity: 0, width: 0}}
                onChange={onFileUpload}
                accept="image/jpg, image/jpeg, image/png, application/pdf"
                multiple
            />
            <label htmlFor={'upload'}>
                <FormFooterButton color={buttonColor.blue} icon={faPlus}>
                    {`Add files...`}
                </FormFooterButton>
            </label>
        </div>
    );

    const getUploadDocumentsArea = () => (
        <UploadDocumentsArea
            intl={props.intl}
            documentList={documentList}
            applicationNumberPerDocument={applicationNumberPerDocument}
            applicationNumberErrorPerDocument={applicationNumberErrorPerDocument}
            documentTypeErrorPerDocument={documentTypeErrorPerDocument}
            timestamp={timestamp}
            checkApplicationNumber={checkApplicationNumber}
            onDocumentTypeSelect={onDocumentTypeSelect}
            onAddingApplicationNumber={onAddingApplicationNumber}
            deleteFile={deleteFile}
            editInvoice={editInvoice}
            showExplanationsModalInfo={showExplanationsModalInfo}
            criteria={criteria}
            updateCriteriaValue={updateCriteriaValue}
            invoiceDocumentsEdited={invoiceDocumentsEdited}
        />
    );

    const getNotesArea = () => (
        <div className={styles.notesArea}>
            <InputLink
                label={`Notes`}
                icon={informationOpened ? faChevronDown : faChevronRight}
                clickAction={toggleInformation}
            />
            <div style={{clear: 'both'}}>{}</div>
            {informationOpened && <BrowserInfo intl={props.intl} notUpload={false} plus={undefined} />}
        </div>
    );

    return (
        <DragAndDrop handleDrop={handleDrop}>
            {modalScreen === modalScreenTypes.MODAL_FILES ? (
                <ModalCPVOLogsVersion2
                    intl={props.intl}
                    plus={true}
                    zone={TLO_CPVO_BOX_UPLOAD_ZONE}
                    folder={folder}
                    subject={subject}
                    comments={comments}
                    notice={true}
                    timestamp={Date.now()}
                    files={files}
                    userMode={true}
                    successContent={getSuccessContentArea()}
                    close={resetStates}
                />
            ) : null}
            {modalScreen === modalScreenTypes.EXPLANATIONS_INFO ? (
                <ModalDocumentTypesExplanationsInfo close={closeModal} />
            ) : null}
            {invoiceDocumentForEdit !== null && modalScreen === modalScreenTypes.RECORD_INVOICE_DATA ? (
                <ModalRecordInvoiceData
                    invoiceData={invoiceDataPerDocument}
                    invoiceDocument={invoiceDocumentForEdit}
                    saveInvoiceData={saveInvoiceData}
                    close={closeModal}
                />
            ) : null}
            {isModalErrorFileEncryptedOpen.length ? (
                <ModalErrorVersion2
                    title={'Error'}
                    message={`The following file${isModalErrorFileEncryptedOpen.length > 1 ? 's' : ''} ${
                        isModalErrorFileEncryptedOpen.length > 1 ? 'are' : 'is'
                    } encrypted and ${
                        isModalErrorFileEncryptedOpen.length > 1 ? 'have' : 'has'
                    } been removed from the document list: ${isModalErrorFileEncryptedOpen.join(
                        ', '
                    )}. Please upload files without encryption.`}
                    close={() => setIsModalErrorFileEncryptedOpen([])}
                />
            ) : null}
            <div className={`cpvoBox`}>
                <ModalCustomVersion2
                    loading={loading}
                    close={() => props.close(true)}
                    header={`Send documents to CPVO`}
                    body={
                        <>
                            {getFieldsArea()}
                            {getAddFilesButtonArea()}
                            <div style={{clear: 'both'}}>{}</div>
                            {getUploadDocumentsArea()}
                            {getNotesArea()}
                        </>
                    }
                    footer={
                        <>
                            <Button
                                icon={'close'}
                                clickAction={() => props.close(true)}
                                variation={'danger'}
                            >{`Close`}</Button>
                            {documentList.length > 0 && (
                                <Button icon={'arrowRight'} clickAction={confirmSending}>{`Confirm Sending`}</Button>
                            )}
                        </>
                    }
                >
                    {modalScreen === modalScreenTypes.ERROR ? (
                        <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeModal} />
                    ) : null}
                </ModalCustomVersion2>
            </div>
        </DragAndDrop>
    );
};

export default ModalCpvoBox;
