import React, {useState, useEffect} from 'react';

// PROPS
import {ContentsFiltersCriteriaProps} from './props/ContentsFiltersCriteriaProps';

// STYLES
import styles from './ContentsFiltersCriteria.module.scss';

// INTERFACES
import {SectionElement} from '../../CommonInterfaces/CommonInterfaces';

// REUSABLE COMPONENTS
import SelectInput from '~components/SelectInput';

export default function ContentsFiltersCriteria(props: ContentsFiltersCriteriaProps) {
    const [section, setSection] = useState<string>(props.criteria.section || '');
    const [year, setYear] = useState<string>(props.criteria.year || '');
    const [language, setLanguage] = useState<string>(props.criteria.language || '1');

    useEffect(() => {
        setSection(props.criteria.section);
    }, [props.criteria.section]);

    useEffect(() => {
        setYear(props.criteria.year);
    }, [props.criteria.year]);

    useEffect(() => {
        setLanguage(props.criteria.language);
    }, [props.criteria.language]);

    useEffect(() => {
        if (props.yearsList.length === 1) {
            setYear(props.yearsList[0]);
            props.updateCriteriaValue({year: props.yearsList[0]});
        }
    }, [props.yearsList]);

    const getSectionsOptions = () => {
        let sectionOptions: Array<{id: number; value: string}> = [];

        props.sectionsList.forEach((section: SectionElement) =>
            sectionOptions.push({id: section?.ID, value: section?.NAME})
        );

        sectionOptions.sort((a, b) => {
            if (a.value < b.value) {
                return -1;
            }
            if (a.value > b.value) {
                return 1;
            }
            return 0;
        });
        if (section === '' && !section.includes('Please select a section')) {
            sectionOptions.unshift({id: 0, value: '-- Please select a section --'});
        }

        return sectionOptions;
    };

    const getYearOptions = () => {
        let yearOptions: Array<{id: number; value: string}> = [];

        props.yearsList.forEach((yearEl: string) => yearOptions.push({id: Number(yearEl), value: yearEl}));

        yearOptions.sort((a, b) => b.id - a.id);
        if (year === '' && !year.includes('Please select a year')) {
            yearOptions.unshift({id: 0, value: '-- Please select a year --'});
        }

        return yearOptions;
    };

    const getLanguageOptions = () => {
        let languageOptions: Array<{id: string; value: string}> = [];

        languageOptions.push({id: '1', value: 'English'});
        languageOptions.push({id: '2', value: 'French'});

        return languageOptions;
    };

    const onSectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const section = event.target.value;
        setSection(section);
        props.resetCriteria();
        if (!section.includes('Please select a section')) {
            props.getYearsTable(parseInt(section));
            props.updateCriteriaValue({section, year: '', language: '1'});
        } else {
            props.updateCriteriaValue({section, year: '', language: ''});
        }
    };

    const onYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const year = event.target.value;
        setYear(year);
        props.updateCriteriaValue({year});
    };

    const onLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const language = event.target.value;
        setLanguage(language);
        props.updateCriteriaValue({language});
    };

    return (
        <React.Fragment>
            <React.Fragment>
                <div className={styles.mainFieldContainer}>
                    <SelectInput
                        value={section}
                        onChange={onSectionChange}
                        double={true}
                        list={getSectionsOptions()}
                        outsideLabel={'Section'}
                        outsideLabelWidth={160}
                        disabled={props.treeIsEdited}
                        notAll={true}
                    />
                    {section !== '' && !section.includes('Please select a section') && (
                        <SelectInput
                            value={year}
                            onChange={onYearChange}
                            double={true}
                            list={getYearOptions()}
                            outsideLabel={'Year'}
                            outsideLabelWidth={160}
                            disabled={props.treeIsEdited}
                            notAll={true}
                        />
                    )}
                    {section !== '' &&
                        !section.includes('Please select a section') &&
                        props.yearsList.length > 0 &&
                        year !== '' &&
                        !year.includes('Please select a year') &&
                        !props.adminarea && (
                            <SelectInput
                                value={language}
                                onChange={onLanguageChange}
                                double={true}
                                list={getLanguageOptions()}
                                outsideLabel={'Language'}
                                outsideLabelWidth={160}
                                notAll={true}
                            />
                        )}
                    <div style={{clear: 'both'}}>{}</div>
                </div>
            </React.Fragment>
        </React.Fragment>
    );
}
