import React, {useEffect, useRef, useState} from 'react';
import AdminIcon from '../../../TLO/CommonComponents/AdminIcon/AdminIcon';
import CustomTable from '~components/CustomTable';
import {CountriesFetchData, CountryElement} from '../../CommonInterfaces/CommonInterfaces';
import {Footer, HeaderLoading, HeaderLogoMenu, HeaderTitleAndVersion, MainWrapper} from '../../../../componentsLayout';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {History} from 'history';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {ModalConfirmVersion2, ModalErrorVersion2} from '../../../../commonModals';
import ModalAcknowledgementsPagePerson from './modals/ModalAcknowledgementsPagePerson';
import NavigationPVRCaseLaw from '../../../../shared/NavigationPVRCaseLaw';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {
    apiPersonDelete,
    apiPersonEdit,
    apiPersonSave,
    apiPersonsListFetch,
    PersonElement,
    PersonsListFetchData,
} from './AcknowledgementsPagePVRCASELAWService';
import {apiCountriesFetch} from '../../CommonFunctions/CommonFunctions';
import {faEdit, faPlus, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {getUserRoles} from '../../../TLO/CommonFunctions/CommonFunctions';
import {injectIntl, IntlShape} from 'react-intl';
import styles from './AcknowledgementsPagePVRCASELAW.module.scss';
import {trackPageView} from '../../../../utils';

const modalScreenTypes = {
    NEW_PERSON: 'NEW_PERSON',
    SAVE_PERSON: 'SAVE_PERSON',
    ERROR: 'ERROR',
    MODIFY_PERSON: 'MODIFY_PERSON',
    CONFIRM_DELETE: 'CONFIRM_DELETE',
};

interface AcknowledgementsPagePVRCASELAWProps {
    intl: IntlShape;
    history: History;
}

const AcknowledgementsPagePVRCASELAW = (props: AcknowledgementsPagePVRCASELAWProps & RouteComponentProps) => {
    const userRoles = getUserRoles();
    const firstLoad = useFirstRender();

    const RESULT_FIELDS_DEFAULT = userRoles.indexOf('WLAW') !== -1 ? ['id', 'name', 'country'] : ['name', 'country'];

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [pvrcaselawPersonsList, setPvrcaselawPersonsList] = useState<PersonElement[]>([]);
    const [rowObjectForModal, setRowObjectForModal] = useState<PersonElement | null>(null);
    const [countries, setCountries] = useState<CountryElement[]>([]);

    useEffect(() => {
        trackPageView({documentTitle: 'pvrcaselawAcknowledgements'});
    }, []);

    function useFirstRender() {
        const firstRender = useRef(true);

        useEffect(() => {
            firstRender.current = false;
        }, []);

        return firstRender.current;
    }

    const getPersonsList = () => {
        setLoading(true);
        apiPersonsListFetch()
            .then((jsonResponse: PersonsListFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.acknowledgementsList) {
                    const acknowledgementsListFinal: PersonElement[] = [];
                    const acknowledgementsList = jsonResponse.data.acknowledgementsList;
                    acknowledgementsList.forEach(item => {
                        let itemNew = true;
                        acknowledgementsListFinal.forEach(itemFinal => {
                            if (itemNew && item.name === itemFinal.name && item.surname === itemFinal.surname) {
                                itemNew = false;
                                itemFinal.countryArray?.push(item.country);
                                itemFinal.countryIdArray?.push(item.countryId);
                                itemFinal.acknowledgementsIdsArray?.push(item.id);
                            }
                        });
                        if (itemNew) {
                            acknowledgementsListFinal.push({
                                id: item.id,
                                title: item.title,
                                name: item.name,
                                surname: item.surname,
                                country: '',
                                countryId: '',
                                countryArray: [item.country],
                                countryIdArray: [item.countryId],
                                acknowledgementsIdsArray: [item.id],
                            });
                        }
                    });
                    acknowledgementsListFinal.forEach(item => {
                        item.country = item.countryArray ? item.countryArray?.join(', ') : '';
                        item.countryId = item.countryIdArray ? item.countryIdArray?.join(', ') : '';
                    });
                    setPvrcaselawPersonsList(acknowledgementsListFinal);
                }
            })
            .catch(error => {
                setErrorMessage(`Acknowledgements Persons list fetch error: ${error.message}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => !firstLoad && setLoading(false));
    };

    const getCountries = () =>
        apiCountriesFetch()
            .then((jsonResponse: CountriesFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.countries)
                    setCountries(jsonResponse.data.countries);
            })
            .catch(error => {
                setErrorMessage(`Sections Table fetch error: ${error.message}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));

    useEffect(() => {
        if (firstLoad) {
            getPersonsList();
            getCountries();
        }
    }, []);

    const openModalPersonForAddition = (rowObject: PersonElement) => {
        setRowObjectForModal(null);
        setModalScreen(modalScreenTypes.NEW_PERSON);
    };

    const handlerModify = (rowId: number, rowObject: PersonElement) => {
        if (rowId > 0) {
            setRowObjectForModal(rowObject);
            setModalScreen(modalScreenTypes.MODIFY_PERSON);
        }
    };

    const handlerDelete = (rowId: number, rowObject: PersonElement) => {
        if (rowId > 0) {
            setRowObjectForModal(rowObject);
            setModalScreen(modalScreenTypes.CONFIRM_DELETE);
        }
    };

    const actions: any[] = [];
    actions.push({
        title: `Modify`,
        icon: () => {
            return (
                <a data-content={`Modify`}>
                    <FontAwesomeIcon icon={faEdit as IconDefinition} />
                </a>
            );
        },
        handler: handlerModify,
    });
    actions.push({
        title: `Delete`,
        icon: () => {
            return (
                <a data-content={`Delete`}>
                    <FontAwesomeIcon icon={faTrashAlt as IconDefinition} />
                </a>
            );
        },
        handler: handlerDelete,
    });

    const getFullName = (name: string | undefined, rowObject: PersonElement | null) => {
        if (rowObject) {
            const fullName = rowObject?.title + ' ' + name + ' ' + rowObject?.surname;
            return fullName.replace(/\s+/g, ' ');
        } else {
            return '';
        }
    };

    const getTopCaptionElement = () =>
        userRoles.indexOf('WLAW') !== -1 ? (
            <div style={{marginRight: 20}}>
                <FormFooterButton color={buttonColor.blue} clickAction={openModalPersonForAddition} icon={faPlus}>
                    {`New Person`}
                    <AdminIcon />
                </FormFooterButton>
            </div>
        ) : null;

    const getTableArea = () => {
        return pvrcaselawPersonsList.length === 0 ? (
            <div style={{paddingTop: 20, textAlign: 'left'}}>
                <b style={{color: 'red', textAlign: 'left'}}>
                    <span className="ng-scope">{`No Acknowledgement Persons available`}</span>
                </b>
            </div>
        ) : (
            <div className={styles.personsList}>
                <CustomTable
                    version={2}
                    {...props}
                    tableName={'pvrcaselawPersonsList'}
                    tableType={'OBJECT'}
                    tableSource={pvrcaselawPersonsList}
                    id={'id'}
                    hideExcelButton={true}
                    resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                    intl={props.intl}
                    defaultOrder={'id'}
                    reverseOrder={false}
                    count={pvrcaselawPersonsList.length}
                    formatFunctions={{
                        name: (name: string, rowObject: PersonElement) => getFullName(name, rowObject),
                    }}
                    actions={userRoles.indexOf('WLAW') !== -1 ? actions : undefined}
                    actionName={userRoles.indexOf('WLAW') !== -1 ? `Action` : undefined}
                    showAdminIcon={userRoles.indexOf('WLAW') !== -1}
                />
            </div>
        );
    };

    const closeModal = () => setModalScreen(null);

    const savePerson = (
        personId: number,
        personTitle: string,
        personName: string,
        personSurname: string,
        personCountries: string
    ) => {
        setLoading(true);
        const personCountriesArray = personCountries.split(', ');
        if (personId > 0) {
            setModalScreen(null);
            const acknowledgementsIdsArray = rowObjectForModal?.acknowledgementsIdsArray
                ? rowObjectForModal.acknowledgementsIdsArray
                : [rowObjectForModal?.id];
            const countryIdArray = rowObjectForModal?.countryIdArray
                ? rowObjectForModal.countryIdArray
                : [rowObjectForModal?.countryId];
            for (let i = 0; i < personCountriesArray.length; i++) {
                let countryExists = false;
                for (let j = 0; j < countryIdArray.length; j++) {
                    if (personCountriesArray[i] === countryIdArray[j]) {
                        countryExists = true;
                        apiPersonEdit(
                            Object.assign(
                                {},
                                {
                                    id: acknowledgementsIdsArray[j],
                                    title: personTitle,
                                    name: personName,
                                    surname: personSurname,
                                    countryId: countryIdArray[j],
                                }
                            )
                        )
                            .then(() => {
                                setModalScreen(null);
                                getPersonsList();
                            })
                            .catch(error => {
                                setLoading(false);
                                setErrorMessage(`error saving person: ${error.message}`);
                                setModalScreen(modalScreenTypes.ERROR);
                            });
                        break;
                    }
                }
                if (!countryExists) {
                    apiPersonSave(
                        Object.assign(
                            {},
                            {
                                title: personTitle,
                                name: personName,
                                surname: personSurname,
                                countryId: personCountriesArray[i],
                            }
                        )
                    )
                        .then(() => {
                            setModalScreen(null);
                            getPersonsList();
                        })
                        .catch(error => {
                            setLoading(false);
                            setErrorMessage(`error saving person: ${error.message}`);
                            setModalScreen(modalScreenTypes.ERROR);
                        });
                }
            }
            for (let j = 0; j < countryIdArray.length; j++) {
                let countryDeleted = true;
                for (let i = 0; i < personCountriesArray.length; i++) {
                    if (countryIdArray[j] === personCountriesArray[i]) {
                        countryDeleted = false;
                        break;
                    }
                }
                if (countryDeleted) {
                    apiPersonDelete(acknowledgementsIdsArray[j])
                        .then(() => {
                            setModalScreen(null);
                            getPersonsList();
                        })
                        .catch(error => {
                            setLoading(false);
                            setErrorMessage(`error deleting person: ${error}`);
                            setModalScreen(modalScreenTypes.ERROR);
                        });
                }
            }
        } else {
            personCountriesArray.forEach(countryEl => {
                apiPersonSave(
                    Object.assign(
                        {},
                        {
                            title: personTitle,
                            name: personName,
                            surname: personSurname,
                            countryId: countryEl,
                        }
                    )
                )
                    .then(() => {
                        setModalScreen(null);
                        getPersonsList();
                    })
                    .catch(error => {
                        setLoading(false);
                        setErrorMessage(`error saving person: ${error.message}`);
                        setModalScreen(modalScreenTypes.ERROR);
                    });
            });
        }
    };

    const deletePerson = () => {
        setModalScreen(null);
        setLoading(true);
        if (rowObjectForModal !== null) {
            const personIds = rowObjectForModal.acknowledgementsIdsArray
                ? rowObjectForModal.acknowledgementsIdsArray
                : [rowObjectForModal.id];
            personIds.forEach(el => {
                apiPersonDelete(el)
                    .then(() => {
                        setModalScreen(null);
                        getPersonsList();
                    })
                    .catch(error => {
                        setLoading(false);
                        setErrorMessage(`error saving tree: ${error}`);
                        setModalScreen(modalScreenTypes.ERROR);
                    });
            });
        }
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeModal} />
            ) : null}
            {modalScreen === modalScreenTypes.NEW_PERSON ? (
                <ModalAcknowledgementsPagePerson
                    personElement={null}
                    countriesList={countries}
                    savePerson={savePerson}
                    close={closeModal}
                />
            ) : null}
            {modalScreen === modalScreenTypes.MODIFY_PERSON && rowObjectForModal !== null ? (
                <ModalAcknowledgementsPagePerson
                    personElement={rowObjectForModal}
                    countriesList={countries}
                    savePerson={savePerson}
                    close={closeModal}
                />
            ) : null}
            {modalScreen === modalScreenTypes.CONFIRM_DELETE ? (
                <ModalConfirmVersion2
                    title={`Delete Person`}
                    message={`Are you sure you want to delete this person?`}
                    buttonName={'Delete'}
                    action={deletePerson}
                    close={closeModal}
                />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`PVR Case Law`} />
            <NavigationPVRCaseLaw />
            <MainWrapper>
                <div
                    style={{
                        marginBottom: 20,
                        minHeight: 600,
                        textAlign: 'center',
                        maxWidth: '100%',
                    }}
                >
                    {!loading ? (
                        <>
                            {getTopCaptionElement()}
                            <div style={{clear: 'both'}} />
                            <div className={styles.acknowledgementsPage}>{getTableArea()}</div>
                        </>
                    ) : null}
                </div>
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(AcknowledgementsPagePVRCASELAW));
