import React from 'react';
import Checkbox from '~components/Checkbox';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {IDecodedJWT} from '../../types';
import TextInput from '~components/TextInput';
import {apiCommunicationCentreModalInternalSaveSearch} from './CCModalInternalSaveSearchService';
import {getDecodedJWT} from '../../utils';
import {getSelectListOfDirectory} from '../CommunicationCentreInternal/CommunicationCentreInternal.utils';
import statusFlag from '../MyPVRMyDocuments/data/DocumentsStatusFlag.json';
import styles from './CCModalInternalSaveSearch.module.scss';
import {ModalCustomVersion2} from '../../commonModals';
import {Error, LoadingBar} from '../../componentsLayout';
import {Button} from '../../componentsFormV2';

interface IProps {
    close: () => any;
    criteria: {[key: string]: any};
    selectedClients: {[key: string]: string};
    documentTypesSelectedElements: {[key: string]: string};
    directorySelectedElements: {[key: string]: string};
    // toDoBySelectedElements: {[key: string]: string};
}

interface IState {
    loading: boolean;
    checkboxSetAsDefaultSearchValue: boolean;
    textInputSearchNameValue: string;
    errorMessage: string;
}

class CCModalInternalSaveSearch extends React.Component<IProps, IState> {
    decodedJWT: IDecodedJWT | null = getDecodedJWT();

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            checkboxSetAsDefaultSearchValue: false,
            textInputSearchNameValue: '',
            errorMessage: '',
        };
    }

    getValueForDirectory = (directoryId: string) =>
        getSelectListOfDirectory(this.decodedJWT).find(el => el.id === directoryId)?.value || '';

    getSelectInputValueFromId = (list: {id: string; value: string}[], id: string, all?: boolean) => {
        return list.find(el => el.id === id)?.value || (all ? 'All' : '');
    };

    onTextInputSearchTermChange = ({
        target: {value: textInputSearchNameValue},
    }: React.ChangeEvent<HTMLInputElement>): void => this.setState({textInputSearchNameValue});

    onCheckboxSetAsDefaultSearchClick = () =>
        this.setState(prev => ({
            checkboxSetAsDefaultSearchValue: !prev.checkboxSetAsDefaultSearchValue,
        }));

    onButtonSaveClick = () => {
        this.setState(
            {
                errorMessage: '',
            },
            () => {
                const fields: any = {};
                if (!this.state.textInputSearchNameValue) {
                    return this.setState({errorMessage: 'Please type a search name'});
                }
                if (this.props.criteria.searchTerm) {
                    fields.searchTerm = this.props.criteria.searchTerm;
                }
                if (this.props.criteria.applicationNumber) {
                    fields.applicationNumber = this.props.criteria.applicationNumber;
                }
                if (this.props.criteria.arrivalDateFrom) {
                    fields.arrivalDateFrom = this.props.criteria.arrivalDateFrom;
                }
                if (this.props.criteria.arrivalDateTo) {
                    fields.arrivalDateTo = this.props.criteria.arrivalDateTo;
                }
                if (Object.keys(this.props.directorySelectedElements).length) {
                    fields.directory = Object.keys(this.props.directorySelectedElements).join(',');
                }
                // if (Object.keys(this.props.toDoBySelectedElements).length) {
                //     fields.todoBy = Object.keys(this.props.toDoBySelectedElements).join(',');
                // }
                if (this.props.criteria.caseHolder && this.props.criteria.caseHolder !== 'All') {
                    fields.caseHolder = this.props.criteria.caseHolder;
                }
                // if (this.props.criteria.annotations && this.props.criteria.annotations !== 'All') {
                //     fields.annotations = this.props.criteria.annotations;
                // }
                if (this.props.criteria.readingStatus && this.props.criteria.readingStatus !== 'All') {
                    fields.readingStatus = this.props.criteria.readingStatus;
                }
                if (Object.keys(this.props.documentTypesSelectedElements).length) {
                    fields.documentType = Object.keys(this.props.documentTypesSelectedElements).join(',');
                }
                if (this.props.criteria.cc && this.props.criteria.cc !== 'All') {
                    fields.cc = this.props.criteria.cc;
                }
                if (this.props.criteria.from) {
                    fields.from = this.props.criteria.from;
                }
                if (this.props.criteria.flag !== '-1') {
                    fields.flag = this.props.criteria.flag;
                }
                const JSONRequest: any = {
                    searchName: this.state.textInputSearchNameValue,
                    isDefault: this.state.checkboxSetAsDefaultSearchValue,
                    isAlertEnabled: false,
                    fields: Object.entries(fields).map(([key, value]) => ({
                        fieldName: key,
                        fieldValue: value,
                    })),
                };
                this.setState(
                    {
                        loading: true,
                    },
                    () => {
                        apiCommunicationCentreModalInternalSaveSearch(JSONRequest)
                            .then(JSONResponse => {
                                if (JSONResponse && JSONResponse.data === 'OK') {
                                    this.props.close();
                                }
                            })
                            .then(() => {
                                this.setState({
                                    loading: false,
                                });
                            });
                    }
                );
            }
        );
    };

    render() {
        const directoryValue = Object.values(this.props.directorySelectedElements).join(', ');
        // const todoByValue = Object.values(this.props.toDoBySelectedElements).join(', ');
        const documentTypesValue = Object.values(this.props.documentTypesSelectedElements).join(', ');
        const readingStatusValue = this.getSelectInputValueFromId(
            [
                {id: '1', value: 'Read'},
                {id: '2', value: 'not read'},
            ],
            this.props.criteria?.readingStatus,
            true
        );
        const CCValue = this.getSelectInputValueFromId(
            [{id: '1', value: 'Show only CC messages'}],
            this.props.criteria.cc,
            true
        );
        const fromValue = Object.values(this.props.selectedClients || {}).join(', ');
        const tickingStatusValue = this.getSelectInputValueFromId(statusFlag, this.props.criteria.flag);
        const annotationsValue = this.getSelectInputValueFromId(
            [{id: '1', value: 'Show only annotated messages'}],
            this.props.criteria.annotations,
            true
        );

        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={`Save search`}
                body={
                    <>
                        <TextInput
                            double={true}
                            label={`Search name`}
                            onChange={this.onTextInputSearchTermChange}
                            placeholder={`Type a search name`}
                            value={this.state.textInputSearchNameValue}
                        />
                        <div>
                            <Checkbox
                                clickAction={this.onCheckboxSetAsDefaultSearchClick}
                                simple={true}
                                label={`Set as default search`}
                                value={this.state.checkboxSetAsDefaultSearchValue}
                                width={500}
                            />
                        </div>
                        <div style={{clear: 'both'}} />
                        <div className={styles.dataSheetFormSectionWrap}>
                            <DataSheetFormSection title={'Search filters'}>
                                <DataSheetFormFields
                                    data={this.props.criteria.searchTerm}
                                    double={true}
                                    label={'Search term'}
                                />
                                <DataSheetFormFields
                                    data={this.props.criteria.applicationNumber}
                                    double={true}
                                    label={'Application number'}
                                />
                                <DataSheetFormFields
                                    data={this.props.criteria.arrivalDateFrom}
                                    double={true}
                                    label={'Arrival date from'}
                                />
                                <DataSheetFormFields
                                    data={this.props.criteria.arrivalDateTo}
                                    double={true}
                                    label={'Arrival date to'}
                                />
                                <DataSheetFormFields data={directoryValue} double={true} label={'Directory'} />
                                {/*<DataSheetFormFields data={todoByValue} double={true} label={'To do by'} />*/}
                                <DataSheetFormFields
                                    data={this.props.criteria.caseHolder}
                                    double={true}
                                    label={'Caseholder'}
                                />
                                <DataSheetFormFields data={annotationsValue} double={true} label={'Annotations'} />
                                <DataSheetFormFields data={readingStatusValue} double={true} label={'Reading status'} />
                                <DataSheetFormFields data={documentTypesValue} double={true} label={'Document type'} />
                                <DataSheetFormFields data={CCValue} double={true} label={'CC'} />
                                <DataSheetFormFields data={tickingStatusValue} double={true} label={'Ticking status'} />
                                <DataSheetFormFields data={fromValue} double={true} label={'From'} />
                            </DataSheetFormSection>
                        </div>
                    </>
                }
                footer={
                    this.state.loading ? (
                        <LoadingBar />
                    ) : (
                        <>
                            {this.state.errorMessage ? <Error>{this.state.errorMessage}</Error> : null}
                            <Button
                                variation={'danger'}
                                clickAction={this.props.close}
                                icon={'close'}
                            >{`Close`}</Button>
                            <Button clickAction={this.onButtonSaveClick} icon={'arrowRight'}>{`Save`}</Button>
                        </>
                    )
                }
            />
        );
    }
}

export default CCModalInternalSaveSearch;
