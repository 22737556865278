import React, {Component} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {getEnvironment} from './utils';
import 'react-datepicker/dist/react-datepicker.css';
import jwtDecode from 'jwt-decode';
// import {getCookieValue} from './common/init';
// import {TrackJS} from 'trackjs';
import AuthenticationChangePassword from './screens/ChangePassword/ChangePassword';
import AuthenticationLogin from './screens/AuthenticationLogin/AuthenticationLogin';
import AuthenticationRegistration from './screens/AuthenticationRegistration/AuthenticationRegistration';
import AuthenticationMyProfile from './screens/AuthenticationMyProfile/AuthenticationMyProfile';
import AuthenticationTermsAndConditions from './screens/TermsAndConditions/TermsAndConditions';
import BackOffice from './screens/BackOffice/BackOffice';
import BackOfficeFormGeneralSettings from './screens/BackOfficeFormGeneralSettings/BackOfficeFormGeneralSettings';
import BackOfficeFormQuestions from './screens/BackOfficeFormQuestions/BackOfficeFormQuestions';
import BackOfficePictures from './screens/BackOfficePictures/BackOfficePictures';
import BackOfficeForms from './screens/BackOfficeForms/BackOfficeForms';
import BackOfficeLabels from './screens/BackOfficeLabels/BackOfficeLabels';
import BackOfficePdf from './screens/BackOfficePdf/BackOfficePdf';
import BackOfficeRequests from './screens/BackOfficeRequests/BackOfficeRequests';
import BackOfficeTemplateDetails from './screens/BackOfficeTemplateDetails/BackOfficeTemplateDetails';
import BackOfficeTemplateQuestions from './screens/BackOfficeTemplateQuestions/BackOfficeTemplateQuestions';
import BackOfficeTemplates from './screens/BackOfficeTemplates/BackOfficeTemplates';
import Book from './screens/Book/Book';
import CCClientArchive from './screens/CCClientArchive/CCClientArchive';
import CCClientDraft from './screens/CCClientDraft/CCClientDraft';
import CCClientInbox from './screens/CCClientInbox/CCClientInbox';
import CCClientSent from './screens/CCClientSent/CCClientSent';
import CCInternalAlerts from './screens/CCInternalAlerts/CCInternalAlerts';
import CCInternalArchive from './screens/CCInternalArchive/CCInternalArchive';
import CCInternalDraft from './screens/CCInternalDraft/CCInternalDraft';
import CCInternalInbox from './screens/CCInternalInbox/CCInternalInbox';
import CCInternalSent from './screens/CCInternalSent/CCInternalSent';
import Cookies from './screens/Cookies/Cookies';
import CPVOLogs from './screens/CPVOLogs/CPVOLogs';
import DefinePassword from './screens/DefinePassword/DefinePassword';
import ErrorScreen from './shared/ErrorScreen';
import Logout from './screens/Logout/Logout';
import Dashboard from './screens/Dashboard/Dashboard';
import MyPVRPublication from './screens/MyPVRPublication/MyPVRPublication';
import MyPVRPublicSearch from './screens/MyPVRPublicSearch/MyPVRPublicSearch';
import MyPVRLegalProceedings from './screens/MyPVRLegalProceedings/MyPVRLegalProceedings';
import MyPVRAdminProceedings from './screens/MyPVRAdminProceedings/MyPVRAdminProceedings';
import MyPVRMyApplications from './screens/MyPVRMyApplications/MyPVRMyApplications';
import MyPVRPrivateConsultationDetails from './screens/MyPVRPrivateConsultationDetails/MyPVRPrivateConsultationDetails';
import MyPVRPrivateConsultationExamination from './screens/MyPVRPrivateConsultationExamination/MyPVRPrivateConsultationExamination';
import MyPVRPrivateConsultationPublications from './screens/MyPVRPrivateConsultationPublications/MyPVRPrivateConsultationPublications';
import MyPVRPrivateConsultationDocuments from './screens/MyPVRPrivateConsultationDocuments/MyPVRPrivateConsultationDocuments';
import MyPVRPrivateConsultationFees from './screens/MyPVRPrivateConsultationFees/MyPVRPrivateConsultationFees';
import MyPVRMyDenominations from './screens/MyPVRMyDenominations/MyPVRMyDenominations';
import MyPVREndOfFiles from './screens/MyPVREndOfFiles/MyPVREndOfFiles';
import MyPVRMyDocuments from './screens/MyPVRMyDocuments/MyPVRMyDocuments';
import MyPVRMyFees from './screens/MyPVRMyFees/MyPVRMyFees';
import MyPVRPublicConsultationDetails from './screens/MyPVRPublicConsultationDetails/MyPVRPublicConsultationDetails';
import MyPVRPublicConsultationExamination from './screens/MyPVRPublicConsultationExamination/MyPVRPublicConsultationExamination';
import MyPVRPublicConsultationPublications from './screens/MyPVRPublicConsultationPublications/MyPVRPublicConsultationPublications';
import MyPVRPublicConsultationDocuments from './screens/MyPVRPublicConsultationDocuments/MyPVRPublicConsultationDocuments';
import OnlineSettingsGeneral from './screens/OnlineSettingsGeneral/OnlineSettingsGeneral';
import OnlineApplications from './screens/OnlineApplications/OnlineApplications';
import OnlineFormDraft from './screens/OnlineFormDraft/OnlineFormDraft';
import OnlinePdf from './screens/OnlinePdf/OnlinePdf';
import OnlineDelegations from './screens/OnlineDelegations/OnlineDelegations';
import OASignApplication from './screens/OnlineSignApplication/OnlineSignApplication';
import PDFValidationService from './screens/PDFValidationService/PDFValidationService';
import PublicAccess from './screens/PublicAccess/PublicAccess';

import RequestCertifiedCopies from './screens/RequestCertifiedCopies/RequestCertifiedCopies';
import Welcome from './screens/Welcome/Welcome';
import CommunicationCentreInternalCPVOTemplates from './screens/CommunicationCentreInternalCPVOTemplates/CommunicationCentreInternalCPVOTemplates';
import CommunicationCentreInternalBackOffice from './screens/CCInternalBackOffice/CCInternalBackOffice';
import PublicTQSearch from './screens/PublicTQSearch/PublicTQSearch';
// CPVO ACAREA pages
import HomePageACAREA from './screens/ACAREA/Screens/HomePageACAREA/HomePageACAREA';
import AdministratorPageSections from './screens/ACAreaAdministratorSections/ACAreaAdministratorSections';
import AdministratorPageContents from './screens/ACAreaAdministratorContents/ACAreaAdministratorContents';
import MembersListACAREA from './screens/ACAREA/Screens/MembersListACAREA/MembersListACAREA';
import Sections from './screens/ACAREA/Screens/SectionsACAREA/SectionsACAREA';
import RulesOfProcedures from './screens/ACAREA/Screens/RulesOfProcedures/RulesOfProcedures';

//CPVO PVR CASELAW pages
import HomePagePVRCASELAW from './screens/PVRCASELAW/Screens/HomePagePVRCASELAW/HomePagePVRCASELAW';
import AcknowledgementsPagePVRCASELAW from './screens/PVRCASELAW/Screens/AcknowledgementsPagePVRCASELAW/AcknowledgementsPagePVRCASELAW';
import FaqPagePVRCASELAW from './screens/PVRCASELAW/Screens/FaqPagePVRCASELAW/FaqPVRCASELAW';
import SearchCasesPagePVRCASELAW from './screens/PVRCASELAW/Screens/SearchCasesPageBasicPVRCASELAW/SearchCasesPageBasicPVRCASELAW';
import SearchCasesPageAdvancedPVRCASELAW from './screens/PVRCASELAW/Screens/SearchCasesPageAdvancedPVRCASELAW/SearchCasesPageAdvancedPVRCASELAW';
import CaseFormPagePVRCASELAW from './screens/PVRCASELAW/Screens/CaseFormPagePVRCASELAW/CaseFormPagePVRCASELAW';

// CPVO TLO pages
import HomePage from './screens/TLO/Screens/HomePage/HomePage';
import MyRegistersPage from './screens/TLO/Screens/MyRegistersPage/MyRegistersPage';
import MyInvoicesPage from './screens/TLO/Screens/MyInvoicesPage/MyInvoicesPage';
import DesignationAgreementPage from './screens/TLO/Screens/DesignationAgreementPage/DesignationAgreementPage';
import ScopeOfEntrustmentPage from './screens/TLO/Screens/ScopeOfEntrustmentPage/ScopeOfEntrustmentPage';
import ConsultModifySpeciesDataPage from './screens/TLO/Screens/ConsultModifySpeciesDataPage/ConsultModifySpeciesDataPage';
import ReportingDeadlinesPage from './screens/TLO/Screens/ReportingDeadlinesPage/ReportingDeadlinesPage';
import RequestNewEntrustmentPage from './screens/TLO/Screens/RequestNewEntrustmentPage/RequestNewEntrustmentPage';
import WithdrawAnEntrustmentPage from './screens/TLO/Screens/WithdrawAnEntrustmentPage/WithdrawAnEntrustmentPage';
import NewSpeciesProcedurePage from './screens/TLO/Screens/NewSpeciesProcedurePage/NewSpeciesProcedurePage';
import MyRnDProjectsPage from './screens/TLO/Screens/MyRnDProjectsPage/MyRnDProjectsPage';
import QasRequirementsPage from './screens/TLO/Screens/QasRequirementsPage/QasRequirementsPage';
import AdminVademecumPage from './screens/TLO/Screens/AdminVademecumPage/AdminVademecumPage';
import TemplatesPage from './screens/TLO/Screens/TemplatesPage/TemplatesPage';
import GeneralInformationPage from './screens/TLO/Screens/GeneralInformationPage/GeneralInformationPage';
import TqSearchPage from './screens/TLO/Screens/TqSearchPage/TqSearchPage';
import TqSearchCandidateVarietiesPage from './screens/TLO/Screens/TqSearchCandidateVarietiesPage/TqSearchCandidateVarietiesPage';
import CpvoBoxPage from './screens/TLO/Screens/CpvoBoxPage/CpvoBoxPage';
import AdministratorPage from './screens/TLO/Screens/AdministratorPage/AdministratorPage';
import MyFruitSubmissionsPage from './screens/TLO/Screens/MyFruitSubmissionsPage/MyFruitSubmissionsPage';
import VFTermsOfAPIUse from './screens/VFTermsOfAPIUse/VFTermsOfAPIUse';
import VFUPOVClasses from './screens/VFUPOVClasses/VFUPOVClasses';
import VFMyAlerts from './screens/VFMyAlerts/VFMyAlerts';
import VFMyTests from './screens/VFMyTests/VFMyTests';
import VFRegisters from './screens/VFRegisters/VFRegisters';
import VFSpecies from './screens/VFSpecies/VFSpecies';
import VFStatistics from './screens/VFStatistics/VFStatistics';
import VFTestDenominations from './screens/VFTestDenominations/VFTestDenominations';
import VFVarieties from './screens/VFVarieties/VFVarieties';
import VFSearchVarieties from './screens/VFSearchVarieties/VFSearchVarieties';
import VFJurisprudence from './screens/VFJurisprudence/VFJurisprudence';
import VFContributorsPortal from './screens/VFContributorsPortal/VFContributorsPortal';
import VDCCaseMessage from './screens/VDCCaseMessage/VDCCaseMessage';
import VDCCases from './screens/VDCCases/VDCCases';
import VDCCaseJurisprudence from './screens/VDCCaseJurisprudence/VDCCaseJurisprudence';
import VFRequestAPIAccess from './screens/VFRequestAPIAccess/VFRequestAPIAccess';

function isLogged() {
    const remember = localStorage.getItem('remember') === '1';
    return (remember ? localStorage : sessionStorage).getItem('token');
}

const PrivateRouteMyPVR: React.FC<{
    component: React.FC,
    path: string,
    // exact: boolean,
}> = props => {
    let condition = false;
    const remember = localStorage.getItem('remember') === '1';
    let authToken = (remember ? localStorage : sessionStorage).getItem('token');
    //check token Communication Centre
    const tokenCC = localStorage.getItem('token-cc');
    if ((!authToken || authToken === 'null') && tokenCC) {
        localStorage.removeItem('token-cc');
        const remember = localStorage.getItem('remember') === '1';
        remember && (remember ? localStorage : sessionStorage).setItem('token', tokenCC);
    }
    //
    if (authToken) {
        const decoded = jwtDecode(authToken);
        if (
            decoded &&
            (decoded.roles.indexOf('WMPVR') !== -1 ||
                decoded.roles.indexOf('CCACCESS') !== -1 ||
                decoded.roles.indexOf('WBACC') !== -1 || // all users with access to ACAREA have this role
                decoded.roles.indexOf('WLAW') !== -1 || // admin users with access to PVR CASELAW have this role
                decoded.roles.indexOf('WETLO') !== -1 ||
                decoded.roles.indexOf('EOVIS') !== -1 ||
                decoded.thirdPartyId === '1')
        ) {
            condition = true;
        }
        // TODO remove this on the Welcomepage
        // if (localStorage.getItem('MYPVR') === 'true') {
        //     condition = true;
        // }
        if (getEnvironment() === 'DEV' || getEnvironment() === 'DEMO' || getEnvironment() === 'LOCALHOST') {
            condition = true;
        }
    } else if (getEnvironment() === 'LOCALHOST' || getEnvironment() === 'DEV') {
        condition = true;
    }
    try {
        fetch('/message.html')
            .then(response => response.text())
            .then(responseText => (document.getElementById('cpvoMessage').innerHTML = responseText));
    } catch (e) {
        LOG`${e && e.message}`;
    }
    return condition ? (
        <Route path={props.path} exact={props.exact} component={props.component} />
    ) : isLogged() ? (
        <Redirect to="/welcome" />
    ) : (
        <Redirect to={`/login?target=${props.path}${document.location.search}`} />
    );
    // <Route path={props.path} component={isLogged() ? Welcome : Login} />
};

const LoggedUserRoute: React.FC<{
    component: React.FC,
    path: string,
    // exact: boolean,
}> = props => {
    let condition = false;
    const remember = localStorage.getItem('remember') === '1';
    let authToken = (remember ? localStorage : sessionStorage).getItem('token');
    //check token Communication Centre
    const tokenCC = localStorage.getItem('token-cc');
    if ((!authToken || authToken === 'null') && tokenCC) {
        localStorage.removeItem('token-cc');
        const remember = localStorage.getItem('remember') === '1';
        remember && (remember ? localStorage : sessionStorage).setItem('token', tokenCC);
    }
    if (authToken) {
        condition = true;
    }
    try {
        fetch('/message.html')
            .then(response => response.text())
            .then(responseText => (document.getElementById('cpvoMessage').innerHTML = responseText));
    } catch (e) {
        LOG`${e && e.message}`;
    }
    return condition ? (
        <Route path={props.path} exact={props.exact} component={props.component} />
    ) : isLogged() ? (
        <Redirect to="/welcome" />
    ) : (
        <Redirect to={`/login?target=${props.path}${document.location.search}`} />
    );
};

const PublicRouteMyPVR: React.FC<{
    component: React.FC,
    path: string,
    // exact: boolean,
}> = props => {
    try {
        fetch('/message.html')
            .then(response => response.text())
            .then(responseText => (document.getElementById('cpvoMessage').innerHTML = responseText));
    } catch (e) {
        LOG`${e && e.message}`;
    }
    return <Route path={props.path} exact={props.exact} component={props.component} />;
};

class DebugRouter extends Router {
    constructor(props) {
        super(props);
        LOG`initial history is: ${JSON.stringify(JSON.stringify(this.history, null, 2))}`;
        this.history.listen((location, action) => {
            LOG`The current URL is ${location.pathname}${location.search}${location.hash}`;
            LOG`The last navigation action was ${action}; ${JSON.stringify(this.history, null, 2)}}`;
        });
    }
}

export default class App extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const remember = localStorage.getItem('remember') === '1';
        const auth = (remember ? localStorage : sessionStorage).getItem('auth');
        LOG`auth: ${JSON.stringify(auth, null, 4)}`;
        const history = createBrowserHistory();

        return (
            <>
                <DebugRouter>
                    <Router history={history}>
                        <Switch>
                            <PublicRouteMyPVR path="/cookies" component={Cookies} />
                            <PublicRouteMyPVR path="/book" component={Book} />
                            <Route path="/backOffice" component={BackOffice} />
                            <PublicRouteMyPVR path="/login" component={AuthenticationLogin} />
                            <PublicRouteMyPVR path="/register" component={AuthenticationRegistration} />
                            <PublicRouteMyPVR path="/profile" component={AuthenticationMyProfile} />
                            <PublicRouteMyPVR path="/logout" component={Logout} />
                            <PublicRouteMyPVR path="/welcome" component={Welcome} />
                            <PrivateRouteMyPVR path="/dashboard" component={Dashboard} />
                            <PublicRouteMyPVR
                                path="/publicConsultationDetails"
                                component={MyPVRPublicConsultationDetails}
                            />
                            <PublicRouteMyPVR
                                path="/publicConsultationExamination"
                                component={MyPVRPublicConsultationExamination}
                            />
                            <PublicRouteMyPVR
                                path="/publicConsultationPublications"
                                component={MyPVRPublicConsultationPublications}
                            />
                            <PublicRouteMyPVR
                                path="/publicConsultationDocuments"
                                component={MyPVRPublicConsultationDocuments}
                            />
                            <Route path="/publicSearch" component={MyPVRPublicSearch} />
                            <LoggedUserRoute path="/legalProceedings" component={MyPVRLegalProceedings} />
                            <PublicRouteMyPVR path="/publication" component={MyPVRPublication} />
                            <PrivateRouteMyPVR path="/adminProceedings" component={MyPVRAdminProceedings} />
                            <PrivateRouteMyPVR path="/myApplications" component={MyPVRMyApplications} />
                            <PrivateRouteMyPVR
                                path="/privateConsultationDetails"
                                component={MyPVRPrivateConsultationDetails}
                            />
                            <PrivateRouteMyPVR
                                path="/privateConsultationExamination"
                                component={MyPVRPrivateConsultationExamination}
                            />
                            <PrivateRouteMyPVR
                                path="/privateConsultationPublications"
                                component={MyPVRPrivateConsultationPublications}
                            />
                            <PrivateRouteMyPVR
                                path="/privateConsultationDocuments"
                                component={MyPVRPrivateConsultationDocuments}
                            />
                            <PrivateRouteMyPVR
                                path="/privateConsultationFees"
                                component={MyPVRPrivateConsultationFees}
                            />
                            <PrivateRouteMyPVR path="/denomination" component={MyPVRMyDenominations} />
                            <PrivateRouteMyPVR path="/endOfFiles" component={MyPVREndOfFiles} />
                            <PrivateRouteMyPVR path="/myDocuments" component={MyPVRMyDocuments} />
                            <PrivateRouteMyPVR path="/myFees" component={MyPVRMyFees} />
                            <PrivateRouteMyPVR path="/onlineSettingsGeneral" component={OnlineSettingsGeneral} />
                            <PublicRouteMyPVR path="/applications" component={OnlineApplications} />
                            <PublicRouteMyPVR path="/onlinePdf" component={OnlinePdf} />
                            <PublicRouteMyPVR path="/formDraft" component={OnlineFormDraft} />
                            <PublicRouteMyPVR path="/delegations" component={OnlineDelegations} />
                            <PublicRouteMyPVR path="/onlineSignApplication" component={OASignApplication} />
                            {/**/}
                            <PublicRouteMyPVR path="/backOfficePictures" component={BackOfficePictures} />
                            <PublicRouteMyPVR path="/forms" component={BackOfficeForms} />
                            <PublicRouteMyPVR path="/labels" component={BackOfficeLabels} />
                            <PublicRouteMyPVR path="/templates" component={BackOfficeTemplates} />
                            <PublicRouteMyPVR path="/TQSearch" component={PublicTQSearch} />
                            <PublicRouteMyPVR path="/backOfficePdf" component={BackOfficePdf} />
                            <PublicRouteMyPVR path="/requests" component={BackOfficeRequests} />
                            <PublicRouteMyPVR path="/templateDetails" component={BackOfficeTemplateDetails} />
                            <PublicRouteMyPVR path="/templateQuestions" component={BackOfficeTemplateQuestions} />
                            <PublicRouteMyPVR
                                path="/backOfficeFormGeneralSettings"
                                component={BackOfficeFormGeneralSettings}
                            />
                            <PublicRouteMyPVR path="/backOfficeFormQuestions" component={BackOfficeFormQuestions} />
                            {/**/}
                            {/*<PublicRouteMyPVR path="/certifiedAccess" component={RequestCertifiedCopies} />*/}
                            <PublicRouteMyPVR path="/PDFValidationService" component={PDFValidationService} />
                            <PublicRouteMyPVR path="/requestCertifiedCopies" component={RequestCertifiedCopies} />
                            <PublicRouteMyPVR path="/publicAccess" component={PublicAccess} />
                            {/**/}
                            <PublicRouteMyPVR path="/error" component={ErrorScreen} />
                            {/**/}
                            <PublicRouteMyPVR path="/definePassword" component={DefinePassword} />
                            <PrivateRouteMyPVR path="/changePassword" component={AuthenticationChangePassword} />
                            <PublicRouteMyPVR path="/TermsAndConditions" component={AuthenticationTermsAndConditions} />
                            <PrivateRouteMyPVR path="/cpvoLogs" component={CPVOLogs} />
                            <PrivateRouteMyPVR path="/CommunicationCentreClientArchive" component={CCClientArchive} />
                            <PrivateRouteMyPVR path="/CommunicationCentreClientDraft" component={CCClientDraft} />
                            <PrivateRouteMyPVR path="/communicationCentreClientInbox" component={CCClientInbox} />
                            <PrivateRouteMyPVR path="/CommunicationCentreClientSent" component={CCClientSent} />
                            <PrivateRouteMyPVR path="/CommunicationCentreInternalAlerts" component={CCInternalAlerts} />
                            <PrivateRouteMyPVR
                                path="/communicationCentreInternalArchive"
                                component={CCInternalArchive}
                            />
                            <PrivateRouteMyPVR path="/communicationCentreInternalDraft" component={CCInternalDraft} />
                            <PrivateRouteMyPVR path="/communicationCentreInternalInbox" component={CCInternalInbox} />
                            <PrivateRouteMyPVR path="/communicationCentreInternalSent" component={CCInternalSent} />
                            <PrivateRouteMyPVR
                                path="/communicationCentreInternalCPVOTemplates"
                                component={CommunicationCentreInternalCPVOTemplates}
                            />
                            <PrivateRouteMyPVR
                                path="/communicationCentreInternalBackOffice"
                                component={CommunicationCentreInternalBackOffice}
                            />

                            {/* CPVO ACAREA - START */}
                            <PrivateRouteMyPVR path="/acarea" component={HomePageACAREA} />
                            <PrivateRouteMyPVR
                                path="/acareaAdministratorSections"
                                component={AdministratorPageSections}
                            />
                            <PrivateRouteMyPVR
                                path="/acareaAdministratorContents"
                                component={AdministratorPageContents}
                            />
                            <PrivateRouteMyPVR path="/acareaSections" component={Sections} />
                            <PrivateRouteMyPVR path="/acareaRulesOfProcedures" component={RulesOfProcedures} />
                            <PrivateRouteMyPVR path="/acareaMembersList" component={MembersListACAREA} />
                            {/* CPVO ACAREA - END */}

                            {/* CPVO PVRCASELAW - START */}
                            <PublicRouteMyPVR path="/pvrcaselaw" exact component={HomePagePVRCASELAW} />
                            <PublicRouteMyPVR
                                path="/pvrcaselawAcknowledgements"
                                component={AcknowledgementsPagePVRCASELAW}
                            />
                            <PublicRouteMyPVR path="/pvrcaselawFaq" component={FaqPagePVRCASELAW} />
                            <PublicRouteMyPVR
                                path="/pvrcaselawSearchCasesBasicSearch"
                                component={SearchCasesPagePVRCASELAW}
                            />
                            <PublicRouteMyPVR
                                path="/pvrcaselawSearchCasesAdvancedSearch"
                                component={SearchCasesPageAdvancedPVRCASELAW}
                            />
                            <PrivateRouteMyPVR path="/pvrcaselawCase" component={CaseFormPagePVRCASELAW} />
                            {/* CPVO PVRCASELAW - END */}

                            {/* CPVO TLO - START */}
                            <PrivateRouteMyPVR path="/tlo" component={HomePage} />
                            <PrivateRouteMyPVR path="/tloAdministrator" component={AdministratorPage} />
                            <PrivateRouteMyPVR path="/tloMyApplicationsMyReports" component={MyRegistersPage} />
                            <PrivateRouteMyPVR path="/tloMyApplicationsMyInvoices" component={MyInvoicesPage} />
                            <PrivateRouteMyPVR
                                path="/tloMyContractDesignationAgreement"
                                component={DesignationAgreementPage}
                            />
                            <PrivateRouteMyPVR
                                path="/tloMyContractScopeOfEntrustment"
                                component={ScopeOfEntrustmentPage}
                            />
                            <PrivateRouteMyPVR
                                path="/tloMyContractConsultModifySpecies"
                                component={ConsultModifySpeciesDataPage}
                            />
                            <PrivateRouteMyPVR
                                path="/tloMyContractReportingDeadlines"
                                component={ReportingDeadlinesPage}
                            />
                            <PrivateRouteMyPVR
                                path="/tloMyContractRequestNewEntrustment"
                                component={RequestNewEntrustmentPage}
                            />
                            <PrivateRouteMyPVR
                                path="/tloMyContractWithdrawAnEntrustment"
                                component={WithdrawAnEntrustmentPage}
                            />
                            <PrivateRouteMyPVR
                                path="/tloMyContractNewSpeciesProcedure"
                                component={NewSpeciesProcedurePage}
                            />
                            <PrivateRouteMyPVR path="/tloMyRnDProjects" component={MyRnDProjectsPage} />
                            <PrivateRouteMyPVR path="/tloQasRequirements" component={QasRequirementsPage} />
                            <PrivateRouteMyPVR path="/tloDocumentsVademecum" component={AdminVademecumPage} />
                            <PrivateRouteMyPVR path="/tloDocumentsTemplates" component={TemplatesPage} />
                            <PrivateRouteMyPVR
                                path="/tloDocumentsGeneralInformation"
                                component={GeneralInformationPage}
                            />
                            <PrivateRouteMyPVR path="/tloTqSearch" component={TqSearchPage} />
                            <PrivateRouteMyPVR path="/candidateVarieties" component={TqSearchCandidateVarietiesPage} />
                            <PrivateRouteMyPVR path="/tloCpvoBox" component={CpvoBoxPage} />
                            <PrivateRouteMyPVR
                                path="/tloMyApplicationsMyFruitSubmissions"
                                component={MyFruitSubmissionsPage}
                            />
                            {/* CPVO TLO - END */}
                            <PublicRouteMyPVR path="/varietyFinderTermsOfAPIUse" component={VFTermsOfAPIUse} />
                            <Route
                                path={`/varieties/:countryid-:publicationextraid-:publicationtype-:varietyid`}
                                component={VFVarieties}
                                strict={true}
                            />
                            <PublicRouteMyPVR path="/classes" component={VFUPOVClasses} />
                            <PublicRouteMyPVR path="/myalerts" component={VFMyAlerts} />
                            <PublicRouteMyPVR path="/mytests" component={VFMyTests} />
                            <PublicRouteMyPVR path="/registers" component={VFRegisters} />
                            <PublicRouteMyPVR path="/varieties" component={VFSearchVarieties} />
                            <PublicRouteMyPVR path="/species" component={VFSpecies} />
                            <PublicRouteMyPVR path="/statistics" component={VFStatistics} />
                            <PublicRouteMyPVR path="/jurisprudence" component={VFJurisprudence} />
                            <PublicRouteMyPVR path="/contributions" component={VFContributorsPortal} />
                            <PublicRouteMyPVR path="/requestAPIAccess" component={VFRequestAPIAccess} />
                            <PublicRouteMyPVR path="/denominations" component={VFTestDenominations} />
                            <PrivateRouteMyPVR path="/VDCCaseMessage" component={VDCCaseMessage} />
                            <PrivateRouteMyPVR path="/VDCCases" component={VDCCases} />
                            <PrivateRouteMyPVR path="/VDCJurisprudence" component={VDCCaseJurisprudence} />
                            <PublicRouteMyPVR path="/" component={isLogged() ? Welcome : AuthenticationLogin} />
                        </Switch>
                    </Router>
                </DebugRouter>
            </>
        );
    }
}
