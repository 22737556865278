import React, {useEffect, useState} from 'react';
import AdminIcon from '../../../AdminIcon/AdminIcon';
import {Button} from '../../../../../../componentsFormV2';
import {FileSearchFilterCriteriaAreaProps} from './Props/FileSearchFilterCriteriaAreaProps';
import {FormFooter} from '../../../../../../componentsLayout';
import TextInput from '~components/TextInput';
import styles from './FileSearchFilterCriteriaArea.module.scss';

export default function FileSearchFilterCriteriaArea(props: FileSearchFilterCriteriaAreaProps) {
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        setSearchTerm(props.criteria.searchTerm);
    }, [props.criteria.searchTerm]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            props.updateCriteriaValue({searchTerm});
        }, 600);
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [searchTerm]);

    const onSearchTermChange = ({target: {value: searchTerm}}: React.ChangeEvent<HTMLInputElement>) =>
        setSearchTerm(searchTerm);

    return (
        <>
            <div className={styles.mainFieldContainer}>
                <TextInput
                    placeholder={`Please type file name or document code`}
                    value={searchTerm}
                    onChange={onSearchTermChange}
                    double={true}
                    outsideLabel={'File Search Term'}
                    outsideLabelWidth={160}
                />
                {props.showAdminIcon && (
                    <div className={styles.adminIconArea}>
                        <AdminIcon />
                    </div>
                )}
                <div style={{clear: 'both'}}>{}</div>
            </div>
            <FormFooter>
                <Button clickAction={() => props.resetCriteria()} variation={'secondary'}>{`Clear search`}</Button>
                <Button
                    clickAction={() => props.search(false)}
                    disabled={props.criteria.searchTerm.length === 0}
                >{`Search`}</Button>
            </FormFooter>
        </>
    );
}
