import React from 'react';
import styles from './ModalCountryListVersion2.module.scss';
import ModalCustomVersion2 from './ModalCustomVersion2';
import {Button} from '../componentsFormV2';
import {apiCountries} from '../commonApi/static';
import {ICountryItem} from '../types';

interface IProps {
    close: any;
}

interface IState {
    countries: ICountryItem[] | null;
}

export default class ModalCountryListVersion2 extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            countries: null,
        };
    }

    componentDidMount() {
        apiCountries().then(jsonData => {
            this.setState({countries: jsonData});
        });
    }

    close = () => {
        this.props.close && this.props.close();
    };

    render() {
        return (
            <ModalCustomVersion2
                close={this.close}
                header={`Countries codes`}
                body={
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>
                                    <a>{`Code `}</a>
                                </th>
                                <th>
                                    <a>{`Country `}</a>
                                </th>
                            </tr>
                        </thead>
                        <tbody id="tbody">
                            {this.state.countries
                                ? this.state.countries.map(country => (
                                      <tr key={country.COUNTRYID}>
                                          <td>{country.COUNTRYID}</td>
                                          <td>{country.COUNTRYNAME}</td>
                                      </tr>
                                  ))
                                : null}
                        </tbody>
                    </table>
                }
                footer={
                    <>
                        {' '}
                        <Button variation={'danger'} clickAction={this.close} icon={'close'}>{`Close`}</Button>
                    </>
                }
                size={'sm'}
            />
        );
    }
}
