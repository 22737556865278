import React from 'react';
import {Button} from '../../componentsFormV2';
import DateInput from '~components/DateInput';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationVarietyFinder from '../../shared/NavigationVarietyFinder';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import graphQLClientInstance from '../../utils/axiosGraphQLClient';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {trackPageView} from '../../utils';
import {getSearchJurisprudenceQuery} from './VFJurisprudenceService';
import CustomTable from '~components/CustomTable';
import ModalJurisprudenceCaseDataSheet from './modals/ModalJurisprudenceCaseDataSheet';
import XLSX from 'xlsx';

const ALL = 'All';

const RESULT_FIELDS_DEFAULT_VF_JURISPRUDENCE = ['denomination', 'specieid', 'article', 'decisiondate', 'outcomestatus'];

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

const DEFAULT_CRITERIA = {
    article: '',
    denomination: '',
    subHeading: '',
    outcome: '',
    decisionDateMin: '',
    decisionDateMax: '',
    decisionKwrd: '',
    pageNumber: 1,
    pageSize: 10,
    refresh: false,
};

interface IProps {
    intl: any;
    history: any;
}

interface IState {
    criteria: TDefaultCriteria;
    jurisprudenceSearchResults: any[];
    jurisprudenceSearchResultsCount: number;
    isModalJurisprudenceCaseDataSheetOpen: any;
    selectInputArticleList: any[];
    selectInputSubHeadingList: any[];
    loading: number;
    screenLoaded: boolean;
    timestamp: number;
}

class VFJurisprudence extends React.Component<IProps, IState> {
    criteriaOptionsInitial: any = [];

    constructor(props: IProps) {
        super(props);
        this.state = {
            criteria: DEFAULT_CRITERIA,
            jurisprudenceSearchResults: [],
            jurisprudenceSearchResultsCount: 0,
            isModalJurisprudenceCaseDataSheetOpen: false,
            selectInputArticleList: [],
            selectInputSubHeadingList: [],
            loading: 0,
            screenLoaded: false,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'Jurisprudence'});
        this.loadJSONs();
    }

    loadJSONs() {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                const values: any = {};
                const tablePageSize = this.state.criteria.pageSize;
                const tablePageIndex = this.state.criteria.pageNumber - 1;
                Promise.all([
                    graphQLClientInstance.post('/graphql', {
                        query: `
                                {
                                  jurisprudencepre {
                                    data {
                                      key
                                      subheadings
                                    } total
                                  }
                                }`,
                    }),
                    graphQLClientInstance.post(
                        '/graphql',
                        getSearchJurisprudenceQuery(values, tablePageSize, tablePageIndex)
                    ),
                ]).then(([JSONResponseCriteriaOptions, JSONResponseJurisprudence]: any) => {
                    let selectInputArticleList: any = [];
                    let selectInputSubHeadingList: any = [];
                    let jurisprudenceSearchResultsCount = 0;
                    let jurisprudenceSearchResults: any = [];
                    if (
                        JSONResponseCriteriaOptions &&
                        JSONResponseCriteriaOptions.data &&
                        JSONResponseCriteriaOptions.data.data &&
                        JSONResponseCriteriaOptions.data.data.jurisprudencepre &&
                        JSONResponseCriteriaOptions.data.data.jurisprudencepre.data
                    ) {
                        this.criteriaOptionsInitial = JSONResponseCriteriaOptions.data.data.jurisprudencepre.data;
                        selectInputArticleList = JSONResponseCriteriaOptions.data.data.jurisprudencepre.data.map(
                            ({key}: any) => ({
                                id: key,
                                value: key,
                            })
                        );
                        JSONResponseCriteriaOptions.data.data.jurisprudencepre.data.forEach(({subheadings}: any) =>
                            selectInputSubHeadingList.push(
                                ...subheadings.map((value: string) => ({
                                    id: value,
                                    value,
                                }))
                            )
                        );
                    }
                    if (
                        JSONResponseJurisprudence &&
                        JSONResponseJurisprudence.data &&
                        JSONResponseJurisprudence.data.data &&
                        JSONResponseJurisprudence.data.data.jurisprudenceSearch
                    ) {
                        jurisprudenceSearchResults = JSONResponseJurisprudence.data.data.jurisprudenceSearch.data;
                        jurisprudenceSearchResultsCount = JSONResponseJurisprudence.data.data.jurisprudenceSearch.total;
                    }

                    this.setState(prev => ({
                        jurisprudenceSearchResults,
                        jurisprudenceSearchResultsCount,
                        loading: prev.loading - 1,
                        screenLoaded: true,
                        selectInputArticleList,
                        selectInputSubHeadingList,
                        timestamp: Date.now(),
                    }));
                });
            }
        );
    }

    printExcel = () => {
        const resultGrid: any = [['Juris', 'Title', 'Species', 'Article', 'Decision Date', 'Outcome']];
        const values: any = {};
        this.state.criteria.article &&
            this.state.criteria.article !== ALL &&
            (values.article = this.state.criteria.article);
        this.state.criteria.subHeading &&
            this.state.criteria.subHeading !== ALL &&
            (values.subheading = this.state.criteria.subHeading);
        this.state.criteria.outcome &&
            this.state.criteria.outcome !== ALL &&
            (values.outcome = this.state.criteria.outcome);
        this.state.criteria.denomination && (values.denomination = this.state.criteria.denomination);
        this.state.criteria.decisionDateMin && (values.decisionDateMin = this.state.criteria.decisionDateMin);
        this.state.criteria.decisionDateMax && (values.decisionDateMax = this.state.criteria.decisionDateMax);
        this.state.criteria.decisionKwrd && (values.decisionKwrd = this.state.criteria.decisionKwrd);
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                graphQLClientInstance
                    .post('/graphql', getSearchJurisprudenceQuery(values, 1000, 0))
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.jurisprudenceSearch
                        ) {
                            JSONResponse.data.data.jurisprudenceSearch.data.forEach(
                                ({jurisprudenceid, title, specieid, article, decisiondate, outcomestatus}: any) => {
                                    resultGrid.push([
                                        jurisprudenceid,
                                        title,
                                        specieid,
                                        article,
                                        decisiondate,
                                        outcomestatus,
                                    ]);
                                }
                            );
                            const workSheet = XLSX.utils.aoa_to_sheet(resultGrid);
                            const workBook = XLSX.utils.book_new();
                            XLSX.utils.book_append_sheet(workBook, workSheet, 'VFJurisprudence');
                            XLSX.writeFile(workBook, `VFJurisprudence.xlsx`);
                            this.setState(prev => ({
                                loading: prev.loading - 1,
                            }));
                        }
                    });
            }
        );
    };

    closeModalJurisprudenceCaseDataSheet = () => this.setState({isModalJurisprudenceCaseDataSheetOpen: false});

    updateCriteriaValue = (criteriaValue: any, callback?: any, refresh?: any) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = criteriaValue.pageNumber && prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.criteria.pageSize !== criteriaValue.pageSize;
                return {...prev, criteria};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged) && refresh && this.search();
            }
        );
    };

    resetCriteria = () => this.setState({criteria: DEFAULT_CRITERIA});

    search() {
        let jurisprudenceSearchResultsCount = 0;
        let jurisprudenceSearchResults: any = [];
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                const values: any = {};
                this.state.criteria.article &&
                    this.state.criteria.article !== ALL &&
                    (values.article = this.state.criteria.article);
                this.state.criteria.subHeading &&
                    this.state.criteria.subHeading !== ALL &&
                    (values.subheading = this.state.criteria.subHeading);
                this.state.criteria.outcome &&
                    this.state.criteria.outcome !== ALL &&
                    (values.outcome = this.state.criteria.outcome);
                this.state.criteria.denomination && (values.denomination = this.state.criteria.denomination);
                this.state.criteria.decisionDateMin && (values.decisionDateMin = this.state.criteria.decisionDateMin);
                this.state.criteria.decisionDateMax && (values.decisionDateMax = this.state.criteria.decisionDateMax);
                this.state.criteria.decisionKwrd && (values.decisionKwrd = this.state.criteria.decisionKwrd);
                const tablePageSize = this.state.criteria.pageSize;
                const tablePageIndex = this.state.criteria.pageNumber - 1;
                graphQLClientInstance
                    .post('/graphql', getSearchJurisprudenceQuery(values, tablePageSize, tablePageIndex))
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.jurisprudenceSearch
                        ) {
                            jurisprudenceSearchResults = JSONResponse.data.data.jurisprudenceSearch.data;
                            jurisprudenceSearchResultsCount = JSONResponse.data.data.jurisprudenceSearch.total;
                            this.setState(prev => ({
                                loading: prev.loading - 1,
                                jurisprudenceSearchResults,
                                jurisprudenceSearchResultsCount,
                                timestamp: Date.now(),
                            }));
                        }
                    });
            }
        );
    }

    onHeaderSearchLinkClick = (searchListItem: any) =>
        this.props.history.push({
            pathname: '/varieties',
            state: {searchListItem},
        });

    onLinkButtonHelpClick = () => window.open('/help/en/Jurisprudence_help.html', '_blank');

    onLinkButtonDisclaimerClick = () => window.open('/help/en/Jurisprudence_disclaimer.html', '_blank');

    onSelectInputArticleChange = ({target: {value: article}}: React.ChangeEvent<HTMLSelectElement>) => {
        let selectInputSubHeadingList: any = [];
        if (this.criteriaOptionsInitial.map(({key}: any) => key).includes(article)) {
            let foundArticle = this.criteriaOptionsInitial.find(({key}: any) => key === article);
            if (foundArticle && foundArticle.subheadings) {
                foundArticle.subheadings.forEach((subHeading: string) =>
                    selectInputSubHeadingList.push({
                        id: subHeading,
                        value: subHeading,
                    })
                );
            }
        } else {
            this.criteriaOptionsInitial.forEach(({subheadings}: any) =>
                selectInputSubHeadingList.push(
                    ...subheadings.map((value: string) => ({
                        id: value,
                        value,
                    }))
                )
            );
        }
        this.setState(
            prev => ({...prev, selectInputSubHeadingList}),
            () => this.updateCriteriaValue({article, subHeading: ''})
        );
    };

    onSelectInputSubHeadingChange = ({target: {value: subHeading}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({subHeading});

    onSelectInputOutcomeChange = ({target: {value: outcome}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({outcome});

    onTextInputDenominationChange = ({target: {value: denomination}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({denomination});

    onTextInputKeywordInDecisionChange = ({target: {value: decisionKwrd}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({decisionKwrd});

    onDateInputDecisionDateFromChange = (decisionDateMin: string) => this.updateCriteriaValue({decisionDateMin});

    onDateInputDecisionDateToChange = (decisionDateMax: string) => this.updateCriteriaValue({decisionDateMax});

    onCustomTableRowDataDenominationClick = (rowObject: any) =>
        this.setState({isModalJurisprudenceCaseDataSheetOpen: rowObject});

    render() {
        return (
            <>
                {this.state.loading ? <HeaderLoading /> : null}
                {this.state.isModalJurisprudenceCaseDataSheetOpen ? (
                    <ModalJurisprudenceCaseDataSheet
                        close={this.closeModalJurisprudenceCaseDataSheet}
                        rowObject={this.state.isModalJurisprudenceCaseDataSheetOpen}
                    />
                ) : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Variety Finder | Jurisprudence`} hostApplication={'Variety Finder'} />
                <NavigationVarietyFinder onSearchLinkClick={this.onHeaderSearchLinkClick} />
                <MainWrapper>
                    <FormWrapper formInnerWidth={'lg'} paddingFormContent={'sm'}>
                        <SelectInput
                            label={`Article`}
                            value={this.state.criteria.article}
                            onChange={this.onSelectInputArticleChange}
                            list={this.state.selectInputArticleList}
                            size={'lg'}
                        />
                        <SelectInput
                            label={`SubHeading`}
                            value={this.state.criteria.subHeading}
                            onChange={this.onSelectInputSubHeadingChange}
                            list={this.state.selectInputSubHeadingList}
                            double={true}
                            size={'lg'}
                        />
                        <SelectInput
                            label={`Outcome`}
                            value={this.state.criteria.outcome}
                            onChange={this.onSelectInputOutcomeChange}
                            list={[
                                {id: 'All', value: 'All'},
                                {id: 'suitable', value: 'Suitable'},
                                {id: 'not suitable', value: 'Not Suitable'},
                            ]}
                            notAll={true}
                            size={'lg'}
                        />
                        <TextInput
                            label={`Denomination`}
                            value={this.state.criteria.denomination}
                            onChange={this.onTextInputDenominationChange}
                            double={true}
                            size={'lg'}
                        />
                        <DateInput
                            label={'Decision Date'}
                            changeDateFrom={this.onDateInputDecisionDateFromChange}
                            changeDateTo={this.onDateInputDecisionDateToChange}
                            inputValueFrom={this.state.criteria.decisionDateMin}
                            inputValueTo={this.state.criteria.decisionDateMax}
                            size={'lg'}
                            maxDateTo={new Date()}
                            maxDateFrom={new Date()}
                        />
                        <TextInput
                            label={`Keyword in decision`}
                            value={this.state.criteria.decisionKwrd}
                            onChange={this.onTextInputKeywordInDecisionChange}
                            double={true}
                            size={'lg'}
                        />
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            <Button
                                clickAction={this.onLinkButtonDisclaimerClick}
                                variation={'secondary'}
                            >{`Disclaimer`}</Button>
                            <Button clickAction={this.onLinkButtonHelpClick} variation={'secondary'}>{`Help`}</Button>
                            <Button clickAction={this.resetCriteria} variation={'secondary'}>{`Clear fields`}</Button>
                            <Button clickAction={() => this.search()}>{`Search`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    {this.state.screenLoaded ? (
                        <div style={{marginBottom: 10}}>
                            <CustomTable
                                count={this.state.jurisprudenceSearchResultsCount}
                                formatFunctions={{
                                    denomination: (denomination: string, rowObject: any) => (
                                        <div
                                            style={{color: '#255899', cursor: 'pointer', paddingLeft: 5}}
                                            onClick={() => this.onCustomTableRowDataDenominationClick(rowObject)}
                                        >
                                            {denomination}
                                        </div>
                                    ),
                                }}
                                id={'jurisprudenceid'}
                                intl={this.props.intl}
                                isNavigationButtonCompactOrDefaultViewEnabled={true}
                                pageNumber={this.state.criteria.pageNumber}
                                pageSize={this.state.criteria.pageSize}
                                pagination={true}
                                printExcel={this.printExcel}
                                resultFieldsDefault={RESULT_FIELDS_DEFAULT_VF_JURISPRUDENCE}
                                notSortable={RESULT_FIELDS_DEFAULT_VF_JURISPRUDENCE}
                                tableName={'varietyFinderJurisprudence'}
                                tableType={'OBJECT'}
                                tableSource={this.state.jurisprudenceSearchResults}
                                timestamp={this.state.timestamp}
                                updateCriteriaValue={this.updateCriteriaValue}
                                version={2}
                                hoverGrey={true}
                            />
                        </div>
                    ) : null}
                </MainWrapper>
                <Footer hostApplication={'Variety Finder'} />
            </>
        );
    }
}

export default withRouter(injectIntl(VFJurisprudence));
