import React from 'react';
import DateInput from '~components/DateInput';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormattedMessage, injectIntl} from 'react-intl';
import Help from '../common/Help';
import ReactDOMServer from 'react-dom/server';
import ResponseWrapper, {IWizardResponseWrapperProps, IWizardResponseWrapperState} from './ResponseWrapper';
import cx from 'classnames';
import {faPen, faPlus} from '@fortawesome/free-solid-svg-icons';
import {getErrorMessageResponseMandatoryField, isValidDate} from './utils';
import {htmlToText} from '../../../utils';
import styles from './ResponseDate.module.scss';
import {IGroupQuestionResponseLogic} from '../../../types';

interface IState extends IWizardResponseWrapperState {
    showInformativeLabel: boolean;
    isValidDate: boolean;
}

class ResponseDate extends ResponseWrapper<IWizardResponseWrapperProps, IState> {
    constructor(props: IWizardResponseWrapperProps) {
        super(props);
        this.state = {
            ...this.state,
            showInformativeLabel: false,
            isValidDate: true,
        };
    }

    componentDidMount() {
        this.checkAndUpdateIfCurrentResponseIsDisabledDueToLogicDisabledOfOtherResponse();
        if (this.getResponseAnswer()) {
            this.setState({
                isValidDate: isValidDate(this.getResponseAnswer()),
            });
        }
    }

    componentDidUpdate(prevProps: IWizardResponseWrapperProps, prevState: IState) {
        if (this.props.screenData.hash !== prevProps.screenData.hash) {
            this.checkAndUpdateIfCurrentResponseIsDisabledDueToLogicDisabledOfOtherResponse();
        }
    }

    hasLogicShowInformativeLabel = () => {
        const {logics} = this.props.response || {};
        return (logics || []).find(logic => (logic.logicParam || '').includes('showlabel:true'));
    };

    getLogicShowInformativeLabelMonths = () => {
        const {logics} = this.props.response || {};
        const showLabelLogic: any = (logics || []).find(logic => (logic.logicParam || '').includes('showlabel:true'));
        let months = 12;
        if (showLabelLogic && showLabelLogic?.logicParam?.includes('months:')) {
            months = parseInt(showLabelLogic?.logicParam?.split('months:').pop().split(';')[0]);
        }
        return months;
    };

    isSelectedDateEarlierThanInformativeLabelYears = (inputValueFrom: any) => {
        let selectedDateObject = new Date(
            inputValueFrom.split('/').pop(),
            parseInt(inputValueFrom.split('/')[1]) - 1,
            inputValueFrom.split('/')[0]
        );
        const subtractMonths = (date: Date, months: number) => {
            date.setMonth(date.getMonth() - months);
            return date;
        };
        let informativeLabelMinimumAllowedDateObject = subtractMonths(
            new Date(),
            this.getLogicShowInformativeLabelMonths()
        );
        return selectedDateObject.getTime() < informativeLabelMinimumAllowedDateObject.getTime();
    };

    onDateInputChange = (inputValueFrom: string) => {
        const stateUpdate: any = {
            isValidDate: inputValueFrom ? isValidDate(inputValueFrom) : true,
        };
        if (this.hasLogicShowInformativeLabel()) {
            stateUpdate.showInformativeLabel =
                inputValueFrom &&
                this.isSelectedDateEarlierThanInformativeLabelYears(inputValueFrom) &&
                this.getCopyOfResponsesClient()[this.getResponseKey()] !== inputValueFrom;
        }
        this.setState(stateUpdate);
        this.updateCurrentResponseAnswer(inputValueFrom);
    };

    onButtonInformativeLabelCloseClick = () => this.setState({showInformativeLabel: false});

    getMaxDate = (logics: IGroupQuestionResponseLogic[]) => {
        let simpleMaxDate = null;
        if (logics && logics.length > 0) {
            let {logicParam} = logics[logics.length - 1] || {};
            if ((logicParam || '').includes('maxdate:')) {
                let numOfMaxDate: any = ((logicParam || '').split('maxdate:').pop() || '').split(';')[0];
                if (!isNaN(numOfMaxDate)) {
                    let date = new Date();
                    date.setDate(date.getDate() + parseInt(numOfMaxDate));
                    simpleMaxDate = date;
                }
            }
        }
        return simpleMaxDate;
    };

    renderRemedyIcon = () => {
        const remedyType = this.getRemedyType();
        return (
            <div
                className={cx(
                    styles.remedyIconWrap,
                    styles[this.props.question.type],
                    remedyType ? styles[remedyType] : null
                )}
                onClick={this.onButtonAddResponseToRemedyClick}
                id={`REMEDY_${this.getResponseKey()}`}
            >
                <FontAwesomeIcon
                    icon={(this.checkIfRemedyResponseExists() ? faPen : faPlus) as any}
                    color={'white'}
                    width={16}
                    height={10}
                />
            </div>
        );
    };

    renderPDFVersion = () => {
        const {messageRemark} = this.props.response || {};
        const inputValueFromAnswer = this.getResponseAnswer() || null;
        if ((window as any).elements) {
            let element: any = {
                type: 'RESPONSE_DATE',
                label: htmlToText(ReactDOMServer.renderToStaticMarkup(this.getLabelWithHTML())),
                order: this.getResponseOrderForPDF(),
                questionType: this.props.question.type,
            };
            messageRemark && (element.remark = messageRemark);
            let elementExist = (window as any).elements.find((el: any) => el.order === element.order);
            if (!elementExist) {
                (window as any).elements.push(element);
            }
        }
        return (
            <>
                {super.render()}
                {this.props.isRemedyStarted && this.renderRemedyIcon()}
                <div className={styles.pdfResponseWrap}>
                    {this.props.isPDFVersionAndQuestionIsTableType ? null : (
                        <div className={styles.pdfResponseWrapLeft}>{this.getLabelWithHTML()}</div>
                    )}
                    <div className={cx(styles.pdfResponseWrapRight, !!inputValueFromAnswer && styles.hasPDFValue)}>
                        {inputValueFromAnswer}
                    </div>
                </div>
            </>
        );
    };

    render() {
        const {messageRemark, logics} = this.props.response || {};
        const simpleMaxDate = this.getMaxDate(logics) || null;
        const inputValueFromAnswer = this.getResponseAnswer() || null;
        const outsideLabelWithHTML = this.getLabelWithHTML();
        const hasClickedOnSaveAndValidate = this.checkIfUserHasClickedOnSaveAndValidate();
        const hasMandatoryError =
            hasClickedOnSaveAndValidate && this.checkIfResponseHasMandatoryError(this.props.responseIteration);
        const mandatoryErrorMessage =
            hasClickedOnSaveAndValidate && hasMandatoryError
                ? getErrorMessageResponseMandatoryField({intl: this.props.intl})
                : '';
        const validationErrorMessage =
            hasClickedOnSaveAndValidate && !this.state.isValidDate
                ? this.props.intl?.formatMessage({id: 'online.dateShouldBeInValidFormat'})
                : '';

        if (this.props.PDFVersion) {
            return this.renderPDFVersion();
        }

        return (
            <>
                <>
                    <Help
                        message={this.props.response && this.props.response.messageHelp}
                        language={this.props.questionnaireLanguage}
                        className="responseHelp"
                    />
                    <DateInput
                        outsideLabelWithHTML={outsideLabelWithHTML}
                        inputValueFrom={inputValueFromAnswer}
                        simple={true}
                        changeDateFrom={this.onDateInputChange}
                        isTranslationMissing={this.checkIfTranslationIsMissing()}
                        remark={messageRemark}
                        isRemarkTranslationMissing={this.checkIfResponseRemarkTranslationIsMissing()}
                        oldBackOfficeStyles={true}
                        oldBackOfficeStylesError={hasMandatoryError || !this.state.isValidDate}
                        error={mandatoryErrorMessage || validationErrorMessage}
                        maxDateFrom={simpleMaxDate}
                        disabled={this.state.isCurrentResponseDisabledDueToLogicDisabledOfOtherResponse}
                    />
                    {!this.props.editable && this.state.showInformativeLabel && (
                        <div className={styles.informativeLabel}>
                            <FormattedMessage
                                id="online.formDraft.theLastFilingMustHaveTakenPlaceInTheLastXMonths"
                                values={{numberOfMonths: this.getLogicShowInformativeLabelMonths()}}
                            />
                        </div>
                    )}
                </>
            </>
        );
    }
}

export default injectIntl(ResponseDate);
