import React from 'react';
import {Button} from '../componentsFormV2';
import CustomTable from '~components/CustomTable';
import ModalCustomVersion2 from './ModalCustomVersion2';
import {INoteLine} from '../types';

interface IProps {
    close: any;
    intl?: any;
    loadedDocuments?: any;
    noteLines?: INoteLine[];
    title: string;
}

interface IState {}

export default class ModalNoteDetailsVersion2 extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            content: '',
            invalid: true,
        };
    }

    close = (reload: any) => this.props.close && this.props.close(reload);

    buttonEnabled = () => this.props.loadedDocuments && this.props.loadedDocuments.length > 0;

    render() {
        return (
            <React.Fragment>
                <ModalCustomVersion2
                    close={this.close}
                    header={this.props.title}
                    body={
                        this.props.noteLines && this.props.noteLines.length > 0 ? (
                            <CustomTable
                                tableName={'noteLines'}
                                tableType={'OBJECT'}
                                tableSource={this.props.noteLines}
                                resultFieldsDefault={[
                                    'lineNumber',
                                    'registerId',
                                    'breederReference',
                                    'denomination',
                                    'specieName',
                                    'feeStringtType',
                                    'amount',
                                    'statusString',
                                ]}
                                id={'notelineId'}
                                defaultOrder={'notelineId'}
                                intl={this.props.intl}
                                formatFunctions={{
                                    amount: (fieldData: any) => (
                                        <div style={{whiteSpace: 'nowrap'}}>{`${fieldData}.00 €`}</div>
                                    ),
                                }}
                            />
                        ) : null
                    }
                    footer={<Button clickAction={this.close} icon={'close'} variation={'danger'}>{`Close`}</Button>}
                />
            </React.Fragment>
        );
    }
}
