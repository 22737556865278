import React from 'react';
import {injectIntl} from 'react-intl';
import TextInput from '~components/TextInput';
import {faEye, faEyeSlash, faInfo} from '@fortawesome/free-solid-svg-icons';
import {withRouter} from 'react-router-dom';
import {apiDefinePassword} from './DefinePasswordService';
import {ModalAlertVersion2, ModalErrorVersion2} from '../../commonModals';
import {
    Error,
    Footer,
    FormFooter,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderLogo,
    HeaderRecommendedBrowsers,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import {Button} from '../../componentsFormV2';
import {trackPageView} from '../../utils';

interface IProps {
    intl: any;
    history: any;
}

interface IState {
    enableButton: boolean;
    password: string;
    passwordConfirm: string;
    loginUiError: any;
    login: string;
    errorTitle: string;
    errorMessage: string;
    hidePassword: boolean;
    criteria: any;
    passwordError: boolean;
    confirmPasswordError: boolean;
    loading: number;
    ok: boolean;
}

class DefinePassword extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = Object.assign(
            {},
            {
                enableButton: false,
                password: '',
                passwordConfirm: '',
                loginUiError: null,
                login: '',
                errorTitle: '',
                errorMessage: '',
                hidePassword: true,
                criteria: {},
                passwordError: false,
                confirmPasswordError: false,
                loading: 0,
                ok: false,
            }
        );
    }

    componentDidMount() {
        trackPageView({documentTitle: 'definePassword'});
        this.loadUrlParams();
        (self as any).setStateFunction = this.setStateFunction;
        (self as any).defaultState = this.state;
    }

    setStateFunction = (state: any) => this.setState(state);

    loadUrlParams = () => {
        const domainArray = document.location.href.split('definePassword?');
        if (domainArray.length > 1) {
            const criteria: any = {};
            const params: any = domainArray.pop();
            if (params.indexOf('PARAM=') === 0) {
                const paramsArray = params.split('=');
                paramsArray.shift();
                criteria['PARAM'] = paramsArray.join('=');
            }
            if (Object.keys(criteria).length > 0) {
                this.setState({criteria});
            }
        }
    };

    checkForm = () =>
        this.state.passwordError ||
        this.state.confirmPasswordError ||
        this.state.password === '' ||
        this.state.passwordConfirm === '';

    closeError = () => this.setState({errorMessage: ''});

    closeInfo = () => this.props.history.replace(`/login`);

    onPasswordChange = ({target: {value: password}}: React.ChangeEvent<HTMLInputElement>) => {
        const passwordValid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(.{6,})$/.test(password);
        const confirmPasswordValid = password === this.state.passwordConfirm;
        this.setState({password, passwordError: !passwordValid, confirmPasswordError: !confirmPasswordValid});
    };

    onPasswordConfirmChange = ({target: {value: passwordConfirm}}: React.ChangeEvent<HTMLInputElement>) => {
        const passwordValid = this.state.password === passwordConfirm;
        this.setState({passwordConfirm, confirmPasswordError: !passwordValid});
    };

    onPasswordVisibilityChange = () => this.setState(prev => ({hidePassword: !prev.hidePassword}));

    onConfirm = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiDefinePassword({token: this.state.criteria.PARAM, password: this.state.password})
                    .then((result: any) => {
                        if (result.errorMessage) {
                            this.setState({errorMessage: result.errorMessage});
                        } else {
                            this.setState({ok: true});
                        }
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );

    onReset = () =>
        this.setState({password: '', passwordConfirm: '', passwordError: false, confirmPasswordError: false});

    render() {
        return (
            <>
                {this.state.errorMessage ? (
                    <ModalErrorVersion2
                        title={'Define password error'}
                        message={this.state.errorMessage}
                        close={this.closeError}
                    />
                ) : null}
                {this.state.ok ? (
                    <ModalAlertVersion2
                        title={'Define password information'}
                        message={'Operation success! You will be redirected to the login page'}
                        close={this.closeInfo}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderCookies />
                <HeaderLogo />
                <HeaderTitleAndVersion title={`Define password`} />
                <HeaderRecommendedBrowsers />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <TextInput
                            type={this.state.hidePassword ? 'password' : 'text'}
                            double={true}
                            infoIcon={faInfo}
                            onChange={this.onPasswordChange}
                            value={this.state.password}
                            outsideLabel={'New password'}
                            outsideLabelWidth={160}
                            focus={true}
                            buttonIcon={this.state.hidePassword ? faEye : faEyeSlash}
                            buttonAction={this.onPasswordVisibilityChange}
                            error={
                                this.state.passwordError &&
                                'The password must contain at least one digit, one uppercase character and one lowercase character. Its length should be 6 characters minimum.'
                            }
                        />
                        <TextInput
                            type={this.state.hidePassword ? 'password' : 'text'}
                            double={true}
                            onChange={this.onPasswordConfirmChange}
                            value={this.state.passwordConfirm}
                            outsideLabel={'Confirm password'}
                            outsideLabelWidth={160}
                            buttonColor={'white'}
                            buttonIcon={'none'}
                            error={this.state.confirmPasswordError && 'Password does not match'}
                        />
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            {this.state.loginUiError && <Error>{this.state.loginUiError}</Error>}
                            <Button variation={'secondary'} clickAction={this.onReset}>{`Reset`}</Button>
                            <Button clickAction={this.onConfirm} disabled={this.checkForm()}>{`Confirm`}</Button>
                        </FormFooter>
                        <div style={{clear: 'both'}} />
                    </FormWrapper>
                </MainWrapper>
                <Footer sticky={true} />
            </>
        );
    }
}

export default withRouter(injectIntl(DefinePassword));
