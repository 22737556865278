import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';
import COUNTRIES_EU from './data/COUNTRIES_EU.json';

export function apiFileListLogs(folder: any) {
    return getFetch(`${API_SERVERLESS}/common/v3/cpvoLogsCPVO/e-demandes/${folder}`, true);
}

export function apiLoadCountriesEU(): Promise<{
    data: {COUNTRYID: string; COUNTRYNAME: string}[];
}> {
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve(COUNTRIES_EU);
        }, 10);
    });
}

export function apiCommonCheckApplicationNumbers({applicationNumbers}: {applicationNumbers: string[]}): Promise<{
    data: {applicationNumber: string; errors: string[]}[];
}> {
    return postFetch(`${API_SERVERLESS}/common/v3/checkApplicationNumbers`, true, {applicationNumbers});
}
