import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export interface I_RESPONSE_TEMPLATE {
    templateId: number;
    nameLabelId: number | null;
    nameLabelValueHTML: string | null;
    remarkLabelId: number | null;
    remarkLabelValueHTML: string | null;
    helpLabelId: number | null;
    helpLabelValueHTML: string | null;
    displayOrder: string | null;
    status: number;
    mappingCode: string | null;
    multi: number;
    mandatory: number;
    buttonAddLabelId: number | null;
    buttonAddLabelValueHTML: string | null;
    buttonDelLabelId: number | null;
    buttonDelLabelValueHTML: string | null;
    officeId?: number;
    jsonMapping: string | null;
    templateComment: string | null;
}

export function apiBackOfficeNewTemplate(JSONRequest: {
    displayOrder?: string;
    helpLabelId?: number | null;
    jsonMapping?: string | null;
    mandatory?: number;
    mappingCode?: string | null;
    multi?: number;
    nameLabelId?: number;
    remarkLabelId?: number | null;
    templateComment?: string | null;
    buttonAddLabelId?: number | null;
    buttonDelLabelId?: number | null;
}): Promise<{
    templateId: null;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v2/newTemplate`, true, JSONRequest);
}

export function apiBackOfficeSaveTemplate(JSONRequest: {
    templateId?: number;
    displayOrder?: string;
    helpLabelId?: number | null;
    jsonMapping?: string | null;
    mandatory?: number;
    mappingCode?: string | null;
    multi?: number;
    nameLabelId?: number;
    remarkLabelId?: number | null;
    templateComment?: string | null;
    buttonAddLabelId?: number | null;
    buttonDelLabelId?: number | null;
}): Promise<{data: string}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v2/saveTemplate`, true, JSONRequest);
}

export function apiBackOfficeGetTemplate(templateId: number): Promise<{
    data: {
        templates: I_RESPONSE_TEMPLATE[];
    };
}> {
    return getFetch(`${API_SERVERLESS}/backOffice/v2/templates?templateId=${templateId}&status=1,2,3`, true);
}
