import React from 'react';
import {injectIntl} from 'react-intl';
import TextInput from '~components/TextInput';
import SelectInput from '~components/SelectInput';
import Title from '~components/Title';
import DateInput from '~components/DateInput';
import TextLabelInput from '~components/TextLabelInput';
import InputLink from '~components/InputLink';
import Empty from '~components/Empty';
import {faChevronDown, faChevronRight, faInfo, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {apiPublicationCharpters, apiSpecies} from '~commonApi/static';
import {
    apiEndOfFiles,
    apiEndOfFilesDeleteRequest,
    apiEndOfFilesDownloadDraft,
    apiEndOfFilesDownloadSigned,
    apiEndOfFilesDraftRequests,
    apiEndOfFilesOnBehalfOfApplicant,
    apiEndOfFilesOnBehalfOfPR,
    apiEndOfFilesSignRequests,
} from './MyPVREndOfFilesService';
import styles from './MyPVREndOfFiles.module.scss';
import CustomTable from '~components/CustomTable';
import {FORMAT_DATE, formatDateEasy, reformatDateEasy} from '~components/FormatFunctions';
import {withRouter} from 'react-router-dom';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import {saveAs} from 'file-saver';
import ModalApplicationList from './ModalApplicationList';
import MyPVREndOfFilesTopCaption from './MyPVREndOfFilesTopCaption';
import RESULT_FIELDS_ALL from './data/RESULT_FIELDS_ALL.json';
import DEFAULT_RESULT_FIELDS from './data/DEFAULT_RESULT_FIELDS.json';
import getIcon from '~utils/icons';
import MyPVREndOfFilesActionButtons from './MyPVREndOfFilesActionButtons';
import {ModalAlertVersion2, ModalConfirmVersion2, ModalStatusInfoVersion2} from '../../commonModals';
import ModalEndOfFilesPreviousToSignStatusVersion2 from './ModalEndOfFilesPreviousToSignStatusVersion2';
import ModalEndOfFilesSignatorVersion2 from './ModalEndOfFilesSignatorVersion2';
import {sanitize} from '~utils';
import CryptoJS from 'crypto-js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getPreSignedURLFetchRequest} from '../../utils/requests';
import {MAX_UPLOAD_FILE_SIZE} from '../../utils/constants';
import {getExcelSignedURLFetchRequest} from '../../commonApi/download';
import NavigationMyPVR from '../../shared/NavigationMyPVR';
import {Button} from '../../componentsFormV2';
import {trackPageView} from '../../utils';

const ALL = 'All';
const advancedCriteria = [
    'grantNumber',
    'applicationStatus',
    'onlineAppNumber',
    'applicants',
    'applicationDateFrom',
    'applicationDateTo',
    'grantDateFrom',
    'grantDateTo',
    'clientName',
    'lastFeeStatus',
];

const statusOptions = [
    {id: 'A', value: 'Active application'},
    {id: 'G', value: 'Granted'},
];

const lastFeeStatusOptions = [
    {id: 'paid', value: 'Paid'},
    {id: 'invoiced', value: 'Invoiced'},
];

const endOfFileStatusOptions = [
    {id: 'draft', value: 'Draft'},
    {id: 'todayRequest', value: `Today's request`},
];

const DEFAULT_CRITERIA = {
    denomination: '',
    denominationFilter: 'equals',
    breedersReference: '',
    speciesName: '',
    speciesNameFilter: 'starts',
    speciesIds: '',
    applicationNumber: '',
    endOfFileStatus: ALL,
    grantNumber: '',
    onlineAppNumbers: '',
    applicationStatus: ALL,
    onlineAppNumber: '',
    applicants: '',
    applicationDateFrom: '',
    applicationDateTo: '',
    grantDateFrom: '',
    grantDateTo: '',
    clientName: '',
    lastFeeStatus: ALL,
    //
    pageNumber: 1,
    pageSize: 10,
    order: 'applicationNumber',
    reverse: false,
    refresh: false,
    excel: false,
    modalConfirmDeletion: null,
};

const DATEFORMAT_CRITERIA_KEYS = ['applicationDateFrom', 'applicationDateTo', 'grantDateFrom', 'grantDateTo'];

const CONFIRMATION_MESSAGES_LINE1 =
    'Your request will be sent to the Office. You can find it under "Today’s requests" from your dashboard until today midnight or make use of the search criteria "End of file status".';
const CONFIRMATION_MESSAGES_LINE2 = {
    G: 'A surrender takes effect as from the day following your request.',
    A: 'A withdrawal takes effect as from the day of your request.',
};
const CONFIRMATION_MESSAGES_LINE3 =
    'If you wish to cancel this request, you can only do it today before midnight by clicking on the action button of the bin at the end of the line of the file concerned:';
const CONFIRMATION_MESSAGES_LINE4 =
    'If the last annual fee invoiced is due, the Office will prepare a cancellation decision.';

const modalScreen = {
    APPLICATION_LIST: 'APPLICATION_LIST',
    STATUS_INFO: 'STATUS_INFO',
    APPLICATIONS_TO_SIGN: 'APPLICATIONS_TO_SIGN',
    SIGNATOR: 'SIGNATOR',
};

const DRAFT_INFO = (
    <div>
        <div>
            {
                'Your drafts of end of file requests will be kept in MyPVR and will only be sent to the CPVO after a confirmation by a click on the button "Sign and Send".'
            }
        </div>
        <div>{'You can retrieve your drafts from the dashboard.'}</div>
    </div>
);

const DEFAULT_COUNT = 0;

const FORM_TITLE = {G: 'My files to surrender', A: 'My files to withdraw'};

class MyPVREndOfFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: null,
            advancedOpened: false,
            errorTitle: '',
            errorMessage: '',
            modalScreen: null,
            modalInfo: null,
            loading: false,
            selectedSpecies: {},
            selectedApplicationNumbers: {},
            registers: null,
            timestamp: null,
            publicationChapterList: [],
            count: DEFAULT_COUNT,
            criteria: DEFAULT_CRITERIA,
            criteriaCount: 0,
            advancedCriteriaCount: 0,
            lastSearch: (this.lastSearchCriteria && this.lastSearchCriteria.dateTime) || null,
            clientRepresentant: true,
            gazetteIssueDisabled: true,
            speciesListByIdName: null,
            speciesNameHash: null,
            selectedApplications: {},
            onBehalfResults: null,
            documentList: [],
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'endOfFiles'});
        this.loadJSONs();
        this.loadUrlParams();
    }

    loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const criteria = Object.assign({}, this.initialCriteria);
            const params = domainArray.pop();
            params.split('&').forEach(param => {
                const paramElements = param.split('=');
                if (paramElements.length === 2) {
                    const key = paramElements[0];
                    criteria[key] = decodeURIComponent(paramElements[1]);
                }
            });
            if (Object.keys(criteria).length > 0) {
                const selectedSpecies = {};
                if (criteria.speciesIds && criteria.speciesIds !== '') {
                    criteria.speciesIds.split(',').forEach(speciesId => (selectedSpecies[speciesId] = false));
                }
                const selectedApplicationNumbers = {};
                if (criteria.applicationNumberIds && criteria.applicationNumberIds !== '') {
                    criteria.applicationNumberIds
                        .split(',')
                        .forEach(
                            applicationNumber => (selectedApplicationNumbers[applicationNumber] = applicationNumber)
                        );
                }
                DATEFORMAT_CRITERIA_KEYS.forEach(criteriaKey => {
                    if (/^[0-9]{4}(-([0-9]){2}){2}$/.test(criteria[criteriaKey])) {
                        criteria[criteriaKey] = formatDateEasy(criteria[criteriaKey]);
                    }
                });
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                this.setState(
                    Object.assign(
                        {},
                        {
                            criteria,
                            criteriaCount,
                            advancedCriteriaCount,
                            selectedSpecies,
                            selectedApplicationNumbers,
                        },
                        criteria.order && {defaultOrder: criteria.order},
                        criteria.reverse && {reverseOrder: criteria.reverse}
                    ),
                    () => this.search(false, true)
                );
            }
        }
    };

    loadJSONs = () => {
        apiPublicationCharpters().then(jsonResponse => {
            if (jsonResponse) {
                this.setState({
                    publicationChapterList: jsonResponse,
                });
            }
        });

        apiSpecies()
            .then(jsonResponse => {
                if (jsonResponse) {
                    const speciesNameHash = {};
                    (jsonResponse || []).forEach(speciesElement => {
                        if (!speciesNameHash[speciesElement.NAME]) {
                            speciesNameHash[speciesElement.NAME] = [];
                        }
                        speciesNameHash[speciesElement.NAME].push(speciesElement.ID);
                    });
                    const speciesListByIdName = Object.keys(speciesNameHash).map(speciesName => ({
                        ID: speciesNameHash[speciesName].join(','),
                        NAME: speciesName,
                    }));
                    const selectedSpecies = {};
                    const selectedSpeciesKeyArray = Object.keys(this.state.selectedSpecies || {});
                    speciesListByIdName
                        .filter(i => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                        .forEach(item => (selectedSpecies[item.ID] = item.NAME));
                    this.setState({speciesListByIdName, speciesNameHash, selectedSpecies, timestamp: Date.now()});
                }
            })
            .catch(error => {
                ERROR`FormLabelInputSpecies: ${error.message}`;
            });
    };

    showError = (title, message) => {
        this.setState({
            errorTitle: title,
            errorMessage: message,
        });
    };

    closeError = () => {
        this.setState({
            errorTitle: '',
            errorMessage: '',
        });
    };

    toggleAdvancedSearch = () => {
        this.setState(
            prevState => ({
                advancedOpened: !prevState.advancedOpened,
            }),
            () => {
                if (this.state.advancedOpened && this.setSelectedFunction && this.state.criteria.applicants) {
                    this.setSelectedFunction(this.state.criteria.applicants.split(','));
                }
            }
        );
    };

    showModalStatusInfo = () => this.setState({modalScreen: modalScreen.STATUS_INFO});
    showModalScreenApplicationList = () => this.setState({modalScreen: modalScreen.APPLICATION_LIST});
    showModalApplicationsToSign = () => {
        const applicantsHash = {};
        Object.values(this.state.selectedApplications).forEach(
            application => (applicantsHash[application.applicants.join('\r')] = true)
        );
        if (Object.keys(applicantsHash).length > 1) {
            this.setState({
                modalInfo:
                    'You cannot file ends file request for different applicants at the same time. Please select applications with the same applicants.',
            });
        } else if (Object.keys(this.state.selectedApplications).length > 0) {
            const selectedApplicationFirstRow = Object.values(this.state.selectedApplications)[0];
            if (selectedApplicationFirstRow.authorizedToSign === 1) {
                this.setState({modalScreen: modalScreen.APPLICATIONS_TO_SIGN});
            } else {
                this.setState({loading: true, modalScreen: null}, () => {
                    (selectedApplicationFirstRow.role === 1
                        ? apiEndOfFilesOnBehalfOfApplicant
                        : apiEndOfFilesOnBehalfOfPR)(selectedApplicationFirstRow.applicationNumber)
                        .then(response => {
                            response.data &&
                                this.setState({modalScreen: modalScreen.SIGNATOR, onBehalfResults: response.data});
                        })
                        .then(() => this.setState({loading: false}));
                });
            }
        }
    };
    closeModal = () => this.setState({modalScreen: null, documentList: []});
    closeModalInfo = () => this.setState({modalInfo: null});
    closeModalConfirm = () => this.setState({modalConfirmDeletion: null});

    parseCriteria = criteria => {
        const parsedCriteria = {};
        Object.keys(criteria).map(key => {
            if (DATEFORMAT_CRITERIA_KEYS.indexOf(key) !== -1 && criteria[key] !== '') {
                const date = criteria[key];
                parsedCriteria[key] = reformatDateEasy(date);
            } else if (key === 'denominationFilter' && !criteria['denomination']) {
                criteria[key] = DEFAULT_CRITERIA[key];
            } else if (key === 'speciesNameFilter' && !criteria['speciesName']) {
                criteria[key] = DEFAULT_CRITERIA[key];
            } else {
                parsedCriteria[key] = criteria[key];
            }
        });
        return parsedCriteria;
    };

    search = (refresh, urlLoad) => {
        this.setState(
            prev => ({
                loading: true,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                !urlLoad && this.buildParams(parsedCriteria);
                apiEndOfFiles(parsedCriteria, DEFAULT_CRITERIA)
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.endOfFileRequests) {
                            if (!refresh) {
                                this.RegisterHash = {};
                            }
                            (jsonResponse.data.endOfFileRequests || []).forEach(
                                register => (this.RegisterHash[register.applicationNumber] = register)
                            );
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        registers: jsonResponse.data.endOfFileRequests,
                                        timestamp: Date.now(),
                                    },
                                    jsonResponse.data.count && {count: jsonResponse.data.count},
                                    !refresh && {selectedApplications: {}}
                                );
                            });
                        }
                    })
                    .catch(error => {
                        ERROR`Register search list error: ${error.message}`;
                    })
                    .then(() => this.setState({loading: false}));
            }
        );
    };

    printExcel = excelTranslations => {
        this.setState({loading: true}, () => {
            const parsedCriteria = Object.assign({}, this.parseCriteria(this.state.criteria), {excel: true});
            apiEndOfFiles(parsedCriteria, DEFAULT_CRITERIA, excelTranslations)
                .then(jsonResponse => {
                    if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                        getExcelSignedURLFetchRequest(jsonResponse.data.token).then(response => {
                            if (response && response.signedUrl) {
                                const {signedUrl} = response;
                                getPreSignedURLFetchRequest(signedUrl)
                                    .then(response => response.blob())
                                    .then(responseBlob => {
                                        saveAs(responseBlob, `MyFilesToTerminate.xlsx`);
                                    });
                            }
                        });
                    }
                })
                .catch(error => LOG`error downloading: ${error}`)
                .then(() => this.setState({loading: false}));
        });
    };

    buildParams = parsedCriteria => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(i => parsedCriteria[i] !== DEFAULT_CRITERIA[i] && i !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key]}`);
        this.props.history.replace(`/endOfFiles${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`);
    };

    countCriteria = (criteria, advanced) => {
        let count = 0;
        (advanced ? advancedCriteria : Object.keys(criteria)).map(key => {
            if (criteria[key] && criteria[key] !== DEFAULT_CRITERIA[key] && criteria[key] !== '') count++;
        });
        return count;
    };

    resetCriteria = () => {
        this.setState(prev => ({
            registers: null,
            selectedSpecies: {},
            selectedApplicationNumbers: {},
            criteria: Object.assign(
                {},
                DEFAULT_CRITERIA,
                prev.criteria.pageSize && {pageSize: prev.criteria.pageSize},
                prev.criteria.applicationStatus && {applicationStatus: prev.criteria.applicationStatus}
            ),
            criteriaCount: 0,
            advancedCriteriaCount: 0,
            gazetteIssueDisabled: true,
        }));
    };

    updateCriteriaValue = (criteriaValue, callback, refresh) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                // DEBUG`new criteria: ${{...criteria}}`;
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                pageNumberChanged = criteriaValue.pageNumber && prev.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse && prev.reverse !== criteriaValue.reverse;
                return {criteria, criteriaCount, advancedCriteriaCount};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    getRowClass = record =>
        record.endOfFileStatus === 'draft'
            ? styles.warning
            : record.endOfFileStatus === 'todayRequest'
            ? styles.success
            : null;

    confirmDeletion = applicationNumber => this.setState({modalConfirmDeletion: applicationNumber});

    deleteRequest = () => {
        const applicationNumber = this.state.modalConfirmDeletion;
        this.setState({loading: true, modalConfirmDeletion: null}, () => {
            apiEndOfFilesDeleteRequest({applicationNumber}).then(response => {
                response.data === 'OK' && this.setState({loading: false}, () => this.search(true));
            });
        });
    };

    draftRequests = () => {
        this.setState({loading: true, modalConfirmDraft: null}, () => {
            apiEndOfFilesDraftRequests({applicationNumbers: Object.keys(this.state.selectedApplications)})
                .then(response => {
                    response.data === 'OK' && this.setState({modalInfo: DRAFT_INFO}, () => this.search(true));
                })
                .then(() => this.setState({loading: false}));
        });
    };

    downloadDraft = applicationNumber => {
        this.setState({loading: true}, () => {
            apiEndOfFilesDownloadDraft(applicationNumber).then(response => {
                if (response.error === 404) {
                    this.setState({
                        errorTitle: '404 - Document not found',
                        errorMessage: 'This document is not available for download',
                    });
                } else {
                    this.setState({loading: false}, () => {
                        if (response && response.signedUrl) {
                            const {signedUrl} = response;
                            getPreSignedURLFetchRequest(signedUrl)
                                .then(response => response.blob())
                                .then(responseBlob => {
                                    saveAs(responseBlob, `Request draft ${applicationNumber}.pdf`);
                                });
                        }
                    });
                }
            });
        });
    };

    signRequests = () => {
        this.setState({loading: true, modalScreen: null}, () => {
            apiEndOfFilesSignRequests(
                Object.assign(
                    {},
                    {
                        applications: Object.values(this.state.selectedApplications || {})
                            .filter(
                                application => this.getApplicationNumberMessageAndStatus(application).error === false
                            )
                            .map(application =>
                                Object.assign({}, application, this.getApplicationNumberMessageAndStatus(application))
                            ),
                    },
                    this.state.signOnBehalfOf && {
                        clientIdOnBehalf: this.state.signOnBehalfOf.clientId,
                        contactIdOnBehalf: this.state.signOnBehalfOf.contactId,
                    }
                ),
                this.state.documentList
            )
                .then(response => {
                    response.data === 'OK' &&
                        this.setState(
                            prev => ({
                                modalInfo: (
                                    <div>
                                        <div>{CONFIRMATION_MESSAGES_LINE1}</div>
                                        <div>{CONFIRMATION_MESSAGES_LINE2[prev.criteria.applicationStatus]}</div>
                                        <div>
                                            <div style={{display: 'inline-block'}}>{CONFIRMATION_MESSAGES_LINE3}</div>
                                            <div style={{display: 'inline-block'}}>
                                                <FontAwesomeIcon icon={faTrashAlt} color={'green'} />
                                            </div>
                                        </div>
                                        {prev.criteria.applicationStatus === 'G' && (
                                            <div>{CONFIRMATION_MESSAGES_LINE4}</div>
                                        )}
                                    </div>
                                ),
                            }),
                            () => this.search(true)
                        );
                })
                .then(() => this.setState({loading: false, documentList: []}));
        });
    };

    getApplicationNumberMessageAndStatus = application => {
        const messages = [];
        let valid = true;
        let error = false;
        let allowUpload = false;
        let messageType2 = false;
        let messageType4 = false;
        let messageType5 = false;
        if (application.ongoingCancellation === 1) {
            messages.push(this.props.intl.formatMessage({id: 'endOfFiles.message1'}));
            valid = false;
            error = true;
        } else {
            if (application.applicationStatus === 'G' && application.role === 2 && application.PRCredentialsOK === 0) {
                messages.push(
                    <React.Fragment>
                        <span>{this.props.intl.formatMessage({id: 'endOfFiles.message2a'})}</span>
                        <span style={{fontWeight: 800}}>
                            {this.props.intl.formatMessage({id: 'endOfFiles.message2b'})}
                        </span>
                        <span>{this.props.intl.formatMessage({id: 'endOfFiles.message2c'})}</span>
                    </React.Fragment>
                );
                allowUpload = true;
                messageType2 = true;
            }
            if (
                application.lastFeeStatus === 'invoiced' &&
                (application.afterAniversary === 1 || application.firstYear === 1)
            ) {
                messages.push(this.props.intl.formatMessage({id: 'endOfFiles.message3'}));
            }
            if (application.openDeedLevy === 1) {
                messages.push(this.props.intl.formatMessage({id: 'endOfFiles.message4'}));
                allowUpload = true;
                messageType4 = true;
            }
            if (application.chapter8) {
                messages.push(this.props.intl.formatMessage({id: 'endOfFiles.message5'}));
                allowUpload = true;
                messageType5 = true;
            }
        }
        return {messages, valid, error, allowUpload, messageType2, messageType4, messageType5};
    };

    downloadSigned = applicationNumber => {
        this.setState({loading: true}, () => {
            apiEndOfFilesDownloadSigned(applicationNumber).then(response => {
                if (response.error === 404) {
                    this.setState({
                        errorTitle: '404 - Document not found',
                        errorMessage: 'This document is not available for download',
                    });
                } else {
                    this.setState({loading: false}, () => {
                        if (response && response.signedUrl) {
                            const {signedUrl} = response;
                            getPreSignedURLFetchRequest(signedUrl)
                                .then(response => response.blob())
                                .then(responseBlob => {
                                    saveAs(responseBlob, `Request signed ${applicationNumber}.pdf`);
                                });
                        }
                    });
                }
            });
        });
    };

    checkFile = (file, event, documentType) => {
        LOG`file.type:${file.type}`;
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType.toLowerCase();
        const filename = sanitize(file.name);
        this.setState({error: null}, () => {
            if (
                fileTypeLowercase === 'jpeg' ||
                fileTypeLowercase === 'png' ||
                fileTypeLowercase === 'jpg' ||
                fileTypeLowercase === 'pdf'
            ) {
                if (file.size > MAX_UPLOAD_FILE_SIZE) {
                    this.setState({
                        error: 'The maximum document size for uploads is 10 MB. Please select a proper file.',
                    });
                } else {
                    const a = new FileReader();
                    a.readAsBinaryString(file);
                    a.onloadend = () => {
                        const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(a.result));
                        this.setState(
                            prev => {
                                const documentList = prev.documentList.slice(0);
                                documentList.push({
                                    index,
                                    file,
                                    filename,
                                    size: file.size / 1024,
                                    extension: fileTypeLowercase,
                                    documentType: documentType || null,
                                });
                                return {documentList, timestamp: Date.now()};
                            },
                            () => (event.target.value = null)
                        );
                    };
                }
            } else {
                this.setState({error: 'type not valid'}, () => (event.target.value = null));
            }
        });
    };

    deleteFile = index =>
        this.setState(prev => ({
            documentList: prev.documentList.filter(i => i.index !== index),
            timestamp: Date.now(),
        }));

    onContinueSignatory = contactId => {
        this.setState(prev => ({
            signOnBehalfOf: prev.onBehalfResults.filter(contact => contact.contactId === contactId)[0],
            modalScreen: modalScreen.APPLICATIONS_TO_SIGN,
        }));
    };

    onSelectedChange = applicationNumbers => {
        const selectedApplications = {};
        applicationNumbers.forEach(applicationNumber => {
            if (this.RegisterHash[applicationNumber]) {
                selectedApplications[applicationNumber] = this.RegisterHash[applicationNumber];
            }
        });
        this.setState({selectedApplications});
    };

    onApplicationStatusChange = ({target: {value: applicationStatus}}) => this.updateCriteriaValue({applicationStatus});
    onLastFeeStatusChange = ({target: {value: lastFeeStatus}}) => this.updateCriteriaValue({lastFeeStatus});
    onEndOfFileStatusChange = ({target: {value: endOfFileStatus}}) => this.updateCriteriaValue({endOfFileStatus});
    onPublicationChapterChange = ({target: {value: chapterId}}) => this.updateCriteriaValue({chapterId});
    onApplicationFromChange = applicationDateFrom => this.updateCriteriaValue({applicationDateFrom});
    onApplicationToChange = applicationDateTo => this.updateCriteriaValue({applicationDateTo});
    onGrantDateFromChange = grantDateFrom => this.updateCriteriaValue({grantDateFrom});
    onGrantDateToChange = grantDateTo => this.updateCriteriaValue({grantDateTo});
    onDenominationChange = ({target: {value: denomination}}) => this.updateCriteriaValue({denomination});
    onDenominationFilterChange = ({target: {value: denominationFilter}}) =>
        this.updateCriteriaValue({denominationFilter});
    onBreederReferenceChange = ({target: {value: breedersReference}}) => this.updateCriteriaValue({breedersReference});

    onSpeciesSelectionChange = selectedSpecies =>
        this.updateCriteriaValue({speciesIds: Object.keys(selectedSpecies).join(','), speciesName: ''}, () =>
            this.setState({selectedSpecies})
        );

    onSpeciesNameChange = speciesName => this.updateCriteriaValue({speciesName});
    onSpeciesNameFilterChange = ({target: {value: speciesNameFilter}}) => this.updateCriteriaValue({speciesNameFilter});

    onApplicationNumbersSelectionChange = selectedApplicationNumbers =>
        this.updateCriteriaValue({applicationNumberIds: Object.keys(selectedApplicationNumbers).join(',')}, () =>
            this.setState({selectedApplicationNumbers})
        );

    onApplicationNumberChange = applicationNumber =>
        this.updateCriteriaValue({applicationNumber: applicationNumber.substring(0, 8)});

    onGrantNumberChange = ({target: {value: grantNumber}}) => {
        if (!isNaN(grantNumber)) {
            this.updateCriteriaValue({grantNumber});
        }
    };

    onOnlineAppNumbersChange = ({target: {value: onlineAppNumber}}) => {
        if (/^[a-cA-C][0-9]{0,9}$/.test(onlineAppNumber) || onlineAppNumber === '') {
            onlineAppNumber = onlineAppNumber.substring(0, 10);
            this.updateCriteriaValue({onlineAppNumber});
        }
    };

    onClientChange = ({target: {value: clientName}}) => this.updateCriteriaValue({clientName});

    onImportList = list => {
        this.setState(prev => {
            const selectedApplicationNumbers = prev.selectedApplicationNumbers;
            list.forEach(applicationNumber => (selectedApplicationNumbers[applicationNumber] = applicationNumber));
            return {
                selectedApplicationNumbers,
                criteria: Object.assign({}, prev.criteria, {
                    applicationNumberIds: Object.keys(selectedApplicationNumbers).join(','),
                }),
            };
        });
    };

    onSelectType = (event, index) => {
        const documentType = event.target.value === 'NONE' ? null : event.target.value;
        this.setState(prev => ({
            documentList: prev.documentList
                .slice(0)
                .map(document => (document.index === index ? Object.assign({}, document, {documentType}) : document)),
        }));
    };

    render() {
        const actions = MyPVREndOfFilesActionButtons(this.props, this.confirmDeletion);

        const applicationStatusHash = {};
        const endOfFileStatusHash = {};
        Object.keys(this.state.selectedApplications || {}).forEach(applicationNumber => {
            if (this.RegisterHash[applicationNumber]) {
                const register = this.RegisterHash[applicationNumber];
                applicationStatusHash[register.applicationStatus] = true;
                endOfFileStatusHash[register.endOfFileStatus || 'ongoing'] = true;
            }
        });
        const signEnabled =
            Object.keys(applicationStatusHash).length === 1 &&
            Object.keys(this.state.selectedApplications).length > 0 &&
            !endOfFileStatusHash['todayRequest'];
        const draftEnabled =
            Object.keys(applicationStatusHash).length === 1 &&
            Object.keys(this.state.selectedApplications).length > 0 &&
            !endOfFileStatusHash['draft'];

        return (
            <>
                {this.state.modalInfo ? (
                    <ModalAlertVersion2
                        title={'General information'}
                        message={this.state.modalInfo}
                        close={this.closeModalInfo}
                    />
                ) : null}
                {this.state.modalConfirmDeletion ? (
                    <ModalConfirmVersion2
                        title={'Confirm deletion'}
                        message={'Are you sure you want to delete this request?'}
                        buttonName={'Yes'}
                        action={this.deleteRequest}
                        close={this.closeModalConfirm}
                    />
                ) : null}
                {this.state.modalScreen === modalScreen.STATUS_INFO ? (
                    <ModalStatusInfoVersion2 filter={['A', 'G']} close={this.closeModal} />
                ) : null}
                {this.state.modalScreen === modalScreen.APPLICATION_LIST ? (
                    <ModalApplicationList importList={this.onImportList} close={this.closeModal} />
                ) : null}
                {this.state.modalScreen === modalScreen.APPLICATIONS_TO_SIGN ? (
                    <ModalEndOfFilesPreviousToSignStatusVersion2
                        checkFile={this.checkFile}
                        documentList={this.state.documentList}
                        error={this.state.error}
                        timestamp={this.state.timestamp}
                        deleteFile={this.deleteFile}
                        signRequests={this.signRequests}
                        getApplicationNumberMessageAndStatus={this.getApplicationNumberMessageAndStatus}
                        selectedApplications={this.state.selectedApplications}
                        onSelectType={this.onSelectType}
                        intl={this.props.intl}
                        close={this.closeModal}
                    />
                ) : null}
                {this.state.modalScreen === modalScreen.SIGNATOR ? (
                    <ModalEndOfFilesSignatorVersion2
                        checkFile={this.checkFile}
                        documentList={this.state.documentList}
                        timestamp={this.state.timestamp}
                        deleteFile={this.deleteFile}
                        onContinueSignatory={this.onContinueSignatory}
                        onBehalfResults={this.state.onBehalfResults}
                        intl={this.props.intl}
                        close={this.closeModal}
                    />
                ) : null}
                {this.state.loading ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion
                    title={FORM_TITLE[this.state.criteria.applicationStatus] || 'My files to terminate'}
                />
                <NavigationMyPVR />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div className={styles.mainFieldContainer}>
                            <TextInput
                                filter={['equals', 'contains']}
                                currentFilter={this.state.criteria.denominationFilter}
                                onFilterChange={this.onDenominationFilterChange}
                                double={true}
                                infoIcon={faInfo}
                                onChange={this.onDenominationChange}
                                onEnter={this.search}
                                value={this.state.criteria.denomination}
                                popOverText={`The search will find all applications containing what is typed`}
                                outsideLabel={'Denomination'}
                                outsideLabelWidth={160}
                            />
                            <TextInput
                                filter={'Contains'}
                                double={true}
                                infoIcon={faInfo}
                                onChange={this.onBreederReferenceChange}
                                onEnter={this.search}
                                value={this.state.criteria.breedersReference}
                                popOverText={`The search will find all applications containing what is typed`}
                                outsideLabel={'Breeder’s reference'}
                                outsideLabelWidth={160}
                            />
                            <TextLabelInput
                                filter={['starts', 'contains']}
                                currentFilter={this.state.criteria.speciesNameFilter}
                                onFilterChange={this.onSpeciesNameFilterChange}
                                double={true}
                                onSelectionChange={this.onSpeciesSelectionChange}
                                onChange={this.onSpeciesNameChange}
                                onEnter={this.search}
                                value={this.state.criteria.speciesName}
                                selectedElements={this.state.selectedSpecies}
                                delay={300}
                                multiple={true}
                                outsideLabel={'Species'}
                                outsideLabelWidth={160}
                                listByIdName={this.state.speciesListByIdName}
                                nameHash={this.state.speciesNameHash}
                                popover={`The search will find all applications concerned by the specified species`}
                            />
                            <TextLabelInput
                                filter={'Starts with'}
                                double={true}
                                onSelectionChange={this.onApplicationNumbersSelectionChange}
                                onChange={this.onApplicationNumberChange}
                                onEnter={this.search}
                                value={this.state.criteria.applicationNumber}
                                selectedElements={this.state.selectedApplicationNumbers}
                                delay={300}
                                multiple={true}
                                popover={`The search will find all applications whose number starts with the entered terms`}
                                outsideLabel={'Application number(s)'}
                                outsideLabelWidth={160}
                            />
                            <div className={styles.AdvancedSearchLinkSpacer}>
                                <Empty width={160} oneLine={true} />
                            </div>
                            <InputLink
                                label={`Import application number list`}
                                clickAction={this.showModalScreenApplicationList}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <SelectInput
                                double={true}
                                value={this.state.criteria.endOfFileStatus}
                                onChange={this.onEndOfFileStatusChange}
                                list={endOfFileStatusOptions}
                                outsideLabel={'End of file status'}
                                outsideLabelWidth={160}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <div className={styles.AdvancedSearchLinkSpacer}>
                                <Empty width={160} oneLine={true} />
                            </div>
                            <InputLink
                                label={`Advanced search${
                                    this.state.advancedCriteriaCount !== 0
                                        ? ` (${this.state.advancedCriteriaCount})`
                                        : ''
                                } `}
                                icon={this.state.advancedOpened ? faChevronDown : faChevronRight}
                                clickAction={this.toggleAdvancedSearch}
                            />
                            <Empty oneLine={true} />
                        </div>
                        {this.state.advancedOpened ? (
                            <div>
                                <Title triple={true} full={true}>{`Application details`}</Title>
                                <TextInput
                                    filter={'Starts with'}
                                    label={`Grant number`}
                                    placeholder={`e.g. 3600`}
                                    infoIcon={faInfo}
                                    onChange={this.onGrantNumberChange}
                                    onEnter={this.search}
                                    value={this.state.criteria.grantNumber}
                                />
                                <DateInput
                                    label={`Application date from`}
                                    changeDateFrom={this.onApplicationFromChange}
                                    changeDateTo={this.onApplicationToChange}
                                    inputValueFrom={this.state.criteria.applicationDateFrom}
                                    inputValueTo={this.state.criteria.applicationDateTo}
                                />
                                <TextInput
                                    filter={'Starts with'}
                                    label={`Online app. number`}
                                    placeholder={`e.g. A2015 or A201500001`}
                                    infoIcon={faInfo}
                                    onChange={this.onOnlineAppNumbersChange}
                                    onEnter={this.search}
                                    value={this.state.criteria.onlineAppNumber}
                                />
                                <SelectInput
                                    label={`Application status`}
                                    value={this.state.criteria.applicationStatus}
                                    onChange={this.onApplicationStatusChange}
                                    list={statusOptions}
                                    buttonAction={this.showModalStatusInfo}
                                    buttonIcon={faInfo}
                                    disabled={true}
                                />
                                <DateInput
                                    label={`Grant date`}
                                    changeDateFrom={this.onGrantDateFromChange}
                                    changeDateTo={this.onGrantDateToChange}
                                    inputValueFrom={this.state.criteria.grantDateFrom}
                                    inputValueTo={this.state.criteria.grantDateTo}
                                />
                                <SelectInput
                                    label={`Last fee status`}
                                    value={this.state.criteria.lastFeeStatus}
                                    onChange={this.onLastFeeStatusChange}
                                    list={lastFeeStatusOptions}
                                />
                                <TextInput
                                    label={`Applicant(s)`}
                                    placeholder={`Please type part of the client name`}
                                    value={this.state.criteria.clientName}
                                    onChange={this.onClientChange}
                                />
                            </div>
                        ) : null}
                        <div style={{clear: 'both'}}>{}</div>
                        <FormFooter>
                            <Button clickAction={this.resetCriteria} variation={'secondary'}>{`Clear fields`}</Button>
                            <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                        </FormFooter>
                        <div style={{clear: 'both'}}>{}</div>
                    </FormWrapper>
                    <div style={{textAlign: 'center', marginBottom: 20}}>
                        <CustomTable
                            version={2}
                            loading={this.state.loading}
                            pageNumber={this.state.criteria.pageNumber}
                            selectable={true}
                            onSelectedChange={this.onSelectedChange}
                            selectedElements={Object.keys(this.state.selectedApplications || {}).length}
                            {...this.props}
                            tableName={'endOfFiles'}
                            tableType={'OBJECT'}
                            tableSource={this.state.registers}
                            timestamp={this.state.timestamp}
                            dataFilter={null}
                            id={'applicationNumber'}
                            pagination={true}
                            printExcel={this.printExcel}
                            resultFieldsAll={RESULT_FIELDS_ALL}
                            resultFieldsDefault={DEFAULT_RESULT_FIELDS}
                            intl={this.props.intl}
                            defaultOrder={this.state.criteria.order}
                            reverseOrder={this.state.criteria.reverse}
                            pageSize={this.state.criteria.pageSize}
                            filterFunctions={null}
                            count={this.state.count}
                            rowClass={this.getRowClass}
                            formatFunctions={{
                                applicants: applicants => {
                                    const applicantList = [];
                                    applicants.forEach(applicant => {
                                        applicantList.push(
                                            <p key={applicant} style={{margin: 0}} className="ng-scope">
                                                {applicant}
                                            </p>
                                        );
                                    });
                                    return applicantList;
                                },
                                grantingDate: FORMAT_DATE,
                                applicationStatus: fieldData => {
                                    switch (fieldData) {
                                        case 'A':
                                            return 'Active application';
                                        case 'G':
                                            return 'Granted';
                                        default:
                                            return fieldData;
                                    }
                                },
                                applicationDate: FORMAT_DATE,
                                expirationDate: FORMAT_DATE,
                            }}
                            headerPopup={{
                                applicationStatus: {
                                    description: 'Status explanations',
                                    handler: this.showModalStatusInfo,
                                },
                            }}
                            topCaption={
                                <MyPVREndOfFilesTopCaption
                                    signEnabled={signEnabled}
                                    draftEnabled={draftEnabled}
                                    showModalApplicationsToSign={this.showModalApplicationsToSign}
                                    draftRequests={this.draftRequests}
                                />
                            }
                            excelFormatFunctions={{
                                applicants: applicants => applicants.join('; '),
                            }}
                            updateCriteriaValue={this.updateCriteriaValue}
                            forehandColumn={application => {
                                return application.endOfFileStatus === 'draft' ||
                                    application.endOfFileStatus === 'todayRequest' ? (
                                    <img
                                        style={{width: 20, height: 20, cursor: 'pointer'}}
                                        alt={'Download'}
                                        src={getIcon(document.extension || 'pdf')}
                                        title="Click on this button to display your request"
                                        onClick={event => {
                                            event.stopPropagation();
                                            if (application.endOfFileStatus === 'draft') {
                                                this.downloadDraft(application.applicationNumber);
                                            }

                                            if (application.endOfFileStatus === 'todayRequest') {
                                                this.downloadSigned(application.applicationNumber);
                                            }
                                        }}
                                    />
                                ) : (
                                    <span>{}</span>
                                );
                            }}
                            actions={actions}
                        />
                    </div>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(withRouter(MyPVREndOfFiles));
