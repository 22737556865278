import React from 'react';
import Navigation from '../componentsLayout/Navigation';

function NavigationPublicSearch() {
    return (
        <Navigation
            hideLinkUserArea={true}
            showLinkHomeCPVO={true}
            links={[
                {to: '/publicSearch', title: 'Search the CPVO Register'},
                {
                    to: '/publication',
                    title: 'S2/S3 Publication',
                },
            ]}
        />
    );
}

export default NavigationPublicSearch;
