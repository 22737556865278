import React, {useEffect, useRef, useState} from 'react';
import {RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import {injectIntl, IntlShape} from 'react-intl';
import {saveAs} from 'file-saver';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {faEdit, faIdCard, faPlus, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {
    CaseElement,
    CaseMetaDataFetchData,
    CaseNamesFetchData,
    CasesListFetchData,
    CountriesFetchData,
    DecidingBodiesFetchData,
    DecisionNumbersFetchData,
    KeywordsFetchData,
    LanguagesFetchData,
    PartiesFetchData,
    SearchFiles,
} from '../../CommonInterfaces/CommonInterfaces';
import {
    apiCaseDelete,
    apiCaseNamesFetch,
    apiCasesListFetch,
    apiCountriesFetch,
    apiDecidingBodiesFetch,
    apiDecisionNumbersFetch,
    apiDownloadFile,
    apiFilesSearch,
    apiKeywordsFetch,
    apiLanguagesFetch,
    apiPartiesFetch,
    apiViewCaseFetch,
} from '../../CommonFunctions/CommonFunctions';
import en from '../../../../translations/en.json';
import CustomTable from '../../../../components/CustomTable';
import AdminIcon from '../../../TLO/CommonComponents/AdminIcon/AdminIcon';
import {getUserRoles} from '../../../TLO/CommonFunctions/CommonFunctions';
import ModalCaseDetails from '../../CommonComponents/ModalCaseDetails/ModalCaseDetails';
import ModalCaseDelete from '../../CommonComponents/ModalCaseDelete/ModalCaseDelete';
import SubMenuTabs from '../../../TLO/SubMenu/SubMenuTabs';
import {formatDate, formatDateEasy, reformatDateEasy} from '../../../../components/FormatFunctions';
import {getPreSignedURLFetchRequest} from '../../../../utils/requests';
import {getExcelSignedURLFetchRequest} from '../../../../commonApi/download';
import SearchCasesAdvancedFiltersCriteria from './Components/SearchCasesAdvancedFiltersCriteria/SearchCasesAdvancedFiltersCriteria';
import styles from './SearchCasesPageAdvancedPVRCASELAW.module.scss';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import NavigationPVRCaseLaw from '../../../../shared/NavigationPVRCaseLaw';
import {ModalErrorVersion2} from '../../../../commonModals';
import {History} from 'history';
import {trackPageView} from '../../../../utils';

const modalScreenTypes = {
    SAVE_CASE: 'SAVE_CASE',
    CONFIRM_DELETE: 'CONFIRM_DELETE',
    READ_DETAILS_CASE: 'READ_DETAILS_CASE',
    ERROR: 'ERROR',
    INFO: 'INFO',
};

const maxOpenSearchResults = 10000;

export interface SearchCasesAdvancedFiltersCriteriaData {
    originsId: string;
    originName: string;
    selectedOrigins: string;
    languagesId: string;
    languageName: string;
    selectedLanguages: string;
    keywordsId: string;
    keywordName: string;
    selectedKeywords: string;
    decisionDateFrom: string;
    decisionDateTo: string;
    caseId: string;
    caseName: string;
    caseNameFilter: string;
    selectedCases: string;
    decidingBodyId: string;
    decidingBody: string;
    decidingBodyFilter: string;
    selectedDecidingsBody: string;
    decisionNumberId: string;
    decisionNumber: string;
    decisionNumberFilter: string;
    selectedDecisionNumbers: string;
    partiesId: string;
    parties: string;
    partiesFilter: string;
    selectedParties: string;
    draft: boolean;
    fileName: string;
    fileNameFilter: string;
    caseIds: string;
    pageNumber: number;
    pageSize: number;
    order: string;
    excel: boolean;
    reverse: boolean;
    refresh: boolean;

    [key: string]: string | boolean | number | undefined;
}

const DEFAULT_CRITERIA: SearchCasesAdvancedFiltersCriteriaData = {
    originsId: '',
    originName: '',
    selectedOrigins: '',
    languagesId: '',
    languageName: '',
    selectedLanguages: '',
    keywordsId: '',
    keywordName: '',
    selectedKeywords: '',
    decisionDateFrom: '',
    decisionDateTo: '',
    caseId: '',
    caseName: '',
    caseNameFilter: 'starts',
    selectedCases: '',
    decidingBodyId: '',
    decidingBody: '',
    decidingBodyFilter: 'starts',
    selectedDecidingsBody: '',
    decisionNumberId: '',
    decisionNumber: '',
    decisionNumberFilter: 'starts',
    selectedDecisionNumbers: '',
    partiesId: '',
    parties: '',
    partiesFilter: 'starts',
    selectedParties: '',
    draft: false,
    fileName: '',
    fileNameFilter: 'all',
    caseIds: '',
    pageNumber: 1,
    pageSize: Number(localStorage.getItem('pageSizePvrcaselawCasesAdvancedList')) || 50,
    reverse: false,
    order: 'caseId',
    excel: false,
    refresh: false,
};

const DATEFORMAT_CRITERIA_KEYS = ['decisionDateFrom', 'decisionDateTo'];

const DEFAULT_COUNT = 0;

const DEFAULT_PAGE_SIZE = 50;

const DEFAULT_KEYWORDS_SIZE = 50;

interface SearchCasesPageAdvancedPVRCASELAWProps {
    intl: IntlShape;
    history: History;
}

const SearchCasesPageAdvancedPVRCASELAW = (props: SearchCasesPageAdvancedPVRCASELAWProps & RouteComponentProps) => {
    const firstLoad = useFirstRender();
    const userRoles = getUserRoles();

    const RESULT_FIELDS_DEFAULT =
        userRoles.indexOf('WLAW') !== -1
            ? [
                  'caseId',
                  'countryName',
                  'languageName',
                  'parties',
                  'decidingBody',
                  'decisionDate',
                  'decisionNumber',
                  'caseName',
                  'isOnline',
                  'keywords',
              ]
            : [
                  'countryName',
                  'languageName',
                  'parties',
                  'decidingBody',
                  'decisionDate',
                  'decisionNumber',
                  'caseName',
                  'keywords',
              ];
    const [loading, setLoading] = useState(false);
    const [initLoading, setInitLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [pvrcaselawCasesList, setPvrcaselawCasesList] = useState<CaseElement[]>([]);
    const [rowObjectForModal, setRowObjectForModal] = useState<CaseElement | null>(null);
    const [rowObjectForExtend, setRowObjectForExtend] = useState<CaseElement | null>(null);
    const [showExpandedRow, setShowExpandedRow] = useState<{[key: number]: boolean}>({});
    const [showRowNumber, setShowRowNumber] = useState(DEFAULT_PAGE_SIZE);
    const [criteria, setCriteria] = useState<SearchCasesAdvancedFiltersCriteriaData>(
        Object.assign({}, DEFAULT_CRITERIA)
    );
    const [refresh, setRefresh] = useState(true);
    const [count, setCount] = useState(DEFAULT_COUNT);
    const [timestamp, setTimestamp] = useState(0);
    const [, setDefaultOrder] = useState('caseId');
    const [, setReverseOrder] = useState(false);
    const [, setCriteriaCount] = useState(0);

    const [originsListByIdName, setOriginsListByIdName] = useState<{ID: string; NAME: string}[] | null>(null);
    const [originsNameHash, setOriginsNameHash] = useState<{[key: string]: string[]} | null>(null);
    const [selectedOrigins, setSelectedOrigins] = useState<{[key: string]: string}>({});
    const [languagesListByIdName, setLanguagesListByIdName] = useState<{ID: string; NAME: string}[] | null>(null);
    const [languagesNameHash, setLanguagesNameHash] = useState<{[key: string]: string[]} | null>(null);
    const [selectedLanguages, setSelectedLanguages] = useState<{[key: string]: string}>({});
    const [keywordsListByIdName, setKeywordsListByIdName] = useState<{ID: string; NAME: string}[] | null>(null);
    const [keywordsNameHash, setKeywordsNameHash] = useState<{[key: string]: string[]} | null>(null);
    const [selectedKeywords, setSelectedKeywords] = useState<{[key: string]: string}>({});
    const [casesListByIdName, setCasesListByIdName] = useState<{ID: string; NAME: string}[] | null>(null);
    const [casesNameHash, setCasesNameHash] = useState<{[key: string]: string[]} | null>(null);
    const [selectedCases, setSelectedCases] = useState<{[key: string]: string}>({});
    const [decidingsBodyListByIdName, setDecidingsBodyListByIdName] = useState<
        | {
              ID: string;
              NAME: string;
          }[]
        | null
    >(null);
    const [decidingsBodyNameHash, setDecidingsBodyNameHash] = useState<{[key: string]: string[]} | null>(null);
    const [selectedDecidingsBody, setSelectedDecidingsBody] = useState<{[key: string]: string}>({});
    const [decisionsNumberListByIdName, setDecisionsNumberListByIdName] = useState<
        | {
              ID: string;
              NAME: string;
          }[]
        | null
    >(null);
    const [decisionsNumberNameHash, setDecisionsNumberNameHash] = useState<{[key: string]: string[]} | null>(null);
    const [selectedDecisionNumbers, setSelectedDecisionNumbers] = useState<{[key: string]: string}>({});
    const [partiesListByIdNames, setPartiesListByIdNames] = useState<{ID: string; NAME: string}[] | null>(null);
    const [partiesHash, setpartiesHash] = useState<{[key: string]: string[]} | null>(null);
    const [selectedParties, setSelectedParties] = useState<{[key: string]: string}>({});
    const filesListByIdNames: {ID: string; NAME: string}[] | null = null;
    const filesNamesHash: {[key: string]: string[]} | null = null;
    const [caseDetails, setCaseDetails] = useState<CaseElement | null>(null);
    const [, setPageNumberForOpenSearch] = useState(0);

    const menuLinks = [
        {id: 'BASIC_SEARCH', value: 'Full Text Search', path: 'pvrcaselawSearchCasesBasicSearch'},
        {id: 'ADVANCED_SEARCH', value: 'Search by Field', path: 'pvrcaselawSearchCasesAdvancedSearch'},
    ];
    let history = useHistory();

    useEffect(() => {
        trackPageView({documentTitle: 'pvrcaselawSearchCasesAdvancedSearch'});
    }, []);

    function useFirstRender() {
        const firstRender = useRef(true);

        useEffect(() => {
            firstRender.current = false;
        }, []);

        return firstRender.current;
    }

    const loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const params = domainArray.pop();
            if (params) {
                params.split('&').forEach(param => {
                    const paramElements = param.split('=');
                    if (paramElements.length === 2) {
                        const key = paramElements[0];
                        criteria[key] = decodeURIComponent(paramElements[1]);
                    }
                });
                if (Object.keys(criteria).length > 0) {
                    DATEFORMAT_CRITERIA_KEYS.forEach(criteriaKey => {
                        const criteriaValue = criteria && criteria?.criteriaKey?.toString();
                        if (criteriaValue && /^\d{4}(-(\d){2}){2}$/.test(criteriaValue)) {
                            criteria[criteriaKey] = formatDateEasy(criteria[criteriaKey]);
                        }
                    });
                    setCriteria(Object.assign({}, criteria));
                    criteria.order && setDefaultOrder(criteria.order);
                    criteria.reverse && setReverseOrder(criteria.reverse);
                    setCriteriaCount(countCriteria(criteria));
                    search(false, true);
                }
            }
        }
    };

    const closeErrorModal = () => {
        setErrorMessage(null);
        setModalScreen(null);
    };

    const getOriginsAutocompletionList = (selectedOrigins: {[key: string]: string}) => {
        apiCountriesFetch()
            .then((jsonResponse: CountriesFetchData) => {
                const originsNameHash: {[key: string]: string[]} = {};
                ((jsonResponse && jsonResponse.data && jsonResponse.data.countries) || []).forEach(el => {
                    originsNameHash[el.countryName] = [];
                    if (!originsNameHash[el.countryName]) {
                        originsNameHash[el.countryName] = [];
                    }
                    originsNameHash[el.countryName].push(el.countryId);
                });
                const originsListByIdName = Object.keys(originsNameHash).map(el => ({
                    ID: originsNameHash[el].join(','),
                    NAME: el,
                }));
                const selectedOriginObj: {[key: string]: string} = {};
                const selectedOriginKeyArray = Object.keys(selectedOrigins || {});
                originsListByIdName
                    .filter(i => selectedOriginKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectedOriginObj[item.ID] = item.NAME));
                setOriginsNameHash(originsNameHash);
                setOriginsListByIdName(originsListByIdName);
                setSelectedOrigins(selectedOriginObj);
            })
            .catch(error => {
                setLoading(false);
                setErrorMessage(`Origin Autocompletion list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            });
    };

    const getLanguagesAutocompletionList = (selectedLanguages: {[key: string]: string}) => {
        apiLanguagesFetch()
            .then((jsonResponse: LanguagesFetchData) => {
                const languagesNameHash: {[key: string]: string[]} = {};
                ((jsonResponse && jsonResponse.data && jsonResponse.data.languages) || []).forEach(el => {
                    languagesNameHash[el.languageName] = [];
                    if (!languagesNameHash[el.languageName]) {
                        languagesNameHash[el.languageName] = [];
                    }
                    languagesNameHash[el.languageName].push(el.languageId);
                });
                const languagesListByIdName = Object.keys(languagesNameHash).map(el => ({
                    ID: languagesNameHash[el].join(','),
                    NAME: el,
                }));
                const selectedLanguageObj: {[key: string]: string} = {};
                const selectedLanguageKeyArray = Object.keys(selectedLanguages || {});
                languagesListByIdName
                    .filter(i => selectedLanguageKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectedLanguageObj[item.ID] = item.NAME));
                setLanguagesNameHash(languagesNameHash);
                setLanguagesListByIdName(languagesListByIdName);
                setSelectedLanguages(selectedLanguageObj);
            })
            .catch(error => {
                setLoading(false);
                setErrorMessage(`Languages Autocompletion list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            });
    };

    const getKeywordsAutocompletionList = (selectedKeywords: {[key: string]: string}) => {
        apiKeywordsFetch()
            .then((jsonResponse: KeywordsFetchData) => {
                const keywordsNameHash: {[key: string]: string[]} = {};
                ((jsonResponse && jsonResponse.data && jsonResponse.data.keywords) || []).forEach(el => {
                    keywordsNameHash[el.keyword] = [];
                    if (!keywordsNameHash[el.keyword]) {
                        keywordsNameHash[el.keyword] = [];
                    }
                    keywordsNameHash[el.keyword].push(el.keywordId);
                });
                const keywordsListByIdName = Object.keys(keywordsNameHash).map(el => ({
                    ID: keywordsNameHash[el].join(','),
                    NAME: el,
                }));
                const selectedKeywordObj: {[key: string]: string} = {};
                const selectedKeywordKeyArray = Object.keys(selectedKeywords || {});
                keywordsListByIdName
                    .filter(i => selectedKeywordKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectedKeywordObj[item.ID] = item.NAME));
                setKeywordsNameHash(keywordsNameHash);
                setKeywordsListByIdName(keywordsListByIdName);
                setSelectedKeywords(selectedKeywordObj);
            })
            .catch(error => {
                setLoading(false);
                setErrorMessage(`Keywords Autocompletion list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            });
    };

    const getCasesAutocompletionList = (selectedCases: {[key: string]: string}) => {
        apiCaseNamesFetch(criteria.caseName, criteria.caseNameFilter)
            .then((jsonResponse: CaseNamesFetchData) => {
                const casesNameHash: {[key: string]: string[]} = {};
                ((jsonResponse && jsonResponse.data && jsonResponse.data.caseNames) || []).forEach(el => {
                    casesNameHash[el.caseName] = [];
                    if (!casesNameHash[el.caseName]) {
                        casesNameHash[el.caseName] = [];
                    }
                    casesNameHash[el.caseName].push(el.caseName);
                });
                const casesListByIdName = Object.keys(casesNameHash).map(el => ({
                    ID: casesNameHash[el].join(','),
                    NAME: el,
                }));
                const selectedCaseObj: {[key: string]: string} = {};
                const selectedCaseKeyArray = Object.keys(selectedCases || {});
                casesListByIdName
                    .filter(i => selectedCaseKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectedCaseObj[item.ID] = item.NAME));
                setCasesNameHash(casesNameHash);
                setCasesListByIdName(casesListByIdName);
                setSelectedCases(selectedCaseObj);
            })
            .catch(error => {
                setLoading(false);
                setErrorMessage(`Case Names Autocompletion list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            });
    };

    const getDecidingBodiesAutocompletionList = (selectedDecidingsBody: {[key: string]: string}) => {
        apiDecidingBodiesFetch(criteria.decidingBody, criteria.decidingBodyFilter)
            .then((jsonResponse: DecidingBodiesFetchData) => {
                const decidingsBodyNameHash: {[key: string]: string[]} = {};
                ((jsonResponse && jsonResponse.data && jsonResponse.data.decidingBodies) || []).forEach(el => {
                    decidingsBodyNameHash[el.decidingBody] = [];
                    if (!decidingsBodyNameHash[el.decidingBody]) {
                        decidingsBodyNameHash[el.decidingBody] = [];
                    }
                    decidingsBodyNameHash[el.decidingBody].push(el.decidingBody);
                });
                const decidingsBodyListByIdName = Object.keys(decidingsBodyNameHash).map(el => ({
                    ID: decidingsBodyNameHash[el].join(','),
                    NAME: el,
                }));
                const selectedDecidingsBodyObj: {[key: string]: string} = {};
                const selectedDecidingsBodyKeyArray = Object.keys(selectedDecidingsBody || {});
                decidingsBodyListByIdName
                    .filter(i => selectedDecidingsBodyKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectedDecidingsBodyObj[item.ID] = item.NAME));
                setDecidingsBodyNameHash(decidingsBodyNameHash);
                setDecidingsBodyListByIdName(decidingsBodyListByIdName);
                setSelectedDecidingsBody(selectedDecidingsBodyObj);
            })
            .catch(error => {
                setLoading(false);
                setErrorMessage(`Deciding Bodies Autocompletion list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            });
    };

    const getDecisionNumbersAutocompletionList = (selectedDecisionNumbers: {[key: string]: string}) => {
        apiDecisionNumbersFetch(criteria.decisionNumber, criteria.decisionNumberFilter)
            .then((jsonResponse: DecisionNumbersFetchData) => {
                const decisionsNumberNameHash: {[key: string]: string[]} = {};
                ((jsonResponse && jsonResponse.data && jsonResponse.data.decisionNumbers) || []).forEach(el => {
                    decisionsNumberNameHash[el.decisionNumber] = [];
                    if (!decisionsNumberNameHash[el.decisionNumber]) {
                        decisionsNumberNameHash[el.decisionNumber] = [];
                    }
                    decisionsNumberNameHash[el.decisionNumber].push(el.decisionNumber);
                });
                const decisionsNumberListByIdName = Object.keys(decisionsNumberNameHash).map(el => ({
                    ID: decisionsNumberNameHash[el].join(','),
                    NAME: el,
                }));
                const selectedDecisionsNumberObj: {[key: string]: string} = {};
                const selectedDecisionsNumberKeyArray = Object.keys(selectedDecisionNumbers || {});
                decisionsNumberListByIdName
                    .filter(i => selectedDecisionsNumberKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectedDecisionsNumberObj[item.ID] = item.NAME));
                setDecisionsNumberNameHash(decisionsNumberNameHash);
                setDecisionsNumberListByIdName(decisionsNumberListByIdName);
                setSelectedDecisionNumbers(selectedDecisionsNumberObj);
            })
            .catch(error => {
                setLoading(false);
                setErrorMessage(`Decision Numbers Autocompletion list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            });
    };

    const getPartiesAutocompletionList = (selectedParties: {[key: string]: string}) => {
        apiPartiesFetch(criteria.parties, criteria.partiesFilter)
            .then((jsonResponse: PartiesFetchData) => {
                const partiesHash: {[key: string]: string[]} = {};
                ((jsonResponse && jsonResponse.data && jsonResponse.data.parties) || []).forEach(el => {
                    partiesHash[el.parties] = [];
                    if (!partiesHash[el.parties]) {
                        partiesHash[el.parties] = [];
                    }
                    partiesHash[el.parties].push(el.parties);
                });
                const partiesListByIdNames = Object.keys(partiesHash).map(el => ({
                    ID: partiesHash[el].join(','),
                    NAME: el,
                }));
                const selectediesObj: {[key: string]: string} = {};
                const selectedPartiesKeyArray = Object.keys(selectedParties || {});
                partiesListByIdNames
                    .filter(i => selectedPartiesKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectediesObj[item.ID] = item.NAME));
                setpartiesHash(partiesHash);
                setPartiesListByIdNames(partiesListByIdNames);
                setSelectedParties(selectediesObj);
            })
            .catch(error => {
                setLoading(false);
                setErrorMessage(`Parties Autocompletion list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            });
    };

    useEffect(() => {
        if (firstLoad) {
            window.scrollTo(0, 0);

            loadUrlParams();
            criteria.pageSize = Number(localStorage.getItem('pageSizepvrcaselawCasesList')) || 50;
            setCriteria(Object.assign({}, criteria));

            const selectedOrigins: {[key: string]: string} = {};
            if (criteria.originsId && criteria.originsIds !== '') {
                criteria.originsId.split(',').forEach(originsId => (selectedOrigins[originsId] = ''));
            }
            getOriginsAutocompletionList(selectedOrigins);

            const selectedLanguages: {[key: string]: string} = {};
            if (criteria.languagesId && criteria.languagesIds !== '') {
                criteria.languagesId.split(',').forEach(languagesId => (selectedLanguages[languagesId] = ''));
            }
            getLanguagesAutocompletionList(selectedLanguages);

            const selectedKeywords: {[key: string]: string} = {};
            if (criteria.keywordsId && criteria.keywordsIds !== '') {
                criteria.keywordsId.split(',').forEach(keywordsId => (selectedKeywords[keywordsId] = ''));
            }
            getKeywordsAutocompletionList(selectedKeywords);

            const selectedCases: {[key: string]: string} = {};
            if (criteria.caseId && criteria.caseIds !== '') {
                criteria.caseId.split(',').forEach(caseId => (selectedCases[caseId] = ''));
            }
            getCasesAutocompletionList(selectedCases);

            const selectedDecidingsBody: {[key: string]: string} = {};
            if (criteria.decidingBodyId && criteria.decidingBodyIds !== '') {
                criteria.decidingBodyId
                    .split(',')
                    .forEach(decidingBodyId => (selectedDecidingsBody[decidingBodyId] = ''));
            }
            getDecidingBodiesAutocompletionList(selectedDecidingsBody);

            const selectedDecisionNumbers: {[key: string]: string} = {};
            if (criteria.decisionNumberId && criteria.decisionNumberIds !== '') {
                criteria.decisionNumberId
                    .split(',')
                    .forEach(decisionNumberId => (selectedDecisionNumbers[decisionNumberId] = ''));
            }
            getDecisionNumbersAutocompletionList(selectedDecisionNumbers);

            const selectedParties: {[key: string]: string} = {};
            if (criteria.partiesId && criteria.partiesIds !== '') {
                criteria.partiesId.split(',').forEach(partiesId => (selectedParties[partiesId] = ''));
            }
            getPartiesAutocompletionList(selectedParties);
        }
    }, []);

    useEffect(() => {
        !firstLoad && refresh && search(true);
    }, [criteria.order, criteria.reverse, criteria.pageSize, criteria.pageNumber]);

    useEffect(() => {
        firstLoad && search(false, true);
    }, [criteria.order, criteria.reverse]);

    const updateSelectedOrigins = (selectedOrigins: {[key: string]: string}) => {
        setSelectedOrigins(selectedOrigins);
    };

    const updateSelectedLanguages = (selectedLanguages: {[key: string]: string}) => {
        setSelectedLanguages(selectedLanguages);
    };

    const updateSelectedKeywords = (selectedKeywords: {[key: string]: string}) => {
        setSelectedKeywords(selectedKeywords);
    };

    const updateSelectedCases = (selectedCases: {[key: string]: string}) => {
        setSelectedCases(selectedCases);
    };

    const updateSelectedDecidingsBody = (selectedDecidingsBody: {[key: string]: string}) => {
        setSelectedDecidingsBody(selectedDecidingsBody);
    };

    const updateSelectedDecisionNumbers = (selectedDecisionNumbers: {[key: string]: string}) => {
        setSelectedDecisionNumbers(selectedDecisionNumbers);
    };

    const updateSelectedParties = (selectedParties: {[key: string]: string}) => {
        setSelectedParties(selectedParties);
    };

    const countCriteria = (registersCriteria: SearchCasesAdvancedFiltersCriteriaData) => {
        let countNum = 0;
        Object.keys(registersCriteria).forEach(key => {
            if (
                registersCriteria[key] &&
                registersCriteria[key] !== DEFAULT_CRITERIA[key] &&
                registersCriteria[key] !== ''
            )
                countNum++;
        });
        return countNum;
    };

    const parseCriteria = (
        unParsedCriteria: SearchCasesAdvancedFiltersCriteriaData
    ): SearchCasesAdvancedFiltersCriteriaData => {
        const parsedCriteria: SearchCasesAdvancedFiltersCriteriaData = JSON.parse(JSON.stringify({}));
        Object.keys(unParsedCriteria).forEach(key => {
            if (DATEFORMAT_CRITERIA_KEYS.includes(key) && !unParsedCriteria[key]) {
                const date = unParsedCriteria[key];
                parsedCriteria[key] = reformatDateEasy(date);
            } else if (key === 'caseNameFilter' && !criteria['caseName'] && !criteria['caseId']) {
                unParsedCriteria[key] = DEFAULT_CRITERIA[key];
            } else if (key === 'decidingBodyFilter' && !criteria['decidingBody'] && !criteria['decidingBodyId']) {
                unParsedCriteria[key] = DEFAULT_CRITERIA[key];
            } else if (key === 'decisionNumberFilter' && !criteria['decisionNumber'] && !criteria['decisionNumberId']) {
                unParsedCriteria[key] = DEFAULT_CRITERIA[key];
            } else if (key === 'partiesFilter' && !criteria['parties'] && !criteria['partiesId']) {
                unParsedCriteria[key] = DEFAULT_CRITERIA[key];
            } else {
                parsedCriteria[key] = unParsedCriteria[key];
            }
        });

        return parsedCriteria;
    };

    const buildParams = (parsedCriteria: SearchCasesAdvancedFiltersCriteriaData) => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(key => parsedCriteria[key] !== DEFAULT_CRITERIA[key] && key !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key]}`);
        const joinedParamsText = `?${paramArray.join('&')}`;
        props.history.replace(
            `/pvrcaselawSearchCasesAdvancedSearch${(paramArray.length > 0 && joinedParamsText) || ''}`
        );
    };

    const getCasesList = (
        parsedCriteria: SearchCasesAdvancedFiltersCriteriaData,
        shouldRefresh: boolean,
        urlLoad = false,
        withoutPagination = false
    ) => {
        const newParsedCriteria = Object.assign({}, parsedCriteria, withoutPagination ? {pageNumber: 1} : {});
        apiCasesListFetch(newParsedCriteria, {})
            .then((jsonResponse: CasesListFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.cases) {
                    const newCriteria = Object.assign({}, criteria, {pageNumber: 1});
                    if (!shouldRefresh && !urlLoad) setCriteria(Object.assign({}, newCriteria));

                    setPvrcaselawCasesList(jsonResponse.data.cases);
                    setTimestamp(Date.now());
                    if (
                        jsonResponse.count &&
                        (criteria.fileName === '' ||
                            (criteria.fileName !== '' && hasAtLeastOneDefinedProperty(criteria)))
                    )
                        setCount(jsonResponse.count);
                }
            })
            .catch(error => {
                setErrorMessage(`Search Cases list fetch error: ${error.message}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => {
                setLoading(false);
                setInitLoading(false);
            });
    };

    const resetCriteria = () => {
        setLoading(true);

        setPvrcaselawCasesList([]);
        setCriteria(Object.assign({}, DEFAULT_CRITERIA));
        setCriteriaCount(0);

        setSelectedOrigins({});
        setSelectedLanguages({});
        setSelectedKeywords({});
        setSelectedCases({});
        setSelectedDecidingsBody({});
        setSelectedDecisionNumbers({});
        setSelectedParties({});

        const parsedCriteria = parseCriteria(DEFAULT_CRITERIA);
        buildParams(parsedCriteria);
        getCasesList(parsedCriteria, true, false);
    };

    const search = (shouldRefresh: boolean, urlLoad = false) => {
        setLoading(true);
        if (criteria.fileName !== '') {
            searchInFiles();
        } else {
            setCriteria(
                Object.assign({}, criteria, {refresh: !!shouldRefresh}, !shouldRefresh && !urlLoad && {pageNumber: 1})
            );
            const parsedCriteria = parseCriteria(criteria);
            buildParams(parsedCriteria);
            getCasesList(parsedCriteria, shouldRefresh, urlLoad);
        }
    };

    const resetFileSearch = () => {
        setLoading(true);
        setPvrcaselawCasesList([]);
        const newCriteria = Object.assign({}, DEFAULT_CRITERIA);
        setCriteria(newCriteria);
        const parsedCriteria = parseCriteria(newCriteria);
        buildParams(parsedCriteria);
        getCasesList(parsedCriteria, true, true);
    };

    const hasAtLeastOneDefinedProperty = (obj: SearchCasesAdvancedFiltersCriteriaData): boolean => {
        return (
            ('originsId' in obj && obj.originsId !== '') ||
            ('originName' in obj && obj.originName !== '') ||
            ('selectedOrigins' in obj && obj.selectedOrigins !== '') ||
            ('languagesId' in obj && obj.languagesId !== '') ||
            ('languageName' in obj && obj.languageName !== '') ||
            ('selectedLanguages' in obj && obj.selectedLanguages !== '') ||
            ('keywordsId' in obj && obj.keywordsId !== '') ||
            ('keywordName' in obj && obj.keywordName !== '') ||
            ('selectedKeywords' in obj && obj.selectedKeywords !== '') ||
            ('decisionDateFrom' in obj && obj.decisionDateFrom !== '') ||
            ('decisionDateTo' in obj && obj.decisionDateTo !== '') ||
            ('caseId' in obj && obj.caseId !== '') ||
            ('caseName' in obj && obj.caseName !== '') ||
            ('selectedCases' in obj && obj.selectedCases !== '') ||
            ('decidingBodyId' in obj && obj.decidingBodyId !== '') ||
            ('decidingBody' in obj && obj.decidingBody !== '') ||
            ('selectedDecidingsBody' in obj && obj.selectedDecidingsBody !== '') ||
            ('decisionNumberId' in obj && obj.decisionNumberId !== '') ||
            ('decisionNumber' in obj && obj.decisionNumber !== '') ||
            ('selectedDecisionNumbers' in obj && obj.selectedDecisionNumbers !== '') ||
            ('partiesId' in obj && obj.partiesId !== '') ||
            ('parties' in obj && obj.parties !== '') ||
            ('selectedParties' in obj && obj.selectedParties !== '')
        );
    };

    const searchInFiles = () => {
        setLoading(true);
        const newPageNumberForOpenSearch = (criteria.pageNumber - 1) * criteria.pageSize;
        setPageNumberForOpenSearch(newPageNumberForOpenSearch);
        const pageSizeForOpenSearch =
            criteria.fileName !== '' && hasAtLeastOneDefinedProperty(criteria)
                ? maxOpenSearchResults
                : criteria.pageSize;
        apiFilesSearch(criteria.fileName, criteria.fileNameFilter, newPageNumberForOpenSearch, pageSizeForOpenSearch)
            .then((jsonResponse: SearchFiles) => {
                // Search in returned files with rest of criterias like search button clicked
                if (jsonResponse && jsonResponse.searchResults && jsonResponse.searchResults.length > 0) {
                    const caseIds = jsonResponse.searchResults.map(res => res.caseId);
                    let criteriaAfterFileSearch = {} as SearchCasesAdvancedFiltersCriteriaData;
                    if (caseIds.length > 0) {
                        criteriaAfterFileSearch = Object.assign({}, criteria, {
                            fileName: criteria.fileName,
                            caseIds: caseIds.join(),
                        });
                    } else {
                        criteriaAfterFileSearch = Object.assign({}, criteria, {
                            fileName: criteria.fileName,
                        });
                    }
                    setCount(jsonResponse.searchResults[0].total);
                    setCriteria(criteriaAfterFileSearch);
                    const parsedCriteria = parseCriteria(criteriaAfterFileSearch);
                    buildParams(parsedCriteria);
                    if (caseIds.length > 0) {
                        getCasesList(parsedCriteria, true, false, true);
                    } else {
                        setPvrcaselawCasesList([]);
                        setLoading(false);
                    }
                } else {
                    setPvrcaselawCasesList([]);
                    setLoading(false);
                }
            })
            .catch(error => {
                setErrorMessage(`error search in files: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
                setLoading(false);
            });
    };

    const updateCriteriaValue = (
        // eslint-disable-next-line no-undef
        criteriaValue: Partial<SearchCasesAdvancedFiltersCriteriaData>,
        callback = () => {},
        shouldRefresh = true
    ) => {
        setRefresh(shouldRefresh);
        if (criteriaValue !== undefined) setCriteria({...criteria, ...criteriaValue});

        setCriteriaCount(countCriteria(criteria));
        callback && callback();
    };

    const closeModal = () => setModalScreen(null);

    const deleteCase = () => {
        setModalScreen(null);
        setLoading(true);
        if (rowObjectForModal !== null) {
            apiCaseDelete(rowObjectForModal.caseId)
                .then(() => {
                    setModalScreen(null);
                    resetCriteria();
                })
                .catch(error => {
                    setLoading(false);
                    setErrorMessage(`error deleting case: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                });
        }
    };

    const getTopCaptionElement = () =>
        userRoles.indexOf('WLAW') !== -1 ? (
            <div className={styles.topCaptionContainer}>
                <FormFooterButton color={buttonColor.blue} clickAction={openCasePageForAddition} icon={faPlus}>
                    {`New Case`}
                    <AdminIcon />
                </FormFooterButton>
            </div>
        ) : null;

    const goToEditOrCreateCasePage = (rowId?: number) => {
        const parsedCriteria = Object.assign({}, parseCriteria(criteria));
        buildParams(parsedCriteria);
        const paramsArray = document.location.href.split('?');
        const validParams = paramsArray.length > 1;
        paramsArray.shift();
        const params = validParams ? paramsArray.join('?') : '';
        const caseId = rowId || 0;
        if (caseId > 0) {
            history.push(`/pvrcaselawCase?caseId=${rowId}${(params && `&${params}`) || ''}`);
        } else {
            const joinedParamsText = `?${paramsArray.join('&')}`;
            history.push(`/pvrcaselawCase${(paramsArray.length > 0 && joinedParamsText) || ''}`);
        }
    };

    const openCasePageForAddition = () => goToEditOrCreateCasePage();

    const handlerModify = (rowId: number, rowObject: CaseElement) => {
        if (rowId > 0) goToEditOrCreateCasePage(rowId);
    };

    const handlerDelete = (rowId: number, rowObject: CaseElement) => {
        if (rowId > 0) {
            setRowObjectForModal(rowObject);
            setLoading(true);
            apiViewCaseFetch(rowObject.caseId)
                .then((jsonResponse: CaseMetaDataFetchData) => {
                    if (
                        jsonResponse &&
                        jsonResponse.data &&
                        jsonResponse.data.case &&
                        jsonResponse.data.case.length > 0
                    ) {
                        setCaseDetails(jsonResponse.data.case[0]);
                        setModalScreen(modalScreenTypes.CONFIRM_DELETE);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    setErrorMessage(`View Case Fetch error: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        }
    };

    const handlerDetails = (rowId: number, rowObject: CaseElement) => {
        if (rowId > 0) {
            setRowObjectForModal(rowObject);
            setLoading(true);
            apiViewCaseFetch(rowObject.caseId)
                .then((jsonResponse: CaseMetaDataFetchData) => {
                    if (
                        jsonResponse &&
                        jsonResponse.data &&
                        jsonResponse.data.case &&
                        jsonResponse.data.case.length > 0
                    ) {
                        setCaseDetails(jsonResponse.data.case[0]);
                        setModalScreen(modalScreenTypes.READ_DETAILS_CASE);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    setErrorMessage(`View Case Fetch error: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        }
    };

    const downloadFile = (uuid: string) => {
        setLoading(true);
        apiDownloadFile(uuid)
            .then(response => {
                if (response && response.signedUrl) {
                    const {signedUrl} = response;
                    getPreSignedURLFetchRequest(signedUrl)
                        .then(response => response.blob())
                        .then(responseBlob => {
                            if (uuid.includes('-')) {
                                const fileNameArray = uuid.split('_');
                                fileNameArray.splice(0, 1);
                                const fileName = fileNameArray.join('_');
                                saveAs(responseBlob, fileName);
                            } else {
                                saveAs(responseBlob, uuid);
                            }
                        });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading file: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const actions: any[] = [];
    if (userRoles.indexOf('WLAW') !== -1) {
        actions.push({
            title: `Modify`,
            icon: () => {
                return (
                    <a data-content={`Modify`}>
                        <FontAwesomeIcon icon={faEdit as IconDefinition} />
                    </a>
                );
            },
            handler: handlerModify,
        });
        actions.push({
            title: `Delete`,
            icon: () => {
                return (
                    <a data-content={`Delete`}>
                        <FontAwesomeIcon icon={faTrashAlt as IconDefinition} />
                    </a>
                );
            },
            handler: handlerDelete,
        });
    }
    actions.push({
        title: `Read Details`,
        icon: () => {
            return (
                <a data-content={`Read Details`}>
                    <FontAwesomeIcon icon={faIdCard as IconDefinition} />
                </a>
            );
        },
        handler: handlerDetails,
    });

    const onSetTextOpen = (value: boolean, caseId: number) =>
        pvrcaselawCasesList
            .filter(e => e.caseId === caseId)
            .map(item => (item.isKeywordsTextOpen = item.caseId === caseId ? value : false));

    const getIsOnline = (isOnline: string | undefined, rowObject: CaseElement | null) => {
        if (rowObject && isOnline) {
            if (isOnline === '1') {
                return 'online';
            } else {
                return 'draft';
            }
        } else {
            return '';
        }
    };

    const getSubstringOfKeywords = (keywords: string | undefined, rowObject: CaseElement | null) => {
        if (rowObject && keywords) {
            if (keywords.length > DEFAULT_KEYWORDS_SIZE) {
                if (rowObject?.isKeywordsTextOpen) {
                    return <div>{keywords}</div>;
                } else {
                    return (
                        <div>
                            {keywords.substring(0, DEFAULT_KEYWORDS_SIZE)} {' ...'}
                        </div>
                    );
                }
            } else {
                return keywords;
            }
        } else {
            return '';
        }
    };

    const getDataSheetFormSectionArea = (rowElement: CaseElement) => (
        <tr>
            <td>
                <b>{`Abstract: `}</b>
            </td>
            <td colSpan={13}>{rowElement?.abstractInput}</td>
        </tr>
    );

    const getExcelTranslations = () => {
        const excelTranslations: {[key: string]: string} = {};
        const searchCasesExcelKeys = Object.keys(en).filter(key => ~key.indexOf('excel.pvrcaselawCasesList.'));
        const searchCasesHeaderKeys = searchCasesExcelKeys.map(key => key.replace('excel.pvrcaselawCasesList.', ''));
        searchCasesHeaderKeys.forEach(headerKey => {
            excelTranslations[headerKey] = props.intl.formatMessage({
                id: `excel.pvrcaselawCasesList.${headerKey}`,
            });
        });

        return excelTranslations;
    };

    const printExcel = () => {
        setLoading(true);
        const excelTranslations = getExcelTranslations();
        const parsedCriteria = Object.assign({}, parseCriteria(criteria), {excel: true});
        apiCasesListFetch(parsedCriteria, excelTranslations)
            .then((jsonResponse: CasesListFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                    getExcelSignedURLFetchRequest(jsonResponse.data.token).then(response => {
                        if (response && response.signedUrl) {
                            const {signedUrl} = response;
                            getPreSignedURLFetchRequest(signedUrl)
                                .then(response => response.blob())
                                .then(responseBlob => {
                                    saveAs(responseBlob, `Export_Caselaw_${moment().format('DD/MM/YYYY')}.xlsx`);
                                });
                        }
                    });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getRowClass = (rowObject: CaseElement) => {
        const clickedRowIndex =
            rowObjectForExtend !== null &&
            pvrcaselawCasesList.findIndex(
                el => el.caseId === rowObjectForExtend.caseId && el.caseId === rowObject.caseId
            );
        const clickedRowIndexClass =
            rowObjectForExtend !== null && Number(clickedRowIndex) >= 0 ? Number(clickedRowIndex) + 1 : clickedRowIndex;
        const className =
            clickedRowIndex !== -1 && showExpandedRow[pvrcaselawCasesList.indexOf(rowObject)] === true
                ? clickedRowIndexClass + '-clickedRow'
                : '0-clickedRow';
        return styles[className];
    };

    const rowClick = (_caseId: number, rowObject: CaseElement) => {
        const tableRowNumber = document.querySelectorAll(
            "div[class^='SearchCasesPageAdvancedPVRCASELAW_searchCasesPage'] table tr[class^='CustomTable_rowData']"
        ).length;
        setShowRowNumber(tableRowNumber > DEFAULT_PAGE_SIZE ? tableRowNumber : DEFAULT_PAGE_SIZE);
        setRowObjectForExtend(rowObject);
        const dataFormSection: {[key: number]: boolean} = {};
        dataFormSection[pvrcaselawCasesList.indexOf(rowObject) % showRowNumber] =
            !showExpandedRow[pvrcaselawCasesList.indexOf(rowObject) % showRowNumber];
        setShowExpandedRow(dataFormSection);
        if (rowObject?.isKeywordsTextOpen) {
            onSetTextOpen(false, rowObject.caseId);
        } else {
            onSetTextOpen(true, rowObject.caseId);
        }
    };

    const getTableArea = () => {
        return pvrcaselawCasesList.length === 0 ? (
            <div style={{paddingTop: 20, textAlign: 'left'}}>
                <b style={{color: 'red', textAlign: 'left'}}>
                    <span className="ng-scope">{`No Search Cases available`}</span>
                </b>
            </div>
        ) : (
            <div style={{marginBottom: 20}}>
                <CustomTable
                    version={2}
                    {...props}
                    useExpandedRow={true}
                    forehandColumn={true}
                    expandedRow={(rowObject: CaseElement) =>
                        showExpandedRow[pvrcaselawCasesList.indexOf(rowObject) % showRowNumber] === true &&
                        getDataSheetFormSectionArea(rowObject)
                    }
                    showExpandedRow={showExpandedRow}
                    tableName={'pvrcaselawCasesList'}
                    tableType={'OBJECT'}
                    tableSource={pvrcaselawCasesList}
                    id={'caseId'}
                    resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                    intl={props.intl}
                    defaultOrder={criteria.order}
                    reverseOrder={criteria.reverse}
                    timestamp={timestamp}
                    count={count}
                    formatFunctions={{
                        decisionDate: (decisionDate: string, rowObject: CaseElement) =>
                            decisionDate && formatDate(decisionDate),
                        isOnline: (isOnline: string, rowObject: CaseElement) => getIsOnline(isOnline, rowObject),
                        keywords: (keywords: string, rowObject: CaseElement) =>
                            getSubstringOfKeywords(keywords, rowObject),
                    }}
                    actions={actions}
                    actionName={`Action`}
                    showAdminIcon={userRoles.indexOf('WLAW') !== -1}
                    rowClick={rowClick}
                    rowClass={getRowClass}
                    pageSize={criteria.pageSize}
                    pageNumber={criteria.pageNumber}
                    pagination={true}
                    updateCriteriaValue={(criteriaValue: SearchCasesAdvancedFiltersCriteriaData) =>
                        updateCriteriaValue(criteriaValue)
                    }
                    printExcel={(_excelTranslations: {[key: string]: string}) => printExcel()}
                />
            </div>
        );
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {modalScreen === modalScreenTypes.CONFIRM_DELETE && rowObjectForModal !== null && caseDetails !== null ? (
                <ModalCaseDelete
                    intl={props.intl}
                    caseElement={caseDetails}
                    delete={deleteCase}
                    downloadFile={downloadFile}
                    close={closeModal}
                />
            ) : null}
            {modalScreen === modalScreenTypes.READ_DETAILS_CASE &&
            rowObjectForModal !== null &&
            caseDetails !== null ? (
                <ModalCaseDetails
                    intl={props.intl}
                    caseElement={caseDetails}
                    downloadFile={downloadFile}
                    close={closeModal}
                />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`PVR Case Law`} />
            <NavigationPVRCaseLaw activeTitle={`Search Cases`} />
            <MainWrapper>
                <FormWrapper paddingFormContent={'sm'}>
                    <SubMenuTabs tabId={'ADVANCED_SEARCH'} loading={loading} menuLinks={menuLinks} />
                    <SearchCasesAdvancedFiltersCriteria
                        criteria={criteria}
                        originsListByIdName={originsListByIdName}
                        originsNameHash={originsNameHash}
                        selectedOrigins={selectedOrigins}
                        languagesListByIdName={languagesListByIdName}
                        languagesNameHash={languagesNameHash}
                        selectedLanguages={selectedLanguages}
                        keywordsListByIdName={keywordsListByIdName}
                        keywordsNameHash={keywordsNameHash}
                        selectedKeywords={selectedKeywords}
                        casesListByIdName={casesListByIdName}
                        casesNameHash={casesNameHash}
                        selectedCases={selectedCases}
                        decidingsBodyListByIdName={decidingsBodyListByIdName}
                        decidingsBodyNameHash={decidingsBodyNameHash}
                        selectedDecidingsBody={selectedDecidingsBody}
                        decisionsNumberListByIdName={decisionsNumberListByIdName}
                        decisionsNumberNameHash={decisionsNumberNameHash}
                        selectedDecisionNumbers={selectedDecisionNumbers}
                        partiesListByIdNames={partiesListByIdNames}
                        partiesHash={partiesHash}
                        selectedParties={selectedParties}
                        filesListByIdNames={filesListByIdNames}
                        filesNamesHash={filesNamesHash}
                        updateSelectedOrigins={updateSelectedOrigins}
                        updateSelectedLanguages={updateSelectedLanguages}
                        updateSelectedKeywords={updateSelectedKeywords}
                        updateSelectedCases={updateSelectedCases}
                        updateSelectedDecidingsBody={updateSelectedDecidingsBody}
                        updateSelectedDecisionNumbers={updateSelectedDecisionNumbers}
                        updateSelectedParties={updateSelectedParties}
                        updateCriteriaValue={updateCriteriaValue}
                        resetCriteria={resetCriteria}
                        search={search}
                        searchInFiles={searchInFiles}
                        resetFileSearch={resetFileSearch}
                    />
                </FormWrapper>
                {!initLoading ? (
                    <>
                        {getTopCaptionElement()}
                        <div style={{clear: 'both'}} />
                        <div className={styles.searchCasesPage}>{getTableArea()}</div>
                    </>
                ) : null}
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(SearchCasesPageAdvancedPVRCASELAW));
