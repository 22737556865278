import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/free-solid-svg-icons';

function renderButton(popupContent, handler, icon) {
    return {
        title: popupContent,
        icon: rowObject =>
            rowObject.valueHTML ? (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon} color={'green'} />
                </a>
            ) : (
                <div />
            ),
        handler,
    };
}

export default function ModalMergeLabelsVersion2ActionButtons(props, showModalPreview) {
    const buttons = [];
    buttons.push(renderButton('Preview', (labelId, rowObject) => showModalPreview(rowObject.valueHTML), faEye));
    return buttons;
}
