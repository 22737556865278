import {SELECT_INPUT_REGISTER_TYPE_OPTIONS} from './VFRegister.config';
import {COUNTRY_CODE_MAP_COUNTRY_NAME} from '../../utils/constants';

export const computeTableValueRegisterType = (registerTypeId: string) => {
    let listItem = SELECT_INPUT_REGISTER_TYPE_OPTIONS.find(listItem => listItem.id === registerTypeId.toLowerCase());
    if (listItem) {
        return `${listItem.value}`;
    }
    return `(${registerTypeId})`;
};

export const computeTableValueCountry = (countryId: string) => {
    if (COUNTRY_CODE_MAP_COUNTRY_NAME[countryId]) {
        return `${countryId} - ${COUNTRY_CODE_MAP_COUNTRY_NAME[countryId]}`;
    }
    return countryId;
};
