import React from 'react';
import CryptoJS from 'crypto-js';
import styles from './ModalCommunicationCenterCompose.module.scss';
import {faChevronDown, faChevronRight, faFlag, faPlus} from '@fortawesome/free-solid-svg-icons';
import TextLabelInput from '~components/TextLabelInput';
import Info from '~components/Info';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TextAreaInput from '~components/TextAreaInput';
import {getDecodedJWT, isRegisterIdValid, sanitize} from '~utils';
import CustomTable from '~components/CustomTable';
import getIcon from '~utils/icons';
import {saveAs} from 'file-saver';
import SelectInput from '~components/SelectInput';
import DragAndDrop from '~components/DragAndDrop';
import ModalCommunicationCenterComposeActions from './ModalCommunicationCenterComposeActions.js';
import Error from '~components/Error';
import {
    apiCommunicationCentreClients,
    apiCPVOColleagues,
    apiDocumentUpload,
    apiUploadGetFileInfo,
} from './ModalCommunicationCenterComposeService';
import InputLink from '~components/InputLink';
import BrowserInfo from '~shared/BrowserInfo';
import {getMandatoryAsterisk} from '~utils/format';
import jwtDecode from 'jwt-decode';
import {USER_SHORTKEY_TO_CONTACTID} from '../../CommunicationCentreInternal/CommunicationCentreInternal.utils';
import PROFILES_DIRECTORIES_SUBTYPES from '../../CommunicationCentreInternal/modals/DATA/PROFILES_DIRECTORIES_SUBTYPES.json';
import {MAX_UPLOAD_FILE_SIZE} from '../../../utils/constants';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';
import {HeaderLoading} from '../../../componentsLayout';

export const MODAL_COMPOSE_MESSAGE_ORIGIN_SCREENS = {
    ADMIN_PROCEEDINGS: 'adminProceedings',
};

export const COMMUNICATION_TYPES = [
    {id: 'none', value: 'Please select a communication type'},
    {id: 'ApplicationProcedure', value: 'Application procedure'},
    {id: 'Denominations', value: `Denominations`},
    {id: 'Financial', value: 'Financial'},
    {id: 'LegalProceedings', value: `Legal proceedings`},
    {id: 'ApplicationProcedure', value: 'MyPVR Correspondence'},
    {id: 'OtherMatters', value: `Other matters`},
    {id: 'TechnicalExamination', value: 'Technical examination'},
    {id: 'WithdrawalSurrender', value: `Withdrawal/Surrender documents`},
];

const SELECT_LIST_ITEM_ID_NOT_SELECTED = 'none';
const SELECT_LIST_VALUE = {
    DOCMAN_PROFILE_NOT_SELECTED: 'Please select a profile',
    DOCMAN_DIRECTORY_NOT_SELECTED: 'Please select a directory',
    DOCMAN_SUBTYPE_NOT_SELECTED: 'Please select a subtype',
};

const SELECT_LIST_ALL_DOCMAN_PROFILES = [
    {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_PROFILE_NOT_SELECTED},
    {id: 'ACCOUN', value: 'Accountancy'},
    {id: 'BOADOC', value: 'Board of appeal'},
    {id: 'GREEND', value: 'Green Files'},
    {id: 'HR', value: 'Human Resources'},
    {id: 'LEGALD', value: 'Legal'},
    {id: 'PRESIF', value: 'Presidency'},
    {id: 'PURCHA', value: 'Purchase'},
    {id: 'QAUDIT', value: 'Quality Audit'},
    {id: 'TECHMA', value: 'Technical matters'},
    {id: 'THPDOC', value: 'Third parties'},
];

const errorMessages = {
    UNKNOWN: `This is not a valid application number. The value must be in range 19950001 to ${new Date().getFullYear()}9999!`,
    FORBIDDEN: 'This application is not linked to your account',
    DUPLICATE: `Application number already in the list`,
    NOT_RECEIPT: 'The reception of your application has not been finalised yet.',
    ADD: 'Please include the application number using the button + or "Enter" keyboard button.',
};

const CPVO_THIRD_PARTY_ID = '1';

const arrayUniqueByKey = (array, uniqueKey = 'id') => [...new Map(array.map(item => [item[uniqueKey], item])).values()];

const getSelectedThirdPartyIdValue = client => {
    let postFix = 'Requestor';
    if (client.roles === '1') {
        postFix = 'Applicant';
    }
    if (client.roles === '2') {
        postFix = 'Representative';
    }
    return `${client.companyName} - ${client.thirdPartyId} - (${postFix})`;
};

const getSelectListOfDocmanProfiles = decodedJWT => {
    let {id: contactId} = decodedJWT || {};
    let selectList = [
        {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_PROFILE_NOT_SELECTED},
        ...SELECT_LIST_ALL_DOCMAN_PROFILES.filter(el => Object.keys(PROFILES_DIRECTORIES_SUBTYPES).includes(el.id)),
    ];
    if (parseInt(contactId) === USER_SHORTKEY_TO_CONTACTID.FB) {
        selectList = selectList.filter(listOption => listOption.id === 'BOADOC');
    } else {
        selectList = selectList.filter(listOption => listOption.id === 'GREEND');
    }
    return selectList;
};

export default class ModalCommunicationCenterCompose extends React.Component {
    clientList = [];
    clientHash = {};
    clientNameHash = {};

    constructor(props) {
        super(props);
        this.inputUploadRef = React.createRef();
        const selectedApplicationNumbers = {};
        ((this.props.message && this.props.message.greenFiles) || []).forEach(
            applicationNumber => (selectedApplicationNumbers[applicationNumber] = applicationNumber)
        );

        const authToken = sessionStorage.getItem('token-cc');
        if (authToken) {
            this.decodedJWT = jwtDecode(authToken);
        } else {
            this.decodedJWT = getDecodedJWT();
        }

        const selectedThirdPartyId = {};
        const readMode =
            this.props.message &&
            this.props.message.messageStatus === 'sent' &&
            this.props.message.addressees.indexOf(this.decodedJWT.thirdPartyId) !== -1;
        if (this.decodedJWT.thirdPartyId === CPVO_THIRD_PARTY_ID && !readMode) {
            if (this.props.message && this.props.message.thirdPartyIdTarget) {
                const client = this.clientHash[this.props.message.thirdPartyIdTarget];
                client &&
                    (selectedThirdPartyId[this.props.message.thirdPartyIdTarget] =
                        getSelectedThirdPartyIdValue(client));
            }
        }

        this.state = {
            selectValueProfile: SELECT_LIST_ITEM_ID_NOT_SELECTED,
            selectValueDirectory: SELECT_LIST_ITEM_ID_NOT_SELECTED,
            selectValueSubtypeOfDocument: SELECT_LIST_ITEM_ID_NOT_SELECTED,
            applicationNumber: '',
            selectedApplicationNumbers,
            communicationType: (this.props.message && this.props.message.communicationType) || 'none',
            selectedCPVOColleagues: {},
            subject:
                this.props.message && this.props.message.messageStatus === 'draft'
                    ? this.props.message.messageSubject
                    : '',
            body:
                this.props.message && this.props.message.messageStatus === 'draft'
                    ? this.props.message.messageBody
                    : '',
            errorType: null,
            documentList: [],
            informationOpened: true,
            loading: 0,
            selectedThirdPartyId,
            thirdPartyId: this.props.message && this.props.message.thirdPartyIdTarget,
            readModeCommunicationTypeValue: (props.message && props.message.communicationType) || '',
            showDataSheetReadModeTypeOfCommunicationSelectList: false,
            responseDocmanProfilesDirectoriesSubtypes: {},
            selectListDocmanProfiles: getSelectListOfDocmanProfiles(this.decodedJWT),
            selectListDocmanDirectories: [
                {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_DIRECTORY_NOT_SELECTED},
            ],
            selectListDocmanDocumentSubtypes: [
                {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_SUBTYPE_NOT_SELECTED},
            ],
        };
    }

    componentDidMount() {
        this.loadJSONs();
        if (this.state.selectListDocmanProfiles?.length === 1) {
            this.onSelectInputProfileChange({target: {value: this.state.selectListDocmanProfiles[0].id}});
        }
    }

    loadJSONs = () => {
        if (this.decodedJWT.thirdPartyId === CPVO_THIRD_PARTY_ID) {
            Promise.all([this.loadCPVOColleagues()]);
        }
        const selectedApplicationNumbers = {};
        (this.props.initApplicationNumbers || []).forEach(el => (selectedApplicationNumbers[el] = el));
        this.setState({selectedApplicationNumbers}, () => this.updateClientList(selectedApplicationNumbers));
    };

    loadCPVOColleagues = () =>
        new Promise((resolve, reject) => {
            this.setState(
                prev => ({loading: ++prev.loading}),
                () => {
                    apiCPVOColleagues()
                        .then(jsonResponse => {
                            if (jsonResponse) {
                                this.ccList = jsonResponse.data;
                                const CPVOColleaguesNameHash = {};
                                (jsonResponse.data || []).forEach(colleagueElement => {
                                    if (!CPVOColleaguesNameHash[colleagueElement.name]) {
                                        CPVOColleaguesNameHash[colleagueElement.name] = [];
                                    }
                                    CPVOColleaguesNameHash[colleagueElement.name].push(colleagueElement.id);
                                });
                                const CPVOColleaguesListByIdName = Object.keys(CPVOColleaguesNameHash).map(
                                    colleagueName => ({
                                        ID: CPVOColleaguesNameHash[colleagueName].join(','),
                                        NAME: colleagueName,
                                    })
                                );
                                const selectedCPVOColleagues = {};
                                this.setState({
                                    CPVOColleaguesListByIdName,
                                    CPVOColleaguesNameHash,
                                    selectedCPVOColleagues,
                                });
                            }
                        })
                        .catch(error => {
                            ERROR`ModalCommunicationCenterCompose: ${error.message}`;
                            reject(error && error.message);
                        })
                        .finally(() =>
                            this.setState(
                                prev => ({loading: --prev.loading}),
                                () => resolve(true)
                            )
                        );
                }
            );
        });

    confirmSending = messageStatus => {
        if (this.state.selectValueProfile === SELECT_LIST_ITEM_ID_NOT_SELECTED) {
            this.setState({error: 'Please select a profile to continue'});
            return;
        }
        if (this.state.selectValueDirectory === SELECT_LIST_ITEM_ID_NOT_SELECTED) {
            this.setState({error: 'Please select a directory to continue'});
            return;
        }
        if (this.state.selectValueSubtypeOfDocument === SELECT_LIST_ITEM_ID_NOT_SELECTED) {
            this.setState({error: 'Please select a subtype of document to continue'});
            return;
        }
        if (!Object.keys(this.state.selectedThirdPartyId || {}).length) {
            this.setState({error: 'Addressees cannot be empty'});
            return;
        }
        if (Object.keys(this.state.selectedApplicationNumbers).length === 0) {
            this.setState({error: 'No application number linked'});
            return;
        }
        if (!this.state.subject) {
            this.setState({error: 'Subject cannot be empty'});
            return;
        }
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                const {selectedApplicationNumbers, subject, body, documentList} = this.state;
                const JSONRequest = {
                    CPVOColleagues: [],
                    addressees: [],
                    applicationNumbers: Object.keys(selectedApplicationNumbers),
                    messageBody: body,
                    docmanProfile: this.state.selectValueProfile,
                    docmanDirectory: this.state.selectValueDirectory,
                    docmanSubtypeOfDocument: this.state.selectValueSubtypeOfDocument,
                    draft: messageStatus === 'draft',
                    messageSubject: subject,
                };
                Object.keys(this.state.selectedThirdPartyId || {})
                    .map(el => parseInt(el))
                    .forEach(thirdPartyId => {
                        JSONRequest.addressees.push({
                            thirdPartyId,
                            role: (
                                (this.clientResults || []).find(
                                    clientResult => clientResult['thirdPartyId'] === thirdPartyId
                                ) || {}
                            ).roles,
                        });
                    });
                Object.keys(this.state.selectedCPVOColleagues || {})
                    .map(el => parseInt(el))
                    .forEach(thirdPartyId => {
                        JSONRequest.CPVOColleagues.push({
                            contactId: thirdPartyId,
                            role: (this.ccList.find(ccListResult => ccListResult['id'] === thirdPartyId) || {}).role,
                        });
                    });
                apiDocumentUpload(JSONRequest, documentList)
                    .then(({communicationId} = {}) => {
                        if (communicationId) {
                            this.setState(
                                prev => ({loading: --prev.loading}),
                                () => {
                                    this.props.close();
                                    // this.props.openModalCPVOLogs &&
                                    // this.props.openModalCPVOLogs({
                                    //     comments: this.state.body,
                                    //     communicationId,
                                    //     subject,
                                    // });
                                }
                            );
                        } else {
                            this.setState(prev => ({loading: --prev.loading, error: '404 - Document not found'}));
                        }
                    })
                    .catch(this.close);
            }
        );
    };

    close = refresh => this.props.close && this.props.close(refresh);

    closeError = () => this.setState({error: null});

    handleDrop = (files, event) => this.setState({error: false}, () => this.checkFile(files[0], event));

    checkFile = (file, event) => {
        LOG`file.type:${file.type}`;
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType.toLowerCase();
        const filename = sanitize(file.name);
        this.setState({error: null}, () => {
            if (
                fileTypeLowercase === 'jpeg' ||
                fileTypeLowercase === 'png' ||
                fileTypeLowercase === 'jpg' ||
                fileTypeLowercase === 'pdf'
            ) {
                if (file.size > MAX_UPLOAD_FILE_SIZE) {
                    this.setState({
                        error: 'The maximum document size for uploads is 10 MB. Please select a proper file.',
                    });
                } else {
                    const a = new FileReader();
                    a.readAsBinaryString(file);
                    a.onloadend = () => {
                        const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(a.result));
                        this.setState(
                            prev => {
                                const documentList = prev.documentList.slice(0);
                                documentList.push({
                                    index,
                                    file,
                                    filename,
                                    size: file.size / 1024,
                                    extension: fileTypeLowercase,
                                    documentType: null,
                                });
                                return {documentList, timestamp: Date.now(), informationOpened: false};
                            },
                            () => (event.target.value = null)
                        );
                    };
                }
            } else {
                this.setState({error: 'type not valid'}, () => (event.target.value = null));
            }
        });
    };

    deleteFile = index =>
        this.setState(prev => {
            return {
                documentList: prev.documentList.filter(i => i.index !== index),
                timestamp: Date.now(),
                informationOpened: prev.documentList.length === 1,
            };
        });

    toggleInformation = () => this.setState(prev => ({informationOpened: !prev.informationOpened}));

    checkApplicationNumber = applicationNumber => {
        let applicationNumberValue = applicationNumber;
        if (!isNaN(applicationNumberValue)) {
            applicationNumberValue = applicationNumberValue.substring(0, 8);
            this.setState({
                applicationNumber: applicationNumberValue,
                applicationNumberError: isRegisterIdValid(applicationNumberValue)
                    ? applicationNumberValue.length === 8
                        ? 'ADD'
                        : null
                    : applicationNumber !== ''
                    ? 'UNKNOWN'
                    : null,
            });
        }
    };

    /*
     * Requestor object: { 2690: "Marie-Anne Haaring-Schepman - 2690 - (Requestor)" }
     *
     *
     * */
    updateClientList = selectedApplicationNumbers =>
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                apiCommunicationCentreClients({
                    applicationNumbers: Object.keys(selectedApplicationNumbers),
                }).then(resultJSON => {
                    this.clientList = [];
                    this.clientHash = {};
                    this.clientNameHash = {};
                    this.clientResults = resultJSON.data;
                    resultJSON.data.forEach(client => {
                        const companyNameWithThirdPartyIdv = getSelectedThirdPartyIdValue(client);
                        this.clientList.push({
                            ID: String(client.thirdPartyId),
                            NAME: companyNameWithThirdPartyIdv,
                        });
                        this.clientHash[client.thirdPartyId] = client;
                        this.clientNameHash[companyNameWithThirdPartyIdv] = [client.thirdPartyId];
                        if (this.props.adminProccedingsComposeMessageRequestorThirdPartyId) {
                            if (!this.clientHash[this.props.adminProccedingsComposeMessageRequestorThirdPartyId]) {
                                let requestorObject = getSelectedThirdPartyIdValue({
                                    companyName: this.props.adminProccedingsComposeMessageRequestorName,
                                    thirdPartyId: this.props.adminProccedingsComposeMessageRequestorThirdPartyId,
                                });
                                this.clientList.push({
                                    ID: String(this.props.adminProccedingsComposeMessageRequestorThirdPartyId),
                                    NAME: requestorObject,
                                });
                                this.clientHash[this.props.adminProccedingsComposeMessageRequestorThirdPartyId] = {
                                    thirdPartyId: this.props.adminProccedingsComposeMessageRequestorThirdPartyId,
                                    companyName: this.props.adminProccedingsComposeMessageRequestorName,
                                };
                                this.clientNameHash[requestorObject] = [
                                    this.props.adminProccedingsComposeMessageRequestorThirdPartyId,
                                ];
                            }
                        }
                    });
                    let clientsWithRoles2 = resultJSON.data.filter(el => parseInt((el && el.roles) || '') === 2);
                    let clientsWithRoles1 = resultJSON.data.filter(el => parseInt((el && el.roles) || '') === 1);
                    return new Promise(resolve =>
                        this.setState(
                            prev => {
                                // THIS IS FOR keeping selected ids on application number change, if still exist
                                let selectedThirdPartyIdWithRoles2 = {};
                                let selectedThirdPartyIdWithRoles1 = {};
                                if (
                                    this.props.originScreen !== MODAL_COMPOSE_MESSAGE_ORIGIN_SCREENS.ADMIN_PROCEEDINGS
                                ) {
                                    clientsWithRoles1.forEach(({thirdPartyId}) => {
                                        if (prev.selectedThirdPartyId[thirdPartyId]) {
                                            selectedThirdPartyIdWithRoles1[thirdPartyId] =
                                                prev.selectedThirdPartyId[thirdPartyId];
                                        }
                                    });
                                    clientsWithRoles2.forEach(client => {
                                        selectedThirdPartyIdWithRoles2[client.thirdPartyId] =
                                            getSelectedThirdPartyIdValue(client);
                                    });
                                }

                                let requestorObject = {};
                                if (this.props.adminProccedingsComposeMessageRequestorThirdPartyId) {
                                    requestorObject[this.props.adminProccedingsComposeMessageRequestorThirdPartyId] =
                                        getSelectedThirdPartyIdValue({
                                            companyName: this.props.adminProccedingsComposeMessageRequestorName,
                                            thirdPartyId:
                                                this.props.adminProccedingsComposeMessageRequestorThirdPartyId,
                                        });
                                }
                                let theSelectedThirdParty = {};
                                if (clientsWithRoles2.length) {
                                    theSelectedThirdParty[clientsWithRoles2[0].thirdPartyId] =
                                        getSelectedThirdPartyIdValue(clientsWithRoles2[0]);
                                }
                                if (!Object.keys(theSelectedThirdParty).length) {
                                    theSelectedThirdParty[clientsWithRoles1[0].thirdPartyId] =
                                        getSelectedThirdPartyIdValue(clientsWithRoles1[0]);
                                }
                                return {
                                    selectedThirdPartyId: {
                                        ...requestorObject,
                                        ...theSelectedThirdParty,
                                        ...selectedThirdPartyIdWithRoles2,
                                        ...selectedThirdPartyIdWithRoles1,
                                    },
                                    loading: --prev.loading,
                                };
                            },
                            () => resolve(true)
                        )
                    );
                });
            }
        );

    getLabelOfCC = () => {
        let {id: contactId} = this.decodedJWT || {};
        if (parseInt(contactId) === USER_SHORTKEY_TO_CONTACTID.FB) {
            return 'CPVO Colleagues / BOA members / EU agencies BOA members';
        }
        return 'CPVO Colleagues';
    };

    onSelectInputProfileChange = ({target: {value: selectValueProfile}}) => {
        if (selectValueProfile === SELECT_LIST_ITEM_ID_NOT_SELECTED) {
            this.setState({
                selectValueProfile: SELECT_LIST_ITEM_ID_NOT_SELECTED,
                selectValueDirectory: SELECT_LIST_ITEM_ID_NOT_SELECTED,
                selectValueSubtypeOfDocument: SELECT_LIST_ITEM_ID_NOT_SELECTED,
            });
        } else {
            this.setState(prev => {
                let selectListDocmanDirectories = [
                    {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_DIRECTORY_NOT_SELECTED},
                    ...arrayUniqueByKey(
                        PROFILES_DIRECTORIES_SUBTYPES[selectValueProfile].map(el => ({
                            id: el.dirId,
                            value: el.dirName,
                        }))
                    ),
                ];
                return {selectValueProfile, selectListDocmanDirectories};
            });
        }
    };

    onSelectInputDirectoryChange = ({target: {value: selectValueDirectory}}) => {
        this.setState(prev => {
            let selectListDocmanDocumentSubtypes = [
                {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_SUBTYPE_NOT_SELECTED},
                ...PROFILES_DIRECTORIES_SUBTYPES[prev.selectValueProfile]
                    .filter(directoryItem => directoryItem.dirId === parseInt(selectValueDirectory))
                    .map(el => ({
                        id: el.docTypeId,
                        value: el.docTypeName,
                    })),
            ];
            return {selectValueDirectory, selectListDocmanDocumentSubtypes};
        });
    };

    onSelectInputSubtypeOfDocumentChange = ({target: {value: selectValueSubtypeOfDocument}}) =>
        this.setState({selectValueSubtypeOfDocument});

    onApplicationNumberChange = applicationNumber => this.checkApplicationNumber(applicationNumber);

    onApplicationNumbersSelectionChange = selectedApplicationNumbers =>
        this.setState(
            Object.assign(
                {},
                {selectedApplicationNumbers},
                Object.keys(selectedApplicationNumbers).length === 0 && {selectedThirdPartyId: {}}
            ),
            () =>
                Object.keys(selectedApplicationNumbers).length !== 0 &&
                this.updateClientList(selectedApplicationNumbers)
        );

    onThirdPartyUserChange = thirdPartyContact => this.setState({thirdPartyContact});

    onIdChange = (thirdPartyId, callback) => {
        LOG`thirdPartyId: ${thirdPartyId}`;
        if (thirdPartyId) {
            const selectedThirdPartyId = {};
            this.clientList
                .filter(i => i.ID === thirdPartyId)
                .forEach(contact => (selectedThirdPartyId[contact.ID] = contact.NAME));
            this.setState(
                {
                    thirdPartyId,
                    selectedThirdPartyId,
                    thirdPartyContact: '',
                },
                callback
            );
        }
    };

    onThirdPartyUserSelectionChange = selectedThirdPartyId =>
        this.setState(
            Object.assign(
                {},
                {selectedThirdPartyId: selectedThirdPartyId, thirdPartyContact: ''},
                Object.keys(selectedThirdPartyId).length === 0 && {thirdPartyId: null}
            )
        );

    onAddingApplicationNumber = () => {
        const {applicationNumber} = this.state;
        if (applicationNumber.length === 8 && isRegisterIdValid(applicationNumber)) {
            this.setState(
                prev => ({loading: ++prev.loading}),
                () => {
                    (this.decodedJWT.thirdPartyId === CPVO_THIRD_PARTY_ID
                        ? Promise.resolve({message: 'OK'})
                        : apiUploadGetFileInfo(applicationNumber)
                    )
                        .then(result => {
                            if (result.message !== 'OK') {
                                this.setState({applicationNumberError: 'FORBIDDEN'});
                            } else {
                                this.setState(
                                    prev => {
                                        const selectedApplicationNumbers = prev.selectedApplicationNumbers;
                                        selectedApplicationNumbers[prev.applicationNumber] = prev.applicationNumber;
                                        return {
                                            applicationNumber: '',
                                            applicationNumberError: null,
                                            selectedApplicationNumbers,
                                            criteria: Object.assign({}, prev.criteria, {
                                                applicationNumberIds: Object.keys(selectedApplicationNumbers).join(','),
                                            }),
                                        };
                                    },
                                    () => this.updateClientList(this.state.selectedApplicationNumbers)
                                );
                            }
                        })
                        .then(() => this.setState(prev => ({loading: --prev.loading})));
                }
            );
        }
    };
    onCommunicationTypeChange = ({target: {value: communicationType}}) => this.setState({communicationType});

    onCommunicationGroupChange = ({target: {value: communicationGroup}}) => this.setState({communicationGroup});

    onCPVOColleaguesSectionChange = selectedCPVOColleagues =>
        this.setState({selectedCPVOColleagues, textLabelInputValueCPVOColleagues: ''});

    onTextLabelInputCPVOColleaguesChange = textLabelInputValueCPVOColleagues =>
        this.setState({textLabelInputValueCPVOColleagues});

    onSubjectChange = ({target: {value: subject}}) => this.setState({subject});

    onBodyChange = ({target: {value: body}}) => this.setState({body});

    onFileUpload = event => {
        const file = event.target.files[0];
        this.checkFile(file, event);
    };

    render() {
        const actions = ModalCommunicationCenterComposeActions(this.deleteFile);

        return (
            <DragAndDrop handleDrop={this.handleDrop}>
                {(this.state.loading !== 0 && <HeaderLoading />) || null}
                <ModalCustomVersion2
                    close={this.close}
                    header={`Compose a new message`}
                    body={
                        <>
                            {this.decodedJWT.thirdPartyId === CPVO_THIRD_PARTY_ID && (
                                <>
                                    <SelectInput
                                        label={`Profile`}
                                        value={this.state.selectValueProfile}
                                        onChange={this.onSelectInputProfileChange}
                                        list={this.state.selectListDocmanProfiles || []}
                                        notAll={true}
                                        triple={true}
                                        noIcon={true}
                                        mandatory={true}
                                        disabled={this.state.selectListDocmanProfiles?.length === 1}
                                    />
                                    <SelectInput
                                        label={`Directory`}
                                        value={this.state.selectValueDirectory}
                                        onChange={this.onSelectInputDirectoryChange}
                                        list={this.state.selectListDocmanDirectories || []}
                                        notAll={true}
                                        triple={true}
                                        noIcon={true}
                                        mandatory={true}
                                    />
                                    <SelectInput
                                        label={`Subtype of document`}
                                        value={this.state.selectValueSubtypeOfDocument}
                                        onChange={this.onSelectInputSubtypeOfDocumentChange}
                                        list={this.state.selectListDocmanDocumentSubtypes || []}
                                        notAll={true}
                                        triple={true}
                                        noIcon={true}
                                        mandatory={true}
                                    />
                                </>
                            )}
                            <div style={{clear: 'both'}}>{}</div>
                            {this.state.documentList.length > 0 && (
                                <CustomTable
                                    version={2}
                                    {...this.props}
                                    notSortable={['filename', 'size']}
                                    tableName={'documentUpload'}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.documentList || []}
                                    dataFilter={null}
                                    id={'index'}
                                    setLastCursor={null}
                                    resultFieldsDefault={['filename', 'size']}
                                    intl={this.props.intl}
                                    formatFunctions={{
                                        size: size => `${Math.floor(size * 100) / 100} KB`,
                                        extension: extension => (
                                            <img src={getIcon(extension || 'pdf')} alt={extension} />
                                        ),
                                    }}
                                    count={(this.state.documentList || []).length}
                                    hideExcelButton={true}
                                    noChangePageSize={true}
                                    forehandColumn={row => (
                                        <img
                                            style={{width: 20, height: 20, cursor: 'pointer'}}
                                            alt={'Download'}
                                            src={getIcon(row.extension || 'pdf')}
                                            onClick={event => {
                                                event.stopPropagation();
                                                saveAs(row.file, row.filename);
                                            }}
                                        />
                                    )}
                                    timestamp={this.state.timestamp}
                                    actions={actions}
                                />
                            )}
                            <TextLabelInput
                                onSelectionChange={this.onApplicationNumbersSelectionChange}
                                onChange={this.onApplicationNumberChange}
                                onEnter={this.onAddingApplicationNumber}
                                value={this.state.applicationNumber}
                                selectedElements={this.state.selectedApplicationNumbers}
                                delay={300}
                                multiple={true}
                                label={getMandatoryAsterisk(
                                    'To which application number(s) the communication is linked'
                                )}
                                buttonAction={this.onAddingApplicationNumber}
                                buttonIcon={faPlus}
                                buttonDisabled={
                                    (this.state.applicationNumberError &&
                                        this.state.applicationNumberError !== 'ADD') ||
                                    this.state.applicationNumber.length !== 8
                                }
                                triple={true}
                            />
                            {this.state.applicationNumberError && (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        width: 700,
                                        marginLeft: 40,
                                        marginBottom: 20,
                                    }}
                                >
                                    <Info>
                                        <div style={{display: 'inline-block', marginRight: 5}}>
                                            <FontAwesomeIcon icon={faFlag} color={'#8a6d3b'} />
                                        </div>
                                        <div style={{display: 'inline-block'}}>
                                            {errorMessages[this.state.applicationNumberError]}
                                        </div>
                                    </Info>
                                </div>
                            )}
                            {this.decodedJWT.thirdPartyId === CPVO_THIRD_PARTY_ID && (
                                <>
                                    <TextLabelInput
                                        onIdChange={this.onIdChange}
                                        onChange={this.onThirdPartyUserChange}
                                        onSelectionChange={this.onThirdPartyUserSelectionChange}
                                        value={this.state.thirdPartyContact}
                                        selectedElements={this.state.selectedThirdPartyId}
                                        delay={300}
                                        multiple={true}
                                        label={getMandatoryAsterisk('Addressees')}
                                        placeholder={`Please click on input and select an addressee`}
                                        triple={true}
                                        disabled={true}
                                        hideRemoveElementButton={true}
                                        listByIdName={this.clientList}
                                        nameHash={this.clientNameHash}
                                        uniqueValue={false}
                                        autoSelection={true}
                                        filterContains={true}
                                        clickable={null}
                                    />
                                    <TextLabelInput
                                        clickableAndEditable={true}
                                        double={true}
                                        onChange={this.onTextLabelInputCPVOColleaguesChange}
                                        onSelectionChange={this.onCPVOColleaguesSectionChange}
                                        value={this.state.textLabelInputValueCPVOColleagues}
                                        selectedElements={this.state.selectedCPVOColleagues}
                                        delay={300}
                                        multiple={true}
                                        label={this.getLabelOfCC()}
                                        placeholder={`Please type part of the colleague name`}
                                        triple={true}
                                        listByIdName={this.state.CPVOColleaguesListByIdName}
                                        nameHash={this.state.CPVOColleaguesNameHash}
                                        filterContains
                                    />
                                </>
                            )}
                            <TextAreaInput
                                label={getMandatoryAsterisk('Subject')}
                                value={this.state.subject}
                                onChange={this.onSubjectChange}
                                rows={3}
                                triple={true}
                                noIcon={true}
                            />
                            <TextAreaInput
                                label={getMandatoryAsterisk('Body')}
                                value={this.state.body}
                                onChange={this.onBodyChange}
                                rows={5}
                                triple={true}
                                noIcon={true}
                            />
                            <div style={{clear: 'both'}} />
                            <div>
                                <InputLink
                                    label={`Notes`}
                                    icon={this.state.informationOpened ? faChevronDown : faChevronRight}
                                    clickAction={this.toggleInformation}
                                />
                                <div style={{clear: 'both'}} />
                                {this.state.informationOpened && <BrowserInfo intl={this.props.intl} />}
                            </div>
                            <div style={{clear: 'both'}} />
                        </>
                    }
                    footer={
                        <>
                            {this.state.error ? <Error>{this.state.error}</Error> : null}
                            <div className={styles.modalFooterActions}>
                                <div className={styles.buttonAddDocument}>
                                    <label htmlFor={'upload'}>
                                        <input
                                            type="file"
                                            name="files[]"
                                            id="upload"
                                            style={{opacity: 0, width: 0}}
                                            onChange={this.onFileUpload}
                                            accept="image/jpg, image/jpeg, image/png, application/pdf"
                                            ref={this.inputUploadRef}
                                        />
                                        <Button
                                            variation={'secondary'}
                                            clickAction={() => this.inputUploadRef.current.click()}
                                        >{`Add document`}</Button>
                                    </label>
                                </div>
                                <div>
                                    <Button
                                        variation={'danger'}
                                        clickAction={this.close}
                                        icon={'close'}
                                        className={styles.buttonClose}
                                    >{`Close`}</Button>
                                    <Button
                                        clickAction={() => this.confirmSending('sent')}
                                        icon={'arrowRight'}
                                    >{`Send`}</Button>
                                </div>
                            </div>
                        </>
                    }
                />
            </DragAndDrop>
        );
    }
}
