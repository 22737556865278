import React, {useEffect, useState} from 'react';
import {ModalCustomVersion2, ModalLabelPreviewVersion2, ModalLabelSearchVersion2} from '../../../commonModals';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {faLightbulb, faList, faPencilAlt, faPlus, faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import ModalUsedInVersion2, {rowType} from '../../../commonModals/ModalUsedInVersion2';
import ModalLabelVersion2, {getLabelTypeId} from '../../../commonModals/ModalLabelVersion2';
import {injectIntl} from 'react-intl';
import {htmlToText} from '~utils/index';
import {Button} from '../../../componentsFormV2';

const trimStringIfNeeded = (string, maxLength = 24) => {
    let textString = htmlToText(string);
    return textString.length > maxLength ? `${textString.slice(0, maxLength)}...` : textString;
};

const ModalLogicTypeForceResponse = ({
    close,
    onCreateOrUpdateLogicType,
    responseLogicValues,
    responseLogicType,
    intl,
    steps,
}) => {
    const {logicParam, messageLink, messageLinkId, responseIdDest, iteration} = responseLogicValues || {};
    const {name} = responseLogicType || {};
    const [allResponses, setAllResponses] = useState([]);
    const [selectList, setSeletectList] = useState([]);
    const [selectedResponseIdDest, setSelectedResponseIdDest] = useState(null);
    const [selectedQuestionIdDest, setSelectedQuestionIdDest] = useState(null);
    const [modalLabel, setModalLabel] = useState(null);
    const [modalLabelSearch, setModalLabelSearch] = useState(null);
    const [modalPreview, setModalPreview] = useState(null);
    const [modalUsedIn, setModalUsedIn] = useState(null);
    const [labelIdValue, setLabelIdValue] = useState(messageLinkId ? messageLinkId : null);
    const [labelHtml, setLabelHtml] = useState(messageLink ? messageLink : null);
    const [paramValue, setParamValue] = useState(logicParam !== undefined ? logicParam : null);

    useEffect(() => {
        let propsResponsesList = [];
        steps
            .filter(step => step.isGroup === true)
            .forEach(step => {
                step.questions.forEach(question => {
                    question.responses.forEach(response => {
                        propsResponsesList.push({
                            id: response.id,
                            questionId: question.id,
                            libelle: `${step.order} ${trimStringIfNeeded(step.message)} / ${
                                question.order
                            } ${trimStringIfNeeded(question.message)} / ${response.order} ${response.message}`,
                        });
                    });
                });
            });
        setAllResponses([...propsResponsesList]);
        setSeletectList(propsResponsesList.map(el => ({id: el.id, value: el.libelle})));
        let logicSelectedId = responseIdDest ? responseIdDest : null;
        setSelectedResponseIdDest(logicSelectedId || (propsResponsesList.length > 0 ? propsResponsesList[0].id : null));
    }, []);

    useEffect(() => {
        const selectedResponse = allResponses.find(el => parseInt(el.id) === parseInt(selectedResponseIdDest)) || {};
        const {questionId} = selectedResponse || null;
        setSelectedQuestionIdDest(questionId);
    }, [selectedResponseIdDest]);

    const onParamValueChange = ({target: {value: param}}) => setParamValue(param);

    const setLabelId = label => {
        const {labelId, valueHTML, labelValueHTML} = label;
        setLabelIdValue(labelId);
        setLabelHtml(valueHTML || labelValueHTML);
    };

    const clearLabelUse = () => {
        setLabelIdValue(null);
        setLabelHtml(null);
    };

    const renderPopupElements = (labelId, typeId, fieldIdKey, fieldHTMLKey) => {
        const elementsArr = [];
        elementsArr.push({
            label: 'Search label',
            icon: faSearch,
            handler: () => setModalLabelSearch({labelId, typeId, fieldIdKey, fieldHTMLKey}),
        });
        !labelId &&
            elementsArr.push({
                label: 'Add label',
                icon: faPlus,
                handler: () => setModalLabel({labelId: 0, typeId, fieldIdKey, fieldHTMLKey}),
            });
        labelId &&
            elementsArr.push({
                label: 'Show label',
                icon: faPencilAlt,
                handler: () => setModalPreview(labelHtml),
            });
        labelId &&
            elementsArr.push({
                label: 'Clear label',
                icon: faTimes,
                handler: () => clearLabelUse(),
            });
        labelId &&
            elementsArr.push({
                label: 'Used in',
                icon: faLightbulb,
                handler: () => setModalUsedIn(labelId),
            });
        return elementsArr;
    };

    return (
        <>
            <ModalCustomVersion2
                close={close}
                header={name}
                body={
                    <>
                        <div>
                            <SelectInput
                                label={`Response to show`}
                                value={selectedResponseIdDest}
                                notAll={true}
                                double={true}
                                onChange={({target: {value}}) => setSelectedResponseIdDest(value)}
                                list={selectList}
                            />
                            <TextInput double={true} label={`Value`} onChange={onParamValueChange} value={paramValue} />
                        </div>
                        <div style={{clear: 'both'}} />
                        <div style={{paddingLeft: 10, marginTop: 10}}>
                            <DataSheetFormFields
                                label={'Message'}
                                html={labelHtml}
                                double={true}
                                containerClassName="popover-nested"
                                labelIcon={faList}
                                labelPopup={renderPopupElements(
                                    labelIdValue,
                                    getLabelTypeId('Response'),
                                    'nameMessage',
                                    'remarklValueHTML'
                                )}
                            />
                        </div>
                    </>
                }
                footer={
                    <>
                        <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                        <Button
                            icon={'arrowRight'}
                            clickAction={() =>
                                onCreateOrUpdateLogicType(
                                    paramValue,
                                    null,
                                    selectedResponseIdDest,
                                    selectedQuestionIdDest,
                                    labelIdValue,
                                    iteration
                                )
                            }
                        >{`Save`}</Button>
                    </>
                }
            />
            {modalLabel ? (
                <ModalLabelVersion2
                    intl={intl}
                    label={modalLabel}
                    setLabelId={setLabelId}
                    showModalUsedIn={labelId => setModalUsedIn(labelId)}
                    close={() => setModalLabel(null)}
                />
            ) : null}
            {modalLabelSearch !== null ? (
                <ModalLabelSearchVersion2
                    intl={intl}
                    target={modalLabelSearch}
                    setLabelId={setLabelId}
                    // setRefreshModalSearchFunction={this.setRefreshModalSearchFunction}
                    showModalPreview={valueHTML => setModalPreview(valueHTML)}
                    closeModalPreview={() => setModalPreview(null)}
                    showModalUsedIn={labelId => setModalUsedIn(labelId)}
                    closeModal={() => setModalLabelSearch(null)}
                    showModalLabel={(labelId, typeId, fieldIdKey, fieldHTMLKey) =>
                        setModalLabel({labelId, typeId, fieldIdKey, fieldHTMLKey})
                    }
                    closeModalLabel={() => setModalLabel(null)}
                    close={() => setModalLabelSearch(null)}
                />
            ) : null}
            {modalPreview !== null ? (
                <ModalLabelPreviewVersion2 intl={intl} close={() => setModalPreview(null)} valueHTML={modalPreview} />
            ) : null}
            {modalUsedIn ? (
                <ModalUsedInVersion2
                    elementId={modalUsedIn}
                    intl={intl}
                    type={rowType.RESPONSE}
                    close={() => setModalUsedIn(null)}
                />
            ) : null}
        </>
    );
};

export default injectIntl(ModalLogicTypeForceResponse);
