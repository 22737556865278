import React from 'react';
import styles from './VFContainerWrapper.module.scss';

const VFContainerWrapper = ({contentRight, title, subtitle, children, style: userStyle}: any) => {
    return (
        <div>
            <div className={styles.wrapper} style={{...(userStyle || {})}}>
                <header className={styles.header}>
                    <div className={styles.title}>{title}</div>
                    {subtitle ? <div style={{display: 'inline-block'}}>{subtitle}</div> : null}
                    {contentRight ? <div style={{float: 'right'}}>{contentRight}</div> : null}
                </header>
                <div>{children}</div>
            </div>
        </div>
    );
};

export default VFContainerWrapper;
