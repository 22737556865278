import React from 'react';
import {injectIntl} from 'react-intl';
import ApplicationConsultationTabs, {TAB} from '~shared/ApplicationConsultationTabs';
import {apiPublicSearchApplicationDetails, apiPublicSearchDenomination} from './MyPVRPublicConsultationDetailsService';
import {apiPublicSearchParties} from '~commonApi/publicSearch';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {FORMAT_DATE, formatDateEasy} from '~components/FormatFunctions';
import CustomTable from '~components/CustomTable';
import {faInfo} from '@fortawesome/free-solid-svg-icons';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogo,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import styles from './MyPVRPublicConsultationDetails.module.scss';
import {ModalCountryListVersion2, ModalDenominationInfoVersion2, ModalStatusInfoVersion2} from '../../commonModals';
import NavigationPublicSearch from '../../shared/NavigationPublicSearch';
import {trackPageView} from '../../utils';

class MyPVRPublicConsultationDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {fileDetails: null, parties: null, denominations: null, loading: false, timestamp: Date.now()};
    }

    componentDidMount() {
        trackPageView({documentTitle: 'publicConsultationDetails'});
    }

    loadJSONs = applicationNumber => {
        return new Promise((resolve, reject) => {
            this.setState({loading: true}, () => {
                Promise.all([
                    this.loadApplicationDetails(applicationNumber),
                    this.loadParties(applicationNumber),
                    this.loadDenominations(applicationNumber),
                ])
                    .then(() => this.setState({loading: false}, resolve))
                    .catch(reject);
            });
        });
    };

    loadApplicationDetails = applicationNumber => {
        return apiPublicSearchApplicationDetails(applicationNumber)
            .then(
                jsonResponse =>
                    jsonResponse.data &&
                    this.setState({
                        fileDetails: Object.assign(
                            {},
                            jsonResponse.data,
                            jsonResponse.data.applicationNumber === '19951123' && {
                                applicationStatus: 'T',
                                expirationDate: '2023-12-31',
                            }
                        ),
                    })
            )
            .catch(error => LOG`ERROR ${error}`);
    };

    loadParties = applicationNumber => {
        return apiPublicSearchParties(applicationNumber)
            .then(jsonResponse => jsonResponse.data && this.setState({parties: jsonResponse.data}))
            .catch(error => LOG`ERROR ${error}`);
    };

    loadDenominations = applicationNumber => {
        return apiPublicSearchDenomination(applicationNumber)
            .then(jsonResponse => jsonResponse.data && this.setState({denominations: jsonResponse.data}))
            .catch(error => LOG`ERROR ${error}`);
    };

    closeModal = () => {
        this.setState({
            modalScreenCountries: false,
            modalDenominationInfo: false,
            modalStatusInfo: false,
        });
    };

    showModalDenominationInfo = () => {
        this.setState({modalDenominationInfo: true});
    };

    showModalStatusInfo = () => {
        this.setState({modalStatusInfo: true});
    };

    showCountriesModal = () => {
        this.setState({modalScreenCountries: true});
    };

    updateTimestamp = dateNow => this.setState({timestamp: dateNow});

    render() {
        const fileDetails = this.state.fileDetails || {};
        return (
            <>
                {this.state.loading ? <HeaderLoading /> : null}
                {this.state.modalDenominationInfo && <ModalDenominationInfoVersion2 close={this.closeModal} />}
                {this.state.modalStatusInfo ? (
                    <ModalStatusInfoVersion2 filter={['A', 'W', 'R', 'G', 'T']} close={this.closeModal} />
                ) : null}
                {this.state.modalScreenCountries && <ModalCountryListVersion2 close={this.closeModal} />}
                <HeaderLogo />
                <HeaderTitleAndVersion title={`CPVO Application Consultation`} />
                <NavigationPublicSearch />
                <MainWrapper>
                    <FormWrapper classNameContent={styles.formContent}>
                        <ApplicationConsultationTabs
                            tabId={TAB.DETAILS}
                            close={this.props.close}
                            search={this.loadJSONs}
                            loading={this.state.loading}
                            updateTimestamp={this.updateTimestamp}
                        />
                        <DataSheetFormSection title={'General'}>
                            <DataSheetFormFields
                                label={'Application number'}
                                data={fileDetails.applicationNumber}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'Status'}
                                data={
                                    fileDetails.applicationStatus &&
                                    this.props.intl.formatMessage({
                                        id: `status.fieldName.${fileDetails.applicationStatus}`,
                                    })
                                }
                                loading={this.state.loading}
                                buttonIcon={faInfo}
                                buttonAction={this.showModalStatusInfo}
                            />
                            <DataSheetFormFields
                                label={'Application date'}
                                data={formatDateEasy(fileDetails.applicationDate)}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'UPOV Code'}
                                data={fileDetails.specieUPOVId}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'Species'}
                                data={fileDetails.specieName}
                                loading={this.state.loading}
                                double={true}
                            />
                            <DataSheetFormFields
                                label={'First sale inside EU'}
                                data={formatDateEasy(fileDetails.firstCommercializationInsideEUDate)}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'Country'}
                                data={fileDetails.countryIdInsideEU}
                                loading={this.state.loading}
                                buttonIcon={faInfo}
                                buttonAction={this.showCountriesModal}
                            />
                            <DataSheetFormFields
                                label={'First sale outside EU'}
                                data={formatDateEasy(fileDetails.firstCommercializationOutsideEUDate)}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'Country'}
                                data={fileDetails.countryIdOutsideEU}
                                loading={this.state.loading}
                                buttonIcon={faInfo}
                                buttonAction={this.showCountriesModal}
                            />
                        </DataSheetFormSection>
                        <DataSheetFormSection title={'Application'}>
                            <DataSheetFormFields
                                label={'Arrival date'}
                                data={formatDateEasy(fileDetails.arrivalDate)}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'National application date'}
                                data={formatDateEasy(fileDetails.nationalOfficeDate)}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'Crop sector'}
                                data={fileDetails.groupVarietal}
                                loading={this.state.loading}
                                double={true}
                            />
                            <DataSheetFormFields
                                label={'Denomination'}
                                data={fileDetails.denomination}
                                loading={this.state.loading}
                                double={true}
                            />
                            <DataSheetFormFields
                                label={"Breeder's reference"}
                                data={fileDetails.reference}
                                loading={this.state.loading}
                                double={true}
                            />
                        </DataSheetFormSection>
                        <DataSheetFormSection title={'Title'}>
                            <DataSheetFormFields
                                label={'Title number'}
                                data={fileDetails.title}
                                loading={this.state.loading}
                                double={true}
                            />
                            <DataSheetFormFields
                                label={'Title date'}
                                data={formatDateEasy(fileDetails.titleStartDate)}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'Expiration date of protection'}
                                data={formatDateEasy(fileDetails.expirationDate)}
                                loading={this.state.loading}
                            />
                        </DataSheetFormSection>
                        <DataSheetFormSection title={'Parties'}>
                            {this.state.parties && this.state.parties.length && this.state.parties.length > 0 ? (
                                <CustomTable
                                    tableName={'parties'}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.parties}
                                    id={'clientId'}
                                    loading={this.state.loading}
                                    resultFieldsDefault={['role', 'clientId', 'name', 'addressOfficial', 'addressMail']}
                                    intl={this.props.intl}
                                    formatFunctions={{
                                        addressOfficial: fieldData => (
                                            <div style={{cursor: 'default'}}>
                                                {fieldData.split('\n').map(line => (
                                                    <div>{line}</div>
                                                ))}
                                            </div>
                                        ),
                                        addressMail: fieldData => (
                                            <div style={{cursor: 'default'}}>
                                                {fieldData.split('\n').map(line => (
                                                    <div>{line}</div>
                                                ))}
                                            </div>
                                        ),
                                    }}
                                    bold={() => true}
                                    timestamp={this.state.timestamp}
                                />
                            ) : null}
                        </DataSheetFormSection>
                        <>
                            {this.state.denominations &&
                            this.state.denominations.length &&
                            this.state.denominations.length > 0 ? (
                                <DataSheetFormSection title={'Denomination'}>
                                    <CustomTable
                                        tableName={'denomination'}
                                        tableType={'OBJECT'}
                                        tableSource={this.state.denominations}
                                        id={'denominationId'}
                                        resultFieldsDefault={[
                                            'denomination',
                                            'status',
                                            'applicationDate',
                                            'publicationDate',
                                        ]}
                                        intl={this.props.intl}
                                        defaultOrder={'applicationDate'}
                                        reverseOrder={true}
                                        formatFunctions={{
                                            status: status =>
                                                this.props.intl.formatMessage({
                                                    id: `denomination.${status}`,
                                                }),
                                            applicationDate: FORMAT_DATE,
                                            publicationDate: FORMAT_DATE,
                                        }}
                                        headerPopup={{
                                            status: {
                                                description: 'Status explanations',
                                                handler: this.showModalDenominationInfo,
                                            },
                                        }}
                                        bold={() => true}
                                        timestamp={this.state.timestamp}
                                    />
                                </DataSheetFormSection>
                            ) : null}
                        </>
                    </FormWrapper>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(MyPVRPublicConsultationDetails);
