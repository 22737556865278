import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export interface I_SEARCH_RESULT_FORM {
    formId: number;
    formName: string;
    commonName: string;
    status: number;
    versionCode: string | null;
    validFrom: string | null;
    endOfValidity: string | null;
    proposalDate: string | null;
    expert: string | null;
    type: number;
    origin: number | null;
    officeId: number;
    newVersionID: number | null;
}

export function apiFormSearch(
    criteria: any,
    DEFAULT_CRITERIA: any = {}
): Promise<{
    data: {
        COUNT: number;
        forms: I_SEARCH_RESULT_FORM[];
    };
}> {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return getFetch(`${API_SERVERLESS}/backOffice/v2/forms?${requestParams.join('&')}`, true);
}

export function apiBackOfficeGetExperts(): Promise<{
    data: string[];
}> {
    return getFetch(`${API_SERVERLESS}/backOffice/v3/experts`, true);
}

export function apiBackOfficeFormCopy(JSONRequest: {formId: number}): Promise<{
    newFormId?: number;
    errorMessage?: string;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formCopy`, true, JSONRequest);
}

export function apiBackOfficeFormDelete(JSONRequest: {formId: number}) {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formDelete`, true, JSONRequest);
}

export function apiBackOfficeFormNewVersion(JSONRequest: {formId: number}): Promise<{
    formNewVersion?: any;
    errorMessage?: string;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formNewVersion`, true, JSONRequest);
}

export function apiBackOfficeFormImport(JSONRequest: {formId: number}): Promise<{
    data?: string;
    errorMessage?: string;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formImport`, true, JSONRequest);
}

export function apiBackOfficeGetCropSectors(): Promise<{
    data: {ID: string; NAME: string}[];
}> {
    return new Promise((resolve, reject) => {
        setTimeout(function () {
            resolve({
                data: [
                    {ID: '1', NAME: 'Agricultural'},
                    {ID: '2', NAME: 'Vegetables'},
                    {ID: '3', NAME: 'Fruit'},
                    {ID: '4', NAME: 'Ornamental'},
                    {ID: '5', NAME: 'Forest'},
                    {ID: '0', NAME: 'Not identified'},
                ],
            });
        }, 10);
    });
}
