import {API_SERVERLESS, getFetch} from '~commonApi';
import {apiCommunicationCentreGetCommunicationIdUploadFilesAndSendMessage} from '../CommunicationCentreClient/CommunicationCentreClientService';
import {ICommunicationMessageClientSubmitJSONRequest, IDocument} from '../../types';

export function apiCommunicationCentreModalClientComposeGetFileInfo(applicationNumber: string) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreModalClient/v4/CCMCCCAN/compose/getFileInfoIfExists/${applicationNumber}`,
        true
    );
}

export function apiGetCommunicationIdUploadFilesAndSendMessage(
    JSONRequest: ICommunicationMessageClientSubmitJSONRequest,
    documentList: IDocument[]
) {
    return apiCommunicationCentreGetCommunicationIdUploadFilesAndSendMessage(JSONRequest, documentList, {
        getCommunicationId: `communicationCentreModalClient/v4/CCMCCGID/compose/getCommunicationId`,
        documentUploadFile: 'communicationCentreModalClient/v4/CCMCCUF/compose/documentUploadFile',
        submitRequest: 'communicationCentreModalClient/v4/CCMCCS/compose/messageSend',
    });
}
