import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import CombolistLanguage1 from './screenshots/CombolistLanguage_1.png';
import CombolistLanguage2 from './screenshots/CombolistLanguage_2.png';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeComboListLanguageHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>Displays a language select list like the following: </p>
                    <img src={CombolistLanguage1} alt="CombolistLanguage" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>
                        When the user selects a language, the select list value is updated accordingly:
                    </p>
                    <img src={CombolistLanguage2} alt="CombolistLanguage" className={styles.modalHelpImage} />
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeComboListLanguageHelp;
