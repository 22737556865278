import React from 'react';
import ModalCustomVersion2 from '../ModalCustomVersion2';
import CustomTable from '~components/CustomTable';
import ModalLabelSearchVersion2ActionButtons from './ModalLabelSearchVersion2ActionButtons';
import {faInfo} from '@fortawesome/free-solid-svg-icons';
import TextInput from '~components/TextInput';
import SelectInput from '~components/SelectInput';
import OPTIONS_OFFICE from './data/OPTIONS_OFFICE.json';
import OPTIONS_LANGUAGE from './data/OPTIONS_LANGUAGE.json';
import OPTIONS_TYPE from './data/OPTIONS_TYPE.json';
import DEFAULT_RESULT_FIELDS from './data/DEFAULT_RESULT_FIELDS.json';
import DEFAULT_CRITERIA from './data/DEFAULT_CRITERIA.json';
import {apiLabelsSearch} from '~commonApi/labels';
import {getDecodedJWT, isUserOfficeCPVO, isUserOfficeNAK} from '../../utils';
import {LoadingBar} from '../../componentsLayout';
import {Button} from '../../componentsFormV2';

const DEFAULT_COUNT = 0;
export default class ModalLabelSearchVersion2 extends React.Component {
    constructor(props) {
        super(props);
        this.loggedInUser = getDecodedJWT() || {};
        this.state = {
            labels: null,
            selectedLabels: {},
            criteria: Object.assign(
                {},
                DEFAULT_CRITERIA,
                {
                    languageId: 'EN',
                    typeId: props.target && props.target.typeId,
                    order: 'value',
                },
                this.loggedInUser && this.loggedInUser.language && this.loggedInUser.language !== 'en'
                    ? {
                          withOfficeTranslation: true,
                      }
                    : {},
                isUserOfficeCPVO() ? {officeId: '1'} : {},
                isUserOfficeNAK() ? {officeId: '21'} : {}
            ),
            criteriaCount: 0,
            count: DEFAULT_COUNT,
            modalConfirm: null,
            modalLabel: null,
            modalMergeLabels: false,
            modalPreview: null,
        };
    }

    componentDidMount() {
        this.props.setRefreshModalSearchFunction && this.props.setRefreshModalSearchFunction(() => this.search(true));
    }

    showModalPreview = valueHTML => this.props.showModalPreview && this.props.showModalPreview(valueHTML);

    showModalUsedIn = labelId => this.props.showModalUsedIn && this.props.showModalUsedIn(labelId);

    showModalLabel = (labelId, typeId) => this.props.showModalLabel && this.props.showModalLabel(labelId, typeId);

    search = refresh => {
        this.setState(
            prev =>
                Object.assign(
                    {},
                    {
                        loading: true,
                        criteria: Object.assign(
                            {},
                            prev.criteria,
                            {refresh: !!refresh},
                            !refresh && !prev.criteria.pageNumber && {pageNumber: 1}
                        ),
                    },
                    !refresh && {selectedLabels: {}}
                ),
            () => {
                const {criteria} = this.state;
                const parsedCriteria = Object.assign({}, criteria);
                apiLabelsSearch(parsedCriteria, DEFAULT_CRITERIA)
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.labels) {
                            this.labelHash = {};
                            (jsonResponse.data.labels || []).forEach(
                                label => (this.labelHash[`${label.labelId}-${label.languageId}`] = label)
                            );
                            this.setState(
                                prev => {
                                    const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                    return Object.assign(
                                        {usedInChannel: Date.now()},
                                        !refresh && {criteria},
                                        !refresh && {selectedLabels: {}},
                                        {
                                            labels: jsonResponse.data.labels,
                                            timestamp: Date.now(),
                                        },
                                        jsonResponse.data.COUNT && {count: jsonResponse.data.COUNT}
                                    );
                                },
                                () => {
                                    !refresh && this.clearSelectionFunction && this.clearSelectionFunction();
                                }
                            );
                        }
                    })
                    .catch(error => {
                        ERROR`Register search list error: ${error.message}`;
                    })
                    .then(() => this.setState({loading: false}));
            }
        );
    };

    resetCriteria = () =>
        this.setState(prev => ({
            selectedLabels: {},
            criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
            criteriaCount: 0,
            labels: null,
            timestamp: Date.now(),
        }));

    updateCriteriaValue = (criteriaValue, callback, refresh) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                const criteriaCount = this.countCriteria(criteria);
                pageNumberChanged = criteriaValue.pageNumber && prev.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse && prev.reverse !== criteriaValue.reverse;
                return {criteria, criteriaCount};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    countCriteria = () => {
        return 0;
    };

    close = () => {
        this.props.close && this.props.close();
    };

    getLanguageById = languageId => {
        const selectedLanguage = OPTIONS_LANGUAGE.filter(l => l.id === languageId);
        if (selectedLanguage.length > 0) {
            return selectedLanguage[0].value;
        } else {
            return languageId;
        }
    };

    getLabelTypeById = typeId => {
        const selectedType = OPTIONS_TYPE.filter(c => parseInt(c.id) === typeId);
        if (selectedType.length > 0) {
            return selectedType[0].value;
        } else {
            return typeId;
        }
    };

    getOfficeNameById = officeId => {
        const selectedOffice = OPTIONS_OFFICE.filter(o => parseInt(o.id) === officeId);
        if (selectedOffice.length > 0) {
            return selectedOffice[0].value;
        } else {
            return officeId;
        }
    };

    onSelectedOfficeChange = event => {
        const officeId = event.target.value;
        this.updateCriteriaValue({officeId});
    };

    onSelectedLanguageChange = event => {
        const languageId = event.target.value;
        this.updateCriteriaValue({languageId});
    };

    onSelectedTypeIdChange = event => {
        const typeId = event.target.value;
        this.updateCriteriaValue({typeId});
    };

    onContainsInputChange = event => {
        const contains = event.target.value;
        this.updateCriteriaValue({contains});
    };

    onContainsFilterChange = event => {
        const containsFilter = event.target.value;
        this.updateCriteriaValue({containsFilter});
    };

    onLabelIdInputChange = event => {
        const labelIds = event.target.value;
        if (/^[0-9]*$/.test(labelIds)) {
            this.updateCriteriaValue({labelIds});
        }
    };

    onRowClick = (labelKey, label) => {
        const {setLabelId, target} = this.props;
        Promise.resolve(
            setLabelId && setLabelId(label, target && target.fieldIdKey, target && target.fieldHTMLKey)
        ).then(this.close());
    };

    render() {
        const actions = ModalLabelSearchVersion2ActionButtons(
            this.props,
            this.showModalLabel,
            this.showModalUsedIn,
            this.showModalPreview,
            this.openModalConfirm
        );
        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={`Search labels`}
                body={
                    <div>
                        <SelectInput
                            label={`Office`}
                            value={this.state.criteria.officeId}
                            onChange={this.onSelectedOfficeChange}
                            list={OPTIONS_OFFICE}
                        />
                        <SelectInput
                            label={`Language`}
                            value={this.state.criteria.languageId}
                            onChange={this.onSelectedLanguageChange}
                            list={OPTIONS_LANGUAGE}
                        />
                        <SelectInput
                            label={`Type`}
                            value={this.state.criteria.typeId}
                            onChange={this.onSelectedTypeIdChange}
                            list={OPTIONS_TYPE}
                            disabled={true}
                        />
                        <TextInput
                            label={`Label value`}
                            filter={['start', 'contains']}
                            currentFilter={this.state.criteria.containsFilter}
                            onFilterChange={this.onContainsFilterChange}
                            infoIcon={faInfo}
                            onChange={this.onContainsInputChange}
                            value={this.state.criteria.contains}
                            double={true}
                            onEnter={() => this.search(false)}
                        />
                        <TextInput
                            label={`Label ID`}
                            filter={'Equals'}
                            infoIcon={faInfo}
                            onChange={this.onLabelIdInputChange}
                            value={this.state.criteria.labelIds}
                        />
                        <div style={{clear: 'both'}} />
                        <div
                            style={{
                                height: 450,
                                overflowY: 'auto',
                            }}
                        >
                            <CustomTable
                                version={2}
                                loading={this.state.loading}
                                pageNumber={this.state.criteria.pageNumber}
                                {...this.props}
                                tableName={'labels'}
                                actionName={`Action`}
                                tableType={'OBJECT'}
                                tableSource={this.state.labels}
                                timestamp={this.state.timestamp}
                                dataFilter={null}
                                id={['labelId', 'languageId']}
                                rowClick={this.onRowClick}
                                pagination={true}
                                resultFieldsDefault={DEFAULT_RESULT_FIELDS}
                                intl={this.props.intl}
                                defaultOrder={this.state.criteria.order}
                                reverseOrder={this.state.criteria.reverse}
                                pageSize={this.state.criteria.pageSize}
                                filterFunctions={null}
                                count={this.state.count}
                                hideExcelButton={true}
                                formatFunctions={{
                                    languageId: this.getLanguageById,
                                    typeId: this.getLabelTypeById,
                                    officeId: this.getOfficeNameById,
                                }}
                                actions={actions}
                                rowClass={this.getRowClass}
                                updateCriteriaValue={this.updateCriteriaValue}
                            />
                        </div>
                    </div>
                }
                footer={
                    this.state.loading ? (
                        <LoadingBar />
                    ) : (
                        <>
                            <Button clickAction={this.resetCriteria} variation={'secondary'}>{`Clear fields`}</Button>
                            <Button clickAction={this.close} icon={'close'} variation={'danger'}>{`Close`}</Button>
                            <Button clickAction={() => this.search(false)} icon={'arrowRight'}>{`Search`}</Button>
                        </>
                    )
                }
            />
        );
    }
}
