import {getFetch, postFetch, API_SERVERLESS} from '~commonApi';

export function apiGetOAPrepare({officeId, speciesId, speciesName}: any = {}) {
    let uri = Object.entries({officeId, speciesId, speciesName})
        .map(([key, value]) => value && `${key}=${value}`)
        .filter(el => el)
        .join('&');
    return getFetch(`${API_SERVERLESS}/online/v3/prepare?${uri}`, true);
}

export function apiOnlineApplicationRequestCreate(jsonBody: any) {
    return postFetch(`${API_SERVERLESS}/online/v3/applicationRequestCreate`, true, jsonBody);
}

export function apiOnlineApplicationRequestCopy(jsonBody: any) {
    return postFetch(`${API_SERVERLESS}/online/v3/applicationRequestCopy`, true, jsonBody);
}

export function apiOnlineApplicationRequestDuplicate(jsonBody: any) {
    return postFetch(`${API_SERVERLESS}/online/v3/applicationRequestDuplicate`, true, jsonBody);
}
