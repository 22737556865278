import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export function apiFormDatas(formId, language = 'EN') {
    return getFetch(`${API_SERVERLESS}/backOffice/v2/datas?formId=${formId}&language=${language}`, false).then(
        jsonResponse => {
            if (jsonResponse) {
                if (jsonResponse.datas && jsonResponse.datas.countries) {
                    jsonResponse.datas.ue_countries = jsonResponse.datas.countries.filter(
                        ({UEMEMBER} = {}) => parseInt(UEMEMBER) === 1
                    );
                }
                return {
                    ...jsonResponse,
                    datas: {
                        ...jsonResponse.datas,
                        eo_countries: [
                            {
                                ID: 'ID',
                                LABEL: 'Countries depends on species selected by end user',
                            },
                        ],
                    },
                };
            }
        }
    );
}

export function apiBackOfficeGetForm(formId = '', type = 'TQ', language = 'EN', mockFormType) {
    return getFetch(`${API_SERVERLESS}/backOffice/v3/formWithQuestions/${language}/${type}/${formId}`, false);
}

export function apiBackOfficeRemoveQuestion(jsonBody) {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/questionDelete`, true, jsonBody);
}

export function apiBackOfficeRemoveResponse(jsonBody) {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/responseDelete`, true, jsonBody);
}

export function apiBackOfficeGenerateJSONMapping(formId = '') {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/mappingsGenerate/${formId}`, true);
}
