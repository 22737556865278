import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';
import styles from './ModalLogicType.module.scss';

const ModalLogicTypeShowMeLinkToAFResponseQuestionHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.deprecated}>DEPRECATED</p>
                    <p>
                        This logic can be applied several times to a question; all the logics will then have to be
                        validated to carry out the processing.
                    </p>
                    <p>
                        This logic acts depending on the response given on an element of the application questionnaire;
                        depending on the value of the response, it will either display the question in the technical
                        part of the questionnaire or not. To identify the reference question, mapping codes are used;
                        they are what must be entered in the group/question/response areas. On the switch to the
                        technical questionnaire, the response value is tested and display of the technical questionnaire
                        is therefore activated.
                    </p>
                </>
            }
            footer={<Button clickAction={close}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeShowMeLinkToAFResponseQuestionHelp;
