import React from 'react';
import cx from 'classnames';
import {injectIntl} from 'react-intl';
import {apiCountries, apiExaminationOffices, apiGenus, apiPublications} from './MyPVRPublicationService';
import {apiSpecies} from '~commonApi/static';
import Label from '~components/Label';
import SelectInput from '~components/SelectInput';
import DateInput from '~components/DateInput';
import TextLabelInput from '~components/TextLabelInput';
import TextLabelInputVersion2 from '~components/TextLabelInputVersion2';
import Link from '~components/Link';
import InputLink from '~components/InputLink';
import {faCheck, faChevronDown, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import dateFormat from 'date-format';
import styles from './MyPVRPublication.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CustomTable from '~components/CustomTable';
import {withRouter} from 'react-router-dom';
import {FORMAT_DATE_EASY} from '~components/FormatFunctions';
import QualityQuantity from './components/QualityQuantity';
import DEFAULT_RESULT_FIELDS from './data/DEFAULT_RESULT_FIELDS.json';
import DEFAULT_RESULT_FIELDS_MODIFICATIONS_BETWEEN_DATES from './data/DEFAULT_RESULT_FIELDS_MODIFICATIONS_BETWEEN_DATES.json';
import RESULT_FIELDS_ALL from './data/RESULT_FIELDS_ALL.json';
import RESULT_FIELDS_ALL_MODIFICATIONS_BETWEEN_DATES from './data/RESULT_FIELDS_ALL_MODIFICATIONS_BETWEEN_DATES.json';
import ModalCategoryInfoVersion2 from './ModalCategoryInfoVersion2';
import {IPublicationItem} from '../../types';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogo,
    HeaderTitleAndVersion,
} from '../../componentsLayout';
import {Button} from '../../componentsFormV2';
import NavigationPublicSearch from '../../shared/NavigationPublicSearch';
import {trackPageView} from '../../utils';

const ALL = 'All';

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

type TKeyOfDefaultCriteria = keyof TDefaultCriteria;

const DEFAULT_CRITERIA = {
    speciesName: '',
    speciesIds: '',
    country: ALL,
    examinationOffice: ALL,
    genus: '',
    fileNumber: '',
    closingDateMonth: '',
    entrustment: '',
    dateFrom: '',
    dateTo: '',
};

const linksByCountryCode: any = {
    bg: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_bg.pdf',
    es: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_es.pdf',
    cs: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_cs.pdf',
    da: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_da.pdf',
    de: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_de.pdf',
    et: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_et.pdf',
    el: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_el.pdf',
    en: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_en.pdf',
    fr: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_fr.pdf',
    hr: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_hr.pdf',
    it: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_it.pdf',
    lv: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_lv.pdf',
    lt: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_lt.pdf',
    hu: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_hu.pdf',
    mt: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_mt.pdf',
    nl: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_nl.pdf',
    pl: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_pl.pdf',
    pt: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_pt.pdf',
    ro: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_ro.pdf',
    sk: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_sk.pdf',
    sl: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_sl.pdf',
    fi: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_fi.pdf',
    sv: 'https://cpvo.europa.eu/sites/default/files/documents/s2_s3_notice_2022_sv.pdf',
};

const monthOptions = [
    {id: '01', value: 'January'},
    {id: '02', value: 'February'},
    {id: '03', value: 'March'},
    {id: '04', value: 'April'},
    {id: '05', value: 'May'},
    {id: '06', value: 'June'},
    {id: '07', value: 'July'},
    {id: '08', value: 'August'},
    {id: '09', value: 'September'},
    {id: '10', value: 'October'},
    {id: '11', value: 'November'},
    {id: '12', value: 'December'},
];

const entrustmentOptions = [
    {id: '1', value: 'Current applicable data'},
    {id: '2', value: 'Modifications since 2 months'},
    {id: '3', value: 'Modifications between two dates'},
];

const generalInstructionsLinks = [
    {
        label: 'Ornamental crops',
        url: 'https://cpvo.europa.eu/en/applications-ornamental-crops-general-instructions-submission-samples',
    },
    {
        label: 'Fruit crops',
        url: 'https://cpvo.europa.eu/en/applications-fruit-crops-general-instructions-submission-samples',
    },
    {
        label: 'Vegetable crops',
        url: 'https://cpvo.europa.eu/en/about-us/what-we-do/reports/vegetable-crops-general-instructions-submission-samples',
    },
    {
        label: 'Agricultural crops',
        url: 'https://cpvo.europa.eu/en/about-us/what-we-do/reports/agricultural-crops-general-instructions-submission-samples',
    },
];
const headerLinks = [
    {
        label: 'Postponement of testing rules (OG n°01/2004)',
        url: 'https://cpvo.europa.eu/en/postponement-testing-rules',
    },
    {
        label: 'CPVO Policy on the Status of Plant Material Submitted for DUS Testing Purposes',
        url: 'https://cpvo.europa.eu/en/cpvo-policy-status-plant-material-used-dus-testing-purposes',
    },
    {
        label: 'Consolidated list of Invasive Alien Species and Regulation (EU) 1143/2014',
        url: 'https://cpvo.europa.eu/en/consolidated-list-invasive-alien-species-and-regulation-eu-11432014',
    },
];

const DEFAULT_COUNT = 0;

interface IProps {
    intl: any;
    history: any;
}

interface IState {
    loading: number;
    speciesNameFilter: string;
    selectedSpecies: any;
    offices: string[];
    countries: string[];
    publications: IPublicationItem[];
    groupHash: {[key: string]: string};
    timestamp: number;
    modalScreen: boolean;
    count: number;
    criteria: any;
    criteriaCount: number;
    informationOpened: boolean;
    screenLoaded: boolean;
    selectedPublications: string[];
    searchingStrustment: boolean;
    speciesListByIdName: {ID: any; NAME: string}[];
    speciesNameHash: {[key: string]: string[]};
    showTableModificationBetweenDates: boolean;
    genus: string;
    selectedGenus: any;
    genusListByIdName: {ID: any; NAME: string}[];
    genusNameHash: {[key: string]: string[]};
}

class MyPVRPublication extends React.Component<IProps, IState> {
    initialCriteria: any;
    lastSearchCriteria: any;

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: 0,
            speciesNameFilter: 'starts',
            selectedSpecies: {},
            offices: [],
            countries: [],
            publications: [],
            groupHash: {},
            timestamp: Date.now(),
            modalScreen: false,
            count: DEFAULT_COUNT,
            criteria: DEFAULT_CRITERIA,
            criteriaCount: 0,
            informationOpened: true,
            screenLoaded: false,
            selectedPublications: [],
            searchingStrustment: false,
            speciesListByIdName: [],
            speciesNameHash: {},
            showTableModificationBetweenDates: false,
            genus: '',
            selectedGenus: {},
            genusListByIdName: [],
            genusNameHash: {},
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'publication'});
        this.loadJSONs();
        this.loadUrlParams();
    }

    loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const criteria: any = Object.assign({}, this.initialCriteria);
            const params: any = domainArray.pop();
            params.split('&').forEach((param: any) => {
                const paramElements = param.split('=');
                if (paramElements.length === 2) {
                    const key = paramElements[0];
                    criteria[key] = decodeURIComponent(paramElements[1]);
                }
            });
            if (Object.keys(criteria).length > 0) {
                const selectedSpecies: any = {};
                const selectedGenus: any = {};
                if (criteria.speciesIds && criteria.speciesIds !== '') {
                    criteria.speciesIds.split(',').forEach((speciesId: any) => (selectedSpecies[speciesId] = false));
                }
                if (criteria.genus) {
                    criteria.genus.split(',').forEach((genus: any) => (selectedGenus[genus] = false));
                }
                const criteriaCount = this.countCriteria(criteria);
                this.setState({criteria, criteriaCount, selectedSpecies, selectedGenus}, () => this.search());
            }
        }
    };

    loadJSONs = () => {
        apiExaminationOffices().then(
            jsonResult => jsonResult && jsonResult.data && this.setState({offices: jsonResult.data})
        );
        apiCountries().then(jsonResult => jsonResult && jsonResult.data && this.setState({countries: jsonResult.data}));
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                Promise.all([apiSpecies(), apiGenus()])
                    .then(([jsonResponse, JSONResponseGenus]: any) => {
                        if (jsonResponse) {
                            const speciesNameHash: {[key: string]: string[]} = {};
                            (jsonResponse || []).forEach((speciesElement: any) => {
                                if (!speciesNameHash[speciesElement.NAME]) {
                                    speciesNameHash[speciesElement.NAME] = [];
                                }
                                speciesNameHash[speciesElement.NAME].push(speciesElement.ID);
                            });
                            const speciesListByIdName = Object.keys(speciesNameHash).map(speciesName => ({
                                ID: speciesNameHash[speciesName].join(','),
                                NAME: speciesName,
                            }));
                            const selectedSpecies: any = {};
                            const selectedSpeciesKeyArray = Object.keys(this.state.selectedSpecies || {});
                            speciesListByIdName
                                .filter(i => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                                .forEach(item => (selectedSpecies[item.ID] = item.NAME));
                            this.setState({
                                speciesListByIdName,
                                speciesNameHash,
                                selectedSpecies,
                                timestamp: Date.now(),
                            });
                        }
                        if (JSONResponseGenus) {
                            const genusNameHash: {[key: string]: string[]} = {};
                            (JSONResponseGenus || []).forEach((genusElement: any) => {
                                if (!genusNameHash[genusElement]) {
                                    genusNameHash[genusElement] = [];
                                }
                                genusNameHash[genusElement].push(genusElement);
                            });
                            const genusListByIdName = Object.keys(genusNameHash).map(genusName => ({
                                ID: genusNameHash[genusName].join(','),
                                NAME: genusName,
                            }));
                            const selectedGenus: any = {};
                            const selectedGenusKeyArray = Object.keys(this.state.selectedGenus || {});
                            genusListByIdName
                                .filter(i => selectedGenusKeyArray.indexOf(i.ID) !== -1)
                                .forEach(item => (selectedGenus[item.ID] = item.NAME));
                            this.setState({
                                genusListByIdName,
                                genusNameHash,
                                selectedGenus,
                                timestamp: Date.now(),
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`FormLabelInputSpecies: ${error.message}`]);
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    showSearchModalInfo = () => this.setState({modalScreen: true});

    closeModal = () => this.setState({modalScreen: false});

    parseCriteria = (criteria: TDefaultCriteria): TDefaultCriteria => {
        const parsedCriteria: any = {};
        Object.keys(criteria).map(key => {
            if ((key === 'dateFrom' || key === 'dateTo') && criteria[key] !== '') {
                const date = criteria[key];
                parsedCriteria[key] = dateFormat(
                    'yyyy-MM-dd',
                    dateFormat.parse('yyyy/MM/dd', date.split('/').reverse().join('/'))
                );
            } else if (key === 'speciesNameFilter' && !criteria['speciesName']) {
                criteria[key as TKeyOfDefaultCriteria] = DEFAULT_CRITERIA[key as TKeyOfDefaultCriteria];
            } else {
                parsedCriteria[key] = criteria[key as TKeyOfDefaultCriteria];
            }
        });
        return parsedCriteria;
    };

    getEntrustmentRowClass = (rowObject: IPublicationItem) => {
        if (this.state.searchingStrustment) {
            switch (rowObject.dmlType) {
                case 'INS':
                    return styles.labelGreen;
                case 'DEL':
                    return styles.labelRed;
                case 'UPD':
                    return styles.labelYellow;
            }
        } else {
            return null;
        }
    };

    search = () => {
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                informationOpened: false,
                searchingStrustment: prev.criteria.entrustment === '2' || prev.criteria.entrustment === '3',
            }),
            () => {
                // if (saveSearch) {
                //     this.lastSearchCriteria = {dateTime: Date.now(), criteria: this.state.criteria};
                //     localStorage.setItem(
                //         `publicSearchLastSearch${
                //             this.props.auth && this.props.auth.user ? `-${this.props.auth.user.id}` : ''
                //         }`,
                //         JSON.stringify(this.lastSearchCriteria)
                //     );
                // }
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams();
                apiPublications(parsedCriteria, DEFAULT_CRITERIA)
                    .then((jsonResponse: any) => {
                        if (jsonResponse && jsonResponse.data) {
                            const hash: any = {};
                            (jsonResponse.data || []).forEach((row: any) => {
                                if (row.speciesId) {
                                    hash[row.speciesId] = row.speciesName;
                                }
                            });
                            this.setState({
                                publications: jsonResponse.data,
                                groupHash: hash,
                                count: Object.keys(hash).length,
                                screenLoaded: true,
                                timestamp: Date.now(),
                                showTableModificationBetweenDates:
                                    parsedCriteria.entrustment === '2' || parsedCriteria.entrustment === '3',
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`Register search list error: ${error.message}`]);
                    })
                    .then(() => {
                        this.setState(prev => ({loading: prev.loading - 1}));
                    });
            }
        );
    };

    buildParams = (criteria?: any) => {
        const paramArray = Object.keys(criteria || {})
            .filter(i => criteria[i] !== DEFAULT_CRITERIA[i as TKeyOfDefaultCriteria])
            .map(key => `${key}=${criteria[key]}`);
        this.props.history.replace(`/publication${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`);
    };

    toggleInformation = () => this.setState(prev => ({informationOpened: !prev.informationOpened}));

    countCriteria = (criteria: TDefaultCriteria): number => {
        let count = 0;
        Object.keys(criteria).map(key => {
            if (
                criteria[key as TKeyOfDefaultCriteria] &&
                criteria[key as TKeyOfDefaultCriteria] !== DEFAULT_CRITERIA[key as TKeyOfDefaultCriteria] &&
                criteria[key as TKeyOfDefaultCriteria] !== ''
            )
                count++;
        });
        return count;
    };

    resetCriteria = () =>
        this.setState({
            publications: [],
            selectedSpecies: {},
            criteria: DEFAULT_CRITERIA,
            criteriaCount: 0,
            selectedGenus: {},
            genus: '',
        });

    updateCriteriaValue = (criteriaValue: any, callback?: any) => {
        this.setState(prev => {
            const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
            const criteriaCount = this.countCriteria(criteria);
            return {criteria, criteriaCount};
        }, callback);
    };

    goToS2Gazette = () =>
        window.open('https://cpvo.europa.eu/en/applications-and-examinations/official-publications', '_blank');

    goToEntrustmentRequirements = () =>
        window.open('https://cpvo.europa.eu/sites/default/files/documents/qas/Entrustment_Requirements.pdf', '_blank');

    goToListOfFeeGroups = () =>
        window.open('https://cpvo.europa.eu/en/applications-and-examinations/fees-and-payments', '_blank');

    goToCountryCodeLinkAddress = (countryCode: string) =>
        linksByCountryCode[countryCode] && window.open(linksByCountryCode[countryCode], '_blank');

    goToLinkAddress = (url: string) => url && window.open(url, '_blank');

    onSpeciesSelectionChange = (selectedSpecies: {[key: string]: string}) =>
        this.updateCriteriaValue({speciesIds: Object.keys(selectedSpecies).join(','), speciesName: ''}, () =>
            this.setState({selectedSpecies})
        );

    onSpeciesNameChange = (speciesName: any) => this.updateCriteriaValue({speciesName});

    onSpeciesNameFilterChange = ({target: {value: speciesNameFilter}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({speciesNameFilter});

    onGenusSelectionChange = (selectedGenus: any) =>
        this.updateCriteriaValue({genus: Object.keys(selectedGenus).join(',')}, () =>
            this.setState({selectedGenus, genus: ''})
        );

    onGenusChange = (genus: any) => this.setState({genus});

    onGenusInputBlur = () => {
        if (Object.keys(this.state.selectedGenus || {}).length === 0) {
            this.setState({genus: ''});
        }
    };

    onPublicationCountryChange = ({target: {value: country}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({country});

    onExaminationOfficeChange = ({target: {value: examinationOffice}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({examinationOffice});

    onSelectInputGenusChange = ({target: {value: genus}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({genus});

    onClosingDateMonthChange = (monthValue: any) => {
        const monthArray =
            (this.state.criteria &&
                this.state.criteria.closingDateMonth &&
                this.state.criteria.closingDateMonth !== '' &&
                this.state.criteria.closingDateMonth.split(',')) ||
            [];
        const index = monthArray.indexOf(monthValue);
        if (index === -1) {
            monthArray.push(monthValue);
        } else {
            monthArray.splice(index, 1);
        }
        this.updateCriteriaValue({closingDateMonth: monthArray.join(',')});
    };

    onEntrustmentChange = ({target: {value: entrustment}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue(Object.assign({}, {entrustment}, entrustment !== '3' && {dateFrom: '', dateTo: ''}));

    onDateFromChange = (dateFrom: any) => this.updateCriteriaValue({dateFrom});

    onDateToChange = (dateTo: any) => this.updateCriteriaValue({dateTo});

    onSelectedPublicationsChange = (selectedPublications: string[]) => this.setState({selectedPublications});

    renderLink(headerLink: any) {
        const translationLength = Object.keys(headerLink.translations || []).length;
        return (
            <div style={{padding: 2}}>
                <Link
                    width={250}
                    label={headerLink.label}
                    clickAction={() => this.goToLinkAddress(headerLink.url)}
                    rel="noopener noreferrer"
                />
                {translationLength > 1 ? (
                    <React.Fragment>
                        {': '}
                        {Object.keys(headerLink.translations || {}).map((key, index) => {
                            const translationLink = headerLink.translations[key];
                            return (
                                <React.Fragment key={key}>
                                    <a href={translationLink} rel="noopener noreferrer" target="_blank">
                                        {key.toUpperCase()}
                                    </a>
                                    {`${index < translationLength - 1 ? ' ' : ''}`}
                                </React.Fragment>
                            );
                        })}
                    </React.Fragment>
                ) : null}
            </div>
        );
    }

    renderTableTopCaption = () => {
        return (
            <>
                {this.state.searchingStrustment && (
                    <div className={styles.captionContainer}>
                        <div className={cx(styles.label, styles.labelYellow)}>{`Species data updated`}</div>
                        <div className={cx(styles.label, styles.labelGreen)}>{`Species added to a contract`}</div>
                        <div className={cx(styles.label, styles.labelRed)}>{`Species deleted from a contract`}</div>
                    </div>
                )}
                <Label width={160}>{`Explanatory note: `}</Label>
                {Object.keys(linksByCountryCode).map((countryCode: any) => (
                    <React.Fragment key={countryCode}>
                        <InputLink
                            width={14}
                            label={countryCode}
                            clickAction={() => this.goToCountryCodeLinkAddress(countryCode)}
                            disabled={!linksByCountryCode[countryCode]}
                        />
                    </React.Fragment>
                ))}
                <div style={{clear: 'both'}} />
            </>
        );
    };

    render() {
        const countryOption = this.state.countries
            .map((countryId: any) => ({
                id: countryId,
                value: this.props.intl.formatMessage({id: `publication.country.${countryId}`}),
            }))
            .sort((a: any, b: any) => (a.value > b.value ? 1 : a.value < b.value ? -1 : 0));

        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                {this.state.modalScreen ? <ModalCategoryInfoVersion2 close={this.closeModal} /> : null}
                <HeaderLogo />
                <HeaderTitleAndVersion
                    title={'Plant material submission to Entrusted Examination Offices S2/S3 Publication'}
                />
                <NavigationPublicSearch />
                <FormWrapper paddingFormContent={'sm'}>
                    <InputLink
                        label={`Information`}
                        icon={this.state.informationOpened ? faChevronDown : faChevronRight}
                        clickAction={this.toggleInformation}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    {this.state.informationOpened && (
                        <>
                            <div style={{textAlign: 'left', marginBottom: 20, padding: 5}}>
                                The purpose of the S2/S3 Publication is to provide applicants with a consolidated
                                version of the closing dates for applications and the submission requirements for plant
                                material per species and according to the examination offices which are currently
                                entrusted to carry out the DUS technical examination on behalf of the CPVO.
                            </div>
                            <div style={{textAlign: 'left', marginBottom: 20, padding: 5}}>
                                For species without an entrusted examination office in the EU, the publication contains
                                information about cooperating authorities in third countries.
                            </div>
                            <div style={{textAlign: 'left', marginBottom: 20, padding: 5}}>
                                The S2/S3 Publication also provides information on the Period of Grace to comply with
                                the requirements set out in Article 10 (1)(b) of Council Regulation (EC) 2100/94
                                (Novelty) as well as on the Duration of Community plant variety rights pursuant to
                                Article 19 (1),(2) of said Regulation. This information is provided in the column
                                “Category” and encoded by a letter A, B, C.
                            </div>
                            <div style={{textAlign: 'left', marginBottom: 20, padding: 5}}>
                                Please note that the content of the publication is updated on a daily basis. A search
                                tool is available to identify modifications made since 15 August 2020. In addition, once
                                per year (mid-February), the content of the publication is extracted and published in a
                                pdf on the website of the CPVO.
                            </div>
                            <div style={{textAlign: 'left', marginBottom: 20, padding: 5}}>
                                It is reminded that a genus entrustment does not stand for all species of the genus but
                                for interspecific hybrids of unknown species.
                            </div>
                            <div
                                style={{
                                    textAlign: 'left',
                                    fontWeight: 800,
                                    marginBottom: 20,
                                    padding: 5,
                                    background: '#e0e0e0',
                                    height: 'auto',
                                    color: '#2f923f',
                                }}
                            >
                                {headerLinks.map(headerLink => this.renderLink(headerLink))}
                                <div style={{clear: 'both'}} />
                            </div>
                        </>
                    )}
                    <div style={{fontWeight: 800}}>General Instructions on the submission of samples:</div>
                    <div style={{display: 'table', marginBottom: 20}}>
                        <div style={{display: 'table-row'}}>
                            <div style={{display: 'table-cell'}}>{this.renderLink(generalInstructionsLinks[0])}</div>
                            <div style={{display: 'table-cell'}}>{this.renderLink(generalInstructionsLinks[1])}</div>
                            <div style={{display: 'table-cell'}}>{this.renderLink(generalInstructionsLinks[2])}</div>
                            <div style={{display: 'table-cell'}}>{this.renderLink(generalInstructionsLinks[3])}</div>
                        </div>
                    </div>
                    <div style={{width: 770}}>
                        <TextLabelInputVersion2
                            filter={['starts', 'contains']}
                            currentFilter={this.state.criteria.speciesNameFilter}
                            onFilterChange={this.onSpeciesNameFilterChange}
                            double={true}
                            onSelectionChange={this.onSpeciesSelectionChange}
                            onChange={this.onSpeciesNameChange}
                            value={this.state.criteria.speciesName}
                            selectedElements={this.state.selectedSpecies}
                            delay={0}
                            noInfo={true}
                            multiple={true}
                            outsideLabel={'Species'}
                            outsideLabelWidth={160}
                            listByIdName={this.state.speciesListByIdName}
                            nameHash={this.state.speciesNameHash}
                        />
                        <TextLabelInput
                            double={true}
                            onSelectionChange={this.onGenusSelectionChange}
                            onChange={this.onGenusChange}
                            value={this.state.genus}
                            selectedElements={this.state.selectedGenus}
                            delay={300}
                            multiple={true}
                            noInfo={true}
                            outsideLabel={'Genus'}
                            outsideLabelWidth={160}
                            listByIdName={this.state.genusListByIdName}
                            nameHash={this.state.genusNameHash}
                            autoSelection={true}
                            onBlur={this.onGenusInputBlur}
                            uniqueValue={true}
                            placeholder={`Please type and select one genus`}
                        />
                        <div style={{clear: 'both'}} />
                        <Label width={160}>{`Examination office`}</Label>
                        <SelectInput
                            value={this.state.criteria.examinationOffice}
                            onChange={this.onExaminationOfficeChange}
                            list={this.state.offices}
                        />
                        <div style={{clear: 'both'}} />
                        <Label width={160}>{`Country`}</Label>
                        <SelectInput
                            value={this.state.criteria.country}
                            onChange={this.onPublicationCountryChange}
                            list={countryOption}
                        />
                        <div style={{clear: 'both'}} />
                        <Label width={160}>{`Closing date month`}</Label>
                        <div
                            style={{
                                position: 'relative',
                                float: 'left',
                                width: 625,
                                marginLeft: -30,
                                marginTop: -10,
                                marginBottom: 10,
                            }}
                        >
                            <ul>
                                {monthOptions.map(monthOption => {
                                    const active =
                                        (this.state.criteria.closingDateMonth || []).indexOf(monthOption.id) !== -1;
                                    return (
                                        <li
                                            key={monthOption.id}
                                            className={styles.listItem}
                                            onClick={() => this.onClosingDateMonthChange(monthOption.id)}
                                        >
                                            <div className={cx(styles.listItemValue, active && styles.listItemActive)}>
                                                {active && <FontAwesomeIcon icon={faCheck as any} />}
                                                {` ${monthOption.value}`}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div style={{clear: 'both'}} />
                        <Label width={160}>{`Modifications`}</Label>
                        <SelectInput
                            value={this.state.criteria.entrustment}
                            onChange={this.onEntrustmentChange}
                            list={entrustmentOptions}
                            notAll={true}
                        />
                        {this.state.criteria.entrustment === '3' ? (
                            <DateInput
                                changeDateFrom={this.onDateFromChange}
                                changeDateTo={this.onDateToChange}
                                inputValueFrom={this.state.criteria.dateFrom}
                                inputValueTo={this.state.criteria.dateTo}
                                disabled={this.state.criteria.entrustment !== '3'}
                                minDateFrom={new Date('15 August 2020 12:00:00 GMT')}
                                maxDateTo={new Date()}
                            />
                        ) : null}
                        <div style={{clear: 'both'}} />
                    </div>
                    <div style={{float: 'right'}}>
                        <InputLink width={150} label={`List of fee groups`} clickAction={this.goToListOfFeeGroups} />
                        <div style={{clear: 'both'}} />
                    </div>
                    <FormFooter>
                        <Button variation={'secondary'} clickAction={this.resetCriteria}>{`Clear fields`}</Button>
                        <Button clickAction={this.search}>{`Search`}</Button>
                    </FormFooter>
                    <div style={{clear: 'both'}} />
                </FormWrapper>
                {this.state.screenLoaded ? (
                    <div
                        className="form-group"
                        style={{
                            textAlign: 'left',
                            paddingLeft: 15,
                            paddingRight: 15,
                            overflowX: 'visible',
                            marginTop: 20,
                        }}
                    >
                        {this.state.showTableModificationBetweenDates ? (
                            <div>
                                <CustomTable
                                    version={2}
                                    group={{
                                        id: 'speciesId',
                                        label: 'speciesName',
                                        hash: Object.keys(this.state.groupHash),
                                    }}
                                    selectable={true}
                                    headerMargin={true}
                                    headerSizes={{
                                        foreSeenGrowingCycle: 75,
                                        closingDate: 65,
                                        submissionDateStart: 95,
                                        submissionDateEnd: 95,
                                        modificationDate: 95,
                                    }}
                                    onSelectedChange={this.onSelectedPublicationsChange}
                                    notSortable={RESULT_FIELDS_ALL_MODIFICATIONS_BETWEEN_DATES}
                                    tableName={'S2S3publicationsModificationsBetweenDates'}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.publications}
                                    timestamp={this.state.timestamp}
                                    dataFilter={null}
                                    id={'speciesId'}
                                    setLastCursor={null}
                                    resultFieldsAll={RESULT_FIELDS_ALL_MODIFICATIONS_BETWEEN_DATES}
                                    resultFieldsDefault={DEFAULT_RESULT_FIELDS_MODIFICATIONS_BETWEEN_DATES}
                                    intl={this.props.intl}
                                    defaultOrder={'registerId'}
                                    reverseOrder={false}
                                    filterFunctions={null}
                                    count={this.state.count}
                                    rowClass={this.getEntrustmentRowClass}
                                    formatFunctions={{
                                        qualityQuantity: (fieldData: any) => <QualityQuantity {...{fieldData}} />,
                                        startDateOfEntrustment: FORMAT_DATE_EASY,
                                        endDateOfEntrustment: FORMAT_DATE_EASY,
                                        modificationDate: FORMAT_DATE_EASY,
                                        tqb: (tqb: any) =>
                                            tqb ? <FontAwesomeIcon icon={faCheck as any} color={'green'} /> : null,
                                    }}
                                    excelFormatFunctions={{qualityQuantity: (fieldData: any) => fieldData}}
                                    headerPopup={{
                                        category: {
                                            description: 'Category',
                                            handler: this.showSearchModalInfo,
                                        },
                                    }}
                                    topCaption={this.renderTableTopCaption()}
                                />
                            </div>
                        ) : (
                            <CustomTable
                                version={2}
                                group={{
                                    id: 'speciesId',
                                    label: 'speciesName',
                                    hash: Object.keys(this.state.groupHash),
                                }}
                                selectable={true}
                                headerMargin={true}
                                headerSizes={{
                                    foreSeenGrowingCycle: 75,
                                    closingDate: 65,
                                    submissionDateStart: 95,
                                    submissionDateEnd: 95,
                                    modificationDate: 95,
                                }}
                                onSelectedChange={this.onSelectedPublicationsChange}
                                notSortable={RESULT_FIELDS_ALL}
                                tableName={'S2S3publications'}
                                tableType={'OBJECT'}
                                tableSource={this.state.publications}
                                timestamp={this.state.timestamp}
                                dataFilter={null}
                                id={'speciesId'}
                                setLastCursor={null}
                                resultFieldsAll={RESULT_FIELDS_ALL}
                                resultFieldsDefault={DEFAULT_RESULT_FIELDS}
                                intl={this.props.intl}
                                defaultOrder={'registerId'}
                                reverseOrder={false}
                                filterFunctions={null}
                                count={this.state.count}
                                rowClass={this.getEntrustmentRowClass}
                                formatFunctions={{
                                    qualityQuantity: (fieldData: any) => <QualityQuantity {...{fieldData}} />,
                                    startDateOfEntrustment: FORMAT_DATE_EASY,
                                    endDateOfEntrustment: FORMAT_DATE_EASY,
                                    modificationDate: FORMAT_DATE_EASY,
                                    tqb: (tqb: any) =>
                                        tqb ? <FontAwesomeIcon icon={faCheck as any} color={'green'} /> : null,
                                }}
                                excelFormatFunctions={{qualityQuantity: (fieldData: any) => fieldData}}
                                headerPopup={{
                                    category: {
                                        description: 'Category',
                                        handler: this.showSearchModalInfo,
                                    },
                                }}
                                topCaption={this.renderTableTopCaption()}
                            />
                        )}
                    </div>
                ) : null}
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(MyPVRPublication));
