import React, {useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter, RouteComponentProps} from 'react-router-dom';

// STYLES
import styles from './SubMenuTabs.module.scss';
import cx from 'classnames';

// PROPS
import {SubMenuTabsProps} from './props/SubMenuTabsProps';

// COMMON API
import {TLO_STATIC_DOCS_DATA_PATH} from '../../../commonApi';

const SubMenuTabs = (props: SubMenuTabsProps & RouteComponentProps) => {
    const [params, setParams] = useState<{[key: string]: string | undefined} | null>(null);

    const loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        const parameters: {[key: string]: string} = JSON.parse(JSON.stringify({}));
        if (domainArray.length > 1) {
            const paramsText = domainArray.pop();
            paramsText &&
                paramsText.split('&').forEach(param => {
                    const paramElements = param.split('=');
                    if (paramElements.length === 2) {
                        const key = paramElements[0];
                        parameters[key] = decodeURIComponent(paramElements[1]);
                    }
                });
            setParams({...params, ...parameters});
        }
    };

    useEffect(() => {
        loadUrlParams();
    }, []);

    const joinParams = (parameters: {[key: string]: string | undefined}) => {
        return Object.keys(parameters)
            .map(paramKey => `${paramKey}=${parameters[paramKey]}`)
            .join('&');
    };

    const goToTab = (menuLinkPath: string) => {
        if (!props.loading) {
            const parameters = Object.assign({}, params);
            if (menuLinkPath.includes('tlodocs/manual')) {
                const url = TLO_STATIC_DOCS_DATA_PATH + 'manual\\TLO_user_manual_new_revised_by_LDE.pdf';
                props.history.push(location.pathname);
                window.open(url, '_blank');
            } else {
                props.history.push(`/${menuLinkPath}/?${joinParams(parameters)}`);
            }
        }
    };

    const menuTabs = (menuLinks: Array<{id: string; value: string; path: string}>) => {
        let idx = 0;
        return menuLinks.map(menuLink => {
            return (
                <div
                    key={idx++}
                    className={cx(
                        props.menuLinks && props.menuLinks.length > 4 ? styles.menuItemFlex : styles.menuItem,
                        props.tabId === menuLink.id && styles.menuItemActive
                    )}
                    onClick={() => goToTab(menuLink.path)}
                >
                    <div className={styles.menuLabel}>{menuLink.value}</div>
                </div>
            );
        });
    };

    return (
        <React.Fragment>
            <div className={styles.header}>
                <div
                    className={props.menuLinks && props.menuLinks.length > 4 ? styles.leftPanelFlex : styles.leftPanel}
                >
                    {props.menuLinks && props.menuLinks.length > 0 && menuTabs(props.menuLinks)}
                    <div style={{clear: 'both'}} />
                </div>
                <div style={{clear: 'both'}} />
            </div>
        </React.Fragment>
    );
};

export default injectIntl(withRouter(SubMenuTabs));
