import React from 'react';
import CustomTable from '~components/CustomTable';
import CryptoJS from 'crypto-js';
import DragAndDrop from '~components/DragAndDrop';
import Error from '~components/Error';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import Help from '../common/Help';
import LoadingBar from '~components/LoadingBar';
import {MAX_UPLOAD_FILE_SIZE} from '../../../utils/constants';
import Remark from '../../../components/Remark';
import ResponseUploadActions from './ResponseUploadActions';
import ResponseWrapper, {IWizardResponseWrapperProps, IWizardResponseWrapperState} from './ResponseWrapper';
import SelectInput from '~components/SelectInput';
import TextAreaInput from '~components/TextAreaInput';
import {apiDocumentDownload, apiDocumentUpload} from './ResponseUploadService';
import cx from 'classnames';
import {injectIntl} from 'react-intl';
import {faPen, faPlus} from '@fortawesome/free-solid-svg-icons';
import {htmlToText, sanitize} from '../../../utils';
import getIcon from '~utils/icons';
import {getPreSignedURLFetchRequest} from '../../../utils/requests';
import {saveAs} from 'file-saver';
import styles from './ResponseUpload.module.scss';

interface IDocumentUploadItem {
    index: any;
    file?: any;
    filename: string;
    onlyPicturesFilename?: string;
    size?: number;
    extension: string;
    documentType?: string | null;
    onlyPicturesDocumentType?: string | null;
    documentRemark: string | null;
    onlyPicturesDocumentRemark?: string | null;
    documentIteration: number;
}

interface IState extends IWizardResponseWrapperState {
    documentList: IDocumentUploadItem[];
    documentIteration: number;
    error: string | boolean | null;
    loading: number;
    timestamp: number;
}

class ResponseUpload extends ResponseWrapper<IWizardResponseWrapperProps, IState> {
    pleaseChooseMessage = '';

    constructor(props: IWizardResponseWrapperProps) {
        super(props);
        this.pleaseChooseMessage = props.intl && props.intl.formatMessage({id: 'pleaseChoose'});
        this.state = {
            ...this.state,
            documentList: [],
            documentIteration: 0,
            error: null,
            loading: 0,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        this.updateComponentState();
    }

    componentDidUpdate(prevProps: IWizardResponseWrapperProps, prevState: IState) {
        if (prevProps.responsesClientHash !== this.props.responsesClientHash) {
            this.updateComponentState();
        }
    }

    updateComponentState = () => {
        let responsesClient = this.getCopyOfResponsesClient();
        let responseKeyWithIteration = this.getResponseKey();
        let responseKeys = Object.keys(responsesClient).filter(key =>
            key.includes(responseKeyWithIteration.slice(0, -1))
        );
        if (responseKeys.length > 0) {
            let maxIteration = Math.max(...responseKeys.map(el => parseInt(el.split('_').pop() || '0')));
            let documentList: IDocumentUploadItem[] = [];
            Array(maxIteration || 0)
                .fill(true)
                .forEach((_, index) => {
                    const responseKey = `${responseKeyWithIteration.slice(0, -1)}${index + 1}`;
                    if (responsesClient[responseKey]) {
                        const documentIndex = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(responsesClient[responseKey]));
                        const documentListItem = {
                            index: documentIndex,
                            filename: responsesClient[responseKey],
                            onlyPicturesFilename: responsesClient[responseKey],
                            extension: (responsesClient[responseKey] || '.').split('.')[1],
                            documentType: responsesClient[`upl${responseKey}`],
                            onlyPicturesDocumentType: responsesClient[`upl${responseKey}`],
                            documentRemark: responsesClient[`ra${responseKey}`],
                            onlyPicturesDocumentRemark: responsesClient[`ra${responseKey}`],
                            documentIteration: index + 1,
                        };
                        documentList.push(documentListItem);
                    }
                });
            this.setState({
                documentList,
                timestamp: Date.now(),
                error: null,
                documentIteration: maxIteration,
            });
        } else {
            this.setState(prev => ({
                documentList: [],
                documentIteration: 0,
                error: null,
                timestamp: Date.now(),
            }));
        }
    };

    getFileNameToUpload = (fileTypeLowercase: string): string => {
        const randomString = (length: number) =>
            [...Array(length)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
        return `${this.props.applicationCode || `applicationCode`}_${randomString(13)}.${fileTypeLowercase}`;
    };

    onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        file && this.checkFile(file, event);
    };

    checkFile = (file: File, event: any) => {
        LOG([`file.type:${file.type}`]);
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = (fileType || '').toLowerCase();
        const filename = sanitize(file.name, '_', /[^0-9a-zA-Z! _\\.\\*\\(\\)\\\-/]/g);

        this.setState({error: null}, () => {
            const uniqueFileNameForUpload = this.getFileNameToUpload(fileTypeLowercase);
            if (file.size > MAX_UPLOAD_FILE_SIZE) {
                return this.setState({
                    error: this.props.intl.formatMessage({
                        id: 'general.theMaximumDocumentSizeForUploadsIs10MBPleaseSelectAProperFile',
                    }),
                });
            }
            if (
                fileTypeLowercase === 'jpeg' ||
                fileTypeLowercase === 'png' ||
                fileTypeLowercase === 'jpg' ||
                fileTypeLowercase === 'pdf'
            ) {
                const documentTypeOptions = this.getDocumentTypeOptions();
                const documentType =
                    documentTypeOptions && (documentTypeOptions || []).length === 2 ? documentTypeOptions[1].id : null;
                this.setState(
                    prev => ({loading: prev.loading + 1}),
                    () => {
                        apiDocumentUpload({
                            document: {file, filename: uniqueFileNameForUpload},
                            applicationRequestCode: this.props.applicationCode || `applicationCode`,
                        })
                            .then(JSONResponse => {
                                if (JSONResponse && JSONResponse.data === 'OK') {
                                    const a = new FileReader();
                                    a.readAsBinaryString(file);
                                    a.onloadend = () => {
                                        const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(a.result));
                                        this.setState(
                                            prev => {
                                                const documentList = prev.documentList.slice(0);
                                                documentList.push({
                                                    index,
                                                    file,
                                                    filename: uniqueFileNameForUpload,
                                                    onlyPicturesFilename: uniqueFileNameForUpload,
                                                    size: file.size / 1024,
                                                    extension: fileTypeLowercase,
                                                    documentType,
                                                    onlyPicturesDocumentType: documentType,
                                                    documentRemark: filename,
                                                    onlyPicturesDocumentRemark: filename,
                                                    documentIteration: prev.documentIteration + 1,
                                                });
                                                return {
                                                    documentList,
                                                    timestamp: Date.now(),
                                                    error: null,
                                                    documentIteration: prev.documentIteration + 1,
                                                    loading: prev.loading - 1,
                                                };
                                            },
                                            () => {
                                                event.target.value = null;
                                            }
                                        );
                                        let updatedResponsesClient = this.getCopyOfResponsesClient();
                                        let responseKey = this.getResponseKey(); //GR123_1_Q20382_1_R43835_1
                                        responseKey = `${responseKey.slice(0, -1)}${this.state.documentIteration + 1}`;
                                        // GR123_1_Q20382_1_R43835_1: download.png
                                        updatedResponsesClient[responseKey] = uniqueFileNameForUpload;
                                        updatedResponsesClient[`ra${responseKey}`] = filename;
                                        updatedResponsesClient[`upl${responseKey}`] = documentType;
                                        this.updateResponsesClient(updatedResponsesClient);
                                    };
                                } else {
                                    this.setState({
                                        loading: 0,
                                    });
                                }
                            })
                            .catch(() => this.setState({loading: 0}));
                    }
                );
            } else {
                this.setState({error: 'type not valid'}, () => (event.target.value = null));
            }
        });
    };

    onSelectDocumentType = ({target: {value}}: React.ChangeEvent<HTMLSelectElement>, object: IDocumentUploadItem) => {
        const {index, documentIteration} = object;
        // uplGR123_1_Q20382_1_R43835_2: 26
        const documentType = value !== this.pleaseChooseMessage ? value : null;
        this.setState(prev => ({
            documentList: prev.documentList.slice(0).map(document =>
                document.index === index
                    ? Object.assign({}, document, {
                          documentType,
                          onlyPicturesDocumentType: documentType,
                      })
                    : document
            ),
            timestamp: Date.now(),
        }));
        let responseKey = this.getResponseKey();
        let uploadKey = `upl${responseKey.slice(0, -1)}${documentIteration}`;
        let updatedResponsesClient = this.getCopyOfResponsesClient();
        updatedResponsesClient[uploadKey] = documentType;
        this.updateResponsesClient(updatedResponsesClient);
    };

    onDocumentRemarkChange = (event: any, index: any, object: IDocumentUploadItem) => {
        const documentRemark = event.target.value;
        const {documentIteration} = object;
        // raGR123_1_Q20382_1_R43835_1: remark 00 2
        let responseKey = this.getResponseKey(); //GR123_1_Q20382_1_R43835_1
        let remarkKey = `ra${responseKey.slice(0, -1)}${documentIteration}`;
        let updatedResponsesClient = this.getCopyOfResponsesClient();
        updatedResponsesClient[remarkKey] = documentRemark;
        this.updateResponsesClient(updatedResponsesClient);
        this.setState(prev => ({
            timestamp: Date.now(),
            documentList: prev.documentList.slice(0).map(document =>
                document.index === index
                    ? Object.assign({}, document, {
                          documentRemark,
                          onlyPicturesDocumentRemark: documentRemark,
                      })
                    : document
            ),
        }));
    };

    deleteFile = (rowObject: IDocumentUploadItem) => {
        const {index, documentIteration} = rowObject || {};
        // delete and reindex responsesClient
        let responseKey = this.getResponseKey(); //GR123_1_Q20382_1_R43835_1
        let tableResponseKey = `${responseKey.slice(0, -1)}${documentIteration}`;
        let remarkKey = `ra${tableResponseKey}`;
        let uplKey = `upl${tableResponseKey}`;
        let updatedResponsesClient = this.getCopyOfResponsesClient();
        delete updatedResponsesClient[tableResponseKey];
        delete updatedResponsesClient[remarkKey];
        delete updatedResponsesClient[uplKey];

        Object.keys(updatedResponsesClient).forEach(key => {
            if (key.includes(this.props.questionKeyWithIteration)) {
                let currentKeyIteration = parseInt(key.slice(-1));
                if (currentKeyIteration > documentIteration) {
                    let tempValue = updatedResponsesClient[key];
                    let newKey = `${key.slice(0, -1)}${parseInt(String((key as any).slice(-1) - 1))}`;
                    delete updatedResponsesClient[key];
                    updatedResponsesClient[newKey] = tempValue;
                }
            }
        });
        this.updateResponsesClient(updatedResponsesClient);
        // update state
        this.setState(prev => {
            let newDocumentList = prev.documentList.filter(i => i.index !== index);
            newDocumentList.forEach((doc, i) => {
                if (doc.documentIteration > documentIteration) {
                    newDocumentList[i].documentIteration = newDocumentList[i].documentIteration - 1;
                }
            });
            return {
                documentList: newDocumentList,
                documentIteration: prev.documentIteration - 1,
                timestamp: Date.now(),
            };
        });
    };

    getDocumentTypeOptions = () => {
        const {response} = this.props;
        const {logics} = response || {};
        if (logics && logics.length > 0) {
            if (logics.some(({type}) => type === 'onlypictures')) {
                return [
                    {id: this.pleaseChooseMessage, value: this.pleaseChooseMessage},
                    {id: 26, value: 'Photo'},
                ];
            }
            let {logicParam} = logics[logics.length - 1] || {};
            if ((logicParam || '').includes('filter')) {
                //filter:21,23; --> ['21', '23']
                let filterNumsArray = ((logicParam || '').split(':').pop() || '').replace(';', '').split(',');
                const filteredUploadTypes = (
                    (this.props.assistedEntries && this.props.assistedEntries.uploadTypes) ||
                    []
                ).filter((el: any) => filterNumsArray.map(el => parseInt(el)).includes(parseInt(el.ID)));
                return [
                    {id: this.pleaseChooseMessage, value: this.pleaseChooseMessage},
                    ...filteredUploadTypes.map((el: any) => ({
                        id: el.ID,
                        value: htmlToText(el.LABEL),
                    })),
                ];
            }
        } else {
            return [
                {id: this.pleaseChooseMessage, value: this.pleaseChooseMessage},
                ...((this.props.assistedEntries && this.props.assistedEntries.uploadTypes) || []).map((el: any) => ({
                    id: el.ID,
                    value: htmlToText(el.LABEL),
                })),
            ];
        }
    };

    downloadFile = (filename: string) =>
        apiDocumentDownload({filename, applicationRequestCode: this.props.applicationCode || `applicationCode`}).then(
            response => {
                if (response && response.signedUrl) {
                    const {signedUrl} = response;
                    getPreSignedURLFetchRequest(signedUrl)
                        .then(response => response.blob())
                        .then(responseBlob => {
                            saveAs(responseBlob, filename);
                        });
                }
            }
        );

    renderRemedyIcon = () => {
        const remedyType = this.getRemedyType();
        return (
            <div
                className={cx(
                    styles.remedyIconWrap,
                    styles[this.props.question.type],
                    remedyType ? styles[remedyType] : null
                )}
                onClick={this.onButtonAddResponseToRemedyClick}
                id={`REMEDY_${this.getResponseKey()}`}
            >
                <FontAwesomeIcon
                    icon={(this.checkIfRemedyResponseExists() ? faPen : faPlus) as any}
                    color={'white'}
                    width={16}
                    height={10}
                />
            </div>
        );
    };

    renderPDFVersion = () => {
        const {messageRemark} = this.props.response || {};
        if (this.state.documentList && this.state.documentList.length) {
            const documentTypeOptions = this.getDocumentTypeOptions();
            return (
                <>
                    {super.render()}
                    <table className={styles.pdfDocumentsTable}>
                        <tr>
                            <th>Document</th>
                            <th>Type</th>
                            <th>Remark</th>
                        </tr>
                        {this.state.documentList.map(({filename, documentType, documentRemark}) => {
                            let documentTypeValue = (
                                (documentTypeOptions || []).find(
                                    el => parseInt(el.id) === parseInt(String(documentType))
                                ) || {}
                            ).value;
                            return (
                                <tr key={filename}>
                                    <td>
                                        {filename}
                                        {this.props.isRemedyStarted && this.renderRemedyIcon()}
                                    </td>
                                    <td>
                                        {htmlToText(documentTypeValue)}
                                        {this.props.isRemedyStarted && this.renderRemedyIcon()}
                                    </td>
                                    <td>
                                        {documentRemark}
                                        {this.props.isRemedyStarted && this.renderRemedyIcon()}
                                    </td>
                                </tr>
                            );
                        })}
                    </table>
                </>
            );
        }
        if ((window as any).elements) {
            let element: any = {
                type: 'RESPONSE_UPLOAD',
                label: `Documents to be attached`,
                order: this.getResponseOrderForPDF(),
                questionType: this.props.question.type,
            };
            messageRemark && (element.remark = messageRemark);
            let elementExist = (window as any).elements.find((el: any) => el.order === element.order);
            if (!elementExist) {
                (window as any).elements.push(element);
            }
        }

        return (
            <>
                {super.render()}
                {this.props.isRemedyStarted && this.renderRemedyIcon()}
                <div className={styles.pdfResponseWrap}>
                    <div className={styles.pdfResponseWrapLeft}>{`Documents to be attached`}</div>
                    <div className={styles.pdfResponseWrapRight} />
                </div>
            </>
        );
    };

    handleDrop = (files: File[], event: any) =>
        this.setState({error: false}, () => ([...files] || []).forEach(file => this.checkFile(file, event)));

    formatFunctionDocumentType = (documentType: any, object: IDocumentUploadItem) => {
        const documentTypeOptions = this.getDocumentTypeOptions();
        return (
            <div style={{marginTop: '-5px'}}>
                <SelectInput
                    value={documentType ? documentType : this.pleaseChooseMessage}
                    onChange={(event: any) => this.onSelectDocumentType(event, object)}
                    list={documentTypeOptions}
                    notAll={true}
                    height={25}
                    width={150}
                    disabled={(documentTypeOptions || []).length === 2}
                />
                <div style={{clear: 'both'}} />
            </div>
        );
    };

    formatFunctionDocumentRemark = (documentRemark: any, object: IDocumentUploadItem) => {
        return (
            <div style={{marginTop: '-5px'}}>
                <TextAreaInput
                    value={documentRemark}
                    onChange={(event: any) => this.onDocumentRemarkChange(event, object.index, object)}
                    rows={2}
                />
                <div style={{clear: 'both'}} />
            </div>
        );
    };

    render() {
        const {response} = this.props;
        const {messageRemark, messageRemarkIsDef, logics} = response || {};
        const actions = ResponseUploadActions(this.deleteFile);
        const hasLogicOnlyPictures = logics.some(({type}) => type === 'onlypictures');
        const isButtonAddDocumentDisabled = hasLogicOnlyPictures && this.state.documentList.length === 5;

        if (this.props.PDFVersion) {
            return this.renderPDFVersion();
        }

        return (
            <DragAndDrop handleDrop={this.handleDrop}>
                <div className={styles.responseUploadWrap}>
                    <Help
                        message={response.messageHelp}
                        language={this.props.questionnaireLanguage}
                        className="questionHelp"
                    />
                    <div style={{display: 'inline-block', maxWidth: 'calc(100% - 45px)'}}>
                        {hasLogicOnlyPictures ? null : (
                            <h4 className={styles.responseUploadTitle} style={{marginTop: 0}}>
                                {this.props.intl.formatMessage({id: 'uploadTemplate.label'})}
                            </h4>
                        )}
                        {messageRemark && (
                            <div style={{marginTop: -20}}>
                                <Remark
                                    remark={messageRemark}
                                    isRemarkTranslationMissing={parseInt(messageRemarkIsDef) === 1}
                                />
                            </div>
                        )}
                        <div className={styles.responseUploadAddDocumentWrap} style={{float: 'left'}}>
                            <input
                                type="file"
                                name="files[]"
                                id={`upload-${this.getResponseKey()}`}
                                style={{opacity: 0, width: 0}}
                                onChange={this.onFileUpload}
                                accept={'image/jpg, image/jpeg, image/png, application/pdf'}
                                disabled={isButtonAddDocumentDisabled}
                            />
                            <label htmlFor={`upload-${this.getResponseKey()}`}>
                                <FormFooterButton
                                    color={buttonColor.blue}
                                    icon={faPlus}
                                    extraStyles={styles.addDocumentButton}
                                    disabled={isButtonAddDocumentDisabled}
                                >
                                    {hasLogicOnlyPictures
                                        ? `Add pictures (5 maximum)`
                                        : this.props.intl.formatMessage({id: 'uploadTemplate.addDocument'})}
                                </FormFooterButton>
                            </label>
                        </div>
                        <div style={{clear: 'both'}} />
                        <div className={styles.documentListWrap}>
                            <h4>
                                {hasLogicOnlyPictures
                                    ? `Pictures list`
                                    : this.props.intl.formatMessage({id: 'uploadTemplate.documentsList'})}
                            </h4>
                            {this.state.documentList.length === 0 && (
                                <div>
                                    {hasLogicOnlyPictures
                                        ? `No picture selected`
                                        : this.props.intl.formatMessage({id: 'uploadTemplate.noDocument'})}
                                </div>
                            )}
                            {this.state.documentList.length > 0 && (
                                <CustomTable
                                    version={1}
                                    {...this.props}
                                    notSortable={
                                        hasLogicOnlyPictures
                                            ? [
                                                  'onlyPicturesFilename',
                                                  'onlyPicturesDocumentType',
                                                  'onlyPicturesDocumentRemark',
                                              ]
                                            : ['filename', 'documentType', 'documentRemark']
                                    }
                                    tableName={'documentUpload'}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.documentList || []}
                                    dataFilter={null}
                                    id={'index'}
                                    setLastCursor={null}
                                    resultFieldsDefault={
                                        hasLogicOnlyPictures
                                            ? [
                                                  'onlyPicturesFilename',
                                                  'onlyPicturesDocumentType',
                                                  'onlyPicturesDocumentRemark',
                                              ]
                                            : ['filename', 'documentType', 'documentRemark']
                                    }
                                    intl={this.props.intl}
                                    formatFunctions={{
                                        documentType: this.formatFunctionDocumentType,
                                        onlyPicturesDocumentType: this.formatFunctionDocumentType,
                                        documentRemark: this.formatFunctionDocumentRemark,
                                        onlyPicturesDocumentRemark: this.formatFunctionDocumentRemark,
                                    }}
                                    count={(this.state.documentList || []).length}
                                    hideExcelButton={true}
                                    noChangePageSize={true}
                                    forehandColumn={(row: any) => (
                                        <img
                                            style={{width: 20, height: 20, cursor: 'pointer'}}
                                            alt={'Download'}
                                            src={getIcon(row.extension || 'pdf')}
                                            onClick={event => {
                                                event.stopPropagation();
                                                this.downloadFile(row.filename);
                                            }}
                                        />
                                    )}
                                    timestamp={this.state.timestamp}
                                    actions={actions}
                                    unlimited={true}
                                />
                            )}
                            <div style={{clear: 'both'}} />
                            {this.state.error && (
                                <div style={{marginBottom: 10, textAlign: 'center'}}>
                                    <Error>{this.state.error}</Error>
                                </div>
                            )}
                        </div>
                    </div>
                    {this.state.loading !== 0 && (
                        <div className={styles.loadingBarWrap}>
                            <LoadingBar />
                        </div>
                    )}
                </div>
            </DragAndDrop>
        );
    }
}

export default injectIntl(ResponseUpload);
