import React from 'react';
import styles from './HeaderLoading.module.scss';

interface IHeaderLoadingProps {
    message?: string;
}

function HeaderLoading({message}: IHeaderLoadingProps) {
    return (
        <section className={styles.section}>
            <div className={styles.label}>{message || 'Loading. Please wait ...'}</div>
            <div className={styles.barWrap}>
                <div className={styles.bar} />
            </div>
        </section>
    );
}

export default HeaderLoading;
