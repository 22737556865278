import React, {useState} from 'react';
import Navigation from '../componentsLayout/Navigation';
import {ModalContactUsVersion2} from '../commonModals';

const sendToList = [
    {
        label: 'PVR Case Law Contact point',
        email: 3,
    },
];

interface INavigationPVRCaseLawProps {
    activeTitle?: string;
}

function NavigationPVRCaseLaw({activeTitle}: INavigationPVRCaseLawProps) {
    const [isModalContactUsOpen, setIsModalContactUsOpen] = useState(false);

    return (
        <>
            {isModalContactUsOpen ? (
                <ModalContactUsVersion2
                    additionalSendToList={sendToList}
                    subjectTag={'[PVRCASELAW]'}
                    close={() => setIsModalContactUsOpen(false)}
                />
            ) : null}
            <Navigation
                activeTitle={activeTitle}
                links={[
                    {to: '/pvrcaselaw', title: 'Dashboard'},
                    {
                        to: '/pvrcaselawSearchCasesBasicSearch',
                        title: 'Search Cases',
                    },
                    {
                        to: '/pvrcaselawAcknowledgements',
                        title: 'Acknowledgements',
                    },
                    {
                        to: '/pvrcaselawFaq',
                        title: 'FAQs',
                    },
                ]}
                linkModals={[{onClick: () => setIsModalContactUsOpen(true), title: 'Contact us'}]}
            />
        </>
    );
}

export default NavigationPVRCaseLaw;
