import {checkClientCode, checkEmail} from '../../../utils';
import {VALIDATION_TYPE_VALUE} from '../responsesLogicModals/ModalLogicTypeValidations';
import {IGroupQuestionResponse, IGroupQuestionResponseLogic} from '../../../types';

export function getResponseKeyWithIteration({
    questionKeyWithIteration,
    response,
    responseIteration = 1,
}: {
    questionKeyWithIteration: string;
    response: {
        id: number;
    };
    responseIteration?: number;
}): string {
    return `${questionKeyWithIteration}_R${response && response.id}_${responseIteration || 1}`;
}

export function checkIfIsEmailInput(response: IGroupQuestionResponse): boolean {
    let {logics} = response || {};
    if (logics && logics.length) {
        if (
            logics.find(logic => logic.type === 'emailaddress') ||
            logics.find(logic => logic.type === 'regularexpression' && logic.logicParam === VALIDATION_TYPE_VALUE.EMAIL)
        ) {
            return true;
        }
    }
    return false;
}

export function checkIfIsRegExpInput(response: IGroupQuestionResponse): string | null | false {
    let {logics} = response || {};
    if (logics && logics.length) {
        let regExpLogic = logics.find(
            logic => logic.type === 'regularexpression' && logic.logicParam !== VALIDATION_TYPE_VALUE.EMAIL
        );
        if (regExpLogic) {
            return regExpLogic.logicParam;
        }
    }
    return false;
}

export function checkIfInputHasLength(response: IGroupQuestionResponse): false | {min: any; max: any} {
    let {logics} = response || {};
    if (logics && logics.length) {
        let lengthLogic = logics.find(logic => logic.type === 'length');
        if (lengthLogic) {
            let objWithMinOrMax: {
                min: any;
                max: any;
            } = {
                min: null,
                max: null,
            };
            let logicParam = lengthLogic.logicParam || '';
            let logicParams = logicParam.split(';');
            (logicParams || []).forEach(param => {
                if (param.indexOf('min:') > -1 && param.split('min:').length > 0) {
                    objWithMinOrMax.min = parseInt(param.split('min:')[1]);
                }
                if (param.indexOf('max:') > -1 && param.split('max:').length > 0) {
                    objWithMinOrMax.max = parseInt(param.split('max:')[1]);
                }
            });
            if (objWithMinOrMax.min === null && objWithMinOrMax.max === null) {
                return false;
            }
            return objWithMinOrMax;
        }
    }
    return false;
}

export function isValidText({response, responseAnswer}: {response: IGroupQuestionResponse; responseAnswer: string}) {
    if (checkIfInputHasClientCode(response)) {
        return checkClientCode(responseAnswer);
    }
    if (checkIfIsEmailInput(response)) {
        return checkEmail(responseAnswer);
    }
    if (checkIfIsRegExpInput(response)) {
        let logicPattern = (checkIfIsRegExpInput(response) || '').replace('regexp:', '');
        let pattern = logicPattern.slice(1, logicPattern.length - 1);
        const regularExpression = new RegExp(pattern);
        return regularExpression.test(responseAnswer);
    }
    if (checkIfInputHasLength(response)) {
        const {min, max} = checkIfInputHasLength(response) || {};
        let valid = false;
        min && max && (valid = (responseAnswer || '').length >= min && (responseAnswer || '').length <= max);
        min && !max && (valid = (responseAnswer || '').length >= min);
        max && !min && (valid = (responseAnswer || '').length <= max);
        return valid;
    }
    return true;
}

export function isValidDate(responseAnswer: string): boolean {
    return /\d{1,2}\/\d{1,2}\/\d{4}/.test(responseAnswer);
}

export function checkIfInputHasClientCode(
    response: IGroupQuestionResponse
): IGroupQuestionResponseLogic | undefined | false {
    let {logics} = response || {};
    if (logics && logics.length) {
        return logics.find(logic => logic.type === 'clientcode');
    }
    return false;
}

export function checkIfHasDisabledLogic(
    response: IGroupQuestionResponse
): IGroupQuestionResponseLogic | false | undefined {
    let {logics} = response;
    if (logics && logics.length) {
        return logics.find(logic => logic.type === 'disabled' && !logic.responseIdDest);
    }
    return false;
}

export function checkIfHasHiddenLogic(
    response: IGroupQuestionResponse
): IGroupQuestionResponseLogic | undefined | false {
    let {logics} = response;
    if (logics && logics.length) {
        return logics.find(logic => logic.type === 'hidden');
    }
    return false;
}

export function getErrorMessageResponseMandatoryField({intl}: {intl: any}) {
    return intl?.formatMessage({id: 'online.pleaseRememberToFillThisMandatoryField'});
}

export function getErrorMessageResponseMandatoryFieldWithoutArrow({intl}: {intl: any}) {
    return intl?.formatMessage({id: 'online.pleaseRememberToFillThisMandatoryFieldWithoutArrow'});
}

export function getErrorMessageResponseMandatoryRemark({intl}: {intl: any}) {
    return intl?.formatMessage({id: 'online.pleaseRememberToFillThisMandatoryRemark'});
}
