import React from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {faChevronDown, faChevronRight, faInfo, faPlus} from '@fortawesome/free-solid-svg-icons';
import {apiSpecies} from '~commonApi/static';
import {
    apiGetBackOfficeContacts,
    apiOnlineApplicationRequestDuplicateAndCopy,
    apiOnlineApplicationRequestDuplicateObsolete,
    apiOnlineApplicationRequestsDelete,
    apiSearchApplications,
    apiSendFormToUPOV,
} from './OnlineApplicationsService';
import styles from './OnlineApplications.module.scss';
import TextInput from '~components/TextInput';
import SelectInput from '~components/SelectInput';
import Checkbox from '~components/Checkbox';
import TextLabelInput from '~components/TextLabelInput';
import RadioButton from '~components/RadioButton';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {FORMAT_DATE_EASY} from '~components/FormatFunctions';
import CustomTable from '~components/CustomTable';
import InputLink from '~components/InputLink';
import Empty from '~components/Empty';
import Title from '~components/Title';
import ModalCreateApplication from './modals/ModalCreateApplication/ModalOnlineApplicationsCreateApplication';
import {getDecodedJWT, getLoggedUsedId, loadUrlParams as loadUrlParamsFn} from '~utils';
import OnlineApplicationsCustomTableActionButtons from './customTable/OnlineApplicationsCustomTableActionButtons';
import OnlineApplicationsCustomTableBottomCaption from './customTable/OnlineApplicationsCustomTableBottomCaption';
import ApplicationSteps from './customTable/ApplicationSteps/ApplicationSteps';
import {buildFormDraftUrl} from './utils';
import OnlineApplicationsModalDuplicateGroupsAF from '../OnlineApplicationsModalDuplicateGroupsAF/OnlineApplicationsModalDuplicateGroupsAF';
import OnlineApplicationsModalDuplicateGroupsTQ from '../OnlineApplicationsModalDuplicateGroupsTQ/OnlineApplicationsModalDuplicateGroupsTQ';
import PRISMALogo from '../../img/prisma_logo.png';
import ModalBackOfficeRequestsRemedy from '../BackOfficeRequests/modals/ModalBackOfficeRequestsRemedy';
import {IApplicationSearchItem, IDecodedJWT, ISpeciesItem} from '../../types';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
} from '../../componentsLayout';
import NavigationOnlineApplications from '../../shared/NavigationOnlineApplications';
import {Button} from '../../componentsFormV2';
import {
    ModalAlertVersion2,
    ModalConfirmVersion2,
    ModalPaymentInfoBodyVersion2,
    ModalPaymentInfoVersion2,
} from '../../commonModals';
import ModalSMEFundForCPVR from './modals/ModalSMEFundForCPVR/ModalSMEFundForCPVR';
import {formatDateEasy} from '../../components/FormatFunctions';
import {trackPageView} from '../../utils';

const TABLE_NAME = 'OnlineApplications';

const FORM_STATUS = {
    NEW_FORM: 1,
    ACTIVE_FORM: 2,
};

export const MODAL_CREATE_MODES = {
    COPY: 'COPY',
    DUPLICATE: 'DUPLICATE',
};

const DRAFTS_DEFAULT_RESULT_FIELDS = [
    'office',
    'applicationRequestCode',
    'speciesInput',
    'applicationSteps',
    'breederReference',
    'modificationDate',
    'creationDate',
    'contactName',
];

const DRAFTS_RESULT_FIELDS_ALL = [
    'office',
    'applicationRequestCode',
    'speciesInput',
    'applicationSteps',
    'breederReference',
    'denomination',
    'modificationDate',
    'creationDate',
    'contactName',
    'newTQVersionBeginDate',
];

const SIGNED_DEFAULT_RESULT_FIELDS = [
    'office',
    'applicationRequestCode',
    'speciesInput',
    'breederReference',
    'contactName',
    'creationDate',
    'modificationDate',
    'signatureDate',
];

const SIGNED_RESULT_FIELDS_ALL = [
    'office',
    'applicationRequestCode',
    'speciesInput',
    'breederReference',
    'denomination',
    'contactName',
    'creationDate',
    'modificationDate',
    'signatureDate',
];

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

type TKeyOfDefaultCriteria = keyof TDefaultCriteria;

const DEFAULT_CRITERIA = {
    author: 0,
    officeId: 0,
    denomination: '',
    denominationFilter: 'equals',
    breedersReference: '',
    speciesName: '',
    speciesNameFilter: 'starts',
    speciesIds: '',
    applicationNumber: '',
    onlineAppNumber: '',
    signed: false,
    importedFromPrisma: false,
    UPOVReference: '',
    pageNumber: 1,
    pageSize: 10,
    order: 'applicationNumber',
    reverse: false,
    refresh: false,
    excel: false,
    contact: 0,
};

const ADVANCED_CRITERIA = ['UPOVReference', 'importedFromPrisma'];

const OUTSIDE_LABEL_WIDTH = 160;

const utilPrepareTableSource = (applications: IApplicationSearchItem[] | null) => {
    return (applications || []).map(application => {
        return {
            ...application,
            applicationSteps: {
                application,
            },
        };
    });
};

export const checkIfApplicationHasObsoleteForms = ({statusAF, statusTQ, signatureDate}: IApplicationSearchItem) => {
    return (
        signatureDate === null &&
        statusAF &&
        statusTQ &&
        (statusAF !== FORM_STATUS.ACTIVE_FORM || statusTQ !== FORM_STATUS.ACTIVE_FORM)
    );
};

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    loading: number;
    responseApplications: IApplicationSearchItem[] | null;
    timestamp: number;
    criteria: TDefaultCriteria;
    criteriaCount: number;
    count: number;
    selectedSpecies: any;
    speciesListByIdName: any;
    speciesNameHash: any;
    signedByContacts: any;
    advancedOpened: boolean;
    advancedCriteriaCount: number;
    isModalCreateApplicationOpen: boolean;
    isModalApplicationDuplicateGroupsAFOpen: boolean;
    isModalApplicationDuplicateGroupsTQOpen: boolean;
    isModalApplicationDuplicatePreStepOpen: any;
    isModalDuplicateApplicationExcludingDocumentsOpen: IApplicationSearchItem | null;
    isModalRemedyOpen: boolean;
    modalCreateApplicationMode: any;
    modalCreateApplicationOriginId: any; // TODO: check if can me removed
    modalCreateApplicationSpeciesId: any;
    modalCreateApplicationSpeciesInput: any;
    modalCreateApplicationOfficeId: any;
    modalCreateApplicationSelectedTechnicalQuestionnaireId: any;
    modalCreateApplicationSelectedTechnicalQuestionnaireLanguage: any;
    modalDuplicateApplicationClickedRowObject: IApplicationSearchItem | null;
    modalDuplicateGroupsAFSelectedGroupsOrTemplates: any[] | null;
    modalDuplicateGroupsTQSelectedGroupsOrTemplates: any[] | null;
    isModalConfirmApplicationDeletionOpen: number | false;
    isModalConfirmSendToUPOVOpen: any;
    isModalAlertObsoleteFormsOpen: any;
    isModalConfirmDuplicateDataMightBeLostOpen: any;
    isModalPaymentInfoOpen: any;
    modalAlertTitle: any;
    modalAlertMessage: any;
    clickedApplicationRequest: IApplicationSearchItem | null;
}

class OnlineApplications extends React.Component<IProps, IState> {
    loggedUserId = getLoggedUsedId();
    decodedJWT: IDecodedJWT = getDecodedJWT();
    loggedInUserCompany = (this.decodedJWT && this.decodedJWT.company) || '';
    initialCriteria = Object.assign({}, DEFAULT_CRITERIA, {signed: false});

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: 0,
            responseApplications: null,
            timestamp: Date.now(),
            count: 0,
            criteria: this.initialCriteria,
            criteriaCount: 0,
            selectedSpecies: {},
            speciesListByIdName: null,
            speciesNameHash: null,
            signedByContacts: null,
            advancedOpened: false,
            advancedCriteriaCount: 0,
            isModalCreateApplicationOpen: false,
            isModalApplicationDuplicateGroupsAFOpen: false,
            isModalApplicationDuplicateGroupsTQOpen: false,
            isModalApplicationDuplicatePreStepOpen: false,
            isModalDuplicateApplicationExcludingDocumentsOpen: null,
            isModalRemedyOpen: false,
            modalCreateApplicationMode: null,
            modalCreateApplicationOriginId: null, // TODO: check if can me removed
            modalCreateApplicationSpeciesId: null,
            modalCreateApplicationSpeciesInput: null,
            modalCreateApplicationOfficeId: null,
            modalCreateApplicationSelectedTechnicalQuestionnaireId: null,
            modalCreateApplicationSelectedTechnicalQuestionnaireLanguage: null,
            modalDuplicateApplicationClickedRowObject: null,
            modalDuplicateGroupsAFSelectedGroupsOrTemplates: null,
            modalDuplicateGroupsTQSelectedGroupsOrTemplates: null,
            isModalConfirmApplicationDeletionOpen: false,
            isModalConfirmSendToUPOVOpen: null,
            isModalAlertObsoleteFormsOpen: false,
            isModalConfirmDuplicateDataMightBeLostOpen: false,
            isModalPaymentInfoOpen: null,
            modalAlertTitle: null,
            modalAlertMessage: null,
            clickedApplicationRequest: null,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'applications'});
        const postFix = this.state.criteria.signed ? 'Signed' : 'Drafts';
        const order = localStorage.getItem(`order${TABLE_NAME}${postFix}`) || DEFAULT_CRITERIA.order;
        const pageSize = localStorage.getItem(`pageSize${TABLE_NAME}${postFix}`)
            ? parseInt(localStorage.getItem(`pageSize${TABLE_NAME}${postFix}`) || '')
            : DEFAULT_CRITERIA.pageSize;
        const reverse = localStorage.getItem(`reverse${TABLE_NAME}${postFix}`)
            ? localStorage.getItem(`reverse${TABLE_NAME}${postFix}`) === 'true'
            : DEFAULT_CRITERIA.reverse;
        const signed = localStorage.getItem(`signed${TABLE_NAME}${postFix}`)
            ? localStorage.getItem(`signed${TABLE_NAME}${postFix}`) === 'true'
            : DEFAULT_CRITERIA.signed;
        this.setState(
            prev => ({criteria: {...prev.criteria, pageSize, order, reverse, signed}}),
            () => {
                this.loadJSONs();
                this.loadUrlParams();
            }
        );
    }

    loadUrlParams = () => {
        const urlParams = loadUrlParamsFn();
        urlParams.signed && (urlParams.signed = urlParams.signed === 'true');
        const criteria = Object.assign({}, this.initialCriteria, urlParams);
        if (Object.keys(urlParams).length > 0) {
            const selectedSpecies: any = {};
            if (criteria.speciesIds && criteria.speciesIds !== '') {
                criteria.speciesIds.split(',').forEach((speciesId: string) => (selectedSpecies[speciesId] = false));
            }
            const criteriaCount = this.countCriteria(criteria);
            const advancedCriteriaCount = this.countCriteria(criteria, true);
            this.setState(
                Object.assign(
                    {},
                    {
                        criteria,
                        criteriaCount,
                        advancedCriteriaCount,
                        selectedSpecies,
                        timestamp: Date.now(),
                    },
                    criteria.order && {defaultOrder: criteria.order},
                    criteria.reverse && {reverseOrder: criteria.reverse}
                ),
                () => this.search(false, true)
            );
        }
    };

    search = (refresh?: boolean, urlLoad?: boolean, forceCriteria?: any) => {
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    forceCriteria || {},
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams(parsedCriteria);
                apiSearchApplications(parsedCriteria, DEFAULT_CRITERIA)
                    .then(jsonResponse => {
                        if (
                            jsonResponse &&
                            jsonResponse.data &&
                            jsonResponse.data.applications &&
                            Array.isArray(jsonResponse.data.applications)
                        ) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {
                                    pageNumber: prev.criteria.pageNumber || 1,
                                });
                                return Object.assign(
                                    {...prev},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        responseApplications: jsonResponse.data.applications,
                                        timestamp: Date.now(),
                                    },
                                    jsonResponse.data.COUNT && {count: jsonResponse.data.COUNT}
                                );
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`OAApplications: ${(error && error.message) || `Error while searching applications`}`]);
                        this.setState({
                            modalAlertTitle: `Error`,
                            modalAlertMessage: `There was an error while searching applications`,
                        });
                    })
                    .finally(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    loadSpecies = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiSpecies()
                    .then((jsonResponse: ISpeciesItem[]) => {
                        if (jsonResponse) {
                            const speciesNameHash: any = {};
                            (jsonResponse || []).forEach(speciesElement => {
                                if (!speciesNameHash[speciesElement.NAME]) {
                                    speciesNameHash[speciesElement.NAME] = [];
                                }
                                speciesNameHash[speciesElement.NAME].push(speciesElement.ID);
                            });
                            const speciesListByIdName = Object.keys(speciesNameHash).map(speciesName => ({
                                ID: speciesNameHash[speciesName].join(','),
                                NAME: speciesName,
                            }));
                            const selectedSpecies: any = {};
                            const selectedSpeciesKeyArray = Object.keys(this.state.selectedSpecies || {});
                            speciesListByIdName
                                .filter(i => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                                .forEach(item => (selectedSpecies[item.ID] = item.NAME));
                            this.setState({
                                speciesListByIdName,
                                speciesNameHash,
                                selectedSpecies,
                                timestamp: Date.now(),
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`OAApplications: ${(error && error.message) || `Error while loading species`}`]);
                        this.setState({
                            modalAlertTitle: `Error`,
                            modalAlertMessage: `There was an error while loading species`,
                        });
                    })
                    .finally(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );

    loadContacts = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiGetBackOfficeContacts()
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.contacts) {
                            const activeContacts = jsonResponse.contacts.filter(el => el.valid);
                            const inActiveContacts = jsonResponse.contacts.filter(el => !el.valid);
                            this.setState({
                                signedByContacts: [
                                    {id: 0, value: 'All'},
                                    {id: 1, value: 'My requests'},
                                    {id: 2, value: 'My delegations'},
                                    ...activeContacts.map(el => ({
                                        id: el.contactId,
                                        value: `${el.forename} ${el.surname}`,
                                    })),
                                    ...inActiveContacts.map(el => ({
                                        id: el.contactId,
                                        value: `${el.forename} ${el.surname} - Inactive`,
                                    })),
                                ],
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`OAApplications: ${(error && error.message) || `Error while loading contacts`}`]);
                        this.setState({
                            modalAlertTitle: `Error`,
                            modalAlertMessage: `There was an error while loading contacts`,
                        });
                    })
                    .finally(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );

    loadJSONs = () => {
        const promises = [this.loadContacts(), this.loadSpecies()];
        Promise.all(promises).catch(error => LOG([`Error loading data: ${error}`]));
    };

    countCriteria = (criteria: any, advanced?: boolean) => {
        let count = 0;
        (advanced ? ADVANCED_CRITERIA : Object.keys(criteria)).map(key => {
            if (
                criteria[key] &&
                criteria[key] !== DEFAULT_CRITERIA[key as TKeyOfDefaultCriteria] &&
                criteria[key] !== ''
            )
                count++;
        });
        return count;
    };

    updateCriteriaValue = (criteriaValue: any, callback?: any, refresh?: boolean) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                // DEBUG`new criteria: ${{...criteria}}`;
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                pageNumberChanged = prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = prev.criteria.order !== criteriaValue.order;
                reverseChanged = prev.criteria.reverse !== criteriaValue.reverse;
                return {criteria, criteriaCount, advancedCriteriaCount};
            },
            () => {
                callback && callback();
                const postFix = this.state.criteria.signed ? 'Signed' : 'Drafts';
                localStorage.setItem(`order${TABLE_NAME}${postFix}`, this.state.criteria.order);
                localStorage.setItem(`reverse${TABLE_NAME}${postFix}`, `${this.state.criteria.reverse}`);
                localStorage.setItem(`signed${TABLE_NAME}${postFix}`, `${this.state.criteria.signed}`);
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    buildParams = (parsedCriteria: TDefaultCriteria): any => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(
                i =>
                    parsedCriteria[i as TKeyOfDefaultCriteria] !== DEFAULT_CRITERIA[i as TKeyOfDefaultCriteria] &&
                    i !== 'refresh'
            )
            .map(key => `${key}=${parsedCriteria[key as TKeyOfDefaultCriteria]}`);
        this.props.history.replace(`/applications${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`);
    };

    parseCriteria = (criteria: TDefaultCriteria): TDefaultCriteria => {
        const parsedCriteria: any = {};
        Object.keys(criteria).map(key => {
            if (key === 'denominationFilter' && !criteria['denomination']) {
                criteria[key] = DEFAULT_CRITERIA[key];
            } else if (key === 'speciesNameFilter' && !criteria['speciesName']) {
                criteria[key] = DEFAULT_CRITERIA[key];
            } else {
                parsedCriteria[key] = criteria[key as TKeyOfDefaultCriteria];
            }
        });
        return parsedCriteria;
    };

    getCustomTableRowClass = (rowObj: IApplicationSearchItem) => {
        const {thirdPartyId: loggedInUserThirdPartyId, id: loggedInUserId} = this.decodedJWT || {};
        const {thirdPartyId, contactId} = rowObj || {};
        if (thirdPartyId !== parseInt(loggedInUserThirdPartyId)) {
            return styles.yellowBackground;
        }
        if (thirdPartyId === parseInt(loggedInUserThirdPartyId) && parseInt(loggedInUserId) !== contactId) {
            return styles.greenBackground;
        }
    };

    requestSendToUPOV = () => {
        // let selectedApplication = this.state.responseApplications.find(el => el.demandeId === applicationId);
        // selectedApplication &&
        //     selectedApplication.canBeSendToUPOV === true &&
        let applicationRequestId = this.state.isModalConfirmSendToUPOVOpen;
        this.setState(
            prev => ({loading: prev.loading + 1, isModalConfirmSendToUPOVOpen: null}),
            () => {
                apiSendFormToUPOV({applicationRequestId})
                    .then((jsonResponse: any) => {
                        jsonResponse.data === 'OK' && this.search();
                        jsonResponse.errorMessage &&
                            this.setState({
                                modalAlertTitle: `Error`,
                                modalAlertMessage: jsonResponse.errorMessage,
                            });
                    })
                    .catch((error: any) => {
                        ERROR([`OAApplications: ${(error && error.message) || `Error while sending request to UPOV`}`]);
                        this.setState({
                            modalAlertTitle: `Error`,
                            modalAlertMessage: `There was an error while sending the request to UPOV`,
                        });
                    })
                    .finally(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    requestDeleteApplication = () => {
        if (this.state.isModalConfirmApplicationDeletionOpen) {
            let applicationRequestId = this.state.isModalConfirmApplicationDeletionOpen;
            this.setState(
                prev => ({loading: prev.loading + 1, isModalConfirmApplicationDeletionOpen: false}),
                () => {
                    apiOnlineApplicationRequestsDelete({applicationRequestId})
                        .then((JSONResponse: any) => {
                            JSONResponse && JSONResponse.errorMessage
                                ? this.setState({
                                      modalAlertTitle: `Deletion error`,
                                      modalAlertMessage: JSONResponse.errorMessage,
                                  })
                                : this.search();
                        })
                        .catch((error: any) => {
                            ERROR([
                                `OAApplications: ${(error && error.message) || `Error while deleting application`}`,
                            ]);
                            this.setState({
                                modalAlertTitle: `Error`,
                                modalAlertMessage: `There was an error while deleting the application`,
                            });
                        })
                        .finally(() => this.setState(prev => ({loading: prev.loading - 1})));
                }
            );
        }
    };

    closeModalAlert = () => this.setState({modalAlertTitle: null, modalAlertMessage: null});

    closeModalDuplicateApplicationExcludingDocuments = () =>
        this.setState({isModalDuplicateApplicationExcludingDocumentsOpen: null});

    setModalDuplicateGroupsTQSelectedGroupsOrTemplates = (modalDuplicateGroupsTQSelectedGroupsOrTemplates: any[]) =>
        this.setState({modalDuplicateGroupsTQSelectedGroupsOrTemplates});

    setModalDuplicateGroupsAFSelectedGroupsOrTemplates = (modalDuplicateGroupsAFSelectedGroupsOrTemplates: any[]) =>
        this.setState({modalDuplicateGroupsAFSelectedGroupsOrTemplates});

    requestDuplicateApplication = ({
        selectedAFGroups,
        selectedTQGroups,
    }: {
        selectedAFGroups: string[];
        selectedTQGroups: string[];
    }) => {
        const {applicationRequestId, applicationFormLanguageId, formIdApplicationForm} =
            this.state.modalDuplicateApplicationClickedRowObject || {};
        const JSONRequest = {
            applicationRequestId,
            applicationFormId: formIdApplicationForm,
            applicationFormLanguageId,
            officeId: this.state.modalCreateApplicationOfficeId,
            speciesId: this.state.modalCreateApplicationSpeciesId,
            technicalQuestionnaireId: this.state.modalCreateApplicationSelectedTechnicalQuestionnaireId,
            technicalQuestionnaireLanguageId: this.state.modalCreateApplicationSelectedTechnicalQuestionnaireLanguage,
            selectedAFGroups,
            selectedTQGroups,
        };
        this.onCloseModalCreateApplication(() =>
            this.setState(
                prev => ({
                    loading: prev.loading + 1,
                    isModalApplicationDuplicateGroupsAFOpen: false,
                    isModalApplicationDuplicateGroupsTQOpen: false,
                }),
                () => {
                    apiOnlineApplicationRequestDuplicateAndCopy(JSONRequest)
                        .then(JSONResponse => {
                            if (JSONResponse && JSONResponse.errorMessage) {
                                return this.setState({
                                    modalAlertTitle: `Duplicate error`,
                                    modalAlertMessage: JSONResponse.errorMessage,
                                });
                            } else {
                                const {applicationId, formType, formSequence, formId} = JSONResponse;
                                let uri = buildFormDraftUrl({
                                    applicationId,
                                    formType,
                                    formId,
                                    formSequence,
                                });
                                this.props.history.push(uri);
                            }
                        })
                        .catch((error: any) => {
                            ERROR([
                                `OAApplications: ${(error && error.message) || `Error while duplicating application`}`,
                            ]);
                            this.setState({
                                modalAlertTitle: `Error`,
                                modalAlertMessage: `There was an error while duplicating the application`,
                            });
                        })
                        .finally(() => this.setState(prev => ({loading: prev.loading - 1})));
                }
            )
        );
    };

    modalCreateApplicationSetTechnicalQuestionnaireLanguage = (technicalQuestionnaireLanguage: string) =>
        this.setState({
            modalCreateApplicationSelectedTechnicalQuestionnaireLanguage: technicalQuestionnaireLanguage,
            modalDuplicateGroupsTQSelectedGroupsOrTemplates: null,
        });

    modalCreateApplicationSetSelectedTechnicalQuestionnaireId = (selectedTechnicalQuestionnaireId: any) =>
        this.setState({
            modalCreateApplicationSelectedTechnicalQuestionnaireId: selectedTechnicalQuestionnaireId,
        });

    modalCreateApplicationSetOfficeId = (officeId: any) =>
        this.setState({
            modalCreateApplicationOfficeId: officeId,
        });

    modalCreateApplicationSetSpecies = ({speciesId, speciesInput}: any) =>
        this.setState({
            modalCreateApplicationSpeciesId: speciesId,
            modalCreateApplicationSpeciesInput: speciesInput,
        });

    actionDuplicateApplication = (rowObject: IApplicationSearchItem) => {
        const {applicationRequestId, formIdTQ, TQLanguageId, speciesId, speciesInput, officeId} = rowObject;
        this.setState({
            modalCreateApplicationMode: MODAL_CREATE_MODES.COPY,
            modalCreateApplicationOriginId: applicationRequestId,
            isModalCreateApplicationOpen: true,
            modalCreateApplicationSpeciesId: speciesId,
            modalCreateApplicationSpeciesInput: speciesInput,
            modalCreateApplicationOfficeId: officeId,
            modalDuplicateApplicationClickedRowObject: rowObject,
            modalCreateApplicationSelectedTechnicalQuestionnaireId: formIdTQ,
            modalCreateApplicationSelectedTechnicalQuestionnaireLanguage: TQLanguageId,
        });
    };

    confirmDuplicateDataMightBeLostAction = () => {
        const rowObject = {...this.state.isModalConfirmDuplicateDataMightBeLostOpen};
        const {signatureDate} = rowObject;
        this.setState({isModalConfirmDuplicateDataMightBeLostOpen: false}, () => {
            if (signatureDate) {
                let today = new Date();
                let signDatePlusOneYear = new Date(signatureDate);
                signDatePlusOneYear.setMonth(signDatePlusOneYear.getMonth() + 12);
                if (today >= signDatePlusOneYear) {
                    return this.setState({isModalDuplicateApplicationExcludingDocumentsOpen: rowObject});
                }
            }
            this.actionDuplicateApplication(rowObject);
        });
    };

    confirmUpdateObsoleteFormsAction = () => {
        const JSONRequest = {...this.state.isModalAlertObsoleteFormsOpen};
        this.setState(
            prev => ({loading: prev.loading + 1, isModalAlertObsoleteFormsOpen: false}),
            () => {
                apiOnlineApplicationRequestDuplicateObsolete({applicationRequestId: JSONRequest.applicationRequestId})
                    .then(JSONResponse => {
                        if (JSONResponse && JSONResponse.errorMessage) {
                            this.setState({
                                modalAlertTitle: `Obsolete form duplication error`,
                                modalAlertMessage: JSONResponse.errorMessage,
                            });
                        }
                        if (JSONResponse && JSONResponse.data) {
                            this.search(true);
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`OAApplications: ${(error && error.message) || `Error while deleting application`}`]);
                        this.setState({
                            modalAlertTitle: `Error`,
                            modalAlertMessage: `There was an error while duplicating the application`,
                        });
                    })
                    .finally(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    closeModalRemedy = () => this.setState({isModalRemedyOpen: false});

    onCloseModalConfirmSendToUPOV = () => this.setState({isModalConfirmSendToUPOVOpen: null});

    onOpenModalCreateApplication = () => this.setState({isModalCreateApplicationOpen: true});

    onCloseModalCreateApplication = (callback?: any) =>
        this.setState(
            {
                isModalCreateApplicationOpen: false,
                modalCreateApplicationMode: null,
                modalCreateApplicationOriginId: null,
                modalCreateApplicationSpeciesId: null,
                modalCreateApplicationSpeciesInput: null,
                modalCreateApplicationOfficeId: null,
                modalCreateApplicationSelectedTechnicalQuestionnaireId: null,
                modalCreateApplicationSelectedTechnicalQuestionnaireLanguage: null,
                modalDuplicateApplicationClickedRowObject: null,
                modalDuplicateGroupsAFSelectedGroupsOrTemplates: null,
                modalDuplicateGroupsTQSelectedGroupsOrTemplates: null,
            },
            callback && callback()
        );

    onCloseModalConfirmApplicationDeletion = () => this.setState({isModalConfirmApplicationDeletionOpen: false});

    onCloseModalAlertObsoleteForms = () => this.setState({isModalAlertObsoleteFormsOpen: false});

    onCloseModalConfirmDuplicateDataMightBeLost = () =>
        this.setState({isModalConfirmDuplicateDataMightBeLostOpen: false});

    onCloseModalSMEFundForCPVR = () => {
        sessionStorage.modalSMEFund = 'closed';
        this.setState({timestamp: Date.now()});
    };

    onSelectValueAuthorChange = ({target: {value: author}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({author: parseInt(author)});

    onSelectValueOfficeChange = ({target: {value: officeId}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({officeId: parseInt(officeId)});

    onInputValueDenominationChange = ({target: {value: denomination}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({denomination});

    onInputFilterDenominationChange = ({target: {value: denominationFilter}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({denominationFilter});

    onInputValueBreederReferenceChange = ({target: {value: breedersReference}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({breedersReference});

    onInputValueSpeciesChange = (speciesName: string) => this.updateCriteriaValue({speciesName});

    onInputFilterSpeciesChange = ({target: {value: speciesNameFilter}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({speciesNameFilter});

    onInputValueApplicationNumberChange = ({
        target: {value: applicationNumber},
    }: React.ChangeEvent<HTMLInputElement>) => {
        applicationNumber = applicationNumber.substring(0, 10);
        this.updateCriteriaValue({applicationNumber});
    };

    onApplicationTypeChange = (type: any) =>
        this.updateCriteriaValue({signed: typeof type === 'string' ? type === 'true' : type});

    onInputValueUPOVReferenceChange = ({target: {value: UPOVReference}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({UPOVReference});

    onCheckboxValueImportedFromPrismaChange = () =>
        this.updateCriteriaValue({importedFromPrisma: !this.state.criteria.importedFromPrisma, UPOVReference: ''});

    onSpeciesSelectionChange = (selectedSpecies: any) =>
        this.updateCriteriaValue({speciesIds: Object.keys(selectedSpecies).join(','), speciesName: ''}, () =>
            this.setState({selectedSpecies})
        );

    onToggleAdvancedSearch = () => this.setState(prevState => ({advancedOpened: !prevState.advancedOpened}));

    onClearFieldsClick = () => {
        this.setState(prev => ({
            responseApplications: null,
            selectedSpecies: {},
            criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
            criteriaCount: 0,
        }));
    };

    onTableEditClick = (rowObject: IApplicationSearchItem) => {
        const {
            TQLanguageId,
            applicationRequestId,
            AFValid,
            applicationFormLanguageId,
            officeId,
            speciesId,
            formIdApplicationForm,
            formIdTQ,
        } = rowObject;
        if (checkIfApplicationHasObsoleteForms(rowObject)) {
            const JSONRequest = {
                applicationRequestId,
                applicationFormId: formIdApplicationForm,
                applicationFormLanguageId,
                officeId,
                speciesId,
                technicalQuestionnaireId: formIdTQ,
                technicalQuestionnaireLanguageId: TQLanguageId,
            };
            return this.setState({isModalAlertObsoleteFormsOpen: JSONRequest});
        }
        let formId, formType, formSequence;
        if (!AFValid) {
            formId = formIdApplicationForm;
            formType = 'AF';
            formSequence = 0;
        } else {
            formId = formIdTQ;
            formType = 'TQ';
            formSequence = 0;
        }
        let uri = buildFormDraftUrl({
            applicationId: applicationRequestId,
            formType,
            formId,
            formSequence,
        });
        this.props.history.push(uri);
    };

    onApplicationStepClick = (rowObject: IApplicationSearchItem) => {
        const {
            TQLanguageId,
            applicationRequestId,
            applicationFormLanguageId,
            officeId,
            speciesId,
            formIdApplicationForm,
            formIdTQ,
        } = rowObject;
        if (checkIfApplicationHasObsoleteForms(rowObject)) {
            const JSONRequest = {
                applicationRequestId,
                applicationFormId: formIdApplicationForm,
                applicationFormLanguageId,
                officeId,
                speciesId,
                technicalQuestionnaireId: formIdTQ,
                technicalQuestionnaireLanguageId: TQLanguageId,
            };
            return this.setState({isModalAlertObsoleteFormsOpen: JSONRequest});
        }
    };

    onTableCopyClick = (_: any, rowObject: IApplicationSearchItem) => {
        const {
            TQLanguageId,
            applicationRequestId,
            applicationFormLanguageId,
            officeId,
            speciesId,
            formIdApplicationForm,
            formIdTQ,
        } = rowObject;
        if (checkIfApplicationHasObsoleteForms(rowObject)) {
            const JSONRequest = {
                applicationRequestId,
                applicationFormId: formIdApplicationForm,
                applicationFormLanguageId,
                officeId,
                speciesId,
                technicalQuestionnaireId: formIdTQ,
                technicalQuestionnaireLanguageId: TQLanguageId,
            };
            return this.setState({isModalAlertObsoleteFormsOpen: JSONRequest});
        }
        this.setState({isModalConfirmDuplicateDataMightBeLostOpen: rowObject});
    };

    onModalDuplicateApplicationExcludingDocumentsAction = () => {
        if (this.state.isModalDuplicateApplicationExcludingDocumentsOpen) {
            const rowObject = {...this.state.isModalDuplicateApplicationExcludingDocumentsOpen};
            this.setState({isModalDuplicateApplicationExcludingDocumentsOpen: null}, () =>
                this.actionDuplicateApplication(rowObject)
            );
        }
    };

    onOpenModalDuplicateGroupsAF = () => {
        this.setState(prev => {
            const clickedApplicationRequest =
                (prev.responseApplications || []).find(
                    el => el.applicationRequestId === prev.modalCreateApplicationOriginId
                ) || null;
            return {
                isModalCreateApplicationOpen: false,
                isModalApplicationDuplicateGroupsAFOpen: true,
                clickedApplicationRequest,
            };
        });
    };

    onButtonNextOfModalDuplicateGroupsAFClick = () => {
        const {TQLanguageId} = this.state.clickedApplicationRequest || {};
        if (this.state.modalCreateApplicationSelectedTechnicalQuestionnaireLanguage !== TQLanguageId) {
            this.requestDuplicateApplication({
                selectedAFGroups: [...(this.state.modalDuplicateGroupsAFSelectedGroupsOrTemplates || [])],
                selectedTQGroups: [],
            });
        } else {
            this.setState({
                isModalApplicationDuplicateGroupsAFOpen: false,
                isModalApplicationDuplicateGroupsTQOpen: true,
            });
        }
    };

    onButtonNextOfModalDuplicateGroupsTQClick = () =>
        this.requestDuplicateApplication({
            selectedAFGroups: [...(this.state.modalDuplicateGroupsAFSelectedGroupsOrTemplates || [])],
            selectedTQGroups: [...(this.state.modalDuplicateGroupsTQSelectedGroupsOrTemplates || [])],
        });

    onButtonBackOfModalDuplicateGroupsAFClick = () => {
        this.setState({
            isModalApplicationDuplicateGroupsAFOpen: false,
            modalCreateApplicationMode: MODAL_CREATE_MODES.COPY,
            isModalCreateApplicationOpen: true,
        });
    };

    onButtonBackOfModalDuplicateGroupsTQClick = () => {
        this.setState({
            isModalApplicationDuplicateGroupsAFOpen: true,
            isModalApplicationDuplicateGroupsTQOpen: false,
        });
    };

    onCloseModalApplicationDuplicateGroupsAFOpen = (): any =>
        this.setState(
            {
                isModalApplicationDuplicateGroupsAFOpen: false,
            },
            () => this.onCloseModalCreateApplication()
        );

    onCloseModalApplicationDuplicateGroupsTQOpen = (): any =>
        this.setState(
            {
                isModalApplicationDuplicateGroupsTQOpen: false,
            },
            () => this.onCloseModalCreateApplication()
        );

    onTableRemedyClick = (rowObject: any) => {
        const {applicationRequestId, formIdApplicationForm} = rowObject || {};
        let uri = buildFormDraftUrl({
            applicationId: applicationRequestId,
            formType: 'AF',
            formId: formIdApplicationForm,
            formSequence: 0,
        });
        let AFgroups = ['G21', 'G22'];
        let AFquestions = ['G21_Q21455', 'G21_Q21456', 'G22_Q21459'];
        let TQgroups = ['G1', 'T1'];
        let TQquestions = ['G1_Q9551', 'G1_Q8265', 'T1_Q11889'];
        uri = uri.replace('formDraft', 'formRemedy');
        uri = `${uri}&AFgroups=${AFgroups.join(',')}&AFquestions=${AFquestions.join(',')}`;
        uri = `${uri}&TQgroups=${TQgroups.join(',')}&TQquestions=${TQquestions.join(',')}`;
        this.props.history.push(uri);
    };

    onTableIconPrintClick = (rowObject: IApplicationSearchItem) => {
        const {
            TQLanguageId,
            applicationRequestId,
            formIdApplicationForm,
            formIdTQ,
            applicationFormLanguageId,
            officeId,
            speciesId,
        } = rowObject;
        if (checkIfApplicationHasObsoleteForms(rowObject)) {
            const JSONRequest = {
                applicationRequestId,
                applicationFormId: formIdApplicationForm,
                applicationFormLanguageId,
                officeId,
                speciesId,
                technicalQuestionnaireId: formIdTQ,
                technicalQuestionnaireLanguageId: TQLanguageId,
            };
            return this.setState({isModalAlertObsoleteFormsOpen: JSONRequest});
        }
    };

    onTableDataRequestClick = (rowObject: any) => this.setState({isModalRemedyOpen: rowObject});

    onTableSendToUPOVClick = (rowObject: any) => {
        const {applicationRequestId} = rowObject || {};
        // console.log(applicationId, 'application id');
        // let selectedForm = this.state.responseApplications.find(el => el.demandeId === applicationRequestId);
        // const {canBeSendToUPOV} = selectedForm || {};
        // canBeSendToUPOV && this.setState({isModalConfirmSendToUPOVOpen: applicationId});
        this.setState({isModalConfirmSendToUPOVOpen: applicationRequestId});
    };

    onTableDeleteClick = (applicationId: number) =>
        this.setState({isModalConfirmApplicationDeletionOpen: applicationId});

    onTablePaymentInfoClick = (rowObject: IApplicationSearchItem) => {
        const {applicationRequestCode, speciesInput, officeId, signatureDate} = rowObject || {};
        this.setState({
            isModalPaymentInfoOpen: {
                applicationRequestCode,
                applicationSpeciesInput: speciesInput,
                officeId,
                applicationSignDateString: signatureDate,
            },
        });
    };

    onClosePaymentModal = () => this.setState({isModalPaymentInfoOpen: null});

    onModalPaymentInfoButtonPrintClick = () => {
        document.getElementById('react-content')!.style.display = 'none';
        const printInfo = document.getElementById('print-info');
        printInfo!.style.display = 'block';
        window.print();
        document.getElementById('react-content')!.style.display = 'block';
        printInfo!.style.display = 'none';
    };

    onTableRemedyFormClick = () => {};

    render() {
        const actions = OnlineApplicationsCustomTableActionButtons(
            this.onTableEditClick,
            this.onTableIconPrintClick,
            this.onTableRemedyClick,
            this.onTableDataRequestClick,
            this.onTableCopyClick,
            this.onTableSendToUPOVClick,
            this.onTableDeleteClick,
            this.onTablePaymentInfoClick,
            this.onTableRemedyFormClick
        );
        const tableSourceData = utilPrepareTableSource(this.state.responseApplications);

        return (
            <>
                <div id={'print-info'} style={{display: 'none'}}>
                    <ModalPaymentInfoBodyVersion2 {...this.state.isModalPaymentInfoOpen} />
                </div>
                <div id={`react-content`}>
                    {sessionStorage.modalSMEFund !== 'closed' ? (
                        <ModalSMEFundForCPVR close={this.onCloseModalSMEFundForCPVR} />
                    ) : null}
                    {this.state.isModalRemedyOpen ? (
                        <ModalBackOfficeRequestsRemedy
                            close={this.closeModalRemedy}
                            application={this.state.isModalRemedyOpen}
                            mode={'DATA_REQUEST'}
                        />
                    ) : null}
                    {this.state.isModalApplicationDuplicateGroupsAFOpen ? (
                        <OnlineApplicationsModalDuplicateGroupsAF
                            close={this.onCloseModalApplicationDuplicateGroupsAFOpen}
                            clickApplicationRequest={this.state.clickedApplicationRequest}
                            onButtonNextClick={this.onButtonNextOfModalDuplicateGroupsAFClick}
                            onButtonBackClick={this.onButtonBackOfModalDuplicateGroupsAFClick}
                            selectedGroupsOrTemplates={this.state.modalDuplicateGroupsAFSelectedGroupsOrTemplates}
                            setSelectedGroupsOrTemplates={this.setModalDuplicateGroupsAFSelectedGroupsOrTemplates}
                            selectedTechnicalQuestionnaireLanguage={
                                this.state.modalCreateApplicationSelectedTechnicalQuestionnaireLanguage
                            }
                        />
                    ) : null}
                    {this.state.isModalApplicationDuplicateGroupsTQOpen ? (
                        <OnlineApplicationsModalDuplicateGroupsTQ
                            close={this.onCloseModalApplicationDuplicateGroupsTQOpen}
                            clickApplicationRequest={this.state.clickedApplicationRequest}
                            onButtonNextClick={this.onButtonNextOfModalDuplicateGroupsTQClick}
                            onButtonBackClick={this.onButtonBackOfModalDuplicateGroupsTQClick}
                            selectedGroupsOrTemplates={this.state.modalDuplicateGroupsTQSelectedGroupsOrTemplates}
                            setSelectedGroupsOrTemplates={this.setModalDuplicateGroupsTQSelectedGroupsOrTemplates}
                        />
                    ) : null}
                    {this.state.isModalConfirmApplicationDeletionOpen ? (
                        <ModalConfirmVersion2
                            title={`Delete application`}
                            message={`Are you sure you want to delete this application ?`}
                            buttonName={'Yes'}
                            action={this.requestDeleteApplication}
                            close={this.onCloseModalConfirmApplicationDeletion}
                        />
                    ) : null}
                    {this.state.isModalConfirmSendToUPOVOpen ? (
                        <ModalConfirmVersion2
                            title={`Send to UPOV`}
                            message={`Are you sure you want to send this application to UPOV ?`}
                            buttonName={'Send'}
                            action={this.requestSendToUPOV}
                            close={this.onCloseModalConfirmSendToUPOV}
                        />
                    ) : null}
                    {this.state.isModalAlertObsoleteFormsOpen ? (
                        <ModalAlertVersion2
                            title={`Obsolete forms`}
                            message={`Please note that this application has become obsolete because a new version of the application form or of the technical questionnaire has been released. By clicking on "CONFIRM", the relevant form (AF or TQ) will be automatically updated and therefore some data you have initially declared may be lost.`}
                            action={this.confirmUpdateObsoleteFormsAction}
                            close={this.onCloseModalAlertObsoleteForms}
                            buttonName={'CONFIRM'}
                            buttonVariation={'primary'}
                        />
                    ) : null}
                    {this.state.isModalConfirmDuplicateDataMightBeLostOpen ? (
                        <ModalConfirmVersion2
                            title={`Duplicate an application`}
                            message={
                                <>
                                    <p
                                        style={{marginTop: 0}}
                                    >{`You chose to duplicate application documents. In the following screens, you will be requested to select the information of the application form (AF) and of the technical questionnaire (TQ) you would like to duplicate.`}</p>
                                    <p
                                        style={{marginBottom: 0}}
                                    >{`In case a new version of the AF or the TQ has been released since the initial application, some information might be lost in the duplication.`}</p>
                                </>
                            }
                            buttonName={'Confirm'}
                            action={this.confirmDuplicateDataMightBeLostAction}
                            close={this.onCloseModalConfirmDuplicateDataMightBeLost}
                        />
                    ) : null}
                    {this.state.modalAlertMessage !== null ? (
                        <ModalAlertVersion2
                            message={this.state.modalAlertMessage}
                            title={this.state.modalAlertTitle}
                            close={this.closeModalAlert}
                        />
                    ) : null}
                    {this.state.isModalCreateApplicationOpen ? (
                        <ModalCreateApplication
                            close={this.onCloseModalCreateApplication}
                            duplicateApplicationClickedRowObject={this.state.modalDuplicateApplicationClickedRowObject}
                            mode={this.state.modalCreateApplicationMode}
                            originId={this.state.modalCreateApplicationOriginId}
                            officeId={this.state.modalCreateApplicationOfficeId}
                            speciesId={this.state.modalCreateApplicationSpeciesId}
                            speciesInput={this.state.modalCreateApplicationSpeciesInput}
                            selectedTechnicalQuestionnaireId={
                                this.state.modalCreateApplicationSelectedTechnicalQuestionnaireId
                            }
                            selectedTechnicalQuestionnaireLanguage={
                                this.state.modalCreateApplicationSelectedTechnicalQuestionnaireLanguage
                            }
                            onOpenModalDuplicateGroupsAF={this.onOpenModalDuplicateGroupsAF}
                            history={this.props.history}
                            setSpecies={this.modalCreateApplicationSetSpecies}
                            setOfficeId={this.modalCreateApplicationSetOfficeId}
                            setSelectedTechnicalQuestionnaireId={
                                this.modalCreateApplicationSetSelectedTechnicalQuestionnaireId
                            }
                            setTechnicalQuestionnaireLanguage={
                                this.modalCreateApplicationSetTechnicalQuestionnaireLanguage
                            }
                        />
                    ) : null}
                    {this.state.isModalPaymentInfoOpen ? (
                        <ModalPaymentInfoVersion2
                            confirm={this.onClosePaymentModal}
                            onPrint={this.onModalPaymentInfoButtonPrintClick}
                            {...this.state.isModalPaymentInfoOpen}
                        />
                    ) : null}
                    {this.state.isModalDuplicateApplicationExcludingDocumentsOpen ? (
                        <ModalConfirmVersion2
                            close={this.closeModalDuplicateApplicationExcludingDocuments}
                            title={'Duplicate excluding documents'}
                            message={
                                "This application has been signed more than 1 year ago and its attachments won't be copied"
                            }
                            action={this.onModalDuplicateApplicationExcludingDocumentsAction}
                            buttonName={`Continue`}
                        />
                    ) : null}
                    {this.state.loading !== 0 ? <HeaderLoading /> : null}
                    <HeaderLogoMenu />
                    <HeaderTitleAndVersion title={`Online Applications`} />
                    <NavigationOnlineApplications />
                    <FormWrapper paddingFormContent={'sm'}>
                        <div className={styles.mainFieldContainer}>
                            <SelectInput
                                outsideLabel={`Author`}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                notAll={true}
                                value={this.state.criteria.author}
                                onChange={this.onSelectValueAuthorChange}
                                loading={this.state.loading}
                                list={this.state.signedByContacts || []}
                            />
                            <FormFooterButton
                                color={buttonColor.green}
                                clickAction={this.onOpenModalCreateApplication}
                                icon={faPlus}
                            >{`Create an application`}</FormFooterButton>
                            <div style={{clear: 'both'}} />
                            <SelectInput
                                outsideLabel={`Office`}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                notAll={true}
                                value={this.state.criteria.officeId}
                                onChange={this.onSelectValueOfficeChange}
                                loading={this.state.loading}
                                list={[
                                    {id: 0, value: 'All'},
                                    {id: 1, value: 'CPVO'},
                                    {id: 21, value: 'Dutch Board'},
                                ]}
                            />
                            <div style={{clear: 'both'}} />
                            <TextInput
                                filter={['equals', 'contains']}
                                currentFilter={this.state.criteria.denominationFilter}
                                onFilterChange={this.onInputFilterDenominationChange}
                                double={true}
                                infoIcon={faInfo}
                                onChange={this.onInputValueDenominationChange}
                                onEnter={this.search}
                                value={this.state.criteria.denomination}
                                popOverText={`The search will find all applications containing what is typed`}
                                outsideLabel={'Denomination'}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                            />
                            <div style={{clear: 'both'}} />
                            <TextInput
                                filter={'Contains'}
                                double={true}
                                infoIcon={faInfo}
                                onChange={this.onInputValueBreederReferenceChange}
                                onEnter={this.search}
                                value={this.state.criteria.breedersReference}
                                popOverText={`The search will find all applications containing what is typed`}
                                outsideLabel={'Breeder’s reference'}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                            />
                            <div style={{clear: 'both'}} />
                            <TextLabelInput
                                filter={['starts', 'contains']}
                                currentFilter={this.state.criteria.speciesNameFilter}
                                onFilterChange={this.onInputFilterSpeciesChange}
                                double={true}
                                onSelectionChange={this.onSpeciesSelectionChange}
                                onChange={this.onInputValueSpeciesChange}
                                onEnter={this.search}
                                value={this.state.criteria.speciesName}
                                selectedElements={this.state.selectedSpecies}
                                delay={300}
                                multiple={true}
                                outsideLabel={'Species'}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                listByIdName={this.state.speciesListByIdName}
                                nameHash={this.state.speciesNameHash}
                                popover={`The search will find all applications concerned by the specified species`}
                            />
                            <div style={{clear: 'both'}} />
                            <TextInput
                                filter={'Starts with'}
                                placeholder={`e.g. A2022`}
                                infoIcon={faInfo}
                                onChange={this.onInputValueApplicationNumberChange}
                                onEnter={this.search}
                                value={this.state.criteria.applicationNumber}
                                popOverText={`The search will find all applications whose code starts with the entered terms.`}
                                outsideLabel={'Application code'}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                            />
                            <div style={{clear: 'both'}} />
                            <div className={styles.radioContainer}>
                                <RadioButton
                                    radioButtonList={[
                                        {
                                            description: 'Drafts',
                                            value: false,
                                        },
                                        {description: 'Signed applications', value: true},
                                    ]}
                                    groupName={'Application type'}
                                    value={this.state.criteria.signed}
                                    className={styles.radioHorizontal}
                                    clickEvent={this.onApplicationTypeChange}
                                    triple={true}
                                />
                            </div>
                            <InputLink
                                label={`Advanced search${
                                    this.state.advancedCriteriaCount !== 0
                                        ? ` (${this.state.advancedCriteriaCount})`
                                        : ''
                                } `}
                                icon={this.state.advancedOpened ? faChevronDown : faChevronRight}
                                clickAction={this.onToggleAdvancedSearch}
                            />
                            <Empty oneLine={true} />
                            <Empty oneLine={true} />
                            {this.state.advancedOpened && (
                                <>
                                    <Title triple={true}>{`Add filters`}</Title>
                                    <Checkbox
                                        clickAction={this.onCheckboxValueImportedFromPrismaChange}
                                        simple={true}
                                        label={`Imported from PRISMA`}
                                        value={this.state.criteria.importedFromPrisma}
                                    />
                                    <div style={{clear: 'both'}} />
                                    <TextInput
                                        placeholder={`e.g. XU_20180000000001`}
                                        infoIcon={faInfo}
                                        onChange={this.onInputValueUPOVReferenceChange}
                                        onEnter={this.search}
                                        value={this.state.criteria.UPOVReference}
                                        popOverText={`The search will find all applications imported from PRISMA whose UPOV reference starts with the entered terms`}
                                        outsideLabel={'UPOV reference'}
                                        outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                        disabled={!this.state.criteria.importedFromPrisma}
                                    />
                                </>
                            )}
                        </div>
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            <Button variation={'secondary'} clickAction={this.onClearFieldsClick}>
                                {`Clear fields`}
                            </Button>
                            <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    <div style={{textAlign: 'center'}}>
                        {Array.isArray(this.state.responseApplications) && (
                            <div className={styles.customTableWrap}>
                                <CustomTable
                                    version={2}
                                    pageNumber={this.state.criteria.pageNumber}
                                    pageSize={this.state.criteria.pageSize}
                                    defaultOrder={this.state.criteria.order}
                                    {...this.props}
                                    tableName={
                                        this.state.criteria.signed ? `${TABLE_NAME}Signed` : `${TABLE_NAME}Drafts`
                                    }
                                    actionName={`Action`}
                                    tableType={'OBJECT'}
                                    tableSource={tableSourceData}
                                    timestamp={this.state.timestamp}
                                    dataFilter={null}
                                    id={'applicationRequestId'}
                                    pagination={true}
                                    setLastCursor={null}
                                    resultFieldsDefault={
                                        this.state.criteria.signed
                                            ? SIGNED_DEFAULT_RESULT_FIELDS
                                            : DRAFTS_DEFAULT_RESULT_FIELDS
                                    }
                                    resultFieldsAll={
                                        this.state.criteria.signed ? SIGNED_RESULT_FIELDS_ALL : DRAFTS_RESULT_FIELDS_ALL
                                    }
                                    intl={this.props.intl}
                                    reverseOrder={this.state.criteria.reverse}
                                    filterFunctions={null}
                                    count={this.state.count}
                                    rowClass={this.getCustomTableRowClass}
                                    formatFunctions={{
                                        applicationRequestCode: (
                                            applicationRequestCode: string,
                                            application: IApplicationSearchItem
                                        ) => {
                                            const {internationalReference} = application;
                                            return (
                                                <>
                                                    {applicationRequestCode}
                                                    {internationalReference ? (
                                                        <span className={styles.prismaLogoWrap}>
                                                            <img
                                                                alt="PRISMA logo"
                                                                src={PRISMALogo}
                                                                className={styles.prismaLogo}
                                                            />
                                                            <div className={styles.internationalReferenceWrap}>
                                                                <h5
                                                                    className={styles.internationalReferenceTitle}
                                                                >{`Application imported from EAF`}</h5>
                                                                <div className={styles.internationalReferenceDetails}>
                                                                    <div>{`International reference number:`}</div>
                                                                    <span>{internationalReference}</span>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    ) : null}
                                                </>
                                            );
                                        },
                                        applicationSteps: ({application}: any) => (
                                            <ApplicationSteps
                                                application={application}
                                                onApplicationStepClick={this.onApplicationStepClick}
                                            />
                                        ),
                                        creationDate: FORMAT_DATE_EASY,
                                        modificationDate: FORMAT_DATE_EASY,
                                        newTQVersionBeginDate: FORMAT_DATE_EASY,
                                        office: (office: string, {signatureDate}: any) => {
                                            if (signatureDate) {
                                                if (office === 'NAKTUINBOUW') {
                                                    let signatureDateDateObject = new Date(signatureDate);
                                                    let dutchOfficeNewNameDateObject = new Date('2023-08-01');
                                                    if (
                                                        signatureDateDateObject.getTime() >=
                                                        dutchOfficeNewNameDateObject.getTime()
                                                    ) {
                                                        return 'Dutch Board';
                                                    } else {
                                                        return 'NAKTUINBOUW';
                                                    }
                                                } else {
                                                    return office;
                                                }
                                            } else {
                                                return office === 'NAKTUINBOUW' ? 'Dutch Board' : office;
                                            }
                                        },
                                        signatureDate: FORMAT_DATE_EASY,
                                    }}
                                    hideExcelButton={true}
                                    headerPopup={{
                                        applicationStatus: {
                                            description: 'Status explanations',
                                            handler: () => {},
                                        },
                                    }}
                                    actions={actions}
                                    bottomCaption={
                                        <OnlineApplicationsCustomTableBottomCaption
                                            loggedInUserCompany={this.loggedInUserCompany}
                                        />
                                    }
                                    updateCriteriaValue={this.updateCriteriaValue}
                                    forceUpdateCurrentFields={true}
                                    forehandColumn={(rowObject: IApplicationSearchItem) => {
                                        const {newTQVersionBeginDate, signatureDate} = rowObject;
                                        if (!signatureDate && newTQVersionBeginDate) {
                                            return (
                                                <div
                                                    style={{
                                                        background: '#f0ad4e',
                                                        color: 'white',
                                                        borderRadius: 4,
                                                        padding: '2px 4px',
                                                        fontWeight: 'bold',
                                                        fontSize: 14,
                                                        textAlign: 'center',
                                                    }}
                                                    title={`A new version of the TQ will be released on ${formatDateEasy(
                                                        newTQVersionBeginDate
                                                    )}. Consequently if you send your application after this date, it will become obsolete and some data you have initially filled may be lost.`}
                                                >{`EXPIRING SOON`}</div>
                                            );
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <Footer />
                </div>
            </>
        );
    }
}

export default withRouter(injectIntl(OnlineApplications));
