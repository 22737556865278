import React from 'react';
import {injectIntl} from 'react-intl';
import {Button} from '../componentsFormV2';
import ModalCustomVersion2 from './ModalCustomVersion2';

interface IProps {
    action: any;
    actionOnNoButtonClick?: any;
    buttonName?: string;
    close?: any;
    intl: any;
    title: string;
    message: any;
    zIndexNumber?: number;
}

interface IState {}

class ModalConfirmVersion2 extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    close = () => {
        this.props.close && this.props.close();
    };

    render() {
        return (
            <ModalCustomVersion2
                close={this.close}
                plus3={true}
                size={'sm'}
                header={this.props.title}
                body={this.props.message}
                footer={
                    <>
                        <Button
                            variation={'danger'}
                            clickAction={
                                this.props.actionOnNoButtonClick ? this.props.actionOnNoButtonClick : this.close
                            }
                        >{`NO`}</Button>
                        <Button clickAction={this.props.action}>
                            {(this.props.buttonName || 'Yes').toUpperCase()}
                        </Button>
                    </>
                }
                zIndexNumber={this.props.zIndexNumber}
            />
        );
    }
}

export default injectIntl(ModalConfirmVersion2);
