import React, {useEffect, useState} from 'react';
import AdminIcon from '../../../TLO/CommonComponents/AdminIcon/AdminIcon';
import {Footer, FormWrapper, HeaderLogoMenu, HeaderTitleAndVersion, MainWrapper} from '../../../../componentsLayout';
import {History} from 'history';
import InputLink from '~components/InputLink';
import {IntlShape} from 'react-intl';
import {ModalContactUsVersion2, ModalErrorVersion2} from '../../../../commonModals';
import NavigationPVRCaseLaw from '../../../../shared/NavigationPVRCaseLaw';
import Title from '~components/Title';
import cx from 'classnames';
import dashboard from './data/dashboardData.json';
import {faChevronDown, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {isTextHTML, trackPageView} from '../../../../utils';
import infoMessage from './data/infoMessage.json';
import styles from './HomePagePVRCASELAW.module.scss';

const modalScreenTypes = {ERROR: 'ERROR', CONTACT_US: 'CONTACT_US'};

const sendToList = [
    {
        label: 'PVR Case Law Contact point',
        email: 3,
    },
];

interface HomePagePVRCASELAWProps {
    intl: IntlShape;
    history: History;
}

export interface Page {
    id: number;
    title: string;
    link: string;
}

export interface Section {
    id: number;
    title: string;
    pages: Page[];
}

export interface DashboardData {
    sections: Section[];
}

const HomePagePVRCASELAW = (props: HomePagePVRCASELAWProps) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [informationOpened, setInformationOpened] = useState(true);

    useEffect(() => {
        trackPageView({documentTitle: 'pvrcaselaw'});
    }, []);

    const closeErrorModal = () => {
        setErrorMessage(null);
        setModalScreen(null);
    };

    const toggleInfoMessage = () => setInformationOpened(!informationOpened);

    const InfoMessageArea = () => {
        return (
            <div className={styles.infoMessageContainer}>
                <InputLink
                    label={`Introduction Message`}
                    icon={informationOpened ? faChevronDown : faChevronRight}
                    clickAction={toggleInfoMessage}
                />
                <div style={{clear: 'both'}} />
                {informationOpened ? (
                    isTextHTML(infoMessage.message) ? (
                        // eslint-disable-next-line react/no-danger
                        <div className={styles.infoMessage} dangerouslySetInnerHTML={{__html: infoMessage.message}} />
                    ) : (
                        <p className={styles.infoMessage}>{infoMessage.message}</p>
                    )
                ) : null}
            </div>
        );
    };

    const goToPage = (linkPath: string) => props.history.push(linkPath);

    const closeModal = () => setModalScreen(null);

    const showModalContactUs = () => setModalScreen(modalScreenTypes.CONTACT_US);

    const openContactUsModalRoutine = (page: Page) => {
        return (
            <a
                onClick={() => {
                    showModalContactUs();
                }}
                key={page.id}
            >
                <div
                    className={cx(
                        styles.pageTitleContainer,
                        page.title === 'Contact Us' && styles.pageTitleTinyContainer
                    )}
                    key={page.id}
                >
                    {page.title}
                </div>
            </a>
        );
    };

    const addAdminIcon = (page: Page) => {
        return page.title === 'Administrator' ? <AdminIcon /> : <div></div>;
    };

    const goToPageRoutine = (page: Page) => {
        return (
            <a
                onClick={() => {
                    goToPage(page.link);
                }}
                key={page.id}
                target={page.title === 'Contract’s User Manual' ? '_blank' : '_none'}
            >
                <div
                    className={cx(
                        styles.pageTitleContainer,
                        page.title === 'CPVO Box' && styles.pageTitleTinyContainer
                    )}
                    key={page.id}
                >
                    {page.title}
                    {addAdminIcon(page)}
                </div>
            </a>
        );
    };

    const DashboardArea = () => {
        const sections = dashboard.dashboardData.sections;
        return sections.map((sectionEl: Section) => {
            const sectionPages = sectionEl.pages;
            return (
                <div className={'sectionElement'} key={sectionEl.id}>
                    <Title triple={true} key={sectionEl.id}>
                        {sectionEl.title}
                    </Title>
                    {sectionPages.map((pageEl: Page) => {
                        if (pageEl.title === 'Contact Us') {
                            return openContactUsModalRoutine(pageEl);
                        } else {
                            return goToPageRoutine(pageEl);
                        }
                    })}
                </div>
            );
        });
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {modalScreen === modalScreenTypes.CONTACT_US ? (
                <ModalContactUsVersion2
                    additionalSendToList={sendToList}
                    subjectTag={'[PVRCASELAW]'}
                    close={closeModal}
                />
            ) : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`PVR Case Law`} />
            <NavigationPVRCaseLaw />
            <MainWrapper>
                <FormWrapper paddingFormContent={'sm'}>
                    {InfoMessageArea()}
                    <div style={{clear: 'both', marginTop: 15}} />
                    {DashboardArea()}
                    <div style={{clear: 'both'}} />
                </FormWrapper>
            </MainWrapper>
            <Footer />
        </>
    );
};

export default HomePagePVRCASELAW;
