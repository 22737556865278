import styles from './HeaderTitleAndVersion.module.scss';
import IconInfo from '../iconComponents/Info';
import React, {useState} from 'react';
import ModalWhatsNewVersion2 from '../commonModals/ModalWhatsNewVersion2';

const modalScreens = {
    WHATS_NEW: 'WHATS_NEW',
};

interface IHeaderTitleAndVersionProps {
    hostApplication?: 'Variety Finder';
    subtitle?: string;
    title: string;
}

function HeaderTitleAndVersion({title, subtitle, hostApplication}: IHeaderTitleAndVersionProps) {
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const openModalWhatsNew = () => setModalScreen(modalScreens.WHATS_NEW);
    const closeModal = () => setModalScreen(null);

    return (
        <>
            {modalScreen === modalScreens.WHATS_NEW && <ModalWhatsNewVersion2 close={closeModal} />}
            <section className={styles.wrap}>
                <h1 className={styles.linkTitle} style={{fontWeight: 'bold'}}>
                    {title}
                </h1>
                {hostApplication !== 'Variety Finder' ? (
                    <button type={`button`} className={styles.version} onClick={openModalWhatsNew}>
                        {`Version ${process.env.VERSION}`}
                        <IconInfo style={{marginLeft: 4}} color={'white'} />
                    </button>
                ) : null}
                {subtitle ? <div className={styles.subtitle}>{subtitle}</div> : null}
            </section>
        </>
    );
}

export default HeaderTitleAndVersion;
