import React from 'react';
import CryptoJS from 'crypto-js';
import styles from './ModalMyPVRPublicSearchCommon.module.scss';
import {faChevronDown, faChevronRight, faFlag, faPlus} from '@fortawesome/free-solid-svg-icons';
import TextLabelInput from '~components/TextLabelInput';
import Info from '~components/Info';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TextAreaInput from '~components/TextAreaInput';
import {isRegisterIdValid, sanitize} from '~utils';
import CustomTable from '~components/CustomTable';
import getIcon from '~utils/icons';
import {saveAs} from 'file-saver';
import SelectInput from '~components/SelectInput';
import DragAndDrop from '~components/DragAndDrop';
import ModalMyPVRPublicSearchCommonActions from './ModalMyPVRPublicSearchCommonActions.js';
import {apiDocumentUpload} from './ModalMyPVRPublicSearchCommonService';
import InputLink from '~components/InputLink';
import BrowserInfo from '~shared/BrowserInfo';
import DateInput from '~components/DateInput';
import TextInput from '~components/TextInput';
import {getMandatoryAsterisk} from '~utils/format';
import {SharedMessageProceduralRepresentative} from './ModalMyPVRSharedMessages';
import {MAX_UPLOAD_FILE_SIZE} from '../../../utils/constants';
import {Button} from '../../../componentsFormV2';
import {ModalAlertVersion2, ModalCustomVersion2} from '../../../commonModals';
import {Error, HeaderLoading} from '../../../componentsLayout';

const DOCUMENT_TYPES = [
    {id: null, value: 'Please select a document type'},
    {id: 'powerOfAttorney', value: 'Power of attorney'},
    {id: 'factsAndEvidences', value: `Fact and evidences`},
];

const EXPLAIN_OPTIONS = [
    {id: null, value: 'Please select'},
    {id: 'annulDecision', value: 'Annul decision'},
    {id: 'amendDecision', value: 'Amend decision'},
    {id: 'other', value: 'Other - please specify'},
];

const LANGUAGE_OPTIONS = [
    {
        id: 'NONE',
        value: 'Please select a language',
    },
    {
        id: 'BG',
        value: 'Bulgarian',
    },
    {
        id: 'HR',
        value: 'Croatian',
    },
    {
        id: 'CS',
        value: 'Czech',
    },
    {
        id: 'DA',
        value: 'Danish',
    },
    {
        id: 'NL',
        value: 'Dutch',
    },
    {
        id: 'EN',
        value: 'English',
    },
    {
        id: 'ET',
        value: 'Estonian',
    },
    {
        id: 'FI',
        value: 'Finnish',
    },
    {
        id: 'FR',
        value: 'French',
    },
    {
        id: 'DE',
        value: 'German',
    },
    {
        id: 'EL',
        value: 'Greek',
    },
    {
        id: 'HU',
        value: 'Hungarian',
    },
    {
        id: 'GA',
        value: 'Irish',
    },
    {
        id: 'IT',
        value: 'Italian',
    },
    {
        id: 'LV',
        value: 'Latvian',
    },
    {
        id: 'LT',
        value: 'Lithuanian',
    },
    {
        id: 'MT',
        value: 'Maltese',
    },
    {
        id: 'PL',
        value: 'Polish',
    },
    {
        id: 'PT',
        value: 'Portuguese',
    },
    {
        id: 'RO',
        value: 'Romanian',
    },
    {
        id: 'SK',
        value: 'Slovak',
    },
    {
        id: 'SL',
        value: 'Slovenian',
    },
    {
        id: 'ES',
        value: 'Spanish',
    },
    {
        id: 'SV',
        value: 'Swedish',
    },
];

const errorMessages = {
    UNKNOWN: `This is not a valid application number. The value must be in range 19950001 to ${new Date().getFullYear()}9999!`,
    FORBIDDEN: 'This application is not linked to your account',
    DUPLICATE: `Application number already in the list`,
    NOT_RECEIPT: 'The reception of your application has not been finalised yet.',
    ADD: 'Please include the application number using the button + or "Enter" keyboard button.',
};

export default class ModalMyPVRPublicSearchAppeal extends React.Component {
    constructor(props) {
        super(props);
        this.inputUploadRef = React.createRef();
        const selectedApplicationNumbers = {};
        (this.props.legalActionTarget || []).forEach(
            applicationNumber => (selectedApplicationNumbers[applicationNumber] = applicationNumber)
        );
        this.state = {
            applicationNumber: '',
            selectedApplicationNumbers,
            subject: '',
            comments: '',
            errorType: null,
            documentList: [],
            informationOpened: true,
            language: null,
            loading: 0,
            DUSOption: null,
            serviceOfTheDecision: '',
            modalAlertMessage: '',
            modalAlertTitle: '',
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevState.serviceOfTheDecision !== this.state.serviceOfTheDecision &&
            (this.state.serviceOfTheDecision || '').length === 10
        ) {
            this.setState({
                modalAlertMessage:
                    'An appeal can be submitted within 2 months from the date of service of the decision',
            });
        }
    }

    confirmSending = () => {
        if (Object.keys(this.state.selectedApplicationNumbers).length === 0) {
            this.setState({error: 'No application number linked'});
            return;
        }
        if (!this.state.appealNumber) {
            this.setState({error: 'Please indicate contested decision number'});
            return;
        }
        if (!this.state.requesterAddress) {
            this.setState({error: 'Please complete name and address of the appellant'});
            return;
        }
        if (!this.state.DUSOption) {
            this.setState({
                error: 'Please explain to which extent an amendment or cancellation of the decision is sought',
            });
            return;
        }
        if (this.state.DUSOption === 'other' && !this.state.specify) {
            this.setState({
                error: 'Please specify',
            });
            return;
        }
        if (!this.state.serviceOfTheDecision) {
            this.setState({
                error: 'Please indicate date of service of the decision',
            });
            return;
        }
        if (!this.state.groundOfAppeal) {
            this.setState({
                error: 'Please fill or add attachment(s) with facts and evidence in support of the grounds',
            });
            return;
        }
        if (!this.state.language || this.state.language === 'NONE') {
            this.setState({
                error: 'Please select the language of the appeal',
            });
            return;
        }
        if (this.state.documentList.filter(i => i.documentType === null).length > 0) {
            this.setState({error: 'You have to select a type for each document'});
            return;
        }
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                const {
                    selectedApplicationNumbers,
                    appealNumber,
                    requesterAddress,
                    representativeAddress,
                    DUSOption,
                    specify,
                    serviceOfTheDecision,
                    groundOfAppeal,
                    factsAndEvidence,
                    documentList,
                    language,
                } = this.state;
                apiDocumentUpload(
                    {
                        appealNumber,
                        requesterAddress,
                        representativeAddress,
                        DUSOption,
                        specify,
                        serviceOfTheDecision,
                        groundOfAppeal,
                        factsAndEvidence,
                        applicationNumbers: Object.keys(selectedApplicationNumbers).join(','),
                        language,
                    },
                    documentList,
                    this.props.actionName
                )
                    .then(response => {
                        if (response.error === 404 || !response || !response.folder) {
                            this.setState(prev => ({loading: --prev.loading, error: '404 - Document not found'}));
                        } else {
                            this.setState(
                                prev => ({loading: --prev.loading}),
                                () => {
                                    this.props.openModal({
                                        folder: response.folder,
                                        subject: this.state.subject,
                                        comments: this.state.comments,
                                    });
                                }
                            );
                        }
                    })
                    .catch(this.close);
            }
        );
    };

    close = () => this.props.close && this.props.close();

    closeError = () => this.setState({error: null});

    handleDrop = (files, event) => this.setState({error: false}, () => this.checkFile(files[0], event));

    checkFile = (file, event) => {
        LOG`file.type:${file.type}`;
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType.toLowerCase();
        const filename = sanitize(file.name);
        this.setState({error: null}, () => {
            if (
                fileTypeLowercase === 'jpeg' ||
                fileTypeLowercase === 'png' ||
                fileTypeLowercase === 'jpg' ||
                fileTypeLowercase === 'pdf'
            ) {
                if (file.size > MAX_UPLOAD_FILE_SIZE) {
                    this.setState({
                        error: 'The maximum document size for uploads is 10 MB. Please select a proper file.',
                    });
                } else {
                    const a = new FileReader();
                    a.readAsBinaryString(file);
                    a.onloadend = () => {
                        const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(a.result));
                        this.setState(
                            prev => {
                                const documentList = prev.documentList.slice(0);
                                documentList.push({
                                    index,
                                    file,
                                    filename,
                                    size: file.size / 1024,
                                    extension: fileTypeLowercase,
                                    documentType: null,
                                });
                                return {documentList, timestamp: Date.now(), informationOpened: false};
                            },
                            () => (event.target.value = null)
                        );
                    };
                }
            } else {
                this.setState({error: 'type not valid'}, () => (event.target.value = null));
            }
        });
    };

    deleteFile = index =>
        this.setState(prev => ({
            documentList: prev.documentList.filter(i => i.index !== index),
            timestamp: Date.now(),
            informationOpened: prev.documentList.length === 1,
        }));

    toggleInformation = () => this.setState(prev => ({informationOpened: !prev.informationOpened}));

    checkApplicationNumber = applicationNumber => {
        let applicationNumberValue = applicationNumber;
        if (!isNaN(applicationNumberValue)) {
            applicationNumberValue = applicationNumberValue.substring(0, 8);
            this.setState({
                applicationNumber: applicationNumberValue,
                applicationNumberError: isRegisterIdValid(applicationNumberValue)
                    ? applicationNumberValue.length === 8
                        ? 'ADD'
                        : null
                    : 'UNKNOWN',
            });
        }
    };
    checkIfSurrenderOrWithdrawal = () =>
        this.setState(prev => ({
            surrender: prev.documentList.filter(i => i.documentType === 'WithdrawalSurrender').length > 0,
        }));

    closeModalAlert = () => this.setState({modalAlertMessage: '', modalAlertTitle: ''});

    onApplicationNumberChange = applicationNumber => this.checkApplicationNumber(applicationNumber);

    onApplicationNumbersSelectionChange = selectedApplicationNumbers => this.setState({selectedApplicationNumbers});

    onAddingApplicationNumber = () => {
        const {applicationNumber} = this.state;
        if (applicationNumber.length === 8 && isRegisterIdValid(applicationNumber)) {
            if (this.state.selectedApplicationNumbers[applicationNumber]) {
                this.setState({applicationNumberError: 'DUPLICATE'});
            } else {
                this.setState(prev => {
                    const selectedApplicationNumbers = prev.selectedApplicationNumbers;
                    selectedApplicationNumbers[prev.applicationNumber] = prev.applicationNumber;
                    return {
                        applicationNumber: '',
                        applicationNumberError: null,
                        selectedApplicationNumbers,
                        criteria: Object.assign({}, prev.criteria, {
                            applicationNumberIds: Object.keys(selectedApplicationNumbers).join(','),
                        }),
                    };
                });
            }
        }
    };
    onAppealNumberChange = ({target: {value: appealNumber}}) => this.setState({appealNumber});
    onRequesterAddressChange = ({target: {value: requesterAddress}}) => this.setState({requesterAddress});
    onRepresentativeAddressChange = ({target: {value: representativeAddress}}) =>
        this.setState({representativeAddress});
    onDUSOptionChange = ({target: {value: DUSOption}}) =>
        this.setState(Object.assign({}, {DUSOption}, DUSOption !== 'other' && {specify: ''}));
    onLanguageOptionChange = ({target: {value: language}}) => this.setState({language});
    onSpecifyChange = ({target: {value: specify}}) => this.setState({specify});
    onServiceOfTheDecisionChange = serviceOfTheDecision =>
        this.setState({
            serviceOfTheDecision,
        });
    onGroundOfAppealChange = ({target: {value: groundOfAppeal}}) => this.setState({groundOfAppeal});
    onAcceptanceCheckboxChange = () => {
        this.setState(prev => ({acceptance: !prev.acceptance}));
    };
    onFileUpload = event => {
        const file = event.target.files[0];
        this.checkFile(file, event);
    };
    onSelectType = (event, index) => {
        const documentType = event.target.value;
        this.setState(
            prev => ({
                documentList: prev.documentList
                    .slice(0)
                    .map(document =>
                        document.index === index ? Object.assign({}, document, {documentType}) : document
                    ),
            }),
            this.checkIfSurrenderOrWithdrawal
        );
    };

    render() {
        const actions = ModalMyPVRPublicSearchCommonActions(this.deleteFile);
        this.signAndUploadEnabled = false;
        return (
            <>
                {this.state.modalAlertMessage !== '' ? (
                    <ModalAlertVersion2
                        title={this.state.modalAlertTitle || 'Info'}
                        message={this.state.modalAlertMessage}
                        close={this.closeModalAlert}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <DragAndDrop handleDrop={this.handleDrop}>
                    <ModalCustomVersion2
                        close={this.close}
                        header={`Initiate legal proceedings - Appeal`}
                        body={
                            <>
                                {this.state.documentList.length > 0 && (
                                    <CustomTable
                                        version={2}
                                        {...this.props}
                                        notSortable={['filename', 'size', 'documentType']}
                                        tableName={'documentUpload'}
                                        tableType={'OBJECT'}
                                        tableSource={this.state.documentList || []}
                                        dataFilter={null}
                                        id={'index'}
                                        setLastCursor={null}
                                        resultFieldsDefault={['filename', 'size', 'documentType']}
                                        intl={this.props.intl}
                                        formatFunctions={{
                                            size: size => `${Math.floor(size * 100) / 100} KB`,
                                            extension: extension => (
                                                <img src={getIcon(extension || 'pdf')} alt={extension} />
                                            ),
                                            documentType: (documentType, object) => (
                                                <div style={{marginTop: '-5px'}}>
                                                    <SelectInput
                                                        value={documentType}
                                                        onChange={event => this.onSelectType(event, object.index)}
                                                        list={DOCUMENT_TYPES}
                                                        notAll={true}
                                                        height={25}
                                                    />
                                                    <div style={{clear: 'both'}}>{}</div>
                                                </div>
                                            ),
                                        }}
                                        count={(this.state.documentList || []).length}
                                        hideExcelButton={true}
                                        noChangePageSize={true}
                                        forehandColumn={row => (
                                            <img
                                                style={{width: 20, height: 20, cursor: 'pointer'}}
                                                alt={'Download'}
                                                src={getIcon(row.extension || 'pdf')}
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    saveAs(row.file, row.filename);
                                                }}
                                            />
                                        )}
                                        timestamp={this.state.timestamp}
                                        actions={actions}
                                    />
                                )}
                                <div style={{padding: 15, paddingLeft: 7}}>
                                    When negatively affected by a decision of the Office, it possible to request to the
                                    Board of Appeal, an independent body, the review said decision. See Article 67
                                    Regulation 2100/1994 (
                                    <span style={{color: 'green', cursor: 'pointer'}} onClick={alert}>
                                        more information on the CPVO website
                                    </span>
                                    ).
                                </div>
                                <SharedMessageProceduralRepresentative />
                                <div style={{paddingLeft: 7, marginBottom: 15, fontWeight: 800}}>
                                    {
                                        '* You have 2 months from the service of the appealed decision to file your notice of appeal.'
                                    }
                                </div>
                                <div style={{paddingLeft: 7, marginBottom: 15, fontWeight: 800}}>
                                    {
                                        '* You have 4 months from the service of the appealed decision to file your grounds of appeal.'
                                    }
                                </div>
                                <TextLabelInput
                                    onSelectionChange={this.onApplicationNumbersSelectionChange}
                                    onChange={this.onApplicationNumberChange}
                                    onEnter={this.onAddingApplicationNumber}
                                    value={this.state.applicationNumber}
                                    selectedElements={this.state.selectedApplicationNumbers}
                                    delay={300}
                                    multiple={true}
                                    label={getMandatoryAsterisk(
                                        'To which application number(s) the document is linked'
                                    )}
                                    buttonAction={this.onAddingApplicationNumber}
                                    buttonIcon={faPlus}
                                    buttonDisabled={
                                        (this.state.applicationNumberError &&
                                            this.state.applicationNumberError !== 'ADD') ||
                                        this.state.applicationNumber.length !== 8
                                    }
                                    triple={true}
                                />
                                {this.state.applicationNumberError && (
                                    <div style={{textAlign: 'center', width: 700, marginLeft: 40, marginBottom: 20}}>
                                        <Info>
                                            <div style={{display: 'inline-block', marginRight: 5}}>
                                                <FontAwesomeIcon icon={faFlag} color={'#8a6d3b'} />
                                            </div>
                                            <div style={{display: 'inline-block'}}>
                                                {errorMessages[this.state.applicationNumberError]}
                                            </div>
                                        </Info>
                                    </div>
                                )}
                                <TextInput
                                    label={getMandatoryAsterisk(`Contested decision number`)}
                                    placeholder={``}
                                    value={this.state.appealNumber}
                                    onChange={this.onAppealNumberChange}
                                    triple={true}
                                    noIcon={true}
                                />
                                <TextAreaInput
                                    label={getMandatoryAsterisk('Name and address of the appellant')}
                                    value={this.state.requesterAddress}
                                    onChange={this.onRequesterAddressChange}
                                    rows={3}
                                    triple={true}
                                    noIcon={true}
                                />
                                <TextAreaInput
                                    label={'Name and address of the procedural representative'}
                                    value={this.state.representativeAddress}
                                    onChange={this.onRepresentativeAddressChange}
                                    rows={3}
                                    triple={true}
                                    noIcon={true}
                                />
                                <SelectInput
                                    label={getMandatoryAsterisk(
                                        `Explain to which extent an amendment or cancellation of the decision is sought`
                                    )}
                                    value={this.state.DUSOption}
                                    onChange={this.onDUSOptionChange}
                                    list={EXPLAIN_OPTIONS}
                                    // buttonAction={this.showSearchModalInfo}
                                    // buttonIcon={faInfo}
                                    notAll={true}
                                    triple={true}
                                    noIcon={true}
                                />
                                {this.state.DUSOption === 'other' && (
                                    <TextAreaInput
                                        label={getMandatoryAsterisk('Please specify')}
                                        value={this.state.specify}
                                        onChange={this.onSpecifyChange}
                                        rows={3}
                                        triple={true}
                                        noIcon={true}
                                    />
                                )}
                                <DateInput
                                    label={getMandatoryAsterisk(`Date of service of the decision`)}
                                    changeDateFrom={this.onServiceOfTheDecisionChange}
                                    inputValueFrom={this.state.serviceOfTheDecision}
                                    maxDateFrom={new Date()}
                                    simple={true}
                                />
                                <TextAreaInput
                                    label={getMandatoryAsterisk('Reason of appeal')}
                                    value={this.state.groundOfAppeal}
                                    onChange={this.onGroundOfAppealChange}
                                    rows={3}
                                    triple={true}
                                    noIcon={true}
                                />
                                <SelectInput
                                    label={`Please select the language of the appeal`}
                                    list={LANGUAGE_OPTIONS}
                                    mandatory={true}
                                    noIcon={true}
                                    notAll={true}
                                    onChange={this.onLanguageOptionChange}
                                    triple={true}
                                    value={this.state.language}
                                />
                                {/*<div style={{height: 56, paddingTop: 10}}>*/}
                                {/*    <Checkbox*/}
                                {/*        clickAction={this.onAcceptanceCheckboxChange}*/}
                                {/*        label={getMandatoryAsterisk(`Yes, I confirm that I am party to proceedings`)}*/}
                                {/*        value={this.state.acceptance}*/}
                                {/*        triple={true}*/}
                                {/*        height={46}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <div style={{clear: 'both'}} />
                                <div style={{marginLeft: 7, marginTop: 10}}>
                                    <span style={{color: 'red', fontWeight: 'bold'}}>*</span>
                                    {` = compulsory field`}
                                </div>
                                <div>
                                    <InputLink
                                        label={`Notes`}
                                        icon={this.state.informationOpened ? faChevronDown : faChevronRight}
                                        clickAction={this.toggleInformation}
                                    />
                                    <div style={{clear: 'both'}}>{}</div>
                                    {this.state.informationOpened && <BrowserInfo intl={this.props.intl} />}
                                </div>
                                <div style={{clear: 'both'}}>{}</div>
                            </>
                        }
                        footer={
                            <>
                                {this.state.error ? <Error>{this.state.error}</Error> : null}
                                <div className={styles.modalFooterActions}>
                                    <div className={styles.buttonAddDocument}>
                                        <label htmlFor={'upload'}>
                                            <input
                                                type="file"
                                                name="files[]"
                                                id="upload"
                                                style={{opacity: 0, width: 0}}
                                                onChange={this.onFileUpload}
                                                accept="image/jpg, image/jpeg, image/png, application/pdf"
                                                ref={this.inputUploadRef}
                                            />
                                            <Button
                                                variation={'secondary'}
                                                clickAction={() => this.inputUploadRef.current.click()}
                                            >{`Add document`}</Button>
                                        </label>
                                    </div>
                                    <div>
                                        <Button
                                            variation={'danger'}
                                            clickAction={this.close}
                                            icon={'close'}
                                            className={styles.buttonClose}
                                        >{`Close`}</Button>
                                        <Button clickAction={this.confirmSending}>{`Proceed`}</Button>
                                    </div>
                                </div>
                            </>
                        }
                    />
                </DragAndDrop>
            </>
        );
    }
}
