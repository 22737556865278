import React from 'react';
import CustomTable from '~components/CustomTable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import HeaderLoading from '~shared/HeaderLoading';
import LoadingBar from '~components/LoadingBar';
import {ModalCustomVersion2} from '../../commonModals';
import SelectInput from '~components/SelectInput';
import VFContainerWrapper from '~components/VFContainerWrapper';
import VFModalVarietyDataSheet from '../VFModalVarietyDataSheet/VFModalVarietyDataSheet';
import {VFPDFTestDataSheet} from '../VFPDFTestDataSheet/VFPDFTestDataSheet';
import {
    checkToday,
    convertDate,
    getFileFormat,
    getFileIcon,
    GLOBAL_DATETIME_FORMAT,
    isAdvanced,
} from '../../utils/utilsVarietyFinder';
import cx from 'classnames';
import {CPVO_EXPERT_ADVICE_STATUS, downloadBlob} from '../VFPDFTestDataSheet/VFPDFTestDataSheet.utils';
import {
    faArrowCircleDown,
    faChevronLeft,
    faChevronRight,
    faEnvelope,
    faEnvelopeOpenText,
    faEyeSlash,
    faFile,
} from '@fortawesome/free-solid-svg-icons';
import graphQLClientInstance from '../../utils/axiosGraphQLClient';
import {injectIntl} from 'react-intl';
import {pdf} from '@react-pdf/renderer';
import styles from './VFModalDenominationTest.module.scss';
import XLSX from 'xlsx';
import {getDataSheetFromVidQuery} from './VFModalDenominationTestService';
import {VFPDFVarietyDataSheet} from '../VFPDFVarietyDataSheet/VFPDFVarietyDataSheet';
import AdviceRequestBadge from '../VFMyTests/AdviceRequestBadge';
import TextAreaInput from '~components/TextAreaInput';
import {formatBytes} from '../../utils';
import VFModalDenominationTestMessageActions from './VFModalDenominationTestMessageActions';

window.Buffer = window.Buffer || require('buffer').Buffer;

const TABLE_NAME = 'modalVFMyTestDetails';

const RESULT_FIELDS_DENOMINATION = [
    'denominationSpecies',
    'breedersReference',
    'varietyStatus',
    'date',
    'denominationStatus',
    'register',
    'breeder',
    'holder',
    'applicant',
    'maintainer',
    'score',
];

const RESULT_FIELDS_EUTM = ['denomination', 'applicationNumber', 'applicationDate', 'applicant', 'status', 'score'];

const TABLE_ACTIVE_FILTER = {
    DENOMINATIONS: 'denominations',
    EUTM: 'eutm',
};

interface IProps {
    close: any;
    currentIndex: number;
    intl: any;
    onButtonNextClick: any;
    onButtonPreviousClick: any;
    rowObject: any;
    totalCount: number;
    hideNavigation?: boolean;
}

interface IState {
    count: number;
    currentIndex: number;
    comments: any[];
    isSectionMessagesEnvelopeOpen: boolean;
    isModalVarietyDataSheetOpen: number | false;
    loading: number;
    messageAttachments: any[];
    textInputMessageValue: string;
    screenLoaded: boolean;
    selectedVarietyIds: number[];
    selectInputTableNumberOfResultsValue: string;
    testResults: any;
    tableResults: any;
    tableActiveFilter: string;
    timestamp: number;
}

class VFModalDenominationTest extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            count: 0,
            currentIndex: 0,
            comments: [],
            isSectionMessagesEnvelopeOpen: false,
            isModalVarietyDataSheetOpen: false,
            loading: 0,
            messageAttachments: [],
            textInputMessageValue: '',
            testResults: [],
            tableResults: [],
            tableActiveFilter: TABLE_ACTIVE_FILTER.DENOMINATIONS,
            timestamp: Date.now(),
            selectInputTableNumberOfResultsValue: localStorage.getItem(`numberOfResults${TABLE_NAME}`) || '50',
            selectedVarietyIds: [],
            screenLoaded: false,
        };
    }

    componentDidMount() {
        this.loadJSONs();
    }

    loadJSONs = () => {
        Promise.all([
            graphQLClientInstance.get(`/api/v1/tests/${this.props.rowObject && this.props.rowObject.testid}`),
            graphQLClientInstance.get(`/api/v1/tests/${this.props.rowObject && this.props.rowObject.testid}/comments`),
        ]).then(([JSONResponse, JSONResponseComments]: any) => {
            if (
                JSONResponse &&
                JSONResponse.data &&
                JSONResponse.data.testresults_list &&
                JSONResponseComments &&
                JSONResponseComments.data
            ) {
                const tableResults = JSONResponse.data.testresults_list
                    .filter((testResult: any) => testResult.eutm_tab === 0)
                    .map(this.resultsRespondToTableResults);
                this.setState(prev => ({
                    count: JSONResponse.data.testresults_list ? JSONResponse.data.testresults_list.length : 0,
                    tableResults,
                    testResults: JSONResponse.data.testresults_list.map((testResult: any) => {
                        return {
                            ...testResult,
                            denomination_species: [testResult.denomination, testResult.speciename],
                        };
                    }),
                    comments: JSONResponseComments.data,
                    screenLoaded: true,
                }));
            }
        });
    };

    resultsRespondToTableResults = ({
        applicantname: applicant,
        apl_nb: applicationNumber,
        brd_name: breeder,
        brd_ref: breedersReference,
        denomination,
        deno_status: denominationStatus,
        maintainer,
        register,
        speciename: species,
        score,
        title_holder: holder,
        var_id: varietyId,
        var_status: varietyStatus,
        var_status_date: date,
    }: any) => ({
        applicant,
        applicationDate: date,
        applicationNumber,
        breeder,
        breedersReference,
        date: convertDate(date),
        denomination: denomination,
        denominationSpecies: denomination,
        denominationStatus,
        holder,
        maintainer,
        register,
        status: varietyStatus,
        species,
        score,
        varietyId,
        varietyStatus,
    });

    readFileAsync = (file: any, commentId: number) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = (e: any) => {
                const sender = Buffer.from(e.target.result, 'base64');
                graphQLClientInstance
                    .post(
                        `api/v1/tests/comments/${commentId}/attachments`,
                        new Uint32Array(
                            sender.buffer,
                            sender.byteOffset,
                            sender.byteLength / Uint32Array.BYTES_PER_ELEMENT
                        ),
                        {
                            headers: {
                                Filename: file.name,
                                'Content-Type': file.type,
                            },
                        }
                    )
                    .then(res => {
                        resolve(res.headers.location);
                    })
                    .catch(err => {
                        reject(err);
                    });
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        });
    };

    deleteMessageAttachment = ({index}: any) =>
        this.setState(prev => {
            const updatedAttachments = prev.messageAttachments;
            updatedAttachments.splice(index, 1);
            return {
                messageAttachments: updatedAttachments,
                timestamp: Date.now(),
            };
        });

    downloadAttachment = (attachment: any) => {
        graphQLClientInstance
            .get(attachment.href, {
                responseType: 'arraybuffer',
            })
            .then((res: any) => {
                const blob = new Blob([res.data], {type: attachment.file_type});
                downloadBlob(blob, attachment.file_name);
            })
            .catch((err: any) => ERROR([err.message]));
    };

    getCPVOExpertAdviceSubtitle = () => {
        const {
            advice_request_date: adviceRequestDate,
            advice_cancel_date: adviceCancelDate,
            advice_requested_by: adviceRequestedBy,
            advice_cancelled_by: adviceCancelledBy,
        } = (this.props.rowObject && this.props.rowObject.JSONResponse) || {};
        let title = '';
        if (adviceRequestDate) {
            title = `Requested by ${adviceRequestedBy} on ${convertDate(adviceRequestDate, GLOBAL_DATETIME_FORMAT)}`;
        }
        if (adviceCancelDate) {
            title = `Cancelled by ${adviceCancelledBy} on ${convertDate(adviceCancelDate, GLOBAL_DATETIME_FORMAT)}`;
        }
        if (title) {
            return <div style={{fontWeight: 'normal', color: 'black', fontSize: 16, marginTop: 10}}>{title}</div>;
        }
        return null;
    };

    getCPVOExpertAdviceTitleDateAndName = () => {
        const {analysis_date: analysisDate, analysisby: analysisBy} =
            (this.props.rowObject && this.props.rowObject.JSONResponse) || {};
        if (analysisDate) {
            return `on ${convertDate(analysisDate, GLOBAL_DATETIME_FORMAT)}${analysisBy ? ` - ${analysisBy}` : ''}`;
        }
    };

    printExcel = () => {
        const resultGrid: any = [];
        if (this.state.tableActiveFilter === TABLE_ACTIVE_FILTER.DENOMINATIONS) {
            resultGrid.push([
                'Score',
                'Denomination',
                'Species latin name',
                'Breeder‘s reference',
                'Variety status',
                'Date',
                'Denomination status',
                'Register',
                "Breeder's name",
                'Holder',
                'Applicant',
                'Maintainer',
            ]);
            this.state.tableResults
                .slice(0, parseInt(this.state.selectInputTableNumberOfResultsValue))
                .forEach(
                    ({
                        score,
                        denomination,
                        species,
                        breedersReference,
                        varietyStatus,
                        date,
                        denominationStatus,
                        register,
                        breeder,
                        holder,
                        applicant,
                        maintainer,
                    }: any) => {
                        resultGrid.push([
                            score,
                            denomination,
                            species,
                            breedersReference,
                            varietyStatus,
                            date,
                            denominationStatus,
                            register,
                            breeder,
                            holder,
                            applicant,
                            maintainer,
                        ]);
                    }
                );
        }
        if (this.state.tableActiveFilter === TABLE_ACTIVE_FILTER.EUTM) {
            resultGrid.push(['Score', 'Denomination', 'Application number', 'Date', 'Applicant', 'Variety status']);
            this.state.tableResults
                .slice(0, parseInt(this.state.selectInputTableNumberOfResultsValue))
                .forEach(({score, denomination, applicationNumber, date, applicant, varietyStatus}: any) => {
                    resultGrid.push([score, denomination, applicationNumber, date, applicant, varietyStatus]);
                });
        }
        const workSheet = XLSX.utils.aoa_to_sheet(resultGrid);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, 'TestResults');
        XLSX.writeFile(workBook, `testresults.xlsx`);
    };

    closeModalVarietyDataSheet = () => this.setState({isModalVarietyDataSheetOpen: false});

    sectionBetweenHeaderAndTable = () => {
        return (
            <div className={styles.sectionBetweenHeaderAndTable}>
                <button
                    className={cx(
                        styles.tableFilterButton,
                        this.state.tableActiveFilter === TABLE_ACTIVE_FILTER.DENOMINATIONS &&
                            styles.tableFilterButtonActive
                    )}
                    type={`button`}
                    onClick={() => this.onTableFilterButtonClick(TABLE_ACTIVE_FILTER.DENOMINATIONS)}
                >{`Denominations`}</button>
                <button
                    className={cx(
                        styles.tableFilterButton,
                        this.state.tableActiveFilter === TABLE_ACTIVE_FILTER.EUTM && styles.tableFilterButtonActive
                    )}
                    type={`button`}
                    onClick={() => this.onTableFilterButtonClick(TABLE_ACTIVE_FILTER.EUTM)}
                >{`EUTM`}</button>
            </div>
        );
    };

    headerSectionRight = () => {
        return (
            <div style={{float: 'left'}}>
                <SelectInput
                    width={100}
                    label={``}
                    value={this.state.selectInputTableNumberOfResultsValue}
                    onChange={this.onSelectInputTableNumberOfResultsChange}
                    list={[
                        {id: '50', value: 'Top 50'},
                        {id: '100', value: 'Top 100'},
                        {id: '500', value: 'Top 500'},
                        {id: '1000000', value: 'All'},
                    ]}
                    notAll={true}
                />
                <span style={{display: 'inline-block', marginLeft: 10, marginTop: 12}}>Total: {this.state.count}</span>
            </div>
        );
    };

    tableNavigationButtons = () => {
        const hasTableSelectedRows = this.state.selectedVarietyIds.length > 0;
        return (
            <div
                className={styles.navigationBarV2LeftItem}
                onClick={hasTableSelectedRows ? this.onTableNavigationButtonDownloadSelectedClick : undefined}
                style={{
                    opacity: hasTableSelectedRows ? 1 : 0.6,
                }}
            >
                <FontAwesomeIcon style={{float: 'left'}} icon={faFile as any} />
                <div
                    style={{
                        float: 'left',
                        paddingLeft: 5,
                    }}
                >
                    {`Download Selected`}
                </div>
                <div style={{clear: 'both'}} />
            </div>
        );
    };

    onSelectedChange = (selectedVarietyIds: string[]) => {
        this.setState({selectedVarietyIds: selectedVarietyIds.map(varietyId => parseInt(varietyId))});
    };

    onSelectInputTableNumberOfResultsChange = ({
        target: {value: selectInputTableNumberOfResultsValue},
    }: React.ChangeEvent<HTMLSelectElement>) => {
        localStorage.setItem(`numberOfResults${TABLE_NAME}`, selectInputTableNumberOfResultsValue);
        this.setState({selectInputTableNumberOfResultsValue, timestamp: Date.now()});
    };

    onTableFilterButtonClick = (tableActiveFilter: string) =>
        this.setState(prev => ({
            tableActiveFilter,
            selectedVarietyIds: [],
            tableResults: prev.testResults
                .filter((testResult: any) =>
                    tableActiveFilter === TABLE_ACTIVE_FILTER.EUTM
                        ? testResult.eutm_tab === 1
                        : testResult.eutm_tab === 0
                )
                .map(this.resultsRespondToTableResults),
            timestamp: Date.now(),
        }));

    onTableDataDenominationSpeciesClick = (varietyId: number) =>
        this.setState(prev => {
            const currentIndex = prev.tableResults.findIndex((tableResult: any) => tableResult.varietyId === varietyId);
            return {isModalVarietyDataSheetOpen: varietyId, currentIndex};
        });

    onButtonDownloadPDFClick = () => {
        let testResultsDenominationsData = this.state.testResults
            ?.filter((result: any) => result['eutm_tab'] === 0)
            .slice(0, parseInt(this.state.selectInputTableNumberOfResultsValue));
        let testResultsEutmData = this.state.testResults
            .filter((result: any) => result['eutm_tab'] === 1)
            .slice(0, parseInt(this.state.selectInputTableNumberOfResultsValue));
        const {JSONResponse} = this.props.rowObject || {};
        const data = {
            test_id: JSONResponse.testid,
            advice_request_date: JSONResponse.advice_request_date,
            advice_requested_by: JSONResponse.advice_requested_by,
            advice_cancel_date: JSONResponse.advice_cancel_date,
            advice_cancelled_by: JSONResponse.advice_cancelled_by,
            request_advice_id: JSONResponse.request_advice_id,
            analysis_date: JSONResponse.analysis_date,
            analysisby: JSONResponse.analysisby,
            contact_name: JSONResponse.contact_name,
            standard_advice: JSONResponse.standard_advice,
            original_advice: JSONResponse.original_advice,
            cpvo_advice: JSONResponse.cpvo_advice,
            batch_name: JSONResponse.batch_name,
            breeder_name: JSONResponse.breedername,
            breeder_ref: JSONResponse.breederreference,
            denomination: JSONResponse.denomination,
            species_id: JSONResponse.specieid,
            class_code: JSONResponse.class_code,
            species_latin_name: JSONResponse.species_name,
            test_date: JSONResponse.test_date,
            test_response: JSONResponse.test_response,
            user_remark: JSONResponse.user_remark,
        };
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                pdf(
                    <VFPDFTestDataSheet
                        data={[
                            {
                                ...({
                                    ...data,
                                    test_results: {
                                        denominations: testResultsDenominationsData,
                                        eutm: testResultsEutmData,
                                    },
                                } as any),
                            },
                        ]}
                    />
                )
                    .toBlob()
                    .then((blob: any) => {
                        downloadBlob(blob, 'Results-TestDataSheet.pdf');
                        this.setState(prev => ({
                            loading: prev.loading - 1,
                        }));
                    });
            }
        );
    };

    onButtonMarkAsUnreadClick = () => {
        this.setState(
            prev => ({
                loading: prev.loading + 1,
            }),
            () => {
                graphQLClientInstance
                    .patch('/api/v1/tests/notifications/read-unread', {
                        test_ids: [this.props.rowObject && this.props.rowObject.testid],
                        read_unread: 0,
                    })
                    .then((JSONResponse: any) => {
                        if (JSONResponse.data && JSONResponse.data.response === 'OK') {
                            this.setState(
                                prev => ({
                                    loading: prev.loading - 1,
                                }),
                                () => this.props.close(true)
                            );
                        }
                    });
            }
        );
    };

    onTableNavigationButtonDownloadSelectedClick = () => {
        const varietyIds = this.state.selectedVarietyIds.map((id: number) => id.toString());
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                graphQLClientInstance
                    .post('/graphql', getDataSheetFromVidQuery(varietyIds))
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.datasheetFromVid &&
                            JSONResponse.data.data.datasheetFromVid.length
                        ) {
                            const validVarieties = JSONResponse.data.data.datasheetFromVid.map(
                                (item: {varietyid: any}) => item.varietyid
                            );
                            const invalidVarieties = this.state.selectedVarietyIds.filter(
                                (varietyId: number) => !validVarieties.includes(varietyId)
                            );
                            const invalidVarietiesDenominations = this.state.testResults
                                .filter((testResult: any) => invalidVarieties.includes(testResult.var_id))
                                .map((testResult: any) => testResult.denomination);
                            pdf(
                                <VFPDFVarietyDataSheet
                                    data={[...JSONResponse.data.data.datasheetFromVid]}
                                    invalidDenoms={[...invalidVarietiesDenominations]}
                                />
                            )
                                .toBlob()
                                .then((blob: any) => {
                                    downloadBlob(blob, 'SelectedResults.pdf');
                                    this.setState(prev => ({
                                        loading: prev.loading - 1,
                                        selectedVarietyIds: [],
                                        timestamp: Date.now(),
                                    }));
                                });
                        }
                    });
            }
        );
    };

    onModalVarietyDataSheetButtonNextClick = () => {
        let currentIndex = this.state.currentIndex;
        this.setState({isModalVarietyDataSheetOpen: false}, () => {
            this.setState(prev => {
                let nextTableResult = prev.tableResults[currentIndex + 1];
                return {
                    isModalVarietyDataSheetOpen: nextTableResult.varietyId,
                    currentIndex: currentIndex + 1,
                };
            });
        });
    };

    onModalVarietyDataSheetButtonPreviousClick = () => {
        let currentIndex = this.state.currentIndex;
        this.setState({isModalVarietyDataSheetOpen: false}, () => {
            this.setState(prev => {
                let nextTableResult = prev.tableResults[currentIndex - 1];
                return {
                    isModalVarietyDataSheetOpen: nextTableResult.varietyId,
                    currentIndex: currentIndex - 1,
                };
            });
        });
    };

    onSectionMessagesEnvelopeClick = () =>
        this.setState(prev => ({isSectionMessagesEnvelopeOpen: !prev.isSectionMessagesEnvelopeOpen}));

    onTextInputMessageChange = ({target: {value: textInputMessageValue}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({textInputMessageValue});

    onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length > 0) {
            const files = event.target.files;
            const selectedFile = files[0];
            if (selectedFile.size < 52428800) {
                this.setState(prev => ({
                    messageAttachments: [...prev.messageAttachments, selectedFile],
                    timestamp: Date.now(),
                }));
            }
        }
    };

    onButtonSendMessageClick = () => {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                graphQLClientInstance
                    .post(`api/v1/tests/${this.props.rowObject && this.props.rowObject.testid}/comments`, {
                        comment: this.state.textInputMessageValue,
                    })
                    .then(JSONResponse => {
                        const commentId = JSONResponse.headers.location;
                        Promise.all(
                            this.state.messageAttachments.map(attachment => this.readFileAsync(attachment, commentId))
                        ).then(() => {
                            graphQLClientInstance
                                .get(`/api/v1/tests/${this.props.rowObject && this.props.rowObject.testid}/comments`)
                                .then(JSONResponseComments => {
                                    this.setState(prev => ({
                                        comments: JSONResponseComments.data,
                                        loading: prev.loading - 1,
                                        timestamp: Date.now(),
                                        textInputMessageValue: '',
                                        messageAttachments: [],
                                    }));
                                });
                        });
                    });
            }
        );
    };

    onButtonCancelRequestClick = () => {
        this.setState(
            prev => ({
                loading: prev.loading + 1,
            }),
            () => {
                graphQLClientInstance
                    .post('/api/v1/tests/advice/cancel', {
                        test_ids: [this.props.rowObject && this.props.rowObject.testid],
                    })
                    .then(JSONResponse => {
                        if (JSONResponse && JSONResponse.data && JSONResponse.data.response === 'OK') {
                            this.setState(
                                prev => ({
                                    loading: prev.loading - 1,
                                }),
                                () => this.props.close(true)
                            );
                        }
                    });
            }
        );
    };

    onButtonRequestAdviceClick = () => {
        this.setState(
            prev => ({
                loading: prev.loading + 1,
            }),
            () => {
                graphQLClientInstance
                    .post('/api/v1/tests/advice/request', {
                        test_ids: [this.props.rowObject && this.props.rowObject.testid],
                    })
                    .then(JSONResponse => {
                        if (JSONResponse && JSONResponse.data && JSONResponse.data.response === 'OK') {
                            this.setState(
                                prev => ({
                                    loading: prev.loading - 1,
                                }),
                                () => this.props.close(true)
                            );
                        }
                    });
            }
        );
    };

    render() {
        const {denomination, testid, testDate, speciesName} = this.props.rowObject || {};
        const {
            breedername: breederName,
            breederreference: breederReference,
            contact_name: contactName,
            class_code: classCodeArray,
            request_advice_id: requestAdviceId,
            test_response: testResponse,
            user_remark: userRemark,
            cpvo_advice: cpvoAdvice,
            standard_advice: standardAdvice,
            test_date: testDateTimestamp,
        } = (this.props.rowObject && this.props.rowObject.JSONResponse) || {};
        const subtitle = `#${testid} - ${testDate} - ${contactName}`;
        let selectedElementsHash: any = {};
        this.state.selectedVarietyIds.forEach((el: any) => (selectedElementsHash[el] = true));
        const totalCount = this.state.tableResults.slice(
            0,
            parseInt(this.state.selectInputTableNumberOfResultsValue)
        ).length;
        const messageActions = VFModalDenominationTestMessageActions(this.deleteMessageAttachment);

        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <ModalCustomVersion2
                    close={this.props.close}
                    headerInnerTitlesStyle={{width: '100%'}}
                    header={
                        <div style={{margin: '0 6px', height: 40, width: '96%'}}>
                            <div style={{float: 'left', fontSize: 16}}>
                                {requestAdviceId === CPVO_EXPERT_ADVICE_STATUS.OBSERVATION ? (
                                    <FormFooterButton
                                        icon={faEyeSlash}
                                        color={buttonColor.whiteGray}
                                        clickAction={this.onButtonMarkAsUnreadClick}
                                        style={{marginLeft: 10}}
                                    >{`Mark as unread`}</FormFooterButton>
                                ) : null}
                                <FormFooterButton
                                    icon={faArrowCircleDown}
                                    color={buttonColor.whiteGray}
                                    clickAction={this.onButtonDownloadPDFClick}
                                    style={{marginLeft: 0}}
                                >{`Download PDF`}</FormFooterButton>
                            </div>
                            {!this.props.hideNavigation ? (
                                <div style={{float: 'right', marginTop: 10, fontSize: 16, marginLeft: 10}}>
                                    {this.props.currentIndex + 1} of {this.props.totalCount}{' '}
                                    <div style={{display: 'inline-block', marginLeft: 10}}>
                                        <div
                                            onClick={
                                                this.props.currentIndex === 0
                                                    ? undefined
                                                    : this.props.onButtonPreviousClick
                                            }
                                            style={{
                                                cursor: this.props.currentIndex === 0 ? 'not-allowed' : 'pointer',
                                                display: 'inline-block',
                                                marginRight: 10,
                                                opacity: this.props.currentIndex === 0 ? 0.4 : 1,
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faChevronLeft as any}
                                                color={'black'}
                                                fontSize={20}
                                            />
                                        </div>
                                        <div
                                            onClick={
                                                this.props.currentIndex === this.props.totalCount - 1
                                                    ? undefined
                                                    : this.props.onButtonNextClick
                                            }
                                            style={{
                                                cursor:
                                                    this.props.currentIndex === this.props.totalCount - 1
                                                        ? 'not-allowed'
                                                        : 'pointer',
                                                display: 'inline-block',
                                                opacity:
                                                    this.props.currentIndex === this.props.totalCount - 1 ? 0.4 : 1,
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faChevronRight as any}
                                                color={'black'}
                                                fontSize={20}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            <div style={{clear: 'both'}} />
                        </div>
                    }
                    body={
                        <div style={{minHeight: '95vh'}}>
                            <VFContainerWrapper title={`Denomination test`} subtitle={subtitle} style={{marginTop: 20}}>
                                <DataSheetFormSection title={'Summary'} layoutStyle={`green`}>
                                    <div style={{float: 'left', width: '100%'}}>
                                        <DataSheetFormFields
                                            label={'Denomination tested'}
                                            data={denomination}
                                            double={true}
                                        />
                                        <div style={{clear: 'both'}} />
                                    </div>
                                    <div style={{float: 'left', width: '100%'}}>
                                        <DataSheetFormFields label={'Species'} data={speciesName} double={true} />
                                        <div style={{clear: 'both'}} />
                                    </div>
                                    <div style={{float: 'left', width: '100%'}}>
                                        <DataSheetFormFields
                                            label={'Class'}
                                            data={(classCodeArray || []).join(', ')}
                                            double={true}
                                        />
                                        <div style={{clear: 'both'}} />
                                    </div>
                                    <DataSheetFormFields
                                        label={"Breeder's Reference"}
                                        data={breederReference}
                                        simple={true}
                                    />
                                    <DataSheetFormFields label={"Breeder's Name"} data={breederName} simple={true} />
                                    <div style={{float: 'left', width: '100%'}}>
                                        <DataSheetFormFields label={'Remark'} data={userRemark} double={true} />
                                        <div style={{clear: 'both'}} />
                                    </div>
                                </DataSheetFormSection>
                                <div style={{clear: 'both'}} />
                                {isAdvanced() ? (
                                    <DataSheetFormSection
                                        title={
                                            <>
                                                <div style={{float: 'left'}}>
                                                    <div>
                                                        {`CPVO Expert advice`}
                                                        <span
                                                            style={{
                                                                fontWeight: 'normal',
                                                                marginLeft: 7,
                                                                fontSize: 16,
                                                            }}
                                                        >
                                                            {this.getCPVOExpertAdviceTitleDateAndName()}
                                                        </span>
                                                    </div>
                                                    {this.getCPVOExpertAdviceSubtitle()}
                                                </div>
                                                <div style={{float: 'right'}}>
                                                    <AdviceRequestBadge
                                                        adviceId={requestAdviceId}
                                                        style={{float: 'left'}}
                                                    />
                                                    {requestAdviceId === CPVO_EXPERT_ADVICE_STATUS.NOT_REQUESTED &&
                                                    checkToday(testDateTimestamp) ? (
                                                        <div
                                                            className={styles.buttonRequestAdvice}
                                                            onClick={this.onButtonRequestAdviceClick}
                                                        >{`Request advice`}</div>
                                                    ) : null}
                                                    {requestAdviceId === CPVO_EXPERT_ADVICE_STATUS.REQUESTED ? (
                                                        <div
                                                            className={styles.buttonCancelRequest}
                                                            onClick={this.onButtonCancelRequestClick}
                                                        >{`Cancel request`}</div>
                                                    ) : null}
                                                </div>
                                            </>
                                        }
                                        layoutStyle={`green`}
                                    >
                                        {requestAdviceId > 1 ? (
                                            <div>
                                                {cpvoAdvice ? (
                                                    <div
                                                        className={styles.adviceBox}
                                                        /* eslint-disable-next-line react/no-danger */
                                                        dangerouslySetInnerHTML={{
                                                            __html: cpvoAdvice?.join('<br />').replace('\n', '<br />'),
                                                        }}
                                                    />
                                                ) : null}
                                                {standardAdvice ? (
                                                    <div
                                                        className={styles.adviceBox}
                                                        /* eslint-disable-next-line react/no-danger */
                                                        dangerouslySetInnerHTML={{
                                                            __html: Array.isArray(standardAdvice)
                                                                ? standardAdvice?.join('<br />').replace('\n', '<br />')
                                                                : standardAdvice,
                                                        }}
                                                    />
                                                ) : null}
                                            </div>
                                        ) : null}
                                    </DataSheetFormSection>
                                ) : null}
                                {isAdvanced() && requestAdviceId > 1 ? (
                                    <DataSheetFormSection
                                        title={
                                            <div>
                                                Messages{' '}
                                                <span
                                                    style={{float: 'right', cursor: 'pointer'}}
                                                    onClick={this.onSectionMessagesEnvelopeClick}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={
                                                            this.state.isSectionMessagesEnvelopeOpen
                                                                ? faEnvelopeOpenText
                                                                : (faEnvelope as any)
                                                        }
                                                    />
                                                </span>
                                            </div>
                                        }
                                        layoutStyle={`green`}
                                    >
                                        {this.state.isSectionMessagesEnvelopeOpen ? (
                                            <div style={{float: 'left', width: '100%', marginBottom: 10}}>
                                                <TextAreaInput
                                                    value={this.state.textInputMessageValue}
                                                    onChange={this.onTextInputMessageChange}
                                                    rows={3}
                                                    styleInputContainer={{
                                                        width: '100%',
                                                        maxWidth: 'unset',
                                                        marginLeft: -9,
                                                    }}
                                                />
                                                <div style={{clear: 'both'}} />
                                                <div>
                                                    <label className={styles.linkAddAttachments}>
                                                        <input type="file" onChange={this.onFileUpload} />
                                                        {`Add Attachments`}
                                                    </label>
                                                    <FormFooterButton
                                                        color={buttonColor.green}
                                                        clickAction={this.onButtonSendMessageClick}
                                                        disabled={!this.state.textInputMessageValue.length}
                                                    >{`Send`}</FormFooterButton>
                                                </div>
                                                <div style={{clear: 'both'}} />
                                                {this.state.messageAttachments.length ? (
                                                    <CustomTable
                                                        actions={messageActions}
                                                        formatFunctions={{
                                                            size: (size: any) => formatBytes(size),
                                                        }}
                                                        tableName={'formDuplicateSelectedResponses'}
                                                        tableType={'OBJECT'}
                                                        tableSource={this.state.messageAttachments.map(
                                                            (attachment, index) => ({
                                                                attachment,
                                                                index,
                                                                name: attachment.name,
                                                                size: attachment.size,
                                                            })
                                                        )}
                                                        name={'myTestsMessageAttachments'}
                                                        id={'name'}
                                                        resultFieldsDefault={['name', 'size']}
                                                        intl={this.props.intl}
                                                        hideExcelButton={true}
                                                        hiddenHeader={true}
                                                        timestamp={this.state.timestamp}
                                                        notSortable={true}
                                                        unlimited={true}
                                                    />
                                                ) : null}
                                            </div>
                                        ) : null}
                                        {this.state.comments.map(
                                            (
                                                {
                                                    attachments,
                                                    comment_date: commentDate,
                                                    data: message,
                                                    commenter_name: commenterName,
                                                    is_cpvo: isCPVO,
                                                }: any,
                                                commentIndex
                                            ) => (
                                                <div
                                                    key={commentDate}
                                                    style={{fontSize: 16, marginBottom: 20, color: '#255899'}}
                                                >
                                                    <div style={{fontWeight: 'bold', marginBottom: 4}}>
                                                        {convertDate(commentDate, 'DD/MM/YYYY HH:mm')} - {commenterName}
                                                        {isCPVO ? ' (CPVO)' : ''}
                                                    </div>
                                                    <div style={{paddingLeft: 15}}>{message}</div>
                                                    {attachments.map((attachment: any) => {
                                                        return (
                                                            <div
                                                                /* eslint-disable-next-line react/no-danger */
                                                                key={`attachment-${attachment.file_name}`}
                                                                style={{
                                                                    color: '#00447a',
                                                                    marginBottom: 8,
                                                                    marginTop: 8,
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => this.downloadAttachment(attachment)}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        getFileIcon(
                                                                            getFileFormat(attachment?.file_name)
                                                                        ) as any
                                                                    }
                                                                    className="align-middle"
                                                                    style={{margin: '0px 9px 0px 0px'}}
                                                                    color="#00447A"
                                                                />
                                                                <span>{attachment.file_name}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )
                                        )}
                                    </DataSheetFormSection>
                                ) : null}
                                <div style={{clear: 'both'}} />
                                <DataSheetFormSection title={'Test Results'} layoutStyle={`green`}>
                                    <DataSheetFormFields label={'Test status'} data={testResponse} double={true} />
                                    <div style={{clear: 'both'}} />
                                    <div style={{paddingBottom: 30, paddingTop: 20}}>
                                        {!this.state.screenLoaded ? <LoadingBar /> : null}
                                        {this.state.screenLoaded ? (
                                            <div className={styles.customTableWrap}>
                                                <CustomTable
                                                    additionalNavigationButtons={this.tableNavigationButtons}
                                                    forceUpdateResultFieldsDefault={true}
                                                    formatFunctions={{
                                                        denomination: (_: any, rowObject: any) => (
                                                            <div
                                                                style={{
                                                                    color: 'green',
                                                                    textDecoration: 'underline',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() =>
                                                                    this.onTableDataDenominationSpeciesClick(
                                                                        rowObject.varietyId
                                                                    )
                                                                }
                                                            >
                                                                {rowObject.denomination}
                                                            </div>
                                                        ),
                                                        denominationSpecies: (_: any, rowObject: any) => (
                                                            <>
                                                                <div
                                                                    style={{
                                                                        color: 'green',
                                                                        textDecoration: 'underline',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() =>
                                                                        this.onTableDataDenominationSpeciesClick(
                                                                            rowObject.varietyId
                                                                        )
                                                                    }
                                                                >
                                                                    {rowObject.denomination}
                                                                </div>
                                                                <div>{rowObject.species}</div>
                                                            </>
                                                        ),
                                                        register: (register: any, rowObject: any) => {
                                                            if (
                                                                this.state.tableActiveFilter ===
                                                                    TABLE_ACTIVE_FILTER.DENOMINATIONS &&
                                                                register &&
                                                                register.length > 2
                                                            ) {
                                                                return `${register.substring(
                                                                    0,
                                                                    2
                                                                )} - ${register.substring(2)}`;
                                                            }
                                                        },
                                                    }}
                                                    headerSectionRight={this.headerSectionRight}
                                                    id={'varietyId'}
                                                    intl={this.props.intl}
                                                    isNavigationButtonCompactOrDefaultViewEnabled={true}
                                                    notSortable={true}
                                                    resultFieldsDefault={
                                                        this.state.tableActiveFilter ===
                                                        TABLE_ACTIVE_FILTER.DENOMINATIONS
                                                            ? RESULT_FIELDS_DENOMINATION
                                                            : RESULT_FIELDS_EUTM
                                                    }
                                                    noShowingPageNumber={true}
                                                    noChangePageSize={true}
                                                    onSelectedChange={this.onSelectedChange}
                                                    printExcel={this.printExcel}
                                                    rowBorderLeft={true}
                                                    rowClick={() => null}
                                                    sectionBetweenHeaderAndTable={this.sectionBetweenHeaderAndTable}
                                                    selectable={true}
                                                    selectDefault={selectedElementsHash}
                                                    styleNavigationBarV2Left={{paddingTop: 12}}
                                                    tableName={TABLE_NAME}
                                                    tableType={'OBJECT'}
                                                    tableSource={this.state.tableResults.slice(
                                                        0,
                                                        parseInt(this.state.selectInputTableNumberOfResultsValue)
                                                    )}
                                                    timestamp={this.state.timestamp}
                                                    timestampSelected={this.state.timestamp}
                                                    unlimited={true}
                                                    version={2}
                                                    hoverGrey={true}
                                                    navigationBarWider={true}
                                                    fontSize={14}
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                    <div style={{clear: 'both'}} />
                                </DataSheetFormSection>
                            </VFContainerWrapper>
                            <div style={{clear: 'both'}} />
                        </div>
                    }
                    footer={<div />}
                    size={'xl'}
                />
                {this.state.isModalVarietyDataSheetOpen ? (
                    <VFModalVarietyDataSheet
                        close={this.closeModalVarietyDataSheet}
                        currentIndex={this.state.currentIndex}
                        onButtonPreviousClick={this.onModalVarietyDataSheetButtonPreviousClick}
                        onButtonNextClick={this.onModalVarietyDataSheetButtonNextClick}
                        totalCount={totalCount}
                        varietyId={this.state.isModalVarietyDataSheetOpen}
                    />
                ) : null}
            </>
        );
    }
}

export default injectIntl(VFModalDenominationTest);
