export interface DObject {
    [key: string]: string | number;
}

export const myTestsReturnTypes = {
    DENOMINATION: 'denomination',
    ADVICE: 'advice',
    REQUEST_ADVICE_ID: 'request_advice_id',
    BATCH_NAME: 'batch_name',
    CONTACT_NAME: 'contact_name',
    BREEDER_NAME: 'breedername',
    BREEDER_REFERENCE: 'breederreference',
    SPECIES_ID: 'specieid',
    CLASS_CODE: 'class_code',
    SPECIES_LATIN_NAME: 'species_name',
    TEST_DATE: 'test_date',
    TEST_RESPONSE: 'test_response',
    TEST_STATUS: 'test_status',
    TEST_ID: 'testid',
    ADDITIONAL_INFORMATION: 'user_remark',
    TEST_ARCHIVED: 'test_archived',
    USER_INPUT_NEEDED: 'user_input_needed',
};

const getValueFromMultiselectObj = (value: any, elasticField: string) => {
    const output: DObject[] = [];
    const obj: DObject = {};
    if (value && value.length > 0) {
        obj[elasticField] = value.map((el: any) => {
            if (typeof el.value === 'number') {
                return el.value;
            }
            try {
                const parsedValue = JSON.parse(el.value);
                return parsedValue.countryid.toLowerCase();
            } catch {
                return el.value.toLowerCase();
            }
        });
        output.push(obj);
    }
    return output;
};

export const getMyTestsQuery = (values: any, pageSize: number, pageIndex: number, order: string, reverse: boolean) => {
    const expertAdviceObj = getValueFromMultiselectObj(values.expertAdvice, 'advice');
    const expertAdvice = expertAdviceObj.length ? expertAdviceObj[0] : [];

    const testDateObject: any = {
        test_dt_from: '',
        test_dt_to: '',
    };
    if (values.testDateFrom) {
        testDateObject.test_dt_from = parseInt(values.testDateFrom.split('/').reverse().join(''));
    }
    if (values.testDateTo) {
        testDateObject.test_dt_to = parseInt(values.testDateTo.split('/').reverse().join(''));
    }

    const SORT_ORDER_MAP_ID: any = {
        batchName: 'BATCH_NAME',
        denomination: 'DENOMINATION',
        breederReference: 'BRED_REF',
        speciesName: 'SPECIES_NAME',
        testid: 'TESTID',
        testDate: 'TEST_DATE',
        testStatus: 'TEST_STATUS',
    };

    const output = {
        ...expertAdvice,
        ...testDateObject,
        sort_column: SORT_ORDER_MAP_ID[order],
        sort_order: reverse ? 'ASC' : 'DESC',
        deno_name: values.denomination,
        deno_search: 0,
        batch_name: values.batchName,
        batch_search: 0,
        species_name: values.speciesLatinName,
        species_search: 0,
        org_id: values.testsOrganization ? 1 : 0,
        tests_archived: values.archivedTests ? 1 : 0,
        fetch_size: pageSize,
        fetch_offset: pageSize * pageIndex,
    };

    return JSON.stringify(output);
};
