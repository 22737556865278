import React from 'react';
import ModalCustomVersion2 from '../../commonModals/ModalCustomVersion2';
import Button from '../../componentsFormV2/Button';
import TextInput from '../../componentsFormV2/TextInput';

interface IProps {
    close: any;
    setLoading: any;
    apiLostPassword: any;
    showModalRedefinePasswordOK: any;
    showContactUs: any;
}

interface IState {
    username: string;
    error: string;
}

export default class ModalAuthenticationLoginRetriveLoginVersion2 extends React.Component<IProps, IState> {
    signAndUploadEnabled: any;

    constructor(props: IProps) {
        super(props);
        this.state = {username: '', error: ''};
    }

    componentDidMount() {
        (self as any).setModalStateFunction = this.setStateFunction;
        (self as any).defaultModalState = this.state;
    }

    setStateFunction = (state: any) => this.setState(state);

    close = () => {
        this.props.close && this.props.close();
    };

    isContinueEnabled = () => this.state.username !== '';

    onUsernameChange = ({target: {value: username}}: React.ChangeEvent<HTMLSelectElement>) => this.setState({username});

    onContinue = () => {
        this.setState({error: ''}, () => {
            this.props
                .setLoading(true)
                .then(() => this.props.apiLostPassword({login: this.state.username}))
                .then((response: any) => {
                    if (response && response.data === 'OK') {
                        this.props.showModalRedefinePasswordOK();
                    } else {
                        this.setState({error: 'Username not found'});
                    }
                })
                .then(() => this.props.setLoading(false));
        });
    };

    render() {
        this.signAndUploadEnabled = false;
        return (
            <ModalCustomVersion2
                size={'sm'}
                close={this.close}
                header={`Define or re-define your password`}
                body={
                    <>
                        {this.state.error && <div style={{color: '#810202', marginBottom: 15}}>{this.state.error}</div>}
                        <TextInput
                            onChange={this.onUsernameChange}
                            value={this.state.username}
                            focus={true}
                            placeholder={'Please type your login'}
                        />
                        <div style={{textAlign: 'left', marginBottom: 15, padding: 10, fontSize: 16}}>
                            <div style={{display: 'inline', marginRight: 5}}>
                                {'If you have forgotten your password AND login, please send a message using the'}
                            </div>
                            <div
                                onClick={this.props.showContactUs}
                                style={{
                                    display: 'inline',
                                    textDecoration: 'underline',
                                    color: 'green',
                                    cursor: 'pointer',
                                }}
                            >{`following form.`}</div>
                            <div style={{clear: 'both'}}>{}</div>
                        </div>
                    </>
                }
                footer={
                    <>
                        <Button variation={'danger'} clickAction={this.close} icon={'close'}>{`Cancel`}</Button>
                        <Button
                            clickAction={this.onContinue}
                            disabled={!this.isContinueEnabled()}
                            icon={'arrowRight'}
                        >{`Continue`}</Button>
                    </>
                }
            />
        );
    }
}
