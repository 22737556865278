import React from 'react';
import styles from './BackOfficeForms.module.scss';
import {injectIntl} from 'react-intl';
import {apiSpecies} from '~commonApi/static';
import {
    apiBackOfficeFormCopy,
    apiBackOfficeFormDelete,
    apiBackOfficeFormImport,
    apiBackOfficeFormNewVersion,
    apiBackOfficeGetCropSectors,
    apiBackOfficeGetExperts,
    apiFormSearch,
    I_SEARCH_RESULT_FORM,
} from './BackOfficeFormsService';
import CustomTable from '~components/CustomTable';
import BackOfficeFormsBottomCaption from './BackOfficeFormsBottomCaption';
import BackOfficeFormsActionButtons from './BackOfficeFormsActionButtons';
import TextInput from '~components/TextInput';
import Checkbox from '~components/Checkbox';
import SelectInput from '~components/SelectInput';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {faChevronDown, faChevronRight, faInfo, faPlus} from '@fortawesome/free-solid-svg-icons';
import Empty from '~components/Empty';
import TextLabelInput from '~components/TextLabelInput';
import InputLink from '~components/InputLink';
import Title from '~components/Title';
import Option from '~components/Option';
import StatusField from '~shared/StatusField';
import {FORMAT_DATE_EASY, formatDateEasy} from '~components/FormatFunctions';
import {
    getDecodedJWT,
    isAdminUser,
    joinParams,
    loadUrlParams as loadUrlParamsFn,
    utilGetUserOfficeIdFromThirdPartyId,
} from '~utils';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationBackOffice from '../../shared/NavigationBackOffice';
import {Button} from '../../componentsFormV2';
import {ModalAlertVersion2, ModalComparisonVersion2, ModalConfirmVersion2} from '../../commonModals';
import {trackPageView} from '../../utils';

const ADVANCED_CRITERIA = [
    'formName',
    'formId',
    'expert',
    'versionCode',
    'proposalDate',
    'versionDate',
    'origin',
    'source',
    'speciesType',
    'readyXML',
];

const DEFAULT_RESULT_FIELDS = [
    'formId',
    'formName',
    'commonName',
    'status',
    'versionCode',
    'validFrom',
    'endOfValidity',
    'expert',
];

const RESULT_FIELDS_ALL = [
    'formId',
    'formName',
    'commonName',
    'status',
    'versionCode',
    'validFrom',
    'endOfValidity',
    'expert',
    'cropSectors',
];

const DATEFORMAT_CRITERIA_KEYS = ['proposalDate', 'versionDate'];

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

type TKeyOfDefaultCriteria = keyof TDefaultCriteria;

const DEFAULT_CRITERIA = {
    commonName: '',
    cropSectors: '',
    speciesName: '',
    speciesIds: '',
    type: '',
    status: '',
    formName: '',
    formId: '',
    expert: 'All',
    versionCode: '',
    proposalDate: '',
    versionDate: '',
    origin: '0',
    source: '0',
    speciesType: '0',
    readyXML: '0',
    pageNumber: 1,
    pageSize: 10,
    order: null,
    reverse: false,
    refresh: false,
    speciesNameFilter: 'starts',
};

const TYPE = {
    APPLICATION_FORM: '1',
    TECHNICAL_QUESTIONNAIRE: '2',
};
const STATUS = {
    IN_PREPARATION: '1',
    ACTIVE: '2',
    TERMINATED: '3',
};
const ORIGIN = {
    ALL: '0',
    UPOV: '1',
    CPVO: '2',
    OTHER: '3',
};
const SOURCE = {
    MY_FORMS: '0',
    CORE: '1',
    NOT_IMPORTED: '2',
};
const SPECIES_TYPE = {
    ALL: '0',
    GENERAL: '1',
    LINK_TO_SPECIES: '2',
};

const READY_XML = {
    ALL: '0',
    READY_FOR_XML_EXPORT: '1',
    NOT_READY_YET: '2',
};

const DEFAULT_COUNT = 0;

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    advancedCriteriaCount: number;
    advancedOpened: boolean;
    count: number;
    criteria: TDefaultCriteria;
    criteriaCount: number;
    forms: I_SEARCH_RESULT_FORM[];
    listByIdNameCropSectors: any;
    loading: number;
    modalAlertTitle: string | null;
    modalAlertMessage: string | null;
    modalComparison: number | null;
    modalConfirmCopy: number | null;
    modalConfirmDeletion: number | null;
    modalConfirmNewVersion: number | null;
    modalFormImport: number | null;
    nameHashCropSectors: {[key: string]: string};
    responseExpertsList: string[];
    screenLoaded: boolean;
    selectedCropSectors: {[key: string]: string};
    speciesListByIdName: {ID: any; NAME: string}[];
    speciesNameHash: {[key: string]: string};
    selectedSpecies: {[key: string]: string};
    timestamp: number;
}

class BackOfficeForms extends React.Component<IProps, IState> {
    initialCriteria: any = null;
    administrator: any;
    userOfficeId: any;
    labelHash = {};
    clearSelectionFunction = null;

    constructor(props: IProps) {
        super(props);
        this.initialCriteria = Object.assign({}, DEFAULT_CRITERIA);
        this.initialCriteria.status = [STATUS.IN_PREPARATION, STATUS.ACTIVE].join(',');
        this.administrator = isAdminUser();
        const {thirdPartyId} = getDecodedJWT() || {};
        this.userOfficeId = utilGetUserOfficeIdFromThirdPartyId(thirdPartyId);
        this.state = {
            advancedCriteriaCount: 0,
            advancedOpened: false,
            count: DEFAULT_COUNT,
            criteria: {...this.initialCriteria, order: 'formName'},
            criteriaCount: 0,
            forms: [],
            listByIdNameCropSectors: [],
            loading: 0,
            modalAlertTitle: null,
            modalAlertMessage: null,
            modalComparison: null,
            modalConfirmCopy: null,
            modalConfirmDeletion: null,
            modalConfirmNewVersion: null,
            modalFormImport: null,
            nameHashCropSectors: {},
            responseExpertsList: [],
            screenLoaded: false,
            speciesListByIdName: [],
            speciesNameHash: {},
            selectedSpecies: {},
            selectedCropSectors: {},
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'forms'});
        (self as any).setStateFunction = this.setStateFunction;
        (self as any).defaultState = this.state;
        let decoded = getDecodedJWT();
        let notAuth = (decoded.roles || []).indexOf('OABAC') === -1 && (decoded.roles || []).indexOf('OABAD') === -1;
        if (notAuth) {
            this.props.history.push('/adminProceedings');
        }
        this.loadJSONs();
    }

    loadJSONs = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                Promise.all([this.loadSpecies(), this.loadExperts(), this.fetchBackOfficeCropSectors()]).then(() =>
                    this.setState(
                        prev => ({loading: prev.loading - 1}),
                        () => this.loadUrlParams()
                    )
                );
            }
        );

    loadSpecies = () =>
        new Promise(resolve => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiSpecies()
                        .then((jsonResponse: any) => {
                            if (jsonResponse) {
                                const speciesNameHash: any = {};
                                (jsonResponse || []).forEach((speciesElement: any) => {
                                    if (!speciesNameHash[speciesElement.NAME]) {
                                        speciesNameHash[speciesElement.NAME] = [];
                                    }
                                    speciesNameHash[speciesElement.NAME].push(speciesElement.ID);
                                });
                                const speciesListByIdName = Object.keys(speciesNameHash).map(speciesName => ({
                                    ID: speciesNameHash[speciesName].join(','),
                                    NAME: speciesName,
                                }));
                                this.setState(
                                    prev => ({
                                        ...prev,
                                        loading: prev.loading - 1,
                                        speciesListByIdName,
                                        speciesNameHash,
                                        timestamp: Date.now(),
                                    }),
                                    () => resolve([])
                                );
                            }
                        })
                        .catch((error: any) => {
                            ERROR([`FormsLabelInputSpecies: ${error.message}`]);
                        });
                }
            );
        });

    fetchBackOfficeCropSectors = () =>
        new Promise(resolve => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiBackOfficeGetCropSectors()
                        .then(JSONResponse => {
                            if (JSONResponse && JSONResponse.data) {
                                const nameHashCropSectors: any = {};
                                (JSONResponse.data || []).forEach((cropSectorElement: any) => {
                                    if (!nameHashCropSectors[cropSectorElement.NAME]) {
                                        nameHashCropSectors[cropSectorElement.NAME] = [];
                                    }
                                    nameHashCropSectors[cropSectorElement.NAME].push(cropSectorElement.ID);
                                });
                                const listByIdNameCropSectors = Object.keys(nameHashCropSectors).map(
                                    cropSectorName => ({
                                        ID: nameHashCropSectors[cropSectorName].join(','),
                                        NAME: cropSectorName,
                                    })
                                );
                                const selectedCropSectors: any = {};
                                this.setState({
                                    listByIdNameCropSectors,
                                    nameHashCropSectors,
                                    selectedCropSectors,
                                    timestamp: Date.now(),
                                });
                            }
                        })
                        .catch((error: any) => {
                            ERROR([`fetchBackOfficeCropSectors: ${error.message}`]);
                        })
                        .then(() =>
                            this.setState(
                                prev => ({loading: prev.loading - 1}),
                                () => resolve([])
                            )
                        );
                }
            );
        });

    loadExperts = () =>
        apiBackOfficeGetExperts()
            .then(JSONResponse => {
                JSONResponse &&
                    JSONResponse.data &&
                    JSONResponse.data.length > 0 &&
                    this.setState({
                        responseExpertsList: [...JSONResponse.data],
                    });
            })
            .catch((error: any) => {
                ERROR([`FormsLoadExpertsError: ${error.message}`]);
            });

    loadUrlParams = () => {
        const urlParams = loadUrlParamsFn();
        const criteria = Object.assign({}, this.initialCriteria, urlParams);
        let selectedCropSectors: any = {};
        criteria?.cropSectors &&
            criteria.cropSectors
                .split(',')
                .forEach(
                    (cropSectorId: any) =>
                        (selectedCropSectors[cropSectorId] = this.state.listByIdNameCropSectors.find(
                            (el: any) => el.ID === cropSectorId
                        )?.NAME)
                );
        if (Object.keys(urlParams).length > 0) {
            const selectedSpecies: any = {};
            if (criteria.speciesIds && criteria.speciesIds !== '') {
                criteria.speciesIds
                    .split(',')
                    .forEach(
                        (speciesId: any) =>
                            (selectedSpecies[speciesId] = (
                                (this.state.speciesListByIdName || []).find((el: any) => el.ID === speciesId) || {}
                            ).NAME)
                    );
            }
            DATEFORMAT_CRITERIA_KEYS.forEach(criteriaKey => {
                if (/^[0-9]{4}(-([0-9]){2}){2}$/.test(criteria[criteriaKey])) {
                    criteria[criteriaKey] = formatDateEasy(criteria[criteriaKey]);
                }
            });
            const criteriaCount = this.countCriteria(criteria);
            const advancedCriteriaCount = this.countCriteria(criteria, true);
            this.setState(
                {
                    criteria,
                    criteriaCount,
                    advancedCriteriaCount,
                    selectedSpecies,
                    selectedCropSectors,
                    timestamp: Date.now(),
                },
                this.search
            );
        }
    };

    search = (refresh?: any) =>
        this.setState(
            prev =>
                Object.assign(
                    {},
                    {
                        ...prev,
                        loading: prev.loading + 1,
                        criteria: Object.assign(
                            {},
                            prev.criteria,
                            {refresh: !!refresh},
                            !refresh && !prev.criteria.pageNumber && {pageNumber: 1}
                        ),
                    },
                    !refresh && {selectedLabels: {}}
                ),
            () => {
                const {criteria} = this.state;
                const parsedCriteria = this.parseCriteria(criteria);
                this.buildParams(parsedCriteria);
                apiFormSearch(parsedCriteria, DEFAULT_CRITERIA)
                    .then(JSONResponse => {
                        if (JSONResponse && JSONResponse.data && JSONResponse.data.forms) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {
                                    pageNumber: prev.criteria.pageNumber || 1,
                                });
                                return Object.assign(
                                    {},
                                    !refresh && {criteria},
                                    {
                                        ...prev,
                                        forms: JSONResponse.data.forms,
                                        screenLoaded: true,
                                        timestamp: Date.now(),
                                    },
                                    JSONResponse.data.COUNT && {count: JSONResponse.data.COUNT}
                                );
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`Register search list error: ${error.message}`]);
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );

    setStateFunction = (state: any) => this.setState(state);

    toggleAdvancedSearch = () => this.setState(prevState => ({advancedOpened: !prevState.advancedOpened}));

    toggleType = (type: any) => {
        let typeArray = (this.state.criteria.type || '') === '' ? [] : this.state.criteria.type.split(',');
        if (typeArray.indexOf(type) !== -1) {
            typeArray = typeArray.filter((i: any) => i !== type);
        } else {
            typeArray.push(type);
        }
        return typeArray.join(',');
    };

    toggleStatus = (status: any) => {
        let statusArray = (this.state.criteria.status || '') === '' ? [] : this.state.criteria.status.split(',');
        if (statusArray.indexOf(status) !== -1) {
            statusArray = statusArray.filter((i: any) => i !== status);
        } else {
            statusArray.push(status);
        }
        return statusArray.join(',');
    };

    closeModalAlert = () => this.setState({modalAlertTitle: null, modalAlertMessage: null});

    closeModalComparison = () => this.setState({modalComparison: null});

    closeModalConfirmNewVersion = () => this.setState({modalConfirmNewVersion: null});

    closeModalFormImport = () => this.setState({modalFormImport: null});

    closeModalConfirmDeletion = () => this.setState({modalConfirmDeletion: null});

    updateCriteriaValue = (criteriaValue: any, callback?: any, refresh?: any) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                pageNumberChanged = criteriaValue.pageNumber && prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.criteria.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse !== undefined && prev.criteria.reverse !== criteriaValue.reverse;
                return {criteria, criteriaCount, advancedCriteriaCount};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    resetCriteria = () =>
        this.setState(prev => ({
            advancedCriteriaCount: 0,
            criteria: Object.assign({}, this.initialCriteria, prev.criteria.pageSize),
            criteriaCount: 0,
            forms: [],
            selectedSpecies: {},
            selectedCropSectors: {},
            timestamp: Date.now(),
        }));

    countCriteria = (criteria: any, advanced?: any) => {
        let count = 0;
        (advanced ? ADVANCED_CRITERIA : Object.keys(criteria)).map(key => {
            if (
                criteria[key] &&
                criteria[key] !== DEFAULT_CRITERIA[key as TKeyOfDefaultCriteria] &&
                criteria[key] !== ''
            )
                count++;
        });
        return count;
    };

    buildParams = (criteria: TDefaultCriteria) => {
        const paramArray = Object.keys(criteria)
            .filter(i => criteria[i as TKeyOfDefaultCriteria] !== DEFAULT_CRITERIA[i as TKeyOfDefaultCriteria])
            .map(key => `${key}=${criteria[key as TKeyOfDefaultCriteria]}`);
        this.props.history.replace(`/forms${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`);
    };

    parseCriteria = (criteria: any) => {
        const parsedCriteria: any = {};
        Object.keys(criteria).map(key => {
            parsedCriteria[key] = criteria[key];
        });
        return parsedCriteria;
    };

    goToEditScreen = (formId: any, type: any) => {
        let params = loadUrlParamsFn();
        const previewOrEditUri = this.buildPreviewOrEditUrl({formId, type});
        this.props.history.push(`/${previewOrEditUri}&edit=true${(params && `&${joinParams(params)}`) || ''}`);
        window.scrollTo(0, 0);
    };

    buildPreviewOrEditUrl = ({formId, type}: any) => {
        const formType = parseInt(type) === 1 ? 'AF' : 'TQ';
        return `backOfficeFormQuestions?viewFormId=${formId}&viewFormType=${formType}&viewFormLang=EN`;
    };

    deleteFormAction = () => {
        const formId = this.state.modalConfirmDeletion;
        this.setState(
            prev => ({loading: prev.loading + 1, modalConfirmDeletion: null}),
            () => {
                apiBackOfficeFormDelete({formId: formId!}).then((jsonResponse: any) => {
                    this.setState(
                        prev => ({loading: prev.loading - 1}),
                        () => {
                            jsonResponse.data === 'OK' && this.search(true);
                            jsonResponse.errorMessage &&
                                this.setState({
                                    modalAlertTitle: `Form deletion failed`,
                                    modalAlertMessage: jsonResponse.errorMessage,
                                });
                        }
                    );
                });
            }
        );
    };

    onTableIconPreviewClick = (formId: any, type: any) => {
        let params = loadUrlParamsFn();
        delete params.edit;
        const previewOrEditUri = this.buildPreviewOrEditUrl({formId, type});
        this.props.history.push(`/${previewOrEditUri}${(params && `&${joinParams(params)}`) || ''}`);
        window.scrollTo(0, 0);
    };

    onTableIconEditClick = (formId: any) => {
        const {type: formType, officeId: formOfficeId} =
            (this.state.forms || []).find((form: any) => form.formId === formId) || {};
        formOfficeId === this.userOfficeId && this.goToEditScreen(formId, formType);
    };

    onTableIconCompareClick = (formId: any) => {
        const {origin: questionnaireIdOrigin} =
            (this.state.forms || []).find((form: any) => form.formId === formId) || {};
        questionnaireIdOrigin && this.setState({modalComparison: formId});
    };

    onTableIconCopyClick = (formId: any) => {
        const {officeId: formOfficeId} = (this.state.forms || []).find((form: any) => form.formId === formId) || {};
        formOfficeId === this.userOfficeId && this.setState({modalConfirmCopy: formId});
    };

    onTableIconNewVersionClick = (formId: any) => {
        const {status: formStatus, officeId: formOfficeId} =
            (this.state.forms || []).find((form: any) => form.formId === formId) || {};
        formStatus === parseInt(STATUS.ACTIVE) &&
            formOfficeId === this.userOfficeId &&
            this.setState({modalConfirmNewVersion: formId});
    };

    onTableIconImportClick = (formId: any) => {
        const {officeId: formOfficeId} = (this.state.forms || []).find((form: any) => form.formId === formId) || {};
        formOfficeId !== this.userOfficeId && this.setState({modalFormImport: formId});
    };

    onTableIconDeleteClick = (formId: number) => {
        const {status: formStatus, officeId: formOfficeId} =
            (this.state.forms || []).find((form: any) => form.formId === formId) || {};
        formStatus === parseInt(STATUS.IN_PREPARATION) &&
            formOfficeId === this.userOfficeId &&
            this.setState({modalConfirmDeletion: formId});
    };

    onTextLabelInputCropSectorsSelectionChange = (selectedCropSectors: {[key: string]: string}) =>
        this.setState({selectedCropSectors}, () =>
            this.updateCriteriaValue({cropSectors: Object.keys(selectedCropSectors || {}).join(',')})
        );

    onCreateCopyAction = () => {
        let copyFormId = this.state.modalConfirmCopy;
        let copyFormType = (this.state.forms.find((form: any) => form.formId === copyFormId) || {}).type;
        this.setState(
            prev => ({loading: prev.loading + 1, modalConfirmCopy: null}),
            () => {
                apiBackOfficeFormCopy({formId: copyFormId!}).then((jsonResponse: any) => {
                    this.setState(
                        prev => ({loading: prev.loading - 1}),
                        () => {
                            jsonResponse.newFormId && this.goToEditScreen(jsonResponse.newFormId, copyFormType || 1);
                            jsonResponse.errorMessage &&
                                this.setState({
                                    modalAlertTitle: `Form copy failed`,
                                    modalAlertMessage: jsonResponse.errorMessage,
                                });
                        }
                    );
                });
            }
        );
    };

    onCreateFormNewVersionAction = () => {
        const formId = this.state.modalConfirmNewVersion;
        const formCurrentVersionType = (this.state.forms.find(form => form.formId === formId) || {}).type;
        this.setState(
            prev => ({loading: prev.loading + 1, modalConfirmNewVersion: null}),
            () => {
                apiBackOfficeFormNewVersion({formId: formId!})
                    .then(JSONResponse =>
                        this.setState(
                            prev => ({loading: prev.loading - 1}),
                            () => {
                                JSONResponse &&
                                    JSONResponse.formNewVersion &&
                                    this.goToEditScreen(JSONResponse.formNewVersion, formCurrentVersionType);
                                JSONResponse.errorMessage &&
                                    this.setState({
                                        modalAlertTitle: `New version failed`,
                                        modalAlertMessage: JSONResponse.errorMessage,
                                    });
                            }
                        )
                    )
                    .catch((error: any) =>
                        this.setState({
                            modalAlertTitle: `New version failed`,
                            modalAlertMessage:
                                (error && error.message) || `Something went wrong. Please try again later`,
                        })
                    );
            }
        );
    };

    onFormImportAction = () => {
        const formId = this.state.modalFormImport;
        this.setState(
            prev => ({loading: prev.loading + 1, modalFormImport: null}),
            () => {
                apiBackOfficeFormImport({formId: formId!}).then(JSONResponse =>
                    this.setState(
                        prev => ({loading: prev.loading - 1}),
                        () => {
                            JSONResponse.data === 'OK' && this.search(true);
                            JSONResponse.errorMessage &&
                                this.setState({
                                    modalAlertTitle: `Form import failed`,
                                    modalAlertMessage: JSONResponse.errorMessage,
                                });
                        }
                    )
                );
            }
        );
    };

    onButtonCreateNewFormClick = () => {
        let urlParams = loadUrlParamsFn();
        delete urlParams['viewFormId'];
        this.props.history.push(
            `/backOfficeFormGeneralSettings?viewFormId=0${(urlParams && `&${joinParams(urlParams)}`) || ''}`
        );
    };

    onCloseModalConfirmCopy = () => this.setState({modalConfirmCopy: null});

    onCommonNameInputChange = ({target: {value: commonName}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({commonName});

    onSpeciesSelectionChange = (selectedSpecies: any) =>
        this.updateCriteriaValue({speciesIds: Object.keys(selectedSpecies).join(','), speciesName: ''}, () =>
            this.setState({selectedSpecies})
        );

    onSpeciesNameChange = (speciesName: any) => this.updateCriteriaValue({speciesName});

    onSpeciesNameFilterChange = ({target: {value: speciesNameFilter}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({speciesNameFilter});

    onApplicationFormCheckboxChange = () => this.updateCriteriaValue({type: this.toggleType(TYPE.APPLICATION_FORM)});

    onTechnicalQuestionnaireCheckboxChange = () =>
        this.updateCriteriaValue({type: this.toggleType(TYPE.TECHNICAL_QUESTIONNAIRE)});

    onInPreparationCheckboxChange = () => this.updateCriteriaValue({status: this.toggleStatus(STATUS.IN_PREPARATION)});

    onActiveCheckboxChange = () => this.updateCriteriaValue({status: this.toggleStatus(STATUS.ACTIVE)});

    onTerminatedCheckboxChange = () => this.updateCriteriaValue({status: this.toggleStatus(STATUS.TERMINATED)});

    onFormNameInputChange = ({target: {value: formName}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({formName});

    onFormIdInputChange = ({target: {value: formId}}: React.ChangeEvent<HTMLInputElement>) =>
        /^[0-9]*$/.test(formId) && this.updateCriteriaValue({formId});

    onSelectedExpertChange = ({target: {value: expert}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({expert});

    onVersionCodeInputChange = ({target: {value: versionCode}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({versionCode});

    onSelectInputOriginChange = ({target: {value: origin}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({origin});

    onSourceOptionChange = (source: any) => this.updateCriteriaValue({source});

    onSpeciesTypeOptionChange = (speciesType: any) => this.updateCriteriaValue({speciesType});

    onReadyForXMLOptionChange = (readyXML: any) => this.updateCriteriaValue({readyXML});

    render() {
        const actions = BackOfficeFormsActionButtons(
            this.userOfficeId,
            this.onTableIconPreviewClick,
            this.onTableIconEditClick,
            this.onTableIconCompareClick,
            this.onTableIconCopyClick,
            this.onTableIconNewVersionClick,
            this.onTableIconImportClick,
            this.onTableIconDeleteClick
        );
        return (
            <>
                {this.state.modalComparison !== null ? (
                    <ModalComparisonVersion2
                        formId={this.state.modalComparison}
                        intl={this.props.intl}
                        close={this.closeModalComparison}
                    />
                ) : null}
                {this.state.modalConfirmCopy !== null ? (
                    <ModalConfirmVersion2
                        title={`Copy form`}
                        message={`Create a copy of this form ?`}
                        buttonName={'Copy'}
                        action={this.onCreateCopyAction}
                        close={this.onCloseModalConfirmCopy}
                    />
                ) : null}
                {this.state.modalConfirmNewVersion !== null ? (
                    <ModalConfirmVersion2
                        title={`New version`}
                        message={`Are you sure you want to create a new version of this form?`}
                        buttonName={'Yes'}
                        action={this.onCreateFormNewVersionAction}
                        close={this.closeModalConfirmNewVersion}
                    />
                ) : null}
                {this.state.modalConfirmDeletion !== null ? (
                    <ModalConfirmVersion2
                        title={`Delete form`}
                        message={`Are you sure you want to delete this form?`}
                        buttonName={'Delete'}
                        action={this.deleteFormAction}
                        close={this.closeModalConfirmDeletion}
                    />
                ) : null}
                {this.state.modalFormImport !== null ? (
                    <ModalConfirmVersion2
                        title={`Import form`}
                        message={`Are you sure you want to import this form?`}
                        buttonName={'Import'}
                        action={this.onFormImportAction}
                        close={this.closeModalFormImport}
                    />
                ) : null}
                {this.state.modalAlertMessage !== null ? (
                    <ModalAlertVersion2
                        message={this.state.modalAlertMessage}
                        title={this.state.modalAlertTitle || ''}
                        close={this.closeModalAlert}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Web Back Office`} />
                <NavigationBackOffice />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <TextInput
                            label={`Common name`}
                            filter={'Contains'}
                            infoIcon={faInfo}
                            onChange={this.onCommonNameInputChange}
                            value={this.state.criteria.commonName}
                            popOverText={`The search will find all forms that contains this text in their common name`}
                            double={true}
                            onEnter={this.search}
                        />
                        <div className={styles.buttonCreateContainer}>
                            <FormFooterButton
                                color={buttonColor.green}
                                clickAction={this.onButtonCreateNewFormClick}
                                icon={faPlus}
                            >{`Create a new form`}</FormFooterButton>
                        </div>
                        <TextLabelInput
                            label={`Species`}
                            filter={['starts']}
                            currentFilter={this.state.criteria.speciesNameFilter}
                            onFilterChange={this.onSpeciesNameFilterChange}
                            double={true}
                            onSelectionChange={this.onSpeciesSelectionChange}
                            onChange={this.onSpeciesNameChange}
                            value={this.state.criteria.speciesName}
                            selectedElements={this.state.selectedSpecies}
                            listByIdName={this.state.speciesListByIdName}
                            nameHash={this.state.speciesNameHash}
                            delay={300}
                            noInfo={true}
                            multiple={true}
                        />
                        <div style={{clear: 'both'}} />
                        <Checkbox
                            clickAction={this.onApplicationFormCheckboxChange}
                            simple={true}
                            label={`Application Form`}
                            value={
                                this.state.criteria.type &&
                                this.state.criteria.type.indexOf(TYPE.APPLICATION_FORM) !== -1
                            }
                        />
                        <Checkbox
                            clickAction={this.onTechnicalQuestionnaireCheckboxChange}
                            simple={true}
                            label={`Technical Questionnaire`}
                            value={
                                this.state.criteria.type &&
                                this.state.criteria.type.indexOf(TYPE.TECHNICAL_QUESTIONNAIRE) !== -1
                            }
                        />
                        <Empty height={48} />
                        <Checkbox
                            clickAction={this.onInPreparationCheckboxChange}
                            simple={true}
                            label={`Under preparation`}
                            value={
                                this.state.criteria.status &&
                                this.state.criteria.status.indexOf(STATUS.IN_PREPARATION) !== -1
                            }
                        />
                        <Checkbox
                            clickAction={this.onActiveCheckboxChange}
                            simple={true}
                            label={`Active`}
                            value={
                                this.state.criteria.status && this.state.criteria.status.indexOf(STATUS.ACTIVE) !== -1
                            }
                        />
                        <Checkbox
                            clickAction={this.onTerminatedCheckboxChange}
                            simple={true}
                            label={`Terminated`}
                            value={
                                this.state.criteria.status &&
                                this.state.criteria.status.indexOf(STATUS.TERMINATED) !== -1
                            }
                        />
                        <div style={{clear: 'both'}}>{}</div>
                        <InputLink
                            label={`Advanced search${
                                this.state.advancedCriteriaCount !== 0 ? ` (${this.state.advancedCriteriaCount})` : ''
                            } `}
                            icon={this.state.advancedOpened ? faChevronDown : faChevronRight}
                            clickAction={this.toggleAdvancedSearch}
                        />
                        <Empty oneLine={true} />
                        <Empty oneLine={true} />
                        {this.state.advancedOpened ? (
                            <>
                                <Title triple={true}>{`Add filters`}</Title>
                                <TextInput
                                    label={`Form name`}
                                    filter={'Contains'}
                                    placeholder={'Filter by name'}
                                    infoIcon={faInfo}
                                    onChange={this.onFormNameInputChange}
                                    onEnter={() => this.search(false)}
                                    value={this.state.criteria.formName}
                                    popOverText={`The search will find all forms that contains this text in their name`}
                                />
                                <TextInput
                                    label={`Form id`}
                                    filter={'Equals'}
                                    placeholder={'Filter by form id'}
                                    infoIcon={faInfo}
                                    onChange={this.onFormIdInputChange}
                                    onEnter={() => this.search(false)}
                                    value={this.state.criteria.formId}
                                    popOverText={`The search will find all forms with this id`}
                                />
                                <SelectInput
                                    label={`Expert`}
                                    value={this.state.criteria.expert}
                                    onChange={this.onSelectedExpertChange}
                                    list={this.state.responseExpertsList}
                                />
                                <TextInput
                                    label={`Version code`}
                                    filter={'contains'}
                                    placeholder={'Filter by version'}
                                    infoIcon={faInfo}
                                    onChange={this.onVersionCodeInputChange}
                                    onEnter={() => this.search(false)}
                                    value={this.state.criteria.versionCode}
                                    popOverText={`The search will find all forms with this version`}
                                />
                                <TextLabelInput
                                    useAsMultipleSelection={true}
                                    autoSelection={true}
                                    delay={300}
                                    label={'Crop sector'}
                                    listByIdName={this.state.listByIdNameCropSectors}
                                    nameHash={this.state.nameHashCropSectors}
                                    noInfo={true}
                                    onSelectionChange={this.onTextLabelInputCropSectorsSelectionChange}
                                    placeholder={`Agricultural etc..`}
                                    selectedElements={this.state.selectedCropSectors}
                                    removeStyleClear={true}
                                />
                                <SelectInput
                                    label={`Origin`}
                                    value={this.state.criteria.origin}
                                    onChange={this.onSelectInputOriginChange}
                                    list={[
                                        {id: ORIGIN.ALL, value: 'All'},
                                        {id: ORIGIN.UPOV, value: 'UPOV'},
                                        {id: ORIGIN.CPVO, value: 'CPVO'},
                                        {id: ORIGIN.OTHER, value: 'Other'},
                                    ]}
                                    notAll={true}
                                />
                                <Option
                                    clickAction={() => this.onSourceOptionChange(SOURCE.MY_FORMS)}
                                    simple={true}
                                    label={`My forms`}
                                    value={this.state.criteria.source === SOURCE.MY_FORMS}
                                />
                                <Option
                                    clickAction={() => this.onSourceOptionChange(SOURCE.CORE)}
                                    simple={true}
                                    label={`Core`}
                                    value={this.state.criteria.source === SOURCE.CORE}
                                />
                                <Option
                                    clickAction={() => this.onSourceOptionChange(SOURCE.NOT_IMPORTED)}
                                    simple={true}
                                    label={`Not imported`}
                                    value={this.state.criteria.source === SOURCE.NOT_IMPORTED}
                                />
                                <div style={{clear: 'both'}} />
                                <Option
                                    clickAction={() => this.onSpeciesTypeOptionChange(SPECIES_TYPE.ALL)}
                                    simple={true}
                                    label={`All`}
                                    value={this.state.criteria.speciesType === SPECIES_TYPE.ALL}
                                />
                                <Option
                                    clickAction={() => this.onSpeciesTypeOptionChange(SPECIES_TYPE.GENERAL)}
                                    simple={true}
                                    label={`General`}
                                    value={this.state.criteria.speciesType === SPECIES_TYPE.GENERAL}
                                />
                                <Option
                                    clickAction={() => this.onSpeciesTypeOptionChange(SPECIES_TYPE.LINK_TO_SPECIES)}
                                    simple={true}
                                    label={`Link to species`}
                                    value={this.state.criteria.speciesType === SPECIES_TYPE.LINK_TO_SPECIES}
                                />
                                <div style={{clear: 'both'}} />
                                <Option
                                    clickAction={() => this.onReadyForXMLOptionChange(READY_XML.ALL)}
                                    simple={true}
                                    label={`All`}
                                    value={this.state.criteria.readyXML === READY_XML.ALL}
                                />
                                <Option
                                    clickAction={() => this.onReadyForXMLOptionChange(READY_XML.READY_FOR_XML_EXPORT)}
                                    simple={true}
                                    label={`Ready for XML export`}
                                    value={this.state.criteria.readyXML === READY_XML.READY_FOR_XML_EXPORT}
                                />
                                <Option
                                    clickAction={() => this.onReadyForXMLOptionChange(READY_XML.NOT_READY_YET)}
                                    simple={true}
                                    label={`Not ready yet`}
                                    value={this.state.criteria.readyXML === READY_XML.NOT_READY_YET}
                                />
                            </>
                        ) : null}
                        <FormFooter>
                            <Button clickAction={this.resetCriteria} variation={'secondary'}>{`Clear fields`}</Button>
                            <Button clickAction={() => this.search(false)} icon={'arrowRight'}>{`Search`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    {this.state.screenLoaded ? (
                        <CustomTable
                            actions={actions}
                            actionName={`Action`}
                            bottomCaption={<BackOfficeFormsBottomCaption />}
                            count={this.state.count}
                            defaultOrder={this.state.criteria.order}
                            formatFunctions={{
                                status: (statusId: any, rowObject: any) => (
                                    <StatusField
                                        intl={this.props.intl}
                                        statusId={statusId}
                                        validFrom={rowObject.validFrom}
                                    />
                                ),
                                validFrom: FORMAT_DATE_EASY,
                                endOfValidity: FORMAT_DATE_EASY,
                            }}
                            excelFormatFunctions={{
                                status: (statusId: any) =>
                                    (statusId && this.props.intl.formatMessage({id: `label.status.${statusId}`})) || '',
                            }}
                            id={'formId'}
                            intl={this.props.intl}
                            resultFieldsDefault={DEFAULT_RESULT_FIELDS}
                            resultFieldsAll={RESULT_FIELDS_ALL}
                            reverseOrder={this.state.criteria.reverse}
                            tableName={'forms'}
                            tableSource={this.state.forms}
                            tableType={'OBJECT'}
                            timestamp={this.state.timestamp}
                            version={2}
                        />
                    ) : null}
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(BackOfficeForms);
