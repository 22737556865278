import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import MaskPhoneFax1 from './screenshots/MaskPhoneFax_1.PNG';
import MaskPhoneFax2 from './screenshots/MaskPhoneFax_2.PNG';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeMaskPhoneFaxHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>Displays an phone/fax input like the following: </p>
                    <img src={MaskPhoneFax1} alt="MaskPhoneFax" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>
                        If the value of the input is not a valid phone/fax (e.g. other than numbers, and the symbols
                        &apos;-&apos;, &apos;+&apos;), then error styles are applied to the input:
                    </p>
                    <img src={MaskPhoneFax2} alt="MaskPhoneFax" className={styles.modalHelpImage} />
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeMaskPhoneFaxHelp;
