import React from 'react';
import ModalCustomVersion2 from './ModalCustomVersion2';
import {Button} from '../componentsFormV2';
import {formatDateTimeEasy} from '~components/FormatFunctions';
import ModalPaymentInfoBodyVersion2 from './ModalPaymentInfoBodyVersion2';

const emailContent: any = {
    1: {
        subject: 'CPVO - application signed',
        body: `The application %1 was signed at %2.

---------------------------------------
    PAYMENT REQUEST
---------------------------------------

REFERENCE: %1

VARIETY: %3

AMOUNT: € %4
Thank you for your application. In accordance with the fee regulation of the Community Plant Variety Office, the applicant shall take the necessary steps for payment of the € %4 application fee prior to or on the date on which the application is filed at the Office. In case for some reason that the payment has not yet been made, please proceed with the payment using the reference cited above to the following account:

ACCOUNT:\tCOMMUNITY PLANT VARIETY OFFICE
IBAN:\t\tFR76 1790 6000 3215 8665 4800 044
BIC:\t\t\tAGRIFRPP879`,
    },
    21: {
        subject: 'Board for Plant Varieties - application signed',
        body: `The application %1 was signed at %2.

---------------------------------------
PAYMENT REQUEST
---------------------------------------
REFERENCE: %1
VARIETY: %3
AMOUNT: see Applicable fees - Raad voor plantenrassen: https://www.raadvoorplantenrassen.nl/en/plant-breeders-rights-and-or-listing/applicable-fees/

Thank you for your application. In accordance with the fee regulation of the Board for plant varieties, the applicant will receive an invoice for the payment of the application fee prior to or on the date on which the application is filed at the Board for plant varieties. In case the closing date for the application is on due course, the applicant may decide to use the undermentioned bank-account fort he fulfillment of the invoice for the application fee.
ACCOUNT:\tNAKTUINBOUW
IBAN:\t\tNL82RABO0300017057
BIC:\t\t\tRABONL2U`,
    },
};

interface IModalPaymentInfoVersion2 {
    confirm?: any;
    applicationRequestCode?: any;
    applicationSignDateString?: any;
    applicationSpeciesInput?: any;
    officeId?: any;
    onPrint?: any;
}

export default function ({
    confirm,
    applicationRequestCode,
    applicationSignDateString,
    applicationSpeciesInput,
    officeId,
    onPrint,
}: IModalPaymentInfoVersion2) {
    const emailSubject = emailContent[officeId || 1].subject
        .replace(/%1/g, applicationRequestCode)
        .replace(/%2/g, formatDateTimeEasy(applicationSignDateString));
    const emailBody = emailContent[officeId || 1].body
        .replace(/%1/g, applicationRequestCode)
        .replace(/%2/g, formatDateTimeEasy(applicationSignDateString))
        .replace(/%3/g, applicationSpeciesInput)
        .replace(/%4/g, 450);

    return (
        <ModalCustomVersion2
            close={confirm}
            header={emailSubject}
            body={
                <ModalPaymentInfoBodyVersion2
                    officeId={officeId}
                    applicationRequestCode={applicationRequestCode}
                    applicationSignDateString={applicationSignDateString}
                    applicationSpeciesInput={applicationSpeciesInput}
                />
            }
            footer={
                <>
                    <Button
                        variation={'secondary'}
                        clickAction={() =>
                            window.location.replace(
                                `mailto:?body=${encodeURIComponent(emailBody)}&subject=${encodeURIComponent(
                                    emailSubject
                                )}`
                            )
                        }
                    >{`Send by email`}</Button>
                    <Button variation={'secondary'} clickAction={onPrint}>{`Print`}</Button>
                    <Button clickAction={confirm}>{`Confirm`}</Button>
                </>
            }
        />
    );
}
