import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import styles from './ClearRadioResponse.module.scss';
import {ModalConfirmVersion2} from '../../../commonModals';
import {injectIntl} from 'react-intl';
import {IGroupQuestion, IScreenData} from '../../../types';

interface IClearRadioResponseProps {
    applicationCode?: string;
    clearRadioResponseAction?: any;
    groupIteration: number;
    intl: any;
    screenData: IScreenData;
    question: IGroupQuestion;
}

const ClearRadioResponse = ({
    applicationCode,
    clearRadioResponseAction,
    groupIteration,
    intl,
    question,
    screenData,
}: IClearRadioResponseProps) => {
    const [modalConfirmDeleteAttachments, setModalConfirmDeleteAttachments] = useState(false);

    if (!clearRadioResponseAction) return null;

    const closeModalConfirmDeleteAttachments = () => setModalConfirmDeleteAttachments(false);

    const onConfirmRemoveAttachmentsAction = () => {
        setModalConfirmDeleteAttachments(false);
        clearRadioResponseAction();
    };

    const onClearAnswerClick = () => {
        let targetQuestionHasAttachment = false;
        question.responses.forEach((response: any) =>
            response.logics
                .filter((logic: any) => logic.type === 'showquestion')
                .forEach((logic: any) => {
                    Object.entries(screenData.responsesClient).forEach(([key, value]) => {
                        if (
                            !targetQuestionHasAttachment &&
                            key &&
                            key.includes(`_Q${logic.questionIdDest}_`) &&
                            parseInt(key.split('_')[1]) === groupIteration &&
                            typeof value === 'string' &&
                            value.startsWith(`${applicationCode}_`)
                        ) {
                            targetQuestionHasAttachment = true;
                        }
                    });
                })
        );
        if (targetQuestionHasAttachment) {
            setModalConfirmDeleteAttachments(true);
        } else {
            clearRadioResponseAction();
        }
    };

    return (
        <>
            {modalConfirmDeleteAttachments && (
                <ModalConfirmVersion2
                    title={`Removing Attachments`}
                    message={`Some question(s) that include(s) attachments won't be visible due to the selected response answer. Do you confirm to remove attachments ?`}
                    buttonName={'Yes'}
                    action={onConfirmRemoveAttachmentsAction}
                    close={closeModalConfirmDeleteAttachments}
                />
            )}
            <div className={styles.clearAnswerWrap} style={{marginLeft: 38}}>
                <div className="col-sm-11">
                    <div className="clear-response-radio" style={{marginTop: '5px'}}>
                        <a onClick={onClearAnswerClick}>
                            <FontAwesomeIcon icon={faTrashAlt as any} style={{marginRight: 5}} />
                            {intl && intl.formatMessage({id: 'online.clearAnswer'})}
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default injectIntl(ClearRadioResponse);
