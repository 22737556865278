import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import PastDate1 from './screenshots/PastDate_1.PNG';
import PastDate2 from './screenshots/PastDate_2.PNG';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypePastDateHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>
                        Similar to the date input with the only difference that future dates are disabled:{' '}
                    </p>
                    <img src={PastDate1} alt="PastDate" className={styles.modalHelpImage} />
                    <p className={styles.modalHelpParagraph}>
                        It can take a <span className={styles.helpParameter}>parameter</span> like e.g.{' '}
                        <span className={styles.helpCode}>maxdate:10</span> to allow the selection of a date that is in
                        the past <span className={styles.helpBold}>or</span> 10 days after today{' '}
                    </p>
                    <img src={PastDate2} alt="PastDate" className={styles.modalHelpImage} />
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypePastDateHelp;
