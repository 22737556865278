import React from 'react';
import CustomTable from '../../components/CustomTable';
import FormFooterLinkButton from '../../componentsForm/FormFooterLinkButton';
import FormFooterButton, {buttonColor} from '../../componentsForm/FormFooterButton';
import Logo from '../../shared/Logo';
import {ModalAlertVersion2} from '../../commonModals';
import PDFValidationServiceActions from './PDFValidationServiceActions';
import Title from '../../components/Title';
import getIcon from '../../utils/icons';
import {saveAs} from 'file-saver';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {sanitize} from '~utils';
import CryptoJS from 'crypto-js';
import {apiDocumentUpload} from './PDFValidationServiceService';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import styles from '../RequestCertifiedCopies/RequestCertifiedCopies.module.scss';
import {MAX_UPLOAD_FILE_SIZE} from '../../utils/constants';
import {
    Error,
    FormFooter,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderTitle,
    MainWrapper,
} from '../../componentsLayout';
import {trackPageView} from '../../utils';

class PDFValidationService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            PDFFiles: [],
            formError: '',
            documentList: [],
            isModalValidationSuccessOpen: false,
            timestamp: null,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'PDFValidationService'});
    }

    getMandatoryAsterisk = label => (
        <>
            <div style={{display: 'inline-block', marginRight: 5}}>{label}</div>
            <div style={{display: 'inline-block', color: 'red'}}>{'*'}</div>
        </>
    );

    checkFilePDF = (file, event) => {
        LOG`file.type:${file.type}`;
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType.toLowerCase();
        const filename = sanitize(file.name);
        this.setState({error: null}, () => {
            if (
                fileTypeLowercase === 'jpeg' ||
                fileTypeLowercase === 'png' ||
                fileTypeLowercase === 'jpg' ||
                fileTypeLowercase === 'pdf'
            ) {
                if (file.size > MAX_UPLOAD_FILE_SIZE) {
                    this.setState({
                        error: 'The maximum document size for uploads is 10 MB. Please select a proper file.',
                    });
                } else {
                    const a = new FileReader();
                    a.readAsBinaryString(file);
                    a.onloadend = () => {
                        const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(a.result));
                        this.setState(
                            prev => {
                                const PDFFiles = prev.PDFFiles.slice(0);
                                PDFFiles.push({
                                    index,
                                    file,
                                    filename,
                                    size: file.size / 1024,
                                    extension: fileTypeLowercase,
                                    documentType: null,
                                });
                                return {PDFFiles, timestamp: Date.now()};
                            },
                            () => (event.target.value = null)
                        );
                    };
                }
            } else {
                this.setState({error: 'type not valid'}, () => (event.target.value = null));
            }
        });
    };

    deleteFilePDF = index =>
        this.setState(prev => ({
            PDFFiles: prev.PDFFiles.filter(i => i.index !== index),
            timestamp: Date.now(),
        }));

    closeModalValidationSuccess = () => this.setState({isModalValidationSuccessOpen: false});

    checkFile = (file, event) => {
        LOG`file.type:${file.type}`;
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType.toLowerCase();
        const filename = sanitize(file.name);
        this.setState({error: null}, () => {
            if (
                fileTypeLowercase === 'jpeg' ||
                fileTypeLowercase === 'png' ||
                fileTypeLowercase === 'jpg' ||
                fileTypeLowercase === 'pdf'
            ) {
                if (file.size > MAX_UPLOAD_FILE_SIZE) {
                    this.setState({
                        error: 'The maximum document size for uploads is 10 MB. Please select a proper file.',
                    });
                } else {
                    const a = new FileReader();
                    a.readAsBinaryString(file);
                    a.onloadend = () => {
                        const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(a.result));
                        this.setState(
                            prev => {
                                const documentList = prev.documentList.slice(0);
                                documentList.push({
                                    index,
                                    file,
                                    filename,
                                    size: file.size / 1024,
                                    extension: fileTypeLowercase,
                                    documentType: null,
                                });
                                return {documentList, timestamp: Date.now()};
                            },
                            () => (event.target.value = null)
                        );
                    };
                }
            } else {
                this.setState({error: 'type not valid'}, () => (event.target.value = null));
            }
        });
    };

    deleteFile = index =>
        this.setState(prev => ({
            documentList: prev.documentList.filter(i => i.index !== index),
            timestamp: Date.now(),
        }));

    onFileUploadHandler = (files, event) => {
        this.checkFile(files[0], event);
    };

    onButtonValidateClick = () => {
        this.setState(
            {
                formError: '',
            },
            () => {
                if (!this.state.PDFFiles.length) {
                    return this.setState({
                        formError: 'Please select the Confirmation of receipt',
                    });
                }
                if (!this.state.documentList.length) {
                    return this.setState({
                        formError: 'Please select attachments',
                    });
                }
                this.setState(
                    {
                        loading: true,
                    },
                    () => {
                        apiDocumentUpload({
                            PDFFiles: this.state.PDFFiles,
                            documentList: this.state.documentList,
                        })
                            .then(JSONResponse => {
                                if (JSONResponse && JSONResponse.data === 'OK') {
                                    this.setState(
                                        {
                                            isModalValidationSuccessOpen: true,
                                        },
                                        this.onButtonResetFormClick
                                    );
                                }
                            })
                            .then(() => this.setState({loading: false}));
                    }
                );
            }
        );
    };

    onButtonResetFormClick = () =>
        this.setState({
            PDFFiles: [],
            formError: '',
            documentList: [],
            timestamp: Date.now(),
        });

    onFileUploadPDF = event => this.onFileUploadHandlerPDF(event.target.files, event);

    onFileUploadHandlerPDF = (files, event) => {
        this.checkFilePDF(files[0], event);
    };

    onFileUpload = event => this.onFileUploadHandler(event.target.files, event);

    render() {
        const actionsPDF = PDFValidationServiceActions(this.deleteFilePDF);
        const actionsDocumentList = PDFValidationServiceActions(this.deleteFile);

        return (
            <>
                {this.state.loading ? <HeaderLoading /> : null}
                {this.state.isModalValidationSuccessOpen ? (
                    <ModalAlertVersion2
                        close={this.closeModalValidationSuccess}
                        message={`Validation of the confirmation of receipt and attachments completed successfully.`}
                        title={`Success`}
                    />
                ) : null}
                <HeaderCookies />
                <Logo />
                <HeaderTitle title={`PDF Validation Service`} />
                <MainWrapper>
                    <FormWrapper>
                        <div className={styles.main}>
                            <div className={styles.section}>
                                <Title triple={true} full={true}>
                                    {this.getMandatoryAsterisk('A. Selection of Confirmation of receipt PDF file')}
                                </Title>
                                <div style={{clear: 'both'}} />
                                <div style={{minHeight: 80, paddingTop: 15}}>
                                    {this.state.PDFFiles.length ? (
                                        <div style={{marginTop: -20, marginLeft: -25}}>
                                            <CustomTable
                                                actions={actionsPDF}
                                                version={1}
                                                notSortable={['filename']}
                                                tableName={'documentUpload'}
                                                tableType={'OBJECT'}
                                                tableSource={this.state.PDFFiles || []}
                                                dataFilter={null}
                                                id={'filename'}
                                                setLastCursor={null}
                                                resultFieldsDefault={['filename', 'size']}
                                                intl={this.props.intl}
                                                count={(this.state.PDFFiles || []).length}
                                                hideExcelButton={true}
                                                noChangePageSize={true}
                                                forehandColumn={row => (
                                                    <img
                                                        style={{width: 20, height: 20, cursor: 'pointer'}}
                                                        alt={'Download'}
                                                        src={getIcon(row.extension || 'pdf')}
                                                        onClick={event => {
                                                            event.stopPropagation();
                                                            saveAs(row.file, row.filename);
                                                        }}
                                                    />
                                                )}
                                                formatFunctions={{
                                                    filename: filename => (
                                                        <div
                                                            style={{
                                                                width: 430,
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                            }}
                                                            title={(filename || '').length > 50 ? filename : ''}
                                                        >
                                                            {filename}
                                                        </div>
                                                    ),
                                                    extension: extension => (
                                                        <img src={getIcon(extension || 'pdf')} alt={extension} />
                                                    ),
                                                    size: size => (
                                                        <div style={{width: 90}}>{`${
                                                            Math.floor(size * 100) / 100
                                                        } KB`}</div>
                                                    ),
                                                }}
                                                timestamp={this.state.timestamp}
                                            />
                                        </div>
                                    ) : (
                                        <div style={{marginLeft: 5, paddingTop: 7}}>{`Please select a PDF file`}</div>
                                    )}
                                </div>
                                <div style={{clear: 'both'}} />
                                <div style={{float: 'left'}}>
                                    <input
                                        type="file"
                                        name="files[]"
                                        id="upload"
                                        style={{opacity: 0, width: 0}}
                                        onChange={this.onFileUploadPDF}
                                        accept="image/jpg, image/jpeg, image/png, application/pdf"
                                    />
                                    <label htmlFor={'upload'}>
                                        <FormFooterButton
                                            color={buttonColor.blue}
                                            icon={faPlus}
                                            disabled={this.state.PDFFiles.length > 0}
                                        >{`Add Confirmation`}</FormFooterButton>
                                    </label>
                                </div>
                                <div style={{clear: 'both'}} />
                            </div>
                        </div>
                        <div className={styles.main}>
                            <div className={styles.section}>
                                <Title triple={true} full={true}>
                                    {this.getMandatoryAsterisk('B. Selection of attachments')}
                                </Title>
                                <div style={{clear: 'both'}} />
                                <div style={{minHeight: 80, paddingTop: 15, marginBottom: 20}}>
                                    {this.state.documentList.length ? (
                                        <div style={{marginTop: -20, marginLeft: -25}}>
                                            <CustomTable
                                                actions={actionsDocumentList}
                                                version={1}
                                                notSortable={['filename']}
                                                tableName={'documentUpload'}
                                                tableType={'OBJECT'}
                                                tableSource={this.state.documentList || []}
                                                dataFilter={null}
                                                id={'filename'}
                                                setLastCursor={null}
                                                resultFieldsDefault={['filename', 'size']}
                                                intl={this.props.intl}
                                                count={(this.state.documentList || []).length}
                                                hideExcelButton={true}
                                                noChangePageSize={true}
                                                forehandColumn={row => (
                                                    <img
                                                        style={{width: 20, height: 20, cursor: 'pointer'}}
                                                        alt={'Download'}
                                                        src={getIcon(row.extension || 'pdf')}
                                                        onClick={event => {
                                                            event.stopPropagation();
                                                            saveAs(row.file, row.filename);
                                                        }}
                                                    />
                                                )}
                                                formatFunctions={{
                                                    filename: filename => (
                                                        <div
                                                            style={{
                                                                width: 430,
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                            }}
                                                            title={(filename || '').length > 50 ? filename : ''}
                                                        >
                                                            {filename}
                                                        </div>
                                                    ),
                                                    extension: extension => (
                                                        <img src={getIcon(extension || 'pdf')} alt={extension} />
                                                    ),
                                                    size: size => (
                                                        <div style={{width: 90}}>{`${
                                                            Math.floor(size * 100) / 100
                                                        } KB`}</div>
                                                    ),
                                                }}
                                                timestamp={this.state.timestamp}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            style={{marginLeft: 5, paddingTop: 7}}
                                        >{`Please select any attachments to validate`}</div>
                                    )}
                                </div>
                                <div style={{clear: 'both'}} />
                                <div style={{float: 'left'}}>
                                    <input
                                        type="file"
                                        name="files[]"
                                        id="upload-document"
                                        style={{opacity: 0, width: 0}}
                                        onChange={this.onFileUpload}
                                        accept="image/jpg, image/jpeg, image/png, application/pdf"
                                    />
                                    <label htmlFor={'upload-document'}>
                                        <FormFooterButton
                                            color={buttonColor.blue}
                                            icon={faPlus}
                                        >{`Add document(s)`}</FormFooterButton>
                                    </label>
                                </div>
                                <div style={{clear: 'both'}} />
                            </div>
                        </div>
                        <FormFooter>
                            {this.state.formError || this.state.error ? (
                                <Error>{this.state.formError || this.state.error}</Error>
                            ) : null}
                            <FormFooterButton
                                color={buttonColor.green}
                                clickAction={this.onButtonValidateClick}
                            >{`Validate`}</FormFooterButton>
                            <FormFooterLinkButton
                                clickAction={this.onButtonResetFormClick}
                            >{`Reset form`}</FormFooterLinkButton>
                        </FormFooter>
                    </FormWrapper>
                </MainWrapper>
            </>
        );
    }
}

export default injectIntl(withRouter(PDFValidationService));
