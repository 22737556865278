import React from 'react';

interface IIconInfoProps {
    color?: string;
    style?: any;
}

export default function ({color, style: userStyle}: IIconInfoProps) {
    return (
        <svg
            width="18"
            height="18"
            style={{...(userStyle || {})}}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M9 12.75V8.24997" stroke={color || '#00457C'} strokeWidth="1.5" strokeLinecap="round" />
            <path
                d="M9 5.24997C9.41421 5.24997 9.75 5.58576 9.75 5.99997C9.75 6.41418 9.41421 6.74997 9 6.74997C8.58579 6.74997 8.25 6.41418 8.25 5.99997C8.25 5.58576 8.58579 5.24997 9 5.24997Z"
                fill={color || '#00457C'}
            />
            <path
                d="M5.25 2.50348C6.38964 1.84415 7.68338 1.49795 9 1.49998C13.1422 1.49998 16.5 4.85773 16.5 8.99998C16.5 13.1422 13.1422 16.5 9 16.5C4.85775 16.5 1.5 13.1422 1.5 8.99998C1.5 7.63423 1.86525 6.35248 2.5035 5.24998"
                stroke={color || '#00457C'}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
}
