import {API_SERVERLESS, getFetch, postForm, postFetch} from '~commonApi';
import {computeGetUploadPreSignedURLPromises, computePostFilesToPreSignedURLPromises} from '../../../utils/requests';

export function apiDocumentUpload(jsonRequest, documentList, actionName) {
    let fileFolder = '';

    const submitRequest = () => {
        return postFetch(
            `${API_SERVERLESS}/publicSearch/v3/legalDocumentUpload/${actionName}`,
            true,
            Object.assign({}, jsonRequest, {fileFolder})
        );
    };

    if (!documentList.length) {
        return getFetch(`${API_SERVERLESS}/publicSearch/v3/documentUploadCreateFolder`, true).then(({folder}) => {
            fileFolder = folder;
            return submitRequest();
        });
    } else {
        return getFetch(`${API_SERVERLESS}/publicSearch/v3/documentUploadCreateFolder`, true)
            .then(({folder}) => {
                fileFolder = folder;
                return Promise.all(computeGetUploadPreSignedURLPromises({documentList})).then(
                    getUploadPreSignedURLResponses => {
                        return Promise.all(
                            computePostFilesToPreSignedURLPromises({
                                getUploadPreSignedURLResponses,
                                documentList,
                            })
                        ).then(() => {
                            const uploadArray = [];
                            documentList.forEach((document, index) => {
                                const formData = new FormData();
                                formData.append('files[]', getUploadPreSignedURLResponses[index]?.identifier);
                                formData.append('filename', encodeURIComponent(document.filename));
                                formData.append(`documentType`, document.documentType);
                                formData.append(`folder`, folder);
                                formData.append(`index`, index);
                                uploadArray.push(
                                    postForm(`${API_SERVERLESS}/publicSearch/v3/documentUploadFile`, true, formData)
                                );
                            });
                            return Promise.all(uploadArray);
                        });
                    }
                );
            })
            .then(submitRequest);
    }
}
