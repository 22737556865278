import {API_SERVERLESS, getFetch} from '~commonApi';

export interface IVDCCaseJurisprudenceMessage {
    title: string;
    suitability: string;
    opinionDate: string;
    message: string;
}

export function apiVDCJurisprudenceMessagesGet(testId: string): Promise<{
    testNumber: number;
    messages: IVDCCaseJurisprudenceMessage[];
}> {
    return getFetch(`${API_SERVERLESS}/vdc/v4/VDCJurisprudenceMessagesGet?testId=${testId}`, true);
}
