const LOCAL_STORAGE_IMPERSONATE_KEY = 'impersonate';

export interface ILocalStorageImpersonateItem {
    name: string;
    contactId: string;
    lastLogin: number;
}

const createImpersonateItem = (name: string, contactId: string) => ({name, contactId, lastLogin: Date.now()});

export const updateLocalStorageImpersonate = (name: string, contactId: string) => {
    let impersonateArray = [];
    let storedImpersonate = JSON.parse(localStorage.getItem(LOCAL_STORAGE_IMPERSONATE_KEY) as string);
    if (!storedImpersonate) {
        impersonateArray.push(createImpersonateItem(name, contactId));
    } else {
        impersonateArray = [...storedImpersonate];
        let impersonateIndex = storedImpersonate.findIndex(
            (impersonateItem: ILocalStorageImpersonateItem) => impersonateItem.name === name
        );
        if (impersonateIndex === -1) {
            impersonateArray.push(createImpersonateItem(name, contactId));
        } else {
            impersonateArray[impersonateIndex] = createImpersonateItem(name, contactId);
        }
    }
    localStorage.setItem(LOCAL_STORAGE_IMPERSONATE_KEY, JSON.stringify(impersonateArray));
};

export const getLocalStorageImpersonate = () => {
    let impersonate = JSON.parse(localStorage.getItem(LOCAL_STORAGE_IMPERSONATE_KEY) as string);
    return impersonate ? impersonate : [];
};

export const deleteLocalStorageImpersonate = (contactId: any) => {
    let impersonateArray = JSON.parse(localStorage.getItem(LOCAL_STORAGE_IMPERSONATE_KEY) as string);
    let impersonateIndex = impersonateArray.findIndex(
        (impersonate: ILocalStorageImpersonateItem) => impersonate.contactId === contactId
    );
    impersonateArray.splice(impersonateIndex, 1);
    localStorage.setItem(LOCAL_STORAGE_IMPERSONATE_KEY, JSON.stringify(impersonateArray));
};
