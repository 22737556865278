import React from 'react';
import cx from 'classnames';
import styles from './FormFooterLinkButton.module.scss';

export default function ({children, clickAction, hoverUnderline}) {
    return (
        <div className={cx(styles.link, hoverUnderline && styles.hoverUnderline)} onClick={clickAction}>
            {children}
        </div>
    );
}
