import React, {useState} from 'react';
import {faList} from '@fortawesome/free-solid-svg-icons';
import {Popover} from 'react-tiny-popover';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './WizardContextualMenu.module.scss';
import cx from 'classnames';

const WizardContextualMenu = props => {
    const {editable, labelPopup, menuType} = props;
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const getClassName = () => {
        switch (menuType) {
            case 'response':
                return styles.menuTypeResponse;
            case 'question':
                return styles.menuTypeQuestion;
            case 'group':
                return styles.menuTypeGroup;
            default:
                return styles.menuType;
        }
    };

    return (
        <>
            {editable && (
                <div className={cx(styles.contextualMenuInner, getClassName())}>
                    <div
                        className={getClassName()}
                        style={{
                            display: editable ? 'inline-block' : 'none',
                            minWidth: menuType === 'response' ? 25 : 0,
                            verticalAlign: 'top',
                        }}
                    >
                        {
                            <Popover
                                isOpen={isPopoverOpen}
                                positions={'bottom'}
                                onClickOutside={() => setIsPopoverOpen(!isPopoverOpen)}
                                content={() => (
                                    <div style={{display: 'f'}} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                        <div
                                            className="custom-popover"
                                            style={{
                                                background: 'white',
                                                color: 'black',
                                                borderRadius: '4px',
                                                maxWidth: '250px',
                                                zIndex: 99,
                                                position: 'relative',
                                                borderStyle: 'solid',
                                                borderWidth: '1px',
                                                borderColor: '#ccc',
                                            }}
                                        >
                                            <div>
                                                {(labelPopup || []).map(action => (
                                                    <ul className={styles.menuPopup}>
                                                        <li className={styles.menuElement} onClick={action.handler}>
                                                            <div className={styles.menuElementIcon}>
                                                                <FontAwesomeIcon icon={action.icon} />
                                                            </div>
                                                            <div className={styles.menuElementLabel}>
                                                                {action.label}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            >
                                <div
                                    style={{display: 'inline-block', height: 21}}
                                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                                >
                                    <FontAwesomeIcon icon={faList} size="lg" color="green" />
                                </div>
                            </Popover>
                        }
                    </div>
                </div>
            )}
        </>
    );
};

export default WizardContextualMenu;
