import React from 'react';
import styles from './ModalBackgroundVersion2.module.scss';

export default function ModalBackgroundVersion2(props) {
    let zIndexNumber = 1040;
    if (props.plus) {
        if (props.plus === 1) {
            zIndexNumber = 1054;
        } else {
            zIndexNumber = 1060;
        }
    }
    if (props.plus2) {
        zIndexNumber = 1160;
    }

    return (
        <div
            onKeyDown={props.onKeyDown}
            onClick={props.click}
            className={styles.background}
            aria-hidden="true"
            tabindex="-1"
            style={{zIndex: zIndexNumber}}
        >
            {}
        </div>
    );
}
