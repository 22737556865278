import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import Length1 from './screenshots/Length_1.PNG';
import Length2 from './screenshots/Length_2.PNG';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeLengthHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>
                        It requires a <span className={styles.helpParameter}>parameter</span> like e.g.{' '}
                        <span className={styles.helpCode}>min:1;max:20</span> that defines the minimum and maximum
                        characters length that this text input should have. Error styles are applied when min and max
                        conditions are not met.
                    </p>
                    <img src={Length1} alt="Number" className={styles.modalHelpImage} />
                    <img src={Length2} alt="Number" className={styles.modalHelpImage} />
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeLengthHelp;
