import {API_SERVERLESS, getFetch} from '~commonApi';

export function apiCommunicationCenterGetDocumentList(communicationId: any) {
    return getFetch(`${API_SERVERLESS}/communicationCentre/v3/getDocumentList/${communicationId}`, true);
}

export function apiCommunicationCenterDownloadDocument({communicationId, serverIndex}: any = {}) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentre/v3/downloadDocument/${communicationId}/${serverIndex}`,
        true
    );
}
