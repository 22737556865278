import React, {useState} from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import TextAreaInput from '~components/TextAreaInput';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeResponseMandatoryOnFirstOccurrenceOfGroup = ({
    close,
    onCreateOrUpdateLogicType,
    responseLogicValues,
    responseLogicType,
    iteration,
}) => {
    const {logicParam, applyToRemark} = responseLogicValues || {};
    const {defaultParam, name} = responseLogicType || {};
    const [paramValue, setParamValue] = useState(logicParam !== undefined ? logicParam : null);
    const onParamValueChange = ({target: {value: param}}) => setParamValue(param);

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    {defaultParam && (
                        <div>
                            <TextAreaInput
                                label="Default Parameters"
                                value={defaultParam}
                                width={550}
                                rows={7}
                                disabled={true}
                            />
                        </div>
                    )}
                    <div>
                        <TextAreaInput
                            label="Parameters"
                            value={paramValue}
                            onChange={onParamValueChange}
                            width={550}
                            rows={7}
                        />
                    </div>
                </>
            }
            footer={
                <>
                    <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                    <Button
                        icon={'arrowRight'}
                        clickAction={() =>
                            onCreateOrUpdateLogicType({
                                param: paramValue,
                                applyToRemark: applyToRemark !== undefined ? applyToRemark : false,
                                responseIdDest: null,
                                questionIdDest: null,
                                messageId: null,
                                iteration,
                            })
                        }
                    >{`Save`}</Button>
                </>
            }
        />
    );
};

export default ModalLogicTypeResponseMandatoryOnFirstOccurrenceOfGroup;
