import React, {useState} from 'react';
import {Button} from '../../../../../../componentsFormV2';
import Empty from '~components/Empty';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormFooter} from '../../../../../../componentsLayout';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import Label from '~components/Label';
import {ModalAlertVersion2} from '../../../../../../commonModals';
import ModalBasicSearchDetails from '../../../../CommonComponents/ModalFileSearchDetails/ModalFileSearchDetails';
import TextLabelInput from '../../../../../../components/TextLabelInput';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import styles from './SearchCasesBasicFiltersCriteria.module.scss';
import {SearchFilesBasicFiltersCriteriaData} from '../../SearchCasesPageBasicPVRCASELAW';

const modalScreenTypes = {
    INFO: 'INFO',
};

interface SearchCasesBasicFiltersCriteriaProps {
    criteria: SearchFilesBasicFiltersCriteriaData;
    filesListByIdNames: {ID: string; NAME: string}[] | null;
    filesNamesHash: {[key: string]: string[]} | null;
    updateFilesCriteriaValue: (
        // eslint-disable-next-line no-undef
        criteriaValue: Partial<SearchFilesBasicFiltersCriteriaData>,
        callback?: () => void,
        refresh?: boolean
    ) => void;
    resetCriteria: () => void;
    search: (shouldRefresh: boolean, urlLoad?: boolean) => void;
}

export default function SearchCasesBasicFiltersCriteria(props: SearchCasesBasicFiltersCriteriaProps) {
    const [showBasicSearchDetailsModal, setShowBasicSearchDetailsModal] = useState(false);
    const [infoMessage, setInfoMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);

    const onFileNamesSelectionChange = () => {
        props.updateFilesCriteriaValue({fileName: ''});
    };

    const onFileNamesChange = (fileName: string) => {
        props.updateFilesCriteriaValue({fileName});
    };

    const onFileNameFilterChange = ({target: {value: fileNameFilter}}: React.ChangeEvent<HTMLSelectElement>) => {
        props.updateFilesCriteriaValue({fileNameFilter});
    };

    const onSearchClick = () => {
        if (props.criteria.fileName.length === 0) {
            setInfoMessage(`Please type a search word and try again.`);
            setModalScreen(modalScreenTypes.INFO);
        } else {
            props.search(false);
        }
    };

    const closeInfoModal = () => {
        setInfoMessage(null);
        setModalScreen(null);
    };

    return (
        <>
            <>
                {modalScreen === modalScreenTypes.INFO ? (
                    <ModalAlertVersion2 title={'Info'} message={infoMessage || ''} close={closeInfoModal} />
                ) : null}
                {showBasicSearchDetailsModal ? (
                    <ModalBasicSearchDetails close={() => setShowBasicSearchDetailsModal(false)} />
                ) : null}
                <TextLabelInput
                    extraFilterLabelHash={{all: 'All', summary: 'Summary', decision: 'Decision'}}
                    filter={['all', 'summary', 'decision']}
                    placeholder={`please type one or more relative keywords here`}
                    onSelectionChange={onFileNamesSelectionChange}
                    onChange={onFileNamesChange}
                    value={props.criteria.fileName}
                    currentFilter={props.criteria.fileNameFilter}
                    onFilterChange={onFileNameFilterChange}
                    double={true}
                    delay={300}
                    outsideLabel={'Search in Files'}
                    outsideLabelWidth={184}
                    listByIdName={props.filesListByIdNames}
                    nameHash={props.filesNamesHash}
                    noInfo={true}
                    useAsMultipleSelection={false}
                    isOnModal={true}
                    listMaxHeight={370}
                    maxElementsInList={100}
                    hideCounter={true}
                />
                <div style={{clear: 'both'}} />
                <div className={styles.labelBasicSearchDetails}>
                    <Label double={true}>
                        <a
                            onClick={() => setShowBasicSearchDetailsModal(true)}
                            title={`Click here to find out how the full text research is carried out`}
                        >
                            <FontAwesomeIcon icon={faQuestionCircle as IconDefinition} color="green" />
                        </a>
                        <span> {`Click here to find out how the full text research is carried out`}</span>
                    </Label>
                    <Empty height={5} width={85} />
                </div>
                <FormFooter>
                    <Button clickAction={() => props.resetCriteria()} variation={'secondary'}>{`Clear fields`}</Button>
                    <Button clickAction={onSearchClick}>{`Search`}</Button>
                </FormFooter>
                <div style={{clear: 'both'}} />
            </>
        </>
    );
}
