import {API_SERVERLESS, getFetch, getFile} from '~commonApi';
import {IResponseFormDatas} from '../../types';

export function apiFormDatas({
    applicationRequestId,
    formType,
    verificationCode,
}: any = {}): Promise<IResponseFormDatas> {
    let query = '';
    applicationRequestId && formType && (query = `applicationRequestId=${applicationRequestId}&formType=${formType}`);
    verificationCode && (query = `verificationCode=${verificationCode}`);
    return getFetch(`${API_SERVERLESS}/online/v2/datas${query ? `?${query}` : ''}`).then((jsonResponse: any) => {
        if (jsonResponse) {
            if (jsonResponse.datas && jsonResponse.datas.countries) {
                jsonResponse.datas.ue_countries = jsonResponse.datas.countries.filter(
                    ({UEMEMBER}: any = {}) => parseInt(UEMEMBER) === 1
                );
            }
            return jsonResponse;
        }
    });
}

export function apiGetFormDraftWithVerificationCode({verificationCode}: {verificationCode: string}) {
    return getFetch(
        `${API_SERVERLESS}/online/v3/formDraftGetWithVerificationCode?verificationCode=${verificationCode}`,
        true
    );
}

export function apiGetFormApplicationName({applicationRequestId, formType, confidential}: any) {
    return getFetch(
        `${API_SERVERLESS}/online/v3/applicationRequestFormName/${applicationRequestId}/${formType}/${confidential}`,
        true
    );
}

export function apiGetFormDraft({applicationId, formId, formType, formSequence = '0', authToken}: any) {
    return getFetch(
        `${API_SERVERLESS}/online/v3/formDraftGet?applicationId=${applicationId}&formId=${formId}&formType=${formType}&formSequence=${formSequence}&languageId=${`EN`}`,
        true,
        {Authorization: `Bearer ${authToken}`}
    );
}

export function apiDocumentDownload({filename, applicationRequestCode}: any = {}) {
    return getFetch(
        `${API_SERVERLESS}/online/v3/attachmentDownload/${applicationRequestCode}/${encodeURIComponent(filename)}`,
        true
    );
}

export function apiCommonGetPDF({URL, species, applicationNumber}: any) {
    return getFile(
        `${API_SERVERLESS}/common/v3/getPDF?url=${encodeURIComponent(URL)}&species=${encodeURIComponent(
            species
        )}&applicationNumber=${applicationNumber}&pageNumbers=1`,
        true
    );
}
