import React from 'react';
import {injectIntl, IntlShape} from 'react-intl';
import VFModalVarietyDataSheet from '../VFModalVarietyDataSheet/VFModalVarietyDataSheet';
import {trackPageView} from '../../utils';
import {RouteComponentProps} from 'react-router-dom';

interface IProps extends RouteComponentProps {
    intl: IntlShape;
}

interface IState {
    varietyId: number | null;
}

class VFVarieties extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            varietyId: null,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'Search Varieties'});
        const {varietyid} = this.props.match.params as any;
        if (varietyid) {
            this.setState({varietyId: varietyid});
        }
    }

    render() {
        return (
            <div>
                {this.state.varietyId ? (
                    <VFModalVarietyDataSheet renderOnlyModalBody={true} varietyId={this.state.varietyId} />
                ) : null}
            </div>
        );
    }
}

export default injectIntl(VFVarieties);
