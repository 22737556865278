import React from 'react';
import {
    Footer,
    FormWrapper,
    HeaderCookies,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationVarietyFinder from '../../shared/NavigationVarietyFinder';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {trackPageView} from '../../utils';

const tableauUrl =
    'https://public.tableau.com/views/ContentVF_Statisticforwebsite_16397512901350/Database' +
    '?:embed=y&amp;:display_count=yes&amp;:showVizHome=no&amp;:host_url=https%3A%2F%2Fpublic.tableausoftware.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;' +
    ':animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;:loadOrderID=0';

interface IProps {
    history: any;
    intl: any;
}

interface IState {}

class VFStatistics extends React.Component<IProps, IState> {
    componentDidMount() {
        trackPageView({documentTitle: 'Statistics'});
        window.scrollTo(0, 0);
    }

    onHeaderSearchLinkClick = (searchListItem: any) => {
        this.props.history.push({
            pathname: '/varieties',
            state: {searchListItem},
        });
    };

    render() {
        return (
            <>
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Variety Finder`} hostApplication={'Variety Finder'} />
                <NavigationVarietyFinder onSearchLinkClick={this.onHeaderSearchLinkClick} />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'} formInnerWidth={'lg'}>
                        <iframe
                            src={tableauUrl}
                            allowFullScreen={true}
                            className="tableauViz"
                            frameBorder={0}
                            marginHeight={0}
                            marginWidth={0}
                            style={{
                                display: 'block',
                                margin: 'auto',
                                padding: '0px',
                                border: 'none',
                                width: '815px',
                                height: '1180px',
                                borderRadius: '4px',
                            }}
                        />
                    </FormWrapper>
                </MainWrapper>
                <Footer hostApplication={'Variety Finder'} />
            </>
        );
    }
}

export default withRouter(injectIntl(VFStatistics));
