import React from 'react';
import styles from './BackOfficeFormGeneralSettings.module.scss';
import {injectIntl} from 'react-intl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popover} from 'react-tiny-popover';
import {
    faCheck,
    faInfo,
    faLightbulb,
    faList,
    faPencilAlt,
    faPlus,
    faPrint,
    faRedo,
    faSearch,
    faTasks,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {apiSpecies} from '~commonApi/static';
import {
    apiBackOfficeAddSpecies,
    apiBackOfficeDeleteSpecies,
    apiBackOfficeFormCreate,
    apiBackOfficeGetCropSectors,
    apiBackOfficeGetForm,
    apiBackOfficeSaveForm,
    apiBackOfficeUpdateLabel,
    apiBackOfficeUpdateSpecies,
} from './BackOfficeFormGeneralSettingsService';
import BackOfficeFormTabs, {TAB} from '~shared/BackOfficeFormTabs';
import FormWithBackground, {backgrounds} from '~shared/FormWithBackground';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import SelectInput from '~components/SelectInput';
import Checkbox from '~components/Checkbox';
import DateInput from '~components/DateInput';
import TextAreaInput from '~components/TextAreaInput';
import CustomTable from '~components/CustomTable';
import BackOfficeFormGeneralSettingsActionButtons from './BackOfficeFormGeneralSettingsActionButtons';
import {
    ModalComparisonVersion2,
    ModalConfirmVersion2,
    ModalLabelPreviewVersion2,
    ModalLabelSearchVersion2,
} from '../../commonModals';
import ModalUsedInVersion2, {rowType} from '../../commonModals/ModalUsedInVersion2';
import ModalLabelVersion2, {getLabelTypeId} from '../../commonModals/ModalLabelVersion2';
import Error from '~components/Error';
import {renderHTML} from '~common/format';
import {CPVO_THIRDPARTY_ID, getDecodedJWT, isAdminUser, joinParams, loadUrlParams, trackPageView} from '../../utils';
import {prepareFormCreateBody, prepareFormUpdateBody} from './BackOfficeFormGeneralSettings.utils';
import {Footer, HeaderLoading, HeaderLogoMenu, HeaderTitleAndVersion, MainWrapper} from '../../componentsLayout';
import NavigationBackOffice from '../../shared/NavigationBackOffice';

type ValueOf<T> = T[keyof T];

const STATUS = {
    IN_PREPARATION: 1,
    ACTIVE: 2,
    TERMINATED: 3,
};

const FORM_TYPE = {
    APPLICATION_FORM: 1,
    TECHNICAL_QUESTIONNAIRE: 2,
};

const SPECIE_ORDER_OPERATION = {
    FIRST: 'FIRST',
    PREVIOUS: 'PREVIOUS',
    NEXT: 'NEXT',
    LAST: 'LAST',
};

const ERROR_MANDATORY_FIELD = {
    FORM_NAME: 'FORM_NAME',
    COMMON_NAME: 'COMMON_NAME',
    VERSION_CODE: 'VERSION_CODE',
};

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    form: {
        id: any;
        idFormulaireRoot: any;
        name: {
            id: any;
            value: any;
        };
        common: {
            id: any;
            value: any;
        };
        cropSectors: any;
        header: {
            id: any;
            value: any;
        };
        footer: {
            id: any;
            value: any;
        };
        office: any;
        officeName: any;
        species: any;
        status: number;
        validityBegin: any;
        validityEnd: any;
        expert: any;
        remarkModif: any;
        versionCode: any;
        versionDate: any;
        type: any;
        proposeOtherOptionOnPDF: any;
        questionnaireIdOrigin: any;
        origin: any;
        proposalDate: any;
        mappingCode: any;
        questionnaireIdFirst: any;
        paramXmlReady: any;
        modifRemark: any;
        root: any;
        jsonMapping: any;
    };
    errorMandatoryField: ValueOf<typeof ERROR_MANDATORY_FIELD> | null;
    errorMandatoryFields: boolean;
    isModalConfirmInformApplicantsOfNewTQVersionOpen: boolean;
    isPopoverCompareWithPreviousVersionNotAvailableOpen: any;
    listByIdNameCropSectors: any;
    loading: number;
    modalUsedIn: any;
    modalLabel: any;
    modalConfirmDeletion: any;
    modalComparison: any;
    modalLabelSearch: any;
    modalPrintForm: any;
    modalPreview: any;
    nameHashCropSectors: any;
    speciesName: string;
    selectedSpecies: any;
    selectedCropSectors: any;
    speciesListByIdName: any;
    speciesNameHash: any;
    textLabelInputCropSectorValue: string;
    timestamp: number;
}

class BackOfficeFormGeneralSettings extends React.Component<IProps, IState> {
    refreshModalSearchFunction = null;
    initialForm: any = {};
    administrator: any = false;
    params: any = null;

    constructor(props: IProps) {
        super(props);
        this.administrator = isAdminUser();
        this.params = loadUrlParams();
        this.state = {
            form: {
                id: null,
                idFormulaireRoot: null,
                name: {
                    id: null,
                    value: null,
                },
                common: {
                    id: null,
                    value: null,
                },
                cropSectors: null,
                header: {
                    id: null,
                    value: null,
                },
                footer: {
                    id: null,
                    value: null,
                },
                office: null,
                officeName: null,
                species: null,
                status: 1,
                validityBegin: null,
                validityEnd: null,
                expert: null,
                remarkModif: null,
                versionCode: null,
                versionDate: null,
                type: FORM_TYPE.APPLICATION_FORM,
                proposeOtherOptionOnPDF: null,
                questionnaireIdOrigin: null,
                origin: 1,
                proposalDate: null,
                mappingCode: null,
                questionnaireIdFirst: null,
                paramXmlReady: null,
                modifRemark: null,
                root: null,
                jsonMapping: null,
            },
            errorMandatoryField: null,
            errorMandatoryFields: false,
            isModalConfirmInformApplicantsOfNewTQVersionOpen: false,
            isPopoverCompareWithPreviousVersionNotAvailableOpen: null,
            listByIdNameCropSectors: [],
            loading: 0,
            modalUsedIn: null,
            modalLabel: null,
            modalConfirmDeletion: null,
            modalComparison: null,
            modalLabelSearch: null,
            modalPrintForm: null,
            modalPreview: null,
            nameHashCropSectors: {},
            speciesName: '',
            selectedSpecies: {},
            selectedCropSectors: {},
            speciesListByIdName: null,
            speciesNameHash: null,
            textLabelInputCropSectorValue: '',
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'backOfficeFormGeneralSettings'});
        (self as any).setStateFunction = this.setStateFunction;
        (self as any).defaultState = this.state;
        const {viewFormId, viewFormType, viewFormLang} = this.params;
        Number(viewFormId) === 0
            ? this.setState(
                  prev => ({
                      ...prev,
                      form: {
                          ...prev.form,
                          id: 0,
                      },
                  }),
                  () => this.fetchBackOfficeCropSectors()
              )
            : this.loadJSONs(viewFormId, viewFormType, viewFormLang);
    }

    setStateFunction = (state: any) => this.setState(state);

    loadJSONs = (formId: any, type: any, language: any) => {
        let callback = type === 'TQ' ? this.fetchBackOfficeCropSectors : null;
        Promise.all([this.fetchBackOfficeForm(formId, type, language, callback), this.fetchSpecies()]).catch(error =>
            LOG([`Error loading data: ${error}`])
        );
    };

    fetchBackOfficeForm = (formId: string, type: string, language: string, callback?: any) =>
        new Promise(resolve => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiBackOfficeGetForm(formId, type, language)
                        .then(JSONResponse => {
                            if (JSONResponse && JSONResponse.form) {
                                this.initialForm = {...JSONResponse.form};
                                delete this.initialForm.species;
                                this.setState({
                                    form: {
                                        ...JSONResponse.form,
                                    },
                                });
                            }
                        })
                        .then(() => {
                            this.setState(
                                prev => ({loading: prev.loading - 1}),
                                () => {
                                    callback && callback();
                                    resolve([]);
                                }
                            );
                        });
                }
            );
        });

    fetchSpecies = () =>
        new Promise(resolve => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiSpecies()
                        .then((jsonResponse: any) => {
                            if (jsonResponse) {
                                const speciesNameHash: any = {};
                                (jsonResponse || []).forEach((speciesElement: any) => {
                                    if (!speciesNameHash[speciesElement.NAME]) {
                                        speciesNameHash[speciesElement.NAME] = [];
                                    }
                                    speciesNameHash[speciesElement.NAME].push(speciesElement.ID);
                                });
                                const speciesListByIdName = Object.keys(speciesNameHash).map(speciesName => ({
                                    ID: speciesNameHash[speciesName].join(','),
                                    NAME: speciesName,
                                }));
                                const selectedSpecies: any = {};
                                const selectedSpeciesKeyArray = Object.keys(this.state.selectedSpecies || {});
                                speciesListByIdName
                                    .filter((i: any) => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                                    .forEach(item => (selectedSpecies[item.ID] = item.NAME));
                                this.setState({
                                    speciesListByIdName,
                                    speciesNameHash,
                                    selectedSpecies,
                                    timestamp: Date.now(),
                                });
                            }
                        })
                        .catch((error: any) => {
                            ERROR([`fetchSpecies: ${error.message}`]);
                        })
                        .then(() => {
                            this.setState(prev => ({loading: prev.loading - 1}));
                            resolve([]);
                        });
                }
            );
        });

    fetchBackOfficeCropSectors = () =>
        new Promise(resolve => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiBackOfficeGetCropSectors()
                        .then(JSONResponse => {
                            if (JSONResponse && JSONResponse.data) {
                                const nameHashCropSectors: any = {};
                                (JSONResponse.data || []).forEach((cropSectorElement: any) => {
                                    if (!nameHashCropSectors[cropSectorElement.NAME]) {
                                        nameHashCropSectors[cropSectorElement.NAME] = [];
                                    }
                                    nameHashCropSectors[cropSectorElement.NAME].push(cropSectorElement.ID);
                                });
                                const listByIdNameCropSectors = Object.keys(nameHashCropSectors).map(
                                    cropSectorName => ({
                                        ID: nameHashCropSectors[cropSectorName].join(','),
                                        NAME: cropSectorName,
                                    })
                                );
                                const selectedCropSectors: any = {};
                                (this.state.form?.cropSectors || []).forEach(
                                    (item: any) => (selectedCropSectors[item.ID] = item.NAME)
                                );
                                this.setState({
                                    listByIdNameCropSectors,
                                    nameHashCropSectors,
                                    selectedCropSectors,
                                    timestamp: Date.now(),
                                });
                            }
                        })
                        .catch((error: any) => {
                            ERROR([`fetchBackOfficeCropSectors: ${error.message}`]);
                        })
                        .then(() => {
                            this.setState(prev => ({loading: prev.loading - 1}));
                            resolve([]);
                        });
                }
            );
        });

    showModalUsedIn = (labelId: any) => this.setState({modalUsedIn: labelId});

    closeModalUsedIn = () => this.setState({modalUsedIn: null});

    showModalLabel = (labelId: number | null, typeId: string, fieldIdKey: string, fieldHTMLKey: string) =>
        this.setState({modalLabel: {labelId, typeId, fieldIdKey, fieldHTMLKey}});

    closeModalLabel = () =>
        this.setState({modalLabel: null}, this.state.modalLabelSearch && this.refreshModalSearchFunction);

    openModalConfirmDeletion = (formId: any) => this.setState({modalConfirmDeletion: formId});

    closeModalConfirmDeletion = () => this.setState({modalConfirmDeletion: null});

    closeModalConfirmInformApplicantsOfNewTQVersion = () =>
        this.setState({isModalConfirmInformApplicantsOfNewTQVersionOpen: false});

    openModalComparison = (formId: any) => this.setState({modalComparison: formId});

    closeModalComparison = () => this.setState({modalComparison: null});

    showModalPreview = (valueHTML: string) => this.setState({modalPreview: valueHTML});

    closeModalPreview = () => this.setState({modalPreview: null});

    showModalLabelSearch = (typeId: string, fieldIdKey: string, fieldHTMLKey: string) =>
        this.setState({modalLabelSearch: {typeId, fieldIdKey, fieldHTMLKey}});

    closeModalLabelSearch = () => this.setState({modalLabelSearch: null});

    setLabelId = (label: any, fieldIdKey: string, fieldHTMLKey: string) => {
        const {labelId, valueHTML, labelValueHTML, typeId} = label;
        return this.updateForm({
            [fieldIdKey]: {id: labelId, value: valueHTML || labelValueHTML, type: typeId},
        });
    };

    clearLabelUse = (fieldIdKey: string) => this.updateForm({[fieldIdKey]: {id: null, value: null, type: null}});

    updateForm = (formData: any) =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiBackOfficeUpdateLabel(formData)
                    .then((response: any) => {
                        response.data === 'OK' &&
                            this.setState(prev => {
                                const form = Object.assign({}, {...prev.form}, {...formData});
                                return {form, loading: prev.loading - 1};
                            });
                    })
                    .catch((error: any) => {
                        ERROR([`FormGeneralSettings: ${error.message}`]);
                    });
            }
        );

    addSpecie = () => {
        const {id: formId} = this.state.form;
        let specieId = '';
        if (this.state.selectedSpecies && Object.keys(this.state.selectedSpecies).length > 0) {
            specieId = Object.keys(this.state.selectedSpecies)[0];
        }
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiBackOfficeAddSpecies({formId, specieId})
                    .then(response => {
                        this.setState(prev => {
                            return {
                                ...prev,
                                form: {
                                    ...prev.form,
                                    species: response.species || [],
                                },
                                selectedSpecies: {},
                                timestamp: Date.now(),
                            };
                        });
                    })
                    .catch((error: any) => {
                        ERROR([`FormGeneralSettings: ${error.message}`]);
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    deleteSpecie = () => {
        const specieId = this.state.modalConfirmDeletion;
        const {id: formId} = this.state.form;
        this.setState(
            prev => ({loading: prev.loading + 1, modalConfirmDeletion: null}),
            () => {
                apiBackOfficeDeleteSpecies({formId, specieId})
                    .then(response => {
                        this.setState(prev => {
                            return {
                                ...prev,
                                form: {
                                    ...prev.form,
                                    species: response.species || [],
                                },
                                selectedSpecies: {},
                                timestamp: Date.now(),
                            };
                        });
                    })
                    .catch((error: any) => {
                        ERROR([`FormGeneralSettings: ${error.message}`]);
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    moveSpecieToNextPosition = (order: any, specieId: string) =>
        this.moveSpecieToPosition(order, specieId, SPECIE_ORDER_OPERATION.NEXT);

    moveSpecieToFirstPosition = (order: any, specieId: string) =>
        this.moveSpecieToPosition(order, specieId, SPECIE_ORDER_OPERATION.FIRST);

    moveSpecieToPreviousPosition = (order: any, specieId: string) =>
        this.moveSpecieToPosition(order, specieId, SPECIE_ORDER_OPERATION.PREVIOUS);

    moveSpecieToLastPosition = (order: any, specieId: string) =>
        this.moveSpecieToPosition(order, specieId, SPECIE_ORDER_OPERATION.LAST);

    moveSpecieToPosition = (order: any, specieId: string, mode: string) => {
        const {id: formId} = this.state.form;
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiBackOfficeUpdateSpecies({formId, specieId, mode})
                    .then(response => {
                        this.setState(prev => {
                            prev.form.species = response.species || [];
                            return {
                                ...prev,
                                form: {
                                    ...prev.form,
                                    species: response.species || [],
                                },
                                selectedSpecies: {},
                                timestamp: Date.now(),
                            };
                        });
                    })
                    .catch((error: any) => {
                        ERROR([`FormGeneralSettings: ${error.message}`]);
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    resetToDefault = () =>
        this.setState(prev => {
            return {form: Object.assign({}, {...this.initialForm, species: [...prev.form.species]})};
        });

    saveChanges = () => {
        const {form} = this.state;
        const hasEmptyMandatoryFields =
            (form.name && form.name.value === null) || (form.common && form.common.value === null) || !form.versionCode;
        if (hasEmptyMandatoryFields) {
            let errorMandatoryField = null;
            !form.versionCode && (errorMandatoryField = ERROR_MANDATORY_FIELD.VERSION_CODE);
            form.common && form.common.value === null && (errorMandatoryField = ERROR_MANDATORY_FIELD.COMMON_NAME);
            form.name && form.name.value === null && (errorMandatoryField = ERROR_MANDATORY_FIELD.FORM_NAME);
            return this.setState({errorMandatoryFields: true, errorMandatoryField});
        } else {
            this.setState({errorMandatoryFields: false, errorMandatoryField: null});
        }
        let loggedInUser = getDecodedJWT();
        const {thirdPartyId} = loggedInUser || {};
        if (
            this.state.form.questionnaireIdOrigin &&
            this.state.form.validityBegin &&
            thirdPartyId === CPVO_THIRDPARTY_ID
        ) {
            return this.setState({isModalConfirmInformApplicantsOfNewTQVersionOpen: true});
        }
        this.saveAction();
    };

    saveAction = (informApplicants?: boolean) =>
        this.setState(
            prev => ({loading: prev.loading + 1, isModalConfirmInformApplicantsOfNewTQVersionOpen: false}),
            () => {
                if (this.state.form.id === 0) {
                    apiBackOfficeFormCreate(
                        prepareFormCreateBody(this.state.form, this.state.selectedCropSectors)
                    ).then((jsonResponse: any) => {
                        const {formId} = jsonResponse || {};
                        const params = Object.assign({}, this.params);
                        delete params.viewFormId;
                        delete params.viewFormLang;
                        params.edit = 'true';
                        params.viewFormLang = 'EN';
                        params.viewFormType = this.state.form.type === FORM_TYPE.APPLICATION_FORM ? 'AF' : 'TQ';
                        window.location.href = `/backOfficeFormGeneralSettings?viewFormId=${formId}${
                            (params && `&${joinParams(params)}`) || ''
                        }`;
                    });
                } else {
                    apiBackOfficeSaveForm(
                        prepareFormUpdateBody(this.state.form, this.state.selectedCropSectors, informApplicants)
                    )
                        .then(() => (this.initialForm = Object.assign({}, this.state.form)))
                        .then(() => this.setState(prev => ({loading: prev.loading - 1})))
                        .catch((error: any) => {
                            ERROR([`FormGeneralSettings: ${error.message}`]);
                        });
                }
            }
        );

    onButtonPrintClick = () => {
        const {viewFormId, viewFormType, viewFormLang} = this.params;
        window.open(
            `/backOfficePdf?formId=${viewFormId}&formType=${viewFormType}&formLanguage=${viewFormLang || 'EN'}`
        );
    };

    onGoBackToSearch = () => {
        const params = Object.assign({}, this.params);
        delete params.viewFormId;
        delete params.viewFormType;
        delete params.viewFormLang;
        delete params.edit;
        this.props.history.push(`/forms${(params && `?${joinParams(params)}`) || ''}`);
        window.scrollTo(0, 0);
    };

    onSpeciesNameChange = (speciesName: string) => this.setState({speciesName});

    onSpeciesSelectionChange = (selectedSpecies: any) => this.setState({selectedSpecies, speciesName: ''});

    onTextLabelInputCropSectorValueChange = (textLabelInputCropSectorValue: any) =>
        this.setState({textLabelInputCropSectorValue});

    onTextLabelInputCropSectorsSelectionChange = (selectedCropSectors: any) =>
        this.setState({selectedCropSectors, textLabelInputCropSectorValue: ''});

    onFormDataChange = (valueObject: any) => this.setState(prev => ({form: Object.assign(prev.form, valueObject)}));

    onTypeChange = ({target: {value: type}}: React.ChangeEvent<HTMLSelectElement>) => this.onFormDataChange({type});

    onOriginChange = ({target: {value: origin}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.onFormDataChange({origin});

    onVersionCodeChange = ({target: {value: versionCode}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({versionCode});

    onMappingCodeChange = ({target: {value: mappingCode}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({mappingCode});

    onExpertChange = ({target: {value: expert}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({expert: expert.slice(0, 5)});

    onVersionDateChange = (versionDate: any) => this.onFormDataChange({versionDate});

    onValidityBeginChange = (validityBegin: any) => this.onFormDataChange({validityBegin});

    onValidityEndChange = (validityEnd: any) => this.onFormDataChange({validityEnd});

    onProposalDateChange = (proposalDate: any) => this.onFormDataChange({proposalDate});

    onRemarkModifChange = ({target: {value: remarkModif}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({remarkModif});

    onProposeOtherOptionOnPDFChange = () =>
        this.onFormDataChange({proposeOtherOptionOnPDF: !this.state.form.proposeOtherOptionOnPDF});

    onParamXmlReadyChange = () => this.onFormDataChange({paramXmlReady: !this.state.form.paramXmlReady});

    onRootChange = () => this.onFormDataChange({root: !this.state.form.root});

    onLanguageChange = (language: any) => {
        let urlParams: any = loadUrlParams();
        delete urlParams.viewFormLang;
        urlParams['viewFormLang'] = (language || 'en').toUpperCase();
        window.location.href = `/backOfficeFormGeneralSettings?${joinParams(urlParams)}`;
    };

    renderPopupElements(
        labelId: number | null,
        typeId: string,
        fieldIdKey: string,
        fieldHTMLKey: string,
        isFormInPreparation: any
    ) {
        const elementsArr = [];
        isFormInPreparation &&
            elementsArr.push({
                label: 'Search label',
                icon: faSearch,
                handler: () => this.showModalLabelSearch(typeId, fieldIdKey, fieldHTMLKey),
            });

        !labelId &&
            isFormInPreparation &&
            elementsArr.push({
                label: 'Add label',
                icon: faPlus,
                handler: () => this.showModalLabel(0, typeId, fieldIdKey, fieldHTMLKey),
            });

        labelId &&
            elementsArr.push({
                label: 'Show label',
                icon: faPencilAlt,
                handler: () => this.showModalLabel(labelId, typeId, fieldIdKey, fieldHTMLKey),
            });

        labelId &&
            isFormInPreparation &&
            elementsArr.push({
                label: 'Clear label use',
                icon: faTimes,
                handler: () => this.clearLabelUse(fieldIdKey),
            });

        labelId &&
            elementsArr.push({
                label: 'Used in',
                icon: faLightbulb,
                handler: () => this.showModalUsedIn(labelId),
            });

        return elementsArr;
    }

    render() {
        const form = this.state.form || {};
        const isCreateNewFormOperation = Number(this.params.viewFormId) === 0;
        const {status} = form;
        const actions = BackOfficeFormGeneralSettingsActionButtons(
            this.moveSpecieToFirstPosition,
            this.moveSpecieToPreviousPosition,
            this.moveSpecieToNextPosition,
            this.moveSpecieToLastPosition,
            this.openModalConfirmDeletion,
            form.species && form.species.length
        );
        // const formHasChanged = isCreateNewFormOperation
        //     ? true
        //     : Object.keys(this.initialForm || {}).filter(
        //           key => this.state.form && this.initialForm[key] !== this.state.form[key]
        //       ).length > 0;
        const formHasSpecies = !!(form.species && Array.isArray(form.species) && form.species.length > 0);
        const isFormLocked = [STATUS.ACTIVE, STATUS.TERMINATED].includes(status);
        const isFormInPreparation = status === STATUS.IN_PREPARATION;

        return (
            <>
                {this.state.modalLabelSearch ? (
                    <ModalLabelSearchVersion2
                        intl={this.props.intl}
                        target={this.state.modalLabelSearch}
                        setLabelId={this.setLabelId}
                        // setRefreshModalSearchFunction={this.setRefreshModalSearchFunction}
                        showModalPreview={this.showModalPreview}
                        showModalUsedIn={this.showModalUsedIn}
                        showModalLabel={this.showModalLabel}
                        closeModalLabel={this.closeModalLabel}
                        close={this.closeModalLabelSearch}
                    />
                ) : null}
                {this.state.modalLabel ? (
                    <ModalLabelVersion2
                        intl={this.props.intl}
                        label={this.state.modalLabel}
                        setLabelId={this.setLabelId}
                        showModalUsedIn={this.showModalUsedIn}
                        close={this.closeModalLabel}
                    />
                ) : null}
                {this.state.modalUsedIn ? (
                    <ModalUsedInVersion2
                        elementId={this.state.modalUsedIn}
                        intl={this.props.intl}
                        type={rowType.LABEL}
                        close={this.closeModalUsedIn}
                    />
                ) : null}
                {this.state.modalConfirmDeletion !== null ? (
                    <ModalConfirmVersion2
                        title={`Delete specie`}
                        message={`Are you sure you want to delete this specie ?`}
                        buttonName={'DELETE'}
                        action={this.deleteSpecie}
                        close={this.closeModalConfirmDeletion}
                    />
                ) : null}
                {this.state.modalComparison !== null ? (
                    <ModalComparisonVersion2
                        formId={this.state.modalComparison}
                        intl={this.props.intl}
                        close={this.closeModalComparison}
                    />
                ) : null}
                {this.state.modalPreview !== null ? (
                    <ModalLabelPreviewVersion2
                        intl={this.props.intl}
                        close={this.closeModalPreview}
                        valueHTML={this.state.modalPreview}
                    />
                ) : null}
                {this.state.isModalConfirmInformApplicantsOfNewTQVersionOpen ? (
                    <ModalConfirmVersion2
                        action={() => this.saveAction(true)}
                        close={this.closeModalConfirmInformApplicantsOfNewTQVersion}
                        message={`Do you want to inform the applicants having draft in preparation about the release of the new version of the TQ?`}
                        title={`Inform applicants`}
                        buttonName={`Yes`}
                        actionOnNoButtonClick={() => this.saveAction(false)}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Web Back Office`} />
                <NavigationBackOffice activeTitle={'Forms'} />
                <MainWrapper>
                    <FormWithBackground
                        title={isCreateNewFormOperation ? `Create New Form` : `Form General Settings`}
                        subTitle={
                            <div
                                style={{paddingBottom: 15}}
                                /* eslint-disable-next-line react/no-danger */
                                dangerouslySetInnerHTML={{__html: form && form.name && form.name.value}}
                            />
                        }
                        subTitle2={''}
                        background={backgrounds.backgroundOABO}
                        languages={['nl', 'en', 'fr', 'de', 'es']}
                        onLanguageChange={this.onLanguageChange}
                    >
                        <BackOfficeFormTabs
                            tabId={TAB.GENERAL_SETTINGS}
                            loading={this.state.loading}
                            formStatus={form.status}
                            formId={form.id}
                            formValidFrom={form.validityBegin}
                            onGoBackToSearch={this.onGoBackToSearch}
                            officeId={form.office}
                        />
                        {!isCreateNewFormOperation && (
                            <section className={styles.formTopActions}>
                                <FormFooterButton
                                    color={buttonColor.whiteGray}
                                    clickAction={this.onButtonPrintClick}
                                    icon={faPrint}
                                >{`Print`}</FormFooterButton>
                                <Popover
                                    isOpen={this.state.isPopoverCompareWithPreviousVersionNotAvailableOpen}
                                    positions={'top'}
                                    onClickOutside={() =>
                                        this.setState(prev => ({
                                            isPopoverCompareWithPreviousVersionNotAvailableOpen:
                                                !prev.isPopoverCompareWithPreviousVersionNotAvailableOpen,
                                        }))
                                    }
                                    content={() => (
                                        <div
                                            style={{
                                                backgroundColor: 'lightgray',
                                                paddingLeft: 5,
                                                paddingRight: 5,
                                                borderRadius: 3,
                                            }}
                                        >
                                            {`Form does not have a previous version`}
                                        </div>
                                    )}
                                >
                                    <div
                                        style={{
                                            opacity:
                                                this.state.form && !this.state.form.questionnaireIdOrigin ? 0.5 : 1,
                                        }}
                                    >
                                        <FormFooterButton
                                            color={buttonColor.whiteGray}
                                            clickAction={() =>
                                                this.state.form && !this.state.form.questionnaireIdOrigin
                                                    ? this.setState({
                                                          isPopoverCompareWithPreviousVersionNotAvailableOpen: true,
                                                      })
                                                    : this.openModalComparison(this.state.form.id)
                                            }
                                            icon={faTasks}
                                        >{`Compare with previous version`}</FormFooterButton>
                                    </div>
                                </Popover>
                            </section>
                        )}
                        <DataSheetFormSection title={'Form fields'} disableToggle={true}>
                            {isFormLocked && (
                                <div style={{marginBottom: 15, marginTop: 10}}>
                                    <div style={{display: 'inline-block'}}>
                                        <FontAwesomeIcon icon={faInfo as any} color={'green'} />
                                    </div>
                                    <div style={{display: 'inline-block', marginLeft: 10}}>
                                        Form locked due to its status, limited changes.
                                    </div>
                                </div>
                            )}
                            <DataSheetFormFields
                                label={'Form name'}
                                mandatory={true}
                                html={renderHTML(form.name && form.name.value)}
                                loading={this.state.loading}
                                double={true}
                                labelIcon={faList}
                                labelPopup={this.renderPopupElements(
                                    form.name && form.name.id,
                                    getLabelTypeId('Questionnaire name'),
                                    'name',
                                    'formNamelValueHTML',
                                    isFormInPreparation
                                )}
                            />
                            <DataSheetFormFields
                                label={'Common name'}
                                mandatory={true}
                                html={renderHTML(form.common && form.common.value)}
                                loading={this.state.loading}
                                double={true}
                                labelIcon={faList}
                                labelPopup={this.renderPopupElements(
                                    form.common && form.common.id,
                                    getLabelTypeId('Questionnaire name'),
                                    'common',
                                    'commonNameLabelValueHTML',
                                    isFormInPreparation
                                )}
                            />
                            <DataSheetFormFields
                                label={'Header'}
                                html={renderHTML(form.header && form.header.value)}
                                loading={this.state.loading}
                                double={true}
                                labelIcon={faList}
                                labelPopup={this.renderPopupElements(
                                    form.header && form.header.id,
                                    getLabelTypeId('Header'),
                                    'header',
                                    'headerLabelValueHTML',
                                    isFormInPreparation
                                )}
                            />
                            <DataSheetFormFields
                                label={'Footer'}
                                html={renderHTML(form.footer && form.footer.value)}
                                loading={this.state.loading}
                                double={true}
                                labelIcon={faList}
                                labelPopup={this.renderPopupElements(
                                    form.footer && form.footer.id,
                                    getLabelTypeId('Footer'),
                                    'footer',
                                    'footerLabelValueHTML',
                                    isFormInPreparation
                                )}
                            />
                        </DataSheetFormSection>
                        <div style={{height: 20}} />
                        <TextInput label={`Form ID`} value={form.id} disabled={true} />
                        <SelectInput
                            label={`Type`}
                            notAll={true}
                            value={form.type}
                            onChange={this.onTypeChange}
                            loading={this.state.loading}
                            list={[
                                {id: 1, value: 'Application Form'},
                                {id: 2, value: 'Technical questionnaire'},
                            ]}
                            disabled={!isCreateNewFormOperation}
                        />
                        <SelectInput
                            label={`Origin`}
                            notAll={true}
                            value={form.origin}
                            onChange={this.onOriginChange}
                            loading={this.state.loading}
                            list={[
                                {id: 1, value: 'UPOV'},
                                {id: 2, value: 'Office'},
                                {id: 3, value: 'Other'},
                            ]}
                            disabled={!isCreateNewFormOperation && status !== STATUS.IN_PREPARATION}
                        />

                        <TextInput
                            label={`Version code`}
                            value={form.versionCode}
                            loading={this.state.loading}
                            onChange={this.onVersionCodeChange}
                            mandatory={true}
                            disabled={!isCreateNewFormOperation && status !== STATUS.IN_PREPARATION}
                        />
                        <TextInput
                            label={`Mapping code`}
                            value={form.mappingCode}
                            loading={this.state.loading}
                            onChange={this.onMappingCodeChange}
                            disabled={
                                !isCreateNewFormOperation &&
                                status !== STATUS.IN_PREPARATION &&
                                status !== STATUS.ACTIVE
                            }
                        />
                        <TextInput
                            label={`Expert`}
                            value={form.expert}
                            onChange={this.onExpertChange}
                            loading={this.state.loading}
                            disabled={
                                !isCreateNewFormOperation &&
                                status !== STATUS.IN_PREPARATION &&
                                status !== STATUS.ACTIVE
                            }
                        />

                        <DateInput
                            label={'Date of adoption of CPVO-TP'}
                            inputValueFrom={form.versionDate}
                            simple={true}
                            changeDateFrom={this.onVersionDateChange}
                            disabled={!isCreateNewFormOperation && status !== STATUS.IN_PREPARATION}
                            loading={this.state.loading}
                        />
                        <DateInput
                            label={'Valid from'}
                            inputValueFrom={form.validityBegin}
                            simple={true}
                            changeDateFrom={this.onValidityBeginChange}
                            disabled={!isCreateNewFormOperation && status !== STATUS.IN_PREPARATION}
                            loading={this.state.loading}
                        />
                        <DateInput
                            label={'End of validity'}
                            inputValueFrom={form.validityEnd || ''}
                            simple={true}
                            changeDateFrom={this.onValidityEndChange}
                            disabled={status === STATUS.TERMINATED} //{!isCreateNewFormOperation && status !== STATUS.IN_PREPARATION}
                            loading={this.state.loading}
                        />
                        <DateInput
                            label={'Entry into force of CPVO-TP'}
                            inputValueFrom={form.proposalDate}
                            simple={true}
                            changeDateFrom={this.onProposalDateChange}
                            disabled={!isCreateNewFormOperation && status !== STATUS.IN_PREPARATION}
                            loading={this.state.loading}
                        />
                        {parseInt(this.state.form.type) === FORM_TYPE.TECHNICAL_QUESTIONNAIRE ? (
                            <TextLabelInput
                                delay={300}
                                label={'Crop sector'}
                                listByIdName={this.state.listByIdNameCropSectors}
                                nameHash={this.state.nameHashCropSectors}
                                noInfo={true}
                                onSelectionChange={this.onTextLabelInputCropSectorsSelectionChange}
                                onChange={this.onTextLabelInputCropSectorValueChange}
                                placeholder={`Agricultural etc..`}
                                selectedElements={this.state.selectedCropSectors}
                                value={this.state.textLabelInputCropSectorValue}
                                clickable={true}
                                multiple={true}
                            />
                        ) : null}
                        <TextAreaInput
                            label={`Remark modification`}
                            triple={true}
                            value={form.remarkModif}
                            onChange={this.onRemarkModifChange}
                            disabled={
                                !isCreateNewFormOperation &&
                                status !== STATUS.IN_PREPARATION &&
                                status !== STATUS.ACTIVE
                            }
                            rows={7}
                        />
                        <Checkbox
                            label={`Propose "other" option on PDF printed version`}
                            value={form.proposeOtherOptionOnPDF}
                            double={true}
                            clickAction={this.onProposeOtherOptionOnPDFChange}
                            disabled={
                                !isCreateNewFormOperation &&
                                status !== STATUS.IN_PREPARATION &&
                                status !== STATUS.ACTIVE
                            }
                        />
                        <Checkbox
                            label={`Form is ready for XML export to UPOV PRISMA`}
                            value={form.paramXmlReady}
                            double={true}
                            clickAction={this.onParamXmlReadyChange}
                            disabled={
                                !isCreateNewFormOperation &&
                                status !== STATUS.IN_PREPARATION &&
                                status !== STATUS.ACTIVE
                            }
                        />
                        {/*{this.administrator && (*/}
                        <Checkbox
                            label={`Allow import from another office`}
                            value={form.root}
                            double={true}
                            clickAction={this.onRootChange}
                            disabled={!isCreateNewFormOperation && status !== STATUS.IN_PREPARATION}
                        />
                        {/*)}*/}
                        {this.state.errorMandatoryFields ? (
                            <section className={styles.formErrors}>
                                {this.state.errorMandatoryField ? (
                                    <>
                                        {this.state.errorMandatoryField === ERROR_MANDATORY_FIELD.FORM_NAME ? (
                                            <Error>Please fill Form name</Error>
                                        ) : null}
                                        {this.state.errorMandatoryField === ERROR_MANDATORY_FIELD.COMMON_NAME ? (
                                            <Error>Please fill Common name</Error>
                                        ) : null}
                                        {this.state.errorMandatoryField === ERROR_MANDATORY_FIELD.VERSION_CODE ? (
                                            <Error>Please fill Version code</Error>
                                        ) : null}
                                    </>
                                ) : (
                                    <Error>Please fill mandatory fields</Error>
                                )}
                            </section>
                        ) : null}
                        <section className={styles.formActions}>
                            <FormFooterButton
                                color={buttonColor.green}
                                clickAction={this.saveChanges}
                                icon={faCheck}
                                // disabled={!formHasChanged}
                            >
                                {isCreateNewFormOperation ? 'Create form' : `Save`}
                            </FormFooterButton>
                            {!isCreateNewFormOperation && (
                                <FormFooterButton
                                    color={buttonColor.blue}
                                    clickAction={this.resetToDefault}
                                    icon={faRedo}
                                    // disabled={!formHasChanged}
                                >{`Reset to default`}</FormFooterButton>
                            )}
                        </section>
                        <div style={{clear: 'both'}} />
                        {!isCreateNewFormOperation && (
                            <DataSheetFormSection title={'Species linked'} disableToggle={true}>
                                {!formHasSpecies && (
                                    <p>
                                        <FontAwesomeIcon icon={faInfo as any} color={'green'} />
                                        <span style={{marginLeft: 5}}>There is no species linked to this form</span>
                                    </p>
                                )}
                                <TextLabelInput
                                    double={true}
                                    onSelectionChange={this.onSpeciesSelectionChange}
                                    onChange={this.onSpeciesNameChange}
                                    value={this.state.speciesName}
                                    selectedElements={this.state.selectedSpecies}
                                    delay={300}
                                    outsideLabel={'Species'}
                                    placeholder={`Please type part of the species name`}
                                    outsideLabelWidth={160}
                                    listByIdName={this.state.speciesListByIdName}
                                    nameHash={this.state.speciesNameHash}
                                    buttonIcon={Object.keys(this.state.selectedSpecies).length !== 0 && faPlus}
                                    buttonAction={this.addSpecie}
                                    uniqueValue={true}
                                />
                                {formHasSpecies && (
                                    <CustomTable
                                        version={1}
                                        hideExcelButton={true}
                                        tableName={'species'}
                                        tableType={'OBJECT'}
                                        tableSource={form.species}
                                        timestamp={this.state.timestamp}
                                        id={'specieId'}
                                        resultFieldsDefault={['order', 'specieId', 'specieName']}
                                        intl={this.props.intl}
                                        defaultOrder={'order'}
                                        reverseOrder={false}
                                        formatFunctions={{
                                            specieName: (specieName: string) => {
                                                /* eslint-disable-next-line react/no-danger */
                                                return <div dangerouslySetInnerHTML={{__html: specieName}} />;
                                            },
                                            order: (order: any) => ++order,
                                        }}
                                        actions={actions}
                                        actionName={`Action`}
                                        notSortable={['#', 'order', 'specieId', 'specieName']}
                                        unlimited={true}
                                    />
                                )}
                            </DataSheetFormSection>
                        )}
                        <div style={{clear: 'both'}} />
                    </FormWithBackground>
                    <div style={{height: 40}} />
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(BackOfficeFormGeneralSettings);
