import React from 'react';
import {VFPDFVarietyDataSheet} from '../VFPDFVarietyDataSheet/VFPDFVarietyDataSheet';
import VFContainerWrapper from '~components/VFContainerWrapper';
import VFModalClass from '../VFModalClass/VFModalClass';
import VFModalRegister from '../VFModalRegister/VFModalRegister';
import VFModalSpecies from '../VFModalSpecies/VFModalSpecies';
import CPVOLogo from '../../img/cpvo_logo.svg';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {HeaderLoading, LoadingBar} from '../../componentsLayout';
import {ModalCustomVersion2} from '../../commonModals';
import {
    convertDate,
    getProtectedDesignation,
    GLOBAL_DATE_FORMAT,
    joinNotEmptyValues,
    partiesConditionalHeader,
    prefixCheck,
    regStatusConditionalHeader,
} from '../../utils/utilsVarietyFinder';
import {downloadBlob} from '../VFPDFTestDataSheet/VFPDFTestDataSheet.utils';
import {faArrowCircleDown, faChevronLeft, faChevronRight, faShareSquare} from '@fortawesome/free-solid-svg-icons';
import graphQLClientInstance from '../../utils/axiosGraphQLClient';
import {getVarietyDataSheetQuery} from './VFModalVarietyDataSheetService';
import {injectIntl} from 'react-intl';
import {pdf} from '@react-pdf/renderer';
import styles from './VFModalVarietyDataSheet.module.scss';

const VARIETY_DATASHEET_DATE_FORMAT = 'DDMMYYYY';

const PROTECTED_DESIGNATION_MAP_TITLE: any = {
    variety: 'Variety Data Sheet',
    eutm: 'EUTM Data Sheet',
    pgi: 'PGI Data Sheet',
    pdo: 'PDO Data Sheet',
    tsg: 'TSG Data Sheet',
};

interface IProps {
    close?: any;
    currentIndex?: number;
    intl: any;
    onButtonPreviousClick?: any;
    onButtonNextClick?: any;
    renderOnlyModalBody?: boolean;
    totalCount?: number;
    varietyId: number;
}

interface IState {
    JSONResponse: any;
    additionalInfoExtraDataURL: string | null;
    additionalInfoRemark: string | null;
    isModalClassOpen: string | false;
    isModalRegisterOpen: any;
    isModalSpeciesOpen: any;
    loading: boolean;
    namesDenominationsArray: any[];
    namesSynonymsArray: any[];
    namesTradeNamesArray: any[];
    namesTrademarksArray: any[];
    protectedDesignation: any;
    partiesBreedersName: string;
    partiesApplicant: string;
    partiesMaintainer: string;
    partiesHolder: string;
    partiesOtherParties: string;
    registrationApplicationNumber: string;
    registrationBreedersReference: string;
    registrationApplicationPublicationDate: string;
    registrationApplicationDate: string;
    registrationStatus: string;
    registrationExpirationFutureRegistrationDate: string;
    registrationExpirationExpirationActual: string;
    registrationGrantRegistrationDate: string;
    registrationGrantRegistrationNumber: string;
    registrationGrantRegistrationPublicationDate: string;
    registrationGrantRegistrationRenewalDate: string;
    registerCountry: string;
    registerName: string;
    registerOrganization: string;
    registerPublicationId: any;
    registerType: string;
    registerWebsite: string;
    showPopoverCopiedToClipboard: boolean;
    speciesCode: string;
    speciesClasses: string[];
    speciesPrincipalBotanicalName: string;
}

class VFModalVarietyDataSheet extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            additionalInfoExtraDataURL: '',
            additionalInfoRemark: '',
            JSONResponse: {},
            isModalClassOpen: false,
            isModalRegisterOpen: false,
            isModalSpeciesOpen: false,
            loading: false,
            namesDenominationsArray: [],
            namesSynonymsArray: [],
            namesTradeNamesArray: [],
            namesTrademarksArray: [],
            partiesBreedersName: '',
            partiesApplicant: '',
            partiesMaintainer: '',
            partiesHolder: '',
            partiesOtherParties: '',
            protectedDesignation: null,
            registrationApplicationNumber: '',
            registrationBreedersReference: '',
            registrationApplicationPublicationDate: '',
            registrationApplicationDate: '',
            registrationStatus: '',
            registrationGrantRegistrationDate: '',
            registrationExpirationFutureRegistrationDate: '',
            registrationExpirationExpirationActual: '',
            registrationGrantRegistrationNumber: '',
            registrationGrantRegistrationPublicationDate: '',
            registrationGrantRegistrationRenewalDate: '',
            registerCountry: '',
            registerName: '',
            registerOrganization: '',
            registerPublicationId: '',
            registerType: '',
            registerWebsite: '',
            showPopoverCopiedToClipboard: false,
            speciesCode: '',
            speciesClasses: [],
            speciesPrincipalBotanicalName: '',
        };
    }

    componentDidMount() {
        this.loadJSONs();
    }

    formatDate = (date: any) => convertDate(date, GLOBAL_DATE_FORMAT, VARIETY_DATASHEET_DATE_FORMAT);

    loadJSONs = () => {
        this.setState({loading: true}, () => {
            graphQLClientInstance
                .post(`/graphql`, getVarietyDataSheetQuery(this.props.varietyId))
                .then(JSONResponse => {
                    if (
                        JSONResponse &&
                        JSONResponse.data &&
                        JSONResponse.data.data &&
                        JSONResponse.data.data.varietyDatasheet
                    ) {
                        const {
                            app_date: registrationApplicationDate,
                            app_pub_date: registrationApplicationPublicationDate,
                            applicationnumber: registrationApplicationNumber,
                            breeder: partiesBreedersName,
                            applicant: partiesApplicant,
                            breeder_ref: registrationBreedersReference,
                            country,
                            countryid,
                            denominationsList,
                            future_exp_date: registrationExpirationFutureRegistrationDate,
                            end_date: registrationExpirationExpirationActual,
                            extra_data_url: additionalInfoExtraDataURL,
                            grant_date: registrationGrantRegistrationDate,
                            grant_number: registrationGrantRegistrationNumber,
                            grant_pub_date: registrationGrantRegistrationPublicationDate,
                            maintainer: partiesMaintainer,
                            name: registerOrganization,
                            other_parties: partiesOtherParties,
                            reg_status: registrationStatus,
                            renewal_date: registrationGrantRegistrationRenewalDate,
                            species_code: speciesCode,
                            species_classes: speciesClasses,
                            species_princ_bot_name: speciesPrincipalBotanicalName,
                            remark: additionalInfoRemark,
                            titleholder: partiesHolder,
                            pub_name: registerName,
                            pub_type_name: registerPublication,
                            publicationextraid,
                            publicationtypeid: registerPublicationId,
                            websiteurl: registerWebsite,
                        } = JSONResponse.data.data.varietyDatasheet || {};
                        const denominationsListParsed = JSON.parse(denominationsList || '{}').denos;
                        const namesDenominationsArray = denominationsListParsed?.filter(
                            (item: any) => item.nature === 'Denomination'
                        );
                        const namesSynonymsArray = denominationsListParsed?.filter(
                            (item: any) => item.nature === 'Synonym'
                        );
                        const namesTradeNamesArray = denominationsListParsed?.filter(
                            (item: any) => item.nature === 'Trade name'
                        );
                        const namesTrademarksArray = denominationsListParsed?.filter(
                            (item: any) => item.nature === 'Trade mark'
                        );

                        this.setState({
                            JSONResponse: {...JSONResponse.data.data.varietyDatasheet},
                            additionalInfoExtraDataURL,
                            additionalInfoRemark,
                            loading: false,
                            namesDenominationsArray,
                            namesSynonymsArray,
                            namesTradeNamesArray,
                            namesTrademarksArray,
                            protectedDesignation: getProtectedDesignation(
                                countryid,
                                registerPublicationId,
                                publicationextraid
                            ),
                            partiesBreedersName,
                            partiesApplicant,
                            partiesMaintainer,
                            partiesHolder,
                            partiesOtherParties,
                            registrationApplicationNumber,
                            registrationBreedersReference,
                            registrationApplicationDate: this.formatDate(registrationApplicationDate),
                            registrationExpirationExpirationActual: this.formatDate(
                                registrationExpirationExpirationActual
                            ),
                            registrationExpirationFutureRegistrationDate: this.formatDate(
                                registrationExpirationFutureRegistrationDate
                            ),
                            registrationGrantRegistrationNumber,
                            registrationGrantRegistrationDate: this.formatDate(registrationGrantRegistrationDate),
                            registrationGrantRegistrationRenewalDate: this.formatDate(
                                registrationGrantRegistrationRenewalDate
                            ),
                            registrationGrantRegistrationPublicationDate: this.formatDate(
                                registrationGrantRegistrationPublicationDate
                            ),
                            registrationApplicationPublicationDate: this.formatDate(
                                registrationApplicationPublicationDate
                            ),
                            registrationStatus,
                            registerCountry: `${countryid} - ${country}`,
                            registerName,
                            registerOrganization,
                            registerPublicationId,
                            registerType: `${registerPublication} (${registerPublicationId})`,
                            registerWebsite,
                            speciesClasses,
                            speciesCode,
                            speciesPrincipalBotanicalName,
                        });
                    }
                });
        });
    };

    closeModalClass = () => this.setState({isModalClassOpen: false});

    getModalTitle = () => {
        return this.state.protectedDesignation
            ? PROTECTED_DESIGNATION_MAP_TITLE[this.state.protectedDesignation]
            : this.state.registerPublicationId === 'EUTM'
            ? 'EUTM Data Sheet'
            : 'Variety Data Sheet';
    };

    closeModalRegister = () => this.setState({isModalRegisterOpen: false});

    closeModalSpecies = () => this.setState({isModalSpeciesOpen: false});

    onDataSheetFormFieldSpeciesClassNameClick = (speciesClass: string) =>
        this.setState({isModalClassOpen: speciesClass});

    onButtonDownloadPDFClick = () =>
        pdf(
            <VFPDFVarietyDataSheet
                data={[
                    {
                        ...this.state.JSONResponse,
                        protectedDesignation: this.state.protectedDesignation,
                    },
                ]}
            />
        )
            .toBlob()
            .then((blob: any) => {
                downloadBlob(blob, 'Variety.pdf');
            });

    onButtonShareVarietyDataSheetClick = () => {
        const {countryid, publicationextraid, publicationtypeid, varietyid} = this.state.JSONResponse || {};
        const URL = `${window.location.origin}/varieties/${countryid}-${publicationextraid}-${publicationtypeid}-${varietyid}`;
        navigator.clipboard.writeText(URL).then(
            () => {
                this.setState({showPopoverCopiedToClipboard: true});
                setTimeout(() => {
                    this.setState({showPopoverCopiedToClipboard: false});
                }, 3000);
            },
            () => {
                alert('Something went wrong. Please try again later.');
            }
        );
    };

    onDataSheetFormFieldRegisterNameClick = () => {
        const {countryid, publicationextraid, publicationtypeid} = this.state.JSONResponse || {};
        this.setState({
            isModalRegisterOpen: {
                countryid,
                publicationtypeid,
                publicationextraid,
            },
        });
    };

    onDataSheetFormFieldSpeciesPrincipalBotanicalNameClick = () => {
        const {speciesCode} = this.state;
        this.setState({isModalSpeciesOpen: speciesCode});
    };

    renderModalBody = () => {
        if (this.props.renderOnlyModalBody && this.state.loading) {
            return <HeaderLoading />;
        }
        const showSectionSpecies = !this.state.protectedDesignation && this.state.registerPublicationId !== 'EUTM';
        const {
            countryid,
            pub_type_name: pubTypeName,
            country,
            publicationtypeid,
            end_date: endDate,
            renewal_date: renewalDate,
            grant_date: grantDate,
            app_date: appDate,
        } = this.state.JSONResponse || {};
        const {
            speciesPrincipalBotanicalName,
            registrationStatus,
            partiesBreedersName,
            partiesApplicant,
            partiesMaintainer,
            partiesHolder,
            partiesOtherParties,
        } = this.state;

        return (
            <VFContainerWrapper
                style={
                    this.props.renderOnlyModalBody
                        ? {
                              maxWidth: 899,
                              margin: '20px auto',
                          }
                        : null
                }
                title={
                    <div>
                        <a
                            href="https://cpvo.europa.eu/en"
                            style={{
                                width: 30,
                                marginRight: 10,
                            }}
                            target={`_blank`}
                        >
                            <img
                                src={CPVOLogo}
                                alt="CPVO logo"
                                style={{
                                    width: 30,
                                    borderRadius: '50%',
                                    verticalAlign: 'middle',
                                }}
                            />
                        </a>
                        <span
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'middle',
                            }}
                        >
                            {this.getModalTitle()}
                        </span>
                    </div>
                }
                contentRight={
                    this.props.renderOnlyModalBody ? (
                        <div
                            className={styles.linkGoToVarietyFinder}
                            onClick={() => window.open('/varieties')}
                        >{`Go to Variety Finder`}</div>
                    ) : null
                }
            >
                <DataSheetFormSection
                    title={'Register'}
                    layoutStyle={`green`}
                    subtitleClosed={joinNotEmptyValues(
                        ', ',
                        joinNotEmptyValues(' - ', countryid, country),
                        `${pubTypeName} (${publicationtypeid})`
                    )}
                >
                    <DataSheetFormFields
                        label={'Country'}
                        data={this.state.registerCountry}
                        loading={this.state.loading}
                    />
                    <DataSheetFormFields label={'Type'} data={this.state.registerType} loading={this.state.loading} />
                    <DataSheetFormFields
                        label={'Name'}
                        data={
                            <div
                                style={
                                    this.props.renderOnlyModalBody
                                        ? undefined
                                        : {
                                              color: '#2f923f',
                                              cursor: 'pointer',
                                          }
                                }
                                onClick={
                                    this.props.renderOnlyModalBody
                                        ? undefined
                                        : this.onDataSheetFormFieldRegisterNameClick
                                }
                            >
                                {this.state.registerName}
                            </div>
                        }
                        double={true}
                        loading={this.state.loading}
                    />
                    <DataSheetFormFields
                        label={'Organization'}
                        data={this.state.registerOrganization}
                        double={true}
                        loading={this.state.loading}
                    />
                    <DataSheetFormFields
                        label={'Web site'}
                        data={
                            <a
                                href={prefixCheck(this.state.registerWebsite)}
                                target={`_blank`}
                                rel="noopener noreferrer"
                            >
                                {this.state.registerWebsite}
                            </a>
                        }
                        double={true}
                        loading={this.state.loading}
                    />
                    <div style={{clear: 'both'}} />
                </DataSheetFormSection>
                {showSectionSpecies ? (
                    <DataSheetFormSection
                        title={'Species'}
                        layoutStyle={`green`}
                        subtitleClosed={speciesPrincipalBotanicalName}
                    >
                        <DataSheetFormFields
                            label={'Principal botanical name'}
                            data={
                                <div
                                    style={
                                        this.props.renderOnlyModalBody
                                            ? undefined
                                            : {color: '#2f923f', cursor: 'pointer'}
                                    }
                                    onClick={
                                        this.props.renderOnlyModalBody
                                            ? undefined
                                            : this.onDataSheetFormFieldSpeciesPrincipalBotanicalNameClick
                                    }
                                >
                                    {this.state.speciesPrincipalBotanicalName}
                                </div>
                            }
                            double={true}
                            loading={this.state.loading}
                        />
                        <DataSheetFormFields
                            label={'Code'}
                            data={this.state.speciesCode}
                            loading={this.state.loading}
                        />
                        <DataSheetFormFields
                            label={'Class'}
                            data={
                                <>
                                    {this.state.speciesClasses.map(speciesClass => (
                                        <div
                                            key={speciesClass}
                                            style={
                                                this.props.renderOnlyModalBody
                                                    ? undefined
                                                    : {color: '#2f923f', cursor: 'pointer'}
                                            }
                                            onClick={() =>
                                                this.props.renderOnlyModalBody
                                                    ? undefined
                                                    : this.onDataSheetFormFieldSpeciesClassNameClick(speciesClass)
                                            }
                                        >
                                            {speciesClass}
                                        </div>
                                    ))}
                                </>
                            }
                            loading={this.state.loading}
                        />
                        <div style={{clear: 'both'}} />
                    </DataSheetFormSection>
                ) : null}
                <DataSheetFormSection
                    title={'Registration status'}
                    layoutStyle={`green`}
                    subtitleClosed={joinNotEmptyValues(
                        ', ',
                        registrationStatus,
                        regStatusConditionalHeader(
                            registrationStatus,
                            convertDate(endDate, GLOBAL_DATE_FORMAT, VARIETY_DATASHEET_DATE_FORMAT),
                            convertDate(renewalDate, GLOBAL_DATE_FORMAT, VARIETY_DATASHEET_DATE_FORMAT),
                            convertDate(grantDate, GLOBAL_DATE_FORMAT, VARIETY_DATASHEET_DATE_FORMAT),
                            convertDate(appDate, GLOBAL_DATE_FORMAT, VARIETY_DATASHEET_DATE_FORMAT)
                        )
                    )}
                >
                    <DataSheetFormFields
                        label={
                            this.state.protectedDesignation || this.state.registerPublicationId === 'EUTM'
                                ? 'Status'
                                : 'Variety Status'
                        }
                        data={this.state.registrationStatus}
                        double={true}
                        loading={this.state.loading}
                    />
                    <div style={{clear: 'both'}} />
                    <div style={{marginTop: 10, marginBottom: 5}}>
                        <strong>{`Application`}</strong>
                    </div>
                    <DataSheetFormFields
                        label={'Number'}
                        data={this.state.registrationApplicationNumber}
                        loading={this.state.loading}
                    />
                    {!this.state.protectedDesignation && this.state.registerPublicationId !== 'EUTM' ? (
                        <DataSheetFormFields
                            label={"Breeder's Reference"}
                            data={this.state.registrationBreedersReference}
                            loading={this.state.loading}
                        />
                    ) : null}
                    <DataSheetFormFields
                        label={'Date'}
                        data={this.state.registrationApplicationDate}
                        loading={this.state.loading}
                    />
                    <DataSheetFormFields
                        label={'Publication Date'}
                        data={this.state.registrationApplicationPublicationDate}
                        loading={this.state.loading}
                    />
                    <div style={{clear: 'both'}} />
                    <div style={{marginTop: 7, marginBottom: 5}}>
                        <strong>{`Grant/registration`}</strong>
                    </div>
                    <DataSheetFormFields
                        label={'Number'}
                        data={this.state.registrationGrantRegistrationNumber}
                        loading={this.state.loading}
                    />
                    <DataSheetFormFields
                        label={'Date'}
                        data={this.state.registrationGrantRegistrationDate}
                        loading={this.state.loading}
                    />
                    {!this.state.protectedDesignation ? (
                        <>
                            <DataSheetFormFields
                                label={'Publication Date'}
                                data={this.state.registrationGrantRegistrationPublicationDate}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'Renewal Date'}
                                data={this.state.registrationGrantRegistrationRenewalDate}
                                loading={this.state.loading}
                            />
                            <div style={{clear: 'both'}} />
                            <div style={{marginTop: 7, marginBottom: 5}}>
                                <strong>{`Expiration`}</strong>
                            </div>
                            <DataSheetFormFields
                                label={'Expiration actual'}
                                data={this.state.registrationExpirationExpirationActual}
                                loading={this.state.loading}
                            />
                            <DataSheetFormFields
                                label={'Future registration date'}
                                data={this.state.registrationExpirationFutureRegistrationDate}
                                loading={this.state.loading}
                            />
                            <div style={{clear: 'both'}} />
                        </>
                    ) : null}
                    <div style={{clear: 'both'}} />
                </DataSheetFormSection>
                <DataSheetFormSection
                    title={'Parties'}
                    layoutStyle={`green`}
                    subtitleClosed={
                        this.state.protectedDesignation
                            ? partiesHolder
                            : partiesConditionalHeader(
                                  partiesBreedersName,
                                  partiesApplicant,
                                  partiesMaintainer,
                                  partiesHolder,
                                  partiesOtherParties
                              )
                    }
                >
                    {!this.state.protectedDesignation && this.state.registerPublicationId !== 'EUTM' ? (
                        <DataSheetFormFields
                            label={"Breeder's Name"}
                            data={this.state.partiesBreedersName}
                            double={true}
                            loading={this.state.loading}
                        />
                    ) : null}
                    {!this.state.protectedDesignation ? (
                        <DataSheetFormFields
                            label={'Applicant'}
                            data={this.state.partiesApplicant}
                            double={true}
                            loading={this.state.loading}
                        />
                    ) : null}
                    {!this.state.protectedDesignation && this.state.registerPublicationId !== 'EUTM' ? (
                        <DataSheetFormFields
                            label={'Maintainer'}
                            data={this.state.partiesMaintainer}
                            double={true}
                            loading={this.state.loading}
                        />
                    ) : null}
                    {this.state.registerPublicationId !== 'EUTM' ? (
                        <DataSheetFormFields
                            label={'Holder'}
                            data={this.state.partiesHolder}
                            double={true}
                            loading={this.state.loading}
                        />
                    ) : null}
                    {!this.state.protectedDesignation && this.state.registerPublicationId !== 'EUTM' ? (
                        <DataSheetFormFields
                            label={'Other parties'}
                            data={this.state.partiesOtherParties}
                            double={true}
                            loading={this.state.loading}
                        />
                    ) : null}
                    <div style={{clear: 'both'}} />
                </DataSheetFormSection>
                {this.state.additionalInfoExtraDataURL || this.state.additionalInfoRemark ? (
                    <DataSheetFormSection title={'Additional information'} layoutStyle={`green`}>
                        <DataSheetFormFields
                            label={'Remarks'}
                            data={this.state.additionalInfoRemark}
                            double={true}
                            loading={this.state.loading}
                        />
                        <DataSheetFormFields
                            label={'Link to database of origin'}
                            data={
                                <a href={this.state.additionalInfoExtraDataURL || ''} target={`_blank`}>
                                    {this.state.additionalInfoExtraDataURL}
                                </a>
                            }
                            double={true}
                            loading={this.state.loading}
                        />
                        <div style={{clear: 'both'}} />
                    </DataSheetFormSection>
                ) : null}
                {this.state.namesDenominationsArray.length ||
                this.state.namesSynonymsArray.length ||
                this.state.namesTradeNamesArray.length ||
                this.state.namesTrademarksArray.length ? (
                    <DataSheetFormSection
                        title={'Names'}
                        layoutStyle={`green`}
                        subtitleClosed={
                            this.state.namesDenominationsArray.length > 1
                                ? 'Several denominations'
                                : this.state.namesDenominationsArray.length === 1
                                ? this.state.namesDenominationsArray[0].name
                                : 'No denominations'
                        }
                    >
                        {this.state.namesDenominationsArray.length ? (
                            <>
                                <div style={{marginTop: 7, marginBottom: 5, clear: 'both'}}>
                                    <strong>{`Denomination(s)`}</strong>
                                </div>
                                {this.state.namesDenominationsArray.map(
                                    ({
                                        name,
                                        type,
                                        status,
                                        prop_dt: proposalDate,
                                        appr_dt: approvalDate,
                                        pub_dt: publicationDate,
                                        end_dt: endDate,
                                    }: any) => (
                                        <div key={name} style={{marginBottom: 8}}>
                                            <DataSheetFormFields
                                                label={this.state.protectedDesignation ? 'Name' : 'Denomination'}
                                                data={name}
                                                loading={this.state.loading}
                                            />
                                            {!this.state.protectedDesignation ? (
                                                <DataSheetFormFields
                                                    label={'Type'}
                                                    data={type}
                                                    loading={this.state.loading}
                                                />
                                            ) : null}
                                            <DataSheetFormFields
                                                label={'Status'}
                                                data={status}
                                                loading={this.state.loading}
                                            />
                                            {!this.state.protectedDesignation ? (
                                                <div>
                                                    <DataSheetFormFields
                                                        label={'Proposal Date'}
                                                        data={convertDate(proposalDate, GLOBAL_DATE_FORMAT, 'YYYYMMDD')}
                                                        loading={this.state.loading}
                                                    />
                                                    <DataSheetFormFields
                                                        label={'Approval Date'}
                                                        data={convertDate(approvalDate, GLOBAL_DATE_FORMAT, 'YYYYMMDD')}
                                                        loading={this.state.loading}
                                                    />
                                                    <DataSheetFormFields
                                                        label={'Publication Date'}
                                                        data={convertDate(
                                                            publicationDate,
                                                            GLOBAL_DATE_FORMAT,
                                                            'YYYYMMDD'
                                                        )}
                                                        loading={this.state.loading}
                                                    />
                                                    <DataSheetFormFields
                                                        label={'End Date'}
                                                        data={convertDate(endDate, GLOBAL_DATE_FORMAT, 'YYYYMMDD')}
                                                        loading={this.state.loading}
                                                    />
                                                </div>
                                            ) : null}
                                            <div style={{clear: 'both'}} />
                                        </div>
                                    )
                                )}
                            </>
                        ) : null}
                        {this.state.namesSynonymsArray.length ? (
                            <>
                                <div style={{marginTop: 7, marginBottom: 5, clear: 'both'}}>
                                    <strong>{`Synonym(s)`}</strong>
                                </div>
                                {this.state.namesSynonymsArray.map(({name}: any) => (
                                    <div key={name} style={{marginBottom: 8}}>
                                        {name}
                                    </div>
                                ))}
                            </>
                        ) : null}
                        {this.state.namesTradeNamesArray.length ? (
                            <>
                                <div style={{marginTop: 7, marginBottom: 5, clear: 'both'}}>
                                    <strong>{`Trade name(s)`}</strong>
                                </div>
                                {this.state.namesTradeNamesArray.map(({name}: any) => (
                                    <div key={name} style={{marginBottom: 8}}>
                                        {name}
                                    </div>
                                ))}
                            </>
                        ) : null}
                        {this.state.namesTrademarksArray.length ? (
                            <>
                                <div style={{marginTop: 7, marginBottom: 5, clear: 'both'}}>
                                    <strong>{`Trademark(s)`}</strong>
                                </div>
                                {this.state.namesTrademarksArray.map(({name}: any) => (
                                    <React.Fragment key={name}>
                                        <DataSheetFormFields
                                            label={'Name'}
                                            data={name}
                                            double={true}
                                            loading={this.state.loading}
                                        />
                                    </React.Fragment>
                                ))}
                                <div style={{clear: 'both'}} />
                            </>
                        ) : null}
                        <div style={{clear: 'both'}} />
                    </DataSheetFormSection>
                ) : null}
                <div style={{clear: 'both'}} />
            </VFContainerWrapper>
        );
    };

    render() {
        return (
            <>
                {this.props.renderOnlyModalBody ? (
                    this.renderModalBody()
                ) : (
                    <ModalCustomVersion2
                        close={this.props.close}
                        headerInnerTitlesStyle={{width: '96%'}}
                        header={
                            <div style={{margin: '0 6px', height: 40, fontSize: 16, width: '96%'}}>
                                <div style={{float: 'left'}}>
                                    <FormFooterButton
                                        icon={faArrowCircleDown}
                                        color={buttonColor.whiteGray}
                                        clickAction={this.onButtonDownloadPDFClick}
                                        style={{marginLeft: 0}}
                                    >{`Download PDF`}</FormFooterButton>
                                </div>
                                <div style={{float: 'left', marginLeft: 10, position: 'relative'}}>
                                    <FormFooterButton
                                        icon={faShareSquare}
                                        color={buttonColor.whiteGray}
                                        clickAction={this.onButtonShareVarietyDataSheetClick}
                                        style={{marginLeft: 0}}
                                    >{`Share Variety data sheet`}</FormFooterButton>
                                    {this.state.showPopoverCopiedToClipboard ? (
                                        <div
                                            className={styles.popoverCopiedToClipboard}
                                        >{`URL has been copied to clipboard!`}</div>
                                    ) : null}
                                </div>
                                {this.props.currentIndex !== undefined &&
                                this.props.onButtonPreviousClick &&
                                this.props.totalCount &&
                                this.props.onButtonNextClick ? (
                                    <div style={{float: 'right', marginTop: 10}}>
                                        {this.props.currentIndex + 1} of {this.props.totalCount}{' '}
                                        <div style={{display: 'inline-block', marginLeft: 10}}>
                                            <div
                                                onClick={
                                                    this.props.currentIndex === 0
                                                        ? undefined
                                                        : this.props.onButtonPreviousClick
                                                }
                                                style={{
                                                    cursor: this.props.currentIndex === 0 ? 'not-allowed' : 'pointer',
                                                    display: 'inline-block',
                                                    marginRight: 10,
                                                    opacity: this.props.currentIndex === 0 ? 0.4 : 1,
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faChevronLeft as any}
                                                    color={'black'}
                                                    fontSize={20}
                                                />
                                            </div>
                                            <div
                                                onClick={
                                                    this.props.currentIndex === this.props.totalCount - 1
                                                        ? undefined
                                                        : this.props.onButtonNextClick
                                                }
                                                style={{
                                                    cursor:
                                                        this.props.currentIndex === this.props.totalCount - 1
                                                            ? 'not-allowed'
                                                            : 'pointer',
                                                    display: 'inline-block',
                                                    opacity:
                                                        this.props.currentIndex === this.props.totalCount - 1 ? 0.4 : 1,
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faChevronRight as any}
                                                    color={'black'}
                                                    fontSize={20}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                <div style={{clear: 'both'}} />
                            </div>
                        }
                        body={
                            <div>
                                {this.state.loading ? <LoadingBar /> : null}
                                {!this.state.loading ? this.renderModalBody() : null}
                            </div>
                        }
                        footer={<></>}
                        plus2={true}
                    />
                )}
                {this.state.isModalClassOpen ? (
                    <VFModalClass close={this.closeModalClass} classId={this.state.isModalClassOpen} />
                ) : null}
                {this.state.isModalRegisterOpen ? (
                    <VFModalRegister
                        close={this.closeModalRegister}
                        countryId={this.state.isModalRegisterOpen.countryid}
                        publicationTypeId={this.state.isModalRegisterOpen.publicationtypeid}
                        publicationExtraId={this.state.isModalRegisterOpen.publicationextraid}
                    />
                ) : null}
                {this.state.isModalSpeciesOpen ? (
                    <VFModalSpecies close={this.closeModalSpecies} speciesCode={this.state.isModalSpeciesOpen} />
                ) : null}
            </>
        );
    }
}

export default injectIntl(VFModalVarietyDataSheet);
