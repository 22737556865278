import React from 'react';
import CustomTable from '~components/CustomTable';
import {ModalCustomVersion2} from '../../commonModals';
import {Button} from '../../componentsFormV2';

export default class ModalMergeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: '',
            invalid: true,
        };
    }

    close = reload => this.props.close && this.props.close(reload);

    buttonEnabled = () => this.props.loadedDocuments && this.props.loadedDocuments.length > 0;

    render() {
        return (
            <ModalCustomVersion2
                close={this.close}
                header={
                    <>
                        <span>{`${this.props.title} `}</span>
                        <small>{`${this.props.subtitle} `}</small>
                    </>
                }
                body={
                    <div
                        style={{
                            height: 400,
                            mintHeight: 400,
                            overflowY: 'auto',
                        }}
                    >
                        {this.props.loadedDocuments && this.props.loadedDocuments.length > 0 ? (
                            <CustomTable
                                version={2}
                                tableName={'documentsPrint'}
                                actionName={`Action`}
                                tableType={'OBJECT'}
                                tableSource={this.props.loadedDocuments}
                                dataFilter={null}
                                id={'name'}
                                setLastCursor={null}
                                resultFieldsDefault={['name']}
                                intl={this.props.intl}
                                filterFunctions={null}
                                count={this.props.loadedDocuments.length}
                                limit={300}
                                hideExcelButton={true}
                                noChangePageSize={true}
                            />
                        ) : null}
                    </div>
                }
                footer={
                    <>
                        <Button variation={'danger'} clickAction={this.close}>{`Close`}</Button>
                        <Button
                            clickAction={this.props.openMergeConfirm}
                            disabled={!this.buttonEnabled}
                            icon={'arrowRight'}
                        >{`Merge`}</Button>
                    </>
                }
            />
        );
    }
}
