import gqlPrettier from 'graphql-prettier';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import {faFile, faFileCsv, faFileExcel, faFileImage, faFilePdf, faFileWord} from '@fortawesome/free-solid-svg-icons';

export const GLOBAL_DATE_FORMAT = 'DD/MM/YYYY';
export const GLOBAL_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const getToken = () => {
    const remember = localStorage.getItem('remember') === '1';
    const token = (remember ? localStorage : sessionStorage).getItem('tokenVarietyFinder');
    return token;
};

export const checkTokenError = (token: string) => {
    return token === 'NO_COOKIE_FOUND_ERROR' || token === 'NO_COOKIE_REDIRECT';
};

const getValueFromFormControlSelect = (value: any, elasticField: string) => {
    return value ? `${elasticField}:"${value.toLowerCase()}"` : '';
};

export const registersFields = {
    COUNTRY: 'countryid',
    REGISTER: 'registerType',
};

const createRegistersParams = (values: any) => {
    const country = getValueFromFormControlSelect(values.countryid, registersFields.COUNTRY);
    const registerType = getValueFromFormControlSelect(values.registerType, registersFields.REGISTER);
    const extraid = values?.extraid !== undefined && values?.extraid !== null ? `extraid:"${values?.extraid}"` : '';

    return `
    ${country}
    ${registerType}
    ${extraid}
  `;
};

export const getRegistersQuery = (
    values: any,
    pageSize: number,
    pageIndex: number,
    sortBy?: {id: string; desc: boolean}[]
) => {
    const searchParams = createRegistersParams(values);
    const sorting =
        sortBy && sortBy.length
            ? `sortingField:"${sortBy[0].id}" sortingDirection:"${sortBy[0].desc ? 'desc' : 'asc'}"`
            : '';

    const searchQuery = `
  {
    registersSearch(
      size:${pageSize}
      from:${pageSize * pageIndex}
      ${searchParams}
      ${sorting}
    ){
      total
      data {
        countryid
        publicationtypeid
        publicationname
        name
        addressformatted
        websiteurl
        remarks
        email
        all_contributions {
            pub_date
            pub_number
            pub_info
        }
      }
    }
  }`;

    return {
        query: gqlPrettier(searchQuery),
    };
};

export const autoCompletePostData = (
    autoCompleteName: string,
    term: string,
    type: string,
    includeSynonyms = false,
    autoCompleteReturnString = ''
) => {
    const synonyms = includeSynonyms ? `includeSynonyms:"1"` : '';

    return {
        query: `
    {
      ${autoCompleteName}(
        term: "${term.toLowerCase()}"
        type: "${type}"
        ${synonyms}
      )${autoCompleteReturnString}
    }`,
    };
};

export const convertDate = (value: any, outputFormat: string = GLOBAL_DATE_FORMAT, inputFormat = '') => {
    if (!value) {
        return '';
    }
    const date = inputFormat ? moment(value, inputFormat, true) : moment(value);
    return date.isValid() ? date.format(outputFormat) : '';
};

export const TRADE_NAME = 'Trade name';
export const TRADE_MARK = 'Trade mark';
export const SYNONYM = 'Synonym';
export const DENOMINATION = 'Denomination';

export const getProtectedDesignation = (country: string, pubTypeId: string, publicationExtraId: number) => {
    if (country === 'QZ' && pubTypeId === 'ZZZ') {
        switch (publicationExtraId) {
            case 3:
                return 'pdo';
            case 4:
                return 'pgi';
            case 5:
                return 'tsg';
            default:
                return '';
        }
    } else {
        return '';
    }
};

export const computeRegisterLastContribution = (allContributions: any) => {
    if (!allContributions || !Array.isArray(allContributions) || !allContributions.length) {
        return '';
    }
    // const latestContribution = allContributions.find(contribution => !!contribution.pub_date);
    // if (!latestContribution) {
    //     return '';
    // }
    return convertDate(allContributions[0].pub_date);
};

export const formatSearchDate = (date: string) => {
    return (date || '').split('/').reverse().join('');
};

export const checkIfFirstDateIsAfterSecondDate = (fromDate: string, toDate: string) => {
    return moment(fromDate).isAfter(toDate);
};

export const isAdvanced = () => {
    const token: string | null = getToken();
    if (token) {
        const decoded: any = jwtDecode(token);
        return decoded.roles.includes('WDEN3');
    }
    return false;
};

export const isContributor = () => {
    const token: string | null = getToken();
    if (token) {
        const decoded: any = jwtDecode(token);
        return decoded.roles.includes('CNTR1');
    }
    return false;
};

export const isFullSearchExp = () => {
    const token: string | null = getToken();
    if (token) {
        const decoded: any = jwtDecode(token);
        return decoded.roles.includes('FSEXP');
    }
    return false;
};

export const getUserId = () => {
    const token: string | null = getToken();
    let id = '';
    if (token && !checkTokenError(token)) {
        const decoded: any = jwtDecode(token);
        id = decoded.id;
    }
    return id;
};

export const downloadUrl = (url: string, fileName = '') => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    if (fileName) {
        link.download = fileName;
    }
    link.click();
    document.body.removeChild(link);
};

export const calcFileSize = (size: any) => {
    if (size === 0) {
        return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return `${parseFloat((size / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

export const getFileFormat = (file: any) => {
    return file ? file.match(/\.([^.]+)$/)[1] : '';
};

export const getFileIcon = (fileFormat: any) => {
    switch (fileFormat) {
        case 'jpeg':
        case 'jpg':
        case 'png':
            return faFileImage;
        case 'docx':
        case 'doc':
            return faFileWord;
        case 'xlsx':
        case 'xls':
            return faFileExcel;
        case 'pdf':
            return faFilePdf;
        case 'csv':
            return faFileCsv;
        default:
            return faFile;
    }
};

export const checkToday = (timestamp: any) => {
    if (timestamp) {
        const parsedDate = moment(timestamp);
        if (parsedDate.isValid()) {
            return parsedDate.isSame(moment(), 'day');
        }
    }
    return false;
};

export const dataHeaderConv = (dataHeader: string) =>
    ({
        denomination: 'Denomination',
        countryid: 'Country',
        countryname: 'Country name',
        denominationnature: 'Denomination nature',
        specielatinname: 'Species latin name',
        applicationstatus: 'Variety status',
        publicationtype: 'Register type',
        breederreference: 'Breeder‘s reference',
        breedername: 'Breeder',
        otherparty: 'Other parties',
        applicationnumber: 'Application number',
        applicationdate: 'Application date',
        pubapplicationdate: 'Application published on',
        grantnumber: 'Grant/registration number',
        grantdate: 'Grant/registration date',
        pubgrantdate: 'Grant/registration published on',
        renewalregistration: 'Renewal on',
        varietyinsertdate: 'Variety insert date',
        denominationinsertdate: 'Denomination insert date',
        specieclasscodes: 'Species class',
        specieclasses: 'Class name',
        cropsectors: 'Crop sector',
        classid: 'Species Code',
        princ_bot: 'Principal botanical name',
        other_bot: 'Other botanical name(s)',
        en: 'English',
        fr: 'French',
        de: 'German',
        es: 'Spanish',
        crop_sector: 'Crop sector',
        upovclass: 'Class',
        classname: 'Class name',
        info: 'Remark',
        species_id: 'Species code',
        description: 'Description',
        maintainername: 'Maintainer',
        applicantname: 'Applicant',
        holdername: 'Holder',
        pubproposaldate: 'Denomination published on',
        approvaldate: 'Denomination approved on',
        speciesid: 'Species code',
        futureexpirationdate: 'Future expiration date',
        publicationextraid: 'Publication extra ID',
        register_name: 'Register name',
        denominationstatus: 'Denomination status',
        denominationtype: 'Denomination type',
        species_name_en: 'Species English name',
        extra_data_url: 'Extra data url',
        remark: 'Remarks',
        deno_end_date: 'Denomination end date',
        enddate: 'Expiration actual',
        proposaldate: 'Denomination proposed on',
        apl_nb: 'Application number',
        var_status_date: 'Date',
        score: 'Score',
        var_status: 'Variety status',
        species_latin_name: 'Species latin name',
        breeder_name: "Breeder's name",
        brd_name: "Breeder's name",
        breeder_ref: 'Breeder‘s reference',
        brd_ref: 'Breeder‘s reference',
        country: 'Country',
        register_type: 'Register type',
        speciename: 'Species latin name',
        deno_status: 'Denomination status',
        testid: 'Test ID',
        species_name: 'Species Latin Name',
        test_date: 'Test Date',
        batch_name: 'Batch Name',
        advice: 'Advice',
        test_status: 'Status',
        register: 'Register',
        applicant: 'Applicant',
        title_holder: 'Holder',
        maintainer: 'Maintainer',
        class_data: 'Class',
        crop_sectors: 'Crop sector',
        name: 'Organization',
        publicationname: 'Register Name',
        publicationtypeid: 'Register Type',
        all_contributions: 'Last Contribution',
        outcomestatus: 'Outcome',
        article: 'Article',
        title: 'Title',
        jurisprudenceid: 'Juris',
        decisiondate: 'Decision Date',
        specieid: 'Species',
    }[dataHeader]);

export const compareResults = (sortBy: {id: string; desc: boolean}) => {
    return function (a: any, b: any) {
        const varA = a[sortBy['id']] || '';
        const varB = b[sortBy['id']] || '';

        if (varA.toString().toLowerCase() > varB.toString().toLowerCase()) {
            return sortBy['desc'] ? -1 : 1;
        } else if (varA.toString().toLowerCase() < varB.toString().toLowerCase()) {
            return sortBy['desc'] ? 1 : -1;
        }
        return 0;
    };
};

export const joinNotEmptyValues = (delimiter: string, ...values: any) => {
    return [...values].filter(Boolean).join(delimiter);
};

export const varietyStatus = {
    APPLICATION: 'Application',
    REGISTERED: 'Registered',
    WITHDRAWN: 'Withdrawn',
    REJECTED: 'Rejected',
    SURRENDERED: 'Surrendered',
    TERMINATED: 'Terminated',
    EXPIRED: 'Expired',
};

export const regStatusConditionalHeader = (
    regStatus: string,
    endDate: string,
    renewalDate: string,
    grantDate: string,
    appDate: string
) => {
    let output = '';
    switch (regStatus) {
        case varietyStatus.EXPIRED:
        case varietyStatus.SURRENDERED:
        case varietyStatus.TERMINATED:
        case varietyStatus.WITHDRAWN:
        case varietyStatus.REJECTED:
            output = endDate;
            break;
        case varietyStatus.REGISTERED:
            output = renewalDate ? renewalDate : grantDate;
            break;
        case varietyStatus.APPLICATION:
            output = appDate;
            break;
        default:
            break;
    }
    return output;
};

export const partiesConditionalHeader = (
    breeder: string,
    applicant: string,
    maintainer: string,
    holder: string,
    otherParties: string
) => {
    if (breeder) {
        return `${breeder} (Breeder)`;
    } else if (applicant) {
        return `${applicant} (Applicant)`;
    } else if (maintainer) {
        return `${maintainer} (Maintainer)`;
    } else if (holder) {
        return `${holder} (Holder)`;
    } else if (otherParties) {
        return `${otherParties} (Other party)`;
    } else {
        return '';
    }
};

export const prefixCheck = (s: any) => {
    if (s && !s.match(/^[a-zA-Z]+:\/\//)) {
        s = 'https://' + s;
    }
    return s;
};
