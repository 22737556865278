import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export function apiLabelTranslations(labelIds) {
    return getFetch(
        `${API_SERVERLESS}/backOffice/v2/labels?order=languageId&labelIds=${labelIds}&withHTML=true&pageSize=100`,
        true
    );
}

export function apiLabelLabelTargets(labelIds) {
    return getFetch(
        `${API_SERVERLESS}/backOffice/v2/labels?languageId=EN&labelIds=${labelIds}&withHTML=true&pageSize=100`,
        true
    );
}

export function apiMergeLabels(jsonRequest) {
    return postFetch(`${API_SERVERLESS}/backOffice/v2/mergeLabels`, true, jsonRequest);
}

export function apiReplaceLabel(jsonRequest) {
    return postFetch(`${API_SERVERLESS}/backOffice/v2/replaceLabel`, true, jsonRequest);
}
