import React from 'react';

interface IIconLoginProps {
    color?: any;
    style?: any;
}

export default function ({color, style: userStyle}: IIconLoginProps) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={userStyle ? userStyle : {}}
        >
            <path
                d="M16.6667 9.99998C16.6667 8.23187 15.9643 6.53618 14.714 5.28593C13.4638 4.03569 11.7681 3.33331 10 3.33331M10 16.6666C10.9995 16.6675 11.9864 16.4433 12.8873 16.0105C13.7883 15.5777 14.5802 14.9475 15.2042 14.1666"
                stroke={color || 'white'}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M3.33331 10H11.6666H3.33331ZM11.6666 10L9.16665 7.5L11.6666 10ZM11.6666 10L9.16665 12.5L11.6666 10Z"
                fill="white"
            />
            <path
                d="M3.33331 10H11.6666M11.6666 10L9.16665 7.5M11.6666 10L9.16665 12.5"
                stroke={color || 'white'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
