import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt, faTrashAlt} from '@fortawesome/free-solid-svg-icons';

function renderButton(popupContent: any, handler: any, icon: any, mainScreen: any) {
    return {
        title: popupContent,
        icon: (rowObject: any) => {
            return mainScreen === 'templateQuestions' ||
                (mainScreen === 'backOfficeFormQuestions' && rowObject.fromTemplate === false) ? (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon} />
                </a>
            ) : (
                <div />
            );
        },
        handler,
    };
}

export default function QuestionModalDetailsLogicsActions(openModalLogic: any, confirmDeletion: any, mainScreen: any) {
    const buttons = [];
    buttons.push(
        renderButton(
            'Edit',
            (labelId: any, rowObject: any) => {
                openModalLogic(rowObject.id);
            },
            faPencilAlt,
            mainScreen
        )
    );
    buttons.push(
        renderButton(
            'Delete',
            (labelId: any, rowObject: any) => {
                confirmDeletion(rowObject.id);
            },
            faTrashAlt,
            mainScreen
        )
    );
    return buttons;
}
