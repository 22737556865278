import React from 'react';
import ResponseWrapper, {IWizardResponseWrapperProps, IWizardResponseWrapperState} from './ResponseWrapper';

interface IState extends IWizardResponseWrapperState {}

export default class ResponseHidden extends ResponseWrapper<IWizardResponseWrapperProps, IState> {
    constructor(props: IWizardResponseWrapperProps) {
        super(props);
    }

    render() {
        return <>{}</>;
    }
}
