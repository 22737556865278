import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLightbulb} from '@fortawesome/free-solid-svg-icons';
import {injectIntl} from 'react-intl';
import {ModalCustomVersion2} from '../../../commonModals';
import ModalUsedInVersion2, {rowType} from '../../../commonModals/ModalUsedInVersion2';
import StatusField from '~shared/StatusField';
import QuestionModalDetailsTabs, {QUESTION_MODAL_DETAILS_TABS} from './QuestionModalDetailsTabs';
import QuestionModalDetailsGeneralInformation from './QuestionModalDetailsGeneralInformation';
import QuestionModalDetailsLogics, {LOGIC_TYPES} from './QuestionModalDetailsLogics';
import {IGroup, IGroupQuestion} from '../../../types';

interface IProps {
    activeResponseId?: any;
    afterCreatingNewQuestion?: any;
    close: any;
    formId?: number | null;
    formOrTemplateOfficeId?: number;
    group?: IGroup;
    intl: any;
    loadFormQuestionsJSONs?: any;
    mainScreen?: string;
    mock?: any;
    modalQueuePop: any;
    modalQueuePush: any;
    questionId: number;
    question?: IGroupQuestion;
    steps: IGroup[];
    templateId?: any;
}

interface IState {
    currentTab: string;
    questionStatus: number | null;
    modalUsedIn: number | null;
    footer: any;
}

class QuestionModalDetails extends React.Component<IProps, IState> {
    closeHandler: any;

    constructor(props: IProps) {
        super(props);
        this.state = {
            currentTab:
                Object.values(LOGIC_TYPES).indexOf(this.props.mock) !== -1
                    ? QUESTION_MODAL_DETAILS_TABS.LOGICS
                    : QUESTION_MODAL_DETAILS_TABS.GENERAL_INFORMATION,
            questionStatus: null,
            modalUsedIn: null,
            footer: null,
        };
    }

    setCloseHandler = (handler: any) => (this.closeHandler = handler);

    close = (force: boolean) => {
        if (force) {
            this.props.close();
        } else if (this.closeHandler && this.closeHandler()) {
            this.props.close();
        }
    };

    setQuestionModalDetailsStatus = (status: number | null) => this.setState({questionStatus: status});

    setRenderFooter = (footer: any) => this.setState({footer});

    showModalUsedIn = () => this.setState({modalUsedIn: this.props.questionId});

    closeModalUsedIn = () => this.setState({modalUsedIn: null});

    onTabChange = (currentTab: string) => (_: any) => this.setState({currentTab});

    render() {
        const isNewQuestionOperation = this.props.questionId === 0;

        return (
            <>
                <ModalCustomVersion2
                    close={this.close}
                    header={
                        <>
                            {isNewQuestionOperation ? 'New ' : ''}Question
                            {!isNewQuestionOperation && (
                                <div
                                    style={{
                                        fontSize: 22,
                                        marginLeft: 10,
                                        border: '1px solid green',
                                        borderRadius: '50%',
                                        width: 26,
                                        height: 26,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: 2,
                                        cursor: 'pointer',
                                    }}
                                    onClick={this.showModalUsedIn}
                                >
                                    <FontAwesomeIcon icon={faLightbulb as any} color="green" />
                                </div>
                            )}
                            {this.state.questionStatus && (
                                <div style={{marginLeft: 20}}>
                                    <StatusField intl={this.props.intl} statusId={this.state.questionStatus} />
                                </div>
                            )}
                        </>
                    }
                    body={
                        <>
                            <div style={{marginBottom: 20}} />
                            <QuestionModalDetailsTabs
                                onChange={this.onTabChange}
                                currentTab={this.state.currentTab}
                                questionId={this.props.questionId}
                            />
                            <div>
                                {this.state.currentTab === QUESTION_MODAL_DETAILS_TABS.GENERAL_INFORMATION && (
                                    <QuestionModalDetailsGeneralInformation
                                        afterCreatingNewQuestion={this.props.afterCreatingNewQuestion}
                                        close={this.close}
                                        formId={this.props.formId}
                                        formOrTemplateOfficeId={this.props.formOrTemplateOfficeId}
                                        group={this.props.group}
                                        loadFormQuestionsJSONs={this.props.loadFormQuestionsJSONs}
                                        mainScreen={this.props.mainScreen}
                                        modalQueuePush={this.props.modalQueuePush}
                                        modalQueuePop={this.props.modalQueuePop}
                                        questionId={this.props.questionId}
                                        question={this.props.question}
                                        setCloseHandler={this.setCloseHandler}
                                        setQuestionModalDetailsStatus={this.setQuestionModalDetailsStatus}
                                        steps={this.props.steps}
                                        setRenderFooter={this.setRenderFooter}
                                    />
                                )}
                                {this.state.currentTab === QUESTION_MODAL_DETAILS_TABS.LOGICS && (
                                    <QuestionModalDetailsLogics
                                        formId={this.props.formId}
                                        loadFormQuestionsJSONs={this.props.loadFormQuestionsJSONs}
                                        mainScreen={this.props.mainScreen}
                                        mock={this.props.mock}
                                        modalQueuePop={this.props.modalQueuePop}
                                        modalQueuePush={this.props.modalQueuePush}
                                        questionId={this.props.questionId}
                                        question={this.props.question}
                                        responseId={this.props.activeResponseId}
                                        steps={this.props.steps}
                                        templateId={this.props.templateId}
                                        setRenderFooter={this.setRenderFooter}
                                    />
                                )}
                            </div>
                        </>
                    }
                    footer={<>{this.state.footer}</>}
                />
                {this.state.modalUsedIn ? (
                    <ModalUsedInVersion2
                        elementId={this.state.modalUsedIn}
                        intl={this.props.intl}
                        type={rowType.QUESTION}
                        close={this.closeModalUsedIn}
                    />
                ) : null}
            </>
        );
    }
}

export default injectIntl(QuestionModalDetails);
