import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';

function renderButton(popupContent: any, handler: any, icon: any) {
    return {
        title: popupContent,
        icon: () => (
            <a data-content={popupContent}>
                <FontAwesomeIcon icon={icon} />
            </a>
        ),
        handler,
    };
}

export default function MyPVRLegalProceedingsActionButtons(goToRegisters: any) {
    const buttons = [];
    buttons.push(renderButton('Application details', goToRegisters, faChevronRight));
    return buttons;
}
