import React from 'react';
import Checkbox from '~components/Checkbox';
import {Editor} from '@tinymce/tinymce-react';
import {Error, HeaderLoading} from '../../../componentsLayout';
import {Button} from '../../../componentsFormV2';
import {ModalCustomVersion2} from '../../../commonModals';
import SelectInput from '~components/SelectInput';
import {
    apiBackOfficeFormOrTemplateResponseCreateOrUpdate,
    apiBackOfficeLabelsSearch,
    apiBackOfficeUpdateLabels,
    IResponseCreatePayload,
    IResponseLabelsSearch,
} from './ResponseModalAddResponsesWithTableService';
import {injectIntl} from 'react-intl';
import {htmlToText} from '../../../utils';
import {IGroupQuestion} from '../../../types';

const ID_NONE = 'NONE';

interface IProps {
    afterCreatingNewResponse: () => any;
    close: () => any;
    intl: any;
    questionId: number;
    question: IGroupQuestion;
}

interface IState {
    currentValue: string;
    checkboxAddNumbersValue: boolean;
    errorMessage: string;
    loading: number;
    selectInputNameValue: string;
    selectInputRemarkValue: string;
    selectInputNameList: {id: string; value: string}[];
    selectInputRemarkList: {id: string; value: string}[];
    tables: {[key: string]: any};
}

class ResponseModalAddResponsesWithTable extends React.Component<IProps, IState> {
    tinyConfig = {
        height: 500,
        inline: false,
        plugins:
            'table hr anchor code paste searchreplace image preview fullscreen textcolor link table responsivefilemanager',
        toolbar1:
            'bold italic underline strikethrough | bullist numlist | alignleft aligncenter alignright alignjustify | outdent indent | table',
        statusbar: false,
        menubar: false,
        image_advtab: true,
        image_dimensions: false,
        subfolder: 'images/html',
        language: 'en',
        table_default_attributes: {
            border: '0',
        },
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            currentValue: '',
            checkboxAddNumbersValue: false,
            errorMessage: '',
            loading: 0,
            selectInputNameValue: ID_NONE,
            selectInputRemarkValue: ID_NONE,
            selectInputNameList: [{id: ID_NONE, value: 'Not selected'}],
            selectInputRemarkList: [{id: ID_NONE, value: 'Not selected'}],
            tables: {},
        };
    }

    getJSONPayload = ({
        nameMessageId,
        remarkMessageId,
        order,
    }: {
        nameMessageId: number;
        remarkMessageId: number | null;
        order: number;
    }): IResponseCreatePayload => {
        return {
            nameMessage: {
                id: nameMessageId,
            },
            remarkMessage: {
                id: remarkMessageId,
            },
            helpMessage: {
                id: null,
            },
            idElement: null,
            office: 1,
            score: null,
            order: `00${order}`.slice(-2),
            mappingCode: null,
            jsonMapping: null,
            remarkAllowed: null,
            remarkMandatory: null,
            status: 1,
            questionId: this.props.questionId,
            responseId: 0,
        };
    };

    fetchLabelIdByLabelValue = async (searchTerm: string) => {
        let labelResult = await apiBackOfficeLabelsSearch({searchTerm});
        let labelExists = this.checkIfLabelExists(labelResult, searchTerm);
        let labelId;
        if (!labelExists) {
            let labelCreated = await apiBackOfficeUpdateLabels({
                labelValue: searchTerm,
                languageId: 'EN',
            });
            labelId = labelCreated.data;
        } else {
            labelId = this.findLabelIdByLabelResult(labelResult, searchTerm);
        }
        return labelId;
    };

    findLabelIdByLabelResult = (labelResult: IResponseLabelsSearch, tableLabel: string) => {
        let labelId;
        labelResult.data.labels.forEach((el: any) => {
            if (el.value === tableLabel) {
                labelId = el.labelId;
            }
        });
        return labelId;
    };

    checkIfLabelExists = (labelResult: IResponseLabelsSearch, tableLabel: string) =>
        labelResult &&
        labelResult.data &&
        labelResult.data.COUNT &&
        labelResult.data.labels.some(el => el.value === tableLabel);

    onEditorChange = (contentHTML: string) => {
        let iDiv = document.createElement('div');
        iDiv.innerHTML = contentHTML;
        let tableDOM = iDiv.querySelector('table');
        const stateTables: any = {};
        let selectInputNameList = [{id: ID_NONE, value: 'Not selected'}];
        let selectInputRemarkList = [{id: ID_NONE, value: 'Not selected'}];
        let data: any = [];
        if (tableDOM && tableDOM.rows) {
            for (let i = 0; i < tableDOM.rows.length; i++) {
                const row: any = {};
                for (let j = 0; j < tableDOM.rows[i].cells.length; j++) {
                    row[j.toString()] = tableDOM.rows[i].cells[j].textContent;
                }
                data.push(row);
            }
            data.forEach((el: any, i: number) => {
                let parsedObject = {...data[i]};
                Object.keys(parsedObject).forEach(key => {
                    parsedObject[key] = htmlToText(parsedObject[key].replaceAll('\n', ''));
                });
                data[i] = parsedObject;
            });
            if (data.length > 0 && Object.keys(data[0]).length >= 2) {
                let tableKey = `${data[0]['0']}`;
                Object.values(data[0]).forEach(columnName => {
                    let nameOption: any = {id: columnName, value: columnName};
                    let remarkOption: any = {id: columnName, value: columnName};
                    if (!selectInputNameList.some(el => el.id === columnName)) {
                        selectInputNameList.push(nameOption);
                    }
                    if (!selectInputRemarkList.some(el => el.id === columnName)) {
                        selectInputRemarkList.push(remarkOption);
                    }
                });
                stateTables[tableKey] = data;
            }
        }
        this.setState(prev => ({
            ...prev,
            currentValue: contentHTML,
            tables: stateTables,
            selectInputNameList,
            selectInputRemarkList,
        }));
    };

    onCheckboxAddNumbersClick = () =>
        this.setState(prev => ({...prev, checkboxAddNumbersValue: !prev.checkboxAddNumbersValue}));

    onSelectInputNameChange = ({target: {value: selectInputNameValue}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.setState({selectInputNameValue});

    onSelectInputRemarkChange = ({target: {value: selectInputRemarkValue}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.setState({selectInputRemarkValue});

    onButtonContinueClick = async () => {
        if (this.state.selectInputNameValue === ID_NONE || !this.state.selectInputNameValue) {
            return this.setState({errorMessage: 'Please select name'});
        }
        this.setState(
            prev => ({errorMessage: '', loading: prev.loading + 1}),
            async () => {
                let table = Object.values(this.state.tables)[0];
                let tableHeader = table[0];
                let tableRows = table.slice(1);
                let keyOfTableForName = Object.values(tableHeader).findIndex(
                    el => el === this.state.selectInputNameValue
                );
                let keyOfTableForRemark = Object.values(tableHeader).findIndex(
                    el => el === this.state.selectInputRemarkValue
                );
                let tableLabels: any = [];
                tableRows.forEach((row: any, index: number) =>
                    tableLabels.push(
                        this.state.checkboxAddNumbersValue
                            ? `${index + 1} - ${row[keyOfTableForName]}`
                            : row[keyOfTableForName]
                    )
                );
                let tableRemarks: any = [];
                if (keyOfTableForRemark >= 0) {
                    tableRows.forEach((row: any) => tableRemarks.push(row[keyOfTableForRemark]));
                }
                try {
                    for (let i = 0; i < tableLabels.length; i++) {
                        let nameMessageId = await this.fetchLabelIdByLabelValue(tableLabels[i]);
                        let remarkMessageId = tableRemarks.length
                            ? await this.fetchLabelIdByLabelValue(tableRemarks[i])
                            : null;
                        const JSONResponseToAdd = this.getJSONPayload({
                            nameMessageId: nameMessageId || 0,
                            remarkMessageId: remarkMessageId || null,
                            order: i + 1,
                        });
                        await apiBackOfficeFormOrTemplateResponseCreateOrUpdate(JSONResponseToAdd);
                    }
                    this.setState(
                        prev => ({loading: prev.loading - 1}),
                        () => this.props.afterCreatingNewResponse()
                    );
                } catch (error: any) {
                    this.setState({errorMessage: error.message, loading: 0});
                }
            }
        );
    };

    render() {
        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <ModalCustomVersion2
                    close={this.props.close}
                    header={`Add responses with table`}
                    body={
                        <div>
                            <div>
                                <SelectInput
                                    label={`Name`}
                                    value={this.state.selectInputNameValue}
                                    onChange={this.onSelectInputNameChange}
                                    list={this.state.selectInputNameList}
                                    notAll={true}
                                    mandatory={true}
                                    disabled={this.state.selectInputNameList.length === 1}
                                />
                                <SelectInput
                                    label={`Remark`}
                                    value={this.state.selectInputRemarkValue}
                                    onChange={this.onSelectInputRemarkChange}
                                    list={this.state.selectInputRemarkList}
                                    notAll={true}
                                    disabled={this.state.selectInputRemarkList.length < 2}
                                />
                                <div style={{float: 'left', marginTop: 13}}>
                                    <Checkbox
                                        label={`Add numbers`}
                                        value={this.state.checkboxAddNumbersValue}
                                        clickAction={this.onCheckboxAddNumbersClick}
                                        disabled={this.state.selectInputNameList.length < 2}
                                    />
                                </div>
                            </div>
                            <div style={{clear: 'both'}} />
                            <div style={{minHeight: this.tinyConfig.height}}>
                                <Editor
                                    id="textareaMainLanguage"
                                    value={this.state.currentValue}
                                    init={this.tinyConfig}
                                    onEditorChange={this.onEditorChange}
                                />
                            </div>
                            <div style={{clear: 'both'}} />
                        </div>
                    }
                    footer={
                        <>
                            {this.state.errorMessage ? <Error>{this.state.errorMessage}</Error> : null}
                            <Button
                                icon={'close'}
                                clickAction={this.props.close}
                                variation={'danger'}
                            >{`Close`}</Button>
                            <Button icon={'arrowRight'} clickAction={this.onButtonContinueClick}>{`Continue`}</Button>
                        </>
                    }
                />
            </>
        );
    }
}

export default injectIntl(ResponseModalAddResponsesWithTable);
