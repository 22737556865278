import React from 'react';
import styles from './ConsultModifySpeciesDataFilterCriteriaArea.module.scss';
import TextLabelInput from '../../../../../../components/TextLabelInput';
import {entrustmentMode} from '../ModalEntrustment/ModalEntrustment';
import {FormFooter} from '../../../../../../componentsLayout';
import {Button} from '../../../../../../componentsFormV2';
import {ConsultModifySpeciesDataFilterCriteriaData} from '../../../../../../types/TLO';

interface ConsultModifySpeciesDataFilterCriteriaAreaProps {
    entrustmentMode?: string;
    openModalEntrustmentModal?: () => void;
    criteria: ConsultModifySpeciesDataFilterCriteriaData;
    speciesListByIdName: Array<{ID: string; NAME: string}> | null;
    speciesNameHash: {[key: string]: Array<string>} | null;
    selectedSpecies: {[key: string]: string};
    updateSelectedSpecies: (selectedSpecies: {[key: string]: string}) => void;
    genusesListByIdName: Array<{ID: string; NAME: string}> | null;
    genusesNameHash: {[key: string]: Array<string>} | null;
    selectedGenuses: {[key: string]: string};
    updateSelectedGenuses: (selectedGenuses: {[key: string]: string}) => void;
    entrustmentTypesListByIdName: Array<{ID: string; NAME: string}> | null;
    entrustmentTypesNameHash: {[key: string]: Array<string>} | null;
    selectedEntrustmentTypes: {[key: string]: string};
    updateSelectedEntrustmentTypes: (selectedEntrustmentTypes: {[key: string]: string}) => void;
    updateCriteriaValue: (
        // eslint-disable-next-line no-undef
        criteriaValue: Partial<ConsultModifySpeciesDataFilterCriteriaData>,
        callback?: () => void,
        refresh?: boolean
    ) => void;
    resetCriteria: () => void;
    search: (shouldRefresh: boolean, urlLoad?: boolean) => void;
}

export default function ConsultModifySpeciesDataFilterCriteriaArea(
    props: ConsultModifySpeciesDataFilterCriteriaAreaProps
) {
    const onSpeciesSelectionChange = (selectedSpecies: {[key: string]: string}) =>
        props.updateCriteriaValue({speciesIds: Object.keys(selectedSpecies).join(','), speciesName: ''}, () =>
            props.updateSelectedSpecies(selectedSpecies)
        );

    const onSpeciesNameChange = (speciesName: string) => {
        props.updateCriteriaValue({speciesName});
    };

    const onSpeciesNameFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const speciesNameFilter = event.target.value;
        props.updateCriteriaValue({speciesNameFilter});
    };

    const onGenusesSelectionChange = (selectedGenuses: {[key: string]: string}) =>
        props.updateCriteriaValue({genusIds: Object.keys(selectedGenuses).join(','), genus: ''}, () =>
            props.updateSelectedGenuses(selectedGenuses)
        );

    const onGenusChange = (genus: string) => {
        props.updateCriteriaValue({genus});
    };

    const onGenusesFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const genusFilter = event.target.value;
        props.updateCriteriaValue({genusFilter});
    };

    const onEntrustmentTypesSelectionChange = (selectedEntrustmentTypes: {[key: string]: string}) =>
        props.updateCriteriaValue(
            {entrustmentTypeIds: Object.keys(selectedEntrustmentTypes).join(','), entrustmentType: ''},
            () => props.updateSelectedEntrustmentTypes(selectedEntrustmentTypes)
        );

    const onEntrustmentTypeChange = (entrustmentType: string) => {
        props.updateCriteriaValue({entrustmentType});
    };

    const onEntrustmentTypesNameFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const entrustmentTypeFilter = event.target.value;
        props.updateCriteriaValue({entrustmentTypeFilter});
    };

    const openModalEntrustmentModalCB = () => props.openModalEntrustmentModal && props.openModalEntrustmentModal();

    return (
        <>
            <div className={styles.mainFieldContainer}>
                <TextLabelInput
                    filter={['starts', 'ends', 'contains', 'equals']}
                    placeholder={`please use autocompletion`}
                    currentFilter={props.criteria.speciesNameFilter}
                    onFilterChange={onSpeciesNameFilterChange}
                    double={true}
                    onSelectionChange={onSpeciesSelectionChange}
                    onChange={onSpeciesNameChange}
                    onEnter={props.search}
                    value={props.criteria.speciesName}
                    selectedElements={props.selectedSpecies}
                    delay={300}
                    outsideLabel={'Species'}
                    outsideLabelWidth={160}
                    listByIdName={props.speciesListByIdName}
                    nameHash={props.speciesNameHash}
                    noInfo={true}
                />
                <TextLabelInput
                    filter={['starts', 'ends', 'contains', 'equals']}
                    placeholder={`please use autocompletion`}
                    currentFilter={props.criteria.genusFilter}
                    onFilterChange={onGenusesFilterChange}
                    double={true}
                    onSelectionChange={onGenusesSelectionChange}
                    onChange={onGenusChange}
                    onEnter={props.search}
                    value={props.criteria.genus}
                    selectedElements={props.selectedGenuses}
                    delay={300}
                    outsideLabel={'Genus'}
                    outsideLabelWidth={160}
                    listByIdName={props.genusesListByIdName}
                    nameHash={props.genusesNameHash}
                    noInfo={true}
                />
                <TextLabelInput
                    filter={['starts', 'ends', 'contains', 'equals']}
                    placeholder={`please use autocompletion`}
                    currentFilter={props.criteria.entrustmentTypeFilter}
                    onFilterChange={onEntrustmentTypesNameFilterChange}
                    double={true}
                    onSelectionChange={onEntrustmentTypesSelectionChange}
                    onChange={onEntrustmentTypeChange}
                    onEnter={props.search}
                    value={props.criteria.entrustmentType}
                    selectedElements={props.selectedEntrustmentTypes}
                    delay={300}
                    outsideLabel={'Entrustment Type'}
                    outsideLabelWidth={160}
                    listByIdName={props.entrustmentTypesListByIdName}
                    nameHash={props.entrustmentTypesNameHash}
                    noInfo={true}
                />
                <div style={{clear: 'both'}}>{}</div>
            </div>
            <FormFooter
                left={
                    props.entrustmentMode && props.entrustmentMode === entrustmentMode.NEW ? (
                        <Button clickAction={openModalEntrustmentModalCB} variation={'secondary'}>
                            {`New Entrustment`}
                        </Button>
                    ) : (
                        <div />
                    )
                }
                right={
                    <>
                        <Button clickAction={() => props.resetCriteria()} variation={'secondary'}>
                            {`Clear fields`}
                        </Button>
                        <Button clickAction={() => props.search(false)}>{`Search`}</Button>
                    </>
                }
            />
            <div style={{clear: 'both'}}>{}</div>
        </>
    );
}
