import React from 'react';
import Checkbox from '~components/Checkbox';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {IGroup} from '../../../types';
import {ModalConfirmVersion2, ModalLabelPreviewVersion2, ModalLabelSearchVersion2} from '../../../commonModals';
import {Error, LoadingBar} from '../../../componentsLayout';
import ModalUsedInVersion2, {rowType} from '../../../commonModals/ModalUsedInVersion2';
import ModalLabelVersion2, {getLabelTypeId} from '../../../commonModals/ModalLabelVersion2';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import {
    apiBackOfficeFormGroupCreateOrUpdate,
    IPayloadGroup,
} from './GroupOrTemplateModalDetailsGeneralInformationService';
import {
    faLightbulb,
    faList,
    faPencilAlt,
    faPlus,
    faSearch,
    faSpinner,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {getContentWithCorrectImagePath, isAdminUser} from '~utils';
import {injectIntl} from 'react-intl';
import {renderHTML} from '~common/format';
import styles from './GroupModalCreate.module.scss';
import {Button} from '../../../componentsFormV2';

const GROUP_STATUS = {
    IN_PREPARATION: 1,
    ACTIVE: 2,
    TERMINATED: 3,
};

const EMPTY_STATE_GROUP: IStateGroup = {
    nameMessage: {
        id: null,
        valueHTML: '',
        value: '',
    },
    remarkMessage: {
        id: null,
        valueHTML: '',
        value: '',
    },
    helpMessage: {
        id: null,
        valueHTML: '',
        value: '',
    },
    btnAddMessage: {
        id: null,
        valueHTML: '',
        value: '',
    },
    btnDelMessage: {
        id: null,
        valueHTML: '',
        value: '',
    },
    idElement: null,
    order: null,
    mappingCode: null,
    jsonMapping: null,
    mandatory: null,
    multi: null,
    status: null,
};

interface IStateGroup {
    nameMessage: {
        id: any;
        valueHTML: string;
        value: string;
    };
    remarkMessage: {
        id: any;
        valueHTML: string;
        value: string;
    };
    helpMessage: {
        id: any;
        valueHTML: string;
        value: string;
    };
    btnAddMessage: {
        id: any;
        valueHTML: string;
        value: string;
    };
    btnDelMessage: {
        id: any;
        valueHTML: string;
        value: string;
    };
    idElement: any;
    order: any;
    mappingCode: any;
    jsonMapping: any;
    mandatory: any;
    multi: any;
    status: any;
}

type TStateGroup = typeof EMPTY_STATE_GROUP;

type TKeyOfStateGroup = keyof TStateGroup;

interface IProps {
    close: any;
    formId?: number | null;
    group: IGroup | null;
    intl: any;
    loadFormQuestionsJSONs: any;
    mainScreen?: string;
    modalQueuePush: any;
    modalQueuePop: any;
    setCloseHandler: any;
    setGroupModalDetailsStatus: any;
    setRenderFooter?: any;
}

interface IState {
    loading: number;
    group: IStateGroup;
    groupListByIdName: {ID: string; NAME: string}[];
    groupNameHash: {[key: string]: number[]};
    groupSelectedElements: {[key: string]: any};
    modalConfirmClose: any;
    textLabelQuestionNameValue: string;
    textLabelGroupNameSelectedElements: {[key: string]: string[]};
    textLabelGroupNameListByIdName: {ID: number; NAME: string}[];
    textLabelGroupNameNameHash: {[key: string]: number[]};
    textLabelGroupNameTimeoutId: any;
    textLabelGroupNameSearchLoading: boolean;
    textTimestamp: number;
    forceHideSearchList: boolean;
}

class GroupOrTemplateModalDetailsGeneralInformation extends React.Component<IProps, IState> {
    initialGroup: IStateGroup = EMPTY_STATE_GROUP;
    administrator = false;
    isTemplate: any;

    constructor(props: IProps) {
        super(props);
        this.administrator = isAdminUser();
        this.isTemplate = props.group && props.group.isTemplate;
        const initialStateGroup = {
            nameMessage: {
                id: (props.group && props.group.messageId) || null,
                valueHTML: (props.group && props.group.message) || '',
                value:
                    (props.group && props.group.message && getContentWithCorrectImagePath(props.group.message)) || '',
            },
            remarkMessage: {
                id: (props.group && props.group.messageRemarkId) || null,
                valueHTML: (props.group && props.group.messageRemark) || '',
                value:
                    (props.group &&
                        props.group.messageRemark &&
                        getContentWithCorrectImagePath(props.group.messageRemark)) ||
                    '',
            },
            helpMessage: {
                id: (props.group && props.group.messageHelpId) || null,
                valueHTML:
                    (props.group &&
                        props.group.messageHelp &&
                        getContentWithCorrectImagePath(props.group.messageHelp)) ||
                    '',
                value:
                    (props.group &&
                        props.group.messageHelp &&
                        getContentWithCorrectImagePath(props.group.messageHelp)) ||
                    '',
            },
            btnAddMessage: {
                id: (props.group && props.group.messageIdButtonAddId) || null,
                valueHTML:
                    (props.group &&
                        props.group.messageButtonAdd &&
                        getContentWithCorrectImagePath(props.group.messageButtonAdd)) ||
                    '',
                value:
                    (props.group &&
                        props.group.messageButtonAdd &&
                        getContentWithCorrectImagePath(props.group.messageButtonAdd)) ||
                    '',
            },
            btnDelMessage: {
                id: (props.group && props.group.messageButtonDelId) || null,
                valueHTML:
                    (props.group &&
                        props.group.messageButtonDel &&
                        getContentWithCorrectImagePath(props.group.messageButtonDel)) ||
                    '',
                value:
                    (props.group &&
                        props.group.messageButtonDel &&
                        getContentWithCorrectImagePath(props.group.messageButtonDel)) ||
                    '',
            },
            idElement:
                (this.isTemplate ? props.group && props.group.templateId : props.group && props.group.id) || null,
            order: (props.group && props.group.order) || null,
            mappingCode: (props.group && props.group.mappingCode) || null,
            jsonMapping: (props.group && props.group.jsonMapping) || null,
            mandatory: (props.group && props.group.mandatory) || null,
            multi: (props.group && props.group.multi) || null,
            status: (props.group && props.group.status) || null,
        };
        this.initialGroup = {...initialStateGroup};
        this.state = {
            loading: 0,
            group: {...initialStateGroup},
            groupListByIdName: [],
            groupNameHash: {},
            groupSelectedElements: {},
            modalConfirmClose: null,
            textLabelQuestionNameValue: '',
            textLabelGroupNameSelectedElements: {
                [props?.group?.messageId as any]: [props?.group?.message || ''],
            },
            textLabelGroupNameListByIdName: [
                {
                    ID: props?.group?.messageId || 0,
                    NAME: props?.group?.message || '',
                },
            ],
            textLabelGroupNameNameHash: {
                [props?.group?.message as any]: [props?.group?.messageId || 0],
            },
            textLabelGroupNameTimeoutId: null,
            textLabelGroupNameSearchLoading: false,
            textTimestamp: Date.now(),
            forceHideSearchList: true,
        };
    }

    componentDidMount() {
        this.props.setCloseHandler(this.closeHandler);
        this.props.setRenderFooter && this.props.setRenderFooter(this.renderFooter());
    }

    closeHandler = () => {
        const formHasChanged =
            Object.keys(this.initialGroup || {}).filter(
                key =>
                    this.state.group &&
                    this.initialGroup[key as TKeyOfStateGroup] !== this.state.group[key as TKeyOfStateGroup]
            ).length > 0;
        if (formHasChanged) {
            this.openModalConfirmClose();
            return false;
        } else {
            return true;
        }
    };

    closeModalLabelSearch = () => this.props.modalQueuePop();

    showModalLabelSearch = (typeId: string, fieldIdKey: string, fieldHTMLKey: string) =>
        this.props.modalQueuePush(
            <ModalLabelSearchVersion2
                intl={this.props.intl}
                target={{typeId, fieldIdKey, fieldHTMLKey}}
                setLabelId={this.setLabelId}
                showModalPreview={this.showModalPreview}
                showModalUsedIn={this.showModalUsedIn}
                showModalLabel={this.showModalLabel}
                close={this.closeModalLabelSearch}
            />
        );

    setLabelId = (label: any, fieldIdKey: any) => {
        const {labelId, valueHTML, labelValueHTML, typeId} = label;
        return this.updateForm({
            [fieldIdKey]: {id: labelId, value: valueHTML || labelValueHTML, type: typeId},
        });
    };

    clearLabelUse = (fieldIdKey: string) => this.updateForm({[fieldIdKey]: {id: null, value: null, type: null}});

    showModalPreview = (valueHTML: string) =>
        this.props.modalQueuePush(
            <ModalLabelPreviewVersion2 intl={this.props.intl} close={this.closeModalPreview} valueHTML={valueHTML} />
        );

    closeModalPreview = () => this.props.modalQueuePop();

    showModalLabel = (labelId: number, typeId: string, fieldIdKey: string, fieldHTMLKey: string) =>
        this.props.modalQueuePush(
            <ModalLabelVersion2
                intl={this.props.intl}
                label={{labelId, typeId, fieldIdKey, fieldHTMLKey}}
                setLabelId={this.setLabelId}
                showModalUsedIn={this.showModalUsedIn}
                close={this.closeModalLabel}
            />
        );

    closeModalLabel = () => this.props.modalQueuePop();

    showModalUsedIn = (labelId: number) =>
        this.props.modalQueuePush(
            <ModalUsedInVersion2
                elementId={labelId}
                intl={this.props.intl}
                type={rowType.LABEL}
                close={this.closeModalUsedIn}
            />
        );

    closeModalUsedIn = () => this.props.modalQueuePop();

    openModalConfirmClose = () => this.setState({modalConfirmClose: true});

    closeModalConfirmClose = () => this.setState({modalConfirmClose: null});

    updateForm = (stateData: any) =>
        this.setState(prev => {
            return {
                ...prev,
                group: {
                    ...prev.group,
                    ...stateData,
                },
            };
        });

    removeLoader = () => this.setState(prev => ({loading: prev.loading - 1}));

    prepareGroupCreateOrUpdateBody = (stateGroup: IStateGroup): IPayloadGroup => {
        let JSONRequest: any = {...stateGroup};
        JSONRequest.groupId = JSONRequest.idElement;
        JSONRequest.formId = this.props.formId;
        JSONRequest.messageId = JSONRequest.nameMessage.id;
        JSONRequest.messageRemarkId = JSONRequest.remarkMessage.id;
        JSONRequest.messageHelpId = JSONRequest.helpMessage.id;
        JSONRequest.orderDisplay = JSONRequest.order;
        JSONRequest.messageButtonAddId = JSONRequest.btnAddMessage.id; // 5347
        JSONRequest.messageButtonDelId = JSONRequest.btnDelMessage.id; // 5348
        [
            'btnAddMessage',
            'btnDelMessage',
            'nameMessage',
            'remarkMessage',
            'helpMessage',
            'idElement',
            'order',
            'status',
        ].forEach(key => {
            delete JSONRequest[key];
        });
        return JSONRequest;
    };

    onOrderChange = ({target: {value: order}}: React.ChangeEvent<HTMLInputElement>) => this.onFormDataChange({order});

    onMappingCodeChange = ({target: {value: mappingCode}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({mappingCode});

    onJsonMappingChange = ({target: {value: jsonMapping}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({jsonMapping});

    onMultiChange = () => this.onFormDataChange({multi: !this.state.group.multi});

    onMandatoryChange = () => this.onFormDataChange({mandatory: !this.state.group.mandatory});

    onFormDataChange = (valueObject: any) =>
        this.setState(prev => ({group: Object.assign({}, prev.group, valueObject)}));

    onSave = () => {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                const JSONRequest = this.prepareGroupCreateOrUpdateBody(this.state.group);
                apiBackOfficeFormGroupCreateOrUpdate(JSONRequest).then(() => {
                    this.props.loadFormQuestionsJSONs &&
                        this.props.loadFormQuestionsJSONs(null, this.removeLoader, null, true);
                });
            }
        );
    };

    renderPopupElements(labelId: number | null, typeId: string, fieldIdKey: string, fieldHTMLKey: string) {
        const elementsArr = [];
        fieldIdKey !== 'nameMessage' &&
            elementsArr.push({
                label: 'Search label',
                icon: faSearch,
                handler: () => this.showModalLabelSearch(typeId, fieldIdKey, fieldHTMLKey),
            });
        !labelId &&
            fieldIdKey !== 'nameMessage' &&
            elementsArr.push({
                label: 'Add label',
                icon: faPlus,
                handler: () => this.showModalLabel(0, typeId, fieldIdKey, fieldHTMLKey),
            });
        labelId &&
            elementsArr.push({
                label: 'Show label',
                icon: faPencilAlt,
                handler: () => this.showModalLabel(labelId, typeId, fieldIdKey, fieldHTMLKey),
            });
        labelId &&
            fieldIdKey !== 'nameMessage' &&
            elementsArr.push({
                label: 'Clear label',
                icon: faTimes,
                handler: () => this.clearLabelUse(fieldIdKey),
            });
        labelId &&
            elementsArr.push({
                label: 'Used in',
                icon: faLightbulb,
                handler: () => this.showModalUsedIn(labelId),
            });
        return elementsArr;
    }

    renderFooter = () => {
        const hasEmptyMandatoryFields = false;
        let disabled = false;
        if (this.props.group && this.props.group.isTemplate && this.props.mainScreen === 'backOfficeFormQuestions') {
            disabled = true;
        }
        return (
            <>
                {hasEmptyMandatoryFields && <Error>{`Please fill the mandatory fields`}</Error>}
                <Button
                    icon={'close'}
                    clickAction={() => this.props.close(false)}
                    variation={'danger'}
                >{`Close`}</Button>
                {/*{question && question.status !== GROUP_STATUS.TERMINATED && (*/}
                <Button icon={'arrowRight'} clickAction={this.onSave} disabled={disabled}>{`Save`}</Button>
                {/*)}*/}
            </>
        );
    };

    render() {
        const {group} = this.state || {};

        return (
            <>
                {this.state.loading !== 0 && <LoadingBar />}
                {this.state.modalConfirmClose && (
                    <ModalConfirmVersion2
                        title={'Element modified'}
                        message={'Are you sure you want to validate your modifications?'}
                        buttonName={'Yes'}
                        action={() => this.props.close(true)}
                        close={this.closeModalConfirmClose}
                    />
                )}
                <section style={{visibility: this.state.loading === 0 ? 'visible' : 'hidden'}}>
                    <main
                        style={{
                            maxHeight: `calc(100vh - 350px)`,
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            marginRight: -25,
                            marginBottom: 30,
                        }}
                    >
                        <div
                            style={{
                                marginBottom: 25,
                                zIndex: 1056,
                            }}
                        >
                            <DataSheetFormSection title={'Group fields'} disableToggle={true}>
                                <div className={styles.textLabelGroupNameSearchWrap}>
                                    <TextLabelInput
                                        double={true}
                                        // onSelectionChange={this.onTextLabelGroupNameSelectionChange}
                                        // onChange={this.onTextLabelGroupNameValueChange}
                                        // value={this.state.textLabelGroupNameValue}
                                        selectedElements={this.state.textLabelGroupNameSelectedElements}
                                        noInfo={true}
                                        multiple={false}
                                        filterContains={true}
                                        label={'Name'}
                                        mandatory={true}
                                        listByIdName={this.state.textLabelGroupNameListByIdName}
                                        nameHash={this.state.textLabelGroupNameNameHash}
                                        uniqueValue={true}
                                        buttonIcon={this.state.textLabelGroupNameSearchLoading && faSpinner}
                                        timestamp={this.state.textTimestamp}
                                        autoSelection={true}
                                        forceHideSearchList={this.state.forceHideSearchList}
                                        disabled={true}
                                        hideRemoveElementButton={true}
                                    />
                                </div>
                                {/*<DataSheetFormFields*/}
                                {/*    label={'Name'}*/}
                                {/*    mandatory={true}*/}
                                {/*    html={renderHTML(group && group.nameMessage && group.nameMessage.value)}*/}
                                {/*    loading={this.state.loading}*/}
                                {/*    double={true}*/}
                                {/*    containerClassName="popover-nested"*/}
                                {/*    labelIcon={faList}*/}
                                {/*    labelPopup={this.renderPopupElements(*/}
                                {/*        group && group.nameMessage && group.nameMessage.id,*/}
                                {/*        getLabelTypeId('Group question'),*/}
                                {/*        'nameMessage',*/}
                                {/*        'remarklValueHTML'*/}
                                {/*    )}*/}
                                {/*    disabled={group && group.status === GROUP_STATUS.TERMINATED}*/}
                                {/*/>*/}
                                <DataSheetFormFields
                                    label={'Remark'}
                                    html={renderHTML(group && group.remarkMessage && group.remarkMessage.value)}
                                    loading={this.state.loading}
                                    double={true}
                                    containerClassName="popover-nested"
                                    labelIcon={faList}
                                    labelPopup={this.renderPopupElements(
                                        group && group.remarkMessage && group.remarkMessage.id,
                                        getLabelTypeId('Remark'),
                                        'remarkMessage',
                                        'remarklValueHTML'
                                    )}
                                    disabled={group && group.status === GROUP_STATUS.TERMINATED}
                                />
                                <DataSheetFormFields
                                    label={'Help'}
                                    html={renderHTML(group && group.helpMessage && group.helpMessage.value)}
                                    loading={this.state.loading}
                                    double={true}
                                    containerClassName="popover-nested"
                                    labelIcon={faList}
                                    labelPopup={this.renderPopupElements(
                                        group && group.helpMessage && group.helpMessage.id,
                                        getLabelTypeId('Help'),
                                        'helpMessage',
                                        'helplValueHTML'
                                    )}
                                    disabled={group && group.status === GROUP_STATUS.TERMINATED}
                                />
                                {/* TODO: FYI Add and Del buttons are used when the group or template is multi*/}
                                {/* meaning we can add a group and the button message will be the messageButtonAdd */}
                                {/* eg: Add a breeder in the main form */}
                                <DataSheetFormFields
                                    label={'Add'}
                                    html={renderHTML(group && group.btnAddMessage && group.btnAddMessage.value)}
                                    loading={this.state.loading}
                                    double={true}
                                    containerClassName="popover-nested"
                                    labelIcon={faList}
                                    labelPopup={this.renderPopupElements(
                                        group && group.btnAddMessage && group.btnAddMessage.id,
                                        getLabelTypeId('Front Office Button'),
                                        'btnAddMessage',
                                        'addlValueHTML'
                                    )}
                                    disabled={group && group.status === GROUP_STATUS.TERMINATED}
                                />
                                <DataSheetFormFields
                                    label={'Del'}
                                    html={renderHTML(group && group.btnDelMessage && group.btnDelMessage.value)}
                                    loading={this.state.loading}
                                    double={true}
                                    containerClassName="popover-nested"
                                    labelIcon={faList}
                                    labelPopup={this.renderPopupElements(
                                        group && group.btnDelMessage && group.btnDelMessage.id,
                                        getLabelTypeId('Front Office Button'),
                                        'btnDelMessage',
                                        'delValueHTML'
                                    )}
                                    disabled={group && group.status === GROUP_STATUS.TERMINATED}
                                />
                            </DataSheetFormSection>
                        </div>
                        <div>
                            <TextInput label={`Id`} value={group && group.idElement} disabled={true} simple={true} />
                            <TextInput
                                label={`Order display`}
                                mandatory={true}
                                value={group && group.order}
                                onChange={this.onOrderChange}
                                disabled={group && group.status !== GROUP_STATUS.IN_PREPARATION}
                            />
                            <TextInput
                                label={`Mapping code`}
                                value={group && group.mappingCode}
                                onChange={this.onMappingCodeChange}
                                disabled={group && group.status !== GROUP_STATUS.IN_PREPARATION}
                            />
                            {this.administrator && (
                                <TextInput
                                    label={`JSON Mapping`}
                                    required={true}
                                    value={group && group.jsonMapping}
                                    onChange={this.onJsonMappingChange}
                                    double={true}
                                    disabled={group && group.status === GROUP_STATUS.TERMINATED}
                                />
                            )}
                        </div>
                        <div style={{float: 'left', width: '100%'}}>
                            <Checkbox
                                clickAction={this.onMandatoryChange}
                                simple={true}
                                label={`Mandatory`}
                                value={group && group.mandatory}
                                disabled={group && group.status !== GROUP_STATUS.IN_PREPARATION}
                            />
                            <Checkbox
                                clickAction={this.onMultiChange}
                                simple={true}
                                label={`Multi`}
                                value={group && group.multi}
                                disabled={group && group.status !== GROUP_STATUS.IN_PREPARATION}
                            />
                        </div>
                        <div style={{clear: 'both'}} />
                        <div style={{float: 'left', fontStyle: 'italic'}}>
                            {this.props.group &&
                            this.props.group.isTemplate &&
                            this.props.mainScreen === 'backOfficeFormQuestions' ? (
                                <div
                                    style={{
                                        color: 'rgb(138, 109, 59)',
                                        fontStyle: 'italic',
                                        marginBottom: 0,
                                        marginTop: 15,
                                    }}
                                >
                                    {`Template modification is only available in templates screen`}
                                </div>
                            ) : (
                                <p style={{color: '#8a6d3b', marginBottom: 0}}>
                                    Note: required fields are marked with an asterisk (*)
                                </p>
                            )}
                            {/*{question && question.user && question.modifDate && (*/}
                            {/*    <p style={{color: '#31708f', marginTop: 5}}>*/}
                            {/*        Last update: {question.modifDate} by {question.user}*/}
                            {/*    </p>*/}
                            {/*)}*/}
                        </div>
                        <div style={{clear: 'both'}} />
                    </main>
                </section>
            </>
        );
    }
}

export default injectIntl(GroupOrTemplateModalDetailsGeneralInformation);
