import {IPayloadFormResponseCreateOrUpdate} from './ResponseModalDetailsGeneralInformationService';
import {IProps} from './ResponseModalDetailsGeneralInformation';

export function prepareJSONRequestBody(JSONRequest: any): IPayloadFormResponseCreateOrUpdate {
    const preparedJSONRequestBody: any = {...JSONRequest};
    preparedJSONRequestBody.nameMessage = {
        id: JSONRequest.nameMessage.id,
    };
    preparedJSONRequestBody.helpMessage = {
        id: JSONRequest.helpMessage.id,
    };
    preparedJSONRequestBody.remarkMessage = {
        id: JSONRequest.remarkMessage.id,
    };
    return preparedJSONRequestBody;
}

export const computeResponseOrder = (props: IProps) => {
    const {responseId, question, response} = props || {};
    const {responses} = question || {};
    if (responseId === 0) {
        let maxOrder = parseInt(([...responses].pop() || {}).order || '0');
        return responses && responses.length ? `00${maxOrder + 1}`.slice(-2) : '01';
    }
    if (response && response.order) {
        return response && response.order;
    }
    return '01';
};
