import React from 'react';
import {Document, Font, Image, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import {
    convertDate,
    DENOMINATION,
    GLOBAL_DATE_FORMAT,
    SYNONYM,
    TRADE_MARK,
    TRADE_NAME,
} from '../../utils/utilsVarietyFinder';

export interface IInvalidVarieties {
    denomination: string;
}

export interface IVarietyDataSheet {
    applicationnumber: string;
    applicant: string;
    app_date: string;
    app_pub_date: string;
    breeder: string;
    breeder_ref: string;
    country: string;
    countryid: string;
    crop_sectors: Array<string>;
    end_date: string;
    extra_data_url: string;
    future_exp_date: string;
    grant_date: string;
    grant_number: string;
    grant_pub_date: string;
    maintainer: string;
    name: string;
    other_names: Array<string>;
    other_parties: string;
    pub_name: string;
    pub_type_name: string;
    publicationtypeid: string;
    publicationextraid: number;
    remark: string;
    reg_status: string;
    renewal_date: string;
    species_code: string;
    species_classes: Array<string>;
    species_princ_bot_name: string;
    titleholder: string;
    varietyid: number;
    websiteurl: string;
    denominationsList: string;
}

Font.register({
    family: 'Open Sans',
    fonts: [{src: '/OpenSans-Regular.ttf'}, {src: '/OpenSans-Bold.ttf', fontWeight: 'bold'}],
});

// Create styles
const styles = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        margin: 10,
        padding: 10,
        fontFamily: 'Open Sans',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'center',
        flexGrow: 0,
        flexShrink: 0,
    },
    headerLeft: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 200,
    },
    headerRight: {
        flexShrink: 0,
        flexGrow: 0,
        flexBasis: 300,
        alignSelf: 'center',
    },
    headerText: {
        paddingLeft: 30,
        fontSize: 20,
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        color: '#2F932F',
    },
    col: {
        flexGrow: 0,
        flexShrink: 0,
    },
    textKey: {
        paddingTop: 4,
        paddingBottom: 4,
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        fontSize: 9,
        color: '#867B5E',
    },
    textValue: {
        border: 1,
        fontSize: 9,
        padding: 4,
    },
    regionTitle: {
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        fontSize: 14,
        color: '#2F932F',
    },
    fullRow: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 500,
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 8,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    table: {
        // @ts-ignore
        display: 'table',
        width: 'auto',
        marginRight: 38,
        marginLeft: 38,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        borderColor: '#AFA794',
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row',
    },
    tableCol: {
        width: '90%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: '#AFA794',
    },
    tableColNum: {
        width: '10%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: '#AFA794',
    },
    tableHeaderCol: {
        fontFamily: 'Open Sans',
        fontWeight: 'ultrabold',
        backgroundColor: '#EFE7DE',
        color: '#867A5E',
    },
    tableCell: {
        fontSize: 8,
        paddingHorizontal: 4,
        paddingVertical: 2,
        justifyContent: 'center',
    },
    image: {width: 200, backgroundColor: '#ffffff'},
});

// Create Document Component
export const VFPDFVarietyDataSheet = (props: {data: IVarietyDataSheet[]; invalidDenoms?: IInvalidVarieties[]}) => {
    const denominationList: any[] = [];
    const tradenameList: string[][] = [];
    const trademarkList: string[][] = [];
    const synonymsList: string[][] = [];

    props.data.forEach((datasheet: IVarietyDataSheet, i: number) => {
        denominationList[i] = JSON.parse(datasheet.denominationsList);

        trademarkList[i] = [];
        tradenameList[i] = [];
        synonymsList[i] = [];

        denominationList[i]?.denos.forEach((item: any) => {
            if (item?.nature === TRADE_MARK && item.name) {
                trademarkList[i].push(item.name);
            } else if (item?.nature === TRADE_NAME && item.name) {
                tradenameList[i].push(item.name);
            } else if (item?.nature === SYNONYM && item.name) {
                synonymsList[i].push(item.name);
            }
        });
    });

    const invalidDenominations = (
        <>
            {props.invalidDenoms && props.invalidDenoms.length > 0 ? (
                <Page size="A4" style={styles.page}>
                    <View style={[styles.row, {paddingTop: 10}]}>
                        <View style={styles.headerLeft}>
                            <Image style={styles.image} src="/logo-en.png" />
                        </View>
                        <View style={styles.headerRight}>
                            <Text style={styles.headerText}>{`Invalid varieties`}</Text>
                        </View>
                    </View>

                    <View style={[styles.table, {marginTop: 5}]}>
                        {/* TableHeader */}
                        <View style={styles.tableRow}>
                            <View style={[styles.tableHeaderCol, styles.tableColNum]}>
                                <Text style={styles.tableCell}>No.</Text>
                            </View>
                            <View style={[styles.tableHeaderCol, styles.tableCol]}>
                                <Text style={styles.tableCell}>{`Denomination`}</Text>
                            </View>
                        </View>

                        {props.invalidDenoms.map((data: any, i: number) => {
                            let key = `key-${i}`;
                            return (
                                <View style={styles.tableRow} key={key}>
                                    <View style={styles.tableColNum}>
                                        <Text style={styles.tableCell}>{i + 1}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{data}</Text>
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                    <Text
                        style={{
                            position: 'absolute',
                            bottom: 33,
                            left: 40,
                            right: 320,
                            borderBottom: 1,
                        }}
                        fixed
                    />

                    <Text
                        style={styles.pageNumber}
                        render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />

                    <Text
                        style={{
                            position: 'absolute',
                            bottom: 33,
                            left: 320,
                            right: 40,
                            borderBottom: 1,
                        }}
                        fixed
                    />
                </Page>
            ) : (
                <></>
            )}
        </>
    );

    return (
        <Document>
            <>
                {props.data.map((datasheet: IVarietyDataSheet, i: number) => (
                    <Page
                        key={`datasheet-${datasheet.varietyid}-${datasheet.applicationnumber}`}
                        size="A4"
                        style={styles.page}
                    >
                        <View style={[styles.row, {paddingTop: 10}]}>
                            <View style={styles.headerLeft}>
                                <Image style={styles.image} src="/logo-en.png" />
                            </View>
                            <View style={styles.headerRight}>
                                <Text style={styles.headerText}>{`Variety Data Sheet`}</Text>
                            </View>
                        </View>

                        <View style={[styles.row, {paddingTop: 5}]}>
                            <View style={[styles.col, {flexBasis: 100}]}>
                                <Text style={styles.textKey}>{`Country`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 400}]}>
                                <Text style={styles.textValue}>
                                    {datasheet.countryid ? `${datasheet.countryid} - ${datasheet.country}` : ''}
                                </Text>
                            </View>
                        </View>

                        <View style={[styles.row, {paddingTop: 5}]}>
                            <View style={[styles.col, {flexBasis: 100}]}>
                                <Text style={styles.textKey}>{`Register type`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 400}]}>
                                <Text style={styles.textValue}>{printHtmlField(datasheet.pub_type_name)}</Text>
                            </View>
                        </View>

                        <View style={[styles.row, {paddingTop: 5}]}>
                            <View style={[styles.col, {flexBasis: 100}]}>
                                <Text style={styles.textKey}>{`Register name`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 400}]}>
                                <Text style={styles.textValue}>{printHtmlField(datasheet.pub_name)}</Text>
                            </View>
                        </View>

                        <View style={[styles.row, {paddingTop: 5}]}>
                            <View style={[styles.col, {flexBasis: 100}]}>
                                <Text style={styles.textKey}>{`Species`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 400}]}>
                                <Text style={styles.textValue}>{printHtmlField(datasheet.species_princ_bot_name)}</Text>
                            </View>
                        </View>
                        <View style={[styles.row, {paddingTop: 5}]}>
                            <View style={[styles.col, {flexBasis: 100}]}>
                                <Text style={styles.textKey}>{`Variety Status`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 400}]}>
                                <Text style={styles.textValue}>{printHtmlField(datasheet.reg_status)}</Text>
                            </View>
                        </View>
                        <View style={[styles.row, {paddingTop: 20}]}>
                            <View style={[styles.col, {flexBasis: 250}]}>
                                <Text style={styles.regionTitle}>{`Application/filing`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 250}]}>
                                <Text style={[styles.regionTitle, {paddingLeft: 10}]}>{`Grant/registration`}</Text>
                            </View>
                        </View>
                        <View style={[styles.row, {paddingTop: 10}]}>
                            <View style={[styles.col, {flexBasis: 100}]}>
                                <Text style={styles.textKey}>{`Number`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 150}]}>
                                <Text style={styles.textValue}>{printHtmlField(datasheet.applicationnumber)}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 160}]}>
                                <Text style={[styles.textKey, {paddingLeft: 10}]}>{`Number`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 90}]}>
                                <Text style={styles.textValue}>{printHtmlField(datasheet.grant_number)}</Text>
                            </View>
                        </View>
                        <View style={[styles.row, {paddingTop: 5}]}>
                            <View style={[styles.col, {flexBasis: 100}]}>
                                <Text style={styles.textKey}>{`Date`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 150}]}>
                                <Text style={[styles.textValue, {width: 100}]}>
                                    {printHtmlField(datasheet.app_date, 'date')}
                                </Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 160}]}>
                                <Text style={[styles.textKey, {paddingLeft: 10}]}>{`Date`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 90}]}>
                                <Text style={styles.textValue}>{printHtmlField(datasheet.grant_date, 'date')}</Text>
                            </View>
                        </View>
                        <View style={[styles.row, {paddingTop: 5}]}>
                            <View style={[styles.col, {flexBasis: 100}]}>
                                <Text style={styles.textKey}>{`Published on`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 150}]}>
                                <Text style={[styles.textValue, {width: 100}]}>
                                    {printHtmlField(datasheet.app_pub_date, 'date')}
                                </Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 160}]}>
                                <Text style={[styles.textKey, {paddingLeft: 10}]}>{`Published on`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 90}]}>
                                <Text style={styles.textValue}>{printHtmlField(datasheet.grant_pub_date, 'date')}</Text>
                            </View>
                        </View>
                        <View style={[styles.row, {paddingTop: 5}]}>
                            <View style={[styles.col, {flexBasis: 250}]}>
                                <Text>&nbsp;</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 160}]}>
                                <Text style={[styles.textKey, {paddingLeft: 10}]}>{`Renewal on`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 90}]}>
                                <Text style={styles.textValue}>{printHtmlField(datasheet.renewal_date, 'date')}</Text>
                            </View>
                        </View>
                        <View style={[styles.row, {paddingTop: 5}]}>
                            <View style={[styles.col, {flexBasis: 250}]}>
                                <Text>&nbsp;</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 160}]}>
                                <Text style={[styles.textKey, {paddingLeft: 10}]}>{`Expiration actual`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 90}]}>
                                <Text style={styles.textValue}>{printHtmlField(datasheet.end_date, 'date')}</Text>
                            </View>
                        </View>
                        <View style={[styles.row, {paddingTop: 5}]}>
                            <View style={[styles.col, {flexBasis: 250}]}>
                                <Text>&nbsp;</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 160}]}>
                                <Text style={[styles.textKey, {paddingLeft: 10}]}>{`Future expiration date`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 90}]}>
                                <Text style={styles.textValue}>
                                    {printHtmlField(datasheet.future_exp_date, 'date')}
                                </Text>
                            </View>
                        </View>
                        <View style={[styles.row, {paddingTop: 20}]}>
                            <View style={[styles.col, {flexBasis: 100}]}>
                                <Text style={styles.textKey}>{`Breeder's Reference`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 400}]}>
                                <Text style={styles.textValue}>{printHtmlField(datasheet.breeder_ref)}</Text>
                            </View>
                        </View>
                        <View style={[styles.row, {paddingTop: 5}]}>
                            <View style={[styles.col, {flexBasis: 100}]}>
                                <Text style={styles.textKey}>{`Breeder`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 400}]}>
                                <Text style={styles.textValue}>{printHtmlField(datasheet.breeder)}</Text>
                            </View>
                        </View>
                        <View style={[styles.row, {paddingTop: 5}]}>
                            <View style={[styles.col, {flexBasis: 100}]}>
                                <Text style={styles.textKey}>{`Applicant`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 400}]}>
                                <Text style={styles.textValue}>{printHtmlField(datasheet.applicant)}</Text>
                            </View>
                        </View>
                        <View style={[styles.row, {paddingTop: 5}]}>
                            <View style={[styles.col, {flexBasis: 100}]}>
                                <Text style={styles.textKey}>{`Maintainer`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 400}]}>
                                <Text style={styles.textValue}>{printHtmlField(datasheet.maintainer)}</Text>
                            </View>
                        </View>
                        <View style={[styles.row, {paddingTop: 5}]}>
                            <View style={[styles.col, {flexBasis: 100}]}>
                                <Text style={styles.textKey}>{`Holder`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 400}]}>
                                <Text style={styles.textValue}>{printHtmlField(datasheet.titleholder)}</Text>
                            </View>
                        </View>
                        <View style={[styles.row, {paddingTop: 5}]}>
                            <View style={[styles.col, {flexBasis: 100}]}>
                                <Text style={styles.textKey}>{`Other parties`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 400}]}>
                                <Text style={styles.textValue}>{printHtmlField(datasheet.other_parties)}</Text>
                            </View>
                        </View>
                        <View style={[styles.row, {paddingTop: 5}]}>
                            <View style={[styles.col, {flexBasis: 100}]}>
                                <Text style={styles.textKey}>{`Remarks`}</Text>
                            </View>
                            <View style={[styles.col, {flexBasis: 400}]}>
                                <Text style={[styles.textValue, {minHeight: 100}]}>
                                    {printHtmlField(datasheet.remark)}
                                </Text>
                            </View>
                        </View>
                        <View break style={[styles.row, {paddingTop: 20}]}>
                            <View style={styles.fullRow}>
                                <Text style={styles.regionTitle}>{`Denominations list`}</Text>
                            </View>
                        </View>
                        {denominationList[i]?.denos
                            ?.filter((deno: any) => deno.nature === DENOMINATION)
                            .map((item: any, index: number) => {
                                let key = `denomination-i-${index}`;
                                return (
                                    <React.Fragment key={key}>
                                        <View style={[styles.row, {paddingTop: 10}]}>
                                            <View style={[styles.col, {flexBasis: 100}]}>
                                                <Text style={styles.textKey}>{`Denomination`}</Text>
                                            </View>
                                            <View style={[styles.col, {flexBasis: 400}]}>
                                                <Text
                                                    style={[
                                                        styles.textValue,
                                                        {
                                                            fontFamily: 'Open Sans',
                                                            fontWeight: 'ultrabold',
                                                        },
                                                    ]}
                                                >
                                                    {printHtmlField(item?.name)}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={[styles.row, {paddingTop: 5}]}>
                                            <View style={[styles.col, {flexBasis: 100}]}>
                                                <Text style={styles.textKey}>{`Type`}</Text>
                                            </View>
                                            <View style={[styles.col, {flexBasis: 200}]}>
                                                <Text style={[styles.textValue, {width: 170}]}>
                                                    {printHtmlField(item?.nature)}
                                                </Text>
                                            </View>
                                            <View style={[styles.col, {flexBasis: 120}]}>
                                                <Text style={[styles.textKey, {paddingLeft: 10}]}>{`Proposed on`}</Text>
                                            </View>
                                            <View style={[styles.col, {flexBasis: 80}]}>
                                                <Text style={styles.textValue}>{printHtmlField(item?.prop_dt)}</Text>
                                            </View>
                                        </View>
                                        <View style={[styles.row, {paddingTop: 5}]}>
                                            <View style={[styles.col, {flexBasis: 100}]}>
                                                <Text style={styles.textKey}>{`Status`}</Text>
                                            </View>
                                            <View style={[styles.col, {flexBasis: 200}]}>
                                                <Text style={[styles.textValue, {width: 170}]}>
                                                    {printHtmlField(item?.status)}
                                                </Text>
                                            </View>
                                            <View style={[styles.col, {flexBasis: 120}]}>
                                                <Text style={[styles.textKey, {paddingLeft: 10}]}>
                                                    {`Published on`}
                                                </Text>
                                            </View>
                                            <View style={[styles.col, {flexBasis: 80}]}>
                                                <Text style={styles.textValue}>{printHtmlField(item?.pub_dt)}</Text>
                                            </View>
                                        </View>
                                        <View style={[styles.row, {paddingTop: 5}]}>
                                            <View style={[styles.col, {flexBasis: 300}]}>
                                                <Text>&nbsp;</Text>
                                            </View>
                                            <View style={[styles.col, {flexBasis: 120}]}>
                                                <Text style={[styles.textKey, {paddingLeft: 10}]}>{`Approved on`}</Text>
                                            </View>
                                            <View style={[styles.col, {flexBasis: 80}]}>
                                                <Text style={styles.textValue}>{printHtmlField(item?.appr_dt)}</Text>
                                            </View>
                                        </View>
                                        <View style={[styles.row, {paddingTop: 5}]}>
                                            <View style={[styles.col, {flexBasis: 300}]}>
                                                <Text>&nbsp;</Text>
                                            </View>
                                            <View style={[styles.col, {flexBasis: 120}]}>
                                                <Text style={[styles.textKey, {paddingLeft: 10}]}>{`End Date`}</Text>
                                            </View>
                                            <View style={[styles.col, {flexBasis: 80}]}>
                                                <Text style={styles.textValue}>{printHtmlField(item?.end_dt)}</Text>
                                            </View>
                                        </View>
                                    </React.Fragment>
                                );
                            })}
                        <View style={[styles.row, {paddingTop: 20}]}>
                            <View style={styles.fullRow}>
                                <Text style={styles.regionTitle}>{`List of synonyms`}</Text>
                            </View>
                        </View>
                        {synonymsList[i].map((synonym: string, index: number) => {
                            let key = `key-${index}`;
                            return (
                                <View key={`synonym-${key}`} style={[styles.row, {paddingTop: 10}]}>
                                    <View style={[styles.col, {flexBasis: 100}]}>
                                        <Text style={[styles.textKey, {color: '#000000'}]}>{`Synonym`}</Text>
                                    </View>
                                    <View style={[styles.col, {flexBasis: 400}]}>
                                        <Text style={[styles.textKey, {color: '#000000'}]}>{synonym}</Text>
                                    </View>
                                </View>
                            );
                        })}
                        <View style={[styles.row, {paddingTop: 20}]}>
                            <View style={styles.fullRow}>
                                <Text style={styles.regionTitle}>{`Trade names`}</Text>
                            </View>
                        </View>
                        {tradenameList[i].map((tradename: string, index: number) => {
                            let key = `key-${index}`;
                            return (
                                <View key={`tradename-${key}`} style={[styles.row, {paddingTop: 10}]}>
                                    <View style={[styles.col, {flexBasis: 100}]}>
                                        <Text style={[styles.textKey, {color: '#000000'}]}>{`Trade name`}</Text>
                                    </View>
                                    <View style={[styles.col, {flexBasis: 400}]}>
                                        <Text style={[styles.textKey, {color: '#000000'}]}>{tradename}</Text>
                                    </View>
                                </View>
                            );
                        })}
                        <View style={[styles.row, {paddingTop: 20}]}>
                            <View style={styles.fullRow}>
                                <Text style={styles.regionTitle}>{`Trade marks`}</Text>
                            </View>
                        </View>
                        {trademarkList[i].map((trademark: string, index: number) => {
                            let key = `key-${index}}`;
                            return (
                                <View key={`trademark-${key}`} style={[styles.row, {paddingTop: 10}]}>
                                    <View style={[styles.col, {flexBasis: 100}]}>
                                        <Text style={[styles.textKey, {color: '#000000'}]}>{`Trade mark`}</Text>
                                    </View>
                                    <View style={[styles.col, {flexBasis: 400}]}>
                                        <Text style={[styles.textKey, {color: '#000000'}]}>{trademark}</Text>
                                    </View>
                                </View>
                            );
                        })}
                        <Text
                            style={{
                                position: 'absolute',
                                bottom: 33,
                                left: 40,
                                right: 320,
                                borderBottom: 1,
                            }}
                            fixed
                        />
                        <Text
                            style={styles.pageNumber}
                            render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
                            fixed
                        />
                        <Text
                            style={{
                                position: 'absolute',
                                bottom: 33,
                                left: 320,
                                right: 40,
                                borderBottom: 1,
                            }}
                            fixed
                        />
                    </Page>
                ))}
                {invalidDenominations}
            </>
        </Document>
    );
};

const printHtmlField = (value: any, type = 'text') => {
    if (type === 'date') {
        const dateFormatted = convertDate(value, GLOBAL_DATE_FORMAT, 'DDMMYYYY');
        return dateFormatted ? dateFormatted : ' ';
    }
    return value ? value : ' ';
};
