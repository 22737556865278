import React from 'react';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {faArchive, faExclamation, faPaperclip, faUser} from '@fortawesome/free-solid-svg-icons';
import CustomTable from '~components/CustomTable';
import DateInput from '~components/DateInput';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import FormFooterLinkButton from '~componentsForm/FormFooterLinkButton';
import {FORMAT_DATE_TIME_EASY, reformatDateEasy} from '~components/FormatFunctions';
import {getDecodedJWT, loadUrlParams} from '~utils';
import CCModalClientCompose from '../CCModalClientCompose/CCModalClientCompose';
import CCModalClientHistory from '../CCModalClientHistory/CCModalClientHistory';
import CCModalClientRead from '../CCModalClientRead/CCModalClientRead';
import CCModalClientReply from '../CCModalClientReply/CCModalClientReply';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import CCClientInboxActionButtons from './CCClientInboxActionButtons';
import {
    apiCommunicationCentreClientInboxClients,
    apiCommunicationCentreClientInboxMarkMessagesRead,
    apiCommunicationCentreClientInboxMarkMessagesTicked,
    apiCommunicationCentreClientInboxMarkMessagesUnTick,
    apiCommunicationCentreClientInboxMessageArchive,
    apiCommunicationCentreClientInboxSpecies,
    apiCommunicationCentreClientSearchInbox,
} from './CCClientInboxService';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import addressedTo from '../MyPVRMyDocuments/data/DocumentsAddressedTo.json';
import ApplicationNumbersMultiple from '../../components/ApplicationNumbersMultiple';
import {
    ICommunicationMessageClient,
    ICommunicationMessageClientHistory,
    ICommunicationMessageClientRead,
    ICommunicationMessagesClientSearchResults,
    IDecodedJWT,
} from '../../types';
import _COMMUNICATION_KEYS_TO_TYPE from '../CommunicationCentreClient/DATA/COMMUNICATION_KEYS_TO_TYPES.json';
import {
    addDaysToDate,
    getDateObjectByStringDate,
    getStringDateByDateObject,
    trackPageView,
    utilCalculateTableMaxPossiblePage,
} from '../../utils';
import {formatDateEasy, formatDateTimeEasy} from '../../components/FormatFunctions';
import {faEnvelopeOpen} from '@fortawesome/free-regular-svg-icons';
import CPVOIcon from '../../shared/img/cpvo.svg';
import ModalPreview from '../CommunicationCentreInternal/modals/ModalPreview';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import {Button} from '../../componentsFormV2';
import NavigationCommunicationCentre from '../../shared/NavigationCommunicationCentre';
import {ModalApplicationNumbers, ModalCommunicationCentreDocumentList, ModalConfirmVersion2} from '../../commonModals';

export const RESULT_FIELDS_DEFAULT_INBOX = [
    'from',
    'documentType',
    'messageSubject',
    'applicationNumbers',
    'firstOpened',
    'notificationDate',
    'deemedService',
    'replyBy',
];

export const RESULT_FIELDS_ALL_INBOX = [
    'from',
    'addressees',
    'documentType',
    'messageSubject',
    'applicationNumbers',
    'firstOpened',
    'notificationDate',
    'deemedService',
    'replyBy',
];

const DATEFORMAT_CRITERIA_KEYS = ['arrivalDateFrom', 'arrivalDateTo', 'notificationDateFrom', 'notificationDateTo'];

const ALL = '0';

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

type TKeyOfDefaultCriteria = keyof TDefaultCriteria;

export const DEFAULT_CRITERIA = {
    denominationFilter: 'equals',
    pageNumber: 1,
    pageSize: 10,
    order: 'sentDate',
    reverse: true,
    refresh: false,
    excel: false,
    searchTerm: '',
    documentType: 'All',
    addressedTo: ALL,
    applicationNumber: '',
    arrivalDateFrom: '',
    arrivalDateTo: '',
    notificationDateFrom: '',
    notificationDateTo: '',
    readingStatus: 'All',
    sendingDateFrom: '',
    sendingDateTo: '',
    ticked: '-1',
    applicants: '',
    denomination: '',
    denominationFiler: 'equals',
    breedersReference: '',
    speciesNameFilter: 'starts',
    speciesIds: '',
    speciesName: '',
};

const advancedCriteria = ['speciesIds', 'applicants', 'denomination', 'breedersReference'];

const documentTypes = [
    {id: 'All', value: 'All'},
    {id: 'Acknowledgements of receipt', value: 'Acknowledgements of receipt'},
    {id: 'Application - Other matters', value: 'Application - Other matters'},
    {id: 'Application documents', value: 'Application documents'},
    {id: 'Deed of assignment', value: 'Deed of assignment'},
    {id: 'Denomination - Other documents', value: 'Denomination - Other documents'},
    {id: 'Denomination Proposal', value: 'Denomination Proposal'},
    {id: 'Denomination objection/observations', value: 'Denomination objection/observations'},
    {id: 'Designation of procedural representative', value: 'Designation of procedural representative'},
    {id: 'Examination procedure', value: 'Examination procedure'},
    {id: 'Financial information', value: 'Financial information'},
    {id: 'Legal proceedings', value: 'Legal proceedings'},
    {id: 'Legal proceedings to be identified', value: 'Legal proceedings to be identified'},
    {id: 'Miscellaneous - Other matters', value: 'Miscellaneous - Other matters'},
    {id: 'Novelty', value: 'Novelty'},
    {id: 'Office decisions', value: 'Office decisions'},
    {id: 'Photo submitted by the applicant', value: 'Photo submitted by the applicant'},
    {id: 'Priority', value: 'Priority'},
    {id: 'Recordals in the register', value: 'Recordals in the register'},
    {id: 'Reports', value: 'Reports'},
    {id: 'Request for plant material', value: 'Request for plant material'},
    {id: 'Title of protection', value: 'Title of protection'},
    {id: 'Withdrawal/Surrender documents', value: 'Withdrawal/Surrender documents'},
];

const SELECT_INPUT_LIST_TICKING_STATUS = [
    {id: '-1', value: 'All'},
    {id: '1', value: 'Ticked'},
    {id: '0', value: 'Unticked'},
];

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    advancedOpened: boolean;
    advancedCriteriaCount: number;
    clientListByIdName: {ID: string; NAME: string}[];
    clientName: string;
    clientNameHash: any;
    criteriaCount: number;
    communicationIdsSelected: string[];
    companyName: string;
    count: number;
    criteria: TDefaultCriteria;
    isModalConfirmNotReplyingToColleagueOpen: ICommunicationMessageClient | boolean;
    isModalHistoryOpen: number | false;
    isModalPreviewOpen: boolean;
    loading: number;
    messages: ICommunicationMessageClient[];
    modalApplicationNumbers: string[];
    modalClientMessageCompose: boolean;
    modalClientMessageRead: number | false;
    modalClientMessageReply: number | false;
    modalCPVOLogs: {
        communicationId: number | null;
        subject: string;
    };
    modalPreviewURL: string;
    modalPreviewLastPageResultSetSize: number | null;
    modalPreviewMessage: ICommunicationMessageClientRead | null;
    screenLoaded: boolean;
    selectedClients: {[key: string]: string};
    speciesListByIdName: any;
    speciesNameHash: any;
    selectedSpecies: any;
    timestamp: number;
    viewSearchTermOnly: boolean;
}

class CCClientInbox extends React.Component<IProps, IState> {
    decodedJWT: IDecodedJWT = getDecodedJWT();
    initialCriteria: TDefaultCriteria = Object.assign({}, DEFAULT_CRITERIA);

    constructor(props: IProps) {
        super(props);
        this.state = {
            advancedOpened: false,
            advancedCriteriaCount: 0,
            clientListByIdName: [],
            clientName: '',
            clientNameHash: [],
            communicationIdsSelected: [],
            companyName: this.decodedJWT.company,
            count: 0,
            criteria: Object.assign({}, DEFAULT_CRITERIA),
            criteriaCount: 0,
            isModalConfirmNotReplyingToColleagueOpen: false,
            isModalHistoryOpen: false,
            isModalPreviewOpen: false,
            loading: 0,
            messages: [],
            modalApplicationNumbers: [],
            modalClientMessageCompose: false,
            modalClientMessageRead: false,
            modalClientMessageReply: false,
            modalCPVOLogs: {
                communicationId: null,
                subject: '',
            },
            screenLoaded: false,
            selectedClients: {},
            speciesListByIdName: null,
            speciesNameHash: null,
            selectedSpecies: {},
            timestamp: Date.now(),
            viewSearchTermOnly: false,
            modalPreviewURL: '',
            modalPreviewLastPageResultSetSize: null,
            modalPreviewMessage: null,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'communicationCentreClientInbox'});
        const criteria = loadUrlParams(DATEFORMAT_CRITERIA_KEYS);
        let shouldOpenModalMessageCompose = window.location.href.includes('?new');
        Promise.all([this.loadSpecies(criteria), this.loadClients(criteria)]).then(
            () => shouldOpenModalMessageCompose && this.openModalClientMessageCompose()
        );
        this.setState({criteria: {...DEFAULT_CRITERIA, ...criteria}, timestamp: Date.now()}, () =>
            this.search(false, false, () => this.setState({screenLoaded: true}))
        );
    }

    loadSpecies = (criteria: any) =>
        new Promise((resolve, reject) => {
            this.setState(
                prev => ({
                    loading: prev.loading + 1,
                }),
                () => {
                    apiCommunicationCentreClientInboxSpecies()
                        .then((jsonResponse: any) => {
                            if (jsonResponse) {
                                const speciesNameHash: any = {};
                                (jsonResponse || []).forEach(
                                    (speciesElement: {
                                        FORMATED: string;
                                        ID: string;
                                        NAME: string;
                                        SPECIEUPOVID: string;
                                    }) => {
                                        if (!speciesNameHash[speciesElement.NAME]) {
                                            speciesNameHash[speciesElement.NAME] = [];
                                        }
                                        speciesNameHash[speciesElement.NAME].push(speciesElement.ID);
                                    }
                                );
                                const speciesListByIdName = Object.keys(speciesNameHash).map(speciesName => ({
                                    ID: speciesNameHash[speciesName].join(','),
                                    NAME: speciesName,
                                }));
                                const selectedSpecies: any = {};
                                const selectedSpeciesKeyArray = Object.keys(this.state.selectedSpecies || {});
                                if (criteria.speciesIds) {
                                    criteria.speciesIds.split(',').forEach((speciesId: string) => {
                                        selectedSpecies[speciesId] = (
                                            speciesListByIdName.find(el => el.ID === speciesId) || {}
                                        ).NAME;
                                    });
                                }
                                speciesListByIdName
                                    .filter(i => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                                    .forEach(item => (selectedSpecies[item.ID] = item.NAME));
                                this.setState(
                                    prev => ({
                                        loading: prev.loading - 1,
                                        speciesListByIdName,
                                        speciesNameHash,
                                        selectedSpecies,
                                        timestamp: Date.now(),
                                    }),
                                    () => resolve([])
                                );
                            }
                        })
                        .catch((error: any) => {
                            reject();
                            ERROR([`FormLabelInputSpecies: ${error.message}`]);
                        });
                }
            );
        });

    loadClients = (criteria: any) =>
        new Promise((resolve, reject) => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiCommunicationCentreClientInboxClients()
                        .then((jsonResponse: any) => {
                            if (jsonResponse) {
                                const clientNameHash: {[key: string]: string[]} = {};
                                ((jsonResponse && jsonResponse.clients) || []).forEach(
                                    (client: {clientId: string; name: string}) => {
                                        if (!clientNameHash[client.name]) {
                                            clientNameHash[client.name] = [];
                                        }
                                        clientNameHash[client.name].push(client.clientId);
                                    }
                                );
                                const clientListByIdName: {ID: string; NAME: string}[] = Object.keys(
                                    clientNameHash
                                ).map(clientName => ({
                                    ID: clientNameHash[clientName].join(','),
                                    NAME: clientName,
                                }));
                                const selectedClients: any = {};
                                const selectedClientsKeyArray = Object.keys(this.state.selectedClients || {});
                                clientListByIdName
                                    .filter(i => selectedClientsKeyArray.indexOf(i.ID) !== -1)
                                    .forEach(item => (selectedClients[item.ID] = item.NAME));
                                if (criteria.applicants) {
                                    criteria.applicants
                                        .split(',')
                                        .forEach(
                                            (applicantId: any) =>
                                                (selectedClients[applicantId] = (
                                                    clientListByIdName.find(el => el.ID === applicantId) || {}
                                                ).NAME)
                                        );
                                }
                                this.setState(
                                    prev => ({
                                        loading: prev.loading - 1,
                                        clientListByIdName,
                                        clientNameHash,
                                        selectedClients,
                                    }),
                                    () => resolve([])
                                );
                            }
                        })
                        .catch((error: any) => {
                            reject();
                            ERROR([`Error loadJSONs() at MyPVRFinances: ${error.message}`]);
                        });
                }
            );
        });

    goToScreen = (screen: string) =>
        this.setState({criteria: {...DEFAULT_CRITERIA}}, () => {
            this.props.history.push(screen);
        });

    parseCriteria = (criteria: TDefaultCriteria): TDefaultCriteria => {
        const parsedCriteria: any = {};
        Object.keys(criteria).map(key => {
            let criteriaValue = criteria[key as TKeyOfDefaultCriteria];
            if (DATEFORMAT_CRITERIA_KEYS.indexOf(key) !== -1 && criteriaValue !== '') {
                parsedCriteria[key] = reformatDateEasy(criteriaValue);
            } else if (key === 'documentType') {
                parsedCriteria[key] = encodeURIComponent(criteriaValue);
            } else {
                parsedCriteria[key] = criteriaValue;
            }
        });
        return parsedCriteria as TDefaultCriteria;
    };

    buildParams = (parsedCriteria: TDefaultCriteria): void => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(
                i =>
                    parsedCriteria[i as TKeyOfDefaultCriteria] !== DEFAULT_CRITERIA[i as TKeyOfDefaultCriteria] &&
                    i !== 'refresh'
            )
            .map(key => `${key}=${parsedCriteria[key as TKeyOfDefaultCriteria]}`);
        this.props.history.replace(
            `/communicationCentreClientInbox${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`
        );
    };

    countCriteria = (criteria: TDefaultCriteria, advanced?: boolean): number => {
        let count = 0;
        const hasValue = (value: any) => !!value;
        (advanced ? advancedCriteria : Object.keys(criteria)).map(key => {
            let criteriaValue = criteria[key as TKeyOfDefaultCriteria];
            if (
                (hasValue(criteriaValue) || hasValue(criteriaValue)) &&
                criteriaValue !== this.initialCriteria[key as TKeyOfDefaultCriteria]
            )
                count++;
        });
        return count;
    };

    search = (refresh?: boolean, urlLoad?: boolean, callBack?: () => any): void => {
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams(parsedCriteria);
                apiCommunicationCentreClientSearchInbox(parsedCriteria, DEFAULT_CRITERIA)
                    .then((jsonResponse: ICommunicationMessagesClientSearchResults) => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.messages) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        messages: jsonResponse.data.messages,
                                        timestamp: Date.now(),
                                    },
                                    jsonResponse.data.count && {count: jsonResponse.data.count},
                                    !refresh && {selectedMessages: {}}
                                );
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`Register search list error: ${error.message}`]);
                    })
                    .then(() => {
                        this.setState(prev => ({loading: prev.loading - 1}));
                        callBack && callBack();
                    });
            }
        );
    };

    updateCriteriaValue = (
        criteriaValue: Partial<TDefaultCriteria>,
        callback?: () => any,
        refresh?: boolean,
        searchCallback?: () => any
    ): void => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                pageNumberChanged = prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = prev.criteria.order !== criteriaValue.order;
                reverseChanged = prev.criteria.reverse !== criteriaValue.reverse;
                return {
                    criteria,
                    criteriaCount,
                    advancedCriteriaCount,
                };
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true, false, searchCallback);
            }
        );
    };

    openModalClientMessageRead = (communicationId: number) => this.setState({modalClientMessageRead: communicationId});

    closeModalClientMessageRead = () =>
        this.setState({modalClientMessageRead: false, isModalPreviewOpen: false, modalPreviewURL: ''}, () =>
            this.search(true)
        );

    closeModalClientMessageReply = () =>
        this.setState({modalClientMessageReply: false, isModalPreviewOpen: false, modalPreviewURL: ''}, () =>
            this.search(true)
        );

    openModalClientMessageCompose = () => this.setState({modalClientMessageCompose: true});

    closeModalClientMessageCompose = () => this.setState({modalClientMessageCompose: false}, () => this.search(true));

    closeModalClientMessageHistory = () => this.setState({isModalHistoryOpen: false}, () => this.search(true));

    closeModalCPVOLogs = (): void => this.setState({modalCPVOLogs: {communicationId: null, subject: ''}});

    openModalCPVOLogs = ({communicationId, subject}: {communicationId: number; subject: string}) =>
        this.search(false, false, () => {
            this.setState({
                modalCPVOLogs: {
                    communicationId,
                    subject,
                },
            });
        });

    getTableFormatFunctions = () => ({
        from: (from: string) => (
            <>
                {(from || '').includes('Community Plant Variety Office') ? (
                    <img
                        src={CPVOIcon}
                        style={{
                            position: 'relative',
                            marginRight: -4,
                            left: -10,
                            width: 42,
                            height: 42,
                            verticalAlign: 'middle',
                        }}
                        alt="CPVO logo"
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faUser as any}
                        color={'gray'}
                        style={{width: 22, height: 25, verticalAlign: 'middle', marginRight: 10}}
                    />
                )}
                {from}
            </>
        ),
        applicationNumbers: (applicationNumbers: string[]) => (
            <ApplicationNumbersMultiple
                applicationNumbers={applicationNumbers}
                openModalApplicationNumbers={this.openModalApplicationNumbers}
            />
        ),
        deemedService: (_: any, rowObject: ICommunicationMessageClient) => {
            const {thirdPartyId} = this.decodedJWT;
            const {firstOpened, notificationDate, thirdPartyIdFrom} = rowObject;
            if (thirdPartyIdFrom === parseInt(thirdPartyId)) {
                return null;
            }
            let deemedServiceResult = '';
            let notificationDatePlus7Days = addDaysToDate({
                date: notificationDate,
                numberOfDays: 7,
            });

            if (firstOpened) {
                let firstOpenedDateObject = getDateObjectByStringDate(firstOpened);
                if (firstOpenedDateObject > notificationDatePlus7Days) {
                    deemedServiceResult = getStringDateByDateObject(notificationDatePlus7Days);
                }
            } else {
                if (new Date() > notificationDatePlus7Days) {
                    deemedServiceResult = getStringDateByDateObject(notificationDatePlus7Days);
                }
            }
            return deemedServiceResult ? formatDateEasy(deemedServiceResult) : '';
        },
        documentType: (type: string, rowObject: ICommunicationMessageClient) => {
            const {docmanProfile, docmanDirectory, docmanSubtypeOfDocument} = rowObject || {};
            let key = `${docmanProfile}-${docmanDirectory}-${docmanSubtypeOfDocument}`;
            const COMMUNICATION_KEYS_TO_TYPE: {[key: string]: string} = _COMMUNICATION_KEYS_TO_TYPE;
            return COMMUNICATION_KEYS_TO_TYPE[key];
        },
        firstOpened: (firstOpened: string | null, rowObject: ICommunicationMessageClient) => {
            const {thirdPartyId} = this.decodedJWT;
            const {thirdPartyIdFrom} = rowObject;
            if (thirdPartyIdFrom === parseInt(thirdPartyId)) {
                return null;
            }
            return formatDateTimeEasy(firstOpened);
        },
        lastUpdateDate: FORMAT_DATE_TIME_EASY,
        notificationDate: (notificationDate: string | null, rowObject: ICommunicationMessageClient) => {
            const {thirdPartyId} = this.decodedJWT;
            const {thirdPartyIdFrom} = rowObject;
            if (thirdPartyIdFrom === parseInt(thirdPartyId)) {
                return null;
            }
            return formatDateTimeEasy(notificationDate);
        },
        receivedDate: FORMAT_DATE_TIME_EASY,
        sentDate: FORMAT_DATE_TIME_EASY,
        replyBy: (replyBy: any, rowObject: ICommunicationMessageClient) => {
            const {thirdPartyIdFrom} = rowObject || {};
            const {thirdPartyId} = this.decodedJWT || {};
            return thirdPartyIdFrom !== parseInt(thirdPartyId) ? '' : replyBy;
        },
    });

    formatTableMessage = (messages: (ICommunicationMessageClient | ICommunicationMessageClientHistory)[]) =>
        messages.map(el => ({
            ...el,
            lastUpdateDate: el.sentDate,
            receivedDate: el.sentDate,
            to: 'Community Plant Variety Office',
        }));

    toggleAdvancedSearch = () =>
        this.setState(prevState => ({
            advancedOpened: !prevState.advancedOpened,
        }));

    openModalReply = (rowObject: ICommunicationMessageClient) => {
        const {communicationId} = rowObject || {};
        this.setState({modalClientMessageReply: communicationId}, () => {
            this.setState({modalClientMessageRead: false});
        });
    };

    closeModalApplicationNumbers = () => this.setState({modalApplicationNumbers: []});

    openModalApplicationNumbers = (applicationNumbers: string[]): void =>
        this.setState({modalApplicationNumbers: applicationNumbers});

    setModalPreviewURL = (URL: any) => this.setState({modalPreviewURL: URL});

    setModalPreviewMessage = (message: ICommunicationMessageClientRead) =>
        this.setState({modalPreviewMessage: message});

    updateModalClientMessageReply = (nextMessageId: number) => {
        this.setState({modalClientMessageReply: false, modalPreviewURL: ''}, () => {
            this.setState({modalClientMessageReply: nextMessageId});
        });
    };

    updateModalClientMessageRead = (nextMessageId: number) => {
        this.setState({modalClientMessageRead: false, modalPreviewURL: ''}, () => {
            this.openModalClientMessageRead(nextMessageId);
        });
    };

    tablePaginate = (direction: -1 | 1) => {
        const currentPageNumber = this.state.criteria.pageNumber;
        this.updateCriteriaValue(
            {pageNumber: currentPageNumber + direction},
            () => null,
            true,
            () => {
                let nextMessage: any;
                if (direction === -1) {
                    nextMessage = [...this.state.messages].pop();
                } else {
                    nextMessage = this.state.messages.length ? {...this.state.messages[0]} : null;
                }
                let nextMessageId = (nextMessage && nextMessage.communicationId) || 0;
                this.updateModalClientMessageRead(nextMessageId);
            }
        );
    };

    onTextInputSearchTermChange = ({target: {value: searchTerm}}: React.ChangeEvent<HTMLInputElement>): void =>
        this.updateCriteriaValue({searchTerm});

    onTextInputValueApplicationNumberChange = ({
        target: {value: textInputValueApplicationNumber},
    }: React.ChangeEvent<HTMLInputElement>): void =>
        this.updateCriteriaValue({applicationNumber: textInputValueApplicationNumber});

    onDateInputValueArrivalDateFromChange = (dateInputValueArrivalDateFrom: string) =>
        this.updateCriteriaValue({arrivalDateFrom: dateInputValueArrivalDateFrom});

    onDateInputValueArrivalDateToChange = (dateInputValueArrivalDateTo: string) =>
        this.updateCriteriaValue({arrivalDateTo: dateInputValueArrivalDateTo});

    onSelectInputValueReadingStatusChange = ({
        target: {value: selectInputValueReadingStatus},
    }: React.ChangeEvent<HTMLSelectElement>): any =>
        this.updateCriteriaValue({readingStatus: selectInputValueReadingStatus});

    onSelectInputDocumentTypeChange = ({target: {value: documentType}}: React.ChangeEvent<HTMLSelectElement>): any =>
        this.updateCriteriaValue({documentType});

    onDateInputValueNotificationDateFromChange = (dateInputValueNotificationDateFrom: string) =>
        this.updateCriteriaValue({notificationDateFrom: dateInputValueNotificationDateFrom});

    onDateInputValueNotificationDateToChange = (dateInputValueNotificationDateTo: string) =>
        this.updateCriteriaValue({notificationDateTo: dateInputValueNotificationDateTo});

    onSelectedClientsIdChange = (selectedClients: {[key: string]: string}): void =>
        this.updateCriteriaValue({applicants: Object.keys(selectedClients).join(',')}, () =>
            this.setState({selectedClients, clientName: ''})
        );

    onClientNameChange = (clientName: string) => this.setState({clientName});

    onSpeciesNameFilterChange = ({target: {value: speciesNameFilter}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({speciesNameFilter});

    onSpeciesSelectionChange = (selectedSpecies: {[key: string]: string}) =>
        this.updateCriteriaValue({speciesIds: Object.keys(selectedSpecies).join(','), speciesName: ''}, () =>
            this.setState({selectedSpecies})
        );

    onSpeciesNameChange = (speciesName: string): void => this.updateCriteriaValue({speciesName});

    onDenominationFilterChange = ({target: {value: denominationFilter}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({denominationFilter});

    onDenominationChange = ({target: {value: denomination}}: React.ChangeEvent<HTMLInputElement>): void =>
        this.updateCriteriaValue({denomination});

    onBreederReferenceChange = ({target: {value: breedersReference}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({breedersReference});

    onTableIconHistoryClick = ({correlationId}: ICommunicationMessageClient) =>
        this.setState({isModalHistoryOpen: correlationId});

    onTableIconCheckClick = ({communicationId, isTicked}: ICommunicationMessageClient) =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                (isTicked
                    ? apiCommunicationCentreClientInboxMarkMessagesUnTick
                    : apiCommunicationCentreClientInboxMarkMessagesTicked)(communicationId).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1}));
                    this.search(true);
                })
        );

    onModalClientMessageReadButtonReplyClick = () =>
        this.setState(prev => ({
            modalClientMessageRead: false,
            modalClientMessageReply: prev.modalClientMessageRead,
            isModalPreviewOpen: true,
        }));

    onClearFieldsClick = () =>
        this.setState(
            prev => {
                return {
                    advancedCriteriaCount: 0,
                    clientName: '',
                    selectedClients: {},
                    selectedSpecies: {},
                    timestamp: Date.now(),
                    criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
                };
            },
            () => this.search()
        );

    onTableIconReplyClick = (rowObject: ICommunicationMessageClient) => {
        const {thirdPartyIdFrom} = rowObject || {};
        let {thirdPartyId: loggedInUserThirdPartyId} = this.decodedJWT || {};
        if (parseInt(loggedInUserThirdPartyId) === thirdPartyIdFrom) {
            this.setState({isModalConfirmNotReplyingToColleagueOpen: rowObject});
        } else {
            this.openModalReply(rowObject);
        }
    };

    onTableIconArchiveClick = (communicationId: number): void =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                apiCommunicationCentreClientInboxMessageArchive([communicationId]).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1}));
                    this.search(true);
                })
        );

    onTableRowClick = (communicationId: number): void => {
        this.openModalClientMessageRead(communicationId);
        this.setState({isModalPreviewOpen: true});
    };

    onSelectedChange = (communicationIdsSelected: string[]): void => this.setState({communicationIdsSelected});

    onSelectedAddressToChange = ({target: {value: addressedTo}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({addressedTo});

    onButtonArchiveSelectedClick = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                apiCommunicationCentreClientInboxMessageArchive(
                    this.state.communicationIdsSelected.map(el => parseInt(el))
                ).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1, communicationIdsSelected: []}));
                    this.search(true);
                })
        );

    onButtonMarkAllAsReadClick = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                apiCommunicationCentreClientInboxMarkMessagesRead({all: 'inbox'}).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1}));
                    this.search(true);
                })
        );

    onButtonMarkSelectedAsReadClick = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                apiCommunicationCentreClientInboxMarkMessagesRead({
                    communicationIds: this.state.communicationIdsSelected.map(el => parseInt(el)),
                }).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1, communicationIdsSelected: []}));
                    this.search(true);
                })
        );

    onModalPreviewButtonFirstClick = () => {
        this.updateCriteriaValue(
            {pageNumber: 1},
            () => null,
            true,
            () => {
                let nextMessageId = this.state.messages[0].communicationId;
                this.updateModalClientMessageRead(nextMessageId);
            }
        );
    };

    onModalPreviewButtonPreviousClick = () => {
        let currentCommunicationIdIndex = this.state.messages.findIndex(
            el => el.communicationId === this.state.modalClientMessageRead
        );
        if (currentCommunicationIdIndex > 0) {
            let nextCommunicationId = this.state.messages[currentCommunicationIdIndex - 1].communicationId;
            this.updateModalClientMessageRead(nextCommunicationId);
        } else {
            this.tablePaginate(-1);
        }
    };

    onModalPreviewButtonNextClick = () => {
        let currentCommunicationIdIndex = this.state.messages.findIndex(
            el => el.communicationId === this.state.modalClientMessageRead || this.state.modalClientMessageReply
        );
        if (currentCommunicationIdIndex < this.state.messages.length - 1) {
            let nextCommunicationId = this.state.messages[currentCommunicationIdIndex + 1].communicationId;
            this.updateModalClientMessageRead(nextCommunicationId);
        } else {
            this.tablePaginate(1);
        }
    };

    onModalPreviewButtonLastClick = () => {
        const lastPage = utilCalculateTableMaxPossiblePage({
            totalRows: this.state.count,
            pageSize: this.state.criteria.pageSize,
        });
        this.updateCriteriaValue(
            {pageNumber: lastPage},
            () => null,
            true,
            () => {
                let nextMessage = [...this.state.messages].pop();
                let nextMessageId = (nextMessage && nextMessage.communicationId) || 0;
                this.setState(prev => ({modalPreviewLastPageResultSetSize: prev.messages.length}));
                this.updateModalClientMessageRead(nextMessageId);
            }
        );
    };

    onSelectInputTickingStatusChange = ({target: {value: ticked}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({ticked});

    onButtonActionOfModalConfirmNotReplyingColleagueClick = () => {
        if (
            this.state.isModalConfirmNotReplyingToColleagueOpen &&
            typeof this.state.isModalConfirmNotReplyingToColleagueOpen === 'object'
        ) {
            const rowObject = {...(this.state.isModalConfirmNotReplyingToColleagueOpen || {})};
            this.setState(
                {
                    isModalConfirmNotReplyingToColleagueOpen: false,
                },
                () => this.openModalReply(rowObject)
            );
        }
    };

    onCloseModalConfirmNotReplyingColleague = () => this.setState({isModalConfirmNotReplyingToColleagueOpen: false});

    onLinkButtonViewSearchTermOnlyClick = () =>
        this.setState(prevState => ({viewSearchTermOnly: !prevState.viewSearchTermOnly}));

    render() {
        const actions = CCClientInboxActionButtons(
            this.onTableIconReplyClick,
            this.onTableIconArchiveClick,
            this.onTableIconHistoryClick,
            this.onTableIconCheckClick
        );
        const addressedToWithCompany = addressedTo.map(element => ({
            id: element.id,
            value: element.value === 'Me' && this.state.companyName ? this.state.companyName : element.value,
        }));
        let selectedElementsHash: any = {};
        this.state.communicationIdsSelected.forEach((el: any) => (selectedElementsHash[el] = true));

        return (
            <>
                {this.state.isModalConfirmNotReplyingToColleagueOpen ? (
                    <ModalConfirmVersion2
                        title={`Replying CPVO`}
                        message={`You are not replying to your colleague, you are replying to CPVO. Are you sure you want to continue ?`}
                        buttonName={'Continue'}
                        action={this.onButtonActionOfModalConfirmNotReplyingColleagueClick}
                        close={this.onCloseModalConfirmNotReplyingColleague}
                    />
                ) : null}
                {this.state.modalClientMessageCompose && (
                    <CCModalClientCompose
                        close={this.closeModalClientMessageCompose}
                        intl={this.props.intl}
                        openModalCPVOLogs={this.openModalCPVOLogs}
                    />
                )}
                {this.state.modalClientMessageRead && (
                    <CCModalClientRead
                        close={this.closeModalClientMessageRead}
                        communicationId={this.state.modalClientMessageRead}
                        onButtonReplyClick={this.onModalClientMessageReadButtonReplyClick}
                        isPreviewMode={this.state.isModalPreviewOpen}
                        modalPreviewURL={this.state.modalPreviewURL}
                        setModalPreviewURL={this.setModalPreviewURL}
                        setModalPreviewMessage={this.setModalPreviewMessage}
                    />
                )}
                {this.state.modalClientMessageReply && (
                    <CCModalClientReply
                        close={this.closeModalClientMessageReply}
                        communicationId={this.state.modalClientMessageReply}
                        openModalCPVOLogs={this.openModalCPVOLogs}
                        isPreviewMode={this.state.isModalPreviewOpen}
                    />
                )}
                {this.state.isModalPreviewOpen ? (
                    <ModalPreview
                        modalPreviewURL={this.state.modalPreviewURL}
                        modalPreviewMessage={this.state.modalPreviewMessage}
                        modalReadCommunicationId={this.state.modalClientMessageRead}
                        tableMessages={this.state.messages}
                        count={this.state.count}
                        currentPageNumber={this.state.criteria.pageNumber}
                        currentPageSize={this.state.criteria.pageSize}
                        onButtonFirstClick={this.onModalPreviewButtonFirstClick}
                        onButtonPreviousClick={this.onModalPreviewButtonPreviousClick}
                        onButtonNextClick={this.onModalPreviewButtonNextClick}
                        onButtonLastClick={this.onModalPreviewButtonLastClick}
                        lastPageResultSetSize={this.state.modalPreviewLastPageResultSetSize}
                        hideNavButtons={!!this.state.modalClientMessageReply}
                    />
                ) : null}
                {this.state.isModalHistoryOpen ? (
                    <CCModalClientHistory
                        close={this.closeModalClientMessageHistory}
                        formatTableMessages={this.formatTableMessage}
                        correlationId={this.state.isModalHistoryOpen}
                        getTableFormatFunctions={this.getTableFormatFunctions}
                    />
                ) : null}
                {this.state.modalCPVOLogs && this.state.modalCPVOLogs.communicationId ? (
                    <ModalCommunicationCentreDocumentList
                        close={this.closeModalCPVOLogs}
                        communicationId={this.state.modalCPVOLogs.communicationId}
                        intl={this.props.intl}
                        subject={this.state.modalCPVOLogs.subject}
                        userMode={false}
                    />
                ) : null}
                {this.state.modalApplicationNumbers.length ? (
                    <ModalApplicationNumbers
                        applicationNumbers={this.state.modalApplicationNumbers}
                        close={this.closeModalApplicationNumbers}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Communication Centre`} />
                <NavigationCommunicationCentre
                    clearFields={this.onClearFieldsClick}
                    openModalMessageCompose={this.openModalClientMessageCompose}
                    search={this.search}
                    updateCriteriaValue={this.updateCriteriaValue}
                />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div>
                            <div>
                                <TextInput
                                    double={true}
                                    onChange={this.onTextInputSearchTermChange}
                                    onEnter={this.search}
                                    label={`Search term`}
                                    value={this.state.criteria.searchTerm}
                                />
                                <div style={{paddingTop: 17}}>
                                    <FormFooterLinkButton clickAction={this.onLinkButtonViewSearchTermOnlyClick}>{`${
                                        this.state.viewSearchTermOnly ? 'Show' : 'Hide'
                                    } filters`}</FormFooterLinkButton>
                                </div>
                            </div>
                            <div style={{clear: 'both'}} />
                            {!this.state.viewSearchTermOnly ? (
                                <>
                                    <SelectInput
                                        label={'Document type'}
                                        value={this.state.criteria.documentType}
                                        onChange={this.onSelectInputDocumentTypeChange}
                                        list={documentTypes}
                                        notAll={true}
                                        disabled={false}
                                    />
                                    <TextInput
                                        filter={'Starts with'}
                                        label={`Application number(s)`}
                                        onChange={this.onTextInputValueApplicationNumberChange}
                                        onEnter={this.search}
                                        placeholder={`e.g. 2015 or 20150001`}
                                        popOverText={`The search will find all documents whose number starts with the entered terms.`}
                                        value={this.state.criteria.applicationNumber}
                                    />
                                    <SelectInput
                                        label={`Reading Status`}
                                        value={this.state.criteria.readingStatus}
                                        onChange={this.onSelectInputValueReadingStatusChange}
                                        list={[
                                            {id: '1', value: 'Read'},
                                            {id: '2', value: 'not read'},
                                        ]}
                                    />
                                    <DateInput
                                        label={`Arrival date`}
                                        changeDateFrom={this.onDateInputValueArrivalDateFromChange}
                                        changeDateTo={this.onDateInputValueArrivalDateToChange}
                                        inputValueFrom={this.state.criteria.arrivalDateFrom}
                                        inputValueTo={this.state.criteria.arrivalDateTo}
                                        disabled={true}
                                    />
                                    <DateInput
                                        label={`Notification date`}
                                        changeDateFrom={this.onDateInputValueNotificationDateFromChange}
                                        changeDateTo={this.onDateInputValueNotificationDateToChange}
                                        inputValueFrom={this.state.criteria.notificationDateFrom}
                                        inputValueTo={this.state.criteria.notificationDateTo}
                                    />
                                    <SelectInput
                                        label={'Ticking status'}
                                        value={this.state.criteria.ticked}
                                        onChange={this.onSelectInputTickingStatusChange}
                                        list={SELECT_INPUT_LIST_TICKING_STATUS}
                                        notAll={true}
                                    />
                                    <SelectInput
                                        double={true}
                                        label={'Addressed to'}
                                        value={this.state.criteria.addressedTo}
                                        onChange={this.onSelectedAddressToChange}
                                        list={addressedToWithCompany}
                                        notAll={true}
                                    />
                                    <div style={{clear: 'both'}} />
                                    {/*<div style={{display: 'block', paddingLeft: 160}}>*/}
                                    {/*    <InputLink*/}
                                    {/*        label={`Advanced search${*/}
                                    {/*            this.state.advancedCriteriaCount !== 0*/}
                                    {/*                ? ` (${this.state.advancedCriteriaCount})`*/}
                                    {/*                : ''*/}
                                    {/*        } `}*/}
                                    {/*        icon={this.state.advancedOpened ? faChevronDown : faChevronRight}*/}
                                    {/*        clickAction={this.toggleAdvancedSearch}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    {/*{this.state.advancedOpened && (*/}
                                    {/*    <>*/}
                                    {/*        <Title*/}
                                    {/*            triple={true}*/}
                                    {/*            double={false}*/}
                                    {/*            full={false}*/}
                                    {/*            titleDescription={''}*/}
                                    {/*        >{`Other details`}</Title>*/}
                                    {/*        <TextLabelInput*/}
                                    {/*            double={true}*/}
                                    {/*            onSelectionChange={this.onSelectedClientsIdChange}*/}
                                    {/*            onChange={this.onClientNameChange}*/}
                                    {/*            value={this.state.clientName}*/}
                                    {/*            selectedElements={this.state.selectedClients}*/}
                                    {/*            delay={300}*/}
                                    {/*            multiple={true}*/}
                                    {/*            label={'Applicant(s)/Breeder(s)/Representative(s)'}*/}
                                    {/*            outsideLabel={''}*/}
                                    {/*            outsideLabelWidth={160}*/}
                                    {/*            listByIdName={this.state.clientListByIdName}*/}
                                    {/*            nameHash={this.state.clientNameHash}*/}
                                    {/*        />*/}
                                    {/*        <TextInput*/}
                                    {/*            filter={['equals', 'contains']}*/}
                                    {/*            currentFilter={this.state.criteria.denominationFilter}*/}
                                    {/*            onFilterChange={this.onDenominationFilterChange}*/}
                                    {/*            double={true}*/}
                                    {/*            onChange={this.onDenominationChange}*/}
                                    {/*            onEnter={this.search}*/}
                                    {/*            value={this.state.criteria.denomination}*/}
                                    {/*            popOverText={`The search will find all applications containing what is typed`}*/}
                                    {/*            outsideLabel={'Denomination'}*/}
                                    {/*            outsideLabelWidth={160}*/}
                                    {/*        />*/}
                                    {/*        <TextInput*/}
                                    {/*            filter={'Contains'}*/}
                                    {/*            double={true}*/}
                                    {/*            onChange={this.onBreederReferenceChange}*/}
                                    {/*            onEnter={this.search}*/}
                                    {/*            value={this.state.criteria.breedersReference}*/}
                                    {/*            popOverText={`The search will find all applications containing what is typed`}*/}
                                    {/*            outsideLabel={'Breeder’s reference'}*/}
                                    {/*            outsideLabelWidth={160}*/}
                                    {/*        >*/}
                                    {/*            {}*/}
                                    {/*        </TextInput>*/}
                                    {/*        <TextLabelInput*/}
                                    {/*            filter={['starts', 'contains']}*/}
                                    {/*            currentFilter={this.state.criteria.speciesNameFilter}*/}
                                    {/*            onFilterChange={this.onSpeciesNameFilterChange}*/}
                                    {/*            double={true}*/}
                                    {/*            onSelectionChange={this.onSpeciesSelectionChange}*/}
                                    {/*            onChange={this.onSpeciesNameChange}*/}
                                    {/*            onEnter={this.search}*/}
                                    {/*            value={this.state.criteria.speciesName}*/}
                                    {/*            selectedElements={this.state.selectedSpecies}*/}
                                    {/*            delay={300}*/}
                                    {/*            multiple={true}*/}
                                    {/*            outsideLabel={'Species'}*/}
                                    {/*            outsideLabelWidth={160}*/}
                                    {/*            listByIdName={this.state.speciesListByIdName}*/}
                                    {/*            nameHash={this.state.speciesNameHash}*/}
                                    {/*        />*/}
                                    {/*    </>*/}
                                    {/*)}*/}
                                </>
                            ) : null}
                        </div>
                        <FormFooter
                            left={<></>}
                            right={
                                <>
                                    <Button
                                        variation={'secondary'}
                                        clickAction={this.onClearFieldsClick}
                                    >{`Clear fields`}</Button>
                                    <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                                </>
                            }
                        />
                        <div style={{clear: 'both'}} />
                    </FormWrapper>
                    <div style={{marginTop: 20, marginBottom: 20}}>
                        {this.state.screenLoaded ? (
                            <>
                                {(this.state.messages || []).length ? (
                                    <>
                                        <div
                                            style={{
                                                float: 'left',
                                                width: 'calc(100% - 15px)',
                                                marginBottom: 15,
                                                paddingRight: 15,
                                            }}
                                        >
                                            <FormFooterButton
                                                color={buttonColor.blue}
                                                clickAction={this.onButtonMarkAllAsReadClick}
                                                icon={faEnvelopeOpen}
                                            >{`Mark all as read`}</FormFooterButton>
                                            <FormFooterButton
                                                color={buttonColor.blue}
                                                clickAction={this.onButtonMarkSelectedAsReadClick}
                                                disabled={!(this.state.communicationIdsSelected || []).length}
                                                icon={faEnvelopeOpen}
                                            >{`Mark selected as read`}</FormFooterButton>
                                            <FormFooterButton
                                                color={buttonColor.blue}
                                                clickAction={this.onButtonArchiveSelectedClick}
                                                disabled={!(this.state.communicationIdsSelected || []).length}
                                                icon={faArchive}
                                            >{`Archive selected`}</FormFooterButton>
                                        </div>
                                        <div style={{clear: 'both'}} />
                                    </>
                                ) : null}
                                <CustomTable
                                    actions={actions}
                                    bold={(rowObject: ICommunicationMessageClient) => !rowObject.isRead}
                                    count={this.state.count}
                                    dataFilter={null}
                                    defaultOrder={this.state.criteria.order}
                                    filterFunctions={null}
                                    formatFunctions={this.getTableFormatFunctions()}
                                    forehandColumn={({urgent, numberOfAttachments}: any) => {
                                        return (
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                {urgent ? (
                                                    <FontAwesomeIcon
                                                        icon={faExclamation as any}
                                                        fontSize={20}
                                                        title={'Urgent'}
                                                        color={'#810202'}
                                                        style={{marginLeft: 2, marginRight: 2}}
                                                    />
                                                ) : null}
                                                {numberOfAttachments > 1 ? (
                                                    <FontAwesomeIcon
                                                        icon={faPaperclip as any}
                                                        fontSize={20}
                                                        title={'This message includes attachments'}
                                                        color={'grey'}
                                                        style={{marginLeft: 2, marginRight: 8}}
                                                    />
                                                ) : null}
                                            </div>
                                        );
                                    }}
                                    hideExcelButton={true}
                                    id={'communicationId'}
                                    intl={this.props.intl}
                                    notSortable={RESULT_FIELDS_DEFAULT_INBOX}
                                    onSelectedChange={this.onSelectedChange}
                                    pageNumber={this.state.criteria.pageNumber}
                                    pageSize={this.state.criteria.pageSize}
                                    pagination={true}
                                    resultFieldsDefault={RESULT_FIELDS_DEFAULT_INBOX}
                                    resultFieldsAll={RESULT_FIELDS_ALL_INBOX}
                                    reverseOrder={this.state.criteria.reverse}
                                    rowBorderLeft={true}
                                    rowClick={this.onTableRowClick}
                                    selectable={true}
                                    selectDefault={selectedElementsHash}
                                    timestampSelected={this.state.timestamp}
                                    setLastCursor={null}
                                    timestamp={this.state.timestamp}
                                    tableName={'communicationCentreClientInbox'}
                                    tableType={'OBJECT'}
                                    tableSource={this.formatTableMessage(this.state.messages)}
                                    updateCriteriaValue={this.updateCriteriaValue}
                                    version={2}
                                />
                            </>
                        ) : null}
                    </div>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(CCClientInbox));
