import React, {Component} from 'react';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import Checkbox from '~components/Checkbox';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSort} from '@fortawesome/free-solid-svg-icons';
import styles from './SortableConfigureSearchFields.module.scss';

const DragHandle = SortableHandle(() => (
    <span
        style={{
            display: 'inline-block',
            width: 40,
            textAlign: 'center',
            marginTop: 5,
            verticalAlign: 'top',
        }}
    >
        <FontAwesomeIcon icon={faSort} style={{display: 'inline-block'}} />
    </span>
));

const SortableItem = SortableElement(({intl, value, items, onCheckboxClick}) => {
    const isCheckboxChecked = (items.find(({name}) => name === value.name) || {}).enabled;

    const disabled = ['denomination'].includes(value.name);
    const alwaysCheckedField = ['applicationnumber'].includes(value.name);

    let wrapperStyle = {};
    disabled && (wrapperStyle = {pointerEvents: 'none', opacity: 0.4});
    alwaysCheckedField && (wrapperStyle = {opacity: 0.4});

    return (
        <div key={value.name} style={wrapperStyle}>
            <Checkbox
                clickAction={() => (alwaysCheckedField ? null : onCheckboxClick(value.name))}
                simple={true}
                label={` `}
                value={isCheckboxChecked}
                width={30}
                style={{marginTop: 0}}
            />
            <span
                style={{
                    marginTop: 5,
                    display: 'inline-block',
                    width: 200,
                }}
            >
                {intl.formatMessage({id: `table.varietyFinderSearchVarieties.${value.name}`})}
            </span>
            <DragHandle />
            <div style={{clear: 'both'}} />
        </div>
    );
});

const SortableList = SortableContainer(({intl, items, onCheckboxClick}) => {
    return (
        <div>
            {items.map((value, index) => (
                <SortableItem
                    key={`item-${value.name}`}
                    index={index}
                    intl={intl}
                    value={value}
                    items={items}
                    onCheckboxClick={onCheckboxClick}
                />
            ))}
        </div>
    );
});

class SortableConfigureTableColumns extends Component {
    render() {
        return (
            <SortableList
                intl={this.props.intl}
                items={this.props.orderedColumns}
                onCheckboxClick={this.props.onCheckboxClick}
                onSortEnd={this.props.onSortEnd}
                lockAxis={'y'}
                useDragHandle
                helperClass={styles.sortableHelper}
            />
        );
    }
}

export default SortableConfigureTableColumns;
