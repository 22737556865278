import React, {useState} from 'react';
import styles from './Navigation.module.scss';
import {NavLink} from 'react-router-dom';
import {Home as IconHome, MenuBars as IconMenuBars} from '../iconComponents';
import cx from 'classnames';
import {getDecodedJWT} from '../utils';

export interface ILink {
    Component?: any;
    disabled?: boolean;
    onClick?: any;
    style?: any;
    to?: string;
    title?: string;
}

interface ILinkModal {
    onClick: any;
    title: string;
}

interface INavigationProps {
    activeTitle?: string;
    hideLinkUserArea?: boolean;
    links?: ILink[];
    linkModals?: ILinkModal[];
    linksRight?: ILink[];
    showLinkHomeCPVO?: boolean;
}

function Navigation({
    activeTitle,
    hideLinkUserArea,
    links,
    linkModals,
    linksRight,
    showLinkHomeCPVO,
}: INavigationProps) {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const RenderNavLink = ({Component, to, disabled, title, onClick, style}: ILink) => {
        if (Component) {
            return (
                <div className={styles.link}>
                    <Component />
                </div>
            );
        }

        if (onClick) {
            return (
                <button type={`button`} onClick={onClick} className={cx(styles.link)} style={style}>
                    {title}
                </button>
            );
        }

        const onNavLinkClick = (e: any) => {
            if (disabled) return e.preventDefault();
        };

        return (
            <NavLink
                to={to || '/'}
                activeClassName={styles.active}
                className={cx(
                    styles.link,
                    disabled ? styles.disabled : undefined,
                    activeTitle && activeTitle === title ? styles.activeTitle : null
                )}
                aria-disabled={disabled}
                onClick={onNavLinkClick}
                style={style}
            >
                {title}
            </NavLink>
        );
    };

    const loggedInUser = getDecodedJWT();

    if (!loggedInUser) {
        return null;
    }

    return (
        <>
            <nav className={styles.nav}>
                <div className={styles.navLeft}>
                    {showLinkHomeCPVO ? (
                        <a className={cx(styles.link, styles.homeLink)} href="https://cpvo.europa.eu/en">{`Home`}</a>
                    ) : null}
                    {!hideLinkUserArea ? (
                        <NavLink
                            exact={true}
                            to={'/welcome'}
                            className={cx(styles.link, styles.homeLink)}
                            activeClassName={styles.active}
                        >
                            <IconHome style={{marginRight: 8}} />
                            <span>{`User Area`}</span>
                        </NavLink>
                    ) : null}
                    {(links || []).map(link => (
                        <React.Fragment key={link.title}>
                            <RenderNavLink {...link} />
                        </React.Fragment>
                    ))}
                    {(linkModals || []).map(({onClick, title}) => (
                        <div key={title} className={cx(styles.link)} onClick={onClick} style={{cursor: 'pointer'}}>
                            {title}
                        </div>
                    ))}
                </div>
                {linksRight ? (
                    <div className={styles.navRight}>
                        {linksRight.map(link => (
                            <React.Fragment key={link.title}>
                                <RenderNavLink {...link} />
                            </React.Fragment>
                        ))}
                    </div>
                ) : null}
            </nav>
            <nav
                className={styles.navMobile}
                style={{boxShadow: isMobileMenuOpen ? 'none' : 'inset 0px -1px 3px #959595'}}
            >
                <button
                    type={'button'}
                    className={styles.mobileButton}
                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                >
                    {`Menu`}
                    <IconMenuBars style={{marginLeft: 8}} />
                </button>
                {isMobileMenuOpen ? (
                    <div className={styles.mobileLinks}>
                        {!hideLinkUserArea ? <RenderNavLink to={'/'} title={'User Area'} /> : null}
                        {showLinkHomeCPVO ? (
                            <a
                                className={cx(styles.link, styles.homeLink)}
                                href="https://cpvo.europa.eu/en"
                            >{`Home`}</a>
                        ) : null}
                        {(links || []).map(link => (
                            <React.Fragment key={link.title}>
                                <RenderNavLink {...link} />
                            </React.Fragment>
                        ))}
                        {(linkModals || []).map(({onClick, title}) => (
                            <div key={title} className={cx(styles.link)} onClick={onClick} style={{cursor: 'pointer'}}>
                                {title}
                            </div>
                        ))}
                        {(linksRight || []).map(link => (
                            <React.Fragment key={link.title}>
                                <RenderNavLink {...link} />
                            </React.Fragment>
                        ))}
                    </div>
                ) : null}
            </nav>
        </>
    );
}

export default Navigation;
