import {API_SERVERLESS, getFetch, postFetch} from '../../../../commonApi';
import {CaseMetaDataFetchData} from '../../CommonInterfaces/CommonInterfaces';
import {computeGetUploadPreSignedURLPromises, postFileToPreSignedURLRequest} from '../../../../utils/requests';

export interface CaseFormPageUrlParamsData {
    caseId: string;

    [key: string]: string | number;
}

export interface KeywordElement {
    keywordId: number;
    keyword: string;
}

export interface KeywordsListFetchData {
    data: {
        keywords: KeywordElement[];
        token: string;
    };
}

export interface LinkedCasesElement {
    caseId: number;
    caseName: string;
}

export interface LinkedCasesListFetchData {
    data: {
        linkedCases: LinkedCasesElement[];
        token: string;
    };
}

export function apiGetCaseFetch(caseId: number): Promise<CaseMetaDataFetchData> {
    return getFetch(`${API_SERVERLESS}/caselaw/getcase?caseId=${caseId}`, true);
}

export function apiCaseSave(jsonData: {
    countryId: string;
    decisionLanguageId: string;
    parties: string;
    decidingBody: string;
    decisionDate: string;
    decisionNumber: string;
    caseName: string;
    isOnline: boolean;
    abstractInput: string;
    publicationDate: string;
    publicationNumber: string;
    keywords: number[];
    summaryFile: string;
    decisionFile: string;
    decisionTranslationLanguageId: string;
    decisionTranslationFile: string;
}): Promise<{message: string}> {
    return postFetch(`${API_SERVERLESS}/caselaw/createcase`, true, jsonData).then(jsonResponse => {
        if (jsonResponse) {
            return jsonResponse;
        }
    });
}

export function apiCaseEdit(jsonData: {
    caseId: number;
    countryId: string;
    decisionLanguageId: string;
    parties: string;
    decidingBody: string;
    decisionDate: string;
    decisionNumber: string;
    caseName: string;
    isOnline: boolean;
    abstractInput: string;
    publicationDate: string;
    publicationNumber: string;
    keywords: number[];
    summaryFile: string;
    decisionFile: string;
    decisionTranslationLanguageId: string;
    decisionTranslationFile: string;
}): Promise<{message: string}> {
    return postFetch(`${API_SERVERLESS}/caselaw/editcase`, true, jsonData).then(jsonResponse => {
        if (jsonResponse) {
            return jsonResponse;
        }
    });
}

export function apiUploadCaseFile(jsonData: {
    fileName: string;
    caseId?: number;
    isSummary?: boolean;
    file?: Blob;
    translationLanguageId?: string;
}): Promise<{fileUUID: string}> {
    return computeGetUploadPreSignedURLPromises({}).then(
        ({presignedUrl, identifier}: {presignedUrl: string; identifier: string}) => {
            return postFileToPreSignedURLRequest({preSignedURL: presignedUrl, file: jsonData.file}).then(() => {
                const returnData = jsonData.caseId
                    ? {
                          fileName: jsonData.fileName,
                          fileIdentifier: identifier,
                          caseId: jsonData.caseId,
                          isSummary: jsonData.isSummary,
                          translationLanguageId: jsonData.translationLanguageId,
                      }
                    : {
                          fileName: jsonData.fileName,
                          fileIdentifier: identifier,
                          isSummary: jsonData.isSummary,
                          translationLanguageId: jsonData.translationLanguageId,
                      };
                return postFetch(`${API_SERVERLESS}/caselaw/uploadfile`, true, returnData);
            });
        }
    );
}

export function apiCaseFileDelete(
    fileUUID: string,
    caseId: number,
    isSummary: boolean,
    isTranslation: boolean
): Promise<{}> {
    return getFetch(
        `${API_SERVERLESS}/caselaw/deletefile?uuid=${fileUUID}&caseId=${caseId}&isSummary=${isSummary}&isTranslation=${isTranslation}`,
        true,
        {}
    );
}

export function apiKeywordsListFetch(): Promise<KeywordsListFetchData> {
    return getFetch(`${API_SERVERLESS}/caselaw/keywords`, true);
}

export function apiKeywordSave(jsonData: {keyword: string}): Promise<{message: string}> {
    return postFetch(`${API_SERVERLESS}/caselaw/createkeyword`, true, jsonData).then(jsonResponse => {
        if (jsonResponse) {
            return jsonResponse;
        }
    });
}

export function apiKeywordEdit(jsonData: {keywordId: number; keyword: string}): Promise<{message: string}> {
    return postFetch(`${API_SERVERLESS}/caselaw/editkeyword`, true, jsonData).then(jsonResponse => {
        if (jsonResponse) {
            return jsonResponse;
        }
    });
}

export function apiKeywordDelete(keywordId?: number): Promise<{message: string}> {
    return postFetch(`${API_SERVERLESS}/caselaw/deletekeyword`, true, {keywordId: keywordId});
}

export function apiCasesLinkedWithKeywordsListFetch(keywordId: number): Promise<LinkedCasesListFetchData> {
    return getFetch(`${API_SERVERLESS}/caselaw/caseslinkedwithkeyword?keywordId=${keywordId}`, true);
}
