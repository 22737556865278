import React from 'react';
import cx from 'classnames';
import styles from './FormFooterButton.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const buttonColor = {
    green: styles.green,
    blue: styles.blue,
    gray: styles.gray,
    orange: styles.orange,
    whiteBlue: styles.whiteBlue,
    whiteGray: styles.whiteGray,
    whiteGreen: styles.whiteGreen,
    whiteBlack: styles.whiteBlack,
    red: styles.red,
    lightGreen: styles.lightGreen,
};
export default function ({
    children,
    color,
    clickAction,
    icon,
    width,
    height,
    disabled,
    float,
    extraStyles,
    style: userStyle,
}) {
    return (
        <div
            style={Object.assign(
                {},
                width && {width, minWidth: 'inherit'},
                height && {height, minHeight: 'inherit'},
                float && {float},
                userStyle && {...userStyle}
            )}
            onClick={!disabled ? clickAction : undefined}
            className={cx(styles.button, color, disabled && styles.disabled, extraStyles)}
        >
            <div className={styles.buttonContainer}>
                <div className={styles.buttonIcon}>{icon && <FontAwesomeIcon icon={icon} />}</div>
                <div className={styles.buttonLabel}>{children}</div>
                <div style={{clear: 'both'}}>{}</div>
            </div>
        </div>
    );
}
