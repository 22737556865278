import React from 'react';
import {LOGIC_TYPES} from '../modals/ResponseModalDetailsLogics';
import {MOCK} from '../../BackOfficeFormQuestions/BackOfficeFormQuestions';
import RemarkMessage from './RemarkMessage';
import WizardContextualMenu from '../common/WizardContextualMenu';
import WizardQuestionWrapper, {IWizardQuestionWrapperProps, IWizardQuestionWrapperState} from './WizardQuestionWrapper';
import {checkIfQuestionHasForceResponseError} from './utils';
import cx from 'classnames';
import {
    getResponseComponent,
    renderMandatoryError,
    renderNumberOfResponsesAllowedError,
    renderSuccessiveScoreOnlyError,
    renderForceResponseError,
    renderUploadTypeMustBeDefinedError,
} from './utils';
import styles from './WizardQuestion.module.scss';
import {injectIntl} from 'react-intl';

interface IState extends IWizardQuestionWrapperState {
    hidden: boolean;
}

class WizardQuestion extends WizardQuestionWrapper<IWizardQuestionWrapperProps, IState> {
    constructor(props: IWizardQuestionWrapperProps) {
        super(props);
        this.state = {
            ...this.state,
            hidden: false,
        };
    }

    componentDidMount() {
        this.setState(this.checkIfQuestionIsHidden(), () =>
            this.removeResponsesFromHiddenQuestionIfExist(this.state.hidden)
        );
        this.props.setWizardQuestionCallables &&
            this.props.setWizardQuestionCallables({
                showModalResponseDetails: this.showModalResponseDetails,
            });
        if (
            (this.props.mock === MOCK.EDIT_RESPONSE || Object.values(LOGIC_TYPES).indexOf(this.props.mock) !== -1) &&
            !(self as any).singletonModal
        ) {
            (self as any).singletonModal = true;
            this.showModalResponseDetails(11719);
        }
    }

    componentDidUpdate(prevProps: IWizardQuestionWrapperProps, prevState: IWizardQuestionWrapperState) {
        if (this.props.screenData.hash !== prevProps.screenData.hash) {
            this.setState(this.checkIfQuestionIsHidden(), () =>
                this.removeResponsesFromHiddenQuestionIfExist(this.state.hidden)
            );
        }
    }

    render() {
        const questionKeyWithIteration = this.getQuestionKeyWithIteration();
        const hasClickedOnSaveAndValidate = this.checkIfUserHasClickedOnSaveAndValidate();
        const showMandatoryError = this.checkIfShouldShowMandatoryError(this.state.hidden);
        const showQuestionUploadTypeMustBeDefinedError =
            hasClickedOnSaveAndValidate && this.checkIfQuestionHasUploadTypeMustBeDefinedError();
        const forceResponseError =
            hasClickedOnSaveAndValidate &&
            checkIfQuestionHasForceResponseError({
                steps: this.props.steps,
                destinationQuestion: this.props.question,
                responsesClient: this.props.screenData.responsesClient,
                questionKeyWithIteration: this.getQuestionKeyWithIteration(),
            });
        const successiveScoreOnlyError = this.checkIfShouldShowSuccessiveScoreOnlyError(showMandatoryError);
        const numberOfResponsesAllowedError = this.checkIfShouldShowNumberOfResponsesAllowedError(showMandatoryError);
        const hasError =
            showMandatoryError || forceResponseError || successiveScoreOnlyError || numberOfResponsesAllowedError;
        const {hidden, hiddenDueToQuestionResponseLines} = this.state;

        if (this.pdfConfidentialHideQuestion()) {
            return <></>;
        }

        return (
            <>
                {super.render()}
                <div id={questionKeyWithIteration}>
                    <div className={cx(styles.titleSection, this.state.hidden && styles.sectionHidden)}>
                        {this.renderQuestionLabel({
                            hidden,
                            hiddenDueToQuestionResponseLines,
                            hasError,
                        })}
                        {this.renderQuestionHelpIcon()}
                    </div>
                    {showMandatoryError && renderMandatoryError({intl: this.props.intl})}
                    {forceResponseError && renderForceResponseError(forceResponseError)}
                    {successiveScoreOnlyError && renderSuccessiveScoreOnlyError({intl: this.props.intl})}
                    {numberOfResponsesAllowedError &&
                        renderNumberOfResponsesAllowedError({intl: this.props.intl, numberOfResponsesAllowedError})}
                    {showQuestionUploadTypeMustBeDefinedError &&
                        renderUploadTypeMustBeDefinedError({intl: this.props.intl})}
                    {!this.state.hidden ? (
                        <div className={styles.section}>
                            <RemarkMessage
                                remarkMessage={this.props.question && this.props.question.messageremark}
                                isRemarkTranslationMissing={this.checkIfQuestionRemarkTranslationIsMissing()}
                            />
                            {this.pdfConfidentialHideResponsesOnly() ? (
                                <div style={{marginLeft: 20}}>Confidential information</div>
                            ) : (
                                ((this.props.question && this.props.question.responses) || []).map(response => {
                                    const params = this.getResponseComponentProps(response);
                                    let responseComponent = getResponseComponent({
                                        question: this.props.question,
                                        response,
                                        params,
                                    });

                                    return (
                                        <div
                                            className={styles.responseWrap}
                                            key={`${questionKeyWithIteration}_${response.id}`}
                                        >
                                            <WizardContextualMenu
                                                editable={this.props.editable}
                                                menuType="response"
                                                labelPopup={this.getResponseLabelPopup(response.id, response)}
                                            />
                                            <div
                                                title={this.props.editable ? `${response.id}` : undefined}
                                                className={styles.responseComponentWrap}
                                            >
                                                {responseComponent}
                                                {this.renderResponseIsDisabledTargetPopover(response)}
                                                {this.renderResponseLogicsPopover(response)}
                                            </div>
                                            <div style={{clear: 'both'}} />
                                        </div>
                                    );
                                })
                            )}
                            {this.renderClearRadioResponse()}
                            {this.renderConfidentialQuestion()}
                        </div>
                    ) : null}
                </div>
            </>
        );
    }
}

export default injectIntl(WizardQuestion);
