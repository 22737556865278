import {API_SERVERLESS, getFetch, postFetch} from '../../../../commonApi';
import AUTOCOMPLETE_SPECIES from './DATA/AUTOCOMPLETE.json';

import {TqSearchCandidateVarietiesTableRowData} from '../TqSearchCandidateVarietiesPage/TqSearchCandidateVarietiesPageService';

interface SpeciesNameRowData {
    lib: string;
    id: string;
}

export interface TqSpeciesNamesFetchData {
    data: {
        speciesNames: Array<SpeciesNameRowData>;
    };
}

export interface TqSearchTableRowData {
    questionnaireId: number;
    countryId: string;
    countryName: string;
    speciesId: string;
    speciesName: string;
    registerId: string;
    denomination: string | null;
    applicationDate: string;
    reference: string;
    applicantName: string;
    applicationStatus: string;
    type: string;
    officialName: string;
    onlineId: string;
    email: string;
}

export interface TqSearchParamsData {
    data: {
        tqSearchSpecies: Array<TqSearchTableRowData>;
        count: number;
    };
}

export interface TqSearchTableCriteriaData {
    pageNumber: number;
    pageSize: number;
    refresh: boolean;
    excel: boolean;
    order: string;
    reverse: boolean;
}

export interface TqSearchFilterCriteriaData extends TqSearchTableCriteriaData {
    speciesName: string;
    speciesId: string;
    denomination: string;
    denominationFilter: string;
    reference: string;
    applicantName: string;
    email: string;
    applicationDateFrom: string;
    applicationDateTo: string;
    responseIds?: string;

    [key: string]: string | number | boolean | undefined;
}

interface TqSearchExcelTableRowData extends TqSearchTableRowData, TqSearchCandidateVarietiesTableRowData {
    arrivalDate: string;
}

export interface TqSearchExcelParamsData {
    data: {
        tqSearchExcel: Array<TqSearchExcelTableRowData>;
        token: string;
    };
    count: number;
}

export interface TqSearchQuestionnaireIdsData {
    data: {
        questionnaireIds: Array<{questionnaireId: number}>;
    };
}

export interface TqInfoData {
    versionCode: string;
    nameCommon: string;
    name: string;
    questionnaireId: number;
}

export interface TqSearchQuestionnairesData {
    data: {
        questionnaires: Array<TqInfoData>;
    };
}

export interface TqQuestionInfoData {
    questionId: number;
    questionName: string;
    responseId: number;
    responseName: string;
    score: number;
    groupOrder: string;
    questionOrder: string;

    [key: string]: number | string;
}

export interface TqSearchQuestionsData {
    data: {
        questions: Array<TqQuestionInfoData>;
    };
}

// eslint-disable-next-line no-undef
function urlParamsDateValuesReplacements(params: Partial<TqSearchFilterCriteriaData>) {
    let splittedList: Array<string> = [];
    if (params.applicationDateFrom) {
        splittedList = params.applicationDateFrom.split('/');
        params.applicationDateFrom = splittedList[2] + splittedList[1] + splittedList[0];
    }
    if (params.applicationDateTo) {
        splittedList = params.applicationDateTo.split('/');
        params.applicationDateTo = splittedList[2] + splittedList[1] + splittedList[0];
    }
    return params;
}

export function apiTqSearchSpeciesSearch(
    // eslint-disable-next-line no-undef
    criteria: Partial<TqSearchFilterCriteriaData>
): Promise<TqSearchParamsData> {
    const params = urlParamsDateValuesReplacements({...criteria});
    if (params.speciesNameFilter === 'equals') params.speciesNameFilter = 'exact';
    if (params.denominationFilter === 'equals') params.denominationFilter = 'exact';
    return postFetch(`${API_SERVERLESS}/tlo/applications/tqsearch`, true, params);
}

export function apiTqSpeciesAutocompletionFetch(): Promise<TqSpeciesNamesFetchData> {
    // return postFetch(`${API_SERVERLESS}/tlo/applications/tqsearch/species/autocomplete`, true, {
    //     speciesName: '',
    // });
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve(AUTOCOMPLETE_SPECIES);
        }, 800);
    });
}

export function apiTqSearchExcel(
    // eslint-disable-next-line no-undef
    criteria: Partial<TqSearchFilterCriteriaData>,
    excelTranslations: {[key: string]: string} = {}
): Promise<TqSearchExcelParamsData> {
    const params = urlParamsDateValuesReplacements({...criteria});
    if (params.speciesNameFilter === 'equals') params.speciesNameFilter = 'exact';
    if (params.denominationFilter === 'equals') params.denominationFilter = 'exact';

    return postFetch(
        `${API_SERVERLESS}/tlo/applications/tqsearch?excel=true`,
        true,
        Object.assign({}, params, {columnNames: excelTranslations})
    );
}

export function apiTqSearchQuestionnaireIdsFetch(speciesName: string): Promise<TqSearchQuestionnaireIdsData> {
    // TODO review: for the feature multiple species selection
    // TODO: How to get multiple questionnaireIds if there is only one speciesName as input
    return postFetch(`${API_SERVERLESS}/tlo/applications/tqsearch/questionnaireIds`, true, {
        speciesName,
    });
}

export function apiTqSearchQuestionnairesFetch(questionnaireIds: Array<number>): Promise<TqSearchQuestionnairesData> {
    return getFetch(
        `${API_SERVERLESS}/tlo/applications/tqsearch/questionnaires?questionnaireIds=${questionnaireIds}`,
        true,
        {}
    );
}

export function apiTqSearchQuestionsFetch(questionnaireId: number, confidential = '0'): Promise<TqSearchQuestionsData> {
    return getFetch(
        `${API_SERVERLESS}/tlo/applications/tqsearch/questions?questionnaireId=${questionnaireId}&confidential=${confidential}`,
        true,
        {}
    );
}
