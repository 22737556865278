import React from 'react';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import CustomTable from '~components/CustomTable';
import DateInput from '~components/DateInput';
import {FORMAT_DATE_TIME_EASY, reformatDateEasy} from '~components/FormatFunctions';
import {getDecodedJWT, loadUrlParams} from '~utils';
import CCModalClientCompose from '../CCModalClientCompose/CCModalClientCompose';
import CCModalClientDraft from '../CCModalClientDraft/CCModalClientDraft';
import CCModalClientHistory from '../CCModalClientHistory/CCModalClientHistory';
import CCModalClientRead from '../CCModalClientRead/CCModalClientRead';
import CCModalClientReply from '../CCModalClientReply/CCModalClientReply';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import CCClientDraftActionButtons from './CCClientDraftActionButtons';
import {apiCommunicationCentreClientDraftDelete, apiCommunicationCentreClientSearchDraft} from './CCClientDraftService';
import ApplicationNumbersMultiple from '~components/ApplicationNumbersMultiple';
import {
    ICommunicationMessageClient,
    ICommunicationMessageClientHistory,
    ICommunicationMessagesClientSearchResults,
    IDecodedJWT,
} from '../../types';
import _COMMUNICATION_KEYS_TO_TYPE from '../CommunicationCentreClient/DATA/COMMUNICATION_KEYS_TO_TYPES.json';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
} from '../../componentsLayout';
import NavigationCommunicationCentre from '../../shared/NavigationCommunicationCentre';
import {Button} from '../../componentsFormV2';
import {ModalApplicationNumbers, ModalCommunicationCentreDocumentList, ModalConfirmVersion2} from '../../commonModals';
import MainWrapper from '../../componentsLayout/MainWrapper';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamation} from '@fortawesome/free-solid-svg-icons';
import {trackPageView} from '../../utils';

export const RESULT_FIELDS_DEFAULT_DRAFTS = [
    'from',
    'documentType',
    'messageSubject',
    'applicationNumbers',
    'to',
    'lastUpdateDate',
];

const DATEFORMAT_CRITERIA_KEYS = ['arrivalDateFrom', 'arrivalDateTo', 'notificationDateFrom', 'notificationDateTo'];

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

type TKeyOfDefaultCriteria = keyof TDefaultCriteria;

const DEFAULT_CRITERIA = {
    pageNumber: 1,
    pageSize: 10,
    order: 'sentDate',
    reverse: true,
    refresh: false,
    excel: false,
    searchTerm: '',
    applicationNumber: '',
    arrivalDateFrom: '',
    arrivalDateTo: '',
    notificationDateFrom: '',
    notificationDateTo: '',
    readingStatus: 'All',
    documentType: 'All',
    sendingDateFrom: '',
    sendingDateTo: '',
};

const documentTypes = [
    {id: 'All', value: 'All'},
    {id: 'Acknowledgements of receipt', value: 'Acknowledgements of receipt'},
    {id: 'Application - Other matters', value: 'Application - Other matters'},
    {id: 'Application documents', value: 'Application documents'},
    {id: 'Deed of assignment', value: 'Deed of assignment'},
    {id: 'Denomination - Other documents', value: 'Denomination - Other documents'},
    {id: 'Denomination Proposal', value: 'Denomination Proposal'},
    {id: 'Denomination objection/observations', value: 'Denomination objection/observations'},
    {id: 'Designation of procedural representative', value: 'Designation of procedural representative'},
    {id: 'Examination procedure', value: 'Examination procedure'},
    {id: 'Financial information', value: 'Financial information'},
    {id: 'Legal proceedings', value: 'Legal proceedings'},
    {id: 'Legal proceedings to be identified', value: 'Legal proceedings to be identified'},
    {id: 'Miscellaneous - Other matters', value: 'Miscellaneous - Other matters'},
    {id: 'Novelty', value: 'Novelty'},
    {id: 'Office decisions', value: 'Office decisions'},
    {id: 'Photo submitted by the applicant', value: 'Photo submitted by the applicant'},
    {id: 'Priority', value: 'Priority'},
    {id: 'Recordals in the register', value: 'Recordals in the register'},
    {id: 'Reports', value: 'Reports'},
    {id: 'Request for plant material', value: 'Request for plant material'},
    {id: 'Title of protection', value: 'Title of protection'},
    {id: 'Withdrawal/Surrender documents', value: 'Withdrawal/Surrender documents'},
];

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    count: number;
    criteria: TDefaultCriteria;
    isModalDraftDeleteOpen: number | false;
    isModalHistoryOpen: number | false;
    loading: number;
    messages: ICommunicationMessageClient[];
    modalApplicationNumbers: string[];
    modalClientMessageCompose: boolean;
    modalClientMessageDraft: number | false;
    modalClientMessageRead: number | false;
    modalClientMessageReply: number | false;
    modalCPVOLogs: {
        communicationId: number | null;
        subject: string;
    };
    screenLoaded: boolean;
    timestamp: number;
}

class CommunicationCentre extends React.Component<IProps, IState> {
    decodedJWT: IDecodedJWT = getDecodedJWT();

    constructor(props: IProps) {
        super(props);
        this.state = {
            count: 0,
            criteria: Object.assign({}, DEFAULT_CRITERIA),
            isModalDraftDeleteOpen: false,
            isModalHistoryOpen: false,
            loading: 0,
            messages: [],
            modalApplicationNumbers: [],
            modalClientMessageCompose: false,
            modalClientMessageDraft: false,
            modalClientMessageRead: false,
            modalClientMessageReply: false,
            modalCPVOLogs: {
                communicationId: null,
                subject: '',
            },
            screenLoaded: false,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'CommunicationCentreClientDraft'});
        const criteria = loadUrlParams(DATEFORMAT_CRITERIA_KEYS);
        this.setState({criteria, timestamp: Date.now()}, () =>
            this.search(false, false, () => this.setState({screenLoaded: true}))
        );
    }

    goToScreen = (screen: string): void =>
        this.setState({criteria: {...DEFAULT_CRITERIA}}, () => {
            this.props.history.push(screen);
        });

    parseCriteria = (criteria: TDefaultCriteria): TDefaultCriteria => {
        const parsedCriteria: any = {};
        Object.keys(criteria).map(key => {
            let criteriaValue = criteria[key as TKeyOfDefaultCriteria];
            if (DATEFORMAT_CRITERIA_KEYS.indexOf(key) !== -1 && criteriaValue !== '') {
                parsedCriteria[key] = reformatDateEasy(criteriaValue);
            } else if (key === 'documentType') {
                parsedCriteria[key] = encodeURIComponent(criteriaValue);
            } else {
                parsedCriteria[key] = criteriaValue;
            }
        });
        return parsedCriteria as TDefaultCriteria;
    };

    buildParams = (parsedCriteria: TDefaultCriteria): void => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(
                i =>
                    parsedCriteria[i as TKeyOfDefaultCriteria] !== DEFAULT_CRITERIA[i as TKeyOfDefaultCriteria] &&
                    i !== 'refresh'
            )
            .map(key => `${key}=${parsedCriteria[key as TKeyOfDefaultCriteria]}`);
        this.props.history.replace(
            `/communicationCentreClientDraft${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`
        );
    };

    search = (refresh?: boolean, urlLoad?: boolean, callBack?: () => any): void => {
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams(parsedCriteria);
                apiCommunicationCentreClientSearchDraft(parsedCriteria, DEFAULT_CRITERIA)
                    .then((jsonResponse: ICommunicationMessagesClientSearchResults) => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.messages) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        messages: jsonResponse.data.messages,
                                        timestamp: Date.now(),
                                    },
                                    jsonResponse.data.count && {count: jsonResponse.data.count},
                                    !refresh && {selectedMessages: {}}
                                );
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`Register search list error: ${error.message}`]);
                    })
                    .then(() => {
                        this.setState(prev => ({loading: prev.loading - 1}));
                        callBack && callBack();
                    });
            }
        );
    };

    updateCriteriaValue = (criteriaValue: Partial<TDefaultCriteria>, callback?: () => any, refresh?: boolean): void => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = prev.criteria.order !== criteriaValue.order;
                reverseChanged = prev.criteria.reverse !== criteriaValue.reverse;
                return {criteria};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    openModalClientMessageDraft = (communicationId: number): void =>
        this.setState({modalClientMessageDraft: communicationId});

    closeModalClientMessageRead = (): void => this.setState({modalClientMessageRead: false}, () => this.search(true));

    closeModalClientMessageDraft = (): void => this.setState({modalClientMessageDraft: false}, () => this.search(true));

    closeModalClientMessageReply = (): void => this.setState({modalClientMessageReply: false}, () => this.search(true));

    openModalClientMessageCompose = (): void => this.setState({modalClientMessageCompose: true});

    closeModalClientMessageCompose = (): void =>
        this.setState({modalClientMessageCompose: false}, () => this.search(true));

    closeModalClientMessageHistory = (): void => this.setState({isModalHistoryOpen: false}, () => this.search(true));

    closeModalCPVOLogs = (): void => this.setState({modalCPVOLogs: {communicationId: null, subject: ''}});

    openModalCPVOLogs = ({communicationId, subject}: {communicationId: number; subject: string}): void =>
        this.search(false, false, () => {
            this.setState({
                modalCPVOLogs: {
                    communicationId,
                    subject,
                },
            });
        });

    closeModalDraftDelete = () => this.setState({isModalDraftDeleteOpen: false});

    getTableFormatFunctions = () => ({
        applicationNumbers: (applicationNumbers: string[]) => (
            <ApplicationNumbersMultiple
                applicationNumbers={applicationNumbers}
                openModalApplicationNumbers={this.openModalApplicationNumbers}
            />
        ),
        deemedService: FORMAT_DATE_TIME_EASY,
        documentType: (type: string, rowObject: ICommunicationMessageClient) => {
            const {docmanProfile, docmanDirectory, docmanSubtypeOfDocument} = rowObject || {};
            let key = `${docmanProfile}-${docmanDirectory}-${docmanSubtypeOfDocument}`;
            const COMMUNICATION_KEYS_TO_TYPE: {[key: string]: string} = _COMMUNICATION_KEYS_TO_TYPE;
            return COMMUNICATION_KEYS_TO_TYPE[key];
        },
        firstOpened: FORMAT_DATE_TIME_EASY,
        lastUpdateDate: FORMAT_DATE_TIME_EASY,
        notificationDate: FORMAT_DATE_TIME_EASY,
        receivedDate: FORMAT_DATE_TIME_EASY,
        sentDate: FORMAT_DATE_TIME_EASY,
    });

    formatTableMessage = (messages: (ICommunicationMessageClient | ICommunicationMessageClientHistory)[]) =>
        messages.map(el => ({
            ...el,
            lastUpdateDate: el.sentDate,
            receivedDate: el.sentDate,
            to: 'Community Plant Variety Office',
        }));

    closeModalApplicationNumbers = (): void => this.setState({modalApplicationNumbers: []});

    openModalApplicationNumbers = (applicationNumbers: string[]): void => {
        this.setState({modalApplicationNumbers: applicationNumbers});
    };

    onTextInputSearchTermChange = ({target: {value: searchTerm}}: React.ChangeEvent<HTMLInputElement>): void =>
        this.updateCriteriaValue({searchTerm});

    onTextInputValueApplicationNumberChange = ({
        target: {value: textInputValueApplicationNumber},
    }: React.ChangeEvent<HTMLInputElement>): void =>
        this.updateCriteriaValue({applicationNumber: textInputValueApplicationNumber});

    onDateInputValueArrivalDateFromChange = (dateInputValueArrivalDateFrom: string): void =>
        this.updateCriteriaValue({arrivalDateFrom: dateInputValueArrivalDateFrom});

    onDateInputValueArrivalDateToChange = (dateInputValueArrivalDateTo: string): void =>
        this.updateCriteriaValue({arrivalDateTo: dateInputValueArrivalDateTo});

    onSelectInputValueReadingStatusChange = ({
        target: {value: selectInputValueReadingStatus},
    }: React.ChangeEvent<HTMLSelectElement>): void =>
        this.updateCriteriaValue({readingStatus: selectInputValueReadingStatus});

    onSelectInputDocumentTypeChange = ({target: {value: documentType}}: React.ChangeEvent<HTMLSelectElement>): void =>
        this.updateCriteriaValue({documentType});

    onDateInputValueNotificationDateFromChange = (dateInputValueNotificationDateFrom: string): void =>
        this.updateCriteriaValue({notificationDateFrom: dateInputValueNotificationDateFrom});

    onDateInputValueNotificationDateToChange = (dateInputValueNotificationDateTo: string): void =>
        this.updateCriteriaValue({notificationDateTo: dateInputValueNotificationDateTo});

    onTableIconHistoryClick = ({correlationId}: ICommunicationMessageClient): void =>
        this.setState({isModalHistoryOpen: correlationId});

    onTableIconDeleteClick = ({communicationId}: ICommunicationMessageClient) =>
        this.setState({isModalDraftDeleteOpen: communicationId});

    onModalClientMessageReadButtonReplyClick = (): void =>
        this.setState(prev => ({
            modalClientMessageRead: false,
            modalClientMessageReply: prev.modalClientMessageRead,
        }));

    onClearFieldsClick = (): void =>
        this.setState(
            prev => {
                return {
                    criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
                };
            },
            () => this.search()
        );

    onModalButtonDraftDeleteActionClick = () => {
        const communicationIdToDelete = this.state.isModalDraftDeleteOpen || 0;
        this.setState(
            prev => ({loading: prev.loading + 1, isModalDraftDeleteOpen: false}),
            () =>
                apiCommunicationCentreClientDraftDelete(communicationIdToDelete).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1}));
                    this.search(true);
                })
        );
    };

    onTableRowClick = (communicationId: number): void => this.openModalClientMessageDraft(communicationId);

    render() {
        const actions = CCClientDraftActionButtons(this.onTableIconHistoryClick, this.onTableIconDeleteClick);

        return (
            <>
                {this.state.modalClientMessageCompose ? (
                    <CCModalClientCompose
                        close={this.closeModalClientMessageCompose}
                        intl={this.props.intl}
                        openModalCPVOLogs={this.openModalCPVOLogs}
                    />
                ) : null}
                {this.state.modalClientMessageDraft ? (
                    <CCModalClientDraft
                        close={this.closeModalClientMessageDraft}
                        communicationId={this.state.modalClientMessageDraft}
                        intl={this.props.intl}
                        openModalCPVOLogs={this.openModalCPVOLogs}
                    />
                ) : null}
                {this.state.modalClientMessageRead ? (
                    <CCModalClientRead
                        close={this.closeModalClientMessageRead}
                        communicationId={this.state.modalClientMessageRead}
                        folder={`draft`}
                        onButtonReplyClick={this.onModalClientMessageReadButtonReplyClick}
                    />
                ) : null}
                {this.state.modalClientMessageReply ? (
                    <CCModalClientReply
                        close={this.closeModalClientMessageReply}
                        communicationId={this.state.modalClientMessageReply}
                        openModalCPVOLogs={this.openModalCPVOLogs}
                    />
                ) : null}
                {this.state.isModalHistoryOpen ? (
                    <CCModalClientHistory
                        close={this.closeModalClientMessageHistory}
                        formatTableMessages={this.formatTableMessage}
                        correlationId={this.state.isModalHistoryOpen}
                        getTableFormatFunctions={this.getTableFormatFunctions}
                    />
                ) : null}
                {this.state.modalCPVOLogs && this.state.modalCPVOLogs.communicationId ? (
                    <ModalCommunicationCentreDocumentList
                        close={this.closeModalCPVOLogs}
                        communicationId={this.state.modalCPVOLogs.communicationId}
                        intl={this.props.intl}
                        subject={this.state.modalCPVOLogs.subject}
                        userMode={false}
                    />
                ) : null}
                {this.state.modalApplicationNumbers.length ? (
                    <ModalApplicationNumbers
                        applicationNumbers={this.state.modalApplicationNumbers}
                        close={this.closeModalApplicationNumbers}
                    />
                ) : null}
                {this.state.isModalDraftDeleteOpen ? (
                    <ModalConfirmVersion2
                        title={`Delete draft`}
                        message={`Are you sure you want to delete this draft message ?`}
                        buttonName={'DELETE'}
                        action={this.onModalButtonDraftDeleteActionClick}
                        close={this.closeModalDraftDelete}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Communication Centre`} />
                <NavigationCommunicationCentre openModalMessageCompose={this.openModalClientMessageCompose} />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <TextInput
                            label={`Search term`}
                            onChange={this.onTextInputSearchTermChange}
                            onEnter={this.search}
                            value={this.state.criteria.searchTerm}
                        />
                        <SelectInput
                            label={'Document type'}
                            value={this.state.criteria.documentType}
                            onChange={this.onSelectInputDocumentTypeChange}
                            list={documentTypes}
                            notAll={true}
                            disabled={false}
                        />
                        <TextInput
                            filter={'Starts with'}
                            placeholder={`e.g. 2015 or 20150001`}
                            label={`Application number(s)`}
                            onChange={this.onTextInputValueApplicationNumberChange}
                            value={this.state.criteria.applicationNumber}
                            onEnter={this.search}
                        />
                        <DateInput
                            label={`Arrival date`}
                            changeDateFrom={this.onDateInputValueArrivalDateFromChange}
                            changeDateTo={this.onDateInputValueArrivalDateToChange}
                            inputValueFrom={this.state.criteria.arrivalDateFrom}
                            inputValueTo={this.state.criteria.arrivalDateTo}
                            disabled={true}
                        />
                        <DateInput
                            label={`Notification date`}
                            changeDateFrom={this.onDateInputValueNotificationDateFromChange}
                            changeDateTo={this.onDateInputValueNotificationDateToChange}
                            inputValueFrom={this.state.criteria.notificationDateFrom}
                            inputValueTo={this.state.criteria.notificationDateTo}
                            disabled={true}
                        />
                        <SelectInput
                            label={`Reading Status`}
                            value={this.state.criteria.readingStatus}
                            onChange={this.onSelectInputValueReadingStatusChange}
                            list={[
                                {id: '1', value: 'Read'},
                                {id: '2', value: 'not read'},
                            ]}
                            disabled={true}
                        />
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            <Button
                                variation={'secondary'}
                                clickAction={this.onClearFieldsClick}
                            >{`Clear fields`}</Button>
                            <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    <div style={{marginTop: 20, marginBottom: 20}}>
                        {this.state.screenLoaded ? (
                            <CustomTable
                                pagination={true}
                                notSortable={RESULT_FIELDS_DEFAULT_DRAFTS}
                                // mode={'mailbox'}
                                version={2}
                                timestamp={this.state.timestamp}
                                tableName={'communicationCentreClientDraft'}
                                tableType={'OBJECT'}
                                tableSource={this.formatTableMessage(this.state.messages)}
                                dataFilter={null}
                                id={'communicationId'}
                                setLastCursor={null}
                                resultFieldsDefault={RESULT_FIELDS_DEFAULT_DRAFTS}
                                forceUpdateResultFieldsDefault={true}
                                defaultOrder={this.state.criteria.order}
                                reverseOrder={this.state.criteria.reverse}
                                pageSize={this.state.criteria.pageSize}
                                intl={this.props.intl}
                                filterFunctions={null}
                                count={this.state.count}
                                hideExcelButton={true}
                                rowClick={this.onTableRowClick}
                                formatFunctions={this.getTableFormatFunctions()}
                                forehandColumn={({urgent}: any) =>
                                    urgent ? (
                                        <FontAwesomeIcon
                                            icon={faExclamation as any}
                                            fontSize={25}
                                            title={'Urgent'}
                                            color={'#810202'}
                                        />
                                    ) : null
                                }
                                actions={actions}
                                updateCriteriaValue={this.updateCriteriaValue}
                                pageNumber={this.state.criteria.pageNumber}
                            />
                        ) : null}
                    </div>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(CommunicationCentre));
