import {API_SERVERLESS, getFetch} from '~commonApi';

export function apiCommunicationCentreModalClientReadMessageRead(communicationId: number) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreModalClient/v4/CCMCRMR/read/messageRead/${communicationId}`,
        true
    );
}

export function apiCommunicationCenterModalClientReadDownloadDocument({
    communicationId,
    serverIndex,
}: {
    communicationId: number;
    serverIndex?: string;
}) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreModalClient/v4/CCMCRMDD/read/downloadDocument/${communicationId}/${serverIndex}`,
        true
    );
}
