import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export interface IVDCCaseMessage {
    messageId: number;
    sender: string;
    messageSubject: string;
    sendingDate: string;
    emailTo: string;
    emailCC: string | null;
    caseId: any;
    testId: number;
    suitableNotSuitable: number;
    type: number;
    conclusion: number;
    clearDate: string | null;
    clearedBy: string | null;
    messageBody: string;
    rowId: string;
}

export function apiVDCMessageGet(
    caseId: string | undefined,
    PARAM: string | undefined,
    PARAM2: string | undefined
): Promise<{
    messages: IVDCCaseMessage[];
    president: boolean;
    title: string;
    usersByEmail: {[keys: string]: {email: string; firstName: string; lastName: string}};
}> {
    return getFetch(
        `${API_SERVERLESS}/vdc/v4/VDCMessageGet?${caseId ? `caseId=${caseId}` : ''}${PARAM ? `PARAM=${PARAM}` : ''}${
            PARAM2 ? `PARAM2=${PARAM2}` : ''
        }`,
        true
    );
}

export function apiVDCMessageSend(JSONRequest: any): Promise<{data: string}> {
    return postFetch(`${API_SERVERLESS}/vdc/v4/VDCMessageSend`, true, JSONRequest);
}
