import React from 'react';
import BackOfficeTemplateTabs, {TAB} from '~shared/BackOfficeTemplateTabs';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import QuestionModalDetails from '../Wizard/modals/QuestionModalDetails';
import WizardStepsGroup from '../Wizard/WizardStepsGroup';
import WizardWrapper, {IWizardState} from '../Wizard/WizardWrapper';
import {
    apiBackOfficeGetTemplate,
    apiBackOfficeRemoveQuestion,
    apiBackOfficeRemoveResponse,
    apiFormDatas,
} from './BackOfficeTemplateQuestionsService';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {getDecodedJWT, trackPageView} from '../../utils';
import {getGroupOrTemplateKeyForIterationMap, getTableQuestionsKeysIterationsMap} from '../Wizard/utils';
import {injectIntl} from 'react-intl';
import {loadUrlParams} from '~utils';
import styles from '../BackOfficeFormQuestions/BackOfficeFormQuestions.module.scss';
import {IAssistedEntries, IGroup, IScreenData} from '../../types';
import {withRouter} from 'react-router-dom';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationBackOffice from '../../shared/NavigationBackOffice';

const CPVO_THIRDPARTY_ID = '1';

const CPVO_OFFICE_ID = 1;

const NAK_OFFICE_ID = 21;

const STATUS = {
    IN_PREPARATION: 1,
    ACTIVE: 2,
    TERMINATED: 3,
};

type TModalScreen = typeof TEMPLATE_QUESTIONS_MODAL_SCREEN;

type TKeyOfModalScreen = keyof TModalScreen;

const TEMPLATE_QUESTIONS_MODAL_SCREEN = {
    CREATE_QUESTION: 'CREATE_QUESTION',
};

interface IProps {
    intl: any;
    history: any;
}

interface IState extends IWizardState {
    template: IGroup | null;
    loading: number;
    assistedEntries: IAssistedEntries | null;
    modalScreen: TKeyOfModalScreen | null;
    screenData: IScreenData;
}

class BackOfficeTemplateQuestions extends WizardWrapper<IProps, IState> {
    stepGroupCallables: any = null;

    constructor(props: IProps) {
        super(props);
        this.state = {
            ...this.state,
            template: null,
            loading: 0,
            assistedEntries: null,
            modalScreen: null,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'templateQuestions'});
        (self as any).setStateFunction = this.setStateFunction;
        (self as any).defaultState = this.state;
    }

    setStateFunction = (state: any) => this.setState(state);

    loadJSONs = async (mockType?: any, callbackFn?: any, callbackFnParam?: any) => {
        const {templateId} = loadUrlParams();
        this.setState(
            prev => ({loading: prev.loading + 1}),
            async () => {
                const {thirdPartyId} = getDecodedJWT() || {};
                const officeId = thirdPartyId === CPVO_THIRDPARTY_ID ? CPVO_OFFICE_ID : NAK_OFFICE_ID;
                const [jsonResponseDatas, jsonResponseTemplate] = await Promise.all([
                    apiFormDatas(officeId),
                    apiBackOfficeGetTemplate((this.state.template && this.state.template.id) || templateId),
                ]);
                const {template} = jsonResponseTemplate || {};
                const {name, orderDisplay} = template || {};
                let templateObj = {
                    ...template,
                    message: name && name.value,
                    order: orderDisplay,
                    isGroup: false,
                    isTemplate: true,
                };
                let tableQuestionsKeysIterationsMap = getTableQuestionsKeysIterationsMap([templateObj]);
                const groupsIdsIterationsMap: any = {};
                ([templateObj] || []).forEach(el => {
                    groupsIdsIterationsMap[getGroupOrTemplateKeyForIterationMap(el)] = 1;
                });
                this.setState(
                    prevState => ({
                        ...prevState,
                        loading: prevState.loading - 1,
                        assistedEntries: jsonResponseDatas.datas,
                        template: templateObj,
                        screenData: {
                            ...prevState.screenData,
                            responsesClient: {},
                            hash: Date.now(),
                            tableQuestionsKeysIterationsMap,
                            groupsIdsIterationsMap,
                        },
                    }),
                    () => {
                        callbackFn && callbackFn(callbackFnParam ? callbackFnParam : null);
                    }
                );
            }
        );
    };

    removeQuestion = (questionId: number) => {
        const JSONRequest = {
            formId: null,
            questionId,
            templateId: this.state.template && this.state.template.templateId,
        };
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiBackOfficeRemoveQuestion(JSONRequest)
                    .then(JSONResponse => {
                        JSONResponse && JSONResponse.data === 'OK' && this.loadJSONs();
                    })
                    .catch((error: any) => LOG([`ERROR ${error}`]));
            }
        );
    };

    removeResponse = (responseId: any, questionId: any) => {
        const JSONRequest = {
            formId: null,
            questionId,
            responseId,
            templateId: this.state.template && this.state.template.templateId,
        };
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiBackOfficeRemoveResponse(JSONRequest)
                    .then((jsonResponse: any) => {
                        this.setState(
                            prev => ({loading: prev.loading - 1}),
                            () => {
                                jsonResponse.data === 'OK' && this.loadJSONs();
                            }
                        );
                    })
                    .catch((error: any) => LOG([`ERROR ${error}`]));
            }
        );
    };

    openModal = (modalScreen: any) => this.setState({modalScreen});

    closeModal = () => this.setState({modalScreen: null});

    afterCreatingNewQuestion = () => {
        this.closeModal();
        this.loadJSONs(null, null, null);
    };

    setStepGroupCallables = (callables: any) => (this.stepGroupCallables = callables);

    render() {
        return (
            <>
                {this.state.template &&
                this.state.template.status === STATUS.IN_PREPARATION &&
                this.state.modalScreen === TEMPLATE_QUESTIONS_MODAL_SCREEN.CREATE_QUESTION ? (
                    <QuestionModalDetails
                        afterCreatingNewQuestion={this.afterCreatingNewQuestion}
                        close={this.closeModal}
                        mainScreen={`templateQuestions`}
                        modalQueuePop={this.modalQueuePop}
                        modalQueuePush={this.modalQueuePush}
                        questionId={0}
                        steps={[this.state.template || []]}
                    />
                ) : null}
                {this.state.loading ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Web Back Office`} />
                <NavigationBackOffice activeTitle={'Templates'} />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <BackOfficeTemplateTabs
                            history={this.props.history}
                            tabId={TAB.QUESTIONS}
                            search={this.loadJSONs}
                            loading={this.state.loading}
                            templateId={(this.state.template && this.state.template.templateId) || null}
                            templateStatus={(this.state.template && this.state.template.status) || null}
                            officeId={this.state.template && this.state.template.officeId}
                        />
                        {this.state.template && this.state.template.status === STATUS.IN_PREPARATION && (
                            <section style={{marginBottom: 0}} className={styles.formTopActions}>
                                <div>
                                    <FormFooterButton
                                        color={buttonColor.green}
                                        clickAction={() =>
                                            this.openModal(TEMPLATE_QUESTIONS_MODAL_SCREEN.CREATE_QUESTION)
                                        }
                                        icon={faPlus}
                                    >{`Create question`}</FormFooterButton>
                                </div>
                            </section>
                        )}
                        {this.state.template &&
                        this.state.template.questions &&
                        this.state.template.questions.length === 0 ? (
                            <div>{this.props.intl.formatMessage({id: `template.hasNoQuestions`})}</div>
                        ) : null}
                        <div style={{padding: '0 15px'}}>
                            {this.state.template ? (
                                <WizardStepsGroup
                                    assistedEntries={this.state.assistedEntries}
                                    currentStepNumber={1}
                                    editable={true}
                                    formOrTemplateStatus={this.state.template && this.state.template.status}
                                    formOrTemplateOfficeId={this.state.template && this.state.template.officeId}
                                    formId={null}
                                    group={this.state.template || null}
                                    goToStep={() => null}
                                    loadFormQuestionsJSONs={this.loadJSONs}
                                    mainScreen={`templateQuestions`}
                                    mock={null}
                                    modalQueuePush={this.modalQueuePush}
                                    modalQueuePop={this.modalQueuePop}
                                    removeQuestion={this.removeQuestion}
                                    removeResponse={this.removeResponse}
                                    screenData={this.state.screenData}
                                    setStepGroupCallables={this.setStepGroupCallables}
                                    steps={[this.state.template || []]}
                                    templateId={this.state.template && this.state.template.templateId}
                                    updateScreenData={this.updateScreenData}
                                />
                            ) : null}
                        </div>
                    </FormWrapper>
                </MainWrapper>
                {super.render()}
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(BackOfficeTemplateQuestions));
