import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeApplySameConfidentialValueToOtherQuestionsHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <p>
                    {`If some other question has the confidential option checked, then we can apply to that other question
                    the same confidential value of this confidential question`}
                </p>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeApplySameConfidentialValueToOtherQuestionsHelp;
