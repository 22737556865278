import React from 'react';

interface IProps {}

export default class WizardQuestionLooper extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render() {
        return <React.Fragment>{`Looper`}</React.Fragment>;
    }
}
