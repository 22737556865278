import React, {useEffect, useState} from 'react';
import {Button} from '../../../../../../componentsFormV2';
import {ConsultModifySpeciesDataTableRowData, EditRowsSpecies, ModifySpeciesModal} from '../../../../../../types/TLO';
import CustomTable from '../../../../../../components/CustomTable';
import {Error} from '../../../../../../componentsLayout';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {History} from 'history';
import {ModalCustomVersion2} from '../../../../../../commonModals';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import TextInput from '~components/TextInput';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {getMandatoryAsterisk} from '../../../../../../utils/format';
import {injectIntl, IntlShape} from 'react-intl';
import styles from './ModalSelectSpecies.module.scss';
import {apiTloApplicationsCommonsExaminationContractSpecies} from '../../../../../TLO/Screens/ConsultModifySpeciesDataPage/Components/ModalSelectSpecies/ModalSelectSpeciesService';

export const entrustmentEditMode: any = {NEW: 'NEW', VIEW: 'VIEW', EDIT: 'EDIT'};

const RESULT_FIELDS_SPECIES_AUTOCOMPLETE_RESULTS_DEFAULT = ['NAME', 'ID', 'FEEGROUPID', 'EXAMID', 'CULTIVATIONTYPE'];

interface IRowObject {
    CULTIVATIONTYPE: number;
    EXAMID: string;
    FEEGROUPID: string;
    ID: string;
    NAME: string;
}

interface ModalSelectSpeciesProps {
    intl: IntlShape;
    history: History;
    editRowsSpecies: Array<EditRowsSpecies>;
    speciesForModalModifySeveralPlantRequirements: Array<ConsultModifySpeciesDataTableRowData> | null;
    count: number;
    timestamp: number;
    modifySpecies: ModifySpeciesModal;
    speciesListByIdNameExamId: Array<{ID: string; NAME: string; EXAMID: number}> | null;
    speciesNameHash: {[key: string]: Array<string>} | null;
    selectedModifySpecies: {[key: string]: string};
    updateSelectedModifySpecies: (selectedModifySpecies: {[key: string]: string}) => void;
    updateModifySpeciesValue: (
        // eslint-disable-next-line no-undef
        modifySpeciesValue: Partial<ModifySpeciesModal>,
        callback?: () => void,
        refresh?: boolean
    ) => void;
    actionButtonClickCModifySeveralPlantRequirements: (
        modifySeveralPlantRequirements: string,
        examContactId?: any
    ) => void;
    close: (refresh?: boolean) => void;
}

const ModalSelectSpecies = (props: ModalSelectSpeciesProps & RouteComponentProps) => {
    const RESULT_FIELDS_DEFAULT = ['speciesName', 'genus', 'entrustmentType', 'subType', 'examGroupName'];
    const [loading, setLoading] = useState(false);
    const [errorMessage] = useState<string | null>(null);
    const [highlightErrors] = useState(false);
    const [species, setSpecies] = useState<Array<ConsultModifySpeciesDataTableRowData> | null>(
        props.speciesForModalModifySeveralPlantRequirements
    );
    const [timestamp, setTimestamp] = useState(props.timestamp);
    const [textInputSpeciesValue, setTextInputSpeciesValue] = useState('');
    const [speciesAutocompleteTableSource, setSpeciesAutocompleteTableSource] = useState<IRowObject[]>([]);
    const [speciesAutocompleteActiveRowID, setSpeciesAutocompleteActiveRowID] = useState<any>('');
    const [speciesJSONResponse, setSpeciesJSONResponse] = useState<IRowObject[]>([]);
    const [speciesAutocompleteActiveExaminationId, setSpeciesAutocompleteActiveExaminationId] = useState('');

    useEffect(() => {
        (async function () {
            setLoading(true);
            const JSONResponse = await apiTloApplicationsCommonsExaminationContractSpecies();
            const speciesNameHash: any = {};
            JSONResponse.species.forEach(({ID, NAME}: any) => (speciesNameHash[NAME] = [ID]));
            setSpeciesJSONResponse(JSONResponse.species);
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        setSpecies(props.speciesForModalModifySeveralPlantRequirements);
        setTimestamp(Date.now());
    }, [props.speciesForModalModifySeveralPlantRequirements]);

    const comebackToSearch = (refresh = false) => {
        if (refresh) {
            props.close && props.close(refresh);
        } else {
            props.close && props.close();
        }
    };

    const onOpenModifySeveralPlantRequirments = () =>
        props.actionButtonClickCModifySeveralPlantRequirements('EDIT', speciesAutocompleteActiveExaminationId);

    const onTextInputSpeciesChange = ({
        target: {value: textInputSpeciesValue},
    }: React.ChangeEvent<HTMLSelectElement>) => {
        const matchingAutocompleteSpecies = speciesJSONResponse.filter(({NAME}: IRowObject) =>
            NAME.toLowerCase().startsWith(textInputSpeciesValue.toLowerCase())
        );
        setTextInputSpeciesValue(textInputSpeciesValue);
        setSpeciesAutocompleteTableSource(matchingAutocompleteSpecies);
        setSpeciesAutocompleteActiveRowID(null);
        setSpeciesAutocompleteActiveExaminationId('');
        setTimestamp(Date.now());
    };

    const getRowID = ({ID, NAME, EXAMID, FEEGROUPID, CULTIVATIONTYPE}: IRowObject) =>
        `${ID}-${NAME}-${EXAMID}-${FEEGROUPID}-${CULTIVATIONTYPE}`;

    const onTableSpeciesAutocompleteRowClick = (_: any, rowObject: IRowObject) => {
        setSpeciesAutocompleteActiveRowID(getRowID(rowObject));
        setSpeciesAutocompleteActiveExaminationId(rowObject.EXAMID);
    };

    const getTableSpeciesAutocompleteRowClass = (rowObject: IRowObject) => {
        if (getRowID(rowObject) === speciesAutocompleteActiveRowID) {
            return styles.activeRow;
        }
    };

    return (
        <ModalCustomVersion2
            loading={loading}
            close={comebackToSearch}
            header={`Selected species...`}
            body={
                <div>
                    <div style={{maxHeight: '30vh', overflowY: 'auto'}}>
                        <CustomTable
                            version={2}
                            {...props}
                            tableName={'modifySeveralPlantRequirements'}
                            tableType={'OBJECT'}
                            tableSource={species}
                            timestamp={timestamp}
                            id={'entrustmentType'}
                            hideExcelButton={true}
                            resultFieldsAll={RESULT_FIELDS_DEFAULT}
                            resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                            intl={props.intl}
                            defaultOrder={'speciesName'}
                            reverseOrder={false}
                            count={props.count}
                        />
                    </div>
                    <div style={{clear: 'both'}}>{}</div>
                    <div style={{height: '20px'}}>{}</div>
                    <hr />
                    <div style={{height: '20px'}}>{}</div>
                    <div style={{clear: 'both'}}>{}</div>
                    <div
                        style={{marginBottom: 20, fontWeight: 'bold'}}
                    >{`...for which you would like to apply the same plant requirements (editable) as:`}</div>
                    <TextInput
                        double={true}
                        outsideLabel={getMandatoryAsterisk('Species')}
                        outsideLabelWidth={160}
                        onChange={onTextInputSpeciesChange}
                        value={textInputSpeciesValue}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    {textInputSpeciesValue ? (
                        <CustomTable
                            {...props}
                            hideExcelButton={true}
                            id={'ID'}
                            intl={props.intl}
                            resultFieldsDefault={RESULT_FIELDS_SPECIES_AUTOCOMPLETE_RESULTS_DEFAULT}
                            reverseOrder={false}
                            rowClick={onTableSpeciesAutocompleteRowClick}
                            rowClass={getTableSpeciesAutocompleteRowClass}
                            tableName={'modifySeveralPlantRequirementsSpeciesAutocompleteResults'}
                            tableType={'OBJECT'}
                            tableSource={speciesAutocompleteTableSource}
                            timestamp={timestamp}
                            unlimited={true}
                        />
                    ) : null}
                    <div style={{clear: 'both'}}>{}</div>
                    <div style={{height: '200px'}}>{}</div>
                    <div style={{clear: 'both'}}>{}</div>
                </div>
            }
            footer={
                <>
                    {highlightErrors ? (
                        <Error>
                            <FontAwesomeIcon icon={faExclamationCircle as any} color={'white'} />
                            {` ${errorMessage}`}
                        </Error>
                    ) : null}
                    <Button clickAction={comebackToSearch} variation={'secondary'}>
                        {`Cancel`}
                    </Button>
                    <Button
                        clickAction={onOpenModifySeveralPlantRequirments}
                        disabled={!speciesAutocompleteActiveExaminationId}
                    >
                        {`Show plant requirements`}
                    </Button>
                </>
            }
        />
    );
};

export default injectIntl(withRouter(ModalSelectSpecies));
