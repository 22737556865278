import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeResponseMandatoryOnFirstOccurrenceOfGroupHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p>If a group is multi, then the response is only mandatory for the first occurence of group</p>
                    <p>In the next occurences of group, then response is optional</p>
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeResponseMandatoryOnFirstOccurrenceOfGroupHelp;
