import React, {useRef, useState} from 'react';
import styles from './ModalEndOfFilesPreviousToSignStatusVersion2.module.scss';
import {faChevronDown, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import DragAndDrop from '~components/DragAndDrop';
import CustomTable from '~components/CustomTable';
import getIcon from '~utils/icons';
import {saveAs} from 'file-saver';
import ModalEndOfFilesPreviousToSignStatusVersion2Actions from './ModalEndOfFilesPreviousToSignStatusVersion2Actions';
import BrowserInfo from '~shared/BrowserInfo';
import InputLink from '~components/InputLink';
import {Error} from '../../componentsLayout';
import SelectInput from '~components/SelectInput';
import {ModalCustomVersion2} from '../../commonModals';
import {Button} from '../../componentsFormV2';

const HOLDERS_CONSENT = 'holdersConsent';
const THIRD_PARTY_CONSENT = 'thirdPartyConsent';
const NONE = 'NONE';
const DOCUMENT_TYPES = [
    {id: NONE, value: 'Please select a document type'},
    {id: HOLDERS_CONSENT, value: `Holder's consent`},
    {id: THIRD_PARTY_CONSENT, value: `Third party's consent`},
];

export const SIGN_CONSENT_DOCUMENT_TYPE = 'signatureConsent';

export default function ({
    selectedApplications,
    getApplicationNumberMessageAndStatus,
    documentList,
    checkFile,
    deleteFile,
    intl,
    timestamp,
    signRequests,
    onSelectType,
    close,
    error: validationError,
}) {
    let signAndUploadEnabled = false;
    let allowUploadButton = false;
    let messageType2Present = false;
    let messageType4Present = false;
    let messageType5Present = false;
    const [informationOpened, setInformationOpened] = useState(false);
    const [notes, showNotes] = useState(false);
    const [error, setError] = useState(null);
    const inputUploadRef = useRef(null);

    const toggleInformation = () => setInformationOpened(!informationOpened);
    const onFileUploadHandler = (files, event) => {
        if (allowUploadButton) {
            setInformationOpened(false);
            let documentType = null;
            if (!messageType2Present || !messageType4Present) {
                if (messageType2Present) {
                    documentType = HOLDERS_CONSENT;
                }
                if (messageType4Present || messageType5Present) {
                    documentType = THIRD_PARTY_CONSENT;
                }
            }
            checkFile(files[0], event, documentType);
        }
    };
    const handleDrop = (files, event) => onFileUploadHandler(files, event);

    const onFileUpload = event => onFileUploadHandler(event.target.files, event);

    const signRequestCheck = () => {
        if (documentList.filter(i => i.documentType === null).length > 0) {
            setError('You have to select a type for each document');
        } else {
            signRequests();
        }
    };

    const actions = ModalEndOfFilesPreviousToSignStatusVersion2Actions(deleteFile);
    if (error && documentList.filter(i => i.documentType === null).length === 0) {
        setError(null);
    }

    return (
        <DragAndDrop handleDrop={handleDrop}>
            <ModalCustomVersion2
                close={close}
                header={'Validation step - Observations before signing'}
                body={
                    <div>
                        {Object.keys(selectedApplications || {}).length > 0 &&
                            Object.values(selectedApplications)[0].applicationStatus === 'A' && (
                                <div style={{paddingLeft: 15}}>
                                    {`You are going to `}
                                    <strong>{` STOP `}</strong>
                                    {` the application process for the selected variety`}
                                </div>
                            )}
                        {Object.keys(selectedApplications || {}).length > 0 &&
                            Object.values(selectedApplications)[0].applicationStatus === 'G' && (
                                <div style={{paddingLeft: 15}}>
                                    {`You are going to`}
                                    <strong>{` STOP `}</strong>
                                    {`the protection process for the selected variety`}
                                </div>
                            )}
                        <div style={{height: 370, overflowY: 'auto', padding: 20}}>
                            <div style={{paddingBottom: 20}}>
                                {notes && (
                                    <React.Fragment>
                                        <InputLink
                                            label={`Notes`}
                                            icon={informationOpened ? faChevronDown : faChevronRight}
                                            clickAction={toggleInformation}
                                        />
                                        <div style={{clear: 'both'}}>{}</div>
                                        {informationOpened && <BrowserInfo intl={intl} />}
                                    </React.Fragment>
                                )}
                            </div>
                            {documentList.length > 0 && (
                                <div style={{marginBottom: 20}}>
                                    <CustomTable
                                        version={2}
                                        notSortable={['filename', 'size', 'documentType']}
                                        tableName={'documentUpload'}
                                        tableType={'OBJECT'}
                                        tableSource={documentList || []}
                                        dataFilter={null}
                                        id={'index'}
                                        setLastCursor={null}
                                        resultFieldsDefault={['filename', 'size', 'documentType']}
                                        intl={intl}
                                        formatFunctions={{
                                            size: size => `${Math.floor(size * 100) / 100} KB`,
                                            extension: extension => (
                                                <img src={getIcon(extension || 'pdf')} alt={extension} />
                                            ),
                                            documentType: (documentType, object) =>
                                                documentType === SIGN_CONSENT_DOCUMENT_TYPE ? (
                                                    <div style={{textHeight: 800}}>{'Signature consent'}</div>
                                                ) : (
                                                    <React.Fragment>
                                                        {messageType2Present && messageType4Present ? (
                                                            <div
                                                                style={{
                                                                    marginTop: '-5px',
                                                                    marginLeft: '-10px',
                                                                }}
                                                            >
                                                                <SelectInput
                                                                    value={documentType}
                                                                    onChange={event =>
                                                                        onSelectType(event, object.index)
                                                                    }
                                                                    list={DOCUMENT_TYPES}
                                                                    notAll={true}
                                                                    height={25}
                                                                />
                                                                <div style={{clear: 'both'}}>{}</div>
                                                            </div>
                                                        ) : (
                                                            <React.Fragment>
                                                                {messageType2Present ? (
                                                                    <div style={{textHeight: 800}}>
                                                                        {DOCUMENT_TYPES.filter(
                                                                            i => i.id === HOLDERS_CONSENT
                                                                        )
                                                                            .map(i => i.value)
                                                                            .join('')}
                                                                    </div>
                                                                ) : (
                                                                    <div style={{textHeight: 800}}>
                                                                        {DOCUMENT_TYPES.filter(
                                                                            i => i.id === THIRD_PARTY_CONSENT
                                                                        )
                                                                            .map(i => i.value)
                                                                            .join('')}
                                                                    </div>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                ),
                                        }}
                                        count={(documentList || []).length}
                                        hideExcelButton={true}
                                        noChangePageSize={true}
                                        forehandColumn={row => (
                                            <img
                                                style={{width: 20, height: 20, cursor: 'pointer'}}
                                                alt={'Download'}
                                                src={getIcon(row.extension || 'pdf')}
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    saveAs(row.file, row.filename);
                                                }}
                                            />
                                        )}
                                        timestamp={timestamp}
                                        actions={actions}
                                    />
                                </div>
                            )}
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>
                                            <a>{'Application Number'}</a>
                                        </th>
                                        <th style={{width: 697}}>
                                            <a>{'Observations'}</a>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {((selectedApplications && Object.values(selectedApplications)) || []).map(
                                        application => {
                                            const {
                                                messages,
                                                error,
                                                allowUpload,
                                                messageType2,
                                                messageType4,
                                                messageType5,
                                            } = getApplicationNumberMessageAndStatus(application);
                                            if (!error) {
                                                signAndUploadEnabled = true;
                                            }
                                            if (allowUpload) {
                                                allowUploadButton = true;
                                                !notes && showNotes(true);
                                            }
                                            if (messageType2) {
                                                messageType2Present = true;
                                            }
                                            if (messageType4) {
                                                messageType4Present = true;
                                            }
                                            if (messageType5) {
                                                messageType5Present = true;
                                            }
                                            return (
                                                <tr>
                                                    <td className={error && styles.error}>
                                                        {application.applicationNumber}
                                                    </td>
                                                    <td className={error && styles.error}>
                                                        {messages.length === 0 ? (
                                                            <ul
                                                                style={{
                                                                    margin: 0,
                                                                    listStyleType: 'none',
                                                                    paddingLeft: 25,
                                                                }}
                                                            >
                                                                <li>{'OK'}</li>
                                                            </ul>
                                                        ) : (
                                                            <React.Fragment>
                                                                <ul style={{margin: 0}}>
                                                                    {messages.map(message => (
                                                                        <li>{message}</li>
                                                                    ))}
                                                                </ul>
                                                            </React.Fragment>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                footer={
                    <div className={styles.footerWrap}>
                        {error || validationError ? <Error>{error || validationError}</Error> : null}
                        <div className={styles.footerLeft}>
                            <label htmlFor={allowUploadButton ? 'upload' : null}>
                                <input
                                    type="file"
                                    name="files[]"
                                    id="upload"
                                    style={{opacity: 0, width: 0}}
                                    onChange={onFileUpload}
                                    accept="image/jpg, image/jpeg, image/png, application/pdf"
                                    ref={inputUploadRef}
                                />
                                <Button
                                    variation={'secondary'}
                                    disabled={!allowUploadButton}
                                    clickAction={() => inputUploadRef.current.click()}
                                >{`Upload document(s)`}</Button>
                            </label>
                        </div>
                        <div className={styles.footerRight}>
                            <Button clickAction={close} icon={'close'} variation={'danger'}>
                                {`Cancel`}
                            </Button>
                            <Button
                                clickAction={signRequestCheck}
                                disabled={!signAndUploadEnabled}
                                icon={'arrowRight'}
                            >{`Sign request`}</Button>
                        </div>
                    </div>
                }
            />
        </DragAndDrop>
    );
}
