import React from 'react';
import styles from './ModalPreview.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBackward, faCaretLeft, faCaretRight, faForward, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {ICommunicationMessageClient, ICommunicationMessageInternal} from '../../../types';
import {utilCalculateTableMaxPossiblePage} from '../../../utils/index';

interface IProps {
    modalPreviewURL: string;
    modalPreviewMessage: any;
    modalReadCommunicationId?: number | false;
    tableMessages?: ICommunicationMessageInternal[] | ICommunicationMessageClient[];
    count?: number;
    currentPageNumber?: number;
    currentPageSize?: number;
    onButtonFirstClick?: any;
    onButtonPreviousClick?: any;
    onButtonNextClick?: any;
    onButtonLastClick?: any;
    lastPageResultSetSize?: number | null;
    hideNavButtons?: boolean;
}

interface IState {}

class ModalPreview extends React.Component<IProps, IState> {
    render() {
        const isLoading = this.props.modalPreviewURL === '';
        const currentMessageIndex = (this.props.tableMessages || []).findIndex(
            message => message.communicationId === this.props.modalReadCommunicationId
        );
        const lastPage = utilCalculateTableMaxPossiblePage({
            totalRows: this.props.count || 0,
            pageSize: this.props.currentPageSize || 0,
        });
        const buttonFirstIsDisabled = this.props.currentPageNumber === 1 && currentMessageIndex === 0;
        const buttonPreviousIsDisabled = this.props.currentPageNumber === 1 && currentMessageIndex === 0;
        const buttonNextIsDisabled =
            this.props.currentPageNumber === lastPage &&
            currentMessageIndex === (this.props.lastPageResultSetSize || 0) - 1;
        const buttonLastIsDisabled =
            this.props.currentPageNumber === lastPage &&
            currentMessageIndex === (this.props.lastPageResultSetSize || 0) - 1;

        return (
            <div className={styles.modalWrap}>
                <div className={styles.navWrap}>
                    <div className={styles.navInner}>
                        {this.props.hideNavButtons ? null : (
                            <>
                                <button
                                    className={styles.navButton}
                                    disabled={buttonFirstIsDisabled}
                                    onClick={this.props.onButtonFirstClick}
                                    type={'button'}
                                >
                                    <FontAwesomeIcon icon={faBackward as any} color={'white'} fontSize={20} />
                                </button>
                                <button
                                    className={styles.navButton}
                                    onClick={this.props.onButtonPreviousClick}
                                    disabled={buttonPreviousIsDisabled}
                                    type={'button'}
                                >
                                    <FontAwesomeIcon icon={faCaretLeft as any} color={'white'} fontSize={20} />
                                </button>
                            </>
                        )}
                        <span className={styles.messageTitle}>
                            {this.props.modalPreviewMessage?.messageSubject || ''}
                        </span>
                        {this.props.hideNavButtons ? null : (
                            <>
                                <button
                                    className={styles.navButton}
                                    disabled={buttonNextIsDisabled}
                                    onClick={this.props.onButtonNextClick}
                                    type={'button'}
                                >
                                    <FontAwesomeIcon icon={faCaretRight as any} color={'white'} fontSize={20} />
                                </button>
                                <button
                                    className={styles.navButton}
                                    disabled={buttonLastIsDisabled}
                                    onClick={this.props.onButtonLastClick}
                                    type={'button'}
                                >
                                    <FontAwesomeIcon icon={faForward as any} color={'white'} fontSize={20} />
                                </button>
                            </>
                        )}
                    </div>
                </div>
                <div className={styles.iframeWrap}>
                    {isLoading ? (
                        <div className={styles.loadingWrap}>
                            <div className={styles.spinner}>
                                <FontAwesomeIcon icon={faSpinner as any} />
                            </div>
                        </div>
                    ) : null}
                    <iframe src={this.props.modalPreviewURL} frameBorder={0} className={styles.iframe} />
                </div>
            </div>
        );
    }
}

export default ModalPreview;
