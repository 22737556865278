import {SectionsTableFetchData, YearsTableFetchData} from '../ACAREA/CommonInterfaces/CommonInterfaces';
import {API_SERVERLESS, getFetch, postFetch} from '../../commonApi';
import {Tree} from '../TLO/CommonComponents/TreeView/Interfaces/TreeViewInterfaces';
import {computeGetUploadPreSignedURLPromises, postFileToPreSignedURLRequest} from '../../utils/requests';

export function apiSectionsTableFetch(): Promise<SectionsTableFetchData> {
    return getFetch(`${API_SERVERLESS}/acarea/viewcategories`, true);
}

export function apiYearsTableFetch(sectionId: number): Promise<YearsTableFetchData> {
    return getFetch(`${API_SERVERLESS}/acarea/viewcategoryyears?categoryId=${sectionId}`, true);
}

export function apiDownloadTreeDocumentFile(fileUUID: string, categoryId: string) {
    return getFetch(`${API_SERVERLESS}/acarea/downloadfile?uuid=${fileUUID}&categoryId=${categoryId}`, true);
}

export function apiFetchTree(jsonData: {year: string; categoryId: string; languageId?: string}): Promise<Tree> {
    const path = `/acarea/getdocument`;

    return postFetch(`${API_SERVERLESS}${path}`, true, jsonData);
}

export function apiCreateTree(jsonData: {
    tree: Tree;
    categoryId: string;
    year: string;
    filename?: string;
    file?: Blob;
}): Promise<{fileUUID: string}> {
    if (jsonData.filename && jsonData.file) {
        return computeGetUploadPreSignedURLPromises({}).then(({presignedUrl, identifier}: any = {}) => {
            return postFileToPreSignedURLRequest({preSignedURL: presignedUrl, file: jsonData.file}).then(() => {
                return postFetch(`${API_SERVERLESS}/acarea/createdocument`, true, {
                    year: jsonData.year,
                    categoryId: jsonData.categoryId,
                    tree: jsonData.tree,
                    filename: jsonData.filename,
                    fileIdentifier: identifier,
                });
            });
        });
    } else {
        return postFetch(`${API_SERVERLESS}/acarea/createdocument`, true, {
            year: jsonData.year,
            categoryId: jsonData.categoryId,
            tree: jsonData.tree,
        });
    }
}

export function apiFetchACAREARights() {
    return getFetch(`${API_SERVERLESS}/acarea/fetchrights`, true);
}

export function apiDeleteTreeDocumentFile(fileUUID: string, categoryId: string): Promise<{}> {
    return getFetch(`${API_SERVERLESS}/acarea/deletefile?uuid=${fileUUID}&categoryId=${categoryId}`, true, {});
}
