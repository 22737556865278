import React from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {faInfo} from '@fortawesome/free-solid-svg-icons';
import {apiSpecies} from '~commonApi/static';
import {apiOnlineApplicationRequestRepairDocuments, apiSearchApplications} from './BackOfficeRequestsService';
import styles from './BackOfficeRequests.module.scss';
import TextInput from '~components/TextInput';
import SelectInput from '~components/SelectInput';
import TextLabelInput from '~components/TextLabelInput';
import {FORMAT_DATE_EASY, FORMAT_DATE_TIME_EASY} from '~components/FormatFunctions';
import CustomTable from '~components/CustomTable';
import Empty from '~components/Empty';
import {ModalAlertVersion2, ModalConfirmVersion2} from '../../commonModals';
import {getDecodedJWT, getLoggedUsedId, loadUrlParams as loadUrlParamsFn} from '~utils';
import BackOfficeRequestsCustomTableActionButtons from './customTable/BackOfficeRequestsCustomTableActionButtons';
import ModalBackOfficeRequestsRemedy from './modals/ModalBackOfficeRequestsRemedy';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationBackOffice from '../../shared/NavigationBackOffice';
import {Button} from '../../componentsFormV2';
import {trackPageView} from '../../utils';

const SIGNED_DEFAULT_RESULT_FIELDS = [
    'office',
    'applicationRequestCode',
    'speciesInput',
    'breederReference',
    'contactName',
    'creationDate',
    'signatureDate',
];

const DEFAULT_CRITERIA = {
    author: 0,
    officeId: 0,
    denomination: '',
    denominationFilter: 'equals',
    breedersReference: '',
    speciesName: '',
    speciesNameFilter: 'starts',
    speciesIds: '',
    applicationNumber: '',
    onlineAppNumber: '',
    importedFromPrisma: false,
    UPOVReference: '',
    pageNumber: 1,
    pageSize: 10,
    order: 'signDate',
    reverse: true,
    refresh: false,
    excel: false,
    contact: 0,
};

const ADVANCED_CRITERIA = ['UPOVReference', 'importedFromPrisma'];

const OUTSIDE_LABEL_WIDTH = 160;

const utilPrepareTableSource = applications => {
    return (applications || []).map(application => {
        return {
            ...application,
            applicationSteps: {
                application,
            },
        };
    });
};

class BackOfficeRequests extends React.Component {
    initialCriteria = null;

    constructor(props) {
        super(props);
        this.loggedUserId = getLoggedUsedId();
        this.decodedJWT = getDecodedJWT();
        this.loggedInUserCompany = (this.decodedJWT && this.decodedJWT.company) || '';
        this.initialCriteria = Object.assign({}, DEFAULT_CRITERIA);
        this.state = {
            advancedCriteriaCount: 0,
            criteria: this.initialCriteria,
            criteriaCount: 0,
            isModalConfirmRepairDocumentsOpen: false,
            isModalRemedyOpen: false,
            loading: 0,
            modalAlertTitle: null,
            modalAlertMessage: null,
            responseApplications: null,
            selectedSpecies: {},
            speciesListByIdName: null,
            speciesNameHash: null,
            timestamp: null,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'requests'});
        this.loadJSONs();
        this.loadUrlParams();
    }

    loadUrlParams = () => {
        const urlParams = loadUrlParamsFn();
        const criteria = Object.assign({}, this.initialCriteria, urlParams);
        if (Object.keys(urlParams).length > 0) {
            const selectedSpecies = {};
            if (criteria.speciesIds && criteria.speciesIds !== '') {
                criteria.speciesIds.split(',').forEach(speciesId => (selectedSpecies[speciesId] = false));
            }
            const criteriaCount = this.countCriteria(criteria);
            const advancedCriteriaCount = this.countCriteria(criteria, true);
            this.setState(
                Object.assign(
                    {},
                    {
                        criteria,
                        criteriaCount,
                        advancedCriteriaCount,
                        selectedSpecies,
                        timestamp: Date.now(),
                    },
                    criteria.order && {defaultOrder: criteria.order},
                    criteria.reverse && {reverseOrder: criteria.reverse}
                ),
                () => this.search(false, true)
            );
        }
    };

    search = (refresh, urlLoad) => {
        this.setState(
            prev => ({
                loading: ++prev.loading,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams(parsedCriteria);
                apiSearchApplications(parsedCriteria, DEFAULT_CRITERIA)
                    .then(jsonResponse => {
                        if (
                            jsonResponse &&
                            jsonResponse.data &&
                            jsonResponse.data.applications &&
                            Array.isArray(jsonResponse.data.applications)
                        ) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {
                                    pageNumber: prev.criteria.pageNumber || 1,
                                });
                                return Object.assign(
                                    {},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        responseApplications: jsonResponse.data.applications,
                                        timestamp: Date.now(),
                                    },
                                    jsonResponse.data.COUNT && {count: jsonResponse.data.COUNT}
                                );
                            });
                        }
                    })
                    .catch(error => {
                        ERROR`OAApplications: ${(error && error.message) || `Error while searching applications`}`;
                        this.setState({
                            modalAlertTitle: `Error`,
                            modalAlertMessage: `There was an error while searching applications`,
                        });
                    })
                    .finally(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );
    };

    loadSpecies = () =>
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                apiSpecies()
                    .then(jsonResponse => {
                        if (jsonResponse) {
                            const speciesNameHash = {};
                            (jsonResponse || []).forEach(speciesElement => {
                                if (!speciesNameHash[speciesElement.NAME]) {
                                    speciesNameHash[speciesElement.NAME] = [];
                                }
                                speciesNameHash[speciesElement.NAME].push(speciesElement.ID);
                            });
                            const speciesListByIdName = Object.keys(speciesNameHash).map(speciesName => ({
                                ID: speciesNameHash[speciesName].join(','),
                                NAME: speciesName,
                            }));
                            const selectedSpecies = {};
                            const selectedSpeciesKeyArray = Object.keys(this.state.selectedSpecies || {});
                            speciesListByIdName
                                .filter(i => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                                .forEach(item => (selectedSpecies[item.ID] = item.NAME));
                            this.setState({
                                speciesListByIdName,
                                speciesNameHash,
                                selectedSpecies,
                                timestamp: Date.now(),
                            });
                        }
                    })
                    .catch(error => {
                        ERROR`OAApplications: ${(error && error.message) || `Error while loading species`}`;
                        this.setState({
                            modalAlertTitle: `Error`,
                            modalAlertMessage: `There was an error while loading species`,
                        });
                    })
                    .finally(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );

    loadJSONs = () => {
        const promises = [this.loadSpecies()];
        Promise.all(promises).catch(error => LOG`Error loading data: ${error}`);
    };

    countCriteria = (criteria, advanced) => {
        let count = 0;
        (advanced ? ADVANCED_CRITERIA : Object.keys(criteria)).map(key => {
            if (criteria[key] && criteria[key] !== DEFAULT_CRITERIA[key] && criteria[key] !== '') count++;
        });
        return count;
    };

    updateCriteriaValue = (criteriaValue, callback, refresh) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                // DEBUG`new criteria: ${{...criteria}}`;
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                pageNumberChanged = criteriaValue.pageNumber && prev.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse && prev.reverse !== criteriaValue.reverse;
                return {criteria, criteriaCount, advancedCriteriaCount};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    buildParams = parsedCriteria => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(i => parsedCriteria[i] !== DEFAULT_CRITERIA[i] && i !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key]}`);
        this.props.history.replace(`/requests${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`);
    };

    parseCriteria = criteria => {
        const parsedCriteria = {};
        Object.keys(criteria).map(key => {
            if (key === 'denominationFilter' && !criteria['denomination']) {
                criteria[key] = DEFAULT_CRITERIA[key];
            } else if (key === 'speciesNameFilter' && !criteria['speciesName']) {
                criteria[key] = DEFAULT_CRITERIA[key];
            } else {
                parsedCriteria[key] = criteria[key];
            }
        });
        return parsedCriteria;
    };

    closeModalAlert = () => this.setState({modalAlertTitle: null, modalAlertMessage: null});

    closeModalRemedy = () => this.setState({isModalRemedyOpen: false});

    closeModalConfirmRepairDocuments = () => this.setState({isModalConfirmRepairDocumentsOpen: false});

    onSelectValueOfficeChange = ({target: {value: officeId}}) =>
        this.updateCriteriaValue({officeId: parseInt(officeId)});

    onInputValueDenominationChange = ({target: {value: denomination}}) => this.updateCriteriaValue({denomination});

    onInputFilterDenominationChange = ({target: {value: denominationFilter}}) =>
        this.updateCriteriaValue({denominationFilter});

    onInputValueBreederReferenceChange = ({target: {value: breedersReference}}) =>
        this.updateCriteriaValue({breedersReference});

    onInputValueSpeciesChange = speciesName => this.updateCriteriaValue({speciesName});

    onInputFilterSpeciesChange = ({target: {value: speciesNameFilter}}) =>
        this.updateCriteriaValue({speciesNameFilter});

    onInputValueApplicationNumberChange = ({target: {value: applicationNumber}}) => {
        applicationNumber = applicationNumber.substring(0, 10);
        this.updateCriteriaValue({applicationNumber});
    };

    onSpeciesSelectionChange = selectedSpecies =>
        this.updateCriteriaValue({speciesIds: Object.keys(selectedSpecies).join(','), speciesName: ''}, () =>
            this.setState({selectedSpecies})
        );

    onClearFieldsClick = () => {
        this.setState(prev => ({
            responseApplications: null,
            selectedSpecies: {},
            criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
            criteriaCount: 0,
        }));
    };

    onModalConfirmRepairDocumentsActionClick = () => {
        const applicationRequestId = this.state.isModalConfirmRepairDocumentsOpen;
        this.setState(
            prev => ({loading: prev.loading + 1, isModalConfirmRepairDocumentsOpen: false}),
            () => {
                apiOnlineApplicationRequestRepairDocuments(applicationRequestId).then(JSONResponse => {
                    if (JSONResponse && JSONResponse.data === 'OK') {
                        this.setState(prev => ({loading: prev.loading - 1}), this.search);
                    }
                });
            }
        );
    };

    onTableIconRepairClick = ({applicationRequestId}) =>
        this.setState({isModalConfirmRepairDocumentsOpen: applicationRequestId});

    onTableIconRemedyClick = rowObject => this.setState({isModalRemedyOpen: rowObject});

    render() {
        const actions = BackOfficeRequestsCustomTableActionButtons(
            this.onTableIconRepairClick,
            this.onTableIconRemedyClick
        );
        const tableSourceData = utilPrepareTableSource(this.state.responseApplications);

        return (
            <>
                {this.state.modalAlertMessage !== null ? (
                    <ModalAlertVersion2
                        message={this.state.modalAlertMessage}
                        title={this.state.modalAlertTitle}
                        close={this.closeModalAlert}
                    />
                ) : null}
                {this.state.isModalRemedyOpen ? (
                    <ModalBackOfficeRequestsRemedy
                        close={this.closeModalRemedy}
                        application={this.state.isModalRemedyOpen}
                    />
                ) : null}
                {this.state.isModalConfirmRepairDocumentsOpen ? (
                    <ModalConfirmVersion2
                        action={this.onModalConfirmRepairDocumentsActionClick}
                        buttonName={'Yes'}
                        close={this.closeModalConfirmRepairDocuments}
                        message={`Are you sure you want to regenerate the files? This process will take 5 to 10 minutes.`}
                        title={`Repair documents`}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Web Back Office`} />
                <NavigationBackOffice />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div className={styles.mainFieldContainer}>
                            <div style={{clear: 'both'}} />
                            {this.decodedJWT && this.decodedJWT.thirdPartyId === '1' && (
                                <SelectInput
                                    outsideLabel={`Office`}
                                    outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                    notAll={true}
                                    value={this.state.criteria.officeId}
                                    onChange={this.onSelectValueOfficeChange}
                                    loading={this.state.loading}
                                    list={[
                                        {id: 0, value: 'All'},
                                        {id: 1, value: 'CPVO'},
                                        {id: 21, value: 'NAKTUINBOUW'},
                                    ]}
                                />
                            )}
                            <div style={{clear: 'both'}} />
                            <TextInput
                                filter={['equals', 'contains']}
                                currentFilter={this.state.criteria.denominationFilter}
                                onFilterChange={this.onInputFilterDenominationChange}
                                double={true}
                                infoIcon={faInfo}
                                onChange={this.onInputValueDenominationChange}
                                onEnter={this.search}
                                value={this.state.criteria.denomination}
                                popOverText={`The search will find all applications containing what is typed`}
                                outsideLabel={'Denomination'}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                            />
                            <div style={{clear: 'both'}} />
                            <TextInput
                                filter={'Contains'}
                                double={true}
                                infoIcon={faInfo}
                                onChange={this.onInputValueBreederReferenceChange}
                                onEnter={this.search}
                                value={this.state.criteria.breedersReference}
                                popOverText={`The search will find all applications containing what is typed`}
                                outsideLabel={'Breeder’s reference'}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                            />
                            <div style={{clear: 'both'}} />
                            <TextLabelInput
                                filter={['starts', 'contains']}
                                currentFilter={this.state.criteria.speciesNameFilter}
                                onFilterChange={this.onInputFilterSpeciesChange}
                                double={true}
                                onSelectionChange={this.onSpeciesSelectionChange}
                                onChange={this.onInputValueSpeciesChange}
                                onEnter={this.search}
                                value={this.state.criteria.speciesName}
                                selectedElements={this.state.selectedSpecies}
                                delay={300}
                                multiple={true}
                                outsideLabel={'Species'}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                                listByIdName={this.state.speciesListByIdName}
                                nameHash={this.state.speciesNameHash}
                                popover={`The search will find all applications concerned by the specified species`}
                            />
                            <div style={{clear: 'both'}} />
                            <TextInput
                                filter={'Starts with'}
                                placeholder={`e.g. A2022`}
                                infoIcon={faInfo}
                                onChange={this.onInputValueApplicationNumberChange}
                                onEnter={this.search}
                                value={this.state.criteria.applicationNumber}
                                popOverText={`The search will find all applications whose code starts with the entered terms.`}
                                outsideLabel={'Application code'}
                                outsideLabelWidth={OUTSIDE_LABEL_WIDTH}
                            />
                            <div style={{clear: 'both'}} />
                            <Empty oneLine={true} />
                            <Empty oneLine={true} />
                        </div>
                        <FormFooter>
                            <Button clickAction={this.onClearFieldsClick} variation={'secondary'}>
                                {`Clear fields`}
                            </Button>
                            <Button clickAction={() => this.search(false)} icon={'arrowRight'}>
                                {`Search`}
                            </Button>
                        </FormFooter>
                    </FormWrapper>
                    {Array.isArray(this.state.responseApplications) && (
                        <div className={styles.customTableWrap}>
                            <CustomTable
                                version={2}
                                pageNumber={this.state.criteria.pageNumber}
                                {...this.props}
                                tableName={'OnlineApplicationsRequests'}
                                actionName={`Action`}
                                tableType={'OBJECT'}
                                tableSource={tableSourceData}
                                timestamp={this.state.timestamp}
                                dataFilter={null}
                                id={'applicationRequestId'}
                                pagination={true}
                                setLastCursor={null}
                                resultFieldsDefault={SIGNED_DEFAULT_RESULT_FIELDS}
                                intl={this.props.intl}
                                defaultOrder={this.state.criteria.modificationDate}
                                reverseOrder={this.state.criteria.reverse}
                                filterFunctions={null}
                                count={this.state.count}
                                formatFunctions={{
                                    creationDate: FORMAT_DATE_EASY,
                                    modificationDate: FORMAT_DATE_EASY,
                                    signatureDate: FORMAT_DATE_TIME_EASY,
                                }}
                                hideExcelButton={true}
                                headerPopup={{
                                    applicationStatus: {
                                        description: 'Status explanations',
                                        handler: this.showSearchModalInfo,
                                    },
                                }}
                                actions={actions}
                                updateCriteriaValue={this.updateCriteriaValue}
                            />
                        </div>
                    )}
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(BackOfficeRequests));
