import React, {useState} from 'react';
import {Popover} from 'react-tiny-popover';
import styles from './WizardResponseIsDisabledTargetLogicPopover.module.scss';
import {htmlToText} from '~utils/index';
import {IGroup, IGroupQuestionResponse} from '../../../types';

export default function WizardResponseIsDisabledTargetLogicPopover({
    response,
    steps,
}: {
    response: IGroupQuestionResponse;
    steps: IGroup[];
}) {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    let responsesThatHaveLogicDisabledWithTargetTheCurrentResponse: {
        groupOrTemplateOrder: string;
        questionOrder: string;
        response: string;
    }[] = [];
    (steps || []).forEach(step => {
        (step.questions || []).forEach(question => {
            question.responses.forEach(questionResponse => {
                questionResponse.logics.forEach(logic => {
                    if (logic.type === 'disabled' && logic.responseIdDest === response.id) {
                        responsesThatHaveLogicDisabledWithTargetTheCurrentResponse.push({
                            groupOrTemplateOrder: step.order || '',
                            questionOrder: question.order,
                            response: htmlToText(questionResponse.message),
                        });
                    }
                });
            });
        });
    });
    return (
        <Popover
            isOpen={isPopoverOpen}
            positions={'left'}
            onClickOutside={() => null}
            content={() => (
                <div
                    className={styles.customPopover}
                    style={{
                        background: '#e8e8e8',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '4px',
                        maxWidth: '100%',
                        opacity: 0.9,
                        borderColor: 'black',
                        borderWidth: 1,
                        borderStyle: 'solid',
                    }}
                >
                    <div className={styles.popoverTitle}>
                        {`Response "${htmlToText(response.message)}" can be disabled due to`}:
                    </div>
                    <div className={styles.popoverLogicsWrap}>
                        <ul>
                            {(responsesThatHaveLogicDisabledWithTargetTheCurrentResponse || []).map(
                                ({questionOrder, response}) => (
                                    <li
                                        key={`popover-response-logic-target-${response}`}
                                        className={styles.popoverLogic}
                                    >
                                        {`${questionOrder} / ${response} `}
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            )}
        >
            {responsesThatHaveLogicDisabledWithTargetTheCurrentResponse &&
            responsesThatHaveLogicDisabledWithTargetTheCurrentResponse.length > 0 ? (
                <div
                    onMouseEnter={() => setIsPopoverOpen(true)}
                    onMouseLeave={() => setIsPopoverOpen(false)}
                    className={styles.responseLogicsLabel}
                    title={``}
                >
                    D
                </div>
            ) : (
                <div />
            )}
        </Popover>
    );
}
