import React, {useState} from 'react';
import styles from './OtherFormsButton.module.scss';
import {ArrowContainer, Popover} from 'react-tiny-popover';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {buildFormDraftUrl} from '../../../OnlineApplications/utils';
import {getValidatedSteps} from '../../../Wizard/utils';
import {IDraftFormFullListItem, IGroup, IStateResponsesClient} from '../../../../types';

interface OtherFormsButtonProps {
    checkIfFormIsValid: any;
    currentFormId: number;
    forms: IDraftFormFullListItem[];
    goToNextScreen: any;
    groupsQuestion: IGroup[];
    groupsIdsIterationsMap: {[key: string]: number};
    intl: any;
    loading: number;
    responsesClient: IStateResponsesClient;
    tableQuestionsKeysIterationsMap: {[key: string]: number};
}

export default function OtherFormsButton({
    checkIfFormIsValid,
    currentFormId,
    forms,
    goToNextScreen,
    groupsQuestion,
    groupsIdsIterationsMap,
    intl,
    loading,
    responsesClient,
    tableQuestionsKeysIterationsMap,
}: OtherFormsButtonProps) {
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

    const onFormClick = (form: IDraftFormFullListItem) => () => {
        const {questionnaireId, type, iteration, applicationId} = form || {};
        let uri = buildFormDraftUrl({
            applicationId,
            formType: type,
            formId: questionnaireId,
            formSequence: iteration,
        });
        goToNextScreen(uri, true);
    };

    const getFormClassName = (form: IDraftFormFullListItem) => {
        const {questionnaireId, ISVALID} = form || {};
        if (questionnaireId === parseInt(String(currentFormId))) {
            let computedValidateSteps = getValidatedSteps({
                groupsQuestion,
                groupsIdsIterationsMap,
                tableQuestionsKeysIterationsMap,
                responsesClient,
            });
            let currentFormIsValid = checkIfFormIsValid({computedValidateSteps});
            return styles[currentFormIsValid ? 'valid' : 'inPreparation'];
        } else {
            return styles[ISVALID === 1 ? 'valid' : 'inPreparation'];
        }
    };

    return (
        <div className={styles.container}>
            <Popover
                isOpen={isPopoverOpen}
                positions={'top'}
                onClickOutside={() => setIsPopoverOpen(!isPopoverOpen)}
                content={({position, childRect, popoverRect}) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={'#3a924d'}
                        arrowSize={5}
                        arrowStyle={{opacity: 0.7}}
                    >
                        <div className={styles.popupWrap}>
                            <h5>{`Application's Forms`}</h5>
                            <main>
                                {(forms || []).map((form: any) => (
                                    <div
                                        key={form && form.questionnaireId}
                                        className={getFormClassName(form)}
                                        onClick={onFormClick(form)}
                                    >
                                        {form && form.type}: {form && form.name}
                                    </div>
                                ))}
                            </main>
                        </div>
                    </ArrowContainer>
                )}
            >
                <div>
                    <FormFooterButton
                        color={buttonColor.whiteBlack}
                        clickAction={() => setIsPopoverOpen(!isPopoverOpen)}
                        disabled={loading !== 0}
                    >
                        {intl && intl.formatMessage({id: 'button.allForms'})}
                    </FormFooterButton>
                </div>
            </Popover>
        </div>
    );
}
