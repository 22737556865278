import {getFetch, postFetch, API_SERVERLESS, postForm} from '~commonApi';
import {computePostFilesToPreSignedURLPromises, computeGetUploadPreSignedURLPromises} from '../../utils/requests';

export function apiEndOfFiles(criteria, DEFAULT_CRITERIA = {}, excelTranslations) {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/mypvr/v3/endOfFiles?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}

export function apiEndOfFilesDeleteRequest(jsonRequest) {
    return postFetch(`${API_SERVERLESS}/mypvr/v3/endOfFiles/deleteRequest`, true, jsonRequest);
}

export function apiEndOfFilesDraftRequests(jsonRequest) {
    return postFetch(`${API_SERVERLESS}/mypvr/v3/endOfFiles/draftRequests`, true, jsonRequest);
}

export function apiEndOfFilesDownloadDraft(applicationNumber) {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/endOfFiles/downloadDraft/${applicationNumber}`, true);
}

export function apiEndOfFilesSignRequests(jsonRequest, documentList) {
    let fileFolders = {};
    const signRequest = () => {
        const finalJsonRequest = Object.assign({}, jsonRequest);
        delete finalJsonRequest.applications;
        finalJsonRequest.applicationNumbers = jsonRequest.applications.map(i => i.applicationNumber);
        return postFetch(
            `${API_SERVERLESS}/mypvr/v3/endOfFiles/signRequests`,
            true,
            Object.assign({}, finalJsonRequest, {fileFolders, uploads: (documentList || []).length})
        );
    };
    if (Object.keys(documentList).length === 0) {
        return postFetch(`${API_SERVERLESS}/mypvr/v3/endOfFiles/documentUploadCreateFolder`, true, {
            applicationNumbers: jsonRequest.applications.map(application => application.applicationNumber),
        }).then(({folders}) => {
            fileFolders = folders;
            return signRequest();
        });
    } else {
        return postFetch(`${API_SERVERLESS}/mypvr/v3/endOfFiles/documentUploadCreateFolder`, true, {
            applicationNumbers: jsonRequest.applications.map(application => application.applicationNumber),
        })
            .then(({folders}) => {
                fileFolders = folders;
                const uploadArray = [];
                return Promise.all(computeGetUploadPreSignedURLPromises({documentList})).then(
                    getUploadPreSignedURLResponses => {
                        return Promise.all(
                            computePostFilesToPreSignedURLPromises({
                                getUploadPreSignedURLResponses,
                                documentList,
                            })
                        ).then(() => {
                            documentList.forEach((document, index) => {
                                const applicationNumbers = jsonRequest.applications
                                    .filter(application => {
                                        if (document.documentType === 'holdersConsent') {
                                            return application.messageType2;
                                        } else if (document.documentType === 'thirdPartyConsent') {
                                            return application.messageType4;
                                        } else {
                                            return true;
                                        }
                                    })
                                    .map(i => i.applicationNumber);
                                const foldersHash = {};
                                applicationNumbers.forEach(
                                    applicationNumber => (foldersHash[applicationNumber] = folders[applicationNumber])
                                );
                                const formData = new FormData();
                                formData.append('files[]', getUploadPreSignedURLResponses[index].identifier);
                                formData.append('filename', encodeURIComponent(document.filename));
                                formData.append(`documentType`, document.documentType);
                                formData.append(`folders`, JSON.stringify(foldersHash));
                                formData.append(`applicationNumbers`, applicationNumbers.join(','));
                                formData.append(`index`, index);
                                formData.append(`clientIdOnBehalf`, jsonRequest.clientIdOnBehalf);
                                formData.append(`contactIdOnBehalf`, jsonRequest.contactIdOnBehalf);
                                uploadArray.push(
                                    postForm(`${API_SERVERLESS}/mypvr/v3/endOfFiles/documentUploadFile`, true, formData)
                                );
                                return Promise.all(uploadArray);
                            });
                        });
                    }
                );
            })
            .then(signRequest);
    }
}

export function apiEndOfFilesDownloadSigned(applicationNumber) {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/endOfFiles/downloadSigned/${applicationNumber}`, true);
}

export function apiEndOfFilesOnBehalfOfPR(applicationNumber) {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/endOfFileOnBehalfOfPR/${applicationNumber}`, true);
}

export function apiEndOfFilesOnBehalfOfApplicant() {
    return getFetch(`${API_SERVERLESS}/mypvr/v3/endOfFileOnBehalfOfApplicant`, true);
}
