import React from 'react';
import {ModalCustomVersion2} from '../../../../commonModals';
import Link from '~components/Link';
import ImageSMEFund from '../../../../img/sme_fund.png';
import {Button} from '../../../../componentsFormV2';

export default function ({close}: any) {
    return (
        <ModalCustomVersion2
            plus={2}
            header={<h2>{`SME Fund for Community Plant Variety Rights`}</h2>}
            body={
                <div style={{paddingBottom: 20, textAlign: 'center', fontSize: 20}}>
                    <div style={{marginBottom: 20}}>
                        <Link
                            label={<img src={ImageSMEFund} style={{maxWidth: '100%', height: 'auto'}} alt="SME Fund" />}
                            clickAction={() =>
                                window.open(
                                    'https://www.euipo.europa.eu/en/discover-ip/sme-fund/before-applying',
                                    '_blank'
                                )
                            }
                            isInline={true}
                        />
                    </div>
                    <div style={{marginBottom: 10}}>{`Are you an EU or Ukraine based SME?`}</div>
                    <div
                        style={{marginBottom: 10}}
                    >{`Do you know that you can get 75% reimbursement on your online application fee and first year examination fee up to 1500€?`}</div>
                    <div
                        style={{marginBottom: 10}}
                    >{`If you are interested, get the voucher before applying for your CPVR!`}</div>
                    <div style={{marginBottom: 10}}>
                        {`More information on how to request your voucher can be found on the `}
                        <Link
                            label={`SME Fund dedicated website`}
                            clickAction={() =>
                                window.open(
                                    'https://www.euipo.europa.eu/en/discover-ip/sme-fund/before-applying',
                                    '_blank'
                                )
                            }
                            isInline={true}
                        />
                    </div>
                </div>
            }
            footer={<Button variation={'danger'} icon={'close'} clickAction={close}>{`Close`}</Button>}
            close={close}
        />
    );
}
