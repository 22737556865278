import React, {useState} from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import TextAreaInput from '~components/TextAreaInput';
import TextInput from '../../../components/TextInput';
import Checkbox from '../../../components/Checkbox';
import {Button} from '../../../componentsFormV2';

const getLabelMonths = logicParam => {
    if (logicParam && logicParam.includes('months:')) {
        return logicParam.split('months:').pop().split(';')[0];
    }
    return 12;
};

const ModalLogicTypePastDate = ({close, onCreateOrUpdateLogicType, responseLogicValues, responseLogicType}) => {
    const {logicParam, applyToRemark} = responseLogicValues || {};
    const {defaultParam, name} = responseLogicType || {};
    const [paramValue, setParamValue] = useState(logicParam !== undefined ? logicParam : null);
    const onParamValueChange = ({target: {value: param}}) => setParamValue(param);

    const [showInformativeSticker, setShowInformativeSticker] = useState((logicParam || '').includes('showlabel:true'));
    const [labelMonths, setLabelMonths] = useState(getLabelMonths(logicParam));

    const onInputLabelMonthsChange = ({target: {value}}) => {
        setLabelMonths(value <= 0 ? 1 : value);
    };

    const onCreateOrUpdateLogicTypeClick = () => {
        let paramValueMaxDate = paramValue.includes('maxdate:')
            ? `maxdate:${paramValue.split('maxdate:').pop().split(';')[0]}`
            : '';
        let customParamValue = showInformativeSticker ? `showlabel:true;months:${labelMonths};` : '';
        let paramValueWithInformativeLabel = `${paramValueMaxDate};${customParamValue}`;
        onCreateOrUpdateLogicType(paramValueWithInformativeLabel, logicParam !== undefined ? applyToRemark : false);
    };

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    {defaultParam && (
                        <div>
                            <TextAreaInput
                                label="Default Parameters"
                                value={defaultParam}
                                width={550}
                                rows={2}
                                disabled={true}
                            />
                        </div>
                    )}
                    <div>
                        <TextAreaInput
                            label="Parameters"
                            value={paramValue}
                            onChange={onParamValueChange}
                            width={550}
                            rows={2}
                        />
                    </div>
                    <div style={{clear: 'both'}} />
                    <div>
                        <h4 style={{marginBottom: 0}}>{`Date should be no more than x months`}</h4>
                        <Checkbox
                            clickAction={() => setShowInformativeSticker(!showInformativeSticker)}
                            label={`Show informative sticker`}
                            value={showInformativeSticker}
                            double={true}
                        />
                        <div style={{clear: 'both'}} />
                        <div style={{marginTop: 10}}>
                            <TextInput
                                double={true}
                                label={'Label months'}
                                onChange={onInputLabelMonthsChange}
                                value={labelMonths}
                                width={100}
                                type={'number'}
                                disabled={!showInformativeSticker}
                            />
                        </div>
                    </div>
                </>
            }
            footer={
                <>
                    <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                    <Button icon={'arrowRight'} clickAction={onCreateOrUpdateLogicTypeClick}>{`Save`}</Button>
                </>
            }
        />
    );
};

export default ModalLogicTypePastDate;
