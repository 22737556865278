import React from 'react';
import {injectIntl} from 'react-intl';
import styles from './ChangePassword.module.scss';
import {Link, withRouter} from 'react-router-dom';
import {apiChangePassword} from './ChangePasswordService';
import {ModalAlertVersion2, ModalErrorVersion2} from '../../commonModals';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderBreadcrumb,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitle,
} from '../../componentsLayout';
import {Button, TextInput} from '../../componentsFormV2';
import {trackPageView} from '../../utils';

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    enableButton: boolean;
    password: string;
    passwordConfirm: string;
    loginUiError: any;
    login: string;
    errorTitle: string;
    errorMessage: string;
    hidePassword: boolean;
    criteria: any;
    passwordError: boolean;
    confirmPasswordError: boolean;
    loading: number;
    ok: boolean;
}

class ChangePassword extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            enableButton: false,
            password: '',
            passwordConfirm: '',
            loginUiError: null,
            login: '',
            errorTitle: '',
            errorMessage: '',
            hidePassword: true,
            criteria: {},
            passwordError: false,
            confirmPasswordError: false,
            loading: 0,
            ok: false,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'changePassword'});
    }

    checkForm = () =>
        this.state.passwordError ||
        this.state.confirmPasswordError ||
        this.state.password === '' ||
        this.state.passwordConfirm === '';

    closeError = () => this.setState({errorMessage: ''});

    goToWelcome = () => this.props.history.push('/welcome');

    onPasswordChange = ({target: {value: password}}: React.ChangeEvent<HTMLInputElement>) => {
        const passwordValid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(.{6,})$/.test(password);
        const confirmPasswordValid = password === this.state.passwordConfirm;
        this.setState({password, passwordError: !passwordValid, confirmPasswordError: !confirmPasswordValid});
    };

    onPasswordConfirmChange = ({target: {value: passwordConfirm}}: React.ChangeEvent<HTMLInputElement>) => {
        const passwordValid = this.state.password === passwordConfirm;
        this.setState({passwordConfirm, confirmPasswordError: !passwordValid});
    };

    onPasswordVisibilityChange = () => this.setState(prev => ({hidePassword: !prev.hidePassword}));

    onConfirm = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiChangePassword({password: this.state.password})
                    .then((result: any) => {
                        if (result.errorMessage) {
                            this.setState({errorMessage: result.errorMessage});
                        } else {
                            this.setState({ok: true});
                        }
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );

    render() {
        return (
            <>
                {this.state.errorMessage && (
                    <ModalErrorVersion2
                        title={'Change password error'}
                        message={this.state.errorMessage}
                        close={this.closeError}
                    />
                )}
                {this.state.ok && (
                    <ModalAlertVersion2
                        title={'Change password information'}
                        message={'Operation success! You will be redirected to the Welcome page'}
                        close={this.goToWelcome}
                    />
                )}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderBreadcrumb
                    links={[
                        <Link key={'/'} to={'/'}>
                            {'User Area'}
                        </Link>,
                        'My password',
                    ]}
                />
                <HeaderTitle title={`Change password`} />
                <FormWrapper className={styles.formWrapper} paddingY={'lg'}>
                    <TextInput
                        type={this.state.hidePassword ? 'password' : 'text'}
                        onChange={this.onPasswordChange}
                        value={this.state.password}
                        focus={true}
                        placeholder={`Type here your new password`}
                    />
                    <TextInput
                        type={this.state.hidePassword ? 'password' : 'text'}
                        onChange={this.onPasswordConfirmChange}
                        value={this.state.passwordConfirm}
                        placeholder={`Confirm your new password`}
                        style={{marginBottom: 15}}
                    />
                    {this.state.passwordError ? (
                        <div
                            className={styles.error}
                        >{`The password must contain at least one digit, one uppercase character and one lowercase character. Its length should be 6 characters minimum.`}</div>
                    ) : null}
                    {this.state.confirmPasswordError ? (
                        <div className={styles.error}>{`Password does not match`}</div>
                    ) : null}
                    {this.state.loginUiError && <div className={styles.error}>{this.state.loginUiError}</div>}
                    <FormFooter>
                        <Button
                            className={styles.buttonCancelChanges}
                            clickAction={this.goToWelcome}
                            icon={'close'}
                            variation={'danger'}
                        >{`Cancel changes`}</Button>
                        <Button
                            className={styles.buttonSaveChanges}
                            clickAction={this.onConfirm}
                            disabled={this.checkForm()}
                            icon={'tick'}
                        >{`Save changes`}</Button>
                    </FormFooter>
                </FormWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(ChangePassword));
