import React from 'react';
import styles from './ModalWhatsNewVersion2.module.scss';
import ModalCustomVersion2 from './ModalCustomVersion2';
import Button from '../componentsFormV2/Button';
import jsonData from './ModalWhatsNewVersion2.json';

interface IModalWhatsNewVersion2Props {
    close: any;
}

function ModalWhatsNewVersion2({close}: IModalWhatsNewVersion2Props) {
    return (
        <ModalCustomVersion2
            close={close}
            header={`What's new`}
            body={
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>
                                <a>{'Title'}</a>
                            </th>
                            <th style={{width: 697}}>
                                <a>{'Description'}</a>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(jsonData || []).map(row => {
                            return (
                                <tr key={row.title}>
                                    <td className={styles.title}>{row.title}</td>
                                    <td>{row.description}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            }
            footer={<Button clickAction={close} icon={'close'} variation={'danger'}>{`Close`}</Button>}
        />
    );
}

export default ModalWhatsNewVersion2;
