import React, {useEffect, useState} from 'react';
import {ModalCustomVersion2, ModalLabelPreviewVersion2, ModalLabelSearchVersion2} from '../../../commonModals';
import SelectInput from '~components/SelectInput';
import {faLightbulb, faList, faPencilAlt, faPlus, faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import ModalUsedInVersion2, {rowType} from '../../../commonModals/ModalUsedInVersion2';
import {htmlToText} from '~utils/index';
import {injectIntl} from 'react-intl';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import TextInput from '~components/TextInput';
import TextAreaInput from '~components/TextAreaInput';
import ModalLabelVersion2, {getLabelTypeId} from '../../../commonModals/ModalLabelVersion2';
import {Button} from '../../../componentsFormV2';

const ID_NONE = 'NONE';

export const STRING_PARAM_PREFIX_ONLY_IF_RESPONSE_IS_SELECTED = 'onlyIfResponseIsSelected';

export const STRING_PARAM_PREFIX_ONLY_IF_CURRENT_COMBOBOX_ANSWER = 'onlyIfCurrentComboboxAnswer';

const getSelectListForOnlyIfResponseIsSelectedSelectInput = steps => {
    const responsesWithGroupInformation = []
        .concat(...steps.filter(el => el.isTemplate === false).map(step => step.questions))
        .filter(({type}) => ['radio'].includes(type))
        .map(({groupId, id, order, responses}) => ({groupId, questionId: id, questionOrder: order, responses}))
        .filter(el => el.responses.length)
        .map(el => [
            ...el.responses.map(r => ({
                ...r,
                groupId: el.groupId,
                questionOrder: el.questionOrder,
                questionId: el.questionId,
            })),
        ])
        .flat(Infinity);
    return [
        {id: ID_NONE, value: 'Not applicable'},
        ...responsesWithGroupInformation.map(el => ({
            id: `GR${el.groupId}_Q${el.questionId}_R${el.id}`,
            value: `${el.questionOrder}: ${el.message}`,
        })),
    ];
};

const getDefaultSelectInputValueOnlyIfResponseIsSelected = logicParam => {
    let defaultValue = ID_NONE;
    if (logicParam && logicParam.includes(`${STRING_PARAM_PREFIX_ONLY_IF_RESPONSE_IS_SELECTED}:`)) {
        defaultValue =
            logicParam.split(`${STRING_PARAM_PREFIX_ONLY_IF_RESPONSE_IS_SELECTED}:`).pop().split(';')[0] || ID_NONE;
    }
    return defaultValue;
};

const getDefaultTextInputValueOnlyIfCurrentComboboxAnswer = logicParam => {
    let defaultValue = '';
    if (logicParam && logicParam.includes(`${STRING_PARAM_PREFIX_ONLY_IF_CURRENT_COMBOBOX_ANSWER}:`)) {
        defaultValue =
            logicParam.split(`${STRING_PARAM_PREFIX_ONLY_IF_CURRENT_COMBOBOX_ANSWER}:`).pop().split(';')[0] || '';
    }
    return defaultValue;
};

const ModalLogicTypeShowQuestion = ({
    close,
    onCreateOrUpdateLogicType,
    responseLogicValues,
    responseLogicType,
    intl,
    steps,
}) => {
    const {logicParam, messageLink, messageLinkId, questionIdDest, iteration} = responseLogicValues || {};
    const {name} = responseLogicType || {};
    const [selectList, setSeletectList] = useState([]);
    const [selectedQuestionIdDest, setSelectedQuestionIdDest] = useState(null);
    const [modalLabel, setModalLabel] = useState(null);
    const [modalLabelSearch, setModalLabelSearch] = useState(null);
    const [modalPreview, setModalPreview] = useState(null);
    const [modalUsedIn, setModalUsedIn] = useState(null);
    const [labelIdValue, setLabelIdValue] = useState(messageLinkId ? messageLinkId : null);
    const [labelHtml, setLabelHtml] = useState(messageLink ? messageLink : null);
    const [selectInputValueOnlyIfResponseIsSelected, setSelectInputValueOnlyIfResponseIsSelected] = useState(
        getDefaultSelectInputValueOnlyIfResponseIsSelected(logicParam)
    );
    const [textInputOnlyIfCurrentComboboxAnswer, setTextInputOnlyIfCurrentComboboxAnswer] = useState(
        getDefaultTextInputValueOnlyIfCurrentComboboxAnswer(logicParam)
    );
    const [textInputParametersValue, setTextInputParametersValue] = useState(logicParam);

    const selectListForOnlyIfResponseIsAnsweredSelectInput = getSelectListForOnlyIfResponseIsSelectedSelectInput(steps);

    useEffect(() => {
        const propsQuestionsList = [].concat(...steps.map(step => step.questions)).map(question => ({
            id: question.id,
            libelle: `${question.order} - ${htmlToText(question.message)}`,
            remark: question.order,
        }));
        setSeletectList(propsQuestionsList.map(el => ({id: el.id, value: el.libelle})));
        setSelectedQuestionIdDest(questionIdDest ? questionIdDest : propsQuestionsList[0].id);
    }, []);

    const setLabelId = label => {
        const {labelId, valueHTML, labelValueHTML} = label;
        setLabelIdValue(labelId);
        setLabelHtml(valueHTML || labelValueHTML);
    };

    const clearLabelUse = () => {
        setLabelIdValue(null);
        setLabelHtml(null);
    };

    const renderPopupElements = (labelId, typeId, fieldIdKey, fieldHTMLKey) => {
        const elementsArr = [];
        elementsArr.push({
            label: 'Search label',
            icon: faSearch,
            handler: () => setModalLabelSearch({labelId, typeId, fieldIdKey, fieldHTMLKey}),
        });
        !labelId &&
            elementsArr.push({
                label: 'Add label',
                icon: faPlus,
                handler: () => setModalLabel({labelId: 0, typeId, fieldIdKey, fieldHTMLKey}),
            });
        labelId &&
            elementsArr.push({
                label: 'Show label',
                icon: faPencilAlt,
                handler: () => setModalPreview(labelHtml),
            });
        labelId &&
            elementsArr.push({
                label: 'Clear label',
                icon: faTimes,
                handler: () => clearLabelUse(),
            });
        labelId &&
            elementsArr.push({
                label: 'Used in',
                icon: faLightbulb,
                handler: () => setModalUsedIn(labelId),
            });
        return elementsArr;
    };

    const onTextInputParametersChange = ({target: {value: textInputParametersValue}}) =>
        setTextInputParametersValue(textInputParametersValue);

    const onSelectInputOnlyIfResponseIsSelectedChange = ({target: {value}}) => {
        let textInputParametersValueUpdated = textInputParametersValue || '';

        const checkIfParamHasOnlyIfResponseIsSelected = () =>
            (textInputParametersValue || '').includes(STRING_PARAM_PREFIX_ONLY_IF_RESPONSE_IS_SELECTED);

        const computeParamOnlyIfResponseIsSelected = () => {
            return value && value !== ID_NONE ? `${STRING_PARAM_PREFIX_ONLY_IF_RESPONSE_IS_SELECTED}:${value};` : '';
        };

        const removeParamOnlyIfResponseIsSelected = () => {
            const regex = new RegExp(`${STRING_PARAM_PREFIX_ONLY_IF_RESPONSE_IS_SELECTED}:\\w+;`);
            textInputParametersValueUpdated = textInputParametersValueUpdated.replace(regex, '');
        };

        if (checkIfParamHasOnlyIfResponseIsSelected()) {
            removeParamOnlyIfResponseIsSelected();
        }
        if (value !== ID_NONE) {
            textInputParametersValueUpdated = computeParamOnlyIfResponseIsSelected() + textInputParametersValueUpdated;
        }
        setTextInputParametersValue(textInputParametersValueUpdated);
        setSelectInputValueOnlyIfResponseIsSelected(value);
    };

    const onTextInputOnlyIfCurrentComboboxAnswerChange = ({target: {value}}) => {
        let textInputParametersValueUpdated = textInputParametersValue || '';

        const checkIfParamHasOnlyIfCurrentComboboxAnswer = () =>
            (textInputParametersValue || '').includes(STRING_PARAM_PREFIX_ONLY_IF_CURRENT_COMBOBOX_ANSWER);

        const computeParamOnlyIfCurrentComboboxAnswer = () => {
            return value && value !== ID_NONE ? `${STRING_PARAM_PREFIX_ONLY_IF_CURRENT_COMBOBOX_ANSWER}:${value};` : '';
        };

        const removeParamOnlyIfCurrentComboboxAnswer = () => {
            const regex = new RegExp(`${STRING_PARAM_PREFIX_ONLY_IF_CURRENT_COMBOBOX_ANSWER}:\\w+;`);
            textInputParametersValueUpdated = textInputParametersValueUpdated.replace(regex, '');
        };

        if (checkIfParamHasOnlyIfCurrentComboboxAnswer()) {
            removeParamOnlyIfCurrentComboboxAnswer();
        }
        if (value) {
            textInputParametersValueUpdated =
                computeParamOnlyIfCurrentComboboxAnswer() + textInputParametersValueUpdated;
        }
        setTextInputParametersValue(textInputParametersValueUpdated);
        setTextInputOnlyIfCurrentComboboxAnswer(value);
    };

    const onButtonSaveClick = () => {
        onCreateOrUpdateLogicType(
            textInputParametersValue,
            null,
            null,
            selectedQuestionIdDest,
            labelIdValue,
            iteration
        );
    };

    return (
        <>
            <ModalCustomVersion2
                close={close}
                header={name}
                body={
                    <div>
                        <SelectInput
                            label={`Question to show`}
                            value={selectedQuestionIdDest}
                            notAll={true}
                            double={true}
                            onChange={({target: {value}}) => setSelectedQuestionIdDest(value)}
                            list={selectList}
                        />
                        <div style={{clear: 'both'}} />
                        <hr />
                        <h3 style={{margin: ''}}>{`Optional configuration`}</h3>
                        {window.location.href.includes('backOfficeFormQuestions') ? (
                            <SelectInput
                                label={
                                    <>
                                        {`Show `}
                                        <strong>{`only if`}</strong>
                                        {` the following single-choice response is
                                        selected:`}
                                    </>
                                }
                                value={selectInputValueOnlyIfResponseIsSelected}
                                notAll={true}
                                double={true}
                                onChange={onSelectInputOnlyIfResponseIsSelectedChange}
                                list={selectListForOnlyIfResponseIsAnsweredSelectInput}
                            />
                        ) : null}
                        <TextInput
                            label={
                                <>
                                    {`Show `} <strong>{`only if`}</strong>
                                    {` the current combobox answer is:`}
                                </>
                            }
                            double={true}
                            onChange={onTextInputOnlyIfCurrentComboboxAnswerChange}
                            value={textInputOnlyIfCurrentComboboxAnswer}
                        />
                        <TextAreaInput
                            label={`Parameters`}
                            double={true}
                            onChange={onTextInputParametersChange}
                            value={textInputParametersValue}
                            disabled={true}
                            rows={2}
                        />
                        <div style={{marginLeft: 10}}>
                            <DataSheetFormFields
                                label={'Message'}
                                html={labelHtml}
                                double={true}
                                containerClassName="popover-nested"
                                labelIcon={faList}
                                labelPopup={renderPopupElements(
                                    labelIdValue,
                                    getLabelTypeId('Front Office'),
                                    'nameMessage',
                                    'remarklValueHTML'
                                )}
                            />
                        </div>
                        <div style={{clear: 'both'}} />
                    </div>
                }
                footer={
                    <>
                        <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                        <Button icon={'arrowRight'} clickAction={onButtonSaveClick}>{`Save`}</Button>
                    </>
                }
            />
            {modalLabel !== null ? (
                <ModalLabelVersion2
                    intl={intl}
                    label={modalLabel}
                    setLabelId={setLabelId}
                    showModalUsedIn={labelId => setModalUsedIn(labelId)}
                    close={() => setModalLabel(null)}
                />
            ) : null}
            {modalLabelSearch !== null ? (
                <ModalLabelSearchVersion2
                    intl={intl}
                    target={modalLabelSearch}
                    setLabelId={setLabelId}
                    // setRefreshModalSearchFunction={this.setRefreshModalSearchFunction}
                    showModalPreview={valueHTML => setModalPreview(valueHTML)}
                    closeModalPreview={() => setModalPreview(null)}
                    showModalUsedIn={labelId => setModalUsedIn(labelId)}
                    closeModal={() => setModalLabelSearch(null)}
                    showModalLabel={(labelId, typeId, fieldIdKey, fieldHTMLKey) =>
                        setModalLabel({labelId, typeId, fieldIdKey, fieldHTMLKey})
                    }
                    closeModalLabel={() => setModalLabel(null)}
                    close={() => setModalLabelSearch(null)}
                />
            ) : null}
            {modalPreview !== null ? (
                <ModalLabelPreviewVersion2 intl={intl} close={() => setModalPreview(null)} valueHTML={modalPreview} />
            ) : null}
            {modalUsedIn ? (
                <ModalUsedInVersion2
                    elementId={modalUsedIn}
                    intl={intl}
                    type={rowType.FRONT_OFFICE}
                    close={() => setModalUsedIn(null)}
                />
            ) : null}
        </>
    );
};

export default injectIntl(ModalLogicTypeShowQuestion);
