import React, {useEffect, useState} from 'react';
import AdminIcon from '../../../../TLO/CommonComponents/AdminIcon/AdminIcon';
import {Button} from '../../../../../componentsFormV2';
import CustomTable from '~components/CustomTable';
import {Error} from '../../../../../componentsLayout';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {IntlShape} from 'react-intl';
import ModalCasesLinkedWithKeywords from './ModalCasesLinkedWithKeywords';
import {ModalConfirmVersion2, ModalCustomVersion2} from '../../../../../commonModals';
import ModalKeyword from './ModalKeyword';
import TextInput from '~components/TextInput';
import {
    apiCasesLinkedWithKeywordsListFetch,
    apiKeywordDelete,
    apiKeywordEdit,
    apiKeywordSave,
    KeywordElement,
    LinkedCasesElement,
    LinkedCasesListFetchData,
} from '../CaseFormPagePVRCASELAWService';
import {faEdit, faExclamationCircle, faPlus, faSearch, faTimes, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {getUserRoles} from '../../../../TLO/CommonFunctions/CommonFunctions';
import styles from './ModalKeywordsList.module.scss';

const modalScreenTypes = {
    NEW_KEYWORD: 'NEW_KEYWORD',
    MODIFY_KEYWORD: 'MODIFY_KEYWORD',
    CONFIRM_DELETE: 'CONFIRM_DELETE',
    CASES_LINKED_WITH_KEYWORDS: 'CASES_LINKED_WITH_KEYWORDS',
};

interface ModalKeywordsListProps {
    close: () => void;
    getKeywordsList: () => void;
    intl: IntlShape;
    keywordsList: KeywordElement[] | null;
    timestamp: number;
}

const ModalKeywordsList = (props: ModalKeywordsListProps) => {
    const RESULT_FIELDS_DEFAULT = ['keywordId', 'keyword'];
    const userRoles = getUserRoles();
    const [modalScreenAddKeyword, setModalScreenAddKeyword] = useState<string | null>(null);
    const [modalScreenModifyKeyword, setModalScreenModifyKeyword] = useState<string | null>(null);
    const [modalScreenConfirmDeletion, setModalScreenConfirmDeletion] = useState<string | null>(null);
    const [modalScreenCasesLinked, setModalScreenCasesLinked] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [highlightErrors, setHighlightErrors] = useState(false);
    const [timestamp, setTimestamp] = useState(props.timestamp);
    const [count, setCount] = useState<number>(0);
    const [keywordsList, setKeywordsList] = useState<KeywordElement[] | null>(props.keywordsList);
    const [linkedCasesList, setLinkedCasesList] = useState<LinkedCasesElement[] | null>(null);
    const [rowObjectForModal, setRowObjectForModal] = useState<KeywordElement | null>(null);
    const [keywordSearch, setKeywordSearch] = useState<string>('');

    useEffect(() => {
        setKeywordsList(props.keywordsList);
        setTimestamp(Date.now());
        setCount(props.keywordsList ? props.keywordsList?.length : 0);
        setKeywordSearch('');
    }, [props.keywordsList]);

    const getCasesLinkedWithKeywordsList = (rowId: number) => {
        apiCasesLinkedWithKeywordsListFetch(rowId)
            .then((jsonResponse: LinkedCasesListFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.linkedCases) {
                    setLinkedCasesList(jsonResponse.data.linkedCases);
                    if (jsonResponse.data.linkedCases.length <= 0) {
                        apiKeywordDelete(rowId)
                            .then(() => {
                                setErrorMessage(null);
                                props.getKeywordsList();
                            })
                            .catch(error => {
                                setErrorMessage(`error deleting keyword: ${error}`);
                                setHighlightErrors(true);
                            })
                            .finally(() => setModalScreenCasesLinked(null));
                    } else {
                        setModalScreenCasesLinked(modalScreenTypes.CASES_LINKED_WITH_KEYWORDS);
                    }
                }
            })
            .catch(error => {
                setErrorMessage(`Keywords list fetch error: ${error.message}`);
                setHighlightErrors(true);
            })
            .finally(() => setModalScreenConfirmDeletion(null));
    };

    const deleteKeyword = () => {
        if (rowObjectForModal !== null) getCasesLinkedWithKeywordsList(rowObjectForModal.keywordId);
    };

    const closeModal = () => props.close && props.close();

    const openModalKeywordForAddition = (rowObject: KeywordElement) => {
        setRowObjectForModal(null);
        setModalScreenAddKeyword(modalScreenTypes.NEW_KEYWORD);
    };

    const handlerModify = (rowId: number, rowObject: KeywordElement) => {
        if (rowId > 0) {
            setRowObjectForModal(rowObject);
            setModalScreenModifyKeyword(modalScreenTypes.MODIFY_KEYWORD);
        }
    };

    const handlerDelete = (rowId: number, rowObject: KeywordElement) => {
        if (rowId > 0) {
            setRowObjectForModal(rowObject);
            setModalScreenConfirmDeletion(modalScreenTypes.CONFIRM_DELETE);
        }
    };

    const actions: any[] = [];
    actions.push({
        title: `Modify`,
        icon: () => {
            return (
                <a data-content={`Modify`}>
                    <FontAwesomeIcon icon={faEdit as IconDefinition} />
                </a>
            );
        },
        handler: handlerModify,
    });
    actions.push({
        title: `Delete`,
        icon: () => {
            return (
                <a data-content={`Delete`}>
                    <FontAwesomeIcon icon={faTrashAlt as IconDefinition} />
                </a>
            );
        },
        handler: handlerDelete,
    });

    const saveKeyword = (keywordId: number, keyword: string) => {
        if (keywordId > 0) {
            apiKeywordEdit(
                Object.assign(
                    {},
                    {
                        keywordId: keywordId,
                        keyword: keyword,
                    }
                )
            )
                .then(res => {
                    if (res.message.includes('Duplicate')) {
                        setErrorMessage(res.message);
                        setHighlightErrors(true);
                    } else {
                        setErrorMessage(null);
                        props.getKeywordsList();
                    }
                })
                .catch(error => {
                    setErrorMessage(`error editing keyword: ${error.message}`);
                    setHighlightErrors(true);
                })
                .finally(() => setModalScreenModifyKeyword(null));
        } else {
            apiKeywordSave(
                Object.assign(
                    {},
                    {
                        keyword: keyword,
                    }
                )
            )
                .then(res => {
                    if (res.message.includes('Duplicate')) {
                        setErrorMessage(res.message);
                        setHighlightErrors(true);
                    } else {
                        setErrorMessage(null);
                        props.getKeywordsList();
                    }
                })
                .catch(error => {
                    setErrorMessage(`error inserting keyword: ${error.message}`);
                    setHighlightErrors(true);
                })
                .finally(() => setModalScreenAddKeyword(null));
        }
    };

    const onKeywordSearchChange = ({target: {value: keywordSearch}}: React.ChangeEvent<HTMLInputElement>) =>
        setKeywordSearch(keywordSearch);

    const clearKeywordSearch = () => {
        setKeywordSearch('');
        setKeywordsList(props.keywordsList);
        setTimestamp(Date.now());
        setCount(props.keywordsList ? props.keywordsList.length : 0);
    };

    const searchInKeywords = () => {
        const newKeywordsList =
            props.keywordsList &&
            props.keywordsList.filter(el => {
                return el.keyword.includes(keywordSearch);
            });
        setKeywordsList(newKeywordsList);
        setTimestamp(Date.now());
        setCount(newKeywordsList ? newKeywordsList.length : 0);
    };

    const getButtonArea = () => {
        return (
            <>
                <div className={styles.keywordSearchFieldContainer}>
                    <div className={styles.keywordSearchFieldContainer}>
                        <TextInput
                            value={keywordSearch}
                            outsideLabel={'Keyword'}
                            outsideLabelWidth={80}
                            disabled={false}
                            onChange={onKeywordSearchChange}
                            double={false}
                            placeholder={`Please type a keyword`}
                        />
                        <div className={styles.keywordSearchButton}>
                            <span onClick={event => clearKeywordSearch()} title={`Clear File Search`} style={{}}>
                                <a data-content={`Clear File Search`}>
                                    <FontAwesomeIcon icon={faTimes as IconDefinition} />
                                </a>
                            </span>
                        </div>
                        <div className={styles.keywordSearchButton}>
                            <span onClick={event => searchInKeywords()} title={`Search In Files`} style={{}}>
                                <a data-content={`Search In Files`}>
                                    <FontAwesomeIcon icon={faSearch as IconDefinition} />
                                </a>
                            </span>
                        </div>
                    </div>
                    <FormFooterButton color={buttonColor.blue} clickAction={openModalKeywordForAddition} icon={faPlus}>
                        {`New Keyword`}
                        <AdminIcon />
                    </FormFooterButton>
                </div>
            </>
        );
    };

    const getKeywordsListArea = () => {
        return !keywordsList || keywordsList.length === 0 ? (
            <div style={{paddingTop: 20, textAlign: 'left'}}>
                <b style={{color: 'red', textAlign: 'left'}}>
                    <span className="ng-scope">{`No Keywords available`}</span>
                </b>
            </div>
        ) : (
            <div className={styles.keywordsList}>
                <CustomTable
                    version={2}
                    {...props}
                    tableName={'keywordsList'}
                    tableType={'OBJECT'}
                    tableSource={keywordsList}
                    id={'keywordId'}
                    hideExcelButton={true}
                    resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                    intl={props.intl}
                    defaultOrder={'keywordId'}
                    reverseOrder={false}
                    timestamp={timestamp}
                    count={count}
                    actions={userRoles.indexOf('WLAW') !== -1 ? actions : undefined}
                    actionName={userRoles.indexOf('WLAW') !== -1 ? `Action` : undefined}
                    showAdminIcon={userRoles.indexOf('WLAW') !== -1}
                />
            </div>
        );
    };

    return (
        <>
            {modalScreenAddKeyword === modalScreenTypes.NEW_KEYWORD ? (
                <div className={styles.modalKeywordOuter}>
                    <ModalKeyword
                        keywordElement={null}
                        saveKeyword={saveKeyword}
                        close={() => setModalScreenAddKeyword(null)}
                    />
                </div>
            ) : null}
            {modalScreenModifyKeyword === modalScreenTypes.MODIFY_KEYWORD && rowObjectForModal !== null ? (
                <div className={styles.modalKeywordOuter}>
                    <ModalKeyword
                        keywordElement={rowObjectForModal}
                        saveKeyword={saveKeyword}
                        close={() => setModalScreenModifyKeyword(null)}
                    />
                </div>
            ) : null}
            {modalScreenConfirmDeletion === modalScreenTypes.CONFIRM_DELETE ? (
                <ModalConfirmVersion2
                    title={`Delete Keyword`}
                    message={`Are you sure you want to delete this keyword?`}
                    buttonName={'Delete'}
                    action={deleteKeyword}
                    close={() => setModalScreenConfirmDeletion(null)}
                />
            ) : null}
            {modalScreenCasesLinked === modalScreenTypes.CASES_LINKED_WITH_KEYWORDS ? (
                <div className={styles.modalKeywordOuter}>
                    <ModalCasesLinkedWithKeywords
                        intl={props.intl}
                        close={() => setModalScreenCasesLinked(null)}
                        timestamp={timestamp}
                        linkedCasesList={linkedCasesList}
                    />
                </div>
            ) : null}
            <ModalCustomVersion2
                close={closeModal}
                header={`Managing Keywords`}
                body={
                    <div className={styles.managingKeywords}>
                        <div>{getButtonArea()}</div>
                        <div style={{clear: 'both'}} />
                        <div>{getKeywordsListArea()}</div>
                    </div>
                }
                footer={
                    <>
                        {highlightErrors ? (
                            <Error>
                                <FontAwesomeIcon icon={faExclamationCircle as IconDefinition} color={'white'} />
                                {` ${errorMessage}`}
                            </Error>
                        ) : null}
                        <Button clickAction={props.close} icon={'close'} variation={'danger'}>{`Close`}</Button>
                    </>
                }
            />
        </>
    );
};

export default ModalKeywordsList;
