import React from 'react';
import CustomPopoverIcon from '~components/CustomPopoverIcon';
import {DENOMINATION_EDIT_MODE} from './ModalDenominationVersion2';
import {faEye, faPencilAlt, faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const iconHash = {
    eye: <FontAwesomeIcon icon={faEye} />,
    pencil: <FontAwesomeIcon icon={faPencilAlt} />,
    'trash-o': <FontAwesomeIcon icon={faTrash} />,
};

function renderButton(popupContent, handler, icon, hidden) {
    return {
        title: '',
        icon: rowObject =>
            (rowObject.denominationStatus !== 'todayProposal' && (icon === 'pencil' || icon === 'trash-o')) ||
            (icon === 'list-alt' && 'NWRT'.indexOf(rowObject.applicationStatus) !== -1) ? (
                <span>{}</span>
            ) : (
                <CustomPopoverIcon {...{popupContent, handler, icon: iconHash[icon], hidden}} />
            ),
        handler,
        hidden,
    };
}

export default function MyPVRDenominationsActionButtons(props, showModalDenominationScreen, deleteDenomination) {
    const buttons = [];
    buttons.push(
        renderButton(
            'View',
            (documentId, rowObject) => rowObject && showModalDenominationScreen(rowObject, DENOMINATION_EDIT_MODE.VIEW),
            'eye'
        )
    );
    buttons.push(
        renderButton(
            'Modify',
            (documentId, rowObject) => rowObject && showModalDenominationScreen(rowObject, DENOMINATION_EDIT_MODE.EDIT),
            'pencil'
        )
    );
    buttons.push(
        renderButton(
            'Delete',
            (documentId, rowObject) => rowObject && deleteDenomination(rowObject.applicationNumber),
            'trash-o'
        )
    );
    return buttons;
}
