import React from 'react';
import {Button} from '../../../../componentsFormV2';
import {CaseElement} from '../../CommonInterfaces/CommonInterfaces';
import DateInput from '~components/DateInput';
import {IntlShape} from 'react-intl';
import InputContainer from '~componentsForm/InputContainer';
import Label from '~components/Label';
import TextInput from '~components/TextInput';
import TextAreaInput from '~components/TextAreaInput';
import {ModalCustomVersion2} from '../../../../commonModals';
import {formatDate} from '~components/FormatFunctions';
import styles from './ModalCaseDelete.module.scss';

interface ModalCaseDeleteProps {
    intl: IntlShape;
    caseElement: CaseElement | null;
    delete: () => void;
    downloadFile: (fileName: string) => void;
    close: () => void;
}

const ModalCaseDelete = (props: ModalCaseDeleteProps) => {
    const caseName = props.caseElement?.caseName || '';
    const origin = props.caseElement?.countryName || '';
    const decisionLanguage = props.caseElement?.languageName || '';
    const decisionNumber = props.caseElement?.decisionNumber || '';
    const decisionDate = props.caseElement?.decisionDate || '';
    const publicationNumber = props.caseElement?.publicationNumber || '';
    const publicationDate = props.caseElement?.publicationDate || '';
    const decidingBody = props.caseElement?.decidingBody || '';
    const keywords = props.caseElement?.keywords || '';
    const parties = props.caseElement?.parties || '';
    const abstractInput = props.caseElement?.abstractInput || '';
    const summaryFileName = props.caseElement?.summaryFile || '';
    const decisionFileName = props.caseElement?.decisionFile || '';
    const decisionTranslationFile = props.caseElement?.decisionTranslationFile || '';

    const closeModal = () => props.close && props.close();

    const downloadFile = (fileName: string) => props.downloadFile && props.downloadFile(fileName);

    return (
        <ModalCustomVersion2
            close={closeModal}
            header={`Delete Case`}
            headerSubtitle={`Are you sure you want to delete this case?`}
            body={
                <>
                    <TextInput
                        value={caseName}
                        outsideLabel={'Case Name'}
                        outsideLabelWidth={160}
                        disabled={true}
                        double={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextInput
                        value={origin}
                        outsideLabel={'Origin'}
                        outsideLabelWidth={160}
                        disabled={true}
                        double={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextInput
                        value={decisionLanguage}
                        outsideLabel={'Decision Language'}
                        outsideLabelWidth={160}
                        disabled={true}
                        double={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextInput
                        value={decisionNumber}
                        outsideLabel={'Decision Number'}
                        outsideLabelWidth={160}
                        disabled={true}
                        double={true}
                    />
                    <div style={{clear: 'both'}} />
                    <DateInput
                        inputValueFrom={formatDate(decisionDate)}
                        outsideLabel={'Decision Date'}
                        outsideLabelWidth={160}
                        simple={true}
                        disabled={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextInput
                        value={publicationNumber}
                        outsideLabel={'Publication Number'}
                        outsideLabelWidth={160}
                        disabled={true}
                        double={true}
                    />
                    <div style={{clear: 'both'}} />
                    <DateInput
                        inputValueFrom={formatDate(publicationDate)}
                        outsideLabel={'Publication Date'}
                        outsideLabelWidth={160}
                        simple={true}
                        disabled={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextInput
                        value={decidingBody}
                        outsideLabel={'Deciding Body'}
                        outsideLabelWidth={160}
                        disabled={true}
                        double={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextAreaInput
                        outsideLabel={`Keywords`}
                        outsideLabelWidth={160}
                        value={keywords}
                        double={true}
                        disabled={true}
                        rows={5}
                    />
                    <div style={{clear: 'both'}} />
                    <TextInput
                        value={parties}
                        outsideLabel={'Parties'}
                        outsideLabelWidth={160}
                        disabled={true}
                        double={true}
                    />
                    <div style={{clear: 'both'}} />
                    <TextAreaInput
                        outsideLabel={`Abstract`}
                        outsideLabelWidth={160}
                        value={abstractInput}
                        double={true}
                        disabled={true}
                        rows={5}
                    />
                    <div style={{clear: 'both'}} />
                    <>
                        <Label width={160}>{`Files`}</Label>
                        <InputContainer double={true} noIcon={true}>
                            {summaryFileName.length > 0 ? (
                                <div title="Download Summary Document">
                                    <a onClick={() => downloadFile(summaryFileName)}>{`Download Summary`}</a>
                                </div>
                            ) : null}
                            {decisionTranslationFile.length > 0 ? (
                                <div title="Download Complete Decision Document">
                                    <a
                                        onClick={() => downloadFile(decisionTranslationFile)}
                                    >{`Download Complete Decision`}</a>
                                </div>
                            ) : null}
                            {decisionFileName.length > 0 ? (
                                <div title="Download Complete Decision English Document">
                                    <a
                                        onClick={() => downloadFile(decisionFileName)}
                                    >{`Download Complete Decision (English)`}</a>
                                </div>
                            ) : null}
                            {summaryFileName.length === 0 &&
                            decisionFileName.length === 0 &&
                            decisionTranslationFile.length === 0 ? (
                                <div className={styles.noFilesAvailable}>{`No files available`}</div>
                            ) : null}
                        </InputContainer>
                    </>
                    <div style={{clear: 'both'}} />
                </>
            }
            footer={
                <>
                    <Button clickAction={props.close} variation={'secondary'}>{`No`}</Button>
                    <Button clickAction={props.delete}>{`Delete`}</Button>
                </>
            }
        />
    );
};

export default ModalCaseDelete;
