import React from 'react';
import {SELECT_INPUT_REGISTER_TYPE_OPTIONS} from './VFRegister.config';
import {Button} from '../../componentsFormV2';
import CustomTable from '~components/CustomTable';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationVarietyFinder from '../../shared/NavigationVarietyFinder';
import SelectInput from '~components/SelectInput';
import VFModalRegister from '../VFModalRegister/VFModalRegister';
import {computeRegisterLastContribution, getRegistersQuery} from '../../utils/utilsVarietyFinder';
import {computeTableValueCountry, computeTableValueRegisterType} from './VFRegister.utils';
import graphQLClientInstance from '../../utils/axiosGraphQLClient';
import {injectIntl} from 'react-intl';
import styles from './VFRegisters.module.scss';
import {withRouter} from 'react-router-dom';
import {trackPageView} from '../../utils';
import XLSX from 'xlsx';

const TABLE_NAME = 'varietyFinderRegisters';

const OPTION_ID_ALL = 'All';

const RESULT_FIELDS_ALL = ['country', 'registerType', 'registerName', 'organization', 'lastContribution'];

const DEFAULT_COUNT = 0;

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

const DEFAULT_CRITERIA = {
    country: OPTION_ID_ALL,
    pageNumber: 1,
    pageSize: parseInt(localStorage.getItem(`pageSize${TABLE_NAME}`) || '50'),
    order: 'lastContribution',
    reverse: true,
    refresh: false,
    registerType: OPTION_ID_ALL,
};

interface IResponseRegister {
    addressformatted: string;
    all_contributions: {
        pub_date: string | null;
        pub_info: string | null;
        pub_number: string | null;
    }[];
    countryid: string;
    email: string;
    name: string;
    publicationname: string;
    publicationtypeid: string;
    remarks: string;
    websiteurl: string;
}

export interface ITableRegister extends IResponseRegister {
    lastContribution: string;
}

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    count: number;
    criteria: TDefaultCriteria;
    loading: number;
    modalRegisterDetails: ITableRegister | null;
    responseRegisters: any;
    selectInputCountryList: {id: string; value: string}[];
    screenLoaded: boolean;
    timestamp: number;
}

class VFRegisters extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            count: DEFAULT_COUNT,
            criteria: {...DEFAULT_CRITERIA},
            loading: 0,
            modalRegisterDetails: null,
            responseRegisters: [],
            selectInputCountryList: [],
            screenLoaded: false,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'Registers'});
        this.loadJSONs();
    }

    loadJSONs = () =>
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                criteria: {...prev.criteria, pageSize: parseInt(localStorage.getItem(`pageSize${TABLE_NAME}`) || '50')},
            }),
            () => {
                Promise.all([this.loadCountries(), this.searchPromisified()]).then(() =>
                    this.setState(prev => ({
                        loading: prev.loading - 1,
                    }))
                );
            }
        );

    loadCountries = () =>
        new Promise(resolve => {
            graphQLClientInstance
                .post('/graphql', {
                    query: `{ Countries{iseumember countryid countryname} }`,
                })
                .then((JSONResponse: any) => {
                    if (
                        JSONResponse &&
                        JSONResponse.data &&
                        JSONResponse.data.data &&
                        JSONResponse.data.data.Countries
                    ) {
                        const countryList = JSONResponse.data.data.Countries.map((country: any) => {
                            return {
                                id: country.countryid,
                                value: `${country.countryname} - ${country.countryid}`,
                            };
                        });
                        const euValue = {
                            id: 'QZ',
                            value: 'European Union - QZ',
                        };
                        countryList.unshift(euValue);
                        this.setState({selectInputCountryList: countryList});
                    }
                    resolve([]);
                });
        });

    updateCriteriaValue = (criteriaValue: any, callback?: any, refresh?: any) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = criteriaValue.pageNumber && prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.criteria.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse !== undefined && prev.criteria.reverse !== criteriaValue.reverse;
                return {...prev, criteria};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    searchPromisified = () => new Promise(resolve => this.search(false, () => resolve([])));

    search = (refresh?: boolean, callback?: any) => {
        const values: any = {
            countryid: '',
            registerType: '',
        };
        this.state.criteria.registerType &&
            this.state.criteria.registerType !== OPTION_ID_ALL &&
            (values.registerType = this.state.criteria.registerType);
        this.state.criteria.country &&
            this.state.criteria.country !== OPTION_ID_ALL &&
            (values.countryid = this.state.criteria.country);
        const tablePageSize = this.state.criteria.pageSize;
        const tablePageIndex = this.state.criteria.pageNumber - 1;
        const tableSortBy: any = [];
        if (this.state.criteria.order) {
            const columnsMap: any = {
                country: 'countryid',
                registerType: 'publicationtypeid',
                registerName: 'publicationname',
                organization: 'name',
                lastContribution: 'all_contributions[0].pub_date',
            };
            tableSortBy.push({id: columnsMap[this.state.criteria.order], desc: this.state.criteria.reverse});
        }
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                graphQLClientInstance
                    .post('/graphql', getRegistersQuery(values, tablePageSize, tablePageIndex, tableSortBy))
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.registersSearch &&
                            JSONResponse.data.data.registersSearch.data
                        ) {
                            const {data, total} = JSONResponse.data.data.registersSearch || {};
                            const responseRegisters = data.map((responseRegister: any) => {
                                // eslint-disable-next-line camelcase
                                const {countryid, name, publicationtypeid, publicationname, all_contributions} =
                                    responseRegister || {};
                                return {
                                    ...responseRegister,
                                    country: computeTableValueCountry(countryid),
                                    registerType: computeTableValueRegisterType(publicationtypeid),
                                    registerName: publicationname,
                                    organization: name,
                                    lastContribution: computeRegisterLastContribution(all_contributions),
                                };
                            });
                            this.setState(
                                prev => ({
                                    count: total,
                                    loading: prev.loading - 1,
                                    responseRegisters,
                                    screenLoaded: true,
                                    timestamp: Date.now(),
                                }),
                                () => callback && callback()
                            );
                        }
                    });
            }
        );
    };

    resetCriteria = () =>
        this.setState({
            criteria: {...DEFAULT_CRITERIA},
            timestamp: Date.now(),
        });

    printExcel = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                graphQLClientInstance.post('/graphql', getRegistersQuery({}, 1000, 0)).then((JSONResponse: any) => {
                    if (
                        JSONResponse &&
                        JSONResponse.data &&
                        JSONResponse.data.data &&
                        JSONResponse.data.data.registersSearch &&
                        JSONResponse.data.data.registersSearch.data
                    ) {
                        const {data} = JSONResponse.data.data.registersSearch || {};
                        const responseRegisters = data.map((responseRegister: any) => {
                            // eslint-disable-next-line camelcase
                            const {countryid, name, publicationtypeid, publicationname, all_contributions} =
                                responseRegister || {};
                            return {
                                ...responseRegister,
                                country: computeTableValueCountry(countryid),
                                lastContribution: computeRegisterLastContribution(all_contributions),
                                organization: name,
                                registerType: computeTableValueRegisterType(publicationtypeid),
                                registerName: publicationname,
                            };
                        });
                        const resultGrid: any = [
                            ['Country', 'Register Type', 'Register Name', 'Organization', 'Last Contribution'],
                        ];
                        responseRegisters.forEach(
                            ({country, registerType, registerName, lastContribution, organization}: any) =>
                                resultGrid.push([country, registerType, registerName, organization, lastContribution])
                        );
                        const workSheet = XLSX.utils.aoa_to_sheet(resultGrid);
                        const workBook = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(workBook, workSheet, 'Registers');
                        XLSX.writeFile(workBook, `Registers.xlsx`);
                        this.setState(prev => ({
                            loading: prev.loading - 1,
                        }));
                    }
                });
            }
        );

    closeModalRegisterDetails = () => this.setState({modalRegisterDetails: null});

    onSelectInputCountryChange = ({target: {value: country}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({country, pageNumber: 1});

    onSelectInputRegisterTypeChange = ({target: {value: registerType}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({registerType, pageNumber: 1});

    onLinkButtonHelpClick = () => window.open('/help/en/Registers.html', '_blank');

    onTableRowClick = (_: any, rowObject: ITableRegister) => this.setState({modalRegisterDetails: rowObject});

    onHeaderSearchLinkClick = (searchListItem: any) =>
        this.props.history.push({
            pathname: '/varieties',
            state: {searchListItem},
        });

    render() {
        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                {this.state.modalRegisterDetails ? (
                    <VFModalRegister
                        close={this.closeModalRegisterDetails}
                        responseRegister={this.state.modalRegisterDetails}
                    />
                ) : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Variety Finder`} hostApplication={'Variety Finder'} />
                <NavigationVarietyFinder onSearchLinkClick={this.onHeaderSearchLinkClick} />
                <MainWrapper bgGray={true}>
                    <FormWrapper paddingFormContent={'sm'} formInnerWidth={'lg'}>
                        <SelectInput
                            label={`Country`}
                            half={true}
                            value={this.state.criteria.country}
                            onChange={this.onSelectInputCountryChange}
                            loading={this.state.loading}
                            list={this.state.selectInputCountryList}
                            size={'lg'}
                        />
                        <SelectInput
                            popOverTextFirstRow={
                                <div
                                    /* eslint-disable-next-line react/no-danger */
                                    dangerouslySetInnerHTML={{
                                        __html: `Register type:<br>-  PBR: Right granted to the breeder of a new plant variety giving the holder exclusive control over the propagation of the variety for a number of years over a certain territory<br>- PLP: Alternative system of protection of varieties<br>- EUTM: Registered trademarks (living plants, class 31) at EUIPO<br>- NLI: Varieties of agricultural and vegetable species eligible for marketing<br>- FRU: Frumatis (EU Commission's Fruit Reproductive Material Information)<br>- COM: Varieties present in a commercial register<br>- ZZZ: Others types of registers<br>- REF : Temporary list of varieties for which an official denomination’s proposal has been received.`,
                                    }}
                                />
                            }
                            noInfo={true}
                            popOverWidth={450}
                            half={true}
                            label={`Register Type`}
                            value={this.state.criteria.registerType}
                            onChange={this.onSelectInputRegisterTypeChange}
                            loading={this.state.loading}
                            list={SELECT_INPUT_REGISTER_TYPE_OPTIONS}
                            size={'lg'}
                        />
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            <Button clickAction={this.onLinkButtonHelpClick} variation={'secondary'}>{`Help`}</Button>
                            <Button clickAction={this.resetCriteria} variation={'secondary'}>{`Clear fields`}</Button>
                            <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    <div style={{clear: 'both'}} />
                    <div>
                        {this.state.screenLoaded ? (
                            <>
                                <div style={{marginBottom: -12}}>
                                    <div
                                        className={styles.tableHeaderCounterTitle}
                                    >{`${this.state.count} results matching your criteria`}</div>
                                </div>
                                <CustomTable
                                    count={this.state.count}
                                    defaultOrder={this.state.criteria.order}
                                    formatFunctions={{
                                        registerName: (registerName: string) => {
                                            return (
                                                <div
                                                    style={{
                                                        color: '#255899',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {registerName}
                                                </div>
                                            );
                                        },
                                    }}
                                    id={'communicationId'}
                                    intl={this.props.intl}
                                    isNavigationButtonCompactOrDefaultViewEnabled={true}
                                    pageNumber={this.state.criteria.pageNumber}
                                    pageSize={this.state.criteria.pageSize}
                                    pagination={true}
                                    printExcel={this.printExcel}
                                    resultFieldsDefault={RESULT_FIELDS_ALL}
                                    reverseOrder={this.state.criteria.reverse}
                                    rowClick={this.onTableRowClick}
                                    tableName={TABLE_NAME}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.responseRegisters}
                                    timestamp={this.state.timestamp}
                                    updateCriteriaValue={this.updateCriteriaValue}
                                    version={2}
                                    hoverGrey={true}
                                    navigationBarWider={true}
                                    fontSize={14}
                                />
                            </>
                        ) : null}
                        <div style={{clear: 'both'}} />
                    </div>
                </MainWrapper>
                <Footer hostApplication={'Variety Finder'} />
            </>
        );
    }
}

export default withRouter(injectIntl(VFRegisters));
