import React from 'react';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {faArchive, faEnvelope, faExclamation, faPaperclip, faUser} from '@fortawesome/free-solid-svg-icons';
import CustomTable from '~components/CustomTable';
import DateInput from '~components/DateInput';
import {FORMAT_DATE_TIME_EASY, reformatDateEasy} from '../../components/FormatFunctions';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import FormFooterLinkButton from '~componentsForm/FormFooterLinkButton';
import {getDecodedJWT, loadUrlParams, trackPageView, utilCalculateTableMaxPossiblePage} from '../../utils';
import CCModalInternalHistory from '../CCModalInternalHistory/CCModalInternalHistory';
import CCModalInternalRead, {TABS} from '../CCModalInternalRead/CCModalInternalRead';
import CCModalInternalReply from '../CCModalInternalReply/CCModalInternalReply';
import CCModalInternalReplyBOA from '../CCModalInternalReplyBOA/CCModalInternalReplyBOA';
import CCModalInternalCompose from '../CCModalInternalCompose/CCModalInternalCompose';
import CCInternalInboxActionButtons from './CCInternalInboxActionButtons';
import {
    apiCommunicationCentreInternalFlagMessage,
    apiCommunicationCentreInternalInboxClientsList,
    apiCommunicationCentreInternalInboxMarkMessagesNotRead,
    apiCommunicationCentreInternalInboxMarkMessagesRead,
    apiCommunicationCentreInternalInboxMessageArchive,
    apiCommunicationCentreInternalSearchInbox,
    apiCommunicationCentreInternalUnFlagMessage,
    apiCommunicationCentreSearchList,
} from './CCInternalInboxService';
import {
    CASEHOLDERS_SHORTKEYS,
    getSelectListOfDirectory,
    USER_SHORTKEY_TO_CONTACTID,
} from '../CommunicationCentreInternal/CommunicationCentreInternal.utils';
import {MODAL_COMPOSE_MESSAGE_ORIGIN_SCREENS} from '../MyPVRAdminProceedings/modals';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ApplicationNumbersMultiple from '../../components/ApplicationNumbersMultiple';
import {ModalApplicationNumbers, ModalConfirmVersion2} from '../../commonModals';
import {
    IClientsList,
    ICommunicationMessageInternal,
    ICommunicationMessageInternalRead,
    ICommunicationMessagesInternalSearchResults,
} from '../../types';
import CCModalInternalSaveSearch from '../CCModalInternalSaveSearch/CCModalInternalSaveSearch';
import ModalPreview from '../CommunicationCentreInternal/modals/ModalPreview';
import PROFILES_DIRECTORIES_SUBTYPES_INTERNAL from '../CommunicationCentreInternal/modals/DATA/PROFILES_DIRECTORIES_SUBTYPES_INTERNAL.json';
import styles from './CCInternalInbox.module.scss';
import {faEnvelopeOpen} from '@fortawesome/free-regular-svg-icons';
import CPVOIcon from '../../shared/img/cpvo.svg';
import {WORKFLOW} from '../../utils/constants';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationCommunicationCentre from '../../shared/NavigationCommunicationCentre';
import {Button} from '../../componentsFormV2';

export const RESULT_FIELDS_DEFAULT_INBOX_CPVO = [
    'from',
    'messageSubject',
    'replyBy',
    'applicationNumbers',
    'receivedDate',
];

export const RESULT_FIELDS_ALL_INBOX_CPVO = [
    'from',
    'addressees',
    'messageSubject',
    'docmanDirectory',
    'replyBy',
    'CPVOColleagues',
    'applicationNumbers',
    'receivedDate',
];

export const documentTypes = Object.values(PROFILES_DIRECTORIES_SUBTYPES_INTERNAL)
    .flat(1)
    .map(el => ({
        id: `${el.docTypeId}`,
        value: `${el.dirName}: ${el.docTypeName}`,
    }));

const DATEFORMAT_CRITERIA_KEYS = ['arrivalDateFrom', 'arrivalDateTo'];

const DIRECTORIES_IDS_WHICH_ENABLE_CASE_HOLDER = ['10', '11', '12'];

export type TDefaultCriteria = typeof DEFAULT_CRITERIA;

type TKeyOfDefaultCriteria = keyof TDefaultCriteria;

export const DEFAULT_CRITERIA = {
    pageNumber: 1,
    pageSize: 10,
    order: 'sentDate',
    reverse: true,
    refresh: false,
    excel: false,
    applicationNumber: '',
    flag: '-1',
    replyBy: 'All',
    cc: 'All',
    annotations: 'All',
    arrivalDateFrom: '',
    arrivalDateTo: '',
    readingStatus: 'All',
    sendingDateFrom: '',
    sendingDateTo: '',
    searchTerm: '',
    directory: '',
    caseHolder: '',
    todoBy: '',
    documentType: '',
    from: '',
};

const SELECT_INPUT_LIST_FLAG = [
    {id: '-1', value: 'All'},
    {id: '1', value: 'Flagged'},
    {id: '0', value: 'Not Flagged'},
];

const getLabelInputNameHashByListIdName = (list: {ID: string; NAME: string}[]) => {
    const nameHash: any = {};
    list.forEach(({ID, NAME}) => {
        nameHash[NAME] = [ID];
    });
    return nameHash;
};

const getListIdNameByListIdValue = (list: {id: string; value: string}[]) =>
    list.map(({id, value}) => ({
        ID: id,
        NAME: value,
    }));

// const toDoByList = Object.keys(USER_SHORTKEY_TO_CONTACTID).map(shortKey => ({
//     id: shortKey,
//     value: `${shortKey}${CASEHOLDERS_SHORTKEYS.includes(shortKey) ? ' - Caseholder' : ''}`,
// }));

const todoByList = CASEHOLDERS_SHORTKEYS.map(shortKey => ({
    id: shortKey,
    value: shortKey,
}));

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    clientsListByIdName: {
        ID: string;
        NAME: string;
    }[];
    clientsName: string;
    clientsNameHash: any;
    count: number;
    communicationIdsSelected: string[];
    criteria: TDefaultCriteria;
    isModalCPVOLogsOpen: boolean;
    isModalConfirmNotReplyingToColleagueOpen: ICommunicationMessageInternal | boolean;
    isModalHistoryOpen: number | false;
    isModalSaveSearchOpen: boolean;
    loading: number;
    messages: ICommunicationMessageInternal[];
    modalApplicationNumbers: string[];
    modalInternalMessageCompose: boolean;
    modalInternalMessageRead: number | false;
    modalFullScreenMessageReadAndPreviewAttachments: number | false;
    modalInternalMessageReadInitialActiveTab: string;
    modalInternalMessageReply: number | false;
    modalInternalMessageReplyBOA: {
        applicationNumbers: string[];
        communicationId: number;
        from: string;
        requestorThirdPartyId: number;
        requestorThirdPartyIdRole: string | null;
        docmanDirectory: string;
    } | null;
    selectedClients: any;
    screenLoaded: boolean;
    timestamp: number;
    viewSearchTermOnly: boolean;
    textLabelInputDirectoryValue: string;
    textLabelInputDirectorySelectedElements: {[key: string]: string};
    textLabelInputCaseHolderValue: string;
    textLabelInputCaseHolderSelectedElements: {[key: string]: string};
    textLabelInputToDoByValue: string;
    // textLabelInputToDoBySelectedElements: {[key: string]: string};
    textLabelInputDocumentTypeValue: string;
    textLabelInputDocumentTypeSelectedElements: {[key: string]: string};
    isModalPreviewOpen: boolean;
    modalPreviewURL: string;
    modalPreviewMessage: ICommunicationMessageInternalRead | null;
    modalPreviewFirstCommunicationId: number | null;
    modalPreviewLastPageResultSetSize: number | null;
}

class CCInternalInbox extends React.Component<IProps, IState> {
    decodedJWT = getDecodedJWT();
    textLabelInputDirectoryListByIdName: {
        ID: string;
        NAME: string;
    }[] = getListIdNameByListIdValue(getSelectListOfDirectory(this.decodedJWT));
    textLabelInputDirectoryNameHash: {[key: string]: string} = getLabelInputNameHashByListIdName(
        getListIdNameByListIdValue(getSelectListOfDirectory(this.decodedJWT))
    );
    textLabelInputCaseHolderListByIdName: {
        ID: string;
        NAME: string;
    }[] = getListIdNameByListIdValue(todoByList);
    textLabelInputCaseHolderNameHash: {[key: string]: string} = getLabelInputNameHashByListIdName(
        getListIdNameByListIdValue(todoByList)
    );

    // textLabelInputToDoByListByIdName: {
    //     ID: string;
    //     NAME: string;
    // }[] = getListIdNameByListIdValue(toDoByList);
    // textLabelInputToDoByNameHash: {[key: string]: string} = getLabelInputNameHashByListIdName(
    //     getListIdNameByListIdValue(toDoByList)
    // );
    textLabelInputDocumentTypeListByIdName: {
        ID: string;
        NAME: string;
    }[] = getListIdNameByListIdValue(documentTypes);
    textLabelInputDocumentTypeNameHash: {[key: string]: string} = getLabelInputNameHashByListIdName(
        getListIdNameByListIdValue(documentTypes)
    );

    constructor(props: IProps) {
        super(props);
        this.state = {
            clientsListByIdName: [],
            clientsName: '',
            clientsNameHash: {},
            count: 0,
            communicationIdsSelected: [],
            criteria: Object.assign({}, DEFAULT_CRITERIA),
            isModalCPVOLogsOpen: false,
            isModalConfirmNotReplyingToColleagueOpen: false,
            isModalHistoryOpen: false,
            isModalSaveSearchOpen: false,
            loading: 0,
            messages: [],
            modalApplicationNumbers: [],
            modalInternalMessageCompose: false,
            modalInternalMessageRead: false,
            modalInternalMessageReadInitialActiveTab: TABS.MESSAGE,
            modalInternalMessageReply: false,
            modalInternalMessageReplyBOA: null,
            modalFullScreenMessageReadAndPreviewAttachments: false,
            selectedClients: {},
            screenLoaded: false,
            timestamp: Date.now(),
            viewSearchTermOnly: false,
            textLabelInputDirectoryValue: '',
            textLabelInputDirectorySelectedElements: {},
            textLabelInputCaseHolderValue: '',
            textLabelInputCaseHolderSelectedElements: {},
            textLabelInputToDoByValue: '',
            // textLabelInputToDoBySelectedElements: {},
            textLabelInputDocumentTypeValue: '',
            textLabelInputDocumentTypeSelectedElements: {},
            isModalPreviewOpen: false,
            modalPreviewURL: '',
            modalPreviewMessage: null,
            modalPreviewFirstCommunicationId: null,
            modalPreviewLastPageResultSetSize: null,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'communicationCentreInternalInbox'});
        const params: any = loadUrlParams(DATEFORMAT_CRITERIA_KEYS);
        const URLCriteria: any = {};
        Object.entries(params).forEach(([fieldName, fieldValue]) => {
            if (Object.keys(DEFAULT_CRITERIA).includes(fieldName)) {
                URLCriteria[fieldName] = fieldValue;
            }
        });
        this.loadJSONs().then(() => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiCommunicationCentreSearchList()
                        .then(JSONResponse => {
                            if (JSONResponse && JSONResponse.data) {
                                let defaultSearch = (JSONResponse.data.searches || []).find(el => el.isDefault);
                                if (defaultSearch) {
                                    const {fields} = defaultSearch || {};
                                    const savedSearchCriteria: any = {};
                                    fields.forEach(({fieldName, fieldValue}) => {
                                        if (Object.keys(DEFAULT_CRITERIA).includes(fieldName)) {
                                            savedSearchCriteria[fieldName] = fieldValue;
                                        }
                                    });
                                    const criteria = {
                                        ...savedSearchCriteria,
                                        ...URLCriteria,
                                    };
                                    this.updateCriteriaValue(criteria, () =>
                                        this.search(false, false, () =>
                                            this.setState({
                                                screenLoaded: true,
                                                ...this.computeTextLabelInputValuesFromCriteria(criteria),
                                            })
                                        )
                                    );
                                }
                            }
                        })
                        .then(() => this.setState(prev => ({loading: prev.loading - 1})));
                }
            );
        });
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (prevState.criteria.directory !== this.state.criteria.directory) {
            if (
                DIRECTORIES_IDS_WHICH_ENABLE_CASE_HOLDER.every(
                    directoryId => !this.state.criteria.directory.split(',').includes(directoryId)
                ) &&
                this.state.criteria.caseHolder !== ''
            ) {
                this.updateCriteriaValue({caseHolder: ''});
            }
        }
    }

    loadJSONs = () =>
        new Promise(resolve => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiCommunicationCentreInternalInboxClientsList()
                        .then((jsonResponse: IClientsList) => {
                            if (jsonResponse && jsonResponse.data) {
                                const nameHash: {[key: string]: number[]} = {};
                                (jsonResponse.data || []).forEach(speciesElement => {
                                    if (!nameHash[speciesElement.NAME]) {
                                        nameHash[speciesElement.NAME] = [];
                                    }
                                    nameHash[speciesElement.NAME].push(speciesElement.ID);
                                });
                                const listByIdName = Object.keys(nameHash).map(name => ({
                                    ID: nameHash[name].join(','),
                                    NAME: name,
                                }));
                                const selectedClients: {[key: string]: string} = {};
                                const selectedSpeciesKeyArray = Object.keys(this.state.selectedClients || {});
                                listByIdName
                                    .filter(i => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                                    .forEach(item => (selectedClients[item.ID] = item.NAME));
                                this.setState({
                                    clientsListByIdName: listByIdName,
                                    clientsNameHash: nameHash,
                                    selectedClients,
                                    timestamp: Date.now(),
                                });
                            }
                        })
                        .catch((error: any) => {
                            ERROR([`FormLabelInputSpecies: ${error.message}`]);
                        })
                        .then(() =>
                            this.setState(
                                prev => ({loading: prev.loading - 1}),
                                () => resolve([])
                            )
                        );
                }
            );
        });

    computeTextLabelInputValuesFromCriteria = (criteria: any) => {
        let textLabelInputDirectorySelectedElements: any = {};
        let textLabelInputCaseHolderSelectedElements: any = {};
        // let textLabelInputToDoBySelectedElements: any = {};
        let textLabelInputDocumentTypeSelectedElements: any = {};
        let selectedClients: any = {};
        criteria?.directory &&
            criteria.directory
                .split(',')
                .forEach(
                    (directoryId: string) =>
                        (textLabelInputDirectorySelectedElements[directoryId] =
                            this.textLabelInputDirectoryListByIdName.find(el => el.ID === directoryId)?.NAME)
                );
        criteria?.caseHolder &&
            criteria.caseHolder
                .split(',')
                .forEach(
                    (caseHolderId: string) =>
                        (textLabelInputCaseHolderSelectedElements[caseHolderId] =
                            this.textLabelInputCaseHolderListByIdName.find(el => el.ID === caseHolderId)?.NAME)
                );
        // criteria?.todoBy &&
        //     criteria.todoBy
        //         .split(',')
        //         .forEach(
        //             (todoByShortKey: string) =>
        //                 (textLabelInputToDoBySelectedElements[todoByShortKey] =
        //                     this.textLabelInputToDoByListByIdName.find(el => el.ID === todoByShortKey)?.NAME)
        //         );
        criteria?.documentType &&
            criteria.documentType
                .split(',')
                .forEach(
                    (documentTypeId: string) =>
                        (textLabelInputDocumentTypeSelectedElements[documentTypeId] =
                            this.textLabelInputDocumentTypeListByIdName.find(el => el.ID === documentTypeId)?.NAME)
                );
        criteria?.from &&
            criteria.from
                .split(',')
                .forEach(
                    (fromId: string) =>
                        (selectedClients[fromId] = this.state.clientsListByIdName.find(el => el.ID === fromId)?.NAME)
                );
        return {
            textLabelInputDirectorySelectedElements,
            textLabelInputCaseHolderSelectedElements,
            // textLabelInputToDoBySelectedElements,
            textLabelInputDocumentTypeSelectedElements,
            selectedClients,
        };
    };

    parseCriteria = (criteria: TDefaultCriteria): TDefaultCriteria => {
        const parsedCriteria: any = {};
        Object.keys(criteria).map(key => {
            let criteriaValue = criteria[key as TKeyOfDefaultCriteria];
            if (DATEFORMAT_CRITERIA_KEYS.indexOf(key) !== -1 && criteriaValue !== '') {
                parsedCriteria[key] = reformatDateEasy(criteriaValue);
            } else {
                parsedCriteria[key] = criteriaValue;
            }
        });
        return parsedCriteria;
    };

    buildParams = (parsedCriteria: TDefaultCriteria): void => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(
                i =>
                    parsedCriteria[i as TKeyOfDefaultCriteria] !== DEFAULT_CRITERIA[i as TKeyOfDefaultCriteria] &&
                    i !== 'refresh'
            )
            .map(key => `${key}=${parsedCriteria[key as TKeyOfDefaultCriteria]}`);
        this.props.history.replace(
            `/communicationCentreInternalInbox${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`
        );
    };

    search = (refresh?: boolean, urlLoad?: boolean, callBack?: () => any): any =>
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams(parsedCriteria);
                apiCommunicationCentreInternalSearchInbox(parsedCriteria, DEFAULT_CRITERIA)
                    .then((jsonResponse: ICommunicationMessagesInternalSearchResults) => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.messages) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {},
                                    jsonResponse.data.count === 0 && {isModalPreviewOpen: false},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        messages: jsonResponse.data.messages,
                                        timestamp: Date.now(),
                                    },
                                    jsonResponse.data.count && {count: jsonResponse.data.count},
                                    !refresh && {selectedMessages: {}}
                                );
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`Register search list error: ${error.message}`]);
                    })
                    .then(() => {
                        let optionalState: any = {};
                        if (this.state.criteria.pageNumber === 1) {
                            optionalState.modalPreviewFirstCommunicationId = this.state.messages.length
                                ? this.state.messages[0].communicationId
                                : null;
                        }
                        this.setState(prev => ({
                            loading: prev.loading - 1,
                            ...optionalState,
                            ...this.computeTextLabelInputValuesFromCriteria(prev.criteria),
                        }));
                        callBack && callBack();
                    });
            }
        );

    openModalInternalMessageRead = (communicationId: number): void =>
        this.setState({modalInternalMessageRead: communicationId});

    closeModalInternalMessageRead = () =>
        this.setState(
            {
                modalInternalMessageRead: false,
                isModalPreviewOpen: false,
                modalPreviewURL: '',
                modalInternalMessageReadInitialActiveTab: TABS.MESSAGE,
            },
            () => this.search(true)
        );

    closeModalFullScreenMessageReadAndPreviewAttachments = () =>
        this.setState({modalFullScreenMessageReadAndPreviewAttachments: false}, () => this.search(true));

    closeModalInternalMessageReply = () =>
        this.setState({modalInternalMessageReply: false, isModalPreviewOpen: false, modalPreviewURL: ''}, () =>
            this.search(true)
        );

    closeModalInternalMessageReplyBOA = () =>
        this.setState({modalInternalMessageReplyBOA: null, isModalPreviewOpen: false, modalPreviewURL: ''}, () =>
            this.search(true)
        );

    updateCriteriaValue = (
        criteriaValue: Partial<TDefaultCriteria>,
        callback?: () => any,
        refresh?: boolean,
        searchCallback?: () => any
    ): void => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = prev.criteria.order !== criteriaValue.order;
                reverseChanged = prev.criteria.reverse !== criteriaValue.reverse;
                return {criteria};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true, false, searchCallback);
            }
        );
    };

    openModalInternalMessageCompose = () => this.setState({modalInternalMessageCompose: true});

    closeModalInternalMessageCompose = () =>
        this.setState({modalInternalMessageCompose: false}, () => this.search(true));

    closeModalInternalMessageHistory = () => this.setState({isModalHistoryOpen: false}, () => this.search(true));

    formatTableMessages = (messages: ICommunicationMessageInternal[]) =>
        [...(messages || [])].map(el => ({
            ...el,
            lastUpdateDate: el.sentDate,
            receivedDate: el.sentDate,
            to: el.from,
        }));

    getTableFormatFunctions = () => ({
        addressees: (addressees: string[]) => (addressees || []).join(', '),
        applicationNumbers: (applicationNumbers: string[]) => (
            <ApplicationNumbersMultiple
                applicationNumbers={applicationNumbers}
                openModalApplicationNumbers={this.openModalApplicationNumbers}
            />
        ),
        deemedService: FORMAT_DATE_TIME_EASY,
        firstOpened: FORMAT_DATE_TIME_EASY,
        from: (from: string, {thirdPartyIdFrom}: ICommunicationMessageInternal) => (
            <>
                {thirdPartyIdFrom === 1 ? (
                    <img
                        src={CPVOIcon}
                        style={{
                            position: 'relative',
                            marginRight: -4,
                            left: -10,
                            width: 42,
                            height: 42,
                            verticalAlign: 'middle',
                        }}
                        alt="CPVO logo"
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faUser as any}
                        color={'gray'}
                        style={{width: 22, height: 25, verticalAlign: 'middle', marginRight: 10}}
                    />
                )}
                {from}
            </>
        ),
        lastUpdateDate: FORMAT_DATE_TIME_EASY,
        notificationDate: FORMAT_DATE_TIME_EASY,
        receivedDate: FORMAT_DATE_TIME_EASY,
        sentDate: FORMAT_DATE_TIME_EASY,
    });

    openModalReply = (rowObject: ICommunicationMessageInternal) => {
        const {applicationNumbers, communicationId, docmanDirectory, from, thirdPartyIdFrom, thirdPartyIdRole} =
            rowObject || {};

        let {id: contactId} = this.decodedJWT || {};
        // this.setState({modalInternalMessageReply: communicationId}, () => {
        //     this.setState({modalInternalMessageRead: null});
        // });
        if (parseInt(contactId || '') === USER_SHORTKEY_TO_CONTACTID.FB) {
            this.setState(
                {
                    modalInternalMessageReplyBOA: {
                        applicationNumbers,
                        communicationId,
                        from,
                        requestorThirdPartyId: thirdPartyIdFrom,
                        requestorThirdPartyIdRole: thirdPartyIdRole,
                        docmanDirectory,
                    },
                },
                () => {
                    this.setState({modalInternalMessageRead: false});
                }
            );
        } else {
            this.setState({modalInternalMessageReply: communicationId}, () => {
                this.setState({
                    modalInternalMessageRead: false,
                    modalInternalMessageReadInitialActiveTab: TABS.MESSAGE,
                });
            });
        }
    };

    closeModalApplicationNumbers = () => this.setState({modalApplicationNumbers: []});

    openModalApplicationNumbers = (applicationNumbers: string[]) =>
        this.setState({modalApplicationNumbers: applicationNumbers});

    getTableRowClass = (rowObject: ICommunicationMessageInternal) =>
        rowObject.includesAnnotationForYou ? styles.rowClassHighlighted : '';

    tablePaginate = (direction: -1 | 1) => {
        const currentPageNumber = this.state.criteria.pageNumber;
        this.updateCriteriaValue(
            {pageNumber: currentPageNumber + direction},
            () => null,
            true,
            () => {
                let nextMessage: any;
                if (direction === -1) {
                    nextMessage = [...this.state.messages].pop();
                } else {
                    nextMessage = this.state.messages.length ? {...this.state.messages[0]} : null;
                }
                let nextMessageId = (nextMessage && nextMessage.communicationId) || 0;
                this.updateModalInternalMessageRead(nextMessageId);
            }
        );
    };

    updateModalInternalMessageRead = (nextMessageId: number) => {
        this.setState({modalInternalMessageRead: false, modalPreviewURL: ''}, () => {
            this.openModalInternalMessageRead(nextMessageId);
        });
    };

    setModalPreviewMessage = (message: ICommunicationMessageInternalRead) =>
        this.setState({modalPreviewMessage: message});

    setModalPreviewURL = (URL: any) => this.setState({modalPreviewURL: URL});

    onButtonSaveSearchClick = () =>
        this.setState({
            isModalSaveSearchOpen: true,
        });

    onCloseModalSaveSearch = () =>
        this.setState({
            isModalSaveSearchOpen: false,
        });

    onModalReadButtonPreviewClick = () =>
        this.setState(prev => ({
            isModalPreviewOpen: !prev.isModalPreviewOpen,
        }));

    onTextLabelInputDirectorySelectionChange = (textLabelInputDirectorySelectedElements: {[key: string]: string}) =>
        this.setState({textLabelInputDirectorySelectedElements, textLabelInputDirectoryValue: ''}, () =>
            this.updateCriteriaValue({
                directory: Object.keys(textLabelInputDirectorySelectedElements || {}).join(','),
            })
        );

    onTextLabelInputDirectoryValueChange = (textLabelInputDirectoryValue: string) =>
        this.setState({textLabelInputDirectoryValue});

    onTextLabelInputCaseHolderSelectionChange = (textLabelInputCaseHolderSelectedElements: {[key: string]: string}) =>
        this.setState({textLabelInputCaseHolderSelectedElements, textLabelInputCaseHolderValue: ''}, () =>
            this.updateCriteriaValue({
                caseHolder: Object.keys(textLabelInputCaseHolderSelectedElements || {}).join(','),
            })
        );

    onTextLabelInputCaseHolderValueChange = (textLabelInputCaseHolderValue: string) =>
        this.setState({textLabelInputCaseHolderValue});

    // onTextLabelInputToDoBySelectionChange = (textLabelInputToDoBySelectedElements: {[key: string]: string}) =>
    //     this.setState({textLabelInputToDoBySelectedElements, textLabelInputToDoByValue: ''}, () =>
    //         this.updateCriteriaValue({
    //             todoBy: Object.keys(textLabelInputToDoBySelectedElements || {}).join(','),
    //         })
    //     );

    onSelectInputValueCaseHolderChange = ({
        target: {value: selectInputValueCaseHolder},
    }: React.ChangeEvent<HTMLSelectElement>): void =>
        this.updateCriteriaValue({caseHolder: selectInputValueCaseHolder});

    // onTextLabelInputToDoByValueChange = (textLabelInputToDoByValue: string) =>
    //     this.setState({textLabelInputToDoByValue});

    onTextLabelInputDocumentTypeSelectionChange = (textLabelInputDocumentTypeSelectedElements: {
        [key: string]: string;
    }) =>
        this.setState({textLabelInputDocumentTypeSelectedElements, textLabelInputDocumentTypeValue: ''}, () =>
            this.updateCriteriaValue({
                documentType: Object.keys(textLabelInputDocumentTypeSelectedElements || {}).join(','),
            })
        );

    onTextLabelInputDocumentTypeValueChange = (textLabelInputDocumentTypeValue: string) =>
        this.setState({textLabelInputDocumentTypeValue});

    onLinkButtonViewSearchTermOnlyClick = () =>
        this.setState(prevState => ({viewSearchTermOnly: !prevState.viewSearchTermOnly}));

    onButtonMarkSelectedAsReadClick = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                apiCommunicationCentreInternalInboxMarkMessagesRead({
                    communicationIds: this.state.communicationIdsSelected.map(el => parseInt(el)),
                }).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1, communicationIdsSelected: []}));
                    this.search(true);
                })
        );

    onButtonMarkSelectedAsNotReadClick = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                apiCommunicationCentreInternalInboxMarkMessagesNotRead({
                    communicationIds: this.state.communicationIdsSelected.map(el => parseInt(el)),
                }).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1, communicationIdsSelected: []}));
                    this.search(true);
                })
        );

    onButtonMarkAllAsReadClick = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                apiCommunicationCentreInternalInboxMarkMessagesRead({all: 'inbox'}).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1}));
                    this.search(true);
                })
        );

    onButtonArchiveSelectedClick = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                apiCommunicationCentreInternalInboxMessageArchive(
                    this.state.communicationIdsSelected.map(el => parseInt(el))
                ).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1, communicationIdsSelected: []}));
                    this.search(true);
                })
        );

    onFlagStatusChange = ({target: {value: flag}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({flag});

    onSelectedChange = (communicationIdsSelected: string[]) => this.setState({communicationIdsSelected});

    onClientsNameChange = (clientsName: string) => this.setState({clientsName});

    onClientsSelectionChange = (selectedClients: {[key: string]: string}) =>
        this.setState({selectedClients, clientsName: ''}, () =>
            this.updateCriteriaValue({from: Object.keys(selectedClients || {}).join(',')})
        );

    onCloseModalConfirmNotReplyingColleague = () => this.setState({isModalConfirmNotReplyingToColleagueOpen: false});

    onButtonActionOfModalConfirmNotReplyingColleagueClick = () => {
        const rowObject =
            typeof this.state.isModalConfirmNotReplyingToColleagueOpen === 'object'
                ? {...this.state.isModalConfirmNotReplyingToColleagueOpen}
                : null;
        this.setState(
            {
                isModalConfirmNotReplyingToColleagueOpen: false,
            },
            () => rowObject && this.openModalReply(rowObject)
        );
    };

    onTableIconReplyClick = (rowObject: ICommunicationMessageInternal) => {
        const {thirdPartyIdFrom} = rowObject || {};
        let {thirdPartyId: loggedInUserThirdPartyId} = this.decodedJWT || {};
        if (parseInt(loggedInUserThirdPartyId || '') === thirdPartyIdFrom) {
            this.setState({isModalConfirmNotReplyingToColleagueOpen: rowObject});
        } else {
            this.openModalReply(rowObject);
        }
    };

    onTableIconArchiveClick = (communicationId: number) =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                apiCommunicationCentreInternalInboxMessageArchive([communicationId]).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1}));
                    this.search(true);
                })
        );

    onTableIconFlagClick = ({communicationId, flagged}: ICommunicationMessageInternal) =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                (flagged ? apiCommunicationCentreInternalUnFlagMessage : apiCommunicationCentreInternalFlagMessage)(
                    communicationId
                ).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1}));
                    this.search(true);
                })
        );

    onCustomTableIconCommentsClick = (rowObject: ICommunicationMessageInternal) =>
        this.setState({
            modalInternalMessageRead: rowObject.communicationId,
            modalInternalMessageReadInitialActiveTab: TABS.COMMENTS,
        });

    onTableIconHistoryClick = ({correlationId}: ICommunicationMessageInternal) =>
        this.setState({isModalHistoryOpen: correlationId});

    onClearFieldsClick = (skipSearch?: boolean) =>
        this.setState(
            prev => ({
                textLabelInputDirectorySelectedElements: {},
                textLabelInputCaseHolderSelectedElements: {},
                // textLabelInputToDoBySelectedElements: {},
                textLabelInputDocumentTypeSelectedElements: {},
                selectedClients: {},
                criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
            }),
            () => (skipSearch ? null : this.search())
        );

    onSelectInputValueReadingStatusChange = ({
        target: {value: selectInputValueReadingStatus},
    }: React.ChangeEvent<HTMLSelectElement>): void =>
        this.updateCriteriaValue({readingStatus: selectInputValueReadingStatus});

    onDateInputValueArrivalDateToChange = (dateInputValueArrivalDateTo: string): void =>
        this.updateCriteriaValue({arrivalDateTo: dateInputValueArrivalDateTo});

    onDateInputValueArrivalDateFromChange = (dateInputValueArrivalDateFrom: string): void =>
        this.updateCriteriaValue({arrivalDateFrom: dateInputValueArrivalDateFrom});

    // onSelectInputValueAnnotationsChange = ({
    //     target: {value: selectInputValueAnnotations},
    // }: React.ChangeEvent<HTMLSelectElement>): void =>
    //     this.updateCriteriaValue({annotations: selectInputValueAnnotations});

    onSelectInputValueCCChange = ({target: {value: selectInputValueCC}}: React.ChangeEvent<HTMLSelectElement>): void =>
        this.updateCriteriaValue({cc: selectInputValueCC});

    onTextInputValueApplicationNumberChange = ({
        target: {value: textInputValueApplicationNumber},
    }: React.ChangeEvent<HTMLInputElement>): void =>
        this.updateCriteriaValue({applicationNumber: textInputValueApplicationNumber});

    onTextInputSearchTermChange = ({target: {value: searchTerm}}: React.ChangeEvent<HTMLInputElement>): void =>
        this.updateCriteriaValue({searchTerm});

    onModalPreviewButtonFirstClick = () => {
        this.updateCriteriaValue(
            {pageNumber: 1},
            () => null,
            true,
            () => {
                let nextMessageId = this.state.messages[0].communicationId;
                this.updateModalInternalMessageRead(nextMessageId);
            }
        );
    };

    onModalPreviewButtonPreviousClick = () => {
        let currentCommunicationIdIndex = this.state.messages.findIndex(
            el => el.communicationId === this.state.modalInternalMessageRead
        );
        if (currentCommunicationIdIndex > 0) {
            let nextCommunicationId = this.state.messages[currentCommunicationIdIndex - 1].communicationId;
            this.updateModalInternalMessageRead(nextCommunicationId);
        } else {
            this.tablePaginate(-1);
        }
    };

    onModalPreviewButtonNextClick = () => {
        let currentCommunicationIdIndex = this.state.messages.findIndex(
            el => el.communicationId === this.state.modalInternalMessageRead
        );
        if (currentCommunicationIdIndex < this.state.messages.length - 1) {
            let nextCommunicationId = this.state.messages[currentCommunicationIdIndex + 1].communicationId;
            this.updateModalInternalMessageRead(nextCommunicationId);
        } else {
            this.tablePaginate(1);
        }
    };

    onModalPreviewButtonLastClick = () => {
        const lastPage = utilCalculateTableMaxPossiblePage({
            totalRows: this.state.count,
            pageSize: this.state.criteria.pageSize,
        });
        this.updateCriteriaValue(
            {pageNumber: lastPage},
            () => null,
            true,
            () => {
                let nextMessage = [...this.state.messages].pop();
                let nextMessageId = (nextMessage && nextMessage.communicationId) || 0;
                this.setState(prev => ({modalPreviewLastPageResultSetSize: prev.messages.length}));
                this.updateModalInternalMessageRead(nextMessageId);
            }
        );
    };

    onModalInternalMessageReadButtonReplyClick = () =>
        this.setState(prev => ({
            modalInternalMessageRead: false,
            modalInternalMessageReply:
                prev.modalInternalMessageRead || prev.modalFullScreenMessageReadAndPreviewAttachments,
        }));

    onTableRowClick = (communicationId: number): void => this.openModalInternalMessageRead(communicationId);

    render() {
        const actions = CCInternalInboxActionButtons(
            this.onTableIconReplyClick,
            this.onTableIconArchiveClick,
            this.onTableIconHistoryClick,
            this.onCustomTableIconCommentsClick,
            this.onTableIconFlagClick
        );
        let selectedElementsHash: any = {};
        this.state.communicationIdsSelected.forEach((el: any) => (selectedElementsHash[el] = true));

        return (
            <>
                {this.state.isModalConfirmNotReplyingToColleagueOpen ? (
                    <ModalConfirmVersion2
                        title={`Replying external`}
                        message={`You are not replying to your colleague, you are replying to an external third party. Are you sure you want to continue ?`}
                        buttonName={'Continue'}
                        action={this.onButtonActionOfModalConfirmNotReplyingColleagueClick}
                        close={this.onCloseModalConfirmNotReplyingColleague}
                    />
                ) : null}
                {this.state.isModalHistoryOpen ? (
                    <CCModalInternalHistory
                        close={this.closeModalInternalMessageHistory}
                        correlationId={this.state.isModalHistoryOpen}
                        formatTableMessages={this.formatTableMessages}
                        getTableFormatFunctions={this.getTableFormatFunctions}
                    />
                ) : null}
                {this.state.modalInternalMessageCompose && (
                    <CCModalInternalCompose close={this.closeModalInternalMessageCompose} intl={this.props.intl} />
                )}
                {this.state.modalInternalMessageReply && (
                    <CCModalInternalReply
                        close={this.closeModalInternalMessageReply}
                        communicationId={this.state.modalInternalMessageReply}
                        isPreviewMode={this.state.isModalPreviewOpen}
                    />
                )}
                {this.state.modalInternalMessageReplyBOA && (
                    <CCModalInternalReplyBOA
                        close={this.closeModalInternalMessageReplyBOA}
                        communicationId={this.state.modalInternalMessageReply}
                        intl={this.props.intl}
                        originScreen={MODAL_COMPOSE_MESSAGE_ORIGIN_SCREENS.ADMIN_PROCEEDINGS}
                        initApplicationNumbers={this.state.modalInternalMessageReplyBOA.applicationNumbers}
                        adminProccedingsComposeMessageRequestorName={this.state.modalInternalMessageReplyBOA.from}
                        adminProccedingsComposeMessageRequestorThirdPartyId={
                            this.state.modalInternalMessageReplyBOA.requestorThirdPartyId
                        }
                        docmanDirectory={this.state.modalInternalMessageReplyBOA.docmanDirectory}
                    />
                )}
                {this.state.modalApplicationNumbers.length ? (
                    <ModalApplicationNumbers
                        applicationNumbers={this.state.modalApplicationNumbers}
                        close={this.closeModalApplicationNumbers}
                    />
                ) : null}
                {this.state.isModalSaveSearchOpen ? (
                    <CCModalInternalSaveSearch
                        close={this.onCloseModalSaveSearch}
                        criteria={this.state.criteria}
                        selectedClients={this.state.selectedClients}
                        documentTypesSelectedElements={this.state.textLabelInputDocumentTypeSelectedElements}
                        directorySelectedElements={this.state.textLabelInputDirectorySelectedElements}
                        // toDoBySelectedElements={this.state.textLabelInputToDoBySelectedElements}
                    />
                ) : null}
                {this.state.modalInternalMessageRead ? (
                    <CCModalInternalRead
                        close={this.closeModalInternalMessageRead}
                        communicationId={this.state.modalInternalMessageRead}
                        onButtonReplyClick={this.onModalInternalMessageReadButtonReplyClick}
                        onButtonPreviewClick={this.onModalReadButtonPreviewClick}
                        isPreviewMode={this.state.isModalPreviewOpen}
                        modalPreviewURL={this.state.modalPreviewURL}
                        setModalPreviewURL={this.setModalPreviewURL}
                        setModalPreviewMessage={this.setModalPreviewMessage}
                        initialActiveTab={this.state.modalInternalMessageReadInitialActiveTab}
                    />
                ) : null}
                {this.state.isModalPreviewOpen ? (
                    <ModalPreview
                        modalPreviewURL={this.state.modalPreviewURL}
                        modalPreviewMessage={this.state.modalPreviewMessage}
                        modalReadCommunicationId={this.state.modalInternalMessageRead}
                        tableMessages={this.state.messages}
                        count={this.state.count}
                        currentPageNumber={this.state.criteria.pageNumber}
                        currentPageSize={this.state.criteria.pageSize}
                        onButtonFirstClick={this.onModalPreviewButtonFirstClick}
                        onButtonPreviousClick={this.onModalPreviewButtonPreviousClick}
                        onButtonNextClick={this.onModalPreviewButtonNextClick}
                        onButtonLastClick={this.onModalPreviewButtonLastClick}
                        lastPageResultSetSize={this.state.modalPreviewLastPageResultSetSize}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Communication Centre`} />
                <NavigationCommunicationCentre
                    clearFields={this.onClearFieldsClick}
                    openModalMessageCompose={this.openModalInternalMessageCompose}
                    search={this.search}
                    updateCriteriaValue={this.updateCriteriaValue}
                />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <TextInput
                            double={true}
                            label={`Search term`}
                            onChange={this.onTextInputSearchTermChange}
                            value={this.state.criteria.searchTerm}
                            placeholder={`Type a search term`}
                            onEnter={this.search}
                        >
                            {}
                        </TextInput>
                        <div style={{paddingTop: 17}}>
                            <FormFooterLinkButton clickAction={this.onLinkButtonViewSearchTermOnlyClick}>{`${
                                this.state.viewSearchTermOnly ? 'Show' : 'Hide'
                            } filters`}</FormFooterLinkButton>
                        </div>
                        <div style={{clear: 'both'}} />
                        {!this.state.viewSearchTermOnly ? (
                            <>
                                <TextInput
                                    filter={'Starts with'}
                                    placeholder={`e.g. 2015 or 20150001`}
                                    label={`Application number(s)`}
                                    onChange={this.onTextInputValueApplicationNumberChange}
                                    value={this.state.criteria.applicationNumber}
                                    onEnter={this.search}
                                >
                                    {}
                                </TextInput>
                                <DateInput
                                    label={`Arrival date`}
                                    changeDateFrom={this.onDateInputValueArrivalDateFromChange}
                                    changeDateTo={this.onDateInputValueArrivalDateToChange}
                                    inputValueFrom={this.state.criteria.arrivalDateFrom}
                                    inputValueTo={this.state.criteria.arrivalDateTo}
                                />
                                <SelectInput
                                    label={`Reading Status`}
                                    value={this.state.criteria.readingStatus}
                                    onChange={this.onSelectInputValueReadingStatusChange}
                                    list={[
                                        {id: '1', value: 'Read'},
                                        {id: '2', value: 'not read'},
                                    ]}
                                />
                                <TextLabelInput
                                    delay={300}
                                    double={true}
                                    label={'Directory'}
                                    listByIdName={this.textLabelInputDirectoryListByIdName}
                                    nameHash={this.textLabelInputDirectoryNameHash}
                                    onSelectionChange={this.onTextLabelInputDirectorySelectionChange}
                                    onChange={this.onTextLabelInputDirectoryValueChange}
                                    outsideLabelWidth={160}
                                    placeholder={`Click to select directories`}
                                    selectedElements={this.state.textLabelInputDirectorySelectedElements}
                                    noInfo={true}
                                    clickableAndEditable={true}
                                    value={this.state.textLabelInputDirectoryValue}
                                    removeStyleClear={true}
                                />
                                <SelectInput
                                    double={false}
                                    label={`CC`}
                                    value={this.state.criteria.cc}
                                    onChange={this.onSelectInputValueCCChange}
                                    list={[{id: '1', value: 'Show only CC messages'}]}
                                />
                                <TextLabelInput
                                    delay={300}
                                    double={true}
                                    label={'Communication type'}
                                    listByIdName={this.textLabelInputDocumentTypeListByIdName}
                                    nameHash={this.textLabelInputDocumentTypeNameHash}
                                    onSelectionChange={this.onTextLabelInputDocumentTypeSelectionChange}
                                    onChange={this.onTextLabelInputDocumentTypeValueChange}
                                    outsideLabelWidth={160}
                                    placeholder={`Type and select communication type`}
                                    selectedElements={this.state.textLabelInputDocumentTypeSelectedElements}
                                    noInfo={true}
                                    value={this.state.textLabelInputDocumentTypeValue}
                                    removeStyleClear={true}
                                    disabled={true}
                                />
                                <TextLabelInput
                                    delay={300}
                                    label={'Caseholder'}
                                    listByIdName={this.textLabelInputCaseHolderListByIdName}
                                    nameHash={this.textLabelInputCaseHolderNameHash}
                                    onSelectionChange={this.onTextLabelInputCaseHolderSelectionChange}
                                    onChange={this.onTextLabelInputCaseHolderValueChange}
                                    outsideLabelWidth={160}
                                    placeholder={`Click to select Caseholders`}
                                    selectedElements={this.state.textLabelInputCaseHolderSelectedElements}
                                    noInfo={true}
                                    clickableAndEditable={true}
                                    value={this.state.textLabelInputCaseHolderValue}
                                    removeStyleClear={true}
                                    disabled={DIRECTORIES_IDS_WHICH_ENABLE_CASE_HOLDER.every(
                                        directoryId => !this.state.criteria.directory.split(',').includes(directoryId)
                                    )}
                                />
                                {/*<SelectInput*/}
                                {/*    label={`Caseholder`}*/}
                                {/*    value={this.state.criteria.caseHolder}*/}
                                {/*    onChange={this.onSelectInputValueCaseHolderChange}*/}
                                {/*    list={todoByList}*/}
                                {/*/>*/}
                                <TextLabelInput
                                    delay={300}
                                    double={true}
                                    label={'From'}
                                    listByIdName={this.state.clientsListByIdName}
                                    nameHash={this.state.clientsNameHash}
                                    onSelectionChange={this.onClientsSelectionChange}
                                    onChange={this.onClientsNameChange}
                                    outsideLabelWidth={160}
                                    placeholder={`Type part of the users name`}
                                    selectedElements={this.state.selectedClients}
                                    uniqueValue={true}
                                    noInfo={true}
                                    value={this.state.clientsName}
                                    removeStyleClear={true}
                                />
                                <SelectInput
                                    label={'Flag status'}
                                    value={this.state.criteria.flag}
                                    onChange={this.onFlagStatusChange}
                                    list={SELECT_INPUT_LIST_FLAG}
                                    notAll={true}
                                />
                                {WORKFLOW ? (
                                    <>
                                        {/*<TextLabelInput*/}
                                        {/*    delay={300}*/}
                                        {/*    double={true}*/}
                                        {/*    label={'To do by'}*/}
                                        {/*    listByIdName={this.textLabelInputToDoByListByIdName}*/}
                                        {/*    nameHash={this.textLabelInputToDoByNameHash}*/}
                                        {/*    onSelectionChange={this.onTextLabelInputToDoBySelectionChange}*/}
                                        {/*    onChange={this.onTextLabelInputToDoByValueChange}*/}
                                        {/*    outsideLabelWidth={160}*/}
                                        {/*    placeholder={`Click to select to do by`}*/}
                                        {/*    selectedElements={this.state.textLabelInputToDoBySelectedElements}*/}
                                        {/*    noInfo={true}*/}
                                        {/*    clickableAndEditable={true}*/}
                                        {/*    value={this.state.textLabelInputToDoByValue}*/}
                                        {/*    removeStyleClear={true}*/}
                                        {/*/>*/}
                                        {/*<TextLabelInput*/}
                                        {/*    delay={300}*/}
                                        {/*    double={true}*/}
                                        {/*    label={'To do by'}*/}
                                        {/*    listByIdName={this.textLabelInputToDoByListByIdName}*/}
                                        {/*    nameHash={this.textLabelInputToDoByNameHash}*/}
                                        {/*    onSelectionChange={this.onTextLabelInputToDoBySelectionChange}*/}
                                        {/*    onChange={this.onTextLabelInputToDoByValueChange}*/}
                                        {/*    outsideLabelWidth={160}*/}
                                        {/*    placeholder={`Click to select to do by`}*/}
                                        {/*    selectedElements={this.state.textLabelInputToDoBySelectedElements}*/}
                                        {/*    noInfo={true}*/}
                                        {/*    clickable={true}*/}
                                        {/*    value={this.state.textLabelInputToDoByValue}*/}
                                        {/*    removeStyleClear={true}*/}
                                        {/*/>*/}
                                        {/*<SelectInput*/}
                                        {/*    double={false}*/}
                                        {/*    label={`Annotations`}*/}
                                        {/*    value={this.state.criteria.annotations}*/}
                                        {/*    onChange={this.onSelectInputValueAnnotationsChange}*/}
                                        {/*    list={[{id: '1', value: 'Show only annotated messages'}]}*/}
                                        {/*/>*/}
                                    </>
                                ) : null}
                            </>
                        ) : null}
                        <div style={{clear: 'both'}} />
                        <FormFooter
                            left={
                                <Button
                                    variation={'tertiary'}
                                    color={buttonColor.whiteGray}
                                    clickAction={this.onButtonSaveSearchClick}
                                >{`Save Search`}</Button>
                            }
                            right={
                                <>
                                    <Button
                                        variation={'secondary'}
                                        clickAction={this.onClearFieldsClick}
                                    >{`Clear fields`}</Button>
                                    <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                                </>
                            }
                        />
                        <div style={{clear: 'both'}} />
                    </FormWrapper>
                    <div style={{marginTop: 20, marginBottom: 20}}>
                        {this.state.screenLoaded ? (
                            <>
                                {(this.state.messages || []).length ? (
                                    <>
                                        <div
                                            style={{
                                                float: 'left',
                                                width: 'calc(100% - 15px)',
                                                marginBottom: 15,
                                                paddingRight: 15,
                                            }}
                                        >
                                            <FormFooterButton
                                                color={buttonColor.blue}
                                                clickAction={this.onButtonMarkAllAsReadClick}
                                                icon={faEnvelopeOpen}
                                            >{`Mark all as read`}</FormFooterButton>
                                            <FormFooterButton
                                                color={buttonColor.blue}
                                                clickAction={this.onButtonMarkSelectedAsReadClick}
                                                disabled={!(this.state.communicationIdsSelected || []).length}
                                                icon={faEnvelopeOpen}
                                            >{`Mark selected as read`}</FormFooterButton>
                                            <FormFooterButton
                                                color={buttonColor.blue}
                                                clickAction={this.onButtonMarkSelectedAsNotReadClick}
                                                disabled={!(this.state.communicationIdsSelected || []).length}
                                                icon={faEnvelope}
                                            >{`Mark selected as unread`}</FormFooterButton>
                                            <FormFooterButton
                                                color={buttonColor.blue}
                                                clickAction={this.onButtonArchiveSelectedClick}
                                                disabled={!(this.state.communicationIdsSelected || []).length}
                                                icon={faArchive}
                                            >{`Archive selected`}</FormFooterButton>
                                        </div>
                                        <div style={{clear: 'both'}} />
                                    </>
                                ) : null}
                                <CustomTable
                                    actions={actions}
                                    bold={(rowObject: ICommunicationMessageInternal) => !rowObject.isRead}
                                    count={this.state.count}
                                    dataFilter={null}
                                    defaultOrder={this.state.criteria.order}
                                    filterFunctions={null}
                                    formatFunctions={this.getTableFormatFunctions()}
                                    forehandColumn={({urgent, numberOfAttachments}: any) => {
                                        return (
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                {urgent ? (
                                                    <FontAwesomeIcon
                                                        icon={faExclamation as any}
                                                        fontSize={20}
                                                        title={'Urgent'}
                                                        color={'#810202'}
                                                        style={{marginLeft: 2, marginRight: 2}}
                                                    />
                                                ) : null}
                                                {numberOfAttachments > 1 ? (
                                                    <FontAwesomeIcon
                                                        icon={faPaperclip as any}
                                                        fontSize={20}
                                                        title={'This message includes attachments'}
                                                        color={'grey'}
                                                        style={{marginLeft: 2, marginRight: 8}}
                                                    />
                                                ) : null}
                                            </div>
                                        );
                                    }}
                                    hideExcelButton={true}
                                    id={'communicationId'}
                                    intl={this.props.intl}
                                    onSelectedChange={this.onSelectedChange}
                                    pagination={true}
                                    pageNumber={this.state.criteria.pageNumber}
                                    pageSize={this.state.criteria.pageSize}
                                    resultFieldsDefault={RESULT_FIELDS_DEFAULT_INBOX_CPVO}
                                    resultFieldsAll={RESULT_FIELDS_ALL_INBOX_CPVO}
                                    reverseOrder={this.state.criteria.reverse}
                                    rowBorderLeft={true}
                                    rowClass={this.getTableRowClass}
                                    rowClick={this.onTableRowClick}
                                    selectable={true}
                                    selectDefault={selectedElementsHash}
                                    timestampSelected={this.state.timestamp}
                                    setLastCursor={null}
                                    timestamp={this.state.timestamp}
                                    tableName={'communicationCentreInternalInbox'}
                                    tableType={'OBJECT'}
                                    tableSource={this.formatTableMessages(this.state.messages)}
                                    updateCriteriaValue={this.updateCriteriaValue}
                                    version={2}
                                />
                            </>
                        ) : null}
                    </div>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(CCInternalInbox));
