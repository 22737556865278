import React from 'react';
import CustomTable from '~components/CustomTable';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {ModalCustomVersion2} from '../../commonModals';
import {apiVarietyFinderGetClassDetails} from './VFModalClassService';
import {injectIntl} from 'react-intl';

interface IProps {
    close: any;
    classId: string;
    intl: any;
}

interface IState {
    classCode: string;
    className: string;
    classType: string;
    loading: boolean;
    species: any[];
    remark: string;
    timestamp: number;
}

class VFModalClass extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            classCode: '',
            className: '',
            classType: '',
            loading: false,
            species: [],
            remark: '',
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        this.loadJSONs();
    }

    loadJSONs = () =>
        this.setState({loading: true}, () => {
            apiVarietyFinderGetClassDetails(this.props.classId).then(JSONResponse => {
                if (
                    JSONResponse &&
                    JSONResponse.data &&
                    JSONResponse.data.data.classesSearch &&
                    JSONResponse.data.data.classesSearch &&
                    JSONResponse.data.data.classesSearch.data &&
                    JSONResponse.data.data.classesSearch.data.length
                ) {
                    const {
                        classid: classCode,
                        classname: className,
                        info: remark,
                        species_object: speciesArray,
                        upovclass: classType,
                    } = JSONResponse.data.data.classesSearch.data[0] || {};
                    this.setState({
                        classCode,
                        className,
                        classType,
                        remark,
                        species: JSON.parse(speciesArray || `[]`).map(
                            ({species_id: speciesCode, description}: any) => ({
                                speciesCode,
                                description,
                            })
                        ),
                        loading: false,
                    });
                }
            });
        });

    render() {
        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={`Class`}
                body={
                    <>
                        <DataSheetFormFields
                            label={'Class Code'}
                            data={this.state.classCode}
                            double={true}
                            loading={this.state.loading}
                        />
                        <DataSheetFormFields
                            label={'Class Name'}
                            data={this.state.className}
                            double={true}
                            loading={this.state.loading}
                        />
                        <DataSheetFormFields
                            label={'Remark'}
                            data={this.state.remark}
                            double={true}
                            loading={this.state.loading}
                        />
                        <DataSheetFormFields
                            label={'Class'}
                            data={this.state.classType}
                            double={true}
                            loading={this.state.loading}
                        />
                        <div style={{clear: 'both'}} />
                        {this.state.species.length ? (
                            <div style={{marginTop: 30}}>
                                <CustomTable
                                    id={'name'}
                                    intl={this.props.intl}
                                    minimalTableTitle={`Species included in the same class`}
                                    minimalTableExcelButton={true}
                                    notSortable={true}
                                    tableName={'modalVFUPOVClassDetails'}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.species}
                                    resultFieldsDefault={['speciesCode', 'description']}
                                    resultFieldsAll={['speciesCode', 'description']}
                                    timestamp={this.state.timestamp}
                                    unlimited={true}
                                    evenRowBackground={true}
                                />
                                <div style={{clear: 'both'}} />
                            </div>
                        ) : null}
                    </>
                }
                footer={<></>}
                plus2={true}
            />
        );
    }
}

export default injectIntl(VFModalClass);
