import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';

import {Button} from '../../../componentsFormV2';

const ModalLogicTypeForceQuestionHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={'DEPRECATED'}
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeForceQuestionHelp;
