import React from 'react';
import cx from 'classnames';
import styles from './Error.module.scss';

interface IErrorProps {
    children: any;
    mb?: 'none';
    mt?: 'sm';
    wizardResponse?: boolean;
    wizardQuestion?: boolean;
}

function Error({children, wizardResponse, wizardQuestion, mb, mt}: IErrorProps) {
    let stylesAppliedMb, stylesAppliedMt;

    const mbStyle: any = {
        none: 'marginBottomNone',
    };

    const mtStyle: any = {
        sm: 'marginBottomSm',
    };

    if (mb) {
        stylesAppliedMb = styles[mbStyle[mb]];
    }

    if (mt) {
        stylesAppliedMt = styles[mtStyle[mt]];
    }

    return (
        <div
            className={cx(
                styles.error,
                wizardResponse && styles.wizardResponse,
                wizardQuestion && styles.wizardQuestion,
                stylesAppliedMb,
                stylesAppliedMt
            )}
        >
            {children}
        </div>
    );
}

export default Error;
