import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';
import styles from './ModalLogicType.module.scss';
import Confidential1 from './screenshots/Confidential_1.PNG';

const ModalLogicTypeDefaultValueForConfidentialOptionHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p>Defines which should be the default selected value for the confidential option.</p>
                    <p>
                        If no parameter is provided then, the default value is{' '}
                        <span className={styles.helpBold}>Yes</span>
                    </p>
                    <img src={Confidential1} alt="Confidential" className={styles.modalHelpImage} />
                    <p>
                        A <div className={styles.helpParameter}>parameter</div> with value{' '}
                        <div className={styles.helpCode}>value:0</div>
                        can be provided and the default value will be No
                    </p>
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeDefaultValueForConfidentialOptionHelp;
