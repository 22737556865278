import {API_SERVERLESS, getFetch, postFetch} from '../../commonApi';
import {ISearchItem} from '../../types';

export function apiCommunicationCentreInternalHeaderSavedSearches(): Promise<{
    data: {
        searches: ISearchItem[];
    };
}> {
    return getFetch(`${API_SERVERLESS}/communicationCentreInternal/v4/CCHSS/header/savedSearches`, true);
}

export function apiCommunicationCentreClientHeaderSavedSearches(): Promise<{
    data: {
        searches: ISearchItem[];
    };
}> {
    // return getFetch(`${API_SERVERLESS}/communicationCentreClient/v4/CCHSS/header/savedSearches`, true);
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve({
                data: {
                    searches: [
                        {
                            searchName: 'by arrival',
                            isDefault: true,
                            isAlertEnabled: false,
                            fields: [
                                {
                                    fieldName: 'arrivalDateFrom',
                                    fieldValue: '01/01/2023',
                                },
                                {
                                    fieldName: 'arrivalDateTo',
                                    fieldValue: '06/03/2023',
                                },
                                {
                                    fieldName: 'directory',
                                    fieldValue: '15',
                                },
                                {
                                    fieldName: 'readingStatus',
                                    fieldValue: '1',
                                },
                            ],
                        },
                        {
                            searchName: 'delete button',
                            isDefault: false,
                            isAlertEnabled: false,
                            fields: [
                                {
                                    fieldName: 'arrivalDateFrom',
                                    fieldValue: '01/01/2023',
                                },
                                {
                                    fieldName: 'arrivalDateTo',
                                    fieldValue: '06/03/2023',
                                },
                                {
                                    fieldName: 'directory',
                                    fieldValue: '15',
                                },
                                {
                                    fieldName: 'readingStatus',
                                    fieldValue: '1',
                                },
                                {
                                    fieldName: 'from',
                                    fieldValue: '167472',
                                },
                            ],
                        },
                        {
                            searchName: 'Default parameters',
                            isDefault: false,
                            isAlertEnabled: true,
                            fields: [],
                        },
                    ],
                },
            });
        }, 500);
    });
}

export function apiCommunicationCentreInternalToggleAlertSearch(searchName: string): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/communicationCentre/v3/internal/toggleAlertSearch`, true, {
        searchName,
    });
}

export function apiCommunicationCentreInternalDefaultSearch(searchName: string): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/communicationCentre/v3/internal/defaultSearch`, true, {
        searchName,
    });
}

export function apiCommunicationCentreClientDefaultSearch(searchName: string): Promise<{
    data: string;
}> {
    // return postFetch(`${API_SERVERLESS}/communicationCentre/v3/client/defaultSearch`, true, {
    //     searchName,
    // });
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve({
                data: 'OK',
            });
        }, 1000);
    });
}

export function apiCommunicationCentreInternalDeleteSearch(searchName: string): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/communicationCentre/v3/internal/deleteSearch`, true, {
        searchName,
    });
}

export function apiCommunicationCentreClientDeleteSearch(searchName: string): Promise<{
    data: string;
}> {
    // return postFetch(`${API_SERVERLESS}/communicationCentre/v3/client/deleteSearch`, true, {
    //     searchName,
    // });
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve({
                data: 'OK',
            });
        }, 1000);
    });
}
