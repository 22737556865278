import {API_SERVERLESS, getFetch} from '../../../../commonApi';
import {ConsultModifySpeciesDataFilterCriteriaData, ConsultModifySpeciesDataParamsData} from '../../../../types';

function concatIdsWithName(idsText: string, name: string) {
    if (idsText.length > 0 && name.length > 0) {
        return idsText + ',' + name;
    } else if (idsText.length > 0 && name.length === 0) {
        return idsText;
    } else if (idsText.length === 0 && name.length > 0) {
        return name;
    }
}

export function apiWithdrawAnEntrustment(specieId: string) {
    return getFetch(`${API_SERVERLESS}/tlo/applications/species/withdraw?specieId=${specieId}`, true);
}

export function apiSpeciesWithoutCultivation(
    criteria: Partial<ConsultModifySpeciesDataFilterCriteriaData>,
    excelTranslations: {[key: string]: string} = {}
): Promise<ConsultModifySpeciesDataParamsData> {
    const params = {...criteria};
    if (params.speciesIds !== undefined && params.speciesName !== undefined) {
        params.speciesName = concatIdsWithName(params.speciesIds, params.speciesName);
    }
    if (params.genusIds !== undefined && params.genus !== undefined) {
        params.genus = concatIdsWithName(params.genusIds, params.genus);
    }
    if (params.entrustmentTypeIds !== undefined && params.entrustmentType !== undefined) {
        params.entrustmentType = concatIdsWithName(params.entrustmentTypeIds, params.entrustmentType);
    }
    if (params.speciesNameFilter === 'equals') params.speciesNameFilter = 'exact';
    if (params.genusFilter === 'equals') params.genusFilter = 'exact';
    if (params.entrustmentTypeFilter === 'equals') params.entrustmentTypeFilter = 'exact';
    const requestParams = Object.keys(params).map(k => `${k}=${params[k]}`);
    return getFetch(
        `${API_SERVERLESS}/tlotree/applications/specieswithoutcultivation?${requestParams.join('&')}&excel=false`,
        true,
        excelTranslations
    );
}
