import React from 'react';
import BackOfficeTemplateTabs, {TAB} from '~shared/BackOfficeTemplateTabs';
import Checkbox from '~components/Checkbox';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import Empty from '~components/Empty';
import Error from '~components/Error';
import TextInput from '~components/TextInput';
import TextAreaInput from '~components/TextAreaInput';
import {
    apiBackOfficeGetTemplate,
    apiBackOfficeNewTemplate,
    apiBackOfficeSaveTemplate,
    I_RESPONSE_TEMPLATE,
} from './BackOfficeTemplateDetailsService';
import {faLightbulb, faList, faPencilAlt, faPlus, faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import {getContentWithCorrectImagePath} from '~utils';
import {injectIntl} from 'react-intl';
import styles from './BackOfficeTemplateDetails.module.scss';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationBackOffice from '../../shared/NavigationBackOffice';
import {Button} from '../../componentsFormV2';
import {ModalLabelPreviewVersion2, ModalLabelSearchVersion2} from '../../commonModals';
import ModalUsedInVersion2, {rowType} from '../../commonModals/ModalUsedInVersion2';
import ModalLabelVersion2, {getLabelTypeId} from '../../commonModals/ModalLabelVersion2';
import {trackPageView} from '../../utils';

const STATUS_TERMINATED = 3;

interface IProps {
    intl: any;
    close: any;
}

interface IState {
    errorMandatoryFields: boolean;
    loading: boolean;
    modalUsedIn: number | null;
    modalLabel: {labelId: number; typeId: string; fieldIdKey: string; fieldHTMLKey: string} | null;
    modalLabelSearch: {typeId: string; fieldIdKey: string; fieldHTMLKey: string} | null;
    modalPreview: string | null;
    template: I_RESPONSE_TEMPLATE | null;
}

class BackOfficeTemplateDetails extends React.Component<IProps, IState> {
    refreshModalSearchFunction = null;
    initialTemplate: I_RESPONSE_TEMPLATE | null = null;

    constructor(props: IProps) {
        super(props);
        this.state = {
            errorMandatoryFields: false,
            loading: false,
            modalUsedIn: null,
            modalLabel: null,
            modalLabelSearch: null,
            modalPreview: null,
            template: null,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'templateDetails'});
        (self as any).setStateFunction = this.setStateFunction;
        (self as any).defaultState = this.state;
    }

    setStateFunction = (state: any) => this.setState(state);

    loadJSONs = (templateId: string) =>
        new Promise((resolve, reject) => {
            this.setState({loading: true}, () => {
                Promise.all([this.loadTemplateDetails(parseInt(templateId))])
                    .then(() => this.setState({loading: false}, () => resolve([])))
                    .catch(reject);
            });
        });

    loadTemplateDetails = (templateId: number) => {
        if (templateId === 0) {
            return new Promise(resolve => {
                this.setState(
                    {
                        template: {
                            templateId: 0,
                            status: 1,
                            buttonAddLabelId: null,
                            buttonAddLabelValueHTML: null,
                            buttonDelLabelId: null,
                            buttonDelLabelValueHTML: null,
                            displayOrder: null,
                            helpLabelId: null,
                            helpLabelValueHTML: null,
                            jsonMapping: null,
                            mandatory: 0,
                            mappingCode: null,
                            multi: 0,
                            nameLabelId: null,
                            nameLabelValueHTML: null,
                            remarkLabelId: null,
                            remarkLabelValueHTML: null,
                            templateComment: null,
                        },
                    },
                    () => {
                        this.initialTemplate = Object.assign({}, this.state.template);
                        resolve([]);
                    }
                );
            });
        } else {
            return apiBackOfficeGetTemplate(templateId)
                .then(
                    jsonResponse =>
                        jsonResponse.data &&
                        jsonResponse.data.templates &&
                        jsonResponse.data.templates.length === 1 &&
                        this.setState(
                            {template: jsonResponse.data.templates[0]},
                            () => (this.initialTemplate = Object.assign({}, this.state.template))
                        )
                )
                .catch((error: any) => LOG([`ERROR ${error}`]));
        }
    };

    showModalUsedIn = (labelId: number) => this.setState({modalUsedIn: labelId});

    closeModal = () => this.setState({modalUsedIn: null});

    showModalPreview = (valueHTML: string) => this.setState({modalPreview: valueHTML});

    closeModalPreview = () => this.setState({modalPreview: null});

    showModalLabel = (labelId: number, typeId: string, fieldIdKey: string, fieldHTMLKey: string) =>
        this.setState({modalLabel: {labelId, typeId, fieldIdKey, fieldHTMLKey}});

    closeModalLabel = () =>
        this.setState({modalLabel: null}, () => this.state.modalLabelSearch && this.refreshModalSearchFunction);

    showModalLabelSearch = (typeId: string, fieldIdKey: string, fieldHTMLKey: string) =>
        this.setState({modalLabelSearch: {typeId, fieldIdKey, fieldHTMLKey}});

    closeModalLabelSearch = () => this.setState({modalLabelSearch: null});

    setLabelId = (
        label: {labelId: number; valueHTML?: string; labelValueHTML?: string},
        fieldIdKey: string,
        fieldHTMLKey: string
    ) => {
        const templateData: any = {};
        templateData[fieldIdKey] = label.labelId;
        templateData[fieldHTMLKey] = label.valueHTML || label.labelValueHTML;
        return this.updateTemplate(templateData);
    };

    setRefreshModalSearchFunction = (refreshModalSearchFunction: any) =>
        (this.refreshModalSearchFunction = refreshModalSearchFunction);

    updateTemplate = (templateData: any) =>
        new Promise(resolve => {
            this.setState(
                prev => {
                    const template = Object.assign({}, {...prev.template}, {...templateData});
                    return {template};
                },
                () => resolve([])
            );
        });

    saveTemplate = () => {
        const {
            templateId,
            displayOrder,
            helpLabelId,
            jsonMapping,
            mandatory,
            mappingCode,
            multi,
            nameLabelId,
            remarkLabelId,
            templateComment,
            buttonAddLabelId,
            buttonDelLabelId,
        } = this.state.template || {};
        if (nameLabelId === null || displayOrder === null || displayOrder === '') {
            this.setState({errorMandatoryFields: true});
        } else {
            this.setState({loading: true, errorMandatoryFields: false}, () => {
                return new Promise(resolve => {
                    if (this.state.template && this.state.template.templateId === 0) {
                        return apiBackOfficeNewTemplate({
                            displayOrder,
                            helpLabelId,
                            jsonMapping,
                            mandatory,
                            mappingCode,
                            multi,
                            nameLabelId,
                            remarkLabelId,
                            templateComment,
                            buttonAddLabelId,
                            buttonDelLabelId,
                        }).then(JSONResponse => {
                            window.location.href = `/templateDetails?templateId=${JSONResponse.templateId}&status=1,2`;
                        });
                    } else {
                        return apiBackOfficeSaveTemplate({
                            templateId,
                            displayOrder,
                            helpLabelId,
                            jsonMapping,
                            mandatory,
                            mappingCode,
                            multi,
                            nameLabelId,
                            remarkLabelId,
                            templateComment,
                            buttonAddLabelId,
                            buttonDelLabelId,
                        }).then(() => this.setState({loading: false}), resolve);
                    }
                });
            });
        }
    };

    resetForm = () => this.initialTemplate && this.setState({template: this.initialTemplate});

    clearLabelUse = (fieldIdKey: string, fieldHTMLKey: string) => {
        const templateData: any = {};
        templateData[fieldIdKey] = null;
        templateData[fieldHTMLKey] = null;
        this.updateTemplate(templateData);
    };

    onDisplayOrderInputChange = ({target: {value: displayOrder}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateTemplate({displayOrder});

    onMandatoryCheckboxChange = () =>
        this.updateTemplate({mandatory: this.state.template && this.state.template.mandatory === 0 ? 1 : 0});

    onMultiCheckboxChange = () =>
        this.updateTemplate({multi: this.state.template && this.state.template.multi === 0 ? 1 : 0});

    onTemplateCommentInputChange = ({target: {value: templateComment}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateTemplate({templateComment});

    onMappingCodeInputChange = ({target: {value: mappingCode}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateTemplate({mappingCode});

    onJsonMappingInputChange = ({target: {value: jsonMapping}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateTemplate({jsonMapping});

    renderPopupElements(labelId: number | null | undefined, typeId: string, fieldIdKey: string, fieldHTMLKey: string) {
        return [
            {
                label: 'Search label',
                icon: faSearch,
                handler: () => this.showModalLabelSearch(typeId, fieldIdKey, fieldHTMLKey),
            },
        ].concat(
            labelId
                ? [
                      {
                          label: 'Show label',
                          icon: faPencilAlt,
                          handler: () => this.showModalLabel(labelId, typeId, fieldIdKey, fieldHTMLKey),
                      },
                      {
                          label: 'Clear label use',
                          icon: faTimes,
                          handler: () => this.clearLabelUse(fieldIdKey, fieldHTMLKey),
                      },
                      {
                          label: 'Used in',
                          icon: faLightbulb,
                          handler: () => this.showModalUsedIn(labelId),
                      },
                  ]
                : [
                      {
                          label: 'Add label',
                          icon: faPlus,
                          handler: () => this.showModalLabel(0, typeId, fieldIdKey, fieldHTMLKey),
                      },
                  ]
        );
    }

    render() {
        const template: any = this.state.template || {};
        const formDisabled = this.state.template && this.state.template.status === STATUS_TERMINATED;
        return (
            <>
                {this.state.modalLabelSearch ? (
                    <ModalLabelSearchVersion2
                        intl={this.props.intl}
                        target={this.state.modalLabelSearch}
                        setLabelId={this.setLabelId}
                        setRefreshModalSearchFunction={this.setRefreshModalSearchFunction}
                        showModalPreview={this.showModalPreview}
                        closeModalPreview={this.closeModalPreview}
                        showModalUsedIn={this.showModalUsedIn}
                        closeModal={this.closeModal}
                        showModalLabel={this.showModalLabel}
                        closeModalLabel={this.closeModalLabel}
                        close={this.closeModalLabelSearch}
                    />
                ) : null}
                {this.state.modalLabel ? (
                    <ModalLabelVersion2
                        intl={this.props.intl}
                        label={this.state.modalLabel}
                        setLabelId={this.setLabelId}
                        showModalUsedIn={this.showModalUsedIn}
                        close={this.closeModalLabel}
                    />
                ) : null}
                {this.state.modalUsedIn ? (
                    <ModalUsedInVersion2
                        elementId={this.state.modalUsedIn}
                        intl={this.props.intl}
                        type={rowType.LABEL}
                        close={this.closeModal}
                    />
                ) : null}
                {this.state.modalPreview !== null ? (
                    <ModalLabelPreviewVersion2
                        intl={this.props.intl}
                        close={this.closeModalPreview}
                        valueHTML={this.state.modalPreview}
                    />
                ) : null}
                {this.state.loading ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Web Back Office`} />
                <NavigationBackOffice activeTitle={'Templates'} />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <BackOfficeTemplateTabs
                            tabId={TAB.DETAILS}
                            search={this.loadJSONs}
                            loading={this.state.loading}
                            templateStatus={template.status}
                            templateId={template.templateId}
                            close={this.props.close}
                            officeId={template && template.officeId}
                        />
                        <DataSheetFormSection title={'Template fields'} disableToggle={true}>
                            <DataSheetFormFields
                                label={'Name'}
                                html={getContentWithCorrectImagePath(template.nameLabelValueHTML)}
                                loading={this.state.loading}
                                double={true}
                                labelIcon={faList}
                                labelPopup={this.renderPopupElements(
                                    template.nameLabelId,
                                    getLabelTypeId('Group question'),
                                    'nameLabelId',
                                    'nameLabelValueHTML'
                                )}
                                disabled={formDisabled}
                                mandatory={true}
                            />
                            <DataSheetFormFields
                                label={'Remark'}
                                html={getContentWithCorrectImagePath(template.remarkLabelValueHTML)}
                                loading={this.state.loading}
                                double={true}
                                labelIcon={faList}
                                labelPopup={this.renderPopupElements(
                                    template.remarkLabelId,
                                    getLabelTypeId('Remark'),
                                    'remarkLabelId',
                                    'remarkLabelValueHTML'
                                )}
                                disabled={formDisabled}
                            />
                            <DataSheetFormFields
                                label={'Help'}
                                html={getContentWithCorrectImagePath(template.helpLabelValueHTML)}
                                loading={this.state.loading}
                                double={true}
                                labelIcon={faList}
                                labelPopup={this.renderPopupElements(
                                    template.helpLabelId,
                                    getLabelTypeId('Help'),
                                    'helpLabelId',
                                    'helpLabelValueHTML'
                                )}
                                disabled={formDisabled}
                            />
                            <DataSheetFormFields
                                label={'Add'}
                                html={getContentWithCorrectImagePath(template.buttonAddLabelValueHTML)}
                                loading={this.state.loading}
                                double={true}
                                labelIcon={faList}
                                labelPopup={this.renderPopupElements(
                                    template.buttonAddLabelId,
                                    getLabelTypeId('Front Office Button'),
                                    'buttonAddLabelId',
                                    'buttonAddLabelValueHTML'
                                )}
                                disabled={formDisabled}
                            />
                            <DataSheetFormFields
                                label={'Del'}
                                html={getContentWithCorrectImagePath(template.buttonDelLabelValueHTML)}
                                loading={this.state.loading}
                                double={true}
                                labelIcon={faList}
                                labelPopup={this.renderPopupElements(
                                    template.buttonDelLabelId,
                                    getLabelTypeId('Front Office Button'),
                                    'buttonDelLabelId',
                                    'buttonDelLabelValueHTML'
                                )}
                                disabled={formDisabled}
                            />
                        </DataSheetFormSection>
                        <Empty height={25} />
                        <Empty height={25} />
                        <Empty height={25} />
                        <TextInput
                            label={`Order display`}
                            mandatory={true}
                            onChange={this.onDisplayOrderInputChange}
                            value={template.displayOrder}
                            disabled={formDisabled}
                        />
                        <div style={{marginLeft: 100, float: 'left'}}>
                            <Checkbox
                                width={200}
                                clickAction={this.onMandatoryCheckboxChange}
                                simple={true}
                                label={`Mandatory`}
                                value={template.mandatory === 1}
                                disabled={formDisabled}
                            />
                            <Checkbox
                                width={200}
                                clickAction={this.onMultiCheckboxChange}
                                simple={true}
                                label={`Multi`}
                                value={template.multi === 1}
                                disabled={formDisabled}
                            />
                            <div style={{clear: 'both'}} />
                        </div>
                        <div className={styles.commentWrap}>
                            <TextAreaInput
                                label={`Comment`}
                                triple={true}
                                value={template.templateComment}
                                onChange={this.onTemplateCommentInputChange}
                                disabled={formDisabled}
                            />
                        </div>
                        <TextInput
                            label={`Mapping code`}
                            onChange={this.onMappingCodeInputChange}
                            value={template.mappingCode}
                            disabled={formDisabled}
                        />
                        <TextInput
                            label={`JSON Mapping`}
                            onChange={this.onJsonMappingInputChange}
                            value={template.jsonMapping}
                            double={true}
                            disabled={formDisabled}
                        />
                        <div style={{clear: 'both'}} />
                        {this.state.errorMandatoryFields ? (
                            <div style={{margin: '0 12px 0 7px'}}>
                                <Error>{`Please fill the mandatory fields`}</Error>
                            </div>
                        ) : null}
                        {this.state.template && this.state.template.status !== STATUS_TERMINATED ? (
                            <FormFooter>
                                <Button
                                    clickAction={this.resetForm}
                                    variation={'secondary'}
                                >{`Reset to default`}</Button>
                                <Button clickAction={this.saveTemplate} icon={'arrowRight'}>{`Save`}</Button>
                            </FormFooter>
                        ) : null}
                        <div style={{clear: 'both'}} />
                    </FormWrapper>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(BackOfficeTemplateDetails);
