import React from 'react';
import InputContainer from '~componentsForm/InputContainer';
// import checked from './icons/checkqued.png';
// import unchecked from './icons/unchecked.png';
import styles from './Checkbox.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckSquare, faSquare} from '@fortawesome/free-regular-svg-icons';
import CustomPopoverCheckbox from './CustomPopoverCheckbox';
import cx from 'classnames';

export default function ({
    label,
    value,
    simple,
    double,
    triple,
    clickAction,
    width,
    height,
    popOverText,
    disabled,
    style: userStyle,
    size,
}) {
    return (
        <InputContainer checkbox={!simple} double={double} triple={triple} width={width} height={height} size={size}>
            {popOverText && <CustomPopoverCheckbox content={popOverText} />}
            <div className={styles.container} onClick={!disabled ? clickAction : undefined} style={userStyle}>
                <div className={cx(styles.checkbox)}>
                    {value ? (
                        <FontAwesomeIcon icon={faCheckSquare} color={disabled ? 'gray' : 'green'} />
                    ) : (
                        <FontAwesomeIcon icon={faSquare} color={disabled ? 'gray' : 'green'} />
                    )}
                    <div className={styles.label}>{label}</div>
                </div>
            </div>
        </InputContainer>
    );
}
