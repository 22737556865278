import React, {useState} from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import RadioButton from '~components/RadioButton';
import TextInput from '~components/TextInput';
import {Button} from '../../../componentsFormV2';

export const VALIDATION_TYPE_VALUE = {
    PHONE_FAX: 'regexp:/^([0-9\\ \\-\\(\\)\\.\\+])*$/',
    EMAIL: 'regexp:/^.+@.+$/',
    DENOMINATION: `regexp:/^[^\\r\\n_;:\\?\\*$%\\+=&#\\~\\(\\[\\{\\)\\]\\}<>\\"@€£§|]{1,}$/`, // eslint-disable-line
    DENOMINATION_NAKTUINBOUW: `regexp:/^[^\\r\\n._,;:!\\?\\*$%\\+=&#\\~\\(\\[\\{\\)\\]\\}<>'\"\\/\\\\]{1,}$/`, // eslint-disable-line
};

const VALIDATION_TYPE = {
    PHONE_FAX: 'PHONE_FAX',
    EMAIL: 'EMAIL',
    DENOMINATION: 'DENOMINATION',
    DENOMINATION_NAKTUINBOUW: 'DENOMINATION_NAKTUINBOUW',
    CUSTOM: 'CUSTOM',
};

const getDefaultSelectedRadio = (logicParam = '') => {
    if (logicParam === VALIDATION_TYPE_VALUE.PHONE_FAX) return VALIDATION_TYPE.PHONE_FAX;
    if (logicParam === VALIDATION_TYPE_VALUE.EMAIL) return VALIDATION_TYPE.EMAIL;
    if (logicParam === VALIDATION_TYPE_VALUE.DENOMINATION) return VALIDATION_TYPE.DENOMINATION;
    if (logicParam === VALIDATION_TYPE_VALUE.DENOMINATION_NAKTUINBOUW) return VALIDATION_TYPE.DENOMINATION_NAKTUINBOUW;
    if (logicParam !== '') return VALIDATION_TYPE.CUSTOM;
    return VALIDATION_TYPE.PHONE_FAX;
};

const ModalLogicTypeValidations = ({close, onCreateOrUpdateLogicType, responseLogicValues, responseLogicType}) => {
    const {logicParam, applyToRemark} = responseLogicValues || {};
    const {name} = responseLogicType || {};
    const [paramValue, setParamValue] = useState(
        logicParam !== undefined ? logicParam : VALIDATION_TYPE_VALUE.PHONE_FAX
    );

    const onParamValueChange = ({target: {value: param}}) => setParamValue(param);

    const [selectedRadio, setSelectedRadio] = useState(getDefaultSelectedRadio(logicParam));

    const onRadioClick = value => {
        setSelectedRadio(value);
        if (value === VALIDATION_TYPE.CUSTOM) {
            setParamValue('');
        } else {
            setParamValue(VALIDATION_TYPE_VALUE[value]);
        }
    };

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <div>
                        <RadioButton
                            radioButtonList={[
                                {
                                    description: 'Phone / fax',
                                    value: VALIDATION_TYPE.PHONE_FAX,
                                },
                                {description: 'Email', value: VALIDATION_TYPE.EMAIL},
                                {description: 'Denomination', value: VALIDATION_TYPE.DENOMINATION},
                                {
                                    description: 'Denomination Naktuinbouw',
                                    value: VALIDATION_TYPE.DENOMINATION_NAKTUINBOUW,
                                },
                                {description: 'Custom', value: VALIDATION_TYPE.CUSTOM},
                            ]}
                            groupName={'Validation'}
                            value={selectedRadio}
                            clickEvent={onRadioClick}
                        />
                        <div style={{clear: 'both'}} />
                    </div>
                    <TextInput
                        label={'Parameter'}
                        double={true}
                        onChange={onParamValueChange}
                        value={paramValue}
                        disabled={selectedRadio !== VALIDATION_TYPE.CUSTOM}
                    />
                </>
            }
            footer={
                <>
                    <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                    <Button
                        icon={'arrowRight'}
                        clickAction={() =>
                            onCreateOrUpdateLogicType(paramValue, applyToRemark !== undefined ? applyToRemark : false)
                        }
                    >{`Save`}</Button>
                </>
            }
        />
    );
};

export default ModalLogicTypeValidations;
