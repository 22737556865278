import React from 'react';
import en from '../../../../../../translations/en.json';
import styles from './ReportingDeadlinesTableArea.module.scss';
import getIcon from '../../../../../../utils/icons';
import {ReportingDeadlinesTableRowData} from '../../ReportingDeadlinesPageService';
import CustomTable from '../../../../../../components/CustomTable';
import {IntlShape} from 'react-intl';

interface ReportingDeadlinesTableAreaProps {
    intl: IntlShape;
    deadlines: Array<ReportingDeadlinesTableRowData> | null;
    timestamp: number;
    downloadDocument: (row: ReportingDeadlinesTableRowData, excelTranslations: {}) => void;
}

export default function ReportingDeadlinesTableArea(props: ReportingDeadlinesTableAreaProps) {
    const downloadDocument = (rowObject: ReportingDeadlinesTableRowData) => {
        const excelTranslations: {[key: string]: string} = {};
        const scopeOfEntrustmentsExcelKeys = Object.keys(en).filter(key => ~key.indexOf('excel.deadlines.'));
        const scopeOfEntrustmentsHeaderKeys = scopeOfEntrustmentsExcelKeys.map(key =>
            key.replace('excel.deadlines.', '')
        );
        scopeOfEntrustmentsHeaderKeys.forEach(headerKey => {
            excelTranslations[headerKey] = props.intl.formatMessage({
                id: `excel.deadlines.${headerKey}`,
            });
        });
        props.downloadDocument(rowObject, excelTranslations);
    };

    return (
        <div className={styles.mainContainer}>
            <CustomTable
                tableName={'deadlines'}
                tableType={'OBJECT'}
                tableSource={props.deadlines}
                timestamp={props.timestamp}
                id={'addressName'}
                resultFieldsDefault={['addressName']}
                intl={props.intl}
                defaultOrder={'addressName'}
                topCaption={
                    <h4 className={styles.topCaptionContainer}>
                        {`Reporting deadlines for your Examination Office(s) (Available in xls format)`}
                    </h4>
                }
                forehandColumn={(rowObject: ReportingDeadlinesTableRowData) =>
                    rowObject.examContractId ? (
                        <span title={`Export to XLS`}>
                            <img
                                style={{width: 20, height: 20, cursor: 'pointer'}}
                                alt={`Export to XLS`}
                                src={getIcon('xls')}
                                onClick={event => {
                                    event.stopPropagation();
                                    downloadDocument(rowObject);
                                }}
                            />
                        </span>
                    ) : null
                }
            />
        </div>
    );
}
