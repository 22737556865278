import React, {useState} from 'react';
import styles from './MyPVREndOfFilesTopCaption.module.scss';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import cx from 'classnames';
import InputLink from '~components/InputLink';
import {faChevronDown, faChevronRight} from '@fortawesome/free-solid-svg-icons';

export default function BackOfficeMessagesBottomCaption({
    signEnabled,
    draftEnabled,
    showModalApplicationsToSign,
    draftRequests,
}) {
    const [informationOpened, setInformationOpened] = useState(null);
    const toggleInformationBox = () => setInformationOpened(!informationOpened);
    return (
        <div className={styles.container}>
            <div style={{display: 'inline-block', width: 'calc(100vw - 430px)', marginRight: 20}}>
                <InputLink
                    label={`Information about "Draft" and "Sign and send" buttons`}
                    icon={informationOpened ? faChevronDown : faChevronRight}
                    clickAction={toggleInformationBox}
                    width={500}
                />
                <div style={{clear: 'both'}}>{}</div>
                {informationOpened && (
                    <div
                        style={{
                            borderStyle: 'solid',
                            borderWidth: 1,
                            borderColor: 'black',
                            padding: 5,
                            marginBottom: 5,
                            marginLeft: 5,
                        }}
                    >
                        <div
                            style={{
                                background: '#FE7F26',
                                color: 'white',
                                paddingRight: 5,
                                paddingLeft: 5,
                                width: 'fit-content',
                            }}
                        >
                            {'Information about the "Draft" button'}
                        </div>
                        {`After selecting one or several files, click on the “Draft” button to prepare the request or update your today’s request(s), and send it later to the CPVO. A draft pdf document will be automatically generated and displayed on the line(s) concerned. You can click on this pdf icon to display the draft document . Drafts can be accessed from your dashboard and transmitted later to the Office (by clicking on ‘sign and send’). Drafts can be cancelled by clicking on the icon of the bin at the right end of the line. A draft request appears in yellow in the list of files displayed below. No document can be attached to a draft request.`}
                        <div
                            style={{
                                background: '#5BC0DE',
                                color: 'white',
                                paddingRight: 5,
                                paddingLeft: 5,
                                width: 'fit-content',
                            }}
                        >
                            {'Information about the "Sign and send" button'}
                        </div>
                        {`After selecting one file or several files, click on this button to file an end of file request. A pdf document will be automatically generated and displayed on the line(s) concerned. You can click on this pdf icon to display the document that the CPVO will receive.`}
                    </div>
                )}
                <div className={cx(styles.label, styles.labelYellow)}>{`Draft request`}</div>
                <div className={cx(styles.label, styles.labelGreen)}>{`Today's request`}</div>
            </div>
            <div style={{display: 'inline-block', textAlign: 'right', verticalAlign: 'top'}}>
                <FormFooterButton
                    color={buttonColor.blue}
                    clickAction={showModalApplicationsToSign}
                    disabled={!signEnabled}
                >{`Sign and send`}</FormFooterButton>
                <FormFooterButton
                    color={buttonColor.orange}
                    clickAction={draftRequests}
                    disabled={!draftEnabled}
                >{`Draft`}</FormFooterButton>
            </div>
            <div style={{clear: 'both'}}>{}</div>
        </div>
    );
}
