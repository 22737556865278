import React from 'react';
import CustomTable from '~components/CustomTable';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {LoadingBar} from '../../componentsLayout';
import {ModalCustomVersion2} from '../../commonModals';
import graphQLClientInstance from '../../utils/axiosGraphQLClient';
import {getSearchSpeciesQuery} from '../VFSpecies/VFSpeciesService';
import {injectIntl} from 'react-intl';
import {responseSpeciesMapToState} from '../VFSpecies/VFSpecies';

const LANGUAGE_KEY_MAP_NAME: any = {
    en: 'English',
    fr: 'French',
    de: 'German',
    es: 'Spanish',
    other_bot: 'Latin',
};

interface IProps {
    close: any;
    intl: any;
    rowObject?: IStateSpecies;
    speciesCode?: string;
}

interface IStateSpecies {
    classData: {
        class_id: string | null;
        class_name: string | null;
        class_type: string | null;
        class_type_value: string | null;
    }[];
    comments: string;
    cropSector: string;
    otherBotanicalNames: any;
    otherName: any;
    principalBotanicalName: string | null;
    speciesCode: string;
    speciesUPOV: string;
}

interface IState {
    loading: number;
    species: IStateSpecies;
    timestamp: number;
}

class VFModalSpecies extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: 0,
            species: {
                classData: [],
                comments: '',
                cropSector: '',
                otherBotanicalNames: null,
                otherName: null,
                principalBotanicalName: null,
                speciesCode: '',
                speciesUPOV: '',
            },
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        if (this.props.rowObject) {
            this.setState({species: {...this.props.rowObject}});
        } else {
            this.loadJSONs();
        }
    }

    loadJSONs = () => {
        const values = {
            speciesCode: this.props.speciesCode,
            speciesCodeSelect: 'equals',
        };
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                graphQLClientInstance.post('/graphql', getSearchSpeciesQuery(values, 1, 0)).then(JSONResponse => {
                    if (
                        JSONResponse &&
                        JSONResponse.data &&
                        JSONResponse.data.data &&
                        JSONResponse.data.data.speciesSearch &&
                        JSONResponse.data.data.speciesSearch.data &&
                        JSONResponse.data.data.speciesSearch.data.length
                    ) {
                        const {data} = JSONResponse.data.data.speciesSearch;
                        const responseSpecies = data.map(responseSpeciesMapToState);
                        this.setState(prev => ({
                            loading: prev.loading - 1,
                            species: responseSpecies[0],
                            timestamp: Date.now(),
                        }));
                    }
                });
            }
        );
    };

    getCropSectors = () => {
        return this.state.species.cropSector.split('/').length <= 1
            ? this.state.species.cropSector
            : this.state.species.cropSector
                  .split('/')
                  .map((cropSector: any) => <div key={cropSector}>- {cropSector}</div>);
    };

    getOtherNamesTableData = () => {
        const otherNamesTableData: any = [];
        (this.state.species.otherBotanicalNames || []).forEach((otherBotanicalName: any) =>
            otherNamesTableData.push({
                language: 'Latin',
                name: otherBotanicalName,
            })
        );
        Object.entries(this.state.species.otherName || {}).forEach(([languageKey, nameArray]: any) => {
            (nameArray || []).forEach((name: string) => {
                otherNamesTableData.push({
                    language: LANGUAGE_KEY_MAP_NAME[languageKey],
                    name,
                });
            });
        });
        return otherNamesTableData;
    };

    render() {
        const otherNamesTableData = this.getOtherNamesTableData();

        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={`Species`}
                body={
                    <div>
                        {this.state.loading !== 0 ? <LoadingBar /> : null}
                        {this.state.loading === 0 ? (
                            <>
                                <DataSheetFormFields
                                    label={'Principal botanical name'}
                                    data={this.state.species.principalBotanicalName}
                                    double={true}
                                />
                                <DataSheetFormFields
                                    label={'Species Code'}
                                    data={this.state.species.speciesCode}
                                    double={true}
                                />
                                <DataSheetFormFields
                                    label={'UPOV Code'}
                                    data={this.state.species.speciesUPOV}
                                    double={true}
                                />
                                <DataSheetFormFields
                                    label={'Crop sector(s)'}
                                    data={<div>{this.getCropSectors()}</div>}
                                    double={true}
                                />
                                <DataSheetFormFields
                                    label={'Other names'}
                                    data={
                                        <div style={{marginTop: -17, marginLeft: -30, marginBottom: 10}}>
                                            {otherNamesTableData.length ? (
                                                <CustomTable
                                                    id={'name'}
                                                    intl={this.props.intl}
                                                    notSortable={true}
                                                    tableName={'modalVFSpeciesSpeciesDetails'}
                                                    tableType={'OBJECT'}
                                                    tableSource={otherNamesTableData}
                                                    resultFieldsDefault={['language', 'name']}
                                                    resultFieldsAll={['language', 'name']}
                                                    timestamp={this.state.timestamp}
                                                    unlimited={true}
                                                    evenRowBackground={true}
                                                />
                                            ) : null}
                                        </div>
                                    }
                                    double={true}
                                />
                                <DataSheetFormFields
                                    label={'Classes'}
                                    data={
                                        <div style={{marginTop: -17, marginLeft: -30, marginBottom: 10}}>
                                            {this.state.species.classData.length ? (
                                                <CustomTable
                                                    id={'name'}
                                                    intl={this.props.intl}
                                                    notSortable={true}
                                                    tableName={'modalVFSpeciesClasses'}
                                                    tableType={'OBJECT'}
                                                    tableSource={this.state.species.classData || []}
                                                    resultFieldsDefault={['class_id', 'class_name', 'class_type_value']}
                                                    resultFieldsAll={['class_id', 'class_name', 'class_type_value']}
                                                    timestamp={this.state.timestamp}
                                                    unlimited={true}
                                                    evenRowBackground={true}
                                                />
                                            ) : null}
                                        </div>
                                    }
                                    double={true}
                                />
                                <DataSheetFormFields
                                    label={'Comment'}
                                    data={this.state.species.comments}
                                    double={true}
                                />
                                <div style={{clear: 'both'}} />
                            </>
                        ) : null}
                    </div>
                }
                footer={<></>}
                plus2={true}
            />
        );
    }
}

export default injectIntl(VFModalSpecies);
