import React from 'react';
import {injectIntl} from 'react-intl';
import {apiUserClients} from '../../commonApi/clients';
import {apiGetNote, apiNotesSearch} from './MyPVRMyFeesService';
import {INote, INoteLine} from '../../types';
import MyPVRMyFeesActionButtons from './MyPVRMyFeesActionButtons';
import {getFeeRowClass} from '~utils';
import {apiDownload} from '~commonApi/download';
import {saveAs} from 'file-saver';
import TextInput from '~components/TextInput';
import {faChevronDown, faChevronRight, faInfo} from '@fortawesome/free-solid-svg-icons';
import TextLabelInput from '~components/TextLabelInput';
import Empty from '~components/Empty';
import InputLink from '~components/InputLink';
import Title from '~components/Title';
import DateInput from '~components/DateInput';
import SelectInput from '~components/SelectInput';
import CustomTable from '~components/CustomTable';
import {FORMAT_DATE_EASY, formatDateEasy, reformatDateEasy} from '~components/FormatFunctions';
import RESULT_FIELDS_ALL from './data/RESULT_FIELDS_ALL.json';
import DEFAULT_RESULT_FIELDS from './data/DEFAULT_RESULT_FIELDS.json';
import jwtDecode from 'jwt-decode';
import getIcon from '~utils/icons';
import MyPVRMyFeesTopCaption from './MyPVRMyFeesTopCaption';
import {getPreSignedURLFetchRequest} from '../../utils/requests';
import JSZip from 'jszip';
import pako from 'pako';
import {getExcelSignedURLFetchRequest} from '../../commonApi/download';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationMyPVR from '../../shared/NavigationMyPVR';
import {Button} from '../../componentsFormV2';
import {ModalAlertVersion2, ModalErrorVersion2, ModalNoteDetailsVersion2} from '../../commonModals';
import {trackPageView} from '../../utils';

const feeTypeOptions = [
    {id: '1', value: 'All'},
    {id: '2', value: 'Application Fees'},
    {id: '3', value: 'Examination Fees'},
    {id: '4', value: 'Take-over Fees'},
    {id: '5', value: 'Annual Fees'},
    {id: '6', value: 'Other Fees'},
];

const noteStatusOptions = [
    {id: '1', value: 'All (balanced/not balanced)'},
    {id: '20', value: 'Not balanced'},
    {id: '30', value: 'Balanced'},
    {id: 'scheduled', value: 'Scheduled'},
];

const addressedTo = [
    {id: '0', value: 'All'},
    {id: '1', value: 'Me'},
    {id: '2', value: 'others'},
];

const noteTypeOptions = [
    {id: '1', value: 'All (Debit/Credit)'},
    {id: '2', value: 'Debit'},
    {id: '3', value: 'Credit'},
];

const advancedCriteria = [
    'applicationNumber',
    'noteId',
    'emissionDateFrom',
    'emissionDateTo',
    'noteType',
    'denomination',
    'balanceDateFrom',
    'balanceDateTo',
];

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

type TKeyOfDefaultCriteria = keyof TDefaultCriteria;

const DEFAULT_CRITERIA = {
    clientName: null,
    clientsId: '',
    feeType: '1',
    noteStatus: '',
    dueDateFrom: '',
    dueDateTo: '',
    applicationNumber: '',
    noteId: '',
    emissionDateFrom: '',
    emissionDateTo: '',
    noteType: '1',
    denomination: '',
    balanceDateFrom: '',
    balanceDateTo: '',
    addressedTo: '0',
    pageNumber: 1,
    pageSize: 10,
    order: 'dueDate',
    reverse: false,
    refresh: false,
    excel: false,
};

const DATEFORMAT_CRITERIA_KEYS = [
    'dueDateFrom',
    'dueDateTo',
    'emissionDateFrom',
    'emissionDateTo',
    'balanceDateFrom',
    'balanceDateTo',
];

const DEFAULT_LIMIT = 2000;
const DEFAULT_COUNT = 0;

interface IProps {
    intl: any;
    history: any;
}

interface IState {
    advancedCriteriaCount: number;
    advancedOpened: boolean;
    companyName: string | null;
    count: number;
    clientListByIdName: {ID: string; NAME: string}[];
    clientNameHash: {[key: string]: any};
    criteria: TDefaultCriteria;
    criteriaCount: number;
    errorMessage: string;
    errorTitle: string;
    limit: number;
    loading: number;
    modalAlert: boolean;
    notes: INote[];
    noteLines: INoteLine[] | null;
    screenLoaded: boolean;
    selectedClients: {[key: string]: any};
    selectedNotes: any;
    timestamp: number;
}

class MyPVRMyFees extends React.Component<IProps, IState> {
    labelHash: any;
    lastSearchCriteria = null;
    noteByIndexHash = {};
    noteHash: any = {};
    selectedNoteId = '';

    constructor(props: IProps) {
        super(props);
        const initialCriteria = Object.assign({}, DEFAULT_CRITERIA, {noteStatus: '20'});
        this.state = {
            advancedCriteriaCount: 0,
            advancedOpened: false,
            companyName: null,
            count: DEFAULT_COUNT,
            clientListByIdName: [],
            clientNameHash: {},
            criteria: initialCriteria,
            criteriaCount: 0,
            errorMessage: '',
            errorTitle: '',
            limit: DEFAULT_LIMIT,
            loading: 0,
            modalAlert: false,
            notes: [],
            noteLines: null,
            screenLoaded: false,
            selectedClients: {},
            selectedNotes: {},
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'myFees'});
        this.loadJSONs();
        this.loadUrlParams();
        this.getCompanyName();
    }

    getCompanyName = () => {
        const remember = localStorage.getItem('remember') === '1';
        const authToken = (remember ? localStorage : sessionStorage).getItem('token');
        if (authToken) {
            const decoded = jwtDecode(authToken);
            decoded && decoded.company && this.setState({companyName: decoded.company});
        }
    };

    toggleAdvancedSearch = () =>
        this.setState(
            prevState => ({
                advancedOpened: !prevState.advancedOpened,
            }),
            () => this.state.advancedOpened
        );

    loadJSONs = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiUserClients()
                    .then(jsonResponse => {
                        if (jsonResponse) {
                            const clientNameHash: any = {};
                            ((jsonResponse && jsonResponse.clients) || []).forEach(client => {
                                if (!clientNameHash[client.name]) {
                                    clientNameHash[client.name] = [];
                                }
                                clientNameHash[client.name].push(client.clientId);
                            });
                            const clientListByIdName = Object.keys(clientNameHash).map(clientName => ({
                                ID: clientNameHash[clientName].join(','),
                                NAME: clientName,
                            }));
                            this.setState({clientListByIdName, clientNameHash});
                        }
                    })
                    .catch(error => {
                        ERROR([`Error loadJSONs() at MyPVRFinances: ${error.message}`]);
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );

    loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const criteria: any = Object.assign({}, DEFAULT_CRITERIA);
            const params: any = domainArray.pop();
            params.split('&').forEach((param: any) => {
                const paramElements = param.split('=');
                if (paramElements.length === 2) {
                    const key = paramElements[0];
                    criteria[key] = decodeURIComponent(paramElements[1]);
                }
            });
            if (Object.keys(criteria).length > 0) {
                const selectedSpecies: any = {};
                if (criteria.speciesIds && criteria.speciesIds !== '') {
                    criteria.speciesIds.split(',').forEach((speciesId: any) => (selectedSpecies[speciesId] = false));
                }
                DATEFORMAT_CRITERIA_KEYS.forEach(criteriaKey => {
                    if (/^[0-9]{4}(-([0-9]){2}){2}$/.test(criteria[criteriaKey])) {
                        criteria[criteriaKey] = formatDateEasy(criteria[criteriaKey]);
                    }
                });
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                this.setState(
                    Object.assign(
                        {},
                        {
                            criteria,
                            criteriaCount,
                            advancedCriteriaCount,
                            selectedSpecies,
                        },
                        criteria.order && {defaultOrder: criteria.order},
                        criteria.reverse && {reverseOrder: criteria.reverse}
                    ),
                    () => this.search(false, true)
                );
            }
        }
    };

    resetCriteria = () =>
        this.setState({
            criteria: DEFAULT_CRITERIA,
            criteriaCount: 0,
            advancedCriteriaCount: 0,
            timestamp: Date.now(),
            notes: [],
            selectedClients: {},
        });

    parseCriteria = (criteria: TDefaultCriteria): TDefaultCriteria => {
        const parsedCriteria: any = {};
        Object.keys(criteria).map(key => {
            if (DATEFORMAT_CRITERIA_KEYS.indexOf(key) !== -1 && criteria[key as TKeyOfDefaultCriteria] !== '') {
                const date = criteria[key as TKeyOfDefaultCriteria];
                parsedCriteria[key] = reformatDateEasy(date);
            } else {
                parsedCriteria[key] = criteria[key as TKeyOfDefaultCriteria];
            }
        });
        return parsedCriteria;
    };

    search = (refresh?: boolean, urlLoad?: any) =>
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                !urlLoad && this.buildParams(parsedCriteria);
                apiNotesSearch(parsedCriteria, DEFAULT_CRITERIA)
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.notes) {
                            this.labelHash = {};
                            this.noteHash = {};
                            (jsonResponse.data.notes || []).forEach(
                                note => (this.noteHash[`${note.noteId}-${note.applicationNumber}`] = note)
                            );
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {...prev},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        notes: jsonResponse.data.notes,
                                        timestamp: Date.now(),
                                        screenLoaded: true,
                                    },
                                    jsonResponse.data.count && {count: jsonResponse.data.count}
                                );
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`Register search list error: ${error.message}`]);
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );

    printExcel = (excelTranslations: any) =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                const parsedCriteria = Object.assign({}, this.parseCriteria(this.state.criteria), {excel: true});
                apiNotesSearch(parsedCriteria, DEFAULT_CRITERIA, excelTranslations)
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                            getExcelSignedURLFetchRequest(jsonResponse.data.token).then(response => {
                                if (response && response.signedUrl) {
                                    const {signedUrl} = response;
                                    getPreSignedURLFetchRequest(signedUrl)
                                        .then(response => response.blob())
                                        .then(responseBlob => {
                                            saveAs(responseBlob, `MyFees.xlsx`);
                                        });
                                }
                            });
                        }
                    })
                    .catch((error: any) => LOG([`error downloading: ${error}`]))
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );

    buildParams = (parsedCriteria: TDefaultCriteria) => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(
                i =>
                    parsedCriteria[i as TKeyOfDefaultCriteria] !== DEFAULT_CRITERIA[i as TKeyOfDefaultCriteria] &&
                    i !== 'refresh'
            )
            .map(key => `${key}=${parsedCriteria[key as TKeyOfDefaultCriteria]}`);
        this.props.history.replace(`/myFees${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`);
    };

    countCriteria = (criteria?: any, advanced?: any) => {
        let count = 0;
        (advanced ? advancedCriteria : Object.keys(criteria)).map(key => {
            if (criteria[key] !== DEFAULT_CRITERIA[key as TKeyOfDefaultCriteria]) count++;
        });
        return count;
    };

    downloadDocument = (note: INote) =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiDownload(note.csDocsKey)
                    .then((JSONResponse: any) => {
                        if (JSONResponse && JSONResponse.documents) {
                            let preSignedURL;
                            let fileName: any;
                            let isZip: any;
                            if (Array.isArray(JSONResponse.documents)) {
                                preSignedURL = JSONResponse.documents[0]?.signedUrl;
                                fileName = JSONResponse.documents[0]?.docName;
                                isZip = JSONResponse.documents[0]?.isZip;
                            } else if (JSONResponse.documents.signecUrl && JSONResponse.documents.fileName) {
                                preSignedURL = JSONResponse.documents.signecUrl;
                                fileName = JSONResponse.documents?.fileName;
                                isZip = JSONResponse.documents?.isZip;
                            }
                            if (preSignedURL && fileName) {
                                getPreSignedURLFetchRequest(preSignedURL)
                                    .then(response => response.blob())
                                    .then(responseBlob => {
                                        if (isZip) {
                                            responseBlob.arrayBuffer().then(fileReader => {
                                                saveAs(new Blob([pako.ungzip(new Uint8Array(fileReader))]), fileName);
                                            });
                                        } else {
                                            saveAs(responseBlob, fileName);
                                        }
                                    });
                            }
                        }
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );

    downloadNotes = () => {
        if (this.isDownloadEnabled()) {
            const csDocsKeyArray = Object.values(this.state.selectedNotes || {})
                .map((i: any) => i.csDocsKey)
                .filter(cs => cs !== null);
            const csDocsKeys = [...new Set(csDocsKeyArray)];
            const documentCount = csDocsKeys.length;
            if (documentCount <= 300) {
                this.setState(
                    prev => ({loading: prev.loading + 1}),
                    () => {
                        apiDownload(
                            documentCount === 1 ? csDocsKeys.slice(0, 1) : null,
                            documentCount > 1 ? {csDocsKeys} : null
                        )
                            .then((JSONResponse: any) => {
                                if (JSONResponse && JSONResponse.documents) {
                                    let preSignedURLs = [];
                                    let fileNames: any = [];
                                    if (Array.isArray(JSONResponse.documents)) {
                                        preSignedURLs = JSONResponse.documents.map(
                                            (document: any) => document?.signedUrl
                                        );
                                        fileNames = JSONResponse.documents.map((document: any) => document?.docName);
                                    } else {
                                        preSignedURLs.push(JSONResponse.documents?.signecUrl);
                                        fileNames.push(JSONResponse.documents?.fileName);
                                    }
                                    Promise.all(preSignedURLs.map(getPreSignedURLFetchRequest)).then(responses =>
                                        Promise.all(responses.map(response => response.blob())).then(responsesBlobs => {
                                            if (responsesBlobs.length === 1) {
                                                saveAs(responsesBlobs[0], fileNames[0]);
                                            } else if (responsesBlobs.length > 1) {
                                                const zip = new JSZip();
                                                responsesBlobs.forEach((blob, i) => {
                                                    zip.file(fileNames[i], blob, {
                                                        binary: true,
                                                    });
                                                });
                                                zip.generateAsync({
                                                    type: 'blob',
                                                }).then(function (content) {
                                                    saveAs(content, 'archive.zip');
                                                });
                                            }
                                        })
                                    );
                                }
                            })
                            .then(() => this.setState(prev => ({loading: prev.loading - 1})));
                    }
                );
            }
        }
    };

    closeError = () =>
        this.setState({
            errorTitle: '',
            errorMessage: '',
        });

    closeModalAlert = () => this.setState({modalAlert: false});

    showNoteLines = (noteId: string) =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                this.selectedNoteId = noteId;
                apiGetNote(noteId)
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.notelines) {
                            this.setState({
                                noteLines: jsonResponse.notelines,
                            });
                        }
                    })
                    .catch(error => {
                        ERROR([`MyPVR Finances showNoteLines error: ${error.message}`]);
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );

    closeNoteLinesModal = () => this.setState({noteLines: null});

    updateCriteriaValue = (criteriaValue: any, callback?: any, refresh?: any) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                pageNumberChanged = criteriaValue.pageNumber && prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.criteria.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse && prev.criteria.reverse !== criteriaValue.reverse;
                return {criteria, criteriaCount, advancedCriteriaCount};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    isDownloadEnabled = () => Object.keys(this.state.selectedNotes).length > 0;

    onSelectedChange = (noteKeys: any) => {
        const selectedNotes: any = {};
        noteKeys.forEach(
            (noteKey: any) => (selectedNotes[noteKey] = this.noteHash[noteKey] || this.state.selectedNotes[noteKey])
        );
        this.setState({selectedNotes});
    };

    onSelectedClientsIdChange = (selectedClients: any) =>
        this.updateCriteriaValue({clientsId: Object.keys(selectedClients).join(','), clientName: ''}, () =>
            this.setState({selectedClients})
        );

    onClientNameChange = (clientName: string) => this.updateCriteriaValue({clientName});

    onSelectInputFeeTypeChange = ({target: {value: feeType}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({feeType});

    onSelectInputNoteStatusChange = ({target: {value: noteStatus}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({noteStatus});

    onDueDateFromChange = (dueDateFrom: string) => this.updateCriteriaValue({dueDateFrom});

    onDueDateToChange = (dueDateTo: string) => this.updateCriteriaValue({dueDateTo});

    onTextInputApplicationNumberChange = ({
        target: {value: applicationNumber},
    }: React.ChangeEvent<HTMLInputElement>) => {
        if (/[0-9]{0,8}/.test(applicationNumber)) {
            applicationNumber = applicationNumber.substring(0, 8);
            this.updateCriteriaValue({applicationNumber});
        }
    };

    onTextInputNoteNumberChange = ({target: {value: noteId}}: React.ChangeEvent<HTMLInputElement>) => {
        if (!isNaN(parseInt(noteId))) {
            this.updateCriteriaValue({noteId});
        }
    };

    onIssuanceDateFromChange = (emissionDateFrom: string) => this.updateCriteriaValue({emissionDateFrom});

    onIssuanceDateToChange = (emissionDateTo: string) => this.updateCriteriaValue({emissionDateTo});

    onSelectInputNoteTypeChange = ({target: {value: noteType}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({noteType});

    onTextInputDenominationChange = ({target: {value: denomination}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({denomination});

    onBalanceDateFromChange = (balanceDateFrom: string) => this.updateCriteriaValue({balanceDateFrom});

    onBalanceDateToChange = (balanceDateTo: string) => this.updateCriteriaValue({balanceDateTo});

    onSelectInputSelectedAddressToChange = ({target: {value: addressedTo}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({addressedTo});

    render() {
        const actions = MyPVRMyFeesActionButtons(this.props, this.showNoteLines);
        const addressedToWithCompany = addressedTo.map(element => ({
            id: element.id,
            value: element.value === 'Me' && this.state.companyName ? this.state.companyName : element.value,
        }));

        return (
            <>
                {this.state.modalAlert ? (
                    <ModalAlertVersion2
                        title={`Download notes`}
                        message={`You can download only 300 notes simultaneously. You have selected ${this.state.modalAlert}. Please change your selection and try again.`}
                        close={this.closeModalAlert}
                    />
                ) : null}
                {this.state.errorMessage ? (
                    <ModalErrorVersion2
                        title={this.state.errorTitle}
                        message={this.state.errorMessage}
                        close={this.closeError}
                    />
                ) : null}
                {this.state.noteLines !== null ? (
                    <ModalNoteDetailsVersion2
                        title={`Details note ${this.selectedNoteId}`}
                        noteLines={this.state.noteLines}
                        intl={this.props.intl}
                        close={this.closeNoteLinesModal}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`My PVR`} />
                <NavigationMyPVR />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div>
                            <TextLabelInput
                                double={true}
                                onSelectionChange={this.onSelectedClientsIdChange}
                                onChange={this.onClientNameChange}
                                value={this.state.criteria.clientName}
                                selectedElements={this.state.selectedClients}
                                delay={300}
                                multiple={true}
                                label={'Applicant(s)/Breeder(s)/Representative(s)'}
                                outsideLabel={''}
                                outsideLabelWidth={160}
                                listByIdName={this.state.clientListByIdName}
                                nameHash={this.state.clientNameHash}
                            />
                            <SelectInput
                                double={true}
                                value={this.state.criteria.feeType}
                                onChange={this.onSelectInputFeeTypeChange}
                                list={feeTypeOptions}
                                outsideLabel={'Fee category'}
                                outsideLabelWidth={160}
                                notAll={true}
                            />
                            <SelectInput
                                double={true}
                                value={this.state.criteria.noteStatus}
                                onChange={this.onSelectInputNoteStatusChange}
                                list={noteStatusOptions}
                                outsideLabel={'Note status'}
                                outsideLabelWidth={160}
                                notAll={true}
                            />
                            <div>
                                <DateInput
                                    double={true}
                                    changeDateFrom={this.onDueDateFromChange}
                                    changeDateTo={this.onDueDateToChange}
                                    inputValueFrom={this.state.criteria.dueDateFrom}
                                    inputValueTo={this.state.criteria.dueDateTo}
                                    outsideLabel={`Due date`}
                                    outsideLabelWidth={160}
                                />
                            </div>
                            <div style={{clear: 'both'}} />
                            <SelectInput
                                double={true}
                                value={this.state.criteria.addressedTo}
                                onChange={this.onSelectInputSelectedAddressToChange}
                                list={addressedToWithCompany}
                                outsideLabel={'Addressed to'}
                                outsideLabelWidth={160}
                                notAll={true}
                            />
                            <div style={{clear: 'both'}} />
                            <Empty width={160} oneLine={true} />
                            <InputLink
                                label={`Advanced search${
                                    this.state.advancedCriteriaCount !== 0
                                        ? ` (${this.state.advancedCriteriaCount})`
                                        : ''
                                } `}
                                icon={this.state.advancedOpened ? faChevronDown : faChevronRight}
                                clickAction={this.toggleAdvancedSearch}
                            />
                            <Empty oneLine={true} />
                        </div>
                        {this.state.advancedOpened && (
                            <>
                                <Title triple={true}>{`Financial details`}</Title>
                                <TextInput
                                    label={'Application number(s)'}
                                    filter={'Starts with'}
                                    placeholder={`e.g. 2015 or 20150001`}
                                    infoIcon={faInfo}
                                    onChange={this.onTextInputApplicationNumberChange}
                                    onEnter={this.search}
                                    value={this.state.criteria.applicationNumber}
                                    popOverText={`The search will find all applications whose number starts with the entered terms.`}
                                />
                                <TextInput
                                    label={'Note number'}
                                    placeholder={`e.g. 19950001`}
                                    onChange={this.onTextInputNoteNumberChange}
                                    onEnter={this.search}
                                    value={this.state.criteria.noteId}
                                />
                                <DateInput
                                    label={`Issuance date`}
                                    changeDateFrom={this.onIssuanceDateFromChange}
                                    changeDateTo={this.onIssuanceDateToChange}
                                    inputValueFrom={this.state.criteria.emissionDateFrom}
                                    inputValueTo={this.state.criteria.emissionDateTo}
                                />
                                <SelectInput
                                    label={'Note type'}
                                    value={this.state.criteria.noteType}
                                    onChange={this.onSelectInputNoteTypeChange}
                                    list={noteTypeOptions}
                                    notAll={true}
                                />
                                <TextInput
                                    label={'Denomination'}
                                    onChange={this.onTextInputDenominationChange}
                                    onEnter={this.search}
                                    value={this.state.criteria.denomination}
                                />
                                <DateInput
                                    label={`Balance date`}
                                    changeDateFrom={this.onBalanceDateFromChange}
                                    changeDateTo={this.onBalanceDateToChange}
                                    inputValueFrom={this.state.criteria.balanceDateFrom}
                                    inputValueTo={this.state.criteria.balanceDateTo}
                                />
                            </>
                        )}
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            <Button variation={'secondary'} clickAction={this.resetCriteria}>
                                {`Clear fields`}
                            </Button>
                            <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    <div style={{marginBottom: 20, textAlign: 'center'}}>
                        {this.state.screenLoaded ? (
                            <CustomTable
                                actions={actions}
                                count={this.state.count}
                                defaultOrder={this.state.criteria.order}
                                formatFunctions={{
                                    note: (fieldData: any) => fieldData.noteId,
                                    amount: (fieldData: any) => `${fieldData}.00 €`,
                                    sendingDate: FORMAT_DATE_EASY,
                                    dueDate: FORMAT_DATE_EASY,
                                    balanceDate: FORMAT_DATE_EASY,
                                }}
                                forehandColumn={(note: any) =>
                                    note.csDocsKey ? (
                                        <img
                                            style={{width: 20, height: 20, cursor: 'pointer'}}
                                            alt={'Download'}
                                            src={getIcon(note.extension || 'pdf')}
                                            onClick={event => {
                                                event.stopPropagation();
                                                note.csDocsKey && this.downloadDocument(note);
                                            }}
                                        />
                                    ) : (
                                        <span>{}</span>
                                    )
                                }
                                id={['noteId', 'applicationNumber']}
                                intl={this.props.intl}
                                onSelectedChange={this.onSelectedChange}
                                pageNumber={this.state.criteria.pageNumber}
                                pageSize={this.state.criteria.pageSize}
                                pagination={true}
                                printExcel={this.printExcel}
                                resultFieldsAll={RESULT_FIELDS_ALL}
                                resultFieldsDefault={DEFAULT_RESULT_FIELDS}
                                reverseOrder={this.state.criteria.reverse}
                                rowClass={getFeeRowClass}
                                selectable={true}
                                tableName={'notes'}
                                tableType={'OBJECT'}
                                tableSource={this.state.notes}
                                timestamp={this.state.timestamp}
                                topCaption={
                                    <MyPVRMyFeesTopCaption
                                        isDownloadEnabled={this.isDownloadEnabled}
                                        downloadNotes={this.downloadNotes}
                                    />
                                }
                                updateCriteriaValue={this.updateCriteriaValue}
                                version={2}
                            />
                        ) : null}
                    </div>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(MyPVRMyFees);
