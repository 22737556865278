import React from 'react';
import cx from 'classnames';
import styles from './SelectInput.module.scss';
import {labelIcons, TLabelIcon} from './TextInput';
// import stylesOld from './SelectInputOld.module.scss';

const ALL = 'All';

const LABEL_POSITION: any = {
    LEFT_INSIDE: 'leftInside',
    LEFT_OUTSIDE: 'leftOutside',
};

interface ISelectInputProps {
    className?: any;
    labelIcon?: TLabelIcon;
    value: any;
    onChange: any;
    label?: string;
    list: {id: any; value: any}[];
    disabled?: boolean;
    notAll?: boolean;
    labelPosition?: 'leftInside' | 'leftOutside';
    mandatory?: boolean;
    style?: any;
}

export default function ({
    className,
    labelIcon,
    mandatory,
    // buttonIcon,
    // buttonAction,
    // infoAction,
    label,
    value,
    onChange,
    list,
    disabled,
    // popOverText,
    // popOverWidth,
    // popOverTextFirstRow,
    notAll,
    labelPosition,
    style: userStyle,
}: // double,
// triple,
// menu,
// outsideLabel,
// outsideLabelWithHTML,
// outsideLabelWidth,
// background,
// error,
// errorVarietyFinder,
// width,
// minWidth,
// height,
// buttonPaddingLeft,
// remark,
// isRemarkTranslationMissing,
// isTranslationMissing,
// oldBackOfficeStyles,
// oldBackOfficeStylesError,
// noIcon,
// size,
// half,
ISelectInputProps) {
    const options = (list || []).map((element: any) => (
        <option
            key={element.id !== undefined ? element.id : element}
            label={element.value !== undefined ? element.value : element}
            value={element.id !== undefined ? element.id : element}
        >
            {element.value !== undefined ? element.value : element}
        </option>
    ));

    return (
        <section
            className={cx(
                styles.section,
                ![LABEL_POSITION.LEFT_INSIDE, LABEL_POSITION.LEFT_OUTSIDE].includes(labelPosition) &&
                    styles[`sectionLabelPositionTop`],
                className ? className : null
            )}
            style={userStyle}
        >
            {label ? (
                <label
                    className={cx(
                        styles.label,
                        labelPosition === LABEL_POSITION.LEFT_INSIDE && styles.labelPositionLeftInside,
                        labelPosition === LABEL_POSITION.LEFT_OUTSIDE && styles.labelPositionLeftOutside,
                        !labelPosition && styles.labelPositionTop
                    )}
                >
                    {labelIcon ? labelIcons[labelIcon] : null}
                    {label || null}
                    {mandatory ? <span className={styles.mandatoryStar}>*</span> : null}
                </label>
            ) : null}
            <div className={styles.inputWrap}>
                <select
                    className={cx(
                        styles.select,
                        labelPosition === LABEL_POSITION.LEFT_INSIDE ? styles.inputHasLabelLeftInside : null
                    )}
                    value={value}
                    onChange={onChange}
                    disabled={disabled ? true : undefined}
                    title={'Select list'}
                >
                    {(!notAll && (
                        <option label={ALL} value={ALL} key={ALL}>
                            {ALL}
                        </option>
                    )) ||
                        null}
                    {options}
                </select>
            </div>
            {/*{outsideLabel && (*/}
            {/*    <div className={styles.label}>*/}
            {/*        <Label width={outsideLabelWidth}>{outsideLabel}</Label>*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*{outsideLabelWithHTML && (*/}
            {/*    <div className={cx(styles.label, isTranslationMissing && styles.translationMissing)}>*/}
            {/*        <Label width={outsideLabelWidth}>{outsideLabelWithHTML}</Label>*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*<InputContainer {...{double, triple, width, minWidth, height, noIcon, size, half}}>*/}
            {/*    <FirstRow>*/}
            {/*        <FirstRowLabel {...{mandatory, popOverTextFirstRow}}>{label}</FirstRowLabel>*/}
            {/*        {infoAction && <FirstRowInfo onClick={infoAction} />}*/}
            {/*    </FirstRow>*/}
            {/*    <SecondRow*/}
            {/*        buttonIcon={popOverText || buttonIcon}*/}
            {/*        {...{disabled, menu, background}}*/}
            {/*        errorBorderColor={errorVarietyFinder}*/}
            {/*        oldBackOfficeStyles={oldBackOfficeStyles}*/}
            {/*    >*/}
            {/*        <select*/}
            {/*            className={cx(*/}
            {/*                styles.select,*/}
            {/*                disabled && styles.selectDisabled,*/}
            {/*                oldBackOfficeStylesError && styles.hasError*/}
            {/*            )}*/}
            {/*            {...{value, onChange}}*/}
            {/*            disabled={disabled && 'disabled'}*/}
            {/*            // ref={el => (elSelect = el)}*/}
            {/*        >*/}
            {/*            {(!notAll && (*/}
            {/*                <option label={ALL} value={ALL} key={ALL}>*/}
            {/*                    {ALL}*/}
            {/*                </option>*/}
            {/*            )) ||*/}
            {/*                null}*/}
            {/*            {options}*/}
            {/*        </select>*/}
            {/*        <div style={{clear: 'both'}}>{}</div>*/}
            {/*    </SecondRow>*/}
            {/*{popOverText && <CustomPopoverInput content={popOverText} popOverWidth={popOverWidth} />}*/}
            {/*{remark && (*/}
            {/*    <div>*/}
            {/*        <Remark remark={remark} isRemarkTranslationMissing={isRemarkTranslationMissing} />*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*{buttonIcon && buttonIcon !== 'none' && (*/}
            {/*    <button*/}
            {/*        type="button"*/}
            {/*        className={styles.button}*/}
            {/*        onClick={buttonAction}*/}
            {/*        style={buttonPaddingLeft && {paddingLeft: buttonPaddingLeft}}*/}
            {/*    >*/}
            {/*        <FontAwesomeIcon icon={buttonIcon} color="green" />*/}
            {/*    </button>*/}
            {/*)}*/}
            {/*{error && (*/}
            {/*    <div className={styles.errorWrap}>*/}
            {/*        <Error wizardResponse={true}>{error}</Error>*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*{errorVarietyFinder ? (*/}
            {/*    <>*/}
            {/*        <div style={{clear: 'both'}} />*/}
            {/*        <div style={{marginLeft: 3, marginTop: 3, color: 'red'}}>{errorVarietyFinder}</div>*/}
            {/*    </>*/}
            {/*) : null}*/}
            {/*</InputContainer>*/}
        </section>
    );
}
