import {API_SERVERLESS, getFetch, postFetch} from './index';

export function apiDocumentsSearch(criteria, DEFAULT_CRITERIA, excelTranslations) {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== (DEFAULT_CRITERIA || {})[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/common/v3/documents?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}

export function apiDocumentsSearchCount(criteria, DEFAULT_CRITERIA) {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== (DEFAULT_CRITERIA || {})[i])
        .map(k => `${k}=${params[k]}`);
    return getFetch(`${API_SERVERLESS}/common/v3/documentsCount?${requestParams.join('&')}`, true);
}
