import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import CustomTable from '~components/CustomTable';
import {injectIntl} from 'react-intl';
import OnlinePdfModalRemedyReviewActions from './OnlinePdfModalRemedyReviewActions';
import {REMEDY_TYPES} from '../../Wizard/responses/ResponseWrapper/ResponseWrapperModalRemedyResponse';
import styles from '../../../components/CustomTableModalFieldChooser.module.scss';
import {Button} from '../../../componentsFormV2';

class OnlinePdfModalRemedyReview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timestamp: Date.now(),
        };
    }

    onTableIconDeleteClick = rowObject => {
        const storedRemedy = JSON.parse(localStorage.getItem(`REMEDY_${this.props.applicationCode}`) || '{}') || {};
        delete storedRemedy[rowObject.uniqueId];
        localStorage.setItem(`REMEDY_${this.props.applicationCode}`, JSON.stringify(storedRemedy));
        this.setState({timestamp: Date.now()});
    };

    onTableIconEditClick = rowObject => {
        const {uniqueId} = rowObject;
        let editButton = document.getElementById(`REMEDY_${uniqueId}`);
        if (editButton) {
            this.props.close();
            editButton.click();
        }
    };

    onButtonDiscardClick = () => {
        localStorage.removeItem(`REMEDY_${this.props.applicationCode}`);
        this.props.close(true);
    };

    render() {
        const actions = OnlinePdfModalRemedyReviewActions(this.onTableIconEditClick, this.onTableIconDeleteClick);
        const storedRemedy = JSON.parse(localStorage.getItem(`REMEDY_${this.props.applicationCode}`) || '{}') || {};
        const tableSource = Object.values(storedRemedy).map(remedy => ({
            ...remedy,
            type: REMEDY_TYPES[remedy.type],
        }));

        return (
            <div>
                <ModalCustomVersion2
                    close={this.props.close}
                    header={<h2>Remedy Review</h2>}
                    body={
                        <div>
                            <CustomTable
                                {...this.props}
                                tableName={'onlinePdfRemedyReview'}
                                tableType={'OBJECT'}
                                tableSource={tableSource}
                                id={'uniqueId'}
                                resultFieldsDefault={['type', 'details']}
                                intl={this.props.intl}
                                hideExcelButton={true}
                                timestamp={this.state.timestamp}
                                actions={actions}
                                notSortable={true}
                                unlimited={true}
                            />
                        </div>
                    }
                    footer={
                        <div className={styles.footerWrap}>
                            <div className={styles.footerLeft}>
                                <Button
                                    clickAction={this.onButtonDiscardClick}
                                    variation={'secondary'}
                                >{`Discard`}</Button>
                            </div>
                            <div className={styles.footerRight}>
                                <Button
                                    icon={'close'}
                                    clickAction={() => this.props.close()}
                                    variation={'danger'}
                                >{`Close`}</Button>
                                <Button icon={'arrowRight'} clickAction={this.close}>{`Submit Remedy`}</Button>
                            </div>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default injectIntl(OnlinePdfModalRemedyReview);
