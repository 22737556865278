import React from 'react';
import cx from 'classnames';
import styles from './TextAreaInput.module.scss';
import {LABEL_POSITION, labelIcons, TButtonIcon, TLabelIcon, TLabelPosition} from '../componentsFormV2/TextInput';

interface ITextAreaInputProps {
    buttonIcon?: TButtonIcon;
    buttonText?: any;
    className?: any;
    counter?: number;
    disabled?: boolean;
    dynamicHeight?: boolean;
    label?: string;
    labelIcon?: TLabelIcon;
    labelPosition?: TLabelPosition;
    mandatory?: boolean;
    maxCounter?: number;
    onChange: any;
    onClick?: any;
    placeholder?: string;
    rows?: number;
    style?: any;
    value: string;
}

export default function ({
    buttonIcon,
    buttonText,
    className,
    counter,
    disabled,
    dynamicHeight,
    label,
    labelIcon,
    labelPosition,
    mandatory,
    maxCounter,
    onChange,
    onClick,
    placeholder,
    rows,
    style: userStyle,
    value,
}: ITextAreaInputProps) {
    const MIN_TEXTAREA_HEIGHT = 32;
    const textareaRef = React.useRef<any>(null);
    React.useLayoutEffect(() => {
        if (dynamicHeight && textareaRef && textareaRef.current) {
            textareaRef.current.style.height = 'inherit';
            textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, MIN_TEXTAREA_HEIGHT)}px`;
        }
    }, [value]);

    const normalStyle = rows && !isNaN(rows) && rows > 0 && {height: `${rows * 35}px`};

    return (
        <section
            className={cx(
                styles.section,
                ![LABEL_POSITION.LEFT_INSIDE, LABEL_POSITION.LEFT_OUTSIDE].includes(labelPosition) &&
                    styles[`sectionLabelPositionTop`],
                className ? className : null
            )}
            style={userStyle}
        >
            {label ? (
                <label
                    className={cx(
                        styles.label,
                        labelPosition === LABEL_POSITION.LEFT_INSIDE && styles.labelPositionLeftInside,
                        labelPosition === LABEL_POSITION.LEFT_OUTSIDE && styles.labelPositionLeftOutside,
                        !labelPosition && styles.labelPositionTop
                    )}
                >
                    {labelIcon ? labelIcons[labelIcon] : null}
                    {label || null}
                    {mandatory ? <span className={styles.mandatoryStar}>*</span> : null}
                </label>
            ) : null}
            <div className={styles.inputWrap}>
                <textarea
                    ref={textareaRef}
                    className={cx(
                        styles.area,
                        !buttonIcon && !buttonText && styles.inputNoButton,
                        labelPosition !== LABEL_POSITION.LEFT_INSIDE && styles.inputLabelIsOutside,
                        disabled && styles.areaDisabled
                    )}
                    value={value || ''}
                    onClick={onClick}
                    onChange={onChange}
                    disabled={disabled ? true : undefined}
                    placeholder={placeholder}
                    style={{
                        ...(dynamicHeight
                            ? {
                                  minHeight: MIN_TEXTAREA_HEIGHT,
                                  resize: 'none',
                              }
                            : normalStyle),
                        ...(onClick ? {caretColor: 'transparent', cursor: 'pointer'} : {}),
                    }}
                />
                {counter && counter > 0 ? (
                    <div style={{display: 'table-cell', width: 40}}>
                        <div
                            className={cx(styles.counter, counter === maxCounter && styles.maxCounterLimit)}
                        >{`${counter}/${maxCounter || 150}`}</div>
                    </div>
                ) : null}
            </div>
        </section>
    );
}
