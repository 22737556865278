import React from 'react';

// PROPS
import {FileSearchTableAreaProps} from './Props/FileSearchTableAreaProps';

// INTERFACES
import {DocumentsTableRowData} from '../../Interfaces/FileSearchInterfaces';

// REUSABLE COMPONENTS & FUNCTIONS
import CustomTable from '../../../../../../components/CustomTable';
import {FORMAT_DATE} from '../../../../../../components/FormatFunctions';

export default function FileSearchTableArea(props: FileSearchTableAreaProps) {
    const onClickFileName = (rowObject: DocumentsTableRowData) => {
        props.downloadFile && props.downloadFile(rowObject.uuid, rowObject.fileName);
    };

    const getFileName = (fileName: string, rowObject: DocumentsTableRowData) => {
        return (
            <div title="Download File">
                <a onClick={() => onClickFileName(rowObject)}>{fileName}</a>
            </div>
        );
    };

    return (
        <React.Fragment>
            {props.documentsList !== null && <div style={{clear: 'both', paddingTop: 30}}>{}</div>}
            <CustomTable
                {...props}
                version={2}
                tableName={'documentsList'}
                tableType={'OBJECT'}
                tableSource={props.documentsList}
                id={'fileCode'}
                timestamp={props.timestamp}
                hideExcelButton={true}
                resultFieldsAll={props.resultFields}
                resultFieldsDefault={props.resultFields}
                formatFunctions={{
                    fileName: (fileName: string, rowObject: DocumentsTableRowData) => getFileName(fileName, rowObject),
                    fileDate: FORMAT_DATE,
                    treeDate: FORMAT_DATE,
                }}
                intl={props.intl}
                defaultOrder={'treeDate'}
                reverseOrder={true}
                pageSize={50}
                count={props.documentsList !== null ? props.documentsList.length : 0}
            />
            <div style={{clear: 'both'}}>{}</div>
        </React.Fragment>
    );
}
