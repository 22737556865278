import {API_SERVERLESS, getFetch, postFetch} from '../../commonApi';
import {INote, INoteLine} from '../../types';

export function apiNotesSearch(
    criteria?: any,
    DEFAULT_CRITERIA?: any,
    excelTranslations?: any
): Promise<{
    data: {
        count?: number;
        notes?: INote[];
        token?: any;
    };
}> {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== (DEFAULT_CRITERIA || {})[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/common/v3/notes?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}

export function apiGetNote(noteId: string): Promise<{
    notelines: INoteLine[];
}> {
    return getFetch(`${API_SERVERLESS}/common/v3/notes/${noteId}`, true);
}
