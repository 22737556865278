import React from 'react';
import {formatDateTimeEasy} from '~components/FormatFunctions';
import styles from './ModalPaymentInfoVersion2.module.scss';

interface IModalPaymentInfoBodyVersion2 {
    officeId?: any;
    applicationRequestCode?: any;
    applicationSignDateString?: any;
    applicationSpeciesInput?: any;
}

export default function ({
    officeId,
    applicationRequestCode,
    applicationSignDateString,
    applicationSpeciesInput,
}: IModalPaymentInfoBodyVersion2) {
    return (
        <>
            {officeId === 1 ? (
                <>
                    <h2 className={styles.printModalTitle}>{`CPVO - application signed`}</h2>
                    <div
                        className={styles.modalSignedSubtitle}
                    >{`The application ${applicationRequestCode} was signed at ${formatDateTimeEasy(
                        applicationSignDateString
                    )}`}</div>
                    <div>
                        <div>
                            <div className={styles.modalSignedTitle}>PAYMENT REQUEST</div>
                        </div>
                        <ul className={styles.modalSignedList}>
                            <li>{`REFERENCE: ${applicationRequestCode}`}</li>
                            <li>{`VARIETY: ${applicationSpeciesInput}`}</li>
                            <li>
                                {`AMOUNT`}: <strong>€ 450</strong>
                            </li>
                        </ul>
                        <div className={styles.modalSignedParagraph}>
                            {`Thank you for your application. In accordance with the fee regulation of the Community Plant Variety Office, the applicant shall take the necessary steps for payment of the € 450 application fee prior to or on the date on which the application is filed at the Office. In case for some reason that the payment has not yet been made, please proceed with the payment `}
                            <strong>{`using the reference cited above`}</strong>
                            {` to the following account:`}
                        </div>
                        <ul className={styles.modalSignedList}>
                            <li>
                                <span className={styles.modalSignedListItemTitle}>{`ACCOUNT: `}</span>
                                <span>{`COMMUNITY PLANT VARIETY OFFICE`}</span>
                            </li>
                            <li>
                                <span className={styles.modalSignedListItemTitle}>{`IBAN: `}</span>
                                <span>{`FR76 1790 6000 3215 8665 4800 044`}</span>
                            </li>
                            <li>
                                <span className={styles.modalSignedListItemTitle}>{`BIC: `}</span>
                                <span>{`AGRIFRPP879`}</span>
                            </li>
                        </ul>
                    </div>
                </>
            ) : (
                <>
                    <h2 className={styles.printModalTitle}>{`Board for Plant Varieties - application signed`}</h2>
                    <div
                        className={styles.modalSignedSubtitle}
                    >{`The application ${applicationRequestCode} was signed at ${formatDateTimeEasy(
                        applicationSignDateString
                    )}`}</div>
                    <div>
                        <div>
                            <div className={styles.modalSignedTitle}>PAYMENT REQUEST</div>
                        </div>
                        <ul className={styles.modalSignedList}>
                            <li>{`REFERENCE: ${applicationRequestCode}`}</li>
                            <li>{`VARIETY: ${applicationSpeciesInput}`}</li>
                            <li>
                                {`AMOUNT: `}
                                <a
                                    href={`https://www.raadvoorplantenrassen.nl/en/plant-breeders-rights-and-or-listing/applicable-fees/`}
                                    target={`_blank`}
                                    rel="noopener noreferrer"
                                >
                                    {`See Applicable fees - Raad voor plantenrassen`}
                                </a>
                            </li>
                        </ul>
                        <div className={styles.modalSignedParagraph}>
                            {`Thank you for your application. In accordance with the fee regulation of the Board for plant varieties, the applicant will receive an invoice for the payment of the application fee prior to or on the date on which the application is filed at the Board for plant varieties. In case the closing date for the application is on due course, the applicant may decide to use the undermentioned bank-account fort he fulfillment of the invoice for the application fee.`}
                        </div>
                        <ul className={styles.modalSignedList}>
                            <li>
                                <span className={styles.modalSignedListItemTitle}>{`ACCOUNT: `}</span>
                                <span>{`NAKTUINBOUW`}</span>
                            </li>
                            <li>
                                <span className={styles.modalSignedListItemTitle}>{`IBAN: `}</span>
                                <span>{`NL82RABO0300017057`}</span>
                            </li>
                            <li>
                                <span className={styles.modalSignedListItemTitle}>{`BIC: `}</span>
                                <span>{`RABONL2U`}</span>
                            </li>
                        </ul>
                    </div>
                </>
            )}
        </>
    );
}
