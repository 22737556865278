import React, {useEffect, useRef, useState} from 'react';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import {ModalErrorVersion2} from '../../../../commonModals';
import NavigationTLO from '../../../../shared/NavigationTLO';
import ReportingDeadlinesTableArea from './Components/ReportingDeadlinesTableArea/ReportingDeadlinesTableArea';
import {
    apiReportingDeadlinesDownload,
    apiReportingDeadlinesSearch,
    ReportingDeadlinesTableCriteriaData,
    ReportingDeadlinesTableRowData,
} from './ReportingDeadlinesPageService';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import SubMenuTabs from '../../SubMenu/SubMenuTabs';
import {getUserRoles} from '../../CommonFunctions/CommonFunctions';
import {getExcelSignedURLFetchRequest} from '../../../../commonApi/download';
import {getPreSignedURLFetchRequest} from '../../../../utils/requests';
import {injectIntl, IntlShape} from 'react-intl';
import moment from 'moment';
import {saveAs} from 'file-saver';
import {History} from 'history';
import {trackPageView} from '../../../../utils';

const DEFAULT_CRITERIA: ReportingDeadlinesTableCriteriaData = {
    pageNumber: 1,
    refresh: false,
    excel: false,
};

const modalScreenTypes = {ERROR: 'ERROR'};

interface ReportingDeadlinesPageProps {
    intl: IntlShape;
    history: History;
}

const ReportingDeadlinesPage = (props: ReportingDeadlinesPageProps & RouteComponentProps) => {
    const [criteria, setCriteria] = useState<ReportingDeadlinesTableCriteriaData>(Object.assign({}, DEFAULT_CRITERIA));
    const [, setCriteriaCount] = useState(0);
    const [deadlines, setDeadlines] = useState<Array<ReportingDeadlinesTableRowData> | null>(null);
    const [timestamp, setTimestamp] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [refresh] = useState(true);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const firstLoad = useFirstRender();
    const [userRoles] = useState(getUserRoles());

    useEffect(() => {
        trackPageView({documentTitle: 'tloMyContractReportingDeadlines'});
    }, []);

    function useFirstRender() {
        const firstRender = useRef(true);
        useEffect(() => {
            firstRender.current = false;
        }, []);
        return firstRender.current;
    }

    const getMenuLinks = () => {
        return userRoles.indexOf('WETLO') === -1 &&
            userRoles.indexOf('TLOOF') === -1 &&
            userRoles.indexOf('EOADM') === -1
            ? []
            : [
                  {
                      id: 'DESIGNATION_AGREEMENT',
                      value: 'Designation Agreement',
                      path: 'tloMyContractDesignationAgreement',
                  },
                  {
                      id: 'SCOPE_OF_ENTRUSTMENT',
                      value: 'Scope of Entrustment',
                      path: 'tloMyContractScopeOfEntrustment',
                  },
                  {
                      id: 'CONSULT_MODIFY_SPECIES_DATA',
                      value: 'Consult / Modify Species Data',
                      path: 'tloMyContractConsultModifySpecies',
                  },
                  {id: 'REPORTING_DEADLINES', value: 'Reporting Deadlines', path: 'tloMyContractReportingDeadlines'},
                  {
                      id: 'REQUEST_NEW_ENTRUSTMENT',
                      value: 'Request New Entrustment',
                      path: 'tloMyContractRequestNewEntrustment',
                  },
                  {
                      id: 'WITHDRAW_AN_ENTRUSTMENT',
                      value: 'Withdraw an Entrustment',
                      path: 'tloMyContractWithdrawAnEntrustment',
                  },
                  {
                      id: 'NEW_SPECIES_PROCEDURE',
                      value: 'New Species Procedure',
                      path: 'tloMyContractNewSpeciesProcedure',
                  },
                  {
                      id: 'MANUAL',
                      value: 'Contract’s User Manual',
                      path: 'tlodocs/manual/TLO_user_manual_new_revised_by_LDE.pdf',
                  },
              ];
    };

    const countCriteria = (deadlinesCriteria: ReportingDeadlinesTableCriteriaData) => {
        let countNum = 0;
        Object.keys(deadlinesCriteria).forEach(key => {
            if (
                deadlinesCriteria[key] &&
                deadlinesCriteria[key] !== DEFAULT_CRITERIA[key] &&
                deadlinesCriteria[key] !== ''
            )
                countNum++;
        });
        return countNum;
    };

    const parseCriteria = (
        unParsedCriteria: ReportingDeadlinesTableCriteriaData
    ): ReportingDeadlinesTableCriteriaData => {
        const parsedCriteria: ReportingDeadlinesTableCriteriaData = JSON.parse(JSON.stringify({}));
        Object.keys(unParsedCriteria).forEach(key => {
            parsedCriteria[key] = unParsedCriteria[key];
        });
        return parsedCriteria;
    };

    const buildParams = (parsedCriteria: ReportingDeadlinesTableCriteriaData) => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(key => parsedCriteria[key] !== DEFAULT_CRITERIA[key] && key !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key]}`);
        const joinedParamsText = `?${paramArray.join('&')}`;
        props.history.replace(`/tloMyContractReportingDeadlines${(paramArray.length > 0 && joinedParamsText) || ''}`);
    };

    const search = (shouldRefresh: boolean, urlLoad = false) => {
        setLoading(true);
        setCriteria(
            Object.assign({}, criteria, {refresh: !!shouldRefresh}, !shouldRefresh && !urlLoad && {pageNumber: 1})
        );
        const parsedCriteria = parseCriteria(criteria);
        buildParams(parsedCriteria);
        apiReportingDeadlinesSearch(parsedCriteria)
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.deadlines) {
                    const newCriteria = Object.assign({}, criteria, {pageNumber: 1});
                    if (!shouldRefresh && !urlLoad) {
                        setCriteria(Object.assign({}, newCriteria));
                    }
                    setDeadlines(jsonResponse.data.deadlines);
                    setTimestamp(Date.now());
                }
            })
            .catch(error => {
                setErrorMessage(`Reporting Deadlines search list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const params = domainArray.pop();
            if (params) {
                params.split('&').forEach(param => {
                    const paramElements = param.split('=');
                    if (paramElements.length === 2) {
                        const key = paramElements[0];
                        criteria[key] = decodeURIComponent(paramElements[1]);
                    }
                });
                if (Object.keys(criteria).length > 0) {
                    setCriteria(criteria);
                    setCriteriaCount(countCriteria(criteria));
                    search(false, true);
                }
            }
        }
    };

    const closeErrorModal = () => {
        setErrorMessage(null);
        setModalScreen(null);
    };

    useEffect(() => {
        (userRoles.indexOf('WETLO') !== -1 || userRoles.indexOf('TLOOF') !== -1 || userRoles.indexOf('EOADM') !== -1) &&
            firstLoad &&
            loadUrlParams();
    }, []);

    useEffect(() => {
        !firstLoad && refresh && search(true);
    }, [criteria.pageNumber]);

    useEffect(() => {
        (userRoles.indexOf('WETLO') !== -1 || userRoles.indexOf('TLOOF') !== -1 || userRoles.indexOf('EOADM') !== -1) &&
            firstLoad &&
            search(false, true);
    }, []);

    const downloadDocument = (rowObject: ReportingDeadlinesTableRowData, excelTranslations: {}) => {
        setLoading(true);
        apiReportingDeadlinesDownload(rowObject.examContractId, excelTranslations)
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                    getExcelSignedURLFetchRequest(jsonResponse.data.token).then(response => {
                        if (response && response.signedUrl) {
                            const {signedUrl} = response;
                            const officeName = rowObject.addressName.replace(' ', '_');
                            getPreSignedURLFetchRequest(signedUrl)
                                .then(response => response.blob())
                                .then(responseBlob => {
                                    saveAs(
                                        responseBlob,
                                        `Reporting_deadlines_${officeName}_${moment().format('DD/MM/YYYY')}.xlsx`
                                    );
                                });
                        }
                    });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`Technical Liaison Officer`} />
            <NavigationTLO activeTitle={`My Contract`} />
            <MainWrapper>
                <FormWrapper paddingFormContent={'sm'}>
                    {userRoles.indexOf('WETLO') === -1 &&
                    userRoles.indexOf('TLOOF') === -1 &&
                    userRoles.indexOf('EOADM') === -1 ? (
                        <div style={{paddingTop: 20}}>
                            <b style={{color: 'red'}}>
                                <span className="ng-scope">{`You have no permissions to access this page.`}</span>
                            </b>
                        </div>
                    ) : (
                        <>
                            <SubMenuTabs tabId={'REPORTING_DEADLINES'} loading={loading} menuLinks={getMenuLinks()} />
                            <ReportingDeadlinesTableArea
                                intl={props.intl}
                                timestamp={timestamp}
                                deadlines={deadlines}
                                downloadDocument={downloadDocument}
                            />
                        </>
                    )}
                </FormWrapper>
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(ReportingDeadlinesPage));
