import React from 'react';

interface IIconLogoutProps {
    style?: any;
    className?: any;
    color?: string;
}

function IconLogout({style: userStyle, color, className}: IIconLogoutProps) {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={userStyle ? userStyle : {}}
            className={className}
        >
            <path
                d="M7 9H17M17 9L14 6M17 9L14 12"
                stroke={color || '#810202'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 9C1 6.87827 1.84285 4.84344 3.34315 3.34315C4.84344 1.84285 6.87827 1 9 1M9 17C7.80058 17.0011 6.61633 16.7319 5.53518 16.2126C4.45404 15.6932 3.50379 14.937 2.755 14"
                stroke={color || '#810202'}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
}

export default IconLogout;
