import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faPlusSquare, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {TreeElement} from '../TLO/CommonComponents/TreeView/Interfaces/TreeViewInterfaces';

function renderButton(popupContent: string, handler: (id: any, rowObject: TreeElement) => void, icon: any) {
    return {
        title: popupContent,
        icon: (rowObject: TreeElement) => {
            const arrowJsx = (popupContent: string) => (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon as any} />
                </a>
            );
            switch (icon) {
                case faPlusSquare:
                    return !rowObject.isContent && !rowObject.isContentItem ? <div /> : arrowJsx(popupContent);
                case faEdit:
                case faTrashAlt:
                    return !rowObject.isContent && !rowObject.isContentItem && rowObject.level > 3 ? (
                        <div />
                    ) : (
                        arrowJsx(popupContent)
                    );
                default:
                    return arrowJsx(popupContent);
            }
        },
        handler,
    };
}

export default function ACAreaAdministratorContentsActionButtons(
    openModalContentItemForAddition: any,
    openModalContentDocumentForAddition: any,
    openModalContentForModify: (rowObject: TreeElement) => void,
    openModalContentItemForModify: (rowObject: TreeElement) => void,
    openModalContentDocumentForModify: (rowObject: TreeElement) => void,
    openModalConfirmDeletion: (rowObject: TreeElement) => void
) {
    const buttons = [];
    buttons.push(
        renderButton(
            `New Child`,
            (_id: number, rowObject: TreeElement) => {
                rowObject.isContent
                    ? openModalContentItemForAddition(rowObject)
                    : openModalContentDocumentForAddition(rowObject);
            },
            faPlusSquare
        )
    );
    buttons.push(
        renderButton(
            `Modify`,
            (_id: number, rowObject: TreeElement) => {
                rowObject.isContent
                    ? openModalContentForModify(rowObject)
                    : rowObject.isContentItem
                    ? openModalContentItemForModify(rowObject)
                    : openModalContentDocumentForModify(rowObject);
            },
            faEdit
        )
    );
    buttons.push(
        renderButton(
            `Delete`,
            (_id: number, rowObject: TreeElement) => {
                openModalConfirmDeletion(rowObject);
            },
            faTrashAlt
        )
    );
    return buttons;
}
