import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen, faTrash} from '@fortawesome/free-solid-svg-icons';

function renderButton(popupContent, handler, icon) {
    return {
        title: popupContent,
        icon: () => {
            return (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon} />
                </a>
            );
        },
        handler,
    };
}

export default function OnlinePdfModalRemedyReviewActions(onTableIconEditClick, onTableIconDeleteClick) {
    const buttons = [];
    buttons.push(renderButton('Edit', (_, rowObject) => onTableIconEditClick(rowObject), faPen));
    buttons.push(renderButton('Delete', (_, rowObject) => onTableIconDeleteClick(rowObject), faTrash));
    return buttons;
}
