import React from 'react';
import {injectIntl} from 'react-intl';
import Button from '../componentsFormV2/Button';
import ModalCustomVersion2 from './ModalCustomVersion2';

interface IProps {
    intl: any;
    close: any;
    title?: any;
    message: any;
}

interface IState {}

class ModalAlertVersion2 extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    close = () => {
        this.props.close && this.props.close();
    };

    render() {
        return (
            <ModalCustomVersion2
                close={this.close}
                plus={2}
                header={this.props.title || 'Alert'}
                body={this.props.message}
                footer={<Button icon={'close'} clickAction={this.close} variation={'danger'}>{`Close`}</Button>}
                size={'sm'}
                variation={'danger'}
            />
        );
    }
}

export default injectIntl(ModalAlertVersion2);
