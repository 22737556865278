import {API_SERVERLESS, getFetch} from '../../commonApi';
import {apiCommunicationCentreGetCommunicationIdUploadFilesAndSendMessage} from '../CommunicationCentreClient/CommunicationCentreClientService';
import {ICommunicationMessageClientSubmitJSONRequest, IDocument} from '../../types';

export function apiCommunicationCentreModalClientDraftReadMessage(communicationId: number) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreModalClient/v4/CCMCDR/draft/messageRead/${communicationId}`,
        true
    );
}

export function apiGetCommunicationIdUploadFilesAndSendMessage(
    JSONRequest: ICommunicationMessageClientSubmitJSONRequest,
    documentList: IDocument[]
) {
    return apiCommunicationCentreGetCommunicationIdUploadFilesAndSendMessage(JSONRequest, documentList, {
        getCommunicationId: 'communicationCentreModalClient/v4/CCMCDGID/draft/getCommunicationId',
        documentUploadFile: 'communicationCentreModalClient/v4/CCMCDUF/draft/documentUploadFile',
        submitRequest: 'communicationCentreModalClient/v4/CCMCDS/draft/messageSend',
    });
}

export function apiCommunicationCentreModalClientDraftGetFileInfo(applicationNumber: string) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreModalClient/v4/CCMCDCAN/draft/getFileInfoIfExists/${applicationNumber}`,
        true
    );
}

export function apiCommunicationCentreModalClientDraftDownloadDocument({communicationId, serverIndex}: any) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreModalClient/v4/CCMCDMDD/draf/downloadDocument/${communicationId}/${serverIndex}`,
        true
    );
}
