import React from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import styles from './ModalLogicType.module.scss';
import Number1 from './screenshots/Number_1.PNG';
import {Button} from '../../../componentsFormV2';

const ModalLogicTypeNumberHelp = ({close, responseLogicType}) => {
    const {name} = responseLogicType || {};

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <>
                    <p className={styles.modalHelpParagraph}>
                        Displays a number input like the following that allows the user to enter a number:
                    </p>
                    <img src={Number1} alt="Number" className={styles.modalHelpImage} />
                </>
            }
            footer={<Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>}
        />
    );
};

export default ModalLogicTypeNumberHelp;
