import React from 'react';
import {injectIntl} from 'react-intl';
import ApplicationConsultationTabs, {TAB} from '~shared/ApplicationConsultationTabs';
import {FORMAT_DATE_EASY} from '~components/FormatFunctions';
import CustomTable from '~components/CustomTable';
import {apiPublicSearchPublications} from '~commonApi/publicSearch';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faList} from '@fortawesome/free-solid-svg-icons';
import {Footer, FormWrapper, HeaderLoading, HeaderLogo, HeaderTitleAndVersion} from '../../componentsLayout';
import NavigationPublicSearch from '../../shared/NavigationPublicSearch';
import MainWrapper from '../../componentsLayout/MainWrapper';
import {trackPageView} from '../../utils';

class MyPVRPublicConsultationPublications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {publications: null, timestamp: null, loading: false};
    }

    componentDidMount() {
        trackPageView({documentTitle: 'publicConsultationPublications'});
    }

    loadJSONs = applicationNumber => {
        return new Promise((resolve, reject) => {
            this.setState({loading: true}, () => {
                apiPublicSearchPublications(applicationNumber)
                    .then(jsonResponse => {
                        jsonResponse.data && this.setState({publications: jsonResponse.data, timestamp: Date.now()});
                    })
                    .then(() => this.setState({loading: false}, resolve))
                    .catch(reject);
            });
        });
    };

    render() {
        return (
            <>
                {this.state.loading ? <HeaderLoading /> : null}
                <HeaderLogo />
                <HeaderTitleAndVersion title={'CPVO Application Consultation'} />
                <NavigationPublicSearch />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <ApplicationConsultationTabs
                            search={this.loadJSONs}
                            tabId={TAB.PUBLICATIONS}
                            loading={this.state.publications === null}
                            close={this.props.close}
                        />
                        {this.state.publications !== null ? (
                            this.state.publications.length > 0 ? (
                                <CustomTable
                                    tableName={'publications'}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.publications}
                                    timestamp={this.state.timestamp}
                                    id={'gazetteId'}
                                    resultFieldsDefault={['denomination', 'publicationDate', 'chapterId']}
                                    intl={this.props.intl}
                                    defaultOrder={'publicationDate'}
                                    formatFunctions={{
                                        publicationDate: FORMAT_DATE_EASY,
                                        chapterId: (fieldData, rowObject) => (
                                            <React.Fragment>
                                                <div style={{float: 'left', width: 50}}>{fieldData}</div>
                                                <div style={{float: 'left'}}>{`${rowObject['chapterName']}`}</div>
                                            </React.Fragment>
                                        ),
                                    }}
                                    actions={[
                                        {
                                            title: 'Download gazette',
                                            icon: rowObject =>
                                                rowObject.publicationDate && rowObject.gazetteFile ? (
                                                    <a
                                                        href={rowObject.gazetteFile}
                                                        target={`_blank`}
                                                        rel="noopener noreferrer"
                                                    >
                                                        <FontAwesomeIcon icon={faList} color="green" />
                                                    </a>
                                                ) : null,
                                            handler: this.downloadGazette,
                                        },
                                    ]}
                                />
                            ) : (
                                <div className="form-group">
                                    <b className="col-sm-offset-2 col-sm-10" style={{color: 'red'}} data-translate="">
                                        <span className="ng-scope">No result found</span>
                                    </b>
                                </div>
                            )
                        ) : null}
                    </FormWrapper>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(MyPVRPublicConsultationPublications);
