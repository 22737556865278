import React, {useState} from 'react';
import {injectIntl, IntlShape} from 'react-intl';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import moment from 'moment';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ModalCustomVersion2} from '../../../commonModals';
import TextInput from '~components/TextInput';
import TextAreaInput from '~components/TextAreaInput';
import Checkbox from '~components/Checkbox';
import DateInput from '~components/DateInput';
import Empty from '~components/Empty';
import {Error} from '../../../componentsLayout';
import {getConcatErrorMessage} from '../../TLO/CommonFunctions/CommonFunctions';
import {History} from 'history';
import {Button} from '../../../componentsFormV2';

export const sections = {
    AC_Meetings: 1,
    Written_Procedures: 2,
    Legal_Working_Group: 3,
};

interface ModalContentProps {
    intl: IntlShape;
    history: History;
    contentElement: any;
    saveContent: (saveContentElement: any) => void;
    close: () => void;
}

const ModalContent = (props: ModalContentProps & RouteComponentProps) => {
    const [contentId] = useState(props.contentElement.id || 0);
    const [title, setTitle] = useState(props.contentElement.title || '');
    const [location, setLocation] = useState(props.contentElement.location || '');
    const [additionalInfo, setAdditionalInfo] = useState(props.contentElement.additionalInfo || '');
    const [beginDate, setBeginDate] = useState(props.contentElement.beginDate || '');
    const [isOnline, setIsOnline] = useState(props.contentElement.isOnline || false);
    const [lastUpdateDate] = useState(props.contentElement.lastUpdateDate || moment().format('DD/MM/YYYY'));
    const [parentId] = useState(props.contentElement.parentId || 0);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [highlightErrors, setHighlightErrors] = useState(false);

    const getSection = (sectionId: number) => {
        let sectionName = '';
        switch (sectionId) {
            case sections.AC_Meetings:
                sectionName = 'AC Meetings';
                break;
            case sections.Written_Procedures:
                sectionName = 'Written Procedures';
                break;
            case sections.Legal_Working_Group:
                sectionName = 'Legal Working Group';
                break;
        }

        return sectionName;
    };

    const ontitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const label = event.target.value;
        setTitle(label);
    };

    const onlocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const label = event.target.value;
        setLocation(label);
    };

    const onadditionalInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const label = event.target.value;
        setAdditionalInfo(label);
    };

    const onBeginDateChange = (beginDate: string) => {
        setBeginDate(beginDate);
    };

    const onCheckIsOnline = () => {
        setIsOnline(!isOnline);
    };

    const prepareContentDataForSave = (): any => {
        return {
            id: contentId,
            order: props.contentElement.order || '0',
            title,
            location,
            additionalInfo,
            beginDate,
            lastUpdateDate,
            isOnline,
            endDate: '',
            rights: [],
            file: [],
            level: props.contentElement.level || 1,
            parentId,
            parentTitle: getSection(parentId),
            showLastUpdateDate: props.contentElement.showLastUpdateDate || false,
            categoryType: 0,
            isCategory: false,
            isContent: true,
            isContentItem: false,
            children: props.contentElement.children || [],
        };
    };

    const getErrorMessagesForSave = () => {
        setHighlightErrors(false);
        const errorMessages: any = {
            errorInput: [],
            errorLogic: [],
        };

        if (title.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a title for the content');
        }

        if (beginDate.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a start date for the content');
        }

        return errorMessages;
    };

    const saveContent = () => {
        const errorMessagesObj = getErrorMessagesForSave();
        let errorMessage = getConcatErrorMessage(errorMessagesObj);

        if (errorMessage.length > 0) {
            setErrorMessage(errorMessage);
            setHighlightErrors(true);
        }

        if (errorMessage.length === 0) {
            const contentData = prepareContentDataForSave();
            props.saveContent && props.saveContent(contentData);
        }
    };

    return (
        <ModalCustomVersion2
            loading={false}
            close={() => props.close && props.close()}
            header={`Content`}
            body={
                <div>
                    <TextInput
                        value={title}
                        outsideLabel={'Name'}
                        outsideLabelWidth={140}
                        onChange={ontitleChange}
                        double={true}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <TextInput
                        value={location}
                        outsideLabel={'Location'}
                        outsideLabelWidth={140}
                        onChange={onlocationChange}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <TextAreaInput
                        outsideLabel={`Additional Info`}
                        outsideLabelWidth={140}
                        value={additionalInfo}
                        double={true}
                        rows={10}
                        onChange={onadditionalInfoChange}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <DateInput
                        changeDateFrom={onBeginDateChange}
                        inputValueFrom={beginDate}
                        label={undefined}
                        outsideLabel={'Full Date'}
                        outsideLabelWidth={140}
                        simple={true}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <DateInput
                        changeDateFrom={undefined}
                        inputValueFrom={lastUpdateDate}
                        label={undefined}
                        outsideLabel={'Last update'}
                        outsideLabelWidth={140}
                        simple={true}
                        disabled={true}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <Empty width={140} />
                    <Checkbox clickAction={onCheckIsOnline} label={`Published`} value={isOnline} double={true} />
                    <div style={{clear: 'both'}}>{}</div>
                </div>
            }
            footer={
                <>
                    {highlightErrors ? (
                        <Error>
                            <FontAwesomeIcon icon={faExclamationCircle as any} color={'white'} />
                            {` ${errorMessage}`}
                        </Error>
                    ) : null}
                    <Button icon={'close'} clickAction={props.close} variation={'danger'}>{`Cancel`}</Button>
                    <Button icon={'arrowRight'} clickAction={saveContent}>{`Save Content`}</Button>
                </>
            }
        />
    );
};

export default injectIntl(withRouter(ModalContent));
