import React from 'react';
import CustomTable from '~components/CustomTable';
import {IGroup} from '../../../types';
import {LoadingBar} from '../../../componentsLayout';
import {ModalCustomVersion2} from '../../../commonModals';
import ModalUsedInVersion2, {rowType} from '../../../commonModals/ModalUsedInVersion2';
import ModalLabelSearchVersion2ActionButtons from './QuestionModalSearchAndAddActionButtons';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import QuestionModalSearchAndAddTableResponses from './QuestionModalSearchAndAddTableResponses';
import QuestionModalSearchAndAddTopCaption from './QuestionModalSearchAndAddTopCaption';
import {
    apiBackOfficeFormQuestionSearch,
    apiBackOfficeLabelAddQuestionToGroup,
    ISearchGroups,
    ISearchQuestionItem,
} from './QuestionModalSearchAndAddService';
import {isUserOfficeNAK as checkIfUserOfficeIsNAK} from '~utils';
import styles from './QuestionModalSearchAndAdd.module.scss';
import {Button} from '../../../componentsFormV2';

const DEFAULT_CRITERIA = {
    officeId: 'All',
    languageId: 'All',
    typeId: 'All',
    contains: '',
    containsFilter: 'contains',
    pageNumber: 1,
    pageSize: 10,
    order: null,
    reverse: false,
    refresh: false,
    questionId: '',
};

const OPTIONS_OFFICE = [
    {id: '1', value: 'CPVO'},
    {id: '21', value: 'NAKTUINBOUW'},
];

const RESULT_FIELDS_DEFAULT = [
    'groupOrder_questionOrder',
    'questionId',
    'questionMessage',
    'groupMessage',
    'questionResponses',
];

const DEFAULT_COUNT = 0;

interface IProps {
    target: any;
    close: () => any;
    formId: number;
    loadFormQuestionsJSONs: (arg0: any, arg1: any, arg2: any, arg3: boolean) => any;
    intl: any;
    steps: IGroup[];
}

interface IQuestionRowObjectQuestionResponse {
    responseOrder: string;
    responseMessage: string;
}

export interface IQuestionRowObject {
    groupOrder_questionOrder: string;
    questionId: number;
    questionMessage: string;
    groupMessage: string;
    questionResponses: IQuestionRowObjectQuestionResponse[];
    sameOffice: boolean;
}

interface IState {
    count: number;
    criteria: any;
    loading: any;
    modalUsedIn: number | false;
    questions: IQuestionRowObject[];
    resultsLoaded: boolean;
    timestamp: number;
}

export default class QuestionModalSearchAndAdd extends React.Component<IProps, IState> {
    groups: {[key: string]: {message: string}} = {};
    isUserOfficeNAK: boolean;

    constructor(props: IProps) {
        super(props);
        this.isUserOfficeNAK = checkIfUserOfficeIsNAK();
        for (const step of props.steps || []) {
            if (step && step.id) {
                this.groups[step.id] = {message: step.message || ''};
            }
        }
        this.state = {
            questions: [],
            criteria: Object.assign(
                {},
                DEFAULT_CRITERIA,
                {
                    languageId: 'EN',
                    typeId: props.target && props.target.typeId,
                    formId: props.formId,
                },
                {
                    officeId: this.isUserOfficeNAK ? '21' : '1',
                }
            ),
            count: DEFAULT_COUNT,
            modalUsedIn: false,
            loading: false,
            resultsLoaded: false,
            timestamp: Date.now(),
        };
    }

    showModalUsedIn = (labelId: number) => this.setState({modalUsedIn: labelId});

    closeModalUsedIn = () => this.setState({modalUsedIn: false});

    requestAddQuestion = (questionId: number) =>
        this.setState({loading: true}, () => {
            apiBackOfficeLabelAddQuestionToGroup({formId: this.props.formId, questionId})
                .then(jsonResponse => {
                    jsonResponse && jsonResponse.data === 'OK' && this.props.close();
                })
                .then(() => {
                    this.props.loadFormQuestionsJSONs && this.props.loadFormQuestionsJSONs(null, null, null, true);
                })
                .catch((error: any) => LOG([`QuestionModalSearchAndAdd ${error}`]));
        });

    search = (refresh?: boolean) => {
        this.setState(
            prev =>
                Object.assign(
                    {},
                    {
                        loading: true,
                        criteria: Object.assign(
                            {},
                            prev.criteria,
                            {refresh: !!refresh},
                            !refresh && !prev.criteria.pageNumber && {pageNumber: 1}
                        ),
                    }
                ),
            () => {
                const {criteria} = this.state;
                const parsedCriteria = Object.assign({}, criteria);
                apiBackOfficeFormQuestionSearch(parsedCriteria, DEFAULT_CRITERIA)
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.questions) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {...prev},
                                    !refresh && {criteria},
                                    {
                                        questions: prepareTableData(
                                            jsonResponse.data.questions,
                                            jsonResponse.data.groups
                                        ),
                                        resultsLoaded: true,
                                        timestamp: Date.now(),
                                    },
                                    jsonResponse.data.COUNT && {count: jsonResponse.data.COUNT}
                                );
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`Register search list error: ${error.message}`]);
                    })
                    .then(() => this.setState({loading: false}));
            }
        );
    };

    resetCriteria = () => {
        this.setState(prev => ({
            criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
            questions: [],
            timestamp: Date.now(),
        }));
    };

    updateCriteriaValue = (criteriaValue: any, callback?: any, refresh?: any) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = criteriaValue.pageNumber && prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.criteria.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse && prev.criteria.reverse !== criteriaValue.reverse;
                return {criteria};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    getOfficeRowClass = (rowObject: IQuestionRowObject) => !rowObject.sameOffice && styles.warning;

    onSelectInputOfficeChange = ({target: {value: officeId}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({officeId});

    onTextInputLabelValueChange = ({target: {value: contains}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({contains});

    onTextInputQuestionIdChange = ({target: {value: questionId}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({questionId});

    render() {
        const actions = ModalLabelSearchVersion2ActionButtons(this.showModalUsedIn);

        return (
            <>
                <ModalCustomVersion2
                    close={this.props.close}
                    header={`Search and add question`}
                    body={
                        <div>
                            <>
                                <TextInput
                                    double={true}
                                    filter={'Contains'}
                                    label={`Label value`}
                                    onChange={this.onTextInputLabelValueChange}
                                    onEnter={() => this.search(false)}
                                    value={this.state.criteria.contains}
                                />
                                <SelectInput
                                    label={`Office`}
                                    list={OPTIONS_OFFICE}
                                    onChange={this.onSelectInputOfficeChange}
                                    value={this.state.criteria.officeId}
                                />
                                <TextInput
                                    label={`Question id`}
                                    onChange={this.onTextInputQuestionIdChange}
                                    onEnter={() => this.search(false)}
                                    value={this.state.criteria.questionId}
                                />
                                <div style={{clear: 'both'}} />
                            </>
                            {this.state.resultsLoaded ? (
                                <CustomTable
                                    version={2}
                                    loading={this.state.loading}
                                    tableName={'searchAndAddQuestion'}
                                    actionName={`Action`}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.questions}
                                    timestamp={this.state.timestamp}
                                    dataFilter={null}
                                    id={'questionId'}
                                    rowClick={this.requestAddQuestion}
                                    pagination={true}
                                    pageNumber={this.state.criteria.pageNumber}
                                    resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                                    notSortable={RESULT_FIELDS_DEFAULT}
                                    intl={this.props.intl}
                                    defaultOrder={this.state.criteria.order}
                                    reverseOrder={this.state.criteria.reverse}
                                    count={this.state.count}
                                    pageSize={this.state.criteria.pageSize}
                                    hideExcelButton={true}
                                    formatFunctions={{
                                        questionResponses: (
                                            questionResponses: IQuestionRowObjectQuestionResponse[]
                                        ) => (
                                            <QuestionModalSearchAndAddTableResponses
                                                fieldData={(questionResponses || [])
                                                    .map(
                                                        response =>
                                                            `${response.responseOrder}. ${response.responseMessage}`
                                                    )
                                                    .join('\n')}
                                            />
                                        ),
                                    }}
                                    actions={actions}
                                    updateCriteriaValue={this.updateCriteriaValue}
                                    topCaption={<QuestionModalSearchAndAddTopCaption />}
                                    rowClass={this.getOfficeRowClass}
                                />
                            ) : null}
                        </div>
                    }
                    footer={
                        this.state.loading ? (
                            <LoadingBar />
                        ) : (
                            <>
                                <Button icon={'close'} clickAction={this.props.close} variation={'danger'}>
                                    {`Close`}
                                </Button>
                                <Button clickAction={this.resetCriteria} variation={'secondary'}>
                                    {`Clear fields`}
                                </Button>
                                <Button clickAction={() => this.search(false)} icon={'arrowRight'}>{`Search`}</Button>
                            </>
                        )
                    }
                />
                {this.state.modalUsedIn ? (
                    <ModalUsedInVersion2
                        plus={2}
                        elementId={this.state.modalUsedIn}
                        intl={this.props.intl}
                        type={rowType.LABEL}
                        close={this.closeModalUsedIn}
                    />
                ) : null}
            </>
        );
    }
}

function prepareTableData(questions: ISearchQuestionItem[], groups: ISearchGroups): IQuestionRowObject[] {
    return questions.map(el => {
        let questionGroup = groups[el.groupId] || {};
        return {
            groupOrder_questionOrder: `${questionGroup.order || '-'}.${el.order}`,
            questionId: el.questionId,
            questionMessage: el.message,
            groupMessage: questionGroup.message,
            questionResponses: el.questionResponses,
            sameOffice: el.sameOffice,
        };
    });
}
