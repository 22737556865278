import React from 'react';
import cx from 'classnames';
import CustomPopoverInput from './CustomPopoverInput';
import styles from './TextLabelInputVersion2.module.scss';
import InputContainer from '~componentsForm/InputContainer';
import FirstRow from '~componentsForm/FirstRow';
import SecondRow from '~componentsForm/SecondRow';
import FirstRowLabel from '~componentsForm/FirstRowLabel';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Label from './Label';
import Error from './Error';
import Remark from './Remark';
import {htmlToText} from '../utils';
import ModalTextLabelInputVersion2 from './TextLabelInputVersion2Modal';

const filterLabelHash = {
    contains: 'Contains',
    starts: 'Starts with',
    equals: 'Equals',
};

const FIRST_LIST_ELEMENT = 0;

export default class TextLabelInput extends React.Component {
    channel = null;
    elInput = null;
    flagDeleted = false;

    constructor(props) {
        super(props);
        this.state = {
            searchList: [],
            highlighted: FIRST_LIST_ELEMENT,
            timestamp: null,
            error: null,
            isModalSearchOpen: false,
            modalSearchResults: [],
            searching: false,
        };
    }

    componentDidUpdate(prevProps, prevSate) {
        prevProps.focus !== this.props.focus && this.elInput && this.elInput.focus();
        if (prevProps.timestamp !== this.props.timestamp) {
            this.setState({timestamp: this.props.timestamp});
            this.searchByName(this.props.value, this.channel);
        }
        if (
            Object.keys(this.props.selectedElements || {}).length !== 1 &&
            this.props.uniqueValue &&
            this.elInput &&
            this.flagDeleted
        ) {
            this.flagDeleted = false;
            this.elInput.focus();
        }
    }

    closeModalSearch = () => this.setState({isModalSearchOpen: false});

    clearList = callback => {
        this.channel = Date.now();
        this.setState({searchList: []}, callback);
    };

    checkAutoSelection = () => {
        const {searchList} = this.state;
        const {autoSelection, onIdChange} = this.props;
        autoSelection && searchList.length === 1 && onIdChange(searchList[0].ID);
    };

    searchByName = (nameCriteria, channel) => {
        if (channel === this.channel && this.props.listByIdName && this.props.value !== '') {
            if (nameCriteria === '') {
                this.clearList && this.clearList();
            } else {
                this.setState(prev => {
                    let filteredData;
                    if (this.props.filterContains || this.props.currentFilter === 'contains') {
                        filteredData = this.props.listByIdName.filter(
                            i =>
                                i.NAME.toUpperCase().indexOf(nameCriteria.toUpperCase()) !== -1 &&
                                (!this.props.selectedElements || !this.props.selectedElements[i.ID])
                        );
                    } else {
                        filteredData = this.props.listByIdName.filter(
                            i =>
                                i.NAME.toUpperCase().startsWith(nameCriteria.toUpperCase()) &&
                                (!this.props.selectedElements || !this.props.selectedElements[i.ID])
                        );
                    }
                    return {
                        searchList: filteredData
                            .sort((a, b) => {
                                if (a.NAME > b.NAME) return 1;
                                else if (a.NAME < b.NAME) return -1;
                                else return 0;
                            })
                            .sort((a, b) => this.calculateScore(b) - this.calculateScore(a)),
                        highlighted: FIRST_LIST_ELEMENT,
                        searching: false,
                    };
                });
            }
        }
    };

    calculateScore = ({NAME}) => {
        const searchTerm = this.props.value;
        try {
            const regexStartsWithTerm = new RegExp(`^${searchTerm}`, 'i');
            const regexStartsWithTermAndSpace = new RegExp(`^${searchTerm} `, 'i');
            const regexNonLatinBeforeTerm = new RegExp(`(\\(|\\)|([^(a-zA-Z)]))${searchTerm}`, 'i');
            const regexSpaceBeforeAndAfter = new RegExp(` ${searchTerm} `, 'i');
            const regexSpaceBeforeEnd = new RegExp(` ${searchTerm}$`, 'i');
            const regexNonLatinBeforeAndNonLatinAfter = new RegExp(
                `(\(|\)|[^(a-zA-Z)])${searchTerm}(\(|\)|[^(a-zA-Z)])`, // eslint-disable-line
                'i'
            );
            const regexNonLatinBeforeTheEnd = new RegExp(`(\\(|\\)|[^(a-zA-Z)])${searchTerm}$`, 'i'); // eslint-disable-line
            if (regexStartsWithTerm.test(NAME)) {
                return 1;
            }
            if (regexStartsWithTermAndSpace.test(NAME)) {
                return 0.95;
            }
            if (regexNonLatinBeforeTerm.test(NAME)) {
                return 0.9;
            }
            if (regexSpaceBeforeAndAfter.test(NAME)) {
                return 0.8;
            }
            if (regexSpaceBeforeEnd.test(NAME)) {
                return 0.7;
            }
            if (regexNonLatinBeforeAndNonLatinAfter.test(NAME)) {
                return 0.6;
            }
            if (regexNonLatinBeforeTheEnd.test(NAME)) {
                return 0.5;
            }
        } catch (error) {
            return 0;
        }
        return 0;
    };

    toggleList = () => {
        const remainingElements = this.props.listByIdName.filter(i => !this.props.selectedElements[i.ID]);
        if (Object.keys(remainingElements).length > 0 && this.state.searchList.length === 0) {
            this.setState({searchList: remainingElements});
        } else {
            this.setState({searchList: []});
        }
    };

    onNameChange = event => {
        this.setState({
            searching: true,
        });
        const nameCriteria = event.target.value;
        this.props.onChange(nameCriteria, null, null);
        this.props.onIdChange && this.props.onIdChange(null);
        this.channel = Date.now();
        setTimeout(
            () => {
                this.searchByName(nameCriteria, this.channel);
            },
            nameCriteria === '' ? 1 : this.props.delay || 1
        );
    };

    onElementListMouseOver = index => {
        this.setState({
            highlighted: index,
        });
    };

    onElementListClick = index => {
        const name = this.state.searchList[index].NAME;
        const id = this.state.searchList[index].ID;
        const object = this.state.searchList[this.state.highlighted];
        const selectedElements = this.props.uniqueValue ? {} : this.props.selectedElements;
        if (selectedElements) {
            id.split(',').forEach(id => {
                selectedElements[id] = name;
            });
        }
        this.clearList &&
            this.clearList(() => {
                this.props.onChange && this.props.onChange(name, id, object);
                this.props.onIdChange && this.props.onIdChange(id);
                this.props.onSelectionChange && this.props.onSelectionChange(selectedElements);
            });
        this.elInput && this.elInput.focus();
        this.setState({error: false});
    };

    onRowClick = clickedId => {
        let object = this.props.listByIdName.find(element => element.ID === clickedId);
        const name = object.NAME;
        const id = object.ID;
        const selectedElements = this.props.uniqueValue ? {} : this.props.selectedElements;
        if (selectedElements) {
            id.split(',').forEach(id => {
                selectedElements[id] = name;
            });
        }
        this.clearList &&
            this.clearList(() => {
                this.props.onChange && this.props.onChange(name, id, object);
                this.props.onIdChange && this.props.onIdChange(id);
                this.props.onSelectionChange && this.props.onSelectionChange(selectedElements);
            });
        this.elInput && this.elInput.focus();
        this.setState({error: false, isModalSearchOpen: false});
    };

    onInputKeyDown = event => {
        LOG`event.key: ${event.key}`;
        if (event.key === 'Enter') {
            if (this.state.searchList[this.state.highlighted]) {
                const name = this.state.searchList[this.state.highlighted].NAME;
                const id = this.state.searchList[this.state.highlighted].ID;
                const object = this.state.searchList[this.state.highlighted];
                const selectedElements = this.props.uniqueValue ? {} : this.props.selectedElements;
                if (selectedElements) {
                    id.split(',').forEach(id => {
                        selectedElements[id] = name;
                    });
                }
                this.clearList &&
                    this.clearList(() => {
                        this.props.onChange(name, id, object);
                        this.props.onIdChange && this.props.onIdChange(id);
                        this.props.onSelectionChange && this.props.onSelectionChange(selectedElements);
                    });
                this.elInput && this.elInput.focus();
            } else if (this.props.onEnter) {
                this.props.onEnter();
            }
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            if (this.state.searchList.length > 0 && this.state.highlighted > 0) {
                this.setState(prev => ({highlighted: --prev.highlighted}));
            }
        } else if (event.key === 'ArrowDown') {
            event.preventDefault();
            if (this.state.highlighted < this.state.searchList.length - 1) {
                this.setState(prev => ({highlighted: ++prev.highlighted}));
            }
        } else if (event.key === 'Escape') {
            this.clearList && this.clearList();
        }
    };

    onRemoveElement = (event, elementName) => {
        event.stopPropagation();
        this.flagDeleted = true;
        if (this.props.selectedElements && this.props.onSelectionChange) {
            const selectedElements = Object.assign({}, this.props.selectedElements);
            if (this.props.nameHash) {
                (this.props.nameHash[elementName] || []).forEach(elementId => delete selectedElements[elementId]);
            } else if (selectedElements[elementName]) {
                delete selectedElements[elementName];
            }
            this.props.onSelectionChange(selectedElements);
            this.setState({searchList: []});
        }
    };

    onFilterChange = event => {
        this.elInput && this.elInput.focus();
        this.props.onFilterChange && this.props.onFilterChange(event);
    };

    onBlur = () => {
        const {searchList} = this.state;
        const {autoSelection, onIdChange} = this.props;
        if (autoSelection && searchList.length === 1 && onIdChange) {
            onIdChange(searchList[0].ID, this.props.onBlur);
        } else {
            this.props.onBlur && this.props.onBlur();
        }
        this.clearList();
    };

    onBlurContainer = () => alert() || this.toggleList();

    onInputClick = () => {
        this.setState({isModalSearchOpen: true});
        this.props.onChange('');
    };

    render() {
        const renderedLabel = {};

        return (
            <React.Fragment>
                {this.state.isModalSearchOpen ? (
                    <ModalTextLabelInputVersion2
                        close={this.closeModalSearch}
                        searchList={this.state.searchList}
                        value={this.props.value}
                        onNameChange={this.onNameChange}
                        listByIdName={this.props.listByIdName}
                        onRowClick={this.onRowClick}
                        currentFilter={this.props.currentFilter}
                        searching={this.state.searching}
                    />
                ) : null}
                {(this.props.outsideLabelWithHTML || this.props.outsideLabelWithHTML === '') && (
                    <div className={cx(styles.label, this.props.isTranslationMissing && styles.translationMissing)}>
                        <Label width={this.props.outsideLabelWidth}>{this.props.outsideLabelWithHTML}</Label>
                    </div>
                )}
                {(this.props.outsideLabel || this.props.outsideLabel === '') && (
                    <div className={styles.label}>
                        <Label width={this.props.outsideLabelWidth}>{this.props.outsideLabel}</Label>
                    </div>
                )}
                <InputContainer double={this.props.double} triple={this.props.triple}>
                    <FirstRow>
                        {this.props.outsideLabel && (
                            <FirstRowLabel mandatory={this.props.mandatory} outsideLabel={this.props.outsideLabel}>
                                {this.props.outsideLabel}
                            </FirstRowLabel>
                        )}
                        {this.props.label && (
                            <FirstRowLabel mandatory={this.props.mandatory}>{this.props.label}</FirstRowLabel>
                        )}
                        <React.Fragment>{this.props.children}</React.Fragment>
                    </FirstRow>
                    <div style={this.props.clickable && {cursor: 'pointer'}}>
                        <SecondRow
                            buttonIcon={!this.props.noInfo}
                            table={true}
                            oldBackOfficeStyles={this.props.oldBackOfficeStyles}
                            disabled={this.props.disabled}
                        >
                            <div className={styles.selectedListContainer}>
                                <ul className={styles.selectedList}>
                                    {Object.keys(this.props.selectedElements || {}).map(itemKey => {
                                        if (
                                            this.props.selectedElements[itemKey] &&
                                            this.props.selectedElements[itemKey] !== '' &&
                                            !renderedLabel[this.props.selectedElements[itemKey]]
                                        ) {
                                            renderedLabel[this.props.selectedElements[itemKey]] = true;
                                            LOG`${this.props.selectedElements[itemKey]}`;
                                            return (
                                                <li
                                                    className={cx(
                                                        styles.selectedListItem,
                                                        this.props.noInfo && styles.selectedListItemNoInfo,
                                                        this.props.triple && styles.selectedListItemTriple
                                                    )}
                                                    title={htmlToText(this.props.selectedElements[itemKey])}
                                                >
                                                    <div
                                                        className={cx(
                                                            styles.selectedListItemLabel,
                                                            (this.props.hideRemoveElementButton ||
                                                                `${this.props.hideRemoveElementButtonForId}` ===
                                                                    `${itemKey}`) &&
                                                                styles.hideRemoveElementButton
                                                        )}
                                                    >
                                                        {htmlToText(this.props.selectedElements[itemKey])}
                                                    </div>
                                                    {!this.props.hideRemoveElementButton &&
                                                        (!this.props.hideRemoveElementButtonForId ||
                                                            `${this.props.hideRemoveElementButtonForId}` !==
                                                                `${itemKey}`) && (
                                                            <div
                                                                className={styles.selectedListItemIcon}
                                                                onClick={event =>
                                                                    this.onRemoveElement(
                                                                        event,
                                                                        this.props.selectedElements[itemKey]
                                                                    )
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faTimes} />
                                                            </div>
                                                        )}
                                                    <div style={{clear: 'both'}}>{}</div>
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                                <div style={{clear: 'both'}}>{}</div>
                            </div>
                            <div style={{width: '100%', display: 'table'}}>
                                <div style={{width: '100%', display: 'flex'}}>
                                    {this.props.filter ? (
                                        <React.Fragment>
                                            {this.props.filter === true || Array.isArray(this.props.filter) ? (
                                                <select
                                                    className={styles.select}
                                                    onChange={this.onFilterChange}
                                                    value={this.props.currentFilter}
                                                >
                                                    {(Array.isArray(this.props.filter)
                                                        ? this.props.filter
                                                        : ['contains', 'start', 'equals']
                                                    ).map(
                                                        key =>
                                                            filterLabelHash[key] && (
                                                                <option value={key}>{filterLabelHash[key]}</option>
                                                            )
                                                    )}
                                                </select>
                                            ) : (
                                                <div
                                                    onClick={() => this.elInput && this.elInput.focus()}
                                                    className={styles.tag}
                                                >
                                                    {this.props.filter}
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ) : null}
                                    {(this.props.uniqueValue &&
                                        Object.keys(this.props.selectedElements || {}).length === 1) ||
                                    this.props.selectable ||
                                    this.props.disabled ? null : (
                                        <div onClick={this.onInputClick} className={styles.inputDiv} />
                                        // <input
                                        //     ref={el => (this.elInput = el)}
                                        //     type="text"
                                        //     placeholder={this.props.placeholder}
                                        //     onChange={this.onNameChange}
                                        //     value={this.props.value}
                                        //     onKeyDown={this.onInputKeyDown}
                                        //     onBlur={this.onBlur}
                                        //     autoComplete="nope"
                                        //     className={cx(
                                        //         styles.input,
                                        //         this.props.oldBackOfficeStylesError && styles.hasError
                                        //     )}
                                        //     // disabled={'disabled'}
                                        //     onClick={this.onInputClick}
                                        // />
                                    )}
                                </div>
                            </div>
                        </SecondRow>
                        {this.props.remark && (
                            <div>
                                <Remark
                                    remark={this.props.remark}
                                    isRemarkTranslationMissing={this.props.isRemarkTranslationMissing}
                                />
                            </div>
                        )}
                    </div>
                    {this.props.popover && <CustomPopoverInput content={this.props.popover} />}
                    {this.props.buttonIcon && this.props.buttonIcon !== 'none' && (
                        <button
                            type="button"
                            className={cx(styles.button, this.props.buttonDisabled && styles.buttonDisabled)}
                            onClick={!this.props.buttonDisabled && this.props.buttonAction}
                        >
                            <FontAwesomeIcon
                                icon={this.props.buttonIcon}
                                color={this.props.buttonColor || this.props.buttonDisabled ? 'gray' : 'green'}
                            />
                        </button>
                    )}
                    {this.props.error && (
                        <div style={{marginRight: 32}} className={styles.errorWrap}>
                            {this.props.oldBackOfficeStylesError ? (
                                <Error wizardResponse={true}>{this.props.error}</Error>
                            ) : (
                                <Error>{this.props.error}</Error>
                            )}
                        </div>
                    )}
                </InputContainer>
                {this.props.removeStyleClear ? null : <div style={{clear: 'both'}}>{}</div>}
            </React.Fragment>
        );
    }
}
