import React from 'react';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {faArchive, faExclamation, faUser} from '@fortawesome/free-solid-svg-icons';
import CustomTable from '~components/CustomTable';
import DateInput from '~components/DateInput';
import {FORMAT_DATE_TIME_EASY, reformatDateEasy} from '~components/FormatFunctions';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {getDecodedJWT, loadUrlParams} from '~utils';
import CCModalInternalHistory from '../CCModalInternalHistory/CCModalInternalHistory';
import CCModalInternalRead from '../CCModalInternalRead/CCModalInternalRead';
import CCModalInternalCompose from '../CCModalInternalCompose/CCModalInternalCompose';
import CCInternalSentActionButtons from './CCInternalSentActionButtons';
import {
    apiCommunicationCentreInternalSentClientsList,
    apiCommunicationCentreInternalSentMessageArchive,
    apiCommunicationCentreInternalSentSearch,
} from './CCInternalSentService';
import {
    CASEHOLDERS_SHORTKEYS,
    findDefaultValueForCaseHolder,
    findDefaultValueForDirectory,
    getSelectListOfDirectory,
} from '../CommunicationCentreInternal/CommunicationCentreInternal.utils';
import CCModalInternalReply from '../CCModalInternalReply/CCModalInternalReply';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ApplicationNumbersMultiple from '../../components/ApplicationNumbersMultiple';
import {ModalApplicationNumbers} from '../../commonModals';
import {
    IClientsList,
    ICommunicationMessageInternal,
    ICommunicationMessageInternalHistory,
    ICommunicationMessageInternalRead,
    ICommunicationMessagesInternalSearchResults,
    IDecodedJWT,
} from '../../types';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
} from '../../componentsLayout';
import NavigationCommunicationCentre from '../../shared/NavigationCommunicationCentre';
import {Button} from '../../componentsFormV2';
import MainWrapper from '../../componentsLayout/MainWrapper';
import {trackPageView} from '../../utils';
import ModalPreview from '../CommunicationCentreInternal/modals/ModalPreview';

export const RESULT_FIELDS_DEFAULT_SENT_CPVO = ['addressees', 'messageSubject', 'applicationNumbers', 'sentDate'];

export const RESULT_FIELDS_ALL_SENT_CPVO = [
    'addressees',
    'messageSubject',
    'applicationNumbers',
    'CPVOColleagues',
    'sentDate',
];

const DATEFORMAT_CRITERIA_KEYS = ['arrivalDateFrom', 'arrivalDateTo'];

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

type TKeyOfDefaultCriteria = keyof TDefaultCriteria;

const DEFAULT_CRITERIA = {
    from: '',
    pageNumber: 1,
    pageSize: 10,
    order: 'sentDate',
    reverse: true,
    refresh: false,
    excel: false,
    applicationNumber: '',
    directory: 'All',
    caseHolder: 'All',
    replyBy: 'All',
    cc: 'All',
    arrivalDateFrom: '',
    arrivalDateTo: '',
    readingStatus: 'All',
    sendingDateFrom: '',
    sendingDateTo: '',
    searchTerm: '',
};

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    clientsListByIdName: {
        ID: string;
        NAME: string;
    }[];
    clientsName: string;
    clientsNameHash: any;
    communicationIdsSelected: string[];
    count: number;
    criteria: TDefaultCriteria;
    isModalCPVOLogsOpen: boolean;
    isModalHistoryOpen: number | false;
    isModalPreviewOpen: boolean;
    loading: number;
    messages: ICommunicationMessageInternal[];
    modalApplicationNumbers: string[];
    modalInternalMessageCompose: boolean;
    modalInternalMessageRead: number | false;
    modalInternalMessageReply: number | false;
    modalPreviewURL: string;
    modalPreviewMessage: ICommunicationMessageInternalRead | null;
    selectedClients: any;
    screenLoaded: boolean;
    timestamp: number;
}

class CCInternalSent extends React.Component<IProps, IState> {
    decodedJWT: IDecodedJWT = getDecodedJWT();

    constructor(props: IProps) {
        super(props);
        this.state = {
            clientsListByIdName: [],
            clientsName: '',
            clientsNameHash: {},
            communicationIdsSelected: [],
            count: 0,
            criteria: Object.assign({}, DEFAULT_CRITERIA),
            isModalCPVOLogsOpen: false,
            isModalHistoryOpen: false,
            isModalPreviewOpen: false,
            loading: 0,
            messages: [],
            modalApplicationNumbers: [],
            modalInternalMessageCompose: false,
            modalInternalMessageRead: false,
            modalInternalMessageReply: false,
            modalPreviewURL: '',
            modalPreviewMessage: null,
            selectedClients: {},
            screenLoaded: false,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'communicationCentreInternalSent'});
        const params = loadUrlParams(DATEFORMAT_CRITERIA_KEYS);
        const URLCriteria: any = {};
        Object.entries(params).forEach(([fieldName, fieldValue]) => {
            if (Object.keys(DEFAULT_CRITERIA).includes(fieldName)) {
                URLCriteria[fieldName] = fieldValue;
            }
        });
        this.loadJSONs();
        this.updateCriteriaValue(
            {
                directory: findDefaultValueForDirectory(this.decodedJWT),
                caseHolder: findDefaultValueForCaseHolder(this.decodedJWT),
                ...URLCriteria,
            },
            () => this.search(false, false, () => this.setState({screenLoaded: true}))
        );
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (prevState.criteria.directory !== this.state.criteria.directory) {
            if (parseInt(this.state.criteria.directory) !== 12 && this.state.criteria.caseHolder !== 'All') {
                this.updateCriteriaValue({caseHolder: 'All'});
            }
        }
    }

    loadJSONs = () => {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiCommunicationCentreInternalSentClientsList()
                    .then((jsonResponse: IClientsList) => {
                        if (jsonResponse && jsonResponse.data) {
                            const nameHash: {[key: string]: number[]} = {};
                            (jsonResponse.data || []).forEach(speciesElement => {
                                if (!nameHash[speciesElement.NAME]) {
                                    nameHash[speciesElement.NAME] = [];
                                }
                                nameHash[speciesElement.NAME].push(speciesElement.ID);
                            });
                            const listByIdName = Object.keys(nameHash).map(name => ({
                                ID: nameHash[name].join(','),
                                NAME: name,
                            }));
                            const selectedClients: {[key: string]: string} = {};
                            const selectedSpeciesKeyArray = Object.keys(this.state.selectedClients || {});
                            listByIdName
                                .filter(i => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                                .forEach(item => (selectedClients[item.ID] = item.NAME));
                            this.setState({
                                clientsListByIdName: listByIdName,
                                clientsNameHash: nameHash,
                                selectedClients,
                                timestamp: Date.now(),
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`FormLabelInputSpecies: ${error.message}`]);
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    parseCriteria = (criteria: TDefaultCriteria): TDefaultCriteria => {
        const parsedCriteria: any = {};
        Object.keys(criteria).map(key => {
            let criteriaValue = criteria[key as TKeyOfDefaultCriteria];
            if (DATEFORMAT_CRITERIA_KEYS.indexOf(key) !== -1 && criteriaValue !== '') {
                parsedCriteria[key] = reformatDateEasy(criteriaValue);
            } else {
                parsedCriteria[key] = criteriaValue;
            }
        });
        return parsedCriteria;
    };

    buildParams = (parsedCriteria: TDefaultCriteria): void => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(
                i =>
                    parsedCriteria[i as TKeyOfDefaultCriteria] !== DEFAULT_CRITERIA[i as TKeyOfDefaultCriteria] &&
                    i !== 'refresh'
            )
            .map(key => `${key}=${parsedCriteria[key as TKeyOfDefaultCriteria]}`);
        this.props.history.replace(
            `/communicationCentreInternalSent${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`
        );
    };

    search = (refresh?: boolean, urlLoad?: boolean, callBack?: () => any): any =>
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams(parsedCriteria);
                apiCommunicationCentreInternalSentSearch(parsedCriteria, DEFAULT_CRITERIA)
                    .then((jsonResponse: ICommunicationMessagesInternalSearchResults) => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.messages) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        messages: jsonResponse.data.messages,
                                        timestamp: Date.now(),
                                    },
                                    jsonResponse.data.count && {count: jsonResponse.data.count},
                                    !refresh && {selectedMessages: {}}
                                );
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`Register search list error: ${error.message}`]);
                    })
                    .then(() => {
                        this.setState(prev => ({loading: prev.loading - 1}));
                        callBack && callBack();
                    });
            }
        );

    openModalInternalMessageRead = (communicationId: number): void =>
        this.setState({modalInternalMessageRead: communicationId});

    closeModalInternalMessageRead = () =>
        this.setState(
            {
                modalInternalMessageRead: false,
                isModalPreviewOpen: false,
                modalPreviewURL: '',
            },
            () => this.search(true)
        );

    closeModalInternalMessageReply = (refresh?: boolean) =>
        this.setState(
            {
                modalInternalMessageReply: false,
                isModalPreviewOpen: false,
                modalPreviewURL: '',
            },
            () => refresh && this.search(true)
        );

    updateCriteriaValue = (criteriaValue: Partial<TDefaultCriteria>, callback?: () => any, refresh?: boolean): void => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = prev.criteria.order !== criteriaValue.order;
                reverseChanged = prev.criteria.reverse !== criteriaValue.reverse;
                return {criteria};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    openModalInternalMessageCompose = () => this.setState({modalInternalMessageCompose: true});

    closeModalInternalMessageCompose = () =>
        this.setState({modalInternalMessageCompose: false}, () => this.search(true));

    closeModalInternalMessageHistory = () => this.setState({isModalHistoryOpen: false}, () => this.search(true));

    formatTableMessages = (messages: ICommunicationMessageInternalHistory[]) =>
        [...(messages || [])].map(el => ({
            ...el,
            lastUpdateDate: el.sentDate,
            receivedDate: el.sentDate,
            to: el.from,
        }));

    getTableFormatFunctions = () => ({
        addressees: (addressees: string) => (
            <>
                <FontAwesomeIcon
                    icon={faUser as any}
                    color={'gray'}
                    style={{width: 22, height: 25, verticalAlign: 'middle', marginRight: 10}}
                />{' '}
                {(addressees || '').split('\\n').join(', ')}
            </>
        ),
        applicationNumbers: (applicationNumbers: string[]) => (
            <ApplicationNumbersMultiple
                applicationNumbers={applicationNumbers}
                openModalApplicationNumbers={this.openModalApplicationNumbers}
            />
        ),
        deemedService: FORMAT_DATE_TIME_EASY,
        firstOpened: FORMAT_DATE_TIME_EASY,
        lastUpdateDate: FORMAT_DATE_TIME_EASY,
        notificationDate: FORMAT_DATE_TIME_EASY,
        receivedDate: FORMAT_DATE_TIME_EASY,
        sentDate: FORMAT_DATE_TIME_EASY,
    });

    openModalApplicationNumbers = (applicationNumbers: string[]) =>
        this.setState({modalApplicationNumbers: applicationNumbers});

    closeModalApplicationNumbers = () => this.setState({modalApplicationNumbers: []});

    setModalPreviewURL = (URL: any) => this.setState({modalPreviewURL: URL});

    onTableRowClick = (communicationId: number): void => this.openModalInternalMessageRead(communicationId);

    onModalInternalMessageReadButtonReplyClick = () =>
        this.setState(prev => ({
            modalInternalMessageRead: false,
            modalInternalMessageReply: prev.modalInternalMessageRead,
        }));

    onTextInputSearchTermChange = ({target: {value: searchTerm}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({searchTerm});

    onTextInputValueApplicationNumberChange = ({
        target: {value: textInputValueApplicationNumber},
    }: React.ChangeEvent<HTMLInputElement>): void =>
        this.updateCriteriaValue({applicationNumber: textInputValueApplicationNumber});

    onSelectInputValueDirectoryChange = ({
        target: {value: selectInputValueDirectory},
    }: React.ChangeEvent<HTMLSelectElement>): void => this.updateCriteriaValue({directory: selectInputValueDirectory});

    onSelectInputValueCaseHolderChange = ({
        target: {value: selectInputValueCaseHolder},
    }: React.ChangeEvent<HTMLSelectElement>): void =>
        this.updateCriteriaValue({caseHolder: selectInputValueCaseHolder});

    onSelectInputValueCCChange = ({target: {value: selectInputValueCC}}: React.ChangeEvent<HTMLSelectElement>): void =>
        this.updateCriteriaValue({cc: selectInputValueCC});

    onDateInputValueArrivalDateFromChange = (dateInputValueArrivalDateFrom: string) =>
        this.updateCriteriaValue({arrivalDateFrom: dateInputValueArrivalDateFrom});

    onDateInputValueArrivalDateToChange = (dateInputValueArrivalDateTo: string) =>
        this.updateCriteriaValue({arrivalDateTo: dateInputValueArrivalDateTo});

    onSelectInputValueReadingStatusChange = ({
        target: {value: selectInputValueReadingStatus},
    }: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({readingStatus: selectInputValueReadingStatus});

    onClearFieldsClick = () =>
        this.setState(
            prev => ({
                selectedClients: {},
                criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
            }),
            () => this.search()
        );

    onTableIconHistoryClick = ({correlationId}: ICommunicationMessageInternal) =>
        this.setState({isModalHistoryOpen: correlationId});

    onTableIconArchiveClick = (communicationId: number) =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                apiCommunicationCentreInternalSentMessageArchive([communicationId]).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1}));
                    this.search(true);
                })
        );

    onClientsSelectionChange = (selectedClients: {[key: string]: string}) =>
        this.setState({selectedClients, clientsName: ''}, () =>
            this.updateCriteriaValue({from: Object.keys(selectedClients || {}).join(',')})
        );

    onClientsNameChange = (clientsName: string) => this.setState({clientsName});

    onSelectedChange = (communicationIdsSelected: string[]): void => this.setState({communicationIdsSelected});

    onButtonArchiveSelectedClick = () => {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                apiCommunicationCentreInternalSentMessageArchive(
                    this.state.communicationIdsSelected.map(el => parseInt(el))
                ).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1, communicationIdsSelected: []}));
                    this.search();
                })
        );
    };

    setModalPreviewMessage = (message: ICommunicationMessageInternalRead) =>
        this.setState({modalPreviewMessage: message});

    onModalReadButtonPreviewClick = () =>
        this.setState(prev => ({
            isModalPreviewOpen: !prev.isModalPreviewOpen,
        }));

    render() {
        const actions = CCInternalSentActionButtons(this.onTableIconArchiveClick, this.onTableIconHistoryClick);

        return (
            <>
                {this.state.isModalHistoryOpen ? (
                    <CCModalInternalHistory
                        close={this.closeModalInternalMessageHistory}
                        correlationId={this.state.isModalHistoryOpen}
                        formatTableMessages={this.formatTableMessages}
                        getTableFormatFunctions={this.getTableFormatFunctions}
                    />
                ) : null}
                {this.state.modalInternalMessageCompose && (
                    <CCModalInternalCompose close={this.closeModalInternalMessageCompose} intl={this.props.intl} />
                )}
                {this.state.modalInternalMessageRead && (
                    <CCModalInternalRead
                        close={this.closeModalInternalMessageRead}
                        communicationId={this.state.modalInternalMessageRead}
                        folder={`sent`}
                        onButtonReplyClick={this.onModalInternalMessageReadButtonReplyClick}
                        onButtonPreviewClick={this.onModalReadButtonPreviewClick}
                        isPreviewMode={this.state.isModalPreviewOpen}
                        modalPreviewURL={this.state.modalPreviewURL}
                        setModalPreviewURL={this.setModalPreviewURL}
                        setModalPreviewMessage={this.setModalPreviewMessage}
                    />
                )}
                {this.state.modalInternalMessageReply && (
                    <CCModalInternalReply
                        close={this.closeModalInternalMessageReply}
                        communicationId={this.state.modalInternalMessageReply}
                        isPreviewMode={this.state.isModalPreviewOpen}
                    />
                )}
                {this.state.isModalPreviewOpen ? (
                    <ModalPreview
                        modalPreviewURL={this.state.modalPreviewURL}
                        modalPreviewMessage={this.state.modalPreviewMessage}
                        modalReadCommunicationId={this.state.modalInternalMessageRead}
                        tableMessages={this.state.messages}
                        hideNavButtons={true}
                    />
                ) : null}
                {this.state.modalApplicationNumbers.length ? (
                    <ModalApplicationNumbers
                        applicationNumbers={this.state.modalApplicationNumbers}
                        close={this.closeModalApplicationNumbers}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Communication Centre`} />
                <NavigationCommunicationCentre openModalMessageCompose={this.openModalInternalMessageCompose} />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div>
                            <TextInput
                                label={`Search term`}
                                onChange={this.onTextInputSearchTermChange}
                                value={this.state.criteria.searchTerm}
                                placeholder={`Type a search term`}
                                onEnter={this.search}
                            />
                            <TextInput
                                filter={'Starts with'}
                                placeholder={`e.g. 2015 or 20150001`}
                                label={`Application number(s)`}
                                onChange={this.onTextInputValueApplicationNumberChange}
                                value={this.state.criteria.applicationNumber}
                                onEnter={this.search}
                            />
                            <DateInput
                                label={'Sending date'}
                                changeDateFrom={this.onDateInputValueArrivalDateFromChange}
                                changeDateTo={this.onDateInputValueArrivalDateToChange}
                                inputValueFrom={this.state.criteria.arrivalDateFrom}
                                inputValueTo={this.state.criteria.arrivalDateTo}
                            />
                            <SelectInput
                                label={`Directory`}
                                value={this.state.criteria.directory}
                                onChange={this.onSelectInputValueDirectoryChange}
                                list={getSelectListOfDirectory(this.decodedJWT)}
                            />
                            <SelectInput
                                label={`Caseholder`}
                                value={this.state.criteria.caseHolder || findDefaultValueForCaseHolder(this.decodedJWT)}
                                onChange={this.onSelectInputValueCaseHolderChange}
                                list={CASEHOLDERS_SHORTKEYS.map(shortKey => ({
                                    id: shortKey,
                                    value: shortKey,
                                }))}
                                disabled={parseInt(this.state.criteria.directory) !== 12}
                            />
                            <SelectInput
                                label={`Reading Status`}
                                value={this.state.criteria.readingStatus}
                                onChange={this.onSelectInputValueReadingStatusChange}
                                list={[
                                    {id: '1', value: 'Read'},
                                    {id: '2', value: 'not read'},
                                ]}
                                disabled={true}
                            />
                            <SelectInput
                                label={`CC`}
                                value={this.state.criteria.cc}
                                onChange={this.onSelectInputValueCCChange}
                                list={[{id: '1', value: 'Show only CC messages'}]}
                                disabled={true}
                            />
                            <TextLabelInput
                                delay={300}
                                double={true}
                                label={'To'}
                                listByIdName={this.state.clientsListByIdName}
                                nameHash={this.state.clientsNameHash}
                                onSelectionChange={this.onClientsSelectionChange}
                                onChange={this.onClientsNameChange}
                                outsideLabelWidth={160}
                                placeholder={`Type part of the users name`}
                                selectedElements={this.state.selectedClients}
                                uniqueValue={true}
                                noInfo={true}
                                value={this.state.clientsName}
                            />
                        </div>
                        <FormFooter>
                            <Button
                                variation={'secondary'}
                                clickAction={this.onClearFieldsClick}
                            >{`Clear fields`}</Button>
                            <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    <div style={{marginTop: 20, marginBottom: 20}}>
                        {this.state.screenLoaded ? (
                            <>
                                {(this.state.messages || []).length ? (
                                    <div style={{paddingRight: 15, marginBottom: 15}}>
                                        <FormFooterButton
                                            color={buttonColor.blue}
                                            clickAction={this.onButtonArchiveSelectedClick}
                                            icon={faArchive}
                                            disabled={!(this.state.communicationIdsSelected || []).length}
                                            width={undefined}
                                            height={undefined}
                                            extraStyles={undefined}
                                            float={undefined}
                                        >{`Archive selected`}</FormFooterButton>
                                        <div style={{clear: 'both'}} />
                                    </div>
                                ) : null}
                                <CustomTable
                                    actions={actions}
                                    count={this.state.count}
                                    dataFilter={null}
                                    defaultOrder={this.state.criteria.order}
                                    filterFunctions={null}
                                    formatFunctions={this.getTableFormatFunctions()}
                                    forehandColumn={({urgent}: any) =>
                                        urgent ? (
                                            <FontAwesomeIcon
                                                icon={faExclamation as any}
                                                fontSize={25}
                                                title={'Urgent'}
                                                color={'#810202'}
                                            />
                                        ) : null
                                    }
                                    hideExcelButton={true}
                                    id={'communicationId'}
                                    intl={this.props.intl}
                                    onSelectedChange={this.onSelectedChange}
                                    pageSize={this.state.criteria.pageSize}
                                    pagination={true}
                                    pageNumber={this.state.criteria.pageNumber}
                                    resultFieldsDefault={RESULT_FIELDS_DEFAULT_SENT_CPVO}
                                    resultFieldsAll={RESULT_FIELDS_ALL_SENT_CPVO}
                                    reverseOrder={this.state.criteria.reverse}
                                    rowBorderLeft={true}
                                    rowClick={this.onTableRowClick}
                                    setLastCursor={null}
                                    selectable={true}
                                    timestamp={this.state.timestamp}
                                    tableName={'communicationCentreInternalSent'}
                                    tableType={'OBJECT'}
                                    tableSource={this.formatTableMessages(this.state.messages)}
                                    updateCriteriaValue={this.updateCriteriaValue}
                                    version={2}
                                />
                            </>
                        ) : null}
                    </div>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(CCInternalSent));
