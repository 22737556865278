import React from 'react';
import {Button} from '../../componentsFormV2';
import CustomTable from '~components/CustomTable';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationVarietyFinder from '../../shared/NavigationVarietyFinder';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import VFModalClass from '../VFModalClass/VFModalClass';
import graphQLClientInstance from '../../utils/axiosGraphQLClient';
import axiosGraphQLClient from '../../utils/axiosGraphQLClient';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {getSearchClassesQuery} from './VFUPOVClassesService';
import {injectIntl} from 'react-intl';
import styles from './VFUPOVClasses.module.scss';
import {withRouter} from 'react-router-dom';
import {trackPageView} from '../../utils';
import XLSX from 'xlsx';

const TABLE_NAME = 'varietyFinderUPOVClasses';

const RESULT_FIELDS_ALL = ['classCode', 'UPOVClass', 'className', 'remark'];

const DEFAULT_COUNT = 0;

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

const DEFAULT_CRITERIA = {
    country: 'All',
    pageNumber: 1,
    pageSize: parseInt(localStorage.getItem(`pageSize${TABLE_NAME}`) || '50'),
    order: null,
    reverse: false,
    refresh: false,
    registerType: 'All',
};

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    count: number;
    criteria: TDefaultCriteria;
    forceHideSearchList: boolean;
    loading: number;
    isModalVFUPOVClassDetailsOpen: string | false;
    responseUPOVClasses: any;
    screenLoaded: boolean;
    textInputClassNameFilter: string;
    textInputClassNameValue: string;
    textLabelInputClassCodeTimeoutId: any;
    textLabelInputClassCodeSearchLoading: boolean;
    textLabelInputClassCodeCurrentFilter: string;
    textLabelInputClassCodeHash: {};
    textLabelInputClassCodeList: [];
    textLabelInputClassCodeValue: string;
    timestamp: number;
    textTimestamp: number;
}

class VFUPOVClasses extends React.Component<IProps, IState> {
    skipSearching = false;

    constructor(props: IProps) {
        super(props);
        this.state = {
            count: DEFAULT_COUNT,
            criteria: {...DEFAULT_CRITERIA},
            forceHideSearchList: false,
            loading: 0,
            isModalVFUPOVClassDetailsOpen: false,
            responseUPOVClasses: [],
            screenLoaded: false,
            textInputClassNameFilter: 'start',
            textInputClassNameValue: '',
            textLabelInputClassCodeTimeoutId: null,
            textLabelInputClassCodeSearchLoading: false,
            textLabelInputClassCodeCurrentFilter: 'equals',
            textLabelInputClassCodeHash: {},
            textLabelInputClassCodeList: [],
            textLabelInputClassCodeValue: '',
            timestamp: Date.now(),
            textTimestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'Search Classes'});
        this.setState(prev => ({
            criteria: {...prev.criteria, pageSize: parseInt(localStorage.getItem(`pageSize${TABLE_NAME}`) || '50')},
        }));
    }

    updateCriteriaValue = (criteriaValue: any, callback?: any, refresh?: any) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = criteriaValue.pageNumber && prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.criteria.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse && prev.criteria.reverse !== criteriaValue.reverse;
                return {...prev, criteria};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) && refresh && this.search();
            }
        );
    };

    search = () => {
        const values: any = {};
        if (this.state.textLabelInputClassCodeValue) {
            values.classCode = this.state.textLabelInputClassCodeValue;
            values.classCodeSelect = this.state.textLabelInputClassCodeCurrentFilter;
        }
        if (this.state.textInputClassNameValue) {
            values.className = this.state.textInputClassNameValue;
            values.classNameSelect = this.state.textInputClassNameFilter;
        }
        const tablePageSize = this.state.criteria.pageSize;
        const tablePageIndex = this.state.criteria.pageNumber - 1;
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                graphQLClientInstance
                    .post('/graphql', getSearchClassesQuery(values, tablePageSize, tablePageIndex))
                    .then(JSONResponse => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.classesSearch &&
                            JSONResponse.data.data.classesSearch.data
                        ) {
                            const {data, total} = JSONResponse.data.data.classesSearch || {};
                            const responseUPOVClasses = data.map(({classid, upovclass, classname, info}: any) => ({
                                classCode: classid,
                                UPOVClass: upovclass,
                                className: classname,
                                remark: info,
                            }));
                            this.setState(prev => ({
                                count: total,
                                loading: prev.loading - 1,
                                responseUPOVClasses,
                                screenLoaded: true,
                                timestamp: Date.now(),
                            }));
                        }
                    });
            }
        );
    };

    resetCriteria = () =>
        this.setState({
            textInputClassNameFilter: 'start',
            textInputClassNameValue: '',
            textLabelInputClassCodeTimeoutId: null,
            textLabelInputClassCodeSearchLoading: false,
            textLabelInputClassCodeCurrentFilter: 'equals',
            textLabelInputClassCodeHash: {},
            textLabelInputClassCodeList: [],
            textLabelInputClassCodeValue: '',
            timestamp: Date.now(),
        });

    closeModalVFUPOVClassDetails = () => this.setState({isModalVFUPOVClassDetailsOpen: false});

    printExcel = () => {
        const resultGrid: any = [['Species Code', 'Class', 'Class name', 'Remark']];
        const searchValues: any = {};
        if (this.state.textLabelInputClassCodeValue) {
            searchValues.classCode = this.state.textLabelInputClassCodeValue;
            searchValues.classCodeSelect = this.state.textLabelInputClassCodeCurrentFilter;
        }
        if (this.state.textInputClassNameValue) {
            searchValues.className = this.state.textInputClassNameValue;
            searchValues.classNameSelect = this.state.textInputClassNameFilter;
        }
        const indexesNeeded = Math.ceil(this.state.count / 1000);
        let promises: any = [];
        for (let index = 0; index < indexesNeeded; index++) {
            promises.push(graphQLClientInstance.post('/graphql', getSearchClassesQuery(searchValues, 1000, index)));
        }
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                Promise.all(promises).then((JSONResponses: any) => {
                    JSONResponses.forEach((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.classesSearch &&
                            JSONResponse.data.data.classesSearch.data
                        ) {
                            JSONResponse.data.data.classesSearch.data.forEach(
                                ({classid, upovclass, classname, info}: any) => {
                                    resultGrid.push([classid, upovclass, classname, info]);
                                }
                            );
                        }
                    });
                    const workSheet = XLSX.utils.aoa_to_sheet(resultGrid);
                    const workBook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workBook, workSheet, 'UPOVClasses');
                    XLSX.writeFile(workBook, `UPOVClasses.xlsx`);
                    this.setState(prev => ({
                        loading: prev.loading - 1,
                    }));
                });
            }
        );
    };

    searchClassCodeSuggestions = () => {
        if (!this.skipSearching) {
            this.setState({textLabelInputClassCodeSearchLoading: true}, () => {
                axiosGraphQLClient
                    .post(
                        '/graphql',
                        this.autoCompletePostData(
                            'autoCompleteClass',
                            this.state.textLabelInputClassCodeValue,
                            'contains',
                            false,
                            ''
                        )
                    )
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.autoCompleteClass
                        ) {
                            const nameHash: {[key: string]: string[]} = {};
                            JSONResponse.data.data.autoCompleteClass.forEach((suggestion: string) => {
                                if (!nameHash[suggestion]) {
                                    nameHash[suggestion] = [];
                                }
                                nameHash[suggestion].push(suggestion);
                            });
                            const listByIdName: any = Object.keys(nameHash).map(questionName => ({
                                ID: nameHash[questionName].join(','),
                                NAME: questionName,
                            }));
                            this.setState({
                                forceHideSearchList: false,
                                textTimestamp: Date.now(),
                                textLabelInputClassCodeSearchLoading: false,
                                textLabelInputClassCodeHash: nameHash,
                                textLabelInputClassCodeList: listByIdName,
                            });
                        }
                    })
                    .catch(err => ERROR([err]));
            });
        }
    };

    autoCompletePostData = (
        autoCompleteName: string,
        term: string,
        type: string,
        includeSynonyms = false,
        autoCompleteReturnString = ''
    ) => {
        const synonyms = includeSynonyms ? `includeSynonyms:"1"` : '';

        return {
            query: `
    {
      ${autoCompleteName}(
        term: "${term.toLowerCase()}"
        type: "${type}"
        ${synonyms}
      )${autoCompleteReturnString}
    }`,
        };
    };

    onTextLabelInputClassCodeChange = (textLabelInputClassCodeValue: string) => {
        clearTimeout(this.state.textLabelInputClassCodeTimeoutId);
        this.setState({
            textLabelInputClassCodeValue,
            textLabelInputClassCodeHash: {},
            textLabelInputClassCodeList: [],
        });
        if (
            this.state.textLabelInputClassCodeCurrentFilter === 'equals' &&
            (textLabelInputClassCodeValue || '').length > 2
        ) {
            let newTimeoutId = setTimeout(() => {
                this.state.textLabelInputClassCodeValue && this.searchClassCodeSuggestions();
            }, 300);
            this.setState({
                textLabelInputClassCodeTimeoutId: newTimeoutId,
            });
        }
    };

    onTextLabelInputClassCodeSelectionChange = () => {
        this.skipSearching = true;
        this.setState({
            textLabelInputClassCodeList: [],
            textLabelInputClassCodeHash: {},
        });
        setTimeout(() => {
            this.skipSearching = false;
        }, 1000);
    };

    onTextLabelInputClassCodeFilterChange = ({
        target: {value: textLabelInputClassCodeCurrentFilter},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({textLabelInputClassCodeCurrentFilter});

    onLinkButtonHelpClick = () => window.open('/help/en/Classes.html', '_blank');

    onSelectInputRegisterTypeChange = ({target: {value: registerType}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.updateCriteriaValue({registerType});

    onTextInputClassNameFilterChange = ({
        target: {value: textInputClassNameFilter},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({textInputClassNameFilter});

    onTextInputClassNameChange = ({target: {value: textInputClassNameValue}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({textInputClassNameValue});

    onTableRowClick = (_: any, rowObject: any) => this.setState({isModalVFUPOVClassDetailsOpen: rowObject.classCode});

    onHeaderSearchLinkClick = (searchListItem: any) =>
        this.props.history.push({
            pathname: '/varieties',
            state: {searchListItem},
        });

    render() {
        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                {this.state.isModalVFUPOVClassDetailsOpen ? (
                    <VFModalClass
                        classId={this.state.isModalVFUPOVClassDetailsOpen}
                        close={this.closeModalVFUPOVClassDetails}
                    />
                ) : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Variety Finder`} hostApplication={'Variety Finder'} />
                <NavigationVarietyFinder onSearchLinkClick={this.onHeaderSearchLinkClick} />
                <MainWrapper bgGray={true}>
                    <FormWrapper formInnerWidth={'lg'} paddingFormContent={'sm'}>
                        <div className={styles.textLabelInputClassCodeWrap}>
                            <TextLabelInput
                                buttonIcon={this.state.textLabelInputClassCodeSearchLoading && faSpinner}
                                currentFilter={this.state.textLabelInputClassCodeCurrentFilter}
                                half={true}
                                filter={['starts', 'equals']}
                                forceHideSearchList={this.state.forceHideSearchList}
                                hideCounter={true}
                                label={`Class Code`}
                                listByIdName={this.state.textLabelInputClassCodeList}
                                nameHash={this.state.textLabelInputClassCodeHash}
                                onChange={this.onTextLabelInputClassCodeChange}
                                onFilterChange={this.onTextLabelInputClassCodeFilterChange}
                                onSelectionChange={this.onTextLabelInputClassCodeSelectionChange}
                                popOverTextFirstRow={`Identifier of the class.`}
                                noInfo={true}
                                selectedElements={{}}
                                timestamp={this.state.textTimestamp}
                                value={this.state.textLabelInputClassCodeValue}
                                size={'lg'}
                                removeStyleClear={true}
                            />
                        </div>
                        <TextInput
                            currentFilter={this.state.textInputClassNameFilter}
                            half={true}
                            filter={true}
                            onChange={this.onTextInputClassNameChange}
                            onFilterChange={this.onTextInputClassNameFilterChange}
                            label={`Class Name`}
                            value={this.state.textInputClassNameValue}
                            size={'lg'}
                        />
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            <Button clickAction={this.onLinkButtonHelpClick} variation={'secondary'}>{`Help`}</Button>
                            <Button clickAction={this.resetCriteria} variation={'secondary'}>{`Clear fields`}</Button>
                            <Button clickAction={this.search}>{`Search`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    <div>
                        {this.state.screenLoaded ? (
                            <>
                                <div style={{marginBottom: -12}}>
                                    <div
                                        className={styles.tableHeaderCounterTitle}
                                    >{`${this.state.count} results matching your criteria`}</div>
                                </div>
                                <CustomTable
                                    count={this.state.count}
                                    defaultOrder={this.state.criteria.order}
                                    formatFunctions={{
                                        classCode: (classCode: string) => {
                                            return (
                                                <div
                                                    style={{
                                                        color: '#255899',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {classCode}
                                                </div>
                                            );
                                        },
                                    }}
                                    id={'communicationId'}
                                    intl={this.props.intl}
                                    isNavigationButtonCompactOrDefaultViewEnabled={true}
                                    notSortable={RESULT_FIELDS_ALL}
                                    pageNumber={this.state.criteria.pageNumber}
                                    pageSize={this.state.criteria.pageSize}
                                    pagination={true}
                                    printExcel={this.printExcel}
                                    resultFieldsDefault={RESULT_FIELDS_ALL}
                                    reverseOrder={this.state.criteria.reverse}
                                    rowClick={this.onTableRowClick}
                                    tableName={TABLE_NAME}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.responseUPOVClasses}
                                    timestamp={this.state.timestamp}
                                    updateCriteriaValue={this.updateCriteriaValue}
                                    version={2}
                                    hoverGrey={true}
                                    navigationBarWider={true}
                                    fontSize={14}
                                />
                            </>
                        ) : null}
                    </div>
                </MainWrapper>
                <Footer hostApplication={'Variety Finder'} />
            </>
        );
    }
}

export default withRouter(injectIntl(VFUPOVClasses));
