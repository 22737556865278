import React, {useEffect, useState} from 'react';
import JSZip from 'jszip';
import Checkbox from '~components/Checkbox';
import {DraftProposalAnswerForSave} from './Components/ModalAnswerToCPVOProposalNumber/ModalAnswerToCPVOProposalNumberService';
import Empty from '~components/Empty';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import {History} from 'history';
import {
    apiDocumentsProposalWindowFetch,
    apiGetClosedProcedureDocumentFile,
    apiGetImageFiles,
    apiInventoryDetailsLDownloadExcel,
    apiInventoryDetailsLDownloadPDF,
    apiInventoryDetailsListFetch,
    apiInventoryNumbersListFetch,
    apiProposalAnswerSaveAsDraft,
    apiProposalAnswersWindowFetch,
    apiRequestedEntrustmentsFetch,
    apiSummaryOfAnswersDownloadExcel,
    apiSummaryOfAnswersFetch,
    apiUpdateLumpSumOkOfSummaryAnswers,
    apiUpdateNegotiationOfSummaryAnswers,
    apiWithdrawProposalsOfSummaryAnswers,
    InventoryDetails,
    InventoryDetailsListFetchData,
    InventoryNumberListFetchData,
    ModalScreenType,
    ProposalAnswer,
    ProposalAnswersListFetchData,
    ProposalDocument,
    ProposalDocumentsListFetchData,
    RequestedEntrustment,
    RequestedEntrustmentsFetchData,
    SummaryOfAnswers,
    SummaryOfAnswersFetchData,
    SummaryOfAnswersForSave,
    SummaryOfAnswersUpdateLumpSumOkData,
    SummaryOfAnswersUpdateNegotiationData,
    SummaryOfAnswersWithdrawProposalData,
} from './NewSpeciesProcedurePageService';
import InputLink from '~components/InputLink';
import InventoryDetailsTableArea from './Components/InventoryDetailsTableArea/InventoryDetailsTableArea';
import IntroductionSectionArea from './Components/IntroductionSectionArea/IntroductionSectionArea';
import Label from '~components/Label';
import {ModalAlertVersion2, ModalErrorVersion2} from '../../../../commonModals';
import ModalDocumentsRelatedToProposal from './Components/ModalDocumentsRelatedToProposal/ModalDocumentsRelatedToProposal';
import ModalListOfRequestedEntrustments from './Components/ModalListOfRequestedEntrustments/ModalListOfRequestedEntrustments';
import ModalSummaryOfAnswersForInventoryNumber from './Components/ModalSummaryOfAnswersForInventoryNumber/ModalSummaryOfAnswersForInventoryNumber';
import ModalAnswerToCPVOProposalNumber from './Components/ModalAnswerToCPVOProposalNumber/ModalAnswerToCPVOProposalNumber';
import NavigationTLO from '../../../../shared/NavigationTLO';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import SubMenuTabs from '../../SubMenu/SubMenuTabs';
import SelectInput from '~components/SelectInput';
import UploadClosedProcedureDocumentArea from './Components/UploadClosedProcedureDocumentArea/UploadClosedProcedureDocumentArea';
import {apiDownloadPDF, apiTloDownloadDocuments} from '../../CommonApi/CommonApi';
import {faCheck, faList} from '@fortawesome/free-solid-svg-icons';
import {getExcelSignedURLFetchRequest} from '~commonApi/download';
import {getPreSignedURLFetchRequest} from '~utils/requests';
import {getUserRoles} from '../../CommonFunctions/CommonFunctions';
import {injectIntl, IntlShape} from 'react-intl';
import moment from 'moment';
import pako from 'pako';
import {saveAs} from 'file-saver';
import {trackPageView} from '../../../../utils';

const DEFAULT_COUNT = 0;

const PROCEDURE_TYPES = [
    {id: '0', value: 'Please choose'},
    {id: '1', value: 'Closed Procedures'},
    {id: '2', value: 'Ongoing Procedures'},
];

const ACCEPTANCE_MESSAGE = `I accept to keep all the information available in the framework of the new species procedure confidential and not to disclose to any unauthorized person any document linked to a CPVO application. I accept that by filing an application for entrustment I implicitly state that my institution is able to fulfil the quality requirements as laid down in the in the “Entrustment Requirements for the CPVO Examination Offices” issued under the authority of the CPVO’s Administrative Council (AC).`;

const PROCEDURE_STATE_MESSAGES = {
    CLOSED: 'Procedure closed',
    STEP_ONE: 'Currently in Step one. See details below.',
    STEP_TWO: 'Currently in Step two. See details below.',
    NOT_OPEN: 'This inventory number is not yet open for consultation nor modification.',
    NO_ONGOING_PROC: 'There is currently no open procedure.',
};

export const PROCEDURE_STATE = {
    CLOSED: 0,
    STEP_ONE: 1,
    STEP_TWO: 2,
    NOT_OPEN: 3,
    NO_ONGOING_PROC: 4,
};

const modalScreenType: ModalScreenType = {
    ERROR: 'ERROR',
    INFO: 'INFO',
    DOCUMENTS_RELATED_TO_PROPOSAL: 'DOCUMENTS_RELATED_TO_PROPOSAL',
    LIST_OF_REQUESTED_ENTRUSTMENTS: 'LIST_OF_REQUESTED_ENTRUSTMENTS',
    SUMMARY_OF_ANSWERS_FOR_INVENTORY_NUMBER: 'SUMMARY_OF_ANSWERS_FOR_INVENTORY_NUMBER',
    ANSWERS_TO_CPVO_PROPOSAL: 'ANSWERS_TO_CPVO_PROPOSAL',
    LUMPSUMOK_TO_CPVO_PROPOSAL: 'LUMPSUMOK_TO_CPVO_PROPOSAL',
};

const CROP_SECTOR_ORDER = {
    AGRICULTURA: 1,
    FRUIT: 2,
    ORNAMENTAL: 3,
    VEGETABLE: 4,
    PENDING: 5,
};

const MODAL_TO_CPVO_PROPOSAL_TYPE = {
    ANSWERS: 1,
    LUMPSUMOK: 2,
};

const PROCEDURE_TYPE = {
    CLOSED_PROCEDURES: '1',
    ONGOING_PROCEDURES: '2',
};

interface NewSpeciesProcedurePageProps {
    intl: IntlShape;
    history: History;
}

const NewSpeciesProcedurePage = (props: NewSpeciesProcedurePageProps & RouteComponentProps) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [infoMessage, setInfoMessage] = useState<string>('');
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [procedureTypeId, setProcedureTypeId] = useState('0');
    const [procedureTypes] = useState(PROCEDURE_TYPES);
    const [hasAcceptedInformation, setHasAcceptedInformation] = useState(false);
    const [inventoryNumberId, setInventoryNumberId] = useState('0');
    const [inventoryNumberValue, setInventoryNumberValue] = useState('');
    const [inventoryNumberList, setInventoryNumberList] = useState<Array<{id: string; value: string}>>([
        {
            id: '0',
            value: 'Please choose',
        },
    ]);
    const [ongoingInventoryNumberList, setOngoingInventoryNumberList] = useState<Array<{id: string; value: string}>>(
        []
    );
    const [closedInventoryNumberList, setClosedInventoryNumberList] = useState<Array<{id: string; value: string}>>([
        {
            id: '0',
            value: 'Please choose',
        },
    ]);
    const [closedProcedureDocumentExists, setClosedProcedureDocumentExists] = useState(false);
    const [procedureState, setProcedureState] = useState<number | null>(null);
    const [procedureStateMessage, setProcedureStateMessage] = useState('');
    const [inventoryDetails, setInventoryDetails] = useState<Array<InventoryDetails> | null>(null);
    const [count, setCount] = useState(DEFAULT_COUNT);
    const [outcomeUrl, setOutcomeUrl] = useState('');
    const [proposalNumber, setProposalNumber] = useState(0);
    const [proposedCostGroup, setProposedCostGroup] = useState('');
    const [quantityQuality, setQuantityQuality] = useState<string | null>(null);
    const [quantityQualityIds, setQuantityQualityIds] = useState<string | null>(null);
    const [proposalDocumentsList, setProposalDocumentsList] = useState<Array<ProposalDocument>>([]);
    const [proposalAnswersList, setProposalAnswersList] = useState<Array<ProposalAnswer>>([]);
    const [eoName, setEoName] = useState('');
    const [requestedEntrustmentsList, setRequestedEntrustmentsList] = useState<Array<RequestedEntrustment>>([]);
    const [summaryOfAnswersList, setSummaryOfAnswersList] = useState<Array<SummaryOfAnswers>>([]);
    const [groupHash, setGroupHash] = useState<{id: string; label: string; hash: Array<string>}>({
        id: '',
        label: '',
        hash: [],
    });
    const [groupHashCropSector, setGroupHashCropSector] = useState<{id: string; label: string; hash: Array<string>}>({
        id: '',
        label: '',
        hash: [],
    });
    const [photosUrls, setPhotosUrls] = useState<
        Array<{
            [key: string]: {
                fileUrl: string;
                thumbnailUrl: string;
            };
        }>
    >([]);
    const [userRoles] = useState(getUserRoles());

    const getMenuLinks = () => {
        return userRoles.indexOf('WETLO') === -1 &&
            userRoles.indexOf('TLOOF') === -1 &&
            userRoles.indexOf('EOADM') === -1
            ? []
            : [
                  {
                      id: 'DESIGNATION_AGREEMENT',
                      value: 'Designation Agreement',
                      path: 'tloMyContractDesignationAgreement',
                  },
                  {
                      id: 'SCOPE_OF_ENTRUSTMENT',
                      value: 'Scope of Entrustment',
                      path: 'tloMyContractScopeOfEntrustment',
                  },
                  {
                      id: 'CONSULT_MODIFY_SPECIES_DATA',
                      value: 'Consult / Modify Species Data',
                      path: 'tloMyContractConsultModifySpecies',
                  },
                  {id: 'REPORTING_DEADLINES', value: 'Reporting Deadlines', path: 'tloMyContractReportingDeadlines'},
                  {
                      id: 'REQUEST_NEW_ENTRUSTMENT',
                      value: 'Request New Entrustment',
                      path: 'tloMyContractRequestNewEntrustment',
                  },
                  {
                      id: 'WITHDRAW_AN_ENTRUSTMENT',
                      value: 'Withdraw an Entrustment',
                      path: 'tloMyContractWithdrawAnEntrustment',
                  },
                  {
                      id: 'NEW_SPECIES_PROCEDURE',
                      value: 'New Species Procedure',
                      path: 'tloMyContractNewSpeciesProcedure',
                  },
                  {
                      id: 'MANUAL',
                      value: 'Contract’s User Manual',
                      path: 'tlodocs/manual/TLO_user_manual_new_revised_by_LDE.pdf',
                  },
              ];
    };

    const getProcedureState = () => {
        let diffCurrentDateStartProposalDate = 0;
        let diffCurrentDateDeadlineNegotiationsDate = 0;
        let diffCurrentDateEndProposalDate = 0;
        if (inventoryDetails !== null && inventoryDetails.length > 0) {
            const startProposalDate = moment(inventoryDetails[0].startProposalDate).format('DD/MM/YYYY');
            const deadlineNegotiationsDate = moment(inventoryDetails[0].deadlineNegotiationsDate).format('DD/MM/YYYY');
            const endProposalDate = moment(inventoryDetails[0].endProposalDate).format('DD/MM/YYYY');
            diffCurrentDateStartProposalDate = moment(new Date(), 'DD/MM/YYYY').diff(
                moment(startProposalDate, 'DD/MM/YYYY'),
                'days'
            );
            diffCurrentDateDeadlineNegotiationsDate = moment(new Date(), 'DD/MM/YYYY').diff(
                moment(deadlineNegotiationsDate, 'DD/MM/YYYY'),
                'days'
            );
            diffCurrentDateEndProposalDate = moment(new Date(), 'DD/MM/YYYY').diff(
                moment(endProposalDate, 'DD/MM/YYYY'),
                'days'
            );
            if (diffCurrentDateStartProposalDate >= 0 && diffCurrentDateDeadlineNegotiationsDate <= 0) {
                if (diffCurrentDateEndProposalDate <= 0) {
                    setProcedureState(PROCEDURE_STATE.STEP_ONE);
                } else {
                    setProcedureState(PROCEDURE_STATE.STEP_TWO);
                }
            } else if (diffCurrentDateStartProposalDate < 0) {
                setProcedureState(PROCEDURE_STATE.NOT_OPEN);
            } else {
                setProcedureState(PROCEDURE_STATE.CLOSED);
            }
        }
    };

    const showDownloadLinkArea = (shouldShow: boolean, fileUrl: string) => {
        setClosedProcedureDocumentExists(shouldShow);
        setOutcomeUrl(fileUrl);
    };

    const checkIfClosedProcedureDocumentExists = (inventoryNumber: string) => {
        setLoading(true);
        apiGetClosedProcedureDocumentFile(inventoryNumber)
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.url && jsonResponse.data.url.length > 0) {
                    showDownloadLinkArea(true, jsonResponse.data.url);
                } else {
                    showDownloadLinkArea(false, '');
                }
            })
            .catch(error => {
                setErrorMessage(`Closed Procedure Document Fetch error: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getProcedureStateMessages = () => {
        if (procedureState === PROCEDURE_STATE.STEP_ONE) {
            setProcedureStateMessage(PROCEDURE_STATE_MESSAGES.STEP_ONE);
        } else if (procedureState === PROCEDURE_STATE.STEP_TWO) {
            setProcedureStateMessage(PROCEDURE_STATE_MESSAGES.STEP_TWO);
        } else if (procedureState === PROCEDURE_STATE.NOT_OPEN) {
            setProcedureStateMessage(PROCEDURE_STATE_MESSAGES.NOT_OPEN);
        } else {
            setProcedureStateMessage(PROCEDURE_STATE_MESSAGES.CLOSED);
        }
    };

    const goToPage = (path: string) => {
        props.history.push(location.pathname);
        window.open(path, '_blank');
    };

    const getInventoryNumbersList = () => {
        setLoading(true);
        apiInventoryNumbersListFetch()
            .then((jsonResponse: InventoryNumberListFetchData) => {
                if (
                    jsonResponse &&
                    jsonResponse.data &&
                    jsonResponse.data.inventoryNumberList &&
                    jsonResponse.data.inventoryNumberList.length > 0
                ) {
                    const finalInventoryNumberList: Array<{id: string; value: string}> = inventoryNumberList;
                    let idx = 0;
                    const ongoingInventoryNumberList: Array<{id: string; value: string}> = [];
                    const finalClosedInventoryNumberList: Array<{id: string; value: string}> =
                        closedInventoryNumberList;
                    for (const inventoryNumber of jsonResponse.data.inventoryNumberList) {
                        finalInventoryNumberList.push({
                            id: (++idx).toString(),
                            value: inventoryNumber.inventoryNumber,
                        });
                        const startProposalDateStr = moment(inventoryNumber.startProposalDate).format('DD/MM/YYYY');
                        const endProposalDateStr = moment(inventoryNumber.endProposalDate).format('DD/MM/YYYY');
                        const deadlineNegotiationsDateStr = moment(inventoryNumber.deadlineNegotiationsDate).format(
                            'DD/MM/YYYY'
                        );
                        const currentDate = moment(new Date(), 'DD/MM/YYYY');
                        if (
                            currentDate.diff(moment(startProposalDateStr, 'DD/MM/YYYY'), 'days') > 0 &&
                            currentDate.diff(moment(endProposalDateStr, 'DD/MM/YYYY'), 'days') > 0 &&
                            currentDate.diff(moment(deadlineNegotiationsDateStr, 'DD/MM/YYYY'), 'days') > 0
                        ) {
                            finalClosedInventoryNumberList.push({
                                id: idx.toString(),
                                value: inventoryNumber.inventoryNumber,
                            });
                        } else {
                            ongoingInventoryNumberList.push({
                                id: idx.toString(),
                                value: inventoryNumber.inventoryNumber,
                            });
                        }
                    }
                    setInventoryNumberList(finalInventoryNumberList);
                    setOngoingInventoryNumberList(ongoingInventoryNumberList);
                    setClosedInventoryNumberList(finalClosedInventoryNumberList);
                }
            })
            .catch(error => {
                setErrorMessage(`Inventory Number list error: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getPhotosUrls = (proposalNumberList: Array<number>) => {
        apiGetImageFiles(proposalNumberList)
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.urls) {
                    setPhotosUrls(jsonResponse.data.urls);
                }
            })
            .catch(error => {
                setErrorMessage(`Photos URLs list error: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            });
    };

    const getInventoryDetails = () => {
        setLoading(true);
        apiInventoryDetailsListFetch(inventoryNumberValue)
            .then((jsonResponse: InventoryDetailsListFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.inventoryDetails) {
                    const proposalNumberList = jsonResponse.data.inventoryDetails.map(el => el.eoProposalNumber);
                    getPhotosUrls(proposalNumberList);
                    setInventoryDetails(jsonResponse.data.inventoryDetails);
                    if (jsonResponse.count !== undefined) {
                        setCount(jsonResponse.count);
                    }
                }
            })
            .catch(error => {
                setErrorMessage(`Inventory Details search list error: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        trackPageView({documentTitle: 'tloMyContractNewSpeciesProcedure'});
        (userRoles.indexOf('EOADM') !== -1 || userRoles.indexOf('WETLO') !== -1 || userRoles.indexOf('TLOOF') !== -1) &&
            getInventoryNumbersList();
    }, []);

    useEffect(() => {
        inventoryNumberValue.length > 0 && procedureTypeId !== '0' && getInventoryDetails();
    }, [inventoryNumberValue, procedureTypeId]);

    useEffect(() => {
        if (
            procedureTypeId === PROCEDURE_TYPE.ONGOING_PROCEDURES &&
            hasAcceptedInformation &&
            ongoingInventoryNumberList.length > 0
        ) {
            setInventoryNumberId(ongoingInventoryNumberList[0].id);
            setInventoryNumberValue(ongoingInventoryNumberList[0].value);
        } else {
            setProcedureState(PROCEDURE_STATE.NO_ONGOING_PROC);
            setProcedureStateMessage(PROCEDURE_STATE_MESSAGES.NO_ONGOING_PROC);
        }
    }, [procedureTypeId, hasAcceptedInformation, ongoingInventoryNumberList]);

    useEffect(() => {
        if (inventoryDetails !== null && inventoryDetails.length > 0) {
            getProcedureState();
            getProcedureStateMessages();
            const groupHashCropSector: {[key: string]: string} = {};
            (inventoryDetails || []).forEach(row => {
                if (row.status) {
                    row.cropSectorGroup = 'Pending new species, already published twice without any proposal';
                    row.cropSector = CROP_SECTOR_ORDER.PENDING + row.cropSector;
                } else {
                    if (row.cropSector === 'Ornamental') {
                        row.cropSectorGroup = 'Ornamental new species';
                        row.cropSector = CROP_SECTOR_ORDER.ORNAMENTAL + row.cropSector;
                    } else if (row.cropSector === 'Agricultural') {
                        row.cropSectorGroup = 'Agricultural new species';
                        row.cropSector = CROP_SECTOR_ORDER.AGRICULTURA + row.cropSector;
                    } else if (row.cropSector === 'Vegetable') {
                        row.cropSectorGroup = 'Vegetable new species';
                        row.cropSector = CROP_SECTOR_ORDER.VEGETABLE + row.cropSector;
                    } else if (row.cropSector === 'Fruit') {
                        row.cropSectorGroup = 'Fruit new species';
                        row.cropSector = CROP_SECTOR_ORDER.FRUIT + row.cropSector;
                    }
                }
                if (row.cropSectorGroup) {
                    groupHashCropSector[row.cropSectorGroup] = row.cropSectorGroup;
                }
            });

            setGroupHashCropSector({
                id: 'cropSectorGroup',
                label: 'cropSectorGroup',
                hash: Object.keys(groupHashCropSector),
            });
        }
    }, [inventoryDetails]);

    useEffect(() => {
        procedureState !== null && getProcedureStateMessages();
    }, [procedureState]);

    const closeErrorModal = () => {
        setModalScreen(null);
        setErrorMessage(null);
    };

    const closeInfoModal = () => {
        setModalScreen(null);
        setInfoMessage('');
    };

    const onProcedureTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const procedureTypeId = event.target.value;
        setProcedureTypeId(procedureTypeId);
        setInventoryNumberId('0');
        setInventoryNumberValue('');
        setProcedureState(null);
        setProcedureStateMessage('');
        setHasAcceptedInformation(false);
    };

    const onAcceptInformationChecked = () => setHasAcceptedInformation(!hasAcceptedInformation);

    const onInventoryNumberChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const inventoryNumberId = event.target.value;
        setInventoryNumberId(inventoryNumberId);
        const inventoryNumberValue = inventoryNumberList.filter(inel => inel.id === inventoryNumberId)[0].value;
        setInventoryNumberValue(inventoryNumberValue);
        checkIfClosedProcedureDocumentExists(inventoryNumberValue);
    };

    const printExcelInventoryDetails = (excelTranslations: {[key: string]: string}) => {
        setLoading(true);
        apiInventoryDetailsLDownloadExcel(inventoryNumberValue, excelTranslations)
            .then((jsonResponse: InventoryDetailsListFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                    getExcelSignedURLFetchRequest(jsonResponse.data.token).then((response: any) => {
                        if (response && response.signedUrl) {
                            const {signedUrl} = response;
                            getPreSignedURLFetchRequest(signedUrl)
                                .then((response: any) => response.blob())
                                .then((responseBlob: any) => {
                                    saveAs(responseBlob, `CPVO_newspecies_${inventoryNumberValue}.xlsx`);
                                });
                        }
                    });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading excel: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const printPDF = () => {
        setLoading(true);
        apiInventoryDetailsLDownloadPDF(inventoryNumberValue)
            .then((jsonResponse: InventoryDetailsListFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                    apiDownloadPDF(jsonResponse.data.token).then((response: any) => {
                        if (response && response.signedUrl) {
                            const {signedUrl} = response;
                            getPreSignedURLFetchRequest(signedUrl)
                                .then((response: any) => response.blob())
                                .then((responseBlob: any) => {
                                    saveAs(responseBlob, `CPVO_newspecies_${inventoryNumberValue}.pdf`);
                                });
                        }
                    });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading pdf: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getDocumentProposalWindowData = (inventoryNumber: string, proposalNumber: number) => {
        setLoading(true);
        apiDocumentsProposalWindowFetch(inventoryNumber, proposalNumber)
            .then((jsonResponse: ProposalDocumentsListFetchData) => {
                if (
                    jsonResponse &&
                    jsonResponse.data &&
                    jsonResponse.data.proposalWindow &&
                    jsonResponse.data.proposalWindow.length > 0
                ) {
                    setProposalDocumentsList(jsonResponse.data.proposalWindow);
                    setProposalNumber(proposalNumber);
                    setModalScreen(modalScreenType.DOCUMENTS_RELATED_TO_PROPOSAL);
                } else {
                    setInfoMessage('No documents available for this proposal');
                    setModalScreen(modalScreenType.INFO);
                }
            })
            .catch(error => {
                setErrorMessage(`Proposal Documents list data error: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const showModalDocumentsRelatedToProposalScreen = (proposalNumber: number) => {
        if (
            userRoles.indexOf('WETLO') === -1 &&
            userRoles.indexOf('TLOOF') === -1 &&
            userRoles.indexOf('EOADM') === -1
        ) {
            setProposalDocumentsList([]);
            setProposalNumber(proposalNumber);
            setModalScreen(modalScreenType.DOCUMENTS_RELATED_TO_PROPOSAL);
        } else {
            getDocumentProposalWindowData(inventoryNumberValue, proposalNumber);
        }
    };

    const closeModalDocumentsRelatedToProposal = () => {
        setModalScreen(null);
        setProposalDocumentsList([]);
        setProposalNumber(0);
    };

    const parseProposalAnsersObject = (obj: ProposalAnswer): ProposalAnswer => {
        const parsedEntrustment = Object.assign({}, obj);
        Object.keys(obj).forEach(key => {
            if (
                [
                    'closingDate',
                    'submissionStart',
                    'submissionEnd',
                    'interimReportDate',
                    'foreseenFinalReportDate',
                    'startEntrustmentDate',
                ].includes(key)
            ) {
                const date = obj[key];
                if (date !== null) {
                    parsedEntrustment[key] = moment(date).format('DD/MM/YYYY');
                } else {
                    parsedEntrustment[key] = date;
                }
            } else {
                parsedEntrustment[key] = obj[key];
            }
        });

        return parsedEntrustment;
    };

    const getProposalAnswersWindowData = (
        inventoryNumber: string,
        proposalNumber: number,
        proposedCostGroup: string,
        quantityQuality: string | null,
        quantityQualityIds: string | null,
        editAnswer: number
    ) => {
        setLoading(true);
        apiProposalAnswersWindowFetch(inventoryNumber, proposalNumber)
            .then((jsonResponse: ProposalAnswersListFetchData) => {
                if (
                    jsonResponse &&
                    jsonResponse.data &&
                    jsonResponse.data.proposalAnswers &&
                    jsonResponse.data.proposalAnswers.length > 0
                ) {
                    const parsedProposalAnswers: Array<ProposalAnswer> = [];
                    jsonResponse.data.proposalAnswers.forEach(el => {
                        const parseElement = parseProposalAnsersObject(el);
                        parsedProposalAnswers.push(parseElement);
                    });
                    setProposalAnswersList(parsedProposalAnswers);
                    setProposalNumber(proposalNumber);
                    setProposedCostGroup(proposedCostGroup);
                    setQuantityQuality(quantityQuality);
                    setQuantityQualityIds(quantityQualityIds);
                    if (editAnswer === MODAL_TO_CPVO_PROPOSAL_TYPE.LUMPSUMOK) {
                        setModalScreen(modalScreenType.LUMPSUMOK_TO_CPVO_PROPOSAL);
                    } else {
                        setModalScreen(modalScreenType.ANSWERS_TO_CPVO_PROPOSAL);
                    }
                }
            })
            .catch(error => {
                setErrorMessage(`Proposal Answers list data error: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const showModalAnswerToCPVOProposalNumberScreen = (
        proposalNumber: number,
        proposedCostGroup: string,
        quantityQuality: string | null,
        quantityQualityIds: string | null
    ) => {
        getProposalAnswersWindowData(
            inventoryNumberValue,
            proposalNumber,
            proposedCostGroup,
            quantityQuality,
            quantityQualityIds,
            MODAL_TO_CPVO_PROPOSAL_TYPE.ANSWERS
        );
    };

    const closeModalAnswerToCPVOProposalNumber = () => {
        setModalScreen(null);
        setProposalDocumentsList([]);
        setProposalNumber(0);
    };

    const saveAnswerToCPVOProposalNumber = (proposalAnswerDataForSave: DraftProposalAnswerForSave) => {
        setLoading(true);
        apiProposalAnswerSaveAsDraft(Object.assign({}, proposalAnswerDataForSave))
            .then(() => {
                closeModalAnswerToCPVOProposalNumber();
                getInventoryDetails();
            })
            .catch(error => {
                setErrorMessage(`error saving draft answer to CPVO proposal: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const getRequestedEntrustmentsData = (inventoryNumber: string) => {
        setLoading(true);
        apiRequestedEntrustmentsFetch(inventoryNumber)
            .then((jsonResponse: RequestedEntrustmentsFetchData) => {
                if (
                    jsonResponse &&
                    jsonResponse.data &&
                    jsonResponse.data.finalPreview &&
                    jsonResponse.data.finalPreview.length > 0
                ) {
                    setRequestedEntrustmentsList(jsonResponse.data.finalPreview);
                    setEoName(jsonResponse.data.finalPreview[0].eoName);
                }
                setModalScreen(modalScreenType.LIST_OF_REQUESTED_ENTRUSTMENTS);
            })
            .catch(error => {
                setErrorMessage(`Requested Entrustments list data error: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const showModalListOfRequestedEntrustmentsScreen = () => getRequestedEntrustmentsData(inventoryNumberValue);

    const closeModalListOfRequestedEntrustments = () => closeModalAnswerToCPVOProposalNumber();

    const downloadDocumentsRelatedToProposalNumber = (selectedDocuments: Array<string>) => {
        if (selectedDocuments && selectedDocuments.length > 0) {
            if (selectedDocuments[0] === 'null') {
                setErrorMessage(`There are no documents available to download.`);
                setModalScreen(modalScreenType.ERROR);
            } else {
                const csDocsKeys: Array<number> = selectedDocuments.map(Number);
                const documentCount = csDocsKeys.length;
                setLoading(true);
                apiTloDownloadDocuments(
                    documentCount === 1 ? csDocsKeys.slice(0, 1)[0] : null,
                    documentCount > 1 ? {csDocsKeys} : undefined
                )
                    .then((JSONResponse: any) => {
                        if (JSONResponse && JSONResponse.documents) {
                            let preSignedURLs = [];
                            let fileNames: Array<string> = [];
                            let isZips: Array<boolean | undefined> = [];
                            if (Array.isArray(JSONResponse.documents)) {
                                preSignedURLs = JSONResponse.documents.map((document: any) => document?.signedUrl);
                                fileNames = JSONResponse.documents.map((document: any) => document?.docName);
                                isZips = JSONResponse.documents.map((document: any) => document?.isZip);
                            } else {
                                preSignedURLs.push(JSONResponse.documents?.signedUrl);
                                fileNames.push(JSONResponse.documents?.docName);
                                isZips = [JSONResponse.documents?.isZip];
                            }
                            if (preSignedURLs.length === 1) {
                                getPreSignedURLFetchRequest(preSignedURLs[0])
                                    .then((response: any) => response.blob())
                                    .then((responseBlob: any) => {
                                        if (isZips[0]) {
                                            responseBlob.arrayBuffer().then((fileReader: any) => {
                                                saveAs(
                                                    new Blob([pako.ungzip(new Uint8Array(fileReader))]),
                                                    fileNames[0]
                                                );
                                            });
                                        } else {
                                            saveAs(responseBlob, fileNames[0]);
                                        }
                                    });
                            } else {
                                (async function () {
                                    let i = 0;
                                    let processedBlobs = [];
                                    for await (let preSignedURL of preSignedURLs) {
                                        let response = await getPreSignedURLFetchRequest(preSignedURL);
                                        let responseBlob = await response.blob();
                                        if (isZips[i]) {
                                            let fileReader = await responseBlob.arrayBuffer();
                                            processedBlobs.push(new Blob([pako.ungzip(new Uint8Array(fileReader))]));
                                        } else {
                                            processedBlobs.push(responseBlob);
                                        }
                                        i += 1;
                                    }
                                    const zip = new JSZip();
                                    processedBlobs.forEach((blob, i) => {
                                        zip.file(fileNames[i], blob, {
                                            binary: true,
                                        });
                                    });
                                    let content = await zip.generateAsync({
                                        type: 'blob',
                                    });
                                    const fileName =
                                        proposalNumber + '_documents_' + moment().format('YYYY-MM-DD') + '.zip';
                                    saveAs(content, fileName);
                                })();
                            }
                        }
                    })
                    .catch((error: any) => {
                        setErrorMessage(`error downloading file: ${error}`);
                        setModalScreen(modalScreenType.ERROR);
                    })
                    .finally(() => setLoading(false));
            }
        }
    };

    const parseSummaryOfAnswersObject = (obj: SummaryOfAnswers): SummaryOfAnswers => {
        const parsedEntrustment = Object.assign({}, obj);
        Object.keys(obj).forEach(key => {
            if (['startEntrustmentDate'].includes(key)) {
                const date = obj[key] as string;
                if (date !== null) {
                    parsedEntrustment[key] = moment(date).format('DD/MM/YYYY');
                } else {
                    parsedEntrustment[key] = date;
                }
            } else {
                parsedEntrustment[key] = obj[key];
            }
        });

        return parsedEntrustment;
    };

    const getSummaryOfAnswersData = (inventoryNumber: string) => {
        setLoading(true);
        apiSummaryOfAnswersFetch(inventoryNumber)
            .then((jsonResponse: SummaryOfAnswersFetchData) => {
                if (
                    jsonResponse &&
                    jsonResponse.data &&
                    jsonResponse.data.summaryAnswers &&
                    jsonResponse.data.summaryAnswers.length > 0
                ) {
                    const parsedSummaryOfAnswers: Array<SummaryOfAnswers> = [];
                    jsonResponse.data.summaryAnswers.forEach(el => {
                        const parseElement = parseSummaryOfAnswersObject(el);
                        parsedSummaryOfAnswers.push(parseElement);
                    });
                    setSummaryOfAnswersList(parsedSummaryOfAnswers);
                    setModalScreen(modalScreenType.SUMMARY_OF_ANSWERS_FOR_INVENTORY_NUMBER);
                }
            })
            .catch(error => {
                setErrorMessage(`Summary of Answers list data error: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (summaryOfAnswersList.length > 0) {
            const groupHash: {[key: string]: string} = {};
            (summaryOfAnswersList || []).forEach(row => {
                if (row.speciesName) {
                    groupHash[row.speciesName] = row.speciesName;
                }
            });

            setGroupHash({id: 'speciesName', label: 'speciesName', hash: Object.keys(groupHash)});
        }
    }, [summaryOfAnswersList]);

    const printExcelSummaryOfAnswers = (excelTranslations: {[key: string]: string}) => {
        setLoading(true);
        apiSummaryOfAnswersDownloadExcel(inventoryNumberValue, excelTranslations)
            .then((jsonResponse: SummaryOfAnswersFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                    getExcelSignedURLFetchRequest(jsonResponse.data.token).then((response: any) => {
                        if (response && response.signedUrl) {
                            const {signedUrl} = response;
                            getPreSignedURLFetchRequest(signedUrl)
                                .then((response: any) => response.blob())
                                .then((responseBlob: any) => {
                                    saveAs(
                                        responseBlob,
                                        `CPVO_newspecies_${inventoryNumberValue}_for_negotiations.xlsx`
                                    );
                                });
                        }
                    });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading excel: ${error}`);
                setModalScreen(modalScreenType.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const updateNegotiationsForInventoryNumber = (editedRows: SummaryOfAnswersForSave) => {
        setLoading(true);
        let obj: SummaryOfAnswersUpdateNegotiationData = {proposalNumber: '0', negotiation: ''};
        Object.keys(editedRows).forEach(pn => {
            obj = {proposalNumber: pn, negotiation: editedRows[pn].negotiation};
            apiUpdateNegotiationOfSummaryAnswers(obj)
                .then(() => {
                    setModalScreen(null);
                    setSummaryOfAnswersList([]);
                })
                .catch(error => {
                    setErrorMessage(`error updating negotiation: ${error}`);
                    setModalScreen(modalScreenType.ERROR);
                })
                .finally(() => setLoading(false));
        });
    };

    const withdrawProposalsForInventoryNumber = (editedRows: SummaryOfAnswersForSave) => {
        setLoading(true);
        const proposalNumberList = Object.keys(editedRows).filter(key => editedRows[key].withdraw === true);
        if (proposalNumberList.length > 0) {
            let obj: SummaryOfAnswersWithdrawProposalData = {proposalNumber: proposalNumberList.map(Number)};
            apiWithdrawProposalsOfSummaryAnswers(obj)
                .then(() => {
                    setModalScreen(null);
                    setSummaryOfAnswersList([]);
                })
                .catch(error => {
                    setErrorMessage(`error withdrawing proposals: ${error}`);
                    setModalScreen(modalScreenType.ERROR);
                })
                .finally(() => setLoading(false));
        }
    };

    const updateLumpSumOkForInventoryNumber = (editedRows: SummaryOfAnswersForSave) => {
        setLoading(true);
        let obj: SummaryOfAnswersUpdateLumpSumOkData = {proposalNumber: '0', lumpSumOk: false};
        Object.keys(editedRows).forEach(pn => {
            obj = {proposalNumber: pn, lumpSumOk: editedRows[pn].lumpSumOk, examOfficeId: editedRows[pn].examOfficeId};
            apiUpdateLumpSumOkOfSummaryAnswers(obj)
                .then(() => {
                    setModalScreen(null);
                    setSummaryOfAnswersList([]);
                })
                .catch(error => {
                    setErrorMessage(`error updating lumpSumOk: ${error}`);
                    setModalScreen(modalScreenType.ERROR);
                })
                .finally(() => setLoading(false));
        });
    };

    const updateSummaryAnswersForInventoryNumber = (editedRows: SummaryOfAnswersForSave) => {
        updateNegotiationsForInventoryNumber(editedRows);
        withdrawProposalsForInventoryNumber(editedRows);
        userRoles.indexOf('EOADM') !== -1 && updateLumpSumOkForInventoryNumber(editedRows);
    };

    const showModalSummaryOfAnswersForInventoryNumberScreen = () => getSummaryOfAnswersData(inventoryNumberValue);

    const closeModalSummaryOfAnswersForInventoryNumber = () => {
        setModalScreen(null);
        setSummaryOfAnswersList([]);
    };

    return (
        <>
            {modalScreen === modalScreenType.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {modalScreen === modalScreenType.INFO ? (
                <ModalAlertVersion2 title={`Info`} message={infoMessage} close={closeInfoModal} />
            ) : null}
            {modalScreen === modalScreenType.DOCUMENTS_RELATED_TO_PROPOSAL &&
            proposalDocumentsList.length >= 0 &&
            proposalNumber > 0 ? (
                <ModalDocumentsRelatedToProposal
                    proposalDocumentsList={proposalDocumentsList}
                    proposalNumber={proposalNumber}
                    userRoles={userRoles}
                    downloadDocuments={downloadDocumentsRelatedToProposalNumber}
                    close={closeModalDocumentsRelatedToProposal}
                />
            ) : null}
            {modalScreen === modalScreenType.ANSWERS_TO_CPVO_PROPOSAL &&
            proposalAnswersList.length === 1 &&
            proposalNumber > 0 &&
            proposedCostGroup.length > 0 ? (
                <ModalAnswerToCPVOProposalNumber
                    proposalAnswer={proposalAnswersList[0]}
                    proposalNumber={proposalNumber}
                    inventoryNumber={inventoryNumberValue}
                    proposedCostGroup={proposedCostGroup}
                    quantityQuality={quantityQuality}
                    quantityQualityIds={quantityQualityIds}
                    speciesExamId={0}
                    userRoles={userRoles}
                    saveProposalAnswerAsDraft={saveAnswerToCPVOProposalNumber}
                    close={closeModalAnswerToCPVOProposalNumber}
                />
            ) : null}
            {modalScreen === modalScreenType.LIST_OF_REQUESTED_ENTRUSTMENTS ? (
                <ModalListOfRequestedEntrustments
                    requestedEntrustmentsList={requestedEntrustmentsList}
                    eoName={eoName}
                    close={closeModalListOfRequestedEntrustments}
                />
            ) : null}
            {modalScreen === modalScreenType.SUMMARY_OF_ANSWERS_FOR_INVENTORY_NUMBER &&
            summaryOfAnswersList.length > 0 ? (
                <ModalSummaryOfAnswersForInventoryNumber
                    summaryOfAnswersList={summaryOfAnswersList}
                    inventoryNumber={inventoryNumberValue}
                    userRoles={userRoles}
                    endProposalDate={inventoryDetails && inventoryDetails[0].endProposalDate}
                    deadlineNegotiationsDate={inventoryDetails && inventoryDetails[0].deadlineNegotiationsDate}
                    printExcel={printExcelSummaryOfAnswers}
                    saveEditedRows={updateSummaryAnswersForInventoryNumber}
                    groupHash={groupHash}
                    close={closeModalSummaryOfAnswersForInventoryNumber}
                />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`Technical Liaison Officer`} />
            <NavigationTLO activeTitle={`My Contract`} />
            <MainWrapper>
                <FormWrapper paddingFormContent={'sm'}>
                    {userRoles.indexOf('WETLO') === -1 &&
                    userRoles.indexOf('TLOOF') === -1 &&
                    userRoles.indexOf('EOADM') === -1 ? (
                        <div style={{paddingTop: 20}}>
                            <b style={{color: 'red'}}>
                                <span>{`You have no permissions to access this page.`}</span>
                            </b>
                        </div>
                    ) : (
                        <>
                            <SubMenuTabs tabId={'NEW_SPECIES_PROCEDURE'} loading={loading} menuLinks={getMenuLinks()} />
                            <SelectInput
                                label={'Procedure Type'}
                                value={procedureTypeId}
                                onChange={onProcedureTypeChange}
                                list={procedureTypes}
                                notAll={true}
                                triple={true}
                            />
                            <div style={{clear: 'both'}} />
                            {procedureTypeId === PROCEDURE_TYPE.CLOSED_PROCEDURES ? (
                                <React.Fragment>
                                    <SelectInput
                                        value={inventoryNumberId}
                                        onChange={onInventoryNumberChange}
                                        list={closedInventoryNumberList}
                                        outsideLabel={'Inventory Number'}
                                        outsideLabelWidth={130}
                                        notAll={true}
                                    />
                                    <Label width={400}>
                                        <div style={{color: '#f38080', float: 'left'}}>{procedureStateMessage}</div>
                                    </Label>
                                    <div style={{clear: 'both'}}>{}</div>
                                    {inventoryNumberId !== '0'
                                        ? procedureState === PROCEDURE_STATE.CLOSED &&
                                          closedProcedureDocumentExists && (
                                              <InputLink
                                                  label={`Download the official AC outcome ${inventoryNumberValue}`}
                                                  clickAction={() => goToPage(outcomeUrl)}
                                                  width={300}
                                              />
                                          )
                                        : null}
                                    {inventoryNumberId !== '0'
                                        ? procedureState === PROCEDURE_STATE.CLOSED &&
                                          !closedProcedureDocumentExists &&
                                          userRoles.indexOf('EOADM') !== -1 && (
                                              <UploadClosedProcedureDocumentArea
                                                  inventoryNumber={inventoryNumberValue}
                                                  showDownloadLinkArea={showDownloadLinkArea}
                                              />
                                          )
                                        : null}
                                    {inventoryNumberId !== '0'
                                        ? procedureState === PROCEDURE_STATE.CLOSED &&
                                          !closedProcedureDocumentExists &&
                                          userRoles.indexOf('EOADM') === -1 && (
                                              <Label double={true}>
                                                  <div style={{color: '#f38080', float: 'left'}}>
                                                      {'Document does not exist.'}
                                                  </div>
                                              </Label>
                                          )
                                        : null}
                                </React.Fragment>
                            ) : null}
                            <div style={{clear: 'both'}} />
                            {procedureTypeId === PROCEDURE_TYPE.ONGOING_PROCEDURES ? (
                                <Checkbox
                                    clickAction={onAcceptInformationChecked}
                                    label={ACCEPTANCE_MESSAGE}
                                    value={hasAcceptedInformation}
                                    triple={true}
                                />
                            ) : null}
                            <div style={{clear: 'both'}} />
                            {procedureTypeId === PROCEDURE_TYPE.ONGOING_PROCEDURES &&
                            hasAcceptedInformation &&
                            ongoingInventoryNumberList.length > 0 ? (
                                <React.Fragment>
                                    <Label>
                                        <div
                                            style={{float: 'left', fontWeight: 'bold'}}
                                        >{`Inventory Number: ${ongoingInventoryNumberList[0].value}`}</div>
                                    </Label>
                                    <Label>
                                        <div style={{color: '#f38080', float: 'left'}}>{procedureStateMessage}</div>
                                    </Label>
                                    <div style={{clear: 'both'}}>{}</div>
                                    {inventoryDetails !== null && inventoryDetails.length > 0 && (
                                        <IntroductionSectionArea
                                            startProposalDate={inventoryDetails[0].startProposalDate}
                                            endProposalDate={inventoryDetails[0].endProposalDate}
                                            deadlineNegotiationsDate={inventoryDetails[0].deadlineNegotiationsDate}
                                        />
                                    )}
                                    <div style={{clear: 'both'}} />
                                    {inventoryDetails !== null &&
                                    inventoryDetails.length > 0 &&
                                    (userRoles.indexOf('TLOOF') !== -1 || userRoles.indexOf('EOADM') !== -1) ? (
                                        <div title="New species qualifying for the payment of a lump sum">
                                            <a
                                                href="/tlodocs/manual/LumpSum_NSP_TLO.pdf"
                                                target="_blank"
                                            >{`New species qualifying for the payment of a lump sum`}</a>
                                        </div>
                                    ) : null}
                                    <div style={{clear: 'both'}} />
                                    <Empty height={5} />
                                    <div style={{clear: 'both'}} />
                                    {inventoryDetails !== null &&
                                    inventoryDetails.length > 0 &&
                                    (userRoles.indexOf('TLOOF') !== -1 || userRoles.indexOf('EOADM') !== -1) ? (
                                        <React.Fragment>
                                            <FormFooterButton
                                                color={buttonColor.blue}
                                                clickAction={showModalSummaryOfAnswersForInventoryNumberScreen}
                                                icon={faList}
                                                float={'left'}
                                            >
                                                {`Consult draft answers from all Examination Offices`}
                                            </FormFooterButton>
                                            <FormFooterButton
                                                color={buttonColor.blue}
                                                clickAction={showModalListOfRequestedEntrustmentsScreen}
                                                icon={faCheck}
                                                float={'right'}
                                            >
                                                {`Preview your final answer to CPVO`}
                                            </FormFooterButton>
                                        </React.Fragment>
                                    ) : null}
                                    <div style={{clear: 'both'}} />
                                </React.Fragment>
                            ) : null}
                            {procedureTypeId === PROCEDURE_TYPE.ONGOING_PROCEDURES &&
                            hasAcceptedInformation &&
                            ongoingInventoryNumberList.length === 0 ? (
                                <Label double={true}>
                                    <div style={{color: '#f38080', float: 'left'}}>{procedureStateMessage}</div>
                                </Label>
                            ) : null}
                            <div style={{clear: 'both'}} />
                        </>
                    )}
                    {procedureTypeId === PROCEDURE_TYPE.ONGOING_PROCEDURES &&
                    hasAcceptedInformation &&
                    procedureState !== null &&
                    procedureState !== PROCEDURE_STATE.NOT_OPEN &&
                    photosUrls.length > 0 ? (
                        <InventoryDetailsTableArea
                            intl={props.intl}
                            count={count}
                            inventoryDetails={inventoryDetails}
                            photosUrls={photosUrls}
                            userRoles={userRoles}
                            groupHash={groupHashCropSector}
                            printExcel={printExcelInventoryDetails}
                            printPDF={printPDF}
                            proposalNumberButtonCB={showModalDocumentsRelatedToProposalScreen}
                            editButtonCB={showModalAnswerToCPVOProposalNumberScreen}
                        />
                    ) : null}
                </FormWrapper>
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(NewSpeciesProcedurePage));
