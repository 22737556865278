import React, {useEffect, useState} from 'react';
import en from '../../../../../../translations/en.json';
import {ConsultModifySpeciesDataTableAreaProps} from './props/ConsultModifySpeciesDataTableAreaProps';
import styles from './ConsultModifySpeciesDataTableArea.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {
    ConsultModifySpeciesDataFilterCriteriaData,
    ConsultModifySpeciesDataTableRowData,
} from '../../../../../../types/TLO';
import CustomTable from '~components/CustomTable';
import ConsultModifySpeciesDataLabelsBottomCaption from '../ConsultModifySpeciesDataLabelsBottomCaption/ConsultModifySpeciesDataLabelsBottomCaption';
import {entrustmentEditMode, entrustmentMode} from '../ModalEntrustment/ModalEntrustment';
import {ACCESS_MESSAGES} from '../../ConsultModifySpeciesDataPage';

export default function ConsultModifySpeciesDataTableArea(props: ConsultModifySpeciesDataTableAreaProps) {
    const RESULT_FIELDS_DEFAULT = [
        'speciesName',
        'genus',
        'entrustmentType',
        'subType',
        'examGroupName',
        'nbYears',
        'interimDate',
        'finalDate',
    ];
    const CONSULT_MODIFY_SPECIES_INITIAL = {
        modifId: 0,
        speciesExamId: 0,
        speciesName: '',
        speciesId: '',
        entrustmentType: '',
        subType: '',
        interimDate: '',
        finaldate: '',
        nbYears: '',
        examFeeGroupId: '',
        examGroupName: '',
        modifType: '',
        decisionDate: '',
        etat: 0,
        genus: '',
        modifyCheckbox: false,
    };

    const [species, setSpecies] = useState<Array<ConsultModifySpeciesDataTableRowData> | null>(props.species);
    const [timestamp, setTimestamp] = useState(props.timestamp);

    const [editedRow, _1] = useState<ConsultModifySpeciesDataTableRowData>(CONSULT_MODIFY_SPECIES_INITIAL);
    // const [, setRowIsEdited] = useState(false);

    const [_2, setModifySeveralPlantRequirements] = useState(true);

    useEffect(() => {
        setSpecies(props.species);
        setTimestamp(Date.now());
    }, [props.species]);

    useEffect(() => {
        if (species) {
            let speciesNew = [...species];
            speciesNew = speciesNew.map(el => {
                if (el.speciesExamId === editedRow.speciesExamId) {
                    el = editedRow;
                }
                return el;
            });
            setSpecies(speciesNew);
            setTimestamp(Date.now());

            setModifySeveralPlantRequirements(true);
            speciesNew.forEach(el => {
                if (el.modifyCheckbox) {
                    setModifySeveralPlantRequirements(false);
                }
            });
        }
    }, [editedRow]);

    const withdrawEntrustmentCondition = (rowObject: ConsultModifySpeciesDataTableRowData) => {
        return rowObject.speciesExamId !== 0 && (rowObject.modifType === 'DEL' || rowObject.modifType === 0);
    };

    const submittedModificationEntrustmentCondition = (rowObject: ConsultModifySpeciesDataTableRowData) => {
        return (
            rowObject.speciesExamId !== 0 &&
            rowObject.modifId !== 0 &&
            rowObject.modifType !== 0 &&
            rowObject.etat === 1
        );
    };

    const newEntrustmentCondition = (rowObject: ConsultModifySpeciesDataTableRowData) => {
        return rowObject.speciesExamId === 0 && rowObject.etat === 1;
    };

    const getRowClass = (rowObject: ConsultModifySpeciesDataTableRowData) => {
        const isWithdrawEntrustment = withdrawEntrustmentCondition(rowObject);
        const isSubmittedModificationEntrustment = submittedModificationEntrustmentCondition(rowObject);
        const isNewEntrustment = newEntrustmentCondition(rowObject);

        if (isWithdrawEntrustment) {
            return styles.withdrawal;
        } else if (isSubmittedModificationEntrustment) {
            return styles.submitted;
        } else if (isNewEntrustment) {
            return styles.new;
        }
    };

    const getTooltipText = (rowObject: ConsultModifySpeciesDataTableRowData) => {
        const isWithdrawEntrustment = withdrawEntrustmentCondition(rowObject);
        const isSubmittedModificationEntrustment = submittedModificationEntrustmentCondition(rowObject);
        const isNewEntrustment = newEntrustmentCondition(rowObject);

        if (isWithdrawEntrustment) {
            return 'Withdrawal requested to CPVO';
        } else if (isSubmittedModificationEntrustment) {
            return 'Modification submitted to CPVO';
        } else if (isNewEntrustment) {
            return '';
        } else {
            return 'Edit';
        }
    };

    const getConsultOrModifyMode = (rowObject: ConsultModifySpeciesDataTableRowData) => {
        const isWithdrawEntrustment = withdrawEntrustmentCondition(rowObject);
        const isSubmittedModificationEntrustment = submittedModificationEntrustmentCondition(rowObject);
        const isNewEntrustment = newEntrustmentCondition(rowObject);

        if (isWithdrawEntrustment || isSubmittedModificationEntrustment) {
            return entrustmentEditMode.VIEW;
        } else if (isNewEntrustment) {
            return '';
        } else {
            return entrustmentEditMode.EDIT;
        }
    };

    const onActionButtonClickWithConsultOrModifyMode = (rowObject: ConsultModifySpeciesDataTableRowData) => {
        const consultOrModifyMode = getConsultOrModifyMode(rowObject);
        props.actionButtonClickCB(rowObject, consultOrModifyMode, false);
    };

    // const onCheckMassModifyCheckbox = (rowObject: ConsultModifySpeciesDataTableRowData) => {
    //     const newEditedRow: ConsultModifySpeciesDataTableRowData = Object.assign({}, rowObject);
    //     newEditedRow.modifyCheckbox = rowObject.modifyCheckbox ? !rowObject.modifyCheckbox : true;
    //     setEditedRow(newEditedRow);
    //     setRowIsEdited(true);
    // };

    const getConsultModifyActionButton = (rowObject: ConsultModifySpeciesDataTableRowData) => {
        const isNewEntrustment = newEntrustmentCondition(rowObject);
        if (isNewEntrustment) {
            return <div></div>;
        } else {
            // const isWithdrawEntrustment = withdrawEntrustmentCondition(rowObject);
            // const isSubmittedModificationEntrustment = submittedModificationEntrustmentCondition(rowObject);
            return (
                <div className={styles.consultModifyActionButton}>
                    <span
                        style={{cursor: 'pointer'}}
                        onClick={() => onActionButtonClickWithConsultOrModifyMode(rowObject)}
                        title={getTooltipText(rowObject)}
                    >
                        <FontAwesomeIcon icon={faEdit as any} color={'green'} />
                    </span>
                    {/*{!isWithdrawEntrustment && !isSubmittedModificationEntrustment ? (*/}
                    {/*    <Checkbox*/}
                    {/*        value={rowObject.modifyCheckbox}*/}
                    {/*        clickAction={() => onCheckMassModifyCheckbox(rowObject)}*/}
                    {/*    />*/}
                    {/*) : (*/}
                    {/*    <span style={{width: '47.5px'}}></span>*/}
                    {/*)}*/}
                </div>
            );
        }
    };

    const getRequestNewActionButton = (rowObject: ConsultModifySpeciesDataTableRowData) => {
        const isWithdrawEntrustment = withdrawEntrustmentCondition(rowObject);
        const isSubmittedModificationEntrustment = submittedModificationEntrustmentCondition(rowObject);
        if (isWithdrawEntrustment || isSubmittedModificationEntrustment) {
            return (
                <span
                    style={{cursor: 'pointer'}}
                    onClick={() => onActionButtonClickWithConsultOrModifyMode(rowObject)}
                    title={getTooltipText(rowObject)}
                >
                    <FontAwesomeIcon icon={faEdit as any} color={'green'} />
                </span>
            );
        } else {
            return <div></div>;
        }
    };

    const getWithdrawModeActionButton = (rowObject: ConsultModifySpeciesDataTableRowData) => {
        const isNewEntrustment = newEntrustmentCondition(rowObject);
        const isWithdrawEntrustment = withdrawEntrustmentCondition(rowObject);
        if (isNewEntrustment || isWithdrawEntrustment) {
            return <div></div>;
        } else {
            return (
                <span
                    style={{cursor: 'pointer'}}
                    onClick={() => props.actionButtonClickCB(rowObject, '', false)}
                    title={'Request to withdraw this entrustment from my contract'}
                >
                    <FontAwesomeIcon icon={faTrashAlt as any} color={'green'} />
                </span>
            );
        }
    };

    const getActionButton = (rowObject: ConsultModifySpeciesDataTableRowData) => {
        let actionButton = <div></div>;
        if (props.accessMessage === ACCESS_MESSAGES.COMPLETE_ACCESS) {
            switch (props.entrustmentMode) {
                case entrustmentMode.CONSULT_MODIFY:
                    actionButton = getConsultModifyActionButton(rowObject);
                    break;
                case entrustmentMode.NEW:
                    actionButton = getRequestNewActionButton(rowObject);
                    break;
                case entrustmentMode.WITHDRAW:
                    actionButton = getWithdrawModeActionButton(rowObject);
                    break;
            }
        }

        return actionButton;
    };

    const getTableName = () => {
        let tableName = '';
        switch (props.entrustmentMode) {
            case entrustmentMode.CONSULT_MODIFY:
                tableName = 'consultSpecies';
                break;
            case entrustmentMode.NEW:
                tableName = 'newEntrustment';
                break;
            case entrustmentMode.WITHDRAW:
                tableName = 'withdrawEntrustment';
                break;
        }

        return tableName;
    };

    const printExcel = () => {
        const excelTranslations: {[key: string]: string} = {};
        const tableName = getTableName();
        const inventoryDetailsExcelKeys = Object.keys(en).filter(key => ~key.indexOf('excel.' + tableName + '.'));
        const inventoryDetailsHeaderKeys = inventoryDetailsExcelKeys.map(key =>
            key.replace('excel.' + tableName + '.', '')
        );
        inventoryDetailsHeaderKeys.forEach(headerKey => {
            excelTranslations[headerKey] = props.intl.formatMessage({
                id: `excel.${tableName}.${headerKey}`,
            });
        });
        props.printExcel(excelTranslations);
    };

    // const openModalModifySeveralPlantRequirements = () => {
    //     let editedRowObject: ConsultModifySpeciesDataTableRowData = CONSULT_MODIFY_SPECIES_INITIAL;
    //     let editRowsSpecies: Array<EditRowsSpecies> = [];
    //     let sameGenus: boolean = true;
    //     let firstRowGenus: string = '';
    //     if (species && species?.length > 0) {
    //         species.forEach(el => {
    //             if (firstRowGenus === '' && el.modifyCheckbox) {
    //                 firstRowGenus = el.genus;
    //             }
    //         });
    //     }
    //     if (species) {
    //         species.forEach(el => {
    //             if (el.modifyCheckbox) {
    //                 if (el.genus !== firstRowGenus) {
    //                     sameGenus = false;
    //                 }
    //                 editRowsSpecies.push(
    //                     Object.assign(
    //                         {},
    //                         {speciesExamId: el.speciesExamId, speciesId: el.speciesId, speciesName: el.speciesName}
    //                     )
    //                 );
    //                 if (editedRowObject.speciesExamId === 0) {
    //                     editedRowObject = el;
    //                 }
    //             }
    //         });
    //     }
    //
    //     if (sameGenus) {
    //         if (editedRowObject.speciesExamId > 0) {
    //             props.actionButtonClickCSelectSpecies(editRowsSpecies);
    //         } else {
    //             editRowsSpecies = [];
    //             editedRowObject = CONSULT_MODIFY_SPECIES_INITIAL;
    //             props.actionButtonClickCErrorMessage(`You have to select at least one row.`);
    //         }
    //     } else {
    //         editRowsSpecies = [];
    //         editedRowObject = CONSULT_MODIFY_SPECIES_INITIAL;
    //         props.actionButtonClickCErrorMessage(`You have to select rows with the same genus.`);
    //     }
    // };

    // const getTopCaptionElement = () => {
    //     return (
    //         <div className={styles.consultModifyTopButton}>
    //             <FormFooterButton
    //                 color={buttonColor.blue}
    //                 clickAction={openModalModifySeveralPlantRequirements}
    //                 icon={faPlus}
    //                 disabled={modifySeveralPlantRequirements}
    //             >
    //                 {`Modify several plant requirements`}
    //                 <AdminIcon />
    //             </FormFooterButton>
    //         </div>
    //     );
    // };

    const getTableArea = () => {
        return species?.length === 0 ? (
            <div style={{paddingTop: 20, textAlign: 'left'}}>
                <b style={{color: 'red', textAlign: 'left'}}>
                    <span className="ng-scope">{`No Species available`}</span>
                </b>
            </div>
        ) : (
            <CustomTable
                version={2}
                pageNumber={props.criteria.pageNumber}
                {...props}
                tableName={getTableName()}
                tableType={'OBJECT'}
                tableSource={species}
                timestamp={timestamp}
                id={'entrustmentType'}
                pagination={true}
                printExcel={printExcel}
                rowClass={getRowClass}
                resultFieldsAll={RESULT_FIELDS_DEFAULT}
                resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                intl={props.intl}
                defaultOrder={props.criteria.order}
                reverseOrder={props.criteria.reverse}
                pageSize={props.criteria.pageSize}
                count={props.count}
                bottomCaption={<ConsultModifySpeciesDataLabelsBottomCaption />}
                actions={[
                    {
                        icon: (rowObject: ConsultModifySpeciesDataTableRowData) => getActionButton(rowObject),
                    },
                ]}
                updateCriteriaValue={(criteriaValue: ConsultModifySpeciesDataFilterCriteriaData) =>
                    props.updateCriteriaValue(criteriaValue)
                }
            />
        );
    };

    return (
        <React.Fragment>
            {/*{getTopCaptionElement()}*/}
            <div style={{clear: 'both'}}>{}</div>
            {getTableArea()}
        </React.Fragment>
    );
}
