import {API_SERVERLESS, getFetch} from '../../../../commonApi';

export function apiFetchACAREARights() {
    return getFetch(`${API_SERVERLESS}/acarea/fetchrights`, true);
}

export const getLanguageAbbrvFromId = (languageId: number | undefined) => {
    let languageText = 'en';
    if (languageId) {
        switch (languageId) {
            case 1: {
                languageText = 'en';
                break;
            }
            case 2: {
                languageText = 'fr';
                break;
            }
        }
    }

    return languageText;
};

export const getLanguageIdFromAbbrv = (languageAbbrv: string | undefined) => {
    let languageId = 1;
    if (languageAbbrv) {
        switch (languageAbbrv) {
            case '1':
            case 'EN': {
                languageId = 1;
                break;
            }
            case '2':
            case 'FR': {
                languageId = 2;
                break;
            }
        }
    }
    return Number(languageId);
};
