import React, {useEffect, useRef, useState} from 'react';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {ModalErrorVersion2} from '../../../../commonModals';
import NavigationTLO from '../../../../shared/NavigationTLO';
import {
    apiScopeOfEntrustmentDownload,
    apiScopeOfEntrustmentSearch,
    ScopeOfEntrustmentTableCriteriaData,
    ScopeOfEntrustmentTableRowData,
} from './ScopeOfEntrustmentPageService';
import ScopeOfEntrustmentTableArea from './Components/ScopeOfEntrustmentTableArea/ScopeOfEntrustmentTableArea';
import SubMenuTabs from '../../SubMenu/SubMenuTabs';
import {getUserRoles} from '../../CommonFunctions/CommonFunctions';
import {getExcelSignedURLFetchRequest} from '../../../../commonApi/download';
import {getPreSignedURLFetchRequest} from '../../../../utils/requests';
import {injectIntl, IntlShape} from 'react-intl';
import moment from 'moment';
import {saveAs} from 'file-saver';
import {History} from 'history';
import {trackPageView} from '../../../../utils';

const DEFAULT_CRITERIA: ScopeOfEntrustmentTableCriteriaData = {
    pageNumber: 1,
    refresh: false,
    excel: false,
};

const DEFAULT_COUNT = 0;

const modalScreenTypes = {ERROR: 'ERROR'};

interface ScopeOfEntrustmentPageProps {
    intl: IntlShape;
    history: History;
}

const ScopeOfEntrustmentPage = (props: ScopeOfEntrustmentPageProps & RouteComponentProps) => {
    const [criteria, setCriteria] = useState<ScopeOfEntrustmentTableCriteriaData>(Object.assign({}, DEFAULT_CRITERIA));
    const [, setCriteriaCount] = useState(0);
    const [annexList, setAnnexList] = useState<Array<ScopeOfEntrustmentTableRowData> | null>(null);
    const [timestamp, setTimestamp] = useState(0);
    const [, setCount] = useState(DEFAULT_COUNT);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [refresh] = useState(true);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const firstLoad = useFirstRender();
    const [userRoles] = useState(getUserRoles());

    useEffect(() => {
        trackPageView({documentTitle: 'tloMyContractScopeOfEntrustment'});
    }, []);

    function useFirstRender() {
        const firstRender = useRef(true);
        useEffect(() => {
            firstRender.current = false;
        }, []);
        return firstRender.current;
    }

    const getMenuLinks = () => {
        return userRoles.indexOf('WETLO') === -1 &&
            userRoles.indexOf('TLOOF') === -1 &&
            userRoles.indexOf('EOADM') === -1
            ? []
            : [
                  {
                      id: 'DESIGNATION_AGREEMENT',
                      value: 'Designation Agreement',
                      path: 'tloMyContractDesignationAgreement',
                  },
                  {
                      id: 'SCOPE_OF_ENTRUSTMENT',
                      value: 'Scope of Entrustment',
                      path: 'tloMyContractScopeOfEntrustment',
                  },
                  {
                      id: 'CONSULT_MODIFY_SPECIES_DATA',
                      value: 'Consult / Modify Species Data',
                      path: 'tloMyContractConsultModifySpecies',
                  },
                  {id: 'REPORTING_DEADLINES', value: 'Reporting Deadlines', path: 'tloMyContractReportingDeadlines'},
                  {
                      id: 'REQUEST_NEW_ENTRUSTMENT',
                      value: 'Request New Entrustment',
                      path: 'tloMyContractRequestNewEntrustment',
                  },
                  {
                      id: 'WITHDRAW_AN_ENTRUSTMENT',
                      value: 'Withdraw an Entrustment',
                      path: 'tloMyContractWithdrawAnEntrustment',
                  },
                  {
                      id: 'NEW_SPECIES_PROCEDURE',
                      value: 'New Species Procedure',
                      path: 'tloMyContractNewSpeciesProcedure',
                  },
                  {
                      id: 'MANUAL',
                      value: 'Contract’s User Manual',
                      path: 'tlodocs/manual/TLO_user_manual_new_revised_by_LDE.pdf',
                  },
              ];
    };

    const countCriteria = (scopeOfEntrustmentCriteria: ScopeOfEntrustmentTableCriteriaData) => {
        let countNum = 0;
        Object.keys(scopeOfEntrustmentCriteria).forEach(key => {
            if (
                scopeOfEntrustmentCriteria[key] &&
                scopeOfEntrustmentCriteria[key] !== DEFAULT_CRITERIA[key] &&
                scopeOfEntrustmentCriteria[key] !== ''
            )
                countNum++;
        });
        return countNum;
    };

    const parseCriteria = (
        unParsedCriteria: ScopeOfEntrustmentTableCriteriaData
    ): ScopeOfEntrustmentTableCriteriaData => {
        const parsedCriteria: ScopeOfEntrustmentTableCriteriaData = JSON.parse(JSON.stringify({}));
        Object.keys(unParsedCriteria).forEach(key => {
            parsedCriteria[key] = unParsedCriteria[key];
        });

        return parsedCriteria;
    };

    const buildParams = (parsedCriteria: ScopeOfEntrustmentTableCriteriaData) => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(key => parsedCriteria[key] !== DEFAULT_CRITERIA[key] && key !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key]}`);
        const joinedParamsText = `?${paramArray.join('&')}`;
        props.history.replace(`/tloMyContractScopeOfEntrustment${(paramArray.length > 0 && joinedParamsText) || ''}`);
    };

    const search = (shouldRefresh: boolean, urlLoad = false) => {
        setLoading(true);
        setCriteria(
            Object.assign({}, criteria, {refresh: !!shouldRefresh}, !shouldRefresh && !urlLoad && {pageNumber: 1})
        );
        const parsedCriteria = parseCriteria(criteria);
        buildParams(parsedCriteria);
        apiScopeOfEntrustmentSearch(parsedCriteria)
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.annexList) {
                    const newCriteria = Object.assign({}, criteria, {pageNumber: 1});
                    if (!shouldRefresh && !urlLoad) {
                        setCriteria(Object.assign({}, newCriteria));
                    }
                    setAnnexList(jsonResponse.data.annexList);
                    setTimestamp(Date.now());
                    if (jsonResponse.count) {
                        setCount(jsonResponse.count);
                    }
                }
            })
            .catch(error => {
                setErrorMessage(`Scope of Entrustment search list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const params = domainArray.pop();
            if (params) {
                params.split('&').forEach(param => {
                    const paramElements = param.split('=');
                    if (paramElements.length === 2) {
                        const key = paramElements[0];
                        criteria[key] = decodeURIComponent(paramElements[1]);
                    }
                });
                if (Object.keys(criteria).length > 0) {
                    setCriteria(criteria);
                    setCriteriaCount(countCriteria(criteria));
                    search(false, true);
                }
            }
        }
    };

    const closeErrorModal = () => {
        setErrorMessage(null);
        setModalScreen(null);
    };

    useEffect(() => {
        (userRoles.indexOf('WETLO') !== -1 || userRoles.indexOf('TLOOF') !== -1 || userRoles.indexOf('EOADM') !== -1) &&
            firstLoad &&
            loadUrlParams();
    }, []);

    useEffect(() => {
        !firstLoad && refresh && search(true);
    }, [criteria.pageNumber, criteria.pageSize]);

    useEffect(() => {
        (userRoles.indexOf('WETLO') !== -1 || userRoles.indexOf('TLOOF') !== -1 || userRoles.indexOf('EOADM') !== -1) &&
            firstLoad &&
            search(false, true);
    }, []);

    const downloadDocument = (rowObject: ScopeOfEntrustmentTableRowData, excelTranslations: {}) => {
        setLoading(true);
        apiScopeOfEntrustmentDownload(rowObject.examContractId, excelTranslations)
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                    getExcelSignedURLFetchRequest(jsonResponse.data.token).then(response => {
                        if (response && response.signedUrl) {
                            const {signedUrl} = response;
                            const officeName = rowObject.addressName.replace(' ', '_');
                            getPreSignedURLFetchRequest(signedUrl)
                                .then(response => response.blob())
                                .then(responseBlob => {
                                    saveAs(
                                        responseBlob,
                                        `Scope_of_entrustment_${officeName}_${moment().format('DD/MM/YYYY')}.xlsx`
                                    );
                                });
                        }
                    });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`Technical Liaison Officer`} />
            <NavigationTLO activeTitle={`My Contract`} />
            <MainWrapper>
                <div style={{marginBottom: 20, textAlign: 'center'}}>
                    <FormWrapper paddingFormContent={'sm'}>
                        {userRoles.indexOf('WETLO') === -1 &&
                        userRoles.indexOf('TLOOF') === -1 &&
                        userRoles.indexOf('EOADM') === -1 ? (
                            <div style={{paddingTop: 20}}>
                                <b style={{color: 'red'}}>
                                    <span className="ng-scope">{`You have no permissions to access this page.`}</span>
                                </b>
                            </div>
                        ) : (
                            <>
                                <SubMenuTabs
                                    tabId={'SCOPE_OF_ENTRUSTMENT'}
                                    loading={loading}
                                    menuLinks={getMenuLinks()}
                                />
                                <ScopeOfEntrustmentTableArea
                                    intl={props.intl}
                                    timestamp={timestamp}
                                    annexList={annexList}
                                    downloadDocument={downloadDocument}
                                />
                            </>
                        )}
                    </FormWrapper>
                </div>
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(ScopeOfEntrustmentPage));
