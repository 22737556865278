import React, {useState} from 'react';
import styles from './CandidateVarietiesFieldsArea.module.scss';
import TextInput from '~components/TextInput';
import SelectInput from '~components/SelectInput';
import {TqSearchCandidateVarietiesUrlParamsData} from '../../TqSearchCandidateVarietiesPageService';

interface CandidateVarietiesFieldsAreaProps {
    urlParams: TqSearchCandidateVarietiesUrlParamsData;
    applicants: Array<string>;
}

export default function CandidateVarietiesFieldsArea(props: CandidateVarietiesFieldsAreaProps) {
    const [applicant, setApplicant] = useState('');

    const onApplicantNameChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const applicant = event.target.value;
        setApplicant(applicant);
    };

    return (
        <React.Fragment>
            <React.Fragment>
                <div className={styles.mainFieldContainer}>
                    <TextInput
                        value={props.urlParams.speciesName}
                        double={true}
                        outsideLabel={`Species Name`}
                        outsideLabelWidth={160}
                        disabled={true}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <TextInput
                        value={props.urlParams.denomination}
                        double={true}
                        outsideLabel={`Denomination`}
                        outsideLabelWidth={160}
                        disabled={true}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <TextInput
                        value={props.urlParams.reference}
                        double={true}
                        outsideLabel={`Breeder's Reference`}
                        outsideLabelWidth={160}
                        disabled={true}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <SelectInput
                        value={applicant}
                        onChange={onApplicantNameChange}
                        list={props.applicants}
                        outsideLabel={'Applicant(s)'}
                        outsideLabelWidth={160}
                        notAll={true}
                        double={true}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <TextInput
                        value={props.urlParams.registerId}
                        double={true}
                        outsideLabel={`Application Number`}
                        outsideLabelWidth={160}
                        disabled={true}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                </div>
            </React.Fragment>
        </React.Fragment>
    );
}
