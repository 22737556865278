import React from 'react';
import {injectIntl, IntlShape} from 'react-intl';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';
import DataSheetFormFields from '~shared/DataSheetFormFields';

interface IProps {
    close: any;
    intl: IntlShape;
    rowObject: any;
}

interface IState {}

class ModalJurisprudenceCaseDataSheet extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render() {
        const {article, description, denomination, decisiondate, outcomestatus, subheading, title, specieid} =
            this.props.rowObject;

        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={`Jurisprudence case data sheet`}
                body={
                    <div>
                        <DataSheetFormFields label={`Title`} data={title} double={true} />
                        <DataSheetFormFields label={`Decision Date`} data={decisiondate} double={true} />
                        <DataSheetFormFields label={`Outcome`} data={outcomestatus} double={true} />
                        <DataSheetFormFields label={`Article`} data={article} double={true} />
                        <DataSheetFormFields label={`SubHeading`} data={subheading} double={true} />
                        <DataSheetFormFields label={`Denomination`} data={denomination} double={true} />
                        <DataSheetFormFields label={`Species Name`} data={specieid} double={true} />
                        <DataSheetFormFields label={`Description`} data={description} double={true} />
                    </div>
                }
                footer={<Button icon={'close'} clickAction={this.props.close} variation={'danger'}>{`Close`}</Button>}
            />
        );
    }
}

export default injectIntl(ModalJurisprudenceCaseDataSheet);
