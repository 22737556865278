import React from 'react';
import {injectIntl} from 'react-intl';
import cx from 'classnames';
import S001FormsScreen from './data/001Forms.json';
import S002BackOfficeFormGeneralSettings from './data/002BackOfficeFormGeneralSettings.json';
import S003BackOfficeFormQuestions from './data/003BackOfficeFormQuestions.json';
import S004BackOfficeQuestionsLogics from './data/004BackOfficeQuestionLogics.json';
import S005BackOfficeQuestionsLogics from './data/005BackOfficeResponseLogics.json';
import S006BackOfficeLabels from './data/006BackOfficeLabels.json';
import S007BackOfficeTemplates from './data/007BackOfficeTemplates.json';
import styles from './BackOffice.module.scss';
import {faChevronDown, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {trackPageView} from '../../utils';

const screens = [
    S001FormsScreen,
    S002BackOfficeFormGeneralSettings,
    S003BackOfficeFormQuestions,
    S004BackOfficeQuestionsLogics,
    S005BackOfficeQuestionsLogics,
    S006BackOfficeLabels,
    S007BackOfficeTemplates,
];

class BackOffice extends React.Component {
    iframe = null;

    constructor(props) {
        super(props);
        this.state = {target: '/forms', currentKey: 'OABO.001.FormsSearch-Default'};
    }

    componentDidMount() {
        trackPageView({documentTitle: 'backOffice'});
    }

    setTarget = (name, target, subTarget) =>
        this.setState(
            {target, currentKey: `${name}-Default`, subTarget},
            () =>
                this.iframe.contentWindow.setStateFunction &&
                this.iframe.contentWindow.setStateFunction(
                    Object.assign({}, this.iframe.contentWindow.defaultState, {timestamp: Date.now()})
                )
        );

    setScenarioState = (targetName, target, key, state, modalState, subTarget) => {
        this.setTarget(targetName, target, subTarget);
        this.setState({currentKey: key}, () => {
            this.iframe.contentWindow.setStateFunction &&
                this.iframe.contentWindow.setStateFunction(
                    Object.assign({}, this.iframe.contentWindow.defaultState, state)
                );
            if (this.iframe.contentWindow.setModalStateFunction) {
                this.iframe.contentWindow.setModalStateFunction(
                    Object.assign({}, this.iframe.contentWindow.defaultModalState, modalState)
                );
            }
        });
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.cellMenu}>
                    {screens.map(screen => (
                        <div>
                            <did
                                className={styles.screenIcon}
                                onClick={() => this.setTarget(screen.name, screen.target)}
                            >
                                {screen.scenarios.length > 1 && (
                                    <FontAwesomeIcon
                                        icon={screen.target === this.state.target ? faChevronDown : faChevronRight}
                                    />
                                )}
                            </did>
                            <div
                                className={styles.screenName}
                                onClick={() => this.setTarget(screen.name, screen.target)}
                            >
                                {screen.name}
                            </div>
                            {screen.scenarios && this.state.target === screen.target && (
                                <ul className={styles.scenarios}>
                                    {screen.scenarios.map(
                                        scenario =>
                                            scenario.label !== 'Default' && (
                                                <li
                                                    key={`${screen.name}-${scenario.label}`}
                                                    onClick={() =>
                                                        this.setScenarioState(
                                                            screen.name,
                                                            screen.target,
                                                            `${screen.name}-${scenario.label}`,
                                                            scenario.state,
                                                            scenario.modalState,
                                                            scenario.subTarget
                                                        )
                                                    }
                                                    className={cx(
                                                        styles.scenario,
                                                        this.state.currentKey === `${screen.name}-${scenario.label}` &&
                                                            styles.scenarioActive
                                                    )}
                                                >
                                                    {scenario.label}
                                                </li>
                                            )
                                    )}
                                </ul>
                            )}
                        </div>
                    ))}
                </div>
                <div className={styles.cellIframe}>
                    <iframe
                        ref={iframe => (this.iframe = iframe)}
                        className={styles.iframe}
                        width="100%"
                        height="100%"
                        src={this.state.subTarget || this.state.target}
                    />
                </div>
            </div>
        );
    }
}

export default injectIntl(BackOffice);
