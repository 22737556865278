import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export function apiCommunicationCentreClientSearchSent(
    criteria: any,
    DEFAULT_CRITERIA: any = {},
    excelTranslations?: boolean
) {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/communicationCentreClient/v4/CCCSS/sent/search?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}

export function apiCommunicationCentreClientSentMessageArchive(communicationIds: any) {
    return postFetch(`${API_SERVERLESS}/communicationCentreClient/v4/CCCSMA/sent/messageArchive`, true, {
        communicationIds,
    });
}
