import React from 'react';
import CryptoJS from 'crypto-js';
import styles from './CCModalInternalReplyBOA.module.scss';
import {ModalConfirmVersion2, ModalCustomVersion2} from '../../commonModals';
import {faChevronDown, faChevronRight, faFlag, faPlus} from '@fortawesome/free-solid-svg-icons';
import TextLabelInput from '~components/TextLabelInput';
import Info from '~components/Info';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TextAreaInput from '~components/TextAreaInput';
import {getDecodedJWT, isRegisterIdValid, sanitize} from '~utils';
import CustomTable from '~components/CustomTable';
import getIcon from '~utils/icons';
import {saveAs} from 'file-saver';
import SelectInput from '~components/SelectInput';
import DragAndDrop from '~components/DragAndDrop';
import ModalCommunicationCenterComposeActions from './CCModalInternalReplyBOAActions';
import Error from '~components/Error';
import {
    apiCommunicationCentreModalInternalReplyBOACCList,
    apiCommunicationCentreModalInternalReplyBOAClients,
    apiCommunicationCentreModalInternalReplyBOAGetFileInfo,
    apiDocumentUpload,
} from './CCModalInternalReplyBOAService';
import InputLink from '~components/InputLink';
import BrowserInfo from '~shared/BrowserInfo';
import HeaderLoading from '~shared/HeaderLoading';
import {getMandatoryAsterisk} from '~utils/format';
import jwtDecode from 'jwt-decode';
import {USER_SHORTKEY_TO_CONTACTID} from '../CommunicationCentreInternal/CommunicationCentreInternal.utils';
import ModalApplicationList from '../RequestCertifiedCopies/modals/ModalApplicationList';
import {CC_MAX_MESSAGE_BODY_LENGTH, MAX_UPLOAD_FILE_SIZE} from '../../utils/constants';
import {
    ICCListItem,
    IClientItem,
    ICommunicationMessageInternal,
    ICommunicationMessageInternalSubmitJSONRequest,
    IDecodedJWT,
    IDocument,
    TProfilesDirectoriesSubtype,
} from '../../types';
import _PROFILES_DIRECTORIES_SUBTYPES from '../CommunicationCentreInternal/modals/DATA/PROFILES_DIRECTORIES_SUBTYPES.json';
import Checkbox from '~components/Checkbox';
import {Button} from '../../componentsFormV2';

export const MODAL_COMPOSE_MESSAGE_ORIGIN_SCREENS = {
    ADMIN_PROCEEDINGS: 'adminProceedings',
};

export const COMMUNICATION_TYPES = [
    {id: 'none', value: 'Please select a communication type'},
    {id: 'ApplicationProcedure', value: 'Application procedure'},
    {id: 'Denominations', value: `Denominations`},
    {id: 'Financial', value: 'Financial'},
    {id: 'LegalProceedings', value: `Legal proceedings`},
    {id: 'ApplicationProcedure', value: 'MyPVR Correspondence'},
    {id: 'OtherMatters', value: `Other matters`},
    {id: 'TechnicalExamination', value: 'Technical examination'},
    {id: 'WithdrawalSurrender', value: `Withdrawal/Surrender documents`},
];

const SELECT_LIST_ITEM_ID_NOT_SELECTED = 'none';
const SELECT_LIST_VALUE = {
    DOCMAN_PROFILE_NOT_SELECTED: 'Please select a profile',
    DOCMAN_DIRECTORY_NOT_SELECTED: 'Please select a directory',
    DOCMAN_SUBTYPE_NOT_SELECTED: 'Please select a subtype',
};

const SELECT_LIST_ALL_DOCMAN_PROFILES = [
    {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_PROFILE_NOT_SELECTED},
    {id: 'ACCOUN', value: 'Accountancy'},
    {id: 'BOADOC', value: 'Board of appeal'},
    {id: 'GREEND', value: 'Green Files'},
    {id: 'HR', value: 'Human Resources'},
    {id: 'LEGALD', value: 'Legal'},
    {id: 'PRESIF', value: 'Presidency'},
    {id: 'PURCHA', value: 'Purchase'},
    {id: 'QAUDIT', value: 'Quality Audit'},
    {id: 'TECHMA', value: 'Technical matters'},
    {id: 'THPDOC', value: 'Third parties'},
];

const errorMessages = {
    UNKNOWN: `This is not a valid application number. The value must be in range 19950001 to ${new Date().getFullYear()}9999!`,
    FORBIDDEN: 'This application is not linked to your account',
    DUPLICATE: `Application number already in the list`,
    NOT_RECEIPT: 'The reception of your application has not been finalised yet.',
    ADD: 'Please include the application number using the button + or "Enter" keyboard button.',
};

const CPVO_THIRD_PARTY_ID = '1';

const arrayUniqueByKey = (array: any[], uniqueKey = 'id') => [
    ...new Map(array.map(item => [item[uniqueKey], item])).values(),
];

// const getSelectedThirdPartyIdValue = (client: IClientItem) => {
//     let postFix = 'Requestor';
//     if (client.roles === '1') {
//         postFix = 'Applicant';
//     }
//     if (client.roles === '2') {
//         postFix = 'Representative';
//     }
//     return `${client.companyName} - ${client.thirdPartyId} - (${postFix})`;
// };

const getThirdPartyIdRole = (client: IClientItem) => {
    let postFix = 'Requestor';
    if (client.roles === '1') {
        postFix = 'Applicant';
    }
    if (client.roles === '2') {
        postFix = 'Representative';
    }
    return postFix;
};

const getSelectListOfDocmanProfiles = (decodedJWT: IDecodedJWT): {id: string; value: string}[] => {
    let {id: contactId} = decodedJWT || {};
    let selectList = [
        {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_PROFILE_NOT_SELECTED},
        ...SELECT_LIST_ALL_DOCMAN_PROFILES.filter(el => Object.keys(PROFILES_DIRECTORIES_SUBTYPES).includes(el.id)),
    ];
    if (parseInt(contactId) === USER_SHORTKEY_TO_CONTACTID.FB) {
        selectList = selectList.filter(listOption => listOption.id === 'BOADOC');
    } else {
        selectList = selectList.filter(listOption => listOption.id === 'GREEND');
    }
    return selectList;
};

interface IProps {
    adminProccedingsComposeMessageRequestorName: string;
    adminProccedingsComposeMessageRequestorThirdPartyId: number;
    close: (refresh: boolean) => any;
    communicationId: number | boolean;
    docmanDirectory: string;
    initApplicationNumbers: string[];
    originScreen: string;
    intl: any;
    message?: ICommunicationMessageInternal;
}

const PROFILES_DIRECTORIES_SUBTYPES = _PROFILES_DIRECTORIES_SUBTYPES as TProfilesDirectoriesSubtype;

type TApplicationNumberError = typeof errorMessages;

type TKeyApplicationNumberError = keyof TApplicationNumberError;

interface IState {
    CPVOColleaguesListByIdName: {ID: string; NAME: string}[];
    CPVOColleaguesNameHash: {[key: string]: number[]};
    applicationNumberError: TKeyApplicationNumberError | null;
    checkboxUrgentValue: boolean;
    selectValueProfile: string;
    selectValueDirectory: string;
    selectValueSubtypeOfDocument: string;
    applicationNumber: string;
    selectedApplicationNumbers: {[key: string]: string};
    communicationType: string;
    selectedCPVOColleagues: {[key: string]: string};
    subject: string;
    body: string;
    error: string | null;
    documentList: IDocument[];
    hasAddresseesBeenModified: boolean;
    modalConfirmAddApplicationNumberWithModifiedAddressees: any;
    modalConfirmDeleteApplicationNumberWithModifiedAddressees: any;
    modalImportApplicationNumberList: boolean;
    informationOpened: boolean;
    loading: number;
    selectedThirdPartyId: any;
    thirdPartyId: any;
    selectListDocmanProfiles: {id: string; value: string}[];
    selectListDocmanDirectories: {id: string | number; value: string}[];
    selectListDocmanDocumentSubtypes: {id: string | number; value: string}[];
    clientList: {ID: string; NAME: string}[];
    clientNameHash: {[key: string]: string[]};
    timestamp: number;
}

export default class CCModalInternalReplyBOA extends React.Component<IProps, IState> {
    ccList: ICCListItem[] = [];
    clientResults: IClientItem[] = [];
    decodedJWT: IDecodedJWT = getDecodedJWT();
    inputUploadRef: any = null;

    constructor(props: IProps) {
        super(props);
        this.inputUploadRef = React.createRef();
        const selectedApplicationNumbers = {};
        const authToken = sessionStorage.getItem('token-cc');
        if (authToken) {
            this.decodedJWT = jwtDecode(authToken);
        }
        this.state = {
            CPVOColleaguesListByIdName: [],
            CPVOColleaguesNameHash: {},
            applicationNumberError: null,
            checkboxUrgentValue: false,
            selectValueProfile: SELECT_LIST_ITEM_ID_NOT_SELECTED,
            selectValueDirectory: SELECT_LIST_ITEM_ID_NOT_SELECTED,
            selectValueSubtypeOfDocument: SELECT_LIST_ITEM_ID_NOT_SELECTED,
            applicationNumber: '',
            selectedApplicationNumbers,
            communicationType: 'none',
            selectedCPVOColleagues: {},
            subject: '',
            body: '',
            error: null,
            documentList: [],
            hasAddresseesBeenModified: false,
            modalConfirmAddApplicationNumberWithModifiedAddressees: false,
            modalConfirmDeleteApplicationNumberWithModifiedAddressees: false,
            modalImportApplicationNumberList: false,
            informationOpened: true,
            loading: 0,
            selectedThirdPartyId: {},
            thirdPartyId: '',
            selectListDocmanProfiles: getSelectListOfDocmanProfiles(this.decodedJWT),
            selectListDocmanDirectories: [
                {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_DIRECTORY_NOT_SELECTED},
            ],
            selectListDocmanDocumentSubtypes: [
                {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_SUBTYPE_NOT_SELECTED},
            ],
            clientList: [],
            clientNameHash: {},
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        this.loadJSONs();
        if (this.state.selectListDocmanProfiles?.length === 1) {
            this.onSelectInputProfileChange({target: {value: this.state.selectListDocmanProfiles[0].id}}, () => {
                if (
                    this.props.docmanDirectory &&
                    (this.state.selectListDocmanDirectories || [])
                        .map(el => el.value)
                        .includes(this.props.docmanDirectory)
                ) {
                    this.onSelectInputDirectoryChange({
                        target: {
                            value: `${
                                (
                                    (this.state.selectListDocmanDirectories || []).find(
                                        el => el.value === this.props.docmanDirectory
                                    ) || {}
                                ).id
                            }`,
                        },
                    });
                }
            });
        }
    }

    loadJSONs = () => {
        if (this.decodedJWT.thirdPartyId === CPVO_THIRD_PARTY_ID) {
            Promise.all([this.loadCPVOColleagues()]);
        }
        const selectedApplicationNumbers: {[key: string]: string} = {};
        (this.props.initApplicationNumbers || []).forEach(el => (selectedApplicationNumbers[el] = el));
        this.setState({selectedApplicationNumbers}, () => this.updateClientList(selectedApplicationNumbers));
    };

    loadCPVOColleagues = () =>
        new Promise((resolve, reject) => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiCommunicationCentreModalInternalReplyBOACCList()
                        .then((jsonResponse: {data: ICCListItem[]}) => {
                            if (jsonResponse) {
                                this.ccList = jsonResponse.data;
                                const CPVOColleaguesNameHash: {[key: string]: number[]} = {};
                                (jsonResponse.data || []).forEach(colleagueElement => {
                                    if (!CPVOColleaguesNameHash[colleagueElement.name]) {
                                        CPVOColleaguesNameHash[colleagueElement.name] = [];
                                    }
                                    CPVOColleaguesNameHash[colleagueElement.name].push(colleagueElement.id);
                                });
                                const CPVOColleaguesListByIdName = Object.keys(CPVOColleaguesNameHash).map(
                                    colleagueName => ({
                                        ID: CPVOColleaguesNameHash[colleagueName].join(','),
                                        NAME: colleagueName,
                                    })
                                );
                                const selectedCPVOColleagues = {};
                                this.setState({
                                    CPVOColleaguesListByIdName,
                                    CPVOColleaguesNameHash,
                                    selectedCPVOColleagues,
                                });
                            }
                        })
                        .catch((error: any) => {
                            ERROR([`ModalCommunicationCenterCompose: ${error.message}`]);
                            reject(error && error.message);
                        })
                        .finally(() =>
                            this.setState(
                                prev => ({loading: prev.loading - 1}),
                                () => resolve(true)
                            )
                        );
                }
            );
        });

    confirmSending = (messageStatus: 'draft' | 'sent') => {
        if (this.state.selectValueProfile === SELECT_LIST_ITEM_ID_NOT_SELECTED) {
            this.setState({error: 'Please select a profile to continue'});
            return;
        }
        if (this.state.selectValueDirectory === SELECT_LIST_ITEM_ID_NOT_SELECTED) {
            this.setState({error: 'Please select a directory to continue'});
            return;
        }
        if (this.state.selectValueSubtypeOfDocument === SELECT_LIST_ITEM_ID_NOT_SELECTED) {
            this.setState({error: 'Please select a subtype of document to continue'});
            return;
        }
        if (!Object.keys(this.state.selectedThirdPartyId || {}).length) {
            this.setState({error: 'Addressees cannot be empty'});
            return;
        }
        if (Object.keys(this.state.selectedApplicationNumbers).length === 0) {
            this.setState({error: 'No application number linked'});
            return;
        }
        if (!this.state.subject) {
            this.setState({error: 'Subject cannot be empty'});
            return;
        }
        if (this.state.body.length > 4000) {
            this.setState({error: CC_MAX_MESSAGE_BODY_LENGTH});
            return;
        }
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                const {selectedApplicationNumbers, subject, body, documentList} = this.state;
                const JSONRequest: ICommunicationMessageInternalSubmitJSONRequest = {
                    CPVOColleagues: [],
                    addressees: [],
                    applicationNumbers: Object.keys(selectedApplicationNumbers),
                    messageBody: body,
                    docmanProfile: this.state.selectValueProfile,
                    docmanDirectory: this.state.selectValueDirectory,
                    docmanSubtypeOfDocument: this.state.selectValueSubtypeOfDocument,
                    draft: messageStatus === 'draft',
                    messageSubject: subject,
                    numberOfAttachments: (documentList || []).length,
                    urgent: this.state.checkboxUrgentValue,
                };
                Object.keys(this.state.selectedThirdPartyId || {})
                    .map(el => parseInt(el))
                    .forEach(thirdPartyId => {
                        JSONRequest.addressees.push({
                            thirdPartyId,
                            role:
                                (
                                    (this.clientResults || []).find(
                                        clientResult => clientResult['thirdPartyId'] === thirdPartyId
                                    ) || {}
                                ).roles || null,
                        });
                    });
                Object.keys(this.state.selectedCPVOColleagues || {})
                    .map(el => parseInt(el))
                    .forEach(thirdPartyId => {
                        JSONRequest.CPVOColleagues.push({
                            contactId: thirdPartyId,
                            role:
                                (this.ccList.find(ccListResult => ccListResult['id'] === thirdPartyId) || {}).role ||
                                null,
                        });
                    });
                apiDocumentUpload(JSONRequest, documentList)
                    .then(({communicationId}: {communicationId: number}) => {
                        if (communicationId) {
                            this.setState(
                                prev => ({loading: prev.loading - 1}),
                                () => {
                                    this.props.close(false);
                                }
                            );
                        } else {
                            this.setState(prev => ({loading: prev.loading - 1, error: '404 - Document not found'}));
                        }
                    })
                    .catch(this.close);
            }
        );
    };

    close = (refresh: boolean) => this.props.close && this.props.close(refresh);

    closeError = () => this.setState({error: null});

    handleDrop = (files: FileList, event: any) => this.setState({error: null}, () => this.checkFile(files[0], event));

    checkFile = (file: File, event: React.ChangeEvent<HTMLInputElement>) => {
        LOG([`file.type:${file.type}`]);
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType?.toLowerCase();
        const filename = sanitize(file.name);
        this.setState({error: null}, () => {
            if (
                fileTypeLowercase === 'jpeg' ||
                fileTypeLowercase === 'png' ||
                fileTypeLowercase === 'jpg' ||
                fileTypeLowercase === 'pdf'
            ) {
                if (file.size > MAX_UPLOAD_FILE_SIZE) {
                    this.setState({
                        error: 'The maximum document size for uploads is 10 MB. Please select a proper file.',
                    });
                } else {
                    const a = new FileReader();
                    a.readAsBinaryString(file);
                    a.onloadend = () => {
                        const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(a.result));
                        this.setState(
                            prev => {
                                const documentList = prev.documentList.slice(0);
                                documentList.push({
                                    index,
                                    file,
                                    filename,
                                    size: file.size / 1024,
                                    extension: fileTypeLowercase,
                                    documentType: null,
                                });
                                return {documentList, timestamp: Date.now(), informationOpened: false};
                            },
                            () => (event.target.value = '')
                        );
                    };
                }
            } else {
                this.setState({error: 'type not valid'}, () => (event.target.value = ''));
            }
        });
    };

    deleteFile = (index: any) =>
        this.setState(prev => {
            return {
                documentList: prev.documentList.filter(i => i.index !== index),
                timestamp: Date.now(),
                informationOpened: prev.documentList.length === 1,
            };
        });

    toggleInformation = () => this.setState(prev => ({informationOpened: !prev.informationOpened}));

    checkApplicationNumber = (applicationNumber: string) => {
        let applicationNumberValue = applicationNumber;
        if (!isNaN(Number(applicationNumberValue))) {
            applicationNumberValue = applicationNumberValue.substring(0, 8);
            this.setState({
                applicationNumber: applicationNumberValue,
                applicationNumberError: isRegisterIdValid(applicationNumberValue)
                    ? applicationNumberValue.length === 8
                        ? 'ADD'
                        : null
                    : applicationNumber !== ''
                    ? 'UNKNOWN'
                    : null,
            });
        }
    };

    /*
     * Requestor object: { 2690: "Marie-Anne Haaring-Schepman - 2690 - (Requestor)" }
     *
     *
     * */
    // this.initSelectedThirdPartyId = {
    //     [String(props.adminProccedingsComposeMessageRequestorThirdPartyId)]: requestorObject,
    // };
    // this.initClientList = [
    //     {
    //         ID: String(props.adminProccedingsComposeMessageRequestorThirdPartyId),
    //         NAME: requestorObject,
    //     },
    // ];
    // this.initClientNameHash = {
    //     [requestorObject]: [props.adminProccedingsComposeMessageRequestorThirdPartyId],
    // };
    updateClientList = (selectedApplicationNumbers: {[key: string]: string}) =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                let promises: any[] = [];
                const hashApplicationNumbers: any = {};
                const hashThirdPartyId: any = {};
                let nextClientList: {ID: string; NAME: string}[] = [];
                let nextClientNameHash: {[key: string]: string[]} = {};
                this.clientResults = [];
                Object.keys(selectedApplicationNumbers).forEach(applicationNumber => {
                    promises.push(
                        apiCommunicationCentreModalInternalReplyBOAClients({
                            applicationNumbers: [applicationNumber],
                        })
                    );
                    hashApplicationNumbers[applicationNumber] = [];
                });
                Promise.all(promises)
                    .then(results => {
                        results.forEach((resultJSON, i) => {
                            let applicationNumber = Object.keys(selectedApplicationNumbers)[i];
                            resultJSON.data.forEach((client: any) => {
                                this.clientResults.push(client);
                                const thirdPartyIdRole = getThirdPartyIdRole(client);
                                hashApplicationNumbers[applicationNumber].push({
                                    thirdPartyId: client.thirdPartyId,
                                    thirdPartyIdRole,
                                });
                                if (!hashThirdPartyId[client.thirdPartyId]) {
                                    hashThirdPartyId[client.thirdPartyId] = [];
                                }
                                hashThirdPartyId[client.thirdPartyId].push({
                                    applicationNumber,
                                    thirdPartyIdRole,
                                    companyName: client.companyName,
                                });
                            });
                        });
                        Object.keys(hashThirdPartyId).forEach(thirdPartyId => {
                            const tmpDictionary: any = {};
                            const isRequestor =
                                parseInt(thirdPartyId) ===
                                parseInt(String(this.props.adminProccedingsComposeMessageRequestorThirdPartyId));
                            if (isRequestor) {
                                tmpDictionary['Requestor'] = true;
                            }
                            hashThirdPartyId[thirdPartyId].forEach(
                                (el: any) => (tmpDictionary[el.thirdPartyIdRole] = true)
                            );
                            let labelRole = Object.keys(tmpDictionary).join(' / ');
                            let label = '';
                            // `${client.companyName} - ${client.thirdPartyId} - (${postFix})`
                            if (isRequestor) {
                                label = `${this.props.adminProccedingsComposeMessageRequestorName} - ${thirdPartyId} - (${labelRole})`;
                            } else {
                                label = `${hashThirdPartyId[thirdPartyId][0].companyName} - ${thirdPartyId} - (${labelRole})`;
                            }
                            nextClientList.push({
                                ID: thirdPartyId,
                                NAME: label,
                            });
                            nextClientNameHash[label] = [thirdPartyId];
                        });
                        let nextSelectedThirdPartyIds: any = {};
                        Object.keys(hashApplicationNumbers).forEach(applicationNumber => {
                            let representative = hashApplicationNumbers[applicationNumber].find(
                                (el: any) => el.thirdPartyIdRole === 'Representative'
                            );
                            if (representative) {
                                let client: any = nextClientList.find(
                                    el => parseInt(el.ID) === parseInt(representative.thirdPartyId)
                                );
                                nextSelectedThirdPartyIds[representative.thirdPartyId] = client.NAME;
                            } else {
                                hashApplicationNumbers[applicationNumber].forEach((applicant: any) => {
                                    let client: any = nextClientList.find(
                                        el => parseInt(el.ID) === parseInt(applicant.thirdPartyId)
                                    );
                                    nextSelectedThirdPartyIds[applicant.thirdPartyId] = client.NAME;
                                });
                            }
                        });
                        if (
                            !Object.keys(nextSelectedThirdPartyIds).includes(
                                String(this.props.adminProccedingsComposeMessageRequestorThirdPartyId)
                            ) &&
                            !Object.keys(nextClientNameHash).some(el => el.includes('Requestor'))
                        ) {
                            let requestorId = `${this.props.adminProccedingsComposeMessageRequestorThirdPartyId}`;
                            let requestorName = `${this.props.adminProccedingsComposeMessageRequestorName} - ${this.props.adminProccedingsComposeMessageRequestorThirdPartyId} - (Requestor)`;
                            nextClientList.push({
                                ID: requestorId,
                                NAME: requestorName,
                            });
                            nextClientNameHash[requestorName] = [requestorId];
                            nextSelectedThirdPartyIds[requestorId] = requestorName;
                        }
                        this.setState(prev => ({
                            clientList: nextClientList,
                            clientNameHash: nextClientNameHash,
                            selectedThirdPartyId: nextSelectedThirdPartyIds,
                        }));
                    })
                    .then(() => {
                        this.setState(prev => ({
                            loading: prev.loading - 1,
                        }));
                    });
            }
        );

    getLabelOfCC = () => {
        let {id: contactId} = this.decodedJWT || {};
        if (parseInt(contactId) === USER_SHORTKEY_TO_CONTACTID.FB) {
            return 'CPVO Colleagues / BOA members / EU agencies BOA members';
        }
        return 'CPVO Colleagues';
    };

    closeModalConfirmDeleteApplicationNumberWithModifiedAddressees = () =>
        this.setState({modalConfirmDeleteApplicationNumberWithModifiedAddressees: false});

    closeModalConfirmAddApplicationNumberWithModifiedAddressees = () =>
        this.setState({modalConfirmAddApplicationNumberWithModifiedAddressees: false});

    closeModalImportApplicationNumbersList = () => this.setState({modalImportApplicationNumberList: false});

    onSelectInputProfileChange = (
        {target: {value: selectValueProfile}}: React.ChangeEvent<HTMLSelectElement> | {target: {value: string}},
        callback: (selectListDocmanDirectories: {id: string | number; value: string}[]) => void
    ) => {
        if (selectValueProfile === SELECT_LIST_ITEM_ID_NOT_SELECTED) {
            this.setState({
                selectValueProfile: SELECT_LIST_ITEM_ID_NOT_SELECTED,
                selectValueDirectory: SELECT_LIST_ITEM_ID_NOT_SELECTED,
                selectValueSubtypeOfDocument: SELECT_LIST_ITEM_ID_NOT_SELECTED,
            });
        } else {
            this.setState(
                () => {
                    let selectListDocmanDirectories: {id: string | number; value: string}[] = [
                        {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_DIRECTORY_NOT_SELECTED},
                        ...arrayUniqueByKey(
                            PROFILES_DIRECTORIES_SUBTYPES[selectValueProfile].map(el => ({
                                id: el.dirId,
                                value: el.dirName,
                            }))
                        ),
                    ];
                    return {selectValueProfile, selectListDocmanDirectories};
                },
                () => callback && callback(this.state.selectListDocmanDirectories)
            );
        }
    };

    onSelectInputDirectoryChange = ({
        target: {value: selectValueDirectory},
    }: React.ChangeEvent<HTMLSelectElement> | {target: {value: string}}) => {
        this.setState(prev => {
            let selectListDocmanDocumentSubtypes = [
                {id: SELECT_LIST_ITEM_ID_NOT_SELECTED, value: SELECT_LIST_VALUE.DOCMAN_SUBTYPE_NOT_SELECTED},
                ...PROFILES_DIRECTORIES_SUBTYPES[prev.selectValueProfile]
                    .filter(directoryItem => directoryItem.dirId === parseInt(selectValueDirectory))
                    .map(el => ({
                        id: el.docTypeId,
                        value: el.docTypeName,
                    })),
            ];
            return {selectValueDirectory, selectListDocmanDocumentSubtypes};
        });
    };

    onSelectInputSubtypeOfDocumentChange = ({
        target: {value: selectValueSubtypeOfDocument},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({selectValueSubtypeOfDocument});

    onInputLinkImportApplicationNumberListClick = () => this.setState({modalImportApplicationNumberList: true});

    onImportApplicationNumbersList = (matches: string[], forceUpdate = false) => {
        if (this.state.hasAddresseesBeenModified && !forceUpdate) {
            this.setState({modalConfirmAddApplicationNumberWithModifiedAddressees: matches});
        } else {
            this.setState(
                prev => {
                    const currentSelectedApplicationNumbers = {...prev.selectedApplicationNumbers};
                    matches.forEach(el => (currentSelectedApplicationNumbers[el] = el));
                    return {selectedApplicationNumbers: currentSelectedApplicationNumbers};
                },
                () => this.updateClientList(this.state.selectedApplicationNumbers)
            );
        }
    };

    onApplicationNumberChange = (applicationNumber: string) => this.checkApplicationNumber(applicationNumber);

    onApplicationNumbersSelectionChangeCheck = (selectedApplicationNumbers: {[key: string]: string}) => {
        if (this.state.hasAddresseesBeenModified) {
            this.setState({modalConfirmDeleteApplicationNumberWithModifiedAddressees: selectedApplicationNumbers});
        } else {
            this.onApplicationNumbersSelectionChange(selectedApplicationNumbers);
        }
    };

    onApplicationNumbersSelectionChange = (selectedApplicationNumbers: {[key: string]: string}): void => {
        this.setState(
            () => {
                let optionalState: any = {};
                if (Object.keys(selectedApplicationNumbers).length === 0) {
                    optionalState.selectedThirdPartyId = {};
                }
                return {
                    selectedApplicationNumbers,
                    ...optionalState,
                };
            },
            () =>
                Object.keys(selectedApplicationNumbers).length !== 0 &&
                this.updateClientList(selectedApplicationNumbers)
        );
    };

    onAddresseesIdChange = (thirdPartyId: string, callback?: () => any) => {
        if (thirdPartyId) {
            const selectedThirdPartyId: {[key: string]: string} = {};
            this.state.clientList
                .filter(i => i.ID === thirdPartyId)
                .forEach(contact => (selectedThirdPartyId[contact.ID] = contact.NAME));
            this.setState(
                {
                    thirdPartyId,
                    selectedThirdPartyId,
                },
                callback
            );
        }
    };

    onThirdPartyUserSelectionChange = (selectedThirdPartyId: {[key: string]: string}) => {
        let optionalState: any = {};
        if (Object.keys(selectedThirdPartyId).length === 0) {
            optionalState.thirdPartyId = null;
        }
        return {
            selectedThirdPartyId,
            hasAddresseesBeenModified: true,
            ...optionalState,
        };
    };

    onAddingApplicationNumberCheck = () => {
        if (this.state.hasAddresseesBeenModified) {
            this.setState({modalConfirmAddApplicationNumberWithModifiedAddressees: true});
        } else {
            this.onAddingApplicationNumber();
        }
    };

    onAddingApplicationNumber = () => {
        const {applicationNumber} = this.state;
        if (applicationNumber.length === 8 && isRegisterIdValid(applicationNumber)) {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    (this.decodedJWT.thirdPartyId === CPVO_THIRD_PARTY_ID
                        ? Promise.resolve({message: 'OK'})
                        : apiCommunicationCentreModalInternalReplyBOAGetFileInfo(applicationNumber)
                    )
                        .then((result: any) => {
                            if (result.message !== 'OK') {
                                this.setState({applicationNumberError: 'FORBIDDEN'});
                            } else {
                                this.setState(
                                    prev => {
                                        const selectedApplicationNumbers = prev.selectedApplicationNumbers;
                                        selectedApplicationNumbers[prev.applicationNumber] = prev.applicationNumber;
                                        return {
                                            applicationNumber: '',
                                            applicationNumberError: null,
                                            selectedApplicationNumbers,
                                        };
                                    },
                                    () => this.updateClientList(this.state.selectedApplicationNumbers)
                                );
                            }
                        })
                        .then(() => this.setState(prev => ({loading: prev.loading - 1})));
                }
            );
        }
    };

    onCPVOColleaguesSectionChange = (selectedCPVOColleagues: {[key: string]: string}): void =>
        this.setState({selectedCPVOColleagues});

    onSubjectChange = ({target: {value: subject}}: React.ChangeEvent<HTMLTextAreaElement>): void =>
        this.setState({subject});

    onBodyChange = ({target: {value: body}}: React.ChangeEvent<HTMLTextAreaElement>): void => this.setState({body});

    onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];
            this.checkFile(file, event);
        }
    };

    onButtonConfirmAddApplicationNumberWithModifiedAddressees = () => {
        const matches = this.state.modalConfirmAddApplicationNumberWithModifiedAddressees;
        this.setState(
            {
                modalConfirmAddApplicationNumberWithModifiedAddressees: false,
                hasAddresseesBeenModified: false,
            },
            () => {
                if (Array.isArray(matches)) {
                    this.onImportApplicationNumbersList(matches, true);
                } else {
                    this.onAddingApplicationNumber();
                }
            }
        );
    };

    onButtonConfirmDeleteApplicationNumberWithModifiedAddressees = () => {
        const updatedApplicationNumbers = this.state.modalConfirmDeleteApplicationNumberWithModifiedAddressees;
        this.setState(
            {
                modalConfirmDeleteApplicationNumberWithModifiedAddressees: false,
                hasAddresseesBeenModified: false,
            },
            () => {
                this.onApplicationNumbersSelectionChange(updatedApplicationNumbers);
            }
        );
    };

    onCheckboxUrgentClick = () => this.setState(prev => ({checkboxUrgentValue: !prev.checkboxUrgentValue}));

    render() {
        const actions = ModalCommunicationCenterComposeActions(this.deleteFile);

        return (
            <>
                {this.state.modalImportApplicationNumberList ? (
                    <ModalApplicationList
                        importList={this.onImportApplicationNumbersList}
                        close={this.closeModalImportApplicationNumbersList}
                    />
                ) : null}
                {this.state.modalConfirmAddApplicationNumberWithModifiedAddressees ? (
                    <ModalConfirmVersion2
                        title={`Reset addressees`}
                        message={`If you add an application number any modifications of addressees will be lost. Are you sure you want to continue ?`}
                        buttonName={'Yes'}
                        action={this.onButtonConfirmAddApplicationNumberWithModifiedAddressees}
                        close={this.closeModalConfirmAddApplicationNumberWithModifiedAddressees}
                    />
                ) : null}
                {this.state.modalConfirmDeleteApplicationNumberWithModifiedAddressees ? (
                    <ModalConfirmVersion2
                        title={`Reset addressees`}
                        message={`If you delete an application number any modifications of addressees will be lost. Are you sure you want to continue ?`}
                        buttonName={'Yes'}
                        action={this.onButtonConfirmDeleteApplicationNumberWithModifiedAddressees}
                        close={this.closeModalConfirmDeleteApplicationNumberWithModifiedAddressees}
                    />
                ) : null}
                <DragAndDrop handleDrop={this.handleDrop}>
                    <ModalCustomVersion2
                        close={this.close}
                        header={'Compose a new message'}
                        body={
                            <>
                                <>
                                    {this.decodedJWT.thirdPartyId === CPVO_THIRD_PARTY_ID && (
                                        <>
                                            <SelectInput
                                                label={`Profile`}
                                                value={this.state.selectValueProfile}
                                                onChange={this.onSelectInputProfileChange}
                                                list={this.state.selectListDocmanProfiles || []}
                                                notAll={true}
                                                triple={true}
                                                noIcon={true}
                                                mandatory={true}
                                                disabled={this.state.selectListDocmanProfiles?.length === 1}
                                            />
                                            <SelectInput
                                                label={`Directory`}
                                                value={this.state.selectValueDirectory}
                                                onChange={this.onSelectInputDirectoryChange}
                                                list={this.state.selectListDocmanDirectories || []}
                                                notAll={true}
                                                triple={true}
                                                noIcon={true}
                                                mandatory={true}
                                                hideOptionNone={true}
                                            />
                                            <SelectInput
                                                label={`Subtype of document`}
                                                value={this.state.selectValueSubtypeOfDocument}
                                                onChange={this.onSelectInputSubtypeOfDocumentChange}
                                                list={this.state.selectListDocmanDocumentSubtypes || []}
                                                notAll={true}
                                                triple={true}
                                                noIcon={true}
                                                mandatory={true}
                                            />
                                        </>
                                    )}
                                    <div style={{clear: 'both'}}>{}</div>
                                    {this.state.documentList.length > 0 && (
                                        <CustomTable
                                            version={2}
                                            {...this.props}
                                            notSortable={['filename', 'size']}
                                            tableName={'documentUpload'}
                                            tableType={'OBJECT'}
                                            tableSource={this.state.documentList || []}
                                            dataFilter={null}
                                            id={'index'}
                                            setLastCursor={null}
                                            resultFieldsDefault={['filename', 'size']}
                                            intl={this.props.intl}
                                            formatFunctions={{
                                                size: (size: number) => `${Math.floor(size * 100) / 100} KB`,
                                                extension: (extension: string) => (
                                                    <img src={getIcon(extension || 'pdf')} alt={extension} />
                                                ),
                                            }}
                                            count={(this.state.documentList || []).length}
                                            hideExcelButton={true}
                                            noChangePageSize={true}
                                            forehandColumn={(row: IDocument) => (
                                                <img
                                                    style={{width: 20, height: 20, cursor: 'pointer'}}
                                                    alt={'Download'}
                                                    src={getIcon(row.extension || 'pdf')}
                                                    onClick={event => {
                                                        event.stopPropagation();
                                                        saveAs(row.file, row.filename);
                                                    }}
                                                />
                                            )}
                                            timestamp={this.state.timestamp}
                                            actions={actions}
                                        />
                                    )}
                                </>
                                <TextLabelInput
                                    onSelectionChange={this.onApplicationNumbersSelectionChangeCheck}
                                    onChange={this.onApplicationNumberChange}
                                    onEnter={this.onAddingApplicationNumberCheck}
                                    value={this.state.applicationNumber}
                                    selectedElements={this.state.selectedApplicationNumbers}
                                    delay={300}
                                    multiple={true}
                                    label={getMandatoryAsterisk(
                                        'To which application number(s) the communication is linked'
                                    )}
                                    buttonAction={this.onAddingApplicationNumberCheck}
                                    buttonIcon={faPlus}
                                    buttonDisabled={
                                        (this.state.applicationNumberError &&
                                            this.state.applicationNumberError !== 'ADD') ||
                                        this.state.applicationNumber.length !== 8
                                    }
                                    triple={true}
                                />
                                {this.state.applicationNumberError && (
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            width: 700,
                                            marginLeft: 40,
                                            marginBottom: 20,
                                        }}
                                    >
                                        <Info>
                                            <div style={{display: 'inline-block', marginRight: 5}}>
                                                <FontAwesomeIcon icon={faFlag as any} color={'#8a6d3b'} />
                                            </div>
                                            <div style={{display: 'inline-block'}}>
                                                {errorMessages[this.state.applicationNumberError]}
                                            </div>
                                        </Info>
                                    </div>
                                )}
                                <div
                                    style={{
                                        float: 'left',
                                        marginTop: -11,
                                        paddingBottom: 7,
                                    }}
                                >
                                    <InputLink
                                        label={`Import application number list`}
                                        clickAction={this.onInputLinkImportApplicationNumberListClick}
                                        width={280}
                                    />
                                </div>
                                {this.decodedJWT.thirdPartyId === CPVO_THIRD_PARTY_ID && (
                                    <>
                                        <TextLabelInput
                                            onIdChange={this.onAddresseesIdChange}
                                            onSelectionChange={this.onThirdPartyUserSelectionChange}
                                            selectedElements={this.state.selectedThirdPartyId}
                                            delay={300}
                                            multiple={true}
                                            label={getMandatoryAsterisk('Addressees')}
                                            placeholder={`Please click on input and select an addressee`}
                                            triple={true}
                                            hideRemoveElementButtonForId={
                                                this.props.adminProccedingsComposeMessageRequestorThirdPartyId
                                            }
                                            listByIdName={this.state.clientList}
                                            nameHash={this.state.clientNameHash}
                                            uniqueValue={false}
                                            autoSelection={true}
                                            filterContains={true}
                                            clickable={true}
                                        />
                                        <TextLabelInput
                                            clickableAndEditable={true}
                                            double={true}
                                            onSelectionChange={this.onCPVOColleaguesSectionChange}
                                            selectedElements={this.state.selectedCPVOColleagues}
                                            delay={300}
                                            multiple={true}
                                            label={this.getLabelOfCC()}
                                            placeholder={`Please type part of the colleague name`}
                                            triple={true}
                                            listByIdName={this.state.CPVOColleaguesListByIdName}
                                            nameHash={this.state.CPVOColleaguesNameHash}
                                            filterContains
                                        />
                                    </>
                                )}
                                <TextAreaInput
                                    label={getMandatoryAsterisk('Subject')}
                                    value={this.state.subject}
                                    onChange={this.onSubjectChange}
                                    rows={3}
                                    triple={true}
                                    noIcon={true}
                                />
                                <TextAreaInput
                                    label={getMandatoryAsterisk('Body')}
                                    value={this.state.body}
                                    onChange={this.onBodyChange}
                                    rows={5}
                                    triple={true}
                                    noIcon={true}
                                />
                                <div style={{marginLeft: 7, marginBottom: 10}}>
                                    {`Characters count: `}
                                    <span
                                        style={{
                                            color: this.state.body.length > 4000 ? 'red' : 'green',
                                        }}
                                    >{`${this.state.body.length} / 4000`}</span>
                                </div>
                                <div>
                                    <Checkbox
                                        clickAction={this.onCheckboxUrgentClick}
                                        simple={true}
                                        label={`Urgent`}
                                        value={this.state.checkboxUrgentValue}
                                        width={500}
                                    />
                                </div>
                                <div style={{clear: 'both'}} />
                                <div>
                                    <InputLink
                                        label={`Notes`}
                                        icon={this.state.informationOpened ? faChevronDown : faChevronRight}
                                        clickAction={this.toggleInformation}
                                    />
                                    <div style={{clear: 'both'}} />
                                    {this.state.informationOpened && <BrowserInfo intl={this.props.intl} />}
                                </div>
                                <div style={{clear: 'both'}} />
                            </>
                        }
                        footer={
                            <div className={styles.footerWrap}>
                                {this.state.error ? <Error>{this.state.error}</Error> : null}
                                <div className={styles.footerLeft}>
                                    <label htmlFor={'upload'}>
                                        <input
                                            type="file"
                                            name="files[]"
                                            id="upload"
                                            style={{opacity: 0, width: 0}}
                                            onChange={this.onFileUpload}
                                            accept="image/jpg, image/jpeg, image/png, application/pdf"
                                            ref={this.inputUploadRef}
                                        />
                                        <Button
                                            variation={'secondary'}
                                            clickAction={() => this.inputUploadRef.current.click()}
                                        >{`Add document`}</Button>
                                    </label>
                                </div>
                                <div className={styles.footerRight}>
                                    <Button clickAction={this.close} icon={'close'} variation={'danger'}>
                                        {`Cancel`}
                                    </Button>
                                    <Button
                                        clickAction={() => this.confirmSending('sent')}
                                        icon={'arrowRight'}
                                    >{`Send`}</Button>
                                </div>
                            </div>
                        }
                    />
                    {(this.state.loading !== 0 && <HeaderLoading />) || null}
                </DragAndDrop>
            </>
        );
    }
}
