import React, {useEffect, useState} from 'react';
import getIcon from '~utils/icons';
import {saveAs} from 'file-saver';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {formatDateTimeEasy} from '~components/FormatFunctions';
import CustomTable from '~components/CustomTable';
import styles from './InternalMessageFieldsAndAttachments.module.scss';
import {apiCommunicationCenterDownloadDocument} from './InternalMessageFieldsAndAttachmentsService';
import {getPreSignedURLFetchRequest} from '../../../utils/requests';
import {ICommunicationAttachmentResponse, ICommunicationMessageInternalRead, IDocument} from '../../../types';
import PDFMerger from 'pdf-merger-js/browser';
import {jsPDF} from 'jspdf';
import {utilPromiseBlobToBase64} from '../../../utils/files';
import {communicationTypeHash} from '../CommunicationCentreInternal.constants';
import DataSheetFormSection from '~shared/DataSheetFormSection';

const findAttachmentServerIndexByFilename = (filename: string, attachments: ICommunicationAttachmentResponse[]) => {
    return attachments.find(el => el.filename === filename)?.serverIndex || '';
};

interface InternalMessageFieldsAndAttachmentsProps {
    attachments: ICommunicationAttachmentResponse[];
    communicationId: number;
    intl: any;
    message: ICommunicationMessageInternalRead | null;
    openModalApplicationNumbers: (applicationNumbers: string[]) => void;
    isPreviewMode?: boolean;
    setModalPreviewURL?: any;
    modalPreviewURL?: string;
}

export default function InternalMessageFieldsAndAttachments({
    attachments,
    communicationId,
    intl,
    message,
    openModalApplicationNumbers,
    isPreviewMode,
    setModalPreviewURL,
    modalPreviewURL,
}: InternalMessageFieldsAndAttachmentsProps) {
    const {addressees, applicationNumbers, CPVOColleagues, from, messageBody, messageSubject, sentDate} = message || {};
    const [timestamp, setTimestamp] = useState(Date.now());

    useEffect(() => {
        if (isPreviewMode && !modalPreviewURL && attachments.length) {
            onTableAttachmentsSelectedChange(Object.keys(getSelectDefaultAttachmentsNames())).catch((error: any) =>
                LOG([error.message])
            );
        }
        setTimestamp(Date.now());
    }, [isPreviewMode, message]);

    const downloadFile = ({serverIndex}: IDocument) =>
        apiCommunicationCenterDownloadDocument({communicationId, serverIndex}).then(
            (response: {signedUrl: string; filename: string}) => {
                if (response && response.signedUrl && response.filename) {
                    const {signedUrl, filename} = response;
                    getPreSignedURLFetchRequest(signedUrl)
                        .then(response => response.blob())
                        .then(responseBlob => {
                            saveAs(responseBlob, filename);
                        });
                }
            }
        );

    const onTableAttachmentsSelectedChange = async (selectedFilenames: string[]) => {
        setModalPreviewURL && setModalPreviewURL('');
        let selectedServerIndexes = selectedFilenames.map((filename: string) =>
            findAttachmentServerIndexByFilename(filename, attachments)
        );
        const promisesSingedURLS: any[] = [];
        selectedServerIndexes.forEach(serverIndex => {
            promisesSingedURLS.push(apiCommunicationCenterDownloadDocument({communicationId, serverIndex}));
        });
        const signeURLs = await Promise.all(promisesSingedURLS);
        const promisesFiles = signeURLs.map(({signedUrl}: any) => getPreSignedURLFetchRequest(signedUrl));
        const filesBlobs = await Promise.all(promisesFiles);
        const files = await Promise.all(filesBlobs.map(fileBlob => fileBlob.blob()));
        let i = 0;
        for await (let file of files) {
            if (file.type.includes('image')) {
                var doc = new jsPDF();
                let b: any = await utilPromiseBlobToBase64(file);
                doc.addImage(b, 'JPEG', 15, 40, 180, 160);
                files[i] = doc.output('blob');
            }
            i += 1;
        }
        const merger = new PDFMerger();
        const mergerPromises = files.map(blob => merger.add(blob));
        await Promise.all(mergerPromises);
        let mergedPDF = await merger.saveAsBlob();
        let mergedPDFURL = URL.createObjectURL(mergedPDF);
        setModalPreviewURL && setModalPreviewURL(mergedPDFURL);
    };

    const getSelectDefaultAttachmentsNames = (): {[key: string]: boolean} => {
        const selectedDefault: {[key: string]: boolean} = {};
        attachments.forEach(el => (selectedDefault[el.filename] = true));
        return selectedDefault;
    };

    const originalCommunicationType = message ? communicationTypeHash[message.communicationType] : '';

    return (
        <div>
            <DataSheetFormFields label={'From'} data={from} double={true} loading={!from} />
            <DataSheetFormFields
                label={'To'}
                data={(addressees || []).map(el => el.NAME).join(', ')}
                double={true}
                loading={!addressees}
            />
            <DataSheetFormFields
                label={'Sent on'}
                data={formatDateTimeEasy(sentDate)}
                double={true}
                loading={!sentDate}
            />
            <DataSheetFormFields
                label={'Original communication type'}
                data={originalCommunicationType}
                double={true}
                loading={!message}
            />
            <DataSheetFormFields
                label={'Application numbers'}
                data={
                    <div
                        onClick={() =>
                            applicationNumbers && applicationNumbers.length > 3
                                ? openModalApplicationNumbers && openModalApplicationNumbers(applicationNumbers)
                                : null
                        }
                        style={(applicationNumbers || []).length > 3 ? {cursor: 'pointer'} : {}}
                    >
                        {(applicationNumbers || []).slice(0, 3).map(applicationNumber => (
                            <span key={applicationNumber} className={styles.applicationNumber}>
                                {applicationNumber}
                            </span>
                        ))}
                        {(applicationNumbers || []).length > 3 ? (
                            <span className={styles.applicationNumber}>{`...`}</span>
                        ) : null}
                    </div>
                }
                loading={!applicationNumbers}
                ready={true}
                double={true}
            />
            <DataSheetFormFields
                label={'CPVO Colleagues'}
                data={<>{(CPVOColleagues || []).map(el => el.NAME).join(', ')}</>}
                loading={!CPVOColleagues}
                ready={true}
                double={true}
            />
            <DataSheetFormFields
                data={messageSubject}
                double={true}
                label={'Subject'}
                loading={!messageSubject}
                ready={true}
            />
            <div style={{clear: 'both'}} />
            <div style={{marginLeft: -30}}>
                <DataSheetFormSection title={'Body'} disableToggle={true} />
            </div>
            <div style={{clear: 'both'}} />
            {messageBody ? (
                message && message.thirdPartyId === 1 ? (
                    <div
                        style={{fontWeight: 'normal'}}
                        /* eslint-disable-next-line react/no-danger */
                        dangerouslySetInnerHTML={{__html: messageBody || ''}}
                    />
                ) : (
                    <div style={{fontWeight: 'normal'}}>
                        <pre style={{margin: 0, whiteSpace: 'pre-wrap', fontFamily: 'inherit'}}>{messageBody}</pre>
                    </div>
                )
            ) : null}
            {messageBody && (message?.signatureFullName || message?.signatureRole || message?.signatureUnit) ? (
                <div style={{marginTop: 7, marginBottom: 20, color: 'navy', fontSize: 14}}>
                    {message?.signatureFullName ? (
                        <div style={{fontWeight: 'bold', fontSize: 16}}>{message.signatureFullName}</div>
                    ) : null}
                    {message?.signatureRole && message?.signatureUnit ? (
                        <div>
                            <span>{message.signatureRole}</span>
                            <span> / {message.signatureUnit}</span>
                        </div>
                    ) : null}
                </div>
            ) : null}
            <div style={{clear: 'both'}} />
            <div>
                {(attachments || []).length ? (
                    <CustomTable
                        version={1}
                        notSortable={['filename']}
                        tableName={'fileLogs'}
                        tableType={'OBJECT'}
                        tableSource={attachments || []}
                        dataFilter={null}
                        id={'filename'}
                        setLastCursor={null}
                        resultFieldsDefault={['filename']}
                        intl={intl}
                        count={(attachments || []).length}
                        hideExcelButton={true}
                        noChangePageSize={true}
                        forehandColumn={(rowObject: IDocument) => (
                            <img
                                style={{width: 20, height: 20, cursor: 'pointer'}}
                                alt={'Download'}
                                src={getIcon(rowObject.filename || 'pdf')}
                                onClick={event => {
                                    event.stopPropagation();
                                    downloadFile(rowObject);
                                }}
                            />
                        )}
                        timestamp={timestamp}
                        timestampSelected={timestamp}
                        formatFunctions={{
                            filename: (filename: string) =>
                                filename === '_INDEX2.pdf' ? 'Confirmation of receipt.pdf' : filename,
                        }}
                        selectable={isPreviewMode}
                        noUnselectAll={true}
                        onSelectedChange={onTableAttachmentsSelectedChange}
                        selectDefault={getSelectDefaultAttachmentsNames()}
                    />
                ) : null}
            </div>
        </div>
    );
}
