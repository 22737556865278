import React, {useEffect, useState} from 'react';
import {Button} from '../../../../../componentsFormV2';
import CustomTable from '~components/CustomTable';
import DateInput from '~components/DateInput';
import {Error} from '../../../../../componentsLayout';
import {ErrorMessages} from '../../../../TLO/CommonInterfaces/CommonInterfaces';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {History} from 'history';
import {ModalCustomVersion2} from '../../../../../commonModals';
import {Right} from '../../../../TLO/CommonComponents/ModalCategory/Interfaces/ModalCategoryInterfaces';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {TreeElement} from '../../../../TLO/CommonComponents/TreeView/Interfaces/TreeViewInterfaces';
import TextInput from '~components/TextInput';
import TextAreaInput from '~components/TextAreaInput';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {getConcatErrorMessage} from '../../../../TLO/CommonFunctions/CommonFunctions';
import {injectIntl, IntlShape} from 'react-intl';
import moment from 'moment';

interface ModalContentItemProps {
    intl: IntlShape;
    history: History;
    contentItemElement: TreeElement;
    rights: Array<Right>;
    saveContentItem: (contentItemElement: TreeElement) => void;
    close: () => void;
}

const ModalContentItem = (props: ModalContentItemProps & RouteComponentProps) => {
    const [contentItemId] = useState(props.contentItemElement.id || 0);
    const [title, setTitle] = useState(props.contentItemElement.title || '');
    const [additionalInfo, setAdditionalInfo] = useState(props.contentItemElement.additionalInfo || '');
    const [order, setOrder] = useState(props.contentItemElement.order || '0');
    const [lastUpdateDate] = useState(props.contentItemElement.lastUpdateDate || moment().format('DD/MM/YYYY'));
    const [selectedRightIds, setSelectedRightIds] = useState<Array<string>>(props.contentItemElement.rights || []);
    const [selectedRightsHash, setSelectedRightsHash] = useState<{[key: string]: boolean}>({});
    const [rights] = useState<Array<Right>>(props.rights || []);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [highlightErrors, setHighlightErrors] = useState(false);

    const updateSelectedRightsHash = (selectedRightIds: Array<string>) => {
        const rightsHash: {[key: string]: boolean} = {};
        selectedRightIds.forEach(id => {
            if (id) {
                rightsHash[id] = rights.filter(el => el.rightId === id).length > 0 ? true : false;
            }
        });
        setSelectedRightsHash(rightsHash);
    };

    useEffect(() => {
        updateSelectedRightsHash(selectedRightIds);
    }, []);

    const ontitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const label = event.target.value;
        setTitle(label);
    };

    const onadditionalInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const label = event.target.value;
        setAdditionalInfo(label);
    };

    const onorderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const label = event.target.value;
        setOrder(label);
    };

    const onSelectedRightsChange = (selectedRightIds: Array<string>) => {
        setSelectedRightIds(selectedRightIds);
        updateSelectedRightsHash(selectedRightIds);
    };

    const prepareContentItemDataForSave = (): TreeElement => {
        return {
            id: contentItemId,
            order,
            title,
            additionalInfo,
            rights: selectedRightIds,
            lastUpdateDate,
            beginDate: '',
            endDate: '',
            file: [],
            level: props.contentItemElement.level || 2,
            parentId: props.contentItemElement.parentId,
            parentTitle: props.contentItemElement.parentTitle,
            showLastUpdateDate: props.contentItemElement.showLastUpdateDate || false,
            categoryType: 0,
            isCategory: false,
            isContent: false,
            isContentItem: true,
            children: props.contentItemElement.children || [],
        };
    };

    const getErrorMessagesForSave = () => {
        setHighlightErrors(false);
        const errorMessages: ErrorMessages = {
            errorInput: [],
            errorLogic: [],
        };

        if (title.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a title for the content item');
        }

        return errorMessages;
    };

    const saveContentItem = () => {
        const errorMessagesObj = getErrorMessagesForSave();
        let errorMessage = getConcatErrorMessage(errorMessagesObj);

        if (errorMessage.length > 0) {
            setErrorMessage(errorMessage);
            setHighlightErrors(true);
        }

        if (errorMessage.length === 0) {
            const contentItemData = prepareContentItemDataForSave();
            props.saveContentItem && props.saveContentItem(contentItemData);
        }
    };

    return (
        <ModalCustomVersion2
            loading={false}
            close={() => props.close && props.close()}
            header={`Content Item`}
            body={
                <div>
                    <TextInput value={title} outsideLabel={'Name'} outsideLabelWidth={140} onChange={ontitleChange} />
                    <div style={{clear: 'both'}}>{}</div>
                    <TextAreaInput
                        outsideLabel={`Additional Info`}
                        outsideLabelWidth={140}
                        value={additionalInfo}
                        double={true}
                        rows={10}
                        onChange={onadditionalInfoChange}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <TextInput
                        value={order}
                        outsideLabel={'Order'}
                        outsideLabelWidth={140}
                        onChange={onorderChange}
                        disabled={true}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <DateInput
                        inputValueFrom={lastUpdateDate}
                        outsideLabel={'Last update'}
                        outsideLabelWidth={140}
                        simple={true}
                        disabled={true}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <CustomTable
                        {...props}
                        version={1}
                        tableName={'rights'}
                        tableType={'OBJECT'}
                        tableSource={rights}
                        id={'rightId'}
                        timestamp={Date.now()}
                        selectable={true}
                        onSelectedChange={onSelectedRightsChange}
                        selectDefault={selectedRightsHash}
                        hideExcelButton={true}
                        noChangePageSize={true}
                        resultFieldsDefault={['description']}
                        intl={props.intl}
                        defaultOrder={'description'}
                        reverseOrder={false}
                        count={rights.length}
                    />
                </div>
            }
            footer={
                <>
                    {highlightErrors ? (
                        <Error>
                            <FontAwesomeIcon icon={faExclamationCircle as any} color={'white'} />
                            {` ${errorMessage}`}
                        </Error>
                    ) : null}
                    <Button clickAction={props.close} variation={'danger'} icon={'close'}>{`Cancel`}</Button>
                    <Button clickAction={saveContentItem} icon={'arrowRight'}>{`Save Content Item`}</Button>
                </>
            }
        />
    );
};

export default injectIntl(withRouter(ModalContentItem));
