import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import {faFile, faServer} from '@fortawesome/free-solid-svg-icons';
import styles from '~shared/ApplicationConsultationTabs.module.scss';

export const QUESTION_MODAL_DETAILS_TABS = {
    GENERAL_INFORMATION: 'GENERAL_INFORMATION',
    LOGICS: 'LOGICS',
};

interface IProps {
    onChange: any;
    currentTab: string;
    questionId: number;
}

interface IState {}

export default class QuestionModalDetailsTabs extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    render() {
        return (
            <div className={styles.header}>
                <div className={styles.leftPanel}>
                    <div
                        className={cx(
                            styles.menuItem,
                            this.props.currentTab === QUESTION_MODAL_DETAILS_TABS.GENERAL_INFORMATION &&
                                styles.menuItemActive
                        )}
                        onClick={this.props.onChange(QUESTION_MODAL_DETAILS_TABS.GENERAL_INFORMATION)}
                    >
                        <div className={styles.menuIcon}>
                            <FontAwesomeIcon icon={faFile as any} color={'green'} />
                        </div>
                        <div className={styles.menuLabel}>{`General information`}</div>
                    </div>
                    {this.props.questionId !== 0 && (
                        <div
                            className={cx(
                                styles.menuItem,
                                this.props.currentTab === QUESTION_MODAL_DETAILS_TABS.LOGICS && styles.menuItemActive
                            )}
                            onClick={this.props.onChange(QUESTION_MODAL_DETAILS_TABS.LOGICS)}
                        >
                            <div className={styles.menuIcon}>
                                <FontAwesomeIcon icon={faServer as any} color={'green'} />
                            </div>
                            <div className={styles.menuLabel}>{`Logics`}</div>
                        </div>
                    )}

                    <div style={{clear: 'both'}} />
                </div>
                <div style={{clear: 'both'}} />
            </div>
        );
    }
}
