/* eslint no-console: "off" */

const STRAIGHT_TO_CONSOLE = {
    devConsole: console.log.bind(console),
};

export function initializeLogger() {
    self.LOG = LOG;
    self.WARN = WARN;
    self.ERROR = ERROR;
    self.DEV = DEV;
}

// This is used as a template literal
function LOG(strings) {
    const parts = [strings[0]];
    for (let i = 1; i < arguments.length; i++) {
        parts.push(debugStr(arguments[i]), strings[i]);
    }

    console.log(parts.join(''));

    return STRAIGHT_TO_CONSOLE;
}

function WARN(strings) {
    const parts = [strings[0]];
    for (let i = 1; i < arguments.length; i++) {
        parts.push(debugStr(arguments[i]), strings[i]);
    }

    console.warn(parts.join(''));

    return STRAIGHT_TO_CONSOLE;
}

function ERROR(strings) {
    const parts = [strings[0]];
    for (let i = 1; i < arguments.length; i++) {
        parts.push(debugStr(arguments[i]), strings[i]);
    }

    console.error(parts.join(''));

    return STRAIGHT_TO_CONSOLE;
}

function DEV(strings) {
    const parts = [strings[0]];
    for (let i = 1; i < arguments.length; i++) {
        parts.push(debugStr(arguments[i]), strings[i]);
    }

    if (self.DEV_MODE) {
        console.info(parts.join(''));
    }

    return STRAIGHT_TO_CONSOLE;
}

function debugStr(value) {
    if (typeof value === 'string') {
        return `'${value}'`;
    } else if (!value || typeof value !== 'object') {
        return String(value);
    } else if (Array.isArray(value)) {
        return `[${value.join(',')}]`;
    } else if (value.toString === Object.prototype.toString) {
        // copy only one layer deep, to prevent infinite loops
        return JSON.stringify(value, (k, v) => (k ? String(v) : v));
    } else {
        return String(value);
    }
}
