import {ICommunicationMessageInternalRead, IDecodedJWT} from '../../types';
import _COMMUNICATION_KEYS_TO_TYPE from '../CommunicationCentreClient/DATA/COMMUNICATION_KEYS_TO_TYPES.json';

export const COMMUNICATION_TYPES = [
    {id: 'none', value: 'Please select a communication type'},
    {id: 'applicationProcedure', value: 'Application procedure'},
    {id: 'denominations', value: `Denominations`},
    {id: 'financial', value: 'Financial'},
    {id: 'legalProceedings', value: `Legal proceedings`},
    // {id: 'ApplicationProcedure', value: 'MyPVR Correspondence'},
    {id: 'otherMatters', value: `Other matters`},
    {id: 'technicalExamination', value: 'Technical examination'},
    {id: 'withdrawalSurrender', value: `Withdrawal/Surrender documents`},
];

export const COMMUNICATION_TYPE = {
    applicationProcedure: 'Application procedure',
    denominations: 'Denominations',
    financial: 'Financial',
    legalProceedings: 'Legal proceedings',
    // applicationProcedure: 'MyPVR Correspondence',
    otherMatters: 'Other matters',
    technicalExamination: 'Technical examination',
    withdrawalSurrender: 'Withdrawal/Surrender documents',
};

export const CASEHOLDERS_SHORTKEYS = ['JW', 'LDE', 'JM', 'CM', 'CC', 'BB', 'UB'];

export const USER_SHORTKEY_TO_CONTACTID: {[key: string]: number} = {
    NG: 10099,
    PEF: 1243,
    GSE: 180413,
    JW: 10095,
    JM: 1055,
    LDE: 80527,
    CM: 154933,
    CC: 150011,
    BB: 182053,
    IM: 200335,
    BL: 10087,
    CB: 1224,
    RC: 1061,
    AMF: 10085,
    PB: 170617,
    OL: 117581,
    LT: 10096,
    GG: 10093,
    DK: 10184,
    AML: 157576,
    FB: 10091,
    UB: 10106,
};

export const OPTION_ALL = 'All';

export const checkIfArrayOfShortKeysIncludeLoggedInUserId = (decodedJWT: IDecodedJWT, arr: string[]) => {
    const {id: loggedInUserId} = decodedJWT || {};
    return (arr || [])
        .map(el => USER_SHORTKEY_TO_CONTACTID[el])
        .find(contactId => contactId === parseInt(loggedInUserId));
};

export const findDefaultValueForDirectory = (decodedJWT: IDecodedJWT) => {
    if (checkIfArrayOfShortKeysIncludeLoggedInUserId(decodedJWT, ['BL', 'CB', 'RC'])) {
        return '10'; // Denomination
    }
    if (checkIfArrayOfShortKeysIncludeLoggedInUserId(decodedJWT, ['AMF', 'CC', 'PB'])) {
        return '8'; // Financial
    }
    if (checkIfArrayOfShortKeysIncludeLoggedInUserId(decodedJWT, ['OL', 'AML'])) {
        return '23'; // Legal proceedings
    }
    if (checkIfArrayOfShortKeysIncludeLoggedInUserId(decodedJWT, ['NG', 'PEF', 'GSE'])) {
        return '15'; // Registry
    }
    if (checkIfArrayOfShortKeysIncludeLoggedInUserId(decodedJWT, CASEHOLDERS_SHORTKEYS)) {
        return '12'; // Examination Procedure
    }
    return OPTION_ALL;
};

export const findDefaultValueForCaseHolder = (decodedJWT: IDecodedJWT) => {
    if (checkIfArrayOfShortKeysIncludeLoggedInUserId(decodedJWT, CASEHOLDERS_SHORTKEYS)) {
        const {id: loggedInUserId} = decodedJWT || {};
        let shortKeyIndex = Object.values(USER_SHORTKEY_TO_CONTACTID).findIndex(
            contactId => contactId === parseInt(loggedInUserId)
        );
        if (shortKeyIndex !== -1) {
            return Object.keys(USER_SHORTKEY_TO_CONTACTID)[shortKeyIndex];
        }
        return OPTION_ALL;
    }
    return OPTION_ALL;
};

export const getUserRoleName = (roleId: number, decodedJWT: IDecodedJWT): string => {
    let {id: contactId} = decodedJWT || {};
    const USER_ROLE_NAME: {[key: string]: string} = {
        11: 'BOA member',
        34: 'EU agencies BOA member',
    };
    if (parseInt(contactId) === USER_SHORTKEY_TO_CONTACTID.FB) {
        if (USER_ROLE_NAME[roleId]) {
            return ` (${USER_ROLE_NAME[roleId]})`;
        }
        return ' (CPVO Colleague)';
    }
    return '';
};

export const getSelectListOfDirectory = (decodedJWT: IDecodedJWT | null) => {
    if (!decodedJWT) {
        return [];
    }
    let {id: contactId} = decodedJWT || {};
    if (parseInt(contactId) === USER_SHORTKEY_TO_CONTACTID.FB) {
        return [
            {id: '50', value: 'Board of Appeal Issues'},
            {id: '29', value: 'Board of Appeal cases'},
            {id: '28', value: 'Composition Board of Appeal'},
            {id: '61', value: 'IAAPN'},
        ];
    }
    return [
        {id: '9', value: 'Committee decision'},
        {id: '10', value: 'Denomination'},
        {id: '11', value: 'End of file'},
        {id: '12', value: 'Examination Procedure'},
        {id: '8', value: 'Financial'},
        {id: '23', value: 'Legal proceedings'},
        {id: '13', value: 'Miscellaneous'},
        {id: '15', value: 'Registry'},
    ];
};

export const getDocumentTypeValueByMessage = (message: ICommunicationMessageInternalRead | null) => {
    if (!message) return '';
    const {docmanProfile, docmanDirectory, docmanSubtypeOfDocument} = message || {};
    let key = `${docmanProfile}-${docmanDirectory}-${docmanSubtypeOfDocument}`;
    const COMMUNICATION_KEYS_TO_TYPE: {[key: string]: string} = _COMMUNICATION_KEYS_TO_TYPE;
    return COMMUNICATION_KEYS_TO_TYPE[key];
};
