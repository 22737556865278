import React from 'react';
import {ModalCustomVersion2} from '../../../../commonModals';
import TextAreaInput from '~components/TextAreaInput';
import RadioButton from '~components/RadioButton';
import styles from '../../../AuthenticationRegistration/AuthenticationRegistration.module.scss';
import {Button} from '../../../../componentsFormV2';

export const REMEDY_TYPES: any = {
    NO_APPLICATION_DATE: 'No application date',
    CONDITIONAL_APPLICATION_DATE: 'Conditional application date',
    ADDITIONAL_INFORMATION: 'Additional information',
};

const TYPES_COLOR: any = {
    NO_APPLICATION_DATE: 'red',
    CONDITIONAL_APPLICATION_DATE: 'orange',
    ADDITIONAL_INFORMATION: 'cornflowerblue',
};

interface IProps {
    close: any;
    applicationCode: string;
    responseKey: string;
}

interface IState {
    radioInputTypeValue: string;
    textAreaInputDetailsValue: string;
}

class OnlinePdfModalRemedyReview extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            radioInputTypeValue: 'NO_APPLICATION_DATE',
            textAreaInputDetailsValue: '',
        };
    }

    componentDidMount() {
        let LOCAL_STORAGE_KEY = `REMEDY_${this.props.applicationCode}`;
        let storedRemedy = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
        if (storedRemedy[this.props.responseKey]) {
            this.setState({
                radioInputTypeValue: storedRemedy[this.props.responseKey].type,
                textAreaInputDetailsValue: storedRemedy[this.props.responseKey].details,
            });
        }
    }

    onRadioButtonTypeChange = (radioInputTypeValue: string) => this.setState({radioInputTypeValue});

    onTextAreaInputDetailsChange = ({
        target: {value: textAreaInputDetailsValue},
    }: React.ChangeEvent<HTMLInputElement>) => this.setState({textAreaInputDetailsValue});

    onButtonSaveClick = () => {
        let LOCAL_STORAGE_KEY = `REMEDY_${this.props.applicationCode}`;
        let storedRemedy = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
        storedRemedy[this.props.responseKey] = {
            ...storedRemedy[this.props.responseKey],
            type: this.state.radioInputTypeValue,
            details: this.state.textAreaInputDetailsValue,
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storedRemedy));
        this.props.close();
    };

    render() {
        return (
            <div>
                <ModalCustomVersion2
                    close={this.props.close}
                    header={`Remedy response`}
                    body={
                        <div>
                            <div style={{marginLeft: 7}}>{`Type:`}</div>
                            <div style={{clear: 'both'}} />
                            <RadioButton
                                radioButtonList={Object.keys(REMEDY_TYPES).map(type => ({
                                    description: REMEDY_TYPES[type],
                                    value: type,
                                    backgroundColor: TYPES_COLOR[type],
                                }))}
                                groupName={'remedyType'}
                                value={this.state.radioInputTypeValue}
                                className={styles.radioHorizontal}
                                clickEvent={this.onRadioButtonTypeChange}
                                double={true}
                            />
                            <div style={{clear: 'both'}} />
                            <div>
                                <TextAreaInput
                                    label="Details"
                                    value={this.state.textAreaInputDetailsValue}
                                    onChange={this.onTextAreaInputDetailsChange}
                                    width={550}
                                    rows={7}
                                />
                            </div>
                            <div style={{clear: 'both'}} />
                        </div>
                    }
                    footer={
                        <>
                            <Button
                                icon={'close'}
                                clickAction={this.props.close}
                                variation={'danger'}
                            >{`Close`}</Button>
                            <Button icon={'arrowRight'} clickAction={this.onButtonSaveClick}>{`Save response`}</Button>
                        </>
                    }
                />
            </div>
        );
    }
}

export default OnlinePdfModalRemedyReview;
