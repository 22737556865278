import React from 'react';
import InputContainer from '~componentsForm/InputContainer';
import styles from './Label.module.scss';

export default function ({children, double, width}) {
    return (
        <InputContainer {...{double, width}}>
            <div className={styles.label}>{children}</div>
        </InputContainer>
    );
}
