import {API_SERVERLESS, getFetch, getFile} from '~commonApi';

export function apiFormSearch(criteria: any, DEFAULT_CRITERIA: any = {}) {
    const requestParams = Object.keys(criteria)
        .filter(i => criteria[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${criteria[k]}`);
    return getFetch(`${API_SERVERLESS}/publicSearch/v3/forms?${requestParams.join('&')}`, true);
}

export function apiPublicSearchFormsDownloadPDF({formId, formLanguage}: any): Promise<any> {
    return getFile(`${API_SERVERLESS}/publicSearch/v3/forms/downloadPdf/${formId}/${formLanguage}`, false);
}
