import React from 'react';
import InputContainer from '~componentsForm/InputContainer';
import CustomPopoverRadio from './CustomPopoverRadio';

export default function RadioButton({
    radioButtonList,
    groupName,
    value,
    clickEvent,
    className,
    disabled,
    double,
    triple,
}) {
    return (
        <InputContainer {...{double, triple}}>
            {radioButtonList.map(radioElement => {
                return (
                    <React.Fragment key={radioElement.value}>
                        <div
                            style={{
                                float: 'left',
                                width: radioElement.popOverText ? 'unset' : 'calc(100% - 100px)',
                                cursor: disabled ? '' : 'pointer',
                                marginBottom: 7,
                                pointerEvents: disabled ? 'none' : '',
                            }}
                            className={className}
                            onClick={() => clickEvent(radioElement.value)}
                        >
                            <label onClick={() => clickEvent(radioElement.value)}>
                                <input
                                    id={`${groupName}-${radioElement.value}`}
                                    checked={radioElement.value === value}
                                    onChange={event => clickEvent(event.target.value)}
                                    type="radio"
                                    value={radioElement.value}
                                    name={groupName}
                                    disabled={disabled}
                                    style={{cursor: 'pointer'}}
                                />
                                <div
                                    style={{
                                        display: 'inline-block',
                                        cursor: 'pointer',
                                        backgroundColor: radioElement.backgroundColor
                                            ? radioElement.backgroundColor
                                            : '',
                                        padding: radioElement.backgroundColor ? '2px 7px' : '0',
                                    }}
                                >{`${radioElement.description}`}</div>
                            </label>
                        </div>
                        {radioElement.popOverText && (
                            <div style={{float: 'left', transform: 'translate(4px, -5px)'}}>
                                <CustomPopoverRadio
                                    content={radioElement.popOverText}
                                    popoverMaxWidth={radioElement.popoverMaxWidth}
                                    popoverPosition={radioElement.popoverPosition}
                                />
                            </div>
                        )}
                        <div style={{clear: 'both'}} />
                        {radioElement.value === value && radioElement.innerHTML}
                    </React.Fragment>
                );
            })}
        </InputContainer>
    );
}
