import React from 'react';
import {faInfo, faPlus} from '@fortawesome/free-solid-svg-icons';
import {
    apiBackOfficeFormResponseLogicCreateOrUpdate,
    apiBackOfficeFormResponseLogicDelete,
    apiBackOfficeTemplateResponseLogicCreateOrUpdate,
    apiBackOfficeTemplateResponseLogicDelete,
} from './ResponseModalDetailsLogicsService';
import ResponseModalDetailsLogicsElements from './ResponseModalDetailsLogicsElements.json';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {ModalAlertVersion2, ModalConfirmVersion2} from '../../../commonModals';
import {LoadingBar} from '../../../componentsLayout';
import SelectInput from '~components/SelectInput';
import {injectIntl} from 'react-intl';
import CustomTable from '~components/CustomTable';
import {isAdminUser} from '~utils';

import ModalLogicTypeAssistedEntry from '../responsesLogicModals/ModalLogicTypeAssistedEntry';
import ModalLogicTypeAssistedEntryHelp from '../responsesLogicModals/ModalLogicTypeAssistedEntryHelp';

//Autocomplete types
import ModalLogicTypeAutocompleteSpecies from '../responsesLogicModals/ModalLogicTypeAutocompleteSpecies';
import ModalLogicTypeAutocompleteSpeciesHelp from '../responsesLogicModals/ModalLogicTypeAutocompleteSpeciesHelp';

import ModalLogicTypeAutocompleteCountries from '../responsesLogicModals/ModalLogicTypeAutocompleteCountries';
import ModalLogicTypeAutocompleteCountriesHelp from '../responsesLogicModals/ModalLogicTypeAutocompleteCountriesHelp';

import ModalLogicTypeAutocompleteLanguages from '../responsesLogicModals/ModalLogicTypeAutocompleteLanguages';
import ModalLogicTypeAutocompleteLanguagesHelp from '../responsesLogicModals/ModalLogicTypeAutocompleteLanguagesHelp';
//----
//ControList
import ModalLogicTypeControlListLanguages from '../responsesLogicModals/ModalLogicTypeControlListLanguages';
import ModalLogicTypeControlListLanguagesHelp from '../responsesLogicModals/ModalLogicTypeControlListLanguagesHelp';

import ModalLogicTypeControlListCountries from '../responsesLogicModals/ModalLogicTypeControlListCountries';
import ModalLogicTypeControlListCountriesHelp from '../responsesLogicModals/ModalLogicTypeControlListCountriesHelp';

import ModalLogicTypeControlListSpecies from '../responsesLogicModals/ModalLogicTypeControlListSpecies';
import ModalLogicTypeControlListSpeciesHelp from '../responsesLogicModals/ModalLogicTypeControlListSpeciesHelp';
//---
//Dates
import ModalLogicTypeDate from '../responsesLogicModals/ModalLogicTypeDate';
import ModalLogicTypeDateHelp from '../responsesLogicModals/ModalLogicTypeDateHelp';

import ModalLogicTypePastDate from '../responsesLogicModals/ModalLogicTypePastDate';
import ModalLogicTypePastDateHelp from '../responsesLogicModals/ModalLogicTypePastDateHelp';
//----
//Inputs
import ModalLogicTypeTextNumberOfLine from '../responsesLogicModals/ModalLogicTypeTextNumberOfLine';
import ModalLogicTypeTextNumberOfLineHelp from '../responsesLogicModals/ModalLogicTypeTextNumberOfLineHelp';

import ModalLogicTypeNumber from '../responsesLogicModals/ModalLogicTypeNumber';
import ModalLogicTypeNumberHelp from '../responsesLogicModals/ModalLogicTypeNumberHelp';
//-----
//Mask
import ModalLogicTypeMaskEmail from '../responsesLogicModals/ModalLogicTypeMaskEmail';
import ModalLogicTypeMaskEmailHelp from '../responsesLogicModals/ModalLogicTypeMaskEmailHelp';

import ModalLogicTypeMaskPhoneFax from '../responsesLogicModals/ModalLogicTypeMaskPhoneFax';
import ModalLogicTypeMaskPhoneFaxHelp from '../responsesLogicModals/ModalLogicTypeMaskPhoneFaxHelp';

import ModalLogicTypeMaskDenomination from '../responsesLogicModals/ModalLogicTypeMaskDenomination';
import ModalLogicTypeMaskDenominationHelp from '../responsesLogicModals/ModalLogicTypeMaskDenominationHelp';

import ModalLogicTypeMaskDenominationNaktuinbouw from '../responsesLogicModals/ModalLogicTypeMaskDenominationNaktuinbouw';
import ModalLogicTypeMaskDenominationNaktuinbouwHelp from '../responsesLogicModals/ModalLogicTypeMaskDenominationNaktuinbouwHelp';
//---
//Limit
import ModalLogicTypeLength from '../responsesLogicModals/ModalLogicTypeLength';
import ModalLogicTypeLengthHelp from '../responsesLogicModals/ModalLogicTypeLengthHelp';
//---
//ComboList
import ModalLogicTypeComboListLanguage from '../responsesLogicModals/ModalLogicTypeComboListLanguage';
import ModalLogicTypeComboListLanguageHelp from '../responsesLogicModals/ModalLogicTypeComboListLanguageHelp';
//---
//Aux
import ModalLogicTypeShowQuestion from '../responsesLogicModals/ModalLogicTypeShowQuestion';
import ModalLogicTypeShowQuestionHelp from '../responsesLogicModals/ModalLogicTypeShowQuestionHelp';

import ModalLogicTypeClientCode from '../responsesLogicModals/ModalLogicTypeClientCode';
import ModalLogicTypeClientCodeHelp from '../responsesLogicModals/ModalLogicTypeClientCodeHelp';

import ModalLogicTypeUploadFilter from '../responsesLogicModals/ModalLogicTypeUploadFilter';
import ModalLogicTypeUploadFilterHelp from '../responsesLogicModals/ModalLogicTypeUploadFilterHelp';

import ModalLogicTypeOptional from '../responsesLogicModals/ModalLogicTypeOptional';
import ModalLogicTypeOptionalHelp from '../responsesLogicModals/ModalLogicTypeOptionalHelp';

import ModalLogicTypeInputHidden from '../responsesLogicModals/ModalLogicTypeInputHidden';
import ModalLogicTypeInputHiddenHelp from '../responsesLogicModals/ModalLogicTypeInputHiddenHelp';

import ModalLogicTypeWidthColumn from '../responsesLogicModals/ModalLogicTypeWidthColumn';
import ModalLogicTypeWidthColumnHelp from '../responsesLogicModals/ModalLogicTypeWidthColumnHelp';

import ModalLogicTypeDisabled from '../responsesLogicModals/ModalLogicTypeDisabled';
import ModalLogicTypeDisabledHelp from '../responsesLogicModals/ModalLogicTypeDisabledHelp';

import ModalLogicTypeAddQuestionnaireHybrid from '../responsesLogicModals/ModalLogicTypeAddQuestionnaireHybrid';
import ModalLogicTypeAddQuestionnaireHybridHelp from '../responsesLogicModals/ModalLogicTypeAddQuestionnaireHybridHelp';

import ModalLogicTypeForceResponse from '../responsesLogicModals/ModalLogicTypeForceResponse';
import ModalLogicTypeForceResponseHelp from '../responsesLogicModals/ModalLogicTypeForceResponseHelp';

import ModalLogicTypeQuestionOptional from '../responsesLogicModals/ModalLogicTypeQuestionOptional';
import ModalLogicTypeQuestionOptionalHelp from '../responsesLogicModals/ModalLogicTypeQuestionOptionalHelp';
//---
import ResponseModalDetailsLogicsActions from './ResponseModalDetailsLogicsActions';
import styles from './ResponseModalDetailsLogics.module.scss';
import ResponseModalDetailsLogicsBottomCaption from './ResponseModalDetailsLogicsBottomCaption';
import ModalLogicTypeValidations from '../responsesLogicModals/ModalLogicTypeValidations';
import ModalLogicTypeValidationsHelp from '../responsesLogicModals/ModalLogicTypeValidationsHelp';
import {IGroup, IGroupQuestionResponse, IGroupQuestionResponseLogic, IScreenData} from '../../../types';
import ModalLogicTypePictures from '../responsesLogicModals/ModalLogicTypePictures';
import ModalLogicTypePicturesHelp from '../responsesLogicModals/ModalLogicTypePicturesHelp';

export const LOGIC_TYPES = {
    ASSISTED_ENTRY: 135,
    AUTOCOMPLETE_SPECIES: 1,
    SHOW_QUESTION: 136,
    AUTOCOMPLETE_COUNTRIES: 21,
    CONTROL_LIST_COUNTRIES: 25,
    AUTOCOMPLETE_LANGUAGES: 23,
    CONTROL_LIST_LANGUAGES: 27,
    COMBO_LIST_LANGUAGE: 94,
    MASK_PHONE_FAX: 28,
    MASK_EMAIL: 29,
    MASK_DENOMINATION: 275,
    LENGTH: 30,
    NUMBER: 31,
    PICTURES: 317,
    DATE: 32,
    CLIENT_CODE: 41,
    TEXT_NUMBER_OF_LINE: 61,
    OPTIONAL: 62,
    DISABLED: 73,
    WIDTH_COLUMN: 74,
    UPLOAD_FILTER: 114,
    INPUT_HIDDEN: 115,
    MASK_DENOMINATION_NAKTUINBOUW: 296,
    CONTROL_LIST_SPECIES: 24,
    ADD_QUESTIONNAIRE_HYBRID: 155,
    PAST_DATE: 195,
    FORCE_RESPONSE: 217,
    QUESTION_OPTIONAL: 315,
    MASK_INPUT: 316,
};

export const MODAL_LOGIC_OPERATION_TO_PERFORM = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
};

interface IProps {
    formId: number | null;
    intl: any;
    loadFormQuestionsJSONs: any;
    mainScreen?: string;
    mock: any;
    modalQueuePop: any;
    modalQueuePush: any;
    questionId: number;
    response?: IGroupQuestionResponse;
    responseId: number;
    screenData?: IScreenData;
    steps: IGroup[];
    templateId?: number | null;
    updateScreenData?: (screenData: Partial<IScreenData>, callback?: any) => any;
    setRenderFooter?: any;
}

interface IState {
    isModalAlertOpen: string | false;
    loading: number;
    modalConfirmDeletion: IGroupQuestionResponseLogic | null;
    modalLogic: any;
    modalLogicOperationToPerform: any;
    selectedLogicTypeId: string;
    timestamp: number;
}

class ResponseModalDetailsLogics extends React.Component<IProps, IState> {
    logicTypesHash: {[key: string]: any} = {};
    isUserAdministrator: boolean = isAdminUser();
    listOptions = ResponseModalDetailsLogicsElements.types.filter(
        type =>
            ![
                LOGIC_TYPES.AUTOCOMPLETE_SPECIES,
                LOGIC_TYPES.AUTOCOMPLETE_COUNTRIES,
                LOGIC_TYPES.CONTROL_LIST_COUNTRIES,
                LOGIC_TYPES.AUTOCOMPLETE_LANGUAGES,
                LOGIC_TYPES.CONTROL_LIST_LANGUAGES,
                LOGIC_TYPES.CONTROL_LIST_SPECIES,
                LOGIC_TYPES.WIDTH_COLUMN,
                LOGIC_TYPES.ADD_QUESTIONNAIRE_HYBRID,
                LOGIC_TYPES.MASK_PHONE_FAX,
                LOGIC_TYPES.MASK_EMAIL,
                LOGIC_TYPES.MASK_DENOMINATION,
                LOGIC_TYPES.MASK_DENOMINATION_NAKTUINBOUW,
            ].includes(parseInt(type.id))
    );

    constructor(props: IProps) {
        super(props);
        !this.isUserAdministrator &&
            (this.listOptions = this.listOptions.filter(logic => parseInt(logic.id) !== LOGIC_TYPES.INPUT_HIDDEN));
        this.state = {
            isModalAlertOpen: false,
            loading: 0,
            timestamp: Date.now(),
            modalLogic: null,
            modalLogicOperationToPerform: null,
            modalConfirmDeletion: null,
            selectedLogicTypeId: String(LOGIC_TYPES.SHOW_QUESTION),
        };

        ResponseModalDetailsLogicsElements.types.forEach(logic => (this.logicTypesHash[logic.id] = logic));
    }

    componentDidMount() {
        this.props.setRenderFooter && this.props.setRenderFooter(null);
        if (Object.values(LOGIC_TYPES).indexOf(this.props.mock) !== -1) {
            this.openModalLogic(this.props.mock);
        }
    }

    closeModalConfirmDeletion = () => this.setState({modalConfirmDeletion: null});

    openModalLogic = (logicId: any, operation: string = MODAL_LOGIC_OPERATION_TO_PERFORM.UPDATE, rowObject?: any) => {
        logicId = parseInt(logicId);
        const {questionId, responseId} = this.props;
        const responseLogicType = this.logicTypesHash[logicId];
        const modalProps = {
            responseLogicValues: {...(rowObject || {})},
            responseLogicType,
            onCreateOrUpdateLogicType: this.onCreateOrUpdateLogicType,
            close: this.closeModalLogic,
            steps: this.props.steps,
        };
        const modalExtraProps = {
            formId: this.props.formId,
            questionId,
            responseId,
            modalQueuePush: this.props.modalQueuePush,
            modalQueuePop: this.props.modalQueuePop,
        };

        let theModal;
        switch (logicId) {
            case LOGIC_TYPES.ASSISTED_ENTRY:
                theModal = <ModalLogicTypeAssistedEntry {...modalProps} />;
                break;
            case LOGIC_TYPES.AUTOCOMPLETE_SPECIES:
                theModal = <ModalLogicTypeAutocompleteSpecies {...modalProps} />;
                break;
            case LOGIC_TYPES.SHOW_QUESTION:
                theModal = <ModalLogicTypeShowQuestion {...modalProps} />;
                break;
            case LOGIC_TYPES.AUTOCOMPLETE_COUNTRIES:
                theModal = <ModalLogicTypeAutocompleteCountries {...modalProps} />;
                break;
            case LOGIC_TYPES.CONTROL_LIST_COUNTRIES:
                theModal = <ModalLogicTypeControlListCountries {...modalProps} />;
                break;
            case LOGIC_TYPES.AUTOCOMPLETE_LANGUAGES:
                theModal = <ModalLogicTypeAutocompleteLanguages {...modalProps} />;
                break;
            case LOGIC_TYPES.CONTROL_LIST_LANGUAGES:
                theModal = <ModalLogicTypeControlListLanguages {...modalProps} />;
                break;
            case LOGIC_TYPES.COMBO_LIST_LANGUAGE:
                theModal = <ModalLogicTypeComboListLanguage {...modalProps} />;
                break;
            case LOGIC_TYPES.MASK_PHONE_FAX:
                theModal = <ModalLogicTypeMaskPhoneFax {...modalProps} />;
                break;
            case LOGIC_TYPES.MASK_EMAIL:
                theModal = <ModalLogicTypeMaskEmail {...modalProps} />;
                break;
            case LOGIC_TYPES.MASK_DENOMINATION:
                theModal = <ModalLogicTypeMaskDenomination {...modalProps} />;
                break;
            case LOGIC_TYPES.LENGTH:
                theModal = <ModalLogicTypeLength {...modalProps} />;
                break;
            case LOGIC_TYPES.NUMBER:
                theModal = <ModalLogicTypeNumber {...modalProps} />;
                break;
            case LOGIC_TYPES.PICTURES:
                theModal = <ModalLogicTypePictures {...modalProps} />;
                break;
            case LOGIC_TYPES.DATE:
                theModal = <ModalLogicTypeDate {...modalProps} />;
                break;
            case LOGIC_TYPES.CLIENT_CODE:
                theModal = <ModalLogicTypeClientCode {...modalProps} />;
                break;
            case LOGIC_TYPES.TEXT_NUMBER_OF_LINE:
                theModal = <ModalLogicTypeTextNumberOfLine {...modalProps} />;
                break;
            case LOGIC_TYPES.OPTIONAL:
                theModal = <ModalLogicTypeOptional {...modalProps} />;
                break;
            case LOGIC_TYPES.DISABLED:
                theModal = <ModalLogicTypeDisabled {...modalProps} />;
                break;
            case LOGIC_TYPES.WIDTH_COLUMN:
                theModal = <ModalLogicTypeWidthColumn {...modalProps} />;
                break;
            case LOGIC_TYPES.UPLOAD_FILTER:
                theModal = <ModalLogicTypeUploadFilter {...modalProps} />;
                break;
            case LOGIC_TYPES.INPUT_HIDDEN:
                theModal = <ModalLogicTypeInputHidden {...modalProps} />;
                break;
            case LOGIC_TYPES.MASK_DENOMINATION_NAKTUINBOUW:
                theModal = <ModalLogicTypeMaskDenominationNaktuinbouw {...modalProps} />;
                break;
            case LOGIC_TYPES.CONTROL_LIST_SPECIES:
                theModal = <ModalLogicTypeControlListSpecies {...modalProps} />;
                break;
            case LOGIC_TYPES.ADD_QUESTIONNAIRE_HYBRID:
                theModal = <ModalLogicTypeAddQuestionnaireHybrid {...modalProps} />;
                break;
            case LOGIC_TYPES.PAST_DATE:
                theModal = <ModalLogicTypePastDate {...modalProps} />;
                break;
            case LOGIC_TYPES.FORCE_RESPONSE:
                theModal = <ModalLogicTypeForceResponse {...modalProps} {...modalExtraProps} />;
                break;
            case LOGIC_TYPES.QUESTION_OPTIONAL:
                theModal = <ModalLogicTypeQuestionOptional {...modalProps} {...modalExtraProps} />;
                break;
            case LOGIC_TYPES.MASK_INPUT:
                theModal = <ModalLogicTypeValidations {...modalProps} {...modalExtraProps} />;
                break;
            default:
                break;
        }

        this.props.modalQueuePush(theModal);
        this.setState({modalLogic: logicId, modalLogicOperationToPerform: operation});
    };

    closeModalLogic = () => {
        this.props.modalQueuePop();
        this.setState({modalLogic: null, modalLogicOperationToPerform: null});
    };

    closeModalLogicHelp = () => this.props.modalQueuePop();

    closeModalAlert = () => this.setState({isModalAlertOpen: false});

    deleteLogicRequest = () => {
        // REVIEW API: "fromTemplate" field of rowObject present only in forms, not in templates
        // templateId in templates is type string, questionnaireId in forms is number
        const rowObject = {...(this.state.modalConfirmDeletion || {})};
        const {id: logicId, responseId, iteration} = rowObject || {};
        this.setState(
            prev => ({loading: prev.loading + 1, modalConfirmDeletion: null}),
            () => {
                (this.props.mainScreen === 'templateQuestions'
                    ? apiBackOfficeTemplateResponseLogicDelete({
                          templateId: this.props.templateId,
                          responseId,
                          logicId,
                          iteration,
                      })
                    : apiBackOfficeFormResponseLogicDelete({
                          formId: this.props.formId,
                          responseId,
                          logicId,
                          iteration,
                      })
                )
                    .then(() => {
                        this.props.loadFormQuestionsJSONs &&
                            this.props.loadFormQuestionsJSONs(null, this.updateTimestamp, null, true);
                    })
                    .catch((error: any) => {
                        ERROR([`deleteLogicRequest: ${error.message}`]);
                    });
            }
        );
    };

    updateTimestamp = () => {
        this.setState(prev => ({loading: prev.loading - 1, timestamp: Date.now()}));
        setTimeout(() => {
            this.props.updateScreenData && this.props.updateScreenData({hash: Date.now()});
        }, 100);
    };

    addRemarkIfNeeded = (logic: IGroupQuestionResponseLogic) => {
        if (logic.questionIdDest) {
            let destinationResponse;
            let destinationQuestion = (this.props.steps || [])
                .map(el => el.questions)
                .flat(1)
                .find(question => question && question.id === logic.questionIdDest);
            if (logic.responseIdDest) {
                destinationResponse = ((destinationQuestion && destinationQuestion.responses) || []).find(
                    response => response.id === logic.responseIdDest
                );
            }
            return {
                ...logic,
                remark: `${destinationQuestion ? destinationQuestion.order : '01'}${
                    destinationResponse ? ` / ${destinationResponse.message}` : ''
                }`,
            };
        }
        return logic;
    };

    prepareTableSource = () => {
        const extraLogics: any = [];
        const sourceQuestion = (this.props.steps || [])
            .map(step => step.questions)
            .flat(1)
            .find(question => question && question.id === this.props.questionId);
        const {type: questionType} = sourceQuestion || {};
        if (questionType === 'upload') {
            extraLogics.push({
                name: 'Remove label',
                type: 'removelabel',
                remark: 'Remove the label if there is less than 3 filters',
                logicParam: 'hardcoded',
            });
        }
        return [
            ...((this.props.response || {}).logics || []).map(logic => this.addRemarkIfNeeded(logic)),
            ...extraLogics,
        ];
    };

    getLogicRowClass = (rowObject: IGroupQuestionResponseLogic) => {
        return this.props.mainScreen === 'templateQuestions'
            ? styles['logicBelongsToTemplate']
            : styles[rowObject.fromTemplate ? 'logicBelongsToTemplate' : 'logicBelongsToForm'];
    };

    onTableIconDeleteClick = (rowObject: IGroupQuestionResponseLogic) =>
        this.setState({modalConfirmDeletion: rowObject});

    onInfoIconClick = () => {
        const selectedLogicTypeId = parseInt(String(this.state.selectedLogicTypeId || 0));
        const responseLogicType = this.logicTypesHash[selectedLogicTypeId];
        const modalProps = {
            responseLogicType,
            close: this.closeModalLogicHelp,
        };
        let theModalHelp;
        switch (selectedLogicTypeId) {
            case LOGIC_TYPES.ASSISTED_ENTRY:
                theModalHelp = <ModalLogicTypeAssistedEntryHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.AUTOCOMPLETE_SPECIES:
                theModalHelp = <ModalLogicTypeAutocompleteSpeciesHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.SHOW_QUESTION:
                theModalHelp = <ModalLogicTypeShowQuestionHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.AUTOCOMPLETE_COUNTRIES:
                theModalHelp = <ModalLogicTypeAutocompleteCountriesHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.CONTROL_LIST_COUNTRIES:
                theModalHelp = <ModalLogicTypeControlListCountriesHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.AUTOCOMPLETE_LANGUAGES:
                theModalHelp = <ModalLogicTypeAutocompleteLanguagesHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.CONTROL_LIST_LANGUAGES:
                theModalHelp = <ModalLogicTypeControlListLanguagesHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.COMBO_LIST_LANGUAGE:
                theModalHelp = <ModalLogicTypeComboListLanguageHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.MASK_PHONE_FAX:
                theModalHelp = <ModalLogicTypeMaskPhoneFaxHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.MASK_EMAIL:
                theModalHelp = <ModalLogicTypeMaskEmailHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.MASK_INPUT:
                theModalHelp = <ModalLogicTypeValidationsHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.MASK_DENOMINATION:
                theModalHelp = <ModalLogicTypeMaskDenominationHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.LENGTH:
                theModalHelp = <ModalLogicTypeLengthHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.NUMBER:
                theModalHelp = <ModalLogicTypeNumberHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.PICTURES:
                theModalHelp = <ModalLogicTypePicturesHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.DATE:
                theModalHelp = <ModalLogicTypeDateHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.CLIENT_CODE:
                theModalHelp = <ModalLogicTypeClientCodeHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.TEXT_NUMBER_OF_LINE:
                theModalHelp = <ModalLogicTypeTextNumberOfLineHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.OPTIONAL:
                theModalHelp = <ModalLogicTypeOptionalHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.DISABLED:
                theModalHelp = <ModalLogicTypeDisabledHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.WIDTH_COLUMN:
                theModalHelp = <ModalLogicTypeWidthColumnHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.UPLOAD_FILTER:
                theModalHelp = <ModalLogicTypeUploadFilterHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.INPUT_HIDDEN:
                theModalHelp = <ModalLogicTypeInputHiddenHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.MASK_DENOMINATION_NAKTUINBOUW:
                theModalHelp = <ModalLogicTypeMaskDenominationNaktuinbouwHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.CONTROL_LIST_SPECIES:
                theModalHelp = <ModalLogicTypeControlListSpeciesHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.ADD_QUESTIONNAIRE_HYBRID:
                theModalHelp = <ModalLogicTypeAddQuestionnaireHybridHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.PAST_DATE:
                theModalHelp = <ModalLogicTypePastDateHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.FORCE_RESPONSE:
                theModalHelp = <ModalLogicTypeForceResponseHelp {...modalProps} />;
                break;
            case LOGIC_TYPES.QUESTION_OPTIONAL:
                theModalHelp = <ModalLogicTypeQuestionOptionalHelp {...modalProps} />;
                break;
            default:
                break;
        }

        this.props.modalQueuePush(theModalHelp);
    };

    onCreateOrUpdateLogicType = (
        param: any,
        applyToRemark: number,
        selectedId: any,
        selectedQuestionId: any,
        labelId: any,
        iteration: number = 1
    ) => {
        const {responseId} = this.props;
        let formId = this.props.mainScreen === 'templateQuestions' ? null : this.props.formId;
        let templateId = this.props.mainScreen === 'templateQuestions' ? this.props.templateId : null;
        const JSONRequest = {
            responseId,
            formId,
            templateId,
            logicId: this.state.modalLogic,
            logicParam: param,
            questionIdDest: selectedQuestionId ? parseInt(selectedQuestionId) : null,
            responseIdDest: selectedId ? parseInt(selectedId) : null,
            applyToRemark,
            iteration: this.state.modalLogicOperationToPerform === 'CREATE' ? 0 : iteration,
            messageId: labelId ? labelId : null,
            root: null,
        };
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                this.props.modalQueuePop();
                (this.props.mainScreen && this.props.mainScreen === 'templateQuestions'
                    ? apiBackOfficeTemplateResponseLogicCreateOrUpdate(JSONRequest)
                    : apiBackOfficeFormResponseLogicCreateOrUpdate(JSONRequest)
                )
                    .then(() => {
                        this.props.loadFormQuestionsJSONs &&
                            this.props.loadFormQuestionsJSONs(null, this.updateTimestamp, null, true);
                    })
                    .catch((error: any) => {
                        ERROR([`Wizard ResponseModalDetailsLogics: ${error.message}`]);
                    });
            }
        );
    };

    onSelectedLogicTypeChange = ({target: {value: selectedLogicTypeId}}: React.ChangeEvent<HTMLSelectElement>) =>
        this.setState({selectedLogicTypeId});

    onButtonAddLogicClick = () => {
        const isCurrentLogicTypeAreaAndResponseHasAlreadyLogicArea =
            ((this.props.response && this.props.response.logics) || []).find(
                logic => parseInt(String(logic.id)) === LOGIC_TYPES.TEXT_NUMBER_OF_LINE
            ) && parseInt(this.state.selectedLogicTypeId) === LOGIC_TYPES.TEXT_NUMBER_OF_LINE;
        if (isCurrentLogicTypeAreaAndResponseHasAlreadyLogicArea) {
            return this.setState({
                isModalAlertOpen: `The logic "Text - number of line" can be applied only once to the response.`,
            });
        }
        this.openModalLogic(this.state.selectedLogicTypeId, MODAL_LOGIC_OPERATION_TO_PERFORM.CREATE);
    };

    render() {
        const actions = ResponseModalDetailsLogicsActions(
            this.openModalLogic,
            this.onTableIconDeleteClick,
            this.props.mainScreen
        );

        return (
            <>
                {this.state.loading !== 0 && <LoadingBar />}
                {this.state.modalConfirmDeletion ? (
                    <ModalConfirmVersion2
                        title={'Confirm deletion'}
                        message={'Are you sure you want to delete this logic type?'}
                        buttonName={'Yes'}
                        action={this.deleteLogicRequest}
                        close={this.closeModalConfirmDeletion}
                    />
                ) : null}
                {this.state.isModalAlertOpen ? (
                    <ModalAlertVersion2
                        message={this.state.isModalAlertOpen}
                        title={`Help`}
                        close={this.closeModalAlert}
                    />
                ) : null}
                <section style={{visibility: this.state.loading === 0 ? 'visible' : 'hidden'}}>
                    <main
                        style={{
                            maxHeight: `calc(100vh - 350px)`,
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            marginRight: -25,
                            marginBottom: 30,
                        }}
                    >
                        <div>
                            <SelectInput
                                label={`Type`}
                                value={this.state.selectedLogicTypeId}
                                notAll={true}
                                double={true}
                                onChange={this.onSelectedLogicTypeChange}
                                list={this.listOptions.map(el => ({
                                    id: el.id,
                                    value: el.libelle,
                                }))}
                                buttonIcon={faInfo}
                                buttonAction={this.onInfoIconClick}
                            />
                            <div style={{clear: 'both'}} />
                            <div style={{float: 'left'}}>
                                <FormFooterButton
                                    color={buttonColor.green}
                                    icon={faPlus}
                                    clickAction={this.onButtonAddLogicClick}
                                >{`Add logic`}</FormFooterButton>
                            </div>
                            <div style={{clear: 'both'}} />
                            {this.props.response && this.props.response.logics && (
                                <CustomTable
                                    {...this.props}
                                    tableName={'responseLogics'}
                                    tableType={'OBJECT'}
                                    tableSource={this.prepareTableSource()}
                                    id={'type'}
                                    resultFieldsDefault={['type', 'name', 'logicParam', 'remark']}
                                    intl={this.props.intl}
                                    hideExcelButton={true}
                                    timestamp={this.state.timestamp}
                                    sortFunctions={{}}
                                    notSortable={true}
                                    unlimited={true}
                                    actions={actions}
                                    rowClass={this.getLogicRowClass}
                                    bottomCaption={<ResponseModalDetailsLogicsBottomCaption />}
                                />
                            )}
                            <div style={{clear: 'both'}} />
                        </div>
                    </main>
                </section>
            </>
        );
    }
}

export default injectIntl(ResponseModalDetailsLogics);
