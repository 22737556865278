import React, {useEffect, useState} from 'react';
import DATA from './dashboardData.json';
import {TLO_STATIC_DOCS_DATA_PATH} from '../../../../commonApi';
import AdminIcon from '../../CommonComponents/AdminIcon/AdminIcon';
import {Footer, FormWrapper, HeaderLoading, HeaderLogoMenu, HeaderTitleAndVersion} from '../../../../componentsLayout';
import {History} from 'history';
import {IntlShape} from 'react-intl';
import {ModalContactUsVersion2, ModalErrorVersion2} from '../../../../commonModals';
import NavigationTLO from '../../../../shared/NavigationTLO';
import Title from '~components/Title';
import {apiGetEditorContent} from '../../CommonApi/CommonApi';
import cx from 'classnames';
import {isTextHTML, trackPageView} from '../../../../utils';
import {getUserRoles} from '../../CommonFunctions/CommonFunctions';
import styles from './HomePage.module.scss';

const webmaster = [
    {
        label: 'Webmaster',
        email: 2,
    },
];

const modalScreenTypes = {ERROR: 'ERROR', CONTACT_WEBMASTER: 'CONTACT_WEBMASTER'};

interface Parameter {
    valueString: string;
}

interface Page {
    id: number;
    title: string;
    link: string;
}

interface Section {
    id: number;
    title: string;
    pages: Array<Page>;
}

interface HomePageProps {
    intl: IntlShape;
    history: History;
}

const HomePage = (props: HomePageProps) => {
    const [loading, setLoading] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [userRoles] = useState(getUserRoles());

    useEffect(() => {
        trackPageView({documentTitle: 'tlo'});
        getEditorContent();
    }, []);

    const getEditorContent = () => {
        setLoading(true);
        apiGetEditorContent()
            .then((jsonResponse: Array<Parameter>) => {
                if (jsonResponse.length === 1 && jsonResponse[0].valueString) {
                    setInfoMessage(jsonResponse[0].valueString);
                } else {
                    setErrorMessage(`No home message found.`);
                    setModalScreen(modalScreenTypes.ERROR);
                }
            })
            .catch(error => {
                setErrorMessage(`Fetch Editor Content error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const closeErrorModal = () => {
        setErrorMessage(null);
        setModalScreen(null);
    };

    const goToPage = (linkPath: string) => {
        if (linkPath.includes('tlodocs/manual')) {
            const url = TLO_STATIC_DOCS_DATA_PATH + 'manual\\TLO_user_manual_new_revised_by_LDE.pdf';
            props.history.push(location.pathname);
            window.open(url, '_blank');
        } else {
            props.history.push(linkPath);
        }
    };

    const closeModal = () => setModalScreen(null);

    const showModalContactWebmaster = () => setModalScreen(modalScreenTypes.CONTACT_WEBMASTER);

    const filterSectionsPerUserRoles = (sections: Array<Section>) => {
        let finalSections: Array<Section> = sections;
        if (
            userRoles.indexOf('WETLO') === -1 &&
            userRoles.indexOf('TLOOF') === -1 &&
            userRoles.indexOf('EOADM') === -1
        ) {
            finalSections = finalSections.filter(
                section => section.title !== 'My Applications' && section.title !== 'My Contract'
            );
        }

        if (userRoles.indexOf('EOADM') === -1) {
            finalSections = finalSections.filter(section => section.id !== 1);
        }

        if (userRoles.includes('EOVIS')) {
            finalSections = finalSections.filter(({id}) => ![4].includes(id));
            let sectionContactIndex = finalSections.findIndex(({id}) => id === 6);
            if (sectionContactIndex >= 0) {
                finalSections[sectionContactIndex] = {
                    ...finalSections[sectionContactIndex],
                    pages:
                        userRoles.indexOf('WETLO') === -1 &&
                        userRoles.indexOf('TLOOF') === -1 &&
                        userRoles.indexOf('EOADM') === -1
                            ? finalSections[sectionContactIndex].pages.filter(
                                  ({title}) => !['CPVO Box'].includes(title)
                              )
                            : finalSections[sectionContactIndex].pages,
                };
            }
        }
        return finalSections;
    };

    const filterPagesPerUserRoles = (pages: Array<Page>) => {
        let finalPagesList: Array<Page> = pages;
        if (
            userRoles.indexOf('WETLO') === -1 &&
            userRoles.indexOf('TLOOF') === -1 &&
            userRoles.indexOf('EOADM') === -1
        ) {
            finalPagesList = finalPagesList.filter(
                page =>
                    page.title !== 'My Reports' &&
                    page.title !== 'My Invoices' &&
                    page.title !== 'My Fruit Submissions' &&
                    page.title !== 'Designation Agreement' &&
                    page.title !== 'Scope of Entrustment' &&
                    page.title !== 'Reporting Deadlines' &&
                    page.title !== 'Consult / Modify Species Data' &&
                    page.title !== 'Request New Entrustment' &&
                    page.title !== 'Withdraw an Entrustment' &&
                    page.title !== 'New Species Procedure' &&
                    page.title !== 'Contract’s User Manual' &&
                    page.title !== 'TQ Search'
            );
        }
        if (userRoles.indexOf('EOADM') === -1) {
            finalPagesList = finalPagesList.filter(page => page.title !== 'Administrator');
        }
        if (userRoles.length === 1 && userRoles.indexOf('EOVIS') === 0) {
            finalPagesList = finalPagesList.filter(
                page =>
                    page.title !== 'My R&D Projects' &&
                    page.title !== 'My Quality Audit Area' &&
                    page.title !== 'CPVO Box'
            );
        }

        return finalPagesList;
    };

    const openContactWebMasterModalRoutine = (page: Page) => {
        return (
            <a
                onClick={() => {
                    showModalContactWebmaster();
                }}
                key={page.id}
            >
                <div
                    className={cx(
                        styles.pageTitleContainer,
                        page.title === 'Contact Webmaster' && styles.pageTitleTinyContainer
                    )}
                    key={page.id}
                >
                    {page.title}
                </div>
            </a>
        );
    };

    const getPageTitle = (page: Page) => {
        if (userRoles.indexOf('EOADM') !== -1 && page.title === 'Vademecum') {
            page.title = 'Admin Vademecum';
        } else if (userRoles.indexOf('EOADM') === -1 && page.title === 'Admin Vademecum') {
            page.title = 'Vademecum';
        }

        return page.title;
    };

    const addAdminIcon = (page: Page) =>
        page.title === 'Admin Vademecum' || page.title === 'Administrator' ? <AdminIcon /> : null;

    const goToPageRoutine = (page: Page) => {
        return (
            <a
                onClick={() => {
                    goToPage(page.link);
                }}
                key={page.id}
                target={page.title === 'Contract’s User Manual' ? '_blank' : '_none'}
            >
                <div
                    className={cx(
                        styles.pageTitleContainer,
                        page.title === 'CPVO Box' && styles.pageTitleTinyContainer
                    )}
                    key={page.id}
                >
                    {getPageTitle(page)}
                    {addAdminIcon(page)}
                </div>
            </a>
        );
    };

    const DashboardArea = () => {
        const sectionPages = filterSectionsPerUserRoles(DATA.dashboardData.sections);
        return sectionPages.map((sectionEl: Section) => {
            const sectionPages = filterPagesPerUserRoles(sectionEl.pages);
            return (
                <div className={'sectionElement'} key={sectionEl.id}>
                    <Title triple={true} key={sectionEl.id}>
                        {sectionEl.title}
                    </Title>
                    {sectionPages.map((pageEl: Page) => {
                        if (pageEl.title === 'Contact Webmaster') {
                            return openContactWebMasterModalRoutine(pageEl);
                        } else {
                            return goToPageRoutine(pageEl);
                        }
                    })}
                </div>
            );
        });
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {modalScreen === modalScreenTypes.CONTACT_WEBMASTER ? (
                <ModalContactUsVersion2
                    close={closeModal}
                    customSendToList={webmaster}
                    headerTitle={'Contact Webmaster'}
                    sendTo={webmaster[0].email}
                    subjectTag={'[TLO]'}
                />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`Technical Liaison Officer`} />
            <NavigationTLO />
            <div style={{minHeight: 600, textAlign: 'center'}}>
                <FormWrapper paddingFormContent={'sm'}>
                    <div className={styles.infoMessageContainer}>
                        {isTextHTML(infoMessage) ? (
                            // eslint-disable-next-line react/no-danger
                            <div dangerouslySetInnerHTML={{__html: infoMessage}} />
                        ) : (
                            <p>{infoMessage}</p>
                        )}
                    </div>
                    {DashboardArea()}
                    <div style={{clear: 'both'}} />
                </FormWrapper>
            </div>
            <Footer />
        </>
    );
};

export default HomePage;
