import React from 'react';
import cx from 'classnames';
import styles from './Link.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function ({label, clickAction, icon, offset, disabled, marginTop, isInline, color}) {
    let labelStyle = {};
    offset &&
        (labelStyle = {
            position: 'absolute',
            left: `calc(50% + ${offset}px)`,
            width: 200,
        });
    marginTop !== undefined && (labelStyle.marginTop = marginTop);
    color && (labelStyle.color = color);

    return (
        <div
            className={cx(styles.container, disabled && styles.containerDisabled, isInline && styles.isInline)}
            onClick={clickAction}
        >
            {icon && (
                <div className={styles.icon}>
                    <FontAwesomeIcon {...{icon}} color="green" />
                </div>
            )}
            <div
                className={cx(
                    styles.label,
                    icon && styles.labelIcon,
                    disabled && styles.disabled,
                    isInline && styles.isInlineLabel
                )}
                style={labelStyle}
            >
                {label}
            </div>
        </div>
    );
}
