import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

interface ISpeciesItem {
    order: number;
    specieId: string;
    specieName: string;
    specieNameRaw: string;
}

export function apiBackOfficeNewTemplate(jsonRequest: any) {
    return postFetch(`${API_SERVERLESS}/backOffice/v2/newTemplate`, true, jsonRequest);
}

export function apiBackOfficeGetForm(
    formId = '',
    type = 'TQ',
    language = 'EN'
): Promise<{
    form: {
        id: number;
        idFormulaireRoot: number;
        name: {
            id: number;
            value: string;
            type: number;
        };
        common: {
            id: number | null;
            value: string | null;
            type: number | null;
        };
        header: {
            id: number | null;
            value: string | null;
            type: number | null;
        };
        footer: {
            id: number | null;
            value: string | null;
            type: number | null;
        };
        office: number;
        officeName: string;
        status: number;
        validityBegin: string | null;
        validityEnd: string | null;
        expert: string | null;
        remarkModif: string | null;
        versionCode: string | null;
        versionDate: string | null;
        type: number;
        questionnaireIdOrigin: number | null;
        origin: number;
        proposalDate: string | null;
        mappingCode: string | null;
        questionnaireIdFirst: number | null;
        modifRemark: string | null;
        root: number | null;
        jsonMapping: string | null;
        species: any[];
        proposeOtherOptionOnPDF: number;
        paramXmlReady: boolean;
        groupsQuestion: any[];
        cropSectors: any[];
    };
}> {
    return getFetch(`${API_SERVERLESS}/backOffice/v3/form/${language}/${type}/${formId}`, true);
}

export function apiBackOfficeGetCropSectors(): Promise<{
    data: {ID: string; NAME: string}[];
}> {
    return new Promise((resolve, reject) => {
        setTimeout(function () {
            resolve({
                data: [
                    {ID: '1', NAME: 'Agricultural'},
                    {ID: '2', NAME: 'Vegetables'},
                    {ID: '3', NAME: 'Fruit'},
                    {ID: '4', NAME: 'Ornamental'},
                    {ID: '5', NAME: 'Forest'},
                ],
            });
        }, 10);
    });
}

interface IFormCreate {
    cropSectors: any[];
    messageId: number;
    messageCommonId: number | null;
    formStatus: number;
    validityBeginDate: string | null;
    validityEndDate: string | null;
    expert: string | null;
    remarkModif: string | null;
    versionCode: string | null;
    versionDate: string | null;
    messageHeaderId: number | null;
    messageFooterId: number | null;
    questionnaireType: number;
    questionnaireOrigin: number | null;
    origin: number;
    proposalDate: string | null;
    mappingCode: string | null;
    idRoot: number | null;
    questionnaireIdFirst: number | null;
    modifRemark: string | null;
    tgCode: string | null;
    proposeOtherOptionOnPDF: boolean;
    paramXmlReady: boolean;
    root: number;
}

export function apiBackOfficeFormCreate(jsonRequest: IFormCreate): Promise<{formId: number}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formCreate`, true, jsonRequest);
}

export function apiBackOfficeSaveForm(jsonRequest: any) {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formUpdate`, true, jsonRequest);
}

export function apiBackOfficeAddSpecies(jsonRequest: {
    formId: number;
    specieId: string;
}): Promise<{species: ISpeciesItem[]}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formSpeciesAdd`, true, jsonRequest);
}

export function apiBackOfficeDeleteSpecies(jsonRequest: {
    formId: number;
    specieId: string;
}): Promise<{species: ISpeciesItem[]}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formSpeciesDelete`, true, jsonRequest);
}

export function apiBackOfficeUpdateSpecies(jsonRequest: {
    formId: number;
    mode: string;
    specieId: string;
}): Promise<{species: ISpeciesItem[]}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formSpeciesUpdate`, true, jsonRequest);
}

export function apiBackOfficeUpdateLabel(jsonRequest: any) {
    //TODO: API SERVICE CALL POST api.ocvv/oa/messages/update
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            resolve({
                message: '',
                success: true,
                data: 'OK',
            });
        }, 500);
    });
}
