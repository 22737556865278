import {API_SERVERLESS, getFetch} from '~commonApi';

export function apiCommunicationCentreClientSearchInbox() {
    return getFetch(`${API_SERVERLESS}/communicationCentreClient/v4/CCCIS/inbox/search?readingStatus=2`, true);
}
export function apiCommunicationCentreInternalSearchInbox(query) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreInternal/v4/CCIIS/inbox/search?readingStatus=2&counter=1${
            query ? `&${query}` : ''
        }`,
        true
    );
}
