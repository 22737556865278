import React from 'react';
import {injectIntl} from 'react-intl';
import styles from './OnlineApplicationsModalDuplicateGroupsAF.module.scss';
import CustomTable from '~components/CustomTable';
import {htmlToText} from '~utils';
import {apiGetFormDraft} from './OnlineApplicationsModalDuplicateGroupsAFService';
import {IApplicationSearchItem, IDraftFormFullListItem} from '../../types';
import {LoadingBar} from '../../componentsLayout';
import {Button} from '../../componentsFormV2';
import {ModalCustomVersion2} from '../../commonModals';

interface IProps {
    close: any;
    clickApplicationRequest: IApplicationSearchItem | null;
    intl: any;
    onButtonNextClick: any;
    onButtonBackClick: any;
    selectedGroupsOrTemplates: any[] | null;
    selectedTechnicalQuestionnaireLanguage: any;
    setSelectedGroupsOrTemplates: any;
}

interface IState {
    applicationForm: any;
    loading: number;
    timestamp: number;
    formsFullList: IDraftFormFullListItem[];
}

class OnlineApplicationsModalDuplicateGroupsAF extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            applicationForm: null,
            formsFullList: [],
            loading: 0,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        this.loadJSONs();
    }

    loadJSONs = () => {
        const promises = [this.loadApplicationForm()];
        Promise.all(promises).then(() => {
            if (!Array.isArray(this.props.selectedGroupsOrTemplates)) {
                this.props.setSelectedGroupsOrTemplates(Object.keys(this.getApplicationFormSelectDefault()));
            }
        });
    };

    loadApplicationForm = () =>
        new Promise(resolve => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    const {applicationRequestId, formIdApplicationForm} = this.props.clickApplicationRequest || {};
                    apiGetFormDraft({
                        applicationId: applicationRequestId,
                        formId: formIdApplicationForm,
                        formType: 'AF',
                    })
                        .then((JSONResponse: any) => {
                            if (JSONResponse) {
                                this.setState(
                                    prev => ({
                                        loading: prev.loading - 1,
                                        timestamp: Date.now(),
                                        applicationForm: JSONResponse.form,
                                        formsFullList: JSONResponse.formsFullList,
                                    }),
                                    () => resolve([])
                                );
                            }
                        })
                        .catch((error: any) => {
                            ERROR([`ModalCreateApplication: ${error.message}`]);
                        });
                }
            );
        });

    getTableSourceDataApplicationForm = () => {
        if (!this.state.applicationForm) {
            return [];
        }
        if (this.state.applicationForm) {
            return this.state.applicationForm.groupsQuestion
                .filter((group: any) => group.isGroup || group.isTemplate)
                .map(({id, message, order, isTemplate, templateId}: any) => ({
                    id,
                    name: `${order}. ${htmlToText(message)}`,
                    groupOrTemplatedId: isTemplate ? `T${templateId}` : `G${id}`,
                }));
        }
    };

    getApplicationFormSelectDefault = () => {
        let data = this.getTableSourceDataApplicationForm();
        const defaultSelected: any = {};
        data.forEach(
            (row: any) =>
                (defaultSelected[row.groupOrTemplatedId] =
                    this.props.selectedGroupsOrTemplates === null
                        ? true
                        : (this.props.selectedGroupsOrTemplates || []).includes(row.groupOrTemplatedId))
        );
        return defaultSelected;
    };

    onTableApplicationFormSelectedChange = (selectedGroupsOrTemplates: any[]) => {
        this.props.setSelectedGroupsOrTemplates(selectedGroupsOrTemplates);
    };

    renderApplicationFormName = () => {
        if (!this.state.formsFullList || !this.state.formsFullList.length) {
            return '';
        }
        if (this.state.formsFullList && this.state.formsFullList.length) {
            return this.state.formsFullList[0].name;
        }
    };

    renderNextButtonTitle = () => {
        const {TQLanguageId} = this.props.clickApplicationRequest || {};
        if (this.props.selectedTechnicalQuestionnaireLanguage !== TQLanguageId) {
            return 'Open Application';
        } else {
            return `Select TQ groups`;
        }
    };

    render() {
        const applicationFormData = this.getTableSourceDataApplicationForm();

        return (
            <div className={styles.modalWrap}>
                <ModalCustomVersion2
                    close={this.props.close}
                    header={`Duplicate the application form`}
                    headerSubtitle={`Please select the group of questions for which you would like to duplicate the information`}
                    body={
                        applicationFormData.length ? (
                            <div className={styles.tabContent}>
                                <h3 style={{marginLeft: 31, marginTop: 0}}>{this.renderApplicationFormName()}</h3>
                                <CustomTable
                                    {...this.props}
                                    tableName={'formDuplicateSelectedResponses'}
                                    tableType={'OBJECT'}
                                    tableSource={this.getTableSourceDataApplicationForm()}
                                    id={'groupOrTemplatedId'}
                                    resultFieldsDefault={['name']}
                                    intl={this.props.intl}
                                    hideExcelButton={true}
                                    timestamp={this.state.timestamp}
                                    timestampSelected={this.state.timestamp}
                                    notSortable={true}
                                    unlimited={true}
                                    selectable={true}
                                    onSelectedChange={this.onTableApplicationFormSelectedChange}
                                    selectDefault={this.getApplicationFormSelectDefault()}
                                />
                            </div>
                        ) : null
                    }
                    footer={
                        this.state.loading === 0 ? (
                            <>
                                <Button variation={'secondary'} clickAction={this.props.onButtonBackClick}>
                                    {`Back`}
                                </Button>
                                <Button clickAction={this.props.onButtonNextClick} icon={'arrowRight'}>
                                    {this.renderNextButtonTitle()}
                                </Button>
                            </>
                        ) : (
                            <LoadingBar />
                        )
                    }
                />
            </div>
        );
    }
}

export default injectIntl(OnlineApplicationsModalDuplicateGroupsAF);
