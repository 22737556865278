import React from 'react';
import QUESTION_TYPES from './QuestionModalDetailsGeneralInformationElements.json';
import Checkbox from '~components/Checkbox';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import {
    apiBackOfficeFormGroupsGet,
    apiBackOfficeFormQuestionCreateOrUpdate,
    apiBackOfficeLabels,
    apiBackOfficeUpdateLabels,
    IResponseGetGroup,
} from './QuestionModalDetailsGeneralInformationService';
import cx from 'classnames';
import {
    faLightbulb,
    faList,
    faPencilAlt,
    faPlus,
    faSearch,
    faSpinner,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {findGroupOrTemplateFromQuestionId} from '../questions/utils';
import {getContentWithCorrectImagePath, getDecodedJWT, htmlToText, isAdminUser, loadUrlParams} from '~utils';
import {injectIntl} from 'react-intl';
import {getMandatoryAsterisk} from '~utils/format';
import {
    getQuestionOrderForNewTemplateQuestion,
    getQuestionTypeIdFromTypeName,
    prepareJSONRequestBody,
} from './QuestionModalDetailsGeneralInformation.utils';
import {renderHTML} from '~common/format';
import styles from './QuestionModalDetailsGeneralInformation.module.scss';
import {IDecodedJWT, IGroup, IGroupQuestion} from '../../../types';
import {Button} from '../../../componentsFormV2';
import {Error, LoadingBar} from '../../../componentsLayout';
import {ModalConfirmVersion2, ModalLabelPreviewVersion2, ModalLabelSearchVersion2} from '../../../commonModals';
import ModalUsedInVersion2, {rowType} from '../../../commonModals/ModalUsedInVersion2';
import ModalLabelVersion2, {getLabelTypeId} from '../../../commonModals/ModalLabelVersion2';

const QUESTION_STATUS = {
    IN_PREPARATION: 1,
    ACTIVE: 2,
    TERMINATED: 3,
};

const EMPTY_STATE_QUESTION = {
    questionId: null,
    groupId: null,
    nameMessage: {
        id: null,
        valueHTML: '',
        value: '',
    },
    remarkMessage: {
        id: null,
        valueHTML: '',
        value: '',
    },
    helpMessage: {
        id: null,
        valueHTML: '',
        value: '',
    },
    btnAddMsg: {
        id: null,
        valueHTML: '',
        value: null,
    },
    btnDelMsg: {
        id: null,
        valueHTML: '',
        value: null,
    },
    order: '',
    office: null,
    successiveScoreOnly: false,
    CPVOCode: null,
    UPOVCode: null,
    groupingCharacteristic: false,
    general: false,
    status: 1,
    mappingCode: null,
    mandatory: false,
    shortDisplay: false,
    typeQuestion: QUESTION_TYPES.types[0].id,
    idOrigin: null,
    responseAllowed: 0,
    confidential: false,
    user: null,
    modifDate: null,
    jsonMapping: null,
    logics: [],
    core: null,
};

export interface IStateQuestion {
    CPVOCode: any;
    UPOVCode: any;
    btnAddMsg: {
        id: number | null;
        valueHTML: string;
        value: string | null;
    };
    btnDelMsg: {
        id: number | null;
        valueHTML: string;
        value: string | null;
    };
    confidential: boolean;
    core: any;
    general: boolean;
    groupId: any;
    groupingCharacteristic: boolean;
    helpMessage: {
        id: number | null;
        valueHTML: string;
        value: string | null;
    };
    idOrigin: any;
    jsonMapping: any;
    logics: any[];
    mandatory: boolean;
    mappingCode: any;
    modifDate: any;
    nameMessage: {
        id: number | null;
        valueHTML: string;
        value: string | null;
    };
    office: any;
    order: string;
    questionId: number | null;
    remarkMessage: {
        id: number | null;
        valueHTML: string;
        value: string | null;
    };
    responseAllowed: number;
    shortDisplay: boolean;
    status: number;
    successiveScoreOnly: boolean;
    typeQuestion: any;
    user: any;
}

export type TStateQuestion = typeof EMPTY_STATE_QUESTION;

export type TKeyOfStateQuestion = keyof TStateQuestion;

interface IProps {
    afterCreatingNewQuestion: any;
    close: any;
    formId?: number | null;
    formOrTemplateOfficeId?: number;
    group?: IGroup;
    intl: any;
    loadFormQuestionsJSONs: any;
    mainScreen?: string;
    modalQueuePush: any;
    modalQueuePop: any;
    questionId: number;
    question?: IGroupQuestion;
    setCloseHandler: any;
    setQuestionModalDetailsStatus: any;
    steps: IGroup[];
    setRenderFooter?: any;
}

interface IState {
    loading: number;
    errorMandatoryFields: boolean;
    question: any;
    groupListByIdName: {ID: string; NAME: string}[];
    groupNameHash: {[key: string]: any[]};
    groupSelectedElements: {[key: string]: string};
    groupValue: string;
    groups: IResponseGetGroup[];
    modalConfirmClose: boolean;
    textLabelQuestionNameValue: string;
    textLabelQuestionNameSelectedElements: {[key: string]: string[]};
    textLabelQuestionNameListByIdName: {ID: any; NAME: string}[];
    textLabelQuestionNameNameHash: {[key: string]: any[]};
    textLabelQuestionNameTimeoutId: any;
    textLabelQuestionNameSearchLoading: boolean;
    textTimestamp: number;
    forceHideSearchList: boolean;
}

class QuestionModalDetailsGeneralInformation extends React.Component<IProps, IState> {
    initialQuestion: any = EMPTY_STATE_QUESTION;
    administrator: boolean;
    decodedJWT: IDecodedJWT;
    textLabelQuestionNameResults: any[];

    constructor(props: IProps) {
        super(props);
        this.administrator = isAdminUser();
        this.decodedJWT = getDecodedJWT();
        this.textLabelQuestionNameResults = [];
        props.setQuestionModalDetailsStatus &&
            props.setQuestionModalDetailsStatus((props.question && props.question.status) || null);
        const initialQuestion = props.question
            ? {
                  CPVOCode: props.question.CPVOCode || null,
                  UPOVCode: props.question.UPOVCode || null,
                  btnAddMsg: {
                      id: props.question.messageButtonAddId || null,
                      valueHTML: props.question.messageButtonAdd || '',
                      value: null,
                  },
                  btnDelMsg: {
                      id: props.question.messageButtonDelId || null,
                      valueHTML: props.question.messageButtonDel || '',
                      value: null,
                  },
                  confidential: props.question.confidential || false,
                  core: null,
                  general: props.question.general === 1,
                  groupId: props.question.groupId || null,
                  groupingCharacteristic: props.question.groupingCharacteristic === 1,
                  helpMessage: {
                      id: props.question.messageHelpId || null,
                      valueHTML:
                          (props.question.messageHelp && getContentWithCorrectImagePath(props.question.messageHelp)) ||
                          '',
                      value:
                          (props.question.messageHelp && getContentWithCorrectImagePath(props.question.messageHelp)) ||
                          '',
                  },
                  idOrigin: props.question.idOrigin || null,
                  jsonMapping: props.question.jsonMapping || null,
                  logics: props.question.logics || [],
                  mandatory: props.questionId === 0 ? true : props.question.mandatory,
                  mappingCode: props.question.mappingCode || null,
                  modifDate: null,
                  nameMessage: {
                      id: props.question.messageId || null,
                      valueHTML: props.question.message || '',
                      value: (props.question.message && getContentWithCorrectImagePath(props.question.message)) || '',
                  },
                  office: props.question.office || null,
                  order: props.question.order || '',
                  questionId: props.question.id || null,
                  remarkMessage: {
                      id: props.question.messageRemarkId || null,
                      valueHTML: props.question.messageremark || '',
                      value:
                          (props.question.messageremark &&
                              getContentWithCorrectImagePath(props.question.messageremark)) ||
                          '',
                  },
                  responseAllowed: props.question.nbResponseAllowed || 0,
                  shortDisplay: props.question.shortDisplay || false,
                  status: props.question.status || 1,
                  successiveScoreOnly: props.question.successiveScoreOnly || false,
                  typeQuestion:
                      (props.question.type && getQuestionTypeIdFromTypeName(props.question.type)) ||
                      QUESTION_TYPES.types[0].id,
                  user: null,
              }
            : EMPTY_STATE_QUESTION;
        this.initialQuestion = {...initialQuestion};
        this.state = {
            loading: 0,
            errorMandatoryFields: false,
            question: {...initialQuestion},
            groupListByIdName: [],
            groupNameHash: {},
            groupSelectedElements: {},
            groupValue: '',
            groups: [],
            modalConfirmClose: false,
            textLabelQuestionNameValue: '',
            textLabelQuestionNameSelectedElements:
                props.questionId === 0 || !props.question
                    ? {}
                    : {
                          [props.question.messageId]: [props.question.message],
                      },
            textLabelQuestionNameListByIdName:
                props.questionId === 0 || !props.question
                    ? []
                    : [
                          {
                              ID: props.question.messageId,
                              NAME: props.question.message,
                          },
                      ],
            textLabelQuestionNameNameHash:
                props.questionId === 0 || !props.question
                    ? {}
                    : {
                          [props.question.message]: [props.question.messageId],
                      },
            textLabelQuestionNameTimeoutId: null,
            textLabelQuestionNameSearchLoading: false,
            textTimestamp: Date.now(),
            forceHideSearchList: true,
        };
    }

    componentDidMount() {
        this.props.setCloseHandler(this.closeHandler);
        this.props.setRenderFooter && this.props.setRenderFooter(this.renderFooter());
        this.setState(
            prev => ({
                loading: prev.loading + 1,
            }),
            () => {
                let urlParams = loadUrlParams() || {};
                const {viewFormLang} = urlParams;
                let JSONRequest: {
                    language: string;
                } | null = null;
                if (viewFormLang) {
                    JSONRequest = {
                        language: viewFormLang,
                    };
                }
                apiBackOfficeFormGroupsGet(JSONRequest).then(({groups}) => {
                    this.setState(
                        prev => ({
                            loading: prev.loading - 1,
                        }),
                        () => {
                            if (groups && Array.isArray(groups) && groups.length > 0) {
                                const groupNameHash: {[key: string]: any[]} = {};
                                groups.forEach(group => {
                                    let groupTitle = `${group.order} - ${htmlToText(group.message)}`;
                                    if (group.remark) {
                                        groupTitle = `${groupTitle}<br/><div>${group.remark}</div>`;
                                    }
                                    if (!groupNameHash[groupTitle]) {
                                        groupNameHash[groupTitle] = [];
                                    }
                                    groupNameHash[groupTitle].push(group.id);
                                });
                                const groupListByIdName = Object.keys(groupNameHash).map(groupName => ({
                                    ID: groupNameHash[groupName].join(','),
                                    NAME: htmlToText(groupName),
                                }));
                                this.setState({groupListByIdName, groupNameHash, groups});
                            }

                            if (this.props.question) {
                                let questionGroupOrTemplate = findGroupOrTemplateFromQuestionId({
                                    steps: this.props.steps,
                                    questionId: this.props.questionId,
                                });
                                if (questionGroupOrTemplate) {
                                    let groupOrTemplateId = questionGroupOrTemplate.isTemplate
                                        ? questionGroupOrTemplate.templateId
                                        : questionGroupOrTemplate.id;
                                    this.setState(() => ({
                                        groupSelectedElements: {
                                            [`${groupOrTemplateId}`]: `${
                                                (questionGroupOrTemplate || {}).order
                                            } - ${htmlToText((questionGroupOrTemplate || {}).message)}`,
                                        },
                                    }));
                                }
                            }
                            if (this.props.mainScreen === 'templateQuestions' && this.props.questionId === 0) {
                                this.setState(prevState => ({
                                    question: {
                                        ...prevState.question,
                                        order: getQuestionOrderForNewTemplateQuestion(this.props.steps),
                                    },
                                }));
                            }
                        }
                    );
                });
            }
        );
    }

    closeHandler = () => {
        const formHasChanged =
            Object.keys(this.initialQuestion || {}).filter(
                key =>
                    this.state.question &&
                    this.initialQuestion[key as TKeyOfStateQuestion] !== this.state.question[key as TKeyOfStateQuestion]
            ).length > 0;
        if (formHasChanged) {
            this.openModalConfirmClose();
            return false;
        } else {
            return true;
        }
    };

    showModalLabelSearch = (typeId: string, fieldIdKey: string, fieldHTMLKey: string) =>
        this.props.modalQueuePush(
            <ModalLabelSearchVersion2
                intl={this.props.intl}
                target={{typeId, fieldIdKey, fieldHTMLKey}}
                setLabelId={this.setLabelId}
                showModalPreview={this.showModalPreview}
                showModalUsedIn={this.showModalUsedIn}
                showModalLabel={this.showModalLabel}
                close={this.closeModalLabelSearch}
            />
        );

    closeModalLabelSearch = () => this.props.modalQueuePop();

    setLabelId = (
        label: {
            labelId: number;
            languageId: string;
            typeId: number;
            officeId: number;
            isUndefined: number;
            value: string;
            valueHTML: string;
            labelValueHTML?: any;
        },
        fieldIdKey: string
    ) => {
        //FIX THIS: on create returns back
        // {
        //     labelId: 43491
        //     labelValue: "add label placeholder 3"
        //     labelValueHTML: "add label <strong>placeholder </strong>3"
        // }
        //ON search
        // {
        // isUndefined: 0
        // labelId: 43492
        // languageId: "EN"
        // officeId: 1
        // typeId: 3
        // value: "add label 4"
        // valueHTML: "add label 4"
        // }
        const {labelId, typeId, valueHTML, labelValueHTML} = label;
        if (fieldIdKey === 'nameMessage') {
            this.setState({
                textLabelQuestionNameSelectedElements: {
                    [labelId]: valueHTML || labelValueHTML,
                },
                textLabelQuestionNameListByIdName: [
                    {
                        ID: labelId,
                        NAME: valueHTML || labelValueHTML,
                    },
                ],
                textLabelQuestionNameNameHash: {
                    [valueHTML || labelValueHTML]: [labelId],
                },
            });
        }
        return this.updateForm({
            [fieldIdKey]: {id: labelId, value: valueHTML || labelValueHTML, type: typeId},
        });
    };

    clearLabelUse = (fieldIdKey: string) => {
        this.updateForm({[fieldIdKey]: {id: null, value: null, type: null}});
        if (fieldIdKey === 'nameMessage') {
            this.onTextLabelQuestionNameSelectionChange({});
            /* workaround to hide contextual menu popup after clear label use */
            setTimeout(() => {
                let DOMLabelIcon = document?.querySelector(
                    'div[class^="DataSheetFormFields_labelIcon"]'
                ) as HTMLElement;
                DOMLabelIcon && DOMLabelIcon.click();
                let DOMLabelInput = document?.querySelector('input[class^="TextLabelInput_input_"]') as HTMLElement;
                DOMLabelInput && DOMLabelInput.click();
            }, 100);
        }
    };

    updateForm = (stateData: any) =>
        this.setState(prev => {
            return {
                ...prev,
                question: {
                    ...prev.question,
                    ...stateData,
                },
            };
        });

    showModalPreview = (valueHTML: string) =>
        this.props.modalQueuePush(
            <ModalLabelPreviewVersion2 intl={this.props.intl} close={this.closeModalPreview} valueHTML={valueHTML} />
        );

    closeModalPreview = () => this.props.modalQueuePop();

    showModalLabel = (labelId: number | null, typeId: string, fieldIdKey: string, fieldHTMLKey: string) =>
        this.props.modalQueuePush(
            <ModalLabelVersion2
                intl={this.props.intl}
                label={{labelId, typeId, fieldIdKey, fieldHTMLKey}}
                setLabelId={this.setLabelId}
                showModalUsedIn={this.showModalUsedIn}
                close={this.closeModalLabel}
            />
        );

    closeModalLabel = () => this.props.modalQueuePop();

    showModalUsedIn = (labelId: number | null) =>
        this.props.modalQueuePush(
            <ModalUsedInVersion2
                elementId={labelId}
                intl={this.props.intl}
                type={rowType.LABEL}
                close={this.closeModalUsedIn}
            />
        );

    closeModalUsedIn = () => this.props.modalQueuePop();

    openModalConfirmClose = () => this.setState({modalConfirmClose: true});

    closeModalConfirmClose = () => this.setState({modalConfirmClose: false});

    getQuestionOrderWithoutGroupOrder = (order: string) => {
        if (order.split('.').length > 1) {
            return order
                .split('.')
                .slice(1) // Remove group order from question update/create
                .map((el: any) => el.trim())
                .join('.');
        }
        return order.trim();
    };

    questionSearch = () =>
        this.setState({textLabelQuestionNameSearchLoading: true}, () => {
            apiBackOfficeLabels({
                questionSearchTerm: this.state.textLabelQuestionNameValue,
                languageId: ((this.decodedJWT && this.decodedJWT.language) || '').toUpperCase(),
                officeId: this.props.formOrTemplateOfficeId || 1,
            }).then(JSONResponse => {
                const questionsNameHash: {[key: string]: number[]} = {};
                ((JSONResponse && JSONResponse.data && JSONResponse.data.labels) || []).forEach(el => {
                    let key = el.value;
                    if (!questionsNameHash[key]) {
                        questionsNameHash[key] = [];
                    }
                    questionsNameHash[key].push(el.labelId);
                    this.textLabelQuestionNameResults = JSONResponse.data.labels;
                });
                const questionsListByIdName = Object.keys(questionsNameHash).map(questionName => ({
                    ID: questionsNameHash[questionName].join(','),
                    NAME: questionName,
                }));
                this.setState({
                    textLabelQuestionNameSearchLoading: false,
                    textLabelQuestionNameListByIdName: questionsListByIdName,
                    textLabelQuestionNameNameHash: questionsNameHash,
                    textTimestamp: Date.now(),
                    forceHideSearchList: false,
                });
            });
        });

    requestFormQuestionCreateOrUpdate = (JSONRequest: IStateQuestion & {formId?: any; templateId?: any}) => {
        apiBackOfficeFormQuestionCreateOrUpdate(prepareJSONRequestBody(JSONRequest)).then((JSONResponse: any) => {
            if (this.props.questionId === 0) {
                const {newQuestionId} = JSONResponse || {};
                this.props.afterCreatingNewQuestion && this.props.afterCreatingNewQuestion(newQuestionId);
            } else {
                this.props.close && this.props.close();
                this.props.loadFormQuestionsJSONs && this.props.loadFormQuestionsJSONs(null, null, null, true);
            }
            this.setState(prev => ({loading: prev.loading - 1, modalConfirmClose: false}));
            return JSONResponse;
        });
    };

    onFormDataChange = (valueObject: any) =>
        this.setState(prev => ({question: Object.assign({}, prev.question, valueObject)}));

    onGroupValueChange = (groupValue: string) => this.setState({groupValue: htmlToText(groupValue)});

    onTextInputCPVOTPCodeChange = ({target: {value: CPVOCode}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({CPVOCode});

    onTextInputUPOVCodeChange = ({target: {value: UPOVCode}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({UPOVCode});

    onTextInputOrderChange = ({target: {value: order}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({order});

    onNumberInputResponsesAllowedChange = ({target: {value: responseAllowed}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({responseAllowed});

    onTextInputMappingCodeChange = ({target: {value: mappingCode}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({mappingCode});

    onTextInputJSONMappingChange = ({target: {value: jsonMapping}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({jsonMapping});

    onSelectQuestionTypeChange = ({target: {value: typeQuestion}}: React.ChangeEvent<HTMLInputElement>) =>
        this.onFormDataChange({typeQuestion});

    onCheckboxSuccessiveScoreOnlyClick = () =>
        this.onFormDataChange({successiveScoreOnly: !this.state.question.successiveScoreOnly});

    onCheckboxGroupingCharacteristicsClick = () =>
        this.onFormDataChange({groupingCharacteristic: !this.state.question.groupingCharacteristic});

    onCheckboxMandatoryClick = () => this.onFormDataChange({mandatory: !this.state.question.mandatory});

    onCheckboxConfidentialClick = () => this.onFormDataChange({confidential: !this.state.question.confidential});

    onCheckboxShortDisplayClick = () => this.onFormDataChange({shortDisplay: !this.state.question.shortDisplay});

    onCheckboxCoreClick = () => this.onFormDataChange({core: !this.state.question.core});

    onCheckboxGeneralQuestionClick = () => this.onFormDataChange({general: !this.state.question.general});

    onSave = () => {
        const qHasIdOrLabelValueToAdd = this.state.question.nameMessage.id || this.state.textLabelQuestionNameValue;
        const hasEmptyMandatoryFields =
            this.props.mainScreen === 'templateQuestions'
                ? !qHasIdOrLabelValueToAdd || !this.state.question.order
                : !qHasIdOrLabelValueToAdd || !this.state.question.groupId || !this.state.question.order;
        if (hasEmptyMandatoryFields) {
            return this.setState({errorMandatoryFields: true});
        } else {
            this.setState({errorMandatoryFields: false});
        }
        const JSONRequest: IStateQuestion & {formId?: any; templateId?: any} = {
            ...this.state.question,
            groupId: parseInt(this.state.question.groupId),
            order: this.getQuestionOrderWithoutGroupOrder(this.state.question.order || ''),
            responseAllowed: parseInt(`${this.state.question.responseAllowed}`, 0),
        };
        this.props.mainScreen !== 'templateQuestions' && (JSONRequest.formId = this.props.formId);
        this.props.mainScreen === 'templateQuestions' && (JSONRequest.templateId = this.props.steps[0].templateId);
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                if (!this.state.question.nameMessage.id) {
                    apiBackOfficeUpdateLabels({
                        labelValue: this.state.textLabelQuestionNameValue,
                        languageId: ((this.decodedJWT && this.decodedJWT.language) || '').toUpperCase() || '',
                    }).then(labelCreateResponse => {
                        if (labelCreateResponse.data) {
                            JSONRequest.nameMessage = {
                                id: labelCreateResponse.data,
                                value: this.state.textLabelQuestionNameValue,
                                valueHTML: this.state.textLabelQuestionNameValue,
                            };
                            this.setState(
                                prev => ({
                                    question: {
                                        ...prev.question,
                                        nameMessage: {
                                            id: labelCreateResponse.data,
                                            value: prev.textLabelQuestionNameValue,
                                            valueHTML: prev.textLabelQuestionNameValue,
                                        },
                                    },
                                }),
                                () => (this.initialQuestion = {...this.state.question})
                            );
                            this.requestFormQuestionCreateOrUpdate(JSONRequest);
                        }
                    });
                } else {
                    this.requestFormQuestionCreateOrUpdate(JSONRequest);
                    this.initialQuestion = {...this.state.question};
                }
            }
        );
    };

    // onSaveBAK = () => {
    //     const {question} = this.state || {};
    //     const {groupId, nameMessage, order} = question || {};
    //     const {id: nameMessageId} = nameMessage || {};
    //     const hasEmptyMandatoryFields =
    //         this.props.mainScreen === 'templateQuestions'
    //             ? !nameMessageId || !order
    //             : !groupId || !nameMessageId || !order;
    //     if (hasEmptyMandatoryFields) {
    //         return this.setState({errorMandatoryFields: true});
    //     } else {
    //         this.setState({errorMandatoryFields: false});
    //     }
    //     const jsonRequest = {
    //         ...this.state.question,
    //         groupId: parseInt(this.state.question.groupId),
    //         order: this.getQuestionOrderWithoutGroupOrder(this.state.question.order),
    //         responseAllowed: parseInt(this.state.question.responseAllowed, 0),
    //     };
    //     this.props.mainScreen !== 'templateQuestions' && (jsonRequest.formId = this.props.formId);
    //     this.props.mainScreen === 'templateQuestions' && (jsonRequest.templateId = this.props.steps[0].templateId);
    //     this.setState(
    //         prev => ({loading: ++prev.loading}),
    //         () => {
    //             apiBackOfficeFormQuestionCreateOrUpdate(jsonRequest).then(jsonResponse => {
    //                 if (this.props.questionId === 0) {
    //                     const {newQuestionId} = jsonResponse || {};
    //                     this.props.afterCreatingNewQuestion && this.props.afterCreatingNewQuestion(newQuestionId);
    //                 } else {
    //                     this.props.loadFormQuestionsJSONs && this.props.loadFormQuestionsJSONs(null, null, null, true);
    //                 }
    //                 this.setState(prev => ({loading: --prev.loading}));
    //                 return jsonResponse;
    //             });
    //         }
    //     );
    // };

    onTextLabelGroupIdChange = (groupId: string | null) => {
        if (groupId) {
            let updateFormData: {
                groupId: string;
                order?: string;
            } = {
                groupId,
            };
            let selectedGroupFromProps = this.props.steps.find(step => parseInt(`${step.id}`) === parseInt(groupId));
            if (!selectedGroupFromProps) {
                // group is not present already in this form
                let fetchedGroup = this.state.groups.find(group => parseInt(`${group.id}`) === parseInt(groupId));
                let groupOrder = (fetchedGroup || {}).order || '00';
                updateFormData.order = `${groupOrder}.01`;
            } else {
                let {questions} = selectedGroupFromProps || {};
                if (questions && questions.length) {
                    let lastQuestionOrder = ([...questions].pop() || {}).order || '';
                    let order = this.getQuestionOrderWithoutGroupOrder(lastQuestionOrder);
                    let orderNumbers = order.split('.');
                    orderNumbers[orderNumbers.length - 1] = `${parseInt(orderNumbers[orderNumbers.length - 1]) + 1}`;
                    updateFormData.order = `${selectedGroupFromProps.order}.${orderNumbers
                        .map(order => `00${order}`.slice(-2))
                        .join('.')}`;
                } else {
                    updateFormData.order = `${selectedGroupFromProps.order}.01`;
                }
            }
            this.updateForm(updateFormData);
        }
    };

    onTextLabelGroupSelectionChange = (selectedGroup: {[key: string]: string}) => {
        this.setState(prev => ({
            groupValue: '',
            groupSelectedElements: Object.keys(prev.groupSelectedElements).length ? {} : selectedGroup,
        }));
        let updateFormData = {groupId: Object.keys(selectedGroup).join(',')};
        this.updateForm(updateFormData);
    };

    onTextLabelQuestionNameValueChange = (textLabelQuestionNameValue: any) => {
        clearTimeout(this.state.textLabelQuestionNameTimeoutId);
        let newTimeoutId = setTimeout(() => {
            this.state.textLabelQuestionNameValue && this.questionSearch();
        }, 300);
        this.setState({
            forceHideSearchList: true,
            textLabelQuestionNameValue,
            textLabelQuestionNameTimeoutId: newTimeoutId,
        });
    };

    onTextLabelQuestionNameSelectionChange = (textLabelQuestionNameSelectedElements: any) => {
        let qId: any = null,
            qValue: any = null,
            qValueHTML: any = null;
        if (Object.keys(textLabelQuestionNameSelectedElements || {}).length) {
            qId = parseInt(Object.keys(textLabelQuestionNameSelectedElements)[0]);
            qValue = Object.values(textLabelQuestionNameSelectedElements)[0];
            qValueHTML = Object.values(textLabelQuestionNameSelectedElements)[0];
        }
        let resultLabelHTML = (
            (this.textLabelQuestionNameResults || []).find((el: any) => el.labelId === parseInt(qId)) || {}
        ).valueHTML;
        this.setState(prev => ({
            textLabelQuestionNameSelectedElements,
            textLabelQuestionNameValue: '',
            question: {
                ...prev.question,
                nameMessage: {
                    id: qId,
                    value: qValue,
                    valueHTML: resultLabelHTML || qValueHTML,
                },
            },
        }));
    };

    renderPopupElements(labelId: number | null, typeId: string, fieldIdKey: string, fieldHTMLKey: string) {
        // const {status: questionStatus} = this.state.question || {};
        const {questionId = null, question} = this.props;
        const {status} = question || {};
        // const {thirdPartyId} = this.decodedJWT || {};
        // let isResponseInPreparation = response && response.status === STATUS.IN_PREPARATION;
        // let isResponseActive = response && response.status === STATUS.ACTIVE;
        // const userHasSameOfficeAsFormOffice = parseInt(thirdPartyId) === this.props.formOrTemplateOfficeId;
        // // let hasResponseSameOfficeAsFormOffice = response && response.officeId === this.props.formOrTemplateOfficeId;
        // let showUpdateOrRemoveActions = isResponseInPreparation || (isResponseActive && userHasSameOfficeAsFormOffice);
        let options = {
            searchLabel: {
                label: 'Search label',
                icon: faSearch,
                handler: () => this.showModalLabelSearch(typeId, fieldIdKey, fieldHTMLKey),
            },
            addLabel: {
                label: 'Add label',
                icon: faPlus,
                handler: () => this.showModalLabel(0, typeId, fieldIdKey, fieldHTMLKey),
            },
            showLabel: {
                label: 'Show label',
                icon: faPencilAlt,
                handler: () => this.showModalLabel(labelId, typeId, fieldIdKey, fieldHTMLKey),
            },
            clearLabel: {
                label: 'Clear label',
                icon: faTimes,
                handler: () => this.clearLabelUse(fieldIdKey),
            },
            usedIn: {
                label: 'Used in',
                icon: faLightbulb,
                handler: () => this.showModalUsedIn(labelId),
            },
        };
        const {searchLabel, addLabel, showLabel, clearLabel, usedIn} = options;
        let a = [];
        if (questionId === 0) {
            if (!labelId) {
                a = [searchLabel, addLabel];
            } else {
                a = [searchLabel, showLabel, clearLabel, usedIn];
            }
        } else {
            if (!labelId) {
                a = [searchLabel, addLabel];
            } else {
                if (fieldIdKey === 'nameMessage') {
                    if (status === QUESTION_STATUS.IN_PREPARATION) {
                        a = [searchLabel, showLabel, clearLabel, usedIn];
                    } else {
                        a = [showLabel, usedIn];
                    }
                } else {
                    a = [searchLabel, showLabel, clearLabel, usedIn];
                }
            }
        }
        return a;
    }

    renderFooter = () => {
        const {question} = this.state || {};
        return this.state.loading === 0 ? (
            <>
                {this.state.errorMandatoryFields && (
                    <section style={{marginBottom: 10, textAlign: 'center'}}>
                        <Error>Please fill the mandatory fields</Error>
                    </section>
                )}
                <>
                    <Button
                        icon={'close'}
                        clickAction={() => this.props.close(false)}
                        variation={'danger'}
                    >{`Close`}</Button>
                    {question && question.status !== QUESTION_STATUS.TERMINATED && (
                        <Button
                            icon={'arrowRight'}
                            clickAction={this.onSave}
                            disabled={
                                this.props.group &&
                                this.props.group.isTemplate &&
                                this.props.mainScreen === 'backOfficeFormQuestions'
                            }
                        >{`Save`}</Button>
                    )}
                </>
            </>
        ) : (
            <LoadingBar />
        );
    };

    render() {
        const {question} = this.state || {};
        const questionTypesSelectList = QUESTION_TYPES.types.map(el => ({id: el.id, value: el.libelle}));
        const questionContextualMenuPopupElements = this.renderPopupElements(
            question && question.nameMessage && question.nameMessage.id,
            getLabelTypeId('Question'),
            'nameMessage',
            'remarklValueHTML'
        );
        const questionContextualMenuHasClearLabel = !!questionContextualMenuPopupElements.find(
            el => el.label === 'Clear label'
        );

        return (
            <>
                {this.state.modalConfirmClose && (
                    <ModalConfirmVersion2
                        title={'Element modified'}
                        message={'Are you sure you want to validate your modifications?'}
                        buttonName={'Yes'}
                        action={() => this.props.close(true)}
                        close={this.closeModalConfirmClose}
                    />
                )}
                <section>
                    {/*<main*/}
                    {/*    style={{*/}
                    {/*        maxHeight: 'calc(100vh - 350px)',*/}
                    {/*        overflowY: 'auto',*/}
                    {/*        overflowX: 'hidden',*/}
                    {/*        marginRight: -25,*/}
                    {/*        marginBottom: 30,*/}
                    {/*    }}*/}
                    {/*>*/}
                    <div
                        style={{
                            marginBottom: 25,
                            zIndex: 1056,
                        }}
                    >
                        <DataSheetFormSection title={'Question fields'} disableToggle={true}>
                            <div
                                className={cx(
                                    styles.textLabelQuestionSearchWrap,
                                    questionContextualMenuHasClearLabel && styles.contextualMenuHasClearLabel
                                )}
                            >
                                <DataSheetFormFields
                                    label={questionContextualMenuHasClearLabel ? 'Name' : ''}
                                    mandatory={questionContextualMenuHasClearLabel}
                                    html={
                                        questionContextualMenuHasClearLabel
                                            ? renderHTML(
                                                  question &&
                                                      question.nameMessage &&
                                                      (question.nameMessage.valueHTML || question.nameMessage.value)
                                              )
                                            : null
                                    }
                                    loading={this.state.loading}
                                    double={true}
                                    containerClassName="popover-nested"
                                    labelIcon={faList}
                                    labelPopup={questionContextualMenuPopupElements}
                                    disabled={
                                        (question && question.status === QUESTION_STATUS.TERMINATED) ||
                                        this.state.loading !== 0
                                    }
                                />
                                {questionContextualMenuHasClearLabel ? null : (
                                    <TextLabelInput
                                        double={true}
                                        onSelectionChange={this.onTextLabelQuestionNameSelectionChange}
                                        onChange={this.onTextLabelQuestionNameValueChange}
                                        value={this.state.textLabelQuestionNameValue}
                                        selectedElements={this.state.textLabelQuestionNameSelectedElements}
                                        noInfo={true}
                                        multiple={false}
                                        filterContains={true}
                                        label={'Name'}
                                        mandatory={true}
                                        listByIdName={this.state.textLabelQuestionNameListByIdName}
                                        nameHash={this.state.textLabelQuestionNameNameHash}
                                        uniqueValue={true}
                                        buttonIcon={this.state.textLabelQuestionNameSearchLoading && faSpinner}
                                        timestamp={this.state.textTimestamp}
                                        autoSelection={true}
                                        forceHideSearchList={this.state.forceHideSearchList}
                                        hideRemoveElementButton={
                                            this.props.questionId !== 0 &&
                                            question &&
                                            question.status !== QUESTION_STATUS.IN_PREPARATION
                                        }
                                        disabled={
                                            this.props.questionId !== 0 &&
                                            question &&
                                            question.status !== QUESTION_STATUS.IN_PREPARATION
                                        }
                                    />
                                )}
                            </div>
                            <DataSheetFormFields
                                label={'Remark'}
                                html={renderHTML(question && question.remarkMessage && question.remarkMessage.value)}
                                loading={this.state.loading}
                                double={true}
                                containerClassName="popover-nested"
                                labelIcon={faList}
                                labelPopup={this.renderPopupElements(
                                    question && question.remarkMessage && question.remarkMessage.id,
                                    getLabelTypeId('Remark'),
                                    'remarkMessage',
                                    'remarklValueHTML'
                                )}
                                disabled={
                                    (question && question.status === QUESTION_STATUS.TERMINATED) ||
                                    this.state.loading !== 0
                                }
                            />
                            <DataSheetFormFields
                                label={'Help'}
                                html={renderHTML(question && question.helpMessage && question.helpMessage.value)}
                                loading={this.state.loading}
                                double={true}
                                containerClassName="popover-nested"
                                labelIcon={faList}
                                labelPopup={this.renderPopupElements(
                                    question && question.helpMessage && question.helpMessage.id,
                                    getLabelTypeId('Help'),
                                    'helpMessage',
                                    'helplValueHTML'
                                )}
                                disabled={
                                    (question && question.status === QUESTION_STATUS.TERMINATED) ||
                                    this.state.loading !== 0
                                }
                            />
                        </DataSheetFormSection>
                    </div>
                    <div>
                        {this.props.mainScreen !== 'templateQuestions' && (
                            <div className={styles.groupWrap}>
                                <TextLabelInput
                                    double={true}
                                    onSelectionChange={
                                        question &&
                                        question.status === QUESTION_STATUS.IN_PREPARATION &&
                                        this.onTextLabelGroupSelectionChange
                                    }
                                    onChange={
                                        question &&
                                        question.status === QUESTION_STATUS.IN_PREPARATION &&
                                        this.onGroupValueChange
                                    }
                                    value={this.state.groupValue}
                                    selectedElements={this.state.groupSelectedElements}
                                    delay={300}
                                    noInfo={true}
                                    multiple={false}
                                    filterContains={true}
                                    label={getMandatoryAsterisk('Group')}
                                    listByIdName={this.state.groupListByIdName}
                                    nameHash={this.state.groupNameHash}
                                    autoSelection={true}
                                    uniqueValue={true}
                                    onIdChange={this.onTextLabelGroupIdChange}
                                    disabled={
                                        (question && question.status !== QUESTION_STATUS.IN_PREPARATION) ||
                                        this.state.loading !== 0
                                    }
                                    hideRemoveElementButton={question.status !== QUESTION_STATUS.IN_PREPARATION}
                                />
                            </div>
                        )}
                        <TextInput
                            label={`Question Id`}
                            value={question && question.questionId}
                            disabled={true}
                            simple={true}
                        />
                        <TextInput
                            label={getMandatoryAsterisk(`Sub-order`)}
                            value={question && question.order}
                            onChange={this.onTextInputOrderChange}
                            disabled={
                                (question && question.status !== QUESTION_STATUS.IN_PREPARATION) ||
                                this.state.loading !== 0
                            }
                        />
                        <SelectInput
                            label={getMandatoryAsterisk(`Question type`)}
                            value={this.state.question && this.state.question.typeQuestion}
                            notAll={true}
                            simple={true}
                            onChange={this.onSelectQuestionTypeChange}
                            list={questionTypesSelectList}
                            disabled={
                                (question && question.status !== QUESTION_STATUS.IN_PREPARATION) ||
                                this.state.loading !== 0
                            }
                        />
                        <TextInput
                            label={`CPVO TP Code`}
                            value={question && question.CPVOCode}
                            onChange={this.onTextInputCPVOTPCodeChange}
                            disabled={
                                (question && question.status === QUESTION_STATUS.TERMINATED) || this.state.loading !== 0
                            }
                        />
                        <TextInput
                            label={`UPOV Code`}
                            value={question && question.UPOVCode}
                            onChange={this.onTextInputUPOVCodeChange}
                            disabled={
                                (question && question.status === QUESTION_STATUS.TERMINATED) || this.state.loading !== 0
                            }
                        />
                        <TextInput
                            label={`Mapping code`}
                            value={question && question.mappingCode}
                            onChange={this.onTextInputMappingCodeChange}
                            disabled={
                                (question && question.status === QUESTION_STATUS.TERMINATED) || this.state.loading !== 0
                            }
                        />
                        <TextInput
                            label={`Responses allowed`}
                            value={question && `${question.responseAllowed}`}
                            onChange={this.onNumberInputResponsesAllowedChange}
                            type="number"
                            disabled={
                                (question &&
                                    (question.typeQuestion !== getQuestionTypeIdFromTypeName('checkbox') ||
                                        question.status !== QUESTION_STATUS.IN_PREPARATION)) ||
                                this.state.loading !== 0
                            }
                            // buttonIcon={faInfo}
                            // buttonAction={this.openModalResponsesAllowedHelp}
                        />
                        {this.administrator === true && (
                            <TextInput
                                label={`JSON Mapping`}
                                required={true}
                                value={question && question.jsonMapping}
                                onChange={this.onTextInputJSONMappingChange}
                                double={true}
                                disabled={question && question.status === QUESTION_STATUS.TERMINATED}
                            />
                        )}
                    </div>
                    <div style={{float: 'left', width: '100%'}}>
                        <Checkbox
                            clickAction={this.onCheckboxMandatoryClick}
                            simple={true}
                            label={`Mandatory`}
                            value={question && question.mandatory}
                            disabled={
                                (question && question.status !== QUESTION_STATUS.IN_PREPARATION) ||
                                this.state.loading !== 0
                            }
                        />
                        <Checkbox
                            clickAction={this.onCheckboxGroupingCharacteristicsClick}
                            simple={true}
                            label={`Grouping characteristics`}
                            value={question && question.groupingCharacteristic}
                            disabled={
                                (question && question.status !== QUESTION_STATUS.IN_PREPARATION) ||
                                this.state.loading !== 0
                            }
                        />
                        <Checkbox
                            clickAction={this.onCheckboxSuccessiveScoreOnlyClick}
                            simple={true}
                            label={`Successive score only?`}
                            value={question && question.successiveScoreOnly}
                            disabled={
                                (question && question.status !== QUESTION_STATUS.IN_PREPARATION) ||
                                question.typeQuestion !== getQuestionTypeIdFromTypeName('checkbox') ||
                                this.state.loading !== 0
                            }
                        />
                        <Checkbox
                            clickAction={this.onCheckboxConfidentialClick}
                            simple={true}
                            label={`Confidential`}
                            value={question && question.confidential}
                            disabled={
                                (question && question.status !== QUESTION_STATUS.IN_PREPARATION) ||
                                this.state.loading !== 0
                            }
                        />
                        <Checkbox
                            clickAction={this.onCheckboxGeneralQuestionClick}
                            simple={true}
                            label={`General question`}
                            value={question && question.general}
                            disabled={
                                (question && question.status !== QUESTION_STATUS.IN_PREPARATION) ||
                                this.state.loading !== 0
                            }
                        />
                        {this.administrator && (
                            <Checkbox
                                clickAction={this.onCheckboxCoreClick}
                                simple={true}
                                label={`Core`}
                                value={question && question.core}
                                disabled={
                                    (question && question.status !== QUESTION_STATUS.IN_PREPARATION) ||
                                    this.state.loading !== 0
                                }
                            />
                        )}
                        <Checkbox
                            clickAction={this.onCheckboxShortDisplayClick}
                            simple={true}
                            label={`Short display`}
                            value={question && question.shortDisplay}
                            disabled={
                                (question && question.status !== QUESTION_STATUS.IN_PREPARATION) ||
                                (question.typeQuestion !== getQuestionTypeIdFromTypeName('checkbox') &&
                                    question.typeQuestion !== getQuestionTypeIdFromTypeName('radio')) ||
                                this.state.loading !== 0
                            }
                        />
                    </div>
                    <div style={{float: 'left', fontStyle: 'italic'}}>
                        <>
                            {this.props.group &&
                            this.props.group.isTemplate &&
                            this.props.mainScreen === 'backOfficeFormQuestions' ? (
                                <div style={{color: 'rgb(138, 109, 59)', fontStyle: 'italic', marginBottom: 0}}>
                                    {`Template Question modification is only available in templates screen`}
                                </div>
                            ) : (
                                <p style={{color: '#8a6d3b', marginBottom: 0}}>
                                    Note: required fields are marked with an asterisk (*)
                                </p>
                            )}
                            {question && question.user && question.modifDate && (
                                <p style={{color: '#31708f', marginTop: 5}}>
                                    Last update: {question.modifDate} by {question.user}
                                </p>
                            )}
                        </>
                    </div>
                    <div style={{clear: 'both'}} />
                    {/*</main>*/}
                </section>
            </>
        );
    }
}

export default injectIntl(QuestionModalDetailsGeneralInformation);
