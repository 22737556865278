import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faList} from '@fortawesome/free-solid-svg-icons';

function renderButton(popupContent: any, handler: any, icon: any) {
    return {
        title: popupContent,
        icon: (object: any) =>
            icon && object.noteId ? (
                <a data-content={popupContent}>
                    <FontAwesomeIcon icon={icon} />
                </a>
            ) : (
                <div />
            ),
        handler,
    };
}

export default function MyPVRPrivateConsultationFeesActionButtons(props: any, showNoteLines: any) {
    const buttons = [];
    buttons.push(
        renderButton('Details note', (noteId: any, rowObject: any) => showNoteLines(rowObject.noteId), faList)
    );
    return buttons;
}
