import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export interface ILabel {
    labelId: number;
    languageId: string;
    typeId: number;
    officeId: number;
    isUndefined: number;
    value: string;
    valueHTML: string;
}

export interface ILabelUpdate {
    [key: string]: {labelId: number; labelValue: string; labelValueHTML: string};
}

export function apiBackOfficeLabels(labelId: number): Promise<{
    data: {COUNT: number; labels: ILabel[]};
}> {
    return getFetch(`${API_SERVERLESS}/backOffice/v2/labels?labelIds=${labelId}&withHTML=true`, true);
}

export function apiUpdateLabel(JSONRequest: ILabelUpdate, typeId = 0) {
    return postFetch(`${API_SERVERLESS}/backOffice/v2/updateLabels/${typeId}`, true, JSONRequest);
}
