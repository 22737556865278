import React, {useState} from 'react';
import {Button} from '../../../../../../componentsFormV2';
import DateInput from '~components/DateInput';
import {Error} from '../../../../../../componentsLayout';
import {ErrorMessages} from '../../../../CommonInterfaces/CommonInterfaces';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {InvoiceData} from './Interfaces/ModalRecordInvoiceDataInterfaces';
import {ModalConfirmVersion2, ModalCustomVersion2} from '../../../../../../commonModals';
import TextInput from '~components/TextInput';
import {UploadedFileData} from '../../../../CommonComponents/ModalDocument/Interfaces/ModalDocumentInterfaces';
import {faEuroSign, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {getConcatErrorMessage} from '../../../../CommonFunctions/CommonFunctions';
import {getMandatoryAsterisk} from '../../../../../../utils/format';
import styles from './ModalRecordInvoiceData.module.scss';

const modalScreenType = {
    AMOUNT_VERIFICATION: 'AMOUNT_VERIFICATION',
};

interface ModalRecordInvoiceDataProps {
    invoiceData: {[key: string]: InvoiceData};
    invoiceDocument: UploadedFileData;
    saveInvoiceData: (invoiceData: InvoiceData, fileName: string) => void;
    close: () => void;
}

const ModalRecordInvoiceData = (props: ModalRecordInvoiceDataProps) => {
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [invoiceDate, setInvoiceDate] = useState(
        (Object.keys(props.invoiceData).length > 0 &&
            props.invoiceData[props.invoiceDocument.fileName] &&
            props.invoiceData[props.invoiceDocument.fileName].invoiceDate) ||
            ''
    );
    const [invoiceNumber, setInvoiceNumber] = useState(
        (Object.keys(props.invoiceData).length > 0 &&
            props.invoiceData[props.invoiceDocument.fileName] &&
            props.invoiceData[props.invoiceDocument.fileName].invoiceNumber) ||
            ''
    );
    const [totalAmountVatIncl, setTotalAmountVatIncl] = useState(
        (Object.keys(props.invoiceData).length > 0 &&
            props.invoiceData[props.invoiceDocument.fileName] &&
            props.invoiceData[props.invoiceDocument.fileName].totalAmountVatIncl.toString()) ||
            '0'
    );
    const [vatAmount, setVatAmount] = useState(
        (Object.keys(props.invoiceData).length > 0 &&
            props.invoiceData[props.invoiceDocument.fileName] &&
            props.invoiceData[props.invoiceDocument.fileName].vatAmount.toString()) ||
            '0'
    );
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [highlightErrors, setHighlightErrors] = useState(false);

    const close = () => props.close && props.close();

    const onInvoiceDateChange = (invoiceDate: string) => setInvoiceDate(invoiceDate);

    const onInvoiceNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const invoiceNumber = event.target.value;
        setInvoiceNumber(invoiceNumber);
    };

    const onTotalAmountVatInclChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const totalAmountVatIncl = event.target.value
            .replace(/[^0-9.]/g, '')
            .replace(/(\..*)\./g, '$1')
            .replace(/^0+(\d)/, '$1');
        setTotalAmountVatIncl(totalAmountVatIncl);
    };

    const onVatAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const vatAmount = event.target.value
            .replace(/[^0-9.]/g, '')
            .replace(/(\..*)\./g, '$1')
            .replace(/^0+(\d)/, '$1');
        setVatAmount(vatAmount);
    };

    const prepareInvoiceDataForSave = (): InvoiceData => {
        return {
            invoiceDate,
            invoiceNumber,
            totalAmountVatIncl: Number(totalAmountVatIncl),
            vatAmount: Number(vatAmount),
        };
    };

    const getErrorMessagesForSave = () => {
        setHighlightErrors(false);
        const errorMessages: ErrorMessages = {
            errorInput: [],
            errorLogic: [],
        };
        if (invoiceDate.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('an invoice date');
        }
        if (invoiceNumber.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('an invoice number');
        }
        if (Number(totalAmountVatIncl) === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a total amount VAT included');
        }
        if (vatAmount.length === 0) {
            errorMessages.errorInput && errorMessages.errorInput.push('a VAT amount');
        }
        if (Number(totalAmountVatIncl) < Number(vatAmount)) {
            errorMessages.errorLogic &&
                errorMessages.errorLogic.push(
                    'Please check that total amount VAT included is greater than VAT amount.'
                );
        }
        return errorMessages;
    };

    const closeAmountVerificationModal = () => setModalScreen(null);

    const saveInvoiceData = () => {
        const invoiceData = prepareInvoiceDataForSave();
        props.saveInvoiceData && props.saveInvoiceData(invoiceData, props.invoiceDocument.fileName);
    };

    const saveInvoiceDataRoutine = () => {
        const errorMessagesObj = getErrorMessagesForSave();
        let errorMessage = getConcatErrorMessage(errorMessagesObj);

        if (errorMessage.length > 0) {
            setErrorMessage(errorMessage);
            setHighlightErrors(true);
        }

        if (errorMessage.length === 0) {
            if (Number(totalAmountVatIncl) > 0 && Number(totalAmountVatIncl) < 240) {
                setModalScreen(modalScreenType.AMOUNT_VERIFICATION);
            } else {
                saveInvoiceData();
            }
        }
    };

    const getFieldsArea = () => {
        return (
            <React.Fragment>
                <TextInput
                    value={props.invoiceDocument.applicationNumber || ''}
                    outsideLabel={'CPVO Register'}
                    outsideLabelWidth={160}
                    disabled={true}
                    double={true}
                />
                <div style={{clear: 'both'}}>{}</div>
                <DateInput
                    changeDateFrom={onInvoiceDateChange}
                    inputValueFrom={invoiceDate}
                    outsideLabel={getMandatoryAsterisk('Invoice Date')}
                    outsideLabelWidth={160}
                    simple={true}
                    error={invoiceDate.length === 0 ? 'Please enter an invoice date' : ''}
                />
                <div style={{clear: 'both'}}>{}</div>
                <TextInput
                    value={invoiceNumber}
                    outsideLabel={getMandatoryAsterisk('Invoice Number')}
                    outsideLabelWidth={160}
                    onChange={onInvoiceNumberChange}
                    double={true}
                    error={invoiceNumber.length === 0 ? 'Please enter an invoice number' : ''}
                />
                <div style={{clear: 'both'}}>{}</div>
                <TextInput
                    value={totalAmountVatIncl}
                    outsideLabel={getMandatoryAsterisk('Total Amount VAT incl.')}
                    outsideLabelWidth={160}
                    onChange={onTotalAmountVatInclChange}
                    type={'text'}
                    double={true}
                    buttonIcon={faEuroSign}
                    error={Number(totalAmountVatIncl) === 0 ? 'Please enter a total amount VAT included' : ''}
                />
                <div style={{clear: 'both'}}>{}</div>
                <TextInput
                    value={vatAmount}
                    outsideLabel={getMandatoryAsterisk('VAT Amount')}
                    outsideLabelWidth={160}
                    onChange={onVatAmountChange}
                    type={'text'}
                    double={true}
                    buttonIcon={faEuroSign}
                    error={vatAmount.length === 0 ? 'Please enter a VAT amount' : ''}
                />
                <div style={{clear: 'both'}}>{}</div>
            </React.Fragment>
        );
    };

    const getConfirmationModal = () =>
        modalScreen === modalScreenType.AMOUNT_VERIFICATION && (
            <ModalConfirmVersion2
                title={'Amount Verification'}
                message={'The amount is less than 240€, do you confirm?'}
                buttonName={'Yes'}
                close={closeAmountVerificationModal}
                action={saveInvoiceData}
            />
        );

    const getPreviewFileArea = () => (
        <iframe className={styles.previewFileArea} src={props.invoiceDocument.fileUrl} width={840} height={300} />
    );

    return (
        <ModalCustomVersion2
            plus2={true}
            loading={false}
            close={close}
            header={`CPVO Box: Record Invoice Data`}
            body={
                <>
                    {getFieldsArea()}
                    {getPreviewFileArea()}
                </>
            }
            footer={
                <>
                    {highlightErrors ? (
                        <Error>
                            <FontAwesomeIcon icon={faExclamationCircle as any} color={'white'} />
                            {` ${errorMessage}`}
                        </Error>
                    ) : null}
                    <Button clickAction={close} variation={'secondary'}>{`Cancel`}</Button>
                    <Button clickAction={saveInvoiceDataRoutine}>{`Save`}</Button>
                </>
            }
        >
            {getConfirmationModal()}
        </ModalCustomVersion2>
    );
};

export default ModalRecordInvoiceData;
