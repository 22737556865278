import {isContributor} from '../utils/utilsVarietyFinder';

export const getExtraFooterLinksForVarietyFinder = () => {
    const isContributorUser = isContributor();
    const linksArray: any[] = [
        {
            title: 'General disclaimer',
            pathname: '/help/en/General_disclaimer.html',
            anchor: true,
        },
    ];
    linksArray.push({
        title: 'Statistics',
        pathname: '/statistics',
    });
    if (isContributorUser) {
        linksArray.push({
            title: 'Contributor portal',
            pathname: '/contributions',
        });
    }
    linksArray.push({
        title: 'About Variety Finder',
        pathname: '/help/en/About_variety_finder.html',
        anchor: true,
    });
    return linksArray;
};
