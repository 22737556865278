import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-regular-svg-icons';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

function renderButton(popupContent, handler) {
    return {
        title: popupContent,
        icon: rowObject => {
            if (rowObject.flagged === 3) {
                return (
                    <div style={{display: 'inline-block', fontSize: 20, cursor: 'pointer'}}>
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </div>
                );
            } else {
                return rowObject.flagId ? (
                    <a data-content={popupContent}>
                        <div style={{display: 'inline-block', fontSize: 20, cursor: 'pointer'}}>
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                color={rowObject.flagged === 0 ? '#AAA' : '#2f923f'}
                            />
                        </div>
                    </a>
                ) : (
                    <span>{}</span>
                );
            }
        },
        handler,
    };
}
export default function DocumentsActionButtons(props, renderTable, markUsRead) {
    const buttons = [];
    buttons.push(
        renderButton('Ticked / Unticked', (documentId, documentObject) => {
            markUsRead([documentObject.flagId], documentObject.flagged);
            renderTable();
        })
    );
    return buttons;
}
