import React, {useEffect, useState} from 'react';
import {
    DocumentsTableRowData,
    FileSearchFilterCriteria,
} from '../../CommonComponents/FileSearch/Interfaces/FileSearchInterfaces';
import FileSearch from '../../CommonComponents/FileSearch/FileSearch';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import {History} from 'history';
import {ModalErrorVersion2} from '../../../../commonModals';
import NavigationTLO from '../../../../shared/NavigationTLO';
import {Prompt, RouteComponentProps, withRouter} from 'react-router-dom';
import SubMenuTabs from '../../SubMenu/SubMenuTabs';
import TreeView from '../../CommonComponents/TreeView/TreeView';
import {Tree} from '../../CommonComponents/TreeView/Interfaces/TreeViewInterfaces';
import {apiCreateTree, apiDownloadTreeDocumentFile, apiFetchTree, apiSearchFile} from '../../CommonApi/CommonApi';
import {getUserRoles} from '../../CommonFunctions/CommonFunctions';
import {getPreSignedURLFetchRequest} from '../../../../utils/requests';
import {injectIntl, IntlShape} from 'react-intl';
import {saveAs} from 'file-saver';
import {trackPageView} from '../../../../utils';

const modalScreenTypes = {
    ERROR: 'ERROR',
};

const DEFAULT_CRITERIA: FileSearchFilterCriteria = {
    searchTerm: '',
};

const RESULT_FIELDS_DEFAULT_FILE_SEARCH = ['fileCode', 'fileName', 'fileDate', 'treeDate'];

interface AdminVademecumPageProps {
    intl: IntlShape;
    history: History;
}

const AdminVademecumPage = (props: AdminVademecumPageProps & RouteComponentProps) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [criteria, setCriteria] = useState<FileSearchFilterCriteria>(Object.assign({}, DEFAULT_CRITERIA));
    const [, setCriteriaCount] = useState(0);
    const [documentsList, setDocumentsList] = useState<Array<DocumentsTableRowData> | null>(null);
    const [timestamp, setTimestamp] = useState(0);
    const [, setRefresh] = useState(true);
    const [tree, setTree] = useState<Tree>(JSON.parse(JSON.stringify({})));
    const [treeIsEdited, setTreeIsEdited] = useState(false);
    const [tag, setTag] = useState('vademecum');
    const [userRoles] = useState(getUserRoles());

    const menuLinks = [
        {
            id: 'VADEMECUM',
            value: userRoles.indexOf('EOADM') !== -1 ? 'Admin Vademecum' : 'Vademecum',
            path: 'tloDocumentsVademecum',
        },
        {id: 'TEMPLATES', value: 'Templates', path: 'tloDocumentsTemplates'},
        {id: 'GENERAL_INFORMATION', value: 'General Information', path: 'tloDocumentsGeneralInformation'},
    ];

    useEffect(() => {
        if (treeIsEdited) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }
    });

    const fetchTree = () => {
        setLoading(true);
        apiFetchTree(tag)
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.general && jsonResponse.general.length > 0) {
                    setTree(jsonResponse.general[0].document);
                    setTag(jsonResponse.general[0].tag);
                }
            })
            .catch(error => {
                setErrorMessage(`Fetch Tree error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        trackPageView({documentTitle: 'tloDocumentsVademecum'});
        if (userRoles.indexOf('EOADM') === -1) {
            fetchTree();
        } else {
            const treeFromLS = localStorage.getItem(tag + 'GeneralTree');
            if (treeFromLS === null) {
                fetchTree();
            } else {
                setTreeIsEdited(true);
                setTree(JSON.parse(treeFromLS));
            }
        }
    }, []);

    const parseCriteria = (unParsedCriteria: FileSearchFilterCriteria): FileSearchFilterCriteria => {
        const parsedCriteria: FileSearchFilterCriteria = JSON.parse(JSON.stringify({}));
        Object.keys(unParsedCriteria).forEach(key => {
            parsedCriteria[key] = unParsedCriteria[key];
        });

        return parsedCriteria;
    };

    const buildParams = (parsedCriteria: FileSearchFilterCriteria) => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(key => parsedCriteria[key] !== DEFAULT_CRITERIA[key] && key !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key]}`);
        const joinedParamsText = `?${paramArray.join('&')}`;
        props.history.replace(`/tloDocumentsVademecum${(paramArray.length > 0 && joinedParamsText) || ''}`);
    };

    const search = (shouldRefresh: boolean, urlLoad = false) => {
        setLoading(true);
        setCriteria(Object.assign({}, criteria, {refresh: !!shouldRefresh}, !shouldRefresh && !urlLoad));
        const parsedCriteria = parseCriteria(criteria);
        buildParams(parsedCriteria);
        const tag = 'vademecum';
        apiSearchFile(parsedCriteria, tag)
            .then(jsonResponse => {
                if (jsonResponse) {
                    const documentsList: Array<DocumentsTableRowData> = [];
                    jsonResponse.forEach(el => {
                        documentsList.push({
                            fileCode: el.file.documentCode || '',
                            fileName: el.file.fileName,
                            fileDate: el.file.fileDate || '',
                            treeDate: el.modifDate,
                            uuid: el.file.uuid || '',
                        });
                    });
                    setDocumentsList(documentsList);
                    setTimestamp(Date.now());
                }
            })
            .catch(error => {
                setErrorMessage(`File search error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const countCriteria = (fileSearchCriteria: FileSearchFilterCriteria) => {
        let countNum = 0;
        Object.keys(fileSearchCriteria).forEach(key => {
            if (
                fileSearchCriteria[key] &&
                fileSearchCriteria[key] !== DEFAULT_CRITERIA[key] &&
                fileSearchCriteria[key] !== ''
            )
                countNum++;
        });
        return countNum;
    };

    const loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const params = domainArray.pop();
            if (params) {
                params.split('&').forEach(param => {
                    const paramElements = param.split('=');
                    if (paramElements.length === 2) {
                        const key = paramElements[0];
                        criteria[key] = decodeURIComponent(paramElements[1]);
                    }
                });
                if (Object.keys(criteria).length > 0) {
                    setCriteria(Object.assign({}, criteria));
                    setCriteriaCount(countCriteria(criteria));
                    search(false, true);
                }
            }
        }
    };

    useEffect(() => {
        loadUrlParams();
    }, []);

    const updateCriteriaValue = (
        // eslint-disable-next-line no-undef
        criteriaValue: Partial<FileSearchFilterCriteria>,
        callback = () => {},
        shouldRefresh = true
    ) => {
        setRefresh(shouldRefresh);
        if (criteriaValue !== undefined) {
            setCriteria({...criteria, ...criteriaValue});
        }
        setCriteriaCount(countCriteria(criteria));
        callback && callback();
    };

    const resetCriteria = () => {
        setCriteria(Object.assign({}, DEFAULT_CRITERIA));
        setDocumentsList(null);
        setCriteriaCount(0);
        const parsedCriteria = parseCriteria(DEFAULT_CRITERIA);
        buildParams(parsedCriteria);
    };

    const closeModal = () => setModalScreen(null);

    const downloadDocumentFile = (fileUUID: string, fileName: string) => {
        setLoading(true);
        apiDownloadTreeDocumentFile(fileUUID)
            .then(response => {
                if (response && response.signedUrl) {
                    const {signedUrl} = response;
                    getPreSignedURLFetchRequest(signedUrl)
                        .then(response => response.blob())
                        .then(responseBlob => {
                            const saveFileName = fileName ? fileName : fileUUID;
                            saveAs(responseBlob, saveFileName);
                        });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading file: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const discardTreeChanges = (tag: string, typeOfTree: string) => {
        localStorage.removeItem(tag + typeOfTree + 'Tree');
        setTreeIsEdited(false);
        fetchTree();
    };

    const saveTree = (tree: Tree, typeOfTree: string) => {
        setLoading(true);
        apiCreateTree(Object.assign({}, {tree, tag}))
            .then(() => {
                discardTreeChanges(tag, typeOfTree);
            })
            .catch(error => {
                setErrorMessage(`error saving tree: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <Prompt when={treeIsEdited} message="You have unsaved changes, are you sure you want to leave?" />
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeModal} />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`Technical Liaison Officer`} />
            <NavigationTLO />
            <MainWrapper>
                <div style={{marginBottom: 20, minHeight: 600, textAlign: 'center'}}>
                    <FormWrapper paddingFormContent={'sm'}>
                        <SubMenuTabs tabId={'VADEMECUM'} loading={loading} menuLinks={menuLinks} />
                        <FileSearch
                            intl={props.intl}
                            criteria={criteria}
                            documentsList={documentsList}
                            resultFields={RESULT_FIELDS_DEFAULT_FILE_SEARCH}
                            timestamp={timestamp}
                            updateCriteriaValue={updateCriteriaValue}
                            resetCriteria={resetCriteria}
                            search={search}
                            downloadFile={downloadDocumentFile}
                            showAdminIcon={false}
                        />
                    </FormWrapper>
                    <TreeView
                        tree={tree}
                        treeIsEdited={treeIsEdited}
                        tag={tag}
                        isAdmin={userRoles.indexOf('EOADM') !== -1}
                        isGeneral={true}
                        downloadFile={downloadDocumentFile}
                        saveTree={saveTree}
                        discardTreeChanges={discardTreeChanges}
                        isACArea={false}
                    />
                </div>
            </MainWrapper>
            <Footer />
            {loading ? <HeaderLoading /> : null}
        </>
    );
};

export default injectIntl(withRouter(AdminVademecumPage));
