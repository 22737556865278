export const SELECT_INPUT_REGISTER_TYPE_OPTIONS = [
    {
        id: 'pbr',
        value: "Plant Breeder's Rights (PBR)",
    },
    {id: 'plp', value: 'Plant Patents (PLP)'},
    {id: 'eutm', value: 'European Union trade Mark (EUTM)'},
    {id: 'nli', value: 'National List (NLI)'},
    {id: 'fru', value: 'Frumatis (FRU)'},
    {id: 'com', value: 'Commercial Registers (COM)'},
    {id: 'zzz', value: 'Other Registers (ZZZ)'},
    {id: 'ref', value: 'Reference List (REF)'},
];
