import React, {useEffect, useRef, useState} from 'react';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import {History} from 'history';
import {apiMyInvoicesSearch, MyInvoicesFilterCriteriaData, MyInvoicesTableRowData} from './MyInvoicesPageService';
import MyInvoicesFilterCriteriaArea from './Components/MyInvoicesFilterCriteriaArea/MyInvoicesFilterCriteriaArea';
import MyInvoicesTableArea from './Components/MyInvoicesTableArea/MyInvoicesTableArea';
import {ModalErrorVersion2} from '../../../../commonModals';
import NavigationTLO from '../../../../shared/NavigationTLO';
import SubMenuTabs from '../../SubMenu/SubMenuTabs';
import {injectIntl, IntlShape} from 'react-intl';
import {getUserRoles} from '../../CommonFunctions/CommonFunctions';
import {getExcelSignedURLFetchRequest} from '../../../../commonApi/download';
import {getPreSignedURLFetchRequest} from '../../../../utils/requests';
import moment from 'moment';
import {saveAs} from 'file-saver';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {trackPageView} from '../../../../utils';

const DEFAULT_CRITERIA: MyInvoicesFilterCriteriaData = {
    invoiceStatus: 'Not Paid',
    fileNumber: '',
    year: '',
    supplierReference: '',
    //
    pageNumber: 1,
    pageSize: Number(localStorage.getItem('pageSizemyInvoices')) || 50,
    refresh: false,
    excel: false,
    order: 'cpvoReference',
    reverse: false,
};

const DEFAULT_COUNT = 0;

const modalScreenTypes = {ERROR: 'ERROR'};

interface MyInvoicesPageProps {
    intl: IntlShape;
    history: History;
}

const MyInvoicesPage = (props: MyInvoicesPageProps & RouteComponentProps) => {
    const [criteria, setCriteria] = useState<MyInvoicesFilterCriteriaData>(Object.assign({}, DEFAULT_CRITERIA));
    const [, setCriteriaCount] = useState(0);
    const [myInvoices, setMyInvoices] = useState<Array<MyInvoicesTableRowData> | null>(null);
    const [timestamp, setTimestamp] = useState(0);
    const [count, setCount] = useState(DEFAULT_COUNT);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [refresh, setRefresh] = useState(true);
    const [, setDefaultOrder] = useState('cpvoReference');
    const [, setReverseOrder] = useState(false);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [userRoles] = useState(getUserRoles());
    const firstLoad = useFirstRender();

    useEffect(() => {
        trackPageView({documentTitle: 'tloMyApplicationsMyInvoices'});
    }, []);

    function useFirstRender() {
        const firstRender = useRef(true);

        useEffect(() => {
            firstRender.current = false;
        }, []);

        return firstRender.current;
    }

    const getMenuLinks = () =>
        userRoles.indexOf('WETLO') !== -1 || userRoles.indexOf('TLOOF') !== -1 || userRoles.indexOf('EOADM') !== -1
            ? [
                  {id: 'MY_REGISTERS', value: 'My Reports', path: 'tloMyApplicationsMyReports'},
                  {id: 'MY_INVOICES', value: 'My Invoices', path: 'tloMyApplicationsMyInvoices'},
                  {
                      id: 'MY_FRUIT_SUBMISSIONS',
                      value: 'My Fruit Submissions',
                      path: 'tloMyApplicationsMyFruitSubmissions',
                  },
              ]
            : [];

    const countCriteria = (invoicesCriteria: MyInvoicesFilterCriteriaData) => {
        let countNum = 0;
        Object.keys(invoicesCriteria).forEach(key => {
            if (
                invoicesCriteria[key] &&
                invoicesCriteria[key] !== DEFAULT_CRITERIA[key] &&
                invoicesCriteria[key] !== ''
            )
                countNum++;
        });
        return countNum;
    };

    const parseCriteria = (unParsedCriteria: MyInvoicesFilterCriteriaData): MyInvoicesFilterCriteriaData => {
        const parsedCriteria: MyInvoicesFilterCriteriaData = JSON.parse(JSON.stringify({}));
        Object.keys(unParsedCriteria).forEach(key => {
            parsedCriteria[key] = unParsedCriteria[key];
        });

        return parsedCriteria;
    };

    const buildParams = (parsedCriteria: MyInvoicesFilterCriteriaData) => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(key => parsedCriteria[key] !== DEFAULT_CRITERIA[key] && key !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key]}`);
        const joinedParamsText = `?${paramArray.join('&')}`;
        props.history.replace(`/tloMyApplicationsMyInvoices${(paramArray.length > 0 && joinedParamsText) || ''}`);
    };

    const getMyInvoices = (parsedCriteria: MyInvoicesFilterCriteriaData, shouldRefresh: boolean, urlLoad: boolean) =>
        apiMyInvoicesSearch(parsedCriteria, {})
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.myInvoices) {
                    const newCriteria = Object.assign({}, criteria, {pageNumber: 1});
                    if (!shouldRefresh && !urlLoad) {
                        setCriteria(Object.assign({}, newCriteria));
                    }
                    setMyInvoices(jsonResponse.data.myInvoices);
                    setTimestamp(Date.now());
                    if (jsonResponse.count !== undefined) {
                        setCount(jsonResponse.count);
                    }
                }
            })
            .catch(error => {
                setErrorMessage(`My Invoices search list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));

    const search = (shouldRefresh: boolean, urlLoad = false) => {
        setLoading(true);
        setCriteria(
            Object.assign({}, criteria, {refresh: !!shouldRefresh}, !shouldRefresh && !urlLoad && {pageNumber: 1})
        );
        const parsedCriteria = parseCriteria(criteria);
        buildParams(parsedCriteria);
        getMyInvoices(parsedCriteria, shouldRefresh, urlLoad);
    };

    const loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const params = domainArray.pop();
            if (params) {
                params.split('&').forEach(param => {
                    const paramElements = param.split('=');
                    if (paramElements.length === 2) {
                        const key = paramElements[0];
                        criteria[key] = decodeURIComponent(paramElements[1]);
                    }
                });
                if (Object.keys(criteria).length > 0) {
                    setCriteria(Object.assign({}, criteria));
                    criteria.order && setDefaultOrder(criteria.order);
                    criteria.reverse && setReverseOrder(criteria.reverse);
                    setCriteriaCount(countCriteria(criteria));
                    search(false, true);
                }
            }
        }
    };

    const closeErrorModal = () => {
        setErrorMessage(null);
        setModalScreen(null);
    };

    useEffect(() => {
        if (
            firstLoad &&
            (userRoles.indexOf('EOADM') !== -1 ||
                userRoles.indexOf('WETLO') !== -1 ||
                userRoles.indexOf('TLOOF') !== -1)
        ) {
            loadUrlParams();
            criteria.pageSize = Number(localStorage.getItem('pageSizemyInvoices')) || 50;
            setCriteria(Object.assign({}, criteria));
        }
    }, []);

    useEffect(() => {
        !firstLoad &&
            refresh &&
            (userRoles.indexOf('EOADM') !== -1 ||
                userRoles.indexOf('WETLO') !== -1 ||
                userRoles.indexOf('TLOOF') !== -1) &&
            search(true);
    }, [criteria.pageNumber, criteria.pageSize, criteria.order, criteria.reverse]);

    useEffect(() => {
        firstLoad &&
            (userRoles.indexOf('EOADM') !== -1 ||
                userRoles.indexOf('WETLO') !== -1 ||
                userRoles.indexOf('TLOOF') !== -1) &&
            search(false, true);
    }, [criteria.order, criteria.reverse]);

    const updateCriteriaValue = (
        // eslint-disable-next-line no-undef
        criteriaValue: Partial<MyInvoicesFilterCriteriaData>,
        callback = () => {},
        shouldRefresh = true
    ) => {
        setRefresh(shouldRefresh);
        if (criteriaValue !== undefined) {
            setCriteria({...criteria, ...criteriaValue});
        }
        setCriteriaCount(countCriteria(criteria));
        callback && callback();
    };

    const printExcel = (excelTranslations: {[key: string]: string}) => {
        setLoading(true);
        const parsedCriteria = Object.assign({}, parseCriteria(criteria), {excel: true});
        apiMyInvoicesSearch(parsedCriteria, excelTranslations)
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                    getExcelSignedURLFetchRequest(jsonResponse.data.token).then(response => {
                        if (response && response.signedUrl) {
                            const {signedUrl} = response;
                            getPreSignedURLFetchRequest(signedUrl)
                                .then(response => response.blob())
                                .then(responseBlob => {
                                    saveAs(responseBlob, `Invoice_overview_${moment().format('DD/MM/YYYY')}.xlsx`);
                                });
                        }
                    });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const resetCriteria = () => {
        setLoading(true);
        setMyInvoices(null);
        setCriteria(Object.assign({}, DEFAULT_CRITERIA, criteria.pageSize));
        setCriteriaCount(0);
        const parsedCriteria = parseCriteria(DEFAULT_CRITERIA);
        buildParams(parsedCriteria);
        getMyInvoices(parsedCriteria, true, false);
    };

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeErrorModal} />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`Technical Liaison Officer`} />
            <NavigationTLO activeTitle={`My Applications`} />
            <MainWrapper>
                <FormWrapper paddingFormContent={'sm'}>
                    {userRoles.indexOf('WETLO') === -1 &&
                    userRoles.indexOf('TLOOF') === -1 &&
                    userRoles.indexOf('EOADM') === -1 ? (
                        <div style={{paddingTop: 20}}>
                            <b style={{color: 'red'}}>
                                <span className="ng-scope">{`You have no permissions to access this page.`}</span>
                            </b>
                        </div>
                    ) : (
                        <>
                            <SubMenuTabs tabId={'MY_INVOICES'} loading={loading} menuLinks={getMenuLinks()} />
                            <MyInvoicesFilterCriteriaArea
                                criteria={criteria}
                                updateCriteriaValue={updateCriteriaValue}
                                search={search}
                                resetCriteria={resetCriteria}
                            />
                        </>
                    )}
                </FormWrapper>
                {(userRoles.indexOf('WETLO') !== -1 ||
                    userRoles.indexOf('TLOOF') !== -1 ||
                    userRoles.indexOf('EOADM') !== -1) && (
                    <MyInvoicesTableArea
                        intl={props.intl}
                        count={count}
                        timestamp={timestamp}
                        criteria={criteria}
                        myInvoices={myInvoices}
                        printExcel={printExcel}
                        updateCriteriaValue={updateCriteriaValue}
                    />
                )}
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(MyInvoicesPage));
