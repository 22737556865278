import React from 'react';
import styles from './ModalMergeLabelsVersion2.module.scss';
import {ModalCustomVersion2} from '../../../commonModals';
import {LoadingBar} from '../../../componentsLayout';
import {
    apiLabelLabelTargets,
    apiLabelTranslations,
    apiMergeLabels,
    apiReplaceLabel,
} from './ModalMergeLabelsVersion2Service';
import CustomTable from '~components/CustomTable';
import BackOfficeLabelsActionButtons from '../BackOfficeLabelsActionButtons';
import ModalMergeLabelsVersion2ActionButtons from './ModalMergeLabelsVersion2ActionButtons.js';
import OPTIONS_OFFICE from '../data/OPTIONS_OFFICE.json';
import {Button} from '../../../componentsFormV2';

const RESULT_FIELDS_TARGETS_ALL = ['labelId', 'officeId', 'value'];

const RESULT_FIELDS_TRANSLATIONS_ALL = ['labelId', 'value'];

const OFFICE = {
    CPVO: 1,
    NAK: 21,
};
export default class ModalMergeLabelsVersion2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            translationLabels: null,
            targetLabels: null,
            selectDefault: {},
            selectable: false,
            loading: true,
            hash: {},
            currentSelectedId: null,
            selectedTranslations: [],
            merging: false,
        };
    }

    componentDidMount() {
        this.loadJSONs();
    }

    componentDidUpdate(prevProps) {
        prevProps.refreshModalMergeLabels !== this.props.refreshModalMergeLabels &&
            this.setState({loading: true}, this.loadJSONs);
    }

    loadJSONs = () => {
        const labelIds = this.props.selectedLabelIds.join(',');
        const promises = [this.loadTargets(labelIds), this.loadTranslations(labelIds)];
        Promise.all(promises)
            .catch(error => LOG`Error loading data: ${error}`)
            .then(() => this.setState({loading: false}));
    };

    loadTargets = labelIds => {
        return apiLabelLabelTargets(labelIds).then(jsonResponse => {
            if (jsonResponse && jsonResponse.data && jsonResponse.data.labels) {
                const CPVOTargetLabels = (jsonResponse.data.labels || []).filter(i => i.officeId === OFFICE.CPVO);
                const selectable = CPVOTargetLabels.length !== 1;
                const selectDefault = {};
                let currentSelectedId = null;
                if (CPVOTargetLabels && CPVOTargetLabels.length > 0) {
                    currentSelectedId = CPVOTargetLabels[0].labelId;
                    selectDefault[`${CPVOTargetLabels[0].labelId}-${CPVOTargetLabels[0].languageId}`] = true;
                } else {
                    currentSelectedId = jsonResponse.data.labels[0].labelId;
                    selectDefault[
                        `${jsonResponse.data.labels[0].labelId}-${jsonResponse.data.labels[0].languageId}`
                    ] = true;
                }
                this.setState(
                    Object.assign(
                        {},
                        {targetLabels: jsonResponse.data.labels, selectDefault, currentSelectedId, selectable},
                        jsonResponse.data.labels.filter(i => i.officeId === OFFICE.CPVO).length > 0 && {
                            CPVOTargetInTheList: true,
                        }
                    )
                );
            }
        });
    };

    loadTranslations = labelIds => {
        return apiLabelTranslations(labelIds).then(jsonResponse => {
            if (jsonResponse && jsonResponse.data && jsonResponse.data.labels) {
                const hash = {};
                ((jsonResponse.data && jsonResponse.data.labels) || []).forEach(label => {
                    if (label.languageId) {
                        hash[label.languageId] = label.languageId;
                    }
                });
                this.setState({translationLabels: jsonResponse.data.labels, groupHash: hash});
            }
        });
    };

    merge = () => {
        this.setState({merging: true}, () => {
            const requestTranslationMerge = {};
            this.state.selectedTranslations.map(selectedTranslation => {
                const selectedTranslationArray = selectedTranslation.split('-');
                const labelId = parseInt(selectedTranslationArray[0]);
                const languageId = selectedTranslationArray[1];
                if (labelId !== this.state.currentSelectedId) {
                    requestTranslationMerge[languageId] = {
                        previousLabelId: this.state.currentSelectedId,
                        newLabelId: labelId,
                    };
                }
            });
            (Object.keys(requestTranslationMerge).length > 0
                ? apiMergeLabels(requestTranslationMerge)
                : Promise.resolve({data: 'OK'})
            )
                .then(response => {
                    if (response.data === 'OK') {
                        const promises = [];
                        this.state.targetLabels.forEach(targetLabel => {
                            if (targetLabel.labelId !== this.state.currentSelectedId) {
                                promises.push(
                                    new Promise((resolve, reject) => {
                                        apiReplaceLabel({
                                            previousLabelId: targetLabel.labelId,
                                            newLabelId: this.state.currentSelectedId,
                                        })
                                            .then(resolve)
                                            .catch(reject);
                                    })
                                );
                            }
                        });
                        return Promise.all(promises);
                    }
                })
                .catch(error => LOG`error: ${error}`)
                .then(() => {
                    this.setState({merging: false}, () => this.close({reload: true}));
                });
        });
    };

    showModalLabel = (labelId, typeId) =>
        this.props.showModalLabel && !isNaN(labelId) && this.props.showModalLabel(labelId, typeId);

    showModalLabelUsedIn = labelId =>
        this.props.showModalLabelUsedIn && !isNaN(labelId) && this.props.showModalLabelUsedIn(labelId);

    showModalPreview = valueHTML => this.props.showModalPreview && this.props.showModalPreview(valueHTML);

    close = reload => this.props.close && this.props.close(reload);

    getTargetRowClass = rowObject => {
        if (this.state.CPVOTargetInTheList && rowObject.officeId === OFFICE.NAK) {
            return styles.labelYellow;
        } else if (rowObject.labelId === this.state.currentSelectedId) {
            return styles.labelGreen;
        } else {
            return null;
        }
    };

    getTranslationRowClass = rowObject => {
        let rowClass = null;
        if (rowObject.labelId === this.state.currentSelectedId) {
            rowClass = styles.labelGreen;
        }

        this.state.selectedTranslations.forEach(selectedTranslation => {
            const selectedTranslationArray = selectedTranslation.split('-');
            const selectedLabelId = parseInt(selectedTranslationArray[0]);
            const selectedLanguageId = selectedTranslationArray[1];
            if (selectedLanguageId === rowObject.languageId && selectedLabelId !== this.state.currentSelectedId) {
                rowClass = null;
                if (selectedLabelId === rowObject.labelId) {
                    rowClass = styles.labelYellow;
                }
            }
        });

        // this.state.selectedTranslations.forEach(selectedTranslation => {
        //     const selectedTranslationArray = selectedTranslation.split('-');
        //     // const selectedLabelId = parseInt(selectedTranslationArray[0]);
        //     const selectedLanguageId = selectedTranslationArray[1];
        //     if (selectedLanguageId === rowObject.languageId && rowObject.labelId !== this.state.currentSelectedId) {
        //         rowClass = styles.labelYellow;
        //     }
        // });
        return rowClass;
    };

    getOfficeNameById = officeId => {
        const selectedOffice = OPTIONS_OFFICE.filter(o => parseInt(o.id) === officeId);
        if (selectedOffice.length > 0) {
            return selectedOffice[0].value;
        } else {
            return officeId;
        }
    };

    onSelectedTargetLabelChange = selectedTarget => {
        this.setState({
            currentSelectedId: selectedTarget.length > 0 ? parseInt(selectedTarget[0].split('-')[0]) : null,
        });
    };

    onSelectedTranslationLabelChange = selectedTranslations => {
        this.setState({selectedTranslations});
    };

    onSelectionBusinessRule = rowId => {
        let pass = true;
        const selectedLabelId = parseInt(rowId.split('-')[0]);
        if (this.state.CPVOTargetInTheList) {
            (this.state.targetLabels || []).forEach(targetLabel => {
                if (selectedLabelId === targetLabel.labelId && targetLabel.officeId === OFFICE.NAK) {
                    pass = false;
                }
            });
        }
        return pass;
    };

    render() {
        const actions = BackOfficeLabelsActionButtons(
            this.props,
            this.showModalLabel,
            this.showModalLabelUsedIn,
            this.showModalPreview,
            this.openModalConfirm
        );
        const translationActions = ModalMergeLabelsVersion2ActionButtons(this.props, this.showModalPreview);
        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={`Merge labels`}
                body={
                    <>
                        <div>
                            <h3 style={{paddingLeft: 15}}>{`Please choose target label:`}</h3>
                            {this.state.loading === false ? (
                                <React.Fragment>
                                    {this.state.targetLabels.length > 0 ? (
                                        <React.Fragment>
                                            <CustomTable
                                                {...this.props}
                                                group={{
                                                    id: 'languageId',
                                                    label: 'languageId',
                                                    hash: Object.keys(this.state.groupHash),
                                                    noSelectChildren: true,
                                                    uniqueKey: 'languageId',
                                                }}
                                                selectable={this.state.selectable}
                                                noSelectAll={true}
                                                noUnselectAll={true}
                                                onSelectedChange={this.onSelectedTargetLabelChange}
                                                selectDefault={this.state.selectDefault}
                                                selectionBusinessRule={this.onSelectionBusinessRule}
                                                notSortable={RESULT_FIELDS_TARGETS_ALL}
                                                tableName={'mergeLabels'}
                                                tableType={'OBJECT'}
                                                tableSource={this.state.targetLabels}
                                                timestamp={this.state.timestamp}
                                                dataFilter={null}
                                                id={['labelId', 'languageId']}
                                                setLastCursor={null}
                                                resultFieldsAll={RESULT_FIELDS_TARGETS_ALL}
                                                resultFieldsDefault={RESULT_FIELDS_TARGETS_ALL}
                                                intl={this.props.intl}
                                                defaultOrder={'languageId'}
                                                reverseOrder={true}
                                                filterFunctions={null}
                                                actions={actions}
                                                formatFunctions={{
                                                    officeId: this.getOfficeNameById,
                                                }}
                                                rowClass={this.getTargetRowClass}
                                            />
                                        </React.Fragment>
                                    ) : (
                                        <div className="form-group">
                                            <b
                                                className="col-sm-offset-2 col-sm-10"
                                                style={{color: 'red'}}
                                                data-translate=""
                                            >
                                                <span className="ng-scope">No result found</span>
                                            </b>
                                        </div>
                                    )}
                                </React.Fragment>
                            ) : (
                                <LoadingBar />
                            )}
                            <h3
                                style={{paddingLeft: 15, paddingTop: 30}}
                            >{`Please select alternative translation if you want other different from the target:`}</h3>
                            {this.state.loading === false ? (
                                <>
                                    {this.state.translationLabels.length > 0 ? (
                                        <CustomTable
                                            {...this.props}
                                            group={{
                                                id: 'languageId',
                                                label: 'languageId',
                                                hash: Object.keys(this.state.groupHash),
                                                noSelectChildren: true,
                                                uniqueKey: 'languageId',
                                            }}
                                            selectable={true}
                                            noSelectAll={true}
                                            noUnselectAll={true}
                                            onSelectedChange={this.onSelectedTranslationLabelChange}
                                            notSortable={RESULT_FIELDS_TRANSLATIONS_ALL}
                                            tableName={'mergeLabels'}
                                            tableType={'OBJECT'}
                                            tableSource={this.state.translationLabels}
                                            timestamp={this.state.timestamp}
                                            dataFilter={null}
                                            id={['labelId', 'languageId']}
                                            setLastCursor={null}
                                            resultFieldsAll={RESULT_FIELDS_TRANSLATIONS_ALL}
                                            resultFieldsDefault={RESULT_FIELDS_TRANSLATIONS_ALL}
                                            intl={this.props.intl}
                                            defaultOrder={'languageId'}
                                            reverseOrder={true}
                                            filterFunctions={null}
                                            actions={translationActions}
                                            rowClass={this.getTranslationRowClass}
                                        />
                                    ) : (
                                        <div className="form-group">
                                            <b
                                                className="col-sm-offset-2 col-sm-10"
                                                style={{color: 'red'}}
                                                data-translate=""
                                            >
                                                <span className="ng-scope">No result found</span>
                                            </b>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <LoadingBar />
                            )}
                        </div>
                        <div style={{clear: 'both'}}>{}</div>
                    </>
                }
                footer={
                    this.state.merging ? (
                        <LoadingBar />
                    ) : (
                        <>
                            <Button clickAction={this.close} icon={'close'} variation={'danger'}>
                                {`Close`}
                            </Button>
                            <Button
                                clickAction={this.merge}
                                disabled={!this.state.currentSelectedId}
                                icon={'arrowRight'}
                            >
                                {`Merge`}
                            </Button>
                        </>
                    )
                }
            />
        );
    }
}
