import React, {useState} from 'react';
import cx from 'classnames';
import styles from './ModalSummaryOfAnswersForInventoryNumberFieldForShowHide.module.scss';
import {ArrowContainer, Popover} from 'react-tiny-popover';

interface ModalSummaryOfAnswersForInventoryNumberFieldForShowHideProps {
    fieldData: string | null;
    maxLines: number;
}

export default function ModalSummaryOfAnswersForInventoryNumberFieldForShowHide(
    props: ModalSummaryOfAnswersForInventoryNumberFieldForShowHideProps
) {
    const [isPopoverOpen, setPopover] = useState(false);
    const [isTextOpen, setTextOpen] = useState(false);

    const remarkMatch = props.fieldData && props.fieldData.match(/\b[\w']+(?:[^\w\n]+[\w']+){0,2}\b/g);
    const expandable = remarkMatch && remarkMatch.length > props.maxLines;
    const onSetTextOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: boolean) => {
        if (expandable) {
            event.stopPropagation();
            setTextOpen(value);
        }
    };

    const onSetPopover = (value: boolean) => expandable && setPopover(value);

    return (
        <Popover
            containerStyle={{zIndex: '1056'}}
            isOpen={isPopoverOpen}
            positions={'left'}
            onClickOutside={() => onSetPopover(!isPopoverOpen)}
            content={({position, childRect, popoverRect}) => (
                <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'black'}
                    arrowSize={5}
                    arrowStyle={{opacity: 0.7, zIndex: 1056}}
                >
                    <div onClick={() => onSetPopover(!isPopoverOpen)}>
                        <div
                            className="custom-popover"
                            style={{
                                background: '#e8e8e8',
                                color: 'black',
                                padding: '5px',
                                borderRadius: '4px',
                                maxWidth: '100%',
                                opacity: 0.9,
                                borderColor: 'black',
                                borderWidth: 1,
                                borderStyle: 'solid',
                            }}
                        >
                            {remarkMatch && remarkMatch.map((line, idx) => <div key={idx++}>{line}</div>)}
                        </div>
                    </div>
                </ArrowContainer>
            )}
        >
            <div
                onMouseEnter={() => onSetPopover(true)}
                onMouseLeave={() => onSetPopover(false)}
                onClick={event => onSetTextOpen(event, !isTextOpen)}
                className={styles.clickable}
            >
                <div style={{cursor: 'pointer'}}>
                    {remarkMatch &&
                        remarkMatch.map((line, index) =>
                            index < props.maxLines || isTextOpen ? (
                                <div key={line} style={{cursor: 'pointer'}}>
                                    {line}
                                </div>
                            ) : null
                        )}
                </div>
                {expandable ? (
                    isTextOpen ? (
                        <div
                            className={cx(styles.link, styles.clickable)}
                            style={{cursor: 'pointer'}}
                            onClick={event => onSetTextOpen(event, false)}
                        >
                            {'less...'}
                        </div>
                    ) : (
                        <div
                            className={cx(styles.link, styles.clickable)}
                            style={{cursor: 'pointer'}}
                            onClick={event => onSetTextOpen(event, true)}
                        >
                            {'more...'}
                        </div>
                    )
                ) : null}
            </div>
        </Popover>
    );
}
