import React from 'react';
import {FileSearchProps} from './Props/FileSearchProps';
import FileSearchTableArea from './Components/FileSearchTableGridArea/FileSearchTableArea';
import FileSearchFilterCriteriaArea from './Components/FileSearchFilterCriteriaArea/FileSearchFilterCriteriaArea';

export default function FileSearch(props: FileSearchProps) {
    return (
        <>
            <FileSearchFilterCriteriaArea
                criteria={props.criteria}
                updateCriteriaValue={props.updateCriteriaValue}
                search={props.search}
                resetCriteria={props.resetCriteria}
                showAdminIcon={props.showAdminIcon}
            />
            <FileSearchTableArea
                intl={props.intl}
                documentsList={props.documentsList}
                resultFields={props.resultFields}
                timestamp={props.timestamp}
                downloadFile={props.downloadFile}
            />
        </>
    );
}
