import {API_SERVERLESS, postFetch, getFetch} from '~commonApi';
import {joinParams} from '~utils';
import {ILabelsSearchResponse} from '../../../types';

export interface IPayloadQuestionCreateOrUpdate {
    CPVOCode: string | null;
    UPOVCode: string | null;
    btnAddMsg: {
        id: number | null;
    };
    btnDelMsg: {
        id: number | null;
    };
    confidential: boolean;
    core: any;
    formId?: number;
    general: boolean;
    groupId: number;
    groupingCharacteristic: boolean;
    helpMessage: {
        id: number | null;
    };
    idOrigin: any;
    jsonMapping: string | null;
    logics: any[];
    mandatory: boolean;
    mappingCode: string | null;
    modifDate: string | null;
    nameMessage: {
        id: number | null;
    };
    office: any;
    order: string;
    questionId: number | null;
    remarkMessage: {
        id: number | null;
    };
    responseAllowed: number;
    shortDisplay: boolean;
    status: number;
    successiveScoreOnly: boolean;
    templateId?: number;
    typeQuestion: any;
    user: any;
}

export function apiBackOfficeFormQuestionCreateOrUpdate(JSONRequest: IPayloadQuestionCreateOrUpdate) {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formQuestionCreateOrUpdate`, true, JSONRequest);
}

export interface IResponseGetGroup {
    id: number;
    message: string;
    remark: string | null;
    order: string;
}

export function apiBackOfficeFormGroupsGet(jsonRequest: {language: string} | null): Promise<{
    groups: IResponseGetGroup[];
}> {
    let queryParams = jsonRequest ? `?${joinParams(jsonRequest)}` : '';
    return getFetch(`${API_SERVERLESS}/backOffice/v3/formGroupsGet${queryParams}`, true);
}

export function apiBackOfficeLabels({
    questionSearchTerm,
    languageId = 'EN',
    officeId,
}: {
    questionSearchTerm: string;
    languageId: string;
    officeId: number;
}): Promise<ILabelsSearchResponse> {
    return getFetch(
        `${API_SERVERLESS}/backOffice/v2/labels?withHTML=true&officeId=${officeId}&languageId=${languageId}&typeId=3&contains=${questionSearchTerm}&containsFilter=start&order=value`,
        true
    );
}

export function apiBackOfficeUpdateLabels({
    labelValue,
    languageId = 'EN',
}: {
    labelValue: string;
    languageId: string;
}): Promise<{
    data: number;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v2/updateLabels/3`, true, {
        [languageId]: {
            labelId: 0,
            labelValue: encodeURIComponent(labelValue),
            labelValueHTML: encodeURIComponent(labelValue),
        },
    });
}
