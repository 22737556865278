import React from 'react';
import Navigation from '../componentsLayout/Navigation';
import CommunicationCentreCounter from './components/CommunicationCentreCounter';

function NavigationMyPVR() {
    return (
        <Navigation
            links={[
                {to: '/dashboard', title: 'Dashboard'},
                {
                    to: '/myApplications',
                    title: 'Applications',
                },
                {
                    to: '/denomination',
                    title: 'Denominations',
                },
                {
                    to: '/myFees',
                    title: 'Fees',
                },
                {
                    to: '/myDocuments',
                    title: 'Documents',
                },
                {
                    to: '/applications',
                    title: 'Online Applications',
                    style: {color: 'rgb(0, 70, 121)', fontWeight: 'bold'},
                },
            ]}
            linksRight={[{Component: CommunicationCentreCounter}]}
        />
    );
}

export default NavigationMyPVR;
