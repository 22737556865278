import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export function apiCommunicationCentreClientSearchDraft(
    criteria: any,
    DEFAULT_CRITERIA: any = {},
    excelTranslations?: boolean
) {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return (criteria.excel ? postFetch : getFetch)(
        `${API_SERVERLESS}/communicationCentreClient/v4/CCCDS/draft/search?${requestParams.join('&')}`,
        true,
        excelTranslations
    );
}

export function apiCommunicationCentreClientDraftDelete(communicationId: number): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/communicationCentreClient/v4/CCCDDM/draft/deleteMessage`, true, {
        communicationId,
    });
}
