import {API_SERVERLESS, postForm, getFetch} from '~commonApi';
import {computeGetUploadPreSignedURLPromises, postFileToPreSignedURLRequest} from '../../../utils/requests';

export function apiDocumentUpload({
    document,
    applicationRequestCode,
}: {
    document: any;
    applicationRequestCode: string;
}): Promise<{
    data: string;
}> {
    return computeGetUploadPreSignedURLPromises({}).then(
        ({presignedUrl, identifier}: {presignedUrl: string; identifier: string}) => {
            return postFileToPreSignedURLRequest({preSignedURL: presignedUrl, file: document.file}).then(() => {
                const formData = new FormData();
                formData.append('files[]', identifier);
                formData.append('filename', encodeURIComponent(document.filename));
                formData.append(`applicationRequestCode`, applicationRequestCode);
                return postForm(`${API_SERVERLESS}/online/v3/attachmentUpload`, true, formData);
            });
        }
    );
}

export function apiDocumentDownload({
    filename,
    applicationRequestCode,
}: {
    filename: string;
    applicationRequestCode: string;
}): Promise<{
    signedUrl: string;
}> {
    return getFetch(
        `${API_SERVERLESS}/online/v3/attachmentDownload/${applicationRequestCode}/${encodeURIComponent(filename)}`,
        true
    );
}
