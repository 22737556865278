import React, {useState} from 'react';
import cx from 'classnames';
import styles from './QuestionModalSearchAndAddTableResponses.module.scss';
import {ArrowContainer, Popover} from 'react-tiny-popover';

const MAX_LINES = 3;

export default function QuestionModalSearchAndAddTableResponses({fieldData}: any) {
    const [isPopoverOpen, setPopover] = useState(false);
    const [isTextOpen, setTextOpen] = useState(false);

    const expandable = fieldData.split('\n').length > MAX_LINES;

    const onSetTextOpen = (event: any, value: any) => {
        if (expandable) {
            event.stopPropagation();
            setTextOpen(value);
        }
    };

    const onSetPopover = (value: any) => expandable && setPopover(value);

    return (
        <Popover
            isOpen={isPopoverOpen}
            positions={'left'}
            onClickOutside={() => onSetPopover(!isPopoverOpen)}
            content={({position, childRect, popoverRect}) => (
                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'black'}
                    arrowSize={5}
                    arrowStyle={{opacity: 0.7}}
                >
                    <div onClick={() => onSetPopover(!isPopoverOpen)}>
                        <div
                            className="custom-popover"
                            style={{
                                background: '#e8e8e8',
                                color: 'black',
                                padding: '5px',
                                borderRadius: '4px',
                                maxWidth: '100%',
                                opacity: 0.9,
                                borderColor: 'black',
                                borderWidth: 1,
                                borderStyle: 'solid',
                            }}
                        >
                            {fieldData.split('\n').map((line: any) => (
                                <div key={`search_and_add_line_${line}`}>{line}</div>
                            ))}
                        </div>
                    </div>
                </ArrowContainer>
            )}
        >
            <div
                onMouseEnter={() => onSetPopover(true)}
                onMouseLeave={() => onSetPopover(false)}
                onClick={event => onSetTextOpen(event, !isTextOpen)}
                className={styles.clickable}
            >
                <div style={{cursor: 'pointer'}}>
                    {fieldData.split('\n').map((line: any, index: number) => {
                        let keyIndex = `i-${index}`;
                        return index < MAX_LINES || isTextOpen ? (
                            <div key={`${line}-i-${keyIndex}`} style={{cursor: 'pointer'}}>
                                {line}
                            </div>
                        ) : null;
                    })}
                </div>
                {expandable ? (
                    isTextOpen ? (
                        <div
                            className={cx(styles.link, styles.clickable)}
                            style={{cursor: 'pointer'}}
                            onClick={event => onSetTextOpen(event, false)}
                        >
                            {'less...'}
                        </div>
                    ) : (
                        <div
                            className={cx(styles.link, styles.clickable)}
                            style={{cursor: 'pointer'}}
                            onClick={event => onSetTextOpen(event, true)}
                        >
                            {'more...'}
                        </div>
                    )
                ) : null}
            </div>
        </Popover>
    );
}
