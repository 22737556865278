import React, {useState} from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import Checkbox from '~components/Checkbox';
import {Button} from '../../../componentsFormV2';

const filtersMap = {
    APPLICATION_COVER_LETTER: '21',
    ENTITLEMENT_DEED_OF_ASSIGNMENT: '23',
    OTHER_CONFIDENTIAL_DOCUMENTS: '24',
    OTHER_DOCUMENTS: '25',
    PHOTO: '26',
    PROCEDURAL_REPRESENTATIVE_CREDENTIALS: '27',
    PRIORITY: '28',
    FINANCIAL_INFORMATION: '41',
};

const ModalLogicTypeUploadFilter = ({close, onCreateOrUpdateLogicType, responseLogicValues, responseLogicType}) => {
    const {logicParam} = responseLogicValues || {};
    const {name} = responseLogicType || {};

    //'filter:23,26;' -> ['23', '26']
    let filters = logicParam ? logicParam.replace('filter:', '').slice(0, -1).split(',') : [];

    const [applicationCoverLetter, setApplicationCoverLetter] = useState(
        filters.includes(filtersMap['APPLICATION_COVER_LETTER'])
    );
    const [entitlementDeedOfAssignment, setEntitlementDeedOfAssignment] = useState(
        filters.includes(filtersMap['ENTITLEMENT_DEED_OF_ASSIGNMENT'])
    );
    const [otherConfidentialDocuments, setOtherConfidentialDocuments] = useState(
        filters.includes(filtersMap['OTHER_CONFIDENTIAL_DOCUMENTS'])
    );
    const [otherDocuments, setOtherDocuments] = useState(filters.includes(filtersMap['OTHER_DOCUMENTS']));
    const [photo, setPhoto] = useState(filters.includes(filtersMap['PHOTO']));
    const [proceduralRepresentativeCredentials, setProceduralRepresentativeCredentials] = useState(
        filters.includes(filtersMap['PROCEDURAL_REPRESENTATIVE_CREDENTIALS'])
    );
    const [priority, setPriority] = useState(filters.includes(filtersMap['PRIORITY']));
    const [financialInformation, setFinancialInformation] = useState(
        filters.includes(filtersMap['FINANCIAL_INFORMATION'])
    );

    const [checkUncheckAllDocumentTypes, setCheckUncheckAllDocumentTypes] = useState(false);

    const onCheckUncheckAllDocumentTypes = () => {
        setApplicationCoverLetter(!checkUncheckAllDocumentTypes);
        setEntitlementDeedOfAssignment(!checkUncheckAllDocumentTypes);
        setOtherConfidentialDocuments(!checkUncheckAllDocumentTypes);
        setOtherDocuments(!checkUncheckAllDocumentTypes);
        setPhoto(!checkUncheckAllDocumentTypes);
        setProceduralRepresentativeCredentials(!checkUncheckAllDocumentTypes);
        setPriority(!checkUncheckAllDocumentTypes);
        setFinancialInformation(!checkUncheckAllDocumentTypes);

        setCheckUncheckAllDocumentTypes(!checkUncheckAllDocumentTypes);
    };

    const getParametersValue = () => {
        let currentFilters = [];
        applicationCoverLetter && currentFilters.push(filtersMap['APPLICATION_COVER_LETTER']);
        entitlementDeedOfAssignment && currentFilters.push(filtersMap['ENTITLEMENT_DEED_OF_ASSIGNMENT']);
        otherConfidentialDocuments && currentFilters.push(filtersMap['OTHER_CONFIDENTIAL_DOCUMENTS']);
        otherDocuments && currentFilters.push(filtersMap['OTHER_DOCUMENTS']);
        photo && currentFilters.push(filtersMap['PHOTO']);
        proceduralRepresentativeCredentials && currentFilters.push(filtersMap['PROCEDURAL_REPRESENTATIVE_CREDENTIALS']);
        priority && currentFilters.push(filtersMap['PRIORITY']);
        financialInformation && currentFilters.push(filtersMap['FINANCIAL_INFORMATION']);

        if (!currentFilters.length) {
            return null;
        }

        return `filters:${currentFilters.join(',')};`;
    };

    return (
        <ModalCustomVersion2
            close={close}
            header={<>{name}</>}
            body={
                <>
                    <div>
                        <p style={{margin: 0}}>
                            <strong>{`Document types allowed for upload:`}</strong>
                        </p>
                        <Checkbox
                            clickAction={() => setApplicationCoverLetter(!applicationCoverLetter)}
                            label={`Application cover letter`}
                            value={applicationCoverLetter}
                            double={true}
                        />
                        <Checkbox
                            clickAction={() => setEntitlementDeedOfAssignment(!entitlementDeedOfAssignment)}
                            label={`Entitlement Deed of assignment`}
                            value={entitlementDeedOfAssignment}
                            double={true}
                        />

                        <Checkbox
                            clickAction={() => setOtherConfidentialDocuments(!otherConfidentialDocuments)}
                            label={`Other confidential documents`}
                            value={otherConfidentialDocuments}
                            double={true}
                        />
                        <Checkbox
                            clickAction={() => setOtherDocuments(!otherDocuments)}
                            label={`Other documents`}
                            value={otherDocuments}
                            double={true}
                        />
                        <Checkbox clickAction={() => setPhoto(!photo)} label={`Photo`} value={photo} double={true} />
                        <Checkbox
                            clickAction={() =>
                                setProceduralRepresentativeCredentials(!proceduralRepresentativeCredentials)
                            }
                            label={`Procedural Representative Credentials`}
                            value={proceduralRepresentativeCredentials}
                            double={true}
                        />
                        <Checkbox
                            clickAction={() => setPriority(!priority)}
                            label={`Priority`}
                            value={priority}
                            double={true}
                        />
                        <Checkbox
                            clickAction={() => setFinancialInformation(!financialInformation)}
                            label={`Financial information`}
                            value={financialInformation}
                            double={true}
                        />
                        <div style={{clear: 'both'}} />
                    </div>
                    <hr />
                    <div style={{float: 'left', paddingBottom: 20}}>
                        <Checkbox
                            clickAction={onCheckUncheckAllDocumentTypes}
                            label={`Check/Uncheck all document types`}
                            value={checkUncheckAllDocumentTypes}
                            double={true}
                        />
                        <div style={{clear: 'both'}} />
                    </div>
                </>
            }
            footer={
                <>
                    <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                    <Button
                        icon={'arrowRight'}
                        clickAction={() => onCreateOrUpdateLogicType(getParametersValue(), 'test')}
                    >{`Save`}</Button>
                </>
            }
        />
    );
};

export default ModalLogicTypeUploadFilter;
