import React from 'react';
import VFModalClass from '../VFModalClass/VFModalClass';
import {Button} from '../../componentsFormV2';
import CustomTable from '~components/CustomTable';
import {FormFooter, HeaderLoading} from '../../componentsLayout';
import {ModalCustomVersion2} from '../../commonModals';
import TextLabelInput from '~components/TextLabelInput';
import TextInput from '~components/TextInput';
import axiosGraphQLClient from '../../utils/axiosGraphQLClient';
import graphQLClientInstance from '../../utils/axiosGraphQLClient';
import {faCheckCircle, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {getSearchClassesQuery} from '../VFUPOVClasses/VFUPOVClassesService';
import {injectIntl} from 'react-intl';
import styles from './ModalSelectClasses.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import XLSX from 'xlsx';

const TABLE_NAME = 'varietyFinderUPOVClasses';

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

const RESULT_FIELDS_ALL = ['classCode', 'UPOVClass', 'className', 'remark'];

const DEFAULT_CRITERIA = {
    country: 'All',
    pageNumber: 1,
    pageSize: parseInt(localStorage.getItem(`pageSize${TABLE_NAME}`) || '50'),
    order: null,
    reverse: false,
    refresh: false,
    registerType: 'All',
};

interface IProps {
    close: any;
    intl: any;
    onButtonSelectClick: any;
    tableId: string;
}

interface IState {
    count: number;
    criteria: TDefaultCriteria;
    forceHideSearchList: boolean;
    isModalVFUPOVClassDetailsOpen: string | false;
    loading: number;
    responseUPOVClasses: any[];
    selectedClassCodes: string[];
    screenLoaded: boolean;
    textInputClassNameFilter: string;
    textInputClassNameValue: string;
    textLabelInputClassCodeSearchLoading: boolean;
    textLabelInputClassCodeCurrentFilter: string;
    textLabelInputClassCodeHash: any;
    textLabelInputClassCodeList: any[];
    textLabelInputClassCodeTimeoutId: any;
    textLabelInputClassCodeValue: string;
    textTimestamp: number;
    timestamp: number;
}

class ModalSelectClasses extends React.Component<IProps, IState> {
    skipSearching = false;

    constructor(props: IProps) {
        super(props);
        this.state = {
            count: 0,
            criteria: {...DEFAULT_CRITERIA},
            forceHideSearchList: false,
            isModalVFUPOVClassDetailsOpen: false,
            loading: 0,
            responseUPOVClasses: [],
            selectedClassCodes: [],
            screenLoaded: false,
            textInputClassNameFilter: 'start',
            textInputClassNameValue: '',
            textLabelInputClassCodeSearchLoading: false,
            textLabelInputClassCodeCurrentFilter: 'contains',
            textLabelInputClassCodeHash: {},
            textLabelInputClassCodeList: [],
            textLabelInputClassCodeTimeoutId: null,
            textLabelInputClassCodeValue: '',
            textTimestamp: Date.now(),
            timestamp: Date.now(),
        };
    }

    componentDidMount() {}

    updateCriteriaValue = (criteriaValue: any, callback?: any, refresh?: any) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = criteriaValue.pageNumber && prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.criteria.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse && prev.criteria.reverse !== criteriaValue.reverse;
                return {...prev, criteria};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) && refresh && this.search();
            }
        );
    };

    autoCompletePostData = (autoCompleteName: string, term: string, type: string, includeSynonyms = false) => {
        const synonyms = includeSynonyms ? `includeSynonyms:"1"` : '';

        return {
            query: `
    {
      ${autoCompleteName}(
        term: "${term.toLowerCase()}"
        type: "${type}"
        ${synonyms}
      )
    }`,
        };
    };

    searchClassCodeSuggestions = () => {
        if (!this.skipSearching) {
            this.setState({textLabelInputClassCodeSearchLoading: true}, () => {
                axiosGraphQLClient
                    .post(
                        '/graphql',
                        this.autoCompletePostData(
                            'autoCompleteClass',
                            this.state.textLabelInputClassCodeValue,
                            'contains',
                            false
                        )
                    )
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.autoCompleteClass
                        ) {
                            const nameHash: {[key: string]: string[]} = {};
                            JSONResponse.data.data.autoCompleteClass.forEach((suggestion: any) => {
                                // let key = suggestion && suggestion.name;
                                if (!nameHash[suggestion]) {
                                    nameHash[suggestion] = [];
                                }
                                nameHash[suggestion].push(suggestion);
                            });
                            const listByIdName: any = Object.keys(nameHash).map(key => ({
                                ID: nameHash[key].join(','),
                                NAME: key,
                            }));
                            this.setState({
                                forceHideSearchList: false,
                                textTimestamp: Date.now(),
                                textLabelInputClassCodeSearchLoading: false,
                                textLabelInputClassCodeHash: nameHash,
                                textLabelInputClassCodeList: listByIdName,
                            });
                        }
                    })
                    .catch(err => ERROR([err.message]));
            });
        }
    };

    getSelectDefault = (): {[key: string]: boolean} => {
        const selectedDefault: {[key: string]: boolean} = {};
        this.state.selectedClassCodes.forEach(classCode => (selectedDefault[classCode] = true));
        return selectedDefault;
    };

    tableNavigationButtons = () => (
        <div
            className={styles.navigationBarV2LeftItem}
            onClick={this.onTableNavigationButtonSelectClick}
            style={this.state.selectedClassCodes.length ? {} : {opacity: 0.4, cursor: 'not-allowed'}}
        >
            <FontAwesomeIcon style={{float: 'left'}} icon={faCheckCircle as any} color={'white'} />
            <div
                style={{
                    float: 'left',
                    paddingLeft: 5,
                }}
            >
                {`Select`}
            </div>
            <div style={{clear: 'both'}} />
        </div>
    );

    printExcel = () => {
        const resultGrid: any = [['Species Code', 'Class', 'Class name', 'Remark']];
        const searchValues: any = {};
        if (this.state.textLabelInputClassCodeValue) {
            searchValues.classCode = this.state.textLabelInputClassCodeValue;
            searchValues.classCodeSelect = this.state.textLabelInputClassCodeCurrentFilter;
        }
        if (this.state.textInputClassNameValue) {
            searchValues.className = this.state.textInputClassNameValue;
            searchValues.classNameSelect = this.state.textInputClassNameFilter;
        }
        const indexesNeeded = Math.ceil(this.state.count / 1000);
        let promises: any = [];
        for (let index = 0; index < indexesNeeded; index++) {
            promises.push(graphQLClientInstance.post('/graphql', getSearchClassesQuery(searchValues, 1000, index)));
        }
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                Promise.all(promises).then((JSONResponses: any) => {
                    JSONResponses.forEach((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.classesSearch &&
                            JSONResponse.data.data.classesSearch.data
                        ) {
                            JSONResponse.data.data.classesSearch.data.forEach(
                                ({classid, upovclass, classname, info}: any) => {
                                    resultGrid.push([classid, upovclass, classname, info]);
                                }
                            );
                        }
                    });
                    const workSheet = XLSX.utils.aoa_to_sheet(resultGrid);
                    const workBook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workBook, workSheet, 'UPOVClasses');
                    XLSX.writeFile(workBook, `UPOVClasses.xlsx`);
                    this.setState(prev => ({
                        loading: prev.loading - 1,
                    }));
                });
            }
        );
    };

    closeModalClass = () => this.setState({isModalVFUPOVClassDetailsOpen: false});

    openModalClass = (classCode: string) => this.setState({isModalVFUPOVClassDetailsOpen: classCode});

    search = () => {
        const values: any = {};
        if (this.state.textLabelInputClassCodeValue) {
            values.classCode = this.state.textLabelInputClassCodeValue;
            values.classCodeSelect = this.state.textLabelInputClassCodeCurrentFilter;
        }
        if (this.state.textInputClassNameValue) {
            values.className = this.state.textInputClassNameValue;
            values.classNameSelect = this.state.textInputClassNameFilter;
        }
        const tablePageSize = this.state.criteria.pageSize;
        const tablePageIndex = this.state.criteria.pageNumber - 1;
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                graphQLClientInstance
                    .post('/graphql', getSearchClassesQuery(values, tablePageSize, tablePageIndex))
                    .then(JSONResponse => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.classesSearch &&
                            JSONResponse.data.data.classesSearch.data
                        ) {
                            const {data, total} = JSONResponse.data.data.classesSearch || {};
                            const responseUPOVClasses = data.map(({classid, upovclass, classname, info}: any) => ({
                                classCode: classid,
                                UPOVClass: upovclass,
                                className: classname,
                                remark: info,
                            }));
                            this.setState(prev => ({
                                count: total,
                                loading: prev.loading - 1,
                                responseUPOVClasses,
                                screenLoaded: true,
                                timestamp: Date.now(),
                                selectedClassCodes: [],
                            }));
                        }
                    });
            }
        );
    };

    resetCriteria = () =>
        this.setState({
            textInputClassNameFilter: 'start',
            textInputClassNameValue: '',
            textLabelInputClassCodeTimeoutId: null,
            textLabelInputClassCodeSearchLoading: false,
            textLabelInputClassCodeCurrentFilter: 'equals',
            textLabelInputClassCodeHash: {},
            textLabelInputClassCodeList: [],
            textLabelInputClassCodeValue: '',
            timestamp: Date.now(),
        });

    onTextLabelInputClassCodeChange = (textLabelInputClassCodeValue: string) => {
        clearTimeout(this.state.textLabelInputClassCodeTimeoutId);
        this.setState({
            textLabelInputClassCodeValue,
            textLabelInputClassCodeHash: {},
            textLabelInputClassCodeList: [],
        });
        if (
            this.state.textLabelInputClassCodeCurrentFilter === 'equals' &&
            (textLabelInputClassCodeValue || '').length > 2
        ) {
            let newTimeoutId = setTimeout(() => {
                this.state.textLabelInputClassCodeValue && this.searchClassCodeSuggestions();
            }, 300);
            this.setState({
                textLabelInputClassCodeTimeoutId: newTimeoutId,
            });
        }
    };

    onTextLabelInputClassCodeFilterChange = ({
        target: {value: textLabelInputClassCodeCurrentFilter},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({textLabelInputClassCodeCurrentFilter});

    onTextLabelInputClassCodeSelectionChange = () => {
        this.skipSearching = true;
        this.setState({
            textLabelInputClassCodeList: [],
            textLabelInputClassCodeHash: {},
        });
        setTimeout(() => {
            this.skipSearching = false;
        }, 1000);
    };

    onTextInputClassNameFilterChange = ({
        target: {value: textInputClassNameFilter},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({textInputClassNameFilter});

    onTextInputClassNameChange = ({target: {value: textInputClassNameValue}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({textInputClassNameValue});

    onLinkButtonHelpClick = () => window.open('/help/en/Classes.html', '_blank');

    onSelectedChange = (selectedClassCodes: string[]) => this.setState({selectedClassCodes});

    onTableNavigationButtonSelectClick = () => {
        if (!this.state.selectedClassCodes.length) return;
        this.props.onButtonSelectClick(this.state.selectedClassCodes);
        this.props.close();
    };

    render() {
        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                {this.state.isModalVFUPOVClassDetailsOpen ? (
                    <VFModalClass classId={this.state.isModalVFUPOVClassDetailsOpen} close={this.closeModalClass} />
                ) : null}
                <ModalCustomVersion2
                    close={this.props.close}
                    header={`Search Classes`}
                    body={
                        <div>
                            <div className={styles.textLabelInputClassCodeWrap}>
                                <TextLabelInput
                                    buttonIcon={this.state.textLabelInputClassCodeSearchLoading && faSpinner}
                                    currentFilter={this.state.textLabelInputClassCodeCurrentFilter}
                                    double={true}
                                    filterContainsKeepJSONResponseSortOrder={true}
                                    filter={['equals', 'contains']}
                                    forceHideSearchList={this.state.forceHideSearchList}
                                    hideCounter={true}
                                    label={`Class Code`}
                                    listByIdName={this.state.textLabelInputClassCodeList}
                                    nameHash={this.state.textLabelInputClassCodeHash}
                                    onChange={this.onTextLabelInputClassCodeChange}
                                    onFilterChange={this.onTextLabelInputClassCodeFilterChange}
                                    onSelectionChange={this.onTextLabelInputClassCodeSelectionChange}
                                    popover={`Identifier of the class.`}
                                    selectedElements={{}}
                                    timestamp={this.state.textTimestamp}
                                    removeStyleClear={true}
                                    value={this.state.textLabelInputClassCodeValue}
                                />
                            </div>
                            <TextInput
                                currentFilter={this.state.textInputClassNameFilter}
                                double={true}
                                filter={true}
                                onChange={this.onTextInputClassNameChange}
                                onFilterChange={this.onTextInputClassNameFilterChange}
                                label={`Class Name`}
                                value={this.state.textInputClassNameValue}
                            />
                            <div style={{clear: 'both'}} />
                            <FormFooter>
                                <Button
                                    clickAction={this.onLinkButtonHelpClick}
                                    variation={'secondary'}
                                >{`Help`}</Button>
                                <Button
                                    clickAction={this.resetCriteria}
                                    variation={'secondary'}
                                >{`Clear fields`}</Button>
                                <Button clickAction={this.search}>{`Search`}</Button>
                            </FormFooter>
                            <div style={{clear: 'both'}} />
                            <div style={{minHeight: 'calc(100vh - 625px)'}}>
                                {this.state.screenLoaded ? (
                                    <CustomTable
                                        additionalNavigationButtons={this.tableNavigationButtons}
                                        count={this.state.count}
                                        defaultOrder={this.state.criteria.order}
                                        formatFunctions={{
                                            classCode: (classCode: string) => (
                                                <div
                                                    onClick={() => this.openModalClass(classCode)}
                                                    style={{
                                                        color: 'green',
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {classCode}
                                                </div>
                                            ),
                                        }}
                                        id={this.props.tableId}
                                        intl={this.props.intl}
                                        isNavigationButtonCompactOrDefaultViewEnabled={true}
                                        notSortable={RESULT_FIELDS_ALL}
                                        pageNumber={this.state.criteria.pageNumber}
                                        pageSize={this.state.criteria.pageSize}
                                        pagination={true}
                                        printExcel={this.printExcel}
                                        resultFieldsDefault={RESULT_FIELDS_ALL}
                                        reverseOrder={this.state.criteria.reverse}
                                        onSelectedChange={this.onSelectedChange}
                                        selectable={true}
                                        tableName={TABLE_NAME}
                                        tableType={'OBJECT'}
                                        tableSource={this.state.responseUPOVClasses}
                                        timestamp={this.state.timestamp}
                                        timestampSelected={this.state.timestamp}
                                        updateCriteriaValue={this.updateCriteriaValue}
                                        version={2}
                                        selectDefault={this.getSelectDefault()}
                                        noSelectAll={true}
                                    />
                                ) : null}
                            </div>
                            <div style={{clear: 'both'}} />
                        </div>
                    }
                    footer={<></>}
                />
            </>
        );
    }
}

export default injectIntl(ModalSelectClasses);
