import React from 'react';
import VFModalClass from '../VFModalClass/VFModalClass';
import VFModalSpecies from '../VFModalSpecies/VFModalSpecies';
import {Button} from '../../componentsFormV2';
import CustomTable from '~components/CustomTable';
import {FormFooter, HeaderLoading} from '../../componentsLayout';
import {ModalCustomVersion2} from '../../commonModals';
import TextLabelInput from '~components/TextLabelInput';
import TextInput from '~components/TextInput';
import {apiBackOfficeGetCropSectors, getSearchSpeciesQuery} from '../VFSpecies/VFSpeciesService';
import graphQLClientInstance from '../../utils/axiosGraphQLClient';
import axiosGraphQLClient from '../../utils/axiosGraphQLClient';
import {faCheckCircle, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {injectIntl} from 'react-intl';
import styles from './ModalSelectSpecies.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import XLSX from 'xlsx';
import {responseSpeciesMapToState} from '../VFSpecies/VFSpecies';

const TABLE_NAME = 'varietyFinderSpecies';

const RESULT_FIELDS_ALL = [
    'speciesCode',
    'classCode',
    'principalBotanicalName',
    'otherBotanicalNames',
    'otherName',
    'cropSector',
];

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

const DEFAULT_CRITERIA = {
    pageNumber: 1,
    pageSize: parseInt(localStorage.getItem(`pageSize${TABLE_NAME}`) || '50'),
};

interface IProps {
    close: any;
    intl: any;
    onButtonSelectClick: any;
}

interface IState {
    count: number;
    criteria: TDefaultCriteria;
    forceHideSearchList: boolean;
    isModalVFSpeciesClassDetailsOpen: any;
    isModalVFSpeciesSpeciesDetailsOpen: any;
    listByIdNameCropSectors: any;
    loading: number;
    nameHashCropSectors: any;
    responseSpecies: any;
    selectedLanguages: any;
    screenLoaded: boolean;
    selectedCropSectors: any;
    textInputOtherNameFilter: string;
    textInputOtherNameValue: string;
    textInputSpeciesCodeFilter: string;
    textInputSpeciesCodeValue: string;
    textLabelInputSpeciesLatinNameSearchLoading: boolean;
    textLabelInputSpeciesLatinNameCurrentFilter: string;
    textLabelInputSpeciesLatinNameHash: any;
    textLabelInputSpeciesLatinNameList: any[];
    textLabelInputSpeciesLatinNameTimeoutId: any;
    textLabelInputSpeciesLatinNameValue: string;
    timestamp: number;
    textTimestamp: number;
    selectedSpecies: any[];
}

class ModalSelectSpecies extends React.Component<IProps, IState> {
    skipSearching = false;

    constructor(props: IProps) {
        super(props);
        this.state = {
            count: 0,
            criteria: {...DEFAULT_CRITERIA},
            forceHideSearchList: false,
            isModalVFSpeciesClassDetailsOpen: false,
            isModalVFSpeciesSpeciesDetailsOpen: null,
            listByIdNameCropSectors: [],
            loading: 0,
            nameHashCropSectors: {},
            responseSpecies: [],
            screenLoaded: false,
            selectedCropSectors: {},
            selectedLanguages: {},
            textInputOtherNameFilter: 'contains',
            textInputOtherNameValue: '',
            textInputSpeciesCodeFilter: 'contains',
            textInputSpeciesCodeValue: '',
            textLabelInputSpeciesLatinNameSearchLoading: false,
            textLabelInputSpeciesLatinNameCurrentFilter: 'contains',
            textLabelInputSpeciesLatinNameHash: {},
            textLabelInputSpeciesLatinNameList: [],
            textLabelInputSpeciesLatinNameTimeoutId: null,
            textLabelInputSpeciesLatinNameValue: '',
            timestamp: Date.now(),
            textTimestamp: Date.now(),
            selectedSpecies: [],
        };
    }

    componentDidMount() {
        this.setState(prev => ({
            criteria: {...prev.criteria, pageSize: parseInt(localStorage.getItem(`pageSize${TABLE_NAME}`) || '50')},
        }));
        this.loadJSONs();
    }

    loadJSONs = () => {
        this.fetchBackOfficeCropSectors().catch(error => LOG([error.message]));
    };

    fetchBackOfficeCropSectors = () =>
        new Promise(resolve => {
            apiBackOfficeGetCropSectors()
                .then(JSONResponse => {
                    if (JSONResponse && JSONResponse.data) {
                        const nameHashCropSectors: any = {};
                        (JSONResponse.data || []).forEach((cropSectorElement: any) => {
                            if (!nameHashCropSectors[cropSectorElement.NAME]) {
                                nameHashCropSectors[cropSectorElement.NAME] = [];
                            }
                            nameHashCropSectors[cropSectorElement.NAME].push(cropSectorElement.ID);
                        });
                        const listByIdNameCropSectors = Object.keys(nameHashCropSectors).map(cropSectorName => ({
                            ID: nameHashCropSectors[cropSectorName].join(','),
                            NAME: cropSectorName,
                        }));
                        this.setState(
                            {
                                listByIdNameCropSectors,
                                nameHashCropSectors,
                                timestamp: Date.now(),
                            },
                            () => resolve([])
                        );
                    }
                })
                .catch((error: any) => {
                    ERROR([`fetchBackOfficeCropSectors: ${error.message}`]);
                });
        });

    updateCriteriaValue = (criteriaValue: any, callback?: any, refresh?: any) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = criteriaValue.pageNumber && prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.criteria.pageSize !== criteriaValue.pageSize;
                return {...prev, criteria};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged) && refresh && this.search();
            }
        );
    };

    tableNavigationButtons = () => (
        <div
            className={styles.navigationBarV2LeftItem}
            onClick={this.onTableNavigationButtonSelectClick}
            style={this.state.selectedSpecies.length ? {} : {opacity: 0.4, cursor: 'not-allowed'}}
        >
            <FontAwesomeIcon style={{float: 'left'}} icon={faCheckCircle as any} color={'white'} />
            <div
                style={{
                    float: 'left',
                    paddingLeft: 5,
                }}
            >
                {`Select`}
            </div>
            <div style={{clear: 'both'}} />
        </div>
    );

    getSelectDefault = (): {[key: string]: boolean} => {
        const selectedDefault: {[key: string]: boolean} = {};
        this.state.selectedSpecies.forEach(species => (selectedDefault[species] = true));
        return selectedDefault;
    };

    closeModalVFSpeciesSpeciesDetails = () => this.setState({isModalVFSpeciesSpeciesDetailsOpen: false});

    closeModalVFSpeciesClassDetails = () => this.setState({isModalVFSpeciesClassDetailsOpen: false});

    printExcel = () => {
        const resultGrid: any = [
            [
                'Species Code',
                'Class',
                'Principal botanical name',
                'Other botanical name(s)',
                'English',
                'French',
                'German',
                'Spanish',
                'Crop sector',
            ],
        ];
        const values: any = {};
        if (this.state.textLabelInputSpeciesLatinNameValue) {
            values.speciesLatinName = this.state.textLabelInputSpeciesLatinNameValue;
            values.speciesLatinNameSelect = this.state.textLabelInputSpeciesLatinNameCurrentFilter;
        }
        if (this.state.textInputSpeciesCodeValue) {
            values.speciesCode = this.state.textInputSpeciesCodeValue;
            values.speciesCodeSelect = this.state.textInputSpeciesCodeFilter;
        }
        if (this.state.textInputOtherNameValue) {
            values.commonName = this.state.textInputOtherNameValue;
            values.commonNameSelect = this.state.textInputOtherNameFilter;
        }
        if (this.state.selectedLanguages && Object.keys(this.state.selectedLanguages).length) {
            values.commonNameLanguage = {...this.state.selectedLanguages};
        }
        if (this.state.selectedCropSectors && Object.keys(this.state.selectedCropSectors).length) {
            values.speciesCropSector = {...this.state.selectedCropSectors};
        }
        const indexesNeeded = Math.ceil(this.state.count / 1000);
        let promises: any = [];
        for (let index = 0; index < indexesNeeded; index++) {
            promises.push(graphQLClientInstance.post('/graphql', getSearchSpeciesQuery(values, 1000, index)));
        }
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                Promise.all(promises).then((JSONResponses: any) => {
                    JSONResponses.forEach((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.speciesSearch &&
                            JSONResponse.data.data.speciesSearch.data
                        ) {
                            JSONResponse.data.data.speciesSearch.data.forEach(
                                ({
                                    class_data: classData,
                                    crop_sectors: cropSectors,
                                    species_id: speciesId,
                                    princ_bot: princeBot,
                                    en,
                                    de,
                                    es,
                                    fr,
                                    other_bot: otherBot,
                                }: any) => {
                                    resultGrid.push([
                                        speciesId,
                                        classData.map((classItem: any) => classItem.class_id).join(','),
                                        princeBot,
                                        (otherBot || []).join(', '),
                                        (en || []).join(', '),
                                        (fr || []).join(', '),
                                        (de || []).join(', '),
                                        (es || []).join(', '),
                                        (cropSectors || []).join('/ '),
                                    ]);
                                }
                            );
                        }
                    });
                    const workSheet = XLSX.utils.aoa_to_sheet(resultGrid);
                    const workBook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workBook, workSheet, 'Species');
                    XLSX.writeFile(workBook, `Species.xlsx`);
                    this.setState(prev => ({
                        loading: prev.loading - 1,
                    }));
                });
            }
        );
    };

    searchSpeciesLatinNameSuggestions = () => {
        if (!this.skipSearching) {
            this.setState({textLabelInputSpeciesLatinNameSearchLoading: true}, () => {
                axiosGraphQLClient
                    .post(
                        '/graphql',
                        this.autoCompletePostData(
                            'autoCompleteSpeciesLatinName',
                            this.state.textLabelInputSpeciesLatinNameValue,
                            'contains',
                            false
                        )
                    )
                    .then((JSONResponse: any) => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.autoCompleteSpeciesLatinName
                        ) {
                            const nameHash: {[key: string]: string[]} = {};
                            JSONResponse.data.data.autoCompleteSpeciesLatinName.forEach((suggestion: any) => {
                                let key = suggestion && suggestion.name;
                                if (!nameHash[key]) {
                                    nameHash[key] = [];
                                }
                                nameHash[key].push(key);
                            });
                            const listByIdName: any = Object.keys(nameHash).map(key => ({
                                ID: nameHash[key].join(','),
                                NAME: key,
                            }));
                            this.setState({
                                forceHideSearchList: false,
                                textTimestamp: Date.now(),
                                textLabelInputSpeciesLatinNameSearchLoading: false,
                                textLabelInputSpeciesLatinNameHash: nameHash,
                                textLabelInputSpeciesLatinNameList: listByIdName,
                            });
                        }
                    })
                    .catch(err => ERROR([err]));
            });
        }
    };

    autoCompletePostData = (autoCompleteName: string, term: string, type: string, includeSynonyms = false) => {
        const synonyms = includeSynonyms ? `includeSynonyms:"1"` : '';

        return {
            query: `
    {
      ${autoCompleteName}(
        term: "${term.toLowerCase()}"
        type: "${type}"
        ${synonyms}
      ){ name principal }
    }`,
        };
    };

    search = () => {
        const values: any = {};
        const tablePageSize = this.state.criteria.pageSize;
        const tablePageIndex = this.state.criteria.pageNumber - 1;
        if (this.state.textLabelInputSpeciesLatinNameValue) {
            values.speciesLatinName = this.state.textLabelInputSpeciesLatinNameValue;
            values.speciesLatinNameSelect = this.state.textLabelInputSpeciesLatinNameCurrentFilter;
        }
        if (this.state.textInputSpeciesCodeValue) {
            values.speciesCode = this.state.textInputSpeciesCodeValue;
            values.speciesCodeSelect = this.state.textInputSpeciesCodeFilter;
        }
        if (this.state.textInputOtherNameValue) {
            values.commonName = this.state.textInputOtherNameValue;
            values.commonNameSelect = this.state.textInputOtherNameFilter;
        }
        if (this.state.selectedLanguages && Object.keys(this.state.selectedLanguages).length) {
            values.commonNameLanguage = {...this.state.selectedLanguages};
        }
        if (this.state.selectedCropSectors && Object.keys(this.state.selectedCropSectors).length) {
            values.speciesCropSector = {...this.state.selectedCropSectors};
        }
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                graphQLClientInstance
                    .post('/graphql', getSearchSpeciesQuery(values, tablePageSize, tablePageIndex))
                    .then(JSONResponse => {
                        if (
                            JSONResponse &&
                            JSONResponse.data &&
                            JSONResponse.data.data &&
                            JSONResponse.data.data.speciesSearch &&
                            JSONResponse.data.data.speciesSearch.data
                        ) {
                            const {data, total} = JSONResponse.data.data.speciesSearch;
                            const responseSpecies = data.map(responseSpeciesMapToState);
                            this.setState(prev => ({
                                count: total,
                                loading: prev.loading - 1,
                                responseSpecies,
                                screenLoaded: true,
                                timestamp: Date.now(),
                                selectedSpecies: [],
                            }));
                        }
                    });
            }
        );
    };

    resetCriteria = () =>
        this.setState({
            criteria: {...DEFAULT_CRITERIA},
            listByIdNameCropSectors: [],
            nameHashCropSectors: {},
            selectedCropSectors: {},
            selectedLanguages: {},
            textInputOtherNameFilter: 'contains',
            textInputOtherNameValue: '',
            textInputSpeciesCodeFilter: 'contains',
            textInputSpeciesCodeValue: '',
            textLabelInputSpeciesLatinNameCurrentFilter: 'contains',
            textLabelInputSpeciesLatinNameHash: {},
            textLabelInputSpeciesLatinNameList: [],
            textLabelInputSpeciesLatinNameValue: '',
        });

    onTextInputSpeciesCodeChange = ({
        target: {value: textInputSpeciesCodeValue},
    }: React.ChangeEvent<HTMLInputElement>) => this.setState({textInputSpeciesCodeValue});

    onTextInputSpeciesCodeFilterChange = ({
        target: {value: textInputSpeciesCodeFilter},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({textInputSpeciesCodeFilter});

    onTextInputOtherNameChange = ({target: {value: textInputOtherNameValue}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({textInputOtherNameValue});

    onTextInputOtherNameFilterChange = ({
        target: {value: textInputOtherNameFilter},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({textInputOtherNameFilter});

    onTextLabelInputCropSectorsSelectionChange = (selectedCropSectors: any) => this.setState({selectedCropSectors});

    onTextLabelInputLanguagesSelectionChange = (selectedLanguages: any) => this.setState({selectedLanguages});

    onLinkButtonHelpClick = () => window.open('/help/en/Species.html', '_blank');

    onTextLabelInputSpeciesLatinNameChange = (textLabelInputSpeciesLatinNameValue: string) => {
        clearTimeout(this.state.textLabelInputSpeciesLatinNameTimeoutId);
        this.setState({
            textLabelInputSpeciesLatinNameValue,
            textLabelInputSpeciesLatinNameHash: {},
            textLabelInputSpeciesLatinNameList: [],
        });
        if (
            this.state.textLabelInputSpeciesLatinNameCurrentFilter === 'equals' &&
            (textLabelInputSpeciesLatinNameValue || '').length > 2
        ) {
            let newTimeoutId = setTimeout(() => {
                this.state.textLabelInputSpeciesLatinNameValue && this.searchSpeciesLatinNameSuggestions();
            }, 300);
            this.setState({
                textLabelInputSpeciesLatinNameTimeoutId: newTimeoutId,
            });
        }
    };

    onTextLabelInputSpeciesLatinNameSelectionChange = () => {
        this.skipSearching = true;
        this.setState({
            textLabelInputSpeciesLatinNameList: [],
            textLabelInputSpeciesLatinNameHash: {},
        });
        setTimeout(() => {
            this.skipSearching = false;
        }, 1000);
    };

    onTextLabelInputSpeciesLatinNameFilterChange = ({
        target: {value: textLabelInputSpeciesLatinNameCurrentFilter},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({textLabelInputSpeciesLatinNameCurrentFilter});

    onTableColumnDataClassClick = (rowObject: any) =>
        this.setState({isModalVFSpeciesClassDetailsOpen: rowObject.classCode});

    onTableColumnDataPrincipalBotanicalNameClick = (rowObject: any) =>
        this.setState({isModalVFSpeciesSpeciesDetailsOpen: rowObject});

    onSelectedChange = (selectedSpecies: string[]) => this.setState({selectedSpecies});

    onTableNavigationButtonSelectClick = () => {
        if (!this.state.selectedSpecies.length) return;
        this.props.onButtonSelectClick(this.state.selectedSpecies);
        this.props.close();
    };

    render() {
        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                {this.state.isModalVFSpeciesClassDetailsOpen ? (
                    <VFModalClass
                        classId={this.state.isModalVFSpeciesClassDetailsOpen}
                        close={this.closeModalVFSpeciesClassDetails}
                    />
                ) : null}
                {this.state.isModalVFSpeciesSpeciesDetailsOpen ? (
                    <VFModalSpecies
                        rowObject={this.state.isModalVFSpeciesSpeciesDetailsOpen}
                        close={this.closeModalVFSpeciesSpeciesDetails}
                    />
                ) : null}
                <ModalCustomVersion2
                    close={this.props.close}
                    header={`Select Species`}
                    body={
                        <div>
                            <div className={styles.textLabelInputSpeciesLatinNameWrap}>
                                <TextLabelInput
                                    buttonIcon={this.state.textLabelInputSpeciesLatinNameSearchLoading && faSpinner}
                                    currentFilter={this.state.textLabelInputSpeciesLatinNameCurrentFilter}
                                    double={true}
                                    filter={['starts', 'equals', 'contains']}
                                    forceHideSearchList={this.state.forceHideSearchList}
                                    hideCounter={true}
                                    label={`Species Latin Name`}
                                    listByIdName={this.state.textLabelInputSpeciesLatinNameList}
                                    nameHash={this.state.textLabelInputSpeciesLatinNameHash}
                                    onChange={this.onTextLabelInputSpeciesLatinNameChange}
                                    onFilterChange={this.onTextLabelInputSpeciesLatinNameFilterChange}
                                    onSelectionChange={this.onTextLabelInputSpeciesLatinNameSelectionChange}
                                    popover={`Search operates in all values from the field principal botanical names.`}
                                    selectedElements={{}}
                                    timestamp={this.state.textTimestamp}
                                    removeStyleClear={true}
                                    value={this.state.textLabelInputSpeciesLatinNameValue}
                                />
                            </div>
                            <TextInput
                                currentFilter={this.state.textInputSpeciesCodeFilter}
                                single={true}
                                filter={true}
                                onChange={this.onTextInputSpeciesCodeChange}
                                onFilterChange={this.onTextInputSpeciesCodeFilterChange}
                                label={`Species Code`}
                                popOverText={`UPOV code or species code for the corresponding species Latin name`}
                                value={this.state.textInputSpeciesCodeValue}
                            />
                            <TextInput
                                currentFilter={this.state.textInputOtherNameFilter}
                                double={true}
                                filter={true}
                                onChange={this.onTextInputOtherNameChange}
                                onFilterChange={this.onTextInputOtherNameFilterChange}
                                label={`Other Name`}
                                popOverText={`Search operates in all other names including the other botanical names in Latin, the common names in English, French, German, Spanish.`}
                                value={this.state.textInputOtherNameValue}
                            />
                            <TextLabelInput
                                autoSelection={true}
                                delay={300}
                                label={'Other name: select language'}
                                listByIdName={[
                                    {ID: 'en', NAME: 'English'},
                                    {ID: 'fr', NAME: 'French'},
                                    {ID: 'de', NAME: 'German'},
                                    {ID: 'es', NAME: 'Spanish'},
                                    {ID: 'other_bot', NAME: 'Latin'},
                                ]}
                                useAsMultipleSelection={true}
                                nameHash={{
                                    English: ['en'],
                                    French: ['fr'],
                                    German: ['de'],
                                    Spanish: ['es'],
                                    Latin: ['other_bot'],
                                }}
                                onSelectionChange={this.onTextLabelInputLanguagesSelectionChange}
                                placeholder={`English etc..`}
                                popover={`Allows filtering on these other names (English, French, German, Spanish, Latin)`}
                                selectedElements={this.state.selectedLanguages}
                            />
                            <TextLabelInput
                                autoSelection={true}
                                delay={300}
                                label={'Crop sector'}
                                listByIdName={this.state.listByIdNameCropSectors}
                                nameHash={this.state.nameHashCropSectors}
                                onSelectionChange={this.onTextLabelInputCropSectorsSelectionChange}
                                useAsMultipleSelection={true}
                                placeholder={`Agricultural etc..`}
                                popover={`Selecting several crop sectors will return species belonging to at least one of these crop.`}
                                selectedElements={this.state.selectedCropSectors}
                            />
                            <FormFooter>
                                <Button
                                    clickAction={this.onLinkButtonHelpClick}
                                    variation={'secondary'}
                                >{`Help`}</Button>
                                <Button
                                    clickAction={this.resetCriteria}
                                    variation={'secondary'}
                                >{`Clear fields`}</Button>
                                <Button clickAction={this.search}>{`Search`}</Button>
                            </FormFooter>
                            <div style={{clear: 'both'}} />
                            <div>
                                {this.state.screenLoaded ? (
                                    <CustomTable
                                        additionalNavigationButtons={this.tableNavigationButtons}
                                        count={this.state.count}
                                        formatFunctions={{
                                            classCode: (classCode: string, rowObject: any) => {
                                                return (
                                                    <div
                                                        onClick={() => this.onTableColumnDataClassClick(rowObject)}
                                                        style={{
                                                            color: 'green',
                                                            textDecoration: 'underline',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {classCode}
                                                    </div>
                                                );
                                            },
                                            principalBotanicalName: (
                                                principalBotanicalName: string,
                                                rowObject: any
                                            ) => {
                                                return (
                                                    <div
                                                        onClick={() =>
                                                            this.onTableColumnDataPrincipalBotanicalNameClick(rowObject)
                                                        }
                                                        style={{
                                                            color: 'green',
                                                            textDecoration: 'underline',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {principalBotanicalName}
                                                    </div>
                                                );
                                            },
                                            otherBotanicalNames: (names: string[]) => {
                                                if (!names) {
                                                    return '';
                                                }
                                                if (names && names.length === 1) {
                                                    return names[0];
                                                }
                                                return (
                                                    <>
                                                        {(names || []).map(name => (
                                                            <React.Fragment key={name}>
                                                                - {name}
                                                                <br />
                                                            </React.Fragment>
                                                        ))}
                                                    </>
                                                );
                                            },
                                            otherName: (namesObject: any) => {
                                                if (
                                                    !Object.values(namesObject).filter(value => value !== null).length
                                                ) {
                                                    return '';
                                                }
                                                if (
                                                    Object.values(namesObject).filter(value => value !== null)
                                                        .length === 1
                                                ) {
                                                    let languageIndex: any = Object.values(namesObject).findIndex(
                                                        value => value !== null
                                                    );
                                                    const languageKey =
                                                        Object.keys(namesObject)[languageIndex].toUpperCase();
                                                    const languageValue =
                                                        Object.values(namesObject)[languageIndex] || '';
                                                    return `${languageKey}:${languageValue}`;
                                                }
                                                return (
                                                    <>
                                                        {Object.entries(namesObject).map(
                                                            ([languageKey, speciesNames]: any) => {
                                                                if (speciesNames) {
                                                                    return (
                                                                        <>
                                                                            - {languageKey.toUpperCase()}:{' '}
                                                                            {speciesNames.join(',')}
                                                                            <br />
                                                                        </>
                                                                    );
                                                                }
                                                            }
                                                        )}
                                                    </>
                                                );
                                            },
                                        }}
                                        intl={this.props.intl}
                                        isNavigationButtonCompactOrDefaultViewEnabled={true}
                                        notSortable={RESULT_FIELDS_ALL}
                                        pageNumber={this.state.criteria.pageNumber}
                                        pageSize={this.state.criteria.pageSize}
                                        pagination={true}
                                        printExcel={this.printExcel}
                                        resultFieldsDefault={RESULT_FIELDS_ALL}
                                        tableName={TABLE_NAME}
                                        tableType={'OBJECT'}
                                        tableSource={this.state.responseSpecies}
                                        timestamp={this.state.timestamp}
                                        updateCriteriaValue={this.updateCriteriaValue}
                                        version={2}
                                        selectable={true}
                                        timestampSelected={this.state.timestamp}
                                        id={`principalBotanicalName`}
                                        selectDefault={this.getSelectDefault()}
                                        noSelectAll={true}
                                        onSelectedChange={this.onSelectedChange}
                                    />
                                ) : null}
                            </div>
                        </div>
                    }
                    footer={<></>}
                />
            </>
        );
    }
}

export default injectIntl(ModalSelectSpecies);
