import React, {useEffect, useRef, useState} from 'react';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../../../componentsLayout';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {ModalAlertVersion2, ModalErrorVersion2} from '../../../../commonModals';
import MyFruitSubmissionsPageFilterCriteriaArea from './Components/MyFruitSubmissionsPageFilterCriteriaArea/MyFruitSubmissionsPageFilterCriteriaArea';
import MyFruitSubmissionsPageTableArea from './Components/MyFruitSubmissionsPageTableArea/MyFruitSubmissionsPageTableArea';
import NavigationTLO from '../../../../shared/NavigationTLO';
import {SpeciesNamesFetchData} from '../../CommonInterfaces/CommonInterfaces';
import SubMenuTabs from '../../SubMenu/SubMenuTabs';
import {
    apiMyFruitSubmissionsPageSearch,
    MyFruitSubmissionsPageFilterCriteriaData,
    MyFruitSubmissionsPageTableRowData,
} from './MyFruitSubmissionsPageService';
import {apiSpeciesAutocompletionFilterFetch} from '../../CommonApi/CommonApi';
import {formatDateEasy, reformatDateEasy} from '../../../../components/FormatFunctions';
import {injectIntl, IntlShape} from 'react-intl';
import {getUserRoles} from '../../CommonFunctions/CommonFunctions';
import {History} from 'history';
import {getExcelSignedURLFetchRequest} from '../../../../commonApi/download';
import {getPreSignedURLFetchRequest} from '../../../../utils/requests';
import {saveAs} from 'file-saver';
import moment from 'moment';
import {trackPageView} from '../../../../utils';

const ALL = 'All';
const FRUIT = '3';
const EXAMINATION = '1';

const DEFAULT_CRITERIA: MyFruitSubmissionsPageFilterCriteriaData = {
    registerId: '',
    reference: '',
    denomination: '',
    speciesId: '',
    specieName: '',
    speciesNameFilter: 'starts',
    groupVarietal: FRUIT,
    type: EXAMINATION,
    status: ALL,
    //
    pageNumber: 1,
    pageSize: Number(localStorage.getItem('pageSizemyFruitSubmissions')) || 50,
    refresh: false,
    order: 'registerId',
    reverse: true,
};

const DATEFORMAT_CRITERIA_KEYS = [''];

const DEFAULT_COUNT = 0;

const modalScreenTypes = {ERROR: 'ERROR', INFO: 'INFO'};

interface MyFruitSubmissionsPageProps {
    intl: IntlShape;
    history: History;
}

const MyFruitSubmissionsPage = (props: MyFruitSubmissionsPageProps & RouteComponentProps) => {
    const [criteria, setCriteria] = useState<MyFruitSubmissionsPageFilterCriteriaData>(
        Object.assign({}, DEFAULT_CRITERIA)
    );
    const [speciesListByIdName, setSpeciesListByIdName] = useState<Array<{ID: string; NAME: string}> | null>(null);
    const [speciesNameHash, setSpeciesNameHash] = useState<{[key: string]: Array<string>} | null>(null);
    const [selectedSpecies, setSelectedSpecies] = useState<{[key: string]: string}>({});
    const [, setCriteriaCount] = useState(0);
    const [myFruitSubmissions, setMyFruitSubmissions] = useState<Array<MyFruitSubmissionsPageTableRowData> | null>(
        null
    );
    const [timestamp, setTimestamp] = useState(0);
    const [count, setCount] = useState(DEFAULT_COUNT);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [infoMessage, setInfoMessage] = useState<string>('');
    const [refresh, setRefresh] = useState(true);
    const [, setDefaultOrder] = useState('registerId');
    const [, setReverseOrder] = useState(false);
    const [modalScreen, setModalScreen] = useState<string | null>(null);
    const [userRoles] = useState(getUserRoles());
    const firstLoad = useFirstRender();

    useEffect(() => {
        trackPageView({documentTitle: 'tloMyApplicationsMyFruitSubmissions'});
    }, []);

    function useFirstRender() {
        const firstRender = useRef(true);
        useEffect(() => {
            firstRender.current = false;
        }, []);
        return firstRender.current;
    }

    const getMenuLinks = () =>
        userRoles.indexOf('WETLO') !== -1 || userRoles.indexOf('TLOOF') !== -1 || userRoles.indexOf('EOADM') !== -1
            ? [
                  {id: 'MY_REGISTERS', value: 'My Reports', path: 'tloMyApplicationsMyReports'},
                  {id: 'MY_INVOICES', value: 'My Invoices', path: 'tloMyApplicationsMyInvoices'},
                  {
                      id: 'MY_FRUIT_SUBMISSIONS',
                      value: 'My Fruit Submissions',
                      path: 'tloMyApplicationsMyFruitSubmissions',
                  },
              ]
            : [];

    const countCriteria = (registersCriteria: MyFruitSubmissionsPageFilterCriteriaData) => {
        let countNum = 0;
        Object.keys(registersCriteria).forEach(key => {
            if (
                registersCriteria[key] &&
                registersCriteria[key] !== DEFAULT_CRITERIA[key] &&
                registersCriteria[key] !== ''
            )
                countNum++;
        });
        return countNum;
    };

    const parseCriteria = (
        unParsedCriteria: MyFruitSubmissionsPageFilterCriteriaData
    ): MyFruitSubmissionsPageFilterCriteriaData => {
        const parsedCriteria: MyFruitSubmissionsPageFilterCriteriaData = JSON.parse(JSON.stringify({}));
        Object.keys(unParsedCriteria).forEach(key => {
            if (DATEFORMAT_CRITERIA_KEYS.includes(key) && !unParsedCriteria[key]) {
                const date = unParsedCriteria[key];
                parsedCriteria[key] = reformatDateEasy(date);
            } else if (key === 'speciesNameFilter' && !criteria['specieName'] && !criteria['speciesId']) {
                unParsedCriteria[key] = DEFAULT_CRITERIA[key];
            } else {
                parsedCriteria[key] = unParsedCriteria[key];
            }
        });

        return parsedCriteria;
    };

    const buildParams = (parsedCriteria: MyFruitSubmissionsPageFilterCriteriaData) => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(key => parsedCriteria[key] !== DEFAULT_CRITERIA[key] && key !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key]}`);
        const joinedParamsText = `?${paramArray.join('&')}`;
        props.history.replace(
            `/tloMyApplicationsMyFruitSubmissions${(paramArray.length > 0 && joinedParamsText) || ''}`
        );
    };

    const search = (shouldRefresh: boolean, urlLoad = false) => {
        if (userRoles.indexOf('EOADM') !== -1) {
            setInfoMessage(`Your account is not linked to an EO office.`);
            setModalScreen(modalScreenTypes.INFO);
        } else {
            setLoading(true);
            setCriteria(
                Object.assign({}, criteria, {refresh: !!shouldRefresh}, !shouldRefresh && !urlLoad && {pageNumber: 1})
            );
            const parsedCriteria = parseCriteria(criteria);
            buildParams(parsedCriteria);
            apiMyFruitSubmissionsPageSearch(parsedCriteria, {})
                .then(jsonResponse => {
                    if (jsonResponse && jsonResponse.data && jsonResponse.data.myFruitSubmissions) {
                        const newCriteria = Object.assign({}, criteria, {pageNumber: 1});
                        if (!shouldRefresh && !urlLoad) {
                            setCriteria(Object.assign({}, newCriteria));
                        }
                        const parsedData = jsonResponse.data.myFruitSubmissions.map(
                            (submission: MyFruitSubmissionsPageTableRowData) => ({
                                ...submission,
                                sampleAccepted:
                                    submission.sampleAccepted && submission.comments
                                        ? 'comment'
                                        : submission.sampleAccepted,
                            })
                        );
                        setMyFruitSubmissions(parsedData);
                        setTimestamp(Date.now());
                        if (jsonResponse.count) {
                            setCount(jsonResponse.count);
                        }
                    }
                })
                .catch(error => {
                    setErrorMessage(`My Fruit Submissions search list error: ${error}`);
                    setModalScreen(modalScreenTypes.ERROR);
                })
                .finally(() => setLoading(false));
        }
    };

    const loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const params = domainArray.pop();
            if (params) {
                params.split('&').forEach(param => {
                    const paramElements = param.split('=');
                    if (paramElements.length === 2) {
                        const key = paramElements[0];
                        criteria[key] = decodeURIComponent(paramElements[1]);
                    }
                });
                if (Object.keys(criteria).length > 0) {
                    DATEFORMAT_CRITERIA_KEYS.forEach(criteriaKey => {
                        const criteriaValue = criteria && criteria?.criteriaKey?.toString();
                        if (criteriaValue && /^\d{4}(-(\d){2}){2}$/.test(criteriaValue)) {
                            criteria[criteriaKey] = formatDateEasy(criteria[criteriaKey]);
                        }
                    });
                    setCriteria(Object.assign({}, criteria));
                    criteria.order && setDefaultOrder(criteria.order);
                    criteria.reverse && setReverseOrder(criteria.reverse);
                    setCriteriaCount(countCriteria(criteria));
                    search(false, true);
                }
            }
        }
    };

    const closeModal = () => setModalScreen(null);

    const closeInfoModal = () => {
        setInfoMessage('');
        setModalScreen(null);
    };

    const getSpeciesAutocompletionList = (selectedSpecies: {[key: string]: string}) =>
        apiSpeciesAutocompletionFilterFetch(criteria.specieName, criteria.speciesNameFilter)
            .then((jsonResponse: SpeciesNamesFetchData) => {
                const speciesNameHash: {[key: string]: Array<string>} = {};
                ((jsonResponse && jsonResponse.data && jsonResponse.data.speciesNamesList) || []).forEach(sn => {
                    if (!speciesNameHash[sn.speciesName]) {
                        speciesNameHash[sn.speciesName] = [];
                    }
                    speciesNameHash[sn.speciesName].push(sn.speciesName);
                });
                const speciesNameListByIdName = Object.keys(speciesNameHash).map(snn => ({
                    ID: speciesNameHash[snn].join(','),
                    NAME: snn,
                }));
                const selectedSpeciesObj: {[key: string]: string} = {};
                const selectedSpeciesKeyArray = Object.keys(selectedSpecies || {});
                speciesNameListByIdName
                    .filter(i => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                    .forEach(item => (selectedSpeciesObj[item.ID] = item.NAME));
                setSpeciesNameHash(speciesNameHash);
                setSpeciesListByIdName(speciesNameListByIdName);
                setSelectedSpecies(selectedSpeciesObj);
            })
            .catch(error => {
                setLoading(false);
                setErrorMessage(`Species Names Autocompletion list error: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            });

    useEffect(() => {
        if (
            firstLoad &&
            (userRoles.indexOf('EOADM') !== -1 ||
                userRoles.indexOf('WETLO') !== -1 ||
                userRoles.indexOf('TLOOF') !== -1)
        ) {
            loadUrlParams();
            criteria.pageSize = Number(localStorage.getItem('pageSizemyFruitSubmissions')) || 50;
            setCriteria(Object.assign({}, criteria));
            const selectedSpecies: {[key: string]: string} = {};
            if (criteria.speciesId && criteria.speciesIds !== '') {
                criteria.speciesId.split(',').forEach(speciesId => (selectedSpecies[speciesId] = ''));
            }
            getSpeciesAutocompletionList(selectedSpecies);
        }
    }, []);

    useEffect(() => {
        !firstLoad &&
            refresh &&
            (userRoles.indexOf('EOADM') !== -1 ||
                userRoles.indexOf('WETLO') !== -1 ||
                userRoles.indexOf('TLOOF') !== -1) &&
            search(true);
    }, [criteria.order, criteria.reverse, criteria.pageSize, criteria.pageNumber]);

    useEffect(() => {
        firstLoad &&
            (userRoles.indexOf('EOADM') !== -1 ||
                userRoles.indexOf('WETLO') !== -1 ||
                userRoles.indexOf('TLOOF') !== -1) &&
            search(false, true);
    }, [criteria.order, criteria.reverse]);

    const updateCriteriaValue = (
        // eslint-disable-next-line no-undef
        criteriaValue: Partial<MyFruitSubmissionsPageFilterCriteriaData>,
        callback = () => {},
        shouldRefresh = true
    ) => {
        setRefresh(shouldRefresh);
        if (criteriaValue !== undefined) {
            setCriteria({...criteria, ...criteriaValue});
        }
        setCriteriaCount(countCriteria(criteria));
        callback && callback();
    };

    const resetCriteria = () => {
        setMyFruitSubmissions(null);
        setCriteria(Object.assign({}, DEFAULT_CRITERIA, criteria.pageSize));
        setCriteriaCount(0);
        const parsedCriteria = parseCriteria(DEFAULT_CRITERIA);
        buildParams(parsedCriteria);
    };

    const printExcel = (excelTranslations: {[key: string]: string}) => {
        setLoading(true);
        const parsedCriteria = Object.assign({}, parseCriteria(criteria), {excel: true}, {pageSize: 10000});
        apiMyFruitSubmissionsPageSearch(parsedCriteria, excelTranslations)
            .then(jsonResponse => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                    getExcelSignedURLFetchRequest(jsonResponse.data.token).then(response => {
                        if (response && response.signedUrl) {
                            const {signedUrl} = response;
                            getPreSignedURLFetchRequest(signedUrl)
                                .then(response => response.blob())
                                .then(responseBlob => {
                                    saveAs(responseBlob, `MyFruitSubmissions_${moment().format('DD/MM/YYYY')}.xlsx`);
                                });
                        }
                    });
                }
            })
            .catch(error => {
                setErrorMessage(`error downloading: ${error}`);
                setModalScreen(modalScreenTypes.ERROR);
            })
            .finally(() => setLoading(false));
    };

    const updateSelectedSpecies = (selectedSpecies: {[key: string]: string}) => setSelectedSpecies(selectedSpecies);

    return (
        <>
            {modalScreen === modalScreenTypes.ERROR ? (
                <ModalErrorVersion2 title={'Error'} message={errorMessage} close={closeModal} />
            ) : null}
            {modalScreen === modalScreenTypes.INFO ? (
                <ModalAlertVersion2 title={'Info'} message={infoMessage} close={closeInfoModal} />
            ) : null}
            {loading ? <HeaderLoading /> : null}
            <HeaderLogoMenu />
            <HeaderTitleAndVersion title={`Technical Liaison Officer`} />
            <NavigationTLO activeTitle={`My Applications`} />
            <MainWrapper>
                <FormWrapper paddingFormContent={'sm'}>
                    {userRoles.indexOf('WETLO') === -1 &&
                    userRoles.indexOf('TLOOF') === -1 &&
                    userRoles.indexOf('EOADM') === -1 ? (
                        <div style={{paddingTop: 20}}>
                            <b style={{color: 'red'}}>
                                <span className="ng-scope">{`You have no permissions to access this page.`}</span>
                            </b>
                        </div>
                    ) : (
                        <>
                            <SubMenuTabs tabId={'MY_FRUIT_SUBMISSIONS'} loading={loading} menuLinks={getMenuLinks()} />
                            <MyFruitSubmissionsPageFilterCriteriaArea
                                criteria={criteria}
                                speciesListByIdName={speciesListByIdName}
                                speciesNameHash={speciesNameHash}
                                selectedSpecies={selectedSpecies}
                                updateSelectedSpecies={updateSelectedSpecies}
                                updateCriteriaValue={updateCriteriaValue}
                                search={search}
                                resetCriteria={resetCriteria}
                            />
                        </>
                    )}
                </FormWrapper>
                {(userRoles.indexOf('WETLO') !== -1 ||
                    userRoles.indexOf('TLOOF') !== -1 ||
                    userRoles.indexOf('EOADM') !== -1) && (
                    <MyFruitSubmissionsPageTableArea
                        intl={props.intl}
                        count={count}
                        timestamp={timestamp}
                        criteria={criteria}
                        myFruitSubmissions={myFruitSubmissions}
                        printExcel={printExcel}
                        updateCriteriaValue={updateCriteriaValue}
                    />
                )}
            </MainWrapper>
            <Footer />
        </>
    );
};

export default injectIntl(withRouter(MyFruitSubmissionsPage));
