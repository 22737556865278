import React from 'react';
import styles from './Title.module.scss';

interface ITitleProps {
    children: any;
    titleDescription?: string;
}

function Title({children, titleDescription}: ITitleProps) {
    return (
        <section className={styles.title}>
            {children}
            {titleDescription ? <span className={styles.titleDescription}>{titleDescription}</span> : null}
        </section>
    );
}

export default Title;
