import {API_SERVERLESS, postFetch} from '~commonApi';

const isLogged = () => {
    const remember = localStorage.getItem('remember') === '1';
    return (remember ? localStorage : sessionStorage).getItem('token');
};

export function apiContactUs(jsonData: any): Promise<any> {
    const logged = isLogged();
    if (logged) {
        return postFetch(`${API_SERVERLESS}/common/v3/contactUsPrivate`, logged, jsonData);
    } else {
        return postFetch(`${API_SERVERLESS}/common/v3/contactUs`, logged, jsonData);
    }
}
