import React, {useEffect, useState} from 'react';
import {Button} from '../../../../../componentsFormV2';
import {CountryElement} from '../../../CommonInterfaces/CommonInterfaces';
import {Error} from '../../../../../componentsLayout';
import {ErrorMessages} from '../../../../TLO/CommonInterfaces/CommonInterfaces';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import Label from '~components/Label';
import {ModalCustomVersion2} from '../../../../../commonModals';
import {PersonElement} from '../AcknowledgementsPagePVRCASELAWService';
import RadioButton from '~components/RadioButton';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {getConcatErrorMessage} from '../../../../TLO/CommonFunctions/CommonFunctions';

interface ModalPersonProps {
    personElement: PersonElement | null;
    countriesList: CountryElement[];
    savePerson: (
        personId: number,
        personTitle: string,
        personName: string,
        personSurname: string,
        personCountryId: string
    ) => void;
    close: () => void;
}

const ModalAcknowledgementsPagePerson = (props: ModalPersonProps) => {
    const personId = props.personElement?.id || 0;
    const [name, setName] = useState(props.personElement?.name || '');
    const [surname, setSurname] = useState(props.personElement?.surname || '');
    const [title, setTitle] = useState(props.personElement?.title || 'Mr.');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [highlightErrors, setHighlightErrors] = useState(false);
    const [country, setCountry] = useState(props.personElement?.country || '');
    const [countryId, setCountryId] = useState(props.personElement?.countryId || '');
    const [selectedCountries, setSelectedCountries] = useState<{[key: string]: string}>({});
    const [countryListByIdName, setCountreyListByIdName] = useState<{ID: string; NAME: string}[] | null>(null);
    const [countryNameHash, setCountryNameHash] = useState<{[key: string]: string[]}>({});

    useEffect(() => {
        const countriesList = props.countriesList;
        const countryNameHash: {[key: string]: string[]} = {};
        const selectedCountries: {[key: string]: string} = {};
        countriesList.forEach(el => {
            countryNameHash[el.countryName] = [];
            if (!countryNameHash[el.countryName]) {
                countryNameHash[el.countryName] = [];
            }
            countryNameHash[el.countryName].push(el.countryId);
        });
        const countryListByIdName = Object.keys(countryNameHash).map(el => ({
            ID: countryNameHash[el].join(','),
            NAME: el,
        }));
        setCountreyListByIdName(countryListByIdName);
        setCountryNameHash(countryNameHash);

        const countryIdArray = countryId.split(', ');
        const countryArray = country.split(', ');
        for (let i = 0; i < countryIdArray.length; i++) selectedCountries[countryIdArray[i]] = countryArray[i];

        setSelectedCountries(selectedCountries);
    }, [props.countriesList]);

    const onNameChange = ({target: {value: name}}: React.ChangeEvent<HTMLInputElement>) => setName(name);

    const onSurnameChange = ({target: {value: surname}}: React.ChangeEvent<HTMLInputElement>) => setSurname(surname);

    const onCheckTitle = (value: string) => setTitle(value);

    const onCountryChange = (_countryChange: string) => {
        const countriesArray: string[] = [];
        const countriesIdsArray: string[] = [];
        Object.entries(selectedCountries).forEach(countryEl => {
            const [key, value] = countryEl;
            if (key && value) {
                countriesArray.push(value);
                countriesIdsArray.push(key);
            }
        });
        setCountry(countriesArray.join(', '));
        setCountryId(countriesIdsArray.join(', '));
    };

    const onCountrySelectionChange = (selectedCountry: {[key: string]: string}) =>
        setSelectedCountries(selectedCountry);

    const getErrorMessagesForSave = () => {
        setHighlightErrors(false);
        const errorMessages: ErrorMessages = {
            errorInput: [],
            errorLogic: [],
        };

        if (title !== 'Mr.' && title !== 'Ms.' && title !== 'Mrs.')
            errorMessages.errorInput && errorMessages.errorInput.push('a title for the new person');

        if (name.length === 0) errorMessages.errorInput && errorMessages.errorInput.push('a name for the new person');

        if (surname.length === 0)
            errorMessages.errorInput && errorMessages.errorInput.push('a surname for the new person');

        if (country.length === 0)
            errorMessages.errorInput && errorMessages.errorInput.push('a country for the new person');

        return errorMessages;
    };

    const onSavePerson = () => {
        const errorMessagesObj = getErrorMessagesForSave();
        let errorMessage = getConcatErrorMessage(errorMessagesObj);
        if (errorMessage.length > 0) {
            setErrorMessage(errorMessage);
            setHighlightErrors(true);
        }
        if (errorMessage.length === 0) props.savePerson(personId, title, name, surname, countryId);
    };

    return (
        <ModalCustomVersion2
            close={() => props.close && props.close()}
            header={personId > 0 ? `Edit Person` : `Add New Person`}
            body={
                <>
                    <TextLabelInput
                        double={true}
                        placeholder={'please use autocompletion'}
                        onSelectionChange={onCountrySelectionChange}
                        onChange={onCountryChange}
                        value={country}
                        selectedElements={selectedCountries}
                        delay={300}
                        outsideLabel={`Country`}
                        outsideLabelWidth={140}
                        listByIdName={countryListByIdName}
                        nameHash={countryNameHash}
                        noInfo={true}
                        useAsMultipleSelection={true}
                        isOnModal={true}
                        listMaxHeight={200}
                        maxElementsInList={50}
                    />
                    <div style={{clear: 'both'}} />
                    <div>
                        <Label width={140}>{`Title`}</Label>
                        <RadioButton
                            radioButtonList={[
                                {description: `Mr.`, value: `Mr.`},
                                {description: `Ms.`, value: `Ms.`},
                                {description: `Mrs.`, value: `Mrs.`},
                            ]}
                            groupName={'personElementTitle'}
                            clickEvent={onCheckTitle}
                            value={title}
                            disabled={false}
                        />
                    </div>
                    <div style={{clear: 'both'}} />
                    <TextInput
                        value={name}
                        outsideLabel={'Name'}
                        outsideLabelWidth={140}
                        disabled={false}
                        onChange={onNameChange}
                        double={true}
                        placeholder={`Please type a person name`}
                    />
                    <div style={{clear: 'both'}} />
                    <TextInput
                        value={surname}
                        outsideLabel={'Surname'}
                        outsideLabelWidth={140}
                        disabled={false}
                        onChange={onSurnameChange}
                        double={true}
                        placeholder={`Please type a person surname`}
                    />
                    <div style={{clear: 'both'}} />
                </>
            }
            footer={
                <>
                    {highlightErrors ? (
                        <Error>
                            <FontAwesomeIcon icon={faExclamationCircle as IconDefinition} color={'white'} />
                            {` ${errorMessage}`}
                        </Error>
                    ) : null}
                    <Button clickAction={props.close} icon={'close'} variation={'danger'}>{`Cancel`}</Button>
                    <Button clickAction={onSavePerson} icon={'arrowRight'}>{`Save`}</Button>
                </>
            }
        />
    );
};

export default ModalAcknowledgementsPagePerson;
