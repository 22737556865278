import React, {useState} from 'react';
import styles from './HeaderCookies.module.scss';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';

export default function HeaderCookies() {
    const [cookiesAccepted, setCookiesAccepted] = useState(localStorage.cookiesAccepted === 'true');

    const acceptCookies = () => {
        localStorage.cookiesAccepted = 'true';
        setCookiesAccepted(true);
    };

    return (
        <React.Fragment>
            {!cookiesAccepted && (
                <div className={styles.noticeCookie}>
                    <div className={styles.noticeContainer}>
                        <div className={styles.noticeMessage}>
                            <div className={styles.noticeLabel}>
                                {`This site uses cookies to offer you a better browsing experience.`}
                            </div>
                            <a target="_blank" href="/cookies" className={styles.noticeLink} rel="noopener noreferrer">
                                {`Find out more on how we use cookies and how you can change your settings.`}
                            </a>
                        </div>
                        <div className={styles.noticeButton}>
                            <FormFooterButton
                                color={buttonColor.green}
                                clickAction={acceptCookies}
                                width={20}
                            >{`OK`}</FormFooterButton>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}
