import React, {useEffect, useState} from 'react';
import jwtDecode from 'jwt-decode';
import {
    apiCommunicationCentreClientSearchInbox,
    apiCommunicationCentreInternalSearchInbox,
} from './CommunicationCentreCounterService';
import {
    findDefaultValueForCaseHolder,
    findDefaultValueForDirectory,
    OPTION_ALL,
} from '../../screens/CommunicationCentreInternal/CommunicationCentreInternal.utils';
import styles from './CommunicationCentreCounter.module.scss';
import {checkIfUserHasAccessToCommunicationCentre} from '../../utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-regular-svg-icons';

export default function () {
    const [messageNumber, setMessages] = useState('*');
    const [thirdPartyId, setThirdPartyId] = useState(null);
    const [showCounter, setShowCounter] = useState(false);

    useEffect(() => {
        const remember = localStorage.getItem('remember') === '1';
        const authToken = (remember ? localStorage : sessionStorage).getItem('token');
        if (authToken) {
            const decoded = jwtDecode(authToken);
            decoded.thirdPartyId && setThirdPartyId(decoded.thirdPartyId);
            const hasUserAccessToCommunicationCentre = checkIfUserHasAccessToCommunicationCentre(decoded);
            const isCPVOUser = parseInt(decoded.thirdPartyId) === 1;
            setShowCounter(isCPVOUser || hasUserAccessToCommunicationCentre);
            if (messageNumber === '*') {
                if (decoded.thirdPartyId === '1') {
                    const caseHolder = findDefaultValueForCaseHolder(decoded);
                    const directory = findDefaultValueForDirectory(decoded);
                    let additionSearchParams = {};
                    directory !== OPTION_ALL && (additionSearchParams.directory = directory);
                    caseHolder !== OPTION_ALL && (additionSearchParams.caseHolder = caseHolder);
                    let query = Object.entries(additionSearchParams)
                        .map(([key, value]) => `${key}=${value}`)
                        .join('&');
                    apiCommunicationCentreInternalSearchInbox(query).then(result => setMessages(result.data.count));
                } else {
                    if (hasUserAccessToCommunicationCentre) {
                        apiCommunicationCentreClientSearchInbox().then(result => setMessages(result.data.count));
                    }
                }
            }
        }
    }, []);

    const goToTheCommunicationCentre = event => {
        event.stopPropagation();
        const remember = localStorage.getItem('remember') === '1';
        sessionStorage.setItem('token-cc', (remember ? localStorage : sessionStorage).getItem('token'));
        if (thirdPartyId === '1') {
            window.open('/communicationCentreInternalInbox', '_blank');
        } else {
            window.open('/communicationCentreClientInbox', '_blank');
        }
    };

    if (!showCounter) {
        return null;
    }

    return (
        <div className={styles.menuCommunicationCentre} onClick={goToTheCommunicationCentre}>
            <div className={styles.menuUserLabelUser}>
                <FontAwesomeIcon icon={faEnvelope} style={{marginRight: 5, fontSize: 20}} />
                {`Communication Centre (${messageNumber})`}
            </div>
        </div>
    );
}
