import React from 'react';
import {injectIntl} from 'react-intl';
import styles from './ModalOnlineApplicationsCreateApplication.module.scss';
import {
    apiGetOAPrepare,
    apiOnlineApplicationRequestCopy,
    apiOnlineApplicationRequestCreate,
    apiOnlineApplicationRequestDuplicate,
} from './ModalOnlineApplicationsCreateApplicationService';
import SelectInput from '~components/SelectInput';
import TextLabelInput from '~components/TextLabelInput';
import {apiSpecies} from '~commonApi/static';
import cx from 'classnames';
import CustomTable from '~components/CustomTable';
import {MODAL_CREATE_MODES} from '../../OnlineApplications';
import {buildFormDraftUrl} from '../../utils';
import Info from '~components/Info';
import {IApplicationSearchItem} from '../../../../types';
import {ModalAlertVersion2, ModalCustomVersion2} from '../../../../commonModals';
import {Error, LoadingBar} from '../../../../componentsLayout';
import {Button} from '../../../../componentsFormV2';

const LANGUAGES_OPTIONS_WITHOUT_OFFICE = [{id: 0, value: 'Please select an office first'}];

const LABEL_WIDTH = 240;

const DEFAULT_RESULT_FIELDS = ['code', 'name'];

interface IProps {
    close: any;
    duplicateApplicationClickedRowObject: IApplicationSearchItem | null;
    mode: any;
    originId: any;
    officeId: any;
    speciesId: any;
    speciesInput: any;
    selectedTechnicalQuestionnaireId: any;
    selectedTechnicalQuestionnaireLanguage: any;
    onOpenModalDuplicateGroupsAF: any;
    intl: any;
    history: any;
    setSpecies: any;
    setOfficeId: any;
    setSelectedTechnicalQuestionnaireId: any;
    setTechnicalQuestionnaireLanguage: any;
}

interface IState {
    errorMessage: string | null;
    isModalAlertUnTickBotanicalTaxonOpen: boolean;
    loading: number;
    responseSelectOptionsOffices: any;
    responseSelectOptionsApplicationFormLanguages: any;
    responseApplicationFormId: any;
    responseApplicationFormTitle: any;
    selectValueOffice: any;
    textLabelInputSpeciesValue: any;
    selectInputApplicationFormLanguageValue: any;
    selectInputTechnicalQuestionnaireLanguageValue: any;
    selectInputWhoAmIValue: any;
    selectedSpeciesIds: {[key: string]: string};
    speciesListByIdName: {ID: string; NAME: string}[];
    speciesNameHash: any;
    responseTableRowsTechnicalQuestionnaires: any;
    responseTableRowsTechnicalQuestionnairesMostRelevant: any;
    responseTableRowsTechnicalQuestionnairesOtherTQs: any;
    tableSelectedRowTechnicalQuestionnaires: any;
    responseSelectOptionsTechnicalQuestionnaireLanguages: any;
    timestamp: any;
}

class ModalOnlineApplicationsCreateApplication extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            errorMessage: null,
            isModalAlertUnTickBotanicalTaxonOpen: false,
            loading: 0,
            responseSelectOptionsOffices: null,
            responseSelectOptionsApplicationFormLanguages: null,
            responseApplicationFormId: null,
            responseApplicationFormTitle: null,
            selectInputWhoAmIValue: 0,
            selectValueOffice: null,
            selectInputApplicationFormLanguageValue: null,
            selectInputTechnicalQuestionnaireLanguageValue: null,
            speciesListByIdName: [],
            speciesNameHash: null,
            textLabelInputSpeciesValue: null,
            selectedSpeciesIds: {},
            responseTableRowsTechnicalQuestionnaires: null,
            responseTableRowsTechnicalQuestionnairesMostRelevant: null,
            responseTableRowsTechnicalQuestionnairesOtherTQs: null,
            tableSelectedRowTechnicalQuestionnaires: null,
            responseSelectOptionsTechnicalQuestionnaireLanguages: null,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        const {mode} = this.props;
        !mode && this.loadApplicationCreateJSONs();
        mode === MODAL_CREATE_MODES.COPY && this.loadApplicationCopyJSONs();
    }

    closeModalAlertUnTickBotanicalTaxon = () => this.setState({isModalAlertUnTickBotanicalTaxonOpen: false});

    loadApplicationCreateJSONs = () =>
        Promise.all([this.loadPrepare(), this.loadSpecies()]).catch(error => LOG([`Error loading data: ${error}`]));

    loadApplicationCopyJSONs = () =>
        Promise.all([this.loadPrepareCopyJSONs(), this.loadSpecies()])
            .then(() => {
                if (
                    this.props.speciesId &&
                    !this.state.speciesListByIdName.map(({ID}) => ID).includes(this.props.speciesId)
                ) {
                    this.setState({
                        tableSelectedRowTechnicalQuestionnaires: null,
                        responseTableRowsTechnicalQuestionnaires: null,
                        responseTableRowsTechnicalQuestionnairesMostRelevant: null,
                        responseTableRowsTechnicalQuestionnairesOtherTQs: null,
                        selectedSpeciesIds: {},
                        selectInputTechnicalQuestionnaireLanguageValue: null,
                        textLabelInputSpeciesValue: '',
                    });
                }
            })
            .catch(error => LOG([`Error loading data: ${error}`]));

    loadPrepareCopyJSONs = () =>
        new Promise(resolve => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiGetOAPrepare({
                        officeId: this.props.officeId,
                        speciesId: this.props.speciesId || 'xxx',
                        speciesName: this.props.speciesInput || 'xxx',
                    })
                        .then((JSONResponse: any) => {
                            const {offices = [], appForms = [], techQuests = []} = (JSONResponse || {}).data || {};
                            const copyOffices = [...offices];
                            const {applicationFormLanguageId} = this.props.duplicateApplicationClickedRowObject || {};
                            appForms.length > 0 &&
                                this.setState(
                                    {
                                        textLabelInputSpeciesValue: this.props.speciesInput,
                                        selectedSpeciesIds: this.props.speciesId
                                            ? {
                                                  [this.props.speciesId]: this.props.speciesInput,
                                              }
                                            : {},
                                        responseSelectOptionsOffices: [
                                            ...copyOffices.map(({id, name: value}: any) => ({
                                                id,
                                                value:
                                                    value === 'NAKTUINBOUW' ? 'Dutch Board for plant varieties' : value,
                                            })),
                                        ],
                                        selectValueOffice: this.props.officeId,
                                        responseApplicationFormId: appForms[0]['id'],
                                        responseApplicationFormTitle: appForms[0]['name'],
                                        responseSelectOptionsApplicationFormLanguages: utilPrepareArrayLanguages(
                                            appForms[0].languages
                                        ),
                                        selectInputApplicationFormLanguageValue: applicationFormLanguageId,
                                        responseTableRowsTechnicalQuestionnaires: techQuests,
                                        responseTableRowsTechnicalQuestionnairesMostRelevant: techQuests.filter(
                                            (el: any) => el.linked === true
                                        ),
                                        responseTableRowsTechnicalQuestionnairesOtherTQs: techQuests.filter(
                                            (el: any) => el.linked === false
                                        ),
                                        selectInputTechnicalQuestionnaireLanguageValue:
                                            this.props.selectedTechnicalQuestionnaireLanguage || null,
                                    },
                                    () => {
                                        this.selectTechnicalQuestionnaireCallback(
                                            this.props.selectedTechnicalQuestionnaireId ||
                                                (techQuests && techQuests.length && techQuests[0]['id']) ||
                                                null
                                        );
                                    }
                                );
                        })
                        .catch((error: any) => {
                            ERROR([`ModalCreateApplication: ${error.message}`]);
                        })
                        .then(() =>
                            this.setState(
                                prev => ({loading: prev.loading - 1}),
                                () => resolve([])
                            )
                        );
                }
            );
        });

    loadPrepare = () =>
        new Promise(resolve => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiGetOAPrepare()
                        .then((JSONResponse: any) => {
                            const {offices = []} = (JSONResponse || {}).data || {};
                            JSONResponse &&
                                this.setState({
                                    responseSelectOptionsOffices: [
                                        {id: '0', value: 'Select an office'},
                                        ...offices.map(({id, name: value}: any) => ({
                                            id,
                                            value: value === 'NAKTUINBOUW' ? 'Dutch Board for plant varieties' : value,
                                        })),
                                    ],
                                });
                        })
                        .catch((error: any) => {
                            ERROR([`ModalCreateApplication: ${error.message}`]);
                        })
                        .finally(() =>
                            this.setState(
                                prev => ({loading: prev.loading - 1}),
                                () => resolve([])
                            )
                        );
                }
            );
        });

    loadSpecies = () =>
        new Promise(resolve => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiSpecies()
                        .then((jsonResponse: any) => {
                            if (jsonResponse) {
                                const speciesNameHash: any = {};
                                (jsonResponse || []).forEach((speciesElement: any) => {
                                    if (!speciesNameHash[speciesElement.NAME]) {
                                        speciesNameHash[speciesElement.NAME] = [];
                                    }
                                    speciesNameHash[speciesElement.NAME].push(speciesElement.ID);
                                });
                                const speciesListByIdName = Object.keys(speciesNameHash).map(speciesName => ({
                                    ID: speciesNameHash[speciesName].join(','),
                                    NAME: speciesName,
                                }));
                                this.setState({
                                    speciesListByIdName,
                                    speciesNameHash,
                                    timestamp: Date.now(),
                                });
                            }
                        })
                        .catch((error: any) => {
                            ERROR([`ModalCreateApplication: ${error.message}`]);
                        })
                        .finally(() =>
                            this.setState(
                                prev => ({loading: prev.loading - 1}),
                                () => resolve([])
                            )
                        );
                }
            );
        });

    getTableTechnicalQuestionnairesRowClass = ({id}: any) =>
        this.state.tableSelectedRowTechnicalQuestionnaires === id && styles.selectedTQ;

    selectTechnicalQuestionnaireCallback = (id: any) => {
        const selectedTechnicalQuestionnaire = (this.state.responseTableRowsTechnicalQuestionnaires || []).find(
            (el: any) => el.id === id
        );
        const {languages} = selectedTechnicalQuestionnaire || {};
        this.setState({
            tableSelectedRowTechnicalQuestionnaires: id,
            responseSelectOptionsTechnicalQuestionnaireLanguages: (languages || []).map((el: any) => ({
                id: el.id,
                value: el.name,
            })),
            selectInputTechnicalQuestionnaireLanguageValue: this.props.selectedTechnicalQuestionnaireLanguage
                ? this.props.selectedTechnicalQuestionnaireLanguage
                : languages && languages.length
                ? languages[0]['id']
                : null,
        });
        if (this.props.mode === MODAL_CREATE_MODES.COPY) {
            this.props.setSelectedTechnicalQuestionnaireId(id);
        }
    };

    getMandatoryAsterisk = (label: any) => (
        <React.Fragment>
            <div style={{display: 'inline-block', marginRight: 5}}>{label}</div>
            <div style={{display: 'inline-block', color: 'red'}}>{'*'}</div>
        </React.Fragment>
    );

    onTQSearch = (callback = true) => {
        let preparePayload = {
            officeId: this.state.selectValueOffice,
            speciesId: Object.keys(this.state.selectedSpeciesIds || {})[0] || 'xxx',
        };
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                responseTableRowsTechnicalQuestionnairesMostRelevant: null,
                responseTableRowsTechnicalQuestionnairesOtherTQs: null,
                tableSelectedRowTechnicalQuestionnaires: null,
                selectInputTechnicalQuestionnaireLanguageValue: null,
                textLabelInputSpeciesValue: prev.textLabelInputSpeciesValue,
            }),
            () => {
                apiGetOAPrepare(preparePayload)
                    .then((jsonResponse: any) => {
                        const {techQuests = []} = jsonResponse.data || {};
                        this.setState(
                            {
                                responseTableRowsTechnicalQuestionnairesMostRelevant: techQuests.filter(
                                    (el: any) => el.linked === true
                                ),
                                responseTableRowsTechnicalQuestionnairesOtherTQs: techQuests.filter(
                                    (el: any) => el.linked === false
                                ),
                                responseTableRowsTechnicalQuestionnaires: techQuests,
                                timestamp: Date.now(),
                            },
                            () =>
                                callback &&
                                this.selectTechnicalQuestionnaireCallback(
                                    techQuests && techQuests.length ? techQuests[0].id : null
                                )
                        );
                    })
                    .catch((error: any) => {
                        ERROR([`ModalCreateApplication: ${error.message}`]);
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    onTextLabelInputSpeciesSelectionChange = (species: any) => {
        let runCallbackForSelectingTQ = true;
        const stateObject: any = {
            textLabelInputSpeciesValue: null,
            selectedSpeciesIds: {},
        };
        if (Object.keys(species || {}).length > 0) {
            stateObject.selectedSpeciesIds = species;
            stateObject.errorMessage = null;
        } else {
            runCallbackForSelectingTQ = false;
            stateObject.tableSelectedRowTechnicalQuestionnaires = null;
        }
        if (
            Object.keys(species || {}).length === 0 &&
            this.props.mode === MODAL_CREATE_MODES.COPY &&
            this.props.officeId === 1
        ) {
            stateObject.isModalAlertUnTickBotanicalTaxonOpen = true;
        }
        this.setState(stateObject, () => this.onTQSearch(runCallbackForSelectingTQ));
        if (this.props.mode === MODAL_CREATE_MODES.COPY) {
            let speciesId = null;
            let speciesInput = null;
            if (Object.keys(species).length) {
                speciesId = Object.keys(species)[0];
                speciesInput = Object.values(species)[0];
            }
            this.props.setSpecies({speciesId, speciesInput});
        }
    };

    onModalClose = () => {
        this.props.close && this.props.close();
    };

    onNextButton = () => {
        this.setState({errorMessage: null}, () => {
            if (
                !this.state.textLabelInputSpeciesValue &&
                (!this.state.selectedSpeciesIds || !Object.keys(this.state.selectedSpeciesIds).length)
            ) {
                return this.setState({
                    errorMessage: 'You need to select a species to continue opening an application.',
                });
            }
            if (this.props.mode === MODAL_CREATE_MODES.COPY) {
                return this.props.onOpenModalDuplicateGroupsAF && this.props.onOpenModalDuplicateGroupsAF();
            }
            const {id: technicalQuestionnaireId} =
                (this.state.responseTableRowsTechnicalQuestionnaires || []).find(
                    (el: any) => el.id === this.state.tableSelectedRowTechnicalQuestionnaires
                ) || {};
            let jsonRequest = {
                whoami: this.state.selectInputWhoAmIValue,
                officeId: parseInt(this.state.selectValueOffice),
                applicationFormId: parseInt(this.state.responseApplicationFormId),
                applicationFormLanguageId: this.state.selectInputApplicationFormLanguageValue,
                technicalQuestionnaireId,
                technicalQuestionnaireLanguageId: this.state.selectInputTechnicalQuestionnaireLanguageValue,
                speciesId: Object.keys(this.state.selectedSpeciesIds || {})[0] || null,
                applicationRequestId: this.props.originId || null,
                speciesName: this.state.textLabelInputSpeciesValue,
            };

            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    let apiRequestFunction;
                    this.props.mode === MODAL_CREATE_MODES.COPY &&
                        (apiRequestFunction = apiOnlineApplicationRequestCopy);
                    this.props.mode === MODAL_CREATE_MODES.DUPLICATE &&
                        (apiRequestFunction = apiOnlineApplicationRequestDuplicate);
                    !apiRequestFunction && (apiRequestFunction = apiOnlineApplicationRequestCreate);
                    apiRequestFunction(jsonRequest)
                        .then((jsonResponse: any) => {
                            if (jsonResponse) {
                                const {applicationId, formId, formType, formSequence} = jsonResponse || {};
                                this.onModalClose();
                                let uri = buildFormDraftUrl({
                                    applicationId,
                                    formType,
                                    formId,
                                    formSequence,
                                });
                                this.props.history.push(uri);
                            }
                        })
                        .catch((error: any) => {
                            ERROR([`ModalCreateApplication: ${error.message}`]);
                        })
                        .finally(() => this.setState(prev => ({loading: prev.loading - 1})));
                }
            );
        });
    };

    onSelectInputTechnicalQuestionnaireLanguageChange = ({
        target: {value: selectInputTechnicalQuestionnaireLanguageValue},
    }: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({selectInputTechnicalQuestionnaireLanguageValue});
        if (this.props.mode === MODAL_CREATE_MODES.COPY) {
            this.props.setTechnicalQuestionnaireLanguage(selectInputTechnicalQuestionnaireLanguageValue);
        }
    };

    onTextLabelInputSpeciesChange = (textLabelInputSpeciesValue: any) =>
        this.setState(prev => {
            return Object.assign(
                {...prev},
                {textLabelInputSpeciesValue},
                !textLabelInputSpeciesValue && {tableSelectedRowTechnicalQuestionnaires: null}
            );
        });

    onSelectInputApplicationFormLanguageChange = ({
        target: {value: selectInputApplicationFormLanguageValue},
    }: React.ChangeEvent<HTMLSelectElement>) => this.setState({selectInputApplicationFormLanguageValue});

    onSelectValueOfficeChange = ({target: {value: selectValueOffice}}: any) => {
        if (selectValueOffice > 0) {
            this.setState(
                prev => ({loading: prev.loading + 1, selectValueOffice, tableSelectedRowTechnicalQuestionnaires: null}),
                () => {
                    apiGetOAPrepare({
                        officeId: selectValueOffice,
                        specieName: this.state.textLabelInputSpeciesValue || 'xxx',
                        speciesId: Object.keys(this.state.selectedSpeciesIds || {})[0] || 'xxx',
                    })
                        .then((jsonResponse: any) => {
                            const {appForms = [], techQuests = []} = (jsonResponse || {}).data || {};
                            appForms.length > 0 &&
                                this.setState(
                                    {
                                        responseApplicationFormTitle: appForms[0].name,
                                        responseApplicationFormId: appForms[0]['id'],
                                        responseSelectOptionsApplicationFormLanguages: utilPrepareArrayLanguages(
                                            appForms[0].languages
                                        ),
                                        selectInputApplicationFormLanguageValue: appForms[0].languages[0].id,
                                        responseTableRowsTechnicalQuestionnaires: techQuests,
                                        responseTableRowsTechnicalQuestionnairesMostRelevant: techQuests.filter(
                                            (el: any) => el.linked === true
                                        ),
                                        responseTableRowsTechnicalQuestionnairesOtherTQs: techQuests.filter(
                                            (el: any) => el.linked === false
                                        ),
                                        timestamp: Date.now(),
                                    },
                                    () => {
                                        if (this.props.mode === MODAL_CREATE_MODES.COPY) {
                                            this.props.setOfficeId(selectValueOffice);
                                        }
                                    }
                                );
                        })
                        .catch((error: any) => {
                            ERROR([`ModalCreateApplication: ${error.message}`]);
                        })
                        .finally(() => this.setState(prev => ({loading: prev.loading - 1})));
                }
            );
        } else {
            this.setState({
                selectValueOffice: null,
                responseApplicationFormTitle: null,
                responseApplicationFormId: null,
                responseSelectOptionsApplicationFormLanguages: null,
                textLabelInputSpeciesValue: null,
                responseTableRowsTechnicalQuestionnaires: null,
                responseSelectOptionsTechnicalQuestionnaireLanguages: null,
                selectInputTechnicalQuestionnaireLanguageValue: null,
            });
            if (this.props.mode === MODAL_CREATE_MODES.COPY) {
                this.props.setOfficeId(null);
            }
        }
    };

    onTableTechnicalQuestionnairesRowClick = (id: any) => this.selectTechnicalQuestionnaireCallback(id);

    onSelectInputWhoAmIChange = ({target: {value: selectInputWhoAmIValue}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({selectInputWhoAmIValue});

    renderInfoTechnicalQuestionnaireCannotBeCopied = () => {
        let {TQLanguageId} = this.props.duplicateApplicationClickedRowObject || {};
        return (
            this.props.mode === MODAL_CREATE_MODES.COPY &&
            this.props.selectedTechnicalQuestionnaireLanguage !== TQLanguageId
        );
    };

    render() {
        const isNextStepEnabled =
            [
                'selectInputWhoAmIValue',
                'selectValueOffice',
                'responseApplicationFormTitle',
                'selectInputApplicationFormLanguageValue',
                'tableSelectedRowTechnicalQuestionnaires',
                'selectInputTechnicalQuestionnaireLanguageValue',
            ].filter(el => this.state[el as keyof IState] === null).length === 0 &&
            (Object.keys(this.state.selectedSpeciesIds).length > 0 || this.state.textLabelInputSpeciesValue);
        const isCopyOrDuplicateAction =
            this.props.mode &&
            (this.props.mode === MODAL_CREATE_MODES.COPY || this.props.mode === MODAL_CREATE_MODES.DUPLICATE);
        const areOnlyOtherTQsExist =
            !this.state.responseTableRowsTechnicalQuestionnairesMostRelevant ||
            !this.state.responseTableRowsTechnicalQuestionnairesMostRelevant.length;

        return (
            <>
                {this.state.isModalAlertUnTickBotanicalTaxonOpen ? (
                    <ModalAlertVersion2
                        close={this.closeModalAlertUnTickBotanicalTaxon}
                        message={
                            <>
                                {`In case you change the species, please note that you need to select the appropriate TQ on this screen and untick point `}
                                <i>{`03/ Botanical taxon/Species`}</i>
                                {` of the AF on the next screen.`}
                            </>
                        }
                    />
                ) : null}
                <div className={styles.modalWrap}>
                    <ModalCustomVersion2
                        close={this.onModalClose}
                        header={
                            <>
                                <>{!this.props.mode && `Create an application`}</>
                                <>{this.props.mode === MODAL_CREATE_MODES.COPY && `Duplicate an application`}</>
                                {isCopyOrDuplicateAction && (
                                    <div className={styles.modalSubtitle}>
                                        <>
                                            {this.props.mode === MODAL_CREATE_MODES.COPY && (
                                                <>
                                                    <p style={{fontWeight: 'normal', marginTop: 0}}>
                                                        {`In case of need, update the information under `}
                                                        <i
                                                            style={{fontWeight: 'bold'}}
                                                        >{`02/ Office for granting PBR`}</i>
                                                        {` and `}
                                                        <i style={{fontWeight: 'bold'}}>{`05/ Species`}</i>.
                                                    </p>
                                                    <p style={{fontWeight: 'normal', marginBottom: 0}}>
                                                        {`In case you change the `}
                                                        <i style={{fontWeight: 'bold'}}>{`05/ Species`}</i>
                                                        {` please note that you need to select the appropriate TQ on this screen below and untick point `}
                                                        <i
                                                            style={{fontWeight: 'bold'}}
                                                        >{`03/ Botanical taxon/Species`}</i>
                                                        {`  of the AF on the next screen.`}
                                                    </p>
                                                </>
                                            )}
                                        </>
                                        <>
                                            {this.props.mode === MODAL_CREATE_MODES.DUPLICATE &&
                                                `Some responses of the application form are copied over. Technical questionnaire will be empty. (deprecated)`}
                                        </>
                                    </div>
                                )}
                            </>
                        }
                        body={
                            <>
                                <SelectInput
                                    outsideLabel={`01 / I am`}
                                    outsideLabelWidth={LABEL_WIDTH}
                                    notAll={true}
                                    value={this.state.selectInputWhoAmIValue}
                                    onChange={this.onSelectInputWhoAmIChange}
                                    loading={this.state.loading}
                                    list={[
                                        {id: 0, value: 'Applicant'},
                                        {id: 1, value: 'Representative'},
                                    ]}
                                    disabled={isCopyOrDuplicateAction || this.state.loading !== 0}
                                />
                                <div style={{clear: 'both'}} />
                                <SelectInput
                                    outsideLabel={`02 / Office for granting PBR`}
                                    outsideLabelWidth={LABEL_WIDTH}
                                    notAll={true}
                                    value={this.state.selectValueOffice}
                                    onChange={this.onSelectValueOfficeChange}
                                    loading={this.state.loading}
                                    list={this.state.responseSelectOptionsOffices || []}
                                    disabled={this.state.loading !== 0}
                                />
                                <div style={{clear: 'both'}} />
                                <div className={styles.titleWrap}>
                                    <div className={styles.titleLabel}>{`03 / Application form`}</div>
                                    <div
                                        className={cx(
                                            styles.titleValue,
                                            this.state.responseApplicationFormTitle && styles.hasValue
                                        )}
                                    >
                                        {this.state.responseApplicationFormTitle
                                            ? this.state.responseApplicationFormTitle
                                            : `Please select an office first`}
                                    </div>
                                </div>
                                <div style={{clear: 'both'}} />
                                <SelectInput
                                    outsideLabel={`04 / Application form language`}
                                    outsideLabelWidth={LABEL_WIDTH}
                                    notAll={true}
                                    value={this.state.selectInputApplicationFormLanguageValue}
                                    onChange={this.onSelectInputApplicationFormLanguageChange}
                                    loading={this.state.loading !== 0}
                                    list={
                                        this.state.responseSelectOptionsApplicationFormLanguages ||
                                        LANGUAGES_OPTIONS_WITHOUT_OFFICE
                                    }
                                    disabled={
                                        !this.state.responseSelectOptionsApplicationFormLanguages ||
                                        this.props.mode === MODAL_CREATE_MODES.COPY ||
                                        this.state.loading !== 0
                                    }
                                />
                                <div style={{clear: 'both'}} />
                                <div>
                                    <TextLabelInput
                                        onSelectionChange={this.onTextLabelInputSpeciesSelectionChange}
                                        onChange={this.onTextLabelInputSpeciesChange}
                                        value={this.state.textLabelInputSpeciesValue}
                                        selectedElements={this.state.selectedSpeciesIds || {}}
                                        uniqueValue={true}
                                        delay={0}
                                        outsideLabel={this.getMandatoryAsterisk('05 / Species')}
                                        outsideLabelWidth={LABEL_WIDTH}
                                        listByIdName={this.state.speciesListByIdName}
                                        nameHash={this.state.speciesNameHash}
                                        disabled={!this.state.selectValueOffice || this.state.loading !== 0}
                                        filterContains={true}
                                        buttonIcon={'none'}
                                        showCounter={true}
                                        noInfo={true}
                                        listSticky={true}
                                        messageMoreMatchesPostFix={`, please precise the species if possible`}
                                    />
                                </div>
                                {this.state.loading === 0 &&
                                this.state.responseTableRowsTechnicalQuestionnaires &&
                                this.state.responseTableRowsTechnicalQuestionnaires.length > 0 &&
                                (this.state.textLabelInputSpeciesValue ||
                                    Object.keys(this.state.selectedSpeciesIds || {}).length) ? (
                                    <>
                                        {this.state.responseTableRowsTechnicalQuestionnairesMostRelevant &&
                                            this.state.responseTableRowsTechnicalQuestionnairesMostRelevant.length >
                                                0 && (
                                                <div className={styles.tableWrap}>
                                                    <div className={styles.techQuestsLeft} />
                                                    <div className={styles.techQuestsRight}>
                                                        <div
                                                            style={{fontWeight: 'bold'}}
                                                        >{`Here is the most relevant technical questionnaire:`}</div>
                                                        <CustomTable
                                                            count={
                                                                this.state
                                                                    .responseTableRowsTechnicalQuestionnairesMostRelevant &&
                                                                this.state
                                                                    .responseTableRowsTechnicalQuestionnairesMostRelevant
                                                                    .length
                                                            }
                                                            formatFunctions={{
                                                                name: (name: any) => (
                                                                    <div style={{width: 380}}>{name}</div>
                                                                ),
                                                            }}
                                                            hideExcelButton={true}
                                                            id={'id'}
                                                            intl={this.props.intl}
                                                            resultFieldsDefault={DEFAULT_RESULT_FIELDS}
                                                            rowClick={this.onTableTechnicalQuestionnairesRowClick}
                                                            rowClass={this.getTableTechnicalQuestionnairesRowClass}
                                                            tableName={'TechQuests'}
                                                            tableType={'OBJECT'}
                                                            tableSource={
                                                                this.state
                                                                    .responseTableRowsTechnicalQuestionnairesMostRelevant ||
                                                                []
                                                            }
                                                            timestamp={this.state.timestamp}
                                                            unlimited={true}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                        <div className={styles.tableWrap}>
                                            {!areOnlyOtherTQsExist && (
                                                <div>
                                                    <div className={styles.techQuestsLeft} />
                                                    <div
                                                        className={styles.techQuestsRight}
                                                    >{`But maybe you want one of those:`}</div>
                                                </div>
                                            )}
                                            <div className={styles.techQuestsLeft} />
                                            <div className={styles.techQuestsRight}>
                                                <CustomTable
                                                    count={
                                                        this.state.responseTableRowsTechnicalQuestionnairesOtherTQs &&
                                                        this.state.responseTableRowsTechnicalQuestionnairesOtherTQs
                                                            .length
                                                    }
                                                    formatFunctions={{
                                                        name: (name: any) => <div style={{width: 380}}>{name}</div>,
                                                    }}
                                                    hideExcelButton={true}
                                                    id={'id'}
                                                    intl={this.props.intl}
                                                    resultFieldsDefault={DEFAULT_RESULT_FIELDS}
                                                    rowClick={this.onTableTechnicalQuestionnairesRowClick}
                                                    rowClass={this.getTableTechnicalQuestionnairesRowClass}
                                                    tableName={'TechQuests'}
                                                    tableType={'OBJECT'}
                                                    tableSource={
                                                        this.state.responseTableRowsTechnicalQuestionnairesOtherTQs ||
                                                        []
                                                    }
                                                    timestamp={this.state.timestamp}
                                                    unlimited={true}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                <SelectInput
                                    outsideLabel={`06 / Technical form language`}
                                    outsideLabelWidth={LABEL_WIDTH}
                                    notAll={true}
                                    value={this.state.selectInputTechnicalQuestionnaireLanguageValue}
                                    onChange={this.onSelectInputTechnicalQuestionnaireLanguageChange}
                                    loading={this.state.loading}
                                    list={
                                        !this.state.tableSelectedRowTechnicalQuestionnaires
                                            ? [{id: 0, value: `Please select a TQ first`}]
                                            : this.state.responseSelectOptionsTechnicalQuestionnaireLanguages || []
                                    }
                                    disabled={
                                        !this.state.tableSelectedRowTechnicalQuestionnaires || this.state.loading !== 0
                                    }
                                />
                                <div style={{clear: 'both'}} />
                            </>
                        }
                        footer={
                            <>
                                {this.state.errorMessage ? <Error>{this.state.errorMessage}</Error> : null}
                                {this.renderInfoTechnicalQuestionnaireCannotBeCopied() ? (
                                    <div style={{maxWidth: 'calc(100% - 2px)', marginBottom: 15, marginTop: -15}}>
                                        <Info>{`Technical Questionnaire groups will not be available for copy if the Technical Questionnaire language is different.`}</Info>
                                        <div style={{clear: 'both'}} />
                                    </div>
                                ) : null}
                                {this.state.loading === 0 ? (
                                    <Button
                                        clickAction={() => this.onNextButton()}
                                        disabled={!isNextStepEnabled}
                                    >{`Next step: ${
                                        this.props.mode === MODAL_CREATE_MODES.COPY
                                            ? `(select groups)`
                                            : `(open Application form)`
                                    }`}</Button>
                                ) : (
                                    <LoadingBar />
                                )}
                            </>
                        }
                    />
                </div>
            </>
        );
    }
}

function utilPrepareArrayLanguages(responseLanguages = []) {
    return responseLanguages.map((el: any) => ({
        id: el.id,
        value: el.name,
    }));
}

export default injectIntl(ModalOnlineApplicationsCreateApplication);
