import React from 'react';
import CryptoJS from 'crypto-js';
import styles from './ModalMyPVRPublicSearchCommon.module.scss';
import {ModalCustomVersion2} from '../../../commonModals';
import {Button} from '../../../componentsFormV2';
import {faChevronDown, faChevronRight, faFlag, faPlus} from '@fortawesome/free-solid-svg-icons';
import TextLabelInput from '~components/TextLabelInput';
import Info from '~components/Info';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TextAreaInput from '~components/TextAreaInput';
import {isRegisterIdValid, sanitize} from '~utils';
import CustomTable from '~components/CustomTable';
import getIcon from '~utils/icons';
import {saveAs} from 'file-saver';
import SelectInput from '~components/SelectInput';
import DragAndDrop from '~components/DragAndDrop';
import ModalMyPVRPublicSearchCommonActions from './ModalMyPVRPublicSearchCommonActions.js';
import {apiDocumentUpload} from './ModalMyPVRPublicSearchCommonService';
import InputLink from '~components/InputLink';
import BrowserInfo from '~shared/BrowserInfo';
import HeaderLoading from '~shared/HeaderLoading';
import {getMandatoryAsterisk} from '~utils/format';
import {SharedMessageProceduralRepresentative} from './ModalMyPVRSharedMessages';
import {MAX_UPLOAD_FILE_SIZE} from '../../../utils/constants';
import {Error} from '../../../componentsLayout';

const DOCUMENT_TYPES = [
    {id: null, value: 'Please select a document type'},
    {id: 'powerOfAttorney', value: 'Power of attorney'},
    {id: 'factsAndEvidences', value: `Fact and evidences`},
];

// const DUS_OPTIONS = [
//     {id: null, value: 'Please select'},
//     {id: 'uniformity', value: 'Uniformity'},
//     {id: 'stability', value: 'Stability'},
//     {id: 'entitlement', value: 'Entitlement'},
//     {id: 'novelty', value: 'Novelty'},
//     {id: 'varietyDenomination', value: 'Variety denomination'},
//     {id: 'others', value: 'Other - please specify'},
// ];

const errorMessages: any = {
    UNKNOWN: `This is not a valid application number. The value must be in range 19950001 to ${new Date().getFullYear()}9999!`,
    FORBIDDEN: 'This application is not linked to your account',
    DUPLICATE: `Application number already in the list`,
    NOT_RECEIPT: 'The reception of your application has not been finalised yet.',
    ADD: 'Please include the application number using the button + or "Enter" keyboard button.',
};

interface IProps {
    close: any;
    intl: any;
    legalActionTarget?: any;
    actionName?: any;
    openModal?: any;
}

interface IState {
    applicationNumber: any;
    selectedApplicationNumbers: any;
    subject: string;
    comments: string;
    errorType: any;
    documentList: any;
    informationOpened: boolean;
    loading: number;
    DUSOption: any;
    error: any;
    requesterAddress: any;
    factsAndEvidence: any;
    representativeAddress: any;
    applicationNumberError: any;
    timestamp: number;
}

export default class ModalMyPVRPublicSearchOther extends React.Component<IProps, IState> {
    inputUploadRef: any;

    constructor(props: IProps) {
        super(props);
        this.inputUploadRef = React.createRef();
        const selectedApplicationNumbers: any = {};
        (this.props.legalActionTarget || []).forEach(
            (applicationNumber: any) => (selectedApplicationNumbers[applicationNumber] = applicationNumber)
        );
        this.state = {
            applicationNumber: '',
            selectedApplicationNumbers,
            subject: '',
            comments: '',
            errorType: null,
            documentList: [],
            informationOpened: true,
            loading: 0,
            DUSOption: null,
            error: '',
            requesterAddress: null,
            factsAndEvidence: null,
            representativeAddress: null,
            applicationNumberError: null,
            timestamp: Date.now(),
        };
    }

    confirmSending = () => {
        if (Object.keys(this.state.selectedApplicationNumbers).length === 0) {
            this.setState({error: 'No application number linked'});
            return;
        }
        if (!this.state.requesterAddress) {
            this.setState({error: 'Please complete name and address of the requestor'});
            return;
        }
        if (!this.state.factsAndEvidence) {
            this.setState({
                error: 'Please fill or add attachment(s) with facts and evidence in support of the grounds',
            });
            return;
        }
        if (this.state.documentList.filter((i: any) => i.documentType === null).length > 0) {
            this.setState({error: 'You have to select a type for each document'});
            return;
        }
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                const {
                    selectedApplicationNumbers,
                    requesterAddress,
                    representativeAddress,
                    factsAndEvidence,
                    documentList,
                } = this.state;
                apiDocumentUpload(
                    {
                        requesterAddress,
                        representativeAddress,
                        factsAndEvidence,
                        applicationNumbers: Object.keys(selectedApplicationNumbers).join(','),
                    },
                    documentList,
                    this.props.actionName
                )
                    .then((response: any) => {
                        if (response.error === 404 || !response || !response.folder) {
                            this.setState(prev => ({loading: prev.loading - 1, error: '404 - Document not found'}));
                        } else {
                            this.setState(
                                prev => ({loading: prev.loading - 1}),
                                () => {
                                    this.props.openModal({
                                        folder: response.folder,
                                        subject: this.state.subject,
                                        comments: this.state.comments,
                                    });
                                }
                            );
                        }
                    })
                    .catch(this.close);
            }
        );
    };

    close = () => this.props.close && this.props.close();

    closeError = () => this.setState({error: null});

    handleDrop = (files: any, event: any) => this.setState({error: false}, () => this.checkFile(files[0], event));

    checkFile = (file: any, event: any) => {
        LOG([`file.type:${file.type}`]);
        const fileTypeArray = (file.type && file.type.split('/')) || [''];
        const fileType = fileTypeArray.pop();
        const fileTypeLowercase = fileType.toLowerCase();
        const filename = sanitize(file.name);
        this.setState({error: null}, () => {
            if (
                fileTypeLowercase === 'jpeg' ||
                fileTypeLowercase === 'png' ||
                fileTypeLowercase === 'jpg' ||
                fileTypeLowercase === 'pdf'
            ) {
                if (file.size > MAX_UPLOAD_FILE_SIZE) {
                    this.setState({
                        error: 'The maximum document size for uploads is 10 MB. Please select a proper file.',
                    });
                } else {
                    const a = new FileReader();
                    a.readAsBinaryString(file);
                    a.onloadend = () => {
                        const index = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(a.result));
                        this.setState(
                            prev => {
                                const documentList = prev.documentList.slice(0);
                                documentList.push({
                                    index,
                                    file,
                                    filename,
                                    size: file.size / 1024,
                                    extension: fileTypeLowercase,
                                    documentType: null,
                                });
                                return {...prev, documentList, timestamp: Date.now(), informationOpened: false};
                            },
                            () => (event.target.value = null)
                        );
                    };
                }
            } else {
                this.setState({error: 'type not valid'}, () => (event.target.value = null));
            }
        });
    };

    deleteFile = (index: any) =>
        this.setState(prev => ({
            ...prev,
            documentList: prev.documentList.filter((i: any) => i.index !== index),
            timestamp: Date.now(),
            informationOpened: prev.documentList.length === 1,
        }));

    toggleInformation = () => this.setState(prev => ({informationOpened: !prev.informationOpened}));

    checkApplicationNumber = (applicationNumber: any) => {
        let applicationNumberValue = applicationNumber;
        if (!isNaN(applicationNumberValue)) {
            applicationNumberValue = applicationNumberValue.substring(0, 8);
            this.setState({
                applicationNumber: applicationNumberValue,
                applicationNumberError: isRegisterIdValid(applicationNumberValue)
                    ? applicationNumberValue.length === 8
                        ? 'ADD'
                        : null
                    : 'UNKNOWN',
            });
        }
    };
    checkIfSurrenderOrWithdrawal = () =>
        this.setState(prev => ({
            ...prev,
            surrender: prev.documentList.filter((i: any) => i.documentType === 'WithdrawalSurrender').length > 0,
        }));

    onApplicationNumberChange = (applicationNumber: any) => this.checkApplicationNumber(applicationNumber);

    onApplicationNumbersSelectionChange = (selectedApplicationNumbers: any) =>
        this.setState({selectedApplicationNumbers});

    onAddingApplicationNumber = () => {
        const {applicationNumber} = this.state;
        if (applicationNumber.length === 8 && isRegisterIdValid(applicationNumber)) {
            if (this.state.selectedApplicationNumbers[applicationNumber]) {
                this.setState({applicationNumberError: 'DUPLICATE'});
            } else {
                this.setState(prev => {
                    const selectedApplicationNumbers = prev.selectedApplicationNumbers;
                    selectedApplicationNumbers[prev.applicationNumber] = prev.applicationNumber;
                    return {
                        ...prev,
                        applicationNumber: '',
                        applicationNumberError: null,
                        selectedApplicationNumbers,
                    };
                });
            }
        }
    };
    onRequesterAddressChange = ({target: {value: requesterAddress}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({requesterAddress});

    onRepresentativeAddressChange = ({target: {value: representativeAddress}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({representativeAddress});

    onFactsAndEvidenceChange = ({target: {value: factsAndEvidence}}: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({factsAndEvidence});

    onFileUpload = (event: any) => {
        const file = event.target.files[0];
        this.checkFile(file, event);
    };

    onSelectType = (event: any, index: any) => {
        const documentType = event.target.value;
        this.setState(
            prev => ({
                documentList: prev.documentList
                    .slice(0)
                    .map((document: any) =>
                        document.index === index ? Object.assign({}, document, {documentType}) : document
                    ),
            }),
            this.checkIfSurrenderOrWithdrawal
        );
    };

    render() {
        const actions = ModalMyPVRPublicSearchCommonActions(this.deleteFile);
        return (
            <DragAndDrop handleDrop={this.handleDrop}>
                <ModalCustomVersion2
                    close={this.close}
                    header={`Initiate legal proceedings - Other`}
                    body={
                        <>
                            {this.state.documentList.length > 0 && (
                                <CustomTable
                                    version={2}
                                    {...this.props}
                                    notSortable={['filename', 'size', 'documentType']}
                                    tableName={'documentUpload'}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.documentList || []}
                                    dataFilter={null}
                                    id={'index'}
                                    setLastCursor={null}
                                    resultFieldsDefault={['filename', 'size', 'documentType']}
                                    intl={this.props.intl}
                                    formatFunctions={{
                                        size: (size: any) => `${Math.floor(size * 100) / 100} KB`,
                                        extension: (extension: any) => (
                                            <img src={getIcon(extension || 'pdf')} alt={extension} />
                                        ),
                                        documentType: (documentType: any, object: any) => (
                                            <div style={{marginTop: '-5px'}}>
                                                <SelectInput
                                                    value={documentType}
                                                    onChange={(event: any) => this.onSelectType(event, object.index)}
                                                    list={DOCUMENT_TYPES}
                                                    notAll={true}
                                                    height={25}
                                                />
                                                <div style={{clear: 'both'}}>{}</div>
                                            </div>
                                        ),
                                    }}
                                    count={(this.state.documentList || []).length}
                                    hideExcelButton={true}
                                    noChangePageSize={true}
                                    forehandColumn={(row: any) => (
                                        <img
                                            style={{width: 20, height: 20, cursor: 'pointer'}}
                                            alt={'Download'}
                                            src={getIcon(row.extension || 'pdf')}
                                            onClick={event => {
                                                event.stopPropagation();
                                                saveAs(row.file, row.filename);
                                            }}
                                        />
                                    )}
                                    timestamp={this.state.timestamp}
                                    actions={actions}
                                />
                            )}
                            <SharedMessageProceduralRepresentative />
                            <TextLabelInput
                                onSelectionChange={this.onApplicationNumbersSelectionChange}
                                onChange={this.onApplicationNumberChange}
                                onEnter={this.onAddingApplicationNumber}
                                value={this.state.applicationNumber}
                                selectedElements={this.state.selectedApplicationNumbers}
                                delay={300}
                                multiple={true}
                                label={getMandatoryAsterisk('To which application number(s) the document is linked')}
                                buttonAction={this.onAddingApplicationNumber}
                                buttonIcon={faPlus}
                                buttonDisabled={
                                    (this.state.applicationNumberError &&
                                        this.state.applicationNumberError !== 'ADD') ||
                                    this.state.applicationNumber.length !== 8
                                }
                                triple={true}
                            />
                            {this.state.applicationNumberError && (
                                <div style={{textAlign: 'center', width: 700, marginLeft: 40, marginBottom: 20}}>
                                    <Info>
                                        <div style={{display: 'inline-block', marginRight: 5}}>
                                            <FontAwesomeIcon icon={faFlag as any} color={'#8a6d3b'} />
                                        </div>
                                        <div style={{display: 'inline-block'}}>
                                            {errorMessages[this.state.applicationNumberError]}
                                        </div>
                                    </Info>
                                </div>
                            )}
                            <TextAreaInput
                                label={getMandatoryAsterisk('Name and address of the requestor')}
                                value={this.state.requesterAddress}
                                onChange={this.onRequesterAddressChange}
                                rows={3}
                                triple={true}
                                noIcon={true}
                            />
                            <TextAreaInput
                                label={'Name and address of the procedural representative'}
                                value={this.state.representativeAddress}
                                onChange={this.onRepresentativeAddressChange}
                                rows={3}
                                triple={true}
                                noIcon={true}
                            />
                            <TextAreaInput
                                label={getMandatoryAsterisk('Ground of the request')}
                                value={this.state.factsAndEvidence}
                                onChange={this.onFactsAndEvidenceChange}
                                rows={3}
                                triple={true}
                                noIcon={true}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <div style={{marginLeft: 7, marginTop: 10}}>
                                <span style={{color: 'red', fontWeight: 'bold'}}>*</span>
                                {` = compulsory field`}
                            </div>
                            <div>
                                <InputLink
                                    label={`Notes`}
                                    icon={this.state.informationOpened ? faChevronDown : faChevronRight}
                                    clickAction={this.toggleInformation}
                                />
                                <div style={{clear: 'both'}}>{}</div>
                                {this.state.informationOpened && <BrowserInfo intl={this.props.intl} />}
                            </div>
                        </>
                    }
                    footer={
                        <>
                            {this.state.error && <Error>{this.state.error}</Error>}
                            <div className={styles.modalFooterActions}>
                                <div className={styles.buttonAddDocument}>
                                    <label htmlFor={'upload'} style={{display: 'flex'}}>
                                        <input
                                            type="file"
                                            name="files[]"
                                            id="upload"
                                            style={{opacity: 0, width: 0}}
                                            onChange={this.onFileUpload}
                                            accept="image/jpg, image/jpeg, image/png, application/pdf"
                                            ref={this.inputUploadRef}
                                        />
                                        <Button
                                            variation={'secondary'}
                                            clickAction={() => this.inputUploadRef.current.click()}
                                        >{`Add document`}</Button>
                                    </label>
                                </div>
                                <div>
                                    <Button
                                        variation={'danger'}
                                        clickAction={this.close}
                                        icon={'close'}
                                        className={styles.buttonClose}
                                    >{`Close`}</Button>
                                    <Button clickAction={this.confirmSending}>{`Proceed`}</Button>
                                </div>
                            </div>
                        </>
                    }
                />
                {(this.state.loading !== 0 && <HeaderLoading />) || null}
            </DragAndDrop>
        );
    }
}
