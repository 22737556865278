import React from 'react';
import cx from 'classnames';
import InputContainer from '~componentsForm/InputContainer';
import FirstRow from '~componentsForm/FirstRow';
import SecondRow from '~componentsForm/SecondRow';
import FirstRowLabel from '~componentsForm/FirstRowLabel';
import FirstRowInfo from '~componentsForm/FirstRowInfo';
import CustomDatePicker from '~componentsForm/CustomDatePicker';
import stylesNew from './TextLabelInput.module.scss';
import stylesOld from './TextLabelInputOld.module.scss';
import Label from './Label';
import Remark from './Remark';
import CustomPopoverInput from './CustomPopoverInput';

const columnWidth = 180;
export default function ({
    label,
    changeDateFrom,
    changeDateTo,
    inputValueFrom,
    inputValueTo,
    mandatory,
    infoAction,
    disabled,
    simple,
    minDateFrom,
    maxDateFrom,
    minDateTo,
    maxDateTo,
    outsideLabel,
    outsideLabelWithHTML,
    outsideLabelWidth,
    remark,
    isTranslationMissing,
    isRemarkTranslationMissing,
    oldBackOfficeStyles,
    oldBackOfficeStylesError,
    double,
    triple,
    error,
    errorVarietyFinder,
    popOverText,
    popOverTextFirstRow,
    size,
}) {
    let styles = oldBackOfficeStyles ? stylesOld : stylesNew;

    return (
        <React.Fragment>
            {(outsideLabel || outsideLabel === '') && (
                <div className={styles.label}>
                    <Label width={outsideLabelWidth}>{outsideLabel}</Label>
                </div>
            )}
            {(outsideLabelWithHTML || outsideLabelWithHTML === '') && (
                <div className={cx(styles.label, isTranslationMissing && styles.translationMissing)}>
                    <Label width={outsideLabelWidth}>{outsideLabelWithHTML}</Label>
                </div>
            )}
            <InputContainer {...{double, triple, size}}>
                <FirstRow>
                    <FirstRowLabel {...{mandatory, popOverTextFirstRow}}>{label}</FirstRowLabel>
                    {infoAction && <FirstRowInfo onClick={infoAction} />}
                </FirstRow>
                <SecondRow
                    buttonIcon={popOverText}
                    errorBorderColor={errorVarietyFinder}
                    {...{
                        disabled,
                        oldBackOfficeStyles,
                        fit: true,
                    }}
                >
                    <div style={{display: 'table-row'}}>
                        <div style={{display: 'table-cell', width: columnWidth}}>
                            <CustomDatePicker
                                {...{disabled}}
                                placeholder={simple ? 'dd/mm/yyyy' : 'from'}
                                change={changeDateFrom}
                                inputValue={inputValueFrom}
                                width={simple && 270}
                                minDate={minDateFrom}
                                maxDate={maxDateFrom}
                                oldBackOfficeStyles={oldBackOfficeStyles}
                                oldBackOfficeStylesError={oldBackOfficeStylesError}
                                errorMessage={error}
                            />
                        </div>
                        {!simple && (
                            <div
                                style={{
                                    display: 'table-cell',
                                    borderLeftStyle: 'solid',
                                    borderLeftWidth: 1,
                                    borderColor: 'white',
                                    width: columnWidth,
                                }}
                            >
                                <CustomDatePicker
                                    {...{disabled}}
                                    placeholder={`to`}
                                    change={changeDateTo}
                                    inputValue={inputValueTo}
                                    minDate={minDateTo}
                                    maxDate={maxDateTo}
                                    oldBackOfficeStyles={oldBackOfficeStyles}
                                    oldBackOfficeStylesError={oldBackOfficeStylesError}
                                />
                            </div>
                        )}
                    </div>
                </SecondRow>
                {remark && (
                    <div>
                        <Remark remark={remark} isRemarkTranslationMissing={isRemarkTranslationMissing} />
                    </div>
                )}
                {popOverText && <CustomPopoverInput content={popOverText} />}
                {errorVarietyFinder ? (
                    <div style={{marginLeft: 3, marginTop: 5, color: 'red'}}>{errorVarietyFinder}</div>
                ) : null}
            </InputContainer>
        </React.Fragment>
    );
}
