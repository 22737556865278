import React from 'react';
import {Button} from '../../../../../../componentsFormV2';
import {ModalCustomVersion2} from '../../../../../../commonModals';
import styles from './ModalDocumentTypesExplanationsInfo.module.scss';

interface ModalDocumentTypesExplanationsInfoProps {
    close: () => void;
}

const ModalDocumentTypesExplanationsInfo = (props: ModalDocumentTypesExplanationsInfoProps) => {
    const close = () => props.close && props.close();

    return (
        <ModalCustomVersion2
            loading={false}
            plus2={true}
            close={close}
            header={`Explanations on documents types`}
            body={
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>
                                <a>{'Document Type'}</a>
                            </th>
                            <th>
                                <a>{'Definition'}</a>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{`Cover Letter for Reports`}</td>
                            <td>{`Cover letter which may be sent along with the examination reports.`}</td>
                        </tr>
                        <tr>
                            <td>{`Examination procedure - Other documents`}</td>
                            <td>
                                {`Please make use of the document type if attached does not correspond to any other types.`}
                            </td>
                        </tr>
                        <tr>
                            <td>{`Final Report`}</td>
                            <td>{`Document sent by the EO at the end of the technical examination.`}</td>
                        </tr>
                        <tr>
                            <td>{`Interim Report`}</td>
                            <td>{`Document sent by the EO each year when a technical examination lasts several years.`}</td>
                        </tr>
                        <tr>
                            <td>{`Invoice`}</td>
                            <td>{`Invoice of the TE, TO or TV, sent by the EO.`}</td>
                        </tr>
                        <tr>
                            <td>{`Photo Final Report`}</td>
                            <td>
                                {`Photo of the variety that may be sent by the EO to the CPVO with the final report and
                            variety description (separate documents).`}
                            </td>
                        </tr>
                        <tr>
                            <td>{`Preliminary Report`}</td>
                            <td>
                                {`Answer of the EO when the EO receive a purchase order either for a technical examination
                            of a variety or for taking over a report.`}
                            </td>
                        </tr>

                        <tr>
                            <td>{`Request for Plant Material`}</td>
                            <td>
                                {`Overview of the list of varieties to be tested in the next growing period with the plant
                            material requirements and deadlines when the CPVO organizes a technical examination.`}
                            </td>
                        </tr>
                        <tr>
                            <td>{`Technical Verification`}</td>
                            <td>
                                {`Please make use of this document type to inform the CPVO about unanswered sample
                            requests (1 request per titleholder).`}
                            </td>
                        </tr>
                        <tr>
                            <td>{`Variety Description`}</td>
                            <td>
                                {`Document sent by the EO at the end of technical examination in case of positive report,
                            this document contains the technical description of the variety.`}
                            </td>
                        </tr>
                        <tr>
                            <td>{`Withdrawal Confirmation`}</td>
                            <td>{`Answer of the EO after receiving a withdrawal notification sent by the CPVO.`}</td>
                        </tr>
                    </tbody>
                </table>
            }
            footer={<Button clickAction={close}>{`Close`}</Button>}
        />
    );
};

export default ModalDocumentTypesExplanationsInfo;
