import React from 'react';
import {injectIntl} from 'react-intl';
import ApplicationConsultationTabs, {ACCESS, TAB} from '~shared/ApplicationConsultationTabs';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import DataSheetFormFields from '~shared/DataSheetFormFields';
import {FORMAT_DATE_EASY, formatDateEasy} from '~components/FormatFunctions';
import CustomTable from '~components/CustomTable';
import {Footer, FormWrapper, HeaderLoading, HeaderLogo, HeaderTitleAndVersion} from '../../componentsLayout';
import NavigationMyPVR from '../../shared/NavigationMyPVR';
import {loadUrlParams, trackPageView} from '../../utils';
import {apiPublicSearchExaminationReports, apiPublicSearchExaminations} from '../../commonApi/publicSearch';
import {IExaminationItem, IExaminationReportItem} from '../../types';
import MainWrapper from '../../componentsLayout/MainWrapper';
import NavigationTLO from '../../shared/NavigationTLO';

interface IProps {
    close: any;
    intl: any;
}

interface IState {
    examination: IExaminationItem | null;
    examinationReports: IExaminationReportItem[] | null;
    loading: boolean;
    timestamp: number;
}

class MyPVRPrivateConsultationExamination extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {examination: null, examinationReports: null, timestamp: Date.now(), loading: false};
    }

    componentDidMount() {
        trackPageView({documentTitle: 'privateConsultationExamination'});
    }

    loadJSONs = (applicationNumber: string) =>
        new Promise((resolve, reject) => {
            this.setState({loading: true}, () => {
                const loadExaminationData = this.loadExaminationData(applicationNumber);
                const loadExaminationReportsData = this.loadExaminationReportsData(applicationNumber);
                Promise.all([loadExaminationData, loadExaminationReportsData])
                    .then((results: any) =>
                        this.setState(results.reduce((prev: any, current: any) => Object.assign({}, prev, current)))
                    )
                    .catch(error => LOG([`ERROR ${error}`]))
                    .then(() => this.setState({loading: false}), resolve)
                    .catch(reject);
            });
        });

    loadExaminationData = (applicationNumber: string) =>
        new Promise((resolve, reject) => {
            apiPublicSearchExaminations(applicationNumber)
                .then(JSONResponse => {
                    JSONResponse.data && resolve({examination: JSONResponse.data});
                })
                .catch(reject);
        });

    loadExaminationReportsData = (applicationNumber: string) =>
        new Promise((resolve, reject) => {
            apiPublicSearchExaminationReports(applicationNumber)
                .then((jsonResponse: any) => {
                    jsonResponse.data && resolve({examinationReports: jsonResponse.data, timestamp: Date.now()});
                })
                .catch(reject);
        });

    render() {
        const {examination, examinationReports} = this.state || {};
        const urlParams = loadUrlParams();

        return (
            <>
                {this.state.examination === null ? <HeaderLoading /> : null}
                <HeaderLogo />
                <HeaderTitleAndVersion title={`My PVR`} />
                {urlParams.tlo ? <NavigationTLO activeTitle={`My Applications`} /> : <NavigationMyPVR />}
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <ApplicationConsultationTabs
                            search={this.loadJSONs}
                            tabId={TAB.EXAMINATION}
                            access={ACCESS.PRIVATE}
                            close={this.props.close}
                            loading={this.state.loading}
                        />
                        {examination ? (
                            <>
                                <DataSheetFormSection title={'Examination'}>
                                    <DataSheetFormFields
                                        label={'Examination Office'}
                                        data={
                                            examination.examinationOffice && examination.examinationOffice.split('\n')
                                        }
                                        loading={this.state.loading}
                                        ready={examination}
                                        double={true}
                                    />
                                    <DataSheetFormFields
                                        label={'Testing office'}
                                        data={examination.testingSite && examination.testingSite.split('\n')}
                                        loading={this.state.loading}
                                        ready={examination}
                                        double={true}
                                    />
                                    <DataSheetFormFields
                                        label={'Type'}
                                        data={examination.type}
                                        loading={this.state.loading}
                                        ready={examination}
                                    />
                                    <DataSheetFormFields
                                        label={'Status'}
                                        data={examination.status}
                                        loading={this.state.loading}
                                        ready={examination}
                                    />
                                    <DataSheetFormFields
                                        label={'Examination reference'}
                                        data={examination.examinationReference}
                                        loading={this.state.loading}
                                        ready={examination}
                                        double={true}
                                    />
                                    <DataSheetFormFields
                                        label={'Report / Examination requesting date'}
                                        data={formatDateEasy(examination.reportExaminationRequestingDate)}
                                        loading={this.state.loading}
                                        ready={examination}
                                    />
                                </DataSheetFormSection>
                                <DataSheetFormSection title={'Material submission'}>
                                    <DataSheetFormFields
                                        label={'From'}
                                        data={formatDateEasy(examination.materialSubmissionFrom)}
                                        loading={this.state.loading}
                                        ready={examination}
                                    />
                                    <DataSheetFormFields
                                        label={'To'}
                                        data={formatDateEasy(examination.materialSubmissionTo)}
                                        loading={this.state.loading}
                                        ready={examination}
                                    />
                                    <DataSheetFormFields
                                        label={'Requested on'}
                                        data={formatDateEasy(examination.requestedOn)}
                                        loading={this.state.loading}
                                        ready={examination}
                                        double={true}
                                    />
                                    <DataSheetFormFields
                                        label={'Quantity Quality'}
                                        data={
                                            examination &&
                                            examination.quantityQuality &&
                                            examination.quantityQuality.split('\n')
                                        }
                                        loading={this.state.loading}
                                        ready={examination}
                                        double={true}
                                    />
                                </DataSheetFormSection>
                            </>
                        ) : null}
                        <DataSheetFormSection title={'Examination reports'}>
                            <CustomTable
                                tableName={'examinationReports'}
                                tableType={'OBJECT'}
                                loading={this.state.loading}
                                tableSource={examinationReports}
                                timestamp={this.state.timestamp}
                                resultFieldsDefault={[
                                    'type',
                                    'status',
                                    'foreseenDate',
                                    'arrivalDate',
                                    'transmissionDate',
                                ]}
                                defaultOrder={'foreseenDate'}
                                intl={this.props.intl}
                                formatFunctions={{
                                    foreseenDate: FORMAT_DATE_EASY,
                                    arrivalDate: FORMAT_DATE_EASY,
                                    transmissionDate: FORMAT_DATE_EASY,
                                }}
                                bold={() => true}
                            />
                        </DataSheetFormSection>
                    </FormWrapper>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(MyPVRPrivateConsultationExamination);
