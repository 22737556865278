import 'babel-polyfill';
import {initializeLogger} from './Logger';

export const DEMO_DOMAIN = 'demo.plantvarieties.eu';
export const DEV_DOMAIN = 'dev.plantvarieties.eu';
export const MOCK_DOMAIN = 'mock.plantvarieties.eu';
export const PREVIEW_DOMAIN = 'preview.plantvarieties.eu';
export const PUBLIC_DOMAIN = 'public.plantvarieties.eu';
export const PUBLIC2_DOMAIN = 'public2.plantvarieties.eu';
export const ONLINE_DOMAIN = 'online.plantvarieties.eu';
export const TLO_DOMAIN = 'online.plantvarieties.eu';
export const AC_DOMAIN = 'online.plantvarieties.eu';
export const CASELAW_DOMAIN = 'online.plantvarieties.eu';
export const ONLINE2_DOMAIN = 'online2.plantvarieties.eu';
export const LOCALHOST = 'localhost:3080';
export const UUID = '8b12eb11-cc94-4983-996f-a50ac33189c5';

Object.values =
    Object.values ||
    function (obj) {
        return Object.keys(obj).map(function (e) {
            return obj[e];
        });
    };

export const getCookieValue = name => document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

function deleteCookie(name) {
    document.cookie =
        name + '=;domain=.plantvarieties.eu;path=/; Secure; SameSite=Strict; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
}

const authorizationChanges = () => {
    const cookieValue = getCookieValue('token');
    deleteCookie('token');
    const remember = localStorage.getItem('remember') === '1';
    let authToken = (remember ? localStorage : sessionStorage).getItem('token');
    if (!authToken || authToken === 'null') {
        if (cookieValue.length > 0) {
            authToken = (remember ? localStorage : sessionStorage).setItem('token', cookieValue);
        }
    }
};

export function getDomainAndAutorization() {
    authorizationChanges();
    initializeLogger();
    const url = document.location.href;
    const domain = url.split('/')[2];
    const onlinePdf = url.toString().indexOf('onlinePdf') !== -1;
    const backOfficePdf = url.toString().indexOf('backOfficePdf') !== -1;
    if (domain.split(':')[0] === 'localhost') {
        self.DEV_MOVE = true;
    } else {
        self.ENVIRONMENT = domain.split('.')[0];
    }

    let authorization = false;
    if (domain !== ONLINE_DOMAIN && (url.split('?').pop() === UUID || localStorage.UUID === UUID)) {
        localStorage.UUID = UUID;
        authorization = true;
    }
    return {domain, authorization, onlinePdf, backOfficePdf};
}
