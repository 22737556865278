import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import styles from './DataSheetFormFields.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popover} from 'react-tiny-popover';

export default function ({
    label,
    data,
    html,
    loading,
    double,
    buttonIcon,
    buttonAction,
    labelIcon,
    labelPopup,
    labelIconEmpty,
    disabled,
    mandatory,
    containerClassName,
    sectionRowWidth,
    cellDataWidth,
}) {
    const [effect, setEffect] = useState(true);
    let elData = null;

    useEffect(() => {
        setEffect(loading);
        if (html !== null && html !== undefined && elData) {
            elData.innerHTML = html;
        }
    });
    return (
        <div
            className={cx(styles.sectionRow, double ? styles.sectionRowDouble : null)}
            style={Object.assign({}, sectionRowWidth && {width: sectionRowWidth})}
        >
            {labelPopup
                ? renderLabelWithPopup(
                      label,
                      labelIcon,
                      labelPopup,
                      labelIconEmpty,
                      disabled,
                      mandatory,
                      containerClassName
                  )
                : renderLabel(label, labelIcon, labelPopup, labelIconEmpty, disabled, mandatory)}
            <div
                ref={el => (elData = el)}
                className={cx(
                    styles.cellData,
                    buttonIcon && styles.cellDataIcon,
                    double && styles.cellDataDouble,
                    double && buttonIcon && styles.cellDataDoubleIcon,
                    effect ? styles.lightUI1Shimmer1 : styles.lightUI1Shimmer2,
                    html !== null && html !== undefined && styles.cellDataHTML
                )}
                style={Object.assign({}, cellDataWidth && {width: cellDataWidth})}
            >
                {Array.isArray(data) ? data.map(line => <div>{line}</div>) : data}
            </div>
            {buttonIcon && (
                <button type="button" className={cx(styles.button)} onClick={buttonAction}>
                    <FontAwesomeIcon icon={buttonIcon} color="green" />
                </button>
            )}
            <div style={{clear: 'both'}}>{}</div>
        </div>
    );
}

function renderLabel(label, labelIcon, labelPopup, labelIconEmpty, disabled, mandatory) {
    return (
        <div className={cx(styles.cellHeader, labelPopup && !disabled && styles.cellHeaderAction)}>
            {labelIcon && !disabled ? (
                <div className={styles.labelIcon}>
                    <FontAwesomeIcon icon={labelIcon} color="green" />
                </div>
            ) : null}
            {labelIconEmpty && !disabled ? <div className={styles.labelIcon} /> : null}
            <div className={styles.label}>{`${label}:`}</div>
            {mandatory ? <div className={styles.mandatory}>*</div> : null}
        </div>
    );
}

let functionIsSet = false;
let channel = null;
let closePopover = null;

function renderLabelWithPopup(label, labelIcon, labelPopup, labelIconEmpty, disabled, mandatory, containerClassName) {
    const [isPopoverOpen, setPopover] = useState(false);
    const componentChannel = Date.now();
    const runPopoverCloseChecker = () => {
        if (functionIsSet === true) {
            functionIsSet = false;
            setPopover(false);
        } else {
            functionIsSet = true;
        }
    };
    const setPopoverHandler = visibility => {
        if (visibility && functionIsSet) {
            functionIsSet = false;
        }
        if (visibility && channel !== componentChannel) {
            closePopover && closePopover();
        }
        channel = componentChannel;
        closePopover = visibility ? () => setPopover(false) : null;
        window.runPopoverCloseChecker = visibility ? runPopoverCloseChecker : null;
        setPopover(!isPopoverOpen);
    };
    return disabled ? (
        renderLabel(label, labelIcon, labelPopup, labelIconEmpty, disabled, mandatory)
    ) : (
        <Popover
            isOpen={isPopoverOpen}
            positions={'bottom'}
            onClickOutside={() => setPopoverHandler(!isPopoverOpen)}
            containerClassName={containerClassName}
            content={() => (
                <div style={{display: 'inline-block'}} onClick={() => setPopoverHandler(!isPopoverOpen)}>
                    <div
                        className="custom-popover"
                        style={{
                            background: 'white',
                            color: 'black',
                            borderRadius: '4px',
                            maxWidth: '250px',
                            zIndex: 99,
                            position: 'relative',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: '#ccc',
                        }}
                    >
                        <div>
                            {(labelPopup || []).map(action => (
                                <ul className={styles.menuPopup}>
                                    <li className={styles.menuElement} onClick={action.handler}>
                                        <div className={styles.menuElementIcon}>
                                            <FontAwesomeIcon icon={action.icon} />
                                        </div>
                                        <div className={styles.menuElementLabel}>{action.label}</div>
                                    </li>
                                </ul>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        >
            <div style={{display: 'inline-block', height: 21}} onClick={() => setPopoverHandler(!isPopoverOpen)}>
                {renderLabel(label, labelIcon, labelPopup, labelIconEmpty, disabled, mandatory)}
            </div>
        </Popover>
    );
}
