import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';

export interface ISearchQuestionItem {
    groupId: number;
    groupMessage: string;
    groupOrder: string;
    message: string;
    officeId: number;
    order: string;
    questionId: number;
    questionResponses: {
        responseOrder: string;
        responseMessage: string;
    }[];
    sameOffice: true;
}

export interface ISearchGroups {
    [key: string]: {message: string; order: string};
}

export function apiBackOfficeFormQuestionSearch(
    criteria: any,
    DEFAULT_CRITERIA: any = {}
): Promise<{
    data: {
        COUNT: number;
        groups: ISearchGroups;
        questions: ISearchQuestionItem[];
    };
}> {
    const params = {...criteria};
    const requestParams = Object.keys(params)
        .filter(i => params[i] !== DEFAULT_CRITERIA[i])
        .map(k => `${k}=${params[k]}`);
    return getFetch(`${API_SERVERLESS}/backOffice/v3/formQuestionSearch?${requestParams.join('&')}`, true);
}

export function apiBackOfficeLabelAddQuestionToGroup(jsonData: {formId: number; questionId: number}): Promise<{
    data: string;
}> {
    return postFetch(`${API_SERVERLESS}/backOffice/v3/formQuestionInsert`, true, jsonData);
}
