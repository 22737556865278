import React from 'react';

interface IIconUserSpeakProps {
    style?: any;
    className?: any;
    color?: string;
}

export default function ({style: userStyle, color, className}: IIconUserSpeakProps) {
    return (
        <svg
            width="20"
            height="20"
            style={{...(userStyle || {})}}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M8.33333 8.33335C10.1743 8.33335 11.6667 6.84097 11.6667 5.00002C11.6667 3.15907 10.1743 1.66669 8.33333 1.66669C6.49238 1.66669 5 3.15907 5 5.00002C5 6.84097 6.49238 8.33335 8.33333 8.33335Z"
                strokeWidth="1.5"
                stroke={color || '#999999'}
            />
            <path
                d="M15.8333 1.66669C15.8333 1.66669 17.5 2.66669 17.5 5.00002C17.5 7.33335 15.8333 8.33335 15.8333 8.33335M14.1667 3.33335C14.1667 3.33335 15 3.83335 15 5.00002C15 6.16669 14.1667 6.66669 14.1667 6.66669M14.9975 15C15 14.8634 15 14.7242 15 14.5834C15 12.5125 12.015 10.8334 8.33332 10.8334C4.65166 10.8334 1.66666 12.5125 1.66666 14.5834C1.66666 16.6542 1.66666 18.3334 8.33332 18.3334C10.1925 18.3334 11.5333 18.2025 12.5 17.9692"
                stroke={color || '#999999'}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
}
