import React from 'react';
import ModalCustomVersion2 from './ModalCustomVersion2';
import {Button} from '../componentsFormV2';
import {getContentWithCorrectImagePath} from '~utils/index';

interface IProps {
    close: any;
    intl: any;
    valueHTML?: any;
}

interface IState {}

export default class ModalLabelPreviewVersion2 extends React.Component<IProps, IState> {
    elContent: any = null;

    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (this.elContent && this.props.valueHTML) {
            this.elContent.innerHTML = getContentWithCorrectImagePath(this.props.valueHTML);
        }
    }

    close = (reload: any) => this.props.close && this.props.close(reload);

    render() {
        return (
            <ModalCustomVersion2
                close={this.close}
                header={`Preview`}
                body={<div ref={el => (this.elContent = el)} />}
                footer={<Button variation={'danger'} clickAction={this.close} icon={'close'}>{`Close`}</Button>}
                plus2={true}
            />
        );
    }
}
