import React from 'react';
import {injectIntl} from 'react-intl';
import ApplicationConsultationTabs, {ACCESS, TAB} from '~shared/ApplicationConsultationTabs';
import CustomTable from '~components/CustomTable';
import getIcon from '~utils/icons';
import {saveAs} from 'file-saver';
import {FORMAT_DATE_EASY} from '~components/FormatFunctions';
import {getFeeRowClass} from '~utils/index';
import MyPVRPrivateConsultationFeesTopCaption from './MyPVRPrivateConsultationFeesTopCaption';
import MyPVRPrivateConsultationFeesActionButtons from './MyPVRPrivateConsultationFeesActionButtons';
import {getPreSignedURLFetchRequest} from '../../utils/requests';
import pako from 'pako';
import {Footer, FormWrapper, HeaderLoading, HeaderLogo, HeaderTitleAndVersion} from '../../componentsLayout';
import NavigationMyPVR from '../../shared/NavigationMyPVR';
import {ModalNoteDetailsVersion2} from '../../commonModals';
import {apiGetNote, apiNotesSearch} from './MyPVRPrivateConsultationFeesService';
import {apiDownload} from '../../commonApi/download';
import {INote, INoteLine} from '../../types';
import MainWrapper from '../../componentsLayout/MainWrapper';
import {trackPageView} from '../../utils';

interface IProps {
    close: any;
    intl: any;
}

interface IState {
    downloading: boolean;
    loading: boolean;
    noteLines: INoteLine[] | null;
    notes: INote[] | null;
}

class MyPVRPrivateConsultationFees extends React.Component<IProps, IState> {
    selectedNoteId: string = '';

    constructor(props: IProps) {
        super(props);
        this.state = {notes: null, downloading: false, loading: false, noteLines: null};
    }

    componentDidMount() {
        trackPageView({documentTitle: 'privateConsultationFees'});
    }

    loadJSONs = (applicationNumber: string) =>
        new Promise((resolve, reject) => {
            this.setState({loading: true}, () => {
                apiNotesSearch({applicationNumber, pageSize: 200})
                    .then(JSONResponse => {
                        JSONResponse.data && JSONResponse.data.notes && this.setState({notes: JSONResponse.data.notes});
                    })
                    .then(() => this.setState({loading: false}, () => resolve))
                    .catch(reject);
            });
        });

    showNoteLines = (noteId: string) =>
        this.setState({loading: true}, () => {
            this.selectedNoteId = noteId;
            apiGetNote(noteId)
                .then(JSONResponse => {
                    if (JSONResponse && JSONResponse.notelines) {
                        this.setState({
                            noteLines: JSONResponse.notelines,
                        });
                    }
                })
                .catch((error: any) => {
                    ERROR([`MyPVR Finances showNoteLines error: ${error.message}`]);
                })
                .then(() => this.setState({loading: false}));
        });

    closeNoteLinesModal = () => this.setState({noteLines: null});

    downloadDocument = (note: INote) =>
        this.setState({downloading: true}, () => {
            apiDownload(note.csDocsKey || '')
                .then((JSONResponse: any) => {
                    if (JSONResponse && JSONResponse.documents) {
                        let preSignedURL;
                        let fileName: any;
                        let isZip: any;
                        if (Array.isArray(JSONResponse.documents)) {
                            preSignedURL = JSONResponse.documents[0]?.signedUrl;
                            fileName = JSONResponse.documents[0]?.docName;
                            isZip = JSONResponse.documents[0]?.isZip;
                        } else if (JSONResponse.documents.signecUrl && JSONResponse.documents.fileName) {
                            preSignedURL = JSONResponse.documents.signecUrl;
                            fileName = JSONResponse.documents?.fileName;
                            isZip = JSONResponse.documents?.isZip;
                        }
                        if (preSignedURL && fileName) {
                            getPreSignedURLFetchRequest(preSignedURL)
                                .then(response => response.blob())
                                .then(responseBlob => {
                                    if (isZip) {
                                        responseBlob.arrayBuffer().then(fileReader => {
                                            saveAs(new Blob([pako.ungzip(new Uint8Array(fileReader))]), fileName);
                                        });
                                    } else {
                                        saveAs(responseBlob, fileName);
                                    }
                                });
                        }
                    }
                })
                .then(() => this.setState({downloading: false}));
        });

    render() {
        const actions = MyPVRPrivateConsultationFeesActionButtons(this.props, this.showNoteLines);

        return (
            <>
                {this.state.loading ? <HeaderLoading /> : null}
                {this.state.downloading ? <HeaderLoading message={`Downloading ...`} /> : null}
                {this.state.noteLines !== null ? (
                    <ModalNoteDetailsVersion2
                        title={`Details note ${this.selectedNoteId}`}
                        noteLines={this.state.noteLines}
                        intl={this.props.intl}
                        close={this.closeNoteLinesModal}
                    />
                ) : null}
                <HeaderLogo />
                <HeaderTitleAndVersion title={`My PVR`} />
                <NavigationMyPVR />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <ApplicationConsultationTabs
                            search={this.loadJSONs}
                            tabId={TAB.FEES}
                            access={ACCESS.PRIVATE}
                            loading={this.state.notes === null}
                            close={this.props.close}
                        />
                        {this.state.notes ? (
                            <>
                                {this.state.notes.length === 0 ? (
                                    <div style={{color: 'red', textAlign: 'center'}}>
                                        <b style={{textAlign: 'center'}}>
                                            <span>{`No documents available`}</span>
                                        </b>
                                    </div>
                                ) : (
                                    <CustomTable
                                        actions={actions}
                                        defaultOrder={'arrivalDate'}
                                        forehandColumn={(note: INote) =>
                                            note.csDocsKey ? (
                                                <img
                                                    style={{width: 20, height: 20, cursor: 'pointer'}}
                                                    alt={'Download'}
                                                    src={getIcon(note.extension || 'pdf')}
                                                    onClick={event => {
                                                        event.stopPropagation();
                                                        note.csDocsKey && this.downloadDocument(note);
                                                    }}
                                                />
                                            ) : (
                                                <span>{}</span>
                                            )
                                        }
                                        formatFunctions={{
                                            note: (fieldData: any) => fieldData.noteId,
                                            amount: (fieldData: any) => `${fieldData}.00 €`,
                                            sendingDate: FORMAT_DATE_EASY,
                                            dueDate: FORMAT_DATE_EASY,
                                            balanceDate: FORMAT_DATE_EASY,
                                        }}
                                        id={['noteId', 'applicationNumber']}
                                        intl={this.props.intl}
                                        pageSize={100}
                                        resultFieldsDefault={[
                                            'noteId',
                                            'feeType',
                                            'status',
                                            'amount',
                                            'sendingDate',
                                            'dueDate',
                                            'balanceDate',
                                        ]}
                                        reverseOrder={true}
                                        rowClass={getFeeRowClass}
                                        tableName={'myApplicationsNotes'}
                                        tableSource={this.state.notes}
                                        tableType={'OBJECT'}
                                        topCaption={<MyPVRPrivateConsultationFeesTopCaption />}
                                    />
                                )}
                            </>
                        ) : null}
                    </FormWrapper>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(MyPVRPrivateConsultationFees);
