import React from 'react';
import cx from 'classnames';
import styles from './StatusField.module.scss';

const statusStyle: any = {1: styles.pending, 2: styles.success, 3: styles.terminated, 4: styles.scheduled};

export default function StatusField({intl, statusId, counter, validFrom}: any) {
    let label = (statusId && intl.formatMessage({id: `label.status.${statusId}`})) || '';
    let appliedStatusStyle = parseInt(statusId);

    if (statusId === 1 && validFrom) {
        label = 'Scheduled';
        appliedStatusStyle = 4;
    }

    return (
        statusId && (
            <div className={styles.container}>
                <div className={cx(styles.field, statusStyle[appliedStatusStyle])}>
                    <span>{`${counter ? `${counter} ` : ''}${counter ? label.toLowerCase() : label}`}</span>
                </div>
            </div>
        )
    );
}
