import React from 'react';
import {injectIntl, IntlShape} from 'react-intl';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {RequestedEntrustment} from '../../NewSpeciesProcedurePageService';
import styles from './ModalListOfRequestedEntrustments.module.scss';
import {ModalCustomVersion2} from '../../../../../../commonModals';
import CustomTable from '../../../../../../components/CustomTable';
import DataSheetFormFields from '../../../../../../shared/DataSheetFormFields';
import {renderHTML} from '../../../../../../common/format';
import {History} from 'history';
import {Button} from '../../../../../../componentsFormV2';

interface ModalListOfRequestedEntrustmentsProps {
    intl: IntlShape;
    history: History;
    requestedEntrustmentsList: Array<RequestedEntrustment>;
    eoName: string;
    close: () => void;
}

const ModalListOfRequestedEntrustments = (props: ModalListOfRequestedEntrustmentsProps & RouteComponentProps) => {
    const getFormatedSpeciesName = (formattedSpeciesName: string, rowObject: RequestedEntrustment) => {
        return rowObject.eoInterested === 1 ? (
            <p style={{margin: 0}} className="ng-scope">
                <DataSheetFormFields
                    label={undefined}
                    mandatory={undefined}
                    html={renderHTML(formattedSpeciesName)}
                    loading={false}
                    disabled={true}
                    double={true}
                    containerClassName={undefined}
                    labelIcon={undefined}
                    labelPopup={undefined}
                    data={undefined}
                    buttonIcon={undefined}
                    buttonAction={undefined}
                    labelIconEmpty={undefined}
                    sectionRowWidth={undefined}
                    cellDataWidth={undefined}
                />
            </p>
        ) : (
            <p style={{margin: 0}} className="ng-scope">
                <span className={styles.redColorLineThrough}>
                    <DataSheetFormFields
                        label={undefined}
                        mandatory={undefined}
                        html={renderHTML(formattedSpeciesName)}
                        loading={false}
                        disabled={true}
                        double={true}
                        containerClassName={undefined}
                        labelIcon={undefined}
                        labelPopup={undefined}
                        data={undefined}
                        buttonIcon={undefined}
                        buttonAction={undefined}
                        labelIconEmpty={undefined}
                        sectionRowWidth={undefined}
                        cellDataWidth={undefined}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <span className={styles.redColor}>
                        {`request for entrustment withdrawn on ${rowObject.humanModifDate}`}
                    </span>
                </span>
            </p>
        );
    };

    return (
        <div className={'listOfRequestedEntrustments'}>
            <ModalCustomVersion2
                loading={false}
                close={() => props.close && props.close()}
                header={`List of requested entrustments for ${props.eoName}`}
                body={
                    <CustomTable
                        pageSize={100}
                        tableName={'requestedEntrustmentsList'}
                        tableType={'OBJECT'}
                        tableSource={props.requestedEntrustmentsList}
                        id={'eoProposalNumber'}
                        resultFieldsDefault={['eoProposalNumber', 'formattedSpeciesName']}
                        intl={props.intl}
                        defaultOrder={'eoProposalNumber'}
                        reverseOrder={false}
                        hiddenHeader={true}
                        formatFunctions={{
                            eoProposalNumber: (eoProposalNumber: number, _rowObject: RequestedEntrustment) => {
                                return (
                                    <p className={styles.proposalNumberContainer}>
                                        <span>• {eoProposalNumber}</span>
                                    </p>
                                );
                            },
                            formattedSpeciesName: (formattedSpeciesName: string, rowObject: RequestedEntrustment) => {
                                return getFormatedSpeciesName(formattedSpeciesName, rowObject);
                            },
                        }}
                    />
                }
                footer={
                    <Button icon={'close'} clickAction={props.close} variation={'danger'}>
                        {`Close`}
                    </Button>
                }
            />
        </div>
    );
};

export default injectIntl(withRouter(ModalListOfRequestedEntrustments));
