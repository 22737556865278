import React from 'react';

export const SharedMessageProceduralRepresentative = () => {
    return (
        <div style={{paddingLeft: 7, marginBottom: 15, fontWeight: 800}}>
            {`* Are you a procedural representative? If yes, you should provide a `}
            <a
                href="https://cpvo.europa.eu/en/applications-and-examinations/filing-application/filing-paper"
                target="_blank"
                rel="noopener noreferrer"
            >
                power of attorney (pdf)
            </a>
            {` using "+ Add document".`}
        </div>
    );
};

export const SharedMessageAfterTheGrantOfARight = () => {
    return (
        <div style={{padding: 15, paddingLeft: 7}}>
            {
                'After the grant of a right, it possible to request the cancellation of a right that does not comply with certain requirements anymore. If the cancellation is successful, the right will be invalid as of the day of the decision. See Article 21 Regulation (EC) 2100/1994.'
            }
        </div>
    );
};
