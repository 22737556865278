import React from 'react';
import InputContainer from '~componentsForm/InputContainer';
import Link from './Link';
import styles from './InputLink.module.scss';

export default function ({label, clickAction, icon, width, offset, disabled}) {
    return (
        <InputContainer {...{width}}>
            <div className={styles.left}>
                <Link {...{label, clickAction, icon, offset, disabled}} />
            </div>
        </InputContainer>
    );
}
