import React from 'react';
import {injectIntl} from 'react-intl';
import styles from './OnlineApplicationsModalDuplicateGroupsTQ.module.scss';
import CustomTable from '~components/CustomTable';
import {htmlToText} from '~utils';
import {apiGetFormDraft} from './OnlineApplicationsModalDuplicateGroupsTQService';
import {LoadingBar} from '../../componentsLayout';
import {ModalCustomVersion2} from '../../commonModals';
import {Button} from '../../componentsFormV2';

interface IProps {
    close: any;
    clickApplicationRequest: any;
    intl: any;
    onButtonBackClick: any;
    onButtonNextClick: any;
    selectedGroupsOrTemplates: any[] | null;
    setSelectedGroupsOrTemplates: any;
}

interface IState {
    loading: number;
    timestamp: number;
    applicationForm: any;
    formsFullList: any[];
    technicalQuestionnaire: any;
    selectedApplicationFormGroupsOrTemplates: any[];
    selectedTechnicalQuestionnaireGroupsOrTemplates: any[];
}

class OnlineApplicationsModalDuplicateGroupsTQ extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            formsFullList: [],
            loading: 0,
            timestamp: Date.now(),
            applicationForm: null,
            technicalQuestionnaire: null,
            selectedApplicationFormGroupsOrTemplates: [],
            selectedTechnicalQuestionnaireGroupsOrTemplates: [],
        };
    }

    componentDidMount() {
        this.loadJSONs();
    }

    loadJSONs = () => {
        const promises = [this.loadTechnicalQuestionnaire()];
        Promise.all(promises).then(() => {
            if (!Array.isArray(this.props.selectedGroupsOrTemplates)) {
                this.props.setSelectedGroupsOrTemplates(Object.keys(this.getTechnicalQuestionnaireSelectDefault()));
            }
        });
    };

    loadTechnicalQuestionnaire = () =>
        new Promise(resolve => {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    const {applicationRequestId, formIdTQ} = this.props.clickApplicationRequest || {};
                    apiGetFormDraft({
                        applicationId: applicationRequestId,
                        formId: formIdTQ,
                        formType: 'TQ',
                    })
                        .then((JSONResponse: any) => {
                            if (JSONResponse) {
                                this.setState(
                                    prev => ({
                                        loading: prev.loading - 1,
                                        timestamp: Date.now(),
                                        technicalQuestionnaire: JSONResponse.form,
                                        formsFullList: JSONResponse.formsFullList,
                                    }),
                                    () => resolve([])
                                );
                            }
                        })
                        .catch((error: any) => {
                            ERROR([`ModalCreateApplication: ${error.message}`]);
                        });
                }
            );
        });

    getTableSourceDataTechnicalQuestionnaire = () => {
        if (!this.state.technicalQuestionnaire) {
            return [];
        }
        if (this.state.technicalQuestionnaire) {
            return this.state.technicalQuestionnaire.groupsQuestion
                .filter((group: any) => group.isGroup || group.isTemplate)
                .map(({id, message, order, isTemplate, templateId}: any) => ({
                    id,
                    name: `${order}. ${htmlToText(message)}`,
                    groupOrTemplatedId: isTemplate ? `T${templateId}` : `G${id}`,
                }));
        }
    };

    getTechnicalQuestionnaireSelectDefault = () => {
        let data = this.getTableSourceDataTechnicalQuestionnaire();
        const defaultSelected: any = {};
        data.forEach(
            (row: any) =>
                (defaultSelected[row.groupOrTemplatedId] =
                    this.props.selectedGroupsOrTemplates === null
                        ? true
                        : (this.props.selectedGroupsOrTemplates || []).includes(row.groupOrTemplatedId))
        );
        return defaultSelected;
    };

    onTableTechnicalQuestionnaireSelectedChange = (selectedGroupsOrTemplates: any[]) =>
        this.props.setSelectedGroupsOrTemplates(selectedGroupsOrTemplates);

    renderTechnicalQuestionnaireName = () =>
        (this.state.formsFullList.find(form => form.type === 'TQ') || {}).name || '...';

    render() {
        const technicalQuestionnaireData = this.getTableSourceDataTechnicalQuestionnaire();

        return (
            <div className={styles.modalWrap}>
                <ModalCustomVersion2
                    close={this.props.close}
                    header={
                        <>
                            <p style={{margin: 0}}>{`Duplicate the technical questionnaire`}</p>
                            <p
                                style={{
                                    width: '100%',
                                    margin: 0,
                                    fontSize: 22,
                                }}
                            >
                                {this.renderTechnicalQuestionnaireName()}
                            </p>
                        </>
                    }
                    headerSubtitle={`Please select the group of questions for which you would like to duplicate the information`}
                    body={
                        technicalQuestionnaireData.length ? (
                            <div>
                                <CustomTable
                                    {...this.props}
                                    tableName={'formDuplicateSelectedResponses'}
                                    tableType={'OBJECT'}
                                    tableSource={this.getTableSourceDataTechnicalQuestionnaire()}
                                    id={'groupOrTemplatedId'}
                                    resultFieldsDefault={['name']}
                                    intl={this.props.intl}
                                    hideExcelButton={true}
                                    timestamp={this.state.timestamp}
                                    timestampSelected={this.state.timestamp}
                                    notSortable={true}
                                    unlimited={true}
                                    selectable={true}
                                    onSelectedChange={this.onTableTechnicalQuestionnaireSelectedChange}
                                    selectDefault={this.getTechnicalQuestionnaireSelectDefault()}
                                />
                            </div>
                        ) : null
                    }
                    footer={
                        this.state.loading === 0 ? (
                            <>
                                <Button variation={'secondary'} clickAction={this.props.onButtonBackClick}>
                                    {`Back`}
                                </Button>
                                <Button clickAction={this.props.onButtonNextClick} icon={'arrowRight'}>
                                    {`Open Application`}
                                </Button>
                            </>
                        ) : (
                            <LoadingBar />
                        )
                    }
                />
            </div>
        );
    }
}

export default injectIntl(OnlineApplicationsModalDuplicateGroupsTQ);
