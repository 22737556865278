import jwtDecode from 'jwt-decode';
import {TreeElement} from '../CommonComponents/TreeView/Interfaces/TreeViewInterfaces';
import {DecodedDataFromToken, ErrorMessages} from '../CommonInterfaces/CommonInterfaces';
import moment from 'moment';

export const getDecodedInfoFromToken = (): DecodedDataFromToken | null => {
    const remember = localStorage.getItem('remember') === '1';
    const authToken = (remember ? localStorage : sessionStorage).getItem('token');
    if (authToken) {
        return jwtDecode(authToken);
    } else {
        return null;
    }
};

export const getUserRoles = (): string[] => {
    const decoded = getDecodedInfoFromToken();
    return (decoded && decoded.roles) || [];
};

export const getConcatErrorMessage = (errorMessagesObj: ErrorMessages) => {
    let concatErrorMessage = '';
    if (errorMessagesObj.errorInput && errorMessagesObj.errorInput.length > 0) {
        const errorInputMessages = errorMessagesObj.errorInput;
        errorInputMessages.forEach((el, idx, array) => {
            const useDot = array.length === idx + 1 ? '.' : '';
            if (idx === 0) {
                concatErrorMessage += 'Please enter ' + el + useDot;
            } else {
                concatErrorMessage += ' and ' + el + useDot;
            }
        });
    }

    if (errorMessagesObj.errorSelect && errorMessagesObj.errorSelect.length > 0) {
        const errorSelectMessages = errorMessagesObj.errorSelect;
        errorSelectMessages.forEach((el, idx, array) => {
            const useDot = array.length === idx + 1 ? '.' : '';
            const useErrorInput = errorMessagesObj.errorInput && errorMessagesObj.errorInput.length > 0 ? ' ' : '';
            if (idx === 0) {
                concatErrorMessage += useErrorInput + ' Please make sure to select ' + el + useDot;
            } else {
                concatErrorMessage += ' and ' + el + useDot;
            }
        });
    }

    if (errorMessagesObj.errorLogic && errorMessagesObj.errorLogic.length > 0) {
        const errorLogicMessages = errorMessagesObj.errorLogic;
        errorLogicMessages.forEach((el, idx, array) => {
            const useErrorInput = errorMessagesObj.errorInput && errorMessagesObj.errorInput.length > 0 ? ' ' : '';
            const useErrorSelect = errorMessagesObj.errorSelect && errorMessagesObj.errorSelect.length > 0 ? ' ' : '';
            if (idx === 0) {
                concatErrorMessage += useErrorInput + useErrorSelect + el;
            } else {
                concatErrorMessage += ' ' + el;
            }
        });
    }

    return concatErrorMessage;
};

export const getLatestDateOfTreeElements = (treeViewList: Array<TreeElement>) => {
    let listOfLastUpdateDates: Array<moment.Moment> = [];
    for (const el of treeViewList) {
        if (el.lastUpdateDate.length > 0) {
            listOfLastUpdateDates.push(moment(el.lastUpdateDate, 'DD/MM/YYYY'));
        }
    }
    return listOfLastUpdateDates.length > 0 ? moment.max(listOfLastUpdateDates) : moment();
};

export const mostRecentDateCondition = (latestDate: moment.Moment, currentTreeElement: TreeElement, isACArea?: any) => {
    const givenDate = moment(currentTreeElement.lastUpdateDate, 'DD/MM/YYYY');
    if (isACArea) {
        return givenDate.valueOf() <= latestDate.valueOf() && givenDate.valueOf() >= latestDate.valueOf();
    }
    return latestDate.diff(givenDate, 'months') <= 1;
};
