import React, {useState} from 'react';
import {ArrowContainer, Popover} from 'react-tiny-popover';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';

const ComponentPopover = () => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    return (
        <div>
            <Popover
                isOpen={isPopoverOpen}
                positions={'left'}
                content={({position, childRect, popoverRect}) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={'black'}
                        arrowSize={7}
                    >
                        <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                            <div
                                className="custom-popover"
                                style={{
                                    background: 'black',
                                    color: 'white',
                                    padding: '4px 8px',
                                    borderRadius: '4px',
                                    zIndex: 99,
                                    position: 'relative',
                                }}
                            >
                                {`Variety has been registered with another denomination`}
                            </div>
                        </div>
                    </ArrowContainer>
                )}
            >
                <div
                    style={{display: 'inline-block'}}
                    onMouseEnter={() => setIsPopoverOpen(true)}
                    onMouseLeave={() => setIsPopoverOpen(false)}
                >
                    <FontAwesomeIcon icon={faInfoCircle as any} color={'#00447a'} />
                </div>
            </Popover>
        </div>
    );
};

export default ComponentPopover;
