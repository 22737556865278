import React from 'react';
import moment from 'moment';
import {MyRegistersTableAreaProps} from './props/MyRegistersTableAreaProps';
import styles from './MyRegistersTableArea.module.scss';
import {MyRegistersFilterCriteriaData, MyRegistersTableRowData} from '../../MyRegistersPageService';
import CustomTable from '../../../../../../components/CustomTable';

import MyRegistersLabelsBottomCaption from '../MyRegistersLabelsBottomCaption/MyRegistersLabelsBottomCaption';

export default function MyRegistersTableArea(props: MyRegistersTableAreaProps) {
    const RESULT_FIELDS_DEFAULT = [
        'registerId',
        'orderId',
        'reference',
        'denomination',
        'specieName',
        'type',
        'applicationDate',
        'amountReserved',
        'foreseenDate',
        'arrivalDate',
        'incorrectReportDate',
        'finalInterim',
        'status',
    ];

    const getRowClass = (rowObject: MyRegistersTableRowData) => {
        if (rowObject.foreseenDate && (rowObject.arrivalDate === null || rowObject.arrivalDate === '')) {
            const diff = moment(rowObject.foreseenDate).diff(moment(), 'days');
            if (diff > 0 && diff < 29) {
                return styles.warning;
            } else if (diff <= 0) {
                return styles.danger;
            }
        }
    };

    return (
        <React.Fragment>
            <CustomTable
                version={2}
                pageNumber={props.criteria.pageNumber}
                {...props}
                tableName={'myRegisters'}
                tableType={'OBJECT'}
                tableSource={props.myRegisters}
                timestamp={props.timestamp}
                id={'registerId'}
                pagination={true}
                printExcel={(excelTranslations: {[key: string]: string}) => props.printExcel(excelTranslations)}
                rowClass={getRowClass}
                formatFunctions={{
                    orderId: (orderId: string, rowObject: MyRegistersTableRowData) => {
                        return (
                            <div
                                style={{cursor: 'pointer'}}
                                onClick={() => props.goToFileFicheAndDocman(rowObject.registerId)}
                                title="Open details"
                                className="ng-binding"
                            >
                                {orderId.replaceAll(',', ', ')}
                            </div>
                        );
                    },
                    registerId: (registerId: string) => (
                        <div
                            style={{cursor: 'pointer'}}
                            onClick={() => props.goToFileFicheAndDocman(registerId)}
                            title="Open details"
                            className="ng-binding"
                        >
                            {registerId}
                        </div>
                    ),
                    reference: (reference: string, rowObject: MyRegistersTableRowData) => (
                        <div
                            style={{cursor: 'pointer'}}
                            onClick={() => props.goToFileFicheAndDocman(rowObject.registerId)}
                            title="Open details"
                            className="ng-binding"
                        >
                            {reference}
                        </div>
                    ),
                    denomination: (denomination: string, rowObject: MyRegistersTableRowData) => (
                        <div
                            style={{cursor: 'pointer'}}
                            onClick={() => props.goToFileFicheAndDocman(rowObject.registerId)}
                            title="Open details"
                            className="ng-binding"
                        >
                            {denomination}
                        </div>
                    ),
                    specieName: (specieName: string, rowObject: MyRegistersTableRowData) => (
                        <div
                            style={{cursor: 'pointer'}}
                            onClick={() => props.goToFileFicheAndDocman(rowObject.registerId)}
                            title="Open details"
                            className="ng-binding"
                        >
                            {specieName}
                        </div>
                    ),
                    type: (type: string, rowObject: MyRegistersTableRowData) => (
                        <div
                            style={{cursor: 'pointer'}}
                            onClick={() => props.goToFileFicheAndDocman(rowObject.registerId)}
                            title="Open details"
                            className="ng-binding"
                        >
                            {type}
                        </div>
                    ),
                    applicationDate: (applicationDate: string, rowObject: MyRegistersTableRowData) =>
                        applicationDate && (
                            <div
                                style={{cursor: 'pointer'}}
                                onClick={() => props.goToFileFicheAndDocman(rowObject.registerId)}
                                title="Open details"
                                className="ng-binding"
                            >
                                {moment(applicationDate).format('DD/MM/YYYY')}
                            </div>
                        ),
                    amountReserved: (amountReserved: number, rowObject: MyRegistersTableRowData) => (
                        <div
                            style={{cursor: 'pointer'}}
                            onClick={() => props.goToFileFicheAndDocman(rowObject.registerId)}
                            title="Open details"
                            className="ng-binding"
                        >
                            {amountReserved}
                        </div>
                    ),
                    foreseenDate: (foreseenDate: string, rowObject: MyRegistersTableRowData) =>
                        foreseenDate && (
                            <div
                                style={{cursor: 'pointer'}}
                                onClick={() => props.goToFileFicheAndDocman(rowObject.registerId)}
                                title="Open details"
                                className="ng-binding"
                            >
                                {moment(foreseenDate).format('DD/MM/YYYY')}
                            </div>
                        ),
                    arrivalDate: (arrivalDate: string, rowObject: MyRegistersTableRowData) =>
                        arrivalDate && (
                            <div
                                style={{cursor: 'pointer'}}
                                onClick={() => props.goToFileFicheAndDocman(rowObject.registerId)}
                                title="Open details"
                                className="ng-binding"
                            >
                                {moment(arrivalDate).format('DD/MM/YYYY')}
                            </div>
                        ),
                    incorrectReportDate: (incorrectReportDate: string, rowObject: MyRegistersTableRowData) =>
                        incorrectReportDate && (
                            <div
                                style={{cursor: 'pointer'}}
                                onClick={() => props.goToFileFicheAndDocman(rowObject.registerId)}
                                title="Open details"
                                className="ng-binding"
                            >
                                {moment(incorrectReportDate).format('DD/MM/YYYY')}
                            </div>
                        ),
                    finalInterim: (finalInterim: string, rowObject: MyRegistersTableRowData) => (
                        <div
                            style={{cursor: 'pointer'}}
                            onClick={() => props.goToFileFicheAndDocman(rowObject.registerId)}
                            title="Open details"
                            className="ng-binding"
                        >
                            {finalInterim}
                        </div>
                    ),
                    status: (status: string, rowObject: MyRegistersTableRowData) => (
                        <div
                            style={{cursor: 'pointer'}}
                            onClick={() => props.goToFileFicheAndDocman(rowObject.registerId)}
                            title="Open details"
                            className="ng-binding"
                        >
                            {status}
                        </div>
                    ),
                }}
                resultFieldsAll={RESULT_FIELDS_DEFAULT}
                resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                intl={props.intl}
                defaultOrder={props.criteria.order}
                reverseOrder={props.criteria.reverse}
                pageSize={props.criteria.pageSize}
                count={props.count}
                bottomCaption={<MyRegistersLabelsBottomCaption />}
                updateCriteriaValue={(criteriaValue: MyRegistersFilterCriteriaData) =>
                    props.updateCriteriaValue(criteriaValue)
                }
            />
        </React.Fragment>
    );
}
