import React from 'react';
import ResponseText from '../responses/ResponseText';
import RemarkMessage from './RemarkMessage';
import WizardQuestionWrapper, {IWizardQuestionWrapperProps, IWizardQuestionWrapperState} from './WizardQuestionWrapper';
import WizardContextualMenu from '../common/WizardContextualMenu';
import cx from 'classnames';
import {renderMandatoryError} from './utils';
import stylesWizardQuestion from './WizardQuestion.module.scss';
import stylesWizardQuestionHybrid from './WizardQuestionHybrid.module.scss';

interface IState extends IWizardQuestionWrapperState {
    hidden: boolean;
}

class WizardQuestionHybrid extends WizardQuestionWrapper<IWizardQuestionWrapperProps, IState> {
    constructor(props: IWizardQuestionWrapperProps) {
        super(props);
        this.state = {
            ...this.state,
            hidden: false,
        };
    }

    componentDidMount() {
        this.setState(this.checkIfQuestionIsHidden(), () =>
            this.removeResponsesFromHiddenQuestionIfExist(this.state.hidden)
        );
    }

    componentDidUpdate(prevProps: IWizardQuestionWrapperProps, prevState: IWizardQuestionWrapperState) {
        if (this.props.screenData.hash !== prevProps.screenData.hash) {
            this.setState(this.checkIfQuestionIsHidden(), () =>
                this.removeResponsesFromHiddenQuestionIfExist(this.state.hidden)
            );
        }
    }

    render() {
        const {responses} = this.props.question || {};
        const questionKeyWithIteration = this.getQuestionKeyWithIteration();
        let styles = {...stylesWizardQuestion, ...stylesWizardQuestionHybrid};
        const hasClickedOnSaveAndValidate = this.checkIfUserHasClickedOnSaveAndValidate();
        const hasMandatoryError = !this.state.hidden && this.checkIfQuestionHasMandatoryError();
        const showMandatoryError = hasClickedOnSaveAndValidate && hasMandatoryError;
        const {hidden, hiddenDueToQuestionResponseLines} = this.state;

        if (this.pdfConfidentialHideQuestion()) {
            return <></>;
        }

        return (
            <>
                {super.render()}
                <div
                    id={questionKeyWithIteration}
                    className={cx(styles.titleSection, this.state.hidden && styles.sectionHidden)}
                >
                    {this.renderQuestionLabel({
                        hidden,
                        hiddenDueToQuestionResponseLines,
                        hasError: showMandatoryError,
                    })}
                    {this.renderQuestionHelpIcon()}
                </div>
                {showMandatoryError && renderMandatoryError({intl: this.props.intl})}
                {!this.state.hidden && (
                    <div
                        className={cx(
                            styles.section,
                            (responses || []).length === 1 ? styles.singleHybridResponse : null
                        )}
                    >
                        <RemarkMessage
                            remarkMessage={this.props.question && this.props.question.messageremark}
                            isRemarkTranslationMissing={this.checkIfQuestionRemarkTranslationIsMissing()}
                        />
                        <>
                            {this.pdfConfidentialHideResponsesOnly() ? (
                                <div style={{marginTop: 20, marginLeft: 20}}>Confidential information</div>
                            ) : (
                                (responses || []).map(response => {
                                    let params: any = this.getResponseComponentProps(response);
                                    params.twoInputsPerLine = true;
                                    return (
                                        <div className={styles.hybridResponseWrap} key={response.message}>
                                            <WizardContextualMenu
                                                editable={this.props.editable}
                                                menuType="response"
                                                labelPopup={this.getResponseLabelPopup(response.id)}
                                            />
                                            <ResponseText {...params} />
                                            {this.renderResponseLogicsPopover(response)}
                                            <div style={{clear: 'both'}} />
                                        </div>
                                    );
                                })
                            )}
                        </>
                        <div style={{clear: 'both'}} />
                        {this.renderConfidentialQuestion()}
                    </div>
                )}
            </>
        );
    }
}

export default WizardQuestionHybrid;
