import React from 'react';
import cx from 'classnames';
import styles from './WizardStepsGroup.module.scss';
import {injectIntl} from 'react-intl';
import {htmlToText} from '~utils/index';
import {HeaderLoading} from '../../componentsLayout';
import {ModalAlertVersion2, ModalConfirmVersion2} from '../../commonModals';
import ModalUsedInVersion2, {rowType} from '../../commonModals/ModalUsedInVersion2';
import WizardQuestion from './questions/WizardQuestion';
import WizardQuestionHybrid from './questions/WizardQuestionHybrid';
import WizardQuestionLooper from './questions/WizardQuestionLooper';
import WizardQuestionShortDisplay from './questions/WizardQuestionShortDisplay';
import WizardQuestionTable from './questions/WizardQuestionTable';
import WizardQuestionNo from './questions/WizardQuestionNo';
import Help from './common/Help';
import {checkIfQuestionIsShortDisplay, checkIfShouldHideQuestionInCurrentGroupIteration} from './questions/utils';
import {faFlag, faLightbulb, faPencilAlt, faPlus, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import WizardContextualMenu from './common/WizardContextualMenu';
import GroupOrTemplateModalDetails from './modals/GroupOrTemplateModalDetails';
import QuestionModalDetails from './modals/QuestionModalDetails';
import ResponseModalDetails from './modals/ResponseModalDetails';
import {apiBackOfficeQuestionNewVersion, apiBackOfficeRemoveTemplateUse} from './WizardStepsGroupService';
import {MOCK} from '../BackOfficeFormQuestions/BackOfficeFormQuestions';
import {LOGIC_TYPES} from './modals/QuestionModalDetailsLogics';
import {getGroupKeyWithIteration as getGroupKeyWithIterationFn, getGroupOrTemplateKeyForIterationMap} from './utils';
import ResponseModalAddResponsesWithTable from './modals/ResponseModalAddResponsesWithTable';
import {IAssistedEntries, IGroup, IGroupQuestion, IScreenData} from '../../types';

const MODAL_SCREEN = {
    GROUP_OR_TEMPLATE_DETAILS: 'GROUP_OR_TEMPLATE_DETAILS',
    QUESTION_DETAILS: 'QUESTION_DETAILS',
    USED_IN: 'USED_IN',
    ADD_RESPONSE: 'ADD_RESPONSE',
    ADD_RESPONSES_WITH_TABLE: 'ADD_RESPONSES_WITH_TABLE',
};
const MODAL_CONFIRM_DELETION = {
    QUESTION: 'QUESTION',
    TEMPLATE: 'TEMPLATE',
};
const STATUS = {
    IN_PREPARATION: 1,
    ACTIVE: 2,
    TERMINATED: 3,
};

interface IProps {
    PDFVersion?: number;
    applicationCode?: string;
    assistedEntries: IAssistedEntries | null;
    currentStepNumber: number;
    editable?: boolean;
    formIsConfidential?: any;
    formOrTemplateStatus?: number | null;
    formOrTemplateOfficeId?: number;
    formLanguage?: any;
    formId: number | null;
    group: IGroup;
    goToStep: (arg0: number) => void;
    intl: any;
    isRemedyStarted?: any;
    loadFormQuestionsJSONs: any;
    modalQueuePush: (arg0: any) => void;
    modalQueuePop: () => void;
    mock: any;
    mainScreen?: string;
    responsesClientHash?: any;
    removeQuestion: (questionId: number) => void;
    removeResponse: (responseId: number, questionId: number) => void;
    screenData: IScreenData;
    steps: IGroup[];
    setStepGroupCallables: (callables: any) => void;
    templateId?: number | null;
    updateScreenData: (arg0: Partial<IScreenData>, arg1?: () => any) => any;
}

interface IState {
    loading: boolean;
    activeQuestion: IGroupQuestion | undefined | null;
    activeGroupOrTemplate: IGroup | null;
    activeQuestionId: number | null;
    modalScreen: any;
    modalUsedIn: {elementId: any; type: any};
    modalConfirmDeletion: any;
    modalConfirmQuestionNewVersion: any;
    modalAlertTitle: string | null;
    modalAlertMessage: null;
}

class WizardStepsGroup extends React.Component<IProps, IState> {
    wizardQuestionCallables: any;

    constructor(props: IProps) {
        super(props);
        this.state = {
            activeQuestion: null,
            activeGroupOrTemplate: null,
            activeQuestionId: null,
            loading: false,
            modalScreen: null,
            modalUsedIn: {elementId: null, type: null},
            modalConfirmDeletion: null,
            modalConfirmQuestionNewVersion: null,
            modalAlertTitle: null,
            modalAlertMessage: null,
        };
    }

    componentDidMount() {
        this.props.setStepGroupCallables &&
            this.props.setStepGroupCallables({
                showModalQuestionDetails: this.showModalQuestionDetails,
            });
        if (this.props.mock === MOCK.EDIT_GROUP) {
            this.showModalGroupOrTemplateDetails();
        }
        if (this.props.mock === MOCK.EDIT_QUESTION || Object.values(LOGIC_TYPES).indexOf(this.props.mock) !== -1) {
            this.showModalQuestionDetails(0);
        }
    }

    setWizardQuestionCallables = (callables: any) => {
        this.wizardQuestionCallables = callables;
    };

    getGroupKeyWithIteration = (groupIteration: any) =>
        getGroupKeyWithIterationFn({group: this.props.group, groupIteration});

    getActiveQuestion = (): IGroupQuestion | undefined => {
        return ((this.props.steps && this.props.steps.map(el => el.questions).flat(1)) || []).find(
            question => question && question.id === this.state.activeQuestionId
        );
    };

    showModalGroupOrTemplateDetails = () =>
        this.setState({modalScreen: MODAL_SCREEN.GROUP_OR_TEMPLATE_DETAILS, activeGroupOrTemplate: this.props.group});

    showModalQuestionDetails = (questionId: number) =>
        this.setState({
            modalScreen: MODAL_SCREEN.QUESTION_DETAILS,
            activeQuestionId: questionId,
            activeQuestion: this.getActiveQuestion(),
        });

    showModalGroupUsedIn = () =>
        this.setState({
            modalUsedIn: {
                elementId: this.props.group && this.props.group.id,
                type: rowType.GROUP,
            },
        });

    showModalTemplateUsedIn = () =>
        this.setState({
            modalUsedIn: {
                elementId: this.props.group && this.props.group.templateId,
                type: rowType.TEMPLATE,
            },
        });

    showModalQuestionUsedIn = (questionId: number) => {
        this.setState({
            modalUsedIn: {
                elementId: questionId,
                type: rowType.QUESTION,
            },
        });
    };

    closeModalUsedIn = () =>
        this.setState({
            modalUsedIn: {
                elementId: null,
                type: null,
            },
        });

    closeModal = () => {
        this.props.modalQueuePop();
        this.setState({modalScreen: null, activeQuestionId: null, activeQuestion: null});
    };

    closeModalGroupOrTemplateDetails = () => this.setState({modalScreen: null, activeGroupOrTemplate: null});

    openModalConfirmDeletion = (objWithTypeAndId: any) => this.setState({modalConfirmDeletion: objWithTypeAndId});

    closeModalConfirmDeletion = () => this.setState({modalConfirmDeletion: null});

    closeModalAlert = () => this.setState({modalAlertTitle: null, modalAlertMessage: null});

    showModalAddResponse = (questionId: number) =>
        this.setState({
            modalScreen: MODAL_SCREEN.ADD_RESPONSE,
            activeQuestionId: questionId,
            activeQuestion: this.getActiveQuestion(),
        });

    showModalAddResponsesWithTable = (questionId: number) =>
        this.setState({
            modalScreen: MODAL_SCREEN.ADD_RESPONSES_WITH_TABLE,
            activeQuestionId: questionId,
            activeQuestion: this.getActiveQuestion(),
        });

    afterCreatingNewResponse = () => {
        this.closeModal();
        this.props.loadFormQuestionsJSONs(null, null, null, true);
    };

    openModalConfirmQuestionNewVersion = (questionId: number) =>
        this.setState({modalConfirmQuestionNewVersion: questionId});

    closeModalConfirmQuestionNewVersion = (questionId: number) =>
        this.setState({modalConfirmQuestionNewVersion: questionId});

    loadQuestionNewVersion = ({questionId, createNewVersionOfResponses = false}: any) => {
        const jsonBody = {
            formId: this.props.formId,
            questionId,
            isTemplate: this.props.group.isTemplate,
            createNewVersionOfResponses,
            templateId: this.props.group.templateId,
        };
        this.setState({loading: true, modalConfirmQuestionNewVersion: null}, () => {
            apiBackOfficeQuestionNewVersion(jsonBody)
                .then((jsonResponse: any) => {
                    this.setState({loading: false}, () => {
                        jsonResponse.newQuestionId &&
                            this.props.loadFormQuestionsJSONs(
                                null,
                                this.showModalQuestionDetails,
                                jsonResponse.newQuestionId,
                                true
                            );
                        jsonResponse.errorMessage &&
                            this.setState({
                                modalAlertTitle: `New version failed`,
                                modalAlertMessage: jsonResponse.errorMessage,
                            });
                    });
                })
                .catch((error: any) => {
                    ERROR([`Wizard loadQuestionNewVersion: ${error.message}`]);
                });
        });
    };

    updateGroupIteration = (value: any) => {
        let currentGroupId = getGroupOrTemplateKeyForIterationMap(this.props.group);
        let currentGroupIdIteration = this.props.screenData.groupsIdsIterationsMap[currentGroupId];
        let nextGroupIdsIterationsMap = {...this.props.screenData.groupsIdsIterationsMap};
        nextGroupIdsIterationsMap[currentGroupId] = currentGroupIdIteration + value;
        this.props.updateScreenData({
            hash: Date.now(),
            groupsIdsIterationsMap: nextGroupIdsIterationsMap,
        });
    };

    // groupIteration starts from 1
    deleteAndReindexResponsesClient = (groupIterationToDelete: any) => {
        const {screenData} = this.props;
        const {responsesClient} = screenData || {};
        const updatedResponseClient = {...responsesClient};
        const groupKeyWithIteration = this.getGroupKeyWithIteration(groupIterationToDelete); // e.g. GR25_2
        const groupPrefix = groupKeyWithIteration.split('_')[0]; //e.g. GR25
        //delete
        Object.keys(responsesClient).forEach(key => {
            if (key.startsWith(groupKeyWithIteration)) {
                delete updatedResponseClient[key];
            }
        });
        //reindex
        Object.keys(updatedResponseClient).forEach(key => {
            if (key.startsWith(groupPrefix)) {
                let iterationOfResponsePayloadKey = parseInt(key.split(`${groupPrefix}_`)[1][0]); // GR25_3_Q12430_1_R26561_1 -> 3
                if (iterationOfResponsePayloadKey > groupIterationToDelete) {
                    let newIterationOfResponsePayloadKey = iterationOfResponsePayloadKey - 1;
                    let newKeyOfResponsePayloadKey = key.replace(
                        `${groupPrefix}_${iterationOfResponsePayloadKey}`,
                        `${groupPrefix}_${newIterationOfResponsePayloadKey}`
                    );
                    let newValueOfResponsesPayloadKey = responsesClient[key];
                    // For Radio Response, the value should be updated as well:
                    // GR21_3_Q11612_1: "GR21_3_Q11612_1_R24982_1" --->
                    // GR21_2_Q11612_1: "GR21_2_Q11612_1_R24982_1"
                    if (typeof responsesClient[key] === 'string' && responsesClient[key].startsWith(groupPrefix)) {
                        newValueOfResponsesPayloadKey = `${groupPrefix}_${newIterationOfResponsePayloadKey}${responsesClient[
                            key
                        ]
                            .split(`${groupPrefix}_`)[1]
                            .slice(1)}`;
                    }
                    // first must set then delete
                    updatedResponseClient[newKeyOfResponsePayloadKey] = newValueOfResponsesPayloadKey;
                    delete updatedResponseClient[key];
                }
            }
        });
        this.props.updateScreenData({responsesClient: updatedResponseClient, hash: Date.now()});
    };

    getMultiButtonMessageAdd = () => {
        let intlMessage = this.props.intl.formatMessage({
            id: `online.multibutton.${this.props.group && this.props.group.messageIdButtonAddId}`,
        });
        if (intlMessage !== `online.multibutton.${this.props.group && this.props.group.messageIdButtonAddId}`) {
            return intlMessage;
        }
        return this.props.group.messageButtonAdd || `Add group`;
    };

    getMultiButtonMessageDelete = () => {
        let intlMessage = this.props.intl.formatMessage({
            id: `online.multibutton.${this.props.group && this.props.group.messageButtonDelId}`,
        });
        if (intlMessage !== `online.multibutton.${this.props.group && this.props.group.messageButtonDelId}`) {
            return intlMessage;
        }
        return this.props.group.messageButtonDel || `Remove group`;
    };

    onAddGroupButton = () => this.updateGroupIteration(1);

    onQuestionNewVersionWithoutResponsesAction = () =>
        this.loadQuestionNewVersion({
            questionId: this.state.modalConfirmQuestionNewVersion,
            createNewVersionOfResponses: false,
        });

    onQuestionNewVersionWithResponsesAction = () =>
        this.loadQuestionNewVersion({
            questionId: this.state.modalConfirmQuestionNewVersion,
            createNewVersionOfResponses: true,
        });

    onRemoveTemplateUseAction = () => {
        const jsonBody = {
            formId: this.props.formId,
            templateId: this.state.modalConfirmDeletion.id,
        };
        this.closeModalConfirmDeletion();
        this.setState({loading: true}, () => {
            apiBackOfficeRemoveTemplateUse(jsonBody)
                .then((jsonResponse: any) => {
                    this.setState({loading: false}, () => {
                        if (jsonResponse && jsonResponse.data === 'OK') {
                            this.props.loadFormQuestionsJSONs();
                        }
                    });
                })
                .catch((error: any) => {
                    ERROR([`Wizard onRemoveTemplateUseAction: ${error.message}`]);
                });
        });
    };

    onRemoveQuestionAction = () => {
        this.props.removeQuestion && this.props.removeQuestion(this.state.modalConfirmDeletion.id);
        this.closeModalConfirmDeletion();
    };

    onRemoveGroupButton = (groupIteration: any) => {
        // reindex responsesClient
        // e.g. if we have 3 groups
        // GR25_1_Q12430_1_R26561_1: "value 1"
        // GR25_2_Q12430_1_R26561_1: "value2"
        // GR25_3_Q12430_1_R26561_1: "value333"
        // ------ on delete of group 2, should update responsesClient to:
        // GR25_1_Q12430_1_R26561_1: "value 1"
        // GR25_2_Q12430_1_R26561_1: "value333"
        // In case of single choice, the payload value (besides the key) should be updated, otherwise it results:
        //GR21_2_Q11612_1: "GR21_3_Q11612_1_R24982_1"
        this.updateGroupIteration(-1); // !important, this should happen first
        this.deleteAndReindexResponsesClient(groupIteration);
    };

    renderGroupPopupElements = () => [
        {
            label: 'Show details',
            icon: faPencilAlt,
            handler: () => this.showModalGroupOrTemplateDetails(),
        },
        {
            label: 'Used in',
            icon: faLightbulb,
            handler: () => this.showModalGroupUsedIn(),
        },
    ];

    renderTemplatePopupElements = () => [
        {
            label: 'Show details',
            icon: faPencilAlt,
            handler: () => this.showModalGroupOrTemplateDetails(),
        },
        {
            label: 'Remove template use',
            icon: faPencilAlt,
            handler: () =>
                this.openModalConfirmDeletion({
                    type: MODAL_CONFIRM_DELETION.TEMPLATE,
                    id: this.props.group && this.props.group.templateId,
                }),
        },
        {
            label: 'Used in',
            icon: faLightbulb,
            handler: () => this.showModalTemplateUsedIn(),
        },
    ];

    renderQuestionPopupElements = (question: IGroupQuestion) => {
        let elementsArr = [];
        const {formOrTemplateStatus} = this.props;
        let isQuestionInPreparation = question && question.status === STATUS.IN_PREPARATION;
        let hasQuestionSameOfficeAsFormOrTemplateOffice =
            question && question.office === this.props.formOrTemplateOfficeId;
        let showUpdateOrRemoveActions =
            formOrTemplateStatus === STATUS.IN_PREPARATION ||
            (isQuestionInPreparation && hasQuestionSameOfficeAsFormOrTemplateOffice);
        let showRemoveQuestionOrAddResponseInFormScreenWhenGroupIsTemplate = !(
            this.props.group &&
            this.props.group.isTemplate &&
            (!this.props.mainScreen || this.props.mainScreen !== 'templateQuestions')
        );

        elementsArr.push({
            label: 'Show details',
            icon: faPencilAlt,
            handler: () => this.showModalQuestionDetails(question.id),
        });

        if (this.props.mainScreen && this.props.mainScreen === 'templateQuestions') {
            this.props.group.status === 1 &&
                showUpdateOrRemoveActions &&
                elementsArr.push({
                    label: 'Remove Question',
                    icon: faTrashAlt,
                    handler: () =>
                        this.openModalConfirmDeletion({type: MODAL_CONFIRM_DELETION.QUESTION, id: question.id}),
                });
        } else {
            showRemoveQuestionOrAddResponseInFormScreenWhenGroupIsTemplate &&
                showUpdateOrRemoveActions &&
                elementsArr.push({
                    label: 'Remove Question',
                    icon: faTrashAlt,
                    handler: () =>
                        this.openModalConfirmDeletion({type: MODAL_CONFIRM_DELETION.QUESTION, id: question.id}),
                });
        }

        question.status !== STATUS.IN_PREPARATION &&
            elementsArr.push({
                label: 'New version',
                icon: faFlag,
                handler: () => this.openModalConfirmQuestionNewVersion(question.id),
            });

        showRemoveQuestionOrAddResponseInFormScreenWhenGroupIsTemplate &&
            isQuestionInPreparation &&
            elementsArr.push({
                label: 'Add Response',
                icon: faPlus,
                handler: () => this.showModalAddResponse(question.id),
            });
        showRemoveQuestionOrAddResponseInFormScreenWhenGroupIsTemplate &&
            isQuestionInPreparation &&
            elementsArr.push({
                label: 'Add Responses with table',
                icon: faPlus,
                handler: () => this.showModalAddResponsesWithTable(question.id),
            });
        elementsArr.push({
            label: 'Used in',
            icon: faLightbulb,
            handler: () => this.showModalQuestionUsedIn(question.id),
        });
        return elementsArr;
    };

    render() {
        const activeQuestion = this.getActiveQuestion();

        return (
            <>
                {(this.state.loading && <HeaderLoading />) || null}
                {this.state.modalUsedIn.elementId !== null && this.state.modalUsedIn.type ? (
                    <ModalUsedInVersion2
                        elementId={this.state.modalUsedIn.elementId}
                        intl={this.props.intl}
                        type={this.state.modalUsedIn.type}
                        close={this.closeModalUsedIn}
                    />
                ) : null}
                {this.state.modalConfirmQuestionNewVersion && (
                    <ModalConfirmVersion2
                        title={`Question New Version`}
                        message={`Do you want to create a new version of responses too ?`}
                        buttonName={'Yes'}
                        action={this.onQuestionNewVersionWithResponsesAction}
                        actionOnNoButtonClick={this.onQuestionNewVersionWithoutResponsesAction}
                        close={this.closeModalConfirmQuestionNewVersion}
                    />
                )}
                {this.state.modalConfirmDeletion && (
                    <>
                        {this.state.modalConfirmDeletion.type === MODAL_CONFIRM_DELETION.QUESTION && (
                            <ModalConfirmVersion2
                                title={`Remove Question`}
                                message={`Are you sure you want to remove this question ?`}
                                buttonName={'REMOVE'}
                                action={this.onRemoveQuestionAction}
                                close={this.closeModalConfirmDeletion}
                            />
                        )}
                        {this.state.modalConfirmDeletion.type === MODAL_CONFIRM_DELETION.TEMPLATE && (
                            <ModalConfirmVersion2
                                title={`Remove template`}
                                message={`Are you sure you want to remove this template ?`}
                                buttonName={'REMOVE'}
                                action={this.onRemoveTemplateUseAction}
                                close={this.closeModalConfirmDeletion}
                            />
                        )}
                    </>
                )}
                {this.state.modalAlertMessage !== null ? (
                    <ModalAlertVersion2
                        message={this.state.modalAlertMessage}
                        title={this.state.modalAlertTitle || 'Alert'}
                        close={this.closeModalAlert}
                    />
                ) : null}
                {this.state.modalScreen === MODAL_SCREEN.GROUP_OR_TEMPLATE_DETAILS && (
                    <GroupOrTemplateModalDetails
                        group={this.state.activeGroupOrTemplate}
                        formId={this.props.formId}
                        modalQueuePush={this.props.modalQueuePush}
                        modalQueuePop={this.props.modalQueuePop}
                        close={this.closeModalGroupOrTemplateDetails}
                        loadFormQuestionsJSONs={this.props.loadFormQuestionsJSONs}
                        mainScreen={this.props.mainScreen}
                    />
                )}
                {this.state.modalScreen === MODAL_SCREEN.QUESTION_DETAILS && this.state.activeQuestionId !== null ? (
                    <QuestionModalDetails
                        close={this.closeModal}
                        formId={this.props.formId}
                        formOrTemplateOfficeId={this.props.formOrTemplateOfficeId}
                        group={this.props.group}
                        loadFormQuestionsJSONs={this.props.loadFormQuestionsJSONs}
                        mainScreen={this.props.mainScreen}
                        modalQueuePush={this.props.modalQueuePush}
                        modalQueuePop={this.props.modalQueuePop}
                        mock={this.props.mock}
                        steps={this.props.steps}
                        question={this.getActiveQuestion()}
                        questionId={this.state.activeQuestionId}
                        templateId={this.props.templateId}
                    />
                ) : null}
                {this.state.modalScreen === MODAL_SCREEN.ADD_RESPONSE &&
                this.state.activeQuestionId !== null &&
                activeQuestion ? (
                    <ResponseModalDetails
                        afterCreatingNewResponse={this.afterCreatingNewResponse}
                        activeResponseId={0}
                        close={this.closeModal}
                        formId={this.props.formId}
                        formOrTemplateOfficeId={this.props.formOrTemplateOfficeId}
                        group={this.props.group}
                        mock={this.props.mock}
                        mainScreen={this.props.mainScreen}
                        modalQueuePop={this.props.modalQueuePop}
                        modalQueuePush={this.props.modalQueuePush}
                        question={activeQuestion}
                        questionId={this.state.activeQuestionId}
                        steps={this.props.steps}
                    />
                ) : null}
                {this.state.modalScreen === MODAL_SCREEN.ADD_RESPONSES_WITH_TABLE &&
                this.state.activeQuestionId !== null &&
                activeQuestion ? (
                    <ResponseModalAddResponsesWithTable
                        questionId={this.state.activeQuestionId}
                        question={activeQuestion}
                        close={this.closeModal}
                        afterCreatingNewResponse={this.afterCreatingNewResponse}
                    />
                ) : null}
                {Array(
                    this.props.screenData.groupsIdsIterationsMap[
                        getGroupOrTemplateKeyForIterationMap(this.props.group)
                    ] || 0
                )
                    .fill(true)
                    .map((el, groupIndex) => {
                        const groupIteration = groupIndex + 1;
                        return (
                            <div
                                key={this.getGroupKeyWithIteration(groupIndex + 1)}
                                id={this.getGroupKeyWithIteration(groupIndex + 1)}
                                className={cx(styles.groupWrap, this.props.group.multi ? styles.groupMulti : '')}
                            >
                                {this.props.mainScreen !== 'templateQuestions' && (
                                    <div className={styles.groupTitleWrap}>
                                        <WizardContextualMenu
                                            editable={this.props.editable}
                                            menuType="group"
                                            labelPopup={
                                                this.props.group && this.props.group.isTemplate
                                                    ? this.renderTemplatePopupElements()
                                                    : this.renderGroupPopupElements()
                                            }
                                        />
                                        <div style={{display: 'inline-block'}}>
                                            <span
                                                ref={el => {
                                                    if (el) {
                                                        let title = `${this.props.group.order} . ${htmlToText(
                                                            this.props.group.message
                                                        )}`;
                                                        el.innerHTML = title;
                                                        if ((window as any).elements) {
                                                            let element: any = {
                                                                type: 'GROUP_TITLE',
                                                                label: title,
                                                                order: this.props.group.order,
                                                            };
                                                            this.props.group.messageRemark &&
                                                                (element.remark = this.props.group.messageRemark);
                                                            (window as any).elements.push(element);
                                                        }
                                                    }
                                                }}
                                                className={cx(styles.groupTitle)}
                                                style={
                                                    this.props.editable &&
                                                    parseInt(
                                                        this.props.group && this.props.group.messageIsDef
                                                            ? this.props.group.messageIsDef
                                                            : '0'
                                                    ) === 1
                                                        ? {color: 'red'}
                                                        : {}
                                                }
                                            />
                                            {this.props.editable && (
                                                <>
                                                    <div
                                                        className={cx(
                                                            styles.groupStatusLabel,
                                                            this.props.group.status === STATUS.IN_PREPARATION &&
                                                                styles.groupIsInPreparation,
                                                            this.props.group.status === STATUS.ACTIVE &&
                                                                styles.groupIsActive,
                                                            this.props.group.status === STATUS.TERMINATED &&
                                                                styles.groupIsTerminated
                                                        )}
                                                    >
                                                        {this.props.group.status === STATUS.IN_PREPARATION
                                                            ? 'Under preparation'
                                                            : null}
                                                        {this.props.group.status === STATUS.ACTIVE ? 'Active' : null}
                                                        {this.props.group.status === STATUS.TERMINATED
                                                            ? 'Terminated'
                                                            : null}
                                                    </div>
                                                    <div
                                                        style={{display: 'inline-block'}}
                                                        title={`${this.props.group.isTemplate ? 'Template' : 'Group'}`}
                                                        className={cx(
                                                            styles.titleLabel,
                                                            this.props.group.isTemplate && styles.titleLabelIsTemplate,
                                                            !this.props.group.isTemplate && styles.titleLabelIsGroup
                                                        )}
                                                    >
                                                        {this.props.group.isTemplate ? 'Template' : 'Group'}
                                                    </div>
                                                    <div
                                                        style={{display: 'inline-block'}}
                                                        title={`${this.props.group.isTemplate ? 'Template' : 'Group'}`}
                                                        className={cx(
                                                            styles.titleLabel,
                                                            this.props.group.isTemplate && styles.titleLabelIsTemplate,
                                                            !this.props.group.isTemplate && styles.titleLabelIsGroup
                                                        )}
                                                    >
                                                        {this.props.group.isTemplate
                                                            ? this.props.group.templateId
                                                            : this.props.group.id}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {groupIndex >= 1 && (
                                            <button
                                                type="button"
                                                className={styles.buttonMinus}
                                                onClick={() => this.onRemoveGroupButton(groupIndex + 1)}
                                            >
                                                {this.getMultiButtonMessageDelete()}
                                            </button>
                                        )}
                                        <Help message={this.props.group.messageHelp} className="groupHelp" />
                                        {this.props.group.messageRemark && this.props.group.messageRemark !== '' ? (
                                            <p
                                                ref={(el: any) => {
                                                    if (el) el.innerHTML = this.props.group.messageRemark;
                                                }}
                                                className={styles.groupRemark}
                                                style={
                                                    this.props.editable &&
                                                    parseInt(String(this.props.group.messageRemarkIsDef)) === 1
                                                        ? {color: 'red'}
                                                        : {}
                                                }
                                            />
                                        ) : null}
                                    </div>
                                )}
                                {((this.props.group && this.props.group.questions) || []).map((question, index) => {
                                    const params = {
                                        PDFVersion: this.props.PDFVersion,
                                        applicationCode: this.props.applicationCode,
                                        assistedEntries: this.props.assistedEntries,
                                        editable: this.props.editable,
                                        formId: this.props.formId,
                                        formIsConfidential: this.props.formIsConfidential,
                                        formLanguage: this.props.formLanguage,
                                        formOrTemplateOfficeId: this.props.formOrTemplateOfficeId,
                                        formOrTemplateStatus: this.props.formOrTemplateStatus,
                                        group: this.props.group,
                                        question,
                                        questionType: question.type,
                                        groupKeyWithIteration: this.getGroupKeyWithIteration(groupIndex + 1),
                                        groupIteration,
                                        isRemedyStarted: this.props.isRemedyStarted,
                                        intl: this.props.intl,
                                        loadFormQuestionsJSONs: this.props.loadFormQuestionsJSONs,
                                        mainScreen: this.props.mainScreen,
                                        mock: this.props.mock,
                                        modalQueuePush: this.props.modalQueuePush,
                                        modalQueuePop: this.props.modalQueuePop,
                                        removeResponse: this.props.removeResponse,
                                        responsesClientHash: this.props.responsesClientHash,
                                        screenData: this.props.screenData,
                                        steps: this.props.steps,
                                        setWizardQuestionCallables: this.setWizardQuestionCallables,
                                        templateId: this.props.templateId,
                                        updateScreenData: this.props.updateScreenData,
                                    };
                                    let QuestionComponent;
                                    const labelPopup = this.renderQuestionPopupElements(question);
                                    let isQuestionShortDisplay = checkIfQuestionIsShortDisplay(question);
                                    if (isQuestionShortDisplay) {
                                        QuestionComponent = <WizardQuestionShortDisplay {...params} />;
                                    } else {
                                        switch (question.type) {
                                            case 'table':
                                                QuestionComponent = <WizardQuestionTable {...params} />;
                                                break;
                                            case 'hybrid':
                                                QuestionComponent = <WizardQuestionHybrid {...params} />;
                                                break;
                                            case 'looper':
                                                QuestionComponent = <WizardQuestionLooper {...params} />;
                                                break;
                                            case 'no':
                                                QuestionComponent = <WizardQuestionNo {...params} />;
                                                break;
                                            default:
                                                QuestionComponent = <WizardQuestion {...params} />;
                                                break;
                                        }
                                    }
                                    let firstIndexOfShortDisplay = (
                                        (this.props.group && this.props.group.questions) ||
                                        []
                                    ).findIndex(el => el.shortDisplay);
                                    if (checkIfShouldHideQuestionInCurrentGroupIteration({question, groupIteration})) {
                                        return null;
                                    }
                                    return (
                                        <div
                                            key={`${question.id}`}
                                            className={cx(
                                                styles.questionWrap,
                                                'col-md-12',
                                                'no-padding-left',
                                                isQuestionShortDisplay ? styles.shortDisplay : null,
                                                isQuestionShortDisplay ? 'border-left' : null,
                                                index % 2 === 1 ? styles.odd : styles.even,
                                                index === firstIndexOfShortDisplay && styles.firstShortQuestion
                                            )}
                                        >
                                            <WizardContextualMenu
                                                editable={this.props.editable}
                                                menuType="question"
                                                labelPopup={labelPopup}
                                            />
                                            <div>{QuestionComponent}</div>
                                            <div style={{clear: 'both'}} />
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                {this.props.group.multi && this.props.mainScreen !== 'templateQuestions' ? (
                    <div className={styles.plusWrap}>
                        <button type="button" className={styles.buttonPlus} onClick={this.onAddGroupButton}>
                            {this.getMultiButtonMessageAdd()}
                        </button>
                    </div>
                ) : null}
            </>
        );
    }
}

export default injectIntl(WizardStepsGroup);
