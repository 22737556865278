import {API_SERVERLESS, getFetch, postFetch} from '~commonApi';
import {apiCommunicationCentreGetCommunicationIdUploadFilesAndSendMessage} from '../CommunicationCentreInternal/CommunicationCentreInternalService';

export function apiCommunicationCentreModalInternalDraftClients(jsonData: any) {
    return postFetch(`${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIDC/draft/clients`, true, jsonData);
}

export function apiCommunicationCentreModalInternalDraftCCList() {
    return getFetch(`${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIDCC/draft/ccList`, true);
}

export function apiCommunicationCentreModalInternalDraftMessageRead(communicationId: any) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIDR/draft/messageRead/${communicationId}`,
        true
    );
}
export function apiCommunicationCentreModalInternalDraftMessageSend(jsonRequest: any, documentList: any) {
    return apiCommunicationCentreGetCommunicationIdUploadFilesAndSendMessage(jsonRequest, documentList, {
        getCommunicationId: 'communicationCentreModalInternal/v4/CCMIDGID/draft/getCommunicationId',
        documentUploadFile: 'communicationCentreModalInternal/v4/CCMIDUF/draft/documentUploadFile',
        submitRequest: 'communicationCentreModalInternal/v4/CCMIDS/draft/messageSend',
    });
}

export function apiCommunicationCentreModalInternalDraftGetFileInfo(applicationNumber: any) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIDCAN/draft/getFileInfoIfExists/${applicationNumber}`,
        true
    );
}

export function apiCommunicationCentreModalInternalDraftDownloadDocument({
    communicationId,
    serverIndex,
}: {
    communicationId: number;
    serverIndex?: string;
}) {
    return getFetch(
        `${API_SERVERLESS}/communicationCentreModalInternal/v4/CCMIDMDD/draf/downloadDocument/${communicationId}/${serverIndex}`,
        true
    );
}
