import React, {useEffect, useState} from 'react';
import {Button} from '../../../../componentsFormV2';
import Empty from '~components/Empty';
import {Error} from '../../../../componentsLayout';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {History} from 'history';
import {ModalCustomVersion2, ModalErrorVersion2} from '../../../../commonModals';
import {PlantMaterialRequirements} from '../../Screens/ConsultModifySpeciesDataPage/Components/ModalEntrustment/Interfaces/ModalEntrustmentInterfaces';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import SelectInput from '~components/SelectInput';
import {TabObject, TabObjectsFetchData} from './Interfaces/ModalDefaultRequirmentsInterfaces';
import TextInput from '~components/TextInput';
import TextAreaInput from '~components/TextAreaInput';
import TextLabelInput from '../../../../components/TextLabelInput';
import {apiTabObjectsFetch} from '../../CommonApi/CommonApi';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {getMandatoryAsterisk} from '../../../../utils/format';
import {injectIntl, IntlShape} from 'react-intl';

interface ModalDefaultRequirementsProps {
    intl: IntlShape;
    history: History;
    plantRequirements: PlantMaterialRequirements | null;
    seedPlantQuantityQualityIds: string;
    speciesExamId: number;
    close: (submit: boolean, quantityQuality?: string, quantityQualityIds?: string) => void;
    triggerCloseAfterInit?: boolean;
    setTriggerCloseAfterInit?: any;
}

const ModalDefaultRequirements = (props: ModalDefaultRequirementsProps & RouteComponentProps) => {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [errorMessageModal, setErrorMessageModal] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [, setSeedPlantQuantityQualityIds] = useState(props.seedPlantQuantityQualityIds);
    const [seedPlantQuantityQuality, setSeedPlantQuantityQuality] = useState(
        (props.plantRequirements &&
            props.plantRequirements.main &&
            props.plantRequirements.main.length > 0 &&
            props.plantRequirements.main[0].quantityQuality) ||
            ''
    );
    const [quantity1, setQuantity1] = useState(
        (props.plantRequirements &&
            props.plantRequirements.main &&
            props.plantRequirements.main.length > 0 &&
            props.plantRequirements.main[0].quantity1 !== null &&
            props.plantRequirements.main[0].quantity1.toString()) ||
            '0'
    );
    const [quantity2, setQuantity2] = useState(
        (props.plantRequirements &&
            props.plantRequirements.main &&
            props.plantRequirements.additionalQuantQualityInfo.length > 0 &&
            props.plantRequirements.additionalQuantQualityInfo[0].quantity2 !== null &&
            props.plantRequirements.additionalQuantQualityInfo[0].quantity2.toString()) ||
            '0'
    );
    const [unit1Id, setUnit1Id] = useState(0);
    const [unit2Id, setUnit2Id] = useState(0);
    const [units, setUnits] = useState<Array<{id: number; value: string}>>([
        {
            id: 0,
            value: ' ',
        },
    ]);
    const [type1Id, setType1Id] = useState(0);
    const [type2Id, setType2Id] = useState(0);
    const [types, setTypes] = useState<Array<{id: number; value: string}>>([
        {
            id: 0,
            value: ' ',
        },
    ]);
    const [alternativeQuantity1Id, setAlternativeQuantity1Id] = useState(0);
    const [alternativeQuantity2Id, setAlternativeQuantity2Id] = useState(0);
    const [alternativeQuantities, setAlternativeQuantities] = useState<Array<{id: number; value: string}>>([
        {
            id: 0,
            value: ' ',
        },
    ]);
    const [age1Id, setAge1Id] = useState(0);
    const [age2Id, setAge2Id] = useState(0);
    const [ages, setAges] = useState<Array<{id: number; value: string}>>([
        {
            id: 0,
            value: ' ',
        },
    ]);
    const [size1Id, setSize1Id] = useState(0);
    const [size2Id, setSize2Id] = useState(0);
    const [sizes, setSizes] = useState<Array<{id: number; value: string}>>([
        {
            id: 0,
            value: ' ',
        },
    ]);
    const [seedQuality1Id, setSeedQuality1Id] = useState(0);
    const [seedQuality2Id, setSeedQuality2Id] = useState(0);
    const [seedQualities, setSeedQualities] = useState<Array<{id: number; value: string}>>([
        {
            id: 0,
            value: ' ',
        },
    ]);
    const [rootsAndGrafts1Id, setRootsAndGrafts1Id] = useState(0);
    const [rootsAndGrafts2Id, setRootsAndGrafts2Id] = useState(0);
    const [rootsAndGrafts, setRootsAndGrafts] = useState<Array<{id: number; value: string}>>([
        {
            id: 0,
            value: ' ',
        },
    ]);
    const [otherQualities1, setOtherQualities1] = useState('');
    const [selectedOtherQualities1, setSelectedOtherQualities1] = useState<{[key: string]: string}>({});
    const [otherQualities1ListByIdName, setOtherQualities1ListByIdName] = useState<Array<{
        ID: string;
        NAME: string;
    }> | null>(null);
    const [otherQualities1NameHash, setOtherQualities1NameHash] = useState<{[key: string]: Array<string>}>({});
    const [otherQualities2, setOtherQualities2] = useState('');
    const [selectedOtherQualities2, setSelectedOtherQualities2] = useState<{[key: string]: string}>({});
    const [otherQualities2ListByIdName, setOtherQualities2ListByIdName] = useState<Array<{
        ID: string;
        NAME: string;
    }> | null>(null);
    const [otherQualities2NameHash, setOtherQualities2NameHash] = useState<{[key: string]: Array<string>}>({});
    const [phytoSanitaryCertificate1Id, setPhytoSanitaryCertificate1Id] = useState(0);
    const [phytoSanitaryCertificate2Id, setPhytoSanitaryCertificate2Id] = useState(0);
    const [phytoSanitaryCertificates, setPhytoSanitaryCertificates] = useState<Array<{id: number; value: string}>>([
        {
            id: 0,
            value: ' ',
        },
    ]);
    const [testedPests1, setTestedPests1] = useState('');
    const [selectedTestedPests1, setSelectedTestedPests1] = useState<{[key: string]: string}>({});
    const [testedPests1ListByIdName, setTestedPests1ListByIdName] = useState<Array<{ID: string; NAME: string}> | null>(
        null
    );
    const [testedPests1NameHash, setTestedPests1NameHash] = useState<{[key: string]: Array<string>}>({});
    const [testedPests2, setTestedPests2] = useState('');
    const [selectedTestedPests2, setSelectedTestedPests2] = useState<{[key: string]: string}>({});
    const [testedPests2ListByIdName, setTestedPests2ListByIdName] = useState<Array<{ID: string; NAME: string}> | null>(
        null
    );
    const [testedPests2NameHash, setTestedPests2NameHash] = useState<{[key: string]: Array<string>}>({});
    const [others1, setOthers1] = useState('');
    const [selectedOthers1, setSelectedOthers1] = useState<{[key: string]: string}>({});
    const [others1ListByIdName, setOthers1ListByIdName] = useState<Array<{ID: string; NAME: string}> | null>(null);
    const [others1NameHash, setOthers1NameHash] = useState<{[key: string]: Array<string>}>({});
    const [others2, setOthers2] = useState('');
    const [selectedOthers2, setSelectedOthers2] = useState<{[key: string]: string}>({});
    const [others2ListByIdName, setOthers2ListByIdName] = useState<Array<{ID: string; NAME: string}> | null>(null);
    const [others2NameHash, setOthers2NameHash] = useState<{[key: string]: Array<string>}>({});
    const [andOrId, setAndOrId] = useState(
        (props.plantRequirements &&
            props.plantRequirements.main &&
            props.plantRequirements.main.length > 0 &&
            props.plantRequirements.main[0].andor !== null &&
            props.plantRequirements.main[0].andor) ||
            0
    );
    const [andOrList, setAndOrList] = useState<Array<{id: number; value: string}>>([
        {
            id: 0,
            value: ' ',
        },
    ]);
    const [highlightErrors, setHighlightErrors] = useState(false);

    useEffect(() => {
        loadJSONs();
    }, []);

    const loadJSONs = () => {
        getTabObjects();
    };

    const getTabObjects = () => {
        setLoading(true);
        apiTabObjectsFetch()
            .then((jsonResponse: TabObjectsFetchData) => {
                if (jsonResponse && jsonResponse.data && jsonResponse.data.tabObjectsList) {
                    const tabObjectUnits = jsonResponse.data.tabObjectsList.filter(object => object.categoryId === 2);
                    setTabObjectUnits(tabObjectUnits);
                    const tabObjectTypes = jsonResponse.data.tabObjectsList.filter(object => object.categoryId === 3);
                    setTabObjectTypes(tabObjectTypes);
                    const tabObjectAlternativeQuantities = jsonResponse.data.tabObjectsList.filter(
                        object => object.categoryId === 4
                    );
                    setTabObjectAlternativeQuantities(tabObjectAlternativeQuantities);
                    const tabObjectAges = jsonResponse.data.tabObjectsList.filter(object => object.categoryId === 5);
                    setTabObjectAges(tabObjectAges);
                    const tabObjectSizes = jsonResponse.data.tabObjectsList.filter(object => object.categoryId === 6);
                    setTabObjectSizes(tabObjectSizes);
                    const tabObjectRootsAndGrafts = jsonResponse.data.tabObjectsList.filter(
                        object => object.categoryId === 7
                    );
                    setTabObjectRootsAndGrafts(tabObjectRootsAndGrafts);
                    const tabObjectSeedQualities = jsonResponse.data.tabObjectsList.filter(
                        object => object.categoryId === 8
                    );
                    setTabObjectSeedQualities(tabObjectSeedQualities);
                    const tabObjectOtherQualities = jsonResponse.data.tabObjectsList.filter(
                        object => object.categoryId === 9
                    );
                    setTabObjectOtherQualities(tabObjectOtherQualities);
                    const tabObjectPhytoSanitaryCertificates = jsonResponse.data.tabObjectsList.filter(
                        object => object.categoryId === 10
                    );
                    setTabObjectPhytoSanitaryCertificates(tabObjectPhytoSanitaryCertificates);
                    const tabObjectTestedPests = jsonResponse.data.tabObjectsList.filter(
                        object => object.categoryId === 11
                    );
                    setTabObjectTestedPests(tabObjectTestedPests);
                    const tabObjectOthers = jsonResponse.data.tabObjectsList.filter(object => object.categoryId === 12);
                    setTabObjectOthers(tabObjectOthers);
                    const tabObjectAndOr = jsonResponse.data.tabObjectsList.filter(object => object.categoryId === 13);
                    setTabObjectAndOrList(tabObjectAndOr);
                }
            })
            .catch(error => {
                setErrorMessageModal(`Tab Objects list error: ${error}`);
            })
            .finally(() => {
                setLoaded(true);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (loaded) {
            if (props.triggerCloseAfterInit && props.setTriggerCloseAfterInit) {
                props.setTriggerCloseAfterInit(false);
                submitFormattedData(true);
            }
        }
    }, [loaded]);

    const setTabObjectUnits = (tabObjectUnits: Array<TabObject>) => {
        const finalUnits: Array<{id: number; value: string}> = units;
        tabObjectUnits.forEach(tabObj =>
            finalUnits.push({
                id: tabObj.objectId,
                value: tabObj.name,
            })
        );
        setUnits(finalUnits);
        if (
            props.plantRequirements &&
            props.plantRequirements.main &&
            props.plantRequirements.main.length > 0 &&
            props.plantRequirements.main[0].unit1
        ) {
            let unit1 = 0;
            if (props.speciesExamId === 0) {
                unit1 = finalUnits.filter(el => el.id === Number(props.plantRequirements?.main[0].unit1))[0].id;
            } else {
                unit1 = finalUnits.filter(el => el.value === props.plantRequirements?.main[0].unit1)[0].id;
            }
            setUnit1Id(unit1);
        }
        if (
            props.plantRequirements &&
            props.plantRequirements.additionalQuantQualityInfo &&
            props.plantRequirements.additionalQuantQualityInfo.length > 0 &&
            props.plantRequirements.additionalQuantQualityInfo[0].unit2
        ) {
            let unit2 = 0;
            if (props.speciesExamId === 0) {
                unit2 = finalUnits.filter(
                    el => el.id === Number(props.plantRequirements?.additionalQuantQualityInfo[0].unit2)
                )[0].id;
            } else {
                unit2 = finalUnits.filter(
                    el => el.value === props.plantRequirements?.additionalQuantQualityInfo[0].unit2
                )[0].id;
            }
            setUnit2Id(unit2);
        }
    };

    const setTabObjectTypes = (tabObjectTypes: Array<TabObject>) => {
        const finalTypes: Array<{id: number; value: string}> = types;
        tabObjectTypes.forEach(unit =>
            finalTypes.push({
                id: unit.objectId,
                value: unit.name,
            })
        );
        setTypes(finalTypes);
        if (
            props.plantRequirements &&
            props.plantRequirements.main &&
            props.plantRequirements.main.length > 0 &&
            props.plantRequirements.main[0].type1
        ) {
            let type1 = 0;
            if (props.speciesExamId === 0) {
                type1 = finalTypes.filter(el => el.id === Number(props.plantRequirements?.main[0].type1))[0].id;
            } else {
                type1 = finalTypes.filter(el => el.value === props.plantRequirements?.main[0].type1)[0].id;
            }
            setType1Id(type1);
        }
        if (
            props.plantRequirements &&
            props.plantRequirements.additionalQuantQualityInfo &&
            props.plantRequirements.additionalQuantQualityInfo.length > 0 &&
            props.plantRequirements.additionalQuantQualityInfo[0].type2
        ) {
            let type2 = 0;
            if (props.speciesExamId === 0) {
                type2 = finalTypes.filter(
                    el => el.id === Number(props.plantRequirements?.additionalQuantQualityInfo[0].type2)
                )[0].id;
            } else {
                type2 = finalTypes.filter(
                    el => el.value === props.plantRequirements?.additionalQuantQualityInfo[0].type2
                )[0].id;
            }
            setType2Id(type2);
        }
    };

    const setTabObjectAlternativeQuantities = (tabObjectAlternativeQuantities: Array<TabObject>) => {
        const finalAlternativeQuantities: Array<{id: number; value: string}> = alternativeQuantities;
        tabObjectAlternativeQuantities.forEach(tabObj =>
            finalAlternativeQuantities.push({
                id: tabObj.objectId,
                value: tabObj.name,
            })
        );
        setAlternativeQuantities(finalAlternativeQuantities);
        if (
            props.plantRequirements &&
            props.plantRequirements.main &&
            props.plantRequirements.main.length > 0 &&
            props.plantRequirements.main[0].alternativeQuantity1
        ) {
            let alternativeQuantity1 = 0;
            if (props.speciesExamId === 0) {
                alternativeQuantity1 = finalAlternativeQuantities.filter(
                    el => el.id === Number(props.plantRequirements?.main[0].alternativeQuantity1)
                )[0].id;
            } else {
                alternativeQuantity1 = finalAlternativeQuantities.filter(
                    el => el.value === props.plantRequirements?.main[0].alternativeQuantity1
                )[0].id;
            }
            setAlternativeQuantity1Id(alternativeQuantity1);
        }
        if (
            props.plantRequirements &&
            props.plantRequirements.additionalQuantQualityInfo &&
            props.plantRequirements.additionalQuantQualityInfo.length > 0 &&
            props.plantRequirements.additionalQuantQualityInfo[0].alternativeQuantity2
        ) {
            let alternativeQuantity2 = 0;
            if (props.speciesExamId === 0) {
                alternativeQuantity2 = finalAlternativeQuantities.filter(
                    el => el.id === Number(props.plantRequirements?.additionalQuantQualityInfo[0].alternativeQuantity2)
                )[0].id;
            } else {
                alternativeQuantity2 = finalAlternativeQuantities.filter(
                    el => el.value === props.plantRequirements?.additionalQuantQualityInfo[0].alternativeQuantity2
                )[0].id;
            }
            setAlternativeQuantity2Id(alternativeQuantity2);
        }
    };

    const setTabObjectAges = (tabObjectAges: Array<TabObject>) => {
        const finalAges: Array<{id: number; value: string}> = ages;
        tabObjectAges.forEach(tabObj =>
            finalAges.push({
                id: tabObj.objectId,
                value: tabObj.name,
            })
        );
        setAges(finalAges);
        if (
            props.plantRequirements &&
            props.plantRequirements.main &&
            props.plantRequirements.main.length > 0 &&
            props.plantRequirements.main[0].age1
        ) {
            let age1 = 0;
            if (props.speciesExamId === 0) {
                age1 = finalAges.filter(el => el.id === Number(props.plantRequirements?.main[0].age1))[0].id;
            } else {
                age1 = finalAges.filter(el => el.value === props.plantRequirements?.main[0].age1)[0].id;
            }
            setAge1Id(age1);
        }
        if (
            props.plantRequirements &&
            props.plantRequirements.main &&
            props.plantRequirements.additionalQuantQualityInfo.length > 0 &&
            props.plantRequirements.additionalQuantQualityInfo[0].age2
        ) {
            let age2 = 0;
            if (props.speciesExamId === 0) {
                age2 = finalAges.filter(
                    el => el.id === Number(props.plantRequirements?.additionalQuantQualityInfo[0].age2)
                )[0].id;
            } else {
                age2 = finalAges.filter(
                    el => el.value === props.plantRequirements?.additionalQuantQualityInfo[0].age2
                )[0].id;
            }
            setAge2Id(age2);
        }
    };

    const setTabObjectSizes = (tabObjectSizes: Array<TabObject>) => {
        const finalSizes: Array<{id: number; value: string}> = sizes;
        tabObjectSizes.forEach(tabObj =>
            finalSizes.push({
                id: tabObj.objectId,
                value: tabObj.name,
            })
        );
        setSizes(finalSizes);
        if (
            props.plantRequirements &&
            props.plantRequirements.main &&
            props.plantRequirements.main.length > 0 &&
            props.plantRequirements.main[0].size1
        ) {
            let size1 = 0;
            if (props.speciesExamId === 0) {
                size1 = finalSizes.filter(el => el.id === Number(props.plantRequirements?.main[0].size1))[0].id;
            } else {
                size1 = finalSizes.filter(el => el.value === props.plantRequirements?.main[0].size1)[0].id;
            }
            setSize1Id(size1);
        }
        if (
            props.plantRequirements &&
            props.plantRequirements.additionalQuantQualityInfo &&
            props.plantRequirements.additionalQuantQualityInfo.length > 0 &&
            props.plantRequirements.additionalQuantQualityInfo[0].size2
        ) {
            let size2 = 0;
            if (props.speciesExamId === 0) {
                size2 = finalSizes.filter(
                    el => el.id === Number(props.plantRequirements?.additionalQuantQualityInfo[0].size2)
                )[0].id;
            } else {
                size2 = finalSizes.filter(
                    el => el.value === props.plantRequirements?.additionalQuantQualityInfo[0].size2
                )[0].id;
            }
            setSize2Id(size2);
        }
    };

    const setTabObjectRootsAndGrafts = (tabObjectRootsAndGrafts: Array<TabObject>) => {
        const finalRootsAndGrafts: Array<{id: number; value: string}> = rootsAndGrafts;
        tabObjectRootsAndGrafts.forEach(tabObj =>
            finalRootsAndGrafts.push({
                id: tabObj.objectId,
                value: tabObj.name,
            })
        );
        setRootsAndGrafts(finalRootsAndGrafts);
        if (
            props.plantRequirements &&
            props.plantRequirements.main &&
            props.plantRequirements.main.length > 0 &&
            props.plantRequirements.main[0].rootsAndGrafts1
        ) {
            let rootsAndGrafts1 = 0;
            if (props.speciesExamId === 0) {
                let foundItems = finalRootsAndGrafts.filter(
                    el => el.id === Number(props.plantRequirements?.main[0].rootsAndGrafts1)
                );
                if (foundItems && foundItems.length && foundItems[0].id) {
                    rootsAndGrafts1 = foundItems[0].id;
                }
            } else {
                let foundItems = finalRootsAndGrafts.filter(
                    el => el.value === props.plantRequirements?.main[0].rootsAndGrafts1
                );
                if (foundItems && foundItems.length && foundItems[0].id) {
                    rootsAndGrafts1 = foundItems[0].id;
                }
            }
            setRootsAndGrafts1Id(rootsAndGrafts1);
        }
        if (
            props.plantRequirements &&
            props.plantRequirements.additionalQuantQualityInfo &&
            props.plantRequirements.additionalQuantQualityInfo.length > 0 &&
            props.plantRequirements.additionalQuantQualityInfo[0].rootsAndGrafts2
        ) {
            let rootsAndGrafts2 = 0;
            if (props.speciesExamId === 0) {
                rootsAndGrafts2 = finalRootsAndGrafts.filter(
                    el => el.id === Number(props.plantRequirements?.additionalQuantQualityInfo[0].rootsAndGrafts2)
                )[0].id;
            } else {
                rootsAndGrafts2 = finalRootsAndGrafts.filter(
                    el => el.value === props.plantRequirements?.additionalQuantQualityInfo[0].rootsAndGrafts2
                )[0].id;
            }
            setRootsAndGrafts2Id(rootsAndGrafts2);
        }
    };

    const setTabObjectSeedQualities = (tabObjectSeedQualities: Array<TabObject>) => {
        const finalSeedQualities: Array<{id: number; value: string}> = seedQualities;
        tabObjectSeedQualities.forEach(tabObj =>
            finalSeedQualities.push({
                id: tabObj.objectId,
                value: tabObj.name,
            })
        );
        setSeedQualities(finalSeedQualities);
        if (
            props.plantRequirements &&
            props.plantRequirements.main &&
            props.plantRequirements.main.length > 0 &&
            props.plantRequirements.main[0].seedQuality1
        ) {
            let seedQuality1 = 0;
            if (props.speciesExamId === 0) {
                seedQuality1 = finalSeedQualities.filter(
                    el => el.id === Number(props.plantRequirements?.main[0].seedQuality1)
                )[0].id;
            } else {
                seedQuality1 = finalSeedQualities.filter(
                    el => el.value === props.plantRequirements?.main[0].seedQuality1
                )[0].id;
            }
            setSeedQuality1Id(seedQuality1);
        }
        if (
            props.plantRequirements &&
            props.plantRequirements.additionalQuantQualityInfo &&
            props.plantRequirements.additionalQuantQualityInfo.length > 0 &&
            props.plantRequirements.additionalQuantQualityInfo[0].seedQuality2
        ) {
            let seedQuality2 = 0;
            if (props.speciesExamId === 0) {
                seedQuality2 = finalSeedQualities.filter(
                    el => el.id === Number(props.plantRequirements?.additionalQuantQualityInfo[0].seedQuality2)
                )[0].id;
            } else {
                seedQuality2 = finalSeedQualities.filter(
                    el => el.value === props.plantRequirements?.additionalQuantQualityInfo[0].seedQuality2
                )[0].id;
            }
            setSeedQuality2Id(seedQuality2);
        }
    };

    const setTabObjectOtherQualities = (tabObjectOtherQualities: Array<TabObject>) => {
        const finalOtherQualities: Array<{id: number; value: string}> = [];
        tabObjectOtherQualities.forEach(tabObj =>
            finalOtherQualities.push({
                id: tabObj.objectId,
                value: tabObj.name,
            })
        );
        const otherQualities1NameHash: {[key: string]: Array<string>} = {};
        (finalOtherQualities || []).forEach(oq => {
            if (!otherQualities1NameHash[oq.value]) {
                otherQualities1NameHash[oq.value] = [];
            }
            otherQualities1NameHash[oq.value].push(oq.id.toString());
        });
        const otherQualities1ListByIdName = Object.keys(otherQualities1NameHash).map(oqn => ({
            ID: otherQualities1NameHash[oqn].join(','),
            NAME: oqn,
        }));
        let selectedOtherQualities1Obj: {[key: string]: string} = {};
        if (
            props.plantRequirements &&
            props.plantRequirements !== null &&
            props.plantRequirements.otherQualities &&
            props.plantRequirements.otherQualities.length > 0 &&
            props.plantRequirements.otherQualities[0].otherQualities1.length > 0
        ) {
            let otherQuality1: Array<{
                ID: string;
                NAME: string;
            }> = [];
            if (props.speciesExamId === 0) {
                for (const otherQuality of props.plantRequirements.otherQualities) {
                    for (const oq1 of otherQuality.otherQualities1.split('-')) {
                        otherQuality1 = otherQualities1ListByIdName.filter(
                            oq => props.plantRequirements !== null && oq.ID === oq1
                        );
                        selectedOtherQualities1Obj[otherQuality1[0].ID] = otherQuality1[0].NAME;
                    }
                }
            } else {
                for (const otherQuality of props.plantRequirements.otherQualities) {
                    otherQuality1 = otherQualities1ListByIdName.filter(
                        oq => props.plantRequirements !== null && oq.NAME === otherQuality.otherQualities1
                    );
                    selectedOtherQualities1Obj[otherQuality1[0].ID] = otherQuality.otherQualities1;
                }
            }
        }
        setOtherQualities1NameHash(otherQualities1NameHash);
        setOtherQualities1ListByIdName(otherQualities1ListByIdName);
        setSelectedOtherQualities1(selectedOtherQualities1Obj);

        const otherQualities2NameHash: {[key: string]: Array<string>} = {};
        (finalOtherQualities || []).forEach(oq => {
            if (!otherQualities2NameHash[oq.value]) {
                otherQualities2NameHash[oq.value] = [];
            }
            otherQualities2NameHash[oq.value].push(oq.id.toString());
        });
        const otherQualities2ListByIdName = Object.keys(otherQualities2NameHash).map(oqn => ({
            ID: otherQualities2NameHash[oqn].join(','),
            NAME: oqn,
        }));
        let selectedOtherQualities2Obj: {[key: string]: string} = {};
        if (
            props.plantRequirements &&
            props.plantRequirements !== null &&
            props.plantRequirements.additionalOtherQualities &&
            props.plantRequirements.additionalOtherQualities.length > 0 &&
            props.plantRequirements.additionalOtherQualities[0].otherQualities2.length > 0
        ) {
            let otherQuality2: Array<{
                ID: string;
                NAME: string;
            }> = [];
            if (props.speciesExamId === 0) {
                for (const additionalOtherQuality of props.plantRequirements.additionalOtherQualities) {
                    for (const oq2 of additionalOtherQuality.otherQualities2.split('-')) {
                        otherQuality2 = otherQualities2ListByIdName.filter(
                            oq => props.plantRequirements !== null && oq.ID === oq2
                        );
                        selectedOtherQualities2Obj[otherQuality2[0].ID] = otherQuality2[0].NAME;
                    }
                }
            } else {
                for (const additionalOtherQuality of props.plantRequirements.additionalOtherQualities) {
                    otherQuality2 = otherQualities2ListByIdName.filter(
                        oq => props.plantRequirements !== null && oq.NAME === additionalOtherQuality.otherQualities2
                    );
                    selectedOtherQualities2Obj[otherQuality2[0].ID] = additionalOtherQuality.otherQualities2;
                }
            }
        }
        setOtherQualities2NameHash(otherQualities2NameHash);
        setOtherQualities2ListByIdName(otherQualities2ListByIdName);
        setSelectedOtherQualities2(selectedOtherQualities2Obj);
    };

    const setTabObjectPhytoSanitaryCertificates = (tabObjectPhytoSanitaryCertificates: Array<TabObject>) => {
        const finalPhytoSanitaryCertificates: Array<{id: number; value: string}> = phytoSanitaryCertificates;
        tabObjectPhytoSanitaryCertificates.forEach(tabObj =>
            finalPhytoSanitaryCertificates.push({
                id: tabObj.objectId,
                value: tabObj.name,
            })
        );

        setPhytoSanitaryCertificates(finalPhytoSanitaryCertificates);
        if (
            props.plantRequirements &&
            props.plantRequirements.main &&
            props.plantRequirements.main.length > 0 &&
            props.plantRequirements.main[0].phytoSanitaryCertificate1
        ) {
            let phytoSanitaryCertificate1 = 0;
            if (props.speciesExamId === 0) {
                phytoSanitaryCertificate1 = finalPhytoSanitaryCertificates.filter(
                    el => el.id === Number(props.plantRequirements?.main[0].phytoSanitaryCertificate1)
                )[0].id;
            } else {
                phytoSanitaryCertificate1 = finalPhytoSanitaryCertificates.filter(
                    el => el.value === props.plantRequirements?.main[0].phytoSanitaryCertificate1
                )[0].id;
            }
            setPhytoSanitaryCertificate1Id(phytoSanitaryCertificate1);
        }
        if (
            props.plantRequirements &&
            props.plantRequirements.additionalQuantQualityInfo &&
            props.plantRequirements.additionalQuantQualityInfo.length > 0 &&
            props.plantRequirements.additionalQuantQualityInfo[0].phytoSanitaryCertificate2
        ) {
            let phytoSanitaryCertificate2 = 0;
            if (props.speciesExamId === 0) {
                phytoSanitaryCertificate2 = finalPhytoSanitaryCertificates.filter(
                    el =>
                        el.id ===
                        Number(props.plantRequirements?.additionalQuantQualityInfo[0].phytoSanitaryCertificate2)
                )[0].id;
            } else {
                phytoSanitaryCertificate2 = finalPhytoSanitaryCertificates.filter(
                    el => el.value === props.plantRequirements?.additionalQuantQualityInfo[0].phytoSanitaryCertificate2
                )[0].id;
            }
            setPhytoSanitaryCertificate2Id(phytoSanitaryCertificate2);
        }
    };

    const setTabObjectTestedPests = (tabObjectTestedPests: Array<TabObject>) => {
        const finalTestedPests: Array<{id: number; value: string}> = [];
        tabObjectTestedPests.forEach(tabObj =>
            finalTestedPests.push({
                id: tabObj.objectId,
                value: tabObj.name,
            })
        );
        const testedPests1NameHash: {[key: string]: Array<string>} = {};
        (finalTestedPests || []).forEach(tp => {
            if (!testedPests1NameHash[tp.value]) {
                testedPests1NameHash[tp.value] = [];
            }
            testedPests1NameHash[tp.value].push(tp.id.toString());
        });
        const testedPests1ListByIdName = Object.keys(testedPests1NameHash).map(tpn => ({
            ID: testedPests1NameHash[tpn].join(','),
            NAME: tpn,
        }));
        let selectedTestedPests1Obj: {[key: string]: string} = {};
        if (
            props.plantRequirements &&
            props.plantRequirements !== null &&
            props.plantRequirements.testedPests &&
            props.plantRequirements.testedPests.length > 0 &&
            props.plantRequirements.testedPests[0].testedPests1.length > 0
        ) {
            let testedPest1: Array<{
                ID: string;
                NAME: string;
            }> = [];
            if (props.speciesExamId === 0) {
                for (const testedPest of props.plantRequirements.testedPests) {
                    for (const tp1 of testedPest.testedPests1.split('-')) {
                        testedPest1 = testedPests1ListByIdName.filter(
                            tp => props.plantRequirements !== null && tp.ID === tp1
                        );
                        selectedTestedPests1Obj[testedPest1[0].ID] = testedPest1[0].NAME;
                    }
                }
            } else {
                for (const testedPest of props.plantRequirements.testedPests) {
                    testedPest1 = testedPests1ListByIdName.filter(
                        tp => props.plantRequirements !== null && tp.NAME === testedPest.testedPests1
                    );
                    selectedTestedPests1Obj[testedPest1[0].ID] = testedPest.testedPests1;
                }
            }
        }
        setTestedPests1NameHash(testedPests1NameHash);
        setTestedPests1ListByIdName(testedPests1ListByIdName);
        setSelectedTestedPests1(selectedTestedPests1Obj);

        const testedPests2NameHash: {[key: string]: Array<string>} = {};
        (finalTestedPests || []).forEach(tp => {
            if (!testedPests2NameHash[tp.value]) {
                testedPests2NameHash[tp.value] = [];
            }
            testedPests2NameHash[tp.value].push(tp.id.toString());
        });
        const testedPests2ListByIdName = Object.keys(testedPests2NameHash).map(tpn => ({
            ID: testedPests2NameHash[tpn].join(','),
            NAME: tpn,
        }));
        let selectedTestedPests2Obj: {[key: string]: string} = {};
        if (
            props.plantRequirements &&
            props.plantRequirements !== null &&
            props.plantRequirements.additionalTestedPests &&
            props.plantRequirements.additionalTestedPests.length > 0 &&
            props.plantRequirements.additionalTestedPests[0].testedPests2.length > 0
        ) {
            let testedPest2: Array<{
                ID: string;
                NAME: string;
            }> = [];
            if (props.speciesExamId === 0) {
                for (const additionalTestedPest of props.plantRequirements.additionalTestedPests) {
                    for (const tp2 of additionalTestedPest.testedPests2.split('-')) {
                        testedPest2 = testedPests2ListByIdName.filter(
                            tp => props.plantRequirements !== null && tp.ID === tp2
                        );
                        selectedTestedPests2Obj[testedPest2[0].ID] = testedPest2[0].NAME;
                    }
                }
            } else {
                for (const additionalTestedPest of props.plantRequirements.additionalTestedPests) {
                    testedPest2 = testedPests2ListByIdName.filter(
                        tp => props.plantRequirements !== null && tp.NAME === additionalTestedPest.testedPests2
                    );
                    selectedTestedPests2Obj[testedPest2[0].ID] = additionalTestedPest.testedPests2;
                }
            }
        }
        setTestedPests2NameHash(testedPests2NameHash);
        setTestedPests2ListByIdName(testedPests2ListByIdName);
        setSelectedTestedPests2(selectedTestedPests2Obj);
    };

    const setTabObjectOthers = (tabObjectOthers: Array<TabObject>) => {
        const finalOthers: Array<{id: number; value: string}> = [];
        tabObjectOthers.forEach(tabObj =>
            finalOthers.push({
                id: tabObj.objectId,
                value: tabObj.name,
            })
        );
        const others1NameHash: {[key: string]: Array<string>} = {};
        (finalOthers || []).forEach(o => {
            if (!others1NameHash[o.value]) {
                others1NameHash[o.value] = [];
            }
            others1NameHash[o.value].push(o.id.toString());
        });
        const others1ListByIdName = Object.keys(others1NameHash).map(on => ({
            ID: others1NameHash[on].join(','),
            NAME: on,
        }));
        let selectedOthers1Obj: {[key: string]: string} = {};
        if (
            props.plantRequirements &&
            props.plantRequirements !== null &&
            props.plantRequirements.others &&
            props.plantRequirements.others.length > 0 &&
            props.plantRequirements.others[0].others1.length > 0
        ) {
            let other1: Array<{
                ID: string;
                NAME: string;
            }> = [];
            if (props.speciesExamId === 0) {
                for (const other of props.plantRequirements.others) {
                    for (const o1 of other.others1.split('-')) {
                        other1 = others1ListByIdName.filter(o => props.plantRequirements !== null && o.ID === o1);
                        selectedOthers1Obj[other1[0].ID] = other1[0].NAME;
                    }
                }
            } else {
                for (const other of props.plantRequirements.others) {
                    other1 = others1ListByIdName.filter(
                        tp => props.plantRequirements !== null && tp.NAME === other.others1
                    );
                    selectedOthers1Obj[other1[0].ID] = other.others1;
                }
            }
        }
        setOthers1NameHash(others1NameHash);
        setOthers1ListByIdName(others1ListByIdName);
        setSelectedOthers1(selectedOthers1Obj);

        const others2NameHash: {[key: string]: Array<string>} = {};
        (finalOthers || []).forEach(o => {
            if (!others2NameHash[o.value]) {
                others2NameHash[o.value] = [];
            }
            others2NameHash[o.value].push(o.id.toString());
        });
        const others2ListByIdName = Object.keys(others2NameHash).map(on => ({
            ID: others2NameHash[on].join(','),
            NAME: on,
        }));
        let selectedOthers2Obj: {[key: string]: string} = {};
        if (
            props.plantRequirements &&
            props.plantRequirements !== null &&
            props.plantRequirements.additionalOthers &&
            props.plantRequirements.additionalOthers.length > 0 &&
            props.plantRequirements.additionalOthers[0].others2.length > 0
        ) {
            let other2: Array<{
                ID: string;
                NAME: string;
            }> = [];
            if (props.speciesExamId === 0) {
                for (const additionalOther of props.plantRequirements.additionalOthers) {
                    for (const o2 of additionalOther.others2.split('-')) {
                        other2 = others2ListByIdName.filter(o => props.plantRequirements !== null && o.ID === o2);
                        selectedOthers2Obj[other2[0].ID] = other2[0].NAME;
                    }
                }
            } else {
                for (const additionalOther of props.plantRequirements.additionalOthers) {
                    other2 = others2ListByIdName.filter(
                        tp => props.plantRequirements !== null && tp.NAME === additionalOther.others2
                    );
                    selectedOthers2Obj[other2[0].ID] = additionalOther.others2;
                }
            }
        }
        setOthers2NameHash(others2NameHash);
        setOthers2ListByIdName(others2ListByIdName);
        setSelectedOthers2(selectedOthers2Obj);
    };

    const setTabObjectAndOrList = (tabObjectAndOrList: Array<TabObject>) => {
        const finalAndOrList: Array<{id: number; value: string}> = andOrList;
        tabObjectAndOrList.forEach(tabObj =>
            finalAndOrList.push({
                id: tabObj.objectId,
                value: tabObj.name,
            })
        );
        setAndOrList(finalAndOrList);
        if (
            props.plantRequirements &&
            props.plantRequirements.main &&
            props.plantRequirements.main.length > 0 &&
            props.plantRequirements.main[0].andor
        ) {
            let andor = 0;
            if (props.speciesExamId === 0) {
                andor = finalAndOrList.filter(el => el.id === Number(props.plantRequirements?.main[0].andor))[0].id;
            } else {
                andor = finalAndOrList.filter(el => el.value === props.plantRequirements?.main[0].andor)[0].id;
            }
            setAndOrId(andor);
        }
    };

    const comebackToSearch = () => {
        props.close && props.close(false);
    };

    const closeErrorModal = () => {
        setErrorMessageModal(null);
    };

    const onQuantity1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        const quantity1 = event.target.value
            .replace(/[^0-9]/g, '')
            .replace('', '0')
            .replace(/^0+(\d)/, '$1');
        setQuantity1(quantity1);
    };

    const onUnit1Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const unit1 = event.target.value;
        setUnit1Id(Number(unit1));
    };

    const onType1Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const type1 = event.target.value;
        setType1Id(Number(type1));
    };

    const onAlternativeQuantity1Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const alternativeQuantity1 = event.target.value;
        setAlternativeQuantity1Id(Number(alternativeQuantity1));
    };

    const onAge1Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const age1 = event.target.value;
        setAge1Id(Number(age1));
    };

    const onSize1Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const size1 = event.target.value;
        setSize1Id(Number(size1));
    };

    const onSeedQuality1Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const seedQuality1 = event.target.value;
        setSeedQuality1Id(Number(seedQuality1));
    };

    const onRootAndGraft1Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const rootsAndGrafts1 = event.target.value;
        setRootsAndGrafts1Id(Number(rootsAndGrafts1));
    };

    const onOtherQualities1SelectionChange = (selectedOtherQualities1: {[key: string]: string}) => {
        setSelectedOtherQualities1(selectedOtherQualities1);
    };

    const onOtherQualities1Change = (otherQualities1: string) => {
        setOtherQualities1(otherQualities1);
    };

    const onPhytoSanitaryCertificate1Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const phytoSanitaryCertificate1 = event.target.value;
        setPhytoSanitaryCertificate1Id(Number(phytoSanitaryCertificate1));
    };

    const onTestedPests1SelectionChange = (selectedTestedPests1: {[key: string]: string}) => {
        setSelectedTestedPests1(selectedTestedPests1);
    };

    const onTestedPests1Change = (testedPests1: string) => {
        setTestedPests1(testedPests1);
    };

    const onOthers1SelectionChange = (selectedOthers1: {[key: string]: string}) => {
        setSelectedOthers1(selectedOthers1);
    };

    const onOthers1Change = (others1: string) => {
        setOthers1(others1);
    };

    const onAndOrChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const andOr = event.target.value;
        setAndOrId(Number(andOr));
    };

    useEffect(() => {
        if (andOrId === 0) {
            setQuantity2('0');
            setUnit2Id(0);
            setType2Id(0);
            setAlternativeQuantity2Id(0);
            setSize2Id(0);
            setRootsAndGrafts2Id(0);
            setSeedQuality2Id(0);
            setOtherQualities2('');
            setSelectedOtherQualities2({});
            setPhytoSanitaryCertificate2Id(0);
            setTestedPests2('');
            setSelectedTestedPests2({});
            setOthers2('');
            setSelectedOthers2({});
        }
    }, [andOrId]);

    const onQuantity2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        const quantity2 = event.target.value
            .replace(/[^0-9]/g, '')
            .replace('', '0')
            .replace(/^0+(\d)/, '$1');
        setQuantity2(quantity2);
    };

    const onUnit2Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const unit2 = event.target.value;
        setUnit2Id(Number(unit2));
    };

    const onType2Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const type2 = event.target.value;
        setType2Id(Number(type2));
    };

    const onAlternativeQuantity2Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const alternativeQuantity2 = event.target.value;
        setAlternativeQuantity2Id(Number(alternativeQuantity2));
    };

    const onAge2Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const age2 = event.target.value;
        setAge2Id(Number(age2));
    };

    const onSize2Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const size2 = event.target.value;
        setSize2Id(Number(size2));
    };

    const onSeedQuality2Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const seedQuality2 = event.target.value;
        setSeedQuality2Id(Number(seedQuality2));
    };

    const onRootAndGraft2Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const rootsAndGrafts2 = event.target.value;
        setRootsAndGrafts2Id(Number(rootsAndGrafts2));
    };

    const onOtherQualities2SelectionChange = (selectedOtherQualities2: {[key: string]: string}) => {
        setSelectedOtherQualities2(selectedOtherQualities2);
    };

    const onOtherQualities2Change = (otherQualities2: string) => {
        setOtherQualities2(otherQualities2);
    };

    const onPhytoSanitaryCertificate2Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const phytoSanitaryCertificate2 = event.target.value;
        setPhytoSanitaryCertificate2Id(Number(phytoSanitaryCertificate2));
    };

    const onTestedPests2SelectionChange = (selectedTestedPests2: {[key: string]: string}) => {
        setSelectedTestedPests2(selectedTestedPests2);
    };

    const onTestedPests2Change = (testedPests2: string) => {
        setTestedPests2(testedPests2);
    };

    const onOthers2SelectionChange = (selectedOthers2: {[key: string]: string}) => {
        setSelectedOthers2(selectedOthers2);
    };

    const onOthers2Change = (others2: string) => {
        setOthers2(others2);
    };

    const getQuantityText = (quantity: string) => {
        let quantityText = '';
        if (Number(quantity) > 0) {
            quantityText = quantity;
        }

        return quantityText;
    };

    const getUnitText = (unitId: number) => {
        let unitText = '';
        if (unitId > 0) {
            const unit = units.filter(unit => unit.id === unitId)[0].value;
            unitText = ' ' + unit;
        }
        return unitText;
    };

    const getTypeText = (typeId: number) => {
        let typeText = '';
        if (typeId > 0) {
            const type = types.filter(type => type.id === typeId)[0].value;
            typeText = ' ' + type;
        }
        return typeText;
    };

    const getAlternativeQuantityText = (alternativeQuantityId: number) => {
        let alternativeQuantityText = '';
        if (alternativeQuantityId > 0) {
            const alternativeQuantity = alternativeQuantities.filter(
                alternativeQuantity => alternativeQuantity.id === alternativeQuantityId
            )[0].value;
            alternativeQuantityText = ' ' + alternativeQuantity;
        }
        return alternativeQuantityText;
    };

    const getAgeText = (ageId: number) => {
        let ageText = '';
        if (ageId > 0) {
            const age = ages.filter(age => age.id === ageId)[0].value;
            ageText = '\r\n' + '- ' + age;
        }

        return ageText;
    };

    const getSizeText = (sizeId: number) => {
        let sizeText = '';
        if (sizeId > 0) {
            const size = sizes.filter(size => size.id === sizeId)[0].value;
            sizeText = '\r\n' + '- ' + size;
        }

        return sizeText;
    };

    const getSeedQualityText = (seedQualityId: number) => {
        let seedQualityText = '';
        if (seedQualityId > 0) {
            const seedQuality = seedQualities.filter(seedQuality => seedQuality.id === seedQualityId)[0].value;
            seedQualityText = '\r\n' + '- ' + seedQuality;
        }

        return seedQualityText;
    };

    const getRootsAndGraftsText = (rootsAndGraftsId: number) => {
        let rootsAndGraftsText = '';
        if (rootsAndGraftsId > 0) {
            const rootsAndGraftsValue = rootsAndGrafts.filter(rootAndGraft => rootAndGraft.id === rootsAndGraftsId)[0]
                .value;
            rootsAndGraftsText = '\r\n' + '- ' + rootsAndGraftsValue;
        }

        return rootsAndGraftsText;
    };

    const getOtherQualitiesText = (otherQualities: Array<string>) => {
        let otherQualitiesText = '';
        if (otherQualities.length > 0) {
            for (const otherQuality of otherQualities) {
                otherQualitiesText += '\r\n' + '- ' + otherQuality;
            }
        }

        return otherQualitiesText;
    };

    const getPhytoSanitaryCertificateText = (phytoSanitaryCertificateId: number) => {
        let phytoSanitaryCertificateText = '';
        if (phytoSanitaryCertificateId > 0) {
            const phytoSanitaryCertificate = phytoSanitaryCertificates.filter(
                phytoSanitaryCertificate => phytoSanitaryCertificate.id === phytoSanitaryCertificateId
            )[0].value;
            phytoSanitaryCertificateText = '\r\n\r\n' + phytoSanitaryCertificate;
        }

        return phytoSanitaryCertificateText;
    };

    const getTestedPestsText = (testedPests: Array<string>) => {
        let testedPestsText = '';
        if (testedPests.length > 0) {
            for (const testedPest of testedPests) {
                testedPestsText += '\r\n' + '- ' + testedPest;
            }
        }

        return testedPestsText;
    };

    const getOthersText = (others: Array<string>) => {
        let othersText = '';
        if (others.length > 0) {
            for (const other of others) {
                othersText += '\r\n\r\n' + other;
            }
        }

        return othersText;
    };

    const getAndOrText = () => {
        let andOrText = '';
        if (parseInt(`${andOrId}`) > 0) {
            const andOr = andOrList.filter(andOr => andOr.id === andOrId)[0].value;
            andOrText = '\r\n\r\n' + andOr + '\r\n\r\n';
        }

        return andOrText;
    };

    const getConcatQuantityQuality = () => {
        return (
            getQuantityText(quantity1) +
            getUnitText(unit1Id) +
            getTypeText(type1Id) +
            getAlternativeQuantityText(alternativeQuantity1Id) +
            getAgeText(age1Id) +
            getSizeText(size1Id) +
            getRootsAndGraftsText(rootsAndGrafts1Id) +
            getSeedQualityText(seedQuality1Id) +
            getOtherQualitiesText(Object.values(selectedOtherQualities1)) +
            getPhytoSanitaryCertificateText(phytoSanitaryCertificate1Id) +
            getTestedPestsText(Object.values(selectedTestedPests1)) +
            getOthersText(Object.values(selectedOthers1)) +
            getAndOrText() +
            getQuantityText(quantity2) +
            getUnitText(unit2Id) +
            getTypeText(type2Id) +
            getAlternativeQuantityText(alternativeQuantity2Id) +
            getAgeText(age2Id) +
            getSizeText(size2Id) +
            getRootsAndGraftsText(rootsAndGrafts2Id) +
            getSeedQualityText(seedQuality2Id) +
            getOtherQualitiesText(Object.values(selectedOtherQualities2)) +
            getPhytoSanitaryCertificateText(phytoSanitaryCertificate2Id) +
            getTestedPestsText(Object.values(selectedTestedPests2)) +
            getOthersText(Object.values(selectedOthers2))
        );
    };

    const getConcatQuantityQualityIds = () => {
        return (
            quantity1 +
            '|' +
            (unit1Id > 0 ? unit1Id + '|' : '|') +
            (type1Id > 0 ? type1Id + '|' : '|') +
            (alternativeQuantity1Id > 0 ? alternativeQuantity1Id + '|' : '|') +
            (age1Id > 0 ? age1Id + '|' : '|') +
            (size1Id > 0 ? size1Id + '|' : '|') +
            (seedQuality1Id > 0 ? seedQuality1Id + '|' : '|') +
            (rootsAndGrafts1Id > 0 ? rootsAndGrafts1Id + '|' : '|') +
            (Object.keys(selectedOtherQualities1).length > 0
                ? Object.keys(selectedOtherQualities1).join('-') + '|'
                : '|') +
            (phytoSanitaryCertificate1Id > 0 ? phytoSanitaryCertificate1Id + '|' : '|') +
            (Object.keys(selectedTestedPests1).length > 0 ? Object.keys(selectedTestedPests1).join('-') + '|' : '|') +
            (Object.keys(selectedOthers1).length > 0 ? Object.keys(selectedOthers1).join('-') + '|' : '|') +
            (parseInt(`${andOrId}`) > 0 ? andOrId + '|' : '|') +
            (parseInt(`${andOrId}`) > 0 ? quantity2 + '|' : '|') +
            (unit2Id > 0 ? unit2Id + '|' : '|') +
            (type2Id > 0 ? type2Id + '|' : '|') +
            (alternativeQuantity2Id > 0 ? alternativeQuantity2Id + '|' : '|') +
            (age2Id > 0 ? age2Id + '|' : '|') +
            (size2Id > 0 ? size2Id + '|' : '|') +
            (seedQuality2Id > 0 ? seedQuality2Id + '|' : '|') +
            (rootsAndGrafts2Id > 0 ? rootsAndGrafts2Id + '|' : '|') +
            (Object.keys(selectedOtherQualities2).length > 0
                ? Object.keys(selectedOtherQualities2).join('-') + '|'
                : '|') +
            (phytoSanitaryCertificate2Id > 0 ? phytoSanitaryCertificate2Id + '|' : '|') +
            (Object.keys(selectedTestedPests2).length > 0 ? Object.keys(selectedTestedPests2).join('-') + '|' : '|') +
            (Object.keys(selectedOthers2).length > 0 ? Object.keys(selectedOthers2).join('-') + '|' : '|')
        );
    };

    const checkForErrors = () => {
        if (
            Number(quantity1) === 0 ||
            type1Id === 0 ||
            (parseInt(`${andOrId}`) > 0 && (Number(quantity2) === 0 || type2Id === 0))
        ) {
            setErrorMessage('Please fill the mandatory fields (*)');
            setHighlightErrors(true);
            return true;
        } else if (
            (Object.values(selectedTestedPests1).length > 0 && phytoSanitaryCertificate1Id === 0) ||
            (parseInt(`${andOrId}`) > 0 &&
                Object.values(selectedTestedPests2).length > 0 &&
                phytoSanitaryCertificate2Id === 0)
        ) {
            setErrorMessage('Please select a phytosanitary certificate');
            setHighlightErrors(true);
            return true;
        } else {
            setErrorMessage(null);
            setHighlightErrors(false);
            return false;
        }
    };

    const previewFormattedData = () => {
        const errorExist = checkForErrors();
        if (!errorExist) {
            const quantityQuality = getConcatQuantityQuality();
            setSeedPlantQuantityQuality(quantityQuality);
            const quantityQualityIds = getConcatQuantityQualityIds();
            setSeedPlantQuantityQualityIds(quantityQualityIds);
        }
    };

    const submitFormattedData = (skipErrorsCheck?: boolean) => {
        if (skipErrorsCheck) {
            const quantityQuality = getConcatQuantityQuality();
            setSeedPlantQuantityQuality(quantityQuality);
            const quantityQualityIds = getConcatQuantityQualityIds();
            setSeedPlantQuantityQualityIds(quantityQualityIds);
            props.close && props.close(true, quantityQuality, quantityQualityIds);
        } else {
            const errorExist = checkForErrors();
            if (!errorExist) {
                const quantityQuality = getConcatQuantityQuality();
                setSeedPlantQuantityQuality(quantityQuality);
                const quantityQualityIds = getConcatQuantityQualityIds();
                setSeedPlantQuantityQualityIds(quantityQualityIds);
                props.close && props.close(true, quantityQuality, quantityQualityIds);
            }
        }
    };

    return (
        <ModalCustomVersion2
            style={{visibility: props.triggerCloseAfterInit ? 'hidden' : 'visible'}}
            plus2={true}
            loading={loading}
            close={comebackToSearch}
            header={`Default Requirements`}
            body={
                <div>
                    <span>{`You are currently editing an existing plant material requirement. `}</span>
                    <div style={{clear: 'both'}}>{}</div>
                    <span>{`Note: Mandatory fields are marked with an asterisk (*)`}</span>
                    <div style={{clear: 'both'}}>{}</div>
                    <Empty height={5} />
                    <Empty height={5} />
                    <div style={{clear: 'both'}}>{}</div>
                    <TextAreaInput
                        outsideLabel={`Seed / Plant quantity and quality as seen by applicant`}
                        outsideLabelWidth={120}
                        value={seedPlantQuantityQuality}
                        double={true}
                        disabled={true}
                        rows={10}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <TextInput
                        label={getMandatoryAsterisk('Quantity')}
                        value={quantity1}
                        onChange={onQuantity1Change}
                        type={'number'}
                        width={100}
                    />
                    <SelectInput
                        label={'Unit'}
                        value={unit1Id}
                        onChange={onUnit1Change}
                        list={units}
                        notAll={true}
                        width={100}
                    />
                    <SelectInput
                        label={'Type'}
                        value={type1Id}
                        onChange={onType1Change}
                        list={types}
                        notAll={true}
                        width={317}
                        mandatory={true}
                    />
                    <div className={`alternativeQuantity`}>
                        <SelectInput
                            label={'Alternative Quantity'}
                            value={alternativeQuantity1Id}
                            onChange={onAlternativeQuantity1Change}
                            list={alternativeQuantities}
                            notAll={true}
                            width={141}
                        />
                    </div>
                    <div style={{clear: 'both'}}>{}</div>
                    <SelectInput
                        value={age1Id}
                        onChange={onAge1Change}
                        double={true}
                        list={ages}
                        outsideLabel={'Age'}
                        outsideLabelWidth={120}
                        notAll={true}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <SelectInput
                        value={size1Id}
                        onChange={onSize1Change}
                        double={true}
                        list={sizes}
                        outsideLabel={'Size'}
                        outsideLabelWidth={120}
                        notAll={true}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <SelectInput
                        value={seedQuality1Id}
                        onChange={onSeedQuality1Change}
                        double={true}
                        list={seedQualities}
                        outsideLabel={'Seed Quality'}
                        outsideLabelWidth={120}
                        notAll={true}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <SelectInput
                        value={rootsAndGrafts1Id}
                        onChange={onRootAndGraft1Change}
                        double={true}
                        list={rootsAndGrafts}
                        outsideLabel={'Roots and Grafts'}
                        outsideLabelWidth={120}
                        notAll={true}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <TextLabelInput
                        double={true}
                        placeholder={'please use autocompletion'}
                        onSelectionChange={onOtherQualities1SelectionChange}
                        onChange={onOtherQualities1Change}
                        value={otherQualities1}
                        selectedElements={selectedOtherQualities1}
                        delay={300}
                        outsideLabel={`Other Qualities`}
                        outsideLabelWidth={120}
                        listByIdName={otherQualities1ListByIdName}
                        nameHash={otherQualities1NameHash}
                        noInfo={true}
                        useAsMultipleSelection={true}
                        isOnModal={true}
                        listMaxHeight={370}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <SelectInput
                        value={phytoSanitaryCertificate1Id}
                        onChange={onPhytoSanitaryCertificate1Change}
                        double={true}
                        list={phytoSanitaryCertificates}
                        outsideLabel={'Phytosanitary Certificate'}
                        outsideLabelWidth={120}
                        notAll={true}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <TextLabelInput
                        double={true}
                        placeholder={'please use autocompletion'}
                        onSelectionChange={onTestedPests1SelectionChange}
                        onChange={onTestedPests1Change}
                        value={testedPests1}
                        selectedElements={selectedTestedPests1}
                        delay={300}
                        outsideLabel={`Tested Pests`}
                        outsideLabelWidth={120}
                        listByIdName={testedPests1ListByIdName}
                        nameHash={testedPests1NameHash}
                        noInfo={true}
                        useAsMultipleSelection={true}
                        isOnModal={true}
                        listMaxHeight={370}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <TextLabelInput
                        double={true}
                        placeholder={'please use autocompletion'}
                        onSelectionChange={onOthers1SelectionChange}
                        onChange={onOthers1Change}
                        value={others1}
                        selectedElements={selectedOthers1}
                        delay={300}
                        outsideLabel={`Others`}
                        outsideLabelWidth={120}
                        listByIdName={others1ListByIdName}
                        nameHash={others1NameHash}
                        noInfo={true}
                        useAsMultipleSelection={true}
                        isOnModal={true}
                        listMaxHeight={370}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    <SelectInput
                        label={'And / Or'}
                        value={andOrId}
                        onChange={onAndOrChange}
                        list={andOrList}
                        notAll={true}
                        width={100}
                    />
                    <div style={{clear: 'both'}}>{}</div>
                    {parseInt(`${andOrId}`) > 0 && (
                        <div className={'secondPlantMaterialRequirements'}>
                            <Empty height={5} />
                            <Empty height={5} />
                            <div style={{clear: 'both'}}>{}</div>
                            <TextInput
                                label={getMandatoryAsterisk('Quantity')}
                                value={quantity2}
                                onChange={onQuantity2Change}
                                type={'number'}
                                width={100}
                                mandatory={undefined}
                                outsideLabel={undefined}
                                outsideLabelWidth={undefined}
                                double={undefined}
                                placeholder={undefined}
                                disabled={undefined}
                                onEnter={undefined}
                                infoAction={undefined}
                                buttonIcon={undefined}
                                buttonAction={undefined}
                                buttonColor={undefined}
                                popOverText={undefined}
                                focus={undefined}
                                background={undefined}
                                error={undefined}
                                maxWidth={undefined}
                                // eslint-disable-next-line react/no-children-prop
                                children={undefined}
                                currentFilter={undefined}
                                onFilterChange={undefined}
                                filter={undefined}
                                remark={undefined}
                                oldBackOfficeStyles={undefined}
                                onBlur={undefined}
                                triple={undefined}
                                noIcon={undefined}
                                outsideLabelWithHTML={undefined}
                                oldBackOfficeStylesError={undefined}
                                twoInputsPerLine={undefined}
                                inputIsHidden={undefined}
                                isRemarkTranslationMissing={undefined}
                                isTranslationMissing={undefined}
                                PDFVersion={undefined}
                                autoCompleteDisabled={undefined}
                            />
                            <SelectInput
                                label={'Unit'}
                                value={unit2Id}
                                onChange={onUnit2Change}
                                list={units}
                                notAll={true}
                                width={100}
                            />
                            <SelectInput
                                label={'Type'}
                                value={type2Id}
                                onChange={onType2Change}
                                list={types}
                                notAll={true}
                                width={317}
                                mandatory={true}
                            />
                            <div className={`alternativeQuantity`}>
                                <SelectInput
                                    label={'Alternative Quantity'}
                                    value={alternativeQuantity2Id}
                                    onChange={onAlternativeQuantity2Change}
                                    list={alternativeQuantities}
                                    notAll={true}
                                    width={141}
                                />
                            </div>
                            <div style={{clear: 'both'}}>{}</div>
                            <SelectInput
                                value={age2Id}
                                onChange={onAge2Change}
                                double={true}
                                list={ages}
                                outsideLabel={'Age'}
                                outsideLabelWidth={120}
                                notAll={true}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <SelectInput
                                value={size2Id}
                                onChange={onSize2Change}
                                double={true}
                                list={sizes}
                                outsideLabel={'Size'}
                                outsideLabelWidth={120}
                                notAll={true}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <SelectInput
                                value={seedQuality2Id}
                                onChange={onSeedQuality2Change}
                                double={true}
                                list={seedQualities}
                                outsideLabel={'Seed Quality'}
                                outsideLabelWidth={120}
                                notAll={true}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <SelectInput
                                value={rootsAndGrafts2Id}
                                onChange={onRootAndGraft2Change}
                                double={true}
                                list={rootsAndGrafts}
                                outsideLabel={'Roots and Grafts'}
                                outsideLabelWidth={120}
                                notAll={true}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <TextLabelInput
                                double={true}
                                placeholder={'please use autocompletion'}
                                onSelectionChange={onOtherQualities2SelectionChange}
                                onChange={onOtherQualities2Change}
                                value={otherQualities2}
                                selectedElements={selectedOtherQualities2}
                                delay={300}
                                outsideLabel={`Other Qualities`}
                                outsideLabelWidth={120}
                                listByIdName={otherQualities2ListByIdName}
                                nameHash={otherQualities2NameHash}
                                noInfo={true}
                                useAsMultipleSelection={true}
                                isOnModal={true}
                                listMaxHeight={370}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <SelectInput
                                value={phytoSanitaryCertificate2Id}
                                onChange={onPhytoSanitaryCertificate2Change}
                                double={true}
                                list={phytoSanitaryCertificates}
                                outsideLabel={'Phytosanitary Certificate'}
                                outsideLabelWidth={120}
                                notAll={true}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <TextLabelInput
                                double={true}
                                placeholder={'please use autocompletion'}
                                onSelectionChange={onTestedPests2SelectionChange}
                                onChange={onTestedPests2Change}
                                value={testedPests2}
                                selectedElements={selectedTestedPests2}
                                delay={300}
                                outsideLabel={`Tested Pests`}
                                outsideLabelWidth={120}
                                listByIdName={testedPests2ListByIdName}
                                nameHash={testedPests2NameHash}
                                noInfo={true}
                                useAsMultipleSelection={true}
                                isOnModal={true}
                                listMaxHeight={370}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <TextLabelInput
                                double={true}
                                placeholder={'please use autocompletion'}
                                onSelectionChange={onOthers2SelectionChange}
                                onChange={onOthers2Change}
                                value={others2}
                                selectedElements={selectedOthers2}
                                delay={300}
                                outsideLabel={`Others`}
                                outsideLabelWidth={120}
                                listByIdName={others2ListByIdName}
                                nameHash={others2NameHash}
                                noInfo={true}
                                useAsMultipleSelection={true}
                                isOnModal={true}
                                listMaxHeight={370}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                        </div>
                    )}
                </div>
            }
            footer={
                <>
                    {highlightErrors ? (
                        <Error>
                            <FontAwesomeIcon icon={faExclamationCircle as any} color={'white'} />
                            {` ${errorMessage}`}
                        </Error>
                    ) : null}
                    <Button clickAction={previewFormattedData} variation={'secondary'}>
                        {`Preview Formatted Data`}
                    </Button>
                    <Button clickAction={submitFormattedData}>{`OK`}</Button>
                </>
            }
        >
            {errorMessageModal && (
                <ModalErrorVersion2 title={'Error'} message={errorMessageModal} close={closeErrorModal} />
            )}
        </ModalCustomVersion2>
    );
};

export default injectIntl(withRouter(ModalDefaultRequirements));
