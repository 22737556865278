import {API_SERVERLESS, getFetch, getFile, postFetch} from '~commonApi';
import {loadUrlParams} from '~utils';
import {IDocumentSignApplication} from '../../types';

export function apiOnlineSignApplicationDetails({applicationRequestId}: {applicationRequestId: string}): Promise<{
    admins: string[];
    applicationRequestCode: string;
    applicationRequestSpeciesInput: string;
    authorizedToSign: boolean;
    documents: IDocumentSignApplication[];
    officeId: number;
    timestampAF: string;
    timestampTQ: string;
}> {
    return getFetch(
        `${API_SERVERLESS}/online/v3/applicationRequestSignApplicationDetails?applicationRequestId=${applicationRequestId}`,
        true
    );
}

export function apiOnlineSignApplicationRequest({
    applicationRequestId,
    timestampAF,
    timestampTQ,
}: {
    applicationRequestId: string;
    timestampAF: string;
    timestampTQ: string;
}) {
    let URLParams = loadUrlParams();
    let URLSearchQuery = '';
    URLParams && URLParams.mockErrorType && (URLSearchQuery = `?mockErrorType=${URLParams.mockErrorType}`);
    return postFetch(`${API_SERVERLESS}/online/v3/applicationRequestSignApplication${URLSearchQuery}`, true, {
        applicationRequestId,
        timestampAF,
        timestampTQ,
    });
}

export function apiApplicationRequestAttachmentDownload({
    filename,
    applicationRequestCode,
}: {
    filename: string;
    applicationRequestCode: string;
}): Promise<{
    signedUrl: string;
}> {
    return getFetch(
        `${API_SERVERLESS}/online/v3/attachmentDownload/${applicationRequestCode}/${encodeURIComponent(filename)}`,
        true
    );
}

export function apiCommonGetPDF({
    URL,
    applicationNumber,
    species,
}: {
    URL: string;
    applicationNumber: string;
    species: string;
}) {
    return getFile(
        `${API_SERVERLESS}/common/v3/getPDF?url=${encodeURIComponent(
            URL
        )}&applicationNumber=${applicationNumber}&species=${encodeURIComponent(species)}&pageNumbers=1`,
        true
    );
}
