import {API_SERVERLESS, postFetch} from '../../../../../../commonApi';

export function apiUploadImageFile(jsonData: {image: Blob; thumbnail: Blob; eoId: number}): Promise<void> {
    const requestOption = {method: 'PUT', headers: {'Content-Type': 'application/octet-stream'}};

    return postFetch(`${API_SERVERLESS}/tlo/applications/documents/images/upload`, true, {
        eoId: jsonData.eoId,
    }).then((jsonResponse: {data: {fileUrl: string; thumbnailUrl: string}}) => {
        fetch(jsonResponse.data.fileUrl, {...requestOption, body: jsonData.image});
        fetch(jsonResponse.data.thumbnailUrl, {...requestOption, body: jsonData.thumbnail});
    });
}
