import React from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {apiVarietyFinderAcceptTerms} from './VFTermsOfAPIUseService';
import {
    Footer,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import NavigationVarietyFinder from '../../shared/NavigationVarietyFinder';
import {ModalAlertVersion2} from '../../commonModals';
import {Button} from '../../componentsFormV2';
import {trackPageView} from '../../utils';

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    loading: number;
    isModalAlertTermsAcceptedSuccessOpen: boolean;
}

class VFTermsOfAPIUse extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: 0,
            isModalAlertTermsAcceptedSuccessOpen: false,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'varietyFinderTermsOfAPIUse'});
        window.scrollTo(0, 0);
    }

    acceptTermsAndConditions = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiVarietyFinderAcceptTerms().then(JSONResponse => {
                    if (JSONResponse && JSONResponse.accepted) {
                        this.setState(prev => ({
                            loading: prev.loading - 1,
                            isModalAlertTermsAcceptedSuccessOpen: true,
                        }));
                    }
                });
            }
        );

    closeModalAlertTermsAcceptedSuccess = () => this.props.history.push('/welcome');

    render() {
        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                {this.state.isModalAlertTermsAcceptedSuccessOpen ? (
                    <ModalAlertVersion2
                        title={'Terms accepted'}
                        message={'You have successfully accepted the terms of API use.'}
                        close={this.closeModalAlertTermsAcceptedSuccess}
                    />
                ) : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={'Variety Finder Terms of API Use'} hostApplication={'Variety Finder'} />
                <NavigationVarietyFinder />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                borderBottom: '2px solid #ccc',
                                paddingBottom: 20,
                            }}
                        >
                            <Button
                                clickAction={this.acceptTermsAndConditions}
                                icon={'arrowRight'}
                            >{`I accept the terms of API use`}</Button>
                        </div>
                        <div style={{clear: 'both'}} />
                        <iframe
                            src={'assets/TC/Terms_of_use_API_Variety_Finder.pdf'}
                            style={{
                                width: '100%',
                                padding: 0,
                                margin: 0,
                                border: 0,
                                height: 1200,
                                marginTop: 50,
                            }}
                        />
                    </FormWrapper>
                </MainWrapper>
                <Footer hostApplication={'Variety Finder'} />
            </>
        );
    }
}

export default withRouter(injectIntl(VFTermsOfAPIUse));
