import React from 'react';
import styles from './Logo.module.scss';

export default function Logo() {
    return (
        <div style={{textAlign: 'center', width: '100%'}}>
            <div className={styles.CPVOIcon} />
        </div>
    );
}
