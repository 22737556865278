import {API_SERVERLESS, getFetch} from '../../../../commonApi';

export interface MembersList {
    address: string;
    civility: string;
    countryId: string;
    fax: string;
    forename: string;
    function: string;
    mail: string;
    memberId: number;
    name: string;
    phone: string;
    status: string;
    surname: string;
}

export interface MembersListFetchData {
    data: {
        membersList: Array<MembersList>;
        token: string;
    };
}

export function apiMembersListFetch(): Promise<MembersListFetchData> {
    return getFetch(`${API_SERVERLESS}/acarea/memberslist`, true);
}
