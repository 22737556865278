import React from 'react';
import cx from 'classnames';
import styles from './CustomTableModalFieldChooser.module.scss';
import {ModalCustomVersion2} from '../commonModals';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {Button} from '../componentsFormV2';

export default class CustomTableModalFieldChooser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeFields: {},
        };
    }

    componentDidMount() {
        const activeFields = {};
        this.props.currentFields.map(fieldId => {
            activeFields[fieldId] = true;
        });
        this.setState({activeFields});
    }

    close = () => {
        this.props.close();
    };

    addOrRemoveField = fieldId => {
        this.setState(prev => {
            const activeFields = prev.activeFields;
            if (activeFields[fieldId]) {
                delete activeFields[fieldId];
            } else {
                activeFields[fieldId] = true;
            }
            return {activeFields};
        });
    };

    applyChanges = () => {
        this.props.updateFields(this.props.resultFieldsAll.filter(i => this.state.activeFields[i]));
    };

    resetToDefault = () => {
        const activeFields = {};
        this.props.resultFieldsDefault.map(fieldId => {
            activeFields[fieldId] = true;
        });
        this.setState({activeFields});
    };

    render() {
        return (
            <ModalCustomVersion2
                close={this.props.close}
                header={`Field chooser`}
                body={
                    <div>
                        <div className="row">
                            <ul className={cx('fields-list', 'text-center', styles.fieldsList)}>
                                {this.props.resultFieldsAll.map(fieldId => {
                                    const buttonName = this.props.intl.formatMessage({
                                        id: `table.${this.props.tableName}.${fieldId}`,
                                    });
                                    return (
                                        <li
                                            key={fieldId}
                                            data-ng-repeat="column in columns"
                                            className={cx(
                                                'col-md-4',
                                                'col-sm-4',
                                                'col-xs-4',
                                                'ng-scope',
                                                styles.fieldsListLi
                                            )}
                                            onClick={() => this.addOrRemoveField(fieldId)}
                                        >
                                            <span
                                                title={buttonName}
                                                data-cy={!!this.state.activeFields[fieldId]}
                                                className={cx(
                                                    'ng-binding',
                                                    styles.fieldsListSpan,
                                                    this.state.activeFields[fieldId] ? styles.fieldActive : null
                                                )}
                                            >
                                                {this.state.activeFields[fieldId] ? (
                                                    <FontAwesomeIcon icon={faCheck} />
                                                ) : null}
                                                {` ${buttonName}`}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                }
                footer={
                    <div className={styles.footerWrap}>
                        <div className={styles.footerLeft}>
                            <Button
                                clickAction={this.resetToDefault}
                                variation={'secondary'}
                            >{`Reset to default settings`}</Button>
                        </div>
                        <div className={styles.footerRight}>
                            <Button icon={'close'} clickAction={this.close} variation={'danger'}>{`Close`}</Button>
                            <Button
                                icon={'arrowRight'}
                                clickAction={this.applyChanges}
                                disabled={Object.keys(this.state.activeFields || {}).length === 0}
                            >{`Apply`}</Button>
                        </div>
                    </div>
                }
            />
        );
    }
}
