import React from 'react';
import {injectIntl} from 'react-intl';
import styles from './BackOfficeFormQuestions.module.scss';
import FormWithBackground, {backgrounds} from '~shared/FormWithBackground';
import {
    apiBackOfficeGenerateJSONMapping,
    apiBackOfficeGetForm,
    apiBackOfficeRemoveQuestion,
    apiBackOfficeRemoveResponse,
    apiFormDatas,
} from './BackOfficeFormQuestionsService';
import BackOfficeFormTabs, {TAB} from '~shared/BackOfficeFormTabs';
import WizardSteps from '../Wizard/WizardSteps';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import Checkbox from '~components/Checkbox';
import {faArrowLeft, faCode, faPencilAlt, faPlus, faPrint, faSave} from '@fortawesome/free-solid-svg-icons';
import {faEye} from '@fortawesome/free-regular-svg-icons';
import {ModalAlertVersion2, ModalConfirmVersion2} from '../../commonModals';
import ModalJSONMapping from './modals/ModalJSONMapping/ModalJSONMapping';
import GroupModalCreate from '../Wizard/modals/GroupModalCreate';
import QuestionModalDetails from '../Wizard/modals/QuestionModalDetails';
import QuestionModalSearchAndAdd from '../Wizard/modals/QuestionModalSearchAndAdd';
import QuestionModalUseTemplate from '../Wizard/modals/QuestionModalUseTemplate';
import {
    getContentWithCorrectImagePath,
    getDecodedJWT,
    isAdminUser,
    isUserOfficeCPVO,
    joinParams,
    loadUrlParams,
} from '~utils';
import {
    getGroupOrTemplateKeyForIterationMap,
    getTableQuestionsKeysIterationsMap,
    getValidatedSteps,
} from '../Wizard/utils';
import WizardWrapper from '../Wizard/WizardWrapper';
import {IntlContext} from '../../context/IntlContext';
import {Footer, HeaderLoading, HeaderLogoMenu, HeaderTitleAndVersion, MainWrapper} from '../../componentsLayout';
import NavigationBackOffice from '../../shared/NavigationBackOffice';
import {trackPageView, utilGetUserOfficeIdFromThirdPartyId} from '../../utils';

export const FORM_QUESTIONS_MODAL_SCREEN = {
    SHOW_JSON_MAPPING: 'SHOW_JSON_MAPPING',
    SUCCESS_GENERATE_JSON_MAPPING: 'SUCCESS_GENERATE_JSON_MAPPING',
    CONFIRM_GENERATE_JSON_MAPPING: 'CONFIRM_GENERATE_JSON_MAPPING',
    SEARCH_AND_ADD_QUESTION: 'SEARCH_AND_ADD_QUESTION',
    CREATE_QUESTION: 'CREATE_QUESTION',
    USE_A_TEMPLATE: 'USE_A_TEMPLATE',
    CREATE_GROUP: 'CREATE_GROUP',
};

const STATUS = {
    IN_PREPARATION: 1,
    ACTIVE: 2,
    TERMINATED: 3,
};
export const MOCK = {
    EDIT_GROUP: 'EDIT_GROUP',
    EDIT_QUESTION: 'EDIT_QUESTION',
    EDIT_RESPONSE: 'EDIT_RESPONSE',
};

const ALLOWED_USER_IDS_TO_GENERATE_JSON_MAPPING = ['72487', '174878', '88031', '72657'];

class BackOfficeTemplateQuestions extends WizardWrapper {
    static contextType = IntlContext;
    stepGroupCallables = null;
    backOfficeNotAuthorised = true;
    userOfficeId = null;

    constructor(props) {
        super(props);
        this.URLParams = loadUrlParams();
        this.isUserAdministrator = isAdminUser();
        this.isUserAllowedToGenerateJSONMapping = false;
        this.groupsQuestionDrafts = {};
        let decoded = getDecodedJWT();
        this.userOfficeId = utilGetUserOfficeIdFromThirdPartyId(decoded && decoded.thirdPartyId);
        if (decoded) {
            this.backOfficeNotAuthorised =
                (decoded.roles || []).indexOf('OABAC') === -1 && (decoded.roles || []).indexOf('OABAD') === -1;
            if (this.backOfficeNotAuthorised) {
                const {viewFormId, viewFormType, viewFormLang} = this.URLParams || {};
                props.history.replace(
                    `/backOfficeFormQuestions?viewFormId=${viewFormId}&viewFormType=${viewFormType}${
                        viewFormLang ? `&viewFormLang=${viewFormLang}` : ''
                    }`
                );
            }
            this.isUserAllowedToGenerateJSONMapping = ALLOWED_USER_IDS_TO_GENERATE_JSON_MAPPING.includes(decoded.id);
        }
        //
        this.state = {
            ...this.state,
            formValidityBegin: null,
            mock: null,
            loading: 0,
            jsonMapping: null,
            modalScreen: null,
            editable: (this.URLParams && this.URLParams.edit === 'true') || false,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'backOfficeFormQuestions'});
        self.setStateFunction = this.setStateFunction;
        self.defaultState = this.state;
        this.loadJSONs();
        this.setState({
            screenData: {
                ...this.state.screenData,
                hasClickedOnSaveAndValidate: this.URLParams && this.URLParams.edit !== 'true',
            },
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.screenData?.hasClickedOnSaveAndValidate &&
            this.state.screenData?.hash !== prevState.screenData?.hash
        ) {
            this.setState(prevState => ({
                validatedSteps: getValidatedSteps({
                    groupsQuestion: prevState.groupsQuestion,
                    groupsIdsIterationsMap: prevState.screenData.groupsIdsIterationsMap,
                    tableQuestionsKeysIterationsMap: prevState.screenData.tableQuestionsKeysIterationsMap,
                    responsesClient: prevState.screenData.responsesClient,
                }),
            }));
        }
    }

    componentWillUnmount() {
        if (!window.location.href.includes('backOfficeFormQuestions')) {
            this.context.switchLanguage('en');
        }
    }

    // todo review
    onGoBackToSearch = () => {
        const params = Object.assign({}, this.URLParams);
        delete params.viewFormId;
        delete params.viewFormType;
        delete params.viewFormLang;
        delete params.edit;
        this.props.history.push(`/forms${(params && `?${joinParams(params)}`) || ''}`);
        window.scrollTo(0, 0);
    };

    setStateFunction = state => {
        self.singletonModal = false;
        this.setState(state);
    };
    setStepGroupCallables = callables => {
        this.stepGroupCallables = callables;
    };

    loadJSONs = (mockFormType = null, callBackAfterLoadForm, paramForCallback, keepCurrentGroup, forceCurrentGroup) => {
        const {viewFormId, viewFormType, viewFormLang} = loadUrlParams();
        viewFormId &&
            this.setState(
                prev => ({loading: ++prev.loading}),
                () => {
                    Promise.all([
                        apiBackOfficeGetForm(viewFormId, viewFormType, viewFormLang, mockFormType),
                        apiFormDatas(viewFormId, viewFormLang),
                    ])
                        .then(jsonResponses => {
                            const [jsonResponseForm, jsonResponseDatas] = jsonResponses;
                            if (jsonResponseDatas) {
                                this.setState({assistedEntries: jsonResponseDatas.datas});
                            }
                            const {form} = jsonResponseForm;
                            const {
                                iteration,
                                questionnaireLanguage,
                                groupsQuestion,
                                status,
                                id,
                                name,
                                jsonMapping,
                                office,
                                header,
                                footer,
                                validityBegin,
                            } = form || {};
                            let groupsQuestionWithDrafts = [...groupsQuestion];
                            Object.keys(this.groupsQuestionDrafts)
                                .filter(key => groupsQuestion.map(el => el.id).indexOf(parseInt(key)) === -1)
                                .forEach(key => groupsQuestionWithDrafts.push(this.groupsQuestionDrafts[key]));
                            let partialState = {};
                            if (form && groupsQuestion) {
                                partialState = Object.assign(partialState, {
                                    groupsQuestion: groupsQuestionWithDrafts,
                                    currentStepNumber: this.computeCurrentStepNumber({
                                        groupsQuestion: groupsQuestionWithDrafts,
                                        keepCurrentGroup,
                                        forceCurrentGroup,
                                    }),
                                });
                            }
                            if (form) {
                                status && Object.assign(partialState, {status});
                                id && Object.assign(partialState, {formId: id});
                                name && Object.assign(partialState, {name: name.value});
                                office && Object.assign(partialState, {formOrTemplateOfficeId: office});
                                header && Object.assign(partialState, {header});
                                footer && Object.assign(partialState, {footer});
                                validityBegin && Object.assign(partialState, {formValidityBegin: validityBegin});
                            }
                            iteration && Object.assign(partialState, {iteration});
                            jsonMapping && Object.assign(partialState, {jsonMapping});
                            questionnaireLanguage &&
                                Object.assign(partialState, {
                                    questionnaireLanguage,
                                });
                            let documents = null;
                            if (form && form.documents) {
                                documents = form.documents;
                            }
                            let tableQuestionsKeysIterationsMap = getTableQuestionsKeysIterationsMap(
                                partialState.groupsQuestion
                            );
                            const groupsIdsIterationsMap = {};
                            (partialState.groupsQuestion || [])
                                .filter(el => el.isGroup)
                                .forEach(el => {
                                    groupsIdsIterationsMap[getGroupOrTemplateKeyForIterationMap(el)] = 1;
                                });
                            this.updateScreenData({
                                tableQuestionsKeysIterationsMap,
                                groupsIdsIterationsMap,
                                hash: Date.now(),
                                responsesClient: {},
                            });
                            this.setState(partialState, () => {
                                if (documents) {
                                    this.updateScreenData({documents});
                                }
                            });
                        })
                        .catch(error => {
                            ERROR`BackOfficeFormQuestions: ${error.message}`;
                        })
                        .then(() => this.setState(prev => ({loading: --prev.loading})))
                        .then(() => {
                            if (callBackAfterLoadForm) {
                                callBackAfterLoadForm(paramForCallback ? paramForCallback : null);
                            }
                        });
                }
            );
    };

    computeCurrentStepNumber = ({groupsQuestion, keepCurrentGroup, forceCurrentGroup}) => {
        if (forceCurrentGroup) {
            if (forceCurrentGroup.templateId) {
                let nextStepIndex = (groupsQuestion || []).findIndex(
                    el => el.templateId === forceCurrentGroup.templateId
                );
                return nextStepIndex > -1 ? nextStepIndex : 0;
            }
            if (forceCurrentGroup.groupId) {
                let nextStepIndex = (groupsQuestion || []).findIndex(el => el.id === forceCurrentGroup.groupId);
                return nextStepIndex > -1 ? nextStepIndex : 0;
            }
        }
        if (keepCurrentGroup) {
            return this.state.currentStepNumber;
        }
        let nextStepIndex = (groupsQuestion || []).findIndex(el => el.isGroup === true);
        return nextStepIndex > -1 ? nextStepIndex : 0;
    };

    removeQuestion = questionId => {
        const {formId} = this.state;
        const group = this.getCurrentStep();
        const {templateId} = group || {};
        const jsonBody = {
            formId,
            questionId,
            templateId,
        };
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                apiBackOfficeRemoveQuestion(jsonBody)
                    .then(jsonResponse => {
                        jsonResponse &&
                            jsonResponse.data === 'OK' &&
                            this.loadJSONs(null, this.reduceLoading, null, true);
                    })
                    .catch(error => LOG`ERROR ${error}`);
            }
        );
    };

    removeResponse = (responseId, questionId) => {
        const {formId} = this.state;
        const jsonBody = {
            formId,
            questionId,
            responseId,
        };
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                apiBackOfficeRemoveResponse(jsonBody)
                    .then(jsonResponse => {
                        this.loadJSONs(null, this.reduceLoading, null, true);
                    })
                    .catch(error => LOG`ERROR ${error}`);
            }
        );
    };

    reduceLoading = () => this.setState(prev => ({loading: --prev.loading}));

    afterCreatingNewQuestion = () => {
        this.closeModal();
        this.loadJSONs(null, null, null, true);
    };

    onSubmit = () => {
        this.setState(prevState => ({
            validatedSteps: getValidatedSteps({
                groupsQuestion: prevState.groupsQuestion,
                groupsIdsIterationsMap: prevState.screenData.groupsIdsIterationsMap,
                tableQuestionsKeysIterationsMap: prevState.screenData.tableQuestionsKeysIterationsMap,
                responsesClient: prevState.screenData.responsesClient,
            }),
        }));
        this.updateScreenData({
            hasClickedOnSaveAndValidate: !this.state.screenData.hasClickedOnSaveAndValidate,
            hash: Date.now(),
        });
    };

    setCloseHandler = handler => (this.closeHandler = handler);

    close = force => {
        if (force) {
            this.closeModal();
        } else if (this.closeHandler && this.closeHandler()) {
            this.closeModal();
        }
    };

    openModal = type => this.setState({modalScreen: type});
    closeModal = () => this.setState({modalScreen: null});

    generateJSONMapping = () =>
        this.setState(
            prev => ({loading: ++prev.loading, modalScreen: null}),
            () => {
                apiBackOfficeGenerateJSONMapping(this.state.formId)
                    .then(jsonResponse => {
                        jsonResponse &&
                            jsonResponse.data === 'OK' &&
                            this.setState(
                                prev => ({
                                    loading: --prev.loading,
                                }),
                                () => {
                                    this.loadJSONs(null, this.showModalSuccessGenerationOfJSONMapping);
                                }
                            );
                    })
                    .catch(error => {
                        LOG`ERROR ${error}` ||
                            this.setState(prev => ({
                                loading: --prev.loading,
                            }));
                    });
            }
        );

    showModalSuccessGenerationOfJSONMapping = () =>
        this.setState({
            modalScreen: FORM_QUESTIONS_MODAL_SCREEN.SUCCESS_GENERATE_JSON_MAPPING,
        });

    addGroupToGroupsQuestionDrafts = group => {
        this.groupsQuestionDrafts[group.id] = group;
        this.setState(prev => ({
            screenData: {
                ...prev.screenData,
                hash: Date.now(),
                groupsIdsIterationsMap: {
                    ...prev.screenData.groupsIdsIterationsMap,
                    [getGroupOrTemplateKeyForIterationMap(group)]: 1,
                },
            },
        }));
    };

    onToggleHiddenButton = () =>
        this.setState(prev => ({
            screenData: {
                ...prev.screenData,
                showHidden: !prev.screenData.showHidden,
                hash: Date.now(),
            },
        }));

    onButtonPrintClick = () => {
        const {viewFormId, viewFormType, viewFormLang} = loadUrlParams();
        window.open(
            `/backOfficePdf?formId=${viewFormId}&formType=${viewFormType}&formLanguage=${viewFormLang || 'EN'}`
        );
    };

    onGoToPreviewForm = () => {
        let urlParams = loadUrlParams();
        delete urlParams['edit'];
        window.location.href = `/backOfficeFormQuestions?${joinParams(urlParams)}`;
    };

    onGoToEditForm = () => {
        let urlParams = loadUrlParams();
        urlParams['edit'] = 'true';
        window.location.href = `/backOfficeFormQuestions?${joinParams(urlParams)}`;
    };

    onLanguageChange = language => {
        let urlParams = loadUrlParams();
        delete urlParams.viewFormLang;
        urlParams['viewFormLang'] = (language || 'en').toUpperCase();
        window.location.href = `/backOfficeFormQuestions?${joinParams(urlParams)}`;
    };

    render() {
        const isEditionAvailable = this.state.formOrTemplateOfficeId === this.userOfficeId;

        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                {this.state.modalScreen === FORM_QUESTIONS_MODAL_SCREEN.SHOW_JSON_MAPPING && (
                    <ModalJSONMapping formId={this.state.formId} intl={this.props.intl} close={this.closeModal} />
                )}
                {this.state.modalScreen === FORM_QUESTIONS_MODAL_SCREEN.SUCCESS_GENERATE_JSON_MAPPING && (
                    <ModalAlertVersion2
                        title={`Success`}
                        message={`JSON Mapping is now generated.`}
                        close={this.closeModal}
                    />
                )}
                {this.state.modalScreen === FORM_QUESTIONS_MODAL_SCREEN.CONFIRM_GENERATE_JSON_MAPPING ? (
                    <ModalConfirmVersion2
                        title={`Generate JSON Mapping`}
                        message={`Are you sure you want to generate JSON Mapping for this form ?`}
                        buttonName={'GENERATE'}
                        action={this.generateJSONMapping}
                        close={this.closeModal}
                    />
                ) : null}
                {this.state.status === STATUS.IN_PREPARATION && (
                    <>
                        {this.state.modalScreen === FORM_QUESTIONS_MODAL_SCREEN.SEARCH_AND_ADD_QUESTION && (
                            <QuestionModalSearchAndAdd
                                target={{typeId: '3'}}
                                close={this.closeModal}
                                formId={this.state.formId}
                                loadFormQuestionsJSONs={this.loadJSONs}
                                intl={this.props.intl}
                                steps={this.state.groupsQuestion}
                            />
                        )}
                        {this.state.modalScreen === FORM_QUESTIONS_MODAL_SCREEN.CREATE_QUESTION && (
                            <QuestionModalDetails
                                afterCreatingNewQuestion={this.afterCreatingNewQuestion}
                                close={this.closeModal}
                                formId={this.state.formId}
                                formOrTemplateOfficeId={this.state.formOrTemplateOfficeId}
                                modalQueuePop={this.modalQueuePop}
                                modalQueuePush={this.modalQueuePush}
                                questionId={0}
                                steps={this.state.groupsQuestion}
                            />
                        )}
                        {this.state.modalScreen === FORM_QUESTIONS_MODAL_SCREEN.USE_A_TEMPLATE && (
                            <QuestionModalUseTemplate
                                formId={this.state.formId}
                                loadFormQuestionsJSONs={this.loadJSONs}
                                intl={this.props.intl}
                                close={this.closeModal}
                            />
                        )}
                        {this.state.modalScreen === FORM_QUESTIONS_MODAL_SCREEN.CREATE_GROUP && (
                            <GroupModalCreate
                                close={this.close}
                                formId={this.state.formId}
                                setCloseHandler={this.setCloseHandler}
                                modalQueuePush={this.modalQueuePush}
                                modalQueuePop={this.modalQueuePop}
                                addGroupToGroupsQuestionDrafts={this.addGroupToGroupsQuestionDrafts}
                                groupsQuestion={this.state.groupsQuestion}
                                loadFormQuestionsJSONs={this.loadJSONs}
                            />
                        )}
                    </>
                )}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Web Back Office`} />
                <NavigationBackOffice activeTitle={'Forms'} />
                <MainWrapper>
                    <FormWithBackground
                        title={
                            this.URLParams && this.URLParams.viewFormType === 'AF'
                                ? `Application form`
                                : 'Technical questionnaire'
                        }
                        subTitle={
                            /* eslint-disable-next-line react/no-danger */
                            <div style={{paddingBottom: 15}} dangerouslySetInnerHTML={{__html: this.state.name}} />
                        }
                        subTitle2={''}
                        background={backgrounds.backgroundOABO}
                        languages={['nl', 'en', 'fr', 'de', 'es']}
                        onLanguageChange={this.onLanguageChange}
                    >
                        {this.state.editable ? (
                            <BackOfficeFormTabs
                                tabId={TAB.QUESTIONS}
                                loading={this.state.loading}
                                formStatus={this.state.status}
                                formId={this.state.formId}
                                formValidFrom={this.state.formValidityBegin}
                                close={this.props.close}
                                onGoBackToSearch={this.onGoBackToSearch}
                                officeId={this.state.formOrTemplateOfficeId}
                            />
                        ) : null}
                        <section className={styles.formTopActions}>
                            {this.state.editable && !this.backOfficeNotAuthorised && (
                                <>
                                    <div>
                                        {this.isUserAdministrator || this.isUserAllowedToGenerateJSONMapping ? (
                                            <>
                                                {this.state.jsonMapping === null ? (
                                                    <FormFooterButton
                                                        color={buttonColor.orange}
                                                        clickAction={() =>
                                                            this.openModal(
                                                                FORM_QUESTIONS_MODAL_SCREEN.CONFIRM_GENERATE_JSON_MAPPING
                                                            )
                                                        }
                                                        icon={faCode}
                                                    >{`Generate JSON Mapping`}</FormFooterButton>
                                                ) : (
                                                    <FormFooterButton
                                                        color={buttonColor.blue}
                                                        clickAction={() =>
                                                            this.openModal(
                                                                FORM_QUESTIONS_MODAL_SCREEN.SHOW_JSON_MAPPING
                                                            )
                                                        }
                                                        icon={faCode}
                                                    >{`Show JSON Mapping`}</FormFooterButton>
                                                )}
                                            </>
                                        ) : null}
                                        {this.state.status === STATUS.IN_PREPARATION && (
                                            <>
                                                <FormFooterButton
                                                    color={buttonColor.green}
                                                    clickAction={() =>
                                                        this.openModal(
                                                            FORM_QUESTIONS_MODAL_SCREEN.SEARCH_AND_ADD_QUESTION
                                                        )
                                                    }
                                                    icon={faPlus}
                                                >{`Search and add Question`}</FormFooterButton>
                                                <FormFooterButton
                                                    color={buttonColor.green}
                                                    clickAction={() =>
                                                        this.openModal(FORM_QUESTIONS_MODAL_SCREEN.CREATE_QUESTION)
                                                    }
                                                    icon={faPlus}
                                                >{`Create Question`}</FormFooterButton>
                                                <FormFooterButton
                                                    color={buttonColor.green}
                                                    clickAction={() =>
                                                        this.openModal(FORM_QUESTIONS_MODAL_SCREEN.USE_A_TEMPLATE)
                                                    }
                                                    icon={faPlus}
                                                >{`Use a template`}</FormFooterButton>
                                                {isUserOfficeCPVO() ? (
                                                    <FormFooterButton
                                                        color={buttonColor.green}
                                                        clickAction={() =>
                                                            this.openModal(FORM_QUESTIONS_MODAL_SCREEN.CREATE_GROUP)
                                                        }
                                                        icon={faPlus}
                                                    >{`Create Group`}</FormFooterButton>
                                                ) : null}
                                            </>
                                        )}
                                    </div>
                                    <div style={{clear: 'both'}} />
                                    <div>
                                        <FormFooterButton
                                            color={buttonColor.whiteBlack}
                                            clickAction={this.onButtonPrintClick}
                                            icon={faPrint}
                                        >
                                            {`Print`}
                                        </FormFooterButton>
                                        {(this.state.status === STATUS.IN_PREPARATION ||
                                            this.state.status === STATUS.ACTIVE) && (
                                            <FormFooterButton
                                                color={buttonColor.whiteBlack}
                                                clickAction={this.onToggleHiddenButton}
                                                extraStyles={styles.toggleHiddenButton}
                                            >
                                                <Checkbox
                                                    label={`Show hidden`}
                                                    value={this.state.screenData.showHidden}
                                                    clickAction={() => null}
                                                />
                                            </FormFooterButton>
                                        )}
                                        {this.state.editable && this.state.name ? (
                                            <FormFooterButton
                                                color={buttonColor.whiteBlack}
                                                clickAction={this.onGoToPreviewForm}
                                                icon={faEye}
                                            >
                                                Preview form
                                            </FormFooterButton>
                                        ) : null}
                                    </div>
                                </>
                            )}
                            {!this.state.editable && !this.backOfficeNotAuthorised && (
                                <>
                                    <div style={{height: 40}}>
                                        <FormFooterButton
                                            color={buttonColor.whiteGray}
                                            clickAction={this.onGoBackToSearch}
                                            icon={faArrowLeft}
                                            extraStyles={styles.goBackToSearchBtn}
                                        >{`Go back to search`}</FormFooterButton>
                                        <FormFooterButton
                                            color={buttonColor.green}
                                            clickAction={this.onSubmit}
                                            icon={faSave}
                                            disabled={this.state.loading !== 0}
                                        >{`Save & Validate`}</FormFooterButton>
                                        {this.state.name && isEditionAvailable ? (
                                            <FormFooterButton
                                                color={buttonColor.whiteBlack}
                                                clickAction={this.onGoToEditForm}
                                                icon={faPencilAlt}
                                            >
                                                Edit form
                                            </FormFooterButton>
                                        ) : null}
                                    </div>
                                    <div style={{textAlign: 'left', marginLeft: 10, marginTop: 10}}>
                                        {this.state.header && this.state.header.value ? (
                                            <div
                                                /* eslint-disable-next-line react/no-danger */
                                                dangerouslySetInnerHTML={{
                                                    __html: getContentWithCorrectImagePath(this.state.header.value),
                                                }}
                                            />
                                        ) : null}
                                    </div>
                                </>
                            )}
                        </section>
                        <WizardSteps
                            updateScreenData={this.updateScreenData}
                            screenData={this.state.screenData}
                            getStepState={this.getStepState}
                            goToStepByReference={this.goToStepByReference}
                            getCurrentStep={this.getCurrentStep}
                            goToStep={this.goToStep}
                            steps={this.state.groupsQuestion}
                            currentStepNumber={this.state.currentStepNumber}
                            iteration={this.state.iteration}
                            questionnaireLanguage={this.state.questionnaireLanguage}
                            formOrTemplateStatus={this.state.status}
                            assistedEntries={this.state.assistedEntries}
                            formOrTemplateOfficeId={this.state.formOrTemplateOfficeId}
                            formId={this.state.formId}
                            removeQuestion={this.removeQuestion}
                            removeResponse={this.removeResponse}
                            setStepGroupCallables={this.setStepGroupCallables}
                            loadFormQuestionsJSONs={this.loadJSONs}
                            modalQueuePush={this.modalQueuePush}
                            modalQueuePop={this.modalQueuePop}
                            mock={this.state.mock}
                            validatedSteps={this.state.validatedSteps}
                            editable={this.state.editable}
                            showHidden={this.state.showHidden}
                            mainScreen={`backOfficeFormQuestions`}
                        />
                        {this.state.footer && this.state.footer.value ? (
                            <div
                                style={{textAlign: 'left', marginTop: 20}}
                                /* eslint-disable-next-line react/no-danger */
                                dangerouslySetInnerHTML={{
                                    __html: getContentWithCorrectImagePath(this.state.footer.value),
                                }}
                            />
                        ) : null}
                    </FormWithBackground>
                    <div className={styles.formBottomActions} />
                </MainWrapper>
                <Footer />
                {super.render()}
            </>
        );
    }
}

export default injectIntl(BackOfficeTemplateQuestions);
