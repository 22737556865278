import React from 'react';
import {CPVO_EXPERT_ADVICE_STATUS, downloadBlob} from '../VFPDFTestDataSheet/VFPDFTestDataSheet.utils';
import VFModalDenominationTest from '../VFModalDenominationTest/VFModalDenominationTest';
import {VFPDFTestDataSheet} from '../VFPDFTestDataSheet/VFPDFTestDataSheet';
import AdviceRequestBadge from './AdviceRequestBadge';
import {Button} from '../../componentsFormV2';
import Checkbox from '~components/Checkbox';
import CustomTable from '~components/CustomTable';
import DateInput from '~components/DateInput';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderCookies,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import {ModalConfirmVersion2} from '../../commonModals';
import ModalVFMyTestsDownloadSelected from './modals/ModalVFMyTestsDownloadSelected';
import NavigationVarietyFinder from '../../shared/NavigationVarietyFinder';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import ToggleButton from '~components/ToggleButton';
import {checkToday, isAdvanced} from '../../utils/utilsVarietyFinder';
import {faArchive, faCheckCircle, faComment, faFile, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import graphQLClientInstance from '../../utils/axiosGraphQLClient';
import {
    getNameHashFromListByIdName,
    getSearchListFromSelectedElements,
} from '../VFSearchVarieties/VFSearchVarieties.utils';
import {getMyTestsQuery} from './VFMyTestsService';
import {injectIntl} from 'react-intl';
import {millisecondsToDateFormatted, trackPageView} from '../../utils';
import {pdf} from '@react-pdf/renderer';
import styles from './VFMyTests.module.scss';
import {withRouter} from 'react-router-dom';
import XLSX from 'xlsx';

const listByIdNameCPVOExpertAdvice = [
    {ID: 'All', NAME: 'All'},
    {ID: CPVO_EXPERT_ADVICE_STATUS.NOT_REQUESTED.toString(), NAME: 'Not requested'},
    {ID: CPVO_EXPERT_ADVICE_STATUS.REQUESTED.toString(), NAME: 'Requested'},
    {ID: CPVO_EXPERT_ADVICE_STATUS.PENDING_ANALYSIS.toString(), NAME: 'Pending analysis'},
    {ID: CPVO_EXPERT_ADVICE_STATUS.PENDING_COMMITTEE.toString(), NAME: 'Pending committee'},
    {ID: CPVO_EXPERT_ADVICE_STATUS.NO_OBSERVATION.toString(), NAME: 'No observation'},
    {ID: CPVO_EXPERT_ADVICE_STATUS.OBSERVATION.toString(), NAME: 'Observation'},
    {ID: CPVO_EXPERT_ADVICE_STATUS.CANCELLED.toString(), NAME: 'Cancelled'},
    {ID: CPVO_EXPERT_ADVICE_STATUS.CONDITION.toString(), NAME: 'Condition'},
    {ID: CPVO_EXPERT_ADVICE_STATUS.PRIOR_RIGHT.toString(), NAME: 'Prior Right'},
];

const TABLE_NAME = 'varietyFinderMyTests';

const DEFAULT_COUNT = 0;

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

export interface ITestResultsResponse {
    apl_nb: string;
    applicantname: string;
    brd_name: string;
    brd_ref: string;
    deno_id: number;
    deno_status: string;
    denomination: string;
    eutm_tab: number;
    maintainer: string;
    register: string;
    country?: string;
    register_type?: string;
    score: number;
    speciename: string;
    testid: number;
    title_holder: string;
    totals: number;
    var_id: number;
    var_status: string;
    var_status_date: string;
    deno_valid: number;
    wt: string;
}

export interface ITestDataSheet {
    advice_cancel_date: number | null;
    advice_cancelled_by: string | null;
    advice_request_date: number | null;
    advice_requested_by: string | null;
    analysis_date: number | null;
    analysisby: string;
    request_advice_id: number;
    contact_name: string;
    standard_advice: string;
    original_advice?: string;
    cpvo_advice: string[];
    batch_name: string;
    denomination: string;
    species_id: string;
    class_code: string[];
    test_date: Date;
    breeder_ref: string;
    breeder_name: string;
    user_remark: string;
    species_latin_name?: string;
    test_id: number;
    test_response: string;
    test_results: {
        denominations: ITestResultsResponse[] | [];
        eutm?: ITestResultsResponse[] | [];
    };
}

const DEFAULT_CRITERIA = {
    batch: '',
    denomination: '',
    speciesLatinName: '',
    testDateFrom: '',
    testDateTo: '',
    testsFromMyOrganization: false,
    showActiveTests: true,
    pageNumber: 1,
    pageSize: 10,
    order: 'testDate',
    reverse: false,
    refresh: false,
};

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    count: number;
    criteria: TDefaultCriteria;
    loading: number;
    isModalConfirmDeleteOpen: boolean;
    isModalConfirmArchiveOpen: boolean;
    isModalDownloadSelectedOpen: boolean;
    isModalRowDetailsOpen: any;
    responseTests: any;
    screenLoaded: boolean;
    showTableNavigationButtonArchive: boolean;
    selectedTestIds: string[];
    timestamp: number;
    textLabelInputCPVOExpertAdviceSelectedElements: any;
}

class VFTests extends React.Component<IProps, IState> {
    initialCriteria: TDefaultCriteria = {...DEFAULT_CRITERIA};
    previousSelectedCPVOExpertAdvice: any = [];
    RESULT_FIELDS_ALL = isAdvanced()
        ? ['denomination', 'testid', 'speciesName', 'breederReference', 'testDate', 'batchName', 'advice', 'testStatus']
        : ['denomination', 'testid', 'speciesName', 'breederReference', 'testDate', 'batchName', 'testStatus'];

    constructor(props: IProps) {
        super(props);
        this.state = {
            count: DEFAULT_COUNT,
            criteria: {...this.initialCriteria},
            isModalConfirmDeleteOpen: false,
            isModalConfirmArchiveOpen: false,
            isModalDownloadSelectedOpen: false,
            isModalRowDetailsOpen: false,
            loading: 0,
            responseTests: [],
            screenLoaded: false,
            showTableNavigationButtonArchive: true,
            selectedTestIds: [],
            textLabelInputCPVOExpertAdviceSelectedElements: {},
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'My Tests'});
        this.search();
    }

    updateCriteriaValue = (criteriaValue: any, callback?: any, refresh?: any) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = criteriaValue.pageNumber && prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.criteria.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse !== undefined && prev.criteria.reverse !== criteriaValue.reverse;
                return {...prev, criteria};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) && refresh && this.search();
            }
        );
    };

    search = (callback?: any) => {
        const values: any = {};
        const tablePageSize = this.state.criteria.pageSize;
        const tablePageIndex = this.state.criteria.pageNumber - 1;
        values.denomination = this.state.criteria.denomination;
        values.speciesLatinName = this.state.criteria.speciesLatinName;
        values.batchName = this.state.criteria.batch;
        values.testDateFrom = this.state.criteria.testDateFrom;
        values.testDateTo = this.state.criteria.testDateTo;
        values.archivedTests = !this.state.criteria.showActiveTests;
        values.testsOrganization = this.state.criteria.testsFromMyOrganization;
        if (Object.keys(this.state.textLabelInputCPVOExpertAdviceSelectedElements).length) {
            values.expertAdvice = getSearchListFromSelectedElements(
                this.state.textLabelInputCPVOExpertAdviceSelectedElements
            );
        }

        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                graphQLClientInstance
                    .post(
                        '/api/v1/tests/search',
                        getMyTestsQuery(
                            values,
                            tablePageSize,
                            tablePageIndex,
                            this.state.criteria.order,
                            this.state.criteria.reverse
                        )
                    )
                    .then((JSONResponse: any) => {
                        if (JSONResponse && JSONResponse.data && JSONResponse.data.tests_list) {
                            this.setState(
                                prev => ({
                                    count: JSONResponse.data.tests_list.length
                                        ? JSONResponse.data.tests_list[0].totals
                                        : 0,
                                    loading: prev.loading - 1,
                                    responseTests: JSONResponse.data.tests_list.map(
                                        (
                                            {
                                                batch_name: batchName,
                                                breederreference: breederReference,
                                                denomination,
                                                testid,
                                                test_date: testDate,
                                                species_name: speciesName,
                                                test_status: testStatus,
                                                request_advice_id: advice,
                                            }: any,
                                            index: number
                                        ) => ({
                                            denomination,
                                            testid,
                                            speciesName,
                                            breederReference,
                                            testDate: millisecondsToDateFormatted(testDate),
                                            batchName,
                                            testStatus,
                                            advice,
                                            JSONResponse: JSONResponse.data.tests_list[index],
                                        })
                                    ),
                                    screenLoaded: true,
                                    showTableNavigationButtonArchive: prev.criteria.showActiveTests,
                                    timestamp: Date.now(),
                                }),
                                () => callback && callback()
                            );
                        }
                    });
            }
        );
    };

    resetCriteria = () =>
        this.setState({
            criteria: this.initialCriteria,
            timestamp: Date.now(),
        });

    getModalTestsRowDetailsIndex = () => {
        const {current_row: currentRow} =
            (this.state.isModalRowDetailsOpen && this.state.isModalRowDetailsOpen.JSONResponse) || {};
        const {pageSize, pageNumber} = this.state.criteria;
        return currentRow - 1 - (pageNumber - 1) * pageSize;
    };

    compareTestResultsList = (a: any, b: any) => {
        let comparison = 0;
        if (a['score'] > b['score']) {
            comparison = 1;
        } else if (a['score'] < b['score']) {
            comparison = -1;
        }
        return comparison;
    };

    tableNavigationButtons = () => {
        const hasTableSelectedRows = this.state.selectedTestIds.length > 0;
        const responseTestsSelected: any = [];
        this.state.responseTests.forEach(
            ({testid}: any, index: any) =>
                this.state.selectedTestIds.map(testId => parseInt(testId)).includes(testid) &&
                responseTestsSelected.push(this.state.responseTests[index])
        );
        const isButtonRequestAdviceDisabled =
            responseTestsSelected.length === 0 ||
            responseTestsSelected.filter(
                ({JSONResponse, advice}: any) =>
                    advice === CPVO_EXPERT_ADVICE_STATUS.NOT_REQUESTED && checkToday(JSONResponse.test_date)
            ).length !== this.state.selectedTestIds.length;
        const isButtonDeleteDisabled =
            responseTestsSelected.length === 0 ||
            responseTestsSelected.filter(({advice}: any) => advice === CPVO_EXPERT_ADVICE_STATUS.NOT_REQUESTED)
                .length !== this.state.selectedTestIds.length;
        const isButtonArchiveDisabled =
            responseTestsSelected.length === 0 ||
            responseTestsSelected.filter(({JSONResponse}: any) => JSONResponse.test_archived === 0).length !==
                this.state.selectedTestIds.length;

        return (
            <>
                <div
                    className={styles.navigationBarV2LeftItem}
                    onClick={hasTableSelectedRows ? this.onTableNavigationButtonDownloadSelectClick : undefined}
                    style={{
                        opacity: hasTableSelectedRows ? 1 : 0.6,
                    }}
                >
                    <FontAwesomeIcon style={{float: 'left'}} icon={faFile as any} />
                    <div
                        style={{
                            float: 'left',
                            paddingLeft: 5,
                        }}
                    >
                        {`Download Selected`}
                    </div>
                    <div style={{clear: 'both'}}>{}</div>
                </div>
                {isAdvanced() ? (
                    <div
                        className={styles.navigationBarV2LeftItem}
                        onClick={
                            isButtonRequestAdviceDisabled ? undefined : this.onTableNavigationButtonRequestAdviceClick
                        }
                        style={{
                            opacity: isButtonRequestAdviceDisabled ? 0.6 : 1,
                        }}
                    >
                        <FontAwesomeIcon style={{float: 'left'}} icon={faComment as any} />
                        <div
                            style={{
                                float: 'left',
                                paddingLeft: 5,
                            }}
                        >
                            {`Request advice`}
                        </div>
                        <div style={{clear: 'both'}}>{}</div>
                    </div>
                ) : null}
                <div
                    className={styles.navigationBarV2LeftItem}
                    onClick={isButtonDeleteDisabled ? undefined : this.onTableNavigationButtonDeleteClick}
                    style={{
                        opacity: isButtonDeleteDisabled ? 0.6 : 1,
                    }}
                >
                    <FontAwesomeIcon style={{float: 'left'}} icon={faTrashAlt as any} />
                    <div
                        style={{
                            float: 'left',
                            paddingLeft: 5,
                        }}
                    >
                        {`Delete`}
                    </div>
                    <div style={{clear: 'both'}}>{}</div>
                </div>
                {this.state.showTableNavigationButtonArchive ? (
                    <div
                        className={styles.navigationBarV2LeftItem}
                        onClick={isButtonArchiveDisabled ? undefined : this.onTableNavigationButtonArchiveClick}
                        style={{
                            opacity: isButtonArchiveDisabled ? 0.6 : 1,
                        }}
                    >
                        <FontAwesomeIcon style={{float: 'left'}} icon={faArchive as any} />
                        <div
                            style={{
                                float: 'left',
                                paddingLeft: 5,
                            }}
                        >
                            {`Archive`}
                        </div>
                        <div style={{clear: 'both'}}>{}</div>
                    </div>
                ) : null}
            </>
        );
    };

    closeModalDownloadSelected = () => this.setState({isModalDownloadSelectedOpen: false});

    closeModalConfirmDelete = () => this.setState({isModalConfirmDeleteOpen: false});

    closeModalConfirmArchive = () => this.setState({isModalConfirmArchiveOpen: false});

    closeModalRowDetails = (refresh?: boolean) => {
        if (refresh) {
            const prevModalRowDetailsOpen = Object.assign({}, this.state.isModalRowDetailsOpen);
            this.search(() =>
                this.setState(prev => ({
                    isModalRowDetailsOpen: prev.responseTests.find(
                        (test: any) => test.testid === prevModalRowDetailsOpen.testid
                    ),
                }))
            );
        } else {
            this.setState({isModalRowDetailsOpen: false});
        }
    };

    printExcel = () => {
        const resultGrid: any = [
            ['Denomination', 'Test ID', 'Species Latin Name', 'Test Date', 'Batch Name', 'Advice', 'Status'],
        ];
        const values: any = {};
        values.denomination = this.state.criteria.denomination;
        values.speciesLatinName = this.state.criteria.speciesLatinName;
        values.batchName = this.state.criteria.batch;
        values.testDateFrom = this.state.criteria.testDateFrom;
        values.testDateTo = this.state.criteria.testDateTo;
        values.archivedTests = !this.state.criteria.showActiveTests;
        values.testsOrganization = this.state.criteria.testsFromMyOrganization;
        const indexesNeeded = Math.ceil(this.state.count / 1000);
        let promises: any = [];
        for (let index = 0; index < indexesNeeded; index++) {
            promises.push(
                graphQLClientInstance.post(
                    '/api/v1/tests/search',
                    getMyTestsQuery(values, 1000, index, this.state.criteria.order, this.state.criteria.reverse)
                )
            );
        }
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                Promise.all(promises).then((JSONResponses: any) => {
                    JSONResponses.forEach((JSONResponse: any) => {
                        if (JSONResponse && JSONResponse.data && JSONResponse.data.tests_list) {
                            JSONResponse.data.tests_list.forEach(
                                ({
                                    // eslint-disable-next-line camelcase
                                    batch_name,
                                    breederreference,
                                    // eslint-disable-next-line camelcase
                                    cpvo_advice,
                                    denomination,
                                    testid,
                                    // eslint-disable-next-line camelcase
                                    test_date,
                                    // eslint-disable-next-line camelcase
                                    species_name,
                                    // eslint-disable-next-line camelcase
                                    test_status,
                                }: any) => {
                                    // eslint-disable-next-line camelcase
                                    resultGrid.push([
                                        denomination,
                                        testid,
                                        // eslint-disable-next-line camelcase
                                        species_name,
                                        // eslint-disable-next-line camelcase
                                        millisecondsToDateFormatted(test_date),
                                        // eslint-disable-next-line camelcase
                                        batch_name,
                                        // eslint-disable-next-line camelcase
                                        cpvo_advice,
                                        // eslint-disable-next-line camelcase
                                        test_status,
                                    ]);
                                }
                            );
                        }
                    });
                    const workSheet = XLSX.utils.aoa_to_sheet(resultGrid);
                    const workBook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workBook, workSheet, 'VFMyTests');
                    XLSX.writeFile(workBook, `VFMyTests.xlsx`);
                    this.setState(prev => ({
                        loading: prev.loading - 1,
                    }));
                });
            }
        );
    };

    onTextInputDenominationChange = ({target: {value: denomination}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({denomination});

    onTextInputSpeciesLatinNameChange = ({target: {value: speciesLatinName}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({speciesLatinName});

    onTextInputBatchChange = ({target: {value: batch}}: React.ChangeEvent<HTMLInputElement>) =>
        this.updateCriteriaValue({batch});

    onDateInputTestDateFromChange = (testDateFrom: string) => this.updateCriteriaValue({testDateFrom});

    onDateInputTestDateToChange = (testDateTo: string) => this.updateCriteriaValue({testDateTo});

    onCheckboxTestsFromMyOrganizationClick = () =>
        this.updateCriteriaValue({testsFromMyOrganization: !this.state.criteria.testsFromMyOrganization});

    onCheckboxShowActiveTestsClick = () =>
        this.updateCriteriaValue({showActiveTests: !this.state.criteria.showActiveTests});

    onTableRowClick = (_: any, rowObject: any) => this.setState({isModalRowDetailsOpen: rowObject});

    onSelectedChange = (selectedTestIds: any) => this.setState({selectedTestIds});

    onTableNavigationButtonDownloadSelectClick = () => this.setState({isModalDownloadSelectedOpen: true});

    onTableNavigationButtonDeleteClick = () => this.setState({isModalConfirmDeleteOpen: true});

    onTableNavigationButtonArchiveClick = () => this.setState({isModalConfirmArchiveOpen: true});

    onModalConfirmDeleteClick = () =>
        this.setState(
            prev => ({isModalConfirmDeleteOpen: false, loading: prev.loading + 1}),
            () => {
                graphQLClientInstance
                    .post('/api/v1/tests/delete', {
                        test_ids: this.state.selectedTestIds.map(testId => parseInt(testId)),
                    })
                    .then((_: any) => {
                        this.setState(
                            prev => ({
                                loading: prev.loading - 1,
                                selectedTestIds: [],
                                timestamp: Date.now(),
                            }),
                            this.search
                        );
                    });
            }
        );

    onModalConfirmArchiveClick = () =>
        this.setState(
            prev => ({isModalConfirmArchiveOpen: false, loading: prev.loading + 1}),
            () => {
                graphQLClientInstance
                    .post('/api/v1/tests/archive', {
                        test_ids: this.state.selectedTestIds.map(testId => parseInt(testId)),
                    })
                    .then((_: any) => {
                        this.setState(
                            prev => ({
                                loading: prev.loading - 1,
                                selectedTestIds: [],
                                timestamp: Date.now(),
                            }),
                            this.search
                        );
                    });
            }
        );

    onTableNavigationButtonRequestAdviceClick = () =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                graphQLClientInstance
                    .post('/api/v1/tests/advice/request', {
                        test_ids: this.state.selectedTestIds.map(testId => parseInt(testId)),
                    })
                    .then(JSONResponse => {
                        if (JSONResponse) {
                            this.setState(
                                prev => ({
                                    loading: prev.loading - 1,
                                    selectedTestIds: [],
                                    timestamp: Date.now(),
                                }),
                                this.search
                            );
                        }
                    });
            }
        );

    onModalDownloadSelectedButtonDownloadClick = ({checkboxIncludeEUIPOTrademarksValue, numberOfResults}: any) => {
        const testIds = this.state.selectedTestIds.map(selectedTestId => parseInt(selectedTestId));
        const myTestsPdfPageSize = parseInt(numberOfResults);
        const testResults: any = [];
        testIds.forEach(testId => {
            let testObject = (this.state.responseTests.find((test: any) => test.testid === testId) || {}).JSONResponse;
            testResults.push(testObject);
        });
        const promises: any = [];
        testIds.forEach(testId => {
            let promise = graphQLClientInstance.get(`api/v1/tests/${testId}`);
            promises.push(promise);
        });
        this.setState(
            prev => ({
                isModalDownloadSelectedOpen: false,
                loading: prev.loading + 1,
            }),
            () => {
                Promise.all(promises).then(JSONResponses => {
                    const dataArr: any = [];
                    JSONResponses.forEach(JSONResponse => {
                        const testResultsData = JSONResponse.data.testresults_list;
                        if (testResultsData.length) {
                            testResultsData.sort(this.compareTestResultsList);
                            dataArr[testResultsData[0]['testid']] = testResultsData;
                        }
                    });
                    const testDatasheetsData = testResults.map((datasheet: any) => {
                        let testResultsDenominationsData = dataArr[datasheet['testid']]
                            ? [...dataArr[datasheet['testid']].filter((result: any) => result['eutm_tab'] === 0)]
                            : [];
                        let testResultsEutmData = dataArr[datasheet['testid']]
                            ? [...dataArr[datasheet['testid']].filter((result: any) => result['eutm_tab'] === 1)]
                            : [];
                        if (myTestsPdfPageSize > 0) {
                            testResultsDenominationsData = testResultsDenominationsData.slice(0, myTestsPdfPageSize);
                            testResultsEutmData = testResultsEutmData.slice(0, myTestsPdfPageSize);
                        }
                        let datasheetObj: ITestDataSheet = {
                            ...datasheet,
                            species_id: datasheet['specieid'],
                            class_code: datasheet['class_code'],
                            test_id: datasheet['testid'],
                            breeder_name: datasheet['breedername'],
                            breeder_ref: datasheet['breederreference'],
                            species_latin_name: datasheet['species_name'],
                            test_results: {
                                denominations: [...testResultsDenominationsData],
                            },
                        };
                        if (checkboxIncludeEUIPOTrademarksValue) {
                            datasheetObj.test_results.eutm = [...testResultsEutmData];
                        }
                        return datasheetObj;
                    });
                    pdf(<VFPDFTestDataSheet data={testDatasheetsData} />)
                        .toBlob()
                        .then((blob: any) => {
                            downloadBlob(blob, 'MyTests.pdf');
                            this.setState(prev => ({
                                loading: prev.loading - 1,
                                selectedTestIds: [],
                                timestamp: Date.now(),
                            }));
                        });
                });
            }
        );
    };

    onModalTestRowDetailsNextClick = () => {
        const {current_row: currentRow} =
            (this.state.isModalRowDetailsOpen && this.state.isModalRowDetailsOpen.JSONResponse) || {};
        const nextRowObject = this.state.responseTests.find(
            (test: any) => test.JSONResponse.current_row === currentRow + 1
        );
        this.setState({isModalRowDetailsOpen: false}, () => {
            this.setState({isModalRowDetailsOpen: nextRowObject});
        });
    };

    onModalTestRowDetailsPreviousClick = () => {
        const {current_row: currentRow} =
            (this.state.isModalRowDetailsOpen && this.state.isModalRowDetailsOpen.JSONResponse) || {};
        const previousRowObject = this.state.responseTests.find(
            (test: any) => test.JSONResponse.current_row === currentRow - 1
        );
        this.setState({isModalRowDetailsOpen: false}, () => {
            this.setState({isModalRowDetailsOpen: previousRowObject});
        });
    };

    onHeaderSearchLinkClick = (searchListItem: any) => {
        this.props.history.push({
            pathname: '/varieties',
            state: {searchListItem},
        });
    };

    onCPVOExpertAdviceChange = (_: any) => {
        const selectedElements = {...this.state.textLabelInputCPVOExpertAdviceSelectedElements};
        if (this.previousSelectedCPVOExpertAdvice.includes('All') && !Object.keys(selectedElements).includes('All')) {
            this.previousSelectedCPVOExpertAdvice = [];
            this.setState({textLabelInputCPVOExpertAdviceSelectedElements: {}});
            return;
        }
        if (Object.keys(selectedElements).includes('All') && !this.previousSelectedCPVOExpertAdvice.includes('All')) {
            const nameHash = getNameHashFromListByIdName(listByIdNameCPVOExpertAdvice);
            this.previousSelectedCPVOExpertAdvice = Object.keys(nameHash);
            this.setState({textLabelInputCPVOExpertAdviceSelectedElements: nameHash});
            return;
        }
        if (
            Object.keys(selectedElements).includes('All') &&
            Object.keys(selectedElements).length < listByIdNameCPVOExpertAdvice.length
        ) {
            delete selectedElements['All'];
            this.setState({textLabelInputCPVOExpertAdviceSelectedElements: selectedElements});
        }
        this.previousSelectedCPVOExpertAdvice = Object.keys(selectedElements);
    };

    render() {
        let selectedElementsHash: any = {};
        this.state.selectedTestIds.forEach((el: any) => (selectedElementsHash[el] = true));

        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                {this.state.isModalRowDetailsOpen ? (
                    <VFModalDenominationTest
                        close={this.closeModalRowDetails}
                        currentIndex={this.getModalTestsRowDetailsIndex()}
                        onButtonNextClick={this.onModalTestRowDetailsNextClick}
                        onButtonPreviousClick={this.onModalTestRowDetailsPreviousClick}
                        rowObject={this.state.isModalRowDetailsOpen}
                        totalCount={this.state.responseTests.length}
                    />
                ) : null}
                {this.state.isModalDownloadSelectedOpen ? (
                    <ModalVFMyTestsDownloadSelected
                        close={this.closeModalDownloadSelected}
                        onDownloadPDF={this.onModalDownloadSelectedButtonDownloadClick}
                    />
                ) : null}
                {this.state.isModalConfirmDeleteOpen ? (
                    <ModalConfirmVersion2
                        title={`Alert`}
                        message={`Are you sure you want to delete the test(s)?`}
                        buttonName={'Confirm'}
                        action={this.onModalConfirmDeleteClick}
                        close={this.closeModalConfirmDelete}
                    />
                ) : null}
                {this.state.isModalConfirmArchiveOpen ? (
                    <ModalConfirmVersion2
                        title={`Alert`}
                        message={`Are you sure you want to archive the test(s)?`}
                        buttonName={'Confirm'}
                        action={this.onModalConfirmArchiveClick}
                        close={this.closeModalConfirmArchive}
                    />
                ) : null}
                <HeaderCookies />
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Variety Finder`} hostApplication={'Variety Finder'} />
                <NavigationVarietyFinder onSearchLinkClick={this.onHeaderSearchLinkClick} />
                <MainWrapper bgGray={true}>
                    <FormWrapper formInnerWidth={'lg'} paddingFormContent={'sm'}>
                        <TextInput
                            label={'Denomination'}
                            onChange={this.onTextInputDenominationChange}
                            value={this.state.criteria.denomination}
                            popOverTextFirstRow={
                                <div
                                    /* eslint-disable-next-line react/no-danger */
                                    dangerouslySetInnerHTML={{
                                        __html: `The denomination that has been tested.<br/>Note that, currently, you must enter the denomination using the same accents as when the test was submitted (e.g. "marée" vs "maree").`,
                                    }}
                                />
                            }
                            size={'lg'}
                        />
                        <TextInput
                            double={true}
                            label={'Species Latin Name'}
                            onChange={this.onTextInputSpeciesLatinNameChange}
                            value={this.state.criteria.speciesLatinName}
                            popOverTextFirstRow={
                                <div
                                    /* eslint-disable-next-line react/no-danger */
                                    dangerouslySetInnerHTML={{
                                        __html: `The species Latin name.<br/>Note that, currently, this fields works as with "Starts with" (ie you have to enter the beginning of the Latin name or the complete name to find your tests).`,
                                    }}
                                />
                            }
                            size={'lg'}
                        />
                        <TextInput
                            label={'Batch'}
                            onChange={this.onTextInputBatchChange}
                            value={this.state.criteria.batch}
                            popOverTextFirstRow={`The batch name used when the test was submitted.`}
                            size={'lg'}
                        />
                        {isAdvanced() ? (
                            <TextLabelInput
                                useAsMultipleSelection={true}
                                autoSelection={true}
                                hideRemoveElementButton={true}
                                label={'CPVO Expert advice'}
                                onSelectionChange={this.onCPVOExpertAdviceChange}
                                delay={300}
                                selectedElements={this.state.textLabelInputCPVOExpertAdviceSelectedElements}
                                listByIdName={listByIdNameCPVOExpertAdvice}
                                nameHash={getNameHashFromListByIdName(listByIdNameCPVOExpertAdvice)}
                                popOverTextFirstRow={`The status of the advice request. `}
                                noInfo={true}
                                value={``}
                                removeStyleClear={true}
                                selectedLabelsAsInputText={true}
                                placeHolder={`Select`}
                                size={'lg'}
                            />
                        ) : null}
                        <DateInput
                            label={`Test date`}
                            changeDateFrom={this.onDateInputTestDateFromChange}
                            changeDateTo={this.onDateInputTestDateToChange}
                            inputValueFrom={this.state.criteria.testDateFrom}
                            inputValueTo={this.state.criteria.testDateTo}
                            popOverTextFirstRow={`The date when the test was submitted.`}
                            size={'lg'}
                        />
                        <div style={{clear: 'both'}} />
                        <ToggleButton
                            clickAction={this.onCheckboxShowActiveTestsClick}
                            label={`Status`}
                            simple={true}
                            subtitleDisplay={this.state.criteria.showActiveTests ? 'Active tests' : 'Archived tests'}
                            value={this.state.criteria.showActiveTests}
                            size={'lg'}
                        />
                        <Checkbox
                            double={true}
                            clickAction={this.onCheckboxTestsFromMyOrganizationClick}
                            label={`Display tests from my organization`}
                            value={this.state.criteria.testsFromMyOrganization}
                            size={'lg'}
                        />
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            <Button clickAction={this.resetCriteria} variation={'secondary'}>{`Clear fields`}</Button>
                            <Button clickAction={() => this.search()}>{`Search`}</Button>
                        </FormFooter>
                        <div style={{clear: 'both'}} />
                    </FormWrapper>
                    <div>
                        {this.state.screenLoaded ? (
                            <>
                                <div style={{marginBottom: 7}}>
                                    <div
                                        className={styles.tableHeaderCounterTitle}
                                    >{`${this.state.count} results matching your criteria`}</div>
                                </div>
                                <CustomTable
                                    additionalNavigationButtons={this.tableNavigationButtons}
                                    count={this.state.count}
                                    defaultOrder={this.state.criteria.order}
                                    formatFunctions={{
                                        advice: (advice: any) => <AdviceRequestBadge adviceId={advice} />,
                                        testStatus: (testStatus: any) =>
                                            testStatus === 0 ? (
                                                <FontAwesomeIcon
                                                    icon={faCheckCircle as any}
                                                    color={'green'}
                                                    style={{marginLeft: 15}}
                                                />
                                            ) : null,
                                        denomination: (denomination: string) => {
                                            return (
                                                <div
                                                    style={{
                                                        color: '#255899',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {denomination}
                                                </div>
                                            );
                                        },
                                    }}
                                    id={'testid'}
                                    intl={this.props.intl}
                                    isNavigationButtonCompactOrDefaultViewEnabled={true}
                                    onSelectedChange={this.onSelectedChange}
                                    pageNumber={this.state.criteria.pageNumber}
                                    pageSize={this.state.criteria.pageSize}
                                    pagination={true}
                                    printExcel={this.printExcel}
                                    resultFieldsDefault={this.RESULT_FIELDS_ALL}
                                    reverseOrder={this.state.criteria.reverse}
                                    rowClick={this.onTableRowClick}
                                    rowBorderLeft={true}
                                    selectable={true}
                                    selectDefault={selectedElementsHash}
                                    tableName={TABLE_NAME}
                                    tableType={'OBJECT'}
                                    tableSource={this.state.responseTests}
                                    timestamp={this.state.timestamp}
                                    timestampSelected={this.state.timestamp}
                                    updateCriteriaValue={this.updateCriteriaValue}
                                    version={2}
                                    hoverGrey={true}
                                    navigationBarWider={true}
                                    fontSize={14}
                                />
                            </>
                        ) : null}
                        <div style={{clear: 'both'}} />
                    </div>
                </MainWrapper>
                <Footer hostApplication={'Variety Finder'} />
            </>
        );
    }
}

export default withRouter(injectIntl(VFTests));
