import {API_SERVERLESS, getFetch} from '~commonApi';

export function apiFileLogs(zone: any) {
    return getFetch(`${API_SERVERLESS}/common/v3/cpvoLogs/${zone}`, true);
}

export function apiFileListLogs(zone: any, directory: any, server?: any) {
    return getFetch(`${API_SERVERLESS}/common/v3/cpvoLogs/${zone}/${directory}`, true);
}

export function apiFileLogsDownload(zone: any, directory: any, filename: any, server?: any) {
    return getFetch(`${API_SERVERLESS}/common/v3/cpvoLogs/${zone}/${directory}/${encodeURIComponent(filename)}`, true);
}
