import React from 'react';

// STYLES & ICONS
import styles from './TreeViewTopCaption.module.scss';
import {faCheck, faPlus, faRemoveFormat} from '@fortawesome/free-solid-svg-icons';

// PROPS
import {TreeViewTopCaptionProps} from './Props/TreeViewTopCaption';

// REUSABLE COMPONENTS & FUNCTIONS
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import AdminIcon from '../../../AdminIcon/AdminIcon';

const TreeViewTopCaption = (props: TreeViewTopCaptionProps) => {
    return (
        <div className={styles.topCaptionContainer}>
            {props.treeIsEdited && (
                <React.Fragment>
                    <FormFooterButton color={buttonColor.green} clickAction={props.openModalConfirmSave} icon={faCheck}>
                        {`Save`}
                        <AdminIcon />
                    </FormFooterButton>
                    <FormFooterButton
                        color={buttonColor.orange}
                        clickAction={props.openModalConfirmDiscard}
                        icon={faRemoveFormat}
                    >
                        {`Discard Changes`}
                        <AdminIcon />
                    </FormFooterButton>
                </React.Fragment>
            )}
            {props.isACArea ? (
                <FormFooterButton
                    color={buttonColor.blue}
                    clickAction={props.openModalContentForAddition}
                    icon={faPlus}
                >
                    {`New Content`}
                    <AdminIcon />
                </FormFooterButton>
            ) : (
                <FormFooterButton
                    color={buttonColor.blue}
                    clickAction={
                        props.tag === 'vademecum'
                            ? props.openModalDocumentForAddition
                            : props.openModalCategoryForAddition
                    }
                    icon={faPlus}
                >
                    {props.tag === 'vademecum' ? `New Parent` : `New Category`}
                    <AdminIcon />
                </FormFooterButton>
            )}
        </div>
    );
};

export default TreeViewTopCaption;
