import React from 'react';
import styles from './ModalBackOfficeRequestsRemedy.module.scss';
import {injectIntl} from 'react-intl';
import {ModalCustomVersion2} from '../../../commonModals';
import {faSquare as faSquareSolid} from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import CustomTable from '../../../components/CustomTable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckSquare, faSquare} from '@fortawesome/free-regular-svg-icons';
import {apiBackOfficeGetForm} from './ModalBackOfficeRequestsRemedyService';
import {LoadingBar} from '../../../componentsLayout';
import {Button} from '../../../componentsFormV2';

const TABS = {
    APPLICATION_FORM: 'APPLICATION_FORM',
    TECHNICAL_QUESTIONNAIRE: 'TECHNICAL_QUESTIONNAIRE',
};

const getGroupOrTemplateId = group => (group.isTemplate ? `T${group.templateId}` : `G${group.id}`);

class ModalBackOfficeRequestsRemedy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: TABS.APPLICATION_FORM,
            applicationForm: null,
            technicalQuestionnaire: null,
            applicationFormCheckedGroupQuestions: null,
            technicalQuestionnaireCheckedGroupQuestions: null,
            loading: 0,
            timestamp: Date.now(),
            timestampSelected: Date.now(),
        };
    }

    componentDidMount() {
        this.loadJSONs();
    }

    loadJSONs() {
        Promise.all([this.loadApplicationForm(), this.loadTechnicalQuestionnaire()]).catch(error =>
            LOG([error.message])
        );
    }

    loadApplicationForm = () =>
        new Promise(resolve => {
            this.setState(
                prev => ({loading: ++prev.loading}),
                () => {
                    const {application} = this.props;
                    const {formIdApplicationForm, applicationFormLanguageId} = application || {};
                    apiBackOfficeGetForm(formIdApplicationForm, 'AF', applicationFormLanguageId).then(JSONResponse => {
                        this.setState(
                            prev => ({
                                applicationForm: JSONResponse.form,
                                applicationFormCheckedGroupQuestions: this.getDataStructureOfCheckedGroupQuestions(
                                    JSONResponse.form
                                ),
                                loading: --prev.loading,
                            }),
                            () => resolve()
                        );
                    });
                }
            );
        });

    loadTechnicalQuestionnaire = () =>
        new Promise(resolve => {
            this.setState(
                prev => ({loading: ++prev.loading}),
                () => {
                    const {application} = this.props;
                    const {formIdTQ, TQLanguageId} = application || {};
                    apiBackOfficeGetForm(formIdTQ, 'TQ', TQLanguageId).then(JSONResponse => {
                        this.setState(
                            prev => ({
                                technicalQuestionnaire: JSONResponse.form,
                                technicalQuestionnaireCheckedGroupQuestions:
                                    this.getDataStructureOfCheckedGroupQuestions(JSONResponse.form),
                                loading: --prev.loading,
                            }),
                            () => resolve()
                        );
                    });
                }
            );
        });

    getDataStructureOfCheckedGroupQuestions = form => {
        const dataStructure = {};
        ((form && form.groupsQuestion) || [])
            .filter(group => group.isGroup === true)
            .forEach(group => {
                let groupOrTemplateKey = group.isTemplate ? `T${group.templateId}` : `G${group.id}`;
                dataStructure[groupOrTemplateKey] = {};
                group.questions.forEach(question => {
                    dataStructure[groupOrTemplateKey][`${groupOrTemplateKey}_Q${question.id}`] = false;
                });
            });
        return dataStructure;
    };

    getTechnicalQuestionnaireSelectedQuestions = group => {
        let groupOrTemplateId = getGroupOrTemplateId(group);
        let stateSelected = {...this.state.technicalQuestionnaireCheckedGroupQuestions[groupOrTemplateId]};
        let checked = {};
        Object.entries(stateSelected).forEach(([key, value]) => (checked[key] = value));
        return checked;
    };

    getApplicationFormSelectedQuestions = group => {
        let groupOrTemplateId = getGroupOrTemplateId(group);
        let stateSelected = {...this.state.applicationFormCheckedGroupQuestions[groupOrTemplateId]};
        let checked = {};
        Object.entries(stateSelected).forEach(([key, value]) => (checked[key] = value));
        return checked;
    };

    onTabTechnicalQuestionnaireClick = () =>
        this.setState({activeTab: TABS.TECHNICAL_QUESTIONNAIRE, timestamp: Date.now()});

    onTabApplicationFormClick = () => this.setState({activeTab: TABS.APPLICATION_FORM, timestamp: Date.now()});

    onTableApplicationFormSelectedChange = (selected, group) => {
        let groupOrTemplateId = getGroupOrTemplateId(group);
        const nextGroupSelected = {...this.state.applicationFormCheckedGroupQuestions[groupOrTemplateId]};
        Object.keys(nextGroupSelected).forEach(key => (nextGroupSelected[key] = selected.includes(key)));
        this.setState(prev => ({
            applicationFormCheckedGroupQuestions: {
                ...prev.applicationFormCheckedGroupQuestions,
                [groupOrTemplateId]: nextGroupSelected,
            },
            timestampSelected: Date.now(),
        }));
    };

    onCheckboxApplicationFormClick = group => {
        let groupOrTemplateId = getGroupOrTemplateId(group);
        let stateSelected = {...this.state.applicationFormCheckedGroupQuestions[groupOrTemplateId]};
        let isAllChecked = Object.values(stateSelected).every(el => !!el);
        let isAllUnchecked = Object.values(stateSelected).every(el => !el);
        let isSomeChecked = !isAllChecked && Object.values(stateSelected).some(el => !!el);
        if (isAllUnchecked) {
            Object.keys(stateSelected).forEach(key => {
                stateSelected[key] = true;
            });
        }
        if (isAllChecked || isSomeChecked) {
            Object.keys(stateSelected).forEach(key => {
                stateSelected[key] = false;
            });
        }
        this.setState(prev => ({
            applicationFormCheckedGroupQuestions: {
                ...prev.applicationFormCheckedGroupQuestions,
                [groupOrTemplateId]: stateSelected,
            },
            timestampSelected: Date.now(),
        }));
    };

    onButtonRemedyClick = () => {};

    onCheckboxTechnicalQuestionnaireClick = group => {
        let groupOrTemplateId = getGroupOrTemplateId(group);
        let stateSelected = {...this.state.technicalQuestionnaireCheckedGroupQuestions[groupOrTemplateId]};
        let isAllChecked = Object.values(stateSelected).every(el => !!el);
        let isAllUnchecked = Object.values(stateSelected).every(el => !el);
        let isSomeChecked = !isAllChecked && Object.values(stateSelected).some(el => !!el);
        if (isAllUnchecked) {
            Object.keys(stateSelected).forEach(key => {
                stateSelected[key] = true;
            });
        }
        if (isAllChecked || isSomeChecked) {
            Object.keys(stateSelected).forEach(key => {
                stateSelected[key] = false;
            });
        }
        this.setState(prev => ({
            technicalQuestionnaireCheckedGroupQuestions: {
                ...prev.technicalQuestionnaireCheckedGroupQuestions,
                [groupOrTemplateId]: stateSelected,
            },
            timestampSelected: Date.now(),
        }));
    };

    onTableTechnicalQuestionnaireSelectedChange = (selected, group) => {
        let groupOrTemplateId = getGroupOrTemplateId(group);
        const nextGroupSelected = {...this.state.technicalQuestionnaireCheckedGroupQuestions[groupOrTemplateId]};
        Object.keys(nextGroupSelected).forEach(key => (nextGroupSelected[key] = selected.includes(key)));
        this.setState(prev => ({
            technicalQuestionnaireCheckedGroupQuestions: {
                ...prev.technicalQuestionnaireCheckedGroupQuestions,
                [groupOrTemplateId]: nextGroupSelected,
            },
            timestampSelected: Date.now(),
        }));
    };

    renderCheckApplicationForm = group => {
        let groupOrTemplateId = getGroupOrTemplateId(group);
        let stateSelected = {...this.state.applicationFormCheckedGroupQuestions[groupOrTemplateId]};
        let isAllChecked = Object.values(stateSelected).every(el => !!el);
        let isSomeChecked = !isAllChecked && Object.values(stateSelected).some(el => !!el);
        let icon = faSquare;
        isAllChecked && (icon = faCheckSquare);
        isSomeChecked && (icon = faSquareSolid);
        return (
            <div style={{cursor: 'pointer'}} onClick={() => this.onCheckboxApplicationFormClick(group)}>
                <FontAwesomeIcon icon={icon} color={'green'} />
            </div>
        );
    };

    renderCheckTechnicalQuestionnaire = group => {
        let groupOrTemplateId = getGroupOrTemplateId(group);
        let stateSelected = {...this.state.technicalQuestionnaireCheckedGroupQuestions[groupOrTemplateId]};
        let isAllChecked = Object.values(stateSelected).every(el => !!el);
        let isSomeChecked = !isAllChecked && Object.values(stateSelected).some(el => !!el);
        let icon = faSquare;
        isAllChecked && (icon = faCheckSquare);
        isSomeChecked && (icon = faSquareSolid);
        return (
            <div style={{cursor: 'pointer'}} onClick={() => this.onCheckboxTechnicalQuestionnaireClick(group)}>
                <FontAwesomeIcon icon={icon} color={'green'} />
            </div>
        );
    };

    renderTabApplicationForm = () => (
        <div>
            {((this.state.applicationForm && this.state.applicationForm.groupsQuestion) || [])
                .filter(group => group.isGroup === true)
                .map(group => (
                    <React.Fragment key={group.message}>
                        <div style={{position: 'relative'}}>
                            <div style={{position: 'absolute', left: 4}}>{this.renderCheckApplicationForm(group)}</div>
                            <DataSheetFormSection
                                title={
                                    <div
                                        /* eslint-disable-next-line react/no-danger */
                                        dangerouslySetInnerHTML={{
                                            __html: `<span style='font-weight: bold'>${group.order}. ${group.message}</span> `,
                                        }}
                                    />
                                }
                            >
                                <CustomTable
                                    {...this.props}
                                    tableName={'applicationRemedy'}
                                    tableType={'OBJECT'}
                                    tableSource={group.questions.map(({id, message, order}) => ({
                                        id,
                                        name: `${order}. ${message}`,
                                        groupOrTemplateId_QuestionId: `${
                                            group.isTemplate ? `T${group.templateId}` : `G${group.id}_Q${id}`
                                        }`,
                                    }))}
                                    id={'groupOrTemplateId_QuestionId'}
                                    resultFieldsDefault={['name']}
                                    intl={this.props.intl}
                                    hideExcelButton={true}
                                    hiddenHeader={true}
                                    timestamp={this.state.timestamp}
                                    notSortable={true}
                                    unlimited={true}
                                    selectable={true}
                                    selectDefault={this.getApplicationFormSelectedQuestions(group)}
                                    onSelectedChange={selected =>
                                        this.onTableApplicationFormSelectedChange(selected, group)
                                    }
                                    timestampSelected={this.state.timestampSelected}
                                />
                            </DataSheetFormSection>
                        </div>
                    </React.Fragment>
                ))}
        </div>
    );

    renderTabTechnicalQuestionnaire = () => (
        <div>
            {((this.state.technicalQuestionnaire && this.state.technicalQuestionnaire.groupsQuestion) || [])
                .filter(group => group.isGroup === true)
                .map(group => (
                    <React.Fragment key={group.message}>
                        <div style={{position: 'relative'}}>
                            <div style={{position: 'absolute', left: 4}}>
                                {this.renderCheckTechnicalQuestionnaire(group)}
                            </div>
                            <DataSheetFormSection
                                title={
                                    <div
                                        /* eslint-disable-next-line react/no-danger */
                                        dangerouslySetInnerHTML={{
                                            __html: `<span style='font-weight: bold'>${group.order}. ${group.message}</span> `,
                                        }}
                                    />
                                }
                            >
                                <CustomTable
                                    {...this.props}
                                    tableName={'applicationRemedy'}
                                    tableType={'OBJECT'}
                                    tableSource={group.questions.map(({id, message, order}) => ({
                                        id,
                                        name: `${order}. ${message}`,
                                        groupOrTemplateId_QuestionId: `${
                                            group.isTemplate ? `T${group.templateId}` : `G${group.id}_Q${id}`
                                        }`,
                                    }))}
                                    id={'groupOrTemplateId_QuestionId'}
                                    resultFieldsDefault={['name']}
                                    intl={this.props.intl}
                                    hideExcelButton={true}
                                    hiddenHeader={true}
                                    timestamp={this.state.timestamp}
                                    notSortable={true}
                                    unlimited={true}
                                    selectable={true}
                                    selectDefault={this.getTechnicalQuestionnaireSelectedQuestions(group)}
                                    onSelectedChange={selected =>
                                        this.onTableTechnicalQuestionnaireSelectedChange(selected, group)
                                    }
                                    timestampSelected={this.state.timestampSelected}
                                />
                            </DataSheetFormSection>
                        </div>
                    </React.Fragment>
                ))}
        </div>
    );

    render() {
        return (
            <div className={styles.modalWrap}>
                <ModalCustomVersion2
                    close={this.props.close}
                    header={
                        <>{`${
                            this.props.mode === 'DATA_REQUEST' ? 'Data request for' : 'Remedy'
                        } specified questions of application ${this.props.application.applicationRequestCode}`}</>
                    }
                    body={
                        <div>
                            <div style={{position: 'sticky', top: 0, background: 'white', zIndex: 1}}>
                                <div className={styles.header}>
                                    <div className={styles.leftPanel}>
                                        <div
                                            className={cx(
                                                styles.menuItem,
                                                this.state.activeTab === TABS.APPLICATION_FORM && styles.menuItemActive
                                            )}
                                            onClick={this.onTabApplicationFormClick}
                                        >
                                            <div className={styles.menuLabel}>{`Application form`}</div>
                                        </div>
                                        <div
                                            className={cx(
                                                styles.menuItem,
                                                this.state.activeTab === TABS.TECHNICAL_QUESTIONNAIRE &&
                                                    styles.menuItemActive
                                            )}
                                            onClick={this.onTabTechnicalQuestionnaireClick}
                                        >
                                            <div className={styles.menuLabel}>{`Technical questionnaire`}</div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{clear: 'both'}} />
                            </div>
                            <div style={{display: this.state.activeTab === TABS.APPLICATION_FORM ? 'block' : 'none'}}>
                                {this.renderTabApplicationForm()}
                            </div>
                            <div
                                style={{
                                    display: this.state.activeTab === TABS.TECHNICAL_QUESTIONNAIRE ? 'block' : 'none',
                                }}
                            >
                                {this.renderTabTechnicalQuestionnaire()}
                            </div>
                        </div>
                    }
                    footer={
                        this.state.loading !== 0 ? (
                            <LoadingBar />
                        ) : (
                            <Button clickAction={this.onButtonRemedyClick}>{`Remedy`}</Button>
                        )
                    }
                />
            </div>
        );
    }
}

export default injectIntl(ModalBackOfficeRequestsRemedy);
