import React from 'react';
import styles from './BackOfficeLabelsTopCaption.module.scss';
import FormFooterButton, {buttonColor} from '~componentsForm/FormFooterButton';
import {faCompressAlt, faCalculator} from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

export default function BackOfficeMessagesBottomCaption({
    mergeEnabled,
    mergeInfo,
    showModalMergeLabels,
    computeEnabled,
    computeUsedInSearch,
}) {
    return (
        <div className={styles.container}>
            <div
                className={cx(styles.label)}
                style={{color: mergeInfo && 'red'}}
            >{`Merge labels: please select one or more labels within the same Language and Type`}</div>
            <FormFooterButton
                color={buttonColor.blue}
                clickAction={showModalMergeLabels}
                icon={faCompressAlt}
                disabled={!mergeEnabled}
            >{`Merge labels`}</FormFooterButton>
            <FormFooterButton
                color={buttonColor.blue}
                clickAction={computeUsedInSearch}
                icon={faCalculator}
                disabled={!computeEnabled}
            >{`Compute usage`}</FormFooterButton>

            <div style={{clear: 'both'}}>{}</div>
        </div>
    );
}
