import React from 'react';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {apiVDCJurisprudenceMessagesGet, IVDCCaseJurisprudenceMessage} from './VDCCaseJurisprudenceService';
import DataSheetFormSection from '~shared/DataSheetFormSection';
import {loadUrlParams} from '~utils';
import {checkIfURIHasPARAM, checkIfURIHasPARAM2} from '../VDCCaseMessage/VDCCaseMessage';
import styles from './VDCCaseJurisprudence.module.scss';
import {
    Footer,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
    MainWrapper,
} from '../../componentsLayout';
import {Button} from '../../componentsFormV2';
import Navigation from '../../componentsLayout/Navigation';
import {trackPageView} from '../../utils';

interface IProps {
    intl: any;
    history: any;
}

interface IState {
    loading: number;
    jurisprudenceMessages: IVDCCaseJurisprudenceMessage[];
    screenLoaded: boolean;
    testNumber: number | null;
    timestamp: number;
}

class VDCCaseJurisprudence extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: 0,
            jurisprudenceMessages: [],
            screenLoaded: false,
            testNumber: null,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'VDCJurisprudence'});
        this.loadJSONs();
    }

    loadJSONs = () => {
        const {testId} = loadUrlParams() || {};
        if (testId) {
            this.setState(
                prev => ({loading: prev.loading + 1}),
                () => {
                    apiVDCJurisprudenceMessagesGet(testId).then(({testNumber, messages}) => {
                        if (testNumber && Array.isArray(messages)) {
                            this.setState(prev => ({
                                jurisprudenceMessages: messages,
                                loading: prev.loading - 1,
                                screenLoaded: true,
                                testNumber,
                            }));
                        }
                    });
                }
            );
        }
    };

    onButtonGoBackToCaseClick = () => {
        const {caseId} = loadUrlParams();
        const PARAM = checkIfURIHasPARAM();
        const PARAM2 = checkIfURIHasPARAM2();
        if (caseId || PARAM) {
            this.props.history.push(
                `/VDCCaseMessage?${caseId ? `caseId=${caseId}` : ''}${PARAM ? `PARAM=${PARAM}` : ''}${
                    PARAM2 ? `PARAM2=${PARAM2}` : ''
                }`
            );
        } else {
            this.props.history.push('/VDCCases');
        }
    };

    render() {
        return (
            <>
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Jurisprudence of test n° ${this.state.testNumber || '...'}`} />
                <Navigation />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div style={{marginBottom: 20, display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                variation={'tertiary'}
                                clickAction={this.onButtonGoBackToCaseClick}
                                icon={'back'}
                            >{`Go back to case`}</Button>
                            <div style={{clear: 'both'}} />
                        </div>
                        {this.state.screenLoaded && !this.state.jurisprudenceMessages.length ? (
                            <div>{`No messages for this jurisprudence`}</div>
                        ) : null}
                        {this.state.jurisprudenceMessages.map(({title, message, opinionDate, suitability}) => (
                            <div key={title}>
                                <DataSheetFormSection disableToggle={true}>
                                    <div style={{padding: '10px 0'}}>
                                        <div style={{float: 'left', width: 'calc(100% - 200px)'}}>
                                            <strong>{`Title: ${title}`}</strong>
                                        </div>
                                        <div style={{float: 'right', width: 200}}>
                                            <div style={{textAlign: 'right'}}>
                                                {`Suitability: `}
                                                <span className={styles[suitability.split(' ').join('')]}>
                                                    {suitability}
                                                </span>
                                            </div>
                                            <div style={{textAlign: 'right'}}>{`Opinion Date: ${opinionDate}`}</div>
                                        </div>
                                        <div style={{clear: 'both'}} />
                                    </div>
                                    <div
                                        /* eslint-disable-next-line react/no-danger */
                                        dangerouslySetInnerHTML={{__html: message.replace(/\n/g, '<br/>')}}
                                        style={{marginTop: 15}}
                                    />
                                </DataSheetFormSection>
                                <div style={{clear: 'both'}} />
                            </div>
                        ))}
                    </FormWrapper>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(VDCCaseJurisprudence));
