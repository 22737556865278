import React, {useState} from 'react';
import {Button} from '../../../../../../componentsFormV2';
import {Error} from '../../../../../../componentsLayout';
import {ErrorMessages} from '../../../../../TLO/CommonInterfaces/CommonInterfaces';
import {FaqElement} from '../../Interfaces/FaqPagePVRCASELAWInterfaces';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {ModalCustomVersion2} from '../../../../../../commonModals';
import TextAreaInput from '~components/TextAreaInput';
import TextInput from '~components/TextInput';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {getConcatErrorMessage} from '../../../../../TLO/CommonFunctions/CommonFunctions';

interface ModalFaqProps {
    close: () => void;
    faqElement: FaqElement | null;
    saveFaq: (faqId: number, faqQuestion: string, faqAnswer: string) => void;
}

const ModalFaq = (props: ModalFaqProps) => {
    const faqId = props.faqElement?.id || 0;
    const [question, setQuestion] = useState(props.faqElement?.question || '');
    const [answer, setAnswer] = useState(props.faqElement?.answer || '');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [highlightErrors, setHighlightErrors] = useState(false);

    const onQuestionChange = ({target: {value: question}}: React.ChangeEvent<HTMLInputElement>) => {
        setQuestion(question);
    };

    const onAnswerChange = ({target: {value: answer}}: React.ChangeEvent<HTMLInputElement>) => {
        setAnswer(answer);
    };

    const getErrorMessagesForSave = () => {
        setHighlightErrors(false);
        const errorMessages: ErrorMessages = {
            errorInput: [],
            errorLogic: [],
        };
        if (question.length === 0)
            errorMessages.errorInput && errorMessages.errorInput.push('a question for the new faq');

        if (answer.length === 0) errorMessages.errorInput && errorMessages.errorInput.push('a answer for the new faq');

        return errorMessages;
    };

    const onSaveFaq = () => {
        const errorMessagesObj = getErrorMessagesForSave();
        let errorMessage = getConcatErrorMessage(errorMessagesObj);

        if (errorMessage.length > 0) {
            setErrorMessage(errorMessage);
            setHighlightErrors(true);
        }

        if (errorMessage.length === 0) props.saveFaq(faqId, question, answer);
    };

    return (
        <ModalCustomVersion2
            close={() => props.close && props.close()}
            header={faqId > 0 ? `Edit Faq` : `Add New Faq`}
            body={
                <>
                    <div style={{clear: 'both'}} />
                    <TextInput
                        value={question}
                        outsideLabel={'Question'}
                        outsideLabelWidth={140}
                        disabled={false}
                        onChange={onQuestionChange}
                        double={true}
                        placeholder={`Please type a faq question`}
                    />
                    <div style={{clear: 'both'}} />
                    <TextAreaInput
                        outsideLabel={`Answer`}
                        outsideLabelWidth={140}
                        value={answer}
                        double={true}
                        disabled={false}
                        rows={10}
                        onChange={onAnswerChange}
                    />
                    <div style={{clear: 'both'}} />
                </>
            }
            footer={
                <>
                    {highlightErrors ? (
                        <Error>
                            <FontAwesomeIcon icon={faExclamationCircle as IconDefinition} color={'white'} />
                            {` ${errorMessage}`}
                        </Error>
                    ) : null}
                    <Button clickAction={props.close} icon={'close'} variation={'danger'}>{`Cancel`}</Button>
                    <Button clickAction={onSaveFaq}>{`Save`}</Button>
                </>
            }
        />
    );
};

export default ModalFaq;
