import React, {useEffect, useState} from 'react';
import {ModalCustomVersion2} from '../../../commonModals';
import TextAreaInput from '~components/TextAreaInput';
import {htmlToText} from '~utils/index';
import SelectInput from '~components/SelectInput';
import RadioButton from '~components/RadioButton';
import {Button} from '../../../componentsFormV2';

const trimStringIfNeeded = (string, maxLength = 24) => {
    let textString = htmlToText(string);
    return textString.length > maxLength ? `${textString.slice(0, maxLength)}...` : textString;
};

const ModalLogicTypeDisabled = ({close, onCreateOrUpdateLogicType, responseLogicValues, responseLogicType, steps}) => {
    const {applyToRemark, responseIdDest, iteration} = responseLogicValues || {};
    const {defaultParam, name} = responseLogicType || {};
    const [allResponses, setAllResponses] = useState([]);
    const [selectList, setSeletectList] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const [doesTheLogicApplyOnCurrentResponse, setDoesTheLogicApplyOnCurrentResponse] = useState(true);

    useEffect(() => {
        const fetchAllResponses = async () => {
            let propsResponsesList = [];
            steps
                .filter(step =>
                    window.location.href.indexOf('templateQuestions') !== -1
                        ? step.isTemplate === true
                        : step.isGroup === true
                )
                .forEach(step => {
                    step.questions.forEach(question => {
                        question.responses.forEach(response => {
                            propsResponsesList.push({
                                id: response.id,
                                questionId: question.id,
                                libelle: `${step.order} ${trimStringIfNeeded(step.message)} / ${
                                    question.order
                                } ${trimStringIfNeeded(question.message)} / ${response.order} ${response.message}`,
                            });
                        });
                    });
                });
            setAllResponses([...propsResponsesList]);
            setSeletectList(propsResponsesList.map(el => ({id: el.id, value: el.libelle})));
            let logicSelectedId = responseIdDest ? responseIdDest : null;
            setSelectedId(logicSelectedId || (propsResponsesList.length > 0 ? propsResponsesList[0].id : null));
            setDoesTheLogicApplyOnCurrentResponse(!responseIdDest);
        };
        fetchAllResponses();
    }, []);

    useEffect(() => {
        const selectedResponse = allResponses.find(el => `${el.id}` === `${selectedId}`) || {};
        const {questionId} = selectedResponse || null;
        setSelectedQuestionId(questionId);
    }, [selectedId]);

    const onRadioButtonResponseToApplyLogicClick = value => setDoesTheLogicApplyOnCurrentResponse(value);

    return (
        <ModalCustomVersion2
            close={close}
            header={name}
            body={
                <div>
                    {defaultParam && (
                        <div>
                            <TextAreaInput
                                label="Default Parameters"
                                value={defaultParam}
                                width={550}
                                rows={7}
                                disabled={true}
                            />
                        </div>
                    )}
                    <div>
                        <div style={{fontWeight: 'bold', marginBottom: 15}}>Response to apply logic:</div>
                        <RadioButton
                            radioButtonList={[
                                {
                                    description: 'Current response',
                                    value: true,
                                },
                                {description: 'Other response', value: false},
                            ]}
                            groupName={'responseToApplyLogic'}
                            value={doesTheLogicApplyOnCurrentResponse}
                            clickEvent={onRadioButtonResponseToApplyLogicClick}
                            triple={true}
                        />
                    </div>
                    <div>
                        <SelectInput
                            label={`Other response to disable:`}
                            value={selectedId}
                            notAll={true}
                            double={true}
                            onChange={e => setSelectedId(e.target.value)}
                            list={selectList}
                            disabled={doesTheLogicApplyOnCurrentResponse === true}
                        />
                    </div>
                    <div style={{clear: 'both'}} />
                </div>
            }
            footer={
                <>
                    <Button icon={'close'} clickAction={close} variation={'danger'}>{`Close`}</Button>
                    <Button
                        icon={'arrowRight'}
                        clickAction={() =>
                            onCreateOrUpdateLogicType(
                                null,
                                applyToRemark !== undefined ? applyToRemark : false,
                                doesTheLogicApplyOnCurrentResponse ? null : selectedId,
                                doesTheLogicApplyOnCurrentResponse ? null : selectedQuestionId,
                                null,
                                iteration
                            )
                        }
                    >{`Save`}</Button>
                </>
            }
        />
    );
};

export default ModalLogicTypeDisabled;
