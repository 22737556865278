import React from 'react';
import {injectIntl} from 'react-intl';
import TextInput from '~components/TextInput';
import SelectInput from '~components/SelectInput';
import Title from '~components/Title';
import DateInput from '~components/DateInput';
import TextLabelInput from '~components/TextLabelInput';
import InputLink from '~components/InputLink';
import Empty from '~components/Empty';
import {faChevronDown, faChevronRight, faInfo} from '@fortawesome/free-solid-svg-icons';
import {apiSpecies} from '~commonApi/static';
import {getExcelSignedURLFetchRequest} from '~commonApi/download';
import {apiDenominationDelete, apiDenominationSearch, apiDownloadProposalPDF} from './MyPVRMyDenominationsService';
import styles from './MyPVRMyDenominations.module.scss';
import ModalDenominationVersion2 from './ModalDenominationVersion2';
import CustomTable from '~components/CustomTable';
import {FORMAT_DATE_EASY, formatDateEasy, reformatDateEasy} from '~components/FormatFunctions';
import {withRouter} from 'react-router-dom';
import {saveAs} from 'file-saver';
import MyPVRMyDenominationsActionButtons from './MyPVRMyDenominationsActionButtons';
import getIcon from '~utils/icons';
import {apiUserClients} from '~commonApi/clients';
import {getPreSignedURLFetchRequest} from '../../utils/requests';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
} from '../../componentsLayout';
import NavigationMyPVR from '../../shared/NavigationMyPVR';
import {ModalDenominationStatusInfoVersion2, ModalStatusInfoVersion2} from '../../commonModals';
import {Button} from '../../componentsFormV2';
import MainWrapper from '../../componentsLayout/MainWrapper';
import {trackPageView} from '../../utils';

const ALL = 'All';
const RESULT_FIELDS_ALL = [
    'applicationNumber',
    'speciesName',
    'denomination',
    'proposalDate',
    'denominationStatus',
    'objectionDate',
    'deadlineForReply',
    'foreseenDate',
    'arrivalDate',
];
const RESULT_FIELDS_DEFAULT = [
    'applicationNumber',
    'speciesName',
    'denomination',
    'proposalDate',
    'denominationStatus',
    'objectionDate',
    'deadlineForReply',
    'foreseenDate',
    'arrivalDate',
];

const advancedCriteria = [
    'denominationStatusIds',
    'applicants',
    'proposalDateFrom',
    'proposalDateTo',
    'applicationStatus',
    'applicationDateFrom',
    'applicationDateTo',
    'deadlineForReplyFrom',
    'deadlineForReplyTo',
];

const denominationStatusArray = [
    'toBeProposed',
    'toBeProposedImminentArrivalOfTheFinalDUS',
    'inProcess',
    'readyForPublicationOrPublished',
    'observation',
    'publishedMoreThan3MonthsAgo',
    'objection',
    'rejectedOrWithdrawn',
    'todayProposal',
];

const statusOptions = [
    {id: 'A', value: 'Active application'},
    {id: 'W', value: 'Withdrawn'},
    {id: 'R', value: 'Refused'},
    {id: 'G', value: 'Granted'},
    {id: 'T', value: 'Terminated Right'},
    {id: 'P,M,U', value: 'Reception ongoing'},
];

const DEFAULT_CRITERIA = {
    denomination: '',
    denominationFilter: 'equals',
    breedersReference: '',
    speciesName: '',
    speciesNameFilter: 'starts',
    speciesIds: '',
    applicationNumber: '',
    denominationStatus: '',
    denominationStatusIds: '',
    clientName: '',
    applicants: '',
    proposalDateFrom: '',
    proposalDateTo: '',
    applicationStatus: ALL,
    applicationDateFrom: '',
    applicationDateTo: '',
    deadlineForReplyFrom: '',
    deadlineForReplyTo: '',
    //
    pageNumber: 1,
    pageSize: 10,
    order: 'applicationNumber',
    reverse: false,
    refresh: false,
    excel: false,
};

const DATEFORMAT_CRITERIA_KEYS = [
    'proposalDateFrom',
    'proposalDateTo',
    'applicationDateFrom',
    'applicationDateTo',
    'deadlineForReplyFrom',
    'deadlineForReplyTo',
];

const selectedDenominationStatusDefault = [
    'inProcess',
    'todayProposal',
    'toBeProposed',
    'toBeProposedImminentArrivalOfTheFinalDUS',
    'publishedMoreThan3MonthsAgo',
    'readyForPublicationOrPublished',
    'observation',
    'objection',
];

const modalScreen = {
    STATUS_INFO: 'STATUS_INFO',
    DENOMINATION_STATUS_INFO: 'DENOMINATION_STATUS_INFO',
    DENOMINATION_SCREEN: 'DENOMINATION_SCREEN',
};

const DEFAULT_COUNT = 0;

class MyPVRMyDenominations extends React.Component {
    denominationStatusListByIdName = [];
    denominationStatusHash = {};

    constructor(props) {
        super(props);
        const selectedDenominationStatuses = {};
        selectedDenominationStatusDefault.forEach(
            selectedDenominationId =>
                (selectedDenominationStatuses[selectedDenominationId] = this.props.intl.formatMessage({
                    id: `denomination.${selectedDenominationId}`,
                }))
        );
        const initialCriteria = Object.assign({}, DEFAULT_CRITERIA, {
            denominationStatusIds: selectedDenominationStatusDefault.join(','),
        });
        this.state = {
            clients: null,
            advancedOpened: false,
            errorTitle: '',
            errorMessage: '',
            modalScreen: null,
            loading: 0,
            selectedSpecies: {},
            denominations: null,
            timestamp: null,
            count: DEFAULT_COUNT,
            criteria: initialCriteria,
            criteriaCount: 0,
            advancedCriteriaCount: 0,
            lastSearch: (this.lastSearchCriteria && this.lastSearchCriteria.dateTime) || null,
            clientRepresentant: true,
            speciesListByIdName: null,
            speciesNameHash: null,
            selectedDenominationStatuses,
            clientListByIdName: null,
            clientNameHash: null,
            currentRecord: null,
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'denomination'});
        this.denominationStatusListByIdName = denominationStatusArray.map(denominationStatus => {
            const denominationStatusName = this.props.intl.formatMessage({id: `denomination.${denominationStatus}`});
            this.denominationStatusHash[denominationStatusName] = [denominationStatus];
            return {ID: denominationStatus, NAME: denominationStatusName};
        });

        this.loadJSONs();
        this.loadUrlParams();
    }

    loadUrlParams = () => {
        const domainArray = document.location.href.split('?');
        if (domainArray.length > 1) {
            const criteria = Object.assign({}, this.initialCriteria);
            const params = domainArray.pop();
            params.split('&').forEach(param => {
                const paramElements = param.split('=');
                if (paramElements.length === 2) {
                    const key = paramElements[0];
                    criteria[key] = decodeURIComponent(paramElements[1]);
                }
            });
            if (Object.keys(criteria).length > 0) {
                const selectedSpecies = {};
                if (criteria.speciesIds && criteria.speciesIds !== '') {
                    criteria.speciesIds.split(',').forEach(speciesId => (selectedSpecies[speciesId] = false));
                }
                const selectedDenominationStatuses = {};
                if (criteria.denominationStatusIds && criteria.denominationStatusIds !== '') {
                    criteria.denominationStatusIds.split(',').forEach(
                        selectedDenominationId =>
                            (selectedDenominationStatuses[selectedDenominationId] = this.props.intl.formatMessage({
                                id: `denomination.${selectedDenominationId}`,
                            }))
                    );
                }
                const selectedClients = {};
                if (criteria.applicants) {
                    criteria.applicants.split(',').forEach(clientId => (selectedClients[clientId] = false));
                }
                DATEFORMAT_CRITERIA_KEYS.forEach(criteriaKey => {
                    if (/^[0-9]{4}(-([0-9]){2}){2}$/.test(criteria[criteriaKey])) {
                        criteria[criteriaKey] = formatDateEasy(criteria[criteriaKey]);
                    }
                });
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                this.setState(
                    Object.assign(
                        {},
                        {
                            criteria,
                            criteriaCount,
                            advancedCriteriaCount,
                            selectedSpecies,
                            selectedDenominationStatuses,
                            selectedClients,
                        },
                        criteria.order && {defaultOrder: criteria.order},
                        criteria.reverse && {reverseOrder: criteria.reverse}
                    ),
                    () => this.search(false, true)
                );
            }
        }
    };

    loadJSONs = () => {
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                apiUserClients()
                    .then(jsonResponse => {
                        if (jsonResponse) {
                            const clientNameHash = {};
                            ((jsonResponse && jsonResponse.clients) || []).forEach(client => {
                                if (!clientNameHash[client.name]) {
                                    clientNameHash[client.name] = [];
                                }
                                clientNameHash[client.name].push(client.clientId);
                            });
                            const clientListByIdName = Object.keys(clientNameHash).map(clientName => ({
                                ID: clientNameHash[clientName].join(','),
                                NAME: clientName,
                            }));
                            const selectedClients = {};
                            const selectedClientsKeyArray = Object.keys(this.state.selectedClients || {});
                            clientListByIdName
                                .filter(i => selectedClientsKeyArray.indexOf(i.ID) !== -1)
                                .forEach(item => (selectedClients[item.ID] = item.NAME));
                            this.setState({clientListByIdName, clientNameHash, selectedClients});
                        }
                    })
                    .catch(error => {
                        ERROR`Error loadJSONs() at MyPVRFinances: ${error.message}`;
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                apiSpecies()
                    .then(jsonResponse => {
                        if (jsonResponse) {
                            const speciesNameHash = {};
                            (jsonResponse || []).forEach(speciesElement => {
                                if (!speciesNameHash[speciesElement.NAME]) {
                                    speciesNameHash[speciesElement.NAME] = [];
                                }
                                speciesNameHash[speciesElement.NAME].push(speciesElement.ID);
                            });
                            const speciesListByIdName = Object.keys(speciesNameHash).map(speciesName => ({
                                ID: speciesNameHash[speciesName].join(','),
                                NAME: speciesName,
                            }));
                            const selectedSpecies = {};
                            const selectedSpeciesKeyArray = Object.keys(this.state.selectedSpecies || {});
                            speciesListByIdName
                                .filter(i => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                                .forEach(item => (selectedSpecies[item.ID] = item.NAME));
                            this.setState({
                                speciesListByIdName,
                                speciesNameHash,
                                selectedSpecies,
                                timestamp: Date.now(),
                            });
                        }
                    })
                    .catch(error => {
                        ERROR`FormLabelInputSpecies: ${error.message}`;
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );
    };

    showError = (title, message) =>
        this.setState({
            errorTitle: title,
            errorMessage: message,
        });

    closeError = () =>
        this.setState({
            errorTitle: '',
            errorMessage: '',
        });

    toggleAdvancedSearch = () =>
        this.setState(
            prevState => ({
                advancedOpened: !prevState.advancedOpened,
            }),
            () => {
                if (this.state.advancedOpened && this.setSelectedFunction && this.state.criteria.applicants) {
                    this.setSelectedFunction(this.state.criteria.applicants.split(','));
                }
            }
        );

    showModalStatusInfo = () => this.setState({modalScreen: modalScreen.STATUS_INFO});

    showModalDenominationStatusInfo = () => this.setState({modalScreen: modalScreen.DENOMINATION_STATUS_INFO});

    showModalDenominationScreen = (rowObject, editMode) =>
        this.setState({
            modalScreen: modalScreen.DENOMINATION_SCREEN,
            denominationId: rowObject.denominationId,
            denominationObject: rowObject,
            editMode,
        });

    closeModal = applicationNumber => {
        if (applicationNumber) {
            this.setState({denominationId: null, denominationObject: {}, modalScreen: null}, () => {
                this.resetCriteria({applicationNumber}).then(() => this.search(true));
            });
        } else {
            this.setState({modalScreen: null});
        }
    };

    parseCriteria = criteria => {
        const parsedCriteria = {};
        Object.keys(criteria).map(key => {
            if (DATEFORMAT_CRITERIA_KEYS.indexOf(key) !== -1 && criteria[key] !== '') {
                const date = criteria[key];
                parsedCriteria[key] = reformatDateEasy(date);
            } else if (key === 'denominationFilter' && !criteria['denomination']) {
                criteria[key] = DEFAULT_CRITERIA[key];
            } else if (key === 'speciesNameFilter' && !criteria['speciesName']) {
                criteria[key] = DEFAULT_CRITERIA[key];
            } else {
                parsedCriteria[key] = criteria[key];
            }
        });
        return parsedCriteria;
    };

    search = (refresh, urlLoad) => {
        this.setState(
            prev => ({
                loading: ++prev.loading,
                criteria: Object.assign(
                    {},
                    prev.criteria,
                    {refresh: !!refresh},
                    !refresh && !urlLoad && {pageNumber: 1}
                ),
            }),
            () => {
                this.lastSearchCriteria = {dateTime: Date.now(), criteria: this.state.criteria};
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams(parsedCriteria);
                apiDenominationSearch(parsedCriteria, DEFAULT_CRITERIA)
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.denominations) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        denominations: jsonResponse.data.denominations,
                                        timestamp: Date.now(),
                                        lastSearch:
                                            (this.lastSearchCriteria && this.lastSearchCriteria.dateTime) || null,
                                    },
                                    jsonResponse.data.count && {count: jsonResponse.data.count}
                                );
                            });
                        }
                    })
                    .catch(error => {
                        ERROR`Register search list error: ${error.message}`;
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );
    };

    printExcel = excelTranslations =>
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                const parsedCriteria = Object.assign({}, this.parseCriteria(this.state.criteria), {excel: true});
                apiDenominationSearch(parsedCriteria, DEFAULT_CRITERIA, excelTranslations)
                    .then(jsonResponse => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.token) {
                            getExcelSignedURLFetchRequest(jsonResponse.data.token).then(response => {
                                if (response && response.signedUrl) {
                                    const {signedUrl} = response;
                                    getPreSignedURLFetchRequest(signedUrl)
                                        .then(response => response.blob())
                                        .then(responseBlob => {
                                            saveAs(responseBlob, `MyDenominations.xlsx`);
                                        });
                                }
                            });
                        }
                    })
                    .catch(error => LOG`error downloading: ${error}`)
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );

    buildParams = parsedCriteria => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(i => parsedCriteria[i] !== DEFAULT_CRITERIA[i] && i !== 'refresh')
            .map(key => `${key}=${parsedCriteria[key]}`);
        this.props.history.replace(`/denomination/${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`);
    };

    countCriteria = (criteria, advanced) => {
        let count = 0;
        (advanced ? advancedCriteria : Object.keys(criteria)).map(key => {
            if (criteria[key] && criteria[key] !== DEFAULT_CRITERIA[key] && criteria[key] !== '') count++;
        });
        return count;
    };

    resetCriteria = criteriaValue => {
        return new Promise(resolve => {
            this.setState(
                prev => ({
                    denominations: null,
                    selectedSpecies: {},
                    selectedDenominationStatuses: {},
                    selectedClients: {},
                    criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize, criteriaValue || {}),
                    criteriaCount: 0,
                    advancedCriteriaCount: 0,
                }),
                resolve
            );
        });
    };

    updateCriteriaValue = (criteriaValue, callback, refresh) => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                // DEBUG`new criteria: ${{...criteria}}`;
                const criteriaCount = this.countCriteria(criteria);
                const advancedCriteriaCount = this.countCriteria(criteria, true);
                pageNumberChanged = criteriaValue.pageNumber && prev.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = criteriaValue.pageSize && prev.pageSize !== criteriaValue.pageSize;
                orderChanged = criteriaValue.order && prev.order !== criteriaValue.order;
                reverseChanged = criteriaValue.reverse && prev.reverse !== criteriaValue.reverse;
                return {criteria, criteriaCount, advancedCriteriaCount};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    downloadProposalPDF = applicationNumber => {
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                apiDownloadProposalPDF(applicationNumber)
                    .then(response => {
                        if (response.error === 404) {
                            this.setState({
                                errorTitle: '404 - Document not found',
                                errorMessage: 'This document is not available for download',
                            });
                        } else {
                            if (response && response.signedUrl) {
                                const {signedUrl} = response;
                                getPreSignedURLFetchRequest(signedUrl)
                                    .then(response => response.blob())
                                    .then(responseBlob => {
                                        saveAs(responseBlob, `Proposal draft ${applicationNumber}.pdf`);
                                    });
                            }
                        }
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );
    };

    deleteDenomination = applicationNumber =>
        this.setState(
            prev => ({loading: ++prev.loading}),
            () => {
                apiDenominationDelete({applicationNumber})
                    .then(jsonResponse => {
                        LOG`rows affected: ${jsonResponse && jsonResponse.rowsAffected}`;
                        this.searchLastCriteria();
                    })
                    .then(() => this.setState(prev => ({loading: --prev.loading})));
            }
        );

    searchLastCriteria = () => {
        const criteriaCount = this.countCriteria(this.lastSearchCriteria.criteria);
        const advancedCriteriaCount = this.countCriteria(this.lastSearchCriteria.criteria, true);
        this.setState(
            {
                criteriaCount,
                advancedCriteriaCount,
                criteria: Object.assign({}, DEFAULT_CRITERIA, this.lastSearchCriteria.criteria),
            },
            () => {
                if (
                    this.state.advancedOpened &&
                    this.setSelectedFunction &&
                    this.lastSearchCriteria.criteria.applicants
                ) {
                    this.setSelectedFunction(this.lastSearchCriteria.criteria.applicants.split(','));
                }
                this.search();
            }
        );
    };

    onDenominationChange = ({target: {value: denomination}}) => this.updateCriteriaValue({denomination});

    onDenominationFilterChange = ({target: {value: denominationFilter}}) =>
        this.updateCriteriaValue({denominationFilter});

    onBreederReferenceChange = ({target: {value: breedersReference}}) => this.updateCriteriaValue({breedersReference});

    onSpeciesSelectionChange = selectedSpecies =>
        this.updateCriteriaValue({speciesIds: Object.keys(selectedSpecies).join(','), speciesName: ''}, () =>
            this.setState({selectedSpecies})
        );

    onSpeciesNameChange = speciesName => this.updateCriteriaValue({speciesName});

    onSpeciesNameFilterChange = ({target: {value: speciesNameFilter}}) => this.updateCriteriaValue({speciesNameFilter});

    onApplicationNumberChange = ({target: {value: applicationNumber}}) => {
        if (/[0-9]{0,8}/.test(applicationNumber)) {
            applicationNumber = applicationNumber.substring(0, 8);
            this.updateCriteriaValue({applicationNumber});
        }
    };

    onClientChange = ({target: {value: clientName}}) => this.updateCriteriaValue({clientName});

    onDenominationStatusChange = () => {};

    onDenominationStatusSelectionChange = selectedDenominationStatuses =>
        this.updateCriteriaValue(
            {denominationStatusIds: Object.keys(selectedDenominationStatuses).join(','), denominationStatus: ''},
            () =>
                this.setState(prev =>
                    Object.assign(
                        {},
                        {selectedDenominationStatuses},
                        !selectedDenominationStatuses['objection'] &&
                            !selectedDenominationStatuses['observation'] && {
                                criteria: Object.assign({}, prev.criteria, {
                                    deadlineForReplyFrom: '',
                                    deadlineForReplyTo: '',
                                }),
                            }
                    )
                )
        );

    onSelectedClientsIdChange = selectedClients =>
        this.updateCriteriaValue({applicants: Object.keys(selectedClients).join(','), clientName: ''}, () =>
            this.setState({selectedClients})
        );

    onClientNameChange = () => {};

    onProposalDateFromChange = proposalDateFrom => this.updateCriteriaValue({proposalDateFrom});

    onProposalDateToChange = proposalDateTo => this.updateCriteriaValue({proposalDateTo});

    onApplicationStatusChange = ({target: {value: applicationStatus}}) => this.updateCriteriaValue({applicationStatus});

    onApplicationFromChange = applicationDateFrom => this.updateCriteriaValue({applicationDateFrom});

    onApplicationToChange = applicationDateTo => this.updateCriteriaValue({applicationDateTo});

    onDeadlineForReplyDateFromChange = deadlineForReplyFrom => this.updateCriteriaValue({deadlineForReplyFrom});

    onDeadlineForReplyDateToChange = deadlineForReplyTo => this.updateCriteriaValue({deadlineForReplyTo});

    render() {
        const actions = MyPVRMyDenominationsActionButtons(
            this.props,
            this.showModalDenominationScreen,
            this.deleteDenomination
        );
        return (
            <>
                {this.state.modalScreen === modalScreen.STATUS_INFO ? (
                    <ModalStatusInfoVersion2 close={this.closeModal} />
                ) : null}
                {this.state.modalScreen === modalScreen.DENOMINATION_STATUS_INFO ? (
                    <ModalDenominationStatusInfoVersion2 close={this.closeModal} />
                ) : null}
                {this.state.modalScreen === modalScreen.DENOMINATION_SCREEN ? (
                    <ModalDenominationVersion2
                        {...this.state.denominationObject}
                        editMode={this.state.editMode}
                        apiDenominationSearch={apiDenominationSearch}
                        close={this.closeModal}
                    />
                ) : null}
                {this.state.loading !== 0 ? <HeaderLoading /> : null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`My PVR`} />
                <NavigationMyPVR />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <div className={styles.mainFieldContainer}>
                            <TextInput
                                filter={['equals', 'contains']}
                                currentFilter={this.state.criteria.denominationFilter}
                                onFilterChange={this.onDenominationFilterChange}
                                double={true}
                                infoIcon={faInfo}
                                onChange={this.onDenominationChange}
                                onEnter={this.search}
                                value={this.state.criteria.denomination}
                                popOverText={`The search will find all applications containing what is typed`}
                                outsideLabel={'Denomination'}
                                outsideLabelWidth={160}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <TextInput
                                filter={'Contains'}
                                double={true}
                                infoIcon={faInfo}
                                onChange={this.onBreederReferenceChange}
                                onEnter={this.search}
                                value={this.state.criteria.breedersReference}
                                popOverText={`The search will find all applications containing what is typed`}
                                outsideLabel={'Breeder’s reference'}
                                outsideLabelWidth={160}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <TextLabelInput
                                filter={['starts', 'contains']}
                                currentFilter={this.state.criteria.speciesNameFilter}
                                onFilterChange={this.onSpeciesNameFilterChange}
                                double={true}
                                onSelectionChange={this.onSpeciesSelectionChange}
                                onChange={this.onSpeciesNameChange}
                                onEnter={this.search}
                                value={this.state.criteria.speciesName}
                                selectedElements={this.state.selectedSpecies}
                                delay={300}
                                multiple={true}
                                outsideLabel={'Species'}
                                outsideLabelWidth={160}
                                listByIdName={this.state.speciesListByIdName}
                                nameHash={this.state.speciesNameHash}
                                popover={`The search will find all applications concerned by the specified species`}
                            />
                            <TextInput
                                filter={'Starts with'}
                                placeholder={`e.g. 2015 or 20150001`}
                                infoIcon={faInfo}
                                onChange={this.onApplicationNumberChange}
                                onEnter={this.search}
                                value={this.state.criteria.applicationNumber}
                                popOverText={`The search will find all applications whose number starts with the entered terms.`}
                                outsideLabel={'Application number(s)'}
                                outsideLabelWidth={160}
                            />
                            <div style={{clear: 'both'}}>{}</div>
                            <div className={styles.AdvancedSearchLinkSpacer}>
                                <Empty width={160} oneLine={true} />
                            </div>
                            <InputLink
                                label={`Advanced search${
                                    this.state.advancedCriteriaCount !== 0
                                        ? ` (${this.state.advancedCriteriaCount})`
                                        : ''
                                } `}
                                icon={this.state.advancedOpened ? faChevronDown : faChevronRight}
                                clickAction={this.toggleAdvancedSearch}
                            />
                            <Empty oneLine={true} />
                        </div>
                        {this.state.advancedOpened && (
                            <>
                                <div className={styles.doubleColumn}>
                                    <Title double={true} full={true}>{`Denomination details`}</Title>
                                    <TextLabelInput
                                        label={'Denomination status'}
                                        double={true}
                                        onSelectionChange={this.onDenominationStatusSelectionChange}
                                        onChange={this.onDenominationStatusChange}
                                        value={this.state.criteria.denominationStatus}
                                        selectedElements={this.state.selectedDenominationStatuses}
                                        delay={300}
                                        multiple={true}
                                        listByIdName={this.denominationStatusListByIdName}
                                        nameHash={this.denominationStatusHash}
                                        clickable={true}
                                        buttonAction={this.showModalDenominationStatusInfo}
                                        buttonIcon={faInfo}
                                    />
                                    <TextLabelInput
                                        double={true}
                                        onSelectionChange={this.onSelectedClientsIdChange}
                                        onChange={this.onClientNameChange}
                                        value={this.state.criteria.clientName}
                                        selectedElements={this.state.selectedClients}
                                        delay={300}
                                        multiple={true}
                                        label={'Applicant(s)/Breeder(s)/Representative(s)'}
                                        outsideLabelWidth={160}
                                        listByIdName={this.state.clientListByIdName}
                                        nameHash={this.state.clientNameHash}
                                    />
                                    <DateInput
                                        label={`Proposal date`}
                                        changeDateFrom={this.onProposalDateFromChange}
                                        changeDateTo={this.onProposalDateToChange}
                                        inputValueFrom={this.state.criteria.proposalDateFrom}
                                        inputValueTo={this.state.criteria.proposalDateTo}
                                    />
                                </div>
                                <div className={styles.simpleColumn}>
                                    <Title full={true}>{`Application details`}</Title>
                                    <SelectInput
                                        label={`Application status`}
                                        value={this.state.criteria.applicationStatus}
                                        onChange={this.onApplicationStatusChange}
                                        list={statusOptions}
                                        buttonAction={this.showModalStatusInfo}
                                        buttonIcon={faInfo}
                                    />
                                    <DateInput
                                        label={`Application date`}
                                        changeDateFrom={this.onApplicationFromChange}
                                        changeDateTo={this.onApplicationToChange}
                                        inputValueFrom={this.state.criteria.applicationDateFrom}
                                        inputValueTo={this.state.criteria.applicationDateTo}
                                    />
                                    {this.state.selectedDenominationStatuses['observation'] ||
                                    this.state.selectedDenominationStatuses['objection'] ? (
                                        <React.Fragment>
                                            <Title full={true}>{`Objection/Observation details`}</Title>
                                            <DateInput
                                                label={`Deadline for reply`}
                                                changeDateFrom={this.onDeadlineForReplyDateFromChange}
                                                changeDateTo={this.onDeadlineForReplyDateToChange}
                                                inputValueFrom={this.state.criteria.deadlineForReplyFrom}
                                                inputValueTo={this.state.criteria.deadlineForReplyTo}
                                            />
                                        </React.Fragment>
                                    ) : null}
                                </div>
                            </>
                        )}
                        <div style={{clear: 'both'}} />
                        <FormFooter>
                            <Button variation={'secondary'} clickAction={() => this.search(false)}>
                                {`Clear fields`}
                            </Button>
                            <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    <div style={{marginBottom: 20}}>
                        <CustomTable
                            version={2}
                            loading={this.state.loading !== 0}
                            pageNumber={this.state.criteria.pageNumber}
                            {...this.props}
                            tableName={'denominations'}
                            tableType={'OBJECT'}
                            tableSource={this.state.denominations}
                            timestamp={this.state.timestamp}
                            dataFilter={null}
                            id={'applicationNumber'}
                            pagination={true}
                            printExcel={this.printExcel}
                            resultFieldsAll={RESULT_FIELDS_ALL}
                            resultFieldsDefault={RESULT_FIELDS_DEFAULT}
                            intl={this.props.intl}
                            defaultOrder={this.state.criteria.order}
                            reverseOrder={this.state.criteria.reverse}
                            pageSize={this.state.criteria.pageSize}
                            filterFunctions={null}
                            count={this.state.count}
                            formatFunctions={{
                                denominationStatus: status =>
                                    this.props.intl.formatMessage({
                                        id: `denomination.${status}`,
                                    }),
                                proposalDate: FORMAT_DATE_EASY,
                                objectionDate: FORMAT_DATE_EASY,
                                deadlineForReply: FORMAT_DATE_EASY,
                                foreseenDate: FORMAT_DATE_EASY,
                                arrivalDate: FORMAT_DATE_EASY,
                            }}
                            headerPopup={{
                                denominationStatus: {
                                    description: 'Status explanations',
                                    handler: this.showModalDenominationStatusInfo,
                                },
                            }}
                            excelFormatFunctions={{
                                applicants: applicants => applicants.join('; '),
                            }}
                            updateCriteriaValue={this.updateCriteriaValue}
                            actions={actions}
                            forehandColumn={rowObject =>
                                rowObject.denominationStatus === 'todayProposal' ? (
                                    <img
                                        style={{width: 20, height: 20, cursor: 'pointer'}}
                                        alt={'Download'}
                                        src={getIcon('pdf')}
                                        onClick={() => this.downloadProposalPDF(rowObject.applicationNumber)}
                                    />
                                ) : null
                            }
                        />
                    </div>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default injectIntl(withRouter(MyPVRMyDenominations));
