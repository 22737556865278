import React from 'react';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import CustomTable from '~components/CustomTable';
import DateInput from '~components/DateInput';
import {FORMAT_DATE_TIME_EASY, reformatDateEasy} from '~components/FormatFunctions';
import SelectInput from '~components/SelectInput';
import TextInput from '~components/TextInput';
import TextLabelInput from '~components/TextLabelInput';
import {getDecodedJWT, loadUrlParams} from '~utils';
import CCModalInternalHistory from '../CCModalInternalHistory/CCModalInternalHistory';
import CCModalInternalRead from '../CCModalInternalRead/CCModalInternalRead';
import CCModalInternalReply from '../CCModalInternalReply/CCModalInternalReply';
import CCModalInternalCompose from '../CCModalInternalCompose/CCModalInternalCompose';
import CCArchiveActionButtons from './CCArchiveActionButtons';
import {
    apiCommunicationCentreInternalArchiveClientsList,
    apiCommunicationCentreInternalArchiveMessageUnArchive,
    apiCommunicationCentreInternalArchiveSearch,
} from './CCInternalArchiveService';
import {
    CASEHOLDERS_SHORTKEYS,
    findDefaultValueForCaseHolder,
    findDefaultValueForDirectory,
    getSelectListOfDirectory,
} from '../CommunicationCentreInternal/CommunicationCentreInternal.utils';
import ApplicationNumbersMultiple from '../../components/ApplicationNumbersMultiple';
import {
    IClientsList,
    ICommunicationMessageInternal,
    ICommunicationMessagesInternalSearchResults,
    IDecodedJWT,
} from '../../types';
import {
    Footer,
    FormFooter,
    FormWrapper,
    HeaderLoading,
    HeaderLogoMenu,
    HeaderTitleAndVersion,
} from '../../componentsLayout';
import NavigationCommunicationCentre from '../../shared/NavigationCommunicationCentre';
import {Button} from '../../componentsFormV2';
import {ModalApplicationNumbers} from '../../commonModals';
import MainWrapper from '../../componentsLayout/MainWrapper';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamation} from '@fortawesome/free-solid-svg-icons';
import {trackPageView} from '../../utils';

export const RESULT_FIELDS_DEFAULT_ARCHIVE_CPVO = [
    'from',
    'messageSubject',
    'replyBy',
    'applicationNumbers',
    'receivedDate',
];

const DATEFORMAT_CRITERIA_KEYS = ['arrivalDateFrom', 'arrivalDateTo'];

type TDefaultCriteria = typeof DEFAULT_CRITERIA;

type TKeyOfDefaultCriteria = keyof TDefaultCriteria;

const DEFAULT_CRITERIA = {
    applicationNumber: '',
    arrivalDateFrom: '',
    arrivalDateTo: '',
    caseHolder: 'All',
    cc: 'All',
    directory: 'All',
    excel: false,
    from: '',
    order: 'sentDate',
    pageNumber: 1,
    pageSize: 10,
    readingStatus: 'All',
    refresh: false,
    replyBy: 'All',
    reverse: true,
    searchTerm: '',
    sendingDateFrom: '',
    sendingDateTo: '',
};

interface IProps {
    history: any;
    intl: any;
}

interface IState {
    clientsListByIdName: {
        ID: string;
        NAME: string;
    }[];
    clientsName: string;
    clientsNameHash: any;
    count: number;
    criteria: TDefaultCriteria;
    isModalCPVOLogsOpen: boolean;
    isModalHistoryOpen: number | false;
    loading: number;
    messages: ICommunicationMessageInternal[];
    modalApplicationNumbers: string[];
    modalInternalMessageCompose: boolean;
    modalInternalMessageRead: number | false;
    modalInternalMessageReply: number | false;
    selectedClients: {[key: string]: string};
    screenLoaded: boolean;
    timestamp: number;
}

class CCInternalArchive extends React.Component<IProps, IState> {
    decodedJWT: IDecodedJWT = getDecodedJWT();

    constructor(props: IProps) {
        super(props);
        this.state = {
            clientsListByIdName: [],
            clientsName: '',
            clientsNameHash: {},
            count: 0,
            criteria: Object.assign({}, DEFAULT_CRITERIA),
            isModalCPVOLogsOpen: false,
            isModalHistoryOpen: false,
            loading: 0,
            messages: [],
            modalApplicationNumbers: [],
            modalInternalMessageCompose: false,
            modalInternalMessageRead: false,
            modalInternalMessageReply: false,
            selectedClients: {},
            screenLoaded: false,
            timestamp: Date.now(),
        };
    }

    componentDidMount() {
        trackPageView({documentTitle: 'communicationCentreInternalArchive'});
        const params = loadUrlParams(DATEFORMAT_CRITERIA_KEYS);
        const URLCriteria: any = {};
        Object.entries(params).forEach(([fieldName, fieldValue]) => {
            if (Object.keys(DEFAULT_CRITERIA).includes(fieldName)) {
                URLCriteria[fieldName] = fieldValue;
            }
        });
        this.loadJSONs();
        this.updateCriteriaValue(
            {
                directory: findDefaultValueForDirectory(this.decodedJWT),
                caseHolder: findDefaultValueForCaseHolder(this.decodedJWT),
                ...URLCriteria,
            },
            () => this.search(false, false, () => this.setState({screenLoaded: true}))
        );
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (prevState.criteria.directory !== this.state.criteria.directory) {
            if (parseInt(this.state.criteria.directory) !== 12 && this.state.criteria.caseHolder !== 'All') {
                this.updateCriteriaValue({caseHolder: 'All'});
            }
        }
    }

    loadJSONs = () => {
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () => {
                apiCommunicationCentreInternalArchiveClientsList()
                    .then((jsonResponse: IClientsList) => {
                        if (jsonResponse && jsonResponse.data) {
                            const nameHash: {[key: string]: number[]} = {};
                            (jsonResponse.data || []).forEach(speciesElement => {
                                if (!nameHash[speciesElement.NAME]) {
                                    nameHash[speciesElement.NAME] = [];
                                }
                                nameHash[speciesElement.NAME].push(speciesElement.ID);
                            });
                            const listByIdName = Object.keys(nameHash).map(name => ({
                                ID: nameHash[name].join(','),
                                NAME: name,
                            }));
                            const selectedClients: {[key: string]: string} = {};
                            const selectedSpeciesKeyArray = Object.keys(this.state.selectedClients || {});
                            listByIdName
                                .filter(i => selectedSpeciesKeyArray.indexOf(i.ID) !== -1)
                                .forEach(item => (selectedClients[item.ID] = item.NAME));

                            this.setState({
                                clientsListByIdName: listByIdName,
                                clientsNameHash: nameHash,
                                selectedClients,
                                timestamp: Date.now(),
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`FormLabelInputSpecies: ${error.message}`]);
                    })
                    .then(() => this.setState(prev => ({loading: prev.loading - 1})));
            }
        );
    };

    goToScreen = (screen: string): void =>
        this.setState({criteria: {...DEFAULT_CRITERIA}, selectedClients: {}}, () => {
            this.props.history.push(screen);
        });

    parseCriteria = (criteria: TDefaultCriteria): TDefaultCriteria => {
        const parsedCriteria: any = {};
        Object.keys(criteria).map(key => {
            let criteriaValue = criteria[key as TKeyOfDefaultCriteria];
            if (DATEFORMAT_CRITERIA_KEYS.indexOf(key) !== -1 && criteriaValue !== '') {
                parsedCriteria[key] = reformatDateEasy(criteriaValue);
            } else {
                parsedCriteria[key] = criteriaValue;
            }
        });
        return parsedCriteria as TDefaultCriteria;
    };

    buildParams = (parsedCriteria: TDefaultCriteria): void => {
        const paramArray = Object.keys(parsedCriteria)
            .filter(
                i =>
                    parsedCriteria[i as TKeyOfDefaultCriteria] !== DEFAULT_CRITERIA[i as TKeyOfDefaultCriteria] &&
                    i !== 'refresh'
            )
            .map(key => `${key}=${parsedCriteria[key as TKeyOfDefaultCriteria]}`);
        this.props.history.replace(
            `/communicationCentreInternalArchive${(paramArray.length > 0 && `?${paramArray.join('&')}`) || ''}`
        );
    };

    search = (refresh?: boolean, urlLoad?: boolean, callBack?: () => any): any =>
        this.setState(
            prev => ({
                loading: prev.loading + 1,
                criteria: Object.assign({}, prev.criteria, {refresh}, !refresh && !urlLoad && {pageNumber: 1}),
            }),
            () => {
                const parsedCriteria = this.parseCriteria(this.state.criteria);
                this.buildParams(parsedCriteria);
                apiCommunicationCentreInternalArchiveSearch(parsedCriteria, DEFAULT_CRITERIA)
                    .then((jsonResponse: ICommunicationMessagesInternalSearchResults) => {
                        if (jsonResponse && jsonResponse.data && jsonResponse.data.messages) {
                            this.setState(prev => {
                                const criteria = Object.assign({}, prev.criteria, {pageNumber: 1});
                                return Object.assign(
                                    {},
                                    !refresh && !urlLoad && {criteria},
                                    {
                                        messages: jsonResponse.data.messages,
                                        timestamp: Date.now(),
                                    },
                                    jsonResponse.data.count && {count: jsonResponse.data.count},
                                    !refresh && {selectedMessages: {}}
                                );
                            });
                        }
                    })
                    .catch((error: any) => {
                        ERROR([`Register search list error: ${error.message}`]);
                    })
                    .then(() => {
                        this.setState(prev => ({loading: prev.loading - 1}));
                        callBack && callBack();
                    });
            }
        );

    openModalInternalMessageRead = (communicationId: number): void =>
        this.setState({modalInternalMessageRead: communicationId});

    closeModalInternalMessageRead = (): void =>
        this.setState({modalInternalMessageRead: false}, () => this.search(true));

    closeModalInternalMessageReply = (refresh?: boolean): void =>
        this.setState({modalInternalMessageReply: false}, () => refresh && this.search(true));

    updateCriteriaValue = (criteriaValue: Partial<TDefaultCriteria>, callback?: () => any, refresh?: boolean): void => {
        let pageNumberChanged = false;
        let pageSizeChanged = false;
        let orderChanged = false;
        let reverseChanged = false;
        this.setState(
            prev => {
                const criteria = Object.assign({...prev.criteria}, {...criteriaValue});
                pageNumberChanged = prev.criteria.pageNumber !== criteriaValue.pageNumber;
                pageSizeChanged = prev.criteria.pageSize !== criteriaValue.pageSize;
                orderChanged = prev.criteria.order !== criteriaValue.order;
                reverseChanged = prev.criteria.reverse !== criteriaValue.reverse;
                return {criteria};
            },
            () => {
                callback && callback();
                (pageNumberChanged || pageSizeChanged || orderChanged || reverseChanged) &&
                    refresh &&
                    this.search(true);
            }
        );
    };

    openModalInternalMessageCompose = (): void => this.setState({modalInternalMessageCompose: true});

    closeModalInternalMessageCompose = (): void =>
        this.setState({modalInternalMessageCompose: false}, () => this.search(true));

    closeModalInternalMessageHistory = () => this.setState({isModalHistoryOpen: false}, () => this.search(true));

    formatTableMessages = (messages: ICommunicationMessageInternal[]) =>
        [...messages].map(el => ({
            ...el,
            lastUpdateDate: el.sentDate,
            receivedDate: el.sentDate,
            to: el.from,
        }));

    getTableFormatFunctions = () => ({
        addressees: (addressees: string) => (addressees || '').split('\\n').join(', '),
        applicationNumbers: (applicationNumbers: string[]) => (
            <ApplicationNumbersMultiple
                applicationNumbers={applicationNumbers}
                openModalApplicationNumbers={this.openModalApplicationNumbers}
            />
        ),
        deemedService: FORMAT_DATE_TIME_EASY,
        firstOpened: FORMAT_DATE_TIME_EASY,
        lastUpdateDate: FORMAT_DATE_TIME_EASY,
        notificationDate: FORMAT_DATE_TIME_EASY,
        receivedDate: FORMAT_DATE_TIME_EASY,
        sentDate: FORMAT_DATE_TIME_EASY,
    });

    closeModalApplicationNumbers = (): void => this.setState({modalApplicationNumbers: []});

    openModalApplicationNumbers = (applicationNumbers: string[]): void =>
        this.setState({modalApplicationNumbers: applicationNumbers});

    onTableRowClick = (communicationId: number) => this.openModalInternalMessageRead(communicationId);

    onModalInternalMessageReadButtonReplyClick = (): void =>
        this.setState(prev => ({
            modalInternalMessageRead: false,
            modalInternalMessageReply: prev.modalInternalMessageRead,
        }));

    onTextInputSearchTermChange = ({target: {value: searchTerm}}: React.ChangeEvent<HTMLInputElement>): void =>
        this.updateCriteriaValue({searchTerm});

    onTextInputValueApplicationNumberChange = ({
        target: {value: textInputValueApplicationNumber},
    }: React.ChangeEvent<HTMLInputElement>): void =>
        this.updateCriteriaValue({applicationNumber: textInputValueApplicationNumber});

    onSelectInputValueDirectoryChange = ({
        target: {value: selectInputValueDirectory},
    }: React.ChangeEvent<HTMLSelectElement>): void => this.updateCriteriaValue({directory: selectInputValueDirectory});

    onSelectInputValueCaseHolderChange = ({
        target: {value: selectInputValueCaseHolder},
    }: React.ChangeEvent<HTMLSelectElement>): void =>
        this.updateCriteriaValue({caseHolder: selectInputValueCaseHolder});

    onSelectInputValueCCChange = ({target: {value: selectInputValueCC}}: React.ChangeEvent<HTMLSelectElement>): void =>
        this.updateCriteriaValue({cc: selectInputValueCC});

    onDateInputValueArrivalDateFromChange = (dateInputValueArrivalDateFrom: string): void =>
        this.updateCriteriaValue({arrivalDateFrom: dateInputValueArrivalDateFrom});

    onDateInputValueArrivalDateToChange = (dateInputValueArrivalDateTo: string): void =>
        this.updateCriteriaValue({arrivalDateTo: dateInputValueArrivalDateTo});

    onSelectInputValueReadingStatusChange = ({
        target: {value: selectInputValueReadingStatus},
    }: React.ChangeEvent<HTMLSelectElement>): void =>
        this.updateCriteriaValue({readingStatus: selectInputValueReadingStatus});

    onClearFieldsClick = () =>
        this.setState(
            prev => ({
                selectedClients: {},
                criteria: Object.assign({}, DEFAULT_CRITERIA, prev.criteria.pageSize),
            }),
            () => this.search()
        );

    onTableIconHistoryClick = ({correlationId}: ICommunicationMessageInternal): void =>
        this.setState({isModalHistoryOpen: correlationId});

    onTableIconUnArchiveClick = (communicationId: number): void =>
        this.setState(
            prev => ({loading: prev.loading + 1}),
            () =>
                apiCommunicationCentreInternalArchiveMessageUnArchive(communicationId).then(() => {
                    this.setState(prev => ({loading: prev.loading - 1}));
                    this.search(true);
                })
        );

    onClientsSelectionChange = (selectedClients: {[key: string]: string}) =>
        this.setState({selectedClients, clientsName: ''}, () =>
            this.updateCriteriaValue({from: Object.keys(selectedClients || {}).join(',')})
        );

    onClientsNameChange = (clientsName: string): void => this.setState({clientsName});

    render() {
        const actions = CCArchiveActionButtons(this.onTableIconUnArchiveClick, this.onTableIconHistoryClick);

        return (
            <>
                {this.state.isModalHistoryOpen ? (
                    <CCModalInternalHistory
                        close={this.closeModalInternalMessageHistory}
                        correlationId={this.state.isModalHistoryOpen}
                        formatTableMessages={this.formatTableMessages}
                        getTableFormatFunctions={this.getTableFormatFunctions}
                    />
                ) : null}
                {this.state.modalInternalMessageCompose && (
                    <CCModalInternalCompose close={this.closeModalInternalMessageCompose} intl={this.props.intl} />
                )}
                {this.state.modalInternalMessageRead && (
                    <CCModalInternalRead
                        close={this.closeModalInternalMessageRead}
                        communicationId={this.state.modalInternalMessageRead}
                        folder={'archive'}
                        onButtonReplyClick={this.onModalInternalMessageReadButtonReplyClick}
                    />
                )}
                {this.state.modalInternalMessageReply && (
                    <CCModalInternalReply
                        close={this.closeModalInternalMessageReply}
                        communicationId={this.state.modalInternalMessageReply}
                    />
                )}
                {this.state.modalApplicationNumbers.length ? (
                    <ModalApplicationNumbers
                        applicationNumbers={this.state.modalApplicationNumbers}
                        close={this.closeModalApplicationNumbers}
                    />
                ) : null}
                {(this.state.loading !== 0 && <HeaderLoading />) || null}
                <HeaderLogoMenu />
                <HeaderTitleAndVersion title={`Communication Centre`} />
                <NavigationCommunicationCentre
                    clearFields={this.onClearFieldsClick}
                    openModalMessageCompose={this.openModalInternalMessageCompose}
                    search={this.search}
                    updateCriteriaValue={this.updateCriteriaValue}
                />
                <MainWrapper>
                    <FormWrapper paddingFormContent={'sm'}>
                        <TextInput
                            label={`Search term`}
                            onChange={this.onTextInputSearchTermChange}
                            value={this.state.criteria.searchTerm}
                            placeholder={`Type a search term`}
                            onEnter={this.search}
                        />
                        <TextInput
                            filter={'Starts with'}
                            placeholder={`e.g. 2015 or 20150001`}
                            label={`Application number(s)`}
                            onChange={this.onTextInputValueApplicationNumberChange}
                            value={this.state.criteria.applicationNumber}
                            onEnter={this.search}
                        />
                        <DateInput
                            label={`Arrival date`}
                            changeDateFrom={this.onDateInputValueArrivalDateFromChange}
                            changeDateTo={this.onDateInputValueArrivalDateToChange}
                            inputValueFrom={this.state.criteria.arrivalDateFrom}
                            inputValueTo={this.state.criteria.arrivalDateTo}
                        />
                        <SelectInput
                            label={`Directory`}
                            value={this.state.criteria.directory}
                            onChange={this.onSelectInputValueDirectoryChange}
                            list={getSelectListOfDirectory(this.decodedJWT)}
                        />
                        <SelectInput
                            label={`Caseholder`}
                            value={this.state.criteria.caseHolder || findDefaultValueForCaseHolder(this.decodedJWT)}
                            onChange={this.onSelectInputValueCaseHolderChange}
                            list={CASEHOLDERS_SHORTKEYS.map(shortKey => ({
                                id: shortKey,
                                value: shortKey,
                            }))}
                            disabled={parseInt(this.state.criteria.directory) !== 12}
                        />
                        <SelectInput
                            label={`Reading Status`}
                            value={this.state.criteria.readingStatus}
                            onChange={this.onSelectInputValueReadingStatusChange}
                            list={[
                                {id: '1', value: 'Read'},
                                {id: '2', value: 'not read'},
                            ]}
                            disabled={true}
                        />
                        <SelectInput
                            label={`CC`}
                            value={this.state.criteria.cc}
                            onChange={this.onSelectInputValueCCChange}
                            list={[{id: '1', value: 'Show only CC messages'}]}
                            disabled={true}
                        />
                        <TextLabelInput
                            delay={300}
                            double={true}
                            disabled={true}
                            label={'From'}
                            listByIdName={this.state.clientsListByIdName}
                            nameHash={this.state.clientsNameHash}
                            onSelectionChange={this.onClientsSelectionChange}
                            onChange={this.onClientsNameChange}
                            outsideLabelWidth={160}
                            placeholder={`Type part of the users name`}
                            selectedElements={this.state.selectedClients}
                            uniqueValue={true}
                            noInfo={true}
                            value={this.state.clientsName}
                        />
                        <FormFooter>
                            <Button
                                variation={'secondary'}
                                clickAction={this.onClearFieldsClick}
                            >{`Clear fields`}</Button>
                            <Button clickAction={() => this.search(false)}>{`Search`}</Button>
                        </FormFooter>
                    </FormWrapper>
                    <div style={{marginTop: 20, marginBottom: 20}}>
                        {this.state.screenLoaded ? (
                            <CustomTable
                                actions={actions}
                                bold={(rowObject: ICommunicationMessageInternal) => !rowObject.isRead}
                                count={this.state.count}
                                dataFilter={null}
                                defaultOrder={this.state.criteria.order}
                                filterFunctions={null}
                                formatFunctions={this.getTableFormatFunctions()}
                                forehandColumn={({urgent}: any) =>
                                    urgent ? (
                                        <FontAwesomeIcon
                                            icon={faExclamation as any}
                                            fontSize={25}
                                            title={'Urgent'}
                                            color={'#810202'}
                                        />
                                    ) : null
                                }
                                hideExcelButton={true}
                                id={'communicationId'}
                                intl={this.props.intl}
                                mode={'mailbox'}
                                pageNumber={this.state.criteria.pageNumber}
                                pageSize={this.state.criteria.pageSize}
                                pagination={true}
                                resultFieldsDefault={RESULT_FIELDS_DEFAULT_ARCHIVE_CPVO}
                                reverseOrder={this.state.criteria.reverse}
                                rowClick={this.onTableRowClick}
                                setLastCursor={null}
                                selectable={true}
                                tableName={'communicationCentreInternalArchive'}
                                tableType={'OBJECT'}
                                tableSource={this.formatTableMessages(this.state.messages)}
                                timestamp={this.state.timestamp}
                                updateCriteriaValue={this.updateCriteriaValue}
                                version={2}
                            />
                        ) : null}
                    </div>
                </MainWrapper>
                <Footer />
            </>
        );
    }
}

export default withRouter(injectIntl(CCInternalArchive));
